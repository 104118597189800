<div class="card appattachments">
  <div class="card-header">{{ "lang_attachments" | language }}</div>
  <div class="card-body p-0">
    <div class="mbrl15">
      <div class="row">
        <div class="col-md-3 col-sm-12 col-xs-12 mb-10">
          <ng-select
            [items]="types"
            [multiple]="true"
            bindLabel="title"
            bindValue="attach_type_id"
            appendTo="body"
            clearAllText="Clear"
            placeholder="{{ 'lang_doc_type' | language }}"
            [(ngModel)]="typelists"
            (change)="spinner.show(); search_my_attachments()"
          >
          </ng-select>
        </div>
        <div class="col-md-9 col-sm-12 col-xs-12 mb-10">
          <div class="input-group input-group-sm">
            <input
              type="text"
              class="form-control almnabrformcontrol"
              (keyup)="search_my_attachments()"
              [(ngModel)]="searchKey"
              placeholder="{{ 'lang_search' | language }}"
            />
            <span
              class="input-group-btn"
              *ngIf="
                requestfor !== 'profile' &&
                user_data.employee_status == '1' &&
                able2add
              "
            >
              <button
                class="btnSearch h34 btn btn-md btn-gray fa fa-upload mnabricon"
                id="btnSearch"
                type="button"
                (click)="openModalA(addNewUploads); emptyAttachForm()"
                matTooltip="{{ 'lang_upload_attachments' | language }}"
              ></button>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive dropdownyes customResponsive col-12">
          <table border="1" class="table CustomTable">
            <thead>
              <tr>
                <td>#</td>
                <td *ngIf="requestfor !== 'profile'">
                  <div
                    class="checkbox"
                    matTooltip="{{ 'lang_check_all' | language }}"
                  >
                    <input
                      type="checkbox"
                      class="allItemsAttach checkbox"
                      id="allItemsAttach"
                      (click)="checkAllAttach($event)"
                    />
                    <label for="checkbox"></label>
                  </div>
                </td>
                <td>{{ "lang_type" | language }}</td>
                <td>{{ "lang_subject" | language }}</td>
                <td>{{ "lang_file_extention" | language }}</td>
                <td>{{ "lang_file_level" | language }}</td>
                <td>{{ "lang_writer" | language }}</td>
                <td>{{ "lang_ondate" | language }}</td>
                <td>{{ "lang_action" | language }}</td>
              </tr>
            </thead>
            <tbody *ngIf="attachdata.length != '0'" class="AppendList">
              <tr
                *ngFor="
                  let file of attachdata.records;
                  let i = index;
                  let odd = odd;
                  let even = even
                "
                [ngClass]="{ odd: odd, even: even }"
                class="makeback removerow{{ file.file_records_id }}"
              >
                <td>{{ i + 1 }}</td>
                <td *ngIf="requestfor !== 'profile'">
                  <div
                    class="checkbox"
                    matTooltip="{{ 'lang_check' | language }}"
                  >
                    <input
                      type="checkbox"
                      class="eachItemAttach checkbox"
                      value="{{ file.file_records_id }}"
                      (click)="checkEachItemAttach($event)"
                    />
                    <label for="checkbox"></label>
                  </div>
                </td>
                <td *ngIf="file.type_name">{{ file.type_name }}</td>
                <td *ngIf="!file.type_name">----</td>
                <td class="breakall">{{ file.file_name }}</td>
                <td>{{ file.file_extension }}</td>
                <td>{{ file.level_keys }}</td>
                <td>{{ file.writer }}</td>
                <td>{{ file.created_datetime }}</td>
                <td>
                  <mat-icon class="mnabricon" [matMenuTriggerFor]="menu"
                    >settings</mat-icon
                  >
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      *ngIf="file.file_extension == 'pdf'"
                      (click)="
                        ds.preview_pdf_file(file.file_path, file?.file_name)
                      "
                    >
                      {{ "lang_file" | language }}
                    </button>
                    <button
                      mat-menu-item
                      *ngIf="file.file_extension != 'pdf'"
                      (click)="downloadImg(file.file_path, file?.file_name)"
                    >
                      {{ "lang_file" | language }}
                    </button>
                    <button
                      mat-menu-item
                      *ngIf="able2edit"
                      (click)="
                        openModalE(EditAttachModal); edit_my_Attach(file)
                      "
                    >
                      {{ "lang_edit" | language }}
                    </button>
                  </mat-menu>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="attachdata.length == '0'" class="AppendList">
              <tr class="odd">
                <td colspan="14" align="center">{{ attacherrormsg }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      *ngIf="attachdata?.length != '0'"
      class="card-footer padb0 bottomfooter"
    >
      <div class="row responsive_pagination">
        <div
          *ngIf="able2delete && requestfor !== 'profile'"
          class="deleteIconDiv none col-lg-1 col-sm-1 mb-10"
        >
          <i
            class="fa fa-trash-o faicon red deleteIcon"
            (click)="deleteBulkDataAttach()"
            aria-hidden="true"
            matTooltip="{{ 'lang_delete' | language }}"
          ></i>
        </div>
        <div
          class="col-lg-3 col-sm-3 mb-10"
          style="height: fit-content; margin: auto 0"
        >
          {{ "lang_total" | language }} : {{ attachdata.page.total_records }}
        </div>
        <div
          class="col-lg-5 col-sm-5 mb-10"
          style="display: flex; justify-content: center"
        >
          <!-- <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="10" checked (click)="spinner.show();attachpage(10)"> 10 </mat-radio-button>
                        <mat-radio-button value="20" (click)="spinner.show();attachpage(20)"> 20 </mat-radio-button>
                        <mat-radio-button value="50" (click)="spinner.show();attachpage(50)"> 50 </mat-radio-button>
                        <mat-radio-button value="100" (click)="spinner.show();attachpage(100)"> 100 </mat-radio-button>
                        <mat-radio-button value="500" (click)="spinner.show();attachpage(500)"> 500 </mat-radio-button>
                    </mat-radio-group> -->
          <ngb-pagination
            class="m-auto"
            [collectionSize]="attachdata?.page?.total_records"
            [rotate]="true"
            [ellipses]="false"
            [maxSize]="3"
            [boundaryLinks]="true"
            [(page)]="apage"
            [pageSize]="apsize"
            (pageChange)="spinner.show(); attachlength($event)"
          ></ngb-pagination>
        </div>
        <div
          class="col-lg-3 col-sm-3 mb-10"
          style="display: flex; justify-content: end; margin: auto"
        >
          <!-- {{'lang_goto' | language }} :
                    <select class="customselect" (change)="spinner.show();attachlength($event.target.value)">
                        <option *ngFor="let k of ds.getrange(attachdata.page.total_pages)" value="{{k}}">{{k}}</option>
                    </select> -->

          <mat-select
            [(ngModel)]="apsize"
            (selectionChange)="spinner.show(); attachpage($event.value)"
          >
            <!-- <mat-option value="1">1  </mat-option> -->
            <mat-option class="text-center" value="10"
              >10 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" value="20"
              >20 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" value="50"
              >50 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" value="100"
              >100 {{ "lang_per_page" | language }}</mat-option
            >
            <mat-option class="text-center" value="500"
              >500 {{ "lang_per_page" | language }}</mat-option
            >
          </mat-select>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #addNewUploads>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_attachments" | language }}</h4>
      <button type="button" class="close" (click)="modalRefA.hide()">
        &times;
      </button>
    </div>
    <form [formGroup]="attachform" (ngSubmit)="upload_files()">
      <div class="modal-body">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <label for="attachment_type"
                >{{ "lang_attachment_type" | language }} :
                <span class="red">*</span></label
              >
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <select
                formControlName="attachment_type"
                class="form-control almnabrformcontrol"
                matTooltip="{{ 'lang_attachment_type' | language }}"
              >
                <option value="">{{ "lang_choose_options" | language }}</option>
                <option *ngFor="let type of types" value="{{ type.key_code }}">
                  {{ type.title }}
                </option>
              </select>
              <mat-error
                *ngIf="
                  attachform.controls['attachment_type'].touched &&
                  attachform.controls['attachment_type'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <label for="level_keys"
                >{{ "lang_file_secutrity_levels" | language }} :
                <span class="red">*</span></label
              >
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <ng-select
                [items]="filepermissionlevels"
                [multiple]="true"
                bindLabel="label"
                appendTo="body"
                bindValue="value"
                clearAllText="Clear"
                placeholder="{{ 'lang_file_secutrity_levels' | language }}"
                formControlName="level_keys"
              >
              </ng-select>
              <mat-error
                *ngIf="
                  attachform.controls['level_keys'].touched &&
                  attachform.controls['level_keys'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <label for="attachment_descriptions"
                >{{ "lang_description" | language }} :
                <span class="red">*</span></label
              >
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <input
                type="text"
                formControlName="attachment_descriptions"
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_description' | language }}"
              />
              <mat-error
                *ngIf="
                  attachform.controls['attachment_descriptions'].touched &&
                  attachform.controls['attachment_descriptions'].errors
                    ?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <label for="attachment_link"
                >{{ "lang_select_files" | language }} :
                <span class="red">*</span></label
              >
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <div class="IDDIV">
                  <input
                    name="attachment_link"
                    type="file"
                    required
                    onclick="this.value = null"
                    (change)="uploadIDFile($event)"
                    class="nghide albutton width100"
                    accept=".pdf"
                  />
                  <label id="uploadIDButton" class="albutton"
                    >{{ idfilename }}
                  </label>
                </div>
              </div>
              <mat-error
                *ngIf="
                  attachform.controls['attachment_link'].touched &&
                  attachform.controls['attachment_link'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              (click)="modalRefA.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #EditAttachModal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_attachments" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefU.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="uform" (ngSubmit)="update()">
      <div class="modal-body">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <label for="level_keys"
                >{{ "lang_file_secutrity_levels" | language }} :
                <span class="red">*</span></label
              >
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <ng-select
                [items]="filepermissionlevels"
                [multiple]="true"
                bindLabel="label"
                appendTo="body"
                bindValue="value"
                clearAllText="Clear"
                placeholder="{{ 'lang_file_secutrity_levels' | language }}"
                formControlName="level_keys"
              >
              </ng-select>
              <mat-error
                *ngIf="
                  uform.controls['level_keys'].touched &&
                  uform.controls['level_keys'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <label for="attachment_descriptions"
                >{{ "lang_description" | language }} :
                <span class="red">*</span></label
              >
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <input
                type="text"
                formControlName="attachment_descriptions"
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_description' | language }}"
              />
              <mat-error
                *ngIf="
                  uform.controls['attachment_descriptions'].touched &&
                  uform.controls['attachment_descriptions'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              (click)="modalRefU.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<div class="modal" id="pdfMirPreviewDoc">
  <div class="modal-dialog width70">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "lang_preview" | language }}</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="pdf-container">
          <iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "lang_close" | language }}
        </button>
      </div>
    </div>
  </div>
</div>
