import { Component, Input, OnInit } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
	FormArray,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { special_approval_notes } from "app/routes/transactions/FORM_PS_TR1/formdata";
import { SendCodeComponent } from "app/routes/transactions/FORM_PS_TR1/send-code/send-code.component";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
	selector: "app-violation-step-two",
	templateUrl: "./step-two.component.html",
	styleUrls: ["./step-two.component.scss"],
})
export class ViolationStepTwoComponent implements OnInit {
	@Input() form_key;
	@Input() form_request_id;
	@Input() project_data;
	@Input() supervision_data;
	@Input() template_data;
	allData;
	@Input() set alldata(data: any) {
		if (data.status) {
			this.allData = data;
			if (this.form.value.violation_arr.length == 0) {
				for (
					let index = 0;
					index < data.form_vol1_data.records.length;
					index++
				) {
					const element = data.form_vol1_data.records[index];
					this.addViolation(element);
				}
			}
		}
	}

	submitForm: boolean = false;
	sendCodeForm: any;
	sendCodeStatus: boolean = false;
	rejectedValidators = [];
	stepName = "EMPLOYEE";
	form: FormGroup = new FormGroup({});
	users: any = [];

	myData: any = [];

	lang_key =
		localStorage.getItem("lang_key") == null
			? "en"
			: localStorage.getItem("lang_key");
	constructor(
		public ds: DataService,
		public router: Router,
		private snackBar: MatSnackBar,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {
		this.form = new FormGroup({
			transaction_request_id: new FormControl(this.form_request_id),
			approved: new FormControl("", [Validators.required]),

			violation_arr: this.fb.array([]),
			notes: new FormControl("", []),
			transactions_persons_action_code: new FormControl("", [
				Validators.required,
			]),
		});
	}
	ngOnInit(): void {
		this.myData = special_approval_notes;
		this.searchFunc();
	}

	getViolation(id: number) {
		if (this.lang_key == "en") {
			return this.searchArray.find((vio) => vio.violation_id == id)
				?.violation_en;
		} else {
			return this.searchArray.find((vio) => vio.violation_id == id)
				?.violation_ar;
		}
	}
	searchArray = [];
	searchFunc() {
		/* let formData = new FormData(); */

		return this.ds
			.post(`form/FORM_VOL1/search_violation`, [])
			.subscribe((response: any) => {
				this.searchArray = response.records;
			});
	}

	get getNotes() {
		return this.form.get("notes");
	}

	checkStatus() {
		if (this.form.get("approved").value == "0") {
			this.rejectedValidators = [Validators.required];
			this.getNotes?.setValidators([Validators.required]);

			this.getNotes?.updateValueAndValidity();
		} else {
			this.rejectedValidators = [];
			this.getNotes?.setValidators([]);

			this.getNotes?.updateValueAndValidity();
		}
	}
	trackByFnCustom(index: number, item: any) {
		return item.trackingId;
	}
	generateUniqueId() {
		return (
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15)
		);
	}

	addViolation(data) {
		var violation_arr = this.form.controls.violation_arr as FormArray;
		violation_arr.push(
			this.fb.group({
				violation_date_en: [data.violation_date_en, Validators.required],
				form_vol1_data_id: [data.form_data_id, Validators.required],
				violation_id: [data.violation_id, Validators.required],

				reason_text: [""],

				violation_punishment_id: [data.violation_punishment_id],
				violation_punishment: [data.violation_punishment],
				violation_count: [data.violation_count],
				trackingId: [this.generateUniqueId()],

				collapsed: [true],

				violation_statement_en: [data.violation_statement_en],
				violation_statement_ar: [data.violation_statement_ar],
				violation_punishment_type: [data.violation_punishment_type],
				violation_deduct_percentage: [data.violation_deduct_percentage],
				violation_amount: [data.violation_amount],
			})
		);
	}
	getFormByName(i, name?) {
		if (name) {
			return this.form.controls.violation_arr["controls"][i].get(name);
		} else {
			return this.form.controls.violation_arr["controls"][i];
		}
	}

	public completeStep3() {
		this.submitForm = true;
		if (this.form.valid) {
			this.spinner.show();
			let formData = new FormData();
			formData.append("transaction_request_id", this.form_request_id);
			formData.append("approving_status", this.form.value.approved);

			formData.append("note", this.form.value.notes);
			formData.append(
				"transactions_persons_action_code",
				this.form.value.transactions_persons_action_code
			);

			this.ds.post(`/form/${this.form_key}/sr`, formData).subscribe(
				(data) => {
					if (data.status) {
						this.spinner.hide();
						this.alert.success(data.msg);
						this.showMsg(data.msg, "ok", "success-snackbar");
						/*  this.addReason() */
						this.submitForm = false;
						setTimeout(() => {
							location.reload();
						}, 2000);
					} else {
						this.spinner.hide();
						this.alert.error(data?.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(error);
				}
			);
		} else {
			this.alert.error(this.lang.transform("lang_all_fields_required"));
		}
	}

	public addReason() {
		let formData = new FormData();
		let haveAtLeastOneReason = false;
		formData.append("transaction_request_id", this.form_request_id);
		let index = 0;
		for (let index = 0; index < this.form.value.violation_arr.length; index++) {
			const element = this.form.value.violation_arr[index];
			if (element.reason_text) {
				haveAtLeastOneReason = true;
				formData.append(
					`reasons[${index}][form_vol1_data_id]`,
					element.form_vol1_data_id
				);
				formData.append(`reasons[${index}][reason_text]`, element.reason_text);
			}
		}
		if (haveAtLeastOneReason) {
			this.ds.post(`/form/${this.form_key}/ar`, formData).subscribe(
				(data) => {
					if (data.status) {
						/*  this.spinner.hide(); */
						/* this.alert.success(data.msg); */
						/*  this.showMsg(data.msg, 'ok', 'success-snackbar'); */
						/*  this.submitForm = false; */
						/* setTimeout(() => {
              location.reload();
            }, 2000); */
						this.completeStep3();
					} else {
						this.spinner.hide();
						this.alert.error(data?.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(error);
				}
			);
		} else {
			this.completeStep3();
		}
	}

	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 4000,
			panelClass: [className],
		});
	}
	openModal() {
		const dialogRef = this.dialog.open(SendCodeComponent, {
			disableClose: true,
			width: "400px",
			data: {
				transaction_persons_type: "signature",
				allData: this.allData,
				stepName: this.stepName,
			},
		});
		dialogRef.afterClosed().subscribe((data) => {
			this.sendCodeForm = data?.formValue;
			this.sendCodeStatus = data?.sendstatus;
		});
	}
}
