<div class="p-2">
	<div class="row">
		<div class="col-12 mb-3">
			<div class="d-flex justify-content-end">
				<button class="add-attachment-btn" (click)="add_attachments()">
					<i class="pi pi-plus"></i>
					<span>{{ "lang_add" | language }}</span>
				</button>
			</div>
		</div>
		<div class="col-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>Title</td>
							<td>Date</td>
							<td>Action</td>
						</tr>
					</thead>
					<tbody *ngIf="contract_attachments?.length" class="AppendList">
						<ng-container
							*ngFor="
								let list of contract_attachments;
								let attachment_index = index;
								let odd = odd;
								let even = even
							"
						>
							<tr>
								<td>{{ attachment_index + 1 }}</td>
								<td>{{ list.file_name_en }}</td>
								<td>{{ list.insert_date }}</td>
								<td>
									<i
										class="fa fa-trash red mr-1 ml-1"
										(click)="deleteAttachment(list.file_records_id)"
										matTooltip="{{ 'lang_delete' | language }}"
									></i>
									<i
										class="fa fa-download mnabricon mr-1 ml-1"
										(click)="downloadAttachment(list.path)"
										matTooltip="{{ 'lang_download_file' | language }}"
									></i>
								</td>
							</tr>
						</ng-container>
					</tbody>
					<tbody class="AppendList" *ngIf="!contract_attachments?.length">
						<tr>
							<td colspan="4">{{ "lang_no_data" | language }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
