<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_team_users" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="form">
        <div class="modal-body">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                    <label for="director_position_id">{{'lang_director_position' | language }}:<i class="red"> * </i></label>
                    <ng-select 
                        [items]="positions" 
                        [multiple]="false" 
                        bindLabel="label" 
                        bindValue="value" 
                        appendTo="body" 
                        [searchFn]="ds.customSearchFn"
                        clearAllText="Clear"
                        formControlName="director_position_id"
                        placeholder="{{'lang_director_position' | language}}">
                    </ng-select>
                    <mat-error *ngIf="form.controls['director_position_id'].touched && form.controls['director_position_id'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                    <label for="project_supervision_director">{{'lang_project_supervision_director' | language }}:<i class="red"> * </i></label>
                    <ng-select 
                            [items]="users" 
                            [multiple]="false" 
                            bindLabel="label" 
                            bindValue="value" 
                            clearAllText="Clear"
                            formControlName="project_supervision_director"
                            placeholder="{{'lang_project_supervision_director' | language}}"
                            (search)="search_users($event)"
                            [searchFn]="ds.customSearchFn"
                            (focus)="users = []">
                    </ng-select>
                    <mat-error *ngIf="form.controls['project_supervision_director'].touched && form.controls['project_supervision_director'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                    <label for="manager_position_id">{{'lang_manager_position' | language }}:<i class="red"> * </i></label>
                    <ng-select 
                        [items]="positions" 
                        [multiple]="false" 
                        bindLabel="label" 
                        bindValue="value" 
                        appendTo="body" 
                        [searchFn]="ds.customSearchFn"
                        clearAllText="Clear"
                        formControlName="manager_position_id"
                        placeholder="{{'lang_director_position' | language}}">
                    </ng-select>
                    <mat-error *ngIf="form.controls['manager_position_id'].touched && form.controls['manager_position_id'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                    <label for="project_supervision_manager">{{'lang_project_supervision_managers' | language }}:<i class="red"> * </i></label>
                    <ng-select 
                            [items]="users" 
                            [multiple]="false" 
                            bindLabel="label" 
                            bindValue="value" 
                            clearAllText="Clear"
                            formControlName="project_supervision_manager"
                            placeholder="{{'lang_project_supervision_managers' | language}}"
                            (search)="search_users($event)"
                            (focus)="users = []"
                            [searchFn]="ds.customSearchFn"
                        >
                    </ng-select>
                    <mat-error *ngIf="form.controls['project_supervision_manager'].touched && form.controls['project_supervision_manager'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
            </div>
            <div class="itemsdata">
                <label> {{'lang_team_users' | language }} <i class="red"> * </i></label>
                <div class="TableParts">
                    <div class="table-responsive customResponsive mb-10 tablefields">
                        <table border="1" class="table CustomTable text-center">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td><i class="fa fa-plus-square fa-icon" matTooltip="{{'lang_add' | language }}" (click)="add_new_items();"></i></td>
                                    <td>{{'lang_position' | language }}<i class="red"> * </i></td>
                                    <td>{{'lang_team_users' | language }}<i class="red"> * </i></td>
                                </tr>
                            </thead>
                            <tbody formArrayName="teams" class="AppendList">
                                <tr *ngFor="let item of items_group().controls; let itemIndex = index;let odd=odd; let even=even" [formGroupName]="itemIndex" style="background: #ffffff;">
                                    <td class="verticalalignmid">{{itemIndex+1}}</td>
                                    <td class="verticalalignmid"><i (click)="removeItems(itemIndex);" class="fa fa-minus-square fa-icon mnabricon red" matTooltip="{{'lang_delete' | language }}"></i></td>
                                    <td width="40%">
                                        <ng-select 
                                            [items]="positions" 
                                            [multiple]="false" 
                                            bindLabel="label" 
                                            bindValue="value" 
                                            appendTo="body" 
                                            [searchFn]="ds.customSearchFn"
                                            clearAllText="Clear"
                                            formControlName="project_settings_id"
                                            placeholder="{{'lang_position' | language}}">
                                        </ng-select>
                                        <mat-error *ngIf="submitted && itemsgroup.controls[itemIndex].get('project_settings_id').errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </td>
                                    <td width="40%">
                                        <ng-select 
                                            [items]="users" 
                                            [multiple]="true" 
                                            bindLabel="label" 
                                            bindValue="value" 
                                            clearAllText="Clear"
                                            [searchFn]="ds.customSearchFn"
                                            appendTo="body" 
                                            formControlName="project_user_group_user_id"
                                            placeholder="{{'lang_team_users' | language}}"
                                            (search)="search_users($event)"
                                            (focus)="users = []"
                                        >
                                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                            <span >{{ item.label}} ({{ item.value}})</span>
                                        </ng-template>
                                        </ng-select>
                                        <mat-error *ngIf="submitted && itemsgroup.controls[itemIndex].get('project_user_group_user_id').errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
    <div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
            (click)="submit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
