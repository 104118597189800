<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_add" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="p-2">
		<div class="row">
			<div class="col-12">
				<div class="form-group">
					<label
						>{{ "lang_users" | language }} :<span class="red">*</span></label
					>
					<ng-select
						[items]="userList"
						[multiple]="true"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						[searchFn]="ds.customSearchFn"
						appendTo="body"
						[(ngModel)]="users"
						placeholder="{{ 'lang_users' | language }}"
						(search)="search_users($event)"
						(focus)="users = []"
					>
					</ng-select>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="addGroupUser()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
