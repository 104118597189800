import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
declare var $:any;
@Component({
  selector: 'app-formct1-add',
  templateUrl: './formct1-add.component.html',
  styleUrls: ['./formct1-add.component.scss']
})
export class Formct1AddComponent implements OnInit {
  @Output() contractEvent = new EventEmitter();
  @Input() employee_data;
  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
  @Input() branch_id;
  form:FormGroup;
  other_allownces_datas:FormArray;
  other_terms_datas:FormArray;
  lang_key          = localStorage.getItem('lang_key') || 'en';
  submitted         = false;
  cperiods:any      = [];
  pperiods:any      = [];
  vacationdays:any  = [];
  workingdays:any   = [];
  workinghours:any  = [];
  languages:any     = [];
  users:any         = [];
  markstatus        = false;
  reviewstatus      = false;
  otherallowances   = false;
  otherterms        = false;
  showmarkdelete    = true;
  showreviewdelete  = true;
  probationenddate  = '';
  net_amount:any    = 0.00;
  vacation_custom_date = "";
  constructor(public ds:DataService, public datepipe : DatePipe , public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.spinner.show();
    this.load_required_datas();
    this.form = new FormGroup({
      subject                           : new FormControl(this.lang.transform('lang_create_contract'), [Validators.required]),
      transaction_from                  : new FormControl({value : this.employee_data.branch_name, disabled : true}, [Validators.required]),
      transaction_to                    : new FormControl(this.employee_data.branch_id, [Validators.required]),
      work_domain                       : new FormControl(this.employee_data.work_domain, [Validators.required]),
      work_location                     : new FormControl(this.employee_data.work_location, [Validators.required]),
      work_type                         : new FormControl(this.employee_data.work_type, [Validators.required]),
      employee_number                   : new FormControl(this.employee_data.employee_number, [Validators.required]),
      joining_date_english              : new FormControl('', [Validators.required]),
      joining_date_arabic               : new FormControl(null, [Validators.required]),
      probation_period                  : new FormControl(null, [Validators.required]),
      contract_start_date_english       : new FormControl('', [Validators.required]),
      contract_period                   : new FormControl(null, [Validators.required]),
      contract_end_date_english         : new FormControl({value : '', disabled : true}, [Validators.required]),
      vacation_paid_days                : new FormControl(null, [Validators.required]),
      vacation_custom_date              : new FormControl(''),
      working_days_per_week             : new FormControl(null, [Validators.required]),
      working_hours_per_day             : new FormControl(null, [Validators.required]),     
      basic_salary                      : new FormControl('', [Validators.required]),
      home_allowance                    : new FormControl(''),
      signature_a_title                 : new FormControl('', [Validators.required]),
      signature_a_user_id               : new FormControl(null, [Validators.required]),
      signature_b_title                 : new FormControl(''),
      signature_b_user_id               : new FormControl(null),
      signature_c_title                 : new FormControl({value:'', disabled:true}),
      signature_c_user_id               : new FormControl({value:null, disabled:true}),
      needMark                          : new FormControl('0', [Validators.required]),
      marks                             : new FormControl(null),
      needReview                        : new FormControl('0', [Validators.required]),
      reviews                          : new FormControl(null),
      needOtherAllowances               : new FormControl('0', [Validators.required]),
      other_allowances                  : this.fb.array([]),
      needOtherTerms                    : new FormControl('0', [Validators.required]),
      other_terms                       : this.fb.array([]),
    });
    this.other_allownces_datas          = this.form.get("other_allowances") as FormArray;
    this.other_terms_datas              = this.form.get("other_terms") as FormArray;
  }
  public isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44 ||charCode == 190 || charCode == 46) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57 || charCode == 101)) {
			return false;
		}
		return true;
	}
  
  public load_required_datas() {
    let param = new FormData();
    param.append('lang_key', this.lang_key);
    param.append('employee_number', this.employee_data.employee_number);
    let url = [
      'form/FORM_CT1/ctperiods',
      'form/FORM_CT1/pbperiods',
      'form/FORM_CT1/vacationdays',
      'form/FORM_CT1/weekdays',
      'form/FORM_CT1/workinghours',
      'form/FORM_CT1/appusers'
    ];
    this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(res => {
      this.spinner.hide();
      if(res[0].status) {
        this.cperiods = res[0].records;
      }
      if(res[1].status) {
        this.pperiods = res[1].records;
      }
      if(res[2].status) {
        this.vacationdays = res[2].records;
      }
      if(res[3].status) {
        this.workingdays = res[3].records;
      }
      if(res[4].status) {
        this.workinghours = res[4].records;
      }
      if(res[5].status) {
        this.users = res[5].records;
        let marks = [];
        let reviews = [];
        this.users.forEach((v, k) => {
          if(v.position == "A" && v.value) {
            this.form.get('signature_a_title').setValue(v.title);
            this.form.get('signature_a_user_id').setValue(v.value);
          }
          if(v.position == "B" && v.value) {
            this.form.get('signature_b_title').setValue(v.title);
            this.form.get('signature_b_user_id').setValue(v.value);
          }
          if(v.position == "C" && v.value) {
            this.form.get('signature_c_title').setValue(v.title);
            this.form.get('signature_c_user_id').setValue(v.value);
          }
          if(v.position == "M" && v.value) {
            marks.push(v.value);
          }
          if(v.position == "R" && v.value) {
            reviews.push(v.value);
          }
        });
        if(this.form.get('signature_b_user_id').value) {

        } else {
          this.form.get('signature_b_title').setValue('');
          this.form.get('signature_b_title').enable();
          this.form.get('signature_b_user_id').setValue(null);
          this.form.get('signature_b_user_id').enable();
        }
        if(marks.length > 0) { // marks
          this.markstatus         = true;
          this.showmarkdelete     = false;
          this.form.get('marks').setValue(marks);
          this.form.get('needMark').setValue('1');
          //this.form.get('marks').disable();
        } else {
          this.showmarkdelete     = true;
          this.markstatus         = false;
          this.form.get('marks').setValue(null);
          this.form.get('marks').enable();
          this.form.get('needMark').setValue('0');
        }
        if(reviews.length > 0) { // reviews
          this.reviewstatus       = true;
          this.showreviewdelete   = false;
          this.form.get('reviews').setValue(reviews);
          this.form.get('needReview').setValue('1');
          //this.form.get('reviews').disable();
        } else {
          this.reviewstatus       = false;
          this.showreviewdelete   = true;
          this.form.get('reviews').setValue(null);
          this.form.get('reviews').enable();
          this.form.get('needReview').setValue('0');
        }
      }
    }, error => {
      this.spinner.hide();
    });
  }
  //Other Allowances Start
  get othersgroup() {
		return this.form.get("other_allowances") as FormArray;
	}
	public others_group() {
		return this.form.get("other_allowances") as FormArray;
	}
	public add_new_others() {
		if (this.other_allownces_datas.length < 5) {
			this.other_allownces_datas.push(this.other_records());
		}
  }
  public other_records(): FormGroup {
		return this.fb.group({
			other_allowance_description_en       : new FormControl('', [Validators.required]),
      other_allowance_amount            : new FormControl('', [Validators.required]),
      other_allowance_description_ar       : new FormControl('', [Validators.required]),
		});
	}
	public remove_other_items(index) {
		if (this.other_allownces_datas.length > 1) {
			this.other_allownces_datas.removeAt(index);
		}
  }
  //Other Allowances End
  //Other Terms & Conditions Start
  get termsgroup() {
    return this.form.get("other_terms") as FormArray;
  }
  public terms_group() {
    return this.form.get("other_terms") as FormArray;
  }
  public add_new_terms() {
    if (this.other_terms_datas.length < 20) {
        this.other_terms_datas.push(this.terms_records());
    }
  }
  public terms_records(): FormGroup {
    return this.fb.group({
        terms_description_english       : new FormControl('', [Validators.required]),
        terms_description_arabic        : new FormControl('', [Validators.required]),
    });
  }
  public remove_terms_items(index) {
    if (this.other_terms_datas.length > 1) {
        this.other_terms_datas.removeAt(index);
    }
  }
  public calculate_net_amount() {
    let amount:any = 0;
    let basic_salary:any    = parseFloat(this.form.get('basic_salary').value || 0);
    let home_allowance:any  = parseFloat(this.form.get('home_allowance').value || 0);
    this.other_allownces_datas.value.forEach((v, k) => {
      amount += parseFloat(v.other_allowance_amount || 0);
    });
    this.net_amount = (parseFloat(basic_salary) + parseFloat(home_allowance) + parseFloat(amount)).toFixed(2);
  }
  //Other Terms & Conditions End
  public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append('search', key.term);
		param.append('lang_key', this.lang_key);
		param.append('user_type_id', '1');
		this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.users = res.list;
			}
		})
	}
  public enable_field(field) {
		if (field == 'mark') {
      this.form.get('needMark').setValue('1');
		  this.form.get('marks').setValidators(Validators.required);
		  this.form.get('marks').updateValueAndValidity();
		  this.markstatus = true;
		} else if (field == 'review') {
      this.form.get('needReview').setValue('1');
		  this.form.get('reviews').setValidators(Validators.required);
		  this.form.get('reviews').updateValueAndValidity();
		  this.reviewstatus = true;
		} else if (field == 'allowances') {
      this.otherallowances = true;
      this.other_allownces_datas.clear();
      this.form.get('needOtherAllowances').setValue('1');
			this.add_new_others();
		} else if (field == 'terms') {
      this.otherterms = true;
      this.other_terms_datas.clear();
      this.form.get('needOtherTerms').setValue('1');
			this.add_new_terms();
		}
	}
	public disable_field(field) {
		if (field == 'mark') {
      this.form.get('needMark').setValue('0');
			this.form.get('marks').setValue(null);
			this.form.get('marks').clearValidators();
			this.form.get('marks').updateValueAndValidity();
			this.markstatus = false;
		} else if (field == 'review') {
      this.form.get('needReview').setValue('0');
			this.form.get('reviews').setValue(null);
			this.form.get('reviews').clearValidators();
			this.form.get('reviews').updateValueAndValidity();
			this.reviewstatus = false;
		} else if (field == 'allowances') {
      this.form.get('needOtherAllowances').setValue('0');
      this.other_allownces_datas.clear();
		  this.otherallowances = false;
		} else if (field == 'terms') {
      this.form.get('needOtherTerms').setValue('0');
      this.other_terms_datas.clear();
      this.otherterms = false;
		}
		this.form.updateValueAndValidity();
  }
  public calculate_contract_enddate() {
    this.form.get('contract_end_date_english').setValue('');
    if(this.form.get('contract_start_date_english').value && this.form.get('contract_period').value && this.form.get('contract_period').value !='0') {
      let date = new Date(this.form.get('contract_start_date_english').value);
      let finaldate = date.setFullYear(date.getFullYear() + parseInt(this.form.get('contract_period').value));
      let Edate = new Date(this.datepipe.transform(new Date(finaldate), 'yyyy/MM/dd'));
      this.form.get('contract_end_date_english').setValue(this.datepipe.transform( Edate.setDate(date.getDate() - 1), 'yyyy/MM/dd'));
    }
  }
  public probation_enddate() {
    this.probationenddate = "";
    if(this.form.get('joining_date_english').value && this.form.get('contract_start_date_english').value != undefined && this.form.get('probation_period').value && this.form.get('probation_period').value != '0') {
      let date = new Date(this.form.get('joining_date_english').value);
      let finaldate = date.setMonth(date.getMonth() + parseInt(this.form.get('probation_period').value));
      this.probationenddate = this.datepipe.transform(new Date(finaldate), 'yyyy/MM/dd');
    }
  }
  public vacation_enddate() {
    //this.form.get('vacation_custom_date').setValue('');
    this.vacation_custom_date = "";
    if(this.form.get('contract_start_date_english').value && this.form.get('vacation_paid_days').value && this.form.get('vacation_paid_days').value != undefined) {
      let date = new Date(this.form.get('contract_start_date_english').value);
      let vdays = this.form.get('vacation_paid_days').value.split('-');
      let finaldate1 = new Date(date.setFullYear(date.getFullYear() + parseInt(vdays[1])));
      let cperiod = this.form.get('contract_period').value || 0;
      if( (parseInt(cperiod) >= parseInt(vdays[1]) )|| parseInt(cperiod) == 0) {
        let finaldate = finaldate1.setDate(date.getDate() - parseInt(vdays[0]));
        this.vacation_custom_date = this.datepipe.transform(new Date(finaldate), 'yyyy/MM/dd')
      } else {
        this.ds.dialogf('', this.lang.transform('lang_valida_vacation_days'));
      }
    }
  }
  public preview_form() {
    this.alert.clear();
    this.submitted = true;
    if(this.form.valid) {
      this.submitted = false; 
      this.spinner.show();
      this.ds.postActionByUrl(this.getformdata(),'form/FORM_CT1/pr/0').subscribe(data => {
        this.spinner.hide();
        if(data.status) {
          let id = "showPDFPreview";
            if (data.extension != 'zip') {
              ($('#' + id) as any).modal({
                backdrop: 'static',
                keyboard: false
              });
              ($('#' + id) as any).modal('show');
            }
            document.querySelector("iframe").src = 'data:' + data['content-type'] + ';base64,' + data.base64;
        } else {
            this.alert.error(data.error);
        }
      }, error => {
        this.spinner.hide();
            this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.alert.error(this.lang.transform('lang_fill_required_fields'));
    }
  }
  public submit() {
    this.alert.clear();
    this.submitted = true;
    if(this.form.valid) { this.submitted = false; }
    this.spinner.show();
    this.ds.postActionByUrl(this.getformdata(),'form/FORM_CT1/cr/0').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
          //this.alert.success(res.msg);
          this.alert.success(this.lang.transform('lang_success'));
          this.form.reset();
          setTimeout(() => {
            this.router.navigate(['/transactions/'+res.url]);
          }, 1000);
      } else {
          this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
          this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  private getformdata() {
    let param = this.ds.json2formdata(this.form.value);    
    param.append('contract_start_date_english', this.form.get('contract_start_date_english').value || '');
    param.append('vacation_custom_date', this.form.get('vacation_custom_date').value || '');
    param.append('signature_a_title', this.form.get('signature_a_title').value|| '');
    param.append('signature_a_user_id', this.form.get('signature_a_user_id').value|| '');
    param.append('signature_b_title', this.form.get('signature_b_title').value || '');
    param.append('signature_b_user_id', this.form.get('signature_b_user_id').value || '');
    return param;
  }
}