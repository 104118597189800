import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-project-zones",
  templateUrl: "./project-zones.component.html",
  styleUrls: ["./project-zones.component.scss"],
})
export class ProjectZonesComponent implements OnInit {
  @Input() report_data;
  constructor() {}

  ngOnInit(): void {}
}
