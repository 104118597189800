import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { RoutesRoutingModule } from "./routes-routing.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./sessions/login/login.component";
import { ThemeModule } from "@theme/theme.module";
import { SigninComponent } from "./sessions/signin/signin.component";
import { ForgetComponent } from "./sessions/forget/forget.component";
import { RegistrationComponent } from "./sessions/registration/registration.component";
import { ResetComponent } from "./sessions/reset/reset.component";
import { SeditComponent } from "./sessions/sedit/sedit.component";
import { SignupComponent } from "./sessions/signup/signup.component";
import { StatusComponent } from "./sessions/status/status.component";
import { SviewComponent } from "./sessions/sview/sview.component";
import { Rstep1Component } from "./sessions/steps/rstep1/rstep1.component";
import { Rstep2Component } from "./sessions/steps/rstep2/rstep2.component";
import { Rstep3Component } from "./sessions/steps/rstep3/rstep3.component";
import { Rstep4Component } from "./sessions/steps/rstep4/rstep4.component";
import { Rstep5Component } from "./sessions/steps/rstep5/rstep5.component";
import { Rstep6Component } from "./sessions/steps/rstep6/rstep6.component";
import { Rstep7Component } from "./sessions/steps/rstep7/rstep7.component";
import { Rstep8Component } from "./sessions/steps/rstep8/rstep8.component";
import { Rstep9Component } from "./sessions/steps/rstep9/rstep9.component";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "app/custom-material-config";
import { McalendarModule } from "./mcalendar/mcalendar.module";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { ConfirmJopOfferComponent } from "./sessions/sview/confirm-jop-offer/confirm-jop-offer.component";
import { ExternalPersonLoginComponent } from "./sessions/external-person-login/external-person-login.component";
import { EpTicketListComponent } from "./tasks/ep-ticket-list/ep-ticket-list.component";
import { EpTicketViewComponent } from "./tasks/ep-ticket-list/ep-ticket-view/ep-ticket-view.component";
import { EpTaskViewComponent } from "./tasks/ep-ticket-list/ep-task-view/ep-task-view.component";
import { EpCheckpointDetailsViewComponent } from "./tasks/ep-ticket-list/ep-task-view/ep-checkpoint-details-view/ep-checkpoint-details-view.component";
import { ProjectSupervisionDashboardComponent } from "./dashboard/project-supervision-dashboard/project-supervision-dashboard.component";
import { NahidhOrgChartComponent } from "./dashboard/nahidh-org-chart/nahidh-org-chart.component";
import { OrganizationChartModule } from "primeng/organizationchart";
import { TasksModule } from "./tasks/tasks.module";
import { TransactionsModule } from "./transactions/transactions.module";

const COMPONENTS = [
	ConfirmJopOfferComponent,
	DashboardComponent,
	LoginComponent,
	ExternalPersonLoginComponent,
	EpTicketListComponent,
	EpTicketViewComponent,
	EpTaskViewComponent,
	EpCheckpointDetailsViewComponent,
	SigninComponent,
	RegistrationComponent,
	StatusComponent,
	ResetComponent,
	ForgetComponent,
	SignupComponent,
	SviewComponent,
	SeditComponent,
	Rstep1Component,
	Rstep2Component,
	Rstep3Component,
	Rstep4Component,
	Rstep5Component,
	Rstep6Component,
	Rstep7Component,
	Rstep8Component,
	Rstep9Component,
];
const COMPONENTS_DYNAMIC = [];
@NgModule({
	imports: [
		SharedModule,
		RoutesRoutingModule,
		ThemeModule,
		McalendarModule,
		SlickCarouselModule,
		OrganizationChartModule,
		TasksModule,
		TransactionsModule,

	],
	declarations: [
		...COMPONENTS,
		...COMPONENTS_DYNAMIC,
		ProjectSupervisionDashboardComponent,
		NahidhOrgChartComponent,
	],
	entryComponents: COMPONENTS_DYNAMIC,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [
		{ provide: DateAdapter, useClass: AppDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
	],
})
export class RoutesModule {}
