import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PreloaderService } from "@core";
import moment from "moment";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-form-hrpr",
  templateUrl: "./form-hrpr.component.html",
  styleUrls: ["./form-hrpr.component.scss"],
})
export class FORMHRPRComponent implements OnInit {
  public bid = this.route.snapshot.paramMap.get("bid");
  public pid = this.route.snapshot.paramMap.get("id");
  users: any = [];
  history = [];
  pageNo = 1;
  total_records = 0;
  pageSize = "10";
  log_action = null;
  @Output() contractEvent = new EventEmitter();
  @Output() pageback = new EventEmitter();
  @Input() account_operation: string;
  @Input() employee_data;
  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
  @Input() branch_id;
  @Input() contract_type;
  @Input() contract_data;
  invoiceList: any = [];
  contract_types: any = [];
  lang_key = localStorage.getItem("lang_key") || "en";
  form: FormGroup;
  branches: any = [];
  contract_termination_list: any = [];
  submitted: boolean = false;
  dateRange: FormGroup;
  constructor(
    public spinner: PreloaderService,
    public ds: DataService,
    public alert: AlertService,
    public lang: LanguagePipe,
    public router: Router,
    public route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private sweetAlert: SweetAlertService,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.dateRange = new FormGroup({
      project_start_date: new FormControl(null),
      project_end_date: new FormControl(null),
    });
    this.buildform();
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  show_contracts() {}
  public buildform() {
    this.form = new FormGroup({
      employee_number: new FormControl("", [Validators.required]),
      project_name_arabic: new FormControl("", [Validators.required]),
      project_name_english: new FormControl(null, Validators.required),
      note: new FormControl(null),
      project_start_date: new FormControl(null),
      project_end_date: new FormControl(null),
    });
  }
  showMsg(msg: string, action: string, className: string) {
    this.snackBar.open(msg, action, {
      duration: 4000,
      panelClass: [className],
    });
  }
  public search_users(key) {
    if (key) {
      this.users = [];
      let param = new FormData();
      param.append("search", key.term);
      param.append("lang_key", this.lang_key);
      param.append("user_type_id", "1");
      this.ds
        .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
        .subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            this.users = res.list;
          }
        });
    }
  }
  public create_project() {
    this.spinner.show();
    let param = new FormData();
    param.set("employee_number", this.employee_data.employee_number);
    param.set(
      "project_name_arabic",
      this.form.get("project_name_arabic").value
    );
    param.set(
      "project_name_english",
      this.form.get("project_name_english").value
    );
    param.set("note", this.form.get("note").value);
    param.append(
      "project_start_date",
      this.form.get("project_start_date").value
        ? moment(this.form.get("project_start_date").value).format("YYYY/MM/DD")
        : ""
    );
    param.append(
      "project_end_date",
      this.form.get("project_end_date").value
        ? moment(this.form.get("project_end_date").value).format("YYYY/MM/DD")
        : ""
    );
    this.spinner.show();
    this.subscriptions.add(
      this.ds
        .postActionByUrl(param, "human_resources/create_hr_projects")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.alert.success(res.msg);
              this.pageback.emit("");
              this.form.reset();
            } else {
              this.alert.error(res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }
}
