import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { AlertService } from "../alert/alert.service";

@Component({
	selector: "app-show-platform-access-codes",
	templateUrl: "./show-platform-access-codes.component.html",
	styleUrls: ["./show-platform-access-codes.component.scss"],
})
export class ShowPlatformAccessCodesComponent implements OnInit {
	platformAccessCodes: any = [];
	platformAccessCodesMsg = this.lang.transform("lang_loading");
	transaction_request_id;
	page: any = 1;
	size: any = "10";
	total_records: any = 0;
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public router: Router,
		private dialogRef: MatDialogRef<ShowPlatformAccessCodesComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.transaction_request_id = data?.transaction_request_id;
	}

	ngOnInit(): void {
		this.getPlatformCodes();
	}

	getPlatformCodes() {
		this.spinner.show();
		this.ds
			.get(
				`form/FORM_WIR/check_platform_access_codes/${this.transaction_request_id}/${this.page}/${this.size}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.platformAccessCodes = res?.records;
						this.total_records = res?.page?.total_records;
					} else {
						this.platformAccessCodes = [];
						this.platformAccessCodesMsg = res?.error;
						this.total_records = 0;
					}
				},
				(err) => {
					this.spinner.hide();
					this.platformAccessCodesMsg = this.lang.transform(
						"lang_internal_server_error"
					);
				}
			);
	}
}
