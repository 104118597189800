import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { QpAddEditComponent } from "./qp-add-edit/qp-add-edit.component";

@Component({
	selector: "app-quotation-positions",
	templateUrl: "./quotation-positions.component.html",
	styleUrls: ["./quotation-positions.component.scss"],
})
export class QuotationPositionsComponent implements OnInit {
	searchKey = "";
	pageno = 1;
	pagesize = "10";
	collectionSize: any;
	positionList = [];
	nodata: string = this.lang.transform("lang_loading");
	constructor(
		private ds: DataService,
		private spinner: PreloaderService,
		private lang: LanguagePipe,
		private sweetAlert: SweetAlertService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.getPositionsList();
	}

	getPositionsList() {
		this.spinner.show();
		// let formData = new FormData();
		// formData.append("search_key", this.searchKey || "");
		this.ds
			.get(`quotation_setting/get_quotation_position_settings`)
			.subscribe((res: any) => {
				this.spinner.hide();
				if (res.status) {
					this.positionList = res?.records;
					// this.collectionSize = res?.page?.total_records;
				} else {
					this.positionList = [];
					// this.collectionSize = 0;
					this.nodata = res.error;
				}
			});
	}
	onAddEditPosition(data?) {
		const dialogRef = this.dialog.open(QpAddEditComponent, {
			width: "500px",
			data: {
				position_data: data,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.getPositionsList();
			}
		});
	}

	deletePosition(id) {
		this.sweetAlert
			.confirmMessage(
				this.lang.transform("lang_are_you_sure"),
				this.lang.transform("lang_yes")
			)
			.then((resConfirm) => {
				if (resConfirm.isConfirmed) {
					this.spinner.show();
					this.ds
						.post(
							`quotation_setting/delete_quotation_position_setting/${id}`,
							{}
						)
						.subscribe((res) => {
							this.spinner.hide();
							if (res.status) {
								this.sweetAlert.successToast(
									this.lang.transform("lang_success"),
									2000
								);
								this.getPositionsList();
							} else {
								this.sweetAlert.errorToast(res.error, 2000);
							}
						});
				}
			});
	}
}
