import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddUpdateContractComponent } from "./add-update-contract/add-update-contract.component";

@Component({
	selector: "app-design-contract",
	templateUrl: "./design-contract.component.html",
	styleUrls: ["./design-contract.component.scss"],
})
export class DesignContractComponent implements OnInit {
	@Input() project_data;
	@Input() design_data;
	@Input() service_data;
	@Input() filelevels;
	@Input() able2add;
	@Input() able2edit;
	@Input() able2delete;
	@Input() isQuotationExists;
	contractList = [];
	contract_pageno = 1;
	contract_pagesize = "10";
	collectionSize;
	constructor(
		public ds: DataService,
		private lang: LanguagePipe,
		private fb: FormBuilder,
		private dialog: MatDialog,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService
	) {}

	ngOnInit(): void {}

	selectedTabValue(e) {
		if (e?.tab?.textLabel == "generate_contract") {
		} else if (e?.tab?.textLabel == "upload_contract") {
			if (this.contractList.length < 1) {
				this.getContractList();
			}
		}
	}
	selectedSubtab(e) {}

	toggleContractor(index) {
		for (let i = 0; i < this.contractList.length; i++) {
			if (i != index) {
				this.contractList[i].expanded = false;
			}
		}
		this.contractList[index].expanded = !this.contractList[index].expanded;
	}

	getContractList() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("project_design_id", this.design_data?.project_design_id);
		this.ds
			.post(
				`project_design/get_data_project_design_contract/${this.contract_pageno}/${this.contract_pagesize}`,
				formData
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.collectionSize = res?.page?.total_records;
						this.contractList = res.data.map((item) => {
							return { ...item, expanded: false };
						});
					} else {
						this.collectionSize = 0;
						this.contractList = [];
						this.sweetAlert.errorToast(res.error, 2000);
					}
				},
				(error) => {
					this.spinner.hide();
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}

	onAddUpdateContract(event?, data?) {
		if (event) {
			event?.stopPropagation();
		}
		const dialogRef = this.dialog.open(AddUpdateContractComponent, {
			width: "800px",
			data: {
				project_design_id: this.design_data?.project_design_id,
				update_data: data,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.getContractList();
			}
		});
	}

	changeContractStatus(event, contract, status) {
		event?.stopPropagation();
		this.spinner.show();
		let formData = new FormData();
		formData.append("contract_id", contract?.contract_id || "");
		formData.append("status", status == "open" ? "1" : "3" || "");
		this.ds.post(`project_design/change_status_contract`, formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successToast(res.message, 2000);
					this.getContractList();
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(error) => {
				this.spinner.hide();
				this.ds.dialogf(
					"",
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}
}
