import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sqr-step2',
  templateUrl: './sqr-step2.component.html',
  styleUrls: ['./sqr-step2.component.scss']
})
export class SqrStep2Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  stepName = 'Contractor_Team_Approval';
  form_request_id   = this.route.snapshot.paramMap.get('form_request_id');
  form_key2          = this.route.snapshot.paramMap.get('form_key');
  rejectedValidators = [];
  form:FormGroup;

  constructor(public ds: DataService, private sweetAlert:SweetAlertService, public router: Router, public route: ActivatedRoute, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id                   : new FormControl(this.form_request_id, Validators.required),
      contractor_approval_status               : new FormControl('', [Validators.required]),
      contractor_rejected_notes                : new FormControl('', this.rejectedValidators)
    });
  }
  checkStatus() {
    if(this.form.get('contractor_approval_status').value == 'Return'){
      this.rejectedValidators=[Validators.required]
    } else {
      this.rejectedValidators=[]
    }
  }

  completeStep2() {
    if(this.form.valid) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('transaction_request_id',this.form.value.transaction_request_id);
      formData.append('contractor_approval_status',this.form.value.contractor_approval_status);
      if(this.form.value.contractor_approval_status == "Return") {
        formData.append('contractor_rejected_notes',this.form.value.contractor_rejected_notes);
      }
      this.ds.post(`form/${this.form_key}/Contractor_Team_Approval/0`, formData).subscribe(data => {
        if(data.status) {
          this.spinner.hide();
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
          setTimeout(() => {
            this.refreshComponent.emit(true);
          }, 2000);
        } else {
          this.spinner.hide();
          this.alert.error(data?.error);
        }
      }, (error) => {
        this.spinner.hide();
          this.alert.error(error);
      })
    } else {
      this.alert.error(this.lang.transform('lang_all_fields_required'));
    }
  }
}
