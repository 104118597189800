<div mat-dialog-title class="d-flex justify-content-between">
	<h4 *ngIf="!edit_mode">{{ "lang_add" | language }}</h4>
	<h4 *ngIf="edit_mode">{{ "lang_edit" | language }}</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="form">
		<div class="p-2">
			<div class="row">
				<div class="col-12 col-sm-6 mb-2">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_name" | language }}</mat-label>
						<input
							matInput
							placeholder="{{ 'lang_name' | language }}"
							formControlName="person_name"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6 mb-2">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_email" | language }}</mat-label>
						<input
							matInput
							placeholder="{{ 'lang_email' | language }}"
							formControlName="email"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6 mb-2">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_mobile_number" | language }}</mat-label>
						<input
							matInput
							placeholder="{{ 'lang_mobile_number' | language }}"
							formControlName="mobile_no"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6 mb-2" *ngIf="!fromTicket">
					<ng-select
						[items]="ticketList"
						[multiple]="true"
						bindLabel="ticket_titel"
						bindValue="ticket_id"
						clearAllText="Clear"
						appendTo="body"
						formControlName="ticket_id"
						placeholder="{{ 'lang_ticket' | language }}"
						(search)="getTicketList($event)"
						clearAllText="Clear"
						[searchFn]="ds.customSearchFn"
					>
					</ng-select>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-0 col-sm-9"></div>
	<div class="col-12 col-sm-3">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onSubmit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
