<form [formGroup]="form" class="form" (ngSubmit)="submit();">
    <section class="panel-expansion ckeditorhidetoolbar">
        <mat-accordion class="stylepanels">
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_new_joining' | language}} ( <span *ngIf="lang_key =='en'"> {{employee_data.firstname_english}} {{employee_data.lastname_english}}</span> <span *ngIf="lang_key =='ar'"> {{employee_data.firstname_arabic}} {{employee_data.lastname_arabic}} </span> ) </mat-expansion-panel-header>
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_subject' | language}} <span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol" readonly placeholder="{{'lang_subject' | language}}" formControlName="subject">
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_from_org' | language}} </label>
                                <input type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_from_org' | language}}" formControlName="transaction_from">
                                <mat-error *ngIf="submitted && form.controls['transaction_from'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_joining_type' | language}} <span class="red">*</span></label>
                                <select class="form-control almnabrformcontrol" formControlName="joining_type">
                                    <option value="">{{'lang_joining_type' | language}}</option>
                                    <option *ngFor="let type of joining_type" value="{{type.value}}">{{type.label}}</option>
                                </select>
                                <mat-error *ngIf="submitted && form.controls['joining_type'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>                        
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label for="joining_start_date_english">{{'lang_human_resources_joining_date_english' | language }} : <span class="red">*</span></label>
                                <div class="positionrelatiove">
                                    <input formControlName="joining_start_date_english" type="text" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event, form.get('joining_start_date_arabic'));" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
                                    <mat-datepicker-toggle matSuffix [for]="BDeng" class="posiab"></mat-datepicker-toggle>
                                    <mat-datepicker #BDeng disabled="false"></mat-datepicker>
                                    <mat-error *ngIf="submitted && form.controls['joining_start_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label for="joining_start_date_arabic">{{'lang_human_resources_joining_date_arabic' | language }} : <span class="red">*</span></label>
                                <input formControlName="joining_start_date_arabic" (ngModelChange)="ds.setARTOENDate($event, form.get('joining_start_date_english'));" ngbDatepicker [readonly]="true" (click)="bdarab.toggle()" #bdarab="ngbDatepicker" type="text" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" />
                                <mat-error *ngIf="submitted && form.controls['joining_start_date_arabic'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-10">
                        <div class="col-12 mb-10">
                            <label> {{'lang_description' | language}} <span class="red">*</span></label>
                            <textarea type="text" formControlName="joining_description" class="form-control almnabrformcontrol" rows="10"></textarea>
                            <mat-error *ngIf="submitted && form.controls['joining_description'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                        </div>
                    </div>
                    <div class="row mb-10">
                        <div class="col-md-2 col-sm-4 col-xs-12 mb-10" *ngIf="!markstatus">
                            <button type="button" class="albutton mnabrbutton mat-accent" mat-button (click)="enable_field('mark')"> <i class="fa fa-plus"></i> {{'lang_need_mark' | language}}</button>
                        </div>
                        <div class="col-md-2 col-sm-4 col-xs-12 mb-10" *ngIf="!reviewstatus">
                            <button type="button" class="albutton mnabrbutton mat-accent" mat-button (click)="enable_field('review')"> <i class="fa fa-plus"></i> {{'lang_need_review' | language}}</button>
                        </div>
                    </div>
                    <div class="row mb-10">
                        <div class="col-12">
                            <fieldset class="cardback fieldset">
                                <legend>{{'lang_signature_stamp' | language}}</legend>
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_first_party' | language}} <span class="red">*</span></label>
                                            <input type="text" formControlName="signature_a_title" placeholder="{{'lang_first_party' | language}}" class="form-control almnabrformcontrol">
                                            <mat-error *ngIf="submitted && form.controls['signature_a_title'].errors?.required"> {{'lang_field_required' | language}} </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_human_resources_job_title_iqama' | language}} B </label>
                                            <input type="text" formControlName="signature_b_title" placeholder="{{'lang_human_resources_job_title_iqama' | language}} B" class="form-control almnabrformcontrol">
                                            <mat-error *ngIf="submitted && form.controls['signature_b_title'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_second_party' | language}} <span class="red">*</span></label>
                                            <input type="text" formControlName="signature_c_title" placeholder="{{'lang_second_party' | language}}" class="form-control almnabrformcontrol">
                                            <mat-error *ngIf="submitted && form.controls['signature_c_title'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_human_resources_employee_name' | language}} A </label>
                                            <ng-select 
                                                [items]="users" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="signature_a_user_id"
                                                placeholder="{{'lang_human_resources_employee_name' | language}}"
                                                (search)="search_users($event)"
                                                (focus)="users = []">
                                                [notFoundText]="'lang_no_data' | language"
                                            </ng-select>
                                            <mat-error *ngIf="submitted && form.controls['signature_a_user_id'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_human_resources_employee_name' | language}} B </label>
                                            <ng-select 
                                                [items]="users" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="signature_b_user_id"
                                                placeholder="{{'lang_human_resources_employee_name' | language}}"
                                                (search)="search_users($event)"
                                                (focus)="users = []">
                                            </ng-select>
                                            <mat-error *ngIf="submitted && form.controls['signature_b_user_id'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_human_resources_employee_name' | language}} C </label>
                                            <ng-select 
                                                [items]="users" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="signature_c_user_id"
                                                placeholder="{{'lang_human_resources_employee_name' | language}}"
                                                (search)="search_users($event)"
                                                (focus)="users = []">
                                            </ng-select>
                                            <mat-error *ngIf="submitted && form.controls['signature_c_user_id'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row mb-10" *ngIf="markstatus || reviewstatus">
                        <div class="col-md-6 col-xs-12 col-sm-4 mb-10">
                            <fieldset class="cardback fieldset" *ngIf="markstatus">
                                <input type="hidden" formControlName="needMark">
                                <legend>{{'lang_markers' | language}} 
                                    <span class="red"> * 
                                        <mat-icon class="red mnabricon red" matTooltip="{{'lang_close' | language }}" (click)="disable_field('mark')">close</mat-icon>
                                    </span>
                                </legend>
                                <ng-select 
                                    [items]="users" 
                                    [multiple]="true" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body" 
                                    formControlName="marks"
                                    placeholder="{{'lang_markers' | language}}"
                                    (search)="search_users($event)"
                                    (focus)="users = []">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['marks'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </fieldset>
                        </div>
                        <div class="col-md-6 col-xs-12 col-sm-4 mb-10">
                            <fieldset class="cardback fieldset" *ngIf="reviewstatus">
                                <input type="hidden" formControlName="needReview">
                                <legend>{{'lang_reviewers' | language}} 
                                    <span class="red">* 
                                        <mat-icon class="red mnabricon red" matTooltip="{{'lang_close' | language }}" (click)="disable_field('review')">close</mat-icon>
                                    </span>
                                </legend>
                                <ng-select 
                                    [items]="users" 
                                    [multiple]="true" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body" 
                                    formControlName="reviews"
                                    placeholder="{{'lang_reviewers' | language}}"
                                    (search)="search_users($event)"
                                    (focus)="users = []">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['reviews'].errors?.required"> {{'lang_field_required' | language}}</mat-error>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" routerLink="/human_resources/employees" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_human_resources_all_emp' | language}}</button>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" (click)="joiningEvent.emit()" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_human_resources_emp_details' | language}}</button>
                        </div>
                        <div class="col-md-6 col-sm-2 col-xs-12 mb-10">
                            <app-alert id="default-alert"></app-alert>
                        </div>
                        <div class="col-md-1 col-sm-2 col-xs-12 mb-10">
                            
                        </div>
                        <div class="col-md-1 col-sm-2 col-xs-12 mb-10">
                            <input type="submit" value="{{'lang_save' | language}}" class="albutton mnabrbutton mat-accent" mat-button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</form>