<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4 *ngIf="!edit_mode">{{ "lang_add" | language }}</h4>
		<h4 *ngIf="edit_mode">{{ "lang_edit" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="subtaskForm">
		<div class="p-2">
			<div class="row">
				<div class="col-12 col-sm-6">
					<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
						<mat-form-field appearance="outline" style="width: 100%">
							<mat-label>Title En</mat-label>
							<input
								matInput
								placeholder="Title En"
								type="text"
								formControlName="title_english"
							/>
						</mat-form-field>
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
						<mat-form-field appearance="outline" style="width: 100%">
							<mat-label>Title Ar</mat-label>
							<input
								matInput
								placeholder="Title Ar"
								type="text"
								formControlName="title_arabic"
							/>
						</mat-form-field>
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
						<mat-form-field appearance="outline" style="width: 100%">
							<mat-label>Task Type</mat-label>
							<mat-select formControlName="task_main_id">
								<mat-option
									*ngFor="let item of setting_task_type"
									[value]="item.id"
								>
									{{ item.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
						<mat-form-field appearance="outline" style="width: 100%">
							<mat-label>Group Users</mat-label>
							<mat-select formControlName="user_group">
								<mat-option
									*ngFor="let item of group_users_list"
									[value]="item.id"
								>
									{{ item.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
						<mat-form-field appearance="outline" style="width: 100%">
							<mat-label>{{ "lang_description" | language }}</mat-label>
							<input
								matInput
								placeholder="{{ 'lang_description' | language }}"
								type="text"
								formControlName="default_description"
							/>
						</mat-form-field>
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
						<mat-form-field appearance="outline" style="width: 100%">
							<mat-label>Working Days</mat-label>
							<input
								matInput
								placeholder="Working Days"
								type="number"
								formControlName="working_days"
							/>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="addEditSubtask()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
