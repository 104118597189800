import { Component, Input, OnInit } from "@angular/core";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-bank-payments",
	templateUrl: "./bank-payments.component.html",
	styleUrls: ["./bank-payments.component.scss"],
})
export class BankPaymentsComponent implements OnInit {
	selected_tab = "payment";
	@Input() projects_work_area_id;
	@Input() projects_profile_id;
	@Input() projects_supervision_id;
	shortCodeList = [];
	constructor(
		public ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.getShortCodeList();
	}

	selectedListTabValue(e) {
		if (e?.tab?.textLabel == "payment") {
			this.selected_tab = "payment";
		}
	}

	getShortCodeList() {
		this.spinner.show();
		this.ds.get("pr/bankpay/get_bank_settings").subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.shortCodeList = res?.records;
				} else {
					this.shortCodeList = [];
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.shortCodeList = [];
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
