import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  apiurl = environment.SERVER_ORIGIN;
  @Input() able2edit;
  @Input() able2delete;
  @Input() able2add;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  @Input() types;
  @Input() requestfor;
  @Input() user_data;
  public noteform: FormGroup;
  mynotes: any = [];
  npsize: any = '10';
  npage: any = 1;
  notedata: any = [];
  noteerrormsg = this.lang.transform('lang_loading');
  noteviewdata: any = [];
  modalRefA: BsModalRef;
  modalRefV: BsModalRef;
  modalRefF: BsModalRef;
  modalRefU: BsModalRef;
  searchKey = "";
  searchStatus = "";
  expandstatus = false;
  constructor(public router:Router, public ds:DataService,public route :ActivatedRoute, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.expandstatus = (this.requestfor == 'profile') ? true : false;
    this.buildnoteform();
    this.list_of_notes();
  }
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  public openModalU(template: TemplateRef<any>) {
    this.modalRefU = this.modalService.show(template, environment.modelconfig);
  }
  public openModalFile(template: TemplateRef<any>) {
    this.modalRefF = this.modalService.show(template, environment.modelconfig);
  }
  public emptyNoteForm() {
    this.noteform.reset();
    this.noteform.patchValue({
      note_remainder_status: 0,
      show_status: 1,
      link_with_view_list: 0,
    });
  }
  public buildnoteform() {
    this.noteform = new FormGroup({
      note_id: new FormControl(''),
      note_description: new FormControl('', [Validators.required]),
      note_remainder_status: new FormControl("0", [Validators.required]),
      note_remainder_date: new FormControl(''),
      show_status: new FormControl('', [Validators.required]),
      link_with_view_list: new FormControl('', [Validators.required]),
    });
  }
  public note_operations() {
    this.spinner.show();
    if(this.noteform.get('note_id').value) {
      const formData = new FormData();
      formData.append('note_id', this.noteform.get('note_id').value);
      formData.append('note_description', this.noteform.get('note_description').value || '');
      formData.append('note_remainder_status', this.noteform.get('note_remainder_status').value);
      if(this.noteform.get('note_remainder_status').value == "1") {
        formData.append('note_remainder_date', this.noteform.get('note_remainder_date').value || '');
      }
      formData.append('show_status', this.noteform.get('show_status').value);
      formData.append('link_with_view_list', this.noteform.get('link_with_view_list').value);   
      formData.append('id', this.id);
      this.ds.putActionByUrl(this.ds.formData2string(formData), 'hr_update_notes').subscribe(data => {
        this.spinner.hide();
        if (data.status) {
          this.alert.success(data.msg);
          this.noteform.reset();
          this.list_of_notes();
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(data.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error(error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      const formData = new FormData();
      formData.append('note_description', this.noteform.get('note_description').value || '');
      formData.append('note_remainder_status', this.noteform.get('note_remainder_status').value);
      if(this.noteform.get('note_remainder_status').value == "1") {
        formData.append('note_remainder_date', this.noteform.get('note_remainder_date').value || '');
      }
      formData.append('show_status', this.noteform.get('show_status').value);
      formData.append('link_with_view_list', this.noteform.get('link_with_view_list').value);
      formData.append('id', this.id);
      this.ds.postActionByUrl(formData, 'hr_create_notes').subscribe(data => {
        this.spinner.hide();
        if (data.status) {
          this.alert.success(data.msg);
          this.noteform.reset();
          this.list_of_notes();
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(data.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error(error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    }


















  }
  public get_notes_for_edit(note) {
    this.emptyNoteForm();
    this.noteform.patchValue({
      note_id: note.note_id,
      note_description: note.note_description,
      note_remainder_status: note.note_remainder_status,
      note_remainder_date: this.ds.encon(note.note_remainder_date),
      show_status: note.show_status,
      link_with_view_list: note.link_with_view_list,
    });
  }
  public notepage(size) {
    this.npsize = size;
    this.npage = 1;
    this.list_of_notes();
  }
  public notelength(page) {
    this.npage = page;
    this.list_of_notes();
  }
  public search_my_notes() {
    this.npage = 1;
    this.npsize = '10';
    this.list_of_notes();
  }
  public list_of_notes() {
    let formData = new FormData();
    formData.append('id', this.id);
    formData.append('branch_id', this.branch_id);
    formData.append('search_key', this.searchKey || '');
    formData.append('search_status', this.searchStatus || '');
    let url = (this.requestfor !== "profile") ? 'hr_notes' : 'get_my_notes';
    this.ds.postActionByUrl(formData, url+'/'+this.npage+'/'+this.npsize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.notedata = res;
      } else {
        this.notedata = [];
        this.noteerrormsg = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.notedata = [];
      this.noteerrormsg = error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public view_my_notes(note) {
    this.noteviewdata = note;
  }
  public checkAllNote(event) {
    if (event.target.checked) {
        $(".appnotes .eachItemNote").prop("checked", true);
        $(".appnotes .makeback").addClass("bground");
        $(".appnotes .deleteIconDiv").show();
    } else {
        $(".appnotes .eachItemNote").prop("checked", false);
        $(".appnotes .makeback").removeClass("bground");
        $(".appnotes .deleteIconDiv").hide();
    }
  }
  public checkEachItemNote(event) {
      if (event.target.checked) {
          const val = event.target.value;
          $(".appnotes .removerow" + val).addClass("bground");
      } else {
          const val = event.target.value;
          $(".appnotes .removerow" + val).removeClass("bground");
      }
      const checkedtotal = [];
      $(".appnotes .eachItemNote:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      const totalrow = [];
      $(".appnotes .eachItemNote").each(function() {
          totalrow.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          $(".appnotes .deleteIconDiv").show();
      } else {
          $(".appnotes .deleteIconDiv").hide();
      }
      if (checkedtotal.length === totalrow.length) {
          $(".appnotes #allItemsNote").prop("checked", true);
      } else {
          $(".appnotes #allItemsNote").prop("checked", false);
      }
  }
  public deleteBulkDataNote() {
      const checkedtotal = [];
      $(".appnotes .eachItemNote:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          const dialogRef = this.dialog.open(ConfirmationDialog, {
              disableClose: true,
              width: '400px',
              data: {
                  title: this.lang.transform('lang_confirmation'),
                  message: this.lang.transform('lang_are_you_sure'),
              }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                  this.spinner.show()
                  this.ds.deleteActionByUrl(checkedtotal, "01f5086b879a62a05da4094dac203558/NOTE/"+this.id+'/'+this.branch_id).subscribe(
                      (data) => {
                          this.spinner.hide()
                          if (data.status) {
                              this.search_my_notes();
                              $(".appnotes #allItemsNote").prop("checked", false);
                              $(".appnotes .deleteIconDiv").hide();
                              this.ds.dialogf('', data.msg);
                          } else {
                              this.ds.dialogf('', data.error);
                          }
                      },
                      (error) => {
                          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                          this.spinner.hide()
                      }
                  );
              }
          });
      } else {
          this.ds.dialogf();
      }
  }
}
