import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cadd-edit',
  templateUrl: './cadd-edit.component.html',
  styleUrls: ['./cadd-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CaddEditComponent implements OnInit {
  @Input() divisionAction;
  @Input() fconsultants;
  @Input() consultantr;
  @Input() platforms;
  @Input() positions;
  @Input() editViewData;
  @Output() action = new EventEmitter<any>();
  form:FormGroup;
  from_consultant:FormArray;
  consultant_requirements:FormArray;
  platform_code_system:any = [];
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.form = new FormGroup({
      from_consultant : this.fb.array([]),
      consultant_requirments : this.fb.array([]),
    });
    this.from_consultant            = this.form.get("from_consultant") as FormArray;
    this.consultant_requirements    = this.form.get("consultant_requirments") as FormArray;
    if(this.divisionAction == "ADD") {
      this.from_consultant.push(this.row_records());
    } else if(this.divisionAction != "ADD" && this.editViewData && this.editViewData.projects_setting_consultant_type == "FC") {
      this.from_consultant.push(this.row_records(this.editViewData));
    } else if(this.divisionAction != "ADD" && this.editViewData && this.editViewData.projects_setting_consultant_type != "FC") {
      this.consultant_requirements.push(this.rowR_records(this.editViewData));
      this.platform_code_system = this.editViewData.platform_code_system.split(',');
    }
    if(this.divisionAction == "VIEW") {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
  // Array Start
  public rows_group() {
		return this.form.get("from_consultant") as FormArray;
	}
	get rowsgroup() {
		return this.form.get("from_consultant") as FormArray;
	}
	public add_new_rows() {
		this.from_consultant.push(this.row_records());
	}
	public remove_row(index) {
		if (this.from_consultant.length > 1) {
			this.from_consultant.removeAt(index);
		}
	}
	public row_records(value?): FormGroup {
		return this.fb.group({
      projects_setting_consultant_id                            : new FormControl((value) ? value.projects_setting_consultant_id : '', [Validators.required]),
      projects_setting_consultant_type                          : new FormControl('FC', [Validators.required]),
      projects_setting_consultant_id_linked                     : new FormControl((value) ? value.projects_setting_consultant_id_linked.split(',') : null, [Validators.required]),			
      projects_setting_consultant_position_code                 : new FormControl((value) ? value.projects_setting_consultant_position_code.split(',') : null, [Validators.required]),
      projects_setting_consultant_authendicate_position_code    : new FormControl((value) ? value.projects_setting_consultant_authendicate_position_code.split(',') : null, [Validators.required]),
      platform_code_system                                      : new FormControl((value) ? value.platform_code_system.split(',') : null, [Validators.required]),			
		});
  }
  //RowsR
  // Array Start
  public rowRs_group() {
		return this.form.get("consultant_requirments") as FormArray;
	}
	get rowRsgroup() {
		return this.form.get("consultant_requirments") as FormArray;
	}
	public add_new_rowRs() {
		this.consultant_requirements.push(this.rowR_records());
	}
	public remove_rowR(index) {
		if (this.consultant_requirements.length > 1) {
			this.consultant_requirements.removeAt(index);
		}
	}
	public rowR_records(value?): FormGroup {
    let platform_code_system = "";
    if(this.divisionAction == "ADD") {
      if(this.form.get('from_consultant').value && this.form.get('from_consultant').value.length > 0) {
        this.form.get('from_consultant').value.forEach((v, k) => {
          platform_code_system += (v.platform_code_system && v.platform_code_system.length > 0) ? v.platform_code_system.toString()+',' : '';
        });
        this.platform_code_system = (platform_code_system) ? platform_code_system.replace(/,\s*$/, "").split(',') : null;
      }
    }
		return this.fb.group({
      projects_setting_consultant_id              : new FormControl((value) ? value.projects_setting_consultant_id : '', [Validators.required]),
      projects_setting_consultant_type            : new FormControl('CR', [Validators.required]),
      projects_setting_consultant_id_linked       : new FormControl((value) ? value.projects_setting_consultant_id_linked.split(',') : null, [Validators.required]),			
      projects_setting_consultant_position_code   : new FormControl(null),
      platform_code_system                        : new FormControl({value : (value) ? value.platform_code_system.split(',') : (platform_code_system) ? platform_code_system.replace(/,\s*$/, "").split(',') : null, disabled : true}, [Validators.required]),
		});
  }
  public submit() {
    this.spinner.show();
    this.alert.clear();
    let results = [];
    if(this.form.get('from_consultant').value && this.form.get('from_consultant').value.length > 0) {
      this.form.get('from_consultant').value.forEach((v, k) => {
        v['projects_setting_consultant_id_linked']                    = (v.projects_setting_consultant_id_linked && v.projects_setting_consultant_id_linked.length > 0) ? v.projects_setting_consultant_id_linked.toString() : '';
        v['projects_setting_consultant_position_code']                = (v.projects_setting_consultant_position_code && v.projects_setting_consultant_position_code.length > 0) ? v.projects_setting_consultant_position_code.toString() : '';
        v['projects_setting_consultant_authendicate_position_code']   = (v.projects_setting_consultant_authendicate_position_code && v.projects_setting_consultant_authendicate_position_code.length > 0) ? v.projects_setting_consultant_authendicate_position_code.toString() : '';
        v['platform_code_system']                                     = (v.platform_code_system && v.platform_code_system.length > 0) ? v.platform_code_system.toString() : '';
        results.push(v);
      });
    }
    if(this.form.get('consultant_requirments').value && this.form.get('consultant_requirments').value.length > 0) {
      this.form.get('consultant_requirments').value.forEach((v, k) => {
        v['projects_setting_consultant_id_linked']      = (v.projects_setting_consultant_id_linked && v.projects_setting_consultant_id_linked.length > 0) ? v.projects_setting_consultant_id_linked.toString() : '';
        v['projects_setting_consultant_position_code']  = '';
        //v['platform_code_system'] = (this.platform_code_system && this.platform_code_system.length > 0) ? this.platform_code_system.toString() : '';
        results.push(v);
      });
    }
    let url = (this.divisionAction == "ADD") ? 'ccsetting' : 'ucsetting/'+this.editViewData.projects_setting_consultant_id;
    let data = (this.divisionAction == "EDIT" && this.editViewData.projects_setting_consultant_type == "FC") ? this.form.get('from_consultant').value[0] : (this.divisionAction == "EDIT" && this.editViewData.projects_setting_consultant_type != "FC") ? this.form.get('consultant_requirments').value[0] : results;
    this.ds.postActionByUrl(this.ds.json2formdata(data), url).subscribe((res) => {
      this.spinner.hide();
      if(res.status) {
        this.action.emit('LIST');
        this.form.reset();
        this.alert.error(res.msg);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}