import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
	selector: "app-supervision-alerts",
	templateUrl: "./supervision-alerts.component.html",
	styleUrls: ["./supervision-alerts.component.scss"],
})
export class SupervisionAlertsComponent implements OnInit {
	@Input() project_data;
	@Input() supervision_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() makeAction;
	modalRef: BsModalRef;
	public config = environment.modelconfig;
	public isFile1;
	public isFile2;
	public isFile3;
	filename1 = this.lang.transform("lang_select_files");
	filename2 = this.lang.transform("lang_select_files");
	filename3 = this.lang.transform("lang_select_files");
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	form: FormGroup;
	setting_data: any = [];
	loading_attachments = false;
	history_files = [];
	page = 1;
	size = 5;
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public alert: AlertService,
		public modalService: BsModalService,
		public dialog: MatDialog,
		private sweetAlert: SweetAlertService
	) {}
	ngOnInit(): void {
		this.buildform();
		this.get_settings();
	}
	public openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, this.config);
	}
	public buildform() {
		this.form = new FormGroup({
			projects_profile_id: new FormControl(
				this.supervision_data.projects_profile_id
			),
			projects_supervision_id: new FormControl(
				this.supervision_data.projects_supervision_id
			),
			total_construction_cost: new FormControl("", [Validators.required]),
			supervision_settings_rfialert_expire: new FormControl("", [
				Validators.required,
			]),
			supervision_settings_drawing_submittal_alert_expire: new FormControl("", [
				Validators.required,
			]),
			supervision_settings_material_submittal_alert_expire: new FormControl(
				"",
				[Validators.required]
			),
			implimentation_phases_required: new FormControl("", [
				Validators.required,
			]),
			supervision_start_date: new FormControl("", [Validators.required]),
			supervision_expiry_date: new FormControl("", [Validators.required]),
			supervision_settings_project_drawing_file: new FormControl("", [
				Validators.required,
			]),
			supervision_settings_plan_file: new FormControl("", [
				Validators.required,
			]),
			supervision_settings_project_logo_url: new FormControl(""),
		});
	}
	public get_settings() {
		this.ds
			.getActionByUrl(
				[],
				"XGOATrFu26gd54g/" +
					this.supervision_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id
			)
			.subscribe((res) => {
				if (res.status) {
					this.setting_data = res.data;
				}
			});
	}
	public emptyFormEdit() {
		this.filename1 = this.lang.transform("lang_select_files");
		this.filename2 = this.lang.transform("lang_select_files");
		this.isFile1 = false;
		this.isFile2 = false;
		this.alert.clear();
		this.form.reset();
		this.spinner.show();
		this.ds
			.getActionByUrl(
				[],
				"XGOATrFu26gd54g/" +
					this.supervision_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.form.patchValue({
							total_construction_cost: res.data.total_construction_cost,
							supervision_settings_rfialert_expire:
								res.data.supervision_settings_rfialert_expire,
							supervision_settings_drawing_submittal_alert_expire:
								res.data.supervision_settings_drawing_submittal_alert_expire,
							supervision_settings_material_submittal_alert_expire:
								res.data.supervision_settings_material_submittal_alert_expire,
							implimentation_phases_required:
								res.data.implimentation_phases_required,
							supervision_settings_project_drawing_file:
								res.data.supervision_settings_project_drawing_file,
							supervision_settings_plan_file:
								res.data.supervision_settings_plan_file,
							supervision_start_date: new Date(res.data.supervision_start_date),
							supervision_expiry_date: new Date(
								res.data.supervision_expiry_date
							),
						});
					}
				},
				(error) => {
					this.spinner.hide();
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	public UploadFile1(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			this.isFile1 = true;
			this.filename1 = event.target.files[0].name;
			this.form
				.get("supervision_settings_project_logo_url")
				.setValue(event.target.files[0], { emitEvent: true });
		} else {
			this.isFile1 = false;
			this.form.get("supervision_settings_project_logo_url").setValue(null);
			this.filename1 = this.lang.transform("lang_select_files");
		}
	}
	public UploadFile2(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			this.isFile2 = true;
			this.filename2 = event.target.files[0].name;
			this.form
				.get("supervision_settings_project_drawing_file")
				.setValue(event.target.files[0], { emitEvent: true });
		} else {
			this.isFile2 = false;
			this.form.get("supervision_settings_project_drawing_file").setValue(null);
			this.filename2 = this.lang.transform("lang_select_files");
		}
	}

	public UploadFile3(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			this.isFile3 = true;
			this.filename3 = event.target.files[0].name;
			this.form
				.get("supervision_settings_plan_file")
				.setValue(event.target.files[0], { emitEvent: true });
		} else {
			this.isFile3 = false;
			this.form.get("supervision_settings_plan_file").setValue(null);
			this.filename3 = this.lang.transform("lang_select_files");
		}
	}
	public removeFile1() {
		this.isFile1 = false;
		this.form.get("supervision_settings_project_logo_url").setValue(null);
		this.filename1 = this.lang.transform("lang_select_files");
	}
	public removeFile2() {
		this.isFile2 = false;
		this.form.get("supervision_settings_project_drawing_file").setValue(null);
		this.filename2 = this.lang.transform("lang_select_files");
	}
	public removeFile3() {
		this.isFile3 = false;
		this.form.get("supervision_settings_plan_file").setValue(null);
		this.filename3 = this.lang.transform("lang_select_files");
	}

	downloadPlanFile() {
		this.spinner.show();
		let projects_profile_id = this.project_data.projects_profile_id;
		let projects_supervision_id = this.supervision_data.projects_supervision_id;
		this.ds
			.get(
				`ps/download_plan_months/${projects_profile_id}/${projects_supervision_id}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetAlert.successMessage(res?.msg);
						this.ds.downloadFile(res, "plan_months");
					} else {
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.sweetAlert.errorMessage(err?.error.error);
					this.spinner.hide();
				}
			);
	}

	get_plan_history() {
		this.loading_attachments = true;
		let projects_profile_id = this.project_data.projects_profile_id,
			projects_supervision_id = this.supervision_data.projects_supervision_id,
			formData = new FormData();
		formData.append("required_type", "ST");
		this.ds
			.post(
				`s6df54s6df/${projects_profile_id}/${projects_supervision_id}`,
				formData
			)
			.subscribe(
				(res) => {
					this.loading_attachments = false;
					if (res.status) {
						this.history_files = res?.records;
					} else {
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.sweetAlert.errorMessage(err?.error.error);
					this.loading_attachments = false;
				}
			);
	}

	download_plan_history(item) {
		this.spinner.show();
		this.ds.get(item?.file).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.ds.downloadFile(res, "plan_months_" + item?.created_datetime);
				} else {
					this.sweetAlert.errorMessage(res?.error);
				}
			},
			(err) => {
				this.sweetAlert.errorMessage(err?.error.error);
				this.spinner.hide();
			}
		);
	}

	public submit() {
		this.spinner.show();
		this.form
			.get("projects_profile_id")
			.setValue(this.supervision_data.projects_profile_id);
		this.form
			.get("projects_supervision_id")
			.setValue(this.supervision_data.projects_supervision_id);
		this.ds
			.postActionByUrl(
				this.ds.json2formdata(this.form.value),
				"XGOATrFu2CGPFli"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.alert.success(res.msg);
						this.form.reset();
						this.get_settings();
						setTimeout(() => {
							this.modalRef.hide();
						}, 1000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
}
