<mat-tab-group
	(selectedTabChange)="selectedListTabValue($event)"
	class="bank_payments"
>
	<mat-tab label="payment">
		<ng-template mat-tab-label>{{ "lang_payment" | language }}</ng-template>
		<ng-container>
			<div class="p-2">
				<app-payments
					[projects_work_area_id]="projects_work_area_id"
					[shortCodeList]="shortCodeList"
				></app-payments>
			</div>
		</ng-container>
	</mat-tab>
	<mat-tab label="units">
		<ng-template mat-tab-label>{{ "lang_units" | language }}</ng-template>
		<ng-container>
			<div class="p-2">
				<app-bp-units
					[projects_work_area_id]="projects_work_area_id"
					[projects_profile_id]="projects_profile_id"
					[projects_supervision_id]="projects_supervision_id"
					[shortCodeList]="shortCodeList"
				></app-bp-units>
			</div>
		</ng-container>
	</mat-tab>
</mat-tab-group>
