<div class="row">
  <div class="w-100 text-end mt-3">
    <button (click)="returnTask()" class="btn mb-2 mx-2 btn-apply actions">
      {{ "lang_return" | language }}
    </button>
    <button (click)="download_PDF()" class="btn mb-2 mx-2 btn-apply actions">
      {{ "lang_export_pdf" | language }}
    </button>
    <button
      (click)="download_PDF('excel')"
      class="btn mb-2 mx-2 btn-apply actions"
    >
      {{ "lang_export_excel" | language }}
    </button>
    <button (click)="SalaryDialog()" class="btn mb-2 mx-2 btn-apply actions">
      {{ "lang_import_txt" | language }}
    </button>
  </div>
</div>

<div class="row my-2 mx-2 mt-4 div-overflow">
  <table class="table table-striped custom-payrole-table">
    <thead>
      <tr>
        <th scope="col">
          <!--  <mat-icon  (click)="clearAll()" matTooltip="{{'lang_delete_all' | language }}" class="delete-all">
             delete
           </mat-icon> -->
          <div class="checkbox m-auto">
            <!-- <input type="checkbox"  class="eachItemHR checkbox" value="{{item.employee_number}}" (click)="checkEachItemHR($event)"/> -->
            <input
              type="checkbox"
              [checked]="AllSelected()"
              (click)="selectAll($event)"
              class="eachItemHR checkbox"
            />
            <label for="checkbox"> </label>
          </div>
        </th>
        <th scope="col">{{ "lang_human_resources_id_number" | language }}</th>
        <th scope="col">
          {{ "lang_human_resources_employee_name" | language }}
        </th>
        <th scope="col">{{ "lang_total_net_salary" | language }}</th>
        <th scope="col">{{ "lang_other_earnings" | language }}</th>
        <th scope="col">{{ "lang_deductions" | language }}</th>
        <th scope="col">{{ "lang_ratio_month" | language }}</th>
        <th scope="col">{{ "lang_branch" | language }}</th>
        <th scope="col">{{ "lang_reviewers" | language }}</th>
        <th scope="col">{{ "lang_status" | language }}</th>
        <th scope="col">{{ "lang_notes" | language }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of usersList; let i = index">
        <tr>
          <th scope="row">
            <div class="checkbox m-auto">
              <!-- <input type="checkbox"  class="eachItemHR checkbox" value="{{item.employee_number}}" (click)="checkEachItemHR($event)"/> -->
              <input
                type="checkbox"
                [checked]="item.select_status == 'selected' ? true : false"
                value="{{ item.select_status == 'selected' ? true : false }}"
                (click)="checkEachItemHR($event, i)"
                class="eachItemHR checkbox"
              />
              <label for="checkbox"> </label>
            </div>
          </th>
          <td
            class="changeColor"
            (click)="employee_details(item.employee_number, i)"
          >
            {{ item.employee_number }}
          </td>
          <td
            class="changeColor"
            (click)="employee_details(item.employee_number, i)"
          >
            {{ item.firstname_english + " " + item.lastname_english }}
          </td>
          <td
            class="changeColor"
            (click)="employee_details(item.employee_number, i)"
          >
            {{ item?.net_salary }}
          </td>
          <td
            class="value changeColor"
            (click)="
              all_financial_transactions(item.employee_number, item, 'earning')
            "
          >
            {{ item?.Other_Earnings }}
          </td>
          <td
            class="value changeColor"
            (click)="
              all_financial_transactions(
                item.employee_number,
                item,
                'deduction'
              )
            "
          >
            {{ item?.Deductions }}
          </td>
          <td
            class="value changeColor"
            (click)="employee_details(item.employee_number, i)"
          >
            {{ item?.ratio_month }}
          </td>
          <td
            class="changeColor"
            (click)="employee_details(item.employee_number, i)"
          >
            {{ item.branch_name_en }}
          </td>
          <td>
            <ng-container *ngFor="let acArr of item.reviews; let ii = index">
              <button
                *ngIf="acArr.action"
                matTooltipClass="my-tooltip"
                matTooltip="{{ makeTooltip(acArr, false) }}"
                class="btn m-0 btn-apply btn-ok-submitted"
              >
                {{ "lang_ok" | language }}
              </button>
              <button
                matTooltipClass="my-tooltip"
                matTooltip="{{ makeTooltip(acArr) }}"
                *ngIf="!acArr.action"
                class="btn m-0 btn-apply btn-ok-submitted"
                style="background: #ffff; padding: 0 !important"
              >
                <img
                  src="assets/images/refuse.png"
                  width="22px"
                  alt="refuse"
                /></button
              ><span
                *ngIf="ii != item.reviews.length - 1"
                style="position: relative; top: -3px"
                >{{ " | " }}</span
              >
            </ng-container>
          </td>
          <td>
            <!-- **  success  -->
            <span *ngIf="item.status == 'failed'" class="failed">
              {{ "lang_failed" | language }}
            </span>
            <span *ngIf="item.status == 'success'" class="success">
              {{ "success" | language }}
            </span>
            <span *ngIf="item.status == null" class="pending">
              {{ "pending" | language }}
            </span>
          </td>
          <td>
            <div
              matTooltip="{{ item.note }}"
              *ngIf="item.note"
              class="note"
            ></div>
          </td>
        </tr>
        <tr *ngIf="item.collapsed">
          <td></td>
          <td colspan="7">
            <div class="width-100 p-1 mt-1">
              <mat-tab-group>
                <mat-tab label="{{ 'lang_details' | language }}">
                  <div class="row my-1 w-100">
                    <div class="col-md-2 d-block col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_id_expiry_data_english" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.iqama_expiry_date_english }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_copy_number" | language
                      }}</label>
                      <p class="value">{{ item?.details?.copy_number }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_birth_date_english" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.birth_date_english }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_gender" | language
                      }}</label>
                      <p class="value">{{ item?.details?.gender }}</p>
                    </div>
                    <div class="col-md-2 d-block col-12 text-start">
                      <label for="">{{
                        "lang_project_name_en" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.quotation_subject }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_nationality" | language
                      }}</label>
                      <p class="value">{{ item?.details?.nationality }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_job_title_iqama" | language
                      }}</label>
                      <p class="value">{{ item?.details?.job_title_iqama }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{ "lang_username" | language }}</label>
                      <p class="value">{{ item?.details?.username }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_primary_mobile" | language
                      }}</label>
                      <p class="value">{{ item?.details?.primary_mobile }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_primary_email" | language
                      }}</label>
                      <p class="value">{{ item?.details?.primary_email }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_primary_address" | language
                      }}</label>
                      <p class="value">{{ item?.details?.primary_address }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_graduation_year" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.primary_graduation_year }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_graduation" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.primary_education_level }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_passport_number" | language
                      }}</label>
                      <p class="value">{{ item?.details?.passport_number }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_passport_issue_date_english"
                          | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.passport_issue_date_english }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_passport_expiry_date_english"
                          | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.passport_expiry_date_english }}
                      </p>
                    </div>

                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_insurance_number" | language
                      }}</label>
                      <p class="value">{{ item?.details?.insurance_number }}</p>
                    </div>

                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_insurance_date" | language
                      }}</label>
                      <p class="value">{{ item?.details?.insurance_date }}</p>
                    </div>

                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_insurance_type_class" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.insurance_type_class }}
                      </p>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="{{ 'lang_financial_details' | language }}">
                  <div class="row my-1 w-100">
                    <div class="col-md-1 d-block col-12 text-start"></div>
                    <div class="col-md-2 d-block col-12 text-start">
                      <label for="">{{ "lang_bank" | language }}</label>
                      <p class="value">
                        {{ item?.details?.bankname }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{ "lang_basic_salary" | language }}</label>
                      <p class="value">
                        {{ item?.details?.basic_salary }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_other_allowances" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.home_allowance }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_other_earnings" | language
                      }}</label>
                      <p class="value">
                        {{ item?.Other_Earnings }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{ "lang_deductions" | language }}</label>
                      <p class="value">
                        {{ item?.Deductions }}
                      </p>
                    </div>
                    <div class="col-md-1 d-block col-12 text-start"></div>
                    <div class="col-md-1 d-block col-12 text-start"></div>
                    <div class="col-md-2 d-block col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_account_number" | language
                      }}</label>
                      <p class="value">
                        {{ item?.account_number }}
                      </p>
                    </div>
                    <div class="col-md-2 d-block col-12 text-start">
                      <label for="">{{ "lang_net_salary" | language }}</label>
                      <p class="value">
                        {{ item?.details?.net_amount }}
                      </p>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </td>
        </tr>
      </ng-container>
      <!-- <tr >
              <th scope="row">
                <div class="checkbox m-auto" >
                  
                  <input type="checkbox"  class="eachItemHR checkbox" />
                  <label for="checkbox">
               
                  </label>
               
              </div>
              </th>
              <td>{{item.label}}</td>
              <td>{{item.value}}</td>
              <td>123456789</td>
              <td >Branch</td>
              <td >Project 1</td>
              <td >
                <span class="success">
                  Success
                </span>
              </td>
              <td >
                
              </td>
            </tr> -->

      <tr *ngIf="usersList?.length == 0">
        <th colspan="7">
          <div class="no_data">
            {{ "lang_no_employees" | language }}
          </div>
        </th>
      </tr>
    </tbody>
  </table>
</div>
<div class="row text-center justify-content-between py-3">
  <div class="col-md-4 count_label text-start p-3">
    <!--  <div class="actions" *ngIf="AtLeastOneSelected()">
       <button (click)="add_review('Approve',{},false)" class="btn mb-2 mx-2 btn-apply btn-ok">{{'lang_ok'|language}}</button>
       <button (click)="add_review('Reject',{},false)" class="btn mb-2 mx-2 btn-apply btn-ok " style="background: #ffff;padding: 0 !important;" >
         <img src="assets/images/refuse.png"  width="25px" alt="refuse">
       </button>
     </div> -->
    <label for="">{{ "lang_total" | language }}</label
    >: {{ usersList.length }}
  </div>
  <div class="col-md-7 totals p-3">
    <table class="table table-bordered custom-payrole-table">
      <thead>
        <tr>
          <th scope="col" colspan="2">
            {{ "lang_financial_request" | language }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row" class="count_label">
            {{ "lang_total_deductions" | language }}
          </td>
          <td>{{ responseData.deductions_amount }}</td>
        </tr>
        <tr>
          <td scope="row" class="count_label">
            {{ "lang_total_other_earnings" | language }}
          </td>
          <td>{{ responseData.other_earnings_amount }}</td>
        </tr>
        <tr>
          <td scope="row" class="count_label">
            {{ "lang_total_net_salary" | language }}
          </td>
          <td>{{ responseData.total_amount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<br />
<div class="row my-2 mx-2 mt-4 div-overflow">
  <table class="table table-striped custom-payrole-table">
    <thead>
      <tr>
        <th scope="col">{{ "lang_employee_name" | language }}</th>
        <th scope="col">
          {{ "lang_employee_number" | language }}
        </th>
        <th scope="col">{{ "lang_reviewer" | language }}</th>
        <th scope="col">{{ "lang_comment" | language }}</th>
        <th scope="col">{{ "lang_value" | language }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="rejetcedData?.length != 0">
        <tr *ngFor="let item of rejetcedData; let i = index">
          <td>{{ item.employee_name }}</td>
          <td>{{ item.employee_number }}</td>
          <td>{{ item.reviewer_name }}</td>
          <td>{{ item.comment }}</td>
          <td>{{ item.value }}</td>
        </tr>
      </ng-container>
      <tr *ngIf="rejetcedData?.length == 0">
        <th colspan="5">
          <div class="no_data">
            {{ "lang_no_employees" | language }}
          </div>
        </th>
      </tr>
    </tbody>
  </table>
  <div
    style="width: 100%"
    class="mb-10 custom-footer no-border"
    *ngIf="rejetcedData?.length != 0"
  >
    <div class="row responsive_pagination">
      <div
        class="col-lg-3 col-sm-3 col-6 mb-10"
        style="height: fit-content; margin: auto 0"
      >
        {{ "lang_total" | language }} :
        {{ total }}
      </div>
      <div
        class="col-lg-6 col-sm-6 mb-10"
        style="display: flex; justify-content: center"
      >
        <ngb-pagination
          class="m-auto"
          [collectionSize]="total"
          [rotate]="true"
          [ellipses]="false"
          [maxSize]="3"
          [boundaryLinks]="true"
          [(page)]="page"
          [pageSize]="size"
          (pageChange)="load_by_page_no($event)"
        ></ngb-pagination>
      </div>
      <div
        class="col-lg-3 col-sm-3"
        style="display: flex; justify-content: end; margin: auto"
      >
        <mat-select
          [(ngModel)]="size"
          (selectionChange)="load_by_page_size($event.value)"
        >
          <mat-option class="text-center" [value]="10"
            >10 {{ "lang_per_page" | language }}
          </mat-option>
          <mat-option class="text-center" [value]="20"
            >20 {{ "lang_per_page" | language }}
          </mat-option>
          <mat-option class="text-center" [value]="50"
            >50 {{ "lang_per_page" | language }}
          </mat-option>
          <mat-option class="text-center" [value]="100"
            >100 {{ "lang_per_page" | language }}</mat-option
          >
          <mat-option class="text-center" [value]="500"
            >500 {{ "lang_per_page" | language }}</mat-option
          >
        </mat-select>
      </div>
    </div>
  </div>
</div>
<div class="w-100 text-start mt-5">
  <!--  <button matStepperPrevious  class="btn mb-2 mx-2 btn-cancel">{{'lang_previous'|language}}</button> -->
  <button (click)="SalaryDialog()" class="btn mb-2 mx-5 btn-apply">
    {{ "lang_submit" | language }}
  </button>
</div>
