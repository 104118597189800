import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-add-permission",
	templateUrl: "./add-permission.component.html",
	styleUrls: ["./add-permission.component.scss"],
})
export class AddPermissionComponent implements OnInit {
	permissionForm: FormGroup;
	admin = JSON.parse(this.ls.getItem("is_admin"));
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	branches: any = [];
	groups: any = [];
	users: any = [];
	mention_allow: boolean = false;
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public sweetAlert: SweetAlertService,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<AddPermissionComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.spinner.show();
		this.permissionform();
		this.get_branches();
		this.get_groups();
		this.get_users(0);
	}
	public permissionform() {
		this.permissionForm = this.fb.group({
			branch_id: ["", [Validators.required]],
			group_id: ["", [Validators.required]],
			users_id: [null, [Validators.required]],
		});
	}
	public get_branches() {
		this.branches = [];
		this.ds.getActionByUrl([], "taskssettings/get_branches").subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.mention_allow = res.mention_allow;
					this.branches = res.records;
				}
			},
			(error) => {
				this.spinner.hide();
			}
		);
	}
	public get_groups() {
		this.groups = [];
		this.ds.getActionByUrl([], "taskssettings/get_groups").subscribe((res) => {
			if (res.status) {
				this.groups = res.records;
			}
		});
	}
	public get_users(value) {
		this.permissionForm.get("users_id").setValue(null);
		this.users = [];
		let bid = value ? value : 0;
		let param = new FormData();
		param.append("user_type_id", "1");
		this.ds.postActionByUrl(param, "taskssettings/get_users/" + bid).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.users = res.records;
				}
			},
			(error) => {
				this.spinner.hide();
			}
		);
	}
	public savepermissions() {
		this.spinner.show();
		let param = new FormData();
		param.append("branch_id", this.permissionForm.get("branch_id").value || "");
		param.append("group_id", this.permissionForm.get("group_id").value || "");
		param.set(
			"users_id",
			this.permissionForm.get("users_id").value &&
				this.permissionForm.get("users_id").value != null
				? this.permissionForm.get("users_id").value.toString()
				: ""
		);
		this.ds.postActionByUrl(param, "taskssettings/savepermissions").subscribe(
			(data) => {
				this.spinner.hide();
				if (data.status) {
					this.sweetAlert.successToast(data.msg, 2000);
					this;
				} else {
					this.sweetAlert.errorToast(data.error, 2000);
				}
			},
			(error) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
}
