<section class="panel-expansion">
	<mat-accordion class="appcollections">
		<mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_collections" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row mb-3">
						<div class="col-12 col-sm-3">
							<button
								class="albutton mnabrbutton"
								mat-button
								(click)="onCreateUpdateBank(createTemp, 'create')"
							>
								<i
									class="fa fa-plus mnabricon mr-1 ml-1"
									style="color: #fff"
								></i>
								<span>{{ "lang_add" | language }}</span>
							</button>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="table-responsive dropdownyes customResponsive">
								<table border="1" class="table CustomTable">
									<thead>
										<tr>
											<td>#</td>
											<td>{{ "lang_title_english" | language }}</td>
											<td>{{ "lang_title_arabic" | language }}</td>
											<td>
												{{ "lang_human_resources_bank_shortcode" | language }}
											</td>
											<td>{{ "lang_action" | language }}</td>
										</tr>
									</thead>
									<tbody *ngIf="bankList.length" class="AppendList">
										<tr
											*ngFor="
												let list of bankList;
												let i = index;
												let odd = odd;
												let even = even
											"
											[ngClass]="{ odd: odd, even: even }"
										>
											<td>{{ i + 1 }}</td>
											<td>{{ list.bank_name_en }}</td>
											<td>{{ list.bank_name_ar }}</td>
											<td>{{ list.bank_short_code }}</td>
											<td>
												<i
													class="fa fa-trash red ml-1 mr-1"
													(click)="deleteBank(list?.bank_id)"
													matTooltip="{{ 'lang_delete' | language }}"
												></i>
												<i
													class="fa fa-pencil-square mnabricon ml-1 mr-1"
													(click)="
														onCreateUpdateBank(createTemp, 'update', list)
													"
													matTooltip="{{ 'lang_edit' | language }}"
												></i>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</section>

<ng-template #createTemp>
	<div class="modal-content">
		<!-- Modal Header -->
		<div class="modal-header">
			<h4 class="modal-title" *ngIf="!isEdit">{{ "lang_add" | language }}</h4>
			<h4 class="modal-title" *ngIf="isEdit">{{ "lang_edit" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<!-- Modal body -->
		<div class="modal-body">
			<form [formGroup]="form">
				<div class="row">
					<div class="col-12 mb-2">
						<div class="form-group">
							<label for="title_en">{{
								"lang_title_english" | language
							}}</label>
							<input
								type="text"
								name="title_en"
								id="title_en"
								class="form-control"
								formControlName="bank_name_en"
							/>
						</div>
					</div>
					<div class="col-12 mb-2">
						<div class="form-group">
							<label for="title_ar">{{ "lang_title_arabic" | language }}</label>
							<input
								type="text"
								name="title_ar"
								id="title_ar"
								class="form-control"
								formControlName="bank_name_ar"
							/>
						</div>
					</div>
					<div class="col-12 mb-2">
						<div class="form-group">
							<label for="shortcode">{{
								"lang_human_resources_bank_shortcode" | language
							}}</label>
							<input
								type="text"
								name="shortcode"
								id="shortcode"
								class="form-control"
								formControlName="bank_short_code"
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
		<!-- Modal footer -->
		<div class="modal-footer justify-content-between">
			<button
				type="button"
				class="btn btn-secondary"
				data-dismiss="modal"
				(click)="modalRef.hide()"
				style="width: 40%"
			>
				{{ "lang_close" | language }}
			</button>
			<button
				type="button"
				class="albutton mnabrbutton"
				style="width: 40% !important"
				(click)="onSubmit()"
				mat-button
			>
				{{ "lang_submit" | language }}
			</button>
		</div>
	</div>
</ng-template>
