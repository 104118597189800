import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { SelectEmployeeComponent } from "./select-employee/select-employee.component";

@Component({
  selector: "app-violation-deduction",
  templateUrl: "./violation-deduction.component.html",
  styleUrls: ["./violation-deduction.component.scss"],
})
export class ViolationDeductionComponent implements OnInit {
  constructor(
    public modalRef: MatDialogRef<SelectEmployeeComponent>,
    private _formBuilder: FormBuilder,
    public ds: DataService,
    public dialog: MatDialog,
    public lang: LanguagePipe,
    private _sweetAlertService: SweetAlertService,
    private spinner: PreloaderService
  ) {}
  @Input() t_id: any = "";
  @Input() projects: any = [];
  @Input() branches: any = [];
  @Input() requestInfo: any = "";
  @Input() usersList: any[] = [];
  @Output() resfreshUsers = new EventEmitter<any>();
  @Output() UserListSetter = new EventEmitter<any>();
  @Output() sendComponentUpdate = new EventEmitter<any>();
  showFilters = false;
  filterForm = this._formBuilder.group({
    employee_id: [""],
    employee_name: [""],
    project_id: [""],
    branch_id: [""],
  });
  ngOnInit(): void {}
  orignalUsersList = [];
  sortArray() {
    this.usersList = this.orignalUsersList;
    let condition = true;
    console.log(this.filterForm.value);
    let filters: any = {
      employee_number: (employee_number) =>
        employee_number.includes(this.filterForm.value?.employee_id),
      employee_name: (employee_name) =>
        employee_name
          .toLowerCase()
          .includes(this.filterForm.value.employee_name.toLowerCase()),
      branch_id: (branch_id) =>
        branch_id.includes(this.filterForm.value.branch_id),
      /* project_id: (project_id) =>  project_id.includes(this.filterForm.value.project_id)   */
    };
    let filterKeys = Object.keys(filters);
    if (!this.filterForm.value.branch_id) {
      filterKeys.splice(2, 1);
    }
    if (!this.filterForm.value.employee_name) {
      filterKeys.splice(1, 1);
    }
    if (!this.filterForm.value.employee_id) {
      filterKeys.splice(0, 1);
    }

    if (this.filterForm.value.project_id) {
      this.usersList = this.usersList.filter((ul) => {
        return ul.project_id != null;
      });

      filters = {
        employee_number: (employee_number) =>
          employee_number.includes(this.filterForm.value.employee_id),
        employee_name: (employee_name) =>
          employee_name
            .toLowerCase()
            .includes(this.filterForm.value.employee_name.toLowerCase()),
        branch_id: (branch_id) =>
          branch_id.includes(this.filterForm.value.branch_id),
        project_id: (project_id) =>
          project_id.includes(this.filterForm.value.project_id),
      };
    }
    this.usersList = this.usersList.filter((ul) => {
      return filterKeys.every((key) => {
        // ignores non-function predicates
        if (typeof filters[key] !== "function") return true;
        return filters[key](ul[key]);
      });
    });
  }

  openChartData(info) {
    const dialogRef2 = this.dialog.open(SelectEmployeeComponent, {
      width: "100%",
      data: {
        info: info,
        projects: this.projects,
        branches: this.branches,
        usersList: this.usersList,
        t_id: this.t_id,
      },
    });

    dialogRef2.afterClosed().subscribe((result) => {
      if (result) {
        this.usersList = [];
        for (let index = 0; index < result.new.length; index++) {
          const element = result.new[index];
          let inTheArr = false;
          for (let i = 0; i < this.usersList.length; i++) {
            const e = this.usersList[i];
            if (e.employee_number == element.employee_number) {
              inTheArr = true;
            }
          }
          if (!inTheArr) {
            this.usersList.push({ ...element, collapsed: false, isNew: true });
          }
        }
        this.orignalUsersList = this.usersList;
      }
    });
  }

  clearAll() {
    this.usersList = [];
    this.orignalUsersList = this.usersList;
  }
  deleteSingle(id: any, index) {
    this.usersList.splice(index, 1);

    this.orignalUsersList = this.usersList;
  }
  submit() {
    this.spinner.show();
    let formData = new FormData();
    this.usersList.forEach((element, index) => {
      formData.append(
        `data[${index}][employee_number]`,
        `${element.employee_number}`
      );
      formData.append(`data[${index}][date]`, `${element.date}`);
    });
    this.ds
      .postActionByUrl(formData, `at/create_violation_deduction`)
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.modalRef.close();
            this._sweetAlertService.successToast(
              this.lang.transform("lang_data_sent_successfully"),
              1500
            );
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorToast(res.error, 2000);
          }
        },
        (err) => {
          this.spinner.hide();
          this._sweetAlertService.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
  }
}
