<div mat-dialog-title class="d-flex justify-content-between">
	<h4>Create Contract</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="contract_form">
		<div class="p-2">
			<div class="row mb-3">
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Contract Name Ar</mat-label>
						<input
							matInput
							placeholder="Type Contract Name Ar"
							formControlName="contact_titel"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Contract Name En</mat-label>
						<input
							matInput
							placeholder="Type Contract Name English"
							formControlName="contact_titel_en"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Contract Age</mat-label>
						<input
							matInput
							placeholder="Type Contract Age"
							autocomplete="off"
							type="number"
							formControlName="contact_age"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Contract Age Type</mat-label>
						<mat-select formControlName="contact_age_type">
							<mat-option
								*ngFor="let item of data_for_add?.age_type"
								[value]="item.id"
							>
								{{ item?.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Grand Total</mat-label>
						<input
							matInput
							placeholder="Type Grand Total"
							autocomplete="off"
							type="number"
							formControlName="contract_grand_total"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Net Amount</mat-label>
						<input
							matInput
							placeholder="Type Net Amount"
							autocomplete="off"
							type="number"
							formControlName="contract_net_amount"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_vat" | language }}</mat-label>
						<mat-select formControlName="contract_vat">
							<mat-option *ngFor="let vat of vat_list" [value]="vat.value">
								{{ vat.label }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Soil Sensor</mat-label>
						<mat-select formControlName="contract_soil_sensor">
							<mat-option
								*ngFor="let item of data_for_add?.contract_soil_sensor"
								[value]="item.id"
							>
								{{ item.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Surveying Costs</mat-label>
						<mat-select formControlName="contract_surveying_costs">
							<mat-option
								*ngFor="let item of data_for_add?.contract_surveying_costs"
								[value]="item.id"
							>
								{{ item.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Contract Printing</mat-label>
						<mat-select formControlName="contract_printing">
							<mat-option
								*ngFor="let item of data_for_add?.contract_printing"
								[value]="item.id"
							>
								{{ item.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_start_date" | language }}</mat-label>
						<input
							matInput
							[matDatepicker]="csd"
							required
							readonly
							formControlName="contract_start_date"
						/>
						<mat-datepicker-toggle
							matSuffix
							[for]="csd"
						></mat-datepicker-toggle>
						<mat-datepicker #csd></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Technical References</mat-label>
						<input
							matInput
							placeholder="Type Technical References"
							formControlName="contract_technical_references"
						/>
					</mat-form-field>
				</div>
				<div class="col-12">
					<label>Government Services<i class="red">*</i></label>
					<ckeditor
						#editor
						[config]="config"
						formControlName="contract_government_services"
						[ngClass]="{ ' contentrtl': lang_key == 'ar' }"
					></ckeditor>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-12 TableParts">
					<p class="mb-10">
						{{ "lang_attachments" | language }}
						<span class="red" *ngIf="attachments_form?.controls?.length"
							>*</span
						>
					</p>
					<div
						class="table-responsive customResponsive tablefields quotationitemtable"
					>
						<table
							border="1"
							class="table CustomTable"
							width="100%"
							formArrayName="attachments"
						>
							<thead>
								<tr>
									<td width="2%" style="padding: 5px; word-break: break-all">
										#
									</td>
									<td width="2%" style="padding: 5px; word-break: break-all">
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addAttachment()"
											>add_box</mat-icon
										>
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_attach_title" | language }}
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_file" | language }}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let attachment of attachments_form?.controls;
										let i = index
									"
									[formGroupName]="i"
								>
									<td
										class="verticalalignmid"
										style="padding: 5px; word-break: break-all"
									>
										{{ i + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											*ngIf="
												!attachments_form.controls[i].get('delete_icon').value
											"
											(click)="removeAttachment(i)"
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											>delete_forever</mat-icon
										>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											type="text"
											formControlName="attach_title"
											placeholder="{{ 'lang_attach_title' | language }}"
											autocomplete="off"
											class="form-control almnabrformcontrol"
										/>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											name="file"
											type="file"
											required
											onclick="this.value = null"
											(change)="
												ds.uploadSingleFile(
													$event,
													attachments_array.controls[i].get('file'),
													'file' + i,
													filename
												)
											"
											class="nghide albutton width100"
										/>
										<label id="file{{ i }}" class="albutton">{{
											filename
										}}</label>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-0 col-sm-9"></div>
	<div class="col-12 col-sm-3">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onSubmit()"
			*ngIf="!edit_mode"
		>
			{{ "lang_submit" | language }}
		</button>
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onUpdateProject()"
			*ngIf="edit_mode"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
