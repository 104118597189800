import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { environment } from "@env/environment";

@Component({
	selector: "app-bp-units",
	templateUrl: "./bp-units.component.html",
	styleUrls: ["./bp-units.component.scss"],
})
export class BpUnitsComponent implements OnInit {
	@Input() projects_work_area_id;
	@Input() projects_profile_id;
	@Input() projects_supervision_id;
	@Input() shortCodeList;
	modalRef: BsModalRef;
	unitList = [];
	filter_bank_short_code = null;
	isEdit = false;
	historyFilesList = [];
	unitsCollectionSize = 0;
	page_no = "1";
	page_size = "10";
	payment_list = [];
	unitForm: FormGroup;
	constructor(
		public ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		public modalService: BsModalService,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.getAllUnits();
		this.unitForm = this.fb.group({
			unit_id: ["", Validators.required],
			bank_short_code: ["", Validators.required],
		});
	}

	getAllUnits() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("unit_id", "");
		formData.append("zone_id", "");
		formData.append("block_id", "");
		formData.append("cluster_id", "");
		formData.append("template_id", "");
		this.spinner.show();
		this.ds
			.post(
				`pr/bankpay/get_unit_bank_payment_report/${this.page_no}/${this.page_size}`,
				formData
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.unitList = res?.results;
						this.unitsCollectionSize = res?.pages?.total_records;
					} else {
						this.sweetAlert.errorToast(res?.error, 3000);
						this.unitList = [];
						this.unitsCollectionSize = 0;
					}
				},
				(err) => {
					this.spinner.hide();
					this.unitList = [];
					this.unitsCollectionSize = 0;
				}
			);
	}

	downloadUnitsFile() {
		this.spinner.show();
		this.ds
			.get(
				`pr/bankpay/download_units_for_bank_payment/${this.projects_profile_id}/${this.projects_supervision_id}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successMessage(res?.msg);
						this.ds.downloadFile(
							res,
							`units_for_bank_payment-${this.projects_profile_id}-${this.projects_supervision_id}`
						);
					} else {
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.sweetAlert.errorMessage(err?.error.error);
					this.spinner.hide();
				}
			);
	}

	uploadUnitsFile(e) {
		if (e && e?.length) {
			let formData = new FormData();
			formData.append("import_bank_payment_file", e[0] || "");
			this.spinner.show();
			this.ds
				.post(
					`pr/bankpay/upload_units_for_bank_payment/${this.projects_profile_id}/${this.projects_supervision_id}`,
					formData
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.sweetAlert.successMessage(res?.msg);
						} else {
							this.sweetAlert.errorMessage(res?.msg);
						}
					},
					(err) => {
						this.spinner.hide();
					}
				);
		}
		document.getElementById("uploadUnitsInput")["value"] = "";
	}

	getUnitsFileHistory(template) {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		this.spinner.show();
		this.ds.post("pr/bankpay/get_unit_bank_file_history", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.historyFilesList = res?.records;
					this.modalRef = this.modalService.show(
						template,
						environment.modelconfig
					);
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
					this.historyFilesList = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
				this.historyFilesList = [];
			}
		);
	}

	downloadHistoryUnitsFile(url, date) {
		this.spinner.show();
		this.ds.get(url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successMessage(res?.msg);
					this.ds.downloadFile(res, `units_for_bank_payment-${date}`);
				} else {
					this.sweetAlert.errorMessage(res?.error);
				}
			},
			(err) => {
				this.sweetAlert.errorMessage(err?.error.error);
				this.spinner.hide();
			}
		);
	}

	onShowUnitPayments(temp, unit_id) {
		this.modalRef = this.modalService.show(temp, environment.modelconfig);
		this.getPayments(unit_id);
	}

	getPayments(unit_id) {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("unit_id", unit_id || "");
		this.spinner.show();
		this.ds.post("pr/bankpay/get_unit_bank_payments", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.payment_list = res?.results;
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
					this.payment_list = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.payment_list = [];
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}

	changePaymentStatus(payment) {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append(
			"bank_payment_setting_id",
			payment?.bank_payment_setting_id || ""
		);
		formData.append("unit_id", payment?.phase_zone_block_cluster_g_no || "");
		this.spinner.show();
		this.ds
			.post("pr/bankpay/update_units_payment_received_status", formData)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_success"),
							3000
						);
						this.getPayments(payment?.phase_zone_block_cluster_g_no);
					} else {
						this.sweetAlert.errorToast(res?.error, 3000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
	}
	onUpdateUnit(temp) {
		this.modalRef = this.modalService.show(temp, environment.modelconfig);
	}
	onSubmitUnit() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("unit_id", this.unitForm.get("unit_id").value || "");
		formData.append(
			"bank_short_code",
			this.unitForm.get("bank_short_code").value || ""
		);
		if (this.unitForm.invalid) {
			this.sweetAlert.errorToast(
				this.lang.transform("lang_fill_required_fields"),
				3000
			);
			return false;
		}
		this.spinner.show();
		this.ds.post("pr/bankpay/add_edit_unit_to_bank", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.getAllUnits();
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
					this.modalRef.hide();
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
