<form [formGroup]="mainTitleForm" (ngSubmit)="addUpdateMainTitle()">
	<div
		mat-dialog-title
		class="d-flex align-items-center justify-content-between"
	>
		<h2 style="font-size: 18px; margin: 0" *ngIf="type == 'main' && !isEdit">
			Add Main Title
		</h2>
		<h2 style="font-size: 18px; margin: 0" *ngIf="type == 'main' && isEdit">
			Update Main Title
		</h2>
		<h2 style="font-size: 18px; margin: 0" *ngIf="type == 'sub' && !isEdit">
			Add Sub Title
		</h2>
		<h2 style="font-size: 18px; margin: 0" *ngIf="type == 'sub' && isEdit">
			Update Sub Title
		</h2>
		<i class="fa fa-times mnabricon" mat-dialog-close></i>
	</div>
	<mat-dialog-content class="mat-typography">
		<div class="row">
			<div class="col-12" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
				<mat-form-field appearance="outline" style="width: 100%">
					<mat-label>Title English</mat-label>
					<input
						matInput
						placeholder="Title English"
						formControlName="title_english"
					/>
				</mat-form-field>
			</div>
			<div class="col-12" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
				<mat-form-field appearance="outline" style="width: 100%">
					<mat-label>Title Arabic</mat-label>
					<input
						matInput
						placeholder="Title Arabic"
						formControlName="title_arabic"
					/>
				</mat-form-field>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions class="justify-content-between align-items-center">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			style="width: 150px !important"
			mat-dialog-close
		>
			{{ "lang_cancel" | language }}
		</button>
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			style="width: 150px !important"
			type="submit"
		>
			{{ "lang_submit" | language }}
		</button>
	</mat-dialog-actions>
</form>
