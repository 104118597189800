<div
	class="panel-expansion matborderno"
	*ngIf="ds.allowMe('human_resources', 'human_resources_view') && viewstatus"
>
	<div class="row">
		<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
			<a routerLink="/human_resources/applications"
				><button type="button" class="albutton">
					{{ "lang_goto_list" | language }}
				</button></a
			>
		</div>
	</div>
	<mat-accordion class="mainiddetails">
		<mat-expansion-panel class="mpanel" [expanded]="true">
			<i
				*ngIf="attachments.id0001"
				(click)="
					spinner.show(); ds.preview_pdf_file(attachments.id0001, 'id0001')
				"
				matTooltip="{{ 'lang_recent_attachment' | language }} : {{
					attachments.id0001_d
				}}"
				class="fa fa-paperclip linkicon mnabricon"
			></i>
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_human_resources_id_details" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-9 col-sm-12 col-xs-12 pad0">
							<div class="row">
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">
										{{ "lang_human_resources_id_number" | language }}
									</div>
									<div class="mb-10">
										<b>{{ viewdata.employee_id_number }}</b>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">{{ "lang_branch" | language }}</div>
									<div class="mb-10"><b>----</b></div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">
										{{ "lang_human_resources_nameen" | language }}
									</div>
									<div class="mb-10">
										<b
											>{{ viewdata.settings_name_english }}
											{{ viewdata.firstname_english }}
											{{ viewdata.secondname_english }}
											{{ viewdata.thirdname_english }}
											{{ viewdata.lastname_english }}</b
										>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">
										{{ "lang_human_resources_namear" | language }}
									</div>
									<div class="mb-10">
										<b
											>{{ viewdata.settings_name_arabic }}
											{{ viewdata.firstname_arabic }}
											{{ viewdata.secondname_arabic }}
											{{ viewdata.thirdname_arabic }}
											{{ viewdata.lastname_arabic }}</b
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">{{ "lang_expiry_date" | language }}</div>
									<div class="mb-10">
										<b
											>{{ viewdata.iqama_expiry_date_english }} -
											{{ viewdata.iqama_expiry_date_arabic }}</b
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">
										{{ "lang_human_resources_copy_number" | language }}
									</div>
									<div class="mb-10">
										<b>{{ viewdata.copy_number }}</b>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">
										{{ "lang_human_resources_birth_date" | language }}
									</div>
									<div class="mb-10">
										<b
											>{{ viewdata.birth_date_english }} -
											{{ viewdata.birth_date_arabic }}</b
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">
										{{ "lang_human_resources_gender" | language }}
									</div>
									<div class="mb-10">
										<b *ngIf="viewdata.gender == 'M'">{{
											"lang_human_resources_male" | language
										}}</b>
									</div>
									<div class="mb-10">
										<b *ngIf="viewdata.gender == 'F'">{{
											"lang_human_resources_female" | language
										}}</b>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">
										{{ "lang_human_resources_nationality" | language }}
									</div>
									<div class="mb-10">
										<img
											*ngIf="viewdata.nationality"
											src="./assets/images/nations/{{
												viewdata.nationality.toLowerCase()
											}}.svg"
											class="img-responsive cntryimage"
										/>
										<b> {{ viewdata.countryname }}</b>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">{{ "lang_user_type" | language }}</div>
									<div class="mb-10">
										<b>{{ viewdata.typename }}</b>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">
										{{ "lang_human_resources_job_title_iqama" | language }}
									</div>
									<div class="mb-10">
										<b>{{ viewdata.job_title_iqama }}</b>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">{{ "lang_status" | language }}</div>
									<div class="mb-10">
										<b *ngIf="viewdata.employee_status == '0'" class="red">{{
											"lang_inactive" | language
										}}</b>
										<b *ngIf="viewdata.employee_status == '1'" class="green">{{
											"lang_active" | language
										}}</b>
										<b *ngIf="viewdata.employee_status == '2'" class="orange">{{
											"lang_pending" | language
										}}</b>
										<b *ngIf="viewdata.employee_status == '3'" class="red">{{
											"lang_rejected" | language
										}}</b>
										<b *ngIf="viewdata.employee_status == '4'" class="blue">{{
											"lang_waiting" | language
										}}</b>
									</div>
								</div>
							</div>
							<div class="row">
								<div
									class="col-md-4 col-sm-4 col-xs-12 mb-10"
									*ngIf="
										viewdata.interview_date_en && viewdata.interview_date_ar
									"
								>
									<div class="mb-10">
										{{ "lang_interview_date" | language }}
									</div>
									<div class="mb-10 red flash">
										<b
											>{{ viewdata.interview_date_en }} -
											{{ viewdata.interview_date_ar }}</b
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">{{ "lang_ondate" | language }}</div>
									<div class="mb-10">
										<b>{{ viewdata.created_datetime }}</b>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
									<div class="mb-10">{{ "lang_onupdate" | language }}</div>
									<div class="mb-10" *ngIf="viewdata.updated_datetime">
										<b>{{ viewdata.updated_datetime }}</b>
									</div>
									<div class="mb-10" *ngIf="!viewdata.updated_datetime">
										<b>----</b>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-3 col-sm-12 col-xs-12 pad0 text-center">
							<p>
								{{ "lang_myprofile" | language }} : {{ profile_percentage }}%
							</p>
							<ngb-progressbar
								class="mb-10"
								[value]="profile_percentage"
								[striped]="true"
								[animated]="true"
							>
								<i>{{ profile_percentage }}%</i></ngb-progressbar
							>
							<div class="row">
								<div class="col-md-12 col-sm-12 col-xs-12 mb-10">
									<div class="avatar-upload">
										<img
											*ngIf="
												(!viewdata.profile_image &&
													viewdata.gender == 'M' &&
													!view_profile_image) ||
												(viewdata.profile_image &&
													!view_profile_image &&
													viewdata.gender == 'M')
											"
											src="./assets/images/male.png"
											class="avatar-preview redborder"
											id="imagePreview"
										/>
										<img
											*ngIf="
												(!viewdata.profile_image &&
													viewdata.gender == 'F' &&
													!view_profile_image) ||
												(viewdata.profile_image &&
													!view_profile_image &&
													viewdata.gender == 'F')
											"
											src="./assets/images/female.png"
											class="avatar-preview redborder"
											id="imagePreview"
										/>
										<img
											*ngIf="viewdata.profile_image && view_profile_image"
											src="{{ view_profile_image }}"
											class="avatar-preview"
											id="imagePreview"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<mat-accordion>
		<mat-expansion-panel class="mpanel" [expanded]="false">
			<i
				*ngIf="attachments.rs0001"
				(click)="
					spinner.show(); ds.preview_pdf_file(attachments.rs0001, 'rs0001')
				"
				matTooltip="{{ 'lang_recent_attachment' | language }} : {{
					attachments.rs0001_d
				}}"
				class="fa fa-paperclip linkicon mnabricon"
			></i>
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_human_resources_job_details" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
							<div class="mb-10">
								{{ "lang_human_resources_job_title_iqama" | language }}
							</div>
							<div class="mb-10">
								<b>{{ viewdata.jobname }}</b>
							</div>
						</div>
						<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
							<div class="mb-10">{{ "lang_job_descriptions" | language }}</div>
							<div class="mb-10">
								<b>{{ viewdata.job_descriptions }}</b>
							</div>
						</div>
					</div>
					<div class="row" *ngIf="licences.length != '0'">
						<div class="col-md-12">
							<div class="table-responsive dropdownyes customResponsive">
								<table border="1" class="table CustomTable">
									<thead>
										<tr>
											<td>#</td>
											<td>{{ "lang_licence_name" | language }}</td>
											<td>{{ "lang_licence_number" | language }}</td>
											<td>{{ "lang_issue_date" | language }}</td>
											<td>{{ "lang_expiry_date" | language }}</td>
											<td>{{ "lang_ondate" | language }}</td>
										</tr>
									</thead>
									<tbody *ngIf="licences" class="AppendList">
										<tr
											*ngFor="
												let li of licences;
												let i = index;
												let odd = odd;
												let even = even
											"
											[ngClass]="{ odd: odd, even: even }"
										>
											<td>{{ i + 1 }}</td>
											<td>{{ li.licence_name }}</td>
											<td>{{ li.licence_number }}</td>
											<td>
												{{ li.licence_issue_date_english }} -
												{{ li.licence_issue_date_arabic }}
											</td>
											<td>
												{{ li.licence_expiry_date_english }} -
												{{ li.licence_expiry_date_arabic }}
											</td>
											<td>{{ li.licence_createddatetime }}</td>
										</tr>
									</tbody>
									<tbody *ngIf="licences.length == '0'" class="AppendList">
										<tr class="odd">
											<td colspan="6" align="center">{{ cerrormessagef }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<mat-accordion>
		<mat-expansion-panel class="mpanel" [expanded]="false">
			<i
				*ngIf="attachments.cn0001"
				(click)="
					spinner.show(); ds.preview_pdf_file(attachments.cn0001, 'cn0001')
				"
				matTooltip="{{ 'lang_recent_attachment' | language }} : {{
					attachments.cn0001_d
				}}"
				class="fa fa-paperclip linkicon mnabricon"
			></i>
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_human_resources_contact_details" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
							<div class="mb-10">
								{{ "lang_human_resources_primary_mobile" | language }}
							</div>
							<div class="mb-10">
								<b>{{ viewdata.primary_mobile }}</b>
							</div>
						</div>
						<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
							<div class="mb-10">
								{{ "lang_human_resources_primary_email" | language }}
							</div>
							<div class="mb-10">
								<b>{{ viewdata.primary_email }}</b>
							</div>
						</div>
						<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
							<div class="mb-10">
								{{ "lang_human_resources_primary_address" | language }}
							</div>
							<div class="mb-10">
								<b>{{ viewdata.primary_address }}</b>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<mat-accordion>
		<mat-expansion-panel class="mpanel" [expanded]="false">
			<i
				*ngIf="attachments.bk0001"
				(click)="
					spinner.show(); ds.preview_pdf_file(attachments.bk0001, 'cn0001')
				"
				matTooltip="{{ 'lang_recent_attachment' | language }} : {{
					attachments.bk0001_d
				}}"
				class="fa fa-paperclip linkicon mnabricon"
			></i>
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_human_resources_bank_details" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
							<div class="mb-10">
								{{ "lang_human_resources_account_number" | language }}
							</div>
							<div class="mb-10">
								<b>{{ viewdata.account_number }}</b>
							</div>
						</div>
						<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
							<div class="mb-10">{{ "lang_bank" | language }}</div>
							<div class="mb-10">
								<b>{{ viewdata.bankname }}</b>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<mat-accordion>
		<mat-expansion-panel class="mpanel" [expanded]="false">
			<i
				*ngIf="attachments.en0001"
				(click)="
					spinner.show(); ds.preview_pdf_file(attachments.en0001, 'cn0001')
				"
				matTooltip="{{ 'lang_recent_attachment' | language }} : {{
					attachments.en0001_d
				}}"
				class="fa fa-paperclip linkicon mnabricon"
			></i>
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_human_resources_education_details" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-3 col-sm-6 col-xs-12 mb-10">
							<div class="mb-10">
								{{ "lang_human_resources_graduation" | language }}
							</div>
							<div class="mb-10">
								<b *ngIf="viewdata.primary_education_level == 'SL'">{{
									"lang_human_resources_belowsslc" | language
								}}</b>
								<b *ngIf="viewdata.primary_education_level == 'SS'">{{
									"lang_human_resources_sslc" | language
								}}</b>
								<b *ngIf="viewdata.primary_education_level == 'HS'">{{
									"lang_human_resources_hsc" | language
								}}</b>
								<b *ngIf="viewdata.primary_education_level == 'DP'">{{
									"lang_human_resources_diploma" | language
								}}</b>
								<b *ngIf="viewdata.primary_education_level == 'UG'">{{
									"lang_human_resources_bachelor" | language
								}}</b>
								<b *ngIf="viewdata.primary_education_level == 'PG'">{{
									"lang_human_resources_pg" | language
								}}</b>
								<b *ngIf="viewdata.primary_education_level == 'DC'">{{
									"lang_human_resources_doc" | language
								}}</b>
							</div>
						</div>
						<div class="col-md-3 col-sm-6 col-xs-12 mb-10">
							<div class="mb-10">
								{{ "lang_human_resources_graduation_year" | language }}
							</div>
							<div class="mb-10">
								<b>{{ viewdata.primary_graduation_year }}</b>
							</div>
						</div>
						<div class="col-md-3 col-sm-6 col-xs-12 mb-10">
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.membership_number }"
							>
								{{ "lang_membership_number" | language }}
							</div>
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.membership_number }"
							>
								<b *ngIf="viewdata.membership_number">{{
									viewdata.membership_number
								}}</b>
								<b *ngIf="!viewdata.membership_number">-</b>
							</div>
						</div>
						<div class="col-md-3 col-sm-6 col-xs-12 mb-10">
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.membership_expiry_date_english }"
							>
								{{ "lang_expiry_date" | language }}
							</div>
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.membership_expiry_date_english }"
							>
								<b *ngIf="viewdata.membership_expiry_date_english"
									>{{ viewdata.membership_expiry_date_english }} -
									{{ viewdata.membership_expiry_date_arabic }}</b
								>
								<b *ngIf="!viewdata.membership_expiry_date_english"> - </b>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<mat-accordion>
		<mat-expansion-panel class="mpanel" [expanded]="false">
			<i
				*ngIf="attachments.ps0001"
				(click)="
					spinner.show(); ds.preview_pdf_file(attachments.ps0001, 'ps0001')
				"
				matTooltip="{{ 'lang_recent_attachment' | language }} : {{
					attachments.ps0001_d
				}}"
				class="fa fa-paperclip linkicon mnabricon"
			></i>
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_human_resources_passport_details" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div class="mb-10" [ngClass]="{ red: !viewdata.passport_number }">
								{{ "lang_human_resources_passport_number" | language }}
							</div>
							<div class="mb-10" [ngClass]="{ red: !viewdata.passport_number }">
								<b *ngIf="viewdata.passport_number">{{
									viewdata.passport_number
								}}</b>
								<b *ngIf="!viewdata.passport_number">-</b>
							</div>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.passport_issue_date_english }"
							>
								{{ "lang_issue_date" | language }}
							</div>
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.passport_issue_date_english }"
							>
								<b
									>{{ viewdata.passport_issue_date_english }} -
									{{ viewdata.passport_issue_date_arabic }}</b
								>
							</div>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.passport_expiry_date_english }"
							>
								{{ "lang_expiry_date" | language }}
							</div>
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.passport_expiry_date_english }"
							>
								<b
									>{{ viewdata.passport_expiry_date_english }} -
									{{ viewdata.passport_expiry_date_arabic }}</b
								>
							</div>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.passport_issue_place }"
							>
								{{ "lang_human_resources_passport_issue_place" | language }}
							</div>
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.passport_issue_place }"
							>
								<b *ngIf="viewdata.passport_issue_place">{{
									viewdata.passport_issue_place
								}}</b>
								<b *ngIf="!viewdata.passport_issue_place">-</b>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<mat-accordion>
		<mat-expansion-panel class="mpanel" [expanded]="false">
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_human_resources_insurance_details" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.insurance_number }"
							>
								{{ "lang_insurance_number" | language }}
							</div>
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.insurance_number }"
							>
								<b *ngIf="viewdata.insurance_number">{{
									viewdata.insurance_number
								}}</b>
								<b *ngIf="!viewdata.insurance_number">-</b>
							</div>
						</div>
						<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
							<div class="mb-10" [ngClass]="{ red: !viewdata.insurance_date }">
								{{ "lang_insurance_date" | language }}
							</div>
							<div class="mb-10" [ngClass]="{ red: !viewdata.insurance_date }">
								<b *ngIf="viewdata.insurance_date">{{
									viewdata.insurance_date
								}}</b>
								<b *ngIf="!viewdata.insurance_date">-</b>
							</div>
						</div>
						<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.insurance_type_class }"
							>
								{{ "lang_insurance_type_class" | language }}
							</div>
							<div
								class="mb-10"
								[ngClass]="{ red: !viewdata.insurance_type_class }"
							>
								<b *ngIf="viewdata.insurance_type_class">{{
									viewdata.insurance_type_class
								}}</b>
								<b *ngIf="!viewdata.insurance_type_class">-</b>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<mat-accordion>
		<mat-expansion-panel class="mpanel" [expanded]="false">
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_signature" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-3 col-xs-6">
							<div>{{ "lang_signature" | language }}</div>
							<div class="mb-10">
								<a>
									<img
										(click)="showsignature(viewdata.employee_number, 'S')"
										class="img-responsive signview"
										src="./assets/images/sign.png"
									/>
								</a>
							</div>
						</div>
						<div class="col-md-3 col-xs-6">
							<div>{{ "lang_mark" | language }}</div>
							<div class="mb-10">
								<a>
									<img
										(click)="showsignature(viewdata.employee_number, 'M')"
										class="img-responsive signview"
										src="./assets/images/sign.png"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<mat-accordion class="appattachments">
		<mat-expansion-panel class="mpanel" [expanded]="false">
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_attachments" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10">
							<ng-select
								[items]="types"
								[multiple]="true"
								bindLabel="title"
								bindValue="attach_type_id"
								appendTo="body"
								clearAllText="Clear"
								placeholder="{{ 'lang_doc_type' | language }}"
								[(ngModel)]="typelists"
								(change)="spinner.show(); search_my_attchments()"
							>
							</ng-select>
						</div>
						<div class="col-md-9 col-sm-8 col-xs-12 mb-10">
							<div class="input-group input-group-sm">
								<input
									type="text"
									class="form-control almnabrformcontrol"
									(keyup)="search_my_attchments()"
									[(ngModel)]="searchKey"
									placeholder="{{ 'lang_search' | language }}"
								/>
								<span
									class="input-group-btn"
									*ngIf="ds.allowMe('human_resources', 'human_resources_add')"
								>
									<button
										class="btnSearch h34 btn btn-md btn-gray fa fa-upload mnabricon"
										id="btnSearch"
										type="button"
										(click)="openModalA(addNewUploads); emptyAttachForm()"
										matTooltip="{{ 'lang_upload_attachments' | language }}"
									></button>
								</span>
							</div>
						</div>
					</div>
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check_all' | language }}"
										>
											<input
												type="checkbox"
												class="allItemsAttach checkbox"
												id="allItemsAttach"
												(click)="checkAllAttach($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ "lang_type" | language }}</td>
									<td>{{ "lang_subject" | language }}</td>
									<td>{{ "lang_file_extention" | language }}</td>
									<td>{{ "lang_file_level" | language }}</td>
									<td>{{ "lang_writer" | language }}</td>
									<td>{{ "lang_ondate" | language }}</td>
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody *ngIf="attach.length != '0'" class="AppendList">
								<tr
									*ngFor="
										let file of attach;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
									class="makeback removerow{{ file.file_records_id }}"
								>
									<td>{{ i + 1 }}</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check' | language }}"
										>
											<input
												type="checkbox"
												class="eachItemAttach checkbox"
												value="{{ file.file_records_id }}"
												(click)="checkEachItemAttach($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td *ngIf="file.type_name">{{ file.type_name }}</td>
									<td *ngIf="!file.type_name">----</td>
									<td class="breakall">{{ file.file_name }}</td>
									<td>{{ file.file_extension }}</td>
									<td>{{ file.level_keys }}</td>
									<td>{{ file.writer }}</td>
									<td>{{ file.created_datetime }}</td>
									<td>
										<i
											*ngIf="file.file_path"
											(click)="
												spinner.show();
												ds.preview_pdf_file(file.file_path, file?.file_name)
											"
											class="fa fa-paperclip fa-icon mnabricon s2icon"
											matTooltip="{{ 'lang_view' | language }}"
										></i>
										<i
											class="fa fa-pencil-square-o fa-icon mnabricon s2icon"
											*ngIf="
												ds.allowMe('human_resources', 'human_resources_edit')
											"
											matTooltip="{{ 'lang_edit' | language }}"
											(click)="
												openModalU(EditAttachModal); edit_my_Attach(file)
											"
										></i>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="attach.length == '0'" class="AppendList">
								<tr class="odd">
									<td colspan="9" align="center">{{ aerror }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div
					*ngIf="attach?.length != '0'"
					class="card-footer padb0 bottomfooter"
				>
					<div class="row responsive_pagination">
						<div class="deleteIconDiv none col-lg-1 col-sm-1 mb-10">
							<i
								*ngIf="ds.allowMe('human_resources', 'human_resources_delete')"
								(click)="deleteBulkDataAttach()"
								class="fa fa-trash-o faicon red deleteIcon"
								aria-hidden="true"
								matTooltip="{{ 'lang_delete' | language }}"
							></i>
						</div>
						<div
							class="col-lg-3 col-sm-3 mb-10"
							style="height: fit-content; margin: auto 0"
						>
							{{ "lang_total" | language }} : {{ wadata.page.total_records }}
						</div>
						<div
							class="col-lg-5 col-sm-5 mb-10"
							style="display: flex; justify-content: center"
						>
							<!--  <mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="10" checked (click)="spinner.show();attachlength('10')"> 10</mat-radio-button>
                                <mat-radio-button value="20" (click)="spinner.show();attachlength('20')"> 20</mat-radio-button>
                                <mat-radio-button value="50" (click)="spinner.show();attachlength('50')"> 50</mat-radio-button>
                                <mat-radio-button value="100" (click)="spinner.show();attachlength('100')"> 100</mat-radio-button>
                                <mat-radio-button value="500" (click)="spinner.show();attachlength('500')"> 500</mat-radio-button>
                            </mat-radio-group> -->
							<ngb-pagination
								class="m-auto"
								[collectionSize]="wadata?.page?.total_records"
								[rotate]="true"
								[ellipses]="false"
								[maxSize]="3"
								[boundaryLinks]="true"
								[(page)]="pageno"
								[pageSize]="pagesize"
								(pageChange)="spinner.show(); attachpage($event)"
							></ngb-pagination>
						</div>
						<div
							class="col-lg-3 col-sm-3 mb-10"
							style="display: flex; justify-content: end; margin: auto"
						>
							<!-- {{'lang_goto' | language }} :
                            <select class="customselect" (change)="spinner.show();attachpage($event.target.value)">
                                <option *ngFor="let k of ds.getrange(wadata.page.total_pages)" value="{{k}}">{{k}}</option>
                            </select> -->
							<mat-select
								style="max-width: 150px"
								[(ngModel)]="pagesize"
								(selectionChange)="spinner.show(); attachlength($event.value)"
							>
								<!-- <mat-option value="1">1  </mat-option> -->
								<mat-option class="text-center" value="10"
									>10 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="20"
									>20 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="50"
									>50 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="100"
									>100 {{ "lang_per_page" | language }}</mat-option
								>
								<mat-option class="text-center" value="500"
									>500 {{ "lang_per_page" | language }}</mat-option
								>
							</mat-select>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="row">
		<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
			<a routerLink="/human_resources/applications"
				><button type="button" class="albutton">
					{{ "lang_goto_list" | language }}
				</button></a
			>
		</div>
	</div>
</div>
<ng-template #EditAttachModal>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_attachments" | language }}</h4>
			<button type="button" class="close" (click)="modalRefU.hide()">
				&times;
			</button>
		</div>
		<form [formGroup]="uform" (ngSubmit)="update()">
			<div class="modal-body">
				<div class="form-group">
					<div class="row">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<label for="level_keys"
								>{{ "lang_file_secutrity_levels" | language }} :
								<span class="red">*</span></label
							>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<ng-select
								[items]="filepermissionlevels"
								[multiple]="true"
								bindLabel="label"
								appendTo="body"
								bindValue="value"
								clearAllText="Clear"
								placeholder="{{ 'lang_file_secutrity_levels' | language }}"
								formControlName="level_keys"
							>
							</ng-select>
							<mat-error
								*ngIf="
									uform.controls['level_keys'].touched &&
									uform.controls['level_keys'].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<label for="attachment_descriptions"
								>{{ "lang_description" | language }} :
								<span class="red">*</span></label
							>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<input
								type="text"
								formControlName="attachment_descriptions"
								class="form-control almnabrformcontrol"
								placeholder="{{ 'lang_description' | language }}"
							/>
							<mat-error
								*ngIf="
									uform.controls['attachment_descriptions'].touched &&
									uform.controls['attachment_descriptions'].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							(click)="modalRefU.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #addNewUploads>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_attachments" | language }}</h4>
			<button type="button" class="close" (click)="modalRefA.hide()">
				&times;
			</button>
		</div>
		<form [formGroup]="attachform" (ngSubmit)="upload_files()">
			<div class="modal-body">
				<div class="form-group">
					<div class="row">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<label for="attachment_type"
								>{{ "lang_attachment_type" | language }} :
								<span class="red">*</span></label
							>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<select
								formControlName="attachment_type"
								class="form-control almnabrformcontrol"
								matTooltip="{{ 'lang_attachment_type' | language }}"
							>
								<option value="">{{ "lang_choose_options" | language }}</option>
								<option *ngFor="let type of types" value="{{ type.key_code }}">
									{{ type.title }}
								</option>
							</select>
							<mat-error
								*ngIf="
									attachform.controls['attachment_type'].touched &&
									attachform.controls['attachment_type'].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<label for="level_keys"
								>{{ "lang_file_secutrity_levels" | language }} :
								<span class="red">*</span></label
							>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<ng-select
								[items]="filepermissionlevels"
								[multiple]="true"
								bindLabel="label"
								appendTo="body"
								bindValue="value"
								clearAllText="Clear"
								placeholder="{{ 'lang_file_secutrity_levels' | language }}"
								formControlName="level_keys"
							>
							</ng-select>
							<mat-error
								*ngIf="
									attachform.controls['level_keys'].touched &&
									attachform.controls['level_keys'].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<label for="attachment_descriptions"
								>{{ "lang_description" | language }} :
								<span class="red">*</span></label
							>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<input
								type="text"
								formControlName="attachment_descriptions"
								class="form-control almnabrformcontrol"
								placeholder="{{ 'lang_description' | language }}"
							/>
							<mat-error
								*ngIf="
									attachform.controls['attachment_descriptions'].touched &&
									attachform.controls['attachment_descriptions'].errors
										?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<label for="attachment_link"
								>{{ "lang_select_files" | language }} :
								<span class="red">*</span></label
							>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<div class="form-group">
								<div class="IDDIV">
									<input
										name="attachment_link"
										type="file"
										required
										onclick="this.value = null"
										(change)="uploadIDFile($event)"
										class="nghide albutton width100"
									/>
									<label id="uploadIDButton" class="albutton"
										>{{ idfilename }}
									</label>
								</div>
								<mat-error
									*ngIf="
										attachform.controls['attachment_link'].touched &&
										attachform.controls['attachment_link'].errors?.required
									"
								>
									{{ "lang_field_required" | language }}
								</mat-error>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							(click)="modalRefA.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
