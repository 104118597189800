import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-platform-levels-list",
	templateUrl: "./platform-levels-list.component.html",
	styleUrls: ["./platform-levels-list.component.scss"],
})
export class PlatformLevelsListComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	projects_profile_id;
	projects_work_area_id;
	title_id;
	sub_title_id;
	platformLevels = [];
	pageno = 1;
	pagesize = "10";
	worklevelesCollectionSize = 0;
	makeActionConfig;
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public sweetalert: SweetAlertService,
		public dialog: MatDialog,
		public fb: FormBuilder,
		public dialogRef: MatDialogRef<PlatformLevelsListComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.projects_profile_id = data?.projects_profile_id;
		this.projects_work_area_id = data?.projects_work_area_id;
		this.title_id = data?.titleId;
		this.sub_title_id = data?.subTitleId;
		this.makeActionConfig = data?.makeActionConfig;
	}

	ngOnInit(): void {
		this.getPlatformLevels();
	}

	getPlatformLevels() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("projects_profile_id", this.projects_profile_id);
		formData.append("projects_work_area_id", this.projects_work_area_id);
		formData.append("search_key", "");
		formData.append("title_ids", this.title_id || "");
		formData.append("sub_title_ids", this.sub_title_id || "");
		formData.append("template_ids", "");
		formData.append("platform_code_systems", "");
		formData.append("platform_work_level_keys", "");
		formData.append("platform_group_type_code_systems", "");
		formData.append("platform_group1_code_systems", "");
		formData.append("platform_group2_code_systems", "");
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				`p/cp/list_platform_wlevels/${this.pageno}/${this.pagesize}`
			)
			.subscribe(
				(res: any) => {
					this.spinner.hide();
					if (res?.status) {
						this.platformLevels = res?.records;
						this.worklevelesCollectionSize = res?.page?.total_records;
					} else {
						this.platformLevels = [];
						this.worklevelesCollectionSize = 0;
					}
				},
				(err) => {
					this.spinner.hide();
					this.platformLevels = [];
					this.worklevelesCollectionSize = 0;
					this.sweetalert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}

	deleteWorklevel(id) {
		this.spinner.show();
		this.ds
			.deleteActionByUrl(
				[id],
				`p/cp/delete_platform_wlevels/${this.projects_profile_id}/${this.projects_work_area_id}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.getPlatformLevels();
					} else {
						this.sweetalert.errorToast(res?.error, 3000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetalert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
}
