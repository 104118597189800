import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-pq1-step3",
	templateUrl: "./pq1-step3.component.html",
	styleUrls: ["./pq1-step3.component.scss"],
})
export class Pq1Step3Component implements OnInit {
	@Output() refreshComponent = new EventEmitter<boolean>();
	@Input() form_key;
	@Input() form_request_id;
	@Input() form_url;
	@Input() editMode;
	@Input() allData: any;
	termsForm: FormGroup;
	termsFP: FormArray;
	termsSP: FormArray;
	termsWS: FormArray;
	termsFC: FormArray;
	constructor(
		private fb: FormBuilder,
		private ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.buildForm();
		let oldTerms = this.allData?.terms;
		if (oldTerms?.length) {
			for (let item of oldTerms) {
				if (item?.condition_type == "FP") {
					this.addTermsFP(item);
				} else if (item?.condition_type == "SP") {
					this.addTermsSP(item);
				} else if (item?.condition_type == "WS") {
					this.addTermsWS(item);
				} else if (item?.condition_type == "FC") {
					this.addTermsFC(item);
				}
			}
		} else {
			this.getTerms();
		}
	}

	buildForm() {
		this.termsForm = this.fb.group({
			termsFP: this.fb.array([], Validators.required),
			termsSP: this.fb.array([], Validators.required),
			termsWS: this.fb.array([], Validators.required),
			termsFC: this.fb.array([], Validators.required),
		});
		this.termsFP = this.termsForm.get("termsFP") as FormArray;
		this.termsSP = this.termsForm.get("termsSP") as FormArray;
		this.termsWS = this.termsForm.get("termsWS") as FormArray;
		this.termsFC = this.termsForm.get("termsFC") as FormArray;
	}
	//terms FP
	public termsGroupFP() {
		return this.termsForm.get("termsFP") as FormArray;
	}
	get getTermsGroupFP() {
		return this.termsForm.get("termsFP") as FormArray;
	}
	public addTermsFP(value?) {
		this.termsFP.push(this.recordsTermsFP(value));
	}
	public removeTermsFP(index) {
		this.termsFP.removeAt(index);
	}
	public recordsTermsFP(value?): FormGroup {
		return this.fb.group({
			rf_sort: [value ? value?.rf_sort : null],
			rf_name_ar: [
				value?.arabic_name
					? value?.arabic_name
					: value?.rf_name_ar
					? value?.rf_name_ar
					: null,
				[Validators.required],
			],
			rf_name_en: [
				value?.english_name
					? value?.english_name
					: value?.rf_name_en
					? value?.rf_name_en
					: null,
				[Validators.required],
			],
			rf_required_or_optional: [
				value ? value?.rf_required_or_optional : "optional",
				[Validators.required],
			],
			condition_type: ["FP", [Validators.required]],
		});
	}

	//terms SP
	public termsGroupSP() {
		return this.termsForm.get("termsSP") as FormArray;
	}
	get getTermsGroupSP() {
		return this.termsForm.get("termsSP") as FormArray;
	}
	public addTermsSP(value?) {
		this.termsSP.push(this.recordsTermsSP(value));
	}
	public removeTermsSP(index) {
		this.termsSP.removeAt(index);
	}
	public recordsTermsSP(value?): FormGroup {
		return this.fb.group({
			rf_sort: [value ? value?.rf_sort : null],
			rf_name_ar: [
				value?.arabic_name
					? value?.arabic_name
					: value?.rf_name_ar
					? value?.rf_name_ar
					: null,
				[Validators.required],
			],
			rf_name_en: [
				value?.english_name
					? value?.english_name
					: value?.rf_name_en
					? value?.rf_name_en
					: null,
				[Validators.required],
			],
			rf_required_or_optional: [
				value ? value?.rf_required_or_optional : "optional",
				[Validators.required],
			],
			condition_type: ["SP", [Validators.required]],
		});
	}

	//terms WS
	public termsGroupWS() {
		return this.termsForm.get("termsWS") as FormArray;
	}
	get getTermsGroupWS() {
		return this.termsForm.get("termsWS") as FormArray;
	}
	public addTermsWS(value?) {
		this.termsWS.push(this.recordsTermsWS(value));
	}
	public removeTermsWS(index) {
		this.termsWS.removeAt(index);
	}
	public recordsTermsWS(value?): FormGroup {
		return this.fb.group({
			rf_sort: [value ? value?.rf_sort : null],
			rf_name_ar: [
				value?.arabic_name
					? value?.arabic_name
					: value?.rf_name_ar
					? value?.rf_name_ar
					: null,
				[Validators.required],
			],
			rf_name_en: [
				value?.english_name
					? value?.english_name
					: value?.rf_name_en
					? value?.rf_name_en
					: null,
				[Validators.required],
			],
			rf_required_or_optional: [
				value ? value?.rf_required_or_optional : "optional",
				[Validators.required],
			],
			condition_type: ["WS", [Validators.required]],
		});
	}

	//Terms FC
	public termsGroupFC() {
		return this.termsForm.get("termsFC") as FormArray;
	}
	get getTermsGroupFC() {
		return this.termsForm.get("termsFC") as FormArray;
	}
	public addTermsFC(value?) {
		this.termsFC.push(this.recordsTermsFC(value));
	}
	public removeTermsFC(index) {
		this.termsFC.removeAt(index);
	}
	public recordsTermsFC(value?): FormGroup {
		return this.fb.group({
			rf_sort: [value ? value?.rf_sort : null],
			rf_name_ar: [
				value?.arabic_name
					? value?.arabic_name
					: value?.rf_name_ar
					? value?.rf_name_ar
					: null,
				[Validators.required],
			],
			rf_name_en: [
				value?.english_name
					? value?.english_name
					: value?.rf_name_en
					? value?.rf_name_en
					: null,
				[Validators.required],
			],
			rf_required_or_optional: [
				value ? value?.rf_required_or_optional : "optional",
				[Validators.required],
			],
			condition_type: ["FC", [Validators.required]],
		});
	}

	getTerms() {
		let formData = new FormData();
		formData.append(
			"quotation_types_keyword",
			this.allData?.form_pq1_data?.records?.quotation_types_keyword || ""
		);
		this.ds
			.getActionByUrl(this.ds.formData2string(formData), "form/FORM_PQ1/qrfr")
			.subscribe((res) => {
				if (res?.status) {
					for (let item of res?.records) {
						if (item?.condition_type == "FP") {
							this.addTermsFP(item);
						} else if (item?.condition_type == "SP") {
							this.addTermsSP(item);
						} else if (item?.condition_type == "WS") {
							this.addTermsWS(item);
						} else if (item?.condition_type == "FC") {
							this.addTermsFC(item);
						}
					}
				}
			});
	}

	submit() {
		let formData = new FormData();
		formData.append(
			"quotation_types_keyword",
			this.allData?.form_pq1_data?.records?.quotation_types_keyword || ""
		);
		let allTerms = this.termsFP.value.concat(
			this.termsSP.value,
			this.termsWS.value,
			this.termsFC.value
		);
		allTerms.forEach((el, key) => {
			formData.append(`terms[${key + 1}][rf_sort]`, el?.rf_sort || "1");
			formData.append(`terms[${key + 1}][rf_name_ar]`, el?.rf_name_ar || "");
			formData.append(`terms[${key + 1}][rf_name_en]`, el?.rf_name_en || "");
			formData.append(
				`terms[${key + 1}][rf_required_or_optional]`,
				el?.rf_required_or_optional || ""
			);
			formData.append(
				`terms[${key + 1}][condition_type]`,
				el?.condition_type || ""
			);
		});
		if (
			(this.termsFP.length && this.termsFP.valid) ||
			(this.termsSP.length && this.termsSP.valid) ||
			(this.termsWS.length && this.termsWS.valid) ||
			(this.termsFC.length && this.termsFC.valid)
		) {
			this.spinner.show();
			this.ds
				.post(
					`form/FORM_PQ1/add_new_condition/${this.form_request_id}`,
					formData
				)
				.subscribe(
					(res) => {
						if (res.status) {
							this.spinner.hide();
							this.sweetAlert.successToast(
								this.lang.transform("lang_data_sent_successfully"),
								1700
							);
							setTimeout(() => {
								this.refreshComponent.emit(true);
							}, 2000);
						} else {
							this.spinner.hide();
							this.sweetAlert.errorToast(res?.error, 3000);
						}
					},
					(err) => {
						this.spinner.hide();
						this.sweetAlert.errorToast(
							this.lang.transform("lang_internal_server_error"),
							3000
						);
					}
				);
		}
	}
}
