<section class="panel-expansion ckeditorhidetoolbar">
  <mat-accordion class="stylepanels">
    <mat-expansion-panel class="mpanel" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">
        {{ "lang_end_contract" | language }}
        (
        <span *ngIf="lang_key == 'en'">
          {{ employee_data.firstname_english }}
          {{ employee_data.lastname_english }}</span
        >
        <span *ngIf="lang_key == 'ar'">
          {{ employee_data.firstname_arabic }}
          {{ employee_data.lastname_arabic }}
        </span>
        )
      </mat-expansion-panel-header>
      <div class="mbrl15">
        <form [formGroup]="form" class="form">
          <div class="mbrl15">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_last_working_date" | language }} :<span
                      class="red"
                      >*</span
                    ></label
                  >
                  <div class="input-group input-group-sm">
                    <input
                      [matDatepicker]="jvd"
                      formControlName="last_working_date"
                      (dateChange)="
                        get_contract_termination_cases(); this.spinner.show()
                      "
                      class="form-control almnabrformcontrol"
                      placeholder="YYYY/MM/DD"
                      readonly
                    />
                    <span class="input-group-btn">
                      <mat-datepicker-toggle
                        class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                        matSuffix
                        [for]="jvd"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #jvd></mat-datepicker>
                    </span>
                  </div>
                  <mat-error
                    *ngIf="
                      form.controls['last_working_date'].touched &&
                      form.controls['last_working_date'].errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}</mat-error
                  >
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4 col-xs-12"
                *ngIf="
                  form.get('last_working_date').value &&
                  form.get('last_working_date').value != ''
                "
              >
                <div class="form-group terminations">
                  <label
                    >{{ "lang_termination_cases" | language }} :<span
                      class="red"
                      >*</span
                    ></label
                  >
                  <select
                    class="form-control almnabrformcontrol"
                    formControlName="termination_case"
                    #termination_case
                  >
                    <option value="">
                      {{ "lang_choose_options" | language }}
                    </option>
                    <ng-container
                      *ngFor="let term of contract_termination_list.records"
                    >
                      <option
                        *ngIf="term.status_case == 'TRUE'"
                        value="{{ term.value }}"
                      >
                        <span *ngIf="lang_key == 'ar'">{{
                          term.case_name_ar
                        }}</span>
                        <span *ngIf="lang_key == 'en'">{{
                          term.case_name_en
                        }}</span>
                      </option>
                    </ng-container>
                  </select>
                  <mat-error
                    *ngIf="
                      form.controls['termination_case'].touched &&
                      form.controls['termination_case'].errors?.required
                    "
                    >{{ "lang_field_required" | language }}</mat-error
                  >
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4 col-xs-12 mb-10"
                *ngIf="
                  form.get('last_working_date').value &&
                  form.get('last_working_date').value != ''
                "
              >
                <label class="mb-10">
                  {{ "lang_direct_manager" | language }}
                  <span class="red"> * </span>
                </label>
                <ng-select
                  [items]="users"
                  [multiple]="false"
                  bindLabel="label"
                  bindValue="value"
                  clearAllText="Clear"
                  appendTo="body"
                  formControlName="direct_manager"
                  placeholder="{{ 'lang_direct_manager' | language }}"
                  (search)="search_users($event)"
                  [searchFn]="ds.customSearchFn"
                >
                </ng-select>
                <mat-error
                  *ngIf="
                    form.controls['direct_manager'].touched &&
                    form.controls['direct_manager'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row justify-content-between">
          <div class="col-md-6 col-sm-2 col-xs-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
          <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
            <button
              type="button"
              (click)="create_end_contract()"
              class="albutton mnabrbutton mat-accent ml-auto"
              mat-button
            >
              {{ "lang_submit" | language }}
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
