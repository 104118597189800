import { ValidatorFn, AbstractControl, FormGroup } from "@angular/forms";
import { PhoneNumberUtil, PhoneNumber } from "google-libphonenumber";

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(): ValidatorFn {
	return (form: FormGroup): { [key: string]: any } => {
		let validNumber = false;
		try {
			const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
				"+" + form.value?.phone_code + form.value?.mobile
			);
			console.log(phoneNumber);

			validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
			console.log(validNumber);
			console.log(form.get("mobile"));
			console.log(form);
		} catch (e) {}
		if (validNumber) {
			return null;
		} else {
			return {
				wrongNumber: {
					value: "+" + form.value?.phone_code + form.value?.mobile,
				},
			};
		}
	};
}
