import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
	FormArray,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
	selector: "app-pq1-step4",
	templateUrl: "./pq1-step4.component.html",
	styleUrls: ["./pq1-step4.component.scss"],
})
export class Pq1Step4Component implements OnInit {
	@Output() refreshComponent = new EventEmitter<boolean>();
	@Input() form_key;
	@Input() form_request_id;
	@Input() project_data;
	@Input() supervision_data;
	@Input() template_data;
	@Input() allData;
	rejectedValidators = [];
	form: FormGroup;
	otherNotes: FormArray;
	constructor(
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		public router: Router,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.form = new FormGroup({
			transaction_request_id: new FormControl(this.form_request_id, [
				Validators.required,
			]),
			approving_status: new FormControl("", [Validators.required]),
			note: new FormControl("", this.rejectedValidators),
			notes: this.fb.array([]),
		});
		this.otherNotes = this.form.get("notes") as FormArray;
	}

	checkStatus() {
		if (
			this.form.get("approving_status").value == "Return" ||
			this.form.get("approving_status").value == "Reject"
		) {
			this.rejectedValidators = [Validators.required];
		} else {
			this.rejectedValidators = [];
		}
	}
	get getNotesFormGroup() {
		return this.form.get("notes") as FormArray;
	}
	notesRecords() {
		return this.fb.group({
			note: [""],
			tt_id: [""],
		});
	}
	addNotes() {
		this.otherNotes.push(this.notesRecords());
	}
	removeNotes(index) {
		this.otherNotes.removeAt(index);
	}

	public completeStep3() {
		if (this.form.valid) {
			let formData = new FormData();
			formData.append(
				"transaction_request_id",
				this.form.value.transaction_request_id
			);
			formData.append("approving_status", this.form.value.approving_status);
			if (this.form?.value?.approving_status) {
				formData.append("note", this.form.value.note);
			}
			this.otherNotes.controls.forEach((el, key) => {
				formData.append(`notes[${key}]`, el.get("note").value || "");
				formData.append(`notes[${key}]`, el.get("tt_id").value || "");
			});
			this.sweetAlert
				.confirmMessage("Are you sure?", "Confirm")
				.then((result) => {
					if (result.isConfirmed) {
						this.spinner.show();
						this.ds
							.post(`form/${this.form_key}/sign_request`, formData)
							.subscribe(
								(data) => {
									if (data.status) {
										this.spinner.hide();
										this.sweetAlert.successToast(
											this.lang.transform("lang_data_sent_successfully"),
											1700
										);
										setTimeout(() => {
											this.refreshComponent.emit(true);
										}, 2000);
									} else {
										this.spinner.hide();
										this.alert.error(data?.error);
									}
								},
								(error) => {
									this.spinner.hide();
									this.alert.error(error);
								}
							);
					}
				});
		} else {
			this.alert.error(this.lang.transform("lang_all_fields_required"));
		}
	}
}
