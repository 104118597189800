import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import {
	hijriCalendar,
	MbscCalendarEvent,
	MbscEventcalendarOptions,
	MbscEventcalendarView,
	MbscPopup,
	MbscPopupOptions,
	Notifications,
} from "@mobiscroll/angular";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { locale } from "moment";
import moment from "moment";
import { FiltersModelComponent } from "app/routes/tasks/ticket-list/filters-model/filters-model.component";
import { ViewTaskComponent } from "app/routes/tasks/view-ticket/view-task/view-task.component";

@Component({
	selector: "app-design-tickets-calendar",
	templateUrl: "./design-tickets-calendar.component.html",
	styleUrls: ["./design-tickets-calendar.component.scss"],
})
export class DesignTicketsCalendarComponent implements OnInit {
	@Input() projects_work_area_id;
	//calendar
	hijriCalendar = hijriCalendar;
	localeAll = locale;
	view = "month";
	myTheme = "ios";
	resource_type = "status";
	calendarTheme = "ios";
	CalendarResourceType = "status";
	ticketUsers = [];
	filters_params = {
		ticket_no: null,
		ticket_type: null,
		start_date_task: null,
		end_date_task: null,
		start_date_ticket: null,
		end_date_ticket: null,
		task_no: null,
		important_task: null,
		admin_or_user: "1",
	};
	statusResources = [
		{
			id: 1,
			name: this.lang.transform("lang_new"),
			color: "#e20000",
		},
		{
			id: 2,
			name: this.lang.transform("lang_in_progress"),
			color: "#60e81a",
		},
		{
			id: 3,
			name: this.lang.transform("lang_confirm"),
			color: "#3ba7ff",
		},
		{
			id: 4,
			name: this.lang.transform("lang_done"),
			color: "#e25dd2",
		},
	];
	membersResources = [];
	ganttResources = [];
	status_events = [];
	member_events = [];
	gantt_events = [];
	allEvents = [];
	myEvents: MbscCalendarEvent[] = [];
	myResources = [...this.statusResources];
	filter_user = [];
	currentView = "calendar";
	@ViewChild("popup", { static: false }) tooltip!: MbscPopup;
	anchor: HTMLElement | undefined;
	currentEvent: any;
	popupOptions: MbscPopupOptions = {
		display: "anchored",
		touchUi: false,
		showOverlay: false,
		contentPadding: false,
		closeOnOverlayClick: true,
		width: 350,
	};
	isTimeline = false;
	priority_list = [];
	type_list = [];
	lang_key = localStorage.getItem("lang_key") || "en";
	nodata = this.lang.transform("lang_no_data");
	constructor(
		private ds: DataService,
		private ls: LocalStorage,
		private lang: LanguagePipe,
		private fb: FormBuilder,
		private dialog: MatDialog,
		private sweetAlert: SweetAlertService,
		private spinner: PreloaderService,
		private notify: Notifications
	) {}

	ngOnInit(): void {
		this.getCalenderData();
		this.getUsers();
	}
	//calendar
	refDate = moment().format("YYYY-MM-DD");
	getCalenderData(filters?) {
		let formData = new FormData();
		moment.locale("en");
		formData.append("work_area_id", this.projects_work_area_id || "");
		formData.append("start_date_task", filters?.start_date_task || "");
		formData.append("end_date_task", filters?.end_date_task || "");
		formData.append("start_date_ticket", filters?.start_date_ticket || "");
		formData.append("end_date_ticket", filters?.end_date_ticket || "");
		this.member_events = [];
		this.status_events = [];
		this.allEvents = [];
		this.ds
			.post("project_design/get_data_task_by_work_area", formData)
			.subscribe((res) => {
				if (res?.status) {
					this.allEvents = res?.data;
					if (this.currentView == "timeline") {
						this.calCalendarView.timeline.startDay =
							this.allEvents.length > 0
								? this.allEvents[0].start_date_task
								: this.refDate;
					}
					this.status_events = this.allEvents.map((el) => {
						return {
							start: el?.start_date_task,
							end: el?.end_nearly_task,
							title: el?.title,
							resource: +el?.task_status,
							task_id: el?.task_id,
							is_can_view: el?.is_can_view,
							ticket_id: el?.ticket_id,
							task_no: el?.task_no,
							ticket_title: el?.ticket_titel,
							start_date_ticket: el?.start_date_ticket,
							start_date_task: el?.start_date_task,
							end_date_task: el?.end_date_task || el?.end_nearly_task,
							task_progress: el?.task_progress,
							end_date_ticket:
								el?.end_date_ticket || el?.end_date_nearly_ticket,
							color:
								el?.task_status == "1"
									? "#e20000"
									: el?.task_status == "2"
									? "#60e81a"
									: el?.task_status == "3"
									? "#3ba7ff"
									: "#e25dd2",
							bgColor:
								el?.task_status == "1"
									? "#e2000087"
									: el?.task_status == "2"
									? "#3ca7069e"
									: el?.task_status == "3"
									? "#1b88e18c"
									: "#e25dd2b8",
						};
					});
					this.member_events = this.allEvents.map((el) => {
						return {
							start: el?.start_date_task,
							end: el?.end_nearly_task,
							title: el?.title,
							resource: el?.emps?.split("||"),
							task_id: el?.task_id,
							is_can_view: el?.is_can_view,
							ticket_id: el?.ticket_id,
							task_no: el?.task_no,
							ticket_title: el?.ticket_titel,
							start_date_ticket: el?.start_date_ticket,
							start_date_task: el?.start_date_task,
							end_date_task: el?.end_date_task || el?.end_nearly_task,
							task_progress: el?.task_progress,
							end_date_ticket:
								el?.end_date_ticket || el?.end_date_nearly_ticket,
							color:
								el?.task_status == "1"
									? "#e20000"
									: el?.task_status == "2"
									? "#60e81a"
									: el?.task_status == "3"
									? "#3ba7ff"
									: "#e25dd2",
							bgColor:
								el?.task_status == "1"
									? "#e2000087"
									: el?.task_status == "2"
									? "#3ca7069e"
									: el?.task_status == "3"
									? "#1b88e18c"
									: "#e25dd2b8",
						};
					});
					this.gantt_events = this.allEvents.map((el) => {
						return {
							start: el?.start_date_task,
							end: el?.end_nearly_task,
							title: `${el?.title} ${this.lang.transform("lang_progress")}: (${
								el?.task_progress
							})%`,
							resource: el?.task_id,
							task_id: el?.task_id,
							is_can_view: el?.is_can_view,
							ticket_id: el?.ticket_id,
							task_no: el?.task_no,
							ticket_title: el?.ticket_titel,
							start_date_ticket: el?.start_date_ticket,
							start_date_task: el?.start_date_task,
							end_date_task: el?.end_date_task || el?.end_nearly_task,
							task_progress: el?.task_progress,
							end_date_ticket:
								el?.end_date_ticket || el?.end_date_nearly_ticket,
							color:
								el?.task_status == "1"
									? "#e20000"
									: el?.task_status == "2"
									? "#60e81a"
									: el?.task_status == "3"
									? "#3ba7ff"
									: "#e25dd2",
							bgColor:
								el?.task_status == "1"
									? "#e2000087"
									: el?.task_status == "2"
									? "#3ca7069e"
									: el?.task_status == "3"
									? "#1b88e18c"
									: "#e25dd2b8",
						};
					});
					this.ganttResources = this.customizeTaskData(this.allEvents).map(
						(el) => {
							return {
								id: el?.id,
								name: el?.name,
								collapsed: true,
								children: el?.tasks?.map((task) => {
									return {
										id: task?.task_id,
										name: task?.title,
										start: task?.start_date_task,
										end: task?.end_nearly_task,
										task_id: task?.task_id,
										is_can_view: task?.is_can_view,
										ticket_id: task?.ticket_id,
										task_no: task?.task_no,
										ticket_title: task?.ticket_titel,
										start_date_ticket: task?.start_date_ticket,
										start_date_task: task?.start_date_task,
										end_date_task: task?.end_date_task || task?.end_nearly_task,
										task_progress: task?.task_progress,
										end_date_ticket:
											task?.end_date_ticket || task?.end_date_nearly_ticket,
										color:
											task?.task_status == "1"
												? "#e20000"
												: task?.task_status == "2"
												? "#60e81a"
												: task?.task_status == "3"
												? "#3ba7ff"
												: "#e25dd2",
										bgColor:
											task?.task_status == "1"
												? "#e2000087"
												: task?.task_status == "2"
												? "#3ca7069e"
												: task?.task_status == "3"
												? "#1b88e18c"
												: "#e25dd2b8",
									};
								}),
							};
						}
					);
					if (this.resource_type == "status") {
						this.myEvents = [];
						this.myEvents = [...this.status_events];
					} else if (this.resource_type == "members") {
						this.myEvents = [];
						this.myEvents = [...this.member_events];
					} else if (this.resource_type == "gantt") {
						this.myEvents = [];
						this.myEvents = [...this.gantt_events];
						this.myResources = [];
						this.myResources = [...this.ganttResources];
					}
				} else {
					this.member_events = [];
					this.status_events = [];
					this.gantt_events = [];
					this.myEvents = [];
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			});
	}
	getUsers() {
		this.ds.post("tasks/get_emp_all_task_calender", {}).subscribe((res) => {
			if (res?.status) {
				this.ticketUsers = res?.data;
				this.membersResources = res?.data?.map((el) => {
					return {
						id: el?.emp_id,
						name: el?.emp_name,
						color: "#e25dd2",
					};
				});
			}
		});
	}

	changeView(): void {
		setTimeout(() => {
			if (this.view == "day" && this.currentView == "timeline") {
				this.calCalendarView = {
					timeline: {
						type: "day",
						eventList: true,
						startDay: 1,
						endDay: 5,
					},
				};
			} else if (this.view == "week" && this.currentView == "timeline") {
				this.calCalendarView = {
					timeline: {
						type: "week",
						eventList: true,
					},
				};
			} else if (this.view == "month" && this.currentView == "timeline") {
				this.calCalendarView = {
					timeline: {
						type: "month",
						eventList: true,
					},
				};
			} else if (this.view == "day" && this.currentView == "calendar") {
				this.calCalendarView = {
					schedule: {
						type: "day",
					},
				};
			} else if (this.view == "week" && this.currentView == "calendar") {
				this.calCalendarView = {
					calendar: {
						type: "week",
					},
				};
			} else if (this.view == "month" && this.currentView == "calendar") {
				this.calCalendarView = {
					calendar: {
						type: "month",
					},
				};
			} else if (this.view == "year") {
				this.calCalendarView = {
					calendar: {
						type: "year",
					},
				};
			}
		});
	}

	changeType(): void {
		if (this.resource_type == "status") {
			this.myResources = [];
			this.myResources = [...this.statusResources];
			this.myEvents = [];
			this.myEvents = [...this.status_events];
		} else if (this.resource_type == "members") {
			this.myResources = [];
			this.myResources = [...this.membersResources];
			this.myEvents = [];
			this.myEvents = [...this.member_events];
		} else if (this.resource_type == "gantt") {
			this.myResources = [];
			this.myResources = [...this.ganttResources];
			this.myEvents = [];
			this.myEvents = [...this.gantt_events];
		}
	}

	changeUsers() {
		if (this.filter_user.length) {
			const memberSelectedResources = this.membersResources.filter((el) =>
				this.filter_user?.includes(el?.id)
			);
			const memberSelectedEvents = this.allEvents.filter((el) => {
				let returnVal = false;
				if (el?.emps) {
					for (let item of el?.emps?.split("||")) {
						if (this.filter_user?.includes(item)) {
							returnVal = true;
							break;
						} else {
							returnVal = false;
						}
					}
				}
				return returnVal;
			});
			this.member_events = memberSelectedEvents.map((el) => {
				return {
					start: el?.start_date_task,
					end: el?.end_nearly_task,
					title: el?.title,
					resource: el?.emps?.split("||"),
					task_id: el?.task_id,
					is_can_view: el?.is_can_view,
					ticket_id: el?.ticket_id,
					task_no: el?.task_no,
					ticket_title: el?.ticket_titel,
					start_date_ticket: el?.start_date_ticket,
					start_date_task: el?.start_date_task,
					end_date_task: el?.end_date_task || el?.end_nearly_task,
					task_progress: el?.task_progress,
					end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
					color:
						el?.task_status == "1"
							? "#e20000"
							: el?.task_status == "2"
							? "#60e81a"
							: el?.task_status == "3"
							? "#3ba7ff"
							: "#e25dd2",
					bgColor:
						el?.task_status == "1"
							? "#e2000087"
							: el?.task_status == "2"
							? "#3ca7069e"
							: el?.task_status == "3"
							? "#1b88e18c"
							: "#e25dd2b8",
				};
			});
			this.myResources = [...memberSelectedResources];
			this.myEvents = [...this.member_events];
		} else {
			this.myResources = [...this.membersResources];
			this.member_events = this.allEvents.map((el) => {
				return {
					start: el?.start_date_task,
					end: el?.end_nearly_task,
					title: el?.title,
					resource: el?.emps?.split("||"),
					task_id: el?.task_id,
					is_can_view: el?.is_can_view,
					ticket_id: el?.ticket_id,
					task_no: el?.task_no,
					ticket_title: el?.ticket_titel,
					start_date_ticket: el?.start_date_ticket,
					start_date_task: el?.start_date_task,
					end_date_task: el?.end_date_task || el?.end_nearly_task,
					task_progress: el?.task_progress,
					end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
					color:
						el?.task_status == "1"
							? "#e20000"
							: el?.task_status == "2"
							? "#60e81a"
							: el?.task_status == "3"
							? "#3ba7ff"
							: "#e25dd2",
					bgColor:
						el?.task_status == "1"
							? "#e2000087"
							: el?.task_status == "2"
							? "#3ca7069e"
							: el?.task_status == "3"
							? "#1b88e18c"
							: "#e25dd2b8",
				};
			});
			this.myEvents = [...this.member_events];
		}
	}

	showFilters() {
		const dialogRef = this.dialog.open(FiltersModelComponent, {
			width: "700px",
			data: {
				metaData: {
					priority_list: this.priority_list,
					type_list: this.type_list,
				},
				lastValues: this.filters_params,
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.filters_params = result;
				this.getCalenderData(this.filters_params);
			}
		});
	}
	//calendar
	calendarOptions: MbscEventcalendarOptions = {
		dragToCreate: false,
		dragToMove: true,
		dragToResize: true,
		dragTimeStep: 60,
		height: "700px",
		view: {
			calendar: { labels: true },
		},
		onEventClick: (event, inst) => {
			if (event.event.is_can_view) {
				const dialogRef = this.dialog.open(ViewTaskComponent, {
					data: {
						task: {
							task_id: event.event.task_id,
							ticket_id: event?.event?.ticket_id,
						},
					},
					width: "970px",
					height: "800px",
					closeOnNavigation: true,
				});
			} else {
				this.sweetAlert.errorToast(
					this.lang.transform("lang_access_denied"),
					2000
				);
			}
		},
		onEventHoverIn: (args, inst) => {
			const event: any = args.event;
			this.currentEvent = event;
			this.anchor = args.domEvent.target;
			this.tooltip.open();
		},
		onEventDragStart: (args) => {
			this.tooltip.close();
		},
		onEventUpdated: (args) => {
			setTimeout(() => {
				this.notify.confirm({
					title: "Are you sure you want to proceed?",
					message: "If you press yes, you will update the task dates.",
					okText: "Yes",
					cancelText: "No",
					callback: (res) => {
						if (res) {
							this.changeTaskDates(args?.event, args);
						} else {
							const index = this.myEvents.findIndex(
								(x) => x.id === args.event.id
							);
							const newEventList = [...this.myEvents];
							newEventList.splice(index, 1, args.oldEvent);
							this.myEvents = newEventList;
						}
					},
				});
			});
		},
	};

	calCalendarView: MbscEventcalendarView = {
		calendar: { labels: true },
	};

	changeCalendarTimeline(event) {
		if (event?.checked) {
			this.currentView = "timeline";
			if (this.view == "day") {
				this.calCalendarView = {
					timeline: {
						type: "day",
						eventList: true,
					},
				};
			} else if (this.view == "week") {
				this.calCalendarView = {
					timeline: {
						type: "week",
						eventList: true,
					},
				};
			} else if (this.view == "month") {
				this.calCalendarView = {
					timeline: {
						type: "month",
						eventList: true,
					},
				};
			}
		} else {
			this.currentView = "calendar";
			if (this.view == "day") {
				this.calCalendarView = {
					schedule: {
						type: "day",
					},
				};
			} else if (this.view == "week") {
				this.calCalendarView = {
					calendar: {
						type: "week",
					},
				};
			} else if (this.view == "month") {
				this.calCalendarView = {
					calendar: {
						type: "month",
					},
				};
			}
		}
	}
	isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	onFilterAdmin(event) {
		if (event?.checked) {
			this.filters_params.admin_or_user = "2";
		} else {
			this.filters_params.admin_or_user = "1";
		}
		this.getCalenderData(this.filters_params);
	}
	mouseLeave(): void {
		this.tooltip.close();
	}

	//change task dates
	changeTaskDates(event, args?) {
		let start_data_formatted: any, end_data_formatted: any;
		if (event) {
			start_data_formatted = moment(event?.start).format("YYYY/MM/DD HH:mm:ss");
			end_data_formatted = moment(event?.end).format("YYYY/MM/DD HH:mm:ss");
		}
		if (start_data_formatted && end_data_formatted) {
			const difference_In_Time =
				event?.end?.getTime() - event?.start?.getTime();
			const difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
			let formData = new FormData();
			formData.append("task_id", event?.task_id || "");
			formData.append(
				"task_time",
				Math.ceil(difference_In_Days) + 1 + "" || ""
			);
			formData.append("start_date", start_data_formatted || "");
			formData.append("end_date", end_data_formatted || "");
			this.spinner.show();
			this.ds.post("tasks/update_start_end_task", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_success"),
							2000
						);
						this.notify.toast({
							message: "Event updated",
						});
					} else {
						this.sweetAlert.errorToast(res?.error, 4000);
						const index = this.myEvents.findIndex(
							(x) => x.id === args.event.id
						);
						const newEventList = [...this.myEvents];
						newEventList.splice(index, 1, args.oldEvent);
						this.myEvents = newEventList;
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						4000
					);
					const index = this.myEvents.findIndex((x) => x.id === args.event.id);
					const newEventList = [...this.myEvents];
					newEventList.splice(index, 1, args.oldEvent);
					this.myEvents = newEventList;
				}
			);
		}
	}

	customizeTaskData(data) {
		let o = {};
		let result = data.reduce((r, el) => {
			let e = el.ticket_id.slice(0, 2);
			if (!o[e]) {
				o[e] = {
					id: el.ticket_id,
					name: el.ticket_titel,
					tasks: [],
				};
				r.push(o[e]);
			}
			o[e].tasks.push(el);
			return r;
		}, []);
		return result;
	}
}
