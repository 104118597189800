import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
/* import CKEDITOR from '@ckeditor/ckeditor5-build-classic'; */
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
declare var $: any;
@Component({
  selector: "app-formpq1-edit2",
  templateUrl: "./formpq1-edit2.component.html",
  styleUrls: ["./formpq1-edit2.component.scss"],
})
export class Formpq1Edit2Component implements OnInit {
  // public Editor = ClassicEditor;
  @Input() form_key;
  @Input() form_request_id;
  @Input() edit_type = "";
  project_data: any = [];
  service_data: any = [];
  form: FormGroup;
  items: FormArray;
  error_status: boolean = false;
  submitted: boolean = false;
  error_message: string = "";
  data: any = [];
  data2: any = [];
  fieldenable = false;
  reuploadstatus: boolean = false;
  markstatus: boolean = false;
  reviewstatus: boolean = false;
  viewerstatus: boolean = false;
  stampstatus: boolean = false;
  attachstatus: boolean = false;
  othersstatus: boolean = false;
  stamps: any = [];
  filename = this.lang.transform("lang_select_files");
  isFile: boolean = false;
  attachments: FormArray;
  dynamic_table_fields: FormArray;
  others_fields: FormArray;
  languages: any = [];
  branches: any = [];
  loading = false;
  document_types: any = [];
  users: any = [];
  lang_key =
    localStorage.getItem("lang_key") == null
      ? "en"
      : localStorage.getItem("lang_key");
  quottypes: any = [];
  nationality: any = [];
  quotation_type_status = false;
  @ViewChild("contentData", {
    static: false,
  })
  contentdata: ElementRef;
  dynamiccontents: boolean = false;
  restrictions_fixed_required: boolean = true;
  restrictions_fixed_required_replace: any = [];
  restrictions_fixed_optional_items: any = [];
  dynamicrestrictions = false;
  show_item_fileds: boolean = false;
  fileslist: any = [];
  vat: any = [];
  subject: string = "";
  vat_name: string = "";
  constructor(
    public ds: DataService,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public fb: FormBuilder,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.build_form();
    this.get_data_edit();
  }
  public get_data_edit() {
    this.spinner.show();
    this.ds
      .getActionByUrl(
        [],
        "form/" + this.form_key + "/vr/" + this.form_request_id
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.lang_key = res.transactions_request.lang_key || this.lang_key;
            this.set_edit_data(res);
          } else {
            this.ds.dialogf("", res.error);
            this.router.navigate(["transactions/allforms/"]);
          }
        },
        (error) => {
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  public build_form() {
    this.form = new FormGroup({
      transaction_request_description: new FormControl(""),
      vat_key: new FormControl(""),
      items: this.fb.array([]),
    });
    this.items = this.form.get("items") as FormArray;
  }
  public items_group() {
    return this.form.get("items") as FormArray;
  }
  get itemsgroup() {
    return this.form.get("items") as FormArray;
  }
  public add_new_items() {
    this.items.push(this.item_records());
  }
  public item_records(): FormGroup {
    return this.fb.group({
      tt_id: new FormControl("", [Validators.required]),
      position: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      presence: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      nationality: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      team_number: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      experience: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      positions_salary: new FormControl(
        "",
        this.edit_type == "edit2" ? [Validators.required] : null
      ),
      basic_salary: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      salary_allowances: new FormControl(
        "",
        this.edit_type == "edit2" ? [Validators.required] : null
      ),
      actual_salary: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      administrative_costs: new FormControl(
        "",
        this.edit_type == "edit2" ? [Validators.required] : null
      ),
      total_cost_per_person_month: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      profit_ratio: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      total_salary_per_person_month: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      duration_of_months: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      visits_per_month: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      total_crew_cost_project: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      total_bid_of_project: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
      positions_task: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [Validators.required]
      ),
    });
  }
  public calculate_quotation(index) {
    let presence = this.items.controls[index].get("presence").value;
    let positions_salary: any =
      parseFloat(this.items.controls[index].get("positions_salary").value) >= 0
        ? parseFloat(this.items.controls[index].get("positions_salary").value)
        : 0;
    let salary_allowances: any =
      parseFloat(this.items.controls[index].get("salary_allowances").value) >= 0
        ? parseFloat(this.items.controls[index].get("salary_allowances").value)
        : 0;
    let administrative_costs: any =
      parseFloat(
        this.items.controls[index].get("administrative_costs").value
      ) >= 0
        ? parseFloat(
            this.items.controls[index].get("administrative_costs").value
          )
        : 0;
    let team_number: any =
      parseFloat(this.items.controls[index].get("team_number").value) >= 0
        ? parseFloat(this.items.controls[index].get("team_number").value)
        : 0;
    let experience: any =
      parseFloat(this.items.controls[index].get("experience").value) >= 0
        ? parseFloat(this.items.controls[index].get("experience").value)
        : 0;
    let profit_ratio: any =
      parseFloat(this.items.controls[index].get("profit_ratio").value) >= 0
        ? parseFloat(this.items.controls[index].get("profit_ratio").value)
        : 0;
    let basic_salary: any =
      parseFloat(positions_salary) + (parseFloat(experience) / 2) * 1000;
    this.items.controls[index]
      .get("basic_salary")
      .setValue(parseFloat(basic_salary).toFixed(2));
    let actual_salary: any =
      parseFloat(salary_allowances) * parseFloat(basic_salary);
    this.items.controls[index]
      .get("actual_salary")
      .setValue(parseFloat(actual_salary).toFixed(2));
    let total_cost_per_person_month: any = 0.0;
    let presence_type =
      presence && presence != undefined ? presence.keyword : "";
    if (presence_type == "resident") {
      this.items.controls[index].get("visits_per_month").disable();
      total_cost_per_person_month =
        parseFloat(administrative_costs) *
        parseFloat(actual_salary) *
        parseFloat(team_number);
    } else if (presence_type == "visitor") {
      this.items.controls[index].get("visits_per_month").enable();
      total_cost_per_person_month =
        (parseFloat(administrative_costs) *
          parseFloat(actual_salary) *
          parseFloat(team_number)) /
        30;
    }
    this.items.controls[index]
      .get("total_cost_per_person_month")
      .setValue(parseFloat(total_cost_per_person_month).toFixed(2));
    let total_salary_per_person_month: any =
      parseFloat(profit_ratio) * parseFloat(total_cost_per_person_month);
    this.items.controls[index]
      .get("total_salary_per_person_month")
      .setValue(parseFloat(total_salary_per_person_month).toFixed(2));
    let total_crew_cost_project: any = 0;
    let total_bid_of_project: any = 0;
    let duration_of_months: any =
      parseInt(this.items.controls[index].get("duration_of_months").value) >= 0
        ? parseInt(this.items.controls[index].get("duration_of_months").value)
        : 0;
    let visits_per_month: any =
      parseInt(this.items.controls[index].get("visits_per_month").value) >= 0
        ? parseInt(this.items.controls[index].get("visits_per_month").value)
        : 0;
    if (presence_type == "resident") {
      total_crew_cost_project =
        parseFloat(total_cost_per_person_month) * parseInt(duration_of_months);
      total_bid_of_project =
        parseFloat(total_salary_per_person_month) *
        parseInt(duration_of_months);
    } else if (presence_type == "visitor") {
      total_crew_cost_project =
        parseFloat(total_cost_per_person_month) *
        parseInt(duration_of_months) *
        parseInt(visits_per_month);
      total_bid_of_project =
        parseFloat(total_salary_per_person_month) *
        parseInt(duration_of_months) *
        parseInt(visits_per_month);
    }
    this.items.controls[index]
      .get("total_crew_cost_project")
      .setValue(parseFloat(total_crew_cost_project).toFixed(2));
    this.items.controls[index]
      .get("total_bid_of_project")
      .setValue(parseFloat(total_bid_of_project).toFixed(2));
  }
  public submit() {
    this.submitted = false;
    this.spinner.show();
    let param = new FormData();
    param.set("transaction_request_id", this.form_request_id);
    param.set("lang_key", this.lang_key);
    this.itemsgroup.value.forEach((v, k) => {
      param.set("teams[" + k + "][tt_id]", v.tt_id);
      param.set("teams[" + k + "][positions_salary]", v.positions_salary);
      param.set("teams[" + k + "][salary_allowances]", v.salary_allowances);
      param.set(
        "teams[" + k + "][administrative_costs]",
        v.administrative_costs
      );
    });
    this.ds.postActionByUrl(param, "form/FORM_PQ1/etc").subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.alert.success(res.msg);
          this.form.reset();
          setTimeout(() => {
            this.router.navigate([
              "/transactions/form/FORM_PQ1/vr/" + this.form_request_id,
            ]);
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.alert.error(
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  private set_edit_data(res) {
    this.vat_name = res.form_pq1_data.records.vat_name;
    this.subject = res.form_pq1_data.records.subject;
    if (
      res.form_pq1_quotation_teams_request &&
      res.form_pq1_quotation_teams_request.status
    ) {
      res.form_pq1_quotation_teams_request.records.forEach((v, index) => {
        this.items.push(this.item_records());
        this.items.controls[index].get("position").setValue({
          value: v.position,
          label: v.position_name,
          positions_salary: v.positions_salary,
          salary_allowances: v.salary_allowances,
          administrative_costs: v.administrative_costs,
        });
        this.items.controls[index].get("presence").setValue({
          label: v.presence_name,
          value: v.presence,
          keyword: v.presence,
        });
        this.items.controls[index].get("nationality").setValue({
          label: v.nationality_name,
          value: v.nationality,
        });
        this.items.controls[index].get("tt_id").setValue(v.tt_id);
        this.items.controls[index].get("team_number").setValue(v.team_number);

        this.items.controls[index].get("experience").setValue(v.experience);
        this.items.controls[index]
          .get("positions_salary")
          .setValue(parseFloat(v.positions_salary).toFixed(2));
        this.items.controls[index]
          .get("basic_salary")
          .setValue(parseFloat(v.basic_salary).toFixed(2));
        this.items.controls[index]
          .get("administrative_costs")
          .setValue(parseFloat(v.administrative_costs).toFixed(2));
        this.items.controls[index]
          .get("salary_allowances")
          .setValue(parseFloat(v.salary_allowances).toFixed(2));
        this.items.controls[index]
          .get("actual_salary")
          .setValue(parseFloat(v.actual_salary).toFixed(2));
        this.items.controls[index]
          .get("total_cost_per_person_month")
          .setValue(parseFloat(v.total_cost_per_person_month).toFixed(2));
        this.items.controls[index]
          .get("profit_ratio")
          .setValue(parseFloat(v.profit_ratio).toFixed(2));
        this.items.controls[index]
          .get("total_salary_per_person_month")
          .setValue(parseFloat(v.total_salary_per_person_month).toFixed(2));
        this.items.controls[index]
          .get("duration_of_months")
          .setValue(v.duration_of_months);
        this.items.controls[index]
          .get("visits_per_month")
          .setValue(v.visits_per_month);
        this.items.controls[index]
          .get("total_crew_cost_project")
          .setValue(parseFloat(v.total_crew_cost_project).toFixed(2));
        this.items.controls[index]
          .get("total_bid_of_project")
          .setValue(parseFloat(v.total_bid_of_project).toFixed(2));
        this.items.controls[index]
          .get("positions_task")
          .setValue(v.positions_task);
        this.calculate_quotation(index);
      });
    }
  }
}
