<div class="mbrl15 martb0">
	<div
		class="row"
		[ngStyle]="
			lang_key == 'ar'
				? {
						'padding-left': '10px',
						'padding-right': '10px'
				  }
				: null
		"
	>
		<div class="col-xs-12 col-12 pad0">
			<div class="stepper-progress">
				<div>
					<p>
						{{ "lang_selected_step" | language }}:
						<span class="bold">{{ stepsTitles[selectedIndex]?.label }}</span>
					</p>
					<p>
						{{ "lang_last_step_opened" | language }}:
						<span class="green bold">{{ lastStepOpenedShow }} &nbsp;</span>
						<!--  <button class="carousel-btn-pre albutton mnabrbutton mat-button custom_btn" >{{'lang_edit'|language}}</button> -->
						<i
							style="padding: 0 10px"
							class="fa fa-info-circle orangestatus-color"
							style="white-space: pre-line !important"
							matTooltip="{{ usersTooltip | language }}"
						></i>
						&nbsp;
						<i
							class="fa fa-pencil custom_icon"
							(click)="openModal(changeSignature)"
							*ngIf="is_admin == 1"
							matTooltip="{{ 'lang_edit' | language }}"
						>
						</i>
					</p>
					<p class="step-counter">
						{{ "lang_step" | language }} <b>{{ selectedIndex + 1 }}</b>
						{{ "lang_of" | language }}
						<b>{{ totalStepsCount }}</b>
					</p>
				</div>
				<mat-progress-bar mode="determinate" [value]="progressValue">
				</mat-progress-bar>
			</div>
			<div class="row pb-3">
				<div class="col-3 col-sm-2">
					<button
						(click)="moveStepper('prev')"
						class="carousel-btn-pre albutton mnabrbutton mat-button"
					>
						<i
							class="fa"
							[ngClass]="{
								'fa-chevron-left': lang_key == 'en',
								'fa-chevron-right': lang_key == 'ar'
							}"
						></i>
					</button>
				</div>
				<div class="col-6 col-sm-8"></div>
				<div class="col-3 col-sm-2">
					<button
						(click)="moveStepper('next')"
						class="carousel-btn-next albutton mnabrbutton mat-button"
					>
						<i
							class="fa"
							[ngClass]="{
								'fa-chevron-right': lang_key == 'en',
								'fa-chevron-left': lang_key == 'ar'
							}"
						></i>
					</button>
				</div>
			</div>

			<app-hrj1-asPdf-view
				[records]="allData?.form_hrj1_data?.records"
				[notes]="history"
				[status]="allData?.form_hrj1_data?.status"
				[lang_key]="lang_key"
			></app-hrj1-asPdf-view>
			<mat-horizontal-stepper
				#stepper
				class="pad0 steps13-stepper"
				[linear]="isLinear"
				[selectedIndex]="selectedIndex"
				(selectionChange)="selectionChange($event)"
			>
				<mat-step>
					<HRJ1-one-configrations
						*ngIf="allData?.steps?.CONFIGURATION && selectedIndex == 0"
						[form_request_id]="form_request_id"
						[form_key]="form_key"
						[actionPage]="actionPage"
						[template_data]="template_data"
					></HRJ1-one-configrations>
				</mat-step>
				<mat-step>
					<HRJ1-step-two
						*ngIf="allData?.steps?.EMPLOYEE && selectedIndex == 1"
						[form_url]="form_url"
						[form_key]="form_key"
						[allData]="allData"
						[form_request_id]="form_request_id"
						[project_data]="project_data"
						[supervision_data]="supervision_data"
						[template_data]="template_data"
					></HRJ1-step-two>
				</mat-step>
				<mat-step>
					<HRJ1-step-three
						*ngIf="allData?.steps?.DIRECT_MANAGER && selectedIndex == 2"
						[form_url]="form_url"
						[form_key]="form_key"
						[allData]="allData"
						[form_request_id]="form_request_id"
						[project_data]="project_data"
						[supervision_data]="supervision_data"
						[template_data]="template_data"
					>
					</HRJ1-step-three>
				</mat-step>
				<mat-step>
					<HRJ1-step-four
						*ngIf="allData?.steps?.HUMAN_RESOURCE_TEAM && selectedIndex == 3"
						[form_url]="form_url"
						[form_key]="form_key"
						[allData]="allData"
						[form_request_id]="form_request_id"
						[project_data]="project_data"
						[supervision_data]="supervision_data"
						[template_data]="template_data"
					></HRJ1-step-four>
				</mat-step>
				<mat-step>
					<HRJ1-step-five
						*ngIf="allData?.steps?.ACCOUNT_TEAM && selectedIndex == 4"
						[form_url]="form_url"
						[form_key]="form_key"
						[form_request_id]="form_request_id"
						[allData]="allData"
						[project_data]="project_data"
						[supervision_data]="supervision_data"
						[template_data]="template_data"
					></HRJ1-step-five>
				</mat-step>

				<mat-step>
					<HRJ1-step-six
						*ngIf="allData?.steps?.HUMAN_RESOURCE_MANAGER && selectedIndex == 5"
						[form_url]="form_url"
						[form_key]="form_key"
						[allData]="allData"
						[form_request_id]="form_request_id"
						[project_data]="project_data"
						[supervision_data]="supervision_data"
						[template_data]="template_data"
					></HRJ1-step-six>
				</mat-step>
				<mat-step>
					<app-HRJ1-step-seven
						*ngIf="
							(allData?.steps?.last || lastStepOpened == 'completed') &&
							selectedIndex == 6
						"
						[form_url]="form_url"
						[form_key]="form_key"
						[allData]="allData"
						[form_request_id]="form_request_id"
						[project_data]="project_data"
						[supervision_data]="supervision_data"
						[template_data]="template_data"
						[lastStepOpened]="lastStepOpened"
					></app-HRJ1-step-seven>
				</mat-step>
			</mat-horizontal-stepper>
		</div>
	</div>
</div>

<ng-template #changeSignature>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_change_signature" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modelRef.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="changeSignatureForm" class="from" (ngSubmit)="submit()">
			<div class="modal-body">
				<div class="form-group">
					<label>
						{{ "lang_name" | language }}
					</label>
					<ng-select
						[items]="users"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						[searchFn]="ds.customSearchFn"
						appendTo="body"
						formControlName="user_id"
						placeholder="{{ 'lang_users' | language }}"
						(search)="search_users($event)"
						(focus)="users = []"
					>
					</ng-select>
					<mat-error
						*ngIf="
							submitForm &&
							changeSignatureForm?.controls['user_id']?.errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
