<div class="theme viewquotation">
	<div class="row">
		<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
			<button
				*ngIf="actionPage == 'A'"
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				routerLink="/transactions/nBU2waRF8N"
			>
				{{ "lang_goto_list" | language }}
			</button>
			<button
				*ngIf="actionPage != 'A'"
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				routerLink="/tasks/alltasks"
			>
				{{ "lang_goto_list" | language }}
			</button>
		</div>
		<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
			<div class="dropdown" *ngIf="actionbuttons">
				<button
					type="button"
					mat-button
					[matMenuTriggerFor]="menu"
					class="col-12 mnabrbutton mat-accent"
				>
					{{ "lang_action" | language }}
					<mat-icon>keyboard_arrow_down</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button
						mat-menu-item
						*ngIf="actionbuttons.edit1"
						(click)="are_you_sure(form_key, form_request_id)"
						matTooltip="{{ 'lang_edit' | language }}"
					>
						{{ "lang_edit" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.edit2 && actionbuttons.preview"
						(click)="are_you_sure(form_key, form_request_id, 'edit2')"
						matTooltip="{{ 'lang_edit_cost' | language }}"
					>
						{{ "lang_edit_cost" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.signature"
						(click)="showverificationform('signature')"
						data-toggle="modal"
						data-target="#verifySMRDA"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_signature' | language }}"
					>
						{{ "lang_signature" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.marks"
						(click)="showverificationform('marks')"
						data-method="Markers"
						data-toggle="modal"
						data-target="#verifySMRDA"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_mark' | language }}"
					>
						{{ "lang_mark" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.opinion"
						(click)="showverificationform('opinion')"
						data-method="opinion"
						data-toggle="modal"
						data-target="#verifySMRDA"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_opinion' | language }}"
					>
						{{ "lang_opinion" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.reviewers"
						(click)="showverificationform('reviewers')"
						data-method="Reviewers"
						data-toggle="modal"
						data-target="#verifySMRDA"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_review' | language }}"
					>
						{{ "lang_review" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.doall"
						(click)="showverificationform('doall')"
						data-method="doall"
						data-toggle="modal"
						data-target="#verifySMRDA"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_do_all' | language }}"
					>
						{{ "lang_do_all" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.preview"
						(click)="preview_form_data()"
						matTooltip="{{ 'lang_preview' | language }}"
					>
						{{ "lang_preview" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.view"
						(click)="ds.preview_pdf_file(request.view_link, form_request_id)"
						matTooltip="{{ 'lang_view' | language }}"
					>
						{{ "lang_view" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.approval"
						(click)="empty_approve_form()"
						data-method="approval"
						data-toggle="modal"
						data-target="#verifyPassword"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_approve' | language }}"
					>
						{{ "lang_approve" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.delete && admin"
						(click)="delete_form_request(form_key, form_request_id)"
						matTooltip="{{ 'lang_delete' | language }}"
					>
						{{ "lang_delete" | language }}
					</button>
				</mat-menu>
			</div>
		</div>
		<div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
		<div class="col-md-2 col-sm-2 col-xs-12 mb-10"></div>
	</div>
	<section class="panel-expansion">
		<!--Document System Details-->
		<mat-accordion class="stylepanels">
			<mat-expansion-panel class="mpanel" [expanded]="true">
				<mat-expansion-panel-header class="mpanel-header"
					>{{ "lang_document_details" | language }}
				</mat-expansion-panel-header>
				<div class="material-panel-body communication">
					<div class="mbrl15">
						<div class="row mb-10">
							<div class="col-md-3 mb-10">
								<h6>
									{{ "lang_request_number" | language }} :
									<b>{{ request.transaction_request_id }}</b>
								</h6>
							</div>
							<div class="col-md-3 mb-10">
								<h6>
									{{ "lang_barcode" | language }} :
									<b>{{ request.tbv_barcodeData }}</b>
								</h6>
							</div>
							<div class="col-md-3 mb-10">
								<mat-icon
									class="fa-icon mnabricon"
									*ngIf="actionbuttons.view"
									(click)="
										ds.preview_pdf_file(
											request.view_link,
											request?.transaction_request_id
										)
									"
									matTooltip="{{ 'lang_view' | language }}"
									>picture_as_pdf</mat-icon
								>
							</div>
							<div class="col-md-3 mb-10">
								<h6>
									{{ "lang_status" | language }} :<b
										class="orangestatus statusdesign"
										>{{ request.transaction_request_status }}</b
									>
								</h6>
							</div>
						</div>
						<div class="row mb-10">
							<div class="col-md-3 mb-10">
								<h6>
									{{ "lang_created_by" | language }} :
									<b> {{ request.created_name }}</b>
								</h6>
							</div>
							<div class="col-md-3 mb-10">
								<h6>
									{{ "lang_created_date" | language }} :
									<b>{{ request.created_date }}</b>
								</h6>
							</div>
							<div class="col-md-3 mb-10" *ngIf="submitter">
								<h6>
									{{ "lang_submitted_by" | language }} :
									<b>{{ request.submitted_name }}</b>
								</h6>
							</div>
							<div class="col-md-3 mb-10" *ngIf="submitter">
								<h6>
									{{ "lang_submitter_date" | language }} :
									<b>{{ request.submitted_date }}</b>
								</h6>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<!--Main Transaction Description Details-->
		<mat-accordion class="stylepanels">
			<mat-expansion-panel class="mpanel" [expanded]="true">
				<mat-expansion-panel-header class="mpanel-header"
					>{{ "lang_general_info" | language }}
				</mat-expansion-panel-header>
				<div class="material-panel-body communication">
					<div class="mbrl15">
						<fieldset
							class="cardback fieldset mb-10"
							[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
						>
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 my-2">
									<div class="form-group">
										<label class="label">
											{{ "lang_customer_name" | translate }}:
										</label>
										<p class="head1 mx-1">{{ viewdata?.customer_name }}</p>
									</div>
								</div>

								<div class="col-md-4 col-sm-6 col-12 my-2">
									<div class="form-group">
										<label class="label">
											{{ "lang_subject" | translate }}:
										</label>
										<p class="head1 mx-1">{{ viewdata?.Subject }}</p>
									</div>
								</div>
								<div class="col-md-4 col-sm-6 col-12 my-2">
									<div class="form-group">
										<label class="label">
											{{ "lang_care" | translate }}:
										</label>
										<p class="head1 mx-1">{{ viewdata?.care }}</p>
									</div>
								</div>
								<div class="col-md-4 col-sm-6 col-12 my-2">
									<div class="form-group">
										<label class="label">
											{{ "lang_city" | translate }}:
										</label>
										<p class="head1 mx-1">{{ viewdata?.city }}</p>
									</div>
								</div>

								<div class="col-md-4 col-sm-6 col-12 my-2">
									<div class="form-group">
										<label class="label">
											{{ "lang_total_area_m2" | translate }}:
										</label>
										<p class="head1 mx-1">{{ viewdata?.total_area_m2 }}</p>
									</div>
								</div>

								<div class="col-md-4 col-sm-6 col-12 my-2">
									<div class="form-group">
										<label class="label">
											{{ "lang_duration_days" | translate }}:
										</label>
										<p class="head1 mx-1">{{ viewdata?.duration_days }}</p>
									</div>
								</div>

								<div class="col-md-4 col-sm-6 col-12 my-2">
									<div class="form-group">
										<label class="label">
											{{ "lang_main_occupancies" | translate }}:
										</label>
										<p class="head1 mx-1">{{ viewdata?.main_occupancies }}</p>
									</div>
								</div>
								<div class="col-md-4 col-sm-6 col-12 my-2">
									<div class="form-group">
										<label class="label"> {{ "lang_vat" | translate }}: </label>
										<p class="head1 mx-1">{{ viewdata?.vat_name }}</p>
									</div>
								</div>
								<div class="col-md-4 col-sm-6 col-12 my-2">
									<div class="form-group">
										<label class="label">
											{{ "lang_quotation_type" | translate }}:
										</label>
										<p class="head1 mx-1">{{ viewdata?.quotaion_type_name }}</p>
									</div>
								</div>
								<div class="col-md-12 col-sm-12 col-12 my-2">
									<div class="form-group">
										<label class="label">
											{{ "lang_description" | translate }}:
										</label>
										<p class="head1 mx-1">{{ viewdata?.description }}</p>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<!--Persons-->

		<ng-container *ngIf="viewdata?.quotaion_type_name == 'Standard'">
			<!-- Standard Step First-->
			<mat-accordion class="stylepanels">
				<mat-expansion-panel class="mpanel" [expanded]="false">
					<mat-expansion-panel-header class="mpanel-header"
						>{{ "lang_project_plan_requirements" | language }}
					</mat-expansion-panel-header>
					<div class="material-panel-body communication">
						<div class="mbrl15">
							<fieldset class="cardback fieldset mb-10">
								<table class="table table-bordered">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">
												{{ "lang_plan_title_english" | translate }}
											</th>
											<th scope="col">
												{{ "lang_plan_title_arabic" | translate }}
											</th>
											<th scope="col">
												{{ "lang_plan_technical_ids" | translate }}
											</th>
											<th scope="col">
												{{ "lang_plan_hour_value" | translate }}
											</th>
											<th scope="col">
												{{ "lang_plan_total_hours" | translate }}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="
												let item of form_pq2_project_plan_requirements;
												let i = index
											"
										>
											<th scope="row">{{ i + 1 }}</th>
											<td>{{ item?.plan_title_english }}</td>
											<td>{{ item?.plan_title_arabic }}</td>
											<td>{{ item?.plan_technical_ids }}</td>
											<td>{{ item?.plan_hour_value }}</td>
											<td>{{ item?.plan_total_hours }}</td>
										</tr>
									</tbody>
								</table>
								<!--   <div class="row" *ngFor="let item of form_pq2_project_plan_requirements;let i =index">
                            <div class="col-md-12">
                                <h4 class="number">
                                    {{'project_plan_no'|language}}. {{i+1}}
                                </h4>
                            </div>
                            <div class="col-md-4 col-sm-6 col-12 my-2">
                                <div class=" form-group">
                                    <label class="label"> {{'lang_plan_title_english'|translate}}: </label> 
                                    <p class="head1 mx-1">{{item?.plan_title_english}}</p>
                                </div>
                            </div>
                          
                            <div class="col-md-4 col-sm-6 col-12 my-2">
                                <div class=" form-group">
                                    <label class="label"> {{'lang_plan_title_arabic'|translate}}: </label> 
                                    <p class="head1 mx-1">{{item?.plan_title_arabic}}</p>
                                </div>
                            </div>
                        
                            <div class="col-md-4 col-sm-6 col-12 my-2">
                                <div class=" form-group">
                                    <label class="label"> {{'lang_plan_technical_ids'|translate}}: </label> 
                                    <p class="head1 mx-1">{{item?.plan_technical_ids}}</p>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-6 col-12 my-2">
                                <div class=" form-group">
                                    <label class="label"> {{'lang_plan_hour_value'|translate}}: </label> 
                                    <p class="head1 mx-1">{{item?.plan_hour_value}}</p>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-6 col-12 my-2">
                                <div class="form-group">
                                    <label class="label"> {{'lang_plan_total_hours'|translate}}: </label> 
                                    <p class="head1 mx-1">{{item?.plan_total_hours}}</p>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-6 col-12 my-2">
                                <div class=" form-group">
                                    <label class="label"> {{'lang_total_plan_quantities'|translate}}: </label> 
                                    <p class="head1 mx-1">{{item?.total_plan_quantities}}</p>
                                </div>
                            </div>
                          
                        </div> -->
							</fieldset>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>

			<!-- Standard Step Second-->
			<mat-accordion class="stylepanels">
				<mat-expansion-panel class="mpanel" [expanded]="true">
					<mat-expansion-panel-header class="mpanel-header"
						>{{ "lang_add_project_analysis" | language }}
					</mat-expansion-panel-header>
					<div class="material-panel-body communication">
						<div class="mbrl15">
							<fieldset class="cardback fieldset mb-10">
								<div class="row">
									<div class="col-md-12 col-sm-12 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_customer_name" | translate }}:
											</label>
											<p class="head1 mx-1">{{ viewdata?.customer_name }}</p>
										</div>
									</div>

									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_subject" | translate }}:
											</label>
											<p class="head1 mx-1">{{ viewdata?.Subject }}</p>
										</div>
									</div>
									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_care" | translate }}:
											</label>
											<p class="head1 mx-1">{{ viewdata?.care }}</p>
										</div>
									</div>
									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_city" | translate }}:
											</label>
											<p class="head1 mx-1">{{ viewdata?.city }}</p>
										</div>
									</div>

									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_total_area_m2" | translate }}:
											</label>
											<p class="head1 mx-1">{{ viewdata?.total_area_m2 }}</p>
										</div>
									</div>

									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_duration_days" | translate }}:
											</label>
											<p class="head1 mx-1">{{ viewdata?.duration_days }}</p>
										</div>
									</div>

									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_main_occupancies" | translate }}:
											</label>
											<p class="head1 mx-1">{{ viewdata?.main_occupancies }}</p>
										</div>
									</div>
									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_vat" | translate }}:
											</label>
											<p class="head1 mx-1">{{ viewdata?.vat_name }}</p>
										</div>
									</div>
									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_quotation_type" | translate }}:
											</label>
											<p class="head1 mx-1">
												{{ viewdata?.quotaion_type_name }}
											</p>
										</div>
									</div>
									<div class="col-md-12 col-sm-12 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_description" | translate }}:
											</label>
											<p class="head1 mx-1">{{ viewdata?.description }}</p>
										</div>
									</div>
								</div>

								<table class="table table-bordered">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">
												{{ "lang_plan_title_english" | translate }}
											</th>
											<th scope="col">
												{{ "lang_plan_title_arabic" | translate }}
											</th>
											<th scope="col">
												{{ "lang_plan_technical_ids" | translate }}
											</th>
											<th scope="col">
												{{ "lang_plan_hour_value" | translate }}
											</th>
											<th scope="col">
												{{ "lang_plan_total_hours" | translate }}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="
												let item of form_pq2_project_plan_requirements;
												let i = index
											"
										>
											<th scope="row">{{ i + 1 }}</th>
											<td>{{ item?.plan_title_english }}</td>
											<td>{{ item?.plan_title_arabic }}</td>
											<td>{{ item?.plan_technical_ids }}</td>
											<td>{{ item?.plan_hour_value }}</td>
											<td>{{ item?.plan_total_hours }}</td>
										</tr>
									</tbody>
								</table>
							</fieldset>
						</div>
						<div
							class="mbrl15"
							*ngFor="let proj_com of form_pq2_form_pq2_project_component"
						>
							<fieldset class="cardback fieldset mb-10">
								<div class="row">
									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_subject_english" | translate }}:
											</label>
											<p class="head1 mx-1">
												{{ proj_com?.project_component_subject_english }}
											</p>
										</div>
									</div>
									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_subject_arabic" | translate }}:
											</label>
											<p class="head1 mx-1">
												{{ proj_com?.project_component_subject_arabic }}
											</p>
										</div>
									</div>
									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_space" | translate }}:
											</label>
											<p class="head1 mx-1">
												{{ proj_com?.project_component_space }}
											</p>
										</div>
									</div>

									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{ "lang_building_quantity" | translate }}:
											</label>
											<p class="head1 mx-1">
												{{ proj_com?.project_component_building_quantity }}
											</p>
										</div>
									</div>

									<div class="col-md-12 my-2">
										<h5>
											{{ "lang_soil_sensor" | language }}
										</h5>
									</div>
									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{
													"lang_component_soil_sensor_cost_per_lmeter"
														| translate
												}}:
											</label>
											<p class="head1 mx-1">
												{{
													proj_com?.project_component_soil_sensor_cost_per_lmeter
												}}
											</p>
										</div>
									</div>

									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{
													"lang_project_soil_sensor_building_quantity"
														| translate
												}}:
											</label>
											<p class="head1 mx-1">
												{{
													proj_com?.project_component_soil_sensor_building_quantity
												}}
											</p>
										</div>
									</div>
									<div class="col-md-4 col-sm-6 col-12 my-2">
										<div class="form-group">
											<label class="label">
												{{
													"lang_project_soil_sensor_depth_level_quantity"
														| translate
												}}:
											</label>
											<p class="head1 mx-1">
												{{
													proj_com?.project_component_soil_sensor_depth_level_quantity
												}}
											</p>
										</div>
									</div>
									<!--  <div class="col-md-4 col-sm-6 col-12 my-2">
                                <div class=" form-group">
                                    <label class="label"> {{'lang_quotation_type'|translate}}: </label> 
                                    <p class="head1 mx-1">{{viewdata?.quotaion_type_name}}</p>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12 my-2">
                                <div class=" form-group">
                                    <label class="label"> {{'lang_description'|translate}}: </label> 
                                    <p class="head1 mx-1">{{viewdata?.description}}</p>
                                </div>
                            </div> -->
								</div>

								<table class="table table-bordered">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">
												{{ "lang_plan_name_english" | translate }}
											</th>
											<th scope="col">
												{{ "lang_plan_name_arabic" | translate }}
											</th>
											<th scope="col">
												{{ "lang_plan_quantity_count" | translate }}
											</th>
											<!--    <th scope="col">{{'lang_plan_technical_ids'|translate}}</th>
                                <th scope="col">{{'lang_plan_hour_value'|translate}}</th>
                                <th scope="col">{{'lang_plan_total_hours'|translate}}</th> -->
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="
												let plan of proj_com.plan_quantities;
												let ii = index
											"
										>
											<th scope="row">{{ ii + 1 }}</th>
											<td>{{ plan?.plan_title_english }}</td>
											<td>{{ plan?.plan_title_arabic }}</td>
											<td>{{ plan?.project_component_plan_quantity }}</td>
											<!-- <td>{{plan?.plan_hour_value}}</td>
                                <td>{{plan?.plan_total_hours}}</td> -->
										</tr>
									</tbody>
								</table>

								<table class="table table-bordered">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">
												{{ "lang_level_quantity" | translate }}
											</th>
											<th scope="col"></th>
											<!-- <th scope="col">{{'lang_plan_quantity_count'|translate}}</th> -->
											<!--    <th scope="col">{{'lang_plan_technical_ids'|translate}}</th>
                                <th scope="col">{{'lang_plan_hour_value'|translate}}</th>
                                <th scope="col">{{'lang_plan_total_hours'|translate}}</th> -->
										</tr>
									</thead>
									<tbody>
										<ng-container
											*ngFor="
												let plan of proj_com.project_component_level;
												let ii = index
											"
										>
											<tr>
												<th scope="row">{{ ii + 1 }}</th>
												<td>{{ plan?.project_component_levels_total_area }}</td>
												<td></td>
												<!--  <td>{{plan?.plan_title_arabic}}</td>
                                        <td>{{plan?.project_component_plan_quantity}}</td> -->
												<!-- <td>{{plan?.plan_hour_value}}</td>
                                        <td>{{plan?.plan_total_hours}}</td> -->
											</tr>
											<tr>
												<th colspan="4">{{ ii + 1 }}</th>
												<td>{{ plan?.project_component_levels_total_area }}</td>
												<td></td>
												<!--  <td>{{plan?.plan_title_arabic}}</td>
                                        <td>{{plan?.project_component_plan_quantity}}</td> -->
												<!-- <td>{{plan?.plan_hour_value}}</td>
                                        <td>{{plan?.plan_total_hours}}</td> -->
											</tr>
										</ng-container>
									</tbody>
								</table>
							</fieldset>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>

			<!-- Standard Step Third-->
			<mat-accordion class="stylepanels">
				<mat-expansion-panel class="mpanel" [expanded]="true">
					<mat-expansion-panel-header class="mpanel-header"
						>{{ request.transaction_request_description }}
					</mat-expansion-panel-header>
					<div class="material-panel-body communication">
						<div class="mbrl15">
							<fieldset class="cardback fieldset mb-10"></fieldset>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>

			<!-- Standard Step Fourth-->
			<mat-accordion class="stylepanels">
				<mat-expansion-panel class="mpanel" [expanded]="true">
					<mat-expansion-panel-header class="mpanel-header"
						>{{ request.transaction_request_description }}
					</mat-expansion-panel-header>
					<div class="material-panel-body communication">
						<div class="mbrl15">
							<fieldset class="cardback fieldset mb-10"></fieldset>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</ng-container>

		<ng-container *ngIf="viewdata?.quotaion_type_name != 'Standard'">
			<!-- Open Step First-->
			<mat-accordion class="stylepanels">
				<mat-expansion-panel class="mpanel" [expanded]="true">
					<mat-expansion-panel-header class="mpanel-header"
						>{{ request.transaction_request_description }}
					</mat-expansion-panel-header>
					<div class="material-panel-body communication">
						<div class="mbrl15">
							<fieldset class="cardback fieldset mb-10">
								<div class="row"></div>
							</fieldset>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>

			<!-- Open Step Second-->
			<mat-accordion class="stylepanels">
				<mat-expansion-panel class="mpanel" [expanded]="true">
					<mat-expansion-panel-header class="mpanel-header"
						>{{ request.transaction_request_description }}
					</mat-expansion-panel-header>
					<div class="material-panel-body communication">
						<div class="mbrl15">
							<fieldset class="cardback fieldset mb-10"></fieldset>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>

			<!-- Open Step Third-->
			<mat-accordion class="stylepanels">
				<mat-expansion-panel class="mpanel" [expanded]="true">
					<mat-expansion-panel-header class="mpanel-header"
						>{{ request.transaction_request_description }}
					</mat-expansion-panel-header>
					<div class="material-panel-body communication">
						<div class="mbrl15">
							<fieldset class="cardback fieldset mb-10"></fieldset>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>

			<!-- Open Step Fourth-->
			<mat-accordion class="stylepanels">
				<mat-expansion-panel class="mpanel" [expanded]="true">
					<mat-expansion-panel-header class="mpanel-header"
						>{{ request.transaction_request_description }}
					</mat-expansion-panel-header>
					<div class="material-panel-body communication">
						<div class="mbrl15">
							<fieldset class="cardback fieldset mb-10"></fieldset>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</ng-container>

		<!--Persons-->
		<mat-accordion class="stylepanels">
			<mat-expansion-panel class="mpanel">
				<mat-expansion-panel-header class="mpanel-header"
					>{{ "lang_person_details" | language }}
				</mat-expansion-panel-header>
				<div class="material-panel-body communication">
					<div class="mbrl15">
						<div class="col-md-12 pad0">
							<div class="table-responsive customResponsive">
								<table border="1" class="table CustomTable">
									<thead>
										<tr>
											<td>#</td>
											<td>{{ "lang_name" | language }}</td>
											<td>{{ "lang_type" | language }}</td>
											<td>{{ "lang_view" | language }}</td>
											<td>{{ "lang_view_time" | language }}</td>
											<td>{{ "lang_last_view_time" | language }}</td>
											<td>{{ "lang_action" | language }}</td>
											<td>{{ "lang_datetime" | language }}</td>
										</tr>
									</thead>
									<tbody *ngIf="persons.length > 0" class="AppendList">
										<tr
											*ngFor="
												let person of persons;
												let i = index;
												let odd = odd;
												let even = even
											"
											[ngClass]="{ odd: odd, even: even }"
										>
											<td>{{ i + 1 }}</td>
											<td>{{ person.person_name }}</td>
											<td>{{ person.transaction_persons_type }}</td>
											<td *ngIf="person.transactions_persons_view == '1'">
												<i
													class="fa fa-check faicon successgreen"
													aria-hidden="true"
												></i>
											</td>
											<td *ngIf="person.transactions_persons_view == '0'">
												<i
													class="fa fa-check faicon silver"
													aria-hidden="true"
												></i>
											</td>
											<td>{{ person.transactions_persons_view_datetime }}</td>
											<td>
												{{
													person.transactions_persons_view_datetime_lastupdate
												}}
											</td>
											<td
												*ngIf="person.transactions_persons_action_status == 1"
											>
												<i
													class="fa fa-check faicon successgreen"
													aria-hidden="true"
												></i>
											</td>
											<td
												*ngIf="person.transactions_persons_action_status == 0"
											>
												<i
													class="fa fa-check faicon silver"
													aria-hidden="true"
												></i>
											</td>
											<td>{{ person.transactions_persons_action_datetime }}</td>
										</tr>
									</tbody>
									<tbody *ngIf="persons.length < 1" class="AppendList">
										<tr class="odd">
											<td colspan="7" class="nodata" align="center">
												{{ "lang_no_data" | language }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<!--Notes-->
		<mat-accordion class="stylepanels">
			<mat-expansion-panel class="mpanel">
				<mat-expansion-panel-header class="mpanel-header"
					>{{ "lang_notes" | language }}
				</mat-expansion-panel-header>
				<div class="material-panel-body communication">
					<div class="mbrl15">
						<div class="col-md-12 pad0">
							<div class="table-responsive customResponsive">
								<table border="1" class="table CustomTable">
									<thead>
										<tr>
											<td>#</td>
											<td>{{ "lang_notes" | language }}</td>
											<td>{{ "lang_writer" | language }}</td>
											<td>{{ "lang_ondate" | language }}</td>
										</tr>
									</thead>
									<tbody *ngIf="notes.length > 0" class="AppendList">
										<tr
											*ngFor="
												let note of notes;
												let i = index;
												let odd = odd;
												let even = even
											"
											[ngClass]="{ odd: odd, even: even }"
										>
											<td>{{ i + 1 }}</td>
											<td>{{ note.transactions_notes_text }}</td>
											<td>{{ note.transactions_notes_user_name }}</td>
											<td>{{ note.transactions_notes_datetime }}</td>
										</tr>
									</tbody>
									<tbody *ngIf="notes.length == '0'" class="AppendList">
										<tr class="odd">
											<td colspan="5" class="nodata" align="center">
												{{ "lang_no_data" | language }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<!--Attachments-->
		<mat-accordion class="stylepanels">
			<mat-expansion-panel class="mpanel">
				<mat-expansion-panel-header class="mpanel-header"
					>{{ "lang_attachments" | language }}
				</mat-expansion-panel-header>
				<div class="material-panel-body communication">
					<div class="mbrl15">
						<div class="col-md-12 pad0">
							<div class="table-responsive customResponsive">
								<table border="1" class="table CustomTable">
									<thead>
										<tr>
											<td>#</td>
											<td>{{ "lang_title" | language }}</td>
											<td>{{ "lang_attach_with_pdf" | language }}</td>
											<td>{{ "lang_official_paper" | language }}</td>
											<td>{{ "lang_action" | language }}</td>
										</tr>
									</thead>
									<tbody *ngIf="files.length != '0'" class="AppendList">
										<tr
											*ngFor="
												let file of files;
												let i = index;
												let odd = odd;
												let even = even
											"
											[ngClass]="{ odd: odd, even: even }"
										>
											<td>{{ i + 1 }}</td>
											<td>{{ file.form_file_attach_title }}</td>
											<td
												*ngIf="file.form_file_attach_with_the_document == '1'"
											>
												{{ "lang_yes" | language }}
											</td>
											<td
												*ngIf="file.form_file_attach_with_the_document !== '1'"
											>
												{{ "lang_no" | language }}
											</td>
											<td *ngIf="file.form_file_print_official_paper == '1'">
												{{ "lang_yes" | language }}
											</td>
											<td *ngIf="file.form_file_print_official_paper !== '1'">
												{{ "lang_no" | language }}
											</td>
											<td>
												<mat-icon
													(click)="
														ds.preview_pdf_file(
															file.link,
															file?.form_file_attach_title
														)
													"
													class="mnabricon"
													>link
												</mat-icon>
											</td>
										</tr>
									</tbody>
									<tbody *ngIf="files.length == '0'" class="AppendList">
										<tr class="odd">
											<td colspan="5" class="nodata" align="center">
												{{ "lang_no_data" | language }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<!--History-->
		<mat-accordion class="stylepanels">
			<mat-expansion-panel class="mpanel">
				<mat-expansion-panel-header class="mpanel-header"
					>{{ "lang_history" | language }}
				</mat-expansion-panel-header>
				<div class="material-panel-body communication">
					<div class="mbrl15">
						<div class="col-md-12 pad0">
							<div class="table-responsive customResponsive">
								<table border="1" class="table CustomTable">
									<thead>
										<tr>
											<td>#</td>
											<td>{{ "lang_name" | language }}</td>
											<td>{{ "lang_notes" | language }}</td>
											<td>{{ "lang_result" | language }}</td>
											<td>{{ "lang_ondate" | language }}</td>
										</tr>
									</thead>
									<tbody *ngIf="history.length > 0" class="AppendList">
										<tr
											*ngFor="
												let his of history;
												let i = index;
												let odd = odd;
												let even = even
											"
											[ngClass]="{ odd: odd, even: even }"
										>
											<td>{{ i + 1 }}</td>
											<td>{{ his.transactions_records_user_name }}</td>
											<td>{{ his.transactions_records_note }}</td>
											<td>{{ his.result }}</td>
											<td>{{ his.transactions_records_datetime }}</td>
										</tr>
									</tbody>
									<tbody *ngIf="history.length < 1" class="AppendList">
										<tr class="odd">
											<td colspan="4" class="nodata" align="center">
												{{ "lang_no_data" | language }}
											</td>
										</tr>
									</tbody>
								</table>
								<br />
								<div
									class="d-flex justify-content-between"
									*ngIf="history.length > 0"
								>
									<ngb-pagination
										[collectionSize]="collectionSize"
										[maxSize]="3"
										[rotate]="true"
										[ellipses]="false"
										[boundaryLinks]="true"
										[(page)]="page"
										[pageSize]="pageSize"
										(pageChange)="refreshLists()"
									></ngb-pagination>
									<select
										class="customselect"
										[(ngModel)]="pageSize"
										(change)="refreshLists()"
									>
										<option [ngValue]="10">10</option>
										<option [ngValue]="20">20</option>
										<option [ngValue]="50">50</option>
										<option [ngValue]="100">100</option>
										<option [ngValue]="250">250</option>
										<option [ngValue]="500">500</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</section>
	<div class="row">
		<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
			<button
				*ngIf="actionPage == 'A'"
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				routerLink="/transactions/nBU2waRF8N"
			>
				{{ "lang_goto_list" | language }}
			</button>
			<button
				*ngIf="actionPage != 'A'"
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				routerLink="/tasks/alltasks"
			>
				{{ "lang_goto_list" | language }}
			</button>
		</div>
		<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
			<div class="dropdown" *ngIf="actionbuttons">
				<button
					type="button"
					mat-button
					[matMenuTriggerFor]="menu"
					class="col-12 mnabrbutton mat-accent"
				>
					{{ "lang_action" | language }}
					<mat-icon>keyboard_arrow_down</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button
						mat-menu-item
						*ngIf="actionbuttons.edit1"
						(click)="are_you_sure(form_key, form_request_id)"
						matTooltip="{{ 'lang_edit' | language }}"
					>
						{{ "lang_edit" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.edit2 && actionbuttons.preview"
						(click)="are_you_sure(form_key, form_request_id, 'edit2')"
						matTooltip="{{ 'lang_edit_cost' | language }}"
					>
						{{ "lang_edit_cost" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.signature"
						(click)="showverificationform('signature')"
						data-toggle="modal"
						data-target="#verifySMRDA"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_signature' | language }}"
					>
						{{ "lang_signature" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.marks"
						(click)="showverificationform('marks')"
						data-method="Markers"
						data-toggle="modal"
						data-target="#verifySMRDA"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_mark' | language }}"
					>
						{{ "lang_mark" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.opinion"
						(click)="showverificationform('opinion')"
						data-method="opinion"
						data-toggle="modal"
						data-target="#verifySMRDA"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_opinion' | language }}"
					>
						{{ "lang_opinion" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.reviewers"
						(click)="showverificationform('reviewers')"
						data-method="Reviewers"
						data-toggle="modal"
						data-target="#verifySMRDA"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_review' | language }}"
					>
						{{ "lang_review" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.doall"
						(click)="showverificationform('doall')"
						data-method="doall"
						data-toggle="modal"
						data-target="#verifySMRDA"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_do_all' | language }}"
					>
						{{ "lang_do_all" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.preview"
						(click)="preview_form_data()"
						matTooltip="{{ 'lang_preview' | language }}"
					>
						{{ "lang_preview" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.view"
						(click)="ds.preview_pdf_file(request.view_link, form_request_id)"
						matTooltip="{{ 'lang_view' | language }}"
					>
						{{ "lang_view" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.approval"
						(click)="empty_approve_form()"
						data-method="approval"
						data-toggle="modal"
						data-target="#verifyPassword"
						data-backdrop="static"
						data-keyboard="false"
						matTooltip="{{ 'lang_approve' | language }}"
					>
						{{ "lang_approve" | language }}
					</button>
					<button
						mat-menu-item
						*ngIf="actionbuttons.delete && admin"
						(click)="delete_form_request(form_key, form_request_id)"
						matTooltip="{{ 'lang_delete' | language }}"
					>
						{{ "lang_delete" | language }}
					</button>
				</mat-menu>
			</div>
		</div>
		<div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
		<div class="col-md-2 col-sm-2 col-xs-12 mb-10"></div>
	</div>
</div>
<div class="modal" id="verifySMRDA">
	<div class="modal-dialog">
		<div class="modal-content">
			<form [formGroup]="form" class="form" (ngSubmit)="confirmVerification()">
				<div class="modal-header" *ngIf="verifylist.length > 0">
					<h4 class="modal-title">
						{{ "lang_complete_verification" | language }}
					</h4>
					<button type="button" class="close" data-dismiss="modal">
						&times;
					</button>
				</div>
				<div class="modal-body" *ngIf="verifylist.length > 0">
					<p>
						# {{ request.transaction_request_id }}
						{{ request.transaction_request_description }}
					</p>
					<app-alert id="default-alert"></app-alert>
					<input type="hidden" formControlName="transaction_persons_type" />
					<input type="hidden" formControlName="do" />
					<div class="form-group">
						<label class="mb-10"
							>{{ "lang_how_to_send_verification" | language }} :
						</label>
						<div
							*ngFor="let option of verifylist"
							class="custom-control custom-radio mb-10"
						>
							<input
								type="radio"
								class="custom-control-input zindex9999"
								value="{{ option.value }}"
								formControlName="sender_type"
								name="sender_type"
							/>
							<label
								class="custom-control-label"
								*ngIf="option.value == 'phone'"
								>{{ "lang_mobile" | language }}
								<b>( {{ option.label }} ) </b></label
							>
							<label
								class="custom-control-label"
								*ngIf="option.value == 'email'"
								>{{ "lang_email" | language }}
								<b>( {{ option.label }} ) </b></label
							>
							<label
								class="custom-control-label"
								*ngIf="option.value == 'whatsapp'"
								>{{ "lang_whatsapp" | language }}
								<b>( {{ option.label }} ) </b></label
							>
						</div>
					</div>
					<div class="form-group" *ngIf="sendstatus">
						<label for="verification_code"
							>{{ "lang_enter_verification_code" | language }}:
						</label>
						<input
							type="text"
							required
							class="form-control almnabrformcontrol"
							formControlName="transactions_persons_action_code"
						/>
					</div>
					<div class="row">
						<div class="col-md-4 col-xs-12">
							<button
								type="button"
								class="albutton mnabrbutton mat-accent"
								mat-button
								[disabled]="sendcodestatus"
								(click)="sendVerificationCode()"
							>
								{{ codemsg }}
							</button>
						</div>
						<div class="col-md-4 col-xs-12">
							<button
								type="submit"
								*ngIf="sendstatus"
								class="albutton mnabrbutton mat-accent"
								mat-button
							>
								{{ "lang_do_verification" | language }}
							</button>
						</div>
						<div class="col-md-4 col-xs-12">
							<button
								type="button"
								class="albutton mnabrbutton mat-accent"
								mat-button
								data-dismiss="modal"
							>
								{{ "lang_close" | language }}
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<div class="modal" id="verifyPassword">
	<div class="modal-dialog">
		<div class="modal-content">
			<form [formGroup]="approve" class="approve" (ngSubmit)="approve_form()">
				<div class="modal-header">
					<h4 class="modal-title">{{ "lang_approve" | language }}</h4>
					<button type="button" class="close" data-dismiss="modal">
						&times;
					</button>
				</div>
				<div class="modal-body">
					<p>
						# {{ request.transaction_request_id }}
						{{ request.transaction_request_description }}
					</p>
					<div class="form-group">
						<label for="password">{{ "lang_password" | language }}: </label>
						<input
							type="password"
							required
							class="form-control almnabrformcontrol"
							formControlName="user_pass"
						/>
						<input
							type="hidden"
							required
							class="form-control almnabrformcontrol"
							formControlName="transaction_request_id"
						/>
					</div>
					<div class="row">
						<div class="col-md-4 col-xs-12">
							<button
								type="submit"
								class="albutton mnabrbutton mat-accent"
								mat-button
							>
								{{ "lang_approve" | language }}
							</button>
						</div>
						<div class="col-md-4 col-xs-12">
							<button
								type="button"
								class="albutton mnabrbutton mat-accent"
								mat-button
								data-dismiss="modal"
							>
								{{ "lang_close" | language }}
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
