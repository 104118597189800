import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-formeste-view-asdpf",
  templateUrl: "./formeste-view-asdpf.component.html",
  styleUrls: ["./formeste-view-asdpf.component.scss"],
})
export class FormesteViewAsdpfComponent implements OnInit {
  @Input() formSTEData;
  @Input() allData;
  lang_key = localStorage.getItem("lang_key") || "en";
  constructor() {}
  ngOnInit(): void {}
}
