import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
	FormControl,
	Validators,
	FormGroup,
	FormBuilder,
	FormArray,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { platformRequirements } from "app/routes/transactions/FORM_PS_TR1/formdata";
import { DwsrService } from "app/routes/transactions/FORM_PS_TR1/services/dwsr.service";
import { ByPhasesComponent } from "app/routes/transactions/FORM_PS_TR1/steps/t-step1/by-phases/by-phases.component";
import { GeneralNumbersComponent } from "app/routes/transactions/FORM_PS_TR1/steps/t-step1/general-numbers/general-numbers.component";

@Component({
	selector: "app-si-step1",
	templateUrl: "./si-step1.component.html",
	styleUrls: ["./si-step1.component.scss"],
})
export class SiStep1Component implements OnInit {
	testArray = [
		{
			label:
				"jdfksdfa sdjkfjsdkg jkdfgjksdngjk sdafgkgmlsdg dsklagnkl sdlkgnkldsgn ergjnerlng s,sdalgkndlsg iwegnrglnsd sdlgnkslgn slgkn",
			value: 0,
		},
		{
			label:
				"jdfksdfa sdjkfjsdkg jkdfgjksdngjk sdafgkgmlsdg dsklagnkl sdlkgnkldsgn ergjnerlng s,sdalgkndlsg iwegnrglnsd sdlgnkslgn slgkn",
			value: 0,
		},
		{
			label:
				"jdfksdfa sdjkfjsdkg jkdfgjksdngjk sdafgkgmlsdg dsklagnkl sdlkgnkldsgn ergjnerlng s,sdalgkndlsg iwegnrglnsd sdlgnkslgn slgkn",
			value: 0,
		},
	];
	testControl = new FormControl();
	@Input() form_key;
	@Input() form_request_id;
	@Input() project_data;
	@Input() supervision_data;
	@Input() template_data;
	@Input() form_url;
	step2IsValid = new FormControl(null, [Validators.required]);
	lang_form: FormGroup;
	form: FormGroup;
	work_sites_form: FormGroup;
	work_level_form: FormGroup;
	platform_form: FormGroup;
	si_form: FormGroup;
	selectmultiple = false; //choose multiple zone or multiple Block or Cluster
	nodata: string = this.lang.transform("lang_loading");
	filename: string = this.lang.transform("lang_select_files");
	user_type = this.ls.getItem("user_type_id") || 0;
	lang_key =
		localStorage.getItem("lang_key") == null
			? "en"
			: localStorage.getItem("lang_key");
	general_different_cluster_allowed: any = "1";
	phases_different_cluster_allowed: any = "0";
	languages: any = [];
	gNumbers: any = [];
	work_levels: any = [];
	platformData: any = [];
	platformRequirements: any = platformRequirements;
	submitted: boolean = false;
	page_no: any = 1;
	page_size: any = 10;
	generalNumbersData = [];
	phasesData = [];
	units_for_all: any;
	work_levels_for_all = [];
	work_levels_for_all_control = new FormControl(null, Validators.required);
	si_name_array = [];
	constructor(
		public ls: LocalStorage,
		private dwsrService: DwsrService,
		public ds: DataService,
		public dialog: MatDialog,
		public datepipe: DatePipe,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public changeDetectorRef: ChangeDetectorRef
	) {}
	ngOnInit(): void {
		this.get_required_data("tc/tlanguages", "lang");
		this.lang_form = new FormGroup({
			lang_key: new FormControl(this.lang_key, [Validators.required]), // By General Number, By Phases, For All
		});

		this.work_sites_form = new FormGroup({
			work_site: new FormControl("", [Validators.required]), // By General Number, By Phases, For All
			separate: new FormControl("0"),
		});

		this.work_level_form = new FormGroup({
			work_levels: new FormControl(null, [Validators.required]),
		});
		this.platform_form = new FormGroup({
			platforms: new FormControl(null),
		});
		this.si_form = this.fb.group({
			si_name: [null],
			new_si_name: [""],
			attachments: this.fb.array([]),
		});

		// demo code
		this.si_name_array = [
			{
				label: "Site Instractions one",
				value: 1,
			},
			{
				label: "Site Instractions two",
				value: 2,
			},
			{
				label: "Site Instractions three",
				value: 3,
			},
		];
	}
	public get_required_data(url, key) {
		this.languages = [];
		this.ds.getActionByUrl([], url).subscribe((res) => {
			this.spinner.hide();
			if (res.status) {
				if (key === "lang") {
					this.languages = res.records;
				}
			}
		});
	}
	//upload multiple files starts
	get filesFormGroup() {
		return this.si_form.get("attachments") as FormArray;
	}

	//Search For Step 2 Starts Work Levels
	public search_required_datas(type?) {
		// this.work_sites_form.get("work_site_general_nos_for_all_units").setValue(null);
		if (type == "ALL") {
			let param = new FormData();
			param.append(
				"projects_work_area_id",
				this.supervision_data?.projects_work_area_id || 0
			);
			param.append("platform_code_system", this.form_key || "");
			param.append(
				"work_site",
				this.work_sites_form.get("work_site").value || ""
			);
			param.append("work_site_type", type || "");
			param.append(
				"work_site_zones",
				this.work_sites_form.get("work_site_zones").value || ""
			);
			param.append(
				"work_site_blocks",
				this.work_sites_form.get("work_site_blocks").value || ""
			);
			param.append(
				"work_site_clusters",
				this.work_sites_form.get("work_site_clusters").value || ""
			);
			param.append("search_key", "");
			this.ds
				.getActionByUrl(
					this.ds.formData2string(param),
					"form/" + this.form_url + "/search_units_by_phases_general_no"
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.units_for_all = res.records;
							this.get_work_levels();
						}
					},
					(error) => {
						this.spinner.hide();
					}
				);
		}
	}

	//Search For Step 2 End Work Levels
	public set_validation_step1() {
		this.alert.clear();
		this.work_sites_form.updateValueAndValidity();
	}
	//Search For Step 2 Starts Work Levels
	public get_work_levels() {
		this.alert.clear();
		this.submitted = true;
		this.spinner.show();
		this.submitted = false;
		this.work_levels = [];
		// this.work_level_form.get("work_levels").setValue(null);
		let param = new FormData(); //this.ds.json2formdata(this.work_sites_form.value);
		param.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id
		);
		param.append("platform_code_system", this.form_key);
		param.append("lang_key", this.lang_form.get("lang_key").value);
		param.append("work_site", "ALL");
		this.ds
			.getActionByUrl(
				this.ds.formData2string(param),
				"form/" + this.form_url + "/get_work_levels_for_transaction"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.work_levels_for_all = res.records;
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
				}
			);
		// if(this.work_sites_form.valid) {
		//   this.spinner.show();
		//   this.submitted    = false;
		//   this.work_levels  = [];
		//   this.work_level_form.get("work_levels").setValue(null);
		//   let param = new FormData();//this.ds.json2formdata(this.work_sites_form.value);
		//   param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id);
		//   param.append('platform_code_system', this.form_key);
		//   if(this.work_sites_form.get('work_site').value == "ALL") {
		//     param.append('work_site', 'ALL');
		//   } else if(this.work_sites_form.get('work_site').value == "GN") {
		//     param.append('work_site', this.work_sites_form.get('work_site_general_nos').value.toString());
		//     param.append('different_cluster_allowed', this.general_different_cluster_allowed);
		//   } else if(this.work_sites_form.get('work_site').value == "IM") {
		//     param.append('work_site', this.work_sites_form.get('work_site_units').value.toString());
		//     param.append('different_cluster_allowed', this.phases_different_cluster_allowed);
		//   }
		//   this.ds.getActionByUrl(this.ds.formData2string(param), 'form/'+this.form_url+'/get_work_levels_for_transaction').subscribe((res) => {
		//     this.spinner.hide();
		//     if(res.status) {
		//       this.work_levels = res.records;
		//     } else {
		//       this.alert.error(res.error);
		//     }
		//   }, error => {
		//     this.spinner.hide();
		//   });
		// } else {
		//   this.alert.error(this.lang.transform('lang_fill_required_fields'));
		// }
	}
	//Search For Step 2 End Work Levels
	public completeStep1() {}
	//submit units and Work Levels Data to check Table Format with paginations Starts
	checkValidation() {
		if (
			(this.work_sites_form.get("work_site").value == "GN" &&
				this.generalNumbersData.length > 0) ||
			(this.work_sites_form.get("work_site").value == "IM" &&
				this.phasesData.length > 0)
		) {
			return true;
		} else {
			return false;
		}
	}

	getAttachments(data) {
		let other_assistance_files = this.si_form.get("attachments") as FormArray;
		other_assistance_files.clear();
		for (let file of data.form.controls) {
			other_assistance_files.push(file);
		}
	}

	public send_units_and_work_levels() {
		this.step2IsValid.setValue(null);
		this.alert.clear();
		if (this.checkValidation()) {
			this.spinner.show();
			let data = {
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
				platform_code_system: this.form_key,
				lang_key: this.lang_form.get("lang_key").value,
				separate: this.work_sites_form.get("separate").value,
				page_no: this.page_no,
				page_size: this.page_size,
				data: {},
			};
			if (this.work_sites_form.get("work_site").value == "ALL") {
				// data['data'] =  this.dwsrService.refinementData(this.generalNumbersData);
			} else if (this.work_sites_form.get("work_site").value == "GN") {
				data["data"] = this.dwsrService.refinementData(this.generalNumbersData);
			} else if (this.work_sites_form.get("work_site").value == "IM") {
				data["data"] = this.dwsrService.refinementData(this.phasesData);
			}
			this.ds.post("form/" + this.form_url + "/cr/1/0", data).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.step2IsValid.setValue(true);
					} else {
						this.step2IsValid.setValue(null);
					}
				},
				(err) => {
					this.spinner.hide();
					this.step2IsValid.setValue(null);
				}
			);
		} else {
			this.alert.error("All fields are required!");
			this.step2IsValid.setValue(null);
		}
	}
	//load data by page Size
	public load_by_page_size(size) {
		//this.platformData = [];
		this.page_size = size;
		this.send_units_and_work_levels();
	}
	// load data by page size
	public load_by_page_no(page) {
		//this.platformData = [];
		this.page_no = page;
		this.send_units_and_work_levels();
	}
	//submit units and Work Levels Data to check Table Format with paginations End
	//submit with attachments
	public send_files_to_step2() {
		this.alert.clear();
		if (
			this.lang_form.valid &&
			this.work_sites_form.valid &&
			this.work_level_form.valid &&
			this.platformData?.NextButton &&
			this.si_form.valid
		) {
			this.spinner.show();
			let param = new FormData();
			param.append(
				"projects_work_area_id",
				this.supervision_data.projects_work_area_id
			);
			param.append(
				"platform_code_system",
				this.template_data.template_platform_code_system
			);
			param.append("lang_key", this.lang_form.get("lang_key").value);
			param.append(
				"work_levels",
				this.work_level_form.get("work_levels").value.toString()
			);
			if (this.work_sites_form.get("work_site").value == "ALL") {
				param.append("work_site", "ALL");
			} else if (this.work_sites_form.get("work_site").value == "GN") {
				param.append(
					"work_site",
					this.work_sites_form.get("work_site_general_nos").value.toString()
				);
			} else if (this.work_sites_form.get("work_site").value == "IM") {
				param.append(
					"work_site",
					this.work_sites_form.get("work_site_units").value.toString()
				);
			}
			this.filesFormGroup.value.forEach((v, k) => {
				param.set("attachments[" + k + "][file]", v.file);
				param.set(
					"attachments[" + k + "][attach_title]",
					this.si_form.controls[k].get("attach_title").value
				);
			});
			this.ds
				.postActionByUrl(param, "form/" + this.form_url + "/cr/2/0")
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.alert.success(res.msg);
							setTimeout(() => {
								this.router.navigate([
									"/projects/Rpq7t/" +
										this.supervision_data.projects_profile_id +
										"/" +
										this.supervision_data.projects_supervision_id,
								]);
							}, 500);
						} else {
							this.alert.error(res.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				);
		} else {
			this.alert.error(this.lang.transform("lang_fill_required_fields"));
		}
	}

	addByGeneralNumbers() {
		const dialogRef = this.dialog.open(GeneralNumbersComponent, {
			width: "500px",
			data: {
				supervision_data: this.supervision_data,
				form_key: this.form_key,
				form_url: this.form_url,
				work_site: "GN",
				lang_key: this.lang_form.get("lang_key").value,
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			this.generalNumbersData.push({
				work_levels: result.work_levels.toString(),
				units: result.work_site_general_nos.toString(),
			});
		});
	}

	deleteByGeneralNumbers(index: any) {
		this.generalNumbersData.splice(index, 1);
	}

	addByPhases() {
		const dialogRef = this.dialog.open(ByPhasesComponent, {
			width: "700px",
			data: {
				supervision_data: this.supervision_data,
				form_key: this.form_key,
				form_url: this.form_url,
				work_site: "IM",
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			this.phasesData.push({
				zone: result.work_site_zones,
				block: result.work_site_blocks,
				cluster: result.work_site_clusters,
				units: result.work_site_units.toString(),
				work_levels: result.work_levels.toString(),
			});
		});
	}

	deleteByPhases(index: any) {
		this.phasesData.splice(index, 1);
	}
}
