import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  platform_id: string,
  platform_code_count: string,
  platform_code_system: string,
  platform_code_manual: string,
  platform_subject_english: string,
  platform_subject_arabic: string,
  platform_descriptions_english: string,
  platform_descriptions_arabic: string,
  group1name: string,
  typename: string,
  group2name: string,
  writer:string
}
@Component({
  selector: 'app-wplatforms',
  templateUrl: './wplatforms.component.html',
  styleUrls: ['./wplatforms.component.scss']
})
export class WplatformsComponent implements OnInit {
  modalRefA: BsModalRef;
  modalRefV: BsModalRef;
  form:FormGroup;
  tabledatas: list[];
  collectionSize;
  page                        = 1;
  pageSize                    = 10;
  searchKey                   = "";
  lang_key                    = localStorage.getItem('lang_key') || 'en';
  wholeData:any               = [];
  admin                       = JSON.parse(this.ls.getItem('is_admin'));
  tabledata:any               = [];
  nodata: string              = this.lang.transform('lang_loading');
  group1lists:any             = [];
  group1model                 = null;
  typeslist:any               = [];
  typemodel                   = null;
  group2lists:any             = [];
  group2model                 = null;
  group2formlists:any         = [];
  viewdata:any                = [];
  selectmultiple                    = true;
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public spinner:PreloaderService, public alert:AlertService, public modalService: BsModalService, public dialog:MatDialog, public fb:FormBuilder) { }
  ngOnInit(): void {
    this.load_group_1_type_lists();
    this.get_platform_lists();
    this.form = new FormGroup({
      platform_id                             : new FormControl(''),
      platform_code_manual                    : new FormControl(''),
      platform_subject_english                : new FormControl('', [Validators.required]),
      platform_subject_arabic                 : new FormControl('', [Validators.required]),
      platform_descriptions_english           : new FormControl('', [Validators.required]),
      platform_descriptions_arabic            : new FormControl('', [Validators.required]),
      platform_group1_code_system             : new FormControl('', [Validators.required]),
      platform_group_type_code_system         : new FormControl('', [Validators.required]),
      platform_group2_code_system             : new FormControl('', [Validators.required]),
    });
  }
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  public load_group_1_type_lists() {
    this.group1lists = this.typeslist  = [];
    this.spinner.show();
    let url = ['lpgone', 'lpgtype'];
    this.ds.forkJoin(url, []).subscribe( res => {
      this.spinner.hide();
      if(res[0].status) {
        this.group1lists = res[0].records;
      }
      if(res[1].status) {
        this.typeslist = res[1].records;
      }
    }, error => {
      this.spinner.hide();
    });
  }
  public load_group_2_lists(type) {
    if(type == 'form') {
      this.group2formlists = []
    } else {
      this.group2lists = [];
    }
    this.spinner.show();
    let param = new FormData();
    param.append('platform_group1_code_system', (type == 'form') ? this.form.get('platform_group1_code_system').value : this.group1model);
    param.append('platform_group_type_code_system', (type == 'form') ? this.form.get('platform_group_type_code_system').value : this.typemodel);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgtwo').subscribe( res => {
      this.spinner.hide();
      if(res.status) {
        if(type == "form") {
          this.group2formlists = res.records;
        } else {
          this.group2lists = res.records;
        }
      }
    }, error => {
      this.spinner.hide();
    });
  }
  public get_platform_lists() {
    let param = new FormData();
    param.append('search_key',                      this.searchKey);
    param.append('platform_group1_code_system',     this.group1model || '');
    param.append('platform_group_type_code_system', this.typemodel || '');
    param.append('platform_group2_code_system',     this.group2model || '');
    param.append('request',                         'list');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpplatform').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.wholeData      = res.records;
        this.tabledatas     = res.records;
        this.collectionSize = res.records.length;
        this.build_table_data();
      } else {
        this.wholeData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholeData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    }) 
  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.append('platform_id', this.form.get('platform_id').value || '');
    param.append('platform_code_manual', this.form.get('platform_code_manual').value || '');
    param.append('platform_subject_english', this.form.get('platform_subject_english').value || '');
    param.append('platform_subject_arabic', this.form.get('platform_subject_arabic').value || '');
    param.append('platform_descriptions_english', this.form.get('platform_descriptions_english').value || '');
    param.append('platform_descriptions_arabic', this.form.get('platform_descriptions_arabic').value || '');
    param.append('platform_group1_code_system', this.form.get('platform_group1_code_system').value || '');
    param.append('platform_group_type_code_system', this.form.get('platform_group_type_code_system').value || '');
    param.append('platform_group2_code_system', this.form.get('platform_group2_code_system').value || '');
    if(this.form.get('platform_id').value) {
      this.ds.putActionByUrl(this.ds.formData2string(param), 'upplatform/'+this.form.get('platform_id').value).subscribe(res=>{
        this.spinner.hide();
        if(res.status) {
          this.form.reset();
          this.get_platform_lists();
          setTimeout(() => {
            this.modalRefA.hide();
            this.alert.clear();
          }, 1000);
          this.alert.success(res.msg);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    } else {
      this.ds.postActionByUrl(param, 'cpplatform').subscribe(res=>{
        this.spinner.hide();
        if(res.status) {
          this.form.reset();
          this.get_platform_lists();
          setTimeout(() => {
            this.modalRefA.hide();
            this.alert.clear();
          }, 1000);
          this.alert.success(res.msg);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    }
  }
  public checkAllItemsWPL(event) {
    if (event.target.checked) {
      $(".appplatforms .eachItemWPL").prop("checked", true);
      $(".appplatforms .makeback").addClass("bground");
      $(".appplatforms .deleteIconDiv").show();
    } else {
      $(".appplatforms .eachItemWPL").prop("checked", false);
      $(".appplatforms .makeback").removeClass("bground");
      $(".appplatforms .deleteIconDiv").hide();
    }
  }
  public checkEachItemWPL(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appplatforms .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appplatforms .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appplatforms .eachItemWPL:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appplatforms .eachItemWPL").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appplatforms .deleteIconDiv").show();
    } else {
      $(".appplatforms .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appplatforms #allItemsWPL").prop("checked", true);
    } else {
      $(".appplatforms #allItemsWPL").prop("checked", false);
    }
  }
  public deleteBulkDataWPL() {
    const checkedtotal = [];
    $(".appplatforms .eachItemWPL:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'dpplatform').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.get_platform_lists();
                $(".appplatforms #allItemsWPL").prop("checked", false);
                $(".appplatforms .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
