<div class="row">
    <div  class="col-12 mb-3">
        <h4  class="page-title">
            <i  class="fa fa-folder mnabricon"></i>
            <span >{{'lang_reviewers'|language}}</span>
        </h4>
    </div>
</div>

<div class="payrole-header">
	<div class="row">
		<div class="col-12 col-sm-6 mb-2 mb-sm-0">
			<!-- <div class="search-input">
				<input
					type="text"
					class="form-control almnabrformcontrol"
					[(ngModel)]="searchKey"
					placeholder="{{ 'lang_search' | language }}"
				/>
				<i class="search-icon fa fa-search mnabricon" [dir]="lang_key == 'ar'? 'rtl':'ltr'"></i>
			</div> -->
		</div>
		<div class="col-12 col-sm-3 mb-2 mb-sm-0">
			 <!--  <button
				class="albutton mnabrbutton"
				mat-button
                routerLink="/human_resources/payrole/reviewers"
			>
                <i class="fa fa-users" style="font-size: 17px; margin: 0 5px"></i>
			    {{ "lang_reviewers" | language }}
			</button>   -->
		</div>
		<div class="col-12 col-sm-3 mb-2 mb-sm-0">
			<button
				class="albutton mnabrbutton"
				mat-button
				(click)="openModal()"
			>
				<i class="fa fa-plus" style="font-size: 17px; margin: 0 5px"></i>
				<span>{{'lang_add'|language}} {{'lang_reviewers'|language}}</span>
			</button>
		</div>
	</div>
</div>


<div class="payrole-list">
    <div class="row">
        <div class="col-12">
            <div class="table-responsive customResponsive">
                <table border="1" class="table CustomTable">
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>{{'lang_reviewer'|language}} {{'lang_human_resources_id_number'|language}}</td>
                            <td>{{'lang_username'|language}}</td>
                            <td>{{'lang_human_resources_number'|language}}</td>
                          
                            <td>{{'lang_name'|language}}</td>
                           
                          
                         
                            <td>{{'lang_action'|language}}</td>
                            <!-- <td>Export</td> -->
                        </tr>
                    </thead>
                    <tbody class="AppendList" *ngIf="reviewers && !loading">
                        <tr *ngFor="let item of reviewers; let i = index">
                            <td>{{ i+1 }}</td>
                            <td>{{ item?.reviewer_id }}</td>
                            <td>{{ item?.user_username }}</td>
                            <td>{{ item?.user_id }}</td>
                            <td>{{ item?.firstname_english }}</td>
                           
                           
                            <td >
                                <button mat-button matTooltip="{{ 'lang_delete' | language }}"  class="delete-btn" (click)="deleteTask(item?.user_id)">
                                    <i class="fa fa-trash mnabricon"></i>
                                </button>
                                <!-- <div class="action-btns">
                                    <button mat-button routerLink="/tasks/view/{{ item?.ticket_id }}" *ngIf="item?.can_view">
                                        <i class="fa fa-eye"></i>
                                    </button>
                                    <button mat-button routerLink="/tasks/edit/{{ item?.ticket_id }}" *ngIf="item?.can_edit">
                                        <i class="fa fa-pencil mnabricon"></i>
                                    </button>
                                    <button mat-button (click)="deleteTicket(item?.ticket_id)" *ngIf="item?.can_delete">
                                        <i class="fa fa-trash mnabricon"></i>
                                    </button>
                                </div> -->
                                 
                            </td>
                           <!--  <td *ngIf="!item?.can_view && !item?.can_edit && !item?.can_delete">---</td> -->
                        </tr>
                    </tbody>
                    <tbody *ngIf="loading">
                        <tr>
                            <td colspan="8" align="center">
                                <ngx-skeleton-loader  count="5" [theme]="{ 'border-radius': '3px', height: '50px' }"></ngx-skeleton-loader>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!reviewers.length && !loading" class="AppendList">
                        <tr>
                            <td colspan="14" align="center">
                                <no-data style="margin: auto;"  [Opened]="!reviewers.length && !loading"></no-data>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
    </div>
    <!-- <div
        *ngIf="reviewers.length"
        class="mb-10 custom-footer"
    >
        <div class="row responsive_pagination">
            <div
                class="col-lg-3 col-sm-3 col-6 mb-10"
                style="height: fit-content; margin: auto 0"
            >
                {{ "lang_total" | language }} :
                {{ reviewers?.page?.total_records ? reviewers?.page?.total_records : 0 }}
            </div>
            <div
                class="col-lg-6 col-sm-6 mb-10"
                style="display: flex; justify-content: center"
            >
                <ngb-pagination
                    class="m-auto"
                    [collectionSize]="reviewers?.page?.total_records"
                    [rotate]="true"
                    [ellipses]="false"
                    [maxSize]="3"
                    [boundaryLinks]="true"
                    [(page)]="payrole_page_no"
                    [pageSize]="payrole_page_size"
                    (pageChange)="load_by_page_no($event)"
                ></ngb-pagination>
            </div>
            <div
                class="col-lg-3 col-sm-3"
                style="display: flex; justify-content: end; margin: auto"
            >
                <mat-select
                    [(ngModel)]="payrole_page_size"
                    (selectionChange)="load_by_page_size($event.value)"
                >
                    <mat-option class="text-center" [value]="10"
                        >10 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="20"
                        >20 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="50"
                        >50 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="100"
                        >100 {{ "lang_per_page" | language }}</mat-option
                    >
                    <mat-option class="text-center" [value]="500"
                        >500 {{ "lang_per_page" | language }}</mat-option
                    >
                </mat-select>
            </div>
        </div>
    </div> -->
</div>



<div class="row pt-3">
    <div class="col-md-2 col-sm-4 col-xs-12 mb-10">
        <button
            class="albutton mnabrbutton mat-accent"
            mat-button
            routerLink="/human_resources/payrole/list" 
            >
            {{'lang_pay_role' | language}}
        </button>
    </div>
    <div class="col-md-8 col-sm-4 col-xs-12 mb-10"></div>
 <!--    <div class="col-md-2 col-sm-4 col-xs-12 mb-10">
        <button
            class="albutton mnabrbutton mat-accent"
            mat-button
            (click)="submit()"
            >
            {{ "lang_submit" | language }}
        </button>
    </div> -->
</div>