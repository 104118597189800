<div class="bold text-center timeDiv">
	{{ livedate }}
	<span id="livetime" class="txt-time">{{ time | date: "mediumTime" }}</span> (
	{{ msg }} )
</div>
<div class="matero-header">
	<mat-toolbar class="matero-toolbar mx-background-top-linear">
		<app-branding *ngIf="true"></app-branding>
		<span fxFlex></span>
		<div class="d-flex align-items-center">
			<button
				mat-icon-button
				class="matero-toolbar-button"
				[matMenuTriggerFor]="menu"
			>
				<mat-icon>translate</mat-icon>
			</button>
			<mat-menu class="header-menu" #menu="matMenu">
				<button
					mat-menu-item
					*ngFor="let lang of languages"
					(click)="switchlanguage(lang)"
				>
					<span>{{ lang.lang_name | language }}</span>
				</button>
			</mat-menu>
			<i
				class="fa fa-sign-out ml-2 mr-2"
				style="color: #fff; font-size: 25px"
				matTooltip="{{ 'lang_logout' | language }}"
				(click)="logout()"
			></i>
		</div>
	</mat-toolbar>
</div>
