import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
@Component({
	selector: "app-confirm-jop-offer-card",
	templateUrl: "./confirm-jop-offer.component.html",
	styleUrls: ["./confirm-jop-offer.component.scss"],
})
export class ConfirmJopOfferComponent implements OnInit {
	@Input() projects: any = [];
	@Input() branches: any = [];
	@Input() metaData: any = [];
	form: FormGroup = this.fb.group({});
	lang_key = localStorage.getItem("lang_key")
		? localStorage.getItem("lang_key")
		: "en";

	type = "confirm";
	form_request_id = "";
	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<ConfirmJopOfferComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _sweetAlertService: SweetAlertService,
		private spinner: PreloaderService,
		public ds: DataService,
		private lang: LanguagePipe
	) {
		/*   this.type = this.data.type; */
		this.form_request_id = this.data.form_request_id;
	}

	ngOnInit(): void {
		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
			transaction_request_id: [this.form_request_id, []],
			approved: ["", [Validators.required]],
			notes: ["", []],
		});
	}

	get getNotes() {
		return this.form.get("notes");
	}
	rejectedValidators = [];
	checkStatus() {
		if (this.form.get("approved").value == "0") {
			this.rejectedValidators = [Validators.required];
			this.getNotes?.setValidators([Validators.required]);

			this.getNotes?.updateValueAndValidity();
		} else {
			this.rejectedValidators = [];
			this.getNotes?.setValidators([]);

			this.getNotes?.updateValueAndValidity();
		}
	}

	sendFilterForm() {
		this.dialogRef.close({ ...this.form?.value, sendRequest: true });
	}
	close() {
		this.dialogRef.close({ sendRequest: false });
	}

	submitForm = false;
	public complete() {
		this.submitForm = true;
		if (this.form.valid) {
			this.spinner.show();
			let formData = new FormData();
			formData.append(
				"transaction_request_id",
				this.form.value.transaction_request_id
			);
			formData.append("approving_status", this.form.value.approved);
			if (this.form?.value?.approved) {
				formData.append("note", this.form.value.notes);
			}

			this.ds.post(`/form/FORM_JF/sign_req_emp`, formData).subscribe(
				(data) => {
					if (data.status) {
						this.spinner.hide();
						this._sweetAlertService.successMessage(data.msg);
						this.submitForm = false;
						this.sendFilterForm();
						/*  setTimeout(() => {
          location.reload();
        }, 2000); */
					} else {
						this.spinner.hide();
						this._sweetAlertService.errorMessage(data?.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this._sweetAlertService.errorMessage(error);
				}
			);
		} else {
			this._sweetAlertService.errorMessage(
				this.lang.transform("lang_all_fields_required")
			);
		}
	}
}
