<form class="incoming" [formGroup]="form" (ngSubmit)="submitForm()">
  <breadcrumb></breadcrumb>
  <section class="panel-expansion">
    <mat-accordion class="stylepanels">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_insert_incoming" | language
        }}</mat-expansion-panel-header>
        <div class="mbrl15">
          <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_languages" | language }}
                  <span class="red">*</span></label
                >
                <ng-select
                  [items]="languages"
                  [multiple]="false"
                  bindLabel="label"
                  bindValue="value"
                  clearAllText="Clear"
                  appendTo="body"
                  formControlName="lang_key"
                  (change)="SetName($event)"
                  placeholder="{{ 'lang_languages' | language }}"
                  (change)="changeEditorDir($event)"
                >
                </ng-select>
                <mat-error
                  *ngIf="
                    submitted && form.controls['lang_key'].errors?.required
                  "
                  >{{ "lang_field_required" | language }}</mat-error
                >
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_subject" | language }}
                  <span class="red">*</span></label
                >
                <input
                  type="text"
                  formControlName="subject"
                  placeholder="{{ 'lang_subject' | language }}"
                  class="form-control almnabrformcontrol"
                />
                <mat-error
                  *ngIf="submitted && form.controls['subject'].errors?.required"
                  >{{ "lang_field_required" | language }}</mat-error
                >
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_from" | language }} <span class="red">*</span></label
                >
                <input
                  type="text"
                  formControlName="transaction_from"
                  placeholder="{{ 'lang_from' | language }}"
                  class="form-control almnabrformcontrol"
                />
                <mat-error
                  *ngIf="
                    submitted &&
                    form.controls['transaction_from'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12" *ngIf="!userData">
              <div class="form-group">
                <label>
                  {{ "lang_to_organization" | language }}
                  <span class="red">*</span></label
                >
                <ng-select
                  [items]="branches"
                  [multiple]="false"
                  bindLabel="label"
                  bindValue="value"
                  clearAllText="Clear"
                  appendTo="body"
                  formControlName="transaction_to"
                  placeholder="{{ 'lang_to_organization' | language }}"
                >
                </ng-select>
                <mat-error
                  *ngIf="
                    submitted &&
                    form.controls['transaction_to'].errors?.required
                  "
                  >{{ "lang_field_required" | language }}</mat-error
                >
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_issued_date_english" | language }}
                  <span class="red">*</span></label
                >

                <custom-arendate-input
                  [_dateValue]="form.controls.issued_date_m.value"
                  (dateChange)="
                    setValueToFields(
                      $event,
                      form.controls.issued_date_m,
                      form.controls.issued_date_h
                    )
                  "
                  [parentForm]="form"
                  format="YYYY/MM/DD"
                  [label]="'YYYY/MM/DD'"
                >
                </custom-arendate-input>

                <!--   <input formControlName="issued_date_m" type="text" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event, form.get('issued_date_h'))" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
                                <mat-datepicker-toggle matSuffix [for]="BDeng" class="posiab"></mat-datepicker-toggle>
                                <mat-datepicker #BDeng disabled="false"></mat-datepicker> -->

                <mat-error
                  *ngIf="
                    submitted && form.controls['issued_date_m'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_issued_number" | language }}
                  <span class="red">*</span></label
                >
                <input
                  onwheel="return false;"
                  formControlName="issued_number"
                  placeholder="{{ 'lang_issued_number' | language }}"
                  class="form-control almnabrformcontrol"
                />
                <mat-error
                  *ngIf="
                    submitted && form.controls['issued_number'].errors?.required
                  "
                  >{{ "lang_field_required" | language }}</mat-error
                >
              </div>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <label> {{ "lang_attachments" | language }} </label>
                <input
                  type="text"
                  formControlName="attachmentstitle"
                  placeholder="{{ 'lang_attachments' | language }}"
                  class="form-control almnabrformcontrol"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_communication_recipient" | language }}
                  <span class="red">*</span></label
                >
                <ng-select
                  [items]="users"
                  [multiple]="false"
                  bindLabel="label"
                  bindValue="value"
                  clearAllText="Clear"
                  [searchFn]="ds.customSearchFn"
                  appendTo="body"
                  formControlName="signature[C][user_id]"
                  placeholder="{{ 'lang_communication_recipient' | language }}"
                  (search)="search_users($event)"
                  (focus)="users = []"
                  [notFoundText]="'lang_no_data' | language"
                >
                </ng-select>
                <mat-error
                  *ngIf="
                    submitted &&
                    form.controls['signature[C][user_id]'].errors?.required
                  "
                  >{{ "lang_field_required" | language }}</mat-error
                >
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_file_secutrity_levels" | language }}
                  <span class="red">*</span></label
                >
                <ng-select
                  [items]="filepermissionlevels"
                  [multiple]="true"
                  bindLabel="label"
                  appendTo="body"
                  bindValue="value"
                  clearAllText="Clear"
                  placeholder="{{ 'lang_file_secutrity_levels' | language }}"
                  formControlName="level_keys"
                >
                </ng-select>
                <mat-error
                  *ngIf="
                    submitted && form.controls['level_keys'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>
          </div>
          <div class="row mb-10">
            <div class="col-12">
              <label>
                {{ "lang_description" | language }}
                <span class="red">*</span></label
              >
              <ckeditor
                #editor
                [config]="config"
                formControlName="content"
                [ngClass]="{ ' contentrtl': lang_key == 'ar' }"
              ></ckeditor>
              <mat-error
                *ngIf="submitted && form.controls['content'].errors?.required"
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
          <div class="row mb-10">
            <div class="col-md-4 col-xs-12 col-sm-6 mb-10">
              <fieldset class="cardback fieldset">
                <legend>{{ "lang_markers" | language }}</legend>
                <ng-select
                  [items]="users"
                  [multiple]="true"
                  bindLabel="label"
                  bindValue="value"
                  clearAllText="Clear"
                  [searchFn]="ds.customSearchFn"
                  appendTo="body"
                  formControlName="marks"
                  placeholder="{{ 'lang_markers' | language }}"
                  (search)="search_users($event)"
                  (focus)="users = []"
                >
                </ng-select>
              </fieldset>
            </div>
            <div class="col-md-8 col-xs-12 col-sm-12 mb-10">
              <fieldset class="cardback fieldset">
                <legend>
                  {{ "lang_attachments" | language }} <span class="red">*</span>
                </legend>
                <div class="table-responsive customResponsive">
                  <table
                    border="1"
                    style="min-width: 945px"
                    class="table CustomTable"
                    width="100%"
                    formArrayName="attachments"
                  >
                    <thead>
                      <tr>
                        <td
                          width="5%"
                          style="padding: 5px; word-break: break-all"
                        >
                          #
                        </td>
                        <td
                          width="5%"
                          style="padding: 5px; word-break: break-all"
                        >
                          <mat-icon
                            class="mnabricon"
                            matTooltip="{{ 'lang_add' | language }}"
                            (click)="addfiles()"
                            >add_box</mat-icon
                          >
                        </td>
                        <td
                          width="45%"
                          style="padding: 5px; word-break: break-all"
                        >
                          {{ "lang_file" | language }}
                        </td>
                        <td
                          width="45%"
                          style="padding: 5px; word-break: break-all"
                        >
                          {{ "lang_title" | language }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let file of filesFormGroup.controls;
                          let i = index;
                          let odd = odd;
                          let even = even
                        "
                        [ngClass]="{ odd: odd, even: even }"
                        [formGroupName]="i"
                      >
                        <td
                          class="verticalalignmid"
                          style="padding: 5px; word-break: break-all"
                        >
                          {{ i + 1 }}
                        </td>
                        <td
                          class="verticalalignmid"
                          style="padding: 5px; word-break: break-all"
                        >
                          <mat-icon
                            (click)="removefiles(i)"
                            class="mnabricon red"
                            matTooltip="{{ 'lang_delete' | language }}"
                            >delete_forever</mat-icon
                          >
                        </td>
                        <td
                          class="verticalalignmid"
                          style="padding: 5px; word-break: break-all"
                          style="position: relative"
                        >
                          <input
                            style="display: block"
                            name="file"
                            type="file"
                            required
                            onclick="this.value = null"
                            accept="application/pdf,application/vnd.ms-excel"
                            #upload
                            (change)="UploadFile($event, i)"
                            class="nghide albutton width100"
                          />
                          <label
                            id="uploadText{{ i }}"
                            class="albutton mnabrbutton mat-accent mat-button"
                            (click)="upload.click()"
                            mat-button
                            >{{ filename }}
                          </label>
                          <mat-error
                            *ngIf="
                              submitted &&
                              this.attachments.controls[i].get('file').errors
                                ?.required
                            "
                          >
                            {{ "lang_field_required" | language }}
                          </mat-error>
                        </td>
                        <td
                          style="padding: 5px; word-break: break-all"
                          style="position: relative"
                        >
                          <input
                            type="text"
                            formControlName="attach_title"
                            placeholder="{{ 'lang_description' | language }}"
                            autocomplete="off"
                            class="form-control almnabrformcontrol"
                          />
                          <mat-error
                            *ngIf="
                              submitted &&
                              this.attachments.controls[i].get('attach_title')
                                .errors?.required
                            "
                          >
                            {{ "lang_field_required" | language }}
                          </mat-error>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
              <button
                type="button"
                class="albutton mnabrbutton mat-accent"
                mat-button
                routerLink="/tasks/alltasks"
              >
                {{ "lang_goto_list" | language }}
              </button>
            </div>
            <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
              <button
                type="button"
                class="albutton mnabrbutton mat-accent"
                mat-button
                (click)="previewBeforeCreate()"
              >
                {{ "lang_preview" | language }}
              </button>
            </div>
            <div class="col-md-6 col-sm-3 col-xs-12 mb-10">
              <app-alert id="default-alert"></app-alert>
            </div>
            <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
              <button
                type="submit"
                class="albutton mnabrbutton mat-accent"
                mat-button
              >
                {{ "lang_submit" | language }}
              </button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</form>
