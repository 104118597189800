<breadcrumb></breadcrumb>
<div class="themesection">
  <div class="container mb-10">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-xs-12 mar0auto"></div>
      <div class="col-md-3 col-sm-6 col-xs-12 mar0auto">
        <div *ngIf="branch_id == '0'" class="red pad0 text-center">
          {{ "lang_choose_branch" | language }}
        </div>
        <select
          name="branch_id"
          class="form-control almnabrformcontrol"
          matTooltip="{{ 'lang_branch' | language }}"
          [(ngModel)]="branch_id"
          (change)="onSelectBranch($event.target.value)"
        >
          <option value="0">{{ "lang_choose_options" | language }}</option>
          <option *ngFor="let branch of branches" value="{{ branch.id }}">
            {{ branch.title }}
          </option>
        </select>
      </div>
      <div
        class="col-md-3 col-sm-6 col-xs-12 mar0auto"
        *ngIf="branch_id && branch_id != '0'"
      >
        <ng-select
          [items]="finances"
          [multiple]="false"
          bindLabel="label"
          bindValue="value"
          clearAllText="Clear"
          appendTo="body"
          [(ngModel)]="finance_id"
          placeholder="{{ 'lang_financial_years' | language }}"
          (change)="getOpeningBalance()"
        >
        </ng-select>
      </div>
      <div class="col-md-3 col-sm-6 col-xs-12 mar0auto"></div>
    </div>
  </div>
  <section
    class="panel-expansion matborderno"
    *ngIf="branch_id && branch_id != '0'"
  >
    <mat-accordion class="receipt_lists">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_opening_balance" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-sm mb-10">
                  <input
                    type="text"
                    placeholder="{{ 'lang_search' | language }}"
                    (keyup)="getOpeningBalance()"
                    [(ngModel)]="searchKey"
                    class="form-control almnabrformcontrol"
                  />
                </div>
              </div>
            </div>
            <form [formGroup]="balanceForm">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive dropdownyes customResponsive">
                    <div class="col-12 TableParts">
                      <div
                        class="table-responsive customResponsive tablefields quotationitemtable"
                      >
                        <table
                          border="1"
                          class="table CustomTable"
                          width="100%"
                          formArrayName="balances"
                        >
                          <thead>
                            <tr>
                              <td
                                width="2%"
                                style="padding: 5px; word-break: break-all"
                              >
                                #
                              </td>
                              <td
                                style="
                                  padding: 5px;
                                  word-break: break-all;
                                  width: 20%;
                                "
                              >
                                {{ "lang_account_masters_code" | language }}
                              </td>
                              <td
                                style="
                                  padding: 5px;
                                  word-break: break-all;
                                  width: 20%;
                                "
                              >
                                {{ "lang_account_name" | language }}
                              </td>
                              <td
                                style="
                                  padding: 5px;
                                  word-break: break-all;
                                  width: 20%;
                                "
                              >
                                {{ "lang_debit_amount" | language }}
                              </td>
                              <td
                                style="
                                  padding: 5px;
                                  word-break: break-all;
                                  width: 20%;
                                "
                              >
                                {{ "lang_credit_amount" | language }}
                              </td>
                            </tr>
                          </thead>
                          <tbody *ngIf="balancesFormArray.length">
                            <tr
                              *ngFor="
                                let item of balances.controls;
                                let i = index;
                                let odd = odd;
                                let even = even
                              "
                              [formGroupName]="i"
                            >
                              <td class="verticalalignmid" style="padding: 5px">
                                {{ i + 1 }}
                              </td>
                              <td
                                style="
                                  padding: 5px;
                                  word-break: break-all;
                                  position: relative;
                                  background: #ededed;
                                "
                              >
                                {{
                                  balancesFormArray?.controls[i].get(
                                    "account_masters_code"
                                  ).value
                                }}
                              </td>
                              <td
                                class="verticalalignmid"
                                style="
                                  padding: 5px;
                                  word-break: break-all;
                                  position: relative;
                                  background: #ededed;
                                "
                              >
                                {{
                                  balancesFormArray?.controls[i].get(
                                    "account_name"
                                  ).value
                                }}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id="debit_amount{{ i }}"
                                  formControlName="debit_amount"
                                  (keypress)="ds.isNumber($event)"
                                  (change)="
                                    onChangeDebitCredit(i, 'debit');
                                    calcTotal();
                                    addHistoryData(item.value);
                                    updateAllData(item?.value);
                                    setCursorPosition('debit_amount' + i)
                                  "
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id="credit_amount{{ i }}"
                                  formControlName="credit_amount"
                                  (keypress)="ds.isNumber($event)"
                                  (change)="
                                    onChangeDebitCredit(i, 'credit');
                                    calcTotal();
                                    addHistoryData(item.value);
                                    updateAllData(item?.value);
                                    setCursorPosition('credit_amount' + i)
                                  "
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3">
                                {{ "lang_total" | language }}
                              </td>
                              <td style="background: #ededed; font-weight: 600">
                                {{ totalDebit }}
                              </td>
                              <td style="background: #ededed; font-weight: 600">
                                {{ totalCredit }}
                              </td>
                            </tr>
                          </tbody>
                          <tbody
                            *ngIf="!balancesFormArray.length"
                            class="AppendList"
                          >
                            <tr class="odd">
                              <td colspan="5" align="center">
                                {{ "lang_no_data" | language }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div
              *ngIf="balances.controls?.length > 0"
              class="mb-10 mt-2 custom-footer"
            >
              <div class="row responsive_pagination">
                <div
                  class="col-sm-3 mb-10 col-lg-3"
                  style="height: fit-content; margin: auto 0"
                >
                  {{ "lang_total" | language }} :
                  {{ totalbalanceRecord ? totalbalanceRecord : 0 }}
                </div>
                <div
                  class="col-sm-6 mb-10 col-lg-6"
                  style="display: flex; justify-content: center"
                >
                  <ngb-pagination
                    class="m-auto"
                    [collectionSize]="totalbalanceRecord"
                    [rotate]="true"
                    [ellipses]="false"
                    [maxSize]="3"
                    [boundaryLinks]="true"
                    [(page)]="page"
                    [pageSize]="size"
                    (pageChange)="getOpeningBalance()"
                  ></ngb-pagination>
                </div>
                <div
                  class="col-sm-3 col-lg-3"
                  style="display: flex; justify-content: end; margin: auto"
                >
                  <mat-select
                    [(ngModel)]="size"
                    (selectionChange)="getOpeningBalance()"
                  >
                    <mat-option class="text-center" value="10"
                      >10 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" value="50"
                      >50 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" value="100"
                      >100 {{ "lang_per_page" | language }}</mat-option
                    >
                    <mat-option class="text-center" value="500"
                      >500 {{ "lang_per_page" | language }}</mat-option
                    >
                    <mat-option class="text-center" value="1000"
                      >1000 {{ "lang_per_page" | language }}</mat-option
                    >
                    <mat-option class="text-center" value="2000"
                      >2000 {{ "lang_per_page" | language }}</mat-option
                    >
                  </mat-select>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-2 col-xs-12 mb-10">
                <input
                  type="file"
                  (change)="onFileChange($event)"
                  accept=".xlsx"
                  #importxlsxInput
                  style="display: none"
                />
                <button
                  type="button"
                  class="albutton"
                  (click)="importxlsxInput?.click()"
                >
                  {{ "lang_import_excel" | language }}
                </button>
              </div>
              <div class="col-sm-2 col-xs-12 mb-10">
                <button type="button" class="albutton" (click)="export_exl()">
                  {{ "lang_export_excel" | language }}
                </button>
              </div>
              <div class="col-sm-2 col-xs-12 mb-10">
                <button
                  type="button"
                  class="albutton"
                  (click)="export_exl_pdf('P')"
                >
                  {{ "lang_export_pdf" | language }}
                </button>
              </div>
              <div class="col-12 col-sm-3">
                <button
                  type="button"
                  class="albutton mnabrbutton mat-accent"
                  mat-button
                  (click)="showExcelHistory()"
                >
                  {{ "lang_excel_history" | language }}
                </button>
              </div>
              <div class="col-12 col-sm-3">
                <button
                  type="button"
                  class="albutton mnabrbutton mat-accent"
                  mat-button
                  (click)="onSubmit()"
                >
                  {{ "lang_save" | language }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
