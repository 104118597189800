import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { PdfViewerComponent } from "ng2-pdf-viewer";
import { AlertService } from "../alert/alert.service";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import printJS from "print-js";

@Component({
  selector: "app-preview-pdf",
  templateUrl: "./preview-pdf.component.html",
  styleUrls: ["./preview-pdf.component.scss"],
})
export class PreviewPdfComponent implements OnInit {
  pdfSrc: any;
  rotationAngle = 0;
  zoom = 1;
  stringToSearch = "";
  fitPageVal = "page-width";
  title = "";
  pdf: ArrayBuffer;
  downloadpdfsrc;
  base64:string
  @ViewChild(PdfViewerComponent, { static: false })
  private pdfViewer: PdfViewerComponent;
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public router: Router,
    private dialogRef: MatDialogRef<PreviewPdfComponent>,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.downloadpdfsrc = data?.src;
    // this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
    //   data?.src + "#toolbar=1"
    // );
    this.base64 = data?.base64;
    this.pdfSrc = data?.src;
    this.title = data?.title;
  }
  ngOnInit(): void {}

  rotationPdf() {
    if (this.rotationAngle == 360) {
      this.rotationAngle = 0;
    }
    this.rotationAngle += 90;
  }

  search() {
    this.pdfViewer.pdfFindController.executeCommand("find", {
      caseSensitive: false,
      findPrevious: undefined,
      highlightAll: true,
      phraseSearch: true,
      query: this.stringToSearch,
    });
  }

  downloadPdf() {
    let currentDate = new Date();
    let tagA = document.createElement("a");
    document.body.appendChild(tagA);
    tagA.style.display = "none";
    tagA.href = this.downloadpdfsrc;
    tagA.download = this.title
      ? "Almnabr-" + this.title
      : "Almnabr-" + currentDate.toLocaleString();
    tagA.click();
    document.body.removeChild(tagA);
  }

  closeModal() {
    this.dialogRef.close();
  }

  printpdf() {
  printJS({printable: this.base64, type: 'pdf', base64: true})
 }
}
