import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
	name: "dateFormnow",
})
export class DateFormnowPipe implements PipeTransform {
	transform(value: string | Date): string {
		if (typeof value === "string") {
			value = new Date(value);
		}
		return moment(value, "YYYY-MM-DD HH:mm:ss").fromNow();
	}
}
