<mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
	<mat-tab label="{{ 'lang_registered_users' | language }}">
		<div class="custom-panel-content padding15">
			<div class="registered_contractor_users">
				<div class="row">
					<div class="col-12 mb-10">
						<div class="input-group input-group-sm">
							<input
								type="text"
								class="form-control almnabrformcontrol h34"
								(keyup)="search_contractor_users()"
								[(ngModel)]="searchKey"
								placeholder="{{ 'lang_search' | language }}"
							/>
							<span class="input-group-btn" *ngIf="makeAction">
								<button
									id="btnSearch"
									type="button"
									(click)="openModalR(signupusers)"
									matTooltip="{{
										'lang_create_new_contractor_users' | language
									}}"
									class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
								></button>
							</span>
							<span class="input-group-btn" *ngIf="makeAction">
								<button
									id="btnSearch"
									type="button"
									(click)="
										empty_exsiting_users(); openModal(add_existing_users)
									"
									matTooltip="{{ 'lang_add_exists_users' | language }}"
									class="btnSearch h34 btn btn-md btn-gray fa fa-users mnabricon"
								></button>
							</span>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="table-responsive dropdownyes customResponsive">
							<table border="1" class="table CustomTable">
								<thead>
									<tr>
										<td>#</td>
										<td>{{ "lang_name" | language }}</td>
										<td>{{ "lang_email_address" | language }}</td>
										<td>{{ "lang_mobile_number" | language }}</td>
										<td>{{ "lang_position" | language }}</td>
										<td>{{ "lang_registration_status" | language }}</td>
										<td>{{ "lang_writer" | language }}</td>
										<td>{{ "lang_action" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="userData?.length != '0'" class="AppendList">
									<tr
										*ngFor="
											let user of userData.records;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
										class="makeback removerow{{ user.user_regsitration_id }}"
									>
										<td>{{ i + 1 }}</td>
										<td>{{ user.registration_name }}</td>
										<td>{{ user.user_email_address }}</td>
										<td>{{ user.user_mobile_number }}</td>
										<td>{{ user.position_name }}</td>
										<td *ngIf="user.user_registration_link_status == '0'">
											<i
												matTooltip="{{ 'lang_inactive' | language }}"
												class="fa fa-circle red fa-icon pad0"
											></i>
										</td>
										<td *ngIf="user.user_registration_link_status == '1'">
											<i
												matTooltip="{{ 'lang_active' | language }}"
												class="fa fa-circle green fa-icon pad0"
											></i>
										</td>
										<td>{{ user.writer }}</td>
										<td>
											<i
												class="fa fa-eye mnabricon s2icon"
												(click)="
													signup_data_view(user); openModalV(viewUserData)
												"
												matTooltip="{{ 'lang_view' | language }}"
												*ngIf="makeAction"
											></i>
											<i
												class="fa fa-link mnabricon s2icon"
												*ngIf="
													user.user_registration_link_status == '0' &&
													makeAction
												"
												(click)="send_signup_link(user)"
												matTooltip="{{ 'lang_send_signup_link' | language }}"
											></i>
											<i
												class="fa fa-pencil-square-o mnabricon s2icon"
												*ngIf="able2edit"
												(click)="
													openModalU(update_existing_users);
													updateFormData(user)
												"
												matTooltip="{{ 'lang_edit' | language }}"
											></i>
											<i
												class="fa fa-trash mnabricon s2icon red"
												*ngIf="
													user.user_registration_link_status == '1' &&
													makeAction
												"
												(click)="remove_contract_users(user)"
												matTooltip="{{ 'lang_delete' | language }}"
											></i>
										</td>
									</tr>
								</tbody>
								<tbody *ngIf="userData?.length == '0'" class="AppendList">
									<tr class="odd">
										<td colspan="9" class="nodata" align="center">
											{{ nodata }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="userData?.records?.length != '0'" class="card-footer padb0">
			<div class="row responsive_pagination">
				<div
					class="col-lg-3 col-sm-3 mb-10"
					style="height: fit-content; margin: auto 0"
				>
					{{ "lang_total" | language }} : {{ userData?.page?.total_records }}
				</div>
				<div
					class="col-lg-6 col-sm-6 mb-10"
					style="display: flex; justify-content: center"
				>
					<ngb-pagination
						class="m-auto"
						[collectionSize]="userData?.page?.total_records"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="pageno"
						[pageSize]="pagesize"
						(pageChange)="page_no($event)"
					></ngb-pagination>
				</div>
				<div
					class="col-lg-3 col-sm-3"
					style="display: flex; justify-content: end; margin: auto"
				>
					<mat-select
						style="max-width: 150px"
						[(ngModel)]="pagesize"
						(selectionChange)="page_size($event.value)"
					>
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab label="{{ 'lang_un_registered_users' | language }}">
		<div class="custom-panel-content padding15">
			<div class="unregistered_contractor_users">
				<div class="row">
					<div class="col-12 mb-10">
						<div class="input-group input-group-sm">
							<input
								type="text"
								class="form-control almnabrformcontrol h34"
								(keyup)="load_unsigedin_contractor_users()"
								[(ngModel)]="unSearchKey"
								placeholder="{{ 'lang_search' | language }}"
							/>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="table-responsive dropdownyes customResponsive">
							<table border="1" class="table CustomTable">
								<thead>
									<tr>
										<td>#</td>
										<td>{{ "lang_name" | language }}</td>
										<td>{{ "lang_email_address" | language }}</td>
										<td>{{ "lang_mobile_number" | language }}</td>
										<td>{{ "lang_registration_status" | language }}</td>
										<td>{{ "lang_writer" | language }}</td>
										<td>{{ "lang_action" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="UnSignupData?.length != '0'" class="AppendList">
									<tr
										*ngFor="
											let user of UnSignupData.records;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
										class="makeback removerow{{ user.user_regsitration_id }}"
									>
										<td>{{ i + 1 }}</td>
										<td>{{ user.registration_name }}</td>
										<td>{{ user.user_email_address }}</td>
										<td>{{ user.user_mobile_number }}</td>
										<td *ngIf="user.user_registration_link_status == '0'">
											<i
												matTooltip="{{
													'lang_registration_pending' | language
												}}"
												class="fa fa-circle yellowcolor fa-icon pad0"
											></i>
										</td>
										<td *ngIf="user.user_registration_link_status == '1'">
											<i
												matTooltip="{{ 'lang_active' | language }}"
												class="fa fa-circle green fa-icon pad0"
											></i>
										</td>
										<td>{{ user.writer }}</td>
										<td>
											<i
												class="fa fa-eye mnabricon s2icon"
												(click)="
													signup_data_view(user); openModalV(viewUserData)
												"
												matTooltip="{{ 'lang_view' | language }}"
												*ngIf="makeAction"
											></i>
											<i
												class="fa fa-link mnabricon s2icon"
												*ngIf="
													user.user_registration_link_status == '0' &&
													makeAction
												"
												(click)="send_signup_link(user)"
												matTooltip="{{ 'lang_send_signup_link' | language }}"
											></i>
										</td>
									</tr>
								</tbody>
								<tbody *ngIf="UnSignupData?.length == '0'" class="AppendList">
									<tr class="odd">
										<td colspan="8" class="nodata" align="center">
											{{ Unnodata }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>
<ng-template #add_existing_users>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_add_exists_users" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="userform" (ngSubmit)="submit()">
			<div class="modal-body">
				<div class="form-group">
					<label for="contractor_id"
						>{{ "lang_position" | language }} :
						<span class="red">*</span></label
					>
					<ng-select
						[items]="positions"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						appendTo="body"
						formControlName="position_id"
						placeholder="{{ 'lang_position' | language }}"
					>
					</ng-select>
					<mat-error
						*ngIf="
							userform.controls['position_id'].touched &&
							userform.controls['position_id'].errors?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
				<div class="form-group">
					<label for="contractor_id"
						>{{ "lang_users" | language }} : <span class="red">*</span></label
					>
					<ng-select
						[items]="users"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						appendTo="body"
						formControlName="contractor_users"
						placeholder="{{ 'lang_users' | language }}"
						(search)="search_users($event)"
						(focus)="users = []"
						[searchFn]="ds.customSearchFn"
					>
						<ng-template
							ng-option-tmp
							let-item="item"
							let-search="searchTerm"
							let-index="index"
						>
							<span>{{ item.label }} ({{ item.value }})</span>
						</ng-template>
					</ng-select>
					<mat-error
						*ngIf="
							userform.controls['contractor_users'].touched &&
							userform.controls['contractor_users'].errors?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #update_existing_users>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_update_positions" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefU.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="updateform" (ngSubmit)="update()">
			<div class="modal-body">
				<div class="form-group">
					<label for="position_id"
						>{{ "lang_position" | language }} :
						<span class="red">*</span></label
					>
					<ng-select
						[items]="positions"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						appendTo="body"
						formControlName="position_id"
						placeholder="{{ 'lang_position' | language }}"
					>
					</ng-select>
					<mat-error
						*ngIf="
							userform.controls['position_id'].touched &&
							userform.controls['position_id'].errors?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRefU.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #signupusers>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">
				{{ "lang_create_new_contractor_users" | language }}
			</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefR.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="registrationform" (ngSubmit)="signup()">
			<div class="modal-body">
				<div class="form-group">
					<label for="user_registration_firstname_en"
						>{{ "lang_human_resources_fname_english" | language }} :
						<span class="red">*</span></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						placeholder="{{ 'lang_human_resources_fname_english' | language }}"
						formControlName="user_registration_firstname_en"
					/>
					<mat-error
						*ngIf="
							registrationform.controls['user_registration_firstname_en']
								.touched &&
							registrationform.controls['user_registration_firstname_en'].errors
								?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
				<div class="form-group">
					<label for="user_registration_lastname_en"
						>{{ "lang_human_resources_lname_english" | language }} :
						<span class="red">*</span></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						placeholder="{{ 'lang_human_resources_lname_english' | language }}"
						formControlName="user_registration_lastname_en"
					/>
					<mat-error
						*ngIf="
							registrationform.controls['user_registration_lastname_en']
								.touched &&
							registrationform.controls['user_registration_lastname_en'].errors
								?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
				<div class="form-group">
					<label for="user_registration_firstname_ar"
						>{{ "lang_human_resources_fname_arabic" | language }} :
						<span class="red">*</span></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						placeholder="{{ 'lang_human_resources_fname_arabic' | language }}"
						formControlName="user_registration_firstname_ar"
					/>
					<mat-error
						*ngIf="
							registrationform.controls['user_registration_firstname_ar']
								.touched &&
							registrationform.controls['user_registration_firstname_ar'].errors
								?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
				<div class="form-group">
					<label for="user_registration_lastname_ar"
						>{{ "lang_human_resources_lname_arabic" | language }} :
						<span class="red">*</span></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						placeholder="{{ 'lang_human_resources_lname_arabic' | language }}"
						formControlName="user_registration_lastname_ar"
					/>
					<mat-error
						*ngIf="
							registrationform.controls['user_registration_lastname_ar']
								.touched &&
							registrationform.controls['user_registration_lastname_ar'].errors
								?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
				<div class="form-group">
					<label for="user_email_address"
						>{{ "lang_email_address" | language }} :
						<span class="red">*</span></label
					>
					<input
						type="email"
						class="form-control almnabrformcontrol"
						placeholder="{{ 'lang_email_address' | language }}"
						formControlName="user_email_address"
					/>
					<mat-error
						*ngIf="
							registrationform.controls['user_email_address'].touched &&
							registrationform.controls['user_email_address'].errors?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
				<div class="form-group">
					<label for="user_mobile_number"
						>{{ "lang_mobile_number" | language }} :
						<span class="red">*</span></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						placeholder="{{ 'lang_mobile_number' | language }}"
						formControlName="user_mobile_number"
						minlength="12"
						maxlength="12"
					/>
					<mat-error
						*ngIf="
							registrationform.controls['user_mobile_number'].touched &&
							registrationform.controls['user_mobile_number'].errors?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
				<div class="form-group">
					<label for="personal_id_number"
						>{{ "lang_human_resources_id_number" | language }} :
					</label>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						placeholder="{{ 'lang_human_resources_id_number' | language }}"
						formControlName="personal_id_number"
						minlength="10"
						maxlength="10"
					/>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRefR.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #viewUserData>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_view" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefV.hide()"
			>
				&times;
			</button>
		</div>
		<div class="modal-body" *ngIf="viewdata">
			<div class="form-group">
				<div class="row" *ngIf="viewdata.username">
					<div class="col-6">
						<label>{{ "lang_username" | language }}</label>
					</div>
					<div class="col-6">
						<label
							>: <b>{{ viewdata.username }}</b></label
						>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<div class="col-6">
						<label>{{ "lang_name" | language }}</label>
					</div>
					<div class="col-6">
						<label
							>: <b>{{ viewdata.registration_name }}</b></label
						>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<div class="col-6">
						<label>{{ "lang_email_address" | language }}</label>
					</div>
					<div class="col-6">
						<label
							>: <b>{{ viewdata.user_email_address }}</b></label
						>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<div class="col-6">
						<label>{{ "lang_mobile_number" | language }}</label>
					</div>
					<div class="col-6">
						<label
							>: <b>{{ viewdata.user_mobile_number }}</b></label
						>
					</div>
				</div>
			</div>
			<div class="form-group" *ngIf="viewdata.personal_id_number != '0'">
				<div class="row">
					<div class="col-6">
						<label>{{ "lang_human_resources_id_number" | language }}</label>
					</div>
					<div class="col-6">
						<label
							>: <b>{{ viewdata.personal_id_number }}</b></label
						>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<div class="col-6">
						<label>{{ "lang_user_status" | language }}</label>
					</div>
					<div class="col-6">
						<label *ngIf="viewdata.user_join_status != '1'"
							>:
							<b
								><i
									matTooltip="{{ 'lang_inactive' | language }}"
									class="fa fa-circle red fa-icon pad0"
								></i>
								{{ "lang_inactive" | language }}
							</b></label
						>
						<label *ngIf="viewdata.user_join_status == '1'"
							>:
							<b
								><i
									matTooltip="{{ 'lang_active' | language }}"
									class="fa fa-circle green fa-icon pad0"
								></i>
								{{ "lang_active" | language }}
							</b></label
						>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<div class="col-6">
						<label>{{ "lang_ondate" | language }}</label>
					</div>
					<div class="col-6">
						<label
							>: <b>{{ viewdata.user_createdatetime }}</b></label
						>
					</div>
				</div>
			</div>
			<div class="form-group" *ngIf="viewdata.user_updatedatetime">
				<div class="row">
					<div class="col-6">
						<label>{{ "lang_onupdate" | language }}</label>
					</div>
					<div class="col-6">
						<label
							>: <b>{{ viewdata.user_updatedatetime }}</b></label
						>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<div class="col-6">
						<label>{{ "lang_registration_status" | language }}</label>
					</div>
					<div class="col-6">
						<label *ngIf="viewdata.user_registration_link_status == '0'"
							>:
							<b
								><i
									matTooltip="{{ 'lang_inactive' | language }}"
									class="fa fa-circle red fa-icon pad0"
								></i>
								{{ "lang_inactive" | language }}</b
							></label
						>
						<label *ngIf="viewdata.user_registration_link_status == '1'"
							>:
							<b
								><i
									matTooltip="{{ 'lang_active' | language }}"
									class="fa fa-circle green fa-icon pad0"
								></i>
								{{ "lang_active" | language }}</b
							></label
						>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<div class="col-6">
						<label>{{ "lang_writer" | language }}</label>
					</div>
					<div class="col-6">
						<label
							>: <b>{{ viewdata.writer }}</b></label
						>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal footer -->
		<div class="modal-footer">
			<button
				type="button"
				class="btn btn-secondary"
				data-dismiss="modal"
				(click)="modalRefV.hide()"
			>
				{{ "lang_close" | language }}
			</button>
		</div>
	</div>
</ng-template>
