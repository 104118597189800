import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddUpdateTicketModalComponent } from "app/routes/tasks/add-update-ticket-modal/add-update-ticket-modal.component";

@Component({
	selector: "app-design-tickets",
	templateUrl: "./design-tickets.component.html",
	styleUrls: ["./design-tickets.component.scss"],
})
export class DesignTicketsComponent implements OnInit {
	@Input() contract_id;
	@Input() projects_work_area_id;
	tickets_page_no = 1;
	tickets_page_size = "10";
	ticket_list = null;
	ticketLoading = true;
	constructor(
		private ds: DataService,
		private ls: LocalStorage,
		private lang: LanguagePipe,
		private fb: FormBuilder,
		private dialog: MatDialog,
		private sweetAlert: SweetAlertService,
		private spinner: PreloaderService
	) {}

	ngOnInit(): void {
		this.getTickets();
	}

	//tickets
	getTickets() {
		this.ticketLoading = true;
		let formData = new FormData();
		formData.append("work_area_id", this.projects_work_area_id || "");
		this.ds
			.post(
				`tasks/get_data_ticket_project/${this.tickets_page_no}/${this.tickets_page_size}`,
				formData
			)
			.subscribe(
				(res) => {
					this.ticketLoading = false;
					if (res?.status) {
						this.ticket_list = res;
					} else {
						this.ticket_list = null;
						this.tickets_page_no = 1;
						this.tickets_page_size = "10";
					}
				},
				(err) => {
					this.ticketLoading = false;
					this.ticket_list = null;
					this.tickets_page_no = 1;
					this.tickets_page_size = "10";
				}
			);
	}

	deleteTicket(event, ticketId) {
		event?.stopPropagation();
		this.sweetAlert.confirmMessage("Are You Sure?!", "Yes").then((res) => {
			if (res?.isConfirmed) {
				this.spinner.show();
				let formData = new FormData();
				formData.append("ticket_id", ticketId);
				this.ds.post("tasks/delete_ticket", formData).subscribe(
					(res) => {
						this.spinner.hide();
						if (res?.status) {
							this.sweetAlert.successToast("Ticket Deleted Successfully", 1000);
							this.getTickets();
						}
					},
					(err) => {
						this.spinner.hide();
					}
				);
			}
		});
	}

	onAddTicket() {
		const dialogRef = this.dialog.open(AddUpdateTicketModalComponent, {
			width: "800px",
			autoFocus: false,
			data: {
				ref_module: "project",
				projects_work_area_id: this.projects_work_area_id,
			},
		});

		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.getTickets();
			}
		});
	}
}
