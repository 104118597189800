<div class="card" style="border-radius: 5px">
	<div class="card-header">
		{{ "lang_form_version" | language }}
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-12">
				<div class="table-responsive customResponsive">
					<table border="1" class="table CustomTable">
						<thead>
							<tr>
								<td>#</td>
								<td>{{ "lang_transaction_number" | language }}</td>
								<td>{{ "lang_unit" | language }}</td>
								<td>{{ "lang_work_level" | language }}</td>
								<td>{{ "lang_barcode" | language }}</td>
								<td>{{ "lang_evaluation_result" | language }}</td>
								<td>
									{{ "lang_date" | language }}
								</td>
								<td>{{ "lang_action" | language }}</td>
							</tr>
						</thead>
						<tbody *ngIf="versions?.records?.length != 0" class="AppendList">
							<tr
								*ngFor="
									let row of versions?.records;
									let i = index;
									let odd = odd;
									let even = even
								"
								[ngClass]="{ odd: odd, even: even }"
							>
								<td>{{ i + 1 }}</td>
								<td>{{ row.transaction_request_id }}</td>
								<td>
									{{
										template_type == "2"
											? "ALL"
											: row?.unit_label || row?.unit_id
									}}
								</td>
								<td>{{ row.levelname }}</td>
								<td *ngIf="row.tbv_barcodeData">
									{{ row.tbv_barcodeData }}
								</td>
								<td *ngIf="!row.tbv_barcodeData">----</td>
								<td
									*ngIf="row.result_code"
									[ngClass]="{
										redstatus: row.color == 'RED',
										greenstatus: row.color == 'GREEN'
									}"
								>
									{{ row.result_code }}
								</td>
								<td *ngIf="!row.result_code">----</td>
								<td>{{ row.transactions_date_datetime }}</td>
								<td *ngIf="row.file_path">
									<i
										class="fa fa-icon mnabricon fa-file-pdf-o"
										(click)="preview_form_data(row.file_path)"
									></i>
								</td>
								<td *ngIf="!row.file_path">----</td>
							</tr>
						</tbody>
						<tbody
							*ngIf="
								(versions?.records?.length == 0 && !isLoading) ||
								(!versions?.records && !isLoading)
							"
							class="AppendList"
						>
							<tr class="odd">
								<td colspan="8" class="nodata" align="center">
									{{ nodata }}
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="isLoading" class="AppendList">
							<tr class="odd">
								<td colspan="8" class="nodata" align="center">
									<div class="d-flex justify-content-center">
										<mat-spinner [diameter]="50"></mat-spinner>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<div
						*ngIf="versions?.records?.length > 0"
						class="mb-10 custom-footer"
					>
						<div class="row responsive_pagination">
							<div
								class="col-sm-3 mb-10 col-lg-3"
								style="height: fit-content; margin: auto 0"
							>
								{{ "lang_total" | language }} :
								{{
									versions?.page?.total_records
										? versions?.page?.total_records
										: 0
								}}
							</div>
							<div
								class="col-sm-6 mb-10 col-lg-6"
								style="display: flex; justify-content: center"
							>
								<ngb-pagination
									class="m-auto"
									[collectionSize]="versions?.page?.total_records"
									[rotate]="true"
									[ellipses]="false"
									[maxSize]="3"
									[boundaryLinks]="true"
									[(page)]="versions_page_no"
									[pageSize]="versions_page_size"
									(pageChange)="load_by_page_no($event, 'versions')"
								></ngb-pagination>
							</div>
							<div
								class="col-sm-3 col-lg-3"
								style="display: flex; justify-content: end; margin: auto"
							>
								<mat-select
									[(ngModel)]="versions_page_size"
									(selectionChange)="
										load_by_page_size($event.value, 'versions')
									"
								>
									<mat-option class="text-center" value="10"
										>10 {{ "lang_per_page" | language }}
									</mat-option>
									<mat-option class="text-center" value="20"
										>20 {{ "lang_per_page" | language }}
									</mat-option>
									<mat-option class="text-center" value="50"
										>50 {{ "lang_per_page" | language }}
									</mat-option>
									<mat-option class="text-center" value="100"
										>100 {{ "lang_per_page" | language }}</mat-option
									>
									<mat-option class="text-center" value="500"
										>500 {{ "lang_per_page" | language }}</mat-option
									>
								</mat-select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
