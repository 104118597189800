import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
	FormGroup,
	FormArray,
	FormBuilder,
	Validators,
	FormControl,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

declare var $: any;
@Component({
	selector: "app-formhrv1-edit",
	templateUrl: "./formhrv1-edit.component.html",
	styleUrls: ["./formhrv1-edit.component.scss"],
})
export class Formhrv1EditComponent implements OnInit {
	@Output() vacationEvent = new EventEmitter();
	@Input() form_request_id;
	@Input() form_key;
	@Input() id;
	@Input() branch_id;
	vacations: any = [];
	editVacationForm: FormGroup;
	selectedCity: any;
	markstatus: boolean = false;
	reviewstatus: boolean = false;
	attachstatus: boolean = false;
	attachments: FormArray;
	filename = this.lang.transform("lang_select_files");
	isFile: boolean = false;
	users: any = [];
	employee_number = "";
	contract_id = "";
	vacation_paid_days_from_contract = "";
	showOtherVacation = false;
	showAttachment = false;

	lang_key =
		localStorage.getItem("lang_key") == null
			? "en"
			: localStorage.getItem("lang_key");
	constructor(
		public ds: DataService,
		private router: Router,
		public spinner: PreloaderService,
		public lang: LanguagePipe,
		private fb: FormBuilder,
		private alert: AlertService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.initForm();
		this.getVacationDetails();
	}

	initForm() {
		this.editVacationForm = this.fb.group({
			vacation_type_id: [null, Validators.required],
			before_vacation_working_date_english: [null, Validators.required],
			before_vacation_working_date_arabic: [null, Validators.required],
			after_vacation_working_date_english: [null, Validators.required],
			after_vacation_working_date_arabic: [null, Validators.required],
			vacation_other: [null, this.showOtherVacation ? Validators.required : []],
			vacation_start_date_english: [null, Validators.required],
			vacation_start_date_arabic: [null, Validators.required],
			vacation_end_date_english: [null, Validators.required],
			vacation_end_date_arabic: [null, Validators.required],
			contract_id: [null, Validators.required],
			vacation_total_days: [null, Validators.required],
			vacation_paid_days_from_contract: [null, Validators.required],
			vacation_total_paid_days: [null, Validators.required],
			paid_days75: [null, Validators.required],
			paid_days100: [null, Validators.required],
			paid_days75_amount: [null, Validators.required],
			paid_days100_amount: [null, Validators.required],
			sick_balance: [null, Validators.required],
			vacation_total_unpaid_days: [null, Validators.required],
			vacation_total_paid_amount: [null, Validators.required],
			needMark: new FormControl("0", [Validators.required]),
			needReview: new FormControl("0", [Validators.required]),
			needAttach: new FormControl("0", [Validators.required]),
			signature_a_title: new FormControl("", [Validators.required]),
			signature_a_user_id: new FormControl(null, [Validators.required]),
			signature_b_title: new FormControl("", [Validators.required]),
			signature_b_user_id: new FormControl(null, [Validators.required]),
			signature_c_title: new FormControl("", [Validators.required]),
			signature_c_user_id: new FormControl(null, [Validators.required]),
			marks: new FormControl(null),
			reviews: new FormControl(null),
			attachments: this.fb.array(
				[],
				this.showAttachment ? [Validators.required] : []
			),
		});
		this.attachments = this.editVacationForm.get("attachments") as FormArray;
	}

	getVacationType(employee_number: any) {
		let data = new FormData();
		data.append("employee_number", employee_number);
		this.ds.post("form/FORM_HRV1/get_vacation_type", data).subscribe((res) => {
			if (res.status) {
				this.vacations = res.records;
				this.checkAttachment();
			}
		});
	}

	checkVacationEmployee() {
		if (
			this.editVacationForm.get("vacation_type_id").valid &&
			this.editVacationForm.get("before_vacation_working_date_english").valid &&
			this.editVacationForm.get("after_vacation_working_date_english").valid
		) {
			let formData = new FormData();
			formData.append("employee_number", this.employee_number);
			formData.append(
				"vacation_type_id",
				this.editVacationForm.get("vacation_type_id").value
			);
			formData.append(
				"before_vacation_working_date_english",
				this.editVacationForm
					.get("before_vacation_working_date_english")
					.value.toLocaleDateString()
			);
			formData.append(
				"after_vacation_working_date_english",
				this.editVacationForm
					.get("after_vacation_working_date_english")
					.value.toLocaleDateString()
			);
			this.ds
				.post("form/FORM_HRV1/check_vacation_for_employee", formData)
				.subscribe(
					(res) => {
						if (res.status) {
							if (this.editVacationForm.get("vacation_type_id").value == "3") {
								this.editVacationForm
									.get("paid_days75")
									.setValue(res?.result?.paid_days75);
								this.editVacationForm
									.get("paid_days100")
									.setValue(res?.result?.paid_days100);
								this.editVacationForm
									.get("paid_days75_amount")
									.setValue(res?.result?.paid_days75_amount);
								this.editVacationForm
									.get("paid_days100_amount")
									.setValue(res?.result?.paid_days100_amount);
								this.editVacationForm
									.get("sick_balance")
									.setValue(res?.result?.sick_balance);
								this.editVacationForm
									.get("vacation_total_paid_amount")
									.clearValidators();
								this.editVacationForm
									.get("vacation_total_paid_amount")
									.updateValueAndValidity();
								this.editVacationForm
									.get("vacation_total_paid_days")
									.clearValidators();
								this.editVacationForm
									.get("vacation_total_paid_days")
									.updateValueAndValidity();
							} else {
								this.editVacationForm
									.get("vacation_total_paid_amount")
									.setValue(
										res?.result?.paid_amount || res?.result?.paid_amount22
									);
								this.editVacationForm
									.get("vacation_total_paid_days")
									.setValue(res?.result?.paid_days);
								this.editVacationForm.get("paid_days75").clearValidators();
								this.editVacationForm.get("paid_days100").clearValidators();
								this.editVacationForm
									.get("paid_days75_amount")
									.clearValidators();
								this.editVacationForm
									.get("paid_days100_amount")
									.clearValidators();
								this.editVacationForm.get("sick_balance").clearValidators();
								this.editVacationForm
									.get("paid_days75")
									.updateValueAndValidity();
								this.editVacationForm
									.get("paid_days100")
									.updateValueAndValidity();
								this.editVacationForm
									.get("paid_days75_amount")
									.updateValueAndValidity();
								this.editVacationForm
									.get("paid_days100_amount")
									.updateValueAndValidity();
								this.editVacationForm
									.get("sick_balance")
									.updateValueAndValidity();
							}
							this.editVacationForm
								.get("vacation_total_unpaid_days")
								.setValue(res?.result?.unpaid_days);
							this.editVacationForm
								.get("vacation_total_days")
								.setValue(res?.result?.vacation_days);
							this.editVacationForm
								.get("vacation_start_date_english")
								.setValue(new Date(res?.result?.vacation_start_date));
							this.editVacationForm
								.get("vacation_end_date_english")
								.setValue(new Date(res?.result?.vacation_end_date));
							this.ds.setENTOARDate(
								{
									value: this.editVacationForm.get(
										"vacation_start_date_english"
									).value,
								},
								this.editVacationForm.get("vacation_start_date_arabic")
							);
							this.ds.setENTOARDate(
								{
									value: this.editVacationForm.get("vacation_end_date_english")
										.value,
								},
								this.editVacationForm.get("vacation_end_date_arabic")
							);
						}
					},
					(err) => {
						this.editVacationForm.get("vacation_type_id").reset();
						this.editVacationForm
							.get("before_vacation_working_date_english")
							.reset();
						this.editVacationForm
							.get("before_vacation_working_date_arabic")
							.reset();
						this.editVacationForm
							.get("after_vacation_working_date_english")
							.reset();
						this.editVacationForm
							.get("after_vacation_working_date_arabic")
							.reset();
					}
				);
		}
	}

	getEmployeeInfo(employee_number: any) {
		let data = new FormData();
		data.append("employee_number", employee_number);
		this.ds.post("form/FORM_HRV1/get_employee_info", data).subscribe((res) => {
			if (res.status) {
				this.editVacationForm.controls["contract_id"].setValue(
					res?.result?.contract_vacation_info?.contract_id
				);
				this.editVacationForm.controls[
					"vacation_paid_days_from_contract"
				].setValue(
					res?.result?.contract_vacation_info?.vacation_paid_days_only
				);
				this.employee_number = res.result?.employee_info?.employee_number;
				this.contract_id = res?.result?.contract_vacation_info?.contract_id;
				this.vacation_paid_days_from_contract =
					res?.result?.contract_vacation_info?.vacation_paid_days_only;
			}
		});
	}

	getVacationDetails() {
		this.spinner.show();
		this.ds.get(`form/FORM_HRV1/vr/${this.form_request_id}`).subscribe(
			(res) => {
				if (res.status) {
					this.spinner.hide();
					this.employee_number =
						res?.form_hrv1_vacation_data?.records[0]?.employee_number;
					this.getEmployeeInfo(
						res?.form_hrv1_vacation_data?.records[0]?.employee_number
					);
					this.getVacationType(
						res?.form_hrv1_vacation_data?.records[0]?.employee_number
					);
					this.editVacationForm
						.get("vacation_type_id")
						.setValue(
							res?.form_hrv1_vacation_data?.records[0]?.vacation_type_id
						);
					this.editVacationForm
						.get("before_vacation_working_date_english")
						.setValue(
							new Date(
								res?.form_hrv1_vacation_data?.records[0]?.before_vacation_working_date_english
							)
						);
					this.editVacationForm
						.get("after_vacation_working_date_english")
						.setValue(
							new Date(
								res?.form_hrv1_vacation_data?.records[0]?.after_vacation_working_date_english
							)
						);
					this.ds.setENTOARDate(
						{
							value: this.editVacationForm.get(
								"before_vacation_working_date_english"
							).value,
						},
						this.editVacationForm.get("before_vacation_working_date_arabic")
					);
					this.ds.setENTOARDate(
						{
							value: this.editVacationForm.get(
								"after_vacation_working_date_english"
							).value,
						},
						this.editVacationForm.get("after_vacation_working_date_arabic")
					);

					this.checkVacationEmployee();

					let defaultusers = [];
					if (res?.transactions_persons && res?.transactions_persons?.status) {
						let markers = [];
						let reviewers = [];
						res?.transactions_persons?.records.forEach((v, k) => {
							defaultusers.push({
								value: v.user_id,
								label: v.person_name,
							});
							//signature users
							if (v.transaction_persons_type === "signature") {
								if (
									v.transactions_persons_val1 === "A" &&
									v.transactions_persons_val2 &&
									v.user_id &&
									v.person_name
								) {
									this.editVacationForm
										.get("signature_a_title")
										.setValue(v.transactions_persons_val2);
									this.editVacationForm
										.get("signature_a_user_id")
										.setValue(v.user_id);
								}
								if (
									v.transactions_persons_val1 === "B" &&
									v.transactions_persons_val2 &&
									v.user_id &&
									v.person_name
								) {
									this.editVacationForm
										.get("signature_b_title")
										.setValue(v.transactions_persons_val2);
									this.editVacationForm
										.get("signature_b_user_id")
										.setValue(v.user_id);
								}
								if (
									v.transactions_persons_val1 === "C" &&
									v.transactions_persons_val2 &&
									v.user_id &&
									v.person_name
								) {
									this.editVacationForm
										.get("signature_c_title")
										.setValue(v.transactions_persons_val2);
									this.editVacationForm
										.get("signature_c_user_id")
										.setValue(v.user_id);
								}
							}
							//markers
							if (v.transaction_persons_type === "marks") {
								this.markstatus = true;
								this.editVacationForm.get("needMark").setValue("1");
								markers.push(v.user_id);
							}
							//reviewers
							if (v.transaction_persons_type === "reviews") {
								this.reviewstatus = true;
								this.editVacationForm.get("needReview").setValue("1");
								reviewers.push(v.user_id);
							}
						});
						if (markers.length > 0) {
							this.editVacationForm
								.get("marks")
								.setValidators(Validators.required);
							this.editVacationForm.get("marks").updateValueAndValidity();
						}
						if (reviewers.length > 0) {
							this.editVacationForm
								.get("reviews")
								.setValidators(Validators.required);
							this.editVacationForm.get("reviews").updateValueAndValidity();
						}
						this.editVacationForm.get("marks").setValue(markers);
						this.editVacationForm.get("reviews").setValue(reviewers);
					}
					this.users = defaultusers;
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}

	checkAttachment() {
		let vacation = this.vacations.find((item) => {
			return item.value == this.editVacationForm.get("vacation_type_id").value;
		});

		if (vacation) {
			if (vacation.need_attachment == "1") {
				this.showAttachment = true;
				this.attachments.setValidators(Validators.required);
			} else {
				this.showAttachment = false;
				this.attachments.clearValidators();
			}
		}

		if (vacation.value == "9") {
			this.showOtherVacation = true;
		} else {
			this.showOtherVacation = false;
		}
	}

	public enable_field(field) {
		if (field == "mark") {
			this.editVacationForm.get("needMark").setValue("1");
			this.editVacationForm.get("marks").setValidators(Validators.required);
			this.editVacationForm.get("marks").updateValueAndValidity();
			this.markstatus = true;
		} else if (field == "review") {
			this.editVacationForm.get("needReview").setValue("1");
			this.editVacationForm.get("reviews").setValidators(Validators.required);
			this.editVacationForm.get("reviews").updateValueAndValidity();
			this.reviewstatus = true;
		} else if (field == "attachment") {
			this.attachments.clear();
			this.editVacationForm.get("needAttach").setValue("1");
			this.addfiles();
			this.attachstatus = true;
		}
	}

	public files(): FormGroup {
		return this.fb.group({
			attach_title: new FormControl(
				"",
				this.editVacationForm.get("needAttach").value == "1"
					? [Validators.required]
					: null
			),
			file: new FormControl(
				"",
				this.editVacationForm.get("needAttach").value == "1"
					? [Validators.required]
					: null
			),
		});
	}
	public addfiles() {
		if (this.attachments.length < 5) {
			this.attachments.push(this.files());
		}
	}
	public removefiles(index) {
		if (this.attachments.length > 1) {
			this.attachments.removeAt(index);
		}
	}

	public getfilesgroup(index): FormGroup {
		const formGroup = this.attachments.controls[index] as FormGroup;
		return formGroup;
	}

	public UploadFile(event, index) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			const fileid = event.target.files[0];
			$("#uploadText" + index).text(fileid.name);
			this.attachments.controls[index]
				.get("file")
				.setValue(fileid, { emitEvent: true });
		} else {
			this.attachments.controls[index].get("file").setValue(null);
			$("#uploadText" + index).text(this.lang.transform("lang_select_files"));
		}
	}
	public uploadFile(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			this.isFile = true;
			const fileid = event.target.files[0];
			this.filename = fileid.name;
			this.editVacationForm.get("upload").setValue(fileid, { emitEvent: true });
		} else {
			this.isFile = false;
			this.editVacationForm.get("upload").setValue(null);
			this.filename = this.lang.transform("lang_select_files");
		}
	}
	public removeFile() {
		this.isFile = false;
		this.editVacationForm.get("upload").setValue(null);
		this.filename = this.lang.transform("lang_select_files");
	}

	public disable_field(field) {
		if (field == "mark") {
			this.editVacationForm.get("needMark").setValue("0");
			this.editVacationForm.get("marks").setValue(null);
			this.editVacationForm.get("marks").clearValidators();
			this.editVacationForm.get("marks").updateValueAndValidity();
			this.markstatus = false;
		} else if (field == "review") {
			this.editVacationForm.get("needReview").setValue("0");
			this.editVacationForm.get("reviews").setValue(null);
			this.editVacationForm.get("reviews").clearValidators();
			this.editVacationForm.get("reviews").updateValueAndValidity();
			this.reviewstatus = false;
		} else if (field == "attachment") {
			this.attachments.clear();
			this.editVacationForm.get("needAttach").setValue("0");
			this.isFile = false;
			this.attachstatus = false;
		}
		this.editVacationForm.updateValueAndValidity();
	}
	get filesFormGroup() {
		return this.editVacationForm.get("attachments") as FormArray;
	}

	public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append("search", key.term || "");
		param.append("lang_key", this.lang_key);
		param.append("user_type_id", "1");
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.users = res.list;
				}
			});
	}

	onSubmit() {
		if (this.editVacationForm.valid) {
			let formData = new FormData();
			formData.append("employee_number", this.employee_number);
			formData.append(
				"vacation_type_id",
				this.editVacationForm.get("vacation_type_id").value
			);
			formData.append(
				"vacation_other",
				this.editVacationForm.get("vacation_other").value
			);
			formData.append(
				"contract_id",
				this.editVacationForm.get("contract_id").value
			);
			formData.append(
				"before_vacation_working_date_english",
				this.editVacationForm
					.get("before_vacation_working_date_english")
					.value.toLocaleDateString()
			);
			formData.append(
				"before_vacation_working_date_arabic",
				this.editVacationForm.get("before_vacation_working_date_arabic").value
			);
			formData.append(
				"vacation_start_date_english",
				this.editVacationForm
					.get("vacation_start_date_english")
					.value.toLocaleDateString()
			);
			formData.append(
				"vacation_start_date_arabic",
				this.editVacationForm.get("vacation_start_date_arabic").value
			);
			formData.append(
				"vacation_end_date_english",
				this.editVacationForm
					.get("vacation_end_date_english")
					.value.toLocaleDateString()
			);
			formData.append(
				"vacation_end_date_arabic",
				this.editVacationForm.get("vacation_end_date_arabic").value
			);
			formData.append(
				"after_vacation_working_date_english",
				this.editVacationForm
					.get("after_vacation_working_date_english")
					.value.toLocaleDateString()
			);
			formData.append(
				"after_vacation_working_date_arabic",
				this.editVacationForm.get("after_vacation_working_date_arabic").value
			);
			formData.append(
				"vacation_total_days",
				this.editVacationForm.get("vacation_total_days").value
			);
			formData.append(
				"vacation_paid_days_from_contract",
				this.editVacationForm.get("vacation_paid_days_from_contract").value
			);
			formData.append(
				"signature_a_user_id",
				this.editVacationForm.get("signature_a_user_id").value
			);
			formData.append(
				"signature_a_title",
				this.editVacationForm.get("signature_a_title").value
			);
			formData.append(
				"signature_b_user_id",
				this.editVacationForm.get("signature_b_user_id").value
			);
			formData.append(
				"signature_b_title",
				this.editVacationForm.get("signature_b_title").value
			);
			formData.append(
				"needAttach",
				this.editVacationForm.get("needAttach").value
			);
			formData.append("needMark", this.editVacationForm.get("needMark").value);
			formData.append("marks", this.editVacationForm.get("marks").value);
			formData.append(
				"needReview",
				this.editVacationForm.get("needReview").value
			);
			formData.append("reviews", this.editVacationForm.get("reviews").value);
			formData.append(
				"vacation_total_unpaid_days",
				this.editVacationForm.get("vacation_total_unpaid_days").value
			);
			if (this.editVacationForm.get("vacation_type_id").value == "3") {
				formData.append(
					"vacation_total_paid_days",
					this.editVacationForm.get("paid_days75").value +
						this.editVacationForm.get("paid_days100").value
				);
				formData.append(
					"vacation_total_paid_amount",
					this.editVacationForm.get("paid_days75_amount").value +
						this.editVacationForm.get("paid_days100_amount").value
				);
			} else {
				formData.append(
					"vacation_total_paid_days",
					this.editVacationForm.get("vacation_total_paid_days").value
				);
				formData.append(
					"vacation_total_paid_amount",
					this.editVacationForm.get("vacation_total_paid_amount").value
				);
			}

			this.attachments.controls.forEach((item, index) => {
				formData.append(`attachments[${index}][file]`, item.get("file").value);
				formData.append(
					`attachments[${index}][attach_title]`,
					item.get("attach_title").value
				);
			});
			formData.append("transaction_request_id", this.form_request_id);

			if (this.showAttachment) {
				if (this.attachments.length) {
					this.spinner.show();
					this.ds.post(`form/FORM_HRV1/et`, formData).subscribe((res) => {
						this.spinner.hide();
						if (res.status) {
							this.showMsg(res.msg, "ok", "success-snackbar");
							this.resetFields();
							setTimeout(() => {
								this.router.navigate([
									`transactions/form/FORM_HRV1/vr/${this.form_request_id}`,
								]);
							}, 500);
						} else {
							this.showMsg(res.error, "ok", "error-snackbar");
						}
					});
				} else {
					this.showMsg("add attachments", "ok", "error-snackbar");
				}
			} else {
				this.spinner.show();
				this.ds
					.post(`form/FORM_HRV1/et/${this.form_request_id}`, formData)
					.subscribe((res) => {
						this.spinner.hide();
						if (res.status) {
							this.showMsg(res.msg, "ok", "success-snackbar");
							this.resetFields();
							setTimeout(() => {
								this.router.navigate([
									`transactions/form/FORM_HRV1/vr/${this.form_request_id}`,
								]);
							}, 500);
						} else {
							this.showMsg(res.error, "ok", "error-snackbar");
						}
					});
			}
		} else {
			this.showMsg("All Fields are required!!", "ok", "error-snackbar");
		}
	}

	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 2000,
			panelClass: [className],
		});
	}

	resetFields() {
		this.editVacationForm.reset();
		this.attachstatus = false;
		this.reviewstatus = false;
		this.markstatus = false;
		this.editVacationForm.get("needMark").setValue("0");
		this.editVacationForm.get("needReview").setValue("0");
		this.editVacationForm.get("needAttach").setValue("0");
		this.editVacationForm.get("contract_id").setValue(this.contract_id);
		this.editVacationForm
			.get("vacation_paid_days_from_contract")
			.setValue(this.vacation_paid_days_from_contract);
	}
}
