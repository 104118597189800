import { Component, OnInit, TemplateRef } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";
declare var $: any;
@Component({
	selector: "app-applicationview",
	templateUrl: "./applicationview.component.html",
	styleUrls: ["./applicationview.component.scss"],
})
export class ApplicationviewComponent implements OnInit, OnDestroy {
	id = this.route.snapshot.paramMap.get("id");
	key = this.route.snapshot.paramMap.get("key");
	apiurl = environment.SERVER_ORIGIN;
	public profile_percentage: number;
	viewstatus = false;
	viewdata: any = [];
	attachments: any = [];
	licences: any = [];
	cerrormessagef: string = this.lang.transform("lang_loading");
	//attachment variable declarations starts
	apage: number = 1;
	apsize: any = "10";
	attach: any = [];
	aerror = this.lang.transform("lang_loading");
	ausers: any = [];
	view_profile_image = "";
	wadata: any = [];
	attacheditdata: any = [];
	attachform: FormGroup;
	uform: FormGroup;
	types: any = [];
	idfilename = this.lang.transform("lang_select_files");
	typelists: any = [];
	modalRefA: BsModalRef;
	modalRefV: BsModalRef;
	modalRefU: BsModalRef;
	searchKey = "";
	filepermissionlevels: any = [];
	constructor(
		public router: Router,
		public ds: DataService,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.uform = new FormGroup({
			employee_number: new FormControl(this.id, [Validators.required]),
			attachment_descriptions: new FormControl("", [Validators.required]),
			level_keys: new FormControl("", [Validators.required]),
			file_records_id: new FormControl("", [Validators.required]),
		});
		this.attach_form();
		this.file_permission_levels();

		this.get_view_details();
	}
	public file_permission_levels() {
		this.filepermissionlevels = [];
		this.subscriptions.add(
			this.ds.getActionByUrl([], "lflevel").subscribe((res) => {
				if (res.status) {
					this.filepermissionlevels = res.records;
				}
			})
		);
	}
	private subscriptions = new Subscription();
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
	public openModalA(template: TemplateRef<any>) {
		this.modalRefA = this.modalService.show(template, environment.modelconfig);
	}
	public openModalV(template: TemplateRef<any>) {
		this.modalRefV = this.modalService.show(template, environment.modelconfig);
	}
	public openModalU(template: TemplateRef<any>) {
		this.modalRefU = this.modalService.show(template, environment.modelconfig);
	}
	public get_view_details() {
		this.spinner.show();
		this.subscriptions.add(
			this.ds
				.getActionByUrl([], "human_resources/get_application_data/" + this.id)
				.subscribe(
					(data) => {
						this.spinner.hide();
						this.viewstatus = data.status;
						if (data.status) {
							this.viewdata = data.data;
							this.attachments = data.attachments;
							this.licences = data.licences;
							if (data.profile_percentage) {
								this.profile_percentage = data.profile_percentage.total;
							}
							if (this.viewdata.profile_image) {
								this.get_profile_image(this.viewdata.profile_image);
							}
							this.load_my_attachments();
							this.attachment_types();
						} else {
							this.spinner.hide();
							this.router.navigate(["human_resources/applications"]);
							this.ds.dialogf("", data.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.viewstatus = false;
						this.ds.dialogf(
							"",
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}
	public attachment_types(): void {
		this.types = [];
		this.subscriptions.add(
			this.ds
				.getActionByUrl([], "module_attach_types/?module_name=human_resources")
				.subscribe((res) => {
					if (res.status) {
						this.types = res.records;
					}
				})
		);
	}
	//code for attachments starts
	public attachlength(size) {
		this.apsize = size;
		this.apage = 1;
		this.load_my_attachments();
	}
	public attachpage(page) {
		if (page) {
			this.apage = page;
		}
		this.load_my_attachments();
	}
	public search_my_attchments() {
		this.apage = 1;
		this.apsize = "10";
		this.load_my_attachments();
	}
	public load_my_attachments() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("search_key", this.searchKey || "");
		formData.append("employee_number", this.id);
		formData.append(
			"attachmentType",
			this.typelists && this.typelists.length > 0
				? this.typelists.toString()
				: ""
		);
		this.subscriptions.add(
			this.ds
				.postActionByUrl(
					formData,
					"human_resources/application_attachments/" +
						this.apage +
						"/" +
						this.apsize
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.attach = res.records;
							this.ausers = res.users;
							this.wadata = res;
						} else {
							this.attach = [];
							this.aerror = res.error;
						}
					},
					(error) => {
						this.spinner.hide();
						this.attach = [];
						this.aerror =
							error.error + (error && error.error && error.error.error)
								? error.error.error
								: this.lang.transform("lang_internal_server_error");
					}
				)
		);
	}
	public get_profile_image(url) {
		this.view_profile_image = "";
		this.subscriptions.add(
			this.ds.getActionByUrl([], url).subscribe((data) => {
				if (data.status) {
					this.view_profile_image =
						"data:" + data["content-type"] + ";base64," + data.base64;
				}
			})
		);
	}
	public showsignature(empid, type) {
		this.spinner.show();
		this.subscriptions.add(
			this.ds
				.getActionByUrl([], "appviewsmark/" + empid + "/" + type)
				.subscribe(
					(data) => {
						this.spinner.hide();
						if (data.status) {
							($("#showSMPreview") as any).modal({
								backdrop: "static",
								keyboard: false,
							});
							($("#showSMPreview") as any).modal("show");
							$("#SMnabrPdf").attr(
								"src",
								"data:" + data["content-type"] + ";base64," + data.base64
							);
						} else {
							this.ds.dialogf("", data.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.ds.dialogf(
							"",
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}
	public edit_my_Attach(attach) {
		this.alert.clear();
		this.attacheditdata = attach;
		this.uform.patchValue({
			employee_number: this.id,
			attachment_descriptions: attach.file_name,
			level_keys: attach.level_keys.split(","),
			file_records_id: attach.file_records_id,
		});
	}
	public emptyAttachForm() {
		this.alert.clear();
		this.attachform.reset();
		this.attachform.get("employee_number").setValue(this.id);
		this.idfilename = this.lang.transform("lang_select_files");
	}
	public attach_form() {
		this.attachform = new FormGroup({
			employee_number: new FormControl(this.id, [Validators.required]),
			attachment_type: new FormControl("", [Validators.required]),
			attachment_descriptions: new FormControl("", [Validators.required]),
			attachment_link: new FormControl("", [Validators.required]),
			level_keys: new FormControl("", [Validators.required]),
		});
	}
	public uploadIDFile(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			const fileid = event.target.files[0];
			this.idfilename = fileid.name;
			this.attachform
				.get("attachment_link")
				.setValue(fileid, { emitEvent: true });
		} else {
			this.attachform.get("attachment_link").setValue(null);
			this.idfilename = this.lang.transform("lang_select_files");
		}
	}
	public upload_files() {
		this.spinner.show();
		this.subscriptions.add(
			this.ds
				.postActionByUrl(
					this.ds.json2formdata(this.attachform.value),
					"hr_appilication_file_upload"
				)
				.subscribe(
					(data) => {
						this.spinner.hide();
						if (data.status) {
							this.load_my_attachments();
							setTimeout(() => {
								this.modalRefA.hide();
							}, 1000);
							this.alert.success(data.msg);
						} else {
							this.alert.error(data.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(
							error.error + (error && error.error && error.error.error)
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}
	public update() {
		this.spinner.show();
		this.subscriptions.add(
			this.ds
				.putActionByUrl(
					this.ds.formData2string(this.ds.json2formdata(this.uform.value)),
					"hr_update_app_filename"
				)
				.subscribe(
					(data) => {
						this.spinner.hide();
						if (data.status) {
							this.uform.reset();
							this.load_my_attachments();
							setTimeout(() => {
								this.modalRefU.hide();
							}, 1000);
							this.alert.success(data.msg);
						} else {
							this.alert.error(data.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(
							error.error + (error && error.error && error.error.error)
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}
	public checkAllAttach(event) {
		if (event.target.checked) {
			$(".appattachments .eachItemAttach").prop("checked", true);
			$(".appattachments .makeback").addClass("bground");
			$(".appattachments .deleteIconDiv").show();
		} else {
			$(".appattachments .eachItemAttach").prop("checked", false);
			$(".appattachments .makeback").removeClass("bground");
			$(".appattachments .deleteIconDiv").hide();
		}
	}
	public checkEachItemAttach(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".appattachments .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".appattachments .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".appattachments .eachItemAttach:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".appattachments .eachItemAttach").each(function () {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".appattachments .deleteIconDiv").show();
		} else {
			$(".appattachments .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".appattachments #allItemsAttach").prop("checked", true);
		} else {
			$(".appattachments #allItemsAttach").prop("checked", false);
		}
	}
	public deleteBulkDataAttach() {
		const checkedtotal = [];
		$(".appattachments .eachItemAttach:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: "400px",
				data: {
					title: this.lang.transform("lang_confirmation"),
					message: this.lang.transform("lang_are_you_sure"),
				},
			});
			this.subscriptions.add(
				dialogRef.afterClosed().subscribe((confirmed: boolean) => {
					if (confirmed) {
						this.spinner.show();
						this.subscriptions.add(
							this.ds
								.deleteActionByUrl(
									checkedtotal,
									"application_attach/" + this.id
								)
								.subscribe(
									(data) => {
										this.spinner.hide();
										if (data.status) {
											this.get_view_details();
											this.load_my_attachments();
											$(".appattachments #allItemsAttach").prop(
												"checked",
												false
											);
											$(".appattachments .deleteIconDiv").hide();
											this.ds.dialogf("", data.msg);
										} else {
											this.ds.dialogf("", data.error);
										}
									},
									(error) => {
										this.ds.dialogf(
											"",
											error && error.error && error.error.error
												? error.error.error
												: this.lang.transform("lang_internal_server_error")
										);
										this.spinner.hide();
									}
								)
						);
					}
				})
			);
		} else {
			this.ds.dialogf();
		}
	}
}
