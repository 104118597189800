<div class="row w-100 justify-content-between mx-1">
  <h5>
    {{
      info.firstname_english +
        " " +
        info.secondname_english +
        " " +
        info.thirdname_english +
        " " +
        info.lastname_english
    }}
  </h5>
  <mat-icon style="color: brown; position: relative" (click)="cancel()"
    >cancel_presentation</mat-icon
  >
</div>

<mat-tab-group class="mat-tap">
  <mat-tab label="{{ 'lang_transactions' | language }}">
    <div class="row w-100">
      <table class="table table-striped custom-payrole-table">
        <thead>
          <tr>
            <th scope="col">
              <div
                class="checkbox m-auto"
                matTooltip="{{ 'lang_check' | language }}"
              >
                <!-- <input type="checkbox"  class="eachItemHR checkbox" value="{{item.employee_number}}" (click)="checkEachItemHR($event)"/> -->
                <input
                  type="checkbox"
                  [checked]="allSelected"
                  (click)="selectAll($event)"
                  class="eachItemHR checkbox"
                />
                <label for="checkbox"></label>
              </div>
            </th>
            <th scope="col">{{ "lang_transaction" | language }}</th>
            <th *ngIf="type == 'earning'" scope="col">
              {{ "lang_credit_amount" | language }}
            </th>
            <th *ngIf="type == 'deduction'" scope="col">
              {{ "lang_debit_amount" | language }}
            </th>

            <!--   <th scope="col">{{'lang_status'|language}}</th> -->
            <th scope="col">{{ "lang_date" | language }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of userTransactions; let i = index">
            <tr
              *ngIf="
                (item.credit_amount != '0.00' && type == 'earning') ||
                (item.debit_amount != '0.00' && type == 'deduction')
              "
            >
              <th scope="row">
                <div
                  class="checkbox m-auto"
                  matTooltip="{{ 'lang_check' | language }}"
                >
                  <!-- <input type="checkbox"  class="eachItemHR checkbox" value="{{item.employee_number}}" (click)="checkEachItemHR($event)"/> -->
                  <input
                    type="checkbox"
                    [checked]="item.select_status == 'selected' ? true : false"
                    value="{{
                      item.select_status == 'selected' ? true : false
                    }}"
                    (click)="checkEachItemHR($event, i)"
                    class="eachItemHR checkbox"
                  />
                  <label for="checkbox"></label>
                </div>
              </th>

              <td>{{ item.transaction_key }}</td>
              <td *ngIf="type == 'earning'">{{ item.credit_amount }}</td>
              <td *ngIf="type == 'deduction'">{{ item.debit_amount }}</td>

              <!--  <td>{{'lang_'+item.select_status|language}}</td> -->
              <td>{{ item.finance_month }} / {{ item.finance_year }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </mat-tab>
  <!--    <mat-tab label="{{'lang_deduction'|language}}">
      <div class="row w-100">
        <table class="table table-striped custom-payrole-table">
            <thead>
              <tr>
                  <th scope="col">
                    <div class="checkbox m-auto"  matTooltip="{{'lang_check' | language}}">
                      
                        <input type="checkbox"  class="eachItemHR checkbox" />
                        <label for="checkbox"></label>
                    </div>
                  </th>
                <th scope="col">{{'lang_transaction'|language}}</th>
                <th scope="col">{{'lang_amount'|language}}</th>
                <th scope="col">{{'lang_status'|language}}</th>
                <th scope="col">{{'lang_date'|language}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                    <div class="checkbox m-auto"  matTooltip="{{'lang_check' | language}}">
                     
                        <input type="checkbox"  class="eachItemHR checkbox" />
                        <label for="checkbox"></label>
                    </div>
                </th>
                
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>Mark</td>
              </tr>
              <tr>

                <th scope="row">
                    <div class="checkbox m-auto"  matTooltip="{{'lang_check' | language}}">
                      
                        <input type="checkbox"  class="eachItemHR checkbox" />
                        <label for="checkbox"></label>
                    </div>
                </th>
                <td>Jacob</td>
                <td>Mark</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">
                    <div class="checkbox m-auto"  matTooltip="{{'lang_check' | language}}">
                        
                        <input type="checkbox"  class="eachItemHR checkbox" />
                        <label for="checkbox"></label>
                    </div>
                </th>
                <td>Larry</td>
                <td>Mark</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
      

      </div> 
    </mat-tab> -->
</mat-tab-group>

<mat-dialog-actions>
  <div class="d-flex justify-content-between" style="width: 100%">
    <div style="width: 100px">
      <button type="button" class="albutton" (click)="cancel()">
        {{ "lang_cancel" | language }}
      </button>
    </div>

    <div style="width: 100px">
      <button
        mat-button
        class="albutton mnabrbutton mat-accent"
        (click)="sendFilterForm()"
      >
        {{ "lang_save" | language }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
