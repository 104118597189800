<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_ticket" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="ticketForm">
		<div class="row">
			<div class="col-12 col-md-8 mb-2">
				<section class="center-content">
					<div class="row pt-2">
						<div class="col-12 mb-2">
							<label for="name"
								>{{ "lang_subject" | language }}
								<span class="red">*</span></label
							>
							<input
								formControlName="ticket_title"
								class="form-control"
								placeholder="{{ 'lang_subject' | language }}"
								autocomplete="off"
							/>
							<mat-error
								*ngIf="
									ticketForm.get('ticket_title')?.touched &&
									ticketForm.get('ticket_title')?.invalid
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
						<div class="col-12">
							<hr />
						</div>
						<div class="col-12">
							<label for="description"
								>{{ "lang_description" | language
								}}<span class="red">*</span></label
							>
							<textarea
								name="description"
								id="description"
								cols="30"
								rows="4"
								class="form-control"
								placeholder="{{ 'lang_description' | language }}"
								formControlName="ticket_detalis"
							>
							</textarea>
							<mat-error
								*ngIf="
									ticketForm.get('ticket_detalis')?.touched &&
									ticketForm.get('ticket_detalis')?.invalid
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div>
					<div class="row pt-4">
						<div class="col-12 TableParts">
							<p class="mb-10">
								{{ "lang_attachments" | language }}
								<span class="red" *ngIf="other_attachments?.length">*</span>
							</p>
							<div class="table-responsive customResponsive quotationitemtable">
								<table
									border="1"
									class="table CustomTable"
									width="100%"
									formArrayName="attachments"
								>
									<thead>
										<tr>
											<td
												width="2%"
												style="padding: 5px; word-break: break-all"
											>
												#
											</td>
											<td
												width="2%"
												style="padding: 5px; word-break: break-all"
											>
												<mat-icon
													class="mnabricon"
													matTooltip="{{ 'lang_add' | language }}"
													(click)="addfiles()"
													>add_box</mat-icon
												>
											</td>
											<td
												width="48%"
												style="padding: 5px; word-break: break-all"
											>
												{{ "lang_title" | language }}
											</td>
											<td
												width="48%"
												style="padding: 5px; word-break: break-all"
											>
												{{ "lang_file" | language }}
											</td>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="
												let file of filesFormGroup.controls;
												let i = index;
												let odd = odd;
												let even = even
											"
											[formGroupName]="i"
										>
											<td
												class="verticalalignmid"
												style="padding: 5px; word-break: break-all"
											>
												{{ i + 1 }}
											</td>
											<td class="verticalalignmid">
												<mat-icon
													*ngIf="
														!other_attachments.controls[i].get('delete_icon')
															.value
													"
													(click)="removefiles(i)"
													class="mnabricon red"
													matTooltip="{{ 'lang_delete' | language }}"
													>delete_forever</mat-icon
												>
											</td>
											<td
												style="
													padding: 5px;
													word-break: break-all;
													position: relative;
												"
											>
												<input
													type="text"
													formControlName="attach_title"
													placeholder="{{ 'lang_description' | language }}"
													autocomplete="off"
													class="form-control almnabrformcontrol shadow-none"
												/>
											</td>
											<td
												class="verticalalignmid"
												style="
													padding: 5px;
													word-break: break-all;
													position: relative;
												"
											>
												<input
													type="text"
													formControlName="file"
													placeholder="{{ 'lang_file' | language }}"
													autocomplete="off"
													class="form-control almnabrformcontrol shadow-none"
													[class.border-danger]="
														other_attachments.controls[i].get('file').errors
															?.urlWrong
													"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="col-12 TableParts" *ngIf="userCounts?.length">
							<p style="margin: 10px 0">
								{{ "lang_user_tasks" | language }}
								<span class="red" *ngIf="other_attachments?.length">*</span>
							</p>
							<div
								class="table-responsive customResponsive tablefields quotationitemtable"
							>
								<table border="1" class="table CustomTable" width="100%">
									<thead>
										<tr>
											<td
												width="4%"
												style="padding: 5px; word-break: break-all"
											>
												#
											</td>
											<td
												width="48%"
												style="padding: 5px; word-break: break-all"
											>
												{{ "lang_username" | language }}
											</td>
											<td
												width="48%"
												style="padding: 5px; word-break: break-all"
											>
												{{ "lang_tasks" | language }}
											</td>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="
												let item of userCounts;
												let i = index;
												let odd = odd;
												let even = even
											"
										>
											<td
												class="verticalalignmid"
												style="padding: 5px; word-break: break-all"
											>
												{{ i + 1 }}
											</td>
											<td
												style="
													padding: 5px;
													word-break: break-all;
													position: relative;
												"
											>
												{{ item?.user_profile }}
											</td>
											<td
												class="verticalalignmid"
												style="
													padding: 5px;
													word-break: break-all;
													position: relative;
												"
											>
												<span
													style="
														font-weight: 600;
														color: rgb(38, 133, 228);
														cursor: pointer;
													"
													(click)="onSelectUser(item?.user_id)"
													>{{ item?.counts }}</span
												>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</section>
			</div>
			<div class="col-12 col-md-4 mb-2">
				<section class="right-content">
					<div class="row">
						<div class="col-12 mb-2">
							<div class="d-flex align-items-center justify-content-between">
								<h4>
									{{ "lang_users" | language }}
									<span class="red">*</span>
								</h4>
								<span (click)="onAddUpdateGroupUser()" style="cursor: pointer">
									{{ "lang_user_group" | language }}
									<i
										class="fa fa-users mnabricon"
										style="font-size: 14px; margin: 0 5px"
									></i>
								</span>
							</div>
						</div>
						<div class="col-12 mb-2">
							<ng-select
								[items]="user_list"
								[multiple]="true"
								appendTo="body"
								bindLabel="label"
								bindValue="value"
								[searchFn]="ds.customSearchFn"
								clearAllText="Clear"
								placeholder="{{ 'lang_users' | language }}"
								[searchable]="true"
								formControlName="users"
								id="assigned_user"
								name="assigned_user"
								(search)="search_users($event)"
							>
								<ng-template
									ng-multi-label-tmp
									let-items="items"
									let-clear="clear"
								>
									<div class="ng-value" *ngFor="let item of items">
										<span class="ng-value-label">
											<img
												[src]="item.userAvatar"
												width="20px"
												height="20px"
												*ngIf="item.userAvatar"
											/>
											<img
												src="assets/images/avatar.png"
												width="20px"
												height="20px"
												*ngIf="!item.userAvatar"
											/>
											<span style="font-size: 11px; margin: 0 1px">{{
												item.label
											}}</span>
										</span>
										<span
											class="ng-value-icon right"
											(click)="clear(item)"
											aria-hidden="true"
											>×</span
										>
									</div>
								</ng-template>
								<ng-template
									ng-option-tmp
									let-item="item"
									let-index="index"
									let-search="searchTerm"
								>
									<div
										style="
											display: flex;
											justify-content: space-between;
											align-items: center;
											width: 100%;
										"
									>
										<img
											[src]="item.userAvatar"
											width="20px"
											height="20px"
											*ngIf="item.userAvatar"
										/>
										<img
											src="assets/images/avatar.png"
											width="20px"
											height="20px"
											*ngIf="!item.userAvatar"
										/>
										<span>{{ item?.label }}</span>
									</div>
								</ng-template>
							</ng-select>
							<mat-error
								*ngIf="
									ticketForm.get('users')?.touched &&
									ticketForm.get('users')?.invalid
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
						<div class="col-12">
							<hr />
						</div>
						<div class="col-12 mb-2">
							<div class="form-group">
								<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
									<mat-form-field appearance="outline" style="width: 100%">
										<mat-label
											>{{ "lang_total_days" | language }}
											<span class="red">*</span></mat-label
										>
										<input
											matInput
											placeholder="{{ 'lang_total_days' | language }}"
											autocomplete="off"
											formControlName="time_work"
											(keyup)="calcEndDate($event, 'dayschange')"
											(keypress)="isNumber($event)"
										/>
									</mat-form-field>
									<mat-error
										*ngIf="
											ticketForm.get('time_work')?.touched &&
											ticketForm.get('time_work')?.invalid
										"
										>{{ "lang_field_required" | language }}</mat-error
									>
								</div>
							</div>
						</div>
						<!-- <div class="col-12 mb-2">
                            <div class="form-group">
                                <div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                    <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label>{{ "lang_start_date" | language }}</mat-label>
                                        <input
                                            matInput
                                            [matDatepicker]="sd"
                                            required
                                            readonly
                                            formControlName="start_date"
                                            (dateChange)="calcEndDate($event, 'datechange')"
                                        />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="sd"
                                        ></mat-datepicker-toggle>
                                        <mat-datepicker #sd></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div> -->
						<div class="col-12 mb-2" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
							<mat-form-field appearance="outline" style="width: 100%">
								<mat-label>{{ "lang_start_date" | language }}</mat-label>
								<input
									matInput
									required
									readonly
									formControlName="start_date"
									(dateChange)="calcEndDate($event, 'datechange')"
								/>
								<mat-datepicker-toggle
									matSuffix
									(click)="picker.open()"
								></mat-datepicker-toggle>
								<mbsc-datepicker
									[controls]="['calendar', 'time']"
									select="date"
									[touchUi]="false"
									dateFormat="YYYY/MM/DD HH:mm:ss"
									[(ngModel)]="startDateMobiscroll"
									[buttons]="autoButtons"
									[ngModelOptions]="{ standalone: true }"
									(onClose)="custmizeDate('start_date')"
									style="visibility: hidden; height: 0"
									#picker
								>
								</mbsc-datepicker>
							</mat-form-field>
						</div>
						<div class="col-12 mb-2" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
							<mat-form-field appearance="outline" style="width: 100%">
								<mat-label>{{ "lang_end_date" | language }}</mat-label>
								<input matInput required readonly formControlName="end_date" />
								<mat-datepicker-toggle matSuffix></mat-datepicker-toggle>
							</mat-form-field>
						</div>
						<div class="col-12 mb-2">
							<hr />
						</div>
						<div class="col-12 mb-2">
							<mat-slide-toggle formControlName="need_reply">{{
								"lang_need_reply" | language
							}}</mat-slide-toggle>
						</div>
						<div class="col-12 mb-2" *ngIf="ticketForm.get('need_reply').value">
							<div class="form-group">
								<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
									<mat-form-field appearance="outline" style="width: 100%">
										<mat-label
											>{{ "lang_reply" | language }}
											{{ "lang_date" | language }}</mat-label
										>
										<input
											matInput
											[matDatepicker]="rd"
											required
											readonly
											formControlName="date_reply"
										/>
										<mat-datepicker-toggle
											matSuffix
											[for]="rd"
										></mat-datepicker-toggle>
										<mat-datepicker #rd></mat-datepicker>
									</mat-form-field>
								</div>
							</div>
						</div>
						<div class="col-12 mb-3">
							<label for="note">{{ "lang_note" | language }}</label>
							<textarea
								name="note"
								id="note"
								cols="30"
								rows="3"
								class="form-control"
								placeholder="{{ 'lang_note' | language }}"
								formControlName="notes"
							>
							</textarea>
						</div>
					</div>
				</section>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="submit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
