<div mat-dialog-title class="d-flex justify-content-between">
	<h4>Contract Component</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="component_form">
		<div class="p-2">
			<div class="row mb-2">
				<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Component Name Ar</mat-label>
						<input
							matInput
							placeholder="Type Component Name Ar"
							formControlName="component_building_name_english"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Component Name En</mat-label>
						<input
							matInput
							placeholder="Type Component Name English"
							formControlName="component_building_name_arabic"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Component Quantity</mat-label>
						<input
							matInput
							placeholder="Type Component Quantity"
							type="number"
							formControlName="component_building_quantity"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Component Level Quantity</mat-label>
						<input
							matInput
							placeholder="Type Component Level Quantity"
							type="number"
							formControlName="component_level_quantity"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Component Estimated Space</mat-label>
						<input
							matInput
							placeholder="Type Component Estimated Space"
							type="number"
							formControlName="component_estimated_space"
						/>
					</mat-form-field>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-0 col-sm-9"></div>
	<div class="col-12 col-sm-3">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="submit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
