<h3 mat-dialog-title class="d-flex justify-content-between">
    <span>{{ "lang_compeletion_rate_filter" | language }}</span>
    <i class="fa fa-close mnabricon" mat-dialog-close></i>
</h3>
<mat-dialog-content class="mat-typography">
	<div class="row">
        <div class="col-12 col-sm-3 mb-2">
            <mat-form-field appearance="outline">
                <mat-label>{{"lang_date" | language}}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate [formControl]="from_date"  placeholder="YYYY/MM/DD" readonly>
                    <input matEndDate [formControl]="to_date" placeholder="YYYY/MM/DD" readonly>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <div class="col-0 col-sm-6"></div>
        <div class="col-12 col-sm-3 mb-2">
			<mat-slide-toggle
            color="primary"
            [formControl]="with_balance">
                {{"lang_with_balance" | language}}
            </mat-slide-toggle>
        </div>
        <div class="col-12 col-sm-6 mb-2">
            <label for="division">{{ "lang_group_one" | language }}</label>
			<ng-select
                [items]="divisions"
                [multiple]="true"
                bindLabel="platform_group1_title"
                bindValue="platform_group1_code_system"
                appendTo="body"
                clearAllText="Clear"
                placeholder="{{ 'lang_group_one' | language }}"
                [(ngModel)]="platform_group1_code_system"
                id="division"
            >
            </ng-select>
        </div>
        
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
    <div style="width: 100px">
        <button
            mat-button
            class="albutton mnabrbutton mat-accent"
            (click)="downloadExcel()"
        >
            {{ "lang_export_excel" | language }}
        </button>
    </div>
	<div style="width: 100px">
        <button
            mat-button
            class="albutton mnabrbutton mat-accent"
            (click)="onSubmit()"
        >
            {{ "lang_submit" | language }}
        </button>
    </div>
</mat-dialog-actions>
