<div mat-dialog-title class="d-flex align-items-center justify-content-between">
	<h2 style="font-size: 18px; margin: 0">
		{{ "lang_work_levels" | language }}
	</h2>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_template" | language }}</td>
							<td>{{ "lang_platform" | language }}</td>
							<td>{{ "lang_group_one" | language }}</td>
							<td>{{ "lang_type" | language }}</td>
							<td>{{ "lang_work_level" | language }}</td>
							<td>{{ "lang_writer" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody *ngIf="platformLevels?.length" class="AppendList">
						<ng-container
							*ngFor="
								let list of platformLevels;
								let i = index;
								let odd = odd;
								let even = even
							"
						>
							<tr>
								<td>{{ i + 1 }}</td>
								<td>{{ list.templatename }}</td>
								<td>
									{{ list.platformname }} ({{ list.platform_code_system }})
								</td>
								<td>{{ list.group1name }}</td>
								<td>{{ list.typename }}</td>
								<td>{{ list.worklevelname }}</td>
								<td>{{ list.writer }}</td>
								<td>
									<i
										class="fa fa-trash red mr-1 ml-1"
										(click)="deleteWorklevel(list.cpay_platform_work_level_id)"
										matTooltip="{{ 'lang_delete' | language }}"
										*ngIf="makeActionConfig"
									></i>
								</td>
							</tr>
						</ng-container>
					</tbody>
					<tbody class="AppendList" *ngIf="!platformLevels?.length">
						<tr>
							<td colspan="8">{{ "lang_no_data" | language }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div *ngIf="platformLevels.length > 0" class="card-footer padb0">
		<div class="row responsive_pagination">
			<div
				class="col-lg-3 col-sm-3 mb-10"
				style="height: fit-content; margin: auto 0"
			>
				{{ "lang_total" | language }} : {{ worklevelesCollectionSize }}
			</div>
			<div
				class="col-lg-5 col-sm-5 mb-10"
				style="display: flex; justify-content: center"
			>
				<ngb-pagination
					class="m-auto"
					[collectionSize]="worklevelesCollectionSize"
					[rotate]="true"
					[ellipses]="false"
					[maxSize]="3"
					[boundaryLinks]="true"
					[(page)]="pageno"
					[pageSize]="pagesize"
					(pageChange)="getPlatformLevels()"
				></ngb-pagination>
			</div>
			<div
				class="col-lg-3 col-sm-3"
				style="display: flex; justify-content: end; margin: auto"
			>
				<mat-select
					[(ngModel)]="pagesize"
					(selectionChange)="getPlatformLevels()"
				>
					<mat-option class="text-center" value="10"
						>10 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="20"
						>20 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="50"
						>50 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="100"
						>100 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option class="text-center" value="500"
						>500 {{ "lang_per_page" | language }}</mat-option
					>
				</mat-select>
			</div>
		</div>
	</div>
</mat-dialog-content>
