import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-qp-add-edit",
	templateUrl: "./qp-add-edit.component.html",
	styleUrls: ["./qp-add-edit.component.scss"],
})
export class QpAddEditComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	positionForm: FormGroup;
	position_data;
	edit_mode = false;
	constructor(
		private fb: FormBuilder,
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private spinner: PreloaderService,
		private dialogRef: MatDialogRef<QpAddEditComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.position_data = data?.position_data;
		this.edit_mode = this.position_data ? true : false;
	}

	ngOnInit(): void {
		this.buildForm();
		if (this.edit_mode) {
			this.setEditData();
		}
	}

	buildForm() {
		this.positionForm = this.fb.group({
			quotation_types_name_ar: [null, Validators.required],
			quotation_types_name_en: [null, [Validators.required]],
			quotation_types_keyword: [null, [Validators.required]],
		});
	}
	setEditData() {
		this.positionForm
			.get("quotation_types_name_ar")
			.setValue(this.position_data.quotation_types_name_ar);
		this.positionForm
			.get("quotation_types_name_en")
			.setValue(this.position_data.quotation_types_name_en);
		this.positionForm
			.get("quotation_types_keyword")
			.setValue(this.position_data.quotation_types_keyword);
	}

	addEditPosition() {
		this.spinner.show();
		let param = new FormData();
		let url = "quotation_setting/add_quotation_types";
		if (this.edit_mode) {
			param.append("quotation_types_id", this.position_data.quotation_types_id);
			url = "quotation_setting/update_quotation_types";
		}
		param.append(
			"quotation_types_name_ar",
			this.positionForm.value.quotation_types_name_ar
		);
		param.append(
			"quotation_types_name_en",
			this.positionForm.value.quotation_types_name_en
		);
		param.append(
			"quotation_types_keyword",
			this.positionForm.value.quotation_types_keyword
		);
		this.ds.post(url, param).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						2000
					);
					this.dialogRef.close(true);
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
}
