import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AddProjectComponent } from "../add-project/add-project.component";

@Component({
	selector: "app-design-lists",
	templateUrl: "./design-lists.component.html",
	styleUrls: ["./design-lists.component.scss"],
})
export class DesignListsComponent implements OnInit {
	modalRefV: BsModalRef;
	@Input() project_data;
	@Input() service_data;
	@Input() filelevels;
	@Input() able2add;
	@Input() able2edit;
	@Input() able2delete;
	@Input() isQuotationExists;
	nodata: string = this.lang.transform("lang_loading");
	viewdata: any = [];
	listData: any = [];
	pageno: any = 1;
	pagesize: any = 10;
	searchKey = "";
	constructor(
		public ds: DataService,
		public router: Router,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.load_design_lists();
	}
	public search_design_lists() {
		this.pageno = 1;
		this.pagesize = 10;
		this.load_design_lists();
	}
	public load_design_lists_page(page) {
		this.spinner.show();
		this.pageno = page;
		this.pagesize = this.pagesize;
		this.load_design_lists();
	}
	public load_design_lists_pagesize(size) {
		this.spinner.show();
		this.pageno = 1;
		this.pagesize = size;
		this.load_design_lists();
	}
	load_design_lists() {
		let formdata = new FormData();
		formdata.append(
			"projects_profile_id",
			this.project_data.projects_profile_id || ""
		);
		this.ds
			.post(
				"project_design/get_data_projects_design/" +
					this.pageno +
					"/" +
					this.pagesize,
				formdata
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.listData = res;
					} else {
						this.listData = [];
						this.nodata = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.listData = [];
					this.nodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}
	public openViewModal(template: TemplateRef<any>, data) {
		this.modalRefV = this.modalService.show(template, environment.modelconfig);
		this.viewdata = data;
	}
	updateDesignProject(update_data) {
		const dialogRef = this.dialog.open(AddProjectComponent, {
			width: "800px",
			data: {
				projects_profile_id: this.project_data.projects_profile_id,
				service_id: this.service_data?.projects_ps_id,
				update_data: update_data,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res?.status == "success") {
				this.spinner.show();
				this.load_design_lists();
			}
		});
	}
}
