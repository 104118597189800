import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { DataService } from "@core/bootstrap/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
	hijriCalendar,
	locale,
	MbscCalendarEvent,
	MbscEventcalendarOptions,
	MbscEventcalendarView,
	MbscPopup,
	MbscPopupOptions,
	Notifications,
} from "@mobiscroll/angular";
import { MatDialog } from "@angular/material/dialog";
import { ViewTaskComponent } from "../view-task/view-task.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { PreloaderService } from "@core";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { TicketTaskUsersComponent } from "../ticket-task-users/ticket-task-users.component";
import { ViewUsersComponent } from "../view-users/view-users.component";
import { TicketContractsComponent } from "../ticket-contracts/ticket-contracts.component";
import { TicketDetailsComponent } from "../ticket-details/ticket-details.component";
import { AddTemplateComponent } from "../../templates/add-template/add-template.component";
import * as moment from "moment";
@Component({
	selector: "app-ticket-timeline",
	templateUrl: "./ticket-timeline.component.html",
	styleUrls: ["./ticket-timeline.component.scss"],
})
export class TicketTimelineComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	ticketID = this.route.snapshot.paramMap.get("ticket_id");
	localeAll = locale;
	view = "month";
	myTheme = "ios";
	resource_type = "gantt";
	calendarTheme = "ios";
	hijri = hijriCalendar;
	ticketUsers = [];
	statusResources = [
		{
			id: 1,
			name: this.lang.transform("lang_new"),
			color: "#e20000",
		},
		{
			id: 2,
			name: this.lang.transform("lang_in_progress"),
			color: "#60e81a",
		},
		{
			id: 3,
			name: this.lang.transform("lang_confirm"),
			color: "#3ba7ff",
		},
		{
			id: 4,
			name: this.lang.transform("lang_done"),
			color: "#e25dd2",
		},
	];
	membersResources = [];
	ganttResources = [];
	status_events = [];
	member_events = [];
	gantt_events = [];
	allEvents = [];
	myEvents: MbscCalendarEvent[] = [];
	myResources = [...this.statusResources];
	filter_user = [];
	currentView = "timeline";
	ticketData = {
		ticket_no: "",
		ticket_id: "",
		ticket_title: "",
		ticket_description: "",
		ticket_type: "",
		ticket_signature: "",
		ticket_periority: "",
		note: "",
		need_reply: "",
		start_date: "",
		reply_date: "",
		ticket_time_work: "",
		end_date: "",
		end_date_nearly: "",
		is_delete: "0",
		is_ticket_admin: false,
		canAddTask: false,
	};
	status_done_list = [];
	task_status_done = null;
	user_popover = {
		user_id: "",
		first_name: "",
		last_name: "",
	};
	ticketUsersList = [];
	@ViewChild("popup", { static: false }) tooltip!: MbscPopup;
	anchor: HTMLElement | undefined;
	currentEvent: any;
	popupOptions: MbscPopupOptions = {
		display: "anchored",
		touchUi: false,
		showOverlay: false,
		contentPadding: false,
		closeOnOverlayClick: true,
		width: 350,
	};
	isTimeline = true;
	connections = [];
	//search vars
	currentDate: any = new Date();
	mySelectedEvent: MbscCalendarEvent[] = [];
	@ViewChild("searchInput", { static: false })
	searchInputElm!: any;
	searchInput: HTMLElement | undefined;
	@ViewChild("popupSearch", { static: false })
	popupSearch!: MbscPopup;
	listEvents: MbscCalendarEvent[] = [];
	timer: any;
	listView: MbscEventcalendarView = {
		agenda: {
			type: "year",
			size: 5,
		},
	};
	constructor(
		private fb: FormBuilder,
		private ds: DataService,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private lang: LanguagePipe,
		private spinner: PreloaderService,
		private router: Router,
		private sweetAlert: SweetAlertService,
		private notify: Notifications
	) {}

	ngOnInit(): void {
		moment.locale("en");
		this.getCalenderData();
		this.getTicketUsers();
		this.getTicketData();
		this.ticketUsersHeader();
	}
	getCalenderData() {
		let formData = new FormData();
		formData.append("ticket_no", "");
		formData.append("ticket_type", "");
		formData.append("ticket_id", this.ticketID || "");
		formData.append("start_date_task", "");
		formData.append("end_date_task", "");
		formData.append("start_date_ticket", "");
		formData.append("end_date_ticket", "");
		formData.append("task_no", "");
		formData.append("important_task", "");
		this.ds.post("tasks/get_tasks_calendar", formData).subscribe((res) => {
			if (res?.status) {
				this.allEvents = res?.data;
				this.connections = res?.connections;
				this.status_events = this.allEvents.map((el) => {
					return {
						start: el?.start_date_task,
						end: el?.end_nearly_task,
						title: el?.title,
						resource: +el?.task_status,
						task_id: el?.task_id,
						task_no: el?.task_no,
						ticket_title: el?.ticket_titel,
						start_date_ticket: el?.start_date_ticket,
						start_date_task: el?.start_date_task,
						end_date_task: el?.end_date_task || el?.end_nearly_task,
						end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
						task_progress: el?.task_progress,
						color:
							el?.task_status == "1"
								? "#e20000"
								: el?.task_status == "2"
								? "#60e81a"
								: el?.task_status == "3"
								? "#3ba7ff"
								: "#e25dd2",
						bgColor:
							el?.task_status == "1"
								? "#e2000087"
								: el?.task_status == "2"
								? "#3ca7069e"
								: el?.task_status == "3"
								? "#1b88e18c"
								: "#e25dd2b8",
					};
				});
				this.member_events = this.allEvents.map((el) => {
					return {
						start: el?.start_date_task,
						end: el?.end_nearly_task,
						title: el?.title,
						resource: el?.emps?.split("||"),
						task_id: el?.task_id,
						task_no: el?.task_no,
						ticket_title: el?.ticket_titel,
						start_date_ticket: el?.start_date_ticket,
						start_date_task: el?.start_date_task,
						end_date_task: el?.end_date_task || el?.end_nearly_task,
						end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
						task_progress: el?.task_progress,
						color:
							el?.task_status == "1"
								? "#e20000"
								: el?.task_status == "2"
								? "#60e81a"
								: el?.task_status == "3"
								? "#3ba7ff"
								: "#e25dd2",
						bgColor:
							el?.task_status == "1"
								? "#e2000087"
								: el?.task_status == "2"
								? "#3ca7069e"
								: el?.task_status == "3"
								? "#1b88e18c"
								: "#e25dd2b8",
					};
				});
				this.gantt_events = this.allEvents.map((el) => {
					return {
						start: el?.start_date_task,
						end: el?.end_nearly_task,
						title: `${el?.title} ${this.lang.transform("lang_progress")}: (${
							el?.task_progress
						})%`,
						resource: el?.task_id,
						task_id: el?.task_id,
						is_can_view: el?.is_can_view,
						ticket_id: el?.ticket_id,
						task_no: el?.task_no,
						ticket_title: el?.ticket_titel,
						start_date_ticket: el?.start_date_ticket,
						start_date_task: el?.start_date_task,
						end_date_task: el?.end_date_task || el?.end_nearly_task,
						task_progress: el?.task_progress,
						end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
						color:
							el?.task_status == "1"
								? "#e20000"
								: el?.task_status == "2"
								? "#60e81a"
								: el?.task_status == "3"
								? "#3ba7ff"
								: "#e25dd2",
						bgColor:
							el?.task_status == "1"
								? "#e2000087"
								: el?.task_status == "2"
								? "#3ca7069e"
								: el?.task_status == "3"
								? "#1b88e18c"
								: "#e25dd2b8",
					};
				});
				this.ganttResources = this.customizeTaskData(this.allEvents).map(
					(el, index) => {
						return {
							id: el?.id,
							name: el?.name,
							collapsed: index == 0 ? false : true,
							children: el?.tasks?.map((task) => {
								return {
									id: task?.task_id,
									name: task?.title,
									start: task?.start_date_task,
									end: task?.end_nearly_task,
									task_id: task?.task_id,
									is_can_view: task?.is_can_view,
									ticket_id: task?.ticket_id,
									task_no: task?.task_no,
									ticket_title: task?.ticket_titel,
									start_date_ticket: task?.start_date_ticket,
									start_date_task: task?.start_date_task,
									end_date_task: task?.end_date_task || task?.end_nearly_task,
									task_progress: task?.task_progress,
									end_date_ticket:
										task?.end_date_ticket || task?.end_date_nearly_ticket,
									color:
										task?.task_status == "1"
											? "#e20000"
											: task?.task_status == "2"
											? "#60e81a"
											: task?.task_status == "3"
											? "#3ba7ff"
											: "#e25dd2",
									bgColor:
										task?.task_status == "1"
											? "#e2000087"
											: task?.task_status == "2"
											? "#3ca7069e"
											: task?.task_status == "3"
											? "#1b88e18c"
											: "#e25dd2b8",
								};
							}),
						};
					}
				);
				if (this.resource_type == "status") {
					this.myEvents = [];
					this.myEvents = [...this.status_events];
				} else if (this.resource_type == "members") {
					this.myEvents = [];
					this.myEvents = [...this.member_events];
				} else if (this.resource_type == "gantt") {
					this.myEvents = [];
					this.myEvents = [...this.gantt_events];
					this.myResources = [];
					this.myResources = [...this.ganttResources];
				}
			} else {
				this.member_events = [];
				this.status_events = [];
				this.gantt_events = [];
				this.myEvents = [];
				this.connections = [];
				this.sweetAlert.errorToast(res?.error, 2000);
			}
		});
	}
	getTicketUsers() {
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		this.ds.post("tasks/get_emp_by_task", formData).subscribe((res) => {
			if (res?.status) {
				this.ticketUsers = res?.data;
				this.membersResources = res?.data?.map((el) => {
					return {
						id: el?.emp_id,
						name: el?.emp_name,
						color: "#e25dd2",
					};
				});
			}
		});
	}

	changeView(): void {
		setTimeout(() => {
			if (this.view == "day" && this.currentView == "timeline") {
				this.calCalendarView = {
					timeline: {
						type: "day",
						eventList: true,
						startDay: 1,
						endDay: 5,
					},
				};
			} else if (this.view == "week" && this.currentView == "timeline") {
				this.calCalendarView = {
					timeline: {
						type: "week",
						eventList: true,
					},
				};
			} else if (this.view == "month" && this.currentView == "timeline") {
				this.calCalendarView = {
					timeline: {
						type: "month",
						eventList: true,
					},
				};
			} else if (this.view == "day" && this.currentView == "calendar") {
				this.calCalendarView = {
					schedule: {
						type: "day",
					},
				};
			} else if (this.view == "week" && this.currentView == "calendar") {
				this.calCalendarView = {
					calendar: {
						type: "week",
					},
				};
			} else if (this.view == "month" && this.currentView == "calendar") {
				this.calCalendarView = {
					calendar: {
						type: "month",
					},
				};
			} else if (this.view == "year") {
				this.calCalendarView = {
					calendar: {
						type: "year",
					},
				};
			}
		});
	}

	changeType(): void {
		if (this.resource_type == "status") {
			this.myResources = [];
			this.myResources = [...this.statusResources];
			this.myEvents = [];
			this.myEvents = [...this.status_events];
		} else if (this.resource_type == "members") {
			this.myResources = [];
			this.myResources = [...this.membersResources];
			this.myEvents = [];
			this.myEvents = [...this.member_events];
		} else if (this.resource_type == "gantt") {
			this.myResources = [];
			this.myResources = [...this.ganttResources];
			this.myEvents = [];
			this.myEvents = [...this.gantt_events];
		}
	}

	changeUsers() {
		if (this.filter_user.length) {
			const memberSelectedResources = this.membersResources.filter((el) =>
				this.filter_user?.includes(el?.id)
			);
			const memberSelectedEvents = this.allEvents.filter((el) => {
				let returnVal = false;
				for (let item of el?.emps?.split("||")) {
					if (this.filter_user?.includes(item)) {
						returnVal = true;
						break;
					} else {
						returnVal = false;
					}
				}
				return returnVal;
			});
			this.member_events = memberSelectedEvents.map((el) => {
				return {
					start: el?.start_date_task,
					end: el?.end_nearly_task,
					title: el?.title,
					resource: el?.emps?.split("||"),
					task_id: el?.task_id,
					task_no: el?.task_no,
					ticket_title: el?.ticket_titel,
					start_date_ticket: el?.start_date_ticket,
					start_date_task: el?.start_date_task,
					end_date_task: el?.end_date_task || el?.end_nearly_task,
					end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
					task_progress: el?.task_progress,
					color:
						el?.task_status == "1"
							? "#e20000"
							: el?.task_status == "2"
							? "#60e81a"
							: el?.task_status == "3"
							? "#3ba7ff"
							: "#e25dd2",
					bgColor:
						el?.task_status == "1"
							? "#e2000087"
							: el?.task_status == "2"
							? "#3ca7069e"
							: el?.task_status == "3"
							? "#1b88e18c"
							: "#e25dd2b8",
				};
			});
			this.myResources = [...memberSelectedResources];
			this.myEvents = [...this.member_events];
		} else {
			this.myResources = [...this.membersResources];
			this.member_events = this.allEvents.map((el) => {
				return {
					start: el?.start_date_task,
					end: el?.end_nearly_task,
					title: el?.title,
					resource: el?.emps?.split("||"),
					task_id: el?.task_id,
					task_no: el?.task_no,
					ticket_title: el?.ticket_titel,
					start_date_ticket: el?.start_date_ticket,
					start_date_task: el?.start_date_task,
					end_date_task: el?.end_date_task || el?.end_nearly_task,
					end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
					task_progress: el?.task_progress,
					color:
						el?.task_status == "1"
							? "#e20000"
							: el?.task_status == "2"
							? "#60e81a"
							: el?.task_status == "3"
							? "#3ba7ff"
							: "#e25dd2",
					bgColor:
						el?.task_status == "1"
							? "#e2000087"
							: el?.task_status == "2"
							? "#3ca7069e"
							: el?.task_status == "3"
							? "#1b88e18c"
							: "#e25dd2b8",
				};
			});
			this.myEvents = [...this.member_events];
		}
	}

	//calendar
	calendarOptions: MbscEventcalendarOptions = {
		dragToCreate: false,
		dragToMove: true,
		dragToResize: true,
		dragTimeStep: 60,
		height: "700px",
		view: {
			timeline: { type: "month", eventList: true },
		},
		onEventClick: (event, inst) => {
			const dialogRef = this.dialog.open(ViewTaskComponent, {
				data: {
					ticketID: this.ticketID,
					task: { task_id: event.event.task_id },
				},
				width: "970px",
				height: "800px",
				closeOnNavigation: true,
			});
		},
		onEventHoverIn: (args, inst) => {
			const event: any = args.event;
			this.currentEvent = {
				...event,
				start_date_task: moment(event?.start).format("YYYY-MM-DD"),
				end_date_task: moment(event?.end).format("YYYY-MM-DD"),
			};
			this.anchor = args.domEvent.target;
			this.tooltip.open();
		},
		onEventDragStart: (args) => {
			this.tooltip.close();
		},
		onEventUpdated: (args) => {
			setTimeout(() => {
				this.notify.confirm({
					title: "Are you sure you want to proceed?",
					message: "If you press yes, you will update the task dates.",
					okText: "Yes",
					cancelText: "No",
					callback: (res) => {
						if (res) {
							this.changeTaskDates(args?.event, args);
						} else {
							const index = this.myEvents.findIndex(
								(x) => x.id === args.event.id
							);
							const newEventList = [...this.myEvents];
							newEventList.splice(index, 1, args.oldEvent);
							this.myEvents = newEventList;
						}
					},
				});
			});
		},
	};

	calCalendarView: MbscEventcalendarView = {
		timeline: {
			type: "month",
			eventList: true,
		},
	};

	changeCalendarTimeline(event) {
		if (event?.checked) {
			this.currentView = "timeline";
			if (this.view == "day") {
				this.calCalendarView = {
					timeline: {
						type: "day",
						eventList: true,
					},
				};
			} else if (this.view == "week") {
				this.calCalendarView = {
					timeline: {
						type: "week",
						eventList: true,
					},
				};
			} else if (this.view == "month") {
				this.calCalendarView = {
					timeline: {
						type: "month",
						eventList: true,
					},
				};
			} else if (this.view == "year") {
				this.view = "month";
				this.calCalendarView = {
					timeline: {
						type: "month",
						eventList: true,
					},
				};
			}
		} else {
			this.currentView = "calendar";
			if (this.view == "day") {
				this.calCalendarView = {
					schedule: {
						type: "day",
					},
				};
			} else if (this.view == "week") {
				this.calCalendarView = {
					calendar: {
						type: "week",
					},
				};
			} else if (this.view == "month") {
				this.calCalendarView = {
					calendar: {
						type: "month",
					},
				};
			}
		}
	}

	//start get ticket data
	getTicketData() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this?.ticketID || "");
		this.ds.post("tasks/get_ticket_row", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.ticketData.ticket_no = res?.data?.ticket_no;
					this.ticketData.ticket_id = res?.data?.ticket_id;
					this.ticketData.ticket_title = res?.data?.ticket_titel;
					this.ticketData.ticket_type = res?.data?.ticket_type_name;
					this.ticketData.ticket_description = res?.data?.ticket_detalis;
					this.ticketData.ticket_periority = res?.data?.important_name;
					this.ticketData.ticket_signature = res?.data?.sig_name;
					this.ticketData.ticket_time_work = res?.data?.time_work;
					this.ticketData.note = res?.data?.notes;
					this.ticketData.start_date = res?.data?.start_date_format;
					this.ticketData.end_date = res?.data?.end_date_format;
					this.ticketData.end_date_nearly = res?.data?.end_date_nearly;
					this.ticketData.reply_date = res?.data?.date_reply;
					this.ticketData.need_reply = res?.data?.need_reply;
					this.ticketData.is_delete = res?.data?.is_delete;
					this.ticketData.is_ticket_admin = res?.data?.is_ticket_admin;
					this.ticketData.canAddTask = res?.data?.canAddTask;
					this.task_status_done = res?.data?.ticket_status;
				} else {
					this.router.navigate(["/tasks/alltasks"]);
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}
	//end get ticket data
	//change status done
	onChangeStatusDone(status) {
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		formData.append("status", status || "");
		this.ds.post("tasks/change_status_ticket", formData).subscribe(
			(res) => {
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						2000
					);
					this.task_status_done = status;
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(err) => {
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	//on show user popover
	onShowUser(user) {
		if (user) {
			this.user_popover.user_id = user?.emp_id;
			if (this.lang_key == "ar") {
				this.user_popover.first_name = user?.firstname_arabic || "-- ";
				this.user_popover.last_name = user?.lastname_arabic || "--";
			} else {
				this.user_popover.first_name = user?.firstname_english || "-- ";
				this.user_popover.last_name = user?.lastname_english || "--";
			}
		}
	}
	//start unfollow ticket
	unfollowTicket() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		this.ds.post("tasks/unfollow_ticket", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(this.lang.transform(res?.message), 1000);
				} else {
					this.sweetAlert.errorMessage(this.lang.transform(res?.error));
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}

	// open Users Modal
	openUsersModal() {
		const dialogRef = this.dialog.open(ViewUsersComponent, {
			data: {
				users: this.ticketUsersList,
				ticketID: this.ticketID,
				isAdmin: this.ticketData?.is_ticket_admin,
			},
			width: "800px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.ticketUsersHeader();
			}
		});
	}
	onShowTicketUsers() {
		const dialogRef = this.dialog.open(TicketTaskUsersComponent, {
			data: {
				users: this.ticketUsersList,
			},
			width: "700px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.ticketUsersHeader();
			}
		});
	}

	//get ticket users
	ticketUsersHeader() {
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		this.ds.post("tasks/emp_in_ticket", formData).subscribe((res) => {
			if (res?.status) {
				this.ticketUsersList = res?.data;
			} else {
				this.ticketUsersList = [];
			}
		});
	}
	showContracts() {
		const dialogRef = this.dialog.open(TicketContractsComponent, {
			width: "700px",
			data: {
				ticket_id: this.ticketID,
			},
		});
	}
	//show ticket details
	showTicketDetials() {
		const dialogRef = this.dialog.open(TicketDetailsComponent, {
			width: "800px",
			height: "400px",
			data: {
				ticket: this.ticketData,
			},
		});
	}
	//start convert ticket to template
	convertTicketToTemplate() {
		const dialogRef = this.dialog.open(AddTemplateComponent, {
			width: "500px",
			data: {
				ticket_id: this.ticketID,
			},
		});
	}

	mouseLeave(): void {
		this.tooltip.close();
	}

	//change task dates
	changeTaskDates(event, args?) {
		let start_data_formatted: any, end_data_formatted: any;
		if (event) {
			start_data_formatted = moment(event?.start).format("YYYY/MM/DD HH:mm:ss");
			end_data_formatted = moment(event?.end).format("YYYY/MM/DD HH:mm:ss");
		}
		if (start_data_formatted && end_data_formatted) {
			const difference_In_Time =
				event?.end?.getTime() - event?.start?.getTime();
			const difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
			let formData = new FormData();
			formData.append("task_id", event?.task_id || "");
			formData.append(
				"task_time",
				Math.ceil(difference_In_Days) + 1 + "" || ""
			);
			formData.append("start_date", start_data_formatted || "");
			formData.append("end_date", end_data_formatted || "");
			this.spinner.show();
			this.ds.post("tasks/update_start_end_task", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_success"),
							2000
						);
						this.notify.toast({
							message: "Event updated",
						});
					} else {
						this.sweetAlert.errorToast(res?.error, 4000);
						const index = this.myEvents.findIndex(
							(x) => x.id === args.event.id
						);
						const newEventList = [...this.myEvents];
						newEventList.splice(index, 1, args.oldEvent);
						this.myEvents = newEventList;
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						4000
					);
					const index = this.myEvents.findIndex((x) => x.id === args.event.id);
					const newEventList = [...this.myEvents];
					newEventList.splice(index, 1, args.oldEvent);
					this.myEvents = newEventList;
				}
			);
		}
	}

	customizeTaskData(data) {
		let o = {};
		let result = data.reduce((r, el) => {
			let e = el.ticket_id.slice(0, 2);
			if (!o[e]) {
				o[e] = {
					id: el.ticket_id,
					name: el.ticket_titel,
					tasks: [],
				};
				r.push(o[e]);
			}
			o[e].tasks.push(el);
			return r;
		}, []);
		return result;
	}

	initPopup(): void {
		setTimeout(() => {
			this.searchInput = this.searchInputElm.nativeElement;
		});
	}

	onSearch(ev: any): void {
		const text = ev.target.value;
		clearTimeout(this.timer);
		this.timer = null;
		if (!this.timer) {
			this.timer = setTimeout(() => {
				if (text.length > 0) {
					if (this.resource_type == "status") {
						const filteredEvents = this.status_events.filter((el) =>
							el?.title?.includes(text)
						);
						this.listEvents = filteredEvents;
					} else if (this.resource_type == "members") {
						const filteredEvents = this.member_events.filter((el) =>
							el?.title?.includes(text)
						);
						this.listEvents = filteredEvents;
					} else if (this.resource_type == "gantt") {
						const filteredEvents = this.gantt_events.filter((el) =>
							el?.title?.includes(text)
						);
						this.listEvents = filteredEvents;
					}
					this.popupSearch.open();
				} else {
					this.popupSearch.close();
				}
			}, 200);
		}
	}
	eventClick(args: any): void {
		this.currentDate = args.event.start;
		this.mySelectedEvent = [args.event];
		this.popupSearch.close();
	}
}
