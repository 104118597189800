import { Component, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Formc1AddComponent } from "../transactions/FORM_C1/formc1-add/formc1-add.component";
import { Formc2AddComponent } from "../transactions/FORM_C2/formc2-add/formc2-add.component";
import { ApplicationsComponent } from "./applications/applications.component";
import { JopOfferCreateComponent } from "./applications/jop-offer-create/jop-offer-create.component";
import { ApplicationviewComponent } from "./applicationview/applicationview.component";
import { CreateComponent } from "./create/create.component";
import { EeditComponent } from "./eedit/eedit.component";
import { AllEmployeesComponent } from "./employees/all-employees/AllEmployees.component";
import { AddGroupComponent } from "./employees/all-employees/attendance/add-group/add-group.component";
import { AttendanceComponent } from "./employees/all-employees/attendance/attendance.component";
import { AddShifComponent } from "./employees/all-employees/attendance/shifts/add-shift/add-shift.component";
import { AllShiftsComponent } from "./employees/all-employees/attendance/shifts/all-shifts/all-shifts.component";
import { EmployeesPageComponent } from "./employees/all-employees/employees-page/employees-page.component";
import { EviewComponent } from "./eview/eview.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { ViewVactionComponent } from "./panels/vaction/view-vaction/view-vaction.component";
import { SettingsComponent } from "./settings/settings.component";
import { CreateLOANComponent } from "../transactions/FORM_FD/LOAN/createLOAN/createLOAN.component";
import { DevicesComponent } from "./employees/all-employees/attendance/devices/devices/devices.component";

const routes: Routes = [
  {
    path: "settings",
    component: SettingsComponent,
    data: { title: "Settings" },
  },
  {
    path: "attendances",
    children: [
      {
        path: "",
        component: AttendanceComponent,
        data: {
          title: "All attendance",
        },
      },
      {
        path: "add-group",
        component: AddGroupComponent,
        data: {
          title: "add attendance",
        },
      },
      {
        path: "add-group",
        component: AddGroupComponent,
        data: {
          title: "add attendance",
        },
      },
      {
        path: "update-group/:group_id",
        component: AddGroupComponent,
        data: {
          title: "add attendance",
        },
      },
      {
        path: "all-shifts",
        component: AllShiftsComponent,
        data: {
          title: "all shifts",
        },
      },
      {
        path: "add-shift/:shift_id",
        component: AddShifComponent,
        data: {
          title: "add shift",
        },
      },
      {
        path: "devices",
        component: DevicesComponent,
        data: {
          title: "devices",
        },
      },
    ],
  },
  /* {
    path: 'attendance',
    component: AttendanceComponent,

    data: { title: 'attendance' }
  }, */
  {
    path: "create",
    component: CreateComponent,
    data: { title: "Add New Employee" },
  },
  {
    path: "employees",

    children: [
      {
        path: "",
        component: EmployeesPageComponent,
        data: {
          title: "All attendance",
        },
      },
      {
        path: "showAll",
        component: AllEmployeesComponent,
        data: {
          title: "add attendance",
        },
      },
    ],
    data: { title: "All Employees" },
  },
  {
    path: "view/:id/:key/:bid",
    children: [
      {
        path: "",
        component: EviewComponent,
        data: {
          title: "All attendance",
        },
      },
      {
        path: "form/FORM_C2/cr/0",
        component: Formc2AddComponent,
        data: {
          title: "All attendance",
        },
      },

      {
        path: "form/FORM_C1/cr/0",
        component: Formc1AddComponent,
        data: {
          title: "All attendance",
        },
      },
    ],

    data: { title: "View Employee Details" },
  },
  {
    path: "edit/:id/:key/:bid",
    component: EeditComponent,
    data: { title: "Edit Employee Details" },
  },
  {
    path: "applications",
    component: ApplicationsComponent,
    data: { title: "All Applications" },
  },
  {
    path: "applications/jop-offer/:id",
    component: JopOfferCreateComponent,
    data: { title: "All Applications" },
  },
  {
    path: "applicationview/:id/:key",
    component: ApplicationviewComponent,
    data: { title: "Application Details" },
  },
  {
    path: "vaction/view/:id",

    component: ViewVactionComponent,
  },

  {
    path: "notifications",
    component: NotificationsComponent,
    data: { title: "All Notifications" },
  },
  {
    path: "payrole",
    loadChildren: () =>
      import("./payrole/payrole.module").then((m) => m.PayroleModule),
    data: { title: "Pay Role", titleI18n: "lang_pay_role" },
  },
  {
    path: "loan/create",
    component: CreateLOANComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HumanresourcesRoutingModule {}
