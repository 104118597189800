<div mat-dialog-title class="d-flex justify-content-between">
	<h4>Contract Attachments</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="attachments_form">
		<div class="p-2">
			<div class="row mb-2">
				<div class="col-12 TableParts">
					<p class="mb-10">
						{{ "lang_attachments" | language }}
						<span class="red" *ngIf="attachments_form?.controls?.length"
							>*</span
						>
					</p>
					<div
						class="table-responsive customResponsive tablefields quotationitemtable"
					>
						<table
							border="1"
							class="table CustomTable"
							width="100%"
							formArrayName="attachments"
						>
							<thead>
								<tr>
									<td width="2%" style="padding: 5px; word-break: break-all">
										#
									</td>
									<td width="2%" style="padding: 5px; word-break: break-all">
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addAttachment()"
											>add_box</mat-icon
										>
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_attach_title" | language }}
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_file" | language }}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let attachment of attachments_formgroup?.controls;
										let i = index
									"
									[formGroupName]="i"
								>
									<td
										class="verticalalignmid"
										style="padding: 5px; word-break: break-all"
									>
										{{ i + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											*ngIf="
												!attachments_array.controls[i].get('delete_icon').value
											"
											(click)="removeAttachment(i)"
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											>delete_forever</mat-icon
										>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											type="text"
											formControlName="attach_title"
											placeholder="{{ 'lang_attach_title' | language }}"
											autocomplete="off"
											class="form-control almnabrformcontrol"
										/>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											name="file"
											type="file"
											required
											onclick="this.value = null"
											(change)="
												ds.uploadSingleFile(
													$event,
													attachments_array.controls[i].get('file'),
													'file' + i,
													filename
												)
											"
											class="nghide albutton width100"
										/>
										<label id="file{{ i }}" class="albutton">{{
											filename
										}}</label>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-0 col-sm-9"></div>
	<div class="col-12 col-sm-3">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="submit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
