import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguagePipe } from '@shared/pipes/language.pipe';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
})
export class ConfirmationDialog {
  message: string = this.lang.transform('lang_select_anyone');
  title:string = this.lang.transform('lang_confirmation');
  constructor(
    private lang: LanguagePipe,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationDialog>) {
    this.title = data.title || this.title;
    this.message = data.message || this.message;
  }
  confirm(): void {
    this.dialogRef.close(true);
  }
  close(): void {
    this.dialogRef.close();
  }
}