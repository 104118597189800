import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-ticket-attachments',
  templateUrl: './ticket-attachments.component.html',
  styleUrls: ['./ticket-attachments.component.scss']
})
export class TicketAttachmentsComponent implements OnInit {
  ticket_attachments  = [];
  ticketID;
  page = 1;
  size = 10;
  constructor(public dialogRef: MatDialogRef<TicketAttachmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private spinner: PreloaderService, private ds: DataService, 
    private sweetAlert: SweetAlertService, private lang: LanguagePipe) { 
      this.ticketID = data?.ticketID;
      this.getTicketAttachments();
    }

  ngOnInit(): void {
    
  }

  getTicketAttachments() {
    this.spinner.show();
    let formData = new FormData();
    formData.append('ticket_id', this.ticketID || '');
    this.ds.post('tasks/get_files_in_ticket', formData).subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.ticket_attachments = res?.files;
      } else {
        this.dialogRef.close();
        this.sweetAlert.errorMessage(res?.error);
        this.ticket_attachments = [];
      }
    }, err => {
      this.spinner.hide();
    })
  }

  downloadFile(file_url, title?) {
    this.spinner.show();
    this.ds.get(file_url).subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.ds.downloadFile(res, title);
      }
    }, err=> {
      this.spinner.hide();
    })
  }

}
