import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-wafi-settings",
	templateUrl: "./wafi-settings.component.html",
	styleUrls: ["./wafi-settings.component.scss"],
})
export class WafiSettingsComponent implements OnInit {
	@Input() projects_work_area_id;
	@Input() projects_profile_id;
	@Input() projects_supervision_id;
	selected_tab = "manpower";
	constructor() {}

	ngOnInit(): void {}

	selectedListTabValue(e) {
		if (e?.tab?.textLabel == "manpower") {
			this.selected_tab = "manpower";
		}
	}
}
