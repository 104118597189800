import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import * as moment from "moment";
// import * as Editor from "assets/libraries/ckeditor/build/ckeditor";
import { almnabrAttachment } from "@shared/validators/almnabr-attachment";
@Component({
  selector: "app-checklist-details",
  templateUrl: "./checklist-details.component.html",
  styleUrls: ["./checklist-details.component.scss"],
})
export class ChecklistDetailsComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key") || "en";
  // public myEditor = Editor;
  config = {
    language: this.lang_key,
    toolbar: [
      {
        name: "document",
        items: [
          "Source",
          "-",
          "Save",
          "NewPage",
          "Preview",
          "Print",
          "-",
          "Templates",
        ],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          "Copy",
          "Paste",
          "PasteText",
          "PasteFromWord",
          "-",
          "Undo",
          "Redo",
        ],
      },
      {
        name: "editing",
        items: ["Find", "Replace", "-", "SelectAll"],
      },
      {
        name: "forms",
        items: [
          "Form",
          "Checkbox",
          "Radio",
          "TextField",
          "Textarea",
          "Select",
          "Button",
          "HiddenField",
        ],
      },
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "Subscript",
          "Superscript",
          "-",
          "CopyFormatting",
          "RemoveFormat",
        ],
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      {
        name: "insert",
        items: [
          "Image",
          "Flash",
          "Table",
          "wrapCode",
          "TableCellProperties",
          "TableColumnResize",
          "TableProperties",
          "TableToolbar",
          "HorizontalRule",
          "Smiley",
          "SpecialChar",
          "PageBreak",
          "Iframe",
        ],
      },
      { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
      { name: "colors", items: ["TextColor", "BGColor"] },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "CreateDiv",
          "-",
          "JustifyLeft",
          "JustifyCenter",
          "JustifyRight",
          "JustifyBlock",
          "-",
          "BidiLtr",
          "BidiRtl",
          "Language",
        ],
      },
      { name: "tools", items: ["Maximize", "ShowBlocks"] },
    ],
    extraPlugins:
      "justify,colorbutton,font,codesnippet,autocomplete,find,selectall,iframe,div,enterkey,notification,bidi",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    removeButtons: "Image",
  };
  checklist_item: any;
  search_point_users = [];
  task_dates;
  pointForm: FormGroup;
  other_attachments: FormArray;
  filename: string = this.lang.transform("lang_select_files");
  parent_check_id: any;
  edit_mode = false;
  group_list = [];
  group_type_list = [
    {
      label: this.lang.transform("lang_system_groups"),
      value: "1",
    },
    {
      label: this.lang.transform("lang_user_groups"),
      value: "2",
    },
  ];
  constructor(
    public dialogRef: MatDialogRef<ChecklistDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: PreloaderService,
    public ds: DataService,
    private fb: FormBuilder,
    private lang: LanguagePipe,
    private sweetAlert: SweetAlertService
  ) {
    this.buildForm();
    this.checklist_item = data.checklist_item;
    this.parent_check_id = data.parent_check_id;
    if (this.checklist_item) {
      this.edit_mode = true;
      this.setEditData(this.checklist_item);
    } else {
      this.edit_mode = false;
      this.getGroups();
    }
  }

  ngOnInit(): void {}
  buildForm() {
    this.pointForm = this.fb.group({
      title: ["", Validators.required],
      prg_done: [null],
      hour_no: [null],
      start_date: [null],
      end_date: [null],
      more_details: [""],
      users: [null],
      group_id: [null],
      group_type_id: ["1"],
      attachments: this.fb.array([]),
    });
    this.other_attachments = this.pointForm.get("attachments") as FormArray;
  }
  get filesFormGroup() {
    return this.pointForm.get("attachments") as FormArray;
  }

  public files(value?): FormGroup {
    return this.fb.group({
      delete_icon: new FormControl(value?.attach_title || ""),
      view: new FormControl(value?.file || null),
      attach_title: new FormControl(
        {
          value: value ? value?.attach_title : "",
          disabled: value?.attach_title ? true : false,
        },
        [Validators.required]
      ),
      file: new FormControl(value?.file || "", [
        Validators.required,
        almnabrAttachment(),
      ]),
    });
  }
  public addfiles(value?) {
    if (this.other_attachments.length < 10) {
      this.other_attachments.push(this.files(value));
      this.other_attachments.setValidators(Validators.required);
      this.other_attachments.updateValueAndValidity();
    }
  }
  public removefiles(index) {
    if (this.other_attachments.length == 1) {
      this.other_attachments.removeAt(index);
      this.other_attachments.clearValidators();
      this.other_attachments.updateValueAndValidity();
    } else {
      this.other_attachments.removeAt(index);
    }
  }
  //search users
  public search_users(key) {
    this.search_point_users = [];
    if (key && key.term) {
      let param = new FormData();
      param.append("search", key.term);
      param.append("lang_key", this.lang_key);
      param.append("user_type_id", "1");
      this.ds
        .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
        .subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            this.search_point_users = res.list;
          }
        });
    }
  }

  setEditData(data: any) {
    this.search_point_users = [];
    this.pointForm.get("title").setValue(data?.notes);
    this.pointForm.get("prg_done").setValue(data?.prg_done);
    this.pointForm.get("hour_no").setValue(data?.hours_by_manual);
    this.pointForm.get("more_details").setValue(data?.more_details);
    this.pointForm.get("more_details").setValue(data?.more_details);
    this.pointForm.get("group_type_id").setValue(`${data?.group_type_id}`);
    this.pointForm.get("group_id").setValue(data?.group_id);
    this.task_dates = [];
    if (data?.start_date) {
      this.task_dates[0] = new Date(data?.start_date);
    }
    if (data?.end_date) {
      this.task_dates[1] = new Date(data?.end_date);
    }
    this.getPointUsers();
    this.getGroups();
  }

  getPointUsers() {
    this.spinner.show();
    let formData = new FormData();
    formData.append("sub_point_id", this.checklist_item?.check_id || "");
    this.ds.post("tasks/get_emp_in_sub_points", formData).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          let usersList = [];
          this.pointForm.get("users").setValue(
            res?.data?.map((item) => {
              usersList?.push({
                label:
                  item?.firstname_english +
                  " " +
                  item?.lastname_english +
                  " " +
                  `(${item?.emp_id})`,
                value: item?.emp_id,
              });
              return `${item?.emp_id}`;
            })
          );
          this.search_point_users = usersList;
        } else {
          this.search_point_users = [];
          this.sweetAlert.errorToast(res.error, 2000);
        }
      },
      (err) => {
        this.search_point_users = [];
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          2000
        );
      }
    );
  }

  updatePoint() {
    let start_data_formatted, end_data_formatted;
    if (this.task_dates) {
      start_data_formatted = moment(this.task_dates[0]).format(
        "YYYY/MM/DD HH:mm:ss"
      );
      end_data_formatted = moment(this.task_dates[1]).format(
        "YYYY/MM/DD HH:mm:ss"
      );
      this.pointForm.get("start_date").setValue(start_data_formatted);
      this.pointForm.get("end_date").setValue(end_data_formatted);
    }
    this.spinner.show();
    let formData = new FormData();
    formData.append("point_id", this.checklist_item?.check_id || "");
    formData.append("title", this.pointForm.get("title").value || "");
    formData.append("prg_done", this.pointForm.get("prg_done").value || "");
    formData.append("hour_no", this.pointForm.get("hour_no").value || "");
    formData.append("start_date", this.pointForm.get("start_date").value || "");
    formData.append("end_date", this.pointForm.get("end_date").value || "");
    formData.append(
      "more_details",
      this.pointForm.get("more_details").value || ""
    );
    formData.append(
      "users",
      this.pointForm.get("users").value?.toString() || ""
    );
    formData.append("group_id", this.pointForm.get("group_id").value || "");
    this.other_attachments.controls.forEach((element, index) => {
      formData.append(
        "attachments[" + index + "][attach_title]",
        element.get("attach_title").value || ""
      );
      formData.append(
        "attachments[" + index + "][file]",
        element.get("file").value || ""
      );
    });
    this.ds.post("tasks/update_task_points_all", formData).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.dialogRef.close(true);
          this.sweetAlert.successToast(
            this.lang.transform("lang_success"),
            2000
          );
        } else {
          this.sweetAlert.errorToast(res.error, 2000);
        }
      },
      (err) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(err.error.error, 2000);
      }
    );
  }

  onAddChecklistItem() {
    let start_data_formatted, end_data_formatted;
    if (this.task_dates) {
      start_data_formatted = moment(this.task_dates[0]).format(
        "YYYY/MM/DD HH:mm:ss"
      );
      end_data_formatted = moment(this.task_dates[1]).format(
        "YYYY/MM/DD HH:mm:ss"
      );
      this.pointForm.get("start_date").setValue(start_data_formatted);
      this.pointForm.get("end_date").setValue(end_data_formatted);
    }
    if (this.pointForm.valid) {
      let formData = new FormData();
      formData.append("point_id", this.parent_check_id || "");
      formData.append(
        "points[0][title]",
        this.pointForm.get("title").value || ""
      );
      formData.append(
        "points[0][prg_done]",
        this.pointForm.get("prg_done").value || ""
      );
      formData.append(
        "points[0][hour_no]",
        this.pointForm.get("hour_no").value || ""
      );
      formData.append(
        "points[0][start_date]",
        this.pointForm.get("start_date").value || ""
      );
      formData.append(
        "points[0][end_date]",
        this.pointForm.get("end_date").value || ""
      );
      formData.append(
        "points[0][more_details]",
        this.pointForm.get("more_details").value || ""
      );
      formData.append(
        "points[0][group_id]",
        this.pointForm.get("group_id").value || ""
      );
      this.other_attachments.controls.forEach((element, index) => {
        formData.append(
          "attachments[" + index + "][attach_title]",
          element.get("attach_title").value || ""
        );
        formData.append(
          "attachments[" + index + "][file]",
          element.get("file").value || ""
        );
      });
      formData.append(
        "users",
        this.pointForm.get("users").value?.toString() || ""
      );
      this.spinner.show();
      this.ds.post("tasks/insert_task_points", formData).subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.sweetAlert.successToast(
              this.lang.transform(res?.message),
              2000
            );
            this.dialogRef.close(true);
          } else {
            this.sweetAlert.errorToast(this.lang.transform(res?.error), 2000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
    } else {
      this.sweetAlert.errorToast(
        this.lang.transform("lang_fill_required_fields"),
        2000
      );
    }
  }
  onSubmit() {
    if (this.edit_mode) {
      this.updatePoint();
    } else {
      this.onAddChecklistItem();
    }
  }

  getGroups() {
    this.spinner.show();
    let formData = new FormData();
    formData.append("type", this.pointForm.get("group_type_id").value || "");
    this.ds.post("tasks/get_group_by_type", formData).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.group_list = res?.data;
        }
      },
      (err) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          2000
        );
      }
    );
  }
  onChangeGroup() {
    if (this.pointForm.get("group_id").value) {
      this.spinner.show();
      let formData = new FormData();
      let url = "tasks/get_group";
      if (this.pointForm.get("group_type_id").value == "2") {
        url = "tasks/get_for_user_group";
      } else {
        url = "tasks/get_group";
      }
      formData.append("group_id", this.pointForm.get("group_id").value || "");
      this.ds.post(url, formData).subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.pointForm
              .get("users")
              .setValue(res?.data?.users?.map((el) => el?.user_id));
            this.search_point_users = res?.data?.users?.map((el) => {
              return {
                label:
                  this.lang_key == "ar"
                    ? el?.firstname_arabic + " " + el?.lastname_arabic
                    : el?.firstname_english + " " + el?.lastname_english,
                value: el?.user_id,
              };
            });
          } else {
            this.sweetAlert.errorToast(res?.error, 3000);
            this.pointForm.get("group_id").setValue(null);
          }
        },
        (err) => {
          this.spinner.hide();
          this.pointForm.get("group_id").setValue(null);
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
    }
  }
}
