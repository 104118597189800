<app-ep-header></app-ep-header>
<section class="ticket-list">
	<div class="ticket-list-header">
		<div class="tl-header-container">
			<h2>{{ "lang_ticket" | language }} {{ "lang_list" | language }}</h2>
		</div>
	</div>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="tl-content">
					<div class="row">
						<div class="col-12">
							<div class="main-status">
								<div class="ms-header">
									<h3
										class="ms-header-title"
										*ngIf="!ticket_list?.data_ticket?.length"
									>
										{{ "lang_ticket" | language }} {{ "lang_list" | language }}
									</h3>
									<h3
										class="ms-header-title"
										*ngIf="ticket_list?.data_ticket?.length"
									>
										{{ "lang_ticket" | language }}
										{{ "lang_list" | language }} ({{
											ticket_list?.data_ticket?.length
										}})
									</h3>
									<hr />
								</div>
								<div class="ms-content">
									<div class="row pt-2 mb-2">
										<div class="col-md-8 col-xs-0"></div>
										<!-- <div class="col-md-4 col-xs-12 mb-1">
                                            <button
                                                mat-button
                                                class="albutton"
                                                (click)="showTicketsFilters()"
                                            >
                                                {{ "lang_advanced_filter" | language }}
                                            </button>
                                        </div> -->
									</div>
									<div class="msc-list">
										<div
											class="row"
											*ngIf="!ticketLoading && ticket_list?.data_ticket?.length"
										>
											<div
												class="col-12 col-sm-6"
												*ngFor="
													let item of ticket_list?.data_ticket;
													let i = index
												"
											>
												<div
													class="mscl-item"
													routerLink="/external-person/tickets/{{
														item?.ticket_id
													}}"
												>
													<div class="taskNumTag">
														<span>{{ item?.ticket_no }}</span>
													</div>
													<div
														class="mscli-labels mscli-labels-show"
														style="padding: 0 50px"
													>
														<div
															class="mscli-labels-label"
															[attr.style]="
																item?.ticket_status_name == 'new'
																	? 'background-color:#61bd4f'
																	: 'background-color:#0079BF'
															"
														>
															<span style="color: #fff">{{
																item?.ticket_status_name
															}}</span>
														</div>
													</div>
													<div
														class="mscli-header"
														[ngClass]="{
															no_labels: !item?.ticket_titel
														}"
													>
														<h4 class="mscli-header-title">
															{{ item?.ticket_titel }}
														</h4>
													</div>
													<div class="mscli-content">
														<div class="task-badge" *ngIf="item?.barcode">
															<i class="pi pi-qrcode"></i>
															<span>{{ item?.barcode }}</span>
														</div>
														<div class="task-badge" *ngIf="item?.ref_model">
															<i
																class="pi pi-check-square"
																style="font-size: 14px"
															></i>
															<span>{{ item?.ref_model }}</span>
														</div>
														<div class="task-badge" *ngIf="item?.insert_date">
															<i class="pi pi-clock"></i>
															<span>{{ item?.insert_date }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row" *ngIf="ticketLoading">
											<div class="col-12 col-sm-6">
												<ngx-skeleton-loader
													count="5"
													[theme]="{ 'border-radius': '3px', height: '70px' }"
												></ngx-skeleton-loader>
											</div>
											<div class="col-12 col-sm-6">
												<ngx-skeleton-loader
													count="5"
													[theme]="{ 'border-radius': '3px', height: '70px' }"
												></ngx-skeleton-loader>
											</div>
										</div>
									</div>
									<no-data
										style="margin: auto"
										[Opened]="ticket_list == null && !ticketLoading"
									></no-data>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<app-footer></app-footer>
