import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-ep-checkpoint-details-view",
	templateUrl: "./ep-checkpoint-details-view.component.html",
	styleUrls: ["./ep-checkpoint-details-view.component.scss"],
})
export class EpCheckpointDetailsViewComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	@Input() point;
	@Output() hideView = new EventEmitter<boolean>();
	point_users = [];
	point_files = [];
	point_logs = [];
	constructor(
		private spinner: PreloaderService,
		private dialog: MatDialog,
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private localstorageService: LocalStorage
	) {}

	ngOnInit(): void {
		// this.getPointUsers();
		this.getPointFiles();
		// this.getPointLogs();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.getPointFiles();
	}

	// getPointUsers() {
	// 	this.spinner.show();
	// 	let formData = new FormData();
	// 	formData.append("sub_point_id", this.point?.check_id || "");
	// 	this.ds.post("tasks/get_emp_in_sub_points", formData).subscribe(
	// 		(res: any) => {
	// 			this.spinner.hide();
	// 			if (res.status) {
	// 				this.point_users = res?.data;
	// 			} else {
	// 				this.point_users = [];
	// 				this.sweetAlert.errorToast(res.error, 2000);
	// 			}
	// 		},
	// 		(err) => {
	// 			this.point_users = [];
	// 			this.sweetAlert.errorToast(
	// 				this.lang.transform("lang_internal_server_error"),
	// 				2000
	// 			);
	// 		}
	// 	);
	// }
	getPointFiles() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("sub_point_id", this.point?.check_id || "");
		formData.append(
			"token_extarnal",
			localStorage.getItem("eploggedin") == "1"
				? this.localstorageService.getItem("eptoken")
				: ""
		);
		this.ds.post("tasks/get_files_sub_points_extarnal", formData).subscribe(
			(res: any) => {
				this.spinner.hide();
				if (res.status) {
					this.point_files = res?.data;
				} else {
					this.point_files = [];
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(err) => {
				this.point_files = [];
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

	// getPointLogs() {
	// 	this.spinner.show();
	// 	let formData = new FormData();
	// 	formData.append("sub_point_id", this.point?.check_id || "");
	// 	this.ds.post("tasks/get_logs_in_sub_points", formData).subscribe(
	// 		(res: any) => {
	// 			this.spinner.hide();
	// 			if (res.status) {
	// 				this.point_logs = res?.data;
	// 			} else {
	// 				this.point_logs = [];
	// 				this.sweetAlert.errorToast(res.error, 2000);
	// 			}
	// 		},
	// 		(err) => {
	// 			this.point_logs = [];
	// 			this.sweetAlert.errorToast(
	// 				this.lang.transform("lang_internal_server_error"),
	// 				2000
	// 			);
	// 		}
	// 	);
	// }
	downloadFile(file_url, title?) {
		this.spinner.show();
		this.ds.get(file_url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.ds.downloadFile(res, title);
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
}
