import { Component, OnInit, ViewChild } from "@angular/core";

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
declare var $: any;
@Component({
  selector: "app-formc1-add",
  templateUrl: "./formc1-add.component.html",
  styleUrls: ["./formc1-add.component.scss"],
})
export class Formc1AddComponent implements OnInit {
  lang_key =
    localStorage.getItem("lang_key") == null
      ? "en"
      : localStorage.getItem("lang_key");
  public myEditor;
  config = {
    language: this.lang_key,
    toolbar: [
      {
        name: "document",
        items: [
          "Source",
          "-",
          "Save",
          "NewPage",
          "Preview",
          "Print",
          "-",
          "Templates",
        ],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          "Copy",
          "Paste",
          "PasteText",
          "PasteFromWord",
          "-",
          "Undo",
          "Redo",
        ],
      },
      {
        name: "editing",
        items: ["Find", "Replace", "-", "SelectAll"],
      },
      {
        name: "forms",
        items: [
          "Form",
          "Checkbox",
          "Radio",
          "TextField",
          "Textarea",
          "Select",
          "Button",
          "HiddenField",
        ],
      },
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "Subscript",
          "Superscript",
          "-",
          "CopyFormatting",
          "RemoveFormat",
        ],
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      {
        name: "insert",
        items: [
          "Image",
          "Flash",
          "Table",
          "wrapCode",
          "TableCellProperties",
          "TableColumnResize",
          "TableProperties",
          "TableToolbar",
          "HorizontalRule",
          "Smiley",
          "SpecialChar",
          "PageBreak",
          "Iframe",
        ],
      },
      { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
      { name: "colors", items: ["TextColor", "BGColor"] },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "CreateDiv",
          "-",
          "JustifyLeft",
          "JustifyCenter",
          "JustifyRight",
          "JustifyBlock",
          "-",
          "BidiLtr",
          "BidiRtl",
          "Language",
        ],
      },
      { name: "tools", items: ["Maximize", "ShowBlocks"] },
    ],
    extraPlugins:
      "justify,colorbutton,font,codesnippet,autocomplete,find,selectall,iframe,div,enterkey,notification,bidi",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    removeButtons: "Image",
  };
  singleselection = environment.singledropdown;
  multipleselection = environment.multipledropdown;
  error_message: string = "";
  error_status: boolean = false;
  stamps: any = [];
  form_key = "FORM_C1";
  filename = this.lang.transform("lang_select_files");
  isFile: boolean = false;
  form: FormGroup;
  markstatus: boolean = false;
  reviewstatus: boolean = false;
  attachstatus: boolean = false;
  stampstatus: boolean = false;
  attachments: FormArray;
  languages: any = [];
  branches: any = [];
  loading = false;
  document_types: any = [];
  users: any = [];
  submitted = false;
  filepermissionlevels = [];
  branchId: string = "";
  queryParams;
  constructor(
    public ds: DataService,
    private route: ActivatedRoute,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public fb: FormBuilder,
    public router: Router
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.route.params.subscribe((params) => {
      this.branchId = params.bid;
    });
    this.route.queryParams.subscribe((res) => {
      if (res?.branch_id) {
        this.branchId = res.branch_id;
      }
    });
    this.route.queryParams.subscribe((res) => {
      this.queryParams = res;
    });
    this.build_form();
    this.get_required_data("tc/tlanguages", "lang");
    this.get_required_data("tc/tbranches", "branch");
    this.get_required_data("tc/doctypes", "type");
    this.file_permission_levels();
    if (location.href.indexOf("human_resources") > -1) {
      this.get_view_details();
    }
  }
  onCkEditorReady(editor: any): boolean {
    const result = true;
    this.myEditor = window["CKEDITOR"];
    return result;
  }
  useLanguage(language: string) {
    let dir = language == "en" ? "ltr" : "rtl";
    localStorage.setItem("lang_key", language);
    if (localStorage.getItem("direction") != dir) {
      localStorage.setItem("direction", dir);
      this.spinner.show();
      this.ds.get("change_lang/" + language).subscribe(
        (response) => {
          this.spinner.hide();
          if (response.status) {
            window.location.reload();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  }

  changeEditorDir(event) {
    this.lang_key = event != undefined ? event.value : this.lang_key;
    this.config.language = this.lang_key;
    this.myEditor.config.defaultLanguage = this.lang_key;
    this.useLanguage(event?.value);
    // if (event.value == "ar") {
    // 	$(".ckeditor-container").addClass("cke_rtl");
    // 	$(
    // 		"ckeditor .ck.ck-reset.ck-editor, ckeditor .ck.ck-content.ck-editor__editable"
    // 	).addClass("text-right");
    // 	$(".cke_editable").addClass("text-right");
    // } else {
    // 	$(".ckeditor-container").removeClass("cke_rtl");
    // 	$(
    // 		"ckeditor .ck.ck-reset.ck-editor, ckeditor .ck.ck-content.ck-editor__editable"
    // 	).removeClass("text-right");
    // 	this.config.language = "en";
    // 	this.myEditor.config.defaultLanguage = "en";
    // }
  }

  public get_required_data(url, key) {
    this.languages = [];
    this.branches = [];
    this.document_types = [];
    this.ds.getActionByUrl([], url).subscribe((res) => {
      this.spinner.hide();
      if (res.status) {
        if (key === "lang") {
          this.languages = res.records;
        } else if (key === "branch") {
          this.branches = res.records;
        } else if (key === "type") {
          this.document_types = res.records;
        }
      }
    });
  }

  /* viewdata */

  id = this.route.snapshot.paramMap.get("id");
  branch_id = this.route.snapshot.paramMap.get("bid");
  get_view_details() {
    this.ds
      .getActionByUrl(
        [],
        "human_resources/get_myview_data/" + this.id + "/" + this.branch_id
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            let emp = data.data;
            this.form.patchValue({
              transaction_to:
                this.lang_key == "en"
                  ? emp?.firstname_english + " " + emp?.lastname_english
                  : emp?.firstname_arabic + " " + emp?.lastname_arabic,
            });
          } else {
            /* this.viewdata = ""; */
            this.ds.dialogf("", data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          /*  this.viewdata = ""; */
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }

  public search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append("search", key.term || "");
    param.append("lang_key", this.form.get("lang_key").value || this.lang_key);
    param.append("user_type_id", "1");
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
      .subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      });
  }
  public file_permission_levels() {
    this.filepermissionlevels = [];
    this.ds.getActionByUrl([], "lflevel").subscribe((res) => {
      if (res.status) {
        this.filepermissionlevels = res.records;
      }
    });
  }
  get filesFormGroup() {
    return this.form.get("attachments") as FormArray;
  }
  public build_form() {
    this.form = new FormGroup({
      lang_key: new FormControl(this.lang_key || null, [Validators.required]),
      subject: new FormControl("", [Validators.required]),
      attachmentstitle: new FormControl(""),
      transaction_from: new FormControl(this.branchId, [Validators.required]),
      transaction_to: new FormControl("", [Validators.required]),
      level_keys: new FormControl("", [Validators.required]),
      content: new FormControl("", [Validators.required]),
      needMark: new FormControl("0", [Validators.required]),
      needReview: new FormControl("0", [Validators.required]),
      needStamp: new FormControl("0", [Validators.required]),
      needAttach: new FormControl("0", [Validators.required]),
      stamp: new FormControl(null),
      "signature[A][title]": new FormControl(""),
      "signature[A][user_id]": new FormControl(null),
      "signature[B][title]": new FormControl(""),
      "signature[B][user_id]": new FormControl(null),
      "signature[C][title]": new FormControl(""),
      "signature[C][user_id]": new FormControl(null),
      marks: new FormControl(null),
      reviews: new FormControl(null),
      attachments: this.fb.array([]),
    });
    this.attachments = this.form.get("attachments") as FormArray;
  }
  public files(): FormGroup {
    return this.fb.group({
      attach_title: new FormControl(
        "",
        this.form.get("needAttach").value == "1" ? [Validators.required] : null
      ),
      file: new FormControl(
        "",
        this.form.get("needAttach").value == "1" ? [Validators.required] : null
      ),
      attach_with_the_document: new FormControl(0),
      print_official_paper: new FormControl(0),
    });
  }
  public addfiles() {
    if (this.attachments.length < 5) {
      this.attachments.push(this.files());
    }
  }
  public removefiles(index) {
    if (this.attachments.length > 1) {
      this.attachments.removeAt(index);
    }
  }
  public getfilesgroup(index): FormGroup {
    const formGroup = this.attachments.controls[index] as FormGroup;
    return formGroup;
  }
  public UploadFile(event, index) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      $("#uploadText" + index).text(fileid.name);
      this.attachments.controls[index]
        .get("file")
        .setValue(fileid, { emitEvent: true });
    } else {
      this.attachments.controls[index].get("file").setValue(null);
      $("#uploadText" + index).text(this.lang.transform("lang_select_files"));
    }
  }
  public uploadFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      this.isFile = true;
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.form.get("upload").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.form.get("upload").setValue(null);
      this.filename = this.lang.transform("lang_select_files");
    }
  }
  public removeFile() {
    this.isFile = false;
    this.form.get("upload").setValue(null);
    this.filename = this.lang.transform("lang_select_files");
  }
  public enable_field(field) {
    if (field == "mark") {
      this.form.get("needMark").setValue("1");
      this.form.get("marks").setValidators(Validators.required);
      this.form.get("marks").updateValueAndValidity();
      this.markstatus = true;
    } else if (field == "review") {
      this.form.get("needReview").setValue("1");
      this.form.get("reviews").setValidators(Validators.required);
      this.form.get("reviews").updateValueAndValidity();
      this.reviewstatus = true;
    } else if (field == "stamp") {
      this.form.get("needStamp").setValue("1");
      this.form.get("stamp").setValidators(Validators.required);
      this.form.get("stamp").updateValueAndValidity();
      this.stampstatus = true;
    } else if (field == "attachment") {
      this.attachments.clear();
      this.form.get("needAttach").setValue("1");
      this.addfiles();
      this.attachstatus = true;
    }
  }
  public disable_field(field) {
    if (field == "mark") {
      this.form.get("needMark").setValue("0");
      this.form.get("marks").setValue(null);
      this.form.get("marks").clearValidators();
      this.form.get("marks").updateValueAndValidity();
      this.markstatus = false;
    } else if (field == "review") {
      this.form.get("needReview").setValue("0");
      this.form.get("reviews").setValue(null);
      this.form.get("reviews").clearValidators();
      this.form.get("reviews").updateValueAndValidity();
      this.reviewstatus = false;
    } else if (field == "stamp") {
      this.form.get("needStamp").setValue("0");
      this.form.get("stamp").setValue(null);
      this.form.get("stamp").clearValidators();
      this.form.get("stamp").updateValueAndValidity();
      this.stampstatus = false;
    } else if (field == "attachment") {
      this.attachments.clear();
      this.form.get("needAttach").setValue("0");
      this.isFile = false;
      this.attachstatus = false;
    }
    this.form.updateValueAndValidity();
  }
  public submitForm() {
    this.submitted = true;
    if (this.validstatus()) {
      this.submitted = false;
      let param = new FormData();
      param.set("subject", this.form.get("subject").value || "");
      param.set(
        "attachmentstitle",
        this.form.get("attachmentstitle").value || ""
      );
      param.set("lang_key", this.form.get("lang_key").value || "");
      param.set(
        "transaction_from",
        this.form.get("transaction_from").value || ""
      );
      param.set("transaction_to", this.form.get("transaction_to").value || "");
      param.set("content", this.form.get("content").value);
      param.set("needMark", this.form.get("needMark").value);
      if (this.form.get("needMark").value == "1") {
        param.set("marks", this.form.get("marks").value.toString() || "");
      }
      param.set("needReview", this.form.get("needReview").value || "");
      if (this.form.get("needReview").value == "1") {
        param.set("reviews", this.form.get("reviews").value.toString() || "");
      }
      param.set(
        "signature[A][title]",
        this.form.get("signature[A][title]").value || ""
      );
      param.set(
        "signature[A][user_id]",
        this.form.get("signature[A][user_id]").value || ""
      );
      param.set(
        "signature[B][title]",
        this.form.get("signature[B][title]").value || ""
      );
      param.set(
        "signature[B][user_id]",
        this.form.get("signature[B][user_id]").value || ""
      );
      param.set(
        "signature[C][title]",
        this.form.get("signature[C][title]").value || ""
      );
      param.set(
        "signature[C][user_id]",
        this.form.get("signature[C][user_id]").value || ""
      );
      param.set(
        "needAttach",
        this.ds.select_array_string(this.form, "needAttach", "value")
      );
      if (this.form.get("needAttach").value == "1") {
        this.filesFormGroup.value.forEach((v, k) => {
          param.set("attachments[" + k + "][file]", v.file);
          param.set("attachments[" + k + "][attach_title]", v.attach_title);
          if (v.attach_with_the_document) {
            param.set("attachments[" + k + "][attach_with_the_document]", "1");
          } else {
            param.set("attachments[" + k + "][attach_with_the_document]", "0");
          }
          if (v.print_official_paper) {
            param.set("attachments[" + k + "][print_official_paper]", "1");
          } else {
            param.set("attachments[" + k + "][print_official_paper]", "0");
          }
        });
      }
      if (this.queryParams?.from == "projects") {
        param.set("extra[module_key]", this.queryParams?.from);
        param.set("extra[projects_work_area_id]", this.queryParams?.pwaid);
        param.set("extra[projects_profile_id]", this.queryParams?.ppid);
      } else if (this.queryParams?.from == "human_resources") {
        param.set("extra[module_key]", "human_resources");
        param.set("extra[employee_number]", this.queryParams?.id);
      } else if (
        this.queryParams?.from == "customers" &&
        this.queryParams?.id
      ) {
        param.set("extra[module_key]", "customers");
        param.set("extra[customer_id]", this.queryParams?.id);
      } else if (
        this.queryParams?.from == "contractors" &&
        this.queryParams?.id
      ) {
        param.set("extra[module_key]", "contractors");
        param.set("extra[contractor_id]", this.queryParams?.id);
      } else {
        param.set("extra[module_key]", "communication");
        param.set("extra[employee_number]", "");
      }
      param.set("extra[transaction_key]", "FORM_C1");
      param.set("extra[file_level_keys]", this.form.get("level_keys").value);
      this.spinner.show();
      this.ds
        .postActionByUrl(param, "form/" + this.form_key + "/cr/0")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.alert.success(this.lang.transform("lang_success"));
              this.form.reset();
              setTimeout(() => {
                this.router.navigate(["/transactions/" + res.url]);
              }, 1000);
            } else {
              this.alert.error(res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        );
    }
  }
  public value(key) {
    return this.form.get(key).value;
  }
  public validstatus() {
    if (
      this.form.get("signature[A][title]").value ||
      this.form.get("signature[A][user_id]").value != null
    ) {
      this.form.get("signature[A][title]").setValidators(Validators.required);
      this.form.get("signature[A][title]").updateValueAndValidity();
      this.form.get("signature[A][user_id]").setValidators(Validators.required);
      this.form.get("signature[A][user_id]").updateValueAndValidity();
    } else {
      this.form.get("signature[A][title]").setValue("");
      this.form.get("signature[A][title]").clearValidators();
      this.form.get("signature[A][title]").updateValueAndValidity();
      this.form.get("signature[A][user_id]").setValue(null);
      this.form.get("signature[A][user_id]").clearValidators();
      this.form.get("signature[A][user_id]").updateValueAndValidity();
    }
    if (
      this.form.get("signature[B][title]").value ||
      this.form.get("signature[B][user_id]").value != null
    ) {
      this.form.get("signature[B][title]").setValidators(Validators.required);
      this.form.get("signature[B][title]").updateValueAndValidity();
      this.form.get("signature[B][user_id]").setValidators(Validators.required);
      this.form.get("signature[B][user_id]").updateValueAndValidity();
    } else {
      this.form.get("signature[B][title]").setValue("");
      this.form.get("signature[B][title]").clearValidators();
      this.form.get("signature[B][title]").updateValueAndValidity();
      this.form.get("signature[B][user_id]").setValue(null);
      this.form.get("signature[B][user_id]").clearValidators();
      this.form.get("signature[B][user_id]").updateValueAndValidity();
    }
    if (
      this.form.get("signature[C][title]").value ||
      this.form.get("signature[C][user_id]").value != null
    ) {
      this.form.get("signature[C][title]").setValidators(Validators.required);
      this.form.get("signature[C][title]").updateValueAndValidity();
      this.form.get("signature[C][user_id]").setValidators(Validators.required);
      this.form.get("signature[C][user_id]").updateValueAndValidity();
    } else {
      this.form.get("signature[C][title]").setValue("");
      this.form.get("signature[C][title]").clearValidators();
      this.form.get("signature[C][title]").updateValueAndValidity();
      this.form.get("signature[C][user_id]").setValue(null);
      this.form.get("signature[C][user_id]").clearValidators();
      this.form.get("signature[C][user_id]").updateValueAndValidity();
    }
    if (
      !this.form.get("signature[A][title]").value &&
      !this.form.get("signature[B][title]").value &&
      !this.form.get("signature[C][title]").value
    ) {
      this.form.get("signature[A][title]").setValidators(Validators.required);
      this.form.get("signature[A][title]").updateValueAndValidity();
      this.form.get("signature[A][user_id]").setValidators(Validators.required);
      this.form.get("signature[A][user_id]").updateValueAndValidity();
    }
    if (this.form.valid) {
      return true;
    } else {
      this.ds.dialogf("", this.lang.transform("lang_need_emp_and_data"));
      return false;
    }
  }

  previewBeforeCreate() {
    this.spinner.show();
    let formData = new FormData();
    formData.append(
      "lang_key",
      this.form.get("lang_key").value || this.lang_key
    );
    formData.append("subject", this.form.get("subject").value || "");
    formData.append(
      "transaction_from",
      this.form.get("transaction_from").value || ""
    );
    formData.append(
      "transaction_to",
      this.form.get("transaction_to").value || ""
    );
    formData.append("content", this.form.get("content").value || "");
    formData.append(
      "attachmentstitle",
      this.form.get("attachmentstitle").value || ""
    );
    if (this.queryParams?.from == "projects") {
      formData.set("extra[module_key]", this.queryParams?.from);
      formData.set("extra[projects_work_area_id]", this.queryParams?.pwaid);
      formData.set("extra[projects_profile_id]", this.queryParams?.ppid);
    } else if (this.queryParams?.from == "human_resources") {
      formData.set("extra[module_key]", "human_resources");
      formData.set("extra[employee_number]", this.queryParams?.id);
    } else if (this.queryParams?.from == "customers" && this.queryParams?.id) {
      formData.set("extra[module_key]", "customers");
      formData.set("extra[customer_id]", this.queryParams?.id);
    } else if (
      this.queryParams?.from == "contractors" &&
      this.queryParams?.id
    ) {
      formData.set("extra[module_key]", "contractors");
      formData.set("extra[contractor_id]", this.queryParams?.id);
    } else {
      formData.set("extra[module_key]", "communication");
    }
    formData.append(
      "extra[file_level_keys]",
      this.form.get("level_keys").value || ""
    );
    formData.append("extra[transaction_key]", "FORM_C1");
    this.ds.post("form/FORM_C1/prc", formData).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          let url = "data:" + res["content-type"] + ";base64," + res.base64;
          let newWindow = window.open("", "_blank");
          setTimeout(() => {
            newWindow.document.write(
              '<html><head><title>Form Outgoing Preview</title></head><body height="100%" width="100%" style="margin:0;overflow:hidden"><iframe src="' +
                url +
                '" height="100%" width="100%"></iframe></body></html>'
            );
          });
        } else {
          this.alert.error(res?.error);
        }
      },
      (err) => {
        this.spinner.hide();
        this.alert.error(this.lang.transform("lang_internal_server"));
      }
    );
  }
}
