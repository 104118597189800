import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '@core/bootstrap/data.service';
import { PreloaderService } from '@core/bootstrap/preloader.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';

@Component({
  selector: 'app-general-numbers',
  templateUrl: './general-numbers.component.html',
  styleUrls: ['./general-numbers.component.scss']
})
export class GeneralNumbersComponent implements OnInit {
  gNumbers = [];
  work_sites_levels_form:FormGroup;
  supervision_data:any;
  lang_key:string;
  form_key:string;
  form_url:string;
  work_site:string;
  separate:string;
  template_id:any;
  work_levels = [];
  generalNumbersData = [];
  projects_work_area_id = 0;
  unitSearchLoading = false;
  unit_key = null;
  constructor(private fb: FormBuilder,public ds:DataService, public spinner : PreloaderService,
    public lang:LanguagePipe, public alert:AlertService,
    public dialogRef: MatDialogRef<GeneralNumbersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.supervision_data = data.supervision_data;
      this.form_key = data.form_key;
      this.form_url = data.form_url;
      this.work_site = data.work_site;
      this.lang_key = data.lang_key;
      this.separate = data.separate;
      this.generalNumbersData = data.generalNumbersData;
      this.projects_work_area_id = data.projects_work_area_id;
      this.template_id = data.template_id;
    }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.work_sites_levels_form = this.fb.group({
      work_site_general_nos: [null, [Validators.required]],
      work_levels          : [null, [Validators.required]]
    })
  }


  public search_required_datas(event?) {
    this.unit_key = event?.term;
    
  }

  getUnits() {
    this.gNumbers = [];
    if(this.unit_key) {
      this.unitSearchLoading = true;
      let param = new FormData();
      param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      param.append('platform_code_system', this.form_key || '');
      param.append('work_site', this.work_site || '');
      param.append('transaction_separation', this.separate);
      param.append('template_id', this.template_id);
      param.append('work_site_type', 'G');
      param.append('work_site_zones', '');
      param.append('work_site_blocks', '');
      param.append('work_site_clusters', '');
      param.append('search_key', this.unit_key || '');
      this.ds.getActionByUrl(this.ds.formData2string(param), 'form/'+this.form_url+'/search_units_by_phases_general_no').subscribe((res) => {
        this.unitSearchLoading = false;
        if(res.status) {
          if(typeof(res.records) == 'string') {
            this.gNumbers = res.records?.split(',');
          } else {
            this.gNumbers = res.records;
          }
          
        }
      }, error => {
        this.unitSearchLoading = false;
      })
    }
  }

  public get_work_levels() {
    this.alert.clear();
    this.unit_key = null;
    if(this.work_sites_levels_form.get('work_site_general_nos').valid) {
      this.work_levels  = [];
      this.work_sites_levels_form.get("work_levels").setValue(null);
      let param = new FormData();
      param.append('lang_key', this.lang_key);
      param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      param.append('platform_code_system', this.form_key || '');
      param.append('work_site', 'GN');
      param.append('transaction_separation', this.separate);
      param.append('template_id', this.template_id);
      param.append('work_site_nos', this.work_sites_levels_form.get('work_site_general_nos').value.toString());

      this.ds.getActionByUrl(this.ds.formData2string(param), 'form/'+this.form_url+'/get_work_levels_for_transaction').subscribe((res) => {
        this.spinner.hide();
        
        if(res.status) {
          this.work_levels = res.records;
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
      });
    } else {
      this.alert.error(this.lang.transform('lang_fill_required_fields'));
    }
  }

  checkValidWorkLevel() {
    if(this.separate == '0' && this.generalNumbersData.length > 0) {
      
      if(this.areArraysEqualSets(this.work_sites_levels_form.get('work_levels').value,
       this.generalNumbersData[0].work_levels.split(','))) {
        return true;
      } else {
        return false;
      } 
    } else {
      return true
    }
  }
  areArraysEqualSets(a1, a2) {
    const superSet = {};
    for (const i of a1) {
      const e = i + typeof i;
      superSet[e] = 1;
    }
  
    for (const i of a2) {
      const e = i + typeof i;
      if (!superSet[e]) {
        return false;
      }
      superSet[e] = 2;
    }
  
    for (let e in superSet) {
      if (superSet[e] === 1) {
        return false;
      }
    }
  
    return true;
  }
  onSubmit() {
    if(this.checkValidWorkLevel()) {
      let work_levels = [];
      for(let item of this.work_sites_levels_form?.value?.work_levels) {
        let  ob = this.work_levels.find(el => {
          return el.value == item;
        })
        if(ob) {
          work_levels.push(ob);
        }
      }
      work_levels = work_levels.map(el => {
        return el?.label;
      })

      this.dialogRef.close({form:this.work_sites_levels_form.value, work_levels:work_levels});
    } else {
      this.alert.error(this.lang.transform('lang_please_select_same_work_level'));
    }
    
  }

}
