<div mat-dialog-title>
  <div class="d-flex justify-content-between">
    <div class="actions">
      <i
        class="fa fa-download mnabricon"
        (click)="downloadPdf()"
        style="margin: 0 5px"
        title="download"
      ></i>
      <i
        class="fa fa-print mnabricon"
        (click)="printpdf()"
        style="margin: 0 10px"
        title="print"
      ></i>
      <i
        class="fa fa-repeat mnabricon"
        (click)="rotationPdf()"
        style="margin: 0 10px"
        title="rotate"
      ></i>
    </div>
    <span (click)="closeModal()"><i class="fa fa-times"></i></span>
  </div>
</div>
<mat-dialog-content
  class="mat-typography"
  style="background-color: rgb(0 0 0 / 16%); height: 65vh; overflow: hidden"
>
  <pdf-viewer
    [src]="pdfSrc"
    id="custom-pdf-viewer"
    [rotation]="rotationAngle"
    [original-size]="false"
    [show-all]="true"
    [fit-to-page]="false"
    [zoom]="1"
    [zoom-scale]="'page-width'"
    [stick-to-page]="false"
    [render-text]="true"
    [external-link-target]="'blank'"
    [autoresize]="true"
    [show-borders]="false"
    style="width: 100%; height: 600px"
  ></pdf-viewer>
  <!-- <iframe
    [src]="pdfSrc"
    frameborder="0"
    style="width: 100%; height: 65vh"
  ></iframe> -->
</mat-dialog-content>
