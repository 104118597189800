import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SettingsService, PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";
import { countries } from "countries-list";
import { PhoneNumberValidator } from "../validators/mobile-validator";
@Component({
	selector: "app-external-person-login",
	templateUrl: "./external-person-login.component.html",
	styleUrls: ["./external-person-login.component.scss"],
})
export class ExternalPersonLoginComponent implements OnInit {
	form: FormGroup;
	profileimage: string = "";
	error_status: boolean = false;
	error_message: string = "";
	languages: any = this.ds.languages;
	options = this.settings.getOptions();
	lang_key = localStorage.getItem("lang_key") || "en";
	qrcode_img = "assets/images/logo.svg";
	api_key = environment.X_API_KEY;
	private subscriptions = new Subscription();
	showBarcode = false;
	//send code
	currentIndex = 0;
	allCountries = Object.values(countries);
	constructor(
		public lang: LanguagePipe,
		private fb: FormBuilder,
		private router: Router,
		private settings: SettingsService,
		public ds: DataService,
		private spinner: PreloaderService,
		private ls: LocalStorage,
		private alert: AlertService,
		private sweetAlert: SweetAlertService
	) {
		this.form = this.fb.group(
			{
				method: ["email", [Validators.required]],
				value: ["", [Validators.required]],
				phone_code: ["966"],
				mobile: [""],
				otp_code: ["", [Validators.required, Validators.min(4)]],
			},
			{
				validators: [PhoneNumberValidator()],
			}
		);
	}
	ngOnInit() {}

	switchlanguage(lang): void {
		localStorage.setItem("lang_key", lang.lang_key);
		if (localStorage.getItem("direction") != lang.lang_dir) {
			localStorage.setItem("direction", lang.lang_dir);
			window.location.reload();
		}
	}
	login() {
		this.spinner.show();
		const formData = new FormData();
		formData.append("otp_code", this.form.get("otp_code").value || "");
		this.subscriptions.add(
			this.ds.post("tasks/login_extarnal_person", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						localStorage.setItem("eploggedin", "1");
						this.ls.setItem("eptoken", res?.token);
						this.router.navigate(["external-person/tickets"]);
					} else {
						this.sweetAlert.errorToast(res?.msg, 3000);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			)
		);
	}

	moveToNext() {
		if (this.currentIndex < 2) {
			this.currentIndex++;
		}
	}

	moveToPrev() {
		if (this.currentIndex <= 2 && this.currentIndex > 0) {
			this.currentIndex--;
		}
	}

	sendCode() {
		console.log(this.form);

		if (this.form.get("method").valid) {
			if (
				this.form.get("method").value == "mobile" &&
				this.form.errors?.wrongNumber?.value
			) {
				this.sweetAlert.errorToast(
					this.lang.transform("lang_fill_required_fields"),
					3000
				);
			} else if (
				this.form.get("method").value != "mobile" &&
				this.form.get("value").invalid
			) {
				this.sweetAlert.errorToast(
					this.lang.transform("lang_fill_required_fields"),
					3000
				);
			} else {
				this.spinner.show();
				let formData = new FormData();
				let value = "";
				if (this.form.get("method").value == "mobile") {
					value =
						this.form.get("phone_code").value + this.form.get("mobile").value;
				} else {
					value = this.form.get("value").value;
				}
				formData.append("method", this.form.get("method").value || "");
				formData.append("value", value || "");
				this.subscriptions.add(
					this.ds.post("tasks/send_otp", formData).subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert.successToast(res?.msg, 3000);
								this.moveToNext();
							} else {
								this.sweetAlert.errorToast(res?.msg, 3000);
							}
						},
						(err) => {
							this.spinner.hide();
							this.sweetAlert.errorToast(
								this.lang.transform("lang_internal_server_error"),
								3000
							);
						}
					)
				);
			}
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
