<section
  class="panel-expansion matborderno"
  *ngIf="branch_id && branch_id != '0'"
>
  <mat-accordion class="accounts_settings">
    <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
      <i class="together-icon">
        <i
          class="fa fa-plus faadd mnabricon"
          *ngIf="ds.allowMe('accounts', 'accounts_add')"
          (click)="openModal(NewFinancial); emptyform()"
          matTooltip="{{ 'lang_add_financial_year' | language }}"
        ></i>
      </i>
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_financial_years" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <div class="mbrl15">
          <div class="row">
            <div class="table-responsive customResponsive">
              <table
                border="1"
                class="table CustomTable"
                *ngIf="data.length > 0"
              >
                <thead>
                  <tr>
                    <td>#</td>
                    <td>{{ "lang_financial_date" | language }}</td>
                    <td>{{ "lang_status" | language }}</td>
                    <td>{{ "lang_writer" | language }}</td>
                    <td>{{ "lang_ondate" | language }}</td>
                    <td>{{ "lang_action" | language }}</td>
                  </tr>
                </thead>
                <tbody *ngIf="data.length > 0" class="AppendList">
                  <tr
                    *ngFor="
                      let his of data;
                      let i = index;
                      let odd = odd;
                      let even = even
                    "
                    [ngClass]="{ odd: odd, even: even }"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>{{ his.label }}</td>
                    <td *ngIf="his.finance_status == '0'">
                      <i
                        class="fa fa-icon fa-circle red"
                        matTooltip="{{ 'lang_closed' | language }}"
                      ></i>
                    </td>
                    <td *ngIf="his.finance_status == '1'">
                      <i
                        class="fa fa-icon fa-circle green"
                        matTooltip="{{ 'lang_opened' | language }}"
                      ></i>
                    </td>
                    <td>{{ his.writer_name }}</td>
                    <td>{{ his.created_date }}</td>
                    <td *ngIf="his.finance_status == '1'">
                      <i
                        class="fa fa-icon fa-times-circle red mr-2 ml-2"
                        matTooltip="{{ 'lang_close' | language }}"
                        (click)="close_financial_year(his)"
                      ></i>
                      <i
                        class="pi fa-icon pi-clone"
                        matTooltip="{{ 'lang_clone' | language }}"
                        (click)="onCloneFinancialYear(his)"
                      ></i>
                    </td>
                    <td *ngIf="his.finance_status == '0'">
                      <i
                        class="fa fa-icon fa-trash red"
                        *ngIf="ds.allowMe('accounts', 'accounts_delete')"
                        matTooltip="{{ 'lang_delete' | language }}"
                      ></i>
                      <i *ngIf="!ds.allowMe('accounts', 'accounts_delete')"
                        >----</i
                      >
                      <i
                        class="pi fa-icon pi-clone mr-2 ml-2"
                        matTooltip="{{ 'lang_clone' | language }}"
                        (click)="onCloneFinancialYear(his)"
                      ></i>
                      <i
                        class="pi fa-icon pi-replay green"
                        matTooltip="{{ 'lang_reopen' | language }}"
                        (click)="reopen_financial_year(his)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <no-data
                style="margin: auto"
                [Opened]="data.length < 1"
              ></no-data>
              <div
                class="d-flex justify-content-between p-2"
                *ngIf="data.length > 0"
              >
                <ngb-pagination
                  [collectionSize]="collectionSize"
                  [maxSize]="3"
                  [rotate]="true"
                  [ellipses]="false"
                  [boundaryLinks]="true"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  (pageChange)="refreshLists()"
                ></ngb-pagination>
                <select
                  class="customselect"
                  [(ngModel)]="pageSize"
                  (change)="refreshLists()"
                >
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="20">20</option>
                  <option [ngValue]="50">50</option>
                  <option [ngValue]="100">100</option>
                  <option [ngValue]="250">250</option>
                  <option [ngValue]="500">500</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
<ng-template #NewFinancial>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_add_financial_year" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="form" class="from" (ngSubmit)="submit()">
      <div class="modal-body">
        <input type="hidden" formControlName="branch_id" />
        <div class="form-group">
          <label
            >{{ "lang_financial_start_date" | language }} :<span class="red"
              >*</span
            ></label
          >
          <div class="input-group input-group-sm">
            <input
              [matDatepicker]="rcd"
              formControlName="financial_start_date"
              class="form-control almnabrformcontrol"
              placeholder="YYYY/MM/DD"
              required
              readonly
            />
            <span class="input-group-btn">
              <mat-datepicker-toggle
                class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                matSuffix
                [for]="rcd"
              ></mat-datepicker-toggle>
              <mat-datepicker #rcd></mat-datepicker>
            </span>
            <span class="input-group-btn">
              <button
                (click)="form.get('financial_start_date').setValue('')"
                matTooltip="{{ 'lang_empty' | language }}"
                type="button"
                class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
              ></button>
            </span>
          </div>
          <mat-error
            *ngIf="
              form.controls['financial_start_date'].touched &&
              form.controls['financial_start_date'].errors?.required
            "
          >
            {{ "lang_field_required" | language }}
          </mat-error>
        </div>
        <div class="form-group">
          <label
            >{{ "lang_financial_end_date" | language }} :<span class="red"
              >*</span
            ></label
          >
          <div class="input-group input-group-sm">
            <input
              [matDatepicker]="ee"
              formControlName="financial_end_date"
              class="form-control almnabrformcontrol"
              placeholder="YYYY/MM/DD"
              required
              readonly
            />
            <span class="input-group-btn">
              <mat-datepicker-toggle
                class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                matSuffix
                [for]="ee"
              ></mat-datepicker-toggle>
              <mat-datepicker #ee></mat-datepicker>
            </span>
            <span class="input-group-btn">
              <button
                (click)="form.get('financial_end_date').setValue('')"
                matTooltip="{{ 'lang_empty' | language }}"
                type="button"
                class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
              ></button>
            </span>
          </div>
          <mat-error
            *ngIf="
              form.controls['financial_end_date'].touched &&
              form.controls['financial_end_date'].errors?.required
            "
            >{{ "lang_field_required" | language }}</mat-error
          >
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              data-dismiss="modal"
              (click)="modalRef.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #CloneFinancial>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_clone" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="cloneModalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form
      [formGroup]="formClone"
      class="from"
      (ngSubmit)="clone_financial_year()"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <label>{{ "lang_finance_to" | language }}</label>
            <ng-select
              [items]="financeToList"
              [multiple]="false"
              bindLabel="label"
              bindValue="value"
              appendTo="body"
              clearAllText="Clear"
              formControlName="to_finance_id"
              placeholder="{{ 'lang_finance_to' | language }}"
            >
            </ng-select>
          </div>
          <div class="col-12 col-sm-6">
            <label>{{ "lang_tree_or_with_balances" | language }}</label>
            <ng-select
              [items]="clone_tree_or_with_balances"
              [multiple]="false"
              bindLabel="label"
              bindValue="value"
              appendTo="body"
              clearAllText="Clear"
              formControlName="clone_tree_or_with_balances"
              placeholder="{{ 'lang_tree_or_with_balances' | language }}"
            >
            </ng-select>
          </div>
          <div class="col-12 col-sm-6">
            <label>{{ "lang_clone_non_zero_accounts" | language }}</label>
            <ng-select
              [items]="clone_non_zero_accounts"
              [multiple]="false"
              bindLabel="label"
              bindValue="value"
              appendTo="body"
              clearAllText="Clear"
              formControlName="clone_non_zero_accounts"
              placeholder="{{ 'lang_clone_non_zero_accounts' | language }}"
            >
            </ng-select>
          </div>
          <div class="col-12 mt-5" *ngIf="this.account_codes.length > 0">
            <table
              class="table CustomTable"
              width="100%"
              formArrayName="Account_Codes"
            >
              <thead>
                <tr>
                  <td width="2%" style="padding: 5px; word-break: break-all">
                    #
                  </td>
                  <td width="32%" style="padding: 5px; word-break: break-all">
                    {{ "lang_account_code" | language }}
                  </td>
                  <td width="32%" style="padding: 5px; word-break: break-all">
                    {{ "lang_value" | language }}
                    <span class="red">*</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let item of Account_Codes_Groups.controls;
                    let i = index;
                    let odd = odd;
                    let even = even
                  "
                  [formGroupName]="i"
                >
                  <td
                    class="verticalalignmid"
                    style="padding: 5px; word-break: break-all"
                  >
                    {{ i + 1 }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      word-break: break-all;
                      position: relative;
                    "
                  >
                    <input
                      type="text"
                      formControlName="account_code"
                      placeholder="{{ 'lang_account_code' | language }}"
                      autocomplete="off"
                      class="form-control almnabrformcontrol text-center"
                      readonly
                    />
                  </td>
                  <td
                    style="
                      padding: 5px;
                      word-break: break-all;
                      position: relative;
                    "
                  >
                    <input
                      type="text"
                      formControlName="value"
                      placeholder="{{ 'lang_account_code' | language }}"
                      autocomplete="off"
                      class="form-control almnabrformcontrol text-center"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              data-dismiss="modal"
              (click)="cloneModalRef.hide(); this.account_codes.length = []"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
