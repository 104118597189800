import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-excel-history",
  templateUrl: "./excel-history.component.html",
  styleUrls: ["./excel-history.component.scss"],
})
export class ExcelHistoryComponent implements OnInit {
  historyArray: any = [];
  page = 1;
  size = "10";
  total_records;
  subscriptions = new Subscription();

  constructor(
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public sweetAlert: SweetAlertService,
    public router: Router,
    public ds: DataService,
    private dialogRef: MatDialogRef<ExcelHistoryComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (this.data && this.data?.length) {
      this.historyArray = this.data;
    } else {
      this.historyArray = [];
    }
  }

  ngOnInit(): void {
    this.getHistoryData();
  }

  getHistoryData() {
    let param = new FormData();
    param.set("key_search", this.data.searchKey || "");
    param.set("finance_id", this.data.finance_id || "");
    param.set("branch_id", this.data.branch_id || "");
    this.spinner.show();
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          param,
          `accounts/view_export_opening_balance/${this.page}/${this.size}`
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.historyArray = res.records;
              this.total_records = res.total_records;
            } else {
              this.sweetAlert.errorToast(res?.error, 2000);
            }
          },
          (error) => {
            this.spinner.hide();
            this.sweetAlert.errorToast(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error"),
              2000
            );
          }
        )
    );
  }

  downloadFile(data, title?, type?) {
    if (data.status) {
      let url = data.base64;
      let tagA = document.createElement("a");
      document.body.appendChild(tagA);
      tagA.style.display = "none";
      tagA.href = url;
      if (type == "blank") {
        tagA.setAttribute("target", "_blank");
      }
      tagA.download = title || "Almnabr";
      tagA.click();
      document.body.removeChild(tagA);
    }
  }

  downloadExl(log_id, fileType) {
    this.spinner.show();
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          {},
          `accounts/get_accounts_for_opening_excal_copy/${log_id}/${fileType}`
        )
        .subscribe(
          (res) => {
            console.log(res);
            this.spinner.hide();
            if (res.status) {
              this.downloadFile(res, "opening_excal");
            } else {
              this.sweetAlert.errorToast(res?.error, 2000);
            }
          },
          (error) => {
            this.spinner.hide();
            this.sweetAlert.errorToast(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error"),
              2000
            );
          }
        )
    );
  }
}
