import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import * as moment from "moment";

@Component({
	selector: "app-createDeduction",
	templateUrl: "./createDeduction.component.html",
	styleUrls: ["./createDeduction.component.scss"],
})
export class CreateDeductionComponent implements OnInit {
	@Output() loanEvent = new EventEmitter();
	@Input() employee_data;
	@Input() form_request_id;
	@Input() form_key;
	@Input() id;

	users: any = [];
	direct_anager: any = [];
	form: FormGroup;
	submitted: boolean = false;
	lang_key = localStorage.getItem("lang_key") || "en";
	constructor(
		public ds: DataService,
		private snackBar: MatSnackBar,
		public datepipe: DatePipe,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public changeDetectorRef: ChangeDetectorRef
	) {}
	emp_data: any = "";
	ngOnInit(): void {
		this.spinner.show();
		moment.locale("en");
		this.form = this.fb.group({
			financial_type: ["Deduction"],
			employee_number: [this.employee_data.employee_number],
			deduction_date_english: ["", Validators.required],
			deduction_date_arabic: ["", Validators.required],
			deduction_amount: ["", Validators.required],
			deduction_percentage: ["2"],

			deduction_reason: ["", Validators.required],
			direct_manager: ["", Validators.required],
		});

		let data = new FormData();
		data.append("employee_number", this.employee_data.employee_number);

		this.spinner.show();
		this.ds.post(`human_resources/get_emp_details`, data).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.emp_data = res.records;
				} else {
					this.spinner.hide();
					this.showMsg(
						res.error || this.lang.transform("lang_employee_have_no_contract"),
						"ok",
						"error-snackbar"
					);
					this.loanEvent.emit();
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);

		this.spinner.hide();
	}

	submit() {
		this.submitted = true;
		if (this.form?.status == "VALID") {
			this.spinner.show();
			let formData = new FormData();
			formData.append("employee_number", this.employee_data.employee_number);
			formData.append(
				"deduction_date_english",
				moment(this.form.value.deduction_date_english).format("YYYY/MM/DD")
			);
			formData.append(
				"deduction_date_arabic",
				this.form.value.deduction_date_arabic
			);
			formData.append("deduction_amount", this.form.value.deduction_amount);
			formData.append(
				"deduction_percentage",
				this.form.value.deduction_percentage
			);
			formData.append("deduction_reason", this.form.value.deduction_reason);
			formData.append("direct_manager", this.form.value.direct_manager || "");

			this.ds.post(`form/${this.form_key}/cr/0`, formData).subscribe(
				(data) => {
					this.spinner.hide();
					if (data.status) {
						this.alert.success(
							data.msg || this.lang.transform("lang_data_sent_successfully")
						);
						this.submitted = false;
						this.form.reset();
						setTimeout(() => {
							this.router.navigate(["transactions/allforms/"]);
						}, 1000);
					} else {
						this.alert.error(data?.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(error);
				}
			);
		} else {
			this.showMsg("Please fill all fields first", "ok", "error-snackbar");
		}
	}

	public isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 101 || charCode == 69) {
			return false;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 4000,
			panelClass: [className],
		});
	}

	public search_users(key) {
		if (key) {
			this.users = [];
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.users = res.list;
					}
				});
		}
	}
}
