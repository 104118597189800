<div class="themesection editemployee" *ngIf="ds.allowMe('human_resources','human_resources_edit') && editstatus">
    <div class="row">
        <div class="col-md-2 col-sm-4 col-xs-12 mb-10 mt-10">
            <a routerLink="/human_resources/employees"><button type="button" class="albutton">{{'lang_goto_list' | language }}</button></a>
        </div>
        <div class="col-md-2 col-sm-4 col-xs-12 mt-10">
            <a routerLink="/human_resources/view/{{id}}/view/{{branch_id}}"><button type="button" class="albutton">{{'lang_view' | language }}</button></a>
        </div>
    </div>
    <div class="card">
        <div class="card-header">{{'lang_human_resources_edit_emp' | language }}</div>
        <div class="card-body pad0">
            <div class="mbrl15 martb0">
                <div class="row">
                    <div class="col-xs-12 col-12 pad0">
                        <form id="regForm" [formGroup]="createHRForm" class="editForm" novalidate (ngSubmit)="updateEmployee()">
                            <mat-horizontal-stepper #stepper class="pad0">
                                <mat-step formGroupName="idDetails" [stepControl]="createHRForm.get('idDetails')">
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-user fa-icon"></i>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_id_details' | language }}</div>
                                    </ng-template>
                                    <app-step1 [regForm]="createHRForm" [action]="action"></app-step1>
                                </mat-step>
                               <!--  <mat-step formGroupName="jobDetails" [stepControl]="createHRForm.get('jobDetails')">
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-suitcase fa-icon"></i>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_job_details' | language }}</div>
                                    </ng-template>
                                    <app-step7 [regForm]="createHRForm" [action]="action"></app-step7>
                                </mat-step> -->
                                <mat-step formGroupName="contactDetails"
                                    [stepControl]="createHRForm.get('contactDetails')">
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-address-book fa-icon"></i>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_contact_details' | language }}</div>
                                    </ng-template>
                                    <app-step2 [regForm]="createHRForm" [action]="action"></app-step2>
                                </mat-step>
                                <mat-step formGroupName="bankDetails" [stepControl]="createHRForm.get('bankDetails')">
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-university fa-icon"></i>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_bank_details' | language }}</div>
                                    </ng-template>
                                    <app-step3 [regForm]="createHRForm" [action]="action"></app-step3>
                                </mat-step>
                                <mat-step formGroupName="educationDetails"
                                    [stepControl]="createHRForm.get('educationDetails')">
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-book fa-icon"></i>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_education_details' | language }}</div>
                                    </ng-template>
                                    <app-step4 [regForm]="createHRForm" [action]="action"></app-step4>
                                </mat-step>
                                <mat-step formGroupName="passportDetails"
                                    [stepControl]="createHRForm.get('passportDetails')">
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-plane fa-icon"></i>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_passport_details' | language }}</div>
                                    </ng-template>
                                    <app-step5 [regForm]="createHRForm" [action]="action"></app-step5>
                                </mat-step>
                                <mat-step formGroupName="insuranceDetails"
                                    [stepControl]="createHRForm.get('insuranceDetails')">
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-hospital-o fa-icon"></i>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_insurance_details' | language }}</div>
                                    </ng-template>
                                    <app-step6 [regForm]="createHRForm" [action]="action"></app-step6>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <app-alert id="default-alert"></app-alert>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2 col-sm-4 col-xs-12 mb-10 mt-10">
            <a routerLink="/human_resources/employees">
                <button type="button" class="albutton">{{'lang_goto_list' | language }}</button>
            </a>
        </div>
        <div class="col-md-2 col-sm-4 col-xs-12 mt-10">
            <a routerLink="/human_resources/view/{{id}}/view/{{branch_id}}">
                <button type="button" class="albutton">{{'lang_view' | language }}</button>
            </a>
        </div>
    </div>
</div>