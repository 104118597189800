import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CtCommunicationComponent } from "./ct-communication/ct-communication.component";
import { CtcreateComponent } from "./ctcreate/ctcreate.component";
import { CteditComponent } from "./ctedit/ctedit.component";
import { CtlistComponent } from "./ctlist/ctlist.component";
import { CtsettingsComponent } from "./ctsettings/ctsettings.component";
import { CtviewComponent } from "./ctview/ctview.component";

const routes: Routes = [
	{
		path: "settings",
		component: CtsettingsComponent,
		data: { title: "Contractor Settings" },
	},
	{
		path: "create",
		component: CtcreateComponent,
		data: { title: "Create Contractor" },
	},
	{
		path: "lists",
		component: CtlistComponent,
		data: { title: "All Contractor" },
	},
	{
		path: "view/:id",
		component: CtviewComponent,
		data: { title: "View Contractor" },
	},
	{
		path: "edit/:id",
		component: CteditComponent,
		data: { title: "Edit Contractor" },
	},
	{
		path: "view/:id/communication",
		component: CtCommunicationComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ContractorsRoutingModule {}
