import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';

@Component({
  selector: 'app-zone-dialog',
  templateUrl: './zone-dialog.component.html',
  styleUrls: ['./zone-dialog.component.scss']
})
export class ZoneDialogComponent implements OnInit {
  lang_key = localStorage.getItem('lang_key');
  projects_work_area_id:any;
  projects_profile_id:any;
  projects_supervision_id:any;

  zones    = [];
  blocks   = [];
  clusters = [];

  zone:any;
  block:any;
  cluster:any;
  constructor(public dialogRef: MatDialogRef<ZoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private ds: DataService, private spinner: PreloaderService) { 
      this.projects_work_area_id   = data?.projects_work_area_id;
      this.projects_profile_id     = data?.projects_profile_id;
      this.projects_supervision_id = data?.projects_supervision_id;
      this.get_zones();
    }

  ngOnInit(): void {
  }

  get_zones() {
    this.spinner.show();
    let phaseId = '0',
    param = new FormData();
    param.append('phase_parent_id', phaseId);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'joYF29rbEi/'+this.projects_profile_id+'/'+this.projects_supervision_id).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.zones = res?.records;
        }
    });
  }

  get_blocks() {
    let phaseId = this.zone?.phase_id;
    if(phaseId) {
      this.spinner.show();
      let param = new FormData();
      param.append('phase_parent_id', phaseId);
      this.ds.getActionByUrl(this.ds.formData2string(param), 'joYF29rbEi/'+this.projects_profile_id+'/'+this.projects_supervision_id).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.blocks = res?.records;
        } else {
          this.blocks = [];
        }
      });
    } else {
      this.blocks = [];
      this.block = null;
      this.clusters = [];
      this.cluster = null;
    }
  }

  get_clusters() {
    let phaseId = this.block?.phase_id;
    if(phaseId) {
      this.spinner.show();
      let param = new FormData();
      param.append('phase_parent_id', phaseId);
      this.ds.getActionByUrl(this.ds.formData2string(param), 'joYF29rbEi/'+this.projects_profile_id+'/'+this.projects_supervision_id).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.clusters = res?.records;
        } else {
          this.clusters = [];
        }
      });
    } else {
      this.clusters = [];
      this.cluster = null;
    }
  }

  saveZoneFilter() {
    this.dialogRef.close({
      zone: this.zone, 
      block: this.block, 
      cluster: this.cluster
    });
  }

}
