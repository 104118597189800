<form [formGroup]="form" (ngSubmit)="submit();">
    <div class="modal-body">            
        <div class="form-group">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <label for="collection_id">{{'lang_collections' | language }} : <span class="red">*</span></label>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <ng-select 
                        [items]="collections" 
                        [multiple]="false" 
                        bindLabel="label"                         
                        appendTo="body" 
                        bindValue="value" 
                        clearAllText="Clear"
                        placeholder="{{'lang_collections' | language}}"
                        formControlName="collection_id"
                        (change)="group2_search();platform_search();">
                    </ng-select>
                    <mat-error *ngIf="submitted && form.controls['collection_id'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <label for="platform_group1_code_system">{{'lang_group' | language }} 1 : <span class="red">*</span></label>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <ng-select 
                        [items]="group1codes" 
                        [multiple]="false" 
                        bindLabel="label" 
                        appendTo="body" 
                        bindValue="value"                         
                        clearAllText="Clear"
                        placeholder="{{'lang_group' | language}} 1"
                        formControlName="platform_group1_code_system"
                        (change)="group2_search();platform_search();">
                    </ng-select>
                    <mat-error *ngIf="submitted && form.controls['platform_group1_code_system'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <label for="platform_group_type_code_system">{{'lang_group_types' | language }} : <span class="red">*</span></label>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <ng-select 
                        [items]="grouptypes" 
                        [multiple]="false" 
                        bindLabel="fulltext" 
                        appendTo="body" 
                        bindValue="value" 
                        clearAllText="Clear"
                        placeholder="{{'lang_group_types' | language}}"
                        formControlName="platform_group_type_code_system"
                        (change)="group2_search();platform_search();">
                    </ng-select>
                    <mat-error *ngIf="submitted && form.controls['platform_group_type_code_system'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="form.get('platform_group1_code_system').value && form.get('platform_group_type_code_system').value">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <label for="platform_group2_code_system">{{'lang_group' | language }} 2 : <span class="red">*</span></label>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <ng-select 
                        [items]="group2codes" 
                        [multiple]="false" 
                        bindLabel="fulltext" 
                        appendTo="body"                         
                        bindValue="value" 
                        clearAllText="Clear"
                        placeholder="{{'lang_group' | language}} 2"
                        formControlName="platform_group2_code_system"
                        (change)="platform_search();">
                    </ng-select>
                    <mat-error *ngIf="submitted && form.controls['platform_group2_code_system'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="form.get('platform_group1_code_system').value && form.get('platform_group_type_code_system').value && form.get('platform_group2_code_system').value">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <label for="platform_code_system">{{'lang_project_platforms' | language }} : <span class="red">*</span></label>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <ng-select 
                        [items]="platforms" 
                        [multiple]="false" 
                        bindLabel="fulltext" 
                        appendTo="body" 
                        bindValue="value"                         
                        clearAllText="Clear"
                        placeholder="{{'lang_project_platforms' | language}}"
                        formControlName="platform_code_system">
                    </ng-select>
                    <mat-error *ngIf="submitted && form.controls['platform_code_system'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
            </div>
        </div>
        <div class="row mb-10">
            <div class="table-responsive customResponsive tablefields">
                <table border="1" class="table CustomTable bigtable" width="100%">
                    <thead>
                        <tr>
                            <td style="padding: 5px;word-break: break-all;"> {{'lang_group' | language }} 1 </td>
                            <td style="padding: 5px;word-break: break-all;"> {{'lang_group_types' | language }} </td>
                            <td style="padding: 5px;word-break: break-all;"> {{'lang_group' | language }} 2 </td>
                            <td style="padding: 5px;word-break: break-all;"> {{'lang_project_platforms' | language }} </td>
                            <td style="padding: 5px;word-break: break-all;"> {{'lang_action' | language }} </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="background: #ffffff;">
                            <td>
                                <ng-select 
                                    [items]="group1codes" 
                                    [multiple]="false" 
                                    bindLabel="label" 
                                    appendTo="body" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    placeholder="{{'lang_group' | language}} 1"
                                    [(ngModel)]="platform_group1_code_system"
                                    [ngModelOptions]="{standalone: true}"
                                    (focus)="platform_group2_code_system = null; platform_code_system= null;">
                                </ng-select>
                            </td>
                            <td>
                                <ng-select 
                                    [items]="grouptypes" 
                                    [multiple]="false" 
                                    bindLabel="label" 
                                    appendTo="body" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    placeholder="{{'lang_group_types' | language}}"
                                    [(ngModel)]="platform_group_type_code_system"
                                    [ngModelOptions]="{standalone: true}"
                                    (change)="group2_model_search()">
                                </ng-select>
                            </td>
                            <td>
                                <ng-select
                                    [items]="group2codesmodel" 
                                    [multiple]="false" 
                                    appendTo="body" 
                                    bindValue="value" 
                                    bindLabel="fulltext" 
                                    clearAllText="Clear"
                                    placeholder="{{'lang_group' | language}} 2"
                                    [(ngModel)]="platform_group2_code_system"
                                    [ngModelOptions]="{standalone: true}"
                                    (change)="platform_model_search();">
                                </ng-select>
                            </td>
                            <td>
                                <ng-select 
                                    [items]="platformsmodel" 
                                    [multiple]="true"
                                    bindValue="value" 
                                    bindLabel="fulltext"
                                    appendTo="body" 
                                    clearAllText="Clear"
                                    placeholder="{{'lang_project_platforms' | language}}"
                                    [(ngModel)]="platform_code_system"
                                    [ngModelOptions]="{standalone: true}">
                                </ng-select>
                            </td>
                            <td class="text-center">
                                <i class="fa fa-plus-square fa-icon mnabricon" matTooltip="{{'lang_add' | language }}" (click)="add_platform_code_systems();"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="form-group">
            <label for="platform_code_systems">{{'lang_platform_code_systems' | language }} : <span class="fa fa-info-circle red" matTooltip="{{'lang_access_codes_info' | language}}"></span></label>
            <ng-select 
                [items]="finalaccesscodes" 
                [multiple]="true" 
                appendTo="body" 
                bindValue="value" 
                bindLabel="fulltext"
                clearAllText="Clear"
                placeholder="{{'lang_platform_code_systems' | language}}"
                formControlName="platform_code_systems">
            </ng-select>
        </div>
    </div>
    <div class="card-footer pad15">
        <div class="row">
            <div class="col-12 mb-10">
                <app-alert id="default-alert"></app-alert>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-xs-12 mb-10">
                <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
            </div>
            <div class="col-md-6 col-xs-12 mb-10">
                <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="closeModel.emit();">{{'lang_close' | language }}</button>
            </div>
        </div>
    </div>
</form>