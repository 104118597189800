import { DatePipe, DecimalPipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";

@Component({
  selector: "app-sinvoice-edit",
  templateUrl: "./sinvoice-edit.component.html",
  styleUrls: ["./sinvoice-edit.component.scss"],
})
export class SinvoiceEditComponent implements OnInit, OnDestroy {
  public branch_id = this.route.snapshot.paramMap.get("bid");
  public invoice_id = this.route.snapshot.paramMap.get("iid");
  public finance_id = this.route.snapshot.paramMap.get("fid");
  advance_acode_text = this.lang.transform("lang_advance_accounts");
  advance_ccode_text = this.lang.transform("lang_advance_costcenter");
  fullname: string;
  date: Date;
  branches: any = [];
  modes: any = [];
  accounts: any = [];
  costs: any = [];
  taxs: any = [];
  disable: boolean = true;
  myDate: any = new Date();
  form: FormGroup;
  items: FormArray;
  item_tax;
  global_tax;
  item_discount;
  global_discount;
  edit_status: boolean = false;
  InvData: any = [];
  viewdata: any = [];
  transactions: any = [];
  get itemvalid() {
    return this.form.get("items") as FormArray;
  }
  constructor(
    public ds: DataService,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    public router: Router,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private _decimalPipe: DecimalPipe
  ) {}
  ngOnInit(): void {
    this.buildform();
    this.fullname = this.ls.getItem("user_username");
    this.spinner.show();
    this.load_tax_settings();
    this.load_taxes();
    this.load_payment_modes();
    setTimeout(() => {
      this.getdata_foredit();
    }, 500);
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public load_tax_settings() {
    this.item_tax = 0;
    this.global_tax = 0;
    this.item_discount = 0;
    this.global_discount = 0;
    this.subscriptions.add(
      this.ds.postActionByUrl([], "gts/" + this.branch_id).subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.item_tax = JSON.parse(res.records.item_tax);
          this.global_tax = JSON.parse(res.records.global_tax);
          this.item_discount = JSON.parse(res.records.item_discount);
          this.global_discount = JSON.parse(res.records.global_discount);
        }
      })
    );
  }
  public load_payment_modes() {
    this.form.get("invoice_payment_mode").setValue(null);
    this.modes = [];
    this.spinner.show();
    this.subscriptions.add(
      this.ds.getActionByUrl([], "pmodes/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.modes = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public load_taxes() {
    this.taxs = [];
    this.subscriptions.add(
      this.ds
        .getActionByUrl([], "invtaxes/" + this.branch_id)
        .subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            this.taxs = res.records;
          }
        })
    );
  }
  public getdata_foredit() {
    this.spinner.show();
    let formData = new FormData();
    formData.append("finance_id", this.finance_id || "");
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(formData),
          "editsinvoice/" + this.branch_id + "/" + this.invoice_id
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            this.edit_status = res.status;
            if (res.status) {
              this.accounts = res.accounts;
              this.costs = res.costs;
              for (let index = 0; index < res.records.items.length; index++) {
                this.items.push(this.item_records());
              }
              this.form.patchValue(res.records);
              this.form
                .get("invoice_date")
                .setValue(this.ds.encon(res.records.invoice_date));
              this.form
                .get("invoice_global_tax_age")
                .setValue(res.records.global_tax_value);
              if (res.records.writer_name) {
                this.fullname = res.records.writer_name;
              }
              if (
                res.records.invoice_credit_days &&
                res.records.invoice_credit_date
              ) {
                this.form.get("invoice_credit_days").enable();
                this.form.get("invoice_credit_date").disable();
                if (
                  res.records.invoice_payment_mode &&
                  res.records.invoice_payment_mode.key !== "cash"
                ) {
                  this.form.get("invoice_cash_advance_amount").enable();
                } else {
                  this.form.get("invoice_cash_advance_amount").disable();
                }
              }
              for (let index = 0; index < res.records.items.length; index++) {
                this.calculate_invoice_tax(
                  this.itemvalid.controls[index],
                  index
                );
              }
              this.calculate_total_calculation();
            } else {
              this.ds.dialogf("", res.error);
              this.router.navigate(["accounts/sinvoices/"]);
            }
          },
          (error) => {
            this.edit_status = false;
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
            this.router.navigate(["accounts/sinvoices/"]);
          }
        )
    );
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(null, [Validators.required]),
      invoice_date: new FormControl(this.ds.encon(this.myDate), [
        Validators.required,
      ]),
      invoice_payment_mode: new FormControl(null, [Validators.required]),
      invoice_credit_days: new FormControl({
        value: "",
        disabled: this.disable,
      }),
      invoice_credit_date: new FormControl({ value: "", disabled: true }),
      invoice_to_account_id: new FormControl(null, [Validators.required]),
      invoice_address_to: new FormControl(""),
      invoice_vat_tax_id: new FormControl(""),
      invoice_ref_number: new FormControl(""),
      invoice_instruction_remarks: new FormControl(""),
      invoice_to_cost_center_id: new FormControl(null),
      items: this.fb.array([]),
      invoice_grand_total: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      invoice_global_discount_percent: new FormControl(""),
      invoice_cash_advance_amount: new FormControl({
        value: "",
        disabled: true,
      }),
      invoice_global_tax_id: new FormControl(null),
      invoice_global_tax_age: new FormControl({ value: "", disabled: true }),
      invoice_global_tax_amount: new FormControl({ value: "", disabled: true }),
      invoice_net_total: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      invoice_income_expanse_account_id: new FormControl(null, [
        Validators.required,
      ]),
      invoice_income_expanse_cost_id: new FormControl(null),
      invoice_cash_advance_account_id: new FormControl(null),
      invoice_cash_advance_cost_center_id: new FormControl(null),
      invoice_tax_account_id: new FormControl(null),
      invoice_tax_cost_center_id: new FormControl(null),
      total_item_tax: new FormControl({ value: "", disabled: true }),
      total_item_discount: new FormControl({ value: "", disabled: true }),
      invoice_global_discount_amount: new FormControl({
        value: "",
        disabled: true,
      }),
    });
    this.items = this.form.get("items") as FormArray;
  }
  public items_group() {
    return this.form.get("items") as FormArray;
  }
  public item_records(): FormGroup {
    return this.fb.group({
      invoice_item_name: new FormControl("", [Validators.required]),
      invoice_item_unit: new FormControl(""),
      invoice_item_quantity: new FormControl("", [Validators.required]),
      invoice_item_price: new FormControl("0", [Validators.required]),
      invoice_item_tax: new FormControl(null),
      invoice_item_tax_amount: new FormControl({ value: "", disabled: true }),
      invoice_item_amount: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      invoice_discount_percent: new FormControl(""),
      invoice_discount_percent_amount: new FormControl({
        value: "",
        disabled: true,
      }),
      invoice_total_amount: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      invoice_item_description: new FormControl("", [Validators.required]),
    });
  }
  public add_items() {
    this.items.push(this.item_records());
  }
  public removeItems(index) {
    if (this.items.length > 1) {
      this.items.removeAt(index);
    }
  }
  public payment_mode_options(event) {
    if (event != undefined && event.key === "cash") {
      this.advance_acode_text = this.lang.transform("lang_cash_accounts");
      this.advance_ccode_text = this.lang.transform("lang_cash_costcenter");
    } else if (event != undefined && event.key === "bank") {
      this.advance_acode_text = this.lang.transform("lang_bank_accounts");
      this.advance_ccode_text = this.lang.transform("lang_bank_costcenter");
    } else if (event != undefined && event.key === "credit") {
      this.advance_acode_text = this.lang.transform("lang_credit_accounts");
      this.advance_ccode_text = this.lang.transform("lang_credit_costcenter");
    }
    this.form.get("invoice_credit_days").setValue("");
    this.form.get("invoice_credit_date").setValue("");
    if (event != undefined && event.key == "credit") {
      this.disable = false;
      this.form.get("invoice_credit_days").enable();
      this.form.get("invoice_credit_date").enable();
    } else {
      this.form.get("invoice_credit_days").disable();
      this.form.get("invoice_credit_date").disable();
      this.disable = true;
    }
    this.form.get("invoice_cash_advance_amount").setValue("");
    if (event == undefined || (event != undefined && event.key === "cash")) {
      this.form.get("invoice_cash_advance_amount").disable();
    } else {
      this.form.get("invoice_cash_advance_amount").enable();
    }
  }
  public add_days(days) {
    this.date = new Date(this.form.get("invoice_date").value);
    this.date.setDate(this.date.getDate() + JSON.parse(days));
  }
  public calculate_invoice_tax(control, index) {
    let quantity = control.get("invoice_item_quantity").value;
    let price = control.get("invoice_item_price").value;
    let amount: any = parseFloat(quantity) * parseFloat(price);
    let taxamount: any = 0.0;
    let tax = this.items.controls[index].get("invoice_item_tax").value;
    if (tax && tax.taxvalue) {
      taxamount = (parseFloat(tax.taxvalue) / 100) * parseFloat(amount);
      control
        .get("invoice_item_tax_amount")
        .setValue(this.roundfunction(taxamount));
    } else {
      control.get("invoice_item_tax_amount").setValue("");
    }
    if (!isNaN(amount)) {
      control
        .get("invoice_item_amount")
        .setValue(this.roundfunction(amount + taxamount));
      control
        .get("invoice_total_amount")
        .setValue(this.roundfunction(amount + taxamount));
    }
    let total_amount_with_tax: any = this.roundfunction(amount + taxamount);
    let discountamount: any = "0.00";
    let discount_percent = control.get("invoice_discount_percent").value;
    if (discount_percent) {
      discountamount =
        (parseFloat(discount_percent) / 100) *
        parseFloat(total_amount_with_tax);
      control
        .get("invoice_discount_percent_amount")
        .setValue(this.roundfunction(discountamount));
    }
    let final_total_amount: any =
      parseFloat(total_amount_with_tax) - parseFloat(discountamount);
    control
      .get("invoice_total_amount")
      .setValue(this.roundfunction(final_total_amount));
    this.calculate_total_calculation();
  }
  public calculate_total_calculation() {
    let items = this.form.get("items").value;
    let grand_total: any = 0;
    let total_item_tax: any = 0.0;
    let total_item_discount: any = 0.0;
    items.forEach((v, k) => {
      let amount: any =
        parseFloat(v.invoice_item_quantity) * parseFloat(v.invoice_item_price);
      let taxamount: any = 0.0;
      let tax = v.invoice_item_tax;
      if (tax && tax.taxvalue) {
        taxamount = (parseFloat(tax.taxvalue) / 100) * parseFloat(amount);
        if (!isNaN(taxamount)) {
          total_item_tax += parseFloat(taxamount);
        }
      }
      let total_amount_with_tax: any = parseFloat(amount + taxamount).toFixed(
        2
      );
      let discountamount: any = "0.00";
      let discount_percent = v.invoice_discount_percent;
      if (discount_percent) {
        discountamount =
          (parseFloat(discount_percent) / 100) *
          parseFloat(total_amount_with_tax);
        if (!isNaN(discountamount)) {
          total_item_discount += parseFloat(discountamount);
        }
      }
      let final_total_amount: any =
        parseFloat(amount) - parseFloat(discountamount);
      if (!isNaN(final_total_amount)) {
        grand_total += parseFloat(final_total_amount);
      }
    });
    this.form
      .get("invoice_grand_total")
      .setValue(this.roundfunction(grand_total));
    this.form
      .get("total_item_tax")
      .setValue(this.roundfunction(total_item_tax));
    this.form
      .get("total_item_discount")
      .setValue(this.roundfunction(total_item_discount));
    let net_total: any = parseFloat(grand_total) + parseFloat(total_item_tax);
    this.form.get("invoice_net_total").setValue(this.roundfunction(net_total));
    let global_tax = this.form.get("invoice_global_tax_id").value;
    let gtaxamount: any = 0.0;
    if (global_tax && global_tax.taxvalue) {
      gtaxamount =
        (parseFloat(global_tax.taxvalue) / 100) * parseFloat(net_total);
    }
    this.form
      .get("invoice_global_tax_amount")
      .setValue(this.roundfunction(gtaxamount));
    let invoice_net_total: any = parseFloat(net_total) + parseFloat(gtaxamount);
    let gdiscount_percent = this.form.get(
      "invoice_global_discount_percent"
    ).value;
    let gdiscountamount: any = "0.00";
    if (gdiscount_percent) {
      gdiscountamount =
        (parseFloat(gdiscount_percent) / 100) * parseFloat(invoice_net_total);
      if (!isNaN(gdiscountamount)) {
        invoice_net_total =
          parseFloat(invoice_net_total) - parseFloat(gdiscountamount);
      }
    }
    this.form
      .get("invoice_global_discount_amount")
      .setValue(this.roundfunction(gdiscountamount));
    let gdis: any = parseFloat(grand_total) - parseFloat(gdiscountamount);
    this.form.get("invoice_grand_total").setValue(this.roundfunction(gdis));
    let advance_amount = this.form.get("invoice_cash_advance_amount").value;
    let payment_mode = this.form.get("invoice_payment_mode").value;
    if (advance_amount && payment_mode.value !== "cash") {
      invoice_net_total =
        parseFloat(invoice_net_total) - parseFloat(advance_amount);
    }
    this.form
      .get("invoice_net_total")
      .setValue(this.roundfunction(invoice_net_total));
  }
  public global_tax_age() {
    let global_tax = this.form.get("invoice_global_tax_id").value;
    if (global_tax && global_tax.taxvalue) {
      this.form.get("invoice_global_tax_age").setValue(global_tax.taxvalue);
    } else {
      this.form.get("invoice_global_tax_age").setValue(0);
    }
    this.calculate_total_calculation();
  }
  public update_invoices() {
    this.spinner.show();
    let param = new FormData();
    param.append("branch_id", this.form.get("branch_id").value);
    param.append("invoice_date", this.form.get("invoice_date").value || "");
    let invoice_payment_mode = this.form.get("invoice_payment_mode").value;
    if (invoice_payment_mode && invoice_payment_mode != undefined) {
      param.append("invoice_payment_mode", invoice_payment_mode.value);
    }
    if (invoice_payment_mode && invoice_payment_mode.key == "credit") {
      param.append(
        "invoice_credit_days",
        this.form.get("invoice_credit_days").value
      );
    }
    param.append(
      "invoice_income_expanse_account_id",
      this.form.get("invoice_income_expanse_account_id").value
        ? this.form.get("invoice_income_expanse_account_id").value
        : ""
    );
    param.append(
      "invoice_to_account_id",
      this.form.get("invoice_to_account_id").value
        ? this.form.get("invoice_to_account_id").value
        : ""
    );
    param.append(
      "invoice_address_to",
      this.form.get("invoice_address_to").value
    );
    param.append(
      "invoice_vat_tax_id",
      this.form.get("invoice_vat_tax_id").value
    );
    param.append(
      "invoice_ref_number",
      this.form.get("invoice_ref_number").value
    );
    param.append(
      "invoice_instruction_remarks",
      this.form.get("invoice_instruction_remarks").value
    );
    param.append(
      "invoice_to_cost_center_id",
      this.form.get("invoice_to_cost_center_id").value
        ? this.form.get("invoice_to_cost_center_id").value
        : ""
    );
    //Items Data
    let items = this.form.get("items").value;
    items.forEach((v, k) => {
      param.append("items[" + k + "][invoice_item_name]", v.invoice_item_name);
      param.append("items[" + k + "][invoice_item_unit]", v.invoice_item_unit);
      param.append(
        "items[" + k + "][invoice_item_quantity]",
        v.invoice_item_quantity
      );
      param.append(
        "items[" + k + "][invoice_item_price]",
        v.invoice_item_price
      );
      param.append(
        "items[" + k + "][invoice_item_tax]",
        v.invoice_item_tax && v.invoice_item_tax.value
          ? v.invoice_item_tax.value
          : ""
      );
      param.append(
        "items[" + k + "][invoice_discount_percent]",
        v.invoice_discount_percent
      );
      param.append(
        "items[" + k + "][invoice_item_description]",
        v.invoice_item_description
      );
    });
    //Total Data
    param.append(
      "invoice_grand_total",
      this.form.get("invoice_grand_total").value
    );
    param.append(
      "invoice_global_discount_percent",
      this.form.get("invoice_global_discount_percent").value
    );
    param.append(
      "invoice_cash_advance_amount",
      this.form.get("invoice_cash_advance_amount").value
    );
    param.append(
      "invoice_global_tax_id",
      this.form.get("invoice_global_tax_id").value
        ? this.form.get("invoice_global_tax_id").value.value
        : ""
    );
    param.append(
      "invoice_global_tax_amount",
      this.form.get("invoice_global_tax_amount").value
    );
    param.append("invoice_net_total", this.form.get("invoice_net_total").value);
    param.append(
      "invoice_income_expanse_account_id",
      this.form.get("invoice_income_expanse_account_id").value
        ? this.form.get("invoice_income_expanse_account_id").value
        : ""
    );
    param.append(
      "invoice_income_expanse_cost_id",
      this.form.get("invoice_income_expanse_cost_id").value
        ? this.form.get("invoice_income_expanse_cost_id").value
        : ""
    );
    param.append(
      "invoice_tax_account_id",
      this.form.get("invoice_tax_account_id").value
        ? this.form.get("invoice_tax_account_id").value
        : ""
    );
    param.append(
      "invoice_tax_cost_center_id",
      this.form.get("invoice_tax_cost_center_id").value
        ? this.form.get("invoice_tax_cost_center_id").value
        : ""
    );
    param.append(
      "invoice_cash_advance_account_id",
      this.form.get("invoice_cash_advance_account_id").value
        ? this.form.get("invoice_cash_advance_account_id").value
        : ""
    );
    param.append(
      "invoice_cash_advance_cost_center_id",
      this.form.get("invoice_cash_advance_cost_center_id").value
        ? this.form.get("invoice_cash_advance_cost_center_id").value
        : ""
    );
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "usinvoice/" + this.invoice_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.form.reset();
            this.alert.success(res.msg);
            this.router.navigate([
              "accounts/vsinvoices/" +
                res.branch_id +
                "/" +
                res.invoice_id +
                "/view",
            ]);
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public searchCosts(key, account_master) {
    this.costs = [];
    let param = new FormData();
    param.append("branch_id", this.form.get("branch_id").value);
    param.append("account_id", account_master);
    param.append("search_text", key.term);
    if (this.form.get("branch_id").value && key.term) {
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "cctransactions").subscribe((res) => {
          if (res.status) {
            this.costs = res.records;
          }
        })
      );
    }
  }
  public searchAccounts(key, payment_mode, field) {
    this.accounts = [];
    let param = new FormData();
    param.append("branch_id", this.form.get("branch_id").value);
    param.append("search_text", key.term);
    param.append("invoice_type", "SINV");
    param.append("search_field", field);
    param.append("payment_mode", payment_mode);
    if (this.form.get("branch_id").value && key.term) {
      this.subscriptions.add(
        this.ds
          .postActionByUrl(param, "sam/accounts_edit")
          .subscribe((data) => {
            if (data.status) {
              this.accounts = data.records;
            }
          })
      );
    }
  }
  roundfunction(number) {
    if (number) {
      number = Number(number);
      number = Number(
        this._decimalPipe.transform(number, "1.0-2").replace(",", "")
      );
      if (
        Number(this._decimalPipe.transform(number, "1.0-1").replace(",", "")) >
        number
      ) {
        number = Number(
          this._decimalPipe.transform(number, "1.0-1").replace(",", "")
        );
      }
      number = parseFloat(number).toFixed(2);
    }
    return number;
  }
}
