 
	<div class="form-wrapper">
		<h3 class="mb-2">{{'lang_edit_overtime'|language}}</h3>
		<mat-divider></mat-divider>

		<form [formGroup]="form" class="form" (ngSubmit)="submitForm()">
			<div class="row mb-10">
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_employee_no"|language }}</div>
					<div class="mb-10">
						<b> {{this.allData?.form_ovr1_data?.records[0]?.employee_number}} </b>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_name"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.employee_name}} </b
						>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_salary"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.basic_salary}}</b
						>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_current_salary"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.net_amount}}</b
						>
					</div>
				</div>

				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_joining_date"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.joining_start_date_english}}</b
						>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
					<div class="form-group">
						<label> {{'lang_type' | language}} <span class="red">*</span></label>
						<input type="text" class="form-control almnabrformcontrol" readonly placeholder="{{'lang_type' | language}}" formControlName="financial_type">
					</div>
				</div>


				<div class="col-md-6 col-xs-12 col-sm-6 mb-10">
					<label class="mb-10">
						{{ "lang_direct_manager" | language }}
						<span class="red"> * </span> </label>
	
						<ng-select
						[items]="users"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						 
						appendTo="body"
						formControlName="direct_manager"
						placeholder="{{ 'lang_direct_manager' | language }}"
						(search)="search_users($event)"
						
						[searchFn]="ds.customSearchFn"
						 
					>
					</ng-select>
					<mat-error
						*ngIf="
						submitted &&
						form.controls['direct_manager'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				 
				</div>
				<!-- <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="form-group">
						<label> {{'lang_joining_type' | language}} <span class="red">*</span></label>
						<select class="form-control almnabrformcontrol" formControlName="joining_type">
							<option value="">{{'lang_joining_type' | language}}</option>
							<option *ngFor="let type of joining_type" value="{{type.value}}">{{type.label}}</option>
						</select>
						<mat-error *ngIf="submitted && form.controls['joining_type'].errors?.required">{{'lang_field_required' | language}}</mat-error>
					</div>
				</div>     -->                    
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									
								  
									<td>{{'lang_overTime_day' | language }}</td>
									<td>{{'lang_overTime_hours' | language }}</td>
									<td>{{'lang_overTime_amount' | language }}</td>
								  
									
									<td>{{'lang_action' | language }}</td>
								</tr>
							</thead>
							   <tbody formGroupName="createOverTimes" 
												   
						 
								class="AppendList Line">
								<tr   >
								
								   

									<td>
										<div class="col-md-12 col-sm-12 col-xs-12 mb-10">
											<div class="form-group top-5">
											 
												<div class="positionrelatiove">
													<input formControlName="overtime_date_english"  type="text" [max]="min" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event, form.get('createOverTimes').get('overtime_date_arabic'))" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
													<mat-datepicker-toggle matSuffix [for]="BDeng"  class="posiab"></mat-datepicker-toggle>
													<mat-datepicker #BDeng disabled="false"></mat-datepicker>
												  <!--   <mat-error *ngIf="CreateSubmitted && form.get('createOverTimes')['controls']['overtime_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
													<mat-error *ngIf="CreateSubmitted && form.get('createOverTimes')['controls']['overtime_date_english'].errors?.haveTheSameDate">{{form.controls['overtime_date_english'].errors?.haveTheSameDate | language}}</mat-error>
												--> </div>
											</div>
										</div>
									</td>
									
											  
									<td>
							  

										<div class="col-md-12 col-xs-6 col-sm-12 mb-10 mt-10">
										
					
											<span
											matTooltip="{{ form?.controls?.createOverTimes['controls']?.error_message?.value | language }}"
											*ngIf="form?.controls?.createOverTimes['controls']?.error_message?.value"
											>
											<input
											type="number"
											 
											(keypress)="isNumber($event)"
											class="form-control almnabrformcontrol"

										   

											formControlName="overtime_hours"
											[ngClass]="{ 'showCustomError': form?.controls?.createOverTimes['controls']?.error_message?.value  }"
										 />
											</span>

											<span
										   
											*ngIf="!form?.controls?.createOverTimes['controls']?.error_message?.value"
											>
											<input
											type="number"
									  
											(keypress)="isNumber($event)"
											class="form-control almnabrformcontrol"
											formControlName="overtime_hours"
											[ngClass]="{ 'showCustomError': form?.controls?.createOverTimes['controls']?.error_message?.value  }"
										 />
											</span>
											 
											<mat-error
												*ngIf="
												CreateSubmitted &&
												form?.controls?.createOverTimes['controls']?.amount?.errors?.required
												"
											>
												{{ "lang_field_required" | language }}
											</mat-error>
										 
										</div>
									</td>
									
									<td>
								   

										<div class="col-md-12 col-xs-12 col-sm-12 mb-10 mt-10">
										 
											----
								   
										 
										</div>
									</td>   

											
									<td class="dropdown">
									  
									 
										<button
										(click)="getThisMonthSalary()" 
											[disabled]="form?.controls?.createOverTimes.status == 'INVALID'"
											id="btnSearch"
											type="button"
											style="background: unset;"
											matTooltip="{{ 'lang_add' | language }}"
											class=" enablecode h34 btn btn-md btn-gray fa fa-plus blue-tooltip mnabricon"
										
										></button>
									</td>
								</tr>
							</tbody>
							<tbody  formArrayName="overTimes" 
						   
						 
							class="AppendList">
								<tr  *ngFor="let creds of form.controls.overTimes.value ; let i = index;trackBy:trackByFnCustom" [formGroupName]="i" >
								   
								   
									<td >
								   

										<div class="col-md-12 col-sm-12 col-xs-12 mb-10">
											<div class="form-group  top-5">
											  
												<div class="positionrelatiove">
													<input formControlName="overtime_date_english" type="text" [max]="min" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event, form.get('createOverTimes').get('overtime_date_arabic'))" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
													<mat-datepicker-toggle matSuffix [for]="BDeng"  class="posiab"></mat-datepicker-toggle>
													<mat-datepicker #BDeng disabled="true"></mat-datepicker>
													<mat-error *ngIf="submitted && form.controls['overtime_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
												</div>

												<mat-error
												*ngIf="
												submitted &&
												creds?.controls['controls'].overtime_date_english.errors?.required
												"
											>
												{{ "lang_field_required" | language }}
											</mat-error>
											</div>
										</div>

									 
									</td>
											  
									<td>
							  

										<div class="col-md-12 col-xs-6 col-sm-12 mb-10 mt-10">
										
					
												<input
												type="number"
												readonly
												class="form-control almnabrformcontrol"
												formControlName="overtime_hours"
											/>
											<mat-error
												*ngIf="
												submitted &&
												creds?.controls['controls'].overtime_hours.errors?.required
												"
											>
												{{ "lang_field_required" | language }}
											</mat-error>
										 
										</div>
									</td>
									<td>
								   

										<div class="col-md-12 col-xs-12 col-sm-12 mb-10 mt-10">
										 
					
												<input
												type="overtime_amount"
												readonly
												class="form-control almnabrformcontrol"
												formControlName="overtime_amount"
											/>
											<mat-error
												*ngIf="
												submitted &&
												creds?.controls['controls'].overtime_amount.errors?.required
												"
											>
												{{ "lang_field_required" | language }}
											</mat-error>
										 
										</div>
									</td>
											

											
									<td class="dropdown">
										<mat-icon
										matTooltip="{{ 'lang_delete' | language }}"
										style="color: red;"
										class="mnabricon" (click)="deleteCreds(i,creds)" >delete_forever</mat-icon>
									
									</td>
								</tr>
							</tbody>
						  <!--   <tbody *ngIf="wholddata.length == '0'" class="AppendList">
								<tr class="odd">
									<td colspan="8" align="center">{{errormessagef}}</td>
								</tr>
							</tbody> -->
						</table>
					</div>
				</div>
			</div>
		   
			<div class="row pb-3 pt-3">
				<div class="col-sm-8 col-12"></div>
				<div class="col-sm-4 col-12">
					<button mat-button type="submit" class="mnabrbutton mat-accent test" >
						Submit
					</button>
				</div>
			</div>
		</form>
	</div>
 






<!-- <div class="row pb-3 pt-3">
	<div class="col-sm-8 col-12"></div>
	<div class="col-sm-4 col-12">
		<button mat-button type="button" class="mnabrbutton mat-accent test" (click)="onSubmit()">
			Submit
		</button>
	</div>
</div> -->