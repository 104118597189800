<div mat-dialog-title class="d-flex justify-content-between">
	<h4 *ngIf="!edit_mode">Create Design Project</h4>
	<h4 *ngIf="edit_mode">Update Design Project</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="project_form">
		<div class="p-2">
			<div class="row mb-3">
				<div class="col-12 col-sm-6">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Project Name Ar</mat-label>
						<input
							matInput
							placeholder="Type Project Name Arabic"
							formControlName="quotation_title_arabic"
						/>
						<i class="fa fa-briefcase mnabricon" matSuffix></i>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Project Name En</mat-label>
						<input
							matInput
							placeholder="Type Project Name English"
							formControlName="quotation_title_english"
						/>
						<i class="fa fa-briefcase mnabricon" matSuffix></i>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Grand Total</mat-label>
						<input
							matInput
							placeholder="Type Grand Total"
							type="number"
							formControlName="grand_total"
						/>
						<i class="fa fa-dollar mnabricon" matSuffix></i>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_vat" | language }}</mat-label>
						<mat-select formControlName="tax_amount">
							<mat-option *ngFor="let vat of vat_list" [value]="vat.value">
								{{ vat.label }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-12 TableParts">
					<p class="mb-10">
						{{ "lang_attachments" | language }}
						<span class="red" *ngIf="attachments_form?.controls?.length"
							>*</span
						>
					</p>
					<div
						class="table-responsive customResponsive tablefields quotationitemtable"
					>
						<table
							border="1"
							class="table CustomTable"
							width="100%"
							formArrayName="attachments"
						>
							<thead>
								<tr>
									<td width="2%" style="padding: 5px; word-break: break-all">
										#
									</td>
									<td width="2%" style="padding: 5px; word-break: break-all">
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addAttachment()"
											>add_box</mat-icon
										>
									</td>
									<td width="32%" style="padding: 5px; word-break: break-all">
										Title Engilsh
									</td>
									<td width="32%" style="padding: 5px; word-break: break-all">
										Title Arabic
									</td>
									<td width="32%" style="padding: 5px; word-break: break-all">
										{{ "lang_file" | language }}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let attachment of attachments_form?.controls;
										let i = index
									"
									[formGroupName]="i"
								>
									<td
										class="verticalalignmid"
										style="padding: 5px; word-break: break-all"
									>
										{{ i + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											*ngIf="
												!attachments_form.controls[i].get('delete_icon').value
											"
											(click)="removeAttachment(i)"
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											>delete_forever</mat-icon
										>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											type="text"
											formControlName="title_english"
											placeholder="Title Engilsh"
											autocomplete="off"
											class="form-control almnabrformcontrol"
										/>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											type="text"
											formControlName="title_arabic"
											placeholder="Title Arabic"
											autocomplete="off"
											class="form-control almnabrformcontrol"
										/>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											name="file"
											type="file"
											required
											onclick="this.value = null"
											(change)="
												ds.uploadSingleFile(
													$event,
													attachments_array.controls[i].get('file'),
													'file' + i,
													filename
												)
											"
											class="nghide albutton width100"
										/>
										<label id="file{{ i }}" class="albutton">{{
											filename
										}}</label>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-0 col-sm-9"></div>
	<div class="col-12 col-sm-3">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onSubmit()"
			*ngIf="!edit_mode"
		>
			{{ "lang_submit" | language }}
		</button>
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onUpdateProject()"
			*ngIf="edit_mode"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
