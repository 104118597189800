import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

@Component({
  selector: "app-approve-transactions",
  templateUrl: "./approve-transactions.component.html",
  styleUrls: ["./approve-transactions.component.scss"],
  animations: [
    trigger("fadeIn", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      transition("void <=> *", animate(1000)),
    ]),
  ],
})
export class ApproveTransactionsComponent implements OnInit {
  modules: any = [];
  admin: any = JSON.parse(this.ls.getItem("is_admin"));
  selectedItems: any = [];
  listData: any = [];
  nodata: string = this.lang.transform("lang_loading");
  formlist: any = [];
  searchKey: string = localStorage.getItem("A_T_S_K") || "";
  searchByTypeOfApproval: any = localStorage.getItem("A_T_A") || "";
  searchByForm: any = localStorage.getItem("A_T_F")
    ? localStorage.getItem("A_T_F")
    : null;
  searchByModule: any = localStorage.getItem("A_T_M")
    ? localStorage.getItem("A_T_M").split(",")
    : null;
  pageno: any = localStorage.getItem("A_T_P") || 1;
  pagesize: any = localStorage.getItem("A_T_S") || "10";
  approval_only = 0;
  approve: FormGroup;
  transaction_request_id;
  transaction_request_description;
  transaction_key;
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.module_lists();
    this.form_lists();
    this.get_approve_lists("");
    this.approveform();
  }
  approveform() {
    this.approve = new FormGroup({
      transaction_request_id: new FormControl(this.transaction_request_id, [
        Validators.required,
      ]),
      user_pass: new FormControl("", [Validators.required]),
    });
  }
  empty_approve_form() {
    this.approve.reset();
    this.approve
      .get("transaction_request_id")
      .setValue(this.transaction_request_id);
  }
  approve_form() {
    this.spinner.show();
    this.approve
      .get("transaction_request_id")
      .setValue(this.transaction_request_id);
    this.ds
      .postActionByUrl(
        this.ds.json2formdata(this.approve.value),
        "form/" + this.transaction_key + "/cm"
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.ds.dialogf("", data.msg);
            this.approve.reset();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            this.ds.dialogf("", data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  public module_lists() {
    this.modules = [];
    this.ds.getActionByUrl([], "tc/getmodulesmydoclist").subscribe((res) => {
      this.spinner.hide();
      if (res.status) {
        this.modules = res.list;
      }
    });
  }
  public form_lists() {
    this.formlist = [];
    this.ds.getActionByUrl([], "tc/gettcmydoclist").subscribe((res) => {
      this.spinner.hide();
      if (res.status) {
        this.formlist = res.list;
      }
    });
  }
  public search_transactions() {
    this.pageno = 1;
    this.pagesize = 10;
    this.get_approve_lists("");
  }
  public loadPageFrm(page) {
    this.spinner.show();
    this.pageno = page;
    this.pagesize = this.pagesize;
    this.get_approve_lists("");
  }
  public loadFrmPagesize(size) {
    this.spinner.show();
    this.pageno = 1;
    this.pagesize = size;
    this.get_approve_lists("");
  }
  public get_approve_lists(approval_only) {
    if (approval_only == "approval_only") {
      if (this.approval_only == 0) {
        this.approval_only = 1;
      } else {
        this.approval_only = 0;
      }
      this.pageno = 1;
      this.pagesize = 10;
    }
    localStorage.setItem("A_T_S_K", this.searchKey || "");
    localStorage.setItem(
      "A_T_A",
      this.admin ? this.searchByTypeOfApproval : ""
    );
    localStorage.setItem("A_T_F", this.searchByForm || "");
    localStorage.setItem(
      "A_T_M",
      this.searchByModule &&
        this.searchByModule &&
        this.searchByModule.length > 0
        ? this.searchByModule
        : ""
    );
    localStorage.setItem("A_T_P", this.pageno);
    localStorage.setItem("A_T_S", this.pagesize);
    let param = new FormData();
    param.append("searchKey", this.searchKey);
    param.append("showReadyForApproval", `${this.approval_only}`);
    param.append("searchByForm", this.searchByForm || "");
    param.append("searchByTypeOfApproval", this.searchByTypeOfApproval || "");
    param.append(
      "searchByModule",
      this.searchByModule &&
        this.searchByModule != "null" &&
        this.searchByModule.length > 0
        ? this.searchByModule.toString()
        : ""
    );
    this.ds
      .getActionByUrl(
        this.ds.formData2string(param),
        "tc/myapprovaldoclist/" + this.pageno + "/" + this.pagesize
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.listData = res;
          } else {
            this.listData = [];
            this.nodata = res.error;
          }
        },
        (error) => {
          this.spinner.hide();
          this.listData = [];
          this.nodata =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
  }
}
