import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-ticket-contracts",
	templateUrl: "./ticket-contracts.component.html",
	styleUrls: ["./ticket-contracts.component.scss"],
})
export class TicketContractsComponent implements OnInit {
	contracts = [];
	pageno = 1;
	pageSize = "10";
	search_key = "";
	ticket_id: any;
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public router: Router,
		private dialogRef: MatDialogRef<TicketContractsComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private sweetAlert: SweetAlertService
	) {
		this.ticket_id = data?.ticket_id;
	}

	ngOnInit(): void {
		this.getContracts();
	}

	getContracts() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("search_word", this.search_key || "");
		this.ds
			.post(
				`tasks/get_data_project_design_contract/${this.pageno}/${this.pageSize}`,
				formData
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.contracts = res?.data;
					} else {
						this.contracts = [];
					}
				},
				(error) => {
					this.spinner.hide();
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}

	mergeTicketContract(contract_id) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.ticket_id || "");
		formData.append("contract_id", contract_id || "");
		this.ds.post("tasks/magre_ticket_to_contract", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						2000
					);
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(error) => {
				this.spinner.hide();
				this.ds.dialogf(
					"",
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}

	unMergeTicketContract(contract_id) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.ticket_id || "");
		formData.append("contract_id", contract_id || "");
		this.ds.post("tasks/unmagre_ticket_to_contract", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						2000
					);
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(error) => {
				this.spinner.hide();
				this.ds.dialogf(
					"",
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}
}
