import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import moment from "moment";
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-form-ect1-add",
  templateUrl: "./form-ect1-add.component.html",
  styleUrls: ["./form-ect1-add.component.scss"],
})
export class FormEct1AddComponent implements OnInit {
  users: any = [];
  history = [];
  pageNo = 1;
  total_records = 0;
  pageSize = "10";
  log_action = null;
  @Output() contractEvent = new EventEmitter();
  @Input() account_operation: string;
  @Input() employee_data;
  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
  @Input() branch_id;
  @Input() contract_type;
  @Input() contract_data;
  invoiceList: any = [];
  contract_types: any = [];
  lang_key = localStorage.getItem("lang_key") || "en";
  form: FormGroup;
  branches: any = [];
  contract_termination_list: any = [];
  submitted: boolean = false;
  dateRange: FormGroup;
  constructor(
    public spinner: PreloaderService,
    public ds: DataService,
    public alert: AlertService,
    public lang: LanguagePipe,
    public router: Router,
    private snackBar: MatSnackBar,
    private sweetAlert: SweetAlertService,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.dateRange = new FormGroup({
      log_action_start_date: new FormControl(null),
      log_action_end_date: new FormControl(null),
    });
    this.buildform();
    this.getAccountHistory();
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  show_contracts() {}
  public buildform() {
    this.form = new FormGroup({
      termination_case: new FormControl("", [Validators.required]),
      invoice_id: new FormControl(null, [Validators.required]),
      direct_manager: new FormControl(null, Validators.required),
      last_working_date: new FormControl(null, Validators.required),
    });
  }
  showMsg(msg: string, action: string, className: string) {
    this.snackBar.open(msg, action, {
      duration: 4000,
      panelClass: [className],
    });
  }
  public search_users(key) {
    if (key) {
      this.users = [];
      let param = new FormData();
      param.append("search", key.term);
      param.append("lang_key", this.lang_key);
      param.append("user_type_id", "1");
      this.ds
        .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
        .subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            this.users = res.list;
          }
        });
    }
  }
  get_contract_termination_cases() {
    let param = new FormData();
    param.append("employee_number", this.employee_data?.employee_number);
    param.append(
      "last_working_date_en",
      this.datePipe.transform(
        this.form.get("last_working_date").value,
        "yyyy/MM/dd"
      )
    );
    this.ds
      .getActionByUrl(
        this.ds.formData2string(param),
        "form/FORM_ECT1/get_contract_termination_cases"
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.contract_termination_list = res;
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.sweetAlert.errorToast(
              res?.error || this.lang.transform("lang_no_data"),
              2000
            );
            this.contract_termination_list = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
          this.invoiceList = [];
        }
      );
  }
  getAccountHistory() {
    let formData = new FormData();
    formData.append("log_action", this.log_action || "");
    formData.append("account_operation", this.account_operation || "");
    formData.append(
      "log_action_start_date",
      this.dateRange.get("log_action_start_date").value
        ? moment(this.dateRange.get("log_action_start_date").value).format(
            "YYYY/MM/DD"
          )
        : ""
    );
    formData.append(
      "log_action_end_date",
      this.dateRange.get("log_action_end_date").value
        ? moment(this.dateRange.get("log_action_end_date").value).format(
            "YYYY/MM/DD"
          )
        : ""
    );
    formData.append("id", this.id || "");
    this.spinner.show();
    this.ds
      .post(
        `get_accounts_history_log/${this.pageNo}/${this.pageSize}`,
        formData
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.history = res?.records;
            this.total_records = res?.page?.total_records;
          } else {
            this.history = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.history = [];
        }
      );
  }
  resetFilter() {
    this.dateRange.reset();
    this.log_action = null;
    this.getAccountHistory();
  }
  public create_end_contract() {
    let param = new FormData();
    param.set("direct_manager", this.form.get("direct_manager").value);
    param.set("employee_number", this.employee_data.employee_number || "");
    param.set(
      "contract_termination_case",
      this.form.get("termination_case").value || ""
    );
    param.set(
      "last_working_date_en",
      this.datePipe.transform(
        this.form.get("last_working_date").value,
        "yyyy/MM/dd"
      ) || ""
    );
    this.spinner.show();
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "form/FORM_ECT1/cr/0").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.alert.success(res.msg);
            this.router.navigate(["tasks/alltasks"]);
            this.form.reset();
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
}
