<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_add" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="TableParts" [formGroup]="snag_form">
        <div class="table-responsive customResponsive mb-10 tablefields">
            <table border="1" class="table CustomTable text-center">
                <thead>
                    <tr>
                        <td style="width: 5%;">#</td>
                        <td style="width: 5%;"><i class="fa fa-plus-square fa-icon" matTooltip="{{'lang_add' | language }}" (click)="add_sang_records();"></i></td>
                        <td style="width: 50%;">{{'lang_group_one' | language }} <i class="red">*</i></td>
                        <td style="width: 40%;">{{'lang_position' | language }} <i class="red">*</i></td>
                    </tr>
                </thead>
                <tbody formArrayName="groups" class="AppendList">
                    <tr *ngFor="let item of snag_formarray.controls; let itemIndex = index;let odd=odd; let even=even"
                        [formGroupName]="itemIndex">
                        <td class="verticalalignmid pad0">{{itemIndex+1}}</td>
                        <td class="verticalalignmid pad0"><i (click)="removeSang(itemIndex)" class="fa fa-minus-square fa-icon red mnabricon" matTooltip="{{'lang_delete' | language }}"></i></td>
                        <td class="pad0">
                            <ng-select 
                                [items]="group1_list" 
                                [multiple]="true" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_group' | language}} 1"
                                formControlName="platform_group1_code_system"
                                [searchFn]="ds.customSearchFn"
                                (search)="get_group1_list($event)">
                            </ng-select>
                            <mat-error *ngIf="snag_formarray.controls[itemIndex].get('platform_group1_code_system').touched && snag_formarray.controls[itemIndex].get('platform_group1_code_system').errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </td>
                        <td class="pad0">
                            <ng-select 
                                [items]="position_list" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_position' | language}} 1"
                                [searchFn]="ds.customSearchFn"
                                formControlName="sgroup_position_id">
                            </ng-select>
                            <mat-error *ngIf="snag_formarray.controls[itemIndex].get('sgroup_position_id').touched && snag_formarray.controls[itemIndex].get('sgroup_position_id').errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
    <div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
            (click)="add_snag()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
