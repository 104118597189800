import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function almnabrAttachment(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null;
		}

		const urlValid = value?.startsWith("https://drive.almnabr.com");

		return !urlValid ? { urlWrong: true } : null;
	};
}
