import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-payrole-list',
  templateUrl: './payrole-list.component.html',
  styleUrls: ['./payrole-list.component.scss']
})
export class PayroleListComponent implements OnInit {
  lang_key          = localStorage.getItem('lang_key') || 'en';
  payrole_list      = [];
  loading           = false;
  searchKey         = '';
  payrole_page_no   = 1;
  payrole_page_size = 10;
  private subscriptions = new Subscription()
  constructor(
    public dialog:MatDialog, public modalService: BsModalService,private ds:DataService,
    private _sweetAlertService:SweetAlertService, public lang:LanguagePipe,   private spinner: PreloaderService,
  ) { }

  ngOnInit(): void {
    this.getAllData()
  }

  load_by_page_no(page) {
    this.payrole_page_no = page;
    this.getAllData()
  }
  load_by_page_size(size) {
    this.payrole_page_size = size;
    this.payrole_page_no = 1
    this.getAllData()
  }


  total_records = 0
 
  public getAllData() {
    this.loading = true;
    this.payrole_list = [];
    this.subscriptions.add(
        this.ds.postActionByUrl([], `form/FORM_SAL/salary_history/${this.payrole_page_no}/${this.payrole_page_size}`).subscribe(data => {
          if (data.status) {
            this.payrole_list = data?.data;
            this.total_records = data?.page?.total_records
        
          }else{
            this.payrole_list = []
            this._sweetAlertService.errorToast(data.error,2000)
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.payrole_list = []
          this._sweetAlertService.errorToast(this.lang.transform('lang_internal_server_error'),2000)
        })			
      )

  }


  ngOnDestroy(): void {
	  this.subscriptions.unsubscribe();
	}
}
