 
<form class="form" [formGroup]="form">
	<section class="panel-expansion ckeditorhidetoolbar">
		<mat-accordion class="stylepanels">
			<mat-expansion-panel class="mpanel" [expanded]="true">
				<mat-expansion-panel-header class="mpanel-header">{{
					"lang_design_service_quotation" | language
					}}</mat-expansion-panel-header>
				<div class="mbrl15" [ngClass]="{ ' contentrtl': lang_key == 'ar' }">
 
					<mat-horizontal-stepper #stepper>
						<mat-step [stepControl]="form.controls.stepOne">
							<form formGroupName="stepOne">
								<div class="container p-0 m-0" style="max-width: 99%">
									<div class="row">
										<div class="col-12 col-sm-3">
											<h3 style="font-size: 20px">
												{{ "lang_customers" | language }}
											</h3>
											<p style="
													font-size: 14px;
													font-weight: 600;
													color: #0e3768;
												">
												{{ project_data?.customer_name }}
											</p>
										</div>
										<div class="d-none d-sm-block col-sm-6"></div>
										<div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{ "lang_languages" | language }}</mat-label>
												<mat-select formControlName="lang_key">
													<mat-option *ngFor="let language of languages"
														[value]="language.value">
														{{ language.label }}
													</mat-option>
												</mat-select>
												<mat-error *ngIf="
														form.controls['stepOne'].get('lang_key').touched &&
														form.controls['stepOne'].get('lang_key').errors
															?.required
													">
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{ "lang_subject" | language }}</mat-label>
												<input matInput formControlName="subject" required />
												<mat-error *ngIf="
														form.controls['stepOne'].get('subject').touched &&
														form.controls['stepOne'].get('subject').errors
															?.required
													">
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{ "lang_care" | language }}</mat-label>
												<input matInput formControlName="care" required />
												<mat-error *ngIf="
														form.controls['stepOne'].get('care').touched &&
														form.controls['stepOne'].get('care').errors
															?.required
													">
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>

										<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{ "lang_city" | language }}</mat-label>
												<input matInput formControlName="city" required />
												<mat-error *ngIf="
														form.controls['stepOne'].get('city').touched &&
														form.controls['stepOne'].get('city').errors
															?.required
													">
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{
													"lang_total_area_m2" | language
													}}</mat-label>
												<input matInput type="number" formControlName="total_area_m2"
													required />
												<mat-error *ngIf="
														form.controls['stepOne'].get('total_area_m2')
															.touched &&
														form.controls['stepOne'].get('total_area_m2').errors
															?.required
													">
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{
													"lang_duration_days" | language
													}}</mat-label>
												<input matInput type="number" formControlName="duration_days"
													required />
												<mat-error *ngIf="
														form.controls['stepOne'].get('duration_days')
															.touched &&
														form.controls['stepOne'].get('duration_days').errors
															?.required
													">
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
											<mat-form-field style="width: 100%" appearance="outline">
												<mat-label>{{
													"lang_main_occupancies" | translate
													}}</mat-label>
												<mat-select multiple formControlName="main_occupancies">
													<mat-option *ngFor="let ut of occupancies_list" [value]="ut.value">
														{{ ut.label }}
													</mat-option>
												</mat-select>
												<mat-error *ngIf="
														form.controls['stepOne'].get('main_occupancies')
															.touched &&
														form.controls['stepOne'].get('main_occupancies')
															.errors?.required
													">
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-12" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{
													"lang_description" | language
													}}</mat-label>
												<textarea matInput formControlName="description" required
													rows="5"></textarea>
												<mat-error *ngIf="
														form.controls['stepOne'].get('description')
															.touched &&
														form.controls['stepOne'].get('description').errors
															?.required
													">
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{ "lang_vat" | language }}</mat-label>
												<mat-select formControlName="vat_key">
													<mat-option *ngFor="let vat of vat_list" [value]="vat.value">
														{{ vat.label }}
													</mat-option>
												</mat-select>
												<mat-error *ngIf="
														form.controls['stepOne'].get('vat_key').touched &&
														form.controls['stepOne'].get('vat_key').errors
															?.required
													">
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>

										<div class="col-12 col-sm-6 d-flex justify-content-between mt-1">
											<mat-label style="margin: 15px 5px; font-size: 17px">{{
												"lang_quotation_type" | language }}
											</mat-label>
											<mat-radio-group aria-labelledby="example-radio-group-label"
												formControlName="quotation_type">
												<mat-radio-button *ngFor="let quotation_type of quotation_type_list"
													(change)="changeQout($event)" [value]="quotation_type.value">
													{{ quotation_type.label }}
												</mat-radio-button>
											</mat-radio-group>
										</div>
									</div>
									<div class="row pt-3">
										<div class="d-none d-sm-block col-sm-9"></div>
										<div class="col-12 col-sm-3 text-end">
											<button class="albutton mnabrbutton mat-accent customBTN" mat-button
												(click)="MATNext(stepper, 'first')">
												{{ "lang_continue" | language }}
												<i class="fa fa-arrow-right" style="font-size: 14px"
													*ngIf="lang_key == 'en'"></i>
												<i class="fa fa-arrow-left" style="font-size: 14px"
													*ngIf="lang_key == 'ar'"></i>
											</button>
										</div>
									</div>
								</div>
							</form>
						</mat-step>
						<mat-step [stepControl]="form.controls.stepOne">
							<form formGroupName="stepTwo">
								<div class="container p-0 m-0" style="max-width: 99%">
 

									<div [ngClass]="{
										notShowen:
											form.controls['stepOne'].get('quotation_type').value !=
											'open'
									}" id="stepperOpen" class="bs-stepper">
									<div class="bs-stepper-header mx-5">
										<div class="step" data-target="#test-l-1">
											<div class="d-block" matTooltip="{{
													'lang_terms' | language
												}}">
												<button class="step-trigger" (click)="setActiveOpen(1)">
													<span class="bs-stepper-circle" [ngClass]="{
															activeNow: currentActiveStepOpen == 1,
															FormValid:
																  currentActiveStepOpen != 1
														}">1</span>
													<div class="custom">
 
													</div>
												</button>
											</div>
										</div>
										 
										<div class="line step-1" [ngClass]="{
												activeNow: currentActiveStepOpen == 1,
												FormValid:
												 currentActiveStepOpen != 1
											}"></div>

										<div class="step" data-target="#test-l-2">
											<div class="d-block" matTooltip="{{ 'lang_financial_managements' | language }}">
												<button class="step-trigger" (click)="setActiveOpen(2)"  >
													<span class="bs-stepper-circle" [ngClass]="{
															activeNow: currentActiveStepOpen == 2,
															FormValid:
															getSmallStepsOpen.quotation_price.status == 'VALID' &&
															currentActiveStepOpen != 2
														}">2</span>
													<div class="custom">
													
													</div>
												</button>
												
											</div>
										</div>
										<div class="line step-3" [ngClass]="{
												activeNow: currentActiveStepOpen == 2,
												FormValid:
												getSmallStepsOpen.quotation_price.status ==
														'VALID' && currentActiveStepOpen != 2
											}"></div>


											<div class="step" data-target="#test-l-3">
												<div class="d-block" (click)="setActiveOpen(3)" matTooltip="{{ 'lang_financial_managements' | language }}">
													<button class="step-trigger"  
													[disabled]="
												getSmallStepsOpen.quotation_price.status ==
														'INVALID'
													"
													>
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStepOpen == 3,
																FormValid:
																getSmallStepsOpen.financial_managments.status == 'VALID' &&
																currentActiveStepOpen != 3
															}">3</span>
														<div class="custom">
														
														</div>
													</button>
													
												</div>
											</div>
											<div class="line step-4" [ngClass]="{
													activeNow: currentActiveStepOpen == 3,
													FormValid:
													getSmallStepsOpen.financial_managments.status ==
															'VALID' && currentActiveStepOpen != 3
												}"></div>

												<div class="step" data-target="#test-l-4">
													<div class="d-block" (click)="setActiveOpen(4)" matTooltip="{{ 'lang_signatures' | language }}">
														<button class="step-trigger"  [disabled]="
														getSmallStepsOpen.financial_managments.status ==
																'INVALID'
															">
															<span class="bs-stepper-circle" [ngClass]="{
																	activeNow: currentActiveStepOpen == 4,
																	FormValid:
																	signature_form?.status == 'VALID' &&
																	currentActiveStepOpen != 4
																}">4</span>
															<div class="custom">
																
															</div>
														</button>
														
													</div>
												</div>
									</div>

									<div class="row width-100 text-start mx-4 mt-3 fontOneRem">
										<div class="col-md-9 p-0">
											<h4 *ngIf="currentActiveStepOpen == 1">
												{{ "lang_terms" | language }}
											</h4>
										 
											<h4 *ngIf="currentActiveStepOpen == 2">
												{{ "lang_quotation_price" | language }}
											</h4>
											<h4 *ngIf="currentActiveStepOpen == 3">
												{{ "lang_financial_managments" | language }}
											</h4>
											<h4 *ngIf="currentActiveStepOpen == 4">
												{{ "lang_signatures" | language }}
											</h4>
										</div>

										<div class="col-md-3 p-0">
											<p class="step_num">
												{{'lang_step'|language}} {{ currentActiveStepOpen }} {{'of'|language}} 4
											</p>
										</div>
									</div>
									<div class="width-100 text-center mx-4 d-flex justify-content-between">
										<button (click)="back_open()" type="button"
											class="albutton mnabrbutton mat-accent mobileBTN" mat-button>
											{{ "lang_previous" | language }}
										</button>

										<button (click)="next_open()" type="button"
											class="albutton mnabrbutton mat-accent mobileBTN" mat-button>
											{{ "lang_next" | language }}
										</button>
									</div>
									<div class="bs-stepper-content mt-1">
										<div id="test-l-1" class="content">
											<form  >
												<div class="form-group">
												 
													<div class="row mb-2" *ngIf="dynamiccontents">
														<div class="col-12">
															<fieldset class="cardback fieldset">
																<legend>{{ "lang_general_terms_conditions" | language }}</legend>
																<div
																	class="contentpanel"
																	[ngClass]="{ 'contentrtl': lang_key == 'ar' }"
																	#contentData
																></div>
															</fieldset>
														</div>
													</div>

													 


													<div class="row mb-2" *ngIf="dynamiccontents_two">
														<div class="col-12">
															<fieldset class="cardback fieldset">
																<legend>{{ "lang_technical_terms_conditions" | language }}</legend>
																<div
																	class="contentpanel"
																	[ngClass]="{ 'contentrtl': lang_key == 'ar' }"
																	#contentData_two
																></div>
															</fieldset>
														</div>
													</div>
												</div>
											</form>

								
										</div>
									 
										<div id="test-l-2" class="content text-center">
											<form [formGroup]="getSmallStepsOpen.quotation_price">
												<div class="row">
													 
													 


											  


													
												<div class="col-12 col-sm-6"
												[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
												<mat-form-field style="width: 100%"
													appearance="outline">
													<mat-label>{{
														"lang_price_amount_byhand"
														| language
														}}</mat-label>

													<input matInput
														(keypress)="isNumber($event)"
														type="number"
														(keyup)="putValueInByHandOpen()"
														formControlName="price_amount_byhand"
														disabled required />
													<mat-error *ngIf="
													getstandard_stepsOpen.controls[
																'quotation_price'
															]['controls'].price_amount_byhand.touched &&
															getstandard_stepsOpen.controls[
															'quotation_price'
														]['controls'].price_amount_byhand.errors
																?.required
														">
														{{ "lang_field_required" | language
														}}
													</mat-error>
												</mat-form-field>
												</div>
												</div>


												<div class="row p-1">
													<div class="col-md-12">
														<div class="table-responsive dropdownyes customResponsive">
															<table
																border="1"
																class="table CustomTable"
															>
																<thead>
																	<tr>
																		<td>{{ "lang_quotation_prices_type" | language }}</td>
																		<td>{{ "lang_quotation_prices_amount" | language }}</td>
									
																		<td>{{ "lang_quotation_prices_profit_percentage" | language }}</td>
																		<td>{{ "lang_quotation_prices_profit_percentage_amount" | language }}</td>
																		<td>{{ "lang_quotation_prices_total_amount" | language }}</td>
																		 
																	</tr>
																</thead>
																<tbody class="AppendList"  >
																	<tr
																	formArrayName="quotation_prices"
																		*ngFor="
																			let item of getSmallStepsOpen?.quotation_price?.value?.quotation_prices;
																			let i = index;
																			let odd = odd;
																			let even = even;
																			trackBy: trackByFnCustom
																		"
																		[ngClass]="{ odd: odd, even: even }"

																		
																	>
																	<ng-container [formGroupName]="i">
																		<td>
																			{{'lang_'+item.quotation_prices_type|translate }}
																		</td>
																		<td>
																			{{item.quotation_prices_amount |number}}
																		</td>
																		<td>
																			<div class="col-12 col-sm-12"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																		<mat-form-field style="width: 100%"
																			appearance="outline">


																			<input matInput
																				(keypress)="isNumber($event)"

																				(keyup)="updateQuotationPricesValuesByPercent(
																					getstandard_stepsOpen?.controls['quotation_price']['controls']?.quotation_prices[
																				'controls'][i])"
																				type="number"
																				formControlName="quotation_prices_profit_percentage"
																				required />

																			<mat-error *ngIf="
																			getstandard_stepsOpen?.controls[
																						'quotation_price'
																					]['controls']?.quotation_prices[
																						'controls'
																					][i]['controls']?.quotation_prices_profit_percentage?.touched &&
																					getstandard_stepsOpen?.controls[
																						'quotation_price'
																					]['controls']?.quotation_prices[
																						'controls'
																					][i]['controls']
																						?.quotation_prices_profit_percentage?.errors
																						?.required
																				">
																				{{ "lang_field_required" | language
																				}}
																			</mat-error>

																			<mat-error *ngIf="
																			getstandard_stepsOpen?.controls[
																						'quotation_price'
																					]['controls']?.quotation_prices[
																						'controls'
																					][i]['controls']?.quotation_prices_profit_percentage?.touched &&
																					getstandard_stepsOpen?.controls[
																						'quotation_price'
																					]['controls']?.quotation_prices[
																						'controls'
																					][i]['controls']
																						?.quotation_prices_profit_percentage?.errors
																						?.max
																				">
																				{{ "lang_max_val_100" | language
																				}}
																			</mat-error>
																		</mat-form-field>
																			</div>
																		</td>
																	
																		<td>
																			{{item.quotation_prices_profit_percentage_amount |number}}
																		</td>
																		<td>
																			{{item.quotation_prices_total_amount |number}}
																		</td>
									
																	</ng-container>
 
																	</tr>
																</tbody>
															</table>
															 
														</div>
													</div>
												</div>






											</form>
										</div>
										<div id="test-l-3" class="content text-center">
											<form [formGroup]="getSmallStepsOpen.financial_managments">
												<div class="col-12 col-sm-12 mt-1 mb-3 text-start">
													<!-- <div class="d-flex justify-content-between">
													
													</div> -->

													<mat-label style="margin: 15px 5px; font-size: 17px">{{
														"lang_financial_quot_price_type" | language }}
													</mat-label>
													<mat-radio-group aria-labelledby="example-radio-group-label"
														formControlName="financial_quot_price_type">
														<mat-radio-button
															*ngFor="let fqpt of getSmallStepsOpen?.quotation_price?.controls?.quotation_prices.value"
															(change)="make_finacial_qout_calcs_open($event)"
															[value]="fqpt.quotation_prices_type">
															{{ 'lang_'+fqpt.quotation_prices_type |language}}
														</mat-radio-button>
													</mat-radio-group>

													<mat-error *ngIf="
													getSmallStepsOpen.financial_managments.get(
																'financial_quot_price_type'
															).touched &&
															getSmallStepsOpen.financial_managments.get(
																'financial_quot_price_type'
															).errors?.required
														">
														{{ "lang_field_required" | language }}
													</mat-error>
												</div>

												<ng-container *ngIf="
												getSmallStepsOpen.financial_managments.value
															?.financial_quot_price_type
													">

													<div class="row">
													<div class="col-12 col-md-6 mb-3">
														<div class="card-item" style="background: linear-gradient(60deg, #2196f3, #3f51b5);">
															<div class="item-icon">
																<mat-icon aria-hidden="false" aria-label="attach_money">attach_money</mat-icon>
															</div>
															<div class="item-text">
																<h5>{{'lang_total_without_vat'|language}}</h5>
																<h4>{{getSmallStepsOpen?.financial_managments?.value?.total_without_vat| number}}</h4>
															</div>
														</div>
													</div>
													<div class="col-12 col-md-6 mb-3">
														<div class="card-item" style="background: linear-gradient(60deg, #26dad2, #00c174);">
															<div class="item-icon">
																<mat-icon aria-hidden="false" aria-label="attach_money">attach_money</mat-icon>
															</div>
															<div class="item-text">
																<h5>{{'lang_total_vat'|language}}</h5>
																<h4>{{getSmallStepsOpen?.financial_managments?.value?.total_vat| number}} </h4>
															</div>
														</div>
													</div>


													<div class="col-12 col-md-6 mb-3">
														<div class="card-item" style="background: linear-gradient(60deg, #ef7067, #ed3b78);">
															<div class="item-icon">
																<mat-icon aria-hidden="false" aria-label="attach_money">attach_money</mat-icon>
															</div>
															<div class="item-text">
																<h5>{{'lang_total_all'|language}}</h5>
																<h4>{{getSmallStepsOpen?.financial_managments?.value?.total_all| number}} </h4>
															</div>
														</div>
													</div>
													</div>

													<div class="form-group">
														<a (click)="addFinancialManagmentsOpen();dynamicValidationOnInput(getstandard_stepsOpen)" class="btn customButton mb-4">
															{{ "lang_add_financial_payments" | language }}
														</a>
														<table class="table table-hover ">
															<thead>
															  <tr>
															  <th scope="col">#</th>
															  <th scope="col">
																{{
																  "lang_payment_title_english"
																  | language
																  }}
															  </th>
															  <th scope="col">
																{{
																  "lang_payment_title_arabic"
																  | language
																  }}
															  </th>
															  <th scope="col">
																{{
																  "lang_payment_stage"
																  | language
																  }}
															  </th>
															  <th scope="col">
																{{
																  "lang_payment_stage_level"
																  | language
																  }}
															  </th>
							 
															  <th scope="col">
																{{
																  "lang_action"
																  | language
																  }}
															  </th>
															  </tr>
															</thead>
															<tbody>
															  <ng-container formArrayName="financial_payments"  *ngFor="
															  let creds of getSmallStepsOpen.financial_managments?.value
															  .financial_payments;
															let i = index;
															trackBy: trackByFnCustom
															 ">
							  
																<tr [formGroupName]="i" >
																  <th scope="row">{{i+1}}</th>
																  <td>
																	<div class="col-12 col-sm-12"
																	[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																	<mat-form-field style="width: 100%"
																	  appearance="outline">
																	  <!-- <mat-label>{{
																		"lang_printing_title_english"
																		| language
																		}}</mat-label> -->
							  
																		<input matInput type="text"
																				 
																		formControlName="payment_title_english"
																		required />
																	<mat-error *ngIf="
																	getstandard_stepsOpen.controls[
																				'financial_managments'
																			]['controls'].financial_payments[
																				'controls'
																			][i]['controls']
																				?.payment_title_english
																				.touched &&
																				getstandard_stepsOpen.controls[
																				'financial_managments'
																			]['controls'].financial_payments[
																				'controls'
																			][i]['controls']
																				?.payment_title_english
																				.errors?.required
																		">
																		{{ "lang_field_required" | language
																		}}
																	</mat-error>
																	</mat-form-field>
																  </div>
																  </td>
																  <td>
																	<div class="col-12 col-sm-12"
																	[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																	<mat-form-field style="width: 100%"
																	  appearance="outline">
																	 <!--  <mat-label>{{
																		"lang_printing_title_arabic"
																		| language
																		}}</mat-label> -->
							  
																		<input matInput type="text"
																		formControlName="payment_title_arabic"
																		required />
																	<mat-error *ngIf="
																	getstandard_stepsOpen.controls[
																					'financial_managments'
																				]['controls'].financial_payments[
																					'controls'
																				][i]['controls']
																				?.payment_title_arabic
																				.touched &&
																				getstandard_stepsOpen.controls[
																				'financial_managments'
																			]['controls'].financial_payments[
																				'controls'
																			][i]['controls']
																				?.payment_title_arabic
																				.errors?.required
																		">
																		{{ "lang_field_required" | language
																		}}
																	</mat-error>
																	</mat-form-field>
																  </div>
																  </td>
																  <td>
																	<div class="col-12 col-sm-12"
																	[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																	<mat-form-field style="width: 100%"
																	  appearance="outline">
																	 <!--  <mat-label>{{
																		"lang_printing_costs_no_of_copies"
																		| language
																		}}</mat-label> -->
							  
																		<mat-select 
																		(selectionChange)="dynamicValidationOnInput(getstandard_stepsOpen)"
																		formControlName="payment_stage">
																		<mat-option *ngFor="let ut of stages"
																		[disabled]="ut.disabled"	[value]="ut.value">
																			{{ ut.label }}
																		</mat-option>
																		</mat-select>
																					<mat-error *ngIf="
																					getstandard_stepsOpen.controls[
																					'financial_managments'
																				]['controls'].financial_payments[
																					'controls'
																				][i]['controls']
																				?.payment_stage
																				.touched &&
																				getstandard_stepsOpen.controls[
																				'financial_managments'
																			]['controls'].financial_payments[
																				'controls'
																			][i]['controls']
																				?.payment_stage
																				.errors?.required
																		">
																			{{ "lang_field_required" | language }}
																		</mat-error>
																	</mat-form-field>
																  </div>
																  </td>
																  <td>
																	<div class="col-12 col-sm-12"
																	[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																	<mat-form-field style="width: 100%"
																	  appearance="outline">
																	  <!-- <mat-label>{{
																		"lang_printing_costs_no_of_panels"
																		| language
																		}}</mat-label> -->
							  
																		<input matInput
																		(keypress)="isNumber($event)"
																		(keyup)="validatePaymentLevel(getstandard_stepsOpen.controls[
																		'financial_managments'
																	]['controls'].financial_payments)"
																		type="number"
																		formControlName="payment_stage_level"
																		required />

																	<mat-error *ngIf="
																	getstandard_stepsOpen.controls[
																					'financial_managments'
																				]['controls'].financial_payments[
																					'controls'
																				][i]['controls']
																				?.payment_stage_level.touched &&
																				getstandard_stepsOpen.controls[
																				'financial_managments'
																			]['controls'].financial_payments[
																				'controls'
																			][i]['controls']
																				?.payment_stage_level.errors
																				?.required
																		">
																		{{ "lang_field_required" | language
																		}}
																	</mat-error>


																	<mat-error *ngIf="
																	getstandard_stepsOpen.controls[
																					'financial_managments'
																				]['controls'].financial_payments[
																					'controls'
																				][i]['controls']
																				?.payment_stage_level.touched &&
																				getstandard_stepsOpen.controls[
																				'financial_managments'
																			]['controls'].financial_payments[
																				'controls'
																			][i]['controls']
																				?.payment_stage_level.errors
																				?.MustBy_100
																		">
																		{{ "lang_field_must_be_total_100" | language
																		}}
																	</mat-error>
																	</mat-form-field>
																  </div>
																  </td>
															   
																  <td>
																	<a class="danger" *ngIf="i != 0"
																			(click)="deleteFinancialPaymentsOpen(i);dynamicValidationOnInput(getstandard_stepsOpen)"
																			matTooltip="{{
																				'lang_delete_financial_payments' | language
																			}}">
																			<i
																				class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																		</a>
																  </td>
																  </tr>
															  </ng-container>
															 
															  
															</tbody>
															</table>

														<!-- <div formArrayName="financial_payments" class="mb-3" *ngFor="
																let creds of getSmallStepsOpen.financial_managments?.value
																	.financial_payments;
																let i = index;
																trackBy: trackByFnCustom
															">
															<div [formGroupName]="i"
																[ngClass]="{ borderTop: i != 0 }">
																<div class="row">
																	<div class="form-group col-md-6 mb-1 text-start" >
																		<h5 class="head">
																			{{ "lang_financial_payments_no" | language }}.
																			{{ i + 1 }}
																		</h5>
																	</div>
																	<div class="form-group col-md-6 mb-1"
																		style="text-align: right">
																		<a class="danger" *ngIf="i != 0"
																			(click)="deleteFinancialPaymentsOpen(i);dynamicValidationOnInput(getstandard_stepsOpen)"
																			matTooltip="{{
																				'lang_delete_financial_payments' | language
																			}}">
																			<i
																				class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																		</a>
																	</div>
																	<div class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																		<mat-form-field style="width: 100%"
																			appearance="outline">
																			<mat-label>{{
																				"lang_payment_title_english"
																				| language
																				}}</mat-label>

																			<input matInput type="text"
																				 
																				formControlName="payment_title_english"
																				required />
																			<mat-error *ngIf="
																			getstandard_stepsOpen.controls[
																						'financial_managments'
																					]['controls'].financial_payments[
																						'controls'
																					][i]['controls']
																						?.payment_title_english
																						.touched &&
																						getstandard_stepsOpen.controls[
																						'financial_managments'
																					]['controls'].financial_payments[
																						'controls'
																					][i]['controls']
																						?.payment_title_english
																						.errors?.required
																				">
																				{{ "lang_field_required" | language
																				}}
																			</mat-error>
																		</mat-form-field>
																	</div>

																	<div class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																		<mat-form-field style="width: 100%"
																			appearance="outline">
																			<mat-label>{{
																				"lang_payment_title_arabic"
																				| language
																				}}</mat-label>

																			<input matInput type="text"
																				formControlName="payment_title_arabic"
																				required />
																			<mat-error *ngIf="
																			getstandard_stepsOpen.controls[
																							'financial_managments'
																						]['controls'].financial_payments[
																							'controls'
																						][i]['controls']
																						?.payment_title_arabic
																						.touched &&
																						getstandard_stepsOpen.controls[
																						'financial_managments'
																					]['controls'].financial_payments[
																						'controls'
																					][i]['controls']
																						?.payment_title_arabic
																						.errors?.required
																				">
																				{{ "lang_field_required" | language
																				}}
																			</mat-error>
																		</mat-form-field>
																	</div>



																	<div class="col-12 col-sm-3"
																	[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																	<mat-form-field style="width: 100%"
																	appearance="outline">
																	<mat-label>{{
																		"lang_payment_stage" | language
																		}}</mat-label>
																	<mat-select 
																		(selectionChange)="dynamicValidationOnInput(getstandard_stepsOpen)"
																		formControlName="payment_stage">
																		<mat-option *ngFor="let ut of stages"
																		[disabled]="ut.disabled"	[value]="ut.value">
																			{{ ut.label }}
																		</mat-option>
																		</mat-select>
																					<mat-error *ngIf="
																					getstandard_stepsOpen.controls[
																					'financial_managments'
																				]['controls'].financial_payments[
																					'controls'
																				][i]['controls']
																				?.payment_stage
																				.touched &&
																				getstandard_stepsOpen.controls[
																				'financial_managments'
																			]['controls'].financial_payments[
																				'controls'
																			][i]['controls']
																				?.payment_stage
																				.errors?.required
																		">
																			{{ "lang_field_required" | language }}
																		</mat-error>
																	</mat-form-field>
																</div>
																	<div class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																		<mat-form-field style="width: 100%"
																			appearance="outline">
																			<mat-label>{{
																				"lang_payment_stage_level" |
																				language
																				}}</mat-label>

																			<input matInput
																				(keypress)="isNumber($event)"
																				(keyup)="validatePaymentLevel(getstandard_stepsOpen.controls[
																				'financial_managments'
																			]['controls'].financial_payments)"
																				type="number"
																				formControlName="payment_stage_level"
																				required />

																			<mat-error *ngIf="
																			getstandard_stepsOpen.controls[
																							'financial_managments'
																						]['controls'].financial_payments[
																							'controls'
																						][i]['controls']
																						?.payment_stage_level.touched &&
																						getstandard_stepsOpen.controls[
																						'financial_managments'
																					]['controls'].financial_payments[
																						'controls'
																					][i]['controls']
																						?.payment_stage_level.errors
																						?.required
																				">
																				{{ "lang_field_required" | language
																				}}
																			</mat-error>


																			<mat-error *ngIf="
																			getstandard_stepsOpen.controls[
																							'financial_managments'
																						]['controls'].financial_payments[
																							'controls'
																						][i]['controls']
																						?.payment_stage_level.touched &&
																						getstandard_stepsOpen.controls[
																						'financial_managments'
																					]['controls'].financial_payments[
																						'controls'
																					][i]['controls']
																						?.payment_stage_level.errors
																						?.MustBy_100
																				">
																				{{ "lang_field_must_be_total_100" | language
																				}}
																			</mat-error>
																		</mat-form-field>
																	</div>

																 
																</div>
																<hr width="70%" class="m-auto" />
															</div>
														</div> -->
													</div>
												</ng-container>
											</form>
										</div>
										<div id="test-l-4" class="content text-center">
											<form [formGroup]="signature_form">
											
												<div class="row mb-2">
													<div class="col-12">
														<fieldset class="cardback fieldset">
															<legend style="text-align: start;">{{ "lang_optional" | language }}</legend>
															<section class="example-section text-start" >
																<mat-checkbox
																	*ngFor="let d of restrictions_fixed_optional_items"
																	class="inlinecheckbox"
																	[checked]="d.checked"
																	(change)="d.checked = !d.checked"
																	>{{ d.label }}</mat-checkbox
																>
															</section>
														</fieldset>
													</div>
												</div>
												<input type="hidden" formControlName="otherTerms" />
												<div class="row mb-2" *ngIf="othersstatus">
													<div class="col-md-12 col-sm-12 col-xs-12 mb-2">
														<fieldset class="cardback fieldset">
															<legend style="text-align: start;">
																{{ "lang_other_terms" | language }}
																<span class="red"
																	>*
																	<mat-icon
																		class="red mnabricon"
																		matTooltip="{{ 'lang_close' | language }}"
																		(click)="disable_field('others')"
																		>close</mat-icon
																	></span
																>
															</legend>
															<div class="table-responsive customResponsive tablefields">
																<table
																	border="1"
																	class="table CustomTable"
																	width="100%"
																	formArrayName="others"
																>
																	<thead>
																		<tr>
																			<td style="padding: 5px; word-break: break-all">
																				<mat-icon
																					class="mnabricon"
																					matTooltip="{{ 'lang_add' | language }}"
																					(click)="add_new_others()"
																					>add_box</mat-icon
																				>
																			</td>
																			<td style="padding: 5px; word-break: break-all">
																				{{ "lang_title_english" | language }}
																			</td>
																			<td style="padding: 5px; word-break: break-all">
																				{{ "lang_title_arabic" | language }}
																			</td>
																		</tr>
																	</thead>
																	<tbody>
																		<tr
																			*ngFor="
																				let otr of othersgroup.controls;
																				let i = index;
																				let odd = odd;
																				let even = even
																			"
																			[ngClass]="{ odd: odd, even: even }"
																			[formGroupName]="i"
																			style="background: #ffffff"
																		>
																			<td width="1%" class="verticalalignmid">
																				<mat-icon
																					class="mnabricon red"
																					matTooltip="{{ 'lang_delete' | language }}"
																					(click)="remove_other_items(i)"
																					>close</mat-icon
																				>
																			</td>
																			<td
																				style="padding: 5px; word-break: break-all"
																				style="position: relative"
																			>
																				<input
																					type="text"
																					formControlName="qror_name_en"
																					placeholder="{{ 'lang_title_english' | language }}"
																					autocomplete="off"
																					class="form-control almnabrformcontrol"
																				/>
																				<mat-error
																					*ngIf="
																						submitted &&
																						others_fields.controls[i].get('qror_name_en').errors
																							?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</td>
																			<td
																				style="padding: 5px; word-break: break-all"
																				style="position: relative"
																			>
																				<input
																					type="text"
																					formControlName="qror_name_ar"
																					placeholder="{{ 'lang_title_arabic' | language }}"
																					autocomplete="off"
																					class="form-control almnabrformcontrol"
																				/>
																				<mat-error
																					*ngIf="
																						submitted &&
																						others_fields.controls[i].get('qror_name_ar').errors
																							?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</fieldset>
													</div>
												</div>
												<div class="row mb-2">
													<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!markstatus">
														<button
															type="button"
															class="albutton mnabrbutton mat-accent"
															mat-button
															(click)="enable_field('mark')"
														>
															<i class="fa fa-plus"></i> {{ "lang_need_mark" | language }}
														</button>
													</div>
													<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!reviewstatus">
														<button
															type="button"
															class="albutton mnabrbutton mat-accent"
															mat-button
															(click)="enable_field('review')"
														>
															<i class="fa fa-plus"></i> {{ "lang_need_review" | language }}
														</button>
													</div>
													<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!viewerstatus">
														<button
															type="button"
															class="albutton mnabrbutton mat-accent"
															mat-button
															(click)="enable_field('viewer')"
														>
															<i class="fa fa-plus"></i> {{ "lang_need_viewer" | language }}
														</button>
													</div>
										
													<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!othersstatus">
														<button
															type="button"
															class="albutton mnabrbutton mat-accent"
															mat-button
															(click)="enable_field('others')"
														>
															<i class="fa fa-plus"></i> {{ "lang_others" | language }}
														</button>
													</div>
												</div>
												<div class="row mb-2">
													<div class="col-12">
														<fieldset class="cardback fieldset">
															<legend  style="text-align: start;">{{ "lang_signature_stamp" | language }}</legend>
															<div class="row">
																<div class="col-sm-6 col-md-4 col-xs-12">
																	<div class="form-group">
																		<label>
																			{{ "lang_human_resources_job_title_iqama" | language }} A
																		</label>
																		<input
																			type="text"
																			formControlName="signature[A][title]"
																			placeholder="{{
																				'lang_human_resources_job_title_iqama' | language
																			}} A"
																			class="form-control almnabrformcontrol"
																		/>
																		<mat-error
																			*ngIf="
																				submitted &&
																				signature_form.controls['signature[A][title]'].errors?.required
																			"
																		>
																			{{ "lang_field_required" | language }}
																		</mat-error>
																	</div>
																</div>
																<div class="col-sm-6 col-md-4 col-xs-12">
																	<div class="form-group">
																		<label>
																			{{ "lang_human_resources_job_title_iqama" | language }} B
																		</label>
																		<input
																			type="text"
																			formControlName="signature[B][title]"
																			placeholder="{{
																				'lang_human_resources_job_title_iqama' | language
																			}} B"
																			class="form-control almnabrformcontrol"
																		/>
																		<mat-error
																			*ngIf="
																				submitted &&
																				signature_form.controls['signature[B][title]'].errors?.required
																			"
																			>{{ "lang_field_required" | language }}</mat-error
																		>
																	</div>
																</div>
																<div class="col-sm-6 col-md-4 col-xs-12">
																	<div class="form-group">
																		<label>
																			{{ "lang_human_resources_job_title_iqama" | language }} C
																		</label>
																		<input
																			type="text"
																			formControlName="signature[C][title]"
																			placeholder="{{
																				'lang_human_resources_job_title_iqama' | language
																			}} C"
																			class="form-control almnabrformcontrol"
																		/>
																		<mat-error
																			*ngIf="
																				submitted &&
																				signature_form.controls['signature[C][title]'].errors?.required
																			"
																			>{{ "lang_field_required" | language }}</mat-error
																		>
																	</div>
																</div>
																<div class="col-sm-6 col-md-4 col-xs-12">
																	<div class="form-group">
																		<label>
																			{{ "lang_human_resources_employee_name" | language }} A
																		</label>
																		<ng-select
																			[items]="users"
																			[multiple]="false"
																			bindLabel="label"
																			bindValue="value"
																			clearAllText="Clear"
																			[searchFn]="ds.customSearchFn"
																			appendTo="body"
																			formControlName="signature[A][user_id]"
																			placeholder="{{
																				'lang_human_resources_employee_name' | language
																			}}"
																			(search)="search_users($event)"
																			(focus)="users = []"
																		>
																		</ng-select>
																		<mat-error
																			*ngIf="
																				submitted &&
																				signature_form.controls['signature[A][user_id]'].errors?.required
																			"
																			>{{ "lang_field_required" | language }}</mat-error
																		>
																	</div>
																</div>
																<div class="col-sm-6 col-md-4 col-xs-12">
																	<div class="form-group">
																		<label>
																			{{ "lang_human_resources_employee_name" | language }} B
																		</label>
																		<ng-select
																			[items]="users"
																			[multiple]="false"
																			bindLabel="label"
																			bindValue="value"
																			clearAllText="Clear"
																			[searchFn]="ds.customSearchFn"
																			appendTo="body"
																			formControlName="signature[B][user_id]"
																			placeholder="{{
																				'lang_human_resources_employee_name' | language
																			}}"
																			(search)="search_users($event)"
																			(focus)="users = []"
																		>
																		</ng-select>
																		<mat-error
																			*ngIf="
																				submitted &&
																				signature_form.controls['signature[B][user_id]'].errors?.required
																			"
																			>{{ "lang_field_required" | language }}</mat-error
																		>
																	</div>
																</div>
																<div class="col-sm-6 col-md-4 col-xs-12">
																	<div class="form-group">
																		<label>
																			{{ "lang_human_resources_employee_name" | language }} C
																		</label>
																		<ng-select
																			[items]="users"
																			[multiple]="false"
																			bindLabel="label"
																			bindValue="value"
																			clearAllText="Clear"
																			[searchFn]="ds.customSearchFn"
																			appendTo="body"
																			formControlName="signature[C][user_id]"
																			placeholder="{{
																				'lang_human_resources_employee_name' | language
																			}}"
																			(search)="search_users($event)"
																			(focus)="users = []"
																		>
																		</ng-select>
																		<mat-error
																			*ngIf="
																				submitted &&
																				signature_form.controls['signature[C][user_id]'].errors?.required
																			"
																			>{{ "lang_field_required" | language }}</mat-error
																		>
																	</div>
																</div>
															</div>
														</fieldset>
													</div>
												</div>
												<div
													class="row mb-2"
													*ngIf="markstatus || reviewstatus || viewerstatus"
												>
													<div class="col-md-4 col-xs-12 col-sm-4 mb-2">
														<fieldset class="cardback fieldset" *ngIf="markstatus">
															<input type="hidden" formControlName="needMark" />
															<legend  style="text-align: start;">
																{{ "lang_markers" | language }}
																<span class="red"
																	>*
																	<mat-icon
																		class="red mnabricon red"
																		matTooltip="{{ 'lang_close' | language }}"
																		(click)="disable_field('mark')"
																		>close</mat-icon
																	>
																</span>
															</legend>
															<ng-select
																[items]="users"
																[multiple]="true"
																bindLabel="label"
																bindValue="value"
																clearAllText="Clear"
																[searchFn]="ds.customSearchFn"
																appendTo="body"
																formControlName="marks"
																placeholder="{{ 'lang_markers' | language }}"
																(search)="search_users($event)"
																(focus)="users = []"
															>
															</ng-select>
															<mat-error
																*ngIf="submitted && signature_form.controls['marks'].errors?.required"
																>{{ "lang_field_required" | language }}</mat-error
															>
														</fieldset>
													</div>
													<div class="col-md-4 col-xs-12 col-sm-4 mb-2">
														<fieldset class="cardback fieldset" *ngIf="reviewstatus">
															<input type="hidden" formControlName="needReview" />
															<legend  style="text-align: start;">
																{{ "lang_reviewers" | language }}
																<span class="red"
																	>*
																	<mat-icon
																		class="red mnabricon red"
																		matTooltip="{{ 'lang_close' | language }}"
																		(click)="disable_field('review')"
																		>close</mat-icon
																	>
																</span>
															</legend>
															<ng-select
																[items]="users"
																[multiple]="true"
																bindLabel="label"
																bindValue="value"
																clearAllText="Clear"
																[searchFn]="ds.customSearchFn"
																appendTo="body"
																formControlName="reviews"
																placeholder="{{ 'lang_reviewers' | language }}"
																(search)="search_users($event)"
																(focus)="users = []"
															>
															</ng-select>
															<mat-error
																*ngIf="submitted && signature_form.controls['reviews'].errors?.required"
															>
																{{ "lang_field_required" | language }}</mat-error
															>
														</fieldset>
													</div>
													<div class="col-md-4 col-xs-12 col-sm-4 mb-2">
														<fieldset class="cardback fieldset" *ngIf="viewerstatus">
															<input type="hidden" formControlName="needViewers" />
															<legend  style="text-align: start;">
																{{ "lang_viewers" | language }}
																<span class="red"
																	>*
																	<mat-icon
																		class="red mnabricon"
																		matTooltip="{{ 'lang_close' | language }}"
																		(click)="disable_field('viewer')"
																		>close</mat-icon
																	></span
																>
															</legend>
															<ng-select
																[items]="users"
																[multiple]="true"
																bindLabel="label"
																bindValue="value"
																clearAllText="Clear"
																[searchFn]="ds.customSearchFn"
																appendTo="body"
																formControlName="viewers"
																placeholder="{{ 'lang_viewers' | language }}"
																(search)="search_users($event)"
																(focus)="users = []"
															>
															</ng-select>
															<mat-error
																*ngIf="submitted && signature_form.controls['viewers'].errors?.required"
																>{{ "lang_field_required" | language }}</mat-error
															>
														</fieldset>
													</div>
												</div>
												<input type="hidden" formControlName="needAttach" />
												<div class="row mb-2" *ngIf="attachstatus">
													<div class="col-md-12 col-sm-12 col-xs-12 mb-2">
														<fieldset class="cardback fieldset">
															<legend style="text-align: start;">
																{{ "lang_attachments" | language }}
																<span class="red"
																	>*
																	<mat-icon
																		class="red mnabricon"
																		matTooltip="{{ 'lang_close' | language }}"
																		(click)="disable_field('attachment')"
																		>close</mat-icon
																	>
																</span>
																<mat-icon
																	class="mnabricon"
																	matTooltip="{{ 'lang_add' | language }}"
																	(click)="addfiles()"
																	>add_box</mat-icon
																>
															</legend>
															<div class="table-responsive customResponsive">
																<table
																	class="table CustomTable"
																	width="100%"
																	formArrayName="attachments"
																>
																	<thead>
																		<tr>
																			<th style="padding: 5px; word-break: break-all">#</th>
																			<th style="padding: 5px; word-break: break-all">
																				{{ "lang_title" | language }}
																			</th>
																			<th style="padding: 5px; word-break: break-all">
																				{{ "lang_file" | language }}
																			</th>
																			<th style="padding: 5px; word-break: break-all">
																				{{ "lang_attach_with_pdf" | language }}
																			</th>
																			<th style="padding: 5px; word-break: break-all">
																				{{ "lang_official_paper" | language }}
																			</th>
																			<th style="padding: 5px; word-break: break-all">
																				{{ "lang_action" | language }}
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr
																			*ngFor="
																				let file of filesFormGroup.controls;
																				let i = index;
																				let odd = odd;
																				let even = even
																			"
																			[formGroupName]="i"
																		>
																			<td
																				class="verticalalignmid"
																				style="padding: 5px; word-break: break-all"
																			>
																				{{ i + 1 }}
																			</td>
																			<td
																				style="
																					padding: 5px;
																					word-break: break-all;
																					position: relative;
																				"
																			>
																				<input
																					type="text"
																					formControlName="attach_title"
																					placeholder="{{ 'lang_description' | language }}"
																					autocomplete="off"
																					class="form-control almnabrformcontrol"
																				/>
																				<mat-error
																					*ngIf="
																						submitted &&
																						this.attachments.controls[i].get('attach_title')
																							.errors?.required
																					"
																					>{{ "lang_field_required" | language }}</mat-error
																				>
																			</td>
																			<td
																				class="verticalalignmid"
																				style="
																					padding: 5px;
																					word-break: break-all;
																					position: relative;
																				"
																			>
																				<input
																					style="display: block"
																					name="file"
																					type="file"
																					required
																					onclick="this.value = null"
																					(change)="UploadFile($event, i)"
																					class="nghide width100"
																				/>
																				<label
																					id="uploadText{{ i }}"
																					class="albutton mat-accent"
																					mat-button
																					><i class="fa fa-upload"></i>
																				</label>
																				<mat-error
																					*ngIf="
																						submitted &&
																						this.attachments.controls[i].get('file').errors
																							?.required
																					"
																					>{{ "lang_field_required" | language }}</mat-error
																				>
																			</td>
																			<td
																				class="verticalalignmid"
																				style="padding: 5px; word-break: break-all"
																			>
																				<mat-checkbox
																					formControlName="attach_with_the_document"
																					class="checkbox-radio-button"
																				></mat-checkbox>
																			</td>
																			<td
																				class="verticalalignmid"
																				style="padding: 5px; word-break: break-all"
																			>
																				<mat-checkbox
																					formControlName="print_official_paper"
																					class="checkbox-radio-button"
																				></mat-checkbox>
																			</td>
																			<td class="verticalalignmid">
																				<mat-icon
																					class="red mnabricon"
																					matTooltip="{{ 'lang_delete' | language }}"
																					(click)="removefiles(i)"
																					>close</mat-icon
																				>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</fieldset>
													</div>
												</div>
											</form>
										</div>
									</div>
                                    <div class="width-100 text-center mx-4 d-flex justify-content-between">
										<button (click)="back_open()" type="button"
											class="albutton mnabrbutton mat-accent mobileBTN" mat-button>
											{{ "lang_previous" | language }}
										</button>

										<button (click)="next_open()" type="button"
											class="albutton mnabrbutton mat-accent mobileBTN" mat-button>
											{{ "lang_next" | language }}
										</button>
									</div>
								</div>
             <!-- ********************************************************** -->
	         <!-- ********************************************************** -->
	         <!-- ********************************************************** -->
	     	 <!-- -------------------- Standard Steps----------------------- -->
	    	 <!-- ********************************************************** -->
		     <!-- ********************************************************** -->
		     <!-- ********************************************************** -->
									<div [ngClass]="{
											notShowen:
												form.controls['stepOne'].get('quotation_type').value ==
												'open'
										}" id="stepper1" class="bs-stepper">
										<div class="bs-stepper-header mx-5">
											<div class="step" data-target="#test-l-1">
												<div class="d-block" 
												(click)="setActive(1,0)"
												matTooltip="{{
														'lang_project_plan_requirements' | language
													}}">
													<button class="step-trigger" >
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 1,
																FormValid:
																	getSmallSteps.project_plan_requirements
																		.status == 'VALID' && currentActiveStep != 1
															}">1</span>
														<div class="custom">
														
														</div>
													</button>
												
												</div>
											</div>
											<div class="line step-1" [ngClass]="{
													activeNow: currentActiveStep == 1,
													FormValid:
														getSmallSteps.project_plan_requirements.status ==
															'VALID' && currentActiveStep != 1
												}"></div>
											<div class="step" data-target="#test-l-2">
												<div class="d-block" 
												(click)="setActive(2,1)" 
												matTooltip="{{ 'lang_soil_sensors' | language }}">
													<button class="step-trigger" [disabled]="
															getSmallSteps.project_plan_requirements.status ==
															'INVALID'
														">
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 2,
																FormValid:
																	getSmallSteps.project_component_soil_sensor
																		.status == 'VALID' && currentActiveStep != 2
															}">2</span>
														<div class="custom">
														
														</div>
													</button>
												
												</div>
											</div>
											<div class="line step-2" [ngClass]="{
													FormValid:
														getSmallSteps.project_component_soil_sensor
															.status == 'VALID' && currentActiveStep != 2,
													activeNow: currentActiveStep == 2
												}"></div>
											<div class="step" data-target="#test-l-3">
												<div class="d-block"
												(click)="moveToStep3(3,2)" 
												 matTooltip="{{
														'lang_plans_quantity_per_level_for_all_building'
															| language
													}}">
													<button class="step-trigger" [disabled]="
															getSmallSteps.project_component_soil_sensor
																.status == 'INVALID'
														">
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 3,
																FormValid:
																	getSmallSteps.project_analysis_data.status ==
																		'VALID' && currentActiveStep != 3
															}">3</span>
														<div class="custom">
														
														</div>
													</button>
													
												</div>
											</div>
											<div class="line step-3" [ngClass]="{
													activeNow: currentActiveStep == 3,
													FormValid:
														getSmallSteps.project_analysis_data.status ==
															'VALID' && currentActiveStep != 3
												}"></div>
											<div class="step" data-target="#test-l-4">
												<div class="d-block"
												(click)="moveToStep4(4,3)" 
													matTooltip="{{ 'lang_surveying_costs' | language }}">
													<button class="step-trigger" [disabled]="
															getSmallSteps.project_analysis_data.status ==
															'INVALID'
														">
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 4,
																FormValid:
																	getSmallSteps.surveying_costs.status ==
																		'VALID' && currentActiveStep != 4
															}">4</span>
														<div class="custom">
														
														</div>
													</button>
													
												</div>
											</div>
											<div class="line step-4" [ngClass]="{
													activeNow: currentActiveStep == 4,
													FormValid:
														getSmallSteps.surveying_costs.status == 'VALID' &&
														currentActiveStep != 4
												}"></div>
											<div class="step" data-target="#test-l-5">
												<div class="d-block"
												(click)="moveToStep5(5,4)" 
													matTooltip="{{ 'lang_printing_costs' | language }}">
													<button class="step-trigger" [disabled]="
															getSmallSteps.surveying_costs.status == 'INVALID'
														">
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 5,
																FormValid:
																	getSmallSteps.printing_costs.status ==
																		'VALID' && currentActiveStep != 5
															}">5</span>
														<div class="custom">
															
														</div>
													</button>
													
												</div>
											</div>
											<div class="line step-5" [ngClass]="{
													activeNow: currentActiveStep == 5,
													FormValid:
														getSmallSteps.printing_costs.status == 'VALID' &&
														currentActiveStep != 5
												}"></div>
											<div class="step" data-target="#test-l-6">
												<div class="d-block"
												(click)="moveToStep6(6,5)" 
													matTooltip="{{ 'lang_engineering_costs' | language }}">
													<button class="step-trigger" [disabled]="
															getSmallSteps.printing_costs.status == 'INVALID'
														">
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 6,
																FormValid:
																	getSmallSteps.engineering_costs.status ==
																		'VALID' && currentActiveStep != 6
															}">6</span>
														<div class="custom">
														
														</div>
													</button>
												
												</div>
											</div>
											<div class="line step-6" [ngClass]="{
													activeNow: currentActiveStep == 6,
													FormValid:
														getSmallSteps.engineering_costs.status == 'VALID' &&
														currentActiveStep != 6
												}"></div>
											<div class="step" data-target="#test-l-7">
												<div class="d-block"
												(click)="moveToStep7(7,6)" 
													matTooltip="{{ 'lang_government_costs' | language }}">
													<button class="step-trigger" [disabled]="
															getSmallSteps.engineering_costs.status ==
															'INVALID'
														">
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 7,
																FormValid:
																	getSmallSteps.government_services.status ==
																		'VALID' && currentActiveStep != 7
															}">7</span>
														<div class="custom">
															
														</div>
													</button>
												
												</div>
											</div>
											<div class="line step-7" [ngClass]="{
													activeNow: currentActiveStep == 7,
													FormValid:
														getSmallSteps.government_services.status ==
															'VALID' && currentActiveStep != 7
												}"></div>
											<div class="step" data-target="#test-l-8">
												<div class="d-block" 
												(click)="moveToStep8(8,7)"
												matTooltip="{{ 'lang_other_costs' | language }}">
													<button class="step-trigger" [disabled]="
															getSmallSteps.government_services.status ==
															'INVALID'
														">
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 8,
																FormValid:
																	getSmallSteps.other_costs.status == 'VALID' &&
																	currentActiveStep != 8
															}">8</span>
														<div class="custom">
														
														</div>
													</button>
													
												</div>
											</div>
											<div class="line step-8" [ngClass]="{
													activeNow: currentActiveStep == 8,
													FormValid:
														getSmallSteps.other_costs.status ==
															'VALID' && currentActiveStep != 8
												}"></div>

											<div class="step" data-target="#test-l-9">
												<div class="d-block"
												(click)="moveToStep9(9,8)" 
												 matTooltip="{{ 'lang_main_analysis_costs_total_analysis_costs' | language }}">
													<button class="step-trigger" 
													[disabled]="
															getSmallSteps.other_costs.status ==
															'INVALID'
														">
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 9,
																FormValid:
																	getSmallSteps.main_analysis_costs.status == 'VALID' &&
																	currentActiveStep != 9
															}">9</span>
														<div class="custom">
														 
														</div>
													</button>
													 
												</div>
											</div>
											<div class="line step-9" [ngClass]="{
													activeNow: currentActiveStep == 9,
													FormValid:
														getSmallSteps.main_analysis_costs.status ==
															'VALID' && currentActiveStep != 9
												}"></div>

											<div class="step" data-target="#test-l-10">
												<div class="d-block"
												(click)="moveToStep10(10,9)"
												 matTooltip="{{ 'lang_quotation_price' | language }}">
													<button class="step-trigger" 
													 [disabled]="
															getSmallSteps.main_analysis_costs.status ==
															'INVALID'
														">
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 10,
																FormValid:
																	getSmallSteps.quotation_price.status == 'VALID' &&
																	currentActiveStep != 10
															}">10</span>
														<div class="custom">
															 
														</div>
													</button>
													 
												</div>
											</div>
											<div class="line step-10" [ngClass]="{
													activeNow: currentActiveStep == 10,
													FormValid:
														getSmallSteps.quotation_price.status ==
															'VALID' && currentActiveStep != 10
												}"></div>

											<div class="step" data-target="#test-l-11">
												<div class="d-block" 
												(click)="setActive(11,10)"
												matTooltip="{{ 'lang_financial_managements' | language }}">
													<button class="step-trigger"
													 [disabled]="
															getSmallSteps.quotation_price.status ==
															'INVALID'
														">
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 11,
																FormValid:
																	getSmallSteps.financial_managments.status == 'VALID' &&
																	currentActiveStep != 11
															}">11</span>
														<div class="custom">
															 
														</div>
													</button>
													 
												</div>
											</div>
											<div class="line step-11" [ngClass]="{
													activeNow: currentActiveStep == 11,
													FormValid:
														getSmallSteps.financial_managments.status ==
															'VALID' && currentActiveStep != 11
												}"></div>

											<div class="step" data-target="#test-l-12">
												<div class="d-block"
												(click)="setActive(12,11)"
												 matTooltip="{{ 'lang_signatures' | language }}">
													<button class="step-trigger"
													 [disabled]="
															getSmallSteps.financial_managments.status ==
															'INVALID'
														">
														<span class="bs-stepper-circle" [ngClass]="{
																activeNow: currentActiveStep == 12,
																FormValid:
																signature_form?.status == 'VALID' &&
																	currentActiveStep != 12
															}">12</span>
														<div class="custom">
														 
														</div>
													</button>
												 
												</div>
											</div>
										</div>

										<div class="row width-100 text-start mx-4 mt-3 fontOneRem">
											<div class="col-md-9 p-0">
												<h4 *ngIf="currentActiveStep == 1">
													{{ "lang_project_plan_requirements" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 2">
													{{ "lang_soil_sensors" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 3">
													{{
													"lang_plans_quantity_per_level_for_all_building"
													| language
													}}
												</h4>
												<h4 *ngIf="currentActiveStep == 4">
													{{ "lang_surveying_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 5">
													{{ "lang_printing_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 6">
													{{ "lang_engineering_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 7">
													{{ "lang_government_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 8">
													{{ "lang_other_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 9">
													{{ "lang_main_analysis_costs_total_analysis_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 10">
													{{ "lang_quotation_price" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 11">
													{{ "lang_financial_managments" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 12">
													{{ "lang_signatures" | language }}
												</h4>
											</div>

											<div class="col-md-3 p-0">
												<p class="step_num">
													{{'lang_step'|language}} {{ currentActiveStep }} {{'of'|language}} 12
												</p>
											</div>
										</div>
										<div class="width-100 text-center mx-4 d-flex justify-content-between">
											<button (click)="back()" type="button"
												class="albutton mnabrbutton mat-accent mobileBTN" mat-button>
												{{ "lang_previous" | language }}
											</button>

											<button (click)="next()" type="button"
												class="albutton mnabrbutton mat-accent mobileBTN" mat-button>
												{{ "lang_next" | language }}
											</button>
										</div>
										<div class="bs-stepper-content mt-1">
											<div id="test-l-1" class="content">
												<form [formGroup]="getstandard_steps">
													<div class="form-group">
														<a (click)="addPlan()" class="btn customButton mb-4">
															{{ "lang_add_another_plan" | language }}
														</a>


														<div class="row w-100">
															<table class="table  table-hover">
																<thead>
																	<tr>
																	  <th scope="col">#</th>
																	  <th scope="col">{{
																		"lang_plan_title_english" | language
																		}}</th>
																	  <th scope="col">{{
																		"lang_plan_title_arabic" | language
																		}}</th>
																	  <th scope="col">{{
																		"lang_plan_technical_ids" | language
																		}}</th>
																		<th scope="col">{{
																		"lang_plan_hour_value" | language
																		}}</th>
																		<th scope="col">{{
																		"lang_plan_total_hours" | language
																		}}</th>
                                    <th scope="col">{{
                                        "lang_action" | language
                                        }}</th>
																	</tr>
																  </thead>
																  <tbody>
																	  <ng-container 
																	  *ngFor="
																let creds of getSmallSteps
																	.project_plan_requirements?.value;
																let i = index;
																trackBy: trackByFnCustom"  
																formArrayName="project_plan_requirements">
																	
																<tr [formGroupName]="i">
																	<td scope="row">
                                                      <span
                                                      style="    
                                                      position: relative;
                                                      top: -12px;">
                                                         {{ i + 1 }} 
                                                      </span>
																 
															      </td>
																	<td>
																		<mat-form-field style="width: 100%"
																		appearance="outline">
																		<!-- <mat-label>{{
																			"lang_plan_title_english" | language
																			}}</mat-label> -->

																		<input matInput type="text"
																			(keyup)="makekeyword($event, i)"
																			formControlName="plan_title_english"
																			required />
																		<mat-error *ngIf="
																				(getstandard_steps.controls[
																					'project_plan_requirements'
																				]['controls'][i]['controls']
																					?.plan_title_english.touched &&
																				getstandard_steps.controls[
																					'project_plan_requirements'
																				]['controls'][i]['controls']
																					?.plan_title_english.errors
																					?.required)
																			">
																			{{ "lang_field_required" | language }}
																		</mat-error>
																	</mat-form-field>
																	</td>
																	<td>
																		<mat-form-field style="width: 100%"
																			appearance="outline">
																			<!-- <mat-label>{{
																				"lang_plan_title_arabic" | language
																				}}</mat-label> -->

																			<input matInput type="text"
																				formControlName="plan_title_arabic"
																				required />
																			<mat-error *ngIf="
																					(getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_title_arabic.touched &&
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_title_arabic.errors?.required)
																				">
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</td>
																	<td>
																		<mat-form-field style="width: 100%"
																			appearance="outline">
																			<!-- <mat-label>{{
																				"lang_plan_technical_ids" | language
																				}}</mat-label> -->
																			<mat-select multiple
																				formControlName="plan_technical_ids">
																				<mat-option *ngFor="let ut of planreq"
																					[value]="ut.value">
																					{{ ut.label }}
																				</mat-option>
																			</mat-select>
																			<mat-error *ngIf="submitStandard1 ||
																					(getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_technical_ids.touched &&
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_technical_ids.errors
																						?.required)
																				">
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</td>
                                                   <td>
                                                      <mat-form-field style="width: 100%"
                                                      appearance="outline">
                                                      <!-- <mat-label>{{
                                                         "lang_plan_hour_value" | language
                                                         }}</mat-label> -->
   
                                                      <input matInput
                                                         (keypress)="isNumber($event)"
                                                         type="number"
                                                         formControlName="plan_hour_value"
                                                         required />
   
                                                      <mat-error *ngIf="
                                                            (getstandard_steps.controls[
                                                               'project_plan_requirements'
                                                            ]['controls'][i]['controls']
                                                               ?.plan_hour_value.touched &&
                                                            getstandard_steps.controls[
                                                               'project_plan_requirements'
                                                            ]['controls'][i]['controls']
                                                               ?.plan_hour_value.errors?.required)
                                                         ">
                                                         {{ "lang_field_required" | language }}
                                                      </mat-error>
                                                   </mat-form-field>
                                                     </td>
   
                                                     <td>
                                                      <mat-form-field style="width: 100%"
                                                      appearance="outline">
                                                      <!-- <mat-label>{{
                                                         "lang_plan_total_hours" | language
                                                         }}</mat-label> -->
   
                                                      <input matInput
                                                         (keypress)="isNumber($event)"
                                                         type="number"
                                                         formControlName="plan_total_hours"
                                                         required />
                                                      <mat-error *ngIf="
                                                            (getstandard_steps.controls[
                                                               'project_plan_requirements'
                                                            ]['controls'][i]['controls']
                                                               ?.plan_total_hours.touched &&
                                                            getstandard_steps.controls[
                                                               'project_plan_requirements'
                                                            ]['controls'][i]['controls']
                                                               ?.plan_total_hours.errors?.required)
                                                         ">
                                                         {{ "lang_field_required" | language }}
                                                      </mat-error>
                                                   </mat-form-field>
                                                     </td>

                                                     <td scope="row"> 
                                                      <a class="danger" 
                                                      style="    
                                                      position: relative;
                                                      top: -12px;" *ngIf="
                                                         i <
                                                         getSmallSteps.project_plan_requirements
                                                            ?.value.length -
                                                            plans.length
                                                      " (click)="deletePaln(i)" matTooltip="{{
                                                         'lang_delete_plan' | language
                                                      }}">
                                                      <i
                                                         class="fa fa-trash deleteICO font-medium-3 m-2"></i>
                                                       </a>
                                                   </td>
																  </tr>
															
															</ng-container>
																
																	 
																  </tbody>
															  </table>
														</div>




                                                	</div>
												</form>

												 
											</div>
											<div id="test-l-2" class="content">
												<form [formGroup]="
														getSmallSteps.project_component_soil_sensor
													">
													<div class="col-12 col-sm-6 mt-1 mb-3">
														<!-- <div class="d-flex justify-content-between">
														
														</div> -->

														<mat-label style="margin: 15px 5px; font-size: 17px">{{
															"lang_project_component_soil_sensor_status"
															| language
															}}
														</mat-label>
														<mat-radio-group aria-labelledby="example-radio-group-label"
															formControlName="project_component_soil_sensor_status">
															<mat-radio-button
																*ngFor="let soil_sensor of soil_sensor_status"
																(change)="changeSoilSensor($event)"
																[value]="soil_sensor.value">
																{{ soil_sensor.label }}
															</mat-radio-button>
														</mat-radio-group>

														<mat-error *ngIf="
																(getSmallSteps.project_component_soil_sensor.get(
																	'project_component_soil_sensor_status'
																).touched &&
																getSmallSteps.project_component_soil_sensor.get(
																	'project_component_soil_sensor_status'
																).errors?.required)
															">
															{{ "lang_field_required" | language }}
														</mat-error>
													</div>

													<div class="col-12" *ngIf="
															getSmallSteps.project_component_soil_sensor.value?.project_component_soil_sensor_status == 0
														" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
														<mat-form-field appearance="outline" style="width: 100%">
															<mat-label>{{
																"lang_soil_sensor_legal_notes" | language
																}}</mat-label>
															<textarea matInput
																formControlName="project_component_soil_sensor_legal_notes"
																required rows="5"></textarea>
															<mat-error *ngIf="
																	getSmallSteps.project_component_soil_sensor.get(
																		'project_component_soil_sensor_legal_notes'
																	).touched &&
																	getSmallSteps.project_component_soil_sensor.get(
																		'project_component_soil_sensor_legal_notes'
																	).errors?.required
																">
																{{ "lang_field_required" | language }}
															</mat-error>
														</mat-form-field>
													</div>
												</form>
											</div>
											<div id="test-l-3" class="content">
												<form [formGroup]="getstandard_steps">
													<div class="form-group">
														<a (click)="addProjectAnalysis()" class="btn customButton mb-4">
															{{ "lang_add_project_analysis" | language }}
														</a>

														<mat-tab-group formArrayName="project_analysis_data"
															[selectedIndex]="selected.value"
															(selectedIndexChange)="selected.setValue($event)">
															<mat-tab *ngFor="
																	let creds of getSmallSteps.project_analysis_data?.value;
																	let i = index;
																	trackBy: trackByFnCustom
																" label="{{
																	'lang_project_analysis_no' | language
																}}. {{ i + 1 }}">
																<div class="my-3">
																	<div [formGroupName]="i"
																		[ngClass]="{ borderTop: i != 0 }">


                                   
																		<div class="row col-md-12">
                                      <div class="form-group col-md-6 mb-1">
																				<h5 class="head">
																					{{
																					"lang_project_analysis_no"
																					| language
																					}}. {{ i + 1 }}
																				</h5>
																			</div>
																			<div class="form-group col-md-6 mb-1"
																				style="text-align: right">
																				<a class="danger"
																					(click)="deleteProjectAnalysis(i)"
																					matTooltip="{{
																						'lang_project_analysis' | language
																					}}">
																					<i
																						class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																				</a>
																			</div>
                                      <mat-accordion class="example-headers-align w-100">
                                        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                                          <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              {{'lang_proj_information'|language}}
                                            </mat-panel-title>
                                            <mat-panel-description>
                                              <!-- Type your name and age -->
                                              <mat-icon>account_circle</mat-icon>
                                            </mat-panel-description>
                                          </mat-expansion-panel-header>
                                      
                                      		<div class="row col-md-12 mt-2">
                                            <div class="col-12 col-sm-3"
                                            [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                            <mat-form-field style="width: 100%"
                                              appearance="outline">
                                              <mat-label>{{
                                                "lang_subject_english" |
                                                language
                                                }}</mat-label>
      
                                              <input matInput type="text"
                                                formControlName="project_component_subject_english"
                                                required />
                                              <mat-error *ngIf="
                                                  (getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_subject_english.touched &&getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_subject_english.errors?.required)
                                                ">
                                                {{
                                                "lang_field_required" | language
                                                }}
                                              </mat-error>
                                            </mat-form-field>
                                          </div>
      
                                          <div class="col-12 col-sm-3"
                                            [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                            <mat-form-field style="width: 100%"
                                              appearance="outline">
                                              <mat-label>{{
                                                "lang_subject_arabic" | language
                                                }}</mat-label>
      
                                              <input matInput type="text"
                                                formControlName="project_component_subject_arabic"
                                                required />
                                              <mat-error *ngIf="
                                                  (getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_subject_arabic.touched &&getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_subject_arabic.errors?.required)
                                                ">
                                                {{
                                                "lang_field_required" | language
                                                }}
                                              </mat-error>
                                            </mat-form-field>
                                          </div>
      
                                     
      
                                          <div class="col-12 col-sm-3"
                                            [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                            <mat-form-field style="width: 100%"
                                              appearance="outline">
                                              <mat-label>{{
                                                "lang_space" | language
                                                }}</mat-label>
      
                                              <input matInput
                                                (keypress)="isNumber($event)"
                                                (keyup)="makeCalculations()"
                                                type="number"
                                                formControlName="project_component_space"
                                                required />
      
                                              <mat-error *ngIf="
                                                  (getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_space.touched &&getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_space.errors?.required)
                                                ">
                                                {{
                                                "lang_field_required" | language
                                                }}
                                              </mat-error>
                                            </mat-form-field>
                                          </div>
      
                                          <div class="col-12 col-sm-3"
                                            [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                            <mat-form-field style="width: 100%"
                                              appearance="outline">
                                              <mat-label>{{
                                                "lang_building_quantity" |
                                                language
                                                }}</mat-label>
      
                                              <input matInput
                                                (keypress)="isNumber($event)"
                                                (keyup)="makeCalculations()"
                                                type="number"
                                                formControlName="project_component_building_quantity"
                                                required />
      
                                              <mat-error *ngIf="
                                                  (getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_building_quantity.touched &&
                                                  getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_building_quantity.errors?.required)
                                                ">
                                                {{
                                                "lang_field_required" | language
                                                }}
                                              </mat-error>
                                            </mat-form-field>
                                          </div>
                                          <ng-container
                                          *ngIf="validationOnSoil == '1'">
                                            <div class="col-12 col-sm-12 mb-3"
                                                [dir]="
                                                  lang_key == 'ar' ? 'rtl' : 'ltr'
                                                ">
												<h5>
													{{
														"lang_soil_sensor"
														| language
														}} 
			
												</h5>
                                                
                                                 
                                              </div>
                                          <div class="col-12 col-sm-3" [dir]="
                                          lang_key == 'ar' ? 'rtl' : 'ltr'
                                        ">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                          <mat-label>{{
                                            "lang_component_soil_sensor_cost_per_lmeter"
                                            | language
                                            }}</mat-label>
    
                                          <input matInput
                                            (keypress)="isNumber($event)"
                                            type="number"
                                            (keyup)="makeCalculations()"
                                            formControlName="project_component_soil_sensor_cost_per_lmeter"
                                            required />
                                          <mat-error *ngIf="
                                              (getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_soil_sensor_cost_per_lmeter.touched &&
                                              getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_soil_sensor_cost_per_lmeter.errors?.required)
                                            ">
                                            {{
                                            "lang_field_required" |
                                            language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                        
                                          <div class="col-12 col-sm-3" [dir]="
                                              lang_key == 'ar' ? 'rtl' : 'ltr'
                                            ">
                                            <mat-form-field style="width: 100%"
                                              appearance="outline">
                                              <mat-label>{{"lang_project_soil_sensor_building_quantity"| language}}</mat-label>
    
                                              <input matInput
                                                (keypress)="isNumber($event)"
												readonly
                                                disabled type="number" 
                                                formControlName="project_component_soil_sensor_building_quantity"
                                                required />
                                              <mat-error *ngIf="
                                                  (getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_soil_sensor_building_quantity.touched &&
                                                  getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_soil_sensor_building_quantity.errors?.required)
                                                ">
                                                {{
                                                "lang_field_required" |
                                                language
                                                }}
                                              </mat-error>
                                            </mat-form-field>
                                          </div>
    
                                          <div class="col-12 col-sm-3" [dir]="
                                              lang_key == 'ar' ? 'rtl' : 'ltr'
                                            ">
                                            <mat-form-field style="width: 100%"
                                              appearance="outline">
                                              <mat-label>{{
                                                "lang_project_soil_sensor_depth_level_quantity"
                                                | language
                                                }}</mat-label>
    
                                              <input matInput
                                                (keypress)="isNumber($event)"
                                                type="number"  
												readonly
                                                formControlName="project_component_soil_sensor_depth_level_quantity"
                                                required />
                                              <mat-error *ngIf="
                                                  (getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_soil_sensor_depth_level_quantity.touched &&
                                                  getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.project_component_soil_sensor_depth_level_quantity.errors?.required)
                                                ">
                                                {{
                                                "lang_field_required" |
                                                language
                                                }}
                                              </mat-error>
                                            </mat-form-field>
                                          </div>
    
                                      
                                        </ng-container>
                                          </div>
													

            
                                          <mat-action-row>
                                            <button mat-button type="button" color="primary" (click)="nextStep()">{{'Next'|language}}</button>
                                          </mat-action-row>
                                        </mat-expansion-panel>
                                      
                                        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                                          <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              {{
                                                "lang_plan_quantities"
                                                | language
                                                }} 
                                            </mat-panel-title>
                                            <mat-panel-description>
                                              <!-- Type the country name -->
                                              <mat-icon>map</mat-icon>
                                            </mat-panel-description>
                                          </mat-expansion-panel-header>
                                      
                                          <div class="w-100 mt-2">
                                           <!--  <div class="col-12 col-sm-12 mb-3"
                                            [dir]="
                                               lang_key == 'ar' ? 'rtl' : 'ltr'
                                            ">
                                            {{
                                            "lang_plan_quantities"
                                            | language
                                            }} 

                                             
                                         </div> -->
                                            <table class="table table-hover">
                                               <thead>
                                                 <tr>
                                                   <th scope="col">#</th>
                                                   <th scope="col">{{
                                                     "lang_plan_name" |
                                                     language
                                                     }}</th>
                                                   <th scope="col">{{
                                                    "lang_plan_quantity_count"
                                                    | language
                                                    }}</th>
                                                  
                                                 </tr>
                                               </thead>
                                               <tbody>
                                                 <ng-container 
                                                 formArrayName="plan_quantities"
                                                  *ngFor="
                                                    let creds3 of getSmallSteps
                                                      .project_analysis_data?.value[i]
                                                      .plan_quantities;
                                                    let ii = index;
                                                    trackBy: trackByFnCustom
                                                  "
                                                 >
                                                 <tr 	[formGroupName]="ii">
                                                  <th scope="row">{{ii+1}}</th>
                                                  <td>
                                                    <mat-form-field
                                                    style="width: 100%"
                                                    appearance="outline">
                                                    <!-- <mat-label>{{
                                                       "lang_plan_name" |
                                                       language
                                                       }}</mat-label> -->
                                                    <mat-select

                                                    (selectionChange)="updateTotalPlanQuantitiyAfterChangePlan(getstandard_steps.controls[
                                                          'project_analysis_data'
                                                       ]['controls'][i]['controls']
                                                          ?.plan_quantities[
                                                          'controls'
                                                       ][ii]['controls'])"
                                                     
                                                       formControlName="plan_keyword">
                                                       <mat-option 
                                                       
                                                       *ngFor="
                                                             let ut of getstandard_steps
                                                                .controls[
                                                                'project_plan_requirements'
                                                             ]['controls']
                                                          " [value]="
                                                             ut['controls']
                                                                ?.plan_keyword.value
                                                          ">
                                                          {{
                                                          lang_key == "en"
                                                          ? ut["controls"]
                                                          ?.plan_title_english
                                                          .value
                                                          : ut["controls"]
                                                          ?.plan_title_arabic
                                                          .value
                                                          }}
                                                       </mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="
                                                          (getstandard_steps.controls[
                                                             'project_analysis_data'
                                                          ]['controls'][i]['controls']
                                                             ?.plan_quantities[
                                                             'controls'
                                                          ][ii]['controls']
                                                             ?.plan_keyword.touched &&
                                                          getstandard_steps.controls[
                                                             'project_analysis_data'
                                                          ]['controls'][i]['controls']
                                                             ?.plan_quantities[
                                                             'controls'
                                                          ][ii]['controls']
                                                             ?.plan_keyword.errors
                                                             ?.required)
                                                       ">
                                                       {{
                                                       "lang_field_required"
                                                       | language
                                                       }}
                                                    </mat-error>
                                                 </mat-form-field>
                                           
                                                  </td>
                                                  <td>
                                                   <mat-form-field
                                                   style="width: 100%"
                                                   appearance="outline">
                                                  <!--  <mat-label>{{
                                                     "lang_plan_quantity_count"
                                                     | language
                                                     }}</mat-label> -->
   
                                                   <input matInput
                                                     (keypress)="isNumber($event)"
                                                     type="number"
                                                     (keyup)="updateTotalPlanQuantitiyAfterChangePlan(getstandard_steps.controls[
                                                       'project_analysis_data'
                                                     ]['controls'][i]['controls']
                                                       ?.plan_quantities[
                                                       'controls'
                                                     ][ii]['controls'])"
                                                     formControlName="plan_quantity_count"
                                                     required />
   
                                                   <mat-error *ngIf="
                                                       (getstandard_steps.controls[
                                                         'project_analysis_data'
                                                       ]['controls'][i]['controls']
                                                         ?.plan_quantities[
                                                         'controls'
                                                       ][ii]['controls']
                                                         ?.plan_quantity_count
                                                         .touched &&
                                                       getstandard_steps.controls[
                                                         'project_analysis_data'
                                                       ]['controls'][i]['controls']
                                                         ?.plan_quantities[
                                                         'controls'
                                                       ][ii]['controls']
                                                         ?.plan_quantity_count
                                                         .errors?.required)
                                                     ">
                                                     {{
                                                     "lang_field_required"
                                                     | language
                                                     }}
                                                   </mat-error>
                                                 </mat-form-field>
                                                  </td>
                                                   
                                                </tr>
                                                 </ng-container>
                                              
                                                 
                                               </tbody>
                                             </table>
                                          </div>
                                      
                                          <mat-action-row>
                                            <button mat-button color="warn"  type="button"  (click)="prevStep()">{{'Previous'|language}}</button>
                                            <button mat-button color="primary"  type="button"  (click)="nextStep()">{{'Next'|language}}</button>
                                          </mat-action-row>
                                        </mat-expansion-panel>
                                      
                                        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                                          <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              {{
                                                "lang_levels" | language
                                                }}
                                            </mat-panel-title>
                                            <mat-panel-description>
                                              <!-- Inform the date you wish to travel -->
                                              <mat-icon>signal_cellular_alt</mat-icon>
                                            </mat-panel-description>
                                          </mat-expansion-panel-header>
                                      
                                         
                                          
                                          <div class="col-12 col-sm-3 mt-2"
                                          [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                          <mat-form-field style="width: 100%"
                                            appearance="outline">
                                            <mat-label>{{
                                              "lang_level_quantity" | language
                                              }}</mat-label>
  
                                            <input matInput
                                              (keypress)="isNumber($event)"
                                              (keyup)="changePlanReq($event, i)"
                                              type="number"
                                              formControlName="project_component_level_quantity"
                                              required />
                                            <mat-error *ngIf="
                                                (getstandard_steps.controls[
                                                  'project_analysis_data'
                                                ]['controls'][i]['controls']
                                                  ?.project_component_level_quantity
                                                  .touched &&
                                                getstandard_steps.controls[
                                                  'project_analysis_data'
                                                ]['controls'][i]['controls']
                                                  ?.project_component_level_quantity
                                                  .errors?.required)
                                              ">
                                              {{
                                              "lang_field_required" | language
                                              }}
                                            </mat-error>

                                            <mat-error *ngIf="
                                                (getstandard_steps.controls[
                                                  'project_analysis_data'
                                                ]['controls'][i]['controls']
                                                  ?.project_component_level_quantity
                                                  .touched &&
                                                getstandard_steps.controls[
                                                  'project_analysis_data'
                                                ]['controls'][i]['controls']
                                                  ?.project_component_level_quantity
                                                  .errors?.min)
                                              ">
                                              {{
                                              "lang_field_ min_value_1" | language
                                              }}
                                            </mat-error>
                                          </mat-form-field>
                                        </div>
  
                                        <div class="row w-100 m-auto"
                                          style="justify-content: space-between">

                                          <table class="table ">
                                            <thead>
                                              <tr>
                                                <th scope="col">#</th>
                                                <th scope="col" style="text-align: start;">{{
                                                  "lang_levels_total"
                                                  | language
                                                  }}</th>
                                                <th scope="col">
                                                  {{'lang_action'|language}}
                                                </th>
                                              <!--   <th scope="col">Handle</th> -->
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <ng-container 
                                              formArrayName="project_plan_requirements" *ngFor="
                                              let creds2 of getSmallSteps
                                                .project_analysis_data?.value[i]
                                                .project_plan_requirements;
                                              let ii = index;
                                              trackBy: trackByFnCustom
                                            ">

                                            <ng-container 
                                            [formGroupName]="ii">
                                            <tr>
                                              <th scope="row" 
                                              (click)="toggleValue(getstandard_steps
                                              .controls[
                                              'project_analysis_data'
                                            ]['controls'][i][
                                              'controls'
                                            ]
                                              ?.project_plan_requirements[
                                              'controls'
                                            ][ii]['controls']
                                              ?.collabsed)">{{ii+1}}</th>
                                              <td colspan="1" class="text-start">
                                                <mat-form-field
                                                style="max-width: 330px"
                                                appearance="outline">
                                               <!--  <mat-label>{{
                                                  "lang_levels_total"
                                                  | language
                                                  }}
                                                </mat-label> -->

                                                <input matInput
                                                  (keypress)="
                                                    isNumber($event)
                                                  " type="number" formControlName="levels_total" required />

                                                <mat-error
                                                  *ngIf="
                                                    (getstandard_steps
                                                      .controls[
                                                      'project_analysis_data'
                                                    ]['controls'][i][
                                                      'controls'
                                                    ]
                                                      ?.project_plan_requirements[
                                                      'controls'
                                                    ][ii]['controls']
                                                      ?.levels_total
                                                      .touched &&
                                                    getstandard_steps
                                                      .controls[
                                                      'project_analysis_data'
                                                    ]['controls'][i][
                                                      'controls'
                                                    ]
                                                      ?.project_plan_requirements[
                                                      'controls'
                                                    ][ii]['controls']
                                                      ?.levels_total.errors
                                                      ?.required)
                                                  ">
                                                  {{
                                                  "lang_field_required"
                                                  | language
                                                  }}
                                                </mat-error>

                                                <mat-error
                                                  *ngIf="
                                                    (getstandard_steps
                                                      .controls[
                                                      'project_analysis_data'
                                                    ]['controls'][i][
                                                      'controls'
                                                    ]
                                                      ?.project_plan_requirements[
                                                      'controls'
                                                    ][ii]['controls']
                                                      ?.levels_total
                                                      .touched &&
                                                    getstandard_steps
                                                      .controls[
                                                      'project_analysis_data'
                                                    ]['controls'][i][
                                                      'controls'
                                                    ]
                                                      ?.project_plan_requirements[
                                                      'controls'
                                                    ][ii]['controls']
                                                      ?.levels_total.errors
                                                      ?.notEqual)
                                                  ">
                                                  {{
                                                  "lang_sub_fields_must_be_equals"
                                                  | language
                                                  }}
                                                </mat-error>
                                              </mat-form-field>
                                              </td>
                                           <td
                                           (click)="toggleValue(getstandard_steps
                                            .controls[
                                            'project_analysis_data'
                                          ]['controls'][i][
                                            'controls'
                                          ]
                                            ?.project_plan_requirements[
                                            'controls'
                                          ][ii]['controls']
                                            ?.collabsed)">
                                             <mat-icon
                                             
                                             *ngIf="!getstandard_steps
                                            .controls[
                                            'project_analysis_data'
                                          ]['controls'][i][
                                            'controls'
                                          ]
                                            ?.project_plan_requirements[
                                            'controls'
                                          ][ii]['controls']
                                            ?.collabsed.value"
 
                                            >
                                              expand_more
                                             </mat-icon>
                                             <mat-icon
                                             *ngIf="getstandard_steps
                                            .controls[
                                            'project_analysis_data'
                                          ]['controls'][i][
                                            'controls'
                                          ]
                                            ?.project_plan_requirements[
                                            'controls'
                                          ][ii]['controls']
                                            ?.collabsed.value"

                                            
                                            >
                                            expand_less
                                             </mat-icon>
                                           </td>
                                            <!--      <td>@mdo</td> -->
                                            </tr>

                                            <tr *ngIf="getstandard_steps
                                            .controls[
                                            'project_analysis_data'
                                          ]['controls'][i][
                                            'controls'
                                          ]
                                            ?.project_plan_requirements[
                                            'controls'
                                          ][ii]['controls']
                                            ?.collabsed.value">
                                              <th scope="row"></th>

                                              <th colspan="2">
                                                <table class="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">#
                                                        <i  class="mat-tooltip-trigger fa fa-plus-square mx-2 mnabricon  "
                                                            (click)="addLevels(i, ii)"
                                                            matTooltip="{{
                                                              'lang_add_level'
                                                                | language
                                                            }}"
                                                            ></i>
                                                      </th>
                                                      <th scope="col">{{
                                                        "lang_level_value"
                                                        |
                                                        language
                                                        }}</th>
                                                      <th scope="col">{{
                                                        "lang_level_occupancy"
                                                        |
                                                        language
                                                        }}</th>
                                                      <th scope="col">Action</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <ng-container formArrayName="levels" *ngFor="
                                                    let creds2 of getSmallSteps
                                                      .project_analysis_data
                                                      ?.value[i]
                                                      .project_plan_requirements[
                                                      ii
                                                    ].levels;
                                                    let iii = index;
                                                    trackBy: trackByFnCustom
                                                  ">
                                                  <tr [formGroupName]="iii">
                                                    <th scope="row">{{iii+1}}</th>
                                                    <td>
                                                      <mat-form-field
                                                      style="width: 100%"
                                                      appearance="outline">
                                                      <!-- <mat-label>
                                                        {{
                                                        "lang_level_value"
                                                        |
                                                        language
                                                        }}
                                                      </mat-label> -->

                                                      <input
                                                        matInput
                                                        (keypress)="
                                                          isNumber($event)
                                                        " 
                                                        (keyup)="checkValidation(getstandard_steps
                                                        .controls['project_analysis_data']['controls'][i]['controls']?.project_plan_requirements['controls'][ii])
                                                      "
                                                        
                                                        type="number" formControlName="level_value" required />

                                                      <mat-error
                                                        *ngIf="
                                                          (getstandard_steps
                                                            .controls[
                                                            'project_analysis_data'
                                                          ]['controls'][i][
                                                            'controls'
                                                          ]
                                                            ?.project_plan_requirements[
                                                            'controls'
                                                          ][ii]['controls']
                                                            ?.levels[
                                                            'controls'
                                                          ][iii]['controls']
                                                            .level_value
                                                            .touched &&
                                                          getstandard_steps
                                                            .controls[
                                                            'project_analysis_data'
                                                          ]['controls'][i][
                                                            'controls'
                                                          ]
                                                            ?.project_plan_requirements[
                                                            'controls'
                                                          ][ii]['controls']
                                                            ?.levels[
                                                            'controls'
                                                          ][iii]['controls']
                                                            .level_value
                                                            .errors
                                                            ?.required)
                                                        ">
                                                        {{
                                                        "lang_field_required"
                                                        |
                                                        language
                                                        }}
                                                      </mat-error>
                                                    </mat-form-field>
                                                    </td>
                                                    <td>
                                                      <mat-form-field
                                                      style="width: 100%"
                                                      appearance="outline">
                                                     <!--  <mat-label>
                                                        {{
                                                        "lang_level_occupancy"
                                                        |
                                                        language
                                                        }}
                                                      </mat-label> -->

                                                      <!-- <input
                                                        matInput
                                                        type="text"
                                                        formControlName="level_occupancy"
                                                        required /> -->

                                                        <mat-select  formControlName="level_occupancy">
                                                          <mat-option *ngFor="let ut of occupancies_list" [value]="ut.value">
                                                            {{ ut.label }}
                                                          </mat-option>
                                                        </mat-select>

                                                      <mat-error
                                                        *ngIf="
                                                          (getstandard_steps
                                                            .controls[
                                                            'project_analysis_data'
                                                          ]['controls'][i][
                                                            'controls'
                                                          ]
                                                            ?.project_plan_requirements[
                                                            'controls'
                                                          ][ii]['controls']
                                                            ?.levels[
                                                            'controls'
                                                          ][iii]['controls']
                                                            .level_occupancy
                                                            .touched &&
                                                          getstandard_steps
                                                            .controls[
                                                            'project_analysis_data'
                                                          ]['controls'][i][
                                                            'controls'
                                                          ]
                                                            ?.project_plan_requirements[
                                                            'controls'
                                                          ][ii]['controls']
                                                            ?.levels[
                                                            'controls'
                                                          ][iii]['controls']
                                                            .level_occupancy
                                                            .errors
                                                            ?.required)
                                                        ">
                                                        {{
                                                        "lang_field_required"
                                                        |
                                                        language
                                                        }}
                                                      </mat-error>
                                                    </mat-form-field>
                                                    </td>
                                                    <td><a class="danger"
                                                      style="position: relative;top: -8px;"
                                                      (click)="
                                                        deleteSUbLevel(
                                                          i,
                                                          ii,
                                                          iii
                                                        )
                                                      " matTooltip="{{
                                                        'lang_delete_level'
                                                          | language
                                                      }}">
                                                      <i
                                                        class="fa fa-trash deleteICO font-medium-3 m-2"></i>
                                                    </a>
                                                  </td>
                                                  </tr>
                                                </ng-container>
                                                   
                                                  </tbody>
                                                </table>
                                              </th>
                                            </tr>
                                          
                                          </ng-container>
                                                
                                              </ng-container>
                                            
                                           
                                            </tbody>
                                          </table>

                                      <!--     <div class="col-md-6 my-3 mx-auto"
                                            [ngClass]="{
                                              'col-md-12':
                                                getSmallSteps
                                                  .project_analysis_data?.value[i]
                                                  .project_plan_requirements
                                                  .length == 1
                                            }" formArrayName="project_plan_requirements" *ngFor="
                                              let creds2 of getSmallSteps
                                                .project_analysis_data?.value[i]
                                                .project_plan_requirements;
                                              let ii = index;
                                              trackBy: trackByFnCustom
                                            ">
                                            <div class="card">
                                              <div class="card-body"
                                                [formGroupName]="ii">
                                                <div class="row">
                                                  <div class="col-6 col-sm-6 mb-3"
                                                    [dir]="
                                                      lang_key == 'ar'
                                                        ? 'rtl'
                                                        : 'ltr'
                                                    ">
                                                    {{
                                                    "lang_level_no" |
                                                    language
                                                    }}. {{ ii + 1 }}
                                                  </div>
  
                                                  <div class="form-group col-md-6 mb-1"
                                                    style="text-align: right">
                                                    <a class="danger"
                                                      (click)="deleteLevel(i, ii)"
                                                      *ngIf="ii != 0"
                                                      matTooltip="{{
                                                        'lang_delete_level'
                                                          | language
                                                      }}">
                                                      <i
                                                        class="fa fa-trash deleteICO font-medium-3 m-2"></i>
                                                    </a>
                                                  </div>
                                                  <div class="col-12 col-sm-12"
                                                    [dir]="
                                                      lang_key == 'ar'
                                                        ? 'rtl'
                                                        : 'ltr'
                                                    ">
                                                    <mat-form-field
                                                      style="width: 100%"
                                                      appearance="outline">
                                                      <mat-label>{{
                                                        "lang_levels_total"
                                                        | language
                                                        }}
                                                      </mat-label>
  
                                                      <input matInput
                                                        (keypress)="
                                                          isNumber($event)
                                                        " type="number" formControlName="levels_total" required />
  
                                                      <mat-error
                                                        *ngIf="
                                                          (getstandard_steps
                                                            .controls[
                                                            'project_analysis_data'
                                                          ]['controls'][i][
                                                            'controls'
                                                          ]
                                                            ?.project_plan_requirements[
                                                            'controls'
                                                          ][ii]['controls']
                                                            ?.levels_total
                                                            .touched &&
                                                          getstandard_steps
                                                            .controls[
                                                            'project_analysis_data'
                                                          ]['controls'][i][
                                                            'controls'
                                                          ]
                                                            ?.project_plan_requirements[
                                                            'controls'
                                                          ][ii]['controls']
                                                            ?.levels_total.errors
                                                            ?.required)
                                                        ">
                                                        {{
                                                        "lang_field_required"
                                                        | language
                                                        }}
                                                      </mat-error>
  
                                                      <mat-error
                                                        *ngIf="
                                                          (getstandard_steps
                                                            .controls[
                                                            'project_analysis_data'
                                                          ]['controls'][i][
                                                            'controls'
                                                          ]
                                                            ?.project_plan_requirements[
                                                            'controls'
                                                          ][ii]['controls']
                                                            ?.levels_total
                                                            .touched &&
                                                          getstandard_steps
                                                            .controls[
                                                            'project_analysis_data'
                                                          ]['controls'][i][
                                                            'controls'
                                                          ]
                                                            ?.project_plan_requirements[
                                                            'controls'
                                                          ][ii]['controls']
                                                            ?.levels_total.errors
                                                            ?.notEqual)
                                                        ">
                                                        {{
                                                        "lang_sub_fields_must_be_equals"
                                                        | language
                                                        }}
                                                      </mat-error>
                                                    </mat-form-field>
                                                  </div>
                                                  <div class="card w-100"
                                                    style="
                                                      width: 100%;
                                                      box-shadow: unset;
                                                      border: unset;
                                                    " >


                                                    <table class="table">
                                                      <thead>
                                                        <tr>
                                                          <th scope="col">#
                                                            <i  class="mat-tooltip-trigger fa fa-plus-square mx-2 mnabricon  "
                                                            matTooltip="{{
                                                              'lang_add_level'
                                                                | language
                                                            }}"
                                                            (click)="addLevels(i, ii)"
                                                            ></i>
                                                          </th>
                                                          <th scope="col">{{
                                                            "lang_level_value"
                                                            |
                                                            language
                                                            }}</th>
                                                          <th scope="col">{{
                                                            "lang_level_occupancy"
                                                            |
                                                            language
                                                            }}</th>
                                                          <th scope="col">Action</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <ng-container formArrayName="levels" *ngFor="
                                                        let creds2 of getSmallSteps
                                                          .project_analysis_data
                                                          ?.value[i]
                                                          .project_plan_requirements[
                                                          ii
                                                        ].levels;
                                                        let iii = index;
                                                        trackBy: trackByFnCustom
                                                      ">
                                                      <tr [formGroupName]="iii">
                                                        <th scope="row">1</th>
                                                        <td>
                                                          <mat-form-field
                                                          style="width: 100%"
                                                          appearance="outline">
                                                          
    
                                                          <input
                                                            matInput
                                                            (keypress)="
                                                              isNumber($event)
                                                            " 
                                                            (keyup)="checkValidation(getstandard_steps
                                                            .controls['project_analysis_data']['controls'][i]['controls']?.project_plan_requirements['controls'][ii])
                                                          "
                                                            
                                                            type="number" formControlName="level_value" required />
    
                                                          <mat-error
                                                            *ngIf="
                                                              (getstandard_steps
                                                                .controls[
                                                                'project_analysis_data'
                                                              ]['controls'][i][
                                                                'controls'
                                                              ]
                                                                ?.project_plan_requirements[
                                                                'controls'
                                                              ][ii]['controls']
                                                                ?.levels[
                                                                'controls'
                                                              ][iii]['controls']
                                                                .level_value
                                                                .touched &&
                                                              getstandard_steps
                                                                .controls[
                                                                'project_analysis_data'
                                                              ]['controls'][i][
                                                                'controls'
                                                              ]
                                                                ?.project_plan_requirements[
                                                                'controls'
                                                              ][ii]['controls']
                                                                ?.levels[
                                                                'controls'
                                                              ][iii]['controls']
                                                                .level_value
                                                                .errors
                                                                ?.required)
                                                            ">
                                                            {{
                                                            "lang_field_required"
                                                            |
                                                            language
                                                            }}
                                                          </mat-error>
                                                        </mat-form-field>
                                                        </td>
                                                        <td>
                                                          <mat-form-field
                                                          style="width: 100%"
                                                          appearance="outline">
                                                     
    
                                                            <mat-select  formControlName="level_occupancy">
                                                              <mat-option *ngFor="let ut of occupancies_list" [value]="ut.value">
                                                                {{ ut.label }}
                                                              </mat-option>
                                                            </mat-select>
    
                                                          <mat-error
                                                            *ngIf="
                                                              (getstandard_steps
                                                                .controls[
                                                                'project_analysis_data'
                                                              ]['controls'][i][
                                                                'controls'
                                                              ]
                                                                ?.project_plan_requirements[
                                                                'controls'
                                                              ][ii]['controls']
                                                                ?.levels[
                                                                'controls'
                                                              ][iii]['controls']
                                                                .level_occupancy
                                                                .touched &&
                                                              getstandard_steps
                                                                .controls[
                                                                'project_analysis_data'
                                                              ]['controls'][i][
                                                                'controls'
                                                              ]
                                                                ?.project_plan_requirements[
                                                                'controls'
                                                              ][ii]['controls']
                                                                ?.levels[
                                                                'controls'
                                                              ][iii]['controls']
                                                                .level_occupancy
                                                                .errors
                                                                ?.required)
                                                            ">
                                                            {{
                                                            "lang_field_required"
                                                            |
                                                            language
                                                            }}
                                                          </mat-error>
                                                        </mat-form-field>
                                                        </td>
                                                        <td><a class="danger"
                                                          style="position: relative;top: -8px;"
                                                          (click)="
                                                            deleteSUbLevel(
                                                              i,
                                                              ii,
                                                              iii
                                                            )
                                                          " matTooltip="{{
                                                            'lang_delete_level'
                                                              | language
                                                          }}">
                                                          <i
                                                            class="fa fa-trash deleteICO font-medium-3 m-2"></i>
                                                        </a>
                                                      </td>
                                                      </tr>
                                                    </ng-container>
                                                       
                                                      </tbody>
                                                    </table>

                                                    <div class="card-body"
                                                      style="padding: 0 1.25rem"
                                                      [formGroupName]="iii">
                                                      <div
                                                        class="row">
                                                        <div class="col-6 col-sm-6 mb-2"
                                                          [dir]="
                                                            lang_key == 'ar'
                                                              ? 'rtl'
                                                              : 'ltr'
                                                          ">
                                                          {{
                                                          "lang_sub_level_no"
                                                          |
                                                          language
                                                          }}. {{
                                                          iii + 1
                                                          }}
                                                        </div>
  
                                                        <div class="form-group col-md-6 mb-1"
                                                          style="
                                                            text-align: right;
                                                          ">
                                                          <a class="danger"
                                                            (click)="
                                                              deleteSUbLevel(
                                                                i,
                                                                ii,
                                                                iii
                                                              )
                                                            " matTooltip="{{
                                                              'lang_delete_level'
                                                                | language
                                                            }}">
                                                            <i
                                                              class="fa fa-trash deleteICO font-medium-3 m-2"></i>
                                                          </a>
                                                        </div>
                                                        <div class="col-12 col-sm-6"
                                                          [dir]="
                                                            lang_key == 'ar'
                                                              ? 'rtl'
                                                              : 'ltr'
                                                          ">
                                                          <mat-form-field
                                                            style="width: 100%"
                                                            appearance="outline">
                                                            <mat-label>
                                                              {{
                                                              "lang_level_value"
                                                              |
                                                              language
                                                              }}
                                                            </mat-label>
  
                                                            <input
                                                              matInput
                                                              (keypress)="
                                                                isNumber($event)
                                                              " 
                                                              (keyup)="checkValidation(getstandard_steps
                                                              .controls['project_analysis_data']['controls'][i]['controls']?.project_plan_requirements['controls'][ii])
                                                            "
                                                              
                                                              type="number" formControlName="level_value" required />
  
                                                            <mat-error
                                                              *ngIf="
                                                                (getstandard_steps
                                                                  .controls[
                                                                  'project_analysis_data'
                                                                ]['controls'][i][
                                                                  'controls'
                                                                ]
                                                                  ?.project_plan_requirements[
                                                                  'controls'
                                                                ][ii]['controls']
                                                                  ?.levels[
                                                                  'controls'
                                                                ][iii]['controls']
                                                                  .level_value
                                                                  .touched &&
                                                                getstandard_steps
                                                                  .controls[
                                                                  'project_analysis_data'
                                                                ]['controls'][i][
                                                                  'controls'
                                                                ]
                                                                  ?.project_plan_requirements[
                                                                  'controls'
                                                                ][ii]['controls']
                                                                  ?.levels[
                                                                  'controls'
                                                                ][iii]['controls']
                                                                  .level_value
                                                                  .errors
                                                                  ?.required)
                                                              ">
                                                              {{
                                                              "lang_field_required"
                                                              |
                                                              language
                                                              }}
                                                            </mat-error>
                                                          </mat-form-field>
                                                        </div>
                                                        <div class="col-12 col-sm-6"
                                                          [dir]="
                                                            lang_key == 'ar'
                                                              ? 'rtl'
                                                              : 'ltr'
                                                          ">
                                                          <mat-form-field
                                                            style="width: 100%"
                                                            appearance="outline">
                                                            <mat-label>
                                                              {{
                                                              "lang_level_occupancy"
                                                              |
                                                              language
                                                              }}
                                                            </mat-label>
   
  
                                                              <mat-select  formControlName="level_occupancy">
                                                                <mat-option *ngFor="let ut of occupancies_list" [value]="ut.value">
                                                                  {{ ut.label }}
                                                                </mat-option>
                                                              </mat-select>
  
                                                            <mat-error
                                                              *ngIf="
                                                                (getstandard_steps
                                                                  .controls[
                                                                  'project_analysis_data'
                                                                ]['controls'][i][
                                                                  'controls'
                                                                ]
                                                                  ?.project_plan_requirements[
                                                                  'controls'
                                                                ][ii]['controls']
                                                                  ?.levels[
                                                                  'controls'
                                                                ][iii]['controls']
                                                                  .level_occupancy
                                                                  .touched &&
                                                                getstandard_steps
                                                                  .controls[
                                                                  'project_analysis_data'
                                                                ]['controls'][i][
                                                                  'controls'
                                                                ]
                                                                  ?.project_plan_requirements[
                                                                  'controls'
                                                                ][ii]['controls']
                                                                  ?.levels[
                                                                  'controls'
                                                                ][iii]['controls']
                                                                  .level_occupancy
                                                                  .errors
                                                                  ?.required)
                                                              ">
                                                              {{
                                                              "lang_field_required"
                                                              |
                                                              language
                                                              }}
                                                            </mat-error>
                                                          </mat-form-field>
                                                        </div>
                                                      </div>
                                                    </div>
                                                
                                                  </div>
                                              
                                                </div>
                                              </div>
                                            </div>
                                          </div> -->
                                        </div>
                                      
                                          <mat-action-row>
                                            <button mat-button color="warn"  type="button"  (click)="prevStep()">Previous</button>
                                            <!-- <button mat-button color="primary"  type="button"  (click)="nextStep()">End</button> -->
                                          </mat-action-row>
                                        </mat-expansion-panel>
                                      
                                      </mat-accordion>

															

																                   			 
																			<!-- <div class="col-12 w-100 m-auto"
																				style="justify-content: space-between">
																				<div class="col-md-12 my-3 mx-auto"
																					formArrayName="plan_quantities"
																					*ngFor="
																						let creds3 of getSmallSteps
																							.project_analysis_data?.value[i]
																							.plan_quantities;
																						let ii = index;
																						trackBy: trackByFnCustom
																					">
																					<div class="row"
																						[formGroupName]="ii">
																						<div class="col-6 col-sm-6 mb-3"
																							[dir]="
																								lang_key == 'ar' ? 'rtl' : 'ltr'
																							">
																							{{
																							"lang_plan_quantity_no"
																							| language
																							}}. {{ ii + 1 }}

																							 
																						</div>

																						<div class="form-group col-md-6 mb-1"
																							style="text-align: right">
																							<a class="danger" (click)="
																									delete_plan_quantities(i, ii)
																								" *ngIf="ii != 0" matTooltip="{{
																									'lang_delete_plan_quantity'
																										| language
																								}}">
																								<i
																									class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																							</a>
																						</div>

																						<div class="col-6 col-sm-6"
																							[dir]="
																								lang_key == 'ar' ? 'rtl' : 'ltr'
																							">
																							<mat-form-field
																								style="width: 100%"
																								appearance="outline">
																								<mat-label>{{
																									"lang_plan_name" |
																									language
																									}}</mat-label>
																								<mat-select

																								(selectionChange)="updateTotalPlanQuantitiyAfterChangePlan(getstandard_steps.controls[
																										'project_analysis_data'
																									]['controls'][i]['controls']
																										?.plan_quantities[
																										'controls'
																									][ii]['controls'])"
																								 
																									formControlName="plan_keyword">
																									<mat-option 
																									
																									*ngFor="
																											let ut of getstandard_steps
																												.controls[
																												'project_plan_requirements'
																											]['controls']
																										" [value]="
																											ut['controls']
																												?.plan_keyword.value
																										">
																										{{
																										lang_key == "en"
																										? ut["controls"]
																										?.plan_title_english
																										.value
																										: ut["controls"]
																										?.plan_title_arabic
																										.value
																										}}
																									</mat-option>
																								</mat-select>
																								<mat-error *ngIf="
																										(getstandard_steps.controls[
																											'project_analysis_data'
																										]['controls'][i]['controls']
																											?.plan_quantities[
																											'controls'
																										][ii]['controls']
																											?.plan_keyword.touched &&
																										getstandard_steps.controls[
																											'project_analysis_data'
																										]['controls'][i]['controls']
																											?.plan_quantities[
																											'controls'
																										][ii]['controls']
																											?.plan_keyword.errors
																											?.required)
																									">
																									{{
																									"lang_field_required"
																									| language
																									}}
																								</mat-error>
																							</mat-form-field>
																						</div>

																						<div class="col-6 col-sm-6"
																							[dir]="
																								lang_key == 'ar' ? 'rtl' : 'ltr'
																							">
																							<mat-form-field
																								style="width: 100%"
																								appearance="outline">
																								<mat-label>{{
																									"lang_plan_quantity_count"
																									| language
																									}}</mat-label>

																								<input matInput
																									(keypress)="isNumber($event)"
																									type="number"
																									(keyup)="updateTotalPlanQuantitiyAfterChangePlan(getstandard_steps.controls[
																										'project_analysis_data'
																									]['controls'][i]['controls']
																										?.plan_quantities[
																										'controls'
																									][ii]['controls'])"
																									formControlName="plan_quantity_count"
																									required />

																								<mat-error *ngIf="
																										(getstandard_steps.controls[
																											'project_analysis_data'
																										]['controls'][i]['controls']
																											?.plan_quantities[
																											'controls'
																										][ii]['controls']
																											?.plan_quantity_count
																											.touched &&
																										getstandard_steps.controls[
																											'project_analysis_data'
																										]['controls'][i]['controls']
																											?.plan_quantities[
																											'controls'
																										][ii]['controls']
																											?.plan_quantity_count
																											.errors?.required)
																									">
																									{{
																									"lang_field_required"
																									| language
																									}}
																								</mat-error>
																							</mat-form-field>
																						</div>
																					</div>
																				</div>
																				<a (click)="add_plan_quantities(i)"
																					class="btn customButton mb-4 mx-3">
																					{{
																					"lang_add_plan_quantity" | language
																					}}
																				</a>
																			</div> -->

																		
																		</div>
																		<hr width="70%" class="m-auto" />
																	</div>
																</div>
															</mat-tab>
														</mat-tab-group>
													</div>
												</form>
											</div>
											<div id="test-l-4" class="content">
												<form [formGroup]="getSmallSteps.surveying_costs">
													<div class="col-12 col-sm-6 mt-1 mb-3">
														<!-- <div class="d-flex justify-content-between">
														
														</div> -->

														<mat-label style="margin: 15px 5px; font-size: 17px">{{
															"lang_surveying_business_costs_status"
															| language
															}}
														</mat-label>
														<mat-radio-group aria-labelledby="example-radio-group-label"
															formControlName="surveying_business_costs_status">
															<mat-radio-button
																*ngFor="let soil_sensor of soil_sensor_status"
																(change)="surveying_business_costs($event)"
																[value]="soil_sensor.value">
																{{ soil_sensor.label }}
															</mat-radio-button>
														</mat-radio-group>

														<mat-error *ngIf="
																getSmallSteps.surveying_costs.get(
																	'surveying_business_costs_status'
																).touched &&
																getSmallSteps.surveying_costs.get(
																	'surveying_business_costs_status'
																).errors?.required
															">
															{{ "lang_field_required" | language }}
														</mat-error>
													</div>

													<div class="col-12" *ngIf="
															getSmallSteps.surveying_costs.value
																?.surveying_business_costs_status == 0
														" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
														<mat-form-field appearance="outline" style="width: 100%">
															<mat-label>{{
																"lang_surveying_business_costs_legal_notes"
																| language
																}}</mat-label>
															<textarea matInput
																formControlName="surveying_business_costs_legal_notes"
																rows="5"></textarea>
															<mat-error *ngIf="
																	getSmallSteps.surveying_costs.get(
																		'surveying_business_costs_legal_notes'
																	).touched &&
																	getSmallSteps.surveying_costs.get(
																		'surveying_business_costs_legal_notes'
																	).errors?.required
																">
																{{ "lang_field_required" | language }}
															</mat-error>
														</mat-form-field>
													</div>

													<ng-container *ngIf="
															getSmallSteps.surveying_costs.value
																?.surveying_business_costs_status == 1
														">
														<div class="form-group w-100">
															<a (click)="addSurveyingCosts()"
																class="btn customButton mb-4">
																{{ "lang_add_surveying_cost" | language }}
															</a>

															<table class="table table-hover">
																<thead>
																  <tr>
																	<th scope="col">#</th>
																	<th scope="col">{{
																		"lang_surveying_title_english"
																		| language
																		}}</th>
																	<th scope="col">{{
																		"lang_surveying_title_arabic"
																		| language
																		}}</th>
																	<th scope="col">{{
																		"lang_surveying_cost_value"
																		| language
																		}}</th>
																	<th scope="col">{{
																		"lang_surveying_cost_total_value"
																		| language
																		}}</th>
																	<th scope="col">{{"lang_action"|language}}</th>
																  </tr>
																</thead>
																<tbody>
																	<ng-container formArrayName="surveying_costs"
																	*ngFor="
																	let creds of getSmallSteps.surveying_costs
																		?.value.surveying_costs;
																	let i = index;
																	trackBy: trackByFnCustom
																    ">
																    	<tr [formGroupName]="i" >
																    		<th scope="row">{{i+1}}</th>
																    		<td>
																				<div class="col-12 col-sm-12"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																			<!-- 	<mat-label>{{
																					"lang_surveying_title_english"
																					| language
																					}}</mat-label> -->

																				<input matInput type="text"
																				 
																					formControlName="surveying_title_english"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_english
																							.touched &&
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_english.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																			</td>
																    		<td>
																				<div class="col-12 col-sm-12"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<!-- <mat-label>{{
																					"lang_surveying_title_arabic"
																					| language
																					}}</mat-label> -->

																				<input matInput type="text"
																					formControlName="surveying_title_arabic"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_arabic
																							.touched &&
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_arabic.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>
																			</td>
																    		<td>
																				<div class="col-12 col-sm-12"
																				[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																				<mat-form-field style="width: 100%"
																					appearance="outline">
																					<!-- <mat-label>{{
																						"lang_surveying_cost_value" |
																						language
																						}}</mat-label> -->
	
																					<input matInput
																						(keypress)="isNumber($event)"
																						(keyup)="calcTotalForServaying(i)"
																						type="number"
																						formControlName="surveying_cost_value"
																						required />
	
																					<mat-error *ngIf="
																							getstandard_steps.controls[
																								'surveying_costs'
																							]['controls'].surveying_costs[
																								'controls'
																							][i]['controls']
																								?.surveying_cost_value.touched &&
																							getstandard_steps.controls[
																								'surveying_costs'
																							]['controls'].surveying_costs[
																								'controls'
																							][i]['controls']
																								?.surveying_cost_value.errors
																								?.required
																						">
																						{{ "lang_field_required" | language
																						}}
																					</mat-error>
																				</mat-form-field>
																			</div>
																			</td>

																			<td>
																				<div class="col-12 col-sm-12"
																				[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																				<mat-form-field style="width: 100%"
																					appearance="outline">
																					<!-- <mat-label>{{
																						"lang_surveying_cost_total_value"
																						| language
																						}}</mat-label> -->
	
																					<input matInput
																						(keypress)="isNumber($event)"
																						[readonly]="true" type="number"
																						formControlName="surveying_cost_total_value"
																						disabled required />
																					<mat-error *ngIf="
																							getstandard_steps.controls[
																								'surveying_costs'
																							]['controls'].surveying_costs[
																								'controls'
																							][i]['controls']
																								?.surveying_cost_total_value
																								.touched &&
																							getstandard_steps.controls[
																								'surveying_costs'
																							]['controls'].surveying_costs[
																								'controls'
																							][i]['controls']
																								?.surveying_cost_total_value
																								.errors?.required
																						">
																						{{ "lang_field_required" | language
																						}}
																					</mat-error>
																				</mat-form-field>
																			</div>
																			</td>

																			<td>
																				<a class="danger" *ngIf="i != 0"
																				(click)="deleteServayingCost(i)"
																				matTooltip="{{
																					'lang_delete_surveying_cost'
																						| language
																				}}">
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																			</a>
																			</td>
																    	</tr>

																	</ng-container>

																  
																</tbody>
															  </table>

															<!-- <div formArrayName="surveying_costs" class="mb-3" *ngFor="
																	let creds of getSmallSteps.surveying_costs
																		?.value.surveying_costs;
																	let i = index;
																	trackBy: trackByFnCustom
																">
																<div [formGroupName]="i"
																	[ngClass]="{ borderTop: i != 0 }">
																	<div class="row">
																		<div class="form-group col-md-6 mb-1">
																			<h5 class="head">
																				{{
																				"lang_surveying_cost_no" | language
																				}}. {{ i + 1 }}
																			</h5>
																		</div>
																		<div class="form-group col-md-6 mb-1"
																			style="text-align: right">
																			<a class="danger" *ngIf="i != 0"
																				(click)="deleteServayingCost(i)"
																				matTooltip="{{
																					'lang_delete_surveying_cost'
																						| language
																				}}">
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																			</a>
																		</div>
																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_surveying_title_english"
																					| language
																					}}</mat-label>

																				<input matInput type="text"
																				 
																					formControlName="surveying_title_english"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_english
																							.touched &&
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_english.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_surveying_title_arabic"
																					| language
																					}}</mat-label>

																				<input matInput type="text"
																					formControlName="surveying_title_arabic"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_arabic
																							.touched &&
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_arabic.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_surveying_cost_value" |
																					language
																					}}</mat-label>

																				<input matInput
																					(keypress)="isNumber($event)"
																					(keyup)="calcTotalForServaying(i)"
																					type="number"
																					formControlName="surveying_cost_value"
																					required />

																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_cost_value.touched &&
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_cost_value.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_surveying_cost_total_value"
																					| language
																					}}</mat-label>

																				<input matInput
																					(keypress)="isNumber($event)"
																					[readonly]="true" type="number"
																					formControlName="surveying_cost_total_value"
																					disabled required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_cost_total_value
																							.touched &&
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_cost_total_value
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>
																	</div>
																	<hr width="70%" class="m-auto" />
																</div>
															</div> -->
														</div>
													</ng-container>
												</form>
											</div>
											<div id="test-l-5" class="content">
												<form [formGroup]="getSmallSteps.printing_costs">
													<div class="col-12 col-sm-6 mt-1 mb-3">
														<!-- <div class="d-flex justify-content-between">
															
														</div> -->
														<mat-label style="margin: 15px 5px; font-size: 17px">{{
															"lang_printing_costs_status" | language }}
														</mat-label>
														<mat-radio-group aria-labelledby="example-radio-group-label"
															formControlName="printing_costs_status">
															<mat-radio-button
																*ngFor="let soil_sensor of soil_sensor_status"
																(change)="printing_business_costs($event)"
																[value]="soil_sensor.value">
																{{ soil_sensor.label }}
															</mat-radio-button>
														</mat-radio-group>

														<mat-error *ngIf="
																getSmallSteps.printing_costs.get(
																	'printing_costs_status'
																).touched &&
																getSmallSteps.printing_costs.get(
																	'printing_costs_status'
																).errors?.required
															">
															{{ "lang_field_required" | language }}
														</mat-error>
													</div>

													<div class="col-12" *ngIf="
															getSmallSteps.printing_costs.value
																?.printing_costs_status == 0
														" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
														<mat-form-field appearance="outline" style="width: 100%">
															<mat-label>{{
																"lang_printing_costs_legal_notes" | language
																}}</mat-label>
															<textarea matInput
																formControlName="printing_costs_legal_notes"
																rows="5"></textarea>
															<mat-error *ngIf="
																	getSmallSteps.printing_costs.get(
																		'printing_costs_legal_notes'
																	).touched &&
																	getSmallSteps.printing_costs.get(
																		'printing_costs_legal_notes'
																	).errors?.required
																">
																{{ "lang_field_required" | language }}
															</mat-error>
														</mat-form-field>
													</div>

													<ng-container *ngIf="
															getSmallSteps.printing_costs.value
																?.printing_costs_status == 1
														">
														<div class="form-group">
															<a (click)="addPrintingCosts()"
																class="btn customButton mb-4">
																{{ "lang_add_printing_cost" | language }}
															</a>

															<table class="table table-hover ">
																<thead>
																  <tr>
																	<th scope="col">#</th>
																	<th scope="col">
                                    {{
                                      "lang_printing_title_english"
                                      | language
                                      }}
                                  </th>
																	<th scope="col">
                                    {{
                                      "lang_printing_title_arabic"
                                      | language
                                      }}
                                  </th>
																	<th scope="col">
                                    {{
                                      "lang_printing_costs_no_of_copies"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_printing_costs_no_of_panels"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_printing_costs_ratio_cost"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_action"
                                      | language
                                      }}
                                  </th>
																  </tr>
																</thead>
																<tbody>
                                  <ng-container  formArrayName="printing_costs" *ngFor="
																	let creds of getSmallSteps.printing_costs
																		?.value.printing_costs;
																	let i = index;
																	trackBy: trackByFnCustom
																">

                                    <tr [formGroupName]="i" >
                                      <th scope="row">{{i+1}}</th>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                          <!-- <mat-label>{{
                                            "lang_printing_title_english"
                                            | language
                                            }}</mat-label> -->
  
                                          <input matInput type="text"
                                            (keyup)="makekeyword($event, i)"
                                            formControlName="printing_title_english"
                                            required />
                                          <mat-error *ngIf="
                                              getstandard_steps.controls[
                                                'printing_costs'
                                              ]['controls'].printing_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.printing_title_english
                                                .touched &&
                                              getstandard_steps.controls[
                                                'printing_costs'
                                              ]['controls'].printing_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.printing_title_english.errors
                                                ?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                         <!--  <mat-label>{{
                                            "lang_printing_title_arabic"
                                            | language
                                            }}</mat-label> -->
  
                                          <input matInput type="text"
                                            formControlName="printing_title_arabic"
                                            required />
                                          <mat-error *ngIf="
                                              getstandard_steps.controls[
                                                'printing_costs'
                                              ]['controls'].printing_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.printing_title_arabic.touched &&
                                              getstandard_steps.controls[
                                                'printing_costs'
                                              ]['controls'].printing_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.printing_title_arabic.errors
                                                ?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                         <!--  <mat-label>{{
                                            "lang_printing_costs_no_of_copies"
                                            | language
                                            }}</mat-label> -->
  
                                          <input matInput
                                            (keypress)="isNumber($event)"
                                            type="number"
                                            formControlName="printing_costs_no_of_copies"
                                            required />
  
                                          <mat-error *ngIf="
                                              getstandard_steps.controls[
                                                'printing_costs'
                                              ]['controls'].printing_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.printing_costs_no_of_copies
                                                .touched &&
                                              getstandard_steps.controls[
                                                'printing_costs'
                                              ]['controls'].printing_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.printing_costs_no_of_copies
                                                .errors?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                          <!-- <mat-label>{{
                                            "lang_printing_costs_no_of_panels"
                                            | language
                                            }}</mat-label> -->
  
                                          <input matInput
                                            (keypress)="isNumber($event)"
                                            [disabled]="true" type="number"
                                            formControlName="printing_costs_no_of_panels"
                                            disabled required />
                                          <mat-error *ngIf="
                                              getstandard_steps.controls[
                                                'printing_costs'
                                              ]['controls'].printing_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.printing_costs_no_of_panels
                                                .touched &&
                                              getstandard_steps.controls[
                                                'printing_costs'
                                              ]['controls'].printing_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.printing_costs_no_of_panels
                                                .errors?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                         <!--  <mat-label>{{
                                            "lang_printing_costs_ratio_cost"
                                            | language
                                            }}</mat-label> -->
  
                                          <input matInput
                                            (keypress)="isNumber($event)"
                                            type="number"
                                            formControlName="printing_costs_ratio_cost"
                                            disabled required />
                                          <mat-error *ngIf="
                                              getstandard_steps.controls[
                                                'printing_costs'
                                              ]['controls'].printing_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.printing_costs_ratio_cost
                                                .touched &&
                                              getstandard_steps.controls[
                                                'printing_costs'
                                              ]['controls'].printing_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.printing_costs_ratio_cost.errors
                                                ?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <a class="danger" *ngIf="i != 0"
																				(click)="deletePrintingCost(i)"
																				matTooltip="{{
																					'lang_delete_printing_cost' | language
																				}}">
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																			</a>
                                      </td>
                                      </tr>
                                  </ng-container>
																 
																  
																</tbody>
															  </table>
														<!-- 	<div formArrayName="printing_costs" class="mb-3" *ngFor="
																	let creds of getSmallSteps.printing_costs
																		?.value.printing_costs;
																	let i = index;
																	trackBy: trackByFnCustom
																">
																<div [formGroupName]="i"
																	[ngClass]="{ borderTop: i != 0 }">
																	<div class="row">
																		<div class="form-group col-md-6 mb-1">
																			<h5 class="head">
																				{{
																				"lang_printing_cost_no" | language
																				}}. {{ i + 1 }}
																			</h5>
																		</div>
																		<div class="form-group col-md-6 mb-1"
																			style="text-align: right">
																			<a class="danger" *ngIf="i != 0"
																				(click)="deletePrintingCost(i)"
																				matTooltip="{{
																					'lang_delete_printing_cost' | language
																				}}">
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																			</a>
																		</div>
																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_printing_title_english"
																					| language
																					}}</mat-label>

																				<input matInput type="text"
																					(keyup)="makekeyword($event, i)"
																					formControlName="printing_title_english"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_title_english
																							.touched &&
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_title_english.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_printing_title_arabic"
																					| language
																					}}</mat-label>

																				<input matInput type="text"
																					formControlName="printing_title_arabic"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_title_arabic.touched &&
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_title_arabic.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_printing_costs_no_of_copies"
																					| language
																					}}</mat-label>

																				<input matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="printing_costs_no_of_copies"
																					required />

																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_no_of_copies
																							.touched &&
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_no_of_copies
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_printing_costs_no_of_panels"
																					| language
																					}}</mat-label>

																				<input matInput
																					(keypress)="isNumber($event)"
																					[disabled]="true" type="number"
																					formControlName="printing_costs_no_of_panels"
																					disabled required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_no_of_panels
																							.touched &&
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_no_of_panels
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_printing_costs_ratio_cost"
																					| language
																					}}</mat-label>

																				<input matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="printing_costs_ratio_cost"
																					disabled required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_ratio_cost
																							.touched &&
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_ratio_cost.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>
																	</div>
																	<hr width="70%" class="m-auto" />
																</div>
															</div> -->
														</div>
													</ng-container>
												</form>
											</div>
											<div id="test-l-6" class="content">
												<form [formGroup]="getstandard_steps">
													<div class="form-group">
														<a (click)="addEngineeringCosts()"
															class="btn customButton mb-4">
															{{ "lang_add_engineering_cost" | language }}
														</a>


                            <table class="table table-hover ">
                              <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                  {{
                                    "lang_engineering_cost_title_english"
                                    | language
                                    }}
                                </th>
                                <th scope="col">
                                  {{
                                    "lang_engineering_cost_title_arabic"
                                    | language
                                    }}
                                </th>
                                <th scope="col">
                                  {{
                                    "lang_plan"
                                    | language
                                    }}
                                </th>
                                <th scope="col">
                                  {{
                                    "lang_quantity"
                                    | language
                                    }}
                                </th>
                                <th scope="col">
                                  {{
                                    "lang_cost_per_day"
                                    | language
                                    }}
                                </th>
                                <th scope="col">
                                  {{
                                    "lang_action"
                                    | language
                                    }}
                                </th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container formArrayName="engineering_costs"  *ngFor="
																let creds of getSmallSteps.engineering_costs
																	?.value;
																let i = index;
																trackBy: trackByFnCustom
															">

                                  <tr [formGroupName]="i" >
                                    <th scope="row">{{i+1}}</th>
                                    <td>
                                      <div class="col-12 col-sm-12"
                                      [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                      <mat-form-field style="width: 100%"
                                        appearance="outline">
                                        <!-- <mat-label>{{
                                          "lang_printing_title_english"
                                          | language
                                          }}</mat-label> -->

                                          <input matInput type="text" (keyup)="
																					makekeywordForEngineeringCosts(
																						$event,
																						i
																					)
																				" formControlName="engineering_cost_title_english" required />
																			<mat-error *ngIf="
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.engineering_cost_title_english
																						.touched &&
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.engineering_cost_title_english
																						.errors?.required
																				">
																				{{ "lang_field_required" | language }}
																			</mat-error>
                                      </mat-form-field>
                                    </div>
                                    </td>
                                    <td>
                                      <div class="col-12 col-sm-12"
                                      [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                      <mat-form-field style="width: 100%"
                                        appearance="outline">
                                       <!--  <mat-label>{{
                                          "lang_printing_title_arabic"
                                          | language
                                          }}</mat-label> -->

                                          <input matInput type="text"
                                          formControlName="engineering_cost_title_arabic"
                                          required />
                                        <mat-error *ngIf="
                                            getstandard_steps.controls[
                                              'engineering_costs'
                                            ]['controls'][i]['controls']
                                              ?.engineering_cost_title_arabic
                                              .touched &&
                                            getstandard_steps.controls[
                                              'engineering_costs'
                                            ]['controls'][i]['controls']
                                              ?.engineering_cost_title_arabic
                                              .errors?.required
                                          ">
                                          {{ "lang_field_required" | language }}
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                    </td>
                                    <td>
                                      <div class="col-12 col-sm-12"
                                      [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                      <mat-form-field style="width: 100%"
                                        appearance="outline">
                                       <!--  <mat-label>{{
                                          "lang_printing_costs_no_of_copies"
                                          | language
                                          }}</mat-label> -->

                                          <mat-select

																			 
                                          formControlName="plan_keyword">
                                          <mat-option 
                                          
                                          *ngFor="
                                              let ut of getstandard_steps
                                                .controls[
                                                'project_plan_requirements'
                                              ]['controls']
                                            " [value]="
                                              ut['controls']
                                                ?.plan_keyword.value
                                            ">
                                            {{
                                            lang_key == "en"
                                            ? ut["controls"]
                                            ?.plan_title_english
                                            .value
                                            : ut["controls"]
                                            ?.plan_title_arabic
                                            .value
                                            }}
                                          </mat-option>
                                        </mat-select>
                                       
                                      <mat-error *ngIf="
                                          getstandard_steps.controls[
                                            'engineering_costs'
                                          ]['controls'][i]['controls']?.plan_keyword
                                            .touched &&
                                          getstandard_steps.controls[
                                            'engineering_costs'
                                          ]['controls'][i]['controls']?.plan_keyword
                                            .errors?.required
                                        ">
                                        {{ "lang_field_required" | language }}
                                      </mat-error>
                                      </mat-form-field>
                                    </div>
                                    </td>
                                    <td>
                                      <div class="col-12 col-sm-12"
                                      [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                      <mat-form-field style="width: 100%"
                                        appearance="outline">
                                        <!-- <mat-label>{{
                                          "lang_printing_costs_no_of_panels"
                                          | language
                                          }}</mat-label> -->

                                          <input matInput
                                          (keypress)="isNumber($event)"
                                          type="number" formControlName="quantity"
                                          required />
  
                                        <mat-error *ngIf="
                                            getstandard_steps.controls[
                                              'engineering_costs'
                                            ]['controls'][i]['controls']?.quantity
                                              .touched &&
                                            getstandard_steps.controls[
                                              'engineering_costs'
                                            ]['controls'][i]['controls']?.quantity
                                              .errors?.required
                                          ">
                                          {{ "lang_field_required" | language }}
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                    </td>
                                    <td>
                                      <div class="col-12 col-sm-12"
                                      [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                      <mat-form-field style="width: 100%"
                                        appearance="outline">
                                       <!--  <mat-label>{{
                                          "lang_printing_costs_ratio_cost"
                                          | language
                                          }}</mat-label> -->

                                          <input matInput
                                          (keypress)="isNumber($event)"
                                          type="number"
                                          formControlName="cost_per_day"
                                          required />
                                        <mat-error *ngIf="
                                            getstandard_steps.controls[
                                              'engineering_costs'
                                            ]['controls'][i]['controls']
                                              ?.cost_per_day.touched &&
                                            getstandard_steps.controls[
                                              'engineering_costs'
                                            ]['controls'][i]['controls']
                                              ?.cost_per_day.errors?.required
                                          ">
                                          {{ "lang_field_required" | language }}
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                    </td>
                                    <td>
                                      <a class="danger" *ngIf="i > 0"
                                        (click)="deleteEngineeringCosts(i)"
                                        matTooltip="{{
                                          'lang_delete_engineering_cost'
                                            | language
                                        }}">
                                        <i
                                          class="fa fa-trash deleteICO font-medium-3 m-2"></i>
                                      </a>
                                    </td>
                                    </tr>
                                </ng-container>
                               
                                
                              </tbody>
                              </table>
													<!-- 	<div formArrayName="engineering_costs" class="mb-3" *ngFor="
																let creds of getSmallSteps.engineering_costs
																	?.value;
																let i = index;
																trackBy: trackByFnCustom
															">
															<div [formGroupName]="i" [ngClass]="{ borderTop: i != 0 }">
																<div class="row">
																	<div class="form-group col-md-6 mb-1">
																		<h5 class="head">
																			{{ "lang_cost_no" | language }}.
																			{{ i + 1 }}
																		</h5>
																	</div>
																	<div class="form-group col-md-6 mb-1"
																		style="text-align: right">
																		<a class="danger" *ngIf="i > 0"
																			(click)="deleteEngineeringCosts(i)"
																			matTooltip="{{
																				'lang_delete_engineering_cost'
																					| language
																			}}">
																			<i
																				class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																		</a>
																	</div>
																	<div class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																		<mat-form-field style="width: 100%"
																			appearance="outline">
																			<mat-label>{{
																				"lang_engineering_cost_title_english"
																				| language
																				}}</mat-label>

																			<input matInput type="text" (keyup)="
																					makekeywordForEngineeringCosts(
																						$event,
																						i
																					)
																				" formControlName="engineering_cost_title_english" required />
																			<mat-error *ngIf="
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.engineering_cost_title_english
																						.touched &&
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.engineering_cost_title_english
																						.errors?.required
																				">
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>

																	<div class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																		<mat-form-field style="width: 100%"
																			appearance="outline">
																			<mat-label>{{
																				"lang_engineering_cost_title_arabic"
																				| language
																				}}</mat-label>

																			<input matInput type="text"
																				formControlName="engineering_cost_title_arabic"
																				required />
																			<mat-error *ngIf="
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.engineering_cost_title_arabic
																						.touched &&
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.engineering_cost_title_arabic
																						.errors?.required
																				">
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>

																	<div class="col-12 col-sm-3"
																	[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																	<mat-form-field style="width: 100%"
																		appearance="outline">
																		<mat-label>{{
																			"lang_plan" | language
																			}}</mat-label>

																			<mat-select

																			 
																				formControlName="plan_keyword">
																				<mat-option 
																				
																				*ngFor="
																						let ut of getstandard_steps
																							.controls[
																							'project_plan_requirements'
																						]['controls']
																					" [value]="
																						ut['controls']
																							?.plan_keyword.value
																					">
																					{{
																					lang_key == "en"
																					? ut["controls"]
																					?.plan_title_english
																					.value
																					: ut["controls"]
																					?.plan_title_arabic
																					.value
																					}}
																				</mat-option>
																			</mat-select>
																		 
																		<mat-error *ngIf="
																				getstandard_steps.controls[
																					'engineering_costs'
																				]['controls'][i]['controls']?.plan_keyword
																					.touched &&
																				getstandard_steps.controls[
																					'engineering_costs'
																				]['controls'][i]['controls']?.plan_keyword
																					.errors?.required
																			">
																			{{ "lang_field_required" | language }}
																		</mat-error>
																	</mat-form-field>
																</div>
																	<div class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																		<mat-form-field style="width: 100%"
																			appearance="outline">
																			<mat-label>{{
																				"lang_quantity" | language
																				}}</mat-label>

																			<input matInput
																				(keypress)="isNumber($event)"
																				type="number" formControlName="quantity"
																				required />

																			<mat-error *ngIf="
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']?.quantity
																						.touched &&
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']?.quantity
																						.errors?.required
																				">
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>






																	<div class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																		<mat-form-field style="width: 100%"
																			appearance="outline">
																			<mat-label>{{
																				"lang_cost_per_day" | language
																				}}</mat-label>

																			<input matInput
																				(keypress)="isNumber($event)"
																				type="number"
																				formControlName="cost_per_day"
																				required />
																			<mat-error *ngIf="
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.cost_per_day.touched &&
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.cost_per_day.errors?.required
																				">
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>
																</div>
																<hr width="70%" class="m-auto" />
															</div>
														</div> -->
													</div>
												</form>
											</div>
											<div id="test-l-7" class="content">
												<form [formGroup]="getSmallSteps.government_services">
													<div class="col-12 col-sm-6 mt-1 mb-3">
														<!-- <div class="d-flex justify-content-between">
															
														</div> -->
														<mat-label style="margin: 15px 5px; font-size: 17px">{{
															"lang_government_services_status" | language
															}}
														</mat-label>
														<mat-radio-group aria-labelledby="example-radio-group-label"
															formControlName="government_services_status">
															<mat-radio-button
																*ngFor="let soil_sensor of soil_sensor_status"
																(change)="
																	OPEN_CLOSE_GOVENTMENT_SERVICES($event)
																" [value]="soil_sensor.value">
																{{ soil_sensor.label }}
															</mat-radio-button>
														</mat-radio-group>

														<mat-error *ngIf="
																getSmallSteps.government_services.get(
																	'government_services_status'
																).touched &&
																getSmallSteps.government_services.get(
																	'government_services_status'
																).errors?.required
															">
															{{ "lang_field_required" | language }}
														</mat-error>
													</div>

													<ng-container *ngIf="
															getSmallSteps.government_services.value
																?.government_services_status == 1
														">
														<div class="form-group">
															<a (click)="addGovernmentServices()"
																class="btn customButton mb-4">
																{{ "lang_add_government_services" | language }}
															</a>

                              <table class="table table-hover ">
                                <thead>
                                  <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">
                                    {{
                                      "lang_government_services_subject_english"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_government_services_subject_arabic"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_government_services_quantity"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_government_services_ratio_cost"
                                      | language
                                      }}
                                  </th>
 
                                  <th scope="col">
                                    {{
                                      "lang_action"
                                      | language
                                      }}
                                  </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container formArrayName="government_services"  *ngFor="
                                  let creds of getSmallSteps.government_services
                                     ?.value.government_services;
                                   let i = index;
                                   trackBy: trackByFnCustom
                                 ">
  
                                    <tr [formGroupName]="i" >
                                      <th scope="row">{{i+1}}</th>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                          <!-- <mat-label>{{
                                            "lang_printing_title_english"
                                            | language
                                            }}</mat-label> -->
  
                                            <input matInput type="text"
																				 
                                            formControlName="government_services_subject_english"
                                            required />
                                          <mat-error *ngIf="
                                              getstandard_steps.controls[
                                                'government_services'
                                              ]['controls'].government_services[
                                                'controls'
                                              ][i]['controls']
                                                ?.government_services_subject_english
                                                .touched &&
                                              getstandard_steps.controls[
                                                'government_services'
                                              ]['controls'].government_services[
                                                'controls'
                                              ][i]['controls']
                                                ?.government_services_subject_english
                                                .errors?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                         <!--  <mat-label>{{
                                            "lang_printing_title_arabic"
                                            | language
                                            }}</mat-label> -->
  
                                            <input matInput type="text"
                                            formControlName="government_services_subject_arabic"
                                            required />
                                          <mat-error *ngIf="
                                              getstandard_steps.controls[
                                                'government_services'
                                              ]['controls'].government_services[
                                                'controls'
                                              ][i]['controls']
                                                ?.government_services_subject_arabic
                                                .touched &&
                                              getstandard_steps.controls[
                                                'government_services'
                                              ]['controls'].government_services[
                                                'controls'
                                              ][i]['controls']
                                                ?.government_services_subject_arabic
                                                .errors?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                         <!--  <mat-label>{{
                                            "lang_printing_costs_no_of_copies"
                                            | language
                                            }}</mat-label> -->
  
                                            <input matInput
                                            (keypress)="isNumber($event)"
                                            type="number"
                                            formControlName="government_services_quantity"
                                            required />
  
                                          <mat-error *ngIf="
                                              getstandard_steps.controls[
                                                'government_services'
                                              ]['controls'].government_services[
                                                'controls'
                                              ][i]['controls']
                                                ?.government_services_quantity
                                                .touched &&
                                              getstandard_steps.controls[
                                                'government_services'
                                              ]['controls'].government_services[
                                                'controls'
                                              ][i]['controls']
                                                ?.government_services_quantity
                                                .errors?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                          <!-- <mat-label>{{
                                            "lang_printing_costs_no_of_panels"
                                            | language
                                            }}</mat-label> -->
  
                                          	<input matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="government_services_ratio_cost"
																					disabled required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_ratio_cost
																							.touched &&
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_ratio_cost
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                   
                                      <td>
                                        <a class="danger" *ngIf="i != 0"
																				(click)="deleteGovCosts(i)" matTooltip="{{
																					'lang_delete_gov_service' | language
																				}}">
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																			</a>
                                      </td>
                                      </tr>
                                  </ng-container>
                                 
                                  
                                </tbody>
                                </table>
														<!-- 	<div formArrayName="government_services" class="mb-3"
																*ngFor="
																	let creds of getSmallSteps.government_services
																		?.value.government_services;
																	let i = index;
																	trackBy: trackByFnCustom
																">
																<div [formGroupName]="i"
																	[ngClass]="{ borderTop: i != 0 }">
																	<div class="row">
																		<div class="form-group col-md-6 mb-1">
																			<h5 class="head">
																				{{
																				"lang_government_service_no"
																				| language
																				}}. {{ i + 1 }}
																			</h5>
																		</div>
																		<div class="form-group col-md-6 mb-1"
																			style="text-align: right">
																			<a class="danger" *ngIf="i != 0"
																				(click)="deleteGovCosts(i)" matTooltip="{{
																					'lang_delete_gov_service' | language
																				}}">
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																			</a>
																		</div>
																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_government_services_subject_english"
																					| language
																					}}</mat-label>

																				<input matInput type="text"
																				 
																					formControlName="government_services_subject_english"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_subject_english
																							.touched &&
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_subject_english
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_government_services_subject_arabic"
																					| language
																					}}</mat-label>

																				<input matInput type="text"
																					formControlName="government_services_subject_arabic"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_subject_arabic
																							.touched &&
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_subject_arabic
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_government_services_quantity"
																					| language
																					}}</mat-label>

																				<input matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="government_services_quantity"
																					required />

																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_quantity
																							.touched &&
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_quantity
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_government_services_ratio_cost"
																					| language
																					}}</mat-label>

																				<input matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="government_services_ratio_cost"
																					disabled required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_ratio_cost
																							.touched &&
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_ratio_cost
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>
																	</div>
																	<hr width="70%" class="m-auto" />
																</div>
															</div> -->
														</div>
													</ng-container>
												</form>
											</div>
											<div id="test-l-8" class="content text-center">
												<form [formGroup]="getSmallSteps.other_costs">
													<div class="col-12 col-sm-6 mt-1 mb-3 text-start">
											 
														<mat-label style="margin: 15px 5px; font-size: 17px">{{
															"lang_other_costs_status" | language }}
														</mat-label>
														<mat-radio-group aria-labelledby="example-radio-group-label"
															formControlName="other_costs_status">
															<mat-radio-button
																*ngFor="let soil_sensor of soil_sensor_status"
																(change)="OPEN_CLOSE_OTHER_COSTS($event)"
																[value]="soil_sensor.value">
																{{ soil_sensor.label }}
															</mat-radio-button>
														</mat-radio-group>

														<mat-error *ngIf="
																getSmallSteps.other_costs.get(
																	'other_costs_status'
																).touched &&
																getSmallSteps.other_costs.get(
																	'other_costs_status'
																).errors?.required
															">
															{{ "lang_field_required" | language }}
														</mat-error>
													</div>

													<ng-container *ngIf="
															getSmallSteps.other_costs.value
																?.other_costs_status == 1
														">
														<div class="form-group">
															<a (click)="addOtherCosts()" class="btn customButton mb-4">
																{{ "lang_add_other_costs" | language }}
															</a>
                              <table class="table table-hover ">
                                <thead>
                                  <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">
                                    {{
                                      "lang_other_costs_subject_english"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_other_costs_subject_arabic"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_other_costs_quantity"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_other_costs_ratio_cost"
                                      | language
                                      }}
                                  </th>
 
                                  <th scope="col">
                                    {{
                                      "lang_action"
                                      | language
                                      }}
                                  </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container formArrayName="other_costs"  *ngFor="
                                  let creds of getSmallSteps.other_costs?.value
                                  .other_costs;
                                let i = index;
                                trackBy: trackByFnCustom
                                 ">
  
                                    <tr [formGroupName]="i" >
                                      <th scope="row">{{i+1}}</th>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                          <!-- <mat-label>{{
                                            "lang_printing_title_english"
                                            | language
                                            }}</mat-label> -->
  
                                          	<input matInput type="text"
																					 
																					formControlName="other_costs_subject_english"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_english
																							.touched &&
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_english
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                         <!--  <mat-label>{{
                                            "lang_printing_title_arabic"
                                            | language
                                            }}</mat-label> -->
  
                                          	<input matInput type="text"
																					formControlName="other_costs_subject_arabic"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_arabic
																							.touched &&
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_arabic
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                         <!--  <mat-label>{{
                                            "lang_printing_costs_no_of_copies"
                                            | language
                                            }}</mat-label> -->
  
                                            <input matInput
                                            (keypress)="isNumber($event)"
                                            type="number"
                                            formControlName="other_costs_quantity"
                                            required />
  
                                          <mat-error *ngIf="
                                              getstandard_steps.controls[
                                                'other_costs'
                                              ]['controls'].other_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.other_costs_quantity.touched &&
                                              getstandard_steps.controls[
                                                'other_costs'
                                              ]['controls'].other_costs[
                                                'controls'
                                              ][i]['controls']
                                                ?.other_costs_quantity.errors
                                                ?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                          <!-- <mat-label>{{
                                            "lang_printing_costs_no_of_panels"
                                            | language
                                            }}</mat-label> -->
  
                                          	<input matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="other_costs_ratio_cost"
																					disabled required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_ratio_cost
																							.touched &&
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_ratio_cost.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                   
                                      <td>
                                        <a class="danger" *ngIf="i != 0"
																				(click)="deleteOtherCosts(i)"
																				matTooltip="{{
																					'lang_delete_other_cost' | language
																				}}">
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																			</a>
                                      </td>
                                      </tr>
                                  </ng-container>
                                 
                                  
                                </tbody>
                                </table>
														<!-- 	<div formArrayName="other_costs" class="mb-3" *ngFor="
																	let creds of getSmallSteps.other_costs?.value
																		.other_costs;
																	let i = index;
																	trackBy: trackByFnCustom
																">
																<div [formGroupName]="i"
																	[ngClass]="{ borderTop: i != 0 }">
																	<div class="row">
																		<div class="form-group col-md-6 mb-1 text-start" >
																			<h5 class="head">
																				{{ "lang_other_cost_no" | language }}.
																				{{ i + 1 }}
																			</h5>
																		</div>
																		<div class="form-group col-md-6 mb-1"
																			style="text-align: right">
																			<a class="danger" *ngIf="i != 0"
																				(click)="deleteOtherCosts(i)"
																				matTooltip="{{
																					'lang_delete_other_cost' | language
																				}}">
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																			</a>
																		</div>
																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_other_costs_subject_english"
																					| language
																					}}</mat-label>

																				<input matInput type="text"
																					 
																					formControlName="other_costs_subject_english"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_english
																							.touched &&
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_english
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_other_costs_subject_arabic"
																					| language
																					}}</mat-label>

																				<input matInput type="text"
																					formControlName="other_costs_subject_arabic"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_arabic
																							.touched &&
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_arabic
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_other_costs_quantity" |
																					language
																					}}</mat-label>

																				<input matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="other_costs_quantity"
																					required />

																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_quantity.touched &&
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_quantity.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_other_costs_ratio_cost"
																					| language
																					}}</mat-label>

																				<input matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="other_costs_ratio_cost"
																					disabled required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_ratio_cost
																							.touched &&
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_ratio_cost.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>
																	</div>
																	<hr width="70%" class="m-auto" />
																</div>
															</div> -->
														</div>
													</ng-container>
												</form>
											</div>
											<div id="test-l-9" class="content text-center">
												<form [formGroup]="getSmallSteps.main_analysis_costs">
													<div class="row">
													 



														<div class="col-12 col-md-6 mb-3">
															<div class="card-item" style="background: linear-gradient(60deg, #2196f3, #3f51b5);">
																<div class="item-icon">
																	<i class="fa fa-calendar"></i>
																</div>
																<div class="item-text">
																	<h5>{{'lang_main_analysis'|language}}</h5>
																	<h4>{{getSmallSteps?.main_analysis_costs?.value?.main_analysis_total| number}}</h4>
																</div>
															</div>
														</div>
														<div class="col-12 col-md-6 mb-3">
															<div class="card-item" style="background: linear-gradient(60deg, #26dad2, #00c174);">
																<div class="item-icon">
																	<i class="fa fa-calendar"></i>
																</div>
																<div class="item-text">
																	<h5>{{'lang_total_analysis'|language}}</h5>
																	<h4>{{getSmallSteps?.main_analysis_costs?.value?.total_analysis| number}} </h4>
																</div>
															</div>
														</div>
													 
													</div>
												</form>
											</div>
											<div id="test-l-10" class="content text-center">
												<form [formGroup]="getSmallSteps.quotation_price">
													<div class="row">
														<div class="col-12 col-md-6 mb-3">
															<div class="card-item" style="background: linear-gradient(60deg, #2196f3, #3f51b5);">
																<div class="item-icon">
																	<mat-icon aria-hidden="false" aria-label="attach_money">attach_money</mat-icon>
																</div>
																<div class="item-text">
																	<h5>{{'lang_profit_percent_byplan'|language}}</h5>
																	<h4>{{getSmallSteps?.quotation_price?.value?.profit_percent_byplan| number}}</h4>
																</div>
															</div>
														</div>
														<div class="col-12 col-md-6 mb-3">
															<div class="card-item" style="background: linear-gradient(60deg, #26dad2, #00c174);">
																<div class="item-icon">
																	<mat-icon aria-hidden="false" aria-label="attach_money">attach_money</mat-icon>
																</div>
																<div class="item-text">
																	<h5>{{'lang_profit_percent_byarea'|language}}</h5>
																	<h4>{{getSmallSteps?.quotation_price?.value?.profit_percent_byarea| number}} </h4>
																</div>
															</div>
														</div>
	
	
														<div class="col-12 col-md-6 mb-3">
															<div class="card-item" style="background: linear-gradient(60deg, #ef7067, #ed3b78);">
																<div class="item-icon">
																	<mat-icon aria-hidden="false" aria-label="attach_money">attach_money</mat-icon>
																</div>
																<div class="item-text">
																	<h5>{{'lang_profit_percent_bytotalcost'|language}}</h5>
																	<h4>{{getSmallSteps?.quotation_price?.value?.profit_percent_bytotalcost| number}} </h4>
																</div>
															</div>
														</div>
														<div class="col-12 col-md-6 mb-3">
															<div class="card-item" style="background: linear-gradient(60deg, #d7688d, #cd3535)">
																<div class="item-icon">
																	 
																	<mat-icon aria-hidden="false" aria-label="attach_money">attach_money</mat-icon>
	
																</div>
																<div class="item-text">
																	<h5>{{'lang_profit_percent_byhours'|language}}</h5>
																	<h4>{{getSmallSteps?.quotation_price?.value?.profit_percent_byhours| number}} </h4>
																</div>
															</div>
														</div>


														
													<div class="col-12 col-sm-6"
													[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
													<mat-form-field style="width: 100%"
														appearance="outline">
														<mat-label>{{
															"lang_price_amount_byhand"
															| language
															}}</mat-label>

														<input matInput
															(keypress)="isNumber($event)"
															type="number"
															(keyup)="putValueInByHand()"
															formControlName="price_amount_byhand"
															disabled required />
														<mat-error *ngIf="
																getstandard_steps.controls[
																	'quotation_price'
																]['controls'].price_amount_byhand.touched &&
																getstandard_steps.controls[
																'quotation_price'
															]['controls'].price_amount_byhand.errors
																	?.required
															">
															{{ "lang_field_required" | language
															}}
														</mat-error>
													</mat-form-field>
											    	</div>
													</div>


													<div class="row p-1">
														<div class="col-md-12">
															<div class="table-responsive dropdownyes customResponsive">
																<table
																	border="1"
																	class="table CustomTable"
																>
																	<thead>
																		<tr>
																			<td>{{ "lang_quotation_prices_type" | language }}</td>
																			<td>{{ "lang_quotation_prices_amount" | language }}</td>
										
																			<td>{{ "lang_quotation_prices_profit_percentage" | language }}</td>
																			<td>{{ "lang_quotation_prices_profit_percentage_amount" | language }}</td>
																			<td>{{ "lang_quotation_prices_total_amount" | language }}</td>
																			 
																		</tr>
																	</thead>
																	<tbody class="AppendList"  >
																		<tr
																		formArrayName="quotation_prices"
																			*ngFor="
																				let item of getSmallSteps?.quotation_price?.value?.quotation_prices;
																				let i = index;
																				let odd = odd;
																				let even = even;
																				trackBy: trackByFnCustom
																			"
																			[ngClass]="{ odd: odd, even: even }"

																			
																		>
																		<ng-container [formGroupName]="i">
																			<td>
																				{{'lang_'+item.quotation_prices_type|translate }}
																			</td>
																			<td>
																				{{item.quotation_prices_amount |number}}
																			</td>
																			<td>
																				<div class="col-12 col-sm-12"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																		 

																				<input matInput
																					(keypress)="isNumber($event)"

																					(keyup)="updateQuotationPricesValuesByPercent(
																				getstandard_steps?.controls['quotation_price']['controls']?.quotation_prices[
																					'controls'][i])"
																					type="number"
																					formControlName="quotation_prices_profit_percentage"
																					required />

																				<mat-error *ngIf="
																						getstandard_steps?.controls[
																							'quotation_price'
																						]['controls']?.quotation_prices[
																							'controls'
																						][i]['controls']?.quotation_prices_profit_percentage?.touched &&
																							getstandard_steps?.controls[
																							'quotation_price'
																						]['controls']?.quotation_prices[
																							'controls'
																						][i]['controls']
																							?.quotation_prices_profit_percentage?.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																		
																				<mat-error *ngIf="
																						getstandard_steps?.controls[
																							'quotation_price'
																						]['controls']?.quotation_prices[
																							'controls'
																						][i]['controls']?.quotation_prices_profit_percentage?.touched &&
																							getstandard_steps?.controls[
																							'quotation_price'
																						]['controls']?.quotation_prices[
																							'controls'
																						][i]['controls']
																							?.quotation_prices_profit_percentage?.errors
																							?.max
																					">
																					{{ "lang_max_val_100" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																	        	</div>
																			</td>
																		
																			<td>
																				{{item.quotation_prices_profit_percentage_amount |number}}
																			</td>
																			<td>
																				{{item.quotation_prices_total_amount |number}}
																			</td>
										
																		</ng-container>
																		
																		 
																		</tr>
																	</tbody>
																</table>
																 
															</div>
														</div>
													</div>






												</form>
											</div>
											<div id="test-l-11" class="content text-center">
												<form [formGroup]="getSmallSteps.financial_managments">
													<div class="col-12 col-sm-12 mt-1 mb-3">
														<!-- <div class="d-flex justify-content-between">
														
														</div> -->
														<mat-label style="margin: 15px 5px; font-size: 17px">{{
															"lang_financial_quot_price_type" | language }}
														</mat-label>
														<mat-radio-group aria-labelledby="example-radio-group-label"
															formControlName="financial_quot_price_type">
															<mat-radio-button
																*ngFor="let fqpt of getSmallSteps?.quotation_price?.controls?.quotation_prices.value"
																(change)="make_finacial_qout_calcs($event)"
																[value]="fqpt.quotation_prices_type">
																{{ 'lang_'+fqpt.quotation_prices_type |language}}
															</mat-radio-button>
														</mat-radio-group>

														<mat-error *ngIf="
																getSmallSteps.financial_managments.get(
																	'financial_quot_price_type'
																).touched &&
																getSmallSteps.financial_managments.get(
																	'financial_quot_price_type'
																).errors?.required
															">
															{{ "lang_field_required" | language }}
														</mat-error>
													</div>

													<ng-container *ngIf="
															getSmallSteps.financial_managments.value
																?.financial_quot_price_type
														">

														<div class="row">
														<div class="col-12 col-md-6 mb-3">
															<div class="card-item" style="background: linear-gradient(60deg, #2196f3, #3f51b5);">
																<div class="item-icon">
																	<mat-icon aria-hidden="false" aria-label="attach_money">attach_money</mat-icon>
																</div>
																<div class="item-text">
																	<h5>{{'lang_total_without_vat'|language}}</h5>
																	<h4>{{getSmallSteps?.financial_managments?.value?.total_without_vat| number}}</h4>
																</div>
															</div>
														</div>
														<div class="col-12 col-md-6 mb-3">
															<div class="card-item" style="background: linear-gradient(60deg, #26dad2, #00c174);">
																<div class="item-icon">
																	<mat-icon aria-hidden="false" aria-label="attach_money">attach_money</mat-icon>
																</div>
																<div class="item-text">
																	<h5>{{'lang_total_vat'|language}}</h5>
																	<h4>{{getSmallSteps?.financial_managments?.value?.total_vat| number}} </h4>
																</div>
															</div>
														</div>
	
	
														<div class="col-12 col-md-6 mb-3">
															<div class="card-item" style="background: linear-gradient(60deg, #ef7067, #ed3b78);">
																<div class="item-icon">
																	<mat-icon aria-hidden="false" aria-label="attach_money">attach_money</mat-icon>
																</div>
																<div class="item-text">
																	<h5>{{'lang_total_all'|language}}</h5>
																	<h4>{{getSmallSteps?.financial_managments?.value?.total_all| number}} </h4>
																</div>
															</div>
														</div>
														</div>

														<div class="form-group">
															<a (click)="addFinancialManagments();dynamicValidationOnInput(getstandard_steps)" class="btn customButton mb-4">
																{{ "lang_add_financial_payments" | language }}
															</a>

                              <table class="table table-hover ">
                                <thead>
                                  <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">
                                    {{
                                      "lang_payment_title_english"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_payment_title_arabic"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_payment_stage"
                                      | language
                                      }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      "lang_payment_stage_level"
                                      | language
                                      }}
                                  </th>
 
                                  <th scope="col">
                                    {{
                                      "lang_action"
                                      | language
                                      }}
                                  </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container formArrayName="financial_payments"  *ngFor="
                                  let creds of getSmallSteps.financial_managments?.value
                                  .financial_payments;
                                let i = index;
                                trackBy: trackByFnCustom
                                 ">
  
                                    <tr [formGroupName]="i" >
                                      <th scope="row">{{i+1}}</th>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                          <!-- <mat-label>{{
                                            "lang_printing_title_english"
                                            | language
                                            }}</mat-label> -->
  
                                            <input matInput type="text"
																				 
                                            formControlName="payment_title_english"
                                            required />
                                          <mat-error *ngIf="
                                              getstandard_steps.controls[
                                                'financial_managments'
                                              ]['controls'].financial_payments[
                                                'controls'
                                              ][i]['controls']
                                                ?.payment_title_english
                                                .touched &&
                                                getstandard_steps.controls[
                                                'financial_managments'
                                              ]['controls'].financial_payments[
                                                'controls'
                                              ][i]['controls']
                                                ?.payment_title_english
                                                .errors?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                         <!--  <mat-label>{{
                                            "lang_printing_title_arabic"
                                            | language
                                            }}</mat-label> -->
  
                                            <input matInput type="text"
                                            formControlName="payment_title_arabic"
                                            required />
                                          <mat-error *ngIf="
                                                          getstandard_steps.controls[
                                                          'financial_managments'
                                                        ]['controls'].financial_payments[
                                                          'controls'
                                                        ][i]['controls']
                                                ?.payment_title_arabic
                                                .touched &&
                                                getstandard_steps.controls[
                                                'financial_managments'
                                              ]['controls'].financial_payments[
                                                'controls'
                                              ][i]['controls']
                                                ?.payment_title_arabic
                                                .errors?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                         <!--  <mat-label>{{
                                            "lang_printing_costs_no_of_copies"
                                            | language
                                            }}</mat-label> -->
  
                                            <mat-select 
                                            (selectionChange)="dynamicValidationOnInput(getstandard_steps)"
                                          formControlName="payment_stage">
                                          <mat-option *ngFor="let ut of stages"
                                          [disabled]="ut.disabled"	[value]="ut.value">
                                            {{ ut.label }}
                                          </mat-option>
                                        </mat-select>
                                                    <mat-error *ngIf="
                                                    getstandard_steps.controls[
                                                    'financial_managments'
                                                  ]['controls'].financial_payments[
                                                    'controls'
                                                  ][i]['controls']
                                                  ?.payment_stage
                                                  .touched &&
                                                  getstandard_steps.controls[
                                                  'financial_managments'
                                                ]['controls'].financial_payments[
                                                  'controls'
                                                ][i]['controls']
                                                  ?.payment_stage
                                                  .errors?.required
                                              ">
                                          {{ "lang_field_required" | language }}
                                        </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                      <td>
                                        <div class="col-12 col-sm-12"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                                        <mat-form-field style="width: 100%"
                                          appearance="outline">
                                          <!-- <mat-label>{{
                                            "lang_printing_costs_no_of_panels"
                                            | language
                                            }}</mat-label> -->
  
                                            <input matInput
                                            (keypress)="isNumber($event)"
                                            (keyup)="validatePaymentLevel(getstandard_steps.controls[
                                            'financial_managments'
                                          ]['controls'].financial_payments)"
                                            type="number"
                                            formControlName="payment_stage_level"
                                            required />
  
                                          <mat-error *ngIf="
                                                          getstandard_steps.controls[
                                                          'financial_managments'
                                                        ]['controls'].financial_payments[
                                                          'controls'
                                                        ][i]['controls']
                                                ?.payment_stage_level.touched &&
                                                getstandard_steps.controls[
                                                'financial_managments'
                                              ]['controls'].financial_payments[
                                                'controls'
                                              ][i]['controls']
                                                ?.payment_stage_level.errors
                                                ?.required
                                            ">
                                            {{ "lang_field_required" | language
                                            }}
                                          </mat-error>
  
  
                                          <mat-error *ngIf="
                                                          getstandard_steps.controls[
                                                          'financial_managments'
                                                        ]['controls'].financial_payments[
                                                          'controls'
                                                        ][i]['controls']
                                                ?.payment_stage_level.touched &&
                                                getstandard_steps.controls[
                                                'financial_managments'
                                              ]['controls'].financial_payments[
                                                'controls'
                                              ][i]['controls']
                                                ?.payment_stage_level.errors
                                                ?.MustBy_100
                                            ">
                                            {{ "lang_field_must_be_total_100" | language
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      </td>
                                   
                                      <td>
                                        <a class="danger" *ngIf="i != 0"
																				(click)="deleteFinancialPayments(i);dynamicValidationOnInput(getstandard_steps)"
																				matTooltip="{{
																					'lang_delete_financial_payments' | language
																				}}">
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																			</a>
                                      </td>
                                      </tr>
                                  </ng-container>
                                 
                                  
                                </tbody>
                                </table>
														<!-- 	<div formArrayName="financial_payments" class="mb-3" *ngFor="
																	let creds of getSmallSteps.financial_managments?.value
																		.financial_payments;
																	let i = index;
																	trackBy: trackByFnCustom
																">
																<div [formGroupName]="i"
																	[ngClass]="{ borderTop: i != 0 }">
																	<div class="row">
																		<div class="form-group col-md-6 mb-1 text-start" >
																			<h5 class="head">
																				{{ "lang_financial_payments_no" | language }}.
																				{{ i + 1 }}
																			</h5>
																		</div>
																		<div class="form-group col-md-6 mb-1"
																			style="text-align: right">
																			<a class="danger" *ngIf="i != 0"
																				(click)="deleteFinancialPayments(i);dynamicValidationOnInput(getstandard_steps)"
																				matTooltip="{{
																					'lang_delete_financial_payments' | language
																				}}">
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"></i>
																			</a>
																		</div>
																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_payment_title_english"
																					| language
																					}}</mat-label>

																				<input matInput type="text"
																				 
																					formControlName="payment_title_english"
																					required />
																				<mat-error *ngIf="
																						getstandard_steps.controls[
																							'financial_managments'
																						]['controls'].financial_payments[
																							'controls'
																						][i]['controls']
																							?.payment_title_english
																							.touched &&
																							getstandard_steps.controls[
																							'financial_managments'
																						]['controls'].financial_payments[
																							'controls'
																						][i]['controls']
																							?.payment_title_english
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_payment_title_arabic"
																					| language
																					}}</mat-label>

																				<input matInput type="text"
																					formControlName="payment_title_arabic"
																					required />
																				<mat-error *ngIf="
																			                	getstandard_steps.controls[
																			                	'financial_managments'
																			                ]['controls'].financial_payments[
																			                	'controls'
																			                ][i]['controls']
																							?.payment_title_arabic
																							.touched &&
																							getstandard_steps.controls[
																							'financial_managments'
																						]['controls'].financial_payments[
																							'controls'
																						][i]['controls']
																							?.payment_title_arabic
																							.errors?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>



																		<div class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																		<mat-form-field style="width: 100%"
																			appearance="outline">
																			<mat-label>{{
																				"lang_payment_stage" | language
																				}}</mat-label>
																			<mat-select 
																			    (selectionChange)="dynamicValidationOnInput(getstandard_steps)"
																				formControlName="payment_stage">
																				<mat-option *ngFor="let ut of stages"
																				[disabled]="ut.disabled"	[value]="ut.value">
																					{{ ut.label }}
																				</mat-option>
																			</mat-select>
																            			<mat-error *ngIf="
																            			getstandard_steps.controls[
																            			'financial_managments'
																            		]['controls'].financial_payments[
																            			'controls'
																            		][i]['controls']
																            		?.payment_stage
																            		.touched &&
																            		getstandard_steps.controls[
																            		'financial_managments'
																            	]['controls'].financial_payments[
																            		'controls'
																            	][i]['controls']
																            		?.payment_stage
																            		.errors?.required
																            ">
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>
																		<div class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
																			<mat-form-field style="width: 100%"
																				appearance="outline">
																				<mat-label>{{
																					"lang_payment_stage_level" |
																					language
																					}}</mat-label>

																				<input matInput
																					(keypress)="isNumber($event)"
																					(keyup)="validatePaymentLevel(getstandard_steps.controls[
																					'financial_managments'
																				]['controls'].financial_payments)"
																					type="number"
																					formControlName="payment_stage_level"
																					required />

																				<mat-error *ngIf="
																			                	getstandard_steps.controls[
																			                	'financial_managments'
																			                ]['controls'].financial_payments[
																			                	'controls'
																			                ][i]['controls']
																							?.payment_stage_level.touched &&
																							getstandard_steps.controls[
																							'financial_managments'
																						]['controls'].financial_payments[
																							'controls'
																						][i]['controls']
																							?.payment_stage_level.errors
																							?.required
																					">
																					{{ "lang_field_required" | language
																					}}
																				</mat-error>


																				<mat-error *ngIf="
																			                	getstandard_steps.controls[
																			                	'financial_managments'
																			                ]['controls'].financial_payments[
																			                	'controls'
																			                ][i]['controls']
																							?.payment_stage_level.touched &&
																							getstandard_steps.controls[
																							'financial_managments'
																						]['controls'].financial_payments[
																							'controls'
																						][i]['controls']
																							?.payment_stage_level.errors
																							?.MustBy_100
																					">
																					{{ "lang_field_must_be_total_100" | language
																					}}
																				</mat-error>
																			</mat-form-field>
																		</div>

																	 
																	</div>
																	<hr width="70%" class="m-auto" />
																</div>
															</div> -->
														</div>
													</ng-container>
												</form>
											</div>
											<div id="test-l-12" class="content text-center">
												<form [formGroup]="signature_form">
												 
													<div class="row mb-2">
														<div class="col-12">
															<fieldset class="cardback fieldset">
																<legend style="text-align: start;">{{ "lang_optional" | language }}</legend>
																<section class="example-section text-start" >
																	<mat-checkbox
																		*ngFor="let d of restrictions_fixed_optional_items"
																		class="inlinecheckbox"
																		[checked]="d.checked"
																		(change)="d.checked = !d.checked"
																		>{{ d.label }}</mat-checkbox
																	>
																</section>
															</fieldset>
														</div>
													</div>
													<input type="hidden" formControlName="otherTerms" />
													<div class="row mb-2" *ngIf="othersstatus">
														<div class="col-md-12 col-sm-12 col-xs-12 mb-2">
															<fieldset class="cardback fieldset">
																<legend style="text-align: start;">
																	{{ "lang_other_terms" | language }}
																	<span class="red"
																		>*
																		<mat-icon
																			class="red mnabricon"
																			matTooltip="{{ 'lang_close' | language }}"
																			(click)="disable_field('others')"
																			>close</mat-icon
																		></span
																	>
																</legend>
																<div class="table-responsive customResponsive tablefields">
																	<table
																		border="1"
																		class="table CustomTable"
																		width="100%"
																		formArrayName="others"
																	>
																		<thead>
																			<tr>
																				<td style="padding: 5px; word-break: break-all">
																					<mat-icon
																						class="mnabricon"
																						matTooltip="{{ 'lang_add' | language }}"
																						(click)="add_new_others()"
																						>add_box</mat-icon
																					>
																				</td>
																				<td style="padding: 5px; word-break: break-all">
																					{{ "lang_title_english" | language }}
																				</td>
																				<td style="padding: 5px; word-break: break-all">
																					{{ "lang_title_arabic" | language }}
																				</td>
																			</tr>
																		</thead>
																		<tbody>
																			<tr
																				*ngFor="
																					let otr of othersgroup.controls;
																					let i = index;
																					let odd = odd;
																					let even = even
																				"
																				[ngClass]="{ odd: odd, even: even }"
																				[formGroupName]="i"
																				style="background: #ffffff"
																			>
																				<td width="1%" class="verticalalignmid">
																					<mat-icon
																						class="mnabricon red"
																						matTooltip="{{ 'lang_delete' | language }}"
																						(click)="remove_other_items(i)"
																						>close</mat-icon
																					>
																				</td>
																				<td
																					style="padding: 5px; word-break: break-all"
																					style="position: relative"
																				>
																					<input
																						type="text"
																						formControlName="qror_name_en"
																						placeholder="{{ 'lang_title_english' | language }}"
																						autocomplete="off"
																						class="form-control almnabrformcontrol"
																					/>
																					<mat-error
																						*ngIf="
																							submitted &&
																							others_fields.controls[i].get('qror_name_en').errors
																								?.required
																						"
																					>
																						{{ "lang_field_required" | language }}
																					</mat-error>
																				</td>
																				<td
																					style="padding: 5px; word-break: break-all"
																					style="position: relative"
																				>
																					<input
																						type="text"
																						formControlName="qror_name_ar"
																						placeholder="{{ 'lang_title_arabic' | language }}"
																						autocomplete="off"
																						class="form-control almnabrformcontrol"
																					/>
																					<mat-error
																						*ngIf="
																							submitted &&
																							others_fields.controls[i].get('qror_name_ar').errors
																								?.required
																						"
																					>
																						{{ "lang_field_required" | language }}
																					</mat-error>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</fieldset>
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!markstatus">
															<button
																type="button"
																class="albutton mnabrbutton mat-accent"
																mat-button
																(click)="enable_field('mark')"
															>
																<i class="fa fa-plus"></i> {{ "lang_need_mark" | language }}
															</button>
														</div>
														<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!reviewstatus">
															<button
																type="button"
																class="albutton mnabrbutton mat-accent"
																mat-button
																(click)="enable_field('review')"
															>
																<i class="fa fa-plus"></i> {{ "lang_need_review" | language }}
															</button>
														</div>
														<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!viewerstatus">
															<button
																type="button"
																class="albutton mnabrbutton mat-accent"
																mat-button
																(click)="enable_field('viewer')"
															>
																<i class="fa fa-plus"></i> {{ "lang_need_viewer" | language }}
															</button>
														</div>
												 
														<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!othersstatus">
															<button
																type="button"
																class="albutton mnabrbutton mat-accent"
																mat-button
																(click)="enable_field('others')"
															>
																<i class="fa fa-plus"></i> {{ "lang_other_terms" | language }}
															</button>
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-12">
															<fieldset class="cardback fieldset">
																<legend  style="text-align: start;">{{ "lang_signature_stamp" | language }}</legend>
																<div class="row">
																	<div class="col-sm-6 col-md-4 col-xs-12">
																		<div class="form-group">
																			<label>
																				{{ "lang_human_resources_job_title_iqama" | language }} A
																			</label>
																			<input
																				type="text"
																				formControlName="signature[A][title]"
																				placeholder="{{
																					'lang_human_resources_job_title_iqama' | language
																				}} A"
																				class="form-control almnabrformcontrol"
																			/>
																			<mat-error
																				*ngIf="
																					submitted &&
																					signature_form.controls['signature[A][title]'].errors?.required
																				"
																			>
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</div>
																	</div>
																	<div class="col-sm-6 col-md-4 col-xs-12">
																		<div class="form-group">
																			<label>
																				{{ "lang_human_resources_job_title_iqama" | language }} B
																			</label>
																			<input
																				type="text"
																				formControlName="signature[B][title]"
																				placeholder="{{
																					'lang_human_resources_job_title_iqama' | language
																				}} B"
																				class="form-control almnabrformcontrol"
																			/>
																			<mat-error
																				*ngIf="
																					submitted &&
																					signature_form.controls['signature[B][title]'].errors?.required
																				"
																				>{{ "lang_field_required" | language }}</mat-error
																			>
																		</div>
																	</div>
																	<div class="col-sm-6 col-md-4 col-xs-12">
																		<div class="form-group">
																			<label>
																				{{ "lang_human_resources_job_title_iqama" | language }} C
																			</label>
																			<input
																				type="text"
																				formControlName="signature[C][title]"
																				placeholder="{{
																					'lang_human_resources_job_title_iqama' | language
																				}} C"
																				class="form-control almnabrformcontrol"
																			/>
																			<mat-error
																				*ngIf="
																					submitted &&
																					signature_form.controls['signature[C][title]'].errors?.required
																				"
																				>{{ "lang_field_required" | language }}</mat-error
																			>
																		</div>
																	</div>
																	<div class="col-sm-6 col-md-4 col-xs-12">
																		<div class="form-group">
																			<label>
																				{{ "lang_human_resources_employee_name" | language }} A
																			</label>
																			<ng-select
																				[items]="users"
																				[multiple]="false"
																				bindLabel="label"
																				bindValue="value"
																				clearAllText="Clear"
																				[searchFn]="ds.customSearchFn"
																				appendTo="body"
																				formControlName="signature[A][user_id]"
																				placeholder="{{
																					'lang_human_resources_employee_name' | language
																				}}"
																				(search)="search_users($event)"
																				(focus)="users = []"
																			>
																			</ng-select>
																			<mat-error
																				*ngIf="
																					submitted &&
																					signature_form.controls['signature[A][user_id]'].errors?.required
																				"
																				>{{ "lang_field_required" | language }}</mat-error
																			>
																		</div>
																	</div>
																	<div class="col-sm-6 col-md-4 col-xs-12">
																		<div class="form-group">
																			<label>
																				{{ "lang_human_resources_employee_name" | language }} B
																			</label>
																			<ng-select
																				[items]="users"
																				[multiple]="false"
																				bindLabel="label"
																				bindValue="value"
																				clearAllText="Clear"
																				[searchFn]="ds.customSearchFn"
																				appendTo="body"
																				formControlName="signature[B][user_id]"
																				placeholder="{{
																					'lang_human_resources_employee_name' | language
																				}}"
																				(search)="search_users($event)"
																				(focus)="users = []"
																			>
																			</ng-select>
																			<mat-error
																				*ngIf="
																					submitted &&
																					signature_form.controls['signature[B][user_id]'].errors?.required
																				"
																				>{{ "lang_field_required" | language }}</mat-error
																			>
																		</div>
																	</div>
																	<div class="col-sm-6 col-md-4 col-xs-12">
																		<div class="form-group">
																			<label>
																				{{ "lang_human_resources_employee_name" | language }} C
																			</label>
																			<ng-select
																				[items]="users"
																				[multiple]="false"
																				bindLabel="label"
																				bindValue="value"
																				clearAllText="Clear"
																				[searchFn]="ds.customSearchFn"
																				appendTo="body"
																				formControlName="signature[C][user_id]"
																				placeholder="{{
																					'lang_human_resources_employee_name' | language
																				}}"
																				(search)="search_users($event)"
																				(focus)="users = []"
																			>
																			</ng-select>
																			<mat-error
																				*ngIf="
																					submitted &&
																					signature_form.controls['signature[C][user_id]'].errors?.required
																				"
																				>{{ "lang_field_required" | language }}</mat-error
																			>
																		</div>
																	</div>
																</div>
															</fieldset>
														</div>
													</div>
													<div
														class="row mb-2"
														*ngIf="markstatus || reviewstatus || viewerstatus"
													>
														<div class="col-md-4 col-xs-12 col-sm-4 mb-2">
															<fieldset class="cardback fieldset" *ngIf="markstatus">
																<input type="hidden" formControlName="needMark" />
																<legend  style="text-align: start;">
																	{{ "lang_markers" | language }}
																	<span class="red"
																		>*
																		<mat-icon
																			class="red mnabricon red"
																			matTooltip="{{ 'lang_close' | language }}"
																			(click)="disable_field('mark')"
																			>close</mat-icon
																		>
																	</span>
																</legend>
																<ng-select
																	[items]="users"
																	[multiple]="true"
																	bindLabel="label"
																	bindValue="value"
																	clearAllText="Clear"
																	[searchFn]="ds.customSearchFn"
																	appendTo="body"
																	formControlName="marks"
																	placeholder="{{ 'lang_markers' | language }}"
																	(search)="search_users($event)"
																	(focus)="users = []"
																>
																</ng-select>
																<mat-error
																	*ngIf="submitted && signature_form.controls['marks'].errors?.required"
																	>{{ "lang_field_required" | language }}</mat-error
																>
															</fieldset>
														</div>
														<div class="col-md-4 col-xs-12 col-sm-4 mb-2">
															<fieldset class="cardback fieldset" *ngIf="reviewstatus">
																<input type="hidden" formControlName="needReview" />
																<legend  style="text-align: start;">
																	{{ "lang_reviewers" | language }}
																	<span class="red"
																		>*
																		<mat-icon
																			class="red mnabricon red"
																			matTooltip="{{ 'lang_close' | language }}"
																			(click)="disable_field('review')"
																			>close</mat-icon
																		>
																	</span>
																</legend>
																<ng-select
																	[items]="users"
																	[multiple]="true"
																	bindLabel="label"
																	bindValue="value"
																	clearAllText="Clear"
																	[searchFn]="ds.customSearchFn"
																	appendTo="body"
																	formControlName="reviews"
																	placeholder="{{ 'lang_reviewers' | language }}"
																	(search)="search_users($event)"
																	(focus)="users = []"
																>
																</ng-select>
																<mat-error
																	*ngIf="submitted && signature_form.controls['reviews'].errors?.required"
																>
																	{{ "lang_field_required" | language }}</mat-error
																>
															</fieldset>
														</div>
														<div class="col-md-4 col-xs-12 col-sm-4 mb-2">
															<fieldset class="cardback fieldset" *ngIf="viewerstatus">
																<input type="hidden" formControlName="needViewers" />
																<legend  style="text-align: start;">
																	{{ "lang_viewers" | language }}
																	<span class="red"
																		>*
																		<mat-icon
																			class="red mnabricon"
																			matTooltip="{{ 'lang_close' | language }}"
																			(click)="disable_field('viewer')"
																			>close</mat-icon
																		></span
																	>
																</legend>
																<ng-select
																	[items]="users"
																	[multiple]="true"
																	bindLabel="label"
																	bindValue="value"
																	clearAllText="Clear"
																	[searchFn]="ds.customSearchFn"
																	appendTo="body"
																	formControlName="viewers"
																	placeholder="{{ 'lang_viewers' | language }}"
																	(search)="search_users($event)"
																	(focus)="users = []"
																>
																</ng-select>
																<mat-error
																	*ngIf="submitted && signature_form.controls['viewers'].errors?.required"
																	>{{ "lang_field_required" | language }}</mat-error
																>
															</fieldset>
														</div>
													</div>
													<input type="hidden" formControlName="needAttach" />
													<div class="row mb-2" *ngIf="attachstatus">
														<div class="col-md-12 col-sm-12 col-xs-12 mb-2">
															<fieldset class="cardback fieldset">
																<legend style="text-align: start;">
																	{{ "lang_attachments" | language }}
																	<span class="red"
																		>*
																		<mat-icon
																			class="red mnabricon"
																			matTooltip="{{ 'lang_close' | language }}"
																			(click)="disable_field('attachment')"
																			>close</mat-icon
																		>
																	</span>
																	<mat-icon
																		class="mnabricon"
																		matTooltip="{{ 'lang_add' | language }}"
																		(click)="addfiles()"
																		>add_box</mat-icon
																	>
																</legend>
																<div class="table-responsive customResponsive">
																	<table
																		class="table CustomTable"
																		width="100%"
																		formArrayName="attachments"
																	>
																		<thead>
																			<tr>
																				<th style="padding: 5px; word-break: break-all">#</th>
																				<th style="padding: 5px; word-break: break-all">
																					{{ "lang_title" | language }}
																				</th>
																				<th style="padding: 5px; word-break: break-all">
																					{{ "lang_file" | language }}
																				</th>
																				<th style="padding: 5px; word-break: break-all">
																					{{ "lang_attach_with_pdf" | language }}
																				</th>
																				<th style="padding: 5px; word-break: break-all">
																					{{ "lang_official_paper" | language }}
																				</th>
																				<th style="padding: 5px; word-break: break-all">
																					{{ "lang_action" | language }}
																				</th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr
																				*ngFor="
																					let file of filesFormGroup.controls;
																					let i = index;
																					let odd = odd;
																					let even = even
																				"
																				[formGroupName]="i"
																			>
																				<td
																					class="verticalalignmid"
																					style="padding: 5px; word-break: break-all"
																				>
																					{{ i + 1 }}
																				</td>
																				<td
																					style="
																						padding: 5px;
																						word-break: break-all;
																						position: relative;
																					"
																				>
																					<input
																						type="text"
																						formControlName="attach_title"
																						placeholder="{{ 'lang_description' | language }}"
																						autocomplete="off"
																						class="form-control almnabrformcontrol"
																					/>
																					<mat-error
																						*ngIf="
																							submitted &&
																							this.attachments.controls[i].get('attach_title')
																								.errors?.required
																						"
																						>{{ "lang_field_required" | language }}</mat-error
																					>
																				</td>
																				<td
																					class="verticalalignmid"
																					style="
																						padding: 5px;
																						word-break: break-all;
																						position: relative;
																					"
																				>
																					<input
																						style="display: block"
																						name="file"
																						type="file"
																						required
																						onclick="this.value = null"
																						(change)="UploadFile($event, i)"
																						class="nghide width100"
																					/>
																					<label
																						id="uploadText{{ i }}"
																						class="albutton mat-accent"
																						mat-button
																						><i class="fa fa-upload"></i>
																					</label>
																					<mat-error
																						*ngIf="
																							submitted &&
																							this.attachments.controls[i].get('file').errors
																								?.required
																						"
																						>{{ "lang_field_required" | language }}</mat-error
																					>
																				</td>
																				<td
																					class="verticalalignmid"
																					style="padding: 5px; word-break: break-all"
																				>
																					<mat-checkbox
																						formControlName="attach_with_the_document"
																						class="checkbox-radio-button"
																					></mat-checkbox>
																				</td>
																				<td
																					class="verticalalignmid"
																					style="padding: 5px; word-break: break-all"
																				>
																					<mat-checkbox
																						formControlName="print_official_paper"
																						class="checkbox-radio-button"
																					></mat-checkbox>
																				</td>
																				<td class="verticalalignmid">
																					<mat-icon
																						class="red mnabricon"
																						matTooltip="{{ 'lang_delete' | language }}"
																						(click)="removefiles(i)"
																						>close</mat-icon
																					>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</fieldset>
														</div>
													</div>
												</form>
											</div>
										</div>
                    <div class="width-100 text-center mx-4 d-flex justify-content-between">
											<button (click)="back()" type="button"
												class="albutton mnabrbutton mat-accent mobileBTN" mat-button>
												{{ "lang_previous" | language }}
											</button>

											<button (click)="next()" type="button"
												class="albutton mnabrbutton mat-accent mobileBTN" mat-button>
												{{ "lang_next" | language }}
											</button>
										</div>
									</div>
									<div class="row">
										<div class="col-12 mb-10">
											<app-alert id="default-alert"></app-alert>
										</div>
									</div>
									<div class="row pt-3">
										<div class="col-12 col-sm-3 mb-2 mb-sm-0 text-center-small">
											<button mat-button matStepperPrevious class="previous-btn backBTN">
												<span>
													<i class="fa fa-arrow-right" style="font-size: 14px"
														*ngIf="lang_key == 'ar'"></i>
													<i class="fa fa-arrow-left" style="font-size: 14px"
														*ngIf="lang_key == 'en'"></i>

													{{ "lang_previous" | language }}
												</span>
											</button>
										</div>
										<div class="d-none d-sm-block col-sm-6"></div>
									 
										<div class="col-12 col-sm-3 text-end text-center-small">
											<button   class="albutton mnabrbutton mat-accent customBTN" mat-button
												(click)="submitForm()">
												{{ "lang_submit" | language }}
												<i class="fa fa-arrow-right" style="font-size: 14px"
													*ngIf="lang_key == 'en'"></i>
												<i class="fa fa-arrow-left" style="font-size: 14px"
													*ngIf="lang_key == 'ar'"></i>
											</button>
										</div>
									</div>
								</div>
							</form>
						</mat-step>
						<mat-step>
						 
						</mat-step>
					</mat-horizontal-stepper>
				</div>
				<div class="card-footer">
					<div class="row">
						<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
							<button type="button" routerLink="/projects/BbVUrWitLE"
								class="albutton mnabrbutton mat-accent" mat-button>
								{{ "lang_all_projects" | language }}
							</button>
						</div>
						<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
							<button type="button" (click)="quotEvent.emit()" class="albutton mnabrbutton mat-accent"
								mat-button>
								{{ "lang_go_to_project_view" | language }}
							</button>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</section>
</form>