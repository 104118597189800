<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_add" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="templateForm">
        <div class="row">
            <div class="col-12 col-sm-6">
                <div [dir]="lang_key == 'ar'? 'rtl':'ltr'">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{'lang_title' | language}}</mat-label>
                        <input matInput placeholder="{{'lang_title' | language}}" formControlName="title">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div [dir]="lang_key == 'ar'? 'rtl':'ltr'">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{'lang_description' | language}}</mat-label>
                        <input matInput placeholder="{{'lang_description' | language}}" formControlName="description">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
    <div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
            (click)="add_template()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
