<div class="d-flex align-items-center justify-content-between">
	<div class="task-title">
		<div class="tt-up">
			<i class="pi pi-credit-card"></i>
			<h3>{{ task?.title }}</h3>
		</div>
		<div class="tt-down">
			<p>
				{{ "lang_status" | language }} <span>{{ task?.task_status_name }}</span>
			</p>
		</div>
	</div>
	<div class="task-dates-bar">
		<app-task-dates-bar *ngIf="false"></app-task-dates-bar>
	</div>
	<div class="task-actions">
		<div
			class="att-box task-btn"
			style="
				display: inline-block;
				width: 65px;
				vertical-align: middle;
				height: 38px;
				margin-top: 0;
			"
			matTooltip="{{ 'lang_task' | language }} {{ 'lang_progress' | language }}"
			[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
		>
			<span>{{ task?.task_progress }}</span>
			<i
				class="pi pi-percentage mr-2 ml-2"
				style="font-size: 12px !important"
			></i>
		</div>
		<div
			class="att-box task-btn"
			*ngIf="task_status_done != '0' && task_status_done != null"
			style="
				display: inline-block;
				width: 100px;
				vertical-align: middle;
				height: 38px;
				margin-top: 0;
			"
			[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
		>
			<mat-select
				[(ngModel)]="task_status_done"
				(selectionChange)="onChangeStatusDone()"
			>
				<mat-option [value]="item?.id" *ngFor="let item of status_done_list">{{
					item?.name
				}}</mat-option>
			</mat-select>
		</div>
		<i
			class="pi pi-credit-card mnabricon"
			[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			routerLink="/tasks/view/{{ ticketID }}"
			matTooltip="{{ 'lang_ticket' | language }}"
		></i>
		<i
			class="fa fa-tasks mnabricon"
			[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			matTooltip="{{ 'lang_meetings' | language }}"
			(click)="onShowMeetings()"
		></i>
		<i
			class="fa fa-minus-circle menu-icon red"
			matTooltip="{{ 'lang_delete' | language }}"
			(click)="deleteTask()"
			*ngIf="task?.user_add_id == userId"
			[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
		></i>
		<i
			class="pi pi-check-square mnabricon"
			[popover]="checklist_popover"
			[outsideClick]="true"
			placement="bottom"
			#checklist_popover_trigger="bs-popover"
			matTooltip="{{ 'lang_checklist' | language }}"
			[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
		></i>
		<i
			class="pi pi-clock mnabricon"
			(click)="task_datesPicker.open()"
			[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			matTooltip="{{ 'lang_date' | language }}"
		>
		</i>
		<mbsc-datepicker
			[controls]="['calendar', 'time']"
			select="range"
			[touchUi]="false"
			dateFormat="YYYY/MM/DD HH:mm:ss"
			[(ngModel)]="task_dates"
			[buttons]="autoButtons"
			#task_datesPicker
			style="visibility: hidden; height: 0; width: 0; display: inline-block"
		>
		</mbsc-datepicker>
		<!-- <i
			class="pi pi-paperclip mnabricon"
			(click)="taskAttachmentInput.click()"
			[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			matTooltip="{{ 'lang_attachments' | language }}"
		>
		</i> -->
		<input
			type="file"
			hidden
			#taskAttachmentInput
			id="taskAttachmentInput"
			(change)="upload_task_attachment($event.target.files)"
		/>
		<i
			class="pi pi-calendar-plus mnabricon"
			(click)="task_reminderPicker.open()"
			matTooltip="{{ 'lang_reminder' | language }}"
			[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
		></i>
		<mbsc-datepicker
			[controls]="['calendar', 'time']"
			[touchUi]="false"
			dateFormat="YYYY/MM/DD HH:mm:ss"
			[(ngModel)]="task_reminder"
			[buttons]="reminderButtons"
			#task_reminderPicker
			style="visibility: hidden; height: 0; width: 0; display: inline-block"
		>
		</mbsc-datepicker>
	</div>
</div>

<div class="p-2" [ngClass]="{ rtl: lang_key == 'ar' }">
	<div class="row">
		<div class="col-12 col-md-5" style="min-height: 450px">
			<!-- start task-members-dates -->
			<div class="members-dates">
				<div class="task-users-container">
					<h5 class="task-users-title">{{ "lang_users" | language }}</h5>
					<div class="task-users">
						<div
							class="bhu-user"
							[popover]="user_popover"
							[outsideClick]="true"
							placement="bottom"
							(onShown)="onShowMemberCard(member)"
							*ngFor="let member of task_members | slice: 0:5"
						>
							<img
								src="assets/images/avatar.png"
								alt="avatar"
								title="{{ member?.userName }}"
								*ngIf="!member?.profile_image_64"
							/>
							<img
								src="{{ member?.profile_image_64 }}"
								alt="avatar"
								title="{{ member?.userName }}"
								*ngIf="member?.profile_image_64"
							/>
						</div>
						<div
							class="bhu-user bhu-user-count"
							*ngIf="task_members?.length > 5"
							(click)="onShowTaskUsers()"
						>
							<span>+{{ task_members?.length - 5 }}</span>
						</div>
						<div
							class="bhu-add-user task-btn"
							[popover]="taskmembers_popover"
							[outsideClick]="true"
							placement="bottom"
							[outsideClick]="false"
							(onShown)="onShowAddMember()"
							#taskmembers_popover_trigger1="bs-popover"
						>
							<i class="fa fa-plus"></i>
						</div>
					</div>
				</div>
				<div class="task-dates">
					<h5 class="task-dates-title">{{ "lang_date" | language }}</h5>
					<div class="task-dates-content task-btn">
						{{ "lang_from" | language }} {{ task?.start_date }}
						{{ "lang_to" | language }} {{ task?.end_date }}
					</div>
				</div>
				<div class="ticket-no">
					<h5 class="ticket-no-title">
						{{ "lang_ticket_number" | language }}
					</h5>
					<!-- <button
                        class="task-btn"
                        (click)="navigateTo('/tasks/view/' + task?.ticket_id)"
                    >
                        {{ task?.ticket_no }}
                    </button> -->
					<button class="task-btn">
						<i
							class="fa fa-undo mnabricon mr-2 ml-2"
							matTooltip="{{ 'lang_ticket' | language }}"
							routerLink="/tasks/view/{{ ticketID }}"
						></i>
						<span
							routerLink="/tasks/view/{{ ticketID }}"
							style="
								text-decoration: underline;
								color: #5e6c84;
								cursor: pointer;
								font-size: 14px;
							"
							>{{ task?.ticket_no }}
						</span>
						<span style="color: #5e6c84; font-size: 14px">
							- {{ task?.task_no }}</span
						>
					</button>
				</div>
				<!-- <div class="task-no">
                    <h5 class="task-no-title">
                        {{ "lang_task_number" | language }}
                    </h5>
                    <button class="task-btn">
                        {{ task?.full_task_number }}
                    </button>
                </div> -->
			</div>
			<!-- end task-members-dates -->
			<!-- start task-Description -->
			<div class="task-description">
				<div class="td-title">
					<i class="pi pi-align-left"></i>
					<h4>{{ "lang_description" | language }}</h4>
				</div>
				<div class="td-body">
					<p>{{ task.description }}</p>
				</div>
			</div>
			<!-- end task-Description -->
			<!-- start task-checklist -->
			<div
				class="task-checklist-list"
				cdkDropList
				#checklistRef="cdkDropList"
				[cdkDropListData]="checklist_arr"
				(cdkDropListDropped)="drop($event, 'checklist')"
				[id]="'checklistRef'"
			>
				<!-- start task-checklist list -->
				<div
					class="task-checklist"
					*ngFor="let checklist of checklist_arr; let checklist_index = index"
					cdkDrag
				>
					<div class="tc-header">
						<div class="tch-title">
							<i class="pi pi-check-square" cdkDragHandle></i>
							<h5 *ngIf="userId != checklist?.user_add_id">
								{{ checklist?.char_item }} - {{ checklist?.title }}
							</h5>
							<h5
								*ngIf="
									userId == checklist?.user_add_id &&
									!checklist_editable[checklist_index]?.edit
								"
							>
								{{ checklist?.char_item }} - {{ checklist?.title }}
							</h5>
							<input
								type="text"
								id="checklist_{{ checklist?.id }}"
								[value]="checklist?.title"
								[title]="checklist?.title"
								(blur)="
									onChangeTCTitle($event, checklist?.id, 'checklist');
									checklist_editable[checklist_index].edit = false
								"
								*ngIf="
									userId == checklist?.user_add_id &&
									checklist_editable[checklist_index]?.edit
								"
							/>
						</div>
						<div class="tc-action">
							<i
								class="fa fa-pencil-square-o mnabricon"
								(click)="
									onEditableChecklist(
										checklist_index,
										'checklist_' + checklist?.id,
										'checklist'
									)
								"
								*ngIf="
									userId == checklist?.user_add_id &&
									!checklist_editable[checklist_index]?.edit
								"
							></i>
							<i
								class="pi pi-eye mnabricon mr-2 ml-2"
								*ngIf="!checklist?.hideChecked"
								(click)="checklist.hideChecked = true"
								matTooltip="{{ 'lang_hide_checked_items' | language }}"
							></i>
							<i
								class="pi pi-eye-slash mnabricon mr-2 ml-2"
								*ngIf="checklist?.hideChecked"
								(click)="checklist.hideChecked = false"
								matTooltip="{{ 'lang_show_checked_items' | language }}"
							></i>
							<i
								class="fa fa-trash"
								style="color: #b04632"
								matTooltip="{{ 'lang_delete' | language }}"
								(click)="deleteChecklist(checklist?.id)"
								*ngIf="userId == checklist?.user_add_id"
							></i>
						</div>
					</div>
					<div class="tc-progress">
						<span class="tc-progress-no"
							>{{ checklist?.progress || "0" }}%</span
						>
						<div class="tc-progress-bar">
							<div
								class="tcpb-current"
								[style]="'width:' + checklist?.progress + '%'"
							></div>
						</div>
					</div>
					<div class="tc-body">
						<!-- start task-checklist list items -->
						<div
							class="tc-items"
							cdkDropList
							#checklistitemsRef="cdkDropList"
							[cdkDropListData]="checklist?.chicklist_items"
							(cdkDropListDropped)="
								drop($event, 'checklist_item', checklist_index, checklist?.id)
							"
							[id]="'checklistitemsRef'"
						>
							<ng-container
								*ngFor="
									let checklist_item of checklist?.chicklist_items;
									let checklistItemIndex = index
								"
							>
								<div
									class="tc-item"
									*ngIf="
										!(
											checklist.hideChecked == true &&
											checklist_item.is_done == '2'
										)
									"
									cdkDrag
								>
									<div class="tc-item-container">
										<div class="tci-check">
											<mat-checkbox
												[checked]="checklist_item?.checked"
												[(ngModel)]="checklist_item.checked"
												(change)="
													onChangePoint(
														$event,
														checklist_item?.check_id,
														checklist_index,
														checklistItemIndex
													)
												"
												[disabled]="checkIsDisabled"
											></mat-checkbox>
										</div>
										<div class="tci-content">
											<span class="tci-content-title">
												<h5>
													{{ checklist_item?.char_item }} -
													{{ checklist_item?.notes }}
												</h5>
												<small
													>{{ "lang_created_by" | language }}
													{{
														lang_key == "ar"
															? checklist_item?.firstname_arabic
															: checklist_item?.firstname_english
													}}
													{{
														lang_key == "ar"
															? checklist_item?.lastname_arabic
															: checklist_item?.lastname_english
													}}</small
												>
												<small> {{ "lang_to" | language }} {{ "---" }} </small>
											</span>
											<div class="tci-content-action">
												<span class="d-flex">
													<i
														class="pi pi-play mr-1 ml-1"
														(click)="
															startStopTimer(
																checklist_item,
																'start',
																checklist_index,
																checklistItemIndex
															)
														"
														matTooltip="{{ 'lang_start_timer' | language }}"
														*ngIf="!checklist_item?.start_date_timer"
													></i>
													<i
														class="pi pi-stop-circle mr-1 ml-1"
														(click)="
															startStopTimer(
																checklist_item,
																'end',
																checklist_index,
																checklistItemIndex
															)
														"
														matTooltip="{{ 'lang_stop_timer' | language }}"
														*ngIf="
															!checklist_item?.end_date_timer &&
															checklist_item?.start_date_timer
														"
													></i>
													<i
														class="pi pi-eye mr-1 ml-1"
														(click)="viewChecklistDetails(checklist_item)"
														matTooltip="{{ 'lang_view' | language }}"
													></i>
													<i
														class="fa fa-pencil-square-o mr-1 ml-1"
														(click)="openChecklistDetails(checklist_item)"
														matTooltip="{{ 'lang_edit' | language }}"
														*ngIf="checklist_item?.is_can_edit"
													></i>
													<i
														class="fa fa-trash mr-1 ml-1"
														(click)="
															deleteChecklistItem(checklist_item?.check_id)
														"
														matTooltip="{{ 'lang_delete' | language }}"
														*ngIf="checklist_item?.is_can_delete"
													></i>
													<i class="pi pi-arrows-v" cdkDragHandle></i>
												</span>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
						<!-- end task-checklist list items -->
					</div>
					<div class="tc-add" *ngIf="!checklist?.is_add">
						<button
							class="tc-open-add-btn task-btn"
							(click)="openChecklistDetails(null, checklist?.id)"
						>
							<i class="pi pi-plus"></i>
							<span
								>{{ "lang_add" | language }} {{ "lang_items" | language }}</span
							>
						</button>
					</div>
					<!-- end add Checklist -->
				</div>
				<!-- end task-checklist list -->
			</div>
			<!-- end task-checklist -->
		</div>
		<div class="col-12 col-md-7" style="min-height: 450px">
			<app-checklist-details-view-floor
				[point]="selectedPointForView"
				(hideView)="onHidePointView($event)"
				*ngIf="showPointView"
			></app-checklist-details-view-floor>
			<app-checklist-details-floor
				[point]="selectedPointForUpdate"
				[parent_check_id]="parent_check_id_update"
				(hideView)="onHidePointAdd($event)"
				*ngIf="showPointAdd"
			></app-checklist-details-floor>
			<app-checklist-details-floor
				[point]="selectedPointForUpdate"
				[parent_check_id]="parent_check_id_update"
				(hideView)="onHidePointUpdate($event)"
				*ngIf="showPointUpdate"
			></app-checklist-details-floor>
			<app-task-meeting
				*ngIf="showMeetings"
				(hideMeetings)="onHideMeetings()"
				[taskId]="task_id"
			></app-task-meeting>
		</div>
		<!-- start comments -->
		<div class="col-12 col-md-4">
			<div class="task-activity">
				<div class="t-activity-header">
					<i class="pi pi-th-large"></i>
					<h4>{{ "lang_activity" | language }}</h4>
				</div>
				<div class="t-activity-body">
					<div class="tab-write-comment">
						<div class="tabwc-avatar">
							<img src="{{ user_avatar }}" alt="avatar" *ngIf="user_avatar" />
							<img
								src="assets/images/avatar.png"
								alt="avatar"
								*ngIf="!user_avatar"
							/>
						</div>
						<div class="tabwc-frame">
							<div class="tabwc-box">
								<textarea
									id=""
									name=""
									placeholder="{{ 'lang_comment' | language }}"
									(input)="autoResizeTxtarea(writeCommentTxtarea)"
									(focus)="onWriteCommentFocusin()"
									(focusout)="onWriteCommentFocusout()"
									[(ngModel)]="write_comment.description"
									#writeCommentTxtarea
								>
								</textarea>
								<div class="tabwc-controls">
									<button
										class="tabwc-save"
										(click)="onAddComment(writeCommentTxtarea)"
									>
										{{ "lang_save" | language }}
									</button>
									<div class="tabwc-actions">
										<span></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-comments">
						<!-- start comment -->
						<ng-container
							*ngFor="let comment of taskComments; let commentIndex = index"
						>
							<div class="tabc-comment">
								<div class="tabc-avatar">
									<img
										src="assets/images/avatar.png"
										alt="avatar"
										*ngIf="!comment?.user_image_64"
									/>
									<img
										src="{{ comment?.user_image_64 }}"
										alt="avatar"
										*ngIf="comment?.user_image_64"
									/>
								</div>
								<div class="tabc-container">
									<div class="tabc-container-name">
										<h4>{{ comment?.emp_name }}</h4>
										<span>{{ comment?.insert_date }}</span>
									</div>
									<div class="tabc-frame">
										<div class="tabc-box">
											<p>{{ comment?.notes_history }}</p>
										</div>
									</div>
									<div class="tabc-actions">
										<span
											class="tabc-actions-item"
											(click)="onUpdateComment(comment, 'comment')"
											*ngIf="comment?.emp_id == userId"
											>{{ "lang_edit" | language }}</span
										>
										<div
											class="tabc-divider"
											*ngIf="comment?.emp_id == userId"
										></div>
										<span
											class="tabc-actions-item"
											(click)="deleteComment(comment, commentIndex)"
											*ngIf="comment?.emp_id == userId"
											>{{ "lang_delete" | language }}</span
										>
										<div
											class="tabc-divider"
											*ngIf="comment?.emp_id == userId"
										></div>
										<span
											class="tabc-actions-item"
											(click)="comment.write_reply = true"
											*ngIf="!comment.write_reply"
											>{{ "lang_reply" | language }}</span
										>
										<span
											class="tabc-actions-item"
											(click)="comment.write_reply = false"
											*ngIf="comment.write_reply"
											>{{ "lang_undo" | language }}</span
										>
										<div
											class="tabc-divider"
											*ngIf="comment?.reply?.length"
										></div>
										<span
											class="tabc-actions-item"
											(click)="comment.show_replys = true"
											*ngIf="!comment.show_replys && comment?.reply?.length"
											>{{ comment?.reply.length }}
											{{ "lang_replys" | language }}</span
										>
										<span
											class="tabc-actions-item"
											(click)="comment.show_replys = false"
											*ngIf="comment.show_replys && comment?.reply?.length"
											>{{ "lang_hide" | language }}
											{{ "lang_replys" | language }}</span
										>
									</div>
								</div>
							</div>
							<!-- start replys -->
							<div class="tabc-replys">
								<div class="tabcr-list" *ngIf="comment?.show_replys">
									<div
										class="tabcr-comment"
										*ngFor="let reply of comment?.reply; let replyIndex = index"
									>
										<div class="tabcr-avatar">
											<img
												src="assets/images/avatar.png"
												alt="avatar"
												*ngIf="!reply?.avatar_64"
											/>
											<img
												src="{{ reply?.avatar_64 }}"
												alt="avatar"
												*ngIf="reply?.avatar_64"
											/>
										</div>
										<div class="tabcr-container">
											<div class="tabcr-container-name">
												<h4>{{ reply?.userName }}</h4>
												<span>{{ reply?.comment_date }}</span>
											</div>
											<div class="tabcr-frame">
												<div class="tabc-box">
													<p>{{ reply?.comment_content }}</p>
												</div>
											</div>
											<div class="tabcr-actions">
												<span
													class="tabcr-actions-item"
													(click)="onUpdateComment(reply, 'reply')"
													*ngIf="reply?.emp_id == userId"
													>{{ "lang_edit" | language }}</span
												>
												<div
													class="tabcr-divider"
													*ngIf="reply?.emp_id == userId"
												></div>
												<span
													class="tabcr-actions-item"
													(click)="deleteReply(reply)"
													*ngIf="reply?.emp_id == userId"
													>{{ "lang_delete" | language }}</span
												>
											</div>
										</div>
									</div>
								</div>
								<div
									class="tabcr-list-write-reply"
									*ngIf="comment?.write_reply"
								>
									<div class="tabcrlwr-avatar">
										<img
											src="{{ user_avatar }}"
											alt="avatar"
											*ngIf="user_avatar"
										/>
										<img
											src="assets/images/avatar.png"
											alt="avatar"
											*ngIf="!user_avatar"
										/>
									</div>
									<div class="tabcrlwr-frame">
										<div class="tabcrlwr-box">
											<textarea
												id=""
												name=""
												placeholder="{{ 'lang_reply' | language }}"
												(input)="autoResizeTxtarea(writeReplyTxtarea)"
												(focus)="onWriteReplyFocusin()"
												(focusout)="onWriteReplyFocusout(commentIndex)"
												[(ngModel)]="write_reply[commentIndex].description"
												#writeReplyTxtarea
											>
											</textarea>
											<div class="tabcrlwr-controls">
												<button
													class="tabcrlwr-save"
													(click)="
														onAddReply(commentIndex, comment?.history_id)
													"
												>
													{{ "lang_save" | language }}
												</button>
												<div class="tabcrlwr-actions">
													<span></span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- end replys -->
						</ng-container>
						<!-- end comment -->
					</div>
				</div>
			</div>
		</div>
		<!-- end comments -->
		<!-- start ticket attachments -->
		<div class="col-12 col-md-6 col-lg-5">
			<!-- start task-attachments -->
			<div class="task-attacchments">
				<div class="ta-header">
					<i class="pi pi-paperclip"></i>
					<h4>{{ "lang_attachments" | language }}</h4>
				</div>
				<div class="ta-list">
					<div
						class="tal-file"
						*ngFor="
							let attachment of task_attachments | slice: 0:taskAttachmentsSlice
						"
					>
						<div
							class="talf-type"
							(click)="navigateNewTab(attachment?.file_path)"
						>
							<i class="fa fa-link" aria-hidden="true"></i>
						</div>
						<div class="talf-content">
							<div class="talf-content-title">
								{{ attachment?.file_name_en }}
							</div>
							<div class="talf-content-body">
								<span class="talfcb-date"
									>{{ "lang_created_date" | language }}
									{{
										attachment?.created_datetime || attachment?.insert_date
									}}</span
								>
								<div class="talfcb-divider"></div>
								<!-- <span
									class="talf-content-body-download"
									(click)="
										downloadFile(attachment?.path, attachment?.file_name_en)
									"
									>{{ "lang_download_file" | language }}</span
								> -->
								<!-- <div class="talfcb-divider"></div> -->
								<span
									class="talf-content-body-delete"
									(click)="onDeleteAttachment(attachment?.file_records_id)"
									>{{ "lang_delete" | language }}</span
								>
							</div>
						</div>
					</div>
					<div
						class="tal-file talf-action-size"
						*ngIf="task_attachments?.length > 2"
					>
						<span
							class="tal-file-action-size"
							*ngIf="taskAttachmentsSlice == 2"
							(click)="taskAttachmentsSlice = task_attachments?.length"
							>{{ "lang_view" | language }} {{ "lang_all" | language }}
							{{ "lang_attachments" | language }} ({{
								task_attachments?.length - taskAttachmentsSlice
							}}
							{{ "lang_hidden" | language }})</span
						>
						<span
							class="tal-file-action-size"
							*ngIf="taskAttachmentsSlice > 2"
							(click)="taskAttachmentsSlice = 2"
							>{{ "lang_show_fewer" | language }}
							{{ "lang_attachments" | language }}</span
						>
					</div>
				</div>
				<!-- start add attachment -->
				<!-- <div class="ta-add">
					<button
						class="ta-add-btn task-btn"
						(click)="taskAttachmentInput.click()"
					>
						{{ "lang_add" | language }} {{ "lang_attachments" | language }}
					</button>
				</div> -->
				<div class="ta-add">
					<button
						class="ta-add-btn task-btn"
						(click)="isAddAttach = true"
						*ngIf="!isAddAttach"
					>
						{{ "lang_add" | language }} {{ "lang_attachments" | language }}
					</button>
					<div *ngIf="isAddAttach" style="margin: 20px 30px">
						<div class="ta-add-inpts">
							<input
								type="text"
								[(ngModel)]="attachTitle"
								placeholder="{{ 'lang_attach_title' | language }}"
								class="form-control shadow-none"
							/>
							<input
								type="text"
								[(ngModel)]="attachFile"
								placeholder="{{ 'lang_file' | language }}"
								class="form-control shadow-none"
							/>
						</div>

						<div class="d-flex align-items-center">
							<button (click)="onAddAttachment()" class="add-attach-btn">
								{{ "lang_save" | language }}
							</button>
							<i
								class="fa fa-times red mr-2 ml-2"
								(click)="isAddAttach = false; attachFile = ''; attachTitle = ''"
							></i>
						</div>
					</div>
				</div>
				<!-- end add attachment -->
			</div>
			<!-- end task-attachments -->
		</div>
		<!-- end ticket attachments -->
		<div class="col-12 col-lg-3">
			<mat-tab-group style="margin-top: 25px">
				<!-- <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="tt-header">
                            <i class="fa fa-comments" aria-hidden="true"></i>
                            <h4>{{ "lang_messages" | language }}</h4>
                        </div>
                    </ng-template>
                    <div class="ticket-timeline">
                        <div class="tt-body">
                            <div class="d-flex justify-content-end mb-2">
                                <i
                                    class="fa fa-filter mnabricon"
                                    matTooltip="{{ 'lang_advanced_filter' | language }}"
                                ></i>
                            </div>
                            <div class="ttb-list"> -->
				<!-- <div class="ttb-list-item" *ngFor="let item of timelineData">
                                    <div class="ttbli-avatar">
                                        <img
                                            src="{{ item?.user_image_64 }}"
                                            alt="avatar"
                                            *ngIf="item?.user_image_64"
                                        />
                                        <img
                                            src="assets/images/avatar.png"
                                            alt="avatar"
                                            *ngIf="!item?.user_image_64"
                                        />
                                    </div>
                                    <div class="ttbli-content ttbli-content-main-message">
                                        <div class="ttbli-content-header">
                                            <p class="m-0 d-flex flex-wrap">
                                                <span class="bold" *ngIf="lang_key == 'en'">{{
                                                    item?.firstname_english && item?.lastname_english
                                                        ? item?.firstname_english +
                                                          " " +
                                                          item?.lastname_english
                                                        : item?.emp_name
                                                }}</span>
                                                <span class="bold" *ngIf="lang_key == 'ar'">{{
                                                    item?.firstname_arabic && item?.lastname_arabic
                                                        ? item?.firstname_arabic +
                                                          " " +
                                                          item?.lastname_arabic
                                                        : item?.emp_name
                                                }}</span>
                                                <span class="message-date">{{
                                                    item?.insert_date
                                                }}</span>
                                            </p>
                                        </div>
                                        <div class="ttbli-content-message">
                                            <p>{{ item?.en_title }}</p>
                                            <div class="attach">
                                                <span *ngFor="let attach of [1]" class="attach-item">
                                                    <span>test-file.pdf</span>
                                                    <i class="fa fa-download" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
				<!-- <p class="text-center">{{ "lang_no_data" | language }}</p>
                            </div>
                        </div>
                    </div>
                </mat-tab> -->
				<mat-tab>
					<ng-template mat-tab-label>
						<div class="tt-header">
							<i class="pi pi-align-left"></i>
							<h4>
								<span>{{ "lang_history" | language }}</span>
								<span style="font-size: 13px; font-weight: 600">
									({{ timelineData?.length }})</span
								>
							</h4>
						</div>
					</ng-template>
					<div class="ticket-timeline">
						<div class="tt-body">
							<div class="ttb-list">
								<div class="ttb-list-item" *ngFor="let item of timelineData">
									<!-- <div class="ttbli-avatar">
										<img
											src="{{ item?.user_image_64 }}"
											alt="avatar"
											*ngIf="item?.user_image_64"
										/>
										<img
											src="assets/images/avatar.png"
											alt="avatar"
											*ngIf="!item?.user_image_64"
										/>
									</div> -->
									<div class="ttbli-content">
										<div class="ttbli-content-header">
											<p class="m-0 d-flex flex-wrap">
												<span class="bold" *ngIf="lang_key == 'en'">{{
													item?.firstname_english && item?.lastname_english
														? item?.firstname_english +
														  " " +
														  item?.lastname_english
														: item?.emp_name
												}}</span>
												<span class="bold" *ngIf="lang_key == 'ar'">{{
													item?.firstname_arabic && item?.lastname_arabic
														? item?.firstname_arabic +
														  " " +
														  item?.lastname_arabic
														: item?.emp_name
												}}</span>
												<span class="desc" *ngIf="lang_key == 'en'">{{
													item?.en_title
												}}</span>
												<span class="desc" *ngIf="lang_key == 'ar'">{{
													item?.ar_title
												}}</span>
											</p>
										</div>
										<div class="ttbli-content-date">
											{{ item?.insert_date }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>
</div>

<!-- start user template popover -->
<ng-template #user_popover>
	<div class="user-popover">
		<div class="up-header">
			<div class="up-header-content">
				<div class="up-header-img">
					<img
						src="assets/images/avatar.png"
						alt="{{ memberCardData?.userName }}"
						*ngIf="!memberCardData?.profile_image_64"
					/>
					<img
						src="{{ memberCardData?.profile_image_64 }}"
						alt="{{ memberCardData?.userName }}"
						*ngIf="memberCardData?.profile_image_64"
					/>
				</div>
				<div class="up-header-details">
					<h3>
						{{ memberCardData?.firstName }} {{ memberCardData?.lastName }}
					</h3>
					<p>{{ memberCardData?.userName }}</p>
				</div>
			</div>
		</div>
		<div class="up-body">
			<p></p>
		</div>
	</div>
</ng-template>
<!-- end user template popover -->

<!-- start checkbox template popover -->
<ng-template #checklist_popover>
	<div class="checkbox-popover">
		<div class="cp-header">
			<div class="cp-header-content">
				<h4>{{ "lang_add" | language }} {{ "lang_checklist" | language }}</h4>
				<i class="pi pi-times" (click)="checklist_popover_trigger.hide()"></i>
			</div>
			<div class="cp-header-divider"></div>
		</div>
		<div class="cp-body">
			<div class="checklist-input">
				<label for="add_checklist">{{ "lang_title" | language }}</label>
				<input
					type="text"
					id="add_checklist"
					name="add_checklist"
					[(ngModel)]="add_checklist"
					autofocus
				/>
			</div>
			<button
				class="cpb-add-btn task-btn"
				(click)="onAddChecklist(); checklist_popover_trigger.hide()"
			>
				{{ "lang_add" | language }}
			</button>
		</div>
	</div>
</ng-template>
<!-- end checkbox template popover -->

<!-- start task members template popover -->
<ng-template #taskmembers_popover>
	<div class="taskmembers-popover">
		<div class="tm-header">
			<div class="tm-header-content">
				<h4>
					{{ "lang_users" | language }}
				</h4>
				<span (click)="onAddUpdateGroupUser()">
					<i class="fa fa-users mnabricon"></i>
					{{ "lang_groups" | language }}
				</span>
				<i
					class="pi pi-times"
					(click)="
						taskmembers_popover_trigger1?.hide();
						taskmembers_popover_trigger2?.hide()
					"
				></i>
			</div>
			<div class="tm-header-divider"></div>
		</div>
		<div class="tm-body">
			<div class="tm-input">
				<!-- <label for="add_checklist"></label> -->
				<ng-select
					[items]="task_members_list"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					[searchFn]="ds.customSearchFn"
					[(ngModel)]="task_members_selected"
					placeholder="Task Members"
					(search)="search_users($event)"
				></ng-select>
			</div>
			<button
				class="tmb-add-btn task-btn"
				(click)="
					onChangeTaskMembers();
					taskmembers_popover_trigger1?.hide();
					taskmembers_popover_trigger2?.hide()
				"
			>
				{{ "lang_save" | language }}
			</button>
		</div>
	</div>
</ng-template>
<!-- end task members template popover -->
