import { Component, Input, OnInit } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { special_approval_notes } from "app/routes/transactions/FORM_PS_TR1/formdata";
import { SendCodeComponent } from "app/routes/transactions/FORM_PS_TR1/send-code/send-code.component";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
	selector: "app-si-step2",
	templateUrl: "./si-step2.component.html",
	styleUrls: ["./si-step2.component.scss"],
})
export class SiStep2Component implements OnInit {
	@Input() form_key;
	@Input() form_request_id;
	@Input() project_data;
	@Input() supervision_data;
	@Input() template_data;
	@Input() allData;
	sendCodeForm: any;
	sendCodeStatus: boolean = false;
	rejectedValidators = [];
	stepName = "Contractor_Manager_Approval";
	form: FormGroup;
	users: any = [];
	filename = this.lang.transform("lang_select_files");
	myData: any = [];
	page = 1;
	psize = 10;
	constructor(
		public ds: DataService,
		public router: Router,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.myData = special_approval_notes;
		this.form = new FormGroup({
			transaction_request_id: new FormControl(this.form_request_id, [
				Validators.required,
			]),
			contractor_approval_status: new FormControl("", [Validators.required]),
			contractor_rejected_notes: new FormControl("", this.rejectedValidators),
			transactions_persons_action_code: new FormControl("", [
				Validators.required,
			]),
		});
	}

	checkStatus() {
		if (this.form.get("contractor_approval_status").value == "0") {
			this.rejectedValidators = [Validators.required];
		} else {
			this.rejectedValidators = [];
		}
	}
	public completeStep3() {
		if (this.form.valid) {
			this.spinner.show();

			this.ds
				.post(
					`/form/${this.form_key}/Contractor_Manager_Approval/0`,
					this.form.value
				)
				.subscribe(
					(data) => {
						if (data.status) {
							this.spinner.hide();
							this.alert.success("data sent successfully");
						} else {
							this.spinner.hide();
							this.alert.error("something wrong");
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(error);
					}
				);
		}
	}
	public search_contractor_approval_manager() {
		this.page = 1;
		this.psize = 10;
		this.get_contractor_approval_manager();
	}
	public by_page_size(page_size) {
		this.page = 1;
		this.psize = page_size;
		this.get_contractor_approval_manager();
	}
	public by_page_number(page_no) {
		this.page = page_no;
		this.psize = 10;
		this.get_contractor_approval_manager();
	}
	public get_contractor_approval_manager() {
		this.spinner.hide();
	}

	openModal() {
		const dialogRef = this.dialog.open(SendCodeComponent, {
			disableClose: true,
			width: "400px",
			data: {
				transaction_persons_type: "signature",
				allData: this.allData,
				stepName: this.stepName,
			},
		});
		dialogRef.afterClosed().subscribe((data) => {
			this.sendCodeForm = data.formValue;
			this.sendCodeStatus = data.sendstatus;
		});
	}
}
