<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_history" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="TableParts">
        <div class="table-responsive customResponsive mb-10 tablefields" style="min-height: 100px;">
            <table border="1" class="table CustomTable text-center">
                <thead>
                    <tr>
                        <td>#</td>
                        <td>{{'lang_name' | language }}</td>
                        <td>{{'lang_created_date' | language }}</td>
                        <td>{{'lang_writer' | language }}</td>
                        <td>{{'lang_action' | language }}</td>
                    </tr>
                </thead>
                <tbody class="AppendList">
                    <tr *ngFor="let item of snag_list_files; let itemIndex = index">
                        <td class="verticalalignmid pad0">{{itemIndex+1}}</td>
                        <td class="pad0">
                            {{item.file_name_en}}
                        </td>
                        <td class="pad0">
                            {{item.created_datetime}}
                        </td>
                        <td class="pad0">
                            {{item.writer}}
                        </td>
                        <td class="pad0">
                            <i class="fa fa-download mnabricon" (click)="downloadFile(item?.file)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</mat-dialog-actions>
