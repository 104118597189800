<div class="row wafi_header">
  <div class="table-responsive customResponsive">
    <table border="1" class="table wafi_title" width="100%">
      <tbody>
        <tr>
          <td width="130px">
            <img src="assets/images/logoawafi.PNG" alt="" />
          </td>
          <td width="87.5%">
            <div class="title_content">
              {{
                "supervision consultant monthly report for july-2022" | language
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row content_header">
  <div class="col">
    {{ "lang_project_zones" | language }}
  </div>
</div>
