<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_platform_triggers" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div
		class="table-responsive dropdownyes customResponsive tablefields"
		*ngIf="!platform_msg && platforms?.length"
	>
		<table border="1" class="table CustomTable">
			<thead>
				<tr>
					<th>#</th>
					<th>{{ "lang_units" | language }}</th>
					<th>{{ "lang_work_levels" | language }}</th>
					<th>{{ "lang_template_name" | language }}</th>
					<th>{{ "lang_platform_code_system" | language }}</th>
					<th>{{ "lang_each_unit_cost_difference" | language }}</th>
					<th>{{ "lang_template_cost_difference" | language }}</th>
					<th>{{ "lang_quantity_difference" | language }}</th>
					<th>{{ "lang_unit_price_difference" | language }}</th>
					<th>{{ "lang_total_price_difference" | language }}</th>
					<th>{{ "lang_unit_ratio_difference" | language }}</th>
					<th>{{ "lang_final_ratio_difference" | language }}</th>
					<th>{{ "lang_template_platform_weight_difference" | language }}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="
						let item of platforms;
						let i = index;
						let odd = odd;
						let even = even
					"
				>
					<td>{{ i + 1 }}</td>
					<td>{{ item?.total_units }}</td>
					<td>{{ item?.worklevelname }}</td>
					<td>{{ item?.template_name }}</td>
					<td>{{ item?.platform_code_system }}</td>
					<td>{{ item?.each_unit_cost_difference }}</td>
					<td>{{ item?.template_cost_difference }}</td>
					<td>{{ item?.quantity_difference }}</td>
					<td>{{ item?.unit_price_difference }}</td>
					<td>{{ item?.total_price_difference }}</td>
					<td>{{ item?.unit_ratio_difference }}</td>
					<td>{{ item?.final_ratio_difference }}</td>
					<td>{{ item?.template_platform_weight_difference }}</td>
				</tr>
			</tbody>
		</table>
		<div
			class="mt-2"
			*ngIf="platforms?.length > 0"
			style="
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
			"
		>
			<ngb-pagination
				*ngIf="platforms.length"
				[collectionSize]="platforms.length"
				[(page)]="page"
				[maxSize]="size"
				[boundaryLinks]="true"
			></ngb-pagination>
		</div>
	</div>
	<div class="mt-3 mb-3 text-center" *ngIf="platform_msg && !platforms?.length">
		<p style="font-size: 18px">{{ platform_msg }}</p>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12"></div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="runTrigger()"
		>
			{{ "lang_run_trigger" | language }}
		</button>
	</div>
</mat-dialog-actions>
