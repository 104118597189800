<breadcrumb></breadcrumb>
<div class="themesection">
  <section class="panel-expansion matborderno">
    <mat-accordion class="create_selling_invoice">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_create_debit_notes" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <form class="form" [formGroup]="form">
            <div class="mbrl15">
              <div class="row">
                <div class="col-md-6 pr-md-3">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_branch" | language }}</label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <select
                          class="form-control almnabrformcontrol"
                          #branch_id
                          formControlName="branch_id"
                          (change)="spinner.show(); load_branches()"
                        >
                          <option value="">
                            {{ "lang_choose_options" | language }}
                          </option>
                          <option
                            *ngFor="let branch of branches"
                            value="{{ branch.id }}"
                          >
                            {{ branch.title }}
                          </option>
                        </select>
                        <mat-error
                          *ngIf="
                            form.controls['branch_id'].touched &&
                            form.controls['branch_id'].errors?.required
                          "
                          >{{ "lang_field_required" | language }}</mat-error
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-md-3">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_default_finance" | language }}</label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <ng-select
                          [items]="defaultFinanceList"
                          [multiple]="false"
                          bindLabel="label"
                          bindValue="value"
                          clearAllText="Clear"
                          [searchFn]="ds.customSearchFn"
                          appendTo="body"
                          formControlName="finance_id"
                          placeholder="{{ 'lang_finance' | language }}"
                          readonly
                          disabled
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-6 pr-md-3"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0'
                  "
                >
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_new_finance" | language }}</label>
                    </div>
                    <div
                      class="col-md-8 mb-1"
                      *ngIf="
                        form.get('branch_id').value &&
                        form.get('branch_id').value != '0'
                      "
                    >
                      <div class="form-group">
                        <ng-select
                          [items]="financeList"
                          [multiple]="false"
                          bindLabel="label"
                          bindValue="value"
                          clearAllText="Clear"
                          [searchFn]="ds.customSearchFn"
                          appendTo="body"
                          formControlName="new_finance_id"
                          placeholder="{{ 'lang_finance' | language }}"
                          (change)="getInvoiceList()"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 pl-md-3"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0'
                  "
                >
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_invoice" | language }} </label>
                      <span class="ml-1">
                        <mat-icon
                          *ngIf="
                            form.get('invoice_id').value &&
                            form.get('invoice_id').value != '0' &&
                            viewdata_status
                          "
                          (click)="openModal(mytemplate)"
                          matTooltipPosition="above"
                          matTooltip="{{ 'lang_view' | language }}"
                          class="mnabricon"
                          >remove_red_eye</mat-icon
                        ></span
                      >
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <ng-select
                          [items]="invoiceList"
                          [multiple]="false"
                          bindLabel="label"
                          bindValue="value"
                          clearAllText="Clear"
                          [searchFn]="ds.customSearchFn"
                          appendTo="body"
                          formControlName="invoice_id"
                          placeholder="{{ 'lang_invoice' | language }}"
                          (change)="spinner.show(); View_Selling_Invoice()"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  form.get('invoice_id').value &&
                  form.get('invoice_id').value != '0' &&
                  form.get('branch_id').value &&
                  form.get('branch_id').value != '0' &&
                  form.get('finance_id').value &&
                  form.get('finance_id').value != '0'
                "
              >
                <div class="row">
                  <div class="col-md-6 pr-md-3">
                    <div class="row overflow-visible">
                      <div class="col-md-4 mb-md-10 p-2 label overflow-visible">
                        <label>{{ "lang_document_date" | language }} </label>
                      </div>
                      <div class="col-md-8 mb-1 overflow-visible">
                        <div class="form-group overflow-visible">
                          <div
                            class="input-group input-group-sm overflow-visible"
                          >
                            <input
                              [matDatepicker]="invoice_date"
                              formControlName="document_date"
                              class="form-control almnabrformcontrol"
                              placeholder="YYYY/MM/DD"
                              readonly
                            />
                            <span class="input-group-btn">
                              <mat-datepicker-toggle
                                class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                                matSuffix
                                [for]="invoice_date"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #invoice_date></mat-datepicker>
                            </span>
                            <span class="input-group-btn">
                              <button
                                (click)="form.get('document_date').setValue('')"
                                matTooltip="{{ 'lang_empty' | language }}"
                                type="button"
                                class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
                              ></button>
                            </span>
                          </div>
                          <mat-error
                            *ngIf="
                              form.controls['document_date'].touched &&
                              form.controls['document_date'].errors?.required
                            "
                            >{{ "lang_field_required" | language }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 pl-md-3">
                    <div class="row">
                      <div class="col-md-4 mb-md-10 p-2 label">
                        <label>{{ "lang_credit_account" | language }} </label>
                      </div>
                      <div class="col-md-8 mb-1">
                        <div class="form-group">
                          <ng-select
                            [items]="accountData"
                            [multiple]="false"
                            bindLabel="label"
                            bindValue="value"
                            clearAllText="Clear"
                            [searchFn]="ds.customSearchFn"
                            appendTo="body"
                            formControlName="credit_account"
                            placeholder="{{ 'lang_credit_account' | language }}"
                            (search)="searchAccounts($event)"
                            (focus)="accountData = []"
                          >
                            <ng-template
                              ng-option-tmp
                              let-item="item"
                              let-search="searchTerm"
                              let-index="index"
                            >
                              <span>{{ item.label }} ({{ item.value }})</span>
                            </ng-template>
                          </ng-select>
                          <mat-error
                            *ngIf="
                              form.controls['credit_account'].touched &&
                              form.controls['credit_account'].errors?.required
                            "
                          >
                            {{ "lang_field_required" | language }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 pr-md-3 overflow-visible">
                    <div class="row">
                      <div class="col-md-4 mb-md-10 p-2 label">
                        <label>{{ "lang_debit_account" | language }} </label>
                      </div>
                      <div class="col-md-8 mb-1">
                        <div class="form-group">
                          <ng-select
                            [items]="accountData"
                            [multiple]="false"
                            bindLabel="label"
                            bindValue="value"
                            clearAllText="Clear"
                            [searchFn]="ds.customSearchFn"
                            appendTo="body"
                            formControlName="debit_account"
                            placeholder="{{ 'lang_debit_account' | language }}"
                            (search)="searchAccounts($event)"
                            (focus)="accountData = []"
                          >
                            <ng-template
                              ng-option-tmp
                              let-item="item"
                              let-search="searchTerm"
                              let-index="index"
                            >
                              <span>{{ item.label }} ({{ item.value }})</span>
                            </ng-template>
                          </ng-select>
                          <mat-error
                            *ngIf="
                              form.controls['debit_account'].touched &&
                              form.controls['debit_account'].errors?.required
                            "
                          >
                            {{ "lang_field_required" | language }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 pr-md-3">
                    <div class="row">
                      <div class="col-md-4 mb-md-10 p-2 label">
                        <label>{{ "lang_tax_account_id" | language }} </label>
                      </div>
                      <div class="col-md-8 mb-1">
                        <div class="form-group">
                          <ng-select
                            [items]="accounts"
                            [multiple]="false"
                            bindLabel="label"
                            bindValue="value"
                            clearAllText="Clear"
                            [searchFn]="ds.customSearchFn"
                            appendTo="body"
                            formControlName="tax_account_id"
                            placeholder="{{ 'lang_tax_accounts' | language }}"
                            (search)="searchTaxAccounts($event, 'tax_accounts')"
                            (focus)="accounts = []"
                          >
                          </ng-select>
                          <mat-error
                            *ngIf="
                              form.controls['tax_account_id'].touched &&
                              form.controls['tax_account_id'].errors?.required
                            "
                          >
                            {{ "lang_field_required" | language }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row">

                  <div class="col-md-6 pl-md-3">
                    <div class="row">
                      <div class="col-md-4 mb-md-10 p-2 label">
                        <label>{{ "lang_amount_b_vat" | language }} </label>
                      </div>
                      <div class="col-md-8 mb-1">
                        <div class="form-group">
                          <input
                            type="text"
                            formControlName="amount"
                            class="form-control almnabrformcontrol"
                            placeholder="{{ 'lang_amount_b_vat' | language }}"
                            (keyup)="
                              calculate_total_calculation(
                                form.controls['tax_id'].value,
                                form.controls['amount'].value
                              )
                            "
                            onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                          />
                          <mat-error
                            *ngIf="
                              form.controls['amount'].touched &&
                              form.controls['amount'].errors?.required
                            "
                          >
                            {{ "lang_field_required" | language }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="row">
                  <!-- <div class="col-md-6 pr-md-3">
                    <div class="row">
                      <div class="col-md-4 mb-md-10 p-2 label">
                        <label>{{ "lang_tax_amount" | language }} </label>
                      </div>
                      <div class="col-md-8 mb-1">
                        <div class="form-group">
                          <input
                            type="text"
                            formControlName="tax"
                            class="form-control almnabrformcontrol"
                            placeholder="{{ 'lang_tax_amount' | language }}"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-6 pl-md-3">
                    <div class="row">
                      <div class="col-md-4 mb-md-10 p-2 label">
                        <label>{{ "lang_amount_with_tax" | language }} </label>
                      </div>
                      <div class="col-md-8 mb-1">
                        <div class="form-group">
                          <input
                            type="text"
                            formControlName="amount_with_tax"
                            class="form-control almnabrformcontrol"
                            placeholder="{{
                              'lang_amount_with_tax' | language
                            }}"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="row">
                  <div class="col-md-6 pr-md-3">
                    <div class="row">
                      <div class="col-md-4 mb-md-10 p-2 label">
                        <label>{{ "lang_description" | language }} </label>
                      </div>
                      <div class="col-md-8 mb-1">
                        <div class="form-group">
                          <textarea
                            formControlName="description"
                            class="form-control almnabrformcontrol"
                          ></textarea>
                          <mat-error
                            *ngIf="
                              form.controls['description'].touched &&
                              form.controls['description'].errors?.required
                            "
                          >
                            {{ "lang_field_required" | language }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 pr-md-3">
                    <div class="row">
                      <div class="col-md-4 mb-md-10 p-2 label">
                        <label>{{ "lang_remarks_2" | language }} </label>
                      </div>
                      <div class="col-md-8 mb-1">
                        <div class="form-group">
                          <input
                            formControlName="remarks2"
                            type="text"
                            class="form-control almnabrformcontrol"
                            placeholder="{{ 'lang_remarks_2' | language }}"
                            onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                          />
                          <mat-error
                            *ngIf="
                              form.controls['remarks2'].touched &&
                              form.controls['remarks2'].errors?.required
                            "
                          >
                            {{ "lang_field_required" | language }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 pr-md-3">
                    <div class="row">
                      <div class="col-md-4 mb-md-10 p-2 label">
                        <label>{{ "lang_remarks3" | language }} </label>
                      </div>
                      <div class="col-md-8 mb-1">
                        <div class="form-group">
                          <input
                            type="text"
                            formControlName="remarks3"
                            class="form-control almnabrformcontrol"
                            placeholder="{{ 'lang_remarks3' | language }}"
                            onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                          />
                          <mat-error
                            *ngIf="
                              form.controls['remarks3'].touched &&
                              form.controls['remarks3'].errors?.required
                            "
                          >
                            {{ "lang_field_required" | language }}
                          </mat-error>
                        </div>
                      </div>
                      <div class="col-md-4"></div>
                      <div class="col-md-8 mb-1">
                        <div class="form-group">
                          <input
                            *ngIf="
                              form.get('invoice_id').value &&
                              form.get('invoice_id').value != '0' &&
                              form.get('branch_id').value &&
                              form.get('branch_id').value != '0' &&
                              form.get('finance_id').value &&
                              form.get('finance_id').value != '0'
                            "
                            (click)="openModal(mytemplate)"
                            type="button"
                            class="form-control almnabrformcontrol transaction"
                            value="{{ 'lang_transactions' | language }}"
                          />
                          <input
                            *ngIf="!form.get('invoice_id').value"
                            disabled
                            (click)="openModal(mytemplate)"
                            type="button"
                            class="form-control almnabrformcontrol transaction"
                            value="{{ 'lang_transactions' | language }}"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                </div>
                <div class="col-md-12">
                  <div
                    class="table-responsive customResponsive mb-10 tablefields"
                  >
                    <table border="1" class="table CustomTable text-center">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>
                            <i
                              class="fa fa-plus-square fa-icon"
                              matTooltip="{{ 'lang_add' | language }}"
                              (click)="add_items()"
                            ></i>
                          </td>
                          <td>
                            {{ "lang_invoice_item_name" | language }}
                            <i class="red">*</i>
                          </td>
                          <td>
                            {{ "lang_invoice_item_unit" | language }}
                            <i class="red">*</i>
                          </td>
                          <td>
                            {{ "lang_invoice_item_quantity" | language }}
                            <i class="red">*</i>
                          </td>
                          <td>
                            {{ "lang_invoice_item_price" | language }}
                            <i class="red">*</i>
                          </td>
                          <td width="10%">
                            {{ "lang_tax" | language }}
                            <i class="red">*</i>
                          </td>
                          <td>
                            {{ "lang_description" | language
                            }}<i class="red">*</i>
                          </td>
                        </tr>
                      </thead>
                      <tbody formArrayName="items" class="AppendList">
                        <tr
                          *ngFor="
                            let item of items_group().controls;
                            let itemIndex = index;
                            let odd = odd;
                            let even = even
                          "
                          [formGroupName]="itemIndex"
                        >
                          <td class="verticalalignmid pad0">
                            {{ itemIndex + 1 }}
                          </td>
                          <td class="verticalalignmid pad0">
                            <i
                              (click)="removeItems(itemIndex)"
                              class="fa fa-minus-square fa-icon red mnabricon"
                              matTooltip="{{ 'lang_delete' | language }}"
                            ></i>
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_item_name"
                              class="form-control almnabrformcontrol"
                              placeholder="{{
                                'lang_invoice_item_name' | language
                              }}"
                            />
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_item_unit"
                              class="form-control almnabrformcontrol"
                              placeholder="{{
                                'lang_invoice_item_unit' | language
                              }}"
                            />
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_item_quantity"
                              class="form-control almnabrformcontrol"
                              placeholder="{{
                                'lang_invoice_item_quantity' | language
                              }}"
                            />
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_item_price"
                              class="form-control almnabrformcontrol"
                              placeholder="{{
                                'lang_invoice_item_price' | language
                              }}"
                            />
                          </td>
                          <td class="pad0">
                            <ng-select
                              [items]="taxs"
                              [multiple]="false"
                              clearAllText="Clear"
                              appendTo="body"
                              formControlName="invoice_item_tax"
                              placeholder="{{ 'lang_invoice_to' | language }}"
                            >
                            </ng-select>
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_item_description"
                              class="form-control almnabrformcontrol"
                              placeholder="{{ 'lang_description' | language }}"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br />

                <hr
                  style="width: 50%; margin-left: auto; margin-right: auto"
                  *ngIf="
                    form.get('finance_id').value &&
                    form.get('finance_id').value != null
                  "
                />
                <div
                  class="row"
                  *ngIf="
                    form.get('finance_id').value &&
                    form.get('finance_id').value != null
                  "
                >
                  <div class="col-sm-6 col-12">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table
                        border="1"
                        class="table CustomTable"
                        width="100%"
                        formArrayName="creditCostCenter"
                      >
                        <thead>
                          <tr>
                            <td
                              width="2%"
                              style="padding: 5px; word-break: break-all"
                            >
                              #
                            </td>
                            <td
                              width="2%"
                              style="padding: 5px; word-break: break-all"
                            >
                              <mat-icon
                                class="mnabricon"
                                matTooltip="{{ 'lang_add' | language }}"
                                (click)="addCreditCostCenter()"
                                >add_box</mat-icon
                              >
                            </td>
                            <td
                              width="32%"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ "lang_cost" | language }}:<span class="red"
                                >* ({{ "lang_credit" | language }})</span
                              >
                            </td>
                            <td
                              width="32%"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ "lang_amount_b_vat" | language }}
                              <span class="red">*</span>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of creditCostCenterGroup().controls;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                            [formGroupName]="i"
                          >
                            <td
                              class="verticalalignmid"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ i + 1 }}
                            </td>
                            <td class="verticalalignmid">
                              <mat-icon
                                (click)="removeCreditCostCenter(i)"
                                class="mnabricon red"
                                matTooltip="{{ 'lang_delete' | language }}"
                                >delete_forever</mat-icon
                              >
                            </td>
                            <td
                              style="
                                padding: 5px;
                                word-break: break-all;
                                position: relative;
                              "
                            >
                              <ng-select
                                [items]="
                                  creditCostsArray?.controls[i]?.get('costList')
                                    ?.value
                                "
                                [multiple]="false"
                                bindLabel="label"
                                bindValue="value"
                                clearAllText="Clear"
                                [searchFn]="ds.customSearchFn"
                                appendTo="body"
                                formControlName="cid"
                                placeholder="{{ 'lang_cost' | language }}"
                                (search)="searchCosts($event, i, 'credit')"
                              >
                                <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-search="searchTerm"
                                  let-index="index"
                                >
                                  <span
                                    >{{ item.label }} ({{ item.value }})</span
                                  >
                                </ng-template>
                              </ng-select>
                            </td>
                            <td
                              style="
                                padding: 5px;
                                word-break: break-all;
                                position: relative;
                              "
                            >
                              <input
                                type="text"
                                formControlName="amount"
                                placeholder="{{
                                  'lang_amount_b_vat' | language
                                }}"
                                autocomplete="off"
                                class="form-control almnabrformcontrol"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table
                        border="1"
                        class="table CustomTable"
                        width="100%"
                        formArrayName="debitCostCenter"
                      >
                        <thead>
                          <tr>
                            <td
                              width="2%"
                              style="padding: 5px; word-break: break-all"
                            >
                              #
                            </td>
                            <td
                              width="2%"
                              style="padding: 5px; word-break: break-all"
                            >
                              <mat-icon
                                class="mnabricon"
                                matTooltip="{{ 'lang_add' | language }}"
                                (click)="addDebitCostCenter()"
                                >add_box</mat-icon
                              >
                            </td>
                            <td
                              width="32%"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ "lang_cost" | language }}:<span class="red"
                                >* ({{ "lang_debit" | language }})</span
                              >
                            </td>
                            <td
                              width="32%"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ "lang_amount_b_vat" | language }}
                              <span class="red">*</span>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of debitCostCenterGroup().controls;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                            [formGroupName]="i"
                          >
                            <td
                              class="verticalalignmid"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ i + 1 }}
                            </td>
                            <td class="verticalalignmid">
                              <mat-icon
                                (click)="removeDebitCostCenter(i)"
                                class="mnabricon red"
                                matTooltip="{{ 'lang_delete' | language }}"
                                >delete_forever</mat-icon
                              >
                            </td>
                            <td
                              style="
                                padding: 5px;
                                word-break: break-all;
                                position: relative;
                              "
                            >
                              <ng-select
                                [items]="
                                  debitCostsArray?.controls[i]?.get('costList')
                                    ?.value
                                "
                                [multiple]="false"
                                bindLabel="label"
                                bindValue="value"
                                clearAllText="Clear"
                                [searchFn]="ds.customSearchFn"
                                appendTo="body"
                                formControlName="cid"
                                placeholder="{{ 'lang_cost' | language }}"
                                (search)="searchCosts($event, i, 'debit')"
                              >
                                <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-search="searchTerm"
                                  let-index="index"
                                >
                                  <span
                                    >{{ item.label }} ({{ item.value }})</span
                                  >
                                </ng-template>
                              </ng-select>
                            </td>
                            <td
                              style="
                                padding: 5px;
                                word-break: break-all;
                                position: relative;
                              "
                            >
                              <input
                                type="text"
                                formControlName="amount"
                                placeholder="{{
                                  'lang_amount_b_vat' | language
                                }}"
                                autocomplete="off"
                                class="form-control almnabrformcontrol"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer padb0">
              <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                  <button
                    type="button"
                    class="albutton"
                    routerLink="/accounts/list_invoice_reversal"
                  >
                    {{ "lang_goto_list" | language }}
                  </button>
                </div>
                <div class="col-md-8 col-sm-8 col-xs-12 mb-10">
                  <app-alert id="default-alert"></app-alert>
                </div>
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                  <button
                    type="submit"
                    class="albutton"
                    (click)="create_invoice_notes()"
                  >
                    {{ "lang_submit" | language }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div></mat-expansion-panel
      ></mat-accordion
    >
  </section>
</div>

<ng-template #mytemplate>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_selling_invoice_view" | language }}</h4>
      <button type="button" class="close" data-dismiss="modal" mat-dialog-close>
        &times;
      </button>
    </div>
    <div class="modal-body">
      <div class="mbrl15">
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_invoice_no" | language }} :
                <b>{{ viewdata.invoice_system_code }}</b></label
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_transaction_number" | language }} :
                <b>{{ viewdata.transaction_id }}</b></label
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_branch" | language }} :
                <b>{{ viewdata.branch_name }}</b></label
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_invoice_date" | language }} :<b>{{
                  viewdata.invoice_date
                }}</b></label
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_payment_mode" | language }} :
                <b>{{ viewdata.payment_name }}</b></label
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_credit_days" | language }} :
                <b>{{ viewdata.invoice_credit_days }}</b></label
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label *ngIf="viewdata.invoice_credit_days != '0'"
                >{{ "lang_credit_date" | language }}:<b>{{
                  viewdata.invoice_credit_date
                }}</b></label
              >
              <label *ngIf="viewdata.invoice_credit_days == '0'"
                >{{ "lang_credit_date" | language }}:----</label
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_invoice_to" | language }} :
                <b>{{ viewdata.customer_account }}</b></label
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_debit_cost" | language }} :
                <b>{{ viewdata.customer_cost }}</b></label
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_invoice_address" | language }} :<b>{{
                  viewdata.invoice_address_to
                }}</b></label
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_vat_tax" | language }} :
                <b>{{ viewdata.invoice_vat_tax_id }}</b></label
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_reference_no" | language }} :
                <b>{{ viewdata.invoice_ref_number }}</b></label
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_invoice_instruction_remarks" | language }} :<b>{{
                  viewdata.invoice_instruction_remarks
                }}</b></label
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_writer" | language }} :
                <b>{{ viewdata.writer_name }}</b></label
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_financial_date" | language }} :
                <b
                  >{{ viewdata.finance_start_date }} -
                  {{ viewdata.finance_end_date }}</b
                ></label
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_ondate" | language }} :
                <b>{{ viewdata.invoice_created_date }}</b></label
              >
            </div>
          </div>
          <div
            class="col-md-4 col-sm-4 col-xs-12 mb-10"
            *ngIf="viewdata.invoice_update_date"
          >
            <div class="form-group">
              <label
                >{{ "lang_onupdate" | language }} :
                <b>{{ viewdata.invoice_update_date }}</b></label
              >
            </div>
          </div>
        </div>
        <div class="table-responsive customResponsive mb-10 tablefields">
          <div class="red pad0">
            <b class="red">{{ "lang_items" | language }} : </b>
          </div>
          <table border="1" class="table CustomTable text-center">
            <thead>
              <tr>
                <td>#</td>
                <td>{{ "lang_invoice_item_name" | language }}</td>
                <td>{{ "lang_invoice_item_unit" | language }}</td>
                <td>
                  {{ "lang_invoice_item_quantity" | language }}
                </td>
                <td>{{ "lang_invoice_item_price" | language }}</td>
                <td>{{ "lang_invoice_discount_percent" | language }} %</td>
                <td>
                  {{ "lang_invoice_discount_amount" | language }}
                </td>
                <td>{{ "lang_tax" | language }}</td>
                <td>{{ "lang_tax_amount" | language }}</td>
                <td>{{ "lang_invoice_item_amount" | language }}</td>
                <td>
                  {{ "lang_invoice_total_amount" | language }}
                </td>
                <td>{{ "lang_notes" | language }}</td>
              </tr>
            </thead>
            <tbody class="AppendList">
              <tr
                *ngFor="
                  let item of items;
                  let itemIndex = index;
                  let odd = odd;
                  let even = even
                "
              >
                <td class="verticalalignmid">
                  {{ itemIndex + 1 }}
                </td>
                <td>{{ item.invoice_item_name }}</td>
                <td>{{ item.invoice_item_unit }}</td>
                <td>
                  {{ item.invoice_item_quantity | number: "1.0-2" }}
                </td>
                <td>
                  {{ item.invoice_item_price | number: "1.0-2" }}
                </td>
                <td>
                  {{ item.invoice_discount_percent | number: "1.0-2" }}
                </td>
                <td>
                  {{ item.invoice_item_disc_amount | number: "1.0-2" }}
                </td>
                <td>{{ item.label }}</td>
                <td>
                  {{ item.invoice_item_tax_amount | number: "1.0-2" }}
                </td>
                <td>{{ invcalc(item) | number: "1.0-2" }}</td>
                <td>{{ invcalc(item) | number: "1.0-2" }}</td>
                <td>{{ item.invoice_item_description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div class="row">
          <div class="col-md-8 col-sm-8 col-xs-12 mb-10"></div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="row">
              <div class="col-6">
                <b
                  ><label>{{ "lang_grand_total" | language }}</label></b
                >
              </div>
              <div class="col-6">
                <label> : {{ viewdata.invoice_grand_total }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-sm-8 col-xs-12 mb-10"></div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="row">
              <div class="col-6 mb-10">
                <b
                  ><label>{{ "lang_total_item_discount" | language }}</label></b
                >
              </div>
              <div class="col-6 mb-10">
                <label> : {{ viewdata.invoice_item_discount_amount }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <b
                  ><label>{{ "lang_global_discount" | language }}</label></b
                >
              </div>
              <div class="col-6">
                <label>
                  : {{ viewdata.invoice_global_discount_amount }} ({{
                    viewdata.invoice_global_discount_percent
                  }}
                  %)</label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-sm-8 col-xs-12 mb-10"></div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="row">
              <div class="col-6">
                <b
                  ><label>{{ "lang_cash_advance" | language }}</label></b
                >
              </div>
              <div class="col-6">
                <label> : {{ viewdata.invoice_cash_advance_amount }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-sm-8 col-xs-12 mb-10"></div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="row">
              <div class="col-6 mb-10">
                <b
                  ><label>{{ "lang_total_item_tax" | language }}</label></b
                >
              </div>
              <div class="col-6 mb-10">
                <label> : {{ viewdata.invoice_item_tax_amount }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <b
                  ><label>{{ "lang_tax_group" | language }}</label></b
                >
              </div>
              <div class="col-6">
                <label *ngIf="viewdata.global_tax_name">
                  : {{ viewdata.invoice_global_tax_amount }} ({{
                    viewdata.global_tax_name
                  }})</label
                >
                <label *ngIf="!viewdata.global_tax_name"> : 0.00</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-sm-8 col-xs-12 mb-10"></div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="row">
              <div class="col-6">
                <b
                  ><label>{{ "lang_net_amount" | language }}</label></b
                >
              </div>
              <div class="col-6">
                <label> : {{ viewdata.invoice_net_total }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-sm-8 col-xs-12 mb-10"></div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="row">
              <div class="col-6">
                <b
                  ><label>{{ "lang_invoice_roundoff" | language }}</label></b
                >
              </div>
              <div class="col-6">
                <label> : {{ viewdata.invoice_roundoff_amount }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="transactions.length > 0">
          <div class="col-md-12">
            <div class="table-responsive dropdownyes customResponsive">
              <div class="red pad0">
                <b>{{ "lang_transactions" | language }} : </b>
              </div>
              <table border="1" class="table CustomTable">
                <thead>
                  <tr>
                    <td>#</td>
                    <td>{{ "lang_accounts_acccode" | language }}</td>
                    <td>{{ "lang_debit_amount" | language }}</td>
                    <td>{{ "lang_credit_amount" | language }}</td>
                    <td>{{ "lang_description" | language }}</td>
                    <td>{{ "lang_cost_center_code" | language }}</td>
                  </tr>
                </thead>
                <tbody class="AppendList">
                  <tr
                    *ngFor="
                      let data of transactions;
                      let i = index;
                      let odd = odd;
                      let even = even
                    "
                    [ngClass]="{ odd: odd, even: even }"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>
                      {{ data.account_name }}
                      <i
                        class="fa fa-exchange mnabricon s2icon"
                        routerLink="/accounts/statement_of_accounts/{{
                          data.account_masters_id
                        }}/"
                        matTooltip="{{ 'lang_view_transactions' | language }}"
                      ></i>
                    </td>
                    <td>{{ ds.addCommas(data.debit_amount) }}</td>
                    <td>{{ ds.addCommas(data.credit_amount) }}</td>
                    <td>
                      {{ data.transaction_history_description }}
                    </td>
                    <td *ngIf="data.cost_name">
                      {{ data.cost_name }}
                      <i
                        class="fa fa-exchange mnabricon s2icon"
                        routerLink="/accounts/statement_of_costs/{{
                          data.cost_center_id
                        }}/"
                        matTooltip="{{ 'lang_view_transactions' | language }}"
                      ></i>
                    </td>
                    <td *ngIf="!data.cost_name">----</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        mat-dialog-close
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>
