<breadcrumb></breadcrumb>
<div class="themesection">
    <section class="panel-expansion matborderno">
        <mat-accordion class="accounts_masters" >
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_income_statements' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body">
                    <div class="mbrl15">
                        <form class="form" [formGroup]="form" (ngSubmit)="fetch_statements()">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                    <select formControlName="branch_id" class="form-control almnabrformcontrol" matTooltip="{{'lang_branch' | language }}" (change)="spinner.show();selectBrach($event.target.value);">
                                        <option value="0">{{'lang_choose_options' | language }}</option>
                                        <option *ngFor="let branch of branches" value="{{branch.id}}">{{branch.title}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10"
                                    *ngIf="form.get('branch_id').value && form.get('branch_id').value != '0' ">
                                    <ng-select 
                                            [items]="finances" 
                                            [multiple]="false" 
                                            bindLabel="label" 
                                            bindValue="value" 
                                            appendTo="body" 
                                            clearAllText="Clear"
                                            formControlName="finance_id"
                                            placeholder="{{'lang_financial_years' | language }}"
                                            (change) = "ds.setDate($event, form);"
                                        >
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                    <div class="form-group">
                                        <label>{{'lang_period_from' | language }} : <span class="red">*</span> </label><br>
                                        <input formControlName="period_from" id="period_from" class="form-control almnabrformcontrol period_from" placeholder="YYYY/MM/DD" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                    <div class="form-group">
                                        <label>{{'lang_period_to' | language }} :<span class="red">*</span> </label><br>
                                        <input formControlName="period_to" id="period_to" class="form-control almnabrformcontrol period_to" placeholder="YYYY/MM/DD" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                    <mat-radio-group aria-label="Select an option" formControlName="report_type">
                                        <mat-radio-button value="GLEDGER" checked>{{'lang_based_on_general_ledger' | language }} </mat-radio-button>
                                        <mat-radio-button value="ALEDGER"> {{'lang_based_on_accounts' | language }}</mat-radio-button>
                                        <mat-radio-button value="ALEVEL"> {{'lang_account_code_level' | language }}</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0' && form.get('report_type').value == 'ALEVEL' ">
                                    <div class="form-group">
                                        <select class="form-control almnabrformcontrol account_level" formControlName="account_level">
                                            <option value="1"> {{'lang_level' | language }} 1</option>
                                            <option value="2"> {{'lang_level' | language }} 2</option>
                                            <option value="3"> {{'lang_level' | language }} 3</option>
                                            <option value="4"> {{'lang_level' | language }} 4</option>
                                            <option value="5"> {{'lang_level' | language }} 5</option>
                                            <option value="6"> {{'lang_level' | language }} 6</option>
                                            <option value="7"> {{'lang_level' | language }} 7</option>
                                            <option value="8"> {{'lang_level' | language }} 8</option>
                                            <option value="9"> {{'lang_level' | language }} 9</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                    <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                                </div>
                            </div>
                        </form>
                        <div class="row" *ngIf="datastatus && form.get('branch_id').value && form.get('branch_id').value != '0'">
                            <div class="col-12 mb-10">
                                <div class="table-responsive dropdownyes customResponsive tablefields">
                                    <span class="red">{{'lang_income_statements' | language }}</span>
                                    <table border="1" class="table CustomTable">
                                        <thead>
                                            <tr>
                                                <td>#</td>
                                                <td>{{'lang_accounts_acccode' | language }}</td>
                                                <td>{{'lang_account' | language }}</td>
                                                <td>{{'lang_invoice_item_amount' | language }}</td>
                                                <td>{{'lang_action' | language }}</td>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="cidata.length != '0' " class="AppendList">
                                            <tr *ngFor="let ci of cidata; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="{{ci.class}}">
                                                <td>{{i+1}}</td>
                                                <td>{{ci.account_code}}</td>
                                                <td>{{ci.account_name}}</td>
                                                <td>{{ds.addCommas(ci.amount)}}</td>
                                                <td *ngIf="ci.transaction_allow == '0'">
                                                    <a target="_blank" class="fa fa-exchange mnabricon" routerLink="/accounts/statement_of_accounts/{{ci.account_cost_id}}/" matTooltip="{{'lang_view_transactions' | language}}"></a>
                                                </td>
                                                <td *ngIf="ci.transaction_allow == '1'"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-between p-2" *ngIf="cidata.length>0">
                                        <ngb-pagination [collectionSize]="cicollectionSize" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="cipage" [pageSize]="cipageSize" (pageChange)="ciRefreshLists()"></ngb-pagination>
                                        <label>{{'lang_total' | language }} : {{cicollectionSize}}</label>
                                        <select class="customselect" [(ngModel)]="cipageSize" (change)="ciRefreshLists()">
                                            <option [ngValue]="10">10</option>
                                            <option [ngValue]="20">20</option>
                                            <option [ngValue]="50">50</option>
                                            <option [ngValue]="100">100</option>
                                            <option [ngValue]="250">250</option>
                                            <option [ngValue]="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-10">
                                <div class="table-responsive dropdownyes customResponsive tablefields">
                                    <span class="red">{{'lang_expense_statements' | language }}</span>
                                    <table border="1" class="table CustomTable">
                                        <thead>
                                            <tr>
                                                <td>#</td>
                                                <td>{{'lang_accounts_acccode' | language }}</td>
                                                <td>{{'lang_account' | language }}</td>
                                                <td>{{'lang_invoice_item_amount' | language }}</td>
                                                <td>{{'lang_action' | language }}</td>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="cedata.length != '0' " class="AppendList">
                                            <tr *ngFor="let ce of cedata; let i = index;let odd=odd; let even=even"
                                                [ngClass]="{ odd: odd, even: even }" class="{{ce.class}}">
                                                <td>{{i+1}}</td>
                                                <td>{{ce.account_code}}</td>
                                                <td>{{ce.account_name}}</td>
                                                <td>{{ds.addCommas(ce.amount)}}</td>
                                                <td *ngIf="ce.transaction_allow == '0'">
                                                    <a target="_blank" class="fa fa-exchange mnabricon" routerLink="/accounts/statement_of_accounts/{{ce.account_cost_id}}/" matTooltip="{{'lang_view_transactions' | language}}"></a>
                                                </td>
                                                <td *ngIf="ce.transaction_allow == '1'"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-between p-2" *ngIf="cedata.length>0">
                                        <ngb-pagination [collectionSize]="cecollectionSize" [rotate]="true" [ellipses]="false"
                                            [maxSize]="3" [boundaryLinks]="true" [(page)]="cepage" [pageSize]="cepageSize" (pageChange)="ceRefreshLists()">
                                        </ngb-pagination>
                                        <label>{{'lang_total' | language }} : {{cecollectionSize}}</label>
                                        <select class="customselect" [(ngModel)]="cepageSize" (change)="ceRefreshLists()">
                                            <option [ngValue]="10">10</option>
                                            <option [ngValue]="20">20</option>
                                            <option [ngValue]="50">50</option>
                                            <option [ngValue]="100">100</option>
                                            <option [ngValue]="250">250</option>
                                            <option [ngValue]="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer padb0" *ngIf="profit_statements">
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label class="bold">
                                    {{'lang_total_income' | language }} : {{ds.addCommas(profit_statements.total_income)}}
                                </label>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label class="bold">
                                    {{'lang_total_expenses' | language }} : {{ds.addCommas(profit_statements.total_expenses)}}
                                </label>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label class="bold" *ngIf="profit_statements.status == 'P'">{{'lang_net_profit' | language }} : {{ds.addCommas(profit_statements.total_profit_or_loss)}}</label>
                                <label class="bold" *ngIf="profit_statements.status == 'L'">{{'lang_net_loss' | language }} : {{ds.addCommas(profit_statements.total_profit_or_loss)}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>