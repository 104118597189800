import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-available-units",
	templateUrl: "./available-units.component.html",
	styleUrls: ["./available-units.component.scss"],
})
export class AvailableUnitsComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	projects_profile_id: any;
	projects_work_area_id: any;
	projects_supervision_id: any;
	unit_msg = this.lang.transform("lang_loading");
	units = [];
	unit_type = "accepted";
	work_type_list = [
		{
			label: this.lang.transform("lang_accepted_units"),
			value: "accepted",
		},
		{
			label: this.lang.transform("lang_unaccepted_units"),
			value: "unAccepted",
		},
	];
	work_site_blocks = null;
	work_site_blocks_list = [];
	total_records: any;
	page_no = 1;
	page_size = 10;
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public router: Router,
		private dialogRef: MatDialogRef<AvailableUnitsComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private sweetAlert: SweetAlertService
	) {
		this.projects_profile_id = data?.projects_profile_id;
		this.projects_work_area_id = data?.projects_work_area_id;
		this.projects_supervision_id = data?.projects_supervision_id;
	}

	ngOnInit(): void {
		this.get_blocks_List();
		this.getAcceptedUnacceptedUnits();
	}

	get_blocks_List() {
		this.spinner.show();
		let param = new FormData();
		param.append("projects_profile_id", this.projects_profile_id || "");
		param.append("projects_work_area_id", this.projects_work_area_id || "");
		param.append("projects_supervision_id", this.projects_supervision_id || "");
		param.append("lang_key", this.lang_key);
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "p/snag/snaglistblocks")
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.work_site_blocks_list = res?.records;
					} else {
						this.work_site_blocks_list = [];
					}
				},
				(error) => {
					this.spinner.hide();
					this.work_site_blocks_list = [];
					this.sweetAlert.errorToast(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}

	getAcceptedUnacceptedUnits() {
		let formData = new FormData();
		formData.append("projects_profile_id", this.projects_profile_id || "");
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append(
			"projects_supervision_id",
			this.projects_supervision_id || ""
		);
		formData.append("work_site_blocks", this.work_site_blocks || "");
		let table_type =
			this.unit_type == "accepted"
				? "get_accepted_units"
				: "get_unaccepted_units";
		this.spinner.show();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				`p/snag/${table_type}/${this.page_no}/${this.page_size}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.units = res?.records;
						this.total_records = res?.total_records;
					} else {
						this.units = [];
						this.unit_msg = res?.error;
					}
				},
				(err) => {
					this.spinner.hide();
					this.units = [];
				}
			);
	}
}
