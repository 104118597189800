import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.scss']
})
export class PersonDetailsComponent implements OnInit {
  @Input() persons:any;
  page = 1;
  pageSize = 5;
  constructor() { }

  ngOnInit(): void {
    
  }

}
