<div mat-dialog-title class="d-flex justify-content-between">
	<h4 class="modal-title">{{ "lang_permit_mentions" | language }}</h4>
	<button type="button" class="close" mat-dialog-close>&times;</button>
</div>
<form [formGroup]="permissionForm" mat-dialog-content>
	<div>
		<input type="hidden" formControlName="branch_id" />
		<div class="form-group">
			<label>{{ "lang_branch" | language }} :<span class="red">*</span></label>
			<select
				class="form-control almnabrformcontrol"
				formControlName="branch_id"
			>
				<option value="">{{ "lang_choose_options" | language }}</option>
				<option *ngFor="let branch of branches" value="{{ branch.value }}">
					{{ branch.label }}
				</option>
			</select>
			<mat-error
				*ngIf="
					permissionForm.controls['branch_id'].touched &&
					permissionForm.controls['branch_id'].errors?.required
				"
			>
				{{ "lang_field_required" | language }}
			</mat-error>
		</div>
		<div class="form-group">
			<label>{{ "lang_group" | language }} :<span class="red">*</span></label>
			<select
				class="form-control almnabrformcontrol"
				formControlName="group_id"
			>
				<option value="">{{ "lang_choose_options" | language }}</option>
				<option *ngFor="let group of groups" value="{{ group.value }}">
					{{ group.label }}
				</option>
			</select>
			<mat-error
				*ngIf="
					permissionForm.controls['group_id'].touched &&
					permissionForm.controls['group_id'].errors?.required
				"
			>
				{{ "lang_field_required" | language }}
			</mat-error>
		</div>
		<div class="form-group">
			<label>{{ "lang_users" | language }}:</label>
			<ng-select
				[items]="users"
				[multiple]="true"
				bindLabel="label"
				bindValue="value"
				clearAllText="Clear"
				appendTo="body"
				formControlName="users_id"
				placeholder="{{ 'lang_users' | language }}"
			>
			</ng-select>
			<mat-error
				*ngIf="
					permissionForm.controls['users_id'].touched &&
					permissionForm.controls['users_id'].errors?.required
				"
			>
				{{ "lang_field_required" | language }}
			</mat-error>
		</div>
	</div>
</form>
<div mat-dialog-actions class="justify-content-between">
	<button
		mat-button
		mat-dialog-close
		class="albutton"
		style="width: 100px !important"
	>
		{{ "lang_close" | language }}
	</button>
	<button
		mat-button
		mat-dialog-close
		type="submit"
		class="albutton"
		(click)="savepermissions()"
		style="width: 100px !important"
	>
		{{ "lang_submit" | language }}
	</button>
</div>
