<section class="meetings">
	<div class="meetings-header">
		<h3>{{ "lang_meetings" | language }}</h3>
		<i class="fa fa-times mnabricon" (click)="onHideMeetings()"></i>
	</div>
	<hr />
	<div class="meetings-content">
		<mat-tab-group class="meeting-tabs" [selectedIndex]="selectedTab">
			<mat-tab>
				<app-meeting-list
					(viewMeeting)="onShowMeeting($event)"
					[taskId]="taskId"
				></app-meeting-list>
			</mat-tab>
			<mat-tab>
				<app-meeting-view
					[meetingId]="viewMeetingId"
					(returnToMeetings)="onHideViewMeeting($event)"
					(goToChecklist)="onShowChecklist($event)"
					*ngIf="viewMeetingId"
				></app-meeting-view>
			</mat-tab>
			<mat-tab>
				<app-meeting-sub-point-view
					(returnToMeeting)="onHideViewChecklist($event)"
					[meetingId]="viewMeetingIdForChecklist"
					*ngIf="viewMeetingIdForChecklist"
				></app-meeting-sub-point-view>
			</mat-tab>
		</mat-tab-group>
	</div>
</section>
