<div class="row">
  <div class="col-md-4 mt-2 mb-2">
    <mat-form-field
      appearance="outline"
      class="reason-date"
      style="width: 100%"
    >
      <mat-label>{{ "lang_date" | language }}</mat-label>
      <mat-date-range-input
        [formGroup]="reasonFromToDate"
        [rangePicker]="picker"
      >
        <input
          matStartDate
          formControlName="start"
          readonly
          placeholder="{{ 'lang_start_date' | language }}"
        />
        <input
          matEndDate
          formControlName="end"
          readonly
          placeholder="{{ 'lang_end_date' | language }}"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #picker (closed)="getEmployeeData()">
      </mat-date-range-picker>
    </mat-form-field>
  </div>
  <div class="col-md-8 mt-2 mb-2">
    <form [formGroup]="searchForm" class="w-100">
      <div class="row my-2 mx-2 filters">
        <div class="col-md-6 col-12">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="lang_employee_id"
              name="lang_employee_id"
              formControlName="employee_id"
              placeholder="{{ 'lang_human_resources_id_number' | language }}"
              (keyup)="sortArray()"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="employee_name"
              name="employee_name"
              placeholder="{{
                'lang_human_resources_employee_name' | language
              }}"
              formControlName="employee_name"
              (keyup)="sortArray()"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-4 pl-3 pr-3 mb-2 d-flex align-items-center">
    <ng-select
      style="width: 100%"
      [multiple]="false"
      appendTo="body"
      [searchable]="false"
      clearAllText="Clear"
      placeholder="{{ 'lang_type' | language }}"
      [(ngModel)]="reasonFilterType"
      (change)="getEmployeeData()"
      class="reason-type"
    >
      <ng-option value="absent">{{ "lang_absent" | language }}</ng-option>
      <ng-option value="not_ok"> {{ "lang_not_ok" | language }}</ng-option>
      <ng-option value="record_missing">
        {{ "lang_missing_record" | language }}</ng-option
      >
    </ng-select>
  </div>
</div>

<div class="row my-2 mx-2 mt-4 over-flow">
  <div
    class="col-md-6 col-12 col-sm-12"
    [ngClass]="{ 'col-md-12': usersList.length == 0 }"
  >
    <h2>
      {{ "lang_suggested" | language }}
    </h2>
    <table class="table table-striped custom-payrole-table">
      <thead>
        <tr>
          <th scope="col">
            <mat-icon
              (click)="addAllSuggested(employeesSuggested)"
              matTooltip="{{ 'lang_select_all' | language }}"
              class="delete-all"
            >
              done
            </mat-icon>
          </th>
          <th scope="col">
            {{ "lang_human_resources_profile_image" | language }}
          </th>
          <th scope="col">
            {{ "lang_human_resources_employee_name" | language }}
          </th>
          <th scope="col">{{ "lang_human_resources_number" | language }}</th>
          <th scope="col">{{ "lang_status" | language }}</th>
          <th scope="col">{{ "lang_date" | language }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of employeesSuggestedfiltered; let i = index">
          <th scope="row">
            <mat-icon
              matTooltip="{{ 'lang_select' | language }}"
              (click)="addEmp([item], i)"
            >
              done
            </mat-icon>
          </th>
          <td>
            <span *ngIf="item?.profile_image">
              <span class="result_code"
                ><img
                  src="{{ item.profile_image }}"
                  style="
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    width: 47px;
                    height: 47px;
                  "
                  alt="" /></span
            ></span>
            <span *ngIf="!item?.profile_image">
              <span class="result_code"
                ><img
                  src="assets/img/profile (1).png"
                  style="width: 47px; height: 47px"
                  alt="" /></span
            ></span>
          </td>
          <td>{{ item.employee_name }}</td>
          <td>{{ item.employee_number }}</td>
          <td>{{ item.state }}</td>
          <td>{{ item.date }}</td>
        </tr>

        <tr *ngIf="employeesSuggestedfiltered?.length == 0">
          <th colspan="7">
            <div class="no_data">
              {{ "lang_no_suggested_employees" | language }}
            </div>
          </th>
        </tr>
      </tbody>
    </table>

    <div class="mb-10 custom-footer no-border">
      <div class="row responsive_pagination">
        <div
          class="col-lg-3 col-sm-3 col-6 mb-10"
          style="height: fit-content; margin: auto 0"
        >
          {{ "lang_total" | language }} :
          {{ total }}
        </div>
        <div
          class="col-lg-6 col-sm-6 mb-10"
          style="display: flex; justify-content: center"
        >
          <ngb-pagination
            class="m-auto"
            [collectionSize]="total"
            [rotate]="true"
            [ellipses]="false"
            [maxSize]="3"
            [boundaryLinks]="true"
            [(page)]="page"
            [pageSize]="size"
            (pageChange)="load_by_page_no($event)"
          ></ngb-pagination>
        </div>
        <div
          class="col-lg-3 col-sm-3"
          style="display: flex; justify-content: end; margin: auto"
        >
          <mat-select
            [(ngModel)]="size"
            (selectionChange)="load_by_page_size($event.value)"
          >
            <mat-option class="text-center" [value]="10"
              >10 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" [value]="20"
              >20 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" [value]="50"
              >50 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" [value]="100"
              >100 {{ "lang_per_page" | language }}</mat-option
            >
            <mat-option class="text-center" [value]="500"
              >500 {{ "lang_per_page" | language }}</mat-option
            >
          </mat-select>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-12 col-sm-12" *ngIf="usersList.length > 0">
    <h2>
      {{ "lang_selected" | language }}
    </h2>
    <table class="table table-striped custom-payrole-table">
      <thead>
        <tr>
          <th scope="col">
            <mat-icon
              (click)="removeAllSelected(usersList)"
              matTooltip="{{ 'lang_delete_all' | language }}"
              class="delete-all"
            >
              delete
            </mat-icon>
          </th>
          <th scope="col">
            {{ "lang_human_resources_profile_image" | language }}
          </th>
          <th scope="col">
            {{ "lang_human_resources_employee_name" | language }}
          </th>
          <th scope="col">{{ "lang_human_resources_id_number" | language }}</th>
          <th scope="col">{{ "lang_human_resources_number" | language }}</th>
          <th scope="col">{{ "lang_branch" | language }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of usersList; let i = index">
          <th scope="row">
            <mat-icon
              matTooltip="{{ 'lang_delete' | language }}"
              (click)="removeEmp([item], i)"
            >
              delete
            </mat-icon>
          </th>
          <td>
            <span *ngIf="item?.profile_image">
              <span class="result_code"
                ><img
                  src="{{ item.profile_image }}"
                  style="
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    width: 47px;
                    height: 47px;
                  "
                  alt="" /></span
            ></span>
            <span *ngIf="!item?.profile_image">
              <span class="result_code"
                ><img
                  src="assets/img/profile (1).png"
                  style="width: 47px; height: 47px"
                  alt="" /></span
            ></span>
          </td>
          <td>{{ item.employee_name }}</td>
          <td>{{ item.employee_number }}</td>
          <td>{{ item.employee_id_number }}</td>
          <td>{{ item.employee_number }}</td>
        </tr>

        <tr *ngIf="usersList?.length == 0">
          <th colspan="7">
            <div class="no_data">
              {{ "lang_no_selected_employees" | language }}
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="w-100 text-start">
  <button (click)="cancel()" class="btn mb-2 mx-2 btn-cancel">
    {{ "Back" | language }}
  </button>
  <button (click)="save()" class="btn mb-2 mx-2 btn-apply">
    {{ "Apply" | language }}
  </button>
</div>
