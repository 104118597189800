import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-snaglist-add',
  templateUrl: './snaglist-add.component.html',
  styleUrls: ['./snaglist-add.component.scss']
})
export class SnaglistAddComponent implements OnInit {
  lang_key        = localStorage.getItem('lang_key') || 'en';
  snag_form: FormGroup;
  snag_array: FormArray;
  group1_list: any = [];
  group2_list: any = [];
  constructor(private fb: FormBuilder, public ds: DataService, private sweetAlert: SweetAlertService, private lang: LanguagePipe, 
    private spinner: PreloaderService, private dialogRef: MatDialogRef<SnaglistAddComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.get_group1_list({trem:''});
    this.buildForm();
    this.add_sang_records();
  }

  buildForm() {
    this.snag_form = this.fb.group({
      notes: this.fb.array([])
    });
    this.snag_array = this.snag_form.get('notes') as FormArray;
  }
  get snag_formarray() {
    return this.snag_form.get("notes") as FormArray;
  }
  snag_records(): FormGroup {
    return this.fb.group({
      platform_group1_code_system: [null, Validators.required],
      platform_group2_code_system: [null, Validators.required],
      note_title_english:          ['', Validators.required],
      note_title_arabic:           ['', Validators.required]
    });
  }
  add_sang_records() {
    this.snag_array.push(this.snag_records());
  }
  removeSang(index) {
    this.snag_array.removeAt(index);
  }

  get_group1_list(event) {
    this.spinner.show();
    let param = new FormData();
    param.append('search_key',  event?.trem || '');
    param.append('lang_key', this.lang_key);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgone').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.group1_list = res?.records;
      } else {
        this.group1_list = [];
      }
    }, error => {
      this.spinner.hide();
      this.group1_list = [];
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    });
  }

  get_group2_list(event, index) {
    this.spinner.show();
    let param = new FormData();
    param.append('search_key', event?.trem || '');
    param.append('lang_key', this.lang_key);
    param.append('platform_group1_code_system', this.snag_array.controls[index].get('platform_group1_code_system').value || '');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgtwo').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.group2_list = res?.records;
      } else {
        this.group2_list = [];
      }
    }, error => {
      this.spinner.hide();
      this.group2_list = [];
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    });
  }

  add_snag() {
    if(this.snag_form.valid) {
      this.spinner.show();
      let formData = new FormData();
      for(let i =0; i<this.snag_formarray.value.length; i++) {
        formData.append(`notes[${i}][platform_group1_code_system]`, this.snag_formarray.controls[i].get('platform_group1_code_system').value);
        formData.append(`notes[${i}][platform_group2_code_system]`, this.snag_formarray.controls[i].get('platform_group2_code_system').value);
        formData.append(`notes[${i}][note_title_english]`, this.snag_formarray.controls[i].get('note_title_english').value);
        formData.append(`notes[${i}][note_title_arabic]`, this.snag_formarray.controls[i].get('note_title_arabic').value);
      }
      this.ds.post('p/snag/create', formData).subscribe(res => {
        this.spinner.hide();
        if(res?.status) {
          this.dialogRef.close(true);
          this.sweetAlert.successToast(res?.msg, 2000);
        } else {
          this.sweetAlert.errorToast(res?.error, 2000);
        }
      }, error => {
        this.spinner.hide();
        this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
      });
    } else { 
      this.sweetAlert.errorToast(this.lang.transform('lang_fill_required_fields'), 2000);
    }
  }
}
