<hr>
<label>{{'lang_change_theme' | language}}</label>
<hr>
<form [formGroup]="fg" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-12">
            <div class="form-group color">
                <label>{{'lang_header_colorA' | language}} <span class="red">*</span></label>
                <input class="form-control almnabrformcontrol" [(ngModel)]="acolor" [ngModelOptions]="{standalone: true}" [style.background]="acolor" autocomplete="off" [(colorPicker)]="acolor" [cpPosition]="'bottom'"/>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-group color">
                <label>{{'lang_header_colorB' | language}} <span class="red">*</span></label>
                <input class="form-control almnabrformcontrol" [(ngModel)]="bcolor" [ngModelOptions]="{standalone: true}" [style.background]="bcolor" autocomplete="off" [(colorPicker)]="bcolor" [cpPosition]="'bottom'"/>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-group color">
                <label>{{'lang_icon_color' | language}} <span class="red">*</span></label>
                <input class="form-control almnabrformcontrol" [(ngModel)]="dcolor" [ngModelOptions]="{standalone: true}" [style.background]="dcolor" autocomplete="off" [(colorPicker)]="dcolor" [cpPosition]="'bottom'"/>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-group color">
                <label>{{'lang_text_color' | language}} <span class="red">*</span></label>
                <input class="form-control almnabrformcontrol" [(ngModel)]="ccolor" [ngModelOptions]="{standalone: true}" [style.background]="ccolor" autocomplete="off" [(colorPicker)]="ccolor" [cpPosition]="'bottom'"/>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-group color">
                <button type="submit" [disabled]="!fg.valid" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_save' | language}}</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-group color">
                <button type="button" (click)="reset()" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_reset' | language}}</button>
            </div>
        </div>
    </div>
</form>