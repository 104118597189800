import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {
  edit_mode = false;
  groupForm:FormGroup;
  groupData;
  constructor(private fb:FormBuilder, private ds: DataService, private sweetAlert: SweetAlertService, public dialogRef: MatDialogRef<AddGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private lang: LanguagePipe) { 
    this.buildForm();
    if(data?.group) {
      this.edit_mode = true;
      this.groupData = data?.group;
      this.setEditData();
    }
  }

  ngOnInit(): void {
  }

  buildForm() {
    this.groupForm = this.fb.group({
      group_name : ['', Validators.required],
      group_email: ['', [Validators.required, Validators.email]]
    });
  }
  setEditData() {
    this.groupForm.get('group_name').setValue(this.groupData?.group_name);
    this.groupForm.get('group_email').setValue(this.groupData?.group_email);
  }
  onSubmit() {
    let formData = new FormData();
    formData.append('group_name', this.groupForm.get('group_name').value   || '');
    formData.append('group_email', this.groupForm.get('group_email').value || '');
    let end_point = 'tasks/add_group';
    if(this.edit_mode) {
      end_point = 'tasks/update_group';
      formData.append('group_id', this.groupData?.group_id || '');
    }
    if(this.groupForm.valid) {
      this.ds.post(end_point, formData).subscribe(res => {
        if(res?.status) {
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 2000);
          this.dialogRef.close(true);
        } else {
          this.sweetAlert.errorToast(res?.error, 2000);
        }
      }, err => {
        this.sweetAlert.errorToast(this.lang.transform('lang_internal_server_error'), 2000);
        this.dialogRef.close();
      });
    } else {
      this.sweetAlert.errorToast(this.lang.transform('lang_fill_required_fields'), 2000);
    }
  }
}
