import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-cdetails-add',
  templateUrl: './cdetails-add.component.html',
  styleUrls: ['./cdetails-add.component.scss']
})
export class CdetailsAddComponent implements OnInit {
  modalRefA: BsModalRef;
  @Input() collections;
  @Input() group1codes;
  @Input() grouptypes;
  @Input() action;
  @Input() editdata;
  form:FormGroup;
  access_code_datas:FormArray;
  @Output() addEvent                = new EventEmitter();
  @Output() closeModel              = new EventEmitter();
  submitted:boolean                 = false;
  lang_key                          = localStorage.getItem('lang_key') || 'en';
  group2codes:any                   = [];
  platforms:any                     = [];
  group2codesmodel:any              = [];
  platformsmodel:any                = [];
  finalaccesscodes:any              = [];
  previousvalues:any                = [];
  platform_group1_code_system       = null;
  platform_group_type_code_system   = null;
  platform_group2_code_system       = null;
  platform_code_system              = null;
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public spinner:PreloaderService, public alert:AlertService, public modalService: BsModalService, public dialog:MatDialog, public fb:FormBuilder) { }
  ngOnInit(): void {
    this.form = new FormGroup({
      collection_details_id           : new FormControl(''),
      collection_id                   : new FormControl(null, [Validators.required]),
      platform_group1_code_system     : new FormControl(null, [Validators.required]),
      platform_group_type_code_system : new FormControl(null, [Validators.required]),
      platform_group2_code_system     : new FormControl(null, [Validators.required]),
      platform_code_system            : new FormControl(null, [Validators.required]),
      platform_code_systems           : new FormControl(null),
    });
    this.alert.clear();
    this.form.reset();
    if(this.action == "edit" && this.editdata) {
      this.get_data_for_edit();
    }
  }
  public get_data_for_edit() {
    let param = new FormData();
    param.append('lang_key', this.lang_key);
    this.spinner.show();
    this.ds.getActionByUrl(this.ds.formData2string(param), 'gpcollectiondet/'+this.editdata.collection_details_id).subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.finalaccesscodes = (res.data.platform_code_systems) ? res.codes : null;
        this.form.patchValue({
          collection_details_id           : res.data.collection_details_id,
          collection_id                   : res.data.collection_id,
          platform_group1_code_system     : res.data.platform_group1_code_system,
          platform_group_type_code_system : res.data.platform_group_type_code_system,
          platform_code_systems           : (res.data.platform_code_systems) ? res.data.platform_code_systems.split(',') : null,
        });
        this.group2_search();
        this.form.get('platform_group2_code_system').setValue(res.data.platform_group2_code_system);
        this.platform_search();
        this.form.get('platform_code_system').setValue(res.data.platform_code_system);
      }
    }, error => {
     this.spinner.hide();
    });
  }
  public group2_search() {
    this.group2codes = [];
    this.form.get('platform_group2_code_system').setValue(null);
    this.form.get('platform_code_system').setValue(null);
    let param = new FormData();
      param.append('lang_key',                        this.lang_key);
      param.append('platform_group1_code_system',     this.form.get('platform_group1_code_system').value || '');
      param.append('platform_group_type_code_system', this.form.get('platform_group_type_code_system').value || '');
      if(this.form.get('platform_group1_code_system').value && this.form.get('platform_group_type_code_system').value) {
        this.spinner.show();
        this.ds.getActionByUrl(this.ds.formData2string(param),'lpgtwo').subscribe(res=> {
          this.spinner.hide();
          if(res.status) {
            this.group2codes = res.records;
          }
        }, error => {
          this.spinner.hide();
        });
      }
  }
  public platform_search() {
    this.platforms = [];
    this.form.get('platform_code_system').setValue(null);
    let param = new FormData();
      param.append('lang_key',                        this.lang_key);
      param.append('platform_group1_code_system',     this.form.get('platform_group1_code_system').value || '');
      param.append('platform_group_type_code_system', this.form.get('platform_group_type_code_system').value || '');
      param.append('platform_group2_code_system',     this.form.get('platform_group2_code_system').value || '');
      if(this.form.get('platform_group1_code_system').value && this.form.get('platform_group_type_code_system').value && this.form.get('platform_group2_code_system').value) {
        this.spinner.show();
        this.ds.getActionByUrl(this.ds.formData2string(param),'lpplatform').subscribe(res=>{
          this.spinner.hide();
          if(res.status) {
            this.platforms = res.records;
          }
        }, error => {
          this.spinner.hide();
        });
      }
  }
  public group2_model_search() {
    this.platform_group2_code_system = null;
    this.group2codesmodel = [];
    let param = new FormData();
      param.append('lang_key',                        this.lang_key);
      param.append('platform_group1_code_system',     this.platform_group1_code_system || '');
      param.append('platform_group_type_code_system', this.platform_group_type_code_system || '');
      if(this.platform_group1_code_system && this.platform_group_type_code_system) {
        this.spinner.show();
        this.ds.getActionByUrl(this.ds.formData2string(param),'lpgtwo').subscribe(res=> {
          this.spinner.hide();
          if(res.status) {
            this.group2codesmodel = res.records;
          }
        }, error => {
          this.spinner.hide();
        });
      }
  }
  public platform_model_search() {
    this.platformsmodel = [];
    this.platform_code_system = null;
    let param = new FormData();
      param.append('lang_key',                        this.lang_key);
      param.append('platform_group1_code_system',     this.platform_group1_code_system || '');
      param.append('platform_group_type_code_system', this.platform_group_type_code_system || '');
      param.append('platform_group2_code_system',     this.platform_group2_code_system || '');
      if(this.platform_group1_code_system  && this.platform_group_type_code_system && this.platform_group2_code_system) {
        this.spinner.show();
        this.ds.getActionByUrl(this.ds.formData2string(param),'lpplatform').subscribe(res=>{
          this.spinner.hide();
          if(res.status) {
            this.platformsmodel = res.records;
          }
        }, error => {
          this.spinner.hide();
        });
      }
  }
  public add_platform_code_systems() {
    this.finalaccesscodes         = (this.finalaccesscodes) ? this.finalaccesscodes.concat(this.platformsmodel) : this.platformsmodel;
    if(this.form.get('platform_code_systems').value) {
      this.previousvalues         = this.previousvalues.concat(this.form.get('platform_code_systems').value);
    }
    if(this.platform_code_system) {
      this.form.get('platform_code_systems').setValue(this.previousvalues.concat(this.platform_code_system));  
    }
    this.platform_code_system     = this.platform_group2_code_system = null;
    this.platformsmodel           = [];
  }
  public submit() {
    this.submitted = true;
    if(this.form.valid) {
      this.submitted = false;
      let param = new FormData();
      param.append('collection_id', this.form.get('collection_id').value || '');
      param.append('platform_group1_code_system', this.form.get('platform_group1_code_system').value || '');
      param.append('platform_group_type_code_system', this.form.get('platform_group_type_code_system').value || '');
      param.append('platform_group2_code_system', this.form.get('platform_group2_code_system').value || '');
      param.append('platform_code_system', this.form.get('platform_code_system').value || '');
      if(this.form.get('platform_code_systems').value && this.form.get('platform_code_systems').value.length > 0) {
        param.append('platform_code_systems', this.form.get('platform_code_systems').value.filter(function(elem, index, self) { return index == self.indexOf(elem); }));
      }
      let url = (!this.form.get('collection_details_id').value) ? 'cpcollectiondet' : 'upcollectiondet/'+this.form.get('collection_details_id').value;
      this.spinner.show();
      this.ds.postActionByUrl(param, url).subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.form.reset();
          setTimeout(() => {
            this.addEvent.emit('S');
          }, 1000);
          this.alert.success(res.msg);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.submitted = true;
    }
  }
}
