import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { MeetingCheckAddUpdateComponent } from "../meeting-check-add-update/meeting-check-add-update.component";

@Component({
	selector: "app-meeting-sub-point-view",
	templateUrl: "./meeting-sub-point-view.component.html",
	styleUrls: ["./meeting-sub-point-view.component.scss"],
})
export class MeetingSubPointViewComponent implements OnInit {
	@Output() returnToMeeting = new EventEmitter<any>();
	@Input() meetingId;
	meeting;
	userId = this.localstorageService.getItem("user_id");
	constructor(
		private localstorageService: LocalStorage,
		private spinner: PreloaderService,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.getMeetingData();
	}
	back_to_meeting() {
		this.returnToMeeting.emit({
			isViewMeetings: true,
		});
	}

	getMeetingData() {
		let formData = new FormData();
		formData.append("meeting_id", this.meetingId || "");
		this.ds.post("tasks/get_row_meetings", formData).subscribe((res) => {
			if (res?.status) {
				this.meeting = res?.data;
			} else {
				this.meeting = null;
			}
		});
	}

	deleteChecklist(meeting_id, point_id) {
		let formData = new FormData();
		formData.append("meeting_id", meeting_id || "");
		formData.append("point_id", point_id || "");
		this.spinner.show();
		this.ds.post("tasks/delete_point_meetings", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
					this.getMeetingData();
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}

	onAddSubPoint(check_id) {
		const dialogRef = this.dialog.open(MeetingCheckAddUpdateComponent, {
			width: "600px",
			autoFocus: false,
			data: {
				meeting: this.meeting,
				point_id: check_id,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.getMeetingData();
			}
		});
	}

	deleteChecklistItem(check_id) {
		let formData = new FormData();
		formData.append("point_sub_id", check_id || "");
		this.spinner.show();
		this.ds.post("tasks/delete_point_sub_meetings", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
					this.getMeetingData();
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
