<div class="p-2 checklist-details-view" [ngClass]="{ rtl: lang_key == 'ar' }">
	<div class="row">
		<div class="col-12">
			<div class="card-title-view">
				<h4>{{ "lang_checklist_item" | language }}</h4>
				<i
					class="fa fa-times mnabricon"
					(click)="hideView.emit(true)"
					matTooltip="{{ 'lang_close' | language }}"
				></i>
			</div>
			<hr />
		</div>
		<div class="col-12 col-sm-8">
			<div class="title">
				<mat-label class="item-label">{{ "lang_title" | language }}</mat-label>
				<p class="task-btn">{{ point?.char_item }} - {{ point?.notes }}</p>
			</div>
		</div>
		<div class="col-12 col-sm-4">
			<div class="point-progress">
				<mat-label class="item-label">{{
					"lang_progress" | language
				}}</mat-label>
				<p class="task-btn">{{ point?.prg_done || "0" }} %</p>
			</div>
		</div>
		<div class="col-12 mb-2">
			<label class="item-label">{{ "lang_description" | language }}</label>
			<div [innerHtml]="point?.more_details" *ngIf="point?.more_details"></div>
			<div *ngIf="!point?.more_details">----</div>
		</div>
		<div class="col-12">
			<label class="item-label">{{ "lang_date" | language }}</label>
			<p class="point-dates task-btn">
				{{ "lang_from" | language }} {{ point?.start_date }} -
				{{ "lang_to" | language }} {{ point?.end_date }}
			</p>
		</div>
		<div class="col-12 col-sm-6">
			<label class="item-label">{{ "lang_timer" | language }}</label>
			<p class="point-dates task-btn">
				{{ "lang_from" | language }}
				{{ point?.start_date_timer || "00:00:00" }} -
				{{ "lang_to" | language }}
				{{ point?.end_date_timer || "00:00:00" }}
			</p>
		</div>
		<div class="col-12 col-sm-6">
			<label class="item-label">{{ "lang_hour_no" | language }}</label>
			<p class="point-dates task-btn">
				{{ point?.hours_by_manual || 0 }}
			</p>
		</div>
		<div class="col-12 mb-2">
			<label class="item-label">{{ "lang_members" | language }}</label>
			<div class="members">
				<div *ngFor="let user of point_users" class="member">
					<img src="{{ user?.avatar }}" alt="avatar" *ngIf="user?.avatar" />
					<img
						src="assets/images/avatar.png"
						alt="avatar"
						*ngIf="!user?.avatar"
					/>
					<span>{{ user.firstname_english }} {{ user.lastname_english }}</span>
				</div>
			</div>
			<div *ngIf="!point_users?.length">----</div>
		</div>
		<div class="col-12 TableParts">
			<p class="mb-10 item-label">
				{{ "lang_attachments" | language }}
			</p>
			<div
				class="table-responsive customResponsive tablefields quotationitemtable"
			>
				<table
					border="1"
					class="table CustomTable"
					style="width: 99% !important"
				>
					<thead>
						<tr>
							<td width="5%" style="padding: 5px; word-break: break-all">#</td>
							<td width="48%" style="padding: 5px; word-break: break-all">
								{{ "lang_title" | language }}
							</td>
							<td width="10%" style="padding: 5px; word-break: break-all">
								{{ "lang_action" | language }}
							</td>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngIf="point_files?.length">
							<tr *ngFor="let file of point_files; let file_index = index">
								<td
									class="verticalalignmid"
									style="padding: 5px; word-break: break-all"
								>
									{{ file_index + 1 }}
								</td>
								<td
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									{{ file?.file_name_en }}
								</td>
								<td
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									<i
										class="fa fa-link mnabricon mr-1 ml-1"
										(click)="navigateNewTab(attachment?.file_path)"
										matTooltip="{{ 'lang_file' | language }}"
									></i>
									<i
										class="fa fa-trash red mr-1 ml-1"
										(click)="deletePointFile(file?.file_id)"
										matTooltip="{{ 'lang_delete' | language }}"
									></i>
								</td>
							</tr>
						</ng-container>
						<tr *ngIf="!point_files?.length">
							<td colspan="3" style="padding: 5px; word-break: break-all">
								{{ "lang_no_data" | language }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="col-12">
			<div class="ticket-timeline">
				<div class="tt-header">
					<i class="pi pi-align-left"></i>
					<h4 class="item-label">{{ "lang_history" | language }}</h4>
				</div>
				<div class="tt-body">
					<div class="ttb-list">
						<div class="ttb-list-item" *ngFor="let item of point_logs">
							<div class="ttbli-avatar">
								<img
									src="{{ item?.avatar }}"
									alt="avatar"
									*ngIf="item?.avatar"
								/>
								<img
									src="assets/images/avatar.png"
									alt="avatar"
									*ngIf="!item?.avatar"
								/>
							</div>
							<div class="ttbli-content">
								<div class="ttbli-content-header">
									<p class="m-0 d-flex">
										<span class="bold"
											>{{ item?.firstname_english }}
											{{ item?.lastname_english }}</span
										>
										<span class="desc">{{ item?.notes }}</span>
									</p>
								</div>
								<div class="ttbli-content-date">{{ item?.insert_date }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
