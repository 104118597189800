<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno">
    <mat-accordion  class="stylepanels">
      <mat-expansion-panel class="mpanel my_profile" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{'lang_myprofile' | language}}</mat-expansion-panel-header>
        <div class="material-panel-body">
            <div class="mbrl15 martb0">
                <div class="row">
                    <div class="col-xs-12 col-12 pad0 suppervision-stepper">
                        <div class="wrapper">
                            <owl-carousel-o [options]="customOptions" #stepperCar>
                                <ng-template carouselSlide id="s1">
                                    <div (click)="moveStepper('user_details')">
                                        <div class="step">
                                            <div class="st">
                                                <i
                                                    class="icon-5x fa fa-user fa-icon stepper-header-icon stepper-active user_details"
                                                ></i>
                                            </div>
                                        </div>
                                        <div
                                            matTooltip="{{ 'lang_user_details' | language }}"
                                            style="text-align: center"
                                        >
                                            {{ "lang_user_details" | language }}
                                        </div>
                                    </div>
                                </ng-template>
                                
                                <ng-template carouselSlide id="s2">
                                    <div (click)="moveStepper('login_details')">
                                        <div class="step">
                                            <div class="st">
                                                <i
                                                    class="icon-5x fa fa-sign-in fa-icon stepper-header-icon login_details"
                                                ></i>
                                            </div>
                                        </div>
                                        <div
                                            matTooltip="{{ 'lang_login_details' | language }}"
                                            style="text-align: center"
                                        >
                                            {{ "lang_login_details" | language }}
                                        </div>
                                    </div>
                                </ng-template>

                                <ng-container >
                                    <ng-template carouselSlide id="s3">
                                        <div (click)="moveStepper('signature')">
                                            <div class="step">
                                                <div class="st">
                                                   <!--  <i
                                                        class="icon-5x fa fa-files-o fa-icon"
                                                    ></i> -->
                                                    <mat-icon class="mnabricon  stepper-header-icon signature">gamepad</mat-icon>
                                                </div>
                                            </div>
                                            <div
                                                matTooltip="{{
                                                    'lang_signature' | language
                                                }}"
                                                style="text-align: center"
                                            >
                                                {{ "lang_signature" | language }}
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
 
                            </owl-carousel-o>
                            <button
                                (click)="
                                    lang_key == 'en' ? stepperCar.prev() : stepperCar.next()
                                "
                                class="carousel-btn-pre albutton mnabrbutton"
                            >
                                <i class="fa fa-chevron-left"></i>
                            </button>
                            <button
                                (click)="
                                    lang_key == 'en' ? stepperCar.next() : stepperCar.prev()
                                "
                                class="carousel-btn-next albutton mnabrbutton"
                            >
                                <i class="fa fa-chevron-right"></i>
                            </button>
                        </div>
                        <mat-horizontal-stepper #stepper class="pad0">
                            <mat-step>
                                <!-- <ng-template matStepLabel>
                                    <div class="step">
                                        <div class="st">
                                            <i class="icon-5x fa fa-user fa-icon"></i>
                                        </div>
                                    </div>
                                    <div>{{'lang_user_details' | language }}</div>
                                </ng-template> -->
                                <div class="card">
                                    <div class="card-header">{{'lang_user_details' | language }} </div>
                                    <div class="card-body p-0">
                                        <div class="mbrl15 martb0">
                                            <div class="row mb-10">
                                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                                    <label>{{'lang_human_resources_fname_english' | language}}</label>
                                                    <div class="bold">{{viewdata.user_registration_firstname_en}}</div>
                                                </div>
                                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                                    <label>{{'lang_human_resources_lname_english' | language}}</label>
                                                    <div class="bold">{{viewdata.user_registration_lastname_en}}</div>
                                                </div>
                                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                                    <label>{{'lang_human_resources_fname_arabic' | language}}</label>
                                                    <div class="bold">{{viewdata.user_registration_firstname_ar}}</div>
                                                </div>
                                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                                    <label>{{'lang_human_resources_lname_arabic' | language}}</label>
                                                    <div class="bold">{{viewdata.user_registration_lastname_ar}}</div>
                                                </div>
                                            </div>
                                            <div class="row mb-10">
                                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                                    <label>{{'lang_branch' | language}}</label>
                                                    <div class="bold">{{viewdata.branch_name}}</div>
                                                </div>
                                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                                    <label>{{'lang_human_resources_primary_email' | language}}</label>
                                                    <div class="bold">{{viewdata.user_email_address}}</div>
                                                </div>
                                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                                    <label>{{'lang_human_resources_primary_mobile' | language}}</label>
                                                    <div class="bold">{{viewdata.user_mobile_number}}</div>
                                                </div>
                                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                                    <label>{{'lang_user_type' | language}}</label>
                                                    <div class="bold">{{viewdata.user_type}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step>
                               <!--  <ng-template matStepLabel>
                                    <div class="step">
                                        <div class="st">
                                            <i class="icon-5x fa fa-sign-in fa-icon"></i>
                                        </div>
                                    </div>
                                    <div>{{'lang_login_details' | language }}</div>
                                </ng-template> -->
                                <div class="card">
                                    <div class="card-header">{{'lang_login_details' | language }} </div>
                                    <div class="card-body p-0">
                                        <div class="mbrl15 martb0">
                                            <div class="row">
                                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                                    <label>{{'lang_username' | language}}</label>
                                                    <div class="bold">{{viewdata.user_username}}</div>
                                                </div>
                                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                                    <label>{{'lang_human_resources_primary_mobile' | language}}</label>
                                                    <div class="bold">{{viewdata.user_phone}}</div>
                                                </div>
                                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                                    <label>{{'lang_human_resources_primary_email' | language}}</label>
                                                    <div class="bold">{{viewdata.user_email}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>                            
                            <mat-step>
                               <!--  <ng-template matStepLabel>
                                    <div class="step">
                                        <div class="st">
                                            <mat-icon class="mnabricon">gamepad</mat-icon>
                                        </div>
                                    </div>
                                    <div>{{'lang_signature' | language }}</div>
                                </ng-template> -->
                                <div class="card">
                                    <mat-icon class="mnabricon s2icon linkicon" data-toggle="modal" data-target="#makeSignature" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_signature' | language }}" (click)="showsignatureform();">gamepad</mat-icon>
                                    <div class="card-header">{{'lang_signature' | language }}</div>
                                    <div class="card-body">
                                        <div class="martb0">
                                            <div class="row">
                                                <div class="col-md-3 col-xs-6">
                                                    <div>{{'lang_signature' | language }}</div>
                                                    <div class="mb-10">
                                                        <a>
                                                            <img *ngIf="viewdata.signature == '0' " class="img-responsive signview" src="./assets/images/sign.png" />
                                                            <img *ngIf="viewdata.signature != '0' " (click)="showsignature('S')" class="img-responsive signview" src="./assets/images/sign.png" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-xs-6">
                                                    <div> {{'lang_mark' | language }}</div>
                                                    <div class="mb-10">
                                                        <a>
                                                            <img *ngIf="viewdata.mark == '0' " class="img-responsive signview" src="./assets/images/sign.png" />
                                                            <img *ngIf="viewdata.mark != '0' " (click)="showsignature('M')" class="img-responsive signview" src="./assets/images/sign.png" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>                            
                        </mat-horizontal-stepper>
                    </div>
                </div>
            </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</section>
<div class="modal" id="makeSignature">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{'lang_signature' | language }}</h4>
                <button type="button" class="close" id="modelclose" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="signatureform" (ngSubmit)="submitSignature();">
                    <div class="form-group">
                        <label class="mb-10">{{'lang_how_to_send_verification' | language }} : <span class="red">*</span></label>
                        <div class="custom-control custom-radio mb-10">
                            <input type="radio" class="custom-control-input zindex9999" value="email" id="vm1" formControlName="verification_method" />
                            <label class="custom-control-label">{{'lang_email' | language }}
                                <b>({{ds.secureemail(viewdata.user_email)}}) </b>
                            </label>
                        </div>
                        <div class="custom-control custom-radio mb-10">
                            <input type="radio" class="custom-control-input zindex9999" value="mobile" id="vm2" formControlName="verification_method" />
                            <label class="custom-control-label">{{'lang_mobile' | language }}
                                <b><bdi>({{ds.securemobile(viewdata.user_phone)}})</bdi> </b>
                            </label>
                        </div>
                        <div class="custom-control custom-radio mb-10">
                            <input type="radio" class="custom-control-input zindex9999" value="whatsapp" id="vm3" formControlName="verification_method" />
                            <label class="custom-control-label">{{'lang_whatsapp' | language }}
                                <b><bdi>({{ds.securemobile(viewdata.user_phone)}})</bdi> </b>
                            </label>
                        </div>
                        <mat-error *ngIf="submitted && signatureform.controls['verification_method'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                    </div>
                    <hr>
                    <div class="form-group">
                        <label for="signature">{{'lang_signature' | language }} : <span class="red">*</span></label>
                        <div class="sig-container" #padElement>
                            <canvas #sPad width="{{width}}" height="{{height}}"></canvas>
                        </div>
                        <mat-error *ngIf="submitted">{{'lang_field_required' | language }}</mat-error>
                        <br>
                        <div fxLayout="row">
                            <div class="col-md-3 col-xs-12 mb-10 pad0">
                                <button type="button" class="albutton" (click)="signaturePad.clear()">{{'lang_clear' | language }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="mark">{{'lang_mark' | language }} : <span class="red">*</span></label>
                        <div class="sig-container" #padElement>
                            <canvas #mPad width="{{width}}" height="{{height}}"></canvas>
                        </div>
                        <mat-error *ngIf="submitted">{{'lang_field_required' | language }}</mat-error>
                        <br>
                        <div fxLayout="row">
                            <div class="col-md-3 col-xs-12 mb-10 pad0">
                                <button type="button" class="albutton" (click)="markPad.clear()">{{'lang_clear' | language }}</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="sendstatus" id="verification_send">
                        <div class="form-group">
                            <label for="verification_code">{{'lang_enter_verification_code' | language }}: <span class="red">*</span></label>
                            <input type="text" required minlength="4" maxlength="4" class="form-control almnabrformcontrol" (keypress)="ds.numberOnly($event)" id="verification_code" formControlName="verification_code">
                            <mat-error *ngIf="submitted && signatureform.controls['verification_code'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                            <mat-error *ngIf="signatureform.controls['verification_code'].errors?.minlength"> {{'lang_minimum' | language }} 4</mat-error>
                            <mat-error *ngIf="signatureform.controls['verification_code'].errors?.maxlength"> {{'lang_maximum' | language }} 4 </mat-error>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                                <button type="button" [disabled]="sendcodestatus" (click)="sendVerificationCode();" class="albutton">{{codemsg}}</button>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                <app-alert id="default-alert"></app-alert>
                            </div>
                            <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                                <button type="submit" [disabled]="!signatureform.valid" class="albutton">{{'lang_submit' | language }}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button"  #myModalClose  class="btn btn-secondary" id="modelclose" data-dismiss="modal">{{'lang_close' | language }}</button>
            </div>
        </div>
    </div>
</div>