import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
	selector: "app-site-handle",
	templateUrl: "./site-handle.component.html",
	styleUrls: ["./site-handle.component.scss"],
})
export class SiteHandleComponent implements OnInit {
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, this.config);
	}
	modalRef: BsModalRef;
	@Input() supervision_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() project_data;
	@Input() filelevels;
	@Input() makeAction;
	siteSearchKey = "";
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	users: any = [];
	positionItems: any = [];
	positions: any = [];
	sitepageno = 1;
	sitepagesize = "10";
	siteListData: any = [];
	sitenodata: string = this.lang.transform("lang_loading");
	form: FormGroup;
	updateform: FormGroup;
	site_attachments: FormArray;
	submitted = false;
	viewdata: any = [];
	directors: any = [];
	managers: any = [];
	public config = environment.modelconfig;
	isFile: boolean = false;
	filename = this.lang.transform("lang_select_files");
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.load_supervision_sites();
		this.buildform();
	}
	emptyform() {
		this.site_attachments.controls = [];
		this.isFile = false;
		this.filename = this.lang.transform("lang_select_files");
		this.add_new_items();
		this.alert.clear();
		this.form.reset();
	}
	buildform() {
		this.form = new FormGroup({
			projects_profile_id: new FormControl(
				this.supervision_data.projects_profile_id
			),
			projects_supervision_id: new FormControl(
				this.supervision_data.projects_supervision_id
			),
			site_attachments: this.fb.array([]),
		});
		this.site_attachments = this.form.get("site_attachments") as FormArray;
		this.updateform = new FormGroup({
			projects_profile_id: new FormControl("", [Validators.required]),
			projects_supervision_id: new FormControl("", [Validators.required]),
			mobilization_id: new FormControl("", [Validators.required]),
			sitehandle_description: new FormControl("", [Validators.required]),
			sitehandle_attachments: new FormControl(""),
			level_keys: new FormControl("", [Validators.required]),
		});
	}
	public items_group() {
		return this.form.get("site_attachments") as FormArray;
	}
	get itemsgroup() {
		return this.form.get("site_attachments") as FormArray;
	}
	public add_new_items() {
		if (this.site_attachments.length < 6) {
			this.site_attachments.push(this.item_records());
		}
	}
	public removeItems(index) {
		if (this.site_attachments.length > 1) {
			this.site_attachments.removeAt(index);
		}
	}
	public item_records(): FormGroup {
		return this.fb.group({
			sitehandle_description: new FormControl("", [Validators.required]),
			sitehandle_attachments: new FormControl("", [Validators.required]),
			level_keys: new FormControl("", [Validators.required]),
		});
	}
	public UploadFile(event, index) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			$("#uploadText" + index).text(event.target.files[0].name);
			this.site_attachments.controls[index]
				.get("sitehandle_attachments")
				.setValue(event.target.files[0], { emitEvent: true });
		} else {
			this.site_attachments.controls[index]
				.get("sitehandle_attachments")
				.setValue(null);
			$("#uploadText" + index).text(this.lang.transform("lang_select_files"));
		}
	}
	public submit() {
		this.spinner.show();
		let param = new FormData();
		param.set("projects_profile_id", this.supervision_data.projects_profile_id);
		param.set(
			"projects_supervision_id",
			this.supervision_data.projects_supervision_id
		);
		this.itemsgroup.value.forEach((v, k) => {
			param.set(
				"sitehandle_description[" + k + "]",
				v.sitehandle_description == null ? "" : v.sitehandle_description
			);
			param.set(
				"sitehandle_attachments[" + k + "]",
				v.sitehandle_attachments == null ? "" : v.sitehandle_attachments
			);
			param.set("level_keys[" + k + "]", v.level_keys ? v.level_keys : "");
		});
		this.ds.postActionByUrl(param, "N6yApdMjBJ").subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.alert.success(res.msg);
					this.form.reset();
					this.load_supervision_sites();
					setTimeout(() => {
						this.modalRef.hide();
					}, 1000);
				} else {
					this.alert.error(res.error);
				}
			},
			(error) => {
				this.spinner.hide();
				this.alert.error(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}
	emptyEditForm(list) {
		this.isFile = false;
		this.filename = this.lang.transform("lang_select_files");
		this.alert.clear();
		this.updateform.reset();
		this.updateform.patchValue({
			projects_profile_id: list.projects_profile_id,
			projects_supervision_id: list.projects_supervision_id,
			mobilization_id: list.mobilization_id,
			sitehandle_description: list.mobilization_description,
			level_keys: list.level_keys
				? list.level_keys.split(",")
				: list.level_keys,
		});
	}
	public UpdateFile(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			this.isFile = true;
			this.filename = event.target.files[0].name;
			this.updateform
				.get("sitehandle_attachments")
				.setValue(event.target.files[0], { emitEvent: true });
		} else {
			this.isFile = false;
			this.updateform.get("sitehandle_attachments").setValue(null);
			this.filename = this.lang.transform("lang_select_files");
		}
	}
	public removeFile() {
		this.isFile = false;
		this.updateform.get("sitehandle_attachments").setValue(null);
		this.filename = this.lang.transform("lang_select_files");
	}
	public update() {
		this.spinner.show();
		this.ds
			.postActionByUrl(
				this.ds.json2formdata(this.updateform.value),
				"tx4tLWOwJM/" + this.updateform.get("mobilization_id").value
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.alert.success(res.msg);
						this.updateform.reset();
						this.load_supervision_sites();
						setTimeout(() => {
							this.modalRef.hide();
						}, 1000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	public search_supervision_sites() {
		this.sitepageno = 1;
		this.sitepagesize = "10";
		this.load_supervision_sites();
	}
	public load_supervision_sites_page(page) {
		this.spinner.show();
		this.sitepageno = page;
		this.sitepagesize = this.sitepagesize;
		this.load_supervision_sites();
	}
	public load_supervision_sites_pagesize(size) {
		this.spinner.show();
		this.sitepageno = 1;
		this.sitepagesize = size;
		this.load_supervision_sites();
	}
	load_supervision_sites() {
		let formdata = new FormData();
		formdata.append("search_key", this.siteSearchKey);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formdata),
				"OUFRiL1C5L/" +
					this.project_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id +
					"/" +
					this.sitepageno +
					"/" +
					this.sitepagesize
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.siteListData = res;
					} else {
						this.siteListData = [];
						this.sitenodata = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.siteListData = [];
					this.sitenodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}
	checkAllItemsSite(event) {
		if (event.target.checked) {
			$(".site_handle .eachItemSite").prop("checked", true);
			$(".site_handle .makeback").addClass("bground");
			$(".site_handle .deleteIconDiv").show();
		} else {
			$(".site_handle .eachItemSite").prop("checked", false);
			$(".site_handle .makeback").removeClass("bground");
			$(".site_handle .deleteIconDiv").hide();
		}
	}
	checkEachItemSite(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".site_handle .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".site_handle .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".site_handle .eachItemSite:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".site_handle .eachItemSite").each(function () {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".site_handle .deleteIconDiv").show();
		} else {
			$(".site_handle .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".site_handle #allItemsSite").prop("checked", true);
		} else {
			$(".site_handle #allItemsSite").prop("checked", false);
		}
	}
	deleteBulkDataSite() {
		const checkedtotal = [];
		$(".site_handle .eachItemSite:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: "400px",
				data: {
					title: this.lang.transform("lang_confirmation"),
					message: this.lang.transform("lang_are_you_sure"),
				},
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show();
					this.ds
						.deleteActionByUrl(
							checkedtotal,
							"oXpSItFCYu/" +
								this.supervision_data.projects_profile_id +
								"/" +
								this.supervision_data.projects_supervision_id
						)
						.subscribe(
							(data) => {
								this.spinner.hide();
								if (data.status) {
									this.load_supervision_sites();
									$(".site_handle #allItemsSite").prop("checked", false);
									$(".site_handle .deleteIconDiv").hide();
									this.ds.dialogf("", data.msg);
								} else {
									this.ds.dialogf("", data.error);
								}
							},
							(error) => {
								this.ds.dialogf(
									"",
									error && error.error && error.error.error
										? error.error.error
										: this.lang.transform("lang_internal_server_error")
								);
								this.spinner.hide();
							}
						);
				}
			});
		} else {
			this.ds.dialogf();
		}
	}
}
