<mat-accordion class="mt-2">
	<mat-expansion-panel
		class="mb-2"
		style="box-shadow: none !important; border: 1px solid #dee2e6"
	>
		<mat-expansion-panel-header>Contract Payment</mat-expansion-panel-header>
		<div class="material-panel-body">
			<div class="mbrl15 martb0">
				<div class="row">
					<div class="col-12" *ngIf="tableContent">
						<div class="table-container" id="table-container">
							<table class="main-table">
								<thead>
									<tr>
										<th
											style="
												color: #035a99;
												width: 27px;
												border-left: 1px solid rgba(148, 148, 148, 0.747);
											"
										></th>
										<th style="color: #035a99; left: 23px"></th>
										<th style="color: #035a99; left: 23px"></th>
										<th
											style="color: #035a99; left: 23px"
											*ngFor="let title of tableContent?.titles"
										>
											{{ title?.titlename }}
										</th>
										<th style="color: #035a99; left: 23px"></th>
										<th style="color: #035a99; left: 23px"></th>
									</tr>
									<tr>
										<th
											style="
												color: #035a99;
												width: 27px;
												border-left: 1px solid rgba(148, 148, 148, 0.747);
											"
										>
											#
										</th>
										<th style="color: #035a99; left: 23px">Villa No</th>
										<th style="color: #035a99; left: 23px">Amount</th>
										<th
											style="color: #035a99; left: 23px"
											*ngFor="let title of tableContent?.titles"
										>
											{{ title?.subtitlename }}
										</th>
										<th style="color: #035a99; left: 23px">Paid Cost</th>
										<th style="color: #035a99; left: 23px">Pending Cost</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="
											let item of tableContent?.units;
											let unit_index = index
										"
									>
										<td class="sticky" style="padding: 5px">
											{{ item?.unit_id }}
										</td>
										<td class="sticky" style="left: 23px; padding: 5px">
											{{ item?.unit_label }}
										</td>
										<td class="sticky" style="left: 23px; padding: 5px">
											{{ item?.total_amount }}
										</td>
										<td
											class="sticky"
											style="left: 23px; padding: 5px"
											*ngFor="let title of item?.notes"
										>
											{{ title?.total_amount }}
										</td>
										<td class="sticky" style="left: 23px; padding: 5px">
											{{ item?.paid_total_amount }}
										</td>
										<td class="sticky" style="left: 23px; padding: 5px">
											{{ item?.pending_total_amount }}
										</td>
									</tr>
									<tr>
										<td
											class="sticky"
											style="padding: 5px; font-weight: 600"
											colspan="2"
										>
											Total
										</td>
										<td class="sticky" style="padding: 5px; font-weight: 600">
											{{ tableContent?.total_amount }}
										</td>
										<td
											class="sticky"
											style="padding: 5px; font-weight: 600"
											*ngFor="let title of tableContent?.titles"
										>
											{{ title?.total_weight }}
										</td>
										<td class="sticky" style="padding: 5px; font-weight: 600">
											2338089.3
										</td>
										<td class="sticky" style="padding: 5px; font-weight: 600">
											2338089.3
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-expansion-panel>
</mat-accordion>
