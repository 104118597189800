import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-ticket-filters",
	templateUrl: "./ticket-filters.component.html",
	styleUrls: ["./ticket-filters.component.scss"],
})
export class TicketFiltersComponent implements OnInit {
	status_list = [];
	user_list = [];
	priority_list = [];
	signature_list = [];
	module_list = [];
	type_list = [];
	lang_key = localStorage.getItem("lang_key")
		? localStorage.getItem("lang_key")
		: "en";
	admin: any = JSON.parse(this.ls.getItem("is_admin"));
	ticketID = "";
	link_issue = "";
	type_filter = null;
	user_filter = [];
	filteradmin_list = [
		{
			id: "1",
			name: this.lang.transform("lang_users"),
		},
		{
			id: "2",
			name: this.lang.transform("lang_admin"),
		},
	];
	status_filter = null;
	priority_filter = null;
	signatue_filter = null;
	module_filter = null;
	filteradmin = "1";
	range = this.fb.group({
		start: null,
		end: null,
	});
	formFromLocalStorage: any;
	lastValues;
	constructor(
		public dialogRef: MatDialogRef<TicketFiltersComponent>,
		public ls: LocalStorage,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private lang: LanguagePipe,
		public ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService
	) {
		this.lastValues = data?.lastValues;
		this.ticketID = data?.lastValues?.ticketID;
		this.link_issue = data?.lastValues?.link_issue;
		this.type_filter = data?.lastValues?.type_filter;
		this.user_filter = data?.lastValues?.user_filter;
		this.status_filter = data?.lastValues?.status_filter;
		this.priority_filter = data?.lastValues?.priority_filter;
		this.signatue_filter = data?.lastValues?.signatue_filter;
		this.module_filter = data?.lastValues?.module_filter;
		this.filteradmin = data?.lastValues?.filteradmin;
		if (data?.lastValues?.start_date) {
			this.range.get("start").setValue(new Date(data?.lastValues?.start_date));
		}
		if (data?.lastValues?.end_date) {
			this.range.get("end").setValue(new Date(data?.lastValues?.end_date));
		}
	}

	ngOnInit(): void {
		this.getFormLists();
	}
	public search_users(key) {
		this.user_list = [];
		if (key && key.term) {
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.user_list = [...res.list];
					}
				});
		}
	}
	getFormLists() {
		this.ds.post("tasks/get_add", {}).subscribe((res) => {
			if (res?.status) {
				this.priority_list = res?.data?.important;
				this.type_list = res?.data?.ticket_type;
				this.signature_list = res?.data?.sig_id;
				this.status_list = res?.data?.ticket_status;
				this.module_list = res?.data?.modules;
			}
		});
	}
	sendFilterForm() {
		this.dialogRef.close({
			ticketID: this.ticketID,
			link_issue: this.link_issue,
			type_filter: this.type_filter,
			user_filter: this.user_filter,
			status_filter: this.status_filter,
			priority_filter: this.priority_filter,
			signatue_filter: this.signatue_filter,
			module_filter: this.module_filter,
			filteradmin: this.filteradmin,
			start_date: this.range.get("start").value,
			end_date: this.range.get("end").value,
		});
	}
	resetFilterForm() {
		this.dialogRef.close({
			ticketID: "",
			link_issue: null,
			type_filter: null,
			user_filter: null,
			status_filter: null,
			priority_filter: null,
			signatue_filter: null,
			filteradmin: "1",
			module_filter: null,
		});
	}
	isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
}
