<form [formGroup]="form" (ngSubmit)="completeStep2()">
	<!-- *ngIf="allData?.step_status?.edit?.Contractor_Team_Approval" -->

	<div class="card">
		<div class="card-header">View SI Request</div>
		<div class="mbrl15">
			<div class="row p-3">
				<div class="col-sm-6 mb-2">
					<label for="si_name">Site Instraction Name</label>
					<ng-select
						[items]="si_name_array"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						[searchFn]="ds.customSearchFn"
						appendTo="body"
						formControlName="si_name"
						placeholder="{{ 'lang_si_name' | language }}"
					>
					</ng-select>
				</div>

				<div class="col-sm-6 mb-2">
					<label for="new_si_name"
						>Add New Site Instraction
						<i
							class="fa fa-info-circle"
							matTooltip="optional field, type your new SI."
						></i
					></label>

					<textarea
						name="new_si_name"
						id="new_issue"
						cols="30"
						rows="5"
						style="max-height: 300px; min-height: 150px"
						formControlName="new_si_name"
						placeholder="{{ 'lang_new_si_name' | language }}"
						autocomplete="off"
						class="form-control almnabrformcontrol"
					>
					</textarea>
				</div>
				<div class="col-sm-6">
					<label for="action_taken">The Action Taken</label>
					<textarea
						name="action_taken"
						id="action_taken"
						cols="30"
						rows="5"
						style="max-height: 300px; min-height: 150px"
						formControlName="action_taken"
						placeholder="{{ 'lang_action_taken' | language }}"
						autocomplete="off"
						class="form-control almnabrformcontrol"
					>
					</textarea>
				</div>
				<div class="col-sm-4">
					<label for="attachments">Upload Attachments</label>
					<div>
						<app-dynamic-files-control
							(getAttachments)="getAttachments($event)"
							[rawIndex]="0"
							[actionMode]="'ADD'"
						></app-dynamic-files-control>
					</div>
				</div>
			</div>
			<div class="row mb-10">
				<div class="col-sm-12">
					<div class="form-group" *ngIf="sendCodeStatus">
						<label for="verification_code"
							>{{ "lang_enter_verification_code" | language }}:
						</label>
						<input
							type="text"
							required
							class="form-control almnabrformcontrol"
							formControlName="transactions_persons_action_code"
						/>
						<button
							type="button"
							(click)="confirmVerification()"
							*ngIf="sendCodeStatus"
							class="albutton mnabrbutton mat-accent"
							mat-button
						>
							{{ "lang_do_verification" | language }}
						</button>
					</div>
					<div class="col-sm-12" *ngIf="sendCodeStatus">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<div>
						<label class="opacity0"><span class="red">*</span></label>
						<button
							class="albutton mnabrbutton mat-accent"
							type="button"
							(click)="openModal()"
							mat-button
						>
							{{ "lang_send_code" | language }}
						</button>
					</div>
				</div>
				<div class="col-sm-4 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<div>
						<label class="opacity0"><span class="red">*</span></label>
						<input
							type="submit"
							value="{{ 'lang_submit' | language }}"
							class="albutton mnabrbutton mat-accent"
							mat-button
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
