<div class="form-wrapper">
  <div class="card border-0">
    <div class="card-body">
      <div class="row w-100">
        <mat-horizontal-stepper
          [linear]="isLinear"
          [selectedIndex]="selectedIndex"
          (selectionChange)="selectionChange($event)"
          class="w-100 cuatomMat"
          [ngClass]="{
            ltr: lang_key != 'ar',
            rtl: lang_key == 'ar',
            fisrtClass: selectedIndex >= 0,
            secondClass: selectedIndex >= 1,
            thirdClass: selectedIndex >= 2,
            fourthClass: selectedIndex >= 3,
            fifthClass: selectedIndex >= 4,
            sixClass: selectedIndex >= 5
          }"
          labelPosition="bottom"
          #stepper
        >
          <mat-step [stepControl]="firstFormGroup">
            <div class="text-center">
              <ng-template matStepLabel>
                <div class="d-block inactive textColor">
                  <div class="custom"></div>
                  {{ "lang_select" | language }}
                  {{ "lang_date" | language }}
                </div>
              </ng-template>

              <app-step-one
                [firstFormGroup]="firstFormGroup"
                *ngIf="requestInfo?.steps?.CONFIGURATION && selectedIndex == 0"
                [t_id]="form_request_id"
                [stepper]="stepper"
                (formValueOutput)="fillFirstFormValue($event)"
              ></app-step-one>

              <div class="not-allow" *ngIf="!requestInfo?.steps?.CONFIGURATION">
                <div class="permission-img">
                  <img
                    src="assets/images/no-permission.gif"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </div>
                <p style="text-align: center; font-weight: 600">
                  {{ "lang_step_permission" | language }}
                </p>
              </div>

              <!-- <app-step-five [t_id]="t_id" 
            [stepper]="stepper" 
              [refresh]="refreshSubject.asObservable()"></app-step-five> -->
            </div>
          </mat-step>
          <mat-step [stepControl]="stepTwoValid">
            <ng-template matStepLabel>
              <div class="d-block inactive textColor">
                <div class="custom"></div>
                {{ "lang_select" | language }}
                {{ "lang_company_employees" | language }}
              </div>
            </ng-template>
            <app-step-two
              *ngIf="requestInfo?.steps?.CONFIGURATION && selectedIndex == 1"
              [usersList]="usersResponse"
              [requestInfo]="requestInfo"
              [t_id]="form_request_id"
              [projects]="projects"
              [branches]="branches"
              [stepper]="stepper"
              (UserListSetter)="fillUserListFromStepTwo($event)"
              (sendComponentUpdate)="emitEventToChild()"
            ></app-step-two>

            <div class="not-allow" *ngIf="!requestInfo?.steps?.CONFIGURATION">
              <div class="permission-img">
                <img
                  src="assets/images/no-permission.gif"
                  alt=""
                  width="100%"
                  height="100%"
                />
              </div>
              <p style="text-align: center; font-weight: 600">
                {{ "lang_step_permission" | language }}
              </p>
            </div>
          </mat-step>
          <mat-step [stepControl]="stepThreeValid">
            <ng-template matStepLabel>
              <div class="d-block inactive textColor">
                <div class="custom"></div>
                {{ "lang_details" | language }}
              </div>
            </ng-template>

            <app-step-three
              [t_id]="form_request_id"
              *ngIf="requestInfo?.steps?.CONFIGURATION && selectedIndex == 2"
              [requestInfo]="requestInfo"
              [usersList]="usersResponse"
              [type]="type"
              [stepper]="stepper"
              (UserListSetter)="fillUserListFromStepThree($event)"
              (resfreshView)="view_request()"
              (resfreshUsers)="get_salary_form_employees()"
              [refresh]="refreshSubject.asObservable()"
            ></app-step-three>

            <div class="not-allow" *ngIf="!requestInfo?.steps?.CONFIGURATION">
              <div class="permission-img">
                <img
                  src="assets/images/no-permission.gif"
                  alt=""
                  width="100%"
                  height="100%"
                />
              </div>
              <p style="text-align: center; font-weight: 600">
                {{ "lang_step_permission" | language }}
              </p>
            </div>
          </mat-step>
          <mat-step [stepControl]="stepFourValid">
            <div [formGroup]="searchForm">
              <ng-template matStepLabel>
                <div class="d-block inactive textColor">
                  <div class="custom"></div>
                  {{ "lang_reviewers" | language }}
                </div>
              </ng-template>
              <app-step-four
                [t_id]="form_request_id"
                *ngIf="requestInfo?.steps?.CONFIGURATION && selectedIndex == 3"
                [requestInfo]="requestInfo"
                (reviewersOutput)="fillReviewers($event)"
                (resfreshView)="view_request()"
                [stepper]="stepper"
                [reviewers]="reviewers"
              ></app-step-four>
              <div class="not-allow" *ngIf="!requestInfo?.steps?.CONFIGURATION">
                <div class="permission-img">
                  <img
                    src="assets/images/no-permission.gif"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </div>
                <p style="text-align: center; font-weight: 600">
                  {{ "lang_step_permission" | language }}
                </p>
              </div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</div>
