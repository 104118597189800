<div class="mbrl15">
	<div class="row mb-3">
		<div class="col-12 col-sm-3">
			<button
				class="albutton mnabrbutton"
				mat-button
				(click)="onCreateUpdateBank(createTemp, 'create')"
			>
				<i class="fa fa-plus mnabricon mr-1 ml-1" style="color: #fff"></i>
				<span>{{ "lang_add" | language }}</span>
			</button>
		</div>
		<div class="col-12 col-sm-3">
			<button
				class="albutton mnabrbutton"
				mat-button
				(click)="onCreateUpdateBank(createTemp, 'update')"
			>
				<i class="fa fa-pencil mnabricon mr-1 ml-1" style="color: #fff"></i>
				<span>{{ "lang_edit" | language }}</span>
			</button>
		</div>
		<div class="col-0 col-sm-2"></div>
		<div class="col-12 col-sm-4 mb-2">
			<div class="form-group">
				<ng-select
					[items]="shortCodeList"
					[multiple]="false"
					bindLabel="bank_name_en"
					bindValue="bank_short_code"
					clearAllText="Clear"
					[searchFn]="ds.customSearchFn"
					appendTo="body"
					[(ngModel)]="filter_bank_short_code"
					placeholder="{{ 'lang_human_resources_bank_shortcode' | language }}"
					(change)="getAllPayments()"
				>
				</ng-select>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_payment_completion_ratio" | language }}</td>
							<td>{{ "lang_payment_due_ratio" | language }}</td>
							<td>{{ "lang_human_resources_bank_shortcode" | language }}</td>
						</tr>
					</thead>
					<tbody class="AppendList">
						<tr
							*ngFor="
								let list of paymentList;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ list.payment_completion_ratio }}</td>
							<td>{{ list.payment_due_ratio }}</td>
							<td>{{ list.bank_short_code }}</td>
						</tr>
						<tr *ngIf="!paymentList?.length">
							<td colspan="4" class="text-center">
								{{ "lang_no_data" | language }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<ng-template #createTemp>
	<div class="modal-content">
		<!-- Modal Header -->
		<div class="modal-header">
			<h4 class="modal-title" *ngIf="!isEdit">{{ "lang_add" | language }}</h4>
			<h4 class="modal-title" *ngIf="isEdit">{{ "lang_edit" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<!-- Modal body -->
		<div class="modal-body">
			<form [formGroup]="form">
				<div class="row">
					<div class="col-12 col-sm-6 mb-2">
						<div class="form-group">
							<label for="title_en">{{
								"lang_human_resources_bank_shortcode" | language
							}}</label>
							<ng-select
								[items]="shortCodeList"
								[multiple]="false"
								bindLabel="bank_name_en"
								bindValue="bank_short_code"
								clearAllText="Clear"
								[searchFn]="ds.customSearchFn"
								appendTo="body"
								formControlName="bank_short_code"
								placeholder="{{
									'lang_human_resources_bank_shortcode' | language
								}}"
							>
							</ng-select>
						</div>
					</div>
					<div class="col-12 mb-2">
						<div class="table-responsive customResponsive quotationitemtable">
							<table
								border="1"
								class="table CustomTable"
								width="100%"
								formArrayName="bank_payment_settings"
							>
								<thead>
									<tr>
										<td width="2%" style="padding: 5px; word-break: break-all">
											#
										</td>
										<td width="2%" style="padding: 5px; word-break: break-all">
											<mat-icon
												class="mnabricon"
												matTooltip="{{ 'lang_add' | language }}"
												(click)="addBps()"
												>add_box</mat-icon
											>
										</td>
										<td width="48%" style="padding: 5px; word-break: break-all">
											{{ "lang_payment_completion_ratio" | language }}
										</td>
										<td width="48%" style="padding: 5px; word-break: break-all">
											{{ "lang_payment_due_ratio" | language }}
										</td>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="
											let item of bpsFormGroup.controls;
											let i = index;
											let odd = odd;
											let even = even
										"
										[formGroupName]="i"
									>
										<td
											class="verticalalignmid"
											style="padding: 5px; word-break: break-all"
										>
											{{ i + 1 }}
										</td>
										<td class="verticalalignmid">
											<mat-icon
												(click)="removeBps(i)"
												class="mnabricon red"
												matTooltip="{{ 'lang_delete' | language }}"
												>delete_forever</mat-icon
											>
										</td>
										<td
											style="
												padding: 5px;
												word-break: break-all;
												position: relative;
											"
										>
											<input
												type="text"
												formControlName="payment_completion_ratio"
												placeholder="{{
													'lang_payment_completion_ratio' | language
												}}"
												autocomplete="off"
												class="form-control almnabrformcontrol shadow-none"
											/>
										</td>
										<td
											class="verticalalignmid"
											style="
												padding: 5px;
												word-break: break-all;
												position: relative;
											"
										>
											<input
												type="text"
												formControlName="payment_due_ratio"
												placeholder="{{ 'lang_payment_due_ratio' | language }}"
												autocomplete="off"
												class="form-control almnabrformcontrol shadow-none"
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</form>
		</div>
		<!-- Modal footer -->
		<div class="modal-footer justify-content-between">
			<button
				type="button"
				class="btn btn-secondary"
				data-dismiss="modal"
				(click)="modalRef.hide()"
				style="width: 40%"
			>
				{{ "lang_close" | language }}
			</button>
			<button
				type="button"
				class="albutton mnabrbutton"
				style="width: 40% !important"
				(click)="onSubmit()"
				mat-button
			>
				{{ "lang_submit" | language }}
			</button>
		</div>
	</div>
</ng-template>
