<div class="card">
    <div class="width100">
        <div class="card-header"> {{'lang_human_resources_education_details' | language }} </div>
        <div class="card-body pad0" [formGroup]="regForm">
            <div class="mbrl15" formGroupName="educationDetails">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="primary_education_level">{{'lang_human_resources_graduation' | language }} : <span class="red">*</span></label>
                            <select formControlName="primary_education_level" type="text" matTooltip="{{'lang_human_resources_graduation' | language }}" class="form-control almnabrformcontrol">
                                <option value="">{{'lang_choose_options' | language }}</option>
                                <option value="SL">{{'lang_human_resources_belowsslc' | language }}</option>
                                <option value="SS">{{'lang_human_resources_sslc' | language }}</option>
                                <option value="HS">{{'lang_human_resources_hsc' | language }}</option>
                                <option value="DP">{{'lang_human_resources_diploma' | language }}</option>
                                <option value="UG">{{'lang_human_resources_bachelor' | language }}</option>
                                <option value="PG">{{'lang_human_resources_pg' | language }}</option>
                                <option value="DC">{{'lang_human_resources_doc' | language }}</option>
                            </select>
                            <mat-error *ngIf="regForm.get('educationDetails')['controls']['primary_education_level'].touched && regForm.get('educationDetails')['controls']['primary_education_level'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="experiance">{{'lang_human_resources_rating_degree' | language }} : <span class="red">*</span></label>
                            
                            <ng-select
                                [items]="metaData"
                                [multiple]="false"
                                bindLabel="label"
                                bindValue="value"
                                appendTo="body"
                                clearAllText="Clear"
                                formControlName="rating_degree"
                                placeholder="{{ 'lang_human_resources_rating_degree' | language }}"
                            ></ng-select>
                            <mat-error *ngIf="regForm.get('educationDetails')['controls']['rating_degree'].touched && regForm.get('educationDetails')['controls']['rating_degree'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="primary_graduation_year">{{'lang_human_resources_graduation_year' | language }} : <span class="red">*</span></label>
                            <input formControlName="primary_graduation_year" minlength="4" maxlength="4" type="text" placeholder="{{'lang_human_resources_graduation_year' | language }}" class="form-control almnabrformcontrol" (keypress)="ds.numberOnly($event)" />
                            <mat-error *ngIf="regForm.get('educationDetails')['controls']['primary_graduation_year'].touched && regForm.get('educationDetails')['controls']['primary_graduation_year'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                            <mat-error *ngIf="regForm.get('educationDetails')['controls']['primary_graduation_year'].errors?.minlength">{{'lang_minimum' | language }} 4 </mat-error>
                            <mat-error *ngIf="regForm.get('educationDetails')['controls']['primary_graduation_year'].errors?.maxlength"> {{'lang_maximum' | language }} 4 </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="action !=='EDIT'">
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="edu_file_name">{{'lang_human_resources_filename' | language }} : <span class="red">*</span> </label>
                            <input formControlName="edu_file_name" type="text" placeholder="{{'lang_human_resources_filename' | language }}" class="form-control almnabrformcontrol" />
                            <mat-error *ngIf="regForm.get('educationDetails')['controls']['edu_file_name'].touched && regForm.get('educationDetails')['controls']['edu_file_name'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <div class="form-group">
                            <div *ngIf="!isFile"> <i aria-hidden="true" class="red">*</i> </div>
                            <div *ngIf="isFile"> <i aria-hidden="true" (click)="removeFile()" class="fa faicon fa-close red"></i> </div>
                            <div class="IDDIV">
                                <input name="education_file" type="file" onclick="this.value = null" (change)="uploadEDUFile($event)" class="nghide albutton width100" accept=".pdf">
                                <label id="uploadIDButton" class="albutton">{{idfilename}} </label>
                                <mat-error *ngIf="regForm.get('educationDetails')['controls']['education_file'].touched && regForm.get('educationDetails')['controls']['education_file'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="membership_number">{{'lang_membership_number' | language }} : </label>
                            <input formControlName="membership_number" id="membership_number" type="text" placeholder="{{'lang_membership_number' | language }}" class="form-control almnabrformcontrol membership_number" />
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="membership_expiry_date_english">{{'lang_membership_expiry_date_english' | language }} : </label>
                            <div class="input-group input-group-sm">
                                <input [matDatepicker]="membership_expiry_date_englishU" formControlName="membership_expiry_date_english" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly (dateChange)="ds.setENTOARDate($event, regForm.get('educationDetails')['controls']['membership_expiry_date_arabic'])">
                                <span class="input-group-btn">
                                    <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="membership_expiry_date_englishU"></mat-datepicker-toggle>
                                    <mat-datepicker #membership_expiry_date_englishU></mat-datepicker>
                                </span>
                                <span class="input-group-btn">
                                    <button (click)="regForm.get('educationDetails')['controls']['membership_expiry_date_english'].setValue('');regForm.get('educationDetails')['controls']['membership_expiry_date_arabic'].setValue(null);" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="membership_expiry_date_arabic">{{'lang_membership_expiry_date_arabic' | language }} : </label>
                            <input formControlName="membership_expiry_date_arabic" ngbDatepicker [readonly]="true" (click)="insa.toggle()" #insa="ngbDatepicker" (ngModelChange)="ds.setARTOENDate($event, regForm.get('educationDetails')['controls']['membership_expiry_date_english'])" type="text" placeholder="YYYY/MM/DD" class="form-control almnabrformcontrol" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer padb0">
                <div class="row">
                    <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
                        <button type="button" class="albutton" matStepperPrevious>{{'lang_previous' | language }}</button>
                    </div>
                    <div class="col-md-8 col-sm-6 col-xs-12 mb-10"></div>
                    <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
                        <button type="button" class="albutton" matStepperNext (click)="step4Submitted()">{{'lang_next' | language }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>