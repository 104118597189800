import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import {
	FormGroup,
	FormArray,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import moment from "moment";

// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
declare var $: any;
@Component({
	selector: "app-formhrj1-add",
	templateUrl: "./formhrj1-add.component.html",
	styleUrls: ["./formhrj1-add.component.scss"],
})
export class Formhrj1AddComponent implements OnInit {
	// public Editor = ClassicEditor;
	@Output() joiningEvent = new EventEmitter();
	@Input() employee_data;
	@Input() form_request_id;
	@Input() form_key;
	@Input() id;
	@Input() branch_id;
	form: FormGroup;
	lang_key = localStorage.getItem("lang_key") || "en";
	submitted = false;
	joining_type: any = [];
	users: any = [];
	markstatus = false;
	reviewstatus = false;
	showmarkdelete = true;
	showreviewdelete = true;
	direct_anager = [];
	constructor(
		public ds: DataService,
		public datepipe: DatePipe,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public changeDetectorRef: ChangeDetectorRef,
		private route: ActivatedRoute
	) {}
	ngOnInit(): void {
		this.spinner.show();
		moment.locale("en");
		this.load_required_datas();
		this.form = new FormGroup({
			subject: new FormControl(this.lang.transform("lang_joining_form"), [
				Validators.required,
			]),
			transaction_from: new FormControl(
				{ value: this.employee_data.branch_name, disabled: true },
				[Validators.required]
			),
			employee_number: new FormControl(this.employee_data.employee_number, [
				Validators.required,
			]),
			joining_type: new FormControl("", [Validators.required]),
			joining_start_date_english: new FormControl("", [Validators.required]),
			joining_start_date_arabic: new FormControl(null, [Validators.required]),
			joining_description: new FormControl(null, [Validators.required]),
			direct_manager: new FormControl(
				{ value: null, disabled: this.direct_anager.length > 0 },
				[Validators.required]
			),
		});

		this.route.queryParams.subscribe((params) => {
			if (params.action == "create-joining") {
				this.form.get("joining_type").setValue("3");
			}
		});
	}

	public load_required_datas() {
		let param = new FormData();
		param.append("lang_key", this.lang_key);

		let url = ["form/FORM_HRJ1/joining_type", "form/FORM_HRJ1/appusers"];
		this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(
			(res) => {
				this.spinner.hide();
				if (res[0].status) {
					this.joining_type = res[0].records;
				}
				this.users = res[1].records;
			},
			(error) => {
				this.spinner.hide();
			}
		);
	}
	//Other Terms & Conditions End
	public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append("search", key.term);
		param.append("lang_key", this.lang_key);
		param.append("user_type_id", "1");
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.users = res.list;
				}
			});
	}

	public submit() {
		this.alert.clear();
		this.submitted = true;
		if (this.form.valid) {
			this.submitted = false;
		}
		this.spinner.show();
		let param = new FormData();
		param.set("transaction_request_id", this.form_request_id);
		param.set("subject", this.form.get("subject").value || "");
		param.set("employee_number", this.form.get("employee_number").value || "");
		param.set(
			"transaction_from",
			this.form.get("transaction_from").value || ""
		);
		param.set("joining_type", this.form.get("joining_type").value || "");
		param.set(
			"joining_start_date_english",
			moment(this.form.get("joining_start_date_english").value).format(
				"YYYY/MM/DD"
			) || ""
		);
		param.set(
			"joining_start_date_arabic",
			this.form.get("joining_start_date_arabic").value || ""
		);
		param.set(
			"joining_description",
			this.form.get("joining_description").value || ""
		);
		param.set("direct_manager", this.form.get("direct_manager").value || "");

		this.ds.postActionByUrl(param, "form/FORM_HRJ1/cr/0").subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.alert.success(this.lang.transform("lang_success"));
					this.form.reset();
					setTimeout(() => {
						this.router.navigate(["transactions/allforms/"]);
					}, 1000);
				} else {
					this.alert.error(res.error);
				}
			},
			(error) => {
				this.spinner.hide();
				this.alert.error(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}
}
