<h3 mat-dialog-title>{{ "lang_create_violation" | language }}</h3>

<mat-dialog-content class="mat-typography">
		<form [formGroup]="form">
			<div class="row mb-10">
				<div class="col-sm-12 col-xs-12 mb-10">
					<label class="mb-10">
						{{ "lang_transaction_approval" | language }} !?.
						<span class="red"> * </span> </label
					><br />

					<mat-radio-group
					aria-label="Select an option"
					formControlName="approved"
						 (change)="checkStatus()"
				>
					<mat-radio-button value="Reject">{{
						"lang_reject" | language
					}}</mat-radio-button>
					<mat-radio-button value="Return">{{
						"lang_return" | language
					}}</mat-radio-button>
					<mat-radio-button value="Approve">{{
						"lang_approve" | language
					}}</mat-radio-button>
				 
				</mat-radio-group>
					<mat-error class="d-block" *ngIf="
					submitForm &&
					form.controls['approved']?.errors?.required
				">{{ "lang_field_required" | language }}</mat-error>
				</div>
				<div class="col-sm-12 col-xs-12 mb-10">
					<div
					
					>
						<label
							>{{ "lang_notes" | language }} :
							<span   class="red" *ngIf="rejectedValidators.length > 0">*</span>	</label
						>
						<textarea
							class="form-control almnabrformcontrol"
							formControlName="notes"
						></textarea>
						<mat-error class="d-block" *ngIf="
						submitForm &&
						form.controls['notes']?.errors?.required
					">{{ "lang_field_required" | language }}</mat-error>
					</div>
				</div>
			</div>
		</form>
	</mat-dialog-content>
 
	<mat-dialog-actions>
		<div class="d-flex justify-content-between" style="width: 100%">
			
			<div style="width: 100px">
				<button type="button" class="albutton" (click)="close()">
					{{ "lang_cancel" | language }}
				</button>
			</div>
	
			<div style="width: 100px">
				<button
					mat-button
					class="albutton mnabrbutton mat-accent"
					(click)="complete()"
				>
					{{ "lang_submit" | language }}
				</button>
			</div>
		</div>
	</mat-dialog-actions>
	