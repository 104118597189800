<form [formGroup]="form" style="min-height: 100vh; overflow: hidden">
	<div class="row" style="min-height: 100vh">
		<div class="col-md-6 d-none d-md-block side-bg">
			<div class="overlay">
				<div class="qr-code" *ngIf="showBarcode">
					<img
						src="{{ qrcode_img }}"
						alt=""
						[ngClass]="{
							'loader-animation': qrcode_img == 'assets/images/logo.svg'
						}"
					/>
					<p *ngIf="qrcode_img != 'assets/images/logo.svg'">
						{{ "lang_login_barcode" | language }}
					</p>
				</div>
			</div>
		</div>
		<div class="col-md-6" style="background-color: #fff">
			<div class="login-header">
				<div class="languages">
					<mat-form-field appearance="standard" style="width: 100%">
						<mat-select [(value)]="lang_key">
							<mat-option
								[value]="lang.lang_key"
								*ngFor="let lang of languages"
								(click)="switchlanguage(lang)"
								>{{ lang.lang_name | language }}</mat-option
							>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="logo">
					<img src="assets/images/login-logo.png" alt="" />
				</div>
			</div>
			<div class="login-body">
				<h3 class="mb-4">{{ "lang_welcome" | language }}</h3>
				<div class="login-inpts" *ngIf="currentIndex == 0">
					<input
						type="text"
						class="mnabr-login-input"
						formControlName="username"
						required
						placeholder="{{ 'lang_username' | language }}"
						[attr.style]="
							lang_key == 'ar'
								? 'border-right: 10px solid #0e3768d9;'
								: 'border-left: 10px solid #0e3768d9;'
						"
					/>
					<input
						type="password"
						class="mnabr-login-input"
						formControlName="password"
						required
						placeholder="{{ 'lang_password' | language }}"
						[attr.style]="
							lang_key == 'ar'
								? 'border-right: 10px solid #0e3768d9;margin-top:20px'
								: 'border-left: 10px solid #0e3768d9;margin-top:20px'
						"
					/>
					<div
						class="d-flex justify-content-end mt-3"
						*ngIf="
							form.get('username').valid &&
							form.get('password').valid &&
							need_otp
						"
					>
						<button class="goto-sendcode" type="button" (click)="moveToNext()">
							<span>{{ "lang_send_code" | language }}</span>
							<i
								class="pi"
								[ngClass]="{
									'pi-arrow-right': lang_key == 'en',
									'pi-arrow-left': lang_key == 'ar'
								}"
							></i>
						</button>
					</div>
				</div>
				<div class="choose-send-code" *ngIf="currentIndex == 1">
					<label
						id="example-radio-group-label"
						style="width: 100%; text-align: start"
						>{{ "lang_send_code" | language }}:</label
					>
					<mat-radio-group
						aria-labelledby="example-radio-group-label"
						formControlName="sendcodetype"
					>
						<mat-radio-button value="email" style="display: block">{{
							email
						}}</mat-radio-button>
						<mat-radio-button value="mobile" style="display: block">{{
							mobile
						}}</mat-radio-button>
						<mat-radio-button value="whatsapp" style="display: block">{{
							whatsapp
						}}</mat-radio-button>
					</mat-radio-group>
					<div class="d-flex justify-content-between mt-3">
						<button class="goto-sendcode" type="button" (click)="moveToPrev()">
							<i
								class="pi"
								[ngClass]="{
									'pi-arrow-right': lang_key == 'ar',
									'pi-arrow-left': lang_key == 'en'
								}"
							></i>
							<span>{{ "lang_login" | language }}</span>
						</button>
						<button
							class="goto-sendcode"
							type="button"
							(click)="sendCode()"
							*ngIf="form.get('sendcodetype').valid"
						>
							<span>{{ "lang_send_code" | language }}</span>
							<i
								class="pi"
								[ngClass]="{
									'pi-arrow-right': lang_key == 'en',
									'pi-arrow-left': lang_key == 'ar'
								}"
								(click)="sendCode()"
							></i>
						</button>
					</div>
				</div>
				<div class="confirm-code" *ngIf="currentIndex == 2">
					<input
						type="text"
						class="mnabr-login-input"
						formControlName="verification_code"
						required
						placeholder="{{ 'lang_verification_code' | language }}"
						[attr.style]="
							lang_key == 'ar'
								? 'border-right: 10px solid #0e3768d9;'
								: 'border-left: 10px solid #0e3768d9;'
						"
					/>
					<div class="d-flex justify-content-between mt-3">
						<button class="goto-sendcode" type="button" (click)="moveToPrev()">
							<i
								class="pi"
								[ngClass]="{
									'pi-arrow-right': lang_key == 'ar',
									'pi-arrow-left': lang_key == 'en'
								}"
							></i>
							<span>{{ "lang_send_code" | language }}</span>
						</button>
					</div>
				</div>
				<div class="remember-me pt-2">
					<div>
						<mat-checkbox [color]="'primary'">
							{{ "lang_remember_me" | language }}
						</mat-checkbox>
					</div>
					<div>
						<a routerLink="/forget" class="forgot_pass">{{
							"lang_forgot_password" | language
						}}</a>
					</div>
				</div>
				<input
					type="submit"
					class="mat-accent submit-btn"
					value="{{ 'lang_login' | language }}"
					(click)="checkSendCode()"
					*ngIf="!need_otp"
				/>
				<input
					type="submit"
					class="mat-accent submit-btn"
					value="{{ 'lang_login' | language }}"
					(click)="login()"
					*ngIf="need_otp"
				/>
				<p style="color: #fff; margin-top: 20px">
					<a
						routerLink="/signup"
						style="text-decoration: underline; font-weight: 600"
						class="join-btn"
						>{{ "lang_join_almnabr" | language }}</a
					>
				</p>
				<div class="remember-me pt-2">
					<div>
						<a routerLink="/status" class="app-status">{{
							"lang_check_app_status" | language
						}}</a>
					</div>
					<div>
						<a routerLink="/reset" class="reset-login">{{
							"lang_reset_login" | language
						}}</a>
					</div>
				</div>
			</div>
			<div class="qr-code-mobile d-flex">
				<div class="qr-code d-md-none" *ngIf="showBarcode">
					<img
						src="{{ qrcode_img }}"
						alt=""
						[ngClass]="{
							'loader-animation': qrcode_img == 'assets/images/logo.svg'
						}"
					/>
					<p *ngIf="qrcode_img != 'assets/images/logo.svg'">
						{{ "lang_login_barcode" | language }}
					</p>
				</div>
			</div>
			<div class="google-ios-links">
				<div class="google-play-link">
					<a
						href="https://play.google.com/store/apps/details?id=com.almnabr&hl=en&gl=US"
						target="_blank"
					>
						<img src="assets/images/get-it-ongoogleplay.png" alt="" />
					</a>
				</div>
				<div class="play-store-link">
					<a
						href="https://apps.apple.com/app/almnabr/id1621889347?src=AppAgg.com"
						target="_blank"
					>
						<img src="assets/images/play-store-link.svg" alt="" />
					</a>
				</div>
			</div>
		</div>
	</div>
</form>
