<br />
<div class="themesection panel-expansion">
	<div class="container-fluid">
		<div class="row" *ngIf="datastatus">
			<div class="col-md-10 col-sm-12 col-xs-12 mar0auto">
				<p class="red text-center">
					{{ "lang_latest_updates" | language }} {{ countdown }}
				</p>
				<!--  <div class="signupviewcardfooter padb0 bottomfooter">
                    <div class="row text-center">
                        <div class="col-md-1">
                            <i (click)="logoutme();" class="fa fa-power-off fa-icon pad-10 red" matTooltip="{{'lang_logout' | language }}" aria-hidden="true"></i>
                        </div>
                        <div class="col-md-2">
                            <a routerLink="/editdata/{{id}}/{{code}}"> {{'lang_edit' | language }}</a>
                        </div>
                        <div class="col-md-3">
                            <a routerLink="/auth">{{'lang_signin' | language }}</a>
                        </div>
                        <div class="col-md-3">
                            <a routerLink="/forget">{{'lang_forgot_password' | language }}</a>
                        </div>
                        <div class="col-md-3">
                            <a routerLink="/reset">{{'lang_reset_login' | language }}</a>
                        </div>
                    </div>
                </div> -->

				<!-- <ng-container *ngIf="jop_offers.length > 0">
          <div
            class="card appattachments backgroundImage"
            *ngFor="let item of jop_offers; let i = index"
          >
            <div class="card-header">
              {{ "lang_jop_offer" | language }} ({{ i + 1 }})
            </div>
            <div class="card-body p-0">
              <div class="mbrl15 martb0">
                <div class="row justify-content-start">
                  <div class="col-md-12 mt-1 mb-4">
                    <h4 class="headerr">
                      {{ "lang_details" | language }}
                    </h4>
                  </div>
                  <div class="col-12 col-sm-4 mb-5 row">
                    <div class="col-md-12">
                      <h5 class="card-title title_card mt-1 d-head">
                        {{
                          "lang_human_resources_contract_start_date" | language
                        }}
                      </h5>

                      <h6 class="d-detail">
                        {{ item.contract_start_date_english }}
                      </h6>
                    </div>
                    <div class="col-md-12">
                      <h5 class="card-title title_card mt-1 d-head">
                        {{
                          "lang_human_resources_contract_end_date" | language
                        }}
                      </h5>

                      <h6 class="d-detail">
                        {{ item.contract_end_date_english }}
                      </h6>
                    </div>
                    <div class="col-md-12">
                      <h5 class="card-title title_card mt-1 d-head">
                        {{ "lang_human_resources_contract_period" | language }}
                      </h5>

                      <h6>{{ item.contract_period }} ({{ "lang_year" }})</h6>
                    </div>
                    <div class="col-md-12">
                      <h5 class="card-title title_card mt-1 d-head">
                        {{
                          "lang_human_resources_vacation_paid_days" | language
                        }}
                      </h5>

                      <h6>
                        {{ item.vacation_paid_days }}
                      </h6>
                    </div>

                    <div class="col-md-12">
                      <h5 class="card-title title_card mt-1 d-head">
                        {{ "lang_basic_salary" | language }}
                      </h5>

                      <h6>
                        {{ item.basic_salary }}
                      </h6>
                    </div>

                    <div class="col-md-12">
                      <h5 class="card-title title_card mt-1 d-head">
                        {{ "lang_net_amount" | language }}
                      </h5>

                      <h6>
                        {{ item.net_amount }}
                      </h6>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4 mb-5 row">
                    <div class="col-md-12">
                      <h5 class="card-title title_card mt-1 d-head">
                        {{
                          "lang_human_resources_contract_start_date" | language
                        }}
                        {{ "lang_arabic" | language }}
                      </h5>

                      <h6 class="d-detail">
                        {{ item.contract_start_date_arabic }}
                      </h6>
                    </div>

                    <div class="col-md-12">
                      <h5 class="card-title title_card mt-1 d-head">
                        {{
                          "lang_human_resources_contract_end_date" | language
                        }}
                        {{ "lang_arabic" | language }}
                      </h5>

                      <h6 class="d-detail">
                        {{ item.contract_end_date_arabic }}
                      </h6>
                    </div>
                    <div class="col-md-12">
                      <h5 class="card-title title_card mt-1 d-head">
                        {{
                          "lang_human_resources_working_days_per_week"
                            | language
                        }}
                      </h5>

                      <h6>
                        {{ item.working_days_per_week }}
                      </h6>
                    </div>
                    <div class="col-md-12">
                      <h5 class="card-title title_card mt-1 d-head">
                        {{ "lang_human_resources_working_hours" | language }}
                      </h5>

                      <h6>
                        {{ item.working_hours }} ({{ item.working_hours_type }})
                      </h6>
                    </div>

                    <div class="col-md-12">
                      <h5 class="card-title title_card mt-1 d-head">
                        {{ "lang_home_allowance" | language }}
                      </h5>

                      <h6>
                        {{ item.home_allowance }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="col-12 col-md-4 mb-2">
                    <button
                      class="albutton mnabrbutton"
                      mat-raised-button
                      [routerLink]="['/human_resources/employees']"
                    >
                      <i class="fa fa-users"></i>
                      {{ "lang_download" | language }}
                    </button>
                  </div>
                  <div class="col-12 col-md-4 mb-2">
                    <button
                      class="albutton mnabrbutton"
                      mat-raised-button
                      (click)="onViewFile()"
                    >
                      <i class="fa fa-users"></i>
                      {{ "lang_preview" | language }}
                    </button>
                  </div>
                  <div class="col-12 col-md-4 mb-2">
                    <button
                      class="albutton mnabrbutton"
                      mat-raised-button
                      (click)="openModel(item.transaction_request_id)"
                    >
                      <i class="fa fa-users"></i>
                      {{ "lang_action" | language }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="jop_offers.length == 0">
          <div class="card appattachments">
            <div class="card-header">{{ "lang_jop_offer" | language }}</div>
            <div class="card-body p-0">
              <no-data style="margin: auto" [Opened]="true"></no-data>
            </div>
          </div>
        </ng-container> -->

				<div class="signupviewcardfooter padb0 footerbottom">
					<div class="row text-center">
						<div class="col-md-1">
							<i
								(click)="logoutme()"
								class="fa fa-power-off fa-icon pad-10 red"
								matTooltip="{{ 'lang_logout' | language }}"
								aria-hidden="true"
							></i>
						</div>
						<div class="col-md-2">
							<a routerLink="/editdata/{{ id }}/{{ code }}">
								{{ "lang_edit" | language }}</a
							>
						</div>
						<div class="col-md-3">
							<a routerLink="/auth">{{ "lang_signin" | language }}</a>
						</div>
						<div class="col-md-3">
							<a routerLink="/forget">{{
								"lang_forgot_password" | language
							}}</a>
						</div>
						<div class="col-md-3">
							<a routerLink="/reset">{{ "lang_reset_login" | language }}</a>
						</div>
					</div>
				</div>
				<mat-accordion class="stylepanels mb-0">
					<mat-expansion-panel [expanded]="false" class="mpanel my_profile">
						<mat-expansion-panel-header class="mpanel-header">{{
							"lang_myprofile" | language
						}}</mat-expansion-panel-header>
						<div class="material-panel-body">
							<div class="mbrl15 martb0">
								<div class="row">
									<div class="col-xs-12 col-12 pad0">
										<mat-horizontal-stepper #stepper class="pad0">
											<mat-step>
												<ng-template matStepLabel>
													<div class="step">
														<div class="st">
															<i class="icon-5x fa fa-user fa-icon"></i>
														</div>
													</div>
													<div>
														{{ "lang_human_resources_id_details" | language }}
													</div>
												</ng-template>
												<mat-accordion class="mb-10">
													<mat-expansion-panel
														class="mpanel"
														[expanded]="true"
														[disabled]="true"
													>
														<i
															*ngIf="attachments.id0001"
															(click)="
																ds.preview_pdf_file(
																	attachments.id0001,
																	'id0001'
																)
															"
															matTooltip="{{
																'lang_recent_attachment' | language
															}} : {{ attachments.id0001_d }}"
															class="fa fa-paperclip mnabricon linkicon"
														></i>
														<mat-expansion-panel-header class="mpanel-header">{{
															"lang_human_resources_id_details" | language
														}}</mat-expansion-panel-header>
														<div class="material-panel-body">
															<div class="mbrl15 martb0">
																<div class="row">
																	<div class="col-md-9 col-sm-12 col-xs-12">
																		<div class="row">
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																			>
																				<div class="mb-10">
																					{{
																						"lang_human_resources_id_number"
																							| language
																					}}
																				</div>
																				<div class="mb-10">
																					<b>{{
																						viewdata.employee_id_number
																					}}</b>
																				</div>
																			</div>
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																			>
																				<div class="mb-10">
																					{{
																						"lang_human_resources_nameen"
																							| language
																					}}
																				</div>
																				<div class="mb-10">
																					<b
																						>{{
																							viewdata.settings_name_english
																						}}
																						{{ viewdata.firstname_english }}
																						{{ viewdata.secondname_english }}
																						{{ viewdata.thirdname_english }}
																						{{ viewdata.lastname_english }}</b
																					>
																				</div>
																			</div>
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																			>
																				<div class="mb-10">
																					{{
																						"lang_human_resources_namear"
																							| language
																					}}
																				</div>
																				<div class="mb-10">
																					<b
																						>{{ viewdata.settings_name_arabic }}
																						{{ viewdata.firstname_arabic }}
																						{{ viewdata.secondname_arabic }}
																						{{ viewdata.thirdname_arabic }}
																						{{ viewdata.lastname_arabic }}</b
																					>
																				</div>
																			</div>
																		</div>
																		<div class="row">
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																			>
																				<div class="mb-10">
																					{{ "lang_expiry_date" | language }}
																				</div>
																				<div class="mb-10">
																					<b
																						>{{
																							viewdata.iqama_expiry_date_english
																						}}
																						-
																						{{
																							viewdata.iqama_expiry_date_arabic
																						}}</b
																					>
																				</div>
																			</div>
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																			>
																				<div class="mb-10">
																					{{
																						"lang_human_resources_copy_number"
																							| language
																					}}
																				</div>
																				<div class="mb-10">
																					<b>{{ viewdata.copy_number }}</b>
																				</div>
																			</div>
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																			>
																				<div class="mb-10">
																					{{
																						"lang_human_resources_birth_date"
																							| language
																					}}
																				</div>
																				<div class="mb-10">
																					<b
																						>{{ viewdata.birth_date_english }} -
																						{{ viewdata.birth_date_arabic }}</b
																					>
																				</div>
																			</div>
																		</div>
																		<div class="row">
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																			>
																				<div class="mb-10">
																					{{
																						"lang_human_resources_gender"
																							| language
																					}}
																				</div>
																				<div class="mb-10">
																					<b *ngIf="viewdata.gender == 'M'">{{
																						"lang_human_resources_male"
																							| language
																					}}</b>
																				</div>
																				<div class="mb-10">
																					<b *ngIf="viewdata.gender == 'F'">{{
																						"lang_human_resources_female"
																							| language
																					}}</b>
																				</div>
																			</div>
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																			>
																				<div class="mb-10">
																					{{
																						"lang_human_resources_nationality"
																							| language
																					}}
																				</div>
																				<div class="mb-10">
																					<img
																						*ngIf="viewdata.nationality"
																						src="./assets/images/nations/{{
																							viewdata.nationality.toLowerCase()
																						}}.svg"
																						class="img-responsive cntryimage"
																					/>
																					<b> {{ viewdata.countryname }}</b>
																				</div>
																			</div>
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																			>
																				<div class="mb-10">
																					{{
																						"lang_human_resources_job_title_iqama"
																							| language
																					}}
																				</div>
																				<div class="mb-10">
																					<b>{{ viewdata.job_title_iqama }}</b>
																				</div>
																			</div>
																		</div>
																		<div class="row">
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																			>
																				<div class="mb-10">
																					{{ "lang_status" | language }}
																				</div>
																				<div class="mb-10">
																					<b
																						*ngIf="
																							viewdata.employee_status == '0'
																						"
																						class="red"
																						>{{ "lang_inactive" | language }}</b
																					>
																					<b
																						*ngIf="
																							viewdata.employee_status == '1'
																						"
																						class="green"
																						>{{ "lang_active" | language }}</b
																					>
																					<b
																						*ngIf="
																							viewdata.employee_status == '2'
																						"
																						class="orange"
																						>{{ "lang_pending" | language }}</b
																					>
																					<b
																						*ngIf="
																							viewdata.employee_status == '3'
																						"
																						class="red"
																						>{{ "lang_rejected" | language }}</b
																					>
																					<b
																						*ngIf="
																							viewdata.employee_status == '4'
																						"
																						class="blue"
																						>{{ "lang_waiting" | language }}</b
																					>
																				</div>
																			</div>
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																				*ngIf="
																					viewdata.interview_date_en &&
																					viewdata.interview_date_ar
																				"
																			>
																				<div class="mb-10">
																					{{ "lang_interview_date" | language }}
																				</div>
																				<div class="mb-10 red flash pad0">
																					<b
																						>{{ viewdata.interview_date_en }} -
																						{{ viewdata.interview_date_ar }}</b
																					>
																				</div>
																			</div>
																			<div
																				class="col-md-4 col-sm-4 col-xs-12 mb-10"
																			>
																				<div class="mb-10">
																					{{ "lang_created_date" | language }}
																				</div>
																				<div class="mb-10">
																					<b>{{ viewdata.created_datetime }}</b>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div
																		class="col-md-3 col-sm-12 col-xs-12 text-center"
																	>
																		<p>
																			{{ "lang_myprofile" | language }} :
																			{{ profile_percentage }}%
																		</p>
																		<ngb-progressbar
																			class="mb-10"
																			[value]="profile_percentage"
																			[striped]="true"
																			[animated]="true"
																		>
																			<i
																				>{{ profile_percentage }}%</i
																			></ngb-progressbar
																		>
																		<b class="green">
																			{{
																				ds.calculateage(
																					viewdata.birth_date_english
																				)
																			}}</b
																		>
																		<div class="row">
																			<div
																				class="col-md-12 col-sm-12 col-xs-12 mb-10"
																			>
																				<div class="avatar-upload">
																					<img
																						*ngIf="
																							(!viewdata.profile_image &&
																								viewdata.gender == 'M' &&
																								!view_profile_image) ||
																							(viewdata.profile_image &&
																								!view_profile_image &&
																								viewdata.gender == 'M')
																						"
																						src="./assets/images/male.png"
																						class="avatar-preview redborder"
																						id="imagePreview"
																					/>
																					<img
																						*ngIf="
																							(!viewdata.profile_image &&
																								viewdata.gender == 'F' &&
																								!view_profile_image) ||
																							(viewdata.profile_image &&
																								!view_profile_image &&
																								viewdata.gender == 'F')
																						"
																						src="./assets/images/female.png"
																						class="avatar-preview redborder"
																						id="imagePreview"
																					/>
																					<img
																						*ngIf="
																							viewdata.profile_image &&
																							view_profile_image
																						"
																						src="{{ view_profile_image }}"
																						class="avatar-preview"
																						id="imagePreview"
																					/>
																				</div>
																			</div>
																		</div>
																		<div
																			class="row"
																			*ngIf="
																				ds.birthday(viewdata.birth_date_english)
																			"
																		>
																			<div
																				class="col-md-12 col-sm-12 col-xs-12 mb-10"
																			>
																				<b class="green">{{
																					"lang_birthday_wishes" | language
																				}}</b>
																				<div class="avatar-upload">
																					<img
																						src="./assets/images/birthday.png"
																						class="avatar-preview"
																						id="imagePreview"
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</mat-expansion-panel>
												</mat-accordion>
											</mat-step>
											<mat-step>
												<ng-template matStepLabel>
													<div class="step">
														<div class="st">
															<i class="icon-5x fa fa-suitcase fa-icon"></i>
														</div>
													</div>
													<div>
														{{ "lang_human_resources_job_details" | language }}
													</div>
												</ng-template>
												<mat-accordion class="mb-10">
													<mat-expansion-panel
														class="mpanel"
														[expanded]="true"
														[disabled]="true"
													>
														<i
															*ngIf="attachments.rs0001"
															(click)="
																ds.preview_pdf_file(
																	attachments.rs0001,
																	'rs0001'
																)
															"
															matTooltip="{{
																'lang_recent_attachment' | language
															}} : {{ attachments.rs0001_d }}"
															class="fa fa-paperclip mnabricon linkicon"
														></i>
														<mat-expansion-panel-header class="mpanel-header">{{
															"lang_human_resources_job_details" | language
														}}</mat-expansion-panel-header>
														<div class="material-panel-body">
															<div class="mbrl15 martb0">
																<div class="row">
																	<div
																		class="col-md-4 col-sm-4 col-xs-12 mb-10"
																	>
																		<div class="mb-10">
																			{{
																				"lang_human_resources_job_title_iqama"
																					| language
																			}}
																		</div>
																		<div class="mb-10">
																			<b>{{ viewdata.jobname }}</b>
																		</div>
																	</div>
																	<div
																		class="col-md-8 col-sm-8 col-xs-12 mb-10"
																	>
																		<div class="mb-10">
																			{{ "lang_job_descriptions" | language }}
																		</div>
																		<div class="mb-10">
																			<b>{{ viewdata.job_descriptions }}</b>
																		</div>
																	</div>
																</div>
																<div class="row" *ngIf="licences.length != '0'">
																	<div class="col-md-12">
																		<div
																			class="table-responsive dropdownyes customResponsive"
																		>
																			<table
																				border="1"
																				class="table CustomTable"
																			>
																				<thead>
																					<tr>
																						<td>#</td>
																						<td>
																							{{
																								"lang_licence_name" | language
																							}}
																						</td>
																						<td>
																							{{
																								"lang_licence_number" | language
																							}}
																						</td>
																						<td>
																							{{ "lang_issue_date" | language }}
																						</td>
																						<td>
																							{{
																								"lang_expiry_date" | language
																							}}
																						</td>
																						<td>
																							{{ "lang_ondate" | language }}
																						</td>
																					</tr>
																				</thead>
																				<tbody
																					*ngIf="licences"
																					class="AppendList"
																				>
																					<tr
																						*ngFor="
																							let li of licences;
																							let i = index;
																							let odd = odd;
																							let even = even
																						"
																						[ngClass]="{ odd: odd, even: even }"
																					>
																						<td>{{ i + 1 }}</td>
																						<td>{{ li.licence_name }}</td>
																						<td>{{ li.licence_number }}</td>
																						<td>
																							{{
																								li.licence_issue_date_english
																							}}
																							-
																							{{ li.licence_issue_date_arabic }}
																						</td>
																						<td>
																							{{
																								li.licence_expiry_date_english
																							}}
																							-
																							{{
																								li.licence_expiry_date_arabic
																							}}
																						</td>
																						<td>
																							{{ li.licence_createddatetime }}
																						</td>
																					</tr>
																				</tbody>
																				<tbody
																					*ngIf="licences.length == '0'"
																					class="AppendList"
																				>
																					<tr class="odd">
																						<td colspan="6" align="center">
																							{{ cerrormessagef }}
																						</td>
																					</tr>
																				</tbody>
																			</table>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</mat-expansion-panel>
												</mat-accordion>
											</mat-step>
											<mat-step>
												<ng-template matStepLabel>
													<div class="step">
														<div class="st">
															<i class="icon-5x fa fa-address-book fa-icon"></i>
														</div>
													</div>
													<div>
														{{
															"lang_human_resources_contact_details" | language
														}}
													</div>
												</ng-template>
												<mat-accordion class="mb-10">
													<mat-expansion-panel
														class="mpanel"
														[expanded]="true"
														[disabled]="true"
													>
														<i
															*ngIf="attachments.cn0001"
															(click)="
																ds.preview_pdf_file(
																	attachments.cn0001,
																	'cn0001'
																)
															"
															matTooltip="{{
																'lang_recent_attachment' | language
															}} : {{ attachments.cn0001_d }}"
															class="fa fa-paperclip mnabricon linkicon"
														></i>
														<mat-expansion-panel-header class="mpanel-header">{{
															"lang_human_resources_contact_details" | language
														}}</mat-expansion-panel-header>
														<div class="material-panel-body">
															<div class="mbrl15 martb0">
																<div class="row">
																	<div
																		class="col-md-4 col-sm-4 col-xs-12 mb-10"
																	>
																		<div class="mb-10">
																			{{
																				"lang_human_resources_primary_mobile"
																					| language
																			}}
																		</div>
																		<div class="mb-10">
																			<b>{{ viewdata.primary_mobile }}</b>
																		</div>
																	</div>
																	<div
																		class="col-md-4 col-sm-4 col-xs-12 mb-10"
																	>
																		<div class="mb-10">
																			{{
																				"lang_human_resources_primary_email"
																					| language
																			}}
																		</div>
																		<div class="mb-10">
																			<b>{{ viewdata.primary_email }}</b>
																		</div>
																	</div>
																	<div
																		class="col-md-4 col-sm-4 col-xs-12 mb-10"
																	>
																		<div class="mb-10">
																			{{
																				"lang_human_resources_primary_address"
																					| language
																			}}
																		</div>
																		<div class="mb-10">
																			<b>{{ viewdata.primary_address }}</b>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</mat-expansion-panel>
												</mat-accordion>
											</mat-step>
											<mat-step>
												<ng-template matStepLabel>
													<div class="step">
														<div class="st">
															<i class="icon-5x fa fa-university fa-icon"></i>
														</div>
													</div>
													<div>
														{{ "lang_human_resources_bank_details" | language }}
													</div>
												</ng-template>
												<mat-accordion class="mb-10">
													<mat-expansion-panel
														class="mpanel"
														[expanded]="true"
														[disabled]="true"
													>
														<i
															*ngIf="attachments.bk0001"
															(click)="
																ds.preview_pdf_file(
																	attachments.bk0001,
																	'bk0001'
																)
															"
															matTooltip="{{
																'lang_recent_attachment' | language
															}} : {{ attachments.bk0001_d }}"
															class="fa fa-paperclip mnabricon linkicon"
														></i>
														<mat-expansion-panel-header class="mpanel-header">{{
															"lang_human_resources_bank_details" | language
														}}</mat-expansion-panel-header>
														<div class="material-panel-body">
															<div class="mbrl15 martb0">
																<div class="row">
																	<div
																		class="col-md-4 col-sm-4 col-xs-12 mb-10"
																	>
																		<div class="mb-10">
																			{{
																				"lang_human_resources_account_number"
																					| language
																			}}
																		</div>
																		<div class="mb-10">
																			<b>{{ viewdata.account_number }}</b>
																		</div>
																	</div>
																	<div
																		class="col-md-4 col-sm-4 col-xs-12 mb-10"
																	>
																		<div class="mb-10">
																			{{ "lang_bank" | language }}
																		</div>
																		<div class="mb-10">
																			<b>{{ viewdata.bankname }}</b>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</mat-expansion-panel>
												</mat-accordion>
											</mat-step>
											<mat-step>
												<ng-template matStepLabel>
													<div class="step">
														<div class="st">
															<i class="icon-5x fa fa-book fa-icon"></i>
														</div>
													</div>
													<div>
														{{
															"lang_human_resources_education_details"
																| language
														}}
													</div>
												</ng-template>
												<mat-accordion class="mb-10">
													<mat-expansion-panel
														class="mpanel"
														[expanded]="true"
														[disabled]="true"
													>
														<i
															*ngIf="attachments.en0001"
															(click)="
																ds.preview_pdf_file(
																	attachments.en0001,
																	'en0001'
																)
															"
															matTooltip="{{
																'lang_recent_attachment' | language
															}} : {{ attachments.en0001_d }}"
															class="fa fa-paperclip mnabricon linkicon"
														></i>
														<mat-expansion-panel-header class="mpanel-header">{{
															"lang_human_resources_education_details"
																| language
														}}</mat-expansion-panel-header>
														<div class="material-panel-body">
															<div class="mbrl15 martb0">
																<div class="row">
																	<div
																		class="col-md-3 col-sm-6 col-xs-12 mb-10"
																	>
																		<div class="mb-10">
																			{{
																				"lang_human_resources_graduation"
																					| language
																			}}
																		</div>
																		<div class="mb-10">
																			<b
																				*ngIf="
																					viewdata.primary_education_level ==
																					'SL'
																				"
																				>{{
																					"lang_human_resources_belowsslc"
																						| language
																				}}</b
																			>
																			<b
																				*ngIf="
																					viewdata.primary_education_level ==
																					'SS'
																				"
																				>{{
																					"lang_human_resources_sslc" | language
																				}}</b
																			>
																			<b
																				*ngIf="
																					viewdata.primary_education_level ==
																					'HS'
																				"
																				>{{
																					"lang_human_resources_hsc" | language
																				}}</b
																			>
																			<b
																				*ngIf="
																					viewdata.primary_education_level ==
																					'DP'
																				"
																				>{{
																					"lang_human_resources_diploma"
																						| language
																				}}</b
																			>
																			<b
																				*ngIf="
																					viewdata.primary_education_level ==
																					'UG'
																				"
																				>{{
																					"lang_human_resources_bachelor"
																						| language
																				}}</b
																			>
																			<b
																				*ngIf="
																					viewdata.primary_education_level ==
																					'PG'
																				"
																				>{{
																					"lang_human_resources_pg" | language
																				}}</b
																			>
																			<b
																				*ngIf="
																					viewdata.primary_education_level ==
																					'DC'
																				"
																				>{{
																					"lang_human_resources_doc" | language
																				}}</b
																			>
																		</div>
																	</div>
																	<div
																		class="col-md-3 col-sm-6 col-xs-12 mb-10"
																	>
																		<div class="mb-10">
																			{{
																				"lang_human_resources_graduation_year"
																					| language
																			}}
																		</div>
																		<div class="mb-10">
																			<b>{{
																				viewdata.primary_graduation_year
																			}}</b>
																		</div>
																	</div>
																	<div
																		class="col-md-3 col-sm-6 col-xs-12 mb-10"
																	>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.membership_number
																			}"
																		>
																			{{ "lang_membership_number" | language }}
																		</div>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.membership_number
																			}"
																		>
																			<b *ngIf="viewdata.membership_number">{{
																				viewdata.membership_number
																			}}</b>
																			<b *ngIf="!viewdata.membership_number"
																				>-</b
																			>
																		</div>
																	</div>
																	<div
																		class="col-md-3 col-sm-6 col-xs-12 mb-10"
																	>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.membership_expiry_date_english
																			}"
																		>
																			{{ "lang_expiry_date" | language }}
																		</div>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.membership_expiry_date_english
																			}"
																		>
																			<b
																				*ngIf="
																					viewdata.membership_expiry_date_english
																				"
																				>{{
																					viewdata.membership_expiry_date_english
																				}}
																				-
																				{{
																					viewdata.membership_expiry_date_arabic
																				}}</b
																			>
																			<b
																				*ngIf="
																					!viewdata.membership_expiry_date_english
																				"
																			>
																				-
																			</b>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</mat-expansion-panel>
												</mat-accordion>
											</mat-step>
											<mat-step>
												<ng-template matStepLabel>
													<div class="step">
														<div class="st">
															<i class="icon-5x fa fa-plane fa-icon"></i>
														</div>
													</div>
													<div>
														{{
															"lang_human_resources_passport_details" | language
														}}
													</div>
												</ng-template>
												<mat-accordion class="mb-10">
													<mat-expansion-panel
														class="mpanel"
														[expanded]="true"
														[disabled]="true"
													>
														<i
															*ngIf="attachments.ps0001"
															(click)="
																ds.preview_pdf_file(
																	attachments.ps0001,
																	'ps0001'
																)
															"
															matTooltip="{{
																'lang_recent_attachment' | language
															}} : {{ attachments.ps0001_d }}"
															class="fa fa-paperclip mnabricon linkicon"
														></i>
														<mat-expansion-panel-header class="mpanel-header">{{
															"lang_human_resources_passport_details" | language
														}}</mat-expansion-panel-header>
														<div class="material-panel-body">
															<div class="mbrl15 martb0">
																<div class="row">
																	<div
																		class="col-md-3 col-sm-3 col-xs-12 mb-10"
																	>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.passport_number
																			}"
																		>
																			{{
																				"lang_human_resources_passport_number"
																					| language
																			}}
																		</div>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.passport_number
																			}"
																		>
																			<b *ngIf="viewdata.passport_number">{{
																				viewdata.passport_number
																			}}</b>
																			<b *ngIf="!viewdata.passport_number">-</b>
																		</div>
																	</div>
																	<div
																		class="col-md-3 col-sm-3 col-xs-12 mb-10"
																	>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.passport_issue_date_english
																			}"
																		>
																			{{ "lang_issue_date" | language }}
																		</div>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.passport_issue_date_english
																			}"
																		>
																			<b
																				>{{
																					viewdata.passport_issue_date_english
																				}}
																				-
																				{{
																					viewdata.passport_issue_date_arabic
																				}}</b
																			>
																		</div>
																	</div>
																	<div
																		class="col-md-3 col-sm-3 col-xs-12 mb-10"
																	>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.passport_expiry_date_english
																			}"
																		>
																			{{ "lang_expiry_date" | language }}
																		</div>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.passport_expiry_date_english
																			}"
																		>
																			<b
																				>{{
																					viewdata.passport_expiry_date_english
																				}}
																				-
																				{{
																					viewdata.passport_expiry_date_arabic
																				}}</b
																			>
																		</div>
																	</div>
																	<div
																		class="col-md-3 col-sm-3 col-xs-12 mb-10"
																	>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.passport_issue_place
																			}"
																		>
																			{{
																				"lang_human_resources_passport_issue_place"
																					| language
																			}}
																		</div>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.passport_issue_place
																			}"
																		>
																			<b
																				*ngIf="viewdata.passport_issue_place"
																				>{{ viewdata.passport_issue_place }}</b
																			>
																			<b *ngIf="!viewdata.passport_issue_place"
																				>-</b
																			>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</mat-expansion-panel>
												</mat-accordion>
											</mat-step>
											<mat-step>
												<ng-template matStepLabel>
													<div class="step">
														<div class="st">
															<i class="icon-5x fa fa-hospital-o fa-icon"></i>
														</div>
													</div>
													<div>
														{{
															"lang_human_resources_insurance_details"
																| language
														}}
													</div>
												</ng-template>
												<mat-accordion class="mb-10">
													<mat-expansion-panel
														class="mpanel"
														[expanded]="true"
														[disabled]="true"
													>
														<mat-expansion-panel-header class="mpanel-header">{{
															"lang_human_resources_insurance_details"
																| language
														}}</mat-expansion-panel-header>
														<div class="material-panel-body">
															<div class="mbrl15 martb0">
																<div class="row">
																	<div
																		class="col-md-4 col-sm-4 col-xs-12 mb-10"
																	>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.insurance_number
																			}"
																		>
																			{{ "lang_insurance_number" | language }}
																		</div>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.insurance_number
																			}"
																		>
																			<b *ngIf="viewdata.insurance_number">{{
																				viewdata.insurance_number
																			}}</b>
																			<b *ngIf="!viewdata.insurance_number"
																				>-</b
																			>
																		</div>
																	</div>
																	<div
																		class="col-md-4 col-sm-4 col-xs-12 mb-10"
																	>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.insurance_date
																			}"
																		>
																			{{ "lang_insurance_date" | language }}
																		</div>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.insurance_date
																			}"
																		>
																			<b *ngIf="viewdata.insurance_date">{{
																				viewdata.insurance_date
																			}}</b>
																			<b *ngIf="!viewdata.insurance_date">-</b>
																		</div>
																	</div>
																	<div
																		class="col-md-4 col-sm-4 col-xs-12 mb-10"
																	>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.insurance_type_class
																			}"
																		>
																			{{
																				"lang_insurance_type_class" | language
																			}}
																		</div>
																		<div
																			class="mb-10"
																			[ngClass]="{
																				red: !viewdata.insurance_type_class
																			}"
																		>
																			<b
																				*ngIf="viewdata.insurance_type_class"
																				>{{ viewdata.insurance_type_class }}</b
																			>
																			<b *ngIf="!viewdata.insurance_type_class"
																				>-</b
																			>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</mat-expansion-panel>
												</mat-accordion>
											</mat-step>
											<mat-step>
												<ng-template matStepLabel>
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-sticky-note-o fa-icon"
															></i>
														</div>
													</div>
													<div>{{ "lang_notes" | language }}</div>
												</ng-template>
												<mat-accordion class="mb-10">
													<mat-expansion-panel
														class="mpanel"
														[expanded]="true"
														[disabled]="true"
													>
														<mat-expansion-panel-header class="mpanel-header">{{
															"lang_notes" | language
														}}</mat-expansion-panel-header>
														<div class="material-panel-body">
															<div class="mbrl15 martb0">
																<div class="row">
																	<div class="col-12 mb-10">
																		<input
																			type="text"
																			class="form-control almnabrformcontrol"
																			(keyup)="get_my_notes()"
																			[(ngModel)]="nsearchKey"
																			placeholder="{{
																				'lang_search' | language
																			}}"
																		/>
																	</div>
																	<div class="col-md-12">
																		<div
																			class="table-responsive dropdownyes customResponsive"
																		>
																			<table
																				border="1"
																				class="table CustomTable"
																			>
																				<thead>
																					<tr>
																						<td>#</td>
																						<td>
																							{{ "lang_notes" | language }}
																						</td>
																						<td>
																							{{
																								"lang_remainder_status"
																									| language
																							}}
																						</td>
																						<td>
																							{{
																								"lang_remainder_date" | language
																							}}
																						</td>
																						<td>
																							{{ "lang_ondate" | language }}
																						</td>
																					</tr>
																				</thead>
																				<tbody
																					*ngIf="notes.length != '0'"
																					class="AppendList"
																				>
																					<tr
																						*ngFor="
																							let note of notes;
																							let i = index;
																							let odd = odd;
																							let even = even
																						"
																						[ngClass]="{ odd: odd, even: even }"
																					>
																						<td>{{ i + 1 }}</td>
																						<td class="link">
																							<span class="breakall">{{
																								note.note_description
																							}}</span>
																						</td>
																						<td
																							*ngIf="
																								note.note_remainder_status ==
																								'1'
																							"
																						>
																							{{ "lang_yes" | language }}
																						</td>
																						<td
																							*ngIf="
																								note.note_remainder_status ==
																								'0'
																							"
																						>
																							{{ "lang_no" | language }}
																						</td>
																						<td
																							*ngIf="
																								note.note_remainder_status ==
																								'1'
																							"
																						>
																							{{ note.note_remainder_date }}
																						</td>
																						<td
																							*ngIf="
																								note.note_remainder_status ==
																								'0'
																							"
																						>
																							----
																						</td>
																						<td>
																							{{ note.note_created_date }}
																						</td>
																					</tr>
																				</tbody>
																				<tbody
																					*ngIf="notes.length == '0'"
																					class="AppendList"
																				>
																					<tr class="odd">
																						<td colspan="7" align="center">
																							{{ cerrormessagef }}
																						</td>
																					</tr>
																				</tbody>
																			</table>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</mat-expansion-panel>
												</mat-accordion>
											</mat-step>
											<mat-step>
												<ng-template matStepLabel>
													<div class="step">
														<div class="st">
															<i class="icon-5x fa fa-files-o fa-icon"></i>
														</div>
													</div>
													<div>{{ "lang_attachments" | language }}</div>
												</ng-template>
												<mat-accordion style="margin: 0">
													<mat-expansion-panel
														class="mpanel"
														[expanded]="true"
														[disabled]="true"
													>
														<mat-expansion-panel-header class="mpanel-header">{{
															"lang_attachments" | language
														}}</mat-expansion-panel-header>
														<div class="material-panel-body">
															<div class="mbrl15 martb0">
																<div class="row">
																	<div class="col-12 mb-10">
																		<input
																			type="text"
																			class="form-control almnabrformcontrol"
																			(keyup)="get_my_attach()"
																			[(ngModel)]="asearchKey"
																			placeholder="{{
																				'lang_search' | language
																			}}"
																		/>
																	</div>
																	<div class="col-md-12">
																		<div
																			class="table-responsive dropdownyes customResponsive"
																		>
																			<table
																				border="1"
																				class="table CustomTable"
																			>
																				<thead>
																					<tr>
																						<td>#</td>
																						<td>
																							{{ "lang_type" | language }}
																						</td>
																						<td>
																							{{ "lang_subject" | language }}
																						</td>
																						<td>
																							{{
																								"lang_file_extention" | language
																							}}
																						</td>
																						<td>
																							{{ "lang_ondate" | language }}
																						</td>
																						<td>
																							{{ "lang_action" | language }}
																						</td>
																					</tr>
																				</thead>
																				<tbody
																					*ngIf="attach.length != '0'"
																					class="AppendList"
																				>
																					<tr
																						*ngFor="
																							let file of attach;
																							let i = index;
																							let odd = odd;
																							let even = even
																						"
																						[ngClass]="{ odd: odd, even: even }"
																					>
																						<td>{{ i + 1 }}</td>
																						<td *ngIf="file.type_name">
																							{{ file.type_name }}
																						</td>
																						<td *ngIf="!file.type_name">
																							----
																						</td>
																						<td class="breakall">
																							{{ file.file_name }}
																						</td>
																						<td>{{ file.file_extension }}</td>
																						<td>{{ file.created_datetime }}</td>
																						<td>
																							<i
																								*ngIf="file.file_path"
																								(click)="
																									spinner.show();
																									ds.preview_pdf_file(
																										file.file_path,
																										file?.file_name
																									)
																								"
																								class="fa fa-paperclip fa-icon mnabricon s2icon"
																								matTooltip="{{
																									'lang_view' | language
																								}}"
																							></i>
																						</td>
																					</tr>
																				</tbody>
																				<tbody
																					*ngIf="attach.length == '0'"
																					class="AppendList"
																				>
																					<tr class="odd">
																						<td colspan="6" align="center">
																							{{ cerrormessagef }}
																						</td>
																					</tr>
																				</tbody>
																			</table>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</mat-expansion-panel>
												</mat-accordion>
											</mat-step>
										</mat-horizontal-stepper>
									</div>
								</div>
							</div>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel [expanded]="false" class="mpanel my_profile">
						<mat-expansion-panel-header class="mpanel-header">{{
							"lang_job_offer" | language
						}}</mat-expansion-panel-header>
						<div class="material-panel-body">
							<div class="mbrl15 martb0">
								<div class="row p-3">
									<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
										<label class="bold">{{ "lang_view" | language }}</label>
										<div>
											<i
												(click)="preview_form_data(false)"
												aria-hidden="true"
												class="fa fa-file-pdf-o"
											></i>
										</div>
									</div>
								</div>
								<div class="row mb-10">
									<div class="contentbodypanel width100 mt-0">
										<div class="col-12 contentpanel">
											<div class="itemsdata">
												<label> {{ "lang_info" | language }} : </label>
												<div class="TableParts">
													<div
														class="table-responsive customResponsive viewtable mb-10 tablefields quotationitemtable"
													>
														<table class="table CustomTables text-center">
															<tbody class="AppendList quotationtable">
																<tr>
																	<td class="bold">
																		{{ "lang_subject" | language }}
																	</td>
																	<td>{{ jop_offers.subject }}</td>
																	<td class="bold">
																		{{ "lang_branch_name" | language }}
																	</td>
																	<td>{{ viewdata.branch_name }}</td>

																	<td class="bold">
																		{{ "lang_work_domain" | language }}
																	</td>
																	<td>{{ jop_offers.work_domain }}</td>
																</tr>
																<tr>
																	<td class="bold">
																		{{ "lang_work_location" | language }}
																	</td>
																	<td>{{ jop_offers.work_location }}</td>

																	<td class="bold">
																		{{ "lang_work_type" | language }}
																	</td>
																	<td>{{ jop_offers.work_type }}</td>
																	<td class="bold">
																		{{ "lang_contract_writer" | language }}
																	</td>
																	<td>{{ jop_offers.contract_writer }}</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<br />
											</div>
											<div class="itemsdata">
												<label> {{ "lang_work" | language }} : </label>
												<div class="TableParts">
													<div
														class="table-responsive customResponsive viewtable mb-10 tablefields quotationitemtable"
													>
														<table class="table CustomTables text-center">
															<tbody class="AppendList quotationtable">
																<tr>
																	<td class="bold">
																		{{ "lang_joining_date_arabic" | language }}
																	</td>
																	<td>{{ jop_offers.joining_date_arabic }}</td>

																	<td class="bold">
																		{{ "lang_joining_date_english" | language }}
																	</td>
																	<td>{{ jop_offers.joining_date_english }}</td>

																	<td class="bold">
																		{{ "lang_probation_period" | language }}
																	</td>
																	<td>{{ jop_offers.probation_period }}</td>
																</tr>
																<tr>
																	<td class="bold">
																		{{ "lang_contract_start_date" | language }}
																	</td>
																	<td *ngIf="lang_key == 'en'">
																		{{ jop_offers.contract_start_date_english }}
																	</td>
																	<td *ngIf="lang_key == 'ar'">
																		{{ jop_offers.contract_start_date_arabic }}
																	</td>

																	<td class="bold">
																		{{ "lang_contract_period" | language }}
																	</td>
																	<td>{{ jop_offers.contract_period }}</td>

																	<td class="bold">
																		{{ "lang_contract_end_date" | language }}
																	</td>
																	<td *ngIf="lang_key == 'en'">
																		{{ jop_offers.contract_end_date_english }}
																	</td>
																	<td *ngIf="lang_key == 'ar'">
																		{{ jop_offers.contract_end_date_arabic }}
																	</td>
																</tr>
																<tr>
																	<td class="bold">
																		{{
																			"lang_notification_period_for_end_cotract"
																				| language
																		}}
																	</td>
																	<td>{{ jop_offers.joining_date_arabic }}</td>

																	<td class="bold" colspan="2">
																		{{ "lang_employee_notes" | language }}
																	</td>
																	<td colspan="2">
																		{{ jop_offers.employee_notes }}
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<br />
											</div>
											<div class="itemsdata">
												<label> {{ "lang_salary" | language }} : </label>
												<div class="TableParts">
													<div
														class="table-responsive customResponsive viewtable mb-10 tablefields quotationitemtable"
													>
														<table class="table CustomTables text-center">
															<tbody class="AppendList quotationtable">
																<tr>
																	<td class="bold">
																		{{ "lang_vacation_paid_days" | language }}
																	</td>
																	<td>{{ jop_offers.vacation_paid_days }}</td>
																	<td class="bold">
																		{{ "lang_working_days" | language }}
																	</td>
																	<td>{{ jop_offers.vacation_paid_days }}</td>

																	<td class="bold">
																		{{ "lang_working_hours" | language }}
																	</td>
																	<td>{{ jop_offers.working_hours }}</td>
																</tr>
																<tr>
																	<td class="bold">
																		{{ "lang_ticket_amount" | language }}
																	</td>
																	<td>{{ jop_offers.ticket_amount }}</td>
																	<td class="bold">
																		{{ "lang_basic_salary" | language }}
																	</td>
																	<td>{{ jop_offers.basic_salary }}</td>
																	<td class="bold">
																		{{
																			"lang_working_hours_per_week" | language
																		}}
																	</td>
																	<td>
																		{{ jop_offers.working_hours_per_week }}
																	</td>
																</tr>
																<tr>
																	<td class="bold" colspan="3">
																		{{ "lang_net_amount" | language }}
																	</td>
																	<td colspan="3">
																		{{ jop_offers.net_amount }}
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<br />
											</div>
											<div class="itemsdata">
												<label> {{ "lang_penalty_clause" | language }} </label>
												<div class="TableParts">
													<div
														class="table-responsive customResponsive viewtable mb-10 tablefields quotationitemtable"
													>
														<table class="table CustomTables text-center">
															<tbody class="AppendList quotationtable">
																<tr>
																	<td class="bold">
																		{{ "lang_first_party_user" | language }}
																	</td>
																	<td>
																		{{ jop_offers.penalty_clause_first_party }}
																	</td>
																	<td class="bold">
																		{{ "lang_second_party_user" | language }}
																	</td>
																	<td>
																		{{ jop_offers.penalty_clause_second_party }}
																	</td>
																</tr>
																<tr>
																	<td class="bold">
																		{{
																			"penalty_clause_first_party_letters"
																				| language
																		}}
																	</td>
																	<td>
																		{{
																			jop_offers.penalty_clause_first_party_letters
																		}}
																	</td>
																	<td class="bold">
																		{{
																			"penalty_clause_second_party_letters"
																				| language
																		}}
																	</td>
																	<td>
																		{{
																			jop_offers.penalty_clause_second_party_letters
																		}}
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<br />
											</div>
										</div>
									</div>
								</div>
								<form [formGroup]="form" (ngSubmit)="confirm()">
									<div class="card contractor_approval_manager">
										<div class="card-header">
											{{ "lang_jop_offer_Approval " | language }}
											<i
												*ngIf="approving_manager_status == 'Approve'"
												style="padding: 0 10px"
												class="fa fa-check-circle successgreen"
												matTooltip="{{
													'lang_approving_manager_status_is_Approve' | language
												}}"
											></i>
										</div>
										<div class="card-body pad0">
											<div class="mbrl15">
												<div class="row mb-10">
													<div class="col-sm-6 col-xs-12 mb-10">
														<label class="mb-10">
															{{ "lang_transaction_approval" | language }} !?.
															<span class="red"> * </span> </label
														><br />

														<mat-radio-group
															aria-label="Select an option"
															formControlName="approved"
															(change)="checkStatus()"
														>
															<mat-radio-button value="Reject">{{
																"lang_reject" | language
															}}</mat-radio-button>
															<mat-radio-button value="Approve">{{
																"lang_approve" | language
															}}</mat-radio-button>
														</mat-radio-group>
														<mat-error
															class="d-block"
															*ngIf="
																submitForm &&
																form.controls['approved']?.errors?.required
															"
															>{{ "lang_field_required" | language }}</mat-error
														>
													</div>
													<div class="col-sm-6 col-xs-12 mb-10">
														<div>
															<label
																>{{ "lang_notes" | language }} :
																<span
																	class="red"
																	*ngIf="rejectedValidators.length > 0"
																	>*</span
																>
															</label>
															<textarea
																class="form-control almnabrformcontrol"
																formControlName="notes"
															></textarea>
															{{ form.controls["notes"].VALID }}
															<mat-error
																class="d-block"
																*ngIf="
																	submitForm &&
																	form.controls['notes']?.errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</div>
													</div>
												</div>
												<div class="row pt-3">
													<div class="col-sm-6">
														<div class="form-group">
															<label for="verification_code"
																>{{
																	"lang_enter_verification_code" | language
																}}
																<span class="red">*</span>:
																<i
																	style="padding: 0 10px"
																	class="fa fa-info-circle orangestatus-color"
																	matTooltip="{{
																		'lang_if_you_not_have_code_hit_sendcode_button'
																			| language
																	}}"
																></i>
															</label>
															<input
																type="text"
																required
																class="form-control almnabrformcontrol"
																formControlName="transactions_persons_action_code"
															/>
															{{
																form.controls[
																	"transactions_persons_action_code"
																].VALID
															}}

															<mat-error
																*ngIf="
																	submitForm &&
																	form.controls[
																		'transactions_persons_action_code'
																	]?.errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</div>
													</div>
													<div class="col-sm-12">
														<app-alert id="default-alert"></app-alert>
													</div>
												</div>
												<div class="row mb-10">
													<div class="col-sm-4 col-xs-12 mb-10">
														<div>
															<button
																class="albutton mnabrbutton mat-accent"
																type="button"
																(click)="openModal()"
																mat-button
															>
																{{ "lang_send_code" | language }}
															</button>
														</div>
													</div>
													<div class="col-sm-4 col-xs-12 mb-10"></div>
													<div class="col-sm-4 col-xs-12 mb-10">
														<div>
															<input
																*ngIf="form.status == 'VALID'"
																type="submit"
																value="{{ 'lang_submit' | language }}"
																class="albutton mnabrbutton mat-accent"
																mat-button
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</div>
	</div>
</div>
