import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-main-reports",
  templateUrl: "./main-reports.component.html",
  styleUrls: ["./main-reports.component.scss"],
})
export class MainReportsComponent implements OnInit {
  @Input() report_data;
  constructor() {}

  ngOnInit(): void {}
}
