import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { NgbCalendar, NgbCalendarIslamicUmalqura, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { IslamicI18n } from '@shared/utils/arabicpicker';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-step7',
  templateUrl: './step7.component.html',
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n }
  ]
})
export class Step7Component implements OnInit {
  @Input() regForm: FormGroup;
  @Input() action;
  jobLists: any = [];
  totallice: any = [];
  singleselection = environment.singledropdown;
  licencesList: FormArray;
  licencesstatus: boolean;
  id: string;
  branch_id: string;
  key: string;
  constructor(public router:Router,public route :ActivatedRoute, public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  get f() {
    return this.regForm.controls.jobDetails;
  }
  get licenceFormGroup() {
    return this.regForm.get('jobDetails').get("liclists") as FormArray;
  }
  ngOnInit(): void {
    this.jobTitles();
    this.licencesList = this.regForm.get("jobDetails").get('liclists') as FormArray;
    if(this.action === "EDIT"){
      this.id = this.route.snapshot.paramMap.get('id');
      this.branch_id = this.route.snapshot.paramMap.get('bid');
      this.key = this.route.snapshot.paramMap.get('key');
      this.loadmylicenses();
    }
  }
  jobTitles() {
    this.jobLists = [];
    this.ds.getActionByUrl([], 'human_resources/joblists/').subscribe(data => {
      if (data.status) {
        this.jobLists = data.joblists;
      }
    });
  }
  loadmylicenses() {
    this.licencesstatus = false;
    this.licencesList.controls = [];
    this.ds.getActionByUrl([], 'human_resources/get_my_licences/' + this.id + '/' + this.branch_id).subscribe(data => {
      this.licencesstatus = data.status;
      if (data.status) {
        data.lics.forEach(lic => {
          this.licencesList.push(this.appendLicences(lic));
        });
      }
    })
  }
  appendLicences(item){
    return this.fb.group({
      licence_list_id: new FormControl(item.licence_list_id),
      settings_id: new FormControl(item.settings_id),
      licence_name: new FormControl({ value: item.licence_name, disabled: true }),
      licence_number: new FormControl(item.licence_number),
      licence_issue_date_english: new FormControl(this.ds.encon(item.licence_issue_date_english)),
      licence_issue_date_arabic: new FormControl(this.ds.arcon(item.licence_issue_date_arabic)),
      licence_expiry_date_english: new FormControl(this.ds.encon(item.licence_expiry_date_english)),
      licence_expiry_date_arabic: new FormControl(this.ds.arcon(item.licence_expiry_date_arabic)),
    });
  }
  createLicences(item): FormGroup {
    return this.fb.group({
      licence_list_id: new FormControl(item.licence_list_id),
      settings_id: new FormControl(item.settings_id),
      licence_name: new FormControl({ value: item.licence_name, disabled: true }),
      licence_number: new FormControl(""),
      licence_issue_date_english: new FormControl(""),
      licence_issue_date_arabic: new FormControl(null),
      licence_expiry_date_english: new FormControl(""),
      licence_expiry_date_arabic: new FormControl(null),
    });
  }
  getJobLicenses(evt) {
    this.licencesstatus = false;
    this.licencesList.controls = [];
    if(evt && evt.value) {
      this.spinner.show();
      this.loadLicences(evt.value);
    }
  }
  loadLicences(id) {
    if (id) {
      this.licencesList.controls = [];
      this.ds.getActionByUrl([], 'human_resources/joblistslic/' + id).subscribe(data => {
        this.spinner.hide();
        this.licencesstatus = data.status;
        if (data.status) {
          data.licenses.forEach(item => {
            this.licencesList.push(this.createLicences(item));
          });
        } else {
          this.licencesstatus = false;
        }
      }, error => {
          this.spinner.hide();
        this.licencesstatus = false;
      });
    }
  }
  step7Submitted() {
    this.regForm.get("jobDetails").get("job_title_id").markAsTouched();
    this.regForm.get("jobDetails").get("job_title_id").updateValueAndValidity();
    this.regForm.get("jobDetails").get("job_descriptions").markAsTouched();
    this.regForm.get("jobDetails").get("job_descriptions").updateValueAndValidity();
  }
}
