<mat-toolbar class="matero-toolbar mx-background-top-linear">
  <ng-container *ngIf="isMob">
    <button
      mat-icon-button
      class="matero-toolbar-button"
      *ngIf="showToggle"
      (click)="toggleSidenav.emit()"
    >
      <mat-icon>menu</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="!isMob">
    <div
      id="nav-icon2"
      class="open"
      *ngIf="showToggle"
      (click)="toggleSidenav.emit(); hamburger($event)"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </ng-container>

  <app-branding *ngIf="showBranding"></app-branding>
  <span fxFlex></span>
  <!--<app-dynamic-fontsize></app-dynamic-fontsize>-->
  <!--<app-notification></app-notification>-->
  <app-translate></app-translate>
  <!--<app-user fxHide.lt-sm></app-user>-->
  <!-- (click)="toggleSidenavNotice.emit(); getNotificationsEvent.emit()" -->
  <button
    mat-icon-button
    class="matero-toolbar-button"
    (click)="showNotifications()"
  >
    <i
      *ngIf="!notifications_number"
      class="fa fa-bell"
      style="color: #fff"
    ></i>
    <i
      *ngIf="notifications_number"
      class="fa fa-bell"
      style="color: #fff"
      [matBadge]="notifications_number"
      matBadgeColor="warn"
      [matBadgePosition]="lang_key == 'en' ? 'after' : 'before'"
    ></i>
  </button>
</mat-toolbar>
