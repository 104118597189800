import { Component, Inject, OnInit } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-add-platforn-levels",
	templateUrl: "./add-platforn-levels.component.html",
	styleUrls: ["./add-platforn-levels.component.scss"],
})
export class AddPlatfornLevelsComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	projects_profile_id;
	projects_work_area_id;
	title_id;
	sub_title_id;
	templateList = [];
	worklevelForm: FormGroup;
	other_platforms: FormArray;
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public sweetalert: SweetAlertService,
		public dialog: MatDialog,
		public fb: FormBuilder,
		public dialogRef: MatDialogRef<AddPlatfornLevelsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.projects_profile_id = data?.projects_profile_id;
		this.projects_work_area_id = data?.projects_work_area_id;
		this.title_id = data?.titleId;
		this.sub_title_id = data?.subTitleId;
	}

	ngOnInit(): void {
		this.buildForm();
		this.getTemplates();
	}
	onTemplateChange(event) {
		this.other_platforms.clear();
		this.other_platforms.setValidators(Validators.required);
		this.other_platforms.updateValueAndValidity();
	}
	getTemplates() {
		let formData = new FormData();
		formData.append("projects_profile_id", this.projects_profile_id);
		formData.append("projects_work_area_id", this.projects_work_area_id);
		formData.append("lang_key", this.lang_key);
		this.ds
			.getActionByUrl(this.ds.formData2string(formData), "p/cp/get_templates")
			.subscribe(
				(res) => {
					if (res?.status) {
						this.templateList = res?.records;
					} else {
						this.templateList = [];
					}
				},
				(err) => {
					this.templateList = [];
					this.sweetalert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
	getPlatforms(event, index) {
		let formData = new FormData();
		formData.append("projects_profile_id", this.projects_profile_id);
		formData.append("projects_work_area_id", this.projects_work_area_id);
		formData.append("lang_key", this.lang_key);
		formData.append("template_id", this.worklevelForm.get("template_id").value);
		formData.append("search_key", event?.term || "");
		formData.append("platform_code_system", "");
		this.ds
			.getActionByUrl(this.ds.formData2string(formData), "p/cp/get_platforms")
			.subscribe(
				(res) => {
					if (res?.status) {
						this.other_platforms.controls[index]
							.get("platforms")
							.setValue(res?.records);
					} else {
						this.other_platforms.controls[index].get("platforms").setValue([]);
					}
				},
				(err) => {
					this.other_platforms.controls[index].get("platforms").setValue([]);
					this.sweetalert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
	getWorklevels(index) {
		let formData = new FormData();
		formData.append("projects_profile_id", this.projects_profile_id);
		formData.append("projects_work_area_id", this.projects_work_area_id);
		formData.append("lang_key", this.lang_key);
		formData.append("template_id", this.worklevelForm.get("template_id").value);
		formData.append(
			"platform_code_system",
			this.other_platforms.controls[index].get("platform_code_system")?.value
		);
		this.ds
			.getActionByUrl(this.ds.formData2string(formData), "p/cp/get_work_levels")
			.subscribe(
				(res) => {
					if (res?.status) {
						this.other_platforms.controls[index]
							.get("worklevelList")
							.setValue(res?.records);
					} else {
						this.other_platforms.controls[index]
							.get("worklevelList")
							.setValue([]);
					}
				},
				(err) => {
					this.other_platforms.controls[index]
						.get("worklevelList")
						.setValue([]);
					this.sweetalert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
	buildForm() {
		this.worklevelForm = this.fb.group({
			template_id: [null, Validators.required],
			platforms: this.fb.array([], Validators.required),
		});
		this.other_platforms = this.worklevelForm.get("platforms") as FormArray;
	}

	public worklevels(value?): FormGroup {
		return this.fb.group({
			platform_code_system: [null, Validators.required],
			work_levels: [null, Validators.required],
			platforms: [],
			worklevelList: [],
		});
	}
	public addWorklevels(value?) {
		if (this.other_platforms.length < 10) {
			this.other_platforms.push(this.worklevels(value));
			this.other_platforms.setValidators(Validators.required);
			this.other_platforms.updateValueAndValidity();
			this.getPlatforms({ term: "" }, this.other_platforms?.length - 1);
		}
	}
	public removeWorklevels(index) {
		if (this.other_platforms.length == 1) {
			this.other_platforms.removeAt(index);
			this.other_platforms.clearValidators();
			this.other_platforms.updateValueAndValidity();
		} else {
			this.other_platforms.removeAt(index);
		}
	}
	get worklevelsGroup() {
		return this.worklevelForm.get("platforms") as FormArray;
	}
	submit() {
		if (this.worklevelForm.valid) {
			let formData = new FormData();
			formData.append("projects_profile_id", this.projects_profile_id);
			formData.append("projects_work_area_id", this.projects_work_area_id);
			formData.append("title_id", this.title_id);
			formData.append("sub_title_id", this.sub_title_id);
			formData.append(
				"template_id",
				this.worklevelForm.get("template_id").value
			);
			this.other_platforms.controls.forEach((element, index) => {
				formData.append(
					`platforms[${index}][platform_code_system]`,
					element.get("platform_code_system").value
				);
				formData.append(
					`platforms[${index}][work_levels]`,
					element.get("work_levels").value
				);
			});
			this.spinner.show();
			this.ds.post("p/cp/assign_platform_wlevels", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetalert.successToast(res?.msg, 2000);
						this.dialogRef.close(true);
					} else {
						this.sweetalert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetalert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
		} else {
			this.sweetalert.errorToast(
				this.lang.transform("lang_fill_required_fields"),
				2000
			);
		}
	}
}
