import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
// import * as Editor from "assets/libraries/ckeditor/build/ckeditor";
declare var $: any;
@Component({
  selector: "app-formpq1-add",
  templateUrl: "./formpq1-add.component.html",
  styleUrls: ["./formpq1-add.component.scss"],
})
export class Formpq1AddComponent implements OnInit {
  lang_key =
    localStorage.getItem("lang_key") == null
      ? "en"
      : localStorage.getItem("lang_key");
  // public myEditor = Editor;
  config = {
    language: this.lang_key,
    toolbar: [
      {
        name: "document",
        items: [
          "Source",
          "-",
          "Save",
          "NewPage",
          "Preview",
          "Print",
          "-",
          "Templates",
        ],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          "Copy",
          "Paste",
          "PasteText",
          "PasteFromWord",
          "-",
          "Undo",
          "Redo",
        ],
      },
      {
        name: "editing",
        items: ["Find", "Replace", "-", "SelectAll"],
      },
      {
        name: "forms",
        items: [
          "Form",
          "Checkbox",
          "Radio",
          "TextField",
          "Textarea",
          "Select",
          "Button",
          "HiddenField",
        ],
      },
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "Subscript",
          "Superscript",
          "-",
          "CopyFormatting",
          "RemoveFormat",
        ],
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      {
        name: "insert",
        items: [
          "Image",
          "Flash",
          "Table",
          "wrapCode",
          "TableCellProperties",
          "TableColumnResize",
          "TableProperties",
          "TableToolbar",
          "HorizontalRule",
          "Smiley",
          "SpecialChar",
          "PageBreak",
          "Iframe",
        ],
      },
      { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
      { name: "colors", items: ["TextColor", "BGColor"] },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "CreateDiv",
          "-",
          "JustifyLeft",
          "JustifyCenter",
          "JustifyRight",
          "JustifyBlock",
          "-",
          "BidiLtr",
          "BidiRtl",
          "Language",
        ],
      },
      { name: "tools", items: ["Maximize", "ShowBlocks"] },
    ],
    extraPlugins:
      "justify,colorbutton,font,codesnippet,autocomplete,find,selectall,iframe,div,enterkey,notification,bidi",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    removeButtons: "Image",
  };
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() service_data;
  @Output() quotEvent = new EventEmitter();
  form: FormGroup;
  items: FormArray;
  error_status: boolean = false;
  submitted: boolean = false;
  error_message: string = "";
  data: any = [];
  data2: any = [];
  fieldenable = false;
  markstatus: boolean = false;
  reviewstatus: boolean = false;
  viewerstatus: boolean = false;
  stampstatus: boolean = false;
  attachstatus: boolean = false;
  othersstatus: boolean = false;
  stamps: any = [];
  filename = this.lang.transform("lang_select_files");
  isFile: boolean = false;
  attachments: FormArray;
  dynamic_table_fields: FormArray;
  others_fields: FormArray;
  languages: any = [];
  branches: any = [];
  loading = false;
  document_types: any = [];
  users: any = [];
  quottypes: any = [];
  nationality: any = [];
  quotation_type_status = false;
  @ViewChild("contentData", { static: false }) contentdata: ElementRef;
  dynamiccontents: boolean = false;
  restrictions_fixed_required: boolean = true;
  restrictions_fixed_required_replace = [
    {
      key: "###test###",
      val: "Hi Raja",
    },
  ];
  restrictions_fixed_optional_items = [];
  dynamicrestrictions = false;
  show_item_fileds: boolean = false;
  vat: any = [];
  constructor(
    public ds: DataService,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public fb: FormBuilder,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.build_form();
    this.add_new_items();
    this.get_required_data("tc/tlanguages");
  }
  onCkEditorReady(editor: any): boolean {
    const result = true;
    // editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    //   return new UploadAdapter (loader, {}, currentComponent.httpClient);
    // };

    console.log(editor);
    // console.log(editor);

    return result;
  }
  public build_form() {
    let description =
      this.lang_key == "ar"
        ? this.project_data.projects_profile_name_ar
        : this.project_data.projects_profile_name_en;
    let servicename =
      this.lang_key == "ar"
        ? this.service_data.projects_services_name_ar
        : this.service_data.projects_services_name_en;
    this.form = new FormGroup({
      care: new FormControl(""),
      quotation_types_keyword: new FormControl(null, [Validators.required]),
      lang_key: new FormControl(null, [Validators.required]),
      vat_key: new FormControl("", [Validators.required]),
      transaction_request_description: new FormControl(
        { value: description + " (" + servicename + ")", disabled: true },
        [Validators.required]
      ),
      items: this.fb.array([]),
      content: new FormControl("", [Validators.required]),
      needMark: new FormControl("0", [Validators.required]),
      needReview: new FormControl("0", [Validators.required]),
      needStamp: new FormControl("0", [Validators.required]),
      needAttach: new FormControl("0", [Validators.required]),
      needViewers: new FormControl("0", [Validators.required]),
      needOthers: new FormControl("0", [Validators.required]),
      "signature[A][title]": new FormControl(""),
      "signature[A][user_id]": new FormControl(null),
      "signature[B][title]": new FormControl(""),
      "signature[B][user_id]": new FormControl(null),
      stamp: new FormControl(null),
      "signature[C][title]": new FormControl(""),
      "signature[C][user_id]": new FormControl(null),
      marks: new FormControl(null),
      reviews: new FormControl(null),
      viewers: new FormControl(null),
      attachments: this.fb.array([]),
      others: this.fb.array([]),
      dynamic_table_fields: this.fb.array([]),
    });
    this.items = this.form.get("items") as FormArray;
    this.attachments = this.form.get("attachments") as FormArray;
    this.others_fields = this.form.get("others") as FormArray;
    this.dynamic_table_fields = this.form.get(
      "dynamic_table_fields"
    ) as FormArray;
  }
  public items_group() {
    return this.form.get("items") as FormArray;
  }
  get itemsgroup() {
    return this.form.get("items") as FormArray;
  }
  public add_new_items() {
    this.items.push(this.item_records());
  }
  public removeItems(index) {
    if (this.items.length > 1) {
      this.items.removeAt(index);
    }
  }
  public item_records(): FormGroup {
    return this.fb.group({
      position: new FormControl(null, [Validators.required]),
      presence: new FormControl(null, [Validators.required]),
      nationality: new FormControl(null, [Validators.required]),
      team_number: new FormControl("", [Validators.required]),
      experience: new FormControl("", [Validators.required]),
      positions_salary: new FormControl("", [Validators.required]),
      basic_salary: new FormControl("", [Validators.required]),
      salary_allowances: new FormControl("", [Validators.required]),
      actual_salary: new FormControl("", [Validators.required]),
      administrative_costs: new FormControl("", [Validators.required]),
      total_cost_per_person_month: new FormControl("", [Validators.required]),
      profit_ratio: new FormControl("", [Validators.required]),
      total_salary_per_person_month: new FormControl("", [Validators.required]),
      duration_of_months: new FormControl("", [Validators.required]),
      visits_per_month: new FormControl("", [Validators.required]),
      total_crew_cost_project: new FormControl("", [Validators.required]),
      total_bid_of_project: new FormControl("", [Validators.required]),
      positions_task: new FormControl("", [Validators.required]),
    });
  }
  //other dynamics start
  public others_group() {
    return this.form.get("others") as FormArray;
  }
  get othersgroup() {
    return this.form.get("others") as FormArray;
  }
  public add_new_others() {
    this.others_fields.push(this.other_records());
  }
  public remove_other_items(index) {
    if (this.others_fields.length > 1) {
      this.others_fields.removeAt(index);
    }
  }
  public other_records(): FormGroup {
    return this.fb.group({
      qror_name_en: new FormControl("", [Validators.required]),
      qror_name_ar: new FormControl("", [Validators.required]),
    });
  }
  public changedirection(event) {
    this.dynamic_table_fields.controls = [];
    this.items.clear();
    this.add_new_items();
    let val = event != undefined ? event.value : this.lang_key;
    this.form.get("quotation_types_keyword").setValue(null);
    this.show_item_fileds = false;
    this.dynamiccontents = false;
    this.quottypes = [];
    this.data = [];
    this.data2 = [];
    this.nationality = [];
    this.quotation_type_status = false;
    this.dynamicrestrictions = false;
    this.restrictions_fixed_optional_items = [];
    this.lang_key = val;
    let description =
      this.lang_key == "ar"
        ? this.project_data.projects_profile_name_ar
        : this.project_data.projects_profile_name_en;
    let servicename =
      this.lang_key == "ar"
        ? this.service_data.projects_services_name_ar
        : this.service_data.projects_services_name_en;
    this.form
      .get("transaction_request_description")
      .setValue(description + " (" + servicename + ")");
    if (this.form.get("lang_key").value) {
      this.quotation_type_status = true;
      this.multiple_requests();
    } else {
      this.spinner.hide();
    }
  }
  //join guotation types, positions, presences and nationalities
  public multiple_requests() {
    let param = new FormData();
    param.append("lang_key", this.lang_key);
    const urls: string[] = [
      "form/FORM_PQ1/qt",
      "form/FORM_PQ1/po",
      "form/FORM_PQ1/pres",
      "form/FORM_PQ1/nationality",
      "form/FORM_PQ1/vat",
    ];
    this.ds.forkJoin(urls, this.ds.formData2string(param)).subscribe(
      (res) => {
        if (res[0].status) {
          this.quottypes = res[0].records;
        }
        if (res[1].status) {
          this.data = res[1].records;
        }
        if (res[2].status) {
          this.data2 = res[2].records;
        }
        if (res[3].status) {
          this.nationality = res[3].records;
        }
        if (res[4].status) {
          this.vat = res[4].records;
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  public quotation_select(val) {
    this.dynamicrestrictions = false;
    this.dynamiccontents = false;
    this.show_item_fileds = false;
    if (val != undefined) {
      this.show_item_fileds = true;
      this.spinner.show();
      let param = new FormData();
      param.append("quotation_types_keyword", val.value);
      param.append("lang_key", this.lang_key);
      param.append("projects_ps_id", this.service_data.projects_ps_id);
      //descriptions
      this.ds
        .getActionByUrl(this.ds.formData2string(param), "form/FORM_PQ1/qrfr")
        .subscribe(
          (res) => {
            this.spinner.hide();
            this.dynamiccontents = res.status;
            if (res.status) {
              this.changeDetectorRef.detectChanges();
              let text = "";
              res.records.forEach((v, k) => {
                text += v.label + "<br>";
              });
              this.contentdata.nativeElement.innerHTML = text;
            }
          },
          (error) => {
            this.dynamiccontents = false;
            this.spinner.hide();
          }
        );
      //restrichtion fields
      this.ds
        .getActionByUrl(this.ds.formData2string(param), "form/FORM_PQ1/qrfo")
        .subscribe(
          (res) => {
            this.spinner.hide();
            this.dynamicrestrictions = res.status;
            if (res.status) {
              let dynamiccheck = [];
              res.records.forEach((v, k) => {
                dynamiccheck.push({
                  label: v.label,
                  value: v.value,
                  checked: false,
                });
              });
              this.restrictions_fixed_optional_items = dynamiccheck;
            }
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
    this.spinner.hide();
  }
  public setDataAction(event, index) {
    if (event == undefined) {
      this.DesetData(index);
    } else {
      this.setData(index);
    }
  }
  // other dynamics end
  public setData(index) {
    let positions_salary: any = 0.0;
    let salary_allowances: any = 0.0;
    let administrative_costs: any = 0.0;
    let positions = this.items.controls[index].get("position").value;
    if (positions && positions != undefined) {
      positions_salary =
        parseFloat(positions.positions_salary) >= 0
          ? parseFloat(positions.positions_salary)
          : 0.0;
      salary_allowances =
        parseFloat(positions.salary_allowances) >= 0
          ? parseFloat(positions.salary_allowances)
          : 0.0;
      administrative_costs =
        parseFloat(positions.administrative_costs) >= 0
          ? parseFloat(positions.administrative_costs)
          : 0.0;
      this.items.controls[index]
        .get("positions_salary")
        .setValue(parseFloat(positions_salary).toFixed(2));
      this.items.controls[index]
        .get("salary_allowances")
        .setValue(parseFloat(salary_allowances).toFixed(2));
      this.items.controls[index]
        .get("administrative_costs")
        .setValue(parseFloat(administrative_costs).toFixed(2));
      this.items.controls[index]
        .get("positions_task")
        .setValue(positions.positions_task);
    }
    this.items.controls[index].get("profit_ratio").setValue("1.25");
    this.items.controls[index].get("visits_per_month").setValue("");
  }
  public DesetData(index) {
    this.items.controls[index].get("positions_salary").setValue(0);
    this.items.controls[index].get("salary_allowances").setValue(0);
    this.items.controls[index].get("administrative_costs").setValue(0);
    this.items.controls[index].get("positions_task").setValue(0);
    this.items.controls[index].get("visits_per_month").setValue("");
    this.items.controls[index].get("profit_ratio").setValue(0);
  }
  public calculate_quotation(index) {
    let presence = this.items.controls[index].get("presence").value;
    let positions_salary: any =
      parseFloat(this.items.controls[index].get("positions_salary").value) >= 0
        ? parseFloat(this.items.controls[index].get("positions_salary").value)
        : 0;
    let salary_allowances: any =
      parseFloat(this.items.controls[index].get("salary_allowances").value) >= 0
        ? parseFloat(this.items.controls[index].get("salary_allowances").value)
        : 0;
    let administrative_costs: any =
      parseFloat(
        this.items.controls[index].get("administrative_costs").value
      ) >= 0
        ? parseFloat(
            this.items.controls[index].get("administrative_costs").value
          )
        : 0;
    let team_number: any =
      parseFloat(this.items.controls[index].get("team_number").value) >= 0
        ? parseFloat(this.items.controls[index].get("team_number").value)
        : 0;
    let experience: any =
      parseFloat(this.items.controls[index].get("experience").value) >= 0
        ? parseFloat(this.items.controls[index].get("experience").value)
        : 0;
    let profit_ratio: any =
      parseFloat(this.items.controls[index].get("profit_ratio").value) >= 0
        ? parseFloat(this.items.controls[index].get("profit_ratio").value)
        : 0;
    let basic_salary: any =
      parseFloat(positions_salary) + (parseFloat(experience) / 2) * 1000;
    this.items.controls[index]
      .get("basic_salary")
      .setValue(parseFloat(basic_salary).toFixed(2));
    let actual_salary: any =
      parseFloat(salary_allowances) * parseFloat(basic_salary);
    this.items.controls[index]
      .get("actual_salary")
      .setValue(parseFloat(actual_salary).toFixed(2));
    let total_cost_per_person_month: any = 0.0;
    let presence_type =
      presence && presence != undefined ? presence.keyword : "";
    if (presence_type == "resident") {
      this.fieldenable = false;
      $(".rowdisable" + index).addClass("disablebyaction");
      this.items.controls[index].get("visits_per_month").disable();
      total_cost_per_person_month =
        parseFloat(administrative_costs) *
        parseFloat(actual_salary) *
        parseFloat(team_number);
    } else if (presence_type == "visitor") {
      $(".rowdisable" + index).removeClass("disablebyaction");
      this.items.controls[index].get("visits_per_month").enable();
      total_cost_per_person_month =
        (parseFloat(administrative_costs) *
          parseFloat(actual_salary) *
          parseFloat(team_number)) /
        30;
    } else {
      $(".rowdisable" + index).addClass("disablebyaction");
      this.items.controls[index].get("visits_per_month").disable();
    }
    this.items.controls[index]
      .get("total_cost_per_person_month")
      .setValue(parseFloat(total_cost_per_person_month).toFixed(2));
    let total_salary_per_person_month: any =
      parseFloat(profit_ratio) * parseFloat(total_cost_per_person_month);
    this.items.controls[index]
      .get("total_salary_per_person_month")
      .setValue(parseFloat(total_salary_per_person_month).toFixed(2));
    let total_crew_cost_project: any = 0;
    let total_bid_of_project: any = 0;
    let duration_of_months: any =
      parseInt(this.items.controls[index].get("duration_of_months").value) >= 0
        ? parseInt(this.items.controls[index].get("duration_of_months").value)
        : 0;
    let visits_per_month: any =
      parseInt(this.items.controls[index].get("visits_per_month").value) >= 0
        ? parseInt(this.items.controls[index].get("visits_per_month").value)
        : 0;
    if (presence_type == "resident") {
      total_crew_cost_project =
        parseFloat(total_cost_per_person_month) * parseInt(duration_of_months);
      total_bid_of_project =
        parseFloat(total_salary_per_person_month) *
        parseInt(duration_of_months);
    } else if (presence_type == "visitor") {
      total_crew_cost_project =
        parseFloat(total_cost_per_person_month) *
        parseInt(duration_of_months) *
        parseInt(visits_per_month);
      total_bid_of_project =
        parseFloat(total_salary_per_person_month) *
        parseInt(duration_of_months) *
        parseInt(visits_per_month);
    }
    this.items.controls[index]
      .get("total_crew_cost_project")
      .setValue(parseFloat(total_crew_cost_project).toFixed(2));
    this.items.controls[index]
      .get("total_bid_of_project")
      .setValue(parseFloat(total_bid_of_project).toFixed(2));
  }
  public files(): FormGroup {
    return this.fb.group({
      attach_title: new FormControl(
        "",
        this.form.get("needAttach").value == "1" ? [Validators.required] : null
      ),
      file: new FormControl(
        "",
        this.form.get("needAttach").value == "1" ? [Validators.required] : null
      ),
      attach_with_the_document: new FormControl(0),
      print_official_paper: new FormControl(0),
    });
  }
  public addfiles() {
    if (this.attachments.length < 5) {
      this.attachments.push(this.files());
    }
  }
  public removefiles(index) {
    if (this.attachments.length > 1) {
      this.attachments.removeAt(index);
    }
  }
  public getfilesgroup(index): FormGroup {
    const formGroup = this.attachments.controls[index] as FormGroup;
    return formGroup;
  }
  get filesFormGroup() {
    return this.form.get("attachments") as FormArray;
  }
  public UploadFile(event, index) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      $("#uploadText" + index).text(fileid.name);
      this.attachments.controls[index]
        .get("file")
        .setValue(fileid, { emitEvent: true });
    } else {
      this.attachments.controls[index].get("file").setValue(null);
      $("#uploadText" + index).text(this.lang.transform("lang_select_files"));
    }
  }
  public uploadFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      this.isFile = true;
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.form.get("upload").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.form.get("upload").setValue(null);
      this.filename = this.lang.transform("lang_select_files");
    }
  }
  public removeFile() {
    this.isFile = false;
    this.form.get("upload").setValue(null);
    this.filename = this.lang.transform("lang_select_files");
  }
  public enable_field(field) {
    if (field == "mark") {
      this.form.get("needMark").setValue("1");
      this.form.get("marks").setValidators(Validators.required);
      this.form.get("marks").updateValueAndValidity();
      this.markstatus = true;
    } else if (field == "review") {
      this.form.get("needReview").setValue("1");
      this.form.get("reviews").setValidators(Validators.required);
      this.form.get("reviews").updateValueAndValidity();
      this.reviewstatus = true;
    } else if (field == "stamp") {
      this.form.get("needStamp").setValue("1");
      this.form.get("stamp").setValidators(Validators.required);
      this.form.get("stamp").updateValueAndValidity();
      this.stampstatus = true;
    } else if (field == "attachment") {
      this.attachments.clear();
      this.form.get("needAttach").setValue("1");
      this.addfiles();
      this.attachstatus = true;
    } else if (field == "viewer") {
      this.form.get("needViewers").setValue("1");
      this.form.get("viewers").setValidators(Validators.required);
      this.form.get("viewers").updateValueAndValidity();
      this.viewerstatus = true;
    } else if (field == "others") {
      this.form.get("needOthers").setValue("1");
      this.others_fields.clear();
      this.add_new_others();
      this.othersstatus = true;
    }
  }
  public disable_field(field) {
    if (field == "mark") {
      this.form.get("needMark").setValue("0");
      this.form.get("marks").setValue(null);
      this.form.get("marks").clearValidators();
      this.form.get("marks").updateValueAndValidity();
      this.markstatus = false;
    } else if (field == "review") {
      this.form.get("needReview").setValue("0");
      this.form.get("reviews").setValue(null);
      this.form.get("reviews").clearValidators();
      this.form.get("reviews").updateValueAndValidity();
      this.reviewstatus = false;
    } else if (field == "stamp") {
      this.form.get("needStamp").setValue("0");
      this.form.get("stamp").setValue(null);
      this.form.get("stamp").clearValidators();
      this.form.get("stamp").updateValueAndValidity();
      this.stampstatus = false;
    } else if (field == "attachment") {
      this.attachments.clear();
      this.form.get("needAttach").setValue("0");
      this.isFile = false;
      this.attachstatus = false;
    } else if (field == "viewer") {
      this.form.get("needViewers").setValue("0");
      this.form.get("viewers").setValue(null);
      this.form.get("viewers").clearValidators();
      this.form.get("viewers").updateValueAndValidity();
      this.viewerstatus = false;
    } else if (field == "others") {
      this.others_fields.clear();
      this.form.get("needOthers").setValue("0");
      this.othersstatus = false;
    }
    this.form.updateValueAndValidity();
  }
  public get_required_data(url) {
    this.spinner.show();
    this.languages = [];
    this.ds.getActionByUrl([], url).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.languages = res.records;
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  public search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append("search", key.term);
    param.append("lang_key", this.lang_key);
    param.append("user_type_id", "1");
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
      .subscribe((res) => {
        if (res.status) {
          this.users = res.list;
        }
      });
  }
  public submit() {
    this.submitted = true;
    if (this.validstatus()) {
      this.submitted = false;
      this.spinner.show();
      let param = new FormData();
      param.set("care", this.form.get("care").value);
      param.set("lang_key", this.lang_key);
      param.set(
        "quotation_types_keyword",
        this.form.get("quotation_types_keyword").value
      );
      param.set("projects_profile_id", this.project_data.projects_profile_id);
      param.set("vat_key", this.form.get("vat_key").value);
      param.set("projects_ps_id", this.service_data.projects_ps_id);
      param.set("branch_id", this.project_data.branch_id);
      param.set("transaction_from", this.project_data.branch_id);
      param.set(
        "transaction_to",
        this.lang_key == "ar"
          ? this.project_data.customer_title_ar
          : this.project_data.customer_title_en
      );
      let description =
        this.lang_key == "ar"
          ? this.project_data.projects_profile_name_ar
          : this.project_data.projects_profile_name_en;
      let servicename =
        this.lang_key == "ar"
          ? this.service_data.projects_services_name_ar
          : this.service_data.projects_services_name_en;
      param.set(
        "transaction_request_description",
        description + " (" + servicename + ")"
      );
      this.itemsgroup.value.forEach((v, k) => {
        param.set(
          "teams[" + k + "][position]",
          v.position != null && v.position != undefined ? v.position.value : ""
        );
        param.set(
          "teams[" + k + "][presence]",
          v.presence != null && v.presence != undefined ? v.presence.value : ""
        );
        param.set("teams[" + k + "][nationality]", v.nationality);
        param.set("teams[" + k + "][team_number]", v.team_number);
        param.set("teams[" + k + "][experience]", v.experience);
        param.set("teams[" + k + "][positions_salary]", v.positions_salary);
        param.set("teams[" + k + "][basic_salary]", v.basic_salary);
        param.set("teams[" + k + "][salary_allowances]", v.salary_allowances);
        param.set("teams[" + k + "][actual_salary]", v.actual_salary);
        param.set(
          "teams[" + k + "][administrative_costs]",
          v.administrative_costs
        );
        param.set(
          "teams[" + k + "][total_cost_per_person_month]",
          v.total_cost_per_person_month
        );
        param.set("teams[" + k + "][profit_ratio]", v.profit_ratio);
        param.set(
          "teams[" + k + "][total_salary_per_person_month]",
          v.total_salary_per_person_month
        );
        param.set("teams[" + k + "][duration_of_months]", v.duration_of_months);
        param.set(
          "teams[" + k + "][visits_per_month]",
          v.visits_per_month != undefined ? v.visits_per_month : "0"
        );
        param.set(
          "teams[" + k + "][total_crew_cost_project]",
          v.total_crew_cost_project
        );
        param.set(
          "teams[" + k + "][total_bid_of_project]",
          v.total_bid_of_project
        );
        param.set("teams[" + k + "][positions_task]", v.positions_task);
      });
      param.set("content", this.form.get("content").value);
      param.set("needMark", this.form.get("needMark").value);
      param.set("needReview", this.form.get("needReview").value);
      param.set("needStamp", this.form.get("needStamp").value);
      param.set("needViewers", this.form.get("needViewers").value);
      param.set("needAttach", this.form.get("needAttach").value);
      param.set("needOthers", this.form.get("needOthers").value);
      param.set("needMark", this.form.get("needMark").value);
      param.set(
        "signature[A][title]",
        this.form.get("signature[A][title]").value
      );
      param.set(
        "signature[A][user_id]",
        this.form.get("signature[A][user_id]").value || ""
      );
      param.set(
        "signature[B][title]",
        this.form.get("signature[B][title]").value
      );
      param.set(
        "signature[B][user_id]",
        this.form.get("signature[B][user_id]").value || ""
      );
      param.set(
        "signature[C][title]",
        this.form.get("signature[C][title]").value
      );
      param.set(
        "signature[C][user_id]",
        this.form.get("signature[C][user_id]").value || ""
      );
      //param.set('stamp', (this.form.get('stamp').value != null || this.form.get('stamp').value != undefined) ? this.form.get('stamp').value:'');
      param.set(
        "marks",
        this.form.get("marks").value != null ||
          this.form.get("marks").value != undefined
          ? this.form.get("marks").value.toString()
          : ""
      );
      param.set(
        "reviews",
        this.form.get("reviews").value != null ||
          this.form.get("reviews").value != undefined
          ? this.form.get("reviews").value.toString()
          : ""
      );
      param.set(
        "viewers",
        this.form.get("viewers").value != null ||
          this.form.get("viewers").value != undefined
          ? this.form.get("viewers").value.toString()
          : ""
      );
      if (this.form.get("needAttach").value == "1") {
        this.filesFormGroup.value.forEach((v, k) => {
          param.set("attachments[" + k + "][file]", v.file);
          param.set("attachments[" + k + "][attach_title]", v.attach_title);
          param.set(
            "attachments[" + k + "][attach_with_the_document]",
            v.attach_with_the_document ? "1" : "0"
          );
          param.set(
            "attachments[" + k + "][print_official_paper]",
            v.print_official_paper ? "1" : "0"
          );
        });
      }
      if (this.form.get("needOthers").value == "1") {
        this.othersgroup.value.forEach((v, k) => {
          param.set("other_fields[" + k + "][qror_name_en]", v.qror_name_en);
          param.set("other_fields[" + k + "][qror_name_ar]", v.qror_name_ar);
        });
      }
      if (this.restrictions_fixed_optional_items.length > 0) {
        var t = this.restrictions_fixed_optional_items
          .filter((opt) => opt.checked)
          .map((opt) => opt.value);
        if (t.length > 0) {
          param.set("restrictions_fixed_optional", t.toString());
        }
      }
      this.ds.postActionByUrl(param, "form/FORM_PQ1/cr/0").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            //this.alert.success(res.msg);
            this.alert.success(this.lang.transform("lang_success"));
            this.form.reset();
            this.router.navigate(["/transactions/" + res.url]);
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
    }
  }
  public validstatus() {
    if (
      this.form.get("signature[A][title]").value ||
      this.form.get("signature[A][user_id]").value != null
    ) {
      this.form.get("signature[A][title]").setValidators(Validators.required);
      this.form.get("signature[A][title]").updateValueAndValidity();
      this.form.get("signature[A][user_id]").setValidators(Validators.required);
      this.form.get("signature[A][user_id]").updateValueAndValidity();
    } else {
      this.form.get("signature[A][title]").setValue("");
      this.form.get("signature[A][title]").clearValidators();
      this.form.get("signature[A][title]").updateValueAndValidity();
      this.form.get("signature[A][user_id]").setValue(null);
      this.form.get("signature[A][user_id]").clearValidators();
      this.form.get("signature[A][user_id]").updateValueAndValidity();
    }
    if (
      this.form.get("signature[B][title]").value ||
      this.form.get("signature[B][user_id]").value != null
    ) {
      this.form.get("signature[B][title]").setValidators(Validators.required);
      this.form.get("signature[B][title]").updateValueAndValidity();
      this.form.get("signature[B][user_id]").setValidators(Validators.required);
      this.form.get("signature[B][user_id]").updateValueAndValidity();
    } else {
      this.form.get("signature[B][title]").setValue("");
      this.form.get("signature[B][title]").clearValidators();
      this.form.get("signature[B][title]").updateValueAndValidity();
      this.form.get("signature[B][user_id]").setValue(null);
      this.form.get("signature[B][user_id]").clearValidators();
      this.form.get("signature[B][user_id]").updateValueAndValidity();
    }
    if (
      this.form.get("signature[C][title]").value ||
      this.form.get("signature[C][user_id]").value != null
    ) {
      this.form.get("signature[C][title]").setValidators(Validators.required);
      this.form.get("signature[C][title]").updateValueAndValidity();
      this.form.get("signature[C][user_id]").setValidators(Validators.required);
      this.form.get("signature[C][user_id]").updateValueAndValidity();
    } else {
      this.form.get("signature[C][title]").setValue("");
      this.form.get("signature[C][title]").clearValidators();
      this.form.get("signature[C][title]").updateValueAndValidity();
      this.form.get("signature[C][user_id]").setValue(null);
      this.form.get("signature[C][user_id]").clearValidators();
      this.form.get("signature[C][user_id]").updateValueAndValidity();
    }
    if (
      !this.form.get("signature[A][title]").value &&
      !this.form.get("signature[B][title]").value &&
      !this.form.get("signature[C][title]").value
    ) {
      this.form.get("signature[A][title]").setValidators(Validators.required);
      this.form.get("signature[A][title]").updateValueAndValidity();
      this.form.get("signature[A][user_id]").setValidators(Validators.required);
      this.form.get("signature[A][user_id]").updateValueAndValidity();
    }
    if (this.form.valid) {
      return true;
    } else {
      this.ds.dialogf("", this.lang.transform("lang_need_emp_and_data"));
      return false;
    }
  }
}
