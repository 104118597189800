<div class="row wafi_header">
  <div class="table-responsive customResponsive">
    <table border="1" class="table wafi_title" width="100%">
      <tbody>
        <tr>
          <td width="130px">
            <img src="assets/images/logoawafi.PNG" alt="" />
          </td>
          <td width="87.5%">
            <div class="title_content">
              {{
                "supervision consultant monthly report for july-2022" | language
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row content_header">
  <div class="col">
    {{ "lang_issue_log" | language }}
  </div>
</div>
<div class="row programme_work">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody>
        <tr>
          <th rowspan="2">{{ "lang_issue_summary" | language }}</th>
          <th class="highlight">{{ "lang_total_no_of_issue" | language }}</th>
          <th class="highlight">{{ "lang_no_of_closed_issue" | language }}</th>
          <th class="highlight">
            {{ "lang_no_of_open_issue" | language }}
          </th>
        </tr>
        <tr>
          <td class="highlight">
            {{ report_Data?.total_issues_counts }}
          </td>
          <td class="highlight">
            {{ no_of_closed_issue }}
          </td>
          <td class="highlight">
            {{ no_of_open_issue }}
          </td>
        </tr>
        <br />
        <tr>
          <th style="background-color: #595959">#</th>
          <th>{{ "lang_issue_discerption" | language }}</th>
          <th>{{ "lang_issue_start_date" | language }}</th>
          <th>
            {{ "lang_type" | language }}
          </th>
          <th>{{ "lang_level" | language }} {{ "lang_level" | language }}</th>
          <th>
            {{ "lang_time_elapsed" | language }}
          </th>
          <th>
            {{ "lang_owner" | language }}
          </th>
          <th>
            {{ "lang_action_by" | language }}
          </th>
          <th>
            {{ "lang_recommended_action" | language }}
          </th>
          <th>
            {{ "lang_expected_closure_date" | language }}
          </th>
          <th>
            {{ "lang_actual_closure_date" | language }}
          </th>
          <th>
            {{ "lang_status" | language }}
          </th>
        </tr>
        <tr *ngFor="let issue of report_Data?.work_area_issues; let i = index">
          <td class="highlight2">{{ i + 1 }}</td>
          <td *ngIf="lang_key == 'ar'">
            {{ issue?.issue_description_ar }}
          </td>
          <td *ngIf="lang_key == 'en'">
            {{ issue?.issue_description_en }}
          </td>
          <td>
            {{ issue?.issue_start_date | language }}
          </td>
          <td>
            {{ "lang_" + issue?.issue_type | language }}
          </td>
          <td>
            {{ issue?.issue_level }}
          </td>
          <td>
            {{ issue?.issue_probability }}
          </td>
          <td class="bg-warning">
            {{ issue?.issue_owner }}
          </td>
          <td>
            {{ issue?.issue_action_by }}
          </td>
          <td>
            {{ issue?.issue_recommended_action }}
          </td>
          <td>{{ issue?.issue_expected_closure_date }}</td>
          <td>{{ issue?.issue_end_date }}</td>
          <td class="bg-success">
            {{ "lang_" + issue?.issue_status | language }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
