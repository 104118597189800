import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-completed-step',
  templateUrl: './completed-step.component.html',
  styleUrls: ['./completed-step.component.scss']
})
export class CompletedStepComponent implements OnInit {
  @Input() t_id:any = ''
  constructor() { }

  ngOnInit(): void {
  }

}
