<div class="card pstr-step1">
	<div class="card-header">
		{{ "lang_configurations" | language }}
		<i
			style="padding: 0 10px"
			class="fa fa-info-circle orangestatus-color"
			matTooltip="{{ 'lang_configurations_tooltip' | language }}"
		></i>
	</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<mat-horizontal-stepper #stepper [linear]="true" class="pad0">
				<mat-step [stepControl]="lang_form">
					<form [formGroup]="lang_form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-building fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_languages" | language }}</div>
						</ng-template>
						<div class="row mb-10">
							<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
								<label>
									{{ "lang_languages" | language }}
									<span class="red">*</span></label
								>
								<select
									class="form-control almnabrformcontrol"
									formControlName="lang_key"
								>
									<option value="">
										{{ "lang_choose_options" | language }}
									</option>
									<option
										*ngFor="let lang of languages"
										value="{{ lang.value }}"
									>
										{{ lang.label }}
									</option>
								</select>
								<mat-error
									*ngIf="
										submitted && lang_form.controls['lang_key'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10 col-sm-8 col-xs-12 mb-10"></div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperNext
								>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>
				<mat-step [stepControl]="canMoveStep2">
					<form [formGroup]="work_sites_form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-building fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_work_site_levels" | language }}</div>
						</ng-template>
						<div class="row p-1">
							<div class="col-sm-12">
								<label class="mb-10"
									>{{ "lang_separate" | language }} :
									<span class="red">*</span>
									<i
										style="padding: 0 10px"
										class="fa fa-info-circle orangestatus-color"
										matTooltip="{{ 'lang_separate_tooltip' | language }}"
									></i>
								</label>
								<div class="col-sm-12">
									<mat-radio-group
										formControlName="separate"
										(change)="
											work_sites_form.controls['work_site'].setValue('')
										"
									>
										<mat-radio-button value="1">{{
											"lang_yes" | language
										}}</mat-radio-button>
										<mat-radio-button value="0">{{
											"lang_no" | language
										}}</mat-radio-button>
									</mat-radio-group>
								</div>
							</div>
						</div>
						<div class="row mb-10 p-1">
							<div class="col-sm-12 col-xs-12 mb-10">
								<label class="mb-10"
									>{{ "lang_work_site" | language }} :
									<span class="red">*</span>
									<i
										style="padding: 0 10px"
										class="fa fa-info-circle orangestatus-color"
										matTooltip="{{ 'lang_work_site_tooltip' | language }}"
									></i>
									<span style="margin: 0 20px" *ngIf="unitsOfTemplate">
										{{ "lang_available_units" | language }} :
										<span
											class="bold"
											matTooltip="{{ unitsOfTemplate }}"
											*ngIf="unitsOfTemplate?.length > 10"
											>{{ unitsOfTemplate | shortenLength: 10 }}</span
										>
										<span class="bold" *ngIf="unitsOfTemplate?.length < 10">{{
											unitsOfTemplate | shortenLength: 10
										}}</span>
									</span>
								</label>
								<div class="">
									<mat-radio-group
										formControlName="work_site"
										(change)="
											get_work_levels();
											set_validation_step1();
											generalNumbersData = [];
											phasesData = []
										"
									>
										<mat-radio-button
											value="ALL"
											*ngIf="work_sites_form.get('separate').value == '0'"
											>{{ "lang_for_all_units" | language }}</mat-radio-button
										>
										<mat-radio-button value="GN">{{
											"lang_by_general_number" | language
										}}</mat-radio-button>
										<mat-radio-button value="IM">{{
											"lang_by_phases" | language
										}}</mat-radio-button>
									</mat-radio-group>
								</div>
								<mat-error
									*ngIf="
										submitted &&
										work_sites_form.controls['work_site'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div
								class="col-sm-6 col-xs-12 mb-10 mt-2"
								*ngIf="work_sites_form.get('work_site').value == 'ALL'"
							>
								<label class="mb-10"
									>{{ "lang_fill_worklevels" | language }} :
									<span class="red">*</span></label
								>

								<ng-select
									[items]="work_levels_for_all"
									[multiple]="true"
									bindLabel="label"
									bindValue="value"
									clearAllText="Clear"
									[searchFn]="ds.customSearchFn"
									appendTo="body"
									[formControl]="work_levels_for_all_control"
									(change)="getUnitsForAll()"
									placeholder="{{ 'lang_fill_worklevels' | language }}"
								>
								</ng-select>
								<mat-error
									*ngIf="work_levels_for_all_control.errors?.required"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div
								class="col-sm-12 mb-2"
								*ngIf="work_sites_form.get('work_site').value == 'GN'"
							>
								<div
									class="table-responsive dropdownyes customResponsive tablefields"
								>
									<table border="1" class="table CustomTable">
										<thead>
											<tr>
												<th>#</th>
												<th>
													<i
														class="fa fa-plus green"
														style="font-size: 15px"
														(click)="addByGeneralNumbers()"
														#tooltip="matTooltip"
														matTooltip="Add"
													></i>
												</th>
												<th>{{ "lang_units" | language }}</th>
												<th>{{ "lang_work_level" | language }}</th>
											</tr>
										</thead>
										<tbody *ngIf="generalNumbersData.length">
											<tr
												*ngFor="
													let item of generalNumbersData;
													let i = index;
													let odd = odd;
													let even = even
												"
											>
												<td>{{ i + 1 }}</td>
												<td>
													<i
														class="fa fa-trash red"
														style="font-size: 15px"
														(click)="deleteByGeneralNumbers(i)"
														#tooltip="matTooltip"
														matTooltip="Delete"
													></i>
												</td>
												<td>{{ item.units }}</td>
												<td>{{ item.displayWorklevels }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div class="row pb-5">
							<div
								class="col-sm-12 mb-2"
								*ngIf="work_sites_form.get('work_site').value == 'IM'"
							>
								<div
									class="table-responsive dropdownyes customResponsive tablefields"
								>
									<table border="1" class="table CustomTable">
										<thead>
											<tr>
												<th>#</th>
												<th>
													<i
														class="fa fa-plus green"
														style="font-size: 15px"
														(click)="addByPhases()"
														#tooltip="matTooltip"
														matTooltip="Add"
													></i>
												</th>
												<th>{{ "lang_zones" | language }}</th>
												<th>{{ "lang_blocks" | language }}</th>
												<th>{{ "lang_clusters" | language }}</th>
												<th>{{ "lang_units" | language }}</th>
												<th>{{ "lang_work_level" | language }}</th>
											</tr>
										</thead>
										<tbody *ngIf="phasesData.length">
											<tr
												*ngFor="
													let item of phasesData;
													let i = index;
													let odd = odd;
													let even = even
												"
											>
												<td>{{ i + 1 }}</td>
												<td>
													<i
														class="fa fa-trash red"
														style="font-size: 15px"
														(click)="deleteByPhases(i)"
														#tooltip="matTooltip"
														matTooltip="Delete"
													></i>
												</td>
												<td>{{ item.zone }}</td>
												<td>{{ item.block }}</td>
												<td>{{ item.cluster }}</td>
												<td>{{ item.units }}</td>
												<td>{{ item.displayWorklevels }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperPrevious
								>
									{{ "lang_previous" | language }}
								</button>
							</div>
							<div class="col-md-8 col-sm-4 col-xs-12 mb-10"></div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									(click)="
										send_units_and_work_levels(false); get_form_versions()
									"
								>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>

				<mat-step>
					<form>
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-window-restore fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_form_version" | language }}</div>
						</ng-template>
						<div class="row pt-3 pb-3">
							<div class="col-md-12">
								<div class="table-responsive customResponsive">
									<table border="1" class="table CustomTable">
										<thead>
											<tr>
												<td>#</td>
												<td>{{ "lang_transaction_number" | language }}</td>
												<td>{{ "lang_unit" | language }}</td>
												<td>{{ "lang_work_level" | language }}</td>
												<td>{{ "lang_barcode" | language }}</td>
												<td>{{ "lang_evaluation_result" | language }}</td>
												<td>
													{{ "lang_date" | language }}
												</td>
												<td>{{ "lang_action" | language }}</td>
											</tr>
										</thead>
										<tbody
											*ngIf="versions?.records?.length != 0"
											class="AppendList"
										>
											<tr
												*ngFor="
													let row of versions?.records;
													let i = index;
													let odd = odd;
													let even = even
												"
												[ngClass]="{ odd: odd, even: even }"
											>
												<td>{{ i + 1 }}</td>
												<td>{{ row.transaction_request_id }}</td>
												<td>{{ row.unit_id }}</td>
												<td>{{ row.levelname }}</td>
												<td *ngIf="row.tbv_barcodeData">
													{{ row.tbv_barcodeData }}
												</td>
												<td *ngIf="!row.tbv_barcodeData">----</td>
												<td
													*ngIf="row.result_code"
													[ngClass]="{
														redstatus: row.color == 'RED',
														greenstatus: row.color == 'GREEN'
													}"
												>
													{{ row.result_code }}
												</td>
												<td *ngIf="!row.result_code">----</td>
												<td>{{ row.transactions_date_datetime }}</td>
												<td *ngIf="row.file_path">
													<i
														class="fa fa-icon mnabricon fa-file-pdf-o"
														(click)="
															ds.preview_pdf_file(
																row.file_path,
																row?.transaction_request_id
															)
														"
													></i>
												</td>
												<td *ngIf="!row.file_path">----</td>
											</tr>
										</tbody>
										<tbody
											*ngIf="
												versions?.records?.length == 0 || !versions?.records
											"
											class="AppendList"
										>
											<tr class="odd">
												<td colspan="8" class="nodata" align="center">
													{{ nodata }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div
							*ngIf="versions?.records?.length > 0"
							class="mb-10 custom-footer"
						>
							<div class="row responsive_pagination">
								<div
									class="col-lg-3 col-sm-3 mb-10"
									style="height: fit-content; margin: auto 0"
								>
									{{ "lang_total" | language }} :
									{{
										versions?.page?.total_records
											? versions?.page?.total_records
											: 0
									}}
								</div>
								<div
									class="col-lg-6 col-sm-6 mb-10"
									style="display: flex; justify-content: center"
								>
									<ngb-pagination
										class="m-auto"
										[collectionSize]="versions?.page?.total_records"
										[rotate]="true"
										[ellipses]="false"
										[maxSize]="3"
										[boundaryLinks]="true"
										[(page)]="versions_page_no"
										[pageSize]="versions_page_size"
										(pageChange)="load_by_page_no($event, 'versions')"
									></ngb-pagination>

									<!-- <mat-radio-group aria-label="Select an option">
										<mat-radio-button
											value="10"
											[checked]="versions_page_size == '10' ? true : false"
											(click)="load_by_page_size(10, 'versions')"
										>
											10</mat-radio-button
										>
										<mat-radio-button
											value="20"
											[checked]="versions_page_size == '20' ? true : false"
											(click)="load_by_page_size(20, 'versions')"
										>
											20
										</mat-radio-button>
										<mat-radio-button
											value="50"
											[checked]="versions_page_size == '50' ? true : false"
											(click)="load_by_page_size(50, 'versions')"
										>
											50
										</mat-radio-button>
										<mat-radio-button
											value="100"
											[checked]="versions_page_size == '100' ? true : false"
											(click)="load_by_page_size(100, 'versions')"
										>
											100
										</mat-radio-button>
										<mat-radio-button
											value="500"
											[checked]="versions_page_size == '500' ? true : false"
											(click)="load_by_page_size(500, 'versions')"
										>
											500
										</mat-radio-button>
									</mat-radio-group> -->
								</div>
								<div
									class="col-lg-3 col-sm-3"
									style="display: flex; justify-content: end; margin: auto"
								>
									<!-- 	{{ "lang_goto" | language }} :
									<select
										class="customselect"
										(change)="load_by_page_no($event.target.value, 'versions')"
									>
										<option
											*ngFor="
												let ks of ds.getrange(versions?.page?.total_pages)
											"
											[selected]="versions_page_no == ks ? true : false"
											value="{{ ks }}"
										>
											{{ ks }}
										</option>
									</select> -->

									<mat-select
										style="max-width: 150px"
										[(ngModel)]="versions_page_size"
										(selectionChange)="
											load_by_page_size($event.value, 'versions')
										"
									>
										<!-- <mat-option value="1">1  </mat-option> -->
										<mat-option class="text-center" value="10"
											>10 {{ "lang_per_page" | language }}
										</mat-option>
										<mat-option class="text-center" value="20"
											>20 {{ "lang_per_page" | language }}
										</mat-option>
										<mat-option class="text-center" value="50"
											>50 {{ "lang_per_page" | language }}
										</mat-option>
										<mat-option class="text-center" value="100"
											>100 {{ "lang_per_page" | language }}</mat-option
										>
										<mat-option class="text-center" value="500"
											>500 {{ "lang_per_page" | language }}</mat-option
										>
									</mat-select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperPrevious
								>
									{{ "lang_previous" | language }}
								</button>
							</div>
							<div class="col-md-8 col-sm-4 col-xs-12 mb-10"></div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperNext
									(click)="moveToPlatform()"
								>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>

				<mat-step [stepControl]="canMoveStep3">
					<form>
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-code fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_platforms" | language }}</div>
						</ng-template>
						<div class="row pt-3 pb-3">
							<div class="col-md-12">
								<p class="mb-10">
									{{
										"lang_related_platform_access_codes_requirements" | language
									}}
									:
									<span
										class="red bold"
										*ngIf="platformData.length != 0 && !platformData.NextButton"
										>{{ "lang_platform_message" | language }} :
										<!-- Sorry. Some platform Requirements does not have qualified
										Evaluation Result. Please Kindly Fix the Required Platforms.
										Remaining -->
										{{
											platformData.Rule.all_platforms_required_result_fail
										}}</span
									>
								</p>
								<div class="table-responsive customResponsive">
									<table border="1" class="table CustomTable">
										<thead>
											<tr>
												<td>#</td>
												<td>{{ "lang_unit" | language }}</td>
												<td>{{ "lang_work_level" | language }}</td>
												<td>{{ "lang_platform_access_codes" | language }}</td>
												<td>{{ "lang_barcode" | language }}</td>
												<td>{{ "lang_evaluation_result" | language }}</td>
												<td>{{ "lang_action" | language }}</td>
											</tr>
										</thead>
										<tbody
											*ngIf="platformData?.records?.length != 0"
											class="AppendList"
										>
											<tr
												*ngFor="
													let row of platformData?.records;
													let i = index;
													let odd = odd;
													let even = even
												"
												[ngClass]="{ odd: odd, even: even }"
											>
												<td>{{ i + 1 }}</td>
												<td>{{ row.unit }}</td>
												<td>{{ row.work_level_label }}</td>
												<td>
													{{ row.platform_label }} ({{ row.required_platform }})
												</td>
												<td *ngIf="row.barcode">{{ row.barcode }}</td>
												<td *ngIf="!row.barcode">----</td>
												<td
													*ngIf="row.result_code"
													style="font-weight: 600; font-size: 16px"
													[ngClass]="{
														redstatus: row.color == 'RED',
														greenstatus: row.color == 'GREEN'
													}"
												>
													{{ row.result_code }}
												</td>
												<td *ngIf="!row.result_code">----</td>
												<td *ngIf="row.file_path">
													<i
														class="fa fa-icon mnabricon fa-file-pdf-o"
														(click)="
															ds.preview_pdf_file(row.file_path, row?.unit)
														"
													></i>
												</td>
												<td *ngIf="!row.file_path">----</td>
											</tr>
										</tbody>
										<tbody
											*ngIf="
												platformData?.records?.length == 0 ||
												!platformData?.records
											"
											class="AppendList"
										>
											<tr class="odd">
												<td colspan="7" class="nodata" align="center">
													{{ nodata }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div
							*ngIf="platformData?.records?.length > 0"
							class="mb-10 custom-footer"
						>
							<div class="row responsive_pagination">
								<div
									class="col-lg-3 col-sm-3 mb-10"
									style="height: fit-content; margin: auto 0"
								>
									{{ "lang_total" | language }} :
									{{
										platformData?.page?.total_records
											? platformData?.page?.total_records
											: 0
									}}
								</div>
								<div
									class="col-lg-6 col-sm-6 mb-10"
									style="display: flex; justify-content: center"
								>
									<ngb-pagination
										class="m-auto"
										[collectionSize]="platformData?.page?.total_records"
										[rotate]="true"
										[ellipses]="false"
										[maxSize]="3"
										[boundaryLinks]="true"
										[(page)]="platform_page_no"
										[pageSize]="load_by_page_size"
										(pageChange)="load_by_page_no($event, 'platform')"
									></ngb-pagination>

									<!-- <mat-radio-group aria-label="Select an option">
										<mat-radio-button
											value="10"
											[checked]="platform_page_size == '10' ? true : false"
											(click)="load_by_page_size(10, 'platform')"
										>
											10</mat-radio-button
										>
										<mat-radio-button
											value="20"
											[checked]="platform_page_size == '20' ? true : false"
											(click)="load_by_page_size(20, 'platform')"
										>
											20
										</mat-radio-button>
										<mat-radio-button
											value="50"
											[checked]="platform_page_size == '50' ? true : false"
											(click)="load_by_page_size(50, 'platform')"
										>
											50
										</mat-radio-button>
										<mat-radio-button
											value="100"
											[checked]="platform_page_size == '100' ? true : false"
											(click)="load_by_page_size(100, 'platform')"
										>
											100
										</mat-radio-button>
										<mat-radio-button
											value="500"
											[checked]="platform_page_size == '500' ? true : false"
											(click)="load_by_page_size(500, 'platform')"
										>
											500
										</mat-radio-button>
									</mat-radio-group> -->
								</div>
								<div
									class="col-lg-3 col-sm-3"
									style="display: flex; justify-content: end; margin: auto"
								>
									<!-- {{ "lang_goto" | language }} :
									<select
										class="customselect"
										(change)="load_by_page_no($event.target.value)"
									>
										<option
											*ngFor="
												let ks of ds.getrange(platformData?.page?.total_pages)
											"
											[selected]="platform_page_no == ks ? true : false"
											value="{{ ks }}"
										>
											{{ ks }}
										</option>
									</select> -->

									<mat-select
										style="max-width: 150px"
										[(ngModel)]="platform_page_size"
										(selectionChange)="
											load_by_page_size($event.value, 'platform')
										"
									>
										<!-- <mat-option value="1">1  </mat-option> -->
										<mat-option class="text-center" value="10"
											>10 {{ "lang_per_page" | language }}
										</mat-option>
										<mat-option class="text-center" value="20"
											>20 {{ "lang_per_page" | language }}
										</mat-option>
										<mat-option class="text-center" value="50"
											>50 {{ "lang_per_page" | language }}
										</mat-option>
										<mat-option class="text-center" value="100"
											>100 {{ "lang_per_page" | language }}</mat-option
										>
										<mat-option class="text-center" value="500"
											>500 {{ "lang_per_page" | language }}</mat-option
										>
									</mat-select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperPrevious
								>
									{{ "lang_previous" | language }}
								</button>
							</div>
							<div class="col-md-8 col-sm-4 col-xs-12 mb-10"></div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									*ngIf="platformData.length != 0 && platformData?.NextButton"
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperNext
								>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>
				<mat-step [stepControl]="config_attachment_form">
					<form [formGroup]="config_attachment_form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-paperclip fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_attachments" | language }}</div>
						</ng-template>
						<div class="row pt-4" *ngIf="user_type == '3' || user_type == '4'">
							<div class="col-12 col-sm-6">
								<label class="mb-10"
									>{{ "lang_contractor_team_users" | language }} :
									<span class="red">*</span>
								</label>
								<ng-select
									[items]="contractor_team_users_list"
									[multiple]="true"
									bindLabel="label"
									bindValue="value"
									clearAllText="Clear"
									[searchFn]="ds.customSearchFn"
									appendTo="body"
									formControlName="contractor_team_users"
									placeholder="{{ 'lang_contractor_team_users' | language }}"
								>
								</ng-select>
								<mat-error
									*ngIf="
										config_attachment_form.get('contractor_team_users').errors
											?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-12 col-sm-6">
								<label class="mb-10"
									>{{ "lang_contractor_manager_step_require" | language }} :
									<span class="red">*</span>
								</label>
								<br />
								<mat-radio-group
									formControlName="contractor_manager_step_require"
								>
									<mat-radio-button value="1">{{
										"lang_yes" | language
									}}</mat-radio-button>
									<mat-radio-button value="0">{{
										"lang_no" | language
									}}</mat-radio-button>
								</mat-radio-group>
							</div>
						</div>
						<div class="row pt-4">
							<div class="col-md-12 TableParts">
								<p class="mb-10">
									{{ "lang_attachments" | language }}
									<span class="red" *ngIf="other_attachments?.length">*</span>
								</p>
								<div
									class="table-responsive customResponsive tablefields quotationitemtable"
								>
									<table
										border="1"
										class="table CustomTable"
										width="100%"
										formArrayName="attachments"
									>
										<thead>
											<tr>
												<td
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													#
												</td>
												<td
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													<mat-icon
														class="mnabricon"
														matTooltip="{{ 'lang_add' | language }}"
														(click)="addfiles()"
														>add_box</mat-icon
													>
												</td>
												<td
													width="48%"
													style="padding: 5px; word-break: break-all"
												>
													{{ "lang_title" | language }}
												</td>
												<td
													width="48%"
													style="padding: 5px; word-break: break-all"
												>
													{{ "lang_file" | language }}
												</td>
											</tr>
										</thead>
										<tbody>
											<tr
												*ngFor="
													let file of filesFormGroup.controls;
													let i = index;
													let odd = odd;
													let even = even
												"
												[formGroupName]="i"
											>
												<td
													class="verticalalignmid"
													style="padding: 5px; word-break: break-all"
												>
													{{ i + 1 }}
												</td>
												<td class="verticalalignmid">
													<mat-icon
														*ngIf="
															!other_attachments.controls[i].get('delete_icon')
																.value
														"
														(click)="removefiles(i)"
														class="mnabricon red"
														matTooltip="{{ 'lang_delete' | language }}"
														>delete_forever</mat-icon
													>
												</td>
												<td
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<input
														type="text"
														formControlName="attach_title"
														placeholder="{{ 'lang_description' | language }}"
														autocomplete="off"
														class="form-control almnabrformcontrol"
													/>
												</td>
												<td
													class="verticalalignmid"
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<input
														name="file"
														type="file"
														required
														onclick="this.value = null"
														(change)="
															ds.uploadSingleFile(
																$event,
																other_attachments.controls[i].get('file'),
																'file' + i,
																filename
															)
														"
														class="nghide albutton width100"
													/>
													<label id="file{{ i }}" class="albutton">{{
														filename
													}}</label>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<br />
						<div class="row">
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperPrevious
								>
									{{ "lang_previous" | language }}
								</button>
							</div>
							<div class="col-md-8 col-sm-4 col-xs-12 mb-10"></div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									(click)="send_files_to_step2()"
								>
									{{ "lang_submit" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>
			</mat-horizontal-stepper>
		</div>
	</div>
</div>
