<breadcrumb></breadcrumb>
<mat-accordion>
	<mat-expansion-panel [expanded]="true">
		<mat-expansion-panel-header>{{
			"lang_settings" | language
		}}</mat-expansion-panel-header>
		<div class="material-panel-body">
			<div class="mbrl15 mb-0">
				<div class="row">
					<div class="col-xs-12 col-12 pad0">
						<mat-horizontal-stepper #stepper class="pad0">
							<mat-step>
								<ng-template matStepLabel>
									<div class="step">
										<div class="st">
											<i class="icon-5x fa fa-users fa-icon"></i>
										</div>
									</div>
									<div>{{ "lang_group" | language }}</div>
								</ng-template>
								<app-groups></app-groups>
							</mat-step>
							<mat-step>
								<ng-template matStepLabel>
									<div class="step">
										<div class="st">
											<i class="icon-5x fa fa-list fa-icon"></i>
										</div>
									</div>
									<div>{{ "lang_constants" | language }}</div>
								</ng-template>
								<app-constants></app-constants>
							</mat-step>
							<mat-step>
								<ng-template matStepLabel>
									<div class="step">
										<div class="st">
											<i class="icon-5x fa fa-users fa-icon"></i>
										</div>
									</div>
									<div>{{ "lang_permit_mentions" | language }}</div>
								</ng-template>
								<app-permissions></app-permissions>
							</mat-step>
							<mat-step>
								<ng-template matStepLabel>
									<div class="step">
										<div class="st">
											<i class="icon-5x fa fa-users fa-icon"></i>
										</div>
									</div>
									<div>{{ "lang_external_persons" | language }}</div>
								</ng-template>
								<app-external-persons></app-external-persons>
							</mat-step>
						</mat-horizontal-stepper>
					</div>
				</div>
			</div>
		</div>
	</mat-expansion-panel>
</mat-accordion>
