import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AvailableUnitsComponent } from "../initial-delivery/initial-delivery-list/available-units/available-units.component";
import { SnagNoteAddComponent } from "./snag-note-add/snag-note-add.component";
import { SnagNoteConfirmModalComponent } from "./snag-note-confirm-modal/snag-note-confirm-modal.component";

@Component({
	selector: "app-snag-note-operations",
	templateUrl: "./snag-note-operations.component.html",
	styleUrls: ["./snag-note-operations.component.scss"],
})
export class SnagNoteOperationsComponent implements OnInit {
	searchKey = "";
	@Input() supervision_data: any;
	@Input() able2add: any;
	@Input() able2edit: any;
	@Input() project_data: any;
	@Input() makeActionOperations: any;
	contractor_reservation: boolean;
	system_team_reservation: boolean;
	lang_key = localStorage.getItem("lang_key") || "en";
	snagNoteList = [];
	page_no = 1;
	page_size = 10;
	collection_size;
	user_type_id = this.ls.getItem("user_type_id");
	reserve_transaction = false;
	showFilter = false;
	isMob = false;
	filterForm: FormGroup;
	unitList = [];
	levels_arr = [];
	group1List = [];
	group2List = [];
	zones = [];
	blocks = [];
	clusters = [];
	typeList = [];
	templateList = [];
	notesForReserve = [];
	snagStatus = [];
	constructor(
		private spinner: PreloaderService,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private dialog: MatDialog,
		private ls: LocalStorage,
		private fb: FormBuilder
	) {
		this.snagStatus = [
			{
				label: this.lang.transform("lang_writer_verification"),
				value: "writer_verification",
			},
			{
				label: this.lang.transform("lang_manager_verification"),
				value: "manager_verification",
			},
			{
				label: this.lang.transform("lang_contractor_receiving_waiting"),
				value: "contractor_receiving_waiting",
			},
			{
				label: this.lang.transform("lang_contractor_reserved"),
				value: "contractor_reserved",
			},
			{
				label: this.lang.transform("lang_almnabr_team"),
				value: "almnabr_team",
			},
			{
				label: this.lang.transform("lang_almnabr_team_reserved"),
				value: "almnabr_team_reserved",
			},
			{
				label: this.lang.transform("lang_De_Snag_M"),
				value: "De-Snag-M",
			},
			{
				label: this.lang.transform("lang_completed"),
				value: "completed",
			},
		];
	}
	ngOnInit(): void {
		if (window.innerWidth < 600) {
			this.isMob = true;
		} else {
			this.isMob = false;
		}
		this.filterForm = this.fb.group({
			general_number: null,
			note_lists_id: null,
			snag_status: null,
			platform_work_level_key: null,
			snag_request_id: null,
			template_id: null,
			platform_group1_code_system: null,
			group_type_id: null,
			platform_group2_code_system: null,
			phase_zone_no: null,
			phase_zone_block_no: null,
			phase_zone_block_cluster_no: null,
		});
		this.getWorkLevels();
		this.get_zones();
		this.getTemplateList();
		this.getSnagNoteList();
	}
	createUpdateSnagNote() {
		const dialogRef = this.dialog.open(SnagNoteAddComponent, {
			width: "1200px",
			data: {
				projects_profile_id: this.project_data?.projects_profile_id,
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
				projects_supervision_id: this.supervision_data?.projects_supervision_id,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.getSnagNoteList();
			}
		});
	}

	getSnagNoteList() {
		this.spinner.show();
		let param = new FormData();
		param.append("projects_profile_id", this.project_data?.projects_profile_id);
		param.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id
		);
		param.append(
			"projects_supervision_id",
			this.supervision_data?.projects_supervision_id
		);
		param.append("lang_key", this.lang_key || "");
		param.append(
			"general_number",
			this.filterForm.get("general_number")?.value || ""
		);
		param.append(
			"note_lists_id",
			this.filterForm.get("note_lists_id")?.value || ""
		);
		param.append(
			"snag_status",
			this.filterForm.get("snag_status")?.value || ""
		);
		param.append(
			"platform_work_level_key",
			this.filterForm.get("platform_work_level_key")?.value || ""
		);
		param.append(
			"snag_request_id",
			this.filterForm.get("snag_request_id")?.value || ""
		);
		param.append(
			"platform_group1_code_system",
			this.filterForm.get("platform_group1_code_system")?.value || ""
		);
		param.append(
			"platform_group2_code_system",
			this.filterForm.get("platform_group2_code_system")?.value || ""
		);
		param.append(
			"phase_zone_no",
			this.filterForm.get("phase_zone_no")?.value?.phase_zone_no || ""
		);
		param.append(
			"phase_zone_block_no",
			this.filterForm.get("phase_zone_block_no")?.value?.phase_zone_block_no ||
				""
		);
		param.append(
			"phase_zone_block_cluster_no",
			this.filterForm.get("phase_zone_block_cluster_no")?.value
				?.phase_zone_block_cluster_no || ""
		);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(param),
				`p/snag/main_unit_lists/${this.page_no}/${this.page_size}`
			)
			.subscribe(
				(res: any) => {
					this.spinner.hide();
					if (res.status) {
						this.snagNoteList = res.records.map((el) => {
							return { ...el, expanded: false, snag_note_list: [] };
						});
						this.collection_size = res?.page?.total_records;
						this.contractor_reservation =
							res?.reservation?.contractor_reservation;
						this.system_team_reservation =
							res?.reservation?.system_team_reservation;
					} else {
						this.collection_size = 0;
						this.snagNoteList = [];
						this.contractor_reservation =
							res?.reservation?.contractor_reservation;
						this.system_team_reservation =
							res?.reservation?.system_team_reservation;
						if (
							res?.reservation?.contractor_reservation ||
							res?.reservation?.system_team_reservation
						) {
							this.getNotesForReserve();
						}
					}
				},
				(error) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
	expandUnit(unit, unit_index) {
		if (this.snagNoteList[unit_index].snag_note_list.length == 0) {
			this.getSnagNoteListByUnit(unit, unit_index);
		} else {
			this.snagNoteList[unit_index].expanded =
				!this.snagNoteList[unit_index].expanded;
		}
	}

	getSnagNoteListByUnit(unit, unit_index) {
		this.spinner.show();
		let param = new FormData();
		param.append("projects_profile_id", this.project_data?.projects_profile_id);
		param.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id
		);
		param.append(
			"projects_supervision_id",
			this.supervision_data?.projects_supervision_id
		);
		param.append("team_final_id", unit?.team_final_id || "");
		param.append("general_number", unit?.unit_id || "");
		param.append("snag_status", unit?.status || "");
		param.append("lang_key", "");
		param.append("search_key", "");
		param.append("platform_group1_code_system", "");
		param.append("platform_group2_code_system", "");
		param.append("note_lists_id", "");
		param.append("platform_work_level_key", "");
		param.append("phase_zone_no", "");
		param.append("phase_zone_block_no", "");
		param.append("phase_zone_block_cluster_no", "");
		this.ds
			.getActionByUrl(
				this.ds.formData2string(param),
				`p/snag/main_unit_action_lists/${this.page_no}/${this.page_size}`
			)
			.subscribe(
				(res: any) => {
					this.spinner.hide();
					if (res.status) {
						this.snagNoteList[unit_index].snag_note_list = res.records;
						this.snagNoteList[unit_index].expanded = true;
					} else {
						this.sweetAlert.errorToast(res.error, 2000);
					}
				},
				(error) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}

	deleteUnit(team_final_id) {
		this.sweetAlert
			.confirmMessage(
				this.lang.transform("lang_are_you_sure"),
				this.lang.transform("lang_yes")
			)
			.then((confirmRes) => {
				if (confirmRes.isConfirmed) {
					this.spinner.show();
					this.ds
						.delete(
							`p/snag/deletesnagwriters/${this.project_data?.projects_profile_id}/${this.supervision_data?.projects_work_area_id}/${team_final_id}`
						)
						.subscribe(
							(res: any) => {
								this.spinner.hide();
								if (res?.status) {
									this.sweetAlert.successToast(
										this.lang.transform("lang_deleted"),
										2000
									);
									this.getSnagNoteList();
								} else {
									this.sweetAlert.errorToast(res.error, 2000);
								}
							},
							(error) => {
								this.spinner.hide();
								this.sweetAlert.errorToast(
									this.lang.transform("lang_internal_server_error"),
									2000
								);
							}
						);
				}
			});
	}

	deleteSnagNote(snag_note_id) {
		this.sweetAlert
			.confirmMessage(
				this.lang.transform("lang_are_you_sure"),
				this.lang.transform("lang_yes")
			)
			.then((confirmRes) => {
				if (confirmRes.isConfirmed) {
					this.spinner.show();
					this.ds
						.deleteActionByUrl(
							[snag_note_id],
							`p/snag/snagnotedeleteadmin/${this.project_data?.projects_profile_id}/${this.supervision_data?.projects_work_area_id}`
						)
						.subscribe(
							(res: any) => {
								this.spinner.hide();
								if (res?.status) {
									this.sweetAlert.successToast(
										this.lang.transform("lang_deleted"),
										2000
									);
									this.getSnagNoteList();
								} else {
									this.sweetAlert.errorToast(res.error, 2000);
								}
							},
							(error) => {
								this.spinner.hide();
								this.sweetAlert.errorToast(
									this.lang.transform("lang_internal_server_error"),
									2000
								);
							}
						);
				}
			});
	}

	downloadNoteAttachment(note) {
		this.spinner.show();
		this.ds.get(note?.file_path).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.ds.downloadFile(res, note?.notename + "_file");
				} else {
					this.sweetAlert.errorMessage(res?.error);
				}
			},
			(err) => {
				this.sweetAlert.errorMessage(err?.error?.error);
				this.spinner.hide();
			}
		);
	}

	confirmStep(note, unit, unit_index) {
		const dialogRef = this.dialog.open(SnagNoteConfirmModalComponent, {
			width: "500px",
			minHeight: "200px",
			autoFocus: false,
			data: {
				note: note,
				projects_profile_id: this.project_data?.projects_profile_id,
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
				projects_supervision_id: this.supervision_data?.projects_supervision_id,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.getSnagNoteList();
			}
		});
	}
	getNotesForReserve() {
		this.spinner.show();
		this.ds
			.get(
				`p/snag/getreservationnotes/${this.project_data?.projects_profile_id}/${this.supervision_data?.projects_work_area_id}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.notesForReserve = res?.records;
					} else {
						this.notesForReserve = [];
					}
				},
				(err) => {
					this.spinner.hide();
					this.notesForReserve = [];
				}
			);
	}
	reserveAction() {
		if (this.reserve_transaction == true) {
			let formData = new FormData();
			formData.append(
				"projects_profile_id",
				this.project_data?.projects_profile_id
			);
			formData.append(
				"projects_work_area_id",
				this.supervision_data?.projects_work_area_id
			);
			this.spinner.show();
			let url = "";
			if (this.contractor_reservation) {
				url = "p/snag/contractor_reservation";
			} else if (this.system_team_reservation) {
				url = "p/snag/almnabrteam_reservation";
			}
			this.ds.postActionByUrl(formData, url).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetAlert.successToast(res?.msg, 2000);
						this.getSnagNoteList();
						this.reserve_transaction = false;
					} else {
						this.sweetAlert.errorToast(res.error, 2000);
					}
				},
				(error) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
		} else {
			this.sweetAlert.errorToast(
				this.lang.transform("lang_fill_required_fields"),
				2000
			);
		}
	}

	toggleFilter() {
		this.showFilter = !this.showFilter;
	}
	resetFilterForm() {
		this.filterForm.reset();
		this.getSnagNoteList();
	}
	getWorkLevels() {
		let param = new FormData();
		param.append("lang_key", this.lang_key);
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "lpworklevel")
			.subscribe((res) => {
				if (res?.status) {
					this.levels_arr = res?.records;
				} else {
					this.levels_arr = [];
				}
			});
	}
	get_unit_general_List(event) {
		this.spinner.show();
		let formData = new FormData();
		formData.append(
			"projects_profile_id",
			this.project_data?.projects_profile_id || ""
		);
		formData.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id || ""
		);
		formData.append(
			"projects_supervision_id",
			this.supervision_data?.projects_supervision_id || ""
		);
		formData.append("lang_key", this.lang_key || "");
		formData.append("search_key", event?.term || "");
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"p/snag/snaglistnotesunits"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.unitList = res?.records;
					} else {
						this.unitList = [];
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.unitList = [];
					this.sweetAlert.errorToast(
						err && err.error && err.error.error
							? err.error.error
							: this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
	get_zones() {
		this.spinner.show();
		let phaseId = "0",
			param = new FormData();
		param.append("phase_parent_id", phaseId);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(param),
				"joYF29rbEi/" +
					this.project_data?.projects_profile_id +
					"/" +
					this.supervision_data?.projects_supervision_id
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.zones = res?.records;
				}
			});
	}

	get_blocks() {
		let phaseId = this.filterForm.get("phase_zone_no").value?.phase_id;
		if (phaseId) {
			this.spinner.show();
			let param = new FormData();
			param.append("phase_parent_id", phaseId);
			this.ds
				.getActionByUrl(
					this.ds.formData2string(param),
					"joYF29rbEi/" +
						this.project_data?.projects_profile_id +
						"/" +
						this.supervision_data?.projects_supervision_id
				)
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.blocks = res?.records;
					} else {
						this.blocks = [];
					}
				});
		} else {
			this.blocks = [];
			this.filterForm.get("phase_zone_no").setValue(null);
			this.clusters = [];
			this.filterForm.get("phase_zone_block_cluster_no").setValue(null);
		}
	}

	get_clusters() {
		let phaseId = this.filterForm.get("phase_zone_block_no").value?.phase_id;
		if (phaseId) {
			this.spinner.show();
			let param = new FormData();
			param.append("phase_parent_id", phaseId);
			this.ds
				.getActionByUrl(
					this.ds.formData2string(param),
					"joYF29rbEi/" +
						this.project_data?.projects_profile_id +
						"/" +
						this.supervision_data?.projects_supervision_id
				)
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.clusters = res?.records;
					} else {
						this.clusters = [];
					}
				});
		} else {
			this.clusters = [];
		}
	}

	public getTemplateList() {
		this.spinner.show();
		this.templateList = [];
		let formData = new FormData();
		formData.append("lang_key", this.lang_key);
		formData.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id || ""
		);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"p/get_templates_for_transactions"
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.templateList = res.records;
				}
			});
	}

	public load_group1_type_group2_lists(type?) {
		let sendAPI = false;
		if (type == "group1") {
			this.group1List = this.typeList = this.group2List = [];
			if (this.filterForm.get("template_id").value) {
				sendAPI = true;
			}
		} else if (type == "type") {
			this.typeList = this.group2List = [];
			if (
				this.filterForm.get("template_id").value &&
				this.filterForm.get("platform_group1_code_system").value
			) {
				sendAPI = true;
			}
		} else if (type == "group2") {
			this.group2List = [];
			if (
				this.filterForm.get("template_id").value &&
				this.filterForm.get("platform_group1_code_system").value &&
				this.filterForm.get("group_type_id").value
			) {
				sendAPI = true;
			}
		}

		if (sendAPI) {
			this.spinner.show();
			let param = new FormData();
			param.append(
				"projects_work_area_id",
				this.supervision_data?.projects_work_area_id || ""
			);
			param.append(
				"template_id",
				this.filterForm.get("template_id").value || ""
			);
			if (type == "group1") {
				param.append("required_type", "group1");
				param.append("group1", "");
				param.append("type", "");
			}
			if (type == "type") {
				param.append("required_type", "type");
				param.append(
					"group1",
					this.filterForm.get("platform_group1_code_system").value || ""
				);
				param.append("type", "");
			}
			if (type == "group2") {
				param.append("required_type", "group2");
				param.append(
					"group1",
					this.filterForm.get("platform_group1_code_system").value || ""
				);
				param.append("type", this.filterForm.get("group_type_id").value || "");
			}

			this.ds
				.getActionByUrl(
					this.ds.formData2string(param),
					"pforms/get_group1_type_group2"
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							if (type == "group1") {
								this.group1List = res.records;
							} else if (type == "type") {
								this.typeList = res.records;
							} else if (type == "group2") {
								this.group2List = res.records;
							}
						}
					},
					(error) => {
						this.spinner.hide();
					}
				);
		} else {
			if (type == "group1") {
				this.filterForm.get("template_id").setValue(null);
				this.filterForm.get("platform_group1_code_system").setValue(null);
				this.filterForm.get("group_type_id").setValue(null);
				this.filterForm.get("platform_group2_code_system").setValue(null);
			} else if (type == "type") {
				this.filterForm.get("platform_group1_code_system").setValue(null);
				this.filterForm.get("group_type_id").setValue(null);
				this.filterForm.get("platform_group2_code_system").setValue(null);
			} else if (type == "group2") {
				this.filterForm.get("group_type_id").setValue(null);
				this.filterForm.get("platform_group2_code_system").setValue(null);
			}
		}
	}

	acceptedUnacceptedUnits() {
		const dialogRef = this.dialog.open(AvailableUnitsComponent, {
			width: "700px",
			autoFocus: false,
			closeOnNavigation: true,
			data: {
				projects_profile_id: this.project_data?.projects_profile_id,
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
				projects_supervision_id: this.supervision_data?.projects_supervision_id,
			},
		});
	}

	confirmDeSnag(confirm, team_final_id) {
		let formData = new FormData();
		formData.append(
			"projects_profile_id",
			this.project_data?.projects_profile_id
		);
		formData.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id
		);
		formData.append(
			"projects_supervision_id",
			this.supervision_data?.projects_supervision_id
		);
		formData.append("team_final_id", team_final_id);
		formData.append("note_operation_id", "0");
		formData.append("action_status_id", "0");
		formData.append("action_status", confirm);
		if (confirm == "3") {
			formData.append("request_rejection_note", "");
		}
		this.spinner.show();
		this.ds.postActionByUrl(formData, "p/snag/perform_unit_action").subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successToast(res?.msg, 2000);
					this.getSnagNoteList();
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(error) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
}
