<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_platform" | language }}</h4>
		<span (click)="closeModal()"><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_platform_code_system" | language }}
			</p>
			<p style="word-break: break-all">
				<bdi>{{ platformData?.platform_code_system }}</bdi>
			</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">{{ "lang_platform_title" | language }}</p>
			<p style="word-break: break-all">{{ platformData?.platformname }}</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_platform_code_manual" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.platform_code_manual }}
			</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_consultants_requirements" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.consultant_recomendations }}
			</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_authendicate_position" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.authorized_positions }}
			</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_receipient_position" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.receipient_positions }}
			</p>
		</div>

		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_from_consultants" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.consultant_required }}
			</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_start_date" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.start_date }}
			</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_end_date" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.end_date }}
			</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_item_specifications" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.template_platform_item_specifications }}
			</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600; word-break: break-all">
				{{ "lang_related_platform_access_codes_requirements" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.template_platform_requirements_code_systems }}
			</p>
		</div>

		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_invoice_item_quantity" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.quantity }}
			</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_unit_price" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.unit_price }}
			</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_total_price" | language }}
			</p>
			<p style="word-break: break-all">
				{{ platformData?.total_price }}
			</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_unit_ratio" | language }}
			</p>
			<p style="word-break: break-all">{{ platformData?.unit_ratio }} %</p>
		</div>
		<div class="col-sm-4 col-md-3 text-center">
			<p style="font-weight: 600">
				{{ "lang_units" | language }}
			</p>
			<p style="word-break: break-all">{{ platformData?.units }}</p>
		</div>
	</div>
	<div class="row pt-2">
		<div class="col-12">
			<h3>{{ "lang_building_codes" | language }}</h3>
		</div>
		<div class="col-12">
			<div class="table-responsive dropdownyes customResponsive tablefields">
				<table border="1" class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>{{ "lang_label" | language }}</th>
							<th>{{ "lang_value" | language }}</th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="
								let item of building_codes
									| slice
										: (building_codes_page - 1) * building_codes_size
										: building_codes_page * building_codes_size;
								let i = index;
								let odd = odd;
								let even = even
							"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ item?.label }}</td>
							<td>{{ item?.value }}</td>
						</tr>
					</tbody>
				</table>
				<div
				*ngIf="building_codes.length>0"
					class="mt-2"
					style="
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<ngb-pagination
						*ngIf="building_codes.length"
						[collectionSize]="building_codes.length"
						[(page)]="building_codes_page"
						[maxSize]="4"
						[pageSize]="building_codes_size"
						[boundaryLinks]="true"
					></ngb-pagination>
				</div>
			</div>
		</div>
	</div>
	<div class="row pt-2">
		<div class="col-12">
			<h3>{{ "lang_authendicate_position" | language }}</h3>
		</div>
		<div class="col-12">
			<div class="table-responsive dropdownyes customResponsive tablefields">
				<table border="1" class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>{{ "lang_label" | language }}</th>
							<th>{{ "lang_value" | language }}</th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="
								let item of authorized_positions
									| slice
										: (authorized_positions_page - 1) *
												authorized_positions_size
										: authorized_positions_page * authorized_positions_size;
								let i = index;
								let odd = odd;
								let even = even
							"
						>
							<td>{{ i + 1 }}</td>
							<td style="width: 70%">{{ item?.label }}</td>
							<td>{{ item?.value }}</td>
						</tr>
					</tbody>
				</table>
				<div
				*ngIf="authorized_positions.length>0"
					class="mt-2"
					style="
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<ngb-pagination
						*ngIf="authorized_positions.length"
						[collectionSize]="authorized_positions.length"
						[(page)]="authorized_positions_page"
						[maxSize]="4"
						[pageSize]="authorized_positions_size"
						[boundaryLinks]="true"
					></ngb-pagination>
				</div>
			</div>
		</div>
	</div>

	<div class="row pt-2">
		<div class="col-12">
			<h3>{{ "lang_receipient_position" | language }}</h3>
		</div>
		<div class="col-12">
			<div class="table-responsive dropdownyes customResponsive tablefields">
				<table border="1" class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>{{ "lang_label" | language }}</th>
							<th>{{ "lang_value" | language }}</th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="
								let item of receipient_positions
									| slice
										: (receipient_positions_page - 1) *
												receipient_positions_size
										: receipient_positions_page * receipient_positions_size;
								let i = index;
								let odd = odd;
								let even = even
							"
						>
							<td>{{ i + 1 }}</td>
							<td style="width: 70%">{{ item?.label }}</td>
							<td>{{ item?.value }}</td>
						</tr>
					</tbody>
				</table>
				<div
					class="mt-2"
					style="
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<ngb-pagination
						*ngIf="receipient_positions.length"
						[collectionSize]="receipient_positions.length"
						[(page)]="receipient_positions_page"
						[maxSize]="4"
						[pageSize]="receipient_positions_size"
						[boundaryLinks]="true"
					></ngb-pagination>
				</div>
			</div>
		</div>
	</div>

	<div class="row pt-2">
		<div class="col-12">
			<h3>{{ "lang_from_consultants" | language }}</h3>
		</div>
		<div class="col-12">
			<div class="table-responsive dropdownyes customResponsive tablefields">
				<table border="1" class="table">
					<thead>
						<tr>
							<th>#</th>
							<th style="width: 70%">{{ "lang_label" | language }}</th>
							<th>{{ "lang_value" | language }}</th>
							<th>{{ "lang_result" | language }}</th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="
								let item of new_consultant_required_arr
									| slice
										: (consultant_required_page - 1) * consultant_required_size
										: consultant_required_page * consultant_required_size;
								let i = index;
								let odd = odd;
								let even = even
							"
						>
							<td>{{ i + 1 }}</td>
							<td style="width: 70%">{{ item?.label }}</td>
							<td>{{ item?.value }}</td>
							<td>{{ item?.result }}</td>
						</tr>
					</tbody>
				</table>
				<div
					class="mt-2"
					style="
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<ngb-pagination
						*ngIf="consultant_required_arr.length"
						[collectionSize]="consultant_required_arr.length"
						[(page)]="consultant_required_page"
						[maxSize]="4"
						[pageSize]="consultant_required_size"
						[boundaryLinks]="true"
					></ngb-pagination>
				</div>
			</div>
		</div>
	</div>

	<div class="row pt-2">
		<div class="col-12">
			<h3>{{ "lang_consultants_requirements" | language }}</h3>
		</div>
		<div class="col-12">
			<div class="table-responsive dropdownyes customResponsive tablefields">
				<table border="1" class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>{{ "lang_label" | language }}</th>
							<th>{{ "lang_value" | language }}</th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="
								let item of consultant_recommindations
									| slice
										: (consultant_recommindations_page - 1) *
												consultant_recommindations_size
										: consultant_recommindations_page *
												consultant_recommindations_size;
								let i = index;
								let odd = odd;
								let even = even
							"
						>
							<td>{{ i + 1 }}</td>
							<td style="width: 70%">{{ item?.label }}</td>
							<td>{{ item?.value }}</td>
						</tr>
					</tbody>
				</table>
				<div
					class="mt-2"
					style="
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<ngb-pagination
						*ngIf="consultant_recommindations.length"
						[collectionSize]="consultant_recommindations.length"
						[(page)]="consultant_recommindations_page"
						[maxSize]="4"
						[pageSize]="consultant_recommindations_size"
						[boundaryLinks]="true"
					></ngb-pagination>
				</div>
			</div>
		</div>
	</div>
	<div class="row pt-2">
		<div class="col-12">
			<h3>{{ "lang_work_levels" | language }}</h3>
		</div>
		<div class="col-12">
			<div class="table-responsive dropdownyes customResponsive tablefields">
				<table border="1" class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>{{ "lang_work_levels" | language }}</th>
							<th>{{ "lang_value" | language }}</th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="
								let item of work_levels
									| slice
										: (work_levels_page - 1) * work_levels_size
										: work_levels_page * work_levels_size;
								let i = index;
								let odd = odd;
								let even = even
							"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ item?.label }}</td>
							<td>{{ item?.value }}</td>
						</tr>
					</tbody>
				</table>
				<div
					class="mt-2"
					style="
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<ngb-pagination
						*ngIf="work_levels.length"
						[collectionSize]="work_levels.length"
						[(page)]="work_levels_page"
						[maxSize]="4"
						[pageSize]="work_levels_size"
						[boundaryLinks]="true"
					></ngb-pagination>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="closeModal()"
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</mat-dialog-actions>
