<breadcrumb></breadcrumb>
<div class="themesection">
  <div class="container mb-10" class="form" [formGroup]="formId">
    <div class="row d-flex flex-row">
      <div class="col-md-2 col-xs-12 mar0auto"></div>
      <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
        <div *ngIf="branch_id == '0'" class="red pad0 text-center">
          {{ "lang_choose_branch" | language }}
        </div>
        <select
          formControlName="branch_id"
          class="form-control almnabrformcontrol"
          matTooltip="{{ 'lang_branch' | language }}"
          (change)="spinner.show(); selectBrach($event.target.value)"
        >
          <option value="0">
            {{ "lang_choose_options" | language }}
          </option>
          <option *ngFor="let branch of branches" value="{{ branch.id }}">
            {{ branch.title }}
          </option>
        </select>
      </div>
      <div
        class="col-md-4 col-sm-4 col-xs-12 mb-10"
        *ngIf="
          formId.get('branch_id').value && formId.get('branch_id').value != '0'
        "
      >
        <ng-select
          [items]="finances"
          [multiple]="false"
          bindLabel="label"
          bindValue="value"
          appendTo="body"
          clearAllText="Clear"
          formControlName="finance_id"
          placeholder="{{ 'lang_financial_years' | language }}"
          (change)="
            ds.setDate($event, form);
            loadTellerReports();
            selectFinanceYear(finance_id)
          "
        >
        </ng-select>
      </div>
      <div class="col-md-2 col-xs-12 mar0auto"></div>
    </div>
  </div>
  <section class="panel-expansion matborderno">
    <mat-accordion class="accounttrial">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-tab-group
          class="tickets-transactions-tabs"
          [(selectedIndex)]="tt_tabindex"
        >
          <mat-tab>
            <ng-template mat-tab-label>
              <h3 class="tickets-transactions-tab-title">
                {{ "lang_teller_reports" | language }}
              </h3>
            </ng-template>
            <div class="material-panel-body">
              <div class="mbrl15">
                <form
                  class="form"
                  [formGroup]="form"
                  (ngSubmit)="
                    fetch_statements('income');
                    fetch_statements('spend');
                    getTellerReportsTitles()
                  "
                >
                  <div class="row">
                    <div
                      class="col-md-6 col-sm-6 col-xs-12 mb-10"
                      *ngIf="
                        formId.get('finance_id').value &&
                        formId.get('branch_id').value &&
                        formId.get('branch_id').value != '0'
                      "
                    >
                      <div class="form-group">
                        <label
                          >{{ "lang_period_from" | language }} :
                          <span class="red">*</span> </label
                        ><br />
                        <input
                          formControlName="period_from"
                          id="period_from"
                          class="form-control almnabrformcontrol period_from"
                          placeholder="YYYY/MM/DD"
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-6 col-sm-6 col-xs-12 mb-10"
                      *ngIf="
                        formId.get('finance_id').value &&
                        formId.get('branch_id').value &&
                        formId.get('branch_id').value != '0'
                      "
                    >
                      <div class="form-group">
                        <label
                          >{{ "lang_period_to" | language }} :<span class="red"
                            >*</span
                          > </label
                        ><br />
                        <input
                          formControlName="period_to"
                          id="period_to"
                          class="form-control almnabrformcontrol period_to"
                          placeholder="YYYY/MM/DD"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                      <div class="form-group">
                        <label>{{ "lang_group_by" | language }} : </label><br />
                        <ng-select
                          [items]="fromaccounts"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          appendTo="body"
                          clearAllText="Clear"
                          formControlName="group_by"
                          placeholder="{{ 'lang_group_by' | language }}"
                          (change)="getGroupsField($event)"
                        >
                        </ng-select>
                      </div>
                    </div>
                    <div
                      class="col-md-4 col-sm-4 col-xs-12 mb-2"
                      *ngIf="branch_id && branch_id != '0'"
                    >
                      <div class="form-group">
                        <label
                          >{{
                            "lang_transaction_request_type" | language
                          }}
                          : </label
                        ><br />
                        <ng-select
                          [items]="transactionType"
                          [multiple]="false"
                          bindLabel="label"
                          bindValue="value"
                          clearAllText="Clear"
                          appendTo="body"
                          formControlName="transaction_request_type"
                          placeholder="{{
                            'lang_transaction_request_type' | language
                          }}"
                        >
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                      <div class="form-group">
                        <label
                          >{{ "lang_report_type" | language }} :
                          <span class="red">*</span> </label
                        ><br />
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                      <div class="form-group">
                        <mat-radio-group
                          aria-label="Select an option"
                          formControlName="report_type"
                          (change)="report_type(form.get('report_type').value)"
                        >
                          <mat-radio-button value="S">{{
                            "lang_summary" | language
                          }}</mat-radio-button>
                          <mat-radio-button value="D">{{
                            "lang_details" | language
                          }}</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      formId.get('finance_id').value &&
                      formId.get('branch_id').value &&
                      formId.get('branch_id').value != '0'
                    "
                  >
                    <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                      <button type="submit" class="albutton">
                        {{ "lang_submit" | language }}
                      </button>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                      <button
                        type="button"
                        class="albutton"
                        (click)="export_exl_pdf('E')"
                      >
                        {{ "lang_export_excel" | language }}
                      </button>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                      <button
                        type="button"
                        class="albutton"
                        (click)="export_exl_pdf('P')"
                      >
                        {{ "lang_export_pdf" | language }}
                      </button>
                    </div>
                  </div>
                </form>
                <div
                  class="row"
                  *ngIf="
                    datastatus &&
                    formId.get('branch_id').value &&
                    formId.get('branch_id').value != '0'
                  "
                >
                  <div class="col-md-12">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <td>#</td>
                            <td>{{ "lang_date" | language }}</td>
                            <td>{{ "lang_account" | language }}</td>
                            <td>
                              {{ "lang_amount" | language }}
                            </td>
                            <td>
                              {{ "lang_status" | language }}
                            </td>
                          </tr>
                        </thead>
                        <tbody *ngIf="titles.length" class="AppendList">
                          <tr
                            *ngFor="
                              let tran of data;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                            [ngClass]="{ odd: odd, even: even }"
                            class="{{ tran.class }}"
                          >
                            <td>{{ i + 1 }}</td>
                            <td>
                              {{ form.get("period_from").value }} -
                              {{ form.get("period_to").value }}
                            </td>
                            <td>
                              {{ tran.account_masters_code }} ({{
                                tran.account_name
                              }})
                            </td>
                            <td>
                              {{ absolute(tran.total_amount) }}
                            </td>
                            <td>
                              {{ tran.color }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody *ngIf="!titles.length" class="AppendList">
                          <tr class="odd">
                            <td colspan="9" align="center">
                              {{ lodingdatas }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        class="d-flex justify-content-between p-2 printnone"
                        *ngIf="titles?.length"
                      >
                        <ngb-pagination
                          [collectionSize]="titles?.length"
                          [rotate]="true"
                          [ellipses]="false"
                          [maxSize]="3"
                          [boundaryLinks]="true"
                          [(page)]="titlesPage"
                          [pageSize]="titlesPageSize"
                          (pageChange)="refreshLists()"
                        ></ngb-pagination>
                        <label
                          >{{ "lang_total" | language }} :
                          {{ titles?.length }}</label
                        >
                        <select
                          class="customselect"
                          [(ngModel)]="titlesPageSize"
                          (change)="refreshLists()"
                        >
                          <option [ngValue]="10">10</option>
                          <option [ngValue]="20">20</option>
                          <option [ngValue]="50">50</option>
                          <option [ngValue]="100">100</option>
                          <option [ngValue]="200">200</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-3">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <td class="incoming-color">#</td>
                            <td class="incoming-color">
                              {{ "lang_incoming_amount" | language }}
                            </td>
                            <td class="incoming-color center-line">
                              {{ "lang_incoming_statements" | language }}
                            </td>
                            <td class="spend-color">
                              {{ "lang_spend_amount" | language }}
                            </td>
                            <td class="spend-color">
                              {{ "lang_spend_statements" | language }}
                            </td>
                          </tr>
                        </thead>
                        <tbody
                          *ngIf="spendList?.length || incomeList?.length"
                          class="AppendList"
                        >
                          <ng-container
                            *ngFor="
                              let tran of 'x'.repeat(pageSize).split('');
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                          >
                            <tr
                              *ngIf="incomeList[i] || spendList[i]"
                              [ngClass]="{ odd: odd, even: even }"
                              class="{{ tran.class }}"
                            >
                              <td>{{ i + 1 }}</td>
                              <td>
                                <span
                                  *ngIf="
                                    !incomeList[i] ||
                                    (incomeList[i] &&
                                      incomeList[i]?.total_amount == '0.00')
                                  "
                                >
                                  {{ "" }}
                                </span>
                                <span
                                  class="bold green"
                                  *ngIf="
                                    incomeList[i] &&
                                    incomeList[i]?.total_amount != '0.00'
                                  "
                                >
                                  {{ absolute(incomeList[i]?.total_amount) }}
                                </span>
                              </td>
                              <td class="center-line">
                                <span
                                  *ngIf="
                                    !incomeList[i] ||
                                    (incomeList[i] &&
                                      incomeList[i]?.total_amount == '0.00')
                                  "
                                >
                                  {{ "" }}
                                </span>
                                <span
                                  class="bold green"
                                  *ngIf="
                                    incomeList[i] &&
                                    incomeList[i]?.total_amount != '0.00'
                                  "
                                >
                                  {{
                                    incomeList[i]
                                      ?.transaction_history_description
                                  }}
                                </span>
                              </td>
                              <td>
                                <span
                                  *ngIf="
                                    !spendList[i] ||
                                    (spendList[i] &&
                                      spendList[i]?.total_amount == '0.00')
                                  "
                                >
                                  {{ "" }}
                                </span>
                                <span
                                  class="bold red"
                                  *ngIf="
                                    spendList[i] &&
                                    spendList[i]?.total_amount != '0.00'
                                  "
                                >
                                  {{ absolute(spendList[i]?.total_amount) }}
                                </span>
                              </td>
                              <td>
                                <span
                                  *ngIf="
                                    !spendList[i] ||
                                    (spendList[i] &&
                                      spendList[i]?.total_amount == '0.00')
                                  "
                                >
                                  {{ "" }}
                                </span>
                                <span
                                  class="bold red"
                                  *ngIf="
                                    spendList[i] &&
                                    spendList[i]?.total_amount != '0.00'
                                  "
                                >
                                  {{
                                    spendList[i]
                                      ?.transaction_history_description
                                  }}
                                </span>
                              </td>
                            </tr>
                          </ng-container>
                          <tr class="color-bold">
                            <td class="incoming-color"></td>
                            <td class="incoming-color">
                              {{ totalsDate?.total_credit }}
                            </td>
                            <td class="incoming-color center-line">
                              {{ "lang_total_incoming" | language }}
                            </td>
                            <td class="spend-color">
                              {{ totalsDate?.total_debit }}
                            </td>
                            <td class="spend-color">
                              {{ "lang_total_spend" | language }}
                            </td>
                          </tr>
                          <tr class="color-bold">
                            <td class="incoming-color"></td>
                            <td class="incoming-color">
                              {{ totalsMonths?.total_credit }}
                            </td>
                            <td class="incoming-color center-line">
                              {{ "lang_total_monthly_incoming" | language }}
                            </td>
                            <td class="spend-color">
                              {{ totalsMonths?.total_debit }}
                            </td>
                            <td class="spend-color">
                              {{ "lang_total_monthly_spend" | language }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          *ngIf="!spendList?.length && !incomeList?.length"
                          class="AppendList"
                        >
                          <tr class="odd">
                            <td colspan="5" align="center">
                              {{ lodingdatas }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        class="d-flex justify-content-between p-2 printnone"
                        *ngIf="listofdatas?.length"
                      >
                        <ngb-pagination
                          [collectionSize]="
                            incomeCollectionSize >= spendCollectionSize
                              ? incomeCollectionSize
                              : spendCollectionSize
                          "
                          [rotate]="true"
                          [ellipses]="false"
                          [maxSize]="3"
                          [boundaryLinks]="true"
                          [(page)]="page"
                          [pageSize]="pageSize"
                          (pageChange)="
                            fetch_statements('income');
                            fetch_statements('spend')
                          "
                        ></ngb-pagination>
                        <label
                          >{{ "lang_total" | language }} :
                          {{
                            incomeCollectionSize >= spendCollectionSize
                              ? incomeCollectionSize
                              : spendCollectionSize
                          }}</label
                        >
                        <select
                          class="customselect"
                          [(ngModel)]="pageSize"
                          (change)="
                            fetch_statements('income');
                            fetch_statements('spend')
                          "
                        >
                          <option [ngValue]="10">10</option>
                          <option [ngValue]="20">20</option>
                          <option [ngValue]="50">50</option>
                          <option [ngValue]="100">100</option>
                          <option [ngValue]="200">200</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <h3 class="tickets-transactions-tab-title">
                {{ "lang_teller_groups_settings" | language }}
              </h3>
            </ng-template>
            <div class="material-panel-body">
              <div class="mbrl15">
                <div class="row">
                  <div class="col-md-5 col-sm-5 col-xs-12 mb-10">
                    <div>
                      <label>{{ "lang_search" | language }} : </label><br />
                      <input
                        type="text"
                        placeholder="{{ 'lang_search' | language }}"
                        [(ngModel)]="searchGroupName"
                        (keyup)="loadTellerReports()"
                        class="form-control almnabrformcontrol"
                      />
                    </div>
                  </div>
                  <div class="col-md-5 col-sm-5 col-xs-12 mb-10">
                    <div>
                      <label>{{ "lang_report_groups" | language }} : </label
                      ><br />
                      <ng-select
                        [items]="groupAccounts"
                        [multiple]="true"
                        bindLabel="label"
                        bindValue="value"
                        appendTo="body"
                        clearAllText="Clear"
                        [(ngModel)]="groups"
                        placeholder="{{ 'lang_report_groups' | language }}"
                        (change)="loadTellerReports()"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                    <i class="together-icon">
                      <i
                        class="fa fa-plus faadd mnabricon"
                        *ngIf="ds.allowMe('accounts', 'accounts_add')"
                        (click)="openModal(CreateTellerReport, 'create')"
                        matTooltip="{{
                          'lang_create_report_groups' | language
                        }}"
                      ></i>
                    </i>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive customResponsive">
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <td>#</td>
                            <td>{{ "lang_group_name" | language }}</td>
                            <td>{{ "lang_action" | language }}</td>
                          </tr>
                        </thead>
                        <tbody
                          *ngIf="tellerGroups?.length != '0'"
                          class="AppendList"
                        >
                          <tr
                            *ngFor="
                              let data of tellerGroups?.records;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                            [ngClass]="{ odd: odd, even: even }"
                            class="makeback removerow{{
                              data.teller_report_id
                            }}"
                          >
                            <td>{{ i + 1 }}</td>
                            <td>{{ data.group_name }}</td>
                            <td>
                              <i
                                class="fa fa-trash faicon red mnabricon s2icon"
                                (click)="deleteTellerGroupData(data)"
                                matTooltip="{{ 'lang_delete' | language }}"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          *ngIf="tellerGroups?.length == '0'"
                          class="AppendList"
                        >
                          <tr class="odd">
                            <td colspan="7" align="center">
                              {{ lodingdatas }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="tellerGroups?.length != '0'"
                class="card-footer padb0"
              >
                <div class="row responsive_pagination">
                  <div
                    class="col-lg-3 col-sm-3 mb-10"
                    style="height: fit-content; margin: auto 0"
                  >
                    {{ "lang_total" | language }} :
                    {{ tellerGroups?.page?.total_records }}
                  </div>
                  <div
                    class="col-lg-5 col-sm-5 mb-10"
                    style="display: flex; justify-content: center"
                  >
                    <ngb-pagination
                      class="m-auto"
                      [collectionSize]="tellerGroups?.page?.total_records"
                      [rotate]="true"
                      [ellipses]="false"
                      [boundaryLinks]="true"
                      [(page)]="acpage_no"
                      [pageSize]="acpage_size"
                      (pageChange)="loadpagerec($event)"
                    ></ngb-pagination>
                  </div>
                  <div
                    class="col-lg-3 col-sm-3"
                    style="display: flex; justify-content: end; margin: auto"
                  >
                    <mat-select
                      style="max-width: 150px"
                      [(ngModel)]="recpage_size"
                      (selectionChange)="loadrecpagesize($event.value)"
                    >
                      <mat-option class="text-center" value="10"
                        >10 {{ "lang_per_page" | language }}
                      </mat-option>
                      <mat-option class="text-center" value="20"
                        >20 {{ "lang_per_page" | language }}
                      </mat-option>
                      <mat-option class="text-center" value="50"
                        >50 {{ "lang_per_page" | language }}
                      </mat-option>
                      <mat-option class="text-center" value="100"
                        >100 {{ "lang_per_page" | language }}</mat-option
                      >
                      <mat-option class="text-center" value="500"
                        >500 {{ "lang_per_page" | language }}</mat-option
                      >
                    </mat-select>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
<ng-template #CreateTellerReport>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ "lang_create_teller_report_groups" | language }}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="formCreate" class="from" (ngSubmit)="submitModal()">
      <div class="modal-body">
        <div class="form-group">
          <label for="report_groups"
            >{{ "lang_report_groups" | language }}:
            <span class="red">*</span></label
          >
          <ng-select
            [items]="groupAccounts"
            [multiple]="true"
            bindLabel="label"
            bindValue="value"
            clearAllText="Clear"
            [searchFn]="ds.customSearchFn"
            appendTo="body"
            formControlName="report_groups"
            placeholder="{{ 'lang_report_groups' | language }}"
          >
          </ng-select>
          <mat-error
            *ngIf="
              formCreate.controls['report_groups'].touched &&
              formCreate.controls['report_groups'].errors?.required
            "
          >
            {{ "lang_field_required" | language }}
          </mat-error>
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              data-dismiss="modal"
              (click)="modalRef.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
