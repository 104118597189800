<form [formGroup]="form">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between">
      <h4>
        {{ "lang_update_shift" | language }} ({{ dayInfo | date: "fullDate" }})
      </h4>
      <span (click)="closeModal()"><i class="fa fa-times"></i></span>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <div>
        <div class="row">
          <div class="col-sm-12">
            <h3>{{ "lang_start_end_shift" | language }}</h3>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_shift_start_time" | language }}</mat-label>
              <input
                matInput
                readonly
                formControlName="shift_start_time"
                required
                [ngxTimepicker]="shift_start_time"
              />
              <ngx-material-timepicker
                #shift_start_time
              ></ngx-material-timepicker>
              <mat-error
                *ngIf="
                  form.controls['shift_start_time'].touched &&
                  form.controls['shift_start_time'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_shift_end_time" | language }}</mat-label>
              <input
                matInput
                readonly
                formControlName="shift_end_time"
                required
                [ngxTimepicker]="shift_end_time"
              />
              <ngx-material-timepicker
                #shift_end_time
              ></ngx-material-timepicker>

              <mat-error
                *ngIf="
                  form.controls['shift_end_time'].touched &&
                  form.controls['shift_end_time'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label
                >{{ "lang_shift" | language }}
                {{ "lang_total_hours" | language }}</mat-label
              >

              <input
                matInput
                type="number"
                formControlName="shift_total_hours"
                required
              />
              <mat-error
                *ngIf="
                  form.controls['shift_total_hours'].touched &&
                  form.controls['shift_total_hours'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <h3>{{ "lang_check_in_out_shift" | language }}</h3>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_check_in_start" | language }}</mat-label>

              <input
                matInput
                readonly
                formControlName="check_in_start"
                required
                [ngxTimepicker]="check_in_start"
              />
              <ngx-material-timepicker
                #check_in_start
              ></ngx-material-timepicker>
              <mat-error
                *ngIf="
                  form.controls['check_in_start'].touched &&
                  form.controls['check_in_start'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_check_in_end" | language }}</mat-label>

              <input
                matInput
                readonly
                formControlName="check_in_end"
                required
                [ngxTimepicker]="check_in_end"
              />
              <ngx-material-timepicker #check_in_end></ngx-material-timepicker>
              <mat-error
                *ngIf="
                  form.controls['check_in_end'].touched &&
                  form.controls['check_in_end'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_check_out_start" | language }}</mat-label>
              <input
                matInput
                readonly
                formControlName="check_out_start"
                required
                [ngxTimepicker]="check_out_start"
              />
              <ngx-material-timepicker
                #check_out_start
              ></ngx-material-timepicker>

              <mat-error
                *ngIf="
                  form.controls['check_out_start'].touched &&
                  form.controls['check_out_start'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_check_out_end" | language }}</mat-label>
              <input
                matInput
                readonly
                formControlName="check_out_end"
                required
                [ngxTimepicker]="check_out_end"
              />
              <ngx-material-timepicker #check_out_end></ngx-material-timepicker>

              <mat-error
                *ngIf="
                  form.controls['check_out_end'].touched &&
                  form.controls['check_out_end'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12">
            <h3>{{ "lang_break_in_out" | language }}</h3>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_break_in_start" | language }}</mat-label>
              <input
                matInput
                readonly
                formControlName="break_in_start"
                required
                [ngxTimepicker]="break_in_start"
              />
              <ngx-material-timepicker
                #break_in_start
              ></ngx-material-timepicker>

              <mat-error
                *ngIf="
                  form.controls['break_in_start'].touched &&
                  form.controls['break_in_start'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_break_in_end" | language }}</mat-label>

              <input
                matInput
                readonly
                formControlName="break_in_end"
                required
                [ngxTimepicker]="break_in_end"
              />
              <ngx-material-timepicker #break_in_end></ngx-material-timepicker>
              <mat-error
                *ngIf="
                  form.controls['break_in_end'].touched &&
                  form.controls['break_in_end'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_break_out_start" | language }}</mat-label>
              <input
                matInput
                readonly
                formControlName="break_out_start"
                required
                [ngxTimepicker]="break_out_start"
              />
              <ngx-material-timepicker
                #break_out_start
              ></ngx-material-timepicker>

              <mat-error
                *ngIf="
                  form.controls['break_out_start'].touched &&
                  form.controls['break_out_start'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_break_out_end" | language }}</mat-label>

              <input
                matInput
                readonly
                formControlName="break_out_end"
                required
                [ngxTimepicker]="break_out_end"
              />
              <ngx-material-timepicker #break_out_end></ngx-material-timepicker>
              <mat-error
                *ngIf="
                  form.controls['break_out_end'].touched &&
                  form.controls['break_in_end'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label
                >{{ "lang_shift" | language }}
                {{ "lang_break_hours" | language }}</mat-label
              >

              <input
                matInput
                type="number"
                formControlName="shift_break_hours"
                required
              />
              <mat-error
                *ngIf="
                  form.controls['shift_break_hours'].touched &&
                  form.controls['shift_break_hours'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-9 col-12"></div>
          <div class="col-sm-12">
            <h3>{{ "lang_deduction" | language }}</h3>
          </div>

          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_absent_A" | language }}</mat-label>
              <input
                matInput
                formControlName="absent_A"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_absent_B" | language }}</mat-label>
              <input
                matInput
                formControlName="absent_B"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_absent_C" | language }}</mat-label>
              <input
                matInput
                formControlName="absent_C"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_absent_D" | language }}</mat-label>
              <input
                matInput
                formControlName="absent_D"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_not_ok_A" | language }}</mat-label>
              <input
                matInput
                formControlName="not_ok_A"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_not_ok_B" | language }}</mat-label>
              <input
                matInput
                formControlName="not_ok_B"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_not_ok_C" | language }}</mat-label>
              <input
                matInput
                formControlName="not_ok_C"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_not_ok_D" | language }}</mat-label>
              <input
                matInput
                formControlName="not_ok_D"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_record_missing_A" | language }}</mat-label>
              <input
                matInput
                formControlName="record_missing_A"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_record_missing_B" | language }}</mat-label>
              <input
                matInput
                formControlName="record_missing_B"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_record_missing_C" | language }}</mat-label>
              <input
                matInput
                formControlName="record_missing_C"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_record_missing_D" | language }}</mat-label>
              <input
                matInput
                formControlName="record_missing_D"
                onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
              />
            </mat-form-field>
          </div>

          <div class="col-md-4 col-12">
            <mat-checkbox
              class="example-margin"
              (change)="dayOff($event)"
              formControlName="shift_time_day_off"
            >
              {{ "lang_shift" | language }} {{ "lang_time_day_off" | language }}
            </mat-checkbox>
          </div>
          <div class="col-md-8 col-12">
            <mat-checkbox
              class="example-margin"
              formControlName="effect_all_data"
            >
              {{ "lang_shift" | language }}
              {{ "lang_effect_all_data" | language }}
            </mat-checkbox>
          </div>
          <h3 class="margin">{{ "lang_copy_to_days" | language }}</h3>
          <div class="col-md-12" *ngFor="let item of daysNameArr">
            <mat-checkbox
              class="example-margin2-{{ item }}"
              (change)="getAllDaysByNameiNMothDynamic($event, item)"
            >
              {{ "lang_" + item | language }}
            </mat-checkbox>
          </div>

          <h3 class="margin">{{ "lang_apply_to_all_monthes" | language }}</h3>
          <div class="col-md-12">
            <mat-checkbox
              class="example-margin-month"
              formControlName="apply_to_all_monthes"
            >
              {{ "lang_apply_to_all_monthes" | language }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="col-md-4 mb-2 col-xs-12">
      <button
        type="button"
        class="albutton mnabrbutton mat-accent"
        mat-button
        (click)="closeModal()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
    <div class="col-md-4 col-xs-12"></div>
    <div class="col-md-4 mb-2 col-xs-12">
      <button
        type="button"
        class="albutton mnabrbutton mat-accent"
        mat-button
        [disabled]="!form.valid"
        (click)="save()"
      >
        {{ "lang_next" | language }}
      </button>
    </div>
  </mat-dialog-actions>
</form>
