import { Component, Inject, Input, OnInit } from "@angular/core";
import {
	FormGroup,
	FormControl,
	Validators,
	FormBuilder,
} from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
	selector: "app-add-shift-name",
	templateUrl: "./add-shift-name.component.html",
	styleUrls: ["./add-shift-name.component.scss"],
})
export class AddShiftNameComponent implements OnInit {
	modalRefA: BsModalRef;
	form: FormGroup;
	form_request_id: any;
	request: any = [];
	allData: any;
	lang_key = localStorage.getItem("lang_key") || "en";

	groups: any = [];
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		private snackBar: MatSnackBar,
		public lang: LanguagePipe,
		private fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public router: Router,
		private dialogRef: MatDialogRef<AddShiftNameComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.buildform(this.data);
	}

	buildform(data) {
		if (data) {
			this.form = this.fb.group({
				shift_title_english: [data?.shift_title_english, [Validators.required]],
				/*  , */
				shift_title_arabic: [data?.shift_title_arabic, [Validators.required]],
				groups: [
					data?.groups.map((g) => {
						return g.group_id;
					}),
					[Validators.required],
				],
			});
			this.groups = data?.groups.map((g) => {
				return {
					label:
						this.lang_key == "ar"
							? g.group_title_arabic
							: g.group_title_english,
					value: g.group_id,
				};
			});
		} else {
			this.form = this.fb.group({
				shift_title_english: ["", [Validators.required]],
				/*  , */
				shift_title_arabic: ["", [Validators.required]],
				groups: [[], [Validators.required]],
			});
		}
	}

	ngOnInit(): void {
		this.get_groups();
	}

	public get_groups() {
		this.groups = [];

		let param = new FormData();

		this.ds
			.getActionByUrl(
				this.ds.formData2string(param),
				"at/groups?lang_key=" + this.lang_key
			)
			.subscribe(
				(res) => {
					if (res.status) {
						this.groups = res.records;
					} else {
						this.groups = [];
					}
				},
				(error) => {
					this.groups = [];
					this.showMsg(
						"lang_must_be_at_least_one_group",
						"ok",
						"error-snackbar"
					);
				}
			);
	}

	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 4000,
			panelClass: [className],
		});
	}

	close() {
		this.dialogRef.close({ saved: false });
	}

	submitForm() {
		if (this.form?.status == "VALID") {
			this.spinner.show();
			let formData = new FormData();
			formData.append(
				"shift_title_english",
				this.form.value.shift_title_english
			);
			formData.append("shift_title_arabic", this.form.value.shift_title_arabic);
			for (let index = 0; index < this.form.value.groups.length; index++) {
				const element = this.form.value.groups[index];
				formData.append("groups[]", element);
			}
			if (this.data) {
				this.ds
					.post(`at/update_shifts/${this.data.shift_id}`, formData)
					.subscribe(
						(data) => {
							if (data.status) {
								this.form.reset();
								this.showMsg(data.msg, "ok", "success-snackbar");
								this.dialogRef.close({ saved: true });
								this.spinner.hide();
							} else {
								this.spinner.hide();
								this.showMsg(data.error, "ok", "error-snackbar");
							}
						},
						(error) => {
							this.spinner.hide();
							this.showMsg(error?.error?.error, "ok", "error-snackbar");
						}
					);
			} else {
				this.ds.post(`at/create_shifts`, formData).subscribe(
					(data) => {
						if (data.status) {
							this.form.reset();
							this.showMsg(data.msg, "ok", "success-snackbar");
							this.dialogRef.close({ saved: true });
							this.spinner.hide();
						} else {
							this.spinner.hide();
							this.showMsg(data.error, "ok", "error-snackbar");
						}
					},
					(error) => {
						this.spinner.hide();
						this.showMsg(error?.error?.error, "ok", "error-snackbar");
					}
				);
			}
		} else {
			this.showMsg("Please fill all fields first", "ok", "error-snackbar");
		}
	}
	closeModal() {
		this.dialogRef.close({ formValue: null, days: null });
	}
}
