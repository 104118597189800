<h3 mat-dialog-title class="d-flex align-items-center justify-content-between">
	<span>{{ "lang_advanced_filter" | language }}</span>
	<i class="fa fa-close mnabricon" mat-dialog-close></i>
</h3>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="filtersForm">
		<div class="row">
			<div class="col-12 col-sm-6 mb-2">
				<label for="template">{{ "lang_template_name" | language }}</label>
				<ng-select
					[items]="templateList"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_template_name' | language }}"
					formControlName="template_id"
					id="template"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="zone">{{ "lang_zone" | language }}</label>
				<ng-select
					[items]="zones"
					[multiple]="false"
					bindLabel="label"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_zone' | language }}"
					formControlName="zone_id"
					id="zone"
					(change)="get_blocks()"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="block">{{ "lang_blocks" | language }}</label>
				<ng-select
					[items]="blocks"
					[multiple]="false"
					bindLabel="label"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_blocks' | language }}"
					formControlName="block_id"
					id="block"
					(change)="get_clusters()"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="cluster">{{ "lang_clusters" | language }}</label>
				<ng-select
					[items]="clusters"
					[multiple]="false"
					bindLabel="label"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_clusters' | language }}"
					formControlName="cluster_id"
					id="cluster"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="unit_id">{{ "lang_general_numbers" | language }}</label>
				<input
					type="text"
					class="form-control"
					id="unit_id"
					name="unit_id"
					(keypress)="ds?.isNumber($event)"
					formControlName="unit_id"
					placeholder="{{ 'lang_general_numbers' | language }}"
				/>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="group1">{{ "lang_group_one" | language }}</label>
				<ng-select
					[items]="divisions"
					[multiple]="true"
					bindLabel="platform_group1_title"
					bindValue="platform_group1_code_system"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_group_one' | language }}"
					formControlName="group_1"
					id="group1"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions class="align-items-center justify-content-between">
	<div style="width: 100px">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onReset()"
		>
			{{ "lang_reset" | language }}
		</button>
	</div>
	<div style="width: 100px">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onSubmit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
