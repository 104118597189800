<div class="row wafi_header">
  <div class="table-responsive customResponsive">
    <table border="1" class="table wafi_title" width="100%">
      <tbody>
        <tr>
          <td width="130px">
            <img src="assets/images/logoawafi.PNG" alt="" />
          </td>
          <td width="87.5%">
            <div class="title_content">
              {{
                "supervision consultant monthly report for july-2022" | language
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row content_header">
  <div class="col">
    {{ "lang_infrastructure_works_for_master_development_projects" | language }}
  </div>
</div>
<div class="row quality_control">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody>
        <tr>
          <th rowspan="6">{{ "lang_overview" | language }}</th>
          <th>
            {{ "lang_infrastructure_contractor" | language }}
          </th>
          <th>
            {{ "lang_infrastructure_cost" | language }} (
            {{ "lang_sar" | language }})
          </th>
          <th>
            {{ "lang_contractual_start_data" | language }}
          </th>
          <th>
            {{ "lang_contractual_finish_data" | language }}
          </th>
          <th>
            {{ "lang_infra._contractual_duration" | language }}({{
              "lang_month" | language
            }})
          </th>
          <th>{{ "lang_eot" | language }}({{ "lang_days" | language }})</th>
          <th>{{ "lang_revised_contractual_completion_date" | language }}</th>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <th style="background-color: #595959">
            {{ "lang_actual_start_date" | language }}
          </th>
          <th>
            {{
              "lang_forecast_completion_date_from_the_updated_programme"
                | language
            }}
          </th>
          <th>
            {{ "lang_elapsed_time" | language }} ({{ "lang_days" | language }})
          </th>
          <th>{{ "lang_elapsed_time" | language }} %</th>
          <th>
            {{ "lang_remaining_time" | language }} ({{
              "lang_days" | language
            }})
          </th>
          <th>{{ "lang_remaining_time" | language }}%</th>
          <th>{{ "lang_delay" | language }} ({{ "lang_days" | language }})</th>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <th style="background-color: #595959">
            {{ "lang_monthly_planned_progress" | language }} %
          </th>
          <th>{{ "lang_monthly_actual_progress" | language }} %</th>
          <th>
            {{ "lang_variance" | language }}
          </th>
          <th>{{ "lang_cumulative_actual_progress" | language }} %</th>
          <th>
            {{ "lang_cumulative_planned_progress" | language }}
          </th>
          <th>
            {{ "lang_variance" | language }}
          </th>
          <th>{{ "lang_spi" | language }}</th>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <br />
        <tr>
          <th rowspan="4">
            {{ "lang_tie_in_connection_with_services_providers" | language }}
          </th>
          <th>
            {{ "lang_service" | language }}
          </th>
          <th>
            {{ "lang_infrastructure_cost" | language }} (
            {{ "lang_status" | language }})
          </th>
          <th colspan="5">
            {{ "lang_notes" | language }}
          </th>
        </tr>
        <tr>
          <td class="highlight2">{{ "lang_electricity" | language }}</td>
          <td></td>
          <td colspan="5"></td>
        </tr>
        <tr>
          <td class="highlight2">{{ "lang_water" | language }}</td>
          <td></td>
          <td colspan="5"></td>
        </tr>
        <tr>
          <td class="highlight2">{{ "lang_sewage" | language }}</td>
          <td></td>
          <td colspan="5"></td>
        </tr>
        <br />
        <tr>
          <th rowspan="16">
            {{ "lang_infrastructure_networks_status" | language }}
          </th>
          <th>
            {{ "lang_no" | language }}
          </th>
          <th>
            {{ "lang_activity" | language }}
          </th>
          <th>{{ "lang_cumulative_planned_progress" | language }}</th>
          <th>
            {{ "lang_cumulative_actual_progress" | language }}
          </th>
          <th>
            {{ "lang_variance" | language }}
          </th>
          <th colspan="2">
            {{ "lang_notes" | language }}
          </th>
        </tr>
        <tr>
          <td class="highlight2">1</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">2</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">2</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">4</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">5</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">6</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">7</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">8</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">9</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">10</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">11</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">12</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">12</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">13</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="highlight2">14</td>
          <td>{{ "lang_enabling_works" | language }}</td>
          <td></td>
          <td></td>
          <td class="highlight"></td>
          <td colspan="2"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
