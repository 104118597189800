import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-ctsettings',
  templateUrl: './ctsettings.component.html',
  styleUrls: ['./ctsettings.component.scss']
})
export class CtsettingsComponent implements OnInit ,OnDestroy {
  admin = JSON.parse(this.ls.getItem('is_admin'));
  modalRef: BsModalRef;
  modalRefC: BsModalRef;
  modalRefV: BsModalRef;
  modalRefU: BsModalRef;
  able2delete = JSON.parse(this.ls.getItem('is_admin'));
  permissionForm: FormGroup;
  branches: any = [];
  groups: any = [];
  users: any = [];
  mention_allow: boolean = false;
  pmpage_no = 1;
  pmpage_size = 10;
  pmdata: any = [];
  lodingdatas = this.lang.transform('lang_loading');
  searchBranch= null;
  searchGroup = null;
  searchUser = null;
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.permissionform();
    this.get_branches();
    this.get_groups();
    this.get_users(0);
    this.get_permission_mentions();
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  public openModelC(template: TemplateRef<any>) {
    this.modalRefC = this.modalService.show(template, environment.modelconfig);
  }
  public permissionform() {
    this.permissionForm = this.fb.group({
      branch_id: ['', [Validators.required]],
      group_id: ['', [Validators.required]],
      users_id: [null, [Validators.required]],
    });
  }
  public get_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], 'ct/b/contractors_view').subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.mention_allow  = res.mention_allow;
            this.branches       = res.records;
          }
        }, error => {
          this.spinner.hide();
        })     
      )
   
  }
  public get_groups() {
    this.groups = [];
    this.subscriptions.add(
        this.ds.getActionByUrl([], 'ct/g').subscribe(res => {
          if (res.status) {
            this.groups = res.records;
          }
        })      
      )

  }
  public get_users(value) {
    this.permissionForm.get('users_id').setValue(null);
    this.users  = [];
    let bid     = (value) ? value : 0;
    let param   = new FormData();
    param.append('user_type_id', '1');
    this.subscriptions.add(
        this.ds.getActionByUrl(param, 'ct/gu/' + bid).subscribe(res => {
          this.spinner.hide();;
          if (res.status) {
            this.users = res.records;
          }
        }, error => {
            this.spinner.hide();;
        })      
      )

  }
  public emptypermissionfields() {
    this.permissionForm.reset();
    this.permissionForm.get('branch_id').setValue('');
    this.permissionForm.get('group_id').setValue('');
  }
  public savepermissions() {
    this.spinner.show();
    let param = new FormData();
    param.append('branch_id', this.permissionForm.get('branch_id').value || '');
    param.append('group_id', this.permissionForm.get('group_id').value || '');
    param.set('users_id', (this.permissionForm.get('users_id').value && this.permissionForm.get('users_id').value != null) ? this.permissionForm.get('users_id').value.toString() : '');
    this.subscriptions.add(
        
        this.ds.postActionByUrl(param, 'ct/sp').subscribe((data) => {
            this.spinner.hide();
            if (data.status) {
              this.alert.success(data.msg);
              this.get_permission_mentions();
              this.permissionForm.reset();
              setTimeout(() => {
                this.modalRef.hide();  
              }, 1000);          
            } else {
              this.alert.error(data.error);
            }
          }, (error) => {
            this.spinner.hide();
            this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          }
        )      
      ) 
;
  }
  public loadpage(page) {
    this.pmpage_no = page;
    this.pmpage_size = this.pmpage_size;
    this.get_permission_mentions();
  }
  public loadpagesize(size) {
    this.pmpage_no = 1;
    this.pmpage_size = size;
    this.get_permission_mentions();
  }
  public load_permission_mention() {
    this.pmpage_no = 1;
    this.pmpage_size = 10;
    this.get_permission_mentions();
  }
  public get_permission_mentions() {
    let formdata = new FormData();
    formdata.append('search[branch_id]', this.searchBranch || '');
    formdata.append('search[group_key]', this.searchGroup || '');
    formdata.append('search[user_id]', this.searchUser || '');
    this.subscriptions.add(
        this.ds.postActionByUrl(formdata, 'ct/gp/' + this.pmpage_no + '/' + this.pmpage_size,).subscribe(res => {
          this.spinner.hide();;
          if (res.status) {
            this.pmdata = res;
          } else {
            this.pmdata = [];
            this.lodingdatas = res.error;
          }
        }, error => {
          this.spinner.hide();;
          this.pmdata = [];
          this.lodingdatas = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
        })      
      )

  }
  public checkAllItemsUsr(event) {
    if (event.target.checked) {
      $(".user_permissions .eachItemUsr").prop("checked", true);
      $(".user_permissions .makeback").addClass("bground");
      $(".user_permissions .deleteIconDiv").show();
    } else {
      $(".user_permissions .eachItemUsr").prop("checked", false);
      $(".user_permissions .makeback").removeClass("bground");
      $(".user_permissions .deleteIconDiv").hide();
    }
  }
  public checkEachItemUsr(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".user_permissions .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".user_permissions .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".user_permissions .eachItemUsr:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".user_permissions .eachItemUsr").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".user_permissions .deleteIconDiv").show();
    } else {
      $(".user_permissions .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".user_permissions #allItemsUsr").prop("checked", true);
    } else {
      $(".user_permissions #allItemsUsr").prop("checked", false);
    }
  }
  public deleteBulkDataUsr() {
    const checkedtotal = [];
    $(".user_permissions .eachItemUsr:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      this.subscriptions.add(
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                this.spinner.show()
                this.subscriptions.add(
                    this.ds.deleteActionByUrl(checkedtotal, "ct/dp").subscribe(
                      (data) => {
                        this.spinner.hide()
                        if (data.status) {
                          this.get_permission_mentions();
                          $(".user_permissions #allItemsUsr").prop("checked", false);
                          $(".user_permissions .deleteIconDiv").hide();
                          this.ds.dialogf('', data.msg);
                        } else {
                          this.ds.dialogf('', data.error);
                        }
                      },
                      (error) => {
                        this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                        this.spinner.hide()
                      }
                    )      
                  )
                ;
              }
            })      
        )
     ;
    } else {
      this.ds.dialogf();
    }
  }
}
