<div class="row mt-3 mx-2">
  <div class="col-12 deduction_header">
    {{ "lang_create_violation_deduction" | language }}
  </div>
  <div class="col-md-8 col-sm-12 d-flex">
    <button
      class="btn btn-cancel mx-2"
      [disabled]="usersList.length == 0"
      (click)="showFilters = !showFilters"
      style="padding: 3px 10px"
    >
      <mat-icon class="mx-1">filter_list</mat-icon>
      <div class="filter">
        {{ "lang_advanced_filter" | language }}
      </div>
    </button>

    <button (click)="openChartData([])" class="btn btn-add-employees mx-2 p-0">
      <div class="add-icon">
        <mat-icon>add</mat-icon>
      </div>
      <span class="content">
        {{ "lang_add_employees" | language }}
      </span>
    </button>
  </div>
</div>

<div
  class="row my-2 mx-2 justify-content-between filters"
  [formGroup]="filterForm"
  *ngIf="showFilters"
>
  <div class="col-md-4 col-sm-6 col-12">
    <div class="form-group">
      <label>{{ "lang_human_resources_id_number" | language }} </label>

      <input
        type="text"
        class="form-control"
        id="lang_employee_id"
        name="lang_employee_id"
        (keyup)="sortArray()"
        formControlName="employee_id"
      />
    </div>
  </div>
  <div class="col-md-4 col-sm-6 col-12">
    <div class="form-group">
      <label>{{ "lang_human_resources_employee_name" | language }} </label>

      <input
        type="text"
        class="form-control"
        id="employee_name"
        name="employee_name"
        (keyup)="sortArray()"
        formControlName="employee_name"
      />
    </div>
  </div>

  <!--     <div class="col-md-4 text-center">
   <button
    class="albutton mnabrbutton top-30"
    mat-button
   (click)="getEmployeeData()"
   >
             <i class="fa fa-users" style="font-size: 17px; margin: 0 5px"></i>
       {{ "lang_search" | language }}
   </button>  
 </div>
 <div class="col-md-4 text-center">
  
 </div> -->
</div>

<div class="row my-2 mx-2 mt-4 div-overflow">
  <table class="table table-striped custom-payrole-table">
    <thead>
      <tr>
        <!--  <th scope="col"> 
           <mat-icon  (click)="clearAll()" matTooltip="{{'lang_delete_all' | language }}" class="delete-all">
           delete
         </mat-icon>
       </th> -->
        <th scope="col">
          {{ "lang_human_resources_profile_image" | language }}
        </th>
        <th scope="col">
          {{ "lang_human_resources_employee_name" | language }}
        </th>
        <th scope="col">{{ "lang_human_resources_number" | language }}</th>
        <th scope="col">{{ "lang_status" | language }}</th>
        <th scope="col">{{ "lang_date" | language }}</th>

        <th scope="col">{{ "lang_action" | language }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of usersList; let i = index">
        <!--    <th scope="row">
            <mat-icon matTooltip="{{'lang_delete' | language }}" (click)="deleteSingle(item.value,i)">
                delete
            </mat-icon>
         </th> -->
        <td>
          <span *ngIf="item?.profile_image">
            <span class="result_code"
              ><img
                src="{{ item.profile_image }}"
                style="
                  border-radius: 50%;
                  width: 100%;
                  height: 100%;
                  width: 47px;
                  height: 47px;
                "
                alt="" /></span
          ></span>
          <span *ngIf="!item?.profile_image">
            <span class="result_code"
              ><img
                src="assets/img/profile (1).png"
                style="width: 47px; height: 47px"
                alt="" /></span
          ></span>
        </td>
        <td>{{ item.employee_name }}</td>
        <td>{{ item.employee_number }}</td>
        <td>{{ item.state }}</td>
        <td>{{ item.date }}</td>
        <!--  <td>
             <div matTooltip="{{'backend note' | language }}" class="note">

             </div>
         </td> -->
        <td>
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Actions"
            style="color: black !important"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" style="color: black !important">
            <button (click)="deleteSingle(item.id, i)" mat-menu-item>
              <mat-icon style="color: black !important">delete</mat-icon>
              <span>{{ "lang_delete" | language }}</span>
            </button>
            <!--  <button mat-menu-item disabled>
               <mat-icon>voicemail</mat-icon>
               <span>Check voice mail</span>
             </button>
             <button mat-menu-item>
               <mat-icon>notifications_off</mat-icon>
               <span>Disable alerts</span>
             </button> -->
          </mat-menu>
        </td>
      </tr>

      <tr *ngIf="usersList?.length == 0">
        <th colspan="6">
          <div class="no_data">
            {{ "lang_no_employees" | language }}
          </div>
        </th>
      </tr>
    </tbody>
  </table>
</div>
<div>
  <!--   <button mat-button matStepperPrevious>Back</button>
     <button mat-button matStepperNext>Next</button> -->
  <div>
    <div class="row">
      <div class="col-12 mb-10">
        <app-alert id="default-alert"></app-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xs-12 mb-10">
        <button
          type="button"
          class="btn btn-secondary secondarybutton"
          data-dismiss="modal"
          (click)="modalRef.close()"
        >
          {{ "lang_close" | language }}
        </button>
      </div>
      <div class="col-md-6 col-xs-12 mb-10">
        <button type="submit" (click)="submit()" click class="albutton">
          {{ "lang_submit" | language }}
        </button>
      </div>
    </div>
  </div>
  <!-- <div class="w-100 text-right">
    <button (click)="moveToDetails()" class="btn mb-2 mx-2 btn-apply">
      {{ "lang_next" | language }}
    </button>
  </div> -->
</div>
