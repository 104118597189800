import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit,OnDestroy {
  form:FormGroup;
  submitted:boolean = false;
  constructor(public ds: DataService, public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      password     : new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirm_password    : new FormControl('', [Validators.required, Validators.minLength(6)]),
    }, { validators: this.passwordConfirming });
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirm_password').value) {
      return { invalid: true };
    }
  }
  private subscriptions = new Subscription();
	ngOnDestroy(): void {
	  this.subscriptions.unsubscribe();
	}

  public submit() {
    this.submitted = true;
    if(this.form.valid) {
      this.submitted = false;
      this.spinner.show();
      this.subscriptions.add(
          this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'users/email/change_password').subscribe((res) => {
            this.spinner.hide();
            if(res.status) {
              this.alert.success(res.msg);
              setTimeout(() => {
                this.router.navigateByUrl('/');
              }, 1000);
            } else {
              this.alert.error(res.error);
            }
          }, error => {
            this.spinner.hide();
            this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          })			
        )

    }
  }

}
