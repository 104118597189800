import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-consultant-settings',
  templateUrl: './consultant-settings.component.html',
  styleUrls: ['./consultant-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsultantSettingsComponent implements OnInit {
  modalRefV: BsModalRef;
  divisionAction:string                           = "LIST";
  searchKey:any                                   = "";
  projects_setting_consultant_type:any            = "";
  projects_setting_consultant_id_linked:any       = null;
  projects_setting_consultant_position_code:any   = null;
  platform_code_system:any                        = null;
  wholedata:any                                   = [];
  fconsultants:any                                = [];
  consultantr:any                                 = [];
  platforms:any                                   = [];
  nodata: string                                  = this.lang.transform('lang_loading');
  lang_key                                        = localStorage.getItem('lang_key') || 'en';
  isAdmin                                         = this.ds.is_admin();
  positions:any                                   = [];
  editData:any                                    = [];
  pageno                                          = 1;
  pagesize                                        = '10';
  //View Models
  consultantsModel:any                            = null;
  positionsModel:any                              = null;
  positionsAuthModel:any                          = null;
  platformsModel:any                              = null;

  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.get_all_options();
    this.get_consultant_settings();
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  public get_all_options() {
    let param = new FormData();
    param.append('lang_key', this.lang_key);
    let url = ['fconsultant', 'consultantreq', 'platformconsultant', '366484fd45'];
    this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(res => {
      this.spinner.hide();
      if(res[0].status) { //From Consultant
        this.fconsultants = res[0].records;
      }
      if(res[1].status) { //Consultant Requirements records
        this.consultantr = res[1].records;
      }
      if(res[2].status) { //Platforms records
        this.platforms = res[2].records;
      }
      if(res[3].status) { //Positions records
        this.positions = res[3].records;
      }
    });
  }
  public search_types() {
    this.pageno   = 1;
    this.pagesize = '10';
    this.get_consultant_settings();
  }
  public loadpagesize(pagesize) {
    this.pageno = 1;
    this.pagesize = pagesize;
    this.get_consultant_settings();
  }
  public loadpage(pageno) {
    this.pageno = pageno;
    this.pagesize = this.pagesize;
    this.get_consultant_settings();
  }
  public get_consultant_settings() {
    let param = new FormData();
    param.append('search_key',  this.searchKey || '');
    param.append('projects_setting_consultant_type',  this.projects_setting_consultant_type || '');
    param.append('projects_setting_consultant_id_linked',  this.projects_setting_consultant_id_linked || '');
    param.append('projects_setting_consultant_position_code',  this.projects_setting_consultant_position_code || '');
    param.append('platform_code_system',  this.platform_code_system || '');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'gcsetting/'+this.pageno+'/'+this.pagesize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.wholedata = res;
      } else {
        this.wholedata = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholedata = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public checkAllConsuls(event) {
    if (event.target.checked) {
      $(".appconssettings .eachConsul").prop("checked", true);
      $(".appconssettings .makeback").addClass("bground");
      $(".appconssettings .deleteIconDiv").show();
    } else {
      $(".appconssettings .eachConsul").prop("checked", false);
      $(".appconssettings .makeback").removeClass("bground");
      $(".appconssettings .deleteIconDiv").hide();
    }
  }
  public checkEachConsul(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appconssettings .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appconssettings .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appconssettings .eachConsul:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appconssettings .eachConsul").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appconssettings .deleteIconDiv").show();
    } else {
      $(".appconssettings .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appconssettings #allConsuls").prop("checked", true);
    } else {
      $(".appconssettings #allConsuls").prop("checked", false);
    }
  }
  public deleteBulkDataConsuls() {
    const checkedtotal = [];
    $(".appconssettings .eachConsul:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'dcsetting').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.search_types();
                $(".appconssettings #AllConsuls").prop("checked", false);
                $(".deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}