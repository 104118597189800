import { Component } from '@angular/core';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from  'environments/environment';
@Component({
  selector: 'app-branding',
  template: `
    <a class="matero-branding" routerLink="/">
      <img src="{{appLogo}}" class="matero-branding-logo-expanded" alt="logo" />
      <img src="{{appName}}" class="matero-branding-name" alt="logoname" />
    </a>
  `,
})
export class BrandingComponent {
  public appLogo = environment.SERVER_ORIGIN+"default/theme/logo/"+this.ls.getItemString('user_id')+"/B";
  public appName = environment.SERVER_ORIGIN+"default/theme/logoname/"+this.ls.getItemString('user_id')+"/B";
  constructor(private ls:LocalStorage) {}
  public load_theme_logo() {
    this.appLogo = environment.SERVER_ORIGIN+"default/theme/logo/"+this.ls.getItemString('user_id')+"/B";
    this.appName = environment.SERVER_ORIGIN+"default/theme/logoname/"+this.ls.getItemString('user_id')+"/B";
  }
}