<breadcrumb></breadcrumb>
<section class="panel-expansion" [@fadeIn]>
  <mat-accordion class="stylepanel">
    <mat-expansion-panel class="mpanel" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_communication_lists" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body communication">
        <div class="mbrl15">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control almnabrformcontrol searchKey"
                  id="searchKey"
                  [(ngModel)]="searchKey"
                  (keyup)="search_transactions()"
                  placeholder="{{ 'lang_search' | language }}"
                />
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="form-group">
                <ng-select
                  [items]="modulelist"
                  [multiple]="true"
                  bindLabel="label"
                  bindValue="value"
                  appendTo="body"
                  clearAllText="Clear"
                  placeholder="{{ 'lang_search_modules' | language }}"
                  [(ngModel)]="moduleItems"
                  (change)="spinner.show(); search_transactions()"
                >
                  <ng-template ng-header-tmp>
                    <button class="btn btn-link" (click)="selectall('modules')">
                      {{ "lang_search_all" | language }}
                    </button>
                  </ng-template>
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-search="searchTerm"
                    let-index="index"
                  >
                    <span>{{ item.label }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="form-group">
                <ng-select
                  [items]="formlist"
                  [multiple]="true"
                  bindLabel="label"
                  bindValue="value"
                  appendTo="body"
                  clearAllText="Clear"
                  placeholder="{{ 'lang_search_form' | language }}"
                  [(ngModel)]="formItems"
                  (change)="spinner.show(); search_transactions()"
                >
                  <ng-template ng-header-tmp>
                    <button class="btn btn-link" (click)="selectall('forms')">
                      {{ "lang_search_all" | language }}
                    </button>
                  </ng-template>
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-search="searchTerm"
                    let-index="index"
                  >
                    <span>{{ item.label }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="form-group">
                <ng-select
                  [items]="branchlists"
                  [multiple]="true"
                  bindLabel="label"
                  appendTo="body"
                  bindValue="value"
                  clearAllText="Clear"
                  placeholder="{{ 'lang_search_branch' | language }}"
                  [(ngModel)]="branchItems"
                  (change)="spinner.show(); search_transactions()"
                >
                  <ng-template ng-header-tmp>
                    <button
                      class="btn btn-link"
                      (click)="selectall('branches')"
                    >
                      {{ "lang_search_all" | language }}
                    </button>
                  </ng-template>
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-search="searchTerm"
                    let-index="index"
                  >
                    <span>{{ item.label }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="form-group">
                <ng-select
                  [items]="commtypes"
                  [multiple]="true"
                  bindLabel="label"
                  bindValue="value"
                  appendTo="body"
                  clearAllText="Clear"
                  placeholder="{{ 'lang_search_type' | language }}"
                  [(ngModel)]="typeItems"
                  [notFoundText]="'lang_no_data' | language"
                  (change)="spinner.show(); search_transactions()"
                >
                  <ng-template ng-header-tmp>
                    <button class="btn btn-link" (click)="selectall('types')">
                      {{ "lang_search_all" | language }}
                    </button>
                  </ng-template>
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-search="searchTerm"
                    let-index="index"
                  >
                    <span>{{ item.label }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive customResponsive">
                <table border="1" class="table CustomTable">
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>{{ "lang_request_number" | language }}</td>
                      <td>{{ "lang_description" | language }}</td>
                      <td>{{ "lang_from" | language }}</td>
                      <td>{{ "lang_to" | language }}</td>
                      <td>{{ "lang_type" | language }}</td>
                      <td>{{ "lang_module_name" | language }}</td>
                      <td>{{ "lang_forms" | language }}</td>
                      <td>{{ "lang_writer" | language }}</td>
                      <td>{{ "lang_submitter" | language }}</td>
                      <td>{{ "lang_barcode" | language }}</td>
                      <td>{{ "lang_date" | language }}</td>
                      <td>{{ "lang_action" | language }}</td>
                    </tr>
                  </thead>
                  <tbody *ngIf="listData.length != '0'" class="AppendList">
                    <tr
                      *ngFor="
                        let list of listData.list;
                        let i = index;
                        let odd = odd;
                        let even = even
                      "
                      [ngClass]="{ odd: odd, even: even }"
                      class="{{ list.row_color }}{{ list.row_color_flash }}"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ list.transaction_request_id }}</td>
                      <td>{{ list.communication_subject }}</td>
                      <td>{{ list.communication_from_name }}</td>
                      <td>{{ list.communication_to_name }}</td>
                      <td>{{ list.communication_types_name }}</td>
                      <td>{{ list.modules_name }}</td>
                      <td>{{ list.transactions_name }}</td>
                      <td>{{ list.communication_user_name_writer }}</td>
                      <td>{{ list.transactions_submitter_user_name }}</td>
                      <td>{{ list.tbv_barcodeData }}</td>
                      <td>
                        {{ list.communication_date_m }} -
                        {{ list.communication_date_h }}
                      </td>
                      <td>
                        <i
                          matTooltip="{{ 'lang_view' | language }}"
                          (click)="
                            ds.preview_pdf_file(
                              list.file_path,
                              'communication' + list?.transaction_request_id
                            )
                          "
                          class="fa fa fa-paperclip mnabricon faicon star"
                          aria-hidden="true"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="listData.length == '0'" class="AppendList">
                    <tr class="odd">
                      <td colspan="14" class="nodata" align="center">
                        {{ nodata }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="listData.length != '0'" class="card-footer padb0">
          <div class="row responsive_pagination">
            <div
              class="col-lg-3 col-sm-3 mb-10"
              style="height: fit-content; margin: auto 0"
            >
              {{ "lang_total" | language }} :
              {{ listData?.page?.total_records }}
            </div>
            <div
              class="col-lg-6 col-sm-6 mb-10"
              style="display: flex; justify-content: center"
            >
              <!--  <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="10" checked (click)="loadFrmPagesize(10)"> 10</mat-radio-button>
                            <mat-radio-button value="20" (click)="loadFrmPagesize(20)"> 20 </mat-radio-button>
                            <mat-radio-button value="50" (click)="loadFrmPagesize(50)"> 50 </mat-radio-button>
                            <mat-radio-button value="100" (click)="loadFrmPagesize(100)"> 100 </mat-radio-button>
                            <mat-radio-button value="500" (click)="loadFrmPagesize(500)"> 500 </mat-radio-button>
                        </mat-radio-group> -->
              <ngb-pagination
                class="m-auto"
                [collectionSize]="listData?.page?.total_records"
                [rotate]="true"
                [ellipses]="false"
                [maxSize]="3"
                [boundaryLinks]="true"
                [(page)]="pageno"
                [pageSize]="pagesize"
                (pageChange)="loadPageFrm($event)"
              ></ngb-pagination>
            </div>
            <div
              class="col-lg-3 col-sm-3"
              style="display: flex; justify-content: end; margin: auto"
            >
              <!-- {{'lang_goto' | language }} :
                        <select class="customselect" (change)="loadPageFrm($event.target.value)">
                            <option *ngFor="let ks of ds.getrange(listData.page.total_pages)" value="{{ks}}">{{ks}}
                            </option>
                        </select> -->

              <mat-select
                style="max-width: 150px"
                [(ngModel)]="pagesize"
                (selectionChange)="loadFrmPagesize($event.value)"
              >
                <!-- <mat-option value="1">1  </mat-option> -->
                <mat-option class="text-center" value="10"
                  >10 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="20"
                  >20 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="50"
                  >50 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="100"
                  >100 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option class="text-center" value="500"
                  >500 {{ "lang_per_page" | language }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
