import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddUpdateConstantComponent } from "./add-update-constant/add-update-constant.component";

@Component({
	selector: "app-ds-constants",
	templateUrl: "./ds-constants.component.html",
	styleUrls: ["./ds-constants.component.scss"],
})
export class DsConstantsComponent implements OnInit {
	searchKey = "";
	page_no = 1;
	page_size = 10;
	constants = null;
	nodata: string = this.lang.transform("lang_loading");
	constant_type_list = [];
	constant_type;
	constructor(
		public ds: DataService,
		private dialog: MatDialog,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.getConstantTypes();
		this.loadConstants();
	}
	getConstantTypes() {
		this.ds.post("tasks/constants", {}).subscribe((res) => {
			if (res?.status) {
				this.constant_type_list = res?.data;
			} else {
				this.constant_type_list = [];
			}
		});
	}

	loadConstants() {
		let formData = new FormData();
		formData.append("constant_name", this.searchKey || "");
		formData.append("contant_type", this.constant_type || "");
		this.ds
			.post(`tasks/data_constants/${this.page_no}/${this.page_size}`, formData)
			.subscribe((res) => {
				if (res?.status) {
					this.constants = res;
				} else {
					this.constants = null;
					this.nodata = res.error;
				}
			});
	}
	onAddConstant() {
		const dialogRef = this.dialog.open(AddUpdateConstantComponent, {
			width: "400px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.loadConstants();
			}
		});
	}
	onUpdateConstant(item) {
		const dialogRef = this.dialog.open(AddUpdateConstantComponent, {
			width: "400px",
			data: {
				constant: item,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.loadConstants();
			}
		});
	}

	deleteConstant(constant) {
		this.sweetAlert
			.confirmMessage(
				this.lang.transform("lang_are_you_sure"),
				this.lang.transform("lang_yes")
			)
			.then((confirmRes) => {
				if (confirmRes.isConfirmed) {
					this.spinner.show();
					let formData = new FormData();
					formData.append("constant_id", constant?.constant_id || "");
					this.ds.post(`tasks/delete_constant`, formData).subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert.successToast(
									this.lang.transform("lang_deleted"),
									2000
								);
								this.loadConstants();
							} else {
								this.sweetAlert.errorToast(res?.error, 2000);
							}
						},
						(err) => {
							this.spinner.hide();
						}
					);
				}
			});
	}
}
