import { element } from "protractor";
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
interface list {
  id?: number;
  finance_id: string;
  branch_id: string;
  label: string;
  writer_name: string;
  created_date: string;
  finance_status: string;
}
@Component({
  selector: "app-financeyear",
  templateUrl: "./financeyear.component.html",
  styleUrls: ["./financeyear.component.scss"],
})
export class FinanceyearComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef;
  cloneModalRef: BsModalRef;
  account_codes: any = [];
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  openCloneModal(template: TemplateRef<any>) {
    this.account_codes.length = [];
    this.account_codes_array.clear();
    this.cloneModalRef = this.modalService.show(
      template,
      environment.modelconfig
    );
  }
  @ViewChild("NewFinancial") financeadd;
  @ViewChild("CloneFinancial") financeClone;
  form: FormGroup;
  listofdatas: list[];
  data: any = [];
  page = 1;
  abranches: any = [];
  pageSize = 10;
  collectionSize;
  branch_id: any =
    localStorage.getItem("selectedBranch") != null
      ? localStorage.getItem("selectedBranch")
      : "0";
  lodingdatas = this.lang.transform("lang_loading");
  formClone: FormGroup;
  financeToList = [];
  clone_tree_or_with_balances = [
    {
      label: this.lang.transform("lang_only_tree"),
      value: "1",
    },
    {
      label: this.lang.transform("lang_tree_with_amounts"),
      value: "2",
    },
  ];
  clone_non_zero_accounts = [
    {
      label: this.lang.transform("lang_no"),
      value: "0",
    },
    {
      label: this.lang.transform("lang_yes"),
      value: "1",
    },
  ];
  account_codes_array: FormArray;

  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.buildform();
    if (this.branch_id && this.branch_id != "0") {
      this.load_financial_years();
    }
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public changeBranch(branch) {
    this.spinner.show();
    this.branch_id = branch;
    this.load_financial_years();
  }
  public load_financial_years() {
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.listofdatas = res.records;
            this.collectionSize = res.records.length;
            this.refreshLists();
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.spinner.hide();
          this.data = [];
        }
      )
    );
  }
  public refreshLists() {
    this.data = this.listofdatas
      .map((list, i) => ({ id: i + 1, ...list }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }
  public load_add_branches() {
    this.abranches = [];
    this.form.reset();
    this.spinner.show();
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_add").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.abranches = res.records;
            this.openModal(this.financeadd);
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      financial_start_date: new FormControl("", [Validators.required]),
      financial_end_date: new FormControl("", [Validators.required]),
    });
    this.formClone = this.fb.group({
      branch_id: [this.branch_id, Validators.required],
      from_finance_id: [null, Validators.required],
      to_finance_id: [null, Validators.required],
      clone_tree_or_with_balances: [null, Validators.required],
      clone_non_zero_accounts: [null, Validators.required],
      Account_Codes: this.fb.array([]),
    });
    this.account_codes_array = this.formClone.get("Account_Codes") as FormArray;
  }
  public emptyform() {
    this.alert.clear();
    this.form.reset();
    this.form.get("branch_id").setValue(this.branch_id);
  }
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.append("branch_id", this.branch_id);
    param.append(
      "financial_start_date",
      this.form.get("financial_start_date").value || ""
    );
    param.append(
      "financial_end_date",
      this.form.get("financial_end_date").value || ""
    );
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "acc/createfyear").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.form.reset();
            this.load_financial_years();
            this.alert.success(res.msg);
            this.modalRef.hide();
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public close_financial_year(his) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true,
      width: "400px",
      data: {
        title: this.lang.transform("lang_confirmation"),
        message: this.lang.transform("lang_are_you_sure"),
      },
    });
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          this.subscriptions.add(
            this.ds
              .getActionByUrl(
                [],
                "acc/closeyear/" + his.branch_id + "/" + his.finance_id
              )
              .subscribe(
                (data) => {
                  this.spinner.hide();
                  if (data.status) {
                    this.load_financial_years();
                    this.ds.dialogf("", data.msg);
                  } else {
                    this.ds.dialogf("", data.error);
                  }
                },
                (error) => {
                  this.spinner.hide();
                  this.ds.dialogf(
                    "",
                    error && error.error && error.error.error
                      ? error.error.error
                      : this.lang.transform("lang_internal_server_error")
                  );
                }
              )
          );
        }
      })
    );
  }
  onCloneFinancialYear(item) {
    this.getToFinanceList();
    this.clearCloneForm();
    this.formClone.get("from_finance_id").setValue(item?.finance_id);
    this.openCloneModal(this.financeClone);
  }
  clone_financial_year() {
    let formData = new FormData();
    formData.append("branch_id", this.formClone.get("branch_id").value || "");
    formData.append(
      "from_finance_id",
      this.formClone.get("from_finance_id").value || ""
    );
    formData.append(
      "to_finance_id",
      this.formClone.get("to_finance_id").value || ""
    );
    formData.append(
      "clone_tree_or_with_balances",
      this.formClone.get("clone_tree_or_with_balances").value || ""
    );
    formData.append(
      "clone_non_zero_accounts",
      this.formClone.get("clone_non_zero_accounts").value || ""
    );
    this.account_codes_array?.controls?.forEach((el) => {
      formData.append(
        `rows[${el?.get("account_code")?.value}]`,
        el?.get("value")?.value
      );
    });
    this.spinner.show();
    this.ds.postActionByUrl(formData, "acc/clonefyear").subscribe((res) => {
      this.spinner.hide();
      if (res?.status) {
        this.alert.success(res?.msg);
        this.cloneModalRef.hide();
      } else {
        this.alert.error(res?.error);
        this.account_codes_array.clear();
        this.formClone.reset();
        this.account_codes = res?.account_codes || [];
        this.account_codes.forEach((element) => {
          this.add_Account_Codes(element);
        });
      }
    });
  }
  clearCloneForm() {
    this.formClone.get("to_finance_id").reset();
    this.formClone.get("clone_tree_or_with_balances").reset();
    this.formClone.get("clone_non_zero_accounts").reset();
  }

  reopen_financial_year(item) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true,
      width: "400px",
      data: {
        title: this.lang.transform("lang_confirmation"),
        message: this.lang.transform("lang_are_you_sure"),
      },
    });
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          let formData = new FormData();
          formData.append("branch_id", item?.branch_id || "");
          formData.append("finance_id", item?.finance_id || "");
          this.spinner.show();
          this.subscriptions.add(
            this.ds.postActionByUrl(formData, "acc/reopenfyear").subscribe(
              (data) => {
                this.spinner.hide();
                if (data.status) {
                  this.load_financial_years();
                  this.ds.dialogf("", data.msg);
                } else {
                  this.ds.dialogf("", data.error);
                }
              },
              (error) => {
                this.spinner.hide();
                this.ds.dialogf(
                  "",
                  error && error.error && error.error.error
                    ? error.error.error
                    : this.lang.transform("lang_internal_server_error")
                );
              }
            )
          );
        }
      })
    );
  }

  getToFinanceList() {
    let formData = new FormData();
    formData.append("branch_id", this.branch_id || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(this.ds.formData2string(formData), "acc/current_fyears")
      .subscribe((res) => {
        this.spinner.hide();
        if (res?.status) {
          this.financeToList = res?.records?.map((el) => {
            return {
              label: el?.finance_start_date + " - " + el?.finance_end_date,
              value: el?.finance_id,
            };
          });
        } else {
          this.financeToList = [];
        }
      });
  }

  public Account_Codes_Group() {
    return this.formClone.get("Account_Codes") as FormArray;
  }
  get Account_Codes_Groups() {
    return this.formClone.get("Account_Codes") as FormArray;
  }
  public Account_Codes_Records(account_code): FormGroup {
    return this.fb.group({
      account_code: new FormControl(account_code, [Validators.required]),
      value: new FormControl("", [Validators.required]),
    });
  }
  public add_Account_Codes(element) {
    this.account_codes_array.push(this.Account_Codes_Records(element));
  }
  public remove_Account_Codes(index) {
    this.account_codes_array.removeAt(index);
  }
}
