import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import moment from "moment";
import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";
declare var $: any;

@Component({
	selector: "JFstep-one-configrations",
	templateUrl: "./JFstep-one-configrations.component.html",
	styleUrls: ["./JFstep-one-configrations.component.scss"],
})
export class JFStepOneConfigrationsComponent implements OnInit {
	@Output() contractEvent = new EventEmitter();
	employee_data;
	@Input() form_request_id;
	@Input() form_key;
	@Input() id;
	@Input() branch_id;
	/* @Input() allData */
	allData;
	/* _allowDay: any;
    get allowDay(): boolean {
        return this._allowDay;
    } */
	@Input() set alldata(value: any) {
		this.allData = value;
		/* 
      
        transaction_from                  : new FormControl({value : '', disabled : false}, [Validators.required]),
        transaction_to                    : new FormControl('', [Validators.required]),
        work_domain                       : new FormControl('', [Validators.required]),
        work_location                     : new FormControl('', [Validators.required]),
        work_type                         : new FormControl('', [Validators.required]),
        employee_number                   : new FormControl(''),
       
        joining_date_english              : new FormControl(''),
        joining_date_arabic               : new FormControl(null),
        probation_period                  : new FormControl(null),
        contract_start_date_english       : new FormControl(''),
        contract_period                   : new FormControl(null),
        contract_end_date_english         : new FormControl({value : '', disabled : true}),
        vacation_paid_days                : new FormControl(null, [Validators.required]),
        vacation_custom_date              : new FormControl(''),
        working_days_per_week             : new FormControl(null, [Validators.required]),
        working_hours_type             : new FormControl("day", [Validators.required]),     
        basic_salary                      : new FormControl('', [Validators.required]),
        home_allowance                    : new FormControl(''),
       
        working_hours                : new FormControl(''),// Need to be added
        needOtherAllowances               : new FormControl('0', [Validators.required]),
        other_allowances                  : this.fb.array([]),
         needOtherTerms                    : new FormControl('0', [Validators.required]),
        other_terms                       : this.fb.array([]),
      */
		moment.locale("en");
		this.form.patchValue({
			subject: value?.form_jf_data?.records?.subject,
			transaction_from: value?.form_jf_data?.records?.transaction_from,
			transaction_to: value?.form_jf_data?.records?.transaction_to,
			work_domain: value?.form_jf_data?.records?.tr_work_domain,
			work_location: value?.form_jf_data?.records?.tr_work_location,
			work_type: value?.form_jf_data?.records?.tr_work_type,
			employee_number: value?.form_jf_data?.records?.employee_number,
			joining_date_english: new Date(
				value?.form_jf_data?.records?.joining_date_english
			),
			joining_date_arabic: value?.form_jf_data?.records?.joining_date_arabic
				? this.ds.arcon(value?.form_jf_data?.records?.joining_date_arabic)
				: "",
			probation_period: value?.form_jf_data?.records?.probation_period,
			contract_start_date_english: moment(
				value?.form_jf_data?.records?.contract_start_date_english,
				"YYYY/MM/DD"
			).format(),
			contract_period: value?.form_jf_data?.records?.contract_period,
			contract_end_date_english: moment(
				value?.form_jf_data?.records?.contract_end_date_english,
				"YYYY/MM/DD"
			).format("DD/MM/YYYY"),
			vacation_paid_days: value?.form_jf_data?.records?.vacation_paid_days,
			/*  vacation_custom_date:value?.form_jf_data?.records?.vacation_custom_date ? moment(value?.form_jf_data?.records?.vacation_custom_date,'YYYY/MM/DD').format():'', */
			working_days_per_week:
				value?.form_jf_data?.records?.working_days_per_week,
			working_hours_type: value?.form_jf_data?.records?.working_hours_type,
			basic_salary: value?.form_jf_data?.records?.basic_salary,
			home_allowance: value?.form_jf_data?.records?.home_allowance,
			working_hours: value?.form_jf_data?.records?.working_hours,
			signature_a_user_id: value?.form_jf_data?.records?.first_party_user,
			needOtherAllowances:
				value?.form_jf_data_allowances?.records?.length > 0 ? 1 : 0,
		});

		this.users.push({
			label: value?.form_jf_data?.records?.first_party_user_name,
			value: value?.form_jf_data?.records?.first_party_user,
		});

		if (value?.form_jf_data_allowances?.records?.length > 0) {
			this.otherallowances = true;
			for (
				let index = 0;
				index < value?.form_jf_data_allowances?.records?.length;
				index++
			) {
				const element = value?.form_jf_data_allowances?.records[index];
				this.add_new_others({
					other_allowance_description_en:
						element.other_allowance_description_en,
					other_allowance_description_ar:
						element.other_allowance_description_ar,
					other_allowance_amount: element.other_allowance_amount,
				});
			}
		}
		if (value?.form_jf_data_additional_terms?.records?.length > 0) {
			this.otherterms = true;
			for (
				let index = 0;
				index < value?.form_jf_data_additional_terms?.records?.length;
				index++
			) {
				const element = value?.form_jf_data_additional_terms?.records[index];
				this.add_new_terms(element);
			}
		}

		this.calculate_net_amount();
	}
	form = new FormGroup({
		subject: new FormControl(this.lang.transform("lang_create_jop_offer"), [
			Validators.required,
		]),
		transaction_from: new FormControl({ value: "", disabled: false }, [
			Validators.required,
		]),
		transaction_to: new FormControl({ value: "", disabled: true }),
		work_domain: new FormControl("", [Validators.required]),
		work_location: new FormControl("", [Validators.required]),
		work_type: new FormControl("", [Validators.required]),
		employee_number: new FormControl(""),
		needOtherTerms: new FormControl("0", [Validators.required]),
		other_terms: this.fb.array([]),
		joining_date_english: new FormControl(""),
		joining_date_arabic: new FormControl(null),
		probation_period: new FormControl(null),
		contract_start_date_english: new FormControl(""),
		contract_period: new FormControl(null),
		contract_end_date_english: new FormControl({ value: "", disabled: true }),
		vacation_paid_days: new FormControl(null, [Validators.required]),
		/*  vacation_custom_date              : new FormControl(''), */
		working_days_per_week: new FormControl(null, [Validators.required]),
		working_hours_type: new FormControl("day", [Validators.required]),
		basic_salary: new FormControl("", [Validators.required]),
		home_allowance: new FormControl(""),

		working_hours: new FormControl(""), // Need to be added
		needOtherAllowances: new FormControl("0", [Validators.required]),
		other_allowances: this.fb.array([]),
		signature_a_title: new FormControl("First Party", [Validators.required]),
		signature_a_user_id: new FormControl(null, [Validators.required]),
		signature_b_title: new FormControl(""),
		signature_b_user_id: new FormControl(null),
	});

	other_allownces_datas: FormArray = this.form.get(
		"other_allowances"
	) as FormArray;
	other_terms_datas: FormArray = this.form.get("other_terms") as FormArray;
	/* other_allownces_datas:FormArray;
  other_terms_datas:FormArray; */
	lang_key = localStorage.getItem("lang_key") || "en";
	submitted = false;
	@Input() cperiods: any = [];
	@Input() pperiods: any = [];
	@Input() vacationdays: any = [];
	@Input() workingdays: any = [];
	@Input() workinghours: any = [];
	languages: any = [];
	users: any = [];
	markstatus = false;
	reviewstatus = false;
	otherallowances = false;
	otherterms = false;
	showmarkdelete = true;
	showreviewdelete = true;
	probationenddate = "";
	net_amount: any = 0.0;
	vacation_custom_date = "";
	firstFormGroup: FormGroup | any = new FormGroup({});

	branches: any = [];
	constructor(
		public ds: DataService,
		private route: ActivatedRoute,
		public datepipe: DatePipe,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.spinner.show();
		moment.locale("en");

		this.get_required_data("tc/tbranches");
	}
	private subscriptions = new Subscription();
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
	public get_required_data(url) {
		this.branches = [];
		this.subscriptions.add(
			this.ds.getActionByUrl([], url).subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.branches = res.records;
				}
			})
		);
	}
	viewdata = "";
	attachments = "";
	licences = "";
	profile_percentage = "";
	viewstatus = false;
	public get_view_details() {
		this.spinner.show();
		this.subscriptions.add(
			this.ds
				.getActionByUrl(
					[],
					"human_resources/get_application_data/" +
						this.allData.form_jf_data.records.employee_number
				)
				.subscribe(
					(data) => {
						this.spinner.hide();
						this.viewstatus = data.status;
						if (data.status) {
							this.viewdata = data.data;
							this.employee_data = data.data;
							this.attachments = data.attachments;
							this.licences = data.licences;
							if (data.profile_percentage) {
								this.profile_percentage = data.profile_percentage.total;
							}

							/*  if (this.viewdata.profile_image) {
              this.get_profile_image(this.viewdata.profile_image);
            }
            this.load_my_attachments();
            this.attachment_types(); */
						} else {
							this.spinner.hide();
							this.router.navigate(["human_resources/applications"]);
							this.ds.dialogf("", data.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.viewstatus = false;
						this.ds.dialogf(
							"",
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}

	public isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44 || charCode == 190 || charCode == 46) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57 || charCode == 101)) {
			return false;
		}
		return true;
	}

	public load_required_datas() {
		let param = new FormData();
		param.append("lang_key", this.lang_key);
		param.append(
			"employee_number",
			this.allData.form_jf_data.records.employee_number
		);
		let url = [
			"form/FORM_CT1/ctperiods",
			"form/FORM_CT1/pbperiods",
			"form/FORM_CT1/vacationdays",
			"form/FORM_CT1/weekdays",
			"form/FORM_CT1/workinghours",
			"form/FORM_CT1/appusers",
		];
		this.subscriptions.add(
			this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(
				(res) => {
					this.spinner.hide();
					if (res[0].status) {
						this.cperiods = res[0].records;
					}
					if (res[1].status) {
						this.pperiods = res[1].records;
					}
					if (res[2].status) {
						this.vacationdays = res[2].records;
					}
					if (res[3].status) {
						this.workingdays = res[3].records;
					}
					if (res[4].status) {
						this.workinghours = res[4].records;
					}
					if (res[5].status) {
						this.users = res[5].records;
						let marks = [];
						let reviews = [];
						this.users.forEach((v, k) => {
							if (v.position == "A" && v.value) {
								this.form.get("signature_a_title").setValue(v.title);
								this.form.get("signature_a_user_id").setValue(v.value);
							}
							if (v.position == "B" && v.value) {
								this.form.get("signature_b_title").setValue(v.title);
								this.form.get("signature_b_user_id").setValue(v.value);
							}
							if (v.position == "C" && v.value) {
								this.form.get("signature_c_title").setValue(v.title);
								this.form.get("signature_c_user_id").setValue(v.value);
							}
							if (v.position == "M" && v.value) {
								marks.push(v.value);
							}
							if (v.position == "R" && v.value) {
								reviews.push(v.value);
							}
						});
						if (this.form.get("signature_b_user_id").value) {
						} else {
							this.form.get("signature_b_title").setValue("");
							this.form.get("signature_b_title").enable();
							this.form.get("signature_b_user_id").setValue(null);
							this.form.get("signature_b_user_id").enable();
						}
						if (marks.length > 0) {
							// marks
							this.markstatus = true;
							this.showmarkdelete = false;
							this.form.get("marks").setValue(marks);
							this.form.get("needMark").setValue("1");
							//this.form.get('marks').disable();
						} else {
							this.showmarkdelete = true;
							this.markstatus = false;
							this.form.get("marks").setValue(null);
							this.form.get("marks").enable();
							this.form.get("needMark").setValue("0");
						}
						if (reviews.length > 0) {
							// reviews
							this.reviewstatus = true;
							this.showreviewdelete = false;
							this.form.get("reviews").setValue(reviews);
							this.form.get("needReview").setValue("1");
							//this.form.get('reviews').disable();
						} else {
							this.reviewstatus = false;
							this.showreviewdelete = true;
							this.form.get("reviews").setValue(null);
							this.form.get("reviews").enable();
							this.form.get("needReview").setValue("0");
						}
					}
				},
				(error) => {
					this.spinner.hide();
				}
			)
		);
	}
	//Other Allowances Start
	get othersgroup() {
		return this.form.get("other_allowances") as FormArray;
	}
	public others_group() {
		return this.form.get("other_allowances") as FormArray;
	}
	public add_new_others(obj: any = false) {
		if (this.other_allownces_datas.length < 5) {
			this.other_allownces_datas.push(this.other_records(obj));
		}
	}
	public other_records(obj: any = false): FormGroup {
		return this.fb.group({
			other_allowance_description_en: new FormControl(
				obj.other_allowance_description_en,
				[Validators.required]
			),
			other_allowance_amount: new FormControl(obj.other_allowance_amount, [
				Validators.required,
			]),
			other_allowance_description_ar: new FormControl(
				obj.other_allowance_description_ar,
				[Validators.required]
			),
		});
	}
	public remove_other_items(index) {
		if (this.other_allownces_datas.length > 1) {
			this.other_allownces_datas.removeAt(index);
		}
	}
	//Other Allowances End
	//Other Terms & Conditions Start
	get termsgroup() {
		return this.form.get("other_terms") as FormArray;
	}
	public terms_group() {
		return this.form.get("other_terms") as FormArray;
	}
	public add_new_terms(obj: any = false) {
		if (this.other_terms_datas.length < 20) {
			this.other_terms_datas.push(this.terms_records(obj));
		}
	}
	public terms_records(obj: any = false): FormGroup {
		return this.fb.group({
			terms_description_english: new FormControl(obj.terms_content_english, [
				Validators.required,
			]),
			terms_description_arabic: new FormControl(obj.terms_content_arabic, [
				Validators.required,
			]),
		});
	}
	public remove_terms_items(index) {
		if (this.other_terms_datas.length > 1) {
			this.other_terms_datas.removeAt(index);
		}
	}
	public calculate_net_amount() {
		let amount: any = 0;
		let basic_salary: any = parseFloat(
			this.form.get("basic_salary").value || 0
		);
		let home_allowance: any = parseFloat(
			this.form.get("home_allowance").value || 0
		);
		this.other_allownces_datas.value.forEach((v, k) => {
			amount += parseFloat(v.other_allowance_amount || 0);
		});
		this.net_amount = (
			parseFloat(basic_salary) +
			parseFloat(home_allowance) +
			parseFloat(amount)
		).toFixed(2);
	}
	//Other Terms & Conditions End
	public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append("search", key.term);
		param.append("lang_key", this.lang_key);
		param.append("user_type_id", "1");
		this.subscriptions.add(
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.users = res.list;
					}
				})
		);
	}
	public enable_field(field) {
		if (field == "mark") {
			this.form.get("needMark").setValue("1");
			this.form.get("marks").setValidators(Validators.required);
			this.form.get("marks").updateValueAndValidity();
			this.markstatus = true;
		} else if (field == "review") {
			this.form.get("needReview").setValue("1");
			this.form.get("reviews").setValidators(Validators.required);
			this.form.get("reviews").updateValueAndValidity();
			this.reviewstatus = true;
		} else if (field == "allowances") {
			this.otherallowances = true;
			this.other_allownces_datas.clear();
			this.form.get("needOtherAllowances").setValue("1");
			this.add_new_others();
		} else if (field == "terms") {
			this.otherterms = true;
			this.other_terms_datas.clear();
			this.form.get("needOtherTerms").setValue("1");
			this.add_new_terms();
		}
	}
	public disable_field(field) {
		if (field == "mark") {
			this.form.get("needMark").setValue("0");
			this.form.get("marks").setValue(null);
			this.form.get("marks").clearValidators();
			this.form.get("marks").updateValueAndValidity();
			this.markstatus = false;
		} else if (field == "review") {
			this.form.get("needReview").setValue("0");
			this.form.get("reviews").setValue(null);
			this.form.get("reviews").clearValidators();
			this.form.get("reviews").updateValueAndValidity();
			this.reviewstatus = false;
		} else if (field == "allowances") {
			this.form.get("needOtherAllowances").setValue("0");
			this.other_allownces_datas.clear();
			this.otherallowances = false;
		} else if (field == "terms") {
			this.form.get("needOtherTerms").setValue("0");
			this.other_terms_datas.clear();
			this.otherterms = false;
		}
		this.form.updateValueAndValidity();
	}
	public calculate_contract_enddate() {
		this.form.get("contract_end_date_english").setValue("");
		if (
			this.form.get("contract_start_date_english").value &&
			this.form.get("contract_period").value &&
			this.form.get("contract_period").value != "0"
		) {
			let date = new Date(this.form.get("contract_start_date_english").value);
			let finaldate = date.setFullYear(
				date.getFullYear() + parseInt(this.form.get("contract_period").value)
			);
			let Edate = new Date(
				this.datepipe.transform(new Date(finaldate), "yyyy/MM/dd")
			);
			this.form
				.get("contract_end_date_english")
				.setValue(
					this.datepipe.transform(
						Edate.setDate(date.getDate() - 1),
						"yyyy/MM/dd"
					)
				);
		}
	}
	public probation_enddate() {
		this.probationenddate = "";
		if (
			this.form.get("joining_date_english").value &&
			this.form.get("contract_start_date_english").value != undefined &&
			this.form.get("probation_period").value &&
			this.form.get("probation_period").value != "0"
		) {
			let date = new Date(this.form.get("joining_date_english").value);
			let finaldate = date.setMonth(
				date.getMonth() + parseInt(this.form.get("probation_period").value)
			);
			this.probationenddate = this.datepipe.transform(
				new Date(finaldate),
				"yyyy/MM/dd"
			);
		}
	}
	public vacation_enddate() {
		//this.form.get('vacation_custom_date').setValue('');
		this.vacation_custom_date = "";
		if (
			this.form.get("contract_start_date_english").value &&
			this.form.get("vacation_paid_days").value &&
			this.form.get("vacation_paid_days").value != undefined
		) {
			let date = new Date(this.form.get("contract_start_date_english").value);
			let vdays = this.form.get("vacation_paid_days").value.split("-");
			let finaldate1 = new Date(
				date.setFullYear(date.getFullYear() + parseInt(vdays[1]))
			);
			let cperiod = this.form.get("contract_period").value || 0;
			if (parseInt(cperiod) >= parseInt(vdays[1]) || parseInt(cperiod) == 0) {
				let finaldate = finaldate1.setDate(date.getDate() - parseInt(vdays[0]));
				/*  this.vacation_custom_date = this.datepipe.transform(new Date(finaldate), 'yyyy/MM/dd') */
			} else {
				this.ds.dialogf("", this.lang.transform("lang_valida_vacation_days"));
			}
		}
	}
	public preview_form() {
		this.alert.clear();
		this.submitted = true;
		if (this.form.valid) {
			this.submitted = false;
			this.spinner.show();
			this.subscriptions.add(
				this.ds
					.postActionByUrl(this.getformdata(), "form/FORM_CT1/pr/0")
					.subscribe(
						(data) => {
							this.spinner.hide();
							if (data.status) {
								let id = "showPDFPreview";
								if (data.extension != "zip") {
									($("#" + id) as any).modal({
										backdrop: "static",
										keyboard: false,
									});
									($("#" + id) as any).modal("show");
								}
								document.querySelector("iframe").src =
									"data:" + data["content-type"] + ";base64," + data.base64;
							} else {
								this.alert.error(data.error);
							}
						},
						(error) => {
							this.spinner.hide();
							this.alert.error(
								error && error.error && error.error.error
									? error.error.error
									: this.lang.transform("lang_internal_server_error")
							);
						}
					)
			);
		} else {
			this.alert.error(this.lang.transform("lang_fill_required_fields"));
		}
	}

	goBack(stepper: MatStepper) {
		stepper.previous();
	}

	goForward(stepper: MatStepper) {
		stepper.next();
	}

	@Output() updateForm = new EventEmitter();

	public submit() {
		this.alert.clear();
		this.submitted = true;
		if (this.form.valid) {
			this.submitted = false;
		}
		this.spinner.show();
		this.form.patchValue({
			employee_number: this.allData.form_jf_data.records.employee_number,
		});
		this.subscriptions.add(
			this.ds
				.postActionByUrl(
					this.getformdata(),
					`form/FORM_JF/et/${this.form_request_id}`
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							//this.alert.success(res.msg);
							this.alert.success(this.lang.transform("lang_success"));
							this.form.reset();
							/*  this.goForward(stepper) */

							this.updateForm.emit("");
						} else {
							this.alert.error(res.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}
	private getformdata() {
		let param = this.ds.json2formdata({
			/*   ...this.form.value, */
			/* ...this.firstFormGroup.value, */
			...this.form.value,
		});
		param.set("first_party_user", this.form.value.signature_a_user_id);

		param.set(
			"contract_start_date_english",
			moment(this.form.get("contract_start_date_english").value).format(
				"YYYY/MM/DD"
			) || ""
		);
		param.set(
			"joining_date_english",
			moment(this.form.get("joining_date_english").value).format(
				"YYYY/MM/DD"
			) || ""
		);
		/*   param.set('vacation_custom_date', moment(this.form.get('vacation_custom_date').value).format('YYYY/MM/DD') || '');
		 */

		/*  param.append('signature_a_title', this.form.get('signature_a_title').value|| '');
    param.append('signature_a_user_id', this.form.get('signature_a_user_id').value|| '');
    param.append('signature_b_title', this.form.get('signature_b_title').value || '');
    param.append('signature_b_user_id', this.form.get('signature_b_user_id').value || ''); */
		return param;
	}

	selected;
	isLinear = true;
	selectedIndex: number = 0;
	selectionChange(e: any) {
		this.selectedIndex = e.selectedIndex;
	}
}
