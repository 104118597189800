import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

@Component({
	selector: "app-t-step11",
	templateUrl: "./t-step11.component.html",
	styleUrls: ["./t-step11.component.scss"],
})
export class TStep11Component implements OnInit {
	@Output() refreshComponent = new EventEmitter<boolean>();
	@Input() form_key;
	@Input() form_request_id;
	@Input() project_data;
	@Input() supervision_data;
	@Input() template_data;
	@Input() lastStepOpened;
	constructor(
		public ds: DataService,
		public datepipe: DatePipe,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {}
}
