import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
  selector: "app-show-deductions",
  templateUrl: "./show-deductions.component.html",
  styleUrls: ["./show-deductions.component.scss"],
})
export class ShowDeductionsComponent implements OnInit {
  info: any = "";
  userTransactions = [];
  t_id = "";
  type;
  constructor(
    public dialogRef: MatDialogRef<ShowDeductionsComponent>,
    public ds: DataService,
    private spinner: PreloaderService,
    public lang: LanguagePipe,
    private _sweetAlertService: SweetAlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.info = data.info;
    this.userTransactions = data.userTransactions;
    console.log(data.userTransactions);

    this.t_id = data.t_id;
    this.type = data.type;
    this.checkSelect();
  }

  allSelected = false;

  checkEachItemHR(e, index) {
    if (e?.target.checked) {
      this.userTransactions[index].select_status = "selected";
    } else {
      this.userTransactions[index].select_status = "unselected";
    }
    this.checkSelect();
  }

  checkSelect() {
    let allLocalSelected = true;
    for (let index = 0; index < this.userTransactions.length; index++) {
      const element = this.userTransactions[index];
      if (element.select_status == "unselected") {
        allLocalSelected = false;
      }
    }
    if (allLocalSelected) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }
  selectAll(e) {
    if (e?.target.checked) {
      this.userTransactions = this.userTransactions.map((t) => {
        return {
          ...t,
          select_status: "selected",
        };
      });

      this.allSelected = true;
    } else {
      this.userTransactions = this.userTransactions.map((t) => {
        return {
          ...t,
          select_status: "unselected",
        };
      });
    }

    this.allSelected = false;
  }

  ngOnInit(): void {}

  cancel(status = false) {
    this.dialogRef.close(status);
  }

  sendFilterForm() {
    if (this.userTransactions.length > 0) {
      this.spinner.show();
      let formData = new FormData();

      for (let index = 0; index < this.userTransactions.length; index++) {
        const element = this.userTransactions[index];
        if (this.type == "earning" && element.credit_amount != "0.00") {
          formData.append(
            `financial_transaction[${index + 1}][id]`,
            element.finance_id
          );
          formData.append(
            `financial_transaction[${index + 1}][select_status]`,
            element.select_status == "selected" ? "unselected" : "selected"
          );
        } else if (element.debit_amount != "0.00" && this.type == "deduction") {
          formData.append(
            `financial_transaction[${index + 1}][id]`,
            element.finance_id
          );
          formData.append(
            `financial_transaction[${index + 1}][select_status]`,
            element.select_status == "selected" ? "unselected" : "selected"
          );
        }
      }

      this.ds
        .post(
          `form/FORM_SAL/select_unselect_transaction/${this.info.employee_number}`,
          formData
        )
        .subscribe(
          (data) => {
            if (data.status) {
              this.cancel(true);
              this._sweetAlertService.successToast(
                this.lang.transform("lang_data_sent_successfully"),
                2000
              );
              this.spinner.hide();
            } else {
              this.spinner.hide();
              this._sweetAlertService.errorMessage(data.error);
            }
          },
          (error) => {
            this._sweetAlertService.errorMessage(error?.error?.error);
            this.spinner.hide();
          }
        );
    } else {
      this._sweetAlertService.warningToast(
        this.lang.transform("lang_select_anyone"),
        2000
      );
    }
  }
}
