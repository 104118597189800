<div class="mbrl15">
	<div class="row">
		<div class="col-md-3 col-sm-4 col-xs-12 mb-10">
			<ng-select
				[items]="group1List"
				[multiple]="false"
				bindLabel="label"
				appendTo="body"
				bindValue="value"
				clearAllText="Clear"
				[(ngModel)]="selected_group1"
				placeholder="{{ 'lang_group' | language }} 1"
				(change)="get_initial_delivery_platforms(); getGroup2List()"
			>
			</ng-select>
		</div>
		<div class="col-md-3 col-sm-4 col-xs-12 mb-10">
			<ng-select
				[items]="group2List"
				[multiple]="false"
				bindLabel="label"
				appendTo="body"
				bindValue="value"
				clearAllText="Clear"
				[(ngModel)]="selected_group2"
				placeholder="{{ 'lang_group' | language }} 2"
				(change)="get_initial_delivery_platforms()"
			>
			</ng-select>
		</div>
		<div class="col-md-6 col-sm-4 col-xs-12 mb-10">
			<div class="input-group input-group-sm">
				<input
					type="text"
					class="form-control almnabrformcontrol"
					[(ngModel)]="searchKey"
					(keyup)="get_initial_delivery_platforms()"
					placeholder="{{ 'lang_search' | language }}"
				/>
				<span class="input-group-btn" *ngIf="makeActionInitial">
					<button
						id="btnSearch"
						type="button"
						matTooltip="{{ 'lang_add' | language }}"
						class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
						(click)="createUpdateInitialDelivery()"
					></button>
				</span>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_group_one" | language }}</td>
							<td>{{ "lang_group_two" | language }}</td>
							<td>{{ "lang_platform" | language }}</td>
							<td>{{ "lang_writer" | language }}</td>
							<td>{{ "lang_ondate" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody *ngIf="initial_delivery_platform.length" class="AppendList">
						<tr
							*ngFor="
								let list of initial_delivery_platform;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ list.group1name }}</td>
							<td>{{ list.group2name }}</td>
							<td>{{ list.platformname }}</td>
							<td>{{ list.writer }}</td>
							<td>{{ list.created_datetime }}</td>
							<td>
								<i
									class="fa fa-trash red"
									*ngIf="makeActionInitial"
									(click)="
										deleteInitialDelivery(list?.initial_deliveries_platform_id)
									"
									matTooltip="{{ 'lang_delete' | language }}"
								></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
<div
	*ngIf="initial_delivery_platform?.length > 0"
	class="padb0 card-footer padb0 ng-star-inserted"
>
	<div class="row responsive_pagination">
		<div
			class="col-lg-3 col-sm-3 mb-10"
			style="height: fit-content; margin: auto 0"
		>
			{{ "lang_total" | language }} : {{ collection_size }}
		</div>
		<div
			class="col-lg-5 col-sm-5 mb-10"
			style="display: flex; justify-content: center"
		>
			<ngb-pagination
				class="m-auto"
				[collectionSize]="collection_size"
				[rotate]="true"
				[ellipses]="false"
				[maxSize]="3"
				[boundaryLinks]="true"
				[(page)]="page_no"
				[pageSize]="page_size"
				(pageChange)="get_initial_delivery_platforms()"
			></ngb-pagination>
		</div>
		<div
			class="col-lg-3 col-sm-3"
			style="display: flex; justify-content: end; margin: auto"
		>
			<mat-select
				[(ngModel)]="page_size"
				(selectionChange)="get_initial_delivery_platforms()"
			>
				<mat-option class="text-center" [value]="10"
					>10 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" [value]="20"
					>20 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" [value]="50"
					>50 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" [value]="100"
					>100 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option class="text-center" [value]="500"
					>500 {{ "lang_per_page" | language }}</mat-option
				>
			</mat-select>
		</div>
	</div>
</div>
<no-data
	style="margin: auto"
	[Opened]="initial_delivery_platform.length < 1"
></no-data>
