<div mat-dialog-title>
	<div class="contacts-header">
		<span mat-dialog-close>
			<svg fill="#fff" viewBox="0 0 24 24" width="24" height="24">
				<path
					d="m19.1 17.2-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"
				></path>
			</svg>
		</span>
		<h1>{{ contactsModalTitle }}</h1>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="mbrl15">
		<div class="row">
			<div class="col-12 contacts-title">
				<h3 class="contacts-title">Contacts</h3>
				<i
					class="fa fa-plus"
					(click)="onOpenNewChat()"
					matTooltip="{{ 'lang_new' | language }}"
					*ngIf="data?.type == 'new_chat'"
				></i>
			</div>
			<div class="col-12 contacts-list-container">
				<div class="contacts-list" *ngIf="!isSendedContact">
					<div
						class="contacts-list-item"
						*ngFor="let contact of contactList"
						(click)="onContactSelected(contact)"
					>
						<div class="cli-name">
							<p>{{ contact?.name || contact?.number }}</p>
							<small>Last seen: {{ contact?.lastSeen | dateFormnow }}</small>
						</div>
					</div>
				</div>
				<div class="select-contact-type" *ngIf="isSendedContact">
					<ng-select
						[items]="contactTypes"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						[searchFn]="ds.customSearchFn"
						appendTo="body"
						[(ngModel)]="contactType"
						placeholder="{{ 'lang_contact_type' | language }}"
					>
					</ng-select>
					<div class="sct-action">
						<button (click)="onSelectContactType()">Send</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
