<form [formGroup]="form" (ngSubmit)="addReason()">
	<div class="card contractor_approval_manager">
		<div class="card-header">
			{{ "lang_employee" | language }}
			<i
				style="padding: 0 10px"
				class="fa fa-info-circle orangestatus-color"
				matTooltip="{{ 'lang_employee_tooltip' | language }}"
			></i>
		</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row mb-10">
					<div class="col-sm-6 col-xs-12 mb-10">
						<label class="mb-10">
							{{ "lang_transaction_approval" | language }} !?.
							<span class="red"> * </span> </label
						><br />

						<mat-radio-group
							aria-label="Select an option"
							formControlName="approved"
							 	(change)="checkStatus()"
						>
							<mat-radio-button value="Reject">{{
								"lang_reject" | language
							}}</mat-radio-button>
							<mat-radio-button value="Approve">{{
								"lang_approve" | language
							}}</mat-radio-button>
						 
						</mat-radio-group>
                        <mat-error class="d-block" *ngIf="
                        submitForm &&
                        form.controls['approved']?.errors?.required
                    ">{{ "lang_field_required" | language }}</mat-error>
					</div>
					<div class="col-sm-6 col-xs-12 mb-10">
						<div
                        
						>
							<label
								>{{ "lang_notes" | language }} :
                                <span   class="red" *ngIf="rejectedValidators.length > 0">*</span>	</label
							>
							<textarea
								class="form-control almnabrformcontrol"
								formControlName="notes"
							></textarea>
							<mat-error class="d-block" *ngIf="
							submitForm &&
							form.controls['notes']?.errors?.required
						">{{ "lang_field_required" | language }}</mat-error>
						</div>
					</div>
 
				</div>

				<!-- <hr style="max-width: 80%;margin: auto;">
				<label class="mb-10">
					{{ "lang_reasons" | language }} </label> -->
				<div class="row w-100 flow-auto mt-4">

                    <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col"># <!-- <i class="fa fa-plus-square fa-icon" style="color: #0e3768 !important;" matTooltip="{{'lang_add' | language }}"></i> --></th>
                            <th scope="col">{{'lang_date'|language}}</th> 
                            <th scope="col" >{{'lang_violation_reason'|language}}</th>
                            <th scope="col">{{'lang_violation_num'|language}}</th>  
                            <th scope="col" >{{'lang_punishment'|language}}</th>
                            <th scope="col" >{{'lang_deduct_amount'|language}}</th>
                            <th scope="col" >{{'lang_comment'|language}}</th>
                          <!--   <th scope="col" >{{'lang_statement_ar'|language}}</th> -->

                          <!--   <th scope="col">{{'lang_action'|language}}</th> -->
                          </tr>
                        </thead>
                        <tbody >
                          <tr  
                          
                          formArrayName="violation_arr"
                           *ngFor="
                          let creds of form.controls?.violation_arr.value;
                          let i = index;
                          trackBy: trackByFnCustom
                          ">
                          <ng-container [formGroupName]="i">
    
                          <td scope="row">{{i+1}} </td>
                          <td >
                             <!--  <div class="col-md-12 col-sm-12 col-xs-12 ">
                                  <div class="form-group w-250">
                                    
                                      <div class="positionrelatiove">
                                          <input formControlName="violation_date_en" style="min-width: 200px;" type="text" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event,getForm(i))" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
                                          <mat-datepicker-toggle matSuffix [for]="BDeng" class="posiab"></mat-datepicker-toggle>
                                          <mat-datepicker #BDeng disabled="true"></mat-datepicker>
                                        
                                      </div>
                                  </div>

                              </div> -->
							  {{getFormByName(i,'violation_date_en').value}}
                          </td>
                          <td>
						
                            <div  class="col-md-12 col-sm-12 col-xs-12 " style="max-width: 300px;margin: auto;">
     
                           
                                <div class="form-group m-auto" style="max-width: 300px">
                                   <!--  <ng-select
                                    style="max-width: 200px;margin: auto;"
                                    [items]="searchArray"
                                    [multiple]="false"
                                    bindLabel="label"
                                    bindValue="value"
									[disabled]="true"
                                    clearAllText="Clear"
                                   readOnly="true"
                                    appendTo="body"
                                    formControlName="violation_id"
                                    placeholder="{{ 'lang_violation_reason' | language }}"
        
                                     class="customSelect"
                                       >
                                       </ng-select> -->
									   {{getViolation(getFormByName(i,'violation_id').value)}}
                                     <!--   <mat-error
                                       *ngIf="
                                       submitted &&
                                       form.controls['violation_id'].errors?.required
                                       "
                                      >
                                       {{ "lang_field_required" | language }}
                                   </mat-error> -->
                                
                                </div>
                               
                              </div>
    
                                </td>


                                <td> 
                                    {{getFormByName(i,'violation_count').value}}
                                 </td>

                                 <td> 
                                    {{getFormByName(i,'violation_punishment').value}}
                                 </td>


                                 <td> 
                                    {{+getFormByName(i,'violation_amount').value| number : '.2-2'}}
                                 </td>


                                 <td> 
                                    <textarea  id="" cols="10" rows="1" class="form-control almnabrformcontrol"  formControlName="reason_text" ></textarea>
                                    <mat-error
                                    *ngIf="
                                    submitted &&
                                    getFormByName(i,'reason_text').errors?.required
                                    "
                                >
                                    {{ "lang_field_required" | language }}
                                </mat-error>
                                 </td>
 
                               <!--  <td> 
                                    <mat-icon *ngIf="getFormByName(i,'violation_id').value"   (click)="get_employee_violations(getFormByName(i,'violation_id').value)" matTooltip="{{ 'lang_view_history' | language }}" class="mat-icon notranslate mat-tooltip-trigger mnabricon material-icons mat-icon-no-color"    >history</mat-icon>
                              
                               
                                    <mat-icon (click)="deleteViolation(i)" matTooltip="{{ 'lang_delete' | language }}"  class="mat-icon notranslate mat-tooltip-trigger mnabricon red material-icons mat-icon-no-color"   >delete_forever</mat-icon>
                                      
                                </td> -->
                              </ng-container>
                                
                                </tr>
                               
                              </tbody>
                            </table>
    
    
                      </div>


				<div class="row pt-3">
					<div class="col-sm-6">
						<div class="form-group">
							<label for="verification_code"
								>{{ "lang_enter_verification_code" | language }}
								<span class="red">*</span>:
								<i
									style="padding: 0 10px"
									class="fa fa-info-circle orangestatus-color"
									matTooltip="{{
										'lang_if_you_not_have_code_hit_sendcode_button' | language
									}}"
								></i>
							</label>
							<input
								type="text"
								required
								class="form-control almnabrformcontrol"
								formControlName="transactions_persons_action_code"
							/>

                            <mat-error *ngIf="
                            submitForm &&
                            form.controls['transactions_persons_action_code']?.errors?.required
                        ">{{ "lang_field_required" | language }}</mat-error>
						</div>
					</div>
					<div class="col-sm-12">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row mb-10">
					<div class="col-sm-4 col-xs-12 mb-10">
						<div>
							<button
								class="albutton mnabrbutton mat-accent"
								type="button"
								(click)="openModal()"
								mat-button
							>
								{{ "lang_send_code" | language }}
							</button>
						</div>
					</div>
					<div class="col-sm-4 col-xs-12 mb-10"></div>
					<div class="col-sm-4 col-xs-12 mb-10">
						<div>
							<input
							*ngIf="form.status == 'VALID'"
								type="submit"
								value="{{ 'lang_submit' | language }}"
								class="albutton mnabrbutton mat-accent"
								mat-button
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
