import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { ToggleMenuService } from "@core/bootstrap/toggle-menu.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";
@Component({
  selector: "app-wafi-create",
  templateUrl: "./wafi-create.component.html",
  styleUrls: ["./wafi-create.component.scss"],
})
export class WafiCreateComponent implements OnInit {
  report_id = 1;
  report_data;
  sum_total_plan = 0;
  sum_actual_total = 0;
  last_date_plan = "";
  apisSubscription = new Subscription();
  constructor(
    private route: ActivatedRoute,
    private ds: DataService,
    private sweetAlert: SweetAlertService,
    private spinner: PreloaderService,
    private lang: LanguagePipe,
    private toggleMenu: ToggleMenuService
  ) {}

  ngOnInit(): void {
    this.toggleMenu.close();
    this.getWafiReportData();
  }
  getWafiReportData() {
    let formData = new FormData();
    formData.append("wafi_report_id", "1");
    this.spinner.show();
    this.apisSubscription.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(formData),
          "pr/report/get_wafi_report"
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res?.error) {
              this.sweetAlert.errorToast(res?.error, 3000);
              return;
            }
            this.report_data = res;
          },
          (err) => {
            this.spinner.hide();
            this.sweetAlert.errorToast(
              this.lang.transform("lang_internal_server_error"),
              3000
            );
          }
        )
    );
  }
}
