<div class="row wafi_header">
  <div class="table-responsive customResponsive">
    <table border="1" class="table wafi_title" width="100%">
      <tbody>
        <tr>
          <td width="130px">
            <img src="assets/images/logoawafi.PNG" alt="" />
          </td>
          <td width="87.5%">
            <div class="title_content">
              {{
                "supervision consultant monthly report for july-2022" | language
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row content_header">
  <div class="col">
    {{ "lang_programme_of_work,_cost_and_performance_indices" | language }}
  </div>
</div>
<div class="row programme_work">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody>
        <tr>
          <th rowspan="2">{{ "lang_programme_of_work" | language }}</th>
          <th>{{ "lang_contractual_start_date" | language }}</th>
          <th>{{ "lang_contractual_finish_date" | language }}</th>
          <th>
            {{ "lang_contractual_duration" | language }} ({{
              "lang_month" | language
            }})
          </th>
          <th>{{ "lang_eot" | language }} ({{ "lang_days" | language }})</th>
          <th>{{ "lang_revised_contractual_completion_date" | language }}</th>
          <th>{{ "lang_actual_start_date" | language }}</th>
          <th>
            {{
              "lang_forecast_completion_date_form_the_updated_programme"
                | language
            }}
          </th>
        </tr>
        <tr>
          <td>
            {{
              report_data?.report_work_area_info?.supervision_start_date ||
                "---"
            }}
          </td>
          <td>
            {{
              report_data?.report_work_area_info?.supervision_start_date ||
                "---"
            }}
          </td>
          <td>
            {{
              report_data?.report_work_area_info?.contractual_duration || "---"
            }}
          </td>
          <td>
            {{ report_data?.report_work_area_info?.eot || "---" }}
          </td>
          <td>
            {{
              report_data?.report_work_area_info?.revised_completion_date ||
                "---"
            }}
          </td>
          <td>
            {{ report_data?.report_work_area_info?.actual_start_date || "---" }}
          </td>
          <td>
            {{
              report_data?.report_work_area_info?.forecast_completion_date ||
                "---"
            }}
          </td>
        </tr>
        <br />
        <tr>
          <th rowspan="2">{{ "lang_progress_percentage" | language }}</th>
          <th>{{ "lang_monthly_planned_progress" | language }}</th>
          <th>{{ "lang_monthly_actual_progress" | language }}</th>
          <th>
            {{ "lang_variance" | language }}
          </th>
          <th>{{ "lang_cumulative_planned_progress" | language }}</th>
          <th>{{ "lang_cumulative_actual_progress" | language }}</th>
          <th>{{ "lang_variance" | language }}</th>
          <th>{{ "lang_delay" | language }} ({{ "lang_days" | language }})</th>
        </tr>
        <tr>
          <td>
            {{
              report_data?.actual_planned_progress?.monthly_total_plan || "---"
            }}
          </td>
          <td>
            {{
              report_data?.actual_planned_progress?.monthly_actual_total ||
                "---"
            }}
          </td>
          <td>
            {{ report_data?.actual_planned_progress?.variances || "---" }}
          </td>
          <td>
            {{ report_data?.wafi_report?.cumulative_planned_progress || "---" }}
          </td>
          <td>
            {{ report_data?.wafi_report?.cumulative_actual_progress || "---" }}
          </td>
          <td>
            {{ report_data?.actual_planned_progress?.variances || "---" }}
          </td>
          <td>
            {{ report_data?.wafi_report?.project_reasons_of_delay || "---" }}
          </td>
        </tr>
        <br />
        <tr>
          <th rowspan="2">
            {{ "lang_certified_construction_cost" | language }}
          </th>
          <th>
            {{ "lang_monthly_actual_cost" | language }} ({{
              "lang_sar" | language
            }})
          </th>
          <th>
            {{ "lang_cumulative_actual_cost" | language }} ({{
              "lang_sar" | language
            }})
          </th>
          <th rowspan="2" class="another_title">
            {{ "lang_performance_indices" | language }}
          </th>
          <th>{{ "lang_spi" | language }}</th>
          <th>{{ "lang_cpi" | language }}</th>
          <th>{{ "lang_project_status_timewise" | language }}</th>
          <th>
            {{ "lang_project_status_costwise" | language }}
          </th>
        </tr>
        <tr>
          <td>
            {{ report_data?.wafi_report?.monthly_actual_cost || "---" }}
          </td>
          <td>
            {{ report_data?.wafi_report?.cumulative_actual_cost || "---" }}
          </td>
          <td>
            {{ report_data?.wafi_report?.report_monthly_spi || "---" }}
          </td>
          <td>
            {{ report_data?.wafi_report?.report_monthly_cpi || "---" }}
          </td>
          <td>
            {{ report_data?.wafi_report?.project_timewise_status || "---" }}
          </td>
          <td>
            {{ report_data?.wafi_report?.project_costwise_status || "---" }}
          </td>
        </tr>
        <br />
        <tr>
          <th rowspan="2">
            {{ "lang_manpower" | language }}
          </th>
          <th>
            {{ "lang_monthly_planned_manpower" | language }}
          </th>
          <th>
            {{ "lang_average_monthly_actual_manpower" | language }}
          </th>
          <th>
            {{ "lang_variance" | language }}
          </th>
          <th rowspan="2" class="another_title">
            {{ "lang_reasons_of_delay" | language }} ({{
              "lang_if_any" | language
            }})
          </th>
          <td rowspan="2" colspan="3" style="white-space: nowrap">
            {{ report_data?.wafi_report?.project_reasons_of_delay || "---" }}
          </td>
        </tr>
        <tr>
          <td>
            {{ report_data?.wafi_report?.monthly_planned_manpower || "---" }}
          </td>
          <td>
            {{ report_data?.wafi_report?.monthly_actual_manpower || "---" }}
          </td>
          <td>
            {{ report_data?.actual_planned_progress?.variances || "---" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- <div class="row progress_percentage">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody>
        <tr>
          <th rowspan="2">{{ "lang_progress_percentage" | language }}</th>
          <th>{{ "lang_monthly_planned_progress" | language }}</th>
          <th>{{ "lang_monthly_actual_progress" | language }}</th>
          <th>
            {{ "lang_variance" | language }}
          </th>
          <th>{{ "lang_cumulative_planned_progress" | language }}</th>
          <th>{{ "lang_cumulative_actual_progress" | language }}</th>
          <th>{{ "lang_variance" | language }}</th>
          <th>{{ "lang_dalay" | language }} ({{ "lang_days" | language }})</th>
        </tr>
        <tr>
          <td>
            {{ "december 15 , 2018" }}
          </td>
          <td>
            {{ "december 14 , 2018" }}
          </td>
          <td>
            {{ "20" }}
          </td>
          <td>
            {{ "0" }}
          </td>
          <td>
            {{ "december 14 , 2021" }}
          </td>
          <td>
            {{ "december 15 , 2018" }}
          </td>
          <td>
            {{ "june 14 , 2020" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div> -->
<!-- <div class="row programme_work">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody>
        <tr>
          <th rowspan="2">
            {{ "lang_certified_construction_cost" | language }}
          </th>
          <th>
            {{ "lang_monthly_actual_cost" | language }} ({{
              "lang_sar" | language
            }})
          </th>
          <th>
            {{ "lang_cumulative_actual_cost" | language }} ({{
              "lang_sar" | language
            }})
          </th>
          <th rowspan="2" class="another_title">
            {{ "lang_performance_indices" | language }}
          </th>
          <th>{{ "lang_spi" | language }}</th>
          <th>{{ "lang_cpi" | language }}</th>
          <th>{{ "lang_project_status_timewise" | language }}</th>
          <th>
            {{ "lang_project_status_costwise" | language }}
          </th>
        </tr>
        <tr>
          <td>
            {{ "december 15 , 2018" }}
          </td>
          <td>
            {{ "december 14 , 2018" }}
          </td>
          <td>
            {{ "20" }}
          </td>
          <td>
            {{ "0" }}
          </td>
          <td>
            {{ "december 14 , 2021" }}
          </td>
          <td>
            {{ "december 15 , 2018" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div> -->
<!-- <div class="row manpower">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody>
        <tr>
          <th rowspan="2">
            {{ "lang_manpower" | language }}
          </th>
          <th>
            {{ "lang_monthly_planned_manpower" | language }}
          </th>
          <th>
            {{ "lang_average_monthly_actual_manpower" | language }}
          </th>
          <th>
            {{ "lang_variance" | language }}
          </th>
          <th rowspan="2" class="another_title">
            {{ "lang_reasons_of_delay" | language }} ({{
              "lang_if_any" | language
            }})
          </th>
          <td rowspan="2" style="white-space: nowrap">
            {{ "december 14 , 2021" }}
          </td>
        </tr>
        <tr>
          <td>
            {{ "december 15 , 2018" }}
          </td>
          <td>
            {{ "december 14 , 2018" }}
          </td>
          <td>
            {{ "20" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div> -->
