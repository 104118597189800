<app-formc1-edit
	*ngIf="form_key == 'FORM_C1'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[edit_type]="edit_type"
></app-formc1-edit>
<app-formc2-edit
	*ngIf="form_key == 'FORM_C2'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[edit_type]="edit_type"
></app-formc2-edit>
<app-formpq1-edit
	*ngIf="form_key == 'FORM_PQ1' && edit_type != 'edit2'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[edit_type]="edit_type"
></app-formpq1-edit>
<app-formpq1-edit2
	*ngIf="form_key == 'FORM_PQ1' && edit_type == 'edit2'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[edit_type]="edit_type"
></app-formpq1-edit2>
<app-formct1-edit
	*ngIf="form_key == 'FORM_CT1'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[edit_type]="edit_type"
></app-formct1-edit>
<app-formhrj1-edit
	*ngIf="form_key == 'FORM_HRJ1'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[edit_type]="edit_type"
></app-formhrj1-edit>
<app-form-acr-edit
	*ngIf="form_key == 'FORM_ACR'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[edit_type]="edit_type"
></app-form-acr-edit>
<app-form-acp-edit
	*ngIf="form_key == 'FORM_ACP'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[edit_type]="edit_type"
></app-form-acp-edit>
<app-form-acj-edit
	*ngIf="form_key == 'FORM_ACJ'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[edit_type]="edit_type"
></app-form-acj-edit>
<app-formhrv1-edit
	*ngIf="form_key == 'FORM_HRV1'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[edit_type]="edit_type"
></app-formhrv1-edit>
