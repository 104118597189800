import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

@Component({
  selector: "app-create-project-transaction",
  templateUrl: "./create-project-transaction.component.html",
  styleUrls: ["./create-project-transaction.component.scss"],
})
export class CreateProjectTransactionComponent implements OnInit {
  projects_work_area_id;
  projects_profile_id;
  collections: any = [];
  worklevels: any = [];
  lang_key = localStorage.getItem("lang_key") || "en";
  searchKey = "";
  collection_id: any = null;
  template_status = "";
  wholedata: any = [];
  page_no: any = 1;
  page_size: any = "10";
  nodata: string = this.lang.transform("lang_loading");
  viewdata: any = [];

  templateLists: any = [];
  typeLists: any = [];
  group1List: any = [];
  group2List: any = [];

  typemodel: any = null;
  group1model: any = null;
  group2model: any = null;
  templatemodel: any = null;

  constructor(
    public ds: DataService,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    private dialogRef: MatDialogRef<CreateProjectTransactionComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router
  ) {
    this.projects_profile_id = data?.projects_profile_id;
    this.projects_work_area_id = data?.projects_work_area_id;
  }
  ngOnInit(): void {
    this.getTemplateList();
  }

  public getTemplateList() {
    this.templateLists = [];
    let formData = new FormData();
    formData.append("lang_key", this.lang_key);
    formData.append("projects_work_area_id", this.projects_work_area_id);
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "dashboard/get_templates"
      )
      .subscribe((res) => {
        if (res.status) {
          this.templateLists = res.records;
        }
      });
  }
  public getTypeList() {
    this.templateLists = [];
    let formData = new FormData();
    formData.append("lang_key", this.lang_key);
    formData.append("projects_work_area_id", this.projects_work_area_id);
    formData.append("template_id", this.templatemodel);
    this.ds
      .getActionByUrl(this.ds.formData2string(formData), "dashboard/get_types")
      .subscribe((res) => {
        if (res.status) {
          this.typeLists = res.records;
        }
      });
  }
  public getGroup1List() {
    this.templateLists = [];
    let formData = new FormData();
    formData.append("lang_key", this.lang_key);
    formData.append("projects_work_area_id", this.projects_work_area_id);
    formData.append("template_id", this.templatemodel);
    formData.append("type_code_system", this.typemodel);
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "dashboard/get_divisions"
      )
      .subscribe((res) => {
        if (res.status) {
          this.group1List = res.records;
        }
      });
  }
  public getGroup2List() {
    this.templateLists = [];
    let formData = new FormData();
    formData.append("lang_key", this.lang_key);
    formData.append("projects_work_area_id", this.projects_work_area_id);
    formData.append("template_id", this.templatemodel);
    formData.append("type_code_system", this.typemodel);
    formData.append("group1_code_system", this.group1model);
    this.ds
      .getActionByUrl(this.ds.formData2string(formData), "dashboard/get_group2")
      .subscribe((res) => {
        if (res.status) {
          this.group2List = res.records;
        }
      });
  }

  public page_number(page) {
    this.page_no = page;
    this.page_size = this.page_size;
    this.get_all_forms_for_transactions();
  }
  public by_page_size(size) {
    this.page_no = 1;
    this.page_size = size;
    this.get_all_forms_for_transactions();
  }
  public search_by() {
    this.page_no = 1;
    this.page_size = "10";
    this.get_all_forms_for_transactions();
  }
  public get_all_forms_for_transactions() {
    if (
      (this.group1model &&
        this.typemodel &&
        this.group2model &&
        this.templatemodel) ||
      this.searchKey
    ) {
      let param = new FormData();
      param.append("search_key", this.searchKey || "");
      param.append("lang_key", this.lang_key || "");
      param.append("projects_work_area_id", this.projects_work_area_id || "0");
      param.append("group1_code_system", this.group1model || "");
      param.append("type_code_system", this.typemodel || "");
      param.append("group2_code_system", this.group2model || "");
      param.append("template_id", this.templatemodel || "");
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "dashboard/get_platforms"
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.wholedata = res;
            } else {
              this.wholedata = [];
              this.nodata = res.error;
            }
          },
          (error) => {
            this.spinner.hide();
            this.wholedata = [];
            this.nodata =
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error");
          }
        );
    }
  }
  public create_form_transaction(list) {
    localStorage.setItem("formKey", list?.value);
    localStorage.setItem("template_id", this.templatemodel);
    localStorage.setItem("formName", this.typemodel);
    localStorage.setItem("projects_work_area_id", this.projects_work_area_id);
    this.router.navigate(["projects/form/create"]);
  }
}
