import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AddStepUserComponent } from "@shared/components/add-step-user/add-step-user.component";
import { AlertService } from "@shared/components/alert/alert.service";
import { UnitLevelShowComponent } from "@shared/components/unit-level-show/unit-level-show.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { DwsrService } from "../../FORM_PS_TR1/services/dwsr.service";

@Component({
	selector: "app-form-msr-view",
	templateUrl: "./form-msr-view.component.html",
	styleUrls: ["./form-msr-view.component.scss"],
})
export class FormMsrViewComponent implements OnInit {
	@Input() form_key;
	@Input() form_request_id;
	@Input() project_data;
	@Input() supervision_data;
	@Input() allData;
	viewMode: boolean = false;
	transaction_request: any;
	persons: any = [];
	notes: any = [];
	history: any = [];
	collectionSize: any;
	page = 1;
	pageSize = 10;
	form_url = "";
	form: FormGroup;
	lang_key = localStorage.getItem("lang_key");
	//stepper
	@ViewChild("stepper") myStepper: MatStepper;
	totalStepsCount: number = 11;
	selectedIndex: number = 0;
	stepProgressValue = 100 / this.totalStepsCount;
	progressValue = this.stepProgressValue;
	stepsTitles = [
		{
			label: `${this.lang.transform("lang_configurations")}`,
			value: "Configurations",
		},
		{
			label: `${this.lang.transform("lang_contractor_team_approval")}`,
			value: "Contractor_Team_Approval",
		},
		{
			label: `${this.lang.transform("lang_contractor_manager_approval")}`,
			value: "Contractor_Manager_Approval",
		},
		{
			label: `${this.lang.transform("lang_recipient_verification")}`,
			value: "Recipient_Verification",
		},
		{
			label: `${this.lang.transform("lang_techinical_assistant")}`,
			value: "Techinical_Assistant",
		},
		{
			label: `${this.lang.transform("lang_special_approval")}`,
			value: "Special_Approval",
		},
		{
			label: `${this.lang.transform("lang_evaluation_result")}`,
			value: "Evaluation_Result",
		},
		{
			label: `${this.lang.transform("lang_authorized_positions_approval")}`,
			value: "Authorized_Positions_Approval",
		},
		{
			label: `${this.lang.transform("lang_manager_approval")}`,
			value: "Manager_Approval",
		},
		{
			label: `${this.lang.transform("lang_owner_representative")}`,
			value: "Owners_Representative",
		},
		{
			label: `${this.lang.transform("lang_final_result")}`,
			value: "Final_Result",
		},
	];
	stepTitle = this.stepsTitles[this.selectedIndex].label;
	@ViewChild("stepTitleEl", { static: false })
	stepTitleEl?: ElementRef<HTMLElement>;
	varSettimeout: any;
	isLinear: boolean = false;
	showStatus: boolean = false;
	@Output() showAction = new EventEmitter();
	Configurations_Attachments = [];
	Evaludation_Result_Attachments = [];
	Manager_Approval_Attachments = [];
	Technical_Assistants_Evaluation_Attachments = [];
	Owners_Representative_Attachments = [];
	All_Attachments = [];
	lastStepOpened = "";
	lastStepOpenedShow = "";
	projects_work_area_id = 0;
	template_id: any;
	platform_code_system: any;
	usersTooltip = "";
	expandedStep = 0;
	evaluation_result;
	rejected = null;
	projects_profile_id: string;
	projects_workarea_id: string;
	Admin_Persons: boolean = false;
	show_versions: boolean = false;
	constructor(
		public ds: DataService,
		public datepipe: DatePipe,
		private dwsrService: DwsrService,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public dialog: MatDialog,
		public changeDetectorRef: ChangeDetectorRef
	) {
		this.initViewForm();
	}

	ngOnInit(): void {
		this.refreshLists();
	}

	refreshComponent(e) {
		if (e == true) {
			this.initViewForm();
			this.ngOnInit();
		}
	}

	initViewForm() {
		this.form_url = "FORM_MSR";
		this.dwsrService.getData().subscribe(
			(data) => {
				if (data.status) {
					this.allData = data;
					this.projects_profile_id =
						this.allData?.view_request?.work_area_info?.records?.projects_profile_id;
					this.projects_workarea_id =
						this.allData?.view_request?.work_area_info?.records?.projects_work_area_id;
					this.Admin_Persons = data?.step_status?.buttons?.Admin_Persons;
					this.show_versions = data?.form_version_status;
					this.viewMode = this.allData?.view_request?.transactions_request
						?.records?.view_link
						? true
						: false;
					this.persons =
						this.allData?.view_request?.transactions_persons?.records || [];
					this.notes =
						this.allData?.view_request?.transactions_notes?.records || [];
					this.transaction_request =
						this.allData?.view_request?.transactions_request?.records || {};
					this.history =
						this.allData?.view_request?.transactions_records.records || [];
					this.collectionSize = this.history.length;
					if (this.allData?.view_request?.Configurations_Attachments?.status) {
						this.Configurations_Attachments =
							this.allData?.view_request?.Configurations_Attachments?.records;
					}
					if (
						this.allData?.view_request?.Evaludation_Result_Attachments?.status
					) {
						this.Evaludation_Result_Attachments =
							this.allData?.view_request?.Evaludation_Result_Attachments?.records;
					}
					if (
						this.allData?.view_request?.Manager_Approval_Attachments?.status
					) {
						this.Manager_Approval_Attachments =
							this.allData?.view_request?.Manager_Approval_Attachments?.records;
					}
					if (
						this.allData?.view_request
							?.Technical_Assistants_Evaluation_Attachments?.status
					) {
						this.Technical_Assistants_Evaluation_Attachments =
							this.allData?.view_request?.Technical_Assistants_Evaluation_Attachments?.records;
					}
					if (
						this.allData?.view_request?.Owners_Representative_Attachments
							?.status
					) {
						this.Owners_Representative_Attachments =
							this.allData?.view_request?.Owners_Representative_Attachments?.records;
					}
					this.All_Attachments = this.Configurations_Attachments.concat(
						...this.Evaludation_Result_Attachments,
						...this.Manager_Approval_Attachments,
						...this.Technical_Assistants_Evaluation_Attachments,
						...this.Owners_Representative_Attachments
					);

					this.lastStepOpened =
						this.allData?.view_request?.transactions_request?.records?.transaction_request_last_step;
					this.projects_work_area_id =
						this.allData?.view_request?.work_area_info?.records?.projects_work_area_id;
					this.template_id =
						this.allData?.view_request?.form_msr_data?.records[0]?.template_id;
					this.evaluation_result =
						this.allData?.view_request?.form_msr_data?.records[0]?.evaluation_result;
					this.platform_code_system =
						this.allData?.view_request?.form_msr_data?.records[0]?.platform_code_system;
					this.selectedIndex = this.stepsTitles.findIndex((el) => {
						return el.value == this.lastStepOpened;
					});
					this.lastStepOpenedShow = this.stepsTitles[this.selectedIndex]?.label;
					if (this.lastStepOpened == "last") {
						this.selectedIndex = 10;
						this.lastStepOpenedShow = this.lang.transform("lang_last");
					} else if (this.lastStepOpened == "completed") {
						this.selectedIndex = 10;
						this.lastStepOpenedShow = this.lang.transform("lang_completed");
					}
					this.progressValue =
						this.stepProgressValue * (this.selectedIndex + 1);
					let Configurations = this.allData?.step_status?.edit?.Configurations,
						Authorized_Positions_Approval =
							this.allData?.step_status?.edit?.Authorized_Positions_Approval,
						Contractor_Manager_Approval =
							this.allData?.step_status?.edit?.Contractor_Manager_Approval,
						Contractor_Team_Approval =
							this.allData?.step_status?.edit?.Contractor_Team_Approval,
						Evaluation_Result =
							this.allData?.step_status?.edit?.Evaluation_Result,
						Final_Result = this.allData?.step_status?.edit?.Final_Result,
						Manager_Approval =
							this.allData?.step_status?.edit?.Manager_Approval,
						Owners_Representative =
							this.allData?.step_status?.edit?.Owners_Representative,
						Recipient_Verification =
							this.allData?.step_status?.edit?.Recipient_Verification,
						Special_Approval =
							this.allData?.step_status?.edit?.Special_Approval,
						Techinical_Assistant =
							this.allData?.step_status?.edit?.Techinical_Assistant;
					if (
						Configurations ||
						Authorized_Positions_Approval ||
						Contractor_Manager_Approval ||
						Contractor_Team_Approval ||
						Evaluation_Result ||
						Final_Result ||
						Manager_Approval ||
						Owners_Representative ||
						Recipient_Verification ||
						Special_Approval ||
						Techinical_Assistant
					) {
						this.get_create_template_options(
							this.projects_work_area_id,
							this.template_id
						);
					} else {
						this.showStatus = true;
					}
					this.getLastOpenedUsers();
				} else {
					this.alert.error(data.error);
					this.router.navigate(["transactions/allforms"]);
				}
			},
			(err) => {
				this.alert.error(err);
				this.router.navigate(["transactions/allforms"]);
			}
		);
	}

	getLastOpenedUsers() {
		let users = this.persons.filter((item) => {
			return item?.transactions_persons_action_status == "0";
		});
		let name = [];
		users.map((el) => {
			name.push(`${el?.first_name} ${el?.last_name}`);
		});
		if (users[users.length - 1]?.transactions_persons_key4 == "REJECTED") {
			this.rejected = this.lang.transform("lang_rejected");
		} else {
			this.rejected = null;
		}
		name = name.filter(this.dwsrService.onlyUnique);
		this.usersTooltip =
			this.lang.transform("lang_users_access_open_step") + " : ";
		for (let i = 0; i < name.length; i++) {
			this.usersTooltip += name[i] + (i == name.length - 1 ? "" : " , ");
		}
	}
	addStepUser() {
		const dialogRef = this.dialog.open(AddStepUserComponent, {
			disableClose: false,
			width: "100%",
			data: {
				form_request_id: this.form_request_id,
				form_key: this.form_key,
			},
		});
	}
	scrollToElement() {
		let el = document.querySelector("#versions_section");
		el.scrollIntoView({ behavior: "smooth" });
	}
	openAccord(index) {
		this.expandedStep = index;
	}

	refreshLists() {
		this.history = this.history
			.map((list, i) => ({
				id: i + 1,
				...list,
			}))
			.slice(
				(this.page - 1) * this.pageSize,
				(this.page - 1) * this.pageSize + this.pageSize
			);
	}

	selectionChange(e: any) {
		this.selectedIndex = e.selectedIndex;
		this.progressValue = this.stepProgressValue * (this.selectedIndex + 1);
		this.stepTitle = this.stepsTitles[this.selectedIndex].label;
	}

	moveStepper(action: string) {
		if (
			action == "next" &&
			this.myStepper.selectedIndex + 1 < this.myStepper.steps.length
		) {
			this.myStepper.selectedIndex++;
		} else if (action == "prev" && this.myStepper.selectedIndex > 0) {
			this.myStepper.selectedIndex--;
		} else {
			return false;
		}
	}

	// current_stepper(step) {
	//   this.currentStep = parseInt(step);
	// }
	public get_create_template_options(projects_work_area_id, template_id) {
		this.spinner.show();
		let param = new FormData();
		param.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id || projects_work_area_id
		);
		param.append("platform_code_system", this.platform_code_system);
		param.append("template_id", template_id);
		this.ds
			.postActionByUrl(
				param,
				"form/" + this.form_url + `/cr/0/${this.form_request_id}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					this.showStatus = res.status;
					if (res.status) {
						//
					} else {
						this.ds.dialogf("", res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.showStatus = true;
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}

	public preview_form_data() {
		this.ds.preview_pdf_file(
			"form/" + this.form_key + "/pr/" + this.form_request_id,
			this.form_key + "-" + this.form_request_id
		);
	}

	public view_form_data() {
		this.ds.preview_pdf_file(
			this.transaction_request?.view_link,
			this.form_key + "-" + this.form_request_id
		);
	}

	openUnitLevelModal() {
		const dialogRef = this.dialog.open(UnitLevelShowComponent, {
			disableClose: false,
			width: "600px",
			data: {
				unit_level:
					this.allData?.view_request?.project_supervision_form_unit_level,
			},
		});
	}

	ngOnDestroy(): void {
		clearTimeout(this.varSettimeout);
	}
}
