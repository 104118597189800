<div class="p-2">
	<div class="row">
		<div class="col-12">
			<app-initial-delivery-list
				[supervision_data]="supervision_data"
				[able2add]="able2add"
				[able2edit]="able2edit"
				[makeActionInitial]="makeActionInitial"
				[project_data]="project_data"
			>
			</app-initial-delivery-list>
		</div>
	</div>
</div>
