import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import { special_approval_notes } from "app/routes/transactions/FORM_PS_TR1/formdata";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { PreviewPdfComponent } from "@shared/components/preview-pdf/preview-pdf.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { SendCodeComponent } from "app/routes/transactions/FORM_PS_TR1/send-code/send-code.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { ConfirmJopOfferComponent } from "./confirm-jop-offer/confirm-jop-offer.component";
@Component({
	selector: "app-sview",
	templateUrl: "./sview.component.html",
	styleUrls: ["./sview.component.scss"],
})
export class SviewComponent implements OnInit {
	form_key: any;
	@Input() form_request_id;
	@Input() project_data;
	@Input() supervision_data;
	@Input() template_data;
	@Input() allData;
	@Input() formCt1Data;

	submitForm: boolean = false;
	sendCodeForm: any;
	sendCodeStatus: boolean = false;
	rejectedValidators = [];
	stepName = "ACCOUNT_TEAM";
	form: FormGroup;
	users: any = [];

	myData: any = [];
	id = this.route.snapshot.paramMap.get("id");
	code = this.route.snapshot.paramMap.get("code");
	notesdescription = "";
	attach: any = [];
	profile_percentage: any = "";
	countdown = localStorage.getItem("countdown")
		? localStorage.getItem("countdown")
		: "00:00:00";
	viewdata: any = [];
	datastatus = false;
	notes: any = [];
	apiurl = environment.SERVER_ORIGIN;
	attachments: any = [];
	cerrormessagef = this.lang.transform("lang_no_data");
	licences: any = [];
	view_profile_image = "";
	lang_key = localStorage.getItem("lang_key") || "en";
	asearchKey = "";
	nsearchKey = "";
	constructor(
		public fb: FormBuilder,
		private cdRef: ChangeDetectorRef,
		private sweetAlert: SweetAlertService,
		public ds: DataService,
		public route: ActivatedRoute,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public router: Router,
		public dialog: MatDialog,
		public changeDetectorRef: ChangeDetectorRef,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.myData = special_approval_notes;
		this.form = new FormGroup({
			transaction_request_id: new FormControl(this.form_request_id, [
				Validators.required,
			]),
			approved: new FormControl("", [Validators.required]),
			notes: new FormControl("", []),
			transactions_persons_action_code: new FormControl("", [
				Validators.required,
			]),
		});
		this.spinner.show();
		this.get_my_data();
	}
	jop_offers;
	get_my_data() {
		this.ds
			.getActionByNoToken(
				[],
				"signup/get_signup_view/" +
					this.id +
					"/" +
					this.code +
					"/" +
					this.lang_key
			)
			.subscribe(
				(data) => {
					this.spinner.hide();
					this.datastatus = data.status;
					if (data.status) {
						this.viewdata = data.data;
						this.attachments = data.attachments;
						this.licences = data.licences;
						if (data.profile_percentage) {
							this.profile_percentage = data.profile_percentage.total;
						}

						this.jop_offers = data.job_offer;
						this.form
							.get("transaction_request_id")
							.setValue(data?.job_offer?.transaction_request_id);
						if (data.data.profile_image) {
							this.ds
								.getActionByNoToken([], data.data.profile_image)
								.subscribe((data) => {
									if (data.status) {
										this.view_profile_image =
											"data:" + data["content-type"] + ";base64," + data.base64;
									}
								});
						}
						this.get_my_notes();
						this.get_my_attach();
					} else {
						this.ds.dialogf("", data.error);
						this.router.navigate([""]);
					}
				},
				(error) => {
					this.spinner.hide();
					this.datastatus = false;
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	get_my_notes() {
		let formData = new FormData();
		formData.append("searchKey", this.nsearchKey || "");
		this.ds
			.postActionByNoToken(
				formData,
				"signup/get_my_notes/" + this.id + "/" + this.code
			)
			.subscribe(
				(data) => {
					if (data.status) {
						this.notes = data.data;
					} else {
						this.notes = [];
					}
				},
				(error) => {
					this.notes = [];
				}
			);
	}
	get_my_attach() {
		let formData = new FormData();
		formData.append("searchKey", this.asearchKey || "");
		this.ds
			.postActionByNoToken(
				formData,
				"signup/get_my_attach/" +
					this.id +
					"/" +
					this.code +
					"/" +
					this.lang_key
			)
			.subscribe(
				(data) => {
					if (data.status) {
						this.attach = data.data;
					} else {
						this.attach = [];
					}
				},
				(error) => {
					this.attach = [];
				}
			);
	}
	viewnotes(text) {
		this.ds.dialogf("", text);
	}
	logoutme() {
		this.spinner.show();
		this.ds
			.getActionByNoToken([], "signup/logoutme/" + this.id + "/" + this.code)
			.subscribe(
				(res) => {
					this.spinner.hide();
					localStorage.removeItem("employee_id_number");
					localStorage.removeItem("verified_code");
					this.router.navigate(["auth/login"]);
				},
				(error) => {
					this.spinner.hide();
					this.ds.dialogf("", this.lang.transform("lang_wrong"));
				}
			);
	}

	onViewFile(data) {
		if (!data) {
			return 0;
		}
		this.ds.getActionByUrl([], data).subscribe(
			(res) => {
				if (res.status) {
					if (res.extension == "pdf") {
						let src = "data:" + res["content-type"] + ";base64," + res.base64;
						const dialogRef = this.dialog.open(PreviewPdfComponent, {
							disableClose: false,
							width: "100%",
							data: {
								src: src,
								base64: res.base64,
							},
						});
					} else {
						this.ds.showPreviewWithoutAPi(res);
					}
				} else {
					this.sweetAlert.errorToast(data.error, 2000);
				}
			},
			(error) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					error.error + (error && error.error && error.error.error)
						? error.error.error
						: this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

	openModel(form_request_id) {
		const dialogRef = this.dialog.open(ConfirmJopOfferComponent, {
			disableClose: true,
			width: "400px",
			data: {
				form_request_id: form_request_id,
			},
		});

		dialogRef.afterClosed().subscribe((res: any) => {
			if (res.sendRequest) {
				this.spinner.show();
				this.get_my_data();
			}
		});
	}

	get getNotes() {
		return this.form.get("notes");
	}

	checkStatus() {
		if (
			this.form.get("approved").value == "Reject" ||
			this.form.get("approved").value == "Return"
		) {
			this.rejectedValidators = [Validators.required];
			this.getNotes?.setValidators([Validators.required]);

			this.getNotes?.updateValueAndValidity();
		} else {
			this.rejectedValidators = [];
			this.getNotes?.setValidators([]);

			this.getNotes?.updateValueAndValidity();
		}
	}
	public confirm() {
		this.submitForm = true;
		if (this.form.valid) {
			this.spinner.show();
			let formData = new FormData();
			formData.append(
				"transaction_request_id",
				this.form.value.transaction_request_id
			);
			formData.append("approving_status", this.form.value.approved);
			formData.append("note", this.form.value.notes);
			formData.append(
				"transactions_persons_action_code",
				this.form.value.transactions_persons_action_code
			);

			this.ds.post(`/form/FORM_JF/sign_req_emp`, formData).subscribe(
				(data) => {
					if (data.status) {
						this.spinner.hide();
						this.alert.success(data.msg);
						this.submitForm = false;
						setTimeout(() => {
							this.logoutme();
						}, 500);
					} else {
						this.spinner.hide();
						this.alert.error(data?.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(error);
				}
			);
		} else {
			this.alert.error(this.lang.transform("lang_all_fields_required"));
		}
	}
	@Output() updateForm = new EventEmitter();

	disableInput = true;
	openModal() {
		this.disableInput = false;
		const dialogRef = this.dialog.open(SendCodeComponent, {
			disableClose: true,
			width: "400px",
			data: {
				transaction_persons_type: "signature",
				allData: this.allData,
				stepName: this.stepName,
			},
		});
		dialogRef.afterClosed().subscribe((data) => {
			this.sendCodeForm = data?.formValue;
			this.sendCodeStatus = data?.sendstatus;
		});
	}
	public preview_form_data(isView: boolean) {
		let url = this.jop_offers?.joboffer_link;
		this.spinner.show();
		this.ds.getActionByUrl([], url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					if (res.extension == "pdf") {
						let src = "data:" + res["content-type"] + ";base64," + res.base64;
						const dialogRef = this.dialog.open(PreviewPdfComponent, {
							disableClose: false,
							width: "100%",
							data: {
								src: src,
								base64: res.base64,
							},
						});
					} else {
						this.sweetAlert.errorMessage("The attachment must be a PDF");
					}
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}
}
