import { Injectable } from "@angular/core";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { SocketServiceService } from "@core/bootstrap/socket-service.service";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class TicketSocket {
	socket = this.socketService.socket;
	userId = this.localStorageService.getItem("user_id");
	constructor(
		private socketService: SocketServiceService,
		private localStorageService: LocalStorage
	) {}
	listenToTasks(ticket_id) {
		return Observable.create((observer) => {
			this.socket.on(`ticket_${ticket_id}_tasks_${this.userId}`, (message) => {
				observer.next(message);
			});
		});
	}
	listenToComments(ticket_id) {
		return Observable.create((observer) => {
			this.socket.on(
				`ticket_${ticket_id}_comments_${this.userId}`,
				(message) => {
					observer.next(message);
				}
			);
		});
	}
	listenToAttachments(ticket_id) {
		return Observable.create((observer) => {
			this.socket.on(
				`ticket_${ticket_id}_attachments_${this.userId}`,
				(message) => {
					observer.next(message);
				}
			);
		});
	}
	listenToTimeline(ticket_id) {
		return Observable.create((observer) => {
			this.socket.on(
				`ticket_${ticket_id}_timeline_${this.userId}`,
				(message) => {
					observer.next(message);
				}
			);
		});
	}

	//events of task view
	listenToTaskComments(ticket_id, task_id) {
		return Observable.create((observer) => {
			this.socket.on(
				`ticket_${ticket_id}_task_${task_id}_comments_${this.userId}`,
				(message) => {
					observer.next(message);
				}
			);
		});
	}
	listenToTaskTimeline(ticket_id, task_id) {
		return Observable.create((observer) => {
			this.socket.on(
				`ticket_${ticket_id}_task_${task_id}_timeline_${this.userId}`,
				(message) => {
					observer.next(message);
				}
			);
		});
	}
	listenToTaskAttachments(ticket_id, task_id) {
		return Observable.create((observer) => {
			this.socket.on(
				`ticket_${ticket_id}_task_${task_id}_attachments_${this.userId}`,
				(message) => {
					observer.next(message);
				}
			);
		});
	}
	listenToTaskDate(ticket_id, task_id) {
		return Observable.create((observer) => {
			this.socket.on(
				`ticket_${ticket_id}_task_${task_id}_dates_${this.userId}`,
				(message) => {
					observer.next(message);
				}
			);
		});
	}
	listenToTaskMembers(ticket_id, task_id) {
		return Observable.create((observer) => {
			this.socket.on(
				`ticket_${ticket_id}_task_${task_id}_members_${this.userId}`,
				(message) => {
					observer.next(message);
				}
			);
		});
	}

	listenToTaskChecklists(ticket_id, task_id) {
		return Observable.create((observer) => {
			this.socket.on(
				`ticket_${ticket_id}_task_${task_id}_checklists_${this.userId}`,
				(message) => {
					observer.next(message);
				}
			);
		});
	}
}
