import {
	Appearance,
	GermanAddress
} from '@angular-material-extensions/google-maps-autocomplete';
import { trigger, state, style, transition, animate } from '@angular/animations';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from '@angular/forms';
import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	PreloaderService
} from '@core';
import {
	DataService
} from '@core/bootstrap/data.service';
import {
	AlertService
} from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import PlaceResult = google.maps.places.PlaceResult;
@Component({
	selector: 'app-projects-edit',
	templateUrl: './projects-edit.component.html',
	styleUrls: ['./projects-edit.component.scss'],
	animations: [
	  trigger("fadeIn", [
		state(
		  "void",
		  style({
			opacity: 0
		  })
		),
		transition("void <=> *", animate(1000))
	  ]),
	]
})
export class ProjectsEditComponent implements OnInit {
	public project_profile_id = this.route.snapshot.paramMap.get('id');
	public appearance = Appearance;
	public mapaddress;
	public zoom: number = 19;
	public latitude: number;
	public longitude: number;
	public selectedAddress: PlaceResult;
	form: FormGroup;
	branches: any = [];
	customers: any = [];
	customer_types: any = [];
	services: any = [];
	users: any = [];
	reviewers: any = [];
	public add;
	public edit;
	public delete;
	public view;
	constructor(public lang:LanguagePipe, public ds: DataService, public router: Router, public fb: FormBuilder, public route: ActivatedRoute, public spinner: PreloaderService, public alert: AlertService) {}
	ngOnInit(): void {
		this.latitude = 52.520008;
		this.longitude = 13.404954;
		this.setCurrentPosition();
		this.buildform();
		this.get_branches();
		this.get_customer_type();
		this.get_services();
		this.get_data_for_edit();
	}
	public get_data_for_edit() {
		this.spinner.show();
		this.ds.getActionByUrl([], 'ZrglGNMl4y6BC17/' + this.project_profile_id).subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.add = res.add;
				this.edit = res.edit;
				this.view = res.view;
				this.delete = res.delete;
				this.users = res.service_user_data.users;
				this.form.patchValue({
					branch_id: res.data.branch_id,
					projects_profile_name_en: res.data.projects_profile_name_en,
					projects_profile_name_ar: res.data.projects_profile_name_ar,
					customer_id: res.data.customer_id,
					customer_type_id: res.data.customer_type_id,
					projects_profile_location: res.data.projects_profile_location,
					latitude: res.data.latitude,
					longitude: res.data.longitude,
					project_scope_types: this.service_data(res.service_user_data.services),
					project_managers: this.user_data(res.service_user_data.users, 'PTM'),
					project_users: this.user_data(res.service_user_data.users, 'PTV'),
				});
				this.mapaddress = res.data.projects_profile_location;
				if (res.data.longitude && res.data.latitude) {
					this.latitude = Number(res.data.latitude);
					this.longitude = Number(res.data.longitude);
				}
				this.zoom = 16;
				this.get_customers();
			} else {
				this.ds.dialogf('', res.error);
				this.router.navigate(['BbVUrWitLE']);
			}
		}, error => {
			this.spinner.hide();
			this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
		})
	}
	public user_data(users, key) {
		const data = [];
		if (users && users.length > 0) {
			users.forEach((v, k) => {
				if (v.position == key) {
					data.push(v.value)
				}
			});
		}
		return data;
	}
	public service_data(services) {
		const data = [];
		if (services && services.length > 0) {
			services.forEach((v, k) => {
				data.push(v.value);
			});
		}
		return data;
	}
	public buildform() {
		this.form = new FormGroup({
			branch_id: new FormControl('', [Validators.required]),
			projects_profile_name_en: new FormControl('', [Validators.required]),
			projects_profile_name_ar: new FormControl('', [Validators.required]),
			customer_id: new FormControl('', [Validators.required]),
			customer_type_id: new FormControl('', [Validators.required]),
			projects_profile_location: new FormControl('', [Validators.required]),
			latitude: new FormControl(''),
			longitude: new FormControl(''),
			project_scope_types: new FormControl('', [Validators.required]),
			project_managers: new FormControl('', [Validators.required]),
			project_users: new FormControl(''),
		})
	}
	public get_branches() {
		this.branches = [];
		this.ds.getActionByUrl([], 'jQUW6F52AgToEsm/projects_edit').subscribe(res => {
			if (res.status) {
				this.branches = res.records;
			}
		})
	}
	public get_customers() {
		this.customers = [];
		this.ds.getActionByUrl([], 'c91SZ4xopgmOM6A/projects_edit/' + this.form.get('branch_id').value).subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.customers = res.records;
			}
		}, error => {
			this.spinner.hide();
		})
	}
	public get_customer_type() {
		this.customer_types = [];
		this.ds.getActionByUrl([], 'Y3ZjDZmYyUy5Rlx/projects_edit').subscribe(res => {
			if (res.status) {
				this.customer_types = res.records;
			}
		})
	}
	public get_services() {
		this.services = [];
		this.ds.getActionByUrl([], 'aB8Vlmue0xVSv8O/projects_edit').subscribe(res => {
			if (res.status) {
				this.services = res.records;
			}
		})
	}
	public search_users(key) {
		this.users = [];
		if (key.term) {
			let param = new FormData();
			param.append('search', key.term || '');
			param.append('user_type_id', '1');
			this.ds.getActionByUrl(this.ds.formData2string(param), 'sZMVmoFEATU9YlN/projects_edit').subscribe(res => {
				if (res.status) {
					this.users = res.records;
				}
			})
		}
	}
	public setCurrentPosition() {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.latitude = position.coords.latitude;
				this.longitude = position.coords.longitude;
			});
		}
	}
	public onGermanAddressMapped($event: GermanAddress) {
		this.form.get('latitude').setValue($event.geoLocation.latitude);
		this.form.get('longitude').setValue($event.geoLocation.longitude);
		this.form.get('projects_profile_location').setValue($event.displayAddress);
		this.latitude = $event.geoLocation.latitude;
		this.longitude = $event.geoLocation.longitude;
	}
	public update() {
		this.spinner.show();
		let param = new FormData();
		param.set('branch_id', this.form.get('branch_id').value || '');
		param.set('projects_profile_name_en', this.form.get('projects_profile_name_en').value || '');
		param.set('projects_profile_name_ar', this.form.get('projects_profile_name_ar').value || '');
		param.set('customer_id', this.form.get('customer_id').value || '');
		param.set('customer_type_id', this.form.get('customer_type_id').value || '');
		param.set('project_scope_types', (this.form.get('project_scope_types').value) ? this.form.get('project_scope_types').value.toString() : '');
		param.set('project_managers', this.form.get('project_managers').value.toString());
		param.set('project_users', this.form.get('project_users').value.toString());
		let loc = this.form.get('projects_profile_location').value;
		param.set('projects_profile_location', this.mapaddress);
		if (loc && loc != undefined && loc.displayAddress && loc.displayAddress != undefined) {
			param.set('projects_profile_location', loc.displayAddress);
		}
		param.set('latitude', this.latitude.toString());
		param.set('longitude', this.longitude.toString());
		this.ds.putActionByUrl(this.ds.formData2string(param), 'rvx3H0b0Dt10svK/' + this.project_profile_id).subscribe(
			(data) => {
				this.spinner.hide();
				if (data.status) {
					this.alert.success(data.msg);
					this.form.reset();
					setTimeout(() => {
						this.router.navigate(['projects/LtYh/' + data.project_profile_id]);
					}, 2000);
				} else {
					this.alert.error(data.error);
				}
			},
			(error) => {
				this.spinner.hide();
				this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
			}
		);
	}
}