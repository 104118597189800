import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
declare var $:any;
@Component({
  selector: 'app-form-cp-add',
  templateUrl: './form-cp-add.component.html',
  styleUrls: ['./form-cp-add.component.scss']
})
export class FormCpAddComponent implements OnInit {

  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  @Input() unitsOfTemplate;
  transaction_request:any;
  persons: any = [];
  notes: any = [];
  form_url = "";
  form:FormGroup;
  lang_key = localStorage.getItem('lang_key');
//stepper
@ViewChild('stepper') myStepper: MatStepper;
totalStepsCount: number = 11;
selectedIndex: number = 0;
stepProgressValue = 100 / this.totalStepsCount;
progressValue = this.stepProgressValue;
stepsTitles = [
  `${this.lang.transform("lang_configurations")}`,
  `${this.lang.transform("lang_review_by_contractor_manager")}`,
  `${this.lang.transform("lang_review_by_consultant_team")}`,
  `${this.lang.transform("lang_review_by_consultant_manager")}`,
  `${this.lang.transform("lang_review_by_consultant_QAQC")}`,
  `${this.lang.transform("lang_final_result")}`
];
stepTitle =this.stepsTitles[this.selectedIndex];
@ViewChild('stepTitleEl') stepTitleEl:any;
varSettimeout:any;

  isLinear:boolean      = false;
  showStatus:boolean    = false;
  currentStep           = 0;
  @Output() showAction  = new EventEmitter();
  drawing_file:any;
  constructor(public ds:DataService, public datepipe : DatePipe ,  private dialog:MatDialog, private sweetAlert: SweetAlertService, public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) {
    this.persons = this.allData?.view_request?.transactions_persons?.records || [];
    this.notes   = this.allData?.view_request?.transactions_notes?.records || [];
    this.transaction_request = this.allData?.view_request?.transactions_request?.records || {};

  }
  ngOnInit(): void {
    this.form_url = "FORM_CP"; //"FORM_"+this.template_data?.template_platform_group_type_code_system;
  }

}
