<breadcrumb></breadcrumb>

<section class="panel-expansion" [@fadeIn]></section>
<mat-tab-group class="device-mat-tab">
	<mat-tab>
		<ng-template mat-tab-label>{{
      "lang_devices" | language
    }}</ng-template>
		<div class="p-2 my-2" style="background-color: #fbfbfb;
    border-radius: 5px;">
			<div class="row">
				<div class="col-12">
					<div class="material-panel-body tlist">
            <div class="mbrl15 fromtransactions">
              <div
                class="row col-md-12 attendence mb-4"
                style="justify-content: end"
              >
                <div class="col-md-3">
                  <button
                    type="button"
                    (click)="openModal()"
                    class="albutton mnabrbutton mat-accent"
                    mat-button
                  >
                    {{ "lang_add_device" | language }}
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive customResponsive">
                    <table border="1" class="table CustomTable">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>{{ "lang_device_id" | language }}</td>
                          <td>{{ "lang_device_name" | language }}</td>
                          <td>{{ "lang_device_ip" | language }}</td>
                          <td>{{ "lang_device_key" | language }}</td>
                          <td>{{ "lang_date" | language }}</td>
                          <td>{{ "lang_writer" | language }}</td>
                          <td>{{ "lang_action" | language }}</td>
                        </tr>
                      </thead>
                      <tbody
                        *ngIf="listData?.records?.length != '0'"
                        class="AppendList"
                      >
                        <tr
                          *ngFor="
                            let list of listData?.records;
                            let i = index;
                            let odd = odd;
                            let even = even
                          "
                          [ngClass]="{ odd: odd, even: even }"
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ list.device_id }}</td>
                          <td>{{ list.device_name }}</td>
                          <td>{{ list.device_ip }}</td>
                          <td>{{ list.device_key }}</td>
                          <td>{{ list.created_datetime }}</td>
                          <td>{{ list.writer }}</td>
                          <td>
                            <mat-icon
                              matTooltip="{{ 'lang_delete' | language }}"
                              class="mnabricon red"
                              (click)="deleteSingleData(list?.device_id)"
                              >delete</mat-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="loading">
                        <tr>
                          <td colspan="14" class="nodata" align="center">
                            <ngx-skeleton-loader
                              count="5"
                              [theme]="{ 'border-radius': '3px', height: '50px' }"
                            ></ngx-skeleton-loader>
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        *ngIf="listData?.records?.length == '0' && !loading"
                        class="AppendList"
                      >
                        <tr class="odd">
                          <td colspan="14" class="nodata" align="center">
                            {{ nodata }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="listData?.records?.length != '0'" class="card-footer padb0">
              <div class="row responsive_pagination">
                <div
                  class="col-lg-3 col-sm-3 mb-10"
                  style="height: fit-content; margin: auto 0"
                >
                  {{ "lang_total" | language }} :
                  {{ listData?.page?.total_records }}
                </div>
                <div
                  class="col-lg-5 col-sm-5 mb-10"
                  style="display: flex; justify-content: center"
                >
                  <ngb-pagination
                    class="m-auto"
                    [collectionSize]="listData?.page?.total_records"
                    [rotate]="true"
                    [ellipses]="false"
                    [maxSize]="3"
                    [boundaryLinks]="true"
                    [(page)]="page_no"
                    [pageSize]="pagesize"
                    (pageChange)="loadPageFrm($event)"
                  ></ngb-pagination>
                </div>
                <div
                  class="col-lg-3 col-sm-3"
                  style="display: flex; justify-content: end; margin: auto"
                >
                  <mat-select
                    style="max-width: 150px"
                    [(ngModel)]="pagesize"
                    (selectionChange)="loadFrmPagesize($event.value)"
                  >
                    <mat-option class="text-center" value="10"
                      >10 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" value="20"
                      >20 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" value="50"
                      >50 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" value="100"
                      >100 {{ "lang_per_page" | language }}</mat-option
                    >
                    <mat-option class="text-center" value="500"
                      >500 {{ "lang_per_page" | language }}</mat-option
                    >
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>
    <div class="row col-md-12 attendence mb-4">
      <div class="col-md-3">
        <button
          type="button"
          routerLink="/human_resources/attendances"
          class="albutton mnabrbutton mat-accent"
          mat-button
        >
          {{ "lang_previous" | language }}
        </button>
      </div>
    </div>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>{{
      "lang_attendance_logs" | language
    }}</ng-template>
		<div class="p-2 my-2" style="background-color: #fbfbfb;
    border-radius: 5px;">
			<div class="row">
				<div class="col-12">
					<app-device-attendance-logs></app-device-attendance-logs>
          <div class="row col-md-12 attendence mb-4">
            <div class="col-md-3">
              <button
                type="button"
                routerLink="/human_resources/attendances"
                class="albutton mnabrbutton mat-accent"
                mat-button
              >
                {{ "lang_previous" | language }}
              </button>
            </div>
          </div>
				</div>
			</div>
		</div>
	</mat-tab>

</mat-tab-group>
