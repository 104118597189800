import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-view-group',
  templateUrl: './view-group.component.html',
  styleUrls: ['./view-group.component.scss']
})
export class ViewGroupComponent implements OnInit {
  lang_key = localStorage.getItem('lang_key') || 'en';
  user = [];
  users_list = [];
  groupData;
  group_users = [];
  constructor(public ds: DataService, private sweetAlert: SweetAlertService, public dialogRef: MatDialogRef<ViewGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private lang: LanguagePipe, private spinner: PreloaderService) { 
      this.groupData = data?.group;
      this.getGroupData();
    }

  ngOnInit(): void {
  }

  search_users(key) {
		this.users_list = [];
    if(key && key.term) {
      let param = new FormData();
      param.append('search', key.term);
      param.append('lang_key', this.lang_key);
      param.append('user_type_id', '1');
      this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
        if (res.status) {
          this.users_list = res.list;
        }
      });
    }
	}
  getGroupData() {
    this.spinner.show();
    let formData = new FormData();
    formData.append('group_id', this.groupData?.group_id || '');
    this.ds.post('tasks/get_group', formData).subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.group_users = res?.data?.users;
      } else {
        this.sweetAlert.errorToast(res?.error, 2000);
        this.group_users = [];
      }
    }, err => {
      this.group_users = [];
      this.spinner.hide();
    })
  }
  addUserToGroup() {
    this.spinner.show();
    let formData = new FormData();
    formData.append('group_id', this.groupData?.group_id || '');
    formData.append('users',    this.user.toString() || '');
    this.ds.post('tasks/add_group_user', formData).subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.user = [];
        this.group_users = [];
        this.getGroupData();
        this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 2000);
      } else {
        this.sweetAlert.errorToast(res?.error, 2000);
      }
    }, err => {
      this.spinner.hide();
    })
  }
  deleteUser(user) {
    this.sweetAlert.confirmMessage(this.lang.transform('lang_are_you_sure'), this.lang.transform('lang_yes')).then(confirmRes => {
      if(confirmRes.isConfirmed) {
        this.spinner.show();
        this.ds.post(`tasks/delete_group_user/${this.groupData?.group_id}/${user?.user_id}`, {}).subscribe(res => {
          this.spinner.hide();
          if(res?.status) {
            this.getGroupData();
            this.sweetAlert.successToast(this.lang.transform('lang_deleted'), 2000);
          } else {
            this.sweetAlert.errorToast(res?.error, 2000);
          }
        }, err => {
          this.spinner.hide();
        })
      }
    })
  }
}
