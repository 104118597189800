<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_create_transaction" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-md-3 col-xs-12 mb-10">
			<ng-select
				[items]="templateLists"
				[multiple]="false"
				bindLabel="label"
				bindValue="value"
				appendTo="body"
				clearAllText="Clear"
				placeholder="{{ 'lang_template_name' | language }}"
				[(ngModel)]="templatemodel"
				(change)="getTypeList()"
				[notFoundText]="'lang_no_data' | language"
			>
			</ng-select>
		</div>

		<div class="col-md-3 col-xs-12 mb-10">
			<ng-select
				[items]="typeLists"
				[multiple]="false"
				bindLabel="label"
				bindValue="value"
				appendTo="body"
				clearAllText="Clear"
				placeholder="{{ 'lang_group_type' | language }}"
				[(ngModel)]="typemodel"
				(change)="getGroup1List()"
				[notFoundText]="'lang_no_data' | language"
			>
			</ng-select>
		</div>
		<div class="col-md-3 col-xs-12 mb-10">
			<ng-select
				[items]="group1List"
				[multiple]="false"
				bindLabel="label"
				bindValue="value"
				appendTo="body"
				clearAllText="Clear"
				placeholder="{{ 'lang_group' | language }} 1"
				[(ngModel)]="group1model"
				(change)="getGroup2List()"
				[notFoundText]="'lang_no_data' | language"
			>
			</ng-select>
		</div>
		<div class="col-md-3 col-xs-12 mb-10">
			<ng-select
				[items]="group2List"
				[multiple]="false"
				bindLabel="label"
				bindValue="value"
				appendTo="body"
				clearAllText="Clear"
				placeholder="{{ 'lang_group' | language }} 2"
				[(ngModel)]="group2model"
				(change)="get_all_forms_for_transactions()"
				[notFoundText]="'lang_no_data' | language"
			>
			</ng-select>
		</div>
	</div>
	<hr class="orclass mt-2 mb-2" />
	<div class="row">
		<div class="col-md-12 mb-10">
			<input
				type="text"
				class="form-control almnabrformcontrol"
				(keyup)="search_by()"
				[(ngModel)]="searchKey"
				placeholder="{{ 'lang_search' | language }}"
			/>
		</div>
	</div>
	<div
		class="row"
		*ngIf="
			wholedata.length != '0' &&
			((typemodel && group1model && group2model) || searchKey)
		"
	>
		<div class="col-md-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_title" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody *ngIf="wholedata.length != '0'" class="AppendList">
						<tr
							*ngFor="
								let list of wholedata.records;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
						>
							<td>{{ i + 1 }}</td>
							<td style="direction: ltr !important">
								{{ list?.label }} ({{ list?.value }})
							</td>
							<td>
								<i
									matTooltip="{{ 'lang_create_transaction' | language }}"
									(click)="create_form_transaction(list)"
									class="fa fa-link fa-icon mnabricon s2icon"
								></i>
							</td>
						</tr>
					</tbody>
					<tbody *ngIf="wholedata.length == '0'" class="AppendList">
						<tr class="odd">
							<td colspan="9" align="center">{{ nodata }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</mat-dialog-actions>
