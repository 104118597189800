import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-edit-comment',
  templateUrl: './edit-comment.component.html',
  styleUrls: ['./edit-comment.component.scss']
})
export class EditCommentComponent implements OnInit {

  user_list = [];
  lang_key = localStorage.getItem('lang_key') || 'en';
  other_attachments: FormArray;
  filename:string     = this.lang.transform('lang_select_files');
  comment_form: FormGroup;
  comment_data = {
    comment_description: '',
    id:''
  };
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<EditCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private lang: LanguagePipe, public ds: DataService, private sweetAlert: SweetAlertService, private spinner: PreloaderService) { 
      if(data?.type == 'comment') {
        this.comment_data.comment_description = data?.content?.notes_history;
        this.comment_data.id = data?.content?.history_id;
      } else if(data?.type == 'reply') {
        this.comment_data.comment_description = data?.content?.comment_content;
        this.comment_data.id = data?.content?.history_id;
      } else if(data?.type == 'taskComment') {
        this.comment_data.comment_description = data?.content?.comment_content;
        this.comment_data.id = data?.content?.history_id;
      }
      this.buildForm();
      this.setEditData();
    }

  ngOnInit(): void {
    
  }

  buildForm() {
    this.comment_form = this.fb.group({
      comment_description: [null, Validators.required],
      attachments     : this.fb.array([])
    });
    this.other_attachments = this.comment_form.get('attachments') as FormArray;
  }
  get filesFormGroup() {
    return this.comment_form.get("attachments") as FormArray;
  }

  public files(value?): FormGroup {
    return this.fb.group({
      delete_icon     : new FormControl(value?.attach_title || ''),
      view            : new FormControl(value?.file || null),
      attach_title    : new FormControl({value : (value) ? value?.attach_title : '', disabled : (value?.attach_title) ? true : false}, [Validators.required]),
      file            : new FormControl(value?.file || null,  [Validators.required]),
    });
  }
  public addfiles(value?) {
    if (this.other_attachments.length < 5) {
      this.other_attachments.push(this.files(value));
      this.other_attachments.setValidators(Validators.required);
      this.other_attachments.updateValueAndValidity();
    }
  }
  public removefiles(index) {
    if(this.other_attachments.length == 1) {
      this.other_attachments.removeAt(index);
      this.other_attachments.clearValidators();
      this.other_attachments.updateValueAndValidity();
    } else {
      this.other_attachments.removeAt(index);
    }
  }
  setEditData() {
    this.comment_form.get("comment_description").setValue(this.comment_data?.comment_description);
  }
  checkValidation() {
    if(this.comment_form.get('comment_description').invalid) {
      this.sweetAlert.errorToast(`${this.lang.transform('lang_comment_description')} ${this.lang.transform('lang_field_required')}`, 1500);
      return false;
    } else {
      return true;
    }
  }
  update() {
    if(this.checkValidation()) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('comment_id', this.comment_data?.id || '');
      formData.append('notes', this.comment_form.get('comment_description').value || '');
      
      // this.filesFormGroup.value.forEach((v, k) => {
      //   if(!v.view) {
      //     formData.set("attachments[" + k + "][file]", v.file);
      //     formData.set("attachments[" + k + "][attach_title]", this.other_attachments.controls[k].get("attach_title").value);  
      //   }      
      // });
      let url = 'tasks/update_comment_reply';
      this.ds.post(url, formData).subscribe(res => {
        this.spinner.hide();
        if(res?.status) {
          this.dialogRef.close({status:true});
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1500);
        } else {
          this.dialogRef.close({status:false})
          this.sweetAlert.errorToast(res?.error, 1500);
        }
      }, err => {
        this.spinner.hide();
        this.dialogRef.close({status:false})
        this.sweetAlert.errorToast(err?.error, 1500);
      })
    }
  }

  updateComment() {

  }
}
