import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PayroleCreateComponent } from './components/payrole-create/payrole-create.component';
import { PayroleListComponent } from './components/payrole-list/payrole-list.component';
import { ReviewersListComponent } from './components/reviewers/reviewers-list/reviewers-list.component';

const routes: Routes = [
  {path:'', redirectTo:'list', pathMatch: 'full'},
  {path:'list', component:PayroleListComponent, data: { title: 'List' }},
  {path:'reviewers', component:ReviewersListComponent, data: { title: 'reviewers' }},
  {path:'create', component:PayroleCreateComponent, data: { title: 'Create Pay Role' }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PayroleRoutingModule { }
