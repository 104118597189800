import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LocalStorage } from './localstorage.service';
declare var $:any;
@Injectable({
  providedIn: 'root'
})
export class MnabrthemeService {
  constructor(
    private ls:LocalStorage
  ) {}
  load(): Promise<any> {
    $("<link/>", {
        rel: "stylesheet",
        type: "text/css",
        href: environment.SERVER_ORIGIN + "default/theme/systemcolor/" + this.ls.getItem('user_id')
    }).appendTo("head");
    if(localStorage.getItem('direction') == "rtl") {
      $("<link/>", {
          rel: "stylesheet",
          type: "text/css",
          href: "https://bootstrap.rtlcss.com/docs/4.5/dist/css/rtl/bootstrap.min.css"
      }).appendTo("head");
    }    
    return null;
  }
}
