import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-add-edit-subtask",
	templateUrl: "./add-edit-subtask.component.html",
	styleUrls: ["./add-edit-subtask.component.scss"],
})
export class AddEditSubtaskComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	subtaskForm: FormGroup;
	subtask_data;
	edit_mode = false;
	setting_task_type = [];
	group_users_list = [];
	constructor(
		private fb: FormBuilder,
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private spinner: PreloaderService,
		private dialogRef: MatDialogRef<AddEditSubtaskComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.subtask_data = data?.subtask_data;
		this.edit_mode = this.subtask_data ? true : false;
	}

	ngOnInit(): void {
		this.buildForm();
		this.getDataForAdd();
		if (this.edit_mode) {
			this.setEditData();
		}
	}

	buildForm() {
		this.subtaskForm = this.fb.group({
			task_main_id: [null, Validators.required],
			default_description: [null, Validators.required],
			title_english: [null, Validators.required],
			title_arabic: [null, Validators.required],
			working_days: [null, Validators.required],
			user_group: [null, Validators.required],
		});
	}
	getDataForAdd() {
		this.spinner.show();
		this.ds
			.post("project_design/get_add_setting_subtasks", {})
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.setting_task_type = res?.setting_task_type;
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			});
	}
	setEditData() {
		this.subtaskForm
			.get("task_main_id")
			.setValue(this.subtask_data.task_main_id);
		this.subtaskForm
			.get("default_description")
			.setValue(this.subtask_data.default_description);
		this.subtaskForm
			.get("title_english")
			.setValue(this.subtask_data.title_english);
		this.subtaskForm
			.get("title_arabic")
			.setValue(this.subtask_data.title_arabic);
		this.subtaskForm
			.get("working_days")
			.setValue(this.subtask_data.working_days);
		this.subtaskForm.get("user_group").setValue(this.subtask_data.user_group);
	}

	addEditSubtask() {
		this.spinner.show();
		let param = new FormData();
		let url = "project_design/add_settings_sub_tasks";
		if (this.edit_mode) {
			param.append("sub_task_id", this.subtask_data.sub_task_id);
			url = "project_design/update_settings_sub_tasks";
		}
		param.append("task_main_id", this.subtaskForm.value.task_main_id);
		param.append(
			"default_description",
			this.subtaskForm.value.default_description
		);
		param.append("title_english", this.subtaskForm.value.title_english || "");
		param.append("title_arabic", this.subtaskForm.value.title_arabic || "");
		param.append("working_days", this.subtaskForm.value.working_days || "");
		param.append("user_group", this.subtaskForm.value.user_group || "6");
		this.ds.post(url, param).subscribe((res) => {
			this.spinner.hide();
			if (res.status) {
				this.sweetAlert.successToast(res?.message, 2000);
				this.dialogRef.close(true);
			} else {
				this.sweetAlert.errorToast(res.error, 2000);
			}
		});
	}
}
