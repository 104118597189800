<div class="row">
	<div class="col-sm-12">
		<h5>{{"lang_configurations" | language}}</h5>
	</div>
	<div class="col-sm-12">
		<mat-horizontal-stepper #stepper [linear]="true" class="pad0">
			<mat-step>
				<ng-template matStepLabel>
					<div class="step">
						<div class="st">
							<i class="icon-5x fa fa-wpforms fa-icon"></i>
						</div>
					</div>
					<div>{{ "lang_list_allowed" | language }}</div>
				</ng-template>
				<div class="row">
					<div class="col-12 col-md-3" *ngIf="!hideTreeMenu">
						<div>
							<div class="tree-title">
								<span>Tree Menu</span>
								<i class="fa fa-close" (click)="hideTreeMenu = true"></i>
							</div>
							<div class="tree-body">
								<div class="mb-2">
									<input mat-input type="text" class="form-control" placeholder="Filter" [(ngModel)]="filterText" (keyup)="filterTree($event)">
								</div>
								<div class="contractor-tree">
									<tree-root
									#tree
									[options]="options"
									[nodes]="nodes"
									(toggleExpanded)="onToggleTree($event)"
									(activate)="toggleViewAction(tree)"
									(deactivate)="deactivate($event)"
								></tree-root>
								</div>
							</div>
						</div>
					</div>
					<div class=""[ngClass]="{
						'col-12 col-md-9': !hideTreeMenu,
						'col-12 col-md-12': hideTreeMenu
					}" *ngIf="!hideTreeContent">
						<div style="display: flex; align-items: center;">
							<span class="show-tree-menu" *ngIf="hideTreeMenu">
								<i class="fa fa-expand" (click)="onHideTreeMenu()"></i>
							</span>
							<p innerHTML="{{treePath}}" *ngIf="treePath" class="tree-path"></p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-10 col-sm-8 col-xs-12 mb-10"></div>
					<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
						<button
							class="albutton mnabrbutton mat-accent"
							mat-button
							matStepperNext
						>
							{{ "lang_next" | language }}
						</button>
					</div>
				</div>
			</mat-step>
			<mat-step>
				<ng-template matStepLabel>
					<div class="step">
						<div class="st">
							<i class="icon-5x fa fa-user fa-icon"></i>
						</div>
					</div>
					<div>{{ "lang_contractor_manager" | language }}</div>
				</ng-template>
				<div class="row">
					<div class="col-12 p-3">
						<label class="mb-10">
							{{"lang_contractor_manager_required" | language}} !?.
							<span class="red"> * </span> </label
						><br />
						<mat-radio-group [(ngModel)]="need_contractor_manager">
							<mat-radio-button value="0">{{
								"lang_no" | language
							}}</mat-radio-button>
							<mat-radio-button value="1">{{
								"lang_yes" | language
							}}</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
				<div class="row">
					<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
						<button
							class="albutton mnabrbutton mat-accent"
							mat-button
							matStepperPrevious>
							{{ "lang_previous" | language }}
						</button>
					</div>
					<div class="col-md-8 col-sm-4 col-xs-12 mb-10"></div>
					<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
						<button
							class="albutton mnabrbutton mat-accent"
							mat-button>
							{{ "lang_next" | language }}
						</button>
					</div>
				</div>
			</mat-step>
		</mat-horizontal-stepper>
	</div>
</div>