import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-hse-management",
  templateUrl: "./hse-management.component.html",
  styleUrls: ["./hse-management.component.scss"],
})
export class HseManagementComponent implements OnInit {
  @Input() report_data;
  constructor() {}

  ngOnInit(): void {}
}
