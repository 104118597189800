import { Component, OnInit } from '@angular/core';
 
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AddReviewersComponent } from '../add-reviewers/add-reviewers.component';
@Component({
  selector: 'app-reviewers-list',
  templateUrl: './reviewers-list.component.html',
  styleUrls: ['./reviewers-list.component.scss']
})
export class ReviewersListComponent implements OnInit {
  lang_key          = localStorage.getItem('lang_key') || 'en';
  reviewers      = [];
  loading           = false;
  searchKey         = '';
  payrole_page_no   = 1;
  payrole_page_size = 1;
  constructor(
    public dialog:MatDialog, public modalService: BsModalService,private ds:DataService,
    private _sweetAlertService:SweetAlertService, public lang:LanguagePipe,   private spinner: PreloaderService,
  ) { }

  ngOnInit(): void {
    this.getAllData()
  }

  load_by_page_no(page) {
    this.payrole_page_no = page;
  }
  load_by_page_size(size) {
    this.payrole_page_size = size;
  }


  getAllData(){
    this.reviewers = [];
    this.loading = true;
    this.ds.getActionByUrl([], `form/FORM_SAL/get_all_reviewers`).subscribe(data => {
      if (data.status) {
        this.reviewers = data.records;
    
    
      }else{
        this.reviewers = []
        this._sweetAlertService.errorToast(data.error,2000)
      }
      this.loading = false;
    },
    error => {
      this.loading = false;
      this.reviewers = []
      this._sweetAlertService.errorToast(this.lang.transform('lang_internal_server_error'),2000)
    });
  }

  openModal() {
    const dialogRef = this.dialog.open(AddReviewersComponent,{
      disableClose: true,
      width:'600px',
      data:''
      
    });

    dialogRef.afterClosed().subscribe(data => {
      if(data?.saved){
        this.ngOnInit()
      }
    })
  }


  deleteTask(id:number) {
    Swal.fire({
      title: this.lang.transform('lang_are_you_sure'),
      text: '', 
      icon: 'warning',
      confirmButtonColor: '#0e3768',
      showCancelButton: true, 
      confirmButtonText: this.lang.transform('lang_delete'),
      cancelButtonText: this.lang.transform('lang_cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        /* this.spinner.show(); */
      /*   Swal.fire(
          this.lang.transform('lang_deleted'),
          this.lang.transform('lang_success'),
          'success'
        ) */
       
         
      this.ds.delete( `form/FORM_SAL/delete_reviewer/${id}`).subscribe(data => {
          if (data.status) {
      
            this.getAllData()
            Swal.fire(
              this.lang.transform('lang_deleted'),
              this.lang.transform('lang_success'),
              'success'
            )
          } else {
            this._sweetAlertService.errorMessage(data?.error);
          }
          this.spinner.hide();
        }, (err) => {
          this._sweetAlertService.errorMessage(err?.error.error);
          this.spinner.hide();
        }) 
        
        /* this._AdminsService.DeleteAvailabilityById(id).subscribe(
          (res:any) => {

            const creds = this.thirdFormGroup.controls.availability as FormArray;
   
            creds.removeAt(index);
            Swal.fire(
              'Deleted!',
              'Availability has been deleted.',
              'success'
            )
          }) */
          
          
      
      
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          `${this.lang.transform('lang_cancel')}`,
          `${this.lang.transform('lang_cancel')+' '+this.lang.transform('lang_completed')}`,
          'error'
        )
      }
    })
 


  }
}
