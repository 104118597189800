import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import * as moment from "moment";
// import * as Editor from "assets/libraries/ckeditor/build/ckeditor";
import { DataService } from "@core/bootstrap/data.service";
import { PreloaderService } from "@core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
@Component({
  selector: "app-add-update-meeting",
  templateUrl: "./add-update-meeting.component.html",
  styleUrls: ["./add-update-meeting.component.scss"],
})
export class AddUpdateMeetingComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key") || "en";
  // public myEditor = Editor;
  config = {
    language: this.lang_key,
    toolbar: [
      {
        name: "document",
        items: [
          "Source",
          "-",
          "Save",
          "NewPage",
          "Preview",
          "Print",
          "-",
          "Templates",
        ],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          "Copy",
          "Paste",
          "PasteText",
          "PasteFromWord",
          "-",
          "Undo",
          "Redo",
        ],
      },
      {
        name: "editing",
        items: ["Find", "Replace", "-", "SelectAll"],
      },
      {
        name: "forms",
        items: [
          "Form",
          "Checkbox",
          "Radio",
          "TextField",
          "Textarea",
          "Select",
          "Button",
          "HiddenField",
        ],
      },
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "Subscript",
          "Superscript",
          "-",
          "CopyFormatting",
          "RemoveFormat",
        ],
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      {
        name: "insert",
        items: [
          "Image",
          "Flash",
          "Table",
          "wrapCode",
          "TableCellProperties",
          "TableColumnResize",
          "TableProperties",
          "TableToolbar",
          "HorizontalRule",
          "Smiley",
          "SpecialChar",
          "PageBreak",
          "Iframe",
        ],
      },
      { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
      { name: "colors", items: ["TextColor", "BGColor"] },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "CreateDiv",
          "-",
          "JustifyLeft",
          "JustifyCenter",
          "JustifyRight",
          "JustifyBlock",
          "-",
          "BidiLtr",
          "BidiRtl",
          "Language",
        ],
      },
      { name: "tools", items: ["Maximize", "ShowBlocks"] },
    ],
    extraPlugins:
      "justify,colorbutton,font,codesnippet,autocomplete,find,selectall,iframe,div,enterkey,notification,bidi",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    removeButtons: "Image",
  };
  point_data;
  point_form: FormGroup;
  dateMobiscroll;
  autoButtons = [
    {
      text: this.lang.transform("lang_save"),
      handler: "set",
    },
  ];
  search_point_users = [];
  checklistArray: FormArray;
  task_id;
  editMode = false;
  meetingData;
  constructor(
    private fb: FormBuilder,
    private lang: LanguagePipe,
    public ds: DataService,
    private spinner: PreloaderService,
    public dialogRef: MatDialogRef<AddUpdateMeetingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sweetAlert: SweetAlertService
  ) {
    this.task_id = data?.task_id;
    this.buildForm();
    if (data?.meeting) {
      this.editMode = true;
      this.meetingData = data?.meeting;
      this.setEditData();
    } else {
      this.editMode = false;
    }
  }

  ngOnInit(): void {
    moment.locale("en");
  }

  buildForm() {
    this.point_form = this.fb.group({
      meeting_title: ["", Validators.required],
      meeting_date: [null, Validators.required],
      meeting_details: [null],
      users: [null],
      point: this.fb.array([]),
    });
    this.checklistArray = this.point_form.get("point") as FormArray;
  }

  get checklistFormGroup() {
    return this.point_form.get("point") as FormArray;
  }

  checklistRecords(data?) {
    return this.fb.group({
      title: [data?.point_notes || "", Validators.required],
    });
  }

  addChecklist(data?) {
    this.checklistArray.push(this.checklistRecords(data));
  }

  removeChecklist(index) {
    this.checklistArray.removeAt(index);
  }

  custmizeDate(type) {
    if (type == "start_date") {
      const date_formatted = moment(this.dateMobiscroll).format("YYYY/MM/DD");
      this.point_form.get("meeting_date").setValue(date_formatted);
    }
  }

  //search users
  public search_users(key) {
    this.search_point_users = [];
    if (key && key.term) {
      let param = new FormData();
      param.append("search", key.term);
      param.append("lang_key", this.lang_key);
      param.append("user_type_id", "1");
      this.ds
        .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
        .subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            this.search_point_users = res.list;
          }
        });
    }
  }

  setEditData() {
    this.point_form
      .get("meeting_title")
      .setValue(this.meetingData?.meeting_title);
    this.point_form
      .get("meeting_date")
      .setValue(
        moment(new Date(this.meetingData?.meeting_date_en)).format("YYYY/MM/DD")
      );
    this.point_form
      .get("meeting_details")
      .setValue(this.meetingData?.meeting_details);
    this.point_form
      .get("users")
      .setValue(this.meetingData?.users?.map((el) => el?.emp_id));
    this.meetingData?.points?.map((el) => {
      this.addChecklist(el);
    });
    this.search_point_users = this.meetingData?.users?.map((el) => {
      return {
        label: el?.firstname_english + " " + el?.lastname_english,
        value: el?.emp_id,
      };
    });
  }

  addUpdateMeeting() {
    let formData = new FormData();
    formData.append(
      "meeting_title",
      this.point_form.get("meeting_title").value || ""
    );
    formData.append(
      "meeting_date",
      this.point_form.get("meeting_date").value || ""
    );
    formData.append(
      "meeting_details",
      this.point_form.get("meeting_details").value || ""
    );
    formData.append("users", this.point_form.get("users").value || "");
    this.checklistArray.controls.forEach((el, key) => {
      formData.append(`point[${key}][title]`, el?.get("title").value || "");
    });
    let url = "tasks/add_task_meetings";
    if (this.editMode) {
      url = "tasks/update_task_meetings";
      formData.append("meeting_id", this.meetingData?.meeting_id || "");
    } else {
      formData.append("task_id", this.task_id || "");
    }
    this.spinner.show();
    this.ds.post(url, formData).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.dialogRef.close(true);
          this.sweetAlert.successToast(
            this.lang.transform("lang_success"),
            3000
          );
        } else {
          this.sweetAlert.errorToast(res?.error, 3000);
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
}
