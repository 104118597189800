import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
	selector: "app-supervision-bill-quantities",
	templateUrl: "./supervision-bill-quantities.component.html",
	styleUrls: ["./supervision-bill-quantities.component.scss"],
})
export class SupervisionBillQuantitiesComponent implements OnInit {
	modalRef: BsModalRef;
	public config = environment.modelconfig;
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, this.config);
	}
	@Input() supervision_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() project_data;
	@Input() makeAction;
	public isFile;
	filename = this.lang.transform("lang_select_files");
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	form: FormGroup;
	billpageno = 1;
	billpagesize = "10";
	billsearchKey = "";
	billListData: any = [];
	billnodata: string = this.lang.transform("lang_loading");
	billdata: any;
	writer: "";
	viewitemdata: any = [];
	samplefile = "";
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public alert: AlertService,
		public modalService: BsModalService,
		public dialog: MatDialog
	) {}
	ngOnInit(): void {
		this.buildform();
		this.load_supervision_quantities();
		this.get_sample_bill_quantity_file();
	}
	public get_sample_bill_quantity_file() {
		this.ds
			.getActionByUrl(
				[],
				"rUWFLQ7ckW/" +
					this.project_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.samplefile = res.file;
				}
			});
	}
	public buildform() {
		this.form = new FormGroup({
			projects_profile_id: new FormControl("", [Validators.required]),
			projects_supervision_id: new FormControl("", [Validators.required]),
			bill_quantity_id: new FormControl(""),
			bill_quantity_tax: new FormControl("0", [Validators.required]),
			bill_quantity_description: new FormControl("", [Validators.required]),
			bill_quantity_file: new FormControl(""),
		});
	}
	public search_supervision_quantities() {
		this.billpageno = 1;
		this.billpagesize = "10";
		this.load_supervision_quantities();
	}
	public load_supervision_quantities_page(page) {
		this.spinner.show();
		this.billpageno = page;
		this.billpagesize = this.billpagesize;
		this.load_supervision_quantities();
	}
	public load_supervision_quantities_pagesize(size) {
		this.spinner.show();
		this.billpageno = 1;
		this.billpagesize = size;
		this.load_supervision_quantities();
	}
	public load_supervision_quantities() {
		let formdata = new FormData();
		formdata.append("search_key", this.billsearchKey);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formdata),
				"6xTSY3JDTX/" +
					this.project_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id +
					"/" +
					this.billpageno +
					"/" +
					this.billpagesize
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.writer = res.records[0].writer;
						this.billListData = res;
						this.billdata = res.data;
					} else {
						this.billListData = [];
						this.billnodata = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.billListData = [];
					this.billnodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}
	public viewitems(list) {
		this.viewitemdata = list;
	}
	public emptyaddform() {
		this.alert.clear();
		this.form.reset();
		this.form.get("bill_quantity_tax").setValue("0");
	}
	public emptyEditform() {
		this.alert.clear();
		this.form.reset();
		this.form.get("bill_quantity_id").setValue(this.billdata.bill_quantity_id);
		this.form
			.get("bill_quantity_description")
			.setValue(this.billdata.bill_quantity_description);
		this.form
			.get("bill_quantity_tax")
			.setValue(this.billdata.bill_quantity_tax);
		this.filename = this.lang.transform("lang_select_files");
		this.isFile = false;
	}
	public UploadFile(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			this.isFile = true;
			this.filename = event.target.files[0].name;
			this.form
				.get("bill_quantity_file")
				.setValue(event.target.files[0], { emitEvent: true });
		} else {
			this.isFile = false;
			this.form.get("bill_quantity_file").setValue(null);
			this.filename = this.lang.transform("lang_select_files");
		}
	}
	public removeFile() {
		this.isFile = false;
		this.form.get("bill_quantity_file").setValue(null);
		this.filename = this.lang.transform("lang_select_files");
	}
	public submit() {
		this.spinner.show();
		this.form
			.get("projects_profile_id")
			.setValue(this.supervision_data.projects_profile_id);
		this.form
			.get("projects_supervision_id")
			.setValue(this.supervision_data.projects_supervision_id);
		let url =
			this.form.get("bill_quantity_id").value &&
			this.form.get("bill_quantity_id").value != null
				? "IGrCB8VGNH/" + this.form.get("bill_quantity_id").value
				: "9L1b529uTT";
		this.ds
			.postActionByUrl(this.ds.json2formdata(this.form.value), url)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.alert.success(res.msg);
						this.form.reset();
						this.load_supervision_quantities();
						setTimeout(() => {
							this.modalRef.hide();
						}, 1000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	//delete bill of quantity
	public deletebillof_quantity() {
		const dialogRef = this.dialog.open(ConfirmationDialog, {
			disableClose: true,
			width: "400px",
			data: {
				title: this.lang.transform("lang_confirmation"),
				message: this.lang.transform("lang_are_you_sure"),
			},
		});
		dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.spinner.show();
				this.ds
					.deleteActionByUrl(
						[],
						"EA1gdw5L7H/" +
							this.supervision_data.projects_profile_id +
							"/" +
							this.supervision_data.projects_supervision_id
					)
					.subscribe(
						(data) => {
							this.spinner.hide();
							if (data.status) {
								this.billdata = [];
								this.load_supervision_quantities();
								this.ds.dialogf("", data.msg);
							} else {
								this.ds.dialogf("", data.error);
							}
						},
						(error) => {
							this.ds.dialogf(
								"",
								error && error.error && error.error.error
									? error.error.error
									: this.lang.transform("lang_internal_server_error")
							);
							this.spinner.hide();
						}
					);
			}
		});
	}
}
