<div class="row">
    <div  class="col-12 mb-3">
        <h4  class="page-title">
            <i  class="fa fa-folder mnabricon"></i>
            <span >{{'lang_pay_role'|language}}</span>
        </h4>
    </div>
</div>

<div class="payrole-header">
	<div class="row">
		<div class="col-12 col-sm-6 mb-2 mb-sm-0">
			<!-- <div class="search-input">
				<input
					type="text"
					class="form-control almnabrformcontrol"
					[(ngModel)]="searchKey"
					placeholder="{{ 'lang_search' | language }}"
				/>
				<i class="search-icon fa fa-search mnabricon" [dir]="lang_key == 'ar'? 'rtl':'ltr'"></i>
			</div> -->
		</div>
		<div class="col-12 col-sm-3 mb-2 mb-sm-0">
			  <button
				class="albutton mnabrbutton"
				mat-button
                routerLink="/human_resources/payrole/reviewers"
			>
                <i class="fa fa-users" style="font-size: 17px; margin: 0 5px"></i>
			    {{ "lang_reviewers" | language }}
			</button>  
		</div>
		<div class="col-12 col-sm-3 mb-2 mb-sm-0">
			<button
				class="albutton mnabrbutton"
				mat-button
				routerLink="/human_resources/payrole/create"
                [queryParams]="{ t_id: '0'}"
			>
				<i class="fa fa-plus" style="font-size: 17px; margin: 0 5px"></i>
				<span>{{'lang_add'|language}} {{'lang_pay_role'|language}}</span>
			</button>
		</div>
	</div>
</div>

<div class="payrole-list">
    <div class="row">
        <div class="col-12">
            <div class="table-responsive customResponsive">
                <table border="1" class="table CustomTable">
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>{{'lang_request_no'|language}}</td>
                            <td>{{'lang_subject'|language}}</td>
                            <td>{{'lang_month'|language}}</td>
                            <!-- <td>{{'lang_year'|language}}</td> -->
                       
                            <td>{{'lang_number_of_employees'|language}}</td>
                            <!-- <td>{{'lang_number_of_success_employees'|language}}</td>
                            <td>{{'lang_number_of_failed_employees'|language}}</td> -->
                          <!--   <td>{{'lang_number_of_error_employees'|language}}</td> -->
                            <td>{{'lang_invoice_total_amount'|language}}</td>
                            <td>{{'lang_last_step_opened'|language}}</td>
                            <td>{{'lang_action'|language}}</td>
                            <!-- <td>Export</td> -->
                        </tr>
                    </thead>
                    <tbody class="AppendList" *ngIf="payrole_list && !loading">
                        <tr *ngFor="let item of payrole_list; let i = index">
                            <td>{{ i+1 }}</td>
                            <td>{{ item?.transaction_request_id }}</td>
                            <td>{{ item?.subject }}</td>
                            <td>{{ item?.salary_month +'/'+item?.salary_year}}</td>
                            
                            <td>{{ item?.number_of_employees }}</td>
                            <!--  -->
                      <!--       <td>{{ item?.number_of_success_employees }}</td>
                            <td>{{ item?.number_of_failed_employees }}</td> -->
                           <!--  <td>{{ item?.number_of_error_employees }}</td> -->
                           <td>{{ item?.total_amount }}</td>
                           <td>{{ item?.transaction_request_last_step }}</td>
                       
                            
                            <td  >
                                <button mat-button routerLink="/transactions/form/FORM_SAL/vr/{{ item?.transaction_request_id }}">
                                    <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="loading">
                        <tr>
                            <td colspan="8" align="center">
                                <ngx-skeleton-loader  count="5" [theme]="{ 'border-radius': '3px', height: '50px' }"></ngx-skeleton-loader>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!payrole_list.length && !loading" class="AppendList">
                        <tr>
                            <td colspan="14" align="center">
                                <no-data style="margin: auto;"  [Opened]="!payrole_list.length && !loading"></no-data>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
    </div>
    <div
        *ngIf="payrole_list?.length"
        class="mb-10 custom-footer"
    >
        <div class="row responsive_pagination">
            <div
                class="col-lg-3 col-sm-3 col-6 mb-10"
                style="height: fit-content; margin: auto 0"
            >
                {{ "lang_total" | language }} :
                {{ payrole_list?.page?.total_records ? payrole_list?.page?.total_records : 0 }}
            </div>
            <div
                class="col-lg-6 col-sm-6 mb-10"
                style="display: flex; justify-content: center"
            >
                <ngb-pagination
                    class="m-auto"
                    [collectionSize]="total_records"
                    [rotate]="true"
                    [ellipses]="false"
                    [maxSize]="3"
                    [boundaryLinks]="true"
                    [(page)]="payrole_page_no"
                    [pageSize]="payrole_page_size"
                    (pageChange)="load_by_page_no($event)"
                ></ngb-pagination>
            </div>
            <div
                class="col-lg-3 col-sm-3"
                style="display: flex; justify-content: end; margin: auto"
            >
                <mat-select
                    [(ngModel)]="payrole_page_size"
                    (selectionChange)="load_by_page_size($event.value)"
                >
                    <mat-option class="text-center" [value]="10"
                        >10 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="20"
                        >20 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="50"
                        >50 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="100"
                        >100 {{ "lang_per_page" | language }}</mat-option
                    >
                    <mat-option class="text-center" [value]="500"
                        >500 {{ "lang_per_page" | language }}</mat-option
                    >
                </mat-select>
            </div>
        </div>
    </div>
</div>
