<breadcrumb></breadcrumb>
<div class="themesection">
    <section class="panel-expansion matborderno">
        <mat-accordion class="accounts_masters" >
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_balance_sheet' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body">
                    <div class="mbrl15">
                        <form class="form" [formGroup]="form" (ngSubmit)="fetch_statements()">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                    <select formControlName="branch_id" class="form-control almnabrformcontrol" matTooltip="{{'lang_branch' | language }}" (change)="spinner.show();selectBrach($event.target.value);">
                                        <option value="0">{{'lang_choose_options' | language }}</option>
                                        <option *ngFor="let branch of branches" value="{{branch.id}}">{{branch.title}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('branch_id').value && form.get('branch_id').value != '0' ">
                                    <ng-select 
                                            [items]="finances" 
                                            [multiple]="false" 
                                            bindLabel="label" 
                                            bindValue="value" 
                                            appendTo="body" 
                                            clearAllText="Clear"
                                            formControlName="finance_id"
                                            placeholder="{{'lang_financial_years' | language }}"
                                            (change) = "ds.setDate($event, form);"
                                        >
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                    <div class="form-group">
                                        <label>{{'lang_period_from' | language }} : <span class="red">*</span> </label><br>
                                        <input formControlName="period_from" id="period_from" class="form-control almnabrformcontrol period_from" placeholder="YYYY/MM/DD" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                    <div class="form-group">
                                        <label>{{'lang_period_to' | language }} :<span class="red">*</span> </label><br>
                                        <input formControlName="period_to" id="period_to" class="form-control almnabrformcontrol period_to" placeholder="YYYY/MM/DD" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                    <div class="form-group">
                                        <mat-checkbox formControlName="compare_previous_year" (change)="fetch_statements()">{{'lang_compare_last_year' | language }}</mat-checkbox>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                    <mat-radio-group aria-label="Select an option" formControlName="report_type">
                                        <mat-radio-button value="GLEDGER" checked>{{'lang_based_on_general_ledger' | language }} </mat-radio-button>
                                        <mat-radio-button value="ALEDGER"> {{'lang_based_on_accounts' | language }}</mat-radio-button>
                                        <mat-radio-button value="ALEVEL"> {{'lang_account_code_level' | language }}</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10"
                                    *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0' && form.get('report_type').value == 'ALEVEL' ">
                                    <div class="form-group">
                                        <select class="form-control almnabrformcontrol account_level" formControlName="account_level">
                                            <option value="1"> {{'lang_level' | language }} 1</option>
                                            <option value="2"> {{'lang_level' | language }} 2</option>
                                            <option value="3"> {{'lang_level' | language }} 3</option>
                                            <option value="4"> {{'lang_level' | language }} 4</option>
                                            <option value="5"> {{'lang_level' | language }} 5</option>
                                            <option value="6"> {{'lang_level' | language }} 6</option>
                                            <option value="7"> {{'lang_level' | language }} 7</option>
                                            <option value="8"> {{'lang_level' | language }} 8</option>
                                            <option value="9"> {{'lang_level' | language }} 9</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                    <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                                </div>
                            </div>
                        </form>
                        <div class="row" *ngIf="datastatus && form.get('branch_id').value && form.get('branch_id').value != '0'">
                            <!--Previous Year Assets, Capital and Liabilities Statements-->
                            <div class="col-md-6 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('compare_previous_year').value == true && padata.length > 0" [ngClass]="{'col-md-6 col-sm-6' : form.get('compare_previous_year').value == true} ">
                                <div class="row">
                                    <div class="col-12 mb-10">
                                        <div class="table-responsive dropdownyes customResponsive tablefields">
                                            <span class="red">{{'lang_assets_statements' | language }}</span>
                                            <table border="1" class="table CustomTable">
                                                <thead>
                                                    <tr>
                                                        <td>#</td>
                                                        <td>{{'lang_accounts_acccode' | language }}</td>
                                                        <td>{{'lang_account' | language }}</td>
                                                        <td>{{'lang_invoice_item_amount' | language }}</td>
                                                        <td>{{'lang_status' | language }}</td>
                                                        <td>{{'lang_action' | language }}</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="padata.length != '0' " class="AppendList">
                                                    <tr *ngFor="let pa of padata; let i = index;let odd=odd; let even=even"
                                                        [ngClass]="{ odd: odd, even: even }" class="{{pa.class}}">
                                                        <td>{{i+1}}</td>
                                                        <td>{{pa.account_code}}</td>
                                                        <td>{{pa.account_name}}</td>
                                                        <td>{{ds.addCommas(pa.amount)}}</td>
                                                        <td *ngIf="pa.status == 'C'">
                                                            {{'lang_credit' | language }}
                                                        </td>
                                                        <td *ngIf="pa.status == 'D'">
                                                            {{'lang_debit' | language }}
                                                        </td>
                                                        <td *ngIf="pa.transaction_allow == '0'">
                                                            <a target="_blank" class="fa fa-exchange mnabricon" routerLink="/accounts/statement_of_accounts/{{pa.account_cost_id}}/" matTooltip="{{'lang_view_transactions' | language}}"></a>
                                                        </td>
                                                        <td *ngIf="pa.transaction_allow == '1'"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="d-flex justify-content-between p-2" *ngIf="padata.length>0">
                                                <ngb-pagination [collectionSize]="pacollectionSize" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="papage" [pageSize]="papageSize" (pageChange)="passets()"></ngb-pagination>
                                                <label>{{'lang_total' | language }} : {{pacollectionSize}}</label>
                                                <select class="customselect" [(ngModel)]="papageSize" (change)="passets()">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="20">20</option>
                                                    <option [ngValue]="50">50</option>
                                                    <option [ngValue]="100">100</option>
                                                    <option [ngValue]="250">250</option>
                                                    <option [ngValue]="500">500</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-10">
                                        <div class="table-responsive dropdownyes customResponsive tablefields">
                                            <span class="red">{{'lang_liabilities_statements' | language }}</span><br>
                                            <span>{{'lang_owner_equity_statements' | language }}</span>
                                            <table border="1" class="table CustomTable">
                                                <thead>
                                                    <tr>
                                                        <td>#</td>
                                                        <td>{{'lang_accounts_acccode' | language }}</td>
                                                        <td>{{'lang_account' | language }}</td>
                                                        <td>{{'lang_invoice_item_amount' | language }}</td>
                                                        <td>{{'lang_status' | language }}</td>
                                                        <td>{{'lang_action' | language }}</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="pldata.length != '0' " class="AppendList">
                                                    <tr *ngFor="let pl of pldata; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="{{pl.class}}">
                                                        <td>{{i+1}}</td>
                                                        <td>{{pl.account_code}}</td>
                                                        <td>{{pl.account_name}}</td>
                                                        <td>{{ds.addCommas(pl.amount)}}</td>
                                                        <td *ngIf="pl.status == 'C'">
                                                            {{'lang_credit' | language }}
                                                        </td>
                                                        <td *ngIf="pl.status == 'D'">
                                                            {{'lang_debit' | language }}
                                                        </td>
                                                        <td *ngIf="pl.transaction_allow == '0'">
                                                            <a target="_blank" class="fa fa-exchange mnabricon" routerLink="/accounts/statement_of_accounts/{{pl.account_cost_id}}/" matTooltip="{{'lang_view_transactions' | language}}"></a>
                                                        </td>
                                                        <td *ngIf="pl.transaction_allow == '1'"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="d-flex justify-content-between p-2" *ngIf="pldata.length>0">
                                                <ngb-pagination [collectionSize]="plcollectionSize" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="plpage" [pageSize]="plpageSize" (pageChange)="pliable()"></ngb-pagination>
                                                <label>{{'lang_total' | language }} : {{plcollectionSize}}</label>
                                                <select class="customselect" [(ngModel)]="plpageSize" (change)="pliable()">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="20">20</option>
                                                    <option [ngValue]="50">50</option>
                                                    <option [ngValue]="100">100</option>
                                                    <option [ngValue]="250">250</option>
                                                    <option [ngValue]="500">500</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-10">
                                        <div class="table-responsive dropdownyes customResponsive tablefields">
                                            <span>{{'lang_current_liabilities' | language }}</span>
                                            <table border="1" class="table CustomTable">
                                                <thead>
                                                    <tr>
                                                        <td>#</td>
                                                        <td>{{'lang_accounts_acccode' | language }}</td>
                                                        <td>{{'lang_account' | language }}</td>
                                                        <td>{{'lang_invoice_item_amount' | language }}</td>
                                                        <td>{{'lang_status' | language }}</td>
                                                        <td>{{'lang_action' | language }}</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="pcdata.length != '0' " class="AppendList">
                                                    <tr *ngFor="let pc of pcdata; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="{{pc.class}}">
                                                        <td>{{i+1}}</td>
                                                        <td>{{pc.account_code}}</td>
                                                        <td>{{pc.account_name}}</td>
                                                        <td>{{ds.addCommas(pc.amount)}}</td>
                                                        <td *ngIf="pc.status == 'C'">
                                                            {{'lang_credit' | language }}
                                                        </td>
                                                        <td *ngIf="pc.status == 'D'">
                                                            {{'lang_debit' | language }}
                                                        </td>
                                                        <td *ngIf="pc.transaction_allow == '0'">
                                                            <a target="_blank" class="fa fa-exchange mnabricon" routerLink="/accounts/statement_of_accounts/{{pc.account_cost_id}}/" matTooltip="{{'lang_view_transactions' | language}}"></a>
                                                        </td>
                                                        <td *ngIf="pc.transaction_allow == '1'"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="d-flex justify-content-between p-2" *ngIf="pcdata.length>0">
                                                <ngb-pagination [collectionSize]="pccollectionSize" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="pcpage" [pageSize]="pcpageSize" (pageChange)="pcapital()"></ngb-pagination>
                                                <label>{{'lang_total' | language }} : {{pccollectionSize}}</label>
                                                <select class="customselect" [(ngModel)]="pcpageSize" (change)="pcapital()">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="20">20</option>
                                                    <option [ngValue]="50">50</option>
                                                    <option [ngValue]="100">100</option>
                                                    <option [ngValue]="250">250</option>
                                                    <option [ngValue]="500">500</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Current Year Assets, Capital and Liabilities Statements-->
                            <div class="col-xs-12 mb-10"
                                [ngClass]="{'col-md-6 col-sm-6' : form.get('compare_previous_year').value == true, 'col-12' : form.get('compare_previous_year').value == false} ">
                                <div class="row">
                                    <div class="col-12 mb-10">
                                        <div class="table-responsive dropdownyes customResponsive tablefields">
                                            <span class="red">{{'lang_assets_statements' | language }}</span>
                                            <table border="1" class="table CustomTable">
                                                <thead>
                                                    <tr>
                                                        <td>#</td>
                                                        <td>{{'lang_accounts_acccode' | language }}</td>
                                                        <td>{{'lang_account' | language }}</td>
                                                        <td>{{'lang_invoice_item_amount' | language }}</td>
                                                        <td>{{'lang_status' | language }}</td>
                                                        <td>{{'lang_action' | language }}</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="cadata.length != '0' " class="AppendList">
                                                    <tr *ngFor="let ca of cadata; let i = index;let odd=odd; let even=even"
                                                        [ngClass]="{ odd: odd, even: even }" class="{{ca.class}}">
                                                        <td>{{i+1}}</td>
                                                        <td>{{ca.account_code}}</td>
                                                        <td>{{ca.account_name}}</td>
                                                        <td>{{ds.addCommas(ca.amount)}}</td>
                                                        <td *ngIf="ca.status == 'C'">
                                                            {{'lang_credit' | language }}
                                                        </td>
                                                        <td *ngIf="ca.status == 'D'">
                                                            {{'lang_debit' | language }}
                                                        </td>
                                                        <td *ngIf="ca.transaction_allow == '0'">
                                                            <a target="_blank" class="fa fa-exchange mnabricon" routerLink="/accounts/statement_of_accounts/{{ca.account_cost_id}}/" matTooltip="{{'lang_view_transactions' | language}}"></a>
                                                        </td>
                                                        <td *ngIf="ca.transaction_allow == '1'"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="d-flex justify-content-between p-2" *ngIf="cadata.length>0">
                                                <ngb-pagination [collectionSize]="cacollectionSize" [maxSize]="3" [boundaryLinks]="true" [(page)]="capage" [pageSize]="capageSize" (pageChange)="cassets()"></ngb-pagination>
                                                <label>{{'lang_total' | language }} : {{cacollectionSize}}</label>
                                                <select class="customselect" [(ngModel)]="capageSize" (change)="cassets()">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="20">20</option>
                                                    <option [ngValue]="50">50</option>
                                                    <option [ngValue]="100">100</option>
                                                    <option [ngValue]="250">250</option>
                                                    <option [ngValue]="500">500</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-10">
                                        <div class="table-responsive dropdownyes customResponsive tablefields">
                                            <span class="red">{{'lang_liabilities_statements' | language }}</span> <br>
                                            <span>{{'lang_owner_equity_statements' | language }}</span>
                                            <table border="1" class="table CustomTable">
                                                <thead>
                                                    <tr>
                                                        <td>#</td>
                                                        <td>{{'lang_accounts_acccode' | language }}</td>
                                                        <td>{{'lang_account' | language }}</td>
                                                        <td>{{'lang_invoice_item_amount' | language }}</td>
                                                        <td>{{'lang_status' | language }}</td>
                                                        <td>{{'lang_action' | language }}</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="cldata.length != '0' " class="AppendList">
                                                    <tr *ngFor="let cl of cldata; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="{{cl.class}}">
                                                        <td>{{i+1}}</td>
                                                        <td>{{cl.account_code}}</td>
                                                        <td>{{cl.account_name}}</td>
                                                        <td>{{ds.addCommas(cl.amount)}}</td>
                                                        <td *ngIf="cl.status == 'C'">
                                                            {{'lang_credit' | language }}
                                                        </td>
                                                        <td *ngIf="cl.status == 'D'">
                                                            {{'lang_debit' | language }}
                                                        </td>
                                                        <td *ngIf="cl.transaction_allow == '0'">
                                                            <a target="_blank" class="fa fa-exchange mnabricon" routerLink="/accounts/statement_of_accounts/{{cl.account_cost_id}}/" matTooltip="{{'lang_view_transactions' | language}}"></a>
                                                        </td>
                                                        <td *ngIf="cl.transaction_allow == '1'"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="d-flex justify-content-between p-2" *ngIf="cldata.length >0">
                                                <ngb-pagination [collectionSize]="clcollectionSize" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="clpage" [pageSize]="clpageSize" (pageChange)="cliable()"></ngb-pagination>
                                                <label>{{'lang_total' | language }} : {{clcollectionSize}}</label>
                                                <select class="customselect" [(ngModel)]="clpageSize" (change)="cliable()">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="20">20</option>
                                                    <option [ngValue]="50">50</option>
                                                    <option [ngValue]="100">100</option>
                                                    <option [ngValue]="250">250</option>
                                                    <option [ngValue]="500">500</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-10">
                                        <div class="table-responsive dropdownyes customResponsive tablefields">
                                            <span>{{'lang_current_liabilities' | language }}</span>
                                            <table border="1" class="table CustomTable">
                                                <thead>
                                                    <tr>
                                                        <td>#</td>
                                                        <td>{{'lang_accounts_acccode' | language }}</td>
                                                        <td>{{'lang_account' | language }}</td>
                                                        <td>{{'lang_invoice_item_amount' | language }}</td>
                                                        <td>{{'lang_status' | language }}</td>
                                                        <td>{{'lang_action' | language }}</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="ccdata.length != '0' " class="AppendList">
                                                    <tr *ngFor="let cc of ccdata; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="{{cc.class}}">
                                                        <td>{{i+1}}</td>
                                                        <td>{{cc.account_code}}</td>
                                                        <td>{{cc.account_name}}</td>
                                                        <td>{{ds.addCommas(cc.amount)}}</td>
                                                        <td *ngIf="cc.status == 'C'">
                                                            {{'lang_credit' | language }}
                                                        </td>
                                                        <td *ngIf="cc.status == 'D'">
                                                            {{'lang_debit' | language }}
                                                        </td>
                                                        <td *ngIf="cc.transaction_allow == '0'">
                                                            <a target="_blank" class="fa fa-exchange mnabricon" routerLink="/accounts/statement_of_accounts/{{cc.account_cost_id}}/" matTooltip="{{'lang_view_transactions' | language}}"></a>
                                                        </td>
                                                        <td *ngIf="cc.transaction_allow == '1'"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="d-flex justify-content-between p-2" *ngIf="ccdata.length>0">
                                                <ngb-pagination [collectionSize]="cccollectionSize" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="ccpage" [pageSize]="ccpageSize" (pageChange)="ccapital()"></ngb-pagination>
                                                <label>{{'lang_total' | language }} : {{cccollectionSize}}</label>
                                                <select class="customselect" [(ngModel)]="ccpageSize" (change)="ccapital()">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="20">20</option>
                                                    <option [ngValue]="50">50</option>
                                                    <option [ngValue]="100">100</option>
                                                    <option [ngValue]="250">250</option>
                                                    <option [ngValue]="500">500</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>