import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  collectionname: string,
  group1name: string,
  typename: string,
  group2name: string,
  platformname: string,
  writer: string,
}
@Component({
  selector: 'app-collection-details',
  templateUrl: './collection-details.component.html',
  styleUrls: ['./collection-details.component.scss']
})
export class CollectionDetailsComponent implements OnInit {
  modalRefA: BsModalRef;
  modalRefV: BsModalRef;
  searchKey                         = "";
  //ngmodel for lists starts
  collection_search_field           = null;
  collection_search_group1          = null;
  collection_search_type            = null;
  collection_search_group2          = null;
  collection_search_platform        = null;
  //ngmodel for lists Ends
  collections:any                   = [];
  group1codes:any                   = [];
  types:any                         = [];
  group2codes:any                   = [];
  platforms:any                     = [];
  lang_key                          = localStorage.getItem('lang_key') || 'en';
  //lists 
  tabledatas: list[];
  collectionSize                    = 0;
  page                              = 1;
  pageSize                          = 10;
  data:any                          = [];
  admin                             = JSON.parse(this.ls.getItem('is_admin'));
  tabledata:any                     = [];
  nodata: string                    = this.lang.transform('lang_loading');
  action                            = "";
  editdata:any                      = [];
  viewdata:any                      = [];
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public spinner:PreloaderService, public alert:AlertService, public modalService: BsModalService, public dialog:MatDialog, public fb:FormBuilder) { }
  ngOnInit(): void {
    this.get_collection_group1_types();
    this.list_of_collection_details();
  }
  //add, edit model
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  //view model
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  //get collections. group1 and types
  public get_collection_group1_types() {
    this.collections = this.group1codes = this.types = [];
    let param = new FormData();
    param.append('lang_key',      this.lang_key);
    let url = ['lpcollection','lpgone','lpgtype'];
    this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(res=> {
      this.spinner.hide();
      if(res[0].status) { //collection records
        this.collections = res[0].records;
      }
      if(res[1].status) { // group 1 records
        this.group1codes = res[1].records;
      }
      if(res[2].status) { // group types records
        this.types = res[2].records;
      }
    },error => {
      this.spinner.hide();
    });
  }
  //list start
  public list_of_collection_details(load?) {
    if(load) {
      this.modalRefA.hide();
    }
    let param = new FormData();
    param.append('search_key',  this.searchKey || '');
    param.append('collection_id',  this.collection_search_field || '');
    param.append('platform_group1_code_system',  this.collection_search_group1 || '');
    param.append('platform_group_type_code_system',  this.collection_search_type || '');
    param.append('platform_group2_code_system',  this.collection_search_group2 || '');
    param.append('platform_access_code',  this.collection_search_platform || '');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpcollectiondet').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.data = res;
        this.tabledatas = res.records;
        this.collectionSize = res.records.length;
        this.build_table_data();
      } else {
        this.data = [];
        this.nodata = res.error;
      }
    }, error=> {
      this.spinner.hide();
      this.data = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  //list End
  public group2_search() {
    this.group2codes = [];
    let param = new FormData();
      param.append('lang_key',                        this.lang_key);
      param.append('platform_group1_code_system',     this.collection_search_group1 || '');
      param.append('platform_group_type_code_system', this.collection_search_type || '');
      if(this.collection_search_group1 && this.collection_search_type) {
        this.ds.getActionByUrl(this.ds.formData2string(param),'lpgtwo').subscribe(res=> {
          if(res.status) {
            this.group2codes = res.records;
          }
        });
      }
  }
  public platform_search() {
    this.platforms = [];
    let param = new FormData();
      param.append('lang_key',                        this.lang_key);
      param.append('platform_group1_code_system',     this.collection_search_group1 || '');
      param.append('platform_group_type_code_system', this.collection_search_type || '');
      param.append('platform_group2_code_system',     this.collection_search_group2 || '');
      if(this.collection_search_group1 && this.collection_search_type && this.collection_search_group2) {
        this.ds.getActionByUrl(this.ds.formData2string(param),'lpplatform').subscribe(res=>{
          if(res.status) {
            this.platforms = res.records;
          }
        });
      }
  }
  //view data
  public load_view_data(row, template) {
    this.viewdata = [];
    let param = new FormData();
      param.append('lang_key', this.lang_key);
      this.spinner.show();
      this.ds.getActionByUrl(this.ds.formData2string(param), 'gpcollectiondet/'+row.collection_details_id).subscribe(res=>{
        this.spinner.hide();
        if(res.status) {
          this.openModalV(template);
          this.viewdata = res;
        } else {
          this.ds.dialogf('', res.error);
        }
      }, error => {
        this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
       this.spinner.hide();
      });
  }
  //delete
  public checkallItemsCollDet(event) {
    if (event.target.checked) {
      $(".appcollectiondetails .eachItemCollDet").prop("checked", true);
      $(".appcollectiondetails .makeback").addClass("bground");
      $(".appcollectiondetails .deleteIconDiv").show();
    } else {
      $(".appcollectiondetails .eachItemCollDet").prop("checked", false);
      $(".appcollectiondetails .makeback").removeClass("bground");
      $(".appcollectiondetails .deleteIconDiv").hide();
    }
  }
  public checkeachItemCollDet(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appcollectiondetails .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appcollectiondetails .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appcollectiondetails .eachItemCollDet:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appcollectiondetails .eachItemCollDet").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appcollectiondetails .deleteIconDiv").show();
    } else {
      $(".appcollectiondetails .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appcollectiondetails #allItemsCollDet").prop("checked", true);
    } else {
      $(".appcollectiondetails #allItemsCollDet").prop("checked", false);
    }
  }
  public deleteBulkDataCollDet() {
    const checkedtotal = [];
    $(".appcollectiondetails .eachItemCollDet:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'dpcollectiondet').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.list_of_collection_details();
                $(".appcollectiondetails #allItemsCollDet").prop("checked", false);
                $(".appcollectiondetails .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}