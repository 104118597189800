<form [formGroup]="form" class="form" (ngSubmit)="submit()" *ngIf="subject">
	<section class="panel-expansion ckeditorhidetoolbar">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/tasks/alltasks"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_goto_list" | language }}
				</button>
			</div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/transactions/form/FORM_PQ1/vr/{{ form_request_id }}"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_view" | language }}
				</button>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<input
					type="submit"
					value="{{ 'lang_save' | language }}"
					class="albutton mnabrbutton mat-accent"
					mat-button
				/>
			</div>
		</div>
		<mat-accordion class="stylepanels">
			<mat-expansion-panel class="mpanel" [expanded]="true">
				<mat-expansion-panel-header class="mpanel-header">{{
					"lang_quotation" | language
				}}</mat-expansion-panel-header>
				<div class="mbrl15" [ngClass]="{ ' contentrtl': lang_key == 'ar' }">
					<div class="row mb-10">
						<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
							<div class="form-group">
								<label> {{ "lang_subject" | language }} </label>
								<input
									type="text"
									class="form-control almnabrformcontrol"
									value="{{ subject }}"
									disabled
								/>
							</div>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
							<div class="form-group">
								<label> {{ "lang_vat" | language }} </label>
								<input
									type="text"
									class="form-control almnabrformcontrol"
									value="{{ vat_name }}"
									disabled
								/>
							</div>
						</div>
					</div>
					<div class="itemsdata">
						<label> {{ "lang_items" | language }} </label>
						<div class="TablePart">
							<div
								class="table-responsive customResponsive mb-10 tablefields quotationitemtable width250"
							>
								<table border="1" class="table CustomTable text-center">
									<thead>
										<tr>
											<td>#</td>
											<td>
												<span matTooltip="{{ 'lang_position' | language }}">{{
													"lang_position" | language
												}}</span>
											</td>
											<td>
												<span matTooltip="{{ 'lang_presence' | language }}">{{
													"lang_presence" | language
												}}</span>
											</td>
											<td>
												<span
													matTooltip="{{
														'lang_projects_nationality' | language
													}}"
													>{{ "lang_projects_nationality" | language }}</span
												>
											</td>
											<td>
												<span
													matTooltip="{{ 'lang_team_number' | language }}"
													>{{ "lang_team_number" | language }}</span
												>
											</td>
											<td>
												<span matTooltip="{{ 'lang_experience' | language }}">{{
													"lang_experience" | language
												}}</span>
											</td>
											<td>
												<span
													matTooltip="{{ 'lang_position_salary' | language }}"
													>{{ "lang_position_salary" | language }}</span
												>
											</td>
											<td>
												<span
													matTooltip="{{ 'lang_salary_allowance' | language }}"
													>{{ "lang_salary_allowance" | language }}</span
												>
											</td>
											<td>
												<span
													matTooltip="{{
														'lang_administrative_costs' | language
													}}"
													>{{ "lang_administrative_costs" | language }}</span
												>
											</td>
											<td>
												<span
													matTooltip="{{ 'lang_proit_ratio' | language }}"
													>{{ "lang_proit_ratio" | language }}</span
												>
											</td>
											<td>
												<span
													matTooltip="{{
														'lang_total_salary_per_person_month' | language
													}}"
													>{{
														"lang_total_salary_per_person_month" | language
													}}</span
												>
											</td>
											<td>
												<span
													matTooltip="{{
														'lang_duration_number_months' | language
													}}"
													>{{ "lang_duration_number_months" | language }}</span
												>
											</td>
											<td>
												<span
													matTooltip="{{
														'lang_total_no_of_visits' | language
													}}"
													>{{ "lang_total_no_of_visits" | language }}</span
												>
											</td>
											<td>
												<span matTooltip="{{ 'lang_toal_bid' | language }}">{{
													"lang_toal_bid" | language
												}}</span>
											</td>
										</tr>
									</thead>
									<tbody
										formArrayName="items"
										class="AppendList quotationtable"
									>
										<tr
											*ngFor="
												let item of items_group().controls;
												let itemIndex = index;
												let odd = odd;
												let even = even
											"
											[formGroupName]="itemIndex"
											style="background: #ffffff"
										>
											<td class="verticalalignmid readonlybyaction">
												<span class="readonlyinput">
													{{ itemIndex + 1 }}
												</span>
											</td>
											<td class="readonlybyaction" width="10%">
												<ng-select
													[items]="data"
													[multiple]="false"
													clearAllText="Clear"
													appendTo="body"
													formControlName="position"
													placeholder="{{ 'lang_position' | language }}"
													class="diabledngselect"
												>
												</ng-select>
											</td>
											<td class="readonlybyaction" width="10%">
												<ng-select
													[items]="data2"
													[multiple]="false"
													clearAllText="Clear"
													appendTo="body"
													formControlName="presence"
													placeholder="{{ 'lang_presence' | language }}"
													class="diabledngselect"
												>
												</ng-select>
											</td>
											<td class="readonlybyaction" width="10%">
												<ng-select
													[items]="nationality"
													[multiple]="false"
													clearAllText="Clear"
													appendTo="body"
													formControlName="nationality"
													placeholder="{{
														'lang_human_resources_nationality' | language
													}}"
													class="diabledngselect"
												>
												</ng-select>
											</td>
											<td class="readonlybyaction">
												<input
													type="text"
													placeholder="{{ 'lang_team_number' | language }}"
													formControlName="team_number"
													class="form-control almnabrformcontrol readonlyinput"
												/>
											</td>
											<td class="readonlybyaction">
												<input
													type="text"
													placeholder="{{ 'lang_experience' | language }}"
													formControlName="experience"
													class="form-control almnabrformcontrol readonlyinput"
												/>
											</td>
											<td>
												<input
													type="text"
													placeholder="{{ 'lang_position_salary' | language }}"
													formControlName="positions_salary"
													class="form-control almnabrformcontrol"
													(keyup)="calculate_quotation(itemIndex)"
												/>
											</td>
											<td>
												<input
													type="text"
													placeholder="{{ 'lang_salary_allowance' | language }}"
													formControlName="salary_allowances"
													class="form-control almnabrformcontrol"
													(keyup)="calculate_quotation(itemIndex)"
												/>
											</td>
											<td>
												<input
													type="text"
													placeholder="{{
														'lang_administrative_costs' | language
													}}"
													formControlName="administrative_costs"
													class="form-control almnabrformcontrol"
													(keyup)="calculate_quotation(itemIndex)"
												/>
											</td>
											<td class="readonlybyaction">
												<input
													type="text"
													placeholder="{{ 'lang_proit_ratio' | language }}"
													formControlName="profit_ratio"
													class="form-control almnabrformcontrol readonlyinput"
												/>
											</td>
											<td class="readonlybyaction">
												<input
													type="text"
													placeholder="{{
														'lang_total_salary_per_person_month' | language
													}}"
													formControlName="total_salary_per_person_month"
													readonly
													class="form-control almnabrformcontrol readonlyinput"
												/>
											</td>
											<td class="readonlybyaction">
												<input
													type="text"
													placeholder="{{
														'lang_duration_number_months' | language
													}}"
													formControlName="duration_of_months"
													class="form-control almnabrformcontrol readonlyinput"
												/>
											</td>
											<td class="readonlybyaction">
												<input
													type="text"
													placeholder="{{
														'lang_total_no_of_visits' | language
													}}"
													formControlName="visits_per_month"
													class="form-control almnabrformcontrol readonlyinput"
												/>
											</td>
											<td class="readonlybyaction">
												<input
													type="text"
													placeholder="{{ 'lang_toal_bid' | language }}"
													formControlName="total_bid_of_project"
													readonly
													class="form-control almnabrformcontrol readonlyinput"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="TableParts">
							<div class="table-responsive customResponsive mb-10 tablefields">
								<table border="1" class="table CustomTable text-center">
									<thead>
										<tr>
											<td>#</td>
											<td>
												<span
													matTooltip="{{
														'lang_position_description' | language
													}}"
													>{{ "lang_position_description" | language }}</span
												>
											</td>
										</tr>
									</thead>
									<tbody formArrayName="items" class="AppendList">
										<tr
											*ngFor="
												let item of items_group().controls;
												let itemIndex = index;
												let odd = odd;
												let even = even
											"
											[formGroupName]="itemIndex"
											style="background: #ffffff"
										>
											<td width="1%" class="verticalalignmid readonlybyaction">
												<span class="readonlyinput">
													{{ itemIndex + 1 }}
												</span>
											</td>
											<td class="readonlybyaction">
												<input
													type="text"
													formControlName="positions_task"
													class="form-control almnabrformcontrol readonlyinput"
													placeholder="{{
														'lang_position_description' | language
													}}"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="row">
						<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
							<button
								type="button"
								routerLink="/tasks/alltasks"
								class="albutton mnabrbutton mat-accent"
								mat-button
							>
								{{ "lang_goto_list" | language }}
							</button>
						</div>
						<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
							<button
								type="button"
								routerLink="/transactions/form/FORM_PQ1/vr/{{
									form_request_id
								}}"
								class="albutton mnabrbutton mat-accent"
								mat-button
							>
								{{ "lang_view" | language }}
							</button>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
							<app-alert id="default-alert"></app-alert>
						</div>
						<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
							<input
								type="submit"
								value="{{ 'lang_save' | language }}"
								class="albutton mnabrbutton mat-accent"
								mat-button
							/>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</section>
</form>
