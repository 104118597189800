<breadcrumb></breadcrumb>
<section class="panel-expansion" [@fadeIn]>
    <mat-accordion class="stylepanels">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <i class="mat-tooltip-trigger fa fa-refresh linkicon mnabricon" (click)="run_cron();" matTooltip="{{'lang_run_cron' | language }}"></i>
        <mat-expansion-panel-header class="mpanel-header">{{'lang_cron_transactions' | language}}</mat-expansion-panel-header>
        <div class="material-panel-body communication">
            <div class="mbrl15">
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive customResponsive">
                            <table border="1" class="table CustomTable">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>{{'lang_request_number' | language }}</td>
                                        <td>{{'lang_form_name' | language }}</td>
                                        <td>{{'lang_fail_attempt' | language }}</td>
                                        <td>{{'lang_last_update' | language }}</td>
                                        <td>{{'lang_error' | language }}</td>
                                        <td>{{'lang_action' | language }}</td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="listData.length != '0' " class="AppendList">
                                    <tr *ngFor="let list of listData; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }">
                                        <td>{{i+1}}</td>
                                        <td>{{list.transaction_request_id}}</td>
                                        <td>{{list.transaction_key}}</td>
                                        <td>{{list.transactions_cronjob_try_to_submitting}}</td>
                                        <td>{{list.transactions_cronjob_last_update}}</td>
                                        <td>{{list.transactions_cronjob_err}}</td>
                                        <td>
                                            <i *ngIf="admin" class="red fa fa-trash-o" (click)="deleteCronJob(list.transaction_request_id);" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="listData.length == '0' " class="AppendList">
                                    <tr class="odd">
                                        <td colspan="7" class="nodata" align="center">{{nodata}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-between p-2" *ngIf="listData.length >0 ">
                               <div class="row w-100">
                                <div class="col-lg-3 col-sm-6 col-6 mb-10" style="height: fit-content;margin: auto 0;">
                                    <!-- {{ "lang_total" | language }} : {{ listData?.page?.total_records }} -->
                                </div>
                                <div class="col-lg-6 col-sm-6 mb-10" style="display: flex;justify-content: center;" >
                                    <ngb-pagination [collectionSize]="collectionSize" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                                    [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshLists()"></ngb-pagination>
                               
                                </div>
                                <div class="col-lg-3 col-sm-6" style="display: flex;justify-content: end;margin: auto;">
                                    
                                      <!--   <mat-select  style="max-width: 150px;"  [(ngModel)]="pagesize" (selectionChange)="refreshLists($event.value)">
                                         
                                          
                                            <mat-option class="text-center" [ngValue]="10" value="10">10 {{'lang_per_page'|language}} </mat-option>
                                            <mat-option class="text-center" [ngValue]="20" value="20">20 {{'lang_per_page'|language}}  </mat-option>
                                            <mat-option class="text-center" [ngValue]="50" value="50">50 {{'lang_per_page'|language}}  </mat-option>
                                            <mat-option class="text-center" [ngValue]="100" value="100">100 {{'lang_per_page'|language}}</mat-option>
                                            <mat-option class="text-center" [ngValue]="500" value="500">500 {{'lang_per_page'|language}}</mat-option>
                                          </mat-select> -->
                                    </div>
                               </div>
                             
                               <!--  <select class="customselect" [(ngModel)]="pageSize" (change)="refreshLists()">
                                    <option [ngValue]="10" >10</option>
                                    <option [ngValue]="20" >20</option>
                                    <option [ngValue]="50" >50</option>
                                    <option [ngValue]="100">100</option>
                                    <option [ngValue]="250">250</option>
                                    <option [ngValue]="500">500</option>
                                </select> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</section>