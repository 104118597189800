import { Component, OnInit, TemplateRef } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
	selector: "app-b-payments",
	templateUrl: "./b-payments.component.html",
	styleUrls: ["./b-payments.component.scss"],
})
export class BPaymentsComponent implements OnInit {
	bankList = [];
	modalRef: BsModalRef;
	form: FormGroup;
	isEdit = false;
	item;
	constructor(
		private spinner: PreloaderService,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		public modalService: BsModalService
	) {}

	ngOnInit(): void {
		this.getBankList();
		this.form = new FormGroup({
			bank_name_en: new FormControl(""),
			bank_name_ar: new FormControl(""),
			bank_short_code: new FormControl(""),
		});
	}

	onCreateUpdateBank(template: TemplateRef<any>, type, item?) {
		if (type == "update") {
			this.form.patchValue(item);
			this.item = item;
			this.isEdit = true;
		}
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}

	getBankList() {
		this.spinner.show();
		this.ds.get("pr/bankpay/get_bank_settings").subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.bankList = res?.records;
				} else {
					this.bankList = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.bankList = [];
			}
		);
	}

	deleteBank(bank_id) {
		let formData = new FormData();
		formData.append("bank_id", bank_id || "");
		this.spinner.show();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"pr/bankpay/delete_bank_setting"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(res?.records, 3000);
						this.getBankList();
					} else {
						this.sweetAlert.errorToast(res?.records, 3000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
	}

	onSubmit() {
		let formData = new FormData();
		if (this.isEdit) {
			formData.append("bank_id", this.item?.bank_id || "");
		}
		formData.append("bank_name_ar", this.form.get("bank_name_ar").value || "");
		formData.append("bank_name_en", this.form.get("bank_name_en").value || "");
		formData.append(
			"bank_short_code",
			this.form.get("bank_short_code").value || ""
		);
		this.spinner.show();
		this.ds.post("pr/bankpay/add_edit_bank_setting", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(res?.message, 3000);
					this.getBankList();
					this.modalRef.hide();
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
