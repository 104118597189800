<div class="main">
	<div class="site_handle">
		<div class="mbrl15">
			<div class="row">
				<div class="col-12 mb-10">
					<div class="input-group input-group-sm">
						<input
							type="text"
							class="form-control almnabrformcontrol h34"
							[(ngModel)]="siteSearchKey"
							(keyup)="search_supervision_sites()"
							placeholder="{{ 'lang_search' | language }}"
						/>
						<span class="input-group-btn" *ngIf="makeAction">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_add' | language }}"
								class="btnSearch enablecode h34 btn btn-md btn-gray mnabricon fa fa-plus"
								(click)="emptyform(); openModal(createsitehandle)"
							></button>
						</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check_all' | language }}"
										>
											<input
												type="checkbox"
												class="allItemsSite checkbox"
												id="allItemsSite"
												(click)="checkAllItemsSite($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ "lang_description" | language }}</td>
									<td>{{ "lang_file_level" | language }}</td>
									<td>{{ "lang_writer" | language }}</td>
									<td>{{ "lang_ondate" | language }}</td>
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody *ngIf="siteListData.length != '0'" class="AppendList">
								<tr
									*ngFor="
										let list of siteListData.records;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
									class="makeback removerow{{ list.mobilization_id }}"
								>
									<td>{{ i + 1 }}</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check' | language }}"
										>
											<input
												type="checkbox"
												class="eachItemSite checkbox"
												value="{{ list.mobilization_id }}"
												(click)="checkEachItemSite($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ list.mobilization_description }}</td>
									<td>{{ list.level_keys }}</td>
									<td>{{ list.writer }}</td>
									<td>{{ list.created_datetime }}</td>
									<td>
										<i
											matTooltip="{{ 'lang_file' | language }}"
											(click)="
												ds.preview_pdf_file(
													list.mobilization_file_url,
													list?.mobilization_description
												)
											"
											class="fa fa-paperclip fa-icon s2icon mnabricon"
										></i>
										<i
											*ngIf="makeAction"
											matTooltip="{{ 'lang_edit' | language }}"
											(click)="emptyEditForm(list); openModal(updatesiteform)"
											class="fa fa-pencil-square-o fa-icon s2icon mnabricon"
										></i>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="siteListData.length == '0'" class="AppendList">
								<tr class="odd">
									<td colspan="9" align="center">{{ sitenodata }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="siteListData?.records?.length > '0'" class="card-footer padb0">
			<div class="row responsive_pagination">
				<div
					*ngIf="makeAction"
					class="deleteIconDiv none col-lg-1 col-sm-1 mb-10"
				>
					<i
						class="fa fa-trash-o faicon red deleteIcon"
						(click)="deleteBulkDataSite()"
						aria-hidden="true"
						matTooltip="{{ 'lang_delete' | language }}"
					></i>
				</div>
				<div class="col-lg-3 col-sm-3 mb-10">
					{{ "lang_total" | language }} :
					{{ siteListData?.page?.total_records }}
				</div>
				<div class="col-lg-5 col-sm-5 mb-10">
					<ngb-pagination
						class="m-auto"
						[collectionSize]="siteListData?.page?.total_records"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="sitepageno"
						[pageSize]="sitepagesize"
						(pageChange)="load_supervision_sites_page($event)"
					></ngb-pagination>
				</div>
				<div class="col-lg-3 col-sm-3">
					<mat-select
						[(ngModel)]="sitepagesize"
						(selectionChange)="load_supervision_sites_pagesize($event.value)"
					>
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #createsitehandle>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_site_handle" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div class="modal-body">
				<div class="itemsdata">
					<div class="TableParts">
						<div class="customResponsive mb-10 tablefields">
							<table border="1" class="table CustomTable text-center">
								<thead>
									<tr>
										<td>#</td>
										<td>
											<i
												class="fa fa-plus-square fa-icon"
												matTooltip="{{ 'lang_add' | language }}"
												(click)="add_new_items()"
											></i>
										</td>
										<td>
											{{ "lang_description" | language }}<i class="red">*</i>
										</td>
										<td>
											{{ "lang_attachments" | language }}<i class="red">*</i>
										</td>
										<td>
											{{ "lang_file_secutrity_levels" | language
											}}<i class="red">*</i>
										</td>
									</tr>
								</thead>
								<tbody formArrayName="site_attachments" class="AppendList">
									<tr
										*ngFor="
											let item of items_group().controls;
											let itemIndex = index;
											let odd = odd;
											let even = even
										"
										[formGroupName]="itemIndex"
										style="background: #ffffff"
									>
										<td class="verticalalignmid">{{ itemIndex + 1 }}</td>
										<td class="verticalalignmid">
											<i
												(click)="removeItems(itemIndex)"
												class="fa fa-minus-square fa-icon mnabricon red"
												matTooltip="{{ 'lang_delete' | language }}"
											></i>
										</td>
										<td>
											<input
												type="text"
												class="form-control almnabrformcontrol"
												placeholder="{{ 'lang_description' | language }}"
												formControlName="sitehandle_description"
												autocomplete="off"
											/>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get(
														'sitehandle_description'
													).errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
										<td
											style="
												padding: 5px;
												word-break: break-all;
												position: relative;
											"
										>
											<input
												style="display: block"
												name="sitehandle_attachments"
												type="file"
												required
												onclick="this.value = null"
												(change)="UploadFile($event, itemIndex)"
												class="nghide albutton width100"
											/>
											<label id="uploadText{{ itemIndex }}" class="albutton"
												>{{ filename }}
											</label>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get(
														'sitehandle_attachments'
													).errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
										<td
											style="
												padding: 5px;
												word-break: break-all;
												position: relative;
											"
										>
											<div class="form-group">
												<ng-select
													[items]="filelevels"
													[multiple]="true"
													bindLabel="label"
													appendTo="body"
													bindValue="value"
													clearAllText="Clear"
													placeholder="{{
														'lang_file_secutrity_levels' | language
													}}"
													formControlName="level_keys"
												>
												</ng-select>
												<mat-error
													*ngIf="
														submitted &&
														itemsgroup.controls[itemIndex].get('level_keys')
															.errors?.required
													"
													>{{ "lang_field_required" | language }}</mat-error
												>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #updatesiteform>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">
				{{ "lang_update_site_testing_file" | language }}
			</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="updateform" (ngSubmit)="update()">
			<div class="modal-body">
				<div class="form-group">
					<label for="level_keys"
						>{{ "lang_file_secutrity_levels" | language }} :
						<span class="red">*</span></label
					>
					<ng-select
						[items]="filelevels"
						[multiple]="true"
						bindLabel="label"
						appendTo="body"
						bindValue="value"
						clearAllText="Clear"
						placeholder="{{ 'lang_file_secutrity_levels' | language }}"
						formControlName="level_keys"
					>
					</ng-select>
					<mat-error
						*ngIf="
							updateform.controls['level_keys'].touched &&
							updateform.controls['level_keys'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="sitehandle_description"
						>{{ "lang_description" | language }}:<span class="red"
							>*</span
						></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="sitehandle_description"
					/>
					<mat-error
						*ngIf="
							updateform.controls['sitehandle_description'].touched &&
							updateform.controls['sitehandle_description'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label
						>{{ "lang_attachments" | language }}:
						<span *ngIf="isFile" for="sitehandle_attachments">
							<mat-icon
								(click)="removeFile()"
								class="mnabricon red"
								matTooltip="{{ 'lang_delete' | language }}"
								>close</mat-icon
							>
						</span>
					</label>
					<div class="IDDIV">
						<input
							style="display: block"
							name="sitehandle_attachments"
							type="file"
							required
							onclick="this.value = null"
							(change)="UpdateFile($event)"
							class="nghide albutton width100"
						/>
						<label id="uploadText" class="albutton">{{ filename }} </label>
					</div>
					<mat-error
						*ngIf="
							updateform.controls['sitehandle_attachments'].touched &&
							updateform.controls['sitehandle_attachments'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<div class="modal" id="pdfPreviewSite">
	<div class="modal-dialog width70">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_preview" | language }}</h4>
				<button type="button" class="close" data-dismiss="modal">
					&times;
				</button>
			</div>
			<div class="modal-body">
				<div class="pdf-container">
					<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</div>
