import { Component, OnInit } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";

@Component({
	selector: "app-add-group",
	templateUrl: "./add-group.component.html",
	styleUrls: ["./add-group.component.scss"],
})
export class AddGroupComponent implements OnInit, OnDestroy {
	lang_key = localStorage.getItem("lang_key") || "en";
	form: FormGroup | any;
	private subscriptions = new Subscription();
	users: any = [];

	group_id = 0;
	constructor(
		private fb: FormBuilder,
		private spinner: PreloaderService,
		public alert: AlertService,
		public router: Router,
		private route: ActivatedRoute,
		private snackBar: MatSnackBar,
		public ds: DataService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			group_title_english: [null, Validators.required],
			group_title_arabic: [null, Validators.required],
			un_restricted_group: [false, Validators.required],
			group_users: [null, Validators.required],
		});
		this.subscriptions.add(
			this.route.params.subscribe((params: Params) => {
				this.group_id = params.group_id;
				if (this.group_id) {
					this.get_by_Id();
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	public get_by_Id() {
		this.subscriptions.add(
			this.ds
				.get(
					"at/get_groups/" +
						1 +
						"/" +
						10 +
						`?search_key=&group_id=${this.group_id}`
				)
				.subscribe(
					(res) => {
						if (res.status) {
							this.form.patchValue({
								group_title_english: res.records[0].group_title_english,
								group_title_arabic: res.records[0].group_title_arabic,
								un_restricted_group:
									res.records[0].un_restricted_group == 1 ? true : false,
								group_users: res.users.map((u) => {
									return u.value;
								}),
							});
							this.users = res.users;
						} else {
							this.alert.error(res.msg);
						}
					},
					(error) => {
						this.alert.error(error);
					}
				)
		);
	}

	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 4000,
			panelClass: [className],
		});
	}

	public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append("search", key.term);
		param.append("lang_key", this.lang_key);
		param.append("user_type_id", "1");
		this.subscriptions.add(
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.users = res.list;
					}
				})
		);
	}

	trackByFnCustom(index: number, item: any) {
		return item.trackingId;
	}
	generateUniqueId() {
		return (
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15)
		);
	}

	submitForm() {
		if (this.form?.status == "VALID") {
			this.spinner.show();
			let formData = new FormData();

			formData.append(
				"group_title_english",
				this.form.value.group_title_english
			);
			formData.append("group_title_arabic", this.form.value.group_title_arabic);
			formData.append(
				"un_restricted_group",
				this.form.value.un_restricted_group ? "1" : "0"
			);
			for (let index = 0; index < this.form.value.group_users.length; index++) {
				const element = this.form.value.group_users[index];
				formData.append("group_users[]", element);
			}

			if (this.group_id) {
				this.subscriptions.add(
					this.ds.post(`at/update_groups/${this.group_id}`, formData).subscribe(
						(data) => {
							if (data.status) {
								this.alert.success(data.msg);
								this.form.reset();
								this.router.navigate(["/human_resources/attendances"]);

								this.spinner.hide();
							} else {
								this.spinner.hide();

								this.alert.error(data.error);
							}
						},
						(error) => {
							this.spinner.hide();
							this.alert.error(error?.error?.error);
						}
					)
				);
			} else {
				this.subscriptions.add(
					this.ds.post(`at/create_groups`, formData).subscribe(
						(data) => {
							if (data.status) {
								this.alert.success(data.msg);
								this.form.reset();
								this.router.navigate(["/human_resources/attendances"]);
								this.spinner.hide();
							} else {
								this.spinner.hide();
								this.alert.error(data.error);
							}
						},
						(error) => {
							this.spinner.hide();
							this.alert.error(error?.error?.error);
						}
					)
				);
			}
		} else {
			this.showMsg("Please fill all fields first", "ok", "error-snackbar");
		}
	}
}
