<div mat-dialog-title class="d-flex justify-content-between">
	<h4>{{ "lang_group_details" | language }}</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<div class="p-2">
		<div class="row">
			<div class="col-12">
				<h4>{{ "lang_name" | language }}</h4>
				<p>{{ groupData?.group_name }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-sm-10 mb-2">
				<ng-select
					[items]="users_list"
					[multiple]="true"
					appendTo="body"
					bindLabel="label"
					bindValue="value"
					[searchFn]="ds.customSearchFn"
					clearAllText="Clear"
					placeholder="{{ 'lang_users' | language }}"
					[(ngModel)]="user"
					(search)="search_users($event)"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-2 mb-2">
				<button
					mat-button
					class="albutton mnabrbutton mat-accent"
					(click)="addUserToGroup()"
				>
					{{ "lang_add" | language }}
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="table-responsive dropdownyes customResponsive">
					<table border="1" class="table CustomTable">
						<thead>
							<tr>
								<td>#</td>
								<td>{{ "lang_human_resources_fname_english" | language }}</td>
								<td>{{ "lang_human_resources_lname_english" | language }}</td>
								<td>{{ "lang_username" | language }}</td>
								<td>{{ "lang_action" | language }}</td>
							</tr>
						</thead>
						<tbody class="AppendList">
							<tr
								*ngFor="
									let list of group_users;
									let i = index;
									let odd = odd;
									let even = even
								"
								[ngClass]="{ odd: odd, even: even }"
							>
								<td>{{ i + 1 }}</td>
								<td>{{ list?.firstname_english || "---" }}</td>
								<td>{{ list?.lastname_english || "---" }}</td>
								<td>{{ list?.username || "---" }}</td>
								<td>
									<div class="d-flex align-items-center justify-content-center">
										<i
											class="fa fa-trash red mr-1 ml-1"
											(click)="deleteUser(list)"
											matTooltip="{{ 'lang_delete' | language }}"
										></i>
									</div>
								</td>
							</tr>
							<tr *ngIf="!group_users?.length">
								<td colspan="5">
									{{ "lang_no_data" | language }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
