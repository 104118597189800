import { Component, OnInit } from "@angular/core";
import {
  trigger,
  transition,
  query,
  style,
  stagger,
  animate,
  state,
} from "@angular/animations";
import { PreloaderService } from "@core";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { DataService } from "@core/bootstrap/data.service";
import { MatDialog } from "@angular/material/dialog";
import { AddDeviceComponent } from "../add-device/add-device.component";
import { HttpParams } from "@angular/common/http";
declare var $: any;

@Component({
  selector: "app-devices",
  templateUrl: "./devices.component.html",
  styleUrls: ["./devices.component.scss"],
  animations: [
    trigger("fadeIn", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      transition("void <=> *", animate(1000)),
    ]),
  ],
})
export class DevicesComponent implements OnInit {
  listData: any = [];
  loading: boolean = true;
  nodata: string = this.lang.transform("lang_loading");
  search_key: any = "";
  page_no: any = 1;
  pagesize: any = "10";
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public dialog: MatDialog,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    private sweetAlert: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.module_lists();
  }

  public module_lists(page = 1) {
    this.listData = [];
    this.page_no = page;
    this.pagesize = this.pagesize;
    this.get_form_lists();
  }

  public loadPageFrm(page) {
    this.page_no = page;
    this.pagesize = this.pagesize;
    this.get_form_lists();
  }
  public loadFrmPagesize(size) {
    this.page_no = 1;
    this.pagesize = size;
    this.get_form_lists();
  }

  openModal(data?) {
    const dialogRef = this.dialog.open(AddDeviceComponent, {
      disableClose: false,
      width: "600px",
      data: data,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.module_lists();
      }
    });
  }
  public get_form_lists() {
    this.listData = [];
    this.loading = true;

    let param = new FormData();
    param.append("search_key", this.search_key);

    this.ds
      .getActionByUrl(
        this.ds.formData2string(param),
        "human_resources/list_devices/" + this.page_no + "/" + this.pagesize
      )
      .subscribe(
        (res) => {
          this.loading = false;
          if (res.status) {
            this.listData = res;
          } else {
            this.listData = [];
            this.nodata = res.error;
          }
        },
        (error) => {
          this.loading = false;
          this.listData = [];
          this.nodata =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
  }

  public deleteSingleData(id) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("device_id", id);
    this.sweetAlert
      .confirmMessage("Are you sure?", "Confirm")
      .then((result) => {
        this.spinner.show();
        if(result.isConfirmed){
          this.ds
          .deleteActionByBody2(httpParams,`human_resources/delete_device`)
          .subscribe(
            (data) => {
              this.spinner.hide();
              if (data) {
                this.get_form_lists();
                this.ds.dialogf("", data.msg);
              }
            },
            (error) => {
              this.ds.dialogf(
                "",
                error && error.error && error.error.error
                  ? error.error.error
                  : this.lang.transform("lang_internal_server_error")
              );
              this.spinner.hide();
            }
          );
        }else{
          this.spinner.hide()
        }
        
      });
  }
  
}
