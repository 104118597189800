import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pinvoice-list',
  templateUrl: './pinvoice-list.component.html',
  styleUrls: ['./pinvoice-list.component.scss']
})
export class PinvoiceListComponent implements OnInit ,OnDestroy {
  branches: any     = [];
  InvData: any      = [];
  branch_id: any    = (localStorage.getItem('selectedBranch') != null) ? localStorage.getItem('selectedBranch') : '0';
  lodingdatas       = this.lang.transform('lang_loading');
  finances: any     = [];
  Invpage_no:any    = localStorage.getItem('AC_PI_P') || 1;
  Invpage_size:any  = localStorage.getItem('AC_PI_S') || '10';
  finance_id: any   = localStorage.getItem('AC_PI_S_F') || null;
  searchKey:any     = localStorage.getItem('AC_PI_S_K') || "";
  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public router:Router, public dialog : MatDialog, public changeDetectorRef: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != '0') {
      this.load_financial_years();
    }
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
        this.ds.getActionByUrl([], 'ab/accounts_view').subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        }, error => {
          this.spinner.hide();
        })      
      )

  }
  public load_financial_years() {
    this.finances = [];
    this.finance_id = null;
    this.subscriptions.add(
        this.ds.getActionByUrl([], 'financial/' + this.branch_id).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
              }
            });
          }
          this.load_purchase_invoices();
        }, error => {
          this.spinner.hide();
        })      
      )

  }
  public selectBrach(branch) {
    if (branch && branch !='0' ) {
      localStorage.setItem('selectedBranch', branch);
      this.branch_id = branch;
      this.load_financial_years();
    } else {
      this.spinner.hide();
    }
  }
  public loadPageInv(page) {
    this.spinner.show();
    this.Invpage_no = page;
    this.Invpage_size = this.Invpage_size;
    this.load_purchase_invoices();
  }
  public loadInvPagesize(size) {
    this.spinner.show();
    this.Invpage_no = 1;
    this.Invpage_size = size;
    this.load_purchase_invoices();
  }
  public search_invoices() {
    this.Invpage_no = 1;
    this.Invpage_size = '10';
    this.load_purchase_invoices();
  }
  public load_purchase_invoices() {
    localStorage.setItem('AC_PI_S_K', this.searchKey);
		localStorage.setItem('AC_PI_S_F', this.finance_id);
		localStorage.setItem('AC_PI_P', this.Invpage_no);
		localStorage.setItem('AC_PI_S', this.Invpage_size);
    let formdata = new FormData();
    this.branch_id = (this.branch_id) ? this.branch_id : 0;
    formdata.append('search_key', this.searchKey);
    formdata.append('finance_id', this.finance_id);
    this.subscriptions.add(
        this.ds.postActionByUrl(formdata, 'listpinvoice/' + this.branch_id + '/' + this.Invpage_no + '/' + this.Invpage_size).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.InvData = res;
          } else {
            this.InvData = [];
            this.lodingdatas = res.error;
          }
        }, error => {
          this.spinner.hide();
          this.InvData = [];
          this.lodingdatas = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
        })      
      )

  }
  public remove_invoice(data) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show()
        this.subscriptions.add(
                  this.ds.deleteActionByUrl([], 'dpinvoice/' + data.branch_id + '/' + data.transaction_id + '/' + data.invoice_id).subscribe(
              (data) => {
                this.spinner.hide();
                if (data.status) {
                  this.load_purchase_invoices();
                  this.ds.dialogf('',data.msg);
                } else {
                  this.ds.dialogf('',data.error);
                }
              },
              (error) => {
                this.spinner.hide();
                this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              }
            )
          )
;
      }
    });    
  }
}
