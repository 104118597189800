import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleMenuService {
  isMob: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private sidenav: MatSidenav;
  constructor() { }

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public open() {
    return this.sidenav.open();
  }


  public close() {
    document.getElementById("nav-icon2").classList.remove("open");
		this.isMob.next(false);
    return this.sidenav.close();
  }

  public toggleSidenav(): void {
    this.sidenav.toggle();
  }
}
