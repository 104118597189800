<form [formGroup]="form" class="form" (ngSubmit)="completeStep4()">
	<div class="card">
		<div class="card-header">Confirm Receive SI</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<label>Confirm Receive SI !!!</label>
						<section class="example-section">
							<mat-checkbox
								formControlName="confirm_receive_SI"
								class="example-margin"
							></mat-checkbox>
						</section>
					</div>
				</div>
			</div>
		</div>
		<div class="pl-2 pr-2">
			<div class="row">
				<div class="col-sm-12">
					<div class="form-group" *ngIf="sendCodeStatus">
						<label for="verification_code"
							>{{ "lang_enter_verification_code" | language }}:
						</label>
						<input
							type="text"
							required
							class="form-control almnabrformcontrol"
							formControlName="transactions_persons_action_code"
						/>
						<button
							type="button"
							(click)="confirmVerification()"
							*ngIf="sendCodeStatus"
							class="albutton mnabrbutton mat-accent"
							mat-button
						>
							{{ "lang_do_verification" | language }}
						</button>
					</div>
					<div class="col-sm-12" *ngIf="sendCodeStatus">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<div>
						<button
							class="albutton mnabrbutton mat-accent"
							type="button"
							(click)="openModal()"
							mat-button
						>
							{{ "lang_send_code" | language }}
						</button>
					</div>
				</div>
				<div class="col-sm-4 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<input
						type="submit"
						value="{{ 'lang_submit' | language }}"
						class="albutton mnabrbutton mat-accent"
						mat-button
					/>
				</div>
			</div>
		</div>
	</div>
</form>
