<div class="theme">
  <div class="row">
    <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
      <button
        *ngIf="actionPage == 'A'"
        type="button"
        class="albutton mnabrbutton mat-accent"
        mat-button
        routerLink="/transactions/nBU2waRF8N"
      >
        {{ "lang_goto_list" | language }}
      </button>
      <button
        *ngIf="actionPage != 'A'"
        type="button"
        class="albutton mnabrbutton mat-accent"
        mat-button
        routerLink="/tasks/alltasks"
      >
        {{ "lang_goto_list" | language }}
      </button>
    </div>
    <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
      <div class="dropdown" *ngIf="actionbuttons">
        <!-- 	<button
                        type="button"
                        mat-button
                        [matMenuTriggerFor]="menu"
                        class="col-12 mnabrbutton mat-accent"
                    >
                        {{ "lang_action" | language }}
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            *ngIf="actionbuttons.edit"
                            (click)="are_you_sure(form_key, form_request_id)"
                            matTooltip="{{ 'lang_edit' | language }}"
                        >
                            {{ "lang_edit" | language }}
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="actionbuttons.signature"
                            (click)="showverificationform('signature')"
                            data-toggle="modal"
                            data-target="#verifySMRDA"
                            data-backdrop="static"
                            data-keyboard="false"
                            matTooltip="{{ 'lang_signature' | language }}"
                        >
                            {{ "lang_signature" | language }}
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="actionbuttons.marks"
                            (click)="showverificationform('marks')"
                            data-method="Markers"
                            data-toggle="modal"
                            data-target="#verifySMRDA"
                            data-backdrop="static"
                            data-keyboard="false"
                            matTooltip="{{ 'lang_mark' | language }}"
                        >
                            {{ "lang_mark" | language }}
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="actionbuttons.opinion"
                            (click)="showverificationform('opinion')"
                            data-method="opinion"
                            data-toggle="modal"
                            data-target="#verifySMRDA"
                            data-backdrop="static"
                            data-keyboard="false"
                            matTooltip="{{ 'lang_opinion' | language }}"
                        >
                            {{ "lang_opinion" | language }}
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="actionbuttons.reviewers"
                            (click)="showverificationform('reviewers')"
                            data-method="Reviewers"
                            data-toggle="modal"
                            data-target="#verifySMRDA"
                            data-backdrop="static"
                            data-keyboard="false"
                            matTooltip="{{ 'lang_review' | language }}"
                        >
                            {{ "lang_review" | language }}
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="actionbuttons.doall"
                            (click)="showverificationform('doall')"
                            data-method="doall"
                            data-toggle="modal"
                            data-target="#verifySMRDA"
                            data-backdrop="static"
                            data-keyboard="false"
                            matTooltip="{{ 'lang_do_all' | language }}"
                        >
                            {{ "lang_do_all" | language }}
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="actionbuttons.preview"
                            (click)="preview()"
                            matTooltip="{{ 'lang_preview' | language }}"
                        >
                            {{ "lang_preview" | language }}
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="actionbuttons.view"
                            (click)="ds.preview_pdf_file(request.view_link)"
                            matTooltip="{{ 'lang_view' | language }}"
                        >
                            {{ "lang_view" | language }}
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="actionbuttons.approval"
                            (click)="empty_approve_form()"
                            data-method="approval"
                            data-toggle="modal"
                            data-target="#verifyPassword"
                            data-backdrop="static"
                            data-keyboard="false"
                            matTooltip="{{ 'lang_approve' | language }}"
                        >
                            {{ "lang_approve" | language }}
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="actionbuttons.delete && admin"
                            (click)="delete_form_request(form_key, form_request_id)"
                            matTooltip="{{ 'lang_delete' | language }}"
                        >
                            {{ "lang_delete" | language }}
                        </button>
                    </mat-menu> -->
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
    <div class="col-md-2 col-sm-2 col-xs-12 mb-10"></div>
  </div>
  <mat-accordion>
    <mat-expansion-panel class="mb-2" [expanded]="true">
      <mat-expansion-panel-header>{{
        "lang_settlement" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <app-ste-steps
          [alldata]="allData"
          [maindata]="allData"
          [request]="request"
          (updateForm)="get_data_view()"
          [form_request_id]="form_request_id"
          [form_key]="form_key"
        ></app-ste-steps>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="mb-2">
      <mat-expansion-panel-header>{{
        "lang_person_details" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body communication">
        <div class="mbrl15">
          <div class="col-md-12 pad0">
            <div class="table-responsive customResponsive">
              <table
                border="1"
                class="table CustomTable"
                *ngIf="persons.length > 0"
              >
                <thead>
                  <tr>
                    <td>#</td>
                    <td>{{ "lang_name" | language }}</td>
                    <td>{{ "lang_type" | language }}</td>
                    <td>{{ "lang_view" | language }}</td>
                    <td>{{ "lang_view_time" | language }}</td>
                    <td>{{ "lang_last_view_time" | language }}</td>
                    <td>{{ "lang_action" | language }}</td>
                    <td>{{ "lang_step" | language }}</td>
                    <td>{{ "lang_datetime" | language }}</td>
                  </tr>
                </thead>
                <tbody class="AppendList">
                  <tr
                    *ngFor="
                      let person of persons;
                      let i = index;
                      let odd = odd;
                      let even = even
                    "
                    [ngClass]="{ odd: odd, even: even }"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>{{ person.person_name }}</td>
                    <td>{{ person.transaction_persons_type }}</td>
                    <td *ngIf="person.transactions_persons_view == '1'">
                      <i
                        class="fa fa-check faicon successgreen"
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td *ngIf="person.transactions_persons_view == '0'">
                      <i
                        class="fa fa-check faicon silver"
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>{{ person.transactions_persons_view_datetime }}</td>
                    <td>
                      {{ person.transactions_persons_view_datetime_lastupdate }}
                    </td>
                    <td *ngIf="person.transactions_persons_action_status == 1">
                      <i
                        class="fa fa-check faicon successgreen"
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td *ngIf="person.transactions_persons_action_status == 0">
                      <i
                        class="fa fa-check faicon silver"
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>{{ person.transactions_persons_last_step }}</td>
                    <td>
                      {{
                        person.transactions_persons_view_datetime
                          | date: "short"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <no-data
                style="margin: auto"
                [Opened]="persons.length < 1"
              ></no-data>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="mb-2">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_history" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body communication">
        <div class="mbrl15">
          <div class="col-md-12 pad0">
            <div class="table-responsive customResponsive">
              <table
                border="1"
                class="table CustomTable"
                *ngIf="history.length > 0"
              >
                <thead>
                  <tr>
                    <td>#</td>
                    <td>{{ "lang_name" | language }}</td>
                    <td>{{ "lang_notes" | language }}</td>
                    <td>{{ "lang_result" | language }}</td>
                    <td>{{ "lang_ondate" | language }}</td>
                  </tr>
                </thead>
                <tbody class="AppendList">
                  <tr
                    *ngFor="
                      let his of history;
                      let i = index;
                      let odd = odd;
                      let even = even
                    "
                    [ngClass]="{ odd: odd, even: even }"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>{{ his.transactions_records_user_name }}</td>
                    <td>{{ his.transactions_records_note }}</td>
                    <td>{{ his.result }}</td>
                    <td>{{ his.transactions_records_datetime }}</td>
                  </tr>
                </tbody>
              </table>
              <no-data
                style="margin: auto"
                [Opened]="history.length < 1"
              ></no-data>

              <br />
              <div
                class="d-flex justify-content-between"
                *ngIf="history.length > 0"
              >
                <ngb-pagination
                  [collectionSize]="history.length"
                  [maxSize]="3"
                  [rotate]="true"
                  [ellipses]="false"
                  [boundaryLinks]="true"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  (pageChange)="refreshLists()"
                ></ngb-pagination>
                <select
                  class="customselect"
                  [(ngModel)]="pageSize"
                  (change)="refreshLists()"
                >
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="20">20</option>
                  <option [ngValue]="50">50</option>
                  <option [ngValue]="100">100</option>
                  <option [ngValue]="250">250</option>
                  <option [ngValue]="500">500</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="All_Attachments.length > 0" class="mb-2">
      <mat-expansion-panel-header>{{
        "lang_attachments" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body communication">
        <div class="mbrl15">
          <div class="col-md-12 pad0">
            <app-transaction-attachments
              [All_Attachments]="All_Attachments"
            ></app-transaction-attachments>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
