import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import * as moment from "moment";
@Component({
	selector: "app-meeting-check-add-update",
	templateUrl: "./meeting-check-add-update.component.html",
	styleUrls: ["./meeting-check-add-update.component.scss"],
})
export class MeetingCheckAddUpdateComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	point_data;
	point_form: FormGroup;
	dateMobiscroll;
	autoButtons = [
		{
			text: this.lang.transform("lang_save"),
			handler: "set",
		},
	];
	search_point_users = [];
	editMode = false;
	meetingData;
	parentPoint;

	constructor(
		private fb: FormBuilder,
		private lang: LanguagePipe,
		public ds: DataService,
		private spinner: PreloaderService,
		public dialogRef: MatDialogRef<MeetingCheckAddUpdateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private sweetAlert: SweetAlertService
	) {
		this.meetingData = data?.meeting;
		this.parentPoint = data?.point_id;
		this.buildForm();
		// if (data?.meeting) {
		// 	this.editMode = true;
		// 	this.meetingData = data?.meeting;
		// 	this.setEditData();
		// } else {
		// 	this.editMode = false;
		// }
	}

	ngOnInit(): void {
		moment.locale("en");
	}

	buildForm() {
		this.point_form = this.fb.group({
			point_notes: ["", Validators.required],
			end_date: [null, Validators.required],
			hour_no: [null],
			users: [null],
		});
	}

	setEditData() {
		this.point_form
			.get("meeting_title")
			.setValue(this.meetingData?.meeting_title);
		this.point_form
			.get("meeting_date")
			.setValue(
				moment(new Date(this.meetingData?.meeting_date_en)).format("YYYY/MM/DD")
			);
		this.point_form
			.get("meeting_details")
			.setValue(this.meetingData?.meeting_details);
		this.point_form
			.get("users")
			.setValue(this.meetingData?.users?.map((el) => el?.emp_id));

		this.search_point_users = this.meetingData?.users?.map((el) => {
			return {
				label: el?.firstname_english + " " + el?.lastname_english,
				value: el?.emp_id,
			};
		});
	}

	custmizeDate(type) {
		if (type == "end_date") {
			const date_formatted = moment(this.dateMobiscroll).format("YYYY/MM/DD");
			this.point_form.get("end_date").setValue(date_formatted);
		}
	}

	//search users
	public search_users(key) {
		this.search_point_users = [];
		if (key && key.term) {
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.search_point_users = res.list;
					}
				});
		}
	}

	addUpdatePoint() {
		let formData = new FormData();
		formData.append("meeting_id", this.meetingData?.meeting_id || "");
		formData.append("point_id", this.parentPoint || "");
		formData.append(
			"points[0][point_notes]",
			this.point_form.get("point_notes").value || ""
		);
		formData.append(
			"points[0][end_date]",
			this.point_form.get("end_date").value || ""
		);
		formData.append(
			"points[0][hour_no]",
			this.point_form.get("hour_no").value || ""
		);
		formData.append(
			"points[0][users]",
			this.point_form.get("users").value || ""
		);
		let url = "tasks/add_sub_points_meeting";
		this.spinner.show();
		this.ds.post(url, formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.dialogRef.close(true);
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
