<form [formGroup]="form" class="form" (ngSubmit)="completeStep4()">
	<div class="card">
		<div class="card-header">
			{{ "lang_recipient_verification" | language }}
			<i
				style="padding: 0 10px"
				class="fa fa-info-circle orangestatus-color"
				matTooltip="{{ 'lang_recipient_verification_tooltip' | language }}"
			></i>
		</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row">
					<div class="col-12">
						<label>{{ "lang_reserve_the_transaction" | language }} ?!</label>
					</div>
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<mat-radio-group
							formControlName="teserve_transaction"
							(change)="form.get('notes').setValue(''); checkStatus()"
						>
							<mat-radio-button value="0">{{
								"lang_return_to_update" | language
							}}</mat-radio-button>
							<mat-radio-button value="1">{{
								"lang_reserve_the_transaction" | language
							}}</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="col-sm-6 col-xs-12 mb-10">
						<div
							*ngIf="form.get('teserve_transaction').value == '0'"
						>
							<label
								>{{ "lang_rejected_notes" | language }} :
								<span class="red">*</span></label
							>
							<textarea
								class="form-control almnabrformcontrol"
								formControlName="notes"
							></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pl-2 pr-2">
			<div class="row">
				<div class="col-md-12 col-sm-8 col-xs-12 mb-10">
					<app-alert id="default-alert"></app-alert>
				</div>
				<div class="col-sm-8"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<input
						type="submit"
						value="{{ 'lang_submit' | language }}"
						class="albutton mnabrbutton mat-accent"
						mat-button
					/>
				</div>
			</div>
		</div>
	</div>
</form>
