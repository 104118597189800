import { Component, Inject, OnInit } from "@angular/core";
import {
	FormGroup,
	FormArray,
	FormBuilder,
	Validators,
	FormControl,
} from "@angular/forms";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-initial-delivery-list-add",
	templateUrl: "./initial-delivery-list-add.component.html",
	styleUrls: ["./initial-delivery-list-add.component.scss"],
})
export class InitialDeliveryListAddComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	user_type_id = this.localstorage.getItem("user_type_id");
	form: FormGroup;
	unit_general_List: any = [];
	blocks_List: any = [];
	projects_profile_id: any;
	projects_work_area_id: any;
	projects_supervision_id: any;
	filename: string = this.lang.transform("lang_select_files");
	other_attachments: FormArray;
	constructor(
		private fb: FormBuilder,
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private spinner: PreloaderService,
		private dialogRef: MatDialogRef<InitialDeliveryListAddComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private localstorage: LocalStorage
	) {
		this.projects_profile_id = data.projects_profile_id;
		this.projects_work_area_id = data.projects_work_area_id;
		this.projects_supervision_id = data.projects_supervision_id;
	}

	ngOnInit(): void {
		this.buildForm();
		this.get_blocks_List();
		if (this.user_type_id == "1") {
			this.addfiles();
		}
	}

	buildForm() {
		this.form = this.fb.group({
			work_site_type: ["GN", [Validators.required]],
			unit_general_numbers: ["", [Validators.required]],
			work_site_blocks: [""],
			attachments: this.fb.array([]),
		});
		this.other_attachments = this.form.get("attachments") as FormArray;
	}
	get filesFormGroup() {
		return this.form.get("attachments") as FormArray;
	}
	public files(value?): FormGroup {
		return this.fb.group({
			delete_icon: new FormControl(value?.title_english || ""),
			view: new FormControl(value?.file || null),
			title_english: new FormControl(value?.title_english, [
				Validators.required,
			]),
			title_arabic: new FormControl(value?.title_arabic, [Validators.required]),
			file: new FormControl(value?.file || null, [Validators.required]),
		});
	}
	public addfiles(value?) {
		if (this.other_attachments.length < 10) {
			this.other_attachments.push(this.files(value));
			this.other_attachments.setValidators(Validators.required);
			this.other_attachments.updateValueAndValidity();
		}
	}
	public removefiles(index) {
		if (this.other_attachments.length == 1) {
			this.other_attachments.removeAt(index);
			this.other_attachments.clearValidators();
			this.other_attachments.updateValueAndValidity();
		} else {
			this.other_attachments.removeAt(index);
		}
	}
	onChangeWorksiteType() {
		if (this.form.value.work_site_type == "GN") {
			this.form
				.get("unit_general_numbers")
				.setValidators([Validators.required]);
			this.form.get("unit_general_numbers").updateValueAndValidity();
			this.form.get("work_site_blocks").clearValidators();
			this.form.get("work_site_blocks").updateValueAndValidity();
		} else {
			this.form.get("work_site_blocks").setValidators([Validators.required]);
			this.form.get("work_site_blocks").updateValueAndValidity();
			this.form.get("unit_general_numbers").clearValidators();
			this.form.get("unit_general_numbers").updateValueAndValidity();
		}
	}
	get_unit_general_List(event) {
		this.spinner.show();
		let param = new FormData();
		param.append("projects_profile_id", this.projects_profile_id || "");
		param.append("projects_work_area_id", this.projects_work_area_id || "");
		param.append("projects_supervision_id", this.projects_supervision_id || "");
		param.append("search_key", event?.term || "");
		param.append("lang_key", this.lang_key);
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "p/snag/snagsearchunits")
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.unit_general_List = res?.records;
					} else {
						this.unit_general_List = [];
					}
				},
				(error) => {
					this.spinner.hide();
					this.unit_general_List = [];
					this.sweetAlert.errorToast(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}

	get_blocks_List() {
		this.spinner.show();
		let param = new FormData();
		param.append("projects_profile_id", this.projects_profile_id || "");
		param.append("projects_work_area_id", this.projects_work_area_id || "");
		param.append("projects_supervision_id", this.projects_supervision_id || "");
		param.append("lang_key", this.lang_key);
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "p/snag/snaglistblocks")
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.blocks_List = res?.records;
					} else {
						this.blocks_List = [];
					}
				},
				(error) => {
					this.spinner.hide();
					this.blocks_List = [];
					this.sweetAlert.errorToast(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
	add_initial_delivery() {
		if (this.form.valid) {
			this.spinner.show();
			let formData = new FormData();
			formData.append("projects_profile_id", this.projects_profile_id || "");
			formData.append(
				"projects_work_area_id",
				this.projects_work_area_id || ""
			);
			formData.append(
				"projects_supervision_id",
				this.projects_supervision_id || ""
			);
			formData.append("work_site_type", this.form.value.work_site_type || "");
			if (this.form.value.work_site_type == "GN") {
				formData.append(
					"unit_general_numbers",
					this.form.value.unit_general_numbers.toString() || ""
				);
			} else {
				formData.append(
					"work_site_blocks",
					this.form.value.work_site_blocks.toString() || ""
				);
			}
			for (let i = 0; i < this.other_attachments.value.length; i++) {
				formData.append(
					`attachments[${i}][title_english]`,
					this.other_attachments.controls[i].get("title_english").value
				);
				formData.append(
					`attachments[${i}][title_arabic]`,
					this.other_attachments.controls[i].get("title_arabic").value
				);
				formData.append(
					`attachments[${i}][file]`,
					this.other_attachments.controls[i].get("file").value
				);
			}
			this.ds
				.postActionByUrl(formData, "p/snag/checkinitialdelivery")
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res?.status) {
							this.dialogRef.close(true);
							this.sweetAlert.successToast(res?.msg, 2000);
						} else {
							this.sweetAlert.errorToast(res?.error, 2000);
						}
					},
					(error) => {
						this.spinner.hide();
						this.sweetAlert.errorToast(
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error"),
							2000
						);
					}
				);
		} else {
			this.sweetAlert.errorToast(
				this.lang.transform("lang_fill_required_fields"),
				2000
			);
		}
	}
}
