import { DatePipe } from "@angular/common";
import { Component, Inject, Input, OnInit } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import moment from "moment";

@Component({
	selector: "app-formhrj1-add-modal",
	templateUrl: "./formhrj1-add-modal.component.html",
	styleUrls: ["./formhrj1-add-modal.component.scss"],
})
export class Formhrj1AddModalComponent implements OnInit {
	employee_data;
	@Input() form_request_id;
	form: FormGroup;
	lang_key = localStorage.getItem("lang_key") || "en";
	submitted = false;
	joining_type: any = [];
	users: any = [];
	direct_anager = [];
	constructor(
		public ds: DataService,
		public datepipe: DatePipe,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		private dialogRef: MatDialogRef<Formhrj1AddModalComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private sweetAlert: SweetAlertService
	) {
		this.employee_data = data?.employee_data;
	}
	ngOnInit(): void {
		moment.locale("en");
		this.form = new FormGroup({
			transaction_from: new FormControl(
				{ value: this.employee_data.branch_id, disabled: true },
				[Validators.required]
			),
			employee_number: new FormControl(this.employee_data.employee_number, [
				Validators.required,
			]),
			joining_start_date_english: new FormControl("", [Validators.required]),
			joining_start_date_arabic: new FormControl(null, [Validators.required]),
			joining_description: new FormControl(null, [Validators.required]),
			direct_manager: new FormControl(
				{ value: null, disabled: this.direct_anager.length > 0 },
				[Validators.required]
			),
		});
	}

	//Other Terms & Conditions End
	public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append("search", key.term);
		param.append("lang_key", this.lang_key);
		param.append("user_type_id", "1");
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.users = res.list;
				}
			});
	}

	public submit() {
		this.alert.clear();
		this.submitted = true;
		if (this.form.valid) {
			this.submitted = false;
		}
		this.spinner.show();
		let param = new FormData();
		param.set("request_from", " USER");
		param.set("employee_number", this.form.get("employee_number").value || "");
		param.set(
			"transaction_from",
			this.form.get("transaction_from").value || ""
		);
		param.set("joining_type", "3");
		param.set(
			"joining_start_date_english",
			moment(this.form.get("joining_start_date_english").value).format(
				"YYYY/MM/DD"
			) || ""
		);
		param.set(
			"joining_start_date_arabic",
			this.form.get("joining_start_date_arabic").value || ""
		);
		param.set(
			"joining_description",
			this.form.get("joining_description").value || ""
		);
		param.set("direct_manager", this.form.get("direct_manager").value || "");

		this.ds.postActionByUrl(param, "form/FORM_HRJ1/cr/0").subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.alert.success(this.lang.transform("lang_success"));
					this.form.reset();
					this.dialogRef.close();
					setTimeout(() => {
						this.router.navigate(["transactions/allforms/"]);
					}, 1000);
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(error) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
