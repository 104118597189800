import { Component, Input, OnInit } from "@angular/core";
import {
	FormGroup,
	FormArray,
	FormControl,
	FormBuilder,
	Validators,
} from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
	selector: "app-wafi-custom-divisions",
	templateUrl: "./wafi-custom-divisions.component.html",
	styleUrls: ["./wafi-custom-divisions.component.scss"],
})
export class WafiCustomDivisionsComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	@Input() projects_work_area_id;
	@Input() projects_profile_id;
	@Input() projects_supervision_id;
	modalRef: BsModalRef;
	form: FormGroup;
	customDivisionList = [];
	itemForUpdate;
	isEdit = false;
	group1List = [];
	constructor(
		public modalService: BsModalService,
		private fb: FormBuilder,
		private ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.get_divisions();
		this.buildForm();
		this.getCustomDivisionList();
	}

	getCustomDivisionList() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		this.spinner.show();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"pr/report/get_report_custom_division"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.customDivisionList = res?.record;
					} else {
						this.customDivisionList = [];
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
	}

	get_divisions(search_event?) {
		let searchKey = search_event?.term || "";
		let params = new FormData();
		params.append("lang_key", this.lang_key);
		params.append("search_key", searchKey || "");
		this.ds
			.getActionByUrl(this.ds.formData2string(params), "lpgone")
			.subscribe((res) => {
				if (res.status) {
					this.group1List = res?.records;
				} else {
					this.group1List = [];
				}
			});
	}

	buildForm() {
		this.form = this.fb.group({
			division_custom_name_en: ["", Validators.required],
			division_custom_name_ar: ["", Validators.required],
			division_ids: ["", Validators.required],
		});
	}

	onAddEditCustomDivision(template, item?) {
		this.form.reset();
		console.log(item);

		if (item) {
			this.isEdit = true;
			this.itemForUpdate = item;
			this.form.patchValue({
				...item,
				division_ids: [item?.platform_group1_code_system],
			});
		} else {
			this.isEdit = false;
			this.itemForUpdate = null;
		}
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}

	deleteCustomDivision(item) {
		let formData = new FormData();
		formData.append("custom_divisions_id", item?.custom_divisions_id || "");
		this.spinner.show();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"pr/report/delete_custom_division"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(res?.records, 3000);
						this.getCustomDivisionList();
					} else {
						this.sweetAlert.errorToast(res?.error, 3000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
	}

	onSubmit() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		let url = "pr/report/add_report_custom_division";
		if (this.isEdit) {
			formData.append(
				"pr/report/update_custom_division",
				this.itemForUpdate?.custom_divisions_id || ""
			);
			url = "pr/report/update_custom_division";
		}
		formData.append(
			"division_custom_name_en",
			this.form.get("division_custom_name_en").value || ""
		);
		formData.append(
			"division_custom_name_ar",
			this.form.get("division_custom_name_ar").value || ""
		);
		formData.append("division_ids", this.form.get("division_ids").value || "");
		this.spinner.show();
		this.ds.post(url, formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
					this.modalRef.hide();
					this.getCustomDivisionList();
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
