import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-supervision-project-completion",
	templateUrl: "./supervision-project-completion.component.html",
	styleUrls: ["./supervision-project-completion.component.scss"],
})
export class SupervisionProjectCompletionComponent implements OnInit {
	@Input() supervision_data: any;
	@Input() able2add: any;
	@Input() able2edit: any;
	@Input() project_data: any;
	@Input() makeActionGroups: any;
	@Input() makeActionInitial: any;
	@Input() makeActionOperations: any;
	selected_tab = "snaglist_groups";
	constructor() {}

	ngOnInit(): void {}

	selectedListTabValue(e) {
		if (e?.tab?.textLabel == "snaglist_groups") {
			this.selected_tab = "snaglist_groups";
		} else if (e?.tab?.textLabel == "initial_delivery") {
			this.selected_tab = "initial_delivery";
		} else if (e?.tab?.textLabel == "snag_note_operations") {
			this.selected_tab = "snag_note_operations";
		}
	}
}
