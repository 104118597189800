import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-user-workarea-modal",
	templateUrl: "./user-workarea-modal.component.html",
	styleUrls: ["./user-workarea-modal.component.scss"],
})
export class UserWorkareaModalComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	form: FormGroup;
	workareaList = [];
	positionList = [];
	user_id;
	constructor(
		private fb: FormBuilder,
		public ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private dialogRef: MatDialogRef<UserWorkareaModalComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.user_id = data?.user_id;
	}

	ngOnInit(): void {
		this.buildForm();
		this.searchWorkarea();
		this.searchPositions();
	}

	buildForm() {
		this.form = this.fb.group({
			projects_work_area_id: [null, Validators.required],
			position_id: [null, Validators.required],
			user_id: [this.user_id, Validators.required],
		});
	}

	searchWorkarea() {
		let formData = new FormData();
		formData.append("lang_key", this.lang_key || "");
		formData.append("wsearch_key", "");
		this.ds
			.getActionByUrl(this.ds.formData2string(formData), "g/get_all_workarea")
			.subscribe((res) => {
				if (res?.status) {
					this.workareaList = res?.records;
				} else {
					this.workareaList = [];
				}
			});
	}

	searchPositions() {
		let formData = new FormData();
		formData.append("lang_key", this.lang_key || "");
		formData.append("wsearch_key", "");
		this.ds
			.getActionByUrl(this.ds.formData2string(formData), "g/get_all_positions")
			.subscribe((res) => {
				if (res?.status) {
					this.positionList = res?.records;
				} else {
					this.positionList = [];
				}
			});
	}

	onSubmit() {
		let formData = new FormData();
		formData.append(
			"projects_work_area_id",
			this.form.get("projects_work_area_id").value || ""
		);
		formData.append("position_id", this.form.get("position_id").value || "");
		formData.append("user_id", this.form.get("user_id").value || "");
		this.spinner.show();
		this.ds.post("g/assign_user", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
					this.dialogRef.close(true);
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
