import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";
@Component({
  selector: "app-csedit",
  templateUrl: "./csedit.component.html",
  styleUrls: ["./csedit.component.scss"],
})
export class CseditComponent implements OnInit, OnDestroy {
  registrationForm: FormGroup;
  id: string = this.route.snapshot.paramMap.get("id");
  type: string = this.route.snapshot.paramMap.get("type");
  users: any = [];
  branches: any = [];
  types: any = [];
  data_status: boolean = false;
  isFile: boolean = false;
  filename: any = this.lang.transform("lang_select_files");
  constructor(
    public ds: DataService,
    public router: Router,
    public route: ActivatedRoute,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.registration_form();
    this.get_customers_data();
    this.get_branches();
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public get_customers_data() {
    this.spinner.show();
    this.subscriptions.add(
      this.ds.getActionByUrl([], "cs/edit/" + this.id).subscribe(
        (res) => {
          this.spinner.hide();
          this.data_status = res.status;
          if (res.status) {
            this.registrationForm.patchValue(res.data);
            this.users = res.users;
          } else {
            this.ds.dialogf("", res.error);
          }
        },
        (error) => {
          this.data_status = false;
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public registration_form() {
    this.registrationForm = new FormGroup({
      branch_id: new FormControl("", [Validators.required]),
      customer_title_en: new FormControl("", [Validators.required]),
      customer_title_ar: new FormControl("", [Validators.required]),
      logo: new FormControl(""),
      customer_email_address: new FormControl("", [
        Validators.required,
        Validators.email,
      ]),
      customer_mobile: new FormControl("", [Validators.required]),
      customer_tax_vat: new FormControl("", [Validators.required]),
      customer_ccr_no: new FormControl(""),
      customer_managers: new FormControl("", [Validators.required]),
      customer_teams: new FormControl(""),
      customer_link_with_accounts: new FormControl(0, [Validators.required]),
      customer_address: new FormControl(""),
      customer_credit_days: new FormControl(""),
      customer_security_amount: new FormControl(""),
      customer_credit_limit: new FormControl(""),
      customer_discount_limit: new FormControl(""),
      customer_discount_onpay: new FormControl(""),
      customer_salesmancode: new FormControl(""),
      customer_salesmanarea: new FormControl(""),
      customer_stop_transaction: new FormControl(""),
      customer_account_id: new FormControl(""),
      street_name: new FormControl("", [Validators.required]),
      building_number: new FormControl("", [Validators.required]),
      plot_identification: new FormControl("", [Validators.required]),
      city_sub_division: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      postal_number: new FormControl("", [Validators.required]),
      registration_name: new FormControl("", [Validators.required]),
    });
  }
  public get_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "cs/b/customers_edit").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public emptyAccountFileds() {
    this.registrationForm.patchValue({
      customer_address: null,
      customer_credit_days: null,
      customer_security_amount: null,
      customer_credit_limit: null,
      customer_discount_limit: null,
      customer_discount_onpay: null,
      customer_salesmancode: null,
      customer_salesmanarea: null,
      customer_stop_transaction: null,
      customer_account_id: null,
    });
  }
  public search_users(key) {
    if (this.registrationForm.get("branch_id").value && key != undefined) {
      let param = new FormData();
      param.append("branch_id", this.registrationForm.get("branch_id").value);
      param.append("search_text", key.term);
      param.append("permission_key", "customers_edit");
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "cs/susers").subscribe((data) => {
          if (data.status) {
            this.users = data.records;
          }
        })
      );
    } else {
      this.ds.dialogf("", this.lang.transform("lang_choose_branch"));
    }
  }
  public uploadFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.isFile = true;
      this.registrationForm.get("logo").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.registrationForm.get("logo").setValue("");
      this.filename = this.lang.transform("lang_select_files");
    }
  }
  public removeFile() {
    this.isFile = false;
    this.registrationForm.get("logo").setValue("");
    this.filename = this.lang.transform("lang_select_files");
  }
  public update() {
    this.spinner.show();
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          this.ds.json2formdata(this.registrationForm.value),
          "cs/update/" + this.id
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
              this.alert.success(data.msg);
              this.registrationForm.reset();
              setTimeout(() => {
                this.router.navigate(["/customers/view/" + this.id]);
              }, 1000);
            } else {
              this.alert.error(data.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }
}
