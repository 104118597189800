import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
// import * as Editor from "assets/libraries/ckeditor/build/ckeditor";
@Component({
  selector: "app-add-update-contract",
  templateUrl: "./add-update-contract.component.html",
  styleUrls: ["./add-update-contract.component.scss"],
})
export class AddUpdateContractComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key") || "en";
  // public myEditor = Editor;
  config = {
    language: this.lang_key,
    toolbar: [
      {
        name: "document",
        items: [
          "Source",
          "-",
          "Save",
          "NewPage",
          "Preview",
          "Print",
          "-",
          "Templates",
        ],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          "Copy",
          "Paste",
          "PasteText",
          "PasteFromWord",
          "-",
          "Undo",
          "Redo",
        ],
      },
      {
        name: "editing",
        items: ["Find", "Replace", "-", "SelectAll"],
      },
      {
        name: "forms",
        items: [
          "Form",
          "Checkbox",
          "Radio",
          "TextField",
          "Textarea",
          "Select",
          "Button",
          "HiddenField",
        ],
      },
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "Subscript",
          "Superscript",
          "-",
          "CopyFormatting",
          "RemoveFormat",
        ],
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      {
        name: "insert",
        items: [
          "Image",
          "Flash",
          "Table",
          "wrapCode",
          "TableCellProperties",
          "TableColumnResize",
          "TableProperties",
          "TableToolbar",
          "HorizontalRule",
          "Smiley",
          "SpecialChar",
          "PageBreak",
          "Iframe",
        ],
      },
      { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
      { name: "colors", items: ["TextColor", "BGColor"] },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "CreateDiv",
          "-",
          "JustifyLeft",
          "JustifyCenter",
          "JustifyRight",
          "JustifyBlock",
          "-",
          "BidiLtr",
          "BidiRtl",
          "Language",
        ],
      },
      { name: "tools", items: ["Maximize", "ShowBlocks"] },
    ],
    extraPlugins:
      "justify,colorbutton,font,codesnippet,autocomplete,find,selectall,iframe,div,enterkey,notification,bidi",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    removeButtons: "Image",
  };
  data_for_add: any;
  vat_list = [];
  vat_value = null;
  contract_form: FormGroup;
  attachments_array: FormArray;
  filename: string = this.lang.transform("lang_select_files");
  project_design_id;
  update_data;
  edit_mode = false;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddUpdateContractComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lang: LanguagePipe,
    public ds: DataService,
    private spinner: PreloaderService,
    private sweetAlert: SweetAlertService
  ) {
    this.project_design_id = data?.project_design_id;
    this.update_data = data?.update_data;
    this.edit_mode = data?.update_data ? true : false;
  }

  ngOnInit(): void {
    this.build_form();
    // this.getVatList();
    this.vat_list = [
      {
        label: "5%",
        value: "5",
      },
      {
        label: "10%",
        value: "10",
      },
      {
        label: "15%",
        value: "15",
      },
    ];
    this.getForAddContract();
    if (this.edit_mode) {
      this.setEditForm();
    }
  }

  build_form() {
    this.contract_form = this.fb.group({
      contact_titel: ["", Validators.required],
      contact_titel_en: ["", Validators.required],
      contact_age: ["", Validators.required],
      contact_age_type: [null, Validators.required],
      contract_start_date: [null, Validators.required],
      contract_status: ["1", Validators.required],
      contract_soil_sensor: [null, Validators.required],
      contract_surveying_costs: [null, Validators.required],
      contract_printing: [null, Validators.required],
      contract_government_services: [null, Validators.required],
      contract_grand_total: [null, Validators.required],
      contract_net_amount: [null, Validators.required],
      contract_vat: [null, Validators.required],
      contract_technical_references: [null, Validators.required],
      attachments: this.fb.array([]),
    });
    this.attachments_array = this.contract_form.get("attachments") as FormArray;
  }
  setEditForm() {
    this.contract_form
      .get("contact_titel")
      .setValue(this.update_data?.contact_titel);
    this.contract_form
      .get("contact_titel_en")
      .setValue(this.update_data?.contact_titel_en);
    this.contract_form
      .get("contact_age")
      .setValue(this.update_data?.contact_age);
    this.contract_form
      .get("contact_age_type")
      .setValue(this.update_data?.contact_age_type);
    this.contract_form
      .get("contract_start_date")
      .setValue(this.update_data?.contract_start_date);
    this.contract_form
      .get("contract_soil_sensor")
      .setValue(this.update_data?.contract_soil_sensor);
    this.contract_form
      .get("contract_surveying_costs")
      .setValue(this.update_data?.contract_surveying_costs);
    this.contract_form
      .get("contract_printing")
      .setValue(this.update_data?.contract_printing);
    this.contract_form
      .get("contract_government_services")
      .setValue(this.update_data?.contract_government_services);
    this.contract_form
      .get("contract_grand_total")
      .setValue(this.update_data?.contract_grand_total);
    this.contract_form
      .get("contract_net_amount")
      .setValue(this.update_data?.contract_net_amount);
    this.contract_form
      .get("contract_vat")
      .setValue(this.update_data?.contract_vat);
    this.contract_form
      .get("contract_technical_references")
      .setValue(this.update_data?.contract_technical_references);
  }

  getVatList() {
    let param = new FormData();
    param.append("lang_key", this.lang_key);
    param.append("request_type", "vat");
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "form/FORM_PQ2/requests")
      .subscribe((res) => {
        if (res?.status) {
          this.vat_list = res.data;
        }
      });
  }
  getForAddContract() {
    this.ds
      .post("project_design/get_for_design_contract", {})
      .subscribe((res) => {
        if (res?.status) {
          this.data_for_add = res;
        }
      });
  }
  get attachments_form() {
    return this.contract_form.get("attachments") as FormArray;
  }

  public attachment(value?): FormGroup {
    return this.fb.group({
      delete_icon: [value?.title_english || value?.title_arabic || ""],
      view: [value?.title_english || value?.title_arabic || null],
      attach_title: [value?.title_english || ""],
      file: [null],
    });
  }
  public addAttachment(value?) {
    this.attachments_array.push(this.attachment(value));
    this.attachments_array.setValidators(Validators.required);
    this.attachments_array.updateValueAndValidity();
  }
  public removeAttachment(index) {
    this.attachments_array.removeAt(index);
    this.attachments_array.clearValidators();
    this.attachments_array.updateValueAndValidity();
  }
  onSubmit() {
    if (this.contract_form.valid) {
      this.spinner.show();
      let formData = new FormData();
      formData.append("project_design_id", this.project_design_id || "");
      formData.append(
        "contact_titel",
        this.contract_form.get("contact_titel").value || ""
      );
      formData.append(
        "contact_titel_en",
        this.contract_form.get("contact_titel_en").value || ""
      );
      formData.append(
        "contact_age",
        this.contract_form.get("contact_age").value || ""
      );
      formData.append(
        "contact_age_type",
        this.contract_form.get("contact_age_type").value || ""
      );
      formData.append(
        "contract_start_date",
        this.contract_form.get("contract_start_date").value || ""
      );
      formData.append(
        "contract_status",
        this.contract_form.get("contract_status").value || "1"
      );
      formData.append(
        "contract_soil_sensor",
        this.contract_form.get("contract_soil_sensor").value || ""
      );
      formData.append(
        "contract_surveying_costs",
        this.contract_form.get("contract_surveying_costs").value || ""
      );
      formData.append(
        "contract_printing",
        this.contract_form.get("contract_printing").value || ""
      );
      formData.append(
        "contract_government_services",
        this.contract_form.get("contract_government_services").value || ""
      );
      formData.append(
        "contract_grand_total",
        this.contract_form.get("contract_grand_total").value || ""
      );
      formData.append(
        "contract_net_amount",
        this.contract_form.get("contract_net_amount").value || ""
      );
      formData.append(
        "contract_vat",
        this.contract_form.get("contract_vat").value || ""
      );
      formData.append(
        "contract_technical_references",
        this.contract_form.get("contract_technical_references").value || ""
      );
      this.attachments_form.value.forEach((v, k) => {
        formData.append("attachments[" + k + "][file]", v.file);
        formData.append(
          "attachments[" + k + "][attach_title]",
          this.contract_form
            .get("attachments")
            ["controls"][k].get("attach_title").value
        );
      });
      this.ds
        .post("project_design/add_project_design_contract", formData)
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res?.status) {
              this.dialogRef.close({ status: "success" });
              this.sweetAlert.successToast(
                this.contract_form.get("contact_titel_en").value +
                  " " +
                  "Created Successfuly",
                1000
              );
            } else {
              this.dialogRef.close({ status: "failed" });
              this.sweetAlert.errorMessage(res?.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        );
    } else {
      this.sweetAlert.errorMessage(this.lang.transform("lang_required_fields"));
    }
  }
  onUpdateProject() {
    this.spinner.show();
    let formData = new FormData();
    formData.append("project_design_id", this.project_design_id || "");
    formData.append(
      "contact_titel",
      this.contract_form.get("contact_titel").value || ""
    );
    formData.append(
      "contact_titel_en",
      this.contract_form.get("contact_titel_en").value || ""
    );
    formData.append(
      "contact_age",
      this.contract_form.get("contact_age").value || ""
    );
    formData.append(
      "contact_age_type",
      this.contract_form.get("contact_age_type").value || ""
    );
    formData.append(
      "contract_start_date",
      this.contract_form.get("contract_start_date").value || ""
    );
    formData.append(
      "contract_status",
      this.contract_form.get("contract_status").value || ""
    );
    formData.append(
      "contract_soil_sensor",
      this.contract_form.get("contract_soil_sensor").value || ""
    );
    formData.append(
      "contract_surveying_costs",
      this.contract_form.get("contract_surveying_costs").value || ""
    );
    formData.append(
      "contract_printing",
      this.contract_form.get("contract_printing").value || ""
    );
    formData.append(
      "contract_government_services",
      this.contract_form.get("contract_government_services").value || ""
    );
    formData.append(
      "contract_grand_total",
      this.contract_form.get("contract_grand_total").value || ""
    );
    formData.append(
      "contract_net_amount",
      this.contract_form.get("contract_net_amount").value || ""
    );
    formData.append(
      "contract_vat",
      this.contract_form.get("contract_vat").value || ""
    );
    formData.append(
      "contract_technical_references",
      this.contract_form.get("contract_technical_references").value || ""
    );
    formData.append("contract_id", this.update_data?.contract_id || "");
    this.ds
      .post("project_design/update_project_design_contract", formData)
      .subscribe((res) => {
        this.spinner.hide();
        if (res?.status) {
          this.dialogRef.close(true);
          this.sweetAlert.successToast(
            this.contract_form.get("contact_titel_en").value +
              " " +
              "Updated Successfuly",
            1000
          );
        } else {
          this.sweetAlert.errorMessage(res?.error);
        }
      });
  }
}
