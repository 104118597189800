<div class="mnabrDialog">
    <h4 class="">{{'lang_employees_info'|language}}  </h4>
    <div class="matbody">
        <div class="row">
            <div class="col-12">
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{'lang_human_resources_number'|language}}</th>
                        <th scope="col">{{'lang_human_resources_id_number'|language}}</th>
                        <th scope="col">{{'lang_name'|language}}</th>
                        <th scope="col">{{'lang_experience'|language}}</th>
                        <th scope="col">{{'lang_human_resources_nationality'|language}}</th>
                        <th scope="col">{{'lang_action'|language}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of emps;let i = index">
                        <th scope="row">{{i+1}}</th>
                        <th scope="row">{{item.employee_number}}</th>
                        <th scope="row">{{item.employee_id_number}}</th>
                        <th scope="row">{{item.name}}</th>
                        <th scope="row">{{item.experience}}</th>
                        <th scope="row">{{item.country}}</th>
                        <th>
                          <mat-icon
                          matTooltip="{{'lang_view_profile' | language}}"
                         (click)="navigate(item)">
                            account_circle
                          </mat-icon>
                        </th>
                      </tr>
                   
                    </tbody>
                  </table>
            </div>
        </div>
       <!--  <hr class="hrclass"> -->
        <div class="row mt-4">
            <div class="col-6">
                <button mat-raised-button mat-dialog-close class="mnabrbutton" tabindex="-1">{{'lang_close' | language}}</button>
            </div>
            <div class="col-6">
               <!--  <button mat-raised-button class="mnabrbutton" (click)="confirm()" tabindex="1">{{'lang_yes' | language}}</button> -->
            </div>
        </div>
    </div>
</div>