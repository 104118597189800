import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-wafi-performance-indices",
  templateUrl: "./wafi-performance-indices.component.html",
  styleUrls: ["./wafi-performance-indices.component.scss"],
})
export class WafiPerformanceIndicesComponent implements OnInit {
  @Input() report_data;
  constructor() {}

  ngOnInit(): void {}
}
