<form [formGroup]="form" (ngSubmit)="completeStep3()">
  <div class="card contractor_approval_manager">
    <div class="card-header">
      {{ "lang_direct_manager" | language }}
      <i
        style="padding: 0 10px"
        class="fa fa-info-circle orangestatus-color"
        matTooltip="{{ 'lang_direct_manager_tooltip' | language }}"
      ></i>
    </div>
    <div class="card-body pad0">
      <div class="mbrl15">
        <div class="row mb-10">
          <div class="col-sm-6 col-xs-12 mb-10">
            <label class="mb-10">
              {{ "lang_transaction_approval" | language }} !?.
              <span class="red"> * </span> </label
            ><br />

            <mat-radio-group
              aria-label="Select an option"
              formControlName="approved"
              (change)="checkStatus()"
            >
              <mat-radio-button value="Reject">{{
                "lang_reject" | language
              }}</mat-radio-button>
              <mat-radio-button value="Approve">{{
                "lang_approve" | language
              }}</mat-radio-button>
            </mat-radio-group>
            <mat-error
              class="d-block"
              *ngIf="submitForm && form.controls['approved']?.errors?.required"
              >{{ "lang_field_required" | language }}</mat-error
            >
          </div>
          <div class="col-sm-6 col-xs-12 mb-10">
            <div>
              <label
                >{{ "lang_notes" | language }} :
                <span class="red" *ngIf="rejectedValidators.length > 0">*</span>
              </label>
              <textarea
                class="form-control almnabrformcontrol"
                formControlName="notes"
              ></textarea>
              <mat-error
                class="d-block"
                *ngIf="submitForm && form.controls['notes']?.errors?.required"
                >{{ "lang_field_required" | language }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="row mb-10">
          <div class="col-md-6 mb-10">
            <label class="mb-10">
              {{ "lang_substitute_employee_type" | language }}
              <span class="red"> * </span>
            </label>
            <ng-select
              [items]="employee_type"
              bindLabel="type"
              bindValue="value"
              clearAllText="Clear"
              (change)="setValidators()"
              appendTo="body"
              formControlName="employee_type"
              placeholder="{{ 'lang_employee_type' | language }}"
            >
            </ng-select>
            <mat-error
              *ngIf="
                form.controls['employee_type'].touched &&
                form.controls['employee_type'].errors?.required
              "
            >
              {{ "lang_field_required" | language }}
            </mat-error>
          </div>
          <div
            class="col-md-6 mb-10"
            *ngIf="form.controls['employee_type'].value == '1'"
          >
            <label class="mb-10">
              {{ "lang_substitute_employee" | language }}
              <span class="red"> * </span>
            </label>
            <ng-select
              [items]="users"
              [multiple]="false"
              bindLabel="label"
              bindValue="value"
              clearAllText="Clear"
              appendTo="body"
              formControlName="substitute_employee"
              placeholder="{{ 'lang_substitute_employee' | language }}"
              (search)="search_users($event)"
              [searchFn]="ds.customSearchFn"
            >
            </ng-select>
            <mat-error
              *ngIf="
                form.controls['substitute_employee'].touched &&
                form.controls['substitute_employee'].errors?.required
              "
            >
              {{ "lang_field_required" | language }}
            </mat-error>
          </div>
        </div>
        <div
          class="row mb-10"
          *ngIf="form.controls['employee_type'].value == '0'"
        >
          <div class="col-md-6 mb-10">
            <label class="mb-10">
              {{ "lang_substitute_employee_name" | language }}
              <span class="red"> * </span>
            </label>
            <input
              type="text"
              class="form-control almnabrformcontrol"
              formControlName="substitute_employee_name"
              placeholder="{{ 'lang_substitute_employee_name' | language }}"
            />
            <mat-error
              *ngIf="
                form.controls['substitute_employee_name'].touched &&
                form.controls['substitute_employee_name'].errors?.required
              "
            >
              {{ "lang_field_required" | language }}
            </mat-error>
          </div>
          <div class="col-md-6 mb-10">
            <label class="mb-10">
              {{ "lang_substitute_employee_number_iqama" | language }}
              <span class="red"> * </span>
            </label>
            <input
              type="text"
              class="form-control almnabrformcontrol"
              formControlName="substitute_employee_number_iqama"
              placeholder="{{
                'lang_substitute_employee_number_iqama' | language
              }}"
            />
            <mat-error
              *ngIf="
                form.controls['substitute_employee_number_iqama'].touched &&
                form.controls['substitute_employee_number_iqama'].errors
                  ?.required
              "
            >
              {{ "lang_field_required" | language }}
            </mat-error>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="verification_code"
                >{{ "lang_enter_verification_code" | language }}
                <span class="red">*</span>:
                <i
                  style="padding: 0 10px"
                  class="fa fa-info-circle orangestatus-color"
                  matTooltip="{{
                    'lang_if_you_not_have_code_hit_sendcode_button' | language
                  }}"
                ></i>
              </label>
              <input
                type="text"
                required
                class="form-control almnabrformcontrol"
                formControlName="transactions_persons_action_code"
              />

              <mat-error
                *ngIf="
                  submitForm &&
                  form.controls['transactions_persons_action_code']?.errors
                    ?.required
                "
                >{{ "lang_field_required" | language }}</mat-error
              >
            </div>
          </div>
          <div class="col-sm-12">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row mb-10">
          <div class="col-sm-4 col-xs-12 mb-10">
            <div>
              <button
                class="albutton mnabrbutton mat-accent"
                type="button"
                (click)="openModal()"
                mat-button
              >
                {{ "lang_send_code" | language }}
              </button>
            </div>
          </div>
          <div class="col-sm-4 col-xs-12 mb-10"></div>
          <div class="col-sm-4 col-xs-12 mb-10">
            <div>
              <input
                *ngIf="form.status == 'VALID'"
                type="submit"
                value="{{ 'lang_submit' | language }}"
                class="albutton mnabrbutton mat-accent"
                mat-button
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
