import {
	Component,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
	selector: "app-supervision-documents",
	templateUrl: "./supervision-documents.component.html",
	styleUrls: ["./supervision-documents.component.scss"],
})
export class SupervisionDocumentsComponent implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}
	modalRef: BsModalRef;
	@Input() supervision_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() project_data;
	@Input() filelevels;
	@Input() makeAction;
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	single = environment.singledropdown;
	docpageno = 1;
	docpagesize = "10";
	docListData: any = [];
	docnodata = this.lang.transform("lang_loading");
	documentsearchKey = "";
	documents_file_status = "1";
	document_type = "";
	item_document_type = "";
	document_types: any = [];
	sub_document_types: any = [];
	form: FormGroup;
	updateform: FormGroup;
	isFile: boolean = false;
	showdivision: boolean = false;
	filename = this.lang.transform("lang_select_files");
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.load_document_and_sub_doc_types();
		this.load_supervision_documents();
		this.form = new FormGroup({
			projects_profile_id: new FormControl(
				this.supervision_data.projects_profile_id,
				[Validators.required]
			),
			projects_supervision_id: new FormControl(
				this.supervision_data.projects_supervision_id,
				[Validators.required]
			),
			document_type: new FormControl("", [Validators.required]),
			documents_parent_id: new FormControl(""),
			documents_description: new FormControl("", [Validators.required]),
			documents_file: new FormControl("", [Validators.required]),
			level_keys: new FormControl("", [Validators.required]),
		});
		this.updateform = new FormGroup({
			documents_id: new FormControl("", [Validators.required]),
			projects_profile_id: new FormControl(
				this.supervision_data.projects_profile_id,
				[Validators.required]
			),
			projects_supervision_id: new FormControl(
				this.supervision_data.projects_supervision_id,
				[Validators.required]
			),
			documents_description: new FormControl("", [Validators.required]),
			documents_file: new FormControl(""),
			level_keys: new FormControl("", [Validators.required]),
		});
	}
	public load_document_and_sub_doc_types() {
		this.document_types = [];
		this.sub_document_types = [];
		this.spinner.show();
		let url = ["ZyYkGcK85IUr/DCT01", "ZyYkGcK85IUr/ID001"];
		this.ds.forkJoin(url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res[0].status) {
					this.document_types = res[0].records;
				}
				if (res[1].status) {
					this.sub_document_types = res[1].records;
				}
			},
			(error) => {
				this.spinner.hide();
			}
		);
	}
	public search_supervision_documents() {
		this.docpageno = 1;
		this.docpagesize = "10";
		this.load_supervision_documents();
	}
	public load_supervision_documents_page(page) {
		this.spinner.show();
		this.docpageno = page;
		this.docpagesize = this.docpagesize;
		this.load_supervision_documents();
	}
	public load_supervision_documents_pagesize(size) {
		this.spinner.show();
		this.docpageno = 1;
		this.docpagesize = size;
		this.load_supervision_documents();
	}
	public load_supervision_documents() {
		let formdata = new FormData();
		formdata.append("search_key", this.documentsearchKey);
		// formdata.append('documents_file_status', this.documents_file_status);
		formdata.append(
			"document_type",
			this.document_type.length > 0 ? this.document_type.toString() : ""
		);
		formdata.append(
			"item_document_type",
			this.item_document_type.length > 0
				? this.item_document_type.toString()
				: ""
		);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formdata),
				"gnN4Pp81e9/" +
					this.project_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id +
					"/" +
					this.docpageno +
					"/" +
					this.docpagesize
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.docListData = res;
					} else {
						this.docListData = [];
						this.docnodata = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.docListData = [];
					this.docnodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}
	public emptyaddform() {
		this.showdivision = false;
		this.alert.clear();
		this.form.reset();
		this.form
			.get("projects_profile_id")
			.setValue(this.supervision_data.projects_profile_id);
		this.form
			.get("projects_supervision_id")
			.setValue(this.supervision_data.projects_supervision_id);
		this.filename = this.lang.transform("lang_select_files");
	}
	public emptyEditform(list) {
		this.alert.clear();
		this.updateform.reset();
		this.showdivision = false;
		this.isFile = false;
		this.filename = this.lang.transform("lang_select_files");
		this.updateform.get("documents_id").setValue(list.documents_id);
		this.updateform
			.get("documents_description")
			.setValue(list.documents_description);
		this.updateform
			.get("projects_profile_id")
			.setValue(this.supervision_data.projects_profile_id);
		this.updateform
			.get("projects_supervision_id")
			.setValue(this.supervision_data.projects_supervision_id);
		this.updateform.get("level_keys").setValue(list.level_keys.split(","));
	}
	public show_sub_division_field(event) {
		this.form.get("documents_parent_id").clearValidators(); // 5.Set Required Validator
		this.form.get("documents_parent_id").updateValueAndValidity();
		if (event.keycode && event.keycode == "SOIDC") {
			this.form.get("documents_parent_id").setValidators([Validators.required]); // 5.Set Required Validator
			this.form.get("documents_parent_id").updateValueAndValidity();
			this.showdivision = true;
		}
	}
	public UploadFile(event, form) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			this.isFile = true;
			this.filename = event.target.files[0].name;
			form.setValue(event.target.files[0], { emitEvent: true });
		} else {
			this.isFile = false;
			form.setValue(null);
			this.filename = this.lang.transform("lang_select_files");
		}
	}
	public removeFile() {
		this.isFile = false;
		this.updateform.get("documents_file").setValue(null);
		this.filename = this.lang.transform("lang_select_files");
	}
	public submit(type) {
		this.spinner.show();
		this.form
			.get("projects_profile_id")
			.setValue(this.supervision_data.projects_profile_id);
		this.form
			.get("projects_supervision_id")
			.setValue(this.supervision_data.projects_supervision_id);
		let url =
			type == "S"
				? "Vy3cARkSMR"
				: "OW7rKSVCdg/" + this.updateform.get("documents_id").value;
		let form = type == "S" ? this.form.value : this.updateform.value;
		this.ds.postActionByUrl(this.ds.json2formdata(form), url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.alert.success(res.msg);
					this.form.reset();
					this.load_supervision_documents();
					setTimeout(() => {
						this.modalRef.hide();
					}, 1000);
				} else {
					this.alert.error(res.error);
				}
			},
			(error) => {
				this.spinner.hide();
				this.alert.error(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}
	public checkAllItemsDoc(event) {
		if (event.target.checked) {
			$(".supervision_documents .eachItemDoc").prop("checked", true);
			$(".supervision_documents .makeback").addClass("bground");
			$(".supervision_documents .deleteIconDiv").show();
		} else {
			$(".supervision_documents .eachItemDoc").prop("checked", false);
			$(".supervision_documents .makeback").removeClass("bground");
			$(".supervision_documents .deleteIconDiv").hide();
		}
	}
	public checkEachItemDoc(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".supervision_documents .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".supervision_documents .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".supervision_documents .eachItemDoc:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".supervision_documents .eachItemDoc").each(function () {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".supervision_documents .deleteIconDiv").show();
		} else {
			$(".supervision_documents .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".supervision_documents #AllItemsDoc").prop("checked", true);
		} else {
			$(".supervision_documents #AllItemsDoc").prop("checked", false);
		}
	}
	public deleteBulkDataDoc() {
		const checkedtotal = [];
		$(".supervision_documents .eachItemDoc:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: "400px",
				data: {
					title: this.lang.transform("lang_confirmation"),
					message: this.lang.transform("lang_are_you_sure"),
				},
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show();
					this.ds
						.deleteActionByUrl(
							checkedtotal,
							"BZgkdaN430/" +
								this.supervision_data.projects_profile_id +
								"/" +
								this.supervision_data.projects_supervision_id
						)
						.subscribe(
							(data) => {
								this.spinner.hide();
								if (data.status) {
									this.load_supervision_documents();
									$(".supervision_documents #AllItemsDoc").prop(
										"checked",
										false
									);
									$(".supervision_documents .deleteIconDiv").hide();
									this.ds.dialogf("", data.msg);
								} else {
									this.ds.dialogf("", data.error);
								}
							},
							(error) => {
								this.ds.dialogf(
									"",
									error && error.error && error.error.error
										? error.error.error
										: this.lang.transform("lang_internal_server_error")
								);
								this.spinner.hide();
							}
						);
				}
			});
		} else {
			this.ds.dialogf();
		}
	}
}
