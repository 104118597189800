<div class="row pb-3">
	<div class="col-12 col-sm-3 col-md-2 mb-2 mb-sm-0" style="align-self: center">
		<mat-select
			(selectionChange)="changeView()"
			[(ngModel)]="view"
			class="view-select"
		>
			<mat-option value="day">{{ "lang_day" | language }}</mat-option>
			<mat-option value="week">{{ "lang_week" | language }}</mat-option>
			<mat-option value="month">{{ "lang_month" | language }}</mat-option>
			<mat-option value="year" *ngIf="currentView == 'calendar'">{{
				"lang_year" | language
			}}</mat-option>
		</mat-select>
	</div>
	<div class="col-12 col-sm-3 col-md-2 mb-2 mb-sm-0" style="align-self: center">
		<mat-select
			(selectionChange)="changeType()"
			[(ngModel)]="resource_type"
			class="resource-type-select"
		>
			<mat-option value="status">{{ "lang_status" | language }}</mat-option>
			<mat-option value="members">{{ "lang_users" | language }}</mat-option>
			<mat-option value="gantt" *ngIf="isTimeline">{{
				"lang_gantt" | language
			}}</mat-option>
		</mat-select>
	</div>
	<div class="col-12 col-sm-3 col-md-2 mb-2 mb-sm-0" style="align-self: center">
		<mat-select [(ngModel)]="myTheme" class="theme-select">
			<mat-option value="ios">{{ "lang_theme_ios" | language }}</mat-option>
			<mat-option value="windows">{{
				"lang_theme_windows" | language
			}}</mat-option>
			<mat-option value="material">{{
				"lang_theme_material" | language
			}}</mat-option>
		</mat-select>
	</div>
	<div class="col-12 col-sm-3">
		<ng-select
			[items]="ticketUsers"
			[multiple]="true"
			appendTo="body"
			bindLabel="emp_name"
			bindValue="emp_id"
			clearAllText="Clear"
			placeholder="{{ 'lang_users' | language }}"
			[(ngModel)]="filter_user"
			(change)="changeUsers()"
			class="calendar_users"
			*ngIf="resource_type == 'members'"
		>
		</ng-select>
	</div>

	<div class="col-12 col-sm-3 text-end">
		<button
			(click)="showFilters()"
			class="btn btn-info albutton filter-btn m-auto"
		>
			<i class="fa fa-filter" style="font-size: 17px; margin: 0 5px"></i>
			{{ "lang_advanced_filter" | language }}
		</button>
	</div>
</div>
<div class="md-event-listing mb-3">
	<mbsc-eventcalendar
		[data]="myEvents"
		class="rtl-calender"
		[options]="calendarOptions"
		[themeVariant]="'light'"
		[theme]="myTheme"
		[view]="calCalendarView"
		[calendarSystem]="lang_key == 'ar' ? hijriCalendar : null"
		[locale]="localeAll[lang_key]"
		[rtl]="lang_key == 'ar' ? true : false"
		[resources]="myResources"
		[headerTemplate]="myHeaderTemplate"
		[resourceHeaderTemplate]="resourceHeaderTemp"
		[dragToMove]="true"
		[dragToResize]="true"
	>
		<ng-template #resourceHeaderTemp>
			<span *ngIf="resource_type == 'status'">{{
				"lang_status" | language
			}}</span>
			<span *ngIf="resource_type == 'members'">{{
				"lang_users" | language
			}}</span>
			<span *ngIf="resource_type == 'gantt'">{{
				"lang_gantt" | language
			}}</span>
		</ng-template>
		<ng-template #myHeaderTemplate>
			<mbsc-calendar-nav class="md-event-listing-nav"></mbsc-calendar-nav>
			<div class="md-event-listing-picker">
				<div class="timeline-calendar-toggle">
					<mat-slide-toggle
						[color]="'primary'"
						[(ngModel)]="isTimeline"
						(change)="changeCalendarTimeline($event)"
					>
						{{ "lang_timeline" | language }}
					</mat-slide-toggle>
					<mat-slide-toggle
						*ngIf="admin"
						class="ml-3 mr-3"
						(change)="onFilterAdmin($event)"
						[color]="'primary'"
						>{{ "lang_admin" | language }}</mat-slide-toggle
					>
				</div>
			</div>
			<mbsc-calendar-prev class="md-event-listing-prev"></mbsc-calendar-prev>
			<mbsc-calendar-today class="md-event-listing-today"></mbsc-calendar-today>
			<mbsc-calendar-next class="md-event-listing-next"></mbsc-calendar-next>
		</ng-template>
		<mbsc-popup
			class="md-tooltip"
			#popup
			[anchor]="anchor"
			[options]="popupOptions"
		>
			<div (mouseleave)="mouseLeave()" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
				<div
					class="md-tooltip-header"
					[ngStyle]="{
						background: '#ddd',
						color: currentEvent?.type == '2' ? 'black' : '#fff'
					}"
				>
					<span class="md-tooltip-name-age"
						>{{ currentEvent?.task_no }} - {{ currentEvent?.title }}</span
					>
					<!-- <span class="md-tooltip-time">{{time}}</span> -->
				</div>
				<div class="md-tooltip-info">
					<div class="md-tooltip-title">
						{{ "lang_ticket_title" | language }}:
						<span class="md-tooltip-status md-tooltip-text">{{
							currentEvent?.ticket_title
						}}</span>
					</div>
					<hr />
					<!-- {{currentEvent|json}} -->
					<div class="md-tooltip-title">
						{{ "lang_ticket" | language }}
						{{ "lang_start_date" | language }}:
						<span class="md-tooltip-reason md-tooltip-text">{{
							currentEvent?.start_date_ticket
						}}</span>
					</div>
					<div class="md-tooltip-title">
						{{ "lang_ticket" | language }}
						{{ "lang_end_date" | language }}:
						<span class="md-tooltip-reason md-tooltip-text">{{
							currentEvent?.end_date_ticket
						}}</span>
					</div>
					<hr />
					<div class="md-tooltip-title">
						{{ "lang_task" | language }}
						{{ "lang_start_date" | language }}:
						<span class="md-tooltip-reason md-tooltip-text">{{
							currentEvent?.start_date_task
						}}</span>
					</div>
					<div class="md-tooltip-title">
						{{ "lang_task" | language }}
						{{ "lang_end_date" | language }}:
						<span class="md-tooltip-reason md-tooltip-text">{{
							currentEvent?.end_date_task
						}}</span>
					</div>
					<hr />
					<div class="md-tooltip-title">
						{{ "lang_task" | language }} {{ "lang_progress" | language }}:
						<span class="md-tooltip-reason md-tooltip-text">{{
							currentEvent?.task_progress
						}}</span>
					</div>
				</div>
			</div>
		</mbsc-popup>
	</mbsc-eventcalendar>
</div>
