<form [formGroup]="form" (ngSubmit)="completeStep3()">
	<div class="card contractor_approval_manager">
		<div class="card-header">
			{{ "lang_contractor_manager_approval" | language }}
			<i
				style="padding: 0 10px"
				class="fa fa-info-circle orangestatus-color"
				matTooltip="{{ 'lang_contractor_manager_approval_tooltip' | language }}"
			></i>
		</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row mb-10">
					<div class="col-sm-6 col-xs-12 mb-10">
						<label class="mb-10">
							{{ "lang_transaction_approval" | language }} !?.
							<span class="red"> * </span> </label
						><br />
						<mat-radio-group
							aria-label="Select an option"
							formControlName="contractor_approval_status"
							(change)="form.get('contractor_rejected_notes').setValue('')"
						>
							<mat-radio-button value="Return">{{
								"lang_return_to_update" | language
							}}</mat-radio-button>
							<mat-radio-button value="Approve">{{
								"lang_approve" | language
							}}</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="col-sm-6 col-xs-12 mb-10">
						<div
							*ngIf="form.get('contractor_approval_status').value == 'Return'"
						>
							<label
								>{{ "lang_rejected_notes" | language }} :
								<span class="red">*</span></label
							>
							<textarea
								class="form-control almnabrformcontrol"
								formControlName="contractor_rejected_notes"
							></textarea>
						</div>
					</div>
				</div>
				<div class="row pt-3">
					<div class="col-sm-6">
						<div class="form-group">
							<label for="verification_code"
								>{{ "lang_enter_verification_code" | language }}
								<span class="red">*</span>:
								<i
									style="padding: 0 10px"
									class="fa fa-info-circle orangestatus-color"
									matTooltip="{{
										'lang_if_you_not_have_code_hit_sendcode_button' | language
									}}"
								></i>
							</label>
							<input
								type="text"
								required
								class="form-control almnabrformcontrol"
								formControlName="transactions_persons_action_code"
							/>

							<mat-error
								*ngIf="
									submitForm &&
									form.controls['transactions_persons_action_code']?.errors
										?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div>
					<div class="col-sm-12">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row mb-10">
					<div class="col-sm-4 col-xs-12 mb-10">
						<div>
							<button
								class="albutton mnabrbutton mat-accent"
								type="button"
								(click)="openModal()"
								mat-button
							>
								{{ "lang_send_code" | language }}
							</button>
						</div>
					</div>
					<div class="col-sm-4 col-xs-12 mb-10"></div>
					<div class="col-sm-4 col-xs-12 mb-10">
						<div>
							<input
								type="submit"
								value="{{ 'lang_submit' | language }}"
								class="albutton mnabrbutton mat-accent"
								mat-button
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<!--Contract Payment Table-->
<app-cpay-steps-table
	[tableContent]="allData?.view_request?.form_cpay_table_data?.records"
></app-cpay-steps-table>
