import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddUpdateTitlesComponent } from "../add-update-titles/add-update-titles.component";
import { AddPlatfornLevelsComponent } from "./add-platforn-levels/add-platforn-levels.component";
import { PlatformLevelsListComponent } from "./platform-levels-list/platform-levels-list.component";

@Component({
	selector: "app-cp-subtitles",
	templateUrl: "./cp-subtitles.component.html",
	styleUrls: ["./cp-subtitles.component.scss"],
})
export class CpSubtitlesComponent implements OnInit {
	@Input() title_id: any;
	@Input() projects_profile_id: any;
	@Input() projects_work_area_id: any;
	@Input() makeActionConfig: any;
	search_key = "";
	subTitlesList = [];
	pageno = 1;
	pagesize = "10";
	constructor(
		private ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.getSubtitles();
	}

	getSubtitles() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("projects_profile_id", this.projects_profile_id || "");
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("title_id", this.title_id || "");
		formData.append("search_key", this.search_key || "");
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				`p/cp/list_sub_titles/${this.pageno}/${this.pagesize}`
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.subTitlesList = res.records;
				} else {
					this.subTitlesList = [];
				}
			});
	}
	onAddUpdateSubTitle(data?) {
		const dialogRef = this.dialog.open(AddUpdateTitlesComponent, {
			width: "500px",
			data: {
				projects_work_area_id: this?.projects_work_area_id,
				projects_profile_id: this?.projects_profile_id,
				edit_data: data,
				type: "sub",
				titleId: this?.title_id,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.getSubtitles();
			}
		});
	}
	deleteSubtitle(sub_title_id) {
		this.spinner.show();
		this.ds
			.deleteActionByUrl(
				[sub_title_id],
				`p/cp/delete_sub_titles/${this.projects_profile_id}/${this.projects_work_area_id}/${this.title_id}`
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successToast(res?.message?.msg, 2000);
					this.getSubtitles();
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			});
	}
	onAddPlatformLevels(subTitleId) {
		const dialogRef = this.dialog.open(AddPlatfornLevelsComponent, {
			width: "600px",
			data: {
				projects_work_area_id: this?.projects_work_area_id,
				projects_profile_id: this?.projects_profile_id,
				titleId: this?.title_id,
				subTitleId: subTitleId,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
			}
		});
	}
	onShowPlatformLevels(subTitleId) {
		const dialogRef = this.dialog.open(PlatformLevelsListComponent, {
			width: "1000px",
			data: {
				projects_work_area_id: this?.projects_work_area_id,
				projects_profile_id: this?.projects_profile_id,
				titleId: this?.title_id,
				subTitleId: subTitleId,
				makeActionConfig: this.makeActionConfig,
			},
		});
	}
}
