<breadcrumb></breadcrumb>
<section class="panel-expansion">
    <mat-accordion class="appconssettings" >
        <mat-expansion-panel class="mpanel" [expanded]="true">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_consultant_settings' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15">
                    <div class="row" *ngIf="divisionAction == 'LIST'">
                        <div class="col-md-2 col-xs-12 mb-10">
                            <select class="form-control almnabrformcontrol" [(ngModel)]="projects_setting_consultant_type" (change)="spinner.show();search_types();">
                                <option value="">{{'lang_consultant_type' | language }} - {{'lang_all' | language}}</option>
                                <option value="FC">{{'lang_managing_from_consultants' | language}}</option>
                                <option value="CR">{{'lang_consultants_requirements' | language}}</option>
                            </select>
                        </div>
                        <div class="col-md-3 col-xs-12 mb-10">
                            <ng-select 
                                [items]="positions" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                [(ngModel)]="projects_setting_consultant_position_code"
                                placeholder="{{'lang_position' | language}}"
                                (change)="spinner.show();search_types();">
                            </ng-select>
                        </div>
                        <div class="col-md-3 col-xs-12 mb-10">
                            <ng-select 
                                [items]="platforms" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                [(ngModel)]="platform_code_system"
                                placeholder="{{'lang_platform' | language}}"
                                (change)="spinner.show();search_types();">
                            </ng-select>
                        </div>
                        <div class="col-md-4 mb-10">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control almnabrformcontrol" (keyup)="search_types();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                                <span class="input-group-btn" *ngIf="ds.allowMe('projects','projects_add');">
                                    <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" (click)="divisionAction='ADD' " type="button" matTooltip="{{'lang_create_consultant_setting' | language }}"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="divisionAction == 'LIST'">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allConsuls checkbox" id="AllConsuls"  (click)="checkAllConsuls($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_consultant_type' | language }}</td>
                                            <td>{{'lang_consultant_ids' | language }}</td>
                                            <td>{{'lang_receipient_position' | language }}</td>
                                            <td>{{'lang_authendicate_position' | language }}</td>
                                            <td>{{'lang_platform' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="wholedata.length != '0' " class="AppendList">
                                        <tr *ngFor="let list of wholedata.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{list.projects_setting_consultant_id}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachConsul checkbox" value="{{list.projects_setting_consultant_id}}" (click)="checkEachConsul($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td *ngIf="list.projects_setting_consultant_type == 'FC' ">{{'lang_managing_from_consultants' | language}}</td>
                                            <td *ngIf="list.projects_setting_consultant_type != 'FC' ">{{'lang_consultants_requirements' | language}}</td>                                           
                                            <td matTooltip="{{'lang_view_details' | language}}" (click)="openModalV(viewCSettings);viewModel= list.projects_setting_consultant_type;consultantsModel = list.projects_setting_consultant_id_linked.split(',');">
                                                <a href="javascript:void(0)">{{list.projects_setting_consultant_id_linked}}</a>
                                            </td>
                                            <td matTooltip="{{'lang_view_details' | language}}" (click)="openModalV(viewCSettings);viewModel='PO';positionsModel = list.projects_setting_consultant_position_code.split(','); " *ngIf="list.projects_setting_consultant_position_code">
                                                <a href="javascript:void(0)">{{list.projects_setting_consultant_position_code}}</a>
                                            </td>
                                            <td *ngIf="!list.projects_setting_consultant_position_code">----</td>
                                            <td matTooltip="{{'lang_view_details' | language}}" (click)="openModalV(viewCSettings);viewModel='PO';positionsModel = list.projects_setting_consultant_authendicate_position_code.split(','); " *ngIf="list.projects_setting_consultant_authendicate_position_code">
                                                <a href="javascript:void(0)">{{list.projects_setting_consultant_authendicate_position_code}}</a>
                                            </td>
                                            <td *ngIf="!list.projects_setting_consultant_authendicate_position_code">----</td>
                                            <td matTooltip="{{'lang_view_details' | language}}" (click)="openModalV(viewCSettings);viewModel='PT';platformsModel = list.platform_code_system.split(','); ">
                                                <a href="javascript:void(0)">{{list.platform_code_system}}</a>
                                            </td>
                                            <td>
                                                <i (click)="divisionAction='VIEW'; editData = list; " *ngIf="ds.allowMe('projects','projects_view');" matTooltip="{{'lang_view' | language }}" class="mnabricon fa fa-eye s2icon"></i>
                                                <i (click)="divisionAction='EDIT'; editData = list; " *ngIf="ds.allowMe('projects','projects_edit');" matTooltip="{{'lang_edit' | language }}" class="fa fa-pencil-square-o s2icon mnabricon"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="wholedata.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="8" align="center">{{nodata}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="divisionAction == 'ADD' || divisionAction == 'EDIT' || divisionAction == 'VIEW' ">
                        <div class="col-md-12 mb-10">
                            <app-cadd-edit (action)="divisionAction = $event;spinner.show();search_types();" [divisionAction]="divisionAction" [fconsultants]="fconsultants" [consultantr]="consultantr" [platforms]="platforms" [positions]="positions" [editViewData]="editData"></app-cadd-edit>
                        </div>
                    </div>
                </div>
                <div *ngIf="wholedata?.records?.length != '0' && divisionAction == 'LIST' " class="card-footer padb0">
                    <div class="row responsive_pagination">
                        <div class="col-lg-1 col-sm-1 mb-10">
                            <i *ngIf="isAdmin" class="deleteIconDiv none material-icons red deleteIcon" (click)="deleteBulkDataConsuls()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}">delete_forever</i>
                        </div>
                        <div class="col-lg-3 col-sm-3 mb-10" style="height: fit-content;margin: auto 0;">
                            {{'lang_total' | language }} : {{wholedata?.page?.total_records}}
                        </div>
                        <div class="col-lg-5 col-sm-5 mb-10"  style="display: flex;justify-content: center;">
                            <ngb-pagination class="m-auto" [collectionSize]="wholedata?.page?.total_records" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="pageno" [pageSize]="pagesize" (pageChange)="spinner.show();loadpage($event)"></ngb-pagination>
                        </div>
                        <div class="col-lg-3 col-sm-3" style="display: flex;justify-content: end;margin: auto;">
                            <mat-select  [(ngModel)]="pagesize" (selectionChange)="spinner.show();loadpagesize($event.value)">
                                <mat-option class="text-center" value="10">10 {{'lang_per_page'|language}} </mat-option>
                                <mat-option class="text-center" value="20">20 {{'lang_per_page'|language}}  </mat-option>
                                <mat-option class="text-center" value="50">50 {{'lang_per_page'|language}}  </mat-option>
                                <mat-option class="text-center" value="100">100 {{'lang_per_page'|language}}</mat-option>
                                <mat-option class="text-center" value="500">500 {{'lang_per_page'|language}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<ng-template #viewCSettings>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_view' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefV.hide();">&times;</button>
        </div>
        <div class="modal-body">     
            <div class="form-group" *ngIf="viewModel == 'FC' ">
                <div class="row">
                    <label>{{'lang_managing_from_consultants' | language }} : </label>
                    <div class="col-md-12 col-xs-12">
                        <ng-select 
                            class="DisabledSelectClass"
                            [items]="fconsultants" 
                            [multiple]="true" 
                            bindLabel="label" 
                            bindValue="value" 
                            appendTo="body" 
                            [disabled]="true"
                            clearAllText="Clear"
                            [(ngModel)]="consultantsModel"
                            placeholder="{{'lang_from_consultants' | language}}">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewModel == 'CR' ">
                <div class="row">
                    <label>{{'lang_consultants_requirements' | language }} : </label>
                    <div class="col-md-12 col-xs-12">
                        <ng-select 
                            class="DisabledSelectClass"
                            [items]="consultantr" 
                            [multiple]="true" 
                            bindLabel="label" 
                            bindValue="value" 
                            appendTo="body" 
                            [disabled]="true"
                            clearAllText="Clear"
                            [(ngModel)]="consultantsModel"
                            placeholder="{{'lang_consultants_requirements' | language}}">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewModel == 'PT' ">
                <div class="row">
                    <label>{{'lang_platform' | language }} : </label>
                    <div class="col-md-12 col-xs-12">
                        <ng-select 
                            class="DisabledSelectClass"
                            [items]="platforms" 
                            [multiple]="true" 
                            bindLabel="label" 
                            bindValue="value" 
                            appendTo="body" 
                            [disabled]="true"
                            clearAllText="Clear"
                            [(ngModel)]="platformsModel"
                            placeholder="{{'lang_platform' | language}}">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewModel == 'PO' ">
                <div class="row">
                    <label>{{'lang_position' | language }} : </label>
                    <div class="col-md-12 col-xs-12">
                        <ng-select 
                            class="DisabledSelectClass"
                            [items]="positions" 
                            [multiple]="true" 
                            bindLabel="label" 
                            bindValue="value" 
                            appendTo="body" 
                            [disabled]="true"
                            clearAllText="Clear"
                            [(ngModel)]="positionsModel"
                            placeholder="{{'lang_position' | language}}">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer pad15">
            <div class="row">
                <div class="col-md-9 col-xs-12 mb-10"></div>
                <div class="col-md-3 col-xs-12 mb-10">
                    <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefV.hide();">{{'lang_close' | language }}</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>