import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";

@Component({
	selector: "app-department-report-filters",
	templateUrl: "./department-report-filters.component.html",
	styleUrls: ["./department-report-filters.component.scss"],
})
export class DepartmentReportFiltersComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	phase_zone_no = null;
	projects_profile_id;
	projects_supervision_id;
	projects_work_area_id;
	forms = [];
	templateList = [];
	zones = [];
	divisions = [];
	units = [];
	blocks = [];
	clusters = [];
	filtersForm: FormGroup;
	constructor(
		public dialogRef: MatDialogRef<DepartmentReportFiltersComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private spinner: PreloaderService,
		public ds: DataService,
		private fb: FormBuilder
	) {
		this.buildFilters();
		this.projects_profile_id = data?.projects_profile_id;
		this.projects_supervision_id = data?.projects_supervision_id;
		this.projects_work_area_id = data?.projects_work_area_id;
		if (data?.filters) {
			this.filtersForm.patchValue(data?.filters);
		}
	}

	ngOnInit(): void {
		this.getFormsNames();
		this.getTemplateList();
		this.get_zones();
		this.get_divisions();
	}

	buildFilters() {
		this.filtersForm = this.fb.group({
			transaction_key: [null, Validators.required],
			template_id: [null],
			zone_id: [null],
			block_id: [null],
			cluster_id: [null],
			unit_id: [null],
		});
	}

	// form steps
	getFormsNames() {
		this.spinner.show();
		let params = new FormData();
		params.append("projects_work_area_id", this.projects_work_area_id || "");
		this.ds
			.getActionByUrl(this.ds.formData2string(params), "pr/get_forms_list")
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.forms = res?.records;
					}
				},
				(err) => {
					this.spinner.hide();
				}
			);
	}

	public getTemplateList() {
		this.spinner.show();
		this.templateList = [];
		let formData = new FormData();
		formData.append("lang_key", this.lang_key);
		formData.append("projects_work_area_id", this.projects_work_area_id);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"p/get_templates_for_transactions"
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.templateList = res.records;
				}
			});
	}

	get_zones() {
		this.spinner.show();
		let phaseId = "0",
			param = new FormData();
		param.append("phase_parent_id", phaseId);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(param),
				"joYF29rbEi/" +
					this.projects_profile_id +
					"/" +
					this.projects_supervision_id
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.zones = res?.records;
				}
			});
	}
	get_blocks() {
		let phaseId = this.filtersForm.get("zone_id").value?.phase_id;
		if (phaseId) {
			this.spinner.show();
			let param = new FormData();
			param.append("phase_parent_id", phaseId);
			this.ds
				.getActionByUrl(
					this.ds.formData2string(param),
					"joYF29rbEi/" +
						this.projects_profile_id +
						"/" +
						this.projects_supervision_id
				)
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.blocks = res?.records;
					} else {
						this.blocks = [];
					}
				});
		} else {
			this.blocks = [];
			this.filtersForm.get("zone_id").setValue(null);
			this.clusters = [];
			this.filtersForm.get("cluster_id").setValue(null);
		}
	}

	get_clusters() {
		let phaseId = this.filtersForm.get("block_id").value?.phase_id;
		if (phaseId) {
			this.spinner.show();
			let param = new FormData();
			param.append("phase_parent_id", phaseId);
			this.ds
				.getActionByUrl(
					this.ds.formData2string(param),
					"joYF29rbEi/" +
						this.projects_profile_id +
						"/" +
						this.projects_supervision_id
				)
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.clusters = res?.records;
					} else {
						this.clusters = [];
					}
				});
		} else {
			this.clusters = [];
		}
	}
	get_divisions() {
		this.spinner.show();
		this.ds.get("pr/get_system_division").subscribe((res) => {
			this.spinner.hide();
			if (res.status) {
				this.divisions = res?.result;
			}
		});
	}
	get_units(event) {
		this.spinner.show();
		this.ds.get("pr/get_system_division").subscribe((res) => {
			this.spinner.hide();
			if (res.status) {
				this.divisions = res?.result;
			}
		});
	}
	onSubmit() {
		this.dialogRef.close(this.filtersForm.value);
	}
	onReset() {
		this.filtersForm.reset();
	}
}
