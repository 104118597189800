<div class="card pstr-step1">
	<div class="card-header">{{ "lang_configurations" | language }}</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-12">
					<form [formGroup]="configrationStep1Form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-building fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_form_details" | language }}</div>
						</ng-template>
						<div class="row mb-10">
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_languages" | language }}:
										<span class="red">*</span></mat-label
									>
									<mat-select formControlName="lang_key">
										<mat-option value="en">{{
											"lang_english" | language
										}}</mat-option>
										<mat-option value="ar">{{
											"lang_arabic" | language
										}}</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['lang_key'].touched &&
										configrationStep1Form.controls['lang_key'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_subject" | language }}:
										<span class="red">*</span></mat-label
									>
									<input
										matInput
										placeholder="{{ 'lang_subject' | language }}"
										formControlName="transaction_request_description"
									/>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls[
											'transaction_request_description'
										].touched &&
										configrationStep1Form.controls[
											'transaction_request_description'
										].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_care" | language }}:
										<span class="red">*</span></mat-label
									>
									<input
										matInput
										placeholder="{{ 'lang_care' | language }}"
										formControlName="care"
									/>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['care'].touched &&
										configrationStep1Form.controls['care'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_transaction_to" | language }}:
										<span class="red">*</span></mat-label
									>
									<input
										matInput
										placeholder="{{ 'lang_transaction_to' | language }}"
										formControlName="transaction_to"
										type="text"
									/>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['transaction_to'].touched &&
										configrationStep1Form.controls['transaction_to'].errors
											?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_vat" | language }}:
										<span class="red">*</span></mat-label
									>
									<mat-select formControlName="vat_key">
										<mat-option
											*ngFor="let item of vat_list"
											[value]="item.value"
											>{{ item?.label }}</mat-option
										>
									</mat-select>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['lang_key'].touched &&
										configrationStep1Form.controls['lang_key'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_quotation_type" | language }}:
										<span class="red">*</span></mat-label
									>
									<mat-select formControlName="quotation_types_keyword">
										<mat-option
											*ngFor="let item of quotation_types"
											[value]="item?.value"
											>{{ item?.label }}</mat-option
										>
									</mat-select>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['quotation_types_keyword']
											.touched &&
										configrationStep1Form.controls['quotation_types_keyword']
											.errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label>{{
										"lang_attachmentstitle" | language
									}}</mat-label>
									<input
										matInput
										placeholder="{{ 'lang_attachmentstitle' | language }}"
										formControlName="attachmentstitle"
									/>
								</mat-form-field>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10 d-flex align-items-center">
								<mat-checkbox
									formControlName="needAttachments"
									class="checkbox-radio-button"
									>{{ "lang_need_attachment" | language }}</mat-checkbox
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10 d-flex align-items-center">
								<mat-checkbox
									formControlName="needOthers"
									class="checkbox-radio-button"
									>{{ "lang_others" | language }}</mat-checkbox
								>
							</div>
							<div class="col-12 mb-10">
								<label
									>{{ "lang_description" | language }}:
									<span class="red">*</span></label
								>
								<ckeditor
									#editor
									[config]="config"
									formControlName="content"
									[ngClass]="{ ' contentrtl': lang_key == 'ar' }"
								></ckeditor>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['content'].touched &&
										configrationStep1Form.controls['content'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div
								class="col-12 TableParts"
								*ngIf="configrationStep1Form.get('needAttachments').value"
							>
								<p class="mb-10">
									{{ "lang_attachments" | language }}:
									<span class="red"
										>*
										<mat-icon
											class="red mnabricon"
											matTooltip="{{ 'lang_close' | language }}"
											(click)="
												configrationStep1Form
													.get('needAttachments')
													.setValue(false)
											"
											>close</mat-icon
										></span
									>
								</p>
								<div
									class="table-responsive customResponsive tablefields quotationitemtable"
								>
									<table
										border="1"
										class="table CustomTable"
										width="100%"
										formArrayName="attachments"
									>
										<thead>
											<tr>
												<th
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													#
												</th>
												<th
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													<mat-icon
														class="mnabricon"
														matTooltip="{{ 'lang_add' | language }}"
														(click)="addfiles()"
														>add_box</mat-icon
													>
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="25%"
												>
													{{ "lang_title" | language }}
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="25%"
												>
													{{ "lang_file" | language }}
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="15%"
												>
													{{ "lang_attach_with_pdf" | language }}
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="30%"
												>
													{{ "lang_official_paper" | language }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												*ngFor="
													let file of filesFormGroup.controls;
													let i = index;
													let odd = odd;
													let even = even
												"
												[formGroupName]="i"
											>
												<td
													class="verticalalignmid"
													style="padding: 5px; word-break: break-all"
												>
													{{ i + 1 }}
												</td>
												<td class="verticalalignmid">
													<mat-icon
														*ngIf="
															!other_attachments.controls[i].get('delete_icon')
																.value
														"
														(click)="removefiles(i)"
														class="mnabricon red"
														matTooltip="{{ 'lang_delete' | language }}"
														>delete_forever</mat-icon
													>
												</td>
												<td
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<input
														type="text"
														formControlName="attach_title"
														placeholder="{{ 'lang_description' | language }}"
														autocomplete="off"
														class="form-control almnabrformcontrol"
													/>
												</td>
												<td
													class="verticalalignmid"
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
													*ngIf="
														!other_attachments.controls[i].get('view').value
													"
												>
													<input
														name="file"
														type="file"
														required
														onclick="this.value = null"
														(change)="
															ds.uploadSingleFile(
																$event,
																other_attachments.controls[i].get('file'),
																'file' + i,
																filename
															)
														"
														class="nghide albutton width100"
													/>
													<label id="file{{ i }}" class="albutton">{{
														filename
													}}</label>
												</td>
												<td
													class="verticalalignmid"
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<mat-checkbox
														formControlName="attach_with_the_document"
														class="checkbox-radio-button"
													></mat-checkbox>
												</td>
												<td
													class="verticalalignmid"
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<mat-checkbox
														formControlName="print_official_paper"
														class="checkbox-radio-button"
													></mat-checkbox>
												</td>
												<!-- <td
													*ngIf="
														other_attachments.controls[i].get('view').value
													"
												>
													<i
														class="fa fa-file-pdf-o"
														aria-hidden="true"
														(click)="
															ds?.preview_pdf_file(
																other_attachments.controls[i].get('file').value
															)
														"
													></i>
												</td> -->
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div
								class="col-12 TableParts mt-4"
								*ngIf="configrationStep1Form.get('needOthers').value"
							>
								<p class="mb-10">
									{{ "lang_others" | language }}:
									<span class="red"
										>*
										<mat-icon
											class="red mnabricon"
											matTooltip="{{ 'lang_close' | language }}"
											(click)="
												configrationStep1Form.get('needOthers').setValue(false)
											"
											>close</mat-icon
										></span
									>
								</p>
								<div
									class="table-responsive customResponsive tablefields quotationitemtable"
								>
									<table
										border="1"
										class="table CustomTable"
										width="100%"
										formArrayName="others"
									>
										<thead>
											<tr>
												<th
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													#
												</th>
												<th
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													<mat-icon
														class="mnabricon"
														matTooltip="{{ 'lang_add' | language }}"
														(click)="addOthers()"
														>add_box</mat-icon
													>
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="25%"
												>
													{{ "lang_title_english" | language }}
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="25%"
												>
													{{ "lang_title_arabic" | language }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												*ngFor="
													let item of othersFormGroup.controls;
													let i = index;
													let odd = odd;
													let even = even
												"
												[formGroupName]="i"
											>
												<td
													class="verticalalignmid"
													style="padding: 5px; word-break: break-all"
												>
													{{ i + 1 }}
												</td>
												<td class="verticalalignmid">
													<mat-icon
														*ngIf="
															!othersArray.controls[i].get('delete_icon').value
														"
														(click)="removeOthers(i)"
														class="mnabricon red"
														matTooltip="{{ 'lang_delete' | language }}"
														>delete_forever</mat-icon
													>
												</td>
												<td
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<input
														type="text"
														formControlName="qror_name_en"
														placeholder="{{ 'lang_title_english' | language }}"
														autocomplete="off"
														class="form-control almnabrformcontrol"
													/>
												</td>
												<td
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<input
														type="text"
														formControlName="qror_name_ar"
														placeholder="{{ 'lang_title_arabic' | language }}"
														autocomplete="off"
														class="form-control almnabrformcontrol"
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="row pt-2 pb-2">
							<div class="col-sm-12">
								<app-alert id="default-alert"></app-alert>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10 col-sm-8 col-xs-12 mb-10"></div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									(click)="submit()"
								>
									{{ "lang_submit" | language }}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<!-- <mat-horizontal-stepper #stepper [linear]="true" class="pad0">
				<mat-step [stepControl]="canMoveToTeams">
					<form [formGroup]="configrationStep1Form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-building fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_form_details" | language }}</div>
						</ng-template>
						<div class="row mb-10">
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_languages" | language }}:
										<span class="red">*</span></mat-label
									>
									<mat-select formControlName="lang_key">
										<mat-option value="en">{{
											"lang_english" | language
										}}</mat-option>
										<mat-option value="ar">{{
											"lang_arabic" | language
										}}</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['lang_key'].touched &&
										configrationStep1Form.controls['lang_key'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_subject" | language }}:
										<span class="red">*</span></mat-label
									>
									<input
										matInput
										placeholder="{{ 'lang_subject' | language }}"
										formControlName="transaction_request_description"
									/>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls[
											'transaction_request_description'
										].touched &&
										configrationStep1Form.controls[
											'transaction_request_description'
										].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_care" | language }}:
										<span class="red">*</span></mat-label
									>
									<input
										matInput
										placeholder="{{ 'lang_care' | language }}"
										formControlName="care"
									/>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['care'].touched &&
										configrationStep1Form.controls['care'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_transaction_to" | language }}:
										<span class="red">*</span></mat-label
									>
									<input
										matInput
										placeholder="{{ 'lang_transaction_to' | language }}"
										formControlName="transaction_to"
									/>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['transaction_to'].touched &&
										configrationStep1Form.controls['transaction_to'].errors
											?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_vat" | language }}:
										<span class="red">*</span></mat-label
									>
									<mat-select formControlName="vat_key">
										<mat-option
											*ngFor="let item of vat_list"
											[value]="item.value"
											>{{ item?.label }}</mat-option
										>
									</mat-select>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['lang_key'].touched &&
										configrationStep1Form.controls['lang_key'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label
										>{{ "lang_quotation_type" | language }}:
										<span class="red">*</span></mat-label
									>
									<mat-select formControlName="quotation_types_keyword">
										<mat-option
											*ngFor="let item of quotation_types"
											[value]="item?.value"
											>{{ item?.label }}</mat-option
										>
									</mat-select>
								</mat-form-field>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['quotation_types_keyword']
											.touched &&
										configrationStep1Form.controls['quotation_types_keyword']
											.errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10">
								<mat-form-field appearance="outline" style="width: 100%">
									<mat-label>{{
										"lang_attachmentstitle" | language
									}}</mat-label>
									<input
										matInput
										placeholder="{{ 'lang_attachmentstitle' | language }}"
										formControlName="attachmentstitle"
									/>
								</mat-form-field>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10 d-flex align-items-center">
								<mat-checkbox
									formControlName="needAttachments"
									class="checkbox-radio-button"
									>{{ "lang_need_attachment" | language }}</mat-checkbox
								>
							</div>
							<div class="col-sm-4 col-xs-12 mb-10 d-flex align-items-center">
								<mat-checkbox
									formControlName="needOthers"
									class="checkbox-radio-button"
									>{{ "lang_others" | language }}</mat-checkbox
								>
							</div>
							<div class="col-12 mb-10">
								<label
									>{{ "lang_description" | language }}:
									<span class="red">*</span></label
								>
								<ckeditor
									#editor
									[editor]="myEditor"
									[config]="config"
									formControlName="content"
									[ngClass]="{ ' contentrtl': lang_key == 'ar' }"
								></ckeditor>
								<mat-error
									*ngIf="
										configrationStep1Form.controls['content'].touched &&
										configrationStep1Form.controls['content'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div
								class="col-12 TableParts"
								*ngIf="configrationStep1Form.get('needAttachments').value"
							>
								<p class="mb-10">
									{{ "lang_attachments" | language }}:
									<span class="red"
										>*
										<mat-icon
											class="red mnabricon"
											matTooltip="{{ 'lang_close' | language }}"
											(click)="
												configrationStep1Form
													.get('needAttachments')
													.setValue(false)
											"
											>close</mat-icon
										></span
									>
								</p>
								<div
									class="table-responsive customResponsive tablefields quotationitemtable"
								>
									<table
										border="1"
										class="table CustomTable"
										width="100%"
										formArrayName="attachments"
									>
										<thead>
											<tr>
												<th
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													#
												</th>
												<th
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													<mat-icon
														class="mnabricon"
														matTooltip="{{ 'lang_add' | language }}"
														(click)="addfiles()"
														>add_box</mat-icon
													>
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="25%"
												>
													{{ "lang_title" | language }}
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="25%"
												>
													{{ "lang_file" | language }}
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="15%"
												>
													{{ "lang_attach_with_pdf" | language }}
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="30%"
												>
													{{ "lang_official_paper" | language }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												*ngFor="
													let file of filesFormGroup.controls;
													let i = index;
													let odd = odd;
													let even = even
												"
												[formGroupName]="i"
											>
												<td
													class="verticalalignmid"
													style="padding: 5px; word-break: break-all"
												>
													{{ i + 1 }}
												</td>
												<td class="verticalalignmid">
													<mat-icon
														*ngIf="
															!other_attachments.controls[i].get('delete_icon')
																.value
														"
														(click)="removefiles(i)"
														class="mnabricon red"
														matTooltip="{{ 'lang_delete' | language }}"
														>delete_forever</mat-icon
													>
												</td>
												<td
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<input
														type="text"
														formControlName="attach_title"
														placeholder="{{ 'lang_description' | language }}"
														autocomplete="off"
														class="form-control almnabrformcontrol"
													/>
												</td>
												<td
													class="verticalalignmid"
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
													*ngIf="
														!other_attachments.controls[i].get('view').value
													"
												>
													<input
														name="file"
														type="file"
														required
														onclick="this.value = null"
														(change)="
															ds.uploadSingleFile(
																$event,
																other_attachments.controls[i].get('file'),
																'file' + i,
																filename
															)
														"
														class="nghide albutton width100"
													/>
													<label id="file{{ i }}" class="albutton">{{
														filename
													}}</label>
												</td>
												<td
													class="verticalalignmid"
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<mat-checkbox
														formControlName="attach_with_the_document"
														class="checkbox-radio-button"
													></mat-checkbox>
												</td>
												<td
													class="verticalalignmid"
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<mat-checkbox
														formControlName="print_official_paper"
														class="checkbox-radio-button"
													></mat-checkbox>
												</td>
												<td
													*ngIf="
														other_attachments.controls[i].get('view').value
													"
												>
													<i
														class="fa fa-file-pdf-o"
														aria-hidden="true"
														(click)="
															ds?.preview_pdf_file(
																other_attachments.controls[i].get('file').value
															)
														"
													></i>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div
								class="col-12 TableParts mt-4"
								*ngIf="configrationStep1Form.get('needOthers').value"
							>
								<p class="mb-10">
									{{ "lang_others" | language }}:
									<span class="red"
										>*
										<mat-icon
											class="red mnabricon"
											matTooltip="{{ 'lang_close' | language }}"
											(click)="
												configrationStep1Form.get('needOthers').setValue(false)
											"
											>close</mat-icon
										></span
									>
								</p>
								<div
									class="table-responsive customResponsive tablefields quotationitemtable"
								>
									<table
										border="1"
										class="table CustomTable"
										width="100%"
										formArrayName="others"
									>
										<thead>
											<tr>
												<th
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													#
												</th>
												<th
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													<mat-icon
														class="mnabricon"
														matTooltip="{{ 'lang_add' | language }}"
														(click)="addOthers()"
														>add_box</mat-icon
													>
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="25%"
												>
													{{ "lang_title_english" | language }}
												</th>
												<th
													style="padding: 5px; word-break: break-all"
													width="25%"
												>
													{{ "lang_title_arabic" | language }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												*ngFor="
													let item of othersFormGroup.controls;
													let i = index;
													let odd = odd;
													let even = even
												"
												[formGroupName]="i"
											>
												<td
													class="verticalalignmid"
													style="padding: 5px; word-break: break-all"
												>
													{{ i + 1 }}
												</td>
												<td class="verticalalignmid">
													<mat-icon
														*ngIf="
															!othersArray.controls[i].get('delete_icon').value
														"
														(click)="removeOthers(i)"
														class="mnabricon red"
														matTooltip="{{ 'lang_delete' | language }}"
														>delete_forever</mat-icon
													>
												</td>
												<td
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<input
														type="text"
														formControlName="qror_name_en"
														placeholder="{{ 'lang_title_english' | language }}"
														autocomplete="off"
														class="form-control almnabrformcontrol"
													/>
												</td>
												<td
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<input
														type="text"
														formControlName="qror_name_ar"
														placeholder="{{ 'lang_title_arabic' | language }}"
														autocomplete="off"
														class="form-control almnabrformcontrol"
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="row pt-2 pb-2">
							<div class="col-sm-12">
								<app-alert id="default-alert"></app-alert>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10 col-sm-8 col-xs-12 mb-10"></div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperNext
									(click)="moveToTeams()"
								>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>
				<mat-step>
					<form [formGroup]="configrationStep2Form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-users fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_teams" | language }}</div>
						</ng-template>
						<div class="row p-1">
							<div class="col-12">
								<div class="itemsdata">
									<label>
										{{ "lang_teams" | language }}
										<span class="red">*</span></label
									>
									<div class="TableParts">
										<div
											class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
										>
											<table border="1" class="table CustomTable text-center">
												<thead>
													<tr>
														<td>#</td>
														<td>
															<mat-icon
																class="mnabricon"
																matTooltip="{{ 'lang_add' | language }}"
																(click)="add_new_items()"
																>add_box</mat-icon
															>
														</td>
														<td>
															<span
																matTooltip="{{ 'lang_position' | language }}"
																>{{ "lang_position" | language }}</span
															>
															<i class="red"> *</i>
														</td>
														<td>
															<span
																matTooltip="{{ 'lang_presence' | language }}"
																>{{ "lang_presence" | language }}</span
															>
															<i class="red"> *</i>
														</td>
														<td>
															<span
																matTooltip="{{
																	'lang_projects_nationality' | language
																}}"
																>{{
																	"lang_projects_nationality" | language
																}}</span
															>
															<i class="red"> *</i>
														</td>
														<td>
															<span
																matTooltip="{{ 'lang_team_number' | language }}"
																>{{ "lang_team_number" | language }}</span
															><i class="red"> *</i>
														</td>
														<td>
															<span
																matTooltip="{{ 'lang_experience' | language }}"
																>{{ "lang_experience" | language }}</span
															><i class="red"> *</i>
														</td>
														<td>
															<span
																matTooltip="{{ 'lang_proit_ratio' | language }}"
																>{{ "lang_proit_ratio" | language }}</span
															><i class="red"> *</i>
														</td>
														<td>
															<span
																matTooltip="{{
																	'lang_total_salary_per_person_month'
																		| language
																}}"
																>{{
																	"lang_total_salary_per_person_month"
																		| language
																}}</span
															><i class="red"> *</i>
														</td>
														<td>
															<span
																matTooltip="{{
																	'lang_duration_number_months' | language
																}}"
																>{{
																	"lang_duration_number_months" | language
																}}</span
															><i class="red"> *</i>
														</td>
														<td>
															<span
																matTooltip="{{
																	'lang_total_no_of_visits' | language
																}}"
																>{{
																	"lang_total_no_of_visits" | language
																}}</span
															><i class="red"> *</i>
														</td>
														<td>
															<span
																matTooltip="{{ 'lang_toal_bid' | language }}"
																>{{ "lang_toal_bid" | language }}</span
															><i class="red"> *</i>
														</td>
													</tr>
												</thead>
												<tbody
													formArrayName="teams"
													class="AppendList quotationtable"
												>
													<tr
														*ngFor="
															let item of items_group().controls;
															let itemIndex = index;
															let odd = odd;
															let even = even
														"
														[formGroupName]="itemIndex"
														style="background: #ffffff"
													>
														<td class="verticalalignmid">
															{{ itemIndex + 1 }}
														</td>
														<td class="verticalalignmid">
															<mat-icon
																class="mnabricon red"
																matTooltip="{{ 'lang_delete' | language }}"
																(click)="removeItems(itemIndex)"
																>close</mat-icon
															>
														</td>
														<td width="10%">
															<ng-select
																[items]="data"
																[multiple]="false"
																clearAllText="Clear"
																appendTo="body"
																formControlName="position"
																placeholder="{{ 'lang_position' | language }}"
																(change)="
																	setDataAction($event, itemIndex);
																	calculate_quotation(itemIndex)
																"
															>
															</ng-select>
															<mat-error
																*ngIf="
																	submitted &&
																	itemsgroup.controls[itemIndex].get('position')
																		.errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</td>
														<td width="10%">
															<ng-select
																[items]="data2"
																[multiple]="false"
																clearAllText="Clear"
																appendTo="body"
																formControlName="presence"
																placeholder="{{ 'lang_presence' | language }}"
																(change)="
																	setDataAction($event, itemIndex);
																	calculate_quotation(itemIndex)
																"
															>
															</ng-select>
															<mat-error
																*ngIf="
																	submitted &&
																	itemsgroup.controls[itemIndex].get('presence')
																		.errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</td>
														<td width="10%">
															<ng-select
																[items]="nationality"
																[multiple]="false"
																bindLabel="label"
																bindValue="value"
																clearAllText="Clear"
																appendTo="body"
																formControlName="nationality"
																placeholder="{{
																	'lang_human_resources_nationality' | language
																}}"
															>
															</ng-select>
															<mat-error
																*ngIf="
																	submitted &&
																	itemsgroup.controls[itemIndex].get(
																		'nationality'
																	).errors?.required
																"
																>{{
																	"lang_human_resources_nationality" | language
																}}</mat-error
															>
														</td>
														<td>
															<input
																type="text"
																placeholder="{{
																	'lang_team_number' | language
																}}"
																formControlName="team_number"
																class="form-control almnabrformcontrol"
																(keyup)="calculate_quotation(itemIndex)"
															/>
															<mat-error
																*ngIf="
																	submitted &&
																	itemsgroup.controls[itemIndex].get(
																		'team_number'
																	).errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</td>
														<td>
															<input
																type="text"
																placeholder="{{ 'lang_experience' | language }}"
																formControlName="experience"
																class="form-control almnabrformcontrol"
																(keyup)="calculate_quotation(itemIndex)"
															/>
															<mat-error
																*ngIf="
																	submitted &&
																	itemsgroup.controls[itemIndex].get(
																		'experience'
																	).errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</td>
														<td>
															<input
																type="text"
																placeholder="{{
																	'lang_proit_ratio' | language
																}}"
																formControlName="profit_ratio"
																class="form-control almnabrformcontrol"
																(change)="
																	ds.CheckDecimal(
																		$event.target.value,
																		items.controls[itemIndex].get(
																			'profit_ratio'
																		)
																	);
																	calculate_quotation(itemIndex)
																"
																(keyup)="calculate_quotation(itemIndex)"
															/>
															<mat-error
																*ngIf="
																	submitted &&
																	itemsgroup.controls[itemIndex].get(
																		'profit_ratio'
																	).errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</td>
														<td class="readonlybyaction">
															<input
																type="text"
																placeholder="{{
																	'lang_total_salary_per_person_month'
																		| language
																}}"
																formControlName="total_salary_per_person_month"
																readonly
																class="form-control almnabrformcontrol readonlyinput"
																(keyup)="calculate_quotation(itemIndex)"
															/>
															<mat-error
																*ngIf="
																	submitted &&
																	itemsgroup.controls[itemIndex].get(
																		'total_salary_per_person_month'
																	).errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</td>
														<td>
															<input
																type="text"
																placeholder="{{
																	'lang_duration_number_months' | language
																}}"
																formControlName="duration_of_months"
																class="form-control almnabrformcontrol"
																(keyup)="calculate_quotation(itemIndex)"
															/>
															<mat-error
																*ngIf="
																	submitted &&
																	itemsgroup.controls[itemIndex].get(
																		'duration_of_months'
																	).errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</td>
														<td class="rowdisable{{ itemIndex }}">
															<input
																type="text"
																placeholder="{{
																	'lang_total_no_of_visits' | language
																}}"
																formControlName="visits_per_month"
																class="form-control almnabrformcontrol inputdisable"
																(keyup)="calculate_quotation(itemIndex)"
															/>
															<mat-error
																*ngIf="
																	submitted &&
																	itemsgroup.controls[itemIndex].get(
																		'visits_per_month'
																	).errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</td>
														<td class="readonlybyaction">
															<input
																type="text"
																placeholder="{{ 'lang_toal_bid' | language }}"
																formControlName="total_bid_of_project"
																readonly
																class="form-control almnabrformcontrol readonlyinput"
																(change)="
																	ds.CheckDecimal(
																		$event.target.value,
																		items.controls[itemIndex].get(
																			'total_bid_of_project'
																		)
																	)
																"
																(keyup)="calculate_quotation(itemIndex)"
															/>
															<mat-error
																*ngIf="
																	submitted &&
																	itemsgroup.controls[itemIndex].get(
																		'total_bid_of_project'
																	).errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div class="TableParts">
										<div
											class="table-responsive customResponsive mb-10 tablefields"
										>
											<table border="1" class="table CustomTable text-center">
												<thead>
													<tr>
														<td>#</td>
														<td>
															<span
																matTooltip="{{
																	'lang_position_description' | language
																}}"
																>{{
																	"lang_position_description" | language
																}}</span
															>
															<i class="red"> *</i>
														</td>
													</tr>
												</thead>
												<tbody formArrayName="teams" class="AppendList">
													<tr
														*ngFor="
															let item of items_group().controls;
															let itemIndex = index;
															let odd = odd;
															let even = even
														"
														[formGroupName]="itemIndex"
														style="background: #ffffff"
													>
														<td width="1%" class="verticalalignmid">
															{{ itemIndex + 1 }}
														</td>
														<td>
															<input
																type="text"
																formControlName="positions_task"
																class="form-control almnabrformcontrol"
																placeholder="{{
																	'lang_position_description' | language
																}}"
															/>
															<mat-error
																*ngIf="
																	submitted &&
																	itemsgroup.controls[itemIndex].get(
																		'positions_task'
																	).errors?.required
																"
																>{{
																	"lang_field_required" | language
																}}</mat-error
															>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-12">
								<app-alert id="default-alert"></app-alert>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperPrevious
								>
									{{ "lang_previous" | language }}
								</button>
							</div>
							<div class="col-md-8 col-sm-4 col-xs-12 mb-10"></div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button class="albutton mnabrbutton mat-accent" mat-button>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>
				<mat-step>
					<form>
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-balance-scale fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_terms_conditions" | language }}</div>
						</ng-template>
						<div class="row p-1"></div>

						<div class="row">
							<div class="col-sm-12">
								<app-alert id="default-alert"></app-alert>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperPrevious
								>
									{{ "lang_previous" | language }}
								</button>
							</div>
							<div class="col-md-8 col-sm-4 col-xs-12 mb-10"></div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button class="albutton mnabrbutton mat-accent" mat-button>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>
			</mat-horizontal-stepper>  -->
		</div>
	</div>
</div>
