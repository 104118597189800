import { Component, OnInit, ViewChild } from '@angular/core';
import { FilesLevelGroupsComponent } from './files-level-groups/files-level-groups.component';
import { FilesLevelComponent } from './files-level/files-level.component';

@Component({
  selector: 'app-file-settings',
  templateUrl: './file-settings.component.html',
  styleUrls: ['./file-settings.component.scss']
})
export class FileSettingsComponent implements OnInit {
  @ViewChild(FilesLevelComponent) filelevel: FilesLevelComponent;
  @ViewChild(FilesLevelGroupsComponent) filelevelgroup: FilesLevelGroupsComponent;
  constructor() { }
  ngOnInit(): void {}
  public load_realtime_records(event) {
    this.filelevel.ngOnInit();
    this.filelevelgroup.ngOnInit();
  }
}
