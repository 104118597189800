import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ChoiceWithIndices } from "@bboxx/mentions";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { ToggleMenuService } from "@core/bootstrap/toggle-menu.service";
import { environment } from "@env/environment";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";
import { AddTemplateComponent } from "../../templates/add-template/add-template.component";
import { TicketSocket } from "../../ticketSocket.service";
import { AddUpdateTaskComponent } from "../add-update-task/add-update-task.component";
import { EditCommentComponent } from "../edit-comment/edit-comment.component";
import { TicketAttachmentsComponent } from "../ticket-attachments/ticket-attachments.component";
import { TicketContractsComponent } from "../ticket-contracts/ticket-contracts.component";
import { TicketDetailsComponent } from "../ticket-details/ticket-details.component";
import { TicketTaskUsersComponent } from "../ticket-task-users/ticket-task-users.component";
import { ViewTaskComponent } from "../view-task/view-task.component";
import { ViewUsersComponent } from "../view-users/view-users.component";

@Component({
	selector: "app-tracking-ticket",
	templateUrl: "./tracking-ticket.component.html",
	styleUrls: ["./tracking-ticket.component.scss"],
})
export class TrackingTicketComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	ticketID = this.route.snapshot.paramMap.get("ticket_id");
	userId = this.localstorageService.getItem("user_id");
	user_avatar = this.localstorageService.getItem("user_avatar");
	baseUrl = environment.SERVER_ORIGIN;
	newList = [];
	inprogressList = [];
	confirmList = [];
	doneList = [];
	allTasks = [];
	ticketData = {
		ticket_no: "",
		ticket_id: "",
		ticket_title: "",
		ticket_description: "",
		ticket_type: "",
		ticket_signature: "",
		ticket_periority: "",
		note: "",
		need_reply: "",
		start_date: "",
		reply_date: "",
		ticket_time_work: "",
		end_date: "",
		end_date_nearly: "",
		is_delete: "0",
		is_ticket_admin: false,
		canAddTask: false,
	};
	ticketUsersList = [];
	ticket_attachments = [];
	ticketAttachmentsSlice = 2;
	attachTitle = "";
	attachFile = "";
	isAddAttach = false;
	isCommentsLoading = false;
	ticketComments = [];
	write_comment = {
		description: "",
	};
	write_reply = [];
	user_popover = {
		user_id: "",
		first_name: "",
		last_name: "",
	};
	timelineData = [];
	mentionConfig = {
		items: [],
		triggerChar: "@",
		labelKey: "emp_name",
	};
	commentMentionChoices: any[] = [];
	commentMentions: ChoiceWithIndices[] = [];
	commentMentionLoading = false;
	replyMentionChoices: any[] = [];
	replyMentions: ChoiceWithIndices[] = [];
	replyMentionLoading = false;
	count_is_temp = "0";
	status_done_list = [];
	task_status_done = null;
	routeQueryParams: Subscription;
	constructor(
		private dialog: MatDialog,
		private route: ActivatedRoute,
		private localstorageService: LocalStorage,
		public ds: DataService,
		private spinner: PreloaderService,
		private router: Router,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private ticketSocketService: TicketSocket,
		private toggleMenu: ToggleMenuService
	) {}

	ngOnInit(): void {
		setTimeout(() => {
			this.toggleMenu.close();
		}, 500);
		this.getFormLists();
		this.getTicketData();
		this.getTicketTasks();
		this.ticketUsers();
		this.getTicketComments();
		this.getTicketAttachments();
		this.getTimelineData();
		//listen to changes
		this.listenTicketTasks();
		this.listenTicketComments();
		this.listenTicketAttachments();
		this.listenTicketTimeline();
		this.routeQueryParams = this.route.queryParams.subscribe((params) => {
			if (params["task"]) {
				this.openTask({ task_id: params["task"] });
			}
		});
	}

	getFormLists() {
		this.ds.post("tasks/get_add", {}).subscribe((res) => {
			if (res?.status) {
				this.status_done_list = res?.data?.ticket_status;
			}
		});
	}
	//change status done
	onChangeStatusDone(status) {
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		formData.append("status", status || "");
		this.ds.post("tasks/change_status_ticket", formData).subscribe(
			(res) => {
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						2000
					);
					this.task_status_done = status;
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(err) => {
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	//start get ticket data
	getTicketData() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this?.ticketID || "");
		this.ds.post("tasks/get_ticket_row", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.ticketData.ticket_no = res?.data?.ticket_no;
					this.ticketData.ticket_id = res?.data?.ticket_id;
					this.ticketData.ticket_title = res?.data?.ticket_titel;
					this.ticketData.ticket_type = res?.data?.ticket_type_name;
					this.ticketData.ticket_description = res?.data?.ticket_detalis;
					this.ticketData.ticket_periority = res?.data?.important_name;
					this.ticketData.ticket_signature = res?.data?.sig_name;
					this.ticketData.ticket_time_work = res?.data?.time_work;
					this.ticketData.note = res?.data?.notes;
					this.ticketData.start_date = res?.data?.start_date_format;
					this.ticketData.end_date = res?.data?.end_date_format;
					this.ticketData.end_date_nearly = res?.data?.end_date_nearly;
					this.ticketData.reply_date = res?.data?.date_reply;
					this.ticketData.need_reply = res?.data?.need_reply;
					this.ticketData.is_delete = res?.data?.is_delete;
					this.ticketData.is_ticket_admin = res?.data?.is_ticket_admin;
					this.ticketData.canAddTask = res?.data?.canAddTask;
					this.count_is_temp = res?.data?.count_is_temp;
					this.task_status_done = res?.data?.ticket_status;
				} else {
					this.router.navigate(["/tasks/alltasks"]);
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}
	//end get ticket data

	//start get ticket tasks
	getTicketTasks() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		this.ds.post("tasks/get_tasks", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.allTasks = res?.data;
					for (let task of res?.data) {
						switch (task?.task_status) {
							case "1": {
								this.newList.push(task);
								break;
							}
							case "2": {
								this.inprogressList.push(task);
								break;
							}
							case "3": {
								this.confirmList.push(task);
								break;
							}
							case "4": {
								this.doneList.push(task);
								break;
							}
						}
					}
				} else {
					this.allTasks = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.allTasks = [];
			}
		);
	}
	//end get ticket tasks
	//show ticket details
	showTicketDetials() {
		const dialogRef = this.dialog.open(TicketDetailsComponent, {
			width: "800px",
			height: "400px",
			data: {
				ticket: this.ticketData,
			},
		});
	}
	//On Add task
	onAddTask(taskStatus) {
		const dialogRef = this.dialog.open(AddUpdateTaskComponent, {
			width: "1000px",
			data: {
				ticket_id: this.ticketData.ticket_id,
				startDate: this.ticketData.start_date,
				ticketTimework: this.ticketData.ticket_time_work,
				endDate: this.ticketData.end_date || this.ticketData.end_date_nearly,
				task_status: taskStatus,
				tasks: this.allTasks,
			},
		});
	}
	//On update task
	updateTask(event, task) {
		event.stopPropagation();
		const dialogRef = this.dialog.open(AddUpdateTaskComponent, {
			width: "800px",
			data: {
				ticket_id: this.ticketData.ticket_id,
				startDate: this.ticketData.start_date,
				ticketTimework: this.ticketData.ticket_time_work,
				endDate: this.ticketData.end_date || this.ticketData.end_date_nearly,
				task: task,
				task_status: task?.task_status,
				tasks: this.allTasks,
			},
		});
	}

	dublicateTask(event, task) {
		event.stopPropagation();
		let formData = new FormData();
		formData.append("task_id", task?.task_id || "");
		this.spinner.show();
		this.ds.post("tasks/copy_task_data", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
	//open attachment
	openTicketAttachments() {
		const dialogRef = this.dialog.open(TicketAttachmentsComponent, {
			data: {
				ticketID: this.ticketID,
				isAdmin: this.ticketData?.is_ticket_admin,
			},
			width: "700px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
			}
		});
	}
	// getTicketAttachments
	getTicketAttachments() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		this.ds.post("tasks/get_files_in_ticket", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.ticket_attachments = res?.files;
				} else {
					this.ticket_attachments = [];
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}
	// downloadFile (ticket attachments)
	// downloadFile(file_url, title?) {
	// 	this.spinner.show();
	// 	this.ds.get(file_url).subscribe(
	// 		(res) => {
	// 			this.spinner.hide();
	// 			if (res?.status) {
	// 				this.ds.downloadFile(res, title);
	// 			}
	// 		},
	// 		(err) => {
	// 			this.spinner.hide();
	// 		}
	// 	);
	// }
	//add ticket attachment
	onAddAttachment() {
		if (
			this.attachTitle &&
			this.attachFile &&
			this.attachFile.startsWith("https://drive.almnabr.com")
		) {
			let formData = new FormData();
			formData.append("ticket_id", this.ticketID || "");
			formData.append("attachments[0][attach_title]", this.attachTitle || "");
			formData.append("attachments[0][file]", this.attachFile || "");
			this.spinner.show();
			this.ds.post("tasks/add_files_in_ticket", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_success"),
							2000
						);
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_error_server"),
						2000
					);
				}
			);
			this.attachTitle = "";
			this.attachFile = "";
			this.isAddAttach = false;
		} else {
			this.sweetAlert.errorToast(
				this.lang.transform("lang_field_required"),
				3000
			);
		}
	}
	//delete ticket attachment
	onDeleteAttachment(file_id) {
		let formData = new FormData();
		formData.append("file_id", file_id || "");
		this.sweetAlert
			.confirmMessage("Are you sure?", "Yes, Delete")
			.then((confirmRes) => {
				if (confirmRes?.isConfirmed) {
					this.spinner.show();
					this.ds.post("tasks/delete_file_ticket", formData).subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert.successToast("File Deleted Successfully", 2000);
							} else {
								this.sweetAlert.errorToast(res?.error, 2000);
							}
						},
						(err) => {
							this.spinner.hide();
							this.sweetAlert.errorToast(
								this.lang.transform("lang_internal_error_server"),
								2000
							);
						}
					);
				}
			});
	}
	//open attachment in new tab
	navigateNewTab(url) {
		window.open(url, "_blank");
	}
	//open task
	openTask(task) {
		const dialogRef = this.dialog.open(ViewTaskComponent, {
			data: {
				ticketID: this.ticketID,
				isAdmin: this.ticketData?.is_ticket_admin,
				task: task,
			},
			width: "970px",
			height: "800px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			this.router.navigate([`/tasks/view/${this.ticketID}`]);
		});
	}
	// open Users Modal
	openUsersModal() {
		const dialogRef = this.dialog.open(ViewUsersComponent, {
			data: {
				users: this.ticketUsersList,
				ticketID: this.ticketID,
				isAdmin: this.ticketData?.is_ticket_admin,
			},
			width: "800px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.ticketUsers();
			}
		});
	}
	onShowTicketUsers() {
		const dialogRef = this.dialog.open(TicketTaskUsersComponent, {
			data: {
				users: this.ticketUsersList,
			},
			width: "700px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.ticketUsers();
			}
		});
	}
	//get ticket users
	ticketUsers() {
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		this.ds.post("tasks/emp_in_ticket", formData).subscribe((res) => {
			if (res?.status) {
				this.ticketUsersList = res?.data;
			} else {
				this.ticketUsersList = [];
			}
		});
	}
	//comment mentions
	async loadCommentMentions(searchTerm: string): Promise<any[]> {
		this.commentMentionLoading = true;
		const users = await this.ticketUsersList;
		this.commentMentionChoices = users.filter((user) => {
			const alreadyExists = this.commentMentions.some(
				(m) => m.choice.emp_id === user.emp_id
			);
			return (
				!alreadyExists &&
				(user.firstname_english
					.toLowerCase()
					.indexOf(searchTerm.toLowerCase()) > -1 ||
					user.firstname_arabic
						.toLowerCase()
						.indexOf(searchTerm.toLowerCase()) > -1)
			);
		});
		this.commentMentionLoading = false;
		return this.commentMentionChoices;
	}
	getChoiceLabel = (user: any): string => {
		return `@${
			this.lang_key == "ar" ? user.firstname_arabic : user.firstname_english
		} ${this.lang_key == "ar" ? user.lastname_arabic : user.lastname_english}`;
	};

	onMentionsCommentChange(choices: ChoiceWithIndices[]): void {
		this.commentMentions = choices;
	}

	onCommentMenuHide(): void {
		this.commentMentionChoices = [];
	}
	//reply mentions
	async loadReplyMentions(searchTerm: string): Promise<any[]> {
		const users = await this.ticketUsersList;
		this.replyMentionChoices = users.filter((user) => {
			const alreadyExists = this.replyMentions.some(
				(m) => m.choice.emp_name === user.emp_name
			);
			return (
				!alreadyExists &&
				user.emp_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
			);
		});
		return this.replyMentionChoices;
	}
	onMentionsReplyChange(choices: ChoiceWithIndices[]): void {
		this.replyMentions = choices;
	}

	onReplyMenuHide(): void {
		this.replyMentionChoices = [];
	}

	//add mention
	onAddMention(type, content?) {
		let mentionIds = [];
		if (type == "comment") {
			mentionIds = this.commentMentions.map((el) => {
				return el?.choice?.emp_id;
			});
		}
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		formData.append("notes", content || "");
		formData.append("emp_id", mentionIds.toString() || "");
		this.ds.post("tasks/add_mention", formData).subscribe((res) => {
			this.commentMentions = [];
		});
	}
	//get ticket comments
	getTicketComments() {
		this.isCommentsLoading = true;
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		formData.append("type", "1");
		this.ds.post(`tasks/get_comments/asc`, formData).subscribe((res) => {
			this.isCommentsLoading = false;
			if (res?.status) {
				this.ticketComments = res?.data;
				this.write_comment.description = "";
				this.buildReplysInputs();
			} else {
				this.ticketComments = [];
				this.buildReplysInputs();
			}
		});
	}
	//add comment
	onAddComment(element?) {
		if (this.write_comment.description) {
			let formData = new FormData();
			formData.append("ticket_id", this.ticketID || "");
			formData.append("notes", this.write_comment.description || "");
			this.ds.post("tasks/add_comment", formData).subscribe((res) => {
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_comment_added_successfully"),
						1000
					);
					this.buildReplysInputs();
					// this.onAddMention('comment', this.write_comment.description);
					this.write_comment.description = " ";
					this.write_comment.description = "";
				} else {
					this.sweetAlert.errorToast(this.lang.transform(res?.error), 2000);
					this.write_comment.description = "";
				}
			});
		}
		this.write_comment.description = "";
		this.onWriteCommentFocusout();
		this.buildReplysInputs();
		if (element) {
			element.style.height = "5px";
		}
	}
	//delete comment
	deleteComment(comment) {
		let formData = new FormData();
		formData.append("comment_id", comment?.history_id || "");
		this.sweetAlert
			.confirmMessage("Are you sure?", "Yes, Delete")
			.then((confirmRes) => {
				if (confirmRes.isConfirmed) {
					this.spinner.show();
					this.ds.post("tasks/delete_comment_reply", formData).subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert.successToast(
									this.lang.transform("lang_comment_deleted_successfully"),
									1000
								);
								this.buildReplysInputs();
							} else {
								this.sweetAlert.errorMessage(this.lang.transform(res?.error));
							}
						},
						(err) => {
							this.spinner.hide();
							this.sweetAlert.errorToast(
								this.lang.transform("lang_internal_error_server"),
								2000
							);
						}
					);
				}
			});
	}
	//edit comment
	onUpdateComment(comment, type) {
		const dialogRef = this.dialog.open(EditCommentComponent, {
			width: "800px",
			data: { content: comment, type: type },
		});
		dialogRef.afterClosed().subscribe((res) => {});
	}
	//get ticket comments
	buildReplysInputs() {
		this.write_reply = [];
		for (let item of this.ticketComments) {
			this.write_reply.push({
				description: "",
			});
		}
	}
	//add reply
	onAddReply(index, comment_id) {
		if (this.write_reply[index].description) {
			let formData = new FormData();
			formData.append("ticket_id", this.ticketID || "");
			formData.append("comment_id", comment_id || "");
			formData.append("notes", this.write_reply[index].description || "");
			this.ds.post("tasks/add_new_reply", formData).subscribe((res) => {
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_comment_added_successfully"),
						1000
					);
					this.write_reply[index].description = "";
				} else {
					this.sweetAlert.errorMessage(this.lang.transform(res?.error));
					this.write_reply[index].description = "";
				}
			});
			this.ticketComments[index].show_replys = true;
			this.onWriteReplyFocusout(index);
		}
	}

	//delete reply
	deleteReply(reply) {
		let formData = new FormData();
		formData.append("comment_id", reply?.history_id || "");
		this.sweetAlert
			.confirmMessage("Are you sure?", "Yes, Delete")
			.then((confirmRes) => {
				if (confirmRes.isConfirmed) {
					this.spinner.show();
					this.ds.post("tasks/delete_comment_reply", formData).subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert.successToast(
									this.lang.transform("lang_reply_deleted_successfully"),
									1000
								);
							} else {
								this.sweetAlert.errorMessage(this.lang.transform(res?.error));
							}
						},
						(err) => {
							this.spinner.hide();
							this.sweetAlert.errorToast(
								this.lang.transform("lang_internal_error_server"),
								2000
							);
						}
					);
				}
			});
	}

	//get timeline data
	getTimelineData() {
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		formData.append("type", "1");
		this.ds.post(`tasks/time_line/desc`, formData).subscribe(
			(res) => {
				if (res?.status) {
					this.timelineData = res?.data;
				} else {
					this.timelineData = [];
				}
			},
			(err) => {
				this.timelineData = [];
			}
		);
	}
	//start drag drop functionality
	drop(event: CdkDragDrop<any[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		} else {
			//change task status
			let movedList = "";
			let task = event.previousContainer.data[event.previousIndex];
			let formData = new FormData();
			switch (event.container.id) {
				case "new": {
					formData.append("status", "1");
					movedList = "New";
					break;
				}
				case "inprogress": {
					formData.append("status", "2");
					movedList = "In Progress";
					break;
				}
				case "confirm": {
					formData.append("status", "3");
					movedList = "Confirm";
					break;
				}
				case "done": {
					formData.append("status", "4");
					movedList = "Done";
					break;
				}
			}
			formData.append("task_id", task?.task_id || "");
			this.ds.post("tasks/change_status_task", formData).subscribe(
				(res) => {
					if (res?.status) {
						this.sweetAlert.successToast(
							`${task?.title} ${this.lang.transform(
								"lang_moved_to"
							)} ${movedList}`,
							2000
						);
						transferArrayItem(
							event.previousContainer.data,
							event.container.data,
							event.previousIndex,
							event.currentIndex
						);
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
		}
	}
	//end drag drop functionality

	//on show user popover
	onShowUser(user) {
		if (user) {
			this.user_popover.user_id = user?.emp_id;
			if (this.lang_key == "ar") {
				this.user_popover.first_name = user?.firstname_arabic || "-- ";
				this.user_popover.last_name = user?.lastname_arabic || "--";
			} else {
				this.user_popover.first_name = user?.firstname_english || "-- ";
				this.user_popover.last_name = user?.lastname_english || "--";
			}
		}
	}
	//start unfollow ticket
	unfollowTicket() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		this.ds.post("tasks/unfollow_ticket", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(this.lang.transform(res?.message), 1000);
				} else {
					this.sweetAlert.errorMessage(this.lang.transform(res?.error));
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}
	//start listen on Tasks
	listenTicketTasks() {
		this.ticketSocketService.listenToTasks(this.ticketID).subscribe((res) => {
			switch (res?.content?.task_status) {
				case "1": {
					if (res?.type == "add_task") {
						this.newList.unshift(res?.content);
					} else if (res?.type == "edit_task") {
						const taskIndex = this.newList.findIndex(
							(el) => `${el?.task_id}` == `${res?.content?.task_id}`
						);
						this.newList[taskIndex] = res?.content;
					} else if (res?.type == "delete_task") {
						const taskIndex = this.newList.findIndex(
							(el) => `${el?.task_id}` == `${res?.content?.task_id}`
						);
						this.newList.splice(taskIndex, 1);
					} else if (res?.type == "change_status_task") {
						const taskIndex = this.newList.findIndex(
							(el) => `${el?.task_id}` == `${res?.content?.task_id}`
						);
						this.newList.splice(taskIndex, 1);
						if (res?.content?.task_status == "2") {
							this.inprogressList.unshift(res?.content);
						} else if (res?.content?.task_status == "3") {
							this.confirmList.unshift(res?.content);
						} else if (res?.content?.task_status == "4") {
							this.doneList.unshift(res?.content);
						}
					}
					break;
				}
				case "2": {
					if (res?.type == "add_task") {
						this.inprogressList.unshift(res?.content);
					} else if (res?.type == "edit_task") {
						const taskIndex = this.inprogressList.findIndex(
							(el) => `${el?.task_id}` == `${res?.content?.task_id}`
						);
						this.inprogressList[taskIndex] = res?.content;
					} else if (res?.type == "delete_task") {
						const taskIndex = this.inprogressList.findIndex(
							(el) => `${el?.task_id}` == `${res?.content?.task_id}`
						);
						this.inprogressList.splice(taskIndex, 1);
					} else if (res?.type == "change_status_task") {
						const taskIndex = this.inprogressList.findIndex(
							(el) => `${el?.task_id}` == `${res?.content?.task_id}`
						);
						this.inprogressList.splice(taskIndex, 1);
						if (res?.content?.task_status == "1") {
							this.newList.unshift(res?.content);
						} else if (res?.content?.task_status == "3") {
							this.confirmList.unshift(res?.content);
						} else if (res?.content?.task_status == "4") {
							this.doneList.unshift(res?.content);
						}
					}
					break;
				}
				case "3": {
					if (res?.type == "add_task") {
						this.confirmList.unshift(res?.content);
					} else if (res?.type == "edit_task") {
						const taskIndex = this.confirmList.findIndex(
							(el) => `${el?.task_id}` == `${res?.content?.task_id}`
						);
						this.confirmList[taskIndex] = res?.content;
					} else if (res?.type == "delete_task") {
						const taskIndex = this.confirmList.findIndex(
							(el) => `${el?.task_id}` == `${res?.content?.task_id}`
						);
						this.confirmList.splice(taskIndex, 1);
					} else if (res?.type == "change_status_task") {
						const taskIndex = this.confirmList.findIndex(
							(el) => `${el?.task_id}` == `${res?.content?.task_id}`
						);
						this.confirmList.splice(taskIndex, 1);
						if (res?.content?.task_status == "1") {
							this.newList.unshift(res?.content);
						} else if (res?.content?.task_status == "2") {
							this.inprogressList.unshift(res?.content);
						} else if (res?.content?.task_status == "4") {
							this.doneList.unshift(res?.content);
						}
					}
					break;
				}
				case "4": {
					if (res?.type == "add_task") {
						this.doneList.unshift(res?.content);
					} else if (res?.type == "edit_task") {
						const taskIndex = this.doneList.findIndex(
							(el) => el?.task_id == res?.content?.task_id
						);
						this.doneList[taskIndex] = res?.content;
					} else if (res?.type == "delete_task") {
						const taskIndex = this.doneList.findIndex(
							(el) => el?.task_id == res?.content?.task_id
						);
						this.doneList.splice(taskIndex, 1);
					} else if (res?.type == "change_status_task") {
						const taskIndex = this.doneList.findIndex(
							(el) => `${el?.task_id}` == `${res?.content?.task_id}`
						);
						this.doneList.splice(taskIndex, 1);
						if (res?.content?.task_status == "1") {
							this.newList.unshift(res?.content);
						} else if (res?.content?.task_status == "2") {
							this.inprogressList.unshift(res?.content);
						} else if (res?.content?.task_status == "3") {
							this.confirmList.unshift(res?.content);
						}
					}
					break;
				}
			}
		});
	}
	//end listen on Tasks

	//start listen on comments
	listenTicketComments() {
		this.ticketSocketService
			.listenToComments(this.ticketID)
			.subscribe((res) => {
				if (res?.type == "add_comment") {
					this.ticketComments.unshift(res?.content);
					this.buildReplysInputs();
				} else if (res?.type == "edit_comment") {
					const commentIndex = this.ticketComments.findIndex(
						(el) => el?.history_id == res?.content?.history_id
					);
					this.ticketComments[commentIndex].notes_history =
						res?.content?.notes_history;
				} else if (res?.type == "delete_comment") {
					const commentIndex = this.ticketComments.findIndex(
						(el) => el?.history_id == res?.content?.history_id
					);
					this.ticketComments.splice(commentIndex, 1);
					this.buildReplysInputs();
				} else if (res?.type == "add_reply") {
					const commentIndex = this.ticketComments.findIndex(
						(el) => el?.history_id == res?.content?.comment_id
					);
					if (this.ticketComments[commentIndex]?.reply) {
						this.ticketComments[commentIndex]?.reply?.unshift(
							res?.content?.reply
						);
					} else {
						this.ticketComments[commentIndex].reply = [res?.content?.reply];
					}
				} else if (res?.type == "edit_reply") {
					const commentIndex = this.ticketComments.findIndex(
						(el) => el?.history_id == res?.content?.comment_id
					);
					const replyIndex = this.ticketComments[
						commentIndex
					]?.reply?.findIndex(
						(el) => el?.history_id == res?.content?.reply?.history_id
					);
					this.ticketComments[commentIndex].reply[replyIndex].comment_content =
						res?.content?.reply?.comment_content;
				} else if (res?.type == "delete_reply") {
					const commentIndex = this.ticketComments.findIndex(
						(el) => el?.history_id == res?.content?.comment_id
					);
					const replyIndex = this.ticketComments[
						commentIndex
					]?.reply?.findIndex(
						(el) => el?.history_id == res?.content?.reply?.history_id
					);
					this.ticketComments[commentIndex].reply.splice(replyIndex, 1);
				}
			});
	}
	//end listen on comments

	//start listen on Attachments
	listenTicketAttachments() {
		this.ticketSocketService
			.listenToAttachments(this.ticketID)
			.subscribe((res) => {
				if (res?.type == "files_ticket") {
					this.ticket_attachments.unshift(res?.content);
				} else if (res?.type == "delete_attachment") {
					const attachmentIndex = this.ticket_attachments.findIndex(
						(el) => el?.file_records_id == res?.content?.file_records_id
					);
					this.ticket_attachments.splice(attachmentIndex, 1);
				}
			});
	}
	//end listen on Attachments

	//start listen on Timeline
	listenTicketTimeline() {
		this.ticketSocketService
			.listenToTimeline(this.ticketID)
			.subscribe((res) => {
				this.timelineData.unshift(res?.content);
			});
	}
	//end listen on Timeline
	//start show task labels
	showTaskLabels(event, id) {
		event.stopPropagation();
		let labelsContainer = document.getElementById(id);
		labelsContainer.classList.contains("mscli-labels-show")
			? labelsContainer.classList.remove("mscli-labels-show")
			: labelsContainer.classList.add("mscli-labels-show");
	}
	//end show task labels

	//start auto sizing textarea of comment box
	autoResizeTxtarea(element) {
		element.style.height = "5px";
		element.style.height = element.scrollHeight + "px";
	}
	onWriteCommentFocusin() {
		document.querySelector(".bcbwc-box").classList.add("is_focused");
	}
	onWriteCommentFocusout() {
		if (!this.write_comment.description) {
			document.querySelector(".bcbwc-box").classList.remove("is_focused");
		}
	}
	//end auto sizing textarea of comment box
	//start auto sizing textarea of comment box
	onWriteReplyFocusin() {
		document.querySelector(".bcbcrlwr-box").classList.add("is_focused");
	}
	onWriteReplyFocusout(index) {
		if (!this.write_reply[index].description) {
			document.querySelector(".bcbcrlwr-box").classList.remove("is_focused");
		}
	}
	//end auto sizing textarea of comment box

	//start convert ticket to template
	convertTicketToTemplate() {
		const dialogRef = this.dialog.open(AddTemplateComponent, {
			width: "500px",
			data: {
				ticket_id: this.ticketID,
			},
		});
	}
	showContracts() {
		const dialogRef = this.dialog.open(TicketContractsComponent, {
			width: "700px",
			data: {
				ticket_id: this.ticketID,
			},
		});
	}
	ngOnDestroy() {
		this.ticketSocketService.socket.removeAllListeners();
	}
}
