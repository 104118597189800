import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddUpdateComponentComponent } from "./add-update-component/add-update-component.component";

@Component({
	selector: "app-contract-components",
	templateUrl: "./contract-components.component.html",
	styleUrls: ["./contract-components.component.scss"],
})
export class ContractComponentsComponent implements OnInit {
	@Input() contract_id: any;
	contract_components = [];
	constructor(
		private ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private dialog: MatDialog,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.getContractComponents();
	}

	getContractComponents() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("contract_id", this.contract_id || "");
		this.ds
			.post("project_design/get_rows_component_by_contract", formData)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.contract_components = res.data;
				} else {
					this.contract_components = [];
				}
			});
	}
	deleteComponent(component_id) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("component_id", component_id || "");
		this.ds.post("project_design/delete_component_row", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successToast(res?.message?.msg, 2000);
					this.getContractComponents();
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(error) => {
				this.spinner.hide();
				this.ds.dialogf(
					"",
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}

	add_update_component(data?) {
		const dialogRef = this.dialog.open(AddUpdateComponentComponent, {
			width: "600px",
			data: {
				contract_id: this.contract_id,
				component_data: data,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.getContractComponents();
			}
		});
	}
}
