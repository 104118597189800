import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-sqr-step5',
  templateUrl: './sqr-step5.component.html',
  styleUrls: ['./sqr-step5.component.scss']
})
export class SqrStep5Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  viewMode:boolean = false;
  displayTechnical = [];
  displaySaudiBuilding = [];
  rawIndex:any;
  stepName:string = 'Techinical_Assistant';
  form:FormGroup;
  extra1_status = null;
  mainform:FormGroup;
  other_assistances :FormArray;
  other_building_code :FormArray;
  other_notes:FormArray;
  technicalData:any;
  users:any = [];
  lang_key:string     = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
  constructor(public ds:DataService, private sweetAlert:SweetAlertService, public datepipe : DatePipe , public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef, public dialog: MatDialog) {
    setTimeout(() => {
      this.viewMode = this.allData?.view_request?.transactions_request?.records?.view_link ? true : false;
      this.displayTechnical = this.allData?.view_request?.Technical_Assistants_Evaluation?.records || [];
      this.displaySaudiBuilding = this.allData?.view_request?.Saudi_Building_Codes?.records || [];
      if(!this.viewMode) {
        this.getStep5Data();
      }
    });
    this.mainform = new FormGroup({
        special_confirmation_required : new FormControl(false),
        special_confirmation_users    : new FormControl(null, [Validators.required]),
        production_lines              : new FormControl(null, [Validators.required]),
        production_amount             : new FormControl(null, [Validators.required]),
        daily_production_needs        : new FormControl(null, [Validators.required]),
        notes                         : new FormArray([])
      });	
    this.form = new FormGroup({
      Technical_Assistants_Evaluation : this.fb.array([], Validators.required),
      Saudi_Building_Codes           : this.fb.array([])
    });
    this.other_assistances = this.form.get("Technical_Assistants_Evaluation") as FormArray;
    this.other_building_code = this.form.get("Saudi_Building_Codes") as FormArray;
    this.other_notes = this.mainform.get("notes") as FormArray;
  }
  ngOnInit(): void {
    
  }

  getStep5Data() {
    let formData = new FormData();
    formData.append('transaction_request_id',`${this.form_request_id}`);
    this.ds.post(`form/${this.form_key}/Techinical_Assistant/0`, formData).subscribe(data => {
      this.technicalData = data;
      if(data?.status) {
        data?.Technical_Assistants_Evaluation?.forEach((v, k) => {
          let assistanceData = {
            extra1_title:  v.extra1_title,
            extra1_result: v.extra1_result,
            no_code_result: v.no_code_result,
            yes_code_result: v.yes_code_result,
            projects_from_consultant_id: v.projects_from_consultant_id,
            extra1_status: null
          }
          this.add_assistances(assistanceData);
        });  
        data?.Saudi_Building_Codes?.forEach((v, k) => {
          let saudiBuildingData = {
          extra1_title:  v.extra1_title,
          extra1_status: null
        }
        this.add_building_code(saudiBuildingData);
        }); 
      } else {
        this.add_assistances();
        this.add_building_code();
      }},
      err => {
        this.alert.error(err);
        this.add_assistances();
      })
  }

  get _assistancesgroup() {
    return this.form.get("Technical_Assistants_Evaluation") as FormArray;
  }
  public _assistances(value?): FormGroup {
    if(value) {
      return this.fb.group({
        delete                      : [false],
        projects_from_consultant_id : new FormControl(value?.projects_from_consultant_id),
        title                       : new FormControl({ value : value?.extra1_title, disabled : (value?.extra1_title) ? true : false }, [Validators.required]),
        status                      : new FormControl(value?.extra1_status, [Validators.required]),
        result                      : new FormControl({ value:value?.extra1_result, disabled : (value?.extra1_result) ? true : false}, [Validators.required]),
        yes_code_result             : new FormControl({ value:value?.yes_code_result, disabled : (value?.yes_code_result) ? true : false}, [Validators.required]),
        no_code_result              : new FormControl({ value:value?.no_code_result, disabled : (value?.no_code_result) ? true : false}, [Validators.required]),
        resultChanged               : [null],
        attachments                 : this.fb.array([]),
        showSelect                  : [false]
      });
    } else {
      return this.fb.group({
        delete                      : [true],
        projects_from_consultant_id : [''],
        title                       : [{ value : '', disabled : false }, [Validators.required]],
        status                      : [null, [Validators.required]],
        result                      : [null, [Validators.required]],
        yes_code_result             : new FormControl(null),
        no_code_result              : new FormControl(null),
        resultChanged               : [null],
        attachments                 : this.fb.array([]),
        showSelect                  : [true]
      });
    }
  }
  public add_assistances(value?) {
    this.other_assistances.push(this._assistances(value));
  }
  public remove_assistances(index) {
    this.other_assistances.removeAt(index);
  }

  get _building_codegroup() {
    return this.form.get("Saudi_Building_Codes") as FormArray;
  }
  public _building_code(value?): FormGroup {
    if(value) {
      return this.fb.group({
        delete  : [false],
        title   : new FormControl({ value : value?.extra1_title, disabled : true }, [Validators.required]),
        status  : new FormControl(null, [Validators.required]),
      });
    } else {
      return this.fb.group({
        delete  : [true],
        title   : new FormControl('', [Validators.required]),
        status  : new FormControl(null, [Validators.required]),
      });
    }
  }
  public add_building_code(value?) {
    this.other_building_code.push(this._building_code(value));
  }
  public remove_building_code(index) {
    this.other_building_code.removeAt(index);
  }

  get _notesgroup() {
    return this.mainform.get("notes") as FormArray;
  }
  public _notes(value?): FormGroup {
    if(value) {
      return this.fb.group({
        delete : [false],
        note   :  [value?.note, [Validators.required]]
      });
    } else {
      return this.fb.group({
        delete :  [true],
        note   :  ['', [Validators.required]]
      });
    }
  }
  public add_note(value?) {
    this.other_notes.push(this._notes(value));
  }
  public remove_note(index) {
    this.other_notes.removeAt(index);
  }

  public search_special_approvers(key?) {
    this.users  = [];
		let param   = new FormData();
		param.append('search', key.term || '');
		param.append('lang_key', this.lang_key);
		param.append('user_type_id', '1');
		this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.users = res.list;
			}
		});
  }

  radioChange(e, i) {
    if(e.value == '1' && !this.other_assistances.controls[i].get('showSelect').value) {
      let value = this.other_assistances.controls[i].get('yes_code_result').value;
      this.other_assistances.controls[i].get('resultChanged').setValue(value);
      this.other_assistances.controls[i].get('result').setValue(value);
    } else if(e.value == '0' && !this.other_assistances.controls[i].get('showSelect').value) {
      let value = this.other_assistances.controls[i].get('no_code_result').value;
      this.other_assistances.controls[i].get('resultChanged').setValue(value);
      this.other_assistances.controls[i].get('result').setValue(value);
    } else if( this.other_assistances.controls[i].get('showSelect').value) {
      let value = this.other_assistances.controls[i].get('result').value;
      this.other_assistances.controls[i].get('resultChanged').setValue(value);
      this.other_assistances.controls[i].get('result').setValue(value);
    }
  }

  onAllCheckChange(e) {
    if(e?.value == '1') {
      this.other_assistances.controls.forEach(el => {
        el?.get('status').setValue('1');
        el?.get('resultChanged').setValue('B');
        el?.get('result').setValue('B');
      })
    } else if(e?.value == '0') {
      this.other_assistances.controls.forEach(el => {
        el?.get('status').setValue('0');
        el?.get('resultChanged').setValue('C');
        el?.get('result').setValue('C');
      })
    }
  }

  getAttachments(data) {
    let other_assistance_files = this.other_assistances.get(`${data.index}`).get('attachments') as FormArray;
    other_assistance_files.setValue([]);
    for(let file of data.form.controls) {
      other_assistance_files.push(file);
    }
  }

  checkValidation() {
    if(this.form.get('Technical_Assistants_Evaluation').invalid) {
      this.alert.error(this.lang.transform('lang_technical_assistants_fields_required'));
      return false;
    } else if(this.form.get('Saudi_Building_Codes').invalid) {
      this.alert.error(this.lang.transform('lang_saudi_building_codes_required'));
      return false;
    } else if(this.mainform.get('notes').invalid) {
      this.alert.error(this.lang.transform('lang_notes_required'));
      return false;
    } else if(this.mainform.get('special_confirmation_required').value && this.mainform.get('special_confirmation_users').invalid) {
      this.alert.error(this.lang.transform('lang_special_confirmation_users_required'));
        return false;
      
    } else if(this.mainform.get('production_lines').invalid) {
      this.alert.error(this.lang.transform('lang_production_lines_required'));
      return false;
    } else if(this.mainform.get('production_amount').invalid) {
      this.alert.error(this.lang.transform('lang_production_amount_required'));
      return false;
    } else if(this.mainform.get('daily_production_needs').invalid) {
      this.alert.error(this.lang.transform('lang_daily_production_needs_required'));
      return false;
    } else {
      return true;
    }
  }
  public completeStep5() {
    if(this.checkValidation()) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('transaction_request_id', this.form_request_id);
      if(this.mainform?.value?.special_confirmation_required){
        formData.append('special_approvers', this.mainform?.value?.special_confirmation_users);
      }
      formData.append("production_lines", this.mainform?.value?.production_lines);
      formData.append("production_amount", this.mainform?.value?.production_amount);
      formData.append("daily_production_needs", this.mainform?.value?.daily_production_needs);
      this.other_assistances?.controls?.forEach((v, k) => {
      formData.set("Technical_Assistants_Evaluation[" + k + "][title]", v['controls']['title']?.value);
      formData.set("Technical_Assistants_Evaluation[" + k + "][projects_from_consultant_id]", v['controls']['projects_from_consultant_id']?.value);
      formData.set("Technical_Assistants_Evaluation[" + k + "][status]", v['controls']['status']?.value);
      if(v['controls']['showSelect']?.value) {
        formData.set("Technical_Assistants_Evaluation[" + k + "][result]", v['controls']['result']?.value);
      } else {
        formData.set("Technical_Assistants_Evaluation[" + k + "][result]", v['controls']['resultChanged']?.value);
      }
      if(v['controls']['attachments']?.length) {
        v['controls']['attachments']['controls']?.forEach((a, b) => {
          formData.set("Technical_Assistants_Evaluation[" + k + "][attachments][" + b + "][file]", a['controls']['file']?.value);
          formData.set("Technical_Assistants_Evaluation[" + k + "][attachments][" + b + "][attach_title]", a['controls']['attach_title']?.value);
          formData.set("Technical_Assistants_Evaluation[" + k + "][attachments][" + b + "][required]", null);
        });
      }
      });
      this.other_building_code?.controls?.forEach((v, k) => { 
        formData.append("Saudi_Building_Codes[" + k + "][title]", v['controls']['title']?.value);
        formData.append("Saudi_Building_Codes[" + k + "][status]", v['controls']['status']?.value);
      });
      this.other_notes?.controls?.forEach((v, k) => {
        formData.append("notes[" + k + "][description]", v['controls']['note']?.value);
      })
      this.ds.post(`form/${this.form_key}/Techinical_Assistant/1`, formData).subscribe(data => {
        if(data.status) {
          this.spinner.hide();
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
          setTimeout(() => {
            this.refreshComponent.emit(true);
          }, 2000);
        } else {
          this.spinner.hide();
          this.alert.error(data.error)
        }
      }, (error) => {
        this.spinner.hide();
        this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } 
  }
}
