<breadcrumb></breadcrumb>

<section class="panel-expansion" [@fadeIn]>
	<mat-accordion class="stylepanel groups_data">
		<mat-expansion-panel class="mpanel" [expanded]="true">
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_shifts" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body tlist">
				<div class="mbrl15 fromtransactions">
					<!-- <div class="row">
						<div class="col-lg-12 col-sm-12 mb-10">
							<input
								type="text"
								class="form-control almnabrformcontrol"
								[(ngModel)]="searchKey"
								(keyup)="search_transactions()"
								placeholder="{{ 'lang_search' | language }}"
							/>
						</div>
					</div>
					<div class="row">
						<div
							class="col-md-3 mb-10 col-xs-12"
							[ngClass]="{ 'col-md-3': admin, 'col-md-4': !admin }"
						>
							<ng-select
								[items]="formlist"
								[multiple]="false"
								appendTo="body"
								bindLabel="label"
								bindValue="value"
								clearAllText="Clear"
								placeholder="{{ 'lang_search_form' | language }}"
								[(ngModel)]="searchByForm"
								(change)="search_transactions()"
							>
							</ng-select>
						</div>
						<div
							class="col-md-3 mb-10 col-xs-12"
							*ngIf="admin"
							[ngClass]="{ 'col-md-3': admin, 'col-md-4': !admin }"
						>
							<select
								class="form-control almnabrformcontrol"
								(change)="search_transactions();"
								[(ngModel)]="filteradmin"
							>
								<option value="0">{{ "lang_users" | language }}</option>
								<option value="1">{{ "lang_admin" | language }}</option>
							</select>
						</div>
						<div
							class="col-md-3 mb-10 col-xs-12"
							[ngClass]="{ 'col-md-3': admin, 'col-md-4': !admin }"
						>
							<ng-select
								[items]="modules"
								[multiple]="true"
								bindLabel="label"
								appendTo="body"
								bindValue="value"
								clearAllText="Clear"
								placeholder="{{ 'lang_search_modules' | language }}"
								[(ngModel)]="searchByModule"
								(change)="search_transactions()"
							>
							</ng-select>
						</div>
						<div
							class="col-md-3 mb-10 col-xs-12"
							[ngClass]="{ 'col-md-3': admin, 'col-md-4': !admin }"
						>
							<ng-select
								[items]="statusList"
								[multiple]="false"
								bindLabel="label"
								appendTo="body"
								bindValue="value"
								[clearable]="false"
								placeholder="{{ 'lang_status' | language }}"
								[(ngModel)]="searchByStatus"
								(change)="search_transactions()"                  <h4>{{ 'lang_add' | language }} {{ 'lang_shifts' | language }}</h4>
							>
							</ng-select>
						</div>
					</div> -->
					<div class="row col-md-12  attendence mb-4" style="justify-content: end;">
                        <div class="col-md-3">
                            <button type="button" (click)="openModal()" class="albutton mnabrbutton mat-accent" mat-button>{{ 'lang_add' | language }} {{ 'lang_shifts' | language }}</button>
                        </div>
                    </div>
                    <div class="row">
						<div class="col-md-12">
							<div class="table-responsive customResponsive">
								<table border="1" class="table CustomTable">
									<thead>
										<tr>
											<td>#</td>
											<td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsUsr checkbox" id="allItemsUsr" (click)="checkAllItemsUsr($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
											<td>{{ "lang_human_resources_id_number" | language }}</td>
											<td>{{ "lang_human_resources_nameen" | language }}</td>
                                            <td>{{ "lang_human_resources_namear" | language }}</td>
											<td>{{ "lang_writer" | language }}</td>
											<td>{{ "lang_action" | language }}</td>
										 
										</tr>
									</thead>
									<tbody *ngIf="listData?.records?.length != '0'" class="AppendList">
										<tr
											*ngFor="
												let list of listData?.records;
												let i = index;
												let odd = odd;
												let even = even
											"
											[ngClass]="{ odd: odd, even: even }"
											class="{{ list.row_color }}{{ list.row_color_flash }}
											makeback removerow{{list.mention_id}}
											"
										>
											<td>{{ i + 1 }}</td>
											<td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemUsr checkbox" value="{{list.shift_id}}" (click)="checkEachItemUsr($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{ list.shift_id }}</td>
                                            <td>{{ list.shift_title_english }}</td>
                                            <td>{{ list.shift_title_arabic }}</td>
                                            <td>{{ list.writer}}</td>
                                          
											<td>
												<mat-icon
												    (click)="openModal(list)"
													matTooltip="{{ 'lang_update' | language }}"
													class="mnabricon"
													>edit</mat-icon
												>
												<mat-icon
												routerLink="/human_resources/attendances/add-shift/{{list.shift_id}}"
												matTooltip="{{ 'lang_show_shift_times' | language }}"
												class="mnabricon"
												>schedule</mat-icon
											>
												
												<mat-icon
												 
													matTooltip="{{ 'lang_delete' | language }}"
													class="mnabricon red"
													(click)="deleteSingleData(list.shift_id)"
													>delete</mat-icon
												>
											</td>
										</tr>
									</tbody>
									<tbody *ngIf="loading">
										<tr>
											<td colspan="14" class="nodata" align="center">
												<ngx-skeleton-loader  count="5" [theme]="{ 'border-radius': '3px', height: '50px' }"></ngx-skeleton-loader>
											</td>
										</tr>
									</tbody>
									<tbody *ngIf="listData?.records?.length == '0' && !loading" class="AppendList">
										<tr class="odd">
											<td colspan="14" class="nodata" align="center">
												{{ nodata }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="listData?.records?.length != '0'" class="card-footer padb0">
					<div class="row responsive_pagination">
						<div  class="deleteIconDiv none col-lg-1 col-sm-1  mb-10" style="position: relative;top: 10px;">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkData()" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
						<div class="col-lg-3 col-sm-3  mb-10" style="height: fit-content;margin: auto 0;">
							{{ "lang_total" | language }} : {{ listData?.page?.total_records }}
						</div>
						<div class="col-lg-5 col-sm-5 mb-10" style="display: flex;justify-content: center;" >
							<ngb-pagination class="m-auto" 
							[collectionSize]="listData?.page?.total_records" 
							[rotate]="true" [ellipses]="false" [maxSize]="3" 
							[boundaryLinks]="true" [(page)]="page_no" 
							[pageSize]="pagesize" (pageChange)="loadPageFrm($event)"></ngb-pagination>

						<!-- 	<mat-radio-group aria-label="Select an option">
								<mat-radio-button
									value="10"
									[checked]="pagesize == '10' ? true : false"
									(click)="loadFrmPagesize(10)"
								>
									10</mat-radio-button
								>
								<mat-radio-button
									value="20"
									[checked]="pagesize == '20' ? true : false"
									(click)="loadFrmPagesize(20)"
								>
									20
								</mat-radio-button>
								<mat-radio-button
									value="50"
									[checked]="pagesize == '50' ? true : false"
									(click)="loadFrmPagesize(50)"
								>
									50
								</mat-radio-button>
								<mat-radio-button
									value="100"
									[checked]="pagesize == '100' ? true : false"
									(click)="loadFrmPagesize(100)"
								>
									100
								</mat-radio-button>
								<mat-radio-button
									value="500"
									[checked]="pagesize == '500' ? true : false"
									(click)="loadFrmPagesize(500)"
								>
									500
								</mat-radio-button>
							</mat-radio-group> -->
						</div>
						<div class="col-lg-3 col-sm-3" style="display: flex;justify-content: end;margin: auto;">
						<!-- 	{{ "lang_goto" | language }} :
							<select
								class="customselect"
								(change)="loadPageFrm($event.target.value)"
							>
								<option
									*ngFor="let ks of ds.getrange(listData?.page?.total_pages)"
									[selected]="pageno == ks ? true : false"
									value="{{ ks }}"
								>
									{{ ks }}
								</option>
							</select> -->
							<mat-select  style="max-width: 150px;"  [(ngModel)]="pagesize" (selectionChange)="loadFrmPagesize($event.value)">
                             
                                <!-- <mat-option value="1">1  </mat-option> -->
                                <mat-option class="text-center" value="10">10 {{'lang_per_page'|language}} </mat-option>
								<mat-option class="text-center" value="20">20 {{'lang_per_page'|language}}  </mat-option>
								<mat-option class="text-center" value="50">50 {{'lang_per_page'|language}}  </mat-option>
								<mat-option class="text-center" value="100">100 {{'lang_per_page'|language}}</mat-option>
								<mat-option class="text-center" value="500">500 {{'lang_per_page'|language}}</mat-option>
							  </mat-select>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="row col-md-12  attendence mb-4">
		<div class="col-md-3">
			<button type="button" routerLink="/human_resources/attendances"  class="albutton mnabrbutton mat-accent" mat-button>{{'lang_previous' | language }}</button>
		</div>
	</div>
</section>
