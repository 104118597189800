import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { CostCenterViewDialogComponent } from "../../cost-center-view-dialog/cost-center-view-dialog.component";

@Component({
  selector: "app-invoice-claim-view",
  templateUrl: "./invoice-claim-view.component.html",
  styleUrls: ["./invoice-claim-view.component.scss"],
})
export class InvoiceClaimViewComponent implements OnInit, OnDestroy {
  public branch_id = this.route.snapshot.paramMap.get("bid");
  public invoice_id = this.route.snapshot.paramMap.get("iid");
  public finance_id = this.route.snapshot.paramMap.get("fid");
  viewdata_status: boolean = false;
  showfield: boolean = false;
  transactions: any = [];
  items: any;
  InvData: any = [];
  viewdata: any = [];
  constructor(
    public ds: DataService,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    public router: Router,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  ngOnInit(): void {
    this.getdata_forview();
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public getdata_forview() {
    this.spinner.show();
    let formData = new FormData();
    formData.append("finance_id", this.finance_id);
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(formData),
          "viewsinvoiceclaim/" + this.branch_id + "/" + this.invoice_id
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.viewdata_status = true;
              this.InvData = res;
              this.viewdata = res.records;
              this.items = res.invoice_items;
              this.transactions = res.transactions;
            } else {
              this.viewdata_status = false;
              this.ds.dialogf("", res.error);
              this.router.navigate(["accounts/list_invoice_claims/"]);
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }

  showCostCenter(data) {
    this.spinner.show();
    this.ds
      .get(
        `acc/get_cost_center_transactions/${data?.transaction_id}/${this.invoice_id}/${data?.transaction_history_id}`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            const dialogRef = this.dialog.open(CostCenterViewDialogComponent, {
              width: "700px",
              autoFocus: false,
              data: res?.records,
            });
          } else {
            this.sweetAlert.errorToast(res?.error, 3000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
  }

  public invcalc(item: any) {
    return (
      item.invoice_item_quantity * item.invoice_item_price -
      parseFloat(item.invoice_item_disc_amount) +
      parseFloat(item.invoice_item_tax_amount)
    );
  }
}
