import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
interface list {
  id?: number;
  transaction_request_id: string;
  transactions_records_datetime: string;
  transactions_records_id: string;
  transactions_records_note: string;
  transactions_records_user_id: string;
  transactions_records_user_name: string;
}
@Component({
  selector: "app-formc2-view",
  templateUrl: "./formc2-view.component.html",
  styleUrls: ["./formc2-view.component.scss"],
})
export class Formc2ViewComponent implements OnInit {
  @Input() form_key;
  @Input() form_request_id;
  @Input() actionPage;
  apiurl = environment.SERVER_ORIGIN;
  admin = JSON.parse(this.ls.getItem("is_admin"));
  viewdata: any = [];
  files: any = [];
  dates: any = [];
  notes: any = [];
  persons: any = [];
  history: any = [];
  request: any = [];
  @ViewChild("contentData", { static: true }) ss;
  barcode;
  submitter;
  actionbuttons: any = [];
  verifylist: any = [];
  //verification
  sendstatus: boolean = false;
  sendlist: any = [];
  verifystatus: boolean = false;
  verifyerrormsg: any = "";
  nodata: string = this.lang.transform("lang_loading");
  sendcodestatus = false;
  codemsg: string = this.lang.transform("lang_send_code");
  form: FormGroup;
  approve: FormGroup;
  listofdatas: list[];
  page = 1;
  pageSize = 10;
  collectionSize;
  extra_data;
  module_key: string;
  contractor_id: string;
  customer_id: string;
  employee_number: string;
  projects_profile_id: string;
  projects_work_area_id: string;
  url_of_content_text: string;
  modalRef: BsModalRef;
  content_text: string;
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService,
    public spinner: PreloaderService,
    public alert: AlertService,
    public router: Router,
    public dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.get_data_view();
    this.buildform();
    this.approveform();
  }
  buildform() {
    this.form = new FormGroup({
      transaction_request_id: new FormControl(this.form_request_id, [
        Validators.required,
      ]),
      transaction_persons_type: new FormControl("", [Validators.required]),
      sender_type: new FormControl("", [Validators.required]),
      do: new FormControl("", [Validators.required]),
      transactions_persons_action_code: new FormControl("", [
        Validators.required,
      ]),
      transactions_persons_last_step: new FormControl(""),
    });
  }
  approveform() {
    this.approve = new FormGroup({
      transaction_request_id: new FormControl(this.form_request_id, [
        Validators.required,
      ]),
      user_pass: new FormControl("", [Validators.required]),
    });
  }
  public get_data_view() {
    this.viewdata = [];
    this.ds
      .getActionByUrl(
        [],
        "form/" + this.form_key + "/vr/" + this.form_request_id
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.viewdata = res.form_c2_data.status
              ? res.form_c2_data.records[0]
              : [];
            if (this.viewdata.content) {
              const contentData = this.viewdata.content.replace(/\\"/g, '"');
              this.ss.nativeElement.innerHTML = contentData;
            }
            this.files = res.form_c2_files.status
              ? res.form_c2_files.records
              : [];
            this.dates = res.transactions_date.status
              ? res.transactions_date.records
              : [];
            this.notes = res.transactions_notes.status
              ? res.transactions_notes.records
              : [];
            this.persons = res.transactions_persons.status
              ? res.transactions_persons.records
              : [];
            this.request = res.transactions_request
              ? res.transactions_request
              : [];
            this.submitter = res.transactions_submitter.status
              ? res.transactions_submitter.records
              : "";
            this.actionbuttons = res.transactions_buttons
              ? res.transactions_buttons
              : [];
            this.barcode = res.transactions_barcode_version.status
              ? res.transactions_barcode_version.records
              : "";
            this.extra_data = res.transactions_extra.status
              ? res.transactions_extra.records
              : [];
            let his = res.transactions_records.status
              ? res.transactions_records.records
              : [];
            this.listofdatas = his;
            this.collectionSize = his.length;
            this.refreshLists();
            for (let item of this.extra_data) {
              if (item?.extra_custom_key == "module_key") {
                this.module_key = item?.extra_custom_val;
              }
              if (item?.extra_custom_key == "contractor_id") {
                this.contractor_id = item?.extra_custom_val;
              }
              if (item?.extra_custom_key == "customer_id") {
                this.customer_id = item?.extra_custom_val;
              }
              if (item?.extra_custom_key == "projects_work_area_id") {
                this.projects_work_area_id = item?.extra_custom_val;
              }
              if (item?.extra_custom_key == "projects_profile_id") {
                this.projects_profile_id = item?.extra_custom_val;
              }
              if (item?.extra_custom_key == "employee_number") {
                this.employee_number = item?.extra_custom_val;
              }
              if (item?.extra_custom_key == "url_of_content_text") {
                this.url_of_content_text = item?.extra_custom_val;
              }
            }
          } else {
            this.ds.dialogf("", res.error);
            this.router.navigate(["transactions/allforms/"]);
          }
        },
        (error) => {
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  showverificationform(transaction_persons_type) {
    this.spinner.show();
    this.form.reset();
    this.sendstatus = false;
    this.sendcodestatus = false;
    this.verifyerrormsg = "";
    this.form.get("transaction_request_id").setValue(this.form_request_id);
    this.form
      .get("transaction_persons_type")
      .setValue(transaction_persons_type);
    this.form
      .get("transactions_persons_last_step")
      .setValue(this.request.transaction_request_last_step);

    if (transaction_persons_type === "doall") {
      this.form.get("do").setValue("do_all");
      this.form.get("transaction_persons_type").setValue("");
    } else {
      this.form.get("do").setValue("do");
    }
    this.verifylist = [];
    this.ds.getActionByUrl([], "tc/sender/select").subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.verifylist = res.data;
        } else {
          this.ds.dialogf("", res.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.ds.dialogf(
          "",
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  sendVerificationCode() {
    this.spinner.show();
    this.ds
      .postActionByUrl(
        this.ds.json2formdata(this.form.value),
        "tc/sender/send_code"
      )
      .subscribe(
        (data) => {
          this.sendcodestatus = data.status;
          this.spinner.hide();
          if (data.status) {
            this.sendstatus = true;
            this.alert.success(data.msg);
            setTimeout(() => {
              this.sendcodestatus = false;
            }, 180000);
          } else {
            this.alert.error(data.error);
            this.sendstatus = false;
          }
        },
        (error) => {
          this.sendcodestatus = false;
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
          this.sendstatus = false;
        }
      );
  }
  confirmVerification() {
    this.spinner.show();
    this.ds
      .postActionByUrl(
        this.ds.json2formdata(this.form.value),
        "tc/sender/complete_action"
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.alert.success(this.lang.transform("lang_success"));
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            this.alert.error(data.error);
            this.verifyerrormsg = data.error;
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  are_you_sure(form_key, form_request_id) {
    if (form_key && form_request_id) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true,
        width: "400px",
        data: {
          title: this.lang.transform("lang_confirmation"),
          message: this.lang.transform("lang_edit_form_info"),
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router.navigate([
            "transactions/form/" + form_key + "/er/" + form_request_id,
          ]);
        }
      });
    }
  }
  refreshLists() {
    this.history = this.listofdatas
      .map((list, i) => ({ id: i + 1, ...list }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }
  preview_form_data() {
    //this.ds.preview_pdf_file('form/FORM_HRV1/pr/32');
    this.ds.preview_pdf_file(
      "form/" + this.form_key + "/pr/" + this.form_request_id,
      this.form_key + "-" + this.form_request_id
    );
  }
  empty_approve_form() {
    this.approve.reset();
    this.approve.get("transaction_request_id").setValue(this.form_request_id);
  }
  approve_form() {
    this.spinner.show();
    this.ds
      .postActionByUrl(
        this.ds.json2formdata(this.approve.value),
        "form/" + this.form_key + "/cm"
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.ds.dialogf("", data.msg);
            this.approve.reset();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            this.ds.dialogf("", data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  delete_form_request(form_key, form_request_id) {
    if (form_key && form_request_id && this.admin) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true,
        width: "400px",
        data: {
          title: this.lang.transform("lang_confirmation"),
          message: this.lang.transform("lang_are_you_sure"),
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          let param = new FormData();
          param.append("transaction_request_id", form_request_id);
          this.ds.postActionByUrl(param, "form/" + form_key + "/dr").subscribe(
            (res) => {
              this.spinner.hide();
              if (res.status) {
                this.ds.dialogf("", res.msg);
                this.router.navigate(["transactions/allforms"]);
              } else {
                this.ds.dialogf("", res.error);
              }
            },
            (error) => {
              this.spinner.hide();
              this.ds.dialogf(
                "",
                error && error.error && error.error.error
                  ? error.error.error
                  : this.lang.transform("lang_internal_server_error")
              );
            }
          );
        }
      });
    }
  }
  able_to_show(user_type) {
    const returndata = this.persons.find(
      (x) =>
        x.transaction_persons_type == user_type &&
        x.transactions_persons_key1 == null &&
        x.transactions_persons_val1 == null
    );
    if (returndata === undefined) {
      return false;
    } else {
      return true;
    }
  }

  onShowContentText(template: TemplateRef<any>) {
    this.spinner.show();
    this.ds.get(this.url_of_content_text).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.content_text = this.decodeBase64(res?.base64);
          this.content_text = this.content_text.replace(/\\"/g, '"');
          this.modalRef = this.modalService.show(
            template,
            environment.modelconfig
          );
        } else {
          this.sweetAlert.errorToast(res?.error, 3000);
        }
      },
      (err) => {
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_error_server"),
          3000
        );
      }
    );
  }
  onCopyText() {
    let copyTextarea = this.viewdata.content;
    let successful = document.execCommand("copy");
    navigator.clipboard.writeText(copyTextarea);
    if (successful) {
      this.sweetAlert.successToast(this.lang.transform("lang_success"), 3000);
    } else {
      this.sweetAlert.errorToast(this.lang.transform("lang_faild"), 3000);
    }
  }
  decodeBase64(str) {
    return decodeURIComponent(
      atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }
}
