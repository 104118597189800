import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import moment from "moment";
@Component({
  selector: "app-select-employee",
  templateUrl: "./select-employee.component.html",
  styleUrls: ["./select-employee.component.scss"],
})
export class SelectEmployeeComponent implements OnInit {
  form_request_id = this.route.snapshot.paramMap.get("form_request_id");
  constructor(
    public dialogRef: MatDialogRef<SelectEmployeeComponent>,
    public ds: DataService,
    public route: ActivatedRoute,
    private spinner: PreloaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  // searchForm = this.fb.group({
  //   employee_id: [""],
  //   employee_name: [""],
  //   project_id: [[]],
  //   branch_id: [[]],
  // });
  public searchForm: FormGroup;
  user_list = [];
  lang_key = localStorage.getItem("lang_key") || "en";

  reasonFilterType = "absent";

  reasonFromToDate: FormGroup = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });

  ngOnInit(): void {
    this.build_form();
    this.projects = this.data.projects;

    this.branches = this.data.branches;
    this.usersList = this.data.usersList;
    this.getEmployeeData();
  }

  projects: any = [];
  branches: any = [];
  employeesSuggested = [];
  employeesSuggestedfiltered = [];
  total = 0;
  size = 10;
  page = 1;
  /* employeesByNames = []
  employeesByProjects = []
  employeesByBranches = [] */

  load_by_page_no(page) {
    this.page = page;
    this.getEmployeeData();
  }
  load_by_page_size(size) {
    this.size = size;
    this.page = 1;
    this.getEmployeeData();
  }

  getEmployeeData(filters: any = this.searchForm.value) {
    this.spinner.show();
    let formData = new FormData();
    formData.append("filter_type", this.reasonFilterType);
    formData.append(
      "from_date",
      this.reasonFromToDate?.get("start")?.value
        ? moment(this.reasonFromToDate?.get("start")?.value).format(
            "YYYY/MM/DD"
          )
        : ""
    );
    formData.append(
      "to_date",
      this.reasonFromToDate?.get("end")?.value
        ? moment(this.reasonFromToDate?.get("end")?.value).format("YYYY/MM/DD")
        : ""
    );
    formData.append("page_no", `${this.page}`);
    formData.append("page_size", `${this.size}`);
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `at/lists_select_employess`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.employeesSuggested = res.records;
            this.employeesSuggestedfiltered = res.records;
            this.total = res?.page?.total_records;
          } else {
            this.spinner.hide();
            this.employeesSuggested = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.employeesSuggested = [];
        }
      );
  }

  usersList: any[] = [];

  public build_form() {
    this.searchForm = new FormGroup({
      employee_id: new FormControl([""]),
      employee_name: new FormControl([""]),
      project_id: new FormControl([[]]),
      reasonFilterType: new FormControl(["absent"]),
    });
  }
  addEmp(e, i) {
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      let inTheArr = false;
      for (let i = 0; i < this.usersList.length; i++) {
        const e = this.usersList[i];
        if (e.employee_number == element.employee_number) {
          inTheArr = true;
        }
      }
      if (!inTheArr) {
        this.usersList.push(element);
        this.employeesSuggested.splice(i, 1);
      } else {
        this.employeesSuggested.splice(i, 1);
      }
    }
    /* this.searchForm.reset() */
  }

  removeAllSelected(employees) {
    for (let index = 0; index < employees.length; index++) {
      const element = employees[index];
      let inTheArr = false;
      for (let i = 0; i < this.employeesSuggested.length; i++) {
        const e = this.employeesSuggested[i];
        if (e.employee_number == element.employee_number) {
          inTheArr = true;
        }
      }
      if (!inTheArr) {
        this.employeesSuggested.push(element);
      }
    }
    this.usersList = [];
    /* this.searchForm.reset() */
  }

  addAllSuggested(employees) {
    for (let index = 0; index < employees.length; index++) {
      const element = employees[index];
      let inTheArr = false;
      for (let i = 0; i < this.usersList.length; i++) {
        const e = this.usersList[i];
        if (e.employee_number == element.employee_number) {
          inTheArr = true;
        }
      }
      if (!inTheArr) {
        this.usersList.push(element);
      }
    }
    this.employeesSuggested = [];
    /* this.searchForm.reset() */
  }
  removeEmp(e, i) {
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      let inTheArr = false;
      for (let i = 0; i < this.employeesSuggested.length; i++) {
        const e = this.employeesSuggested[i];
        if (e.employee_number == element.employee_number) {
          inTheArr = true;
        }
      }
      if (!inTheArr) {
        this.employeesSuggested.push(element);
        this.usersList.splice(i, 1);
      } else {
        this.usersList.splice(i, 1);
      }
    }
    /* this.searchForm.reset() */
  }

  save() {
    this.dialogRef.close({
      new: this.usersList,
    });
  }
  cancel() {
    this.dialogRef.close();
  }
  sortArray() {
    console.log(this.searchForm.value?.employee_id);
    if (
      this.searchForm.value?.employee_id != "" ||
      this.searchForm.value?.employee_name != ""
    ) {
      this.employeesSuggestedfiltered = [];
      this.employeesSuggested.forEach((element) => {
        const employee_name: string = this.searchForm.value?.employee_name;
        let employee_name_empty: boolean;
        if (this.searchForm.value?.employee_name != "") {
          employee_name_empty =
            element.employee_name
              .toUpperCase()
              .indexOf(employee_name.toUpperCase()) > -1;
        } else employee_name_empty = false;
        console.log(
          element.employee_number,
          this.searchForm.value?.employee_id
        );
        console.log(
          element.employee_name,
          this.searchForm.value?.employee_name
        );
        if (
          element.employee_number == this.searchForm.value?.employee_id ||
          employee_name_empty
        ) {
          this.employeesSuggestedfiltered = [
            ...this.employeesSuggestedfiltered,
            element,
          ];
        }
      });
    } else {
      this.employeesSuggestedfiltered = this.employeesSuggested;
    }
  }
}
