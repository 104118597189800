<div class="board">
	<div class="board-header">
		<div class="bh-left">
			<div class="bh-users ml-2 mr-2">
				<div
					class="bhu-user"
					[popover]="test"
					[outsideClick]="true"
					placement="bottom"
					*ngFor="let item of ticketUsersList | slice: 0:5"
					(onShown)="onShowUser(item)"
				>
					<img
						src="assets/images/avatar.png"
						alt="avatar"
						title="{{ item?.userName }}"
						*ngIf="!item?.profile_image_64"
					/>
					<img
						src="{{ item?.profile_image_64 }}"
						alt="avatar"
						title="{{ item?.userName }}"
						*ngIf="item?.profile_image_64"
					/>
				</div>
				<div
					class="bhu-user bhu-user-count"
					*ngIf="ticketUsersList?.length > 5"
					(click)="onShowTicketUsers()"
				>
					<span>+{{ ticketUsersList?.length - 5 }}</span>
				</div>
				<i
					class="pi pi-user-edit bh-users-edit"
					[ngClass]="{
						'ml-3 mr-3': lang_key == 'ar',
						'ml-2 mr-2': lang_key == 'en'
					}"
					(click)="openUsersModal()"
				></i>
			</div>
		</div>
		<div class="bh-center">
			<div
				class="bh-box-txt"
				matTooltip="{{ 'lang_ticket_number' | language }}"
			>
				<i
					class="fa fa-undo mnabricon mr-2 ml-2"
					routerLink="/tasks/view/{{ ticketID }}"
				></i>
				<h2>{{ ticketData?.ticket_no }}</h2>
			</div>
			<div class="bh-divider"></div>
			<div class="bh-box-txt" matTooltip="{{ 'lang_ticket_title' | language }}">
				<h2>{{ ticketData?.ticket_title }}</h2>
			</div>
			<i
				class="fa fa-info-circle mnabricon"
				style="margin: 0 10px"
				matTooltip="More Details"
				(click)="showTicketDetials()"
			></i>
		</div>
		<div class="bh-right">
			<span
				class="ticket-status greenbg"
				*ngIf="task_status_done == '2'"
				matTooltip="{{ 'lang_in_progress' | language }}"
				(click)="onChangeStatusDone('1')"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></span>
			<span
				class="ticket-status yellowbgcolor"
				*ngIf="task_status_done == '1'"
				matTooltip="{{ 'lang_pending' | language }}"
				(click)="onChangeStatusDone('3')"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></span>
			<span
				class="ticket-status redstatus"
				*ngIf="task_status_done == '3'"
				matTooltip="{{ 'lang_closed' | language }}"
				(click)="onChangeStatusDone('2')"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></span>
			<!-- actions -->
			<i
				class="pi pi-credit-card menu-icon mnabricon"
				matTooltip="{{ 'lang_view' | language }}"
				routerLink="/tasks/view/{{ ticketID }}"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></i>
			<i
				class="fa fa-list-ul menu-icon mnabricon"
				matTooltip="{{ 'lang_ticket_list' | language }}"
				routerLink="/tasks/alltasks"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></i>
			<i
				class="fa fa-edit menu-icon mnabricon"
				matTooltip="{{ 'lang_edit' | language }}"
				routerLink="/tasks/edit/{{ ticketID }}"
				*ngIf="ticketData?.is_ticket_admin"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></i>
			<i
				class="pi pi-minus-circle red"
				matTooltip="{{ 'lang_unfollow_ticket' | language }}"
				(click)="unfollowTicket()"
				*ngIf="ticketData?.is_delete == '1'"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></i>
			<i
				class="fa fa-inbox mnabricon"
				aria-hidden="true"
				matTooltip="{{ 'lang_make_template' | language }}"
				(click)="convertTicketToTemplate()"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></i>
			<i
				class="pi pi-copy mnabricon"
				aria-hidden="true"
				matTooltip="{{ 'lang_contracts' | language }}"
				(click)="showContracts()"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></i>
		</div>
	</div>
	<div>
		<div class="timeline-header">
			<div class="ticket_timeline_title mb-4">
				<svg
					width="24"
					height="24"
					role="presentation"
					focusable="false"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M4.5 4H11.5C12.8807 4 14 5.11929 14 6.5C14 7.88071 12.8807 9 11.5 9H4.5C3.11929 9 2 7.88071 2 6.5C2 5.11929 3.11929 4 4.5 4ZM11.5 7C11.7761 7 12 6.77614 12 6.5C12 6.22386 11.7761 6 11.5 6H4.5C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7H11.5Z"
						fill="#0e3768"
					></path>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M6.5 10H13.5C14.8807 10 16 11.1193 16 12.5C16 13.8807 14.8807 15 13.5 15H6.5C5.11929 15 4 13.8807 4 12.5C4 11.1193 5.11929 10 6.5 10ZM13.5 13C13.7761 13 14 12.7761 14 12.5C14 12.2239 13.7761 12 13.5 12H6.5C6.22386 12 6 12.2239 6 12.5C6 12.7761 6.22386 13 6.5 13H13.5Z"
						fill="#0e3768"
					></path>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M18.5 16H11.5C10.1193 16 9 17.1193 9 18.5C9 19.8807 10.1193 21 11.5 21H18.5C19.8807 21 21 19.8807 21 18.5C21 17.1193 19.8807 16 18.5 16ZM11.5 19C11.2239 19 11 18.7761 11 18.5C11 18.2239 11.2239 18 11.5 18H18.5C18.7761 18 19 18.2239 19 18.5C19 18.7761 18.7761 19 18.5 19H11.5Z"
						fill="#0e3768"
					></path>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M17.5 4H19.5C20.8807 4 22 5.11929 22 6.5C22 7.88071 20.8807 9 19.5 9H17.5C16.1193 9 15 7.88071 15 6.5C15 5.11929 16.1193 4 17.5 4ZM19.5 7C19.7761 7 20 6.77614 20 6.5C20 6.22386 19.7761 6 19.5 6H17.5C17.2239 6 17 6.22386 17 6.5C17 6.77614 17.2239 7 17.5 7H19.5Z"
						fill="#0e3768"
					></path>
				</svg>
				<h2>{{ "lang_ticket_timeline" | language }}</h2>
			</div>
		</div>
		<div class="row pb-3">
			<div
				class="col-12 col-sm-3 col-md-2 mb-2 mb-sm-0"
				style="align-self: center"
			>
				<mat-select
					(selectionChange)="changeView()"
					[(ngModel)]="view"
					class="view-select"
				>
					<mat-option value="day">{{ "lang_day" | language }}</mat-option>
					<mat-option value="week">{{ "lang_week" | language }}</mat-option>
					<mat-option value="month">{{ "lang_month" | language }}</mat-option>
					<mat-option value="year" *ngIf="currentView == 'calendar'">{{
						"lang_year" | language
					}}</mat-option>
				</mat-select>
			</div>
			<div
				class="col-12 col-sm-3 col-md-2 mb-2 mb-sm-0"
				style="align-self: center"
			>
				<mat-select
					(selectionChange)="changeType()"
					[(ngModel)]="resource_type"
					class="resource-type-select"
				>
					<mat-option value="status">{{ "lang_status" | language }}</mat-option>
					<mat-option value="members">{{ "lang_users" | language }}</mat-option>
					<mat-option value="gantt" *ngIf="isTimeline">{{
						"lang_gantt" | language
					}}</mat-option>
				</mat-select>
			</div>
			<div
				class="col-12 col-sm-3 col-md-2 mb-2 mb-sm-0"
				style="align-self: center"
			>
				<mat-select [(ngModel)]="myTheme" class="theme-select">
					<mat-option value="ios">{{ "lang_theme_ios" | language }}</mat-option>
					<mat-option value="windows">{{
						"lang_theme_windows" | language
					}}</mat-option>
					<mat-option value="material">{{
						"lang_theme_material" | language
					}}</mat-option>
				</mat-select>
			</div>
			<div class="col-12 col-sm-3">
				<ng-select
					[items]="ticketUsers"
					[multiple]="true"
					appendTo="body"
					bindLabel="emp_name"
					bindValue="emp_id"
					clearAllText="Clear"
					placeholder="{{ 'lang_users' | language }}"
					[(ngModel)]="filter_user"
					(change)="changeUsers()"
					*ngIf="resource_type == 'members'"
				>
				</ng-select>
			</div>
		</div>
		<div class="md-event-listing mb-3">
			<mbsc-eventcalendar
				[data]="myEvents"
				[connections]="connections"
				[options]="calendarOptions"
				[themeVariant]="'light'"
				[theme]="myTheme"
				[view]="calCalendarView"
				[locale]="localeAll[lang_key]"
				[rtl]="lang_key == 'ar' ? true : false"
				[calendarSystem]="lang_key == 'ar' ? hijri : null"
				[resources]="myResources"
				[headerTemplate]="myHeaderTemplate"
				[resourceHeaderTemplate]="resourceHeaderTemp"
				[dragToMove]="true"
				[dragToResize]="true"
				[(selectedDate)]="currentDate"
				[(selectedEvents)]="mySelectedEvent"
			>
				<ng-template #resourceHeaderTemp>
					<div>
						<span *ngIf="resource_type == 'status'">{{
							"lang_status" | language
						}}</span>
						<span *ngIf="resource_type == 'members'">{{
							"lang_users" | language
						}}</span>
						<span *ngIf="resource_type == 'gantt'">{{
							"lang_gantt" | language
						}}</span>
					</div>
				</ng-template>
				<ng-template #myHeaderTemplate>
					<mbsc-calendar-nav class="md-event-listing-nav"></mbsc-calendar-nav>
					<div class="md-event-listing-picker">
						<div class="timeline-calendar-toggle">
							<mat-slide-toggle
								class=""
								[color]="'primary'"
								[(ngModel)]="isTimeline"
								(change)="changeCalendarTimeline($event)"
							>
								{{ "lang_timeline" | language }}
							</mat-slide-toggle>
						</div>
					</div>
					<div class="md-seach-header-bar mbsc-flex-1-0">
						<mbsc-input
							#searchInput
							(input)="onSearch($event)"
							startIcon="material-search"
							inputStyle="box"
							placeholder="Search events"
						></mbsc-input>
					</div>
					<mbsc-calendar-prev
						class="md-event-listing-prev"
					></mbsc-calendar-prev>
					<mbsc-calendar-today
						class="md-event-listing-today"
					></mbsc-calendar-today>
					<mbsc-calendar-next
						class="md-event-listing-next"
					></mbsc-calendar-next>
				</ng-template>
				<mbsc-popup
					class="md-tooltip"
					#popup
					[anchor]="anchor"
					[options]="popupOptions"
				>
					<div
						(mouseleave)="mouseLeave()"
						[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
					>
						<div
							class="md-tooltip-header"
							[ngStyle]="{
								background: '#ddd',
								color: currentEvent?.type == '2' ? 'black' : '#fff'
							}"
						>
							<span class="md-tooltip-name-age"
								>{{ currentEvent?.task_no }} - {{ currentEvent?.title }}</span
							>
						</div>
						<div class="md-tooltip-info">
							<div class="md-tooltip-title">
								{{ "lang_ticket_title" | language }}:
								<span class="md-tooltip-status md-tooltip-text">{{
									currentEvent?.ticket_title
								}}</span>
							</div>
							<hr />
							<div class="md-tooltip-title">
								{{ "lang_ticket" | language }}
								{{ "lang_start_date" | language }}:
								<span class="md-tooltip-reason md-tooltip-text">{{
									currentEvent?.start_date_ticket
								}}</span>
							</div>
							<div class="md-tooltip-title">
								{{ "lang_ticket" | language }}
								{{ "lang_end_date" | language }}:
								<span class="md-tooltip-reason md-tooltip-text">{{
									currentEvent?.end_date_ticket
								}}</span>
							</div>
							<hr />
							<div class="md-tooltip-title">
								{{ "lang_task" | language }}
								{{ "lang_start_date" | language }}:
								<span class="md-tooltip-reason md-tooltip-text">{{
									currentEvent?.start_date_task
								}}</span>
							</div>
							<div class="md-tooltip-title">
								{{ "lang_task" | language }}
								{{ "lang_end_date" | language }}:
								<span class="md-tooltip-reason md-tooltip-text">{{
									currentEvent?.end_date_task
								}}</span>
							</div>
							<hr />
							<div class="md-tooltip-title">
								{{ "lang_task" | language }} {{ "lang_progress" | language }}:
								<span class="md-tooltip-reason md-tooltip-text">{{
									currentEvent?.task_progress
								}}</span>
							</div>
						</div>
					</div>
				</mbsc-popup>
				<mbsc-popup
					[options]="popupOptions"
					class="md-search-popup"
					[anchor]="searchInput"
					[focusElm]="searchInput"
					#popupSearch
					(onInit)="initPopup()"
				>
					<mbsc-eventcalendar
						[data]="listEvents"
						[view]="listView"
						[showControls]="false"
						class="mbsc-popover-list"
						(onEventClick)="eventClick($event)"
					></mbsc-eventcalendar>
				</mbsc-popup>
			</mbsc-eventcalendar>
		</div>
	</div>
</div>

<!-- user template popover -->
<ng-template #test>
	<div class="user-popover">
		<div class="up-header">
			<div class="up-header-content">
				<div class="up-header-img">
					<img src="assets/images/avatar.png" alt="" />
				</div>
				<div class="up-header-details">
					<h3>{{ user_popover.first_name }} {{ user_popover.last_name }}</h3>
				</div>
			</div>
		</div>
		<div class="up-body"></div>
	</div>
</ng-template>
