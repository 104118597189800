import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

import { Location } from "@angular/common";
import moment from "moment";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { environment } from "@env/environment";
import { Subscription } from "rxjs";

declare var $: any;
@Component({
  selector: "app-formhrv1-add",
  templateUrl: "./formhrv1-add.component.html",
  styleUrls: ["./formhrv1-add.component.scss"],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "en-EN" }],
})
export class Formhrv1AddComponent implements OnInit {
  @Output() vacationEvent = new EventEmitter();
  @Input() employee_data;
  modalRefV: BsModalRef;
  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
  @Input() branch_id;
  vacations: any = [];
  financial_details_list = [{}];
  createVactionForm: FormGroup;
  selectedCity: any;
  markstatus: boolean = false;
  reviewstatus: boolean = false;
  attachstatus: boolean = false;
  attachments: FormArray;
  filename = this.lang.transform("lang_select_files");
  isFile: boolean = false;
  users: any = [];
  employee_number = "";
  contract_id = "";
  vacation_paid_days_from_contract = "";
  showOtherVacation = false;
  showAttachment = false;
  financial_details = [];
  net_amount;
  details = [];
  lang_key =
    localStorage.getItem("lang_key") == null
      ? "en"
      : localStorage.getItem("lang_key");
  direct_anager = [];
  constructor(
    private sweetAlert: SweetAlertService,
    public router: Router,
    private route: ActivatedRoute,
    private Location: Location,
    public ds: DataService,
    public spinner: PreloaderService,
    public lang: LanguagePipe,
    private fb: FormBuilder,
    private alert: AlertService,
    private snackBar: MatSnackBar,
    public modalService: BsModalService
  ) {}
  openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  ngOnInit(): void {
    moment.locale("en");
    if (!this.employee_data) {
      this.id = this.route.snapshot.paramMap.get("id");
      this.branch_id = this.route.snapshot.paramMap.get("bid");
      this.spinner.show();
      this.get_view_details();
      this.initForm();
    } else {
      this.initForm();
      this.getEmployeeInfo();
      this.getVacationType();
    }
  }
  get_view_details(load: boolean = false) {
    this.ds
      .getActionByUrl(
        [],
        "human_resources/get_myview_data/" + this.id + "/" + this.branch_id
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.employee_data = data.data;

            this.getEmployeeInfo();
            this.getVacationType();
          } else {
            this.employee_data = "";
            this.ds.dialogf("", data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.employee_data = "";
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }

  initForm() {
    this.createVactionForm = this.fb.group({
      vacation_type_id: [null, Validators.required],
      before_vacation_working_date_english: [null, Validators.required],
      before_vacation_working_date_arabic: [null, Validators.required],
      after_vacation_working_date_english: [null, Validators.required],
      after_vacation_working_date_arabic: [null, Validators.required],
      vacation_other: [null, this.showOtherVacation ? Validators.required : []],
      vacation_start_date_english: [null, Validators.required],
      vacation_start_date_arabic: [null, Validators.required],
      vacation_end_date_english: [null, Validators.required],
      vacation_end_date_arabic: [null, Validators.required],
      contract_id: [null, Validators.required],
      vacation_total_days: [null, Validators.required],
      vacation_paid_days_from_contract: [null, Validators.required],
      vacation_total_paid_days: [null, Validators.required],
      paid_days75: [null, Validators.required],
      paid_days100: [null, Validators.required],
      paid_days75_amount: [null, Validators.required],
      paid_days100_amount: [null, Validators.required],
      sick_balance: [null, Validators.required],
      vacation_total_unpaid_days: [null, Validators.required],
      vacation_total_paid_amount: [null, Validators.required],
      direct_manager: [
        { value: null, disabled: this.direct_anager.length > 0 },
        Validators.required,
      ],

      needAttach: new FormControl("0", [Validators.required]),

      attachments: this.fb.array(
        [],
        this.showAttachment ? [Validators.required] : []
      ),
    });
    this.attachments = this.createVactionForm.get("attachments") as FormArray;
  }

  get getAttachments() {
    return this.createVactionForm.get("attachments");
  }
  getVacationType() {
    let data = new FormData();
    data.append("employee_number", this.employee_data.employee_number);
    this.ds.post("form/FORM_HRV1/get_vacation_type/", data).subscribe((res) => {
      if (res.status) {
        this.vacations = res.records;
      }
    });
  }

  back() {
    if (this.Location.path().includes("profile/create-vacation")) {
      this.Location.back();
    } else {
      this.vacationEvent.emit("EVIEW");
    }
  }
  isValidForm = false;
  isSickCase = false;
  yearContractVacationInfo = "";
  checkVacationEmployee() {
    this.isValidForm = false;

    if (
      this.createVactionForm.get("vacation_type_id").valid &&
      this.createVactionForm.get("before_vacation_working_date_english")
        .valid &&
      this.createVactionForm.get("after_vacation_working_date_english").valid
    ) {
      let formData = new FormData();
      formData.append("employee_number", this.employee_data.employee_number);
      if (this.createVactionForm.get("vacation_type_id").value == 3) {
        this.isSickCase = true;
      } else {
        this.isSickCase = false;
      }
      formData.append(
        "vacation_type_id",
        this.createVactionForm.get("vacation_type_id").value
      );
      formData.append(
        "before_vacation_working_date_english",
        moment(
          this.createVactionForm.get("before_vacation_working_date_english")
            .value
        ).format("YYYY/MM/DD")
      );
      formData.append(
        "after_vacation_working_date_english",
        moment(
          this.createVactionForm.get("after_vacation_working_date_english")
            .value
        ).format("YYYY/MM/DD")
      );
      this.ds
        .post("form/FORM_HRV1/check_vacation_for_employee", formData)
        .subscribe(
          (res) => {
            if (res.status) {
              this.yearContractVacationInfo = res?.result;
              if (this.createVactionForm.get("vacation_type_id").value == "3") {
                this.createVactionForm
                  .get("paid_days75")
                  .setValue(res?.result?.paid_days75);
                this.createVactionForm
                  .get("paid_days100")
                  .setValue(res?.result?.paid_days100);
                this.createVactionForm
                  .get("paid_days75_amount")
                  .setValue(res?.result?.paid_days75_amount);
                this.createVactionForm
                  .get("paid_days100_amount")
                  .setValue(res?.result?.paid_days100_amount);
                this.createVactionForm
                  .get("sick_balance")
                  .setValue(res?.result?.sick_balance);
                this.createVactionForm
                  .get("vacation_total_paid_amount")
                  .clearValidators();
                this.createVactionForm
                  .get("vacation_total_paid_amount")
                  .updateValueAndValidity();
                this.createVactionForm
                  .get("vacation_total_paid_days")
                  .clearValidators();
                this.createVactionForm
                  .get("vacation_total_paid_days")
                  .updateValueAndValidity();
              } else {
                this.createVactionForm
                  .get("vacation_total_paid_amount")
                  .setValue(
                    res?.result?.paid_amount
                      ? res?.result?.paid_amount
                      : res?.result?.paid_amount22
                      ? res?.result?.paid_amount22
                      : 0
                  );
                this.createVactionForm
                  .get("vacation_total_paid_days")
                  .setValue(res?.result?.paid_days);
                this.createVactionForm.get("paid_days75").clearValidators();
                this.createVactionForm.get("paid_days100").clearValidators();
                this.createVactionForm
                  .get("paid_days75_amount")
                  .clearValidators();
                this.createVactionForm
                  .get("paid_days100_amount")
                  .clearValidators();
                this.createVactionForm.get("sick_balance").clearValidators();
                this.createVactionForm
                  .get("paid_days75")
                  .updateValueAndValidity();
                this.createVactionForm
                  .get("paid_days100")
                  .updateValueAndValidity();
                this.createVactionForm
                  .get("paid_days75_amount")
                  .updateValueAndValidity();
                this.createVactionForm
                  .get("paid_days100_amount")
                  .updateValueAndValidity();
                this.createVactionForm
                  .get("sick_balance")
                  .updateValueAndValidity();
              }
              this.createVactionForm
                .get("vacation_total_unpaid_days")
                .setValue(res?.result?.unpaid_days);
              this.createVactionForm
                .get("vacation_total_days")
                .setValue(res?.result?.vacation_days);
              this.createVactionForm
                .get("vacation_start_date_english")
                .setValue(new Date(res?.result?.vacation_start_date));
              this.createVactionForm
                .get("vacation_end_date_english")
                .setValue(new Date(res?.result?.vacation_end_date));
              this.ds.setENTOARDate(
                {
                  value: this.createVactionForm.get(
                    "vacation_start_date_english"
                  ).value,
                },
                this.createVactionForm.get("vacation_start_date_arabic")
              );
              this.ds.setENTOARDate(
                {
                  value: this.createVactionForm.get("vacation_end_date_english")
                    .value,
                },
                this.createVactionForm.get("vacation_end_date_arabic")
              );
              this.financial_details = res?.result?.finance;
              this.isValidForm = true;
            } else {
              this.isValidForm = false;
              this.alert.error(res?.error);
            }
          },
          (err) => {
            this.isValidForm = false;
            this.createVactionForm
              .get("before_vacation_working_date_english")
              .reset();
            this.createVactionForm
              .get("before_vacation_working_date_arabic")
              .reset();
            this.createVactionForm
              .get("after_vacation_working_date_english")
              .reset();
            this.createVactionForm
              .get("after_vacation_working_date_arabic")
              .reset();
          }
        );
    }
  }

  setValueToFields(obj, enField: AbstractControl, ArField: AbstractControl) {
    enField.setValue(obj.enField);
    ArField.setValue(obj.arField);
  }
  getEmployeeInfo() {
    let data = new FormData();
    data.append("employee_number", this.employee_data.employee_number);
    this.ds.post("form/FORM_HRV1/get_employee_info", data).subscribe((res) => {
      if (res.status) {
        this.createVactionForm.controls["contract_id"].setValue(
          res?.result?.contract_vacation_info?.contract_id
        );
        this.createVactionForm.controls[
          "vacation_paid_days_from_contract"
        ].setValue(
          res?.result?.contract_vacation_info?.vacation_paid_days_only
        );
        this.employee_number = res.result?.employee_info?.employee_number;
        this.contract_id = res?.result?.contract_vacation_info?.contract_id;
        this.vacation_paid_days_from_contract =
          res?.result?.contract_vacation_info?.vacation_paid_days_only;
      } else {
        this.showMsg(res.error, "ok", "error-snackbar");
        this.back();
      }
    });
  }
  get attachmentsARR() {
    return this.createVactionForm.get("attachments") as FormArray;
  }
  checkAttachment() {
    let vacation = this.vacations.find((item) => {
      return item.value == this.createVactionForm.get("vacation_type_id").value;
    });

    if (vacation) {
      if (vacation.need_attachment == "1") {
        this.showAttachment = true;
        this.getAttachments.setValidators([Validators.required]);
        this.attachments.setValidators(Validators.required);

        this.getAttachments.updateValueAndValidity();
        this.attachstatus = false;
        this.showAttachment = true;
        this.attachmentsARR.clear();
      } else {
        this.showAttachment = false;
        this.attachstatus = false;

        this.attachments.clearValidators();
        this.getAttachments.reset();
        this.getAttachments.updateValueAndValidity();
        this.attachmentsARR.clear();
      }
    }

    if (vacation.value == "9") {
      this.showOtherVacation = true;
    } else {
      this.showOtherVacation = false;
    }
  }

  public enable_field(field) {
    if (field == "attachment") {
      this.attachments.clear();
      this.createVactionForm.get("needAttach").setValue("1");
      this.addfiles();
      this.attachstatus = true;
    }
  }

  public files(): FormGroup {
    return this.fb.group({
      attach_title: new FormControl(
        "",
        this.createVactionForm.get("needAttach").value == "1"
          ? [Validators.required]
          : null
      ),
      file: new FormControl(
        "",
        this.createVactionForm.get("needAttach").value == "1"
          ? [Validators.required]
          : null
      ),
    });
  }
  public addfiles() {
    if (this.attachments.length < 5) {
      this.attachments.push(this.files());
    }
  }
  public removefiles(index) {
    if (this.attachments.length > 1) {
      this.attachments.removeAt(index);
    }
  }

  public getfilesgroup(index): FormGroup {
    const formGroup = this.attachments.controls[index] as FormGroup;
    return formGroup;
  }

  public UploadFile(event, index) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      $("#uploadText" + index).text(fileid.name);
      this.attachments.controls[index]
        .get("file")
        .setValue(fileid, { emitEvent: true });
    } else {
      this.attachments.controls[index].get("file").setValue(null);
      $("#uploadText" + index).text(this.lang.transform("lang_select_files"));
    }
  }
  public uploadFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      this.isFile = true;
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.createVactionForm
        .get("upload")
        .setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.createVactionForm.get("upload").setValue(null);
      this.filename = this.lang.transform("lang_select_files");
    }
  }
  public removeFile() {
    this.isFile = false;
    this.createVactionForm.get("upload").setValue(null);
    this.filename = this.lang.transform("lang_select_files");
  }

  public disable_field(field) {
    if (field == "attachment") {
      this.attachments.clear();
      this.createVactionForm.get("needAttach").setValue("0");
      this.isFile = false;
      this.attachstatus = false;
    }
    this.createVactionForm.updateValueAndValidity();
  }
  get filesFormGroup() {
    return this.createVactionForm.get("attachments") as FormArray;
  }

  public search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append("search", key.term || "");
    param.append("lang_key", this.lang_key);
    param.append("user_type_id", "1");
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
      .subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      });
  }

  changeDateInput(w) {}
  onSubmit() {
    moment.locale("en");
    if (this.createVactionForm.valid) {
      let formData = new FormData();
      formData.append("employee_number", this.employee_data.employee_number);
      formData.append(
        "vacation_type_id",
        this.createVactionForm.get("vacation_type_id").value
      );
      formData.append(
        "vacation_other",
        this.createVactionForm.get("vacation_other").value
      );
      formData.append(
        "contract_id",
        this.createVactionForm.get("contract_id").value
      );
      formData.append(
        "before_vacation_working_date_english",
        moment(
          this.createVactionForm.get("before_vacation_working_date_english")
            .value
        ).format("YYYY/MM/DD")
      );
      formData.append(
        "before_vacation_working_date_arabic",
        this.createVactionForm.get("before_vacation_working_date_arabic").value
      );
      formData.append(
        "vacation_start_date_english",
        moment(
          this.createVactionForm.get("vacation_start_date_english").value
        ).format("YYYY/MM/DD")
      );
      formData.append(
        "vacation_start_date_arabic",
        this.createVactionForm.get("vacation_start_date_arabic").value
      );
      formData.append(
        "vacation_end_date_english",
        moment(
          this.createVactionForm.get("vacation_end_date_english").value
        ).format("YYYY/MM/DD")
      );
      formData.append(
        "vacation_end_date_arabic",
        this.createVactionForm.get("vacation_end_date_arabic").value
      );
      formData.append(
        "after_vacation_working_date_english",
        moment(
          this.createVactionForm.get("after_vacation_working_date_english")
            .value
        ).format("YYYY/MM/DD")
      );
      formData.append(
        "after_vacation_working_date_arabic",
        this.createVactionForm.get("after_vacation_working_date_arabic").value
      );
      formData.append(
        "vacation_total_days",
        this.createVactionForm.get("vacation_total_days").value
      );
      formData.append(
        "vacation_paid_days_from_contract",
        this.createVactionForm.get("vacation_paid_days_from_contract").value
      );
      formData.append(
        "direct_manager",
        this.createVactionForm.get("direct_manager").value
      );
      formData.append(
        "vacation_total_unpaid_days",
        this.createVactionForm.get("vacation_total_unpaid_days").value
      );

      formData.append(
        "needAttach",
        this.createVactionForm.get("needAttach").value
      );
      if (this.createVactionForm.get("vacation_type_id").value == "3") {
        formData.append(
          "vacation_total_paid_days",
          this.createVactionForm.get("paid_days75").value +
            this.createVactionForm.get("paid_days100").value
        );
        formData.append(
          "vacation_total_paid_amount",
          this.createVactionForm.get("paid_days75_amount").value +
            this.createVactionForm.get("paid_days100_amount").value
        );
      } else {
        formData.append(
          "vacation_total_paid_days",
          this.createVactionForm.get("vacation_total_paid_days").value
        );
        formData.append(
          "vacation_total_paid_amount",
          this.createVactionForm.get("vacation_total_paid_amount").value
        );
      }

      this.attachments.controls.forEach((item, index) => {
        formData.append(
          `attachments[${index + 1}][file]`,
          item.get("file").value
        );
        formData.append(
          `attachments[${index + 1}][attach_title]`,
          item.get("attach_title").value
        );
      });

      if (this.showAttachment) {
        if (this.attachments.length) {
          this.spinner.show();
          this.ds.post("form/FORM_HRV1/cr/0", formData).subscribe((res) => {
            this.spinner.hide();
            if (res.status) {
              this.alert.success(res.msg);
              this.resetFields();
              this.financial_details = [];
              this.router.navigate(["/tasks/alltasks"]);
            } else {
              this.alert.error(res.error);
            }
          });
        } else {
          this.showMsg("add attachments", "ok", "error-snackbar");
        }
      } else {
        this.spinner.show();
        this.ds.post("form/FORM_HRV1/cr/0", formData).subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            /* this.alert.success(res.msg); */
            this.showMsg(res.msg, "ok", "success-snackbar");
            this.resetFields();
            this.financial_details = [];
            this.router.navigate(["/tasks/alltasks"]);
          } else {
            this.alert.error(res.error);
          }
        });
      }
    } else {
      if (!this.createVactionForm.controls.attachments.valid) {
        this.showMsg(
          this.lang.transform("lang_need_to_upload_attachment"),
          "ok",
          "error-snackbar"
        );
      } else {
        this.showMsg(
          this.lang.transform("lang_all_fields_are_required"),
          "ok",
          "error-snackbar"
        );
      }
    }
  }

  showMsg(msg: string, action: string, className: string) {
    this.snackBar.open(msg, action, {
      duration: 2000,
      panelClass: [className],
    });
  }

  resetFields() {
    this.createVactionForm.reset();
    this.attachstatus = false;
    this.reviewstatus = false;
    this.markstatus = false;

    this.createVactionForm.get("needAttach").setValue("0");
    this.createVactionForm.get("contract_id").setValue(this.contract_id);
    this.createVactionForm
      .get("vacation_paid_days_from_contract")
      .setValue(this.vacation_paid_days_from_contract);
  }
  get_details(month, year, temp) {
    this.spinner.show();
    let param = new FormData();
    param.append("employee_number", this.employee_data.employee_number);
    param.append("year", year);
    param.append("month", month);
    this.subscriptions.add(
      this.ds
        .postActionByUrl(param, "form/FORM_HRV1/detailes_salary_amount")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res?.status) {
              this.details = res.data;
              this.net_amount = res?.data?.net_amount;
              this.financial_details_list = res?.data?.financial_details;
              if (this.financial_details_list?.length > 0) {
                this.financial_details_list = res?.data?.financial_details[0];
                this.sweetAlert.successToast(
                  this.lang.transform("lang_success"),
                  2000
                );
                this.openModalV(temp);
              } else {
                this.sweetAlert.errorToast(
                  this.lang.transform("lang_no_data"),
                  2000
                );
              }
            } else {
              this.sweetAlert.errorToast(res?.msg, 2000);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
