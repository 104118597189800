import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { SendCodeComponent } from "app/routes/transactions/FORM_PS_TR1/send-code/send-code.component";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
	selector: "app-cpay-step2",
	templateUrl: "./cpay-step2.component.html",
	styleUrls: ["./cpay-step2.component.scss"],
})
export class CpayStep2Component implements OnInit {
	@Output() refreshComponent = new EventEmitter<boolean>();
	@Input() form_key;
	@Input() form_request_id;
	@Input() project_data;
	@Input() supervision_data;
	@Input() template_data;
	@Input() allData;
	rejectedValidators = [];
	stepName = "Contractor_Manager_Approval";
	form: FormGroup;
	users: any = [];
	submitForm: boolean = false;
	constructor(
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		public router: Router,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.form = new FormGroup({
			transaction_request_id: new FormControl(this.form_request_id, [
				Validators.required,
			]),
			contractor_approval_status: new FormControl("", [Validators.required]),
			contractor_rejected_notes: new FormControl("", this.rejectedValidators),
			transactions_persons_action_code: new FormControl("", [
				Validators.required,
			]),
		});
	}

	checkStatus() {
		if (this.form.get("contractor_approval_status").value == "Return") {
			this.rejectedValidators = [Validators.required];
		} else {
			this.rejectedValidators = [];
		}
	}
	public completeStep3() {
		if (this.form.valid) {
			this.submitForm = true;
			let formData = new FormData();
			formData.append(
				"transaction_request_id",
				this.form.value.transaction_request_id
			);
			formData.append(
				"contractor_approval_status",
				this.form.value.contractor_approval_status == "Approve" ? "1" : "0"
			);
			if (this.form?.value?.contractor_approval_status) {
				formData.append(
					"contractor_rejected_notes",
					this.form.value.contractor_rejected_notes
				);
			}
			formData.append(
				"transactions_persons_action_code",
				this.form.value.transactions_persons_action_code
			);
			this.sweetAlert
				.confirmMessage("Are you sure?", "Confirm")
				.then((result) => {
					if (result.isConfirmed) {
						this.spinner.show();
						this.ds
							.post(`form/${this.form_key}/contractor_manager_action`, formData)
							.subscribe(
								(data) => {
									this.submitForm = false;
									if (data.status) {
										this.spinner.hide();
										this.sweetAlert.successToast(
											this.lang.transform("lang_data_sent_successfully"),
											1700
										);
										setTimeout(() => {
											this.refreshComponent.emit(true);
										}, 2000);
									} else {
										this.spinner.hide();
										this.alert.error(data?.error);
									}
								},
								(error) => {
									this.spinner.hide();
									this.alert.error(error);
								}
							);
					}
				});
		} else {
			this.alert.error(this.lang.transform("lang_all_fields_required"));
		}
	}

	openModal() {
		const dialogRef = this.dialog.open(SendCodeComponent, {
			disableClose: true,
			width: "400px",
			data: {
				transaction_persons_type: "signature",
				allData: this.allData,
				stepName: this.stepName,
				title: "lang_signature",
			},
		});
	}
}
