import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { SendCodeComponent } from 'app/routes/transactions/FORM_PS_TR1/send-code/send-code.component';

@Component({
  selector: 'app-wri-step6',
  templateUrl: './wri-step6.component.html',
  styleUrls: ['./wri-step6.component.scss']
})
export class WriStep6Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  form:FormGroup;
  stepName:string = 'Special_Approval';
  users:any					= [];
  lang_key:string     = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
  constructor(public ds: DataService, private sweetAlert:SweetAlertService, public router: Router, public route: ActivatedRoute, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id             : new FormControl(this.form_request_id, Validators.required),
      notes                              : new FormControl('', [Validators.required])
    });
  }
  public search_special_approvers(key?) {
    this.users  = [];
    let param   = new FormData();
    param.append('search', key.term || '');
    param.append('lang_key', this.lang_key);
    param.append('user_type_id', '1');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.users = res.list;
      }
    });
  }

  openModal() {
    const dialogRef = this.dialog.open(SendCodeComponent,{
      disableClose: true,
      width:'400px',
      data: {transaction_persons_type: 'notes', allData: this.allData, stepName:this.stepName, title:'lang_notes'}
      
    });
  }
  completeStep6() {
    if(this.form.valid) {
      let formData = new FormData();
      formData.append('transaction_request_id', this.form.value.transaction_request_id);
      formData.append('notes', this.form.value.notes)
      this.sweetAlert.confirmMessage('Are you sure?', 'Confirm').then(result => {
        if(result.isConfirmed) {
          this.spinner.show();
          this.ds.post(`form/${this.form_key}/Special_Approval/0`, formData).subscribe(data => {
            if(data.status) {
              this.spinner.hide();
              this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
              setTimeout(() => {
                this.refreshComponent.emit(true);
              }, 2000);
            } else {
              this.spinner.hide();
              this.alert.error(data.error);
            }
          }, (error) => {
            this.spinner.hide();
              this.alert.error(error);
          })
        }
      })
      
    } else {
      this.alert.error(this.lang.transform('lang_all_fields_required'));
    }
  }

  
}
