import {
	Component,
	Input,
	EventEmitter,
	OnInit,
	Output,
	SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { ChecklistDetailsComponent } from "../checklist-details/checklist-details.component";

@Component({
	selector: "app-checklist-details-view-floor",
	templateUrl: "./checklist-details-view-floor.component.html",
	styleUrls: ["./checklist-details-view-floor.component.scss"],
})
export class ChecklistDetailsViewFloorComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	@Input() point;
	@Output() hideView = new EventEmitter<boolean>();
	point_users = [];
	point_files = [];
	point_logs = [];
	constructor(
		private spinner: PreloaderService,
		private dialog: MatDialog,
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		this.getPointUsers();
		this.getPointFiles();
		this.getPointLogs();
	}

	getPointUsers() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("sub_point_id", this.point?.check_id || "");
		this.ds.post("tasks/get_emp_in_sub_points", formData).subscribe(
			(res: any) => {
				this.spinner.hide();
				if (res.status) {
					this.point_users = res?.data;
				} else {
					this.point_users = [];
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(err) => {
				this.point_users = [];
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	getPointFiles() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("sub_point_id", this.point?.check_id || "");
		this.ds.post("tasks/get_files_in_sub_points", formData).subscribe(
			(res: any) => {
				this.spinner.hide();
				if (res.status) {
					this.point_files = res?.data;
				} else {
					this.point_files = [];
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(err) => {
				this.point_files = [];
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

	getPointLogs() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("sub_point_id", this.point?.check_id || "");
		this.ds.post("tasks/get_logs_in_sub_points", formData).subscribe(
			(res: any) => {
				this.spinner.hide();
				if (res.status) {
					this.point_logs = res?.data;
				} else {
					this.point_logs = [];
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(err) => {
				this.point_logs = [];
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

	deletePointFile(file_id) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("sub_point_id", file_id || "");
		this.ds.post("tasks/delete_file_sub_points", formData).subscribe(
			(res: any) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successToast(res.msg, 2000);
					this.point.files.splice(
						this.point.files.findIndex((x) => x.id === file_id),
						1
					); // need to review........
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	downloadFile(file_url, title?) {
		this.spinner.show();
		this.ds.get(file_url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.ds.downloadFile(res, title);
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	editPoint() {
		setTimeout(() => {
			const dialogRef = this.dialog.open(ChecklistDetailsComponent, {
				width: "600px",
				data: {
					checklist_item: this.point,
				},
			});
		}, 200);
	}

	//open attachment in new tab
	navigateNewTab(url) {
		window.open(url, "_blank");
	}
}
