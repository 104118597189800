import * as io from "socket.io-client";
import { Injectable } from "@angular/core";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { DataService } from "./data.service";
import { NullTemplateVisitor } from "@angular/compiler";

@Injectable({
	providedIn: "root",
})
export class SocketServiceService {
	// private url = 'https://dev.smovel1.com:3000';
	private url = environment.SOCKET_ORIGIN;
	public socket;
	private userToken = "";
	socketQrCode;
	api_key = environment.X_API_KEY;
	qr_codeimg = "";
	constructor(
		private localStorageService: LocalStorage,
		private ds: DataService
	) {}

	connectToServer() {
		this.userToken = this.localStorageService.getItem("token");
		this.socket = io.connect(this.url, {
			secure: true,
			auth: { token: this.userToken },
		});
	}

	connectToServerQRcode() {
		this.socketQrCode = io.connect(this.url, {
			secure: true,
			auth: { qrcode: "qrcode" },
		});
	}

	diconnectToServer() {
		this.socket.close();
	}

	setPath(path) {
		this.socket.emit("setPath", path);
	}
}
