import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

@Component({
	selector: "app-communication",
	templateUrl: "./communication.component.html",
	styleUrls: ["./communication.component.scss"],
})
export class CommunicationComponent implements OnInit {
	@Input() user_data: any;
	@Input() able2add: Boolean;
	wholedata: any;
	searchKey = "";
	size = 10;
	page = 1;
	transaction_keys = [
		{
			label: this.lang.transform("lang_form_outgoing"),
			value: "FORM_C1",
		},
		{
			label: this.lang.transform("lang_form_incoming"),
			value: "FORM_C2",
		},
	];
	transaction_key = "FORM_C1";
	constructor(
		public router: Router,
		public ds: DataService,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService
	) {}

	ngOnInit(): void {
		this.get_employee_communications();
	}

	get_employee_communications() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("searchKey", this.searchKey || "");
		formData.append("extra[module_key]", "human_resources");
		formData.append(
			"extra[transaction_key]",
			this.transaction_key || "FORM_C1"
		);
		formData.append(
			"extra[employee_number]",
			this.user_data?.employee_number || ""
		);
		formData.append("page_num", "" + this.page);
		formData.append("page_size", "" + this.size);
		this.ds.post("ccustom/human_resources_list", formData).subscribe((res) => {
			this.spinner.hide();
			if (res?.status) {
				this.wholedata = res;
			} else {
				this.wholedata = null;
			}
		});
	}

	onTranKeyChange() {
		this.get_employee_communications();
	}

	newCommunication(name) {
		let url = this.router.url;
		if (name == "incoming") {
			this.router.navigate([url + "/form/FORM_C2/cr/0"], {
				queryParams: {
					branchId: this.user_data.branch_id,
					from: "human_resources",
					id: this.user_data?.employee_number,
				},
			});
		} else if (name == "outgoing") {
			this.router.navigate([url + "/form/FORM_C1/cr/0"], {
				queryParams: {
					branchId: this.user_data.branch_id,
					from: "human_resources",
					id: this.user_data?.employee_number,
				},
			});
		}
	}

	public load_page_size(pageno) {
		this.size = pageno;
		this.get_employee_communications();
	}
	public page_length(page) {
		this.page = page;
		this.get_employee_communications();
	}
}
