<div mat-dialog-title class="d-flex justify-content-between">
	<h4>{{ "lang_ticket" | language }} {{ "lang_attachments" | language }}</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content>
	<div class="p-2">
		<div class="row pt-2 pb-2">
			<div class="col-12">
				<div
					class="table-responsive customResponsive tablefields quotationitemtable"
				>
					<table border="1" class="table CustomTable" width="100%">
						<thead>
							<tr>
								<td width="2%" style="padding: 5px; word-break: break-all">
									#
								</td>
								<td width="48%" style="padding: 5px; word-break: break-all">
									{{ "lang_title" | language }}
								</td>
								<td width="48%" style="padding: 5px; word-break: break-all">
									{{ "lang_file" | language }}
								</td>
							</tr>
						</thead>
						<tbody *ngIf="ticket_attachments?.length">
							<tr
								*ngFor="
									let file of ticket_attachments
										| slice: (page - 1) * size:page * size;
									let i = index;
									let odd = odd;
									let even = even
								"
							>
								<td
									class="verticalalignmid"
									style="padding: 5px; word-break: break-all"
								>
									{{ i + 1 }}
								</td>
								<td
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									{{ file?.file_name_en }}
								</td>
								<td
									class="verticalalignmid"
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									<i
										class="fa fa-download mnabricon"
										matTooltip="{{ 'lang_download_file' | language }}"
										(click)="downloadFile(file?.path, file?.file_name_en)"
										style="margin: 0 5px"
									></i>
									<i
										class="fa fa-file-pdf-o mnabricon"
										matTooltip="{{ 'lang_preview' | language }}"
										(click)="
											ds.preview_pdf_file(file?.path, file?.file_name_en)
										"
										*ngIf="
											file?.file_extension == 'pdf' ||
											file?.file_extension == 'PNG'
										"
										style="margin: 0 5px"
									></i>
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="!ticket_attachments?.length">
							<tr>
								<td colspan="3" class="text-center">
									{{ "lang_no_data" | language }}
								</td>
							</tr>
						</tbody>
					</table>
					<div
						class="mt-2"
						*ngIf="ticket_attachments?.length > 0"
						style="
							height: 40px;
							display: flex;
							justify-content: center;
							align-items: center;
						"
					>
						<ngb-pagination
							*ngIf="ticket_attachments.length"
							[collectionSize]="ticket_attachments.length"
							[(page)]="page"
							[maxSize]="size"
							[boundaryLinks]="true"
						></ngb-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
