<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4 class="modal-title">{{ "lang_uploads_history" | language }}</h4>
		<button type="button" class="close" mat-dialog-close>&times;</button>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-md-12 pad0">
			<div class="table-responsive customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>{{ "lang_template_id" | language }}</td>
							<td>{{ "lang_projects_work_area_id" | language }}</td>
							<td>{{ "lang_platform_group1_code_system" | language }}</td>
							<td>
								{{ "lang_platform_group_type_code_system" | language }}
							</td>
							<td>{{ "lang_download_file" | language }}</td>
						</tr>
					</thead>
					<tbody *ngIf="uploadsArray.length > 0" class="AppendList">
						<tr
							*ngFor="
								let uploadedFile of uploadsArray;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
						>
							<td style="text-align: center">
								{{ uploadedFile.template_id }}
							</td>
							<td style="text-align: center">
								{{ uploadedFile.project_work_area_id }}
							</td>
							<td style="text-align: center">
								{{ uploadedFile.group1name }}
							</td>
							<td style="text-align: center">
								{{ uploadedFile.typename }}
							</td>
							<td>
								<i
									class="fa fa-download mnabricon"
									style="text-align: center"
									(click)="
										downlaodFile(uploadedFile.file, uploadedFile.typename)
									"
									matTooltip="{{ 'lang_download' | language }}"
								></i>
							</td>
						</tr>
					</tbody>
					<tbody *ngIf="uploadsArray.length < 1" class="AppendList">
						<tr class="odd">
							<td colspan="5" class="nodata" align="center">
								{{ "lang_no_data" | language }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="closeModal()"
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</mat-dialog-actions>
