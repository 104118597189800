import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { parseMetadata } from '@angular/localize/src/utils';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  platform_group1_id: string,
  platform_group1_code_system: string,
  platform_group1_code_manual: string,
  platform_group1_title_english: string,
  platform_group1_title_arabic: string,
  typename: string,
}
@Component({
  selector: 'app-wgroup1',
  templateUrl: './wgroup1.component.html',
  styleUrls: ['./wgroup1.component.scss']
})
export class Wgroup1Component implements OnInit {
  modalRefA: BsModalRef;
  tabledatas: list[];
  form:FormGroup;
  @Output() actionEvent             = new EventEmitter<any>();
  collectionSize                    = 0;
  page                              = 1;
  pageSize                          = 10;
  searchKey                         = "";
  platform_group_type_code_system   = null;
  projects_services_ids             = null;
  nodata: string                    = this.lang.transform('lang_loading');
  admin                             = JSON.parse(this.ls.getItem('is_admin'));
  wholeData:any                     = [];
  tabledata:any                     = [];
  types:any                         = [];
  services:any                      = [];
  selectmultiple                    = true;
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public spinner:PreloaderService, public alert:AlertService, public modalService: BsModalService, public dialog:MatDialog, public fb:FormBuilder) { }
  ngOnInit(): void {
    this.spinner.show();
    this.get_group_types();
    this.get_group_one_list();
    this.form = new FormGroup({
      platform_group1_id                : new FormControl(''),
      platform_group1_code_manual       : new FormControl(''),
      platform_group1_title_english     : new FormControl('', [Validators.required]),
      platform_group1_title_arabic      : new FormControl('', [Validators.required]),
      platform_group_type_code_system   : new FormControl('', [Validators.required]),
      projects_services_ids             : new FormControl('', [Validators.required]),
    });
  }
  //add, edit model
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public get_group_types() {
    this.services   = this.types      = [];    
    this.ds.forkJoin(['aB8Vlmue0xVSv8O/projects_view', 'lpgtype'], []).subscribe(res=> {
      if(res[0].status) {
        this.services = res[0].records;
      }
      if(res[1].status) {
        this.types = res[1].records;
      }
    });
  }
  public get_group_one_list() {
    let param                                         = new FormData();
    param.append('search_key',                        this.searchKey);
    param.append('request',                           'list');
    param.append('platform_group_type_code_system',   this.platform_group_type_code_system || '');
    param.append('projects_services_ids',             this.projects_services_ids || '');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgone').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.wholeData      = res.records;
        this.tabledatas     = res.records;
        this.collectionSize = res.records.length;
        this.build_table_data();
      } else {
        this.wholeData  = [];
        this.nodata     = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholeData  = [];
      this.nodata     = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    }) 
  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public edit_formdata(row) {
    this.form.patchValue(row);
    this.selectmultiple = false;
    this.form.get('projects_services_ids').setValue((row.projects_services_ids) ? row.projects_services_ids.split(',') : null);
  }
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.append('platform_group1_id', this.form.get('platform_group1_id').value || '');
    param.append('platform_group1_code_manual', this.form.get('platform_group1_code_manual').value || '');
    param.append('platform_group1_title_english', this.form.get('platform_group1_title_english').value || '');
    param.append('platform_group1_title_arabic', this.form.get('platform_group1_title_arabic').value || '');
    param.append('platform_group_type_code_system', this.form.get('platform_group_type_code_system').value || '');
    param.append('projects_services_ids', (this.form.get('projects_services_ids').value) ? this.form.get('projects_services_ids').value.toString() : '');
    if(this.form.get('platform_group1_id').value) {
      this.ds.putActionByUrl(this.ds.formData2string(param), 'upgone/' + this.form.get('platform_group1_id').value).subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.actionEvent.emit('');
          this.get_group_one_list();
          this.form.reset();
          this.alert.success(res.msg);
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.ds.postActionByUrl(param, 'cpgone').subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.actionEvent.emit('');
          this.get_group_one_list();
          this.form.reset();
          this.alert.success(res.msg);
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    }
  }
  public checkallItemsWG1(event) {
    if (event.target.checked) {
      $(".appwgroup1 .eachItemWG1").prop("checked", true);
      $(".appwgroup1 .makeback").addClass("bground");
      $(".appwgroup1 .deleteIconDiv").show();
    } else {
      $(".appwgroup1 .eachItemWG1").prop("checked", false);
      $(".appwgroup1 .makeback").removeClass("bground");
      $(".appwgroup1 .deleteIconDiv").hide();
    }
  }
  public checkeachItemWG1(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appwgroup1 .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appwgroup1 .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appwgroup1 .eachItemWG1:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appwgroup1 .eachItemWG1").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appwgroup1 .deleteIconDiv").show();
    } else {
      $(".appwgroup1 .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appwgroup1 #allItemsWG1").prop("checked", true);
    } else {
      $(".appwgroup1 #allItemsWG1").prop("checked", false);
    }
  }
  public deleteBulkDataWG1() {
    const checkedtotal = [];
    $(".appwgroup1 .eachItemWG1:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'dpgone').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.actionEvent.emit('');
                this.get_group_one_list();
                $(".appwgroup1 #allItemsWG1").prop("checked", false);
                $(".appwgroup1 .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}