import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { FilterEmployeeCardMobComponent } from "./filter-card-mob/filter-card-mob.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AlertService } from "@shared/components/alert/alert.service";
declare var $: any;
import * as echarts from "echarts";
import { findFlagUrlByIso2Code } from "country-flags-svg";
import moment from "moment";

import { ToggleMenuService } from "@core/bootstrap/toggle-menu.service";
import { ChartDataInformationComponent } from "./chart-data-information/chart-data-information.component";
import {
  MbscCalendarEvent,
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  MbscPopup,
  MbscPopupOptions,
} from "@mobiscroll/angular";
import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";
import { formatDate } from "@mobiscroll/angular";
import { Router } from "@angular/router";

@Component({
  selector: "app-AllEmployees",
  templateUrl: "./AllEmployees.component.html",
  styleUrls: ["./AllEmployees.component.scss"],
})
export class AllEmployeesComponent implements OnInit, OnDestroy {
  modalRefC: BsModalRef;
  signform: FormGroup;
  liststatus: boolean = false;
  errormessagef = this.lang.transform("lang_loading");
  myemployees: any = [];
  signchecked: any = false;
  branches: any = [];
  lltotal: any = "";
  lltotalpage: any = "";
  wholddata: any = [];
  signfilename = this.lang.transform("lang_select_files");
  markfilename = this.lang.transform("lang_select_files");
  signform_status: any = false;
  signerror: string = "";
  is_admin = JSON.parse(this.ls.getItem("is_admin"));
  searchKey = localStorage.getItem("M_H_R_S_K") || "";
  lang_key = localStorage.getItem("lang_key")
    ? localStorage.getItem("lang_key")
    : "en";
  showFilter = false;
  showSort = false;
  isMob = false;
  EmployeeData = [];
  imageUrl = environment.SERVER_ORIGIN;
  searchByBranch = localStorage.getItem("M_H_R_B") || "";
  pages: any = "pages";
  collection_size = 0;
  paginationMaxSize = 3;
  showExel = false;
  filters_params = {
    nationality_type: null,
    project_id: null,
    branch_id: null,
    national_id: null,
    employee_id: null,
    emp_user_id: null,
    employee_number: null,
    employee_name: null,
    nationality: null,
    position: null,
    employee_status: ["active"],
  };
  sort_params = {
    form_request_id: null,
    template_id: null,
    platform_code_system: null,
  };
  reportsData: any = {};
  count_data_loading = true;
  formsChart: any;
  formsChartEmployees: any;
  option2: echarts.EChartsOption = {};
  optionnEmp: echarts.EChartsOption;
  myChartEmp: any;
  chartDomEmpJops: any;
  optionnEmpJops: echarts.EChartsOption;
  myChartEmpJops: any;
  showFiller = false;
  //reports
  RE_report_type_list = [];
  RE_report_type = "iqama_expiry_date_english";
  RE_filter_type = "30";
  RE_page_no = 1;
  RE_page_size = 10;
  RE_report_data = [];
  RE_total_records;

  REC_filter_type = "30";
  REC_page_no = 1;
  REC_page_size = 10;
  REC_report_data = [];
  REC_total_records;

  REV_filter_type = "30";
  REV_page_no = 1;
  REV_page_size = 10;
  REV_report_data = [];
  REV_total_records;

  EPP_filter_type = "30";
  EPP_page_no = 1;
  EPP_page_size = 10;
  ENC_page_no = 1;
  ENC_page_size = 10;
  ENJ_page_no = 1;
  ENJ_page_size = 10;
  EOV_page_no = 1;
  EOV_page_size = 10;
  EHC_page_no = 1;
  EHC_page_size = 10;
  EPP_report_data = [];
  EPP_total_records;
  ENC_report_data: any;
  ENC_total_records: any;
  ENJ_report_data: any;
  ENJ_total_records: any;
  EOV_report_data: any;
  EOV_total_records: any;
  EHC_report_data: any;
  EHC_total_records: any;
  form: FormGroup;
  constructor(
    public ds: DataService,
    private router: Router,
    public alert: AlertService,
    private spinner: PreloaderService,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {
    this.RE_report_type_list = [
      {
        label: this.lang.transform("lang_membership_expiry_date_english"),
        value: "membership_expiry_date_english",
      },
      {
        label: this.lang.transform("lang_passport_expiry_date_english"),
        value: "passport_expiry_date_english",
      },
      {
        label: this.lang.transform("lang_iqama_expiry_date_english"),
        value: "iqama_expiry_date_english",
      },
    ];
    this.buildform();
    this.getEOVemployeesReport();
  }

  getIcon(flag: any) {
    return findFlagUrlByIso2Code(flag);
  }
  optionsTest;

  onChartClick(e) {
    let emps = e.data[2].who;
    this.openChartData(emps);
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  agesOptions: any = {};
  agesFunc(dataArr: any) {
    this.agesOptions = {
      xAxis: {},
      yAxis: {},

      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params) => {
          let str = `<div dir="ltr"> ${params.marker} ${this.lang.transform(
            "lang_age"
          )}: ${params?.data[1]} => ${params?.data[0]} ${this.lang.transform(
            "lang_users"
          )} <br>`;

          if (
            params.data.length > 2 &&
            params.data[2] &&
            params.data[2].who.length > 0
          ) {
            for (let index = 0; index < params.data[2].who.length; index++) {
              if (index > 3) {
                str += `+${params.data[2].who.length - 4} ${this.lang.transform(
                  "lang_users"
                )}`;
                break;
              } else {
                const element = params.data[2].who[index];
                str += `${element.name}: <span> ${
                  element.experience
                } ${this.lang.transform("lang_experience")} </span> (${
                  element.country
                })<br>`;
              }
            }

            str += "</div>";
          }

          return str;
        },
      },
      series: [
        {
          symbolSize: 20,

          data: dataArr,
          type: "scatter",
        },
      ],
    };
  }
  jopsOptions: any = {};
  jopsFunc(positions_report: any) {
    this.jopsOptions = {
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {},
      grid: {
        left: "3%",
        right: "4%",
        bottom: "12%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: positions_report?.position_names,
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
        {
          type: "slider",
          yAxisIndex: 0,
          filterMode: "none",
        },
        {
          type: "inside",
          xAxisIndex: 0,
          filterMode: "none",
        },
        {
          type: "inside",
          yAxisIndex: 0,
          filterMode: "none",
        },
      ],
      series: [],
    };

    for (const key in positions_report.data.stack1) {
      const element = positions_report.data.stack1[key];
      this.jopsOptions.series.push({
        name: this.lang.transform(`lang_${key}`),
        type: "bar",
        label: {
          position: "top",
          show: true,
        },

        stack: "stack1",
        emphasis: {
          focus: "series",
        },
        tooltip: {
          formatter: (params) => {
            return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.value}</span> `;
          },
        },
        data: element,
      });
    }

    for (const key in positions_report.data.stack2) {
      const element = positions_report.data.stack2[key];
      this.jopsOptions.series.push({
        name: this.lang.transform(`lang_${key}`),
        type: "bar",
        label: {
          position: "top",
          show: true,
        },

        stack: "stack2",
        emphasis: {
          focus: "series",
        },
        tooltip: {
          formatter: (params) => {
            return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.value}</span> `;
          },
        },
        data: element,
      });
    }

    for (const key in positions_report.data.stack3) {
      if (
        Object.prototype.hasOwnProperty.call(positions_report.data.stack3, key)
      ) {
        const element = positions_report.data.stack3[key];
        this.jopsOptions.series.push({
          name: this.lang.transform(`lang_${key}`),
          type: "bar",
          label: {
            position: "top",
            show: true,
          },

          stack: "stack3",
          emphasis: {
            focus: "series",
          },
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.value}</span> `;
            },
          },
          data: element,
        });
      }
    }
    for (const key in positions_report.data.stack4) {
      const element = positions_report.data.stack4[key];
      this.jopsOptions.series.push({
        name: this.lang.transform(`lang_${key}`),
        type: "bar",
        label: {
          position: "top",
          show: true,
        },

        /*  stack: 'stack4', */
        emphasis: {
          focus: "series",
        },
        tooltip: {
          formatter: (params) => {
            return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.value}</span> `;
          },
        },
        data: element,
      });
    }
  }

  othersOtions: any = {};
  othersFunctions(vacations_joinings_report: any) {
    this.othersOtions = {
      title: {
        text: "",
        subtext: "",
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: "50%",
          data: [],
        },
      ],
    };
    for (let index = 0; index < vacations_joinings_report.length; index++) {
      const element = vacations_joinings_report[index];
      this.othersOtions.series[0].data.push({
        value: element.value,
        name: element.name,
      });
    }
  }
  ngOnInit() {
    this.buildform();
    moment.locale("en");
    if (window.innerWidth < 600) {
      this.isMob = true;
      this.paginationMaxSize = 1;
    } else {
      this.isMob = false;
    }

    localStorage.setItem(
      "employee_filterParams",
      JSON.stringify(this.filters_params)
    );

    /* if(this.filterParams['group_type_id']) {
      this.showExel = true;
    } else {
      this.showExel = false;
    } */
    this.spinner.show();
    /* this.getEmployeeData(this.filters_params, this.sort_params, false); */
    this.getAllProjects();

    this.loadbranches();
    this.get_advanced_search_meta_data();

    /* this.get_reports() */

    this.get_general_counts(this.filters_params, true);
    this.get_ages_report(this.filters_params, true);
    this.get_vacations_joinings_report(this.filters_params, true);
    this.get_positions_report(this.filters_params, true);
    this.get_contranct_project_report(this.filters_params, true);
    this.getREReport();
    this.getRECReport();
    this.getREVReport();
    this.getEPPReport();
    this.getENCemployeesReport();
    this.getEHCemployeesReport();
    this.getENJemployeesReport();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }
  view: MbscEventcalendarView = {
    timeline: {
      type: "month",
    },
  };
  //calendar
  @ViewChild("popup", { static: false })
  tooltip!: MbscPopup;
  currentEvent: any;
  status = "";
  buttonText = "";
  buttonType = "";
  bgColor = "";
  info = "";
  time = "";
  reason = "";
  location = "";
  popupData: any = "";
  anchor: HTMLElement | undefined;
  timer: any;
  popupOptions: MbscPopupOptions = {
    display: "anchored",
    touchUi: false,
    showOverlay: false,
    contentPadding: false,
    closeOnOverlayClick: false,
    width: 350,
  };

  mouseEnter(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  mouseLeave(): void {
    this.timer = setTimeout(() => {
      this.tooltip.close();
    }, 200);
  }

  setStatus(): void {
    /*  const index = this.appointments.findIndex((item: any) => item.id === this.currentEvent.id);
  this.appointments[index].confirmed = !this.appointments[index].confirmed;
  this.tooltip.close();
  this.notify.toast({
      message: 'Appointment ' + (this.currentEvent.confirmed ? 'confirmed' : 'canceled')
  }); */
  }

  calendarOptions: MbscEventcalendarOptions = {
    dragToCreate: false,
    dragToMove: false,
    dragToResize: false,
    dragTimeStep: 60,

    view: {
      calendar: { labels: true },
    },
    showEventTooltip: false,
    height: 460,
    onEventHoverIn: (args, inst) => {
      const event: any = args.event;
      const time =
        formatDate("hh:mm A", new Date(event.start)) +
        " - " +
        formatDate("hh:mm A", new Date(event.end));

      this.currentEvent = event;

      if (event.confirmed) {
        this.status = "Confirmed";
        this.buttonText = "Cancel appointment";
        this.buttonType = "warning";
      } else {
        this.status = "Canceled";
        this.buttonText = "Confirm appointment";
        this.buttonType = "success";
      }

      this.bgColor = event.color;
      this.info = event.name;
      this.time = time;
      this.reason = event.reason;
      this.location = event.location;

      clearTimeout(this.timer);
      this.timer = null;

      this.anchor = args.domEvent.target;
      this.tooltip.open();
    },
    onEventHoverOut: () => {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.tooltip.close();
        }, 200);
      }
    },
    onPageLoading: (args: any) => {
      this.selectedDate = new Date(moment(args.viewStart).format("YYYY/MM/DD"));
    },
  };

  resourceClick(e) {
    let resourceIndex = this.accualRes.findIndex((a) => a.id == e.id);
    if (
      this.accualRes[resourceIndex].events.length - 1 >
      this.accualRes[resourceIndex].activeIndex
    ) {
      this.accualRes[resourceIndex].activeIndex =
        this.accualRes[resourceIndex].activeIndex + 1;
    } else if (
      this.accualRes[resourceIndex].events.length - 1 ==
      this.accualRes[resourceIndex].activeIndex
    ) {
      this.accualRes[resourceIndex].activeIndex = 0;
    }

    let myActiveEventsIndex = this.myActiveEvents.filter(
      (myAE) => myAE.resource == e.id
    );

    if (myActiveEventsIndex[this.accualRes[resourceIndex].activeIndex]) {
      this.selectedDate = new Date(
        moment(
          myActiveEventsIndex[this.accualRes[resourceIndex].activeIndex]
            .clickDate,
          "YYYY/MM/DD"
        ).format("YYYY/MM/DD")
      );
    } else {
      this.selectedDate = new Date(
        moment(myActiveEventsIndex[0].clickDate, "YYYY/MM/DD").format(
          "YYYY/MM/DD"
        )
      );
    }
  }
  myEvents: MbscCalendarEvent[] = [];
  myActiveEvents: MbscCalendarEvent[] = [];
  myResources: any[] = [
    /*    {
      id: 1,
      name: 'Resource A',
      color: '#4981d6'
    } */
  ];
  myChartContractsThree: any;
  myChartContractsOne: any;
  myChart: any;

  accualRes = [];
  filtersReturner(filters?: any) {
    let formData = new FormData();
    if (filters?.project_id && filters?.project_id.length > 0) {
      filters?.project_id.forEach((element) => {
        formData.append("project_id[]", element || "");
      });
    }
    if (filters?.nationality && filters?.nationality.length > 0) {
      filters?.nationality.forEach((element) => {
        formData.append("nationality[]", element || "");
      });
    }
    if (filters?.branch_id && filters?.branch_id.length > 0) {
      filters?.branch_id.forEach((element) => {
        formData.append("branch_id[]", element || "");
      });
    }
    if (filters?.position && filters?.position.length > 0) {
      filters?.position.forEach((element) => {
        formData.append("position[]", element || "");
      });
    }
    if (filters?.employee_status && filters?.employee_status.length > 0) {
      filters?.employee_status.forEach((element) => {
        /*    if (element == 'in_active'){
          element = 'inactive'
        } */
        formData.append("employee_status[]", element || "");
      });
    }

    if (filters?.iqama_expiry_date_from) {
      formData.append(
        "iqama_expiry_date_from",
        moment(filters?.iqama_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.iqama_expiry_date_to) {
      formData.append(
        "iqama_expiry_date_to",
        moment(filters?.iqama_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.passport_expiry_date_from) {
      formData.append(
        "passport_expiry_date_from",
        moment(filters?.passport_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.passport_expiry_date_to) {
      formData.append(
        "passport_expiry_date_to",
        moment(filters?.passport_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.membership_expiry_date_to) {
      formData.append(
        "membership_expiry_date_to",
        moment(filters?.membership_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.contract_expiry_date_from) {
      formData.append(
        "contract_expiry_date_from",
        moment(filters?.contract_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.membership_expiry_date_from) {
      formData.append(
        "membership_expiry_date_from",
        moment(filters?.membership_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.contract_expiry_date_to) {
      formData.append(
        "contract_expiry_date_to",
        moment(filters?.contract_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }

    if (filters?.nationality_type) {
      formData.append("nationality_type[]", filters?.nationality_type || "");
    }
    if (filters?.national_id) {
      formData.append("national_id[]", filters?.national_id || "");
    }
    if (filters?.employee_id) {
      formData.append("employee_id[]", filters?.employee_id || "");
    }
    if (filters?.emp_user_id) {
      formData.append("emp_user_id[]", filters?.emp_user_id || "");
    }
    if (filters?.employee_number) {
      formData.append("employee_number[]", filters?.employee_number || "");
    }
    if (filters?.employee_name) {
      formData.append("employee_name", filters?.employee_name || "");
    }

    return formData;
  }

  general_counts: any = "";
  public get_general_counts(filters?: any, search: boolean = false) {
    let formData = new FormData();
    if (search) {
      this.spinner.show();
      formData = this.filtersReturner(filters);
    }

    this.count_data_loading = true;
    this.subscriptions.add(
      this.ds
        .postActionByUrl(formData, "human_resources/get_general_counts")
        .subscribe((data) => {
          this.general_counts = data.general_counts;
          this.count_data_loading = false;
        })
    );
  }

  public get_ages_report(filters?: any, search: boolean = false) {
    let formData = new FormData();
    if (search) {
      this.spinner.show();

      formData = this.filtersReturner(filters);
    }

    this.count_data_loading = true;
    this.subscriptions.add(
      this.ds
        .postActionByUrl(formData, "human_resources/get_ages_report")
        .subscribe((data) => {
          this.spinner.hide();
          this.agesFunc(data.ages_report);

          this.count_data_loading = false;
        })
    );
  }

  public get_vacations_joinings_report(filters?: any, search: boolean = false) {
    let formData = new FormData();
    if (search) {
      this.spinner.show();

      formData = this.filtersReturner(filters);
    }

    this.count_data_loading = true;
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          formData,
          "human_resources/get_vacations_joinings_report"
        )
        .subscribe((data) => {
          this.othersFunctions(data.vacations_joinings_report);
          this.spinner.hide();
          this.count_data_loading = false;
        })
    );
  }

  saudi_arr = [];
  non_saudi_arr = [];
  experiance_arr = [];
  position_names = [];
  jopsOptionsHead = [];

  orignalStack2 = {};

  jopsOptionsHeadStack3 = [];

  orignalStack3 = {};
  public get_positions_report(filters?: any, search: boolean = false) {
    let formData = new FormData();
    if (search) {
      this.spinner.show();

      formData = this.filtersReturner(filters);
    }

    this.count_data_loading = true;
    this.subscriptions.add(
      this.ds
        .postActionByUrl(formData, "human_resources/get_positions_report")
        .subscribe((data) => {
          this.spinner.hide();
          this.jopsFunc(data.positions_report);
          this.count_data_loading = false;
          this.position_names = data.positions_report.position_names;

          this.saudi_arr = data.positions_report.data.stack1.saudi;
          this.non_saudi_arr = data.positions_report.data.stack1.non_saudi;
          this.experiance_arr =
            data.positions_report.data.stack4["Experience Average"];

          this.jopsOptionsHead = [];

          this.orignalStack2 = data.positions_report.data.stack2;
          for (const key in data.positions_report.data.stack2) {
            const element = data.positions_report.data.stack2[key];

            this.jopsOptionsHead.push(key);
          }

          this.jopsOptionsHeadStack3 = [];

          this.orignalStack3 = data.positions_report.data.stack3;
          for (const key in data.positions_report.data.stack3) {
            const element = data.positions_report.data.stack3[key];

            this.jopsOptionsHeadStack3.push(key);
          }
        })
    );
  }

  scrollLeft() {
    document.getElementById("table-container-3").scrollLeft -= 150;
  }
  scrollRight() {
    document.getElementById("table-container-3").scrollLeft += 150;
  }

  scrollLeft2() {
    document.getElementById("table-container").scrollLeft -= 150;
  }
  scrollRight2() {
    document.getElementById("table-container").scrollLeft += 150;
  }
  totalValues: any = 10;
  page_size = 10;
  page = 1;

  fist = true;
  load_by_page_size(e) {
    /*  this.page = 1; */
    if (e && e.value) {
      this.page_size = e.value;
      this.get_contranct_project_report(this.filters_params, true);
    }
  }
  public onPageChange(): void {
    if (!this.fist) {
      this.get_contranct_project_report(this.filters_params, true);
    }
  }
  npContractReports = false;
  totalAllRecords = 0;

  public get_contranct_project_report(filters?: any, search: boolean = false) {
    let formData = new FormData();
    if (search) {
      this.spinner.show();

      formData = this.filtersReturner(filters);
      formData.append("remove_empty_reports", "true");
    }

    /*     this.count_data_loading =true; */
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          formData,
          `human_resources/get_contranct_project_report/${this.page}/${this.page_size}`
        )
        .subscribe((data) => {
          this.spinner.hide();
          /* this.count_data_loading =false; */
          this.fist = false;
          this.totalValues = Number(data.total);
          this.page_size = +data.page.per_page;
          this.page = +data.page.page_no;
          this.myEvents = [];
          this.myResources = [];
          this.accualRes = [];
          if (data.status) {
            for (let ii = 0; ii < data.records.length; ii++) {
              const user = data.records[ii];
              if (
                user["project_expiry_before_contract_status"] ==
                  "has_project_expiry_before_contract" ||
                user["contract_expiry_before_project_status"] ==
                  "has_contract_expiry_before_project" ||
                user["iqama_expiry_status"] == "has_iqama_expiry" ||
                user["contract_expiry_status"] == "has_contract_expiry"
              ) {
                this.myResources.push({
                  id: +user.employee_number,
                  name: user.employee_name,
                  image: user.profile_image,
                  events: [],
                  activeIndex: 0,
                });
                this.accualRes.push({
                  id: +user.employee_number,
                  name: user.employee_name,
                  image: user.profile_image,
                  events: [],
                  activeIndex: 0,
                });
              }

              // Equal
              if (
                user["contract_expiry_status"] == "has_contract_expiry" &&
                user["contract_or_project_expired_first"] ==
                  "project_contract_samedate"
              ) {
                let resourceIndex = this.myResources.findIndex(
                  (r) => r.id == user.employee_number
                );
                this.myResources[resourceIndex]["events"].push({
                  index: this.myEvents.length,
                });
                this.accualRes[resourceIndex]["events"].push({
                  index: this.myEvents.length,
                });
                let state = "";
                if (
                  moment(
                    user.contract_start_date_english,
                    "YYYY/MM/DD"
                  ).isBefore(moment(user.contract_end_date_english))
                ) {
                  state = "before";
                }
                this.myEvents = [
                  ...this.myEvents,
                  {
                    taskType: "cogs",

                    employee_name: user.employee_name,
                    start: moment(
                      user.contract_start_date_english,
                      "YYYY/MM/DD"
                    ).format("YYYY-MM-DDTHH:mm"),
                    end: moment(
                      user.contract_end_date_english,
                      "YYYY/MM/DD"
                    ).format("YYYY-MM-DDTHH:mm"),
                    projects_expiry_date: user.projects_expiry_date,
                    contract_end_date_english: user.contract_end_date_english,
                    contract_start_date_english:
                      user.contract_start_date_english,
                    clickDate: user.contract_start_date_english,
                    daysCount: moment(
                      user.contract_end_date_english,
                      "YYYY/MM/DD"
                    ).diff(
                      moment(user.contract_start_date_english, "YYYY/MM/DD"),
                      "days"
                    ),
                    title: `${user.employee_name} ${this.lang.transform(
                      "lang_ends_his_project_at"
                    )}`,
                    color: "#34c8e0",
                    name: `${this.lang.transform(
                      "lang_contract"
                    )} ${this.lang.transform(
                      "lang_expiry_date"
                    )} ${this.lang.transform(
                      "lang_before"
                    )} ${this.lang.transform("lang_projects")} ( ${
                      +moment(
                        user.contract_end_date_english,
                        "YYYY/MM/DD"
                      ).diff(
                        moment(user.contract_start_date_english, "YYYY/MM/DD"),
                        "days"
                      ) +
                      " " +
                      this.lang.transform("lang_days")
                    } )`,
                    hidden: this.hidden5 ? true : false,
                    resource: +user.employee_number,
                    branch_id: +user.branch_id,
                  },
                ];
              }

              /// contract expiry before project
              if (
                user["contract_expiry_status"] == "has_contract_expiry" &&
                user["contract_or_project_expired_first"] ==
                  "contract_expired_first"
              ) {
                let resourceIndex = this.myResources.findIndex(
                  (r) => r.id == user.employee_number
                );
                this.myResources[resourceIndex]["events"].push({
                  type: "0",
                  index: this.myEvents.length,
                });
                this.accualRes[resourceIndex]["events"].push({
                  type: "0",
                  index: this.myEvents.length,
                });
                let state = "";
                if (
                  moment(
                    user.contract_start_date_english,
                    "YYYY/MM/DD"
                  ).isBefore(moment(user.contract_end_date_english))
                ) {
                  state = "before";
                }
                this.myEvents = [
                  ...this.myEvents,
                  {
                    taskType: "cogs",
                    type: "0",
                    employee_name: user.employee_name,
                    start: moment(
                      user.contract_start_date_english,
                      "YYYY/MM/DD"
                    ).format("YYYY-MM-DDTHH:mm"),
                    end: moment(
                      user.contract_end_date_english,
                      "YYYY/MM/DD"
                    ).format("YYYY-MM-DDTHH:mm"),
                    projects_expiry_date: user.projects_expiry_date,
                    contract_end_date_english: user.contract_end_date_english,
                    contract_start_date_english:
                      user.contract_start_date_english,
                    daysCount: moment(
                      user.contract_end_date_english,
                      "YYYY/MM/DD"
                    ).diff(
                      moment(user.contract_start_date_english, "YYYY/MM/DD"),
                      "days"
                    ),
                    clickDate: user.contract_start_date_english,
                    title: `${user.employee_name} ${this.lang.transform(
                      "lang_ends_his_project_at"
                    )}`,
                    color: "rgb(199, 124, 10)",
                    name: `${this.lang.transform(
                      "lang_contract"
                    )} ${this.lang.transform(
                      "lang_expiry_date"
                    )} ${this.lang.transform(
                      "lang_before"
                    )} ${this.lang.transform("lang_projects")} ( ${
                      state == "before" ? "-" : ""
                    }${
                      moment(user.contract_end_date_english, "YYYY/MM/DD").diff(
                        moment(user.contract_start_date_english, "YYYY/MM/DD"),
                        "days"
                      ) +
                      " " +
                      this.lang.transform("lang_days")
                    } )`,
                    hidden: this.hidden1 ? true : false,
                    branch_id: +user.branch_id,
                    resource: +user.employee_number,
                  },
                ];
              }

              //contract expiry
              if (
                user["contract_expiry_status"] == "has_contract_expiry" &&
                user["contract_or_project_expired_first"] == null
              ) {
                /* for (let index = 0; index < user['contract_expiry'].length; index++) {
                const element = user['contract_expiry'][index];
              
              
              } */
                let resourceIndex = this.myResources.findIndex(
                  (r) => r.id == user.employee_number
                );
                this.myResources[resourceIndex]["events"].push({
                  type: "1",
                  index: this.myEvents.length,
                });
                this.accualRes[resourceIndex]["events"].push({
                  type: "1",
                  index: this.myEvents.length,
                });
                let state = "";
                if (
                  moment(
                    user.contract_start_date_english,
                    "YYYY/MM/DD"
                  ).isBefore(moment(user.contract_end_date_english))
                ) {
                  state = "before";
                }
                this.myEvents = [
                  ...this.myEvents,
                  {
                    taskType: "material-repeat",
                    type: "1",
                    employee_name: user.employee_name,
                    start: moment(
                      user.contract_start_date_english,
                      "YYYY/MM/DD"
                    ).format("YYYY-MM-DDTHH:mm"),
                    end: moment(
                      user.contract_end_date_english,
                      "YYYY/MM/DD"
                    ).format("YYYY-MM-DDTHH:mm"),
                    title: `${user.employee_name} ${this.lang.transform(
                      "lang_ends_his_project_at"
                    )}`,
                    projects_expiry_date: null,
                    contract_end_date_english: user.contract_end_date_english,
                    daysCount: moment(
                      user.contract_end_date_english,
                      "YYYY/MM/DD"
                    ).diff(
                      moment(user.contract_start_date_english, "YYYY/MM/DD"),
                      "days"
                    ),
                    contract_start_date_english:
                      user.contract_start_date_english,
                    clickDate: user.contract_start_date_english,
                    color: "#7a5886",
                    name: `${this.lang.transform(
                      "lang_contract"
                    )} ${this.lang.transform("lang_expiry_date")} ( ${
                      state == "before" ? "-" : ""
                    }${
                      moment(user.contract_end_date_english, "YYYY/MM/DD").diff(
                        moment(user.contract_start_date_english, "YYYY/MM/DD"),
                        "days"
                      ) +
                      " " +
                      this.lang.transform("lang_days")
                    } )`,
                    hidden: this.hidden2 ? true : false,
                    branch_id: +user.branch_id,
                    resource: +user.employee_number,
                  },
                ];
              }

              //iqama expiry
              if (user["iqama_expiry_status"] == "has_iqama_expiry") {
                /*  for (let index = 0; index < user['iqama_expiry'].length; index++) {
                const element = user['iqama_expiry'][index];
            
              
              
              } */
                let resourceIndex = this.myResources.findIndex(
                  (r) => r.id == user.employee_number
                );
                this.myResources[resourceIndex]["events"].push({
                  type: "2",
                  index: this.myEvents.length,
                });
                this.accualRes[resourceIndex]["events"].push({
                  type: "2",
                  index: this.myEvents.length,
                });
                let state = "";
                let start = "";
                let end = "";
                let startWithoutFormat: any = "";
                let endWithoutFormat: any = "";
                if (
                  moment(user.iqama_expiry_date_english, "YYYY/MM/DD").isAfter(
                    moment()
                  )
                ) {
                  state = "after";

                  start = moment().format("YYYY-MM-DDTHH:mm");
                  startWithoutFormat = moment();
                  endWithoutFormat = moment(
                    user.iqama_expiry_date_english,
                    "YYYY/MM/DD"
                  );
                  end = moment(
                    user.iqama_expiry_date_english,
                    "YYYY/MM/DD"
                  ).format("YYYY-MM-DDTHH:mm");
                } else if (
                  moment(user.iqama_expiry_date_english, "YYYY/MM/DD").isSame(
                    moment()
                  )
                ) {
                  state = "equal";
                  startWithoutFormat = moment();
                  endWithoutFormat = moment(
                    user.iqama_expiry_date_english,
                    "YYYY/MM/DD"
                  );
                  start = moment().format("YYYY-MM-DDTHH:mm");
                  end = moment(
                    user.iqama_expiry_date_english,
                    "YYYY/MM/DD"
                  ).format("YYYY-MM-DDTHH:mm");
                } else {
                  state = "before";
                  startWithoutFormat = moment(
                    user.iqama_expiry_date_english,
                    "YYYY/MM/DD"
                  );
                  endWithoutFormat = moment();
                  start = moment(
                    user.iqama_expiry_date_english,
                    "YYYY/MM/DD"
                  ).format("YYYY-MM-DDTHH:mm");
                  end = moment().format("YYYY-MM-DDTHH:mm");
                }

                this.myEvents = [
                  ...this.myEvents,
                  {
                    taskType: "material-search",
                    type: "2",
                    start: start,
                    end: end,
                    state: state,
                    employee_name: user.employee_name,
                    iqama_expiry_date_english: user.iqama_expiry_date_english,
                    projects_expiry_date: user.iqama_expiry_date_english,
                    clickDate: moment(start).format("YYYY-MM-DD"),
                    daysCount: endWithoutFormat.diff(
                      startWithoutFormat,
                      "days"
                    ),
                    title: `${user.employee_name} ${this.lang.transform(
                      "lang_ends_his_iqama_at"
                    )} ${user.iqama_expiry_date_english}`,
                    color: "#76e083",
                    name: `${this.lang.transform(
                      "lang_human_resources_id_expiry_data_english"
                    )} ( ${state == "before" ? "-" : ""}${
                      endWithoutFormat.diff(startWithoutFormat, "days") +
                      " " +
                      this.lang.transform("lang_days")
                    } )`,
                    hidden: this.hidden3 ? true : false,
                    branch_id: +user.branch_id,
                    resource: +user.employee_number,
                  },
                ];
              }

              // project expiry before contract
              if (
                user["contract_expiry_status"] == "has_contract_expiry" &&
                user["contract_or_project_expired_first"] ==
                  "project_expired_first"
              ) {
                /*   for (let index = 0; index < user['project_expiry_before_contract'].length; index++) {
                const element = user['project_expiry_before_contract'][index];
              
                
              } */
                let resourceIndex = this.myResources.findIndex(
                  (r) => r.id == user.employee_number
                );
                this.myResources[resourceIndex]["events"].push({
                  type: "3",
                  index: this.myEvents.length,
                });
                this.accualRes[resourceIndex]["events"].push({
                  type: "3",
                  index: this.myEvents.length,
                });

                let state = "";
                if (
                  moment(
                    user.contract_start_date_english,
                    "YYYY/MM/DD"
                  ).isBefore(moment(user.contract_end_date_english))
                ) {
                  state = "before";
                }
                this.myEvents = [
                  ...this.myEvents,
                  {
                    taskType: "material-format-paint",
                    type: "3",
                    employee_name: user.employee_name,
                    start: moment(
                      user.contract_start_date_english,
                      "YYYY/MM/DD"
                    ).format("YYYY-MM-DDTHH:mm"),
                    end: moment(
                      user.contract_end_date_english,
                      "YYYY/MM/DD"
                    ).format("YYYY-MM-DDTHH:mm"),
                    projects_expiry_date: user.projects_expiry_date,
                    contract_end_date_english: user.contract_end_date_english,
                    contract_start_date_english:
                      user.contract_start_date_english,
                    clickDate: user.contract_start_date_english,
                    daysCount: moment(
                      user.contract_end_date_english,
                      "YYYY/MM/DD"
                    ).diff(
                      moment(user.contract_start_date_english, "YYYY/MM/DD"),
                      "days"
                    ),
                    title: `${user.employee_name} ${this.lang.transform(
                      "lang_ends_his_project_at"
                    )}`,
                    color: "rgb(197, 53, 53)",
                    name: `${this.lang.transform(
                      "lang_projects"
                    )} ${this.lang.transform(
                      "lang_expiry_date"
                    )} ${this.lang.transform(
                      "lang_before"
                    )} ${this.lang.transform("lang_contract")} ( ${
                      state == "before" ? "-" : ""
                    }${
                      +moment(
                        user.contract_end_date_english,
                        "YYYY/MM/DD"
                      ).diff(
                        moment(user.contract_start_date_english, "YYYY/MM/DD"),
                        "days"
                      ) +
                      " " +
                      this.lang.transform("lang_days")
                    } )`,
                    hidden: this.hidden4 ? true : false,
                    branch_id: +user.branch_id,
                    resource: +user.employee_number,
                  },
                ];
              }
            }
            this.myActiveEvents = this.myEvents;
            this.npContractReports = false;
          } else {
            this.npContractReports = true;
          }
        })
    );
  }
  refDate!: Date;
  selectedDate = new Date();
  navigate(item) {
    this.router.navigate([
      "/human_resources/view/" + item.resource + "/view/" + item.branch_id,
    ]);
  }

  myResourcesClear() {
    let newArr = [];

    for (let index = 0; index < this.myResources.length; index++) {
      const element = this.myResources[index];

      let currentIndex = this.myActiveEvents.findIndex(
        (e) => e.resource == element.id
      );

      if (currentIndex != -1) {
        newArr.push(element);
      }
    }
    this.accualRes = newArr;
  }

  toggleTimelines(type: string) {
    this.myEvents = this.myEvents.map((e) => {
      if (e.type == type) {
        return { ...e, hidden: !e.hidden };
      } else {
        return e;
      }
    });
    if (type == "0") {
      this.hidden1 = !this.hidden1;
    }
    if (type == "1") {
      this.hidden2 = !this.hidden2;
    }
    if (type == "2") {
      this.hidden3 = !this.hidden3;
    }
    if (type == "3") {
      this.hidden4 = !this.hidden4;
    }

    if (type == "4") {
      this.hidden5 = !this.hidden5;
    }
    this.myActiveEvents = this.returnHiddenTrueOnly(this.myEvents);

    this.myResourcesClear();
  }
  hidden1 = false;
  hidden2 = false;
  hidden3 = false;
  hidden4 = false;
  hidden5 = false;
  returnHiddenTrueOnly(array) {
    return array.filter((a) => a.hidden != true);
  }

  public loadbranches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds
        .getActionByUrl([], "human_resources/branches/human_resources_view")
        .subscribe((data) => {
          if (data.status) {
            this.branches = data.branches;
          }
        })
    );
  }

  metaData = [];

  public get_advanced_search_meta_data() {
    this.metaData = [];
    this.subscriptions.add(
      this.ds
        .getActionByUrl([], "human_resources/get_advanced_search_meta_data")
        .subscribe((data) => {
          if (data.status) {
            this.metaData = data.records;
          }
        })
    );
  }
  exportExcel() {
    this.getExcel(this.filters_params);
  }

  openChartData(info) {
    const dialogRef = this.dialog.open(ChartDataInformationComponent, {
      width: "900px",
      data: {
        emps: info,
      },
    });
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        }
      })
    );
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.showSort = false;

    if (this.isMob) {
      const dialogRef = this.dialog.open(FilterEmployeeCardMobComponent, {
        width: "700px",
        data: {
          isMob: this.isMob,
          metaData: this.metaData,
          projects: this.projects,
          branches: this.branches,
        },
      });
      this.subscriptions.add(
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.filters_params = result;
            /*  this.getEmployeeData(this.filters_params, this.sort_params, false); */
            this.get_general_counts(this.filters_params, true);
            this.get_ages_report(this.filters_params, true);
            this.get_vacations_joinings_report(this.filters_params, true);
            this.get_positions_report(this.filters_params, true);
            this.get_contranct_project_report(this.filters_params, true);
          }
        })
      );
    }
  }
  view_profile_image;
  public get_profile_image(url) {
    this.view_profile_image = "";
    this.subscriptions.add(
      this.ds.getActionByUrl([], url).subscribe((data) => {
        if (data.status) {
          return (this.view_profile_image =
            "data:" + data["content-type"] + ";base64," + data.base64);
        }
      })
    );
  }

  filterParams(e) {
    this.filters_params = { ...this.filters_params, ...e };
    this.get_general_counts(this.filters_params, true);
    this.get_ages_report(this.filters_params, true);
    this.get_vacations_joinings_report(this.filters_params, true);
    this.get_positions_report(this.filters_params, true);
    this.get_contranct_project_report(this.filters_params, true);
  }

  public openModelC(template: TemplateRef<any>) {
    this.modalRefC = this.modalService.show(template, environment.modelconfig);
  }

  getExcel(filters: any) {
    this.spinner.show();
    let formData = new FormData();
    this.filtersReturner(filters);
    this.subscriptions.add(
      this.ds
        .postActionByUrl(formData, `human_resources/employees_report_excel`)
        .subscribe(
          (res) => {
            if (res?.status) {
              let url = "data:" + res["content-type"] + ";base64," + res.base64;
              this.spinner.hide();
              let downloadExcel = document.createElement("a");
              downloadExcel.style.display = "none";
              downloadExcel.href = url;
              downloadExcel.download = "Almnabr Employees";
              document.body.appendChild(downloadExcel);
              downloadExcel.click();
              document.body.removeChild(downloadExcel);
              this.pages = "pages";
            } else {
              this.spinner.hide();
            }
          },
          (err) => {
            this.spinner.hide();
          }
        )
    );
  }

  projects = [];
  getAllProjects() {
    this.spinner.show();
    this.subscriptions.add(
      this.ds
        .getActionByUrl([], `human_resources/get_project_subjects`)
        .subscribe(
          (res) => {
            if (res?.status) {
              this.spinner.hide();
              this.projects = res.records;
            } else {
              this.spinner.hide();
              this.projects = [];
            }
          },
          (err) => {
            this.spinner.hide();
            this.projects = [];
          }
        )
    );
  }

  //reports
  getREReport() {
    let formData = new FormData();
    formData.append("filter_type", this.RE_filter_type || "");
    formData.append("report_type", this.RE_report_type || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `human_resources/report_expiry/${this.RE_page_no}/${this.RE_page_size}`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.RE_report_data = res?.records;
            this.RE_total_records = res?.page?.total_records;
          } else {
            this.RE_report_data = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.RE_report_data = [];
        }
      );
  }

  getRECReport() {
    let formData = new FormData();
    formData.append("filter_type", this.REC_filter_type || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `human_resources/report_expiry_contract/${this.REC_page_no}/${this.REC_page_size}`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.REC_report_data = res?.records;
            this.REC_total_records = res?.page?.total_records;
          } else {
            this.REC_report_data = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.REC_report_data = [];
        }
      );
  }

  getREVReport() {
    let formData = new FormData();
    formData.append("filter_type", this.REV_filter_type || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `human_resources/report_expiry_vacation/${this.REV_page_no}/${this.REV_page_size}`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.REV_report_data = res?.records;
            this.REV_total_records = res?.page?.total_records;
          } else {
            this.REV_report_data = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.REV_report_data = [];
        }
      );
  }
  getEPPReport() {
    let formData = new FormData();
    formData.append("filter_type", this.EPP_filter_type || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `form/FORM_EPP/report_epp/${this.EPP_page_no}/${this.EPP_page_size}`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.EPP_report_data = res?.records;
            this.EPP_total_records = res?.page?.total_records;
          } else {
            this.EPP_report_data = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.EPP_report_data = [];
        }
      );
  }
  getENCemployeesReport() {
    let formData = new FormData();
    formData.append("page_size", `${this.ENC_page_size}` || "");
    formData.append("page_no", `${this.ENC_page_no}` || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `human_resources/employees_no_contract`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.ENC_report_data = res?.records;
            this.ENC_total_records = res?.page?.total_records;
          } else {
            this.ENC_report_data = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.ENC_report_data = [];
        }
      );
  }
  getEHCemployeesReport() {
    let formData = new FormData();
    formData.append("page_size", `${this.EHC_page_size}` || "");
    formData.append("page_no", `${this.EHC_page_no}` || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `human_resources/employees_have_contract_no_joining`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.EHC_report_data = res?.records;
            this.EHC_total_records = res?.page?.total_records;
          } else {
            this.EHC_report_data = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.EHC_report_data = [];
        }
      );
  }
  getENJemployeesReport() {
    let formData = new FormData();
    formData.append("page_size", `${this.ENJ_page_size}` || "");
    formData.append("page_no", `${this.ENJ_page_no}` || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `human_resources/employees_no_joining_aftervacation`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.ENJ_report_data = res?.records;
            this.ENJ_total_records = res?.page?.total_records;
          } else {
            this.ENJ_report_data = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.ENJ_report_data = [];
        }
      );
  }
  public buildform() {
    this.form = new FormGroup({
      start_date: new FormControl(""),
      end_date: new FormControl(""),
    });
  }
  getEOVemployeesReport() {
    let formData = new FormData();
    formData.append("page_size", `${this.EOV_page_size}` || "");
    formData.append("page_no", `${this.EOV_page_no}` || "");
    if (this.form.get("start_date").value == "") {
      formData.append("from_date", "");
    } else {
      formData.append(
        "from_date",
        moment(this.form.get("start_date").value).format("YYYY/MM/DD")
      );
    }
    if (this.form.get("start_date").value == "") {
      formData.append("to_date", "");
    } else {
      formData.append(
        "to_date",
        moment(this.form.get("end_date").value).format("YYYY/MM/DD")
      );
    }
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `human_resources/employees_on_vacation`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.EOV_report_data = res?.records;
            this.EOV_total_records = res?.page?.total_records;
          } else {
            this.EOV_report_data = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.EOV_report_data = [];
        }
      );
  }
}
