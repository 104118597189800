<div class="card">
  <div class="card-header">
    {{ "lang_final_result" | language }}
    <i
      style="padding: 0 10px"
      class="fa fa-info-circle orangestatus-color"
      matTooltip="{{ 'lang_final_result_tooltip_tooltip' | language }}"
    ></i>
  </div>
  <div class="card-body pad0">
    <div class="mbrl15">
      <div class="row">
        <div
          class="col-sm-6"
          style="display: flex; align-items: center; justify-content: center"
        >
          <p
            style="font-weight: 600; text-align: center"
            *ngIf="lastStepOpened == 'last'"
          >
            {{ "lang_thanks_msg" | language }}
          </p>
          <p
            style="font-weight: 600; text-align: center"
            *ngIf="lastStepOpened == 'completed'"
          >
            {{ "lang_completed_msg" | language }}
          </p>
        </div>

        <div class="col-sm-6">
          <iframe
            src="./assets/images/last-step2.html"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
