<form [formGroup]="form">
	<div mat-dialog-title>
		<div class="d-flex justify-content-between">
			<h4>{{ title | language }}</h4>
			<span (click)="closeModal()"><i class="fa fa-times"></i></span>
		</div>
	</div>
	<mat-dialog-content class="mat-typography">
		<div *ngIf="verifylist.length > 0">
			<h4>
				{{ "lang_complete_verification" | language }}
			</h4>
		</div>

		<div *ngIf="verifylist.length > 0">
			<p>
				# {{ request?.records?.transaction_request_id || request?.transaction_request_id  }}
				{{ request?.records?.transaction_request_description || request?.transaction_request_description }}
			</p>
			<app-alert id="default-alert"></app-alert>
			<input type="hidden" formControlName="transaction_persons_type" />
			<input type="hidden" formControlName="do" />
			<div class="form-group">
				<label class="mb-10"
					>{{ "lang_how_to_send_verification" | language }} :
				</label>
				<div
					*ngFor="let option of verifylist"
					class="custom-control custom-radio mb-10"
				>
					<input
						type="radio"
						class="custom-control-input zindex9999"
						value="{{ option.value }}"
						formControlName="sender_type"
						id="{{ option.value }}"
					/>
					<label
						class="custom-control-label"
						for="phone"
						*ngIf="option.value == 'phone'"
						>{{ "lang_mobile" | language }}
						<b>( {{ option.label }} ) </b></label
					>
					<label
						class="custom-control-label"
						for="email"
						*ngIf="option.value == 'email'"
						>{{ "lang_email" | language }} <b>( {{ option.label }} ) </b></label
					>
					<label
						class="custom-control-label"
						for="whatsapp"
						*ngIf="option.value == 'whatsapp'"
						>{{ "lang_whatsapp" | language }}
						<b>( {{ option.label }} ) </b></label
					>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<div class="col-md-4 col-xs-12">
			<button
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				[disabled]="form.invalid"
				(click)="sendVerificationCode()"
			>
				{{ codemsg }}
			</button>
		</div>
		<div class="col-md-4 col-xs-12"></div>
		<div class="col-md-4 col-xs-12">
			<button
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				(click)="closeModal()"
			>
				{{ "lang_close" | language }}
			</button>
		</div>
	</mat-dialog-actions>
</form>
