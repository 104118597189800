import { Component, Inject, OnInit } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import moment from "moment";

@Component({
	selector: "app-ba-create-update",
	templateUrl: "./ba-create-update.component.html",
	styleUrls: ["./ba-create-update.component.scss"],
})
export class BaCreateUpdateComponent implements OnInit {
	editMode = false;
	blockAccountForm: FormGroup;
	datesFormArray: FormArray;
	branch_id: any;
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public router: Router,
		private dialogRef: MatDialogRef<BaCreateUpdateComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private fb: FormBuilder,
		private sweetAlert: SweetAlertService
	) {
		this.branch_id = data?.branch_id;
	}

	ngOnInit(): void {
		moment.locale("en");
		this.buildForm();
		if (this.data?.blockAccount) {
			this.editMode = true;
			this.setEditData();
		} else {
			this.editMode = false;
			this.addDatesRecord();
		}
	}

	buildForm() {
		this.blockAccountForm = this.fb.group({
			dates: this.fb.array([], Validators.required),
		});
		this.datesFormArray = this.blockAccountForm.get("dates") as FormArray;
	}
	get dates() {
		return this.blockAccountForm.get("dates") as FormArray;
	}
	public datesRecords(value?): FormGroup {
		return this.fb.group({
			start_date: new FormControl(value ? value?.start_date : null, [
				Validators.required,
			]),
			end_date: new FormControl(value ? value?.end_date : null, [
				Validators.required,
			]),
		});
	}
	public addDatesRecord(value?) {
		this.datesFormArray.push(this.datesRecords(value));
	}
	public removeDatesRecord(index) {
		this.datesFormArray.removeAt(index);
	}

	setEditData() {
		this.addDatesRecord({
			start_date: new Date(this.data?.blockAccount?.start_date),
			end_date: new Date(this.data?.blockAccount?.end_date),
		});
	}

	onSubmit() {
		if (this.datesFormArray.valid) {
			let formData = new FormData();
			formData.append("branch_id", this.branch_id || "");
			this.datesFormArray.controls.forEach((el, key) => {
				let startDate = moment(el?.get("start_date").value).format(
					"YYYY/MM/DD"
				);
				let endDate = moment(el?.get("end_date").value).format("YYYY/MM/DD");
				formData.append(`dates[${key}][start_date]`, startDate || "");
				formData.append(`dates[${key}][end_date]`, endDate || "");
			});
			this.spinner.show();
			this.ds.postActionByUrl(formData, "acc/cblocksystem").subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.dialogRef.close(true);
						this.sweetAlert.successToast(res?.msg, 2000);
					} else {
						this.sweetAlert.errorToast(res?.msg, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
		}
	}
}
