import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { VersionsShowComponent } from "@shared/components/versions-show/versions-show.component";
import { ApproveTransactionsComponent } from "./tcore/approve-transactions/approve-transactions.component";
import { CronComponent } from "./tcore/cron/cron.component";
import { TcreateComponent } from "./tcore/tcreate/tcreate.component";
import { TeditComponent } from "./tcore/tedit/tedit.component";
import { TlistComponent } from "./tcore/tlist/tlist.component";
import { TviewComponent } from "./tcore/tview/tview.component";
import { ProjectTransactionFormCreateComponent } from "./project-transaction-form-create/project-transaction-form-create.component";
import { FormWirNewComponent } from "./FORM_WRI/form-wir-new/form-wir-new.component";

const routes: Routes = [
	{
		path: "allforms",
		component: TlistComponent,
		data: { title: "Transactions", titleI18n: "lang_documents" },
	},
	{
		path: "nBU2waRF8N",
		component: ApproveTransactionsComponent,
		data: { title: "Transactions", titleI18n: "lang_approve_transactions" },
	},
	{
		path: "KmRJAQGkZJ",
		component: CronComponent,
		data: { title: "Transactions", titleI18n: "lang_cron_transactions" },
	},
	{
		path: "form/:form_key/cr/:form_request_id",
		component: TcreateComponent,
		data: { title: "Transactions", titleI18n: "lang_new_transactions" },
	},
	{
		path: "form/:form_key/vr/:form_request_id",
		component: TviewComponent,
		data: { title: "Transactions", titleI18n: "lang_view_transactions" },
	},
	// {
	//   path : 'form/:form_key/vr/:form_request_id/:page',
	//   component: TviewComponent,
	//   data: { title: 'Transactions', titleI18n: 'lang_view_transactions' },
	// },
	{
		path: "form/:form_key/vr/:form_request_id/versions",
		component: VersionsShowComponent,
		data: { title: "Transactions", titleI18n: "lang_form_versions" },
	},
	{
		path: "form/:form_key/er/:form_request_id",
		component: TeditComponent,
		data: { title: "Transactions", titleI18n: "lang_edit_transactions" },
	},
	{
		path: "form/:form_key/er/:form_request_id/:type",
		component: TeditComponent,
		data: { title: "Transactions", titleI18n: "lang_edit_transactions" },
	},
	{
		path: "form/create",
		component: ProjectTransactionFormCreateComponent,
	},
	{
		path: "form/WIR/new",
		component: FormWirNewComponent,
		data: { title: "Transactions", titleI18n: "lang_form_versions" },
	},
];
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class TransactionsRoutingModule {}
