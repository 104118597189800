<div class="card pstr-step1">
	<div class="card-header">{{ "lang_configurations" | language }}</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<mat-horizontal-stepper #stepper [linear]="true" class="pad0">
				<mat-step [stepControl]="lang_form">
					<form [formGroup]="lang_form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-building fa-icon"></i>
								</div>
							</div>
							<div>Language:</div>
						</ng-template>
						<div class="row mb-10">
							<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
								<label>
									{{ "lang_languages" | language }}
									<span class="red">*</span></label
								>
								<select
									class="form-control almnabrformcontrol"
									formControlName="lang_key"
								>
									<option value="">
										{{ "lang_choose_options" | language }}
									</option>
									<option
										*ngFor="let lang of languages"
										value="{{ lang.value }}"
									>
										{{ lang.label }}
									</option>
								</select>
								<mat-error
									*ngIf="
										submitted && lang_form.controls['lang_key'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10 col-sm-8 col-xs-12 mb-10">
								<app-alert id="default-alert"></app-alert>
							</div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperNext
								>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>
				<!-- [stepControl]="step2IsValid" -->
				<mat-step>
					<form [formGroup]="work_sites_form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-building fa-icon"></i>
								</div>
							</div>
							<div>Work Site & Levels</div>
						</ng-template>
						<div class="row p-1">
							<div class="col-sm-12">
								<label class="mb-10"
									>{{ "lang_separate" | language }} :
									<span class="red">*</span></label
								>
								<div class="col-sm-12">
									<mat-radio-group
										formControlName="separate"
										(change)="
											work_sites_form.controls['work_site'].setValue('')
										"
									>
										<mat-radio-button value="1">{{
											"lang_yes" | language
										}}</mat-radio-button>
										<mat-radio-button value="0">{{
											"lang_no" | language
										}}</mat-radio-button>
									</mat-radio-group>
								</div>
							</div>
						</div>
						<div class="row mb-10 p-1">
							<div class="col-sm-12 col-xs-12 mb-10">
								<label class="mb-10"
									>{{ "lang_work_site" | language }} :
									<span class="red">*</span></label
								>
								<div class="">
									<mat-radio-group
										formControlName="work_site"
										(change)="
											search_required_datas('ALL'); set_validation_step1()
										"
									>
										<mat-radio-button
											value="ALL"
											*ngIf="work_sites_form.get('separate').value == '0'"
											>{{ "lang_for_all_units" | language }}</mat-radio-button
										>
										<mat-radio-button value="GN">{{
											"lang_by_general_number" | language
										}}</mat-radio-button>
										<mat-radio-button value="IM">{{
											"lang_by_phases" | language
										}}</mat-radio-button>
									</mat-radio-group>
								</div>
								<mat-error
									*ngIf="
										submitted &&
										work_sites_form.controls['work_site'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div
								class="col-sm-6 col-xs-12 mb-10 mt-2"
								*ngIf="work_sites_form.get('work_site').value == 'ALL'"
							>
								<label class="mb-10"
									>{{ "lang_fill_worklevels" | language }} :
									<span class="red">*</span></label
								>

								<ng-select
									[items]="work_levels_for_all"
									[multiple]="true"
									bindLabel="label"
									bindValue="value"
									clearAllText="Clear"
									[searchFn]="ds.customSearchFn"
									appendTo="body"
									[formControl]="work_levels_for_all_control"
									placeholder="{{ 'lang_fill_worklevels' | language }}"
								>
								</ng-select>
								<mat-error
									*ngIf="work_levels_for_all_control.errors?.required"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div
								class="col-sm-12 mb-2"
								*ngIf="work_sites_form.get('work_site').value == 'GN'"
							>
								<div
									class="table-responsive dropdownyes customResponsive tablefields"
								>
									<table border="1" class="table CustomTable">
										<thead>
											<tr>
												<th>#</th>
												<th>
													<i
														class="fa fa-plus green"
														style="font-size: 15px"
														(click)="addByGeneralNumbers()"
														#tooltip="matTooltip"
														matTooltip="Add"
													></i>
												</th>
												<th>Units</th>
												<th>Work Level</th>
											</tr>
										</thead>
										<tbody *ngIf="generalNumbersData.length">
											<tr
												*ngFor="
													let item of generalNumbersData;
													let i = index;
													let odd = odd;
													let even = even
												"
											>
												<td>{{ i + 1 }}</td>
												<td>
													<i
														class="fa fa-trash red"
														style="font-size: 15px"
														(click)="deleteByGeneralNumbers(i)"
														#tooltip="matTooltip"
														matTooltip="Delete"
													></i>
												</td>
												<td>{{ item.units }}</td>
												<td>{{ item.work_levels }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div class="row pb-5">
							<div
								class="col-sm-12 mb-2"
								*ngIf="work_sites_form.get('work_site').value == 'IM'"
							>
								<div
									class="table-responsive dropdownyes customResponsive tablefields"
								>
									<table border="1" class="table CustomTable">
										<thead>
											<tr>
												<th>#</th>
												<th>
													<i
														class="fa fa-plus green"
														style="font-size: 15px"
														(click)="addByPhases()"
														#tooltip="matTooltip"
														matTooltip="Add"
													></i>
												</th>
												<th>Zones</th>
												<th>Blocks</th>
												<th>Clusters</th>
												<th>Units</th>
												<th>Work Level</th>
											</tr>
										</thead>
										<tbody *ngIf="phasesData.length">
											<tr
												*ngFor="
													let item of phasesData;
													let i = index;
													let odd = odd;
													let even = even
												"
											>
												<td>{{ i + 1 }}</td>
												<td>
													<i
														class="fa fa-trash red"
														style="font-size: 15px"
														(click)="deleteByPhases(i)"
														#tooltip="matTooltip"
														matTooltip="Delete"
													></i>
												</td>
												<td>{{ item.zone }}</td>
												<td>{{ item.block }}</td>
												<td>{{ item.cluster }}</td>
												<td>{{ item.units }}</td>
												<td>{{ item.work_levels }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperPrevious
								>
									{{ "lang_previous" | language }}
								</button>
							</div>
							<div class="col-md-8 col-sm-4 col-xs-12 mb-10">
								<app-alert id="default-alert"></app-alert>
							</div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperNext
									(click)="send_units_and_work_levels()"
								>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>

				<mat-step [stepControl]="si_form">
					<form [formGroup]="si_form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-paperclip fa-icon"></i>
								</div>
							</div>
							<div>SI Request</div>
						</ng-template>

						<div class="row p-3">
							<div class="col-sm-6 mb-2">
								<label for="si_name">Site Instraction Name</label>
								<ng-select
									[items]="si_name_array"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									clearAllText="Clear"
									[searchFn]="ds.customSearchFn"
									appendTo="body"
									formControlName="si_name"
									placeholder="{{ 'lang_si_name' | language }}"
								>
								</ng-select>
							</div>

							<div class="col-sm-6 mb-2">
								<label for="new_si_name"
									>Add New Site Instraction
									<i
										class="fa fa-info-circle"
										matTooltip="optional field, type your new SI."
									></i
								></label>

								<textarea
									name="new_si_name"
									id="new_issue"
									cols="30"
									rows="5"
									style="max-height: 300px; min-height: 150px"
									formControlName="new_si_name"
									placeholder="{{ 'lang_new_si_name' | language }}"
									autocomplete="off"
									class="form-control almnabrformcontrol"
								>
								</textarea>
							</div>
							<div class="col-sm-4">
								<label for="attachments">Upload Attachments</label>
								<div>
									<app-dynamic-files-control
										(getAttachments)="getAttachments($event)"
										[rawIndex]="0"
										[actionMode]="'ADD'"
									></app-dynamic-files-control>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperPrevious
								>
									{{ "lang_previous" | language }}
								</button>
							</div>
							<div class="col-md-8 col-sm-4 col-xs-12 mb-10">
								<app-alert id="default-alert"></app-alert>
							</div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									(click)="send_files_to_step2()"
								>
									{{ "lang_submit" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>
			</mat-horizontal-stepper>
		</div>
	</div>
</div>
