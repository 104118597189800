<breadcrumb></breadcrumb>
<div class="row">
  <div class="col-md-12">
    <section class="panel-expansion matborderno">
      <mat-accordion>
        <mat-expansion-panel class="mpanel" [expanded]="true">
          <mat-expansion-panel-header class="mpanel-header">{{
            "lang_create_wafi_reports" | language
          }}</mat-expansion-panel-header>
          <div class="material-panel-body">
            <div class="mbrl15 martb0">
              <div class="row">
                <div class="col-xs-12 col-12 pad0">
                  <form id="signupForm" class="editForm">
                    <mat-horizontal-stepper #stepper class="pad0">
                      <mat-step>
                        <ng-template matStepLabel>
                          <div class="step">
                            <div class="st">
                              <i class="icon-5x fa fa-flag fa-icon"></i>
                            </div>
                          </div>
                          <div>
                            {{ "lang_main_reports" | language }}
                          </div>
                        </ng-template>
                        <app-main-reports
                          [report_data]="report_data"
                        ></app-main-reports>
                      </mat-step>
                      <mat-step>
                        <ng-template matStepLabel>
                          <div class="step">
                            <div class="st">
                              <i class="icon-5x fa fa-line-chart fa-icon"></i>
                            </div>
                          </div>
                          <div>
                            {{ "lang_performance_indices" | language }}
                          </div>
                        </ng-template>
                        <app-wafi-performance-indices
                          [report_data]="report_data"
                        >
                        </app-wafi-performance-indices>
                      </mat-step>
                      <mat-step>
                        <ng-template matStepLabel>
                          <div class="step">
                            <div class="st">
                              <i class="icon-5x fa fa-star fa-icon"></i>
                            </div>
                          </div>
                          <div>
                            {{ "lang_quality_management" | language }}
                          </div>
                        </ng-template>
                        <app-quality-management></app-quality-management>
                      </mat-step>
                      <mat-step>
                        <ng-template matStepLabel>
                          <div class="step">
                            <div class="st">
                              <i class="icon-5x fa fa-building fa-icon"></i>
                            </div>
                          </div>
                          <div>
                            {{ "lang_construction_management" | language }}
                          </div>
                        </ng-template>
                        <app-construction-management></app-construction-management>
                      </mat-step>
                      <mat-step>
                        <ng-template matStepLabel>
                          <div class="step">
                            <div class="st">
                              <i class="icon-5x fa fa-area-chart fa-icon"></i>
                            </div>
                          </div>
                          <div>
                            {{ "lang_project_zones" | language }}
                          </div>
                        </ng-template>
                        <app-project-zones></app-project-zones>
                      </mat-step>
                      <mat-step>
                        <ng-template matStepLabel>
                          <div class="step">
                            <div class="st">
                              <i
                                class="icon-5x fa fa-exclamation-triangle fa-icon"
                              ></i>
                            </div>
                          </div>
                          <div>
                            {{ "lang_risk_register" | language }}
                          </div>
                        </ng-template>
                        <app-risk-register
                          [report_data]="report_data"
                        ></app-risk-register>
                      </mat-step>
                      <mat-step>
                        <ng-template matStepLabel>
                          <div class="step">
                            <div class="st">
                              <i class="icon-5x fa fa-exclamation fa-icon"></i>
                            </div>
                          </div>
                          <div>
                            {{ "lang_issue_log" | language }}
                          </div>
                        </ng-template>
                        <app-issue-log
                          [report_data]="report_data"
                        ></app-issue-log>
                      </mat-step>
                      <mat-step>
                        <ng-template matStepLabel>
                          <div class="step">
                            <div class="st">
                              <i class="icon-5x fa fa-medkit fa-icon"></i>
                            </div>
                          </div>
                          <div>
                            {{ "lang_hse_management" | language }}
                          </div>
                        </ng-template>
                        <app-hse-management
                          [report_data]="report_data"
                        ></app-hse-management>
                      </mat-step>
                      <mat-step>
                        <ng-template matStepLabel>
                          <div class="step">
                            <div class="st">
                              <i class="icon-5x fa fa-code-fork fa-icon"></i>
                            </div>
                          </div>
                          <div>
                            {{ "lang_infrastructure_works" | language }}
                          </div>
                        </ng-template>
                        <app-infrastructure-works></app-infrastructure-works>
                      </mat-step>
                    </mat-horizontal-stepper>
                  </form>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <app-alert id="default-alert"></app-alert>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </section>
  </div>
</div>
