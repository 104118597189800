export interface AppSettings {
  navPos?: 'side' | 'top';
  dir?: 'ltr' | 'rtl';
  theme?: 'light' | 'dark';
  showHeader?: boolean;
  headerPos?: 'fixed' | 'static' | 'above';
  showUserPanel?: boolean;
  sidenavOpened?: boolean;
  sidenavCollapsed?: boolean;
  language?: string;
}
export const defaults: AppSettings = {
  navPos: 'side',
  dir: (localStorage.getItem('direction') === "rtl") ? 'rtl' : 'ltr',
  theme: (localStorage.getItem('theme') === "dark") ? 'dark' : 'light',
  showHeader: true,
  headerPos: 'fixed',
  showUserPanel: true,
  sidenavOpened: false,
  sidenavCollapsed: false,
  language: 'en',
};
