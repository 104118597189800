import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { throws } from 'assert';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-group2',
  templateUrl: './group2.component.html',
  styleUrls: ['./group2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Group2Component),
      multi: true
    }
  ]
})
export class Group2Component implements OnInit, ControlValueAccessor, OnChanges, AfterViewInit {
  @Input() project_data;
  @Input() template_id;
  @Input() supervision_data;
  @Input() tree_data;
  @Input() typeIndex;
  @Input() group1Index;
  @Input() step1formvalue;
  @Input() weightunits;
  @Input() access_codes;
  @Input() maintotalamount;
  @Input() this_template_total_cost;
  @Input() step2formvalue;
  @Input() actionMode;
  @Input() group2_data;
  @Input() type_code;
  @Input() fconsultants;
  @Input() consultantr;
  @Input() positions;
  @Input() buildingCodes;
  @Input() ncrSiIsssues;
  @Input() step2form : FormGroup;
  @Output() group2TotalEvent = new EventEmitter<any>();

  form:FormGroup;
  group2formarray:FormArray;
  //extra Type
  modalRefEG2: BsModalRef;
  extragroup2:any = []; //array list
  extragroup2data:any = null; //ngmodel
  @Output() group2_total_ratio = new EventEmitter<any>();
  constructor(private cdr: ChangeDetectorRef, public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  writeValue(obj: any): void {
    if(obj) {
      this.group2formarray.controls = [];
      this.group2validate.controls = [];
      obj.forEach((v, k) => {
        this.group2formarray.push(this.group2_records({
          platform_group2_code_system         : v.platform_group2_code_system,
          platform_group2_code_manual         : v.platform_group2_code_manual,
          group2name                          : v.platform_group2_code_title,
          platform_group2_code_total          : v.platform_group2_code_total,
          platform_group2_code_total_ratio    : v.platform_group2_code_total_ratio,
          system_total_amount                 : v.system_total_amount,
          manual_total_amount                 : v.manual_total_amount,
          system_total_ratio                  : v.system_total_ratio,
          manual_total_ratio                  : v.manual_total_ratio,
          plaftformData                       : v.plaftformData,
        }));
      });
    }
  }
  registerOnChange(fn: any): void {
    fn(this.group2validate.value);
    this.form.valueChanges.subscribe(x => {
        fn(this.group2validate.value);
    })
  }
  registerOnTouched(fn: any): void {
    fn(this.group2validate.value);
    this.form.valueChanges.subscribe(x => {
        fn(this.group2validate.value);
    })
  }
  setDisabledState?(isDisabled: boolean): void {}
  ngOnInit(): void {
    this.tree_data = this.tree_data;
    this.form = new FormGroup({
        group2array : this.fb.array([]),
    });
    this.group2formarray = this.form.get("group2array") as FormArray;
    this.appendcontrols();
    this.form.valueChanges.subscribe(x => {
      // this.get_group2_total();
    });
    if(this.actionMode == "VIEW") {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.tree_data = this.tree_data;
  }
  //group 2 Title
  public group2_title_total(controls) {
    let lang    = this.lang.transform('lang_projects_total_title');
    let one     = lang.replace('%S', controls.get('platform_group2_code_title').value) ;
    return one.replace('%T', '<b>' + this.ds.addCommas((parseInt(controls.get('platform_group2_code_total').value) >=0 ) ? parseFloat(controls.get('platform_group2_code_total').value).toFixed(2) : 0.00) + '</b>');
  }
  //total Ratio
  public group2_title_total_ratio(controls) {
    let lang    = this.lang.transform('lang_projects_total_ratio_title');
    let one     = lang.replace('%S', controls.get('platform_group2_code_title').value) ;
    return one.replace('%R', '<b>' + this.ds.addCommas(controls.get('platform_group2_code_total_ratio').value) + '</b>');
  }  
  public items_group2() {
		return this.form.get("group2array") as FormArray;
  }
  get group2validate() {
		return this.form.get("group2array") as FormArray;
	}
  public group2_records(value): FormGroup {
		return this.fb.group({
      platform_group2_code_system         : new FormControl(value.platform_group2_code_system, [Validators.required]),
      platform_group2_code_manual         : new FormControl({value : value.platform_group2_code_manual, disabled : (this.actionMode == "VIEW") ? true : false}),
      platform_group2_code_title          : new FormControl(value.group2name),
      platform_group2_code_total          : new FormControl(value?.platform_group2_code_total, [Validators.required]),
      platform_group2_code_total_ratio    : new FormControl(value?.platform_group2_code_total_ratio),
      system_total_amount                 : new FormControl(value?.system_total_amount),
      manual_total_amount                 : new FormControl(value?.manual_total_amount),
      system_total_ratio                  : new FormControl(value?.system_total_ratio),
      manual_total_ratio                  : new FormControl(value?.manual_total_ratio),
      group2Platforms                     : new FormControl(value?.platforms),
      plaftformData                       : new FormControl(value?.plaftformData, [Validators.required]),

    });
  }
  public appendcontrols() {
    this.group2formarray.controls = [];
    if(this.group2_data) {
      var group2   = [];
      let records = this.group2_data;
      Object.keys(records).forEach(function(key) {
        group2.push(records[key]);
      });
      if(group2.length > 0) {
        group2.forEach((v, k) => {
          this.group2formarray.push(this.group2_records(v));
        });
      }
    }
  }
  public openModalG2(template: TemplateRef<any>) {
    this.modalRefEG2 = this.modalService.show(template, environment.modelconfig);
  }
  
  public get_extra_group2(template) {
    this.extragroup2 = [];
    let group2array = [];
    this.group2validate.value.forEach((v, k) => {
      group2array.push(v.platform_group2_code_system);
    });
    let param = new FormData();
    param.append('platform_group1_code_system', this.step2form.value['group1Data'][this.group1Index].platform_group1_code_system);
    param.append('platform_group_type_code_system', this.step2form.value['group1Data'][this.group1Index]['typesData'][this.typeIndex].platform_group_type_code_system);
    param.append('platform_group2_code_system_not_in', group2array.toString());
    param.append('projects_services_ids', this.supervision_data.projects_services_id);
    this.spinner.show();
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgtwo').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.extragroup2 = res.records;
        this.openModalG2(template);
      } else {
        this.ds.dialogf('', res.error);
      }
    }, error => {
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      this.spinner.hide();
    });
  }
  public add_extra_group2_items() {
    this.spinner.show();
    if(this.extragroup2data && this.extragroup2data.length > 0) {
      this.extragroup2data.forEach((v, k) => {
        this.group2formarray.push(this.group2_records({
          platform_group2_code_system: v.value,
          platform_group2_code_manual : v.code,
          group2name : v.label
        }));
      });
    }
    this.extragroup2 = [];
    this.extragroup2data = null;
    setTimeout(() => {
      this.spinner.hide();
      this.modalRefEG2.hide();
    }, 200);
  }

  getPlatformsRatio(e, indx) {
    this.group2validate.controls[indx].get(
      'platform_group2_code_total_ratio'
    ).setValue(e?.total_ratio);
    this.group2validate.controls[indx].get(
      'platform_group2_code_total'
    ).setValue(e?.total_price);
    this.getGroup2TotalRatio();
  }

  getGroup2TotalRatio() {
    let group2 = this.group2validate.value;
    let ratioArr = group2.map(el => el['platform_group2_code_total_ratio']);
    let priceArr = group2.map(el => el['platform_group2_code_total']);
    let group2TotalRatio = parseFloat('0.00');
    let group2TotalPrice = parseFloat('0.00');
    for(let ratio of ratioArr) {
      group2TotalRatio += parseFloat(ratio);
    }
    for(let price of priceArr) {
      group2TotalPrice += parseFloat(price);
    }
    this.group2_total_ratio.emit({total_ratio:group2TotalRatio.toFixed(2), total_price:group2TotalPrice.toFixed(2)});
  }
}