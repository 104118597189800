import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-pq1-step6",
	templateUrl: "./pq1-step6.component.html",
	styleUrls: ["./pq1-step6.component.scss"],
})
export class Pq1Step6Component implements OnInit {
	@Input() lastStepOpened;
	constructor() {}

	ngOnInit(): void {}
}
