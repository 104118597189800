<form [formGroup]="form" style="min-height: 100vh; overflow: hidden">
	<div class="row" style="min-height: 100vh">
		<div class="col-md-6 d-none d-md-block side-bg">
			<div class="overlay"></div>
		</div>
		<div class="col-md-6" style="background-color: #fff">
			<div class="login-header">
				<div class="languages">
					<mat-form-field appearance="standard" style="width: 100%">
						<mat-select [(value)]="lang_key">
							<mat-option
								[value]="lang.lang_key"
								*ngFor="let lang of languages"
								(click)="switchlanguage(lang)"
								>{{ lang.lang_name | language }}</mat-option
							>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="logo">
					<img src="assets/images/login-logo.png" alt="" />
				</div>
			</div>
			<div class="login-body">
				<h3 class="mb-4">{{ "lang_welcome" | language }}</h3>
				<div class="choose-send-code" *ngIf="currentIndex == 0">
					<label
						id="example-radio-group-label"
						style="width: 100%; text-align: start"
						>{{ "lang_send_code" | language }}:</label
					>
					<mat-radio-group
						aria-labelledby="example-radio-group-label"
						formControlName="method"
					>
						<mat-radio-button value="email" style="display: block">{{
							"lang_email" | language
						}}</mat-radio-button>
						<mat-radio-button value="mobile" style="display: block">{{
							"lang_mobile" | language
						}}</mat-radio-button>
						<mat-radio-button value="whatsapp" style="display: block">{{
							"lang_whatsapp" | language
						}}</mat-radio-button>
					</mat-radio-group>
					<input
						type="text"
						class="mnabr-login-input"
						formControlName="value"
						required
						placeholder="{{ 'lang_' + form.get('method').value | language }}"
						[attr.style]="
							lang_key == 'ar'
								? 'border-right: 10px solid #0e3768d9;'
								: 'border-left: 10px solid #0e3768d9;'
						"
						*ngIf="form.get('method').value != 'mobile'"
					/>
					<div class="phone-input" *ngIf="form.get('method').value == 'mobile'">
						<ng-select
							#select
							[items]="allCountries"
							[multiple]="false"
							bindLabel="name"
							bindValue="phone"
							[clearable]="false"
							[searchable]="false"
							appendTo="body"
							formControlName="phone_code"
						>
							<ng-template ng-label-tmp let-item="item">
								{{ item.name }} (+{{ item.phone }})
							</ng-template>
							<ng-template
								ng-option-tmp
								let-item="item"
								let-index="index"
								let-search="searchTerm"
							>
								{{ item.name }} (+{{ item.phone }})
							</ng-template>
							<ng-template ng-header-tmp>
								<input
									style="
										width: 100%;
										line-height: 24px;
										border: 1px solid #cdcdcd;
										border-radius: 3px;
									"
									type="text"
									placeholder="{{ 'lang_search' | language }}"
									(input)="select.filter($event.target.value)"
								/>
							</ng-template>
						</ng-select>
						<input
							type="text"
							class="phi-input mnabr-login-input"
							formControlName="mobile"
							[ngClass]="{
								'error-class':
									form?.errors?.wrongNumber?.value &&
									form.get('mobile')?.touched
							}"
						/>
					</div>
					<div class="d-flex justify-content-end mt-3">
						<button class="goto-sendcode" type="button" (click)="sendCode()">
							<span>{{ "lang_send_code" | language }}</span>
							<i
								class="pi"
								[ngClass]="{
									'pi-arrow-right': lang_key == 'en',
									'pi-arrow-left': lang_key == 'ar'
								}"
								(click)="sendCode()"
							></i>
						</button>
					</div>
				</div>
				<div class="confirm-code" *ngIf="currentIndex == 1">
					<input
						type="text"
						class="mnabr-login-input"
						formControlName="otp_code"
						required
						placeholder="{{ 'lang_verification_code' | language }}"
						[attr.style]="
							lang_key == 'ar'
								? 'border-right: 10px solid #0e3768d9;'
								: 'border-left: 10px solid #0e3768d9;'
						"
					/>
					<div class="d-flex justify-content-end mt-3">
						<button class="goto-sendcode" type="button" (click)="moveToPrev()">
							<i
								class="pi"
								[ngClass]="{
									'pi-arrow-right': lang_key == 'ar',
									'pi-arrow-left': lang_key == 'en'
								}"
							></i>
							<span>{{ "lang_previous" | language }}</span>
						</button>
					</div>
				</div>
				<input
					*ngIf="form.get('otp_code').valid"
					type="submit"
					class="mat-accent submit-btn"
					value="{{ 'lang_login' | language }}"
					(click)="login()"
				/>
			</div>
		</div>
	</div>
</form>
