<breadcrumb></breadcrumb>
<section class="panel-expansion">
    <mat-accordion class="stylepanels">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{'lang_contractors_create' | language}}</mat-expansion-panel-header>
        <div class="material-panel-body">
            <form [formGroup]="registrationForm" (ngSubmit)="create();" class="create">
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_branch' | language }} :<span class="red">*</span></label>
                                <select class="form-control almnabrformcontrol" formControlName="branch_id">
                                    <option value="">{{'lang_choose_options' | language }}</option>
                                    <option *ngFor="let branch of branches" value="{{branch.branch_id}}">{{branch.branch_name}}</option>
                                </select>
                                <mat-error *ngIf="registrationForm.controls['branch_id'].touched && registrationForm.controls['branch_id'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_human_resources_nameen' | language }} :<span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol contractor_title_en" placeholder="{{'lang_human_resources_nameen' | language }}" formControlName="contractor_title_en" />
                                <mat-error *ngIf="registrationForm.controls['contractor_title_en'].touched && registrationForm.controls['contractor_title_en'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_human_resources_namear' | language }} :<span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol contractor_title_ar" placeholder="{{'lang_human_resources_namear' | language }}" formControlName="contractor_title_ar" />
                                <mat-error *ngIf="registrationForm.controls['contractor_title_ar'].touched && registrationForm.controls['contractor_title_ar'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_email_address' | language }} :<span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol contractor_email_address" placeholder="{{'lang_email_address' | language }}" formControlName="contractor_email_address" />
                                <mat-error *ngIf="registrationForm.controls['contractor_email_address'].touched && registrationForm.controls['contractor_email_address'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                <mat-error *ngIf="registrationForm.controls['contractor_email_address'].touched && registrationForm.controls['contractor_email_address'].errors?.email"> {{'lang_field_valid_email' | language }} </mat-error>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_mobile_number' | language }} :<span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol contractor_mobile" placeholder="{{'lang_mobile_number' | language }}" minlength="12" maxlength="12" (keypress)="ds.numberOnly($event)" formControlName="contractor_mobile" />
                                <mat-error *ngIf="registrationForm.controls['contractor_mobile'].touched && registrationForm.controls['contractor_mobile'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                <mat-error *ngIf="registrationForm.controls['contractor_mobile'].errors?.minlength"> {{'lang_minimum' | language }} 12 </mat-error>
                                <mat-error *ngIf="registrationForm.controls['contractor_mobile'].errors?.maxlength"> {{'lang_maximum' | language }} 12 </mat-error>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_vat_tax' | language }} :<span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol contractor_tax_vat" placeholder="{{'lang_vat_tax' | language }}" formControlName="contractor_tax_vat" />
                                <mat-error *ngIf="registrationForm.controls['contractor_tax_vat'].touched && registrationForm.controls['contractor_tax_vat'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_ccr_no' | language }} : </label>
                                <input type="text" class="form-control almnabrformcontrol contractor_ccr_no" placeholder="{{'lang_ccr_no' | language }}" formControlName="contractor_ccr_no" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_manager' | language }} :<span class="red">*</span></label>
                                <ng-select 
                                    [items]="users" 
                                    [multiple]="true" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body" 
                                    formControlName="contractor_managers"
                                    placeholder="{{'lang_manager' | language}}"
                                    (search)="search_users($event)"
                                    (focus)="users = []">
                                </ng-select>
                                <mat-error *ngIf="registrationForm.controls['contractor_managers'].touched && registrationForm.controls['contractor_managers'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_users' | language }} :<span class="red">*</span></label>
                                <ng-select 
                                    [items]="users" 
                                    [multiple]="true" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body" 
                                    formControlName="contractor_teams"
                                    placeholder="{{'lang_users' | language}}"
                                    (search)="search_users($event)"
                                    (focus)="users = []">
                                </ng-select>
                                <mat-error
                                    *ngIf="registrationForm.controls['contractor_teams'].touched && registrationForm.controls['contractor_teams'].errors?.required">
                                    {{'lang_field_required' | language }}
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_link_with_accounts' | language }} :<span class="red">*</span></label>
                                <select type="text" class="form-control almnabrformcontrol contractor_link_with_accounts" matTooltip="{{'lang_link_with_accounts' | language }}" formControlName="contractor_link_with_accounts" (change)="emptyAccountFileds();">
                                    <option value="">{{'lang_choose_options' | language }}</option>
                                    <option value="1">{{'lang_yes' | language }}</option>
                                    <option value="0">{{'lang_no' | language }}</option>
                                </select>
                                <mat-error *ngIf="registrationForm.controls['contractor_link_with_accounts'].touched && registrationForm.controls['contractor_link_with_accounts'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_logo' | language }} : <mat-icon *ngIf="isFile" matTooltip="{{'lang_delete' | language}}"  (click)="removeFile()" class="red mnabricon">close</mat-icon></label>
                                <div class="IDDIV">
                                    <input name="logo" type="file" onclick="this.value = null" (change)="uploadFile($event)" class="nghide albutton width100">
                                    <label id="uploadButton" class="albutton">{{filename}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="registrationForm.get('contractor_link_with_accounts').value == '1' ">
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_address_text' | language }} :</label>
                                <input type="text" class="form-control almnabrformcontrol contractor_address" placeholder="{{'lang_address_text' | language }}" formControlName="contractor_address" />
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_credit_days' | language }} : </label>
                                <input type="text" class="form-control almnabrformcontrol contractor_credit_days" placeholder="{{'lang_credit_days' | language }}" (keypress)="ds.numberOnly($event)" formControlName="contractor_credit_days" />
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_security_amount' | language }} : </label>
                                <input type="text" class="form-control almnabrformcontrol contractor_security_amount" placeholder="{{'lang_security_amount' | language }}" (keypress)="ds.numberOnly($event)" formControlName="contractor_security_amount" />
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_credit_limit' | language }} : </label>
                                <input type="text" class="form-control almnabrformcontrol contractor_credit_limit" placeholder="{{'lang_credit_limit' | language }}" (keypress)="ds.numberOnly($event)" formControlName="contractor_credit_limit" />
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="registrationForm.get('contractor_link_with_accounts').value == '1' ">
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_discount_limit' | language }} : </label>
                                <input type="text" class="form-control almnabrformcontrol contractor_discount_limit" placeholder="{{'lang_discount_limit' | language }}" formControlName="contractor_discount_limit" (keypress)="ds.numberOnly($event)" />
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_discount_on_payment' | language }} : </label>
                                <input type="text" class="form-control almnabrformcontrol contractor_discount_onpay" placeholder="{{'lang_discount_on_payment' | language }}" formControlName="contractor_discount_onpay" (keypress)="ds.numberOnly($event)" />
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_salesman' | language }} : </label>
                                <select type="text" class="form-control almnabrformcontrol contractor_salesmancode" matTooltip="{{'lang_salesman' | language }}" formControlName="contractor_salesmancode">
                                    <option value="">{{'lang_choose_options' | language }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_salesman_area' | language }} : </label>
                                <select type="text" class="form-control almnabrformcontrol contractor_salesmanarea" matTooltip="{{'lang_salesman_area' | language }}" formControlName="contractor_salesmanarea">
                                    <option value="">{{'lang_choose_options' | language }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="registrationForm.get('contractor_link_with_accounts').value == '1' ">
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_stop_transaction' | language }} : </label>
                                <select type="text" class="form-control almnabrformcontrol contractor_stop_transaction" matTooltip="{{'lang_link_with_accounts' | language }}" formControlName="contractor_stop_transaction">
                                    <option value="">{{'lang_choose_options' | language }}</option>
                                    <option value="1">{{'lang_yes' | language }}</option>
                                    <option value="0">{{'lang_no' | language }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{'lang_account' | language }} : </label>
                                <ng-select 
                                    [items]="accounts" 
                                    [multiple]="false" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body" 
                                    formControlName="contractor_account_id"
                                    placeholder="{{'lang_account' | language}}"
                                    (search)="accountSearch($event)"
                                    (focus)="accounts = []">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer padb0">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" routerLink="/contractors/lists" mat-button class="albutton mnabrbutton mat-accent">{{'lang_goto_list' | language }}</button>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 mb-10">
                            <app-alert id="default-alert"></app-alert>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="submit" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_submit' | language }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</section>