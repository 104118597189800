<div mat-dialog-title class="d-flex justify-content-between">
	<div class="task-title">
		<h4>{{ "lang_checklist_item" | language }}</h4>
	</div>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content>
	<div class="p-2">
		<form [formGroup]="pointForm">
			<div class="row">
				<div class="col-12 col-sm-8">
					<div class="title">
						<mat-label>{{ "lang_title" | language }}</mat-label>
						<input
							type="text"
							placeholder="{{ 'lang_title' | language }}"
							formControlName="title"
						/>
					</div>
				</div>
				<div class="col-12 col-sm-4">
					<div class="point-progress">
						<mat-label>{{ "lang_progress" | language }}</mat-label>
						<input
							type="number"
							min="0"
							max="100"
							placeholder="{{ 'lang_progress' | language }}"
							formControlName="prg_done"
						/>
					</div>
				</div>
				<div class="col-12 mb-2">
					<label>{{ "lang_description" | language }}</label>
					<ckeditor
						#editor
						[config]="config"
						formControlName="more_details"
						[ngClass]="{ ' contentrtl': lang_key == 'ar' }"
					></ckeditor>
				</div>
				<div class="col-12 mb-5">
					<label>{{ "lang_date" | language }}</label>
					<mbsc-datepicker
						[controls]="['calendar', 'time']"
						select="range"
						[touchUi]="false"
						dateFormat="YYYY/MM/DD HH:mm:ss"
						[(ngModel)]="task_dates"
						[ngModelOptions]="{ standalone: true }"
						[rtl]="lang_key == 'ar' ? true : false"
						display="inline"
					>
					</mbsc-datepicker>
				</div>
				<div class="col-12 col-sm-6 mb-2">
					<label>{{ "lang_group_type" | language }}</label>
					<ng-select
						[items]="group_type_list"
						[multiple]="false"
						appendTo="body"
						bindLabel="label"
						bindValue="value"
						[searchable]="false"
						placeholder="{{ 'lang_group_type' | language }}"
						formControlName="group_type_id"
						(change)="getGroups()"
					>
					</ng-select>
				</div>
				<div class="col-12 col-sm-6 mb-2">
					<label>{{ "lang_group" | language }}</label>
					<ng-select
						[items]="group_list"
						[multiple]="false"
						appendTo="body"
						bindLabel="group_name"
						bindValue="group_id"
						clearAllText="Clear"
						[searchable]="false"
						placeholder="{{ 'lang_group' | language }}"
						formControlName="group_id"
						(change)="onChangeGroup()"
					>
					</ng-select>
				</div>
				<div class="col-12 col-sm-6 mb-2">
					<label>{{ "lang_members" | language }}</label>
					<ng-select
						[items]="search_point_users"
						[multiple]="true"
						bindLabel="label"
						bindValue="value"
						appendTo="body"
						clearAllText="Clear"
						[searchFn]="ds.customSearchFn"
						formControlName="users"
						placeholder="{{ 'lang_members' | language }}"
						(search)="search_users($event)"
					>
					</ng-select>
				</div>
				<div class="col-12 col-sm-6">
					<div class="point-progress">
						<label for="hour_no">{{ "lang_hour_no" | language }}</label>
						<input
							type="number"
							placeholder="{{ 'lang_hour_no' | language }}"
							formControlName="hour_no"
							id="hour_no"
							name="hour_no"
						/>
					</div>
				</div>
				<div class="col-12 TableParts">
					<p class="mb-10">
						{{ "lang_attachments" | language }}
						<span class="red" *ngIf="other_attachments?.length">*</span>
					</p>
					<div class="table-responsive customResponsive quotationitemtable">
						<table
							border="1"
							class="table CustomTable"
							style="width: 99% !important"
							formArrayName="attachments"
						>
							<thead>
								<tr>
									<td width="2%" style="padding: 5px; word-break: break-all">
										#
									</td>
									<td width="2%" style="padding: 5px; word-break: break-all">
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addfiles()"
											>add_box</mat-icon
										>
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_title" | language }}
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_file" | language }}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let file of filesFormGroup.controls;
										let i = index;
										let odd = odd;
										let even = even
									"
									[formGroupName]="i"
								>
									<td
										class="verticalalignmid"
										style="padding: 5px; word-break: break-all"
									>
										{{ i + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											*ngIf="
												!other_attachments.controls[i].get('delete_icon').value
											"
											(click)="removefiles(i)"
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											>delete_forever</mat-icon
										>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											type="text"
											formControlName="attach_title"
											placeholder="{{ 'lang_description' | language }}"
											autocomplete="off"
											class="form-control almnabrformcontrol shadow-none"
										/>
									</td>
									<td
										class="verticalalignmid"
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											type="text"
											formControlName="file"
											placeholder="{{ 'lang_file' | language }}"
											autocomplete="off"
											class="form-control almnabrformcontrol shadow-none"
											[class.border-danger]="
												other_attachments.controls[i].get('file').errors
													?.urlWrong
											"
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</form>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-0 col-sm-9"></div>
	<div class="col-12 col-sm-3">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onSubmit()"
		>
			{{ "lang_save" | language }}
		</button>
	</div>
</mat-dialog-actions>
