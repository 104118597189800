import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";

@Component({
	selector: "app-ep-ticket-view",
	templateUrl: "./ep-ticket-view.component.html",
	styleUrls: ["./ep-ticket-view.component.scss"],
})
export class EpTicketViewComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	ticketID = this.route.snapshot.paramMap.get("id");
	newList = [];
	inprogressList = [];
	confirmList = [];
	doneList = [];
	allTasks = [];
	constructor(
		private route: ActivatedRoute,
		public ds: DataService,
		private spinner: PreloaderService,
		private ls: LocalStorage
	) {}

	ngOnInit(): void {
		this.getTicketTasks();
	}

	//start get ticket tasks
	getTicketTasks() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		formData.append(
			"token_extarnal",
			localStorage.getItem("eploggedin") == "1"
				? this.ls.getItem("eptoken")
				: ""
		);
		this.ds.post("tasks/get_tasks_for_extarnal_person", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.allTasks = res?.data_task;
					for (let task of res?.data_task) {
						switch (task?.task_status) {
							case "1": {
								this.newList.push(task);
								break;
							}
							case "2": {
								this.inprogressList.push(task);
								break;
							}
							case "3": {
								this.confirmList.push(task);
								break;
							}
							case "4": {
								this.doneList.push(task);
								break;
							}
						}
					}
				} else {
					this.allTasks = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.allTasks = [];
			}
		);
	}
	//end get ticket tasks
	//show ticket details
	showTicketDetials() {
		// const dialogRef = this.dialog.open(TicketDetailsComponent, {
		// 	width: "800px",
		// 	height: "400px",
		// 	// data: {
		// 	//   ticket: this.ticketData
		// 	// }
		// });
	}

	//open task
	openTask(task) {
		// const dialogRef = this.dialog.open(TemplateTaskComponent, {
		// 	data: { task: task },
		// 	width: "970px",
		// 	height: "800px",
		// });
	}

	//start show task labels
	showTaskLabels(event, id) {
		event.stopPropagation();
		let labelsContainer = document.getElementById(id);
		labelsContainer.classList.contains("mscli-labels-show")
			? labelsContainer.classList.remove("mscli-labels-show")
			: labelsContainer.classList.add("mscli-labels-show");
	}
	//end show task labels
}
