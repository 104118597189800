import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-add-contract-attachments",
	templateUrl: "./add-contract-attachments.component.html",
	styleUrls: ["./add-contract-attachments.component.scss"],
})
export class AddContractAttachmentsComponent implements OnInit {
	attachments_form: FormGroup;
	attachments_array: FormArray;
	filename: string = this.lang.transform("lang_select_files");
	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<AddContractAttachmentsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private lang: LanguagePipe,
		public ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService
	) {}

	ngOnInit(): void {
		this.buildForm();
	}
	buildForm() {
		this.attachments_form = this.fb.group({
			attachments: this.fb.array([]),
		});
		this.attachments_array = this.attachments_form.get(
			"attachments"
		) as FormArray;
	}
	get attachments_formgroup() {
		return this.attachments_form.get("attachments") as FormArray;
	}

	public attachment(value?): FormGroup {
		return this.fb.group({
			delete_icon: [value?.attach_title || ""],
			view: [value?.attach_title || null],
			attach_title: [value?.attach_title || ""],
			file: [null],
		});
	}
	public addAttachment(value?) {
		this.attachments_array.push(this.attachment(value));
		this.attachments_array.setValidators(Validators.required);
		this.attachments_array.updateValueAndValidity();
	}
	public removeAttachment(index) {
		this.attachments_array.removeAt(index);
		this.attachments_array.clearValidators();
		this.attachments_array.updateValueAndValidity();
	}
	submit() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("contract_id", this.data.contract_id || "");
		this.attachments_array.controls.forEach((element, key) => {
			formData.append(
				"attachments[" + key + "][attach_title]",
				element.value.attach_title || ""
			);
			formData.append(
				"attachments[" + key + "][file]",
				element.value.file || ""
			);
		});
		this.ds.post("project_design/add_files_in_contract", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successToast(res?.message, 2000);
					this.dialogRef.close(true);
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(error) => {
				this.spinner.hide();
				this.ds.dialogf(
					"",
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}
}
