<section class="panel-expansion ckeditorhidetoolbar">
  <mat-accordion class="stylepanels">
    <mat-expansion-panel class="mpanel" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">
        {{ "lang_create_project" | language }}
        (
        <span *ngIf="lang_key == 'en'">
          {{ employee_data.firstname_english }}
          {{ employee_data.lastname_english }}</span
        >
        <span *ngIf="lang_key == 'ar'">
          {{ employee_data.firstname_arabic }}
          {{ employee_data.lastname_arabic }}
        </span>
        )
      </mat-expansion-panel-header>
      <div class="mbrl15">
        <form [formGroup]="form" class="form">
          <div class="mbrl15">
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <label class="mb-10">
                  {{ "lang_project_name_arabic" | language }}
                  <span class="red"> * </span>
                </label>
                <input
                  placeholder="{{ 'lang_project_name_arabic' | language }}"
                  formControlName="project_name_arabic"
                  class="form-control almnabrformcontrol custom_input"
                />
                <mat-error
                  *ngIf="
                    form.controls['project_name_arabic'].touched &&
                    form.controls['project_name_arabic'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <label class="mb-10">
                  {{ "lang_project_name_english" | language }}
                  <span class="red"> * </span>
                </label>
                <input
                  formControlName="project_name_english"
                  class="form-control almnabrformcontrol custom_input"
                  placeholder="{{ 'lang_project_name_english' | language }}"
                />
                <mat-error
                  *ngIf="
                    form.controls['project_name_english'].touched &&
                    form.controls['project_name_english'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <div class="form-group terminations">
                  <label
                    >{{ "lang_project_date" | language }} :<span class="red"
                      >*</span
                    ></label
                  >
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label
                      >{{ "lang_start_date" | language }} -
                      {{ "lang_end_date" | language }}</mat-label
                    >
                    <mat-date-range-input [rangePicker]="picker">
                      <input
                        matStartDate
                        formControlName="project_start_date"
                        placeholder="{{ 'lang_start_date' | language }}"
                        readonly
                      />
                      <input
                        matEndDate
                        formControlName="project_end_date"
                        placeholder="{{ 'lang_end_date' | language }}"
                        readonly
                      />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <label class="mb-10">
                  {{ "lang_note" | language }}
                </label>
                <input
                  formControlName="note"
                  class="form-control almnabrformcontrol custom_input"
                  placeholder="{{ 'lang_note' | language }}"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
          <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
            <button
              type="button"
              (click)="create_project()"
              class="albutton mnabrbutton mat-accent ml-auto"
              mat-button
            >
              {{ "lang_submit" | language }}
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
