<div class="row">
  <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
    <button
      *ngIf="actionPage == 'A'"
      type="button"
      class="albutton mnabrbutton mat-accent"
      mat-button
      routerLink="/transactions/nBU2waRF8N"
    >
      {{ "lang_goto_list" | language }}
    </button>
    <button
      *ngIf="actionPage != 'A'"
      type="button"
      class="albutton mnabrbutton mat-accent"
      mat-button
      routerLink="/tasks/alltasks"
    >
      {{ "lang_goto_list" | language }}
    </button>
  </div>
</div>

<mat-accordion>
  <mat-expansion-panel class="mb-2" [expanded]="true">
    <mat-expansion-panel-header>{{
      "lang_vacation_form" | language
    }}</mat-expansion-panel-header>
    <div class="material-panel-body">
      <app-steps
        [request]="request"
        [alldata]="allData"
        [form_request_id]="form_request_id"
        [form_key]="form_key"
      ></app-steps>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="mb-2">
    <mat-expansion-panel-header>{{
      "lang_person_details" | language
    }}</mat-expansion-panel-header>
    <div class="material-panel-body communication">
      <div class="mbrl15">
        <div class="col-md-12 pad0">
          <div class="table-responsive customResponsive">
            <table
              border="1"
              class="table CustomTable"
              *ngIf="persons.length > 0"
            >
              <thead>
                <tr>
                  <td>#</td>
                  <td>{{ "lang_name" | language }}</td>
                  <td>{{ "lang_type" | language }}</td>
                  <td>{{ "lang_view" | language }}</td>
                  <td>{{ "lang_view_time" | language }}</td>
                  <td>{{ "lang_last_view_time" | language }}</td>
                  <td>{{ "lang_action" | language }}</td>
                  <td>{{ "lang_step" | language }}</td>
                  <td>{{ "lang_datetime" | language }}</td>
                </tr>
              </thead>
              <tbody class="AppendList">
                <tr
                  *ngFor="
                    let person of persons;
                    let i = index;
                    let odd = odd;
                    let even = even
                  "
                  [ngClass]="{ odd: odd, even: even }"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ person.person_name }}</td>
                  <td>{{ person.transaction_persons_type }}</td>
                  <td *ngIf="person.transactions_persons_view == '1'">
                    <i
                      class="fa fa-check faicon successgreen"
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td *ngIf="person.transactions_persons_view == '0'">
                    <i class="fa fa-check faicon silver" aria-hidden="true"></i>
                  </td>
                  <td>{{ person.transactions_persons_view_datetime }}</td>
                  <td>
                    {{ person.transactions_persons_view_datetime_lastupdate }}
                  </td>
                  <td *ngIf="person.transactions_persons_action_status == 1">
                    <i
                      class="fa fa-check faicon successgreen"
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td *ngIf="person.transactions_persons_action_status == 0">
                    <i class="fa fa-check faicon silver" aria-hidden="true"></i>
                  </td>
                  <td>{{ person.transactions_persons_last_step }}</td>
                  <td>
                    {{
                      person.transactions_persons_view_datetime | date: "short"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <no-data
              style="margin: auto"
              [Opened]="persons.length < 1"
            ></no-data>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="mb-2">
    <mat-expansion-panel-header class="mpanel-header">{{
      "lang_history" | language
    }}</mat-expansion-panel-header>
    <div class="material-panel-body communication">
      <div class="mbrl15">
        <div class="col-md-12 pad0">
          <div class="table-responsive customResponsive">
            <table
              border="1"
              class="table CustomTable"
              *ngIf="history.length > 0"
            >
              <thead>
                <tr>
                  <td>#</td>
                  <td>{{ "lang_name" | language }}</td>
                  <td>{{ "lang_notes" | language }}</td>
                  <td>{{ "lang_result" | language }}</td>
                  <td>{{ "lang_ondate" | language }}</td>
                </tr>
              </thead>
              <tbody class="AppendList">
                <tr
                  *ngFor="
                    let his of history;
                    let i = index;
                    let odd = odd;
                    let even = even
                  "
                  [ngClass]="{ odd: odd, even: even }"
                >
                  <td>{{ i + 1 }}</td>
                  <td>
                    {{
                      his.employee_note || his.transactions_records_user_name
                    }}
                  </td>
                  <td>{{ his.transactions_records_note }}</td>
                  <td>{{ his.result }}</td>
                  <td>{{ his.transactions_records_datetime }}</td>
                </tr>
              </tbody>
            </table>
            <no-data
              style="margin: auto"
              [Opened]="history.length < 1"
            ></no-data>

            <br />
            <div
              class="d-flex justify-content-between"
              *ngIf="history.length > 0"
            >
              <ngb-pagination
                [collectionSize]="history.length"
                [maxSize]="3"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="refreshLists()"
              ></ngb-pagination>
              <select
                class="customselect"
                [(ngModel)]="pageSize"
                (change)="refreshLists()"
              >
                <option [ngValue]="10">10</option>
                <option [ngValue]="20">20</option>
                <option [ngValue]="50">50</option>
                <option [ngValue]="100">100</option>
                <option [ngValue]="250">250</option>
                <option [ngValue]="500">500</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="All_Attachments.length > 0" class="mb-2">
    <mat-expansion-panel-header>{{
      "lang_attachments" | language
    }}</mat-expansion-panel-header>
    <div class="material-panel-body communication">
      <div class="mbrl15">
        <div class="col-md-12 pad0">
          <app-transaction-attachments
            [All_Attachments]="All_Attachments"
          ></app-transaction-attachments>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div class="modal" id="verifySMRDA">
  <div class="modal-dialog">
    <div class="modal-content">
      <form [formGroup]="form" class="form" (ngSubmit)="confirmVerification()">
        <div class="modal-header" *ngIf="verifylist.length > 0">
          <h4 class="modal-title">
            {{ "lang_complete_verification" | language }}
          </h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body" *ngIf="verifylist.length > 0">
          <p>
            # {{ request.transaction_request_id }}
            {{ request.transaction_request_description }}
          </p>
          <app-alert id="default-alert"></app-alert>
          <input type="hidden" formControlName="transaction_persons_type" />
          <input type="hidden" formControlName="do" />
          <div class="form-group">
            <label class="mb-10"
              >{{ "lang_how_to_send_verification" | language }} :
            </label>
            <div
              *ngFor="let option of verifylist"
              class="custom-control custom-radio mb-10"
            >
              <input
                type="radio"
                class="custom-control-input zindex9999"
                value="{{ option.value }}"
                formControlName="sender_type"
                name="sender_type"
              />
              <label
                class="custom-control-label"
                *ngIf="option.value == 'phone'"
                >{{ "lang_mobile" | language }}
                <b>( {{ option.label }} ) </b></label
              >
              <label
                class="custom-control-label"
                *ngIf="option.value == 'email'"
                >{{ "lang_email" | language }}
                <b>( {{ option.label }} ) </b></label
              >
              <label
                class="custom-control-label"
                *ngIf="option.value == 'whatsapp'"
                >{{ "lang_whatsapp" | language }}
                <b>( {{ option.label }} ) </b></label
              >
            </div>
          </div>
          <div class="form-group" *ngIf="sendstatus">
            <label for="verification_code"
              >{{ "lang_enter_verification_code" | language }}:
            </label>
            <input
              type="text"
              required
              class="form-control almnabrformcontrol"
              formControlName="transactions_persons_action_code"
            />
          </div>
          <div class="row">
            <div class="col-md-4 col-xs-12">
              <button
                type="button"
                class="albutton mnabrbutton mat-accent"
                mat-button
                [disabled]="sendcodestatus"
                (click)="sendVerificationCode()"
              >
                {{ codemsg }}
              </button>
            </div>
            <div class="col-md-4 col-xs-12">
              <button
                type="submit"
                *ngIf="sendstatus"
                class="albutton mnabrbutton mat-accent"
                mat-button
              >
                {{ "lang_do_verification" | language }}
              </button>
            </div>
            <div class="col-md-4 col-xs-12">
              <button
                type="button"
                class="albutton mnabrbutton mat-accent"
                mat-button
                data-dismiss="modal"
              >
                {{ "lang_close" | language }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal" id="verifyPassword">
  <div class="modal-dialog">
    <div class="modal-content">
      <form [formGroup]="approve" class="approve" (ngSubmit)="approve_form()">
        <div class="modal-header">
          <h4 class="modal-title">{{ "lang_approve" | language }}</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <p>
            # {{ request?.transaction_request_id }}
            {{ request?.transaction_request_description }}
          </p>
          <div class="form-group">
            <label for="password">{{ "lang_password" | language }}: </label>
            <input
              type="password"
              required
              class="form-control almnabrformcontrol"
              formControlName="user_pass"
            />
            <input
              type="hidden"
              required
              class="form-control almnabrformcontrol"
              formControlName="transaction_request_id"
            />
          </div>
          <div class="row">
            <div class="col-md-4 col-xs-12">
              <button
                type="submit"
                class="albutton mnabrbutton mat-accent"
                mat-button
              >
                {{ "lang_approve" | language }}
              </button>
            </div>
            <div class="col-md-4 col-xs-12">
              <button
                type="button"
                class="albutton mnabrbutton mat-accent"
                mat-button
                data-dismiss="modal"
              >
                {{ "lang_close" | language }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
