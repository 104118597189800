<div class="card appcontracts">
	<div class="card-header">
		{{ "lang_extend_period" | language }}
	</div>
	<div class="card-body p-0">
		<div class="mbrl15 martb0">
			<div class="row">
				<div class="table-responsive dropdownyes customResponsive col-12">
					<table
						border="1"
						class="table CustomTable"
						*ngIf="wholedata.length != 0"
					>
						<thead>
							<tr>
								<td>#</td>
								<td>{{ "lang_subject" | language }}</td>
								<td>{{ "lang_contract_date" | language }}</td>
								<td>{{ "lang_work_domain" | language }}</td>
								<td>{{ "lang_work_location" | language }}</td>
								<td>{{ "lang_approval_date" | language }}</td>
								<td>{{ "lang_writer" | language }}</td>
							</tr>
						</thead>
						<tbody class="AppendList">
							<tr
								*ngFor="
									let con of wholedata.records;
									let i = index;
									let odd = odd;
									let even = even
								"
								[ngClass]="{ odd: odd, even: even }"
								class="makeback removerow{{ con.contract_id }}"
							>
								<td>{{ i + 1 }}</td>
								<td>{{ con.subject }}</td>
								<td>
									{{ con.contract_start_date_english }} -
									{{ con.contract_start_date_arabic }}
								</td>
								<td>{{ con.work_domain }}</td>
								<td>{{ con.work_location }}</td>
								<td>
									{{ con.contract_approved_date_english }} -
									{{ con.contract_approved_date_arabic }}
								</td>
								<td>{{ con.writer }}</td>
							</tr>
						</tbody>
					</table>
					<no-data
						style="margin: auto"
						[Opened]="wholedata.length < 1"
					></no-data>
				</div>
			</div>
		</div>
		<div *ngIf="wholedata.length != 0" class="card-footer padb0 customborder">
			<div class="row">
				<div class="col-lg-2 col-sm-6 col-6 mb-10">
					{{ "lang_total" | language }} {{ wholedata?.page?.total_records }}
				</div>
				<div class="col-lg-5 col-sm-6 col-12 mb-10">
					<mat-radio-group aria-label="Select an option">
						<mat-radio-button
							value="10"
							checked
							(click)="spinner.show(); load_page_size(10)"
						>
							10
						</mat-radio-button>
						<mat-radio-button
							value="20"
							(click)="spinner.show(); load_page_size(20)"
						>
							20
						</mat-radio-button>
						<mat-radio-button
							value="50"
							(click)="spinner.show(); load_page_size(50)"
						>
							50
						</mat-radio-button>
						<mat-radio-button
							value="100"
							(click)="spinner.show(); load_page_size(100)"
						>
							100
						</mat-radio-button>
						<mat-radio-button
							value="500"
							(click)="spinner.show(); load_page_size(500)"
						>
							500
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<div class="col-lg-4 col-sm-6 col-12 mb-10">
					{{ "lang_goto" | language }}
					<select
						class="customselect"
						(change)="spinner.show(); page_length($event?.target?.value)"
					>
						<option
							*ngFor="let k of ds.getrange(wholedata?.page?.total_pages)"
							value="{{ k }}"
						>
							{{ k }}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</div>
