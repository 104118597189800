<form [formGroup]="form" (ngSubmit)="completeStep()">
	<div class="card">
		<div class="card-header">
			{{ "lang_QAQC_approval_operation" | language }}
			<i
				style="padding: 0 10px"
				class="fa fa-info-circle orangestatus-color"
				matTooltip="{{ 'lang_QAQC_approval_operation_tooltip' | language }}"
			></i>
		</div>
		<div class="mbrl15">
			<div class="row mb-10">
				<div class="col-sm-6 col-xs-12 mb-10">
					<label class="mb-10">
						{{ "lang_transaction_approval" | language }} !?.
						<span class="red"> * </span> </label
					><br />
					<mat-radio-group
						aria-label="Select an option"
						formControlName="manager_approval_status"
						(change)="form.get('manager_rejected_notes').setValue('')"
					>
						<mat-radio-button value="Return">{{
							"lang_return" | language
						}}</mat-radio-button>
						<mat-radio-button value="Approve">{{
							"lang_approve" | language
						}}</mat-radio-button>
					</mat-radio-group>
				</div>
				<div class="col-sm-6 col-xs-12 mb-10">
					<div *ngIf="form.get('manager_approval_status').value == 'Return'">
						<label
							>{{ "lang_rejected_notes" | language }} :
							<span class="red">*</span></label
						>
						<textarea
							class="form-control almnabrformcontrol"
							formControlName="manager_rejected_notes"
						></textarea>
					</div>
				</div>
				<!-- <div class="col-sm-6">
					<div class="form-group">
						<label for="verification_code"
							>{{ "lang_enter_verification_code" | language }}
							<span class="red">*</span>:
							<i
								style="padding: 0 10px"
								class="fa fa-info-circle orangestatus-color"
								matTooltip="{{
									'lang_if_you_not_have_code_hit_sendcode_button' | language
								}}"
							></i>
						</label>
						<input
							type="text"
							required
							class="form-control almnabrformcontrol"
							formControlName="transactions_persons_action_code"
						/>
					</div>
				</div> -->
				<div class="col-sm-12">
					<app-alert id="default-alert"></app-alert>
				</div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<!-- <div>
						<button
							class="albutton mnabrbutton mat-accent"
							type="button"
							(click)="openModal()"
							mat-button
						>
							{{ "lang_send_code" | language }}
						</button>
					</div> -->
				</div>
				<div class="col-sm-4 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<div>
						<input
							type="submit"
							value="{{ 'lang_submit' | language }}"
							class="albutton mnabrbutton mat-accent"
							mat-button
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<!--steps history-->
<app-ncr-steps-history
	[allData]="allData"
	[step_no]="14"
></app-ncr-steps-history>
