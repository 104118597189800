<div class="row wafi_header">
  <div class="table-responsive customResponsive">
    <table border="1" class="table wafi_title" width="100%">
      <tbody>
        <tr>
          <td width="130px">
            <img src="assets/images/logoawafi.PNG" alt="" />
          </td>
          <td width="87.5%">
            <div class="title_content">
              {{
                "supervision consultant monthly report for july-2022" | language
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row content_header">
  <div class="col">
    {{ "lang_construction_management" | language }}
  </div>
</div>
<div class="row quality_control">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody>
        <tr>
          <th rowspan="20">{{ "lang_construction_work_status" | language }}</th>
          <th>
            {{ "lang_division_number" | language }}
          </th>
          <th>
            {{ "lang_division" | language }}
          </th>
          <th>
            {{ "lang_division_cost" | language }}
          </th>
          <th>
            {{ "lang_weightage" | language }}
          </th>
          <th>
            {{ "lang_cumulative_planned_progress" | language }}
          </th>
          <th>
            {{ "lang_cumulative_actual_progress" | language }}
          </th>
          <th>{{ "lang_variance" | language }}</th>
          <th>{{ "lang_notes" | language }}</th>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_1" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_general_requirement" | language }}
          </td>
          <td class="highlight"></td>
          <td class="highlight"></td>
          <td class="highlight"></td>
          <td class="highlight"></td>
          <td class="highlight"></td>
          <td class="highlight">
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_2" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_site_work" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_3" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_concrete" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_4" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_mansorny" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_5" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_metals" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_6" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_wood_and_plastics" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_7" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_thermal_and_moinsture_protection" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_8" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_doors_and_windows" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_9" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_finishes" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_10" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_specialties" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_11" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_equipment" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_12" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_furnishings" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_13" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_special_construction" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_14" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_conveying_systems" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_15" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_mechanical/plumbing" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "lang_division_16" | language }}
          </td>
          <td class="highlight3">
            {{ "lang_electrical" | language }}
          </td>
          <td>24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
        <tr>
          <td colspan="2" class="highlight">
            {{ "الاجمالي" | language }}
          </td>
          <td class="highlight">24,083,261,00</td>
          <td class="highlight">4.00%</td>
          <td>100.00%</td>
          <td>100.00%</td>
          <td class="highlight">0.00%</td>
          <td>
            {{ "مشمولة في الاقسام الاخري" | language }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
