import { Component, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-wafi-list",
  templateUrl: "./wafi-list.component.html",
  styleUrls: ["./wafi-list.component.scss"],
})
export class WafiListComponent implements OnInit {
  @Input() projects_work_area_id;
  @Input() projects_profile_id;
  @Input() projects_supervision_id;
  lang_key = localStorage.getItem("lang_key") || "en";
  modalRef: BsModalRef;
  form: FormGroup;
  other_manpower: FormArray;
  wafiList = [];
  planMonthFilter = new FormControl("");
  wafiPageNo = 1;
  wafiPageSize = "10";
  total_records;
  isEdit = false;
  itemForUpdate;
  wafiStatusList = [];
  constructor(
    public modalService: BsModalService,
    private fb: FormBuilder,
    private ds: DataService,
    private spinner: PreloaderService,
    private sweetAlert: SweetAlertService,
    private lang: LanguagePipe,
    public router: Router
  ) {
    this.wafiStatusList = [
      {
        label: this.lang.transform("lang_exist_certified"),
        value: "exist_certified",
      },
      {
        label: this.lang.transform("lang_exist_not_certified"),
        value: "exist_not_certified",
      },
      {
        label: this.lang.transform("lang_not_exist"),
        value: "not_exist",
      },
    ];
  }

  ngOnInit(): void {
    this.buildForm();
    this.getWafiList();
  }

  getWafiList() {
    let formData = new FormData();
    formData.append("projects_work_area_id", this.projects_work_area_id || "");
    formData.append("report_date", this.planMonthFilter.value || "");
    formData.append("lang_key", this.lang_key || "");
    this.spinner.show();
    this.ds
      .post(
        `projects/wafi_report/get_wafi_report_work_area/${this.wafiPageNo}/${this.wafiPageSize}`,
        formData
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.wafiList = res?.results;
            this.total_records = res?.pages?.total_records;
          } else {
            this.wafiList = [];
            this.sweetAlert.errorToast(res?.error, 3000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
  }

  buildForm() {
    this.form = this.fb.group({
      report_date: ["", Validators.required],
      report_monthly_spi: ["", Validators.required],
      report_monthly_cpi: ["", Validators.required],
      project_timewise_status: ["", Validators.required],
      project_costwise_status: ["", Validators.required],
      monthly_actual_manpower: ["", Validators.required],
      project_reasons_of_delay: [""],
      monthly_actual_engineers: ["", Validators.required],
      monthly_actual_inspectors: ["", Validators.required],
      quality_management_plan_status: [null, Validators.required],
      procedures_forms_templates: [null, Validators.required],
      report_itp: [null, Validators.required],
      method_statements: [null, Validators.required],
      risk_management_plan_status: [null, Validators.required],
      hse_plan_status: [null, Validators.required],
      preventive_measures: ["", Validators.required],
      major_incidents: ["", Validators.required],
      lost_time_injuries: ["", Validators.required],
      near_miss_reports: ["", Validators.required],
      hse_observations: ["", Validators.required],
    });
  }

  onAddEditWafi(template, item?) {
    this.form.reset();
    if (item) {
      this.isEdit = true;
      this.itemForUpdate = item;
      this.form.patchValue(item);
    } else {
      this.isEdit = false;
      this.itemForUpdate = null;
    }
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }

  onSubmit() {
    let formData = new FormData();
    let url = "pr/report/add_wafi_report";
    if (this.isEdit) {
      formData.append(
        "wafi_report_id",
        this.itemForUpdate?.wafi_report_id || ""
      );
      url = "pr/report/edit_wafi_report";
    } else {
      formData.append(
        "projects_work_area_id",
        this.projects_work_area_id || ""
      );
      formData.append("report_date", this.form.get("report_date").value || "");
      url = "pr/report/add_wafi_report";
    }
    formData.append(
      "report_monthly_spi",
      this.form.get("report_monthly_spi").value || ""
    );
    formData.append(
      "report_monthly_cpi",
      this.form.get("report_monthly_cpi").value || ""
    );
    formData.append(
      "project_timewise_status",
      this.form.get("project_timewise_status").value || ""
    );
    formData.append(
      "project_costwise_status",
      this.form.get("project_costwise_status").value || ""
    );
    formData.append(
      "monthly_actual_manpower",
      this.form.get("monthly_actual_manpower").value || ""
    );
    formData.append(
      "project_reasons_of_delay",
      this.form.get("project_reasons_of_delay").value || ""
    );
    formData.append(
      "monthly_actual_engineers",
      this.form.get("monthly_actual_engineers").value || ""
    );
    formData.append(
      "monthly_actual_inspectors",
      this.form.get("monthly_actual_inspectors").value || ""
    );
    formData.append(
      "quality_management_plan_status",
      this.form.get("quality_management_plan_status").value || ""
    );
    formData.append(
      "procedures_forms_templates",
      this.form.get("procedures_forms_templates").value || ""
    );
    formData.append("report_itp", this.form.get("report_itp").value || "");
    formData.append(
      "method_statements",
      this.form.get("method_statements").value || ""
    );
    formData.append(
      "risk_management_plan_status",
      this.form.get("risk_management_plan_status").value || ""
    );
    formData.append(
      "hse_plan_status",
      this.form.get("hse_plan_status").value || ""
    );
    formData.append(
      "preventive_measures",
      this.form.get("preventive_measures").value || ""
    );
    formData.append(
      "major_incidents",
      this.form.get("major_incidents").value || ""
    );
    formData.append(
      "lost_time_injuries",
      this.form.get("lost_time_injuries").value || ""
    );
    formData.append(
      "near_miss_reports",
      this.form.get("near_miss_reports").value || ""
    );
    formData.append(
      "hse_observations",
      this.form.get("hse_observations").value || ""
    );
    this.spinner.show();
    this.ds.post(url, formData).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.sweetAlert.successToast(
            this.lang.transform("lang_success"),
            3000
          );
          this.modalRef.hide();
          this.getWafiList();
        } else {
          this.sweetAlert.errorToast(res?.error, 3000);
        }
      },
      (err) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          3000
        );
      }
    );
  }

  deleteWafi(item) {
    let formData = new FormData();
    formData.append("wafi_report_id", item?.wafi_report_id || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "pr/report/delete_wafi_report"
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.sweetAlert.successToast(
              this.lang.transform("lang_success"),
              3000
            );
            this.getWafiList();
          } else {
            this.sweetAlert.errorToast(res?.error, 3000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
  }
  route() {
    this.router.navigate([`projects/wafi_create`]);
  }
}
