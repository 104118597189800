import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { Subscription } from 'rxjs';
declare var $:any;

@Component({
  selector: 'app-shifts-settings',
  templateUrl: './shifts-settings.component.html',
  styleUrls: ['./shifts-settings.component.scss']
})
export class ShiftsSettingsComponent implements OnInit {
  admin = JSON.parse(this.ls.getItem('is_admin'));
  page_no = 1;
  page_size = '10';
  total_records = 0;
  searchKey = '';
  shifts = [];
  subscriptions = new Subscription();
  constructor(public ls: LocalStorage, private ds: DataService, private spinner: PreloaderService, 
    private sweetAlert: SweetAlertService, private dialog: MatDialog, private lang: LanguagePipe) { }

  ngOnInit(): void {
    this.getShifts();
  }

  getShifts() {
    this.spinner.show();
    this.ds.get(`shifts/list_shifts/${this.page_no}/${this.page_size}?search_key=${this.searchKey || ''}`).subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.shifts = res?.records;
        this.total_records = res?.page?.total_records;
      } else {
        this.shifts = [];
        this.total_records = 0;
        this.sweetAlert.errorToast(res?.error, 3000);
      }
    }, err => {
      this.spinner.hide();
      this.sweetAlert.errorToast(err?.error?.error, 3000);
      this.total_records = 0;
    })
  }

  getByPageSize(size) {
    this.page_size = size;
		this.page_no = 1;
		this.getShifts();
  }

  getByPageNo(page) {
    this.page_no = page;
		this.getShifts();
  }

  public checkAllItemsAth(event) {
		if (event.target.checked) {
			$(".attach_panel .eachItemAth").prop("checked", true);
			$(".attach_panel .makeback").addClass("bground");
			$(".attach_panel .deleteIconDiv").show();
		} else {
			$(".attach_panel .eachItemAth").prop("checked", false);
			$(".attach_panel .makeback").removeClass("bground");
			$(".attach_panel .deleteIconDiv").hide();
		}
	}
	public checkEachItemAth(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".attach_panel .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".attach_panel .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".attach_panel .eachItemAth:checked").each(function() {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".attach_panel .eachItemAth").each(function() {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".attach_panel .deleteIconDiv").show();
		} else {
			$(".attach_panel .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".attach_panel #allItemsAth").prop("checked", true);
		} else {
			$(".attach_panel #allItemsAth").prop("checked", false);
		}
	}

  public deleteBulkDataAth() {
		const checkedtotal = [];
		$(".attach_panel .eachItemAth:checked").each(function() {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: '400px',
				data: {
					title: this.lang.transform('lang_confirmation'),
					message: this.lang.transform('lang_are_you_sure'),
				}
			});
			this.subscriptions.add(
				dialogRef.afterClosed().subscribe((confirmed: boolean) => {
							if (confirmed) {
								this.spinner.show()
								this.subscriptions.add(
											this.ds.deleteShiftsByUrl(checkedtotal, 'shifts/delete_shifts').subscribe(
														(data) => {
															this.spinner.hide();
															if (data.status) {
																this.getShifts();
																$(".attach_panel #allItemsAth").prop("checked", false);
																$(".attach_panel .deleteIconDiv").hide();
                                this.sweetAlert.successToast(data?.msg, 3000);
															} else {
																this.ds.dialogf('', data.error);
															}
														},
														(error) => {
															this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
															this.spinner.hide()
														}
													)      
									)
								;
							}
						})    
				)
		;
		} else {
			this.ds.dialogf();
		}
	}

}
