import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DataService } from "@core/bootstrap/data.service";

@Component({
	selector: "app-whats-phone-sendcode",
	templateUrl: "./whats-phone-sendcode.component.html",
	styleUrls: ["./whats-phone-sendcode.component.scss"],
})
export class WhatsPhoneSendcodeComponent implements OnInit {
	type = "phone";
	document_barcode = "";
	document_code = "";
	phone_num = "";
	sender_type = "phone";
	code = "";
	constructor(
		private dialogRef: MatDialogRef<WhatsPhoneSendcodeComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private ds: DataService
	) {
		this.type = data?.type;
		this.document_barcode = data?.document_barcode;
		this.document_code = data?.document_code;
	}

	ngOnInit(): void {}

	onSubmit() {
		let formData = {
			document_barcode: this.document_barcode,
			document_code: this.document_code,
			mobile_num: this.phone_num,
			sender_type: this.sender_type,
			mobile_code: "",
		};
		let end_point = "tc/q/send_code";
		if (this.type == "code") {
			formData.mobile_code = this.code;
			end_point = "tc/q";
		}
		// tc/q
		this.ds
			.postActionByNoToken(this.ds.json2formdata(formData), end_point)
			.subscribe((res) => {
				if (res?.status) {
					if (this.type == "code") {
						this.dialogRef.close();
						this.ds.getActionByFullUrl([], res.url).subscribe(
							(data) => {
								if (data.status) {
									this.ds.fileview(data);
								} else {
									this.ds.dialogf("", data.error);
								}
							},
							(error) => {
								// this.ds.dialogf("", this.lang.transform("lang_login_must"));
							}
						);
					}
					this.type = "code";
				}
			});
	}

	public isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
}
