import { NgModule } from '@angular/core';
import { CommunicationRoutingModule } from './communication-routing.module';
import { SharedModule } from '@shared/shared.module';
import { CommuncationListsComponent } from './communcation-lists/communcation-lists.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CcsettingsComponent } from './settings/ccsettings/ccsettings.component';



const COMPONENTS = [CommuncationListsComponent, CcsettingsComponent];
const COMPONENTS_DYNAMIC = [];
@NgModule({
  imports: [
    SharedModule,
    CommunicationRoutingModule,
    AngularMultiSelectModule
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class CommunicationModule { }
