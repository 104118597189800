<breadcrumb></breadcrumb>
<section class="panel-expansion" [@fadeIn]>
    <mat-accordion  class="stylepanels">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{'lang_update_projects' | language}}</mat-expansion-panel-header>
        <div class="material-panel-body">
            <form [formGroup]="form" (ngSubmit)="update()" class="create">
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{ 'lang_branch' | language }} :<span class="red">*</span></label>
                                <ng-select 
                                        [items]="branches" 
                                        [multiple]="false" 
                                        bindLabel="label" 
                                        bindValue="value" 
                                        clearAllText="Clear"
                                        placeholder="{{'lang_branch' | language}}"
                                        formControlName="branch_id"
                                        (change)="spinner.show();get_customers();form.get('customer_id').setValue(null);"
                                    >
                                </ng-select>
                                <mat-error *ngIf="form.controls['branch_id'].touched && form.controls['branch_id'].errors?.required">
                                    {{ 'lang_field_required' | language }}
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{ 'lang_project_name_en' | language }} :<span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol" formControlName="projects_profile_name_en" placeholder="{{ 'lang_project_name_en' | language }}"/>
                                <mat-error *ngIf="form.controls['projects_profile_name_en'].touched && form.controls['projects_profile_name_en'].errors?.required">
                                    {{ 'lang_field_required' | language }}
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{ 'lang_project_name_ar' | language }} :<span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol" formControlName="projects_profile_name_ar" placeholder="{{ 'lang_project_name_ar' | language }}"/>
                                <mat-error *ngIf="form.controls['projects_profile_name_ar'].touched && form.controls['projects_profile_name_ar'].errors?.required">
                                    {{ 'lang_field_required' | language }}
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{ 'lang_customers' | language }} :<span class="red">*</span></label>
                                <ng-select 
                                        [items]="customers" 
                                        [multiple]="false" 
                                        appendTo="body" 
                                        bindLabel="label" 
                                        bindValue="value" 
                                        placeholder="{{'lang_customers' | language}}"
                                        clearAllText="Clear"
                                        formControlName="customer_id">
                                </ng-select>
                                <mat-error *ngIf="form.controls['customer_id'].touched && form.controls['customer_id'].errors?.required">
                                    {{ 'lang_field_required' | language }}
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{ 'lang_customer_types' | language }} :<span class="red">*</span></label>
                                <ng-select 
                                        [items]="customer_types" 
                                        [multiple]="false" 
                                        bindLabel="label" 
                                        appendTo="body" 
                                        placeholder="{{'lang_customer_types' | language}}"
                                        bindValue="value" 
                                        clearAllText="Clear"
                                        formControlName="customer_type_id">
                                </ng-select>
                                <mat-error *ngIf="form.controls['customer_type_id'].touched && form.controls['customer_type_id'].errors?.required">{{ 'lang_field_required' | language }}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{ 'lang_project_scope' | language }} :<span class="red">*</span></label>
                                <ng-select 
                                    [items]="services" 
                                    [multiple]="true" 
                                    appendTo="body" 
                                    bindLabel="label" 
                                    placeholder="{{'lang_project_scope' | language}}"
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    formControlName="project_scope_types">
                                </ng-select>
                                <mat-error *ngIf="form.controls['project_scope_types'].touched && form.controls['project_scope_types'].errors?.required">
                                    {{ 'lang_field_required' | language }}
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <input type="hidden" formControlName="latitude">
                                <input type="hidden" formControlName="longitude">
                                <label>{{ 'lang_project_location' | language }} :<span *ngIf="!form.get('projects_profile_location').value" class="red">*</span> </label>
                                <mat-search-google-maps-autocomplete [appearance]="appearance.OUTLINE" (onGermanAddressMapped)="onGermanAddressMapped($event)"></mat-search-google-maps-autocomplete>
                            </div>
                            <div>
                                <b *ngIf="mapaddress">( {{mapaddress}} )</b>
                                <agm-map [latitude]="latitude" [longitude]="longitude" [scrollwheel]="false" [zoom]="zoom">
                                  <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                                </agm-map>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{ 'lang_project_managers' | language }} :<span class="red">*</span></label>
                                <ng-select 
                                    [items]="users" 
                                    [multiple]="true" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body" 
                                    formControlName="project_managers"
                                    placeholder="{{'lang_project_managers' | language}}"
                                    (search)="search_users($event)"
                                    (focus)="users = []">
                                </ng-select>
                                <mat-error *ngIf="form.controls['project_managers'].touched && form.controls['project_managers'].errors?.required">
                                    {{ 'lang_field_required' | language }}
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label>{{ 'lang_project_users' | language }} : </label>
                                <ng-select 
                                    [items]="users" 
                                    [multiple]="true" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body" 
                                    formControlName="project_users"
                                    placeholder="{{'lang_project_users' | language}}"
                                    (search)="search_users($event)"
                                    (focus)="users = []">
                                </ng-select>
                                <mat-error *ngIf="form.controls['project_users'].touched && form.controls['project_users'].errors?.required">
                                    {{ 'lang_field_required' | language }}
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer padb0">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" routerLink="/projects/BbVUrWitLE" class="albutton mnabrbutton mat-accent" mat-button>{{ 'lang_all_projects' | language }}</button>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button *ngIf="view" type="button" routerLink="/projects/LtYh/{{project_profile_id}}" class="albutton mnabrbutton mat-accent" mat-button>{{ 'lang_view' | language }}</button>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                            <app-alert id="default-alert"></app-alert>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="submit" class="albutton mnabrbutton mat-accent" mat-button>{{ 'lang_update' | language }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</section>