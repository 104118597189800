<div class="card risk_management">
	<div class="card-header">{{ "lang_risk_management" | language }}</div>
	<div class="card-body pad0">
		<mat-tab-group (selectedTabChange)="selectedListTabValue($event)">
			<mat-tab label="risks">
				<ng-template mat-tab-label>{{ "lang_risks" | language }}</ng-template>
				<ng-container>
					<div class="p-2">
						<app-risks-list
							[projects_work_area_id]="supervision_data?.projects_work_area_id"
							[projects_profile_id]="project_data?.projects_profile_id"
							[projects_supervision_id]="
								supervision_data?.projects_supervision_id
							"
						></app-risks-list>
					</div>
				</ng-container>
			</mat-tab>
			<mat-tab label="issues">
				<ng-template mat-tab-label>{{ "lang_issues" | language }}</ng-template>
				<ng-container>
					<div class="p-2">
						<app-issues-list
							[projects_work_area_id]="supervision_data?.projects_work_area_id"
							[projects_profile_id]="project_data?.projects_profile_id"
							[projects_supervision_id]="
								supervision_data?.projects_supervision_id
							"
						></app-issues-list>
					</div>
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
