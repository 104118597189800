import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { PreloaderService } from "@core/bootstrap/preloader.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-hr-projects",
  templateUrl: "./hr-projects.component.html",
  styleUrls: ["./hr-projects.component.scss"],
})
export class HrProjectsComponent implements OnInit {
  allData;
  @Input() set user_data(data) {
    this.allData = data;
    console.log(this.allData);
    this.get_projects();
  }
  @Input() requestfor;
  @Output() showForm = new EventEmitter();
  projects;
  size: any = 10;
  page: any = 1;
  errormessagef: any = this.lang.transform("lang_loading");
  form: FormGroup = this.fb.group({
    date_from: null,
    date_to: null,
    type: null,
    search_value: "",
    accepted: null,
  });

  constructor(
    public router: Router,
    public ds: DataService,
    public route: ActivatedRoute,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {}

  ngOnInit(): void {}
  onCreateLoan() {
    if (this.requestfor != "profile") {
      this.showForm.emit("HRPR");
    }
  }
  public get_projects() {
    const param = new FormData();

    this.spinner.show();
    param.append("page_size", this.size);
    param.append("page_no", this.page);
    param.append("employee_number", this.allData?.employee_number);
    this.ds
      .getActionByUrl(
        this.ds.formData2string(param),
        "human_resources/get_hr_projects"
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status && data?.records?.length > "0") {
            this.projects = data;
          } else {
            this.projects = data;
            this.errormessagef = this.lang.transform("lang_no_data");
          }
        },
        (error) => {
          this.spinner.hide();
          this.projects = [];
          this.errormessagef =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
  }
  public load_page_size(pageno) {
    this.spinner.show();
    this.size = pageno;
    this.get_projects();
  }

  public page_length(page) {
    this.spinner.show();
    this.page = page;
    this.get_projects();
  }

  public search_employee_modules() {
    this.size = 10;
    this.page = 1;
    this.get_projects();
  }
}
