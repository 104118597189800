<h3 mat-dialog-title class="d-flex justify-content-between align-items-center">
	<span>{{ "lang_advanced_filter" | language }}</span>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</h3>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-12 mb-10">
			<ng-select
				[items]="formlist"
				[multiple]="false"
				appendTo="body"
				bindLabel="label"
				bindValue="value"
				clearAllText="Clear"
				placeholder="{{ 'lang_search_form' | language }}"
				[(ngModel)]="searchByForm"
			>
			</ng-select>
		</div>
		<div class="col-12 mb-10" *ngIf="admin">
			<select class="form-control almnabrformcontrol" [(ngModel)]="filteradmin">
				<option value="0">{{ "lang_users" | language }}</option>
				<option value="1">{{ "lang_admin" | language }}</option>
			</select>
		</div>
		<div class="col-12 mb-10">
			<ng-select
				[items]="modules"
				[multiple]="true"
				bindLabel="label"
				appendTo="body"
				bindValue="value"
				clearAllText="Clear"
				placeholder="{{ 'lang_search_modules' | language }}"
				[(ngModel)]="searchByModule"
			>
			</ng-select>
		</div>
		<div class="col-12 mb-10">
			<ng-select
				[items]="statusList"
				[multiple]="false"
				bindLabel="label"
				appendTo="body"
				bindValue="value"
				[clearable]="false"
				placeholder="{{ 'lang_status' | language }}"
				[(ngModel)]="searchByStatus"
			>
			</ng-select>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="d-flex justify-content-between" style="width: 100%">
		<div style="width: 100px">
			<button type="button" class="albutton" (click)="resetFilterForm()">
				{{ "lang_reset" | language }}
			</button>
		</div>
		<div style="width: 100px">
			<button
				mat-button
				class="albutton mnabrbutton mat-accent"
				(click)="sendFilterForm()"
			>
				{{ "lang_save" | language }}
			</button>
		</div>
	</div>
</mat-dialog-actions>
