<form [formGroup]="form" (ngSubmit)="completeStep3()">
	<div class="card contractor_approval_manager">
		<div class="card-header">Manager Approval</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row mb-10">
					<div class="col-sm-6 col-xs-12 mb-10">
						<label class="mb-10">
							Transaction Approval !?. <span class="red"> * </span> </label
						><br />
						<mat-radio-group
							aria-label="Select an option"
							formControlName="contractor_approval_status"
							(change)="form.get('contractor_rejected_notes').setValue('')"
						>
							<mat-radio-button value="0"
								>Return To SI Request</mat-radio-button
							>
							<mat-radio-button value="1">Approve</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="col-sm-6 col-xs-12 mb-10">
						<div *ngIf="form.get('contractor_approval_status').value == '0'">
							<label>Rejected Notes : <span class="red">*</span></label>
							<textarea
								class="form-control almnabrformcontrol"
								formControlName="contractor_rejected_notes"
							></textarea>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12">
						<div class="form-group" *ngIf="sendCodeStatus">
							<label for="verification_code"
								>{{ "lang_enter_verification_code" | language }}:
							</label>
							<input
								type="text"
								required
								class="form-control almnabrformcontrol"
								formControlName="transactions_persons_action_code"
							/>
							<button
								type="button"
								(click)="confirmVerification()"
								*ngIf="sendCodeStatus"
								class="albutton mnabrbutton mat-accent"
								mat-button
							>
								{{ "lang_do_verification" | language }}
							</button>
						</div>
						<div class="col-sm-12" *ngIf="sendCodeStatus">
							<app-alert id="default-alert"></app-alert>
						</div>
					</div>
				</div>
				<div class="row mb-10">
					<div class="col-sm-4 col-xs-12 mb-10">
						<div>
							<label class="opacity0"><span class="red">*</span></label>
							<button
								class="albutton mnabrbutton mat-accent"
								type="button"
								(click)="openModal()"
								mat-button
							>
								{{ "lang_send_code" | language }}
							</button>
						</div>
					</div>
					<div class="col-sm-4 col-xs-12 mb-10"></div>
					<div class="col-sm-4 col-xs-12 mb-10">
						<div>
							<label class="opacity0"><span class="red">*</span></label>
							<input
								type="submit"
								value="{{ 'lang_submit' | language }}"
								class="albutton mnabrbutton mat-accent"
								mat-button
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
