import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Stepper from "bs-stepper";
import { MatStepper } from "@angular/material/stepper";

declare var $: any;
@Component({
  selector: "app-formpq2-add",
  templateUrl: "./formpq2-add.component.html",
  styleUrls: ["./formpq2-add.component.scss"],
})
export class Formpq2AddComponent implements OnInit {
  private stepper: Stepper | any;
  private stepperOpen: Stepper | any;
  // public Editor = ClassicEditor;
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() service_data;
  @Output() quotEvent = new EventEmitter();
  lang_key = localStorage.getItem("lang_key") || "en";

  tabs = ["First", "Second", "Third"];
  selected = new FormControl(0);

  addTab(selectAfterAdding: boolean) {
    this.tabs.push("New");

    if (selectAfterAdding) {
      this.selected.setValue(this.tabs.length - 1);
    }
  }

  removeTab(index: number) {
    this.tabs.splice(index, 1);
  }

  form: FormGroup | any;
  signature_form: FormGroup | any;
  languages = [];
  vat_list: any = [];
  engineering_type = [];
  arch_list = [];
  quotation_type_list = [];

  soil_sensor_status = [];
  occupancies = [];

  users: any = [];
  markstatus: boolean = false;
  reviewstatus: boolean = false;
  viewerstatus: boolean = false;
  stampstatus: boolean = false;
  attachstatus: boolean = false;
  othersstatus: boolean = false;
  stamps: any = [];
  filename = this.lang.transform("lang_select_files");
  isFile: boolean = false;
  attachments: FormArray;
  dynamic_table_fields: FormArray;
  others_fields: FormArray;
  submitted: boolean = false;
  restrictions_fixed_optional_items = [];
  dynamicrestrictions = false;
  stepOneAddAndDeleteLog = {
    add: [],
    delete: [],
  };
  occupancies_list = [];
  planreq = [];
  stages = [];
  plans = [];
  constructor(
    private fb: FormBuilder,
    private spinner: PreloaderService,
    private snackBar: MatSnackBar,
    public changeDetectorRef: ChangeDetectorRef,
    public alert: AlertService,
    public router: Router,
    public lang: LanguagePipe,
    public ds: DataService
  ) {}
  showMsg(msg: string, action: string, className: string) {
    this.snackBar.open(msg, action, {
      duration: 4000,
      panelClass: [className],
    });
  }
  arr1 = [1, 3, 4, 5, 8, 9, 10, 2];
  arr2 = [1, 2, 3, 4, 5, 6, 7];
  filterFunc(arr1, arr2) {
    for (let index = 0; index < arr1.length; index++) {
      const element = arr1[index];
      let currentIndex = arr2.findIndex((i) => i == element);
      if (currentIndex == -1) {
        arr2.splice(index, 0, element);
      }
    }
    let oldData = [];
    for (let index = 0; index < arr2.length; index++) {
      const element = arr2[index];
      let currentIndex = arr1.findIndex((i) => i == element);
      if (currentIndex == -1) {
        oldData.push(element);
      }
    }
  }
  ngOnInit() {
    this.buildForm();
    this.build_signature_form();
    this.get_languages();
    this.get_required_data("vat");

    this.get_required_data("occupy");
    this.get_required_data("plans", "ALL");
    this.get_required_data("planreq");

    this.get_required_data("stages");
    this.get_terms("standard", "terms");
    this.get_terms("standard", "gterms");

    this.spinner.show();
    let param = new FormData();
    param.append("form_pq2_request_type_keyword", "standard");
    param.append("projects_profile_id", this.project_data.projects_profile_id);
    param.append("form_pq2_request_sub_type", "options");
    param.append("lang_key", this.lang_key);
    param.append("projects_ps_id", this.service_data.projects_ps_id);
    this.ds
      .getActionByUrl(
        this.ds.formData2string(param),
        "form/FORM_PQ2/terms_conditions_options"
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.dynamicrestrictions = res.status;
          if (res.status) {
            let dynamiccheck = [];
            res.records.forEach((v, k) => {
              dynamiccheck.push({
                label: v.label,
                value: v.value,
                checked: false,
              });
            });
            this.restrictions_fixed_optional_items = dynamiccheck;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }
  public search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append("search", key.term);
    param.append("lang_key", this.lang_key);
    param.append("user_type_id", "1");
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
      .subscribe((res) => {
        if (res.status) {
          this.users = res.list;
        }
      });
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.stepper = new Stepper(document.querySelector("#stepper1"), {
      linear: false,
      animation: true,
    });

    this.stepperOpen = new Stepper(document.querySelector("#stepperOpen"), {
      linear: false,
      animation: true,
    });
  }

  public build_signature_form() {
    this.signature_form = new FormGroup({
      needMark: new FormControl("0", [Validators.required]),
      needReview: new FormControl("0", [Validators.required]),
      needStamp: new FormControl("0", [Validators.required]),
      needAttach: new FormControl("0", [Validators.required]),
      needViewers: new FormControl("0", [Validators.required]),
      otherTerms: new FormControl("0", [Validators.required]),
      "signature[A][title]": new FormControl("", [Validators.required]),
      "signature[A][user_id]": new FormControl(null, [Validators.required]),
      "signature[B][title]": new FormControl("", [Validators.required]),
      "signature[B][user_id]": new FormControl(null, [Validators.required]),
      stamp: new FormControl(null),
      "signature[C][title]": new FormControl("", [Validators.required]),
      "signature[C][user_id]": new FormControl(null, [Validators.required]),
      marks: new FormControl(null),
      reviews: new FormControl(null),
      viewers: new FormControl(null),
      attachments: this.fb.array([]),
      others: this.fb.array([]),
      dynamic_table_fields: this.fb.array([]),
    });

    this.attachments = this.signature_form.get("attachments") as FormArray;
    this.others_fields = this.signature_form.get("others") as FormArray;
    this.dynamic_table_fields = this.signature_form.get(
      "dynamic_table_fields"
    ) as FormArray;
  }
  buildForm() {
    this.form = this.fb.group({
      stepOne: this.fb.group({
        projects_profile_id: [null],
        projects_ps_id: [null],
        lang_key: [null, Validators.required],
        quotation_type: ["", Validators.required],
        vat_key: [null, Validators.required],
        subject: [null, Validators.required],
        care: [null, Validators.required],

        city: [null, Validators.required],
        total_area_m2: [null, Validators.required],
        duration_days: [null, Validators.required],
        main_occupancies: [null, Validators.required],
        description: [null, Validators.required],
      }),
      stepTwo: this.fb.group({
        open_steps: this.fb.group({
          //step 1
          quotation_price: this.fb.group({
            price_amount_byhand: ["", Validators.required],

            quotation_prices: this.fb.array([
              this.fb.group({
                quotation_prices_type: ["byhand", [Validators.required]],
                quotation_prices_amount: ["", [Validators.required]],
                quotation_prices_profit_percentage: [
                  "",
                  [Validators.required, Validators.max(100)],
                ],
                quotation_prices_total_amount: ["", [Validators.required]],
                quotation_prices_profit_percentage_amount: [
                  "",
                  [Validators.required],
                ],
                trackingId: [this.generateUniqueId()],
              }),
            ]),
          }),

          //step 2
          financial_managments: this.fb.group({
            financial_quot_price_type: ["byhand", Validators.required],
            total_vat: [null],
            total_without_vat: [null],
            total_all: [null],

            financial_payments: this.fb.array([
              this.fb.group({
                payment_title_english: ["", [Validators.required]],
                payment_title_arabic: ["", [Validators.required]],
                payment_stage: ["", [Validators.required]],
                payment_stage_level: ["", [Validators.required]],

                trackingId: [this.generateUniqueId()],
              }),
            ]),
          }),
        }),

        standard_steps: this.fb.group({
          // Totals
          soil_sensor_total: [null],
          total_net_quantity: [null],
          plans_total: [null],
          total_buildings: [null],
          total_printing_costs: [null],
          total_engineering_costs: [null],
          total_government_services: [null],
          total_other_costs: [null],
          some_of_plans_total_val: [null],
          // Totals

          // Step 1
          project_plan_requirements: this.fb.array([]),

          // Step 2

          project_component_soil_sensor: this.fb.group({
            project_component_soil_sensor_status: [null, Validators.required],
            project_component_soil_sensor_legal_notes: [],
          }),

          // Step 3

          project_analysis_data: this.fb.array([
            this.fb.group({
              project_component_subject_english: [null, Validators.required],
              project_component_subject_arabic: [null, Validators.required],
              project_component_space: [null, Validators.required],
              project_component_building_quantity: [null, Validators.required],
              project_component_level_quantity: [
                1,
                [Validators.required, Validators.min(1)],
              ],

              project_plan_requirements: this.fb.array([
                this.fb.group({
                  levels_total: [null, Validators.required],
                  trackingId: [this.generateUniqueId()],
                  collabsed: [false],
                  levels: this.fb.array([
                    this.fb.group({
                      level_value: [null, Validators.required],
                      level_occupancy: [null, Validators.required],
                      trackingId: [this.generateUniqueId()],
                    }),
                  ]),
                }),
              ]),
              project_component_soil_sensor_building_quantity: [
                { value: "", disabled: true },
                [Validators.required],
              ],
              project_component_soil_sensor_depth_level_quantity: [
                { value: "", disabled: true },
                [Validators.required],
              ],
              project_component_soil_sensor_cost_per_lmeter: [
                { value: "", disabled: true },
                [Validators.required],
              ],
              trackingId: [this.generateUniqueId()],
              plan_quantities_total: [""],
              plan_quantities: this.fb.array([]),
            }),
          ]),

          // Step 4

          surveying_costs: this.fb.group({
            surveying_business_costs_status: [null, Validators.required],
            surveying_business_costs_legal_notes: [null],
            business_surveying_whole_total: [null],
            surveying_costs: this.fb.array([
              this.fb.group({
                surveying_title_english: ["", [Validators.required]],
                surveying_title_arabic: ["", [Validators.required]],
                surveying_cost_value: ["", [Validators.required]],
                surveying_cost_total_value: ["", [Validators.required]],
                trackingId: [this.generateUniqueId()],
              }),
            ]),
          }),

          // Step 5

          printing_costs: this.fb.group({
            printing_costs_status: [null, Validators.required],
            printing_costs_legal_notes: [null],
            printing_costs_whole_total: [null],
            printing_costs: this.fb.array([
              this.fb.group({
                printing_title_english: [
                  "Printing Stages",
                  [Validators.required],
                ],
                printing_title_arabic: ["طباعة المرحلة", [Validators.required]],
                printing_costs_no_of_copies: [3, [Validators.required]],
                printing_costs_no_of_panels: ["", [Validators.required]],
                printing_costs_ratio_cost: [0.5, [Validators.required]],
                trackingId: [this.generateUniqueId()],
              }),
              this.fb.group({
                printing_title_english: [
                  "Final Printing ",
                  [Validators.required],
                ],
                printing_title_arabic: [
                  "الطباعة النهائية",
                  [Validators.required],
                ],
                printing_costs_no_of_copies: [20, [Validators.required]],
                printing_costs_no_of_panels: ["", [Validators.required]],
                printing_costs_ratio_cost: [0.5, [Validators.required]],
                trackingId: [this.generateUniqueId()],
              }),
              this.fb.group({
                printing_title_english: [
                  "Bill Quantity Printing",
                  [Validators.required],
                ],
                printing_title_arabic: ["طباعة الكميات", [Validators.required]],
                printing_costs_no_of_copies: [25, [Validators.required]],
                printing_costs_no_of_panels: ["", [Validators.required]],
                printing_costs_ratio_cost: [0.1, [Validators.required]],
                trackingId: [this.generateUniqueId()],
              }),
              this.fb.group({
                printing_title_english: [
                  "Technical Specifications",
                  [Validators.required],
                ],
                printing_title_arabic: ["مواصفات فنية", [Validators.required]],
                printing_costs_no_of_copies: [22, [Validators.required]],
                printing_costs_no_of_panels: ["", [Validators.required]],
                printing_costs_ratio_cost: [1, [Validators.required]],
                trackingId: [this.generateUniqueId()],
              }),
            ]),
          }),

          // Step 6

          engineering_costs: this.fb.array([
            this.fb.group({
              engineering_cost_title_english: [null, Validators.required],
              engineering_cost_title_arabic: [null, Validators.required],
              plan_keyword: [null, Validators.required],
              quantity: [null, Validators.required],
              cost_per_day: [null, Validators.required],
              trackingId: [this.generateUniqueId()],
            }),
          ]),

          // Step 7

          government_services: this.fb.group({
            government_services_status: [null, Validators.required],

            government_services: this.fb.array([
              this.fb.group({
                government_services_subject_english: [
                  "",
                  [Validators.required],
                ],
                government_services_subject_arabic: ["", [Validators.required]],
                government_services_quantity: ["", [Validators.required]],
                government_services_ratio_cost: ["", [Validators.required]],
                trackingId: [this.generateUniqueId()],
              }),
            ]),
          }),

          //step 8
          other_costs: this.fb.group({
            other_costs_status: [null, Validators.required],
            other_costs: this.fb.array([
              this.fb.group({
                other_costs_subject_english: ["", [Validators.required]],
                other_costs_subject_arabic: ["", [Validators.required]],
                other_costs_quantity: ["", [Validators.required]],
                other_costs_ratio_cost: ["", [Validators.required]],
                trackingId: [this.generateUniqueId()],
              }),
            ]),
          }),
          //step 9
          main_analysis_costs: this.fb.group({
            main_analysis_total: [null, Validators.required],
            total_analysis: [null, Validators.required],
          }),

          //step 10
          quotation_price: this.fb.group({
            price_amount_byhand: [null, Validators.required],
            profit_percent_byplan: [null],
            profit_percent_byarea: [null],
            profit_percent_bytotalcost: [null],
            profit_percent_byhours: [null],

            quotation_prices: this.fb.array([]),
          }),

          //step 11
          financial_managments: this.fb.group({
            financial_quot_price_type: [null, Validators.required],
            total_vat: [null],
            total_without_vat: [null],
            total_all: [null],

            financial_payments: this.fb.array([
              this.fb.group({
                payment_title_english: ["", [Validators.required]],
                payment_title_arabic: ["", [Validators.required]],
                payment_stage: ["", [Validators.required]],
                payment_stage_level: ["", [Validators.required]],

                trackingId: [this.generateUniqueId()],
              }),
            ]),
          }),
        }),
      }),
    });
  }

  @ViewChild("contentData", { static: false }) contentdata: ElementRef;
  dynamiccontents = false;

  @ViewChild("contentData_two", { static: false }) contentdata_two: ElementRef;
  dynamiccontents_two = false;
  public get_terms(val, sub_type) {
    if (sub_type == "terms") {
      this.dynamiccontents = false;

      this.spinner.show();
      let param = new FormData();
      param.append("form_pq2_request_type_keyword", val);
      param.append("form_pq2_request_sub_type", sub_type);
      param.append("lang_key", this.lang_key);
      param.append("projects_ps_id", this.service_data.projects_ps_id);
      param.append(
        "projects_profile_id",
        this.project_data.projects_profile_id
      );
      //descriptions
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "form/FORM_PQ2/terms_conditions_options"
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            this.dynamiccontents = res.status;
            if (res.status) {
              this.changeDetectorRef.detectChanges();
              let text = "";
              res.records.forEach((v, k) => {
                text += v.label + "<br>";
              });
              this.contentdata.nativeElement.innerHTML = text;
            }
          },
          (error) => {
            this.dynamiccontents = false;
            this.spinner.hide();
          }
        );
    } else {
      this.dynamiccontents_two = false;

      this.spinner.show();
      let param = new FormData();
      param.append("form_pq2_request_type_keyword", val);
      param.append("form_pq2_request_sub_type", sub_type);
      param.append("lang_key", this.lang_key);
      param.append("projects_ps_id", this.service_data.projects_ps_id);
      param.append(
        "projects_profile_id",
        this.project_data.projects_profile_id
      );
      //descriptions
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "form/FORM_PQ2/terms_conditions_options"
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            this.dynamiccontents_two = res.status;
            if (res.status) {
              this.changeDetectorRef.detectChanges();
              let text = "";
              res.records.forEach((v, k) => {
                text += v.label + "<br>";
              });
              this.contentdata_two.nativeElement.innerHTML = text;
            }
          },
          (error) => {
            this.dynamiccontents_two = false;
            this.spinner.hide();
          }
        );
    }

    this.spinner.hide();
  }

  checkValidation(formGroup: FormGroup) {
    let valid = true;
    let numOfTotals = 0;
    for (let ii = 0; ii < formGroup.value.levels.length; ii++) {
      const ee = formGroup.value.levels[ii];
      numOfTotals += ee.level_value;
    }
    if (formGroup.value.levels_total != numOfTotals) {
      valid = false;
    }

    let levels_total = formGroup.controls.levels_total as FormControl;
    if (valid) {
      return levels_total.setErrors(null);
    } else {
      return levels_total.setErrors({
        notEqual: true,
      });
    }
  }

  updateTotalPlanQuantitiyAfterChangePlan(target) {
    if (target.plan_keyword.value) {
      let index = this.getstandard_steps.controls["project_plan_requirements"][
        "controls"
      ].findIndex((i) => {
        return i["controls"]?.plan_keyword.value == target.plan_keyword.value;
      });
      let obj =
        this.getSmallSteps.project_plan_requirements.controls[index][
          "controls"
        ];

      if (target.plan_quantity_count.value) {
        target.plan_quantity_total_val.setValue(
          +target.plan_quantity_count.value *
            +obj.plan_hour_value.value *
            +obj.plan_total_hours.value
        );
      }
    }
  }

  updateQuotationPricesValuesByPercent(target) {
    if (target.value.quotation_prices_profit_percentage) {
      target.patchValue({
        ...target.value,
        quotation_prices_profit_percentage_amount:
          (+target.value.quotation_prices_profit_percentage / 100) *
          +target.value.quotation_prices_amount,
        quotation_prices_total_amount:
          +target.value.quotation_prices_amount +
          +(+target.value.quotation_prices_profit_percentage / 100) *
            +target.value.quotation_prices_amount,
      });
    }
  }

  putValueInByHand() {
    let quotation_prices = this.getSmallSteps?.quotation_price?.controls
      ?.quotation_prices as FormArray;
    let index = quotation_prices.value.findIndex(
      (ob) => ob.quotation_prices_type == "byhand"
    );

    let quotation_prices_profit_percentage = quotation_prices.controls[index][
      "controls"
    ].quotation_prices_profit_percentage as FormControl;

    if (this.getSmallSteps?.quotation_price?.value?.price_amount_byhand > 0) {
      quotation_prices_profit_percentage.enable();

      quotation_prices.controls[index].patchValue({
        ...quotation_prices.controls[index].value,
        quotation_prices_amount:
          this.getSmallSteps?.quotation_price?.value?.price_amount_byhand,
        quotation_prices_profit_percentage: 0,
        quotation_prices_profit_percentage_amount: 0,
        quotation_prices_total_amount: 0,
      });
    } else {
      quotation_prices_profit_percentage.disable();
    }

    quotation_prices_profit_percentage.updateValueAndValidity();
  }

  putValueInByHandOpen() {
    let quotation_prices = this.getSmallStepsOpen?.quotation_price?.controls
      ?.quotation_prices as FormArray;
    let index = quotation_prices.value.findIndex(
      (ob) => ob.quotation_prices_type == "byhand"
    );

    let quotation_prices_profit_percentage = quotation_prices.controls[index][
      "controls"
    ].quotation_prices_profit_percentage as FormControl;

    if (
      this.getSmallStepsOpen?.quotation_price?.value?.price_amount_byhand > 0
    ) {
      quotation_prices_profit_percentage.enable();

      quotation_prices.controls[index].patchValue({
        ...quotation_prices.controls[index].value,
        quotation_prices_amount:
          this.getSmallStepsOpen?.quotation_price?.value?.price_amount_byhand,
        quotation_prices_profit_percentage: 0,
        quotation_prices_profit_percentage_amount: 0,
        quotation_prices_total_amount: 0,
      });
    } else {
      quotation_prices_profit_percentage.disable();
    }

    quotation_prices_profit_percentage.updateValueAndValidity();
  }
  total_palns__ = 0;
  total_buildings__ = 0;
  makeCalculations() {
    let total_soil_sensor_total = 0;
    let total_net_quantitiy = 0;
    this.total_palns__ = 0;
    this.total_buildings__ = 0;

    this.form.controls.stepTwo.controls.standard_steps.controls.project_analysis_data.value.forEach(
      (c, index) => {
        if (c.project_component_space && c.project_component_level_quantity) {
          this.form.controls.stepTwo.controls.standard_steps.controls.project_analysis_data.controls[
            index
          ].patchValue({
            ...this.form.controls.stepTwo.controls.standard_steps.controls
              .project_analysis_data.controls[index].value,
            project_component_soil_sensor_building_quantity:
              c.project_component_space /
              (500 * c.project_component_level_quantity),
          });
        }

        if (
          c.project_component_space &&
          c.project_component_level_quantity &&
          c.project_component_building_quantity &&
          c.project_component_level_quantity
        ) {
          this.form.controls.stepTwo.controls.standard_steps.controls.project_analysis_data.controls[
            index
          ].patchValue({
            ...this.form.controls.stepTwo.controls.standard_steps.controls
              .project_analysis_data.controls[index].value,
            project_component_soil_sensor_depth_level_quantity:
              6 *
              (c.project_component_space /
                (500 * c.project_component_level_quantity)) *
              c.project_component_building_quantity *
              c.project_component_level_quantity,
          });
        }

        if (
          c.project_component_soil_sensor_cost_per_lmeter &&
          c.project_component_space &&
          c.project_component_level_quantity &&
          c.project_component_building_quantity &&
          c.project_component_level_quantity
        ) {
          total_soil_sensor_total +=
            c.project_component_soil_sensor_cost_per_lmeter *
            (6 *
              (c.project_component_space /
                (500 * c.project_component_level_quantity)) *
              c.project_component_building_quantity *
              c.project_component_level_quantity);
        }

        if (
          c.project_component_building_quantity &&
          c.project_component_level_quantity
        ) {
          let total_plan_quantities = 0;
          for (let p = 0; p < c.plan_quantities.length; p++) {
            const element = c.plan_quantities[p];
            total_plan_quantities += element.plan_quantity_total_val;
          }

          this.form.controls.stepTwo.controls.standard_steps.patchValue({
            ...this.form.controls.stepTwo.controls.standard_steps.value,
            some_of_plans_total_val: total_plan_quantities,
          });
          this.form.controls.stepTwo.controls.standard_steps.controls.project_analysis_data.controls[
            index
          ].patchValue({
            ...this.form.controls.stepTwo.controls.standard_steps.controls
              .project_analysis_data.controls[index].value,
            plan_quantities_total:
              +c.project_component_building_quantity *
              +c.project_component_level_quantity *
              total_plan_quantities,
          });

          total_net_quantitiy +=
            +c.project_component_building_quantity *
            +c.project_component_level_quantity *
            total_plan_quantities;
        }
        if (c.project_component_building_quantity) {
          this.total_buildings__ += +c.project_component_building_quantity;
        }

        for (let p = 0; p < c.plan_quantities.length; p++) {
          const element = c.plan_quantities[p];
          this.total_palns__ += +element.plan_quantity_count;
        }
      }
    );

    if (total_soil_sensor_total > 0) {
      this.form.controls.stepTwo.controls.standard_steps.patchValue({
        ...this.form.controls.stepTwo.controls.standard_steps.value,
        soil_sensor_total: total_soil_sensor_total,
      });
    }

    if (total_net_quantitiy > 0) {
      this.form.controls.stepTwo.controls.standard_steps.patchValue({
        ...this.form.controls.stepTwo.controls.standard_steps.value,
        total_net_quantity: total_net_quantitiy,
      });
    }
    let printing_costs = this.form.controls.stepTwo.controls.standard_steps
      .controls.printing_costs as FormGroup;
    if (this.total_palns__ > 0) {
      this.form.controls.stepTwo.controls.standard_steps.patchValue({
        ...this.form.controls.stepTwo.controls.standard_steps.value,
        plans_total: this.total_palns__,
      });

      if (
        printing_costs.controls.printing_costs["controls"] &&
        printing_costs.controls.printing_costs["controls"].length > 0
      ) {
        printing_costs.controls.printing_costs["controls"][0].patchValue({
          ...printing_costs.controls.printing_costs["controls"][0].value,
          printing_costs_no_of_panels: this.total_palns__,
        });
      }

      if (
        printing_costs.controls.printing_costs["controls"] &&
        printing_costs.controls.printing_costs["controls"].length > 1
      ) {
        printing_costs.controls.printing_costs["controls"][1].patchValue({
          ...printing_costs.controls.printing_costs["controls"][1].value,
          printing_costs_no_of_panels: this.total_palns__,
        });
      }
    }

    if (this.total_buildings__ > 0) {
      this.form.controls.stepTwo.controls.standard_steps.patchValue({
        ...this.form.controls.stepTwo.controls.standard_steps.value,
        total_buildings: this.total_buildings__,
      });

      if (
        printing_costs.controls.printing_costs["controls"] &&
        printing_costs.controls.printing_costs["controls"].length > 2
      ) {
        printing_costs.controls.printing_costs["controls"][2].patchValue({
          ...printing_costs.controls.printing_costs["controls"][2].value,
          printing_costs_no_of_panels: this.total_buildings__ * 100,
        });
      }
    }
  }

  makeBusunessServeyingWholeTotalCalc() {
    let total = 0;
    let surveying_costs = this.form.controls.stepTwo.controls.standard_steps
      .controls.surveying_costs as FormGroup;

    for (
      let index = 0;
      index < surveying_costs?.value?.surveying_costs?.length;
      index++
    ) {
      const element = surveying_costs.value.surveying_costs[index];
      total += element.surveying_cost_total_value;
    }

    if (total > 0) {
      surveying_costs.patchValue({
        ...surveying_costs.value,
        business_surveying_whole_total: total,
      });
    }
  }

  printingCostsWholeTotalCalc() {
    let total = 0;
    let printing_costs = this.form.controls.stepTwo.controls.standard_steps
      .controls.printing_costs as FormGroup;

    for (
      let index = 0;
      index < printing_costs?.value?.printing_costs?.length;
      index++
    ) {
      const element = printing_costs.value.printing_costs[index];
      total +=
        +element.printing_costs_no_of_copies *
        +element.printing_costs_ratio_cost *
        +element.printing_costs_no_of_panels;
    }

    if (total > 0) {
      this.form.controls.stepTwo.controls.standard_steps.patchValue({
        ...this.form.controls.stepTwo.controls.standard_steps.value,
        total_printing_costs: total,
      });
    }

    let engineering_costs = this.form.controls.stepTwo.controls.standard_steps
      .controls.engineering_costs as FormArray;
    let project_plan_requirements = this.getstandard_steps.controls
      .project_plan_requirements as FormArray;

    for (let t = 0; t < engineering_costs.controls.length; t++) {
      const eng_costs: any = engineering_costs.controls[t];

      for (
        let index = 0;
        index < project_plan_requirements.value.length;
        index++
      ) {
        const plan_req = project_plan_requirements.controls[index] as FormGroup;

        let currentIndex = engineering_costs.controls.findIndex(
          (i) =>
            i["controls"].plan_keyword.value ==
            plan_req.controls.plan_keyword.value
        );
        if (currentIndex == -1) {
          engineering_costs.push(
            this.fb.group({
              engineering_cost_title_english: [
                plan_req["controls"]?.plan_title_english.value,
                Validators.required,
              ],
              engineering_cost_title_arabic: [
                plan_req["controls"]?.plan_title_arabic.value,
                Validators.required,
              ],
              plan_keyword: [
                plan_req["controls"]?.plan_keyword.value,
                Validators.required,
              ],
              quantity: [null, Validators.required],
              cost_per_day: [null, Validators.required],
              trackingId: [this.generateUniqueId()],
            })
          );
        }
      }
    }

    let oldData = [];
    for (let t = 0; t < engineering_costs.controls.length; t++) {
      const eng_costs = engineering_costs.controls[t] as FormGroup;

      let currentIndex = project_plan_requirements.controls.findIndex(
        (i) =>
          i["controls"].plan_keyword.value ==
          eng_costs.controls.plan_keyword.value
      );
      if (currentIndex == -1) {
        oldData.push(eng_costs.controls.plan_keyword.value);
      }
    }

    for (let index = 0; index < oldData.length; index++) {
      const element = oldData[index];

      for (let t = 0; t < engineering_costs.controls.length; t++) {
        const eng_costs = engineering_costs.controls[t] as FormGroup;

        if (eng_costs.controls.plan_keyword.value == element) {
          engineering_costs.removeAt(t);
        }
      }
    }
  }

  governmentCostsWholeTotalCalc() {
    let total = 0;
    let government_services = this.form.controls.stepTwo.controls.standard_steps
      .controls.government_services as FormGroup;

    for (
      let index = 0;
      index < government_services?.value?.government_services?.length;
      index++
    ) {
      const element = government_services.value.government_services[index];
      total +=
        +element.government_services_quantity *
        +element.government_services_ratio_cost;
    }

    if (total > 0) {
      this.form.controls.stepTwo.controls.standard_steps.patchValue({
        ...this.form.controls.stepTwo.controls.standard_steps.value,
        total_government_services: total,
      });
    }
  }

  qoutationCalcs() {
    let standard_steps = this.form.controls.stepTwo.controls
      .standard_steps as FormGroup;
    let stepOne = this.form.controls.stepOne as FormGroup;

    let profit_percent_byplan = 0;
    let profit_percent_byarea = 0;
    let profit_percent_bytotalcost = 0;
    let profit_percent_byhours = 0;

    profit_percent_byplan +=
      (+standard_steps.value.soil_sensor_total +
        +standard_steps.value.surveying_costs.business_surveying_whole_total +
        +standard_steps.value.total_printing_costs +
        +standard_steps.value.total_engineering_costs) /
      +standard_steps.value.plans_total;

    profit_percent_byarea = +stepOne.value.total_area_m2 * 40;

    profit_percent_bytotalcost =
      +standard_steps.value.soil_sensor_total +
      +standard_steps.value.surveying_costs.business_surveying_whole_total +
      +standard_steps.value.total_printing_costs +
      +standard_steps.value.total_engineering_costs +
      +standard_steps.value.total_government_services +
      +standard_steps.value.total_other_costs;

    profit_percent_byhours = +standard_steps.value.some_of_plans_total_val;

    this.form?.controls?.stepTwo?.controls?.standard_steps?.controls?.quotation_price.patchValue(
      {
        ...this.form?.controls?.stepTwo?.controls?.standard_steps?.controls
          ?.quotation_price.value,
        profit_percent_byplan: profit_percent_byplan,
        profit_percent_byarea: profit_percent_byarea,
        profit_percent_bytotalcost: profit_percent_bytotalcost,
        profit_percent_byhours: profit_percent_byhours,
      }
    );

    let quotation_price = this.form.controls.stepTwo.controls.standard_steps
      .controls.quotation_price.controls.quotation_prices as FormArray;

    if (quotation_price.controls.length > 0) {
      if (
        quotation_price.controls[1].value.quotation_prices_amount ==
        profit_percent_byarea
      ) {
        quotation_price.controls[1].patchValue({
          ...quotation_price.controls[1].value,
        });
      } else {
        quotation_price.controls[1].patchValue({
          ...quotation_price.controls[1].value,
          quotation_prices_amount: profit_percent_byarea,
          quotation_prices_type: "byarea",
          quotation_prices_profit_percentage: null,
          quotation_prices_profit_percentage_amount: null,
          quotation_prices_total_amount: null,
          trackingId: [this.generateUniqueId()],
        });
      }

      if (
        quotation_price.controls[2].value.quotation_prices_amount ==
        profit_percent_byplan
      ) {
        quotation_price.controls[2].patchValue({
          ...quotation_price.controls[2].value,
        });
      } else {
        quotation_price.controls[2].patchValue({
          ...quotation_price.controls[2].value,
          quotation_prices_amount: profit_percent_byplan,
          quotation_prices_type: "byplan",
          quotation_prices_profit_percentage: null,
          quotation_prices_profit_percentage_amount: null,
          quotation_prices_total_amount: null,
          trackingId: [this.generateUniqueId()],
        });
      }

      if (
        quotation_price.controls[3].value.quotation_prices_amount ==
        profit_percent_bytotalcost
      ) {
        quotation_price.controls[3].patchValue({
          ...quotation_price.controls[3].value,
        });
      } else {
        quotation_price.controls[3].patchValue({
          ...quotation_price.controls[3].value,
          quotation_prices_amount: profit_percent_bytotalcost,
          quotation_prices_type: "bytotalcost",
          quotation_prices_profit_percentage: null,
          quotation_prices_profit_percentage_amount: null,
          quotation_prices_total_amount: null,
          trackingId: [this.generateUniqueId()],
        });
      }

      if (
        quotation_price.controls[4].value.quotation_prices_amount ==
        profit_percent_byhours
      ) {
        quotation_price.controls[4].patchValue({
          ...quotation_price.controls[4].value,
        });
      } else {
        if (
          quotation_price.controls[4].value.quotation_prices_amount ==
          profit_percent_byhours
        ) {
          quotation_price.controls[4].patchValue({
            ...quotation_price.controls[4].value,
            quotation_prices_amount: profit_percent_byhours,
            quotation_prices_type: "byhours",
            quotation_prices_profit_percentage: null,
            quotation_prices_profit_percentage_amount: null,
            quotation_prices_total_amount: null,
            trackingId: [this.generateUniqueId()],
          });
        }
      }
    } else {
      quotation_price.push(
        this.fb.group({
          quotation_prices_type: ["byhand", Validators.required],
          quotation_prices_amount: [null, Validators.required],
          quotation_prices_profit_percentage: [
            { value: null, disabled: true },
            [Validators.required, Validators.max(100)],
          ],
          quotation_prices_total_amount: [null, Validators.required],
          quotation_prices_profit_percentage_amount: [
            null,
            Validators.required,
          ],
          trackingId: [this.generateUniqueId()],
        })
      );
      quotation_price.push(
        this.fb.group({
          quotation_prices_type: ["byarea", Validators.required],
          quotation_prices_amount: [
            profit_percent_byarea,
            profit_percent_byarea > 0 ? Validators.required : [],
          ],
          quotation_prices_profit_percentage: [
            null,
            profit_percent_byarea > 0
              ? [Validators.required, Validators.max(100)]
              : [],
          ],
          quotation_prices_total_amount: [
            null,
            profit_percent_byarea > 0 ? Validators.required : [],
          ],
          quotation_prices_profit_percentage_amount: [
            null,
            profit_percent_byarea > 0 ? Validators.required : [],
          ],
          trackingId: [this.generateUniqueId()],
        })
      );
      quotation_price.push(
        this.fb.group({
          quotation_prices_type: ["byplan", Validators.required],
          quotation_prices_amount: [
            profit_percent_byplan,
            profit_percent_byplan > 0 ? Validators.required : [],
          ],
          quotation_prices_profit_percentage: [
            null,
            profit_percent_byplan > 0
              ? [Validators.required, Validators.max(100)]
              : [],
          ],
          quotation_prices_total_amount: [
            null,
            profit_percent_byplan > 0 ? Validators.required : [],
          ],
          quotation_prices_profit_percentage_amount: [
            null,
            profit_percent_byplan > 0 ? Validators.required : [],
          ],
          trackingId: [this.generateUniqueId()],
        })
      );

      quotation_price.push(
        this.fb.group({
          quotation_prices_type: ["bytotalcost", Validators.required],
          quotation_prices_amount: [
            profit_percent_bytotalcost,
            profit_percent_bytotalcost > 0 ? Validators.required : [],
          ],
          quotation_prices_profit_percentage: [
            null,
            profit_percent_bytotalcost > 0
              ? [Validators.required, Validators.max(100)]
              : [],
          ],
          quotation_prices_total_amount: [
            null,
            profit_percent_bytotalcost > 0 ? Validators.required : [],
          ],
          quotation_prices_profit_percentage_amount: [
            null,
            profit_percent_bytotalcost > 0 ? Validators.required : [],
          ],
          trackingId: [this.generateUniqueId()],
        })
      );

      quotation_price.push(
        this.fb.group({
          quotation_prices_type: ["byhours", Validators.required],
          quotation_prices_amount: [
            profit_percent_byhours,
            profit_percent_byhours > 0 ? Validators.required : [],
          ],
          quotation_prices_profit_percentage: [
            null,
            profit_percent_byhours > 0
              ? [Validators.required, Validators.max(100)]
              : [],
          ],
          quotation_prices_total_amount: [
            null,
            profit_percent_byhours > 0 ? Validators.required : [],
          ],
          quotation_prices_profit_percentage_amount: [
            null,
            profit_percent_byhours > 0 ? Validators.required : [],
          ],
          trackingId: [this.generateUniqueId()],
        })
      );
    }
  }

  OtherCostsWholeTotalCalc() {
    let total = 0;
    let other_costs = this.form.controls.stepTwo.controls.standard_steps
      .controls.other_costs as FormGroup;

    for (
      let index = 0;
      index < other_costs?.value?.other_costs?.length;
      index++
    ) {
      const element = other_costs.value.other_costs[index];
      total += +element.other_costs_quantity * +element.other_costs_ratio_cost;
    }

    if (total > 0) {
      this.form.controls.stepTwo.controls.standard_steps.patchValue({
        ...this.form.controls.stepTwo.controls.standard_steps.value,
        total_other_costs: total,
      });
    }

    let standard_steps =
      this.form.controls.stepTwo.controls.standard_steps.value;
    this.form.controls.stepTwo.controls.standard_steps.controls.main_analysis_costs.patchValue(
      {
        ...this.form.controls.stepTwo.controls.standard_steps.controls
          .main_analysis_costs.value,

        // Calculate main_analysis_total
        main_analysis_total:
          +standard_steps.soil_sensor_total +
          +standard_steps.total_net_quantity +
          +standard_steps.surveying_costs.business_surveying_whole_total +
          +standard_steps.total_printing_costs +
          +standard_steps.total_engineering_costs,

        // Calculate total_analysis
        total_analysis:
          +standard_steps.soil_sensor_total +
          +standard_steps.total_net_quantity +
          +standard_steps.surveying_costs.business_surveying_whole_total +
          +standard_steps.total_printing_costs +
          +standard_steps.total_engineering_costs +
          +standard_steps.total_government_services +
          +standard_steps.total_other_costs,
      }
    );
  }

  engineeringCostsWholeTotalCalc() {
    let total = 0;

    let engineering_costs = this.form.controls.stepTwo.controls.standard_steps
      .controls.engineering_costs as FormArray;
    for (let index = 0; index < engineering_costs.value.length; index++) {
      const element = engineering_costs.value[index];
      total +=
        +element.quantity *
        +element.cost_per_day *
        +this.form.value.duration_days;
    }
    if (total > 0) {
      this.form.controls.stepTwo.controls.standard_steps.patchValue({
        ...this.form.controls.stepTwo.controls.standard_steps.value,
        total_engineering_costs: total,
      });
    }
  }
  public isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 44 || charCode == 190 || charCode == 46) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57 || charCode == 101)) {
      return false;
    }
    return true;
  }
  getMainAnalysisCosts() {}

  changePlanReq(evt, i) {
    let project_plan_requirements = this.getstandard_steps.controls[
      "project_analysis_data"
    ]["controls"][i]["controls"]?.project_plan_requirements as FormArray;
    project_plan_requirements.clear();

    for (let index = 0; index < evt.target.value; index++) {
      project_plan_requirements.push(
        this.fb.group({
          levels_total: [null, Validators.required],
          trackingId: [this.generateUniqueId()],
          collabsed: [false],
          levels: this.fb.array([
            this.fb.group({
              level_value: [null, Validators.required],
              level_occupancy: [null, Validators.required],
              trackingId: [this.generateUniqueId()],
            }),
          ]),
        })
      );
    }
  }

  calcTotalForServaying(i) {
    var surveying_costs = this.getSmallSteps.surveying_costs["controls"]
      .surveying_costs["controls"][i] as FormGroup;
    let total_area_m2 = this.form.controls.stepOne.controls.total_area_m2.value;
    surveying_costs.patchValue({
      surveying_cost_total_value:
        +surveying_costs.value.surveying_cost_value * +total_area_m2,
    });
  }
  customNumValidation(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 44) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (evt.target.value == 0) {
      return false;
    }
    return true;
  }

  changeQout(e) {
    if (e.value == "open") {
      this.getstandard_stepsOpen.enable();

      this.getstandard_steps.disable();

      this.getstandard_steps.updateValueAndValidity();
      this.getstandard_stepsOpen.updateValueAndValidity();
    } else {
      this.getstandard_stepsOpen.disable();

      this.getstandard_steps.enable();

      this.getstandard_steps.updateValueAndValidity();
      this.getstandard_stepsOpen.updateValueAndValidity();
    }
  }

  validationOnSoil = "0";
  changeSoilSensor(e) {
    this.validationOnSoil = e.value;

    let project_analysis_data_arr = this.getSmallSteps.project_analysis_data;

    for (let index = 0; index < project_analysis_data_arr.length; index++) {
      let project_component_soil_sensor_cost_per_lmeter =
        project_analysis_data_arr["controls"][index]["controls"]
          .project_component_soil_sensor_cost_per_lmeter as FormControl;
      let project_component_soil_sensor_depth_level_quantity =
        project_analysis_data_arr["controls"][index]["controls"]
          .project_component_soil_sensor_depth_level_quantity as FormControl;
      let project_component_soil_sensor_building_quantity =
        project_analysis_data_arr["controls"][index]["controls"]
          .project_component_soil_sensor_building_quantity as FormControl;

      if (e.value == 1) {
        project_component_soil_sensor_building_quantity.setValidators(
          Validators.required
        );

        project_component_soil_sensor_depth_level_quantity.setValidators(
          Validators.required
        );

        project_component_soil_sensor_cost_per_lmeter.setValidators(
          Validators.required
        );

        project_component_soil_sensor_building_quantity.updateValueAndValidity();
        project_component_soil_sensor_depth_level_quantity.updateValueAndValidity();

        project_component_soil_sensor_cost_per_lmeter.updateValueAndValidity();
      } else {
        project_component_soil_sensor_building_quantity.clearValidators();

        project_component_soil_sensor_depth_level_quantity.clearValidators();

        project_component_soil_sensor_cost_per_lmeter.clearValidators();
        project_component_soil_sensor_building_quantity.updateValueAndValidity();
        project_component_soil_sensor_depth_level_quantity.updateValueAndValidity();

        project_component_soil_sensor_cost_per_lmeter.updateValueAndValidity();
      }
    }

    let project_component_soil_sensor = this.getSmallSteps
      .project_component_soil_sensor["controls"]
      .project_component_soil_sensor_legal_notes as FormControl;
    project_component_soil_sensor.clearValidators();
    project_component_soil_sensor.updateValueAndValidity();
  }

  printing_business_costs(e) {
    if (e.value == 0) {
      this.getSmallSteps.printing_costs[
        "controls"
      ].printing_costs.clearValidators();
      this.getSmallSteps.printing_costs[
        "controls"
      ].printing_costs.updateValueAndValidity();
      this.getSmallSteps.printing_costs["controls"].printing_costs.disable();
      this.getSmallSteps.printing_costs[
        "controls"
      ].printing_costs_legal_notes.setValidators(Validators.required);
      this.getSmallSteps.printing_costs[
        "controls"
      ].printing_costs_legal_notes.updateValueAndValidity();
    } else {
      this.getSmallSteps.printing_costs[
        "controls"
      ].printing_costs_legal_notes.clearValidators();
      this.getSmallSteps.printing_costs[
        "controls"
      ].printing_costs_legal_notes.updateValueAndValidity();
      this.getSmallSteps.printing_costs["controls"].printing_costs.enable();
      this.getSmallSteps.printing_costs[
        "controls"
      ].printing_costs.setValidators(Validators.required);
      this.getSmallSteps.printing_costs[
        "controls"
      ].printing_costs.updateValueAndValidity();
    }
  }

  surveying_business_costs(e) {
    if (e.value == 0) {
      this.getSmallSteps.surveying_costs[
        "controls"
      ].surveying_costs.clearValidators();
      this.getSmallSteps.surveying_costs[
        "controls"
      ].surveying_costs.updateValueAndValidity();
      this.getSmallSteps.surveying_costs["controls"].surveying_costs.disable();
      this.getSmallSteps.surveying_costs[
        "controls"
      ].surveying_business_costs_legal_notes.setValidators(Validators.required);
      this.getSmallSteps.surveying_costs[
        "controls"
      ].surveying_business_costs_legal_notes.updateValueAndValidity();
    } else {
      this.getSmallSteps.surveying_costs[
        "controls"
      ].surveying_business_costs_legal_notes.clearValidators();
      this.getSmallSteps.surveying_costs[
        "controls"
      ].surveying_business_costs_legal_notes.updateValueAndValidity();
      this.getSmallSteps.surveying_costs["controls"].surveying_costs.enable();
      this.getSmallSteps.surveying_costs[
        "controls"
      ].surveying_costs.setValidators(Validators.required);
      this.getSmallSteps.surveying_costs[
        "controls"
      ].surveying_costs.updateValueAndValidity();
    }
  }
  OPEN_CLOSE_GOVENTMENT_SERVICES(e) {
    if (e.value == 0) {
      this.getSmallSteps.government_services[
        "controls"
      ].government_services.clearValidators();
      this.getSmallSteps.government_services[
        "controls"
      ].government_services.updateValueAndValidity();
      this.getSmallSteps.government_services[
        "controls"
      ].government_services.disable();
    } else {
      this.getSmallSteps.government_services[
        "controls"
      ].government_services.enable();
      this.getSmallSteps.government_services[
        "controls"
      ].government_services.setValidators(Validators.required);
      this.getSmallSteps.government_services[
        "controls"
      ].government_services.updateValueAndValidity();
    }
  }
  OPEN_CLOSE_OTHER_COSTS(e) {
    if (e.value == 0) {
      this.getSmallSteps.other_costs["controls"].other_costs.clearValidators();
      this.getSmallSteps.other_costs[
        "controls"
      ].other_costs.updateValueAndValidity();
      this.getSmallSteps.other_costs["controls"].other_costs.disable();
    } else {
      this.getSmallSteps.other_costs["controls"].other_costs.enable();
      this.getSmallSteps.other_costs["controls"].other_costs.setValidators(
        Validators.required
      );
      this.getSmallSteps.other_costs[
        "controls"
      ].other_costs.updateValueAndValidity();
    }
  }

  make_finacial_qout_calcs(e) {
    let financial_managments = this.getSmallSteps
      ?.financial_managments as FormGroup;
    if (e.value) {
      let quotation_prices = this.getSmallSteps?.quotation_price?.controls
        ?.quotation_prices as FormArray;
      let index = quotation_prices.value.findIndex(
        (q) => q.quotation_prices_type == e.value
      );
      let vat_index = this.vat_list.findIndex(
        (vt) => vt.value == this.form.value.stepOne.vat_key
      );

      financial_managments.controls.total_without_vat.setValue(
        +quotation_prices.value[index].quotation_prices_total_amount
      );
      financial_managments.controls.total_vat.setValue(
        +quotation_prices.value[index].quotation_prices_total_amount *
          (+this.vat_list[vat_index].default_value / 100)
      );
      financial_managments.controls.total_all.setValue(
        +quotation_prices.value[index].quotation_prices_total_amount +
          quotation_prices.value[index].quotation_prices_total_amount *
            (+this.vat_list[vat_index].default_value / 100)
      );
    }
  }

  make_finacial_qout_calcs_open(e) {
    let financial_managments = this.getSmallStepsOpen
      ?.financial_managments as FormGroup;
    if (e.value) {
      let quotation_prices = this.getSmallStepsOpen?.quotation_price?.controls
        ?.quotation_prices as FormArray;
      let index = quotation_prices.value.findIndex(
        (q) => q.quotation_prices_type == e.value
      );
      let vat_index = this.vat_list.findIndex(
        (vt) => vt.value == this.form.value.stepOne.vat_key
      );

      financial_managments.controls.total_without_vat.setValue(
        +quotation_prices.value[index].quotation_prices_total_amount
      );
      financial_managments.controls.total_vat.setValue(
        +quotation_prices.value[index].quotation_prices_total_amount *
          (+this.vat_list[vat_index].default_value / 100)
      );
      financial_managments.controls.total_all.setValue(
        +quotation_prices.value[index].quotation_prices_total_amount +
          quotation_prices.value[index].quotation_prices_total_amount *
            (+this.vat_list[vat_index].default_value / 100)
      );
    }
  }

  MATNext(stepper: MatStepper, stepNum?: string) {
    if (stepNum == "first" && this.form.get("stepOne").status === "VALID") {
      stepper.next();
    } else if (
      stepNum == "second" &&
      this.form.get("stepTwo").status === "VALID"
    ) {
      stepper.next();
    } else {
      this.showMsg("lang_fix_invalid_fields", "ok", "error-snackbar");
    }
  }
  MATPrev(stepper: MatStepper) {
    stepper.previous();
  }

  currentActiveStep = 1;

  moveToStep3(num, currentIndex) {
    if (this.lastIndex < currentIndex) {
      if (
        this.getSmallSteps.project_component_soil_sensor.status == "INVALID"
      ) {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }

      this.lastIndex = currentIndex;

      this.currentActiveStep = num;
    }

    this.fillPlanQuantity();
  }
  moveToStep4(num, currentIndex) {
    if (this.lastIndex < currentIndex) {
      if (this.getSmallSteps.project_analysis_data.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }

      this.currentActiveStep = num;
      this.lastIndex = currentIndex;
    }
    this.makeCalculations();
  }
  moveToStep5(num, currentIndex) {
    if (this.lastIndex < currentIndex) {
      if (this.getSmallSteps.surveying_costs.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }

      this.currentActiveStep = num;
      this.lastIndex = currentIndex;
    }
    this.makeBusunessServeyingWholeTotalCalc();
  }
  moveToStep6(num, currentIndex) {
    if (this.lastIndex < currentIndex) {
      if (this.getSmallSteps.printing_costs.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }

      this.lastIndex = currentIndex;
      this.currentActiveStep = num;
    }

    this.printingCostsWholeTotalCalc();
  }
  moveToStep7(num, currentIndex) {
    if (this.lastIndex < currentIndex) {
      if (this.getSmallSteps.engineering_costs.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }

      this.lastIndex = currentIndex;
      this.currentActiveStep = num;
    }

    this.engineeringCostsWholeTotalCalc();
  }
  moveToStep8(num, currentIndex) {
    if (this.lastIndex < currentIndex) {
      if (this.getSmallSteps.government_services.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }
      this.lastIndex = currentIndex;
      this.currentActiveStep = num;
    }

    this.governmentCostsWholeTotalCalc();
  }
  moveToStep9(num, currentIndex) {
    if (this.lastIndex < currentIndex) {
      if (this.getSmallSteps.other_costs.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }
      this.currentActiveStep = num;
      this.lastIndex = currentIndex;
    }
    this.OtherCostsWholeTotalCalc();
  }

  moveToStep10(num, currentIndex) {
    if (this.lastIndex < currentIndex) {
      if (this.getSmallSteps.main_analysis_costs.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }
      this.lastIndex = currentIndex;
      this.currentActiveStep = num;
    }

    this.qoutationCalcs();
  }
  lastIndex = 0;
  setActive(num, currentIndex) {
    if (this.lastIndex < currentIndex) {
      if (this.currentActiveStep == 1) {
        if (this.getSmallSteps.project_plan_requirements.status == "INVALID") {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      } else if (this.currentActiveStep == 2) {
        if (
          this.getSmallSteps.project_component_soil_sensor.status == "INVALID"
        ) {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      } else if (this.currentActiveStep == 3) {
        if (this.getSmallSteps.project_analysis_data.status == "INVALID") {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      } else if (this.currentActiveStep == 4) {
        if (this.getSmallSteps.surveying_costs.status == "INVALID") {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      } else if (this.currentActiveStep == 5) {
        if (this.getSmallSteps.printing_costs.status == "INVALID") {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      } else if (this.currentActiveStep == 6) {
        if (this.getSmallSteps.engineering_costs.status == "INVALID") {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      } else if (this.currentActiveStep == 7) {
        if (this.getSmallSteps.government_services.status == "INVALID") {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      } else if (this.currentActiveStep == 8) {
        if (this.getSmallSteps.other_costs.status == "INVALID") {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      } else if (this.currentActiveStep == 9) {
        if (this.getSmallSteps.main_analysis_costs.status == "INVALID") {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      } else if (this.currentActiveStep == 10) {
        if (this.getSmallSteps.quotation_price.status == "INVALID") {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      } else if (this.currentActiveStep == 11) {
        if (this.getSmallSteps.financial_managments.status == "INVALID") {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      } else if (this.currentActiveStep == 12) {
        if (this.signature_form.status == "INVALID") {
          this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
          return 0;
        }
      }
      this.currentActiveStep = num;
      this.lastIndex = currentIndex;
    }
  }
  next() {
    if (this.currentActiveStep == 1) {
      if (this.getSmallSteps.project_plan_requirements.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }
    } else if (this.currentActiveStep == 2) {
      if (
        this.getSmallSteps.project_component_soil_sensor.status == "INVALID"
      ) {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }
    } else if (this.currentActiveStep == 11) {
      if (this.getSmallSteps.financial_managments.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }
    } else if (this.currentActiveStep == 12) {
      if (this.signature_form.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }
    }
    this.stepper.next();
    if (this.currentActiveStep < 12) {
      this.currentActiveStep++;
      if (this.currentActiveStep == 3) {
        this.moveToStep3(this.currentActiveStep, this.lastIndex++);
      }
      if (this.currentActiveStep == 4) {
        this.moveToStep4(this.currentActiveStep, this.lastIndex++);
      }
      if (this.currentActiveStep == 5) {
        this.moveToStep5(this.currentActiveStep, this.lastIndex++);
      }
      if (this.currentActiveStep == 6) {
        this.moveToStep6(this.currentActiveStep, this.lastIndex++);
      }
      if (this.currentActiveStep == 7) {
        this.moveToStep7(this.currentActiveStep, this.lastIndex++);
      }
      if (this.currentActiveStep == 8) {
        this.moveToStep8(this.currentActiveStep, this.lastIndex++);
      }
      if (this.currentActiveStep == 9) {
        this.moveToStep9(this.currentActiveStep, this.lastIndex++);
      }
      if (this.currentActiveStep == 10) {
        this.moveToStep10(this.currentActiveStep, this.lastIndex++);
      }
      this.lastIndex = this.lastIndex++;
    }
  }

  back() {
    this.stepper.previous();
    if (this.currentActiveStep > 1) {
      this.currentActiveStep--;
    }
  }

  // OPEN STEPS

  currentActiveStepOpen = 1;
  setActiveOpen(num) {
    if (this.currentActiveStepOpen == 2) {
      this.make_finacial_qout_calcs_open({ value: "byhand" });
    }

    this.currentActiveStepOpen = num;
  }
  next_open() {
    if (this.currentActiveStepOpen == 2) {
      if (this.getSmallStepsOpen.quotation_price.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      } else {
        this.make_finacial_qout_calcs_open({ value: "byhand" });
      }
    } else if (this.currentActiveStepOpen == 3) {
      if (this.getSmallStepsOpen.financial_managments.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }
    } else if (this.currentActiveStepOpen == 4) {
      if (this.signature_form.status == "INVALID") {
        this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
        return 0;
      }
    }

    this.stepperOpen.next();
    if (this.currentActiveStepOpen < 4) {
      this.currentActiveStepOpen++;
    }
  }

  back_open() {
    this.stepperOpen.previous();
    if (this.currentActiveStepOpen > 1) {
      this.currentActiveStepOpen--;
    }
  }

  // END OPEN STEPS

  //get languages
  get_languages() {
    this.spinner.show();
    this.languages = [];
    this.ds.getActionByUrl([], "tc/tlanguages").subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.languages = res.records;
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  get getSmallSteps() {
    return this.form.controls.stepTwo.controls.standard_steps.controls;
  }

  get getstandard_steps() {
    return this.form.controls.stepTwo.controls.standard_steps;
  }

  get getSmallStepsOpen() {
    return this.form.controls.stepTwo.controls.open_steps.controls;
  }

  get getstandard_stepsOpen() {
    return this.form.controls.stepTwo.controls.open_steps;
  }

  trackByFnCustom(index: number, item: any) {
    return item.trackingId;
  }
  generateUniqueId() {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }

  deleteTerm(index) {
    const creds = this.form.controls.stepTwo["controls"]
      .technical_term_Arr as FormArray;
    if (this.form.controls.stepTwo["controls"].technical_term_Arr.length == 1) {
      this.showMsg("lang_you_must_keep_one_field", "ok", "error-snackbar");
      return 0;
    }
    creds.removeAt(index);
  }

  deleteGeneralTerm(index) {
    const creds = this.form.controls.stepTwo["controls"]
      .technical_general_terms_Arr as FormArray;
    if (
      this.form.controls.stepTwo["controls"].technical_general_terms_Arr
        .length == 1
    ) {
      this.showMsg("lang_you_must_keep_one_field", "ok", "error-snackbar");
      return 0;
    }
    creds.removeAt(index);
  }
  deletePaln(index: number) {
    if (
      index <
      this.getSmallSteps.project_plan_requirements?.value.length -
        this.plans.length
    ) {
      var project_plan_requirements = this.getSmallSteps
        .project_plan_requirements as FormArray;

      project_plan_requirements.removeAt(index);
    }
  }

  deleteEngineeringCosts(index: number) {
    if (index > 0) {
      var project_plan_requirements = this.getSmallSteps
        .engineering_costs as FormArray;

      project_plan_requirements.removeAt(index);
    }
  }

  deleteServayingCost(index: number) {
    if (index < 0) {
      var surveying_costs = this.getSmallSteps.surveying_costs["controls"]
        .surveying_costs as FormArray;
      surveying_costs.removeAt(index);
    }
  }

  deleteGovCosts(index: number) {
    if (index > 0) {
      var government_services = this.getSmallSteps.government_services[
        "controls"
      ].government_services as FormArray;
      government_services.removeAt(index);
    }
  }

  deleteOtherCosts(index: number) {
    if (index > 0) {
      var other_costs = this.getSmallSteps.other_costs["controls"]
        .other_costs as FormArray;
      other_costs.removeAt(index);
    }
  }

  deleteFinancialPayments(index: number) {
    if (index > 0) {
      var financial_managments = this.getSmallSteps.financial_managments[
        "controls"
      ].financial_payments as FormArray;
      financial_managments.removeAt(index);
      this.validatePaymentLevel(
        this.getstandard_steps.controls["financial_managments"]["controls"]
          .financial_payments
      );
    }
  }

  deleteFinancialPaymentsOpen(index: number) {
    if (index > 0) {
      var financial_managments = this.getSmallStepsOpen.financial_managments[
        "controls"
      ].financial_payments as FormArray;
      financial_managments.removeAt(index);
      this.validatePaymentLevel(
        this.getstandard_stepsOpen.controls["financial_managments"]["controls"]
          .financial_payments
      );
    }
  }

  deletePrintingCost(index: number) {
    if (index > 0) {
      var printing_costs = this.getSmallSteps.printing_costs["controls"]
        .printing_costs as FormArray;
      printing_costs.removeAt(index);
    }
  }

  deleteProjectAnalysis(index: number) {
    var project_analysis_data = this.getSmallSteps
      .project_analysis_data as FormArray;
    if (project_analysis_data.value.length > 1) {
      project_analysis_data.removeAt(index);
    }
  }
  toggleValue(control: FormControl) {
    control.setValue(!control.value);
  }
  //get required data
  get_required_data(request_type?, return_type?) {
    let param = new FormData();
    param.append("lang_key", this.lang_key);
    param.append("request_type", request_type);
    if (return_type) {
      param.append("return_type", return_type);
    }

    this.spinner.show();
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "form/FORM_PQ2/requests")
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            if (request_type == "qtype") {
              this.quotation_type_list = res.data;
            }
            if (request_type == "vat") {
              this.vat_list = res.data;
            }
            if (request_type == "occupy") {
              this.occupancies_list = res.data;
            }
            if (request_type == "plans") {
              this.plans = res.data;

              var project_plan_requirements = this.getSmallSteps
                .project_plan_requirements as FormArray;
              for (let index = 0; index < this.plans.length; index++) {
                const element = this.plans[index];
                project_plan_requirements.push(
                  this.fb.group({
                    plan_title_english: [
                      {
                        value: element.form_pq2_request_type_title_english,
                        disabled: true,
                      },
                      Validators.required,
                    ],
                    plan_title_arabic: [
                      {
                        value: element.form_pq2_request_type_title_arabic,
                        disabled: true,
                      },
                      Validators.required,
                    ],
                    plan_keyword: [
                      {
                        value: element.form_pq2_request_type_keyword,
                        disabled: true,
                      },
                      Validators.required,
                    ],
                    plan_technical_ids: [null, Validators.required],
                    plan_hour_value: [
                      {
                        value: element.form_pq2_request_type_value,
                        disabled: true,
                      },
                      Validators.required,
                    ],
                    plan_total_hours: ["", Validators.required],
                    trackingId: [this.generateUniqueId()],
                  })
                );
              }
            }

            if (request_type == "planreq") {
              this.planreq = res.data;
            }
            // if(request_type == "qprice") {
            //   this.qprice = res.records;
            // }
            if (request_type == "stages") {
              this.stages = res.data.map((s) => {
                return {
                  ...s,
                  disabled: false,
                };
              });
            }
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  addProjectAnalysis() {
    var project_analysis_data = this.getSmallSteps
      .project_analysis_data as FormArray;
    project_analysis_data.insert(
      0,
      this.fb.group({
        project_component_subject_english: [null, Validators.required],
        project_component_subject_arabic: [null, Validators.required],
        project_component_space: [null, Validators.required],
        project_component_building_quantity: [null, Validators.required],
        project_component_level_quantity: [
          1,
          [Validators.required, Validators.min(1)],
        ],

        project_plan_requirements: this.fb.array([
          this.fb.group({
            levels_total: [null, Validators.required],
            collabsed: [false],
            trackingId: [this.generateUniqueId()],
            levels: this.fb.array([
              this.fb.group({
                level_value: [null, Validators.required],
                level_occupancy: [null, Validators.required],
                trackingId: [this.generateUniqueId()],
              }),
            ]),
          }),
        ]),
        project_component_soil_sensor_building_quantity: [
          { value: "", disabled: true },
          this.validationOnSoil == "1" ? [Validators.required] : [],
        ],
        project_component_soil_sensor_depth_level_quantity: [
          { value: "", disabled: true },
          this.validationOnSoil == "1" ? [Validators.required] : [],
        ],
        project_component_soil_sensor_cost_per_lmeter: [
          { value: "", disabled: this.validationOnSoil == "1" ? false : true },
          this.validationOnSoil == "1" ? [Validators.required] : [],
        ],

        plan_quantities: this.fb.array([]),
      })
    );
    let project_plan_requirements = this.getstandard_steps.controls
      .project_plan_requirements as FormArray;

    for (let p = 0; p < project_plan_requirements.controls.length; p++) {
      const plan_req = project_plan_requirements.controls[p] as FormGroup;

      this.add_plan_quantities_forArr(0, plan_req.controls.plan_keyword.value);
    }
  }

  deleteLevel(i, index) {
    var project_analysis_data_levels = this.getSmallSteps.project_analysis_data[
      "controls"
    ][i]["controls"]["project_plan_requirements"] as FormArray;
    if (project_analysis_data_levels.length == 1) {
      this.showMsg("lang_you_must_keep_one_field", "ok", "error-snackbar");
      return 0;
    }
    project_analysis_data_levels.removeAt(index);
  }

  delete_plan_quantities(i, index) {
    var plan_quantities = this.getSmallSteps.project_analysis_data["controls"][
      i
    ]["controls"]["plan_quantities"] as FormArray;
    if (plan_quantities.length == 1) {
      this.showMsg("lang_you_must_keep_one_field", "ok", "error-snackbar");
      return 0;
    }
    plan_quantities.removeAt(index);
  }

  deleteSUbLevel(i, ii, index) {
    var project_analysis_data_levels = this.getSmallSteps.project_analysis_data[
      "controls"
    ][i]["controls"]["project_plan_requirements"]["controls"][ii]["controls"][
      "levels"
    ] as FormArray;
    if (project_analysis_data_levels.length == 1) {
      this.showMsg("lang_you_must_keep_one_field", "ok", "error-snackbar");
      return 0;
    }
    project_analysis_data_levels.removeAt(index);
  }

  addLevels(i, ii) {
    var project_analysis_data_levels = this.getSmallSteps.project_analysis_data[
      "controls"
    ][i]["controls"]["project_plan_requirements"]["controls"][ii]["controls"][
      "levels"
    ] as FormArray;
    project_analysis_data_levels.insert(
      0,
      this.fb.group({
        level_value: [null, Validators.required],
        level_occupancy: [null, Validators.required],
        trackingId: [this.generateUniqueId()],
      })
    );
  }

  add_plan_quantities(i) {
    var plan_quantities = this.getSmallSteps.project_analysis_data["controls"][
      i
    ]["controls"]["plan_quantities"] as FormArray;
    plan_quantities.insert(
      0,
      this.fb.group({
        plan_quantity_count: ["", [Validators.required]],
        plan_quantity_total_val: ["", []],
        plan_keyword: ["", [Validators.required]],
        trackingId: [this.generateUniqueId()],
      })
    );
  }

  add_plan_quantities_forArr(i, plan_keyword) {
    var plan_quantities = this.getSmallSteps.project_analysis_data["controls"][
      i
    ]["controls"]["plan_quantities"] as FormArray;
    plan_quantities.push(
      this.fb.group({
        plan_quantity_count: ["", [Validators.required]],
        plan_quantity_total_val: ["", []],
        plan_keyword: [
          { value: plan_keyword, disabled: true },
          [Validators.required],
        ],
        trackingId: [this.generateUniqueId()],
      })
    );
  }

  fillPlanQuantity() {
    let project_analysis_data = this.getstandard_steps.controls
      .project_analysis_data as FormArray;
    let project_plan_requirements = this.getstandard_steps.controls
      .project_plan_requirements as FormArray;

    for (let t = 0; t < project_analysis_data.controls.length; t++) {
      const proj_an_data = project_analysis_data.controls[t];

      for (
        let index = 0;
        index < project_plan_requirements.value.length;
        index++
      ) {
        const plan_req = project_plan_requirements.controls[index] as FormGroup;

        let currentIndex = proj_an_data["controls"][
          "plan_quantities"
        ].controls.findIndex(
          (i) =>
            i["controls"].plan_keyword.value ==
            plan_req.controls.plan_keyword.value
        );
        if (currentIndex == -1) {
          this.add_plan_quantities_forArr(
            t,
            plan_req.controls.plan_keyword.value
          );
        }
      }
    }

    let oldData = [];
    for (let t = 0; t < project_analysis_data.controls.length; t++) {
      const proj_an_data = project_analysis_data.controls[t];

      for (
        let index = 0;
        index < proj_an_data["controls"]["plan_quantities"].controls.length;
        index++
      ) {
        const plan_quentity = proj_an_data["controls"]["plan_quantities"]
          .controls[index] as FormGroup;

        let currentIndex = project_plan_requirements.controls.findIndex(
          (i) =>
            i["controls"].plan_keyword.value ==
            plan_quentity.controls.plan_keyword.value
        );
        if (currentIndex == -1) {
          oldData.push(plan_quentity.controls.plan_keyword.value);
        }
      }
    }

    for (let index = 0; index < oldData.length; index++) {
      const element = oldData[index];

      for (let t = 0; t < project_analysis_data.controls.length; t++) {
        const proj_an_data = project_analysis_data.controls[t];

        for (
          let q = 0;
          q < proj_an_data["controls"]["plan_quantities"].length;
          q++
        ) {
          const plan_quentity = proj_an_data["controls"]["plan_quantities"]
            .controls[q] as FormGroup;

          if (plan_quentity.controls.plan_keyword.value == element) {
            let arr = proj_an_data["controls"]["plan_quantities"] as FormArray;
            arr.removeAt(q);
          }
        }
      }
    }
  }

  addPlan() {
    var project_plan_requirements = this.getSmallSteps
      .project_plan_requirements as FormArray;
    project_plan_requirements.insert(
      0,
      this.fb.group({
        plan_title_english: [
          { value: "", disabled: false },
          Validators.required,
        ],
        plan_title_arabic: [
          { value: "", disabled: false },
          Validators.required,
        ],
        plan_keyword: [{ value: "", disabled: false }, Validators.required],
        plan_technical_ids: [null, Validators.required],
        plan_hour_value: [{ value: "", disabled: false }, Validators.required],
        plan_total_hours: ["", Validators.required],
        trackingId: [this.generateUniqueId()],
      })
    );
  }

  addEngineeringCosts() {
    var engineering_costs = this.getSmallSteps.engineering_costs as FormArray;
    engineering_costs.insert(
      0,
      this.fb.group({
        engineering_cost_title_english: [null, Validators.required],
        engineering_cost_title_arabic: [null, Validators.required],
        plan_keyword: [null, Validators.required],
        quantity: [null, Validators.required],
        cost_per_day: [null, Validators.required],
        trackingId: [this.generateUniqueId()],
      })
    );
  }

  addSurveyingCosts() {
    var surveying_costs = this.getSmallSteps.surveying_costs["controls"]
      .surveying_costs as FormArray;
    surveying_costs.insert(
      0,
      this.fb.group({
        surveying_title_english: ["", [Validators.required]],
        surveying_title_arabic: ["", [Validators.required]],
        surveying_cost_value: ["", [Validators.required]],
        surveying_cost_total_value: ["", [Validators.required]],
        trackingId: [this.generateUniqueId()],
      })
    );
  }

  addPrintingCosts() {
    var printing_costs = this.getSmallSteps.printing_costs["controls"]
      .printing_costs as FormArray;
    printing_costs.insert(
      0,
      this.fb.group({
        printing_title_english: ["", [Validators.required]],
        printing_title_arabic: ["", [Validators.required]],
        printing_costs_no_of_copies: ["", [Validators.required]],
        printing_costs_no_of_panels: ["", [Validators.required]],
        printing_costs_ratio_cost: ["", [Validators.required]],
        trackingId: [this.generateUniqueId()],
      })
    );
  }
  addGovernmentServices() {
    var government_services = this.getSmallSteps.government_services["controls"]
      .government_services as FormArray;
    government_services.insert(
      0,
      this.fb.group({
        government_services_subject_english: ["", [Validators.required]],
        government_services_subject_arabic: ["", [Validators.required]],
        government_services_quantity: ["", [Validators.required]],
        government_services_ratio_cost: ["", [Validators.required]],
        trackingId: [this.generateUniqueId()],
      })
    );
  }
  addOtherCosts() {
    var other_costs = this.getSmallSteps.other_costs["controls"]
      .other_costs as FormArray;
    other_costs.insert(
      0,
      this.fb.group({
        other_costs_subject_english: ["", [Validators.required]],
        other_costs_subject_arabic: ["", [Validators.required]],
        other_costs_quantity: ["", [Validators.required]],
        other_costs_ratio_cost: ["", [Validators.required]],
        trackingId: [this.generateUniqueId()],
      })
    );
  }

  addFinancialManagments() {
    var financial_payments = this.getSmallSteps.financial_managments["controls"]
      .financial_payments as FormArray;
    financial_payments.insert(
      0,
      this.fb.group({
        payment_title_english: ["", [Validators.required]],
        payment_title_arabic: ["", [Validators.required]],
        payment_stage: ["", [Validators.required]],
        payment_stage_level: ["", [Validators.required]],

        trackingId: [this.generateUniqueId()],
      })
    );
  }

  addFinancialManagmentsOpen() {
    var financial_payments = this.getSmallStepsOpen.financial_managments[
      "controls"
    ].financial_payments as FormArray;
    financial_payments.insert(
      0,
      this.fb.group({
        payment_title_english: ["", [Validators.required]],
        payment_title_arabic: ["", [Validators.required]],
        payment_stage: ["", [Validators.required]],
        payment_stage_level: ["", [Validators.required]],

        trackingId: [this.generateUniqueId()],
      })
    );
  }

  makekeyword(event, index) {
    let value: string = event.target.value.toLowerCase();
    value = value.replace(/\s+/g, "_").toLowerCase();
    var project_plan_requirements = this.getSmallSteps
      .project_plan_requirements as FormArray;
    project_plan_requirements["controls"][index].patchValue({
      plan_keyword: value,
    });
  }

  makekeywordForEngineeringCosts(event, index) {
    let value: string = event.target.value.toLowerCase();
    value = value.replace(/\s+/g, "_").toLowerCase();
    var engineering_costs = this.getSmallSteps.engineering_costs as FormArray;
    engineering_costs["controls"][index].patchValue({
      plan_keyword: value,
    });
  }

  remove(el: any): void {
    this.getSmallSteps.P_Q_P_L.patchValue({
      arch_list: this.getSmallSteps.P_Q_P_L.get("arch_list").value.filter(
        (d) => d != el
      ),
    });
  }

  public files(): FormGroup {
    return this.fb.group({
      attach_title: new FormControl(
        "",
        this.signature_form.get("needAttach").value == "1"
          ? [Validators.required]
          : null
      ),
      file: new FormControl(
        "",
        this.signature_form.get("needAttach").value == "1"
          ? [Validators.required]
          : null
      ),
      attach_with_the_document: new FormControl(0),
      print_official_paper: new FormControl(0),
    });
  }
  public addfiles() {
    if (this.attachments.length < 5) {
      this.attachments.push(this.files());
    }
  }
  public removefiles(index) {
    if (this.attachments.length > 1) {
      this.attachments.removeAt(index);
    }
  }
  public getfilesgroup(index): FormGroup {
    const formGroup = this.attachments.controls[index] as FormGroup;
    return formGroup;
  }
  get filesFormGroup() {
    return this.signature_form.get("attachments") as FormArray;
  }
  public UploadFile(event, index) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      $("#uploadText" + index).text(fileid.name);
      this.attachments.controls[index]
        .get("file")
        .setValue(fileid, { emitEvent: true });
    } else {
      this.attachments.controls[index].get("file").setValue(null);
      $("#uploadText" + index).text(this.lang.transform("lang_select_files"));
    }
  }
  public uploadFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      this.isFile = true;
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.signature_form.get("upload").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.signature_form.get("upload").setValue(null);
      this.filename = this.lang.transform("lang_select_files");
    }
  }
  public removeFile() {
    this.isFile = false;
    this.signature_form.get("upload").setValue(null);
    this.filename = this.lang.transform("lang_select_files");
  }
  public enable_field(field) {
    if (field == "mark") {
      this.signature_form.get("needMark").setValue("1");
      this.signature_form.get("marks").setValidators(Validators.required);
      this.signature_form.get("marks").updateValueAndValidity();
      this.markstatus = true;
    } else if (field == "review") {
      this.signature_form.get("needReview").setValue("1");
      this.signature_form.get("reviews").setValidators(Validators.required);
      this.signature_form.get("reviews").updateValueAndValidity();
      this.reviewstatus = true;
    } else if (field == "stamp") {
      this.signature_form.get("needStamp").setValue("1");
      this.signature_form.get("stamp").setValidators(Validators.required);
      this.signature_form.get("stamp").updateValueAndValidity();
      this.stampstatus = true;
    } else if (field == "attachment") {
      this.attachments.clear();
      this.signature_form.get("needAttach").setValue("1");
      this.addfiles();
      this.attachstatus = true;
    } else if (field == "viewer") {
      this.signature_form.get("needViewers").setValue("1");
      this.signature_form.get("viewers").setValidators(Validators.required);
      this.signature_form.get("viewers").updateValueAndValidity();
      this.viewerstatus = true;
    } else if (field == "others") {
      this.signature_form.get("otherTerms").setValue("1");
      this.others_fields.clear();
      this.add_new_others();
      this.othersstatus = true;
    }
  }
  public disable_field(field) {
    if (field == "mark") {
      this.signature_form.get("needMark").setValue("0");
      this.signature_form.get("marks").setValue(null);
      this.signature_form.get("marks").clearValidators();
      this.signature_form.get("marks").updateValueAndValidity();
      this.markstatus = false;
    } else if (field == "review") {
      this.signature_form.get("needReview").setValue("0");
      this.signature_form.get("reviews").setValue(null);
      this.signature_form.get("reviews").clearValidators();
      this.signature_form.get("reviews").updateValueAndValidity();
      this.reviewstatus = false;
    } else if (field == "stamp") {
      this.signature_form.get("needStamp").setValue("0");
      this.signature_form.get("stamp").setValue(null);
      this.signature_form.get("stamp").clearValidators();
      this.signature_form.get("stamp").updateValueAndValidity();
      this.stampstatus = false;
    } else if (field == "attachment") {
      this.attachments.clear();
      this.signature_form.get("needAttach").setValue("0");
      this.isFile = false;
      this.attachstatus = false;
    } else if (field == "viewer") {
      this.signature_form.get("needViewers").setValue("0");
      this.signature_form.get("viewers").setValue(null);
      this.signature_form.get("viewers").clearValidators();
      this.signature_form.get("viewers").updateValueAndValidity();
      this.viewerstatus = false;
    } else if (field == "others") {
      this.others_fields.clear();
      this.signature_form.get("otherTerms").setValue("0");
      this.othersstatus = false;
    }
    this.signature_form.updateValueAndValidity();
  }

  public others_group() {
    return this.signature_form.get("others") as FormArray;
  }
  get othersgroup() {
    return this.signature_form.get("others") as FormArray;
  }
  public add_new_others() {
    this.others_fields.push(this.other_records());
  }
  public remove_other_items(index) {
    if (this.others_fields.length > 1) {
      this.others_fields.removeAt(index);
    }
  }
  public other_records(): FormGroup {
    return this.fb.group({
      qror_name_en: new FormControl("", [Validators.required]),
      qror_name_ar: new FormControl("", [Validators.required]),
    });
  }

  dynamicValidationOnInput(getter: any) {
    this.stages.forEach((element: any, i) => {
      this.stages[i].disabled = false;
    });
    for (
      let i = 0;
      i <
      getter.controls["financial_managments"]["controls"].financial_payments[
        "controls"
      ].length;
      i++
    ) {
      const element =
        getter.controls["financial_managments"]["controls"].financial_payments[
          "controls"
        ][i]["controls"]?.payment_stage.value;

      let index = this.stages.findIndex((i) => i.value == element);
      if (index > -1) {
        this.stages[index].disabled = true;
      }
    }
  }

  validatePaymentLevel(formArray: FormArray) {
    let total = 0;
    for (let index = 0; index < formArray.value.length; index++) {
      const element = formArray.value[index];
      total += element.payment_stage_level;
    }

    if (total != 100) {
      for (let index = 0; index < formArray.value.length; index++) {
        const element = formArray.controls[index] as FormGroup;
        element.controls.payment_stage_level.setErrors({
          MustBy_100: true,
        });
      }
    } else {
      for (let index = 0; index < formArray.value.length; index++) {
        const element = formArray.controls[index] as FormGroup;
        element.controls.payment_stage_level.setErrors(null);
      }
    }
  }

  submitForm() {
    this.submitted = true;
    if (this.form.valid && this.signature_form.valid) {
      let formData = new FormData();

      formData.append("projects_ps_id", this.service_data.projects_ps_id);
      formData.append(
        "projects_profile_id",
        this.project_data.projects_profile_id
      );
      formData.append("lang_key", this.form.value.stepOne.lang_key);
      formData.append("quotation_type", this.form.value.stepOne.quotation_type);
      formData.append("vat_key", this.form.value.stepOne.vat_key);
      formData.append("subject", this.form.value.stepOne.subject);
      formData.append("care", this.form.value.stepOne.care);
      formData.append("city", this.form.value.stepOne.city);
      formData.append("total_area_m2", this.form.value.stepOne.total_area_m2);
      formData.append(
        "main_occupancies",
        this.form.value.stepOne.main_occupancies
      );
      formData.append("description", this.form.value.stepOne.description);
      formData.append("duration_days", this.form.value.stepOne.duration_days);

      if (this.form.value.stepOne.quotation_type == "open") {
        let quotation_price = this.getSmallStepsOpen.quotation_price.controls
          .quotation_prices as FormArray;

        formData.append(
          "price_amount_byhand",
          quotation_price.value[0].quotation_prices_profit_percentage
        );

        // Step 3 , 2
        formData.append(
          "financial_quot_price_type",
          this.getSmallStepsOpen.financial_managments.controls
            .financial_quot_price_type.value
        );
        for (
          let index = 0;
          index <
          this.getSmallStepsOpen.financial_managments.controls
            .financial_payments.controls.length;
          index++
        ) {
          const element =
            this.getSmallStepsOpen.financial_managments.controls
              .financial_payments.controls[index].controls;
          formData.append(
            `financial_payments[${index}][payment_title_english]`,
            element.payment_title_english.value
          );
          formData.append(
            `financial_payments[${index}][payment_title_arabic]`,
            element.payment_title_arabic.value
          );
          formData.append(
            `financial_payments[${index}][payment_stage]`,
            element.payment_stage.value
          );
          formData.append(
            `financial_payments[${index}][payment_stage_level]`,
            element.payment_stage_level.value
          );
        }

        // Step 4

        formData.set("needMark", this.signature_form.get("needMark").value);
        formData.set("needReview", this.signature_form.get("needReview").value);
        formData.set("needStamp", this.signature_form.get("needStamp").value);
        formData.set(
          "needViewers",
          this.signature_form.get("needViewers").value
        );
        formData.set("needAttach", this.signature_form.get("needAttach").value);
        formData.set("otherTerms", this.signature_form.get("otherTerms").value);
        formData.set("needMark", this.signature_form.get("needMark").value);
        formData.set(
          "signature[A][title]",
          this.signature_form.get("signature[A][title]").value
        );
        formData.set(
          "signature[A][user_id]",
          this.signature_form.get("signature[A][user_id]").value || ""
        );
        formData.set(
          "signature[B][title]",
          this.signature_form.get("signature[B][title]").value
        );
        formData.set(
          "signature[B][user_id]",
          this.signature_form.get("signature[B][user_id]").value || ""
        );
        formData.set(
          "signature[C][title]",
          this.signature_form.get("signature[C][title]").value
        );
        formData.set(
          "signature[C][user_id]",
          this.signature_form.get("signature[C][user_id]").value || ""
        );

        formData.set(
          "marks",
          this.signature_form.get("marks").value != null ||
            this.signature_form.get("marks").value != undefined
            ? this.signature_form.get("marks").value.toString()
            : ""
        );
        formData.set(
          "reviews",
          this.signature_form.get("reviews").value != null ||
            this.signature_form.get("reviews").value != undefined
            ? this.signature_form.get("reviews").value.toString()
            : ""
        );
        formData.set(
          "viewers",
          this.signature_form.get("viewers").value != null ||
            this.signature_form.get("viewers").value != undefined
            ? this.signature_form.get("viewers").value.toString()
            : ""
        );
        if (this.signature_form.get("needAttach").value == "1") {
          this.filesFormGroup.value.forEach((v, k) => {
            formData.set("attachments[" + k + "][file]", v.file);
            formData.set(
              "attachments[" + k + "][attach_title]",
              v.attach_title
            );
            formData.set(
              "attachments[" + k + "][attach_with_the_document]",
              v.attach_with_the_document ? "1" : "0"
            );
            formData.set(
              "attachments[" + k + "][print_official_paper]",
              v.print_official_paper ? "1" : "0"
            );
          });
        }
        if (this.signature_form.get("otherTerms").value == "1") {
          this.othersgroup.value.forEach((v, k) => {
            formData.set(
              "other_terms[" + k + "][terms_description_english]",
              v.qror_name_en
            );
            formData.set(
              "other_terms[" + k + "][terms_description_arabic]",
              v.qror_name_ar
            );
          });
        }
        if (this.restrictions_fixed_optional_items.length > 0) {
          var t = this.restrictions_fixed_optional_items
            .filter((opt) => opt.checked)
            .map((opt) => opt.value);
          let val = "";
          for (let index = 0; index < t.length; index++) {
            const element = t[index];
            if (index == t.length - 1) {
              val += `${element}`;
            } else {
              val += `${element},`;
            }
          }
          if (t.length > 0) {
            formData.append("restrictions_fixed_optional", val);
          }
        }
      } else {
        // Step 1
        for (
          let index = 0;
          index < this.getSmallSteps.project_plan_requirements.controls.length;
          index++
        ) {
          const element =
            this.getSmallSteps.project_plan_requirements.controls[index];
          formData.append(
            `project_plan_requirements[${index}][plan_title_english]`,
            element.controls.plan_title_english.value
          );
          formData.append(
            `project_plan_requirements[${index}][plan_title_arabic]`,
            element.controls.plan_title_arabic.value
          );
          formData.append(
            `project_plan_requirements[${index}][plan_keyword]`,
            element.controls.plan_keyword.value
          );
          formData.append(
            `project_plan_requirements[${index}][plan_technical_ids]`,
            element.controls.plan_technical_ids.value
          );
          formData.append(
            `project_plan_requirements[${index}][plan_hour_value]`,
            element.controls.plan_hour_value.value
          );
          formData.append(
            `project_plan_requirements[${index}][plan_total_hours]`,
            element.controls.plan_total_hours.value
          );
        }

        // Step 2
        formData.append(
          `project_component_soil_sensor_status`,
          this.getSmallSteps.project_component_soil_sensor.controls
            .project_component_soil_sensor_status.value
        );
        if (
          this.getSmallSteps.project_component_soil_sensor.controls
            .project_component_soil_sensor_status.value == 0
        ) {
          formData.append(
            `project_component_soil_sensor_legal_notes`,
            this.getSmallSteps.project_component_soil_sensor.controls
              .project_component_soil_sensor_legal_notes.value
          );
        }

        // Step 3
        for (
          let index = 0;
          index < this.getSmallSteps.project_analysis_data.controls.length;
          index++
        ) {
          const element =
            this.getSmallSteps.project_analysis_data.controls[index];
          formData.append(
            `project_analysis_data[${index}][project_component_subject_english]`,
            element.controls.project_component_subject_english.value
          );
          formData.append(
            `project_analysis_data[${index}][project_component_subject_arabic]`,
            element.controls.project_component_subject_arabic.value
          );
          formData.append(
            `project_analysis_data[${index}][project_component_space]`,
            element.controls.project_component_space.value
          );
          formData.append(
            `project_analysis_data[${index}][project_component_building_quantity]`,
            element.controls.project_component_building_quantity.value
          );
          formData.append(
            `project_analysis_data[${index}][project_component_level_quantity]`,
            element.controls.project_component_level_quantity.value
          );

          if (
            this.getSmallSteps.project_component_soil_sensor.controls
              .project_component_soil_sensor_status.value == 1
          ) {
            formData.append(
              `project_analysis_data[${index}][project_component_soil_sensor_building_quantity]`,
              element.controls.project_component_soil_sensor_building_quantity
                .value
            );
            formData.append(
              `project_analysis_data[${index}][project_component_soil_sensor_depth_level_quantity]`,
              element.controls
                .project_component_soil_sensor_depth_level_quantity.value
            );
            formData.append(
              `project_analysis_data[${index}][project_component_soil_sensor_cost_per_lmeter]`,
              element.controls.project_component_soil_sensor_cost_per_lmeter
                .value
            );
          }

          for (
            let i = 0;
            i < element.value.project_plan_requirements.length;
            i++
          ) {
            const e = element.value.project_plan_requirements[i];
            formData.append(
              `project_analysis_data[${index}][project_component_level][${i}][levels_total]`,
              e.levels_total
            );

            for (let l = 0; l < e.levels.length; l++) {
              const e_l = e.levels[l];
              formData.append(
                `project_analysis_data[${index}][project_component_level][${i}][levels][${l}][level_value]`,
                e_l.level_value
              );
              formData.append(
                `project_analysis_data[${index}][project_component_level][${i}][levels][${l}][level_occupancy]`,
                e_l.level_occupancy
              );
            }
          }

          for (
            let i = 0;
            i < element.controls.plan_quantities.value.length;
            i++
          ) {
            const e = element.controls.plan_quantities.value[i];
            let plan_keyword =
              element.controls.plan_quantities.controls[i].controls.plan_keyword
                .value;
            formData.append(
              `project_analysis_data[${index}][plan_quantities][${i}][plan_keyword]`,
              plan_keyword
            );
            formData.append(
              `project_analysis_data[${index}][plan_quantities][${i}][plan_quantity_count]`,
              e.plan_quantity_count
            );
          }
        }

        // Step 4

        formData.append(
          "surveying_business_costs_status",
          this.getSmallSteps.surveying_costs.controls
            .surveying_business_costs_status.value
        );
        if (
          this.getSmallSteps.surveying_costs.controls
            .surveying_business_costs_status.value == 0
        ) {
          formData.append(
            `surveying_business_costs_legal_notes`,
            this.getSmallSteps.surveying_costs.controls
              .surveying_business_costs_legal_notes.value
          );
        } else {
          for (
            let index = 0;
            index <
            this.getSmallSteps.surveying_costs.controls.surveying_costs.controls
              .length;
            index++
          ) {
            const element =
              this.getSmallSteps.surveying_costs.controls.surveying_costs
                .controls[index];
            formData.append(
              `surveying_costs[${index}][surveying_title_english]`,
              element.controls.surveying_title_english.value
            );
            formData.append(
              `surveying_costs[${index}][surveying_title_arabic]`,
              element.controls.surveying_title_arabic.value
            );
            formData.append(
              `surveying_costs[${index}][surveying_cost_value]`,
              element.controls.surveying_cost_value.value
            );
            formData.append(
              `surveying_costs[${index}][surveying_cost_total_value]`,
              element.controls.surveying_cost_total_value.value
            );
          }
        }

        // Step 5

        formData.append(
          "printing_costs_status",
          this.getSmallSteps.printing_costs.controls.printing_costs_status.value
        );
        if (
          this.getSmallSteps.printing_costs.controls.printing_costs_status
            .value == 0
        ) {
          formData.append(
            `printing_costs_legal_notes`,
            this.getSmallSteps.printing_costs.controls
              .printing_costs_legal_notes.value
          );
        } else {
          for (
            let index = 0;
            index <
            this.getSmallSteps.printing_costs.controls.printing_costs.controls
              .length;
            index++
          ) {
            const element =
              this.getSmallSteps.printing_costs.controls.printing_costs
                .controls[index];
            formData.append(
              `printing_costs[${index}][printing_title_english]`,
              element.controls.printing_title_english.value
            );
            formData.append(
              `printing_costs[${index}][printing_title_arabic]`,
              element.controls.printing_title_arabic.value
            );
            formData.append(
              `printing_costs[${index}][printing_costs_no_of_copies]`,
              element.controls.printing_costs_no_of_copies.value
            );
            formData.append(
              `printing_costs[${index}][printing_costs_no_of_panels]`,
              element.controls.printing_costs_no_of_panels.value
            );
            formData.append(
              `printing_costs[${index}][printing_costs_ratio_cost]`,
              element.controls.printing_costs_ratio_cost.value
            );
          }
        }

        // Step 6

        for (
          let index = 0;
          index < this.getSmallSteps.engineering_costs.controls.length;
          index++
        ) {
          const element = this.getSmallSteps.engineering_costs.controls[index];
          formData.append(
            `engineering_costs[${index}][engineering_cost_title_english]`,
            element.controls.engineering_cost_title_english.value
          );
          formData.append(
            `engineering_costs[${index}][engineering_cost_title_arabic]`,
            element.controls.engineering_cost_title_arabic.value
          );
          formData.append(
            `engineering_costs[${index}][plan_keyword]`,
            element.controls.plan_keyword.value
          );
          formData.append(
            `engineering_costs[${index}][quantity]`,
            element.controls.quantity.value
          );
          formData.append(
            `engineering_costs[${index}][cost_per_day]`,
            element.controls.cost_per_day.value
          );
        }

        // Step 7

        formData.append(
          "government_services_status",
          this.getSmallSteps.government_services.controls
            .government_services_status.value
        );
        if (
          this.getSmallSteps.government_services.controls
            .government_services_status.value == 1
        ) {
          for (
            let index = 0;
            index <
            this.getSmallSteps.government_services.controls.government_services
              .controls.length;
            index++
          ) {
            const element =
              this.getSmallSteps.government_services.controls
                .government_services.controls[index];
            formData.append(
              `government_services[${index}][government_services_subject_english]`,
              element.controls.government_services_subject_english.value
            );
            formData.append(
              `government_services[${index}][government_services_subject_arabic]`,
              element.controls.government_services_subject_arabic.value
            );
            formData.append(
              `government_services[${index}][government_services_quantity]`,
              element.controls.government_services_quantity.value
            );
            formData.append(
              `government_services[${index}][government_services_ratio_cost]`,
              element.controls.government_services_ratio_cost.value
            );
          }
        }

        // Step 8

        formData.append(
          "other_costs_status",
          this.getSmallSteps.other_costs.controls.other_costs_status.value
        );
        if (
          this.getSmallSteps.other_costs.controls.other_costs_status.value == 1
        ) {
          for (
            let index = 0;
            index <
            this.getSmallSteps.other_costs.controls.other_costs.controls.length;
            index++
          ) {
            const element =
              this.getSmallSteps.other_costs.controls.other_costs.controls[
                index
              ];
            formData.append(
              `other_costs[${index}][other_costs_subject_english]`,
              element.controls.other_costs_subject_english.value
            );
            formData.append(
              `other_costs[${index}][other_costs_subject_arabic]`,
              element.controls.other_costs_subject_arabic.value
            );
            formData.append(
              `other_costs[${index}][other_costs_quantity]`,
              element.controls.other_costs_quantity.value
            );
            formData.append(
              `other_costs[${index}][other_costs_ratio_cost]`,
              element.controls.other_costs_ratio_cost.value
            );
          }
        }

        this.form.controls.stepTwo.controls.standard_steps.controls
          .quotation_price.controls.quotation_prices as FormArray;

        let quotation_price = this.getSmallSteps.quotation_price.controls
          .quotation_prices as FormArray;

        // IF U don't understand got to qoutationCalcs()
        formData.append(
          "price_amount_byhand",
          quotation_price.value[0].quotation_prices_profit_percentage
        );
        formData.append(
          "profit_percent_byarea",
          quotation_price.value[1].quotation_prices_profit_percentage
        );
        formData.append(
          "profit_percent_byplan",
          quotation_price.value[2].quotation_prices_profit_percentage
        );
        formData.append(
          "profit_percent_bytotalcost",
          quotation_price.value[3].quotation_prices_profit_percentage
        );
        formData.append(
          "profit_percent_byhours",
          quotation_price.value[4].quotation_prices_profit_percentage
        );

        // Step 11
        formData.append(
          "financial_quot_price_type",
          this.getSmallSteps.financial_managments.controls
            .financial_quot_price_type.value
        );
        for (
          let index = 0;
          index <
          this.getSmallSteps.financial_managments.controls.financial_payments
            .controls.length;
          index++
        ) {
          const element =
            this.getSmallSteps.financial_managments.controls.financial_payments
              .controls[index].controls;
          formData.append(
            `financial_payments[${index}][payment_title_english]`,
            element.payment_title_english.value
          );
          formData.append(
            `financial_payments[${index}][payment_title_arabic]`,
            element.payment_title_arabic.value
          );
          formData.append(
            `financial_payments[${index}][payment_stage]`,
            element.payment_stage.value
          );
          formData.append(
            `financial_payments[${index}][payment_stage_level]`,
            element.payment_stage_level.value
          );
        }

        // Step 12

        formData.set("needMark", this.signature_form.get("needMark").value);
        formData.set("needReview", this.signature_form.get("needReview").value);
        formData.set("needStamp", this.signature_form.get("needStamp").value);
        formData.set(
          "needViewers",
          this.signature_form.get("needViewers").value
        );
        formData.set("needAttach", this.signature_form.get("needAttach").value);
        formData.set("otherTerms", this.signature_form.get("otherTerms").value);
        formData.set("needMark", this.signature_form.get("needMark").value);
        formData.set(
          "signature[A][title]",
          this.signature_form.get("signature[A][title]").value
        );
        formData.set(
          "signature[A][user_id]",
          this.signature_form.get("signature[A][user_id]").value || ""
        );
        formData.set(
          "signature[B][title]",
          this.signature_form.get("signature[B][title]").value
        );
        formData.set(
          "signature[B][user_id]",
          this.signature_form.get("signature[B][user_id]").value || ""
        );
        formData.set(
          "signature[C][title]",
          this.signature_form.get("signature[C][title]").value
        );
        formData.set(
          "signature[C][user_id]",
          this.signature_form.get("signature[C][user_id]").value || ""
        );

        formData.set(
          "marks",
          this.signature_form.get("marks").value != null ||
            this.signature_form.get("marks").value != undefined
            ? this.signature_form.get("marks").value.toString()
            : ""
        );
        formData.set(
          "reviews",
          this.signature_form.get("reviews").value != null ||
            this.signature_form.get("reviews").value != undefined
            ? this.signature_form.get("reviews").value.toString()
            : ""
        );
        formData.set(
          "viewers",
          this.signature_form.get("viewers").value != null ||
            this.signature_form.get("viewers").value != undefined
            ? this.signature_form.get("viewers").value.toString()
            : ""
        );
        if (this.signature_form.get("needAttach").value == "1") {
          this.filesFormGroup.value.forEach((v, k) => {
            formData.set("attachments[" + k + "][file]", v.file);
            formData.set(
              "attachments[" + k + "][attach_title]",
              v.attach_title
            );
            formData.set(
              "attachments[" + k + "][attach_with_the_document]",
              v.attach_with_the_document ? "1" : "0"
            );
            formData.set(
              "attachments[" + k + "][print_official_paper]",
              v.print_official_paper ? "1" : "0"
            );
          });
        }

        if (this.signature_form.get("otherTerms").value == "1") {
          this.othersgroup.value.forEach((v, k) => {
            formData.set(
              "other_terms[" + k + "][terms_description_english]",
              v.qror_name_en
            );
            formData.set(
              "other_terms[" + k + "][terms_description_arabic]",
              v.qror_name_ar
            );
          });
        }
        if (this.restrictions_fixed_optional_items.length > 0) {
          var t = this.restrictions_fixed_optional_items
            .filter((opt) => opt.checked)
            .map((opt) => opt.value);
          if (t.length > 0) {
            formData.set("restrictions_fixed_optional", t.toString());
          }
        }
      }

      this.ds.postActionByUrl(formData, "form/FORM_PQ2/cr/0").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.alert.success(res.msg);
            this.form.reset();

            this.signature_form.reset();
            setTimeout(() => {
              this.router.navigate(["/tasks/alltasks"]);
            }, 1000);
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.spinner.hide();

          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
    } else {
      this.alert.error("Please fix all required fields !!");
    }
  }

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}
