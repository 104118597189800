<br>
<div class="themesection panel-expansion">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 mar0auto">
                <mat-accordion class="appsignin" >
                    <mat-expansion-panel class="mpanel" [expanded]="true">
                        <mat-expansion-panel-header class="mpanel-header">{{'lang_signin' | language }}</mat-expansion-panel-header>
                        <div class="material-panel-body">
                            <div class="mbrl15 martb0">
                                <form id="signinForm" [formGroup]="signinform" class="signinform" (ngSubmit)="signin()">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-10">
                                            <div class="form-group">
                                                <label for="id_number">{{'lang_username' | language }} : <span class="red">*</span></label>
                                                <input formControlName="username" minlength="6" id="username" autocomplete="username" type="text" placeholder="{{'lang_username' | language }}" class="form-control almnabrformcontrol" (change)="usernameExists($event.target.value)" />
                                                <mat-error *ngIf="signinform.controls['username'].touched && signinform.controls['username'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                                <mat-error *ngIf="signinform.controls['username'].errors?.minlength"> {{'lang_minimum' | language }} 6</mat-error>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-10">
                                            <div class="form-group">
                                                <label for="password">{{'lang_password' | language }} : <span class="red">*</span></label>
                                                <input formControlName="password" #p minlength="6" autocomplete="current-password" id="password" type="password" placeholder="{{'lang_password' | language }}" class="form-control almnabrformcontrol password" />
                                                <mat-error *ngIf="signinform.controls['password'].touched && signinform.controls['password'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                                <mat-error *ngIf="signinform.controls['password'].errors?.minlength"> {{'lang_minimum' | language }} 6 </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-10">
                                            <div class="form-group">
                                                <label for="confirm_password">{{'lang_confirm_password' | language }} : <span class="red">*</span></label>
                                                <input formControlName="confirm_password" #cp minlength="6" autocomplete="current-password" id="confirm_password" type="password" placeholder="{{'lang_confirm_password' | language }}" class="form-control almnabrformcontrol confirm_password" />
                                                <mat-error *ngIf="signinform.controls['confirm_password'].touched && signinform.controls['confirm_password'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                                <mat-error *ngIf="signinform.controls['confirm_password'].errors?.minlength"> {{'lang_minimum' | language }} 6 </mat-error>
                                                <mat-error *ngIf="signinform.controls['password'].value !== signinform.controls['confirm_password'].value"> {{'lang_password_notmatch' | language }} </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                                            <div class="form-group">
                                                <label for="signature">{{'lang_signature' | language }} : <span class="red">*</span></label>
                                                <div class="sig-container" #padElement>
                                                    <canvas #sPad width="{{width}}" height="{{height}}"></canvas>
                                                </div>
                                                <br>
                                                <div fxLayout="row">
                                                    <div class="col-md-3 col-xs-12 mb-10 pad0">
                                                        <button type="button" class="albutton" (click)="signaturePad.clear()">{{'lang_clear' | language }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                                            <div class="form-group">
                                                <label for="mark">{{'lang_mark' | language }} : <span class="red">*</span></label>
                                                <div class="sig-container" #padElement>
                                                    <canvas #mPad width="{{width}}" height="{{height}}"></canvas>
                                                </div>
                                                <br>
                                                <div fxLayout="row">
                                                    <div class="col-md-3 col-xs-12 mb-10 pad0">
                                                        <button type="button" class="albutton" (click)="markPad.clear()">{{'lang_clear' | language }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="apistatus">
                                        <label class="mb-10">{{'lang_how_to_send_verification' | language }} : <span class="red">*</span></label>
                                        <div class="custom-control custom-radio mb-10">
                                            <input type="radio" class="custom-control-input zindex9999" value="email" id="vm1" formControlName="verification_method" />
                                            <label class="custom-control-label">{{'lang_email' | language }} <b>({{data.primary_email}}) </b> </label>
                                        </div>
                                        <div class="custom-control custom-radio mb-10">
                                            <input type="radio" class="custom-control-input zindex9999" value="mobile" id="vm2" formControlName="verification_method" />
                                            <label class="custom-control-label">{{'lang_mobile' | language }} <b>({{data.primary_mobile}}) </b> </label>
                                        </div>
                                        <div class="custom-control custom-radio mb-10">
                                            <input type="radio" class="custom-control-input zindex9999" value="whatsapp" id="vm3" formControlName="verification_method" />
                                            <label class="custom-control-label">{{'lang_whatsapp' | language }} <b>({{data.primary_mobile}}) </b> </label>
                                        </div>
                                        <mat-error *ngIf="signinform.controls['verification_method'].touched && signinform.controls['verification_method'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                    </div>
                                    <div *ngIf="sendstatus" id="verification_send">
                                        <div class="form-group">
                                            <label for="verification_code">{{'lang_enter_verification_code' | language }}: <span class="red">*</span></label>
                                            <input type="text" minlength="4" maxlength="4" class="form-control almnabrformcontrol" (keypress)="ds.numberOnly($event)" id="verification_code" formControlName="verification_code">
                                            <mat-error *ngIf="signinform.controls['verification_code'].touched && signinform.controls['verification_code'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                            <mat-error *ngIf="signinform.controls['verification_code'].errors?.minlength"> {{'lang_minimum' | language }} 4 </mat-error>
                                            <mat-error *ngIf="signinform.controls['verification_code'].errors?.maxlength"> {{'lang_maximum' | language }} 4 </mat-error>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-xs-12 mb-10">
                                            <button type="button" [disabled]="sendcodestatus" class="albutton" (click)="sendVerificationCode()" *ngIf="apistatus">{{codemsg}}</button>
                                        </div>
                                        <div class="col-md-4 col-xs-12 mb-10" *ngIf="sendstatus">
                                            <button class="albutton">{{'lang_submit' | language }}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer">
                                <app-alert id="default-alert"></app-alert>
                            </div>
                            <div class="card-footer padb0">
                                <div class="row text-center">
                                    <div class="col-md-6 mb-10">
                                        <a routerLink="/signup">{{'lang_join_almnabr' | language }}</a>
                                    </div>
                                    <div class="col-md-6 mb-10">
                                        <a routerLink="/auth/login">{{'lang_signin' | language }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>