import { Appearance } from "@angular-material-extensions/google-maps-autocomplete";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { OwlOptions } from "ngx-owl-carousel-o";
import PlaceResult = google.maps.places.PlaceResult;

declare var $: any;
@Component({
	selector: "app-supervision-dashboard",
	templateUrl: "./supervision-dashboard.component.html",
	styleUrls: ["./supervision-dashboard.component.scss"],
})
export class SupervisionDashboardComponent implements OnInit, AfterViewInit {
	// project details start
	public mapaddress;
	public selectedAddress: PlaceResult;
	public edit;
	public delete;
	public view;
	public zoom: number = 16;
	public latitude: number = 52.520008;
	public longitude: number = 13.404954;
	public project_profile_id = this.route.snapshot.paramMap.get("pid");
	public project_supervision_id = this.route.snapshot.paramMap.get("sid");
	public appearance = Appearance;
	public wholedata: any = [];
	public template_edit_data: any = [];
	public filelevels: any = [];
	tabs_permission;
	// project details end
	// Supervision details start
	public supervision_data: any = [];
	public super_add: boolean = false;
	public super_view: boolean = false;
	public super_edit: boolean = false;
	// Supervision details end
	template_show_option = "VIEW";
	actionMode = "TADD";
	template_id = "";
	template_form_key = "";
	formName = "";
	lang_key = localStorage.getItem("lang_key") || "en";
	is_admin = this.ls.getItem("is_admin");
	user_type_id = this.ls.getItem("user_type_id");
	@ViewChild("stepper") stepper: MatStepper;
	customOptions: OwlOptions = {
		loop: false,
		rtl: this.lang_key == "en" ? false : true,
		mouseDrag: true,
		touchDrag: false,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		navText: ["", ""],
		responsive: {
			0: {
				items: 1,
			},
			400: {
				items: 2,
			},
			740: {
				items: 3,
			},
			940: {
				items: 6,
			},
			1200: {
				items: 6,
			},
		},
		nav: false,
	};
	selectedName = "form_transaction";
	unitsOfTemplate: any;
	transctionSearchKey = "";
	constructor(
		public lang: LanguagePipe,
		public ds: DataService,
		public route: ActivatedRoute,
		public spinner: PreloaderService,
		public router: Router,
		private ls: LocalStorage
	) {}
	ngOnInit(): void {
		this.spinner.show();
		// this.setCurrentPosition();
		this.project_supervision_data();
		this.file_permission_levels();
	}
	ngAfterViewInit(): void {
		setTimeout(() => {
			document
				.querySelectorAll(".stepper-header-icon")[0]
				?.classList?.add("stepper-active");
		}, 1000);
	}

	moveStepper(name: string) {
		const icons = document?.querySelectorAll(".stepper-header-icon");
		const selectedIcon = document?.querySelector(`.${name}`);
		let index: number = 0;
		icons.forEach((item, key) => {
			item?.classList?.remove("stepper-active");
			if (item?.classList?.contains(name)) {
				index = key;
			}
		});
		selectedIcon?.classList?.add("stepper-active");
		this.stepper.selectedIndex = index;
		this.selectedName = name;
	}

	public project_supervision_data() {
		let url = [
			"TEd1bgyHSC0GPcq/" + this.project_profile_id,
			"5mOjlwsszk1lds4/" +
				this.project_profile_id +
				"/" +
				this.project_supervision_id,
		];
		this.ds.forkJoin(url, []).subscribe(
			(res) => {
				this.spinner.hide();
				if (res[0].status) {
					this.wholedata = res[0];
					this.edit = res[0].edit;
					this.view = res[0].view;
					this.delete = res[0].delete;
					this.mapaddress = res[0].data.projects_profile_location;
					if (res[0].data.longitude && res[0].data.latitude) {
						this.latitude = Number(res[0].data.latitude);
						this.longitude = Number(res[0].data.longitude);
					}
					this.zoom = 16;
				}
				if (res[1].status) {
					this.supervision_data = res[1].records;
					this.super_add = res[1].super_add;
					this.super_edit = res[1].super_edit;
					this.tabs_permission = res[1].buttons;
				}
			},
			(error) => {
				this.spinner.hide();
				this.ds.dialogf(
					"",
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
				this.router.navigate(["projects/BbVUrWitLE"]);
			}
		);
	}
	private setCurrentPosition() {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.latitude = position.coords.latitude;
				this.longitude = position.coords.longitude;
			});
		}
	}
	// Supervision details end
	public change_template_action(event) {
		this.template_id = event?.template_id;
		this.template_edit_data = event.data;
		this.template_show_option = event.mode;
		this.actionMode = event.actionMode;
		this.template_form_key = event?.data?.template_platform_code_system;
		this.formName = event?.data?.template_platform_group_type_code_system;
		this.unitsOfTemplate = event?.data?.phase_zone_block_cluster_g_nos;
		if (!this.actionMode) {
			setTimeout(() => {
				this.moveStepper("form_transaction");
			}, 500);
		}
	}
	public by_users_type(users, type) {
		let usersarray: any = [];
		if (users.length > 0) {
			users.forEach((v, k) => {
				if (v.position == type && v.label) {
					usersarray.push(v);
				}
			});
		}
		return usersarray;
	}
	public file_permission_levels() {
		this.filelevels = [];
		this.ds.getActionByUrl([], "lflevel").subscribe((res) => {
			if (res.status) {
				this.filelevels = res.records;
			}
		});
	}
}
