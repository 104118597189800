import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-template-main",
	templateUrl: "./template-main.component.html",
	styleUrls: ["./template-main.component.scss"],
})
export class TemplateMainComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	user_id = this.ls.getItem("user_id");
	template_featured_categories = [];
	module_list = [];
	templates = [];
	projects_temp = [];
	hr_temp = [];
	contractors_temp = [];
	customers_temp = [];
	suppliers_temp = [];
	tasks_temp = [];
	constructor(
		private ds: DataService,
		private router: Router,
		private spinner: PreloaderService,
		private ls: LocalStorage,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.getFormLists();
		this.getTemplates();
		this.template_featured_categories = [
			{
				title: this.lang.transform("lang_projects"),
				module_key: "projects",
				img: "assets/images/tickets-assets/template-category1.svg",
			},
			{
				title: this.lang.transform("lang_human_resources"),
				module_key: "human_resources",
				img: "assets/images/tickets-assets/template-category2.svg",
			},
			{
				title: this.lang.transform("lang_contractors"),
				module_key: "contractors",
				img: "assets/images/tickets-assets/template-category3.svg",
			},
			{
				title: this.lang.transform("lang_customers"),
				module_key: "customers",
				img: "assets/images/tickets-assets/template-category4.svg",
			},
			{
				title: "Suppliers",
				module_key: "suppliers",
				img: "assets/images/tickets-assets/template-category5.svg",
			},
			{
				title: "Tasks",
				module_key: "tasks",
				img: "assets/images/tickets-assets/template-category7.svg",
			},
		];
	}
	getFormLists() {
		this.ds.post("tasks/get_add", {}).subscribe((res) => {
			if (res?.status) {
				this.module_list = res?.data?.modules;
			} else {
				this.router.navigate(["tasks/alltasks"]);
			}
		});
	}

	getTemplates() {
		this.spinner.show();
		this.ds.post("tasks/get_temp_tickets", {}).subscribe(
			(res) => {
				this.spinner.hide();
				this.projects_temp = [];
				this.hr_temp = [];
				this.contractors_temp = [];
				this.customers_temp = [];
				this.suppliers_temp = [];
				this.tasks_temp = [];
				if (res?.status) {
					this.templates = res?.data;
					for (let item of this.templates) {
						switch (item.ref_model) {
							case "projects": {
								this.projects_temp.push(item);
								break;
							}
							case "human_resources": {
								this.hr_temp.push(item);
								break;
							}
							case "contractors": {
								this.contractors_temp.push(item);
								break;
							}
							case "customers": {
								this.customers_temp.push(item);
								break;
							}
							case "suppliers": {
								this.suppliers_temp.push(item);
								break;
							}
							case "tasks": {
								this.tasks_temp.push(item);
								break;
							}
						}
					}
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}

	//delete template
	deleteTemplate(event, id) {
		event.stopPropagation();
		this.spinner.show();
		let formData = new FormData();
		formData.append("temp_id", id || "");
		this.ds.post("tasks/delete_temp_user", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast("", 2000);
					this.getTemplates();
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}
	//toggle template from private to public and vice versa
	toggleTemplatePrivate(event, id, status) {
		event.stopPropagation();
		this.spinner.show();
		let formData = new FormData();
		formData.append("temp_id", id || "");
		if (status == "1") {
			formData.append("status", "2");
		} else if (status == "2") {
			formData.append("status", "1");
		}
		this.ds.post("tasks/update_private_public", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(res?.data?.msg, 2000);
					this.getTemplates();
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
}
