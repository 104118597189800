import { F } from "@angular/cdk/keycodes";
import { KeyValuePipe } from "@angular/common";
import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
	selector: "app-supervision-drawings",
	templateUrl: "./supervision-drawings.component.html",
	styleUrls: ["./supervision-drawings.component.scss"],
})
export class SupervisionDrawingsComponent implements OnInit {
	modalRef: BsModalRef;
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}
	@Input() supervision_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() project_data;
	@Input() makeActionAdd;
	@Input() makeActionEditDelete;
	Dtypes: any = [];
	multiple = environment.multipledropdown;
	single = environment.singledropdown;
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	drawingItems: any = [];
	DListData: any = [];
	dpageno = 1;
	dpagesize = "10";
	Dnodata: string = this.lang.transform("lang_loading");
	Dviewdata: any = [];
	form: FormGroup;
	public isFile;
	filename = this.lang.transform("lang_select_files");
	searchKey = "";
	drawingStatus = "";
	lang_key = localStorage.getItem("lang_key") || "en";
	group1List = [];
	group1_id = null;
	group2List = [];
	group2_id = null;
	group1List_form = [];
	group2List_form = [];
	drawing_main_type_list = [
		{
			label: this.lang.transform("lang_tender_drawing"),
			value: "TD",
		},
		{
			label: this.lang.transform("lang_shop_drawing"),
			value: "SP",
		},
		{
			label: this.lang.transform("lang_asbuilt_drawing"),
			value: "AB",
		},
	];
	filepermissionlevels: any = [];
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public alert: AlertService,
		public modalService: BsModalService,
		public dialog: MatDialog,
		public fb: FormBuilder
	) {}
	ngOnInit(): void {
		this.get_divisions();
		this.tender_drawing_types();
		this.file_permission_levels();
		this.buildform();
		this.load_supervision_tender_drawings();
	}
	get_divisions(search_event?) {
		let searchKey = search_event?.term || "";
		let params = new FormData();
		params.append("lang_key", this.lang_key);
		params.append("search_key", searchKey || "");
		this.ds
			.getActionByUrl(this.ds.formData2string(params), "lpgone")
			.subscribe((res) => {
				if (res.status) {
					this.group1List = res?.records;
					this.group1List_form = res?.records;
				}
			});
	}
	get_group2list(search_event?, isForm?) {
		this.spinner.show();
		let searchKey = search_event?.term || "";
		let params = new FormData();
		if (isForm) {
			params.append(
				"platform_group1_code_system",
				this.form.get("platform_group1_code_system").value || ""
			);
		} else {
			params.append("platform_group1_code_system", this.group1_id || "");
		}
		params.append("platform_group_type_code_system", "");
		params.append("lang_key", this.lang_key);
		params.append("search_key", searchKey || "");
		this.ds.getActionByUrl(this.ds.formData2string(params), "lpgtwo").subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					if (isForm) {
						this.group2List_form = res?.records;
					} else {
						this.group2List = res?.records;
					}
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}
	public file_permission_levels() {
		this.filepermissionlevels = [];
		this.ds.getActionByUrl([], "lflevel").subscribe((res) => {
			if (res.status) {
				this.filepermissionlevels = res.records;
			}
		});
	}
	tender_drawing_types() {
		this.Dtypes = [];
		this.ds.getActionByUrl([], "ZyYkGcK85IUr/TD001").subscribe((res) => {
			if (res.status) {
				this.Dtypes = res.records;
			}
		});
	}
	public buildform() {
		this.form = new FormGroup({
			projects_profile_id: new FormControl(""),
			projects_supervision_id: new FormControl(""),
			platform_group1_code_system: new FormControl(null, [Validators.required]),
			platform_group2_code_system: new FormControl(null, [Validators.required]),
			drawings_main_type: new FormControl(null, [Validators.required]),
			drawing_file_id: new FormControl(""),
			drawings_type_id: new FormControl("", [Validators.required]),
			drawings_name: new FormControl("", [Validators.required]),
			drawings_number: new FormControl("", [Validators.required]),
			level_keys: new FormControl("", [Validators.required]),
			drawings_attachment_url: new FormControl("", [Validators.required]),
		});
	}
	public emptyform() {
		this.alert.clear();
		this.form.reset();
		this.filename = this.lang.transform("lang_select_files");
	}
	public UploadFile(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			this.isFile = true;
			this.filename = event.target.files[0].name;
			this.form
				.get("drawings_attachment_url")
				.setValue(event.target.files[0], { emitEvent: true });
		} else {
			this.isFile = false;
			this.form.get("drawings_attachment_url").setValue(null);
			this.filename = this.lang.transform("lang_select_files");
		}
	}
	public removeFile() {
		this.isFile = false;
		this.form.get("drawings_attachment_url").setValue(null);
		this.filename = this.lang.transform("lang_select_files");
	}
	public submit() {
		this.spinner.show();
		let param = new FormData();
		param.append(
			"projects_profile_id",
			this.supervision_data.projects_profile_id || ""
		);
		param.append(
			"projects_work_area_id",
			this.supervision_data.projects_work_area_id || ""
		);
		param.append(
			"projects_supervision_id",
			this.supervision_data.projects_supervision_id || ""
		);
		param.append("drawings_type_id", this.form.get("drawings_type_id").value);
		param.append(
			"platform_group1_code_system",
			this.form.get("platform_group1_code_system").value || ""
		);
		param.append(
			"platform_group2_code_system",
			this.form.get("platform_group2_code_system").value || ""
		);
		param.append(
			"drawings_main_type",
			this.form.get("drawings_main_type").value || ""
		);
		param.append(
			"drawing_file_id",
			this.form.get("drawing_file_id").value || ""
		);
		param.append("drawings_name", this.form.get("drawings_name").value || "");
		param.append(
			"drawings_number",
			this.form.get("drawings_number").value || ""
		);
		param.append(
			"drawings_attachment_url",
			this.form.get("drawings_attachment_url").value || ""
		);
		for (let item of this.form.get("level_keys").value) {
			param.append("level_keys[]", item || "");
		}
		let url = this.form.get("drawing_file_id").value
			? "MK1AkxVwrDRE/" + this.form.get("drawing_file_id").value
			: "ACxidLBqSh4x";
		this.ds.postActionByUrl(param, url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.alert.success(res.msg);
					this.form.reset();
					this.load_supervision_tender_drawings();
					setTimeout(() => {
						this.modalRef.hide();
					}, 1000);
				} else {
					this.alert.error(res.error);
				}
			},
			(error) => {
				this.spinner.hide();
				this.alert.error(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}
	public search_supervision_tender_drawing() {
		this.dpageno = 1;
		this.dpagesize = "10";
		this.load_supervision_tender_drawings();
	}
	public load_tender_drawings_pagesize(pagesize) {
		this.spinner.show();
		this.dpageno = 1;
		this.dpagesize = pagesize;
		this.load_supervision_tender_drawings();
	}
	public load_tender_drawings_page(page) {
		this.spinner.show();
		this.dpageno = page;
		this.dpagesize = this.dpagesize;
		this.load_supervision_tender_drawings();
	}
	public load_supervision_tender_drawings() {
		let formdata = new FormData();
		formdata.append("search_key", this.searchKey);
		formdata.append("drawing_file_id", "");
		formdata.append("drawing_main_type", "");
		formdata.append("platform_group1_code_system", this.group1_id || "");
		formdata.append("platform_group2_code_system", this.group2_id || "");
		if (this.drawingItems && this.drawingItems.length > 0) {
			formdata.append("drawing_types", this.drawingItems.toString());
		}
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formdata),
				"gPIJcGTu1ikk/" +
					this.project_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id +
					"/" +
					this.dpageno +
					"/" +
					this.dpagesize
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.DListData = res;
					} else {
						this.DListData = [];
						this.Dnodata = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.DListData = [];
					this.Dnodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}
	public viewdata(data) {
		this.Dviewdata = data;
	}
	public editdata(data) {
		this.isFile = false;
		this.filename = this.lang.transform("lang_select_files");
		this.form.reset();
		this.form.patchValue({
			drawing_file_id: data.drawing_file_id,
			drawings_name: data.drawings_name,
			drawings_number: data.drawings_number,
			platform_group1_code_system: data?.platform_group1_code_system,
			platform_group2_code_system: data?.platform_group2_code_system,
			drawings_main_type: data?.drawings_main_type,
			drawings_type_id: data?.drawings_type_id,
			level_keys: data?.level_keys?.split(","),
		});
		this.get_group2list({ term: "" }, true);
	}
	public approve_tender_drawing(data) {
		const dialogRef = this.dialog.open(ConfirmationDialog, {
			disableClose: true,
			width: "400px",
			data: {
				title: this.lang.transform("lang_confirmation"),
				message: this.lang.transform("lang_are_you_sure"),
			},
		});
		dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.spinner.show();
				this.ds
					.getActionByUrl(
						[],
						"gKb1qqlI3rdx/" +
							this.supervision_data.projects_profile_id +
							"/" +
							this.supervision_data.projects_supervision_id +
							"/" +
							data.drawing_file_id
					)
					.subscribe(
						(res) => {
							this.spinner.hide();
							if (res.status) {
								this.load_supervision_tender_drawings();
								this.ds.dialogf("", res.msg);
							} else {
								this.ds.dialogf("", res.error);
							}
						},
						(error) => {
							this.spinner.hide();
							this.ds.dialogf(
								"",
								error && error.error && error.error.error
									? error.error.error
									: this.lang.transform("lang_internal_server_error")
							);
						}
					);
			}
		});
	}
	public checkAllItemsDraw(event) {
		if (event.target.checked) {
			$(".drawings .eachItemDraw").prop("checked", true);
			$(".drawings .makeback").addClass("bground");
			$(".drawings .deleteIconDiv").show();
		} else {
			$(".drawings .eachItemDraw").prop("checked", false);
			$(".drawings .makeback").removeClass("bground");
			$(".drawings .deleteIconDiv").hide();
		}
	}
	public checkEachItemDraw(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".drawings .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".drawings .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".drawings .eachItemDraw:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".drawings .eachItemDraw").each(function () {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".drawings .deleteIconDiv").show();
		} else {
			$(".drawings .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".drawings #allItemsDraw").prop("checked", true);
		} else {
			$(".drawings #allItemsDraw").prop("checked", false);
		}
	}
	public deleteBulkDataDraw() {
		const checkedtotal = [];
		$(".drawings .eachItemDraw:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: "400px",
				data: {
					title: this.lang.transform("lang_confirmation"),
					message: this.lang.transform("lang_are_you_sure"),
				},
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show();
					this.ds
						.deleteActionByUrl(
							checkedtotal,
							"D54d6sFdsf/" +
								this.supervision_data.projects_profile_id +
								"/" +
								this.supervision_data.projects_supervision_id
						)
						.subscribe(
							(data) => {
								this.spinner.hide();
								if (data.status) {
									this.load_supervision_tender_drawings();
									$(".drawings #allItemsDraw").prop("checked", false);
									$(".drawings .deleteIconDiv").hide();
									this.ds.dialogf("", data.msg);
								} else {
									this.ds.dialogf("", data.error);
								}
							},
							(error) => {
								this.ds.dialogf(
									"",
									error && error.error && error.error.error
										? error.error.error
										: this.lang.transform("lang_internal_server_error")
								);
								this.spinner.hide();
							}
						);
				}
			});
		} else {
			this.ds.dialogf();
		}
	}

	getMainTypeName(value) {
		let type = this.drawing_main_type_list.find((el) => {
			return el?.value == value;
		});
		return type?.label;
	}
}
