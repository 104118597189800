<section
  class="panel-expansion matborderno"
  *ngIf="branch_id && branch_id != '0'"
>
  <mat-accordion class="accounts_settings">
    <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
      <i class="together-icon">
        <i
          class="fa fa-plus faadd mnabricon"
          *ngIf="ds.allowMe('accounts', 'accounts_add')"
          (click)="openModal(CreateReportGroup, 'create')"
          matTooltip="{{ 'lang_create_report_groups' | language }}"
        ></i>
      </i>
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_reports_groups" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <div class="mbrl15">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
              <input
                type="text"
                placeholder="{{ 'lang_search' | language }}"
                [(ngModel)]="searchKey"
                (keyup)="load_reports_groups()"
                class="form-control almnabrformcontrol"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive customResponsive">
                <table border="1" class="table CustomTable">
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>{{ "lang_group_name_english" | language }}</td>
                      <td>{{ "lang_group_name_arabic" | language }}</td>
                      <td>{{ "lang_report_group_account_type" | language }}</td>
                      <td>{{ "lang_ondate" | language }}</td>
                      <td>{{ "lang_writer" | language }}</td>
                      <td>{{ "lang_action" | language }}</td>
                    </tr>
                  </thead>
                  <tbody
                    *ngIf="reportsGroupsData?.records?.length"
                    class="AppendList"
                  >
                    <tr
                      *ngFor="
                        let data of reportsGroupsData?.records;
                        let i = index;
                        let odd = odd;
                        let even = even
                      "
                      [ngClass]="{ odd: odd, even: even }"
                      class="makeback removerow{{ data.report_group_id }}"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ data.group_name_english }}</td>
                      <td>{{ data.group_name_arabic }}</td>
                      <td *ngIf="data.report_group_account_type == 'S'">
                        {{ "lang_specific_accounts" | language }}
                      </td>
                      <td *ngIf="data.report_group_account_type == 'P'">
                        {{ "lang_parent_accounts" | language }}
                      </td>
                      <td>{{ data.created_datetime }}</td>
                      <td>{{ data.writer_name }}</td>
                      <td>
                        <i
                          class="fa fa-eye faicon mnabricon s2icon"
                          matTooltip="{{ 'lang_view' | language }}"
                          (click)="showReportsData(data)"
                        ></i>
                        <i
                          class="fa fa-pencil-square-o faicon mnabricon s2icon"
                          *ngIf="ds.allowMe('accounts', 'accounts_edit')"
                          (click)="openModal(CreateReportGroup, 'edit', data)"
                          matTooltip="{{ 'lang_edit' | language }}"
                        ></i>
                        <i
                          class="fa fa-trash faicon red mnabricon s2icon"
                          *ngIf="ds.allowMe('accounts', 'accounts_delete')"
                          (click)="deleteReportsData(data)"
                          matTooltip="{{ 'lang_delete' | language }}"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    *ngIf="!reportsGroupsData?.records?.length"
                    class="AppendList"
                  >
                    <tr class="odd">
                      <td colspan="7" align="center">{{ lodingdatas }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="reportsGroupsData?.records?.length"
          class="card-footer padb0"
        >
          <div class="row responsive_pagination">
            <div
              class="col-lg-3 col-sm-3 mb-10"
              style="height: fit-content; margin: auto 0"
            >
              {{ "lang_total" | language }} :
              {{ reportsGroupsData?.page?.total_records }}
            </div>
            <div
              class="col-lg-5 col-sm-5 mb-10"
              style="display: flex; justify-content: center"
            >
              <ngb-pagination
                class="m-auto"
                [collectionSize]="reportsGroupsData?.page?.total_records"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                [(page)]="acpage_no"
                [pageSize]="acpage_size"
                (pageChange)="loadpagerec($event)"
              ></ngb-pagination>
            </div>
            <div
              class="col-lg-3 col-sm-3"
              style="display: flex; justify-content: end; margin: auto"
            >
              <mat-select
                style="max-width: 150px"
                [(ngModel)]="recpage_size"
                (selectionChange)="loadrecpagesize($event.value)"
              >
                <mat-option class="text-center" value="10"
                  >10 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="20"
                  >20 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="50"
                  >50 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="100"
                  >100 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option class="text-center" value="500"
                  >500 {{ "lang_per_page" | language }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
<ng-template #viewASInvModal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_report_group" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef1.hide()"
      >
        &times;
      </button>
    </div>
    <div class="modal-body" *ngIf="viewdata">
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_report_group_account_type" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b *ngIf="viewdata?.records?.report_group_account_type == 'S'">{{
              "lang_specific_accounts" | language
            }}</b>
            <b *ngIf="viewdata?.records?.report_group_account_type == 'P'">{{
              "lang_parent_accounts" | language
            }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_group_name_english" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">{{ viewdata?.records?.group_name_english }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_group_name_arabic" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">{{ viewdata?.records?.group_name_arabic }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_writer" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">{{ viewdata?.records?.writer_name }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_ondate" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">{{ viewdata?.records?.created_datetime }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_editor_name" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">{{ viewdata?.records?.editor_name }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_updated_datetime" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">{{ viewdata?.records?.updated_datetime }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_accounts" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              <span *ngFor="let item of viewdata?.accounts; let i = index">
                {{ item?.label }} {{ item?.account_masters_code }}
                <span *ngIf="i != viewdata?.accounts?.length - 1">,</span>
              </span>
            </b>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary secondarybutton"
        data-dismiss="modal"
        (click)="modalRef1.hide()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #CreateReportGroup>
  <div class="modal-content">
    <div class="modal-header">
      <h4 *ngIf="!isEditMode" class="modal-title">
        {{ "lang_create_report_groups" | language }}
      </h4>
      <h4 *ngIf="isEditMode" class="modal-title">
        {{ "lang_update_report_groups" | language }}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="form" class="from" (ngSubmit)="submit()">
      <div class="modal-body">
        <div class="form-group">
          <label for="group_name_english"
            >{{ "lang_group_name_english" | language }}:</label
          >
          <input
            type="text"
            formControlName="group_name_english"
            class="form-control"
          />
          <mat-error
            *ngIf="
              form.controls['group_name_english'].touched &&
              form.controls['group_name_english'].errors?.required
            "
          >
            {{ "lang_field_required" | language }}
          </mat-error>
        </div>
        <div class="form-group">
          <label for="group_name_arabic"
            >{{ "lang_group_name_arabic" | language }}:</label
          >
          <input
            type="text"
            formControlName="group_name_arabic"
            class="form-control"
          />
          <mat-error
            *ngIf="
              form.controls['group_name_arabic'].touched &&
              form.controls['group_name_arabic'].errors?.required
            "
          >
            {{ "lang_field_required" | language }}
          </mat-error>
        </div>
        <!-- <div class="form-group">
          <label for="report_group_account_type"
            >{{ "lang_report_group_account_type" | language }}:
            <span class="red">*</span></label
          >
          <ng-select
            [items]="reportTypes"
            [multiple]="false"
            bindLabel="label"
            bindValue="value"
            clearAllText="Clear"
            [searchFn]="ds.customSearchFn"
            appendTo="body"
            formControlName="report_group_account_type"
            placeholder="{{ 'lang_report_group_account_type' | language }}"
          >
          </ng-select>
          <mat-error
            *ngIf="
              form.controls['report_group_account_type'].touched &&
              form.controls['report_group_account_type'].errors?.required
            "
          >
            {{ "lang_field_required" | language }}
          </mat-error>
        </div> -->
        <div class="form-group">
          <label for="group_accounts"
            >{{ "lang_group_accounts" | language }}:
            <span class="red">*</span></label
          >
          <ng-select
            [items]="accounts"
            [multiple]="true"
            bindLabel="label"
            bindValue="value"
            clearAllText="Clear"
            [searchFn]="ds.customSearchFn"
            appendTo="body"
            (search)="getParentAccounts($event)"
            formControlName="group_accounts"
            placeholder="{{ 'lang_group_accounts' | language }}"
          >
          </ng-select>
          <mat-error
            *ngIf="
              form.controls['group_accounts'].touched &&
              form.controls['group_accounts'].errors?.required
            "
          >
            {{ "lang_field_required" | language }}
          </mat-error>
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              data-dismiss="modal"
              (click)="modalRef.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
