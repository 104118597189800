<section class="panel-expansion">
    <mat-accordion class="appcollections" >
        <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
            <mat-expansion-panel-header class="mpanel-header">
                <div class="snaglist-header d-flex justify-content-between align-items-center w-100">
                    <div class="snaglist-title">
                        {{'lang_snaglist' | language }}
                    </div>
                    <div class="snaglist-excel">
                        <i class="pi pi-download" aria-hidden="true" matTooltip="{{'lang_download_file' | language}}" (click)="downloadSnaglistExcel()"></i>
                        <i class="pi pi-upload ml-3 mr-3" aria-hidden="true" matTooltip="{{'lang_export_excel' | language}}" (click)="attachmentInput.click()"></i>
                        <input type="file" hidden #attachmentInput id="attachmentInput" (change)="uploadSnaglistExcel($event.target.files)">
                        <i class="pi pi-history" aria-hidden="true" matTooltip="{{'lang_history' | language}}" (click)="openSnagExcelHistory()"></i>
                    </div>
                </div>
            </mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15 martb0">
                    <div class="row">
                        <div class="col-md-3 col-sm-12 col-xs-12 mb-10">
                            <ng-select 
                                [items]="group1_list" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_group' | language}} 1"
                                [(ngModel)]="group1_selected"
                                (change)="list_of_datas();get_group2_list();">
                            </ng-select>
                        </div>
                        <div class="col-md-3 col-sm-12 col-xs-12 mb-10">
                            <ng-select 
                                [items]="group2_list" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_group' | language}} 2"
                                [(ngModel)]="group2_selected"
                                (change)="list_of_datas();">
                            </ng-select>
                        </div>
                        <div class="col-md-6 col-sm-12 col-xs-12 mb-10">
                            <div class="input-group input-group-sm mb-10">
                                <input type="text" class="form-control almnabrformcontrol" [(ngModel)]="searchKey" (keyup)="list_of_datas();" placeholder="{{'lang_search' | language }}">
                                <span class="input-group-btn">
                                    <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" id="btnSearch" type="button" matTooltip="{{'lang_add' | language }}" (click)="onAddSnaglist()"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>{{'lang_title_english' | language }}</td>
                                            <td>{{'lang_title_arabic' | language }}</td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="snag_list?.records?.length" class="AppendList">
                                        <tr *ngFor="let row of snag_list?.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }">
                                            <td>{{i+1}}</td>
                                            <td>{{row.note_title_english}}</td>
                                            <td>{{row.note_title_arabic}}</td>                                            
                                            <td>{{row.writer}}</td>
                                            <td>
                                                <i class="fa fa-pencil-square-o faicon mnabricon"  matTooltip="{{'lang_edit' | language }}" (click)="onEditSnaglist(row)"></i>
                                                <i class="fa fa-trash faicon mnabricon s2icon red"  matTooltip="{{'lang_delete' | language }}" (click)="deleteSnag(row?.note_lists_id)"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!snag_list?.records?.length" class="AppendList">
                                        <tr class="odd">
                                            <td colspan="6" align="center">
                                                <no-data style="margin: auto;"  [Opened]="!snag_list?.records?.length"></no-data>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="snag_list?.records?.length" class="card-footer padb0">
                    <div class="row">
                        <div class="col-lg-3 col-sm-6 col-6 mb-10">
                            {{'lang_total' | language }} : {{snag_list?.records?.length}}
                        </div>
                        <div class="col-lg-6 col-sm-6 mb-10 d-flex justify-content-center">
                            <ngb-pagination [collectionSize]="snag_list?.records?.length" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [(page)]="page" [pageSize]="pageSize" (pageChange)="list_of_datas()"></ngb-pagination>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <mat-select class="customselect" [(ngModel)]="pageSize" (selectionChange)="list_of_datas()">
                                <mat-option [value]="10">10 {{'lang_per_page' | language}}</mat-option>
                                <mat-option [value]="20">20 {{'lang_per_page' | language}}</mat-option>
                                <mat-option [value]="50">50 {{'lang_per_page' | language}}</mat-option>
                                <mat-option [value]="100">100 {{'lang_per_page' | language}}</mat-option>
                                <mat-option [value]="250">250 {{'lang_per_page' | language}}</mat-option>
                                <mat-option [value]="500">500 {{'lang_per_page' | language}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>