<div mat-dialog-title class="d-flex justify-content-between">
	<div class="task-title">
		<div class="tt-up">
			<i class="pi pi-credit-card"></i>
			<h3>{{ task?.title }}</h3>
		</div>
		<div class="tt-down">
			<p>
				{{ "lang_status" | language }} <span>{{ task?.task_status_name }}</span>
			</p>
		</div>
	</div>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content>
	<div class="p-2">
		<div class="row">
			<div class="col-12 col-md-9">
				<!-- start task-members-dates -->
				<div class="members-dates">
					<div class="task-users-container">
						<h5 class="task-users-title">{{ "lang_users" | language }}</h5>
						<div class="task-users">
							<div
								class="bhu-user"
								[popover]="user_popover"
								[outsideClick]="true"
								placement="bottom"
								(onShown)="onShowMemberCard(member)"
								*ngFor="let member of task_members"
							>
								<img
									src="assets/images/avatar.png"
									alt="avatar"
									title="{{ member?.userName }}"
									*ngIf="!member?.profile_image_64"
								/>
								<img
									src="{{ member?.profile_image_64 }}"
									alt="avatar"
									title="{{ member?.userName }}"
									*ngIf="member?.profile_image_64"
								/>
							</div>
						</div>
					</div>
					<div class="task-dates">
						<h5 class="task-dates-title">{{ "lang_date" | language }}</h5>
						<div class="task-dates-content task-btn">
							{{ "lang_from" | language }} {{ task?.start_date }}
							{{ "lang_to" | language }} {{ task?.end_date }}
						</div>
					</div>
				</div>
				<!-- end task-members-dates -->
				<!-- start task-Description -->
				<div class="task-description">
					<div class="td-title">
						<i class="pi pi-align-left"></i>
						<h4>{{ "lang_description" | language }}</h4>
					</div>
					<div class="td-body">
						<p>{{ task.description }}</p>
					</div>
				</div>
				<!-- end task-Description -->
				<!-- start task-attachments -->
				<div class="task-attacchments">
					<div class="ta-header">
						<i class="pi pi-paperclip"></i>
						<h4>{{ "lang_attachments" | language }}</h4>
					</div>
					<div class="ta-list">
						<div
							class="tal-file"
							*ngFor="
								let attachment of task_attachments
									| slice: 0:taskAttachmentsSlice
							"
						>
							<div
								class="talf-type"
								(click)="
									ds?.preview_pdf_file(
										attachment?.path,
										attachment?.file_name_en
									)
								"
							>
								<span>{{ attachment?.file_extension }}</span>
							</div>
							<div class="talf-content">
								<div class="talf-content-title">
									{{ attachment?.file_name_en }}
								</div>
								<div class="talf-content-body">
									<span class="talfcb-date"
										>{{ "lang_created_date" | language }}
										{{ attachment?.created_datetime }}</span
									>
									<div class="talfcb-divider"></div>
									<span
										class="talf-content-body-download"
										(click)="
											downloadFile(attachment?.path, attachment?.file_name_en)
										"
										>{{ "lang_download_file" | language }}</span
									>
								</div>
							</div>
						</div>
						<div
							class="tal-file talf-action-size"
							*ngIf="task_attachments?.length > 2"
						>
							<span
								class="tal-file-action-size"
								*ngIf="taskAttachmentsSlice == 2"
								(click)="taskAttachmentsSlice = task_attachments?.length"
								>{{ "lang_view" | language }} {{ "lang_all" | language }}
								{{ "lang_attachments" | language }} ({{
									task_attachments?.length - taskAttachmentsSlice
								}}
								{{ "lang_hidden" | language }})</span
							>
							<span
								class="tal-file-action-size"
								*ngIf="taskAttachmentsSlice > 2"
								(click)="taskAttachmentsSlice = 2"
								>{{ "lang_show_fewer" | language }}
								{{ "lang_attachments" | language }}</span
							>
						</div>
					</div>
				</div>
				<!-- end task-attachments -->
				<!-- start task-checklist -->
				<div class="task-checklist-list">
					<!-- start task-checklist list -->
					<div
						class="task-checklist"
						*ngFor="let checklist of checklist_arr; let checklist_index = index"
					>
						<div class="tc-header">
							<div class="tch-title">
								<i class="pi pi-check-square"></i>
								<h5>{{ checklist?.title }}</h5>
							</div>
							<div class="tc-action">
								<span
									class="task-btn tca-hide-checked"
									*ngIf="!checklist?.hideChecked"
									(click)="checklist.hideChecked = true"
									>{{ "lang_hide_checked_items" | language }}</span
								>
								<span
									class="task-btn tca-hide-checked"
									*ngIf="checklist?.hideChecked"
									(click)="checklist.hideChecked = false"
									>{{ "lang_show_checked_items" | language }}</span
								>
							</div>
						</div>
						<div class="tc-progress">
							<span class="tc-progress-no"
								>{{ checklist?.progress || "0" }}%</span
							>
							<div class="tc-progress-bar">
								<div
									class="tcpb-current"
									[style]="'width:' + checklist?.progress + '%'"
								></div>
							</div>
						</div>
						<div class="tc-body">
							<!-- start task-checklist list items -->
							<div class="tc-items">
								<ng-container
									*ngFor="
										let checklist_item of checklist?.chicklist_items;
										let checklistItemIndex = index
									"
								>
									<div
										class="tc-item"
										*ngIf="
											!(
												checklist.hideChecked == true &&
												checklist_item.is_done == '2'
											)
										"
									>
										<div class="tc-item-container">
											<div class="tci-check">
												<mat-checkbox
													[checked]="
														checklist_item.is_done == '2' ? true : false
													"
													[disableRipple]="true"
													(click)="$event.preventDefault()"
												></mat-checkbox>
											</div>
											<div class="tci-content">
												<span class="tci-content-title">
													<h5>{{ checklist_item?.notes }}</h5>
												</span>
											</div>
										</div>
									</div>
								</ng-container>
							</div>
							<!-- end task-checklist list items -->
						</div>
					</div>
					<!-- end task-checklist list -->
				</div>
				<!-- end task-checklist -->
			</div>
			<div class="col-12 col-md-3"></div>
		</div>
	</div>
</mat-dialog-content>

<!-- start user template popover -->
<ng-template #user_popover>
	<div class="user-popover">
		<div class="up-header">
			<div class="up-header-content">
				<div class="up-header-img">
					<img src="assets/images/avatar.png" alt="" />
				</div>
				<div class="up-header-details">
					<h3>
						{{ memberCardData?.firstName }} {{ memberCardData?.lastName }}
					</h3>
					<p>{{ memberCardData?.userName }}</p>
				</div>
			</div>
		</div>
		<div class="up-body">
			<p></p>
		</div>
	</div>
</ng-template>
<!-- end user template popover -->
