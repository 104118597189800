<form [formGroup]="teamsForm" (ngSubmit)="submit()">
	<div class="row p-1">
		<div class="col-12">
			<div class="itemsdata">
				<label>
					{{ "lang_teams" | language }}
					<span class="red">*</span></label
				>
				<div class="TableParts">
					<div
						class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
					>
						<table border="1" class="table CustomTable text-center">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="add_new_items()"
											>add_box</mat-icon
										>
									</td>
									<td>
										<span matTooltip="{{ 'lang_position' | language }}">{{
											"lang_position" | language
										}}</span>
										<i class="red"> *</i>
									</td>
									<td>
										<span matTooltip="{{ 'lang_presence' | language }}">{{
											"lang_presence" | language
										}}</span>
										<i class="red"> *</i>
									</td>
									<td>
										<span
											matTooltip="{{ 'lang_projects_nationality' | language }}"
											>{{ "lang_projects_nationality" | language }}</span
										>
										<i class="red"> *</i>
									</td>
									<td>
										<span matTooltip="{{ 'lang_team_number' | language }}">{{
											"lang_team_number" | language
										}}</span
										><i class="red"> *</i>
									</td>
									<td>
										<span matTooltip="{{ 'lang_experience' | language }}">{{
											"lang_experience" | language
										}}</span
										><i class="red"> *</i>
									</td>
									<td>
										<span matTooltip="{{ 'lang_proit_ratio' | language }}">{{
											"lang_proit_ratio" | language
										}}</span
										><i class="red"> *</i>
									</td>
									<td>
										<span
											matTooltip="{{
												'lang_total_salary_per_person_month' | language
											}}"
											>{{
												"lang_total_salary_per_person_month" | language
											}}</span
										><i class="red"> *</i>
									</td>
									<td>
										<span
											matTooltip="{{
												'lang_duration_number_months' | language
											}}"
											>{{ "lang_duration_number_months" | language }}</span
										><i class="red"> *</i>
									</td>
									<td>
										<span
											matTooltip="{{ 'lang_total_no_of_visits' | language }}"
											>{{ "lang_total_no_of_visits" | language }}</span
										><i class="red"> *</i>
									</td>
									<td>
										<span matTooltip="{{ 'lang_toal_bid' | language }}">{{
											"lang_toal_bid" | language
										}}</span
										><i class="red"> *</i>
									</td>
								</tr>
							</thead>
							<tbody formArrayName="teams" class="AppendList quotationtable">
								<tr
									*ngFor="
										let item of items_group().controls;
										let itemIndex = index;
										let odd = odd;
										let even = even
									"
									[formGroupName]="itemIndex"
									style="background: #ffffff"
								>
									<td class="verticalalignmid">
										{{ itemIndex + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											(click)="removeItems(itemIndex)"
											>close</mat-icon
										>
									</td>
									<td width="10%">
										<ng-select
											[items]="
												itemsgroup.controls[itemIndex].get('positionList').value
											"
											[multiple]="false"
											clearAllText="Clear"
											appendTo="body"
											formControlName="position"
											placeholder="{{ 'lang_position' | language }}"
											(change)="
												setDataAction($event, itemIndex);
												calculate_quotation(itemIndex)
											"
											(search)="getPositions($event, itemIndex)"
										>
										</ng-select>
										<mat-error
											*ngIf="
												submitted &&
												itemsgroup.controls[itemIndex].get('position').errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</td>
									<td width="10%">
										<ng-select
											[items]="
												itemsgroup.controls[itemIndex].get('presenceList').value
											"
											[multiple]="false"
											clearAllText="Clear"
											appendTo="body"
											formControlName="presence"
											placeholder="{{ 'lang_presence' | language }}"
											(change)="
												setDataAction($event, itemIndex);
												calculate_quotation(itemIndex)
											"
											(search)="getPresence($event, itemIndex)"
										>
										</ng-select>
										<mat-error
											*ngIf="
												submitted &&
												itemsgroup.controls[itemIndex].get('presence').errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</td>
									<td width="10%">
										<ng-select
											[items]="
												itemsgroup.controls[itemIndex].get('nationalityList')
													.value
											"
											[multiple]="false"
											bindLabel="label"
											bindValue="value"
											clearAllText="Clear"
											appendTo="body"
											formControlName="nationality"
											placeholder="{{
												'lang_human_resources_nationality' | language
											}}"
											(search)="getNationality($event, itemIndex)"
										>
										</ng-select>
										<mat-error
											*ngIf="
												submitted &&
												itemsgroup.controls[itemIndex].get('nationality').errors
													?.required
											"
											>{{
												"lang_human_resources_nationality" | language
											}}</mat-error
										>
									</td>
									<td>
										<input
											type="text"
											placeholder="{{ 'lang_team_number' | language }}"
											formControlName="team_number"
											class="form-control almnabrformcontrol"
											(keyup)="calculate_quotation(itemIndex)"
										/>
										<mat-error
											*ngIf="
												submitted &&
												itemsgroup.controls[itemIndex].get('team_number').errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</td>
									<td>
										<input
											type="text"
											placeholder="{{ 'lang_experience' | language }}"
											formControlName="experience"
											class="form-control almnabrformcontrol"
											(keyup)="calculate_quotation(itemIndex)"
										/>
										<mat-error
											*ngIf="
												submitted &&
												itemsgroup.controls[itemIndex].get('experience').errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</td>
									<td>
										<input
											type="text"
											placeholder="{{ 'lang_proit_ratio' | language }}"
											formControlName="profit_ratio"
											class="form-control almnabrformcontrol"
											(change)="
												ds.CheckDecimal(
													$event.target.value,
													items.controls[itemIndex].get('profit_ratio')
												);
												calculate_quotation(itemIndex)
											"
											(keyup)="calculate_quotation(itemIndex)"
										/>
										<mat-error
											*ngIf="
												submitted &&
												itemsgroup.controls[itemIndex].get('profit_ratio')
													.errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</td>
									<td class="readonlybyaction">
										<input
											type="text"
											placeholder="{{
												'lang_total_salary_per_person_month' | language
											}}"
											formControlName="total_salary_per_person_month"
											readonly
											class="form-control almnabrformcontrol readonlyinput"
											(keyup)="calculate_quotation(itemIndex)"
										/>
										<mat-error
											*ngIf="
												submitted &&
												itemsgroup.controls[itemIndex].get(
													'total_salary_per_person_month'
												).errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</td>
									<td>
										<input
											type="text"
											placeholder="{{
												'lang_duration_number_months' | language
											}}"
											formControlName="duration_of_months"
											class="form-control almnabrformcontrol"
											(keyup)="calculate_quotation(itemIndex)"
										/>
										<mat-error
											*ngIf="
												submitted &&
												itemsgroup.controls[itemIndex].get('duration_of_months')
													.errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</td>
									<td class="rowdisable{{ itemIndex }}">
										<input
											type="text"
											placeholder="{{ 'lang_total_no_of_visits' | language }}"
											formControlName="visits_per_month"
											class="form-control almnabrformcontrol inputdisable"
											(keyup)="calculate_quotation(itemIndex)"
										/>
										<mat-error
											*ngIf="
												submitted &&
												itemsgroup.controls[itemIndex].get('visits_per_month')
													.errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</td>
									<td class="readonlybyaction">
										<input
											type="text"
											placeholder="{{ 'lang_toal_bid' | language }}"
											formControlName="total_bid_of_project"
											readonly
											class="form-control almnabrformcontrol readonlyinput"
											(change)="
												ds.CheckDecimal(
													$event.target.value,
													items.controls[itemIndex].get('total_bid_of_project')
												)
											"
											(keyup)="calculate_quotation(itemIndex)"
										/>
										<mat-error
											*ngIf="
												submitted &&
												itemsgroup.controls[itemIndex].get(
													'total_bid_of_project'
												).errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="TableParts">
					<div class="table-responsive customResponsive mb-10 tablefields">
						<table border="1" class="table CustomTable text-center">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<span
											matTooltip="{{ 'lang_position_description' | language }}"
											>{{ "lang_position_description" | language }}</span
										>
										<i class="red"> *</i>
									</td>
								</tr>
							</thead>
							<tbody formArrayName="teams" class="AppendList">
								<tr
									*ngFor="
										let item of items_group().controls;
										let itemIndex = index;
										let odd = odd;
										let even = even
									"
									[formGroupName]="itemIndex"
									style="background: #ffffff"
								>
									<td width="1%" class="verticalalignmid">
										{{ itemIndex + 1 }}
									</td>
									<td>
										<input
											type="text"
											formControlName="positions_task"
											class="form-control almnabrformcontrol"
											placeholder="{{ 'lang_position_description' | language }}"
										/>
										<mat-error
											*ngIf="
												submitted &&
												itemsgroup.controls[itemIndex].get('positions_task')
													.errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-8 col-xs-12 mb-10"></div>
		<div class="col-sm-4 col-xs-12 mb-10">
			<div>
				<input
					type="submit"
					value="{{ 'lang_submit' | language }}"
					class="albutton mnabrbutton mat-accent"
					mat-button
				/>
			</div>
		</div>
	</div>
</form>

<!--steps history-->
<app-pq1-steps-history
	[allData]="allData"
	[step_no]="2"
></app-pq1-steps-history>
