<breadcrumb></breadcrumb>
<div class="themesection" *ngIf="viewdata_status">
  <section class="panel-expansion matborderno">
    <div class="card-footer footertop padb0">
      <div class="row">
        <div class="col-sm-2 col-xs-12 mb-10">
          <button
            type="button"
            class="albutton"
            routerLink="/accounts/list_invoice_reversal"
          >
            {{ "lang_goto_list" | language }}
          </button>
        </div>
        <div class="col-sm-8 col-xs-12 mb-10"></div>
        <div class="col-sm-2 col-xs-12 mb-10">
          <button
            *ngIf="viewdata_status"
            type="button"
            class="albutton"
            routerLink="/accounts/create_invoice_reversal/{{
              this.branch_id
            }}/{{ this.finance_id }}"
          >
            {{ "lang_add" | language }}
          </button>
        </div>
      </div>
    </div>
    <mat-accordion class="invoice_view">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_credit_notes" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_credit_id" | language }} :
                    <b>{{ viewdata.credit_debit_id }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_transaction_number" | language }} :
                    <b>{{ viewdata.transaction_id }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_branch" | language }} :
                    <b>{{ viewdata.branch_name }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_credebit_system_code" | language }} :<b>{{
                      viewdata.credebit_system_code
                    }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_debit_accame" | language }} :
                    <b>{{ viewdata.debit_accame }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label>
                    {{ "lang_credit_accname" | language }}:<b>{{
                      viewdata.credit_accname
                    }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_debit_account" | language }} :
                    <b>{{ viewdata.debit_account }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_credit_account" | language }} :
                    <b>{{ viewdata.credit_account }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_description" | language }} :<b>{{
                      viewdata.description
                    }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_amount_b_vat" | language }} :
                    <b>{{ viewdata.amount }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_amount_with_tax" | language }} :
                    <b>{{ viewdata.amount_with_tax }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_tax_amount" | language }} :<b>{{
                      viewdata.tax_amount
                    }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_transaction_amount" | language }} :<b>{{
                      viewdata.transaction_amount
                    }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_writer" | language }} :
                    <b>{{ viewdata.writer_name }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_financial_date" | language }} :
                    <b
                      >{{ viewdata.finance_start_date }} -
                      {{ viewdata.finance_end_date }}</b
                    ></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_remarks1" | language }} :<b>{{
                      viewdata.remarks1_invoice_id
                    }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_remarks2" | language }} :
                    <b>{{ viewdata.remarks2 }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_remarks3" | language }} :
                    <b>{{ viewdata.remarks3 }} </b>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_ondate" | language }} :
                    <b>{{ viewdata.credit_debit_created_datetime }}</b></label
                  >
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4 col-xs-12 mb-10"
                *ngIf="viewdata.transaction_updated_date"
              >
                <div class="form-group">
                  <label
                    >{{ "lang_onupdate" | language }} :
                    <b>{{ viewdata.transaction_updated_date }}</b></label
                  >
                </div>
              </div>
            </div>
            <br />
            <div class="TablePart">
              <div class="table-responsive customResponsive mb-10 tablefields">
                <div class="red pad0">
                  <b class="red">{{ "lang_items" | language }} : </b>
                </div>
                <table border="1" class="table CustomTable text-center">
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>{{ "lang_invoice_item_name" | language }}</td>
                      <td>{{ "lang_invoice_item_unit" | language }}</td>
                      <td>{{ "lang_invoice_item_quantity" | language }}</td>
                      <td>{{ "lang_invoice_item_price" | language }}</td>
                      <td>
                        {{ "lang_invoice_discount_percent" | language }} %
                      </td>
                      <td>{{ "lang_invoice_discount_amount" | language }}</td>
                      <td>{{ "lang_tax" | language }}</td>
                      <td>{{ "lang_tax_amount" | language }}</td>
                      <td>{{ "lang_invoice_item_amount" | language }}</td>
                      <td>{{ "lang_invoice_total_amount" | language }}</td>
                      <td>{{ "lang_notes" | language }}</td>
                    </tr>
                  </thead>
                  <tbody class="AppendList">
                    <tr
                      *ngFor="
                        let item of items;
                        let itemIndex = index;
                        let odd = odd;
                        let even = even
                      "
                    >
                      <td class="verticalalignmid">{{ itemIndex + 1 }}</td>
                      <td>{{ item.invoice_item_name }}</td>
                      <td>{{ item.invoice_item_unit }}</td>
                      <td>
                        {{ item.invoice_item_quantity | number: "1.0-2" }}
                      </td>
                      <td>{{ item.invoice_item_price | number: "1.0-2" }}</td>
                      <td>
                        {{ item.invoice_discount_percent | number: "1.0-2" }}
                      </td>
                      <td>
                        {{ item.invoice_item_disc_amount | number: "1.0-2" }}
                      </td>
                      <td>{{ item.label }}</td>
                      <td>
                        {{ item.invoice_item_tax_amount | number: "1.0-2" }}
                      </td>
                      <td>{{ invcalc(item) | number: "1.0-2" }}</td>
                      <td>{{ invcalc(item) | number: "1.0-2" }}</td>
                      <td>{{ item.invoice_item_description }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <div class="row" *ngIf="transactions.length > 0">
              <div class="col-md-12 mt-3">
                <div class="table-responsive dropdownyes customResponsive">
                  <p class="red">
                    <b class="red">{{ "lang_transactions" | language }} : </b>
                  </p>
                  <table border="1" class="table CustomTable">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>{{ "lang_accounts_acccode" | language }}</td>
                        <td>{{ "lang_accounts_name" | language }}</td>
                        <td>{{ "lang_debit_amount" | language }}</td>
                        <td>{{ "lang_credit_amount" | language }}</td>
                        <td>{{ "lang_description" | language }}</td>
                        <td>{{ "lang_cost_center_code" | language }}</td>
                        <td>{{ "lang_reference_no" | language }}</td>
                        <td>{{ "lang_notes" | language }}</td>
                      </tr>
                    </thead>
                    <tbody class="AppendList">
                      <tr
                        *ngFor="
                          let data of transactions;
                          let i = index;
                          let odd = odd;
                          let even = even
                        "
                        [ngClass]="{ odd: odd, even: even }"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>
                          {{ data.account_masters_code }}
                          <i
                            class="fa fa-exchange mnabricon s2icon"
                            routerLink="/accounts/statement_of_accounts/{{
                              data.account_masters_id
                            }}/"
                            matTooltip="{{
                              'lang_view_transactions' | language
                            }}"
                          ></i>
                        </td>
                        <td>
                          {{ data.asaccount_full_name }}
                        </td>
                        <td>{{ ds.addCommas(data.debit_amount) }}</td>
                        <td>{{ ds.addCommas(data.credit_amount) }}</td>
                        <td>{{ data.transaction_history_description }}</td>
                        <td *ngIf="data.cost_name">
                          {{ data.cost_name }}
                          <i
                            class="fa fa-exchange mnabricon s2icon"
                            routerLink="/accounts/statement_of_costs/{{
                              data.cost_center_id
                            }}/"
                            matTooltip="{{
                              'lang_view_transactions' | language
                            }}"
                          ></i>
                        </td>
                        <td *ngIf="!data.cost_name">----</td>
                        <td *ngIf="data.transaction_history_ref_number">
                          {{ data.transaction_history_ref_number }}
                        </td>
                        <td *ngIf="!data.transaction_history_ref_number">
                          ----
                        </td>
                        <td *ngIf="data.transaction_history_notes">
                          {{ data.transaction_history_notes }}
                        </td>
                        <td *ngIf="!data.transaction_history_notes">----</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="mpanel">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_history" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <app-account-history-log
            [account_operation]="'INVOICE_REVERSAL'"
            [id]="invoice_id"
          ></app-account-history-log>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
