<app-formc1-view
  *ngIf="form_key == 'FORM_C1'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-formc1-view>
<app-formc2-view
  *ngIf="form_key == 'FORM_C2'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-formc2-view>
<app-form-pq1-view
  *ngIf="form_key == 'FORM_PQ1'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-pq1-view>

<app-view-contract
  *ngIf="form_key == 'FORM_CT1'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-view-contract>

<app-form-ect1-view
  *ngIf="form_key == 'FORM_ECT1'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-ect1-view>
<app-form-ste-view
  *ngIf="form_key == 'FORM_STE'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-ste-view>

<!-- <app-formct1-view
	*ngIf="form_key == 'FORM_CT1'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[actionPage]="page"
></app-formct1-view> -->
<app-form-acr-view
  *ngIf="form_key == 'FORM_ACR'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-acr-view>
<app-form-acp-view
  *ngIf="form_key == 'FORM_ACP'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-acp-view>
<app-form-acj-view
  *ngIf="form_key == 'FORM_ACJ'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-acj-view>
<app-formhrj1-view
  *ngIf="form_key == 'FORM_HRJ1'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-formhrj1-view>
<app-viewDeduction
  *ngIf="form_key == 'FORM_DET1'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-viewDeduction>
<app-viewBonus
  *ngIf="form_key == 'FORM_BNS1'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-viewBonus>

<app-viewOvertime
  *ngIf="form_key == 'FORM_OVR1'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-viewOvertime>

<!-- 
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[actionPage]="page"
 -->
<app-payrole-view
  *ngIf="form_key == 'FORM_SAL'"
  [t_id]="form_request_id"
  [actionPage]="page"
></app-payrole-view>

<app-viewLoan
  *ngIf="form_key == 'FORM_HRLN1'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-viewLoan>

<app-viewViolation
  *ngIf="form_key == 'FORM_VOL1'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-viewViolation>

<!-- <app-formpq1-view
	*ngIf="form_key == 'FORM_PQ2'"
	[form_key]="form_key"
	[form_request_id]="form_request_id"
	[actionPage]="page"
></app-formpq1-view> -->
<app-formpq2-view
  *ngIf="form_key == 'FORM_PQ2'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-formpq2-view>
<app-formhrv1-view
  *ngIf="form_key == 'FORM_HRV1'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-formhrv1-view>
<app-form-ps-tr1-view
  *ngIf="form_key == 'FORM_DSR'"
  [project_data]="wholedata?.data"
  [supervision_data]="supervision_data"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-ps-tr1-view>
<app-form-wri-view
  *ngIf="form_key == 'FORM_WIR'"
  [project_data]="wholedata?.data"
  [supervision_data]="supervision_data"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-wri-view>
<app-form-msr-view
  *ngIf="form_key == 'FORM_MSR'"
  [project_data]="wholedata?.data"
  [supervision_data]="supervision_data"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-msr-view>
<app-form-sqr-view
  *ngIf="form_key == 'FORM_SQR'"
  [project_data]="wholedata?.data"
  [supervision_data]="supervision_data"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-sqr-view>
<app-form-mir-view
  *ngIf="form_key == 'FORM_MIR'"
  [project_data]="wholedata?.data"
  [supervision_data]="supervision_data"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-mir-view>
<app-form-ncr-view
  *ngIf="form_key == 'FORM_NCR'"
  [project_data]="wholedata?.data"
  [supervision_data]="supervision_data"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-ncr-view>

<app-form-cpay-view
  *ngIf="form_key == 'FORM_CPAY'"
  [project_data]="wholedata?.data"
  [supervision_data]="supervision_data"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-form-cpay-view>
<app-view-jop-offer
  *ngIf="form_key == 'FORM_JF'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-view-jop-offer>

<app-viewEPP
  *ngIf="form_key == 'FORM_EPP'"
  [form_key]="form_key"
  [form_request_id]="form_request_id"
  [actionPage]="page"
></app-viewEPP>
