<div mat-dialog-title>
  <div class="d-flex justify-content-between">
    <h4>{{ "lang_add" | language }}</h4>
    <span mat-dialog-close><i class="fa fa-times"></i></span>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-md-3 col-xs-12 mb-10">
      <ng-select
        [items]="templateLists"
        [multiple]="false"
        bindLabel="label"
        bindValue="value"
        appendTo="body"
        clearAllText="Clear"
        placeholder="{{ 'lang_template_name' | language }}"
        [(ngModel)]="templatemodel"
        (change)="load_group1_type_group2_lists('group1')"
      >
      </ng-select>
    </div>

    <div class="col-md-3 col-xs-12 mb-10">
      <ng-select
        [items]="group1List"
        [multiple]="false"
        bindLabel="label"
        bindValue="value"
        appendTo="body"
        clearAllText="Clear"
        placeholder="{{ 'lang_group' | language }} 1"
        [(ngModel)]="group1model"
        (change)="load_group1_type_group2_lists('type')"
      >
      </ng-select>
    </div>
    <div class="col-md-3 col-xs-12 mb-10">
      <ng-select
        [items]="typeLists"
        [multiple]="false"
        bindLabel="label"
        bindValue="value"
        appendTo="body"
        clearAllText="Clear"
        placeholder="{{ 'lang_group_type' | language }}"
        [(ngModel)]="typemodel"
        (change)="load_group1_type_group2_lists('group2')"
      >
      </ng-select>
    </div>
    <div class="col-md-3 col-xs-12 mb-10">
      <ng-select
        [items]="group2List"
        [multiple]="false"
        bindLabel="label"
        bindValue="value"
        appendTo="body"
        clearAllText="Clear"
        placeholder="{{ 'lang_group' | language }} 2"
        [(ngModel)]="group2model"
        (change)="get_all_forms_for_transactions()"
      >
      </ng-select>
    </div>
  </div>
  <hr class="orclass mt-2 mb-2" />
  <div class="row">
    <div class="col-md-12 mb-10">
      <input
        type="text"
        class="form-control almnabrformcontrol"
        (keyup)="search_by()"
        [(ngModel)]="searchKey"
        placeholder="{{ 'lang_search' | language }}"
      />
    </div>
  </div>
  <div
    class="row"
    *ngIf="
      wholedata?.records?.length &&
      ((typemodel && group1model && group2model) || searchKey)
    "
  >
    <div class="col-md-12">
      <div class="table-responsive dropdownyes customResponsive">
        <table border="1" class="table CustomTable">
          <thead>
            <tr>
              <td>#</td>
              <td>{{ "lang_platform_code_system" | language }}</td>
              <td>{{ "lang_platform" | language }}</td>
              <td>{{ "lang_template_name" | language }}</td>
              <td>{{ "lang_group_one" | language }}</td>
              <td>{{ "lang_group_type" | language }}</td>
              <td>{{ "lang_group_two" | language }}</td>
              <td>{{ "lang_action" | language }}</td>
            </tr>
          </thead>
          <tbody *ngIf="wholedata?.records?.length" class="AppendList">
            <tr
              *ngFor="
                let list of wholedata.records;
                let i = index;
                let odd = odd;
                let even = even
              "
              [ngClass]="{ odd: odd, even: even }"
            >
              <td>{{ i + 1 }}</td>
              <td style="direction: ltr !important">
                {{ list.template_platform_code_system }}
              </td>
              <td>{{ list.platformname }}</td>
              <td>{{ list.templatename }}</td>
              <td>{{ list.group1name }}</td>
              <td>{{ list.typename }}</td>
              <td>{{ list.group2name }}</td>
              <td>
                <i
                  matTooltip="{{ 'lang_create' | language }}"
                  (click)="add_initial_delivery_platform(list)"
                  class="fa fa-link fa-icon mnabricon s2icon"
                ></i>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!wholedata?.records?.length" class="AppendList">
            <tr class="odd">
              <td colspan="9" align="center">{{ nodata }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      wholedata?.records?.length &&
      ((typemodel && group1model && group2model) || searchKey)
    "
    class="card-footer padb0"
  >
    <div class="row responsive_pagination">
      <div
        class="col-md-3 col-sm-3 mb-10"
        style="height: fit-content; margin: auto 0"
      >
        {{ "lang_total" | language }} : {{ wholedata?.page?.total_records }}
      </div>
      <div
        class="col-md-6 col-sm-6 mb-10"
        style="display: flex; justify-content: center"
      >
        <ngb-pagination
          class="m-auto"
          [collectionSize]="wholedata?.page?.total_records"
          [rotate]="true"
          [ellipses]="false"
          [maxSize]="3"
          [boundaryLinks]="true"
          [(page)]="page_no"
          [pageSize]="page_size"
          (pageChange)="spinner.show(); page_number($event)"
        ></ngb-pagination>
      </div>
      <div
        class="col-md-3 col-sm-3 mb-10"
        style="display: flex; justify-content: end; margin: auto"
      >
        <mat-select
          style="max-width: 150px"
          [(ngModel)]="page_size"
          (selectionChange)="spinner.show(); by_page_size($event.value)"
        >
          <mat-option class="text-center" value="10"
            >10 {{ "lang_per_page" | language }}
          </mat-option>
          <mat-option class="text-center" value="20"
            >20 {{ "lang_per_page" | language }}
          </mat-option>
          <mat-option class="text-center" value="50"
            >50 {{ "lang_per_page" | language }}
          </mat-option>
          <mat-option class="text-center" value="100"
            >100 {{ "lang_per_page" | language }}</mat-option
          >
          <mat-option class="text-center" value="500"
            >500 {{ "lang_per_page" | language }}</mat-option
          >
        </mat-select>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
  <div class="col-md-4 col-xs-12">
    <button
      type="button"
      class="albutton mnabrbutton mat-accent"
      mat-button
      mat-dialog-close
    >
      {{ "lang_close" | language }}
    </button>
  </div>
  <!-- <div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="add_initial_delivery_platform()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div> -->
</mat-dialog-actions>
