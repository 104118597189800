import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-add-device",
  templateUrl: "./add-device.component.html",
  styleUrls: ["./add-device.component.scss"],
})
export class AddDeviceComponent implements OnInit {
  modalRefA: BsModalRef;
  form: FormGroup;
  form_request_id: any;
  request: any = [];
  allData: any;
  lang_key = localStorage.getItem("lang_key") || "en";

  groups: any = [];
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    private snackBar: MatSnackBar,
    public lang: LanguagePipe,
    private fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public router: Router,
    private dialogRef: MatDialogRef<AddDeviceComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.buildform();
  }

  buildform() {
    this.form = this.fb.group({
      device_name: [""],
      device_password: [""],
      device_ip: [""],
      device_port: [""],
      device_key: [""],
    });
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  submitForm() {
    this.spinner.show();
    let formData = new FormData();
    formData.append("device_name", this.form.value.device_name);
    formData.append("device_password", this.form.value.device_password);
    formData.append("device_ip", this.form.value.device_ip);
    formData.append("device_port", this.form.value.device_port);
    formData.append("device_key", this.form.value.device_key);

    this.ds.post("human_resources/add_device", formData).subscribe((res) => {
      this.spinner.hide();
      if (res?.status) {
        this.dialogRef.close(true);
      }
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
