<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_notifications" | language }}</h4>
		<span (click)="closeModal()"><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<virtual-scroller #scroll [items]="notifications" (vsEnd)="fetchMore($event)" >
        <div *ngFor="let noti of scroll.viewPortItems; let i = index; " style="padding: 5px 10px;">
          <div class="notification mb-1">
            <div style="display: flex;">
                <div style="margin: 0 5px; display: flex; align-items: center;justify-content: center;">
                    <img src="{{noti?.noty_messages_image}}" alt="" width="30px">
                  </div>
                  <div class="d-flex flex-column">
                    <p style="font-size: 14px; font-weight: 600;">{{ noti.noty_messages_title }}</p>
                    <p style="font-size: 12px; ">{{ noti.noty_messages_body }}</p>
                  </div>
            </div>
            <small
              style="text-align: end; font-size: 10px; font-weight: 600;display: block;"
              >{{ getTime(noti.noty_messages_date_time) }}</small
            >
            <div class="notification-overlay" (click)="onNotiClick(noti)">
              <i class="fa fa-times"  (click)="deleteNoti($event, noti)"></i>
            </div>
          </div>
        </div>
        <div style="padding: 5px 10px;" *ngIf="loading">
          <ngx-skeleton-loader  count="7" [theme]="{ 'border-radius': '3px', height: '70px' }"></ngx-skeleton-loader>
        </div>
        <div style="padding: 5px 10px;" *ngIf="!loading && (notifications.length == 0)">
          <p style="    text-align: center;
          font-size: 17px;">{{"lang_no_data" | language}}</p>
        </div>
    </virtual-scroller>
	
</mat-dialog-content>

