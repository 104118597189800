<form [formGroup]="form" (ngSubmit)="completeStep7()">
	<div class="card">
		<div class="card-header">
			{{ "lang_evaluation_result" | language }}
			<i
				style="padding: 0 10px"
				class="fa fa-info-circle orangestatus-color"
				matTooltip="{{ 'lang_evaluation_result_tooltip' | language }}"
			></i>
		</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
						<label for="" class="bold">
							{{ "lang_evaluation_result" | language }}</label
						>
						<select
							class="form-control almnabrformcontrol"
							formControlName="evaluation_result"
						>
							<option value="A">A</option>
							<option value="B">B</option>
							<option value="C">C</option>
							<option value="D">D</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 TableParts">
						<p
							class="mb-10 bold"
							*ngIf="
								form.get('evaluation_result').value == 'A' ||
								form.get('evaluation_result').value == 'B'
							"
						>
							{{ "lang_consultant_recomandations" | language }}
						</p>
						<p
							class="mb-10 bold"
							*ngIf="
								form.get('evaluation_result').value == 'C' ||
								form.get('evaluation_result').value == 'D'
							"
						>
							{{ "lang_notes" | language }}
						</p>
						<div
							class="table-responsive customResponsive tablefields quotationitemtable"
							*ngIf="
								form.get('evaluation_result').value == 'A' ||
								form.get('evaluation_result').value == 'B'
							"
						>
							<table
								border="1"
								class="table CustomTable"
								width="100%"
								formArrayName="Evaludation_Result"
							>
								<thead>
									<tr>
										<td style="padding: 5px" class="text-center">#</td>
										<td style="padding: 5px" class="text-center">
											<mat-icon
												class="mnabricon"
												matTooltip="{{ 'lang_add' | language }}"
												(click)="add_recomandations()"
												>add_box</mat-icon
											>
										</td>
										<td style="padding: 5px" class="text-center">
											{{ "lang_title" | language }} <span class="red">*</span>
										</td>
										<td style="padding: 5px" class="text-center">
											{{ "lang_attachments" | language }}
										</td>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="
											let assistant of _recomandationsgroup.controls;
											let i = index;
											let odd = odd;
											let even = even
										"
										[formGroupName]="i"
									>
										<td
											class="verticalalignmid text-center"
											style="padding: 5px; word-break: break-all"
										>
											{{ i + 1 }}
										</td>
										<td class="verticalalignmid text-center">
											<mat-icon
												(click)="remove_recomandations(i)"
												*ngIf="
													other_recomandations.controls[i].get('delete')?.value
												"
												class="mnabricon red"
												matTooltip="{{ 'lang_delete' | language }}"
												>delete_forever</mat-icon
											>
										</td>
										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											<input
												*ngIf="
													other_recomandations.controls[i].get('delete').value
												"
												type="text"
												formControlName="title"
												placeholder="{{ 'lang_description' | language }}"
												autocomplete="off"
												class="form-control almnabrformcontrol"
											/>
											<p
												*ngIf="
													!other_recomandations.controls[i].get('delete').value
												"
											>
												{{
													other_recomandations.controls[i].get("title").value
												}}
											</p>
										</td>

										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											<app-dynamic-files-control
												(getAttachments)="getAttachments($event)"
												[rawIndex]="rawIndex"
												[actionMode]="'ADD'"
												[required]="true"
												(click)="rawIndex = i"
											></app-dynamic-files-control>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div
							class="table-responsive customResponsive tablefields quotationitemtable"
							*ngIf="
								form.get('evaluation_result').value == 'C' ||
								form.get('evaluation_result').value == 'D'
							"
						>
							<table
								border="1"
								class="table CustomTable"
								width="100%"
								formArrayName="Evaludation_Result"
							>
								<thead>
									<tr>
										<td style="padding: 5px" class="text-center">#</td>

										<td style="padding: 5px" class="text-center">
											{{ "lang_title" | language }} <span class="red">*</span>
										</td>
										<td style="padding: 5px" class="text-center">
											{{ "lang_result" | language }} <span class="red">*</span>
										</td>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="
											let note of notesTable;
											let i = index;
											let odd = odd;
											let even = even
										"
									>
										<td
											class="verticalalignmid text-center"
											style="padding: 5px; word-break: break-all"
										>
											{{ i + 1 }}
										</td>

										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											{{ note?.extra1_title }}
										</td>

										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											{{ note?.extra1_result }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="row pt-3">
					<div class="col-sm-12">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<div class="row">
				<div class="col-sm-8 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<input
						type="submit"
						value="{{ 'lang_submit' | language }}"
						class="albutton mnabrbutton mat-accent"
						mat-button
					/>
				</div>
			</div>
		</div>
	</div>
</form>
