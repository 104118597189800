import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";

@Component({
	selector: "app-template-dialog",
	templateUrl: "./template-dialog.component.html",
	styleUrls: ["./template-dialog.component.scss"],
})
export class TemplateDialogComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key");
	projects_work_area_id: any;

	templateList = [];
	group1List = [];
	typeList = [];
	group2List = [];

	template_id: any;
	group1_id: any;
	group_type_id: any;
	group2_id: any;

	constructor(
		public dialogRef: MatDialogRef<TemplateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private ds: DataService,
		private spinner: PreloaderService
	) {
		this.projects_work_area_id = data?.projects_work_area_id;
		this.getTemplateList();
	}

	ngOnInit(): void {}

	public getTemplateList() {
		this.spinner.show();
		this.templateList = [];
		let formData = new FormData();
		formData.append("lang_key", this.lang_key);
		formData.append("projects_work_area_id", this.projects_work_area_id);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"p/get_templates_for_transactions"
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.templateList = res.records;
				}
			});
	}

	public load_group1_type_group2_lists(type?) {
		let sendAPI = false;
		if (type == "group1") {
			this.group1List = this.typeList = this.group2List = [];
			if (this.template_id) {
				sendAPI = true;
			}
		} else if (type == "type") {
			this.typeList = this.group2List = [];
			if (this.template_id && this.group1_id) {
				sendAPI = true;
			}
		} else if (type == "group2") {
			this.group2List = [];
			if (this.template_id && this.group1_id && this.group_type_id) {
				sendAPI = true;
			}
		}

		if (sendAPI) {
			this.spinner.show();
			let param = new FormData();
			param.append("projects_work_area_id", this.projects_work_area_id || "");
			param.append("template_id", this.template_id?.value || "");
			if (type == "group1") {
				param.append("required_type", "group1");
				param.append("group1", "");
				param.append("type", "");
			}
			if (type == "type") {
				param.append("required_type", "type");
				param.append("group1", this.group1_id?.value || "");
				param.append("type", "");
			}
			if (type == "group2") {
				param.append("required_type", "group2");
				param.append("group1", this.group1_id?.value || "");
				param.append("type", this.group_type_id?.value || "");
			}

			this.ds
				.getActionByUrl(
					this.ds.formData2string(param),
					"pforms/get_group1_type_group2"
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							if (type == "group1") {
								this.group1List = res.records;
							} else if (type == "type") {
								this.typeList = res.records;
							} else if (type == "group2") {
								this.group2List = res.records;
							}
						}
					},
					(error) => {
						this.spinner.hide();
					}
				);
		} else {
			if (type == "group1") {
				this.template_id = null;
				this.group1_id = null;
				this.group_type_id = null;
				this.group2_id = null;
			} else if (type == "type") {
				this.group1_id = null;
				this.group_type_id = null;
				this.group2_id = null;
			} else if (type == "group2") {
				this.group_type_id = null;
				this.group2_id = null;
			}
		}
	}

	saveTemplateFilter() {
		this.dialogRef.close({
			template: this.template_id,
			group1: this.group1_id,
			group_type: this.group_type_id,
			group2: this.group2_id,
		});
	}
}
