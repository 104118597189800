import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ncr-step15',
  templateUrl: './ncr-step15.component.html',
  styleUrls: ['./ncr-step15.component.scss']
})
export class NcrStep15Component implements OnInit {
  @Input() allData;
  constructor() { }

  ngOnInit(): void {
  }

}
