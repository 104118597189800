<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_group" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="p-2">
		<div class="row">
			<div class="col-12 col-sm-6">
				<p class="bold">Group Name</p>
				<p>{{ group?.group_name }}</p>
			</div>
			<div class="col-12 col-sm-6">
				<p class="bold">Group Email</p>
				<p>{{ group?.group_email }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="TableParts">
					<div
						class="table-responsive customResponsive mb-10 tablefields"
						style="min-height: 120px"
					>
						<table border="1" class="table CustomTable text-center">
							<thead>
								<tr>
									<td>#</td>
									<td style="width: 30%">
										{{ "lang_human_resources_fname_english" | language }}
									</td>
									<td>
										{{ "lang_human_resources_lname_english" | language }}
									</td>
									<td style="width: 30%">
										{{ "lang_username" | language }}
									</td>
									<td>
										{{ "lang_action" | language }}
									</td>
								</tr>
							</thead>
							<tbody class="AppendList">
								<tr *ngFor="let item of group?.users; let itemIndex = index">
									<td class="verticalalignmid pad0">{{ itemIndex + 1 }}</td>
									<td class="pad0">{{ item?.firstname_english }}</td>
									<td class="pad0">{{ item?.lastname_english }}</td>
									<td class="pad0">{{ item?.username }}</td>
									<td class="pad0">
										<i
											class="fa fa-trash red"
											matTooltip="{{ 'lang_delete' | language }}"
											(click)="removeUser(item?.user_id)"
										></i>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
