import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { QtAddEditComponent } from "./qt-add-edit/qt-add-edit.component";

@Component({
	selector: "app-quotation-types",
	templateUrl: "./quotation-types.component.html",
	styleUrls: ["./quotation-types.component.scss"],
})
export class QuotationTypesComponent implements OnInit {
	searchKey = "";
	pageno = 1;
	pagesize = "10";
	collectionSize: any;
	typeList = [];
	nodata: string = this.lang.transform("lang_loading");
	constructor(
		private ds: DataService,
		private spinner: PreloaderService,
		private lang: LanguagePipe,
		private sweetAlert: SweetAlertService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.getTypeList();
	}

	getTypeList() {
		this.spinner.show();
		// let formData = new FormData();
		// formData.append("search_key", this.searchKey || "");
		this.ds
			.get(`quotation_setting/get_all_quotation_types`)
			.subscribe((res: any) => {
				this.spinner.hide();
				if (res.status) {
					this.typeList = res?.records;
					// this.collectionSize = res?.page?.total_records;
				} else {
					this.typeList = [];
					// this.collectionSize = 0;
					this.nodata = res.error;
				}
			});
	}
	onAddEditType(data?) {
		const dialogRef = this.dialog.open(QtAddEditComponent, {
			width: "500px",
			data: {
				type_data: data,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.getTypeList();
			}
		});
	}

	deleteType(id) {
		this.sweetAlert
			.confirmMessage(
				this.lang.transform("lang_are_you_sure"),
				this.lang.transform("lang_yes")
			)
			.then((resConfirm) => {
				if (resConfirm.isConfirmed) {
					this.spinner.show();
					this.ds
						.post(`quotation_setting/delete_quotation_types/${id}`, {})
						.subscribe((res) => {
							this.spinner.hide();
							if (res.status) {
								this.sweetAlert.successToast(
									this.lang.transform("lang_success"),
									2000
								);
								this.getTypeList();
							} else {
								this.sweetAlert.errorToast(res.error, 2000);
							}
						});
				}
			});
	}
}
