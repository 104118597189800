import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Observable, Subscription } from "rxjs";
import { RefuseReasonComponent } from "../../refuse-reason/refuse-reason.component";
import { ShowDeductionsComponent } from "../../show-deductions/show-deductions.component";

@Component({
  selector: "app-step-five",
  templateUrl: "./step-five.component.html",
  styleUrls: ["./step-five.component.scss"],
})
export class StepFiveComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key") || "en";
  user_id = this.ls.getItem("user_id");
  @Input() stepper: MatStepper;
  @Input() t_id: any = "";
  @Input() requestInfo: any = "";
  @Input() refresh!: Observable<void>;
  @Output() resfreshUsers = new EventEmitter<any>();
  @Output() resfreshView = new EventEmitter<any>();
  @Output() refreshpage = new EventEmitter<any>();
  @Input() reviewers: any[] = [];
  private subscriptions = new Subscription();
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    private _sweetAlertService: SweetAlertService,
    private spinner: PreloaderService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.refresh.subscribe(() => this.get_salary_form_employees())
    );
  }

  getReviewerId(user_id) {
    return this.reviewers.find((r: any) => r.user_id == user_id).reviewer_id;
  }
  @Input() usersList = [];
  @Input() responseData: any = "";
  get_salary_form_employees() {
    this.spinner.show();
    this.ds
      .getActionByUrl(
        [],
        `form/FORM_SAL/get_salary_form_employees/${this.t_id}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.responseData = res;
            this.allSelected = false;
            this.usersList = res.records.map((rec) => {
              let action = rec.reviews.map((rev) => {
                return {
                  action: rev.value == "Reject" ? false : true,
                  name: "Eslam Ahmed 2",
                  date: "20/12/2020",
                  reason: rev.comment,
                };
              });
              return {
                ...rec,
                details: "",
                select_status: "unselected",
                reviews: action,
                employee_name:
                  rec.firstname_english + " " + rec.lastname_english,
              };
            });
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(res?.error);
            this.usersList = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.usersList = [];
          this._sweetAlertService.errorMessage(err?.error.error);
        }
      );
  }

  sign_to_hrm() {
    this.spinner.show();
    this.ds
      .postActionByUrl([], `form/FORM_SAL/sign_to_hrm/${this.t_id}`)
      .subscribe(
        (res) => {
          if (res?.status) {
            this.resfreshView.emit();
            /* this._sweetAlertService.successToast(res?.msg); */
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(res?.error);
          }
        },
        (err) => {
          this.spinner.hide();

          this._sweetAlertService.errorMessage(err?.error.error);
        }
      );
  }

  AtLeastOneSelected() {
    let selected = false;
    for (let index = 0; index < this.usersList.length; index++) {
      const element = this.usersList[index];
      if (element.select_status == "selected") {
        selected = true;
        break;
      }
    }
    return selected;
  }
  AllSelected() {
    let selected = true;
    for (let index = 0; index < this.usersList.length; index++) {
      const element = this.usersList[index];
      if (element.select_status == "unselected") {
        selected = false;
        break;
      }
    }
    return selected;
  }
  employee_details(emp_id, index) {
    if (this.usersList[index].details) {
      this.makeItemCollapsed(index);
      return 0;
    }
    this.spinner.show();
    this.ds
      .getActionByUrl([], `form/FORM_SAL/employee_details/${emp_id}`)
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            /* this.usersList = res.records.map(rec=>{
          return {
            ...rec,
            details:''
          }
        }) */
            this.makeItemCollapsed(index);
            this.usersList[index].details = res.records[0];
          } else {
            this.spinner.hide();
            /* this.usersList = []; */
            this._sweetAlertService.errorMessage(res?.error);
          }
        },
        (err) => {
          this.spinner.hide();
          this._sweetAlertService.errorMessage(err?.error.error);
          /*       this.usersList = []; */
        }
      );
  }
  actionsArr = [
    {
      action: true,
      name: "Eslam Ahmed",
      date: "20/12/2020",
      reason: "TEST test test test test",
    },
    {
      action: false,
      name: "Eslam Ahmed 2",
      date: "20/12/2020",
      reason: "TEST test test test test",
    },
    {
      action: false,
      name: "Eslam Ahmed 3",
      date: "20/12/2020",
      reason: "TEST test test test test",
    },
    {
      action: true,
      name: "Eslam Ahmed 4",
      date: "20/12/2020",
      reason: "TEST test test test test",
    },
  ];

  makeItemCollapsed(index) {
    this.usersList[index].collapsed = !this.usersList[index].collapsed;
  }
  isLinear = true;
  makeTooltip(item, reject = true) {
    if (reject) {
      let data = `${this.lang.transform("lang_name")}: ${item.name}\n 
      ${this.lang.transform("lang_date")}: ${item.date}\n 
      ${this.lang.transform("lang_reason")}: ${
        item.reason ? item.reason : "--"
      }\n`;
      return data;
    } else {
      let data = `${this.lang.transform("lang_name")}: ${item.name}\n 
      ${this.lang.transform("lang_date")}: ${item.date}\n`;
      return data;
    }
  }

  allSelected = false;
  checkEachItemHR(e, index) {
    if (e?.target.checked) {
      this.usersList[index].select_status = "selected";
    } else {
      this.usersList[index].select_status = "unselected";
    }
    this.checkSelect();
  }

  checkSelect() {
    let allLocalSelected = true;
    for (let index = 0; index < this.usersList.length; index++) {
      const element = this.usersList[index];
      if (element.select_status == "unselected") {
        allLocalSelected = false;
      }
    }
    if (allLocalSelected) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  selectAll(e) {
    if (e?.target.checked) {
      this.usersList = this.usersList.map((t) => {
        return {
          ...t,
          select_status: "selected",
        };
      });

      this.allSelected = true;
    } else {
      this.usersList = this.usersList.map((t) => {
        return {
          ...t,
          select_status: "unselected",
        };
      });
    }
    /*   this.oneSelected = false
    this.allSelected = false */
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  update_configuration_step() {
    this.spinner.show();
    let formData = new FormData();
    formData.append(`Configration_Step`, "select_reviewers");
    this.ds
      .post(`form/FORM_SAL/update_configuration_step/${this.t_id}`, formData)
      .subscribe(
        (data) => {
          if (data.status) {
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(data.error);
          }
        },
        (error) => {
          this._sweetAlertService.errorMessage(error?.error?.error);
          this.spinner.hide();
        }
      );
  }
  confirm_reviews() {
    this.ds.post(`form/FORM_SAL/confirm_reviews/${this.t_id}`, {}).subscribe(
      (data) => {
        if (data.status) {
          this._sweetAlertService.successToast(
            this.lang.transform(data.msg),
            2000
          );
          this.refreshpage.emit();
          this.spinner.hide();
          if (data.reviewd_status) {
            this.sign_to_hrm();
          } else {
            this.resfreshView.emit();
          }
        } else {
          this.spinner.hide();
          this._sweetAlertService.errorMessage(data.error || data.msg);
        }
      },
      (error) => {
        this._sweetAlertService.errorMessage(error?.error?.error);
        this.spinner.hide();
      }
    );
  }

  sendReview(formData) {
    this.ds
      .post(
        `form/FORM_SAL/add_review/${this.t_id}/${this.getReviewerId(
          this.user_id
        )}`,
        formData
      )
      .subscribe(
        (data) => {
          if (data.status) {
            this._sweetAlertService.successToast(
              this.lang.transform(data.msg),
              2000
            );
            this.spinner.hide();
            this.resfreshUsers.emit();
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(data.error || data.msg);
          }
        },
        (error) => {
          this._sweetAlertService.errorMessage(error?.error?.error);
          this.spinner.hide();
        }
      );
  }

  add_review(type, item, single = true) {
    if (single) {
      if (type == "Reject") {
        const dialogRef = this.dialog.open(RefuseReasonComponent, {
          width: "700px",
          data: {},
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result.status) {
            let formData = new FormData();
            formData.append("reviews[1][comment]", result.reason);
            formData.append(
              "reviews[1][employee_number]",
              item.employee_number
            );
            formData.append("reviews[1][value]", type);
            this.sendReview(formData);
          }
        });
      } else {
        let formData = new FormData();
        formData.append("reviews[1][comment]", "");
        formData.append("reviews[1][employee_number]", item.employee_number);
        formData.append("reviews[1][value]", type);
        this.sendReview(formData);
      }
    } else {
      if (type == "Reject") {
        const dialogRef = this.dialog.open(RefuseReasonComponent, {
          width: "700px",
          data: {},
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result.status) {
            let formData = new FormData();
            let currentIndex = 1;
            for (let index = 0; index < this.usersList.length; index++) {
              const element = this.usersList[index];
              if (element.select_status == "selected") {
                formData.append(
                  `reviews[${currentIndex}][comment]`,
                  result.reason
                );
                formData.append(
                  `reviews[${currentIndex}][employee_number]`,
                  element.employee_number
                );
                formData.append(`reviews[${currentIndex}][value]`, type);
                currentIndex++;
              }
            }

            this.sendReview(formData);
          }
        });
      } else {
        let formData = new FormData();
        let currentIndex = 1;
        for (let index = 0; index < this.usersList.length; index++) {
          const element = this.usersList[index];
          if (element.select_status == "selected") {
            formData.append(`reviews[${currentIndex}][comment]`, "");
            formData.append(
              `reviews[${currentIndex}][employee_number]`,
              element.employee_number
            );
            formData.append(`reviews[${currentIndex}][value]`, type);
            currentIndex++;
          }
        }

        this.sendReview(formData);
      }
    }
  }
  userTransactions = [];
  all_financial_transactions(emp_id, info, type) {
    this.spinner.show();
    this.ds
      .getActionByUrl(
        [],
        `form/FORM_SAL/all_financial_transactions/${this.t_id}/${emp_id}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.userTransactions = res.records;

            this.openDeduction(info, type);
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(res.error);
          }
        },
        (err) => {
          this.spinner.hide();
          this._sweetAlertService.errorMessage(err?.error?.error);
        }
      );
  }
  openDeduction(info, type) {
    const dialogRef = this.dialog.open(ShowDeductionsComponent, {
      width: "700px",
      data: {
        info: info,
        userTransactions: this.userTransactions,
        t_id: this.t_id,
        type: type,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.get_salary_form_employees();
      }
    });
  }
}
