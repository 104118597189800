import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { WNewContactComponent } from "../w-new-contact/w-new-contact.component";

@Component({
	selector: "app-w-contacts-modal",
	templateUrl: "./w-contacts-modal.component.html",
	styleUrls: ["./w-contacts-modal.component.scss"],
})
export class WContactsModalComponent implements OnInit {
	contactsModalTitle = "";
	contactList = [];
	isSendedContact = false;
	contactTypes = [
		{
			label: "Technical",
			value: "technical",
		},
		{
			label: "Customer",
			value: "customer",
		},
		{
			label: "Legal",
			value: "legal",
		},
	];
	contactType = null;
	selectedContact;
	constructor(
		private dialogRef: MatDialogRef<WContactsModalComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private lang: LanguagePipe,
		private ds: DataService,
		private spinner: PreloaderService
	) {
		if (data?.type == "new_chat") {
			this.contactsModalTitle = this.lang.transform("lang_new_chat");
		} else if (data?.type == "send_contact") {
			this.contactsModalTitle = this.lang.transform("lang_send_contact");
		}
	}

	ngOnInit(): void {
		this.getAllContacts();
	}

	getAllContacts() {
		this.spinner.show();
		this.ds.get("WhatsappApi/getAllContacts").subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.contactList = res?.contactList;
				} else {
					this.contactList = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.contactList = [];
			}
		);
	}

	onContactSelected(contact) {
		this.selectedContact = contact;
		if (this.data.type == "send_contact") {
			this.isSendedContact = true;
		} else {
			this.dialogRef.close(contact);
		}
	}

	onOpenNewChat() {
		this.dialogRef.close();
		this.dialog.open(WNewContactComponent, {
			width: "430px",
		});
	}

	onSelectContactType() {
		if (this.contactType) {
			this.dialogRef.close({
				contact: this.selectedContact,
				type: "send_contact",
				contact_type: this.contactType,
			});
		}
	}
}
