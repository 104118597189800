import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { MbscDatepicker } from "@mobiscroll/angular";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import moment from "moment";
import { Checklist } from "../../models/checklist";
import { Task } from "../../models/task";
import { TaskMembers } from "../../models/task-members";

@Component({
	selector: "app-ep-task-view",
	templateUrl: "./ep-task-view.component.html",
	styleUrls: ["./ep-task-view.component.scss"],
})
export class EpTaskViewComponent implements OnInit {
	// public Editor = ClassicEditor;
	lang_key = localStorage.getItem("lang_key") || "en";
	userId = this.localstorageService.getItem("user_id");
	user_avatar = this.localstorageService.getItem("user_avatar");
	baseUrl = environment.SERVER_ORIGIN;
	ticketID = this.route.snapshot.paramMap.get("ticketId");
	task_id = this.route.snapshot.paramMap.get("taskId");
	task: Task;
	task_members: TaskMembers[];
	task_members_full_data: any;
	memberCardData: TaskMembers;
	task_dates: any = [];
	task_reminder: any;
	range = new FormGroup({
		start: new FormControl(null, Validators.required),
		end: new FormControl(null, Validators.required),
	});
	point_date_range = new FormGroup({
		start: new FormControl(null, Validators.required),
		end: new FormControl(null, Validators.required),
	});
	add_checklist = "";
	search_point_users = [];
	add_check_point_users = [];
	add_point_attachfile = new FormControl(null);
	add_point_title: string = this.lang.transform("lang_select_files");
	checklist_arr: Array<Checklist> = [];
	task_members_selected = [];
	task_members_list = [];
	task_attachments = [];
	taskAttachmentsSlice = 2;
	isCommentsLoading = false;
	taskComments = [];
	write_comment = {
		description: "",
	};
	write_reply = [];
	timelineData = [];
	checklist_editable = [];
	task_status_done = null;
	status_done_list = [];
	@ViewChild("task_datesPicker") task_datesPicker: MbscDatepicker;
	@ViewChild("task_reminderPicker") task_reminderPicker: MbscDatepicker;
	checkIsDisabled = false;
	group_id: any;
	group_type_id: any;
	selectedPointForView: any;
	showPointView = false;
	selectedPointForUpdate: any;
	parent_check_id_update: any;
	showPointAdd = false;
	showPointUpdate = false;
	constructor(
		private spinner: PreloaderService,
		private ds: DataService,
		private localstorageService: LocalStorage,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.task = {
			task_id: "",
			title: "",
			task_status: "",
			task_time: "",
			task_status_name: "",
			description: "",
			start_date: "",
			end_date: "",
			user_add_id: "",
			task_progress: "",
			ticket_no: "",
			ticket_id: "",
			full_task_number: "",
			task_no: "",
		};
		// this.getFormLists();
		this.getTaskData();
		// this.getTaskMembers();
		this.getChecklist();
		// this.getTaskComments();
		// this.getTimelineData();
	}

	ngOnInit(): void {
		moment.locale("en");
	}
	//get task data
	getTaskData() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		formData.append(
			"token_extarnal",
			localStorage.getItem("eploggedin") == "1"
				? this.localstorageService.getItem("eptoken")
				: ""
		);
		this.ds.post("tasks/get_task_row_extarnal", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.task.task_id = res?.data?.task_id;
					this.task.title = res?.data?.title;
					this.task.description = res?.data?.task_detailes;
					this.task.task_status = res?.data?.task_status;
					this.task.task_time = res?.data?.task_time;
					this.task.start_date = res?.data?.start_date;
					this.task.end_date = res?.data?.end_nearly;
					this.task.user_add_id = res?.data?.user_add_id;
					this.task.task_progress = res?.data?.progres;
					this.task.ticket_no = res?.data?.ticket_no;
					this.task.ticket_id = res?.data?.ticket_id;
					this.task.full_task_number = res?.data?.full_task_number;
					this.task.task_no = res?.data?.task_no;
					this.task_attachments = res?.data?.files;
					this.task_status_done = res?.data?.task_status_done;
					this.group_id = res?.data?.group_id;
					this.group_type_id = res?.data?.group_type_id;
					this.task_dates = [];
					if (res?.data?.start_date) {
						this.task_dates[0] = new Date(res?.data?.start_date);
					}
					if (res?.data?.end_nearly) {
						this.task_dates[1] = new Date(res?.data?.end_nearly);
					}
					if (res?.data?.reminder_date) {
						this.task_reminder = new Date(res?.data?.reminder_date);
					}
					switch (res?.data?.task_status) {
						case "1": {
							this.task.task_status_name = "New";
							break;
						}
						case "2": {
							this.task.task_status_name = "In Progress";
							break;
						}
						case "3": {
							this.task.task_status_name = "Confirm";
							break;
						}
						case "4": {
							this.task.task_status_name = "Done";
							break;
						}
					}
					this.ds.taskDates.next({
						startDate: this.task.start_date,
						endDate: this.task.end_date,
					});
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
					this.router.navigate([`/tasks/view/${this.ticketID}`]);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
				this.router.navigate([`/tasks/view/${this.ticketID}`]);
			}
		);
	}
	//get checklist
	getChecklist() {
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		formData.append(
			"token_extarnal",
			localStorage.getItem("eploggedin") == "1"
				? this.localstorageService.getItem("eptoken")
				: ""
		);
		this.ds.post("tasks/get_points_task_checks_extarnal", formData).subscribe(
			(res) => {
				if (res?.status) {
					this.checklist_arr = res?.data?.map((el) => {
						return {
							id: el?.check_id,
							title: el?.title,
							progress: el?.progres,
							chicklist_items: el?.sub_checks?.map((item) => {
								return {
									...item,
									show_details: false,
									checked: item?.is_done == "2" ? true : false,
								};
							}),
							is_add: false,
							user_add_id: el?.user_add_id,
							hideChecked: true,
							char_item: el?.char_item,
						};
					});
				} else {
					this.checklist_arr = [];
				}
			},
			(err) => {
				this.checklist_arr = [];
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	//start downloadFile (task attachments)
	// downloadFile(file_url, title?) {
	// 	this.spinner.show();
	// 	this.ds.get(file_url).subscribe(
	// 		(res) => {
	// 			this.spinner.hide();
	// 			if (res?.status) {
	// 				this.ds.downloadFile(res, title);
	// 			}
	// 		},
	// 		(err) => {
	// 			this.spinner.hide();
	// 		}
	// 	);
	// }
	//end downloadFile (task attachments)

	viewChecklistDetails(item) {
		this.selectedPointForView = item;
		this.showPointAdd = false;
		this.showPointUpdate = false;
		this.showPointView = true;
	}

	navigateTo(url) {
		this.router.navigate([url]);
	}

	//view point checklist
	onHidePointView(event) {
		if (event) {
			this.showPointView = false;
		}
	}
}
