<div mat-dialog-title>
  <div class="d-flex justify-content-between">
    <h4>{{ "lang_cost_center" | language }}</h4>
    <span mat-dialog-close><i class="fa fa-times"></i></span>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div
    class="table-responsive dropdownyes customResponsive tablefields"
    *ngIf="costCenters?.length"
  >
    <table border="1" class="table CustomTable">
      <thead>
        <tr>
          <th>#</th>
          <th>{{ "lang_account_name" | language }}</th>
          <th>{{ "lang_credit_amount" | language }}</th>
          <th>{{ "lang_debit_amount" | language }}</th>
          <th>{{ "lang_cost_name" | language }}</th>
          <th>{{ "lang_amount_b_vat" | language }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let item of costCenters | slice: (page - 1) * size:page * size;
            let i = index;
            let odd = odd;
            let even = even
          "
        >
          <td>{{ i + 1 }}</td>
          <td>{{ item?.account_name || "---" }}</td>
          <td>{{ item?.credit_amount || "---" }}</td>
          <td>{{ item?.debit_amount || "---" }}</td>
          <td>{{ item?.cost_name || "---" }}</td>
          <td>{{ item?.amount || "---" }}</td>
        </tr>
      </tbody>
    </table>
    <div
      class="mt-2"
      *ngIf="costCenters?.length > 0"
      style="
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <ngb-pagination
        *ngIf="costCenters.length"
        [collectionSize]="costCenters.length"
        [(page)]="page"
        [maxSize]="size"
        [boundaryLinks]="true"
      ></ngb-pagination>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="col-md-4 col-xs-12">
    <button
      type="button"
      class="albutton mnabrbutton mat-accent"
      mat-button
      mat-dialog-close
    >
      {{ "lang_close" | language }}
    </button>
  </div>
</mat-dialog-actions>
