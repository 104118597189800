import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
// import * as Editor from "assets/libraries/ckeditor/build/ckeditor";
import { MatSnackBar } from "@angular/material/snack-bar";
declare var $: any;
@Component({
  selector: "app-formc2-add",
  templateUrl: "./formc2-add.component.html",
  styleUrls: ["./formc2-add.component.scss"],
})
export class Formc2AddComponent implements OnInit {
  lang_key =
    localStorage.getItem("lang_key") == null
      ? "en"
      : localStorage.getItem("lang_key");
  // @ViewChild("editor") editorComponent: CKEditorComponent;
  // public myEditor = Editor;
  config = {
    language: this.lang_key,
    toolbar: [
      {
        name: "document",
        items: [
          "Source",
          "-",
          "Save",
          "NewPage",
          "Preview",
          "Print",
          "-",
          "Templates",
        ],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          "Copy",
          "Paste",
          "PasteText",
          "PasteFromWord",
          "-",
          "Undo",
          "Redo",
        ],
      },
      {
        name: "editing",
        items: ["Find", "Replace", "-", "SelectAll"],
      },
      {
        name: "forms",
        items: [
          "Form",
          "Checkbox",
          "Radio",
          "TextField",
          "Textarea",
          "Select",
          "Button",
          "HiddenField",
        ],
      },
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "Subscript",
          "Superscript",
          "-",
          "CopyFormatting",
          "RemoveFormat",
        ],
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      {
        name: "insert",
        items: [
          "Image",
          "Flash",
          "Table",
          "wrapCode",
          "TableCellProperties",
          "TableColumnResize",
          "TableProperties",
          "TableToolbar",
          "HorizontalRule",
          "Smiley",
          "SpecialChar",
          "PageBreak",
          "Iframe",
        ],
      },
      { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
      { name: "colors", items: ["TextColor", "BGColor"] },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "CreateDiv",
          "-",
          "JustifyLeft",
          "JustifyCenter",
          "JustifyRight",
          "JustifyBlock",
          "-",
          "BidiLtr",
          "BidiRtl",
          "Language",
        ],
      },
      { name: "tools", items: ["Maximize", "ShowBlocks"] },
    ],
    extraPlugins:
      "justify,colorbutton,font,codesnippet,autocomplete,find,selectall,iframe,div,enterkey,notification,bidi",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    removeButtons: "Image",
  };
  singleselection = environment.singledropdown;
  multipleselection = environment.multipledropdown;
  error_message: string = "";
  error_status: boolean = false;
  stamps: any = [];
  form_key = "FORM_C2";
  filename = this.lang.transform("lang_select_files");
  isFile: boolean = false;
  form: FormGroup;
  markstatus: boolean = false;
  reviewstatus: boolean = false;
  attachstatus: boolean = false;
  stampstatus: boolean = false;
  attachments: FormArray;
  languages: any = [];
  branches: any = [];
  loading = false;
  document_types: any = [];
  users: any = [];
  usersB: any = [];
  usersC: any = [];
  usersM: any = [];
  usersR: any = [];
  submitted = false;
  filepermissionlevels = [];
  queryParams;
  constructor(
    public ds: DataService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public fb: FormBuilder,
    public router: Router
  ) {}
  userId;
  userData: any = "";
  ngOnInit() {
    this.spinner.show();
    this.build_form();
    this.route.params.subscribe((params) => {
      if (params.id && params.bid) {
        this.userId = params.id;
        if (location.href.indexOf("human_resources") > -1) {
          this.ds
            .getActionByUrl(
              [],
              "human_resources/get_myview_data/" + params.id + "/" + params.bid
            )
            .subscribe(
              (data) => {
                this.spinner.hide();
                if (data.status) {
                  this.userData = data.data;
                  this.form.patchValue({
                    transaction_to: this.userData.branch_id,
                  });
                } else {
                  this.ds.dialogf("", data.error);
                }
              },
              (error) => {
                this.spinner.hide();
                this.ds.dialogf(
                  "",
                  error && error.error && error.error.error
                    ? error.error.error
                    : this.lang.transform("lang_internal_server_error")
                );
              }
            );
        }
      }
    });

    this.addfiles();
    this.get_required_data("tc/tlanguages", "lang");
    this.get_required_data("tc/tbranches", "branch");
    this.get_required_data("tc/doctypes", "type");
    this.file_permission_levels();
    if (location.href.indexOf("human_resources") > -1) {
      this.get_view_details();
    }
    this.route.queryParams.subscribe((res) => {
      this.queryParams = res;
    });
  }
  changeEditorDir(event) {
    this.lang_key = event != undefined ? event.value : this.lang_key;
    $(
      "ckeditor .ck.ck-reset.ck-editor, ckeditor .ck.ck-content.ck-editor__editable"
    ).attr({
      style:
        event.value == "ar"
          ? "text-align : right !important"
          : "text-align : left !important",
      lang: event.value,
      dir: event.value == "ar" ? "rtl" : "ltr",
    });
    this.useLanguage(event?.value);
  }
  useLanguage(language: string) {
    let dir = language == "en" ? "ltr" : "rtl";
    localStorage.setItem("lang_key", language);
    if (localStorage.getItem("direction") != dir) {
      localStorage.setItem("direction", dir);
      this.spinner.show();
      this.ds.get("change_lang/" + language).subscribe(
        (response) => {
          this.spinner.hide();
          if (response.status) {
            window.location.reload();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  }
  get_required_data(url, key) {
    this.languages = [];
    this.branches = [];
    this.document_types = [];
    this.ds.getActionByUrl([], url).subscribe((res) => {
      this.spinner.hide();
      if (res.status) {
        if (key === "lang") {
          this.languages = res.records;
        } else if (key === "branch") {
          this.branches = res.records;
        } else if (key === "type") {
          this.document_types = res.records;
        }
      }
    });
  }
  public file_permission_levels() {
    this.filepermissionlevels = [];
    this.ds.getActionByUrl([], "lflevel").subscribe((res) => {
      if (res.status) {
        this.filepermissionlevels = res.records;
      }
    });
  }
  public search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append("search", key.term || "");
    param.append("lang_key", this.form.get("lang_key").value || this.lang_key);
    param.append("user_type_id", "1");
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
      .subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        } else {
        }
      });
  }
  get filesFormGroup() {
    return this.form.get("attachments") as FormArray;
  }
  SetName(event) {}
  public build_form() {
    this.form = new FormGroup({
      lang_key: new FormControl(this.lang_key || null, [Validators.required]),
      subject: new FormControl("", [Validators.required]),
      transaction_from: new FormControl("", [Validators.required]),
      transaction_to: new FormControl(null, [Validators.required]),
      issued_number: new FormControl("", [Validators.required]),
      issued_date_m: new FormControl("", [Validators.required]),
      issued_date_h: new FormControl("", [Validators.required]),
      level_keys: new FormControl("", [Validators.required]),
      "signature[C][user_id]": new FormControl(null, [Validators.required]),
      content: new FormControl("", [Validators.required]),
      attachmentstitle: new FormControl(""),
      marks: new FormControl(null),
      attachments: this.fb.array([]),
    });
    this.attachments = this.form.get("attachments") as FormArray;
  }

  id = this.route.snapshot.paramMap.get("id");
  branch_id = this.route.snapshot.paramMap.get("bid");
  get_view_details() {
    this.ds
      .getActionByUrl(
        [],
        "human_resources/get_myview_data/" + this.id + "/" + this.branch_id
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            let emp = data.data;
            this.form.patchValue({
              transaction_from:
                this.lang_key == "en"
                  ? emp?.firstname_english + " " + emp?.lastname_english
                  : emp?.firstname_arabic + " " + emp?.lastname_arabic,
            });
          } else {
            /* this.viewdata = ""; */
            this.ds.dialogf("", data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          /*  this.viewdata = ""; */
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  files(): FormGroup {
    return this.fb.group({
      attach_title: new FormControl("", [Validators.required]),
      file: new FormControl("", [Validators.required]),
    });
  }
  addfiles() {
    if (this.attachments.length < 5) {
      this.attachments.push(this.files());
    }
  }
  removefiles(index) {
    if (this.attachments.length > 1) {
      this.attachments.removeAt(index);
    } else {
      this.showMsg("lang_at_least_one_is_required", "ok", "error-snackbar");
    }
  }
  showMsg(msg: string, action: string, className: string) {
    this.snackBar.open(msg, action, {
      duration: 4000,
      panelClass: [className],
    });
  }
  getfilesgroup(index): FormGroup {
    const formGroup = this.attachments.controls[index] as FormGroup;
    return formGroup;
  }
  public UploadFile(event, index) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      $("#uploadText" + index).text(fileid.name);
      this.attachments.controls[index]
        .get("file")
        .setValue(fileid, { emitEvent: true });
    } else {
      this.attachments.controls[index].get("file").setValue(null);
      $("#uploadText" + index).text(this.lang.transform("lang_select_files"));
    }
  }
  public removeFile() {
    this.isFile = false;
    this.form.get("upload").setValue(null);
    this.filename = this.lang.transform("lang_select_files");
  }

  setValueToFields(obj, enField: AbstractControl, ArField: AbstractControl) {
    /* if(obj.type == 'ar'){

    }else{

    } */
    enField.setValue(obj.enField);
    ArField.setValue(obj.arField);
  }
  public submitForm() {
    this.submitted = true;
    if (this.form.valid) {
      let param = new FormData();
      param.set("lang_key", this.form.get("lang_key").value || "");
      param.set("subject", this.form.get("subject").value || "");
      param.set("transaction_from", this.form.get("transaction_from").value);
      param.set("transaction_to", this.form.get("transaction_to").value || "");
      param.set("issued_number", this.form.get("issued_number").value);
      param.set("issued_date_m", this.form.get("issued_date_m").value);
      param.set("issued_date_h", this.form.get("issued_date_h").value);
      param.set(
        "attachmentstitle",
        this.form.get("attachmentstitle").value || ""
      );
      param.set(
        "signature[C][user_id]",
        this.form.get("signature[C][user_id]").value || ""
      );
      param.set("content", this.form.get("content").value);
      param.set(
        "needMark",
        this.form.get("marks").value && this.form.get("marks").value.length > 0
          ? "1"
          : "0"
      );
      param.set(
        "marks",
        this.form.get("marks").value && this.form.get("marks").value.length > 0
          ? this.form.get("marks").value.toString()
          : ""
      );
      this.filesFormGroup.value.forEach((v, k) => {
        param.set("attachments[" + k + "][file]", v.file);
        param.set("attachments[" + k + "][attach_title]", v.attach_title);
      });
      if (this.queryParams?.from == "projects") {
        param.set("extra[module_key]", this.queryParams?.from);
        param.set("extra[projects_work_area_id]", this.queryParams?.pwaid);
        param.set("extra[projects_profile_id]", this.queryParams?.ppid);
      } else if (this.queryParams?.from == "human_resources") {
        param.set("extra[module_key]", "human_resources");
        param.set("extra[employee_number]", this.queryParams?.id);
      } else if (
        this.queryParams?.from == "customers" &&
        this.queryParams?.id
      ) {
        param.set("extra[module_key]", "customers");
        param.set("extra[customer_id]", this.queryParams?.id);
      } else if (
        this.queryParams?.from == "contractors" &&
        this.queryParams?.id
      ) {
        param.set("extra[module_key]", "contractors");
        param.set("extra[contractor_id]", this.queryParams?.id);
      } else {
        param.set("extra[module_key]", "communication");
      }
      param.set("extra[transaction_key]", "FORM_C2");
      param.set("extra[file_level_keys]", this.form.get("level_keys").value);
      this.spinner.show();
      this.ds
        .postActionByUrl(param, "form/" + this.form_key + "/cr/0")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              //this.alert.success(res.msg);
              this.alert.success(this.lang.transform("lang_success"));
              this.form.reset();
              setTimeout(() => {
                this.router.navigate(["transactions/" + res.url]);
              }, 1000);
            } else {
              this.alert.error(res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        );
    }
  }

  previewBeforeCreate() {
    this.spinner.show();
    let formData = new FormData();
    formData.append(
      "lang_key",
      this.form.get("lang_key").value || this.lang_key
    );
    formData.append("subject", this.form.get("subject").value || "");
    formData.append(
      "transaction_from",
      this.form.get("transaction_from").value || ""
    );
    formData.append("issued_number", this.form.get("issued_number").value);
    formData.append("issued_date_m", this.form.get("issued_date_m").value);
    formData.append("issued_date_h", this.form.get("issued_date_h").value);
    formData.append(
      "transaction_to",
      this.form.get("transaction_to").value || ""
    );
    formData.append("content", this.form.get("content").value || "");
    formData.append(
      "attachmentstitle",
      this.form.get("attachmentstitle").value || ""
    );
    if (this.queryParams?.from == "projects") {
      formData.set("extra[module_key]", this.queryParams?.from);
      formData.set("extra[projects_work_area_id]", this.queryParams?.pwaid);
      formData.set("extra[projects_profile_id]", this.queryParams?.ppid);
    } else if (this.queryParams?.from == "human_resources") {
      formData.set("extra[module_key]", "human_resources");
      formData.set("extra[employee_number]", this.queryParams?.id);
    } else if (this.queryParams?.from == "customers" && this.queryParams?.id) {
      formData.set("extra[module_key]", "customers");
      formData.set("extra[customer_id]", this.queryParams?.id);
    } else if (
      this.queryParams?.from == "contractors" &&
      this.queryParams?.id
    ) {
      formData.set("extra[module_key]", "contractors");
      formData.set("extra[contractor_id]", this.queryParams?.id);
    } else {
      formData.set("extra[module_key]", "communication");
    }
    formData.append(
      "extra[file_level_keys]",
      this.form.get("level_keys").value || ""
    );
    formData.append("extra[transaction_key]", "FORM_C2");
    this.ds.post("form/FORM_C2/prc", formData).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          let url = "data:" + res["content-type"] + ";base64," + res.base64;
          let newWindow = window.open("", "_blank");
          setTimeout(() => {
            newWindow.document.write(
              '<html><head><title>Form Incoming Preview</title></head><body height="100%" width="100%" style="margin:0;overflow:hidden"><iframe src="' +
                url +
                '" height="100%" width="100%"></iframe></body></html>'
            );
          });
        } else {
          this.alert.error(res?.error);
        }
      },
      (err) => {
        this.spinner.hide();
        this.alert.error(this.lang.transform("lang_internal_server"));
      }
    );
  }
}
