<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno" >
    <mat-accordion class="user_permissions" *ngIf="mention_allow || admin">
        <mat-expansion-panel class="mpanel" [expanded]="true">
            <i class="together-icon">
                <i (click)="emptypermissionfields();openModal(createPermitGroups);" class="fa fa-plus s2icon mnabricon"  matTooltip="{{'lang_permit_mentions' | language}}"></i>
            </i>
            <mat-expansion-panel-header class="mpanel-header">{{'lang_permit_mentions' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <ng-select 
                                [items]="branches" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                clearAllText="Clear"
                                appendTo="body" 
                                [(ngModel)]="searchBranch"
                                placeholder="{{'lang_search_branch' | language}}"
                                (change)="spinner.show();load_permission_mention();">
                            </ng-select>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <ng-select 
                                [items]="groups" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                clearAllText="Clear"
                                appendTo="body" 
                                [(ngModel)]="searchGroup"
                                placeholder="{{'lang_group' | language}}"
                                (change)="spinner.show();load_permission_mention();">
                            </ng-select>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <ng-select 
                                [items]="users" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                clearAllText="Clear"
                                appendTo="body" 
                                [(ngModel)]="searchUser"
                                placeholder="{{'lang_users' | language}}"
                                (change)="spinner.show();load_permission_mention();">
                            </ng-select>
                        </div>                
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsUsr checkbox" id="allItemsUsr" (click)="checkAllItemsUsr($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_username' | language }}</td>
                                            <td>{{'lang_branch' | language }}</td>
                                            <td>{{'lang_group' | language }}</td>
                                            <td>{{'lang_permission' | language }}</td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_ondate' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="pmdata.length != '0' " class="AppendList">
                                        <tr *ngFor="let list of pmdata.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{list.mention_id}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemUsr checkbox" value="{{list.mention_id}}" (click)="checkEachItemUsr($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td *ngIf="list.mention_name">{{list.mention_name}}</td>
                                            <td *ngIf="!list.mention_name">null</td>
                                            <td>{{list.branch_name}}</td>
                                            <td>{{list.group_name}}</td>
                                            <td *ngIf="list.private_value == 'all' ">{{list.permitname}} ({{'lang_all' | language }})</td>
                                            <td *ngIf="list.private_value != 'all' ">{{list.permitname}} (ID - {{list.private_value}})</td>
                                            <td *ngIf="list.creator_name">{{list.creator_name}}</td>
                                            <td *ngIf="!list.creator_name">null</td>
                                            <td>{{list.create_date}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="pmdata.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="8" align="center">{{lodingdatas}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="pmdata?.records?.length != '0' " class="card-footer padb0">
                    <div class="row responsive_pagination">
                        <div *ngIf="able2delete" class="deleteIconDiv none col-lg-1 col-sm-1 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataUsr()" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-3 mb-10"  style="height: fit-content;margin: auto 0;">
                            {{'lang_total' | language }} : {{pmdata?.page?.total_records}}
                        </div>
                        <div class="col-lg-5 col-sm-5 mb-10"  style="display: flex;justify-content: center;">
                           <!--  <mat-radio-group>
                                <mat-radio-button value="10" checked (click)="loadpagesize(10)"> 10 </mat-radio-button>
                                <mat-radio-button value="20" (click)="loadpagesize(20)"> 20 </mat-radio-button>
                                <mat-radio-button value="50" (click)="loadpagesize(50)"> 50 </mat-radio-button>
                                <mat-radio-button value="100" (click)="loadpagesize(100)"> 100 </mat-radio-button>
                                <mat-radio-button value="500" (click)="loadpagesize(500)"> 500 </mat-radio-button>
                            </mat-radio-group> -->

                            <ngb-pagination class="m-auto" [collectionSize]="pmdata?.page?.total_records" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="pmpage_no" [pageSize]="pmpage_size" (pageChange)="loadpage($event)"></ngb-pagination>

                        </div>
                        <div class="col-lg-3 col-sm-3" style="display: flex;justify-content: end;margin: auto;">
                            <!-- {{'lang_goto' | language }} :
                            <select class="customselect" (change)="loadpage($event.target.value)">
                                <option *ngFor="let ks of ds.getrange(pmdata.page.total_pages)" value="{{ks}}">{{ks}}</option>
                            </select> -->

                            <mat-select  [(ngModel)]="pmpage_size" (selectionChange)="loadpagesize($event.value)">
                                     
                                <!-- <mat-option value="1">1  </mat-option> -->
                                <mat-option class="text-center" value="10">10 {{'lang_per_page'|language}} </mat-option>
                                <mat-option class="text-center" value="20">20 {{'lang_per_page'|language}}  </mat-option>
                                <mat-option class="text-center" value="50">50 {{'lang_per_page'|language}}  </mat-option>
                                <mat-option class="text-center" value="100">100 {{'lang_per_page'|language}}</mat-option>
                                <mat-option class="text-center" value="500">500 {{'lang_per_page'|language}}</mat-option>
                              </mat-select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<ng-template #createPermitGroups>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_permit_mentions' | language}}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRef.hide();">&times;</button>
        </div>
        <form [formGroup]="permissionForm" (ngSubmit)="savepermissions()">
            <div class="modal-body">
                <input type="hidden" formControlName="branch_id" />
                <div class="form-group">
                    <label>{{'lang_branch' | language}} :<span class="red">*</span></label>
                    <select class="form-control almnabrformcontrol" formControlName="branch_id">
                        <option value="">{{'lang_choose_options' | language}}</option>
                        <option *ngFor="let branch of branches" value="{{branch.value}}">{{branch.label}}</option>
                    </select>
                    <mat-error *ngIf="permissionForm.controls['branch_id'].touched && permissionForm.controls['branch_id'].errors?.required"> {{'lang_field_required' | language}} </mat-error>
                </div>
                <div class="form-group">
                    <label>{{'lang_group' | language}} :<span class="red">*</span></label>
                    <select class="form-control almnabrformcontrol" formControlName="group_id">
                        <option value="">{{'lang_choose_options' | language}}</option>
                        <option *ngFor="let group of groups" value="{{group.value}}">{{group.label}}</option>
                    </select>
                    <mat-error *ngIf="permissionForm.controls['group_id'].touched && permissionForm.controls['group_id'].errors?.required"> {{'lang_field_required' | language}} </mat-error>
                </div>
                <div class="form-group">
                    <label>{{'lang_users' | language}}:</label>
                    <ng-select 
                            [items]="users" 
                            [multiple]="true" 
                            bindLabel="label" 
                            bindValue="value" 
                            clearAllText="Clear"
                            appendTo="body" 
                            formControlName="users_id"
                            placeholder="{{'lang_users' | language}}">
                    </ng-select>                    
                    <mat-error *ngIf="permissionForm.controls['users_id'].touched && permissionForm.controls['users_id'].errors?.required"> {{'lang_field_required' | language}} </mat-error>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRef.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>