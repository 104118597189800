import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
// import * as Editor from "assets/libraries/ckeditor/build/ckeditor";
@Component({
  selector: "app-pq1-step1",
  templateUrl: "./pq1-step1.component.html",
  styleUrls: ["./pq1-step1.component.scss"],
})
export class Pq1Step1Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() form_url;
  @Input() branch_id;
  @Input() projects_profile_id;
  @Input() projects_ps_id;
  @Input() allData;
  configrationStep1Form: FormGroup;
  quotation_types = [];
  vat_list = [];
  other_attachments: FormArray;
  othersArray: FormArray;
  nodata: string = this.lang.transform("lang_no_data");
  filename: string = this.lang.transform("lang_select_files");
  user_type = this.ls.getItem("user_type_id") || 0;
  lang_key =
    localStorage.getItem("lang_key") == null
      ? "en"
      : localStorage.getItem("lang_key");
  @Input() editMode;
  // public myEditor = Editor;
  config = {
    language: this.lang_key,
    toolbar: [
      {
        name: "document",
        items: [
          "Source",
          "-",
          "Save",
          "NewPage",
          "Preview",
          "Print",
          "-",
          "Templates",
        ],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          "Copy",
          "Paste",
          "PasteText",
          "PasteFromWord",
          "-",
          "Undo",
          "Redo",
        ],
      },
      {
        name: "editing",
        items: ["Find", "Replace", "-", "SelectAll"],
      },
      {
        name: "forms",
        items: [
          "Form",
          "Checkbox",
          "Radio",
          "TextField",
          "Textarea",
          "Select",
          "Button",
          "HiddenField",
        ],
      },
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "Subscript",
          "Superscript",
          "-",
          "CopyFormatting",
          "RemoveFormat",
        ],
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      {
        name: "insert",
        items: [
          "Image",
          "Flash",
          "Table",
          "wrapCode",
          "TableCellProperties",
          "TableColumnResize",
          "TableProperties",
          "TableToolbar",
          "HorizontalRule",
          "Smiley",
          "SpecialChar",
          "PageBreak",
          "Iframe",
        ],
      },
      { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
      { name: "colors", items: ["TextColor", "BGColor"] },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "CreateDiv",
          "-",
          "JustifyLeft",
          "JustifyCenter",
          "JustifyRight",
          "JustifyBlock",
          "-",
          "BidiLtr",
          "BidiRtl",
          "Language",
        ],
      },
      { name: "tools", items: ["Maximize", "ShowBlocks"] },
    ],
    extraPlugins:
      "justify,colorbutton,font,codesnippet,autocomplete,find,selectall,iframe,div,enterkey,notification,bidi",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    removeButtons: "Image",
  };
  constructor(
    public ls: LocalStorage,
    private sweetAlert: SweetAlertService,
    public ds: DataService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public fb: FormBuilder,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.buildForms();
    this.getVatList();
    this.getQuotationTypes();
    if (this.editMode) {
      this.setDataForEdit();
    }
  }

  buildForms() {
    this.configrationStep1Form = this.fb.group({
      lang_key: new FormControl(this.lang_key, [Validators.required]),
      transaction_request_description: new FormControl("", [
        Validators.required,
      ]),
      care: new FormControl("", [Validators.required]),
      transaction_from: new FormControl(this.branch_id, [Validators.required]),
      transaction_to: new FormControl("", [Validators.required]),
      quotation_types_keyword: new FormControl("", [Validators.required]),
      content: new FormControl("", [Validators.required]),
      vat_key: new FormControl("", [Validators.required]),
      attachmentstitle: new FormControl(""),
      needAttachments: new FormControl(false),
      needOthers: new FormControl(false),
      attachments: this.fb.array([]),
      others: this.fb.array([]),
    });
    this.other_attachments = this.configrationStep1Form.get(
      "attachments"
    ) as FormArray;
    this.othersArray = this.configrationStep1Form.get("others") as FormArray;
  }

  get filesFormGroup() {
    return this.configrationStep1Form.get("attachments") as FormArray;
  }
  files(value?): FormGroup {
    return this.fb.group({
      delete_icon: new FormControl(value?.attach_title || ""),
      view: new FormControl(value?.file || null),
      attach_title: new FormControl(
        {
          value: value ? value?.attach_title : "",
          disabled: value?.attach_title ? true : false,
        },
        [Validators.required]
      ),
      file: new FormControl(value?.file || null, [Validators.required]),
      attach_with_the_document: new FormControl(
        value?.attach_with_the_document || false
      ),
      print_official_paper: new FormControl(
        value?.attach_with_the_document || false
      ),
    });
  }
  addfiles(value?) {
    if (this.other_attachments.length < 10) {
      this.other_attachments.push(this.files(value));
      this.other_attachments.setValidators(Validators.required);
      this.other_attachments.updateValueAndValidity();
    }
  }
  removefiles(index) {
    if (this.other_attachments.length == 1) {
      this.other_attachments.removeAt(index);
      this.other_attachments.clearValidators();
      this.other_attachments.updateValueAndValidity();
    } else {
      this.other_attachments.removeAt(index);
    }
  }

  get othersFormGroup() {
    return this.configrationStep1Form.get("others") as FormArray;
  }
  others(value?): FormGroup {
    return this.fb.group({
      delete_icon: new FormControl(value?.qror_name_en || ""),
      view: new FormControl(value?.file || null),
      qror_name_en: new FormControl(
        {
          value: value ? value?.qror_name_en : "",
          disabled: value?.qror_name_en ? true : false,
        },
        [Validators.required]
      ),
      qror_name_ar: new FormControl(value?.qror_name_ar || null, [
        Validators.required,
      ]),
    });
  }
  addOthers(value?) {
    if (this.othersArray.length < 10) {
      this.othersArray.push(this.others(value));
      this.othersArray.setValidators(Validators.required);
      this.othersArray.updateValueAndValidity();
    }
  }
  removeOthers(index) {
    if (this.othersArray.length == 1) {
      this.othersArray.removeAt(index);
      this.othersArray.clearValidators();
      this.othersArray.updateValueAndValidity();
    } else {
      this.othersArray.removeAt(index);
    }
  }

  getVatList() {
    let formdata = new FormData();
    formdata.append(
      "lang_key",
      this.configrationStep1Form.get("lang_key").value || ""
    );
    formdata.append("qss_keyword", "");
    this.ds
      .getActionByUrl(this.ds.formData2string(formdata), "form/FORM_PQ1/vat")
      .subscribe(
        (res) => {
          if (res?.status) {
            this.vat_list = res?.records;
          } else {
            this.vat_list = [];
          }
        },
        (err) => {
          this.vat_list = [];
        }
      );
  }

  getQuotationTypes() {
    let formdata = new FormData();
    formdata.append(
      "lang_key",
      this.configrationStep1Form.get("lang_key").value || ""
    );
    formdata.append("qss_keyword", "");
    this.ds
      .getActionByUrl(this.ds.formData2string(formdata), "form/FORM_PQ1/qt")
      .subscribe(
        (res) => {
          if (res?.status) {
            this.quotation_types = res?.records;
          } else {
            this.quotation_types = [];
          }
        },
        (err) => {
          this.quotation_types = [];
        }
      );
  }

  setDataForEdit() {
    this.configrationStep1Form
      .get("lang_key")
      .setValue(this.allData?.form_pq1_data?.records?.lang_key);
    this.configrationStep1Form
      .get("transaction_request_description")
      .setValue(this.allData?.form_pq1_data?.records?.subject);
    this.configrationStep1Form
      .get("care")
      .setValue(this.allData?.form_pq1_data?.records?.care);
    this.configrationStep1Form
      .get("transaction_from")
      .setValue(this.allData?.form_pq1_data?.records?.transaction_from);
    this.configrationStep1Form
      .get("transaction_to")
      .setValue(this.allData?.form_pq1_data?.records?.transaction_to);
    this.configrationStep1Form
      .get("quotation_types_keyword")
      .setValue(this.allData?.form_pq1_data?.records?.quotation_types_keyword);
    this.configrationStep1Form
      .get("content")
      .setValue(this.allData?.form_pq1_data?.records?.content);
    this.configrationStep1Form
      .get("vat_key")
      .setValue(this.allData?.form_pq1_data?.records?.vat_key);
    this.configrationStep1Form
      .get("attachmentstitle")
      .setValue(this.allData?.form_pq1_data?.records?.attachment);
  }

  submit() {
    if (this.configrationStep1Form.valid) {
      let formData = new FormData();
      formData.append(
        "projects_profile_id",
        this.projects_profile_id ||
          this.allData?.form_full_data?.records?.project_id
      );
      formData.append(
        "projects_ps_id",
        this.projects_ps_id ||
          this.allData?.form_pq1_data?.records?.projects_ps_id
      );
      formData.append(
        "lang_key",
        this.configrationStep1Form.get("lang_key").value || ""
      );
      formData.append(
        "transaction_request_description",
        this.configrationStep1Form.get("transaction_request_description")
          .value || ""
      );
      formData.append(
        "care",
        this.configrationStep1Form.get("care").value || ""
      );
      formData.append(
        "transaction_from",
        this.configrationStep1Form.get("transaction_from").value || ""
      );
      formData.append(
        "transaction_to",
        this.configrationStep1Form.get("transaction_to").value || ""
      );
      formData.append(
        "quotation_types_keyword",
        this.configrationStep1Form.get("quotation_types_keyword").value || ""
      );
      formData.append(
        "content",
        this.configrationStep1Form.get("content").value || ""
      );
      formData.append(
        "vat_key",
        this.configrationStep1Form.get("vat_key").value || ""
      );
      formData.append(
        "attachmentstitle",
        this.configrationStep1Form.get("attachmentstitle").value || ""
      );
      this.other_attachments?.controls?.forEach((element, key) => {
        formData.append(
          `attachments[${key}][attach_title]`,
          element.get("attach_title").value || ""
        );
        formData.append(
          `attachments[${key}][file]`,
          element.get("file").value || ""
        );
        formData.append(
          `attachments[${key}][attach_with_the_document]`,
          element.get("attach_with_the_document").value || ""
        );
        formData.append(
          `attachments[${key}][print_official_paper]`,
          element.get("print_official_paper").value || ""
        );
      });
      this.othersArray?.controls?.forEach((element, key) => {
        formData.append(
          `other_fields[${key}][qror_name_ar]`,
          element.get("qror_name_ar").value || ""
        );
        formData.append(
          `other_fields[${key}][qror_name_en]`,
          element.get("qror_name_en").value || ""
        );
      });
      let stepUrl = "form/FORM_PQ1/cr/0";
      if (this.editMode) {
        formData.append(`transaction_request_id`, this.form_request_id || "");
        stepUrl = "form/FORM_PQ1/et";
      }
      this.spinner.show();
      this.ds.post(stepUrl, formData).subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.sweetAlert.successToast(
              this.lang.transform("lang_success"),
              400
            );
            setTimeout(() => {
              if (this.editMode) {
                this.refreshComponent.emit(true);
              } else {
                this.router.navigate(["/tasks/alltasks"]);
              }
            }, 500);
          } else {
            this.sweetAlert.errorToast(res?.error, 3000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
    } else {
      this.sweetAlert.errorToast(
        this.lang.transform("lang_fill_required_fields"),
        3000
      );
    }
  }
}
