import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import {
  NgbCalendar,
  NgbCalendarIslamicUmalqura,
  NgbDatepickerI18n,
} from "@ng-bootstrap/ng-bootstrap";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { IslamicI18n } from "@shared/utils/arabicpicker";
import { BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n },
  ],
})
export class SignupComponent implements OnInit {
  public RegistrationForm: FormGroup;
  licencesList: any = [];
  attachments: any = [];
  action: string = "SUP";
  allowsubmit: boolean;
  constructor(
    private cdRef: ChangeDetectorRef,
    public fb: FormBuilder,
    public ds: DataService,
    public route: ActivatedRoute,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public router: Router,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    public modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.buildHrForm();
  }
  buildHrForm() {
    this.RegistrationForm = new FormGroup({
      loginDetails: new FormGroup(
        {
          username: new FormControl("", [Validators.required]),
          password: new FormControl("", [
            Validators.required,
            Validators.minLength(6),
          ]),
          confirm_password: new FormControl("", [Validators.required]),
        },
        { validators: this.passwordConfirming }
      ),
      idDetails: new FormGroup({
        employee_id_number: new FormControl("", [Validators.required]),
        name_english: new FormControl("", [Validators.required]),
        name_arabic: new FormControl("", [Validators.required]),
        firstname_english: new FormControl("", [Validators.required]),
        secondname_english: new FormControl("", [Validators.required]),
        thirdname_english: new FormControl(""),
        lastname_english: new FormControl("", [Validators.required]),
        firstname_arabic: new FormControl("", [Validators.required]),
        secondname_arabic: new FormControl("", [Validators.required]),
        thirdname_arabic: new FormControl(""),
        lastname_arabic: new FormControl("", [Validators.required]),
        iqama_expiry_date_english: new FormControl("", [Validators.required]),
        iqama_expiry_date_arabic: new FormControl(null, [Validators.required]),
        copy_number: new FormControl("", [Validators.required]),
        birth_date_english: new FormControl("", [Validators.required]),
        birth_date_arabic: new FormControl(null, [Validators.required]),
        gender: new FormControl("", [Validators.required]),
        marital_status: new FormControl("", [Validators.required]),
        religion: new FormControl("", [Validators.required]),
        nationality: new FormControl(null, [Validators.required]),
        profile_image: new FormControl(""),
        job_title_iqama: new FormControl("", [Validators.required]),
        employee_title: new FormControl(""),
        id_filename: new FormControl("", [Validators.required]),
        idFileUpload: new FormControl("", [Validators.required]),
      }),
      jobDetails: new FormGroup({
        job_title_id: new FormControl(null, [Validators.required]),
        job_descriptions: new FormControl("", [Validators.required]),
        liclists: this.fb.array([]),
        resume_title: new FormControl("", [Validators.required]),
        resume_file: new FormControl("", [Validators.required]),
      }),
      contactDetails: new FormGroup({
        primary_mobile: new FormControl("", [Validators.required]),
        primary_email: new FormControl("", [
          Validators.required,
          Validators.email,
        ]),
        primary_address: new FormControl("", [Validators.required]),
        contact_file_name: new FormControl(""),
        address_file: new FormControl(""),
      }),
      bankDetails: new FormGroup({
        account_number: new FormControl("", [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(35),
        ]),
        bank_short_code: new FormControl(null, [Validators.required]),
        bank_file_name: new FormControl("", [Validators.required]),
        bank_file: new FormControl("", [Validators.required]),
      }),
      educationDetails: new FormGroup({
        primary_graduation_year: new FormControl("", [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
        ]),
        primary_education_level: new FormControl("", [Validators.required]),
        edu_file_name: new FormControl("", [Validators.required]),
        education_file: new FormControl("", [Validators.required]),
      }),
      passportDetails: new FormGroup({
        passport_number: new FormControl(""),
        passport_issue_date_english: new FormControl(""),
        passport_issue_date_arabic: new FormControl(null),
        passport_expiry_date_english: new FormControl(""),
        passport_expiry_date_arabic: new FormControl(null),
        passport_issue_place: new FormControl(""),
        passport_file_name: new FormControl(""),
        passport_file: new FormControl(""),
      }),
      insuranceDetails: new FormGroup({
        insurance_number: new FormControl(""),
        insurance_date: new FormControl(""),
        insurance_type_class: new FormControl(""),
      }),
      signatureDetails: new FormGroup({
        signature: new FormControl("", [Validators.required]),
        mark: new FormControl("", [Validators.required]),
        terms_conditions: new FormControl(false, [
          (control) => {
            return !control.value ? { required: true } : null;
          },
        ]),
        verification_method: new FormControl("", [Validators.required]),
        verification_code: new FormControl(""),
      }),
    });
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get("password").value !== c.get("confirm_password").value) {
      return { invalid: true };
    }
  }
  register() {
    this.alert.clear();
    let wholeData: any = {};
    let login = this.RegistrationForm.get("loginDetails").value;
    Object.assign(wholeData, login);
    let id = this.RegistrationForm.get("idDetails").value;
    Object.assign(wholeData, id);
    let job = this.RegistrationForm.get("jobDetails").value;
    Object.assign(wholeData, job);
    let contact = this.RegistrationForm.get("contactDetails").value;
    Object.assign(wholeData, contact);
    let bank = this.RegistrationForm.get("bankDetails").value;
    Object.assign(wholeData, bank);
    let edu = this.RegistrationForm.get("educationDetails").value;
    Object.assign(wholeData, edu);
    let passport = this.RegistrationForm.get("passportDetails").value;
    Object.assign(wholeData, passport);
    let ins = this.RegistrationForm.get("insuranceDetails").value;
    Object.assign(wholeData, ins);
    let sig = this.RegistrationForm.get("signatureDetails").value;
    Object.assign(wholeData, sig);
    this.spinner.show();
    const formData = this.ds.json2formdata(wholeData);
    this.ds.postActionByNoToken(formData, "signup/signup/").subscribe(
      (data) => {
        this.spinner.hide();
        if (data.status) {
          this.alert.success(data.msg);
          this.RegistrationForm.reset();
          setTimeout(() => {
            this.router.navigate(["auth/login"]);
          }, 2000);
        } else {
          this.alert.error(data.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.alert.error(
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
}
