<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{'lang_upload' | language }} {{'lang_attendance' | language }}</h4>
		<span (click)="closeModal()"><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
        <!-- <div class="col-12 col-md-12">
            <p>{{'lang_upload' | language }} {{'lang_attendance' | language }}</p>
        </div> -->
        <div class="col-12 col-md-6">
         
                <input
                type="file"
                #uploadAttendance
                id="uploadAttendance"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                (change)="upload_i_phases($event.target.files)"
            />
            <button
                mat-button
                style="
                    background-color: rgb(136 224 136 / 42%);
                    width: 100%;margin-bottom: 5px;
                "
                (click)="uploadAttendance.click()"
            >
                <i
                    class="fa fa-upload"
                    style="
                        font-size: 20px;
                        margin: 0 5px;
                        color: rgb(19, 146, 19);
                    "
                ></i>
                <span
                    style="
                        text-transform: uppercase;
                        font-weight: 600;
                        color: rgb(19, 146, 19);
                        font-size: 12px;
                    "
                    >{{ "lang_upload" | language }}</span
                >
            </button>
        
        </div>
      
        <div class="col-12 col-md-6">
             
            <button
                    mat-button
                    style="
                        background-color: rgb(113 166 223 / 42%);
                        width: 100%;margin-bottom: 5px;
                    "
                    (click)="get_i_phases_history()"
                >
                    <i
                        class="fa fa-download"
                        style="
                            font-size: 20px;
                            margin: 0 5px;
                            color: rgb(19, 65, 146);
                        "
                    ></i>
                    <span
                        style="
                            text-transform: uppercase;
                            font-weight: 600;
                            color: rgb(19, 65, 146);
                            font-size: 12px;
                        "
                        >{{ "lang_history" | language }}</span
                    >
                </button>
        </div>
   
    </div>

    <div class="row">
        <div class="col-12 mt-3">
            <ngx-skeleton-loader *ngIf="loading_attachments" count="5" [theme]="{ 'border-radius': '3px', height: '40px' }"></ngx-skeleton-loader>
           

            <div
                class="table-responsive dropdownyes customResponsive tablefields"
                *ngIf="history_files_ED?.length && !loading_attachments"
            >
                <table border="1" class="table CustomTable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{'lang_filename' | language}}</th>
                            <th>{{'lang_writer' | language}}</th>
                            <th>{{'lang_created_date' | language}}</th>
                            <th>{{'lang_action' | language}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let item of history_files_ED ;
                                let i = index;
                                let odd = odd;
                                let even = even
                            "
                        >
                            <td>{{ i + 1 }}</td>
                            <td>{{item?.file_name}}</td>
                            <td>{{item?.user_username}}</td>
                            <td>{{item?.created_at | date:'medium'}}</td>
                            <td>
                                <i
                                class="fa fa-download"
                                style="
                                    font-size: 20px;
                                    margin: 0 5px;
                                    color: rgb(19, 65, 146);
                                "
                                (click)="download_ED_history(item)" 
                                ></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                *ngIf="history_files_ED?.length>0"
                    class="mt-2"
                    style="
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    "
                >
                    <ngb-pagination
                        *ngIf="history_files_ED.length>0"
                        [collectionSize]="responseInfo.total_records"
                        [(page)]="+page"
                        [pageSize]="+size"
                        [maxSize]="3"
                        [rotate]="true" [ellipses]="false"
                        [boundaryLinks]="true"
                        (pageChange)="get_i_phases_history()"
                    ></ngb-pagination>
                </div>
	        </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="closeModal()"
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</mat-dialog-actions>
