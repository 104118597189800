<div class="card drawings">
	<div class="card-header">{{ "lang_drawing" | language }}</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-lg-3 col-md-6 col-xs-12 mb-10">
					<ng-select
						[items]="group1List"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						appendTo="body"
						clearAllText="Clear"
						placeholder="{{ 'lang_group_one' | language }}"
						[(ngModel)]="group1_id"
						id="group1_id"
						(search)="get_divisions($event)"
						(change)="get_group2list({ term: '' }, false)"
					>
					</ng-select>
				</div>
				<div class="col-lg-3 col-md-6 col-xs-12 mb-10">
					<ng-select
						[items]="group2List"
						[multiple]="false"
						bindLabel="fulltext"
						bindValue="value"
						appendTo="body"
						clearAllText="Clear"
						placeholder="{{ 'lang_group_two' | language }}"
						[(ngModel)]="group2_id"
						id="group2_id"
						(search)="get_group2list($event, false)"
						(change)="spinner.show(); load_supervision_tender_drawings()"
					>
					</ng-select>
				</div>
				<div class="col-lg-3 col-md-6 col-xs-12 mb-10">
					<ng-select
						[items]="Dtypes"
						[multiple]="true"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						appendTo="body"
						[(ngModel)]="drawingItems"
						placeholder="{{ 'lang_drawing_types' | language }}"
						(change)="spinner.show(); search_supervision_tender_drawing()"
					>
					</ng-select>
				</div>
				<div class="col-lg-3 col-md-6 col-xs-12 mb-10">
					<div class="input-group input-group-sm">
						<input
							type="text"
							class="form-control almnabrformcontrol"
							[(ngModel)]="searchKey"
							(keyup)="search_supervision_tender_drawing()"
							placeholder="{{ 'lang_search' | language }}"
						/>
						<span class="input-group-btn" *ngIf="makeActionAdd">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_add' | language }}"
								class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
								(click)="emptyform(); openModal(AddTenderDrawingForm)"
							></button>
						</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check_all' | language }}"
										>
											<input
												type="checkbox"
												class="allItemsDraw checkbox"
												id="allItemsDraw"
												(click)="checkAllItemsDraw($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ "lang_drawing_name" | language }}</td>
									<td>{{ "lang_drawing_type" | language }}</td>
									<td>{{ "lang_drawing_number" | language }}</td>
									<td>{{ "lang_writer" | language }}</td>
									<td>{{ "lang_ondate" | language }}</td>
									<!-- <td>{{'lang_status' | language }}</td> -->
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody *ngIf="DListData.length != '0'" class="AppendList">
								<tr
									*ngFor="
										let list of DListData.records;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
									class="makeback removerow{{ list.drawing_file_id }}"
								>
									<td>{{ i + 1 }}</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check' | language }}"
										>
											<input
												type="checkbox"
												class="eachItemDraw checkbox"
												value="{{ list.drawing_file_id }}"
												(click)="checkEachItemDraw($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ list.drawings_name }}</td>
									<td>{{ list.drawing_type }}</td>
									<td>{{ list.drawings_number }}</td>
									<td>{{ list.writer }}</td>
									<td>{{ list.drawings_created_datetime }}</td>
									<td class="dropdown">
										<i
											class="fa fa-cog mnabricon"
											[matMenuTriggerFor]="menu"
										></i>
										<mat-menu #menu="matMenu">
											<button
												mat-menu-item
												(click)="
													ds?.preview_pdf_file(
														list.drawings_attachment_url,
														list?.drawings_name
													)
												"
											>
												<div
													class="d-flex justify-content-between align-items-center"
												>
													<span>{{ "lang_preview" | language }}</span>
													<i class="fa fa-paperclip mnabricon"></i>
												</div>
											</button>
											<button
												mat-menu-item
												(click)="viewdata(list); openModal(Dviewform)"
											>
												<div
													class="d-flex justify-content-between align-items-center"
												>
													<span>{{ "lang_view" | language }}</span>
													<i class="fa fa-eye mnabricon"></i>
												</div>
											</button>
											<button
												mat-menu-item
												*ngIf="makeActionEditDelete"
												(click)="
													editdata(list); openModal(AddTenderDrawingForm)
												"
											>
												<div
													class="d-flex justify-content-between align-items-center"
												>
													<span>{{ "lang_edit" | language }}</span>
													<i class="fa fa-pencil-square-o mnabricon"></i>
												</div>
											</button>
											<button
												mat-menu-item
												*ngIf="
													makeActionEditDelete && list.drawings_status == '0'
												"
												(click)="approve_tender_drawing(list)"
											>
												<div
													class="d-flex justify-content-between align-items-center"
												>
													<span>{{ "lang_approve" | language }}</span>
													<i
														class="mnabricon yellowcolor fa fa-check-circle"
													></i>
												</div>
											</button>
										</mat-menu>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="DListData.length == '0'" class="AppendList">
								<tr class="odd">
									<td colspan="9" align="center">{{ Dnodata }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="DListData?.records?.length != '0'" class="card-footer padb0">
			<div class="row responsive_pagination">
				<div
					*ngIf="makeActionEditDelete"
					class="deleteIconDiv none col-lg-1 col-sm-1 mb-10"
				>
					<i
						class="fa fa-trash-o faicon red deleteIcon"
						(click)="deleteBulkDataDraw()"
						aria-hidden="true"
						matTooltip="{{ 'lang_delete' | language }}"
					></i>
				</div>
				<div
					class="col-lg-3 col-sm-3 mb-10"
					style="height: fit-content; margin: auto 0"
				>
					{{ "lang_total" | language }} : {{ DListData?.page?.total_records }}
				</div>
				<div
					class="col-lg-5 col-sm-5 mb-10"
					style="display: flex; justify-content: center"
				>
					<ngb-pagination
						class="m-auto"
						[collectionSize]="DListData?.page?.total_records"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="dpageno"
						[pageSize]="dpagesize"
						(pageChange)="load_tender_drawings_page($event)"
					></ngb-pagination>
				</div>
				<div
					class="col-lg-3 col-sm-3"
					style="display: flex; justify-content: end; margin: auto"
				>
					<mat-select
						[(ngModel)]="dpagesize"
						(selectionChange)="load_tender_drawings_pagesize($event.value)"
					>
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #AddTenderDrawingForm>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_drawing" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div class="modal-body">
				<div class="form-group">
					<label for="group1_id">{{ "lang_group_one" | language }}</label>
					<ng-select
						[items]="group1List_form"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						appendTo="body"
						clearAllText="Clear"
						placeholder="{{ 'lang_group_one' | language }}"
						formControlName="platform_group1_code_system"
						id="group1_id"
						(change)="get_group2list({ term: '' }, true)"
					>
					</ng-select>
				</div>
				<div class="form-group">
					<label for="group2_id">{{ "lang_group_two" | language }}</label>
					<ng-select
						[items]="group2List_form"
						[multiple]="false"
						bindLabel="fulltext"
						bindValue="value"
						appendTo="body"
						clearAllText="Clear"
						placeholder="{{ 'lang_group_two' | language }}"
						formControlName="platform_group2_code_system"
						id="group2_id"
					>
					</ng-select>
				</div>
				<div class="form-group">
					<label for="drawing_main_type">{{
						"lang_drawing_main_type" | language
					}}</label>
					<ng-select
						[items]="drawing_main_type_list"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						appendTo="body"
						clearAllText="Clear"
						placeholder="{{ 'lang_drawing_main_type' | language }}"
						formControlName="drawings_main_type"
						id="drawing_main_type"
					>
					</ng-select>
				</div>
				<div class="form-group" *ngIf="!form.get('drawing_file_id').value">
					<label for="drawings_type_id"
						>{{ "lang_drawing_types" | language }}:<span class="red"
							>*</span
						></label
					>
					<ng-select
						[items]="Dtypes"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						appendTo="body"
						formControlName="drawings_type_id"
						placeholder="{{ 'lang_drawing_types' | language }}"
					>
					</ng-select>
				</div>
				<div class="form-group">
					<label for="drawings_name"
						>{{ "lang_drawing_name" | language }}:<span class="red"
							>*</span
						></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="drawings_name"
					/>
					<mat-error
						*ngIf="
							form.controls['drawings_name'].touched &&
							form.controls['drawings_name'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="drawings_number"
						>{{ "lang_drawing_number" | language }}:<span class="red"
							>*</span
						></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="drawings_number"
					/>
					<mat-error
						*ngIf="
							form.controls['drawings_number'].touched &&
							form.controls['drawings_number'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="level_keys"
						>{{ "lang_file_secutrity_levels" | language }} :
						<span class="red">*</span></label
					>
					<ng-select
						[items]="filepermissionlevels"
						[multiple]="true"
						bindLabel="label"
						appendTo="body"
						bindValue="value"
						clearAllText="Clear"
						placeholder="{{ 'lang_file_secutrity_levels' | language }}"
						formControlName="level_keys"
					>
					</ng-select>
					<mat-error
						*ngIf="
							form.controls['level_keys'].touched &&
							form.controls['level_keys'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="drawings_attachment_url"
						>{{ "lang_attachments" | language }}:
						<span class="red" *ngIf="!form.get('drawing_file_id').value">
							*
						</span>
						<span *ngIf="isFile" for="drawings_attachment_url">
							<i
								aria-hidden="true"
								(click)="removeFile()"
								class="fa faicon fa-close red"
							></i>
						</span>
					</label>
					<div class="IDDIV">
						<input
							style="display: block"
							name="drawings_attachment_url"
							type="file"
							required
							onclick="this.value = null"
							(change)="UploadFile($event)"
							class="nghide albutton width100"
						/>
						<label id="uploadText" class="albutton">{{ filename }} </label>
					</div>
					<mat-error
						*ngIf="
							form.controls['drawings_attachment_url'].touched &&
							form.controls['drawings_attachment_url'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #Dviewform>
	<div class="modal-content">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_view" | language }}</h4>
				<button
					type="button"
					class="close"
					data-dismiss="modal"
					(click)="modalRef.hide()"
				>
					&times;
				</button>
			</div>
			<div class="modal-body" *ngIf="Dviewdata.length != '0'">
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_group_one" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{
								Dviewdata.platform_group1_code_system
							}}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_group_two" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{
								Dviewdata.platform_group2_code_system
							}}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_drawing_main_type" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{
								getMainTypeName(Dviewdata.drawings_main_type)
							}}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_drawing_types" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{ Dviewdata.drawing_type }}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_drawing_name" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{ Dviewdata.drawings_name }}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_drawing_number" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{ Dviewdata.drawings_number }}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_writer" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{ Dviewdata.writer }}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_ondate" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{ Dviewdata.drawings_created_datetime }}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_onupdate" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall" *ngIf="Dviewdata.drawings_updated_datetime">{{
								Dviewdata.drawings_updated_datetime
							}}</b>
							<b class="breakall" *ngIf="!Dviewdata.drawings_updated_datetime"
								>----</b
							>
						</div>
					</div>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button
					type="button"
					class="btn btn-secondary secondarybutton"
					data-dismiss="modal"
					(click)="modalRef.hide()"
				>
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</ng-template>
<div class="modal" id="pdfPreviewDraw">
	<div class="modal-dialog width70">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_preview" | language }}</h4>
				<button type="button" class="close" data-dismiss="modal">
					&times;
				</button>
			</div>
			<div class="modal-body">
				<div class="pdf-container">
					<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</div>
