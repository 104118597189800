import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { PreviewPdfComponent } from "@shared/components/preview-pdf/preview-pdf.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { OwlOptions } from "ngx-owl-carousel-o";
import { AttachmentsComponent } from "../panels/attachments/attachments.component";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-eview",
  templateUrl: "./eview.component.html",
  styleUrls: ["./eview.component.scss"],
})
export class EviewComponent implements OnInit {
  @ViewChild(AttachmentsComponent) attachcomponent: AttachmentsComponent;
  public profile_percentage: number;
  module: string = "hr";
  apiurl = environment.SERVER_ORIGIN;
  id = this.route.snapshot.paramMap.get("id");
  branch_id = this.route.snapshot.paramMap.get("bid");
  key = this.route.snapshot.paramMap.get("key");
  delete: boolean = false;
  edit: boolean = false;
  add: boolean = false;
  is_admin: any = false;
  employee_id_number: string;
  viewdata: any;
  attachments: any = [];
  idfilename = this.lang.transform("lang_select_files");
  formstatus: boolean = false;
  formerror: string = "";
  attachform: FormGroup;
  view_profile_image = "";
  modalRefA: BsModalRef;
  modalRefB: BsModalRef;
  actionmethod = "EVIEW";
  filepermissionlevels: any = [];
  selectedIndex = 0;
  @ViewChild("stepper") myStepper: MatStepper;
  device:any=null
  devices: any=[];
  public fileurl;

  accountmasterform: FormGroup;
  profile_image: any;

  constructor(
    public router: Router,
    public ds: DataService,
    public route: ActivatedRoute,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.get_view_details();
    this.get_devices_list()
    this.file_permission_levels();
    this.attach_form();
    this.route.queryParams.subscribe((params) => {
      if (params.action == "create-joining") {
        this.create_joining_form(null);
      }
    });
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  selectionChange(e: any) {
    this.selectedIndex = e.selectedIndex;
  }

  public file_permission_levels() {
    this.filepermissionlevels = [];
    this.ds.getActionByUrl([], "lflevel").subscribe((res) => {
      if (res.status) {
        this.filepermissionlevels = res.records;
      }
    });
  }
  @ViewChild("stepper") stepper: MatStepper;
  selectedName = "human_resources_id_details";
  lang_key = localStorage.getItem("lang_key") || "en";
  customOptions: OwlOptions = {
    loop: false,
    rtl: this.lang_key == "en" ? false : true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 6,
      },
      1200: {
        items: 6,
      },
    },
    nav: false,
  };
  singleUser = true;
  moveStepper(name: string) {
    const icons = document?.querySelectorAll(".stepper-header-icon");
    const selectedIcon = document?.querySelector(`.${name}`);
    let index: number = 0;
    icons.forEach((item, key) => {
      item?.classList?.remove("stepper-active");
      if (item?.classList?.contains(name)) {
        index = key;
      }
    });
    selectedIcon?.classList?.add("stepper-active");
    this.stepper.selectedIndex = index;
    this.selectedName = name;
  }

  openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  openModalB(template: TemplateRef<any>) {
    this.modalRefB = this.modalService.show(template, environment.modelconfig);
  }
  getemployeedetails() {
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          [],
          "human_resources/get_employee_details/" +
            this.id +
            "/" +
            this.branch_id
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
 
            } else {
              this.ds.dialogf("", data.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }
  get_view_details(load: boolean = false) {
    this.ds
      .getActionByUrl(
        [],
        "human_resources/get_myview_data/" + this.id + "/" + this.branch_id
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.viewdata = data.data;
            this.employee_id_number = data.data.employee_id_number;
            this.attachments = data.attachments;
            this.is_admin = data.is_admin;
            this.edit = data.edit;
            this.add = data.add;
            this.delete = data.delete;
            if (data.profile_percentage) {
              this.profile_percentage = data.profile_percentage.total;
            }
            if (this.viewdata.profile_image) {
              this.get_profile_image(this.viewdata.profile_image);
            }
          } else {
            this.viewdata = "";
            this.ds.dialogf("", data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.viewdata = "";
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  changeimage(){
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    this.subscriptions.add(
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.updateEmployee()
            }else{
              this.fileurl=''
            }
          })      
      )
  }
  updateEmployee() {
    this.spinner.show();
    let param = new FormData();
    param.append("employee_id_number_old", this.viewdata.employee_id_number || '');
    param.append("employee_id_number", this.viewdata.employee_id_number || '');
    param.append("branch_id", this.viewdata.branch_id|| '');
    param.append("firstname_english", this.viewdata.firstname_english|| '');
    param.append("secondname_english", this.viewdata.secondname_english|| '');
    param.append("thirdname_english",this.viewdata.thirdname_english|| '');
    param.append("lastname_english", this.viewdata.lastname_english|| '');
    param.append("firstname_arabic", this.viewdata.firstname_arabic|| '');
    param.append("secondname_arabic", this.viewdata.secondname_arabic|| '');
    param.append("thirdname_arabic", this.viewdata.thirdname_arabic|| '');
    param.append("lastname_arabic", this.viewdata.lastname_arabic|| '');
    param.append("iqama_expiry_date_english", this.viewdata.iqama_expiry_date_english|| '');
    param.append("iqama_expiry_date_arabic", this.viewdata.iqama_expiry_date_arabic|| '');
    param.append("copy_number", this.viewdata.copy_number|| '');
    param.append("birth_date_english", this.viewdata.birth_date_english|| '');
    param.append("birth_date_arabic", this.viewdata.birth_date_arabic|| '');
    param.append("gender", this.viewdata.gender|| '');
    param.append("nationality", this.viewdata.nationality|| '');
    param.append("profile_image", this.profile_image|| '');
    param.append("job_title_iqama", this.viewdata.job_title_iqama|| '');
    param.append("marital_status", this.viewdata.marital_status|| '');
    param.append("religion", this.viewdata.religion|| '');
    param.append("work_domain", this.viewdata.work_domain|| '');
    param.append("work_location", this.viewdata.work_location|| '');
    param.append("work_type", this.viewdata.work_type|| '');
    param.append("employee_title", this.viewdata.employee_title|| '');
    param.append("group_id", this.viewdata.group_id|| '');
    param.append("legal_situation_status", this.viewdata.legal_situation_status|| '');
    param.append("total_previous_contract_days", this.viewdata.total_previous_contract_days|| '');
    param.append("primary_mobile", this.viewdata.primary_mobile|| '');
    param.append("primary_address", this.viewdata.primary_address|| '');
    param.append("primary_email", this.viewdata.primary_email|| '');
    param.append("account_number", this.viewdata.account_number|| '');
    param.append("bank_short_code", this.viewdata.bank_short_code|| '');
    param.append("primary_graduation_year", this.viewdata.primary_graduation_year|| '');
    param.append("primary_education_level", this.viewdata.primary_education_level|| '');
    param.append("rating_degree", this.viewdata.rating_degree|| '');
    param.append("membership_number", this.viewdata.membership_number|| '');
    param.append("membership_expiry_date_english", this.viewdata.membership_expiry_date_english|| '');
    param.append("membership_expiry_date_arabic", this.viewdata.membership_expiry_date_arabic|| '');
    param.append("passport_number", this.viewdata.passport_number|| '');
    param.append("passport_issue_date_english", this.viewdata.passport_issue_date_english|| '');
    param.append("passport_issue_date_arabic", this.viewdata.passport_issue_date_arabic|| '');
    param.append("passport_expiry_date_english", this.viewdata.passport_expiry_date_english|| '');
    param.append("passport_expiry_date_arabic", this.viewdata.passport_expiry_date_arabic|| '');
    param.append("passport_issue_place", this.viewdata.passport_issue_place|| '');
    param.append("insurance_number", this.viewdata.insurance_number|| '');
    param.append("insurance_date", this.viewdata.insurance_date|| '');
    param.append("insurance_type_class", this.viewdata.insurance_type_class|| '');
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          param,
          "human_resources/update_employee_details/" + this.id
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
              this.sweetAlert.successToast(
                data.msg,
                2000
              );
              this.get_view_details(true)
            } else {
              this.sweetAlert.errorToast(
                data.error,
                2000
              );
            }
          },
          (error) => {
            this.spinner.hide();
            this.sweetAlert.errorToast(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error"),
                2000
            );
          }
        )
    );
  }
  get_devices_list(load: boolean = false) {
    this.ds
      .getActionByUrl(
        [],
        "human_resources/devices"
      )
      .subscribe(
        (data) => {
          if (data.status) {
            this.devices = data?.records;
          } else {
            this.devices=[]
          }
        },
        (error) => {
          this.devices=[]
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  emptyAttachForm(type) {
    this.attachform.reset();
    this.attachform.get("employee_number").setValue(this.id);
    this.attachform.get("attachment_type").setValue(type);
    this.idfilename = this.lang.transform("lang_select_files");
  }
  attach_form() {
    this.attachform = new FormGroup({
      employee_number: new FormControl(this.id, [Validators.required]),
      attachment_type: new FormControl("", [Validators.required]),
      attachment_descriptions: new FormControl("", [Validators.required]),
      attachment_link: new FormControl("", [Validators.required]),
      level_keys: new FormControl("", [Validators.required]),
    });
  }
  uploadIDFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.attachform
        .get("attachment_link")
        .setValue(fileid, { emitEvent: true });
    } else {
      this.attachform.get("attachment_link").setValue(null);
      this.idfilename = this.lang.transform("lang_select_files");
    }
  }
  load_updated_attachments(event) {
    this.get_view_details(true);
    this.idfilename = this.lang.transform("lang_select_files");

    this.ngOnInit();
  }
  preview(data) {
    this.ds.getActionByUrl([], data).subscribe(
      (res) => {
        if (res.status) {
          if (res.extension == "pdf") {
            let src = "data:" + res["content-type"] + ";base64," + res.base64;
            const dialogRef = this.dialog.open(PreviewPdfComponent, {
              disableClose: false,
              width: "100%",
              data: {
                src: src,
                base64: res.base64,
              },
            });
          } else {
            this.ds.showPreviewWithoutAPi(res);
          }
        } else {
          this.alert.error(data.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.alert.error(
          error.error + (error && error.error && error.error.error)
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  upload_files() {
    this.spinner.show();
    this.ds
      .postActionByUrl(
        this.ds.json2formdata(this.attachform.value),
        "hr_upload_attachments"
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          this.formstatus = data.status;
          if (data.status) {
            this.get_view_details(true);
            this.load_updated_attachments("");
            setTimeout(() => {
              this.modalRefA.hide();
            }, 1000);
            this.alert.success(data.msg);
          } else {
            this.alert.error(data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error.error + (error && error.error && error.error.error)
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  add_person(emp_number:any,path:any) {
    this.spinner.show();
    let param = new FormData();
    param.append("device_id", this.device);
    param.append("employee_number", emp_number);
    // param.append("profile_picture", path);
    this.ds
      .postActionByUrl(
        param,
        "human_resources/add_person"
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.sweetAlert.successToast(
              this.lang.transform("lang_success"),
              2000
            );
            this.get_view_details(true);
          } else {
            this.sweetAlert.errorToast(data.error, 2000);
          }
        },
        (error) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(this.lang.transform("lang_internal_server_error"), 2000);
        }
      );
  }
  get_profile_image(url) {
    this.view_profile_image = "";
    this.ds.getActionByUrl([], url).subscribe((data) => {
      if (data.status) {
        this.view_profile_image =
          "data:" + data["content-type"] + ";base64," + data.base64;
      }
    });
  }
  //dynamic loading
  default = true;
  contractObj = "";
  contract_status = "new";
  contract_data;
  create_contract_form({ event, contract, status, contract_data }) {
    this.default = true;
    if (event == "CTRCR2") {
      this.default = false;
    } else if (event == "EPP") {
      this.actionmethod = "EPP";
      this.contractObj = contract;
      return 0;
    } else if (event == "ECTRCR") {
      this.actionmethod = "ECTRCR";
      this.contractObj = contract;
      this.default = false;
      return 0;
    }
    this.actionmethod = "CTRCR";
    this.contract_status = status;
    this.contract_data = contract_data;
  }
  showEmployeeView(event) {
    this.actionmethod = "EVIEW";
    this.selectedIndex = 0;
    this.selectedName = "human_resources_id_details";
  }
  showMainPage(event?) {
    this.actionmethod = "EVIEW";
    this.selectedIndex = 0;
    this.selectedName = "human_resources_id_details";
  }
  create_joining_form(event) {
    this.actionmethod = "JNGCR";
  }
  create_vacation_form(event) {
    this.actionmethod = "V1CR";
  }
  create_settlement_form(event) {
    this.actionmethod = event;
  }

  create_loan_form(event) {
    this.actionmethod = event;
  }
  create_hr_project(event) {
    this.actionmethod = event;
  }
  back_to_hr_project() {
    this.spinner.show();
    this.showMainPage();
    setTimeout(() => {
      this.moveStepper("projects");
      this.spinner.hide();
    }, 50);
  }
  public onFileChange(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			var reader = new FileReader();
			const file = event.target.files[0];

			if (file.type.includes("image") && file.size > 300000) {
        this.sweetAlert.errorToast(
          this.lang.transform("lang_image_not_exceed_300kb"),
          2000
        );
				return 0;
			}else{
        this.changeimage()
      }

			reader.readAsDataURL(file);
			reader.onload = (event) => {
				if (event) {
					this.fileurl = (<FileReader>event.target).result;
				}
			};
      this.profile_image=file
		} else {
			this.fileurl = "";
      this.profile_image=''
		}
	}
	public removeProfile() {
		this.fileurl = "";
	}
}

