import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
  selector: "app-initial-delivery-platform-add",
  templateUrl: "./initial-delivery-platform-add.component.html",
  styleUrls: ["./initial-delivery-platform-add.component.scss"],
})
export class InitialDeliveryPlatformAddComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key") || "en";
  form: FormGroup;
  projects_profile_id = "";
  projects_work_area_id = "";
  templateLists: any = [];
  typeLists: any = [];
  group1List: any = [];
  group2List: any = [];
  typemodel: any = null;
  group1model: any = null;
  group2model: any = null;
  templatemodel: any = null;
  searchKey = "";
  page_no: any = 1;
  page_size: any = 10;
  wholedata: any;
  nodata: string = this.lang.transform("lang_loading");
  constructor(
    private fb: FormBuilder,
    private ds: DataService,
    private sweetAlert: SweetAlertService,
    private lang: LanguagePipe,
    private spinner: PreloaderService,
    private dialogRef: MatDialogRef<InitialDeliveryPlatformAddComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.projects_profile_id = data.projects_profile_id;
    this.projects_work_area_id = data.projects_work_area_id;
  }

  ngOnInit(): void {
    this.buildForm();
    this.getTemplateList();
  }
  buildForm() {
    this.form = this.fb.group({
      platform_code_systems: ["", Validators.required],
    });
  }

  getTemplateList() {
    this.templateLists = [];
    let formData = new FormData();
    formData.append("lang_key", this.lang_key);
    formData.append("projects_work_area_id", this.projects_work_area_id);
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "p/get_templates_for_transactions"
      )
      .subscribe((res) => {
        if (res.status) {
          this.templateLists = res.records;
        }
      });
  }
  public load_group1_type_group2_lists(type?) {
    let sendAPI = false;
    if (type == "group1") {
      this.group1List = this.typeLists = this.group2List = [];
      if (this.templatemodel) {
        sendAPI = true;
      }
    } else if (type == "type") {
      this.typeLists = this.group2List = [];
      if (this.templatemodel && this.group1model) {
        sendAPI = true;
      }
    } else if (type == "group2") {
      this.group2List = [];
      if (this.templatemodel && this.group1model && this.typemodel) {
        sendAPI = true;
      }
    }

    if (sendAPI) {
      this.spinner.show();
      let param = new FormData();
      param.append("projects_work_area_id", this.projects_work_area_id || "");
      param.append("template_id", this.templatemodel || "");
      if (type == "group1") {
        param.append("required_type", "group1");
        param.append("group1", "");
        param.append("type", "");
      }
      if (type == "type") {
        param.append("required_type", "type");
        param.append("group1", this.group1model || "");
        param.append("type", "");
      }
      if (type == "group2") {
        param.append("required_type", "group2");
        param.append("group1", this.group1model || "");
        param.append("type", this.typemodel || "");
      }

      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "pforms/get_group1_type_group2"
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              if (type == "group1") {
                this.group1List = res.records;
              } else if (type == "type") {
                this.typeLists = res.records;
              } else if (type == "group2") {
                this.group2List = res.records;
              }
            }
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }

  get_all_forms_for_transactions() {
    if (
      (this.group1model &&
        this.typemodel &&
        this.group2model &&
        this.templatemodel) ||
      this.searchKey
    ) {
      let param = new FormData();
      param.append("search_key", this.searchKey || "");
      param.append("projects_profile_id", this.projects_profile_id || "0");
      param.append("platform_group1_code_system", this.group1model || "");
      param.append("platform_group_type_code_system", this.typemodel || "");
      param.append("platform_group2_code_system", this.group2model || "");
      param.append("template_id", this.templatemodel || "");
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "p/formstransactions/" +
            this.projects_work_area_id +
            "/" +
            this.page_no +
            "/" +
            this.page_size
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.wholedata = res;
            } else {
              this.wholedata = [];
              this.nodata = res.error;
            }
          },
          (error) => {
            this.spinner.hide();
            this.wholedata = [];
            this.nodata =
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error");
          }
        );
    }
  }
  search_by() {
    this.page_no = 1;
    this.page_size = "19";
    this.get_all_forms_for_transactions();
  }
  add_initial_delivery_platform(list) {
    this.spinner.show();
    let formData = new FormData();
    formData.append(
      "platform_code_systems",
      list?.template_platform_code_system || ""
    );
    formData.append("projects_profile_id", this.projects_profile_id || "");
    formData.append("projects_work_area_id", this.projects_work_area_id || "");
    this.ds.postActionByUrl(formData, "p/snag/initialplatformcreate").subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.dialogRef.close(true);
          this.sweetAlert.successToast(res.msg, 2000);
        } else {
          this.sweetAlert.errorToast(res?.error, 2000);
        }
      },
      (error) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error"),
          2000
        );
      }
    );
  }
}
