<h3 mat-dialog-title>{{ "lang_advanced_filter" | language }}</h3>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="filterForm">
		<div class="row">
			<div class="col-12 mt-1">
				<label for="lang_nationality_type">{{ "lang_nationality_type" | language }}</label>
				<ng-select
					[items]="metaData?.nationality_types"
					[multiple]="false"
					[bindLabel]="lang_key == 'en'? 'name_english' : 'name_arabic'"
					bindValue="id"
					appendTo="body"
					clearAllText="Clear"
					formControlName="nationality_type"
					placeholder="{{ 'lang_nationality_type' | language }}"
				>
				</ng-select>
			</div>

			<div class="col-12 mt-1" *ngIf="filterForm.value.nationality_type != 'saudi'">
				<label for="lang_nationality">{{ "lang_projects_nationality" | language }}</label>
				<ng-select
					[items]="countries"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					formControlName="nationality"
					placeholder="{{ 'lang_projects_nationality' | language }}"
				>
				</ng-select>
			</div>
			<div class="col-12 mt-1" *ngIf="projects.length>0">
				<label for="lang_project">{{ "lang_project_name_en" | language }}</label>
				<ng-select
					[items]="projects"
					[multiple]="true"
					bindLabel="quotation_subject"
					bindValue="id"
					appendTo="body"
					clearAllText="Clear"
					formControlName="project_id"
					placeholder="{{ 'lang_project_name_en' | language }}"
				>
				</ng-select>
			</div>
			<div class="col-12 mt-1" *ngIf="branches.length>0">
				<label for="branch_id">{{ "lang_branch" | language }}</label>
				<ng-select
					[items]="branches"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					
					 
					clearAllText="Clear"
					formControlName="branch_id"
					placeholder="{{ 'lang_branch' | language }}"
				>
				</ng-select>
			</div>

			<div class="col-12 mt-1">
				<label for="lang_position">{{
					"lang_position" | language
				}}</label>
			 

				<ng-select
				[items]="metaData?.positions"
				[multiple]="true"
				[bindLabel]="lang_key == 'en'? 'name_english' : 'name_arabic'"
				bindValue="id"
				
				 
				clearAllText="Clear"
				formControlName="position"
				placeholder="{{ 'lang_position' | language }}"
			>
			</ng-select>
			</div>

			<div class="col-12 mt-1">
				<label for="lang_position">{{
					"lang_status" | language
				}}</label>
			 

				<ng-select
				[items]="metaData?.employee_statuses"
				[multiple]="true"
				[bindLabel]="lang_key == 'en'? 'name_english' : 'name_arabic'"
				bindValue="id"
				
				 
				clearAllText="Clear"
				formControlName="employee_status"
				placeholder="{{ 'lang_status' | language }}"
			>
			</ng-select>
			</div>
			<div class="col-12 mt-1">
				<label for="lang_iqama_expiry_date">{{
					"lang_iqama_expiry_date" | language
				}}</label>
			 
				<mat-form-field appearance="outline"  style="width: 100%;">
					<!-- <mat-label>{{'lang_iqama_expiry_date'|language}}</mat-label> -->
					<mat-date-range-input   [rangePicker]="picker">
					  <input matStartDate formControlName="iqama_expiry_date_from" readonly placeholder="Start date">
					  <input matEndDate formControlName="iqama_expiry_date_to" readonly placeholder="End date">
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				  
					<mat-error *ngIf="filterForm.controls.iqama_expiry_date_from?.hasError('matStartDateInvalid')">Invalid start date</mat-error>
					<mat-error *ngIf="filterForm.controls.iqama_expiry_date_to?.hasError('matEndDateInvalid')">Invalid end date</mat-error>
				  </mat-form-field>
			
			</div>
			


			<div class="col-12 mt-1">
				<label for="lang_iqama_expiry_date">{{
					"lang_passport_expiry_date" | language
				}}</label>
				<mat-form-field appearance="outline"  style="width: 100%;">
				<!-- 	<mat-label>{{'lang_passport_expiry_date'|language}}</mat-label> -->
					<mat-date-range-input   [rangePicker]="picker2">
					  <input matStartDate formControlName="passport_expiry_date_from" readonly placeholder="Start date">
					  <input matEndDate formControlName="passport_expiry_date_to" readonly placeholder="End date">
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
					<mat-date-range-picker #picker2></mat-date-range-picker>
				  
					<mat-error *ngIf="filterForm.controls.passport_expiry_date_from?.hasError('matStartDateInvalid')">Invalid start date</mat-error>
					<mat-error *ngIf="filterForm.controls.passport_expiry_date_to?.hasError('matEndDateInvalid')">Invalid end date</mat-error>
				  </mat-form-field>
			
			</div>
			<div class="col-12 mt-1">
				<label for="lang_iqama_expiry_date">{{
					"lang_membership_expiry_date" | language
				}}</label>
				<mat-form-field appearance="outline"  style="width: 100%;">
					<!-- <mat-label>{{'lang_membership_expiry_date'|language}}</mat-label> -->
					<mat-date-range-input   [rangePicker]="picker3">
					  <input matStartDate formControlName="membership_expiry_date_from" readonly placeholder="Start date">
					  <input matEndDate formControlName="membership_expiry_date_to" readonly placeholder="End date">
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
					<mat-date-range-picker #picker3></mat-date-range-picker>
				  
					<mat-error *ngIf="filterForm.controls.membership_expiry_date_from?.hasError('matStartDateInvalid')">Invalid start date</mat-error>
					<mat-error *ngIf="filterForm.controls.membership_expiry_date_to?.hasError('matEndDateInvalid')">Invalid end date</mat-error>
				  </mat-form-field>
			
			</div>
			<div class="col-12 mt-1">
				<label for="lang_iqama_expiry_date">{{
					"lang_contract_expiry_date" | language
				}}</label>
				<mat-form-field appearance="outline"  style="width: 100%;">
					<!-- <mat-label>{{'lang_contract_expiry_date'|language}}</mat-label> -->
					<mat-date-range-input   [rangePicker]="picker4">
					  <input matStartDate formControlName="contract_expiry_date_from" readonly placeholder="Start date">
					  <input matEndDate formControlName="contract_expiry_date_to" readonly placeholder="End date">
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
					<mat-date-range-picker #picker4></mat-date-range-picker>
				  
					<mat-error *ngIf="filterForm.controls.contract_expiry_date_from?.hasError('matStartDateInvalid')">Invalid start date</mat-error>
					<mat-error *ngIf="filterForm.controls.contract_expiry_date_to?.hasError('matEndDateInvalid')">Invalid end date</mat-error>
				  </mat-form-field>
			
			</div>

			<div class="col-12 mt-1">
				<label for="employee_name">{{
					"lang_human_resources_employee_name" | language
				}}</label>
				<input
					type="text"
					class="form-control"
					id="employee_name"
					name="employee_name"
		 
					formControlName="employee_name"
				/>
			</div>

			<div class="col-12 mt-1">
				<label for="employee_id">{{
					"lang_human_resources_passport_number" | language
				}}</label>
				<input
					type="text"
					class="form-control"
					id="national_id"
					name="national_id"
		 
					formControlName="national_id"
				/>
			</div>
			<div class="col-12 mt-1">
				<label for="lang_employee_id">{{
					"lang_human_resources_id_number" | language
				}}</label>
				<input
					type="text"
					class="form-control"
					id="lang_employee_id"
					name="lang_employee_id"
		 
					formControlName="employee_id"
				/>
			</div>

			<div class="col-12 mt-1">
				<label for="lang_emp_user_id">{{
					"lang_human_resources_id_details" | language
				}}</label>
				<input
					type="text"
					class="form-control"
					id="lang_emp_user_id"
					name="lang_emp_user_id"
		 
					formControlName="emp_user_id"
				/>
			</div>

			<div class="col-12 mt-1">
				<label for="lang_employee_number">{{
					"lang_human_resources_number" | language
				}}</label>
				<input
					type="text"
					class="form-control"
					id="lang_employee_number"
					name="lang_employee_number"
		 
					formControlName="employee_number"
				/>
			</div>
		 
 
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="d-flex justify-content-between" style="width: 100%">
		<div style="width: 100px">
			<button type="button" class="albutton" (click)="resetFilterForm()">
				{{ "lang_reset" | language }}
			</button>
		</div>

		<div style="width: 100px">
			<button
				mat-button
				class="albutton mnabrbutton mat-accent"
				(click)="sendFilterForm()"
			>
				{{ "lang_save" | language }}
			</button>
		</div>
	</div>
</mat-dialog-actions>
