<p-table
	[columns]="selectedColumns"
	[value]="products"
	[paginator]="true"
	currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	[rowsPerPageOptions]="[10, 25, 50]"
	[rows]="10"
>
	<ng-template pTemplate="caption">
		<p-multiSelect
			[options]="cols"
			[(ngModel)]="selectedColumns"
			optionLabel="header"
			selectedItemsLabel="{0} columns selected"
			[style]="{ minWidth: '200px' }"
			placeholder="Choose Columns"
		></p-multiSelect>
	</ng-template>
	<ng-template pTemplate="header" let-columns>
		<tr>
			<th *ngFor="let col of columns">
				{{ col.header }}
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-product let-index let-columns="columns">
		<tr>
			<td *ngFor="let col of columns">
				{{ product[col.field] }}
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="paginatorleft">
		<p-button
			type="button"
			icon="pi pi-plus"
			styleClass="p-button-text"
		></p-button>
	</ng-template>
	<ng-template pTemplate="paginatorright">
		<p-button
			type="button"
			icon="pi pi-cloud"
			styleClass="p-button-text"
		></p-button>
	</ng-template>
</p-table>
