<form [formGroup]="form">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between">
      <ng-container>
        <h4>{{ "lang_add_device" | language }}</h4>
      </ng-container>

      <span (click)="close()"><i class="fa fa-times"></i></span>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <div>
        <div class="row">
          <div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_device_name" | language }}</mat-label>
              <input matInput type="text" formControlName="device_name" />
              <!-- <mat-error
                *ngIf="
                  form.controls['device_name'].touched &&
                  form.controls['device_name'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error> -->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_device_password" | language }}</mat-label>
              <input matInput type="text" formControlName="device_password" />
              <!-- <mat-error
                *ngIf="
                  form.controls['device_password'].touched &&
                  form.controls['device_password'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error> -->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_device_ip" | language }}</mat-label>
              <input matInput type="text" formControlName="device_ip" />
              <!-- <mat-error
                *ngIf="
                  form.controls['device_ip'].touched &&
                  form.controls['device_ip'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error> -->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_device_port" | language }}</mat-label>
              <input matInput type="text" formControlName="device_port" />
              <!-- <mat-error
                *ngIf="
                  form.controls['device_port'].touched &&
                  form.controls['device_port'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error> -->
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ "lang_device_key" | language }}</mat-label>
              <input matInput type="text" formControlName="device_key" />
              <!-- <mat-error
                *ngIf="
                  form.controls['device_key'].touched &&
                  form.controls['device_key'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error> -->
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="col-md-4 col-xs-12">
      <button
        type="button"
        class="albutton mnabrbutton mat-accent"
        mat-button
        (click)="closeModal()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
    <div class="col-md-4 col-xs-12"></div>
    <div class="col-md-4 col-xs-12">
      <button
        type="button"
        class="albutton mnabrbutton mat-accent"
        mat-button
        (click)="submitForm()"
      >
        <ng-container>
          {{ "lang_save" | language }}
        </ng-container>
      </button>
    </div>
  </mat-dialog-actions>
</form>
