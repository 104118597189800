import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-initial-delivery",
	templateUrl: "./initial-delivery.component.html",
	styleUrls: ["./initial-delivery.component.scss"],
})
export class InitialDeliveryComponent implements OnInit {
	@Input() supervision_data: any;
	@Input() able2add: any;
	@Input() able2edit: any;
	@Input() project_data: any;
	@Input() makeActionInitial: any;
	constructor() {}

	ngOnInit(): void {}
}
