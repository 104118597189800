import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { Subscription } from "rxjs";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
@Component({
  selector: "app-invoice-notes-list",
  templateUrl: "./invoice-notes-list.component.html",
  styleUrls: ["./invoice-notes-list.component.scss"],
})
export class InvoiceNotesListComponent implements OnInit {
  branches: any = [];
  InvData: any = [];
  branch_id: any =
    localStorage.getItem("selectedBranch") != null
      ? localStorage.getItem("selectedBranch")
      : "0";
  lodingdatas = this.lang.transform("lang_loading");
  finances: any = [];
  Invpage_no: any = 1;
  Invpage_size: any = "10";
  finance_id: any = null;
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public router: Router,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    private sweetAlert: SweetAlertService
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != "0") {
      this.load_financial_years();
    }
  }

  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public load_financial_years() {
    this.finances = [];
    this.finance_id = null;
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
              }
            });
          }
          this.load_notes_list();
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public selectBrach(branch) {
    if (branch && branch != "0") {
      localStorage.setItem("selectedBranch", branch);
      this.branch_id = branch;
      this.load_financial_years();
    } else {
      this.spinner.hide();
    }
  }
  public loadPageInv(page) {
    this.spinner.show();
    this.Invpage_no = page;
    this.Invpage_size = this.Invpage_size;
    this.load_notes_list();
  }
  public loadInvPagesize(size) {
    this.spinner.show();
    this.Invpage_no = 1;
    this.Invpage_size = size;
    this.load_notes_list();
  }
  public load_notes_list() {
    let formdata = new FormData();
    formdata.append("finance_id", this.finance_id || "");
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(formdata),
          `accounts/list_debit_notes/${this.branch_id}/${this.Invpage_no}/${this.Invpage_size}`
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.InvData = res;
            } else {
              this.InvData = [];
              this.lodingdatas = res.error;
            }
          },
          (error) => {
            this.spinner.hide();
            this.InvData = [];
            this.lodingdatas =
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error");
          }
        )
    );
  }
  public remove_invoice(data) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true,
      width: "400px",
      data: {
        title: this.lang.transform("lang_confirmation"),
        message: this.lang.transform("lang_are_you_sure"),
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show();
        this.subscriptions.add(
          this.ds
            .deleteActionByUrl(
              [],
              "dpinvoice/" +
                data.branch_id +
                "/" +
                data.transaction_id +
                "/" +
                data.invoice_id
            )
            .subscribe(
              (data) => {
                this.spinner.hide();
                if (data.status) {
                  this.load_notes_list();
                  this.ds.dialogf("", data.msg);
                } else {
                  this.ds.dialogf("", data.error);
                }
              },
              (error) => {
                this.spinner.hide();
                this.ds.dialogf(
                  "",
                  error && error.error && error.error.error
                    ? error.error.error
                    : this.lang.transform("lang_internal_server_error")
                );
              }
            )
        );
      }
    });
  }
  public send_to_zatca(invoice_id) {
    this.spinner.show();
    let param = new FormData();
    param.append("branch_id", this.branch_id);
    param.append("invoice_id", invoice_id);
    this.ds
      .postActionByUrl(param, "accounts/send_tax_invoice_debit_note")
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.sweetAlert.successToast(res?.msg, 2000);
          } else {
            this.sweetAlert.errorToast(res?.error, 2000);
          }
        },
        (error) => {
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
}
