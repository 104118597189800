<div class="row">
	<div class="col-12 mb-3">
		<div class="d-flex justify-content-end">
			<button class="add-btn" (click)="onAddUpdateMeeting()">
				<i class="pi pi-plus"></i>
				<span>{{ "lang_add" | language }}</span>
			</button>
		</div>
	</div>
</div>
<div class="meeting-list row">
	<div class="col-12 col-sm-6" *ngFor="let item of meetings">
		<div class="meeting-item" (click)="onViewMeeting(item?.meeting_id)">
			<div class="mi-icon">
				<i class="fa fa-meetup"></i>
			</div>
			<div class="mi-content">
				<h4>{{ item?.meeting_title }}</h4>
				<div class="mi-content-body">
					<span class="micb-date"
						>{{ "lang_date" | language }} {{ item?.meeting_date_en }}</span
					>
					<div class="micb-divider"></div>
					<span
						class="mi-content-body-icon"
						(click)="onAddUpdateMeeting($event, item)"
						>{{ "lang_edit" | language }}</span
					>
					<div class="micb-divider"></div>
					<span
						class="mi-content-body-icon"
						(click)="deleteMeeting($event, item?.meeting_id)"
						>{{ "lang_delete" | language }}</span
					>
					<div class="micb-divider"></div>
					<span
						class="mi-content-body-icon"
						(click)="convertMeetingToChecklist($event, item?.meeting_id)"
						>{{ "lang_convert_to_checklist" | language }}</span
					>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row pt-2 w-100 pagination responsive_pagination2">
	<div
		class="col-12 col-md-4 col-sm-12 d-flex align-items-center total"
		*ngIf="meetings?.length != 0"
	>
		<p style="font-size: 16px; margin: 0">
			{{ "lang_total" | language }}: {{ collection_size }}
		</p>
	</div>
	<div
		class="col-12 col-md-4 col-sm-12 mt-2 total mt-sm-0 d-flex align-items-center justify-content-center"
		*ngIf="meetings?.length != 0"
	>
		<ngb-pagination
			[collectionSize]="collection_size"
			[pageSize]="+page_size"
			[(page)]="page_no"
			[maxSize]="3"
			[boundaryLinks]="true"
			[rotate]="true"
			(pageChange)="getMeetingList()"
		></ngb-pagination>
	</div>
	<div
		class="col-12 col-md-4 col-sm-12 mt-2 mt-sm-0 d-flex align-items-center size_paginator justify-content-end"
		*ngIf="meetings?.length != 0"
	>
		<div style="width: 65%; text-align: center">
			<mat-select
				style="margin: auto"
				[(value)]="page_size"
				(selectionChange)="getMeetingList()"
			>
				<mat-option [value]="10"
					>10 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option [value]="20"
					>20 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option [value]="50"
					>50 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option [value]="100"
					>100 {{ "lang_per_page" | language }}</mat-option
				>
			</mat-select>
		</div>
	</div>
</div>
