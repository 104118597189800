import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  public form: FormGroup;
  sendstatus: boolean = false;
  apistatus:boolean = false;
  readonly:boolean = false;
  sendcodestatus = false;
  codemsg = this.lang.transform('lang_send_code');
  data:any;
  constructor(private cdRef: ChangeDetectorRef, public ds: DataService, public route:ActivatedRoute ,public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      id_number: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      verification_method: new FormControl('', [Validators.required]),
      verification_code: new FormControl('', [Validators.required]),
    });
  }
  get_verify_options(id) {
    this.alert.clear();
    if(id && id.length == 10) {
      this.spinner.show();
      this.ds.getActionByNoToken([], 'signup/get_verify_options/' + id).subscribe(data => {
        this.spinner.hide();
        this.apistatus = data.status;
        this.readonly = data.status;
        if(data.status){
          this.data = data.data;
        } else {
          this.sendstatus = false;
          this.alert.error(data.error);
        }
      }, error => {
        this.spinner.hide();
          this.sendstatus = false;
          this.apistatus = false;
          this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.sendstatus = false;
      this.apistatus = false;
    }
  }
  sendVerificationCode() {
    this.alert.clear();
    this.spinner.show();
    this.ds.postActionByNoToken(this.ds.json2formdata(this.form.value), 'signup/send_verify_code/').subscribe(result=>{
      this.sendstatus = result.status;
      this.sendcodestatus = result.status;
      this.spinner.hide();
      if(result.status){
        this.codemsg = this.lang.transform('lang_resend');
        this.sendstatus = true;
        setTimeout(() => {
          this.sendcodestatus = false;
        }, 120000);
      } else {
        this.alert.error(result.error);
        this.sendstatus = false;
      }
    }, error => {
        this.sendcodestatus = false;
        this.spinner.hide();
        this.sendstatus = false;
        this.alert.error(error.error + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  confirmVerification() {
    this.alert.clear();
    this.spinner.show();
    this.ds.postActionByNoToken(this.ds.json2formdata(this.form.value), 'signup/confirm_verify_code/').subscribe(result => {
      this.spinner.hide();
      if (result.status) {
        localStorage.setItem('countdown', result.expiry);
		    this.router.navigate(['viewdata/' + result.id + '/' + result.code]);
      } else {
        this.alert.error(result.error);
      }
    }, error => {
        this.spinner.hide();
        this.alert.error(error.error);
    })
  }
}
