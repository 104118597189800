<mat-accordion class="mt-2">
	<mat-expansion-panel
		class="mb-2"
		style="box-shadow: none !important; border: 1px solid #dee2e6"
	>
		<mat-expansion-panel-header
			>{{ "lang_steps_history" | language }}
		</mat-expansion-panel-header>
		<div class="material-panel-body">
			<div class="mbrl15 martb0">
				<div class="row">
					<div class="col-12 mt-2" *ngIf="step_no >= 2">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_configurations" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-4 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_languages" | language }} :
										<span style="font-weight: 500">{{
											configurationData?.language
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-4 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_subject" | language }} :
										<span style="font-weight: 500">{{
											configurationData?.subject
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-4 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_care" | language }} :
										<span style="font-weight: 500">{{
											configurationData?.care
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_transaction_to" | language }} :
										<span style="font-weight: 500">{{
											configurationData?.transaction_to
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_vat" | language }} :
										<span style="font-weight: 500">{{
											configurationData?.vat_name
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_quotation_type" | language }} :
										<span style="font-weight: 500">{{
											configurationData?.quotation_types_name
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_attachmentstitle" | language }} :
										<span style="font-weight: 500">{{
											configurationData?.attachmentTitle
										}}</span>
									</p>
								</div>
								<div class="col-12 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_description" | language }} :
									</p>
									<div [innerHtml]="configurationData?.content"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 mt-3" *ngIf="step_no >= 3">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_teams" | language }}
							</div>
							<div class="row">
								<div class="col-12">
									<div class="TableParts">
										<div
											class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
										>
											<table border="1" class="table CustomTable text-center">
												<thead>
													<tr>
														<td>#</td>
														<td>
															<span
																matTooltip="{{ 'lang_position' | language }}"
																>{{ "lang_position" | language }}</span
															>
														</td>
														<td>
															<span
																matTooltip="{{ 'lang_presence' | language }}"
																>{{ "lang_presence" | language }}</span
															>
														</td>
														<td>
															<span
																matTooltip="{{
																	'lang_projects_nationality' | language
																}}"
																>{{
																	"lang_projects_nationality" | language
																}}</span
															>
														</td>
														<td>
															<span
																matTooltip="{{ 'lang_team_number' | language }}"
																>{{ "lang_team_number" | language }}</span
															>
														</td>
														<td>
															<span
																matTooltip="{{ 'lang_experience' | language }}"
																>{{ "lang_experience" | language }}</span
															>
														</td>
														<td>
															<span
																matTooltip="{{ 'lang_proit_ratio' | language }}"
																>{{ "lang_proit_ratio" | language }}</span
															>
														</td>
														<td>
															<span
																matTooltip="{{
																	'lang_total_salary_per_person_month'
																		| language
																}}"
																>{{
																	"lang_total_salary_per_person_month"
																		| language
																}}</span
															>
														</td>
														<td>
															<span
																matTooltip="{{
																	'lang_duration_number_months' | language
																}}"
																>{{
																	"lang_duration_number_months" | language
																}}</span
															>
														</td>
														<td>
															<span
																matTooltip="{{
																	'lang_total_no_of_visits' | language
																}}"
																>{{
																	"lang_total_no_of_visits" | language
																}}</span
															><i class="red"> *</i>
														</td>
														<td>
															<span
																matTooltip="{{ 'lang_toal_bid' | language }}"
																>{{ "lang_toal_bid" | language }}</span
															>
														</td>
													</tr>
												</thead>
												<tbody class="AppendList quotationtable">
													<tr
														*ngFor="
															let item of teamsData;
															let itemIndex = index;
															let odd = odd;
															let even = even
														"
														style="background: #ffffff"
													>
														<td class="verticalalignmid">
															{{ itemIndex + 1 }}
														</td>
														<td width="10%">
															{{ item?.position_name }}
														</td>
														<td width="10%">
															{{ item?.presence_name }}
														</td>
														<td width="10%">
															{{ item?.nationality_name }}
														</td>
														<td>
															{{ item?.team_number }}
														</td>
														<td>
															{{ item?.experience }}
														</td>
														<td>{{ item?.profit_ratio }}%</td>
														<td class="readonlybyaction">
															{{ item?.total_salary_per_person_month }}
														</td>
														<td>
															{{ item?.duration_of_months }}
														</td>
														<td class="rowdisable{{ itemIndex }}">
															{{ item?.visits_per_month }}
														</td>
														<td class="readonlybyaction">
															{{ item?.total_bid_of_project }}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div class="TableParts">
										<div
											class="table-responsive customResponsive mb-10 tablefields"
										>
											<table border="1" class="table CustomTable text-center">
												<thead>
													<tr>
														<td>#</td>
														<td>
															<span
																matTooltip="{{
																	'lang_position_description' | language
																}}"
																>{{
																	"lang_position_description" | language
																}}</span
															>
														</td>
													</tr>
												</thead>
												<tbody class="AppendList">
													<tr
														*ngFor="
															let item of teamsData;
															let itemIndex = index;
															let odd = odd;
															let even = even
														"
														style="background: #ffffff"
													>
														<td width="1%" class="verticalalignmid">
															{{ itemIndex + 1 }}
														</td>
														<td>
															{{ item?.positions_task }}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 mt-3" *ngIf="step_no >= 4">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_terms_conditions" | language }}
							</div>
							<div class="row">
								<div class="col-12">
									<div class="TableParts">
										<div
											class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
										>
											<table border="1" class="table CustomTable text-center">
												<thead>
													<tr>
														<td width="5%">#</td>
														<td>
															<span
																matTooltip="{{
																	'lang_title_english' | language
																}}"
																>{{ "lang_title_english" | language }}</span
															>
														</td>
														<td>
															<span
																matTooltip="{{
																	'lang_title_arabic' | language
																}}"
																>{{ "lang_title_arabic" | language }}</span
															>
														</td>
														<td>
															<span matTooltip="{{ 'lang_type' | language }}">{{
																"lang_type" | language
															}}</span>
														</td>
													</tr>
												</thead>
												<tbody class="AppendList quotationtable">
													<tr
														*ngFor="
															let item of termsData;
															let itemIndex = index;
															let odd = odd;
															let even = even
														"
														style="background: #ffffff"
													>
														<td class="verticalalignmid">
															{{ itemIndex + 1 }}
														</td>
														<td width="10%">
															{{ item?.rf_name_en }}
														</td>
														<td width="10%">
															{{ item?.rf_name_ar }}
														</td>
														<td width="10%">
															{{ "lang_" + item?.condition_type | language }}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="col-12 mt-4" *ngIf="step_no >= 3">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_manager_approval" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Approved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 4">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_contractor_reservation" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Reserved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 5">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ " lang_contractor_approval" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_reason" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.ncr_issue_reason
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_suggestion_to_fixing" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.ncr_issue_fix_suggestion
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_preventive_action" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.ncr_issue_preventive_action
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_fixing_date" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.ncr_issue_fixing_date
										}}</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 6">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_recipient_reservation" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Reserved
									</p>
								</div>
							</div>
						</div>
					</div> -->

					<!-- <div class="col-12 mt-4" *ngIf="step_no >= 7">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_receipient_team_reply" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_confirm_fixing_way" | language }} :
										<span
											style="font-weight: 500"
											[attr.style]="
												steps_data?.ncr_issue_confirm_fixing_way == '1'
													? 'color: #20a620 !important'
													: 'color: #d02a2a !important'
											"
											>{{
												steps_data?.ncr_issue_confirm_fixing_way == "1"
													? "Accepted"
													: "Rejected"
											}}</span
										>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_note" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.ncr_issue_confirm_fixing_note
										}}</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 8">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_manager_approval" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Approved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 9">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_receipient_position" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Reserved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 10">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_contractor_close_NCR" | language }}
							</div>
							<div class="row">
								<div class="col-12 mt-2 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_note" | language }} :
										<span style="font-weight: 500"></span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 11">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_recipient_reservation" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Reserved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 12">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_contractor_close_NCR" | language }}
							</div>
							<div class="row">
								<div class="col-12 mt-2 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_close_ncr_request" | language }} :
										<span
											style="font-weight: 500"
											[attr.style]="
												steps_data?.close_ncr_request_status == 'B'
													? 'color: #20a620 !important'
													: 'color: #d02a2a !important'
											"
											>{{ steps_data?.close_ncr_request_status }}</span
										>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 13">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_manager_approval" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Approved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 14">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_QAQC_reservation" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Reserved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 15">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_QAQC_approval_operation" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Approved
									</p>
								</div>
							</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</mat-expansion-panel>
</mat-accordion>
