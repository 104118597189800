import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { environment } from "@env/environment";
declare var $: any;
@Component({
  selector: "app-sinvoice-list",
  templateUrl: "./sinvoice-list.component.html",
  styleUrls: ["./sinvoice-list.component.scss"],
})
export class SinvoiceListComponent implements OnInit, OnDestroy {
  branches: any = [];
  InvData: any = [];
  tagsData: any = [];
  branch_id: any =
    localStorage.getItem("selectedBranch") != null
      ? localStorage.getItem("selectedBranch")
      : "0";
  lodingdatas = this.lang.transform("lang_loading");
  finances: any = [];
  Invpage_no: any = localStorage.getItem("AC_SI_P") || 1;
  Invpage_size: any = localStorage.getItem("AC_SI_S") || "10";
  tagspage_no: any = 1;
  tagspage_size: any = "10";
  finance_id: any = localStorage.getItem("AC_SI_S_F") || null;
  searchKey: any = localStorage.getItem("AC_SI_S_K") || "";
  searchKeyByTags;
  qrcode_img: string;
  modalRef: BsModalRef;
  constructor(
    public ds: DataService,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    public router: Router,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != "0") {
      this.load_financial_years();
    }
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public load_financial_years() {
    this.finances = [];
    this.finance_id = null;
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
              }
            });
          }
          this.load_selling_invoices();
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public selectBrach(branch) {
    if (branch && branch != "0") {
      localStorage.setItem("selectedBranch", branch);
      this.branch_id = branch;
      this.load_financial_years();
    } else {
      this.spinner.hide();
    }
  }
  public loadPageInv(page) {
    this.spinner.show();
    this.Invpage_no = page;
    this.Invpage_size = this.Invpage_size;
    this.load_selling_invoices();
  }
  public loadInvPagesize(size) {
    this.spinner.show();
    this.Invpage_no = 1;
    this.Invpage_size = size;
    this.load_selling_invoices();
  }
  public loadPagetags(page) {
    this.spinner.show();
    this.tagspage_no = page;
    this.tagspage_size = this.tagspage_size;
    this.view_tages(true);
  }
  public loadtagsPagesize(size) {
    this.spinner.show();
    this.tagspage_no = 1;
    this.tagspage_size = size;
    this.view_tages(true);
  }
  public search_invoices() {
    this.Invpage_no = 1;
    this.Invpage_size = "10";
    this.load_selling_invoices();
  }
  public load_selling_invoices() {
    //Accounts Selling Invoice
    localStorage.setItem("AC_SI_S_K", this.searchKey);
    localStorage.setItem("AC_SI_S_F", this.finance_id);
    localStorage.setItem("AC_SI_P", this.Invpage_no);
    localStorage.setItem("AC_SI_S", this.Invpage_size);
    let formdata = new FormData();
    this.branch_id = this.branch_id ? this.branch_id : 0;
    formdata.append("search_key", this.searchKey);
    formdata.append("finance_id", this.finance_id ? this.finance_id : "0");
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          formdata,
          "listsinvoice/" +
            this.branch_id +
            "/" +
            this.Invpage_no +
            "/" +
            this.Invpage_size
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.InvData = res;
            } else {
              this.InvData = [];
              this.lodingdatas = res.error;
            }
          },
          (error) => {
            this.spinner.hide();
            this.InvData = [];
            this.lodingdatas =
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error");
          }
        )
    );
  }
  public remove_invoice(data) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true,
      width: "400px",
      data: {
        title: this.lang.transform("lang_confirmation"),
        message: this.lang.transform("lang_are_you_sure"),
      },
    });
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          this.subscriptions.add(
            this.ds
              .deleteActionByUrl(
                [],
                "dsinvoice/" +
                  data.branch_id +
                  "/" +
                  data.transaction_id +
                  "/" +
                  data.invoice_id
              )
              .subscribe(
                (data) => {
                  this.spinner.hide();
                  if (data.status) {
                    this.load_selling_invoices();
                    this.ds.dialogf("", data.msg);
                  } else {
                    this.ds.dialogf("", data.error);
                  }
                },
                (error) => {
                  this.spinner.hide();
                  this.ds.dialogf(
                    "",
                    error && error.error && error.error.error
                      ? error.error.error
                      : this.lang.transform("lang_internal_server_error")
                  );
                }
              )
          );
        }
      })
    );
  }
  public send_to_zatca(invoice_id) {
    this.spinner.show();
    let param = new FormData();
    param.append("branch_id", this.branch_id);
    param.append("invoice_id", invoice_id);
    this.ds.postActionByUrl(param, "accounts/send_tax_invoice").subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.sweetAlert.successToast(res?.msg, 2000);
        } else {
          this.sweetAlert.errorToast(res?.msg, 2000);
        }
      },
      (error) => {
        this.spinner.hide();
        this.ds.dialogf(
          "",
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  public export_account_transaction(
    invoice_id,
    request,
    branch_id,
    iddiv = "#showPDFPreview"
  ) {
    this.spinner.show();
    $(iddiv + " iframe").attr("src", "");
    let param = new FormData();
    param.append("branch_id", branch_id);
    param.append("invoice_id", invoice_id);
    this.subscriptions.add(
      this.ds
        .postActionByUrl(param, "accounts/Ordering/get_data_qr_code_view24")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              if (request == "PDF" || request == "EXL") {
                $(iddiv + " iframe").attr(
                  "src",
                  "data:" + "application/pdf" + ";base64," + res.base_64
                );
              } else {
                this.ds.dialogf("", res.msg);
              }
            } else {
              this.ds.dialogf("", res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : "Internal Server Error 501."
            );
          }
        )
    );
  }
  getqrcode(invoice_id, branch_id) {
    let param = new FormData();
    param.append("branch_id", branch_id);
    param.append("invoice_id", invoice_id);
    this.subscriptions.add(
      this.ds
        .postActionByUrl(param, "accounts/Ordering/get_data_qr_code")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.ds.downloadimage(res, "Qrcode_Img_Almnabr");
            } else {
              this.ds.dialogf("", res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : "Internal Server Error 501."
            );
          }
        )
    );
  }
  export_tages() {
    let formdata = new FormData();
    formdata.append("finance_id", this.finance_id);
    formdata.append("branch_id", this.branch_id);
    formdata.append("tages", this.searchKeyByTags);
    this.subscriptions.add(
      this.ds
        .postActionByUrl(formdata, "accounts/tages_transaction_excal")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.downloadFile(res, "tages_transaction");
            } else {
              this.sweetAlert.errorToast(res?.error, 2000);
            }
          },
          (error) => {
            this.spinner.hide();
            this.sweetAlert.errorToast(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error"),
              2000
            );
          }
        )
    );
  }
  view_tages(temp?, forPagination?: boolean) {
    this.spinner.show();
    let formdata = new FormData();
    formdata.append("finance_id", this.finance_id);
    formdata.append("branch_id", this.branch_id);
    formdata.append("tages", this.searchKeyByTags);
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          formdata,
          "accounts/tages_transaction_report/" +
            this.tagspage_no +
            "/" +
            this.tagspage_size
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.tagsData = res;
              if (forPagination != true) {
                this.openModal(temp);
              }
            } else {
              this.tagsData = [];
              this.sweetAlert.errorToast(res?.error, 2000);
            }
          },
          (error) => {
            this.spinner.hide();
            this.tagsData = [];
            this.sweetAlert.errorToast(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error"),
              2000
            );
          }
        )
    );
  }
  downloadFile(data, title?, type?) {
    if (data.status) {
      let url = data.base64;
      let tagA = document.createElement("a");
      document.body.appendChild(tagA);
      tagA.style.display = "none";
      tagA.href = url;
      if (type == "blank") {
        tagA.setAttribute("target", "_blank");
      }
      tagA.download = title || "Almnabr";
      tagA.click();
      document.body.removeChild(tagA);
    }
  }
}
