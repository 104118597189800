import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { findFlagUrlByIso2Code } from "country-flags-svg";
import { Formhrj1AddModalComponent } from "../transactions/FORM_HRJ1/formhrj1-add/formhrj1-add-modal/formhrj1-add-modal.component";
import { PreloaderService } from "@core";
import { Subscription } from "rxjs";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  empInfo: any;
  employee_tasks: any;
  employee_extra_data: any;
  avatar: any;
  user_type_id = this.ls.getItem("user_type_id");
  employee_status = this.ls.getItem("employee_status");
  products = [];
  cols = [];
  constructor(
    public ds: DataService,
    public lang: LanguagePipe,
    private ls: LocalStorage,
    private dialog: MatDialog,
    public spinner: PreloaderService
  ) {}
  ngOnInit() {
    this.getEmployeeInfo();
    if (this.user_type_id == "1") {
      this.getUserAvatar();
    }
    this.ds.employee_status_change.subscribe((res) => {
      this.employee_status = res;
    });

    //
    this.products = [
      {
        id: "1",
        name: "ahmed",
        category: "tt",
        quantity: "45",
      },
      {
        id: "2",
        name: "mohamed",
        category: "bb",
        quantity: "30",
      },
      {
        id: "3",
        name: "ggggg",
        category: "bdddb",
        quantity: "320",
      },
      {
        id: "4",
        name: "ggggg",
        category: "bdddb",
        quantity: "320",
      },
      {
        id: "5",
        name: "ggggg",
        category: "bdddb",
        quantity: "320",
      },
      {
        id: "6",
        name: "ggggg",
        category: "bdddb",
        quantity: "320",
      },
      {
        id: "7",
        name: "ggggg",
        category: "bdddb",
        quantity: "320",
      },
      {
        id: "8",
        name: "ggggg",
        category: "bdddb",
        quantity: "320",
      },
      {
        id: "9",
        name: "ggggg",
        category: "bdddb",
        quantity: "320",
      },
      {
        id: "10",
        name: "ggggg",
        category: "bdddb",
        quantity: "320",
      },
      {
        id: "11",
        name: "ggggg",
        category: "bdddb",
        quantity: "320",
      },
      {
        id: "12",
        name: "ggggg",
        category: "bdddb",
        quantity: "320",
      },
      {
        id: "13",
        name: "ggggg",
        category: "bdddb",
        quantity: "320",
      },
    ];
    this.cols = [
      { field: "id", header: "Id" },
      { field: "name", header: "Name" },
      { field: "category", header: "Category" },
      { field: "quantity", header: "Quantity" },
    ];
  }

  getUserAvatar() {
    let user = localStorage.getItem("usr")
      ? JSON.parse(localStorage.getItem("usr"))
      : {};
    let params = {
      user_id: user["id"],
      user_username: user["username"],
    };
    this.ds
      .getActionByNoTokenFullUrl(params, "user/avatar")
      .subscribe((res) => {
        if (res?.avatar) {
          this.avatar = res?.avatar;
          this.ls.setItem("user_avatar", res?.avatar);
        } else {
          this.avatar = "./assets/images/avatar.png";
          this.ls.setItem("user_avatar", "./assets/images/avatar.png");
        }
      });
  }

  getEmployeeInfo() {
    /* this.spinner.show(); */
    this.ds.get("dashboard/employee_dashboard").subscribe(
      (res) => {
        if (res.status) {
          /*  this.spinner.hide(); */
          this.empInfo = res.employee_info;
          console.log(this.empInfo);
          this.employee_tasks = res.employee_tasks;
          this.employee_extra_data = res.extra_data;
        } else {
          this.ds.dialogf("", res.error);
        }
      },
      (error) => {
        /*  this.spinner.hide(); */
        this.ds.dialogf(
          "",
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }

  getIcon(flag: any) {
    return findFlagUrlByIso2Code(flag);
  }

  onCreateJoining() {
    const dialogRef = this.dialog.open(Formhrj1AddModalComponent, {
      width: "700px",
      autoFocus: false,
      data: {
        employee_data: this.empInfo,
      },
    });
  }

  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
