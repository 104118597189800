<breadcrumb></breadcrumb>
<div class="row" *ngIf="project_profile_id && project_design_id">
	<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
		<button
			type="button"
			routerLink="/projects/BbVUrWitLE"
			class="albutton"
			mat-button
		>
			{{ "lang_all_projects" | language }}
		</button>
	</div>
	<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
		<button
			type="button"
			routerLink="/projects/LtYh/{{ project_profile_id }}"
			class="albutton"
			mat-button
		>
			{{ "lang_go_to_project_view" | language }}
		</button>
	</div>
	<div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
	<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
		<button
			type="button"
			routerLink="/projects/{{ project_profile_id }}/design/{{
				project_design_id
			}}/workarea"
			class="albutton"
			mat-button
		>
			Design Operations
		</button>
	</div>
</div>
<div class="coming-soon">
	<div class="imgs">
		<img
			src="../../../assets/images/logo.svg"
			alt=""
			width="150px"
			style="margin: 0 10px"
		/>
		<img src="../../../assets/images/logonname.svg" alt="" width="300px" />
	</div>
	<p>this page will opening soon</p>
</div>