import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  platform_group2_id: string,
  platform_group2_code_system: string,
  platform_group2_code_manual: string,
  platform_group2_title_english: string,
  platform_group2_title_arabic: string,
  projects_services_ids: string,
  platform_group1_code_system: string,
  platform_group_type_code_system: string,
  group1name: string,
  typename: string,
  writer: string,
}
@Component({
  selector: 'app-wgroup2',
  templateUrl: './wgroup2.component.html',
  styleUrls: ['./wgroup2.component.scss']
})
export class Wgroup2Component implements OnInit {
  modalRefA: BsModalRef;
  tabledatas: list[];
  form:FormGroup;
  @Output() actionEvent             = new EventEmitter<any>();
  collectionSize                    = 0;
  page                              = 1;
  pageSize                          = 10;
  searchKey                         = "";
  group1:any                        = null;
  platform_group_type_code_system   = null;
  projects_services_ids:any         = null;
  nodata: string                    = this.lang.transform('lang_loading');
  admin                             = JSON.parse(this.ls.getItem('is_admin'));
  wholeData:any                     = [];
  tabledata:any                     = [];
  types:any                         = [];
  group1s:any                       = [];
  services:any                      = [];
  selectmultiple                    = true;
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public spinner:PreloaderService, public alert:AlertService, public modalService: BsModalService, public dialog:MatDialog, public fb:FormBuilder) { }
  ngOnInit(): void {
    this.spinner.show();
    this.get_type_group1_data();
    this.get_group_two_lists();
    this.form = new FormGroup({
      platform_group2_id                  : new FormControl(''),
      platform_group2_code_manual         : new FormControl(''),
      platform_group2_active_code         : new FormControl(''),
      platform_group2_active_name         : new FormControl(''),
      platform_group2_title_english       : new FormControl('', [Validators.required]),
      platform_group2_title_arabic        : new FormControl('', [Validators.required]),
      platform_group1_code_system         : new FormControl('', [Validators.required]),
      platform_group_type_code_system     : new FormControl('', [Validators.required]),
      projects_services_ids               : new FormControl('', [Validators.required]),
    });
  }
  //add, edit model
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public get_type_group1_data() {
    this.types = this.group1s = this.services = [];
    this.spinner.show();
    let url = ['lpgtype', 'lpgone', 'aB8Vlmue0xVSv8O/projects_view'];
    this.ds.forkJoin(url, []).subscribe(res=>{
      this.spinner.hide();
      if(res[0].status) {
        this.types = res[0].records;
      }
      if(res[1].status) {
        this.group1s = res[1].records;
      }
      if(res[2].status) {
        this.services = res[2].records;
      }
    }, error => {
      this.spinner.hide();
    });
  }
  public get_group_two_lists() {
    let param = new FormData();
    param.append('search_key', this.searchKey);
    param.append('platform_group1_code_system', this.group1 || '');
    param.append('platform_group_type_code_system', this.platform_group_type_code_system || '');
    param.append('projects_services_ids', this.projects_services_ids || '');
    param.append('request', 'list');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgtwo').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.wholeData      = res.records;
        this.tabledatas     = res.records;
        this.collectionSize = res.records.length;
        this.build_table_data();
      } else {
        this.wholeData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholeData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    }) 
  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public edit_formdata(row) {
    this.form.patchValue(row);
    this.selectmultiple = false;
    this.form.get('projects_services_ids').setValue((row.projects_services_ids) ? row.projects_services_ids.split(',') : null);
  }
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.append('platform_group2_id', this.form.get('platform_group2_id').value || '');
    param.append('platform_group2_code_manual', this.form.get('platform_group2_code_manual').value || '');
    param.append('platform_group2_active_code', this.form.get('platform_group2_active_code').value || '');
    param.append('platform_group2_active_name', this.form.get('platform_group2_active_name').value || '');
    param.append('platform_group2_title_english', this.form.get('platform_group2_title_english').value || '');
    param.append('platform_group2_title_arabic', this.form.get('platform_group2_title_arabic').value || '');
    param.append('platform_group_type_code_system', this.form.get('platform_group_type_code_system').value || '');
    param.append('platform_group1_code_system', this.form.get('platform_group1_code_system').value || '');
    param.append('projects_services_ids', (this.form.get('projects_services_ids').value) ? this.form.get('projects_services_ids').value.toString() : '');
    if(this.form.get('platform_group2_id').value) {
      this.ds.putActionByUrl(this.ds.formData2string(param), 'upgtwo/'+this.form.get('platform_group2_id').value).subscribe(res=>{
        this.spinner.hide();
        if(res.status) {
          this.form.reset();
          this.actionEvent.emit('');
          this.get_group_two_lists();
          setTimeout(() => {
            this.modalRefA.hide();
            this.alert.clear();
          }, 1000);
          this.alert.success(res.msg);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    } else {
      this.ds.postActionByUrl(param, 'cpgtwo').subscribe(res=>{
        this.spinner.hide();
        if(res.status) {
          this.form.reset();
          this.get_group_two_lists();
          setTimeout(() => {
            this.modalRefA.hide();
            this.alert.clear();
          }, 1000);
          this.alert.success(res.msg);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    }
  }
  public checkAllItemsGr2(event) {
    if (event.target.checked) {
      $(".appgroup2s .eachItemGr2").prop("checked", true);
      $(".appgroup2s .makeback").addClass("bground");
      $(".appgroup2s .deleteIconDiv").show();
    } else {
      $(".appgroup2s .eachItemGr2").prop("checked", false);
      $(".appgroup2s .makeback").removeClass("bground");
      $(".appgroup2s .deleteIconDiv").hide();
    }
  }
  public checkEachItemGr2(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appgroup2s .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appgroup2s .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appgroup2s .eachItemGr2:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appgroup2s .eachItemGr2").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appgroup2s .deleteIconDiv").show();
    } else {
      $(".appgroup2s .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appgroup2s #allItemsGr2").prop("checked", true);
    } else {
      $(".appgroup2s #allItemsGr2").prop("checked", false);
    }
  }
  public deleteBulkDataGr2() {
    const checkedtotal = [];
    $(".appgroup2s .eachItemGr2:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'dpgtwo').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.actionEvent.emit('');
                this.get_group_two_lists();
                $(".appgroup2s #allItemsGr2").prop("checked", false);
                $(".appgroup2s .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
