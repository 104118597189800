import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
  selector: "app-attachments",
  templateUrl: "./attachments.component.html",
  styleUrls: ["./attachments.component.scss"],
})
export class AttachmentsComponent implements OnInit {
  apiurl = environment.SERVER_ORIGIN;
  @Input() able2edit;
  @Input() able2delete;
  @Input() able2add;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  @Input() requestfor;
  @Input() user_data;
  @Output() childToParentAttach = new EventEmitter<String>();
  apage = 1;
  apsize = "10";
  typelists: any = [];
  selectedItems: any = [];
  idfilename = this.lang.transform("lang_select_files");
  attacherrormsg: string = this.lang.transform("lang_loading");
  attachdata: any = [];
  attachviewdata: any = [];
  attacheditdata: any = [];
  attachform: FormGroup;
  types: any = [];
  uform: FormGroup;
  searchKey = "";
  modalRefA: BsModalRef;
  modalRefV: BsModalRef;
  modalRefU: BsModalRef;
  expandstatus = false;
  filepermissionlevels: any = [];
  editlevelkeys: any = null;
  constructor(
    public router: Router,
    public ds: DataService,
    public route: ActivatedRoute,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  ngOnInit(): void {
    this.expandstatus = this.requestfor == "profile" ? true : false;
    this.attach_form();
    this.uform = new FormGroup({
      employee_number: new FormControl(this.id, [Validators.required]),
      attachment_descriptions: new FormControl("", [Validators.required]),
      level_keys: new FormControl("", [Validators.required]),
      file_records_id: new FormControl("", [Validators.required]),
    });
    this.file_permission_levels();
    this.attachment_types();
    this.get_employee_attachments();
  }
  public file_permission_levels() {
    this.filepermissionlevels = [];
    this.ds.getActionByUrl([], "lflevel").subscribe((res) => {
      if (res.status) {
        this.filepermissionlevels = res.records;
      }
    });
  }
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  public openModalE(template: TemplateRef<any>) {
    this.modalRefU = this.modalService.show(template, environment.modelconfig);
  }
  public attachment_types(): void {
    this.types = [];
    this.ds
      .getActionByUrl([], "module_attach_types/?module_name=human_resources")
      .subscribe((res) => {
        if (res.status) {
          this.types = res.records;
        }
      });
  }
  public attachpage(size) {
    this.apsize = size;
    this.apage = 1;
    this.get_employee_attachments();
  }
  public attachlength(page) {
    this.apage = page;
    this.get_employee_attachments();
  }
  public search_my_attachments() {
    this.apage = 1;
    this.apsize = "10";
    this.get_employee_attachments();
  }
  public get_employee_attachments(): void {
    let formdata = new FormData();
    formdata.append("branch_id", this.branch_id);
    formdata.append("employee_number", this.id);
    formdata.append("search_key", this.searchKey || "");
    formdata.append(
      "attachmentType",
      this.typelists.length > 0 ? this.typelists.toString() : ""
    );
    let url =
      this.requestfor === "profile"
        ? "my_attachments/"
        : "attachments_for_employee/";
    this.ds
      .postActionByUrl(formdata, url + "/" + this.apage + "/" + this.apsize)
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.attachdata = res;
          } else {
            this.attachdata = [];
            this.attacherrormsg = res.error;
          }
        },
        (error) => {
          this.spinner.hide();
          this.attachdata = [];
          this.attacherrormsg = error.error;
        }
      );
  }
  public view_my_Attach(attach) {
    this.attachviewdata = attach;
  }
  public attach_form() {
    this.attachform = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      employee_number: new FormControl(this.id, [Validators.required]),
      attachment_type: new FormControl("", [Validators.required]),
      attachment_descriptions: new FormControl("", [Validators.required]),
      attachment_link: new FormControl("", [Validators.required]),
      level_keys: new FormControl("", [Validators.required]),
    });
  }
  public emptyAttachForm() {
    this.alert.clear();
    this.attachform.reset();
    this.attachform.get("attachment_type").setValue("");
    this.attachform.get("branch_id").setValue(this.branch_id);
    this.attachform.get("employee_number").setValue(this.id);
    this.idfilename = this.lang.transform("lang_select_files");
  }
  public uploadIDFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      let max_size = 5 * 1024 * 1024; // 5MB
      const fileid = event.target.files[0];
      console.log(fileid);
      if (fileid?.size > max_size) {
        this.sweetAlert.errorToast(
          this.lang.transform("lang_file_uploaded_size_error"),
          3000
        );
        return 0;
      } else {
        this.idfilename = fileid.name;
        this.attachform
          .get("attachment_link")
          .setValue(fileid, { emitEvent: true });
      }
    } else {
      this.attachform.get("attachment_link").setValue(null);
      this.idfilename = this.lang.transform("lang_select_files");
    }
  }
  public upload_files() {
    this.spinner.show();
    this.ds
      .postActionByUrl(
        this.ds.json2formdata(this.attachform.value),
        "hr_upload_attachments"
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.attachform.reset();
            this.get_employee_attachments();
            this.childToParentAttach.emit();
            setTimeout(() => {
              this.modalRefA.hide();
            }, 1000);
            this.alert.success(data.msg);
          } else {
            this.alert.error(data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error.error + (error && error.error && error.error.error)
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  public edit_my_Attach(attach) {
    this.alert.clear();
    this.attacheditdata = attach;
    this.uform.patchValue({
      employee_number: this.id,
      attachment_descriptions: attach.file_name,
      level_keys: attach.level_keys.split(","),
      file_records_id: attach.file_records_id,
    });
  }
  public update() {
    this.spinner.show();
    this.ds
      .putActionByUrl(
        this.ds.formData2string(this.ds.json2formdata(this.uform.value)),
        "hr_update_filename"
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.uform.reset();
            this.get_employee_attachments();
            this.childToParentAttach.emit();
            setTimeout(() => {
              this.modalRefU.hide();
            }, 1000);
            this.alert.success(data.msg);
          } else {
            this.alert.error(data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error.error + (error && error.error && error.error.error)
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  public checkAllAttach(event) {
    if (event.target.checked) {
      $(".appattachments .eachItemAttach").prop("checked", true);
      $(".appattachments .makeback").addClass("bground");
      $(".appattachments .deleteIconDiv").show();
    } else {
      $(".appattachments .eachItemAttach").prop("checked", false);
      $(".appattachments .makeback").removeClass("bground");
      $(".appattachments .deleteIconDiv").hide();
    }
  }
  public checkEachItemAttach(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appattachments .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appattachments .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appattachments .eachItemAttach:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appattachments .eachItemAttach").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appattachments .deleteIconDiv").show();
    } else {
      $(".appattachments .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appattachments #allItemsAttach").prop("checked", true);
    } else {
      $(".appattachments #allItemsAttach").prop("checked", false);
    }
  }
  public deleteBulkDataAttach() {
    const checkedtotal = [];
    $(".appattachments .eachItemAttach:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true,
        width: "400px",
        data: {
          title: this.lang.transform("lang_confirmation"),
          message: this.lang.transform("lang_are_you_sure"),
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          this.ds
            .deleteActionByUrl(
              checkedtotal,
              "01f5086b879a62a05da4094dac203558/ATTACHMENT/" +
                this.id +
                "/" +
                this.branch_id
            )
            .subscribe(
              (data) => {
                this.spinner.hide();
                if (data.status) {
                  this.get_employee_attachments();
                  this.childToParentAttach.emit();
                  $(".appattachments #allItemsAttach").prop("checked", false);
                  $(".appattachments .deleteIconDiv").hide();
                  this.ds.dialogf("", data.msg);
                } else {
                  this.ds.dialogf("", data.error);
                }
              },
              (error) => {
                this.ds.dialogf(
                  "",
                  error && error.error && error.error.error
                    ? error.error.error
                    : this.lang.transform("lang_internal_server_error")
                );
                this.spinner.hide();
              }
            );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
  downloadImg(Src, title) {
    this.ds.getActionByUrl([], Src).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          let src = "data:" + res["content-type"] + ";base64," + res.base64;
          let currentDate = new Date();
          let tagA = document.createElement("a");
          document.body.appendChild(tagA);
          tagA.style.display = "none";
          tagA.href = src;
          tagA.download = title
            ? "Almnabr-" + title
            : "Almnabr-" + currentDate.toLocaleString();
          tagA.click();
          document.body.removeChild(tagA);
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  console(file) {
    console.log(file);
  }
}
