import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BaCreateUpdateComponent } from "./ba-create-update/ba-create-update.component";

@Component({
  selector: "app-block-accounts",
  templateUrl: "./block-accounts.component.html",
  styleUrls: ["./block-accounts.component.scss"],
})
export class BlockAccountsComponent implements OnInit {
  branch_id: any =
    localStorage.getItem("selectedBranch") != null
      ? localStorage.getItem("selectedBranch")
      : "0";
  financeList = [];
  finance_id: any = null;
  page_no = 1;
  page_size = 10;
  blockAccounts = [];
  total_records: any;
  constructor(
    private lang: LanguagePipe,
    private ds: DataService,
    private sweetAlert: SweetAlertService,
    private spinner: PreloaderService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getFinancialList();
  }
  getFinancialList() {
    this.ds.get("financial/" + this.branch_id).subscribe(
      (res) => {
        if (res?.status) {
          this.financeList = res?.records;
        } else {
          this.financeList = [];
          this.sweetAlert.errorToast(res?.error, 2000);
        }
      },
      (err) => {
        this.financeList = [];
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          2000
        );
      }
    );
  }

  getBlockAccounts() {
    let formData = new FormData();
    formData.append("branch_id", this.branch_id || "");
    formData.append("finance_id", this.finance_id || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `acc/getblocksystems/${this.page_no}/${this.page_size}`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.blockAccounts = res?.records;
            this.total_records = res?.page?.total_records;
          } else {
            this.blockAccounts = [];
            this.total_records = 0;
            this.sweetAlert.errorToast(res?.error, 2000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.blockAccounts = [];
          this.total_records = 0;
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
  }

  onCreateUpdate(data?) {
    const dialogRef = this.dialog.open(BaCreateUpdateComponent, {
      width: "700px",
      data: {
        blockAccount: data,
        branch_id: this.branch_id,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getBlockAccounts();
      }
    });
  }

  deleteBlockAccount(data) {
    this.spinner.show();
    this.ds
      .deleteActionByUrl(
        [data?.finance_block_id],
        `acc/deleteblocksystems/${data?.branch_id}`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.sweetAlert.successToast(res?.msg, 2000);
            this.getBlockAccounts();
          } else {
            this.sweetAlert.errorToast(res?.msg, 2000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
  }
}
