import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import {
	FormGroup,
	FormArray,
	FormBuilder,
	Validators,
	FormControl,
} from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { almnabrAttachment } from "@shared/validators/almnabr-attachment";
import moment from "moment";
import { UserActiveTasksComponent } from "../user-active-tasks/user-active-tasks.component";
import { UserGroupComponent } from "../user-group/user-group.component";

@Component({
	selector: "app-add-update-ticket-modal",
	templateUrl: "./add-update-ticket-modal.component.html",
	styleUrls: ["./add-update-ticket-modal.component.scss"],
})
export class AddUpdateTicketModalComponent implements OnInit {
	priority_list = [];
	user_list = [];
	type_list = [];
	sig_list = [];
	module_list = [];
	ticketForm: FormGroup;
	other_attachments: FormArray;
	filename: string = this.lang.transform("lang_select_files");
	lang_key = localStorage.getItem("lang_key") || "en";
	@Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>> =
		new EventEmitter();
	startDateMobiscroll = null;
	endDateMobiscroll = null;
	autoButtons = [
		{
			text: this.lang.transform("lang_save"),
			handler: "set",
		},
	];
	group_type_id;
	userCounts = [];
	ref_module = "";
	projects_work_area_id;
	constructor(
		private fb: FormBuilder,
		private lang: LanguagePipe,
		public ds: DataService,
		private router: Router,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private dialog: MatDialog,
		private dialogRef: MatDialogRef<AddUpdateTicketModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.ref_module = data?.ref_module;
		this.projects_work_area_id = data?.projects_work_area_id;
	}

	ngOnInit(): void {
		moment.locale("en");
		this.buildForm();
		this.getFormLists();
		let firstTime = 0;
		this.ticketForm.get("users").valueChanges.subscribe((res) => {
			if (res?.length && firstTime) {
				this.getUserTasks();
			}
			firstTime++;
		});
		this.ticketForm.get("end_date").valueChanges.subscribe((res) => {
			if (res?.length && firstTime) {
				this.getUserTasks();
			}
			firstTime++;
		});
	}

	buildForm() {
		this.ticketForm = this.fb.group({
			ticket_title: ["", Validators.required],
			ticket_detalis: ["", Validators.required],
			need_reply: [false, Validators.required],
			date_reply: [null, Validators.required],
			notes: [""],
			time_work: ["", Validators.required],
			start_date: [null, Validators.required],
			end_date: [null, Validators.required],
			users: [null],
			ref_model: [null, Validators.required],
			issue_link: ["", Validators.required],
			group_id: ["", Validators.required],
			attachments: this.fb.array([]),
		});
		this.other_attachments = this.ticketForm.get("attachments") as FormArray;
	}

	getFormLists() {
		this.ds.post("tasks/get_add", {}).subscribe((res) => {
			if (res?.status) {
				this.module_list = res?.data?.modules;
			}
		});
	}

	get filesFormGroup() {
		return this.ticketForm.get("attachments") as FormArray;
	}

	public files(value?): FormGroup {
		return this.fb.group({
			delete_icon: new FormControl(value?.attach_title || ""),
			attach_title: new FormControl(
				{
					value: value ? value?.attach_title : "",
					disabled: value?.attach_title ? true : false,
				},
				[Validators.required]
			),
			file: new FormControl(value?.file || "", [
				Validators.required,
				almnabrAttachment(),
			]),
		});
	}
	public addfiles(value?) {
		if (this.other_attachments.length < 10) {
			this.other_attachments.push(this.files(value));
			this.other_attachments.setValidators(Validators.required);
			this.other_attachments.updateValueAndValidity();
		}
	}
	public removefiles(index) {
		if (this.other_attachments.length == 1) {
			this.other_attachments.removeAt(index);
			this.other_attachments.clearValidators();
			this.other_attachments.updateValueAndValidity();
		} else {
			this.other_attachments.removeAt(index);
		}
	}

	checkValidation() {
		if (this.ticketForm.get("ticket_title").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_ticket_title")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.ticketForm.get("ticket_detalis").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_ticket_detalis")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (
			this.ticketForm.get("need_reply").value == true &&
			this.ticketForm.get("date_reply").invalid
		) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_date_reply")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.ticketForm.get("time_work").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_total_days")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.ticketForm.get("start_date").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_start_date")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.ticketForm.get("end_date").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_end_date")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.ticketForm.get("users").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_assign_user")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.ticketForm.get("attachments").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_attachments")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else {
			return true;
		}
	}

	submit() {
		if (this.checkValidation()) {
			this.spinner.show();
			let need_reply = this.ticketForm.get("need_reply").value ? "1" : "0";
			const reply_data_formatted = this.ticketForm.get("date_reply").value
				? this.ticketForm.get("date_reply").value?.getFullYear() +
				  "/" +
				  (
						"0" +
						(this.ticketForm.get("date_reply").value?.getMonth() + 1)
				  ).slice(-2) +
				  "/" +
				  ("0" + this.ticketForm.get("date_reply").value?.getDate()).slice(-2)
				: "";
			let formData = new FormData();
			formData.append(
				"ticket_titel",
				this.ticketForm.get("ticket_title").value || ""
			);
			formData.append(
				"ticket_detalis",
				this.ticketForm.get("ticket_detalis").value || ""
			);
			formData.append("need_reply", need_reply || "");
			formData.append("date_reply", reply_data_formatted || "");
			formData.append("notes", this.ticketForm.get("notes").value || "");
			formData.append(
				"time_work",
				this.ticketForm.get("time_work").value || ""
			);
			formData.append(
				"start_date",
				this.ticketForm.get("start_date").value || ""
			);
			formData.append("end_date", this.ticketForm.get("end_date").value || "");
			formData.append(
				"issue_link",
				this.ticketForm.get("issue_link").value || ""
			);
			formData.append("ref_model", this.ref_module || "tasks");
			formData.append("ref_id", this.projects_work_area_id || "");
			formData.append("group_id", this.ticketForm.get("group_id").value || "");
			if (this.ticketForm.get("users").value?.length) {
				for (let item of this.ticketForm.get("users").value) {
					formData.append("users[]", item || "");
				}
			}
			this.filesFormGroup.value.forEach((v, k) => {
				formData.set("attachments[" + k + "][file]", v.file);
				formData.set(
					"attachments[" + k + "][attach_title]",
					this.other_attachments.controls[k].get("attach_title").value
				);
			});
			this.ds.post("tasks/add_tickets", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.dialogRef.close(true);
						this.sweetAlert.successToast(
							this.lang.transform("lang_data_sent_successfully"),
							1500
						);
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						1500
					);
				}
			);
		}
	}

	calcEndDate(e, type) {
		if (type == "datechange") {
			let numOfDays = this.ticketForm.get("time_work").value,
				startDateInForm = this.ticketForm.get("start_date").value;
			if (startDateInForm && numOfDays) {
				const endDateFormatted = moment(
					this.addDays(startDateInForm, numOfDays)
				).format("YYYY/MM/DD HH:mm:ss");
				this.ticketForm.get("end_date").setValue(endDateFormatted);
			}
		} else if (type == "dayschange") {
			let numOfDays = this.ticketForm.get("time_work").value,
				startDate = this.ticketForm.get("start_date").value;
			if (e?.target?.value && numOfDays && startDate) {
				const endDateFormatted = moment(
					this.addDays(startDate, numOfDays)
				).format("YYYY/MM/DD HH:mm:ss");
				this.ticketForm.get("end_date").setValue(endDateFormatted);
			}
		}
	}
	addDays(date, days) {
		let result = new Date(date);
		result.setDate(result.getDate() + Number(days) - 1);
		return result;
	}

	public search_users(key) {
		this.user_list = [];
		if (key && key.term) {
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.user_list = res?.list?.map((el) => {
							return {
								...el,
								counts: "",
							};
						});
					}
				});
		}
	}

	isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	custmizeDate(type) {
		if (type == "start_date") {
			const start_data_formatted = moment(this.startDateMobiscroll).format(
				"YYYY/MM/DD HH:mm:ss"
			);
			this.ticketForm.get("start_date").setValue(start_data_formatted);
			this.calcEndDate(null, "datechange");
		}
	}
	onAddUpdateGroupUser() {
		const dialogRef = this.dialog.open(UserGroupComponent, {
			width: "600px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res?.status && res) {
				this.group_type_id = res?.group_type_id;
				this.ticketForm.get("group_id").setValue(res?.group_id);
				this.ticketForm
					.get("users")
					.setValue(res?.users?.map((el) => el?.user_id));
				this.user_list = res?.users?.map((el) => {
					return {
						label:
							this.lang_key == "ar"
								? el?.firstname_arabic + " " + el?.lastname_arabic
								: el?.firstname_english + " " + el?.lastname_english,
						value: el?.user_id,
					};
				});
			}
		});
	}
	onSelectUser(id) {
		const userId = id;
		this.spinner.show();
		let formData = new FormData();
		formData.append("emp_id", userId || "");
		formData.append(
			"start_date",
			this.ticketForm.get("start_date").value || ""
		);
		formData.append("end_date", this.ticketForm.get("end_date").value || "");
		this.ds.post("tasks/check_emp_is_tasks", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					const dialogRef = this.dialog.open(UserActiveTasksComponent, {
						width: "600px",
						data: {
							user_id: userId,
							usersTasks: res?.data,
						},
						autoFocus: false,
					});
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}

	getUserTasks() {
		let formData = new FormData();
		formData.append(
			"emp_id",
			this.ticketForm
				.get("users")
				.value?.map((el) => el?.value)
				?.toString() || ""
		);
		formData.append(
			"start_date",
			this.ticketForm.get("start_date").value || ""
		);
		formData.append("end_date", this.ticketForm.get("end_date").value || "");
		this.ds.post("tasks/get_count_emp_is_tasks", formData).subscribe(
			(res) => {
				if (res?.status) {
					this.userCounts = res?.data;
				} else {
					this.userCounts = [];
				}
			},
			(err) => {
				this.userCounts = [];
			}
		);
	}
}
