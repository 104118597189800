<breadcrumb></breadcrumb>
<section class="panel-expansion" *ngIf="data_status">
	<div class="card-footers padb0">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/suppliers/lists"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_goto_list" | language }}
				</button>
			</div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					*ngIf="able2add"
					type="button"
					routerLink="/suppliers/create"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_add" | language }}
				</button>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					*ngIf="able2edit"
					type="button"
					routerLink="/suppliers/edit/{{ id }}"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_edit" | language }}
				</button>
			</div>
		</div>
	</div>
	<mat-accordion>
		<mat-expansion-panel class="mpanel" [expanded]="true">
			<i class="together-icon">
				<i
					*ngIf="supplier_data.supplier_logo"
					class="fa fa-paperclip mnabricon s2icon"
					(click)="
						ds.preview_pdf_file(
							supplier_data.supplier_logo,
							supplier_data?.supplier_title_en
						)
					"
					matTooltip="{{ 'lang_logo' | language }}"
				></i>
			</i>
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_suppliers" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_branch" | language }} :</label>
								<div class="bold">
									<b>{{ supplier_data.branch_name }}</b>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_human_resources_nameen" | language }} :</label>
								<div class="bold">
									<b>{{ supplier_data.supplier_title_en }}</b>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_human_resources_namear" | language }} :</label>
								<div class="bold">
									<b>{{ supplier_data.supplier_title_ar }}</b>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_email_address" | language }} :</label>
								<div class="bold">
									<b>{{ supplier_data.supplier_email_address }}</b>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_mobile_number" | language }} :</label>
								<div class="bold">
									<b>{{ supplier_data.supplier_mobile }}</b>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_vat_tax" | language }} :</label>
								<div class="bold">
									<b>{{ supplier_data.supplier_tax_vat }}</b>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_ccr_no" | language }} : </label>
								<div class="bold" *ngIf="supplier_data.supplier_ccr_no">
									<b>{{ supplier_data.supplier_ccr_no }}</b>
								</div>
								<div class="bold" *ngIf="!supplier_data.supplier_ccr_no">
									<b>----</b>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_manager" | language }} :</label>
								<div class="bold" *ngIf="supplier_data.supplier_managers">
									<b
										*ngFor="let item of supplier_data.supplier_managers"
										class="mb-10"
										style="display: block"
									>
										<label *ngIf="item.label">{{ item.label }}</label>
									</b>
								</div>
								<div class="bold" *ngIf="!supplier_data.supplier_managers">
									<b>----</b>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_users" | language }} :</label>
								<div class="bold" *ngIf="supplier_data.supplier_teams">
									<b
										*ngFor="let item of supplier_data.supplier_teams"
										class="mb-10"
										style="display: block"
										><label *ngIf="item.label">{{ item.label }}</label></b
									>
								</div>
								<div class="bold" *ngIf="!supplier_data.supplier_teams">
									<b>----</b>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_status" | language }} :</label>
								<div class="bold">
									<b *ngIf="supplier_data.supplier_status == '1'">{{
										"lang_active" | language
									}}</b>
									<b *ngIf="supplier_data.supplier_status == '0'">{{
										"lang_inactive" | language
									}}</b>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_link_with_accounts" | language }} :</label>
								<div class="bold">
									<b *ngIf="supplier_data.supplier_link_with_accounts == '1'">{{
										"lang_yes" | language
									}}</b>
									<b *ngIf="supplier_data.supplier_link_with_accounts == '0'">{{
										"lang_no" | language
									}}</b>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<mat-accordion *ngIf="user_type_id == '1'">
		<mat-expansion-panel class="mpanel" [expanded]="false">
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_account_info" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_address_text" | language }} :</label>
								<div class="bold">
									<b *ngIf="supplier_data.supplier_address">{{
										supplier_data.supplier_address
									}}</b>
									<b *ngIf="!supplier_data.supplier_address">----</b>
								</div>
							</div>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_credit_days" | language }} : </label>
								<div class="bold">
									<b *ngIf="supplier_data.supplier_credit_days">{{
										supplier_data.supplier_credit_days
									}}</b>
									<b *ngIf="!supplier_data.supplier_credit_days">----</b>
								</div>
							</div>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_security_amount" | language }} : </label>
								<div class="bold">
									<b *ngIf="supplier_data.supplier_security_amount">{{
										supplier_data.supplier_security_amount
									}}</b>
									<b *ngIf="!supplier_data.supplier_security_amount">----</b>
								</div>
							</div>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_credit_limit" | language }} : </label>
								<div class="bold">
									<b *ngIf="supplier_data.supplier_credit_limit">{{
										supplier_data.supplier_credit_limit
									}}</b>
									<b *ngIf="!supplier_data.supplier_credit_limit">----</b>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_discount_limit" | language }} : </label>
								<div class="bold">
									<b *ngIf="supplier_data.supplier_discount_limit">{{
										supplier_data.supplier_discount_limit
									}}</b>
									<b *ngIf="!supplier_data.supplier_discount_limit">----</b>
								</div>
							</div>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_discount_on_payment" | language }} : </label>
								<div class="bold">
									<b *ngIf="supplier_data.supplier_discount_onpay">{{
										supplier_data.supplier_discount_onpay
									}}</b>
									<b *ngIf="!supplier_data.supplier_discount_onpay">----</b>
								</div>
							</div>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_salesman" | language }} : </label>
								<div class="bold">
									<b *ngIf="supplier_data.supplier_salesmancode">{{
										supplier_data.supplier_salesmancode
									}}</b>
									<b *ngIf="!supplier_data.supplier_salesmancode">----</b>
								</div>
							</div>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_salesman_area" | language }} : </label>
								<div class="bold">
									<b *ngIf="supplier_data.supplier_salesmanarea">{{
										supplier_data.supplier_salesmanarea
									}}</b>
									<b *ngIf="!supplier_data.supplier_salesmanarea">----</b>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_stop_transaction" | language }} : </label>
								<div class="bold">
									<b *ngIf="supplier_data.supplier_stop_transaction == '1'">{{
										"lang_yes" | language
									}}</b>
									<b
										*ngIf="
											supplier_data.supplier_stop_transaction == '0' ||
											!supplier_data.supplier_stop_transaction
										"
										>{{ "lang_no" | language }}</b
									>
								</div>
							</div>
						</div>
						<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
							<div class="form-group">
								<label>{{ "lang_account" | language }} : </label>
								<div class="bold">
									<b
										*ngIf="
											supplier_data.supplier_account_id &&
											supplier_data.supplier_link_with_accounts == '1'
										"
										>{{ supplier_data.supplier_account_id[0].label }}</b
									>
									<b *ngIf="!supplier_data.supplier_account_id">-----</b>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<app-sp-users
		[supplier_id]="id"
		[type]="type"
		[able2add]="able2add"
		[able2edit]="able2edit"
		[able2delete]="able2delete"
		[user_position]="user_position"
		[supplier_data]="supplier_data"
	></app-sp-users>
	<app-sp-notes
		[supplier_id]="id"
		[type]="type"
		[able2add]="able2add"
		[able2edit]="able2edit"
		[able2delete]="able2delete"
		[supplier_data]="supplier_data"
	></app-sp-notes>
	<app-sp-attachments
		[supplier_id]="id"
		[type]="type"
		[able2add]="able2add"
		[able2edit]="able2edit"
		[able2delete]="able2delete"
		[supplier_data]="supplier_data"
	></app-sp-attachments>
	<div class="card-footers padb0">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/suppliers/lists"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_goto_list" | language }}
				</button>
			</div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					*ngIf="able2add"
					type="button"
					routerLink="/suppliers/create"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_add" | language }}
				</button>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					*ngIf="able2edit"
					type="button"
					routerLink="/suppliers/edit/{{ id }}"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_edit" | language }}
				</button>
			</div>
		</div>
	</div>
</section>
<div class="modal" id="pdfPreviewRecPay">
	<div class="modal-dialog width70">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_preview" | language }}</h4>
				<button type="button" class="close" data-dismiss="modal">
					&times;
				</button>
			</div>
			<div class="modal-body">
				<div class="pdf-container">
					<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</div>
