import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-transactions-notes',
  templateUrl: './transactions-notes.component.html',
  styleUrls: ['./transactions-notes.component.scss']
})
export class TransactionsNotesComponent implements OnInit {
  @Input() notes:any;
  page = 1;
  pageSize = 5;
  constructor() { }

  ngOnInit(): void {
  }

}
