<breadcrumb></breadcrumb>
<section class="panel-expansion">
  <mat-accordion class="stylepanels">
    <mat-expansion-panel class="mpanel" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_customers_create" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <form
          [formGroup]="registrationForm"
          (ngSubmit)="createCustomer()"
          class="create"
        >
          <div class="mbrl15">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_branch" | language }} :<span class="red"
                      >*</span
                    ></label
                  >
                  <select
                    class="form-control almnabrformcontrol"
                    formControlName="branch_id"
                  >
                    <option value="">
                      {{ "lang_choose_options" | language }}
                    </option>
                    <option
                      *ngFor="let branch of branches"
                      value="{{ branch.branch_id }}"
                    >
                      {{ branch.branch_name }}
                    </option>
                  </select>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['branch_id'].touched &&
                      registrationForm.controls['branch_id'].errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}</mat-error
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_human_resources_nameen" | language }} :<span
                      class="red"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_title_en"
                    placeholder="{{ 'lang_human_resources_nameen' | language }}"
                    formControlName="customer_title_en"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_title_en'].touched &&
                      registrationForm.controls['customer_title_en'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_human_resources_namear" | language }} :<span
                      class="red"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_title_ar"
                    placeholder="{{ 'lang_human_resources_namear' | language }}"
                    formControlName="customer_title_ar"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_title_ar'].touched &&
                      registrationForm.controls['customer_title_ar'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_street_name" | language }} :<span class="red"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control almnabrformcontrol"
                    placeholder="{{ 'lang_street_name' | language }}"
                    formControlName="street_name"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['street_name'].touched &&
                      registrationForm.controls['street_name'].errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}</mat-error
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_building_number" | language }} :<span class="red"
                      >*</span
                    ></label
                  >
                  <input
                    minlength="4"
                    type="text"
                    class="form-control almnabrformcontrol"
                    placeholder="{{ 'lang_building_number' | language }}"
                    formControlName="building_number"
                    (keypress)="ds.numberOnly($event)"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['building_number'].touched &&
                      registrationForm.controls['building_number'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['building_number'].errors
                        ?.minlength
                    "
                  >
                    {{ "lang_minimum" | language }} 4
                  </mat-error>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_plot_identification" | language }} :<span
                      class="red"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control almnabrformcontrol"
                    placeholder="{{ 'lang_plot_identification' | language }}"
                    formControlName="plot_identification"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['plot_identification']
                        .touched &&
                      registrationForm.controls['plot_identification'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_city_sub_division" | language }} :<span
                      class="red"
                      >*</span
                    ></label
                  >
                  <input
                    minlength="4"
                    type="text"
                    class="form-control almnabrformcontrol city_sub_division"
                    placeholder="{{ 'lang_city_sub_division' | language }}"
                    formControlName="city_sub_division"
                    (keypress)="ds.numberOnly($event)"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['city_sub_division'].touched &&
                      registrationForm.controls['city_sub_division'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['city_sub_division'].errors
                        ?.minlength
                    "
                  >
                    {{ "lang_minimum" | language }} 4
                  </mat-error>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_city" | language }} :<span class="red"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control almnabrformcontrol city"
                    placeholder="{{ 'lang_city' | language }}"
                    formControlName="city"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['city'].touched &&
                      registrationForm.controls['city'].errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_postal_number" | language }} :</label>
                  <input
                    minlength="5"
                    type="text"
                    class="form-control almnabrformcontrol postal_number"
                    placeholder="{{ 'lang_postal_number' | language }}"
                    formControlName="postal_number"
                    (keypress)="ds.numberOnly($event)"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['postal_number'].touched &&
                      registrationForm.controls['postal_number'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['postal_number'].errors
                        ?.minlength
                    "
                  >
                    {{ "lang_minimum" | language }} 5
                  </mat-error>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_registration_name" | language }} : </label>
                  <input
                    type="text"
                    class="form-control almnabrformcontrol registration_name"
                    placeholder="{{ 'lang_registration_name' | language }}"
                    formControlName="registration_name"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['registration_name'].touched &&
                      registrationForm.controls['registration_name'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_email_address" | language }} :<span class="red"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_email_address"
                    placeholder="{{ 'lang_email_address' | language }}"
                    formControlName="customer_email_address"
                    (input)="checkEmailExists()"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_email_address']
                        .touched &&
                      registrationForm.controls['customer_email_address'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_email_address']
                        .touched &&
                      registrationForm.controls['customer_email_address'].errors
                        ?.email
                    "
                  >
                    {{ "lang_field_valid_email" | language }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_email_address']
                        .touched &&
                      registrationForm.controls['customer_email_address']
                        .valid &&
                      isEmailExists
                    "
                  >
                    {{ "lang_email_exists" | language }}
                    <span *ngIf="emailErrMsg"> ({{ emailErrMsg }}) </span>
                  </mat-error>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_mobile_number" | language }} :<span class="red"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_mobile"
                    placeholder="{{ 'lang_mobile_number' | language }}"
                    minlength="12"
                    maxlength="12"
                    (keypress)="ds.numberOnly($event)"
                    formControlName="customer_mobile"
                    (input)="checkMobExists()"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_mobile'].touched &&
                      registrationForm.controls['customer_mobile'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_mobile'].touched &&
                      registrationForm.controls['customer_mobile'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_mobile'].errors
                        ?.minlength
                    "
                  >
                    {{ "lang_minimum" | language }} 12
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_mobile'].errors
                        ?.maxlength
                    "
                  >
                    {{ "lang_maximum" | language }} 12
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_mobile'].touched &&
                      registrationForm.controls['customer_mobile'].valid &&
                      isMobExists
                    "
                  >
                    {{ "lang_mobile_exists" | language }}
                    <span *ngIf="mobErrMsg"> ({{ mobErrMsg }}) </span>
                  </mat-error>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_vat_tax" | language }} :</label>
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_tax_vat"
                    placeholder="{{ 'lang_vat_tax' | language }}"
                    formControlName="customer_tax_vat"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_tax_vat'].touched &&
                      registrationForm.controls['customer_tax_vat'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_ccr_no" | language }} : </label>
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_ccr_no"
                    placeholder="{{ 'lang_ccr_no' | language }}"
                    formControlName="customer_ccr_no"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_manager" | language }} :<span class="red"
                      >*</span
                    ></label
                  >
                  <ng-select
                    [items]="users"
                    [multiple]="true"
                    bindLabel="label"
                    bindValue="value"
                    clearAllText="Clear"
                    [searchFn]="ds.customSearchFn"
                    appendTo="body"
                    formControlName="customer_managers"
                    placeholder="{{ 'lang_manager' | language }}"
                    (search)="search_users($event)"
                    (focus)="users = []"
                  >
                  </ng-select>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_managers'].touched &&
                      registrationForm.controls['customer_managers'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_users" | language }} :</label>
                  <ng-select
                    [items]="users"
                    [multiple]="true"
                    bindLabel="label"
                    bindValue="value"
                    clearAllText="Clear"
                    [searchFn]="ds.customSearchFn"
                    appendTo="body"
                    formControlName="customer_teams"
                    placeholder="{{ 'lang_users' | language }}"
                    (search)="search_users($event)"
                    (focus)="users = []"
                  >
                  </ng-select>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_teams'].touched &&
                      registrationForm.controls['customer_teams'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_link_with_accounts" | language }} :<span
                      class="red"
                      >*</span
                    ></label
                  >
                  <select
                    type="text"
                    class="form-control almnabrformcontrol customer_link_with_accounts"
                    matTooltip="{{ 'lang_link_with_accounts' | language }}"
                    formControlName="customer_link_with_accounts"
                    (change)="emptyAccountFileds()"
                  >
                    <option value="">
                      {{ "lang_choose_options" | language }}
                    </option>
                    <option value="1">{{ "lang_yes" | language }}</option>
                    <option value="0">{{ "lang_no" | language }}</option>
                  </select>
                  <mat-error
                    *ngIf="
                      registrationForm.controls['customer_link_with_accounts']
                        .touched &&
                      registrationForm.controls['customer_link_with_accounts']
                        .errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}</mat-error
                  >
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_logo" | language }} :
                    <mat-icon
                      *ngIf="isFile"
                      matTooltip="{{ 'lang_delete' | language }}"
                      (click)="removeFile()"
                      class="red mnabricon"
                      >close</mat-icon
                    ></label
                  >
                  <div class="IDDIV">
                    <input
                      name="logo"
                      type="file"
                      onclick="this.value = null"
                      (change)="uploadFile($event)"
                      class="nghide albutton width100"
                    />
                    <label id="uploadButton" class="albutton"
                      >{{ filename }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                registrationForm.get('customer_link_with_accounts').value == '1'
              "
            >
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_address_text" | language }} :</label>
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_address"
                    placeholder="{{ 'lang_address_text' | language }}"
                    formControlName="customer_address"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_credit_days" | language }} : </label>
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_credit_days"
                    placeholder="{{ 'lang_credit_days' | language }}"
                    (keypress)="ds.numberOnly($event)"
                    formControlName="customer_credit_days"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_security_amount" | language }} : </label>
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_security_amount"
                    placeholder="{{ 'lang_security_amount' | language }}"
                    (keypress)="ds.numberOnly($event)"
                    formControlName="customer_security_amount"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_credit_limit" | language }} : </label>
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_credit_limit"
                    placeholder="{{ 'lang_credit_limit' | language }}"
                    (keypress)="ds.numberOnly($event)"
                    formControlName="customer_credit_limit"
                  />
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                registrationForm.get('customer_link_with_accounts').value == '1'
              "
            >
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_discount_limit" | language }} : </label>
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_discount_limit"
                    placeholder="{{ 'lang_discount_limit' | language }}"
                    formControlName="customer_discount_limit"
                    (keypress)="ds.numberOnly($event)"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_discount_on_payment" | language }} : </label>
                  <input
                    type="text"
                    class="form-control almnabrformcontrol customer_discount_onpay"
                    placeholder="{{ 'lang_discount_on_payment' | language }}"
                    formControlName="customer_discount_onpay"
                    (keypress)="ds.numberOnly($event)"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_salesman" | language }} : </label>
                  <select
                    type="text"
                    class="form-control almnabrformcontrol customer_salesmancode"
                    matTooltip="{{ 'lang_salesman' | language }}"
                    formControlName="customer_salesmancode"
                  >
                    <option value="">
                      {{ "lang_choose_options" | language }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_salesman_area" | language }} : </label>
                  <select
                    type="text"
                    class="form-control almnabrformcontrol customer_salesmanarea"
                    matTooltip="{{ 'lang_salesman_area' | language }}"
                    formControlName="customer_salesmanarea"
                  >
                    <option value="">
                      {{ "lang_choose_options" | language }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                registrationForm.get('customer_link_with_accounts').value == '1'
              "
            >
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_stop_transaction" | language }} : </label>
                  <select
                    type="text"
                    class="form-control almnabrformcontrol customer_stop_transaction"
                    matTooltip="{{ 'lang_link_with_accounts' | language }}"
                    formControlName="customer_stop_transaction"
                  >
                    <option value="">
                      {{ "lang_choose_options" | language }}
                    </option>
                    <option value="1">{{ "lang_yes" | language }}</option>
                    <option value="0">{{ "lang_no" | language }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>{{ "lang_account" | language }} : </label>
                  <ng-select
                    [items]="accounts"
                    [multiple]="false"
                    bindLabel="label"
                    bindValue="value"
                    clearAllText="Clear"
                    [searchFn]="ds.customSearchFn"
                    appendTo="body"
                    formControlName="customer_account_id"
                    placeholder="{{ 'lang_account' | language }}"
                    (search)="accountSearch($event)"
                    (focus)="accounts = []"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer padb0">
            <div class="row">
              <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                <button
                  type="button"
                  routerLink="/customers/lists"
                  mat-button
                  class="albutton mnabrbutton mat-accent"
                >
                  {{ "lang_goto_list" | language }}
                </button>
              </div>
              <div class="col-md-8 col-sm-8 col-xs-12 mb-10">
                <app-alert id="default-alert"></app-alert>
              </div>
              <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                <button
                  type="submit"
                  class="albutton mnabrbutton mat-accent"
                  mat-button
                >
                  {{ "lang_submit" | language }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
