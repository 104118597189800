import { AfterViewInit, Component, OnInit } from "@angular/core";
import { DataService } from "@core/bootstrap/data.service";
import { ToggleMenuService } from "@core/bootstrap/toggle-menu.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { Subscription } from "rxjs";
import * as echarts from "echarts";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { TopCountRequestsComponent } from "app/routes/projects/services/supervision/supervision-main-dashboard/top-count-requests/top-count-requests.component";
import { MatDialog } from "@angular/material/dialog";
import { Report1FiltersComponent } from "app/routes/projects/services/supervision/supervision-main-dashboard/report1-filters/report1-filters.component";
import { Report1ModalComponent } from "app/routes/projects/services/supervision/supervision-main-dashboard/report1-modal/report1-modal.component";
import { PreloaderService } from "@core";
import { LocalStorage } from "@core/bootstrap/localstorage.service";

@Component({
	selector: "app-project-supervision-dashboard",
	templateUrl: "./project-supervision-dashboard.component.html",
	styleUrls: ["./project-supervision-dashboard.component.scss"],
})
export class ProjectSupervisionDashboardComponent
	implements OnInit, AfterViewInit
{
	apisSubscription = new Subscription();
	project_setting_data;
	projects_work_area_id =
		localStorage.getItem("selected_projects_workarea") || null;
	project_profile_id = localStorage.getItem("projects_profile_id") || null;
	project_supervision_id =
		localStorage.getItem("projects_supervision_id") || null;
	wir_data;
	formsChart: any;
	projectTimeChart: any;
	report3_chart: any;
	axis3Chart: any;
	sum_total_plan = 0;
	sum_actual_total = 0;
	last_date_plan = "";
	//topCountRequests
	topCountRequests = [];
	topCountRequestsLimit = "10";
	//report1
	report1_data: any;
	show_ok = false;
	show_notok = true;
	show_notuse = false;
	report1_pageno = 1;
	report1_pagesize = 5;
	report1_filters: any;
	isAdmin =
		typeof this.ls.getItem("is_admin") == "string"
			? Number(this.ls.getItem("is_admin"))
			: this.ls.getItem("is_admin");
	report1_trigger = true;
	report1_total_ok = 0;
	report1_total_notok = 0;
	report1_total_notused = 0;
	lateData: any;
	constructor(
		public ds: DataService,
		private lang: LanguagePipe,
		private toggleMenu: ToggleMenuService,
		private sweetAlert: SweetAlertService,
		private dialog: MatDialog,
		private ls: LocalStorage,
		public spinner: PreloaderService
	) {}

	ngOnInit(): void {
		if (this.projects_work_area_id) {
			this.getCountData();
			this.getMonthlyData();
			this.getTopCountRequests();
			this.getReport1Data(false);
			this.getLateData();
		}
		this.ds.general_work_area_id.subscribe((res) => {
			if (res) {
				this.projects_work_area_id =
					localStorage.getItem("selected_projects_workarea") || null;
				this.project_profile_id =
					localStorage.getItem("projects_profile_id") || null;
				this.project_supervision_id =
					localStorage.getItem("projects_supervision_id") || null;
				this.getCountData();
				this.getMonthlyData();
				this.getTopCountRequests();
				this.getReport1Data(false);
				this.getLateData();
			}
		});
	}

	ngAfterViewInit(): void {
		// this.initFormsChart();
		this.initAxis3Chart();
		this.initProjectTimeChart();
		// this.initReport3Chart();
		window.onresize = () => {
			// this.formsChart.resize();
			this.projectTimeChart.resize();
			// this.report3_chart.resize();
			this.axis3Chart.resize();
		};
		this.toggleMenu.isMob.subscribe((res) => {
			setTimeout(() => {
				// this.formsChart.resize();
				this.axis3Chart.resize();
				this.projectTimeChart.resize();
			}, 200);
		});
	}

	initFormsChart() {
		//forms chart
		this.formsChart = echarts.init(document.getElementById("forms-chart"));
		this.formsChart.showLoading();
	}
	initProjectTimeChart() {
		//ProjectTime chart
		this.projectTimeChart = echarts.init(
			document.getElementById("project-time-chart")
		);
		this.projectTimeChart.showLoading();
	}

	initAxis3Chart() {
		//axis3 chart
		this.axis3Chart = echarts.init(document.getElementById("axis3-chart"));
		this.axis3Chart.showLoading();
	}
	initReport3Chart() {
		//report3Chart chart
		this.report3_chart = echarts.init(document.getElementById("report3-chart"));
		this.report3_chart.showLoading();
	}

	getCountData() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		this.apisSubscription.add(
			this.ds
				.post(
					`pr/dashboard_counts/${this.project_profile_id}/${this.project_supervision_id}`,
					formData
				)
				.subscribe((res) => {
					if (res?.status) {
						this.project_setting_data = res?.result?.project_setting;
						this.wir_data = res?.result?.wir_data;
						this.initProjectTimeChart();
						this.updateProjectTimeChart({
							date_from: this.project_setting_data?.supervision_start_date,
							date_to: this.project_setting_data?.supervision_expiry_date,
							done_days: this.customizeFraction(
								this.project_setting_data?.done_days
							),
							left_days: this.customizeFraction(
								this.project_setting_data?.left_days
							),
							total_days: this.customizeFraction(
								this.project_setting_data?.total_days
							),
							late_days: this.customizeFraction(
								this.project_setting_data?.late_days
							),
							average_done_days: this.customizeFraction(
								this.project_setting_data?.average_done_days
							),
							average_left_days: this.customizeFraction(
								this.project_setting_data?.average_left_days
							),
						});
					}
				})
		);
	}
	getMonthlyData() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		this.apisSubscription.add(
			this.ds
				.post(
					`pr/plan_results/${this.project_profile_id}/${this.project_supervision_id}`,
					formData
				)
				.subscribe((res) => {
					if (res?.status) {
						let customData = res?.result?.map((el, index) => {
							let actual_data = res?.result?.slice(0, index + 1);
							let actual_numbers = actual_data?.map((item) => {
								if (item?.monthly_actual_total) {
									return parseFloat(item?.monthly_actual_total);
								} else {
									return 0.0;
								}
							});

							let sum_actual_total = actual_numbers.reduce((accumulator, a) => {
								return accumulator + a;
							}, 0);
							return {
								month_plan_setting: el?.month_plan_setting,
								monthly_total_plan: el?.monthly_total_plan
									? this.customizeFraction(el?.monthly_total_plan)
									: 0.0,
								sum_total_plan: el?.sum_total_plan
									? this.customizeFraction(el?.sum_total_plan)
									: 0.0,
								monthly_actual_total: el?.monthly_actual_total
									? this.customizeFraction(el?.monthly_actual_total)
									: 0.0,
								monthly_sum_actual_total: sum_actual_total
									? this.customizeFraction(sum_actual_total + "")
									: 0.0,
								forecast_average: el?.forecast_average || 0,
							};
						});
						this.initAxis3Chart();
						this.updateAxis3Chart(customData);
					}
				})
		);
	}

	updateFormsChart(data: any) {
		let formNames = [],
			pending_data = [],
			completed_data = [],
			accepted_data = [],
			rejected_data = [],
			acceptedA_data = [],
			acceptedB_data = [],
			rejectedC_data = [],
			rejectedD_data = [],
			accepted_requests_data = [],
			rejected_requests_data = [];
		formNames = Object.keys(data);
		for (let form of Object.values(data)) {
			pending_data.push(form["total_pending_request"]);
			completed_data.push(form["total_complete_requests"]);
			accepted_data.push(form["total_accepted_result"]);
			rejected_data.push(form["total_rejected_result"]);
			acceptedA_data.push(form["total_accepted_result_A"]);
			acceptedB_data.push(form["total_accepted_result_B"]);
			rejectedC_data.push(form["total_rejected_result_C"]);
			rejectedD_data.push(form["total_rejected_result_D"]);
			accepted_requests_data.push({
				value: form["total_accepted_requests"],
				completed_value: form["total_complete_requests"],
			});
			rejected_requests_data.push({
				value: form["total_rejected_requests"],
				completed_value: form["total_complete_requests"],
			});
		}
		const newOption = {
			tooltip: {
				trigger: "item",
				axisPointer: {
					type: "shadow",
				},
			},
			legend: { show: true, type: "scroll" },
			grid: {
				containLabel: true,
			},
			dataZoom: [
				{
					id: "dataZoomX",
					type: "slider",
					xAxisIndex: [0],
					filterMode: "filter",
				},
				{
					id: "dataZoomY",
					type: "slider",
					yAxisIndex: [0],
					filterMode: "empty",
				},
			],
			xAxis: [
				{
					type: "category",
					data: formNames,
				},
			],
			yAxis: [
				{
					type: "value",
				},
			],
			series: [
				{
					name: this.lang.transform("lang_accepted_requests"),
					type: "bar",
					label: {
						show: true,
					},
					itemStyle: {
						color: "#91ca8c",
					},
					stack: "P_C",
					barWidth: "17%",
					barMinWidth: "17%",
					barMaxWidth: "17%",
					emphasis: {
						focus: "series",
					},
					data: accepted_requests_data,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data?.value}</span> of ${params?.data?.completed_value}`;
						},
					},
				},
				{
					name: this.lang.transform("lang_rejected_requests"),
					type: "bar",
					label: {
						show: true,
					},
					itemStyle: {
						color: "#b93939",
					},
					stack: "P_C",
					barWidth: "17%",
					barMinWidth: "17%",
					barMaxWidth: "17%",
					emphasis: {
						focus: "series",
					},
					data: rejected_requests_data,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data?.value}</span> of ${params?.data?.completed_value}`;
						},
					},
				},
				{
					name: this.lang.transform("lang_pending"),
					type: "bar",
					label: {
						show: true,
					},
					stack: "P_C",
					barWidth: "17%",
					barMinWidth: "17%",
					barMaxWidth: "17%",
					itemStyle: {
						color: "#FFDB5C",
					},
					emphasis: {
						focus: "series",
					},
					data: pending_data,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_accepted_result"),
					type: "bar",
					label: {
						show: true,
					},
					itemStyle: {
						color: "#91ca8c",
					},
					stack: "R_A",
					barWidth: "17%",
					barMinWidth: "17%",
					barMaxWidth: "17%",
					emphasis: {
						focus: "series",
					},
					data: accepted_data,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_rejected_result"),
					type: "bar",
					label: {
						show: true,
					},
					itemStyle: {
						color: "#b93939",
					},
					stack: "R_A",
					barWidth: "17%",
					barMinWidth: "17%",
					barMaxWidth: "17%",
					emphasis: {
						focus: "series",
					},
					data: rejected_data,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},

				{
					name: this.lang.transform("lang_accepted_a"),
					type: "bar",
					label: {
						show: true,
					},
					data: acceptedA_data,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
					itemStyle: {
						color: "#8dc1a9",
					},
					stack: "A_B_C_D",
					barWidth: "17%",
					barMinWidth: "17%",
					barMaxWidth: "17%",
					emphasis: {
						focus: "series",
					},
				},
				{
					name: this.lang.transform("lang_accepted_b"),
					type: "bar",
					label: {
						show: true,
					},
					data: acceptedB_data,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
					itemStyle: {
						color: "#759aa0",
					},
					stack: "A_B_C_D",
					barWidth: "17%",
					barMinWidth: "17%",
					barMaxWidth: "17%",
					emphasis: {
						focus: "series",
					},
				},
				{
					name: this.lang.transform("lang_rejected_c"),
					type: "bar",
					label: {
						show: true,
					},
					itemStyle: {
						color: "#c55a5a",
					},
					data: rejectedC_data,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
					stack: "A_B_C_D",
					barWidth: "17%",
					barMinWidth: "17%",
					barMaxWidth: "17%",
					emphasis: {
						focus: "series",
					},
				},
				{
					name: this.lang.transform("lang_rejected_d"),
					type: "bar",
					label: {
						show: true,
					},
					data: rejectedD_data,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
					itemStyle: {
						color: "#f76f6f",
					},
					barWidth: "17%",
					barMinWidth: "17%",
					barMaxWidth: "17%",
					stack: "A_B_C_D",
					emphasis: {
						focus: "series",
					},
				},
			],
		};
		this.formsChart.setOption(newOption);
		this.formsChart.hideLoading();
	}

	updateProjectTimeChart(data: any) {
		let option = {
			tooltip: {
				trigger: "item",
			},
			legend: {
				type: "scroll",
			},
			clockwise: true,
			series: [
				{
					name: this.lang.transform("lang_project_date"),
					type: "pie",
					radius: "50%",
					label: {
						position: "inside",
						formatter: (params) => {
							return `${params?.name} (${params?.data?.value} %)`;
						},
						fontWeight: "bold",
					},
					data: [
						{
							value: data?.average_done_days,
							name: this.lang.transform("lang_done_days"),
							label: { show: true },
							itemStyle: {
								color: "#91ca8c",
							},
						},
						{
							value: data?.average_left_days,
							label: { show: true },
							name: this.lang.transform("lang_left_days"),
							itemStyle: {
								color: "#517eb7",
							},
						},
						{
							value: data?.late_days,
							label: { show: true },
							name: this.lang.transform("lang_late_days"),
							itemStyle: {
								color: "#bd3c4e",
							},
						},
					],
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: "rgba(0, 0, 0, 0.5)",
						},
					},
				},
			],
		};
		this.projectTimeChart.setOption(option);
		this.projectTimeChart.hideLoading();
	}
	updateAxis3Chart(data: any) {
		let xAxisData = [],
			monthly_actual_total = [],
			monthly_total_plan = [],
			sum_total_plan = [],
			monthly_sum_actual_total = [],
			forcast_line = [],
			forcast_bar = [];

		let currentDate = new Date();
		let currentMonth =
			("0" + (currentDate.getMonth() + 1)).slice(-2) +
			"/" +
			currentDate.getFullYear();
		let currentMonthIndex = data
			.map((el) => el?.month_plan_setting)
			.indexOf(currentMonth);
		for (let i = 0; i < data.length; i++) {
			xAxisData.push(data[i]?.month_plan_setting);
			monthly_actual_total.push(data[i]?.monthly_actual_total);
			monthly_total_plan.push(data[i]?.monthly_total_plan);
			monthly_sum_actual_total.push(data[i]?.monthly_sum_actual_total);
			sum_total_plan.push(data[i]?.sum_total_plan);
			if (i >= currentMonthIndex) {
				forcast_line.push(+data[i]?.sum_total_plan + data[i]?.forecast_average);
				forcast_bar.push(data[i]?.forecast_average);
			} else {
				forcast_line.push(data[i]?.sum_total_plan);
				forcast_bar.push(null);
			}
		}

		monthly_sum_actual_total = monthly_sum_actual_total.slice(
			0,
			xAxisData.indexOf(currentMonth) + 1
		);
		this.sum_actual_total =
			monthly_sum_actual_total[monthly_sum_actual_total.length - 1];
		this.sum_total_plan = sum_total_plan[monthly_sum_actual_total.length - 1];
		this.last_date_plan = xAxisData[monthly_sum_actual_total.length - 1];

		let newOption = {
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "cross",
					label: {
						backgroundColor: "#283b56",
					},
				},
			},
			legend: {},
			grid: {
				containLabel: true,
			},
			dataZoom: [
				{
					id: "dataZoomX",
					type: "slider",
					xAxisIndex: [0],
					filterMode: "filter",
				},
				{
					id: "dataZoomY",
					type: "slider",
					yAxisIndex: [0, 1],
					filterMode: "empty",
				},
			],
			xAxis: [
				{
					type: "category",
					boundaryGap: true,
					showMaxLabel: false,
					data: xAxisData,
					axisLabel: {
						hideOverlap: false,
						fontSize: 12,
						rotate: 40,
						interval: 0,
					},
				},
			],
			yAxis: [
				{
					type: "value",
					scale: true,
					max: 15,
					min: 0,
					boundaryGap: [0.2, 0.2],
				},
				{
					type: "value",
					scale: true,
					max: 120,
					min: 0,
					boundaryGap: [0.2, 0.2],
				},
			],
			series: [
				{
					name: this.lang.transform("lang_monthly_total_plan"),
					type: "bar",
					yAxisIndex: 0,
					data: monthly_total_plan,
					stack: "forcast_monthly_plan",
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_forcast"),
					type: "bar",
					yAxisIndex: 0,
					data: forcast_bar,
					stack: "forcast_monthly_plan",
					itemStyle: {
						color: "#e9c74d",
					},
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_actual_total"),
					type: "bar",
					yAxisIndex: 0,
					data: monthly_actual_total,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_sum_total_plan"),
					type: "line",
					yAxisIndex: 1,
					data: sum_total_plan,
					itemStyle: {
						color: "#e7d42f",
					},
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_forcast"),
					type: "line",
					yAxisIndex: 1,
					data: forcast_line,
					itemStyle: {
						color: "#e9c74d",
					},
					lineStyle: {
						type: "dotted",
					},
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_sum_actual_total"),
					type: "line",
					yAxisIndex: 1,
					data: monthly_sum_actual_total,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
					itemStyle: {
						color: "#56ab59",
					},
				},
			],
		};
		this.axis3Chart.setOption(newOption);
		this.axis3Chart.hideLoading();
	}

	//openTopCountRequests
	getTopCountRequests() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("limit", this.topCountRequestsLimit || "");
		this.apisSubscription.add(
			this.ds.post("pr/get_top_count_requests", formData).subscribe(
				(res) => {
					if (res?.status) {
						this.topCountRequests = res?.records;
					} else {
						this.topCountRequests = [];
					}
				},
				(err) => {
					this.topCountRequests = [];
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			)
		);
	}
	openTopCountRequests(item) {
		const dialogRef = this.dialog.open(TopCountRequestsComponent, {
			width: "1300px",
			data: {
				projects_work_area_id: this.projects_work_area_id,
				itemRow: item,
			},
		});
	}
	getLateData() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		this.apisSubscription.add(
			this.ds
				.post("pr/get_count_wir_late_contractor", formData)
				.subscribe((res) => {
					if (res?.status) {
						this.lateData = res?.data;
					}
				})
		);
	}
	// report 1
	scrollLeft() {
		document.getElementById("table-container").scrollLeft -= 150;
	}
	scrollRight() {
		document.getElementById("table-container").scrollLeft += 150;
	}
	getReport1Data(isExcel?) {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id);
		formData.append(
			"template_id",
			this.report1_filters?.template_id?.toString() || ""
		);
		formData.append(
			"zone_id",
			this.report1_filters?.zone_id?.phase_zone_no || ""
		);
		formData.append(
			"block_id",
			this.report1_filters?.block_id?.phase_zone_block_no || ""
		);
		formData.append(
			"cluster_id",
			this.report1_filters?.cluster_id?.phase_zone_block_cluster_no || ""
		);
		formData.append("unit_id", this.report1_filters?.unit_id || "");
		formData.append("group_1", this.report1_filters?.group_1?.toString() || "");
		if (this.isAdmin) {
			formData.append("from_trigger", this.report1_trigger ? "1" : "0");
		} else {
			formData.append("from_trigger", "1");
		}
		if (isExcel) {
			if (
				this.report1_filters?.zone_id == null ||
				this.report1_filters?.group_1 == null
			) {
				this.sweetAlert.warningToast(
					"Please filter data by zone and division to get excel.",
					2000
				);
				return;
			}
			formData.append("export", "excel");
			this.spinner.show();
		} else {
			formData.append("export", "");
		}
		this.apisSubscription.add(
			this.ds
				.post(
					`pr/get_used_unused_requests/${this.report1_pageno}/${this.report1_pagesize}`,
					formData
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res?.status) {
							if (isExcel) {
								let url =
									"data:" + res["content-type"] + ";base64," + res.base64;
								let downloadExcel = document.createElement("a");
								downloadExcel.style.display = "none";
								downloadExcel.href = url;
								downloadExcel.download = "used_unused_report";
								document.body.appendChild(downloadExcel);
								downloadExcel.click();
								document.body.removeChild(downloadExcel);
							} else {
								this.report1_data = res;
								res?.totals.map((el) => {
									if (el?.request_status == "OK") {
										this.report1_total_ok = el?.total_requests;
									} else if (el?.request_status == "NOT_USED") {
										this.report1_total_notused = el?.total_requests;
									} else if (el?.request_status == "NOT_OK") {
										this.report1_total_notok = el?.total_requests;
									}
								});
							}
						} else {
							this.report1_data = null;
							this.sweetAlert.errorToast(res?.error, 2000);
						}
					},
					(err) => {
						this.spinner.hide();
						this.sweetAlert.errorToast(
							this.lang.transform("lang_internal_server_error"),
							2000
						);
					}
				)
		);
	}
	openReport1Row(unit_id, group_1, request_status) {
		const dialogRef = this.dialog.open(Report1ModalComponent, {
			width: "80%",
			autoFocus: false,
			data: {
				projects_work_area_id: this.projects_work_area_id,
				unit_id: unit_id,
				group_1: group_1,
				request_status: request_status,
				isTrigger: this.report1_trigger,
			},
		});
	}
	onFilterReport1() {
		const dialogRef = this.dialog.open(Report1FiltersComponent, {
			width: "500px",
			data: {
				projects_profile_id: this.project_profile_id,
				projects_supervision_id: this.project_supervision_id,
				projects_work_area_id: this.projects_work_area_id,
				filters: this.report1_filters,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.report1_filters = result;
				this.report1_pageno = 1;
				this.spinner.show();
				this.getReport1Data(false);
			}
		});
	}

	customizeFraction(number) {
		let customNumber = "0";
		if (number) {
			let numbers = number?.split(".");
			if (numbers.length > 1) {
				if (numbers[1]?.length > 2) {
					return (customNumber =
						numbers[0] + "." + numbers[1][0] + numbers[1][1] + numbers[1][2]);
				} else if (numbers[1].length > 1) {
					return (customNumber =
						numbers[0] + "." + numbers[1][0] + numbers[1][1]);
				} else {
					return (customNumber = numbers[0] + "." + numbers[1][0]);
				}
			} else {
				return (customNumber = numbers[0]);
			}
		} else {
			return customNumber;
		}
	}

	ngOnDestroy() {
		this.apisSubscription.unsubscribe();
	}
}
