<div mat-dialog-title class="d-flex justify-content-between">
	<h4 *ngIf="!editMode">
		{{ "lang_add" | language }}
	</h4>
	<h4 *ngIf="editMode">
		{{ "lang_edit" | language }}
	</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="point_form">
		<div class="p-2">
			<div class="row">
				<div class="col-12 col-sm-6">
					<div class="title">
						<mat-label class="item-label">{{
							"lang_title" | language
						}}</mat-label>
						<input
							type="text"
							placeholder="{{ 'lang_title' | language }}"
							formControlName="meeting_title"
						/>
					</div>
				</div>
				<div class="col-12 col-sm-6 mb-2">
					<label class="item-label">{{ "lang_members" | language }}</label>
					<ng-select
						[items]="search_point_users"
						[multiple]="true"
						bindLabel="label"
						bindValue="value"
						appendTo="body"
						clearAllText="Clear"
						[searchFn]="ds.customSearchFn"
						formControlName="users"
						placeholder="{{ 'lang_members' | language }}"
						(search)="search_users($event)"
					>
					</ng-select>
				</div>
				<div
					class="col-12 col-sm-6 mb-2"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_date" | language }}</mat-label>
						<input matInput required readonly formControlName="meeting_date" />
						<mat-datepicker-toggle
							matSuffix
							(click)="picker.open()"
						></mat-datepicker-toggle>
						<mbsc-datepicker
							[controls]="['calendar']"
							select="date"
							[touchUi]="false"
							dateFormat="YYYY/MM/DD"
							[(ngModel)]="dateMobiscroll"
							[buttons]="autoButtons"
							[ngModelOptions]="{ standalone: true }"
							(onClose)="custmizeDate('start_date')"
							style="visibility: hidden; height: 0"
							#picker
						>
						</mbsc-datepicker>
					</mat-form-field>
				</div>
				<div class="col-12 mb-2">
					<label>{{ "lang_description" | language }}</label>
					<ckeditor
						#editor
						[config]="config"
						formControlName="meeting_details"
						[ngClass]="{ ' contentrtl': lang_key == 'ar' }"
					></ckeditor>
				</div>
				<div class="col-12 TableParts">
					<p class="mb-10">
						{{ "lang_checklist" | language }}
						<span class="red" *ngIf="checklistArray?.length">*</span>
					</p>
					<div
						class="table-responsive customResponsive tablefields quotationitemtable"
					>
						<table
							border="1"
							class="table CustomTable"
							style="width: 99% !important"
							formArrayName="point"
						>
							<thead>
								<tr>
									<td width="2%" style="padding: 5px; word-break: break-all">
										#
									</td>
									<td width="2%" style="padding: 5px; word-break: break-all">
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addChecklist()"
											>add_box</mat-icon
										>
									</td>
									<td width="95%" style="padding: 5px; word-break: break-all">
										{{ "lang_title" | language }}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let item of checklistFormGroup.controls;
										let i = index;
										let odd = odd;
										let even = even
									"
									[formGroupName]="i"
								>
									<td
										class="verticalalignmid"
										style="padding: 5px; word-break: break-all"
									>
										{{ i + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											(click)="removeChecklist(i)"
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											>delete_forever</mat-icon
										>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											type="text"
											formControlName="title"
											placeholder="{{ 'lang_description' | language }}"
											autocomplete="off"
											class="form-control almnabrformcontrol"
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-0 col-sm-9"></div>
	<div class="col-12 col-sm-3">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="addUpdateMeeting()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
