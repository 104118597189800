import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.scss']
})
export class ForgetComponent implements OnInit {
  public form: FormGroup;
  sendstatus: boolean = false;
  apistatus:boolean = false;
  readonly:boolean = false;
  sendcodestatus = false;
  codemsg = this.lang.transform('lang_send_code');
  data:any;
  lang_key = localStorage.getItem('lang_key') || 'en';
  constructor(private cdRef: ChangeDetectorRef, public ds: DataService, public route:ActivatedRoute ,public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      user_username     : new FormControl('', [Validators.required, Validators.minLength(6)]),
      user_email        : new FormControl('', [Validators.required, Validators.email]),
    });
  }
  submit() {
    this.alert.clear();
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'fpassword/'+this.lang_key).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.form.reset();
        this.ds.dialogf('', res.msg);
        this.router.navigate(['/']);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}
