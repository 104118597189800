import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-ticket-task-users",
	templateUrl: "./ticket-task-users.component.html",
	styleUrls: ["./ticket-task-users.component.scss"],
})
export class TicketTaskUsersComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	users = [];
	constructor(
		public dialogRef: MatDialogRef<TicketTaskUsersComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.users = data?.users;
	}

	ngOnInit(): void {}
}
