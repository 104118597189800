import { Component, OnDestroy, OnInit } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
interface list {
	id?: number;
	class: string;
	account_code: string;
	account_name: string;
	amount: string;
}
@Component({
	selector: "app-balance-sheets",
	templateUrl: "./balance-sheets.component.html",
	styleUrls: ["./balance-sheets.component.scss"],
})
export class BalanceSheetsComponent implements OnInit, OnDestroy {
	form: FormGroup;
	branches: any = [];
	finances: any = [];
	lodingdatas = this.lang.transform("lang_loading");
	finance_id: any = null;
	datastatus = false;
	//pageing 1 for Previous Year Assets Statements
	papage = 1;
	papageSize = 10;
	pacollectionSize;
	palistofdata: list[];
	padata: any = [];
	patotals: any = [];
	//pageing 2 for Previous Year Capital Statements
	pcpage = 1;
	pcpageSize = 10;
	pccollectionSize;
	pclistofdata: list[];
	pcdata: any = [];
	pctotals: any = [];
	//pageing 3 for Previous Year Liabilities Statements
	plpage = 1;
	plpageSize = 10;
	plcollectionSize;
	pllistofdata: list[];
	pldata: any = [];
	pltotals: any = [];
	//pageing 4 for Current Year Assets Statements
	capage = 1;
	capageSize = 10;
	cacollectionSize;
	calistofdata: list[];
	cadata: any = [];
	catotals: any = [];
	//pageing 5 for Current Year Capital Statements
	ccpage = 1;
	ccpageSize = 10;
	cccollectionSize;
	cclistofdata: list[];
	ccdata: any = [];
	cctotals: any = [];
	//pageing 6 for Current Year Liabilities Statements
	clpage = 1;
	clpageSize = 10;
	clcollectionSize;
	cllistofdata: list[];
	cldata: any = [];
	cltotals: any = [];
	settings = environment.singledropdown;
	branch_id: any =
		localStorage.getItem("selectedBranch") != null
			? localStorage.getItem("selectedBranch")
			: "0";
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public fb: FormBuilder,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.buildform();
		this.spinner.show();
		this.load_branches();
		if (this.branch_id && this.branch_id != "0") {
			this.load_financial_years();
		}
	}
	private subscriptions = new Subscription();
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
	public selectBrach(branch) {
		this.datastatus = false;
		if (branch && branch != "0") {
			localStorage.setItem("selectedBranch", branch);
			this.branch_id = branch;
			this.load_financial_years();
		} else {
			this.spinner.hide();
		}
	}
	public selectFinanceYear(financeid) {
		if (financeid && financeid != "0") {
			this.finance_id = financeid;
		}
	}
	public load_branches() {
		this.branches = [];
		this.subscriptions.add(
			this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.branches = res.records;
					}
				},
				(error) => {
					this.spinner.hide();
				}
			)
		);
	}
	public load_financial_years() {
		this.finances = [];
		this.form.get("finance_id").setValue("");
		this.subscriptions.add(
			this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.finances = res.records;
						this.finances.forEach((v, k) => {
							if (v.finance_status == "1") {
								this.form.get("finance_id").setValue(v.finance_id);
								this.form.get("period_from").setValue(v.finance_start_date);
								this.form.get("period_to").setValue(v.finance_end_date);
							}
						});
					}
				},
				(error) => {
					this.spinner.hide();
				}
			)
		);
	}
	public buildform() {
		this.form = new FormGroup({
			branch_id: new FormControl(this.branch_id, [Validators.required]),
			finance_id: new FormControl(null, [Validators.required]),
			report_type: new FormControl("GLEDGER", [Validators.required]),
			period_from: new FormControl("", [Validators.required]),
			period_to: new FormControl("", [Validators.required]),
			account_level: new FormControl(""),
			compare_previous_year: new FormControl(""),
		});
	}
	public setDate() {
		let date = this.form.get("finance_id").value;
		if (
			date &&
			date.length > 0 &&
			date[0]["finance_start_date"] &&
			date[0]["finance_end_date"]
		) {
			this.form.get("period_from").setValue(date[0]["finance_start_date"]);
			this.form.get("period_to").setValue(date[0]["finance_end_date"]);
		}
	}
	public fetch_statements() {
		if (this.form.valid) {
			this.spinner.show();
			let param = new FormData();
			param.set("branch_id", this.form.get("branch_id").value);
			param.set(
				"finance_id",
				this.form.get("finance_id").value
					? this.form.get("finance_id").value
					: "0"
			);
			param.set("report_type", this.form.get("report_type").value);
			if (this.form.get("report_type").value == "ALEVEL") {
				param.set(
					"account_level",
					this.form.get("account_level").value
						? this.form.get("account_level").value
						: "1"
				);
			} else {
				param.set("account_level", "");
			}
			param.set(
				"compare_previous_year",
				this.form.get("compare_previous_year").value == true ? "1" : "0"
			);
			param.set(
				"period_from",
				this.form.get("period_from").value != null
					? this.form.get("period_from").value
					: ""
			);
			param.set(
				"period_to",
				this.form.get("period_to").value != null
					? this.form.get("period_to").value
					: ""
			);
			this.subscriptions.add(
				this.ds.postActionByUrl(param, "balance_sheets").subscribe(
					(res) => {
						this.datastatus = true;
						this.spinner.hide();
						if (res.status) {
							if (
								this.form.get("compare_previous_year").value == true &&
								res.previous_year_records.assets_records.length > 0
							) {
								this.palistofdata = res.previous_year_records.assets_records;
								this.pacollectionSize =
									res.previous_year_records.assets_records.length;
								this.patotals = res.previous_year_records.assets_records.length;
								this.passets();
							}
							if (
								this.form.get("compare_previous_year").value == true &&
								res.previous_year_records.liabilities_records.length > 0
							) {
								this.pclistofdata =
									res.previous_year_records.liabilities_records;
								this.pccollectionSize =
									res.previous_year_records.liabilities_records.length;
								this.pctotals =
									res.previous_year_records.liabilities_records.length;
								this.pcapital();
							}
							if (
								this.form.get("compare_previous_year").value == true &&
								res.previous_year_records.capital_records.length > 0
							) {
								this.pllistofdata = res.previous_year_records.capital_records;
								this.plcollectionSize =
									res.previous_year_records.capital_records.length;
								this.pltotals =
									res.previous_year_records.capital_records.length;
								this.pliable();
							}
							if (res.current_year_records.assets_records.length > 0) {
								this.calistofdata = res.current_year_records.assets_records;
								this.cacollectionSize =
									res.current_year_records.assets_records.length;
								this.catotals = res.current_year_records.assets_records.length;
								this.cassets();
							}
							if (res.current_year_records.liabilities_records.length > 0) {
								this.cclistofdata =
									res.current_year_records.liabilities_records;
								this.cccollectionSize =
									res.current_year_records.liabilities_records.length;
								this.cctotals =
									res.current_year_records.liabilities_records.length;
								this.ccapital();
							}
							if (res.current_year_records.capital_records.length > 0) {
								this.cllistofdata = res.current_year_records.capital_records;
								this.clcollectionSize =
									res.current_year_records.capital_records.length;
								this.cltotals = res.current_year_records.capital_records.length;
								this.cliable();
							}
						} else {
							this.datastatus = false;
							this.ds.dialogf("", res.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.datastatus = false;
						this.ds.dialogf(
							"",
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
			);
		} else {
			this.ds.dialogf("", this.lang.transform("lang_fill_required_fields"));
		}
	}
	public passets() {
		this.padata = this.palistofdata
			.map((list, i) => ({ id: i + 1, ...list }))
			.slice(
				(this.papage - 1) * this.papageSize,
				(this.papage - 1) * this.papageSize + this.papageSize
			);
	}
	public pcapital() {
		this.pcdata = this.pclistofdata
			.map((list, i) => ({ id: i + 1, ...list }))
			.slice(
				(this.pcpage - 1) * this.pcpageSize,
				(this.pcpage - 1) * this.pcpageSize + this.pcpageSize
			);
	}
	public pliable() {
		this.pldata = this.pllistofdata
			.map((list, i) => ({ id: i + 1, ...list }))
			.slice(
				(this.plpage - 1) * this.plpageSize,
				(this.plpage - 1) * this.plpageSize + this.plpageSize
			);
	}
	public cassets() {
		this.cadata = this.calistofdata
			.map((list, i) => ({ id: i + 1, ...list }))
			.slice(
				(this.capage - 1) * this.capageSize,
				(this.capage - 1) * this.capageSize + this.capageSize
			);
	}
	public ccapital() {
		this.ccdata = this.cclistofdata
			.map((list, i) => ({ id: i + 1, ...list }))
			.slice(
				(this.ccpage - 1) * this.ccpageSize,
				(this.ccpage - 1) * this.ccpageSize + this.ccpageSize
			);
	}
	public cliable() {
		this.cldata = this.cllistofdata
			.map((list, i) => ({ id: i + 1, ...list }))
			.slice(
				(this.clpage - 1) * this.clpageSize,
				(this.clpage - 1) * this.clpageSize + this.clpageSize
			);
	}
}
