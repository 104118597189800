<form [formGroup]="form" (ngSubmit)="completeStep4()">
  <div class="card contractor_approval_manager">
    <div class="card-header">
      {{ "lang_human_resource_team" | language }}
      <i
        style="padding: 0 10px"
        class="fa fa-info-circle orangestatus-color"
        matTooltip="{{ 'lang_first_party_tooltip' | language }}"
      ></i>
    </div>
    <div class="card-body pad0">
      <div class="mbrl15">
        <div class="row mb-10">
          <div class="col-sm-6 col-xs-12 mb-10">
            <label class="mb-10">
              {{ "lang_transaction_approval" | language }} !?.
              <span class="red"> * </span> </label
            ><br />

            <mat-radio-group
              aria-label="Select an option"
              formControlName="approved"
              (change)="checkStatus()"
            >
              <mat-radio-button value="Reject">{{
                "lang_reject" | language
              }}</mat-radio-button>
              <mat-radio-button value="Approve">{{
                "lang_approve" | language
              }}</mat-radio-button>
            </mat-radio-group>
            <mat-error
              class="d-block"
              *ngIf="submitForm && form.controls['approved']?.errors?.required"
              >{{ "lang_field_required" | language }}</mat-error
            >
          </div>
          <div class="col-sm-6 col-xs-12 mb-10">
            <div>
              <label
                >{{ "lang_notes" | language }} :
                <span class="red" *ngIf="rejectedValidators.length > 0">*</span>
              </label>
              <textarea
                class="form-control almnabrformcontrol"
                formControlName="notes"
              ></textarea>
              <mat-error
                class="d-block"
                *ngIf="submitForm && form.controls['notes']?.errors?.required"
                >{{ "lang_field_required" | language }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="verification_code"
                >{{ "lang_enter_verification_code" | language }}
                <span class="red">*</span>:
                <i
                  style="padding: 0 10px"
                  class="fa fa-info-circle orangestatus-color"
                  matTooltip="{{
                    'lang_if_you_not_have_code_hit_sendcode_button' | language
                  }}"
                ></i>
              </label>
              <input
                type="text"
                required
                class="form-control almnabrformcontrol"
                formControlName="transactions_persons_action_code"
              />

              <mat-error
                *ngIf="
                  submitForm &&
                  form.controls['transactions_persons_action_code']?.errors
                    ?.required
                "
                >{{ "lang_field_required" | language }}</mat-error
              >
            </div>
          </div>
          <div class="col-sm-12">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row mb-10">
          <div class="col-sm-4 col-xs-12 mb-10">
            <div>
              <button
                class="albutton mnabrbutton mat-accent"
                type="button"
                (click)="openModal()"
                mat-button
              >
                {{ "lang_send_code" | language }}
              </button>
            </div>
          </div>
          <div class="col-sm-4 col-xs-12 mb-10"></div>
          <div class="col-sm-4 col-xs-12 mb-10">
            <div>
              <input
                *ngIf="form.status == 'VALID'"
                type="submit"
                value="{{ 'lang_submit' | language }}"
                class="albutton mnabrbutton mat-accent"
                mat-button
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
