import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit ,OnDestroy {
  @Input() customer_id;
  @Input() type;
  @Input() able2add;
  @Input() able2edit;
  @Input() able2delete;
  @Input() customer_data;
  modalRefA: BsModalRef;
  modalRefV: BsModalRef;
  public projectform: FormGroup;
  myprojects: any = [];
  ppsize: any = '10';
  ppage: any = 1;
  projectdata: any = [];
  projecterrormsg = this.lang.transform('lang_loading');
  projectviewdata: any = [];
  searchKey = "";
  searchStatus = "";
  constructor(public ds: DataService,public router:Router,public route :ActivatedRoute,public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
  ngOnInit() {
    this.list_of_projects();
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public projectpage(size) {
    this.ppsize = size;
    this.ppage = 1;
    this.list_of_projects();
  }
  public projectlength(page) {
    this.ppage = page;
    this.list_of_projects();
  }
  public search_my_projects() {
    this.ppage = 1;
    this.ppsize = '10';
    this.list_of_projects();
  }
  public list_of_projects() {
    let formData = new FormData();
    formData.append('customer_id', this.customer_id);
    formData.append('search_key', this.searchKey);
    this.subscriptions.add(
      this.ds.getActionByUrl(this.ds.formData2string(formData), 'cs/projects/' + this.ppage + '/' + this.ppsize).subscribe(res => {
            this.spinner.hide();
            if (res.status) {
              this.myprojects = res.records;
              this.projectdata = res;
            } else {
              this.myprojects = [];
              this.projecterrormsg = res.error;
            }
          }, error => {
            this.spinner.hide();
            this.myprojects = [];
            this.projectdata = [];
            this.projecterrormsg = error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
          })      
      )
   
  }
}