<h2 mat-dialog-title>{{ "lang_add_supplier" | language }}</h2>
<mat-dialog-content class="mat-typography">
	<div class="container">
		<form [formGroup]="registrationForm" class="create">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label
								>{{ "lang_human_resources_nameen" | language }} :<span
									class="red"
									>*</span
								></label
							>
							<input
								type="text"
								class="form-control almnabrformcontrol supplier_title_en"
								placeholder="{{ 'lang_human_resources_nameen' | language }}"
								formControlName="supplier_title_en"
							/>
							<mat-error
								*ngIf="
									registrationForm.controls['supplier_title_en'].touched &&
									registrationForm.controls['supplier_title_en'].errors
										?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label
								>{{ "lang_human_resources_namear" | language }} :<span
									class="red"
									>*</span
								></label
							>
							<input
								type="text"
								class="form-control almnabrformcontrol supplier_title_ar"
								placeholder="{{ 'lang_human_resources_namear' | language }}"
								formControlName="supplier_title_ar"
							/>
							<mat-error
								*ngIf="
									registrationForm.controls['supplier_title_ar'].touched &&
									registrationForm.controls['supplier_title_ar'].errors
										?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label
								>{{ "lang_email_address" | language }} :<span class="red"
									>*</span
								></label
							>
							<input
								type="text"
								class="form-control almnabrformcontrol supplier_email_address"
								placeholder="{{ 'lang_email_address' | language }}"
								formControlName="supplier_email_address"
							/>
							<mat-error
								*ngIf="
									registrationForm.controls['supplier_email_address'].touched &&
									registrationForm.controls['supplier_email_address'].errors
										?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
							<mat-error
								*ngIf="
									registrationForm.controls['supplier_email_address'].touched &&
									registrationForm.controls['supplier_email_address'].errors
										?.email
								"
							>
								{{ "lang_field_valid_email" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label
								>{{ "lang_mobile_number" | language }} :<span class="red"
									>*</span
								></label
							>
							<input
								type="text"
								class="form-control almnabrformcontrol supplier_mobile"
								placeholder="{{ 'lang_mobile_number' | language }}"
								minlength="12"
								maxlength="12"
								(keypress)="ds.numberOnly($event)"
								formControlName="supplier_mobile"
							/>
							<mat-error
								*ngIf="
									registrationForm.controls['supplier_mobile'].touched &&
									registrationForm.controls['supplier_mobile'].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
							<mat-error
								*ngIf="
									registrationForm.controls['supplier_mobile'].errors?.minlength
								"
							>
								{{ "lang_minimum" | language }} 12
							</mat-error>
							<mat-error
								*ngIf="
									registrationForm.controls['supplier_mobile'].errors?.maxlength
								"
							>
								{{ "lang_maximum" | language }} 12
							</mat-error>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label
								>{{ "lang_vat_tax" | language }} :<span class="red"
									>*</span
								></label
							>
							<input
								type="text"
								class="form-control almnabrformcontrol supplier_tax_vat"
								placeholder="{{ 'lang_vat_tax' | language }}"
								formControlName="supplier_tax_vat"
							/>
							<mat-error
								*ngIf="
									registrationForm.controls['supplier_tax_vat'].touched &&
									registrationForm.controls['supplier_tax_vat'].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label>{{ "lang_ccr_no" | language }} : </label>
							<input
								type="text"
								class="form-control almnabrformcontrol supplier_ccr_no"
								placeholder="{{ 'lang_ccr_no' | language }}"
								formControlName="supplier_ccr_no"
							/>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label>{{ "lang_supplier_box" | language }} : </label>
							<input
								type="text"
								class="form-control almnabrformcontrol"
								placeholder="{{ 'lang_supplier_box' | language }}"
								formControlName="supplier_box"
							/>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label>{{ "lang_supplier_city" | language }} : </label>
							<input
								type="text"
								class="form-control almnabrformcontrol"
								placeholder="{{ 'lang_supplier_city' | language }}"
								formControlName="supplier_city"
							/>
						</div>
					</div>
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label
								>{{ "lang_supplier_est_date" | language }} :<span class="red"
									>*</span
								></label
							>
							<div class="input-group input-group-sm">
								<input
									[matDatepicker]="sed"
									formControlName="supplier_est_date"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									required
									readonly
								/>
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="sed"
									></mat-datepicker-toggle>
									<mat-datepicker #sed></mat-datepicker>
								</span>
							</div>
							<mat-error
								*ngIf="
									registrationForm.controls['supplier_est_date'].touched &&
									registrationForm.controls['supplier_est_date'].errors
										?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label
								>{{ "lang_supplier_approval_date" | language }} :<span
									class="red"
									>*</span
								></label
							>
							<div class="input-group input-group-sm">
								<input
									[matDatepicker]="sad"
									formControlName="supplier_approval_date"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									required
									readonly
								/>
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="sad"
									></mat-datepicker-toggle>
									<mat-datepicker #sad></mat-datepicker>
								</span>
							</div>
							<mat-error
								*ngIf="
									registrationForm.controls['supplier_approval_date'].touched &&
									registrationForm.controls['supplier_approval_date'].errors
										?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-12">
						<app-alert></app-alert>
					</div>
				</div>
			</div>
		</form>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="d-flex justify-content-between" style="width: 100%">
		<div style="width: 150px">
			<button
				mat-button
				mat-dialog-close
				class="albutton mnabrbutton mat-accent"
			>
				{{ "lang_cancel" | language }}
			</button>
		</div>

		<div style="width: 150px">
			<button
				mat-button
				(click)="create()"
				class="albutton mnabrbutton mat-accent"
			>
				{{ "lang_submit" | language }}
			</button>
		</div>
	</div>
</mat-dialog-actions>
