import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sqr-add-supplier',
  templateUrl: './sqr-add-supplier.component.html',
  styleUrls: ['./sqr-add-supplier.component.scss']
})
export class SqrAddSupplierComponent implements OnInit {

  registrationForm: FormGroup;
  users:any           = [];
  accounts:any        = [];
  types: any          = [];  
  isFile: boolean     = false;
  branchId:any;
  filename: any       = this.lang.transform('lang_select_files');
  constructor(public ds: DataService,public router:Router ,public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService, public dialogRef: MatDialogRef<SqrAddSupplierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.branchId = data?.branchId;
    }
  ngOnInit(): void {
    this.registration_form();
  }
  public registration_form() {
    this.registrationForm = new FormGroup({
      branch_id              : new FormControl(this.branchId, [Validators.required]),
      supplier_title_en      : new FormControl('', [Validators.required]),
      supplier_title_ar      : new FormControl('', [Validators.required]),
      supplier_email_address : new FormControl('', [Validators.required, Validators.email]),
      supplier_mobile        : new FormControl('', [Validators.required]),
      supplier_tax_vat       : new FormControl('', [Validators.required]),
      supplier_ccr_no        : new FormControl('', [Validators.required]),
      supplier_box           : new FormControl(''),
      supplier_city          : new FormControl(''),
      supplier_est_date      : new FormControl(null, [Validators.required]),
      supplier_approval_date : new FormControl(null, [Validators.required]),
    })
  }

  public create() {
    
    if(this.registrationForm.valid) {
      this.spinner.show();
      let param = this.ds.json2formdata(this.registrationForm.value);
      this.ds.postActionByUrl(param, '/form/FORM_SQR/create_supplier').subscribe((data) => {
        this.spinner.hide();
        if (data.status) {
          this.alert.success(data.msg);
          this.registrationForm.reset();
          setTimeout(() => {
            this.dialogRef.close();
          }, 1000);
        } else {
          this.alert.error(data.error);
        }
      },(error) => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      }
    );
    } else {
      this.alert.error(this.lang.transform('lang_all_fields_required'));
    }
  
  }

}
