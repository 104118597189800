import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-ncr-step8',
  templateUrl: './ncr-step8.component.html',
  styleUrls: ['./ncr-step8.component.scss']
})
export class NcrStep8Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  form:FormGroup;
  other_attachments:FormArray;
  users:any           = [];
  lang_key:string     = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
  step_lang = this.lang.transform('lang_contractor_reservation');
  manager_contractor_required;
  filename    = this.lang.transform('lang_select_files');
  constructor(public ds:DataService, private sweetAlert:SweetAlertService, public datepipe : DatePipe , public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id : new FormControl(this.form_request_id, [Validators.required]),
      teserve_transaction    : new FormControl(null, [Validators.required]),
      attachments            : this.fb.array([]),
    });
    this.other_attachments = this.form.get("attachments") as FormArray;
    this.manager_contractor_required = this.allData?.view_request?.form_ncr_data?.records[0]?.manager_contractor_required;
    if(!parseInt(this.manager_contractor_required)) {
      this.step_lang = this.lang.transform('lang_contractor_reservation_by_consultant');
      this.addfiles({attach_title: 'Confirm Recipient Document'})
    }
  }

  get filesFormGroup() {
    return this.form.get("attachments") as FormArray;
  }

  public files(value?): FormGroup {
    return this.fb.group({
      delete_icon     : new FormControl(value?.attach_title || ''),
      attach_title    : new FormControl({value : (value) ? value?.attach_title : '', disabled : (value?.attach_title) ? true : false}, [Validators.required]),
      file            : new FormControl(null,  [Validators.required]),
    });
  }
  public addfiles(value?) {
    if (this.other_attachments.length < 10) {
      this.other_attachments.push(this.files(value));
      this.other_attachments.setValidators(Validators.required);
      this.other_attachments.updateValueAndValidity();
    }
  }
  public removefiles(index) {
    if(this.other_attachments.length == 1) {
      this.other_attachments.removeAt(index);
      this.other_attachments.clearValidators();
      this.other_attachments.updateValueAndValidity();
    } else {
      this.other_attachments.removeAt(index);
    }
  }
  
  public completeStep() {
    let teserve_transaction = this.form.get('teserve_transaction').value == true? '1': '0';
    if(this.form.valid) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('transaction_request_id', this.form.value.transaction_request_id);
      formData.append('teserve_transaction', teserve_transaction);
      if(this.form.get('attachments').value.length) {
        this.filesFormGroup.controls.forEach((v, k) => {
          formData.append("attachments[" + k + "][file]", v.get('file').value);
          formData.append("attachments[" + k + "][required]", 'Yes');
          formData.append("attachments[" + k + "][attach_title]", v.get('attach_title').value);        
        });
      }
      this.ds.post(`form/${this.form_key}/Contractor_Reservation_Step8/0`, formData).subscribe(data => {
        if(data.status) {
          this.spinner.hide();
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
          setTimeout(() => {
            this.refreshComponent.emit(true);
          }, 2000);
        } else {
          this.spinner.hide();
          this.alert.error(data.error)
        }
      }, (error) => {
        this.spinner.hide();
          this.alert.error(error);
      })
    } else {
      this.alert.error(this.lang.transform('lang_all_fields_required'));
    }
  }
}
