import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import moment from "moment";
import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
declare var $: any;
@Component({
	selector: "app-jop-offer-create",
	templateUrl: "./jop-offer-create.component.html",
	styleUrls: ["./jop-offer-create.component.scss"],
})
export class JopOfferCreateComponent implements OnInit, OnDestroy {
	@Output() contractEvent = new EventEmitter();
	employee_data;
	@Input() form_request_id;
	@Input() form_key;
	@Input() id;
	@Input() branch_id;
	form: FormGroup;
	other_allownces_datas: FormArray;
	other_terms_datas1: FormArray;
	other_terms_datas2: FormArray;
	lang_key = localStorage.getItem("lang_key") || "en";
	submitted = false;
	cperiods: any = [];
	pperiods: any = [];
	vacationdays: any = [];
	workingdays: any = [];
	workinghours: any = [];
	languages: any = [];
	users: any = [];
	markstatus = false;
	reviewstatus = false;
	otherallowances = false;
	otherterms = false;
	showmarkdelete = true;
	showreviewdelete = true;
	probationenddate = "";
	net_amount: any = 0.0;
	vacation_custom_date = "";
	firstFormGroup: FormGroup | any = new FormGroup({});
	secondFormGroup: FormGroup | any = new FormGroup({});
	thirdFormGroup: FormGroup | any = new FormGroup({});
	fourthFormGroup: FormGroup | any = new FormGroup({});
	branches: any = [];
	constructor(
		public ds: DataService,
		private route: ActivatedRoute,
		public datepipe: DatePipe,
		private _sweetAlert: SweetAlertService,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.spinner.show();
		moment.locale("en");

		this.get_required_data("tc/tbranches");
		this.form = new FormGroup({
			signature_a_title: new FormControl("", [Validators.required]),
			signature_a_user_id: new FormControl(null, [Validators.required]),
			signature_b_title: new FormControl(""),
			signature_b_user_id: new FormControl(null),
			needOtherTerms: new FormControl("0", [Validators.required]),
			other_terms1: this.fb.array([]),
			other_terms2: this.fb.array([]),
			penalty_clause_first_party: new FormControl("", [Validators.required]),
			penalty_clause_second_party: new FormControl("", [Validators.required]),
		});

		this.firstFormGroup = new FormGroup({
			typeOfContract: new FormControl("", [Validators.required]),
			selectOne: new FormControl(""),
		});

		this.secondFormGroup = new FormGroup({
			subject: new FormControl(this.lang.transform("lang_create_jop_offer"), [
				Validators.required,
			]),
			transaction_from: new FormControl({ value: "", disabled: false }, [
				Validators.required,
			]),
			transaction_to: new FormControl({ value: "", disabled: true }),
			work_domain: new FormControl("", [Validators.required]),
			work_location: new FormControl("", [Validators.required]),
			work_type: new FormControl("", [Validators.required]),
			employee_number: new FormControl("", [Validators.required]),
		});

		this.thirdFormGroup = new FormGroup({
			joining_date_english: new FormControl(""),
			joining_date_arabic: new FormControl(null),
			probation_period: new FormControl(null),
			contract_start_date_english: new FormControl(""),
			contract_period: new FormControl(null),
			contract_end_date_english: new FormControl({ value: "", disabled: true }),
			notification_period: new FormControl(null, [Validators.required]),
			auto_renew: new FormControl("0", [Validators.required]),
		});

		this.fourthFormGroup = new FormGroup({
			vacation_paid_days: new FormControl(null, [Validators.required]),
			working_days_per_week: new FormControl(null, [Validators.required]),
			working_hours_type: new FormControl("day", [Validators.required]),
			basic_salary: new FormControl("", [Validators.required]),
			home_allowance: new FormControl(""),
			ticket_amount: new FormControl(""),
			working_hours: new FormControl(""), // Need to be added
			needOtherAllowances: new FormControl("0", [Validators.required]),
			other_allowances: this.fb.array([]),
		});

		this.other_allownces_datas = this.fourthFormGroup.get(
			"other_allowances"
		) as FormArray;
		this.other_terms_datas1 = this.form.get("other_terms1") as FormArray;
		this.other_terms_datas2 = this.form.get("other_terms2") as FormArray;
		this.subscriptions.add(
			this.route.params.subscribe((params: Params) => {
				this.id = params["id"];
				this.secondFormGroup.patchValue({
					employee_number: params["id"],
				});
				this.get_view_details();
				this.load_required_datas();
			})
		);
	}
	private subscriptions = new Subscription();
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
	public get_required_data(url) {
		this.branches = [];
		this.subscriptions.add(
			this.ds.getActionByUrl([], url).subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.branches = res.records;
				}
			})
		);
	}
	viewdata = "";
	attachments = "";
	licences = "";
	profile_percentage = "";
	viewstatus = false;
	public get_view_details() {
		this.spinner.show();
		this.subscriptions.add(
			this.ds
				.getActionByUrl([], "human_resources/get_application_data/" + this.id)
				.subscribe(
					(data) => {
						this.spinner.hide();
						this.viewstatus = data.status;
						if (data.status) {
							this.viewdata = data.data;
							this.employee_data = data.data;
							this.attachments = data.attachments;

							this.licences = data.licences;
							if (data.profile_percentage) {
								this.profile_percentage = data.profile_percentage.total;
							}
						} else {
							this.spinner.hide();
							this.router.navigate(["human_resources/applications"]);
							this.ds.dialogf("", data.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.viewstatus = false;
						this.ds.dialogf(
							"",
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}

	public isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44 || charCode == 190 || charCode == 46) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57 || charCode == 101)) {
			return false;
		}
		return true;
	}
	contract_types: any = [];
	contract_terms = [];
	public load_required_datas() {
		let param = new FormData();
		param.append("lang_key", this.lang_key);
		param.append("employee_number", this.id);
		let url = [
			"form/FORM_CT1/ctperiods",
			"form/FORM_CT1/pbperiods",
			"form/FORM_CT1/vacationdays",
			"form/FORM_CT1/weekdays",
			"form/FORM_CT1/workinghours",
			"form/FORM_CT1/appusers",
		];
		this.subscriptions.add(
			this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(
				(res) => {
					this.spinner.hide();
					if (res[0].status) {
						this.cperiods = res[0].records;
					}
					if (res[1].status) {
						this.pperiods = res[1].records;
					}
					if (res[2].status) {
						this.vacationdays = res[2].records;
					}
					if (res[3].status) {
						this.workingdays = res[3].records;
					}
					if (res[4].status) {
						this.workinghours = res[4].records;
					}
					if (res[5].status) {
						this.users = res[5].records;
						let marks = [];
						let reviews = [];
						this.users.forEach((v, k) => {
							if (v.position == "A" && v.value) {
								this.form.get("signature_a_title").setValue(v.title);
								this.form.get("signature_a_user_id").setValue(v.value);
							}
							if (v.position == "B" && v.value) {
								this.form.get("signature_b_title").setValue(v.title);
								this.form.get("signature_b_user_id").setValue(v.value);
							}
							if (v.position == "M" && v.value) {
								marks.push(v.value);
							}
							if (v.position == "R" && v.value) {
								reviews.push(v.value);
							}
						});
						if (this.form.get("signature_b_user_id").value) {
						} else {
							this.form.get("signature_b_title").setValue("");
							this.form.get("signature_b_title").enable();
							this.form.get("signature_b_user_id").setValue(null);
							this.form.get("signature_b_user_id").enable();
						}
					}
				},
				(error) => {
					this.spinner.hide();
				}
			)
		);
		this.spinner.show();
		this.ds.get("human_resources/get_setting_contract_terms", []).subscribe(
			(data) => {
				this.spinner.hide();
				if (data.status) {
					this.contract_terms = data.records;
					this.optionsFP_ar = this.contract_terms.map((ct) => {
						if (ct?.terms_type == "FP") {
							return ct.terms_content_arabic;
						}
					});
					this.optionsFP_ar = this.optionsFP_ar.filter((el) => el != undefined);
					this.optionsSP_ar = this.contract_terms.map((ct) => {
						if (ct?.terms_type == "SP") {
							return ct.terms_content_arabic;
						}
					});
					this.optionsSP_ar = this.optionsSP_ar.filter((el) => el != undefined);
					this.optionsFP = this.contract_terms.map((ct) => {
						if (ct?.terms_type == "FP") {
							return ct.terms_content_english;
						}
					});
					this.optionsFP = this.optionsFP.filter((el) => el != undefined);
					this.optionsSP = this.contract_terms.map((ct) => {
						if (ct?.terms_type == "SP") {
							return ct.terms_content_english;
						}
					});
					this.optionsSP = this.optionsSP.filter((el) => el != undefined);
					if (this.contract_terms.length > 0) {
						this.form.patchValue({
							needOtherTerms: 1,
						});
						this.otherterms = true;
					}

					for (let index = 0; index < this.contract_terms.length; index++) {
						const element = this.contract_terms[index];
						if (element.terms_type == "FP") {
							this.add_new_terms1({
								terms_description_english: element.terms_content_english,
								terms_description_arabic: element.terms_content_arabic,
								terms_type: element.terms_type ? element.terms_type : "FP",
								required_status: element.required_status,
								setting_contract_terms_id:
									element?.setting_contract_terms_id || null,
							});
						} else if (element.terms_type == "SP") {
							this.add_new_terms2({
								terms_description_english: element.terms_content_english,
								terms_description_arabic: element.terms_content_arabic,
								terms_type: element.terms_type ? element.terms_type : "SP",
								required_status: element.required_status,
								setting_contract_terms_id:
									element?.setting_contract_terms_id || null,
							});
						}
					}
				} else {
					this._sweetAlert.errorMessage(data.error);
				}
			},
			(error) => {
				this.spinner.hide();
				this._sweetAlert.errorMessage(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}
	filteredOptionsFP: any;
	filteredOptionsFP_ar: any;
	filteredOptionsSP: any;
	filteredOptionsSP_ar: any;
	optionsFP: string[] = [];
	optionsFP_ar: string[] = [];
	optionsSP: string[] = [];
	optionsSP_ar: string[] = [];
	_filter(value: string, lang = "en", type = "fp"): any {
		const filterValue = value.toLowerCase();
		if (lang == "ar" && type == "fp") {
			this.filteredOptionsFP_ar = this.optionsFP_ar.filter((option) =>
				option.toLowerCase().includes(filterValue)
			);
		} else if (lang == "ar" && type == "sp") {
			this.filteredOptionsSP_ar = this.optionsSP_ar.filter((option) =>
				option.toLowerCase().includes(filterValue)
			);
		} else if (lang == "en" && type == "fp") {
			this.filteredOptionsFP = this.optionsFP.filter((option) =>
				option.toLowerCase().includes(filterValue)
			);
		} else if (lang == "en" && type == "sp") {
			this.filteredOptionsSP = this.optionsSP.filter((option) =>
				option.toLowerCase().includes(filterValue)
			);
		}
	}
	checkForRequired() {
		let required = false;
		for (let index = 0; index < this.contract_terms.length; index++) {
			const element = this.contract_terms[index];
			if (element.required_status == "required") {
				required = true;
				break;
			}
		}
		return required;
	}
	//Other Allowances Start
	get othersgroup() {
		return this.fourthFormGroup.get("other_allowances") as FormArray;
	}
	public others_group() {
		return this.fourthFormGroup.get("other_allowances") as FormArray;
	}
	public add_new_others() {
		if (this.other_allownces_datas.length < 5) {
			this.other_allownces_datas.push(this.other_records());
		}
	}
	public other_records(): FormGroup {
		return this.fb.group({
			other_allowance_description_en: new FormControl("", [
				Validators.required,
			]),
			other_allowance_amount: new FormControl("", [Validators.required]),
			other_allowance_description_ar: new FormControl("", [
				Validators.required,
			]),
		});
	}
	public remove_other_items(index) {
		if (this.other_allownces_datas.length > 1) {
			this.other_allownces_datas.removeAt(index);
		}
	}
	//Other Allowances End
	//Other Terms & Conditions Start
	get termsgroup1() {
		return this.form.get("other_terms1") as FormArray;
	}
	public terms_group1() {
		return this.form.get("other_terms1") as FormArray;
	}
	public add_new_terms1(obj: any = false) {
		this.other_terms_datas1.push(this.terms_records1(obj));
	}
	public terms_records1(obj: any = false): FormGroup {
		return this.fb.group({
			terms_description_english: new FormControl(
				obj.terms_description_english,
				[Validators.required]
			),
			terms_description_arabic: new FormControl(obj.terms_description_arabic, [
				Validators.required,
			]),
			terms_type: new FormControl(obj.terms_type || "FP", [
				Validators.required,
			]),
			required_status: [obj.required_status || "optional"],
			setting_contract_terms_id: [obj?.setting_contract_terms_id || null],
		});
	}
	public remove_terms_items1(index) {
		if (this.other_terms_datas1.length > 1) {
			this.other_terms_datas1.removeAt(index);
		}
	}
	//Other Terms & Conditions Start
	get termsgroup2() {
		return this.form.get("other_terms2") as FormArray;
	}
	public terms_group2() {
		return this.form.get("other_terms2") as FormArray;
	}
	public add_new_terms2(obj: any = false) {
		this.other_terms_datas2.push(this.terms_records2(obj));
	}
	public terms_records2(obj: any = false): FormGroup {
		return this.fb.group({
			terms_description_english: new FormControl(
				obj.terms_description_english,
				[Validators.required]
			),
			terms_description_arabic: new FormControl(obj.terms_description_arabic, [
				Validators.required,
			]),
			terms_type: new FormControl(obj.terms_type || "SP", [
				Validators.required,
			]),
			required_status: [obj.required_status || "optional"],
			setting_contract_terms_id: [obj?.setting_contract_terms_id || null],
		});
	}
	public remove_terms_items2(index) {
		if (this.other_terms_datas2.length > 1) {
			this.other_terms_datas2.removeAt(index);
		}
	}
	public calculate_net_amount() {
		let amount: any = 0;
		let basic_salary: any = parseFloat(
			this.fourthFormGroup.get("basic_salary").value || 0
		);
		let home_allowance: any = parseFloat(
			this.fourthFormGroup.get("home_allowance").value || 0
		);
		this.other_allownces_datas.value.forEach((v, k) => {
			amount += parseFloat(v.other_allowance_amount || 0);
		});
		this.net_amount = (
			parseFloat(basic_salary) +
			parseFloat(home_allowance) +
			parseFloat(amount)
		).toFixed(2);
	}
	//Other Terms & Conditions End
	public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append("search", key.term);
		param.append("lang_key", this.lang_key);
		param.append("user_type_id", "1");
		this.subscriptions.add(
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.users = res.list;
					}
				})
		);
	}
	public enable_field(field) {
		if (field == "mark") {
			this.form.get("needMark").setValue("1");
			this.form.get("marks").setValidators(Validators.required);
			this.form.get("marks").updateValueAndValidity();
			this.markstatus = true;
		} else if (field == "review") {
			this.form.get("needReview").setValue("1");
			this.form.get("reviews").setValidators(Validators.required);
			this.form.get("reviews").updateValueAndValidity();
			this.reviewstatus = true;
		} else if (field == "allowances") {
			this.otherallowances = true;
			this.other_allownces_datas.clear();
			this.fourthFormGroup.get("needOtherAllowances").setValue("1");
			this.add_new_others();
		} else if (field == "terms") {
			this.otherterms = true;
			this.other_terms_datas1.clear();
			this.other_terms_datas2.clear();
			this.form.get("needOtherTerms").setValue("1");
			this.add_new_terms1();
			this.add_new_terms2();
		}
	}
	public disable_field(field) {
		if (field == "mark") {
			this.form.get("needMark").setValue("0");
			this.form.get("marks").setValue(null);
			this.form.get("marks").clearValidators();
			this.form.get("marks").updateValueAndValidity();
			this.markstatus = false;
		} else if (field == "review") {
			this.form.get("needReview").setValue("0");
			this.form.get("reviews").setValue(null);
			this.form.get("reviews").clearValidators();
			this.form.get("reviews").updateValueAndValidity();
			this.reviewstatus = false;
		} else if (field == "allowances") {
			this.fourthFormGroup.get("needOtherAllowances").setValue("0");
			this.other_allownces_datas.clear();
			this.otherallowances = false;
		} else if (field == "terms") {
			this.form.get("needOtherTerms").setValue("0");
			this.other_terms_datas1.clear();
			this.other_terms_datas2.clear();
			this.otherterms = false;
		}
		this.form.updateValueAndValidity();
	}
	public calculate_contract_enddate() {
		this.thirdFormGroup.get("contract_end_date_english").setValue("");
		if (
			this.thirdFormGroup.get("contract_start_date_english").value &&
			this.thirdFormGroup.get("contract_period").value &&
			this.thirdFormGroup.get("contract_period").value != "0"
		) {
			let date = new Date(
				this.thirdFormGroup.get("contract_start_date_english").value
			);
			let finaldate = date.setFullYear(
				date.getFullYear() +
					parseInt(this.thirdFormGroup.get("contract_period").value)
			);
			let Edate = new Date(
				this.datepipe.transform(new Date(finaldate), "yyyy/MM/dd")
			);
			this.thirdFormGroup
				.get("contract_end_date_english")
				.setValue(
					this.datepipe.transform(
						Edate.setDate(date.getDate() - 1),
						"yyyy/MM/dd"
					)
				);
		}
	}
	public probation_enddate() {
		this.probationenddate = "";
		if (
			this.thirdFormGroup.get("joining_date_english").value &&
			this.thirdFormGroup.get("contract_start_date_english").value !=
				undefined &&
			this.thirdFormGroup.get("probation_period").value &&
			this.thirdFormGroup.get("probation_period").value != "0"
		) {
			let date = new Date(
				this.thirdFormGroup.get("joining_date_english").value
			);
			let finaldate = date.setMonth(
				date.getMonth() +
					parseInt(this.thirdFormGroup.get("probation_period").value)
			);
			this.probationenddate = this.datepipe.transform(
				new Date(finaldate),
				"yyyy/MM/dd"
			);
		}
	}
	public vacation_enddate() {
		//this.form.get('vacation_custom_date').setValue('');
		this.vacation_custom_date = "";
		if (
			this.thirdFormGroup.get("contract_start_date_english").value &&
			this.fourthFormGroup.get("vacation_paid_days").value &&
			this.fourthFormGroup.get("vacation_paid_days").value != undefined
		) {
			let date = new Date(
				this.thirdFormGroup.get("contract_start_date_english").value
			);
			let vdays = this.fourthFormGroup
				.get("vacation_paid_days")
				.value.split("-");
			let finaldate1 = new Date(
				date.setFullYear(date.getFullYear() + parseInt(vdays[1]))
			);
			let cperiod = this.thirdFormGroup.get("contract_period").value || 0;
			if (parseInt(cperiod) >= parseInt(vdays[1]) || parseInt(cperiod) == 0) {
				let finaldate = finaldate1.setDate(date.getDate() - parseInt(vdays[0]));
				/* this.vacation_custom_date = this.datepipe.transform(new Date(finaldate), 'yyyy/MM/dd') */
			} else {
				this.ds.dialogf("", this.lang.transform("lang_valida_vacation_days"));
			}
		}
	}
	public preview_form() {
		this.alert.clear();
		this.submitted = true;
		if (this.form.valid) {
			this.submitted = false;
			this.spinner.show();
			this.subscriptions.add(
				this.ds
					.postActionByUrl(this.getformdata(), "form/FORM_CT1/pr/0")
					.subscribe(
						(data) => {
							this.spinner.hide();
							if (data.status) {
								let id = "showPDFPreview";
								if (data.extension != "zip") {
									($("#" + id) as any).modal({
										backdrop: "static",
										keyboard: false,
									});
									($("#" + id) as any).modal("show");
								}
								document.querySelector("iframe").src =
									"data:" + data["content-type"] + ";base64," + data.base64;
							} else {
								this.alert.error(data.error);
							}
						},
						(error) => {
							this.spinner.hide();
							this.alert.error(
								error && error.error && error.error.error
									? error.error.error
									: this.lang.transform("lang_internal_server_error")
							);
						}
					)
			);
		} else {
			this.alert.error(this.lang.transform("lang_fill_required_fields"));
		}
	}

	goBack(stepper: MatStepper) {
		stepper.previous();
	}

	goForward(stepper: MatStepper) {
		stepper.next();
	}

	public submit(stepper) {
		this.alert.clear();
		this.submitted = true;
		if (this.form.valid) {
			this.submitted = false;
		}
		this.spinner.show();
		this.subscriptions.add(
			this.ds
				.postActionByUrl(this.getformdata(), "form/FORM_JF/cr/0")
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.alert.success(res.msg);
							/* this.alert.success(this.lang.transform('lang_success')); */
							this.goForward(stepper);
							this.form.reset();
							this.firstFormGroup.reset();
							this.secondFormGroup.reset();
							this.thirdFormGroup.reset();
							this.fourthFormGroup.reset();

							/* setTimeout(() => {
                  this.router.navigate(['/transactions/'+res.url]);
                }, 1000); */
						} else {
							this.alert.error(res.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}
	private getformdata() {
		let param = this.ds.json2formdata({
			...this.form.value,
			/* ...this.firstFormGroup.value, */
			...this.secondFormGroup.value,
			...this.thirdFormGroup.value,
			...this.fourthFormGroup.value,
		});

		param.set("first_party_user", this.form.value.signature_a_user_id);
		param.set(
			"contract_start_date_english",
			moment(
				this.thirdFormGroup.get("contract_start_date_english").value
			).format("YYYY/MM/DD") || ""
		);
		param.set(
			"joining_date_english",
			moment(this.thirdFormGroup.get("joining_date_english").value).format(
				"YYYY/MM/DD"
			) || ""
		);
		param.append(
			"penalty_clause_first_party",
			this.form.get("penalty_clause_first_party").value || ""
		);
		param.append(
			"penalty_clause_first_party",
			this.form.get("penalty_clause_first_party").value || ""
		);
		param.append("needOtherTerms", this.form.get("needOtherTerms").value || "");
		let terms1 = this.other_terms_datas1?.value?.filter((el) => {
			return el?.required_status != "required";
		});

		let terms2 = terms1.concat(
			this.other_terms_datas2?.value?.filter((el) => {
				return el?.required_status != "required";
			})
		);
		for (let index = 0; index < terms2?.length; index++) {
			const element = terms2[index];
			param.set(
				`other_terms[${index}][terms_description_english]`,
				element?.terms_description_english
			);
			param.set(
				`other_terms[${index}][terms_description_arabic]`,
				element?.terms_description_arabic
			);
			param.set(`other_terms[${index}][terms_type]`, element?.terms_type);
			param.set(
				`other_terms[${index}][required_status]`,
				element?.required_status
			);
		}
		return param;
	}

	selected;
	isLinear = true;
	selectedIndex: number = 0;
	selectionChange(e: any) {
		this.selectedIndex = e.selectedIndex;
	}
}
