<i
	*ngIf="actionMode != 'VIEW'"
	class="fa fa-upload mnabricon"
	aria-hidden="true"
	matTooltip="{{ 'lang_upload_attachments' | language }}"
	(click)="openModalA(UploadAttachments); getdataifany()"
></i>
<ng-template #UploadAttachments>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_attachments" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="closemodel()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="form" (ngSubmit)="submit(); closemodel()">
			<div class="modal-body">
				<div class="row">
					<div class="col-md-12 col-sm-12 col-xs-12 mb-10">
						<div class="quotationitemtable table-responsives customResponsive">
							<table
								border="1"
								class="table CustomTable"
								width="100%"
								formArrayName="attachments"
							>
								<thead>
									<tr>
										<td width="1%" style="padding: 5px; word-break: break-all">
											#
										</td>
										<td
											width="1%"
											style="padding: 5px; word-break: break-all"
											*ngIf="actionMode != 'VIEW'"
										>
											<i
												class="fa fa-plus-square mnabricon"
												aria-hidden="true"
												matTooltip="{{ 'lang_add' | language }}"
												(click)="addfiles()"
											></i>
										</td>
										<td width="48%" style="padding: 5px; word-break: break-all">
											{{ "lang_attach_title" | language }}
										</td>
										<td width="50%" style="padding: 5px; word-break: break-all">
											{{ "lang_file" | language }}
										</td>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="
											let file of filesFormGroup.controls;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
										[formGroupName]="i"
									>
										<td
											class="verticalalignmid"
											style="padding: 5px; word-break: break-all"
										>
											{{ i + 1 }}
										</td>
										<td class="verticalalignmid" *ngIf="actionMode != 'VIEW'">
											<i
												class="fa fa-trash mnabricon red"
												aria-hidden="true"
												matTooltip="{{ 'lang_delete' | language }}"
												(click)="removefiles(i)"
											></i>
										</td>
										<td
											class="verticalalignmid"
											style="padding: 5px; word-break: break-all"
										>
											<input
												type="text"
												class="form-control"
												name="attach_title"
												placeholder="{{ 'lang_attach_title' | language }}"
												formControlName="attach_title"
											/>
											<mat-error
												*ngIf="
													submitted &&
													this.attachments.controls[i].get('attach_title')
														.errors?.required
												"
											>
												{{ "lang_field_required" | language }}</mat-error
											>
										</td>
										<td
											class="verticalalignmid"
											style="
												padding: 5px;
												word-break: break-all;
												position: relative;
											"
										>
											<div class="IDDIV">
												<input
													style="display: block; height: auto"
													name="file"
													type="file"
													required
													onclick="this.value = null"
													(change)="UploadFile($event, i)"
													class="nghide albutton width100"
												/>
											</div>
											<label
												id="uploadText{{ i }}"
												class="albutton mat-accent m-0"
												mat-button
												>{{ filename }}
											</label>
											<mat-error
												*ngIf="
													submitted &&
													this.attachments.controls[i].get('file').errors
														?.required
												"
											>
												{{ "lang_field_required" | language }}</mat-error
											>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="row pt-2 pb-1">
					<div class="col-sm-12">
						<app-alert></app-alert>
					</div>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton" *ngIf="actionMode != 'VIEW'">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="closemodel()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #viewAttachments>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_attachments" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefV.hide()"
			>
				&times;
			</button>
		</div>
		<div class="modal-body">
			<div class="row">
				<div class="col-md-12 col-sm-12 col-xs-12 mb-10">
					<div class="table-responsives customResponsive">
						<table border="1" class="table CustomTable" width="100%">
							<thead>
								<tr>
									<td width="1%" style="padding: 5px; word-break: break-all">
										#
									</td>
									<td style="padding: 5px; word-break: break-all">
										{{ "lang_platform_code_system" | language }}
									</td>
									<td style="padding: 5px; word-break: break-all">
										{{ "lang_platform_title" | language }}
									</td>
									<td style="padding: 5px; word-break: break-all">
										{{ "lang_filename" | language }}
									</td>
									<td style="padding: 5px; word-break: break-all">
										{{ "lang_file" | language }}
									</td>
								</tr>
							</thead>
							<tbody *ngIf="attachmentData.length != '0'" class="AppendList">
								<tr
									*ngFor="
										let list of attachmentData;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
									class="makeback removerow{{ list.template_attachments_id }}"
								>
									<td>{{ i + 1 }}</td>
									<td>{{ list.template_attachment_code_system }}</td>
									<td>{{ list.platformname }}</td>
									<td>{{ list.template_attachments_desciption }}</td>
									<td>
										<i
											class="fa fa-paperclip mnabricon"
											aria-hidden="true"
											matTooltip="{{ 'lang_view' | language }}"
											(click)="
												modalRefV.hide();
												ds.preview_pdf_file(
													list.file_path,
													list?.template_attachment_code_system
												)
											"
										></i>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="attachmentData.length == '0'" class="AppendList">
								<tr class="odd">
									<td colspan="5" align="center" class="nodata">
										{{ nodata }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer pad15">
			<div class="row">
				<div class="col-md-9 col-xs-12 mb-10"></div>
				<div class="col-md-3 col-xs-12 mb-10">
					<button
						type="button"
						class="btn btn-secondary secondarybutton"
						data-dismiss="modal"
						(click)="modalRefV.hide()"
					>
						{{ "lang_close" | language }}
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<div class="modal" id="pdfTPreviewSuperVision">
	<div class="modal-dialog width70">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_tender_drawing" | language }}</h4>
				<button type="button" class="close" data-dismiss="modal">
					&times;
				</button>
			</div>
			<div class="modal-body">
				<div class="pdf-container">
					<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</div>
