<breadcrumb></breadcrumb>
<section class="panel-expansion">
  <mat-accordion class="stylepanels">
    <mat-expansion-panel class="mpanel phrases_list" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_phrases" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <div class="mbrl15">
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <div class="form-group">
                <ng-select
                  [items]="languages"
                  [multiple]="false"
                  bindLabel="phrase_val"
                  bindValue="lang_key"
                  appendTo="body"
                  clearAllText="Clear"
                  placeholder="{{ 'lang_languages' | language }}"
                  [(ngModel)]="language_Key"
                  (change)="spinner.show(); search_phrases()"
                >
                </ng-select>
              </div>
            </div>
            <div class="col-md-3 col-xs-12">
              <div class="form-group">
                <ng-select
                  [items]="modules"
                  [multiple]="false"
                  bindLabel="module_phrase_val"
                  bindValue="module_key"
                  appendTo="body"
                  clearAllText="Clear"
                  placeholder="{{ 'lang_search_modules' | language }}"
                  [(ngModel)]="module_key"
                  (change)="spinner.show(); search_phrases()"
                >
                </ng-select>
              </div>
            </div>
            <div class="col-md-6 col-xs-12">
              <div class="form-group">
                <div class="input-group input-group-sm mb-10">
                  <input
                    type="text"
                    class="form-control almnabrformcontrol search_key"
                    [(ngModel)]="searchKey"
                    (keyup)="search_phrases()"
                    placeholder="{{ 'lang_search' | language }}"
                  />
                  <span class="input-group-btn">
                    <button
                      id="btnSearch"
                      type="button"
                      matTooltip="{{ 'lang_add' | language }}"
                      class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
                      (click)="emptyform(); openModal(addOperation); addlangs()"
                    ></button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive dropdownyes customResponsive">
                <table border="1" class="table CustomTable">
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>
                        <div
                          class="checkbox"
                          matTooltip="{{ 'lang_check_all' | language }}"
                        >
                          <input
                            type="checkbox"
                            class="allItems checkbox"
                            id="allItems"
                            (click)="checkAllItems($event)"
                          />
                          <label for="checkbox"></label>
                        </div>
                      </td>
                      <td>{{ "lang_lang_name" | language }}</td>
                      <td>{{ "lang_module_name" | language }}</td>
                      <td>{{ "lang_phrase_key" | language }}</td>
                      <td>{{ "lang_phrase_name" | language }}</td>
                      <td>{{ "lang_action" | language }}</td>
                    </tr>
                  </thead>
                  <tbody *ngIf="phrasedata.length != '0'" class="AppendList">
                    <tr
                      *ngFor="
                        let list of phrasedata.records;
                        let i = index;
                        let odd = odd;
                        let even = even
                      "
                      [ngClass]="{ odd: odd, even: even }"
                      class="makeback removerow{{ list.phrase_id }}"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>
                        <div
                          class="checkbox"
                          matTooltip="{{ 'lang_check' | language }}"
                        >
                          <input
                            type="checkbox"
                            class="eachItem checkbox"
                            value="{{ list.phrase_id }}"
                            (click)="checkEachItem($event)"
                          />
                          <label for="checkbox"></label>
                        </div>
                      </td>
                      <td>{{ list.lang_name | language }}</td>
                      <td>{{ list.module_phrase_key | language }}</td>
                      <td>{{ list.phrase_key }}</td>
                      <td>{{ list.phrase_val }}</td>
                      <td>
                        <i
                          matTooltip="{{ 'lang_edit' | language }}"
                          (click)="emptyform(list); openModal(phraseOperations)"
                          class="fa fa-pencil-square-o fa-icon s2icon mnabricon"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="phrasedata.length == '0'" class="AppendList">
                    <tr class="odd">
                      <td colspan="7" align="center" class="nodata">
                        {{ phrasenodata }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="phrasedata?.records?.length != '0'"
          class="card-footer padb0"
        >
          <div class="row responsive_pagination">
            <div class="col-lg-1 col-sm-1 mb-10">
              <i
                class="deleteIconDiv none material-icons red deleteIcon"
                (click)="deleteBulkData()"
                aria-hidden="true"
                matTooltip="{{ 'lang_delete' | language }}"
                >delete_forever</i
              >
            </div>
            <div
              class="col-lg-3 col-sm-3 mb-10"
              style="height: fit-content; margin: auto 0"
            >
              {{ "lang_total" | language }} :
              {{ phrasedata?.page?.total_records }}
            </div>
            <div
              class="col-lg-5 col-sm-5 mb-10"
              style="display: flex; justify-content: center"
            >
              <!-- <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="10" checked (click)="spinner.show();loadpagesize(10)"> 10 </mat-radio-button>
                            <mat-radio-button value="20" (click)="spinner.show();loadpagesize(20)"> 20 </mat-radio-button>
                            <mat-radio-button value="50" (click)="spinner.show();loadpagesize(50)"> 50 </mat-radio-button>
                            <mat-radio-button value="100" (click)="spinner.show();loadpagesize(100)"> 100 </mat-radio-button>
                            <mat-radio-button value="500" (click)="spinner.show();loadpagesize(500)"> 500 </mat-radio-button>
                        </mat-radio-group> -->
              <ngb-pagination
                class="m-auto"
                [collectionSize]="phrasedata?.page?.total_records"
                [rotate]="true"
                [ellipses]="false"
                [maxSize]="3"
                [boundaryLinks]="true"
                [(page)]="ppage"
                [pageSize]="psize"
                (pageChange)="spinner.show(); loadpage($event)"
              ></ngb-pagination>
            </div>
            <div
              class="col-lg-3 col-sm-3"
              style="display: flex; justify-content: end; margin: auto"
            >
              <!-- {{'lang_goto' | language }} :
                        <select class="customselect" (change)="spinner.show();loadpage($event.target.value)">
                            <option *ngFor="let ks of ds.getrange(phrasedata.page.total_pages)" value="{{ks}}">{{ks}}
                            </option>
                        </select> -->

              <mat-select
                [(ngModel)]="psize"
                (selectionChange)="spinner.show(); loadpagesize($event.value)"
              >
                <!-- <mat-option value="1">1  </mat-option> -->
                <mat-option class="text-center" value="10"
                  >10 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="20"
                  >20 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="50"
                  >50 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="100"
                  >100 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option class="text-center" value="500"
                  >500 {{ "lang_per_page" | language }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
<ng-template #phraseOperations>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_phrases" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <input type="hidden" formControlName="phrase_id" />
      <div class="modal-body">
        <div class="form-group" *ngIf="!form.get('phrase_id').value">
          <label
            >{{ "lang_languages" | language }}:<span class="red">*</span></label
          >
          <select
            type="text"
            class="form-control almnabrformcontrol"
            formControlName="lang_key"
          >
            <option value="">{{ "lang_choose_options" | language }}</option>
            <option *ngFor="let lang of languages" value="{{ lang.lang_key }}">
              {{ lang.phrase_val }}
            </option>
          </select>
          <mat-error
            *ngIf="
              form.controls['lang_key'].touched &&
              form.controls['lang_key'].errors?.required
            "
            >{{ "lang_field_required" | language }}</mat-error
          >
        </div>
        <div class="form-group">
          <label
            >{{ "lang_module_name" | language }}:<span class="red"
              >*</span
            ></label
          >
          <select
            type="text"
            class="form-control almnabrformcontrol"
            formControlName="module_key"
          >
            <option value="">{{ "lang_choose_options" | language }}</option>
            <option
              *ngFor="let module of modules"
              value="{{ module.module_key }}"
            >
              {{ module.module_phrase_key | language }}
            </option>
          </select>
          <mat-error
            *ngIf="
              form.controls['module_key'].touched &&
              form.controls['module_key'].errors?.required
            "
            >{{ "lang_field_required" | language }}</mat-error
          >
        </div>
        <div class="form-group">
          <label for="lang_key"
            >{{ "lang_phrase_key" | language }}:<span class="red"
              >*</span
            ></label
          >
          <input
            type="text"
            required
            class="form-control almnabrformcontrol"
            id="phrase_key"
            formControlName="phrase_key"
            placeholder="{{ 'lang_phrase_key' | language }}"
          />
          <mat-error
            *ngIf="
              form.controls['phrase_key'].touched &&
              form.controls['phrase_key'].errors?.required
            "
            >{{ "lang_field_required" | language }}</mat-error
          >
        </div>
        <div class="form-group">
          <label for="lang_name"
            >{{ "lang_phrase_name" | language }}:<span class="red"
              >*</span
            ></label
          >
          <input
            type="text"
            class="form-control almnabrformcontrol"
            formControlName="phrase_val"
            placeholder="{{ 'lang_phrase_name' | language }}"
          />
          <mat-error
            *ngIf="
              form.controls['phrase_val'].touched &&
              form.controls['phrase_val'].errors?.required
            "
            >{{ "lang_field_required" | language }}</mat-error
          >
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              data-dismiss="modal"
              (click)="modalRef.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #addOperation>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_phrases" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide(); this.other_attachments.clear()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="form" #form2 (ngSubmit)="submitArray()" class="p-3">
      <div class="row">
        <div class="col-12">
          <section class="center-content">
            <div class="row pt-4">
              <div class="col-12 TableParts">
                <div
                  class="table-responsive customResponsive quotationitemtable"
                >
                  <table
                    border="1"
                    class="table CustomTable"
                    width="100%"
                    formArrayName="attachments"
                  >
                    <thead>
                      <tr>
                        <td width="2%" style="padding: 5px">#</td>
                        <td width="4%" style="padding: 5px">
                          <mat-icon
                            (click)="addlangs()"
                            class="mnabricon"
                            matTooltip="{{ 'lang_add' | language }}"
                            >add_box</mat-icon
                          >
                        </td>
                        <td width="25%" style="padding: 5px" class="fs-md-3">
                          {{ "lang_languages" | language }}:<span class="red"
                            >*</span
                          >
                        </td>
                        <td width="20%" style="padding: 5px">
                          {{ "lang_module_name" | language }}:<span class="red"
                            >*</span
                          >
                        </td>
                        <td width="20%" style="padding: 5px">
                          {{ "lang_phrase_key" | language }}:<span class="red"
                            >*</span
                          >
                        </td>
                        <td width="20%" style="padding: 5px">
                          {{ "lang_phrase_name" | language }}:<span class="red"
                            >*</span
                          >
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let lang of langsFormGroup.controls;
                          let i = index;
                          let odd = odd;
                          let even = even
                        "
                        [formGroupName]="i"
                      >
                        <td
                          class="verticalalignmid"
                          style="padding: 5px"
                          width="2%"
                        >
                          {{ i + 1 }}
                        </td>
                        <td class="verticalalignmid" width="4%">
                          <mat-icon
                            *ngIf="
                              !other_attachments.controls[i].get('delete_icon')
                                .value
                            "
                            (click)="removelangs(i)"
                            class="mnabricon red"
                            matTooltip="{{ 'lang_delete' | language }}"
                            >delete_forever</mat-icon
                          >
                        </td>
                        <td
                          style="padding: 5px; position: relative"
                          width="20%"
                        >
                          <div
                            class="form-group"
                            *ngIf="!form.get('phrase_val').value"
                          >
                            <select
                              type="text"
                              class="form-control almnabrformcontrol"
                              formControlName="lang_key"
                            >
                              <option value="">
                                {{ "lang_choose_options" | language }}
                              </option>
                              <option
                                *ngFor="let lang of languages"
                                value="{{ lang.lang_key }}"
                              >
                                {{ lang.phrase_val }}
                              </option>
                            </select>
                          </div>
                        </td>
                        <td
                          style="padding: 5px; position: relative"
                          width="20%"
                        >
                          <div class="form-group">
                            <select
                              type="text"
                              class="form-control almnabrformcontrol"
                              formControlName="module_key"
                            >
                              <option value="">
                                {{ "lang_choose_options" | language }}
                              </option>
                              <option
                                *ngFor="let module of modules"
                                value="{{ module.module_key }}"
                              >
                                {{ module.module_phrase_key | language }}
                              </option>
                            </select>
                          </div>
                        </td>
                        <td
                          style="padding: 5px; position: relative"
                          width="20%"
                        >
                          <div class="form-group">
                            <input
                              type="text"
                              required
                              class="form-control almnabrformcontrol"
                              id="phrase_key"
                              formControlName="phrase_key"
                              placeholder="{{ 'lang_phrase_key' | language }}"
                            />
                          </div>
                        </td>
                        <td
                          class="verticalalignmid"
                          style="padding: 5px; position: relative"
                          width="20%"
                        >
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control almnabrformcontrol"
                              formControlName="phrase_val"
                              placeholder="{{ 'lang_phrase_name' | language }}"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              data-dismiss="modal"
              (click)="modalRef.hide(); this.other_attachments.clear()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
