import { NUM_CENTER } from "@angular/cdk/keycodes";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-add-update-component",
	templateUrl: "./add-update-component.component.html",
	styleUrls: ["./add-update-component.component.scss"],
})
export class AddUpdateComponentComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	component_form: FormGroup;
	edit_data;
	edit_mode = false;
	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<AddUpdateComponentComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private lang: LanguagePipe,
		public ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService
	) {
		this.edit_data = data?.component_data;
		this.build_form();
		if (this.edit_data) {
			this.edit_mode = true;
			this.setEditData();
		} else {
			this.edit_mode = false;
		}
	}

	ngOnInit(): void {}

	build_form() {
		this.component_form = this.fb.group({
			component_building_name_english: ["", Validators.required],
			component_building_name_arabic: ["", Validators.required],
			component_building_quantity: [null, Validators.required],
			component_level_quantity: [null, Validators.required],
			component_estimated_space: [null, Validators.required],
		});
	}

	setEditData() {
		this.component_form
			.get("component_building_name_english")
			.setValue(this.edit_data?.component_building_name_english);
		this.component_form
			.get("component_building_name_arabic")
			.setValue(this.edit_data?.component_building_name_arabic);
		this.component_form
			.get("component_building_quantity")
			.setValue(this.edit_data?.component_building_quantity);
		this.component_form
			.get("component_level_quantity")
			.setValue(this.edit_data?.component_level_quantity);
		this.component_form
			.get("component_estimated_space")
			.setValue(this.edit_data?.component_estimated_space);
	}

	submit() {
		this.spinner.show();
		let formData = new FormData();
		formData.append(
			"components[1][component_building_name_english]",
			this.component_form.get("component_building_name_english").value
		);
		formData.append(
			"components[1][component_building_name_arabic]",
			this.component_form.get("component_building_name_arabic").value
		);
		formData.append(
			"components[1][component_building_quantity]",
			this.component_form.get("component_building_quantity").value
		);
		formData.append(
			"components[1][component_level_quantity]",
			this.component_form.get("component_level_quantity").value
		);
		formData.append(
			"components[1][component_estimated_space]",
			Number(
				this.component_form.get("component_estimated_space").value
			)?.toFixed(0)
		);
		let end_point = "project_design/add_component_project_contract";
		if (this.edit_mode) {
			end_point = "project_design/update_component_project_contract";
			formData.append(
				"project_component_id",
				this.edit_data?.project_component_id
			);
		} else {
			formData.append("contract_id", this.data.contract_id);
		}
		this.ds.post(end_point, formData).subscribe(
			(res: any) => {
				this.spinner.hide();
				if (res?.status) {
					this.dialogRef.close(true);
					this.sweetAlert.successToast(res?.message, 2000);
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
}
