<breadcrumb></breadcrumb>
<section class="panel-expansion" [@fadeIn]>
	<mat-accordion class="stylepanel">
		<mat-expansion-panel class="mpanel" [expanded]="true">
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_my_transactions" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body tlist">
				<div class="mbrl15 fromtransactions">
					<div class="row">
						<div class="col-lg-12 col-sm-12 mb-10">
							<input
								type="text"
								class="form-control almnabrformcontrol"
								[(ngModel)]="searchKey"
								(keyup)="search_transactions()"
								placeholder="{{ 'lang_search' | language }}"
							/>
						</div>
					</div>
					<div class="row">
						<div
							class="col-md-3 mb-10 col-xs-12"
							[ngClass]="{ 'col-md-3': admin, 'col-md-4': !admin }"
						>
							<ng-select
								[items]="formlist"
								[multiple]="false"
								appendTo="body"
								bindLabel="label"
								bindValue="value"
								clearAllText="Clear"
								placeholder="{{ 'lang_search_form' | language }}"
								[(ngModel)]="searchByForm"
								(change)="search_transactions()"
							>
							</ng-select>
						</div>
						<div
							class="col-md-3 mb-10 col-xs-12"
							*ngIf="admin"
							[ngClass]="{ 'col-md-3': admin, 'col-md-4': !admin }"
						>
							<select
								class="form-control almnabrformcontrol"
								(change)="search_transactions()"
								[(ngModel)]="filteradmin"
							>
								<option value="0">{{ "lang_users" | language }}</option>
								<option value="1">{{ "lang_admin" | language }}</option>
							</select>
						</div>
						<div
							class="col-md-3 mb-10 col-xs-12"
							[ngClass]="{ 'col-md-3': admin, 'col-md-4': !admin }"
						>
							<ng-select
								[items]="modules"
								[multiple]="true"
								bindLabel="label"
								appendTo="body"
								bindValue="value"
								clearAllText="Clear"
								placeholder="{{ 'lang_search_modules' | language }}"
								[(ngModel)]="searchByModule"
								(change)="search_transactions()"
							>
							</ng-select>
						</div>
						<div
							class="col-md-3 mb-10 col-xs-12"
							[ngClass]="{ 'col-md-3': admin, 'col-md-4': !admin }"
						>
							<ng-select
								[items]="statusList"
								[multiple]="false"
								bindLabel="label"
								appendTo="body"
								bindValue="value"
								[clearable]="false"
								placeholder="{{ 'lang_status' | language }}"
								[(ngModel)]="searchByStatus"
								(change)="search_transactions()"
							>
							</ng-select>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<!-- <div class="table-responsive customResponsive">
								<table border="1" class="table CustomTable">
									<thead>
										<tr>
											<td>#</td>
											<td>{{ "lang_request_number" | language }}</td>
											<td>{{ "lang_description" | language }}</td>
											<td>{{ "lang_from" | language }}</td>
											<td>{{ "lang_to" | language }}</td>
											<td>{{ "lang_type" | language }}</td>
											<td>{{ "lang_barcode" | language }}</td>
											<td>{{ "lang_module_name" | language }}</td>
											<td>{{ "lang_forms" | language }}</td>
											<td>{{ "lang_writer" | language }}</td>
											<td>{{ "lang_submitter" | language }}</td>
											<td>{{ "lang_last_update" | language }}</td>
											<td>{{ "lang_status" | language }}</td>
											<td>{{ "lang_total_signature_counts" | language }}</td>
											<td>{{ "lang_total_marks_counts" | language }}</td>
											<td>{{ "lang_total_reviews_counts" | language }}</td>
											<td>{{ "lang_action" | language }}</td>
										</tr>
									</thead>
									<tbody *ngIf="listData.length != '0'" class="AppendList">
										<tr
											*ngFor="
												let list of listData.list.records;
												let i = index;
												let odd = odd;
												let even = even
											"
											[ngClass]="{ odd: odd, even: even }"
											class="{{ list.row_color }}{{ list.row_color_flash }}"
										>
											<td>{{ i + 1 }}</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">{{
													list.transaction_request_id
												}}</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.transaction_request_description }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.transaction_from_name }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.transaction_to_name }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.transactions_type_name }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.barcode }}
												</a>
											</td>
											<td *ngIf="list.module_name">
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.module_name }}
												</a>
											</td>
											<td *ngIf="!list.module_name">
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.module_key }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.transactions_name }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.transaction_request_user_name_writer }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.transactions_submitter_user_name }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.transactions_date_last_update }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.transaction_request_status }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.total_signature_counts }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.total_marks_counts }}
												</a>
											</td>
											<td>
												<a routerLink="/transactions/{{ list.url }}">
													{{ list.total_reviews_counts }}
												</a>
											</td>
											<td>
												<mat-icon
													routerLink="/transactions/{{ list.url }}"
													matTooltip="{{ 'lang_view' | language }}"
													class="mnabricon"
													>remove_red_eye</mat-icon
												>
												<mat-icon
													*ngIf="
														(admin ||
															list?.transaction_request_user_id_writer ==
																userId) &&
														list?.transaction_request_last_step != 'completed'    
													"
													matTooltip="{{ 'lang_delete' | language }}"
													class="mnabricon red"
													(click)="deleteTransaction(list)"
													>delete</mat-icon
												>
												<mat-icon
													class="mnabricon red"
													*ngIf="
													admin &&
													list?.transaction_request_last_step ==
														'completed' && list?.transaction_key == 'FORM_HRV1'
													"
													(click)="cancelTransaction($event, list)"
													matTooltip="{{ 'lang_cancel' | language }}"
												>
													delete
												</mat-icon>
												<span
													*ngIf="
													(list?.transaction_key == 'FORM_C1' ||
													list?.transaction_key == 'FORM_C2')
													"
													(click)="
													previewOutgoingIncomingPDF(
														list?.transaction_request_id,
														list?.transaction_key
													)
													"
													matTooltip="{{ 'lang_preview' | language }}"
													class="mnabricon"
												>
													<i class="fa fa-file-pdf-o f-18 mx-1 cursor-pointer"></i>
												</span>
												<span
													*ngIf="
													(list?.transaction_key == 'FORM_C1' ||
													list?.transaction_key == 'FORM_C2')
													"
													(click)="
													emptyApproveOutgoinForm(
														list?.transaction_request_id,
														list?.transaction_request_description,
														list?.transaction_key
													)
													"  
													matTooltip="{{ 'lang_approve' | language }}"
													class="mnabricon"
												>
													<i class="fa fa-check-square-o f-18 mx-1 cursor-pointer"></i>
												</span>
												<span *ngIf="
												list.step_status != 'completed'
												"
												(click)="
												onShowNotes(list)
												"
												class="mnabricon"
												matTooltip="{{ 'lang_notes' | language }}">
													<i class="fa fa-sticky-note mx-2"></i>
												</span>
											</td>
										</tr>
									</tbody>
									<tbody *ngIf="loading">
										<tr>
											<td colspan="14" class="nodata" align="center">
												<ngx-skeleton-loader
													count="5"
													[theme]="{ 'border-radius': '3px', height: '50px' }"
												></ngx-skeleton-loader>
											</td>
										</tr>
									</tbody>
									<tbody
										*ngIf="listData.length == '0' && !loading"
										class="AppendList"
									>
										<tr class="odd">
											<td colspan="14" class="nodata" align="center">
												{{ nodata }}
											</td>
										</tr>
									</tbody>
								</table>
							</div> -->

							<div class="col-12">
								<div class="table table-responsive">
									<p-table [value]="listData?.list?.records"  dataKey="name" [tableStyle]="{ 'min-width': '60rem' }">
										<ng-template pTemplate="header">
											<tr>
												<th class="font-small" style="width: 7%;">#</th>
												<th class="font-small">{{ 'lang_transaction_id' | language }}</th>
												<th class="font-small">{{ 'lang_description' | language }}</th>
												<th class="font-small">{{ 'lang_to' | language }}</th>
												<th class="font-small">{{ "lang_total_signature_counts" | language }}</th>
												<th class="font-small">{{ "lang_total_marks_counts" | language }}</th>
												<th class="font-small">{{ "lang_total_reviews_counts" | language }}</th>
												<th class="font-small">{{ 'lang_actions' | language }}</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-rowIndex="rowIndex" let-item let-expanded="expanded">
											<tr  (click)="item.expanded = !item?.expanded"
											class="{{ item.row_color }}{{ item.row_color_flash }} cursor-pointer">
												<td class="font-small">
													<a
													routerLink="/transactions/{{ item.url }}"
														(click)="stopPropagation($event)"
														>{{ rowIndex + 1 }}</a
													>
												</td>
												<td class="font-small">
													<a
													routerLink="/transactions/{{ item.url }}"
														(click)="stopPropagation($event)"
														>{{ item?.transaction_request_id }}</a
													>
												</td>
												<td class="font-small">{{ item?.transaction_request_description }}</td>
												<td class="font-small">{{ item?.transaction_to_name }}</td>
												<td class="font-small">
													{{ item.total_signature_counts }}
												</td>
												<td class="font-small">
													{{ item.total_marks_counts }}
												</td>
												<td class="font-small">
													{{ item.total_reviews_counts }}
												</td>
												<td>
													<mat-icon
														routerLink="/transactions/{{ item.url }}"
														matTooltip="{{ 'lang_view' | language }}"
														class="mnabricon"
														>remove_red_eye</mat-icon
													>
													<mat-icon
														*ngIf="
															(admin ||
															item?.transaction_request_user_id_writer ==
																	userId) &&
																	item?.transaction_request_last_step != 'completed'    
														"
														matTooltip="{{ 'lang_delete' | language }}"
														class="mnabricon red"
														(click)="deleteTransaction(item)"
														>delete</mat-icon
													>
													<mat-icon
														class="mnabricon red"
														*ngIf="
														admin &&
														item?.transaction_request_last_step ==
															'completed' && item?.transaction_key == 'FORM_HRV1'
														"
														(click)="cancelTransaction($event, item)"
														matTooltip="{{ 'lang_cancel' | language }}"
													>
														delete
													</mat-icon>
													<span
														*ngIf="
														(item?.transaction_key == 'FORM_C1' ||
														item?.transaction_key == 'FORM_C2')
														"
														(click)="
														previewOutgoingIncomingPDF(
															item?.transaction_request_id,
															item?.transaction_key
														)
														"
														matTooltip="{{ 'lang_preview' | language }}"
														class="mnabricon"
													>
														<i class="fa fa-file-pdf-o f-18 mx-1 cursor-pointer"></i>
													</span>
													<span
														*ngIf="
														(item?.transaction_key == 'FORM_C1' ||
														item?.transaction_key == 'FORM_C2')
														"
														(click)="
														emptyApproveOutgoinForm(
															item?.transaction_request_id,
															item?.transaction_request_description,
															item?.transaction_key
														)
														"  
														matTooltip="{{ 'lang_approve' | language }}"
														class="mnabricon"
													>
														<i class="fa fa-check-square-o f-18 mx-1 cursor-pointer"></i>
													</span>
													<span *ngIf="
													item.step_status != 'completed'
													"
													(click)="
													onShowNotes(item)
													"
													class="mnabricon"
													matTooltip="{{ 'lang_notes' | language }}">
														<i class="fa fa-sticky-note mx-2"></i>
													</span>
												</td>
											</tr>
											<tr *ngIf="item?.expanded">
												<td colspan="8" class="p-0 py-1">
													<div class="sub-data-container">
														<div class="row">
															<div class="col-12 col-sm-6 sub-title" *ngIf="item?.group1_name">
																<span>{{"lang_group_one" | language}}: </span>
																<span>{{item?.group1_name}}</span>
															</div>
															<div class="col-12 col-sm-6 sub-title">
																<span>{{"lang_date" | language}}: </span>
																<span>{{item?.startdatetime}} - {{item?.transactions_date_last_update}}</span>
															</div>
															<div class="col-12 col-sm-6 sub-title" *ngIf="item?.group2_name">
																<span>{{"lang_group_two" | language}}: </span>
																<span>{{item?.group2_name}}</span>
															</div>
															<div class="col-12 col-sm-6 sub-title">
																<span>{{"lang_last_step_opened" | language}}: </span>
																<span>{{item?.transaction_request_last_step  | language}}</span>
															</div>
															<div class="col-12 col-sm-6 sub-title" *ngIf="item?.barcode">
																<span>{{"lang_barcode" | language}}: </span>
																<span>{{item?.barcode}}</span>
															</div>
															<div class="col-12 col-sm-6 sub-title" *ngIf="item?.evaluation_result">
																<span>{{"lang_evaluation_result" | language}}: </span>
																<span>{{item?.evaluation_result}}</span>
															</div>
															<div class="col-12 col-sm-6 sub-title">
																<span>{{"lang_writer" | language}}: </span>
																<span>{{item?.transaction_request_user_name_writer}}</span>
															</div>
															<div class="col-12 col-sm-6 sub-title">
																<span>{{"lang_forms" | language}}: </span>
																<span>{{item?.transactions_name}}</span>
															</div>
															<div class="col-12 col-sm-6 sub-title">
																<span>{{"lang_module_name" | language}}: </span>
																<span>{{item?.module_name || item?.module_key}}</span>
															</div>
														</div>
													</div>
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="footer">
											<tr *ngIf="listData?.list?.records?.length <= 0">
												<td colspan="8">{{"lang_no_data" | language}}</td>
											</tr>
										</ng-template>
									</p-table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					*ngIf="listData?.list?.records.length > 0"
					class="card-footer padb0"
				>
					<div class="row responsive_pagination">
						<div
							class="col-lg-3 col-sm-3 mb-10"
							style="height: fit-content; margin: auto 0"
						>
							{{ "lang_total" | language }} :
							{{ listData?.page?.total_records }}
						</div>
						<div
							class="col-lg-6 col-sm-6 mb-10"
							style="display: flex; justify-content: center"
						>
							<ngb-pagination
								class="m-auto"
								[collectionSize]="listData?.page?.total_records"
								[rotate]="true"
								[ellipses]="false"
								[maxSize]="3"
								[boundaryLinks]="true"
								[(page)]="+pageno"
								[pageSize]="+pagesize"
								(pageChange)="loadPageFrm($event)"
							></ngb-pagination>
						</div>
						<div
							class="col-lg-3 col-sm-3"
							style="display: flex; justify-content: end; margin: auto"
						>
							<!-- {{ "lang_goto" | language }} : -->

							<!-- 	<mat-form-field appearance="fill" style="padding-bottom: 0 !important;">
								<mat-label>{{ "lang_page_size" | language }}</mat-label>
							 

								

							  </mat-form-field> -->
							<mat-select
								style="max-width: 150px"
								[(ngModel)]="pagesize"
								(selectionChange)="loadFrmPagesize($event.value)"
							>
								<!-- <mat-option value="1">1  </mat-option> -->
								<mat-option class="text-center" value="10"
									>10 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="20"
									>20 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="50"
									>50 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="100"
									>100 {{ "lang_per_page" | language }}</mat-option
								>
								<mat-option class="text-center" value="500"
									>500 {{ "lang_per_page" | language }}</mat-option
								>
							</mat-select>

							<!-- <select
								class="customselect"
								(change)="loadPageFrm($event.target.value)"
							>
								<option
									*ngFor="let ks of ds.getrange(listData.page.total_pages)"
									[selected]="pageno == ks ? true : false"
									value="{{ ks }}"
								>
									{{ ks }}
								</option>
							</select> -->
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</section>



  
  <ng-template #verifyPasswordOutgoing>
	<mat-dialog-content>
	  <div id="verifyPasswordOutgoing">
		<form
		  [formGroup]="approveOutgoinForm"
		  class="approve"
		  (ngSubmit)="approveOutgoingForm()"
		>
		  <div class="modal-header">
			<h4 class="modal-title mb-2">{{ 'lang_approve' | language }}</h4>
			<span (click)="closeVerifyPasswordOutgoing()">
			  <i class="fa fa-times"></i>
			</span>
		  </div>
		  <div class="modal-body">
			<p>
			  # {{ selected_transaction_request_id }}
			  {{ selected_transaction_request_description }}
			</p>
			<div class="form-group">
			  <label for="password">{{ 'lang_password' | language }}: </label>
			  <input
				type="password"
				required
				class="form-control almnabrformcontrol"
				formControlName="user_pass"
			  />
			  <input
				type="hidden"
				required
				class="form-control almnabrformcontrol"
				formControlName="transaction_request_id"
			  />
			</div>
			<div class="row mt-3">
			  <div class="col-md-4 col-xs-12">
				<button
				  type="button"
				  mat-raised-button
				  class="w-100"
				  (click)="closeVerifyPasswordOutgoing()"
				>
				  {{ 'lang_close' | language }}
				</button>
			  </div>
			  <div class="col-12 col-sm-4"></div>
			  <div class="col-md-4 col-xs-12">
				<button
				  type="submit"
				  color="primary"
				  mat-raised-button
				  class="w-100"
				>
				  {{ 'lang_approve' | language }}
				</button>
			  </div>
			</div>
		  </div>
		</form>
	  </div>
	</mat-dialog-content>
  </ng-template>
  