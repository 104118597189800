<div class="card appcontracts">
  <div class="card-header">
    {{ "lang_human_resources_contract_details" | language }}
  </div>
  <div class="card-body p-0">
    <div class="mbrl15 martb0">
      <div class="row">
        <div class="input-group input-group-sm mb-10 col-12">
          <input
            type="text"
            class="form-control almnabrformcontrol"
            (keyup)="search_employee_contracts()"
            [(ngModel)]="csearchKey"
            placeholder="{{ 'lang_search' | language }}"
          />
          <span class="input-group-btn" *ngIf="btn_auth">
            <!--   <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" id="btnSearch" type="button" (click)="showContract.emit('CTRCR');" matTooltip="{{'lang_new_contract' | language }}"></button> -->
            <button
              class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
              id="btnSearch"
              type="button"
              (click)="showContract.emit({ event: 'CTRCR2', status: 'new' })"
              matTooltip="{{ 'lang_new_contract' | language }}"
            ></button>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive dropdownyes customResponsive col-12">
          <table
            border="1"
            class="table CustomTable"
            *ngIf="wholedata.length != 0"
          >
            <thead>
              <tr>
                <td>#</td>
                <td *ngIf="requestfor !== 'profile'">
                  <div
                    class="checkbox"
                    matTooltip="{{ 'lang_check_all' | language }}"
                  >
                    <input
                      type="checkbox"
                      class="allItemsContr checkbox"
                      id="allItemsContr"
                      (click)="checkAllContr($event)"
                    />
                    <label for="checkbox"></label>
                  </div>
                </td>
                <td>{{ "lang_subject" | language }}</td>
                <td>{{ "lang_contract_date" | language }}</td>
                <td>{{ "lang_work_domain" | language }}</td>
                <td>{{ "lang_work_location" | language }}</td>
                <td>{{ "lang_approval_date" | language }}</td>
                <td>{{ "lang_status" | language }}</td>
                <td>{{ "lang_writer" | language }}</td>
                <td>{{ "lang_action" | language }}</td>
              </tr>
            </thead>
            <tbody class="AppendList">
              <tr
                *ngFor="
                  let con of wholedata.records;
                  let i = index;
                  let odd = odd;
                  let even = even
                "
                [ngClass]="{ odd: odd, even: even }"
                class="makeback removerow{{ con.contract_id }}"
              >
                <td>{{ i + 1 }}</td>
                <td *ngIf="requestfor !== 'profile'">
                  <div
                    class="checkbox"
                    matTooltip="{{ 'lang_check' | language }}"
                  >
                    <input
                      type="checkbox"
                      class="eachItemContr checkbox"
                      value="{{ con.contract_id }}"
                      (click)="checkEachItemContr($event)"
                    />
                    <label for="checkbox"></label>
                  </div>
                </td>
                <td>{{ con.subject }}</td>
                <td>
                  {{ con.contract_start_date_english }} -
                  {{ con.contract_start_date_arabic }}
                </td>
                <td>{{ con.work_domain }}</td>
                <td>{{ con.work_location }}</td>
                <td>
                  {{ con.contract_approved_date_english }} -
                  {{ con.contract_approved_date_arabic }}
                </td>
                <td>
                  <i
                    class="fa fa-circle fa-icon green"
                    *ngIf="con.contract_status == '1'"
                    matTooltip="{{ 'lang_active' | language }}"
                  ></i>
                  <i
                    class="fa fa-circle fa-icon blue"
                    *ngIf="con.contract_status == '2'"
                    matTooltip="{{ 'lang_completed' | language }}"
                  ></i>
                  <i
                    class="fa fa-circle fa-icon red"
                    *ngIf="con.contract_status == '3'"
                    matTooltip="{{ 'lang_rejected' | language }}"
                  ></i>
                </td>
                <td>{{ con.writer }}</td>
                <td>
                  <i
                    matTooltip="{{ 'lang_action' | language }}"
                    class="fa fa-cog fa-icon mnabricon s2icon"
                    [matMenuTriggerFor]="matConMenu"
                  ></i>
                  <i
                    *ngIf="con?.contract_type != 'edit'"
                    matTooltip="{{ 'lang_view' | language }}"
                    class="fa fa-eye fa-icon mnabricon s2icon"
                    (click)="spinner.show(); loadContract(con, viewContracts)"
                  ></i>
                  <mat-menu
                    #matConMenu="matMenu"
                    [xPosition]="lang_key == 'ar' ? 'after' : 'before'"
                  >
                    <button
                      mat-menu-item
                      class="d-flex justify-content-between align-items-center"
                      [matMenuTriggerFor]="editMenu"
                      *ngIf="con.contract_status == '1'"
                    >
                      <span>{{ "lang_edit" | language }}</span>
                      <i
                        class="fa fa-pencil-square-o fa-icon mnabricon s2icon"
                      ></i>
                    </button>
                    <button
                      mat-menu-item
                      class="d-flex justify-content-between align-items-center"
                      [matMenuTriggerFor]="renewMenu"
                    >
                      <span>{{ "lang_renew" | language }}</span>
                      <i class="fa fa-retweet fa-icon mnabricon s2icon"></i>
                    </button>
                    <button
                      mat-menu-item
                      class="d-flex justify-content-between align-items-center"
                      (click)="showError()"
                      *ngIf="con.contract_status == '1' && !employee_auth"
                    >
                      <span>{{ "lang_extend_period" | language }}</span>
                      <i class="fa fa-cog fa-icon mnabricon s2icon"></i>
                    </button>
                    <button
                      mat-menu-item
                      class="d-flex justify-content-between align-items-center"
                      (click)="
                        showContract.emit({ event: 'EPP', contract: con })
                      "
                      *ngIf="con.contract_status == '1' && employee_auth"
                    >
                      <span>{{ "lang_extend_period" | language }}</span>
                      <i class="fa fa-cog fa-icon mnabricon s2icon"></i>
                    </button>
                    <button
                      mat-menu-item
                      class="d-flex justify-content-between align-items-center"
                      (click)="
                        showContract.emit({ event: 'ECTRCR', contract: con })
                      "
                      *ngIf="
                        con.contract_status == '1' && employee_has_contract
                      "
                    >
                      <span>{{ "lang_end_contract" | language }}</span>
                      <i class="fa fa-trash-o fa-icon mnabricon s2icon"></i>
                    </button>
                    <button
                      *ngIf="con?.contract_type == 'edit'"
                      mat-menu-item
                      class="d-flex justify-content-between align-items-center"
                      (click)="spinner.show(); view_history(con, history)"
                    >
                      <span>{{ "lang_history" | language }}</span>
                      <i
                        matTooltip="{{ 'lang_view' | language }}"
                        class="fa fa-eye fa-icon mnabricon s2icon"
                      ></i>
                    </button>
                  </mat-menu>
                  <mat-menu #editMenu="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        showContract.emit({
                          event: 'CTRCR2',
                          contract: con,
                          status: 'system_edit',
                          contract_data: con
                        })
                      "
                      *ngIf="con.contract_status == '1'"
                    >
                      {{ "lang_system_edit" | language }}
                    </button>
                    <button
                      mat-menu-item
                      (click)="
                        showContract.emit({
                          event: 'CTRCR2',
                          contract: con,
                          status: 'manual_edit',
                          contract_data: con
                        })
                      "
                      *ngIf="con.contract_status == '1'"
                    >
                      {{ "lang_manual_edit" | language }}
                    </button>
                  </mat-menu>
                  <mat-menu #renewMenu="matMenu">
                    <button
                      mat-menu-item
                      (click)="isCanRenew(con, 'system_renew')"
                    >
                      {{ "lang_system_renew" | language }}
                    </button>
                    <button
                      mat-menu-item
                      (click)="isCanRenew(con, 'manual_renew')"
                    >
                      {{ "lang_manual_renew" | language }}
                    </button>
                  </mat-menu>
                  <i
                    *ngIf="con.contract_attachment"
                    class="fa fa-paperclip fa-icon mnabricon s2icon"
                    (click)="
                      ds.preview_pdf_file(con.contract_attachment, con?.subject)
                    "
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <no-data
            style="margin: auto"
            [Opened]="wholedata.length < 1"
          ></no-data>
        </div>
      </div>
    </div>
    <div *ngIf="wholedata.length != 0" class="card-footer padb0 customborder">
      <div class="row">
        <div
          *ngIf="able2delete && requestfor !== 'profile'"
          class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10"
        >
          <i
            class="fa fa-trash-o faicon red deleteIcon"
            (click)="deleteBulkDataContr()"
            aria-hidden="true"
            matTooltip="{{ 'lang_delete' | language }}"
          ></i>
        </div>
        <div class="col-lg-2 col-sm-6 col-6 mb-10">
          {{ "lang_total" | language }} {{ wholedata?.page?.total_records }}
        </div>
        <div class="col-lg-5 col-sm-6 col-12 mb-10">
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button
              value="10"
              checked
              (click)="spinner.show(); load_page_size(10)"
            >
              10
            </mat-radio-button>
            <mat-radio-button
              value="20"
              (click)="spinner.show(); load_page_size(20)"
            >
              20
            </mat-radio-button>
            <mat-radio-button
              value="50"
              (click)="spinner.show(); load_page_size(50)"
            >
              50
            </mat-radio-button>
            <mat-radio-button
              value="100"
              (click)="spinner.show(); load_page_size(100)"
            >
              100
            </mat-radio-button>
            <mat-radio-button
              value="500"
              (click)="spinner.show(); load_page_size(500)"
            >
              500
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mb-10">
          {{ "lang_goto" | language }}
          <select
            class="customselect"
            (change)="spinner.show(); page_length($event.target.value)"
          >
            <option
              *ngFor="let k of ds.getrange(wholedata.page.total_pages)"
              value="{{ k }}"
            >
              {{ k }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #viewContracts>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ "lang_human_resources_contract_details" | language }}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        &times;
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_subject" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall"> : {{ viewdata.records[0].subject }}</b>
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_work_domain" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall"> : {{ viewdata.records[0].work_domain }}</b>
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_work_location" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall"> : {{ viewdata.records[0].work_location }}</b>
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_work_type" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall"> : {{ viewdata.records[0].work_location }}</b>
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_human_resources_joining_date" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].joining_date_english }} -
              {{ viewdata.records[0].joining_date_arabic }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_human_resources_propation_period" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].probation_period }}
              {{ "lang_days" | language }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_human_resources_probation_end_date" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].probation_expiry_date_english }} -
              {{ viewdata.records[0].probation_expiry_date_arabic }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_human_resources_contract_start_date" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].contract_start_date_english }} -
              {{ viewdata.records[0].contract_start_date_arabic }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_human_resources_contract_period" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].contract_period }}
              {{ "lang_days" | language }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_human_resources_contract_end_date" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].contract_end_date_english }} -
              {{ viewdata.records[0].contract_end_date_arabic }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_contract_actual_end_date" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].contract_actual_end_date }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_human_resources_vacation_paid_days" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].vacation_paid_days }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_human_resources_custom_vacation_date" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].upcoming_vacation_date_english }} -
              {{ viewdata.records[0].upcoming_vacation_date_arabic }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_human_resources_custom_vacation_end_date" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].upcoming_vacation_end_date_english }} -
              {{ viewdata.records[0].upcoming_vacation_end_date_arabic }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_human_resources_working_days_per_week" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].working_days_per_week }}
              {{ "lang_days" | language }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_human_resources_working_hours" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ viewdata.records[0].working_hours }}
              {{ "lang_hours" | language }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_basic_salary" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ ds.addCommas(viewdata.records[0].basic_salary) }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_home_allowance" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ ds.addCommas(viewdata.records[0].home_allowance) }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_net_amount" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">
              : {{ ds.addCommas(viewdata.records[0].net_amount) }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_status" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b
              class="breakall"
              *ngIf="viewdata.records[0].contract_status == '1'"
            >
              : <i class="fa fa-circle fa-icon green"></i>
              {{ "lang_active" | language }}</b
            >
            <b
              class="breakall"
              *ngIf="viewdata.records[0].contract_status == '2'"
            >
              : <i class="fa fa-circle fa-icon blue"></i>
              {{ "lang_completed" | language }}</b
            >
            <b
              class="breakall"
              *ngIf="viewdata.records[0].contract_status == '3'"
            >
              : <i class="fa fa-circle fa-icon red"></i>
              {{ "lang_rejected" | language }}</b
            >
          </div>
        </div>
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12">{{
            "lang_writer" | language
          }}</label>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall"> : {{ viewdata.records[0].writer }}</b>
          </div>
        </div>
        <div class="row" *ngIf="viewdata && viewdata.allowances.length > 0">
          <p>{{ "lang_other_allowances" | language }}</p>
          <div class="table-responsive customResponsive tablefields p-3">
            <table border="1" class="table CustomTable" width="100%">
              <thead>
                <tr>
                  <td style="padding: 5px; word-break: break-all">#</td>
                  <td style="padding: 5px; word-break: break-all">
                    {{ "lang_desc_english" | language }}
                  </td>
                  <td style="padding: 5px; word-break: break-all">
                    {{ "lang_invoice_item_amount" | language }}
                  </td>
                  <td style="padding: 5px; word-break: break-all">
                    {{ "lang_desc_arabic" | language }}
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let otr of viewdata.allowances;
                    let i = index;
                    let odd = odd;
                    let even = even
                  "
                  [ngClass]="{ odd: odd, even: even }"
                  style="background: #ffffff"
                >
                  <td width="1%" class="verticalalignmid">{{ i + 1 }}</td>
                  <td
                    style="padding: 5px; word-break: break-all"
                    style="position: relative"
                  >
                    {{ otr.other_allowance_description_en }}
                  </td>
                  <td
                    style="padding: 5px; word-break: break-all"
                    style="position: relative"
                  >
                    {{ ds.addCommas(otr.other_allowance_amount) }}
                  </td>
                  <td
                    style="padding: 5px; word-break: break-all"
                    style="position: relative"
                  >
                    {{ otr.other_allowance_description_ar }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #history>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ "lang_history" | language }}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefA.hide()"
      >
        &times;
      </button>
    </div>
    <div class="modal-body">
      <div class="table-responsive dropdownyes customResponsive">
        <table border="1" class="table CustomTable">
          <thead>
            <tr>
              <td>#</td>
              <td>{{ "lang_transaction_request_id" | language }}</td>
              <td>{{ "lang_employee_number" | language }}</td>
              <td>{{ "lang_contract_source_id" | language }}</td>
              <td>{{ "lang_notes" | language }}</td>
              <td>{{ "lang_time_edited" | language }}</td>
              <td>{{ "lang_action" | language }}</td>
            </tr>
          </thead>
          <tbody *ngIf="viewhistory.length != '0'" class="AppendList">
            <tr
              *ngFor="
                let data of viewhistory?.records;
                let i = index;
                let odd = odd;
                let even = even
              "
              [ngClass]="{ odd: odd, even: even }"
            >
              <td>{{ i + 1 }}</td>
              <td>
                {{ data?.transaction_request_id }}
              </td>
              <td>
                {{ data?.employee_number }}
              </td>
              <td>
                {{ data?.contract_source_id }}
              </td>
              <td>
                {{ data?.notes | language }}
              </td>
              <td>
                {{ data?.time_edited }}
              </td>
              <td>
                <i
                  matTooltip="{{ 'lang_action' | language }}"
                  class="fa fa-cog fa-icon mnabricon s2icon"
                  [matMenuTriggerFor]="matConMenu"
                ></i>
                <mat-menu
                  #matConMenu="matMenu"
                  [xPosition]="lang_key == 'ar' ? 'after' : 'before'"
                >
                  <button
                    mat-menu-item
                    class="d-flex justify-content-between align-items-center"
                    (click)="
                      choose_history(
                        data?.contract_new_data,
                        data,
                        'new',
                        Old_New_History
                      )
                    "
                  >
                    <span>{{ "lang_new_history" | language }}</span>
                  </button>
                  <button
                    mat-menu-item
                    class="d-flex justify-content-between align-items-center"
                    (click)="
                      choose_history(
                        data?.contract_old_data,
                        data,
                        'old',
                        Old_New_History
                      )
                    "
                  >
                    <span>{{ "lang_old_history" | language }}</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="viewhistory.length == '0'" class="AppendList">
            <tr class="odd">
              <td colspan="7" align="center">{{ lodingdatas }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="viewhistory.length != '0'" class="card-footer padb0">
        <div class="row responsive_pagination">
          <div
            class="col-lg-3 col-sm-3 mb-10"
            style="height: fit-content; margin: auto 0"
          >
            {{ "lang_total" | language }} :
            {{ viewhistory?.page?.total_records }}
          </div>
          <div
            class="col-lg-6 col-sm-6 mb-10"
            style="display: flex; justify-content: center"
          >
            <ngb-pagination
              class="m-auto"
              [collectionSize]="viewhistory?.page?.total_records"
              [rotate]="true"
              [ellipses]="false"
              [maxSize]="3"
              [boundaryLinks]="true"
              [(page)]="pagenum"
              [pageSize]="pageSize"
              (pageChange)="loadPageHis($event)"
            ></ngb-pagination>
          </div>
          <div
            class="col-lg-3 col-sm-3"
            style="display: flex; justify-content: end; margin: auto"
          >
            <mat-select
              style="max-width: 150px"
              [(ngModel)]="pageSize"
              (selectionChange)="loadHisPagesize($event.value)"
            >
              <mat-option class="text-center" value="10"
                >10 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="20"
                >20 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="50"
                >50 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="100"
                >100 {{ "lang_per_page" | language }}</mat-option
              >
              <mat-option class="text-center" value="500"
                >500 {{ "lang_per_page" | language }}</mat-option
              >
            </mat-select>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRefA.hide()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #Old_New_History>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="history_type == 'new'">
        {{ "lang_new_history" | language }}
      </h4>
      <h4 class="modal-title" *ngIf="history_type == 'old'">
        {{ "lang_old_history" | language }}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefB.hide()"
      >
        &times;
      </button>
    </div>
    <div class="modal-body">
      <div class="row mb-10">
        <div class="contentbodypanel width100 mt-0">
          <div class="col-12 viewquotation m-1">
            <div class="itemsdata">
              <label> {{ "lang_info" | language }} : </label>
              <div class="TableParts">
                <div
                  class="table-responsive customResponsive mb-10 tablefields p-3 quotationitemtable"
                >
                  <table class="table CustomTables text-center">
                    <tbody class="AppendList quotationtable">
                      <tr>
                        <td class="bold">
                          {{ "lang_type" | language }}
                        </td>
                        <td>
                          {{ old_new_history?.contract_data?.contract_type }}
                        </td>

                        <td class="bold">
                          {{ "lang_subject" | language }}
                        </td>
                        <td>
                          {{ old_new_history?.contract_data?.subject }}
                        </td>

                        <td class="bold">
                          {{ "lang_transaction_request_id" | language }}
                        </td>
                        <td>
                          {{ main_history?.transaction_request_id }}
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">
                          {{ "lang_employee_number" | language }}
                        </td>
                        <td>
                          {{ main_history?.employee_number }}
                        </td>

                        <td class="bold">
                          {{ "lang_contract_source_id" | language }}
                        </td>
                        <td>
                          {{ main_history?.contract_source_id }}
                        </td>

                        <td class="bold">
                          {{ "lang_notes" | language }}
                        </td>
                        <td>
                          {{ main_history?.notes | language }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
            </div>

            <div class="itemsdata">
              <label> {{ "lang_work" | language }} : </label>
              <div class="TableParts">
                <div
                  class="table-responsive customResponsive mb-10 tablefields p-3 quotationitemtable"
                >
                  <table class="table CustomTables text-center">
                    <tbody class="AppendList quotationtable">
                      <tr>
                        <td class="bold">
                          {{
                            "lang_human_resources_joining_date_english"
                              | language
                          }}
                        </td>
                        <td>
                          {{
                            old_new_history?.contract_data?.joining_date_english
                          }}
                        </td>
                        <td class="bold">
                          {{
                            "lang_human_resources_joining_date_arabic"
                              | language
                          }}
                        </td>
                        <td>
                          {{
                            old_new_history?.contract_data?.joining_date_arabic
                          }}
                        </td>
                        <td class="bold">
                          {{
                            "lang_human_resources_propation_period" | language
                          }}
                        </td>
                        <td>
                          {{ old_new_history?.contract_data?.probation_period }}
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">
                          {{
                            "lang_human_resources_contract_start_date"
                              | language
                          }}
                        </td>
                        <td>
                          {{
                            old_new_history?.contract_data
                              ?.contract_start_date_english
                          }}
                          -
                          {{
                            old_new_history?.contract_data
                              ?.contract_start_date_arabic
                          }}
                        </td>
                        <td class="bold">
                          {{
                            "lang_human_resources_contract_end_date" | language
                          }}
                        </td>
                        <td>
                          {{
                            old_new_history?.contract_data
                              ?.contract_end_date_english
                          }}
                          -
                          {{
                            old_new_history?.contract_data
                              ?.contract_end_date_arabic
                          }}
                        </td>
                        <td class="bold">
                          {{
                            "lang_human_resources_contract_period" | language
                          }}
                        </td>
                        <td>
                          {{ old_new_history?.contract_data?.contract_period }}
                        </td>
                      </tr>

                      <tr>
                        <td class="bold" colspan="2">
                          {{
                            "lang_human_resources_notification_period"
                              | language
                          }}
                        </td>
                        <td>
                          {{
                            old_new_history?.contract_data?.notification_period
                          }}
                        </td>
                        <td class="bold" colspan="2">
                          {{ "lang_auto_renew" | language }}
                        </td>
                        <td>
                          {{
                            old_new_history?.contract_data?.auto_renew == "0"
                              ? ("lang_no" | language)
                              : ("lang_yes" | language)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
            </div>

            <div class="itemsdata">
              <label> {{ "lang_salary" | language }} : </label>
              <div class="TableParts">
                <div
                  class="table-responsive customResponsive mb-10 tablefields p-3 quotationitemtable"
                >
                  <table class="table CustomTables text-center">
                    <tbody class="AppendList quotationtable">
                      <tr>
                        <td class="bold">
                          {{ "lang_hours_work_type" | language }}
                        </td>
                        <td>
                          {{
                            old_new_history?.contract_data?.working_hours_type
                          }}
                        </td>
                        <td class="bold">
                          {{ "lang_human_resources_working_hours" | language }}
                        </td>
                        <td>
                          {{ old_new_history?.contract_data?.working_hours }}
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">
                          {{
                            "lang_human_resources_working_days_per_week"
                              | language
                          }}
                        </td>
                        <td>
                          {{
                            old_new_history?.contract_data
                              ?.working_days_per_week
                          }}
                        </td>

                        <td class="bold">
                          {{
                            "lang_human_resources_vacation_paid_days" | language
                          }}
                        </td>
                        <td>
                          {{
                            old_new_history?.contract_data?.vacation_paid_days
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">
                          {{ "lang_ticket_amount" | language }}
                        </td>
                        <td>
                          {{ old_new_history?.contract_data?.ticket_amount }}
                        </td>

                        <td class="bold">
                          {{ "lang_basic_salary" | language }}
                        </td>
                        <td>
                          {{ old_new_history?.contract_data?.basic_salary }}
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">
                          {{ "lang_home_allowance" | language }}
                        </td>
                        <td>
                          {{ old_new_history?.contract_data?.home_allowance }}
                        </td>
                        <td class="bold">
                          {{ "lang_net_amount" | language }}
                        </td>
                        <td>
                          {{ old_new_history?.contract_data?.net_amount }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
            </div>

            <div
              class="itemsdata"
              *ngIf="old_new_history?.home_allowance?.length > 0"
            >
              <label> {{ "lang_other_allowances" | language }} : </label>
              <div class="row mb-2 mt-2">
                <div class="col-md-12 pad0 viewquotation">
                  <div
                    class="table-responsive customResponsive tablefields p-3"
                  >
                    <table border="1" class="table CustomTable">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>{{ "lang_desc_english" | language }}</td>
                          <td>{{ "lang_desc_arabic" | language }}</td>
                          <td>{{ "lang_invoice_item_amount" | language }}</td>
                        </tr>
                      </thead>
                      <tbody class="AppendList">
                        <tr
                          *ngFor="
                            let item of old_new_history?.home_allowance;
                            let i = index;
                            let odd = odd;
                            let even = even
                          "
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ item?.other_allowance_description_en }}</td>
                          <td>{{ item?.other_allowance_description_ar }}</td>
                          <td>{{ item?.other_allowance_amount }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="itemsdata mb-3"
              *ngIf="old_new_history?.additional_terms?.length > 0"
            >
              <label> {{ "lang_other_allowances" | language }} : </label>
              <div class="row mb-2 mt-2">
                <div class="col-md-12 pad0">
                  <div
                    class="table-responsive customResponsive tablefields p-3"
                  >
                    <table border="1" class="table CustomTable">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>{{ "lang_desc_english" | language }}</td>
                          <td>{{ "lang_desc_arabic" | language }}</td>
                          <td>{{ "lang_type" | language }}</td>
                        </tr>
                      </thead>
                      <tbody class="AppendList">
                        <tr
                          *ngFor="
                            let item of old_new_history?.additional_terms;
                            let i = index;
                            let odd = odd;
                            let even = even
                          "
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ item?.terms_content_english }}</td>
                          <td>{{ item?.terms_content_arabic }}</td>
                          <td>{{ item?.terms_type }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRefB.hide()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>
