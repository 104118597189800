import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { PreloaderService } from "@core/bootstrap/preloader.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ViewVactionComponent } from "./view-vaction/view-vaction.component";
import { environment } from "@env/environment";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
  selector: "app-vaction",
  templateUrl: "./vaction.component.html",
  styleUrls: ["./vaction.component.scss"],
})
export class VactionComponent implements OnInit {
  selected = new FormControl(0);
  loading = true;
  searchText = "";
  @Output() showVacation = new EventEmitter();
  @Input() openFromProfile = false;
  @Input() able2edit;
  @Input() able2delete;
  @Input() able2add;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  @Input() types;
  @Input() requestfor;
  @Input() user_data;
  @Input() attachments;
  totaldetection_lists: number;
  detection_page_size: number = 10;
  detection_page_no: number = 1;
  totaldetection_lists_d: number;
  detection_page_size_d: number = 10;
  detection_page_no_d: number = 1;
  statusList: any = [
    { label: "lang_decrease", value: "C" },
    { label: "lang_increase", value: "D" },
  ];
  modalRefV: BsModalRef;
  //  noteform: FormGroup;
  modalRefA: BsModalRef;
  modalRefH: BsModalRef;
  npsize: any = 10;
  npage: any = 1;
  lang_key = this.ls.getItem("lang_key") || "en";
  nodata: string = this.lang.transform("lang_loading");
  nodata_d: string = this.lang.transform("lang_loading");
  search: string;
  pageSize = 10;
  pageIndex = 0;
  totalElements = 100;
  viewData: any;
  DialogRef: MatDialogRef<any>;
  vacationNote: string = "";

  // lang = "en";
  form: FormGroup;
  noteform: FormGroup = this.fb.group({});
  branches: [] = [];

  searchKey = "";
  module_filter = "";
  wholedata: any = [];
  vacationDetails;
  vacations: any = [];
  expandstatus = false;
  page = 1;
  size = 10;
  // errormessagef: any    = this.lang.transform('lang_loading');
  moduleusers: any = [];
  moduledata: any = [];
  history: any = [];
  persons: any = [];
  collectionSize: any;
  detection_lists = [];
  detection_lists_d = [];
  openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  openModalH(template: TemplateRef<any>, item) {
    this.selectedVacationItem = item;
    this.modalRefH = this.modalService.show(template, environment.modelconfig);
  }
  constructor(
    public router: Router,
    public ds: DataService,
    public route: ActivatedRoute,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    public sweetAlert: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.expandstatus = this.requestfor == "profile" ? true : false;
    this.getVacationDetails();
    this.search_employee_modules();
    this.buildform();
  }

  getVacationDetails() {
    let formData = new FormData();
    formData.append("employee_number", this.id || "");
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "form/FORM_HRV1/get_all_typeof_reports"
      )
      .subscribe((res) => {
        if (res?.status) {
          this.vacationDetails = res?.data;
        }
      });
  }
  get_detection_lists() {
    this.spinner.show();
    let formData = new FormData();
    formData.append("employee_number", this.id || "");
    formData.append("status", "C");
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `human_resources/voc_detection_lists/${this.detection_page_no}/${this.detection_page_size}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.detection_lists = res?.records;
            this.totaldetection_lists = res?.total;
          } else {
            this.spinner.hide();
            this.nodata = res.error;
          }
        },
        (error) => {
          this.spinner.hide();
          this.nodata = error.error;
        }
      );
    formData.append("status", "D");
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `human_resources/voc_detection_lists/${this.detection_page_no}/${this.detection_page_size}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.detection_lists_d = res?.records;
            this.totaldetection_lists_d = res?.total;
          } else {
            this.spinner.hide();
            this.nodata_d = res.error;
          }
        },
        (error) => {
          this.spinner.hide();
          this.nodata_d = error.error;
        }
      );
  }
  public downlaodFile(url, code) {
    this.spinner.show();
    this.ds.getActionByUrl([], url).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          let url = "data:" + res["content-type"] + ";base64," + res.base64;

          let tagA = document.createElement("a");
          tagA.style.display = "none";
          tagA.href = url;
          tagA.download = code;
          tagA.click();
        } else {
          this.ds.dialogf("", res.error);
        }
      },
      (error) => {
        this.ds.dialogf(
          "",
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
        this.spinner.hide();
      }
    );
  }

  public load_page_size(pageno) {
    this.spinner.show();
    this.size = pageno;
    this.get_employee_modules();
  }

  public page_length(page) {
    this.spinner.show();
    this.page = page;
    this.get_employee_modules();
  }

  public search_employee_modules() {
    this.size = 10;
    this.page = 1;
    this.get_employee_modules();
  }

  refreshLists() {}

  records = [];
  selectedVacationItem: any;
  public get_employee_modules() {
    const param = new FormData();
    this.spinner.show();
    param.append("employee_number", this.id);
    param.append("search_value", this.searchKey);
    let url = "human_resources/get_employee_vacation_history";
    if (this.openFromProfile) {
      url = "human_resources/get_employee_vacation_history_P";
    }
    this.ds.post(url + "/" + this.page + "/" + this.size, param).subscribe(
      (data) => {
        this.spinner.hide();
        if (data.status) {
          this.wholedata = data;
          this.records = data.records;
        } else {
          this.records = [];
          this.wholedata = [];
          // this.errormessagef = data.error;
        }
      },
      (error) => {
        this.spinner.hide();
        this.wholedata = [];
        // this.errormessagef = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
      }
    );
  }

  showVacationDetails(id) {
    const dialogRef = this.dialog.open(ViewVactionComponent);
  }

  public note_operations() {
    this.spinner.show();
    if (this.noteform.get("note_id").value) {
      const formData = new FormData();
      formData.append("note_id", this.noteform.get("note_id").value);
      formData.append(
        "note_description",
        this.noteform.get("note_description").value || ""
      );
      formData.append(
        "note_remainder_status",
        this.noteform.get("note_remainder_status").value
      );
      if (this.noteform.get("note_remainder_status").value == "1") {
        formData.append(
          "note_remainder_date",
          this.noteform.get("note_remainder_date").value || ""
        );
      }
      formData.append("show_status", this.noteform.get("show_status").value);
      formData.append(
        "link_with_view_list",
        this.noteform.get("link_with_view_list").value
      );
      formData.append("id", this.id);
      this.ds
        .putActionByUrl(this.ds.formData2string(formData), "hr_update_notes")
        .subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
              this.alert.success(data.msg);
              this.noteform.reset();
              this.list_of_notes();
              setTimeout(() => {
                this.modalRefA.hide();
              }, 1000);
            } else {
              this.alert.error(data.error);
            }
          },
          (error) => {
            this.spinner.hide();
            // this.alert.error(error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          }
        );
    } else {
      const formData = new FormData();
      formData.append(
        "note_description",
        this.noteform.get("note_description").value || ""
      );
      formData.append(
        "note_remainder_status",
        this.noteform.get("note_remainder_status").value
      );
      if (this.noteform.get("note_remainder_status").value == "1") {
        formData.append(
          "note_remainder_date",
          this.noteform.get("note_remainder_date").value || ""
        );
      }
      formData.append("show_status", this.noteform.get("show_status").value);
      formData.append(
        "link_with_view_list",
        this.noteform.get("link_with_view_list").value
      );
      formData.append("id", this.id);
      this.ds.postActionByUrl(formData, "hr_create_notes").subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.alert.success(data.msg);
            this.noteform.reset();
            this.list_of_notes();
            setTimeout(() => {
              this.modalRefA.hide();
            }, 1000);
          } else {
            this.alert.error(data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          // this.alert.error(error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
        }
      );
    }
  }

  public branchlists() {
    this.branches = [];
    this.types = [];
    this.ds
      .postActionByUrl([], "form/FORM_HRV1/get_vacation_type")
      .subscribe((data) => {
        this.branches = data.records;

        if (data.status) {
          this.branches = data.records;
        }
      });
  }

  public list_of_notes() {
    let formData = new FormData();
    formData.append("id", this.id);
    formData.append("branch_id", this.branch_id);
    formData.append("search_key", this.searchKey || "");
    // formData.append('search_status', this.searchStatus || '');
    let url = this.requestfor !== "profile" ? "hr_notes" : "get_my_notes";
    this.ds
      .postActionByUrl(formData, url + "/" + this.npage + "/" + this.npsize)
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            // this.notedata = res;
          } else {
            // this.notedata = [];
            // this.noteerrormsg = res.error;
          }
        },
        (error) => {
          this.spinner.hide();
          // this.notedata = [];
          // this.noteerrormsg = error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
        }
      );
  }
  public loadPageFrm(page) {
    this.detection_page_no = page;
    this.detection_page_size = this.detection_page_size;
    this.get_detection_lists();
  }
  public loadFrmPagesize(size) {
    this.detection_page_no = 1;
    this.detection_page_size = size;
    this.get_detection_lists();
  }
  create_detection() {
    let param = new FormData();
    param.set("employee_number", this.id);
    param.set("days", this.form.get("days").value);
    param.set("note", this.form.get("note").value);
    param.set("status", this.form.get("status").value);
    this.spinner.show();
    this.subscriptions.add(
      this.ds
        .postActionByUrl(param, "human_resources/voc_detection_create")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.get_detection_lists();
              this.form.reset();
            } else {
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }
  public buildform() {
    this.form = new FormGroup({
      employee_number: new FormControl("", [Validators.required]),
      days: new FormControl("", [Validators.required]),
      note: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required),
    });
  }

  cancelVacation() {
    let requestBody = new FormData();
    requestBody.append(
      "transaction_request_id",
      this.selectedVacationItem?.transaction_request_id
    );
    requestBody.append("note", this.vacationNote);
    this.ds.post(`form/FORM_HRV1/cancel_request`, requestBody).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.sweetAlert.successMessage(
            this.lang.transform("lang_vacation_cancelled_successfully")
          );
          this.modalRefH.hide();
        } else {
          this.sweetAlert.errorMessage(res.error);
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
