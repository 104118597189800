import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { SendCodeComponent } from "app/routes/transactions/FORM_PS_TR1/send-code/send-code.component";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
	selector: "app-cpay-step7",
	templateUrl: "./cpay-step7.component.html",
	styleUrls: ["./cpay-step7.component.scss"],
})
export class CpayStep7Component implements OnInit {
	@Output() refreshComponent = new EventEmitter<boolean>();
	@Input() form_key;
	@Input() project_data;
	@Input() supervision_data;
	@Input() template_data;
	@Input() allData;
	stepName = "QAQC_Approval";
	sendCodeForm: any;
	form_request_id = this.route.snapshot.paramMap.get("form_request_id");
	form_key2 = this.route.snapshot.paramMap.get("form_key");
	sendCodeStatus: boolean = false;
	rejectedValidators = [];
	verifyerrormsg: any = "";
	form: FormGroup;

	constructor(
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		public router: Router,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.form = new FormGroup({
			transaction_request_id: new FormControl(
				this.form_request_id,
				Validators.required
			),
			manager_approval_status: new FormControl("", [Validators.required]),
			manager_rejected_notes: new FormControl("", [Validators.required]),
			transactions_persons_action_code: new FormControl("", [
				Validators.required,
			]),
		});
	}

	checkValidators() {
		if (this.form.get("manager_approval_status").invalid) {
			this.alert.error(this.lang.transform("lang_manager_approval_required"));
			return false;
		} else if (
			this.form.get("manager_approval_status").value == "Return" &&
			this.form.get("manager_rejected_notes").invalid
		) {
			this.alert.error(this.lang.transform("lang_rejected_notes_required"));
			return false;
		} else if (this.form.get("transactions_persons_action_code").invalid) {
			this.alert.error(this.lang.transform("lang_send_code_required"));
			return false;
		} else {
			return true;
		}
	}

	completeStep() {
		if (this.checkValidators()) {
			this.spinner.show();
			let formData = new FormData();
			formData.append(
				"transaction_request_id",
				this.form.value.transaction_request_id
			);
			formData.append(
				"transaction_approval_status",
				this.form.value.manager_approval_status == "Approve" ? "1" : "2"
			);
			if (this.form.value.manager_approval_status == "Return") {
				formData.append(
					"transaction_rejected_notes",
					this.form.value.manager_rejected_notes
				);
			}
			formData.append(
				"transactions_persons_action_code",
				this.form.value.transactions_persons_action_code
			);
			this.ds.post(`form/${this.form_key}/QAQC_Approval/0`, formData).subscribe(
				(data) => {
					if (data.status) {
						this.spinner.hide();
						this.sweetAlert.successToast(
							this.lang.transform("lang_data_sent_successfully"),
							1700
						);
						setTimeout(() => {
							this.refreshComponent.emit(true);
						}, 2000);
					} else {
						this.spinner.hide();
						this.alert.error(data?.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(error);
				}
			);
		} else {
			this.alert.error(this.lang.transform("lang_all_fields_required"));
		}
	}

	openModal() {
		const dialogRef = this.dialog.open(SendCodeComponent, {
			disableClose: true,
			width: "400px",
			data: {
				transaction_persons_type: "signature",
				allData: this.allData,
				stepName: this.stepName,
				title: "lang_signature",
			},
		});
		dialogRef.afterClosed().subscribe((data) => {
			this.sendCodeForm = data.formValue;
			this.sendCodeStatus = data.sendstatus;
		});
	}
}
