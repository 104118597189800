import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
	selector: "app-select-preview",
	templateUrl: "./select-preview.component.html",
	styleUrls: ["./select-preview.component.scss"],
})
export class SelectPreviewComponent implements OnInit {
	preview_type = "T1P";
	preview_type_list = [
		{
			label: "Total Amounts By Units PDF",
			value: "T1P",
		},
		{
			label: "Total Amounts By Units Excel",
			value: "T1E",
		},
		{
			label: "Total Amounts By Divisions PDF",
			value: "T2P",
		},
		{
			label: "Total Amounts By Divisions Excel",
			value: "T2E",
		},
		{
			label: "Total Amounts By Divisions and sub PDF",
			value: "T3P",
		},
		{
			label: "Total Amounts By Divisions and sub Excel",
			value: "T3E",
		},
	];
	constructor(
		private dialogRef: MatDialogRef<SelectPreviewComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {}
	ngOnInit(): void {}

	onSubmit() {
		this.dialogRef.close({ status: true, data: this.preview_type });
	}
}
