import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	TemplateRef,
} from "@angular/core";
import {
	FormGroup,
	FormArray,
	FormBuilder,
	Validators,
	FormControl,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { ViolationHistoryComponent } from "../../../send-code/violation-history.component";

declare var $: any;
@Component({
	selector: "violation-step-one-configrations",
	templateUrl: "./step-one-configrations.component.html",
	styleUrls: ["./step-one-configrations.component.scss"],
})
export class ViolationStepOneConfigrationsComponent implements OnInit {
	@Output() violationEvent = new EventEmitter();
	@Input() allData;
	@Input() form_request_id;
	@Input() form_key;
	@Input() id;
	attachstatus: boolean = false;
	attachments: FormArray;
	options: string[] = ["One", "Two", "Three"];
	filteredOptions: Observable<string[]>;
	users: any = [];
	direct_anager: any = [];
	form: FormGroup;
	submitted: boolean = false;
	lang_key = localStorage.getItem("lang_key") || "en";

	@Input() set alldata(data: any) {
		if (data.status) {
			this.allData = data;
			if (this.form.value.violation_arr.length == 0) {
				for (
					let index = 0;
					index < data.form_vol1_data.records.length;
					index++
				) {
					const element = data.form_vol1_data.records[index];
					this.addViolation(element);
				}
			}
			this.users = [
				{
					label: this.allData?.form_vol1_data?.records[0]?.direct_manager_name,
					value: this.allData?.form_vol1_data?.records[0]?.direct_manager,
				},
			];
			this.form.patchValue({
				direct_manager:
					this.allData?.form_vol1_data?.records[0]?.direct_manager,
			});

			if (data.form_vol1_attachments.status) {
				this.form.patchValue({
					needAttach: 1,
				});
				this.attachstatus = true;

				for (
					let index = 0;
					index < data.form_vol1_attachments.records.length;
					index++
				) {
					const element = data.form_vol1_attachments.records[index];
					this.addfiles({
						file_id: element.file_records_id,
						attach_title: element.file_name_en,
						file: element.link,
					});
				}
			}
		}
	}

	constructor(
		public ds: DataService,
		public datepipe: DatePipe,
		private _sweetAlertService: SweetAlertService,
		public dialog: MatDialog,
		private snackBar: MatSnackBar,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public changeDetectorRef: ChangeDetectorRef
	) {
		this.form = this.fb.group({
			needAttach: new FormControl("0", [Validators.required]),
			attachments: this.fb.array(
				[],
				this.showAttachment ? [Validators.required] : []
			),
			violation_arr: this.fb.array([
				/*  this.fb.group({
            violation_date_en: ['', Validators.required],
            violation_date_ar: ['', Validators.required],
            violation_id:['', Validators.required],
            violation_data_en: ['', ],
            violation_data_ar: ['',  ],


            violation_punishment_id: ['', Validators.required],
            violation_punishment: ['', Validators.required],
            violation_count: ['', Validators.required],
            trackingId: [this.generateUniqueId()],
            collapsed:[true],



            violation_statement_en: ['', Validators.required],
            violation_statement_ar: ['', Validators.required],
            violation_punishment_type: ['', Validators.required],
            violation_deduct_percentage: ['', Validators.required],
            violation_amount: ['', Validators.required],
          }), */
			]),

			employee_number: [
				this.allData?.form_vol1_data?.records[0].employee_number,
			],
			/* violation_date_en: [moment(this.allData?.form_vol1_data?.records?.violation_date_en,'YYYY-MM-DD').format(), Validators.required],
      violation_date_ar: [this.allData?.form_vol1_data?.records?.violation_date_ar, Validators.required],
      violation_data_en: [this.allData?.form_vol1_data?.records?.violation_data_en, ],
      violation_data_ar: [this.allData?.form_vol1_data?.records?.violation_data_ar,  ],
      violation_punishment_id: [this.allData?.form_vol1_data?.records?.violation_punishment_id, Validators.required],
      violation_punishment: [this.allData?.form_vol1_data?.records?.violation_punishment, Validators.required],
      violation_count: [this.allData?.form_vol1_data?.records?.violation_count, Validators.required],
      violation_statement_en: [this.allData?.form_vol1_data?.records?.violation_statement_en, Validators.required],
      violation_statement_ar: [this.allData?.form_vol1_data?.records?.violation_statement_ar, Validators.required],
      violation_punishment_type: [this.allData?.form_vol1_data?.records?.violation_punishment_type, Validators.required],
      violation_deduct_percentage: [this.allData?.form_vol1_data?.records?.violation_deduct_percentage, Validators.required],
      violation_amount: [this.allData?.form_vol1_data?.records?.violation_amount, Validators.required], */

			direct_manager: [
				this.allData?.form_vol1_data?.records?.direct_manager,
				Validators.required,
			],
		});

		this.attachments = this.form.get("attachments") as FormArray;
	}
	showAttachment = false;
	ngOnInit(): void {
		this.spinner.show();
		moment.locale("en");
		this.searchFunc();
		this.spinner.hide();
	}
	trackByFnCustom(index: number, item: any) {
		return item.trackingId;
	}
	generateUniqueId() {
		return (
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15)
		);
	}
	deleteViolation(index) {
		var violation_arr = this.form.controls.violation_arr as FormArray;
		if (violation_arr.length > 1) {
			violation_arr.removeAt(index);
		} else {
			this.showMsg(
				this.lang.transform("lang_must_have_at_least_one"),
				"ok",
				"error-snackbar"
			);
		}
	}

	get filesFormGroup() {
		return this.form.get("attachments") as FormArray;
	}
	public disable_field(field) {
		if (field == "attachment") {
			this.attachments.clear();
			this.form.get("needAttach").setValue("0");
			/* this.isFile = false; */
			this.attachstatus = false;
		}
		this.form.updateValueAndValidity();
	}
	public enable_field(field) {
		if (field == "attachment") {
			this.attachments.clear();
			this.form.get("needAttach").setValue("1");
			this.addfiles();
			this.attachstatus = true;
		}
	}
	public files(obj: any = false): FormGroup {
		return this.fb.group({
			attach_title: new FormControl(
				obj?.attach_title,
				this.form.get("needAttach").value == "1" ? [Validators.required] : null
			),
			file: new FormControl(
				obj?.file,
				this.form.get("needAttach").value == "1" ? [Validators.required] : null
			),
			file_id: new FormControl(obj?.file_id ? obj?.file_id : 0),
		});
	}
	public addfiles(obj: any = false) {
		if (this.attachments.length < 5) {
			this.attachments.push(this.files(obj));
		}
	}
	public removefiles(index) {
		if (this.attachments.length > 1) {
			this.attachments.removeAt(index);
		}
	}

	public UploadFile(event, index) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			const fileid = event.target.files[0];
			$("#uploadText" + index).text(fileid.name);
			this.attachments.controls[index]
				.get("file")
				.setValue(fileid, { emitEvent: true });
		} else {
			this.attachments.controls[index].get("file").setValue(null);
			$("#uploadText" + index).text(this.lang.transform("lang_select_files"));
		}
	}
	get getAttachments() {
		return this.form.get("attachments");
	}

	getForm(i) {
		return this.form.controls.violation_arr["controls"][i].get(
			"violation_date_ar"
		);
	}

	getFormByName(i, name?) {
		if (name) {
			return this.form.controls.violation_arr["controls"][i].get(name);
		} else {
			return this.form.controls.violation_arr["controls"][i];
		}
	}
	addViolation(data?) {
		var violation_arr = this.form.controls.violation_arr as FormArray;
		if (data) {
			violation_arr.push(
				this.fb.group({
					violation_date_en: [
						new Date(data.violation_date_en),
						Validators.required,
					],
					violation_date_ar: [data.violation_date_ar, Validators.required],
					violation_id: [data.violation_id, Validators.required],
					violation_data_en: [data.violation_data_en],
					violation_data_ar: [data.violation_data_ar],

					violation_punishment_id: [
						data.violation_punishment_id,
						Validators.required,
					],
					violation_punishment: [
						data.violation_punishment,
						Validators.required,
					],
					violation_count: [data.violation_count, Validators.required],
					trackingId: [this.generateUniqueId()],

					collapsed: [true],

					violation_statement_en: [
						data.violation_statement_en,
						Validators.required,
					],
					violation_statement_ar: [
						data.violation_statement_ar,
						Validators.required,
					],
					violation_punishment_type: [
						data.violation_punishment_type,
						Validators.required,
					],
					violation_deduct_percentage: [
						data.violation_deduct_percentage,
						Validators.required,
					],
					violation_amount: [data.violation_amount, Validators.required],
				})
			);
		} else {
			violation_arr.push(
				this.fb.group({
					violation_date_en: ["", Validators.required],
					violation_date_ar: ["", Validators.required],
					violation_id: ["", Validators.required],
					violation_data_en: [""],
					violation_data_ar: [""],

					violation_punishment_id: ["", Validators.required],
					violation_punishment: ["", Validators.required],
					violation_count: ["", Validators.required],
					trackingId: [this.generateUniqueId()],

					collapsed: [true],

					violation_statement_en: ["", Validators.required],
					violation_statement_ar: ["", Validators.required],
					violation_punishment_type: ["", Validators.required],
					violation_deduct_percentage: ["", Validators.required],
					violation_amount: ["", Validators.required],
				})
			);
		}
	}
	download_ED_history(item) {
		this.spinner.show();
		this.ds.getActionByUrl([], item.file).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.ds.downloadFile(res, item.attach_title);
				} else {
					this._sweetAlertService.errorMessage(res?.error);
				}
			},
			(err) => {
				this._sweetAlertService.errorMessage(err?.error.error);
				this.spinner.hide();
			}
		);
	}

	searchArray = [];
	searchFunc() {
		let formData = new FormData();

		return this.ds
			.post(`form/${this.form_key}/search_violation`, formData)
			.subscribe((response: any) => {
				this.searchArray = response.records.map((re) => {
					return {
						label: this.lang_key == "en" ? re.violation_en : re.violation_ar,
						value: re.violation_id,
					};
				});
			});
	}
	getViolations(e, index) {
		moment.locale("en");

		let formData = new FormData();
		formData.append(
			"employee_number",
			this.allData?.form_vol1_data?.records[0].employee_number
		);
		formData.append("violation_id", e.value);
		formData.append(
			"violation_date_english",
			moment(this.getFormByName(index).value.violation_date_en).format(
				"YYYY/MM/DD"
			)
		);

		return this.ds
			.post(`form/${this.form_key}/check_violation_for_employee`, formData)
			.subscribe((response: any) => {
				if (response.status) {
					this.getFormByName(index).patchValue({
						...this.form.value,
						violation_id: response.records.violation_id,
						violation_punishment_id: response.records.violation_punishment_id,
						violation_punishment: response.records.punishment_en,
						violation_count: response.records.alert_time,
						violation_punishment_type: response.records.punishment_type,
						violation_deduct_percentage:
							+response.records.deduct_percentage || 0,
						violation_data_en: response.records.violation_data_en || "",
						violation_data_ar: response.records.violation_data_ar || "",
						violation_amount: +response.records.deduct_amount || 0,
					});
					/* this.form.patchValue({
          ...this.form.value,
          violation_id:response.records.violation_id,
          violation_punishment_id:response.records.violation_punishment_id,
          violation_punishment:response.records.punishment_en,
          violation_count:response.records.alert_time,
          violation_punishment_type:response.records.punishment_type,
          violation_deduct_percentage:+response.records.deduct_percentage||0,
          violation_amount:+response.records.deduct_amount||0,
        }) */
				} else {
				}
			});
	}
	submitForm() {
		/*  this.submitted = true; */
		if (this.form?.status == "VALID") {
			this.spinner.show();
			let formData = new FormData();
			moment.locale("en");
			formData.append(
				"transaction_request_id",
				this.allData?.form_vol1_data?.records[0]?.transaction_request_id
			);
			formData.append(
				"employee_number",
				this.allData?.form_vol1_data?.records[0].employee_number
			);
			formData.append("direct_manager", this.form.value.direct_manager);
			formData.append("needAttach", this.form.get("needAttach").value);

			this.form.controls.violation_arr["controls"].forEach((item, index) => {
				formData.append(
					`violations[${index}][violation_date_en]`,
					moment(item.get("violation_date_en").value).format("YYYY/MM/DD")
				);
				formData.append(
					`violations[${index}][violation_date_ar]`,
					item.get("violation_date_ar").value
				);
				formData.append(
					`violations[${index}][violation_id]`,
					item.get("violation_id").value
				);
				formData.append(
					`violations[${index}][violation_data_en]`,
					item.get("violation_data_en").value
				);
				formData.append(
					`violations[${index}][violation_data_ar]`,
					item.get("violation_data_ar").value
				);
				formData.append(
					`violations[${index}][violation_punishment_id]`,
					item.get("violation_punishment_id").value
				);
				formData.append(
					`violations[${index}][violation_punishment]`,
					item.get("violation_punishment").value
				);
				formData.append(
					`violations[${index}][violation_count]`,
					item.get("violation_count").value
				);
				formData.append(
					`violations[${index}][violation_statement_en]`,
					item.get("violation_statement_en").value
				);
				formData.append(
					`violations[${index}][violation_statement_ar]`,
					item.get("violation_statement_ar").value
				);
				formData.append(
					`violations[${index}][violation_punishment_type]`,
					item.get("violation_punishment_type").value
				);
				formData.append(
					`violations[${index}][violation_deduct_percentage]`,
					item.get("violation_punishment_type").value
				);
				formData.append(
					`violations[${index}][violation_amount]`,
					item.get("violation_punishment_type").value
				);
			});

			if (this.showAttachment && this.attachments.length == 0) {
				this.alert.error(this.lang.transform("lang_add_attachments"));
			}

			this.attachments.controls.forEach((item, index) => {
				formData.append(
					`attachments[${index + 1}][file]`,
					item.get("file").value
				);
				formData.append(
					`attachments[${index + 1}][attach_title]`,
					item.get("attach_title").value
				);
			});

			this.ds.post(`form/${this.form_key}/et`, formData).subscribe(
				(data) => {
					if (data.status) {
						this.alert.success(data.msg);
						this.showMsg(data.msg, "ok", "success-snackbar");

						this.submitted = false;
						this.form.reset();
						setTimeout(() => {
							location.reload();
						}, 1000);
						this.spinner.hide();
					} else {
						this.alert.error(data.msg);

						this.submitted = false;

						this.spinner.hide();
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(error);
				}
			);
		} else {
			this.showMsg("Please fill all fields first", "ok", "error-snackbar");
		}
	}

	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 4000,
			panelClass: [className],
		});
	}

	public search_users(key) {
		if (key) {
			this.users = [];
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.users = res.list;
					}
				});
		}
	}

	violationsArr = [];
	modalRef: BsModalRef;
	get_employee_violations(id) {
		if (!id) {
			this.showMsg(
				this.lang.transform("lang_please_select_violation_first"),
				"ok",
				"error-snackbar"
			);
			return 0;
		}

		let formData = new FormData();
		formData.append(
			"employee_number",
			this.allData?.form_vol1_data?.records[0].employee_number
		);
		formData.append("violation_id", id);
		formData.append("lang_key", this.lang_key);

		this.spinner.show();

		return (
			this.ds
				.post(`form/${this.form_key}/get_employee_violations`, formData)
				.subscribe((response: any) => {
					this.spinner.hide();
					if (response.status) {
						this.violationsArr = response.records;
						this.openModal();
					} else {
						this.showMsg(response.error, "ok", "error-snackbar");
					}
				}),
			(error) => {
				this.spinner.hide();
				this.alert.error(error);
			}
		);
	}
	openModal() {
		const dialogRef = this.dialog.open(ViolationHistoryComponent, {
			disableClose: true,
			width: "70%",
			data: { violationsArr: this.violationsArr },
		});
	}

	get violation_id() {
		return this.form.get("violation_id") as FormControl;
	}
}
