import { setInterval } from "timers";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { MatDialog } from "@angular/material/dialog";
import { ShowDeductionsComponent } from "../../show-deductions/show-deductions.component";
import { DataService } from "@core/bootstrap/data.service";
import { PreloaderService } from "@core";

import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";

const moment = _rollupMoment || _moment;
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LocalStorage } from "@core/bootstrap/localstorage.service";

declare var $: any;
@Component({
  selector: "app-step1",
  templateUrl: "./step1.component.html",
  styleUrls: ["./step1.component.scss"],
})
export class Step1Component implements OnInit {
  selectedIndex: number = 0;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  @Input() actionPage;
  @Input() t_id = 0;
  @Input() employee_data;
  @Input() form_request_id = 0;
  @Input() form_key;
  @Input() reviewers;
  @ViewChild("stepper") stepper;
  allData;
  goBack(stepper: MatStepper = this.stepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper = this.stepper) {
    stepper.next();
  }
  @Input() set alldata(res: any) {
    this.allData = res;
    if (res?.status) {
      this.requestInfo = res;
      this.request = res?.transactions_request;
      if (res?.transactions_persons?.status) {
        this.persons = res?.transactions_persons?.records;
      }
      this.actionbuttons = res?.transactions_buttons;
      this.form_request_id =
        this.requestInfo.form_sal_data.records[0].transaction_request_id;
      if (this.requestInfo.form_sal_data.status) {
        this.currentStep =
          this.requestInfo.form_sal_data.records[0].configation_current_step;
      }
    }
  }
  movesteps() {
    if (
      !this.requestInfo.steps.CONFIGURATION &&
      !this.requestInfo.steps.HUMAN_RESOURCE_MANAGER &&
      !this.requestInfo.steps.REVIEWER &&
      !this.requestInfo.steps.completed &&
      !this.requestInfo.steps.last
    ) {
      this.accessToSeeThis = false;
      /*  this.router.navigate(['/tasks/alltasks'])
      this._sweetAlertService.errorMessage(this.lang.transform('lang_step_permission')) */
    }
    this.accessToSeeThis = true;
    if (this.requestInfo?.last_request_step == "REVIEWER") {
      this.setState(this.stepTwoValid, false);
      this.setState(this.stepThreeValid, false);
      this.setState(this.stepFourValid, false);
      /* this.setState(this.stepFiveValid,false) */
      this.goForward();
      this.goForward();
      this.goForward();
      this.selectedIndex = 3;
      /*   this.goForward() */
    } else if (this.requestInfo?.last_request_step == "CONFIGURATION") {
      if (this.currentStep == "select_reviewers") {
        this.setState(this.stepTwoValid, false);
        this.setState(this.stepThreeValid, false);
        this.goForward();
        this.goForward();
        this.goForward();

        /* this.selectedIndex = 3 */
      }

      if (this.currentStep == "select_employees") {
        /*  this.setState(this.stepTwoValid,false) */

        this.goForward();
        this.selectedIndex = 1;
      }
      if (this.currentStep == "selected_employees_datils") {
        this.setState(this.stepTwoValid, false);
        this.setState(this.stepThreeValid, false);
        this.goForward();
        this.goForward();
        this.selectedIndex = 2;
      }
    }
  }
  @Output() resfreshView2 = new EventEmitter<any>();
  admin = JSON.parse(this.ls.getItem("is_admin"));
  type = "view";
  lang_key = localStorage.getItem("lang_key") || "en";
  actionbuttons: any = [];
  request: any;
  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public ls: LocalStorage,
    public ds: DataService,
    public lang: LanguagePipe,
    private _sweetAlertService: SweetAlertService,
    private spinner: PreloaderService
  ) {}

  finances = [];

  stepOneValid = new FormControl("", Validators.required);
  stepTwoValid = new FormControl("", Validators.required);
  stepThreeValid = new FormControl("", Validators.required);
  stepFourValid = new FormControl("", Validators.required);
  stepFiveValid = new FormControl("");
  stepSixValid = new FormControl("", Validators.required);

  setState(control: FormControl, state: boolean) {
    if (state) {
      control.setErrors({ required: true });
      control.updateValueAndValidity();
    } else {
      control.clearValidators();

      control.updateValueAndValidity();
    }
  }

  ngOnInit() {
    moment.locale("en");
    this.firstFormGroup = this._formBuilder.group({
      start: [""],
      startAr: [""],
      salary_month: [moment(), Validators.required],
      end: [""],
      endAr: [],
      branch: [],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
    this.loadbranches();
    this.getAllProjects();
    setTimeout(() => {
      this.movesteps();
    }, 200);
  }
  selectionChange(e: any) {
    this.selectedIndex = e.selectedIndex;
  }
  selected;
  isLinear = true;
  makeTooltip(item) {
    let data = `${this.lang.transform("lang_name")}: ${item.name}\n 
    ${this.lang.transform("lang_data")}: ${item.date}\n 
    ${this.lang.transform("lang_the_reason")}: ${item.reason}\n`;
    return data;
  }
  usersList: any[] = [];
  branches = [];
  public loadbranches() {
    this.branches = [];
    this.ds
      .getActionByUrl([], "human_resources/branches/human_resources_view")
      .subscribe((data) => {
        if (data.status) {
          this.branches = data.branches;
        }
      });
  }

  projects = [];
  getAllProjects() {
    /*  this.spinner.show(); */
    this.ds
      .getActionByUrl([], `human_resources/get_project_subjects`)
      .subscribe(
        (res) => {
          if (res?.status) {
            /*  this.spinner.hide(); */
            this.projects = res.records;
          } else {
            /*   this.spinner.hide(); */
            this.projects = [];
          }
        },
        (err) => {
          this.projects = [];
        }
      );
  }

  @Input() requestInfo: any = "";
  currentStep = "";
  persons: any = "";
  accessToSeeThis = false;
  view_request() {
    this.ds
      .getActionByUrl([], `form/FORM_SAL/view_request/${this.form_request_id}`)
      .subscribe(
        (res) => {
          if (res?.status) {
            this.requestInfo = res;
            this.request = res?.transactions_request;
            if (res?.transactions_persons?.status) {
              this.persons = res?.transactions_persons?.records;
            }
            this.actionbuttons = res?.transactions_buttons;
            if (this.requestInfo.form_sal_data.status) {
              this.currentStep =
                this.requestInfo.form_sal_data.records[0].configation_current_step;
            }

            if (
              !this.requestInfo.steps.CONFIGURATION &&
              !this.requestInfo.steps.HUMAN_RESOURCE_MANAGER &&
              !this.requestInfo.steps.REVIEWER &&
              !this.requestInfo.steps.completed &&
              !this.requestInfo.steps.last
            ) {
              this.accessToSeeThis = false;
              /*  this.router.navigate(['/tasks/alltasks'])
            this._sweetAlertService.errorMessage(this.lang.transform('lang_step_permission')) */
            }
            this.accessToSeeThis = true;
            if (this.requestInfo?.last_request_step == "REVIEWER") {
              this.setState(this.stepTwoValid, false);
              this.setState(this.stepThreeValid, false);
              this.setState(this.stepFourValid, false);
              /* this.setState(this.stepFiveValid,false) */
              this.goForward();
              this.goForward();
              this.goForward();
              this.selectedIndex = 3;
              /*   this.goForward() */
            } else if (
              this.requestInfo?.last_request_step == "HUMAN_RESOURCE_MANAGER"
            ) {
              this.setState(this.stepTwoValid, false);
              this.setState(this.stepThreeValid, false);
              this.setState(this.stepFourValid, false);
              this.setState(this.stepFiveValid, false);
              this.goForward();
              this.goForward();
              this.goForward();
              this.goForward();
              this.goForward();
              this.selectedIndex = 4;
            } else if (
              this.requestInfo?.last_request_step == "last" ||
              this.requestInfo?.last_request_step == "completed"
            ) {
              this.setState(this.stepTwoValid, false);
              this.setState(this.stepThreeValid, false);
              this.setState(this.stepFourValid, false);
              this.setState(this.stepFiveValid, false);
              this.setState(this.stepSixValid, false);
              this.goForward();
              this.goForward();
              this.goForward();
              this.goForward();
              this.goForward();
              this.goForward();
              this.selectedIndex = 5;
            } else if (this.requestInfo?.last_request_step == "CONFIGURATION") {
              if (this.currentStep == "select_reviewers") {
                this.setState(this.stepTwoValid, false);
                this.setState(this.stepThreeValid, false);
                this.goForward();
                this.goForward();
                this.goForward();

                /* this.selectedIndex = 3 */
              }

              if (this.currentStep == "select_employees") {
                /*  this.setState(this.stepTwoValid,false) */

                this.goForward();
                this.selectedIndex = 1;
              }

              if (this.currentStep == "selected_employees_datils") {
                this.setState(this.stepTwoValid, false);
                this.setState(this.stepThreeValid, false);
                this.goForward();
                this.goForward();
                this.selectedIndex = 2;
              }
            }
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.projects = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.projects = [];
        }
      );
    this.resfreshView2.emit();
  }
  form__key = "FORM_SAL";
  public preview_form_data() {
    this.ds.preview_pdf_file(
      "form/" + this.form__key + "/pr1/" + this.form_request_id,
      "sal-" + this.form_request_id
    );
  }

  public delete_form_request(form_key, form_request_id) {
    if (form_key && form_request_id && this.admin) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true,
        width: "400px",
        data: {
          title: this.lang.transform("lang_confirmation"),
          message: this.lang.transform("lang_are_you_sure"),
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          let param = new FormData();
          param.append("transaction_request_id", form_request_id);
          this.ds.postActionByUrl(param, "form/" + form_key + "/dr").subscribe(
            (res) => {
              this.spinner.hide();
              if (res.status) {
                this.ds.dialogf("", res.msg);
                this.router.navigate(["transactions/allforms"]);
              } else {
                this.ds.dialogf("", res.error);
              }
            },
            (error) => {
              this.spinner.hide();
              this.ds.dialogf(
                "",
                error && error.error && error.error.error
                  ? error.error.error
                  : this.lang.transform("lang_internal_server_error")
              );
            }
          );
        }
      });
    }
  }

  allSelected = false;
  responseData: any = "";
  usersResponse = [];
  get_salary_form_employees() {
    this.ds
      .getActionByUrl(
        [],
        `form/FORM_SAL/get_salary_form_employees/${this.form_request_id}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.responseData = res;
            this.allSelected = false;

            this.usersResponse = res.records.map((rec) => {
              let action = rec.reviews.map((rev) => {
                return {
                  action: rev.value == "Reject" ? false : true,
                  name: `${rev.firstname_english} ${rev.lastname_english}`,
                  date: rev?.reviewed_time,
                  reason: rev.comment,
                };
              });
              return {
                ...rec,
                details: "",
                select_status: "unselected",
                reviews: action,
                employee_name:
                  rec.firstname_english + " " + rec.lastname_english,
              };
            });
          } else {
            this._sweetAlertService.errorMessage(res?.error);
            this.usersResponse = [];
          }
        },
        (err) => {
          this.usersResponse = [];
          this._sweetAlertService.errorMessage(err?.error.error);
        }
      );
  }

  public able_to_show(user_type) {
    const returndata = this.persons.find(
      (x) =>
        x.transaction_persons_type == user_type &&
        x.transactions_persons_key1 == null &&
        x.transactions_persons_val1 == null
    );
    if (returndata === undefined) {
      return false;
    } else {
      return true;
    }
  }

  openDeduction(info) {
    const dialogRef = this.dialog.open(ShowDeductionsComponent, {
      width: "700px",
      data: {
        info: info,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }
  refreshSubject: Subject<void> = new Subject<void>();
  emitEventToChild() {
    this.refreshSubject.next();
  }

  makeItemCollapsed(index) {
    this.usersList[index].collapsed = !this.usersList[index].collapsed;
  }
  clearAll() {
    this.usersList = [];
  }
  deleteSingle(id: any, index) {
    this.usersList.splice(index, 1);
  }

  searchForm = this._formBuilder.group({
    name: [[]],
  });
  fillFirstFormValue(newItem: any) {
    this.firstFormGroup.patchValue({ ...newItem });
  }

  fillUserListFromStepTwo(newItems: any) {
    this.usersList = newItems;
    // Validate Step 2
    if (this.usersList.length > 0) {
      this.setState(this.stepTwoValid, false);
    } else {
      this.setState(this.stepTwoValid, true);
    }
  }

  usersListReview = [];
  fillUserListFromStepThree(newItems: any) {
    this.usersListReview = newItems;
    // We need to update step 2 list by any update at step 3 :)
    this.fillUserListFromStepTwo(newItems);

    // Validate Step 3
    if (this.usersListReview.length > 0) {
      this.setState(this.stepThreeValid, false);
    } else {
      this.setState(this.stepThreeValid, true);
    }
  }

  checkReviewers() {
    let state = true;
    this.reviewers.forEach((r) => {
      if (r.checked) {
        state = false;
      }
    });
    if (!state) {
      this.setState(this.stepFourValid, false);
    } else {
      this.setState(this.stepFourValid, true);
    }
  }

  fillReviewers(newItems: any) {
    this.reviewers = newItems;
    this.checkReviewers();
  }
}
