<section>
  <div class="row mb-3">
    <div class="col-12 col-sm-9 mb-2">
      <div class="form-group">
        <div style="position: relative">
          <input
            type="text"
            [formControl]="planMonthFilter"
            placeholder="{{ 'lang_project_month' | language }}"
            autocomplete="off"
            class="form-control almnabrformcontrol"
          />
          <span
            style="cursor: pointer; position: absolute; top: 8px; right: 10px"
            (click)="getWafiList()"
            ><i class="fa fa-search mnabricon"></i
          ></span>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-3 mb-2">
      <button class="albutton mnabrbutton" mat-button (click)="route()">
        <i class="fa fa-plus mnabricon mr-1 ml-1" style="color: #fff"></i>
        <span>{{ "lang_add" | language }}</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive dropdownyes customResponsive">
        <table border="1" class="table CustomTable">
          <thead>
            <tr>
              <td>#</td>
              <td>{{ "lang_project_month" | language }}</td>
              <td>{{ "lang_writer" | language }}</td>
              <td>{{ "lang_created_date" | language }}</td>
              <td>{{ "lang_action" | language }}</td>
            </tr>
          </thead>
          <tbody class="AppendList">
            <tr
              *ngFor="
                let list of wafiList;
                let i = index;
                let odd = odd;
                let even = even
              "
              [ngClass]="{ odd: odd, even: even }"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ list.report_date }}</td>
              <td>{{ list.writer }}</td>
              <td>{{ list.created_at }}</td>
              <td>
                <i
                  class="fa fa-eye mnabricon"
                  routerLink="/projects/wafi/{{ list?.wafi_report_id }}"
                  matTooltip="{{ 'lang_view' | language }}"
                ></i>
                <i
                  class="fa fa-pencil-square mnabricon mr-2 ml-2"
                  (click)="onAddEditWafi(createTemp, list)"
                  matTooltip="{{ 'lang_edit' | language }}"
                ></i>
                <i
                  class="fa fa-trash red"
                  (click)="deleteWafi(list)"
                  matTooltip="{{ 'lang_delete' | language }}"
                ></i>
              </td>
            </tr>
            <tr *ngIf="!wafiList?.length">
              <td colspan="5" class="text-center">
                {{ "lang_no_data" | language }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="wafiList.length" class="card-footer padb0 bottomfooter">
        <div class="row responsive_pagination">
          <div
            class="col-lg-3 col-sm-3 mb-10"
            style="height: fit-content; margin: auto 0"
          >
            {{ "lang_total" | language }} : {{ total_records }}
          </div>
          <div
            class="col-lg-5 col-sm-5 mb-10"
            style="display: flex; justify-content: center"
          >
            <ngb-pagination
              class="m-auto"
              [collectionSize]="total_records"
              [rotate]="true"
              [ellipses]="false"
              [maxSize]="3"
              [boundaryLinks]="true"
              [(page)]="wafiPageNo"
              [pageSize]="wafiPageSize"
              (pageChange)="getWafiList()"
            ></ngb-pagination>
          </div>
          <div
            class="col-lg-3 col-sm-3 mb-10"
            style="display: flex; justify-content: end; margin: auto"
          >
            <mat-select
              [(ngModel)]="wafiPageSize"
              (selectionChange)="getWafiList()"
            >
              <mat-option class="text-center" value="10"
                >10 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="20"
                >20 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="50"
                >50 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="100"
                >100 {{ "lang_per_page" | language }}</mat-option
              >
              <mat-option class="text-center" value="500"
                >500 {{ "lang_per_page" | language }}</mat-option
              >
            </mat-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #createTemp>
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_add" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <!-- Modal body -->
    <div class="modal-body">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="report_date">{{
                "lang_project_month" | language
              }}</label>
              <input
                type="text"
                id="report_date"
                name="report_date"
                class="form-control"
                formControlName="report_date"
                placeholder="{{ 'lang_project_month' | language }}"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="report_monthly_spi">{{
                "lang_report_monthly_spi" | language
              }}</label>
              <input
                type="text"
                id="report_monthly_spi"
                name="report_monthly_spi"
                formControlName="report_monthly_spi"
                placeholder="{{ 'lang_report_monthly_spi' | language }}"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="report_monthly_cpi">{{
                "lang_report_monthly_cpi" | language
              }}</label>
              <input
                type="text"
                id="report_monthly_cpi"
                name="report_monthly_cpi"
                formControlName="report_monthly_cpi"
                placeholder="{{ 'lang_report_monthly_cpi' | language }}"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="project_timewise_status">{{
                "lang_project_timewise_status" | language
              }}</label>
              <input
                type="text"
                id="project_timewise_status"
                name="project_timewise_status"
                formControlName="project_timewise_status"
                placeholder="{{ 'lang_project_timewise_status' | language }}"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="project_costwise_status">{{
                "lang_project_costwise_status" | language
              }}</label>
              <input
                type="text"
                id="project_costwise_status"
                name="project_costwise_status"
                formControlName="project_costwise_status"
                placeholder="{{ 'lang_project_costwise_status' | language }}"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="monthly_actual_manpower">{{
                "lang_monthly_actual_manpower" | language
              }}</label>
              <input
                type="text"
                id="monthly_actual_manpower"
                name="monthly_actual_manpower"
                formControlName="monthly_actual_manpower"
                placeholder="{{ 'lang_monthly_actual_manpower' | language }}"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="project_reasons_of_delay">{{
                "lang_project_reasons_of_delay" | language
              }}</label>
              <input
                type="text"
                id="project_reasons_of_delay"
                name="project_reasons_of_delay"
                formControlName="project_reasons_of_delay"
                placeholder="{{ 'lang_project_reasons_of_delay' | language }}"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="monthly_actual_engineers">{{
                "lang_monthly_actual_engineers" | language
              }}</label>
              <input
                type="text"
                id="monthly_actual_engineers"
                name="monthly_actual_engineers"
                formControlName="monthly_actual_engineers"
                placeholder="{{ 'lang_monthly_actual_engineers' | language }}"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="monthly_actual_inspectors">{{
                "lang_monthly_actual_inspectors" | language
              }}</label>
              <input
                type="text"
                id="monthly_actual_inspectors"
                name="monthly_actual_inspectors"
                formControlName="monthly_actual_inspectors"
                placeholder="{{ 'lang_monthly_actual_inspectors' | language }}"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="quality_management_plan_status">{{
                "lang_quality_management_plan_status" | language
              }}</label>
              <ng-select
                [items]="wafiStatusList"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                appendTo="body"
                clearAllText="Clear"
                [searchable]="false"
                placeholder="{{
                  'lang_quality_management_plan_status' | language
                }}"
                formControlName="quality_management_plan_status"
                id="quality_management_plan_status"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="procedures_forms_templates">{{
                "lang_procedures_forms_templates" | language
              }}</label>
              <ng-select
                [items]="wafiStatusList"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                appendTo="body"
                clearAllText="Clear"
                [searchable]="false"
                placeholder="{{ 'lang_procedures_forms_templates' | language }}"
                formControlName="procedures_forms_templates"
                id="procedures_forms_templates"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="report_itp">{{ "lang_report_itp" | language }}</label>
              <ng-select
                [items]="wafiStatusList"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                appendTo="body"
                clearAllText="Clear"
                [searchable]="false"
                placeholder="{{ 'lang_report_itp' | language }}"
                formControlName="report_itp"
                id="report_itp"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="method_statements">{{
                "lang_method_statements" | language
              }}</label>
              <ng-select
                [items]="wafiStatusList"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                appendTo="body"
                clearAllText="Clear"
                [searchable]="false"
                placeholder="{{ 'lang_method_statements' | language }}"
                formControlName="method_statements"
                id="method_statements"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="risk_management_plan_status">{{
                "lang_risk_management_plan_status" | language
              }}</label>
              <ng-select
                [items]="wafiStatusList"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                appendTo="body"
                clearAllText="Clear"
                [searchable]="false"
                placeholder="{{
                  'lang_risk_management_plan_status' | language
                }}"
                formControlName="risk_management_plan_status"
                id="risk_management_plan_status"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="hse_plan_status">{{
                "lang_hse_plan_status" | language
              }}</label>
              <ng-select
                [items]="wafiStatusList"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                appendTo="body"
                clearAllText="Clear"
                [searchable]="false"
                placeholder="{{ 'lang_hse_plan_status' | language }}"
                formControlName="hse_plan_status"
                id="hse_plan_status"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-12 mb-2">
            <div class="form-group">
              <label for="preventive_measures">{{
                "lang_preventive_measures" | language
              }}</label>
              <textarea
                name="preventive_measures"
                id="preventive_measures"
                rows="5"
                formControlName="preventive_measures"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="major_incidents">{{
                "lang_major_incidents" | language
              }}</label>
              <input
                type="text"
                id="major_incidents"
                name="major_incidents"
                formControlName="major_incidents"
                placeholder="{{ 'lang_major_incidents' | language }}"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="lost_time_injuries">{{
                "lang_lost_time_injuries" | language
              }}</label>
              <input
                type="text"
                id="lost_time_injuries"
                name="lost_time_injuries"
                formControlName="lost_time_injuries"
                placeholder="{{ 'lang_lost_time_injuries' | language }}"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="near_miss_reports">{{
                "lang_near_miss_reports" | language
              }}</label>
              <input
                type="text"
                id="near_miss_reports"
                name="near_miss_reports"
                formControlName="near_miss_reports"
                placeholder="{{ 'lang_near_miss_reports' | language }}"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 mb-2">
            <div class="form-group">
              <label for="hse_observations">{{
                "lang_hse_observations" | language
              }}</label>
              <textarea
                name="hse_observations"
                id="hse_observations"
                rows="5"
                formControlName="hse_observations"
                class="form-control"
              ></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer justify-content-between">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRef.hide()"
        style="width: 40%"
      >
        {{ "lang_close" | language }}
      </button>
      <button
        type="button"
        class="albutton mnabrbutton"
        style="width: 40% !important"
        (click)="onSubmit()"
        mat-button
      >
        {{ "lang_submit" | language }}
      </button>
    </div>
  </div>
</ng-template>
