<div class="p-3 mb-3">
	<div class="row">
		<div class="col-md-3 col-sm-4 col-12">
			<mat-form-field appearance="outline" style="width: 100%">
				<mat-label>{{ "lang_log_action" | language }}</mat-label>
				<mat-select
					(selectionChange)="getAccountHistory()"
					[(ngModel)]="log_action"
				>
					<mat-option>{{ "lang_clear" | language }}</mat-option>
					<mat-option
						[value]="item?.value"
						*ngFor="let item of logActionList"
						>{{ item?.label }}</mat-option
					>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-3 col-sm-4 col-12">
			<mat-form-field appearance="outline" style="width: 100%">
				<mat-label
					>{{ "lang_start_date" | language }} -
					{{ "lang_end_date" | language }}</mat-label
				>
				<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
					<input
						matStartDate
						formControlName="log_action_start_date"
						placeholder="{{ 'lang_start_date' | language }}"
						readonly
						(dateChange)="getAccountHistory()"
					/>
					<input
						matEndDate
						formControlName="log_action_end_date"
						placeholder="{{ 'lang_end_date' | language }}"
						readonly
						(dateChange)="getAccountHistory()"
					/>
				</mat-date-range-input>
				<mat-datepicker-toggle
					matIconSuffix
					[for]="picker"
				></mat-datepicker-toggle>
				<mat-date-range-picker #picker></mat-date-range-picker>
			</mat-form-field>
		</div>
		<div
			class="col-md-3 col-sm-4 col-12 d-flex align-items-center"
			*ngIf="
				log_action != null ||
				dateRange.get('log_action_start_date').value ||
				dateRange.get('log_action_end_date').value
			"
		>
			<i
				class="pi pi-refresh mnabricon"
				(click)="resetFilter()"
				matTooltip="{{ 'lang_reset' | language }}"
			></i>
		</div>
	</div>
</div>

<div class="table-responsive customResponsive">
	<table border="1" class="table CustomTable">
		<thead>
			<tr>
				<td>#</td>
				<td>{{ "lang_action" | language }}</td>
				<td>{{ "lang_name" | language }}</td>
				<td>{{ "lang_notes" | language }}</td>
				<td>{{ "lang_notes" | language }}</td>
				<td>{{ "lang_ondate" | language }}</td>
			</tr>
		</thead>
		<tbody *ngIf="history?.length" class="AppendList">
			<tr
				*ngFor="
					let his of history;
					let i = index;
					let odd = odd;
					let even = even
				"
				[ngClass]="{ odd: odd, even: even }"
			>
				<td>{{ i + 1 }}</td>
				<td>{{ his.log_action }}</td>
				<td>{{ his.name }}</td>
				<td>{{ his.description_en }}</td>
				<td>{{ his.description_ar }}</td>
				<td>{{ his.log_action_date }}</td>
			</tr>
		</tbody>
		<tbody *ngIf="!history?.length" class="AppendList">
			<tr class="odd">
				<td colspan="6" class="nodata" align="center">
					{{ "lang_no_data" | language }}
				</td>
			</tr>
		</tbody>
	</table>
	<br />
	<div *ngIf="history?.length" class="card-footer padb0 customborder">
		<div class="row responsive_pagination">
			<div
				class="col-lg-3 col-sm-3 mb-10"
				style="height: fit-content; margin: auto 0"
			>
				{{ "lang_total" | language }} {{ total_records }}
			</div>
			<div
				class="col-lg-6 col-sm-6 mb-10"
				style="display: flex; justify-content: center"
			>
				<ngb-pagination
					class="m-auto"
					[collectionSize]="total_records"
					[rotate]="true"
					[ellipses]="false"
					[maxSize]="3"
					[boundaryLinks]="true"
					[(page)]="pageNo"
					[pageSize]="pageSize"
					(pageChange)="getAccountHistory()"
				></ngb-pagination>
			</div>
			<div
				class="col-lg-3 col-sm-3 mb-10"
				style="display: flex; justify-content: end; margin: auto"
			>
				<mat-select
					[(ngModel)]="pageSize"
					(selectionChange)="getAccountHistory()"
				>
					<mat-option class="text-center" value="10"
						>10 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="20"
						>20 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="50"
						>50 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="100"
						>100 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option class="text-center" value="500"
						>500 {{ "lang_per_page" | language }}</mat-option
					>
				</mat-select>
			</div>
		</div>
	</div>
</div>
