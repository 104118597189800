import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-timeline-details",
  templateUrl: "./timeline-details.component.html",
  styleUrls: ["./timeline-details.component.scss"],
})
export class TimelineDetailsComponent implements OnInit {
  projects_work_area_id: any;
  dataList = null;
  dataList_msg = "";
  page_no = 1;
  page_size = 10;
  apisSubscription = new Subscription();
  lang_key = localStorage.getItem("lang_key") || "en";
  constructor(
    private dialogRef: MatDialogRef<TimelineDetailsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private ds: DataService,
    private spinner: PreloaderService
  ) {
    this.projects_work_area_id = data?.projects_work_area_id;
  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.spinner.show();
    let formData = new FormData();
    formData.append("project_id", this.data?.project_id || "");
    formData.append("unit_id", this.data?.event?.resource[0]);
    formData.append("activity_plan_start_date", this.data?.event?.start);
    formData.append("activity_plan_end_date", this.data?.event?.end);
    this.apisSubscription.add(
      this.ds
        .post(
          `np/reports/get_timeline_counts_details/${this.page_no}/${this.page_size}`,
          formData
        )
        .subscribe(
          (res) => {
            if (res.status) {
              this.spinner.hide();
              this.dataList = res;
            } else {
              this.spinner.hide();
              this.dataList_msg = res?.error;
            }
          },
          (err) => {
            this.spinner.hide();
            this.dataList_msg = err?.error;
          }
        )
    );
  }
  ngOnDestroy() {
    this.apisSubscription.unsubscribe();
  }
}
