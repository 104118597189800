<form [formGroup]="form">
	<div mat-dialog-title>
		<div class="d-flex justify-content-between">
           
             <ng-container >
                <h4>{{ 'lang_add' | language }} {{ 'lang_reviewers' | language }}</h4>
            </ng-container>
		

			<span (click)="close()"><i class="fa fa-times"></i></span>
		</div>
	</div>
	<mat-dialog-content class="mat-typography">
	 
		<form [formGroup]="form">
			<div
			
	 
		>
			<div class="row">
		  

		 
                <div class="col-12 col-sm-12 mb-10">
                    <label class="mb-10">
                        {{ "lang_reviewers" | language }}
                        <span class="red"> * </span> </label>
    
                        <ng-select
                        [items]="users"
                        [multiple]="true"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                         
                        appendTo="body"
                        formControlName="groups"
                        placeholder="{{ 'lang_reviewers' | language }}"
                        [searchFn]="ds.customSearchFn"
						(search)="search_users($event)"
						(focus)="users = []"  
                         
                         
                    >
                    </ng-select>
                    <mat-error *ngIf="
                    form.controls['groups'].touched &&
                    form.controls['groups'].errors
                        ?.required
                      ">
                    {{ "lang_field_required" | language }}
                    </mat-error>
                 
                </div>
                

			


			 
 
				
			</div>
		 
		</div>
		  </form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">

		<div class="col-md-4 col-xs-12">
			<button
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				(click)="closeModal()"
			>
				{{ "lang_close" | language }}
			</button>
		</div>
		<div class="col-md-4 col-xs-12"></div>
		<div class="col-md-4 col-xs-12">
			<button
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				[disabled]="form.invalid"
				(click)="submitForm()"
			>
		 
                 <ng-container >
                   
                    {{ 'lang_save'|language }}
                </ng-container>
            
			</button>
		</div>
		
	</mat-dialog-actions>
</form>
