import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
declare var $:any;
@Component({
  selector: 'app-ct-notes',
  templateUrl: './ct-notes.component.html',
  styleUrls: ['./ct-notes.component.scss']
})
export class CtNotesComponent implements OnInit ,OnDestroy {
  modalRefA: BsModalRef;
  openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  modalRefV: BsModalRef;
  openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  @Input() contractor_id;
  @Input() type;
  @Input() able2add;
  @Input() able2edit;
  @Input() able2delete;
  @Input() contractor_data;
  public noteform: FormGroup;
  mynotes: any = [];
  npsize: any = 10;
  npage: any = 1;
  notedata: any = [];
  noteerrormsg = this.lang.transform('lang_loading');
  noteviewdata: any = [];
  searchKey = "";
  searchStatus = "";
  constructor(public ds: DataService,public router:Router,public route :ActivatedRoute,public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
  ngOnInit() {
    this.buildnoteform();
    this.list_of_notes();
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public emptyNoteForm() {
    this.noteform.reset();
    this.noteform.patchValue({
      note_remainder_status   : 0,
      show_status             : 1,
      link_with_view_list     : 0,
    });
  }
  public buildnoteform() {
    this.noteform = new FormGroup({
      note_id                   : new FormControl(''),
      note_description          : new FormControl('', [Validators.required]),
      note_remainder_status     : new FormControl("0", [Validators.required]),
      note_remainder_date       : new FormControl(''),
      show_status               : new FormControl('1', [Validators.required]),
      link_with_view_list       : new FormControl('0', [Validators.required]),
    });
  }
  public note_operations() {
    this.spinner.show();
    if (this.noteform.get('note_id').value) {
      this.update_notes();
    } else {
      this.create_notes();
    }
  }
  public create_notes() {
    const formData = new FormData();//this.ds.json2formdata(this.noteform.value);
    formData.append('contractor_id', this.contractor_id || '');
    formData.append('note_description', this.noteform.get('note_description').value || '');
    formData.append('note_remainder_status', this.noteform.get('note_remainder_status').value || '0');
    if(this.noteform.get('note_remainder_status').value == "1") {
      formData.append('note_remainder_date', this.noteform.get('note_remainder_date').value);
    }
    formData.append('show_status', this.noteform.get('show_status').value || '1');
    formData.append('link_with_view_list', this.noteform.get('link_with_view_list').value || '0');
    this.subscriptions.add(
   this.ds.postActionByUrl(formData, 'ct/cnotes').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.noteform.reset();
        this.list_of_notes();
        setTimeout(() => {
          this.modalRefA.hide();
        }, 1000);
        this.alert.success(res.msg);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error(error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })    
      )
   
  }
  public update_notes() {
    const formData = new FormData();//this.ds.json2formdata(this.noteform.value);
    formData.append('contractor_id', this.contractor_id || '');
    formData.append('note_id', this.noteform.get('note_id').value || '');
    formData.append('note_description', this.noteform.get('note_description').value || '');
    formData.append('note_remainder_status', this.noteform.get('note_remainder_status').value || '');
    if(this.noteform.get('note_remainder_status').value == "1") {
      formData.append('note_remainder_date', this.noteform.get('note_remainder_date').value || '');
    }
    formData.append('show_status', this.noteform.get('show_status').value || '');
    formData.append('link_with_view_list', this.noteform.get('link_with_view_list').value || '');
    this.subscriptions.add(
        this.ds.putActionByUrl(this.ds.formData2string(formData), 'ct/unotes').subscribe(res => {
            this.spinner.hide();
            if (res.status) {
              this.noteform.reset();
              this.list_of_notes();
              setTimeout(() => {
                this.modalRefA.hide();
              }, 1000);
              this.alert.success(res.msg);
            } else {
              this.alert.error(res.error);
            }
          }, error => {
            this.spinner.hide();
            this.alert.error(error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          })     
      )
   
  }
  public view_my_notes(note) {
    this.noteviewdata = note;
  }
  public get_notes_for_edit(note) {
    this.emptyNoteForm();
    this.noteform.patchValue({
      note_id: note.note_id,
      note_description: note.note_description,
      note_remainder_status: note.note_remainder_status,
      note_remainder_date: this.ds.encon(note.note_remainder_date),
      show_status: note.show_status,
      link_with_view_list: note.link_with_view_list,
    });
  }
  public notepage(size) {
    this.npsize = size;
    this.npage = 1;
    this.list_of_notes();
  }
  public notelength(page) {
    this.npage = page;
    this.list_of_notes();
  }
  public search_my_notes() {
    this.npage = 1;
    this.npsize = 10;
    this.list_of_notes();
  }
  public list_of_notes() {
    let formData = new FormData();
    formData.append('contractor_id', this.contractor_id);
    formData.append('search_key', this.searchKey);
    formData.append('search_status', this.searchStatus);
    this.subscriptions.add(
        this.ds.postActionByUrl(formData, 'ct/notes/' + this.npage + '/' + this.npsize).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.mynotes = res.records;
            this.notedata = res;
          } else {
            this.mynotes = [];
            this.noteerrormsg = res.error;
          }
        }, error => {
          this.spinner.hide();
          this.mynotes = [];
          this.notedata = [];
          this.noteerrormsg = error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
        })    
      )
  
  }
  public checkAllItemsNte(event) {
		if (event.target.checked) {
			$(".notes_panel .eachItemNte").prop("checked", true);
			$(".notes_panel .makeback").addClass("bground");
			$(".notes_panel .deleteIconDiv").show();
		} else {
			$(".notes_panel .eachItemNte").prop("checked", false);
			$(".notes_panel .makeback").removeClass("bground");
			$(".notes_panel .deleteIconDiv").hide();
		}
	}
	public checkEachItemNte(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".notes_panel .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".notes_panel .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".notes_panel .eachItemNte:checked").each(function() {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".notes_panel .eachItemNte").each(function() {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".notes_panel .deleteIconDiv").show();
		} else {
			$(".notes_panel .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".notes_panel #allItemsNte").prop("checked", true);
		} else {
			$(".notes_panel #allItemsNte").prop("checked", false);
		}
	}
	public deleteBulkDataNte() {
		const checkedtotal = [];
		$(".notes_panel .eachItemNte:checked").each(function() {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: '400px',
				data: {
					title: this.lang.transform('lang_confirmation'),
					message: this.lang.transform('lang_are_you_sure'),
				}
			});
      this.subscriptions.add(
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                if (confirmed) {
                  this.spinner.show()
                  this.subscriptions.add(
            this.ds.deleteActionByUrl(checkedtotal, 'ct/sdelete/NOTE/' + this.contractor_id).subscribe(
                          (data) => {
                            this.spinner.hide()
                            if (data.status) {
                              this.list_of_notes();
                              $(".notes_panel #allItemsNte").prop("checked", false);
                              $(".notes_panel .deleteIconDiv").hide();
                              this.ds.dialogf('', data.msg);
                            } else {
                              this.ds.dialogf('', data.error);
                            }
                          },
                          (error) => {
                            this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                            this.spinner.hide()
                          }
                        )
                    )
                 ;
                }
              })      
        )
		;
		} else {
			this.ds.dialogf();
		}
	}
}
