<div class="meeting">
	<div class="back-to-meetings">
		<div class="arrow-btn" (click)="back_to_meetings()">
			<i class="fa fa-long-arrow-left mnabricon"></i>
			<span class="mr-2 ml-2">{{ "lang_meetings" | language }}</span>
		</div>
		<div class="arrow-btn" (click)="go_to_checklist()">
			<span class="mr-2 ml-2">{{ "lang_checklist" | language }}</span>
			<i class="fa fa-long-arrow-right mnabricon"></i>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-sm-6">
			<label class="item-label">{{ "lang_title" | language }}</label>
			<p>{{ meeting?.meeting_title }}</p>
		</div>
		<div class="col-12 col-sm-6">
			<label class="item-label">{{ "lang_date" | language }}</label>
			<p>{{ meeting?.meeting_date_en }}</p>
		</div>
		<div class="col-12">
			<label class="item-label">{{ "lang_description" | language }}</label>
			<div
				[innerHtml]="meeting?.meeting_details"
				*ngIf="meeting?.meeting_details"
			></div>
			<div *ngIf="!meeting?.meeting_details">---</div>
		</div>
		<div class="col-12 mb-2">
			<label class="item-label">{{ "lang_members" | language }}</label>
			<div class="members">
				<div *ngFor="let user of meeting?.users" class="member">
					<img src="{{ user?.avatar }}" alt="avatar" *ngIf="user?.avatar" />
					<img
						src="assets/images/avatar.png"
						alt="avatar"
						*ngIf="!user?.avatar"
					/>
					<span>{{ user.firstname_english }} {{ user.lastname_english }}</span>
				</div>
			</div>
			<div *ngIf="!meeting?.users">---</div>
		</div>
	</div>
</div>
