import { E } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-snag-note-confirm-modal',
  templateUrl: './snag-note-confirm-modal.component.html',
  styleUrls: ['./snag-note-confirm-modal.component.scss']
})
export class SnagNoteConfirmModalComponent implements OnInit {
  form:FormGroup;
  reserveForm:FormGroup;
  projects_profile_id:any;
  projects_work_area_id:any;
  projects_supervision_id:any;
  note_data:any;
  isAction = true;
  constructor(private fb: FormBuilder, public ds: DataService, private sweetAlert: SweetAlertService, private lang: LanguagePipe, 
    private spinner: PreloaderService, private dialogRef: MatDialogRef<SnagNoteConfirmModalComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data) { 
      this.projects_profile_id     = data.projects_profile_id;
      this.projects_work_area_id   = data.projects_work_area_id;
      this.projects_supervision_id = data.projects_supervision_id;
      this.note_data               = data.note;
    }

  ngOnInit(): void {
    if(this.note_data.action_step_code == 'contractor_receiving_waiting' || this.note_data.action_step_code == 'almnabr_team') {
      this.buildReserveForm();
    } else {
      this.buildForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      confirm: ['2', Validators.required],
      rejected_notes: ['']
    });
  }
  buildReserveForm() {
    this.reserveForm = this.fb.group({
      reserveForm: [true, Validators.required]
    });
  }

  onSubmit() {
    if(this.note_data.action_step_code == 'contractor_receiving_waiting' || this.note_data.action_step_code == 'almnabr_team') {
      this.reserveAction();
    } else {
      this.makeAction();
    }
  }
  reserveAction() {
    if(this.reserveForm.valid) {
      let formData = new FormData();
      formData.append('projects_profile_id', this.projects_profile_id);
      formData.append('projects_work_area_id', this.projects_work_area_id);
      this.spinner.show();
      let url = '';
      if(this.note_data?.action_step_code == 'contractor_receiving_waiting') {
        url = 'p/snag/contractor_reservation';
      } else if(this.note_data?.action_step_code == 'almnabr_team') {
        url = 'p/snag/almnabrteam_reservation';
      }
      this.ds.postActionByUrl(formData, url).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.dialogRef.close(true);
          this.sweetAlert.successToast(res?.msg, 2000);
        } else {
          this.sweetAlert.errorToast(res.error, 2000);
        }
      }, error => {
        this.spinner.hide();
        this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
      });
    } else {
      this.sweetAlert.errorToast(this.lang.transform('lang_fill_required_fields'), 2000);
    }
  }
  makeAction() {
    if(this.form.valid) {
      let formData = new FormData();
      formData.append('projects_profile_id', this.projects_profile_id);
      formData.append('projects_work_area_id', this.projects_work_area_id);
      formData.append('projects_supervision_id', this.projects_supervision_id);
      formData.append('note_lists_id', this.note_data.note_lists_id);
      formData.append('team_final_id', this.note_data.team_final_id);
      if(this.note_data.action_step_code == 'De-Snag-M') {
        formData.append('note_operation_id', '0');
        formData.append('action_status_id', '0');
      } else {
        formData.append('note_operation_id', this.note_data.note_operation_id);
        formData.append('action_status_id', this.note_data.action_status_id);
      }
      
      formData.append('action_status', this.form.value.confirm);
      if(this.form.value.confirm == '3') {
        formData.append('request_rejection_note', this.form.value.rejected_notes);
      }
      this.spinner.show();
      this.ds.postActionByUrl(formData, 'p/snag/perform_unit_action').subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.dialogRef.close(true);
          this.sweetAlert.successToast(res?.msg, 2000);
        } else {
          this.sweetAlert.errorToast(res.error, 2000);
        }
      }, error => {
        this.spinner.hide();
        this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
      });
    } else {
      this.sweetAlert.errorToast(this.lang.transform('lang_fill_required_fields'), 2000);
    }
  }
}
