import { Component, Input, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { environment } from "@env/environment";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { DataService } from "@core/bootstrap/data.service";
import { PreloaderService } from "@core";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

@Component({
	selector: "app-wafi-manpower",
	templateUrl: "./wafi-manpower.component.html",
	styleUrls: ["./wafi-manpower.component.scss"],
})
export class WafiManpowerComponent implements OnInit {
	@Input() projects_work_area_id;
	@Input() projects_profile_id;
	@Input() projects_supervision_id;
	modalRef: BsModalRef;
	form: FormGroup;
	other_manpower: FormArray;
	manpowerList = [];
	planMonthFilter = new FormControl("");
	constructor(
		public modalService: BsModalService,
		private fb: FormBuilder,
		private ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.buildForm();
		this.getManpowerList();
	}

	getManpowerList() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("plan_month", this.planMonthFilter.value || "");
		this.spinner.show();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"pr/report/get_manpower_plan"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.manpowerList = res?.record;
					} else {
						this.manpowerList = [];
						this.sweetAlert.errorToast(res?.error, 3000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
	}

	buildForm() {
		this.form = this.fb.group({
			manpower_plan: this.fb.array([], Validators.required),
		});
		this.other_manpower = this.form.get("manpower_plan") as FormArray;
		this.addManpower();
	}

	get manpowerFormGroup() {
		return this.form.get("manpower_plan") as FormArray;
	}

	public manpower_records(value?): FormGroup {
		return this.fb.group({
			project_month: new FormControl(value ? value?.project_month : "", [
				Validators.required,
			]),
			manpower_count: new FormControl(value ? value?.manpower_count : "", [
				Validators.required,
			]),
			engineer_count: new FormControl(value ? value?.engineer_count : "", [
				Validators.required,
			]),
			inspector_count: new FormControl(value ? value?.inspector_count : "", [
				Validators.required,
			]),
		});
	}
	public addManpower(value?) {
		this.other_manpower.push(this.manpower_records(value));
		this.other_manpower.setValidators(Validators.required);
		this.other_manpower.updateValueAndValidity();
	}
	public removeManpower(index) {
		if (this.other_manpower.length == 1) {
			this.other_manpower.removeAt(index);
			this.other_manpower.clearValidators();
			this.other_manpower.updateValueAndValidity();
		} else {
			this.other_manpower.removeAt(index);
		}
	}

	onAddManpower(template) {
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}

	onSubmit() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		this.other_manpower.controls.forEach((element, key) => {
			formData.append(
				`manpower_plan[${key}][project_month]`,
				element?.get("project_month").value || ""
			);
			formData.append(
				`manpower_plan[${key}][manpower_count]`,
				element?.get("manpower_count").value || ""
			);
			formData.append(
				`manpower_plan[${key}][engineer_count]`,
				element?.get("engineer_count").value || ""
			);
			formData.append(
				`manpower_plan[${key}][inspector_count]`,
				element?.get("inspector_count").value || ""
			);
		});
		this.spinner.show();
		this.ds.post("pr/report/add_report_manpower_plan", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
					this.modalRef.hide();
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}

	upload_wafi(e) {
		if (e && e?.length) {
			let formData = new FormData();
			formData.append("supervision_settings_plan_file", e[0] || "");
			formData.append("projects_profile_id", this.projects_profile_id || "");
			formData.append(
				"projects_supervision_id",
				this.projects_supervision_id || ""
			);
			this.spinner.show();
			this.ds.post("pr/report/upload_manpower_plan", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetAlert.successMessage(res?.message);
						this.getManpowerList();
					} else {
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.spinner.hide();
				}
			);
		}
		document.getElementById("uploadWafiInput")["value"] = "";
	}

	download_wafi() {
		this.spinner.show();
		let projects_profile_id = this.projects_profile_id;
		let projects_supervision_id = this.projects_supervision_id;
		this.ds
			.get(
				`pr/report/download_plan_months/${projects_profile_id}/${projects_supervision_id}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetAlert.successMessage(res?.msg);
						this.ds.downloadFile(res, "wafi_manpower");
					} else {
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.sweetAlert.errorMessage(err?.error.error);
					this.spinner.hide();
				}
			);
	}
}
