import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { NgbCalendar, NgbCalendarIslamicUmalqura, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { IslamicI18n } from '@shared/utils/arabicpicker';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-step5',
  templateUrl: './step5.component.html',
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n }
  ]
})
export class Step5Component implements OnInit {
  @Input() regForm: FormGroup;
  @Input() action;
  idfilename: any = this.lang.transform('lang_select_files');
  isFile: boolean = false;
  constructor(public router:Router, public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  get f() {
    return this.regForm.controls.passportDetails;
  }
  ngOnInit() {}
  
  uploadEDUFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.isFile = true;
      this.regForm.get("passportDetails").get("passport_file").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.regForm.get("passportDetails").get("passport_file").setValue([]);
      this.idfilename = this.lang.transform('lang_select_files');
    }
  }
  removeFile() {
    this.isFile = false;
    this.regForm.get("passportDetails").get("passport_file").setValue([]);
    this.idfilename = this.lang.transform('lang_select_files');
  }
  step5Submitted() {
    this.regForm.get('passportDetails').get('passport_number').markAsTouched();
    this.regForm.get('passportDetails').get('passport_number').updateValueAndValidity();
    this.regForm.get('passportDetails').get('passport_issue_date_english').markAsTouched();
    this.regForm.get('passportDetails').get('passport_issue_date_english').updateValueAndValidity();
    this.regForm.get('passportDetails').get('passport_issue_date_arabic').markAsTouched();
    this.regForm.get('passportDetails').get('passport_issue_date_arabic').updateValueAndValidity();
    this.regForm.get('passportDetails').get('passport_expiry_date_english').markAsTouched();
    this.regForm.get('passportDetails').get('passport_expiry_date_english').updateValueAndValidity();
    this.regForm.get('passportDetails').get('passport_expiry_date_arabic').markAsTouched();
    this.regForm.get('passportDetails').get('passport_expiry_date_arabic').updateValueAndValidity();
    this.regForm.get('passportDetails').get('passport_issue_place').markAsTouched();
    this.regForm.get('passportDetails').get('passport_issue_place').updateValueAndValidity();
  }
}
