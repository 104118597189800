import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-formct1-view-aspdf",
	templateUrl: "./formct1-view-aspdf.component.html",
	styleUrls: ["./formct1-view-aspdf.component.scss"],
})
export class Formct1ViewAspdfComponent implements OnInit {
	@Input() formCt1Data;
	lang_key = localStorage.getItem("lang_key") || "en";
	constructor() {}

	ngOnInit(): void {}
}
