<div class="card design_teams">
	<div class="card-header">{{ "lang_team_users" | language }}</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-md-3 col-sm-4 col-xs-12 mb-10">
					<ng-select
						[items]="positions"
						[multiple]="true"
						bindLabel="label"
						appendTo="body"
						bindValue="value"
						clearAllText="Clear"
						[(ngModel)]="positionItems"
						placeholder="{{ 'lang_position' | language }}"
						(change)="load_design_teams()"
					>
					</ng-select>
				</div>
				<div class="col-md-9 col-sm-8 col-xs-12 mb-10">
					<div class="input-group input-group-sm">
						<input
							type="text"
							class="form-control almnabrformcontrol"
							[(ngModel)]="searchKey"
							(keyup)="load_design_teams()"
							placeholder="{{ 'lang_search' | language }}"
						/>
						<span class="input-group-btn">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_add' | language }}"
								class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
								(click)="onAddUpdateTeams()"
							></button>
						</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check_all' | language }}"
										>
											<input
												type="checkbox"
												class="allItemsTeam checkbox"
												id="allItemsTeam"
												(click)="checkAllItemsTeam($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ "lang_title" | language }}</td>
									<td>{{ "lang_position" | language }}</td>
									<td>{{ "lang_role" | language }}</td>
									<td>{{ "lang_writer" | language }}</td>
									<td>{{ "lang_ondate" | language }}</td>
								</tr>
							</thead>
							<tbody *ngIf="tListData?.length != '0'" class="AppendList">
								<tr
									*ngFor="
										let list of tListData?.records;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
									class="makeback removerow{{ list.projects_user_group_id }}"
								>
									<td>{{ i + 1 }}</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check' | language }}"
										>
											<input
												type="checkbox"
												class="eachItemTeam checkbox"
												value="{{ list.projects_user_group_id }}"
												(click)="checkEachItemTeam($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ list.teamtitle }}</td>
									<td *ngIf="list.position">{{ list.position }}</td>
									<td *ngIf="!list.position">----</td>
									<td>
										<span matTooltip="{{ list.teamtitle }}">{{
											list.project_user_group_mention_key
										}}</span>
									</td>
									<td>{{ list.writer }}</td>
									<td>{{ list.project_user_group_created_datetime }}</td>
								</tr>
							</tbody>
							<tbody *ngIf="tListData?.length == '0'" class="AppendList">
								<tr class="odd">
									<td colspan="9" align="center">{{ tnodata }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="tListData?.records?.length > '0'"
			class="padb0 card-footer padb0 ng-star-inserted"
		>
			<div class="row responsive_pagination">
				<div class="deleteIconDiv none col-lg-1 col-sm-1 mb-10">
					<i
						class="fa fa-trash-o faicon red deleteIcon"
						(click)="deleteBulkDataTeam()"
						aria-hidden="true"
						matTooltip="{{ 'lang_delete' | language }}"
					></i>
				</div>
				<div
					class="col-lg-3 col-sm-3 mb-10"
					style="height: fit-content; margin: auto 0"
				>
					{{ "lang_total" | language }} : {{ tListData?.page?.total_records }}
				</div>
				<div
					class="col-lg-5 col-sm-5 mb-10"
					style="display: flex; justify-content: center"
				>
					<ngb-pagination
						class="m-auto"
						[collectionSize]="tListData?.page?.total_records"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="tpageno"
						[pageSize]="tpagesize"
						(pageChange)="load_design_teams()"
					></ngb-pagination>
				</div>
				<div
					class="col-lg-3 col-sm-3"
					style="display: flex; justify-content: end; margin: auto"
				>
					<mat-select
						[(ngModel)]="tpagesize"
						(selectionChange)="load_design_teams()"
					>
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
</div>
