<div class="row my-1 mx-2 mt-1 div-overflow">
  <table class="table table-striped custom-payrole-table">
    <thead>
      <tr>
        <th scope="col">
          <mat-icon
            (click)="clearAll()"
            matTooltip="{{ 'lang_delete_all' | language }}"
            class="delete-all"
          >
            delete
          </mat-icon>
        </th>
        <th scope="col">{{ "lang_human_resources_id_number" | language }}</th>
        <th scope="col">
          {{ "lang_human_resources_employee_name" | language }}
        </th>
        <th scope="col">{{ "lang_total_net_salary" | language }}</th>
        <th scope="col">{{ "lang_other_earnings" | language }}</th>
        <th scope="col">{{ "lang_deductions" | language }}</th>
        <th scope="col">{{ "lang_ratio_month" | language }}</th>
        <th scope="col">{{ "lang_branch" | language }}</th>
        <th scope="col">{{ "lang_action" | language }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of usersList; let i = index">
        <tr>
          <th scope="row">
            <mat-icon
              matTooltip="{{ 'lang_delete' | language }}"
              (click)="deleteTask(item.employee_number)"
            >
              delete
            </mat-icon>
          </th>
          <td
            class="changeColor"
            (click)="employee_details(item.employee_number, i)"
          >
            {{ item.employee_number }}
          </td>
          <td
            class="changeColor"
            (click)="employee_details(item.employee_number, i)"
          >
            {{ item.firstname_english + " " + item.lastname_english }}
          </td>
          <td
            class="changeColor"
            (click)="employee_details(item.employee_number, i)"
          >
            {{ item?.net_salary }}
          </td>
          <td
            class="value changeColor"
            (click)="
              all_financial_transactions(item.employee_number, item, 'earning')
            "
          >
            {{ item?.Other_Earnings }}
          </td>
          <td
            class="value changeColor"
            (click)="
              all_financial_transactions(
                item.employee_number,
                item,
                'deduction'
              )
            "
          >
            {{ item?.Deductions }}
          </td>
          <td
            class="value changeColor"
            (click)="employee_details(item.employee_number, i)"
          >
            {{ item?.ratio_month }}
          </td>
          <td
            class="changeColor"
            (click)="employee_details(item.employee_number, i)"
          >
            {{ item.branch_name_en }}
          </td>
          <!--  <td class="changeColor" (click)="employee_details(item.employee_number,i)">{{item.quotation_subject}}</td> -->
          <!-- <td>
            <div
              matTooltip="{{ item.note }}"
              *ngIf="item.note"
              class="note"
            ></div>
          </td> -->
          <td>
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Actions"
              style="color: black !important"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" style="color: black !important">
              <button (click)="deleteTask(item.employee_number)" mat-menu-item>
                <mat-icon style="color: black !important">delete</mat-icon>
                <span>{{ "lang_delete" | language }}</span>
              </button>
              <!--  <button mat-menu-item disabled>
                            <mat-icon>voicemail</mat-icon>
                            <span>Check voice mail</span>
                          </button>
                          <button mat-menu-item>
                            <mat-icon>notifications_off</mat-icon>
                            <span>Disable alerts</span>
                          </button> -->
            </mat-menu>
          </td>
        </tr>

        <tr *ngIf="item.collapsed">
          <td></td>
          <td colspan="9">
            <div class="width-100 p-1 mt-1">
              <mat-tab-group>
                <mat-tab label="{{ 'lang_financial_details' | language }}">
                  <div class="row my-1 w-100">
                    <div class="col-md-1 d-block col-12 text-start"></div>
                    <div class="col-md-2 d-block col-12 text-start">
                      <label for="">{{ "lang_bank" | language }}</label>
                      <p class="value">
                        {{ item?.details?.bankname }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{ "lang_basic_salary" | language }}</label>
                      <p class="value">
                        {{ item?.details?.basic_salary }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_home_allowances" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.home_allowance }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_other_earnings" | language
                      }}</label>
                      <p
                        class="value changeColor"
                        (click)="
                          all_financial_transactions(
                            item.employee_number,
                            item,
                            'earning'
                          )
                        "
                      >
                        {{ item?.Other_Earnings }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{ "lang_deductions" | language }}</label>
                      <p
                        class="value changeColor"
                        (click)="
                          all_financial_transactions(
                            item.employee_number,
                            item,
                            'deduction'
                          )
                        "
                      >
                        {{ item?.Deductions }}
                      </p>
                    </div>
                    <div class="col-md-1 d-block col-12 text-start"></div>
                    <div class="col-md-1 d-block col-12 text-start"></div>
                    <div class="col-md-2 d-block col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_account_number" | language
                      }}</label>
                      <p class="value">
                        {{ item?.account_number }}
                      </p>
                    </div>
                    <div class="col-md-2 d-block col-12 text-start">
                      <label for="">{{ "lang_net_salary" | language }}</label>
                      <p class="value">
                        {{ item?.net_salary }}
                      </p>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="{{ 'lang_details' | language }}">
                  <div class="row my-1 w-100">
                    <div class="col-md-2 d-block col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_id_expiry_data_english" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.iqama_expiry_date_english }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_copy_number" | language
                      }}</label>
                      <p class="value">{{ item?.details?.copy_number }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_birth_date_english" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.birth_date_english }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_gender" | language
                      }}</label>
                      <p class="value">{{ item?.details?.gender }}</p>
                    </div>
                    <div class="col-md-2 d-block col-12 text-start">
                      <label for="">{{
                        "lang_project_name_en" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.quotation_subject }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_nationality" | language
                      }}</label>
                      <p class="value">{{ item?.details?.nationality }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_job_title_iqama" | language
                      }}</label>
                      <p class="value">{{ item?.details?.job_title_iqama }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{ "lang_username" | language }}</label>
                      <p class="value">{{ item?.details?.username }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_primary_mobile" | language
                      }}</label>
                      <p class="value">{{ item?.details?.primary_mobile }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_primary_email" | language
                      }}</label>
                      <p class="value">{{ item?.details?.primary_email }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_primary_address" | language
                      }}</label>
                      <p class="value">{{ item?.details?.primary_address }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_graduation_year" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.primary_graduation_year }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_graduation" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.primary_education_level }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_passport_number" | language
                      }}</label>
                      <p class="value">{{ item?.details?.passport_number }}</p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_passport_issue_date_english"
                          | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.passport_issue_date_english }}
                      </p>
                    </div>
                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_human_resources_passport_expiry_date_english"
                          | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.passport_expiry_date_english }}
                      </p>
                    </div>

                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_insurance_number" | language
                      }}</label>
                      <p class="value">{{ item?.details?.insurance_number }}</p>
                    </div>

                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_insurance_date" | language
                      }}</label>
                      <p class="value">{{ item?.details?.insurance_date }}</p>
                    </div>

                    <div class="col-md-2 col-sm-4 col-12 text-start">
                      <label for="">{{
                        "lang_insurance_type_class" | language
                      }}</label>
                      <p class="value">
                        {{ item?.details?.insurance_type_class }}
                      </p>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </td>
        </tr>
      </ng-container>

      <tr *ngIf="usersList?.length == 0">
        <th colspan="8">
          <div class="no_data">
            {{ "lang_no_employees" | language }}
          </div>
        </th>
      </tr>
    </tbody>
  </table>
</div>

<div class="row text-center justify-content-between py-3">
  <div class="col-md-4 count_label text-start">
    <label for="">{{ "lang_total" | language }}</label
    >: {{ usersList.length }}
  </div>
  <div class="col-md-7 totals p-1 m-2">
    <table class="table table-bordered custom-payrole-table">
      <thead>
        <tr>
          <th scope="col" colspan="2">
            {{ "lang_financial_request" | language }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row" class="count_label">
            {{ "lang_total_deductions" | language }}
          </td>
          <td>{{ responseData?.deductions_amount }}</td>
        </tr>
        <tr>
          <td scope="row" class="count_label">
            {{ "lang_total_other_earnings" | language }}
          </td>
          <td>{{ responseData?.other_earnings_amount }}</td>
        </tr>
        <tr>
          <td scope="row" class="count_label">
            {{ "lang_total_amount" | language }}
          </td>
          <td>{{ responseData?.total_amount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div>
  <div class="w-100 text-start mt-5">
    <button (click)="goBack()" class="btn mb-2 mx-2 btn-cancel">
      {{ "lang_previous" | language }}
    </button>
    <button
      (click)="update_configuration_step()"
      class="btn mb-2 mx-2 btn-apply"
    >
      {{ "lang_submit_to_reviewers" | language }}
    </button>
  </div>
</div>
