<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>Titles</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-12 col-md-4">
			<input
				type="file"
				#uploadPlatformWlevelsInput
				id="uploadPlatformWlevelsInput"
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
				hidden
				(change)="uploadPlatformWlevels($event.target.files)"
			/>
			<button
				mat-button
				style="
					background-color: rgb(136 224 136 / 42%);
					width: 100%;
					margin-bottom: 5px;
				"
				(click)="uploadPlatformWlevelsInput.click()"
			>
				<i
					class="fa fa-upload"
					style="font-size: 20px; margin: 0 5px; color: rgb(19, 146, 19)"
				></i>
				<span
					style="
						text-transform: uppercase;
						font-weight: 600;
						color: rgb(19, 146, 19);
						font-size: 12px;
					"
					>{{ "lang_upload" | language }}</span
				>
			</button>
		</div>
		<div class="col-12 col-md-4">
			<button
				mat-button
				style="
					background-color: rgb(224 136 200 / 42%);
					width: 100%;
					margin-bottom: 5px;
				"
				(click)="download_titles()"
			>
				<i
					class="fa fa-download"
					style="font-size: 20px; margin: 0 5px; color: rgb(146, 19, 102)"
				></i>
				<span
					style="
						text-transform: uppercase;
						font-weight: 600;
						color: rgb(146, 19, 102);
						font-size: 12px;
					"
					>{{ "lang_download" | language }}</span
				>
			</button>
		</div>
		<div class="col-12 col-md-4">
			<button
				mat-button
				style="
					background-color: rgb(113 166 223 / 42%);
					width: 100%;
					margin-bottom: 5px;
				"
				(click)="getPlatformWlevesHistory()"
			>
				<i
					class="fa fa-download"
					style="font-size: 20px; margin: 0 5px; color: rgb(19, 65, 146)"
				></i>
				<span
					style="
						text-transform: uppercase;
						font-weight: 600;
						color: rgb(19, 65, 146);
						font-size: 12px;
					"
					>{{ "lang_history" | language }}</span
				>
			</button>
		</div>
	</div>

	<div class="row">
		<div class="col-12 mt-3">
			<div
				class="table-responsive dropdownyes customResponsive tablefields"
				*ngIf="history_files?.length"
			>
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<th>#</th>
							<th>{{ "lang_type_code_system" | language }}</th>
							<th>{{ "lang_writer" | language }}</th>
							<th>{{ "lang_created_date" | language }}</th>
							<th>{{ "lang_action" | language }}</th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="
								let item of history_files
									| slice: (page - 1) * size:page * size;
								let i = index;
								let odd = odd;
								let even = even
							"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ item?.type_code_system }}</td>
							<td>{{ item?.writer }}</td>
							<td>{{ item?.created_datetime }}</td>
							<td>
								<i
									class="fa fa-download"
									style="
										font-size: 20px;
										margin: 0 5px;
										color: rgb(19, 65, 146);
									"
									(click)="downloadPlatformWlevelsHistory(item)"
								></i>
							</td>
						</tr>
					</tbody>
				</table>
				<div
				*ngIf="history_files?.length>0"
					class="mt-2"
					style="
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<ngb-pagination
						*ngIf="history_files.length"
						[collectionSize]="history_files.length"
						[(page)]="page"
						[maxSize]="size"
						[boundaryLinks]="true"
					></ngb-pagination>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</mat-dialog-actions>
