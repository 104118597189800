<breadcrumb></breadcrumb>
<section class="panel-expansion" [@fadeIn]>
  <mat-accordion class="stylepanel">
    <mat-expansion-panel class="mpanel" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_approve_transactions" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body communication">
        <div class="mbrl15 fromapprovetransactions">
          <div class="row">
            <div class="col-lg-12 col-sm-12 mb-10">
              <input
                type="text"
                class="form-control almnabrformcontrol searchKey"
                id="searchKey"
                [(ngModel)]="searchKey"
                (keyup)="search_transactions()"
                placeholder="{{ 'lang_search' | language }}"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 mb-10 col-xs-12 mb-10">
              <ng-select
                [items]="formlist"
                [multiple]="false"
                bindLabel="label"
                appendTo="body"
                bindValue="value"
                clearAllText="Clear"
                placeholder="{{ 'lang_search_form' | language }}"
                [(ngModel)]="searchByForm"
                (change)="spinner.show(); search_transactions()"
              >
              </ng-select>
            </div>
            <div class="col-md-3 mb-10 col-xs-12 mb-10">
              <select
                class="form-control almnabrformcontrol"
                [(ngModel)]="searchByTypeOfApproval"
                (change)="spinner.show(); search_transactions()"
              >
                <option value="">{{ "lang_all" | language }}</option>
                <option value="manual">{{ "lang_manual" | language }}</option>
                <option value="auto">{{ "lang_auto" | language }}</option>
              </select>
            </div>
            <div class="col-md-3 mb-10 col-xs-12 mb-10">
              <ng-select
                [items]="modules"
                appendTo="body"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                clearAllText="Clear"
                placeholder="{{ 'lang_search_modules' | language }}"
                [(ngModel)]="searchByModule"
                (change)="spinner.show(); search_transactions()"
              >
              </ng-select>
            </div>
            <div class="col-md-3 mb-10 col-xs-12 check-box">
              <mat-checkbox (change)="get_approve_lists('approval_only')">
                {{ "lang_approval_only" | language }}
              </mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive customResponsive">
                <table border="1" class="table CustomTable">
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>{{ "lang_request_number" | language }}</td>
                      <td>{{ "lang_description" | language }}</td>
                      <td>{{ "lang_from" | language }}</td>
                      <td>{{ "lang_to" | language }}</td>
                      <td>{{ "lang_type" | language }}</td>
                      <td>{{ "lang_module_name" | language }}</td>
                      <td>{{ "lang_forms" | language }}</td>
                      <td>{{ "lang_writer" | language }}</td>
                      <td>{{ "lang_transaction_date" | language }}</td>
                      <td>{{ "lang_approve_type" | language }}</td>
                      <td>{{ "lang_action" | language }}</td>
                    </tr>
                  </thead>
                  <tbody *ngIf="listData.length != '0'" class="AppendList">
                    <tr
                      *ngFor="
                        let list of listData.list.records;
                        let i = index;
                        let odd = odd;
                        let even = even
                      "
                      [ngClass]="{ approval: list?.ready_for_approval == '1' }"
                      class="{{ list.row_color }}{{ list.row_color_flash }}"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ list.transaction_request_id }}</td>
                      <td>{{ list.transaction_request_description }}</td>
                      <td>{{ list.transaction_from_name }}</td>
                      <td>{{ list.transaction_to_name }}</td>
                      <td>{{ list.transactions_type_name }}</td>
                      <td *ngIf="list.module_name">{{ list.module_name }}</td>
                      <td *ngIf="!list.module_name">{{ list.module_key }}</td>
                      <td>{{ list.transactions_name }}</td>
                      <td>{{ list.transaction_request_user_name_writer }}</td>
                      <td>{{ list.create_datetime }}</td>
                      <td>{{ list.transaction_request_type_of_approval }}</td>
                      <td>
                        <mat-icon
                          class="mnabricon"
                          [matMenuTriggerFor]="menu"
                          *ngIf="list?.ready_for_approval == '1'"
                          >settings</mat-icon
                        >
                        <mat-menu #menu="matMenu">
                          <button
                            mat-menu-item
                            (click)="
                              empty_approve_form();
                              transaction_request_id =
                                list.transaction_request_id;
                              transaction_request_description =
                                list.transaction_request_description;
                                transaction_key = list.transaction_key;
                            "
                            data-method="approval"
                            data-toggle="modal"
                            data-target="#verifyPassword"
                            data-backdrop="static"
                            data-keyboard="false"
                            matTooltip="{{ 'lang_approve' | language }}"
                          >
                            {{ "lang_approve" | language }}
                          </button>
                        </mat-menu>
                        <mat-icon
                          routerLink="/transactions/{{ list.url }}"
                          matTooltip="{{ 'lang_view' | language }}"
                          class="mnabricon"
                          >remove_red_eye</mat-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="listData.length == '0'" class="AppendList">
                    <tr class="odd">
                      <td colspan="14" class="nodata" align="center">
                        {{ nodata }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="listData?.list?.records?.length > 0"
          class="card-footer padb0"
        >
          <div class="row responsive_pagination">
            <div
              class="col-lg-3 col-sm-3 mb-10"
              style="height: fit-content; margin: auto 0"
            >
              {{ "lang_total" | language }} :
              {{ listData?.page?.total_records }}
            </div>
            <div
              class="col-lg-6 col-sm-6 mb-10"
              style="display: flex; justify-content: center"
            >
              <!-- <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="10" [checked]="(pagesize =='10') ? true : false" (click)="loadFrmPagesize(10)"> 10</mat-radio-button>
                            <mat-radio-button value="20" [checked]="(pagesize =='20') ? true : false" (click)="loadFrmPagesize(20)"> 20 </mat-radio-button>
                            <mat-radio-button value="50" [checked]="(pagesize =='50') ? true : false" (click)="loadFrmPagesize(50)"> 50 </mat-radio-button>
                            <mat-radio-button value="100" [checked]="(pagesize =='100') ? true : false" (click)="loadFrmPagesize(100)"> 100 </mat-radio-button>
                            <mat-radio-button value="500" [checked]="(pagesize =='500') ? true : false" (click)="loadFrmPagesize(500)"> 500 </mat-radio-button>
                        </mat-radio-group> -->

              <ngb-pagination
                class="m-auto"
                [collectionSize]="listData?.page?.total_records"
                [rotate]="true"
                [ellipses]="false"
                [maxSize]="3"
                [boundaryLinks]="true"
                [(page)]="pageno"
                [pageSize]="pagesize"
                (pageChange)="loadPageFrm($event)"
              ></ngb-pagination>
            </div>
            <div
              class="col-lg-3 col-sm-3"
              style="display: flex; justify-content: end; margin: auto"
            >
              <!--  {{'lang_goto' | language}} :
                        <select class="customselect" (change)="loadPageFrm($event.target.value)">
                            <option *ngFor="let ks of ds.getrange(listData.page.total_pages)" [selected]="(pageno == ks) ? true : false" value="{{ks}}">{{ks}}
                            </option>
                        </select> -->

              <mat-select
                style="max-width: 150px"
                [(ngModel)]="pagesize"
                (selectionChange)="loadFrmPagesize($event.value)"
              >
                <!-- <mat-option value="1">1  </mat-option> -->
                <mat-option class="text-center" value="10"
                  >10 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="20"
                  >20 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="50"
                  >50 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="100"
                  >100 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option class="text-center" value="500"
                  >500 {{ "lang_per_page" | language }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
<div class="modal" id="verifyPassword">
  <div class="modal-dialog">
    <div class="modal-content">
      <form [formGroup]="approve" class="approve" (ngSubmit)="approve_form()">
        <div class="modal-header">
          <h4 class="modal-title">{{ "lang_approve" | language }}</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <p>
            # {{ transaction_request_id }}
            {{ transaction_request_description }}
          </p>
          <div class="form-group">
            <label for="password">{{ "lang_password" | language }}: </label>
            <input
              type="password"
              required
              class="form-control almnabrformcontrol"
              formControlName="user_pass"
            />
            <input
              type="hidden"
              required
              class="form-control almnabrformcontrol"
              formControlName="transaction_request_id"
            />
          </div>
          <div class="row">
            <div class="col-md-4 col-xs-12">
              <button
                type="submit"
                class="albutton mnabrbutton mat-accent"
                mat-button
              >
                {{ "lang_approve" | language }}
              </button>
            </div>
            <div class="col-md-4 col-xs-12">
              <button
                type="button"
                class="albutton mnabrbutton mat-accent"
                mat-button
                data-dismiss="modal"
              >
                {{ "lang_close" | language }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
