 
	<div class="form-wrapper">
		<h3 class="mb-2">{{'lang_edit_bonus'|language}}</h3>
		<mat-divider></mat-divider>

		<form [formGroup]="form" class="form" (ngSubmit)="submitForm()">
		
			<div class="row mb-10">
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_employee_no"|language }}</div>
					<div class="mb-10">
						<b> {{allData?.form_epp_data?.records[0]?.employee_number}} </b>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_name"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.employee_name}} </b
						>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_salary"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.basic_salary}}</b
						>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_current_salary"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.net_amount}}</b
						>
					</div>
				</div>

				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_joining_date"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.joining_start_date_english}}</b
						>
					</div>
				</div>
			</div>

		    <div class="row">
				<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
					<div class="form-group">
						<label> {{'lang_extend_period' | language}} <span class="red">*</span></label>
						<input type="number" 
						(keyup)="calculateEndDate()"
						(keypress)="isNumber($event)"
						
						class="form-control almnabrformcontrol"  
						placeholder="{{'lang_extend_period' | language}}" formControlName="extend_probation_period">
						<mat-error
						*ngIf="
						
						form.controls['extend_probation_period'].errors?.max
						"
					>
						{{ "lang_max_is" | language }} 180
					</mat-error>
					<mat-error
					*ngIf="
					submitted &&
					form.controls['extend_probation_period'].errors?.required
					"
				>
					{{ "lang_field_required" | language }}
				</mat-error>
					</div>
				</div>
				<div class="col-md-6 col-xs-6 col-sm-4 mb-10">
					<label class="mb-10">
						{{ "lang_direct_manager" | language }}
						<span class="red"> * </span> </label>
	
						<ng-select
						[items]="users"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						 
						appendTo="body"
						formControlName="direct_manager"
						placeholder="{{ 'lang_direct_manager' | language }}"
						(search)="search_users($event)"
						
						[searchFn]="ds.customSearchFn"
						 
					>
					</ng-select>
					<mat-error
						*ngIf="
						submitted &&
						form.controls['direct_manager'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				  
				</div>   
				<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
					<div class="form-group">
						<label for="extra_probation_start_date_english">{{'lang_start_date_english' | language }} : <span class="red">*</span></label>
						<div class="positionrelatiove">
							<input formControlName="extra_probation_start_date_english" type="text"  class="form-control almnabrformcontrol" 
							(dateChange)="ds.setENTOARDate($event, form.get('extra_probation_start_date_arabic'));calculateEndDate()" 
							[readonly]="false" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
							<mat-datepicker-toggle matSuffix [for]="BDeng"  class="posiab"></mat-datepicker-toggle>
							<mat-datepicker #BDeng disabled="false"></mat-datepicker>
							<mat-error *ngIf="submitted && form.controls['extra_probation_start_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
						</div>
					   
					   <!--  <div class="positionrelatiove">
						   <custom-arendate-input
							[_dateValue]="form.controls.extra_probation_start_date_english.value"
							[readOnly]="false"
							(dateChange) ="
							setValueToFields($event,
							form.controls.extra_probation_start_date_english,form.controls.extra_probation_start_date_arabic);calculateEndDate()"
							[parentForm]="form"
							 format="YYYY/MM/DD"                        
							 [label]="'YYYY/MM/DD'">
						   </custom-arendate-input>

							<mat-error *ngIf="submitted && form.controls['extra_probation_start_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
						</div> -->
					</div>
				</div>


				<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
					<div class="form-group">
						<label> {{'lang_start_date_arabic' | language}}</label>
						<input type="text" 
						readonly
						
						class="form-control almnabrformcontrol"  
						placeholder="{{'lang_start_date_arabic' | language}}" formControlName="extra_probation_start_date_arabic">
					   
				 
					</div>
				</div>



				<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
					<div class="form-group">
						<label for="extra_probation_end_date_english">{{'lang_end_date_english' | language }} :</label>
						<div class="positionrelatiove">
							<input formControlName="extra_probation_end_date_english" type="text" 
							class="form-control almnabrformcontrol" 
							[min]="form.get('extra_probation_start_date_english').value||min"
							(dateChange)="ds.setENTOARDate($event, form.get('extra_probation_end_date_arabic'));calculatePeriodDate()" 
							[readonly]="false" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng2" />
							<mat-datepicker-toggle matSuffix [for]="BDeng2"  class="posiab"></mat-datepicker-toggle>
							<mat-datepicker #BDeng2 disabled="false"></mat-datepicker>
							<mat-error *ngIf="submitted && form.controls['extra_probation_end_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
						</div>
					   
					   <!--  <div class="positionrelatiove">
						   <custom-arendate-input
							[_dateValue]="form.controls.extra_probation_start_date_english.value"
							[readOnly]="false"
							(dateChange) ="
							setValueToFields($event,
							form.controls.extra_probation_start_date_english,form.controls.extra_probation_start_date_arabic);calculateEndDate()"
							[parentForm]="form"
							 format="YYYY/MM/DD"                        
							 [label]="'YYYY/MM/DD'">
						   </custom-arendate-input>

							<mat-error *ngIf="submitted && form.controls['extra_probation_start_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
						</div> -->
					</div>
				</div>


				<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
					<div class="form-group">
						<label> {{'lang_end_date_arabic' | language}}</label>
						<input type="text" 
						readonly
						
						class="form-control almnabrformcontrol"  
						placeholder="{{'lang_start_date_arabic' | language}}" formControlName="extra_probation_end_date_arabic">
					  
				 
					</div>
				</div>

			  <!--   <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
					<div class="form-group">
						<label for="extra_probation_end_date_english">{{'lang_end_date' | language }} : <span class="red">*</span></label>
						<div class="positionrelatiove">
						   <custom-arendate-input
							[_dateValue]="form.controls.extra_probation_end_date_english.value"
							[readOnly]="true"
							 
							[parentForm]="form"
							format="YYYY/MM/DD"                        
							[label]="'YYYY/MM/DD'">
						   </custom-arendate-input>

							<mat-error *ngIf="submitted && form.controls['extra_probation_end_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
						</div>
					</div>
				</div> -->

			  



			
			</div>
	  
	  
		   
		   
			<div class="row pb-3 pt-3">
				<div class="col-sm-8 col-12"></div>
				<div class="col-sm-4 col-12">
					<button mat-button type="submit" class="mnabrbutton mat-accent test" >
						{{'lang_submit'|language}}
					</button>
				</div>
			</div>
		</form>
	</div>
 






<!-- <div class="row pb-3 pt-3">
	<div class="col-sm-8 col-12"></div>
	<div class="col-sm-4 col-12">
		<button mat-button type="button" class="mnabrbutton mat-accent test" (click)="onSubmit()">
			Submit
		</button>
	</div>
</div> -->