<form class="outgoing" [formGroup]="form" (ngSubmit)="submitForm()">
	<breadcrumb></breadcrumb>
	<section class="panel-expansion">
		<mat-accordion class="stylepanels">
			<mat-expansion-panel class="mpanel" [expanded]="true">
				<mat-expansion-panel-header class="mpanel-header">{{
					"lang_update_outgoing" | language
				}}</mat-expansion-panel-header>
				<div class="mbrl15">
					<div class="row">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<div class="form-group">
								<label>
									{{ "lang_subject" | language }}
									<span class="red">*</span></label
								>
								<input
									type="text"
									formControlName="subject"
									placeholder="{{ 'lang_subject' | language }}"
									class="form-control almnabrformcontrol"
								/>
								<mat-error
									*ngIf="
										form.controls['subject'].touched &&
										form.controls['subject'].errors?.required
									"
								>
									{{ "lang_field_required" | language }}
								</mat-error>
							</div>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<div class="form-group">
								<label> {{ "lang_attachments" | language }}</label>
								<input
									type="text"
									formControlName="attachmentstitle"
									placeholder="{{ 'lang_attachments' | language }}"
									class="form-control almnabrformcontrol"
								/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4 col-sm-4 col-xs-12">
							<div class="form-group">
								<label>
									{{ "lang_languages" | language }}
									<span class="red">*</span></label
								>
								<ng-select
									[items]="languages"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									clearAllText="Clear"
									appendTo="body"
									formControlName="lang_key"
									placeholder="{{ 'lang_languages' | language }}"
									(change)="changeEditorDir($event)"
								>
								</ng-select>
								<mat-error
									*ngIf="
										form.controls['lang_key'].touched &&
										form.controls['lang_key'].errors?.required
									"
								>
									{{ "lang_field_required" | language }}
								</mat-error>
							</div>
						</div>
						<div class="col-md-4 col-sm-4 col-xs-12">
							<div class="form-group">
								<label>
									{{ "lang_from" | language }} <span class="red">*</span></label
								>
								<ng-select
									[items]="branches"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									clearAllText="Clear"
									appendTo="body"
									formControlName="transaction_from"
									placeholder="{{ 'lang_from' | language }}"
								>
								</ng-select>
								<mat-error
									*ngIf="
										form.controls['transaction_from'].touched &&
										form.controls['transaction_from'].errors?.required
									"
								>
									{{ "lang_field_required" | language }}
								</mat-error>
							</div>
						</div>
						<div class="col-md-4 col-sm-4 col-xs-12">
							<div class="form-group">
								<label>
									{{ "lang_to_organization" | language }}
									<span class="red">*</span></label
								>
								<input
									type="text"
									formControlName="transaction_to"
									placeholder="{{ 'lang_to_organization' | language }}"
									class="form-control almnabrformcontrol"
								/>
								<mat-error
									*ngIf="
										form.controls['transaction_to'].touched &&
										form.controls['transaction_to'].errors?.required
									"
								>
									{{ "lang_field_required" | language }}
								</mat-error>
							</div>
						</div>
					</div>
					<div class="row mb-10">
						<div class="col-12">
							<label>
								{{ "lang_description" | language }}
								<span class="red">*</span></label
							>
							<ckeditor
								#editor
								[config]="config"
								formControlName="content"
								[ngClass]="{ ' contentrtl': lang_key == 'ar' }"
							></ckeditor>
							<mat-error
								*ngIf="
									form.controls['content'].touched &&
									form.controls['content'].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="row mb-10">
						<div class="col-md-2 col-sm-3 col-xs-12 mb-10" *ngIf="!markstatus">
							<button
								class="albutton mnabrbutton mat-accent"
								mat-button
								(click)="enable_field('mark')"
							>
								<i class="fa fa-plus"></i> {{ "lang_need_mark" | language }}
							</button>
						</div>
						<div
							class="col-md-2 col-sm-3 col-xs-12 mb-10"
							*ngIf="!reviewstatus"
						>
							<button
								class="albutton mnabrbutton mat-accent"
								mat-button
								(click)="enable_field('review')"
							>
								<i class="fa fa-plus"></i> {{ "lang_need_review" | language }}
							</button>
						</div>
						<div
							class="col-md-2 col-sm-3 col-xs-12 mb-10"
							*ngIf="!attachstatus"
						>
							<button
								class="albutton mnabrbutton mat-accent"
								mat-button
								(click)="enable_field('attachment')"
							>
								<i class="fa fa-plus"></i>
								{{ "lang_need_attachment" | language }}
							</button>
						</div>
					</div>
					<div class="row mb-10">
						<div class="col-12">
							<fieldset class="cardback fieldset">
								<legend>{{ "lang_signature_stamp" | language }}</legend>
								<div class="row">
									<div class="col-md-3 col-sm-3 col-xs-12">
										<div class="form-group">
											<label>
												{{ "lang_human_resources_job_title_iqama" | language }}
												A
											</label>
											<input
												type="text"
												formControlName="signature[A][title]"
												placeholder="{{
													'lang_human_resources_job_title_iqama' | language
												}} A"
												class="form-control almnabrformcontrol"
											/>
											<mat-error
												*ngIf="
													form.controls['signature[A][title]'].touched &&
													form.controls['signature[A][title]'].errors?.required
												"
											>
												{{ "lang_field_required" | language }}
											</mat-error>
										</div>
									</div>
									<div class="col-md-3 col-sm-3 col-xs-12">
										<div class="form-group">
											<label>
												{{ "lang_human_resources_job_title_iqama" | language }}
												B
											</label>
											<input
												type="text"
												formControlName="signature[B][title]"
												placeholder="{{
													'lang_human_resources_job_title_iqama' | language
												}} B"
												class="form-control almnabrformcontrol"
											/>
											<mat-error
												*ngIf="
													form.controls['signature[B][title]'].touched &&
													form.controls['signature[B][title]'].errors?.required
												"
											>
												{{ "lang_field_required" | language }}
											</mat-error>
										</div>
									</div>
									<div class="col-md-3 col-sm-3 col-xs-12"></div>
									<div class="col-md-3 col-sm-3 col-xs-12">
										<div class="form-group">
											<label>
												{{ "lang_human_resources_job_title_iqama" | language }}
												C
											</label>
											<input
												type="text"
												formControlName="signature[C][title]"
												placeholder="{{
													'lang_human_resources_job_title_iqama' | language
												}} C"
												class="form-control almnabrformcontrol"
											/>
											<mat-error
												*ngIf="
													form.controls['signature[C][title]'].touched &&
													form.controls['signature[C][title]'].errors?.required
												"
											>
												{{ "lang_field_required" | language }}
											</mat-error>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3 col-sm-3 col-xs-12">
										<div class="form-group">
											<label>
												{{ "lang_human_resources_employee_name" | language }} A
											</label>
											<ng-select
												[items]="users"
												[multiple]="false"
												bindLabel="label"
												bindValue="value"
												clearAllText="Clear"
												[searchFn]="ds.customSearchFn"
												appendTo="body"
												formControlName="signature[A][user_id]"
												placeholder="{{ 'lang_users' | language }}"
												(search)="search_users($event)"
												(focus)="users = []"
											>
											</ng-select>
											<mat-error
												*ngIf="
													form.controls['signature[A][user_id]'].touched &&
													form.controls['signature[A][user_id]'].errors
														?.required
												"
											>
												{{ "lang_field_required" | language }}
											</mat-error>
										</div>
									</div>
									<div class="col-md-3 col-sm-3 col-xs-12">
										<div class="form-group">
											<label>
												{{ "lang_human_resources_employee_name" | language }} B
											</label>
											<ng-select
												[items]="users"
												[multiple]="false"
												bindLabel="label"
												bindValue="value"
												clearAllText="Clear"
												[searchFn]="ds.customSearchFn"
												appendTo="body"
												formControlName="signature[B][user_id]"
												placeholder="{{ 'lang_users' | language }}"
												(search)="search_users($event)"
												(focus)="users = []"
											>
											</ng-select>
											<mat-error
												*ngIf="
													form.controls['signature[B][user_id]'].touched &&
													form.controls['signature[B][user_id]'].errors
														?.required
												"
											>
												{{ "lang_field_required" | language }}
											</mat-error>
										</div>
									</div>
									<div class="col-md-3 col-sm-3 col-xs-12"></div>
									<div class="col-md-3 col-sm-3 col-xs-12">
										<div class="form-group">
											<label>
												{{ "lang_human_resources_employee_name" | language }} C
											</label>
											<ng-select
												[items]="users"
												[multiple]="false"
												bindLabel="label"
												bindValue="value"
												clearAllText="Clear"
												[searchFn]="ds.customSearchFn"
												appendTo="body"
												formControlName="signature[C][user_id]"
												placeholder="{{ 'lang_users' | language }}"
												(search)="search_users($event)"
												(focus)="users = []"
											>
											</ng-select>
											<mat-error
												*ngIf="
													form.controls['signature[C][user_id]'].touched &&
													form.controls['signature[C][user_id]'].errors
														?.required
												"
											>
												{{ "lang_field_required" | language }}
											</mat-error>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
					<div class="row mb-10" *ngIf="markstatus || reviewstatus">
						<div class="col-md-4 col-xs-12 col-sm-4 mb-10">
							<input type="hidden" formControlName="needMark" />
							<fieldset class="cardback fieldset" *ngIf="markstatus">
								<legend>
									{{ "lang_markers" | language }}
									<span class="red"
										>*
										<mat-icon
											class="red mnabricon"
											matTooltip="{{ 'lang_close' | language }}"
											(click)="disable_field('mark')"
											>close</mat-icon
										></span
									>
								</legend>
								<ng-select
									[items]="users"
									[multiple]="true"
									bindLabel="label"
									bindValue="value"
									clearAllText="Clear"
									[searchFn]="ds.customSearchFn"
									appendTo="body"
									formControlName="marks"
									placeholder="{{ 'lang_users' | language }}"
									(search)="search_users($event)"
									(focus)="users = []"
								>
								</ng-select>
								<mat-error
									*ngIf="
										form.controls['marks'].touched &&
										form.controls['marks'].errors?.required
									"
								>
									{{ "lang_field_required" | language }}
								</mat-error>
							</fieldset>
						</div>
						<div class="col-md-4 col-xs-12 col-sm-4 mb-10">
							<fieldset class="cardback fieldset" *ngIf="reviewstatus">
								<input type="hidden" formControlName="needReview" />
								<legend>
									{{ "lang_reviewers" | language }}
									<span class="red"
										>*
										<mat-icon
											class="red mnabricon"
											matTooltip="{{ 'lang_close' | language }}"
											(click)="disable_field('review')"
											>close</mat-icon
										></span
									>
								</legend>
								<ng-select
									[items]="users"
									[multiple]="true"
									bindLabel="label"
									bindValue="value"
									clearAllText="Clear"
									[searchFn]="ds.customSearchFn"
									appendTo="body"
									formControlName="reviews"
									placeholder="{{ 'lang_users' | language }}"
									(search)="search_users($event)"
									(focus)="users = []"
								>
								</ng-select>
								<mat-error
									*ngIf="
										form.controls['reviews'].touched &&
										form.controls['reviews'].errors?.required
									"
								>
									{{ "lang_field_required" | language }}
								</mat-error>
							</fieldset>
						</div>
					</div>
					<input type="hidden" formControlName="needAttach" />
					<div class="row mb-10" *ngIf="attachstatus">
						<div class="col-md-12 col-sm-12 col-xs-12 mb-10">
							<fieldset class="cardback fieldset">
								<legend>
									{{ "lang_attachments" | language }}
									<mat-icon
										class="red mnabricon"
										(click)="disable_field('attachment')"
										>close</mat-icon
									>
									<span *ngIf="!reuploadstatus">
										<mat-icon
											class="mnabricon"
											(click)="enable_upload_field()"
											matTooltip="{{ 'lang_upload_attachments' | language }}"
											>cloud_upload</mat-icon
										>
									</span>
									<span *ngIf="reuploadstatus">
										<mat-icon
											class="mnabricon"
											(click)="disable_upload_field()"
											matTooltip="{{ 'lang_clear' | language }}"
											>block</mat-icon
										>
									</span>
								</legend>
								<div class="" *ngIf="attachstatus && !reuploadstatus">
									<div class="col-md-12 pad0">
										<div class="table-responsive customResponsive">
											<table border="1" class="table CustomTable">
												<thead>
													<tr>
														<td>#</td>
														<td>{{ "lang_title" | language }}</td>
														<td>{{ "lang_attach_with_pdf" | language }}</td>
														<td>{{ "lang_official_paper" | language }}</td>
														<td>{{ "lang_action" | language }}</td>
													</tr>
												</thead>
												<tbody *ngIf="fileslist.length > 0" class="AppendList">
													<tr
														*ngFor="
															let file of fileslist;
															let i = index;
															let odd = odd;
															let even = even
														"
														[ngClass]="{ odd: odd, even: even }"
													>
														<td>{{ i + 1 }}</td>
														<td>{{ file.form_c1_file_attach_title }}</td>
														<td
															*ngIf="
																file.form_c1_file_attach_with_the_document ==
																'1'
															"
														>
															{{ "lang_yes" | language }}
														</td>
														<td
															*ngIf="
																file.form_c1_file_attach_with_the_document !==
																'1'
															"
														>
															{{ "lang_no" | language }}
														</td>
														<td
															*ngIf="
																file.form_c1_file_print_official_paper == '1'
															"
														>
															{{ "lang_yes" | language }}
														</td>
														<td
															*ngIf="
																file.form_c1_file_print_official_paper !== '1'
															"
														>
															{{ "lang_no" | language }}
														</td>
														<td>
															<i
																class="fa fa-paperclip fa-icon"
																(click)="
																	ds.preview_pdf_file(
																		file.link,
																		file?.form_c1_file_attach_title
																	)
																"
																aria-hidden="true"
															></i>
														</td>
													</tr>
												</tbody>
												<tbody *ngIf="fileslist.length < 1" class="AppendList">
													<tr class="odd">
														<td colspan="5" align="center">
															{{ "lang_no_data" | language }}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div class="attach" *ngIf="attachstatus && reuploadstatus">
									<div class="row">
										<div class="col-2 col-sm-2 col-xs-12">
											<mat-icon
												class="mnabricon"
												matTooltip="{{ 'lang_add' | language }}"
												(click)="addfiles()"
												>add_box</mat-icon
											>
										</div>
									</div>
									<div class="table-responsive customResponsive">
										<table
											border="1"
											class="table CustomTable"
											width="100%"
											formArrayName="attachments"
										>
											<thead>
												<tr>
													<td style="padding: 5px; word-break: break-all">#</td>
													<td style="padding: 5px; word-break: break-all">
														{{ "lang_title" | language }}
													</td>
													<td style="padding: 5px; word-break: break-all">
														{{ "lang_file" | language }}
													</td>
													<td style="padding: 5px; word-break: break-all">
														{{ "lang_attach_with_pdf" | language }}
													</td>
													<td style="padding: 5px; word-break: break-all">
														{{ "lang_official_paper" | language }}
													</td>
													<td style="padding: 5px; word-break: break-all">
														{{ "lang_action" | language }}
													</td>
												</tr>
											</thead>
											<tbody>
												<tr
													*ngFor="
														let file of filesFormGroup.controls;
														let i = index;
														let odd = odd;
														let even = even
													"
													[ngClass]="{ odd: odd, even: even }"
													[formGroupName]="i"
												>
													<td
														class="verticalalignmid"
														style="padding: 5px; word-break: break-all"
													>
														{{ i + 1 }}
													</td>
													<td
														style="padding: 5px; word-break: break-all"
														style="position: relative"
													>
														<input
															type="text"
															formControlName="attach_title"
															placeholder="{{ 'lang_description' | language }}"
															autocomplete="off"
															class="form-control almnabrformcontrol"
														/>
														<mat-error
															*ngIf="
																this.attachments.controls[i].get('attach_title')
																	.touched &&
																this.attachments.controls[i].get('attach_title')
																	.errors?.required
															"
														>
															{{ "lang_field_required" | language }}
														</mat-error>
													</td>
													<td
														class="verticalalignmid"
														style="padding: 5px; word-break: break-all"
														style="position: relative"
													>
														<input
															style="display: block"
															name="file"
															type="file"
															required
															onclick="this.value = null"
															(change)="UploadFile($event, i)"
															class="nghide albutton width100"
														/>
														<label
															id="uploadText{{ i }}"
															class="albutton mnabrbutton mat-accent"
															mat-button
															>{{ filename }}
														</label>
														<mat-error
															*ngIf="
																this.attachments.controls[i].get('file')
																	.touched &&
																this.attachments.controls[i].get('file').errors
																	?.required
															"
														>
															{{ "lang_field_required" | language }}
														</mat-error>
													</td>
													<td
														class="verticalalignmid"
														style="padding: 5px; word-break: break-all"
													>
														<mat-checkbox
															formControlName="attach_with_the_document"
															class="checkbox-radio-button"
														></mat-checkbox>
													</td>
													<td
														class="verticalalignmid"
														style="padding: 5px; word-break: break-all"
													>
														<mat-checkbox
															formControlName="print_official_paper"
															class="checkbox-radio-button"
														></mat-checkbox>
													</td>
													<td class="verticalalignmid">
														<mat-icon
															(click)="removefiles(i)"
															class="mnabricon red"
															matTooltip="{{ 'lang_delete' | language }}"
															>delete_forever</mat-icon
														>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="row">
						<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
							<button
								type="button"
								class="albutton mnabrbutton mat-accent"
								mat-button
								routerLink="/tasks/alltasks"
							>
								{{ "lang_goto_list" | language }}
							</button>
						</div>
						<div class="col-md-8 col-sm-6 col-xs-12 mb-10">
							<app-alert id="default-alert"></app-alert>
						</div>
						<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
							<button
								type="submit"
								class="albutton mnabrbutton mat-accent"
								mat-button
							>
								{{ "lang_submit" | language }}
							</button>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</section>
</form>
