import { Component, Input, OnInit } from "@angular/core";
import { LanguagePipe } from "@shared/pipes/language.pipe";

@Component({
	selector: "app-pq1-steps-history",
	templateUrl: "./pq1-steps-history.component.html",
	styleUrls: ["./pq1-steps-history.component.scss"],
})
export class Pq1StepsHistoryComponent implements OnInit {
	@Input() step_no: number;
	@Input() allData: any;
	configurationData;
	teamsData = [];
	termsData = [];
	lang_key = localStorage.getItem("lang_key") || "en";
	constructor(private lang: LanguagePipe) {
		setTimeout(() => {
			this.getStepsHistory();
		});
	}

	ngOnInit(): void {}

	getStepsHistory() {
		this.configurationData = {
			language:
				this.allData?.form_pq1_data?.records?.lang_key == "ar"
					? this.lang.transform("lang_arabic")
					: this.lang.transform("lang_english"),
			subject: this.allData?.form_pq1_data?.records?.subject,
			care: this.allData?.form_pq1_data?.records?.care,
			transaction_to: this.allData?.form_pq1_data?.records?.transaction_to,
			vat_name: this.allData?.form_pq1_data?.records?.vat_name,
			quotation_types_name:
				this.allData?.form_pq1_data?.records?.quotation_types_name,
			content: this.allData?.form_pq1_data?.records?.content,
			attachmentTitle: this.allData?.form_pq1_data?.records?.attachment,
		};
		this.teamsData = this.allData?.form_pq1_quotation_teams_request?.records;
		this.termsData = this.allData?.terms;
	}
}
