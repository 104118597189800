<breadcrumb></breadcrumb>
<div>
	<!-- *ngIf="wholedata.length != '0'" -->
	<section class="panel-expansion matborderno">
		<div class="row">
			<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/projects/LtYh/{{ project_profile_id }}"
					class="albutton mnabrbutton"
					mat-raised-button
				>
					{{ "lang_project_view" | language }}
				</button>
			</div>
			<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/projects/{{ project_profile_id }}/design/{{
						project_design_id
					}}/dashboard"
					class="albutton mnabrbutton"
					mat-button
				>
					{{ "lang_design_dashboard" | language }}
				</button>
			</div>
			<div class="col-md-1 col-sm-1 col-xs-12 mb-10"></div>
			<div class="col-md-3 col-sm-4 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
		</div>
		<!-- *ngIf="supervision_data.length != '0'" -->
		<mat-accordion>
			<mat-expansion-panel class="mpanel" [expanded]="true">
				<mat-expansion-panel-header>{{
					"lang_design_operation_details" | language
				}}</mat-expansion-panel-header>
				<div class="material-panel-body">
					<div class="mbrl15 martb0">
						<div
							class="row"
							[ngStyle]="
								lang_key == 'ar'
									? {
											'padding-left': '10px',
											'padding-right': '10px'
									  }
									: null
							"
						>
							<div class="col-xs-12 col-12 pad0 suppervision-stepper">
								<div class="wrapper">
									<owl-carousel-o [options]="customOptions" #stepperCar>
										<ng-container>
											<ng-template carouselSlide id="s1">
												<div (click)="moveStepper('contract')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-id-card fa-icon stepper-header-icon stepper-active contract"
															></i>
														</div>
													</div>
													<div matTooltip="Contract" style="text-align: center">
														{{ "lang_contract" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container>
											<ng-template carouselSlide id="s3">
												<div (click)="moveStepper('stages')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-newspaper-o fa-icon stepper-header-icon stages"
																aria-hidden="true"
															></i>
														</div>
													</div>
													<div matTooltip="Stages" style="text-align: center">
														{{ "lang_stages" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container>
											<ng-template carouselSlide id="s4">
												<div (click)="moveStepper('team_users')">
													<div class="step">
														<div class="st">
															<mat-icon
																class="mnabricon stepper-header-icon team_users"
																>supervisor_account</mat-icon
															>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_team_users' | language }}"
														style="text-align: center"
													>
														{{ "lang_team_users" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container>
											<ng-template carouselSlide id="s5">
												<div (click)="moveStepper('communication')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-link fa-icon stepper-header-icon communication"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_communications' | language }}"
														style="text-align: center"
													>
														{{ "lang_communications" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
									</owl-carousel-o>
									<button
										(click)="
											lang_key == 'en' ? stepperCar.prev() : stepperCar.next()
										"
										class="carousel-btn-pre albutton mnabrbutton"
									>
										<i class="fa fa-chevron-left"></i>
									</button>
									<button
										(click)="
											lang_key == 'en' ? stepperCar.next() : stepperCar.prev()
										"
										class="carousel-btn-next albutton mnabrbutton"
									>
										<i class="fa fa-chevron-right"></i>
									</button>
								</div>
								<mat-horizontal-stepper #stepper class="pad0 workarea-stepper">
									<mat-step>
										<app-design-contract
											*ngIf="selectedName == 'contract'"
											[project_data]="wholedata?.data"
											[design_data]="design_data"
											[able2add]="super_add"
											[able2edit]="super_edit"
										>
										</app-design-contract>
									</mat-step>
									<mat-step>
										<app-design-stages
											*ngIf="selectedName == 'stages'"
											[project_data]="wholedata?.data"
											[design_data]="design_data"
											[able2add]="super_add"
											[able2edit]="super_edit"
											[project_profile_id]="project_profile_id"
										></app-design-stages>
									</mat-step>
									<mat-step>
										<app-design-teams
											*ngIf="selectedName == 'team_users'"
											[project_data]="wholedata?.data"
											[design_data]="design_data"
											[able2add]="super_add"
											[able2edit]="super_edit"
											[project_profile_id]="project_profile_id"
										>
										</app-design-teams>
									</mat-step>
									<ng-container>
										<mat-step>
											<app-design-communication
												*ngIf="selectedName == 'communication'"
												[design_data]="design_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-design-communication>
										</mat-step>
									</ng-container>
								</mat-horizontal-stepper>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel class="mt-2">
				<mat-expansion-panel-header>
					{{ "lang_tickets" | language }}
				</mat-expansion-panel-header>
				<div class="material-panel-body">
					<div class="mbrl15 martb0">
						<app-design-tickets
							[contract_id]="design_data?.contract_id"
							[projects_work_area_id]="design_data?.projects_work_area_id"
							*ngIf="design_data?.contract_id"
						></app-design-tickets>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</section>
</div>
