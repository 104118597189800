<section class="panel-expansion">
	<mat-accordion class="stylepanels">
		<mat-expansion-panel class="mpanel" [expanded]="true">
			<mat-expansion-panel-header
				class="mpanel-header"
				*ngIf="!template_edit_data && actionMode != 'VIEW'"
				>{{ "lang_create_formation" | language }}</mat-expansion-panel-header
			>
			<mat-expansion-panel-header
				class="mpanel-header"
				*ngIf="template_edit_data && actionMode != 'VIEW'"
				>{{ "lang_update_formation" | language }}</mat-expansion-panel-header
			>
			<mat-expansion-panel-header
				class="mpanel-header"
				*ngIf="template_edit_data && actionMode == 'VIEW'"
				>{{ "lang_template_details" | language }}</mat-expansion-panel-header
			>
			<div class="mbrl15 martb0">
				<div class="row">
					<div class="col-xs-12 col-12">
						<mat-horizontal-stepper #stepper linear class="pad0">
							<mat-step [stepControl]="step1Validate">
								<ng-template matStepLabel>
									<div class="step">
										<div class="st">
											<i class="icon-5x fa fa-cog fa-icon"></i>
										</div>
									</div>
									<div *ngIf="!template_edit_data && actionMode != 'VIEW'">
										{{ "lang_create_formation" | language }}
									</div>
									<div *ngIf="template_edit_data && actionMode != 'VIEW'">
										{{ "lang_update_formation" | language }}
									</div>
									<div *ngIf="template_edit_data && actionMode == 'VIEW'">
										{{ "lang_template_details" | language }}
									</div>
								</ng-template>
								<br />
								<form [formGroup]="step1form" class="step1form">
									<div class="step1details paddingtop20">
										<div class="row">
											<div class="col-md-3 col-sm-4 col-xs-12">
												<div class="form-group">
													<label for="formation_title_en"
														>{{ "lang_title_english" | language }}:<span
															*ngIf="actionMode != 'VIEW'"
															class="red"
														>
															*
														</span></label
													>
													<input
														type="text"
														class="form-control almnabrformcontrol"
														formControlName="formation_title_en"
													/>
													<mat-error
														*ngIf="
															step1submitted &&
															step1form.controls['formation_title_en'].errors
																?.required
														"
													>
														{{ "lang_field_required" | language }}
													</mat-error>
												</div>
											</div>
											<div class="col-md-3 col-sm-4 col-xs-12">
												<div class="form-group">
													<label for="formation_title_ar"
														>{{ "lang_title_arabic" | language }}:<span
															*ngIf="actionMode != 'VIEW'"
															class="red"
														>
															*
														</span></label
													>
													<input
														type="text"
														class="form-control almnabrformcontrol"
														formControlName="formation_title_ar"
													/>
													<mat-error
														*ngIf="
															step1submitted &&
															step1form.controls['formation_title_ar'].errors
																?.required
														"
													>
														{{ "lang_field_required" | language }}
													</mat-error>
												</div>
											</div>
											<div class="col-md-3 col-sm-4 col-xs-12">
												<div class="form-group">
													<label for="template_type"
														>{{ "lang_template_type" | language }}:
														<span class="red">*</span></label
													>
													<div>
														<select
															class="form-control"
															(change)="set_worl_levels()"
															formControlName="template_type"
														>
															<option value="">
																{{ "lang_choose_options" | language }}
															</option>
															<option value="1">
																{{ "lang_normal" | language }}
															</option>
															<option value="2">
																{{ "lang_private" | language }}
															</option>
														</select>
													</div>
													<mat-error
														*ngIf="
															step1submitted &&
															step1form.controls['template_type'].errors
																?.required
														"
														>{{ "lang_field_required" | language }}</mat-error
													>
												</div>
											</div>

											<div
												class="col-md-3 col-sm-4 col-xs-12"
												*ngIf="step1form.get('template_type').value == '1'"
											>
												<div class="form-group">
													<label for="work_levels"
														>{{ "lang_work_level" | language }}:<span
															*ngIf="actionMode != 'VIEW'"
															class="red"
														>
															*
														</span></label
													>
													<ng-select
														class="DisabledSelectClass"
														[items]="worklevels"
														[multiple]="true"
														clearAllText="Clear"
														appendTo="body"
														[hideSelected]="true"
														formControlName="work_levels"
														placeholder="{{ 'lang_work_level' | language }}"
													>
													</ng-select>
													<mat-error
														*ngIf="
															step1submitted &&
															step1form.controls['work_levels'].errors?.required
														"
													>
														{{ "lang_field_required" | language }}
													</mat-error>
												</div>
											</div>
										</div>
										<div
											class="unitsdata"
											*ngIf="step1form.get('template_type').value == '1'"
										>
											<label>
												{{ "lang_units" | language }}
												<i class="red" *ngIf="actionMode != 'VIEW'">
													*
												</i></label
											>
											<div
												class="table-responsive customResponsive mb-10 tablefields"
											>
												<table border="1" class="table CustomTable text-center">
													<thead>
														<tr>
															<td width="1%">#</td>
															<td width="1%" *ngIf="actionMode != 'VIEW'">
																<i
																	class="fa fa-plus-square fa-icon"
																	matTooltip="{{ 'lang_add' | language }}"
																	(click)="addunit()"
																></i>
															</td>
															<td width="49%">
																{{ "lang_from" | language }}
																<i class="red" *ngIf="actionMode != 'VIEW'">
																	*
																</i>
															</td>
															<td width="49%">
																{{ "lang_to" | language }}
																<i class="red" *ngIf="actionMode != 'VIEW'">
																	*
																</i>
															</td>
														</tr>
													</thead>
													<tbody formArrayName="units" class="AppendList">
														<tr
															*ngFor="
																let item of items_unit().controls;
																let unitIndex = index;
																let odd = odd;
																let even = even
															"
															[formGroupName]="unitIndex"
															style="background: #ffffff"
														>
															<td class="verticalalignmid">
																{{ unitIndex + 1 }}
															</td>
															<td
																class="verticalalignmid"
																*ngIf="actionMode != 'VIEW'"
															>
																<i
																	(click)="removeunit(unitIndex)"
																	class="fa fa-minus-square fa-icon mnabricon red"
																	matTooltip="{{ 'lang_delete' | language }}"
																></i>
															</td>
															<td>
																<ng-select
																	[items]="project_units"
																	[multiple]="false"
																	clearAllText="Clear"
																	bindLabel="label"
																	bindValue="value"
																	appendTo="body"
																	formControlName="unit_from"
																	placeholder="{{ 'lang_from' | language }}"
																	[searchFn]="ds.customSearchFn"
																	(search)="search_project_units($event)"
																	(focus)="project_units = []"
																>
																</ng-select>
																<mat-error
																	*ngIf="
																		step1submitted &&
																		unitvalidate.controls[unitIndex].get(
																			'unit_from'
																		).errors?.required
																	"
																	>{{ "lang_field_required" | language }}
																</mat-error>
															</td>
															<td>
																<ng-select
																	[items]="project_units"
																	[multiple]="false"
																	clearAllText="Clear"
																	bindLabel="label"
																	bindValue="value"
																	appendTo="body"
																	formControlName="unit_to"
																	placeholder="{{ 'lang_to' | language }}"
																	[searchFn]="ds.customSearchFn"
																	(search)="search_project_units($event)"
																	(focus)="project_units = []"
																>
																</ng-select>
																<mat-error
																	*ngIf="
																		step1submitted &&
																		unitvalidate.controls[unitIndex].get(
																			'unit_to'
																		).errors?.required
																	"
																	>{{ "lang_field_required" | language }}
																</mat-error>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="row">
											<div class="col-2 mb-10">
												<input
													type="button"
													(click)="
														showAction.emit({
															mode: 'VIEW',
															data: null,
															template_id: ''
														})
													"
													value="{{ 'lang_go_to_dashboard' | language }}"
													class="albutton"
												/>
											</div>
											<div class="col-8 mb-10">
												<app-alert id="default-alert"></app-alert>
											</div>
											<div class="col-2 mb-10">
												<button
													mat-button
													class="albutton"
													(click)="go_to_step2(stepper); build_edit_data()"
												>
													{{ "lang_next" | language }}
												</button>
											</div>
										</div>
									</div>
								</form>
							</mat-step>

							<mat-step [stepControl]="step2form">
								<ng-template matStepLabel>
									<div
										class="full"
										(click)="go_to_step2(stepper); build_edit_data()"
									>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-cog fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_fill_worklevels" | language }}</div>
									</div>
								</ng-template>
								<br />
								<div class="row">
									<div class="col-12">
										<div
											class="table-responsive customResponsive mb-10 tablefields"
										>
											<table border="1" class="table CustomTable text-center">
												<tbody>
													<tr>
														<td
															class="verticalalignmid tamplateTable"
															width="25%"
														>
															{{
																"lang_projects_total_construction_cost"
																	| language
															}}
														</td>
														<td class="verticalalignmid" width="25%">
															<b>{{
																ds.addCommas(
																	supervision_data.total_construction_cost
																)
															}}</b>
														</td>
														<td
															class="verticalalignmid tamplateTable"
															width="25%"
														>
															{{ "lang_project_main_total_amount" | language }}
														</td>
														<td class="verticalalignmid" width="25%">
															<b>{{ each_unit_cost_of_template }}</b>
														</td>
													</tr>
													<tr>
														<td
															class="verticalalignmid tamplateTable"
															width="25%"
														>
															{{ "lang_selected_units" | language }}
															<i
																class="fa fa-info-circle mnabricon s2icon"
																matTooltip="{{ selectedunitsmsg }}"
															></i>
														</td>
														<td class="verticalalignmid" width="25%">
															<b
																>{{ totalunitspend }} /
																{{ supervision_data.total_no_of_units }}</b
															>
														</td>
														<td
															class="verticalalignmid tamplateTable"
															width="25%"
														>
															{{ "lang_this_template_cost" | language }}
														</td>
														<td
															class="verticalalignmid"
															width="25%"
															*ngIf="actionMode == 'VIEW'"
														>
															<b>{{
																ds.addCommas(
																	template_edit_data.total_cost_of_this_template
																)
															}}</b>
														</td>
														<td
															class="verticalalignmid"
															width="25%"
															*ngIf="!actionMode"
														>
															<b>{{
																ds.addCommas(this_template_total_cost)
															}}</b>
														</td>
														<td
															class="verticalalignmid"
															width="25%"
															*ngIf="
																actionMode &&
																actionMode == 'EDIT' &&
																this_template_total_cost == '0.00'
															"
														>
															<b>{{
																ds.addCommas(
																	template_edit_data.total_cost_of_this_template
																)
															}}</b>
														</td>
														<td
															class="verticalalignmid"
															width="25%"
															*ngIf="
																actionMode &&
																actionMode == 'EDIT' &&
																this_template_total_cost != '0.00'
															"
														>
															<b>{{
																ds.addCommas(this_template_total_cost)
															}}</b>
														</td>
													</tr>
													<tr>
														<td
															class="verticalalignmid tamplateTable"
															width="25%"
														>
															{{ "lang_manual_total_ratio" | language }}
														</td>
														<td class="verticalalignmid" width="25%">
															<b>{{ fmanual_total_ratio }} %</b>
														</td>
														<td
															class="verticalalignmid tamplateTable"
															width="25%"
														>
															{{ "lang_system_total_ratio" | language }}
														</td>
														<td class="verticalalignmid" width="25%">
															<b>{{ fsystem_total_ratio }} %</b>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div class="row" *ngIf="actionMode != 'VIEW'">
									<div class="col-sm-2">
										<i
											class="fa fa-plus-square s2icon mnabricon mb-10"
											matTooltip="{{ 'lang_add_group' | language }} 1"
											(click)="get_extra_group1(addExtraGroup1)"
										></i>
									</div>
								</div>
								<form
									[formGroup]="step2form"
									class="step2form"
									(ngSubmit)="submit()"
								>
									<mat-tab-group
										mat-stretch-tabs
										class="example-stretched-tabs mat-elevation-z4 group1mattab"
										formArrayName="group1Data"
									>
										<mat-tab
											*ngFor="
												let item of items_group1().controls;
												let group1Index = index;
												let odd = odd;
												let even = even
											"
											[formGroupName]="group1Index"
										>
											<ng-template mat-tab-label>
												<span>{{
													group1validate.controls[group1Index] &&
														group1validate.controls[group1Index].get(
															"platform_group1_code_title"
														).value
												}}</span>
												<i
													*ngIf="actionMode != 'VIEW'"
													class="fa fa-trash red s2icon"
													matTooltip="{{ 'lang_delete' | language }}"
													(click)="delete_group1_record(group1Index)"
												></i>
											</ng-template>
											<div class="custom-panel-content">
												<div class="card">
													<div class="width100">
														<div class="card-header">
															<bdi
																innerHtml="{{ 'lang_total_price' | language }} {{
																	group1validate.controls[group1Index].get(
																		'platform_group1_code_title'
																	).value
																}}: {{
																	group1validate.controls[group1Index].get(
																		'platform_group1_code_total'
																	).value
																}}"
															></bdi>
															<span class="float-right"
																><bdi>{{
																	group1validate.controls[group1Index] &&
																		group1validate.controls[group1Index].get(
																			"platform_group1_count"
																		).value
																}}</bdi></span
															>
														</div>
														<div class="card-body padding10">
															<div class="row">
																<div class="col-md-3 col-sm- col-xs-12 mb-10">
																	<label>{{
																		"lang_group1_code_manual" | language
																	}}</label>
																	<input
																		class="form-control almnabrformcontrol"
																		formControlName="platform_group1_code_manual"
																		type="text"
																		placeholder="{{
																			'lang_group1_code_manual' | language
																		}}"
																	/>
																</div>
																<div class="col-md-3 col-sm-6 col-xs-12 mb-10">
																	<label>{{
																		"lang_total_ratio" | language
																	}}</label>
																	<input
																		type="text"
																		class="form-control almnabrformcontrol"
																		formControlName="platform_group1_code_total_ratio"
																		disabled
																		readonly
																	/>
																</div>
															</div>
															<app-types
																[fconsultants]="fconsultants"
																[consultantr]="consultantr"
																[positions]="positions"
																[buildingCodes]="buildingCodes"
																[ncrSiIsssues]="ncrSiIsssues"
																(typeTotalEvent)="get_type_total($event)"
																[actionMode]="actionMode"
																[step1formvalue]="step1form.value"
																[step2form]="step2form"
																[maintotalamount]="maintotalamount"
																[this_template_total_cost]="
																	this_template_total_cost
																"
																[step2formvalue]="step2form.value"
																[weightunits]="weightunits"
																formControlName="typesData"
																[group1Index]="group1Index"
																[project_data]="project_data"
																[template_id]="template_id"
																[supervision_data]="supervision_data"
																[type_data]="
																	group1validate.controls[group1Index].get(
																		'group1TypesData'
																	).value
																"
																[platform_group1_code_system]="
																	group1validate.controls[group1Index].get(
																		'platform_group1_code_system'
																	).value
																"
																[tree_data]="returndata"
																[access_codes]="access_codes"
																(groupTypeEvent)="getTemplateView()"
																(type_total_ratio)="
																	getTypeRatios($event, group1Index)
																"
															></app-types>
														</div>
													</div>
												</div>
											</div>
										</mat-tab>
									</mat-tab-group>
									<br />
									<div class="row">
										<div class="col-2 mb-10">
											<input
												type="button"
												(click)="showAction.emit({ mode: 'VIEW', data: null })"
												value="{{ 'lang_go_to_dashboard' | language }}"
												class="albutton"
											/>
										</div>
										<div class="col-2 mb-10">
											<button mat-button class="albutton" matStepperPrevious>
												{{ "lang_previous" | language }}
											</button>
										</div>
										<div class="col-8 mb-10" *ngIf="actionMode != 'VIEW'">
											<app-alert id="default-alert"></app-alert>
										</div>
									</div>
								</form>
							</mat-step>
						</mat-horizontal-stepper>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</section>
<ng-template #addExtraGroup1>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_add_group" | language }} 1</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefEG1.hide()"
			>
				&times;
			</button>
		</div>
		<div class="modal-body">
			<div class="form-group">
				<div class="row">
					<div class="col-12">
						<label
							>{{ "lang_group" | language }} 1 :
							<span class="red">*</span></label
						>
					</div>
					<div class="col-12">
						<ng-select
							[items]="extragroup1"
							[multiple]="true"
							clearAllText="Clear"
							appendTo="body"
							[hideSelected]="true"
							[(ngModel)]="extragroup1data"
							placeholder="{{ 'lang_group' | language }} 1"
						>
						</ng-select>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer pad15">
			<div class="row">
				<div class="col-md-6 col-xs-12 mb-10">
					<button
						type="button"
						class="albutton"
						(click)="add_extra_group1_items()"
					>
						{{ "lang_add" | language }}
					</button>
				</div>
				<div class="col-md-6 col-xs-12 mb-10">
					<button
						type="button"
						class="btn btn-secondary secondarybutton"
						data-dismiss="modal"
						(click)="modalRefEG1.hide()"
					>
						{{ "lang_close" | language }}
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>
