import {
	Component,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
	selector: "app-supervision-contacts",
	templateUrl: "./supervision-contacts.component.html",
	styleUrls: ["./supervision-contacts.component.scss"],
})
export class SupervisionContactsComponent implements OnInit {
	modalRef: BsModalRef;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}
	@Input() supervision_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() project_data;
	@Input() makeAction;
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	users: any = [];
	positionItems: any = [];
	positions: any = [];
	cpageno = 1;
	cpagesize = "10";
	cListData: any = [];
	cnodata: string = this.lang.transform("lang_loading");
	error_message: string = "";
	error_status: boolean = false;
	form: FormGroup;
	submitted = false;
	viewdata: any = [];
	searchKey = "";
	contactsType = "";
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public alert: AlertService,
		public modalService: BsModalService,
		public dialog: MatDialog
	) {}
	ngOnInit(): void {
		this.buildform();
		this.load_supervision_contacts();
	}
	emptyform() {
		this.form.reset();
		this.alert.clear();
	}
	buildform() {
		this.form = new FormGroup({
			projects_profile_id: new FormControl(""),
			projects_supervision_id: new FormControl(""),
			contacts_id: new FormControl(""),
			contacts_type: new FormControl("", [Validators.required]),
			contacts_name: new FormControl("", [Validators.required]),
			contacts_email: new FormControl("", [Validators.required]),
			contacts_mobile: new FormControl("", [Validators.required]),
			contacts_whatsapp: new FormControl(""),
			contacts_fax: new FormControl(""),
		});
	}
	emptyEditform(list) {
		this.form.reset();
		this.alert.clear();
		this.form.patchValue({
			projects_profile_id: list.projects_profile_id,
			projects_supervision_id: list.projects_supervision_id,
			contacts_id: list.contacts_id,
			contacts_type: list.contacts_type,
			contacts_name: list.contacts_name,
			contacts_email: list.contacts_email,
			contacts_mobile: list.contacts_mobile,
			contacts_whatsapp: list.contacts_whatsapp,
			contacts_fax: list.contacts_fax,
		});
	}
	submit() {
		this.form
			.get("projects_profile_id")
			.setValue(this.supervision_data.projects_profile_id);
		this.form
			.get("projects_supervision_id")
			.setValue(this.supervision_data.projects_supervision_id);
		if (this.form.get("contacts_id").value) {
			this.putdata();
		} else {
			this.postdata();
		}
	}
	private postdata() {
		this.spinner.show();
		this.ds
			.postActionByUrl(
				this.ds.json2formdata(this.form.value),
				"QP36bLsXuVxFDl8"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.alert.success(res.msg);
						this.error_message = res.msg;
						this.form.reset();
						this.load_supervision_contacts();
						setTimeout(() => {
							this.modalRef.hide();
						}, 2000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	private putdata() {
		this.spinner.show();
		this.ds
			.putActionByUrl(
				this.ds.formData2string(this.ds.json2formdata(this.form.value)),
				"75G8Yt3r8grU2WU/" + this.form.get("contacts_id").value
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.alert.success(res.msg);
						this.form.reset();
						this.load_supervision_contacts();
						setTimeout(() => {
							this.modalRef.hide();
						}, 2000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	public search_supervision_contacts() {
		this.cpageno = 1;
		this.cpagesize = "10";
		this.load_supervision_contacts();
	}
	public load_supervision_contacts_page(page) {
		this.spinner.show();
		this.cpageno = page;
		this.cpagesize = this.cpagesize;
		this.load_supervision_contacts();
	}
	public load_supervision_contacts_pagesize(size) {
		this.spinner.show();
		this.cpageno = 1;
		this.cpagesize = size;
		this.load_supervision_contacts();
	}
	load_supervision_contacts() {
		let formdata = new FormData();
		formdata.append("search_key", this.searchKey);
		formdata.append("contacts_type", this.contactsType);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formdata),
				"ckhGSW9rIyAlGX6/" +
					this.project_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id +
					"/" +
					this.cpageno +
					"/" +
					this.cpagesize
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.cListData = res;
					} else {
						this.cListData = [];
						this.cnodata = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.cListData = [];
					this.cnodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}
	checkAllItemsCon(event) {
		if (event.target.checked) {
			$(".supervision_contacts .eachItemCon").prop("checked", true);
			$(".supervision_contacts .makeback").addClass("bground");
			$(".supervision_contacts .deleteIconDiv").show();
		} else {
			$(".supervision_contacts .eachItemCon").prop("checked", false);
			$(".supervision_contacts .makeback").removeClass("bground");
			$(".supervision_contacts .deleteIconDiv").hide();
		}
	}
	checkEachItemCon(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".supervision_contacts .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".supervision_contacts .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".supervision_contacts .eachItemCon:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".supervision_contacts .eachItemCon").each(function () {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".supervision_contacts .deleteIconDiv").show();
		} else {
			$(".supervision_contacts .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".supervision_contacts #AllItemsCon").prop("checked", true);
		} else {
			$(".supervision_contacts #AllItemsCon").prop("checked", false);
		}
	}
	deleteBulkDataCon() {
		const checkedtotal = [];
		$(".supervision_contacts .eachItemCon:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: "400px",
				data: {
					title: this.lang.transform("lang_confirmation"),
					message: this.lang.transform("lang_are_you_sure"),
				},
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show();
					this.ds
						.deleteActionByUrl(
							checkedtotal,
							"xaarfH6cVdFri4q/" +
								this.supervision_data.projects_profile_id +
								"/" +
								this.supervision_data.projects_supervision_id
						)
						.subscribe(
							(data) => {
								this.spinner.hide();
								if (data.status) {
									this.load_supervision_contacts();
									$(".supervision_contacts #AllItemsCon").prop(
										"checked",
										false
									);
									$(".supervision_contacts .deleteIconDiv").hide();
									this.ds.dialogf("", data.msg);
								} else {
									this.ds.dialogf("", data.error);
								}
							},
							(error) => {
								this.ds.dialogf(
									"",
									error && error.error && error.error.error
										? error.error.error
										: this.lang.transform("lang_internal_server_error")
								);
								this.spinner.hide();
							}
						);
				}
			});
		} else {
			this.ds.dialogf();
		}
	}
}
