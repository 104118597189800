<mat-card class="mat-card-custom">
	<div class="form-wrapper">
		<h3 class="mb-2">{{ "lang_edit" | language }}</h3>
		<mat-divider></mat-divider>

		<form [formGroup]="editVacationForm" style="max-width: unset" #vacationForm>
			<div>
				<div class="row pt-3">
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<label class="mb-10"
							>{{ "lang_type" | language }} : <span class="red">*</span></label
						>
						<ng-select
							[items]="vacations"
							[multiple]="false"
							[bindLabel]="lang_key == 'en' ? 'text_en' : 'text_ar'"
							bindValue="value"
							clearAllText="Clear"
							[searchFn]="ds.customSearchFn"
							appendTo="body"
							formControlName="vacation_type_id"
							placeholder="VacationType"
							(change)="checkVacationEmployee(); checkAttachment()"
						>
						</ng-select>
						<mat-error
							*ngIf="
								submitForm &&
								editVacationForm.get('vacation_type_id')?.errors?.required
							"
							>{{ "lang_field_required" | language }}</mat-error
						>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label
								>{{
									"lang_before_vacation_working_date_english" | language
								}}
								:<span class="red">*</span></label
							>
							<div class="input-group input-group-sm">
								<input
									[matDatepicker]="bvwd"
									formControlName="before_vacation_working_date_english"
									(dateChange)="
										ds.setENTOARDate(
											$event,
											editVacationForm.get(
												'before_vacation_working_date_arabic'
											)
										);
										checkVacationEmployee()
									"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									required
									readonly
								/>
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="bvwd"
									></mat-datepicker-toggle>
									<mat-datepicker #bvwd></mat-datepicker>
								</span>
							</div>
							<mat-error
								*ngIf="
									submitForm &&
									editVacationForm.controls[
										'before_vacation_working_date_english'
									]?.errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="bvwda"
								>{{
									"lang_before_vacation_working_date_arabic" | language
								}}
								:<span class="red">*</span></label
							>
							<input
								formControlName="before_vacation_working_date_arabic"
								ngbDatepicker
								[readonly]="true"
								(ngModelChange)="
									ds.setARTOENDate(
										$event,
										editVacationForm.get('before_vacation_working_date_english')
									)
								"
								(click)="idarab.toggle()"
								#idarab="ngbDatepicker"
								required
								id="bvwda"
								type="text"
								class="form-control almnabrformcontrol"
								placeholder="YYYY/MM/DD"
							/>
							<mat-error
								*ngIf="
									editVacationForm.controls[
										'before_vacation_working_date_arabic'
									].touched &&
									editVacationForm.controls[
										'before_vacation_working_date_arabic'
									].errors?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label
								>{{
									"lang_after_vacation_working_date_english" | language
								}}:<span class="red">*</span></label
							>
							<div class="input-group input-group-sm">
								<input
									[matDatepicker]="avwde"
									formControlName="after_vacation_working_date_english"
									(dateChange)="
										ds.setENTOARDate(
											$event,
											editVacationForm.get('after_vacation_working_date_arabic')
										);
										checkVacationEmployee()
									"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									required
									readonly
								/>
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="avwde"
									></mat-datepicker-toggle>
									<mat-datepicker #avwde></mat-datepicker>
								</span>
							</div>
							<mat-error
								*ngIf="
									submitForm &&
									editVacationForm.controls['after_vacation_working']?.errors
										?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="avwda"
								>{{
									"lang_after_vacation_working_date_arabic" | language
								}}
								:<span class="red">*</span></label
							>
							<input
								formControlName="after_vacation_working_date_arabic"
								ngbDatepicker
								[readonly]="true"
								(ngModelChange)="
									ds.setARTOENDate(
										$event,
										editVacationForm.get('after_vacation_working_date_english')
									)
								"
								(click)="avwda.toggle()"
								#avwda="ngbDatepicker"
								required
								id="avwda"
								type="text"
								class="form-control almnabrformcontrol"
								placeholder="YYYY/MM/DD"
							/>
							<mat-error
								*ngIf="
									editVacationForm.controls[
										'after_vacation_working_date_arabic'
									].touched &&
									editVacationForm.controls[
										'after_vacation_working_date_arabic'
									].errors?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div>

					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="showOtherVacation"
					>
						<div class="form-group">
							<label
								>{{ "lang_other_vaction" | language }} :
								<span class="red">*</span> </label
							><br />
							<input
								formControlName="vacation_other"
								id="vacation_other"
								class="form-control almnabrformcontrol"
								placeholder="other vacation"
							/>
							<mat-error
								*ngIf="
									submitForm &&
									editVacationForm.controls['vacation_other']?.errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>

					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid
						"
					>
						<div class="form-group">
							<label>
								{{ "lang_start_date" | language }}
								{{ "lang_english" | language }}:<span class="red"
									>*</span
								></label
							>
							<div class="input-group input-group-sm">
								<input
									[matDatepicker]="vsde"
									formControlName="vacation_start_date_english"
									(dateChange)="
										ds.setENTOARDate(
											$event,
											editVacationForm.get('vacation_start_date_arabic')
										)
									"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									required
									readonly
									disabled
								/>
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="vsde"
									></mat-datepicker-toggle>
									<mat-datepicker #vsde></mat-datepicker>
								</span>
							</div>
							<mat-error
								*ngIf="
									submitForm &&
									editVacationForm.controls['vacation_start_date_english']
										?.errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>

					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid
						"
					>
						<div class="form-group">
							<label for="vsda"
								>{{ "lang_start_date_arabic" | language }} :<span class="red"
									>*</span
								></label
							>
							<input
								formControlName="vacation_start_date_arabic"
								ngbDatepicker
								[readonly]="true"
								disabled
								(ngModelChange)="
									ds.setARTOENDate(
										$event,
										editVacationForm.get('vacation_start_date_english')
									)
								"
								(click)="vsda.toggle()"
								#vsda="ngbDatepicker"
								required
								id="vsda"
								type="text"
								class="form-control almnabrformcontrol"
								placeholder="YYYY/MM/DD"
							/>
							<mat-error
								*ngIf="
									editVacationForm.controls['vacation_start_date_arabic']
										?.touched &&
									editVacationForm.controls['vacation_start_date_arabic']
										?.errors?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div>

					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid
						"
					>
						<div class="form-group">
							<label>
								{{ "lang_end_date_english" | language }} :<span class="red"
									>*</span
								></label
							>
							<div class="input-group input-group-sm">
								<input
									[matDatepicker]="vede"
									formControlName="vacation_end_date_english"
									(dateChange)="
										ds.setENTOARDate(
											$event,
											editVacationForm.get('vacation_end_date_arabic')
										)
									"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									required
									readonly
									disabled
								/>
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="vede"
									></mat-datepicker-toggle>
									<mat-datepicker #vede></mat-datepicker>
								</span>
							</div>
							<mat-error
								*ngIf="
									submitForm &&
									editVacationForm.controls['vacation_end_date_english']?.errors
										?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>

					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid
						"
					>
						<div class="form-group">
							<label for="veda"
								>{{ "lang_end_date_arabic" | language }} :<span class="red"
									>*</span
								></label
							>
							<input
								formControlName="vacation_end_date_arabic"
								ngbDatepicker
								[readonly]="true"
								(ngModelChange)="
									ds.setARTOENDate(
										$event,
										editVacationForm.get('vacation_end_date_english')
									)
								"
								(click)="veda.toggle()"
								#veda="ngbDatepicker"
								required
								disabled
								id="veda"
								type="text"
								class="form-control almnabrformcontrol"
								placeholder="YYYY/MM/DD"
							/>
							<mat-error
								*ngIf="
									editVacationForm.controls['vacation_end_date_arabic']
										.touched &&
									editVacationForm.controls['vacation_end_date_arabic'].errors
										?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div>

					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid
						"
					>
						<div class="form-group">
							<label>
								{{ "lang_total_days" | language }} :
								<span class="red">*</span> </label
							><br />
							<input
								formControlName="vacation_total_days"
								id="vacation_total_days"
								class="form-control almnabrformcontrol"
								placeholder="vacation total days"
								disabled
							/>
						</div>
					</div>
					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['vacation_type_id'].value != '3'
						"
					>
						<div class="form-group">
							<label>
								{{ "lang_total_paid_days" | language }} :
								<span class="red">*</span> </label
							><br />
							<input
								formControlName="vacation_total_paid_days"
								id="vacation_total_paid_days"
								class="form-control almnabrformcontrol"
								placeholder="vacation total paid days"
								disabled
							/>
						</div>
					</div>

					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid
						"
					>
						<div class="form-group">
							<label>
								{{ "lang_total_unpaid_days" | language }}:
								<span class="red">*</span> </label
							><br />
							<input
								formControlName="vacation_total_unpaid_days"
								id="vacation_total_unpaid_days"
								class="form-control almnabrformcontrol"
								placeholder="vacation total unpaid days"
								disabled
							/>
						</div>
					</div>

					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['vacation_type_id'].value != '3'
						"
					>
						<div class="form-group">
							<label>
								{{ "lang_total_paid_amount" | language }} :
								<span class="red">*</span> </label
							><br />
							<input
								formControlName="vacation_total_paid_amount"
								id="vacation_total_paid_amount"
								class="form-control almnabrformcontrol"
								placeholder="vacation total paid amount"
								disabled
							/>
						</div>
					</div>

					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['vacation_type_id'].value == '3'
						"
					>
						<div class="form-group">
							<label>
								{{ "lang_total_paid_75_days_amount" | language }} :
								<span class="red">*</span> </label
							><br />
							<input
								formControlName="paid_days75_amount"
								id="paid_days75_amount"
								class="form-control almnabrformcontrol"
								placeholder="vacation total paid 75 Days amount"
								disabled
							/>
						</div>
					</div>
					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['vacation_type_id'].value == '3'
						"
					>
						<div class="form-group">
							<label>
								{{ "lang_total_paid_100_days_amount" | language }} :
								<span class="red">*</span> </label
							><br />
							<input
								formControlName="paid_days100_amount"
								id="paid_days100_amount"
								class="form-control almnabrformcontrol"
								placeholder="vacation total paid 100 Days amount"
								disabled
							/>
						</div>
					</div>

					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['vacation_type_id'].value == '3'
						"
					>
						<div class="form-group">
							<label>
								{{ "lang_total_paid_75_days" | language }} :
								<span class="red">*</span> </label
							><br />
							<input
								formControlName="paid_days75"
								id="paid_days75"
								class="form-control almnabrformcontrol"
								placeholder="vacation total paid 75 Days"
								disabled
							/>
						</div>
					</div>

					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['vacation_type_id'].value == '3'
						"
					>
						<div class="form-group">
							<label>
								{{ "lang_total_paid_100_days" | language }} :
								<span class="red">*</span> </label
							><br />
							<input
								formControlName="paid_days100"
								id="paid_days100"
								class="form-control almnabrformcontrol"
								placeholder="vacation total paid 100 Days"
								disabled
							/>
						</div>
					</div>
					<div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							editVacationForm.controls['vacation_type_id'].valid &&
							editVacationForm.controls['before_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['after_vacation_working_date_english']
								.valid &&
							editVacationForm.controls['vacation_type_id'].value == '3'
						"
					>
						<div class="form-group">
							<label>
								{{ "lang_sick_balance" | language }} :
								<span class="red">*</span> </label
							><br />
							<input
								formControlName="sick_balance"
								id="sick_balance"
								class="form-control almnabrformcontrol"
								placeholder="Sick Balance"
								disabled
							/>
						</div>
					</div>

					<div class="col-md-4 col-xs-6 col-sm-4 mb-10">
						<label class="mb-10">
							{{ "lang_direct_manager" | language }}
							<span class="red"> * </span>
						</label>

						<ng-select
							[items]="users"
							[multiple]="false"
							bindLabel="label"
							bindValue="value"
							clearAllText="Clear"
							appendTo="body"
							formControlName="direct_manager"
							placeholder="{{ 'lang_direct_manager' | language }}"
							(search)="search_users($event)"
							[searchFn]="ds.customSearchFn"
						>
						</ng-select>
						<mat-error
							*ngIf="
								vacationForm.submitted &&
								vacationForm.controls['direct_manager'].errors?.required
							"
						>
							{{ "lang_field_required" | language }}
						</mat-error>
					</div>
				</div>

				<div class="row">
					<div
						class="col-md-3 col-sm-4 col-xs-12 mb-10"
						*ngIf="!attachstatus && showAttachment"
					>
						<button
							class="albutton mnabrbutton mat-accent"
							mat-button
							(click)="enable_field('attachment')"
						>
							<i class="fa fa-plus"></i> {{ "lang_need_attachment" | language }}
						</button>
					</div>
				</div>

				<input type="hidden" formControlName="needAttach" />
				<div class="row" *ngIf="attachstatus">
					<div class="col-md-12 col-sm-12 col-xs-12 mb-10">
						<fieldset class="cardback fieldset">
							<legend>
								{{ "lang_attachments" | language }}
								<span class="red"
									>*
									<mat-icon
										class="red mnabricon"
										(click)="disable_field('attachment')"
										>close
									</mat-icon>
								</span>
								<mat-icon
									class="mnabricon"
									matTooltip="{{ 'lang_add' | language }}"
									(click)="addfiles()"
									>add_box</mat-icon
								>
							</legend>
							<div class="table-responsive customResponsive">
								<table
									border="1"
									class="table CustomTable"
									width="100%"
									formArrayName="attachments"
								>
									<thead>
										<tr>
											<td style="padding: 5px; word-break: break-all">#</td>
											<td style="padding: 5px; word-break: break-all">
												{{ "lang_title" | language }}
											</td>
											<td style="padding: 5px; word-break: break-all">
												{{ "lang_file" | language }}
											</td>

											<td style="padding: 5px; word-break: break-all">
												{{ "lang_action" | language }}
											</td>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="
												let file of filesFormGroup.controls;
												let i = index;
												let odd = odd;
												let even = even
											"
											[ngClass]="{ odd: odd, even: even }"
											[formGroupName]="i"
										>
											<td
												class="verticalalignmid"
												style="padding: 5px; word-break: break-all"
											>
												{{ i + 1 }}
											</td>
											<td
												style="
													padding: 5px;
													word-break: break-all;
													position: relative;
												"
											>
												<input
													type="text"
													formControlName="attach_title"
													placeholder="{{ 'lang_description' | language }}"
													autocomplete="off"
													class="form-control almnabrformcontrol"
												/>
												<mat-error
													*ngIf="
														submitForm &&
														this.attachments.controls[i].get('attach_title')
															?.errors?.required
													"
													>{{ "lang_field_required" | language }}</mat-error
												>
											</td>
											<td
												class="verticalalignmid"
												style="
													padding: 5px;
													word-break: break-all;
													position: relative;
												"
											>
												<input
													style="display: block"
													name="file"
													type="file"
													required
													onclick="this.value = null"
													(change)="UploadFile($event, i)"
													class="nghide albutton width100"
												/>
												<label
													id="uploadText{{ i }}"
													class="albutton mat-accent"
													mat-button
													>{{ filename }}
												</label>
												<mat-error
													*ngIf="
														submitForm &&
														this.attachments.controls[i].get('file')?.errors
															?.required
													"
												>
													{{ "lang_field_required" | language }}</mat-error
												>
											</td>

											<td class="verticalalignmid">
												<mat-icon
													(click)="removefiles(i)"
													class="mnabricon red"
													matTooltip="{{ 'lang_delete' | language }}"
													>delete_forever
												</mat-icon>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</fieldset>
					</div>
				</div>
				<div class="row mb-2 mt-2" *ngIf="financial_details.length > 0">
					<div class="col-md-12 pad0">
						<div class="table-responsive customResponsive">
							<table
								border="1"
								class="table CustomTable"
								*ngIf="financial_details.length > 0"
							>
								<thead>
									<tr>
										<td>#</td>
										<td>{{ "lang_date" | language }}</td>
										<td>
											{{ "lang_human_resources_vacation_paid_days" | language }}
										</td>
										<td>{{ "lang_finance_unpaid_days" | language }}</td>
										<td>{{ "lang_credit_amount" | language }}</td>
										<!-- <td *ngIf="isSickCase">{{ "lang_paid_days" | language }} 75</td>
										<td *ngIf="isSickCase">{{ "lang_credit_amount" | language }} 25</td>
										<td *ngIf="isSickCase">{{ "lang_credit_amount" | language }} 75</td> -->
										<td>{{ "lang_debit_amount" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="financial_details.length > 0" class="AppendList">
									<tr
										*ngFor="
											let finance of financial_details;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
									>
										<td>{{ i + 1 }}</td>
										<td>
											{{ finance?.finance_month }} / {{ finance?.finance_year }}
										</td>
										<td>{{ finance?.paid_days }}</td>
										<td>{{ finance?.unpaid_days }}</td>
										<td>{{ finance?.credit_amount }}</td>
										<!-- 	<td *ngIf="isSickCase">{{ finance?.paid_days_75 ? finance?.paid_days_75?.toFixed(2) :0}}</td>
										<td *ngIf="isSickCase">{{ finance?.credit_amount_25 ?finance?.credit_amount_25?.toFixed(2):0 }}</td>
										<td *ngIf="isSickCase">{{ finance?.credit_amount_75 ?finance?.credit_amount_75?.toFixed(2):0 }}</td> -->
										<td>{{ finance?.debit_amount }}</td>
										<td>
											<div
												matTooltip="{{ finance.finance_description }}"
												*ngIf="finance.finance_description"
												class="note"
											></div>
										</td>
									</tr>
								</tbody>
							</table>
							<no-data
								style="margin: auto"
								[Opened]="financial_details.length < 1"
							></no-data>
						</div>
					</div>
				</div>

				<div
					class="row mb-2 mt-4 viewquotation stylepanels"
					*ngIf="
						yearContractVacationInfo &&
						this.editVacationForm?.value?.vacation_type_id == 1
					"
				>
					<label>
						{{ "lang_human_resources_vocation_details" | language }} :
					</label>
					<div class="TableParts w-100">
						<div
							class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
						>
							<table class="table CustomTables text-center">
								<tbody class="AppendList quotationtable">
									<tr>
										<td class="text-start">
											{{
												"lang_human_resources_contract_start_date" | language
											}}
											:
										</td>
										<td>
											{{ yearContractVacationInfo?.contract_start_date }}
										</td>

										<td class="text-start">
											{{
												"lang_human_resources_contract_end_date" | language
											}}
											:
										</td>
										<td>
											{{ yearContractVacationInfo?.contract_end_date }}
										</td>

										<td class="text-start">
											{{ "lang_human_resources_contract_warning" | language }} :
										</td>
										<td>
											{{ yearContractVacationInfo?.contract_warning }}
										</td>
									</tr>
									<tr>
										<td class="text-start">
											{{
												"lang_human_resources_vacation_paid_days" | language
											}}
											:
										</td>
										<td>
											{{ yearContractVacationInfo?.contract_yearly_paid_days }}
										</td>

										<td class="text-start">
											{{
												"lang_human_resources_total_active_contract_days"
													| language
											}}
											:
										</td>
										<td>
											{{
												yearContractVacationInfo?.employee_active_contract_total_days
											}}
										</td>

										<td class="text-start">
											{{
												"lang_human_resources_active_contract_worked_day"
													| language
											}}
											:
										</td>
										<td>
											{{
												yearContractVacationInfo?.employee_active_contract_worked_days
											}}
										</td>
									</tr>

									<tr>
										<td class="text-start">
											{{
												"lang_human_resources_all_contract_worked_days"
													| language
											}}
											:
										</td>
										<td>
											{{
												yearContractVacationInfo?.employee_all_contract_worked_days
											}}
										</td>

										<td class="text-start">
											{{
												"lang_human_resources_remaining_working_days" | language
											}}
											:
										</td>
										<td>
											{{
												yearContractVacationInfo?.employee_remaining_working_days
											}}
										</td>

										<td class="text-start">
											{{
												"lang_human_resources_contract_worked_day" | language
											}}
											:
										</td>
										<td>
											{{
												yearContractVacationInfo?.employee_active_contract_worked_days
											}}
										</td>
									</tr>

									<tr>
										<td class="text-start">
											{{ "lang_human_resources_net_amount" | language }} :
										</td>
										<td>
											{{ yearContractVacationInfo?.net_amount }}
										</td>

										<td class="text-start">
											{{ "lang_paid_amount" | language }} :
										</td>
										<td>
											{{ yearContractVacationInfo?.paid_amount }}
										</td>

										<td class="text-start">
											{{ "lang_human_resources_per_day_salary" | language }} :
										</td>
										<td>
											{{ yearContractVacationInfo?.per_day_salary }}
										</td>
									</tr>

									<tr>
										<td class="text-start">
											{{
												"lang_human_resources_probation_warning" | language
											}}
											:
										</td>
										<td>
											{{ yearContractVacationInfo?.probation_warning }}
										</td>

										<td class="text-start">
											{{ "lang_remaining_contract_allowed_days" | language }} :
										</td>
										<td>
											{{
												yearContractVacationInfo?.remaining_contract_allowed_days
											}}
										</td>

										<td class="text-start">
											{{
												"lang_remaining_vocation_days_after_detection"
													| language
											}}
											:
										</td>
										<td>
											{{
												yearContractVacationInfo?.remaining_vocation_days_after_detection
											}}
										</td>
									</tr>

									<tr>
										<td class="text-start">
											{{
												"lang_total_deserved_days_after_detection" | language
											}}
											:
										</td>
										<td>
											{{
												yearContractVacationInfo?.total_deserved_days_after_detection
											}}
										</td>

										<td class="text-start">
											{{ "lang_total_detection_days" | language }} :
										</td>
										<td>
											{{ yearContractVacationInfo?.total_detection_days }}
										</td>

										<td class="text-start">
											{{ "lang_total_unused_days" | language }} :
										</td>
										<td>
											{{ yearContractVacationInfo?.total_unused_days }}
										</td>
									</tr>

									<tr>
										<td class="text-start">
											{{ "lang_total_vacation_days_on_contract" | language }} :
										</td>
										<td>
											{{
												yearContractVacationInfo?.total_vacation_days_on_contract
											}}
										</td>

										<td class="text-start">
											{{ "lang_vacation_deserved_paid_days" | language }} :
										</td>
										<td>
											{{
												yearContractVacationInfo?.vacation_deserved_paid_days
											}}
										</td>

										<td class="text-start">
											{{ "lang_working_days_per_year" | language }} :
										</td>
										<td>
											{{ yearContractVacationInfo?.working_days_per_year }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<app-alert id="default-alert"></app-alert>
				<div class="row pb-3 pt-3">
					<div class="col-sm-8 col-12"></div>
					<div class="col-sm-4 col-12">
						<button
							mat-button
							type="button"
							class="mnabrbutton mat-accent test"
							(click)="onSubmit()"
						>
							{{ "lang_submit" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</mat-card>
