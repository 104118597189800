import { Component, Input, OnInit } from "@angular/core";
import { DataService } from "@core/bootstrap/data.service";
import * as moment from "moment";

@Component({
	selector: "app-task-dates-bar",
	templateUrl: "./task-dates-bar.component.html",
	styleUrls: ["./task-dates-bar.component.scss"],
})
export class TaskDatesBarComponent implements OnInit {
	startDate: any;
	endDate: any;
	todayDate: any;
	completedWidth = 0;
	unCompletedWidth = 0;
	hideToday = false;
	constructor(private ds: DataService) {}

	ngOnInit(): void {
		this.ds.taskDates.subscribe((res) => {
			if (res) {
				this.startDate = res?.startDate;
				this.endDate = res?.endDate;
				this.calcWidth();
			}
		});
	}

	calcWidth() {
		console.log(this.startDate);
		console.log(this.endDate);

		let start = moment(new Date(this.startDate));
		let end = moment(new Date(this.endDate));
		console.log(start);
		console.log(end);

		let start_end_diff = end.diff(start, "days");
		let today: any = moment(new Date());
		this.todayDate = today.format("YYYY-MM-DD");
		let start_today_diff = today.diff(start, "days");
		console.log(start_end_diff);
		console.log(start_today_diff);
		if (start_today_diff < 0) {
			this.hideToday = true;
		} else {
			this.hideToday = false;
		}
		if (start_end_diff <= 0) {
			this.completedWidth = 0;
		} else {
			this.completedWidth = Math.round(
				(start_today_diff / start_end_diff) * 100
			);
		}
		this.updateStyle();
	}

	updateStyle() {
		document.getElementById(
			"start_date_bar"
		).style.width = `${this.completedWidth}px`;
		document.getElementById("today_line").style.left = `${
			this.completedWidth + 2
		}px`;
	}
}
