<div class="p-2">
	<div class="row">
		<div class="col-12 mb-3">
			<div class="d-flex justify-content-end">
				<button class="add-attachment-btn" (click)="onAddUpdateSubTitle()">
					<i class="pi pi-plus"></i>
					<span>{{ "lang_add" | language }}</span>
				</button>
			</div>
		</div>
		<div class="col-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>Title English</td>
							<td>Title Arabic</td>
							<td>Date</td>
							<td>Writer</td>
							<td>Action</td>
						</tr>
					</thead>
					<tbody *ngIf="subTitlesList?.length" class="AppendList">
						<ng-container
							*ngFor="
								let list of subTitlesList;
								let subtitle_index = index;
								let odd = odd;
								let even = even
							"
						>
							<tr>
								<td>{{ subtitle_index + 1 }}</td>
								<td>{{ list.sub_title_english }}</td>
								<td>{{ list.sub_title_arabic }}</td>
								<td>{{ list.sub_title_createdatetime }}</td>
								<td>{{ list.writer }}</td>
								<td>
									<i
										class="fa fa-trash red mr-1 ml-1"
										(click)="deleteSubtitle(list.sub_title_id)"
										matTooltip="{{ 'lang_delete' | language }}"
										*ngIf="makeActionConfig"
									></i>
									<i
										class="fa fa-pencil-square-o mnabricon mr-1 ml-1"
										(click)="onAddUpdateSubTitle(list)"
										matTooltip="{{ 'lang_edit' | language }}"
										*ngIf="makeActionConfig"
									></i>
									<i
										class="fa fa-gear mnabricon mr-1 ml-1"
										[matMenuTriggerFor]="menu"
									></i>
									<mat-menu #menu="matMenu">
										<button
											mat-menu-item
											(click)="onAddPlatformLevels(list?.sub_title_id)"
											*ngIf="makeActionConfig"
										>
											<div
												class="d-flex justify-content-around align-items-center"
											>
												<span>Add Work Levels</span>
												<i class="fa fa-plus mnabricon"></i>
											</div>
										</button>
										<button
											mat-menu-item
											(click)="onShowPlatformLevels(list?.sub_title_id)"
										>
											<div
												class="d-flex justify-content-around align-items-center"
											>
												<span>Worklevels</span>
												<i class="fa fa-list mnabricon"></i>
											</div>
										</button>
									</mat-menu>
								</td>
							</tr>
						</ng-container>
					</tbody>
					<tbody class="AppendList" *ngIf="!subTitlesList?.length">
						<tr>
							<td colspan="6">{{ "lang_no_data" | language }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
