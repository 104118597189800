import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  @ViewChild('sPad', { static: false }) signaturePadElement;
  @ViewChild('mPad', { static: false }) markPadElement;
  @ViewChild('padElement', { static: false }) padElement: any;
  width: any = 900;
  height: any = 450;
  signaturePad: any;
  markPad: any;
  empno = this.route.snapshot.paramMap.get('empno');
  id = this.route.snapshot.paramMap.get('id');
  token = this.route.snapshot.paramMap.get('token');
  tokenstatus = false;
  codemsg = this.lang.transform('lang_send_code');
  signinform: FormGroup;
  errormsg = '';
  errorstatus: boolean = false;
  sendstatus: boolean = false;
  apistatus: boolean = false;
  data: any;
  show_login_page: boolean = false;
  sendcodestatus: boolean = false;
  constructor(private cdRef: ChangeDetectorRef, public ds: DataService, public route:ActivatedRoute ,public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {
    this.signin_form();
    this.valid_token();
  }
  @HostListener('window:resize', ['$event'])
  canvassize(event?) {
    this.width = this.padElement.nativeElement.offsetWidth;
    this.height = this.padElement.nativeElement.offsetHeight;
    this.cdRef.detectChanges();
  }
  ngAfterViewInit(): void {
    this.canvassize();
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
      minWidth: 5,
      maxWidth: 8,
      penColor: "rgb(63, 81, 181)",
      backgroundColor: 'rgba(255,255,255, 0)'
    });
    this.markPad = new SignaturePad(this.markPadElement.nativeElement, {
      minWidth: 5,
      maxWidth: 8,
      penColor: "rgb(63, 81, 181)",
      backgroundColor: 'rgba(255,255,255, 0)'
    });
    this.cdRef.detectChanges();
  }
  valid_token() {
    this.spinner.show();
    this.ds.getActionByNoToken([], 'signup/valid_token/' + this.empno + '/' + this.id + '/'+ this.token).subscribe(result => {
      this.spinner.hide();
      if (result.status) {
        this.tokenstatus = true;
        this.get_verify_options();
      } else {
        this.tokenstatus = false;
        this.router.navigate(['']);
        this.ds.dialogf('',result.error);
      }
    }, error => {
      this.spinner.hide();
      this.tokenstatus = false;
      this.router.navigate(['']);
      this.ds.dialogf('',error.error);
    })
  }
  get_verify_options() {
    this.ds.getActionByNoToken([], 'signup/get_verify_options/' +this.id).subscribe(data => {
      this.apistatus = data.status;
      this.errorstatus = data.status;
      if (data.status) {
        this.data = data.data;
        this.errormsg = '';
      } else {
        this.sendstatus = false;
        this.alert.error(data.error);
      }
    }, error => {
      this.sendstatus = false;
      this.apistatus = false;
      this.errorstatus = false;
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  signin_form() {
    this.signinform = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.minLength(6)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirm_password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      signature: new FormControl('', [Validators.required]),
      mark: new FormControl('', [Validators.required]),
      verification_method: new FormControl('', [Validators.required]),
      verification_code: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)])
    }, { validators: this.passwordConfirming })
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirm_password').value) {
      return { invalid: true };
    }
  }
  usernameExists(username) {
    this.ds.getActionByNoToken([], 'signup/username_exists/' + username).subscribe(data => {
      if (data.status) {
        this.signinform.get('username').setValue('');
        this.ds.dialogf('',data.msg);
      }
    }, error => {
      this.signinform.get('username').setValue('');
      this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }
  sendVerificationCode() {
    this.validate();
    if (!this.signaturePad.isEmpty() && !this.markPad.isEmpty()) {
      this.signinform.get('signature').setValue(this.signaturePad.toDataURL());
      this.signinform.get('mark').setValue(this.markPad.toDataURL());
      this.spinner.show();
      let data = this.ds.json2formdata(this.signinform.value);
      data.append('id_number', this.id);
      this.ds.postActionByNoToken(data, 'signup/send_verify_code').subscribe(result => {
        this.sendstatus = result.status;
        this.sendcodestatus = result.status;
        this.spinner.hide();
        if (result.status) {
          this.codemsg = this.lang.transform('lang_resend');
          this.sendstatus = true;
          setTimeout(() => {
            this.sendcodestatus = false;
          }, 120000);
        } else {
          this.codemsg = this.lang.transform('lang_send_code');
          this.alert.error(result.error);
          this.sendstatus = false;
        }
      }, error => {
        this.codemsg = this.lang.transform('lang_send_code');
        this.sendcodestatus = false;
        this.spinner.hide();
        this.sendstatus = false;
      })
    } else {
      this.alert.error(this.lang.transform('lang_fill_required_fields'));
    }
  }
  validate() {
    this.alert.clear();
    this.signinform.get("username").markAsTouched();
    this.signinform.get("username").updateValueAndValidity();
    this.signinform.get("password").markAsTouched();
    this.signinform.get("password").updateValueAndValidity();
    this.signinform.get("confirm_password").markAsTouched();
    this.signinform.get("confirm_password").updateValueAndValidity();
    this.signinform.get("verification_method").markAsTouched();
    this.signinform.get("verification_method").updateValueAndValidity();
  }
  signin() {
    this.validate();
    this.spinner.show();
    if (!this.signaturePad.isEmpty() && !this.markPad.isEmpty()) {
      this.signinform.get('signature').setValue(this.signaturePad.toDataURL());
      this.signinform.get('mark').setValue(this.markPad.toDataURL());
      if (this.signinform.status === "VALID") {
        let data = this.ds.json2formdata(this.signinform.value);
        data.append('id_number', this.id);
        data.append('empno', this.empno);
        this.ds.postActionByNoToken(data, 'signup/signin/').subscribe(result => {
          this.spinner.hide();
          if (result.status) {
            this.signinform.reset();
            this.alert.success(result.msg);
            setTimeout(() => {
              this.router.navigate(['']);
            }, 3000);
          } else {
            this.alert.error(result.error);
          }
        }, error => {
          this.spinner.hide();
          this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
        })
      }
    } else {
      this.alert.error(this.lang.transform('lang_fill_required_fields'));
    }
  }
}
