<div class="card">
    <div class="width100">
        <div class="card-header"> {{'lang_human_resources_insurance_details' | language }} </div>
        <div class="card-body pad0" [formGroup]="signupForm">
            <div class="mbrl15" formGroupName="insuranceDetails">
                <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="insurance_number">{{'lang_insurance_number' | language }} : </label>
                            <input formControlName="insurance_number" id="insurance_number" type="number"
                            (keypress)="isNumber($event)"
                            placeholder="{{'lang_insurance_number' | language }}" class="form-control almnabrformcontrol insurance_number" />
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="insurance_date">{{'lang_insurance_date' | language }} : </label>
                            <div class="input-group input-group-sm">
                                <input [matDatepicker]="insurance_date" formControlName="insurance_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly>
                                <span class="input-group-btn">
                                    <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="insurance_date"></mat-datepicker-toggle>
                                    <mat-datepicker #insurance_date></mat-datepicker>
                                </span>
                                <span class="input-group-btn">
                                    <button (click)="signupForm.get('insuranceDetails')['controls']['insurance_date'].setValue('');" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="insurance_type_class">{{'lang_insurance_type_class' | language }} : </label>
                            <input formControlName="insurance_type_class" id="insurance_type_class" type="text" placeholder="{{'lang_insurance_type_class' | language }}" class="form-control almnabrformcontrol insurance_type_class" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer padb0">
                <div class="row">
                    <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
                        <button type="button" class="albutton" matStepperPrevious>{{'lang_previous' | language }}</button>
                    </div>
                    <div class="col-md-8 col-sm-6 col-xs-12 mb-10"></div>
                    <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
                        <button class="albutton" matStepperNext type="button">{{'lang_next' | language }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>