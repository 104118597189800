import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { McalendarRoutingModule } from './mcalendar-routing.module';
import { CalendarComponent } from './calendar/calendar.component';
import { SharedModule } from '@shared';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
const COMPONENTS = [CalendarComponent];
const COMPONENTS_DYNAMIC = [];
@NgModule({
  imports: [
    SharedModule,
    McalendarRoutingModule,
    NgbModalModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC],
  exports: [...COMPONENTS, ...COMPONENTS_DYNAMIC],
  entryComponents: COMPONENTS_DYNAMIC,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class McalendarModule { }
