import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddExternalPersonComponent } from "../../settings/external-persons/add-external-person/add-external-person.component";

@Component({
	selector: "app-view-users",
	templateUrl: "./view-users.component.html",
	styleUrls: ["./view-users.component.scss"],
})
export class ViewUsersComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	users = [];
	ticketID;
	isAdmin;
	pepage_no = 1;
	pepage_size = "10";
	pedata: any = [];
	lodingdatas = this.lang.transform("lang_loading");
	constructor(
		public dialogRef: MatDialogRef<ViewUsersComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private spinner: PreloaderService,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private dialog: MatDialog,
		private sweetalert: SweetAlertService
	) {
		this.users = data?.users;
		this.ticketID = data?.ticketID;
		this.isAdmin = data?.isAdmin;
	}

	ngOnInit(): void {
		this.get_external_persons();
	}

	unfollowUser(user) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		formData.append("emp_id", user?.emp_id || "");
		this.ds.post("tasks/unfollow_emp_by_admin", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(this.lang.transform(res?.message), 1000);
					this.dialogRef.close(true);
				} else {
					this.sweetAlert.errorMessage(this.lang.transform(res?.error));
					this.dialogRef.close(false);
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}

	unLockUser(user) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		formData.append("emp_id", user?.emp_id || "");
		this.ds.post("tasks/allow_emp_ticket", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(this.lang.transform(res?.message), 1000);
					this.dialogRef.close(true);
				} else {
					this.sweetAlert.errorMessage(this.lang.transform(res?.error));
					this.dialogRef.close(false);
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}

	lockUser(user) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		formData.append("emp_id", user?.emp_id || "");
		this.ds.post("tasks/prevent_unfollow", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(this.lang.transform(res?.message), 1000);
					this.dialogRef.close(true);
				} else {
					this.sweetAlert.errorMessage(this.lang.transform(res?.error));
					this.dialogRef.close(false);
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}

	//external persons
	public loadpage(page) {
		this.pepage_no = page;
		this.pepage_size = this.pepage_size;
		this.get_external_persons();
	}
	public loadpagesize(size) {
		this.pepage_no = 1;
		this.pepage_size = size;
		this.get_external_persons();
	}
	public load_external_persons() {
		this.pepage_no = 1;
		this.pepage_size = "10";
		this.get_external_persons();
	}
	public get_external_persons() {
		let formData = new FormData();
		formData.append("ticket_id", this.ticketID || "");
		this.spinner.show();
		this.ds
			.post(
				"tasks/get_data_person_extarnal/" +
					this.pepage_no +
					"/" +
					this.pepage_size,
				formData
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.pedata = res;
					} else {
						this.pedata = [];
						this.lodingdatas = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.pedata = [];
					this.lodingdatas =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}

	onAddUpdateExternalPersons(data?) {
		const dialogRef = this.dialog.open(AddExternalPersonComponent, {
			width: "600px",
			data: {
				fromTicket: true,
				ticket_id: this.ticketID,
				epData: data,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.get_external_persons();
			}
		});
	}

	deleteExternalPerson(id) {
		let formData = new FormData();
		formData.append("person_id", id || "");
		this.spinner.show();
		this.ds.post("tasks/delete_person_extarnal", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.get_external_persons();
				} else {
					this.sweetalert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetalert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
