<div class="card border-0">
  <div class="card-header AddNewEmpHeader">
    <span *ngIf="selectedIndex == 0">
      {{ "lang_select" | language }} {{ "lang_date" | language }}
    </span>
    <span *ngIf="selectedIndex == 1">
      {{ "lang_select" | language }} {{ "lang_company_employees" | language }}
    </span>
    <span *ngIf="selectedIndex == 2">
      {{ "lang_details" | language }}
    </span>
    <span *ngIf="selectedIndex == 3">
      {{ "lang_reviewers" | language }}
    </span>
    <span *ngIf="selectedIndex == 4">
      {{ "lang_add_to_review" | language }}
    </span>
    <span *ngIf="selectedIndex == 5">
      {{ "lang_export" | language }}
    </span>
  </div>
  <div class="card-body">
    <div class="row w-100">
      <mat-horizontal-stepper
        [linear]="isLinear"
        [selectedIndex]="selectedIndex"
        (selectionChange)="selectionChange($event)"
        class="w-100 cuatomMat"
        [ngClass]="{
          ltr: lang_key != 'ar',
          rtl: lang_key == 'ar',
          fisrtClass: selectedIndex >= 0,
          secondClass: selectedIndex >= 1,
          thirdClass: selectedIndex >= 2,
          fourthClass: selectedIndex >= 3,
          fifthClass: selectedIndex >= 4,
          sixClass: selectedIndex >= 5
        }"
        labelPosition="bottom"
        #stepper
      >
        <mat-step [stepControl]="firstFormGroup">
          <div class="text-center">
            <ng-template matStepLabel>
              <div class="d-block inactive textColor">
                <div class="custom"></div>
                {{ "lang_select" | language }} {{ "lang_date" | language }}
              </div>
            </ng-template>

            <app-step-one
              [firstFormGroup]="firstFormGroup"
              [t_id]="t_id"
              [stepper]="stepper"
              (formValueOutput)="fillFirstFormValue($event)"
            ></app-step-one>
            <!-- <app-step-five [t_id]="t_id" 
          [stepper]="stepper" 
            [refresh]="refreshSubject.asObservable()"></app-step-five> -->
          </div>
        </mat-step>
        <mat-step [stepControl]="stepTwoValid">
          <ng-template matStepLabel>
            <div class="d-block inactive textColor">
              <div class="custom"></div>
              {{ "lang_select" | language }}
              {{ "lang_company_employees" | language }}
            </div>
          </ng-template>

          <app-step-two
            [requestInfo]="responseData"
            [usersList]="usersResponse"
            [t_id]="t_id"
            [projects]="projects"
            [branches]="branches"
            [stepper]="stepper"
            (UserListSetter)="fillUserListFromStepTwo($event)"
            (sendComponentUpdate)="emitEventToChild()"
          ></app-step-two>
        </mat-step>
        <mat-step [stepControl]="stepThreeValid">
          <ng-template matStepLabel>
            <div class="d-block inactive textColor">
              <div class="custom"></div>
              {{ "lang_details" | language }}
            </div>
          </ng-template>

          <app-step-three
            [t_id]="t_id"
            [requestInfo]="responseData"
            [usersLists]="usersResponse"
            [stepper]="stepper"
            (UserListSetter)="fillUserListFromStepThree($event)"
            [refresh]="refreshSubject.asObservable()"
          ></app-step-three>
        </mat-step>
        <mat-step [stepControl]="stepFourValid">
          <div [formGroup]="searchForm">
            <ng-template matStepLabel>
              <div class="d-block inactive textColor">
                <div class="custom"></div>
                {{ "lang_reviewers" | language }}
              </div>
            </ng-template>
            <app-step-four
              [t_id]="t_id"
              (reviewersOutput)="fillReviewers($event)"
              (resfreshView)="view_request()"
              [stepper]="stepper"
              [reviewers]="reviewers"
            ></app-step-four>
          </div>
        </mat-step>

        <!-- <mat-step>
        
            <ng-template matStepLabel>
              <div class="d-block inactive textColor" >
                <div class="custom">

                </div>
                {{'lang_add_to_review'|language}}
              </div>
            </ng-template>

       
            <app-step-five [t_id]="t_id" [stepper]="stepper"  [refresh]="refreshSubject.asObservable()"></app-step-five>
        </mat-step> -->
        <mat-step>
          <form>
            <ng-template matStepLabel>
              <div class="d-block inactive textColor">
                <div class="custom"></div>
                {{ "lang_completed" | language }}
              </div>
            </ng-template>

            <app-completed-step [t_id]="t_id"></app-completed-step>
          </form>
        </mat-step>
        <!--  <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <p>You are now done.</p>
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="stepper.reset()">Reset</button>
          </div>
        </mat-step> -->
      </mat-horizontal-stepper>
    </div>
  </div>
</div>
