<mat-card class="mat-card-custom">
  <div class="form-wrapper">
    <h3 class="mb-2">
      {{ "lang_create" | language }} {{ "lang_vaction" | language }}
    </h3>
    <mat-divider></mat-divider>

    <form
      [formGroup]="createVactionForm"
      style="max-width: unset"
      #vacationForm
    >
      <div>
        <div class="row pt-3">
          <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
            <label class="mb-10"
              >{{ "lang_type" | language }} : <span class="red">*</span></label
            >
            <ng-select
              [items]="vacations"
              [multiple]="false"
              [bindLabel]="lang_key == 'en' ? 'text_en' : 'text_ar'"
              bindValue="value"
              clearAllText="Clear"
              [searchFn]="ds.customSearchFn"
              appendTo="body"
              formControlName="vacation_type_id"
              placeholder="{{ 'lang_vacation_type' | language }}"
              (change)="checkVacationEmployee(); checkAttachment()"
            >
            </ng-select>
            <mat-error
              *ngIf="
                vacationForm.submitted &&
                createVactionForm.get('vacation_type_id').errors?.required
              "
              >{{ "lang_field_required" | language }}</mat-error
            >
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{
                  "lang_before_vacation_working_date_english" | language
                }}
                :<span class="red">*</span></label
              >
              <!-- <div class="input-group input-group-sm">
								<input
									[matDatepicker]="bvwd"
									formControlName="before_vacation_working_date_english"
									(dateChange)="
										ds.setENTOARDate(
											$event,
											createVactionForm.get(
												'before_vacation_working_date_arabic'
											)
										);
										checkVacationEmployee()
									"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									required
									readonly
								/>
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="bvwd"
									></mat-datepicker-toggle>
									<mat-datepicker #bvwd></mat-datepicker>
								</span>
							</div>
 -->
              <!-- 
								[parentFormControlName]="createVactionForm.controls['before_vacation_working_date_english']"
							[ARDateFormControlName]="createVactionForm.controls['before_vacation_working_date_arabic']"
							 -->
              <custom-arendate-input
                [_dateValue]=""
                (dateChange)="
                  setValueToFields(
                    $event,
                    createVactionForm.controls
                      .before_vacation_working_date_english,
                    createVactionForm.controls
                      .before_vacation_working_date_arabic
                  );
                  checkVacationEmployee();
                  changeDateInput($event)
                "
                [parentForm]="createVactionForm"
                format="YYYY/MM/DD"
                [label]="'YYYY/MM/DD'"
              >
              </custom-arendate-input>

              <mat-error
                *ngIf="
                  vacationForm.submitted &&
                  createVactionForm.controls[
                    'before_vacation_working_date_english'
                  ].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
          <!-- <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="bvwda"
								> {{'lang_before_vacation_working_date_arabic'|language}} :<span class="red"
									>*</span
								></label
							>
							<input
								formControlName="before_vacation_working_date_arabic"
								ngbDatepicker
								[readonly]="true"
								(ngModelChange)="
									ds.setARTOENDate(
										$event,
										createVactionForm.get(
											'before_vacation_working_date_english'
										)
									)
								"
								(click)="idarab.toggle()"
								#idarab="ngbDatepicker"
								required
								id="bvwda"
								type="text"
								class="form-control almnabrformcontrol"
								placeholder="YYYY/MM/DD"
							/>
							<mat-error
								*ngIf="
									createVactionForm.controls[
										'before_vacation_working_date_arabic'
									].touched &&
									createVactionForm.controls[
										'before_vacation_working_date_arabic'
									].errors?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div> -->
          <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{
                  "lang_after_vacation_working_date_english" | language
                }}:<span class="red">*</span></label
              >

              <custom-arendate-input
                [_dateValue]=""
                (dateChange)="
                  setValueToFields(
                    $event,
                    createVactionForm.controls
                      .after_vacation_working_date_english,
                    createVactionForm.controls
                      .after_vacation_working_date_arabic
                  );
                  checkVacationEmployee()
                "
                [parentForm]="createVactionForm"
                format="YYYY/MM/DD"
                [label]="'YYYY/MM/DD'"
              >
              </custom-arendate-input>
              <!-- 	<div class="input-group input-group-sm">
								<input
									[matDatepicker]="avwde"
									formControlName="after_vacation_working_date_english"
									(dateChange)="
										ds.setENTOARDate(
											$event,
											createVactionForm.get(
												'after_vacation_working_date_arabic'
											)
										);
										checkVacationEmployee()
									"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									required
									readonly
								/>
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="avwde"
									></mat-datepicker-toggle>
									<mat-datepicker #avwde></mat-datepicker>
								</span>
							</div> -->
              <mat-error
                *ngIf="
                  vacationForm.submitted &&
                  createVactionForm.controls['after_vacation_working'].errors
                    ?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
          <!-- 	<div class="col-md-4 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="avwda"
								> {{'lang_after_vacation_working_date_arabic'|language}} :<span class="red"
									>*</span
								></label
							>
							<input
								formControlName="after_vacation_working_date_arabic"
								ngbDatepicker
								[readonly]="true"
								(ngModelChange)="
									ds.setARTOENDate(
										$event,
										createVactionForm.get('after_vacation_working_date_english')
									)
								"
								(click)="avwda.toggle()"
								#avwda="ngbDatepicker"
								required
								id="avwda"
								type="text"
								class="form-control almnabrformcontrol"
								placeholder="YYYY/MM/DD"
							/>
							<mat-error
								*ngIf="
									createVactionForm.controls[
										'after_vacation_working_date_arabic'
									].touched &&
									createVactionForm.controls[
										'after_vacation_working_date_arabic'
									].errors?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div> -->

          <div
            class="col-md-4 col-sm-6 col-xs-12 mb-10"
            *ngIf="showOtherVacation"
          >
            <div class="form-group">
              <label
                >{{ "lang_other_vaction" | language }} :
                <span class="red">*</span> </label
              ><br />
              <input
                formControlName="vacation_other"
                id="vacation_other"
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_other_vaction' | language }}"
              />
              <mat-error
                *ngIf="
                  vacationForm.submitted &&
                  createVactionForm.controls['vacation_other'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>

          <div class="col-md-4 col-sm-6 col-xs-12 mb-10" *ngIf="isValidForm">
            <div class="form-group">
              <label>
                {{ "lang_start_date" | language }} :<span class="red"
                  >*</span
                ></label
              >

              <custom-arendate-input
                [_dateValue]="
                  createVactionForm.controls.vacation_start_date_english.value
                "
                [readOnly]="true"
                (dateChange)="
                  setValueToFields(
                    $event,
                    createVactionForm.controls.vacation_start_date_english,
                    createVactionForm.controls.vacation_start_date_arabic
                  )
                "
                [parentForm]="createVactionForm"
                format="YYYY/MM/DD"
                [label]="'YYYY/MM/DD'"
              >
              </custom-arendate-input>

              <!-- <div class="input-group input-group-sm">
								<input
									[matDatepicker]="vsde"
									formControlName="vacation_start_date_english"
									(dateChange)="
										ds.setENTOARDate(
											$event,
											createVactionForm.get('vacation_start_date_arabic')
										)
									"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									required
									readonly
									disabled
								/>
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="vsde"
									></mat-datepicker-toggle>
									<mat-datepicker #vsde></mat-datepicker>
								</span>
							</div> -->
              <mat-error
                *ngIf="
                  vacationForm.submitted &&
                  createVactionForm.controls['vacation_start_date_english']
                    .errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>

          <!-- <div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							createVactionForm.controls['vacation_type_id'].valid &&
							createVactionForm.controls['before_vacation_working_date_english']
								.valid &&
							createVactionForm.controls['after_vacation_working_date_english']
								.valid
						"
					>
						<div class="form-group">
							<label for="vsda"
								>{{'lang_start_date_arabic'|language}} :<span class="red">*</span></label
							>
							<input
								formControlName="vacation_start_date_arabic"
								ngbDatepicker
								[readonly]="true"
								disabled
								(ngModelChange)="
									ds.setARTOENDate(
										$event,
										createVactionForm.get('vacation_start_date_english')
									)
								"
								(click)="vsda.toggle()"
								#vsda="ngbDatepicker"
								required
								id="vsda"
								type="text"
								class="form-control almnabrformcontrol"
								placeholder="YYYY/MM/DD"
							/>
							<mat-error
								*ngIf="
									createVactionForm.controls['vacation_start_date_arabic']
										.touched &&
									createVactionForm.controls['vacation_start_date_arabic']
										.errors?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div> -->

          <div class="col-md-4 col-sm-6 col-xs-12 mb-10" *ngIf="isValidForm">
            <div class="form-group">
              <label>
                {{ "lang_end_date" | language }} :<span class="red"
                  >*</span
                ></label
              >

              <custom-arendate-input
                [_dateValue]="
                  createVactionForm.controls.vacation_end_date_english.value
                "
                [readOnly]="true"
                (dateChange)="
                  setValueToFields(
                    $event,
                    createVactionForm.controls.vacation_end_date_english,
                    createVactionForm.controls.vacation_end_date_arabic
                  )
                "
                [parentForm]="createVactionForm"
                format="YYYY/MM/DD"
                [label]="'YYYY/MM/DD'"
              >
              </custom-arendate-input>
              <!-- 	<div class="input-group input-group-sm">
								<input
									[matDatepicker]="vede"
									formControlName="vacation_end_date_english"
									(dateChange)="
										ds.setENTOARDate(
											$event,
											createVactionForm.get('vacation_end_date_arabic')
										)
									"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									required
									readonly
									disabled
								/>
								
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="vede"
									></mat-datepicker-toggle>
									<mat-datepicker #vede></mat-datepicker>
								</span>
							</div> -->
              <mat-error
                *ngIf="
                  vacationForm.submitted &&
                  createVactionForm.controls['vacation_end_date_english'].errors
                    ?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>

          <!-- <div
						class="col-md-4 col-sm-6 col-xs-12 mb-10"
						*ngIf="
							createVactionForm.controls['vacation_type_id'].valid &&
							createVactionForm.controls['before_vacation_working_date_english']
								.valid &&
							createVactionForm.controls['after_vacation_working_date_english']
								.valid
						"
					>
						<div class="form-group">
							<label for="veda"
								>  {{'lang_end_date_arabic'|language}}  :<span class="red">*</span></label
							>
							<input
								formControlName="vacation_end_date_arabic"
								ngbDatepicker
								[readonly]="true"
								(ngModelChange)="
									ds.setARTOENDate(
										$event,
										createVactionForm.get('vacation_end_date_english')
									)
								"
								(click)="veda.toggle()"
								#veda="ngbDatepicker"
								required
								disabled
								id="veda"
								type="text"
								class="form-control almnabrformcontrol"
								placeholder="YYYY/MM/DD"
							/>
							<mat-error
								*ngIf="
									createVactionForm.controls['vacation_end_date_arabic']
										.touched &&
									createVactionForm.controls['vacation_end_date_arabic'].errors
										?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div> -->

          <div class="col-md-4 col-sm-6 col-xs-12 mb-10" *ngIf="isValidForm">
            <div class="form-group">
              <label>
                {{ "lang_total_days" | language }} :
                <span class="red">*</span> </label
              ><br />
              <input
                formControlName="vacation_total_days"
                id="vacation_total_days"
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_total_days' | language }}"
                disabled
              />
            </div>
          </div>
          <div
            class="col-md-4 col-sm-6 col-xs-12 mb-10"
            *ngIf="
              isValidForm &&
              createVactionForm.controls['vacation_type_id'].value != '3'
            "
          >
            <div class="form-group">
              <label>
                {{ "lang_total" | language }}
                {{ "lang_human_resources_vacation_paid_days" | language }} :
                <span class="red">*</span> </label
              ><br />
              <input
                formControlName="vacation_total_paid_days"
                id="vacation_total_paid_days"
                class="form-control almnabrformcontrol"
                placeholder="{{
                  'lang_human_resources_vacation_paid_days' | language
                }}"
                disabled
              />
            </div>
          </div>

          <div class="col-md-4 col-sm-6 col-xs-12 mb-10" *ngIf="isValidForm">
            <div class="form-group">
              <label>
                {{ "lang_total_unpaid_days" | language }} :
                <span class="red">*</span> </label
              ><br />
              <input
                formControlName="vacation_total_unpaid_days"
                id="vacation_total_unpaid_days"
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_total_unpaid_days' | language }}"
                disabled
              />
            </div>
          </div>

          <div
            class="col-md-4 col-sm-6 col-xs-12 mb-10"
            *ngIf="
              isValidForm &&
              createVactionForm.controls['vacation_type_id'].value != '3'
            "
          >
            <div class="form-group">
              <label>
                {{ "lang_total" | language }}
                {{ "lang_paid_amount" | language }} :
                <span class="red">*</span> </label
              ><br />
              <input
                formControlName="vacation_total_paid_amount"
                id="vacation_total_paid_amount"
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_paid_amount' | language }}"
                disabled
              />
            </div>
          </div>

          <div
            class="col-md-4 col-sm-6 col-xs-12 mb-10"
            *ngIf="
              isValidForm &&
              createVactionForm.controls['vacation_type_id'].value == '3'
            "
          >
            <div class="form-group">
              <label>
                {{ "lang_total_paid" | language }} 75
                {{ "lang_days_amount" | language }}:
                <span class="red">*</span> </label
              ><br />
              <input
                formControlName="paid_days75_amount"
                id="paid_days75_amount"
                class="form-control almnabrformcontrol"
                placeholder="vacation total paid 75 Days amount"
                disabled
              />
            </div>
          </div>
          <div
            class="col-md-4 col-sm-6 col-xs-12 mb-10"
            *ngIf="
              isValidForm &&
              createVactionForm.controls['vacation_type_id'].value == '3'
            "
          >
            <div class="form-group">
              <label>
                {{ "lang_total_paid" | language }} 100
                {{ "lang_days_amount" | language }}:
                <!-- {{'lang_total_paid_100_days_amount'|language}}: -->
                <span class="red">*</span> </label
              ><br />
              <input
                formControlName="paid_days100_amount"
                id="paid_days100_amount"
                class="form-control almnabrformcontrol"
                placeholder="vacation total paid 100 Days amount"
                disabled
              />
            </div>
          </div>

          <div
            class="col-md-4 col-sm-6 col-xs-12 mb-10"
            *ngIf="
              isValidForm &&
              createVactionForm.controls['vacation_type_id'].value == '3'
            "
          >
            <div class="form-group">
              <label>
                {{ "lang_total_paid" | language }} 75
                {{ "lang_day" | language }}:
                <!-- {{ "lang_total_paid_75_days"|language }} : -->
                <span class="red">*</span> </label
              ><br />
              <input
                formControlName="paid_days75"
                id="paid_days75"
                class="form-control almnabrformcontrol"
                placeholder="vacation total paid 75 Days"
                disabled
              />
            </div>
          </div>

          <div
            class="col-md-4 col-sm-6 col-xs-12 mb-10"
            *ngIf="
              isValidForm &&
              createVactionForm.controls['vacation_type_id'].value == '3'
            "
          >
            <div class="form-group">
              <label>
                {{ "lang_total_paid" | language }} 100
                {{ "lang_days" | language }} :
                <!-- {{ "lang_total_paid_100_days"|language }} : -->
                <span class="red">*</span> </label
              ><br />
              <input
                formControlName="paid_days100"
                id="paid_days100"
                class="form-control almnabrformcontrol"
                placeholder="vacation total paid 100 Days"
                disabled
              />
            </div>
          </div>
          <div
            class="col-md-4 col-sm-6 col-xs-12 mb-10"
            *ngIf="
              isValidForm &&
              createVactionForm.controls['vacation_type_id'].value == '3'
            "
          >
            <div class="form-group">
              <label>
                {{ "lang_sick" | language }} {{ "lang_balance" | language }} :
                <span class="red">*</span> </label
              ><br />
              <input
                formControlName="sick_balance"
                id="sick_balance"
                class="form-control almnabrformcontrol"
                placeholder="Sick Balance"
                disabled
              />
            </div>
          </div>

          <div class="col-md-4 col-xs-6 col-sm-4 mb-10">
            <label class="mb-10">
              {{ "lang_direct_manager" | language }}
              <span class="red"> * </span>
            </label>

            <ng-select
              [items]="users"
              [multiple]="false"
              bindLabel="label"
              bindValue="value"
              clearAllText="Clear"
              appendTo="body"
              formControlName="direct_manager"
              placeholder="{{ 'lang_direct_manager' | language }}"
              (search)="search_users($event)"
              [searchFn]="ds.customSearchFn"
            >
            </ng-select>
            <mat-error
              *ngIf="
                vacationForm.submitted &&
                vacationForm.controls['direct_manager'].errors?.required
              "
            >
              {{ "lang_field_required" | language }}
            </mat-error>
          </div>
        </div>

        <div class="row">
          <div
            class="col-md-3 col-sm-4 col-xs-12 mb-10"
            *ngIf="!attachstatus && showAttachment"
          >
            <button
              class="albutton mnabrbutton mat-accent"
              mat-button
              (click)="enable_field('attachment')"
            >
              <i class="fa fa-plus"></i> {{ "lang_need_attachment" | language }}
            </button>
          </div>
        </div>
        <input type="hidden" formControlName="needAttach" />
        <div class="row" *ngIf="attachstatus">
          <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
            <fieldset class="cardback fieldset">
              <legend>
                {{ "lang_attachments" | language }}
                <span class="red" *ngIf="getAttachments.errors?.required"
                  >*
                  <mat-icon
                    class="red mnabricon"
                    (click)="disable_field('attachment')"
                    >close</mat-icon
                  ></span
                >
                <mat-icon
                  class="mnabricon"
                  matTooltip="{{ 'lang_add' | language }}"
                  (click)="addfiles()"
                  >add_box</mat-icon
                >
              </legend>
              <div class="table-responsive customResponsive">
                <table
                  border="1"
                  class="table CustomTable"
                  width="100%"
                  formArrayName="attachments"
                >
                  <thead>
                    <tr>
                      <td style="padding: 5px; word-break: break-all">#</td>
                      <td style="padding: 5px; word-break: break-all">
                        {{ "lang_title" | language }}
                      </td>
                      <td style="padding: 5px; word-break: break-all">
                        {{ "lang_file" | language }}
                      </td>

                      <td style="padding: 5px; word-break: break-all">
                        {{ "lang_action" | language }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let file of filesFormGroup.controls;
                        let i = index;
                        let odd = odd;
                        let even = even
                      "
                      [ngClass]="{ odd: odd, even: even }"
                      [formGroupName]="i"
                    >
                      <td
                        class="verticalalignmid"
                        style="padding: 5px; word-break: break-all"
                      >
                        {{ i + 1 }}
                      </td>
                      <td
                        style="
                          padding: 5px;
                          word-break: break-all;
                          position: relative;
                        "
                      >
                        <input
                          type="text"
                          formControlName="attach_title"
                          placeholder="{{ 'lang_description' | language }}"
                          autocomplete="off"
                          class="form-control almnabrformcontrol"
                        />
                        <mat-error
                          *ngIf="
                            vacationForm.submitted &&
                            this.attachments.controls[i].get('attach_title')
                              .errors?.required
                          "
                          >{{ "lang_field_required" | language }}</mat-error
                        >
                      </td>
                      <td
                        class="verticalalignmid"
                        style="
                          padding: 5px;
                          word-break: break-all;
                          position: relative;
                        "
                      >
                        <input
                          style="display: block"
                          name="file"
                          type="file"
                          required
                          onclick="this.value = null"
                          (change)="UploadFile($event, i)"
                          class="nghide albutton width100"
                        />
                        <label
                          id="uploadText{{ i }}"
                          class="albutton mat-accent"
                          mat-button
                          >{{ filename }}
                        </label>
                        <mat-error
                          *ngIf="
                            vacationForm.submitted &&
                            this.attachments.controls[i].get('file').errors
                              ?.required
                          "
                        >
                          {{ "lang_field_required" | language }}</mat-error
                        >
                      </td>

                      <td class="verticalalignmid">
                        <mat-icon
                          (click)="removefiles(i)"
                          class="mnabricon red"
                          matTooltip="{{ 'lang_delete' | language }}"
                          >delete_forever</mat-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="row mb-2 mt-2" *ngIf="financial_details.length > 0">
          <div class="col-md-12 pad0">
            <div class="table-responsive customResponsive">
              <table
                border="1"
                class="table CustomTable"
                *ngIf="financial_details.length > 0"
              >
                <thead>
                  <tr>
                    <td>#</td>
                    <td>{{ "lang_date" | language }}</td>
                    <td>
                      {{ "lang_human_resources_vacation_paid_days" | language }}
                    </td>
                    <td>{{ "lang_finance_unpaid_days" | language }}</td>
                    <td>{{ "lang_credit_amount" | language }}</td>
                    <!-- <td *ngIf="isSickCase">{{ "lang_paid_days" | language }} 75</td>
										<td *ngIf="isSickCase">{{ "lang_credit_amount" | language }} 25</td>
										<td *ngIf="isSickCase">{{ "lang_credit_amount" | language }} 75</td> -->
                    <td>{{ "lang_debit_amount" | language }}</td>
                    <td>{{ "lang_net_salary_amount" | language }}</td>
                    <td>{{ "lang_description" | language }}</td>
                  </tr>
                </thead>
                <tbody class="AppendList">
                  <tr
                    *ngFor="
                      let finance of financial_details;
                      let i = index;
                      let odd = odd;
                      let even = even
                    "
                    [ngClass]="{ odd: odd, even: even }"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>
                      {{ finance?.finance_month }} / {{ finance?.finance_year }}
                    </td>
                    <td>{{ finance?.paid_days }}</td>
                    <td>{{ finance?.unpaid_days }}</td>
                    <td>{{ finance?.credit_amount }}</td>
                    <!-- <td *ngIf="isSickCase">{{ finance?.paid_days_75 ? finance?.paid_days_75?.toFixed(2) :0}}</td>
										<td *ngIf="isSickCase">{{ finance?.credit_amount_25 ?finance?.credit_amount_25?.toFixed(2):0 }}</td>
										<td *ngIf="isSickCase">{{ finance?.credit_amount_75 ?finance?.credit_amount_75?.toFixed(2):0 }}</td> -->
                    <td>{{ finance?.debit_amount }}</td>
                    <td
                      class="details"
                      matTooltip="{{ 'lang_details' | language }}"
                      (click)="
                        get_details(
                          finance?.finance_month,
                          finance?.finance_year,
                          details
                        )
                      "
                    >
                      {{ finance?.net_salary_amount }}
                    </td>
                    <td>
                      <div
                        matTooltip="{{ finance.finance_description }}"
                        *ngIf="finance.finance_description"
                        class="note"
                      ></div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <no-data
                style="margin: auto"
                [Opened]="financial_details.length < 1"
              ></no-data>
            </div>
          </div>
        </div>

        <div
          class="row mb-2 mt-4 viewquotation stylepanels"
          *ngIf="
            yearContractVacationInfo &&
            this.createVactionForm?.value?.vacation_type_id == 1
          "
        >
          <fieldset class="fieldset">
            <legend>
              {{ "lang_human_resources_contract_details" | language }}
            </legend>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_human_resources_contract_start_date" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.contract_start_date }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_human_resources_contract_end_date" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.contract_end_date }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_net_salary_amount" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.net_amount }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_per_day_salary" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.per_day_salary }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_human_resources_contract_warning" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.contract_warning }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_human_resources_probation_warning" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.probation_warning }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_employee_active_contract_total_days" | language }}
                </div>
                <div class="mb-10">
                  <b>
                    {{
                      yearContractVacationInfo?.employee_active_contract_total_days
                    }}</b
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_employee_all_contract_worked_days" | language }}
                </div>
                <div class="mb-10">
                  <b>
                    {{
                      yearContractVacationInfo?.employee_all_contract_worked_days
                    }}</b
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{
                    "lang_human_resources_active_contract_worked_day" | language
                  }}
                </div>
                <div class="mb-10">
                  <b>
                    {{
                      yearContractVacationInfo?.employee_active_contract_worked_days
                    }}</b
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{
                    "lang_remaining_working_days_on_active_contract" | language
                  }}
                </div>
                <div class="mb-10">
                  <b>
                    {{
                      yearContractVacationInfo?.employee_remaining_working_days
                    }}</b
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_contract_yearly_paid_days" | language }}
                </div>
                <div class="mb-10">
                  <b>
                    {{ yearContractVacationInfo?.contract_yearly_paid_days }}</b
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_contract_actual_end_date" | language }}
                </div>
                <div class="mb-10">
                  <b>
                    {{ yearContractVacationInfo?.contract_actual_end_date }}</b
                  >
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend>
              {{ "lang_human_resources_vocation_details" | language }}
            </legend>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_total_used_voc_paid_days" | language }}
                </div>
                <div class="mb-10">
                  <b>
                    {{ yearContractVacationInfo?.used_paid_vacation_days }}</b
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_total_used_voc_paid_amount" | language }}
                </div>
                <div class="mb-10">
                  <b>
                    {{ yearContractVacationInfo?.used_paid_vacation_amount }}</b
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_total_used_voc_unpaid_days" | language }}
                </div>
                <div class="mb-10">
                  <b>
                    {{ yearContractVacationInfo?.used_unpaid_vacation_days }}</b
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_total_used_voc_unpaid_amount" | language }}
                </div>
                <div class="mb-10">
                  <b>
                    {{
                      yearContractVacationInfo?.used_unpaid_vacation_amount
                    }}</b
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_vacation_start_date_english" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.vacation_start_date }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_vacation_end_date_english" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.vacation_end_date }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_total_deserved_days" | language }}
                </div>
                <div class="mb-10">
                  <b>
                    {{
                      yearContractVacationInfo?.vacation_deserved_paid_days
                    }}</b
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_total_detection_days" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.total_detection_days }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_total_deserved_days_after_detection" | language }}
                </div>
                <div class="mb-10">
                  <b>
                    {{
                      yearContractVacationInfo?.total_deserved_days_after_detection
                    }}</b
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_vacation_days" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.vacation_days }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_paid_vacation_days" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.paid_days }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_paid_vacation_amount" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.paid_amount }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_unpaid_vacation_days" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.unpaid_days }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_unpaid_vacation_amount" | language }}
                </div>
                <div class="mb-10">
                  <b> {{ yearContractVacationInfo?.unpaid_amount }}</b>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="mb-10">
                  {{ "lang_balance_vocation_paid_days" | language }}
                </div>
                <div class="mb-10">
                  <b>
                    {{
                      yearContractVacationInfo?.balance_vocation_paid_days
                    }}</b
                  >
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <app-alert id="default-alert"></app-alert>
        <div class="row pb-3 pt-3">
          <div class="col-sm-4 col-12">
            <button
              mat-button
              type="button"
              class="mnabrbutton mat-accent test"
              (click)="back()"
            >
              {{ "lang_previous" | language }}
            </button>
          </div>
          <div class="col-sm-4 col-12"></div>
          <div class="col-sm-4 col-12">
            <button
              mat-button
              type="button"
              class="mnabrbutton mat-accent test"
              (click)="onSubmit()"
            >
              {{ "lang_submit" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-card>

<ng-template #details>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ "lang_financial_details_list" | language }}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        &times;
      </button>
    </div>
    <div class="modal-body">
      <div class="mbrl15">
        <div class="row text-center">
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_employee_numbers" | language }}</div>
            <div class="mb-10">
              <b> {{ financial_details_list?.employee_number || 0 }}</b>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_net_amount" | language }}
            </div>
            <div class="mb-10">
              <b> {{ net_amount }}</b>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_finance_year" | language }}</div>
            <div class="mb-10">
              <b> {{ financial_details_list?.finance_year || 0 }}</b>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_finance_month" | language }}
            </div>
            <div class="mb-10">
              <b> {{ financial_details_list?.finance_month || 0 }}</b>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_credit_amount" | language }}</div>
            <div class="mb-10">
              <b> {{ financial_details_list?.credit_amount || 0 }}</b>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_debit_amount" | language }}
            </div>
            <div class="mb-10">
              <b> {{ financial_details_list?.debit_amount || 0 }}</b>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_paid_days" | language }}</div>
            <div class="mb-10">
              <b> {{ financial_details_list?.paid_days || 0 }}</b>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_unpaid_days" | language }}
            </div>
            <div class="mb-10">
              <b> {{ financial_details_list?.unpaid_days || 0 }}</b>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_finance_description" | language }}</div>
            <div class="mb-10">
              <b> {{ financial_details_list?.finance_description || 0 }}</b>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_finance_created_data" | language }}
            </div>
            <div class="mb-10">
              <b> {{ financial_details_list?.finance_created_data || 0 }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal footer -->
  </div>
</ng-template>
