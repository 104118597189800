<h2 mat-dialog-title>{{ "lang_general_numbers" | language }}</h2>
<mat-dialog-content class="mat-typography">
	<div class="container" [formGroup]="work_sites_levels_form">
		<div class="row">
			<div class="col-sm-6 mb-10">
				<label class="mb-10"
					>{{ "lang_search_general_numbers" | language }} :
					<span class="red">*</span></label
				>
				<ng-select
					[items]="gNumbers"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					clearAllText="Clear"
					[searchFn]="ds.customSearchFn"
					[loading]="unitSearchLoading"
					loadingText="{{'lang_loading' | language}}"
					appendTo="body"
					formControlName="work_site_general_nos"
					(search)="search_required_datas($event)"
					(focus)="gNumbers = []"
					(clear)="unit_key = null"
					(change)="get_work_levels()"
					placeholder="{{ 'lang_search_general_numbers' | language }}"
				>
					<ng-template ng-header-tmp>
						<div style="display:flex; justify-content:space-between">
							<span>{{"lang_search_tip" | language}}</span>
							<span style='cursor:pointer' (click)="getUnits()"><i class="fa fa-search mnabricon"></i></span>
						</div>
					</ng-template>
				</ng-select>
				<mat-error
					*ngIf="
						work_sites_levels_form.controls['work_site_general_nos'].errors
							?.required
					"
					>{{ "lang_field_required" | language }}</mat-error
				>
			</div>
			<div
				class="col-sm-6 col-xs-12 mb-10"
				*ngIf="work_sites_levels_form.controls['work_site_general_nos'].valid"
			>
				<label class="mb-10"
					>{{ "lang_fill_worklevels" | language }} :
					<span class="red">*</span></label
				>

				<ng-select
					[items]="work_levels"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					clearAllText="Clear"
					(change)="checkValidWorkLevel()"
					[searchFn]="ds.customSearchFn"
					appendTo="body"
					formControlName="work_levels"
					placeholder="{{ 'lang_fill_worklevels' | language }}"
				>
				</ng-select>

				<mat-error
					*ngIf="
						work_sites_levels_form.controls['work_levels'].errors?.required
					"
					>{{ "lang_field_required" | language }}</mat-error
				>
			</div>
			<div class="col-sm-12">
				<app-alert></app-alert>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="d-flex justify-content-between" style="width: 100%">
		<div style="width: 150px">
			<button
				mat-button
				mat-dialog-close
				class="albutton mnabrbutton mat-accent"
			>
				{{ "lang_cancel" | language }}
			</button>
		</div>

		<div style="width: 150px">
			<button
				mat-button
				class="albutton mnabrbutton mat-accent"
				[disabled]="!work_sites_levels_form.valid"
				(click)="onSubmit()"
			>
				{{ "lang_submit" | language }}
			</button>
		</div>
	</div>
</mat-dialog-actions>
