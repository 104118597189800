<form >
    <div class="row w-100 justify-content-around m-auto">
        <div class="col-md-5">
          <div class="col-md-12">
            <div class="form-group">
              <label class="select_rev_label">{{'lang_reviewers' | language }} 
              </label>
              <!-- <ng-select
              [items]="users_arr"
              [multiple]="true"
              appendTo="body"
              bindLabel="label"
              bindValue="value"
              [searchFn]="ds.customSearchFn"
              clearAllText="Clear"
              placeholder="{{'lang_users' | language}}"
              [searchable]="true"
              (change)="addEmp($event)"
              id="assigned_user"
              name="assigned_user"
              formControlName="name"
              (search)="search_users($event)"
              >
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items| slice:0:1">
                      <span class="ng-value-label">
                          <img [src]="item.userAvatar"  class="mx-2" width="20px" height="20px" *ngIf="item.userAvatar"> 
                          <img src="assets/images/avatar.png"  class="mx-2" width="20px" height="20px" *ngIf="!item.userAvatar"> 
                          <span style="font-size: 11px;margin: 0 1px;">{{item.label}}</span>
                      </span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
        
               <div class="ng-value" *ngIf="items.length > 1">
                   <span class="ng-value-label">{{items.length - 1}} {{'lang_more'|language}}</span>
               </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div style="display: flex; justify-content: start;align-items: center;width:100%">
                      <img [src]="item.userAvatar"   class="mx-2" width="20px" height="20px" *ngIf="item.userAvatar"> 
                      <img src="assets/images/avatar.png"  class="mx-2"  width="20px" height="20px" *ngIf="!item.userAvatar"> 
                      <span>{{item?.label}}</span>
                  </div>
                
              </ng-template>
          </ng-select> -->
            </div>
       
           </div>
    
           <div class="col-md-12 selected_reviewers" *ngIf="reviewers.length>0">
          
            <div *ngFor="let item of reviewers;let i =index" class="w-100">
              <div class="checkbox m-auto" >
                <!-- <input type="checkbox"  class="eachItemHR checkbox" value="{{item.employee_number}}" (click)="checkEachItemHR($event)"/> -->
                <input type="checkbox" [checked]="item.checked" (change)="toggleChecked($event,i)" class="eachItemHR checkbox" />
                <label for="checkbox">
             
                </label>
                <p class="name my-0 mx-2">
                  {{item.firstname_english+' '+item.secondname_english+' '+item.lastname_english}}
                </p>
              </div>
    
            </div>
           </div>
    
        </div>
      </div>
      <div>
        <div class="w-100 text-start mt-5">
          <button matStepperPrevious  class="btn mb-2 mx-2 btn-cancel">{{'lang_previous'|language}}</button>
          <button (click)="moveToAddToReviews()" class="btn mb-2 mx-2 btn-apply">{{'lang_add_to_reviewing'|language}}</button>
      </div>
      </div>

</form>
