import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-construction-management",
  templateUrl: "./construction-management.component.html",
  styleUrls: ["./construction-management.component.scss"],
})
export class ConstructionManagementComponent implements OnInit {
  @Input() report_data;
  constructor() {}

  ngOnInit(): void {}
}
