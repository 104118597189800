<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno">
    <mat-accordion  class="stylepanels">
      <mat-expansion-panel class="mpanel filepermissions" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{'lang_file_permissions' | language}}</mat-expansion-panel-header>
        <div class="material-panel-body">
            <div class="mbrl15 mb-0">
                <div class="row">
                    <div class="col-xs-12 col-12 pad0">
                        <mat-horizontal-stepper #stepper class="pad0">
                            <mat-step>
                                <ng-template matStepLabel>
                                    <div class="step">
                                        <div class="st">
                                            <i class="icon-5x fa fa-list fa-icon"></i>
                                        </div>
                                    </div>
                                    <div>{{'lang_group' | language }}</div>
                                </ng-template>
                                <app-user-groups (actionEvent)="load_realtime_records($event);"></app-user-groups>                      
                            </mat-step>
                            <mat-step>
                                <ng-template matStepLabel>
                                    <div class="step">
                                        <div class="st">
                                            <i class="icon-5x fa fa-file fa-icon"></i>
                                        </div>
                                    </div>
                                    <div>{{'lang_file_level' | language }}</div>
                                </ng-template>
                                <app-files-level (actionEvent)="load_realtime_records($event);"></app-files-level>
                            </mat-step>
                            <mat-step>
                                <ng-template matStepLabel>
                                    <div class="step">
                                        <div class="st">
                                            <i class="icon-5x fa fa-object-group fa-icon"></i>
                                        </div>
                                    </div>
                                    <div>{{'lang_file_level_group' | language }}</div>
                                </ng-template>
                                <app-files-level-groups (actionEvent)="load_realtime_records($event);"></app-files-level-groups>
                            </mat-step>
                            <mat-step>
                                <ng-template matStepLabel>
                                    <div class="step">
                                        <div class="st">
                                            <i class="icon-5x fa fa-users fa-icon"></i>
                                        </div>
                                    </div>
                                    <div>{{'lang_file_user_permission' | language }}</div>
                                </ng-template>
                                <app-file-users-groups (actionEvent)="load_realtime_records($event);"></app-file-users-groups>
                            </mat-step>
                            <mat-step>
                                <ng-template matStepLabel>
                                    <div class="step">
                                        <div class="st">
                                            <i class="icon-5x fa fa-files-o fa-icon"></i>
                                        </div>
                                    </div>
                                    <div>{{'lang_module_files_types' | language }}</div>
                                </ng-template>
                                <app-files-types (actionEvent)="load_realtime_records($event);"></app-files-types>
                            </mat-step>
                            <mat-step>
                                <ng-template matStepLabel>
                                    <div class="step">
                                        <div class="st">
                                            <i class="icon-5x fa fa-paperclip fa-icon"></i>
                                        </div>
                                    </div>
                                    <div>{{'lang_module_files_settings' | language }}</div>
                                </ng-template>
                                <app-files-module-settings (actionEvent)="load_realtime_records($event);"></app-files-module-settings>
                            </mat-step>
                        </mat-horizontal-stepper>
                    </div>
                </div>
            </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</section>