import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-qc-add-edit",
	templateUrl: "./qc-add-edit.component.html",
	styleUrls: ["./qc-add-edit.component.scss"],
})
export class QcAddEditComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	conditionForm: FormGroup;
	conditions_data;
	edit_mode = false;
	constructor(
		private fb: FormBuilder,
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private spinner: PreloaderService,
		private dialogRef: MatDialogRef<QcAddEditComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.conditions_data = data?.conditions_data;
		this.edit_mode = this.conditions_data ? true : false;
	}

	ngOnInit(): void {
		this.buildForm();
		if (this.edit_mode) {
			this.setEditData();
		}
	}

	buildForm() {
		this.conditionForm = this.fb.group({
			rf_name_ar: [null, Validators.required],
			rf_name_en: [null, [Validators.required]],
			quotation_types_keyword: [null, [Validators.required]],
			rf_required_or_optional: [null, [Validators.required]],
			condition_type: [null, [Validators.required]],
			rf_sort: [null, [Validators.required]],
		});
	}
	setEditData() {
		this.conditionForm
			.get("rf_name_ar")
			.setValue(this.conditions_data.rf_name_ar);
		this.conditionForm
			.get("rf_name_en")
			.setValue(this.conditions_data.rf_name_en);
		this.conditionForm
			.get("quotation_types_keyword")
			.setValue(this.conditions_data.quotation_types_keyword);
		this.conditionForm
			.get("rf_required_or_optional")
			.setValue(this.conditions_data.rf_required_or_optional);
		this.conditionForm
			.get("condition_type")
			.setValue(this.conditions_data.condition_type);
		this.conditionForm.get("rf_sort").setValue(this.conditions_data.rf_sort);
	}

	addEditCondition() {
		this.spinner.show();
		let param = new FormData();
		let url = "quotation_setting/add_new_condition";
		if (this.edit_mode) {
			param.append("quotation_types_id", this.conditions_data.rf_id);
			url = "quotation_setting/update_condition";
		}
		param.append("rf_name_ar", this.conditionForm.value.rf_name_ar);
		param.append("rf_name_en", this.conditionForm.value.rf_name_en);
		param.append(
			"quotation_types_keyword",
			this.conditionForm.value.quotation_types_keyword
		);
		param.append(
			"rf_required_or_optional",
			this.conditionForm.value.rf_required_or_optional
		);
		param.append("condition_type", this.conditionForm.value.condition_type);
		param.append("rf_sort", this.conditionForm.value.rf_sort);
		this.ds.post(url, param).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						2000
					);
					this.dialogRef.close(true);
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
}
