import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
	selector: "app-design-workarea",
	templateUrl: "./design-workarea.component.html",
	styleUrls: ["./design-workarea.component.scss"],
})
export class DesignWorkareaComponent implements OnInit, AfterViewInit {
	project_profile_id = this.route.snapshot.paramMap.get("pid");
	project_design_id = this.route.snapshot.paramMap.get("did");
	lang_key = localStorage.getItem("lang_key") || "en";
	wholedata: any;
	design_data: any;
	super_add: boolean = false;
	super_view: boolean = false;
	super_edit: boolean = false;
	@ViewChild("stepper") stepper: MatStepper;
	customOptions: OwlOptions = {
		loop: false,
		rtl: this.lang_key == "en" ? false : true,
		mouseDrag: true,
		touchDrag: false,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		navText: ["", ""],
		responsive: {
			0: {
				items: 1,
			},
			400: {
				items: 2,
			},
			740: {
				items: 3,
			},
			940: {
				items: 3,
			},
			1200: {
				items: 3,
			},
		},
		nav: false,
	};
	selectedName = "contract";
	constructor(
		public route: ActivatedRoute,
		private ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private router: Router
	) {}

	ngOnInit(): void {
		this.getDesignData();
		this.get_project_data();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			document
				.querySelectorAll(".stepper-header-icon")[0]
				?.classList?.add("stepper-active");
		}, 1000);
	}

	moveStepper(name: string) {
		const icons = document?.querySelectorAll(".stepper-header-icon");
		const selectedIcon = document?.querySelector(`.${name}`);
		let index: number = 0;
		icons.forEach((item, key) => {
			item?.classList?.remove("stepper-active");
			if (item?.classList?.contains(name)) {
				index = key;
			}
		});
		selectedIcon?.classList?.add("stepper-active");
		this.stepper.selectedIndex = index;
		this.selectedName = name;
	}

	getDesignData() {
		let formData = new FormData();
		formData.append("project_design_id", this.project_design_id);
		this.ds.post("project_design/row_project_design", formData).subscribe(
			(res) => {
				if (res?.status) {
					this.design_data = res.data;
				}
			},
			(err) => {
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
				this.router.navigate([`/projects/LtYh/${this.project_profile_id}`]);
			}
		);
	}

	get_project_data() {
		this.spinner.show();
		this.ds
			.getActionByUrl([], "TEd1bgyHSC0GPcq/" + this.project_profile_id)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.wholedata = res;
					} else {
						this.sweetAlert.errorToast(res.error, 2000);
					}
				},
				(error) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
}
