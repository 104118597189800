import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-issue-log",
  templateUrl: "./issue-log.component.html",
  styleUrls: ["./issue-log.component.scss"],
})
export class IssueLogComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key")
    ? localStorage.getItem("lang_key")
    : "en";
  report_Data;
  no_of_closed_issue = 0;
  no_of_open_issue = 0;
  @Input() set report_data(data: any) {
    this.report_Data = data;
    data?.issue_counts.forEach((v) => {
      console.log(v);
      console.log(data);
      if (v?.issue_status == "open") {
        this.no_of_open_issue = v?.total;
      }
      if (v?.issue_status == "close") {
        this.no_of_closed_issue = v?.total;
      }
    });
  }
  constructor() {}

  ngOnInit(): void {}
}
