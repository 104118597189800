import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-cost-center-view',
  templateUrl: './cost-center-view.component.html',
  styleUrls: ['./cost-center-view.component.scss']
})
export class CostCenterViewComponent implements OnInit {
  viewdata:any = [];
  @Input() costdata;
  constructor() { }
  ngOnInit(): void {
    this.viewdata = this.costdata;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.viewdata = this.costdata;
  }
}
