<div class="card">
	<div class="card-header d-flex align-items-center justify-content-between">
		<span>{{ "lang_create_contractor_payment" | language }}</span>
	</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-12 col-sm-4 mb-3">
					<div class="card-item" *ngIf="!isLoading && divAmounts">
						<div class="item-icon">
							<i class="fa fa-map"></i>
						</div>
						<div class="item-text">
							<h5>{{ "lang_invoice_total_amount" | language }}</h5>
							<p>
								{{ "lang_payment_amount" | language }} :
								{{ ds.addCommas(fixedA(divAmounts?.total_amount)) }}
							</p>
							<p>
								{{ "lang_pending_amount" | language }} :
								{{ ds.addCommas(fixedA(divAmounts?.pending_total_amount)) }}
							</p>
							<p>
								{{ "lang_paid_amount" | language }} :
								{{ ds.addCommas(fixedA(divAmounts?.paid_total_amount)) }}
							</p>
						</div>
					</div>
					<ngx-skeleton-loader
						style="width: 100%"
						*ngIf="isLoading && !divAmounts"
						count="1"
						appearance="circle"
						[theme]="{
							width: '100%',
							height: '70px',
							'border-radius': '10px'
						}"
					></ngx-skeleton-loader>
				</div>
				<div class="col-12 col-sm-4 mb-3">
					<div
						class="card-item"
						*ngIf="!isLoading && divAmounts"
						style="background: linear-gradient(60deg, #2644da, #006dc1)"
					>
						<div class="item-icon">
							<i class="fa fa-th-large"></i>
						</div>
						<div class="item-text">
							<h5>{{ "lang_total_ratio" | language }}</h5>
							<p>
								{{ "lang_payment_ratio" | language }} :
								{{ ratio(fixedA(divAmounts?.total_ratio), "C") }}
							</p>
							<p>
								{{ "lang_pending_ratio" | language }} :
								{{ ratio(fixedA(divAmounts?.pending_total_ratio), "P") }}
							</p>
							<p>
								{{ "lang_paid_ratio" | language }} :
								{{ ratio(fixedA(divAmounts?.paid_total_ratio), "D") }}
							</p>
						</div>
					</div>
					<ngx-skeleton-loader
						style="width: 100%"
						*ngIf="isLoading && !divAmounts"
						count="1"
						appearance="circle"
						[theme]="{
							width: '100%',
							height: '70px',
							'border-radius': '10px'
						}"
					></ngx-skeleton-loader>
				</div>
				<div class="col-12 col-sm-4 mb-3">
					<div
						class="card-item"
						*ngIf="!isLoading && divAmounts"
						style="background: linear-gradient(60deg, #26dad2, #00c174)"
					>
						<div class="item-icon">
							<i class="fa fa-road"></i>
						</div>
						<div class="item-text">
							<h5>{{ "lang_total_weights" | language }}</h5>
							<p>
								{{ "lang_payment_weight" | language }} :
								{{ ratio(fixedA(divAmounts?.total_weight), "C") }}
							</p>
							<p>
								{{ "lang_pending_weight" | language }} :
								{{ ratio(fixedA(divAmounts?.pending_total_weight), "P") }}
							</p>
							<p>
								{{ "lang_paid_weight" | language }} :
								{{ ratio(fixedA(divAmounts?.paid_total_weight), "D") }}
							</p>
						</div>
					</div>
					<ngx-skeleton-loader
						style="width: 100%"
						*ngIf="isLoading && !divAmounts"
						count="1"
						appearance="circle"
						[theme]="{
							width: '100%',
							height: '70px',
							'border-radius': '10px'
						}"
					></ngx-skeleton-loader>
				</div>
			</div>

			<div class="row">
				<div class="col-12 col-sm-3 mb-10">
					<button
						class="albutton mnabrbutton"
						mat-raised-button
						(click)="onFilters()"
					>
						<i class="fa fa-filter"></i> {{ "lang_advanced_filter" | language }}
					</button>
				</div>
				<div class="col-12 col-sm-3 mb-10">
					<button
						mat-button
						style="background-color: rgb(113 166 223 / 42%); width: 100%"
						(click)="downloadExcel()"
					>
						<i
							class="fa fa-download"
							style="font-size: 20px; margin: 0 5px; color: rgb(19, 65, 146)"
						></i>
						<span
							style="
								text-transform: uppercase;
								font-weight: 600;
								color: rgb(19, 65, 146);
								font-size: 12px;
							"
							>{{ "lang_download_excel" | language }}</span
						>
					</button>
				</div>
				<div class="col-12 col-sm-3 mb-10">
					<input
						type="file"
						#uploadExcelFile
						id="uploadExcelInput"
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						hidden
						(change)="uploadExcel($event.target.files)"
					/>
					<button
						mat-button
						style="background-color: rgb(136 224 136 / 42%); width: 100%"
						(click)="uploadExcelFile.click()"
					>
						<i
							class="fa fa-upload"
							style="font-size: 20px; margin: 0 5px; color: rgb(19, 146, 19)"
						></i>
						<span
							style="
								text-transform: uppercase;
								font-weight: 600;
								color: rgb(19, 146, 19);
								font-size: 12px;
							"
							>{{ "lang_upload_excel" | language }}</span
						>
					</button>
				</div>
				<div class="col-12 col-sm-3 mb-10">
					<button
						mat-button
						style="
							background-color: rgb(224 136 200 / 42%);
							width: 100%;
							margin-top: 10px;
						"
						(click)="show_history()"
					>
						<i
							class="fa fa-download"
							style="font-size: 20px; margin: 0 5px; color: rgb(146, 19, 102)"
						></i>
						<span
							style="
								text-transform: uppercase;
								font-weight: 600;
								color: rgb(146, 19, 102);
								font-size: 12px;
							"
							>{{ "lang_history" | language }}</span
						>
					</button>
				</div>
				<div class="col-0 col-sm-6">
					<span *ngIf="total_templates > 1" class="red">{{
						"lang_mulitple_template" | language
					}}</span>
				</div>
				<div class="col-12 col-sm-3"></div>
				<div
					class="col-md-12 table-container contractorpayment TableParts"
					*ngIf="unitData.length != 0"
				>
					<div
						class="table-responsive customResponsive mb-10 tablefields"
						id="table-container"
					>
						<table class="main-table table CustomTable text-center">
							<thead>
								<!-- group1 -->
								<tr>
									<th
										style="
											width: 15%;
											color: #035a99;
											border-left: 1px solid rgba(148, 148, 148, 0.747);
										"
									></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<ng-container *ngIf="total_templates == 1">
										<th
											style="
												width: 15%;
												color: #035a99;
												left: 23px;
												color: #fff !important;
											"
											*ngFor="let div of convertO2A(divTitles)"
											[ngStyle]="{ background: div.color }"
											[colSpan]="getGroup1Colspan(convertO2A(div?.group2))"
										>
											{{ div?.group1name }} ({{ ratio(div.g1_total_weight) }})
										</th>
									</ng-container>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
								</tr>
								<!-- group2 -->
								<tr>
									<th
										style="
											width: 15%;
											color: #035a99;
											width: 27px;
											border-left: 1px solid rgba(148, 148, 148, 0.747);
										"
									></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<ng-container *ngIf="total_templates == 1">
										<th
											style="width: 15%; left: 23px"
											*ngFor="let div of convertO2A(group2Names)"
											[ngStyle]="{ background: div.color }"
											[colSpan]="getGroup2Colspan(convertO2A(div?.platforms))"
										>
											{{ div?.group2name }} ({{ ratio(div.g2_total_weight) }})
										</th>
									</ng-container>

									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
								</tr>
								<!-- platforms -->
								<tr>
									<th
										style="
											width: 15%;
											color: #035a99;
											width: 27px;
											border-left: 1px solid rgba(148, 148, 148, 0.747);
										"
									></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<ng-container *ngIf="total_templates == 1">
										<th
											style="width: 15%; left: 23px"
											*ngFor="let div of convertO2A(platformNames)"
											[ngStyle]="{ background: div.color }"
											[colSpan]="convertO2A(div?.levels)?.length"
										>
											{{ div?.platformname }}
											{{ div?.platform_code_system }} ({{
												ratio(div.p_total_weight)
											}})
										</th>
									</ng-container>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
									<th style="width: 15%; color: #035a99; left: 23px"></th>
								</tr>
								<!-- worklevels -->
								<tr>
									<th
										style="
											width: 15%;
											color: #035a99;
											width: 27px;
											border-left: 1px solid rgba(148, 148, 148, 0.747);
										"
									>
										#
									</th>
									<th style="width: 15%; color: #035a99; left: 23px">
										{{ "lang_villa_no" | language }}
									</th>
									<th style="width: 15%; color: #035a99; left: 23px">
										{{ "lang_unit_cost" | language }}
									</th>
									<th style="width: 25%; color: #035a99; left: 23px">
										{{ "lang_total_payment_ratio" | language }}
									</th>
									<th style="width: 25%; color: #035a99; left: 23px">
										{{ "lang_pending_ratio" | language }}
									</th>
									<th style="width: 25%; color: #035a99; left: 23px">
										{{ "lang_total_paid_ratio" | language }}
									</th>
									<ng-container *ngIf="total_templates == 1">
										<th
											style="width: 15%; left: 23px"
											*ngFor="let div of convertO2A(worklevelNames)"
											[ngStyle]="{ background: div.color }"
										>
											{{ div?.worklevelname }} {{ div?.work_level }} ({{
												ratio(div.wl_total_weight)
											}})
										</th>
									</ng-container>
									<th style="width: 20%; color: #035a99; left: 23px">
										{{ "lang_payment_cost" | language }}
									</th>
									<th style="width: 20%; color: #035a99; left: 23px">
										{{ "lang_pending_cost" | language }}
									</th>
									<th style="width: 20%; color: #035a99; left: 23px">
										{{ "lang_paid_cost" | language }}
									</th>
									<th style="width: 20%; color: #035a99; left: 23px">
										{{ "lang_total_cost" | language }}
									</th>
									<th style="width: 20%; color: #035a99; left: 23px">
										{{ "lang_remaining_cost" | language }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of unitData; let unit_index = index">
									<td class="sticky" style="padding: 5px">
										{{ unit_index + 1 }}
									</td>
									<td class="sticky" style="padding: 5px">
										{{ item?.unit_label }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ ds.addCommas(fixedA(item?.unit_cost)) }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ ds.addCommas(fixedA(item?.total_weight)) }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ ds.addCommas(fixedA(item?.pending_total_weight)) }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ ds.addCommas(fixedA(item?.paid_total_weight)) }}
									</td>
									<ng-container *ngIf="total_templates == 1">
										<td
											class="sticky"
											style="left: 23px; padding: 5px"
											*ngFor="let title of item?.divs"
										>
											{{ ds.addCommas(fixedA(title.total_amount)) }}
										</td>
									</ng-container>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ ds.addCommas(fixedA(item?.total_amount)) }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ ds.addCommas(fixedA(item?.pending_total_amount)) }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ ds.addCommas(fixedA(item?.paid_total_amount)) }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ ds.addCommas(fixedA(item?.total_cost)) }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ ds.addCommas(fixedA(item?.remaining_cost)) }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div *ngIf="unitPage.length != 0">
				<div class="row responsive_pagination">
					<div
						class="col-lg-3 col-sm-3 mt-10 mb-10"
						style="height: fit-content; margin: auto 0"
					>
						{{ "lang_total" | language }} :
						{{ unitPage?.total_records }}
					</div>
					<div
						class="col-lg-4 col-sm-4 mt-10 mb-10"
						style="display: flex; justify-content: center"
					>
						<ngb-pagination
							class="m-auto"
							[collectionSize]="unitPage?.total_records"
							[rotate]="true"
							[ellipses]="false"
							[maxSize]="3"
							[boundaryLinks]="true"
							[(page)]="page_no"
							[pageSize]="page_size"
							(pageChange)="getBuildDataPage($event)"
						></ngb-pagination>
					</div>
					<div
						class="col-lg-2 col-sm-2 mt-10 mb-10"
						style="display: flex; justify-content: end; margin: auto"
					>
						<mat-select
							[(ngModel)]="page_size"
							(selectionChange)="getBuildDataPageSize($event.value)"
						>
							<mat-option class="text-center" value="10"
								>10 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="20"
								>20 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="50"
								>50 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="100"
								>100 {{ "lang_per_page" | language }}</mat-option
							>
							<mat-option class="text-center" value="500"
								>500 {{ "lang_per_page" | language }}</mat-option
							>
						</mat-select>
					</div>
					<div class="col-lg-3 col-sm-3 mt-10 mb-10">
						<button
							class="albutton mnabrbutton"
							mat-raised-button
							(click)="onCreate()"
							*ngIf="unitData && makeActionList"
						>
							<i
								class="fa fa-newspaper-o ml-1 mr-1"
								style="font-size: 18px"
							></i>
							<span>{{ "lang_submit" | language }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
