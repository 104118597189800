import {
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
declare var $: any;
@Component({
	selector: "app-invoice-settings",
	templateUrl: "./invoice-settings.component.html",
	styleUrls: ["./invoice-settings.component.scss"],
})
export class InvoiceSettingsComponent implements OnInit, OnDestroy {
	modalRef: BsModalRef;
	modalRef1: BsModalRef;
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}
	@ViewChild("viewASInvModal") dataview;
	@ViewChild("InvSettingsOperation") dataedit;
	settings = environment.multipledropdown;
	branch_id: any =
		localStorage.getItem("selectedBranch") != null
			? localStorage.getItem("selectedBranch")
			: "0";
	asinvdata: any = [];
	viewdata: any = [];
	paymodes: any = [];
	caccounts: any = [];
	iaccounts: any = [];
	eaccounts: any = [];
	aaccounts: any = [];
	daccounts: any = [];
	financeList: any = [];
	form: FormGroup;
	mode: string = "";
	lodingdatas = this.lang.transform("lang_loading");
	searchKey = "";
	searchType = "";
	finance_id = null;
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public fb: FormBuilder,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService,
		private sweetAlert: SweetAlertService
	) {}
	ngOnInit(): void {
		this.build_form();
		if (this.branch_id && this.branch_id != "0") {
			this.load_invoice_settings();
			this.load_pmodes();
			this.getFinancialList();
		}
	}
	public changeBranch(branch) {
		this.branch_id = branch;
		this.load_pmodes();
		this.load_invoice_settings();
		this.getFinancialList();
	}
	selectFinance(finance_id) {
		this.finance_id = finance_id;
		this.load_invoice_settings();
	}
	private subscriptions = new Subscription();
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
	public load_pmodes() {
		this.spinner.show();
		this.paymodes = [];
		this.subscriptions.add(
			this.ds.getActionByUrl([], "pmodes/" + this.branch_id).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.paymodes = res.records;
					}
				},
				(error) => {
					this.spinner.hide();
				}
			)
		);
	}
	public build_form() {
		this.form = new FormGroup({
			branch_id: new FormControl(this.branch_id, [Validators.required]),
			invoice_type: new FormControl("", [Validators.required]),
			payment_mode: new FormControl("", [Validators.required]),
			customer_accounts: new FormControl(""),
			income_accounts: new FormControl(""),
			expanse_accounts: new FormControl(""),
			advance_accounts: new FormControl(""),
			discount_accounts: new FormControl(""),
			finance_id: new FormControl(null, [Validators.required]),
		});
	}
	public emptyinvformdata(mode = "ADD") {
		this.caccounts =
			this.iaccounts =
			this.eaccounts =
			this.aaccounts =
			this.daccounts =
				[];
		this.form.reset();
		this.form.get("branch_id").setValue(this.branch_id);
		this.alert.clear();
		this.mode = mode;
	}
	public load_invoice_settings() {
		let param = new FormData();
		param.append("search_key", this.searchKey);
		param.append("invoice_type", this.searchType);
		param.append("finance_id", this.finance_id || "");
		this.subscriptions.add(
			this.ds.postActionByUrl(param, "invlists/" + this.branch_id).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.asinvdata = res.records;
					} else {
						this.asinvdata = [];
						this.lodingdatas = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.asinvdata = [];
					this.lodingdatas =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			)
		);
	}

	public showASInvData(data) {
		this.spinner.show();
		this.viewdata = [];
		let formData = new FormData();
		formData.append("finance_id", this.finance_id || "");
		this.subscriptions.add(
			this.ds
				.getActionByUrl(
					this.ds.formData2string(formData),
					"invview/" + this.branch_id + "/" + data.invoice_setting_id
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.modalRef1 = this.modalService.show(this.dataview);
							this.viewdata = res.records;
						} else {
							this.ds.dialogf("", res.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.ds.dialogf(
							"",
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}
	public editASInvData(data) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("finance_id", this.finance_id || "");
		this.subscriptions.add(
			this.ds
				.getActionByUrl(
					this.ds.formData2string(formData),
					"invedit/" + this.branch_id + "/" + data.invoice_setting_id
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.openModal(this.dataedit);
							this.emptyinvformdata("EDIT");
							this.form.patchValue(res.records);
						}
					},
					(error) => {
						this.spinner.hide();
					}
				)
		);
	}
	public deleteInvData(data) {
		const dialogRef = this.dialog.open(ConfirmationDialog, {
			disableClose: true,
			width: "400px",
			data: {
				title: this.lang.transform("lang_confirmation"),
				message: this.lang.transform("lang_are_you_sure"),
			},
		});
		this.subscriptions.add(
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show();
					this.subscriptions.add(
						this.ds
							.deleteActionByUrl(
								[data.invoice_setting_id],
								"ainvd/" + this.branch_id
							)
							.subscribe(
								(data) => {
									this.spinner.hide();
									if (data.status) {
										this.load_invoice_settings();
										this.ds.dialogf("", data.msg);
									} else {
										this.ds.dialogf("", data.error);
									}
								},
								(error) => {
									this.spinner.hide();
									this.ds.dialogf(
										"",
										error && error.error && error.error.error
											? error.error.error
											: this.lang.transform("lang_internal_server_error")
									);
								}
							)
					);
				}
			})
		);
	}
	public submit() {
		this.spinner.show();
		let param = new FormData();
		param.set("branch_id", this.branch_id);
		param.set(
			"invoice_type",
			this.form.get("invoice_type").value &&
				this.form.get("invoice_type").value != null
				? this.form.get("invoice_type").value
				: ""
		);
		param.set(
			"payment_mode",
			this.form.get("payment_mode").value &&
				this.form.get("payment_mode").value != null
				? this.form.get("payment_mode").value
				: ""
		);
		param.set(
			"customer_accounts",
			this.ds.select_array_string(this.form, "customer_accounts", "value")
		);
		param.set(
			"income_accounts",
			this.ds.select_array_string(this.form, "income_accounts", "value")
		);
		param.set(
			"expanse_accounts",
			this.ds.select_array_string(this.form, "expanse_accounts", "value")
		);
		param.set(
			"advance_accounts",
			this.ds.select_array_string(this.form, "advance_accounts", "value")
		);
		param.set(
			"discount_accounts",
			this.ds.select_array_string(this.form, "discount_accounts", "value")
		);
		param.set("finance_id", this.form.get("finance_id").value || "");
		let url = this.mode == "ADD" ? "ainvc" : "ainvu";
		this.subscriptions.add(
			this.ds.postActionByUrl(param, url).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.form.reset();
						this.load_invoice_settings();
						this.alert.success(res.msg);
						setTimeout(() => {
							this.modalRef.hide();
						}, 2000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			)
		);
	}
	public searchAccounts(evt: any, mode) {
		this.caccounts =
			this.iaccounts =
			this.eaccounts =
			this.aaccounts =
			this.daccounts =
				[];
		if (this.form.get("branch_id").value) {
			let param = new FormData();
			param.append("branch_id", this.form.get("branch_id").value);
			param.append("finance_id", this.finance_id || "");
			param.append("search_text", evt.target.value);
			let permit_key = this.mode === "ADD" ? "accounts_add" : "accounts_edit";
			this.subscriptions.add(
				this.ds
					.postActionByUrl(param, "sam/" + permit_key)
					.subscribe((data) => {
						if (data.status) {
							if (mode == "caccounts") {
								this.caccounts = data.records;
							} else if (mode == "iaccounts") {
								this.iaccounts = data.records;
							} else if (mode == "eaccounts") {
								this.eaccounts = data.records;
							} else if (mode == "aaccounts") {
								this.aaccounts = data.records;
							} else if (mode == "daccounts") {
								this.daccounts = data.records;
							}
						}
					})
			);
		} else {
			this.ds.dialogf("", this.lang.transform("lang_choose_branch"));
		}
	}

	getFinancialList() {
		this.financeList = [];
		this.finance_id = null;
		this.subscriptions.add(
			this.ds
				.getActionByUrl([], "financial/" + this.form.get("branch_id").value)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.financeList = res.records;
							this.financeList.forEach((v, k) => {
								if (v.finance_status == "1") {
									this.finance_id = v.finance_id;
								}
							});
						}
					},
					(error) => {
						this.spinner.hide();
					}
				)
		);
	}
}
