<mat-horizontal-stepper #stepper linear class="pad0 matborderno">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <mat-step *ngIf="editViewData && editViewData.projects_setting_consultant_type =='FC' || divisionAction=='ADD' ">
            <ng-template matStepLabel>
                <div class="step">
                    <div class="st">
                        <i class="icon-5x fa fa-list fa-icon"></i>
                    </div>
                </div>
                <div>{{'lang_managing_from_consultants' | language }}</div>
            </ng-template>
            <div class="card appconsultants">
                <div class="card-body pad0">
                    <div class="mbrl15">
                        <div class="rowsdata" *ngIf="editViewData && editViewData.projects_setting_consultant_type =='FC' || divisionAction=='ADD' ">
                            <div class="TableParts">
                                <div class="table-responsive customResponsive mb-10 tablefields">
                                    <table border="1" class="table CustomTable text-center">
                                        <thead>
                                            <tr>
                                                <td width="5%">#</td>
                                                <td *ngIf="divisionAction == 'ADD' " width="5%"><i class="fa fa-plus-square fa-icon" matTooltip="{{'lang_add' | language }}" (click)="add_new_rows();"></i></td>
                                                <td width="20%">{{'lang_from_consultants' | language }}<i *ngIf="divisionAction != 'VIEW' " class="red"> * </i></td>
                                                <td width="25%">{{'lang_receipient_position' | language }}<i *ngIf="divisionAction != 'VIEW' " class="red"> * </i></td>
                                                <td width="25%">{{'lang_authendicate_position' | language }}<i *ngIf="divisionAction != 'VIEW' " class="red"> * </i></td>
                                                <td width="20%">{{'lang_platform' | language }}<i *ngIf="divisionAction != 'VIEW' " class="red"> * </i></td>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="from_consultant" class="AppendList">
                                            <tr *ngFor="let item of rows_group().controls; let itemIndex = index;let odd=odd; let even=even" [formGroupName]="itemIndex" style="background: #ffffff;">
                                                <td width="5%" class="verticalalignmid">{{itemIndex+1}}</td>
                                                <td *ngIf="divisionAction == 'ADD' " width="5%" class="verticalalignmid"><i (click)="remove_row(itemIndex);" class="fa fa-minus-square fa-icon mnabricon red" matTooltip="{{'lang_delete' | language }}"></i></td>
                                                <td>
                                                    <ng-select 
                                                        class="DisabledSelectClass"
                                                        [items]="fconsultants" 
                                                        [multiple]="true" 
                                                        bindLabel="label" 
                                                        bindValue="value" 
                                                        appendTo="body" 
                                                        clearAllText="Clear"
                                                        formControlName="projects_setting_consultant_id_linked"
                                                        placeholder="{{'lang_from_consultants' | language}}">
                                                    </ng-select>
                                                </td>
                                                <td>
                                                    <ng-select 
                                                        class="DisabledSelectClass"
                                                        [items]="positions" 
                                                        [multiple]="true" 
                                                        bindLabel="label" 
                                                        bindValue="value" 
                                                        appendTo="body" 
                                                        clearAllText="Clear"
                                                        formControlName="projects_setting_consultant_position_code"
                                                        placeholder="{{'lang_position' | language}}">
                                                    </ng-select>
                                                </td>
                                                <td>
                                                    <ng-select 
                                                        class="DisabledSelectClass"
                                                        [items]="positions" 
                                                        [multiple]="true" 
                                                        bindLabel="label" 
                                                        bindValue="value" 
                                                        appendTo="body" 
                                                        clearAllText="Clear"
                                                        formControlName="projects_setting_consultant_authendicate_position_code"
                                                        placeholder="{{'lang_position' | language}}">
                                                    </ng-select>
                                                </td>
                                                <td>
                                                    <ng-select 
                                                        class="DisabledSelectClass"
                                                        [items]="platforms" 
                                                        [multiple]="true" 
                                                        bindLabel="label" 
                                                        bindValue="value" 
                                                        appendTo="body" 
                                                        clearAllText="Clear"
                                                        formControlName="platform_code_system"
                                                        placeholder="{{'lang_platform' | language}}">
                                                    </ng-select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer pad15">
                        <div class="row">
                            <div class="col-md-2 col-xs-12 mb-10">
                                <button type="button" class="albutton" (click)="action.emit('LIST');">{{'lang_cancel' | language }}</button>
                            </div>
                            <div class="col-md-8 col-xs-12 mb-10">
                                <app-alert id="default-alert"></app-alert>
                            </div>
                            <div class="col-md-2 col-xs-12 mb-10" *ngIf="divisionAction == 'ADD' ">
                                <button type="submit" class="albutton" matStepperNext (click)="consultant_requirements.controls = [];add_new_rowRs();">{{'lang_next' | language }}</button>
                            </div>
                            <div class="col-md-2 col-xs-12 mb-10" *ngIf="divisionAction == 'EDIT' ">
                                <button type="submit" class="albutton" (click)="submit();">{{'lang_submit' | language }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-step>
        <mat-step *ngIf="editViewData && editViewData.projects_setting_consultant_type !='FC' || divisionAction=='ADD' ">
            <ng-template matStepLabel (click)="add_new_rowRs();">
                <div class="step">
                    <div class="st">
                        <i class="icon-5x fa fa-list fa-icon"></i>
                    </div>
                </div>
                <div>{{'lang_consultants_requirements' | language }}</div>
            </ng-template>
            <div class="card appconsultants">
                <div class="card-body pad0">
                    <div class="mbrl15">
                        <div class="rowRsdata" *ngIf="editViewData && editViewData.projects_setting_consultant_type !='FC' || divisionAction=='ADD' ">
                            <div class="TableParts">
                                <div class="table-responsive customResponsive mb-10 tablefields">
                                    <table border="1" class="table CustomTable text-center">
                                        <thead>
                                            <tr>
                                                <td width="5%">#</td>
                                                <td *ngIf="divisionAction == 'ADD' " width="5%"><i class="fa fa-plus-square fa-icon" matTooltip="{{'lang_add' | language }}" (click)="add_new_rowRs();"></i></td>
                                                <td width="45%">{{'lang_consultants_requirements' | language }}<i *ngIf="divisionAction != 'VIEW' " class="red"> * </i></td>
                                                <td width="45%">{{'lang_platform' | language }}</td>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="consultant_requirments" class="AppendList">
                                            <tr *ngFor="let item of rowRs_group().controls; let itemIndex = index;let odd=odd; let even=even" [formGroupName]="itemIndex" style="background: #ffffff;">
                                                <td width="5%" class="verticalalignmid">{{itemIndex+1}}</td>
                                                <td *ngIf="divisionAction == 'ADD' " width="5%" class="verticalalignmid"><i (click)="remove_rowR(itemIndex);" class="fa fa-minus-square fa-icon mnabricon red" matTooltip="{{'lang_delete' | language }}"></i></td>
                                                <td width="45%">
                                                    <ng-select 
                                                        class="DisabledSelectClass"
                                                        [items]="consultantr" 
                                                        [multiple]="true" 
                                                        bindLabel="label" 
                                                        bindValue="value" 
                                                        appendTo="body" 
                                                        clearAllText="Clear"
                                                        formControlName="projects_setting_consultant_id_linked"
                                                        placeholder="{{'lang_consultants_requirements' | language}}">
                                                    </ng-select>
                                                </td>
                                                <td width="45%">
                                                    <ng-select 
                                                        class="DisabledSelectClass"
                                                        [items]="platforms" 
                                                        [multiple]="true" 
                                                        bindLabel="label" 
                                                        bindValue="value" 
                                                        [disabled]="true"
                                                        appendTo="body" 
                                                        clearAllText="Clear"
                                                        [(ngModel)]="platform_code_system"
                                                        [ngModelOptions]="{standalone: true}"
                                                        placeholder="{{'lang_platform' | language}}">
                                                    </ng-select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer pad15">
                        <div class="row">
                            <div class="col-md-2 col-xs-12 mb-10">
                                <button type="button" class="albutton" (click)="action.emit('LIST');">{{'lang_cancel' | language }}</button>
                            </div>
                            <div class="col-md-2 col-xs-12 mb-10">
                                <button  *ngIf="divisionAction == 'ADD' " type="button" class="albutton" matStepperPrevious>{{'lang_previous' | language }}</button>
                            </div>                           
                            <div class="col-md-6 col-xs-12 mb-10">
                                <app-alert id="default-alert"></app-alert>
                            </div>
                            <div class="col-md-2 col-xs-12 mb-10" *ngIf="divisionAction != 'VIEW' ">
                                <button type="submit" class="albutton" (click)="submit();">{{'lang_submit' | language }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-step>
    </form>
</mat-horizontal-stepper>