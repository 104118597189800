import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
interface cilist {
  id?: number;
  class: string;
  account_code: string;
  account_name: string;
  amount: string;
}
interface celist {
  id?: number;
  class: string;
  account_code: string;
  account_name: string;
  amount: string;
}
@Component({
  selector: "app-income-statement",
  templateUrl: "./income-statement.component.html",
  styleUrls: ["./income-statement.component.scss"],
})
export class IncomeStatementComponent implements OnInit, OnDestroy {
  form: FormGroup;
  branches: any = [];
  finances: any = [];
  lodingdatas = this.lang.transform("lang_loading");
  finance_id: any = null;
  datastatus = false;
  //pageing 3 for Previous Year Income Statements
  cipage = 1;
  cipageSize = 10;
  cicollectionSize;
  cilistofdata: cilist[];
  cidata: any = [];
  citotals: any = [];
  //pageing 4 for Previous Year Expense Statements
  cepage = 1;
  cepageSize = 10;
  cecollectionSize;
  celistofdata: celist[];
  cedata: any = [];
  cetotals: any = [];
  profit_statements;
  settings = environment.singledropdown;
  branch_id: any =
    localStorage.getItem("selectedBranch") != null
      ? localStorage.getItem("selectedBranch")
      : "0";
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.buildform();
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != "0") {
      this.load_financial_years();
    }
  }
  public selectBrach(branch) {
    this.datastatus = false;
    if (branch && branch != "0") {
      localStorage.setItem("selectedBranch", branch);
      this.branch_id = branch;
      this.load_financial_years();
    } else {
      this.spinner.hide();
    }
  }
  public selectFinanceYear(financeid) {
    if (financeid && financeid != "0") {
      this.finance_id = financeid;
    }
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  load_financial_years() {
    this.finances = [];
    this.form.get("finance_id").setValue(null);
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.form.get("finance_id").setValue(v.finance_id);
                this.form.get("period_from").setValue(v.finance_start_date);
                this.form.get("period_to").setValue(v.finance_end_date);
              }
            });
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      finance_id: new FormControl(null, [Validators.required]),
      report_type: new FormControl("GLEDGER", [Validators.required]),
      period_from: new FormControl("", [Validators.required]),
      period_to: new FormControl("", [Validators.required]),
      account_level: new FormControl(""),
      compare_previous_year: new FormControl(""),
    });
  }
  fetch_statements() {
    this.spinner.show();
    let param = new FormData();
    param.set("branch_id", this.form.get("branch_id").value);
    let finance_id = this.form.get("finance_id").value;
    param.set("finance_id", finance_id);
    param.set("report_type", this.form.get("report_type").value);
    if (this.form.get("report_type").value == "ALEVEL") {
      param.set(
        "account_level",
        this.form.get("account_level").value
          ? this.form.get("account_level").value
          : "1"
      );
    } else {
      param.set("account_level", "");
    }
    param.set(
      "period_from",
      this.form.get("period_from").value != null
        ? this.form.get("period_from").value
        : ""
    );
    param.set(
      "period_to",
      this.form.get("period_to").value != null
        ? this.form.get("period_to").value
        : ""
    );
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "income_statements").subscribe(
        (res) => {
          this.datastatus = true;
          this.spinner.hide();
          if (res.status) {
            if (res.records.income_records.length > 0) {
              this.cilistofdata = res.records.income_records;
              this.cicollectionSize = res.records.income_records.length;
              this.citotals = res.records.income_records.length;
              this.ciRefreshLists();
            }
            if (res.records.expenses_records.length > 0) {
              this.celistofdata = res.records.expenses_records;
              this.cecollectionSize = res.records.expenses_records.length;
              this.cetotals = res.records.expenses_records.length;
              this.ceRefreshLists();
            }
            if (res.records.profit_total) {
              this.profit_statements = res.records.profit_total;
            }
          } else {
            this.datastatus = false;
            this.ds.dialogf("", res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.datastatus = false;
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  ciRefreshLists() {
    this.cidata = this.cilistofdata
      .map((cilist, i) => ({ id: i + 1, ...cilist }))
      .slice(
        (this.cipage - 1) * this.cipageSize,
        (this.cipage - 1) * this.cipageSize + this.cipageSize
      );
  }
  ceRefreshLists() {
    this.cedata = this.celistofdata
      .map((celist, i) => ({ id: i + 1, ...celist }))
      .slice(
        (this.cepage - 1) * this.cepageSize,
        (this.cepage - 1) * this.cepageSize + this.cepageSize
      );
  }
}
