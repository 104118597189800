import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-user-active-tasks",
	templateUrl: "./user-active-tasks.component.html",
	styleUrls: ["./user-active-tasks.component.scss"],
})
export class UserActiveTasksComponent implements OnInit {
	userId: string;
	usersTasks: any = [];
	page = 1;
	size = 10;
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public router: Router,
		private dialogRef: MatDialogRef<UserActiveTasksComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private sweetAlert: SweetAlertService
	) {
		if (data) {
			this.userId = data?.user_id;
			this.usersTasks = data?.usersTasks;
		}
	}
	ngOnInit(): void {}

	moveToTask(item) {
		this.router.navigate(["tasks/view/" + item?.ticket_id], {
			queryParams: {
				task: item?.task_id,
			},
		});
		this.dialogRef.close();
	}
}
