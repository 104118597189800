<div class="page-title">
	<div class="row pt-3 pb-3">
		<div class="col-sm-2 d-flex justify-content-center">
			<div class="page-title-icon">
				<i class="{{ icon }}"></i>
			</div>
		</div>
		<div class="col-sm-10">
			<h1>{{ title }}</h1>
			<p>
				<breadcrumb></breadcrumb>
			</p>
		</div>
	</div>
</div>
