<section
	class="card matborderno activeiconstepper customdesignstepper"
	*ngIf="showStatus"
>
	<mat-accordion class="stylepanels">
		<mat-expansion-panel class="mb-2" [expanded]="expandedStep == 0">
			<mat-expansion-panel-header (click)="openAccord(0)"
				>{{ "lang_form_details" | language }}
			</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_transaction_number" | language
							}}</label>
							<div class="">
								{{ transaction_request?.transaction_request_id }}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_project_title" | language }}</label>
							<div class="">
								{{
									allData?.view_request?.work_area_info?.records
										?.projects_profile_name
								}}
							</div>
						</div>

						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_supervision_title" | language
							}}</label>
							<div class="">
								{{
									allData?.view_request?.work_area_info?.records
										?.projects_services_name
								}}
							</div>
						</div>

						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_forms_code" | language }}</label>
							<div class="" style="direction: ltr !important">
								{{
									allData?.view_request?.form_sqr_data?.records[0]
										?.platform_code_system
								}}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_group_one" | language }}</label>
							<div class="">
								{{
									allData?.view_request?.form_sqr_data?.records[0]?.group1name
								}}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_group_two" | language }}</label>
							<div class="">
								{{
									allData?.view_request?.form_sqr_data?.records[0]?.group2name
								}}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_form_name" | language }}</label>
							<div class="">
								{{
									allData?.view_request?.form_sqr_data?.records[0]?.platformname
								}}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_form_specifications" | language
							}}</label>
							<div class="">
								{{
									allData?.view_request?.form_sqr_data?.records[0]
										?.specification
								}}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_units_and_worklevels" | language
							}}</label>
							<div>
								<i class="fa fa-building-o" (click)="openUnitLevelModal()"></i>
							</div>
						</div>
						<div
							class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center"
							*ngIf="!transaction_request?.view_link"
						>
							<label class="bold">{{ "lang_preview" | language }}</label>
							<div>
								<i
									class="fa fa-file-pdf-o"
									aria-hidden="true"
									(click)="preview_form_data()"
								></i>
							</div>
						</div>
						<div
							class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center"
							*ngIf="transaction_request?.view_link"
						>
							<label class="bold">{{ "lang_view" | language }}</label>
							<div>
								<i
									class="fa fa-eye"
									aria-hidden="true"
									(click)="view_form_data()"
								></i>
							</div>
						</div>
						<!-- <div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_form_version" | language }}</label>
							<div>
								<i
									class="fa fa-window-restore"
									routerLink="/transactions/form/{{ form_key }}/vr/{{
										form_request_id
									}}/versions"
								></i>
							</div>
						</div> -->
						<div
							class="col-12 d-flex flex-column align-items-center mb-10 text-center"
							*ngIf="transaction_request?.view_link"
						>
							<hr style="width: 200px" />
							<div class="evalution_result_display">
								<p>{{ "lang_evaluation_result" | language }}</p>
								<p class="bold">{{ evaluation_result }}</p>
							</div>
						</div>
					</div>
					<div
						class="row"
						[ngStyle]="
							lang_key == 'ar'
								? {
										'padding-left': '10px',
										'padding-right': '10px'
								  }
								: null
						"
					>
						<div class="col-xs-12 col-12 pad0">
							<div class="stepper-progress">
								<div>
									<p>
										{{ "lang_selected_step" | language }}:
										<span class="bold">{{
											stepsTitles[selectedIndex]?.label
										}}</span>
									</p>
									<p>
										{{ "lang_last_step_opened" | language }}:
										<span class="green bold"
											>{{ lastStepOpenedShow }}
											<span
												*ngIf="rejected"
												class="red"
												style="cursor: pointer"
												(click)="openAccord(1)"
											>
												({{ rejected }})
											</span>
											<i
												style="padding: 0 10px"
												class="fa fa-info-circle orangestatus-color"
												style="white-space: pre-line !important"
												matTooltip="{{ usersTooltip | language }}"
											></i>
											<i
												style="padding: 0 10px"
												class="fa fa-edit mnabricon"
												style="white-space: pre-line !important"
												*ngIf="Admin_Persons"
												(click)="addStepUser()">
											</i>
										</span>
									</p>
									<p class="step-counter">
										{{ "lang_step" | language }} <b>{{ selectedIndex + 1 }}</b>
										{{ "lang_of" | language }}
										<b>{{ totalStepsCount }}</b>
									</p>
								</div>
								<mat-progress-bar mode="determinate" [value]="progressValue">
								</mat-progress-bar>
							</div>
							<div class="row pb-3">
								<div class="col-3 col-sm-2">
									<button
										(click)="moveStepper('prev')"
										class="carousel-btn-pre albutton mnabrbutton mat-button"
									>
										<i
											class="fa"
											[ngClass]="{
												'fa-chevron-left': lang_key == 'en',
												'fa-chevron-right': lang_key == 'ar'
											}"
										></i>
									</button>
								</div>
								<div class="col-6 col-sm-8"></div>
								<div class="col-3 col-sm-2">
									<button
										(click)="moveStepper('next')"
										class="carousel-btn-next albutton mnabrbutton mat-button"
									>
										<i
											class="fa"
											[ngClass]="{
												'fa-chevron-right': lang_key == 'en',
												'fa-chevron-left': lang_key == 'ar'
											}"
										></i>
									</button>
								</div>
							</div>

							<mat-horizontal-stepper
								#stepper
								class="pad0 steps13-stepper"
								[linear]="isLinear"
								[selectedIndex]="selectedIndex"
								(selectionChange)="selectionChange($event)"
							>
								<mat-step>
									<app-sqr-step1
										*ngIf="
											allData?.step_status?.edit?.Configurations &&
											selectedIndex == 0
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
										[editMode]="true"
									></app-sqr-step1>
									<div
										class="not-allow"
										*ngIf="!allData?.step_status?.edit?.Configurations"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
								<mat-step>
									<app-sqr-step2
										[form_url]="form_url"
										[form_key]="form_key"
										[allData]="allData"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
										*ngIf="
											allData?.step_status?.edit?.Contractor_Team_Approval &&
											selectedIndex == 1
										"
									></app-sqr-step2>
									<div
										class="not-allow"
										*ngIf="
											!allData?.step_status?.edit?.Contractor_Team_Approval
										"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
								<mat-step>
									<app-sqr-step3
										*ngIf="
											allData?.step_status?.edit?.Contractor_Manager_Approval &&
											selectedIndex == 2
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[allData]="allData"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
									></app-sqr-step3>
									<div
										class="not-allow"
										*ngIf="
											!allData?.step_status?.edit?.Contractor_Manager_Approval
										"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
								<mat-step>
									<app-sqr-step4
										*ngIf="
											allData?.step_status?.edit?.Recipient_Verification &&
											selectedIndex == 3
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
									></app-sqr-step4>
									<div
										class="not-allow"
										*ngIf="!allData?.step_status?.edit?.Recipient_Verification"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
								<mat-step>
									<app-sqr-step5
										*ngIf="
											((allData?.step_status?.edit?.Techinical_Assistant &&
												selectedIndex == 4) ||
												viewMode) &&
											allData
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[allData]="allData"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
									></app-sqr-step5>
									<div
										class="not-allow"
										*ngIf="
											!allData?.step_status?.edit?.Techinical_Assistant &&
											!viewMode
										"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
								<mat-step>
									<app-sqr-step6
										*ngIf="
											allData?.step_status?.edit?.Special_Approval &&
											selectedIndex == 5
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[allData]="allData"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
									></app-sqr-step6>
									<div
										class="not-allow"
										*ngIf="!allData?.step_status?.edit?.Special_Approval"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
								<mat-step>
									<app-sqr-step7
										[form_url]="form_url"
										[form_key]="form_key"
										[allData]="allData"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
										*ngIf="
											allData?.step_status?.edit?.Evaluation_Result &&
											selectedIndex == 6
										"
									></app-sqr-step7>
									<div
										class="not-allow"
										*ngIf="!allData?.step_status?.edit?.Evaluation_Result"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
								<mat-step>
									<app-sqr-step8
										*ngIf="
											allData?.step_status?.edit
												?.Authorized_Positions_Approval && selectedIndex == 7
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[allData]="allData"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
									></app-sqr-step8>
									<div
										class="not-allow"
										*ngIf="
											!allData?.step_status?.edit?.Authorized_Positions_Approval
										"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
								<mat-step>
									<app-sqr-step9
										[form_url]="form_url"
										[form_key]="form_key"
										[allData]="allData"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
										*ngIf="
											allData?.step_status?.edit?.Manager_Approval &&
											selectedIndex == 8
										"
									></app-sqr-step9>
									<div
										class="not-allow"
										*ngIf="!allData?.step_status?.edit?.Manager_Approval"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
								<mat-step>
									<app-sqr-step10
										*ngIf="
											allData?.step_status?.edit?.Owners_Representative &&
											selectedIndex == 9
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										[allData]="allData"
										(refreshComponent)="refreshComponent($event)"
									></app-sqr-step10>
									<div
										class="not-allow"
										*ngIf="!allData?.step_status?.edit?.Owners_Representative"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>

								<mat-step>
									<app-sqr-step11
										*ngIf="lastStepOpened == 'last' && selectedIndex == 10"
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
									></app-sqr-step11>
									<div class="not-allow" *ngIf="lastStepOpened != 'last'">
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
							</mat-horizontal-stepper>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel class="mb-2" [expanded]="expandedStep == 1">
			<mat-expansion-panel-header (click)="openAccord(1)">
				{{ "lang_notes" | language }}
			</mat-expansion-panel-header>
			<div class="row">
				<div class="col-12">
					<div class="TableParts">
						<app-transactions-notes [notes]="notes"></app-transactions-notes>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
		<mat-expansion-panel class="mb-2" [expanded]="expandedStep == 2">
			<mat-expansion-panel-header (click)="openAccord(2)">{{
				"lang_person_details" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body communication">
				<div class="mbrl15">
					<div class="col-md-12 pad0">
						<app-person-details [persons]="persons"></app-person-details>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
		<mat-expansion-panel class="mb-2" [expanded]="expandedStep == 3">
			<mat-expansion-panel-header (click)="openAccord(3)">{{
				"lang_attachments" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body communication">
				<div class="mbrl15">
					<div class="col-md-12 pad0">
						<app-transaction-attachments
							[All_Attachments]="All_Attachments"
							[isInsideSupervision]="true"
						></app-transaction-attachments>
					</div>
				</div>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel class="mb-2" [expanded]="expandedStep == 4">
			<mat-expansion-panel-header class="mpanel-header" (click)="openAccord(4)">
				{{ "lang_history" | language }}</mat-expansion-panel-header
			>
			<div class="material-panel-body communication">
				<div class="mbrl15">
					<div class="col-md-12 pad0">
						<app-transactions-history
							[history]="history"
						></app-transactions-history>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<app-versions-show [data]="allData"></app-versions-show>
	<div class="card-footer">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
			<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/projects/Rpq7t/{{ projects_profile_id }}/{{
						projects_workarea_id
					}}"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_supervision_operations" | language }}
				</button>
			</div>
			<div class="col-md-7 col-sm-2 col-xs-12 mb-10"></div>
		</div>
	</div>
</section>
