<div class="card">
  <div class="width100">
    <i
      *ngIf="files && files.id"
      matTooltip="{{ 'lang_recent_attachment' | language }} : {{ files.id_d }}"
      class="fa fa-paperclip s2icon mnabricon"
      (click)="ds.preview_pdf_file(files.id)"
    ></i>
    <div class="card-header">
      {{ "lang_human_resources_id_details" | language }}
    </div>
    <div class="card-body pad0" [formGroup]="signupForm">
      <div class="mbrl15" formGroupName="idDetails">
        <div class="row">
          <div class="col-md-9 col-sm-12 col-xs-12">
            <div class="row">
              <div *ngIf="action === 'EDIT'">
                <input
                  type="hidden"
                  formControlName="employee_number"
                  [readonly]="true"
                />
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="employee_id_number"
                    >{{ "lang_human_resources_id_number" | language }} :
                    <span class="red">*</span></label
                  >
                  <input
                    *ngIf="action === 'EDIT'"
                    formControlName="employee_id_number"
                    readonly
                    minlength="10"
                    maxlength="10"
                    id="employee_id_number"
                    type="text"
                    placeholder="{{
                      'lang_human_resources_id_number' | language
                    }}"
                    class="form-control almnabrformcontrol employee_id_number"
                    (keypress)="ds.numberOnly($event)"
                    (change)="id_exists($event.target.value)"
                  />
                  <input
                    *ngIf="action !== 'EDIT'"
                    formControlName="employee_id_number"
                    minlength="10"
                    maxlength="10"
                    id="employee_id_number"
                    type="text"
                    placeholder="{{
                      'lang_human_resources_id_number' | language
                    }}"
                    class="form-control almnabrformcontrol employee_id_number"
                    (keypress)="ds.numberOnly($event)"
                    (change)="id_exists($event.target.value)"
                  />
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls'][
                        'employee_id_number'
                      ].touched &&
                      signupForm.get('idDetails')['controls'][
                        'employee_id_number'
                      ].errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls'][
                        'employee_id_number'
                      ].errors?.minlength
                    "
                  >
                    {{ "lang_minimum" | language }} 10
                  </mat-error>
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls'][
                        'employee_id_number'
                      ].errors?.maxlength
                    "
                  >
                    {{ "lang_maximum" | language }} 10
                  </mat-error>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="firstname_english"
                    >{{
                      "lang_human_resources_place_name_english" | language
                    }}
                    : <span class="red">*</span></label
                  >
                  <input
                    formControlName="name_english"
                    id="firstname_english"
                    type="text"
                    class="form-control almnabrformcontrol firstname_english"
                    placeholder="{{
                      'lang_human_resources_place_name_english' | language
                    }}"
                    (keyup)="checkNameValidation()"
                  />
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls']['name_english']
                        .touched &&
                      (signupForm.get('idDetails')['controls'][
                        'firstname_english'
                      ].errors?.required ||
                        signupForm.get('idDetails')['controls'][
                          'secondname_english'
                        ].errors?.required ||
                        signupForm.get('idDetails')['controls'][
                          'lastname_english'
                        ].errors?.required)
                    "
                    >{{ "lang_full_name_required" | language }}</mat-error
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="name_arabic"
                    >{{ "lang_human_resources_place_name_arabic" | language }} :
                    <span class="red">*</span></label
                  >
                  <input
                    formControlName="name_arabic"
                    required
                    id="name_arabic"
                    type="text"
                    class="form-control almnabrformcontrol firstname_arabic"
                    placeholder="{{
                      'lang_human_resources_place_name_arabic' | language
                    }}"
                    (keyup)="checkNameValidation()"
                  />
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls']['name_arabic']
                        .touched &&
                      (signupForm.get('idDetails')['controls'][
                        'firstname_arabic'
                      ].errors?.required ||
                        signupForm.get('idDetails')['controls'][
                          'secondname_arabic'
                        ].errors?.required ||
                        signupForm.get('idDetails')['controls'][
                          'lastname_arabic'
                        ].errors?.required)
                    "
                    >{{ "lang_full_name_required" | language }}</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="iqama_expiry_date_english"
                    >{{
                      "lang_human_resources_id_expiry_data_english" | language
                    }}
                    : <span class="red">*</span></label
                  >
                  <div class="positionrelatiove">
                    <input
                      formControlName="iqama_expiry_date_english"
                      class="form-control almnabrformcontrol"
                      (dateChange)="
                        ds.setENTOARDate(
                          $event,
                          signupForm.get('idDetails')['controls'][
                            'iqama_expiry_date_arabic'
                          ]
                        )
                      "
                      [readonly]="true"
                      [matDatepicker]="IDeng"
                      placeholder="YYYY/MM/DD"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="IDeng"
                      class="posiab"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #IDeng disabled="false"></mat-datepicker>
                    <mat-error
                      *ngIf="
                        signupForm.get('idDetails')['controls'][
                          'iqama_expiry_date_english'
                        ].touched &&
                        signupForm.get('idDetails')['controls'][
                          'iqama_expiry_date_english'
                        ].errors?.required
                      "
                    >
                      {{ "lang_field_required" | language }}
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="iqama_expiry_date_arabic"
                    >{{
                      "lang_human_resources_id_expiry_data_arabic" | language
                    }}
                    : <span class="red">*</span></label
                  >
                  <input
                    formControlName="iqama_expiry_date_arabic"
                    ngbDatepicker
                    [readonly]="true"
                    (click)="idarab.toggle()"
                    #idarab="ngbDatepicker"
                    required
                    id="iqama_expiry_date_arabic"
                    type="text"
                    class="form-control almnabrformcontrol iqama_expiry_date_arabic"
                    placeholder="YYYY/MM/DD"
                    (ngModelChange)="
                      ds.setARTOENDate(
                        $event,
                        signupForm.get('idDetails')['controls'][
                          'iqama_expiry_date_english'
                        ]
                      )
                    "
                  />
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls'][
                        'iqama_expiry_date_arabic'
                      ].touched &&
                      signupForm.get('idDetails')['controls'][
                        'iqama_expiry_date_arabic'
                      ].errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="copy_number"
                    >{{ "lang_human_resources_copy_number" | language }} :
                    <span class="red">*</span></label
                  >
                  <input
                    formControlName="copy_number"
                    required
                    id="copy_number"
                    type="text"
                    class="form-control almnabrformcontrol copy_number"
                    placeholder="{{
                      'lang_human_resources_copy_number' | language
                    }}"
                  />
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls']['copy_number']
                        .touched &&
                      signupForm.get('idDetails')['controls']['copy_number']
                        .errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="birth_date_english"
                    >{{
                      "lang_human_resources_birth_date_english" | language
                    }}
                    : <span class="red">*</span></label
                  >
                  <div class="positionrelatiove">
                    <input
                      formControlName="birth_date_english"
                      required
                      id="birth_date_english"
                      type="text"
                      class="form-control almnabrformcontrol birth_date_english"
                      (dateChange)="
                        ds.setENTOARDate(
                          $event,
                          signupForm.get('idDetails')['controls'][
                            'birth_date_arabic'
                          ]
                        )
                      "
                      [readonly]="true"
                      placeholder="YYYY/MM/DD"
                      [matDatepicker]="BDeng"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="BDeng"
                      class="posiab"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #BDeng disabled="false"></mat-datepicker>
                    <mat-error
                      *ngIf="
                        signupForm.get('idDetails')['controls'][
                          'birth_date_english'
                        ].touched &&
                        signupForm.get('idDetails')['controls'][
                          'birth_date_english'
                        ].errors?.required
                      "
                    >
                      {{ "lang_field_required" | language }}
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="birth_date_arabic"
                    >{{ "lang_human_resources_birth_date_arabic" | language }} :
                    <span class="red">*</span></label
                  >
                  <input
                    formControlName="birth_date_arabic"
                    (ngModelChange)="
                      ds.setARTOENDate(
                        $event,
                        signupForm.get('idDetails')['controls'][
                          'birth_date_english'
                        ]
                      )
                    "
                    ngbDatepicker
                    [readonly]="true"
                    (click)="bdarab.toggle()"
                    #bdarab="ngbDatepicker"
                    required
                    id="birth_date_arabic"
                    type="text"
                    class="form-control almnabrformcontrol birth_date_arabic"
                    placeholder="YYYY/MM/DD"
                  />
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls'][
                        'birth_date_arabic'
                      ].touched &&
                      signupForm.get('idDetails')['controls'][
                        'birth_date_arabic'
                      ].errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="gender"
                    >{{ "lang_human_resources_gender" | language }} :
                    <span class="red">*</span></label
                  >
                  <select
                    formControlName="gender"
                    required
                    id="gender"
                    type="text"
                    class="form-control almnabrformcontrol gender"
                    matTooltip="{{ 'lang_human_resources_gender' | language }}"
                  >
                    <option value="">
                      {{ "lang_choose_options" | language }}
                    </option>
                    <option value="M">
                      {{ "lang_human_resources_male" | language }}
                    </option>
                    <option value="F">
                      {{ "lang_human_resources_female" | language }}
                    </option>
                  </select>
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls']['gender']
                        .touched &&
                      signupForm.get('idDetails')['controls']['gender'].errors
                        ?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="nationality"
                    >{{ "lang_human_resources_nationality" | language }} :
                    <span class="red">*</span></label
                  >
                  <ng-select
                    [items]="countries"
                    [multiple]="false"
                    bindLabel="label"
                    bindValue="value"
                    clearAllText="Clear"
                    placeholder="{{
                      'lang_human_resources_nationality' | language
                    }}"
                    formControlName="nationality"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-search="searchTerm"
                      let-index="index"
                    >
                      <span
                        class="widthunset"
                        style="color: #333; min-width: 150px"
                        >{{ item.label }}</span
                      >
                      <img
                        *ngIf="item.flag"
                        src="{{ apiurl }}{{ item.flag }}"
                        class="searchingImage"
                      />
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="job_title_iqama"
                    >{{ "lang_human_resources_job_title_iqama" | language }} :
                    <span class="red">*</span></label
                  >
                  <input
                    formControlName="job_title_iqama"
                    required
                    id="job_title_iqama"
                    type="text"
                    class="form-control almnabrformcontrol job_title_iqama"
                    placeholder="{{
                      'lang_human_resources_job_title_iqama' | language
                    }}"
                  />
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls']['job_title_iqama']
                        .touched &&
                      signupForm.get('idDetails')['controls']['job_title_iqama']
                        .errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_marital_status" | language }} :
                    <span class="red">*</span></label
                  >
                  <select
                    formControlName="marital_status"
                    type="text"
                    matTooltip="{{ 'lang_marital_status' | language }}"
                    class="form-control almnabrformcontrol"
                  >
                    <option value="">
                      {{ "lang_choose_options" | language }}
                    </option>
                    <option value="S">{{ "lang_single" | language }}</option>
                    <option value="M">{{ "lang_married" | language }}</option>
                    <option value="D">{{ "lang_diversed" | language }}</option>
                    <option value="O">{{ "lang_others" | language }}</option>
                  </select>
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls']['marital_status']
                        .touched &&
                      signupForm.get('idDetails')['controls']['marital_status']
                        .errors?.required
                    "
                    >{{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_religion" | language }} :
                    <span class="red">*</span></label
                  >
                  <input
                    formControlName="religion"
                    type="text"
                    placeholder="{{ 'lang_religion' | language }}"
                    class="form-control almnabrformcontrol"
                  />
                  <mat-error
                    *ngIf="
                      signupForm.get('idDetails')['controls']['religion']
                        .touched &&
                      signupForm.get('idDetails')['controls']['religion'].errors
                        ?.required
                    "
                    >{{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12 pad0">
                <div class="form-group text-center">
                  <label
                    >{{ "lang_human_resources_profile_image" | language }} :
                  </label>
                  <div class="avatar-upload">
                    <div
                      class="avatar-edit"
                      matTooltip="{{ 'lang_select_files' | language }}"
                      placement="top"
                    >
                      <input
                        name="profile_image"
                        onclick="this.value = null"
                        accept=".png, .jpg, .jpeg"
                        id="profile_image"
                        type="file"
                        class="form-control almnabrformcontrol profile_image"
                        (change)="onFileChange($event)"
                      />
                      <label for="imageUpload"></label>
                    </div>
                    <label
                      *ngIf="fileurl"
                      (click)="removeProfile()"
                      matTooltip="{{ 'lang_delete' | language }}"
                      placement="top"
                      class="removeImage"
                    ></label>
                    <img
                      *ngIf="fileurl"
                      src="{{ fileurl }}"
                      class="avatar-preview"
                    />
                    <img
                      *ngIf="!fileurl"
                      src="{{ view_profile_image }}"
                      class="avatar-preview"
                      id="imagePreviews"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                <div class="form-group">
                  <label for="employee_title"
                    >{{ "lang_human_resources_emptitle" | language }} :
                  </label>
                  <select
                    formControlName="employee_title"
                    id="employee_title"
                    type="text"
                    matTooltip="{{
                      'lang_human_resources_emptitle' | language
                    }}"
                    class="form-control almnabrformcontrol employee_title"
                  >
                    <option value="">
                      {{ "lang_choose_options" | language }}
                    </option>
                    <option
                      *ngFor="let title of etitles"
                      value="{{ title.value }}"
                    >
                      {{ title.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                  <div class="form-group">
                    <label for="id_filename"
                      >{{ "lang_human_resources_id_filename" | language }} :
                      <span class="red" *ngIf="action !== 'EDIT'">*</span>
                    </label>
                    <input
                      formControlName="id_filename"
                      id="id_filename"
                      type="text"
                      placeholder="{{
                        'lang_human_resources_id_filename' | language
                      }}"
                      class="form-control almnabrformcontrol id_filename"
                    />
                    <mat-error
                      *ngIf="
                        signupForm.get('idDetails')['controls']['id_filename']
                          .touched &&
                        signupForm.get('idDetails')['controls']['id_filename']
                          .errors?.required
                      "
                    >
                      {{ "lang_field_required" | language }}
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                  <div class="form-group">
                    <div class="IDDIV">
                      <input
                        name="idFileUpload"
                        type="file"
                        onclick="this.value = null"
                        (change)="uploadIDFile($event)"
                        class="nghide albutton width100"
                        accept=".pdf"
                      />
                      <label id="uploadIDButton" class="albutton"
                        >{{ idfilename }}
                      </label>
                      <mat-error
                        *ngIf="
                          signupForm.get('idDetails')['controls'][
                            'idFileUpload'
                          ].touched &&
                          signupForm.get('idDetails')['controls'][
                            'idFileUpload'
                          ].errors?.required
                        "
                      >
                        {{ "lang_field_required" | language }}
                      </mat-error>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer padb0">
        <div class="row">
          <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
            <button type="button" class="albutton" matStepperPrevious>
              {{ "lang_previous" | language }}
            </button>
          </div>
          <div class="col-md-8 col-sm-6 col-xs-12"></div>
          <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
            <button
              type="button"
              class="albutton"
              matStepperNext
              (click)="rstep2Submitted()"
            >
              {{ "lang_next" | language }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
