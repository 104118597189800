<breadcrumb></breadcrumb>
<div class="themesection">
    <div class="container mb-10">
        <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-12 mar0auto">
                <div *ngIf="!branch_id" class="red pad0 text-center">{{'lang_choose_branch' | language }}</div>
                <select name="branch_id" class="form-control almnabrformcontrol" matTooltip="{{'lang_branch' | language }}"
                    [(ngModel)]="branch_id" (change)="selectBrach($event.target.value);">
                    <option value="0">{{'lang_choose_options' | language }}</option>
                    <option *ngFor="let branch of branches" value="{{branch.id}}">{{branch.title}}</option>
                </select>
            </div>
        </div>
    </div>
    <section class="panel-expansion matborderno" *ngIf="branch_id && branch_id !='0' ">
        <mat-accordion class="accounts_masters" >
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_accounts_cost_center' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body">
                    <div class="mbrl15">
                        <div class="row">
                            <div class="col-12">
                                <div class="input-group input-group-sm mb-10">
                                    <input name="account_search" id="filter" #filter class="enablecode h34 form-control almnabrformcontrol" type="text" (keyup)="tree.treeModel.filterNodes(filter.value);" autocomplete="off" placeholder="{{'lang_search' | language }}">
                                    <span class="input-group-btn">
                                        <button id="btnSearch" type="button" matTooltip="{{'lang_clear' | language }}" class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-times red blue-tooltip" (click)="filter.value = '';clear(tree.treeModel);"></button>
                                    </span>
                                    <span class="input-group-btn">
                                        <button type="button" matTooltip="{{'lang_refresh' | language }}" class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-refresh red blue-tooltip" (click)="load_tree();clear(tree.treeModel);"></button>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 leftdiv">
                                <div class="treeheader albutton"> {{'lang_accounts_cost_center_tree' | language }}
                                    <div class="actionDiv floatright" *ngIf="viewstatus">
                                        <i class="fa fa-plus fa-icon floatright mnabricon s2icon" (click)="treeAction(tree.treeModel, 'add')" *ngIf="ds.allowMe('accounts', 'accounts_add') && add" matTooltip="{{'lang_add' | language }}"></i>
                                        <i class="fa fa-eye fa-icon floatright mnabricon s2icon" (click)="treeAction(tree.treeModel, 'view')" matTooltip="{{'lang_view' | language }}"></i>
                                        <i class="fa fa-pencil-square-o fa-icon floatright mnabricon s2icon" *ngIf="ds.allowMe('accounts', 'accounts_edit') && edit" (click)="treeAction(tree.treeModel, 'edit')" matTooltip="{{'lang_edit' | language }}"></i>
                                        <i class="fa fa-trash fa-icon floatright mnabricon s2icon" *ngIf="ds.allowMe('accounts', 'accounts_delete') && delete" (click)="treeAction(tree.treeModel, 'delete')" matTooltip="{{'lang_delete' | language }}"></i>
                                    </div>
                                </div>
                                <div class="treepanel tree">
                                    <tree-root #tree [options]="options" [nodes]="nodes" (click)="toggleAction(tree.treeModel)"></tree-root>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 rightdiv">
                                <div class="rightpanel" *ngIf="form_show_status =='ADD' && ds.allowMe('accounts', 'accounts_add')">
                                    <div class="treeheader albutton"> {{'lang_addnew' | language }}</div>
                                    <form class="costcenterform padding15" [formGroup]="costcenterform"
                                        (ngSubmit)="create_cost_center_operation(tree.treeModel)">
                                        <div class="form-group">
                                            <div class="row">
                                                <label class="col-md-6 col-sm-6 col-xs-12 mb-10"> {{'lang_add_root' | language }}<span class="red">*</span></label>
                                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                                    <select class="form-control almnabrformcontrol" formControlName="cost_center_root" matTooltip="{{'lang_add_root' | language }}">
                                                        <option value="">{{'lang_choose_options' | language }}</option>
                                                        <option value="0">{{'lang_no' | language }}</option>
                                                        <option value="1">{{'lang_yes' | language }}</option>
                                                    </select>
                                                    <mat-error *ngIf="costcenterform.controls['cost_center_root'].touched && costcenterform.controls['cost_center_root'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="hidden" formControlName="cost_center_id" />
                                        <input type="hidden" formControlName="branch_id" />
                                        <div class="form-group">
                                            <div class="row">
                                                <label class="col-md-6 col-sm-6 col-xs-12 mb-10">{{'lang_cost_center_code' | language }} </label>
                                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                                    <input type="text" formControlName="cost_center_code" placeholder="{{'lang_cost_center_code' | language }}" readonly class="form-control almnabrformcontrol" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <label class="col-md-6 col-sm-6 col-xs-12 mb-10"> {{'lang_title_english' | language }} <span class="red">*</span></label>
                                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                                    <input type="text" formControlName="cost_center_name_en" placeholder="{{'lang_title_english' | language }}" class="form-control almnabrformcontrol" />
                                                    <mat-error *ngIf="costcenterform.controls['cost_center_name_en'].touched && costcenterform.controls['cost_center_name_en'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <label class="col-md-6 col-sm-6 col-xs-12 mb-10">{{'lang_title_arabic' | language }} <span class="red">*</span></label>
                                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                                    <input type="text" formControlName="cost_center_name_ar" placeholder="{{'lang_title_arabic' | language }}" class="form-control almnabrformcontrol" />
                                                    <mat-error *ngIf="costcenterform.controls['cost_center_name_ar'].touched && costcenterform.controls['cost_center_name_ar'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <label class="col-md-6 col-sm-6 col-xs-12 mb-10">{{'lang_sub_account' | language }} <span class="red">*</span></label>
                                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                                    <select class="form-control almnabrformcontrol" formControlName="cost_center_sub" matTooltip="{{'lang_sub_account' | language }}">
                                                        <option value="">{{'lang_choose_options' | language }}</option>
                                                        <option value="0">{{'lang_no' | language }}</option>
                                                        <option value="1">{{'lang_yes' | language }}</option>
                                                    </select>
                                                    <mat-error *ngIf="costcenterform.controls['cost_center_sub'].touched && costcenterform.controls['cost_center_sub'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-12">
                                                    <app-alert id="default-alert"></app-alert>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10 mar0auto">
                                                    <button type="submit" class="albutton" >{{'lang_submit' | language }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="rightpanel" *ngIf="form_show_status =='EDIT' && ds.allowMe('accounts', 'accounts_edit')">
                                    <div class="treeheader albutton"> {{'lang_update' | language }}</div>
                                    <form class="costcenterform padding15" [formGroup]="costcenterform" (ngSubmit)="update_cost_center_operation(tree.treeModel)">
                                        <input type="hidden" formControlName="cost_center_id" />
                                        <input type="hidden" formControlName="branch_id" />
                                        <div class="form-group">
                                            <div class="row">
                                                <label class="col-md-6 col-sm-6 col-xs-12 mb-10"> {{'lang_cost_center_code' | language }} </label>
                                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                                    <input type="text" formControlName="cost_center_code" placeholder="{{'lang_cost_center_code' | language }}" readonly class="form-control almnabrformcontrol" />
                                                    <mat-error *ngIf="costcenterform.controls['cost_center_code'].touched && costcenterform.controls['cost_center_code'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <label class="col-md-6 col-sm-6 col-xs-12 mb-10">{{'lang_title_english' | language }} <span class="red">*</span></label>
                                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                                    <input type="text" formControlName="cost_center_name_en" placeholder="{{'lang_title_english' | language }}" class="form-control almnabrformcontrol" />
                                                    <mat-error *ngIf="costcenterform.controls['cost_center_name_en'].touched && costcenterform.controls['cost_center_name_en'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <label class="col-md-6 col-sm-6 col-xs-12 mb-10"> {{'lang_title_arabic' | language }} <span class="red">*</span></label>
                                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                                    <input type="text" formControlName="cost_center_name_ar" placeholder="{{'lang_title_arabic' | language }}" class="form-control almnabrformcontrol" />
                                                    <mat-error *ngIf="costcenterform.controls['cost_center_name_ar'].touched && costcenterform.controls['cost_center_name_ar'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <label class="col-md-6 col-sm-6 col-xs-12 mb-10"> {{'lang_sub_account' | language }} <span class="red">*</span> </label>
                                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                                    <select class="form-control almnabrformcontrol" formControlName="cost_center_sub" matTooltip="{{'lang_sub_account' | language }}">
                                                        <option value="">{{'lang_choose_options' | language }}</option>
                                                        <option value="0">{{'lang_no' | language }}</option>
                                                        <option value="1">{{'lang_yes' | language }}</option>
                                                    </select>
                                                    <mat-error *ngIf="costcenterform.controls['cost_center_sub'].touched && costcenterform.controls['cost_center_sub'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-12">
                                                    <app-alert id="default-alert"></app-alert>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10 mar0auto">
                                                    <button type="submit" class="albutton" >{{'lang_submit' | language }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="rightpanel" *ngIf="form_show_status =='VIEW' && ds.allowMe('accounts', 'accounts_view')">
                                    <app-cost-center-view [costdata]="viewdata" [branch_id]="branch_id" [able2view]="ds.allowMe('accounts', 'accounts_view')"></app-cost-center-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>
