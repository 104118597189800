<div class="dates-bar">
	<span
		class="start-date-line"
		matTooltip="{{ 'lang_start_date' | language }}: {{ startDate }}"
	></span>
	<span
		class="start-date-bar"
		id="start_date_bar"
		matTooltip="{{ 'lang_finished' | language }}: {{ completedWidth }} %"
	></span>
	<span
		class="today-line"
		id="today_line"
		matTooltip="{{ 'lang_today' | language }}: {{ todayDate }}"
	></span>
	<span class="end-date-bar"></span>
	<span
		class="end-date-line"
		matTooltip="{{ 'lang_end_date' | language }}: {{ endDate }}"
	></span>
</div>
