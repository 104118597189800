<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_confirm" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form
		[formGroup]="form"
		*ngIf="
			note_data.action_step_code != 'contractor_receiving_waiting' &&
			note_data.action_step_code != 'almnabr_team'
		"
	>
		<div class="row p-2">
			<div class="col-12">
				<mat-radio-group formControlName="confirm">
					<mat-radio-button value="2">{{
						"lang_accept" | language
					}}</mat-radio-button>
					<mat-radio-button value="3">{{
						"lang_reject" | language
					}}</mat-radio-button>
				</mat-radio-group>
			</div>
			<div class="col-12">
				<div *ngIf="form?.get('confirm')?.value == '3'">
					<label
						>{{ "lang_rejected_notes" | language }} :
						<span class="red">*</span></label
					>
					<textarea
						class="form-control almnabrformcontrol"
						style="max-height: 80px"
						formControlName="rejected_notes"
					></textarea>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="onSubmit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
