import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-payrole-aspdf-view",
  templateUrl: "./payrole-aspdf-view.component.html",
  styleUrls: ["./payrole-aspdf-view.component.scss"],
})
export class PayroleAspdfViewComponent implements OnInit {
  @Input() records;
  @Input() financial_details;
  @Input() notes;
  @Input() status;
  @Input() lang_key;
  @Input() alldata;

  constructor() {}

  ngOnInit(): void {}
}
