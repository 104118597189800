<div class="c-view-container" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
  <div class="cv-container" *ngIf="isChatView">
    <div class="cv-header">
      <div class="cvh-details">
        <div class="cvhd-name">
          {{ chatData?.name || chatData?.number }}
          <span *ngIf="chatData?.name != chatData?.number"
            >({{ chatData?.number }})</span
          >
        </div>
        <div class="cvhd-actions">
          <mat-icon [matMenuTriggerFor]="chatActionsMenu">more_vert</mat-icon>
          <mat-menu #chatActionsMenu="matMenu">
            <button mat-menu-item (click)="getChatMessages(chatData.id)">
              Refresh chat
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="cv-body" id="chatViewBody">
      <div class="cvb-messages">
        <div
          class="cvb-messages-item"
          [class.cvb-messages-item-me]="whatsappSettings?.id == message?.sender"
          *ngFor="let message of messages"
        >
          <div
            class="cvbmi-text"
            *ngIf="
              (message?.mtype == 'text' || message?.mtype == 'template') &&
              !message?.replyOfMsg
            "
          >
            <div class="cvbmi-actions">
              <i
                class="pi pi-angle-down"
                [matMenuTriggerFor]="cvbmiActionsMenu"
              ></i>
              <mat-menu #cvbmiActionsMenu="matMenu">
                <button mat-menu-item (click)="onReply(message)">Reply</button>
                <button
                  mat-menu-item
                  (click)="readmessage(message, 'text', hidenMassage)"
                  *ngIf="message?.msg_hidden == 1"
                >
                  {{ "lang_read_message" | language }}
                </button>
                <button
                  mat-menu-item
                  (click)="get_viewers(message, viewersMassage)"
                  *ngIf="message?.msg_hidden == 1"
                >
                  {{ "lang_viewed_by" | language }}
                </button>
              </mat-menu>
            </div>
            <span>{{ message?.body }}</span>
            <div class="cvbmi-date">
              <small>{{ message?.time | dateFormnow }}</small>
              <div class="checkIcon">
                <mat-icon
                  [ngClass]="{ blue: message?.msg_status == 'read' }"
                  *ngIf="
                    message?.msg_status == 'send' ||
                    message?.msg_status == 'delivered' ||
                    message?.msg_status == 'read'
                  "
                  >done</mat-icon
                ><mat-icon
                  *ngIf="
                    message?.msg_status == 'delivered' ||
                    message?.msg_status == 'read'
                  "
                  [ngClass]="{ blue: message?.msg_status == 'read' }"
                  >done</mat-icon
                >
              </div>
            </div>
            <div class="cvbmi-date m0op5">
              <small *ngIf="message?.writer_name"
                >{{ "lang_writer_name" | language }}:
                {{ message?.writer_name }}</small
              >
            </div>
            <div class="cvbmi-date m0op5">
              <small *ngIf="message?.viewed_by"
                >{{ "lang_viewed_by" | language }}:
                {{ message?.viewed_by }}</small
              >
            </div>
          </div>
          <div
            class="cvbmi-attachment"
            *ngIf="
              message?.mtype == 'image' ||
              message?.mtype == 'document' ||
              message?.mtype == 'attachment'
            "
          >
            <div class="cvbmi-actions">
              <i
                class="pi pi-angle-down"
                [matMenuTriggerFor]="cvbmiActionsMenu"
              ></i>
              <mat-menu #cvbmiActionsMenu="matMenu">
                <button mat-menu-item (click)="onReply(message)">Reply</button>
                <button
                  mat-menu-item
                  (click)="readmessage(message, 'document', hidenMassage)"
                  *ngIf="message?.msg_hidden == 1"
                >
                  {{ "lang_read_message" | language }}
                </button>
                <button
                  mat-menu-item
                  (click)="get_viewers(message, viewersMassage)"
                  *ngIf="message?.msg_hidden == 1"
                >
                  {{ "lang_viewed_by" | language }}
                </button>
              </mat-menu>
            </div>
            <div
              class="cvbmia-content"
              matTooltip="{{ 'lang_download_file' | language }}"
              (click)="
                onDownloadAttachment(
                  message?.attachment,
                  message?.body,
                  message?.media_id
                )
              "
            >
              <span>{{ message?.body }} - {{ message?.mtype }}</span>
              <i class="fa fa-download"></i>
            </div>
            <div class="cvbmi-date">
              <small>{{ message?.time | dateFormnow }}</small>
              <div class="checkIcon">
                <mat-icon
                  [ngClass]="{ blue: message?.msg_status == 'read' }"
                  *ngIf="
                    message?.msg_status == 'send' ||
                    message?.msg_status == 'delivered' ||
                    message?.msg_status == 'read'
                  "
                  >done</mat-icon
                ><mat-icon
                  *ngIf="
                    message?.msg_status == 'delivered' ||
                    message?.msg_status == 'read'
                  "
                  [ngClass]="{ blue: message?.msg_status == 'read' }"
                  >done</mat-icon
                >
              </div>
            </div>
            <div class="cvbmi-date m0op5">
              <small *ngIf="message?.writer_name"
                >{{ "lang_writer_name" | language }}:
                {{ message?.writer_name }}</small
              >
            </div>
            <div class="cvbmi-date m0op5">
              <small *ngIf="message?.viewed_by"
                >{{ "lang_viewed_by" | language }}:
                {{ message?.viewed_by }}</small
              >
            </div>
          </div>
          <div
            class="cvbmi-reply"
            *ngIf="
              (message?.mtype == 'text' || message?.mtype == 'replytext') &&
              message?.replyOfMsg
            "
          >
            <div class="cvbmi-actions">
              <i
                class="pi pi-angle-down"
                [matMenuTriggerFor]="cvbmiActionsMenu"
              ></i>
              <mat-menu #cvbmiActionsMenu="matMenu">
                <button mat-menu-item (click)="onReply(message)">Reply</button>
                <button
                  mat-menu-item
                  (click)="readmessage(message, 'replytext', hidenMassage)"
                  *ngIf="message?.msg_hidden == 1"
                >
                  {{ "lang_read_message" | language }}
                </button>
                <button
                  mat-menu-item
                  (click)="get_viewers(message, viewersMassage)"
                  *ngIf="message?.msg_hidden == 1"
                >
                  {{ "lang_viewed_by" | language }}
                </button>
              </mat-menu>
            </div>
            <div class="cvbmir-content">
              <div class="cvbmirc-text">
                <p>{{ message?.replyOfMsg | shortenLength: 200 }}</p>
              </div>
              <div class="cvbmirc-msg">
                <span>{{ message?.body }}</span>
              </div>
            </div>
            <div class="cvbmi-date">
              <small>{{ message?.time | dateFormnow }}</small>
              <div class="checkIcon">
                <mat-icon
                  *ngIf="
                    message?.msg_status == 'send' ||
                    message?.msg_status == 'delivered' ||
                    message?.msg_status == 'read'
                  "
                  [ngClass]="{ blue: message?.msg_status == 'read' }"
                  >done</mat-icon
                ><mat-icon
                  *ngIf="
                    message?.msg_status == 'delivered' ||
                    message?.msg_status == 'read'
                  "
                  [ngClass]="{ blue: message?.msg_status == 'read' }"
                  >done</mat-icon
                >
              </div>
            </div>
            <div class="cvbmi-date m0op5">
              <small *ngIf="message?.writer_name"
                >{{ "lang_writer_name" | language }}:
                {{ message?.writer_name }}</small
              >
            </div>
            <div class="cvbmi-date m0op5">
              <small *ngIf="message?.viewed_by"
                >{{ "lang_viewed_by" | language }}:
                {{ message?.viewed_by }}</small
              >
            </div>
          </div>
          <div class="cvbmi-contact" *ngIf="message?.mtype == 'contact'">
            <div class="cvbmi-actions">
              <i
                class="pi pi-angle-down"
                [matMenuTriggerFor]="cvbmiActionsMenu"
              ></i>
              <mat-menu #cvbmiActionsMenu="matMenu">
                <button mat-menu-item (click)="onReply(message)">Reply</button>
                <button
                  mat-menu-item
                  (click)="readmessage(message, 'contact', hidenMassage)"
                  *ngIf="message?.msg_hidden == 1"
                >
                  {{ "lang_read_message" | language }}
                </button>
                <button
                  mat-menu-item
                  (click)="get_viewers(message, viewersMassage)"
                  *ngIf="message?.msg_hidden == 1"
                >
                  {{ "lang_viewed_by" | language }}
                </button>
              </mat-menu>
            </div>
            <div class="cvbmic-number">
              Contact number: <span>{{ message?.all?.wa_cus_no }}</span>
            </div>
            <span class="cvbmic-msg" [innerHtml]="message?.body"></span>
            <div class="cvbmi-date">
              <small>{{ message?.time | dateFormnow }}</small>
              <div class="checkIcon">
                <mat-icon
                  *ngIf="
                    message?.msg_status == 'send' ||
                    message?.msg_status == 'delivered' ||
                    message?.msg_status == 'read'
                  "
                  [ngClass]="{ blue: message?.msg_status == 'read' }"
                  >done</mat-icon
                ><mat-icon
                  *ngIf="
                    message?.msg_status == 'delivered' ||
                    message?.msg_status == 'read'
                  "
                  [ngClass]="{ blue: message?.msg_status == 'read' }"
                  >done</mat-icon
                >
              </div>
            </div>
            <div class="cvbmi-date m0op5">
              <small *ngIf="message?.writer_name"
                >{{ "lang_writer_name" | language }}:
                {{ message?.writer_name }}</small
              >
            </div>
            <div class="cvbmi-date m0op5">
              <small *ngIf="message?.viewed_by"
                >{{ "lang_viewed_by" | language }}:
                {{ message?.viewed_by }}</small
              >
            </div>
            <hr *ngIf="message?.all?.wa_cus_no != chatData?.number" />
            <button
              class="cvbmic-msg-btn"
              *ngIf="message?.all?.wa_cus_no != chatData?.number"
              (click)="onOpenChat(message)"
            >
              Message
            </button>
          </div>
          <div class="cvbmi-location" *ngIf="message?.mtype == 'location'">
            <div class="cvbmi-actions">
              <i
                class="pi pi-angle-down"
                [matMenuTriggerFor]="cvbmiActionsMenu"
              ></i>
              <mat-menu #cvbmiActionsMenu="matMenu">
                <button mat-menu-item (click)="onReply(message)">Reply</button>
                <button
                  mat-menu-item
                  (click)="readmessage(message, 'location', hidenMassage)"
                  *ngIf="message?.msg_hidden == 1"
                >
                  {{ "lang_read_message" | language }}
                </button>
                <button
                  mat-menu-item
                  (click)="get_viewers(message, viewersMassage)"
                  *ngIf="message?.msg_hidden == 1"
                >
                  {{ "lang_viewed_by" | language }}
                </button>
              </mat-menu>
            </div>
            <div class="cvbmil-agm">
              <agm-map
                [latitude]="+message?.all?.latitude"
                [longitude]="+message?.all?.longitude"
                [scrollwheel]="true"
                [zoom]="zoom"
              >
                <agm-marker
                  [latitude]="+message?.all?.latitude"
                  [longitude]="+message?.all?.longitude"
                ></agm-marker>
              </agm-map>
            </div>

            <div class="cvbmi-date">
              <small>{{ message?.time | dateFormnow }}</small>
              <div class="checkIcon">
                <mat-icon
                  *ngIf="
                    message?.msg_status == 'send' ||
                    message?.msg_status == 'delivered' ||
                    message?.msg_status == 'read'
                  "
                  [ngClass]="{ blue: message?.msg_status == 'read' }"
                  >done</mat-icon
                ><mat-icon
                  *ngIf="
                    message?.msg_status == 'delivered' ||
                    message?.msg_status == 'read'
                  "
                  [ngClass]="{ blue: message?.msg_status == 'read' }"
                  >done</mat-icon
                >
              </div>
            </div>
            <div class="cvbmi-date m0op5">
              <small *ngIf="message?.writer_name"
                >{{ "lang_writer_name" | language }}:
                {{ message?.writer_name }}</small
              >
            </div>
            <div class="cvbmi-date m0op5">
              <small *ngIf="message?.viewed_by"
                >{{ "lang_viewed_by" | language }}:
                {{ message?.viewed_by }}</small
              >
            </div>
            <hr *ngIf="message?.all?.wa_cus_no != chatData?.number" />
            <button
              class="cvbmic-msg-btn"
              *ngIf="message?.all?.wa_cus_no != chatData?.number"
              (click)="onOpenChat(message)"
            >
              Message
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="cv-footer" id="chatFooter">
      <div class="cvf-replyed-message" *ngIf="replyMessage">
        <div class="cvfrm-box">
          <p class="cvfrm-box-name">
            {{
              whatsappSettings?.id == replyMessage?.sender
                ? "You"
                : chatData.number
            }}
          </p>
          <p class="cvfrm-box-message">{{ replyMessage?.body }}</p>
        </div>
        <div class="cvfrm-actions">
          <i class="pi pi-times" (click)="onCancelReply()"></i>
        </div>
      </div>
      <div class="cvf-send">
        <div class="cvf-file-icon">
          <i class="fa fa-ellipsis-v" [matMenuTriggerFor]="sendMenu"></i>
          <mat-menu #sendMenu="matMenu">
            <button mat-menu-item (click)="chatAttachInput.click()">
              Send attachment
            </button>
            <button mat-menu-item (click)="onSendContact()">
              Send contact
            </button>
          </mat-menu>
          <i
            class="fa fa-file-text-o send-template"
            matTooltip="Send template"
            (click)="onSendTemplate()"
          ></i>
        </div>
        <div class="cvf-message-box" *ngIf="canText">
          <textarea
            name="send-message"
            id="send-message"
            placeholder="Type a message."
            [(ngModel)]="sendTxtMsg"
            (keydown.enter)="onSendMessage()"
          ></textarea>
        </div>
        <div class="cvf-send-icon" (click)="onSendMessage()" *ngIf="canText">
          <span>
            <svg viewBox="0 0 24 24" width="24" height="24" class="">
              <path
                fill="currentColor"
                d="M1.101 21.757 23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    </div>

    <!-- attachment design -->
    <div class="cv-attachment" *ngIf="isUploadedAttachment">
      <div class="cva-header">
        <div class="cvah-icon">
          <i class="pi pi-times" (click)="onCloseAttachPreview()"></i>
        </div>
        <div class="cvah-title">
          <p>{{ attachmentSelected?.file_name }}</p>
        </div>
      </div>
      <div class="cva-body">
        <div class="cva-body-preview">
          <div class="cvabp-icon">
            <svg
              viewBox="0 0 88 110"
              height="110"
              width="88"
              preserveAspectRatio="xMidYMid meet"
              *ngIf="!attachmentSelected?.imgSrc"
            >
              <defs>
                <path
                  d="M3 0h56.928a5 5 0 0 1 3.536 1.464l15.072 15.072A5 5 0 0 1 80 20.07V101a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z"
                  id="a"
                ></path>
              </defs>
              <g transform="translate(4 3)" fill="none" fill-rule="evenodd">
                <use fill="#000" filter="url(#filter-3)" xlink:href="#a"></use>
                <use fill="#FFF" xlink:href="#a"></use>
                <path
                  stroke-opacity="0.08"
                  stroke="#000"
                  d="M3-.5h56.929a5.5 5.5 0 0 1 3.889 1.61l15.071 15.072a5.5 5.5 0 0 1 1.611 3.89V101a3.5 3.5 0 0 1-3.5 3.5H3A3.5 3.5 0 0 1-.5 101V3A3.5 3.5 0 0 1 3-.5z"
                  fill="url(#linearGradient-1)"
                ></path>
                <rect
                  fill-opacity="0.2"
                  fill="#000"
                  x="13"
                  y="28"
                  width="52"
                  height="2"
                  rx="0.5"
                ></rect>
                <rect
                  fill-opacity="0.2"
                  fill="#000"
                  x="13"
                  y="33"
                  width="52"
                  height="2"
                  rx="0.5"
                ></rect>
                <rect
                  fill-opacity="0.2"
                  fill="#000"
                  x="13"
                  y="38"
                  width="52"
                  height="2"
                  rx="0.5"
                ></rect>
                <rect
                  fill-opacity="0.2"
                  fill="#000"
                  x="13"
                  y="43"
                  width="40"
                  height="2"
                  rx="0.5"
                ></rect>
                <rect
                  fill-opacity="0.2"
                  fill="#000"
                  x="13"
                  y="53"
                  width="52"
                  height="2"
                  rx="0.5"
                ></rect>
                <rect
                  fill-opacity="0.2"
                  fill="#000"
                  x="13"
                  y="58"
                  width="52"
                  height="2"
                  rx="0.5"
                ></rect>
                <rect
                  fill-opacity="0.2"
                  fill="#000"
                  x="13"
                  y="63"
                  width="52"
                  height="2"
                  rx="0.5"
                ></rect>
                <rect
                  fill-opacity="0.2"
                  fill="#000"
                  x="13"
                  y="68"
                  width="52"
                  height="2"
                  rx="0.5"
                ></rect>
                <rect
                  fill-opacity="0.2"
                  fill="#000"
                  x="13"
                  y="73"
                  width="52"
                  height="2"
                  rx="0.5"
                ></rect>
                <rect
                  fill-opacity="0.2"
                  fill="#000"
                  x="13"
                  y="78"
                  width="27"
                  height="2"
                  rx="0.5"
                ></rect>
                <path
                  d="M61.5.5v15a3 3 0 0 0 3 3h15"
                  stroke-opacity="0.12"
                  stroke="#000"
                  fill="#FFF"
                ></path>
              </g>
            </svg>
            <img
              [src]="attachmentSelected?.imgSrc"
              alt="attachImgPreview"
              id="attachImgPreview"
              *ngIf="attachmentSelected?.imgSrc"
            />
          </div>
          <div class="cvabp-msg">
            <p *ngIf="!attachmentSelected?.imgSrc">No preview available</p>
            <small
              >{{ attachmentSelected?.file_size }} KB -
              {{ attachmentSelected?.file_type }}</small
            >
          </div>
          <div class="cva-body-box-msg">
            <textarea
              name="send-message"
              id="send-message"
              placeholder="Type a message."
              [(ngModel)]="attachmentSelected.caption"
              (keydown.enter)="sendAttachmentMessage()"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="cva-footer">
        <div class="cvaf-items">
          <div
            class="cvafi-item"
            *ngFor="let item of uploadedFiles; let i = index"
            (click)="attachmentSelected = item"
          >
            <i class="fa fa-file"></i>
            <i
              class="fa fa-times remove-attach"
              (click)="declineAttachment(i)"
            ></i>
          </div>
          <div class="cvafi-item" (click)="chatAttachInput.click()">
            <i class="fa fa-plus"></i>
          </div>
        </div>
        <div class="cvaf-send-icon" (click)="sendAttachmentMessage()">
          <span>
            <svg viewBox="0 0 24 24" width="24" height="24" class="">
              <path
                fill="currentColor"
                d="M1.101 21.757 23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="cv-placeholder" *ngIf="!isChatView">
    <svg
      width="360"
      viewBox="0 0 303 172"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
      class=""
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M229.565 160.229c32.647-10.984 57.366-41.988 53.825-86.81-5.381-68.1-71.025-84.993-111.918-64.932C115.998 35.7 108.972 40.16 69.239 40.16c-29.594 0-59.726 14.254-63.492 52.791-2.73 27.933 8.252 52.315 48.89 64.764 73.962 22.657 143.38 13.128 174.928 2.513Z"
        fill="#DAF7F3"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M131.589 68.942h.01c6.261 0 11.336-5.263 11.336-11.756S137.86 45.43 131.599 45.43c-5.081 0-9.381 3.466-10.822 8.242a7.302 7.302 0 0 0-2.404-.405c-4.174 0-7.558 3.51-7.558 7.838s3.384 7.837 7.558 7.837h13.216ZM105.682 128.716c3.504 0 6.344-2.808 6.344-6.27 0-3.463-2.84-6.27-6.344-6.27-1.156 0-2.24.305-3.173.839v-.056c0-6.492-5.326-11.756-11.896-11.756-5.29 0-9.775 3.413-11.32 8.132a8.025 8.025 0 0 0-2.163-.294c-4.38 0-7.93 3.509-7.93 7.837 0 4.329 3.55 7.838 7.93 7.838h28.552Z"
        fill="#fff"
      ></path>
      <rect
        x=".445"
        y=".55"
        width="50.58"
        height="100.068"
        rx="7.5"
        transform="rotate(6 -391.775 121.507) skewX(.036)"
        fill="#42CBA5"
        stroke="#316474"
      ></rect>
      <rect
        x=".445"
        y=".55"
        width="50.403"
        height="99.722"
        rx="7.5"
        transform="rotate(6 -356.664 123.217) skewX(.036)"
        fill="#fff"
        stroke="#316474"
      ></rect>
      <path
        d="m57.16 51.735-8.568 82.024a5.495 5.495 0 0 1-6.042 4.895l-32.97-3.465a5.504 5.504 0 0 1-4.897-6.045l8.569-82.024a5.496 5.496 0 0 1 6.041-4.895l5.259.553 22.452 2.36 5.259.552a5.504 5.504 0 0 1 4.898 6.045Z"
        fill="#EEFEFA"
        stroke="#316474"
      ></path>
      <path
        d="M26.2 102.937c.863.082 1.732.182 2.602.273.238-2.178.469-4.366.69-6.546l-2.61-.274c-.238 2.178-.477 4.365-.681 6.547Zm-2.73-9.608 2.27-1.833 1.837 2.264 1.135-.917-1.838-2.266 2.27-1.833-.92-1.133-2.269 1.834-1.837-2.264-1.136.916 1.839 2.265-2.27 1.835.92 1.132Zm-.816 5.286c-.128 1.3-.265 2.6-.41 3.899.877.109 1.748.183 2.626.284.146-1.31.275-2.614.413-3.925-.878-.092-1.753-.218-2.629-.258Zm16.848-8.837c-.506 4.801-1.019 9.593-1.516 14.396.88.083 1.748.192 2.628.267.496-4.794 1-9.578 1.513-14.37-.864-.143-1.747-.192-2.625-.293Zm-4.264 2.668c-.389 3.772-.803 7.541-1.183 11.314.87.091 1.74.174 2.601.273.447-3.912.826-7.84 1.255-11.755-.855-.15-1.731-.181-2.589-.306-.04.156-.069.314-.084.474Zm-4.132 1.736c-.043.159-.06.329-.077.49-.297 2.896-.617 5.78-.905 8.676l2.61.274c.124-1.02.214-2.035.33-3.055.197-2.036.455-4.075.627-6.115-.863-.08-1.724-.17-2.585-.27Z"
        fill="#316474"
      ></path>
      <path
        d="M17.892 48.489a1.652 1.652 0 0 0 1.468 1.803 1.65 1.65 0 0 0 1.82-1.459 1.652 1.652 0 0 0-1.468-1.803 1.65 1.65 0 0 0-1.82 1.459ZM231.807 136.678l-33.863 2.362c-.294.02-.54-.02-.695-.08a.472.472 0 0 1-.089-.042l-.704-10.042a.61.61 0 0 1 .082-.054c.145-.081.383-.154.677-.175l33.863-2.362c.294-.02.54.02.695.08.041.016.069.03.088.042l.705 10.042a.61.61 0 0 1-.082.054 1.678 1.678 0 0 1-.677.175Z"
        fill="#fff"
        stroke="#316474"
      ></path>
      <path
        d="m283.734 125.679-138.87 9.684c-2.87.2-5.371-1.963-5.571-4.823l-6.234-88.905c-.201-2.86 1.972-5.35 4.844-5.55l138.87-9.684c2.874-.2 5.371 1.963 5.572 4.823l6.233 88.905c.201 2.86-1.971 5.349-4.844 5.55Z"
        fill="#fff"
      ></path>
      <path
        d="M144.864 135.363c-2.87.2-5.371-1.963-5.571-4.823l-6.234-88.905c-.201-2.86 1.972-5.35 4.844-5.55l138.87-9.684c2.874-.2 5.371 1.963 5.572 4.823l6.233 88.905c.201 2.86-1.971 5.349-4.844 5.55"
        stroke="#316474"
      ></path>
      <path
        d="m278.565 121.405-129.885 9.058c-2.424.169-4.506-1.602-4.668-3.913l-5.669-80.855c-.162-2.31 1.651-4.354 4.076-4.523l129.885-9.058c2.427-.169 4.506 1.603 4.668 3.913l5.669 80.855c.162 2.311-1.649 4.354-4.076 4.523Z"
        fill="#EEFEFA"
        stroke="#316474"
      ></path>
      <path
        d="m230.198 129.97 68.493-4.777.42 5.996c.055.781-.098 1.478-.363 1.972-.27.5-.611.726-.923.748l-165.031 11.509c-.312.022-.681-.155-1.017-.613-.332-.452-.581-1.121-.636-1.902l-.42-5.996 68.494-4.776c.261.79.652 1.483 1.142 1.998.572.6 1.308.986 2.125.929l24.889-1.736c.817-.057 1.491-.54 1.974-1.214.413-.577.705-1.318.853-2.138Z"
        fill="#42CBA5"
        stroke="#316474"
      ></path>
      <path
        d="m230.367 129.051 69.908-4.876.258 3.676a1.51 1.51 0 0 1-1.403 1.61l-168.272 11.735a1.51 1.51 0 0 1-1.613-1.399l-.258-3.676 69.909-4.876a3.323 3.323 0 0 0 3.188 1.806l25.378-1.77a3.32 3.32 0 0 0 2.905-2.23Z"
        fill="#fff"
        stroke="#316474"
      ></path>
      <circle
        transform="rotate(-3.989 1304.861 -2982.552) skewX(.021)"
        fill="#42CBA5"
        stroke="#316474"
        r="15.997"
      ></circle>
      <path
        d="m208.184 87.11-3.407-2.75-.001-.002a1.952 1.952 0 0 0-2.715.25 1.89 1.89 0 0 0 .249 2.692l.002.001 5.077 4.11v.001a1.95 1.95 0 0 0 2.853-.433l8.041-12.209a1.892 1.892 0 0 0-.573-2.643 1.95 1.95 0 0 0-2.667.567l-6.859 10.415Z"
        fill="#fff"
        stroke="#316474"
      ></path>
    </svg>
    <div class="cvp-title">
      <h1>Almnabr WhatsApp</h1>
      <p>Send and receive messages without keeping your phone online.</p>
    </div>
  </div>

  <div class="cv-loader" *ngIf="isLoadingMessages">
    <div class="cvl-img">
      <img src="assets/images/whatsapp-icon-loader.svg" alt="" />
    </div>
    <div class="cvl-title">
      <p>Loading Messages...</p>
    </div>
  </div>
</div>

<input
  type="file"
  #chatAttachInput
  id="chatAttachInput"
  accept="*"
  hidden
  multiple="multiple"
  (change)="onSendAttachment($event.target.files)"
/>
<ng-template #hidenMassage>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_hidden_message" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <div class="cvb-hidden-messages">
      <div class="cvb-messages-item">
        <div class="cvbmi-text" *ngIf="hidenMassegeType == 'text'">
          <span>{{ hidenMassege?.messages[0]?.body }}</span>
          <div class="cvbmi-date">
            <small>{{ hidenMassege?.messages[0]?.time | dateFormnow }}</small>
          </div>
          <div class="cvbmi-date m0op5">
            <small *ngIf="hidenMassege?.messages[0]?.writer_name"
              >{{ "lang_writer_name" | language }}:
              {{ hidenMassege?.messages[0]?.writer_name }}</small
            >
          </div>
        </div>
        <div class="cvbmi-attachment" *ngIf="hidenMassegeType == 'document'">
          <div
            class="cvbmia-content"
            matTooltip="{{ 'lang_download_file' | language }}"
            (click)="
              onDownloadAttachment(
                hidenMassege?.messages[0]?.attachment,
                hidenMassege?.messages[0]?.body,
                hidenMassege?.messages[0]?.media_id
              )
            "
          >
            <span
              >{{ hidenMassege?.messages[0]?.body }} -
              {{ hidenMassege?.messages[0]?.mtype }}</span
            >
            <i class="fa fa-download"></i>
          </div>
          <div class="cvbmi-date">
            <small>{{ hidenMassege?.messages[0]?.time | dateFormnow }}</small>
          </div>
          <div class="cvbmi-date m0op5">
            <small *ngIf="message?.writer_name"
              >{{ "lang_writer_name" | language }}:
              {{ hidenMassege?.messages[0]?.writer_name }}</small
            >
          </div>
        </div>
        <div class="cvbmi-reply" *ngIf="hidenMassegeType == 'replytext'">
          <div class="cvbmir-content">
            <div class="cvbmirc-text">
              <p>
                {{ hidenMassege?.messages[0]?.replyOfMsg | shortenLength: 200 }}
              </p>
            </div>
            <div class="cvbmirc-msg">
              <span>{{ hidenMassege?.messages[0]?.body }}</span>
            </div>
          </div>
          <div class="cvbmi-date">
            <small>{{ hidenMassege?.messages[0]?.time | dateFormnow }}</small>
          </div>
          <div class="cvbmi-date m0op5">
            <small *ngIf="hidenMassege?.messages[0]?.writer_name"
              >{{ "lang_writer_name" | language }}:
              {{ hidenMassege?.messages[0]?.writer_name }}</small
            >
          </div>
        </div>
        <div class="cvbmi-contact" *ngIf="hidenMassegeType == 'contact'">
          <div class="cvbmic-number">
            Contact number:
            <span>{{ hidenMassege?.messages[0]?.all?.wa_cus_no }}</span>
          </div>
          <span
            class="cvbmic-msg"
            [innerHtml]="hidenMassege?.messages[0]?.body"
          ></span>
          <div class="cvbmi-date">
            <small>{{ hidenMassege?.messages[0]?.time | dateFormnow }}</small>
          </div>
          <div class="cvbmi-date m0op5">
            <small *ngIf="hidenMassege?.messages[0]?.writer_name"
              >{{ "lang_writer_name" | language }}:
              {{ hidenMassege?.messages[0]?.writer_name }}</small
            >
          </div>
          <hr
            *ngIf="
              hidenMassege?.messages[0]?.all?.wa_cus_no != chatData?.number
            "
          />
          <button
            class="cvbmic-msg-btn"
            *ngIf="
              hidenMassege?.messages[0]?.all?.wa_cus_no !=
              hidenMassege?.messages[0]?.number
            "
            (click)="onOpenChat(hidenMassege)"
          >
            Message
          </button>
        </div>
        <div class="cvbmi-location" *ngIf="hidenMassegeType == 'location'">
          <div class="cvbmil-agm">
            <agm-map
              [latitude]="+hidenMassege?.messages[0]?.all?.latitude"
              [longitude]="+hidenMassege?.messages[0]?.all?.longitude"
              [scrollwheel]="true"
              [zoom]="zoom"
            >
              <agm-marker
                [latitude]="+hidenMassege?.messages[0]?.all?.latitude"
                [longitude]="+hidenMassege?.messages[0]?.all?.longitude"
              ></agm-marker>
            </agm-map>
          </div>

          <div class="cvbmi-date">
            <small>{{ hidenMassege?.messages[0]?.time | dateFormnow }}</small>
          </div>
          <div class="cvbmi-date m0op5">
            <small *ngIf="hidenMassege?.messages[0]?.writer_name"
              >{{ "lang_writer_name" | language }}:
              {{ hidenMassege?.messages[0]?.writer_name }}</small
            >
          </div>
          <hr
            *ngIf="
              hidenMassege?.messages[0]?.all?.wa_cus_no != chatData?.number
            "
          />
          <button
            class="cvbmic-msg-btn"
            *ngIf="
              hidenMassege?.messages[0]?.all?.wa_cus_no != chatData?.number
            "
            (click)="onOpenChat(hidenMassege)"
          >
            Message
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #viewersMassage>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_viewed_by" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide(); msg_histories_id = null"
      >
        &times;
      </button>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive customResponsive">
          <table border="1" class="table CustomTable">
            <thead>
              <tr>
                <td>#</td>
                <td>{{ "lang_viewer" | language }}</td>
                <td>{{ "lang_viewed_datetime" | language }}</td>
              </tr>
            </thead>
            <tbody *ngIf="viewers_list.length" class="AppendList">
              <tr
                *ngFor="
                  let data of viewers_list;
                  let i = index;
                  let odd = odd;
                  let even = even
                "
                [ngClass]="{ odd: odd, even: even }"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ data.viewer }}</td>
                <td>{{ data.viewed_datetime }}</td>
              </tr>
            </tbody>
            <tbody *ngIf="!viewers_list.length" class="AppendList">
              <tr class="odd">
                <td colspan="3" align="center">
                  {{ "lang_no_data" | language }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="viewers_list.length" class="card-footer padb0 bottomfooter">
          <div class="row responsive_pagination">
            <div
              class="col-lg-3 col-sm-3 mb-10"
              style="height: fit-content; margin: auto 0"
            >
              {{ "lang_total" | language }} :
              {{ total_records }}
            </div>
            <div
              class="col-lg-6 col-sm-6 mb-10"
              style="display: flex; justify-content: center"
            >
              <ngb-pagination
                class="m-auto"
                [collectionSize]="total_records"
                [rotate]="true"
                [ellipses]="false"
                [maxSize]="3"
                [boundaryLinks]="true"
                [(page)]="page_no"
                [pageSize]="page_size"
                (pageChange)="get_viewers_pagination()"
              ></ngb-pagination>
            </div>
            <div
              class="col-lg-3 col-sm-3 mb-10"
              style="display: flex; justify-content: end; margin: auto"
            >
              <mat-select
                [(ngModel)]="page_size"
                (selectionChange)="get_viewers_pagination()"
              >
                <mat-option class="text-center" [value]="10"
                  >10 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" [value]="20"
                  >20 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" [value]="50"
                  >50 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" [value]="100"
                  >100 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option class="text-center" [value]="500"
                  >500 {{ "lang_per_page" | language }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
