<div class="table-responsive customResponsive">
	<table border="1" class="table CustomTable">
		<thead>
			<tr>
				<td>#</td>
				<td>{{ "lang_file_attach_title" | language }}</td>
				<td>{{ "lang_writer" | language }}</td>
				<td>{{ "lang_on_date" | language }}</td>
				<td>{{ "lang_file_size" | language }}</td>
				<td>{{ "lang_attachment_type" | language }}</td>
				<td>{{ "lang_view" | language }}</td>
			</tr>
		</thead>
		<tbody *ngIf="All_Attachments?.length > 0" class="AppendList">
			<tr
				*ngFor="
					let attach of All_Attachments;
					let i = index;
					let odd = odd;
					let even = even
				"
				[ngClass]="{ odd: odd, even: even }"
			>
				<td>{{ i + 1 }}</td>
				<td>{{ isInsideSupervision ? attach?.project_supervision_form_file_attach_title : attach?.file_name_en }}</td>
				<td>{{ attach?.writer_name || '---' }}</td>
				<td>{{ attach?.created_datetime || '---' }}</td>
				<td>
					{{ isInsideSupervision ? attach?.project_supervision_form_file_size : attach?.file_size }}
				</td>
				<td>
					{{ isInsideSupervision ? attach?.project_supervision_form_file_attach_type_label : '---' }}
				</td>

				<td>
					<i
						class="fa fa-eye mnabricon"
						style="margin: 0 5px"
						*ngIf="
							attach?.project_supervision_form_file_extension == 'png' ||
							attach?.project_supervision_form_file_extension == 'jpg' ||
							attach?.project_supervision_form_file_extension == 'gif' ||
							attach?.project_supervision_form_file_extension == 'jpeg' ||
							attach?.project_supervision_form_file_extension == 'PNG' ||
							attach?.project_supervision_form_file_extension == 'JPG' ||
							attach?.project_supervision_form_file_extension == 'GIF' ||
							attach?.project_supervision_form_file_extension == 'JPEG' || 
							attach?.file_extension == 'png' ||
							attach?.file_extension == 'jpg' ||
							attach?.file_extension == 'gif' ||
							attach?.file_extension == 'jpeg' ||
							attach?.file_extension == 'PNG' ||
							attach?.file_extension == 'JPG' ||
							attach?.file_extension == 'GIF' ||
							attach?.file_extension == 'JPEG' 
						"
						(click)="
							ds.preview_pdf_file(
								isInsideSupervision ? attach?.project_supervision_form_file_path : attach?.link,
								isInsideSupervision ? attach?.project_supervision_form_file_attach_title : attach?.file_name_en
							)
						"
					></i>
					<i
						class="fa fa-eye mnabricon"
						style="margin: 0 5px"
						*ngIf="
							attach?.project_supervision_form_file_extension == 'pdf' ||
							attach?.project_supervision_form_file_extension == 'PDF' || 
							attach?.file_extension == 'pdf' || attach?.file_extension == 'PDF'
						"
						(click)="preview_file(attach?.project_supervision_form_file_path || attach?.link)"
					></i>
					<i
						class="fa fa-download mnabricon"
						(click)="
							downloadFile(
								attach?.project_supervision_form_file_path || attach?.link,
								attach?.project_supervision_form_file_attach_title || attach?.file_name_en
							)
						"
					></i>
				</td>
			</tr>
		</tbody>
		<tbody *ngIf="All_Attachments?.length < 1" class="AppendList">
			<tr class="odd">
				<td colspan="8" class="nodata" align="center">
					{{ "lang_no_data" | language }}
				</td>
			</tr>
		</tbody>
	</table>
</div>
