import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-refuse-reason",
	templateUrl: "./refuse-reason.component.html",
	styleUrls: ["./refuse-reason.component.scss"],
})
export class RefuseReasonComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<RefuseReasonComponent>,
		public ds: DataService,
		private spinner: PreloaderService,
		public lang: LanguagePipe,
		private _sweetAlertService: SweetAlertService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {}

	formGroup = this.fb.group({
		reason: [""],
	});

	cancel(status = false) {
		this.dialogRef.close({ status });
	}

	sendFilterForm() {
		this.dialogRef.close({ status: true, reason: this.formGroup.value.reason });
	}
}
