import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
  selector: "app-step-four",
  templateUrl: "./step-four.component.html",
  styleUrls: ["./step-four.component.scss"],
})
export class StepFourComponent implements OnInit {
  @Input() stepper: MatStepper;
  @Input() t_id: any = "";
  @Input() reviewers: any = [];
  @Input() requestInfo: any = "";
  @Output() reviewersOutput = new EventEmitter<any>();
  constructor(
    public ds: DataService,
    public lang: LanguagePipe,
    private _sweetAlertService: SweetAlertService,
    private spinner: PreloaderService
  ) {}

  ngOnInit(): void {
    this.spinner.hide();
  }

  toggleChecked(e, i) {
    this.reviewers[i].checked = e?.target?.checked;
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  /*   makeReviews(){

  } */
  @Output() resfreshView = new EventEmitter<any>();
  @Output() resfreshUsers = new EventEmitter<any>();
  moveToAddToReviews() {
    if (this.reviewers.length > 0) {
      this.spinner.show();
      let formData = new FormData();

      let checkedReviewersOnly: any = this.reviewers.filter(
        (re) => re.checked == true
      );

      if (checkedReviewersOnly.length == 0) {
        this.spinner.hide();
        this._sweetAlertService.warningToast(
          this.lang.transform("lang_select_anyone"),
          2000
        );
        return 0;
      }

      for (let index = 0; index < checkedReviewersOnly.length; index++) {
        const element = checkedReviewersOnly[index];
        if (element.checked) {
          formData.append(`reviewers[${index + 1}][user_id]`, element.user_id);
          formData.append(
            `reviewers[${index + 1}][reviewer_id]`,
            element.reviewer_id
          );
        }
      }
      this.ds
        .post(`form/FORM_SAL/sign_reviewer_request/${this.t_id}`, formData)
        .subscribe(
          (data) => {
            if (data.status) {
              this.resfreshView.emit();
              this._sweetAlertService.successToast(
                this.lang.transform("lang_data_sent_successfully"),
                2000
              );
              setTimeout(() => {
                location.reload();
              }, 2000);
              this.spinner.hide();
            } else {
              this.spinner.hide();
              this._sweetAlertService.errorMessage(data.error || data.msg);
            }
          },
          (error) => {
            this._sweetAlertService.errorMessage(error?.error?.error);
            this.spinner.hide();
          }
        );
    } else {
      this._sweetAlertService.warningToast(
        this.lang.transform("lang_select_anyone"),
        2000
      );
    }
  }
}
