import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { SnagGroupsAddEditComponent } from "./snag-groups-add-edit/snag-groups-add-edit.component";

@Component({
	selector: "app-snaglist-groups",
	templateUrl: "./snaglist-groups.component.html",
	styleUrls: ["./snaglist-groups.component.scss"],
})
export class SnaglistGroupsComponent implements OnInit {
	@Input() supervision_data: any;
	@Input() able2add: any;
	@Input() able2edit: any;
	@Input() project_data: any;
	@Input() makeActionGroups: any;
	lang_key = localStorage.getItem("lang_key") || "en";
	group1List = [];
	selected_group1 = null;
	searchKey = "";
	snaglist_groups = [];
	page_no = 1;
	page_size = 10;
	collection_size = 0;
	constructor(
		private spinner: PreloaderService,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.getGroup1List();
		this.search_snaglist_groups();
	}
	getGroup1List() {
		let param = new FormData();
		param.append("search_key", "");
		param.append("lang_key", this.lang_key);
		this.ds.getActionByUrl(this.ds.formData2string(param), "lpgone").subscribe(
			(res) => {
				if (res.status) {
					this.group1List = res?.records;
				} else {
					this.group1List = [];
				}
			},
			(error) => {
				this.group1List = [];
				this.sweetAlert.errorToast(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	search_snaglist_groups() {
		let param = new FormData();
		param.append("search_key", this.searchKey);
		param.append("projects_profile_id", this.project_data?.projects_profile_id);
		param.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id
		);
		param.append("platform_group1_code_system", this.selected_group1 || "");
		this.spinner.show();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(param),
				`p/snag/glist/${this.page_no}/${this.page_size}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.snaglist_groups = res.records;
						this.collection_size = res.page?.total_records;
					} else {
						this.snaglist_groups = [];
						this.collection_size = 0;
					}
				},
				(err) => {
					this.spinner.hide();
					this.snaglist_groups = [];
					this.collection_size = 0;
				}
			);
	}

	createUpdateSnaglistGroup() {
		const dialogRef = this.dialog.open(SnagGroupsAddEditComponent, {
			width: "900px",
			data: {
				projects_profile_id: this.project_data?.projects_profile_id,
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.search_snaglist_groups();
			}
		});
	}

	deleteSnagGroup(id) {
		this.sweetAlert
			.confirmMessage(
				this.lang.transform("lang_are_you_sure"),
				this.lang.transform("lang_yes")
			)
			.then((res) => {
				if (res?.isConfirmed) {
					this.spinner.show();
					this.ds
						.deleteActionByUrl(
							[id],
							`p/snag/gdelete/${this.project_data?.projects_profile_id}/${this.supervision_data?.projects_work_area_id}`
						)
						.subscribe(
							(res) => {
								this.spinner.hide();
								if (res.status) {
									this.sweetAlert.successToast(res.msg, 2000);
									this.search_snaglist_groups();
								} else {
									this.sweetAlert.errorToast(res.message, 2000);
								}
							},
							(err) => {
								this.spinner.hide();
								this.sweetAlert.errorToast(
									err && err.error && err.error.error
										? err.error.error
										: this.lang.transform("lang_internal_server_error"),
									2000
								);
							}
						);
				}
			});
	}
}
