<div class="viewquotation stylepanels" *ngIf="status">
  <div class="material-panel-body communication">
    <fieldset
      class="cardback fieldset mb-10"
      [ngClass]="{ ' contentrtl': lang_key == 'ar' }"
    >
      <legend>{{ "lang_bonus_form" | language }}</legend>
      <div class="row mb-10">
        <div class="contentbodypanel width100 mt-0">
          <div class="col-12 contentpanel">
            <div class="itemsdata">
              <label>
                {{ "lang_human_resources_employee_details" | language }} :
              </label>
              <div class="TableParts">
                <div
                  class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
                >
                  <table class="table CustomTables text-center">
                    <tbody class="AppendList quotationtable">
                      <tr>
                        <td class="text-start">
                          {{ "lang_human_resources_employee_name" | language }}
                          :
                        </td>
                        <td>
                          {{
                            records[0]?.firstname_english +
                              " " +
                              records[0]?.lastname_english
                          }}
                        </td>

                        <td class="text-start">
                          {{
                            "lang_human_resources_job_title_iqama" | language
                          }}
                          :
                        </td>
                        <td>
                          {{ records[0]?.jobname }}
                        </td>

                        <td class="text-start">
                          {{ "lang_human_resources_joining_date" | language }} :
                        </td>
                        <td>
                          {{ records[0]?.joining_start_date_english }} -
                          {{ records[0]?.joining_start_date_arabic }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-start">
                          {{ "lang_mobile_number" | language }} :
                        </td>
                        <td>
                          {{ records[0]?.primary_mobile }}
                        </td>

                        <td class="text-start">
                          {{ "lang_branch" | language }} :
                        </td>
                        <td>
                          {{ records[0]?.branch }}
                        </td>

                        <td class="text-start">
                          {{ "lang_email" | language }} :
                        </td>
                        <td>
                          {{ records[0]?.primary_email }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
            </div>

            <div
              class="itemsdata"
              *ngIf="alldata?.form_ovr1_records?.length > 0"
            >
              <label> {{ "lang_overtime_details" | language }} : </label>
              <div class="row mb-2 mt-2">
                <div class="col-md-12 pad0">
                  <div class="table-responsive customResponsive">
                    <table border="1" class="table CustomTable">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>{{ "lang_hours" | language }}</td>
                          <td>{{ "lang_amount" | language }}</td>
                          <td>{{ "lang_date" | language }}</td>
                        </tr>
                      </thead>
                      <tbody class="AppendList">
                        <tr
                          *ngFor="
                            let his of alldata?.form_ovr1_records;
                            let i = index;
                            let odd = odd;
                            let even = even
                          "
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ his.overtime_hours }}</td>

                          <td>{{ his.overtime_amount }}</td>
                          <td>
                            {{
                              his.overtime_date_english +
                                " - " +
                                his.overtime_date_arabic
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br />
            </div>

            <div class="itemsdata" *ngIf="notes.length > 0">
              <label> {{ "lang_history" | language }} : </label>
              <div class="row mb-2 mt-2">
                <div class="col-md-12 pad0">
                  <div class="table-responsive customResponsive">
                    <table border="1" class="table CustomTable">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>{{ "lang_name" | language }}</td>
                          <td>{{ "lang_ondate" | language }}</td>
                          <td>{{ "lang_notes" | language }}</td>
                        </tr>
                      </thead>
                      <tbody class="AppendList">
                        <tr
                          *ngFor="
                            let his of notes;
                            let i = index;
                            let odd = odd;
                            let even = even
                          "
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ his.transactions_records_user_name }}</td>

                          <td>{{ his.transactions_records_datetime }}</td>
                          <td>{{ his.transactions_records_note }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</div>
