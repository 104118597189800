<div mat-dialog-title>
  <div class="d-flex justify-content-between">
    <h4 *ngIf="!editMode">{{ "lang_add" | language }}</h4>
    <h4 *ngIf="editMode">{{ "lang_edit" | language }}</h4>
    <span mat-dialog-close><i class="fa fa-times mnabricon"></i></span>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ "lang_username" | language }}</mat-label>
            <input
              matInput
              placeholder="{{ 'lang_username' | language }}"
              type="text"
              formControlName="user_username"
              autocomplete="username"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ "lang_password" | language }}</mat-label>
            <input
              matInput
              placeholder="{{ 'lang_password' | language }}"
              type="password"
              formControlName="user_password"
              autocomplete="current-password"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ "lang_email" | language }}</mat-label>
            <input
              matInput
              placeholder="{{ 'lang_email' | language }}"
              type="email"
              formControlName="user_email"
              autocomplete="email"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ "lang_mobile" | language }}</mat-label>
            <input
              matInput
              placeholder="{{ 'lang_mobile' | language }}"
              type="text"
              formControlName="user_phone"
              autocomplete="mobile"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
  <div class="col-md-4 col-xs-12">
    <button
      type="button"
      class="albutton mnabrbutton mat-accent"
      mat-button
      mat-dialog-close
    >
      {{ "lang_close" | language }}
    </button>
  </div>
  <div class="col-md-4 col-xs-12">
    <button
      type="button"
      class="albutton mnabrbutton mat-accent"
      mat-button
      (click)="submit()"
    >
      {{ "lang_submit" | language }}
    </button>
  </div>
</mat-dialog-actions>
