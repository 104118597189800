import { MatSnackBar } from "@angular/material/snack-bar";
import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import moment from "moment";
import { Observable, Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { setInterval } from "timers";
import { type } from "os";

declare var $: any;

@Component({
	selector: "app-ect1-step1-config",
	templateUrl: "./ect1-step1-config.component.html",
	styleUrls: ["./ect1-step1-config.component.scss"],
})
export class Ect1Step1ConfigComponent implements OnInit {
	users: any = [];
	history = [];
	pageNo = 1;
	total_records = 0;
	pageSize = "10";
	log_action = null;
	day: number;
	mon: number;
	year: number;
	@Output() updateForm = new EventEmitter();
	@Input() account_operation: string;
	@Input() form_request_id;
	@Input() form_key;
	@Input() id;
	@Input() branch_id;
	@Input() contract_type;
	@Input() formCt1Data;
	invoiceList: any = [];
	data1: any = "";
	data2: any = "";
	contract_types: any = [];
	lang_key = localStorage.getItem("lang_key") || "en";
	form: FormGroup;
	branches: any = [];
	contract_termination_list: any = [];
	lastWorkingDate: any;
	directManagerName: any;
	contractTerminationCase: any;
	submitted: boolean = false;
	dateRange: FormGroup;
	private _maindata;
	@Input()
	set maindata(maindata) {
		this._maindata = maindata;

		this.buildform();
		this.form
			.get("termination_case")
			.setValue(
				this._maindata?.form_ect1_data?.records?.contract_termination_case
			);
		if (this._maindata?.form_ect1_data?.records?.direct_manager != undefined) {
			this.form
				.get("direct_manager")
				.setValue(this._maindata?.form_ect1_data?.records?.direct_manager);
			this.users.push({
				value: this._maindata?.form_ect1_data?.records?.direct_manager,
				label: this._maindata?.form_ect1_data?.records?.direct_manager_name,
			});
		}
		this.form
			.get("last_working_date")
			.setValue(
				new Date(this._maindata?.form_ect1_data?.records?.last_working_date)
			);
	}
	get getmaindata(): string {
		return this._maindata;
	}
	constructor(
		public spinner: PreloaderService,
		public ds: DataService,
		public alert: AlertService,
		public lang: LanguagePipe,
		public router: Router,
		private snackBar: MatSnackBar,
		private sweetAlert: SweetAlertService,
		public datePipe: DatePipe
	) {}

	ngOnInit(): void {
		this.dateRange = new FormGroup({
			log_action_start_date: new FormControl(null),
			log_action_end_date: new FormControl(null),
		});
		this.getAccountHistory();
		this.get_contract_termination_cases();
	}
	private subscriptions = new Subscription();
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	public buildform() {
		this.form = new FormGroup({
			termination_case: new FormControl(null, [Validators.required]),
			direct_manager: new FormControl(null, Validators.required),
			last_working_date: new FormControl(null, Validators.required),
		});
	}
	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 4000,
			panelClass: [className],
		});
	}
	public search_users(key) {
		if (key) {
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.users = res.list;
					}
				});
		}
	}
	get_contract_termination_cases() {
		this.spinner.show();
		this.ds.get("form/FORM_ECT1/get_contract_termination_cases", []).subscribe(
			(res) => {
				if (res?.status) {
					this.contract_termination_list = res;
					this.spinner.hide();
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
					this.contract_termination_list = [];
				}
			},
			(err) => {
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
				this.invoiceList = [];
			}
		);
	}
	getAccountHistory() {
		let formData = new FormData();
		formData.append("log_action", this.log_action || "");
		formData.append("account_operation", this.account_operation || "");
		formData.append(
			"log_action_start_date",
			this.dateRange.get("log_action_start_date").value
				? moment(this.dateRange.get("log_action_start_date").value).format(
						"YYYY/MM/DD"
				  )
				: ""
		);
		formData.append(
			"log_action_end_date",
			this.dateRange.get("log_action_end_date").value
				? moment(this.dateRange.get("log_action_end_date").value).format(
						"YYYY/MM/DD"
				  )
				: ""
		);
		formData.append("id", this.id || "");
		this.spinner.show();
		this.ds
			.post(
				`get_accounts_history_log/${this.pageNo}/${this.pageSize}`,
				formData
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.history = res?.records;
						this.total_records = res?.page?.total_records;
					} else {
						this.history = [];
					}
				},
				(err) => {
					this.spinner.hide();
					this.history = [];
				}
			);
	}
	resetFilter() {
		this.dateRange.reset();
		this.log_action = null;
		this.getAccountHistory();
	}
	public update_end_contract() {
		let param = new FormData();
		param.set("direct_manager", this.form.get("direct_manager").value);
		param.set(
			"employee_number",

			this._maindata?.form_ect1_data?.records?.employee_number
		);
		param.set(
			"transaction_request_id",
			this._maindata?.form_ect1_data?.records?.transaction_request_id
		);
		param.set(
			"contract_termination_case",
			this.form.get("termination_case").value || ""
		);
		param.set(
			"last_working_date_en",
			this.datePipe.transform(
				this.form.get("last_working_date").value,
				"yyyy/MM/dd"
			) || ""
		);
		this.spinner.show();
		this.subscriptions.add(
			this.ds.postActionByUrl(param, "form/FORM_ECT1/et").subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.alert.success(res.msg);
						this.form.reset();
						this.updateForm.emit();
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			)
		);
	}
}
