<div class="row">
  <div class="col-md-3 m-2">
    <span class="example-input-label">
      {{ "lang_select" | language }} {{ "lang_vacation_type" | language }}:
    </span>
    <select [formControl]="selected" class="SelectCustomStyle">
      <option *ngFor="let data of vacationDetails; let i = index" [ngValue]="i">
        {{ data.label | language }}
      </option>
    </select>
  </div>
</div>
<mat-tab-group
  id="vacation"
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event)"
>
  <mat-tab *ngFor="let data of vacationDetails">
    <div class="card mt-3">
      <div class="card-header">
        {{ "lang_vacation_form" | language }}
      </div>
      <div class="card-body">
        <div class="martb0">
          <div class="row header-content">
            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
              <div class="mb-10 Title">
                {{ "lang_balance_vocation_paid_days" | language }}
              </div>
              <div class="mb-10">
                <b
                  >{{ data?.records?.balance_vocation_paid_days || 0 }}
                  {{ "lang_day" | language }}</b
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <fieldset class="fieldset">
        <legend>{{ "lang_vacations_balance" | language }}</legend>
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_total_deserved_days" | language }}</div>
            <div class="mb-10">
              <b> {{ data?.records?.total_deserved_days || 0 }}</b>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_total_detection_vocation_days" | language }}
              <i
                style="padding: 0 10px"
                class="fa fa-info-circle orangestatus-color"
                style="white-space: pre-line !important"
                matTooltip="{{ 'lang_edit' | language }}"
                (click)="get_detection_lists(); openModalV(edit)"
              ></i>
            </div>
            <div class="mb-10">
              <b> {{ data?.records?.total_detection_vocation_days || 0 }}</b>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_total_used_voc_paid_days" | language }}
            </div>
            <div class="mb-10">
              <b> {{ data?.records?.total_used_voc_paid_days || 0 }}</b>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend>{{ "lang_notes" | language }}</legend>
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_contract_yearly_paid_days" | language }}
            </div>
            <div class="mb-10">
              <b>
                {{ data?.records?.contract_yearly_paid_days || 0 }}
                {{ "lang_day" | language }}</b
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_total_used_voc_unpaid_days" | language }}
            </div>
            <div class="mb-10">
              <b> {{ data?.records?.total_used_voc_unpaid_days || 0 }}</b>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_total_employee_worked_days" | language }}
            </div>
            <div class="mb-10">
              <b> {{ data?.records?.total_employee_worked_days || 0 }}</b>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend>{{ "lang_contract" | language }}</legend>
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_total_active_contract_days" | language }}
            </div>
            <div class="mb-10">
              <b> {{ data?.records?.total_active_contract_days || 0 }}</b>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_employee_worked_days_on_active_contract" | language }}
            </div>
            <div class="mb-10">
              <b>
                {{
                  data?.records?.employee_worked_days_on_active_contract || 0
                }}
                {{ "lang_day" | language }}</b
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_remaining_working_days_on_active_contract" | language }}
            </div>
            <div class="mb-10">
              <b>
                {{
                  data?.records?.remaining_working_days_on_active_contract || 0
                }}</b
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_total_contract_days" | language }}</div>
            <div class="mb-10">
              <b> {{ data?.records?.total_contract_days || 0 }}</b>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend>{{ "lang_salary" | language }}</legend>
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_net_salary" | language }}</div>
            <div class="mb-10">
              <b> {{ data?.records?.net_salary || 0 }}</b>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_total_used_voc_paid_days_amount" | language }}
            </div>
            <div class="mb-10">
              <b> {{ data?.records?.total_used_voc_paid_days_amount || 0 }}</b>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_total_used_voc_unpaid_days_amount" | language }}
            </div>
            <div class="mb-10">
              <b>
                {{ data?.records?.total_used_voc_unpaid_days_amount || 0 }}</b
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_per_day_salary" | language }}
            </div>
            <div class="mb-10">
              <b> {{ data?.records?.per_day_salary || 0 }}</b>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </mat-tab>
</mat-tab-group>
<div class="p-1">
  <div class="input-group input-group-sm mb-10">
    <input
      name="account_search"
      id="filter"
      #filter
      [(ngModel)]="searchKey"
      (keyup)="get_employee_modules()"
      class="enablecode h34 form-control almnabrformcontrol"
      type="text"
      autocomplete="off"
      placeholder="{{ 'lang_search' | language }}"
    />
    <span class="input-group-btn">
      <button
        *ngIf="!openFromProfile"
        (click)="showVacation.emit('V1CR')"
        id="btnSearch"
        type="button"
        matTooltip="{{ 'lang_add' | language }}"
        class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-plus blue-tooltip mnabricon"
      ></button>
      <button
        *ngIf="openFromProfile"
        [routerLink]="['/default/profile/create-vacation', id, branch_id]"
        id="btnSearch"
        type="button"
        matTooltip="{{ 'lang_add' | language }}"
        class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-plus blue-tooltip mnabricon"
      ></button>
    </span>
  </div>
</div>
<div class="row p-1">
  <div class="col-md-12">
    <div class="table-responsive dropdownyes customResponsive">
      <table border="1" class="table CustomTable" *ngIf="records?.length > 0">
        <thead>
          <tr>
            <td>#</td>
            <td>{{ "lang_vacation_type" | language }}</td>
            <td>{{ "lang_start_date" | language }}</td>
            <td>{{ "lang_end_date" | language }}</td>
            <td>{{ "lang_total_days" | language }}</td>
            <td>{{ "lang_status" | language }}</td>
            <td>{{ "lang_action" | language }}</td>
          </tr>
        </thead>
        <tbody class="AppendList">
          <tr
            *ngFor="
              let item of records;
              let i = index;
              let odd = odd;
              let even = even
            "
            [ngClass]="{ odd: odd, even: even }"
          >
            <td>{{ item.vacation_id }}</td>
            <td>{{ item.vacation_type_name }}</td>
            <td>{{ item.vacation_start_date_english }}</td>
            <td>{{ item.vacation_end_date_english }}</td>
            <td>{{ item.vacation_total_days }}</td>
            <td>
              <span *ngIf="item.approved_status == 1">{{
                "lang_approved" | language
              }}</span>
              <span *ngIf="item.approved_status == 0">{{
                "lang_rejected" | language
              }}</span>
              <span *ngIf="item.approved_status == '2'">{{
                "lang_cancelled" | language
              }}</span>
            </td>
            <td>
              <i
                class="fa fa-file-pdf-o mnabricon s2icon"
                style="margin: 0 10px; padding: 0"
                (click)="
                  ds.preview_pdf_file(
                    item?.preview_link,
                    'Vacation-' + item.vacation_id
                  )
                "
                matTooltip="{{ 'lang_preview' | language }}"
                *ngIf="!item.link"
              ></i>
              <i
                class="fa fa-eye mnabricon mat-icon"
                (click)="
                  ds.preview_pdf_file(
                    item?.link,
                    'Vacation-' + item.vacation_id
                  )
                "
                matTooltip="{{ 'lang_view' | language }}"
                *ngIf="item.link"
              ></i>
              <i
                *ngIf="item?.cancel_status == '1'"
                class="fa fa-times red ml-2 mb-1"
                aria-hidden="true"
                matTooltip="{{ 'lang_cancel' | language }}"
                (click)="openModalH(cancel, item)"
              >
              </i>
            </td>
          </tr>
        </tbody>
        <!-- <tbody *ngIf="records?.length == '0'" class="AppendList">
							<tr class="odd">
								<td colspan="7" align="center">
								 
								
								</td>
							</tr>
						</tbody> -->
      </table>

      <no-data style="margin: auto" [Opened]="records?.length == '0'"></no-data>
    </div>
  </div>
</div>
<div *ngIf="records?.length != 0" class="card-footer padb0 customborder">
  <div class="row">
    <!--  <div *ngIf="able2delete && requestfor !== 'profile' " class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
			<i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataContr()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
		</div> -->
    <div class="col-lg-3 col-sm-6 col-6 mb-10">
      {{ "lang_total" | language }} {{ wholedata?.page?.total_records }}
    </div>
    <div class="col-lg-5 col-sm-6 col-12 mb-10">
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button
          value="10"
          checked
          (click)="spinner.show(); load_page_size(10)"
        >
          10
        </mat-radio-button>
        <mat-radio-button
          value="20"
          (click)="spinner.show(); load_page_size(20)"
        >
          20
        </mat-radio-button>
        <mat-radio-button
          value="50"
          (click)="spinner.show(); load_page_size(50)"
        >
          50
        </mat-radio-button>
        <mat-radio-button
          value="100"
          (click)="spinner.show(); load_page_size(100)"
        >
          100
        </mat-radio-button>
        <mat-radio-button
          value="500"
          (click)="spinner.show(); load_page_size(500)"
        >
          500
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="col-lg-4 col-sm-6 col-12 mb-10">
      {{ "lang_goto" | language }}
      <select
        class="customselect"
        (change)="spinner.show(); page_length($event.target.value)"
      >
        <option
          *ngFor="let k of ds.getrange(wholedata?.page?.total_pages)"
          value="{{ k }}"
        >
          {{ k }}
        </option>
      </select>
    </div>
  </div>
</div>

<ng-template #edit>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_detection_vacation" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        &times;
      </button>
    </div>
    <div class="modal-body">
      <fieldset
        class="fieldset"
        *ngIf="ds.allowMe('human_resources', 'human_resources_add')"
      >
        <legend>{{ "lang_create_detection" | language }}</legend>
        <div class="mbrl15">
          <form [formGroup]="form" class="form">
            <div class="mbrl15">
              <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                  <label class="mb-10">
                    {{ "lang_days" | language }}
                    <span class="red"> * </span>
                  </label>
                  <input
                    placeholder="{{ 'lang_days' | language }}"
                    formControlName="days"
                    class="form-control almnabrformcontrol custom_input"
                    onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                  />
                  <mat-error
                    *ngIf="
                      form.controls['days'].touched &&
                      form.controls['days'].errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                  <label class="mb-10">
                    {{ "lang_note" | language }}
                    <span class="red"> * </span>
                  </label>
                  <input
                    formControlName="note"
                    class="form-control almnabrformcontrol custom_input"
                    placeholder="{{ 'lang_note' | language }}"
                  />
                  <mat-error
                    *ngIf="
                      form.controls['note'].touched &&
                      form.controls['note'].errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                  <label class="mb-10">
                    {{ "lang_status" | language }}
                    <span class="red"> * </span>
                  </label>
                  <ng-select
                    [items]="statusList"
                    [multiple]="false"
                    bindLabel="label"
                    bindValue="value"
                    clearAllText="Clear"
                    formControlName="status"
                    appendTo="body"
                    placeholder="{{ 'lang_status' | language }}"
                  >
                  </ng-select>
                  <mat-error
                    *ngIf="
                      form.controls['status'].touched &&
                      form.controls['status'].errors?.required
                    "
                  >
                    {{ "lang_field_required" | language }}
                  </mat-error>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div class="row">
            <div class="col-md-9 col-sm-9 col-xs-12 mb-10">
              <app-alert id="default-alert"></app-alert>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
              <button
                type="button"
                (click)="create_detection()"
                class="albutton mnabrbutton mat-accent ml-auto"
                mat-button
              >
                {{ "lang_submit" | language }}
              </button>
            </div>
          </div>
        </div>
      </fieldset>
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>{{
            "lang_credit" | language
          }}</ng-template>
          <div class="material-panel-body">
            <div class="mbrl15 fromrequests">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive customResponsive">
                    <table border="1" class="table CustomTable">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>{{ "lang_writer_name" | language }}</td>
                          <td>{{ "lang_days" | language }}</td>
                          <td>{{ "lang_note" | language }}</td>
                          <td>{{ "lang_status" | language }}</td>
                        </tr>
                      </thead>
                      <tbody
                        *ngIf="detection_lists?.length > 0"
                        class="AppendList"
                      >
                        <tr
                          *ngFor="
                            let list of detection_lists;
                            let i = index;
                            let odd = odd;
                            let even = even
                          "
                          [ngClass]="{ odd: odd, even: even }"
                        >
                          <td>{{ i + 1 }}</td>
                          <td>
                            {{ list.writer_name }}
                          </td>
                          <td>
                            {{ list.days }}
                          </td>
                          <td>
                            {{ list.note }}
                          </td>
                          <td>
                            {{ list.status_label }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        *ngIf="detection_lists?.length == 0"
                        class="AppendList"
                      >
                        <tr class="odd">
                          <td colspan="5" class="nodata" align="center">
                            {{ nodata }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="detection_lists?.length > 0" class="card-footer padb0">
              <div class="row pt-2 w-100 pagination responsive_pagination2">
                <div
                  class="col-12 col-md-4 col-sm-12 d-flex align-items-center total"
                  style="height: fit-content; margin: auto 0"
                >
                  {{ "lang_total" | language }} :
                  {{ totaldetection_lists }}
                </div>
                <div
                  class="col-12 col-md-4 col-sm-12 mt-2 total mt-sm-0 d-flex align-items-center justify-content-center"
                  style="display: flex; justify-content: center"
                >
                  <ngb-pagination
                    class="m-auto"
                    [collectionSize]="totaldetection_lists"
                    [rotate]="true"
                    [ellipses]="false"
                    [maxSize]="3"
                    [boundaryLinks]="true"
                    [(page)]="+detection_page_no"
                    [pageSize]="+detection_page_size"
                    (pageChange)="loadPageFrm($event)"
                  ></ngb-pagination>
                </div>
                <div
                  class="col-12 col-md-4 col-sm-12 mt-2 mt-sm-0 d-flex align-items-center size_paginator justify-content-end"
                >
                  <div style="width: 65%; text-align: center">
                    <mat-select
                      style="margin: auto"
                      [(ngModel)]="detection_page_size"
                      (selectionChange)="loadFrmPagesize(select.value)"
                      #select
                    >
                      <mat-option [value]="10"
                        >10 {{ "lang_per_page" | language }}</mat-option
                      >
                      <mat-option [value]="20"
                        >20 {{ "lang_per_page" | language }}</mat-option
                      >
                      <mat-option [value]="50"
                        >50 {{ "lang_per_page" | language }}</mat-option
                      >
                      <mat-option [value]="100"
                        >100 {{ "lang_per_page" | language }}</mat-option
                      >
                    </mat-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>{{ "lang_debit" | language }}</ng-template>
          <div class="material-panel-body">
            <div class="mbrl15 fromrequests">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive customResponsive">
                    <table border="1" class="table CustomTable">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>{{ "lang_writer_name" | language }}</td>
                          <td>{{ "lang_days" | language }}</td>
                          <td>{{ "lang_note" | language }}</td>
                          <td>{{ "lang_status" | language }}</td>
                        </tr>
                      </thead>
                      <tbody
                        *ngIf="detection_lists_d?.length > 0"
                        class="AppendList"
                      >
                        <tr
                          *ngFor="
                            let list of detection_lists_d;
                            let i = index;
                            let odd = odd;
                            let even = even
                          "
                          [ngClass]="{ odd: odd, even: even }"
                        >
                          <td>{{ i + 1 }}</td>
                          <td>
                            {{ list.writer_name }}
                          </td>
                          <td>
                            {{ list.days }}
                          </td>
                          <td>
                            {{ list.note }}
                          </td>
                          <td>
                            {{ list.status_label }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        *ngIf="detection_lists_d?.length == 0"
                        class="AppendList"
                      >
                        <tr class="odd">
                          <td colspan="5" class="nodata" align="center">
                            {{ nodata_d }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="detection_lists_d?.length > 0"
              class="card-footer padb0"
            >
              <div class="row pt-2 w-100 pagination responsive_pagination2">
                <div
                  class="col-12 col-md-4 col-sm-12 d-flex align-items-center total"
                  style="height: fit-content; margin: auto 0"
                >
                  {{ "lang_total" | language }} :
                  {{ totaldetection_lists_d }}
                </div>
                <div
                  class="col-12 col-md-4 col-sm-12 mt-2 total mt-sm-0 d-flex align-items-center justify-content-center"
                  style="display: flex; justify-content: center"
                >
                  <ngb-pagination
                    class="m-auto"
                    [collectionSize]="totaldetection_lists_d"
                    [rotate]="true"
                    [ellipses]="false"
                    [maxSize]="3"
                    [boundaryLinks]="true"
                    [(page)]="+detection_page_no"
                    [pageSize]="+detection_page_size"
                    (pageChange)="loadPageFrm($event)"
                  ></ngb-pagination>
                </div>
                <div
                  class="col-12 col-md-4 col-sm-12 mt-2 mt-sm-0 d-flex align-items-center size_paginator justify-content-end"
                >
                  <div style="width: 65%; text-align: center">
                    <mat-select
                      style="margin: auto"
                      [(ngModel)]="detection_page_size"
                      (selectionChange)="loadFrmPagesize(select.value)"
                      #select
                    >
                      <mat-option [value]="10"
                        >10 {{ "lang_per_page" | language }}</mat-option
                      >
                      <mat-option [value]="20"
                        >20 {{ "lang_per_page" | language }}</mat-option
                      >
                      <mat-option [value]="50"
                        >50 {{ "lang_per_page" | language }}</mat-option
                      >
                      <mat-option [value]="100"
                        >100 {{ "lang_per_page" | language }}</mat-option
                      >
                    </mat-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modalRefV.hide()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>

<!-- for Cancel Vacation -->

<ng-template #cancel>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_cancel_vacation" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefH.hide()"
      >
        &times;
      </button>
    </div>
    <div class="modal-body">
      <fieldset class="fieldset">
        <legend>{{ "lang_add_note" | language }}</legend>
        <div class="mbrl15">
          <textarea
            class="form-control"
            name="note"
            id=""
            cols="45"
            rows="3"
            [(ngModel)]="vacationNote"
          ></textarea>
        </div>
      </fieldset>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer d-flex justify-content-between align-items-center">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modalRefH.hide()"
      >
        {{ "lang_close" | language }}
      </button>
      <button
        type="button"
        style="max-width: 100px"
        (click)="cancelVacation()"
        class="albutton mnabrbutton mat-accent"
        mat-button
      >
        {{ "lang_confirm" | language }}
      </button>
    </div>
  </div>
</ng-template>
