import { NgModule } from "@angular/core";
import { ProjectsRoutingModule } from "./projects-routing.module";
import { ProjectsListComponent } from "./projects-list/projects-list.component";
import { ProjectsCreateComponent } from "./projects-create/projects-create.component";
import { ProjectsEditComponent } from "./projects-edit/projects-edit.component";
import { ProjectsViewComponent } from "./projects-view/projects-view.component";
import { SharedModule } from "@shared/shared.module";
import { environment } from "@env/environment";
import { AgmCoreModule } from "@agm/core";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { QuotationsListsComponent } from "./services/supervision/quotations-lists/quotations-lists.component";
import { SupervisionListsComponent } from "./services/supervision/supervision-lists/supervision-lists.component";
import { SupervisionAlertsComponent } from "./services/supervision/supervision-alerts/supervision-alerts.component";
import { SupervisionBillQuantitiesComponent } from "./services/supervision/supervision-bill-quantities/supervision-bill-quantities.component";
import { SupervisionContactsComponent } from "./services/supervision/supervision-contacts/supervision-contacts.component";
import { SupervisionContractorPaymentsComponent } from "./services/supervision/supervision-contractor-payments/supervision-contractor-payments.component";
import { SupervisionContractorsComponent } from "./services/supervision/supervision-contractors/supervision-contractors.component";
import { SupervisionDashboardComponent } from "./services/supervision/supervision-dashboard/supervision-dashboard.component";
import { SupervisionDocumentsComponent } from "./services/supervision/supervision-documents/supervision-documents.component";
import { SupervisionDrawingsComponent } from "./services/supervision/supervision-drawings/supervision-drawings.component";
import { SupervisionIPhasesComponent } from "./services/supervision/supervision-i-phases/supervision-i-phases.component";
import { SupervisionMobilizationsComponent } from "./services/supervision/supervision-mobilizations/supervision-mobilizations.component";
import { SupervisionProjectCompletionComponent } from "./services/supervision/supervision-project-completion/supervision-project-completion.component";
import { SupervisionReportsComponent } from "./services/supervision/supervision-reports/supervision-reports.component";
import { SupervisionSchedulesComponent } from "./services/supervision/supervision-schedules/supervision-schedules.component";
import { SupervisionTeamsComponent } from "./services/supervision/supervision-teams/supervision-teams.component";
import { SupervisionTestProofsComponent } from "./services/supervision/supervision-test-proofs/supervision-test-proofs.component";
import { PhasesAddComponent } from "./services/supervision/supervision-i-phases/phases-add/phases-add.component";
import { PhasesEditComponent } from "./services/supervision/supervision-i-phases/phases-edit/phases-edit.component";
import { TreeModule } from "@circlon/angular-tree-component";
import { SiteHandleComponent } from "./services/supervision/supervision-mobilizations/site-handle/site-handle.component";
import { MobilizationContractComponent } from "./services/supervision/supervision-mobilizations/mobilization-contract/mobilization-contract.component";
import { SupervisionHistoryComponent } from "./services/supervision/supervision-history/supervision-history.component";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { MainContractorsComponent } from "./services/supervision/supervision-contractors/main-contractors/main-contractors.component";
import { SubContractorsComponent } from "./services/supervision/supervision-contractors/sub-contractors/sub-contractors.component";
import { ContractorUsersComponent } from "./services/supervision/supervision-contractors/contractor-users/contractor-users.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { SettingsComponent } from "./settings/settings.component";
import { CollectionComponent } from "./settings/collection/collection.component";
import { CollectionDetailsComponent } from "./settings/collection-details/collection-details.component";
import { CdetailsAddComponent } from "./settings/collection-details/cdetails-add/cdetails-add.component";
import { CdetailsEditComponent } from "./settings/collection-details/cdetails-edit/cdetails-edit.component";
import { FormationAddComponent } from "./settings/formation/formation-add/formation-add.component";
import { FormationEditComponent } from "./settings/formation/formation-edit/formation-edit.component";
import { FormationViewComponent } from "./settings/formation/formation-view/formation-view.component";
import { FormationListsComponent } from "./settings/formation/formation-lists/formation-lists.component";
import { Group2Component } from "./settings/formation/dynamic/group2/group2.component";
import { PlatformsComponent } from "./settings/formation/dynamic/platforms/platforms.component";
import { TypesComponent } from "./settings/formation/dynamic/types/types.component";
import { WorklevelsComponent } from "./settings/worklevels/worklevels.component";
import { WunitsComponent } from "./settings/wunits/wunits.component";
import { WtypesComponent } from "./settings/wtypes/wtypes.component";
import { Wgroup1Component } from "./settings/wgroup1/wgroup1.component";
import { Wgroup2Component } from "./settings/wgroup2/wgroup2.component";
import { WplatformsComponent } from "./settings/wplatforms/wplatforms.component";
import { AppDateAdapter, APP_DATE_FORMATS } from "app/custom-material-config";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { ConsultantSettingsComponent } from "./settings/consultant-settings/consultant-settings.component";
import { CaddEditComponent } from "./settings/consultant-settings/cadd-edit/cadd-edit.component";
import { MfilesComponent } from "./settings/formation/dynamic/platforms/mfiles/mfiles.component";
import { ProjectWorkareaFormsComponent } from "./settings/project-workarea-forms/project-workarea-forms.component";
import { PermissionMentionsComponent } from "./settings/permission-mentions/permission-mentions.component";
import { SupervisionMainDashboardComponent } from "./services/supervision/supervision-main-dashboard/supervision-main-dashboard.component";
import { SupervisionCustomersComponent } from "./services/supervision/supervision-customers/supervision-customers.component";
import { SupervisionMyTransctionsComponent } from "./services/supervision/supervision-my-transctions/supervision-my-transctions.component";
import { PlatformDetailsComponent } from "./settings/formation/dynamic/platforms/platform-details/platform-details.component";
import { UplaodsHistoryComponent } from "./settings/formation/dynamic/types/uploads-history/uploads-history.component";
import { MatStepperModule } from "@angular/material/stepper";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SupervisionTestReportWorkareaComponent } from "./services/supervision/supervision-test-report-workarea/supervision-test-report-workarea.component";
import { TRWDialogComponent } from "./services/supervision/supervision-test-report-workarea/trw-dialog/trw-dialog.component";
import { IphasesDialogComponent } from "./services/supervision/supervision-i-phases/iphases-dialog/iphases-dialog.component";
import { TrwViewDialogComponent } from "./services/supervision/supervision-test-report-workarea/trw-view-dialog/trw-view-dialog.component";
import { TrwHistoryComponent } from "./services/supervision/supervision-test-report-workarea/trw-history/trw-history.component";
import { MatChipsModule } from "@angular/material/chips";
import { TemplateDialogComponent } from "./services/supervision/supervision-my-transctions/template-dialog/template-dialog.component";
import { ZoneDialogComponent } from "./services/supervision/supervision-my-transctions/zone-dialog/zone-dialog.component";
import { CompeletionRateFiltersComponent } from "./services/supervision/supervision-main-dashboard/compeletion-rate-filters/compeletion-rate-filters.component";
import { PriceFiltersComponent } from "./services/supervision/supervision-main-dashboard/price-filters/price-filters.component";
import { CommunicationComponent } from "./services/supervision/communication/communication.component";
import { CompeletionFiltersComponent } from "./services/supervision/supervision-i-phases/compeletion-filters/compeletion-filters.component";
import { AddProjectComponent } from "./services/design/add-project/add-project.component";
import { DesignListsComponent } from "./services/design/design-lists/design-lists.component";
import { DesignMainDashboardComponent } from "./services/design/design-main-dashboard/design-main-dashboard.component";
import { DesignWorkareaComponent } from "./services/design/design-workarea/design-workarea.component";
import { DesignContractComponent } from "./services/design/design-contract/design-contract.component";
import { DesignFinanceComponent } from "./services/design/design-finance/design-finance.component";
import { AddPaymentComponent } from "./services/design/design-finance/add-payment/add-payment.component";
import { DesignStagesComponent } from "./services/design/design-stages/design-stages.component";
import { CreateManualSvwComponent } from "./services/supervision/create-manual-svw/create-manual-svw.component";
import { SnaglistComponent } from "./settings/snaglist/snaglist.component";
import { SnaglistAddComponent } from "./settings/snaglist/snaglist-add/snaglist-add.component";
import { SnaglistEditComponent } from "./settings/snaglist/snaglist-edit/snaglist-edit.component";
import { SnagExcelHistoryComponent } from "./settings/snaglist/snag-excel-history/snag-excel-history.component";
import { SnaglistGroupsComponent } from "./services/supervision/supervision-project-completion/snaglist-groups/snaglist-groups.component";
import { SnagGroupsAddEditComponent } from "./services/supervision/supervision-project-completion/snaglist-groups/snag-groups-add-edit/snag-groups-add-edit.component";
import { InitialDeliveryComponent } from "./services/supervision/supervision-project-completion/initial-delivery/initial-delivery.component";
import { SnagNoteOperationsComponent } from "./services/supervision/supervision-project-completion/snag-note-operations/snag-note-operations.component";
import { InitialDeliveryPlatformComponent } from "./services/supervision/supervision-project-completion/initial-delivery/initial-delivery-platform/initial-delivery-platform.component";
import { InitialDeliveryListComponent } from "./services/supervision/supervision-project-completion/initial-delivery/initial-delivery-list/initial-delivery-list.component";
import { InitialDeliveryPlatformAddComponent } from "./services/supervision/supervision-project-completion/initial-delivery/initial-delivery-platform/initial-delivery-platform-add/initial-delivery-platform-add.component";
import { InitialDeliveryListAddComponent } from "./services/supervision/supervision-project-completion/initial-delivery/initial-delivery-list/initial-delivery-list-add/initial-delivery-list-add.component";
import { MatRadioModule } from "@angular/material/radio";
import { SnagNoteAddComponent } from "./services/supervision/supervision-project-completion/snag-note-operations/snag-note-add/snag-note-add.component";
import { SnagNoteConfirmModalComponent } from "./services/supervision/supervision-project-completion/snag-note-operations/snag-note-confirm-modal/snag-note-confirm-modal.component";
import { DesignTeamsComponent } from "./services/design/design-teams/design-teams.component";
import { TeamsCreateUpdateComponent } from "./services/design/design-teams/teams-create-update/teams-create-update.component";
import { AddUpdateContractComponent } from "./services/design/design-contract/add-update-contract/add-update-contract.component";
import { DesignSettingsComponent } from "./settings/design-settings/design-settings.component";
import { DSGroupsComponent } from "./settings/design-settings/ds-groups/ds-groups.component";
import { ContractAttachmentsComponent } from "./services/design/design-contract/contract-attachments/contract-attachments.component";
import { AddContractAttachmentsComponent } from "./services/design/design-contract/contract-attachments/add-contract-attachments/add-contract-attachments.component";
import { AddEditGroupComponent } from "./settings/design-settings/ds-groups/add-edit-group/add-edit-group.component";
import { SubTasksComponent } from "./settings/design-settings/sub-tasks/sub-tasks.component";
import { DsViewGroupComponent } from "./settings/design-settings/ds-groups/ds-view-group/ds-view-group.component";
import { DsAddGroupUserComponent } from "./settings/design-settings/ds-groups/ds-add-group-user/ds-add-group-user.component";
import { AddEditSubtaskComponent } from "./settings/design-settings/sub-tasks/add-edit-subtask/add-edit-subtask.component";
import { DsConstantsComponent } from "./settings/design-settings/ds-constants/ds-constants.component";
import { AddUpdateConstantComponent } from "./settings/design-settings/ds-constants/add-update-constant/add-update-constant.component";
import { ContractComponentsComponent } from "./services/design/design-contract/contract-components/contract-components.component";
import { AddUpdateComponentComponent } from "./services/design/design-contract/contract-components/add-update-component/add-update-component.component";
import { AddUpdateStageComponent } from "./services/design/design-stages/add-update-stage/add-update-stage.component";
import { StagePaymentsComponent } from "./services/design/design-stages/stage-payments/stage-payments.component";
import { StageTasksComponent } from "./services/design/design-stages/stage-tasks/stage-tasks.component";
import { AddUpdatePaymentComponent } from "./services/design/design-stages/stage-payments/add-update-payment/add-update-payment.component";
import { AddUpdateTaskComponent } from "./services/design/design-stages/stage-tasks/add-update-task/add-update-task.component";
import { StageSubtasksComponent } from "./services/design/design-stages/stage-tasks/stage-subtasks/stage-subtasks.component";
import { AddUpdateSubtaskComponent } from "./services/design/design-stages/stage-tasks/stage-subtasks/add-update-subtask/add-update-subtask.component";
import { MbscModule } from "@mobiscroll/angular";
import { ContractorPaymentSettingComponent } from "./services/supervision/supervision-contractor-payments/contractor-payment-setting/contractor-payment-setting.component";
import { AddUpdateTitlesComponent } from "./services/supervision/supervision-contractor-payments/contractor-payment-setting/add-update-titles/add-update-titles.component";
import { CpSubtitlesComponent } from "./services/supervision/supervision-contractor-payments/contractor-payment-setting/cp-subtitles/cp-subtitles.component";
import { AddPlatfornLevelsComponent } from "./services/supervision/supervision-contractor-payments/contractor-payment-setting/cp-subtitles/add-platforn-levels/add-platforn-levels.component";
import { PlatformLevelsListComponent } from "./services/supervision/supervision-contractor-payments/contractor-payment-setting/cp-subtitles/platform-levels-list/platform-levels-list.component";
import { CpExcelBulkComponent } from "./services/supervision/supervision-contractor-payments/contractor-payment-setting/cp-excel-bulk/cp-excel-bulk.component";
import { DesignCommunicationComponent } from "./services/design/design-communication/design-communication.component";
import { Report1ModalComponent } from "./services/supervision/supervision-main-dashboard/report1-modal/report1-modal.component";
import { ContractTicketsComponent } from "./services/design/design-contract/contract-tickets/contract-tickets.component";
import { ContractPaymentCreateComponent } from "./services/supervision/supervision-contractor-payments/contract-payment-create/contract-payment-create.component";
import { PaymentFiltersComponent } from "./services/supervision/supervision-contractor-payments/contract-payment-create/payment-filters/payment-filters.component";
import { Report1FiltersComponent } from "./services/supervision/supervision-main-dashboard/report1-filters/report1-filters.component";
import { ConfirmCpayCreateComponent } from "./services/supervision/supervision-contractor-payments/contract-payment-create/confirm-cpay-create/confirm-cpay-create.component";
import { TopCountRequestsComponent } from "./services/supervision/supervision-main-dashboard/top-count-requests/top-count-requests.component";
import { QuotationSettingsComponent } from "./settings/quotation-settings/quotation-settings.component";
import { QuotationTypesComponent } from "./settings/quotation-settings/quotation-types/quotation-types.component";
import { QuotationPositionsComponent } from "./settings/quotation-settings/quotation-positions/quotation-positions.component";
import { QuotationConditionsComponent } from "./settings/quotation-settings/quotation-conditions/quotation-conditions.component";
import { QtAddEditComponent } from "./settings/quotation-settings/quotation-types/qt-add-edit/qt-add-edit.component";
import { QpAddEditComponent } from "./settings/quotation-settings/quotation-positions/qp-add-edit/qp-add-edit.component";
import { QcAddEditComponent } from "./settings/quotation-settings/quotation-conditions/qc-add-edit/qc-add-edit.component";
import { DepartmentReportFiltersComponent } from "./services/supervision/supervision-teams/department-report-filters/department-report-filters.component";
import { AvailableUnitsComponent } from "./services/supervision/supervision-project-completion/initial-delivery/initial-delivery-list/available-units/available-units.component";
import { ContractorPaymentDivisionTemplateComponent } from "./services/supervision/supervision-contractor-payments/contractor-payment-division-template/contractor-payment-division-template.component";
import { DesignTicketsComponent } from "./services/design/design-tickets/design-tickets.component";
import { DesignTicketsCalendarComponent } from "./services/design/design-tickets-calendar/design-tickets-calendar.component";
import { ExcelHistoryModalComponent } from "./services/supervision/supervision-contractor-payments/excel-history-modal/excel-history-modal.component";
import { TriggerDialogComponent } from "./settings/formation/formation-lists/trigger-dialog/trigger-dialog.component";
import { SupervisionOwnerComponent } from "./services/supervision/supervision-owner/supervision-owner.component";
import { BankPaymentsComponent } from "./services/supervision/supervision-owner/bank-payments/bank-payments.component";
import { PaymentsComponent } from "./services/supervision/supervision-owner/bank-payments/payments/payments.component";
import { BPaymentsComponent } from "./settings/bank-payments/b-payments.component";
import { BpUnitsComponent } from "./services/supervision/supervision-owner/bank-payments/bp-units/bp-units.component";
import { SupervisionRiskManagementComponent } from "./services/supervision/supervision-risk-management/supervision-risk-management.component";
import { RisksListComponent } from "./services/supervision/supervision-risk-management/risks-list/risks-list.component";
import { IssuesListComponent } from "./services/supervision/supervision-risk-management/issues-list/issues-list.component";
import { SupervisionWafiReportComponent } from "./services/supervision/supervision-wafi-report/supervision-wafi-report.component";
import { WafiSettingsComponent } from "./services/supervision/supervision-wafi-report/wafi-settings/wafi-settings.component";
import { WafiManpowerComponent } from "./services/supervision/supervision-wafi-report/wafi-settings/wafi-manpower/wafi-manpower.component";
import { WafiExtraDataComponent } from "./services/supervision/supervision-wafi-report/wafi-settings/wafi-extra-data/wafi-extra-data.component";
import { WafiCustomDivisionsComponent } from "./services/supervision/supervision-wafi-report/wafi-settings/custom-divisions/wafi-custom-divisions.component";
import { WafiListComponent } from "./services/supervision/supervision-wafi-report/wafi-list/wafi-list.component";
import { WafiViewComponent } from "./services/supervision/supervision-wafi-report/wafi-view/wafi-view.component";
import { CKEditorModule } from "ckeditor4-angular";
import { WafiCreateComponent } from "./wafi-create/wafi-create.component";
import { MainReportsComponent } from "./wafi-create/main-reports/main-reports.component";
import { WafiPerformanceIndicesComponent } from "./wafi-create/wafi-performance-indices/wafi-performance-indices.component";
import { QualityManagementComponent } from "./wafi-create/quality-management/quality-management.component";
import { ConstructionManagementComponent } from "./wafi-create/construction-management/construction-management.component";
import { ProjectZonesComponent } from "./wafi-create/project-zones/project-zones.component";
import { RiskRegisterComponent } from "./wafi-create/risk-register/risk-register.component";
import { IssueLogComponent } from "./wafi-create/issue-log/issue-log.component";
import { HseManagementComponent } from "./wafi-create/hse-management/hse-management.component";
import { InfrastructureWorksComponent } from "./wafi-create/infrastructure-works/infrastructure-works.component";
import { TimelineDetailsComponent } from "./services/supervision/supervision-main-dashboard/timeline-details/timeline-details.component";
import { TransactionsModule } from "../transactions/transactions.module";

const googleMapsParams = {
  apiKey: environment.GOOGLE_MAPS_API_KEY,
  libraries: ["places"],
  language: "en",
};
const COMPONENTS = [
  UplaodsHistoryComponent,
  ProjectsListComponent,
  ProjectsCreateComponent,
  ProjectsEditComponent,
  ProjectsViewComponent,
  QuotationsListsComponent,
  SupervisionListsComponent,
  SupervisionAlertsComponent,
  SupervisionBillQuantitiesComponent,
  SupervisionContactsComponent,
  SupervisionContractorPaymentsComponent,
  SupervisionContractorsComponent,
  SupervisionDashboardComponent,
  SupervisionDocumentsComponent,
  SupervisionDrawingsComponent,
  SupervisionIPhasesComponent,
  SupervisionMobilizationsComponent,
  SupervisionProjectCompletionComponent,
  SupervisionReportsComponent,
  SupervisionSchedulesComponent,
  SupervisionTeamsComponent,
  SupervisionTestProofsComponent,
  PhasesAddComponent,
  PhasesEditComponent,
  SiteHandleComponent,
  MobilizationContractComponent,
  SupervisionHistoryComponent,
  MainContractorsComponent,
  SubContractorsComponent,
  ContractorUsersComponent,
  SettingsComponent,
  CollectionComponent,
  CollectionDetailsComponent,
  CdetailsAddComponent,
  CdetailsEditComponent,
  FormationAddComponent,
  FormationEditComponent,
  FormationViewComponent,
  FormationListsComponent,
  Group2Component,
  PlatformsComponent,
  TypesComponent,
  WorklevelsComponent,
  WunitsComponent,
  WtypesComponent,
  Wgroup1Component,
  Wgroup2Component,
  WplatformsComponent,
  ConsultantSettingsComponent,
  CaddEditComponent,
  MfilesComponent,
  ProjectWorkareaFormsComponent,
  PermissionMentionsComponent,
  SupervisionMainDashboardComponent,
  SupervisionCustomersComponent,
];
const COMPONENTS_DYNAMIC = [];

@NgModule({
  imports: [
    SharedModule,
    ProjectsRoutingModule,
    AgmCoreModule.forRoot(googleMapsParams),
    MatGoogleMapsAutocompleteModule,
    ModalModule.forRoot(),
    AngularMultiSelectModule,
    TreeModule,
    NgbPaginationModule,
    MatStepperModule,
    NgxSkeletonLoaderModule,
    MatChipsModule,
    MatRadioModule,
    MbscModule,
    CKEditorModule,
    TransactionsModule
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_DYNAMIC,
    MfilesComponent,
    ProjectWorkareaFormsComponent,
    PermissionMentionsComponent,
    SupervisionMainDashboardComponent,
    SupervisionMyTransctionsComponent,
    PlatformDetailsComponent,
    SupervisionTestReportWorkareaComponent,
    TRWDialogComponent,
    IphasesDialogComponent,
    TrwViewDialogComponent,
    TrwHistoryComponent,
    TemplateDialogComponent,
    ZoneDialogComponent,
    CompeletionRateFiltersComponent,
    PriceFiltersComponent,
    CommunicationComponent,
    CompeletionFiltersComponent,
    AddProjectComponent,
    DesignListsComponent,
    DesignMainDashboardComponent,
    DesignWorkareaComponent,
    DesignContractComponent,
    DesignFinanceComponent,
    AddPaymentComponent,
    DesignStagesComponent,
    CreateManualSvwComponent,
    SnaglistComponent,
    SnaglistAddComponent,
    SnaglistEditComponent,
    SnagExcelHistoryComponent,
    SnaglistGroupsComponent,
    SnagGroupsAddEditComponent,
    InitialDeliveryComponent,
    SnagNoteOperationsComponent,
    InitialDeliveryPlatformComponent,
    InitialDeliveryListComponent,
    InitialDeliveryPlatformAddComponent,
    InitialDeliveryListAddComponent,
    SnagNoteAddComponent,
    SnagNoteConfirmModalComponent,
    DesignTeamsComponent,
    TeamsCreateUpdateComponent,
    AddUpdateContractComponent,
    DesignSettingsComponent,
    DSGroupsComponent,
    ContractAttachmentsComponent,
    AddContractAttachmentsComponent,
    AddEditGroupComponent,
    SubTasksComponent,
    DsViewGroupComponent,
    DsAddGroupUserComponent,
    AddEditSubtaskComponent,
    DsConstantsComponent,
    AddUpdateConstantComponent,
    ContractComponentsComponent,
    AddUpdateComponentComponent,
    AddUpdateStageComponent,
    StagePaymentsComponent,
    StageTasksComponent,
    AddUpdatePaymentComponent,
    AddUpdateTaskComponent,
    StageSubtasksComponent,
    AddUpdateSubtaskComponent,
    ContractorPaymentSettingComponent,
    AddUpdateTitlesComponent,
    CpSubtitlesComponent,
    AddPlatfornLevelsComponent,
    PlatformLevelsListComponent,
    CpExcelBulkComponent,
    DesignCommunicationComponent,
    Report1ModalComponent,
    TimelineDetailsComponent,
    ContractTicketsComponent,
    ContractPaymentCreateComponent,
    PaymentFiltersComponent,
    Report1FiltersComponent,
    ConfirmCpayCreateComponent,
    TopCountRequestsComponent,
    QuotationSettingsComponent,
    QuotationTypesComponent,
    QuotationPositionsComponent,
    QuotationConditionsComponent,
    QtAddEditComponent,
    QpAddEditComponent,
    QcAddEditComponent,
    DepartmentReportFiltersComponent,
    AvailableUnitsComponent,
    ContractorPaymentDivisionTemplateComponent,
    DesignTicketsComponent,
    DesignTicketsCalendarComponent,
    ExcelHistoryModalComponent,
    TriggerDialogComponent,
    SupervisionOwnerComponent,
    BankPaymentsComponent,
    PaymentsComponent,
    BPaymentsComponent,
    BpUnitsComponent,
    SupervisionRiskManagementComponent,
    RisksListComponent,
    IssuesListComponent,
    SupervisionWafiReportComponent,
    WafiSettingsComponent,
    WafiManpowerComponent,
    WafiExtraDataComponent,
    WafiCustomDivisionsComponent,
    WafiListComponent,
    WafiViewComponent,
    WafiCreateComponent,
    MainReportsComponent,
    WafiPerformanceIndicesComponent,
    QualityManagementComponent,
    ConstructionManagementComponent,
    ProjectZonesComponent,
    RiskRegisterComponent,
    IssueLogComponent,
    HseManagementComponent,
    InfrastructureWorksComponent,
  ],
  entryComponents: COMPONENTS_DYNAMIC,
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class ProjectsModule {}
