import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  collection_id: string,
  collection_title_en: string,
  collection_title_ar: string,
  writer: string,
}
@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {
  modalRefA: BsModalRef;
  form:FormGroup;
  tabledatas: list[];
  collectionSize;
  @Output() actionEvent       = new EventEmitter<any>();
  page                        = 1;
  pageSize                    = 10;
  searchKey                   = "";
  lang_key                    = localStorage.getItem('lang_key') || 'en';
  data:any                    = [];
  admin                       = JSON.parse(this.ls.getItem('is_admin'));
  tabledata:any               = [];
  nodata: string = this.lang.transform('lang_loading');
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public spinner:PreloaderService, public alert:AlertService, public modalService: BsModalService, public dialog:MatDialog, public fb:FormBuilder) { }
  ngOnInit(): void {
    this.spinner.show();
    this.list_of_datas();
    this.form = new FormGroup({
      collection_id               : new FormControl(''),
      collection_title_en         : new FormControl('', [Validators.required]),
      collection_title_ar         : new FormControl('', [Validators.required]),
    });
  }
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public list_of_datas() {
    let param = new FormData();
    param.append('search_key',  this.searchKey || '');
    param.append('request',     'TABLE');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpcollection').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.data = res;
        this.tabledatas = res.records;
        this.collectionSize = res.records.length;
        this.build_table_data();
      } else {
        this.data = [];
        this.nodata = res.error;
      }
    }, error=> {
      this.spinner.hide();
      this.data = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public submit() {
    this.spinner.show();
    if(this.form.get('collection_id').value) {
      this.ds.putActionByUrl(this.ds.formData2string(this.ds.json2formdata(this.form.value)), 'upcollection/'+this.form.get('collection_id').value).subscribe(res=>{
        this.spinner.hide();
        if(res.status) {
          this.actionEvent.emit('');
          this.form.reset();
          this.list_of_datas();
          setTimeout(() => {
            this.modalRefA.hide();
            this.alert.clear();
          }, 1000);
          this.alert.success(res.msg);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    } else {
      this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'cpcollection').subscribe(res=>{
        this.spinner.hide();
        if(res.status) {
          this.actionEvent.emit('');
          this.form.reset();
          this.list_of_datas();
          setTimeout(() => {
            this.modalRefA.hide();
            this.alert.clear();
          }, 1000);
          this.alert.success(res.msg);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    }
  }
  public checkAllItemsColl(event) {
    if (event.target.checked) {
      $(".appcollections .eachItemColl").prop("checked", true);
      $(".appcollections .makeback").addClass("bground");
      $(".appcollections .deleteIconDiv").show();
    } else {
      $(".appcollections .eachItemColl").prop("checked", false);
      $(".appcollections .makeback").removeClass("bground");
      $(".appcollections .deleteIconDiv").hide();
    }
  }
  public checkEachItemColl(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appcollections .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appcollections .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appcollections .eachItemColl:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appcollections .eachItemColl").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appcollections .deleteIconDiv").show();
    } else {
      $(".appcollections .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appcollections #allItemsColl").prop("checked", true);
    } else {
      $(".appcollections #allItemsColl").prop("checked", false);
    }
  }
  public deleteBulkDataColl() {
    const checkedtotal = [];
    $(".appcollections .eachItemColl:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'dpcollection').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.actionEvent.emit('');
                this.list_of_datas();
                $(".appcollections #allItemsColl").prop("checked", false);
                $(".appcollections .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
