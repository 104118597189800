import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-trw-view-dialog",
	templateUrl: "./trw-view-dialog.component.html",
	styleUrls: ["./trw-view-dialog.component.scss"],
})
export class TrwViewDialogComponent implements OnInit {
	projects_profile_id = "";
	projects_work_area_id = "";
	action_auto_id = "";
	tsr_report_id = "";
	historyViewData: any;
	list_type = "soil";
	constructor(
		private dialogRef: MatDialogRef<TrwViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private route: ActivatedRoute,
		public dialog: MatDialog,
		private spinner: PreloaderService,
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {
		this.projects_profile_id = data?.projects_profile_id;
		this.projects_work_area_id = data?.projects_work_area_id;
		this.action_auto_id = data?.action_auto_id;
		this.tsr_report_id = data?.tsr_report_id;
		this.list_type = data?.setting_type;
		this.getHistoryView();
	}

	ngOnInit(): void {}

	getHistoryView() {
		this.spinner.show();
		let url = `ptesting/get_action_reports_and_files/${this.projects_profile_id}/${this.projects_work_area_id}/${this.action_auto_id}/${this.tsr_report_id}`;

		this.ds.getActionByUrl([], url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.historyViewData = res;
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}

	previewPDF(path) {
		this.spinner.show();
		this.ds.get(path).subscribe(
			(res) => {
				this.spinner.hide();
				let url = "data:" + res["content-type"] + ";base64," + res.base64;
				let iframe =
					"<iframe width='100%' height='100%' src='" + url + "'></iframe>";
				let x = window.open();
				x.document.open();
				x.document.write(iframe);
				x.document.close();
				x.document.body.style.margin = "0px";
			},
			(err) => {
				this.sweetAlert.errorMessage(err?.error?.error);
				this.spinner.hide();
			}
		);
	}

	closeModal() {
		this.dialogRef.close();
	}
}
