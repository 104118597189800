import { Component, OnInit } from "@angular/core";
import {
	trigger,
	transition,
	query,
	style,
	stagger,
	animate,
	state,
} from "@angular/animations";
import { PreloaderService } from "@core";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { DataService } from "@core/bootstrap/data.service";
import { HttpParams } from "@angular/common/http";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { AddShiftNameComponent } from "../add-shift/add-shift-name/add-shift-name.component";
declare var $: any;

@Component({
	selector: "app-all-shifts",
	templateUrl: "./all-shifts.component.html",
	styleUrls: ["./all-shifts.component.scss"],
	animations: [
		trigger("fadeIn", [
			state(
				"void",
				style({
					opacity: 0,
				})
			),
			transition("void <=> *", animate(1000)),
		]),
	],
})
export class AllShiftsComponent implements OnInit {
	listData: any = [];
	loading: boolean = true;
	nodata: string = this.lang.transform("lang_loading");

	page_no: any = localStorage.getItem("H_R_S_P_N") || 1;
	pagesize: any = localStorage.getItem("H_R_S_P_S") || "10";
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		public dialog: MatDialog,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		private sweetAlert: SweetAlertService
	) {}

	ngOnInit(): void {
		this.module_lists();
	}

	public module_lists(page = 1) {
		this.listData = [];
		this.page_no = page;
		this.pagesize = this.pagesize;
		this.get_form_lists();
	}

	public loadPageFrm(page) {
		this.page_no = page;
		this.pagesize = this.pagesize;
		localStorage.setItem("H_R_A_P_N", this.page_no);
		this.get_form_lists();
	}
	public loadFrmPagesize(size) {
		this.page_no = 1;
		this.pagesize = size;
		localStorage.setItem("H_R_A_P_S", this.pagesize);
		this.get_form_lists();
	}

	openModal(data?) {
		const dialogRef = this.dialog.open(AddShiftNameComponent, {
			disableClose: true,
			width: "600px",
			data: data,
		});

		dialogRef.afterClosed().subscribe((data) => {
			if (data?.saved) {
				this.ngOnInit();
			}
		});
	}
	public get_form_lists() {
		this.listData = [];
		this.loading = true;

		let param = new FormData();
		param.append("page_no", this.page_no);
		param.append("page_size", this.pagesize);

		this.ds
			.getActionByUrl(
				this.ds.formData2string(param),
				"at/get_shifts/" + this.page_no + "/" + this.pagesize
			)
			.subscribe(
				(res) => {
					this.loading = false;
					if (res.status) {
						this.listData = res;
					} else {
						this.listData = [];
						this.nodata = res.error;
					}
				},
				(error) => {
					this.loading = false;
					this.listData = [];
					this.nodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}

	public checkAllItemsUsr(event) {
		if (event.target.checked) {
			$(".groups_data .eachItemUsr").prop("checked", true);
			$(".groups_data .makeback").addClass("bground");
			$(".groups_data .deleteIconDiv").show();
		} else {
			$(".groups_data .eachItemUsr").prop("checked", false);
			$(".groups_data .makeback").removeClass("bground");
			$(".groups_data .deleteIconDiv").hide();
		}
	}
	public checkEachItemUsr(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".groups_data .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".groups_data .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".groups_data .eachItemUsr:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".groups_data .eachItemUsr").each(function () {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".groups_data .deleteIconDiv").show();
		} else {
			$(".groups_data .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".groups_data #allItemsUsr").prop("checked", true);
		} else {
			$(".groups_data #allItemsUsr").prop("checked", false);
		}
	}

	public deleteBulkData() {
		const checkedtotal = [];
		let formData = new FormData();
		$(".groups_data .eachItemUsr:checked").each(function () {
			checkedtotal.push({ "key_ids[]": $(this).val() });
			formData.append("key_ids[]", $(this).val());
		});

		if (checkedtotal.length > 0) {
			this.sweetAlert
				.confirmMessage("Are you sure?", "Confirm")
				.then((result) => {
					if (result.isConfirmed) {
						this.spinner.show();
						this.ds.post("at/delete_shifts", formData).subscribe(
							(data) => {
								this.spinner.hide();
								if (data.status) {
									this.get_form_lists();
									$(".groups_data #allItemsUsr").prop("checked", false);
									$(".groups_data .deleteIconDiv").hide();
									this.ds.dialogf("", data.msg);
								} else {
									this.ds.dialogf("", data.error);
								}
							},
							(error) => {
								this.ds.dialogf(
									"",
									error && error.error && error.error.error
										? error.error.error
										: this.lang.transform("lang_internal_server_error")
								);
								this.spinner.hide();
							}
						);
					}
				});
		} else {
			this.ds.dialogf();
		}
	}

	public deleteSingleData(id) {
		let formData = new FormData();
		formData.append("key_ids[]", id);
		this.sweetAlert
			.confirmMessage("Are you sure?", "Confirm")
			.then((result) => {
				if (result.isConfirmed) {
					this.spinner.show();
					this.ds.post("at/delete_shifts", formData).subscribe(
						(data) => {
							this.spinner.hide();
							if (data.status) {
								this.get_form_lists();

								this.ds.dialogf("", data.msg);
							} else {
								this.ds.dialogf("", data.error);
							}
						},
						(error) => {
							this.ds.dialogf(
								"",
								error && error.error && error.error.error
									? error.error.error
									: this.lang.transform("lang_internal_server_error")
							);
							this.spinner.hide();
						}
					);
				}
			});
	}
}
