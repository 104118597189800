import { Component, OnInit, TemplateRef } from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from "@angular/animations";
import { PreloaderService } from "@core";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { DataService } from "@core/bootstrap/data.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MatDialog } from "@angular/material/dialog";
import { AttendanceUploadComponent } from "./attendance-upload/attendance-upload.component";
import { Subject } from "rxjs";
import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { environment } from "@env/environment";
declare var $: any;
import * as _moment from "moment";
import { default as _rollupMoment, Moment } from "moment";
import { ViolationDeductionComponent } from "./violation-deduction/violation-deduction.component";

const moment = _rollupMoment || _moment;
@Component({
  selector: "app-attendance",
  templateUrl: "./attendance.component.html",
  styleUrls: ["./attendance.component.scss"],
  animations: [
    trigger("fadeIn", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      transition("void <=> *", animate(1000)),
    ]),
  ],
})
export class AttendanceComponent implements OnInit, OnDestroy {
  date = new Date();
  refresh = false;
  month: any;
  year: any;
  addmonth(month: string) {
    this.month = month;
  }
  addyear(year: string) {
    this.year = year;
  }
  restdate() {
    if (this.calendarToggle == true) {
      this.month = this.date.getMonth() + 1;
      this.year = this.date.getFullYear();
    }
  }
  listData: any = [];
  loading: boolean = true;
  nodata: string = this.lang.transform("lang_loading");
  users: any = [];
  isFile: boolean = false;
  filename: any = this.lang.transform("lang_select_files");
  page_no: any = localStorage.getItem("H_R_A_P_N") || 1;
  pagesize: any = localStorage.getItem("H_R_A_P_S") || "10";
  modalRef: BsModalRef;
  modalRefV: BsModalRef;
  pageC = 1;
  sizeC = 10;
  updateShiftUser: FormArray;
  shfitUserFG: FormGroup;
  exportTime = { hour: 7, minute: 15, meriden: "PM", format: 24 };
  time = { hour: 13, minute: 30 };
  lang_key =
    localStorage.getItem("lang_key") == null
      ? "en"
      : localStorage.getItem("lang_key");
  empStatusFG: FormGroup;
  statusList = [
    {
      label: this.lang.transform("lang_ok"),
      value: "ok",
    },
    {
      label: this.lang.transform("lang_not_ok"),
      value: "not_ok",
    },
    {
      label: this.lang.transform("lang_absent"),
      value: "absent",
    },
    {
      label: this.lang.transform("lang_no_settings"),
      value: "no_settings",
    },
    {
      label: this.lang.transform("lang_record_missing"),
      value: "record_missing",
    },
  ];

  reasonFromToDate: FormGroup = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  reasonsData: any;
  reasonPageNo = 1;
  reasonPageSize = "10";
  calendarToggle = true;
  reasonFilterType = "absent";

  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public dialog: MatDialog,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    private fb: FormBuilder,
    public alert: AlertService,
    private sweetAlert: SweetAlertService,
    public modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.module_lists();
    moment.locale("en");
    this.shfitUserFG = this.fb.group({
      shift_start_time: [null, Validators.required],
      shift_end_time: [null, Validators.required],
      check_in_start: [null, Validators.required],
      check_in_end: [null, Validators.required],
      check_out_start: [null, Validators.required],
      check_out_end: [null, Validators.required],
      user: [null, Validators.required],
      date: [null, Validators.required],
    });
    this.empStatusFG = this.fb.group({
      state: [null, Validators.required],
      user_id: [null, Validators.required],
      date: [null, Validators.required],
    });
    if (localStorage.getItem("attendanceCalendar") == "1") {
      this.calendarToggle = true;
    } else {
      this.calendarToggle = false;
      localStorage.setItem("attendanceCalendar", "0");
    }
    this.get_report_reasons();
  }
  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  public module_lists(page = 1) {
    this.listData = [];
    this.page_no = page;
    this.pagesize = this.pagesize;
    this.get_form_lists();
  }

  public loadPageFrm(page) {
    this.page_no = page;
    localStorage.setItem("H_R_A_P_N", this.page_no);
    this.pagesize = this.pagesize;
    this.get_form_lists();
  }
  public loadFrmPagesize(size) {
    this.page_no = 1;
    this.pagesize = size;
    localStorage.setItem("H_R_A_P_S", this.pagesize);
    this.get_form_lists();
  }

  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get_form_lists() {
    this.listData = [];
    this.loading = true;

    let param = new FormData();
    param.append("page_no", this.page_no);
    param.append("page_size", this.pagesize);
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "at/get_groups/" + this.page_no + "/" + this.pagesize
        )
        .subscribe(
          (res) => {
            this.loading = false;
            if (res.status) {
              this.listData = res;
            } else {
              this.listData = [];
              this.nodata = res.error;
            }
          },
          (error) => {
            this.loading = false;
            this.listData = [];
            this.nodata =
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error");
          }
        )
    );
  }

  public checkAllItemsUsr(event) {
    if (event.target.checked) {
      $(".groups_data .eachItemUsr").prop("checked", true);
      $(".groups_data .makeback").addClass("bground");
      $(".groups_data .deleteIconDiv").show();
    } else {
      $(".groups_data .eachItemUsr").prop("checked", false);
      $(".groups_data .makeback").removeClass("bground");
      $(".groups_data .deleteIconDiv").hide();
    }
  }
  public checkEachItemUsr(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".groups_data .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".groups_data .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".groups_data .eachItemUsr:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".groups_data .eachItemUsr").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".groups_data .deleteIconDiv").show();
    } else {
      $(".groups_data .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".groups_data #allItemsUsr").prop("checked", true);
    } else {
      $(".groups_data #allItemsUsr").prop("checked", false);
    }
  }

  public deleteBulkData() {
    const checkedtotal = [];
    let formData = new FormData();
    $(".groups_data .eachItemUsr:checked").each(function () {
      checkedtotal.push({ "key_ids[]": $(this).val() });
      formData.append("key_ids[]", $(this).val());
    });
    if (checkedtotal.length > 0) {
      this.sweetAlert
        .confirmMessage("Are you sure?", "Confirm")
        .then((result) => {
          if (result.isConfirmed) {
            this.spinner.show();
            this.subscriptions.add(
              this.ds.post("at/delete_groups", formData).subscribe(
                (data) => {
                  this.spinner.hide();
                  if (data.status) {
                    this.get_form_lists();
                    $(".groups_data #allItemsUsr").prop("checked", false);
                    $(".groups_data .deleteIconDiv").hide();
                    this.ds.dialogf("", data.msg);
                  } else {
                    this.ds.dialogf("", data.error);
                  }
                },
                (error) => {
                  this.ds.dialogf(
                    "",
                    error && error.error && error.error.error
                      ? error.error.error
                      : this.lang.transform("lang_internal_server_error")
                  );
                  this.spinner.hide();
                }
              )
            );
          }
        });
    } else {
      this.ds.dialogf();
    }
  }

  public deleteSingleData(id) {
    let formData = new FormData();
    formData.append("key_ids[]", id);

    if (id) {
      this.sweetAlert
        .confirmMessage("Are you sure?", "Confirm")
        .then((result) => {
          if (result.isConfirmed) {
            this.spinner.show();
            this.subscriptions.add(
              this.ds.post("at/delete_groups", formData).subscribe(
                (data) => {
                  this.spinner.hide();
                  if (data.status) {
                    this.get_form_lists();
                    $(".groups_data #allItemsUsr").prop("checked", false);
                    $(".groups_data .deleteIconDiv").hide();
                    this.ds.dialogf("", data.msg);
                  } else {
                    this.ds.dialogf("", data.error);
                  }
                },
                (error) => {
                  this.ds.dialogf(
                    "",
                    error && error.error && error.error.error
                      ? error.error.error
                      : this.lang.transform("lang_internal_server_error")
                  );
                  this.spinner.hide();
                }
              )
            );
          }
        });
    } else {
      this.ds.dialogf();
    }
  }

  refreshSubject: Subject<void> = new Subject<void>();
  emitEventToChild() {
    this.refreshSubject.next();
  }
  dialogRef: any;
  AttendanceDialog() {
    this.dialogRef = this.dialog.open(AttendanceUploadComponent, {
      maxWidth: "1000px",
      width: "90%",
      autoFocus: false,
      restoreFocus: false,
    });
    this.subscriptions.add(
      this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.emitEventToChild();
        }
      })
    );
  }

  //#region FormArray Update Shift
  search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append("search", key.term || "");
    param.append("lang_key", this.lang_key);
    param.append("user_type_id", "1");
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
      .subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      });
  }

  submitForm() {
    if (this.shfitUserFG?.status == "VALID") {
      this.spinner.show();
      let formData = new FormData();
      formData.append(
        "shift_start_time",
        this.shfitUserFG?.value?.shift_start_time || ""
      );
      formData.append(
        "shift_end_time",
        this.shfitUserFG?.value?.shift_end_time || ""
      );
      formData.append(
        "check_in_start",
        this.shfitUserFG?.value?.check_in_start || ""
      );
      formData.append(
        "check_in_end",
        this.shfitUserFG?.value?.check_in_end || ""
      );
      formData.append(
        "check_out_start",
        this.shfitUserFG?.value?.check_out_start || ""
      );
      formData.append(
        "check_out_end",
        this.shfitUserFG?.value?.check_out_end || ""
      );
      for (
        let index = 0;
        index < this.shfitUserFG?.value?.user?.length;
        index++
      ) {
        const element = this.shfitUserFG.value.user[index];
        formData.append(`user_id[${index}]`, element || "");
      }
      for (
        let index = 0;
        index < this.shfitUserFG?.value?.date?.length;
        index++
      ) {
        const element = this.shfitUserFG?.value?.date[index];
        formData.append(
          `date[${index}]`,
          moment(element).format("YYYY/MM/DD") || ""
        );
      }

      this.subscriptions.add(
        this.ds.post(`at/update_shift_seetings`, formData).subscribe(
          (data) => {
            if (data.status) {
              this.sweetAlert.successToast(data?.msg, 3000);
              this.shfitUserFG.reset();
              this.spinner.hide();
              this.modalRef.hide();
            } else {
              this.spinner.hide();
              this.sweetAlert.errorToast(data?.msg, 3000);
            }
          },
          (error) => {
            this.spinner.hide();
            this.sweetAlert.errorToast(
              this.lang.transform("lang_internal_server_error"),
              3000
            );
          }
        )
      );
    }
  }
  onUpdateEmployeeStatus() {
    if (this.empStatusFG.valid) {
      let formData = new FormData();
      formData.append("state", this.empStatusFG.get("state").value || "");
      formData.append("user_id", this.empStatusFG.get("user_id").value || "");
      formData.append(
        "date",
        moment(this.empStatusFG.get("date").value).format("YYYY/MM/DD") || ""
      );
      this.spinner.show();
      this.ds.post("at/update_employee_status", formData).subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.modalRef.hide();
            this.sweetAlert.successToast(res?.msg, 3000);
          } else {
            this.sweetAlert.errorToast(res?.msg, 3000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
    }
  }

  onShowCalendar(event) {
    if (event?.checked) {
      this.calendarToggle = true;
      localStorage.setItem("attendanceCalendar", "1");
    } else {
      this.calendarToggle = false;
      localStorage.setItem("attendanceCalendar", "0");
    }
  }
  previewAttendance() {
    this.ds
      .getActionByUrl([], "at/get_employees_attendance_logs")
      .subscribe((res) => {
        this.ds.downloadFile(res);
      });
  }
  public uploadFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.isFile = true;
      this.Add_attendance_logs(fileid);
    } else {
      this.isFile = false;
      this.filename = this.lang.transform("lang_select_files");
    }
  }
  public Add_attendance_logs(data_file) {
    let formData = new FormData();
    formData.append("data_file", data_file);
    this.spinner.show();
    this.subscriptions.add(
      this.ds.post("at/post_employees_attendance_logs", formData).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.sweetAlert.successToast(data?.msg, 3000);
          } else {
            this.sweetAlert.errorToast(data?.msg, 3000);
          }
        },
        (error) => {
          this.sweetAlert.errorToast(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error"),
            3000
          );
          this.spinner.hide();
        }
      )
    );
  }
  public get_report_reasons() {
    let param = new FormData();
    param.append("filter_type", this.reasonFilterType || "");
    param.append(
      "from_date",
      this.reasonFromToDate?.get("start")?.value
        ? moment(this.reasonFromToDate?.get("start")?.value).format(
            "YYYY/MM/DD"
          )
        : ""
    );
    param.append(
      "to_date",
      this.reasonFromToDate?.get("end")?.value
        ? moment(this.reasonFromToDate?.get("end")?.value).format("YYYY/MM/DD")
        : ""
    );
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "at/get_report_reasons/" +
            this.reasonPageNo +
            "/" +
            this.reasonPageSize
        )
        .subscribe(
          (res) => {
            this.loading = false;
            this.reasonsData = res;
          },
          (error) => {
            this.nodata =
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error");
          }
        )
    );
  }

  onResetReasonFilters() {
    this.reasonFromToDate.setValue({
      start: null,
      end: null,
    });
    this.reasonFilterType = "absent";
    this.get_report_reasons();
  }

  onApproveRejectReason(data, status: string) {
    const requestBody = new FormData();
    requestBody.append("employee_number", data?.employee_number || "");
    requestBody.append("date", data?.violation_date || "");
    requestBody.append("action", status || "");
    this.subscriptions.add(
      this.ds.post("at/hr_action_violation", requestBody).subscribe((res) => {
        if (res?.status) {
          this.sweetAlert.successToast(res?.msg, 3000);
        } else {
          this.sweetAlert.errorToast(res?.error, 3000);
        }
        this.get_report_reasons();
      })
    );
  }
  openChartData() {
    const dialogRef = this.dialog.open(ViolationDeductionComponent, {
      width: "100%",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
