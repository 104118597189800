<breadcrumb></breadcrumb>
<div class="themesection" *ngIf="viewdata_status">
  <section class="panel-expansion matborderno">
    <div class="card-footer footertop padb0">
      <div class="row">
        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
          <button type="button" class="albutton" routerLink="/accounts/jlist">
            {{ "lang_goto_list" | language }}
          </button>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
          <button
            *ngIf="JrnData.permits.add"
            type="button"
            class="albutton"
            (click)="
              this.router.navigateByUrl(
                '/accounts/jcreate/' + branch_id + '/' + finance_id
              )
            "
          >
            {{ "lang_add" | language }}
          </button>
        </div>
        <div
          class="col-md-2 col-sm-2 col-xs-12 mb-10"
          *ngIf="
            JrnData.permits.edit &&
            JrnData.financial_year.finance_id == viewdata.finance_id
          "
        >
          <button
            type="button"
            class="albutton"
            routerLink="/accounts/jedit/{{ branch_id }}/{{
              journal_voucher_id
            }}/{{ finance_id }}/edit"
          >
            {{ "lang_edit" | language }}
          </button>
        </div>
      </div>
    </div>
    <mat-accordion class="journal_view">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_journal_voucher" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_journal_no" | language }} :
                    <b>{{ viewdata.journal_system_code }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_transaction_number" | language }} :
                    <b>{{ viewdata.transaction_id }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_branch" | language }} :
                    <b>{{ viewdata.branch_name }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_journal_date" | language }} :
                    <b>{{ viewdata.journal_voucher_date }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_credit_total" | language }} :
                    <b>{{
                      ds.addCommas(viewdata.journal_voucher_credit_total)
                    }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_debit_total" | language }} :
                    <b>{{
                      ds.addCommas(viewdata.journal_voucher_debit_total)
                    }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_total" | language }} :
                    <b>{{ viewdata.journal_voucher_no_of_records }}</b>
                  </label>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_financial_date" | language }} :
                    <b
                      >{{ viewdata.finance_start_date }} -
                      {{ viewdata.finance_end_date }}</b
                    ></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_writer" | language }} :
                    <b>{{ viewdata.writer_name }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_ondate" | language }} :
                    <b>{{ viewdata.journal_voucher_created_date }}</b></label
                  >
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4 col-xs-12 mb-10"
                *ngIf="viewdata.journal_voucher_updated_date"
              >
                <div class="form-group">
                  <label
                    >{{ "lang_onupdate" | language }} :
                    <b>{{ viewdata.journal_voucher_updated_date }}</b></label
                  >
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4 col-xs-12 mb-10"
                *ngIf="viewdata.journal_voucher_updated_date"
              >
                <div class="form-group">
                  <label
                    >{{ "lang_export_pdf" | language }} :
                    <i
                      class="fa fa-file-pdf-o mnabricon"
                      (click)="
                        ds.export_account_transaction(
                          journal_voucher_id,
                          'JRN',
                          'PDF',
                          branch_id
                        )
                      "
                    ></i
                  ></label>
                </div>
                <div class="form-group">
                  <label
                    >{{ "lang_print" | language }} :
                    <i
                      class="fa fa-print mnabricon"
                      (click)="
                        ds.print_account_transaction(
                          journal_voucher_id,
                          'JRN',
                          'PDF',
                          branch_id
                        )
                      "
                    ></i
                  ></label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="transactions.length > 0">
              <div class="col-md-12">
                <div class="table-responsive dropdownyes customResponsive">
                  <p class="red">
                    <b class="red">{{ "lang_transactions" | language }} : </b>
                  </p>
                  <table border="1" class="table CustomTable">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>{{ "lang_accounts_acccode" | language }}</td>
                        <td>{{ "lang_accounts_name" | language }}</td>
                        <td>{{ "lang_debit_amount" | language }}</td>
                        <td>{{ "lang_credit_amount" | language }}</td>
                        <td>{{ "lang_description" | language }}</td>
                        <td>{{ "lang_cost_center_code" | language }}</td>
                        <td>{{ "lang_reference_no" | language }}</td>
                        <td>{{ "lang_notes" | language }}</td>
                        <td>{{ "lang_cost_center" | language }}</td>
                        <td>{{ "lang_tags" | language }}</td>
                      </tr>
                    </thead>
                    <tbody class="AppendList">
                      <tr
                        *ngFor="
                          let data of transactions;
                          let i = index;
                          let odd = odd;
                          let even = even
                        "
                        [ngClass]="{ odd: odd, even: even }"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>
                          {{ data.account_masters_code }}
                          <i
                            class="fa fa-exchange mnabricon s2icon"
                            routerLink="/accounts/statement_of_accounts/{{
                              data.account_masters_id
                            }}/"
                            matTooltip="{{
                              'lang_view_transactions' | language
                            }}"
                          ></i>
                        </td>
                        <td>
                          {{ data.asaccount_full_name }}
                        </td>
                        <td>{{ ds.addCommas(data.debit_amount) }}</td>
                        <td>{{ ds.addCommas(data.credit_amount) }}</td>
                        <td>{{ data.transaction_history_description }}</td>
                        <td *ngIf="data.cost_name">
                          {{ data.cost_name }}
                          <i
                            class="fa fa-exchange mnabricon s2icon"
                            routerLink="/accounts/statement_of_costs/{{
                              data.cost_center_id
                            }}/"
                            matTooltip="{{
                              'lang_view_transactions' | language
                            }}"
                          ></i>
                        </td>
                        <td *ngIf="!data.cost_name">----</td>
                        <td *ngIf="data.transaction_history_ref_number">
                          {{ data.transaction_history_ref_number }}
                        </td>
                        <td *ngIf="!data.transaction_history_ref_number">
                          ----
                        </td>
                        <td *ngIf="data.transaction_history_notes">
                          {{ data.transaction_history_notes }}
                        </td>
                        <td *ngIf="!data.transaction_history_notes">----</td>
                        <td>
                          <i
                            class="fa fa-credit-card-alt mnabricon"
                            (click)="showCostCenter(data)"
                          ></i>
                        </td>
                        <td>
                          <span>{{ data.transaction_history_tages }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer padb0">
            <div class="row">
              <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                <button
                  type="button"
                  class="albutton"
                  routerLink="/accounts/jlist"
                >
                  {{ "lang_goto_list" | language }}
                </button>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
              <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                <button
                  *ngIf="JrnData.permits.add"
                  type="button"
                  class="albutton"
                  (click)="
                    this.router.navigateByUrl(
                      '/accounts/jcreate/' + branch_id + '/' + finance_id
                    )
                  "
                >
                  {{ "lang_add" | language }}
                </button>
              </div>
              <div
                class="col-md-2 col-sm-2 col-xs-12 mb-10"
                *ngIf="
                  JrnData.permits.delete &&
                  JrnData.financial_year.finance_id == viewdata.finance_id
                "
              >
                <button
                  type="button"
                  class="albutton"
                  routerLink="/accounts/jedit/{{ branch_id }}/{{
                    journal_voucher_id
                  }}/{{ finance_id }}/edit"
                >
                  {{ "lang_edit" | language }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="mpanel">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_history" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <app-account-history-log
            [account_operation]="'JOURNAL_VOUCHERS'"
            [id]="journal_voucher_id"
          ></app-account-history-log>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
