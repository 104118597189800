<div class="card special_approval_notes" [formGroup]="form">
	<div class="card-header">
		{{ "lang_owner_representative" | language }}
		<i
			style="padding: 0 10px"
			class="fa fa-info-circle orangestatus-color"
			matTooltip="{{ 'lang_owner_representative_tooltip' | language }}"
		></i>
	</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-sm-12 mb-3">
					<div class="form-group">
						<label for="notes"
							>{{ "lang_notes" | language }}: <span class="red">*</span></label
						>
						<textarea
							type="text"
							required
							class="form-control almnabrformcontrol"
							formControlName="notes"
							name="notes"
							id="notes"
						></textarea>
					</div>
				</div>
				<div class="col-sm-12">
					<label>{{ "lang_upload_attachments" | language }}</label>
					<div
						class="table-responsive customResponsive tablefields quotationitemtable"
					>
						<table
							border="1"
							class="table CustomTable"
							width="100%"
							formArrayName="attachments"
						>
							<thead>
								<tr>
									<td width="2%" style="padding: 5px; word-break: break-all">
										#
									</td>
									<td width="2%" style="padding: 5px; word-break: break-all">
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addfiles()"
											>add_box</mat-icon
										>
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_title" | language }}
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_file" | language }}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let file of filesFormGroup.controls;
										let i = index;
										let odd = odd;
										let even = even
									"
									[formGroupName]="i"
								>
									<td
										class="verticalalignmid"
										style="padding: 5px; word-break: break-all"
									>
										{{ i + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											*ngIf="!attachments.controls[i].get('delete_icon').value"
											(click)="removefiles(i)"
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											>delete_forever</mat-icon
										>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											type="text"
											formControlName="attach_title"
											placeholder="{{ 'lang_description' | language }}"
											autocomplete="off"
											class="form-control almnabrformcontrol"
										/>
									</td>
									<td
										class="verticalalignmid"
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											name="file"
											type="file"
											required
											onclick="this.value = null"
											(change)="
												ds.uploadSingleFile(
													$event,
													attachments.controls[i].get('file'),
													'file' + i,
													filename
												)
											"
											class="nghide albutton width100"
										/>
										<label id="file{{ i }}" class="albutton">{{
											filename
										}}</label>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="row pt-3">
				<div class="col-sm-12">
					<app-alert id="default-alert"></app-alert>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-4"></div>
				<div class="col-sm-4">
					<div>
						<button
							type="button"
							(click)="completeStep10()"
							class="albutton mnabrbutton mat-accent"
							mat-button
						>
							{{ "lang_submit" | language }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
