export class ApiRoutes {
    // languages
    public static get languages(): string { return 'languages'; }
    public static get phrases(): string { return 'phrases'; }
    public static get getPictureOnType(): string { return 'users/get_my_picture/'; }
    public static get getUser(): string { return 'user?user_id='; }
    public static get getSideNav(): string { return 'menu'; }
    public static get getLogo(): string { return 'default/theme/logo/'; }
    public static get getLiveDate(): string { return 'dateconversion/'; }

    // auth
    public static get login(): string { return 'login'; }
    public static get logout(): string { return 'logout'; }
    public static get forgotPassword(): string { return 'forgot_password'; }
    public static get changePassword(): string { return 'account/reset-password/finish'; }

    // customer
    public static get customer(): string { return 'admin/customers'; }
    public static get customerApplications(): string { return 'admin/customer/applications'; }
    public static get applicationDetail(): string { return 'admin/customer_application'; }
    public static get updateApplicationStatus(): string { return 'admin/update_status'; }

    // profile
    public static get getProfileInfo(): string { return 'admin/profile'; }
    public static get updateProfileInfo(): string { return 'admin/profile'; }

    //projects
    public static get getBranches(): string { return 'jQUW6F52AgToEsm/projects_add' }
    public static get getServices(): string { return 'aB8Vlmue0xVSv8O/projects_add' }
    public static get getCustomers(): string { return 'c91SZ4xopgmOM6A/projects_add/' }
    public static get getCustomerTypes(): string { return 'Y3ZjDZmYyUy5Rlx/projects_add' }
    public static get getSearchUsers(): string { return 'sZMVmoFEATU9YlN/projects_add' }
    public static get createProject(): string { return '9qBaMKIggFglieg' }
    public static get listProjects(): string { return 'MRpWxXzlMesi27d' }
    public static get getProject(): string { return 'TEd1bgyHSC0GPcq' }

    //Project Settings
    public static get getProjectSetting(): string { return  'gurognh32i/'}
    public static get getProjectServices(): string { return  'OP90X47s2n0Lese/'}
    public static get createProjectSetting(): string { return  'pc4ikjqik4/'}
    public static get updateProjectSetting(): string { return  '1rvm2hjqcx/'}
    public static get deleteProjectSetting(): string { return  '8gh7p1nz32/'}
    public static get createProjectService(): string { return  'VQ0pWl4JIgkUL6Z/'}
    public static get updateProjectService(): string { return  '1rvm2hjqcx/'}
    public static get deleteProjectService(): string { return  '8gh7p1nz32/'}
    public static get getProjectSettingTypes(): string { return '3664845n03'}
    // transaction
    public static get cronJobList(): string { return 'tc/cronjoblist' }
    public static get cronJob(): string { return 'tc/cronjob' }
    public static get getFormsValues(): string { return 'tc/gettcmydoclist' }
    public static get getModulesValues(): string { return 'tc/getmodulesmydoclist' }
    public static get getFormListsURL(): string { return 'tc/list' }
    public static get getApprovalList(): string { return 'tc/myapprovaldoclist' }
    public static get getTransaction(): string { return 'form' }
    public static get getVerificationList(): string { return 'tc/sender/select' }
}