<div class="card appjoinings">
	<div class="card-header">
		{{ "lang_human_resources_joining_details" | language }}
	</div>
	<div class="card-body p-0">
		<div class="mbrl15 martb0">
			<div class="row">
				<div class="input-group input-group-sm mb-10 col-12">
					<input
						type="text"
						class="form-control almnabrformcontrol"
						(keyup)="get_employee_joinings()"
						[(ngModel)]="searchKey"
						placeholder="{{ 'lang_search' | language }}"
					/>
					<span
						class="input-group-btn"
						*ngIf="
							requestfor !== 'profile' &&
							user_data.employee_status == '1' &&
							able2add
						"
					>
						<button
							class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
							id="btnSearch"
							type="button"
							(click)="showJoining.emit('JNGCR')"
							matTooltip="{{ 'lang_new_joining' | language }}"
						></button>
					</span>
				</div>
			</div>

			<div class="row p-1">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table
							border="1"
							class="table CustomTable"
							*ngIf="wholedata?.records?.length > '0'"
						>
							<thead>
								<tr>
									<td>{{ "lang_type" | language }}</td>
									<td>{{ "lang_start_date" | language }}</td>

									<td>{{ "lang_status" | language }}</td>
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody class="AppendList">
								<tr
									*ngFor="
										let item of wholedata?.records;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
								>
									<!--   <td>{{ item.joining_id }}</td> -->
									<td>{{ item.joining_type_value }}</td>
									<td>{{ item.joining_start_date_english }}</td>

									<td>
										<span *ngIf="item.approved_status == 1">{{
											"lang_approved" | language
										}}</span>
										<span *ngIf="item.approved_status == 0">{{
											"lang_rejected" | language
										}}</span>
									</td>
									<td>
										<!-- <i
                                        class="fa fa-file-pdf-o mnabricon s2icon"
                                        style="margin: 0 10px; padding: 0"
                                        matTooltip="{{ 'lang_preview' | language }}"
                                    ></i> -->
										<i
											class="fa fa-file-pdf-o mnabricon"
											(click)="
												ds.preview_pdf_file(item.link, item?.joining_type_value)
											"
											matTooltip="{{ 'lang_view' | language }}"
										></i>
									</td>
								</tr>
							</tbody>
						</table>
						<no-data
							style="margin: auto"
							[Opened]="wholedata?.records?.length == '0'"
						></no-data>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="wholedata?.records?.length != 0"
			class="card-footer padb0 customborder"
		>
			<div class="row">
				<!--  <div *ngIf="able2delete && requestfor !== 'profile' " class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                  <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataContr()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
              </div> -->
				<div class="col-lg-3 col-sm-6 col-6 mb-10">
					{{ "lang_total" | language }} {{ wholedata?.page?.total_records }}
				</div>
				<div class="col-lg-5 col-sm-6 col-12 mb-10">
					<mat-radio-group aria-label="Select an option">
						<mat-radio-button
							value="10"
							checked
							(click)="spinner.show(); load_page_size(10)"
						>
							10
						</mat-radio-button>
						<mat-radio-button
							value="20"
							(click)="spinner.show(); load_page_size(20)"
						>
							20
						</mat-radio-button>
						<mat-radio-button
							value="50"
							(click)="spinner.show(); load_page_size(50)"
						>
							50
						</mat-radio-button>
						<mat-radio-button
							value="100"
							(click)="spinner.show(); load_page_size(100)"
						>
							100
						</mat-radio-button>
						<mat-radio-button
							value="500"
							(click)="spinner.show(); load_page_size(500)"
						>
							500
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<div class="col-lg-4 col-sm-6 col-12 mb-10">
					{{ "lang_goto" | language }}
					<select
						class="customselect"
						(change)="spinner.show(); page_length($event.target.value)"
					>
						<option
							*ngFor="let k of ds.getrange(wholedata?.page?.total_pages)"
							value="{{ k }}"
						>
							{{ k }}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #viewjoinings>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">
				{{ "lang_human_resources_joining_details" | language }}
			</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefV.hide()"
			>
				&times;
			</button>
		</div>
		<div class="modal-body">
			<div class="form-group">
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_subject" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall"> : {{ viewdata?.records[0].subject }}</b>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_work_domain" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall"> : {{ viewdata?.records[0].work_domain }}</b>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_work_location" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall"> : {{ viewdata?.records[0].work_location }}</b>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_work_type" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall"> : {{ viewdata?.records[0].work_location }}</b>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_human_resources_joining_date" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ viewdata?.records[0].joining_date_english }} -
							{{ viewdata?.records[0].joining_date_arabic }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_human_resources_propation_period" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ viewdata?.records[0].probation_period }}
							{{ "lang_months" | language }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_human_resources_probation_end_date" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ viewdata?.records[0].probation_expiry_date_english }} -
							{{ viewdata?.records[0].probation_expiry_date_arabic }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_human_resources_joining_start_date" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ viewdata?.records[0].joining_start_date_english }} -
							{{ viewdata?.records[0].joining_start_date_arabic }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_human_resources_joining_period" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ viewdata?.records[0].joining_period }}
							{{ "lang_years" | language }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_human_resources_joining_end_date" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ viewdata?.records[0].joining_end_date_english }} -
							{{ viewdata?.records[0].joining_end_date_arabic }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_human_resources_joining_paid_days" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ viewdata?.records[0].joining_paid_days }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_human_resources_custom_joining_date" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ viewdata?.records[0].upcoming_joining_date_english }} -
							{{ viewdata?.records[0].upcoming_joining_date_arabic }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_human_resources_custom_joining_end_date" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ viewdata?.records[0].upcoming_joining_end_date_english }} -
							{{ viewdata?.records[0].upcoming_joining_end_date_arabic }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_human_resources_working_days_per_week" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ viewdata?.records[0].working_days_per_week }}
							{{ "lang_days" | language }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_human_resources_working_hours" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ viewdata?.records[0].working_hours_per_day }}
							{{ "lang_hours" | language }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_basic_salary" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ ds.addCommas(viewdata?.records[0].basic_salary) }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_home_allowance" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ ds.addCommas(viewdata?.records[0].home_allowance) }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_net_amount" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ ds.addCommas(viewdata?.records[0].net_amount) }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_status" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b
							class="breakall"
							*ngIf="viewdata?.records[0].joining_status == '1'"
						>
							: {{ "lang_active" | language }}</b
						>
					</div>
				</div>
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_writer" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall"> : {{ viewdata?.records[0].writer }}</b>
					</div>
				</div>
				<div class="row" *ngIf="viewdata && viewdata.allowances.length > 0">
					<p>{{ "lang_other_allowances" | language }}</p>
					<div class="table-responsive customResponsive tablefields">
						<table border="1" class="table CustomTable" width="100%">
							<thead>
								<tr>
									<td style="padding: 5px; word-break: break-all">#</td>
									<td style="padding: 5px; word-break: break-all">
										{{ "lang_desc_english" | language }}
									</td>
									<td style="padding: 5px; word-break: break-all">
										{{ "lang_invoice_item_amount" | language }}
									</td>
									<td style="padding: 5px; word-break: break-all">
										{{ "lang_desc_arabic" | language }}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let otr of viewdata.allowances;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
									style="background: #ffffff"
								>
									<td width="1%" class="verticalalignmid">{{ i + 1 }}</td>
									<td
										style="padding: 5px; word-break: break-all"
										style="position: relative"
									>
										{{ otr.other_allowance_description_en }}
									</td>
									<td
										style="padding: 5px; word-break: break-all"
										style="position: relative"
									>
										{{ ds.addCommas(otr.other_allowance_amount) }}
									</td>
									<td
										style="padding: 5px; word-break: break-all"
										style="position: relative"
									>
										{{ otr.other_allowance_description_ar }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button
				type="button"
				class="btn btn-secondary"
				data-dismiss="modal"
				(click)="modalRefV.hide()"
			>
				{{ "lang_close" | language }}
			</button>
		</div>
	</div>
</ng-template>
