import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
declare var $:any;
interface list {
  id?: number;
  group_key: string,
  module_key: string,
  group_name_english: string,
  group_name_arabic: string,
  modulename: string
}
@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss']
})
export class UserGroupsComponent implements OnInit,OnDestroy {
  modalRefA: BsModalRef;
  tabledatas: list[];
  form:FormGroup;
  @Output() actionEvent             = new EventEmitter<any>();
  collectionSize                    = 0;
  page                              = 1;
  pageSize                          = 10;
  searchKey                         = "";
  nodata: string                    = this.lang.transform('lang_loading');
  admin                             = JSON.parse(this.ls.getItem('is_admin'));
  wholeData:any                     = [];
  tabledata:any                     = [];
  modules:any                       = [];
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.get_modules();
    this.get_groups();
    this.form = new FormGroup({
      group_key             : new FormControl(''),
      module_key            : new FormControl('', [Validators.required]),
      group_name_english    : new FormControl('', [Validators.required]),
      group_name_arabic     : new FormControl(''),
      active_to_users       : new FormControl(''),
    });
  }
  private subscriptions = new Subscription();
	ngOnDestroy(): void {
	  this.subscriptions.unsubscribe();
	}
  public get_modules() {
    this.modules = [];
    this.subscriptions.add(
        this.ds.postActionByUrl([], 'module').subscribe(res => {
          if(res.status) {
            this.modules = res.records;
          }
        })			
      )

  }
  //add, edit model
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public get_groups() {
    let param = new FormData();
    param.append('search_key', this.searchKey);
    param.append('request', 'list');
    this.subscriptions.add(
        this.ds.getActionByUrl(this.ds.formData2string(param), 'ugroup_lists').subscribe(res=>{
          this.spinner.hide();
          if(res.status) {
            this.wholeData      = res.records;
            this.tabledatas     = res.records;
            this.collectionSize = res.records.length;
            this.build_table_data();
          } else {
            this.wholeData = [];
            this.nodata = this.lang.transform("lang_no_data");
          }
        }, error => {
          this.spinner.hide();
          this.wholeData = [];
          this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
        }) 			
      )

  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public edit_group_type_data(row) {
    this.form.patchValue(row);
  }
  public submit() {
    this.spinner.show();
    if(this.form.get('platform_group_type_id').value) {
      this.subscriptions.add(
            this.ds.putActionByUrl(this.ds.formData2string(this.ds.json2formdata(this.form.value)), 'upgtype/' + this.form.get('platform_group_type_id').value).subscribe(res=> {
              this.spinner.hide();
              if(res.status) {
                this.get_groups();
                this.form.reset();
                this.actionEvent.emit('');
                this.alert.success(res.msg);
                setTimeout(() => {
                  this.modalRefA.hide();
                }, 1000);
              } else {
                this.alert.error(res.error);
              }
            }, error => {
              this.spinner.hide();
              this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
            })			
        )

    } else {
      this.subscriptions.add(
          this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'cpgtype').subscribe(res=> {
            this.spinner.hide();
            if(res.status) {
              this.get_groups();
              this.form.reset();
              this.actionEvent.emit('');
              this.alert.success(res.msg);
              setTimeout(() => {
                this.modalRefA.hide();
              }, 1000);
            } else {
              this.alert.error(res.error);
            }
          }, error => {
            this.spinner.hide();
            this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          })			
        )

    }
  }
  public checkallItemsuGrp(event) {
    if (event.target.checked) {
      $(".appusergroups .eachItemuGrp").prop("checked", true);
      $(".appusergroups .makeback").addClass("bground");
      $(".appusergroups .deleteIconDiv").show();
    } else {
      $(".appusergroups .eachItemuGrp").prop("checked", false);
      $(".appusergroups .makeback").removeClass("bground");
      $(".appusergroups .deleteIconDiv").hide();
    }
  }
  public checkeachItemuGrp(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appusergroups .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appusergroups .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appusergroups .eachItemuGrp:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appusergroups .eachItemuGrp").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appusergroups .deleteIconDiv").show();
    } else {
      $(".appusergroups .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appusergroups #allItemsuGrp").prop("checked", true);
    } else {
      $(".appusergroups #allItemsuGrp").prop("checked", false);
    }
  }
  public deleteBulkDatauGrp() {
    const checkedtotal = [];
    $(".appusergroups .eachItemuGrp:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      this.subscriptions.add(
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.spinner.show()
              this.subscriptions.add(
                  this.ds.deleteActionByUrl(checkedtotal, 'dpgtype').subscribe(
                    (data) => {
                      this.spinner.hide()
                      if (data.status) {
                        this.actionEvent.emit('');
                        this.get_groups();
                        $(".appusergroups #allItemsuGrp").prop("checked", false);
                        $(".appusergroups .deleteIconDiv").hide();
                        this.ds.dialogf('', data.msg);
                      } else {
                        this.ds.dialogf('', data.error);
                      }
                    },
                    (error) => {
                      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                      this.spinner.hide()
                    }
                  )			
                )

            }
          })			
        )

    } else {
      this.ds.dialogf();
    }
  }
}
