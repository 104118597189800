import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import {
  NgbCalendar,
  NgbCalendarIslamicUmalqura,
  NgbDatepickerI18n,
} from "@ng-bootstrap/ng-bootstrap";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { IslamicI18n } from "@shared/utils/arabicpicker";
import { BsModalService } from "ngx-bootstrap/modal";
@Component({
  selector: "app-step1",
  templateUrl: "./step1.component.html",
  styleUrls: ["./step1.component.scss"],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n },
  ],
})
export class Step1Component implements OnInit {
  @Input() regForm: FormGroup;
  @Input() action;
  public fileurl;
  apiurl = environment.SERVER_ORIGIN;
  idfilename = this.lang.transform("lang_select_files");
  branches: any = [];
  etitles: any = [];
  types: any = [];
  countries: any = [];
  view_profile_image = "./assets/images/avatar.png ";
  isSubmitted = false;
  constructor(
    public router: Router,
    public ds: DataService,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.branchlists();
    this.grouplists();
    console.log(this.regForm);
    const nameEnglish =
      this.regForm?.value?.idDetails?.firstname_english +
      " " +
      this.regForm?.value?.idDetails?.secondname_english +
      " " +
      this.regForm?.value?.idDetails?.thirdname_english +
      " " +
      this.regForm?.value?.idDetails?.lastname_english;
    this.regForm?.get("idDetails").get("name_english")?.setValue(nameEnglish);
    const nameArabic =
      this.regForm?.value?.idDetails?.firstname_arabic +
      " " +
      this.regForm?.value?.idDetails?.secondname_arabic +
      " " +
      this.regForm?.value?.idDetails?.thirdname_arabic +
      " " +
      this.regForm?.value?.idDetails?.lastname_arabic;

    this.regForm?.get("idDetails").get("name_arabic")?.setValue(nameArabic);
  }
  get f() {
    return this.regForm.controls.idDetails;
  }
  public onFileChange(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      var reader = new FileReader();
      const file = event.target.files[0];

      if (file.type.includes("image") && file.size > 300000) {
        this.alert.warn(this.lang.transform("lang_image_not_exceed_300kb"));
        return 0;
      }

      reader.readAsDataURL(file);
      reader.onload = (event) => {
        if (event) {
          this.fileurl = (<FileReader>event.target).result;
        }
      };
      this.regForm
        .get("idDetails")
        .get("profile_image")
        .setValue(file, { emitEvent: true });
    } else {
      this.regForm.get("idDetails").get("profile_image").setValue("");
      this.fileurl = "";
    }
  }
  public removeProfile() {
    this.regForm.get("idDetails").get("profile_image").setValue([]);
    this.fileurl = "";
  }
  public branchlists() {
    this.branches = [];
    this.types = [];
    this.etitles = [];
    this.countries = [];
    let permit =
      this.action === "EDIT" ? "human_resources_edit" : "human_resources_add";
    this.ds
      .getActionByUrl([], "human_resources/branches/" + permit)
      .subscribe((data) => {
        if (data.status) {
          this.branches = data.branches;
          this.etitles = data.titles;
          this.countries = data.countries;
          this.types = data.types;
        }
      });
  }

  groups = [];
  public grouplists() {
    this.groups = [];

    this.ds
      .getActionByUrl([], "human_resources/grouplists")
      .subscribe((data) => {
        if (data.status) {
          this.groups = data.grouplists;
        }
      });
  }
  public employeeExists(idnumber) {
    if (this.action !== "EDIT" && idnumber.length == 10) {
      let formData = new FormData();
      formData.append("employee_id_number", idnumber);
      this.ds
        .postActionByUrl(formData, "human_resources/employeeexists")
        .subscribe(
          (data) => {
            if (!data.status) {
              this.f.get("employee_id_number").setValue("");
              this.ds.dialogf("", data.error);
            }
          },
          (error) => {
            this.f.get("employee_id_number").setValue("");
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        );
    }
  }
  public uploadIDFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.regForm
        .get("idDetails")
        .get("idFileUpload")
        .setValue(fileid, { emitEvent: true });
    } else {
      this.regForm.get("idDetails").get("idFileUpload").setValue("");
      this.idfilename = this.lang.transform("lang_select_files");
    }
  }

  public checkNameValidation() {
    const fullNameEn = this.regForm
      .get("idDetails")
      ["controls"]["name_english"].value.split(" ");
    const fullNameAr = this.regForm
      .get("idDetails")
      ["controls"]["name_arabic"].value.split(" ");

    if (fullNameEn[0]) {
      this.regForm
        .get("idDetails")
        ?.get("firstname_english")
        ?.setValue(fullNameEn[0]);
    } else {
      this.regForm.get("idDetails")?.get("firstname_english")?.setValue("");
    }
    if (fullNameEn[1]) {
      this.regForm
        .get("idDetails")
        ?.get("secondname_english")
        ?.setValue(fullNameEn[1]);
    } else {
      this.regForm.get("idDetails")?.get("secondname_english")?.setValue("");
    }
    if (fullNameEn[2] && fullNameEn?.length > 3) {
      this.regForm
        .get("idDetails")
        ?.get("thirdname_english")
        ?.setValue(fullNameEn[2]);
    } else if (fullNameEn[2] && fullNameEn?.length === 3) {
      this.regForm
        .get("idDetails")
        ?.get("lastname_english")
        ?.setValue(fullNameEn[2]);
    } else {
      this.regForm.get("idDetails")?.get("thirdname_english")?.setValue("");
      this.regForm.get("idDetails")?.get("lastname_english")?.setValue("");
    }
    if (fullNameEn[3]) {
      this.regForm
        .get("idDetails")
        ?.get("lastname_english")
        ?.setValue(fullNameEn[3]);
    }

    if (fullNameAr[0]) {
      this.regForm
        .get("idDetails")
        ?.get("firstname_arabic")
        ?.setValue(fullNameAr[0]);
    } else {
      this.regForm.get("idDetails")?.get("firstname_arabic")?.setValue("");
    }
    if (fullNameAr[1]) {
      this.regForm
        .get("idDetails")
        ?.get("secondname_arabic")
        ?.setValue(fullNameAr[1]);
    } else {
      this.regForm.get("idDetails")?.get("secondname_arabic")?.setValue("");
    }
    if (fullNameAr[2] && fullNameAr?.length > 3) {
      this.regForm
        .get("idDetails")
        ?.get("thirdname_arabic")
        ?.setValue(fullNameAr[2]);
    } else if (fullNameAr[2] && fullNameAr?.length === 3) {
      this.regForm
        .get("idDetails")
        ?.get("lastname_arabic")
        ?.setValue(fullNameAr[2]);
    } else {
      this.regForm.get("idDetails")?.get("thirdname_arabic")?.setValue("");
      this.regForm.get("idDetails")?.get("lastname_arabic")?.setValue("");
    }
    if (fullNameAr[3]) {
      this.regForm
        .get("idDetails")
        ?.get("lastname_arabic")
        ?.setValue(fullNameAr[3]);
    }
  }

  public step1Submitted() {
    this.checkNameValidation();

    this.regForm.get("idDetails").get("employee_id_number").markAsTouched();
    this.regForm
      .get("idDetails")
      .get("employee_id_number")
      .updateValueAndValidity();
    this.regForm.get("idDetails").get("branch_id").markAsTouched();
    this.regForm.get("idDetails").get("branch_id").updateValueAndValidity();
    this.regForm.get("idDetails").get("firstname_english").markAsTouched();
    this.regForm
      .get("idDetails")
      .get("firstname_english")
      .updateValueAndValidity();
    this.regForm.get("idDetails").get("secondname_english").markAsTouched();
    this.regForm
      .get("idDetails")
      .get("secondname_english")
      .updateValueAndValidity();
    this.regForm.get("idDetails").get("lastname_english").markAsTouched();
    this.regForm
      .get("idDetails")
      .get("lastname_english")
      .updateValueAndValidity();
    this.regForm.get("idDetails").get("firstname_arabic").markAsTouched();
    this.regForm
      .get("idDetails")
      .get("firstname_arabic")
      .updateValueAndValidity();
    this.regForm.get("idDetails").get("secondname_arabic").markAsTouched();
    this.regForm
      .get("idDetails")
      .get("secondname_arabic")
      .updateValueAndValidity();
    this.regForm.get("idDetails").get("lastname_arabic").markAsTouched();
    this.regForm
      .get("idDetails")
      .get("lastname_arabic")
      .updateValueAndValidity();
    this.regForm
      .get("idDetails")
      .get("iqama_expiry_date_english")
      .markAsTouched();
    this.regForm
      .get("idDetails")
      .get("iqama_expiry_date_english")
      .updateValueAndValidity();
    this.regForm.get("idDetails").get("copy_number").markAsTouched();
    this.regForm.get("idDetails").get("copy_number").updateValueAndValidity();
    this.regForm.get("idDetails").get("birth_date_english").markAsTouched();
    this.regForm
      .get("idDetails")
      .get("birth_date_english")
      .updateValueAndValidity();
    this.regForm.get("idDetails").get("gender").markAsTouched();
    this.regForm.get("idDetails").get("gender").updateValueAndValidity();
    this.regForm.get("idDetails").get("nationality").markAsTouched();
    this.regForm.get("idDetails").get("nationality").updateValueAndValidity();
    this.regForm.get("idDetails").get("job_title_iqama").markAsTouched();
    this.regForm
      .get("idDetails")
      .get("job_title_iqama")
      .updateValueAndValidity();
    this.regForm.get("idDetails").get("marital_status").markAsTouched();
    this.regForm
      .get("idDetails")
      .get("marital_status")
      .updateValueAndValidity();
    this.regForm.get("idDetails").get("religion").markAsTouched();
    this.regForm.get("idDetails").get("religion").updateValueAndValidity();
    this.regForm.get("idDetails").get("work_domain").markAsTouched();
    this.regForm.get("idDetails").get("work_domain").updateValueAndValidity();
    this.regForm.get("idDetails").get("work_location").markAsTouched();
    this.regForm.get("idDetails").get("work_location").updateValueAndValidity();
    this.regForm.get("idDetails").get("group_id").markAsTouched();
    this.regForm.get("idDetails").get("group_id").updateValueAndValidity();
    this.regForm.get("idDetails").get("work_type").markAsTouched();
    this.regForm.get("idDetails").get("work_type").updateValueAndValidity();
    this.regForm
      .get("idDetails")
      .get("total_previous_contract_days")
      .markAsTouched();
    this.regForm
      .get("idDetails")
      .get("total_previous_contract_days")
      .updateValueAndValidity();
    this.regForm
      .get("idDetails")
      .get("legal_situation_status")
      .updateValueAndValidity();
    if (this.action !== "EDIT") {
      this.regForm.get("idDetails").get("id_filename").markAsTouched();
      this.regForm.get("idDetails").get("id_filename").updateValueAndValidity();
      this.regForm.get("idDetails").get("idFileUpload").markAsTouched();
      this.regForm
        .get("idDetails")
        .get("idFileUpload")
        .updateValueAndValidity();
    }
  }
  public get_profile_image(url) {
    this.ds.getActionByUrl([], url).subscribe(
      (data) => {
        if (data.status) {
          this.view_profile_image =
            "data:" + data["content-type"] + ";base64," + data.base64;
        } else {
          this.view_profile_image = "./assets/images/default1.png ";
        }
      },
      (error) => {
        this.view_profile_image = "./assets/images/default1.png ";
      }
    );
  }

  public isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 44) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
