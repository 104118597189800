import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-sqr-step7',
  templateUrl: './sqr-step7.component.html',
  styleUrls: ['./sqr-step7.component.scss']
})
export class SqrStep7Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  stepName:string = 'Evaluation_Result';
  recomandationsData:any;
  form:FormGroup;
  rawIndex:any;
  other_recomandations :FormArray;
  notesTable = [];
  constructor(public ds:DataService, private sweetAlert:SweetAlertService, public datepipe : DatePipe , public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef,public dialog: MatDialog) { }
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id: new FormControl(this.form_request_id),
      evaluation_result     : new FormControl({value : null, disabled : true}),
      Evaludation_Result   : this.fb.array([])
    });
    this.other_recomandations = this.form.get("Evaludation_Result") as FormArray;
    this.getStep7Data();
  }

  get _recomandationsgroup() {
    return this.form.get("Evaludation_Result") as FormArray;
  }
  public _recomandations(value?): FormGroup {
    if(value) {
      return this.fb.group({
        showSelect                  : [false],
        delete                      : [false],
        resultChanged               : [null],
        title                       : new FormControl({ value : value?.extra1_title, disabled : (value?.extra1_title) ? true : false }),
        projects_from_consultant_id : new FormControl({ value : value?.projects_from_consultant_id, disabled : (value?.projects_from_consultant_id) ? true : false }),
        result                      : new FormControl({ value : value?.extra1_result, disabled : (value?.extra1_result) ? true : false }),
        status                      : new FormControl(null),
        attachments                 : new FormArray([]),
      });
    } else {
      return this.fb.group({
        showSelect                  : [true],
        delete                      : [true],
        resultChanged               : [null],
        title                       : new FormControl('', [Validators.required]),
        projects_from_consultant_id : new FormControl(''),
        result                      : new FormControl(''),
        status                      : new FormControl(null),
        attachments                 : new FormArray([]),
      });
    }
  }
  public add_recomandations(value?) {
    this.other_recomandations.push(this._recomandations(value));
  }
  public remove_recomandations(index) {
    if (this.other_recomandations.length > 1) {
      this.other_recomandations.removeAt(index);
    }
  }

  getStep7Data() {
    let formData = new FormData();
    formData.append("transaction_request_id", this.form_request_id);
      this.ds.post(`form/${this.form_key}/Evaluation_Result/0`, formData).subscribe(data => {
        this.recomandationsData = data;
        if(data?.status) {
          this.form.get('evaluation_result').setValue(data.final_result);
          if(data?.final_result == 'A' || data?.final_result == 'B') {
            data?.Consultant_Recommendations?.forEach((v, k) => {
              let recomandationsData = {
                extra1_title:  v?.extra1_title,
                extra1_result: v?.extra1_result,
                projects_from_consultant_id: v?.projects_consultant_recommendations_id
              }
              this.add_recomandations(recomandationsData);
            }); 
          } else {
            this.notesTable = Object.values(data?.Notes);
          }
        } else {
          this.add_recomandations();
        }
      }, err => {
        this.alert.error(err)
        this.add_recomandations();
      })
  }

  getAttachments(data) {
    let other_recomandations_files = this.other_recomandations.get(`${data.index}`).get('attachments') as FormArray;
    other_recomandations_files.setValue([]);
    for(let file of data.form.controls) {
      other_recomandations_files.push(file);
    }
  }

  radioChange(e, i) {
    if(e.value == '1') {
      let value = this.other_recomandations.controls[i].get('result').value;
      this.other_recomandations.controls[i].get('resultChanged').setValue(value);
    } else {
      this.other_recomandations.controls[i].get('resultChanged').setValue('D');
    }
  }

  checkValidation() {
    if(this.form.get('Evaludation_Result').invalid) {
      this.alert.error(this.lang.transform('lang_evaludation_result_required'));
    } else {
      return true;
    }
  }

  public completeStep7() {
    if(this.checkValidation()) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('transaction_request_id', this.form.value.transaction_request_id);
      this.other_recomandations.controls.forEach((v, k) => {
        formData.append('Evaludation_Result['+k+'][title]', v['controls']['title']?.value);
        formData.append('Evaludation_Result['+k+'][projects_from_consultant_id]', v['controls']['projects_from_consultant_id']?.value);
        formData.append('Evaludation_Result['+k+'][status]', v['controls']['status']?.value);
        formData.set("Evaludation_Result[" + k + "][result]", v['controls']['result']?.value);
        if(v['controls']['attachments']?.length) {
          v['controls']['attachments']['controls']?.forEach((a, b) => {
            formData.set("Evaludation_Result[" + k + "][attachments][" + b + "][file]", a['controls']['file']?.value);
            formData.set("Evaludation_Result[" + k + "][attachments][" + b + "][attach_title]", a['controls']['attach_title']?.value);
            formData.set("Evaludation_Result[" + k + "][attachments][" + b + "][required]", null);
          });
        }
      });
      this.ds.post(`form/${this.form_key}/Evaluation_Result/1`, formData).subscribe((res) => {
        this.spinner.hide();
        if(res.status){
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
          setTimeout(() => {
            this.refreshComponent.emit(true);
          }, 2000);
        } else {
          this.alert.error(res.error);
        }
        
      }, (error) => {
        this.spinner.hide();
        this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    }
  }
}
