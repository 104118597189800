import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Checklist } from "../../models/checklist";
import { Task } from "../../models/task";
import { TaskMembers } from "../../models/task-members";
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
	selector: "app-template-task",
	templateUrl: "./template-task.component.html",
	styleUrls: ["./template-task.component.scss"],
})
export class TemplateTaskComponent implements OnInit {
	// public Editor = ClassicEditor;
	lang_key = localStorage.getItem("lang_key") || "en";
	userId = this.localstorageService.getItem("user_id");
	baseUrl = environment.SERVER_ORIGIN;
	task_id;
	task: Task;
	task_members: TaskMembers[];
	memberCardData: TaskMembers;
	checklist_arr: Array<Checklist> = [];
	task_attachments = [];
	taskAttachmentsSlice = 2;
	task_status_done = null;
	constructor(
		public dialogRef: MatDialogRef<TemplateTaskComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private spinner: PreloaderService,
		private ds: DataService,
		private localstorageService: LocalStorage,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {
		this.task_id = data?.task?.task_id;
		this.task = {
			task_id: "",
			title: "",
			task_status: "",
			task_time: "",
			task_status_name: "",
			description: "",
			start_date: "",
			end_date: "",
			user_add_id: "",
			task_progress: "",
			ticket_no: "",
			ticket_id: "",
			full_task_number: "",
			task_no: "",
		};
		this.getTaskData();
		this.getTaskMembers();
		this.getChecklist();
	}

	ngOnInit(): void {}
	//get task data
	getTaskData() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		this.ds.post("tasks/get_task_only_temp", formData).subscribe(
			(res) => {
				this.spinner.hide();
				this.task.task_id = res?.data?.task_id;
				this.task.title = res?.data?.title;
				this.task.description = res?.data?.task_detailes;
				this.task.task_status = res?.data?.task_status;
				this.task.task_time = res?.data?.task_time;
				this.task.start_date = res?.data?.start_date;
				this.task.end_date = res?.data?.end_nearly;
				this.task.user_add_id = res?.data?.user_add_id;
				this.task.task_progress = res?.data?.progres;
				this.task.ticket_no = res?.data?.ticket_no;
				this.task.ticket_id = res?.data?.ticket_id;
				this.task.full_task_number = res?.data?.full_task_number;
				this.task.task_no = res?.data?.task_no;
				this.task_attachments = res?.data?.files || [];
				this.task_status_done = res?.data?.task_status_done;
				switch (res?.data?.task_status) {
					case "1": {
						this.task.task_status_name = "New";
						break;
					}
					case "2": {
						this.task.task_status_name = "In Progress";
						break;
					}
					case "3": {
						this.task.task_status_name = "Confirm";
						break;
					}
					case "4": {
						this.task.task_status_name = "Done";
						break;
					}
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}
	//get task members
	getTaskMembers() {
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		this.ds.post("tasks/get_emp_in_task", formData).subscribe(
			(res) => {
				this.task_members = res?.data?.map((el) => {
					return {
						firstName: el?.first_name || "Almnabr",
						lastName: el?.last_name || "User",
						userName: el?.emp_name,
						userId: el?.emp_id,
						profile_image_64: el?.profile_image_64,
					};
				});
			},
			(err) => {
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

	onShowMemberCard(member) {
		this.memberCardData = {
			firstName: member?.firstName,
			lastName: member?.lastName,
			userName: member?.userName,
			userId: member?.userId,
		};
	}
	//get checklist
	getChecklist() {
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		this.ds.post("tasks/get_points_task_checks_temp", formData).subscribe(
			(res) => {
				if (res?.status) {
					this.checklist_arr = res?.data?.map((el) => {
						return {
							id: el?.check_id,
							title: el?.title,
							progress: el?.progres,
							chicklist_items: el?.sub_checks,
							is_add: false,
							hideChecked: false,
						};
					});
				} else {
					this.checklist_arr = [];
				}
			},
			(err) => {
				this.checklist_arr = [];
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

	//start downloadFile (task attachments)
	downloadFile(file_url, title?) {
		this.spinner.show();
		this.ds.get(file_url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.ds.downloadFile(res, title);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	//end downloadFile (task attachments)
}
