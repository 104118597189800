import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import moment from 'moment';

@Component({
  selector: 'app-add-shift',
  templateUrl: './add-shift.component.html',
  styleUrls: ['./add-shift.component.scss']
})
export class AddShiftComponent implements OnInit {
  mondayDayOff: boolean = true;
  tuesdayDayOff: boolean = true;
  wednesdayDayOff: boolean = true;
  thursdayDayOff: boolean = true;
  fridayDayOff: boolean = false;
  saturdayDayOff: boolean = false;
  sundayDayOff: boolean = true;
  shiftNameForm!: FormGroup;
  shiftTimeForm!: FormGroup;
  shiftsFormArray: FormArray;

  shiftDataTypes = [
    {
      label: 'Holiday',
      value: 'H'
    },
    {
      label: 'Default Start & End TIme',
      value: 'T'
    },
    {
      label: 'Custom Start & End Time',
      value: 'CT'
    }
  ];
  @ViewChild('stepper') private myStepper: MatStepper;
  shift_id: number;
  routeShiftId = this.route.snapshot.params?.id;
  isEditMode: boolean;
  constructor(
    public fb : FormBuilder,
    private spinner: PreloaderService,
    private ds: DataService,
    private sweetAlert: SweetAlertService,
    private lang: LanguagePipe,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    moment.locale("en");
    this.buildForm();
    if(this.routeShiftId) {
      this.isEditMode = true;
      this.getShiftDataById();
    } else {
      this.addshiftsRecord();
    }
  }

  getShiftDataById() {
    let formData = new FormData();
    formData.append('shift_id', this.routeShiftId || '');
    this.spinner.show();
    this.ds.getActionByUrl(this.ds.formData2string(formData), 'shifts/get_shift_and_setup').subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        console.log(res);
        this.shiftNameForm.get('shift_name_english')?.setValue(res?.records?.shiftData[0]?.shift_name_english);
        this.shiftNameForm.get('shift_name_arabic')?.setValue(res?.records?.shiftData[0]?.shift_name_arabic);
        for(let item of res?.records?.setupData) {
          console.log(item);
          
          if(item?.shift_custom_data_type == 'H' || item?.shift_custom_data_type == 'CT' || item?.shift_custom_data_type == 'T') {
            this.addshiftsRecord(item);
          }
          if(item?.shift_custom_data_type == 'W') {
            switch(item?.shift_weekoff_day) {
              case '1':
                this.mondayDayOff = false;
                break;
              case '2':
                this.tuesdayDayOff = false;
                break;
              case '3':
                this.wednesdayDayOff = false;
                break;
              case '4':
                this.thursdayDayOff = false;
                break;
              case '5':
                this.fridayDayOff = false;
                break;
              case '6':
                this.saturdayDayOff = false;
                break;
              case '7':
                this.sundayDayOff = false;
                break;
            }
          }
        }
      }
    })
  }

  buildForm() {
    this.shiftNameForm = this.fb.group({
      shift_name_english: ['', Validators.required],
      shift_name_arabic: ['', Validators.required]
    });
    this.shiftTimeForm = this.fb.group({
      shifts: this.fb.array([]),
    });
    this.shiftsFormArray = this.shiftTimeForm.get("shifts") as FormArray;
  }

  get shifts() {
    return this.shiftTimeForm.get("shifts") as FormArray;
  }
  
  public shiftsRecords(value?): FormGroup {
    let date;
    if(value?.shift_custom_data_type == 'H' || value?.shift_custom_data_type == 'CT') {
      date = [new Date(value?.holiday_startdate), new Date(value?.holiday_enddate)]
    }
    let shiftStartDateTime = new Date();
    if(value?.shift_starttime) {
      let startTimes = value?.shift_starttime?.split(':')
      shiftStartDateTime.setHours(startTimes[0]);
      shiftStartDateTime.setMinutes(startTimes[1]);
    }
    let shiftEndDateTime = new Date();
    if(value?.shift_endtime) {
      let endTimes = value?.shift_endtime?.split(':')
      shiftEndDateTime.setHours(endTimes[0]);
      shiftEndDateTime.setMinutes(endTimes[1]);
    }
    return this.fb.group({
      shift_custom_data_type: new FormControl(
        value ? value?.shift_custom_data_type : 'T',
        Validators.required
      ),
      date: new FormControl(
        date ? date : "",
        [Validators.required]
      ),
      shiftStartTime: new FormControl(value ? shiftStartDateTime : ""),
      shiftEndTime: new FormControl(value ? shiftEndDateTime : ""),
      apply_all_shifts: new FormControl(value ? value?.apply_all_shifts : false),
    });
  }

  addshiftsRecord(value?) {
    this.shiftsFormArray.push(this.shiftsRecords(value));
  }

  onRemoveItem(index:number) {
    this.shiftsFormArray.removeAt(index);
  }

  //submit shift name
  submitShiftName() {
    if(this.shiftNameForm?.valid) {
      this.spinner.show();
      if(this.shift_id || this.routeShiftId) {
        let formData = new FormData();
        formData.append('shift_name_english', this.shiftNameForm?.get('shift_name_english')?.value || '');
        formData.append('shift_name_arabic', this.shiftNameForm?.get('shift_name_arabic')?.value || '');
        this.ds.putActionByUrl(this.ds.formData2string(formData), `shifts/update_shift/${this.routeShiftId ? this.routeShiftId : this.shift_id}`).subscribe(res => {
          this.spinner.hide();
          if(res?.status) {
            this.sweetAlert.successToast(res?.msg, 3000);
            this.myStepper.next();
          } else {
            this.sweetAlert.errorToast(res?.error, 3000);
          }
        }, err => {
          this.spinner.hide();
          this.sweetAlert.errorToast(err?.error?.error, 3000);
        })
      } else {
        let formData = new FormData();
        formData.append('module_key', '');
        formData.append('module_extra_key', '');
        formData.append('shifts[0][shift_name_english]', this.shiftNameForm?.get('shift_name_english')?.value || '');
        formData.append('shifts[0][shift_name_arabic]', this.shiftNameForm?.get('shift_name_arabic')?.value || '');
        this.ds.post('shifts/create_shifts', formData).subscribe(res => {
          this.spinner.hide();
          if(res?.status) {
            this.sweetAlert.successToast(res?.msg, 3000);
            this.shift_id = res?.shift_id;
            this.myStepper.next();
          } else {
            this.sweetAlert.errorToast(res?.error, 3000);
          }
        }, err => {
          this.spinner.hide();
          this.sweetAlert.errorToast(err?.error?.error, 3000);
        })
      }
    } else {
      this.sweetAlert.errorToast(this.lang.transform('lang_fill_required_fields'), 3000);
    }
    
  }

  onSubmit() {
    let daysOffText = '';
    if(!this.mondayDayOff) {
      daysOffText += '1';
    }
    if(!this.tuesdayDayOff) {
      if(daysOffText?.length >= 1)
        daysOffText += ',2';
      else
        daysOffText += '2';
    }
    if(!this.wednesdayDayOff) {
      if(daysOffText?.length >= 1)
        daysOffText += ',3';
      else
        daysOffText += '3';
    }
    if(!this.thursdayDayOff) {
      if(daysOffText?.length >= 1)
        daysOffText += ',4';
      else
        daysOffText += '4';
    }
    if(!this.fridayDayOff) {
      if(daysOffText?.length >= 1)
        daysOffText += ',5';
      else
        daysOffText += '5';
    }
    if(!this.saturdayDayOff) {
      if(daysOffText?.length >= 1)
        daysOffText += ',6';
      else
        daysOffText += '6';
    }
    if(!this.sundayDayOff) {
      if(daysOffText?.length >= 1)
        daysOffText += ',7';
      else
        daysOffText += '7';
    }
    const formData = new FormData();
    formData.append('shift_id', `${this.isEditMode ? this.routeShiftId : this.shift_id}` || '0');
    formData.append('shift_weekoff_day', daysOffText || '');
    this.shiftsFormArray?.controls?.forEach((el, index) => {
      if(el?.get('shift_custom_data_type')?.value == 'H') {
        formData.append(`shift[${index}][shift_custom_data_type]`, el?.get('shift_custom_data_type')?.value || '');
        formData.append(`shift[${index}][start_date]`, moment(el?.get('date')?.value[0])?.format('yyy-MM-DD') || '');
        formData.append(`shift[${index}][end_date]`, moment(el?.get('date')?.value[1])?.format('yyy-MM-DD') || '');
        formData.append(`shift[${index}][shift_id]`,  el?.get('apply_all_shifts')?.value ? '0' : `${this.isEditMode ? this.routeShiftId : this.shift_id}`);
      } else if(el?.get('shift_custom_data_type')?.value == 'T') {
        formData.append(`shift[${index}][shift_custom_data_type]`, el?.get('shift_custom_data_type')?.value || '');
        formData.append(`shift[${index}][shift_starttime]`, moment(el?.get('shiftStartTime')?.value)?.format("hh:mm") || '');
        formData.append(`shift[${index}][shift_endtime]`, moment(el?.get('shiftEndTime')?.value)?.format("hh:mm") || '');
        formData.append(`shift[${index}][shift_id]`,  el?.get('apply_all_shifts')?.value ? '0' : `${this.isEditMode ? this.routeShiftId : this.shift_id}`);
      } else if(el?.get('shift_custom_data_type')?.value == 'CT') {
        formData.append(`shift[${index}][shift_custom_data_type]`, el?.get('shift_custom_data_type')?.value || '');
        formData.append(`shift[${index}][start_date]`, moment(el?.get('date')?.value[0])?.format('yyy-MM-DD') || '');
        formData.append(`shift[${index}][end_date]`, moment(el?.get('date')?.value[1])?.format('yyy-MM-DD') || '');
        formData.append(`shift[${index}][shift_starttime]`, moment(el?.get('shiftStartTime')?.value)?.format("hh:mm") || '');
        formData.append(`shift[${index}][shift_endtime]`, moment(el?.get('shiftEndTime')?.value)?.format("hh:mm") || '');
        formData.append(`shift[${index}][shift_id]`,  el?.get('apply_all_shifts')?.value ? '0' : `${this.isEditMode ? this.routeShiftId : this.shift_id}`);
      }
    });
    this.spinner.show();
    this.ds.post('shifts/create_shift_custom_data', formData).subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.sweetAlert.successToast(res?.msg, 3000);
        this.router.navigate(['/default/shifts']);
      } else {
        this.sweetAlert.errorToast(res?.error, 3000);
      }
    }, err => {
      this.spinner.hide();
      this.sweetAlert.errorToast(err?.error?.error, 3000);
    })
  }
}
