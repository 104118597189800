import { Component, OnInit, TemplateRef } from "@angular/core";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { DataService } from "@core/bootstrap/data.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { environment } from "@env/environment";
import { PreloaderService } from "@core/bootstrap/preloader.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";
import * as _moment from "moment";
import { default as _rollupMoment, Moment } from "moment";
import { ActivatedRoute } from "@angular/router";
import { type } from "os";

const moment = _rollupMoment || _moment;

@Component({
  selector: "app-add-hollidays",
  templateUrl: "./add-hollidays.component.html",
  styleUrls: ["./add-hollidays.component.scss"],
})
export class AddHollidaysComponent implements OnInit {
  public shift_id = this.route.snapshot.paramMap.get("shift_id");
  listData: any = [];
  loading: boolean = true;
  nodata: string = this.lang.transform("lang_loading");
  lang_key = localStorage.getItem("lang_key") || "en";
  form: FormGroup;
  shift_holidays_Array: FormArray;
  modalRef: BsModalRef | null;
  update_holiday = false;
  constructor(
    public lang: LanguagePipe,
    public ds: DataService,
    public fb: FormBuilder,
    public modalService: BsModalService,
    public spinner: PreloaderService,
    public alert: AlertService,
    private sweetAlert: SweetAlertService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.get_form_lists();
    this.buildform();
    this.shift_holidays_Array = this.form.get("shift_holidays") as FormArray;
  }
  openModal(template: TemplateRef<any>) {
    this.shift_holidays_Array.controls = [];
    if (!this.update_holiday) {
      this.add_shift_holidays("عيد الفطر", "Eid al-Fitr", "true");
      this.add_shift_holidays("عيد الأضحى", "Eid al-Adha", "true");
      this.add_shift_holidays();
    } else {
      this.listData.records.forEach((element, index) => {
        console.log(element, index);
        if (index < 2) {
          this.add_shift_holidays(
            element.name_ar,
            element.name_en,
            "true",
            new Date(element.start_holiday_date),
            new Date(element.end_holiday_date)
          );
          console.log(index);
        } else {
          this.add_shift_holidays(
            element.name_ar,
            element.name_en,
            "",
            new Date(element.start_holiday_date),
            new Date(element.end_holiday_date)
          );
          console.log(index);
        }
      });
    }
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  public get_form_lists() {
    this.listData = [];
    this.loading = true;

    let param = new FormData();
    param.append("shift_id", this.shift_id);
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "at/get_shift_holidays/")
      .subscribe(
        (res) => {
          this.loading = false;
          if (res.status) {
            this.listData = res;
            this.update_holiday = true;
          } else {
            this.listData = [];
            this.nodata = this.lang.transform("lang_no_data");
            this.update_holiday = false;
          }
        },
        (error) => {
          this.loading = false;
          this.update_holiday = false;
          this.listData = [];
          this.nodata =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
  }
  public buildform() {
    this.form = new FormGroup({
      shift_holidays: this.fb.array([]),
    });
  }
  public shift_holidays_Records(
    name_ar?,
    name_en?,
    disabled?,
    start_holiday_date?,
    end_holiday_date?
  ): FormGroup {
    return this.fb.group({
      start_holiday_date: new FormControl(start_holiday_date, [
        Validators.required,
      ]),
      end_holiday_date: new FormControl(end_holiday_date, [
        Validators.required,
      ]),
      name_ar: new FormControl({ value: name_ar, disabled: disabled }, [
        Validators.required,
      ]),
      name_en: new FormControl({ value: name_en, disabled: disabled }, [
        Validators.required,
      ]),
    });
  }
  public shift_holidays_Group() {
    return this.form.get("shift_holidays") as FormArray;
  }
  get shift_holidays_Groups() {
    return this.form.get("shift_holidays") as FormArray;
  }
  public add_shift_holidays(
    name_ar?,
    name_en?,
    disabled?,
    start_holiday_date?,
    end_holiday_date?
  ) {
    this.shift_holidays_Array?.push(
      this.shift_holidays_Records(
        name_ar,
        name_en,
        disabled,
        start_holiday_date,
        end_holiday_date
      )
    );
  }
  public remove_shift_holidays(index) {
    if (index > 2) {
      this.shift_holidays_Array?.removeAt(index);
    }
  }
  clearEmpty_shift_holidays() {
    for (let k = this.shift_holidays_Array.length - 1; k > -1; k--) {
      if (
        this.shift_holidays_Groups.controls[k].get("start_holiday_date")
          .value == null ||
        (this.shift_holidays_Groups.controls[k].get("end_holiday_date").value ==
          "" &&
          this.shift_holidays_Groups.controls[k].get("name_ar").value == "")
      ) {
        this.remove_shift_holidays(k);
      }
    }
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.append("shift_id", this.shift_id);
    this.shift_holidays_Array?.controls?.forEach((el, index) => {
      param.append(
        `array_holidays[${index}][start_holiday_date]`,
        moment(el?.get("start_holiday_date")?.value).format("YYYY/MM/DD") || ""
      );
      param.append(
        `array_holidays[${index}][end_holiday_date]`,
        moment(el?.get("end_holiday_date")?.value).format("YYYY/MM/DD") || ""
      );
      param.append(
        `array_holidays[${index}][name_ar]`,
        el?.get("name_ar")?.value || ""
      );
      param.append(
        `array_holidays[${index}][name_en]`,
        el?.get("name_en")?.value || ""
      );
    });
    let url;
    if (this.update_holiday) {
      url = "at/update_shift_holidays";
    } else {
      url = "at/create_shift_holidays";
    }
    this.subscriptions.add(
      this.ds.postActionByUrl(param, url).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.sweetAlert.successToast(
              this.lang.transform("lang_success"),
              2000
            );
            this.get_form_lists();
            this.modalRef.hide();
          } else {
            this.sweetAlert.errorToast(res?.error, 2000);
          }
        },
        (error) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      )
    );
  }
}
