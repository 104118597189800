<div class="mbrl15 supervision_quotations">
	<div class="row">
		<div class="col-12">
			<div class="form-group">
				<input
					type="text"
					class="form-control almnabrformcontrol searchKey"
					id="searchKey"
					[(ngModel)]="searchKey"
					(keyup)="search_supervision_quotations()"
					placeholder="{{ 'lang_search' | language }}"
				/>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_quotation_no" | language }}</td>
							<td>{{ "lang_subject" | language }}</td>
							<td>{{ "lang_grand_total" | language }}</td>
							<td>{{ "lang_tax_amount" | language }}</td>
							<td>{{ "lang_net_amount" | language }}</td>
							<td>{{ "lang_submitted_date" | language }}</td>
							<td>{{ "lang_approval_date" | language }}</td>
							<td>{{ "lang_status" | language }}</td>
							<td>{{ "lang_writer" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody *ngIf="listData.length != '0'" class="AppendList">
						<tr
							*ngFor="
								let list of listData.records;
								let i = index;
								let odd = odd;
								let even = even
							"
							class="{{ list.row_color }}{{ list.row_color_flash }}"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ list.projects_quotation_id }}</td>
							<td>{{ list.quotation_subject }}</td>
							<td>
								{{
									ds.addCommas(list.quotation_grand_total)
										? ds.addCommas(list.quotation_grand_total)
										: "--"
								}}
							</td>
							<td>
								{{
									ds.addCommas(list.quotation_tax_amount)
										? ds.addCommas(list.quotation_tax_amount)
										: "--"
								}}
							</td>
							<td>
								{{
									ds.addCommas(list.quotation_net_amount)
										? ds.addCommas(list.quotation_net_amount)
										: "--"
								}}
							</td>
							<td>{{ list.quotation_created_date }}</td>
							<td>{{ list.quotation_approved_date }}</td>
							<td *ngIf="list.projects_quotation_status == '0'">
								<i
									class="fa fa-icon fa-circle yellowcolor"
									matTooltip="{{ 'lang_pending' | language }}"
									aria-hidden="true"
								></i>
							</td>
							<td *ngIf="list.projects_quotation_status == '1'">
								<i
									class="fa fa-icon fa-circle green"
									matTooltip="{{ 'lang_approved' | language }}"
									aria-hidden="true"
								></i>
							</td>
							<td *ngIf="list.projects_quotation_status == '2'">
								<i
									class="fa fa-icon fa-circle red"
									matTooltip="{{ 'lang_rejected' | language }}"
									aria-hidden="true"
								></i>
							</td>
							<td>{{ list.writer }}</td>
							<td>
								<mat-icon
									class="mnabricon"
									[matMenuTriggerFor]="menu"
									matTooltip="{{ 'lang_settings' | language }}"
									>settings</mat-icon
								>
								<mat-menu #menu="matMenu">
									<button
										mat-menu-item
										(click)="openViewModal(viewModel, list)"
									>
										<span>{{ "lang_view" | language }}</span>
									</button>
									<button
										mat-menu-item
										*ngIf="list.projects_quotation_pdf_file"
										(click)="
											ds.preview_pdf_file(
												list.projects_quotation_pdf_file,
												list?.quotation_subject
											)
										"
									>
										<span>{{ "lang_quotation_attachment" | language }}</span>
									</button>
									<button
										mat-menu-item
										*ngIf="list.quotation_approval_receipt"
										(click)="
											ds.preview_pdf_file(
												list.quotation_approval_receipt,
												list?.quotation_subject
											)
										"
									>
										<span>{{
											"lang_quotation_approved_receipt" | language
										}}</span>
									</button>
									<button
										mat-menu-item
										*ngIf="list.projects_quotation_status == '0'"
										(click)="openArroveModal(approveModel, list)"
									>
										<span>{{ "lang_approve" | language }}</span>
									</button>
									<button
										mat-menu-item
										*ngIf="list.projects_quotation_status == '0'"
										(click)="openRejectModal(rejectModel, list)"
									>
										<span>{{ "lang_reject" | language }}</span>
									</button>
								</mat-menu>
							</td>
						</tr>
					</tbody>
					<tbody *ngIf="listData.length == '0'" class="AppendList">
						<tr class="odd">
							<td colspan="11" class="nodata" align="center">{{ nodata }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
<div *ngIf="listData.length != '0'" class="card-footer padb0">
	<div class="row responsive_pagination">
		<div
			class="col-lg-3 col-sm-3 mb-10"
			style="height: fit-content; margin: auto 0"
		>
			{{ "lang_total" | language }} {{ listData?.page?.total_records }}
		</div>
		<div
			class="col-lg-6 col-sm-6 mb-10"
			style="display: flex; justify-content: center"
		>
			<ngb-pagination
				class="m-auto"
				[collectionSize]="listData?.page?.total_records"
				[rotate]="true"
				[ellipses]="false"
				[maxSize]="3"
				[boundaryLinks]="true"
				[(page)]="pageno"
				[pageSize]="pagesize"
				(pageChange)="load_supervision_quotations_page($event)"
			></ngb-pagination>
		</div>
		<div
			class="col-lg-3 col-sm-3"
			style="display: flex; justify-content: end; margin: auto"
		>
			<mat-select
				style="max-width: 150px"
				[(ngModel)]="pagesize"
				(selectionChange)="load_supervision_quotations_pagesize($event.value)"
			>
				<mat-option class="text-center" value="10"
					>10 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" value="20"
					>20 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" value="50"
					>50 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" value="100"
					>100 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option class="text-center" value="500"
					>500 {{ "lang_per_page" | language }}</mat-option
				>
			</mat-select>
		</div>
	</div>
</div>
<div class="modal" id="pdfPreviewQuotSuper">
	<div class="modal-dialog width70">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_preview" | language }}</h4>
				<button type="button" class="close" data-dismiss="modal">
					&times;
				</button>
			</div>
			<div class="modal-body">
				<div class="pdf-container">
					<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</div>
<ng-template #approveModel>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_approve_quotation" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefA.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="approveform" (ngSubmit)="approveQuotation()">
			<div class="modal-body">
				<div class="form-group">
					<div class="row">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<label for="quotation_approval_receipt"
								>{{ "lang_select_files" | language }}
								<span class="red">*</span></label
							>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<div class="form-group">
								<div class="IDDIV">
									<input
										name="quotation_approval_receipt"
										type="file"
										required
										onclick="this.value = null"
										(change)="uploadFrontFile($event)"
										class="nghide albutton width100"
									/>
									<label id="uploadIDButton" class="albutton"
										>{{ filename }}
									</label>
								</div>
							</div>
							<mat-error
								*ngIf="
									submitted &&
									approveform.controls['quotation_approval_receipt'].errors
										?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRefA.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #rejectModel>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_reject_quotation" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefR.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="rejectform" (ngSubmit)="rejectQuotation()">
			<div class="modal-body">
				<div class="form-group">
					<div class="row">
						<label for="quotation_rejected_reason"
							>{{ "lang_rejected_reason" | language }}
							<span class="red">*</span></label
						>
						<textarea
							cols="5"
							formControlName="quotation_rejected_reason"
							class="form-control almnabrformcontrol"
							placeholder="{{ 'lang_rejected_reason' | language }}"
						></textarea>
						<mat-error
							*ngIf="
								submitted &&
								rejectform.controls['quotation_rejected_reason'].errors
									?.required
							"
						>
							{{ "lang_field_required" | language }}
						</mat-error>
					</div>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRefR.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #viewModel>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_view" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefV.hide()"
			>
				&times;
			</button>
		</div>
		<div class="modal-body">
			<div class="form-group">
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_subject" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">{{ viewdata.quotation_subject }}</b>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_grand_total" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ ds.addCommas(viewdata.quotation_grand_total) }}</b
						>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_tax_amount" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ ds.addCommas(viewdata.quotation_tax_amount) }}</b
						>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_net_amount" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall">
							: {{ ds.addCommas(viewdata.quotation_net_amount) }}</b
						>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_submitted_date" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall"> : {{ viewdata.quotation_created_date }}</b>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_approval_date" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall"> : {{ viewdata.quotation_approved_date }}</b>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_quotation_version" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall"> : {{ viewdata.tbv_count }}</b>
					</div>
				</div>
			</div>
			<div class="form-group" *ngIf="viewdata.projects_quotation_status == '2'">
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_rejected_reason" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b class="breakall"> : {{ viewdata.quotation_rejected_reason }}</b>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<label class="col-md-6 col-sm-6 col-xs-12">{{
						"lang_status" | language
					}}</label>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<b
							class="breakall"
							*ngIf="viewdata.projects_quotation_status == '0'"
							class="yellowcolor"
						>
							: {{ "lang_pending" | language }}</b
						>
						<b
							class="breakall"
							*ngIf="viewdata.projects_quotation_status == '1'"
							class="green"
						>
							: {{ "lang_approved" | language }}</b
						>
						<b
							class="breakall"
							*ngIf="viewdata.projects_quotation_status == '2'"
							class="red"
						>
							: {{ "lang_rejected" | language }}</b
						>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal footer -->
		<div class="modal-footer">
			<button
				type="button"
				class="btn btn-secondary"
				(click)="modalRefV.hide()"
			>
				{{ "lang_close" | language }}
			</button>
		</div>
	</div>
</ng-template>
