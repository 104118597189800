import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { ChatService } from "../../services/chat.service";

@Component({
	selector: "app-w-new-contact",
	templateUrl: "./w-new-contact.component.html",
	styleUrls: ["./w-new-contact.component.scss"],
})
export class WNewContactComponent implements OnInit {
	name: string;
	number: string;
	addedContact;
	constructor(
		private dialogRef: MatDialogRef<WNewContactComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private chatService: ChatService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private spinner: PreloaderService,
		private ds: DataService
	) {}

	ngOnInit(): void {}
	createContact() {
		if (this.number) {
			let formData = new FormData();
			formData.append("phone_number", this.number || "");
			formData.append("name", this.name || "");
			this.spinner.show();
			this.ds.post("WhatsappApi/contacts", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.chatService.newContact.next(res?.contact);
						this.chatService.selectedChat.next(res?.contact);
						this.dialogRef.close();
					} else {
						this.sweetAlert.errorToast(res?.error, 3000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
		} else {
			this.sweetAlert.errorToast(
				this.lang.transform("lang_fill_required_fields"),
				3000
			);
		}
	}

	isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 43) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
}
