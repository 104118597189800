import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
  selector: "app-teams-create-update",
  templateUrl: "./teams-create-update.component.html",
  styleUrls: ["./teams-create-update.component.scss"],
})
export class TeamsCreateUpdateComponent implements OnInit {
  lang_key: string = localStorage.getItem("lang_key") || "en";
  project_data: any;
  design_data: any;
  form: FormGroup;
  team_posistions: FormArray;
  viewdata: any = [];
  directors: any = [];
  managers: any = [];
  users: any = [];
  positions: any = [];
  teams_data: any = [];
  constructor(
    private fb: FormBuilder,
    public ds: DataService,
    private dialogRef: MatDialogRef<TeamsCreateUpdateComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private spinner: PreloaderService,
    private sweetAlert: SweetAlertService,
    private lang: LanguagePipe
  ) {
    this.project_data = data?.project_data;
    this.design_data = data?.design_data;
    this.teams_data = data?.teams_data;
  }

  ngOnInit(): void {
    this.buildform();
    this.get_positions();
  }
  buildform() {
    this.form = this.fb.group({
      director_position_id: [null, Validators.required],
      project_supervision_director: [null, Validators.required],
      manager_position_id: [null, Validators.required],
      project_supervision_manager: [null, Validators.required],
      teams: this.fb.array([]),
    });
    this.team_posistions = this.form.get("teams") as FormArray;
  }
  get_positions() {
    this.positions = [];
    this.ds.getActionByUrl([], "366484fd45").subscribe((res) => {
      if (res.status) {
        this.positions = res.records;
      }
    });
  }
  items_group() {
    return this.form.get("teams") as FormArray;
  }
  get itemsgroup() {
    return this.form.get("teams") as FormArray;
  }
  add_new_items() {
    this.team_posistions.push(this.item_records());
  }
  removeItems(index) {
    this.users = [];
    if (this.team_posistions.length > 1) {
      this.team_posistions.removeAt(index);
    }
  }
  item_records(): FormGroup {
    return this.fb.group({
      project_settings_id: [null, Validators.required],
      project_user_group_user_id: [null, Validators.required],
    });
  }
  search_users(key) {
    this.users = [];
    let param = new FormData();
    if (key.term && key.term != undefined) {
      param.append("user_type_id", "1");
      param.append("search", key.term);
      param.append("lang_key", this.lang_key);
      this.ds
        .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
        .subscribe((res) => {
          if (res.status) {
            this.users = res.list;
          }
        });
    }
  }

  submit() {
    this.spinner.show();
    let param = new FormData();
    param.set("projects_profile_id", this.design_data.projects_profile_id);
    param.set("projects_supervision_id", this.design_data.projects_design_id);
    param.set(
      "director_position_id",
      this.ds.select_array_string(this.form, "director_position_id", "value") ||
        ""
    );
    param.set(
      "project_supervision_director",
      this.ds.select_array_string(
        this.form,
        "project_supervision_director",
        "value"
      ) || ""
    );
    param.set(
      "manager_position_id",
      this.ds.select_array_string(this.form, "manager_position_id", "value") ||
        ""
    );
    param.set(
      "project_supervision_manager",
      this.ds.select_array_string(
        this.form,
        "project_supervision_manager",
        "value"
      ) || ""
    );
    this.team_posistions.value.forEach((v, k) => {
      if (v.project_settings_id) {
        param.set("project_settings_id[]", v.project_settings_id || "");
      }
      if (v.project_user_group_user_id) {
        param.set(
          "project_user_group_user_id[]",
          v.project_user_group_user_id.toString()
        );
      }
    });
    this.ds.postActionByUrl(param, "MII2kBvbAePmHjG").subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.sweetAlert.successToast(res.msg, 2000);
          this.dialogRef.close(true);
        } else {
          this.sweetAlert.errorToast(res.error, 2000);
        }
      },
      (error) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error"),
          2000
        );
      }
    );
  }
}
