<div class="mbrl15">
	<div class="row">
		<div class="col-12 col-sm-3 mb-2">
			<button
				class="albutton mnabrbutton"
				mat-raised-button
				(click)="toggleFilter()"
			>
				<i class="fa fa-filter"></i>
				{{ "lang_advanced_filter" | language }}
			</button>
		</div>
		<div class="col-12 col-sm-3 mb-2">
			<button
				class="albutton mnabrbutton"
				mat-raised-button
				(click)="acceptedUnacceptedUnits()"
			>
				<i class="fa fa-building-o"></i>
				{{ "lang_available_units" | language }}
			</button>
		</div>
		<div class="col-12 col-sm-6 mb-10">
			<div class="input-group input-group-sm">
				<input
					type="text"
					class="form-control almnabrformcontrol"
					[(ngModel)]="searchKey"
					(keyup)="getSnagNoteList()"
					placeholder="{{ 'lang_search' | language }}"
				/>
				<span class="input-group-btn">
					<button
						id="btnSearch"
						type="button"
						matTooltip="{{ 'lang_add' | language }}"
						class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
						(click)="createUpdateSnagNote()"
						*ngIf="makeActionOperations"
					></button>
				</span>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-md-4" *ngIf="showFilter">
			<mat-card class="card">
				<mat-card-title class="card-title">
					<h4>{{ "lang_advanced_filter" | language }}</h4>
				</mat-card-title>
				<mat-card-content>
					<form [formGroup]="filterForm">
						<div class="row">
							<div class="col-12 mt-1">
								<label for="note_lists_id">{{
									"lang_note_id" | language
								}}</label>
								<input
									type="text"
									class="form-control"
									id="note_lists_id"
									name="note_lists_id"
									formControlName="note_lists_id"
								/>
							</div>
							<div class="col-12 mt-1">
								<label for="snag_request_id">{{
									"lang_snag_request_id" | language
								}}</label>
								<input
									type="text"
									class="form-control"
									id="snag_request_id"
									name="snag_request_id"
									formControlName="snag_request_id"
								/>
							</div>
							<div class="col-12 mt-1">
								<label for="snag_status">{{ "lang_status" | language }}</label>
								<ng-select
									[items]="snagStatus"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									appendTo="body"
									clearAllText="Clear"
									placeholder="{{ 'lang_status' | language }}"
									formControlName="snag_status"
									id="snag_status"
									[notFoundText]="'lang_no_data' | language"
								>
								</ng-select>
							</div>
							<div class="col-12 mt-1">
								<label for="general_number">{{ "lang_unit" | language }}</label>
								<ng-select
									[items]="unitList"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									appendTo="body"
									clearAllText="Clear"
									formControlName="general_number"
									placeholder="{{ 'lang_unit' | language }}"
									(search)="get_unit_general_List($event)"
									[searchFn]="ds.customSearchFn"
									[notFoundText]="'lang_no_data' | language"
								></ng-select>
							</div>
							<div class="col-12 mt-1">
								<label for="level_key">{{ "lang_level_key" | language }}</label>
								<ng-select
									[items]="levels_arr"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									appendTo="body"
									clearAllText="Clear"
									formControlName="platform_work_level_key"
									placeholder="{{ 'lang_level_key' | language }}"
									[notFoundText]="'lang_no_data' | language"
								></ng-select>
							</div>
							<!-- <div class="col-12 mt-1">
								<label for="group1">{{ "lang_group_one" | language }}</label>
								<ng-select
									[items]="group1List"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									appendTo="body"
									clearAllText="Clear"
									formControlName="platform_group1_code_system"
									placeholder="{{ 'lang_group_one' | language }}"
								></ng-select>
							</div>
							<div class="col-12 mt-1">
								<label for="group2">{{ "lang_group_two" | language }}</label>
								<ng-select
									[items]="group2List"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									appendTo="body"
									clearAllText="Clear"
									formControlName="platform_group2_code_system"
									placeholder="{{ 'lang_group_two' | language }}"
								></ng-select>
							</div> -->
							<div class="col-12 mt-1">
								<label for="template_id">{{
									"lang_template_id" | language
								}}</label>
								<ng-select
									[items]="templateList"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									appendTo="body"
									clearAllText="Clear"
									placeholder="{{ 'lang_template_id' | language }}"
									formControlName="template_id"
									(change)="load_group1_type_group2_lists('group1')"
									id="template_id"
									[notFoundText]="'lang_no_data' | language"
								>
								</ng-select>
							</div>
							<div class="col-12 mt-1">
								<label for="group1_id">{{ "lang_group_one" | language }}</label>
								<ng-select
									[items]="group1List"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									appendTo="body"
									clearAllText="Clear"
									placeholder="{{ 'lang_group_one' | language }}"
									formControlName="platform_group1_code_system"
									(change)="load_group1_type_group2_lists('type')"
									id="group1_id"
									[notFoundText]="'lang_no_data' | language"
								>
								</ng-select>
							</div>
							<div class="col-12 mt-1">
								<label for="group_type_id">{{
									"lang_group_type" | language
								}}</label>
								<ng-select
									[items]="typeList"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									appendTo="body"
									clearAllText="Clear"
									placeholder="{{ 'lang_type' | language }}"
									formControlName="group_type_id"
									(change)="load_group1_type_group2_lists('group2')"
									id="group_type_id"
									[notFoundText]="'lang_no_data' | language"
								>
								</ng-select>
							</div>
							<div class="col-12 mt-1">
								<label for="group2_id">{{ "lang_group_two" | language }}</label>
								<ng-select
									[items]="group2List"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									appendTo="body"
									clearAllText="Clear"
									placeholder="{{ 'lang_group_two' | language }}"
									formControlName="platform_group2_code_system"
									id="group2_id"
									[notFoundText]="'lang_no_data' | language"
								>
								</ng-select>
							</div>
							<div class="col-12 mt-1">
								<label for="zone">{{ "lang_zone" | language }}</label>
								<ng-select
									[items]="zones"
									[multiple]="false"
									bindLabel="label"
									appendTo="body"
									clearAllText="Clear"
									placeholder="{{ 'lang_zone' | language }}"
									formControlName="phase_zone_no"
									id="zone"
									(change)="get_blocks()"
									[notFoundText]="'lang_no_data' | language"
								>
								</ng-select>
							</div>
							<div class="col-12 mt-1">
								<label for="block">{{ "lang_blocks" | language }}</label>
								<ng-select
									[items]="blocks"
									[multiple]="false"
									bindLabel="label"
									appendTo="body"
									clearAllText="Clear"
									placeholder="{{ 'lang_blocks' | language }}"
									formControlName="phase_zone_block_no"
									id="block"
									(change)="get_clusters()"
									[notFoundText]="'lang_no_data' | language"
								>
								</ng-select>
							</div>
							<div class="col-12 mt-1">
								<label for="cluster">{{ "lang_clusters" | language }}</label>
								<ng-select
									[items]="clusters"
									[multiple]="false"
									bindLabel="label"
									appendTo="body"
									clearAllText="Clear"
									placeholder="{{ 'lang_clusters' | language }}"
									formControlName="phase_zone_block_cluster_no"
									id="cluster"
									[notFoundText]="'lang_no_data' | language"
								>
								</ng-select>
							</div>
							<div class="col-12 mt-2 d-flex justify-content-between">
								<button
									type="button"
									class="albutton"
									style="width: 40% !important"
									(click)="resetFilterForm()"
								>
									{{ "lang_reset" | language }}
								</button>
								<button
									type="submit"
									class="btn albutton filter-btn"
									style="width: 40% !important"
									(click)="getSnagNoteList()"
								>
									{{ "lang_submit" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-card-content>
			</mat-card>
		</div>
		<div [ngClass]="{ 'col-12 col-md-8': showFilter, 'col-12': !showFilter }">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_unit" | language }}</td>
							<td>{{ "lang_unit_name" | language }}</td>
							<td>{{ "lang_status" | language }}</td>
							<td>{{ "lang_total" | language }}</td>
							<td>{{ "lang_pending" | language }}</td>
							<td>{{ "lang_solved" | language }}</td>
							<td>{{ "lang_performer" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody *ngIf="snagNoteList.length" class="AppendList">
						<ng-container
							*ngFor="
								let list of snagNoteList;
								let unit_index = index;
								let odd = odd;
								let even = even
							"
						>
							<tr [ngClass]="{ odd: odd, even: even }">
								<td>{{ unit_index + 1 }}</td>
								<td>{{ list.unit_id }}</td>
								<td>{{ list.label }}</td>
								<td *ngIf="list.status == 'writer_verification'">
									{{ "lang_writer_verification" | language }}
								</td>
								<td *ngIf="list.status == 'manager_verification'">
									{{ "lang_manager_verification" | language }}
								</td>
								<td *ngIf="list.status == 'contractor_receiving_waiting'">
									{{ "lang_contractor_receiving_waiting" | language }}
								</td>
								<td *ngIf="list.status == 'contractor_reserved'">
									{{ "lang_contractor_reserved" | language }}
								</td>
								<td *ngIf="list.status == 'almnabr_team'">
									{{ "lang_almnabr_team" | language }}
								</td>
								<td *ngIf="list.status == 'almnabr_team_reserved'">
									{{ "lang_almnabr_team_reserved" | language }}
								</td>
								<td *ngIf="list.status == 'De-Snag-M'">
									{{ "lang_De_Snag_M" | language }}
								</td>
								<td *ngIf="list.status == 'completed'">
									{{ "lang_completed" | language }}
								</td>
								<td>{{ list.total_records }}</td>
								<td>{{ list.total_snag_pending }}</td>
								<td>{{ list.total_snag_accepted }}</td>
								<td>{{ list.perfomer || "---" }}</td>
								<td>
									<i
										class="fa fa-expand mnabricon ml-2 mr-2"
										(click)="expandUnit(list, unit_index)"
										matTooltip="{{ 'lang_view_notes' | language }}"
									></i>

									<i
										*ngIf="list.request_rejection_note"
										class="fa fa-sticky-note-o mr-1 ml-1"
										matTooltip="{{ list.request_rejection_note }}"
									></i>

									<i
										*ngIf="list.status == 'De-Snag-M'"
										class="fa fa-check green mr-1 ml-1"
										matTooltip="{{ 'lang_accept' | language }}"
										(click)="confirmDeSnag('2', list?.team_final_id)"
									></i>
									<i
										*ngIf="list.status == 'De-Snag-M'"
										class="fa fa-window-close red mr-1 ml-1"
										matTooltip="{{ 'lang_reject' | language }}"
										(click)="confirmDeSnag('3', list?.team_final_id)"
									></i>
									<i
										class="fa fa-trash red"
										matTooltip="{{ 'lang_delete' | language }}"
										(click)="deleteUnit(list?.team_final_id)"
										*ngIf="
											ds.allowMe('projects', 'projects_delete') ||
											list.status == 'writer_verification'
										"
									></i>
								</td>
							</tr>
							<tr *ngIf="list?.expanded">
								<td></td>
								<td colspan="9">
									<div class="table-responsive dropdownyes customResponsive">
										<table border="1" class="table CustomTable">
											<thead>
												<tr>
													<td>{{ "lang_note_id" | language }}</td>
													<td>{{ "lang_note" | language }}</td>
													<td>{{ "lang_work_level" | language }}</td>
													<td>{{ "lang_description" | language }}</td>
													<td>{{ "lang_status" | language }}</td>
													<td>{{ "lang_writer" | language }}</td>
													<td>{{ "lang_created_date" | language }}</td>
													<td>{{ "lang_action" | language }}</td>
												</tr>
											</thead>
											<tbody *ngIf="snagNoteList.length" class="AppendList">
												<tr
													*ngFor="
														let note of snagNoteList[unit_index].snag_note_list;
														let note_index = index;
														let odd = odd;
														let even = even
													"
													[ngClass]="{ odd: odd, even: even }"
												>
													<td>{{ note.note_lists_id }}</td>
													<td>{{ note.notename }}</td>
													<td>{{ note.worklevel }}</td>
													<td>{{ note.level_description }}</td>
													<td>{{ note.status }}</td>
													<td>{{ note.perfomer }}</td>
													<td>{{ note.created_datetime }}</td>
													<td>
														<i
															class="fa fa-pencil-square-o mnabricon mr-1 ml-1"
															matTooltip="{{ 'lang_confirm' | language }}"
															(click)="confirmStep(note, list, unit_index)"
															*ngIf="
																note?.action_step_code != 'completed' &&
																note?.action_step_code !=
																	'contractor_receiving_waiting' &&
																note?.action_step_code != 'almnabr_team' &&
																note?.action_step_code !=
																	'writer_verification' &&
																list.status != 'De-Snag-M'
															"
														></i>
														<i
															class="fa fa-download mnabricon mr-1 ml-1"
															matTooltip="{{ 'lang_download' | language }}"
															(click)="downloadNoteAttachment(note)"
														></i>
														<i
															*ngIf="note.rejected_notes"
															class="fa fa-sticky-note-o mr-1 ml-1"
															matTooltip="{{ note.rejected_notes }}"
														></i>
														<i
															*ngIf="
																ds.allowMe('projects', 'projects_delete') ||
																list.status == 'writer_verification'
															"
															class="fa fa-trash red mr-1 ml-1"
															matTooltip="{{ 'lang_delete' | language }}"
															(click)="deleteSnagNote(note?.note_operation_id)"
														></i>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
			<div *ngIf="!snagNoteList?.length">
				<p style="margin: 0; margin-top: 10px; text-align: center">
					{{ "lang_no_data" | language }}
				</p>
			</div>
			<div
				*ngIf="snagNoteList.length"
				class="padb0 card-footer padb0 ng-star-inserted"
			>
				<div class="row responsive_pagination">
					<div
						class="col-lg-3 col-sm-3 mb-10"
						style="height: fit-content; margin: auto 0"
					>
						{{ "lang_total" | language }} : {{ collection_size }}
					</div>
					<div
						class="col-lg-5 col-sm-5 mb-10"
						style="display: flex; justify-content: center"
					>
						<ngb-pagination
							class="m-auto"
							[collectionSize]="collection_size"
							[rotate]="true"
							[ellipses]="false"
							[maxSize]="3"
							[boundaryLinks]="true"
							[(page)]="page_no"
							[pageSize]="page_size"
							(pageChange)="getSnagNoteList()"
						></ngb-pagination>
					</div>
					<div
						class="col-lg-3 col-sm-3"
						style="display: flex; justify-content: end; margin: auto"
					>
						<mat-select
							[(ngModel)]="page_size"
							(selectionChange)="getSnagNoteList()"
						>
							<mat-option class="text-center" [value]="10"
								>10 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" [value]="20"
								>20 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" [value]="50"
								>50 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" [value]="100"
								>100 {{ "lang_per_page" | language }}</mat-option
							>
							<mat-option class="text-center" [value]="500"
								>500 {{ "lang_per_page" | language }}</mat-option
							>
						</mat-select>
					</div>
				</div>
			</div>
			<no-data
				style="margin: auto"
				[Opened]="
					snagNoteList.length < 1 && user_type_id != '1' && user_type_id != '4'
				"
			></no-data>
		</div>
	</div>
	<mat-accordion
		*ngIf="contractor_reservation || system_team_reservation"
		class="mt-3"
	>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>
					{{ "lang_reserve_the_transaction" | language }}
				</mat-panel-title>
			</mat-expansion-panel-header>
			<div class="p-3">
				<div class="row">
					<div class="col-12 p-2">
						<mat-checkbox [(ngModel)]="reserve_transaction">{{
							"lang_reserve_the_transaction" | language
						}}</mat-checkbox>
					</div>
					<div class="col-12">
						<div class="table-responsive dropdownyes customResponsive">
							<table border="1" class="table CustomTable">
								<thead>
									<tr>
										<td>{{ "lang_note_id" | language }}</td>
										<td>{{ "lang_unit" | language }}</td>
										<td>{{ "lang_note" | language }}</td>
										<td>{{ "lang_work_level" | language }}</td>
										<td>{{ "lang_description" | language }}</td>
										<td>{{ "lang_status" | language }}</td>
										<td>{{ "lang_writer" | language }}</td>
										<td>{{ "lang_created_date" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="notesForReserve.length" class="AppendList">
									<tr
										*ngFor="
											let note of notesForReserve;
											let note_index = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
									>
										<td>{{ note.note_lists_id }}</td>
										<td>{{ note.general_number }}</td>
										<td>{{ note.notename }}</td>
										<td>{{ note.wlevel }}</td>
										<td>{{ note.level_description }}</td>
										<td>{{ note.status }} ({{ note?.action_step_code }})</td>
										<td>{{ note.writer }}</td>
										<td>{{ note.created_datetime }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="col-12 col-sm-9 d-none d-sm-block"></div>
					<div class="col-12 col-sm-3 mt-2">
						<button
							type="button"
							class="albutton mnabrbutton mat-accent"
							mat-button
							(click)="reserveAction()"
						>
							{{ "lang_submit" | language }}
						</button>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>
