import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuService } from "@core/bootstrap/menu.service";

@Component({
	selector: "breadcrumb",
	templateUrl: "./breadcrumb.component.html",
	styleUrls: ["./breadcrumb.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit {
	crumpclass = "chevron_right";
	@Input() nav: string[] = [];
	lang_key = localStorage.getItem("lang_key") || "en";
	constructor(
		private router: Router,
		private menu: MenuService,
		private route: ActivatedRoute
	) {}
	ngOnInit() {
		this.nav = Array.isArray(this.nav) ? this.nav : [];
		if (this.nav.length === 0) {
			this.genBreadcrumb();
		}
		this.crumpclass =
			localStorage.getItem("direction") == "rtl"
				? "arrow_back"
				: "arrow_forward";
	}
	trackByNavlink(index: number, navlink: string): string {
		return navlink;
	}
	genBreadcrumb() {
		if (this.router.url.slice(1).split("?")) {
			let withoutQuery = this.router.url.slice(1).split("?")[0];
			var states = withoutQuery.split("/");
		} else {
			var states = this.router.url.slice(1).split("/");
		}

		this.nav = this.menu.getMenuLevel(states);
		if (this.nav.length <= 0) {
			this.nav = states;
		}
		this.nav.unshift("home");
	}
}
