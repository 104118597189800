import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { AddExternalPersonComponent } from "./add-external-person/add-external-person.component";

@Component({
	selector: "app-external-persons",
	templateUrl: "./external-persons.component.html",
	styleUrls: ["./external-persons.component.scss"],
})
export class ExternalPersonsComponent implements OnInit {
	admin = JSON.parse(this.ls.getItem("is_admin"));
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	pepage_no = 1;
	pepage_size = "10";
	pedata: any = [];
	lodingdatas = this.lang.transform("lang_loading");
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService,
		private sweetalert: SweetAlertService
	) {}
	ngOnInit(): void {
		this.get_external_persons();
	}

	public loadpage(page) {
		this.pepage_no = page;
		this.pepage_size = this.pepage_size;
		this.get_external_persons();
	}
	public loadpagesize(size) {
		this.pepage_no = 1;
		this.pepage_size = size;
		this.get_external_persons();
	}
	public load_external_persons() {
		this.pepage_no = 1;
		this.pepage_size = "10";
		this.get_external_persons();
	}
	public get_external_persons() {
		this.spinner.show();
		this.ds
			.postActionByUrl(
				{},
				"tasks/get_data_person_extarnal/" +
					this.pepage_no +
					"/" +
					this.pepage_size
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.pedata = res;
					} else {
						this.pedata = [];
						this.lodingdatas = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.pedata = [];
					this.lodingdatas =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}

	onAddUpdateExternalPersons(data?) {
		const dialogRef = this.dialog.open(AddExternalPersonComponent, {
			width: "600px",
			data: {
				fromTicket: false,
				epData: data,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.get_external_persons();
			}
		});
	}

	deleteExternalPerson(id) {
		let formData = new FormData();
		formData.append("person_id", id || "");
		this.spinner.show();
		this.ds.post("tasks/delete_person_extarnal", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.get_external_persons();
				} else {
					this.sweetalert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetalert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
