import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-user-group",
	templateUrl: "./user-group.component.html",
	styleUrls: ["./user-group.component.scss"],
})
export class UserGroupComponent implements OnInit {
	usersFormGroup: FormGroup;
	group_type_list = [
		{
			label: this.lang.transform("lang_system_groups"),
			value: "1",
		},
		{
			label: this.lang.transform("lang_user_groups"),
			value: "2",
		},
	];
	group_list = [];
	group_users = [];
	constructor(
		private fb: FormBuilder,
		private spinner: PreloaderService,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private dialogRef: MatDialogRef<UserGroupComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit(): void {
		this.buildForm();
		if (this.data) {
			this.usersFormGroup
				.get("group_type_id")
				.setValue(
					this.data?.group_type_id ? `${this.data?.group_type_id}` : "1"
				);
			this.usersFormGroup.get("group_id").setValue(this.data?.group_id);
		}
		this.getGroups();
	}

	buildForm() {
		this.usersFormGroup = this.fb.group({
			group_type_id: ["1", Validators.required],
			group_id: [null, Validators.required],
		});
	}

	getGroups() {
		this.spinner.show();
		let formData = new FormData();
		formData.append(
			"type",
			this.usersFormGroup.get("group_type_id").value || ""
		);
		this.ds.post("tasks/get_group_by_type", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.group_list = res?.data;
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
					this.group_list = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.group_list = [];
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

	getGroupById() {
		this.spinner.show();
		let formData = new FormData();
		let url = "tasks/get_group";
		if (this.usersFormGroup.get("group_type_id").value == "2") {
			url = "tasks/get_for_user_group";
		} else {
			url = "tasks/get_group";
		}
		formData.append(
			"group_id",
			this.usersFormGroup.get("group_id").value || ""
		);
		this.ds.post(url, formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.group_users = res?.data?.users;
					this.dialogRef.close({
						status: true,
						users: this.group_users,
						group_id: this.usersFormGroup.get("group_id").value,
						group_type_id: this.usersFormGroup.get("group_type_id").value,
					});
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

	onSubmit() {
		this.getGroupById();
	}
}
