import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-risk-register",
  templateUrl: "./risk-register.component.html",
  styleUrls: ["./risk-register.component.scss"],
})
export class RiskRegisterComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key")
    ? localStorage.getItem("lang_key")
    : "en";
  report_Data;
  no_of_closed_risks = 0;
  no_of_open_risks = 0;
  no_of_risks_cause_issue = 0;
  @Input() set report_data(data: any) {
    this.report_Data = data;
    data?.risk_counts.forEach((v) => {
      console.log(v);
      console.log(data);
      if (v?.risk_status == "open") {
        this.no_of_open_risks = v?.total;
      }
      if (v?.risk_status == "cause_issue") {
        this.no_of_risks_cause_issue = v?.total;
      }
      if (v?.risk_status == "close") {
        this.no_of_closed_risks = v?.total;
      }
    });
  }
  constructor() {}

  ngOnInit(): void {}
}
