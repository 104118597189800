<div  >
	<div mat-dialog-title>
		<div class="d-flex justify-content-between">
			<h4>{{ 'lang_violation_history' | language }}</h4>
			<span (click)="closeModal()"><i class="fa fa-times"></i></span>
		</div>
	</div>
	<mat-dialog-content class="mat-typography">
	<!-- 	<div *ngIf="verifylist.length > 0">
			<h4>
				{{ "lang_complete_verification" | language }}
			</h4>
		</div> -->

		<div class="row  mb-2 mt-2"  >
            <div class="col-md-12 pad0">
                <div class="table-responsive customResponsive">
                    <table border="1" class="table CustomTable" *ngIf="violationsArr.length != 0" >
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>{{ "lang_transaction_request_id" | language }}</td>
                                <td>{{ "lang_violation_date_en" | language }}</td>
                                <td>{{ "lang_violation_count" | language }}</td>
                                <td>{{ "lang_punishment_en" | language }}</td>
                                <td>{{ "lang_violation" | language }}</td>
                            <!-- 	<td>{{ "lang_credlang_debit_amountit_amount" | language }}</td> -->
                                 
                            </tr>
                        </thead>
                        <tbody  class="AppendList">
                            <tr
                                *ngFor="
                                    let vio of violationsArr;
                                    let i = index;
                                    let odd = odd;
                                    let even = even
                                "
                                [ngClass]="{ odd: odd, even: even }"
                            >
                                <td>{{ i + 1 }}</td>
                                <td>{{ vio?.transaction_request_id  }} </td>
                                <td>{{ vio?.violation_date_en }}</td>
                                <td>{{ vio?.violation_count }}  </td>
                                <td>{{ vio?.punishment_en }}</td>
                                <td  matTooltip="{{ vio?.violation_en }} ">{{ vio?.violation_en | slice:0:30 }}  </td>
                     
                         
                            </tr>
                        </tbody>

                    </table>
                    <no-data style="margin: auto;"  [Opened]="violationsArr.length < 1 "></no-data>

                </div>
            </div>
        </div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
	 
		<div class="col-md-4 col-xs-12"></div>
		<div class="col-md-4 col-xs-12">
			<button
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				(click)="closeModal()"
			>
				{{ "lang_close" | language }}
			</button>
		</div>
	</mat-dialog-actions>
</div>
