import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-tedit',
  templateUrl: './tedit.component.html',
  styleUrls: ['./tedit.component.scss']
})
export class TeditComponent implements OnInit {
  form_key = this.route.snapshot.paramMap.get('form_key');
  form_request_id = this.route.snapshot.paramMap.get('form_request_id');
  edit_type = this.route.snapshot.paramMap.get('type') || '';
  constructor(public route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.form_key = param.form_key;
      this.form_request_id = param.form_request_id;
      this.edit_type = param.type || '';
    });
  }
}
