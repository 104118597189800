import { Injectable } from '@angular/core';
declare var $:any;
@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  private selector = '#angular_loader';
  constructor() {}
  hide() {    
    $(this.selector).css("display", "none");
  }
  show() {
    $(this.selector).css("display", "flex");
  }
}
