<div class="wafi-report">
	<div class="wafi-report-title mb-4">
		<h4>{{ "lang_wafi_report" | language }}</h4>
	</div>
	<mat-accordion>
		<mat-expansion-panel class="mb-2" [expanded]="true">
			<mat-expansion-panel-header
				>{{ "lang_wafi_report" | language }}
			</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_report_date" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.report_date || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_project_code" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.Projects_work_area_code ||
										"---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_project_name" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.projects_profile_name ||
										"---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_city" | language }}</p>
							<p class="text-center">
								{{ report_data?.report_work_area_info?.city || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_region" | language }}</p>
							<p class="text-center">
								{{ report_data?.report_work_area_info?.region || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_land_ownership" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.land_ownership || "---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_land_area" | language }}</p>
							<p class="text-center">
								{{ report_data?.report_work_area_info?.land_area || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_construction_budget_cost" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.total_construction_cost ||
										"---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_customer" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.report_work_area_info?.customer_name || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_branch" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.report_work_area_info?.branch_name || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_contractor" | language }}</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.contractor_name || "---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_zones" | language }}</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.total_no_of_zones || "---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_blocks" | language }}</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.total_no_of_blocks ||
										"---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_buildings" | language }}</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.no_of_buildings || "---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_units" | language }}</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.total_no_of_units || "---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_type_of_units" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.report_work_area_info?.type_of_units || "---" }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
		<mat-expansion-panel class="mb-2">
			<mat-expansion-panel-header
				>{{ "lang_program_of_work" | language }}
			</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_start_date" | language }}</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.supervision_start_date ||
										"---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_end_date" | language }}</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.supervision_expiry_date ||
										"---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_contractual_duration" | language }} ({{
									"lang_month" | language
								}})
							</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.contractual_duration ||
										"---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_eot" | language }} ({{ "lang_day" | language }})
							</p>
							<p class="text-center">
								{{ report_data?.report_work_area_info?.eot || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_revised_completion_date" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.revised_completion_date ||
										"---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_actual_start_date" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info?.actual_start_date || "---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_forecast_completion_date" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.report_work_area_info
										?.forecast_completion_date || "---"
								}}
							</p>
						</div>
						<div class="col-12">
							<hr />
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_monthly_planned_progress" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.actual_planned_progress?.monthly_total_plan ||
										"---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_monthly_actual_progress" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.actual_planned_progress?.monthly_actual_total ||
										"---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_variance" | language }}</p>
							<p class="text-center">
								{{ report_data?.actual_planned_progress?.variances || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_cumulative_planned_progress" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.wafi_report?.cumulative_planned_progress || "---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_cumulative_actual_progress" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.wafi_report?.cumulative_actual_progress || "---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_delay" | language }}</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.delay || "---" }}
							</p>
						</div>
						<div class="col-12">
							<hr />
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_monthly_actual_cost" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.monthly_actual_cost || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_cumulative_actual_cost" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.cumulative_actual_cost || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_SPI" | language }}</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.report_monthly_spi || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_CPI" | language }}</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.report_monthly_cpi || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_status_timewise" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.project_timewise_status || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_status_costwise" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.project_costwise_status || "---" }}
							</p>
						</div>
						<div class="col-12">
							<hr />
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_monthly_planned_manpower" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.wafi_report?.monthly_planned_manpower || "---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_monthly_actual_manpower" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.monthly_actual_manpower || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_reasons_of_delay" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.wafi_report?.project_reasons_of_delay || "---"
								}}
							</p>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
		<mat-expansion-panel class="mb-2">
			<mat-expansion-panel-header
				>{{ "lang_project_plan" | language }}
			</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-12 col-sm-6 col-md-4 mb-3">
							<div class="card-item">
								<div class="item-text" style="width: 70%">
									<h5>{{ "lang_sum_total_plan" | language }}</h5>
									<h4>{{ sum_total_plan }}</h4>
								</div>
								<div class="item-icon">
									{{ last_date_plan }}
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-4 mb-3">
							<div class="card-item">
								<div class="item-text" style="width: 70%">
									<h5>{{ "lang_sum_actual_total" | language }}</h5>
									<h4>{{ sum_actual_total }}</h4>
								</div>
								<div class="item-icon">
									{{ last_date_plan }}
								</div>
							</div>
						</div>
						<div class="col-12">
							<div id="axis3-chart" class="axis3-chart"></div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
		<mat-expansion-panel class="mb-2">
			<mat-expansion-panel-header
				>{{ "lang_quality_management" | language }}
			</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_plan_status" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.wafi_report?.quality_management_plan_status ||
										"---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_procedures_forms_templates" | language }}
							</p>
							<p class="text-center">
								{{
									report_data?.wafi_report?.procedures_forms_templates || "---"
								}}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">{{ "lang_ITP" | language }}</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.report_itp || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_monthly_planned_engineers" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.total_engineer || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_monthly_planned_inspectors" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.total_inspector || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_actual_engineers" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.actual_inspectors || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_actual_inspectors" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.actual_inspectors || "---" }}
							</p>
						</div>
						<div class="col-12 col-sm-4 col-md-3">
							<p class="bold text-center">
								{{ "lang_method_statements" | language }}
							</p>
							<p class="text-center">
								{{ report_data?.wafi_report?.method_statements || "---" }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>
