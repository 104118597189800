<div class="card supervision_contacts">
	<div class="card-header">{{ "lang_contacts" | language }}</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-md-3 col-sm-4 col-xs-12 mb-10">
					<select
						matTooltip="{{ 'lang_type' | language }}"
						[(ngModel)]="contactsType"
						(change)="spinner.show(); search_supervision_contacts()"
						class="form-control almnabrformcontrol contacts_type"
						id="contacts_type"
					>
						<option value="">{{ "lang_all" | language }}</option>
						<option value="1">{{ "lang_contractors" | language }}</option>
						<option value="2">{{ "lang_customers" | language }}</option>
						<option value="3">{{ "lang_others" | language }}</option>
					</select>
				</div>
				<div class="col-md-9 col-sm-8 col-xs-12 mb-10">
					<div class="input-group input-group-sm">
						<input
							type="text"
							class="form-control almnabrformcontrol"
							[(ngModel)]="searchKey"
							(keyup)="search_supervision_contacts()"
							placeholder="{{ 'lang_search' | language }}"
						/>
						<span class="input-group-btn" *ngIf="able2add">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_add' | language }}"
								class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
								(click)="emptyform(); openModal(createupdatecontact)"
								*ngIf="makeAction"
							></button>
						</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check_all' | language }}"
										>
											<input
												type="checkbox"
												class="allItems checkbox"
												id="AllItemsCon"
												(click)="checkAllItemsCon($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ "lang_name" | language }}</td>
									<td>{{ "lang_type" | language }}</td>
									<td>{{ "lang_email" | language }}</td>
									<td>{{ "lang_mobile" | language }}</td>
									<td>{{ "lang_whatsapp" | language }}</td>
									<td>{{ "lang_fax" | language }}</td>
									<td>{{ "lang_writer" | language }}</td>
									<td>{{ "lang_ondate" | language }}</td>
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody *ngIf="cListData.length != '0'" class="AppendList">
								<tr
									*ngFor="
										let list of cListData.records;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
									class="makeback removerow{{ list.contacts_id }}"
								>
									<td>{{ i + 1 }}</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check' | language }}"
										>
											<input
												type="checkbox"
												class="eachItemCon checkbox"
												value="{{ list.contacts_id }}"
												(click)="checkEachItemCon($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ list.contacts_name }}</td>
									<td *ngIf="list.contacts_type == '1'">
										{{ "lang_contractors" | language }}
									</td>
									<td *ngIf="list.contacts_type == '2'">
										{{ "lang_customers" | language }}
									</td>
									<td *ngIf="list.contacts_type == '3'">
										{{ "lang_others" | language }}
									</td>
									<td>{{ list.contacts_email }}</td>
									<td>{{ list.contacts_mobile }}</td>
									<td *ngIf="list.contacts_whatsapp">
										{{ list.contacts_whatsapp }}
									</td>
									<td *ngIf="!list.contacts_whatsapp">----</td>
									<td *ngIf="list.contacts_fax">{{ list.contacts_fax }}</td>
									<td *ngIf="!list.contacts_fax">----</td>
									<td>{{ list.writer }}</td>
									<td>{{ list.contacts_created_datetime }}</td>
									<td>
										<i
											*ngIf="makeAction"
											matTooltip="{{ 'lang_edit' | language }}"
											(click)="
												emptyEditform(list); openModal(createupdatecontact)
											"
											class="fa fa-pencil-square-o fa-icon mnabricon"
										></i>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="cListData.length == '0'" class="AppendList">
								<tr class="odd">
									<td colspan="11" align="center">{{ cnodata }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="cListData?.records?.length != '0'" style="padding: 0 15px">
			<div class="row responsive_pagination">
				<div
					*ngIf="makeAction"
					class="deleteIconDiv none col-lg-1 col-sm-1 mb-10"
				>
					<i
						class="fa fa-trash-o faicon red deleteIcon"
						(click)="deleteBulkDataCon()"
						aria-hidden="true"
						matTooltip="{{ 'lang_delete' | language }}"
					></i>
				</div>
				<div
					class="col-lg-3 col-sm-3 mb-10"
					style="height: fit-content; margin: auto 0"
				>
					{{ "lang_total" | language }} : {{ cListData?.page?.total_records }}
				</div>
				<div
					class="col-lg-5 col-sm-5 mb-10"
					style="display: flex; justify-content: center"
				>
					<ngb-pagination
						class="m-auto"
						[collectionSize]="cListData?.page?.total_records"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="cpageno"
						[pageSize]="cpagesize"
						(pageChange)="load_supervision_contacts_page($event)"
					></ngb-pagination>
				</div>
				<div
					class="col-lg-3 col-sm-3"
					style="display: flex; justify-content: end; margin: auto"
				>
					<mat-select
						[(ngModel)]="cpagesize"
						(selectionChange)="load_supervision_contacts_pagesize($event.value)"
					>
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #createupdatecontact>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_contacts" | language }}</h4>
			<button type="button" class="close" data-dismiss="modal">&times;</button>
		</div>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div class="modal-body">
				<div class="form-group">
					<label for="contacts_type"
						>{{ "lang_contact_type" | language }}:<span class="red"
							>*</span
						></label
					>
					<select
						class="form-control almnabrformcontrol"
						formControlName="contacts_type"
					>
						<option value="">{{ "lang_choose_options" | language }}</option>
						<option value="1">{{ "lang_contractors" | language }}</option>
						<option value="2">{{ "lang_customers" | language }}</option>
						<option value="3">{{ "lang_others" | language }}</option>
					</select>
					<mat-error
						*ngIf="
							form.controls['contacts_type'].touched &&
							form.controls['contacts_type'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="contacts_name"
						>{{ "lang_name" | language }}:<span class="red">*</span></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="contacts_name"
						placeholder="{{ 'lang_name' | language }}"
					/>
					<mat-error
						*ngIf="
							form.controls['contacts_name'].touched &&
							form.controls['contacts_name'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="contacts_email"
						>{{ "lang_email" | language }}:<span class="red">*</span></label
					>
					<input
						type="email"
						class="form-control almnabrformcontrol"
						formControlName="contacts_email"
						placeholder="{{ 'lang_email' | language }}"
					/>
					<mat-error
						*ngIf="
							form.controls['contacts_email'].touched &&
							form.controls['contacts_email'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="contacts_mobile"
						>{{ "lang_mobile" | language }}:<span class="red"
							>* (966550000000)</span
						></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="contacts_mobile"
						minlength="12"
						maxlength="12"
						placeholder="{{ 'lang_mobile' | language }}"
					/>
					<mat-error
						*ngIf="
							form.controls['contacts_mobile'].touched &&
							form.controls['contacts_mobile'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="contacts_whatsapp"
						>{{ "lang_whatsapp" | language }}: (966550000000)</label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						minlength="12"
						maxlength="12"
						formControlName="contacts_whatsapp"
						placeholder="{{ 'lang_whatsapp' | language }}"
					/>
				</div>
				<div class="form-group">
					<label for="contacts_fax">{{ "lang_fax" | language }}: </label>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="contacts_fax"
						placeholder="{{ 'lang_fax' | language }}"
					/>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
