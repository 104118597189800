import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";
import { ExcelHistoryComponent } from "./excel-history/excel-history.component";

@Component({
  selector: "app-opening-balance",
  templateUrl: "./opening-balance.component.html",
  styleUrls: ["./opening-balance.component.scss"],
})
export class OpeningBalanceComponent implements OnInit {
  start: number;
  end: number;
  branches: any = [];
  finances: any = [];
  branch_id: any =
    localStorage.getItem("selectedBranch") != null
      ? localStorage.getItem("selectedBranch")
      : "0";
  finance_id: any = null;
  searchKey: any = "";
  key_search: any = "";
  subscriptions = new Subscription();
  openingBalance = [];
  balanceForm: FormGroup;
  balancesFormArray: FormArray;
  totalDebit: any;
  totalCredit: any;
  page = 1;
  size = "10";
  totalbalanceRecord: any;
  history_data = [];
  allOpeningData = [];
  constructor(
    public ds: DataService,
    public router: Router,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private sweetAlert: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.load_branches();
    this.buildForm();
  }

  buildForm() {
    this.balanceForm = this.fb.group({
      balances: this.fb.array([]),
    });
    this.balancesFormArray = this.balanceForm.get("balances") as FormArray;
  }
  get balances() {
    return this.balanceForm.get("balances") as FormArray;
  }
  public balancesRecords(value?): FormGroup {
    return this.fb.group({
      account_masters_code: new FormControl(
        value ? value?.account_masters_id : "",
        Validators.required
      ),
      account_name: new FormControl(
        value ? value?.account_name : "",
        Validators.required
      ),
      account_masters_id: new FormControl(
        value ? value?.account_masters_id : "",
        [Validators.required]
      ),
      debit_amount: new FormControl(value ? value?.debit_amount : ""),
      credit_amount: new FormControl(value ? value?.credit_amount : ""),
    });
  }

  addBalancesRecord(value?) {
    this.balancesFormArray.push(this.balancesRecords(value));
  }

  load_branches() {
    this.spinner.show();
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
            this.load_financial_years();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }

  onSelectBranch(branch) {
    this.finances = [];
    this.finance_id = "";
    if (branch && branch != "0") {
      localStorage.setItem("selectedBranch", branch);
      this.branch_id = branch;
      this.load_financial_years();
    }
  }

  load_financial_years() {
    this.finances = [];
    this.finance_id = null;
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
              }
            });
          }
          this.getOpeningBalance();
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }

  getOpeningBalance() {
    this.balancesFormArray.clear();
    let formData = new FormData();
    formData.append("search_key", this.searchKey || "");
    formData.append("page_no", `${this.page}` || "");
    formData.append("page_size", `${this.size}` || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        `accounts/get_accounts_for_opening/${this.branch_id}/${this.finance_id}`
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.balancesFormArray.controls = [];
            this.totalbalanceRecord = res?.total_records;
            for (let item of res?.records) {
              if (this.history_data.length > 0) {
                const foundindex = this.history_data.findIndex(
                  (element) =>
                    element.account_masters_id == item.account_masters_id
                );
                if (foundindex > -1) {
                  this.addBalancesRecord(this.history_data[foundindex]);
                } else {
                  this.addBalancesRecord(item);
                }
              } else {
                this.addBalancesRecord(item);
              }
              this.updateAllData(item);
            }
            this.calcTotal();
          } else {
            this.balancesFormArray.clear();
          }
        },
        (err) => {
          this.spinner.hide();
          this.balancesFormArray.clear();
        }
      );
  }
  onChangeDebitCredit(index, type) {
    if (
      type == "debit" &&
      this.balancesFormArray.controls[index].get("debit_amount").value
    ) {
      this.balancesFormArray.controls[index]
        .get("credit_amount")
        .setValue("0.00");
      let debit = this.balancesFormArray.controls[index]
        .get("debit_amount")
        ?.value?.replaceAll(",", "");
      this.balancesFormArray.controls[index]
        .get("debit_amount")
        .setValue(debit ? this.convertNumToCurrency(debit) : "0.00");
    } else if (
      type == "credit" &&
      this.balancesFormArray.controls[index].get("credit_amount").value
    ) {
      this.balancesFormArray.controls[index]
        .get("debit_amount")
        .setValue("0.00");
      let debit = this.balancesFormArray.controls[index]
        .get("credit_amount")
        ?.value?.replaceAll(",", "");
      this.balancesFormArray.controls[index]
        .get("credit_amount")
        .setValue(debit ? this.convertNumToCurrency(debit) : "0.00");
    }
  }

  calcTotal() {
    let debitTot = 0;
    let creditTot = 0;
    this.balancesFormArray.controls.forEach((el) => {
      let debAmount = el?.get("debit_amount").value?.replaceAll(",", "");
      let credAmount = el?.get("credit_amount").value?.replaceAll(",", "");
      debitTot += +debAmount;
      creditTot += +credAmount;
    });
    this.totalDebit = this.convertNumToCurrency(debitTot);
    this.totalCredit = this.convertNumToCurrency(creditTot);
    // this.totalDebit = parseFloat(`${debitTot}`).toFixed(2);
    // this.totalCredit = parseFloat(`${creditTot}`).toFixed(2);
  }

  setCursorPosition(elementId) {
    let input = document.getElementById(elementId) as HTMLInputElement;
    let value = input?.value;

    // Find the position of the decimal point
    let decimalIndex = value?.indexOf(".");

    // Set the cursor position to the end if there is no decimal point
    let cursorPosition = value?.length;

    // Set the cursor position to the integer section if a decimal point exists
    if (decimalIndex !== -1) {
      cursorPosition = decimalIndex;
    }

    // Set the cursor position
    input.setSelectionRange(cursorPosition, cursorPosition);
  }

  export_exl() {
    let param = new FormData();
    param.set("search_key", this.searchKey || "");
    this.spinner.show();
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          param,
          `accounts/accounts_for_opening_excal/${this.branch_id}/${this.finance_id}/${this.page}/${this.size}`
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.downloadFile(res, "opening_excal");
            } else {
              this.sweetAlert.errorToast(res?.error, 2000);
            }
          },
          (error) => {
            this.spinner.hide();
            this.sweetAlert.errorToast(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error"),
              2000
            );
          }
        )
    );
  }

  file: File | undefined;

  onFileChange(event: any) {
    this.file = event.target.files[0];
    const formData = new FormData();
    formData.append("branch_id", this.branch_id || "");
    formData.append("finance_id", this.finance_id || "");
    formData.append("file_excal", this.file || "");
    this.spinner.show();
    this.ds
      .postActionByUrl(formData, "accounts/upload_opening_excal")
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.sweetAlert.successToast(
              this.lang.transform("lang_success"),
              2000
            );
          } else {
            this.sweetAlert.errorToast(res?.error, 2000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
  }

  downloadFile(data, title?, type?) {
    if (data.status) {
      let url = data.base64;
      let tagA = document.createElement("a");
      document.body.appendChild(tagA);
      tagA.style.display = "none";
      tagA.href = url;
      if (type == "blank") {
        tagA.setAttribute("target", "_blank");
      }
      tagA.download = title || "Almnabr";
      tagA.click();
      document.body.removeChild(tagA);
    }
  }

  onSubmit() {
    // this.start = (this.page - 1) * Number(this.size);
    // if (this.balances.controls?.length > this.page * Number(this.size)) {
    //   this.end = this.page * Number(this.size);
    // } else {
    //   this.end = this.balances.controls?.length;
    // }
    this.start = 0;
    this.end = this.balances.controls?.length;
    let formData = new FormData();
    formData.append("branch_id", this.branch_id || "");
    formData.append("finance_id", this.finance_id || "");
    let updatedData = this.allOpeningData?.filter((el) => {
      return el?.debit_amount !== "0.00" || el?.credit_amount !== "0.00";
    });
    updatedData.forEach((el, key) => {
      formData.append(
        `balances[${key}][account_masters_id]`,
        el?.account_masters_id || ""
      );
      formData.append(`balances[${key}][debit_amount]`, el?.debit_amount || "");
      formData.append(
        `balances[${key}][credit_amount]`,
        el?.credit_amount || ""
      );
    });
    this.spinner.show();
    this.ds
      .postActionByUrl(formData, "accounts/update_accounts_for_opening")
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.sweetAlert.successToast(
              this.lang.transform("lang_success"),
              2000
            );
          } else {
            this.sweetAlert.errorToast(res?.error, 2000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
  }
  addHistoryData(data) {
    const foundindex = this.history_data.findIndex(
      (element) => element.account_masters_id == data.account_masters_id
    );
    if (foundindex > -1) {
      this.history_data[foundindex] = data;
    } else {
      this.history_data.push(data);
    }
  }

  updateAllData(item) {
    const foundindex = this.allOpeningData.findIndex(
      (element) => element.account_masters_id == item.account_masters_id
    );
    if (foundindex > -1) {
      const historyItem = this.history_data.find(
        (el) => el?.account_masters_id == item?.account_masters_id
      );
      if (historyItem) {
        this.allOpeningData[foundindex] = historyItem;
      } else {
        this.allOpeningData[foundindex] = item;
      }
    } else {
      this.allOpeningData.push(item);
    }
  }

  onUpdateAmountNumber() {}

  convertNumToCurrency(number) {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 3,
      minimumFractionDigits: 2,
    }).format(number);
  }

  export_exl_pdf(type) {
    this.spinner.show();
    let param = new FormData();
    param.set("branch_id", this.branch_id);
    param.set("finance_id", this.finance_id ? this.finance_id : "0");
    param.set("export_type", type);
    param.set("account_report_type", "OBL");
    this.ds.export_account_reports(param, type);
  }
  showExcelHistory() {
    const dialogRef = this.dialog.open(ExcelHistoryComponent, {
      width: "800px",
      autoFocus: false,
      data: {
        finance_id: this.finance_id,
        branch_id: this.branch_id,
        key_search: this.searchKey,
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
