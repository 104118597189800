<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno" [@fadeIn]>
    <mat-accordion class="all_applications" >
        <mat-expansion-panel class="mpanel" [expanded]="true">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_human_resources_jobapp' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <select class="form-control almnabrformcontrol" [(ngModel)]="searchStatus" matTooltip="{{'lang_status' | language }}" (change)="spinner.show();get_job_app();">
                                <option value="ALL">{{'lang_all' | language }}</option>
                                <option value="2">{{'lang_pending' | language }}</option>
                                <option value="3">{{'lang_rejected' | language }}</option>
                                <option value="4">{{'lang_waiting' | language }}</option>
                            </select>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 mb-10">
                            <input type="text" class="form-control almnabrformcontrol" (keyup)="get_job_app();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsApp checkbox" id="allItemsApp" (click)="checkAllApp($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_human_resources_employee_name' | language }}</td>
                                            <td>{{'lang_human_resources_id_number' | language }}</td>
                                            <td>{{'lang_mobile' | language }}</td>
                                            <td>{{'lang_email' | language }}</td>
                                            <td>{{'lang_status' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="wholddata.length != '0' " class="AppendList">
                                        <tr *ngFor="let emp of wholddata?.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{emp.employee_number}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemApp checkbox" value="{{emp.employee_number}}" (click)="checkEachItemApp($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td><a matTooltip="{{'lang_view' | language }}" routerLink="/human_resources/applicationview/{{emp.employee_number}}/view/">{{emp.name}}</a>
                                            </td>
                                            <td><a matTooltip="{{'lang_view' | language }}" routerLink="/human_resources/applicationview/{{emp.employee_number}}/view/">{{emp.employee_id_number}}</a>
                                            </td>
                                            <td>{{emp.primary_mobile}}</td>
                                            <td>{{emp.primary_email}}</td>
                                            <td *ngIf="emp.employee_status == '2'">
                                                <i class="fa fa-icon fa-circle yellowcolor" matTooltip="{{'lang_pending' | language }}" aria-hidden="true"></i>
                                            </td>
                                            <td *ngIf="emp.employee_status == '3'">
                                                <i class="fa fa-icon fa-circle red" matTooltip="{{'lang_rejected' | language }}" aria-hidden="true"></i>
                                            </td>
                                            <td *ngIf="emp.employee_status == '4'">
                                                <i class="fa fa-icon fa-circle blue" matTooltip="{{'lang_waiting' | language }}" aria-hidden="true"></i>
                                            </td>
                                            <td class="dropdown">
                                                <mat-icon class="mnabricon" [matMenuTriggerFor]="menu">settings</mat-icon>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item routerLink="/human_resources/applications/jop-offer/{{emp.employee_number}}"><span>{{'lang_jop_offer' | language }}</span></button>
                                                    <button mat-menu-item routerLink="/human_resources/applicationview/{{emp.employee_number}}/view"><span>{{'lang_view' | language }}</span></button>
                                                    <button mat-menu-item (click)="openModelA(notesForm);get_my_notes(emp.employee_number);employeename = emp.name;"><span>{{'lang_notes' | language }}</span></button>
                                                    <button mat-menu-item *ngIf="emp.employee_status != '3'" (click)="openModelB(interviewForm);setvalue(emp.employee_number);employeename = emp.name;"><span>{{'lang_arange_interview' | language }}</span></button>
                                                    <button mat-menu-item (click)="openModelC(actionForm);makeAction(emp.employee_number, emp.employee_status);employeename = emp.name;"><span>{{'lang_action' | language }}</span></button>
                                                </mat-menu>                                               
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="wholddata.length == '0'" class="AppendList">
                                        <tr class="odd">
                                            <td colspan="8" align="center" class="nodata">{{errormessagef}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="wholddata?.records?.length != '0'" class="card-footer padb0">
                    <div class="row responsive_pagination">
                        <div *ngIf="ds.allowMe('human_resources', 'human_resources_delete')" class="deleteIconDiv none col-lg-1 col-sm-1 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataApp()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-3  mb-10" style="height: fit-content;margin: auto 0;">
                            {{'lang_total' | language }} : {{wholddata?.page?.total_records}}
                        </div>
                        <div class="col-lg-5 col-sm-5 mb-10"  style="display: flex;justify-content: center;">
                           <!--  <mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="10" [checked]="(size =='10') ? true : false" (click)="spinner.show();loadbypagesize('10')"> 10 </mat-radio-button>
                                <mat-radio-button value="20" [checked]="(size =='20') ? true : false" (click)="spinner.show();loadbypagesize(20)"> 20 </mat-radio-button>
                                <mat-radio-button value="50" [checked]="(size =='50') ? true : false" (click)="spinner.show();loadbypagesize(50)"> 50 </mat-radio-button>
                                <mat-radio-button value="100" [checked]="(size =='100') ? true : false" (click)="spinner.show();loadbypagesize(100)"> 100 </mat-radio-button>
                                <mat-radio-button value="500" [checked]="(size =='500') ? true : false" (click)="spinner.show();loadbypagesize(500)"> 500 </mat-radio-button>
                            </mat-radio-group> -->

                            <ngb-pagination class="m-auto" [collectionSize]="wholddata?.page?.total_records" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="page" [pageSize]="size" (pageChange)="spinner.show();loadbypagelength($event)"></ngb-pagination>
                        </div>
                        <div class="col-lg-3 col-sm-3 mb-10" style="display: flex;justify-content: end;margin: auto;">
                            <!-- {{'lang_goto' | language }} :
                            <select class="customselect" (change)="spinner.show();loadbypagelength($event.target.value)">
                                <option *ngFor="let k of ds.getrange(wholddata.page.total_pages)" [selected]="(page == k) ? true : false" value="{{k}}">{{k}}</option>
                            </select> -->

                            <mat-select  [(ngModel)]="size" (selectionChange)="spinner.show();loadbypagesize($event.value)">
                                     
                                <!-- <mat-option value="1">1  </mat-option> -->
                                <mat-option class="text-center" value="10">10 {{'lang_per_page'|language}} </mat-option>
                                <mat-option class="text-center" value="20">20 {{'lang_per_page'|language}}  </mat-option>
                                <mat-option class="text-center" value="50">50 {{'lang_per_page'|language}}  </mat-option>
                                <mat-option class="text-center" value="100">100 {{'lang_per_page'|language}}</mat-option>
                                <mat-option class="text-center" value="500">500 {{'lang_per_page'|language}}</mat-option>
                              </mat-select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<ng-template #interviewForm>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_arange_interview' | language }} ( {{employeename}} )</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefB.hide();">&times;</button>
        </div>
        <form class="interviewform" [formGroup]="interviewform" (ngSubmit)="arrange_interview()">
            <div class="modal-body">
                <div class="form-group">
                    <label for="interview_date_en">{{'lang_interview_date_en' | language }} : <span class="red">*</span></label>
                    <div class="input-group input-group-sm">
                        <input [matDatepicker]="interview_date_en" formControlName="interview_date_en" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly (dateChange)="ds.setENTOARDate($event, interviewform.get('interview_date_ar'));">
                        <span class="input-group-btn">
                            <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="interview_date_en"></mat-datepicker-toggle>
                            <mat-datepicker #interview_date_en></mat-datepicker>
                        </span>
                        <span class="input-group-btn">
                            <button (click)="interviewform.get('interview_date_en').setValue('');interviewform.get('interview_date_ar').setValue(null); " matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                        </span>
                    </div>                    
                    <mat-error *ngIf="interviewform.controls['interview_date_en'].touched && interviewform.controls['interview_date_en'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="interview_date_ar">{{'lang_interview_date_ar' | language }} : <span class="red">*</span></label>
                    <input formControlName="interview_date_ar" ngbDatepicker [readonly]="true" (ngModelChange)="ds.setARTOENDate($event, interviewform.get('interview_date_en'));" (click)="idarab.toggle()" #idarab="ngbDatepicker" required id="interview_date_ar" type="text" class="form-control almnabrformcontrol interview_date_ar" placeholder="YYYY/MM/DD" />
                    <mat-error *ngIf="interviewform.controls['interview_date_ar'].touched && interviewform.controls['interview_date_ar'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefB.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #notesForm>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_notes' | language }} ( {{employeename}} )</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefA.hide();">&times;</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <input type="text" class="form-control almnabrformcontrol" (keyup)="search_my_notes()" [(ngModel)]="nsearchKey" placeholder="{{'lang_search' | language }}">
                    </div>
                </div>
                <div class="col-12">
                    <div class="table-responsive dropdownyes customResponsive">
                        <table border="1" class="table CustomTable">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>{{'lang_description' | language }}</td>
                                    <td>{{'lang_remainder_date' | language }}</td>
                                    <td>{{'lang_writer' | language }}</td>
                                    <td>{{'lang_action' | language }}</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="notedata.length != '0'" class="AppendList">
                                <tr *ngFor="let note of notedata.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }">
                                    <td>{{i+1}}</td>
                                    <td><span class="breakall">{{note.note_description}}</span></td>
                                    <td *ngIf="note.note_remainder_status == '1'">{{note.note_remainder_date}}</td>
                                    <td *ngIf="note.note_remainder_status == '0'">----</td>
                                    <td>{{note.name}}</td>
                                    <td>
                                        <i *ngIf="ds.allowMe('human_resources','human_resources_edit')" matTooltip="{{'lang_edit' | language }}" class="fa fa-pencil-square-o fa-icon mnabricon" (click)="editnotes(note)"></i>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="notedata.length == '0'" class="AppendList">
                                <tr class="odd">
                                    <td colspan="5" align="center">{{noteerrormsg}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-12">
                    <div *ngIf="notedata?.records?.length != '0'" class="card-footer padb0 bottomfooter">
                        <div class="row responsive_pagination">
                            <div class="col-md-3 col-sm-3  mb-10" style="height: fit-content;margin: auto 0;">
                                {{'lang_total' | language }} : {{notedata?.page?.total_records}}
                            </div>
                            <div class="col-md-6 col-sm-6  mb-10" style="display: flex;justify-content: center;">
                                <!-- <mat-radio-group aria-label="Select an option">
                                    <mat-radio-button value="10" checked (click)="spinner.show();notepage(10)"> 10</mat-radio-button>
                                    <mat-radio-button value="20" (click)="spinner.show();notepage(20)"> 20 </mat-radio-button>
                                    <mat-radio-button value="50" (click)="spinner.show();notepage(50)"> 50 </mat-radio-button>
                                    <mat-radio-button value="100" (click)="spinner.show();notepage(100)"> 100 </mat-radio-button>
                                    <mat-radio-button value="500" (click)="spinner.show();notepage(500)"> 500 </mat-radio-button>
                                </mat-radio-group> -->
                                <ngb-pagination class="m-auto" [collectionSize]="notedata?.page?.total_records" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="npage" [pageSize]="npsize" (pageChange)="spinner.show();notelength($event)"></ngb-pagination>

                            </div>
                            <div class="col-md-3 col-sm-3  mb-10" style="display: flex;justify-content: end;margin: auto;">
                              <!--   {{'lang_goto' | language }} :
                                <select class="customselect" (change)="spinner.show();notelength($event.target.value)">
                                    <option *ngFor="let k of ds.getrange(notedata?.page?.total_pages)" value="{{k}}">{{k}}</option>
                                </select> -->

                                
                            <mat-select  style="max-width: 150px;"  [(ngModel)]="npsize" (selectionChange)="spinner.show();notepage($event.value)">
                             
                                <!-- <mat-option value="1">1  </mat-option> -->
                                <mat-option class="text-center" value="10">10 {{'lang_per_page'|language}} </mat-option>
								<mat-option class="text-center" value="20">20 {{'lang_per_page'|language}}  </mat-option>
								<mat-option class="text-center" value="50">50 {{'lang_per_page'|language}}  </mat-option>
								<mat-option class="text-center" value="100">100 {{'lang_per_page'|language}}</mat-option>
								<mat-option class="text-center" value="500">500 {{'lang_per_page'|language}}</mat-option>
							  </mat-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form class="noteform" [formGroup]="noteform" (ngSubmit)="save_notes()">
                        <input type="hidden" formControlName="note_id" />
                        <input type="hidden" formControlName="id" />
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea type="text" required formControlName="note_description" class="form-control almnabrformcontrol inputastarea" placeholder="{{'lang_notes' | language }}"></textarea>
                                    <mat-error *ngIf="noteform.controls['note_description'].touched && noteform.controls['note_description'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-xs-12" [ngClass]="{'col-md-4' : needremainderdate, 'col-md-6' : !needremainderdate}">
                                <div class="form-group">
                                    <label for="show_status">{{'lang_status' | language }} : <span class="red">*</span></label>
                                    <select required formControlName="show_status" class="form-control almnabrformcontrol">
                                        <option value="">{{'lang_choose_options' | language }}</option>
                                        <option value="0">{{'lang_private' | language }}</option>
                                        <option value="1">{{'lang_public' | language }}</option>
                                    </select>
                                </div>
                                <mat-error *ngIf="noteform.controls['show_status'].touched && noteform.controls['show_status'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                            </div>
                            <div class="col-sm-12 col-xs-12" [ngClass]="{'col-md-4' : needremainderdate, 'col-md-6' : !needremainderdate}">
                                <div class="form-group">
                                    <label for="note_remainder_status">{{'lang_remainder_status' | language }} : <span class="red">*</span></label>
                                    <select required formControlName="note_remainder_status" (ngModelChange)="show_remainder_date($event)" class="form-control almnabrformcontrol">
                                        <option value="">{{'lang_choose_options' | language }}</option>
                                        <option value="0">{{'lang_no' | language }}</option>
                                        <option value="1">{{'lang_yes' | language }}</option>
                                    </select>
                                </div>
                                <mat-error *ngIf="noteform.controls['note_remainder_status'].touched && noteform.controls['note_remainder_status'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                            </div>
                            <div class="col-sm-12 col-xs-12" *ngIf="needremainderdate" [ngClass]="{'col-md-4' : needremainderdate, 'col-md-6' : !needremainderdate}">
                                <div class="form-group">
                                    <label for="note_remainder_date">{{'lang_remainder_date' | language }} : <span class="red">*</span></label>
                                    <div class="positionrelatiove">
                                        <input formControlName="note_remainder_date" class="form-control almnabrformcontrol" [readonly]="true" [matDatepicker]="Rdate" placeholder="YYYY/MM/DD" />
                                        <mat-datepicker-toggle matSuffix [for]="Rdate" class="posiab"></mat-datepicker-toggle>
                                        <mat-datepicker #Rdate disabled="false"></mat-datepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-10">
                                <app-alert id="default-alert"></app-alert>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-12 col-xs-12 mb-10">
                                <button type="submit" [disabled]="!noteform.valid" class="albutton">{{'lang_submit' | language }}</button>
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12 mb-10">
                                <button type="button" class="albutton" (click)="resetnoteform()">{{'lang_reset' | language }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modalRefA.hide();">{{'lang_close' | language }}</button>
        </div>
    </div>
</ng-template>
<ng-template #actionForm>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_check_app_status' | language }} ( {{employeename}} )</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefC.hide();">&times;</button>
        </div>
        <form class="actionForm" [formGroup]="actionform" (ngSubmit)="make_action()">
            <div class="modal-body">
                <input type="hidden" formControlName="employee_number" required />
                <div class="form-group">
                    <label for="employee_status"> {{'lang_status' | language }} <span class="red">*</span></label>
                    <select class="form-control almnabrformcontrol" required formControlName="employee_status" matTooltip="{{'lang_status' | language }}" (ngModelChange)="get_data_for_action();">
                        <option value="1">{{'lang_approve' | language }}</option>
                        <option value="2">{{'lang_pending' | language }}</option>
                        <option value="3">{{'lang_rejected' | language }}</option>
                        <option value="4">{{'lang_waiting' | language }}</option>
                    </select>
                    <mat-error *ngIf="actionform.controls['employee_status'].touched && actionform.controls['employee_status'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group" *ngIf=" actionform.get('employee_status').value === '1' ">
                    <label for="branch_id"> {{'lang_branch' | language }} <span class="red">*</span></label>
                    <select class="form-control almnabrformcontrol" formControlName="branch_id" matTooltip="{{'lang_branch' | language }}">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option *ngFor="let branch of branches" value="{{branch.value}}">{{branch.label}}
                        </option>
                    </select>
                    <mat-error *ngIf="actionform.controls['branch_id'].touched && actionform.controls['branch_id'].errors?.required && actionform.get('employee_status').value == 1">{{'lang_field_required' | language }}</mat-error>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefC.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>        
    </div>
</ng-template>