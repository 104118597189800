import { Component, Inject, OnInit } from '@angular/core';
 
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
 
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-violation-history',
  templateUrl: './violation-history.component.html',
  styleUrls: ['./violation-history.component.scss']
})
export class ViolationHistoryComponent implements OnInit {
  modalRefA: BsModalRef;
  
 
  allData:any;
 
  violationsArr=[]
  
  constructor(public lang:LanguagePipe,
    public spinner : PreloaderService, public alert:AlertService, public router:Router, private dialogRef: MatDialogRef<ViolationHistoryComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data) {
       
      this.violationsArr = this.data.violationsArr;
       
    }

  ngOnInit(): void {
    
  }
 
 

  closeModal() {
    this.dialogRef.close();
  }
}
