import { Component, Input, OnInit } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { special_approval_notes } from "app/routes/transactions/FORM_PS_TR1/formdata";
import { SendCodeComponent } from "app/routes/transactions/FORM_PS_TR1/send-code/send-code.component";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
	selector: "app-bonus-step-six",
	templateUrl: "./step-six.component.html",
	styleUrls: ["./step-six.component.scss"],
})
export class BonusStepSixComponent implements OnInit {
	@Input() form_key;
	@Input() form_request_id;
	@Input() project_data;
	@Input() supervision_data;
	@Input() template_data;
	@Input() allData;

	submitForm: boolean = false;
	sendCodeForm: any;
	sendCodeStatus: boolean = false;
	rejectedValidators = [];
	stepName = "HUMAN_RESOURCE_MANAGER";
	form: FormGroup;
	users: any = [];

	myData: any = [];

	constructor(
		public ds: DataService,
		public router: Router,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.myData = special_approval_notes;
		this.form = new FormGroup({
			transaction_request_id: new FormControl(this.form_request_id, [
				Validators.required,
			]),
			approved: new FormControl("", [Validators.required]),
			notes: new FormControl("", []),
			transactions_persons_action_code: new FormControl("", [
				Validators.required,
			]),
		});
	}

	get getNotes() {
		return this.form.get("notes");
	}

	checkStatus() {
		if (this.form.get("approved").value == "0") {
			this.rejectedValidators = [Validators.required];
			this.getNotes?.setValidators([Validators.required]);

			this.getNotes?.updateValueAndValidity();
		} else {
			this.rejectedValidators = [];
			this.getNotes?.setValidators([]);

			this.getNotes?.updateValueAndValidity();
		}
	}
	public completeStep3() {
		this.submitForm = true;
		if (this.form.valid) {
			this.spinner.show();
			let formData = new FormData();
			formData.append(
				"transaction_request_id",
				this.form.value.transaction_request_id
			);
			formData.append("approving_status", this.form.value.approved);
			if (this.form?.value?.approved) {
				formData.append("note", this.form.value.notes);
			}

			formData.append(
				"transactions_persons_action_code",
				this.form.value.transactions_persons_action_code
			);

			this.ds.post(`/form/${this.form_key}/sr`, formData).subscribe(
				(data) => {
					if (data.status) {
						this.spinner.hide();
						this.alert.success(data.msg);
						this.submitForm = false;

						let cmData = new FormData();
						cmData.append(
							"transaction_request_id",
							this.form.value.transaction_request_id
						);
						cmData.append(
							"vacation_approved_status",
							this.form.value.approved == "Approve" ? "1" : "0"
						);

						setTimeout(() => {
							location.reload();
						}, 2000);
					} else {
						this.spinner.hide();
						this.alert.error(data?.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(error);
				}
			);
		} else {
			this.alert.error(this.lang.transform("lang_all_fields_required"));
		}
	}

	openModal() {
		const dialogRef = this.dialog.open(SendCodeComponent, {
			disableClose: true,
			width: "400px",
			data: {
				transaction_persons_type: "signature",
				allData: this.allData,
				stepName: this.stepName,
			},
		});
		dialogRef.afterClosed().subscribe((data) => {
			this.sendCodeForm = data?.formValue;
			this.sendCodeStatus = data?.sendstatus;
		});
	}
}
