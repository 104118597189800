import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root'
})
export class LanguageService implements OnDestroy {
  constructor(
    private ds: DataService,
  ) {}
  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptions.add(
        this.ds.get('languages').pipe(
            catchError(res => {
              resolve(null);
              return throwError(res);
            })
          ).subscribe(
            (res: any) => {
              if(res.status) {
                this.ds.languages = res.languages;
              }
            },
            () => {
              reject();
            },
            () => {
              resolve(null);
            }
          )
        )
      ;
    });
  }

  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
