import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import moment from "moment";

@Component({
	selector: "app-account-history-log",
	templateUrl: "./account-history-log.component.html",
	styleUrls: ["./account-history-log.component.scss"],
})
export class AccountHistoryLogComponent implements OnInit {
	@Input() account_operation: string;
	@Input() id: string;
	history = [];
	pageNo = 1;
	pageSize = "10";
	total_records = 0;
	logActionList = [];
	log_action = null;
	dateRange: FormGroup;

	constructor(private ds: DataService, private spinner: PreloaderService) {
		this.logActionList = [
			{
				label: "Insert",
				value: "insert",
			},
			{
				label: "Update",
				value: "update",
			},
			{
				label: "Delete",
				value: "delete",
			},
		];
	}

	ngOnInit(): void {
		moment.locale("en");
		this.dateRange = new FormGroup({
			log_action_start_date: new FormControl(null),
			log_action_end_date: new FormControl(null),
		});
		this.getAccountHistory();
	}

	getAccountHistory() {
		let formData = new FormData();
		formData.append("log_action", this.log_action || "");
		formData.append("account_operation", this.account_operation || "");
		formData.append(
			"log_action_start_date",
			this.dateRange.get("log_action_start_date").value
				? moment(this.dateRange.get("log_action_start_date").value).format(
						"YYYY/MM/DD"
				  )
				: ""
		);
		formData.append(
			"log_action_end_date",
			this.dateRange.get("log_action_end_date").value
				? moment(this.dateRange.get("log_action_end_date").value).format(
						"YYYY/MM/DD"
				  )
				: ""
		);
		formData.append("id", this.id || "");
		this.spinner.show();
		this.ds
			.post(
				`get_accounts_history_log/${this.pageNo}/${this.pageSize}`,
				formData
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.history = res?.records;
						this.total_records = res?.page?.total_records;
					} else {
						this.history = [];
					}
				},
				(err) => {
					this.spinner.hide();
					this.history = [];
				}
			);
	}

	resetFilter() {
		this.dateRange.reset();
		this.log_action = null;
		this.getAccountHistory();
	}
}
