<div mat-dialog-title>
	<div class="templates-header">
		<span mat-dialog-close>
			<svg fill="#fff" viewBox="0 0 24 24" width="24" height="24">
				<path
					d="m19.1 17.2-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"
				></path>
			</svg>
		</span>
		<h1>Templates</h1>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="mbrl15">
		<div class="row">
			<div class="col-12 templates-title-container">
				<h3 class="templates-title">Templates</h3>
				<!-- <div style="width: 100px; position: relative">
					<input
						type="text"
						class="form-control mb-1"
						id="unit_id"
						name="unit_id"
						placeholder="Limit"
						(keypress)="ds?.isNumber($event)"
						[(ngModel)]="templatesLimit"
					/>
					<i
						class="fa fa-search mnabricon"
						(click)="getAllTemplates()"
						[attr.style]="
							lang_key == 'ar'
								? 'position: absolute; left: 7px;right:unset; top: 9px; font-size: 18px'
								: 'position: absolute; right: 7px;left:unset; top: 9px; font-size: 18px'
						"
					></i>
				</div> -->
			</div>
			<div class="col-12 templates-list-container">
				<div class="templates-list">
					<div
						class="templates-list-item"
						*ngFor="let template of templateList"
						(click)="onTemplateSelected(template)"
					>
						<div class="cli">
							<p>
								<!-- <ng-container *ngFor="let item of template?.components">
									<span *ngIf="item?.type == 'HEADER'" class="cli-header">{{
										item?.text
									}}</span>
									<span
										*ngIf="item?.type == 'BODY' && item?.text?.length >= 45"
										class="cli-body"
										matTooltip="{{ item?.text }}"
										>{{ item?.text | shortenLength: 45 }}</span
									>
									<span
										*ngIf="item?.type == 'BODY' && item?.text?.length < 45"
										class="cli-body"
										>{{ item?.text | shortenLength: 45 }}</span
									>
									<span *ngIf="item?.type == 'FOOTER'" class="cli-footer">{{
										item?.text
									}}</span>
								</ng-container> -->
								{{ template?.label }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
