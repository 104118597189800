<div class="bold text-center timeDiv">
	{{ livedate }}
	<span id="livetime" class="txt-time">{{ time | date: "mediumTime" }}</span> (
	{{ msg }} )
</div>
<div
	class="matero-container-wrap mnabrdashboard"
	[ngClass]="{
		'matero-sidenav-collapsed':
			options.sidenavCollapsed && options.navPos !== 'top',
		'matero-navbar-side': options.navPos === 'side',
		'matero-navbar-top': options.navPos === 'top',
		'matero-header-above': options.headerPos === 'above',
		'matero-header-fixed': options.headerPos === 'fixed',
		'matero-header-white': options.theme === 'light',
		contentbodyrtl: direction == 'rtl',
		contentbodyltr: direction == 'ltr'
	}"
	[dir]="options.dir"
>
	<ng-progress></ng-progress>
	<!-- Header Above -->
	<app-header
		*ngIf="options.showHeader && options.headerPos === 'above'"
		(toggleSidenav)="sidenav.toggle()"
		(toggleSidenavNotice)="sidenavNotice.toggle()"
		[showBranding]="true"
	>
	</app-header>

	<mat-sidenav-container class="matero-container" autosize autoFocus>
		<mat-sidenav
			#sidenav
			class="matero-sidenav"
			[mode]="isOver ? 'over' : 'side'"
			[opened]="options.navPos === 'side' && options.sidenavOpened && !isOver"
			(openedChange)="sidenavOpenedChange($event)"
			(closedStart)="sidenavCloseStart()"
		>
			<app-sidebar
				[showToggle]="!isOver"
				[showUser]="options.showUserPanel"
				[showHeader]="options.headerPos !== 'above'"
				(toggleCollapsed)="toggleCollapsed()"
				[toggleChecked]="options.sidenavCollapsed"
			>
			</app-sidebar>
		</mat-sidenav>

		<mat-sidenav #sidenavNotice position="end" mode="over">
			<!-- <app-sidebar-notice [notifications]="notifications" [notifications_number]="notifications_number" (getNotificationsEvent)="getNotifications()"></app-sidebar-notice> -->
		</mat-sidenav>

		<mat-sidenav-content #content class="matero-content-wrap">
			<app-header
				*ngIf="options.showHeader && options.headerPos !== 'above'"
				[showToggle]="!options.sidenavCollapsed && options.navPos !== 'top'"
				[showBranding]="options.navPos === 'top'"
				(toggleSidenav)="sidenav.toggle()"
				(toggleSidenavNotice)="sidenavNotice.toggle()"
			>
			</app-header>

			<app-topmenu *ngIf="options.navPos === 'top'"></app-topmenu>

			<div
				class="matero-content"
				style="padding: 0; min-height: calc(100vh - 95px);"
			>
				<div style="padding: 16px; padding-bottom: 30px">
					<router-outlet></router-outlet>
				</div>
				<app-footer></app-footer>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>

	<!-- Demo Purposes Only -->
	<app-customizer (optionsChange)="receiveOptions($event)"></app-customizer>
</div>
