<form class="outgoing" [formGroup]="form" (ngSubmit)="submitForm()">
  <breadcrumb></breadcrumb>
  <section class="panel-expansion">
    <mat-accordion class="stylepanels">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_insert_outgoing" | language
        }}</mat-expansion-panel-header>
        <div class="mbrl15">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_subject" | language }}
                  <span class="red">*</span></label
                >
                <input
                  type="text"
                  formControlName="subject"
                  placeholder="{{ 'lang_subject' | language }}"
                  class="form-control almnabrformcontrol"
                />
                <mat-error
                  *ngIf="submitted && form.controls['subject'].errors?.required"
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <label> {{ "lang_attachments" | language }} </label>
                <input
                  type="text"
                  formControlName="attachmentstitle"
                  placeholder="{{ 'lang_attachments' | language }}"
                  class="form-control almnabrformcontrol"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_languages" | language }}
                  <span class="red">*</span></label
                >
                <ng-select
                  [items]="languages"
                  [multiple]="false"
                  bindLabel="label"
                  bindValue="value"
                  clearAllText="Clear"
                  appendTo="body"
                  formControlName="lang_key"
                  placeholder="{{ 'lang_languages' | language }}"
                  (change)="changeEditorDir($event)"
                >
                </ng-select>
                <mat-error
                  *ngIf="
                    submitted && form.controls['lang_key'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="!branchId">
              <div class="form-group">
                <label>
                  {{ "lang_from" | language }} <span class="red">*</span></label
                >
                <ng-select
                  [items]="branches"
                  [multiple]="false"
                  bindLabel="label"
                  bindValue="value"
                  clearAllText="Clear"
                  appendTo="body"
                  formControlName="transaction_from"
                  placeholder="{{ 'lang_from' | language }}"
                >
                </ng-select>
                <mat-error
                  *ngIf="
                    submitted &&
                    form.controls['transaction_from'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_to_organization" | language }}
                  <span class="red">*</span></label
                >
                <input
                  type="text"
                  formControlName="transaction_to"
                  placeholder="{{ 'lang_to_organization' | language }}"
                  class="form-control almnabrformcontrol"
                />
                <mat-error
                  *ngIf="
                    submitted &&
                    form.controls['transaction_to'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_file_secutrity_levels" | language }}
                  <span class="red">*</span></label
                >
                <ng-select
                  [items]="filepermissionlevels"
                  [multiple]="true"
                  bindLabel="label"
                  appendTo="body"
                  bindValue="value"
                  clearAllText="Clear"
                  placeholder="{{ 'lang_file_secutrity_levels' | language }}"
                  formControlName="level_keys"
                >
                </ng-select>
                <mat-error
                  *ngIf="
                    submitted && form.controls['level_keys'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>
          </div>
          <div
            class="row mb-10"
            [ngClass]="{ ' contentrtl': lang_key == 'ar' }"
          >
            <div class="col-12">
              <label>
                {{ "lang_description" | language }}
                <span class="red">*</span></label
              >
              <div class="ckeditor-container">
                <ckeditor
                  #editor
                  id="editor1"
                  name="editor1"
                  [config]="config"
                  (ready)="onCkEditorReady($event)"
                  formControlName="content"
                  [ngClass]="{ ' contentrtl': lang_key == 'ar' }"
                ></ckeditor>
              </div>
              <mat-error
                *ngIf="submitted && form.controls['content'].errors?.required"
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 col-sm-3 col-xs-12 mb-10" *ngIf="!markstatus">
              <button
                class="albutton mnabrbutton mat-accent"
                mat-button
                (click)="enable_field('mark')"
              >
                <i class="fa fa-plus"></i> {{ "lang_need_mark" | language }}
              </button>
            </div>
            <div
              class="col-md-2 col-sm-3 col-xs-12 mb-10"
              *ngIf="!reviewstatus"
            >
              <button
                class="albutton mnabrbutton mat-accent"
                mat-button
                (click)="enable_field('review')"
              >
                <i class="fa fa-plus"></i> {{ "lang_need_review" | language }}
              </button>
            </div>
            <div
              class="col-md-2 col-sm-3 col-xs-12 mb-10"
              *ngIf="!attachstatus"
            >
              <button
                class="albutton mnabrbutton mat-accent"
                mat-button
                (click)="enable_field('attachment')"
              >
                <i class="fa fa-plus"></i>
                {{ "lang_need_attachment" | language }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <fieldset class="cardback fieldset">
                <legend>{{ "lang_signature_stamp" | language }}</legend>
                <div class="row">
                  <div class="col-md-3 col-sm-3 col-xs-12">
                    <div class="form-group">
                      <label>
                        {{ "lang_human_resources_job_title_iqama" | language }}
                        A
                      </label>
                      <input
                        type="text"
                        formControlName="signature[A][title]"
                        placeholder="{{
                          'lang_human_resources_job_title_iqama' | language
                        }} A"
                        class="form-control almnabrformcontrol"
                      />
                      <mat-error
                        *ngIf="
                          submitted &&
                          form.controls['signature[A][title]'].errors?.required
                        "
                      >
                        {{ "lang_field_required" | language }}
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12">
                    <div class="form-group">
                      <label>
                        {{ "lang_human_resources_job_title_iqama" | language }}
                        B
                      </label>
                      <input
                        type="text"
                        formControlName="signature[B][title]"
                        placeholder="{{
                          'lang_human_resources_job_title_iqama' | language
                        }} B"
                        class="form-control almnabrformcontrol"
                      />
                      <mat-error
                        *ngIf="
                          submitted &&
                          form.controls['signature[B][title]'].errors?.required
                        "
                      >
                        {{ "lang_field_required" | language }}
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12"></div>
                  <div class="col-md-3 col-sm-3 col-xs-12">
                    <div class="form-group">
                      <label>
                        {{ "lang_human_resources_job_title_iqama" | language }}
                        C
                      </label>
                      <input
                        type="text"
                        formControlName="signature[C][title]"
                        placeholder="{{
                          'lang_human_resources_job_title_iqama' | language
                        }} C"
                        class="form-control almnabrformcontrol"
                      />
                      <mat-error
                        *ngIf="
                          submitted &&
                          form.controls['signature[C][title]'].errors?.required
                        "
                      >
                        {{ "lang_field_required" | language }}
                      </mat-error>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 col-sm-3 col-xs-12">
                    <div class="form-group">
                      <label>
                        {{ "lang_human_resources_employee_name" | language }} A
                      </label>
                      <ng-select
                        [items]="users"
                        [multiple]="false"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                        [searchFn]="ds.customSearchFn"
                        appendTo="body"
                        formControlName="signature[A][user_id]"
                        placeholder="{{ 'lang_users' | language }}"
                        (search)="search_users($event)"
                        [notFoundText]="'lang_no_data' | language"
                        (focus)="users = []"
                      >
                      </ng-select>
                      <mat-error
                        *ngIf="
                          submitted &&
                          form.controls['signature[A][user_id]'].errors
                            ?.required
                        "
                      >
                        {{ "lang_field_required" | language }}
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12">
                    <div class="form-group">
                      <label>
                        {{ "lang_human_resources_employee_name" | language }} B
                      </label>
                      <ng-select
                        [items]="users"
                        [multiple]="false"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                        [searchFn]="ds.customSearchFn"
                        appendTo="body"
                        formControlName="signature[B][user_id]"
                        placeholder="{{ 'lang_users' | language }}"
                        (search)="search_users($event)"
                        [notFoundText]="'lang_no_data' | language"
                        (focus)="users = []"
                      >
                      </ng-select>
                      <mat-error
                        *ngIf="
                          submitted &&
                          form.controls['signature[B][user_id]'].errors
                            ?.required
                        "
                      >
                        {{ "lang_field_required" | language }}
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12"></div>
                  <div class="col-md-3 col-sm-3 col-xs-12">
                    <div class="form-group">
                      <label>
                        {{ "lang_human_resources_employee_name" | language }} C
                      </label>
                      <ng-select
                        [items]="users"
                        [multiple]="false"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                        [searchFn]="ds.customSearchFn"
                        appendTo="body"
                        formControlName="signature[C][user_id]"
                        placeholder="{{ 'lang_users' | language }}"
                        (search)="search_users($event)"
                        [notFoundText]="'lang_no_data' | language"
                        (focus)="users = []"
                      >
                      </ng-select>
                      <mat-error
                        *ngIf="
                          submitted &&
                          form.controls['signature[C][user_id]'].errors
                            ?.required
                        "
                      >
                        {{ "lang_field_required" | language }}
                      </mat-error>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="row" *ngIf="markstatus || reviewstatus">
            <div class="col-md-4 col-xs-12 col-sm-4 mb-10">
              <fieldset class="cardback fieldset" *ngIf="markstatus">
                <input type="hidden" formControlName="needMark" />
                <legend>
                  {{ "lang_markers" | language }}
                  <span class="red"
                    >*
                    <mat-icon
                      class="red mnabricon"
                      matTooltip="{{ 'lang_close' | language }}"
                      (click)="disable_field('mark')"
                      >close</mat-icon
                    ></span
                  >
                </legend>
                <ng-select
                  [items]="users"
                  [multiple]="true"
                  bindLabel="label"
                  bindValue="value"
                  clearAllText="Clear"
                  [searchFn]="ds.customSearchFn"
                  appendTo="body"
                  formControlName="marks"
                  placeholder="{{ 'lang_users' | language }}"
                  (search)="search_users($event)"
                  (focus)="users = []"
                >
                </ng-select>
                <mat-error
                  *ngIf="submitted && form.controls['marks'].errors?.required"
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </fieldset>
            </div>
            <div class="col-md-4 col-xs-12 col-sm-4 mb-10">
              <fieldset class="cardback fieldset" *ngIf="reviewstatus">
                <input type="hidden" formControlName="needReview" />
                <legend>
                  {{ "lang_reviewers" | language }}
                  <span class="red"
                    >*
                    <mat-icon
                      class="red mnabricon"
                      matTooltip="{{ 'lang_close' | language }}"
                      (click)="disable_field('review')"
                      >close</mat-icon
                    ></span
                  >
                </legend>
                <ng-select
                  [items]="users"
                  [multiple]="true"
                  bindLabel="label"
                  bindValue="value"
                  clearAllText="Clear"
                  [searchFn]="ds.customSearchFn"
                  appendTo="body"
                  formControlName="reviews"
                  placeholder="{{ 'lang_users' | language }}"
                  (search)="search_users($event)"
                  (focus)="users = []"
                >
                </ng-select>
                <mat-error
                  *ngIf="submitted && form.controls['reviews'].errors?.required"
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </fieldset>
            </div>
          </div>
          <input type="hidden" formControlName="needAttach" />
          <div class="row" *ngIf="attachstatus">
            <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
              <fieldset class="cardback fieldset">
                <legend>
                  {{ "lang_attachments" | language }}
                  <span class="red"
                    >*
                    <mat-icon
                      class="red mnabricon"
                      (click)="disable_field('attachment')"
                      >close</mat-icon
                    ></span
                  >
                  <mat-icon
                    class="mnabricon"
                    matTooltip="{{ 'lang_add' | language }}"
                    (click)="addfiles()"
                    >add_box</mat-icon
                  >
                </legend>
                <div class="table-responsive customResponsive">
                  <table
                    border="1"
                    class="table CustomTable"
                    width="100%"
                    formArrayName="attachments"
                  >
                    <thead>
                      <tr>
                        <td style="padding: 5px; word-break: break-all">#</td>
                        <td style="padding: 5px; word-break: break-all">
                          {{ "lang_title" | language }}
                        </td>
                        <td style="padding: 5px; word-break: break-all">
                          {{ "lang_file" | language }}
                        </td>
                        <td style="padding: 5px; word-break: break-all">
                          {{ "lang_attach_with_pdf" | language }}
                        </td>
                        <td style="padding: 5px; word-break: break-all">
                          {{ "lang_official_paper" | language }}
                        </td>
                        <td style="padding: 5px; word-break: break-all">
                          {{ "lang_action" | language }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let file of filesFormGroup.controls;
                          let i = index;
                          let odd = odd;
                          let even = even
                        "
                        [ngClass]="{ odd: odd, even: even }"
                        [formGroupName]="i"
                      >
                        <td
                          class="verticalalignmid"
                          style="padding: 5px; word-break: break-all"
                        >
                          {{ i + 1 }}
                        </td>
                        <td
                          style="
                            padding: 5px;
                            word-break: break-all;
                            position: relative;
                          "
                        >
                          <input
                            type="text"
                            formControlName="attach_title"
                            placeholder="{{ 'lang_description' | language }}"
                            autocomplete="off"
                            class="form-control almnabrformcontrol"
                          />
                          <mat-error
                            *ngIf="
                              submitted &&
                              this.attachments.controls[i].get('attach_title')
                                .errors?.required
                            "
                            >{{ "lang_field_required" | language }}</mat-error
                          >
                        </td>
                        <td
                          class="verticalalignmid"
                          style="
                            padding: 5px;
                            word-break: break-all;
                            position: relative;
                          "
                        >
                          <input
                            style="display: block"
                            name="file"
                            type="file"
                            required
                            onclick="this.value = null"
                            (change)="UploadFile($event, i)"
                            class="nghide albutton width100"
                          />
                          <label
                            id="uploadText{{ i }}"
                            class="albutton mnabrbutton mat-accent"
                            mat-button
                            >{{ filename }}
                          </label>
                          <mat-error
                            *ngIf="
                              submitted &&
                              this.attachments.controls[i].get('file').errors
                                ?.required
                            "
                          >
                            {{ "lang_field_required" | language }}</mat-error
                          >
                        </td>
                        <td
                          class="verticalalignmid"
                          style="padding: 5px; word-break: break-all"
                        >
                          <mat-checkbox
                            formControlName="attach_with_the_document"
                            class="checkbox-radio-button"
                          ></mat-checkbox>
                        </td>
                        <td
                          class="verticalalignmid"
                          style="padding: 5px; word-break: break-all"
                        >
                          <mat-checkbox
                            formControlName="print_official_paper"
                            class="checkbox-radio-button"
                          ></mat-checkbox>
                        </td>
                        <td class="verticalalignmid">
                          <mat-icon
                            (click)="removefiles(i)"
                            class="mnabricon red"
                            matTooltip="{{ 'lang_delete' | language }}"
                            >delete_forever</mat-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
              <button
                type="button"
                class="albutton mnabrbutton mat-accent"
                mat-button
                routerLink="/tasks/alltasks"
              >
                {{ "lang_goto_list" | language }}
              </button>
            </div>
            <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
              <button
                type="button"
                class="albutton mnabrbutton mat-accent"
                mat-button
                (click)="previewBeforeCreate()"
              >
                {{ "lang_preview" | language }}
              </button>
            </div>
            <div class="col-md-6 col-sm-3 col-xs-12 mb-10">
              <app-alert id="default-alert"></app-alert>
            </div>
            <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
              <button
                type="submit"
                class="albutton mnabrbutton mat-accent"
                mat-button
              >
                {{ "lang_submit" | language }}
              </button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</form>
