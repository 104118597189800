<section class="panel-expansion matborderno">
  <mat-accordion class="w-store-details">
    <mat-expansion-panel class="mpanel" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_extra_data" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <div class="mbrl15">
          <div class="row mb-3">
            <div class="col-12 col-sm-9"></div>
            <div class="col-12 col-sm-3">
              <button
                class="albutton mnabrbutton mat-accent"
                mat-button
                (click)="onStoreExtraData(extraDataTemp)"
              >
                {{ "lang_update" | language }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="bold">{{ "lang_city" | language }}</p>
              <p style="word-break: break-all">
                {{ extraData?.city || "---" }}
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <p class="bold">{{ "lang_region" | language }}</p>
              <p style="word-break: break-all">
                {{ extraData?.region || "---" }}
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <p class="bold">{{ "lang_land_ownership" | language }}</p>
              <p style="word-break: break-all">
                {{ extraData?.land_ownership || "---" }}
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <p class="bold">{{ "lang_land_area" | language }}</p>
              <p style="word-break: break-all">
                {{ extraData?.land_area || "---" }}
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <p class="bold">{{ "lang_no_of_buildings" | language }}</p>
              <p style="word-break: break-all">
                {{ extraData?.no_of_buildings || "---" }}
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <p class="bold">{{ "lang_type_of_units" | language }}</p>
              <p style="word-break: break-all">
                {{ extraData?.type_of_units || "---" }}
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <p class="bold">{{ "lang_extend_project_days" | language }}</p>
              <p style="word-break: break-all">
                {{ extraData?.extend_project_days || "---" }}
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <p class="bold">
                {{ "lang_revised_constractual_completion_date" | language }}
              </p>
              <p style="word-break: break-all">
                {{ extraData?.revised_constractual_completion_date || "---" }}
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <p class="bold">
                {{ "lang_forecast_completion_date" | language }}
              </p>
              <p style="word-break: break-all">
                {{ extraData?.forecast_completion_date || "---" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
<ng-template #extraDataTemp>
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_extra_data" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <!-- Modal body -->
    <div class="modal-body">
      <form [formGroup]="extraDataForm">
        <div class="row">
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="city">{{ "lang_city" | language }}</label>
              <input
                type="text"
                name="city"
                id="city"
                class="form-control"
                formControlName="city"
              />
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="region">{{ "lang_region" | language }}</label>
              <input
                type="text"
                name="region"
                id="region"
                class="form-control"
                formControlName="region"
              />
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="land_ownership">{{
                "lang_land_ownership" | language
              }}</label>
              <input
                type="text"
                name="land_ownership"
                id="land_ownership"
                class="form-control"
                formControlName="land_ownership"
              />
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="land_area">{{ "lang_land_area" | language }}</label>
              <input
                type="text"
                name="land_area"
                id="land_area"
                class="form-control"
                formControlName="land_area"
              />
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="no_of_buildings">{{
                "lang_no_of_buildings" | language
              }}</label>
              <input
                type="text"
                name="no_of_buildings"
                id="no_of_buildings"
                class="form-control"
                formControlName="no_of_buildings"
              />
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="type_of_units">{{
                "lang_type_of_units" | language
              }}</label>
              <input
                type="text"
                name="type_of_units"
                id="type_of_units"
                class="form-control"
                formControlName="type_of_units"
              />
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="extend_project_days">{{
                "lang_extend_project_days" | language
              }}</label>
              <input
                type="text"
                name="extend_project_days"
                id="extend_project_days"
                class="form-control"
                formControlName="extend_project_days"
              />
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group mt-3">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{
                  "lang_revised_constractual_completion_date" | language
                }}</mat-label>
                <input
                  matInput
                  [matDatepicker]="revised_constractual_completion_date"
                  readonly
                  formControlName="revised_constractual_completion_date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="revised_constractual_completion_date"
                ></mat-datepicker-toggle>
                <mat-datepicker
                  #revised_constractual_completion_date
                ></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group mt-3">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{
                  "lang_forecast_completion_date" | language
                }}</mat-label>
                <input
                  matInput
                  [matDatepicker]="forecast_completion_date"
                  readonly
                  formControlName="forecast_completion_date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="forecast_completion_date"
                ></mat-datepicker-toggle>
                <mat-datepicker #forecast_completion_date></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer justify-content-between">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRef.hide()"
        style="width: 40% !important"
      >
        {{ "lang_close" | language }}
      </button>
      <button
        type="button"
        class="albutton"
        style="width: 40% !important"
        (click)="onSubmit()"
      >
        {{ "lang_submit" | language }}
      </button>
    </div>
  </div>
</ng-template>
