import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddUserGroupComponent } from "./add-group/add-group.component";
import { ViewUserGroupComponent } from "./view-group/view-group.component";

@Component({
	selector: "app-user-groups",
	templateUrl: "./user-groups.component.html",
	styleUrls: ["./user-groups.component.scss"],
})
export class UserGroupsComponent implements OnInit {
	searchKey = "";
	page_no = 1;
	page_size = 10;
	groups = null;
	nodata: string = this.lang.transform("lang_loading");
	constructor(
		private ds: DataService,
		private dialog: MatDialog,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.loadGroups();
	}

	loadGroups() {
		let formData = new FormData();
		formData.append("search_key", this.searchKey || "");
		this.ds
			.post(
				`tasks/get_for_user_data_groups/${this.page_no}/${this.page_size}`,
				formData
			)
			.subscribe(
				(res) => {
					if (res?.status) {
						this.groups = res;
					} else {
						this.groups = null;
						this.nodata = this.lang.transform("lang_no_data");
					}
				},
				(err) => {
					this.groups = null;
					this.nodata = this.lang.transform("lang_internal_server_error");
				}
			);
	}

	onAddGroup() {
		const dialogRef = this.dialog.open(AddUserGroupComponent, {
			width: "450px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.loadGroups();
			}
		});
	}
	onUpdateGroup(group) {
		const dialogRef = this.dialog.open(AddUserGroupComponent, {
			data: {
				group: group,
			},
			width: "450px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.loadGroups();
			}
		});
	}
	onViewGroup(group) {
		const dialogRef = this.dialog.open(ViewUserGroupComponent, {
			width: "600px",
			data: {
				group: group,
			},
		});
	}

	deleteGroup(group) {
		this.sweetAlert
			.confirmMessage(
				this.lang.transform("lang_are_you_sure"),
				this.lang.transform("lang_yes")
			)
			.then((confirmRes) => {
				if (confirmRes.isConfirmed) {
					this.spinner.show();
					this.ds
						.post(`tasks/delete_for_user_group/${group?.group_id}`, {})
						.subscribe(
							(res) => {
								this.spinner.hide();
								if (res?.status) {
									this.sweetAlert.successToast(
										this.lang.transform("lang_deleted"),
										2000
									);
									this.loadGroups();
								} else {
									this.sweetAlert.errorToast(res?.error, 2000);
								}
							},
							(err) => {
								this.spinner.hide();
							}
						);
				}
			});
	}
}
