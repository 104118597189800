import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
declare var $: any;

@Component({
  selector: "app-reports-groups",
  templateUrl: "./reports-groups.component.html",
  styleUrls: ["./reports-groups.component.scss"],
})
export class ReportsGroupsComponent implements OnInit {
  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  isEditMode: boolean;
  selectedData: any;
  openModal(template: TemplateRef<any>, type: string, data?: any) {
    if (type == "edit") {
      this.isEditMode = true;
      let formData = new FormData();
      formData.append("branch_id", this.branch_id || "");
      formData.append("finance_id", this.finance_id || "");
      this.subscriptions.add(
        this.ds
          .getActionByUrl(
            this.ds.formData2string(formData),
            "ac/dyr/view_report_groups/" + data.report_group_id
          )
          .subscribe(
            (res) => {
              this.spinner.hide();
              if (res.status) {
                this.modalRef = this.modalService.show(
                  template,
                  environment.modelconfig
                );
                this.form.patchValue({
                  ...res?.records,
                  group_accounts: res?.accounts?.map((el) => el?.value),
                });
                this.accounts = res?.accounts;
                this.selectedData = data;
              } else {
                this.ds.dialogf("", res.error);
              }
            },
            (error) => {
              this.spinner.hide();
              this.ds.dialogf(
                "",
                error && error.error && error.error.error
                  ? error.error.error
                  : this.lang.transform("lang_internal_server_error")
              );
            }
          )
      );
    } else {
      this.isEditMode = false;
      this.modalRef = this.modalService.show(template, environment.modelconfig);
    }
  }
  @ViewChild("viewASInvModal") dataview;
  @ViewChild("CreateReportGroup") dataedit;
  settings = environment.multipledropdown;
  branch_id: any =
    localStorage.getItem("selectedBranch") != null
      ? localStorage.getItem("selectedBranch")
      : "0";
  reportsGroupsData: any = [];
  branches: any = [];
  selectedBranch = localStorage.getItem("selectedBranch");
  viewdata: any = [];
  paymodes: any = [];
  accounts: any = [];
  financeList: any = [];
  acpage_no = 1;
  acpage_size = "10";
  form: FormGroup;
  mode: string = "";
  lodingdatas = this.lang.transform("lang_loading");
  searchKey = "";
  searchType = "";
  finance_id = null;
  // reportTypes = [
  //   {
  //     label: this.lang.transform("lang_parent_accounts"),
  //     value: "P",
  //   },
  //   {
  //     label: this.lang.transform("lang_specific_accounts"),
  //     value: "S",
  //   },
  // ];
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  ngOnInit(): void {
    this.build_form();
    this.load_branches();
    if (this.branch_id && this.branch_id != "0") {
      this.load_reports_groups();
      this.getFinancialList();
    }
  }
  public changeBranch(branch) {
    this.branch_id = branch;
    this.load_reports_groups();
    this.getFinancialList();
  }
  selectFinance(finance_id) {
    this.finance_id = finance_id;
    this.load_reports_groups();
  }

  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public build_form() {
    this.form = new FormGroup({
      group_name_english: new FormControl("", [Validators.required]),
      group_name_arabic: new FormControl("", [Validators.required]),
      group_accounts: new FormControl("", [Validators.required]),
    });
  }
  public load_reports_groups() {
    let param = new FormData();
    param.append("branch_id", this.branch_id || "");
    param.append("finance_id", this.finance_id || "");
    param.append("search_key", this.searchKey);
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "ac/dyr/get_report_groups_lists/" +
            this.acpage_no +
            "/" +
            this.acpage_size
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.reportsGroupsData = res;
            } else {
              this.reportsGroupsData = [];
              this.lodingdatas = res?.error;
            }
          },
          (error) => {
            this.spinner.hide();
            this.reportsGroupsData = [];
            this.lodingdatas =
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error");
          }
        )
    );
  }

  public loadpagerec(page) {
    this.spinner.show();
    this.acpage_no = page;
    this.acpage_size = this.acpage_size;
    this.load_reports_groups();
  }
  public loadrecpagesize(size) {
    this.spinner.show();
    this.acpage_no = 1;
    this.acpage_size = size;
    this.load_reports_groups();
  }
  public getParentAccounts(event) {
    let param = new FormData();
    param.append("branch_id", this.branch_id || "");
    param.append("finance_id", this.finance_id || "");
    param.append("search_text", event?.term || "");
    this.accounts = [];
    if (this.branch_id) {
      this.subscriptions.add(
        this.ds.post("ac/dyr/search_accounts", param).subscribe(
          (data) => {
            if (data.status) {
              this.accounts = data.records;
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
      );
    }
  }
  public showReportsData(data) {
    this.spinner.show();
    this.viewdata = [];
    let formData = new FormData();
    formData.append("branch_id", this.branch_id || "");
    formData.append("finance_id", this.finance_id || "");
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(formData),
          "ac/dyr/view_report_groups/" + data?.report_group_id
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.modalRef1 = this.modalService.show(this.dataview);
              this.viewdata = res;
            } else {
              this.ds.dialogf("", res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }
  public updateReportsGroups(data) {
    this.spinner.show();
    let formData = new FormData();
    formData.append("branch_id", this.branch_id || "");
    formData.append("finance_id", this.finance_id || "");
    formData.append("report_group_id", data?.report_group_id || "");
    formData.append(
      "group_name_english",
      this.form.get("group_name_english").value || ""
    );
    formData.append(
      "group_name_arabic",
      this.form.get("group_name_arabic").value || ""
    );
    // formData.append(
    //   "report_group_account_type",
    //   this.form.get("report_group_account_type").value || ""
    // );
    formData.append(
      "group_accounts",
      this.form.get("group_accounts").value || ""
    );
    this.subscriptions.add(
      this.ds.post("ac/dyr/update_report_groups", formData).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.modalRef.hide();
            this.sweetAlert.successToast(res?.msg, 3000);
            this.load_reports_groups();
            this.form.reset();
          }
        },
        (error) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(error?.error?.error, 3000);
        }
      )
    );
  }
  public deleteReportsData(data) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true,
      width: "400px",
      data: {
        title: this.lang.transform("lang_confirmation"),
        message: this.lang.transform("lang_are_you_sure"),
      },
    });
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          this.subscriptions.add(
            this.ds
              .deleteActionByExtraData(
                [data?.report_group_id],
                "ac/dyr/delete_report_groups",
                {
                  branch_id: this.branch_id,
                  finance_id: this.finance_id,
                }
              )
              .subscribe(
                (data) => {
                  this.spinner.hide();
                  if (data.status) {
                    this.load_reports_groups();
                    this.ds.dialogf("", data.msg);
                  } else {
                    this.ds.dialogf("", data.error);
                  }
                },
                (error) => {
                  this.spinner.hide();
                  this.ds.dialogf(
                    "",
                    error && error.error && error.error.error
                      ? error.error.error
                      : this.lang.transform("lang_internal_server_error")
                  );
                }
              )
          );
        }
      })
    );
  }
  public submit() {
    if (!this.isEditMode) {
      this.spinner.show();
      let param = new FormData();
      param.append("branch_id", this.branch_id || "");
      param.append("finance_id", this.finance_id || "");
      param.set(
        "group_name_english",
        this.form.get("group_name_english").value || ""
      );
      param.set(
        "group_name_arabic",
        this.form.get("group_name_arabic").value || ""
      );
      // param.set(
      //   "report_group_account_type",
      //   this.form.get("report_group_account_type").value || ""
      // );
      param.set("group_accounts", this.form.get("group_accounts").value || "");
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "ac/dyr/create_report_groups").subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.form.reset();
              this.load_reports_groups();
              this.alert.success(res.msg);
              setTimeout(() => {
                this.modalRef.hide();
              }, 2000);
            } else {
              this.alert.error(res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
      );
    } else {
      this.updateReportsGroups(this.selectedData);
    }
  }

  getFinancialList() {
    this.financeList = [];
    this.finance_id = null;
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.financeList = res.records;
            this.financeList.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
              }
            });
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  getFinancialListForm() {
    this.financeList = [];
    this.finance_id = null;
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.financeList = res.records;
            this.financeList.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
              }
            });
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
