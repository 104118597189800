<div class="main">
	<div class="mobilization_in_contract">
		<div class="mbrl15">
			<div class="row">
				<div class="col-md-3 col-sm-6 col-xs-12 mb-10">
					<select
						class="form-control almnabrformcontrol"
						[(ngModel)]="mobilization_type"
						(change)="spinner.show(); search_supervision_mobcontract()"
					>
						<option value="">{{ "lang_all" | language }}</option>
						<option value="OF001">{{ "lang_office" | language }}</option>
						<option value="CT001">{{ "lang_contractors" | language }}</option>
						<option value="CL001">{{ "lang_customers" | language }}</option>
					</select>
				</div>
				<div class="col-md-9 col-sm-6 col-xs-12 mb-10">
					<div class="input-group input-group-sm">
						<input
							type="text"
							class="form-control almnabrformcontrol h34"
							[(ngModel)]="mobcontractearchKey"
							(keyup)="search_supervision_mobcontract()"
							placeholder="{{ 'lang_search' | language }}"
						/>
						<span class="input-group-btn" *ngIf="makeAction">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_add' | language }}"
								class="btnSearch enablecode h34 btn btn-md btn-gray mnabricon fa fa-plus"
								(click)="emptyform(); openModal(createmobhandle)"
							></button>
						</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check_all' | language }}"
										>
											<input
												type="checkbox"
												class="allItemsMob checkbox"
												id="allItemsMob"
												(click)="checkAllItemsMob($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ "lang_description" | language }}</td>
									<td>{{ "lang_file_level" | language }}</td>
									<td>{{ "lang_mobilization_type" | language }}</td>
									<td>{{ "lang_writer" | language }}</td>
									<td>{{ "lang_ondate" | language }}</td>
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody *ngIf="mobListData.length != '0'" class="AppendList">
								<tr
									*ngFor="
										let list of mobListData.records;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
									class="makeback removerow{{ list.mobilization_id }}"
								>
									<td>{{ i + 1 }}</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check' | language }}"
										>
											<input
												type="checkbox"
												class="eachItemMob checkbox"
												value="{{ list.mobilization_id }}"
												(click)="checkEachItemMob($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ list.mobilization_description }}</td>
									<td>{{ list.level_keys }}</td>
									<td *ngIf="list.mobilization_type == 'OF001'">
										{{ "lang_office" | language }}
									</td>
									<td *ngIf="list.mobilization_type == 'CT001'">
										{{ "lang_contractors" | language }}
									</td>
									<td *ngIf="list.mobilization_type == 'CL001'">
										{{ "lang_customers" | language }}
									</td>
									<td>{{ list.writer }}</td>
									<td>{{ list.created_datetime }}</td>
									<td>
										<i
											matTooltip="{{ 'lang_file' | language }}"
											(click)="
												ds.preview_pdf_file(
													list.mobilization_file_url,
													list?.mobilization_description
												)
											"
											class="fa fa-paperclip fa-icon s2icon mnabricon"
										></i>
										<i
											*ngIf="makeAction"
											matTooltip="{{ 'lang_edit' | language }}"
											(click)="emptyEditForm(list); openModal(updatemobform)"
											class="fa fa-pencil-square-o fa-icon s2icon mnabricon"
										></i>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="mobListData.length == '0'" class="AppendList">
								<tr class="odd">
									<td colspan="9" align="center">{{ mobnodata }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="mobListData?.records?.length > '0'" class="card-footer padb0">
			<div class="row responsive_pagination">
				<div
					*ngIf="makeAction"
					class="deleteIconDiv none col-lg-1 col-sm-1 mb-10"
				>
					<i
						class="fa fa-trash-o faicon red deleteIcon"
						(click)="deleteBulkDataMob()"
						aria-hidden="true"
						matTooltip="{{ 'lang_delete' | language }}"
					></i>
				</div>
				<div
					class="col-lg-3 col-sm-3 mb-10"
					style="height: fit-content; margin: auto 0"
				>
					{{ "lang_total" | language }} : {{ mobListData?.page?.total_records }}
				</div>
				<div
					class="col-lg-5 col-sm-5 mb-10"
					style="display: flex; justify-content: center"
				>
					<ngb-pagination
						class="m-auto"
						[collectionSize]="mobListData?.page?.total_records"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="mobpageno"
						[pageSize]="mobpagesize"
						(pageChange)="load_supervision_mobcontract_page($event)"
					></ngb-pagination>
				</div>
				<div
					class="col-lg-3 col-sm-3"
					style="display: flex; justify-content: end; margin: auto"
				>
					<mat-select
						[(ngModel)]="mobpagesize"
						(selectionChange)="
							load_supervision_mobcontract_pagesize($event.value)
						"
					>
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #createmobhandle>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">
				{{ "lang_mobilization_in_contract" | language }}
			</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div class="modal-body">
				<div class="form-group">
					<label for="mobilization_type"
						>{{ "lang_mobilization_type" | language }}:<span class="red"
							>*</span
						></label
					>
					<select
						class="form-control almnabrformcontrol"
						formControlName="mobilization_type"
					>
						<option value="OF001">{{ "lang_office" | language }}</option>
						<option value="CT001">{{ "lang_contractors" | language }}</option>
						<option value="CL001">{{ "lang_customers" | language }}</option>
					</select>
					<mat-error
						*ngIf="
							form.controls['mobilization_type'].touched &&
							form.controls['mobilization_type'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="mobilization_description"
						>{{ "lang_description" | language }}:<span class="red"
							>*</span
						></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="mobilization_description"
					/>
					<mat-error
						*ngIf="
							form.controls['mobilization_description'].touched &&
							form.controls['mobilization_description'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="level_keys"
						>{{ "lang_file_secutrity_levels" | language }} :
					</label>
					<ng-select
						[items]="filelevels"
						[multiple]="true"
						bindLabel="label"
						appendTo="body"
						bindValue="value"
						clearAllText="Clear"
						placeholder="{{ 'lang_file_secutrity_levels' | language }}"
						formControlName="level_keys"
					>
					</ng-select>
				</div>
				<div class="form-group">
					<label
						>{{ "lang_attachments" | language }}:
						<span *ngIf="isFile">
							<mat-icon
								(click)="removeFile()"
								class="mnabricon red"
								matTooltip="{{ 'lang_delete' | language }}"
								>close</mat-icon
							>
						</span>
					</label>
					<div class="IDDIV">
						<input
							style="display: block"
							name="mobilization_attachment"
							type="file"
							required
							onclick="this.value = null"
							(change)="UploadFile($event, form.get('mobilization_attachment'))"
							class="nghide albutton width100"
						/>
						<label id="uploadText" class="albutton">{{ filename }} </label>
					</div>
					<mat-error
						*ngIf="
							form.controls['mobilization_attachment'].touched &&
							form.controls['mobilization_attachment'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #updatemobform>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">
				{{ "lang_update_lang_mobilization_in_contract" | language }}
			</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="updateform" (ngSubmit)="update()">
			<div class="modal-body">
				<div class="form-group">
					<label for="sitehandle_description"
						>{{ "lang_description" | language }}:<span class="red"
							>*</span
						></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="sitehandle_description"
					/>
					<mat-error
						*ngIf="
							updateform.controls['sitehandle_description'].touched &&
							updateform.controls['sitehandle_description'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="level_keys"
						>{{ "lang_file_secutrity_levels" | language }} :
					</label>
					<ng-select
						[items]="filelevels"
						[multiple]="true"
						bindLabel="label"
						appendTo="body"
						bindValue="value"
						clearAllText="Clear"
						placeholder="{{ 'lang_file_secutrity_levels' | language }}"
						formControlName="level_keys"
					>
					</ng-select>
				</div>
				<div class="form-group">
					<label
						>{{ "lang_attachments" | language }}:
						<span *ngIf="isFile" for="sitehandle_attachments">
							<mat-icon
								(click)="removeFile()"
								class="mnabricon red"
								matTooltip="{{ 'lang_delete' | language }}"
								>close</mat-icon
							>
						</span>
					</label>
					<div class="IDDIV">
						<input
							style="display: block"
							name="sitehandle_attachments"
							type="file"
							required
							onclick="this.value = null"
							(change)="
								UploadFile($event, updateform.get('sitehandle_attachments'))
							"
							class="nghide albutton width100"
						/>
						<label id="uploadText" class="albutton">{{ filename }} </label>
					</div>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<div class="modal" id="pdfPreviewMob">
	<div class="modal-dialog width70">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_preview" | language }}</h4>
				<button type="button" class="close" data-dismiss="modal">
					&times;
				</button>
			</div>
			<div class="modal-body">
				<div class="pdf-container">
					<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</div>
