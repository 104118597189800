<div class="themesection">
	<app-formc1-add
		*ngIf="form_key == 'FORM_C1'"
		[form_key]="form_key"
		[form_request_id]="form_request_id"
	></app-formc1-add>
	<app-formc2-add
		*ngIf="form_key == 'FORM_C2'"
		[form_key]="form_key"
		[form_request_id]="form_request_id"
	></app-formc2-add>
	<app-formpq1-add
		*ngIf="form_key == 'FORM_PQ1'"
		[form_key]="form_key"
		[form_request_id]="form_request_id"
	></app-formpq1-add>
	<app-form-ps-tr1-add
		*ngIf="form_key == '2.SQR.1.1'"
		[form_key]="'2.SQR.1.1'"
		[form_request_id]="form_request_id"
	></app-form-ps-tr1-add>
	<app-form-wri-add
		*ngIf="form_key == 'FORM_WIR'"
		[form_key]="'FORM_WIR'"
		[form_request_id]="form_request_id"
	></app-form-wri-add>
</div>
