<div class="card appjoinings">
	<div class="card-header">
		{{ "lang_communications" | language }}
	</div>
	<div class="card-body p-0">
		<div class="mbrl15 martb0">
			<div class="row">
				<div class="col-12 col-sm-4">
					<ng-select
						[items]="transaction_keys"
						[multiple]="false"
						appendTo="body"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						placeholder="status"
						[clearable]="false"
						[searchable]="false"
						[(ngModel)]="transaction_key"
						(change)="onTranKeyChange()"
					>
					</ng-select>
				</div>
				<div class="input-group input-group-sm mb-10 col-12 col-sm-8">
					<input
						type="text"
						class="form-control almnabrformcontrol"
						(keyup)="get_employee_communications()"
						[(ngModel)]="searchKey"
						placeholder="{{ 'lang_search' | language }}"
					/>
					<span class="input-group-btn" *ngIf="able2add">
						<button
							class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
							type="button"
							[matMenuTriggerFor]="menu"
						></button>
						<mat-menu #menu="matMenu">
							<button
								class="d-flex justify-content-between align-items-center"
								type="button"
								(click)="newCommunication('outgoing')"
								mat-menu-item
							>
								<i class="fa fa-sign-out mnabricon"></i>
								<span>{{ "lang_outgoing" | language }}</span>
							</button>
							<button
								class="d-flex justify-content-between align-items-center"
								type="button"
								(click)="newCommunication('incoming')"
								mat-menu-item
							>
								<i class="fa fa-sign-in mnabricon"></i>
								<span>{{ "lang_incoming" | language }}</span>
							</button>
						</mat-menu>
					</span>
				</div>
			</div>

			<div class="row p-1">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable" *ngIf="wholedata">
							<thead>
								<tr>
									<td>#</td>
									<td>{{ "lang_request_number" | language }}</td>
									<td>{{ "lang_description" | language }}</td>
									<td>{{ "lang_from" | language }}</td>
									<td>{{ "lang_to" | language }}</td>
									<td>{{ "lang_type" | language }}</td>
									<td>{{ "lang_module_name" | language }}</td>
									<td>{{ "lang_forms" | language }}</td>
									<td>{{ "lang_writer" | language }}</td>
									<td>{{ "lang_submitter" | language }}</td>
									<td>{{ "lang_barcode" | language }}</td>
									<td>{{ "lang_date" | language }}</td>
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody *ngIf="wholedata?.list?.length != '0'" class="AppendList">
								<tr
									*ngFor="
										let list of wholedata?.list;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
									class="{{ list.row_color }}{{ list.row_color_flash }}"
								>
									<td>{{ i + 1 }}</td>
									<td>{{ list.transaction_request_id }}</td>
									<td>{{ list.communication_subject }}</td>
									<td>{{ list.communication_from_name }}</td>
									<td>{{ list.communication_to_name }}</td>
									<td>{{ list.communication_types_name }}</td>
									<td>{{ list.modules_name }}</td>
									<td>{{ list.transactions_name }}</td>
									<td>{{ list.communication_user_name_writer }}</td>
									<td>{{ list.transactions_submitter_user_name }}</td>
									<td>{{ list.tbv_barcodeData }}</td>
									<td>
										{{ list.communication_date_m }} -
										{{ list.communication_date_h }}
									</td>
									<td>
										<i
											matTooltip="{{ 'lang_view' | language }}"
											(click)="
												ds.preview_pdf_file(
													list.file_path,
													'communication' + list?.transaction_request_id
												)
											"
											class="fa fa fa-paperclip mnabricon faicon star"
											aria-hidden="true"
										></i>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="wholedata?.list?.length == '0'" class="AppendList">
								<tr class="odd">
									<td colspan="14" class="nodata" align="center">
										{{ nodata }}
									</td>
								</tr>
							</tbody>
						</table>
						<no-data
							style="margin: auto"
							[Opened]="wholedata?.list?.length == '0' || !wholedata"
						></no-data>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="wholedata?.list?.length > 0"
			class="card-footer padb0 customborder"
		>
			<div class="row responsive_pagination">
				<div
					class="col-lg-3 col-sm-3 mb-10"
					style="height: fit-content; margin: auto 0"
				>
					{{ "lang_total" | language }} {{ wholedata?.page?.total_records }}
				</div>
				<div
					class="col-lg-6 col-sm-6 mb-10"
					style="display: flex; justify-content: center"
				>
					<ngb-pagination
						class="m-auto"
						[collectionSize]="wholedata?.page?.total_records"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="page"
						[pageSize]="size"
						(pageChange)="spinner.show(); page_length($event)"
					></ngb-pagination>
				</div>
				<div
					class="col-lg-3 col-sm-3 mb-10"
					style="display: flex; justify-content: end; margin: auto"
				>
					<mat-select
						[(ngModel)]="size"
						(selectionChange)="spinner.show(); load_page_size(10)($event.value)"
					>
						<!-- <mat-option value="1">1  </mat-option> -->
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
</div>
