import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-issues-list",
  templateUrl: "./issues-list.component.html",
  styleUrls: ["./issues-list.component.scss"],
})
export class IssuesListComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key") || "en";
  @Input() projects_work_area_id;
  @Input() projects_profile_id;
  @Input() projects_supervision_id;
  issue_type_list = [];
  issue_level_list = [];
  issue_status_list = [];
  issue_type = "";
  issue_level = "";
  issue_status = "";
  issues_list = [];
  issues_pageno = 1;
  issues_pagesize = "10";
  total_records = 0;
  isEdit = false;
  form: FormGroup;
  modalRef: BsModalRef;
  constructor(
    private lang: LanguagePipe,
    private ds: DataService,
    private spinner: PreloaderService,
    private sweetAlert: SweetAlertService,
    private fb: FormBuilder,
    public modalService: BsModalService
  ) {
    this.issue_type_list = [
      {
        label: this.lang.transform("lang_specification"),
        value: "specification",
      },
      {
        label: this.lang.transform("lang_quality"),
        value: "quality",
      },
      {
        label: this.lang.transform("lang_schedule"),
        value: "schedule",
      },
    ];
    this.issue_level_list = [
      {
        label: this.lang.transform("lang_severe"),
        value: "severe",
      },
      {
        label: this.lang.transform("lang_medium"),
        value: "medium",
      },
    ];
    this.issue_status_list = [
      {
        label: this.lang.transform("lang_close"),
        value: "close",
      },
      {
        label: this.lang.transform("lang_open"),
        value: "open",
      },
    ];
  }

  ngOnInit(): void {
    moment.locale("en");
    this.getIssues();
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      issue_id: [""],
      issue_description_ar: ["", Validators.required],
      issue_description_en: ["", Validators.required],
      issue_type: ["", Validators.required],
      issue_level: ["", Validators.required],
      issue_owner: ["", Validators.required],
      issue_action_by: ["", Validators.required],
      issue_recommended_action: ["", Validators.required],
      issue_start_date: ["", Validators.required],
      issue_expected_closure_date: ["", Validators.required],
      issue_end_date: ["", Validators.required],
      issue_status: ["", Validators.required],
      project_risk_id: ["", Validators.required],
    });
  }

  getIssues() {
    let formData = new FormData();
    formData.append("projects_work_area_id", this.projects_work_area_id || "");
    formData.append("issue_type", this.issue_type || "");
    formData.append("issue_level", this.issue_level || "");
    formData.append("issue_status", this.issue_status || "");
    this.spinner.show();
    this.ds
      .post(
        `pr/risk_management/get_work_area_issue_log/${this.issues_pageno}/${this.issues_pagesize}`,
        formData
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.issues_list = res?.results;
            this.total_records = res?.pages?.total_records;
          } else {
            this.issues_list = [];
            this.sweetAlert.errorToast(res?.error, 3000);
            this.total_records = 0;
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
          this.total_records = 0;
          this.issues_list = [];
        }
      );
  }
  onAddUpdateIssue(template, data?) {
    this.form.reset();
    if (data) {
      this.isEdit = true;
      this.form.patchValue({
        ...data,
        issue_start_date: new Date(data?.issue_start_date),
        issue_expected_closure_date: new Date(
          data?.issue_expected_closure_date
        ),
        issue_end_date: new Date(data?.issue_end_date),
      });
    }
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  submit() {
    let formData = new FormData();
    formData.append("projects_work_area_id", this.projects_work_area_id || "");
    formData.append(
      "issue_description_ar",
      this.form.get("issue_description_ar")?.value || ""
    );
    formData.append(
      "issue_description_en",
      this.form.get("issue_description_en")?.value || ""
    );
    formData.append("issue_type", this.form.get("issue_type")?.value || "");
    formData.append("issue_level", this.form.get("issue_level")?.value || "");
    formData.append("issue_owner", this.form.get("issue_owner")?.value || "");
    formData.append(
      "issue_action_by",
      this.form.get("issue_action_by")?.value || ""
    );
    formData.append(
      "issue_recommended_action",
      this.form.get("issue_recommended_action")?.value || ""
    );
    formData.append(
      "issue_start_date",
      this.form.get("issue_start_date")?.value
        ? moment(this.form.get("issue_start_date")?.value).format("YYYY/MM/DD")
        : ""
    );
    formData.append(
      "issue_expected_closure_date",
      this.form.get("issue_expected_closure_date")?.value
        ? moment(this.form.get("issue_expected_closure_date")?.value).format(
            "YYYY/MM/DD"
          )
        : ""
    );
    formData.append(
      "issue_end_date",
      this.form.get("issue_end_date")?.value
        ? moment(this.form.get("issue_end_date")?.value).format("YYYY/MM/DD")
        : ""
    );
    formData.append("issue_status", this.form.get("issue_status")?.value || "");
    formData.append(
      "project_risk_id",
      this.form.get("project_risk_id")?.value || ""
    );
    if (this.isEdit) {
      formData.append("issue_id", this.form.get("issue_id")?.value || "");
    }
    this.spinner.show();
    this.ds.post("pr/risk_management/add_edit_issue", formData).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.sweetAlert.successToast("lang_success", 3000);
          this.getIssues();
          this.modalRef.hide();
        } else {
          this.sweetAlert.errorToast(res?.error, 3000);
        }
      },
      (err) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          3000
        );
      }
    );
  }

  deleteIssue(item) {
    let formData = new FormData();
    formData.append("issue_id", item?.issue_id || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "pr/risk_management/delete_issue"
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.sweetAlert.successToast(
              this.lang.transform("lang_success"),
              3000
            );
            this.getIssues();
            this.modalRef.hide();
          } else {
            this.sweetAlert.errorToast(res?.error, 3000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
  }

  onChangeIssueStatus(item, status) {
    let formData = new FormData();
    formData.append("issue_id", item?.issue_id || "");
    formData.append("projects_work_area_id", item?.projects_work_area_id || "");
    if (item?.issue_status != status) {
      formData.append("issue_status", status);

      this.spinner.show();
      this.ds
        .post("pr/risk_management/update_issue_status", formData)
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res?.status) {
              this.sweetAlert.successToast(
                this.lang.transform("lang_success"),
                3000
              );
              this.getIssues();
            } else {
              this.sweetAlert.errorToast(res?.error, 3000);
            }
          },
          (err) => {
            this.spinner.hide();
            this.sweetAlert.successToast(
              this.lang.transform("lang_internal_server_error"),
              3000
            );
          }
        );
    }
  }
}
