import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { MatDialog } from "@angular/material/dialog";
import { ShowDeductionsComponent } from "../show-deductions/show-deductions.component";
import { DataService } from "@core/bootstrap/data.service";
import { PreloaderService } from "@core";

import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";

const moment = _rollupMoment || _moment;
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";

@Component({
  selector: "app-payrole-create",
  templateUrl: "./payrole-create.component.html",
  styleUrls: ["./payrole-create.component.scss"],
})
export class PayroleCreateComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  lang_key = localStorage.getItem("lang_key") || "en";
  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,

    public ds: DataService,
    public lang: LanguagePipe,
    private _sweetAlertService: SweetAlertService,
    private spinner: PreloaderService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.t_id = params["t_id"];
      if (this.t_id != 0) {
        this.view_request();
      }
    });
  }
  t_id = 0;
  finances = [];

  stepOneValid = new FormControl("", Validators.required);
  stepTwoValid = new FormControl("", Validators.required);
  stepThreeValid = new FormControl("", Validators.required);
  stepFourValid = new FormControl("", Validators.required);
  stepFiveValid = new FormControl("", Validators.required);
  stepSixValid = new FormControl("", Validators.required);

  setState(control: FormControl, state: boolean) {
    if (state) {
      control.setErrors({ required: true });
      control.updateValueAndValidity();
    } else {
      control.clearValidators();

      control.updateValueAndValidity();
    }
  }

  ngOnInit() {
    moment.locale("en");
    this.firstFormGroup = this._formBuilder.group({
      start: [""],
      startAr: [""],
      salary_month: [moment(), Validators.required],
      end: [""],
      endAr: [],
      branch: [],
    });

    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
    this.loadbranches();
    this.getAllProjects();
    this.get_all_reviewers();
    /* this.view_request() */
  }

  usersResponse = [];
  responseData = "";
  allSelected = false;
  get_salary_form_employees() {
    this.spinner.show();
    this.ds
      .getActionByUrl(
        [],
        `form/FORM_SAL/get_salary_form_employees/${this.t_id}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.responseData = res;
            this.allSelected = false;
            this.usersResponse = res.records.map((rec) => {
              let action = rec.reviews.map((rev) => {
                return {
                  action: rev.value == "Reject" ? false : true,
                  name: `${rev.firstname_english} ${rev.lastname_english}`,
                  date: rev?.date,
                  reason: rev.comment,
                };
              });
              return {
                ...rec,
                details: "",
                select_status: "unselected",
                reviews: action,
                employee_name:
                  rec.firstname_english + " " + rec.lastname_english,
              };
            });
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(res?.error);
            this.usersResponse = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.usersResponse = [];
          this._sweetAlertService.errorMessage(err?.error.error);
        }
      );
  }

  selectedIndex: number = 0;
  selectionChange(e: any) {
    this.selectedIndex = e.selectedIndex;
  }

  selected;
  isLinear = true;
  makeTooltip(item) {
    let data = `${this.lang.transform("lang_name")}: ${item.name}\n 
    ${this.lang.transform("lang_data")}: ${item.date}\n 
    ${this.lang.transform("lang_the_reason")}: ${item.reason}\n`;
    return data;
  }

  usersList: any[] = [];

  branches = [];
  public loadbranches() {
    this.branches = [];
    this.ds
      .getActionByUrl([], "human_resources/branches/human_resources_view")
      .subscribe((data) => {
        if (data.status) {
          this.branches = data.branches;
        }
      });
  }

  projects = [];
  getAllProjects() {
    this.spinner.show();
    this.ds
      .getActionByUrl([], `human_resources/get_project_subjects`)
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.projects = res.records;
          } else {
            this.spinner.hide();
            this.projects = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.projects = [];
        }
      );
  }

  requestInfo: any = "";
  currentStep = "";
  view_request() {
    this.spinner.show();
    this.ds
      .getActionByUrl([], `form/FORM_SAL/view_request/${this.t_id}`)
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.requestInfo = res;
            if (this.requestInfo.form_sal_data.status) {
              this.currentStep =
                this.requestInfo.form_sal_data.records[0].configation_current_step;
            }
            // if (this.currentStep != "select_employees") {
            //   this.route.queryParams.subscribe((params) => {
            //     this.t_id = params["t_id"];
            //     if (this.t_id != 0) {
            //       this.get_salary_form_employees();
            //     }
            //   });
            // }
            if (this.requestInfo?.last_request_step != "CONFIGURATION") {
              this.setState(this.stepTwoValid, false);
              this.setState(this.stepThreeValid, false);
              this.setState(this.stepFourValid, false);
              this.goForward();
              this.goForward();
              this.goForward();
              this.goForward();
            } else {
              if (this.currentStep == "select_reviewers") {
                this.setState(this.stepTwoValid, false);
                this.setState(this.stepThreeValid, false);
                this.goForward();
                this.goForward();
                this.goForward();
                /* this.selectedIndex = 3 */
              }

              if (this.currentStep == "select_employees") {
                /*  this.setState(this.stepTwoValid,false) */

                this.goForward();
              }

              if (this.currentStep == "selected_employees_datils") {
                this.setState(this.stepTwoValid, false);

                this.goForward();
                this.goForward();
              }
            }
          } else {
            this.spinner.hide();
            this.projects = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.projects = [];
        }
      );
  }

  openDeduction(info) {
    const dialogRef = this.dialog.open(ShowDeductionsComponent, {
      width: "700px",
      data: {
        info: info,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }
  refreshSubject: Subject<void> = new Subject<void>();
  emitEventToChild() {
    this.refreshSubject.next();
  }

  makeItemCollapsed(index) {
    this.usersList[index].collapsed = !this.usersList[index].collapsed;
  }
  clearAll() {
    this.usersList = [];
  }
  deleteSingle(id: any, index) {
    this.usersList.splice(index, 1);
  }

  searchForm = this._formBuilder.group({
    name: [[]],
  });

  reviewers = [];
  get_all_reviewers() {
    this.spinner.show();
    this.ds.getActionByUrl([], `form/FORM_SAL/get_all_reviewers`).subscribe(
      (res) => {
        if (res?.status) {
          this.spinner.hide();
          this.reviewers = res.records.map((element) => {
            return {
              ...element,
              checked: true,
            };
          });
        } else {
          this.spinner.hide();
          this.reviewers = [];
        }
      },
      (err) => {
        this.spinner.hide();
        this.reviewers = [];
      }
    );
  }

  users_arr = [];

  addEmp(e) {
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      let inTheArr = false;
      for (let i = 0; i < this.reviewers.length; i++) {
        const e = this.reviewers[i];
        if (e.value == element.value) {
          inTheArr = true;
        }
      }
      if (!inTheArr) {
        this.reviewers.push({
          ...element,
          checked: true,
        });
      }
    }
    this.searchForm.reset();
  }

  toggleChecked(e, i) {
    this.reviewers[i].checked = e?.target?.checked;
  }
  deleteSingleReviewer(id: any, index) {
    this.reviewers.splice(index, 1);
  }

  clearAllReviewers() {
    this.reviewers = [];
  }

  public search_users(key) {
    this.users_arr = [];
    if (key && key.term) {
      let param = new FormData();
      param.append("search", key.term);
      param.append("lang_key", this.lang_key);
      param.append("user_type_id", "1");
      this.ds
        .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
        .subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            this.users_arr = [...res.list];
          }
        });
    }
  }

  @ViewChild("stepper") stepper;

  goBack(stepper: MatStepper = this.stepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper = this.stepper) {
    stepper.next();
  }

  fillFirstFormValue(newItem: any) {
    this.firstFormGroup.patchValue({ ...newItem });
  }

  fillUserListFromStepTwo(newItems: any) {
    this.usersList = newItems;
    // Validate Step 2
    if (this.usersList.length > 0) {
      this.setState(this.stepTwoValid, false);
    } else {
      this.setState(this.stepTwoValid, true);
    }
  }

  usersListReview = [];
  fillUserListFromStepThree(newItems: any) {
    this.usersListReview = newItems;
    // We need to update step 2 list by any update at step 3 :)
    this.fillUserListFromStepTwo(newItems);

    // Validate Step 3
    if (this.usersListReview.length > 0) {
      this.setState(this.stepThreeValid, false);
    } else {
      this.setState(this.stepThreeValid, true);
    }
  }

  checkReviewers() {
    let state = true;
    this.reviewers.forEach((r) => {
      if (r.checked) {
        state = false;
      }
    });
    if (!state) {
      this.setState(this.stepFourValid, false);
    } else {
      this.setState(this.stepFourValid, true);
    }
  }

  fillReviewers(newItems: any) {
    this.reviewers = newItems;
    this.checkReviewers();
  }
}
