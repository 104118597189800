import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OwlOptions } from 'ngx-owl-carousel-o';
import SignaturePad from 'signature_pad';
declare var $:any;
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit ,OnDestroy {
  modalRefA: BsModalRef;
  openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public profile_percentage: number;
  apiurl = environment.SERVER_ORIGIN;
  key: string = 'view';
  able2edit: boolean = false;
  able2delete: boolean = false;
  able2add: boolean = false;
  educationedit: boolean = true;
  module: string = 'profile';
  branch_id = ''
  isadmin: boolean = false;
  viewdata: any = [];
  myprofile: boolean = false;
  attachments: any = [];
  errorstatus: boolean;
  cerrorMsg: string;
  edit: boolean = false;
  cpage = 1;
  cpagenumber = 10;
  cliststatus: boolean = false;
  signatureform: FormGroup;
  sendcodestatus = false;
  codemsg = this.lang.transform('lang_send_code');
  public age: number;
  singleUser = true;
  @ViewChild('sPad', { static: false }) signaturePadElement : ElementRef;
  @ViewChild('mPad', { static: false }) markPadElement : ElementRef;
  @ViewChild('padElement', { static: false }) padElement: ElementRef;
  width: any = 900;
  height: any = 450;
  signaturePad: any;
  markPad: any;
  errormsg: string;
  sendstatus: any;
  view_profile_image = "";
  submitted:boolean = false;
  constructor(public ds: DataService, public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {
    this.get_my_details();
    this.signatureform = new FormGroup({
      verification_method: new FormControl("", [Validators.required]),
      verification_code: new FormControl("", [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
      signature: new FormControl("", [Validators.required]),
      mark: new FormControl("", [Validators.required]),
    })
  }
  private subscriptions = new Subscription();
	ngOnDestroy(): void {
	  this.subscriptions.unsubscribe();
	}
  @HostListener('window:resize', ['$event'])
  canvassize(event?) {
    if(this.padElement != undefined) {
      this.width = this.padElement.nativeElement.offsetWidth;
      this.height = this.padElement.nativeElement.offsetHeight;
    }
  }
  ngAfterViewInit(): void {
    if(this.signaturePadElement != undefined && this.markPadElement != undefined) {
      this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
        minWidth: 5,
        maxWidth: 8,
        penColor: "rgb(63, 81, 181)",
        backgroundColor: 'rgba(255,255,255, 0)'
      });
      this.markPad = new SignaturePad(this.markPadElement.nativeElement, {
        minWidth: 5,
        maxWidth: 8,
        penColor: "rgb(63, 81, 181)",
        backgroundColor: 'rgba(255,255,255, 0)'
      });
    }
  }


  @ViewChild('stepper') stepper: MatStepper;
  selectedName = 'human_resources_id_details';
  lang_key = localStorage.getItem('lang_key') || 'en';
  customOptions: OwlOptions = {
    loop: false,
    rtl: this.lang_key == 'en'? false: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      },
      1200: {
        items: 6
      }
    },
    nav: false
  }
  moveStepper(name:string) {
    const icons = document?.querySelectorAll('.stepper-header-icon');
    const selectedIcon = document?.querySelector(`.${name}`);
    let index:number=0;
    icons.forEach((item, key) => {
      item?.classList?.remove('stepper-active');
      if(item?.classList?.contains(name)) {
        index = key;
      }
    })
    selectedIcon?.classList?.add('stepper-active');
    this.stepper.selectedIndex = index;
    this.selectedName = name;
    
  }
  get_my_details(): void {
    this.spinner.show();
    this.subscriptions.add(
        this.ds.getActionByUrl([], 'profile').subscribe(data => {
          this.spinner.hide();
          this.myprofile = data.status;
          if (data.status) {
            this.viewdata = data.data;
            this.branch_id = this.viewdata.branch_id
            this.attachments = data.attachments;
            if (data.profile_percentage) {
              this.profile_percentage = data.profile_percentage.total;
            }
            if (this.viewdata.profile_image) {
              this.get_profile_image(this.viewdata.profile_image);
            }
          } else {
            this.ds.dialogf('', data.error);
            this.router.navigate(['/']);
          }
        }, error => {
            this.spinner.hide();
          this.myprofile = false;
          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
        })			
      )

  }
  showsignatureform() {
    this.alert.clear();
    this.submitted = false;
    this.signatureform.reset();
    this.signaturePad.clear();
    this.markPad.clear();
    this.codemsg = this.lang.transform('lang_send_code');
    this.sendcodestatus = false;
    this.sendstatus = false;
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.canvassize();
    }, 500);
  }
  sendVerificationCode(id) {
    this.submitted = true;
    if (!this.signaturePad.isEmpty() && !this.markPad.isEmpty()) {
      this.submitted = false;
      this.signatureform.get('signature').setValue(this.signaturePad.toDataURL());
      this.signatureform.get('mark').setValue(this.markPad.toDataURL());
      this.spinner.show();
      let data = new FormData();
      data.append('id_number', id);
      data.append('verification_method', this.signatureform.get('verification_method').value);
      this.subscriptions.add(
            this.ds.postActionByUrl(data, 'signup/send_verify_code/').subscribe(result => {
              this.sendstatus = result.status;
              this.sendcodestatus = true;
              this.codemsg = this.lang.transform('lang_resend');
              this.spinner.hide();
              if (result.status) {
                this.alert.success(result.msg);
                setTimeout(() => {
                  this.sendcodestatus = false;
                }, 120000);
              } else {
                this.sendcodestatus = false;
                this.alert.error(result.error);
              }
            }, error => {
              this.spinner.hide();
              this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.sendstatus = false;
            })			
        )

    }
  }

  @ViewChild('myModalClose') modalClose;
  submitSignature(id) {
    this.submitted = true;
    if (!this.signaturePad.isEmpty() && !this.markPad.isEmpty()) {
      this.submitted = false;
      this.signatureform.get('signature').setValue(this.signaturePad.toDataURL());
      this.signatureform.get('mark').setValue(this.markPad.toDataURL());
      this.spinner.show();
      let data = this.ds.json2formdata(this.signatureform.value);
      data.append('id_number', id);
      this.subscriptions.add(
          this.ds.postActionByUrl(data, 'submit_signature').subscribe(result => {
            this.spinner.hide();
            if (result.status) {
              this.get_my_details();
              this.alert.success(result.msg);
            /*  this.showsignatureform(); */
            setTimeout(() => {
              this.modalClose.nativeElement.click();
            }, 4000);
            
            /*  window.location.reload(); */
        
            } else {
              this.alert.error(result.error);
            }
          }, error => {
            this.spinner.hide();
            this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          })			
        )

    }
  }
  get_profile_image(url) {
    this.view_profile_image = "";
    this.subscriptions.add(
        this.ds.getActionByUrl([], url).subscribe(data => {
          if (data.status) {
            this.view_profile_image = 'data:' + data['content-type'] + ';base64,' + data.base64;
          }
        })			
      )

  }
  showsignature(id_number, user_id, type) {
    this.spinner.show();
    this.subscriptions.add(
        this.ds.getActionByUrl([], 'viewsmark/' + id_number + type + user_id).subscribe(data => {
          this.spinner.hide();
          if (data.status) {
            ($('#showSMPreview') as any).modal({ backdrop: 'static', keyboard: false });
            ($('#showSMPreview') as any).modal('show');
            $("#SMnabrPdf").attr('src', 'data:' + data['content-type'] + ';base64,' + data.base64);
          } else {
            this.ds.dialogf(data.error);
          }
        }, error => {
          this.spinner.hide();
          this.ds.dialogf((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
        })			
      )

  }
}