<div class="card appcontacts">
  <div class="card-header">
    {{ "lang_human_resources_contact_details" | language }}
  </div>
  <div class="card-body p-0">
    <div class="mbrl15 martb0">
      <div class="row">
        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
          <div class="mb-10">
            {{ "lang_human_resources_primary_mobile" | language }}
          </div>
          <div class="mb-10">
            <b>{{ user_data.primary_mobile }}</b>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
          <div class="mb-10">
            {{ "lang_human_resources_primary_email" | language }}
          </div>
          <div class="mb-10">
            <b>{{ user_data.primary_email }}</b>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
          <div class="mb-10">
            {{ "lang_human_resources_company_email" | language }}
          </div>
          <div class="mb-10">
            <b>{{ user_data?.user_company_email }}</b>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
          <div class="mb-10">
            {{ "lang_human_resources_primary_address" | language }}
          </div>
          <div class="mb-10">
            <b>{{ user_data.primary_address }}</b>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-group input-group-sm mb-10 col-12">
          <input
            type="text"
            class="form-control almnabrformcontrol"
            (keyup)="load_employee_contacts()"
            [(ngModel)]="searchKey"
            placeholder="{{ 'lang_search' | language }}"
          />
          <span
            class="input-group-btn"
            *ngIf="
              requestfor !== 'profile' &&
              user_data.employee_status == '1' &&
              able2add
            "
          >
            <button
              class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
              id="btnSearch"
              type="button"
              (click)="openModalA(addContacts); emptycontactform()"
              matTooltip="{{ 'lang_add_contact' | language }}"
            ></button>
          </span>
          <span class="input-group-btn" *ngIf="attachments.cn0001">
            <button
              class="btnSearch h34 btn btn-md btn-gray fa fa-paperclip mnabricon"
              id="btnSearch"
              type="button"
              (click)="ds.preview_pdf_file(attachments.cn0001, 'cn0001')"
              matTooltip="{{ 'lang_recent_attachment' | language }} : {{
                attachments.cn0001_d
              }}"
            ></button>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive dropdownyes customResponsive col-12">
          <table
            border="1"
            class="table CustomTable"
            *ngIf="wholedata.length != 0"
          >
            <thead>
              <tr>
                <td>#</td>
                <td *ngIf="requestfor !== 'profile'">
                  <div
                    class="checkbox"
                    matTooltip="{{ 'lang_check_all' | language }}"
                  >
                    <input
                      type="checkbox"
                      class="allItemsCon checkbox"
                      id="allItemsCon"
                      (click)="checkAllCon($event)"
                    />
                    <label for="checkbox"></label>
                  </div>
                </td>
                <td>{{ "lang_name" | language }}</td>
                <td>{{ "lang_mobile" | language }}</td>
                <td>{{ "lang_email" | language }}</td>
                <td>{{ "lang_writer" | language }}</td>
                <td>{{ "lang_action" | language }}</td>
              </tr>
            </thead>
            <tbody class="AppendList">
              <tr
                *ngFor="
                  let con of wholedata.records;
                  let i = index;
                  let odd = odd;
                  let even = even
                "
                [ngClass]="{ odd: odd, even: even }"
                class="makeback removerow{{ con.contact_id }}"
              >
                <td>{{ i + 1 }}</td>
                <td *ngIf="requestfor !== 'profile'">
                  <div
                    class="checkbox"
                    matTooltip="{{ 'lang_check' | language }}"
                  >
                    <input
                      type="checkbox"
                      class="eachItemCon checkbox"
                      value="{{ con.contact_id }}"
                      (click)="checkEachItemCon($event)"
                    />
                    <label for="checkbox"></label>
                  </div>
                </td>
                <td>{{ con.contact_person_name }}</td>
                <td *ngIf="con.contact_mobile_number">
                  {{ con.contact_mobile_number }}
                </td>
                <td *ngIf="con.contact_email_address">
                  {{ con.contact_email_address }}
                </td>
                <td *ngIf="!con.contact_mobile_number">----</td>
                <td *ngIf="!con.contact_email_address">----</td>
                <td *ngIf="con.name">{{ con.name }}</td>
                <td *ngIf="!con.name">{{ con.username }}</td>
                <td class="dropdown">
                  <i
                    matTooltip="{{ 'lang_view' | language }}"
                    class="fa fa-eye fa-icon mnabricon s2icon"
                    (click)="openModalV(viewContacts); loadContact(con, 'view')"
                  ></i>
                  <i
                    *ngIf="able2edit && user_data.employee_status == '1'"
                    (click)="openModalA(addContacts); loadContact(con, 'edit')"
                    class="fa fa-pencil-square-o fa-icon mnabricon s2icon"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <no-data
            style="margin: auto"
            [Opened]="wholedata.length < 1"
          ></no-data>
        </div>
      </div>
    </div>
    <div
      *ngIf="wholedata?.records?.length != 0"
      class="card-footer padb0 customborder"
    >
      <div class="row responsive_pagination">
        <div
          *ngIf="able2delete && requestfor !== 'profile'"
          class="deleteIconDiv none col-lg-1 col-sm-1 mb-10"
        >
          <i
            class="fa fa-trash-o faicon red deleteIcon"
            (click)="deleteBulkDataCon()"
            aria-hidden="true"
            matTooltip="{{ 'lang_delete' | language }}"
          ></i>
        </div>
        <div
          class="col-lg-3 col-sm-3 mb-10"
          style="height: fit-content; margin: auto 0"
        >
          {{ "lang_total" | language }} : {{ wholedata?.page?.total_records }}
        </div>
        <div
          class="col-lg-5 col-sm-5 mb-10"
          style="display: flex; justify-content: center"
        >
          <!--  <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="10" checked (click)="spinner.show();loadbypagenumber(10)"> 10 </mat-radio-button>
                      <mat-radio-button value="20" (click)="spinner.show();loadbypagenumber(20)"> 20 </mat-radio-button>
                      <mat-radio-button value="50" (click)="spinner.show();loadbypagenumber(50)"> 50 </mat-radio-button>
                      <mat-radio-button value="100" (click)="spinner.show();loadbypagenumber(100)"> 100 </mat-radio-button>
                      <mat-radio-button value="500" (click)="spinner.show();loadbypagenumber(500)"> 500 </mat-radio-button>
                  </mat-radio-group> -->
          <ngb-pagination
            class="m-auto"
            [collectionSize]="wholedata?.page?.total_records"
            [rotate]="true"
            [ellipses]="false"
            [maxSize]="3"
            [boundaryLinks]="true"
            [(page)]="cpage"
            [pageSize]="csize"
            (pageChange)="spinner.show(); loadbypagelength($event)"
          ></ngb-pagination>
        </div>
        <div
          class="col-lg-3 col-sm-3 mb-10"
          style="display: flex; justify-content: end; margin: auto"
        >
          <!-- {{'lang_goto' | language }} :
                  <select class="customselect" (change)="spinner.show();loadbypagelength($event.target.value)">
                      <option *ngFor="let k of ds.getrange(wholedata.page.total_pages)" value="{{k}}">{{k}}</option>
                  </select> -->

          <mat-select
            [(ngModel)]="csize"
            (selectionChange)="spinner.show(); loadbypagenumber($event.value)"
          >
            <!-- <mat-option value="1">1  </mat-option> -->
            <mat-option class="text-center" value="10"
              >10 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" value="20"
              >20 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" value="50"
              >50 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" value="100"
              >100 {{ "lang_per_page" | language }}</mat-option
            >
            <mat-option class="text-center" value="500"
              >500 {{ "lang_per_page" | language }}</mat-option
            >
          </mat-select>
        </div>
      </div>
    </div>
  </div>
  <div class="card appcontacts m-2">
    <div class="card-header">
      {{ "lang_whatsapp" | language }}
    </div>
    <div class="card-body p-0">
      <div class="martb0">
        <div class="row m-auto">
          <div class="col-12 col-full-height">
            <app-hr-whatsapp
              [user_data]="user_data"
              (resfreshview_details)="resfreshview_details()"
            ></app-hr-whatsapp>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #addContacts>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ "lang_human_resources_contact_details" | language }}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefA.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="contctform" (ngSubmit)="contacts_operations()">
      <div class="modal-body">
        <input type="hidden" id="contact_id" formControlName="contact_id" />
        <div class="form-group">
          <label for="contact_person_name"
            >{{ "lang_person_name" | language }}:<span class="red"
              >*</span
            ></label
          >
          <input
            type="text"
            required
            id="contact_person_name"
            class="form-control almnabrformcontrol contact_person_name"
            placeholder="{{ 'lang_person_name' | language }}"
            formControlName="contact_person_name"
          />
          <mat-error
            *ngIf="
              f.controls['contact_person_name'].touched &&
              f.controls['contact_person_name'].errors?.required
            "
          >
            {{ "lang_field_required" | language }}
          </mat-error>
        </div>
        <div class="form-group">
          <label for="contact_mobile_number"
            >{{ "lang_mobile_number" | language }}:<span class="red"
              >(EX: 966000000000)</span
            ></label
          >
          <input
            type="text"
            id="contact_mobile_number"
            (keypress)="ds.numberOnly($event)"
            minlength="12"
            maxlength="12"
            class="form-control almnabrformcontrol contact_mobile_number"
            placeholder="{{ 'lang_mobile_number' | language }}"
            formControlName="contact_mobile_number"
          />
          <mat-error
            *ngIf="
              f.controls['contact_mobile_number'].touched &&
              f.controls['contact_mobile_number'].errors?.minlength
            "
          >
            {{ "lang_minimum" | language }} 12
          </mat-error>
          <mat-error
            *ngIf="
              f.controls['contact_mobile_number'].touched &&
              f.controls['contact_mobile_number'].errors?.maxlength
            "
          >
            {{ "lang_maximum" | language }} 12
          </mat-error>
        </div>
        <div class="form-group">
          <label for="contact_email_address"
            >{{ "lang_email_address" | language }}:</label
          >
          <input
            type="text"
            id="contact_email_address"
            class="form-control almnabrformcontrol contact_email_address"
            placeholder="{{ 'lang_email_address' | language }}"
            formControlName="contact_email_address"
          />
          <mat-error
            *ngIf="
              f.controls['contact_email_address'].touched &&
              f.controls['contact_email_address'].errors?.email
            "
          >
            {{ "lang_field_valid_email" | language }}
          </mat-error>
        </div>
        <div class="form-group">
          <label for="contact_address_text"
            >{{ "lang_address_text" | language }}:</label
          >
          <textarea
            type="text"
            id="contact_address_text"
            class="form-control almnabrformcontrol contact_address_text"
            placeholder="{{ 'lang_address_text' | language }}"
            formControlName="contact_address_text"
          ></textarea>
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              data-dismiss="modal"
              (click)="modalRefA.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #viewContacts>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ "lang_human_resources_contact_details" | language }}
      </h4>
      <button type="button" class="close" (click)="modalRefV.hide()">
        &times;
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_person_name" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">{{ contactdata.contact_person_name }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_mobile_number" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall" *ngIf="contactdata.contact_mobile_number">{{
              contactdata.contact_mobile_number
            }}</b>
            <b *ngIf="!contactdata.contact_mobile_number">----</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_email_address" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall" *ngIf="contactdata.contact_email_address">{{
              contactdata.contact_email_address
            }}</b>
            <b *ngIf="!contactdata.contact_email_address">----</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_address_text" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall" *ngIf="contactdata.contact_address_text">{{
              contactdata.contact_address_text
            }}</b>
            <b *ngIf="!contactdata.contact_address_text">----</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_writer" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b *ngIf="contactdata.name">{{ contactdata.name }}</b>
            <b *ngIf="!contactdata.name">{{ contactdata.username }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_created_date" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall">{{ contactdata.contact_createddatetime }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_onupdate" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b class="breakall" *ngIf="contactdata.contact_updateddatetime">{{
              contactdata.contact_updateddatetime
            }}</b>
            <b class="breakall" *ngIf="!contactdata.contact_updateddatetime"
              >----</b
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modalRefV.hide()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>
