import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-snaglist-edit',
  templateUrl: './snaglist-edit.component.html',
  styleUrls: ['./snaglist-edit.component.scss']
})
export class SnaglistEditComponent implements OnInit {
  lang_key        = localStorage.getItem('lang_key') || 'en';
  snag_form: FormGroup;
  group1_list: any = [];
  group2_list: any = [];
  snag_id: any;
  constructor(private fb: FormBuilder, public ds: DataService, private sweetAlert: SweetAlertService, private lang: LanguagePipe, 
    private spinner: PreloaderService, private dialogRef: MatDialogRef<SnaglistEditComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data) { 
      this.snag_id = data?.note_lists_id;
      this.buildForm();
      this.get_group1_list({trem: ''});
      this.setEditData(data);
    }

  ngOnInit(): void {
  }
  buildForm() {
    this.snag_form = this.fb.group({
      platform_group1_code_system: [null, Validators.required],
      platform_group2_code_system: [null, Validators.required],
      note_title_english:          ['', Validators.required],
      note_title_arabic:           ['', Validators.required]
    });
  }
  setEditData(data) {
    this.snag_form.get('platform_group1_code_system').setValue(data.platform_group1_code_system);
    this.snag_form.get('platform_group2_code_system').setValue(data.platform_group2_code_system);
    this.snag_form.get('note_title_english').setValue(data.note_title_english);
    this.snag_form.get('note_title_arabic').setValue(data.note_title_arabic);
    this.get_group2_list({trem: ''});
  }
  get_group1_list(event) {
    this.spinner.show();
    let param = new FormData();
    param.append('search_key',  event?.trem || '');
    param.append('lang_key', this.lang_key);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgone').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.group1_list = res?.records;
      } else {
        this.group1_list = [];
      }
    }, error => {
      this.spinner.hide();
      this.group1_list = [];
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    });
  }

  get_group2_list(event) {
    this.spinner.show();
    let param = new FormData();
    param.append('search_key', event?.trem || '');
    param.append('lang_key', this.lang_key);
    param.append('platform_group1_code_system', this.snag_form.get('platform_group1_code_system').value || '');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgtwo').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.group2_list = res?.records;
      } else {
        this.group2_list = [];
      }
    }, error => {
      this.spinner.hide();
      this.group2_list = [];
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    });
  }

  edit_snag() {
    if(this.snag_form.valid) {
      this.spinner.show();
      this.ds.putActionByUrl(this.ds.formData2string(this.ds.json2formdata(this.snag_form.value)), `p/snag/update/${this.snag_id}`).subscribe(res => {
        this.spinner.hide();
        if(res?.status) {
          this.dialogRef.close(true);
          this.sweetAlert.successToast(res?.msg, 2000);
        } else {
          this.sweetAlert.errorToast(res?.error, 2000);
        }
      }, error => {
        this.spinner.hide();
        this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
      });
    } else { 
      this.sweetAlert.errorToast(this.lang.transform('lang_fill_required_fields'), 2000);
    }
  }
}
