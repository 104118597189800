 
	<div class="form-wrapper">
		<h3 class="mb-2">{{'lang_edit_violation'|language}}</h3>
		<mat-divider></mat-divider>

		<form [formGroup]="form" class="form" (ngSubmit)="submitForm()">
			<div class="row mb-10">
				<!--      <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						 <div class="mb-10">{{ "lang_employee_no"|language }}</div>
						 <div class="mb-10">
							 <b> {{employee_data.employee_number}} </b>
						 </div>
					 </div>
					 <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						 <div class="mb-10">{{ "lang_name"|language }}</div>
						 <div class="mb-10">
							 <b>
								 {{employee_data.firstname_english}} </b
							 >
						 </div>
					 </div> -->
					 <!-- <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						 <div class="mb-10">{{ "lang_salary"|language }}</div>
						 <div class="mb-10">
							 <b>
								 5000</b
							 >
						 </div>
					 </div>
					 <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						 <div class="mb-10">{{ "lang_current_salary"|language }}</div>
						 <div class="mb-10">
							 <b>
								4500</b
							 >
						 </div>
					 </div>

					 <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						 <div class="mb-10">{{ "lang_joining_date"|language }}</div>
						 <div class="mb-10">
							 <b>
								15/06/2002</b
							 >
						 </div>
					 </div> -->
				 </div>

				 <div class="row">
			   
			
				 
					 

				
				  

				 <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
					 <label class="mb-10">
						 {{ "lang_direct_manager" | language }}
						 <span class="red"> * </span> </label>
	 
						 <ng-select
						 [items]="users"
						 [multiple]="false"
						 bindLabel="label"
						 bindValue="value"
						 clearAllText="Clear"
						  
						 appendTo="body"
						 formControlName="direct_manager"
						 placeholder="{{ 'lang_direct_manager' | language }}"
						 (search)="search_users($event)"
						 
						 [searchFn]="ds.customSearchFn"
						  
					 >
					 </ng-select>
					 <mat-error
						 *ngIf="
						 submitted &&
						 form.controls['direct_manager'].errors?.required
						 "
					 >
						 {{ "lang_field_required" | language }}
					 </mat-error>
				  
				 </div>  

				 
				 <div
				 class="col-md-3 col-sm-4 col-xs-12 mb-10"
				 *ngIf="!attachstatus"
			 >
				 <button
				 style="position: relative;top:28px;"
					 class="albutton mnabrbutton mat-accent"
					 mat-button
					 (click)="enable_field('attachment')"
				 >
					 <i class="fa fa-plus"></i> {{ "lang_need_attachment" | language }}
				 </button>
			 </div>

		  
			   
			 <!--   <div  class="col-md-3 col-sm-3 col-xs-12 mb-10">
				 <button (click)="get_employee_violations()" type="button" class="albutton mnabrbutton mat-accent top26 withOutLabel" mat-button>
				 {{ "lang_history" | language }}
			   </button>
				-->
			 </div>
					 
	  <!--     <div  class="col-md-3 col-sm-3 col-xs-12 mb-10" *ngIf="form.controls['violation_punishment'].value">

				 <label class="mb-10" style="display: block;">
				   {{ "lang_punishment" | language }}
				   <span class="red"> * </span> </label>
				   
				   <h3>
					 {{form.controls['violation_punishment'].value}}
				   </h3>
			   
		   </div>  

		   
	   <div  class="col-md-3 col-sm-3 col-xs-12 mb-10" *ngIf="form.controls['violation_amount'].value">

			 <label class="mb-10"  style="display: block;">
			   {{ "lang_deduct_amount" | language }}
			   <span class="red"> * </span> </label>
			   <h3>
				 {{+form.controls['violation_amount'].value| number : '.2-2'}}
			   </h3>
		   
		 
		 </div>   -->

	 


  


			   <div class="row w-100 flow-auto">
				 <table class="table table-striped">
					 <thead>
					   <tr>
					 
						 <th scope="col">{{'lang_date'|language}}</th>
						 <th scope="col" >{{'lang_violation_reason'|language}}</th>
						 <th scope="col">{{'lang_violation_num'|language}}</th>
						 <th scope="col" >{{'lang_punishment'|language}}</th>
						 <th scope="col" >{{'lang_deduct_amount'|language}}</th>
						 <th scope="col" >{{'lang_statement_en'|language}}</th>
						 <th scope="col" >{{'lang_statement_ar'|language}}</th>

						 <th scope="col">{{'lang_action'|language}}</th>
					   </tr>
					 </thead>
					 <tbody >
					   <tr  
					   
					   formArrayName="violation_arr"
						*ngFor="
					   let creds of form.controls?.violation_arr.value;
					   let i = index;
					   trackBy: trackByFnCustom
					   ">
					   <ng-container [formGroupName]="i">
 
					  <!--  <td scope="row">{{i+1}} </td> -->
					   <td >
						   <div class="col-md-12 col-sm-12 col-xs-12 ">
							   <div class="form-group w-250">
								   <!-- <label for="violation_date_en">{{'lang_date' | language }} : <span class="red">*</span></label> -->
								   <div class="positionrelatiove">
									   <input formControlName="violation_date_en" style="min-width: 200px;" type="text" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event,getForm(i))" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
									   <mat-datepicker-toggle matSuffix [for]="BDeng" class="posiab"></mat-datepicker-toggle>
									   <mat-datepicker #BDeng disabled="false"></mat-datepicker>
									 
								   </div>
							   </div>
						   </div>
					   </td>
					   <td>
						   
						 <div  class="col-md-12 col-sm-12 col-xs-12 " style="max-width: 200px;margin: auto;">
  
						
							 <div class="form-group m-auto" style="max-width: 200px">
								 <ng-select
								 style="max-width: 200px;margin: auto;"
								 [items]="searchArray"
								 [multiple]="false"
								 bindLabel="label"
								 bindValue="value"
								 clearAllText="Clear"
								 (change)="getViolations($event,i)"
								 appendTo="body"
								 formControlName="violation_id"
								 placeholder="{{ 'lang_violation_reason' | language }}"
	 
								  class="customSelect"
									>
									</ng-select>
									<!-- <mat-error
									*ngIf="
									submitted &&
									form.controls['violation_id'].errors?.required
									"
								   >
									{{ "lang_field_required" | language }}
								</mat-error> -->
							 
							 </div>
							
						   </div>
 
							 </td>


							 <td> 
								 {{getFormByName(i,'violation_count').value}}
							  </td>

							  <td> 
								 {{getFormByName(i,'violation_punishment').value}}
							  </td>


							  <td> 
								 {{+getFormByName(i,'violation_amount').value| number : '.2-2'}}
							  </td>


							  <td> 
								 <textarea  id="" cols="10" rows="1" class="form-control almnabrformcontrol"  formControlName="violation_statement_en" ></textarea>
								 <mat-error
								 *ngIf="
								 submitted &&
								 getFormByName(i,'violation_statement_en').errors?.required
								 "
							 >
								 {{ "lang_field_required" | language }}
							 </mat-error>
							  </td>

							  <td> 
								 <textarea  id="" cols="10" rows="1" 
								 class="form-control almnabrformcontrol"  
								 formControlName="violation_statement_ar" ></textarea>
								 <mat-error
								 *ngIf="
								 submitted &&
								 getFormByName(i,'violation_statement_ar').errors?.required
								 "
							 >
								 {{ "lang_field_required" | language }}
							 </mat-error>
							  </td>
							 <td> 
								 <mat-icon *ngIf="getFormByName(i,'violation_id').value"   (click)="get_employee_violations(getFormByName(i,'violation_id').value)" matTooltip="{{ 'lang_view_history' | language }}" class="mat-icon notranslate mat-tooltip-trigger mnabricon material-icons mat-icon-no-color"    >history</mat-icon>
						   
							
								 <mat-icon (click)="deleteViolation(i)" matTooltip="{{ 'lang_delete' | language }}"  class="mat-icon notranslate mat-tooltip-trigger mnabricon red material-icons mat-icon-no-color"   >delete_forever</mat-icon>
								   
							 </td>
						   </ng-container>
							 
							 </tr>
							
						   </tbody>
						 </table>
 
 
				   </div>
 
 

			   <a (click)="addViolation()" class="btn customButton mb-3 mt-2">
				 {{ "lang_add_violation" | language }}
			 </a>


			 <input type="hidden" formControlName="needAttach" />
			 <div class="row " *ngIf="attachstatus">
				 <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
					 <fieldset class="cardback fieldset">
						 <legend>
							 {{ "lang_attachments" | language }}
							 <span class="red">*
								 <mat-icon
									 class="red mnabricon"
									 (click)="disable_field('attachment')"
									 >close</mat-icon
								 ></span
							 >
							 <mat-icon
								 class="mnabricon"
								 matTooltip="{{ 'lang_add' | language }}"
								 (click)="addfiles()"
								 >add_box</mat-icon
							 >
						 </legend>
						 <div class="table-responsive customResponsive">
							 <table
								 border="1"
								 class="table CustomTable"
								 width="100%"
								 formArrayName="attachments"
							 >
								 <thead>
									 <tr>
										 <td style="padding: 5px; word-break: break-all">#</td>
										 <td style="padding: 5px; word-break: break-all">
											 {{ "lang_title" | language }}
										 </td>
										 <td style="padding: 5px; word-break: break-all">
											 {{ "lang_file" | language }}
										 </td>

										 <td style="padding: 5px; word-break: break-all">
											 {{ "lang_action" | language }}
										 </td>
									 </tr>
								 </thead>
								 <tbody>
									 <tr
										 *ngFor="
											 let file of filesFormGroup.controls;
											 let i = index;
											 let odd = odd;
											 let even = even
										 "
										 [ngClass]="{ odd: odd, even: even }"
										 [formGroupName]="i"
									 >
										 <td
											 class="verticalalignmid"
											 style="padding: 5px; word-break: break-all"
										 >
											 {{ i + 1 }}
										 </td>
										 <td
											 style="
												 padding: 5px;
												 word-break: break-all;
												 position: relative;
											 "
										 >
											 <input
												 type="text"
												 formControlName="attach_title"
												 placeholder="{{ 'lang_description' | language }}"
												 autocomplete="off"
												 class="form-control almnabrformcontrol"
											 />
											 <mat-error
												 *ngIf="
													 submitted &&
													 this.attachments.controls[i].get('attach_title')
														 .errors?.required
												 "
												 >{{ "lang_field_required" | language }}</mat-error
											 >
										 </td>
										 <td
											 class="verticalalignmid"
											 style="
												 padding: 5px;
												 word-break: break-all;
												 position: relative;
											 "
										 >
											 <input
												 style="display: block;"
												 name="file"
												
												 type="file"
												 required
												 onclick="this.value = null"
												 (change)="UploadFile($event, i)"
												 class="nghide albutton width100"
											 />
											 <label
												 id="uploadText{{ i }}"
												 class="albutton mat-accent"
												 mat-button
												 > <span *ngIf="this.attachments.controls[i].get('file_id').value">
													 {{ this.attachments.controls[i].get('attach_title').value }}
												 </span> 

												 <span *ngIf="!this.attachments.controls[i].get('file_id').value">
													 {{ 'lang_select_file'|language }}
												 </span> 
											 </label>
											 <mat-error
												 *ngIf="
													 submitted &&
													 this.attachments.controls[i].get('file').errors
														 ?.required
												 "
											 >
												 {{ "lang_field_required" | language }}</mat-error
											 >
										 </td>

										 <td class="verticalalignmid">
											 <mat-icon
												 (click)="removefiles(i)"
												 class="mnabricon red"
												 matTooltip="{{ 'lang_delete' | language }}"
												 >delete_forever</mat-icon
											 >

											<!--  <mat-icon
											 *ngIf="this.attachments.controls[i].get('file_id')"
											 (click)="removefiles(i)"
											 class="mnabricon"
											 matTooltip="{{ 'lang_download' | language }}"
											 >download</mat-icon
										 > -->

										 <i 
										 *ngIf="this.attachments.controls[i].get('file_id').value"
									 (click)="download_ED_history(this.attachments.controls[i].value)"
									 matTooltip="{{ 'lang_download' | language }}"
									 class="fa fa-download mnabricon">

										 </i>
										 </td>
									 </tr>
								 </tbody>
							 </table>
						 </div>
					 </fieldset>
				 </div>
			 </div>
	  
			 <div class="row pb-3 pt-3">
				<div class="col-sm-8 col-12"></div>
				<div class="col-sm-4 col-12">
					<button mat-button type="submit" class="mnabrbutton mat-accent test" >
						{{'lang_submit'|language}}
					</button>
				</div>
			</div>
		</form>
	</div>
 



