import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-excel-history-modal',
  templateUrl: './excel-history-modal.component.html',
  styleUrls: ['./excel-history-modal.component.scss']
})
export class ExcelHistoryModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
