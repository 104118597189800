<div mat-dialog-title class="d-flex justify-content-between">
	<h4>{{ "lang_users" | language }}</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content>
	<div class="col-12">
		<div
			class="table-responsive customResponsive tablefields quotationitemtable"
		>
			<table border="1" class="table CustomTable" width="100%">
				<thead>
					<tr>
						<td width="10%" style="padding: 5px; word-break: break-all">#</td>
						<td style="padding: 5px; word-break: break-all">
							{{ "lang_user_id" | language }}
						</td>
						<td style="padding: 5px; word-break: break-all">
							{{ "lang_human_resources_fname_english" | language }}
						</td>
						<td style="padding: 5px; word-break: break-all">
							{{ "lang_human_resources_lname_english" | language }}
						</td>
						<td>{{ "lang_action" | language }}</td>
					</tr>
				</thead>
				<tbody *ngIf="users?.length">
					<tr
						*ngFor="
							let user of users;
							let i = index;
							let odd = odd;
							let even = even
						"
					>
						<td
							class="verticalalignmid"
							style="padding: 5px; word-break: break-all"
						>
							{{ i + 1 }}
						</td>
						<td
							style="
								padding: 5px;
								word-break: break-all;
								position: relative;
								text-align: center;
							"
						>
							{{ user?.emp_id }}
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="user?.firstname_english && lang_key == 'en'"
						>
							{{ user?.firstname_english }}
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="!user?.firstname_english && lang_key == 'en'"
						>
							---
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="user?.lastname_english && lang_key == 'en'"
						>
							{{ user?.lastname_english }}
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="!user?.lastname_english && lang_key == 'en'"
						>
							---
						</td>

						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="user?.firstname_arabic && lang_key == 'ar'"
						>
							{{ user?.firstname_arabic }}
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="!user?.firstname_arabic && lang_key == 'ar'"
						>
							---
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="user?.lastname_arabic && lang_key == 'ar'"
						>
							{{ user?.lastname_arabic }}
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="!user?.lastname_arabic && lang_key == 'ar'"
						>
							---
						</td>
						<td>
							<i
								class="pi pi-minus-circle red ml-2 mr-2"
								matTooltip="Unfollow"
								(click)="unfollowUser(user)"
								*ngIf="isAdmin"
							></i>
							<i
								class="pi pi-lock mnabricon"
								matTooltip="Unlock User From Unfollow"
								(click)="unLockUser(user)"
								*ngIf="user?.is_can_delete == '1' && isAdmin"
							></i>
							<i
								class="pi pi-lock-open mnabricon"
								matTooltip="Lock User From Unfollow"
								(click)="lockUser(user)"
								*ngIf="user?.is_can_delete == '0' && isAdmin"
							></i>
						</td>
					</tr>
				</tbody>
				<tbody *ngIf="!users?.length">
					<tr>
						<td colspan="5" class="text-center">
							{{ "lang_no_data" | language }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="col-12 mt-3 d-flex align-items-center justify-content-between">
		<h3 style="font-size: 20px">{{ "lang_external_persons" | language }}</h3>
		<i
			class="fa fa-user-plus mnabricon"
			(click)="onAddUpdateExternalPersons()"
			matTooltip="{{ 'lang_add' | language }}"
		></i>
	</div>
	<div class="col-md-12">
		<div class="table-responsive dropdownyes customResponsive">
			<table border="1" class="table CustomTable">
				<thead>
					<tr>
						<td>#</td>
						<td>{{ "lang_name" | language }}</td>
						<td>{{ "lang_mobile_number" | language }}</td>
						<td>{{ "lang_email" | language }}</td>
						<td>{{ "lang_ondate" | language }}</td>
						<td>{{ "lang_action" | language }}</td>
					</tr>
				</thead>
				<tbody *ngIf="pedata.length != '0'" class="AppendList">
					<tr
						*ngFor="
							let list of pedata.data;
							let i = index;
							let odd = odd;
							let even = even
						"
						[ngClass]="{ odd: odd, even: even }"
					>
						<td>{{ i + 1 }}</td>

						<td>{{ list.person_name }}</td>
						<td>{{ list.mobile_no }}</td>
						<td>{{ list.email }}</td>
						<td>{{ list.insert_date }}</td>
						<td>
							<i
								class="fa fa-pencil-square-o mnabricon mr-2 ml-2"
								(click)="onAddUpdateExternalPersons(list)"
								matTooltip="{{ 'lang_edit' | language }}"
							></i>
							<i
								class="fa fa-trash red"
								(click)="deleteExternalPerson(list?.person_id)"
								matTooltip="{{ 'lang_delete' | language }}"
							></i>
						</td>
					</tr>
				</tbody>
				<tbody *ngIf="pedata.length == '0'" class="AppendList">
					<tr class="odd">
						<td colspan="8" align="center">{{ lodingdatas }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="pedata?.data?.length" class="card-footer padb0">
			<div class="row responsive_pagination">
				<div
					class="col-lg-3 col-sm-3 mb-10"
					style="height: fit-content; margin: auto 0"
				>
					{{ "lang_total" | language }} : {{ pedata?.page?.total_records }}
				</div>
				<div
					class="col-lg-6 col-sm-6 mb-10"
					style="display: flex; justify-content: center"
				>
					<ngb-pagination
						class="m-auto"
						[collectionSize]="pedata?.page?.total_records"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="pepage_no"
						[pageSize]="pepage_size"
						(pageChange)="loadpage($event)"
					></ngb-pagination>
				</div>
				<div
					class="col-lg-3 col-sm-3"
					style="display: flex; justify-content: end; margin: auto"
				>
					<mat-select
						[(ngModel)]="pepage_size"
						(selectionChange)="loadpagesize($event.value)"
					>
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
