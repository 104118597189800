import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";

@Component({
	selector: "app-payment-filters",
	templateUrl: "./payment-filters.component.html",
	styleUrls: ["./payment-filters.component.scss"],
})
export class PaymentFiltersComponent implements OnInit {
	filtersForm: FormGroup;
	projects_work_area_id: any;
	projects_profile_id: any;
	projects_supervision_id: any;
	lang_key = localStorage.getItem("lang_key") || "en";
	unitList = [];
	titlesList = [];
	subTitlesList = [];
	templatesList = [];
	platformList = [];
	worklevelsList = [];
	group1List = [];
	typeList = [];
	group2List = [];
	zones = [];
	blocks = [];
	clusters = [];
	constructor(
		public dialogRef: MatDialogRef<PaymentFiltersComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private spinner: PreloaderService,
		private ds: DataService,
		private fb: FormBuilder
	) {
		this.buildFilters();
		this.projects_work_area_id = data?.projects_work_area_id;
		this.projects_profile_id = data?.projects_profile_id;
		this.projects_supervision_id = data?.projects_supervision_id;
		if (data?.oldFilters) {
			this.filtersForm.patchValue(data?.oldFilters);
		}
	}

	ngOnInit(): void {
		this.getDataForFilters("units");
		this.getDataForFilters("titles");
		this.getDataForFilters("templates");
		this.getDataForFilters("platforms");
		this.getDataForFilters("worklevels");
		this.getDataForFilters("group1");
		this.getDataForFilters("types");
		this.getDataForFilters("group2");
		this.get_zones();
	}

	buildFilters() {
		this.filtersForm = this.fb.group({
			unit_ids: [null],
			title_ids: [null],
			sub_title_ids: [null],
			template_ids: [null],
			platform_code_systems: [null],
			platform_work_level_keys: [null],
			platform_group1_code_systems: [null],
			platform_group_type_code_systems: [null],
			platform_group2_code_systems: [null],
			phase_zone_nos: [null],
			phase_zone_block_nos: [null],
			phase_zone_block_cluster_nos: [null],
			transaction_date_start: [null],
			transaction_date_end: [null],
			submit_data: [null],
			pending_levels: [null],
			paid_levels: [null],
		});
	}
	getDataForFilters(data_name: string) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("lang_key", this.lang_key || "");
		let endPoint = "";
		if (data_name == "units") {
			endPoint = "form/FORM_CPAY/get_filter_api_datas/U";
		} else if (data_name == "titles") {
			endPoint = "form/FORM_CPAY/get_filter_api_datas/T";
		} else if (data_name == "subTitles") {
			endPoint = "form/FORM_CPAY/get_filter_api_datas/ST";
			formData.append(
				"title_id",
				this.filtersForm.get("title_ids").value || ""
			);
		} else if (data_name == "templates") {
			endPoint = "form/FORM_CPAY/get_filter_api_datas/TM";
		} else if (data_name == "platforms") {
			endPoint = "form/FORM_CPAY/get_filter_api_datas/P";
		} else if (data_name == "worklevels") {
			endPoint = "form/FORM_CPAY/get_filter_api_datas/W";
		} else if (data_name == "group1") {
			endPoint = "form/FORM_CPAY/get_filter_api_datas/G1";
		} else if (data_name == "types") {
			endPoint = "form/FORM_CPAY/get_filter_api_datas/TP";
		} else if (data_name == "group2") {
			endPoint = "form/FORM_CPAY/get_filter_api_datas/G2";
		}
		this.ds
			.getActionByUrl(this.ds.formData2string(formData), endPoint)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						if (data_name == "units") {
							this.unitList = res?.records;
						} else if (data_name == "titles") {
							this.titlesList = res?.records;
						} else if (data_name == "subTitles") {
							this.subTitlesList = res?.records;
						} else if (data_name == "templates") {
							this.templatesList = res?.records;
						} else if (data_name == "platforms") {
							this.platformList = res?.records;
						} else if (data_name == "worklevels") {
							this.worklevelsList = res?.records;
						} else if (data_name == "group1") {
							this.group1List = res?.records;
						} else if (data_name == "types") {
							this.typeList = res?.records;
						} else if (data_name == "group2") {
							this.group2List = res?.records;
						}
					}
				},
				(err) => {
					this.spinner.hide();
				}
			);
	}
	get_zones() {
		this.spinner.show();
		let phaseId = "0",
			param = new FormData();
		param.append("phase_parent_id", phaseId);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(param),
				"joYF29rbEi/" +
					this.projects_profile_id +
					"/" +
					this.projects_supervision_id
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.zones = res?.records;
				}
			});
	}

	get_blocks() {
		let phaseId = this.filtersForm.get("phase_zone_nos").value?.phase_id;
		if (phaseId) {
			this.spinner.show();
			let param = new FormData();
			param.append("phase_parent_id", phaseId);
			this.ds
				.getActionByUrl(
					this.ds.formData2string(param),
					"joYF29rbEi/" +
						this.projects_profile_id +
						"/" +
						this.projects_supervision_id
				)
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.blocks = res?.records;
					} else {
						this.blocks = [];
					}
				});
		} else {
			this.blocks = [];
			this.filtersForm.get("phase_zone_nos").setValue(null);
			this.clusters = [];
			this.filtersForm.get("phase_zone_block_cluster_nos").setValue(null);
		}
	}

	get_clusters() {
		let phaseId = this.filtersForm.get("phase_zone_block_nos").value?.phase_id;
		if (phaseId) {
			this.spinner.show();
			let param = new FormData();
			param.append("phase_parent_id", phaseId);
			this.ds
				.getActionByUrl(
					this.ds.formData2string(param),
					"joYF29rbEi/" +
						this.projects_profile_id +
						"/" +
						this.projects_supervision_id
				)
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.clusters = res?.records;
					} else {
						this.clusters = [];
					}
				});
		} else {
			this.clusters = [];
		}
	}

	onSubmit() {
		this.dialogRef.close(this.filtersForm.value);
	}
}
