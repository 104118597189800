import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-add-update-stage",
	templateUrl: "./add-update-stage.component.html",
	styleUrls: ["./add-update-stage.component.scss"],
})
export class AddUpdateStageComponent implements OnInit {
	data_for_add: any;
	vat_list = [];
	vat_value = null;
	lang_key = localStorage.getItem("lang_key") || "en";
	stage_form: FormGroup;
	update_data;
	edit_mode = false;
	contract_id;
	taskList = [];
	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<AddUpdateStageComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private lang: LanguagePipe,
		public ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService
	) {
		this.contract_id = data?.contract_id;
		this.update_data = data?.stage;
		this.edit_mode = data?.stage ? true : false;
	}

	ngOnInit(): void {
		this.build_form();
		this.getForAddStage();
		if (this.edit_mode) {
			this.setEditForm();
		}
	}

	build_form() {
		this.stage_form = this.fb.group({
			stage_id_constant: ["", Validators.required],
			contract_stage_name_english: ["", Validators.required],
			contract_stage_name_arabic: ["", Validators.required],
			contract_stage_payment: [null, Validators.required],
			contract_stage_payment_status: [null, Validators.required],
		});
	}
	setEditForm() {
		this.stage_form
			.get("stage_id_constant")
			.setValue(this.update_data?.stage_id);
		this.stage_form
			.get("contract_stage_name_english")
			.setValue(this.update_data?.contract_stage_name_english);
		this.stage_form
			.get("contract_stage_name_arabic")
			.setValue(this.update_data?.contract_stage_name_arabic);
		this.stage_form
			.get("contract_stage_payment")
			.setValue(this.update_data?.contract_stage_payment);
		this.stage_form
			.get("contract_stage_payment_status")
			.setValue(this.update_data?.contract_stage_payment_status);
	}
	getForAddStage() {
		this.ds.post("project_design/get_add_stage", {}).subscribe((res) => {
			if (res?.status) {
				this.data_for_add = res;
			}
		});
	}

	onSubmit() {
		if (this.stage_form.valid) {
			this.spinner.show();
			let formData = new FormData();
			formData.append(
				"stages[1][stage_id_constant]",
				this.stage_form.get("stage_id_constant").value || ""
			);
			formData.append(
				"stages[1][contract_stage_name_english]",
				this.stage_form.get("contract_stage_name_english").value || ""
			);
			formData.append(
				"stages[1][contract_stage_name_arabic]",
				this.stage_form.get("contract_stage_name_arabic").value || ""
			);
			formData.append(
				"stages[1][contract_stage_payment]",
				this.stage_form.get("contract_stage_payment").value || ""
			);
			formData.append(
				"stages[1][contract_stage_payment_status]",
				this.stage_form.get("contract_stage_payment_status").value || ""
			);
			formData.append("contract_id", this.contract_id || "");
			this.ds
				.post("project_design/add_stage_to_project_contract", formData)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res?.status) {
							this.dialogRef.close(true);
							this.sweetAlert.successToast(
								this.stage_form.get("contract_stage_name_english").value +
									" " +
									"Created Successfuly",
								1000
							);
						} else {
							this.dialogRef.close(false);
							this.sweetAlert.errorMessage(res?.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.ds.dialogf(
							"",
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				);
		} else {
			this.sweetAlert.errorMessage(this.lang.transform("lang_required_fields"));
		}
	}
	onUpdateProject() {
		if (this.stage_form.valid) {
			this.spinner.show();
			let formData = new FormData();
			formData.append(
				"stages[1][stage_id_constant]",
				this.stage_form.get("stage_id_constant").value || ""
			);
			formData.append(
				"stages[1][contract_stage_name_english]",
				this.stage_form.get("contract_stage_name_english").value || ""
			);
			formData.append(
				"stages[1][contract_stage_name_arabic]",
				this.stage_form.get("contract_stage_name_arabic").value || ""
			);
			formData.append(
				"stages[1][contract_stage_payment]",
				this.stage_form.get("contract_stage_payment").value || ""
			);
			formData.append(
				"stages[1][contract_stage_payment_status]",
				this.stage_form.get("contract_stage_payment_status").value || ""
			);
			formData.append(
				"stage_row_id",
				this.update_data?.contract_stage_id || ""
			);
			this.ds
				.post("project_design/update_stage_to_project_contract", formData)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res?.status) {
							this.dialogRef.close(true);
							this.sweetAlert.successToast(
								this.stage_form.get("contract_stage_name_english").value +
									" " +
									"Updated Successfuly",
								1000
							);
						} else {
							this.dialogRef.close(false);
							this.sweetAlert.errorMessage(res?.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.ds.dialogf(
							"",
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				);
		} else {
			this.sweetAlert.errorMessage(this.lang.transform("lang_required_fields"));
		}
	}
}
