<div class="row pt-3">
	<div class="col-12 mb-10">
		<div class="input-group input-group-sm">
			<input
				type="text"
				class="form-control almnabrformcontrol"
				(keyup)="loadGroups()"
				[(ngModel)]="searchKey"
				placeholder="{{ 'lang_search' | language }}"
			/>
			<span class="input-group-btn">
				<button
					id="btnSearch"
					type="button"
					matTooltip="{{ 'lang_add' | language }}"
					class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
					(click)="onAddGroup()"
				></button>
			</span>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-md-12">
		<div class="table-responsive dropdownyes customResponsive">
			<table border="1" class="table CustomTable">
				<thead>
					<tr>
						<td>#</td>
						<td>{{ "lang_name" | language }}</td>
						<td>{{ "lang_email" | language }}</td>
						<td>{{ "lang_action" | language }}</td>
					</tr>
				</thead>
				<tbody *ngIf="groups?.data?.length" class="AppendList">
					<tr
						*ngFor="
							let list of groups?.data;
							let i = index;
							let odd = odd;
							let even = even
						"
						[ngClass]="{ odd: odd, even: even }"
					>
						<td>{{ i + 1 }}</td>
						<td>{{ list.group_name }}</td>
						<td>{{ list.group_email }}</td>
						<td>
							<div class="d-flex align-items-center justify-content-center">
								<i
									class="fa fa-eye mnabricon mr-1 ml-1"
									(click)="onViewGroup(list)"
									matTooltip="{{ 'lang_view' | language }}"
								></i>
								<i
									class="fa fa-edit mnabricon mr-1 ml-1"
									(click)="onUpdateGroup(list)"
									matTooltip="{{ 'lang_edit' | language }}"
								></i>
								<i
									class="fa fa-trash red mr-1 ml-1"
									(click)="deleteGroup(list)"
									matTooltip="{{ 'lang_delete' | language }}"
								></i>
							</div>
						</td>
					</tr>
				</tbody>
				<tbody *ngIf="!groups?.data?.length" class="AppendList">
					<tr class="odd">
						<td colspan="10" align="center">{{ nodata }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
<div *ngIf="groups?.data?.length" class="mb-10 custom-footer">
	<div class="row responsive_pagination">
		<div
			class="col-lg-3 col-sm-3 col-6 mb-10"
			style="height: fit-content; margin: auto 0"
		>
			{{ "lang_total" | language }} :
			{{ groups?.page?.total_records ? groups?.page?.total_records : 0 }}
		</div>
		<div
			class="col-lg-6 col-sm-6 mb-10"
			style="display: flex; justify-content: center"
		>
			<ngb-pagination
				class="m-auto"
				[collectionSize]="groups?.page?.total_records"
				[rotate]="true"
				[ellipses]="false"
				[maxSize]="3"
				[boundaryLinks]="true"
				[(page)]="page_no"
				[pageSize]="page_size"
				(pageChange)="loadGroups()"
			></ngb-pagination>
		</div>
		<div
			class="col-lg-3 col-sm-3"
			style="display: flex; justify-content: end; margin: auto"
		>
			<mat-select [(ngModel)]="page_size" (selectionChange)="loadGroups()">
				<mat-option class="text-center" [value]="10"
					>10 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" [value]="20"
					>20 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" [value]="50"
					>50 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" [value]="100"
					>100 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option class="text-center" [value]="500"
					>500 {{ "lang_per_page" | language }}</mat-option
				>
			</mat-select>
		</div>
	</div>
</div>
