<breadcrumb></breadcrumb>
<div class="themesection">
  <div class="container mb-10">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-xs-12 mar0auto">
        <div *ngIf="!branch_id" class="red pad0 text-center">
          {{ "lang_choose_branch" | language }}
        </div>
        <select
          name="branch_id"
          class="form-control almnabrformcontrol"
          matTooltip="{{ 'lang_branch' | language }}"
          [(ngModel)]="branch_id"
          (change)="selectBrach($event.target.value)"
        >
          <option value="0">{{ "lang_choose_options" | language }}</option>
          <option *ngFor="let branch of branches" value="{{ branch.id }}">
            {{ branch.title }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <section
    class="panel-expansion matborderno"
    *ngIf="branch_id && branch_id != '0'"
  >
    <mat-accordion class="accounts_masters">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_accounts_account_master" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15">
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <ng-select
                  [items]="financeList"
                  [multiple]="false"
                  bindLabel="label"
                  bindValue="value"
                  clearAllText="Clear"
                  [searchFn]="ds.customSearchFn"
                  appendTo="body"
                  [(ngModel)]="finance_id"
                  placeholder="{{ 'lang_finance' | language }}"
                  (change)="load_account_tree()"
                >
                </ng-select>
              </div>
              <div class="col-md-5 col-sm-5 mb-10">
                <div class="input-group input-group-sm mb-10">
                  <input
                    name="account_search"
                    id="filter"
                    #filter
                    class="enablecode h34 form-control almnabrformcontrol"
                    type="text"
                    (keyup)="tree.treeModel.filterNodes(filter.value)"
                    autocomplete="off"
                    placeholder="{{ 'lang_search' | language }}"
                  />
                  <span class="input-group-btn">
                    <button
                      id="btnSearch"
                      type="button"
                      matTooltip="{{ 'lang_clear' | language }}"
                      class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-times red blue-tooltip"
                      (click)="filter.value = ''; clear(tree.treeModel)"
                    ></button>
                  </span>
                  <span class="input-group-btn">
                    <button
                      type="button"
                      matTooltip="{{ 'lang_refresh' | language }}"
                      class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-refresh red blue-tooltip"
                      (click)="load_account_tree(); clear(tree.treeModel)"
                    ></button>
                  </span>
                </div>
              </div>
              <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                <button
                  type="button"
                  class="albutton"
                  (click)="export_exl_pdf('E')"
                >
                  {{ "lang_export_excel" | language }}
                </button>
              </div>
              <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                <button
                  type="button"
                  class="albutton"
                  (click)="export_exl_pdf('P')"
                >
                  {{ "lang_export_pdf" | language }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12 leftdiv">
                <div class="treeheader albutton">
                  {{ "lang_accounts_account_master_tree" | language }}
                  <div class="actionDiv floatright" *ngIf="viewstatus">
                    <i
                      class="fa fa-plus fa-icon floatright mnabricon s2icon"
                      (click)="treeAction(tree.treeModel, 'add')"
                      *ngIf="ds.allowMe('accounts', 'accounts_add')"
                      matTooltip="{{ 'lang_add' | language }}"
                    ></i>
                    <i
                      class="fa fa-eye fa-icon floatright mnabricon s2icon"
                      (click)="treeAction(tree.treeModel, 'view')"
                      matTooltip="{{ 'lang_view' | language }}"
                    ></i>
                    <i
                      class="fa fa-pencil-square-o fa-icon floatright s2icon mnabricon"
                      *ngIf="ds.allowMe('accounts', 'accounts_edit') && edit"
                      (click)="treeAction(tree.treeModel, 'edit')"
                      matTooltip="{{ 'lang_edit' | language }}"
                    ></i>
                    <i
                      class="fa fa-trash fa-icon floatright mnabricon s2icon"
                      *ngIf="
                        ds.allowMe('accounts', 'accounts_delete') && delete
                      "
                      (click)="treeAction(tree.treeModel, 'delete')"
                      matTooltip="{{ 'lang_delete' | language }}"
                    ></i>
                  </div>
                </div>
                <div class="treepanel tree">
                  <tree-root
                    #tree
                    [options]="options"
                    [nodes]="nodes"
                    (click)="toggleAction(tree.treeModel)"
                  ></tree-root>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 rightdiv" #rightdiv>
                <div
                  class="rightpanel"
                  *ngIf="
                    ds.allowMe('accounts', 'accounts_view') &&
                    form_show_status == 'VIEW'
                  "
                >
                  <div class="treeheader albutton">
                    {{ "lang_view" | language }}
                  </div>
                  <div *ngIf="viewdata" class="padding15">
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_branch" | language }} :</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b class="breakall">{{ viewdata.branch_name }}</b>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_accounts_acccode" | language }}:</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b class="breakall">{{
                            viewdata.account_masters_code
                          }}</b>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_title_english" | language }}:</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b class="breakall">{{
                            viewdata.account_masters_name_en
                          }}</b>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_title_arabic" | language }}:</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b class="breakall">{{
                            viewdata.account_masters_name_ar
                          }}</b>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_currencies" | language }} :</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b class="breakall">{{ viewdata.currencylabel }}</b>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_parent_account_type" | language }} :</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b class="breakall">{{ viewdata.accountlabel }}</b>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_balance_sheets" | language }} :</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b class="breakall">{{ viewdata.balancelabel }}</b>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_sub_account" | language }}:</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b
                            class="breakall"
                            *ngIf="viewdata.account_masters_support == '1'"
                            >{{ "lang_yes" | language }}</b
                          >
                          <b
                            class="breakall"
                            *ngIf="viewdata.account_masters_support == '0'"
                            >{{ "lang_no" | language }}</b
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{
                            "lang_accounts_cost_center_support" | language
                          }}:</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b
                            class="breakall"
                            *ngIf="viewdata.cost_center_support == '1'"
                            >{{ "lang_yes" | language }}</b
                          >
                          <b
                            class="breakall"
                            *ngIf="viewdata.cost_center_support == '0'"
                            >{{ "lang_no" | language }}</b
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group"
                      *ngIf="viewdata.cost_center_support == '1'"
                    >
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_accounts_cost_center" | language }}:</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b
                            class="breakall"
                            *ngFor="
                              let cc of viewdata.account_masters_cost_center_id
                            "
                            >{{ cc.label }}<br
                          /></b>
                        </div>
                      </div>
                    </div>
                    <!-- <div
											class="form-group"
											*ngIf="
												viewdata.opening_balance &&
												viewdata.opening_balance_type
											"
										>
											<div class="row">
												<label class="col-md-6 col-sm-6 col-xs-12"
													>{{
														"lang_accounts_opening_balance" | language
													}}:</label
												>
												<div class="col-md-6 col-sm-6 col-xs-12">
													<b class="breakall"
														>{{ viewdata.opening_balance }} ({{
															viewdata.opening_balance_type
														}})</b
													>
												</div>
											</div>
										</div> -->
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_accounts_vat_number" | language }}:</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b
                            class="breakall"
                            *ngIf="viewdata.account_vat_number"
                            >{{ viewdata.account_vat_number }}</b
                          >
                          <b
                            class="breakall"
                            *ngIf="!viewdata.account_vat_number"
                            >----</b
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_writer" | language }}:</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b class="breakall">{{ viewdata.writer_name }}</b>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_created_date" | language }}:</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b class="breakall">{{
                            viewdata.account_masters_created_datetime
                          }}</b>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group"
                      *ngIf="viewdata.account_masters_updated_datetime"
                    >
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12"
                          >{{ "lang_onupdate" | language }}:</label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <b class="breakall">{{
                            viewdata.account_masters_updated_datetime
                          }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="rightpanel"
                  *ngIf="
                    ds.allowMe('accounts', 'accounts_add') &&
                    form_show_status == 'ADD'
                  "
                >
                  <div class="treeheader albutton">
                    {{ "lang_addnew" | language }}
                  </div>
                  <form
                    class="accountmasterform padding15"
                    [formGroup]="accountmasterform"
                    (ngSubmit)="create_account_master(tree.treeModel)"
                  >
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10"
                          >{{ "lang_add_root" | language
                          }}<span class="red"> * </span>
                          <i
                            class="fa fa-question-circle mnabricon fa-icon"
                            matTooltip="{{ 'lang_root_node' | language }}"
                            aria-hidden="true"
                          ></i
                        ></label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <select
                            class="form-control almnabrformcontrol"
                            formControlName="account_master_root"
                            matTooltip="{{ 'lang_add_root' | language }}"
                          >
                            <option value="">
                              {{ "lang_choose_options" | language }}
                            </option>
                            <option value="0">
                              {{ "lang_no" | language }}
                            </option>
                            <option value="1">
                              {{ "lang_yes" | language }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <input type="hidden" formControlName="account_masters_id" />
                    <input type="hidden" formControlName="branch_id" />
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10"
                          >{{ "lang_accounts_acccode" | language }}
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <input
                            type="text"
                            formControlName="account_masters_code"
                            placeholder="{{
                              'lang_accounts_acccode' | language
                            }}"
                            readonly
                            class="form-control almnabrformcontrol"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="account_create_type">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10"
                          >{{ "lang_accounts_create_type" | language }}
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <select
                            class="form-control almnabrformcontrol"
                            formControlName="account_create_type"
                            matTooltip="{{
                              'lang_account_create_type' | language
                            }}"
                          >
                            <option value="">
                              {{ "lang_choose_options" | language }}
                            </option>
                            <option value="next">
                              {{ "lang_next_account" | language }}
                            </option>
                            <option value="child">
                              {{ "lang_child_account" | language }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_title_english" | language }}
                          <span class="red">*</span></label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <input
                            type="text"
                            formControlName="account_masters_name_en"
                            placeholder="{{ 'lang_title_english' | language }}"
                            class="form-control almnabrformcontrol"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10"
                          >{{ "lang_title_arabic" | language }}
                          <span class="red">*</span></label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <input
                            type="text"
                            formControlName="account_masters_name_ar"
                            placeholder="{{ 'lang_title_arabic' | language }}"
                            class="form-control almnabrformcontrol"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10"
                          >{{ "lang_currencies" | language }}
                          <span class="red">*</span></label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <angular2-multiselect
                            formControlName="account_masters_currency_id"
                            [data]="currencies"
                            [settings]="singlesettings"
                          ></angular2-multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10"
                          >{{ "lang_accounts_types" | language }}
                          <span class="red">*</span></label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <angular2-multiselect
                            formControlName="account_masters_type"
                            (onSelect)="emptyBalanceSheets($event, 'select')"
                            (onDeSelect)="
                              emptyBalanceSheets($event, 'deselect')
                            "
                            [data]="acctypes"
                            [settings]="singlesettings"
                          ></angular2-multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10"
                          >{{ "lang_balance_sheets" | language }}
                          <span class="red">*</span>
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <angular2-multiselect
                            formControlName="account_masters_balance_sheet_id"
                            [data]="balancesheets"
                            [settings]="singlesettings"
                          ></angular2-multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10"
                          >{{ "lang_sub_account" | language }}
                          <span class="red"> * </span>
                          <i
                            class="fa fa-question-circle fa-icon mnabricon"
                            matTooltip="{{ 'lang_subaccount_info' | language }}"
                            aria-hidden="true"
                          ></i>
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <select
                            class="form-control almnabrformcontrol"
                            formControlName="account_masters_support"
                            matTooltip="{{ 'lang_sub_account' | language }}"
                          >
                            <option value="">
                              {{ "lang_choose_options" | language }}
                            </option>
                            <option value="0">
                              {{ "lang_no" | language }}
                            </option>
                            <option value="1">
                              {{ "lang_yes" | language }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_hold_account_from_transaction" | language }}
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <mat-checkbox
                            formControlName="hold_account_from_transaction"
                          ></mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_set_profit_loss_account" | language }}
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <mat-checkbox
                            formControlName="set_profit_loss_account"
                          ></mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10"
                          >{{ "lang_accounts_vat_number" | language }}
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <input
                            type="text"
                            formControlName="account_vat_number"
                            class="form-control almnabrformcontrol"
                            autocomplete="off"
                            placeholder="{{
                              'lang_accounts_vat_number' | language
                            }}"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_accounts_cost_center_support" | language }}
                          <span class="red">*</span></label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <select
                            class="form-control almnabrformcontrol"
                            formControlName="cost_center_support"
                            (change)="cost_support()"
                            matTooltip="{{
                              'lang_accounts_cost_center_support' | language
                            }}"
                          >
                            <option value="">
                              {{ "lang_choose_options" | language }}
                            </option>
                            <option value="0">
                              {{ "lang_no" | language }}
                            </option>
                            <option value="1">
                              {{ "lang_yes" | language }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group"
                      *ngIf="
                        accountmasterform.get('cost_center_support').value ==
                        '1'
                      "
                    >
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_accounts_cost_center" | language }}
                          <span class="red">*</span>
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <angular2-multiselect
                            formControlName="account_masters_cost_center_id"
                            [data]="costcenters"
                            [settings]="multisettings"
                          >
                            <c-search>
                              <ng-template>
                                <input
                                  type="text"
                                  (keyup)="searchCostCenter($event)"
                                  style="
                                    border: none;
                                    width: 100%;
                                    height: 100%;
                                    outline: none;
                                  "
                                />
                              </ng-template>
                            </c-search>
                            <c-item>
                              <ng-template let-item="item">
                                <label
                                  *ngIf="item.label"
                                  style="color: #333; width: 150px"
                                  >{{ item.label }}</label
                                >
                              </ng-template>
                            </c-item>
                          </angular2-multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <app-alert id="default-alert"></app-alert>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10 mar0auto">
                          <button type="submit" class="albutton">
                            {{ "lang_submit" | language }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  class="rightpanel"
                  *ngIf="
                    form_show_status == 'EDIT' &&
                    ds.allowMe('accounts', 'accounts_edit')
                  "
                >
                  <div class="treeheader albutton">
                    {{ "lang_update" | language }}
                  </div>
                  <form
                    class="accountmasterform padding15"
                    [formGroup]="accountmasterform"
                    (ngSubmit)="update_account_master(tree.treeModel)"
                  >
                    <input type="hidden" formControlName="account_masters_id" />
                    <input type="hidden" formControlName="branch_id" />
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_accounts_acccode" | language }}
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <input
                            type="text"
                            formControlName="account_masters_code"
                            placeholder="{{
                              'lang_accounts_acccode' | language
                            }}"
                            readonly
                            class="form-control almnabrformcontrol"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10"
                          >{{ "lang_title_english" | language }}
                          <span class="red">*</span></label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <input
                            type="text"
                            formControlName="account_masters_name_en"
                            placeholder="{{ 'lang_title_english' | language }}"
                            class="form-control almnabrformcontrol"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_title_arabic" | language }}
                          <span class="red">*</span></label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <input
                            type="text"
                            formControlName="account_masters_name_ar"
                            placeholder="{{ 'lang_title_arabic' | language }}"
                            class="form-control almnabrformcontrol"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10"
                          >{{ "lang_currencies" | language }}
                          <span class="red">*</span></label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <select
                            formControlName="account_masters_currency_id"
                            [data]="currencies"
                            [settings]="singlesettings"
                            ariaPlaceholder="selected"
                          ></select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_accounts_types" | language }}
                          <span class="red">*</span></label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <angular2-multiselect
                            formControlName="account_masters_type"
                            (onSelect)="emptyBalanceSheets($event, 'select')"
                            (onDeSelect)="
                              emptyBalanceSheets($event, 'deselect')
                            "
                            [data]="acctypes"
                            [settings]="singlesettings"
                          ></angular2-multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_balance_sheets" | language }}
                          <span class="red">*</span>
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <angular2-multiselect
                            formControlName="account_masters_balance_sheet_id"
                            [data]="balancesheets"
                            [settings]="singlesettings"
                          ></angular2-multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_sub_account" | language }}
                          <span class="red">*</span>
                          <i
                            class="fa fa-question-circle fa-icon mnabricon"
                            matTooltip="{{ 'lang_subaccount_info' | language }}"
                            aria-hidden="true"
                          ></i>
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <select
                            class="form-control almnabrformcontrol"
                            formControlName="account_masters_support"
                            matTooltip="{{ 'lang_sub_account' | language }}"
                          >
                            <option value="">
                              {{ "lang_choose_options" | language }}
                            </option>
                            <option value="0">
                              {{ "lang_no" | language }}
                            </option>
                            <option value="1">
                              {{ "lang_yes" | language }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_hold_account_from_transaction" | language }}
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <mat-checkbox
                            formControlName="hold_account_from_transaction"
                          ></mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_set_profit_loss_account" | language }}
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <mat-checkbox
                            formControlName="set_profit_loss_account"
                          ></mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_accounts_vat_number" | language }}
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <input
                            type="text"
                            formControlName="account_vat_number"
                            class="form-control almnabrformcontrol"
                            autocomplete="off"
                            placeholder="{{
                              'lang_accounts_vat_number' | language
                            }}"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_accounts_cost_center_support" | language }}
                          <span class="red">*</span></label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <select
                            class="form-control almnabrformcontrol"
                            formControlName="cost_center_support"
                            matTooltip="{{
                              'lang_accounts_cost_center_support' | language
                            }}"
                            (change)="cost_support()"
                          >
                            <option value="">
                              {{ "lang_choose_options" | language }}
                            </option>
                            <option value="0">
                              {{ "lang_no" | language }}
                            </option>
                            <option value="1">
                              {{ "lang_yes" | language }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group"
                      *ngIf="
                        accountmasterform.get('cost_center_support').value ==
                        '1'
                      "
                    >
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          {{ "lang_accounts_cost_center" | language }}
                          <span class="red">*</span></label
                        >
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                          <angular2-multiselect
                            formControlName="account_masters_cost_center_id"
                            [data]="costcenters"
                            [settings]="multisettings"
                          >
                            <c-search>
                              <ng-template>
                                <input
                                  type="text"
                                  (keyup)="searchCostCenter($event)"
                                  style="
                                    border: none;
                                    width: 100%;
                                    height: 100%;
                                    outline: none;
                                  "
                                />
                              </ng-template>
                            </c-search>
                            <c-item>
                              <ng-template let-item="item">
                                <label
                                  *ngIf="item.label"
                                  style="color: #333; width: 150px"
                                  >{{ item.label }}</label
                                >
                              </ng-template>
                            </c-item>
                          </angular2-multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 col-sm-6 mb-10">
                          <label>
                            {{ "lang_finance" | language }}
                            <span class="red">*</span></label
                          >
                        </div>
                        <div class="col-12 col-sm-6 mb-10">
                          <ng-select
                            [items]="financeList"
                            [multiple]="false"
                            bindLabel="label"
                            bindValue="value"
                            clearAllText="Clear"
                            [searchFn]="ds.customSearchFn"
                            appendTo="body"
                            formControlName="finance_id"
                            placeholder="{{ 'lang_finance' | language }}"
                          >
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <app-alert id="default-alert"></app-alert>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10 mar0auto">
                          <button type="submit" class="albutton">
                            {{ "lang_submit" | language }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
