import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.scss']
})
export class AddTemplateComponent implements OnInit {
  lang_key = localStorage.getItem('lang_key') || 'en';
  templateForm:FormGroup;
  ticket_id:string;
  constructor(private ds:DataService, private sweetAlert: SweetAlertService, private lang:LanguagePipe,public dialogRef: MatDialogRef<AddTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { 
      this.ticket_id = data?.ticket_id;
    }

  ngOnInit(): void {
    this.buildForm();
  }
  buildForm() {
    this.templateForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    });
  }

  add_template() {
    if(this.templateForm.valid) {
      let formData = new FormData();
      formData.append('ticket_id', this.ticket_id);
      formData.append('title', this.templateForm.value.title);
      formData.append('description', this.templateForm.value.description);
      this.ds.post('tasks/create_form_ticket', formData).subscribe(res => {
        if(res?.status) {
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 2000);
          this.dialogRef.close();
        } else {
          this.sweetAlert.errorToast(res?.error, 1500);
        }
      }, error => {
        this.sweetAlert.errorToast(this.lang.transform('lang_internal_server_error'), 1500);
      });
    }
  }
}
