<breadcrumb></breadcrumb>
<div class="supervisiondashboard" *ngIf="template_show_option == 'VIEW'">
	<section class="panel-expansion matborderno" *ngIf="wholedata.length != '0'">
		<div class="row" *ngIf="project_profile_id && project_supervision_id">
			<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
				<button
					*ngIf="view"
					type="button"
					routerLink="/projects/LtYh/{{ project_profile_id }}"
					class="albutton mnabrbutton"
					mat-raised-button
				>
					{{ "lang_project_view" | language }}
				</button>
			</div>
			<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
				<button
					*ngIf="view"
					type="button"
					routerLink="/projects/psmaindashboard/{{ project_profile_id }}/{{
						project_supervision_id
					}}"
					class="albutton mnabrbutton"
					mat-button
				>
					{{ "lang_supervision_dashboard" | language }}
				</button>
			</div>
			<div class="col-md-1 col-sm-1 col-xs-12 mb-10"></div>
			<div class="col-md-3 col-sm-4 col-xs-12 mb-10">
				<button
					*ngIf="view"
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
		</div>
		<mat-accordion *ngIf="supervision_data.length != '0'">
			<mat-expansion-panel class="mpanel" [expanded]="true">
				<mat-expansion-panel-header>{{
					"lang_supervision_operation_details" | language
				}}</mat-expansion-panel-header>
				<div class="material-panel-body">
					<div class="mbrl15 martb0">
						<div
							class="row"
							[ngStyle]="
								lang_key == 'ar'
									? {
											'padding-left': '10px',
											'padding-right': '10px'
									  }
									: null
							"
						>
							<div class="col-xs-12 col-12 pad0 suppervision-stepper">
								<div class="wrapper">
									<owl-carousel-o [options]="customOptions" #stepperCar>
										<ng-container>
											<ng-template carouselSlide id="s1">
												<div (click)="moveStepper('form_transaction')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-id-card-o fa-icon stepper-header-icon stepper-active form_transaction"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{
															'lang_form_transaction' | language
														}}"
														style="text-align: center"
													>
														{{ "lang_formations" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container
											*ngIf="
												tabs_permission?.tab_team_users ||
												tabs_permission?.tab_contractors ||
												tabs_permission?.tab_customers
											"
										>
											<ng-template carouselSlide id="s5">
												<div (click)="moveStepper('users')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-users fa-icon stepper-header-icon users"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_users' | language }}"
														style="text-align: center"
													>
														{{ "lang_users" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_phases">
											<ng-template carouselSlide id="s2">
												<div (click)="moveStepper('phases')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-building fa-icon stepper-header-icon phases"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{
															'lang_implementation_phases' | language
														}}"
														style="text-align: center"
													>
														{{ "lang_implementation_phases" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container
											*ngIf="tabs_permission?.tab_contractor_payments"
										>
											<ng-template carouselSlide id="s8">
												<div (click)="moveStepper('contract_payments')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-money fa-icon stepper-header-icon contract_payments"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{
															'lang_contract_payments' | language
														}}"
														style="text-align: center"
													>
														{{ "lang_contract_payments" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container
											*ngIf="
												tabs_permission?.tab_documents ||
												tabs_permission?.tab_communication_access ||
												tabs_permission?.tab_reports ||
												tabs_permission?.tab_tender_drawing ||
												tabs_permission?.tab_mobilization ||
												tabs_permission?.tab_bill_quantities ||
												tabs_permission?.tab_time_schedule
											"
										>
											<ng-template carouselSlide id="s6">
												<div (click)="moveStepper('documents')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-files-o fa-icon stepper-header-icon documents"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_documents' | language }}"
														style="text-align: center"
													>
														{{ "lang_documents" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_testing_report">
											<ng-template carouselSlide id="s3">
												<div (click)="moveStepper('test_report_workarea')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-flag fa-icon stepper-header-icon test_report_workarea"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{
															'lang_test_report_workarea' | language
														}}"
														style="text-align: center"
													>
														{{ "lang_test_report_workarea" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_contacts">
											<ng-template carouselSlide id="s4">
												<div (click)="moveStepper('contacts')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-mobile fa-icon stepper-header-icon contacts"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_contacts' | language }}"
														style="text-align: center"
													>
														{{ "lang_contacts" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container>
											<ng-template carouselSlide id="s4">
												<div (click)="moveStepper('owner')">
													<div class="step">
														<div class="st">
															<i
																class="fa fa-briefcase mnabricon stepper-header-icon owner"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_owner' | language }}"
														style="text-align: center"
													>
														{{ "lang_owner" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container>
											<ng-template carouselSlide id="s5">
												<div (click)="moveStepper('risk_management')">
													<div class="step">
														<div class="st">
															<i
																class="fa fa-exclamation mnabricon stepper-header-icon risk_management"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_risk_management' | language }}"
														style="text-align: center"
													>
														{{ "lang_risk_management" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container>
											<ng-template carouselSlide id="s6">
												<div (click)="moveStepper('wafi_report')">
													<div class="step">
														<div class="st">
															<i
																class="fa fa-file-pdf-o mnabricon stepper-header-icon wafi_report"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_wafi_report' | language }}"
														style="text-align: center"
													>
														{{ "lang_wafi_report" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container
											*ngIf="
												tabs_permission?.tab_project_completion ||
												user_type_id == '1' ||
												user_type_id == '4'
											"
										>
											<ng-template carouselSlide id="s7">
												<div (click)="moveStepper('project_completion')">
													<div class="step">
														<div class="st">
															<mat-icon
																class="mnabricon stepper-header-icon project_completion"
																>close</mat-icon
															>
														</div>
													</div>
													<div
														matTooltip="{{
															'lang_project_completion' | language
														}}"
														style="text-align: center"
													>
														{{ "lang_project_completion" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_setting">
											<ng-template carouselSlide id="s8">
												<div (click)="moveStepper('settings')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-cog fa-icon stepper-header-icon settings"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_settings' | language }}"
														style="text-align: center"
													>
														{{ "lang_settings" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
									</owl-carousel-o>
									<button
										(click)="
											lang_key == 'en' ? stepperCar.prev() : stepperCar.next()
										"
										class="carousel-btn-pre albutton mnabrbutton"
									>
										<i class="fa fa-chevron-left"></i>
									</button>
									<button
										(click)="
											lang_key == 'en' ? stepperCar.next() : stepperCar.prev()
										"
										class="carousel-btn-next albutton mnabrbutton"
									>
										<i class="fa fa-chevron-right"></i>
									</button>
								</div>
								<mat-horizontal-stepper #stepper class="pad0">
									<mat-step>
										<mat-tab-group *ngIf="selectedName == 'form_transaction'">
											<mat-tab>
												<ng-template mat-tab-label>
													{{ "lang_form_transaction" | language }}
												</ng-template>
												<app-project-workarea-forms
													(templateAction)="change_template_action($event)"
													[project_data]="wholedata.data"
													[filelevels]="filelevels"
													[supervision_data]="supervision_data"
												></app-project-workarea-forms>
											</mat-tab>
											<mat-tab
												*ngIf="
													ds.allowMe('projects', 'projects_template_permission')
												"
											>
												<ng-template mat-tab-label>
													{{ "lang_formations" | language }}
												</ng-template>
												<app-formation-lists
													(templateAction)="change_template_action($event)"
													[project_data]="wholedata.data"
													[filelevels]="filelevels"
													[supervision_data]="supervision_data"
													[able2add]="super_add"
													[able2edit]="super_edit"
												></app-formation-lists>
											</mat-tab>
										</mat-tab-group>
									</mat-step>
									<ng-container
										*ngIf="
											tabs_permission?.tab_team_users ||
											tabs_permission?.tab_contractors ||
											tabs_permission?.tab_customers
										"
									>
										<mat-step>
											<mat-tab-group *ngIf="selectedName == 'users'">
												<mat-tab *ngIf="tabs_permission?.tab_team_users">
													<ng-template mat-tab-label>
														{{ "lang_team_users" | language }}
													</ng-template>
													<app-supervision-teams
														[filelevels]="filelevels"
														[supervision_data]="supervision_data"
														[able2add]="super_add"
														[able2edit]="super_edit"
														[makeAction]="
															tabs_permission?.tab_team_users_action
														"
														[project_data]="wholedata.data"
													></app-supervision-teams>
												</mat-tab>
												<mat-tab *ngIf="tabs_permission?.tab_contractors">
													<ng-template mat-tab-label>
														{{ "lang_contractors" | language }}
													</ng-template>
													<app-supervision-contractors
														[filelevels]="filelevels"
														[supervision_data]="supervision_data"
														[able2add]="super_add"
														[able2edit]="super_edit"
														[project_data]="wholedata.data"
														[makeAction]="
															tabs_permission?.tab_contractors_action
														"
													></app-supervision-contractors>
												</mat-tab>
												<mat-tab *ngIf="tabs_permission?.tab_customers">
													<ng-template mat-tab-label>
														{{ "lang_customers" | language }}
													</ng-template>
													<app-supervision-customers
														[filelevels]="filelevels"
														[supervision_data]="supervision_data"
														[able2add]="super_add"
														[able2edit]="super_edit"
														[project_data]="wholedata.data"
														[makeAction]="tabs_permission?.tab_customers_action"
													></app-supervision-customers>
												</mat-tab>
											</mat-tab-group>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_phases">
										<mat-step>
											<app-supervision-i-phases
												*ngIf="selectedName == 'phases'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[makeAction]="
													tabs_permission?.tab_implementation_phase_action
												"
												[project_data]="wholedata.data"
											></app-supervision-i-phases>
										</mat-step>
									</ng-container>
									<ng-container
										*ngIf="tabs_permission?.tab_contractor_payments"
									>
										<mat-step>
											<app-supervision-contractor-payments
												*ngIf="selectedName == 'contract_payments'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
												[makeActionConfig]="
													tabs_permission?.tab_contractor_payments_config_action
												"
												[makeActionList]="
													tabs_permission?.tab_contractor_payments_form_lists_action
												"
											></app-supervision-contractor-payments>
										</mat-step>
									</ng-container>
									<ng-container
										*ngIf="
											tabs_permission?.tab_documents ||
											tabs_permission?.tab_communication_access ||
											tabs_permission?.tab_reports ||
											tabs_permission?.tab_tender_drawing ||
											tabs_permission?.tab_mobilization ||
											tabs_permission?.tab_bill_quantities ||
											tabs_permission?.tab_time_schedule
										"
									>
										<mat-step>
											<mat-tab-group *ngIf="selectedName == 'documents'">
												<mat-tab *ngIf="tabs_permission?.tab_documents">
													<ng-template mat-tab-label>
														{{ "lang_documents" | language }}
													</ng-template>
													<div class="p-2">
														<app-supervision-documents
															[filelevels]="filelevels"
															[supervision_data]="supervision_data"
															[able2add]="super_add"
															[able2edit]="super_edit"
															[project_data]="wholedata.data"
															[makeAction]="
																tabs_permission?.tab_documents_action
															"
														></app-supervision-documents>
													</div>
												</mat-tab>
												<mat-tab
													*ngIf="tabs_permission?.tab_communication_access"
												>
													<ng-template mat-tab-label>
														{{ "lang_communications" | language }}
													</ng-template>
													<div class="p-2">
														<app-communication
															[filelevels]="filelevels"
															[supervision_data]="supervision_data"
															[able2add]="super_add"
															[able2edit]="super_edit"
															[project_data]="wholedata.data"
															[makeAction]="
																tabs_permission?.tab_communication_action
															"
														></app-communication>
													</div>
												</mat-tab>
												<mat-tab *ngIf="tabs_permission?.tab_reports">
													<ng-template mat-tab-label>
														{{ "lang_report" | language }}
													</ng-template>
													<div class="p-2">
														<app-supervision-reports
															[filelevels]="filelevels"
															[supervision_data]="supervision_data"
															[able2add]="super_add"
															[able2edit]="super_edit"
															[project_data]="wholedata.data"
															[makeAction]="tabs_permission?.tab_reports_action"
														></app-supervision-reports>
													</div>
												</mat-tab>
												<mat-tab *ngIf="tabs_permission?.tab_tender_drawing">
													<ng-template mat-tab-label>
														{{ "lang_drawing" | language }}
													</ng-template>
													<div class="p-2">
														<app-supervision-drawings
															[filelevels]="filelevels"
															[supervision_data]="supervision_data"
															[able2add]="super_add"
															[able2edit]="super_edit"
															[project_data]="wholedata.data"
															[makeActionAdd]="
																tabs_permission?.tab_drawing_add_action
															"
															[makeActionEditDelete]="
																tabs_permission?.tab_drawing_edit_delete_action
															"
														></app-supervision-drawings>
													</div>
												</mat-tab>
												<mat-tab *ngIf="tabs_permission?.tab_mobilization">
													<ng-template mat-tab-label>
														{{ "lang_mobilization" | language }}
													</ng-template>
													<div class="p-2">
														<app-supervision-mobilizations
															[filelevels]="filelevels"
															[supervision_data]="supervision_data"
															[able2add]="super_add"
															[able2edit]="super_edit"
															[project_data]="wholedata.data"
															[makeAction]="
																tabs_permission?.tab_mobilization_action
															"
														></app-supervision-mobilizations>
													</div>
												</mat-tab>
												<mat-tab *ngIf="tabs_permission?.tab_bill_quantities">
													<ng-template mat-tab-label>
														{{ "lang_bill_quantities" | language }}
													</ng-template>
													<div class="p-2">
														<app-supervision-bill-quantities
															[filelevels]="filelevels"
															[supervision_data]="supervision_data"
															[able2add]="super_add"
															[able2edit]="super_edit"
															[project_data]="wholedata.data"
															[makeAction]="
																tabs_permission?.tab_bill_quantities_action
															"
														></app-supervision-bill-quantities>
													</div>
												</mat-tab>
												<mat-tab *ngIf="tabs_permission?.tab_time_schedule">
													<ng-template mat-tab-label>
														{{ "lang_time_schedule" | language }}
													</ng-template>
													<div class="p-2">
														<app-supervision-schedules
															[filelevels]="filelevels"
															[supervision_data]="supervision_data"
															[able2add]="super_add"
															[able2edit]="super_edit"
															[project_data]="wholedata.data"
															[makeAction]="
																tabs_permission?.tab_time_schedule_action
															"
														></app-supervision-schedules>
													</div>
												</mat-tab>
											</mat-tab-group>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_testing_report">
										<mat-step>
											<app-supervision-test-report-workarea
												*ngIf="selectedName == 'test_report_workarea'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
												[makeAction]="
													tabs_permission?.tab_testing_reports_action
												"
											></app-supervision-test-report-workarea>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_contacts">
										<mat-step>
											<app-supervision-contacts
												*ngIf="selectedName == 'contacts'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
												[makeAction]="tabs_permission?.tab_contacts_action"
											></app-supervision-contacts>
										</mat-step>
									</ng-container>
									<ng-container>
										<mat-step>
											<app-supervision-owner
												*ngIf="selectedName == 'owner'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
												[makeActionGroups]="
													tabs_permission?.tab_project_completion_group_action
												"
												[makeActionInitial]="
													tabs_permission?.tab_project_completion_initial_action
												"
												[makeActionOperations]="
													tabs_permission?.tab_project_completion_operation_action
												"
											></app-supervision-owner>
										</mat-step>
									</ng-container>
									<ng-container>
										<mat-step>
											<app-supervision-risk-management
												*ngIf="selectedName == 'risk_management'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-risk-management>
										</mat-step>
									</ng-container>
									<ng-container>
										<mat-step>
											<app-supervision-wafi-report
												*ngIf="selectedName == 'wafi_report'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-wafi-report>
										</mat-step>
									</ng-container>
									<ng-container
										*ngIf="
											tabs_permission?.tab_project_completion ||
											user_type_id == '1' ||
											user_type_id == '4'
										"
									>
										<mat-step>
											<app-supervision-project-completion
												*ngIf="selectedName == 'project_completion'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
												[makeActionGroups]="
													tabs_permission?.tab_project_completion_group_action
												"
												[makeActionInitial]="
													tabs_permission?.tab_project_completion_initial_action
												"
												[makeActionOperations]="
													tabs_permission?.tab_project_completion_operation_action
												"
											></app-supervision-project-completion>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_setting">
										<mat-step>
											<app-supervision-alerts
												*ngIf="selectedName == 'settings'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
												[makeAction]="tabs_permission?.tab_settings_action"
											></app-supervision-alerts>
										</mat-step>
									</ng-container>
								</mat-horizontal-stepper>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel class="mt-2">
				<mat-expansion-panel-header>{{
					"lang_workarea_transactions" | language
				}}</mat-expansion-panel-header>
				<div class="material-panel-body">
					<div class="mbrl15 martb0">
						<app-supervision-my-transctions
							[projects_work_area_id]="supervision_data?.projects_work_area_id"
							[projects_supervision_id]="project_supervision_id"
							[projects_profile_id]="project_profile_id"
						></app-supervision-my-transctions>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</section>
	<div class="modal" id="pdfPreviewSuperVision">
		<div class="modal-dialog width70">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">{{ "lang_preview" | language }}</h4>
					<button type="button" class="close" data-dismiss="modal">
						&times;
					</button>
				</div>
				<div class="modal-body">
					<div class="pdf-container">
						<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
					</div>
				</div>
				<!-- Modal footer -->
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-dismiss="modal">
						{{ "lang_close" | language }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="" *ngIf="template_show_option == 'TADD'">
	<app-formation-add
		(showAction)="change_template_action($event)"
		[project_data]="project_data"
		[filelevels]="filelevels"
		[supervision_data]="supervision_data"
		[template_edit_data]="template_edit_data"
		[template_id]="template_id"
		[actionMode]="actionMode"
	></app-formation-add>
</div>
<!-- wholedata.data.template_platform_code_system -->
<div
	class=""
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'DSR'
	"
>
	<app-form-ps-tr1-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		[unitsOfTemplate]="unitsOfTemplate"
		(showAction)="change_template_action($event)"
	>
	</app-form-ps-tr1-add>
</div>

<div
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'WIR'
	"
>
	<app-form-wri-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		[unitsOfTemplate]="unitsOfTemplate"
		(showAction)="change_template_action($event)"
	>
	</app-form-wri-add>
</div>

<div
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'SQR'
	"
>
	<app-form-sqr-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		[unitsOfTemplate]="unitsOfTemplate"
		(showAction)="change_template_action($event)"
	>
	</app-form-sqr-add>
</div>

<div
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'MSR'
	"
>
	<app-form-msr-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		[unitsOfTemplate]="unitsOfTemplate"
		(showAction)="change_template_action($event)"
	>
	</app-form-msr-add>
</div>

<div
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'MIR'
	"
>
	<app-form-mir-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		[unitsOfTemplate]="unitsOfTemplate"
		(showAction)="change_template_action($event)"
	>
	</app-form-mir-add>
</div>

<div
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'NCR'
	"
>
	<app-form-ncr-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[unitsOfTemplate]="unitsOfTemplate"
		[form_request_id]="'0'"
		(showAction)="change_template_action($event)"
	>
	</app-form-ncr-add>
</div>

<div
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'SI'
	"
>
	<app-form-si-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		(showAction)="change_template_action($event)"
	>
	</app-form-si-add>
</div>
