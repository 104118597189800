<section
	class="panel-expansion matborderno mt-4"
	*ngIf="branch_id && branch_id != '0'"
>
	<mat-accordion class="accounts_settings">
		<mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
			<mat-expansion-panel-header class="mpanel-header">
				<div
					class="d-flex align-items-center justify-content-between"
					style="width: 100%"
				>
					<span>{{ "lang_block_accounts" | language }}</span>
					<i
						class="fa fa-plus mnabricon"
						matTooltip="{{ 'lang_add' | language }}"
						(click)="onCreateUpdate()"
					></i>
				</div>
			</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15">
					<div class="row pb-3">
						<div class="col-12 col-sm-4">
							<ng-select
								[items]="financeList"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								clearAllText="Clear"
								[searchFn]="ds.customSearchFn"
								appendTo="body"
								[(ngModel)]="finance_id"
								placeholder="{{ 'lang_finance' | language }}"
								(change)="getBlockAccounts()"
							>
							</ng-select>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="table-responsive customResponsive">
								<table border="1" class="table CustomTable">
									<thead>
										<tr>
											<td>#</td>
											<td>{{ "lang_start_date" | language }}</td>
											<td>{{ "lang_end_date" | language }}</td>
											<td>{{ "lang_ondate" | language }}</td>
											<td>{{ "lang_writer" | language }}</td>
											<td>{{ "lang_action" | language }}</td>
										</tr>
									</thead>
									<tbody *ngIf="blockAccounts.length" class="AppendList">
										<tr
											*ngFor="
												let data of blockAccounts;
												let i = index;
												let odd = odd;
												let even = even
											"
											[ngClass]="{ odd: odd, even: even }"
										>
											<td>{{ i + 1 }}</td>
											<td>{{ data.start_date }}</td>
											<td>{{ data.end_date }}</td>
											<td>{{ data.created_datetime }}</td>
											<td>{{ data.writer }}</td>
											<td>
												<i
													class="fa fa-pencil-square-o faicon mnabricon s2icon"
													(click)="onCreateUpdate(data)"
													matTooltip="{{ 'lang_edit' | language }}"
												></i>
												<i
													class="fa fa-trash faicon red mnabricon s2icon"
													(click)="deleteBlockAccount(data)"
													matTooltip="{{ 'lang_delete' | language }}"
												></i>
											</td>
										</tr>
									</tbody>
									<tbody *ngIf="!blockAccounts.length" class="AppendList">
										<tr class="odd">
											<td colspan="6" align="center">
												{{ "lang_no_data" | language }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div
								*ngIf="blockAccounts.length"
								class="card-footer padb0 bottomfooter"
							>
								<div class="row responsive_pagination">
									<div
										class="col-lg-3 col-sm-3 mb-10"
										style="height: fit-content; margin: auto 0"
									>
										{{ "lang_total" | language }} :
										{{ total_records }}
									</div>
									<div
										class="col-lg-6 col-sm-6 mb-10"
										style="display: flex; justify-content: center"
									>
										<ngb-pagination
											class="m-auto"
											[collectionSize]="total_records"
											[rotate]="true"
											[ellipses]="false"
											[maxSize]="3"
											[boundaryLinks]="true"
											[(page)]="page_no"
											[pageSize]="page_size"
											(pageChange)="getBlockAccounts()"
										></ngb-pagination>
									</div>
									<div
										class="col-lg-3 col-sm-3 mb-10"
										style="display: flex; justify-content: end; margin: auto"
									>
										<mat-select
											[(ngModel)]="page_size"
											(selectionChange)="getBlockAccounts()"
										>
											<mat-option class="text-center" [value]="10"
												>10 {{ "lang_per_page" | language }}
											</mat-option>
											<mat-option class="text-center" [value]="20"
												>20 {{ "lang_per_page" | language }}
											</mat-option>
											<mat-option class="text-center" [value]="50"
												>50 {{ "lang_per_page" | language }}
											</mat-option>
											<mat-option class="text-center" [value]="100"
												>100 {{ "lang_per_page" | language }}</mat-option
											>
											<mat-option class="text-center" [value]="500"
												>500 {{ "lang_per_page" | language }}</mat-option
											>
										</mat-select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</section>
