<div class="row wafi_header">
  <div class="table-responsive customResponsive">
    <table border="1" class="table wafi_title" width="100%">
      <tbody>
        <tr>
          <td width="130px">
            <img src="assets/images/logoawafi.PNG" alt="" />
          </td>
          <td width="87.5%">
            <div class="title_content">
              {{
                "supervision consultant monthly report for july-2022" | language
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row wafi_main_reports">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody class="tbody_container">
        <tr>
          <td>
            <table border="1" class="table" width="100%">
              <tbody class="main_content">
                <tr>
                  <th>{{ "lang_report_date" | language }}</th>
                  <th>{{ "lang_project_code" | language }}</th>
                  <th>{{ "lang_project_name" | language }}</th>
                  <th>{{ "lang_city" | language }}</th>
                  <th>{{ "lang_region" | language }}</th>
                  <th>{{ "lang_land_ownership" | language }}</th>
                  <th>{{ "lang_land_area" | language }}</th>
                  <th>{{ "lang_construction_budget_cost" | language }}</th>
                </tr>
                <tr>
                  <td>{{ report_data?.wafi_report?.report_date || "---" }}</td>
                  <td>
                    {{
                      report_data?.report_work_area_info
                        ?.Projects_work_area_code || "---"
                    }}
                  </td>
                  <td>
                    {{
                      report_data?.report_work_area_info
                        ?.projects_profile_name || "---"
                    }}
                  </td>
                  <td>
                    {{ report_data?.report_work_area_info?.city || "---" }}
                  </td>
                  <td>
                    {{ report_data?.report_work_area_info?.region || "---" }}
                  </td>
                  <td>
                    {{
                      report_data?.report_work_area_info?.land_ownership ||
                        "---"
                    }}
                  </td>
                  <td>
                    {{ report_data?.report_work_area_info?.land_area || "---" }}
                  </td>
                  <td>
                    {{
                      report_data?.report_work_area_info
                        ?.total_construction_cost || "---"
                    }}
                  </td>
                </tr>
                <tr>
                  <th>{{ "lang_developer" | language }}</th>
                  <th>{{ "lang_consultant" | language }}</th>
                  <th>{{ "lang_constactor" | language }}</th>
                  <th>{{ "lang_no_of_zones" | language }}</th>
                  <th>{{ "lang_no_of_blocks" | language }}</th>
                  <th>{{ "lang_no_of_building" | language }}</th>
                  <th>{{ "lang_no_of_unites" | language }}</th>
                  <th>{{ "lang_types_of_unites" | language }}</th>
                </tr>
                <tr>
                  <td>
                    {{
                      report_data?.report_work_area_info?.customer_name || "---"
                    }}
                  </td>
                  <td>
                    {{
                      report_data?.report_work_area_info?.branch_name || "---"
                    }}
                  </td>
                  <td>
                    {{
                      report_data?.report_work_area_info?.contractor_name ||
                        "---"
                    }}
                  </td>
                  <td>
                    {{
                      report_data?.report_work_area_info?.total_no_of_zones ||
                        "---"
                    }}
                  </td>
                  <td>
                    {{
                      report_data?.report_work_area_info?.total_no_of_blocks ||
                        "---"
                    }}
                  </td>
                  <td>
                    {{
                      report_data?.report_work_area_info?.no_of_buildings ||
                        "---"
                    }}
                  </td>
                  <td>
                    {{
                      report_data?.report_work_area_info?.total_no_of_units ||
                        "---"
                    }}
                  </td>
                  <td>
                    {{
                      report_data?.report_work_area_info?.type_of_units || "---"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
