import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-unit-level-show',
  templateUrl: './unit-level-show.component.html',
  styleUrls: ['./unit-level-show.component.scss']
})
export class UnitLevelShowComponent implements OnInit {

  units_levels:any = [];
  unit_levels_msg = '';
  page = 1;
  size = 10;
  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe,
    public spinner : PreloaderService, public alert:AlertService, public router:Router, private dialogRef: MatDialogRef<UnitLevelShowComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data) {
      
      if(this.data?.unit_level && this.data?.unit_level?.status) {
        this.units_levels = this.data?.unit_level?.records;
        this.unit_levels_msg = this.data?.unit_level?.msg;
      }
    }

  ngOnInit(): void {
    
  }

  
  
  closeModal() {
    this.dialogRef.close();
  }

}
