<section class="panel-expansion">
    <mat-accordion class="appgroup2s" >
        <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_service_group1' | language }} 2</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15 martb0">
                    <div class="row">
                        <div class="col-md-2 col-xs-12 mb-10">
                            <ng-select 
                                [items]="group1s" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_group' | language}} 1"
                                [(ngModel)]="group1"
                                (change)="spinner.show();get_group_two_lists();">
                            </ng-select>
                        </div>
                        <div class="col-md-2 col-xs-12 mb-10">
                            <ng-select 
                                [items]="types" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_group_type' | language}}"
                                [(ngModel)]="platform_group_type_code_system"
                                (change)="spinner.show();get_group_two_lists();">
                            </ng-select>
                        </div>
                        <div class="col-md-2 col-xs-12 mb-10">
                            <ng-select 
                                [items]="services" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_project_scope' | language}}"
                                [(ngModel)]="projects_services_ids"
                                (change)="spinner.show();get_group_two_lists();">
                            </ng-select>
                        </div>
                        <div class="col-md-6 col-xs-12 mb-10">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control almnabrformcontrol" (keyup)="get_group_two_lists();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                                <span class="input-group-btn">
                                    <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" (click)="openModalA(groupTwoAction);form.reset();selectmultiple = true " id="btnSearch" type="button" matTooltip="{{'lang_add' | language }}"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsGr2 checkbox" id="allItemsGr2" (click)="checkAllItemsGr2($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_group' | language }} 1</td>
                                            <td>{{'lang_group_type' | language }}</td>
                                            <td>{{'lang_title_english' | language }}</td>
                                            <td>{{'lang_title_arabic' | language }}</td>
                                            <td>{{'lang_system_code' | language }}</td>
                                            <td>{{'lang_manual_code' | language }}</td>
                                            <td>{{'lang_group2_active_code' | language }}</td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="wholeData.length != '0' " class="AppendList">
                                        <tr *ngFor="let row of tabledata; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{row.platform_group2_id}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemGr2 checkbox" value="{{row.platform_group2_id}}" (click)="checkEachItemGr2($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{row.group1name}}</td>
                                            <td>{{row.typename}}</td>
                                            <td>{{row.platform_group2_title_english}}</td>
                                            <td>{{row.platform_group2_title_arabic}}</td>
                                            <td>{{row.platform_group2_code_system}}</td>
                                            <td *ngIf="row.platform_group2_code_manual">{{row.platform_group2_code_manual}}</td>
                                            <td *ngIf="!row.platform_group2_code_manual">----</td>
                                            <td *ngIf="row.platform_group2_active_code">{{row.platform_group2_active_code}}</td>
                                            <td *ngIf="!row.platform_group2_active_code">----</td>
                                            <td>{{row.writer}}</td>
                                            <td>
                                                <i class="fa fa-pencil-square-o s2icon fa-icon mnabricon" matTooltip="{{'lang_edit' | language }}" (click)="openModalA(groupTwoAction);edit_formdata(row);"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="wholeData.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="11" align="center">{{nodata}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="tabledata?.length != '0' " class="card-footer padb0">
                    <div class="row">
                        <div *ngIf="admin" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataGr2();" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-6 mb-10">
                            {{'lang_total' | language }} : {{collectionSize}}
                        </div>
                        <div class="col-lg-5 col-sm-6 mb-10">
                            <ngb-pagination [collectionSize]="collectionSize" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="page" [pageSize]="pageSize" (pageChange)="build_table_data()"></ngb-pagination>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <select class="customselect" [(ngModel)]="pageSize" (change)="build_table_data()">
                                <option [ngValue]="10">10</option>
                                <option [ngValue]="20">20</option>
                                <option [ngValue]="50">50</option>
                                <option [ngValue]="100">100</option>
                                <option [ngValue]="250">250</option>
                                <option [ngValue]="500">500</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<ng-template #groupTwoAction>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_group' | language }} 2 </h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefA.hide();">&times;</button>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit();">
            <div class="modal-body">
                
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="platform_group2_title_english">{{'lang_title_english' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="platform_group2_title_english" placeholder="{{'lang_title_english' | language }}"/>
                            <mat-error *ngIf="form.controls['platform_group2_title_english'].touched && form.controls['platform_group2_title_english'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="platform_group2_title_arabic">{{'lang_title_arabic' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="platform_group2_title_arabic" placeholder="{{'lang_title_arabic' | language }}"/>
                            <mat-error *ngIf="form.controls['platform_group2_title_arabic'].touched && form.controls['platform_group2_title_arabic'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="platform_group2_code_manual">{{'lang_manual_code' | language }} : </label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="platform_group2_code_manual" placeholder="{{'lang_manual_code' | language }}"/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="platform_group2_active_code">{{'lang_group2_active_code' | language }} : </label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="platform_group2_active_code" placeholder="{{'lang_group2_active_code' | language }}"/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="platform_group2_active_name">{{'lang_group2_active_code_name' | language }} : </label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="platform_group2_active_name" placeholder="{{'lang_group2_active_code_name' | language }}"/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="platform_group1_code_system">{{'lang_group' | language }} : 1 <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <ng-select 
                                [items]="group1s" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_group' | language}} 1"
                                formControlName="platform_group1_code_system">
                            </ng-select>
                            <mat-error *ngIf="form.controls['platform_group1_code_system'].touched && form.controls['platform_group1_code_system'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="platform_group_type_code_system">{{'lang_group_type' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <ng-select 
                                [items]="types" 
                                [multiple]="selectmultiple" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_group_type' | language}}"
                                formControlName="platform_group_type_code_system">
                            </ng-select>
                            <mat-error *ngIf="form.controls['platform_group_type_code_system'].touched && form.controls['platform_group_type_code_system'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="platform_group2_title_arabic">{{'lang_project_scope' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <ng-select 
                                [items]="services" 
                                [multiple]="true" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_project_scope' | language}}"
                                formControlName="projects_services_ids">
                            </ng-select>
                            <mat-error *ngIf="form.controls['projects_services_ids'].touched && form.controls['projects_services_ids'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefA.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>