import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-add-update-titles",
	templateUrl: "./add-update-titles.component.html",
	styleUrls: ["./add-update-titles.component.scss"],
})
export class AddUpdateTitlesComponent implements OnInit {
	mainTitleForm: FormGroup;
	projects_profile_id;
	projects_work_area_id;
	lang_key = localStorage.getItem("lang_key") || "en";
	isEdit = false;
	edit_data;
	type = "main";
	titleId_forsub;
	sub_title_id = null;
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public sweetalert: SweetAlertService,
		public dialog: MatDialog,
		public fb: FormBuilder,
		public dialogRef: MatDialogRef<AddUpdateTitlesComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.projects_profile_id = data?.projects_profile_id;
		this.projects_work_area_id = data?.projects_work_area_id;
		this.edit_data = data?.edit_data;
		this.titleId_forsub = data?.titleId;
		this.type = data?.type;
		if (this.edit_data) {
			this.isEdit = true;
		}
		if (this.type == "sub") {
			this.sub_title_id = data?.edit_data?.sub_title_id;
		}
	}

	ngOnInit(): void {
		this.buildAddUpdateForm();
		this.setEditData();
	}

	buildAddUpdateForm() {
		this.mainTitleForm = this.fb.group({
			title_english: ["", Validators.required],
			title_arabic: ["", Validators.required],
		});
	}
	setEditData() {
		if (this.type == "main") {
			this.mainTitleForm.patchValue({
				title_english: this.edit_data?.title_english,
				title_arabic: this.edit_data?.title_arabic,
			});
		} else if (this.type == "sub") {
			this.mainTitleForm.patchValue({
				title_english: this.edit_data?.sub_title_english,
				title_arabic: this.edit_data?.sub_title_arabic,
			});
		}
	}
	addUpdateMainTitle() {
		if (this.mainTitleForm.valid) {
			let formdata = new FormData();
			formdata.append("projects_profile_id", this.projects_profile_id || "");
			formdata.append(
				"projects_work_area_id",
				this.projects_work_area_id || ""
			);
			let endPoint = "p/cp/create_main_titles";
			if (!this.isEdit && this.type === "main") {
				endPoint = "p/cp/create_main_titles";
				formdata.append(
					"title_english",
					this.mainTitleForm.get("title_english").value || ""
				);
				formdata.append(
					"title_arabic",
					this.mainTitleForm.get("title_arabic").value || ""
				);
			} else if (this.isEdit && this.type === "main") {
				formdata.append("title_id", this.edit_data?.title_id);
				formdata.append(
					"title_english",
					this.mainTitleForm.get("title_english").value || ""
				);
				formdata.append(
					"title_arabic",
					this.mainTitleForm.get("title_arabic").value || ""
				);
				endPoint = "p/cp/update_main_titles";
			} else if (!this.isEdit && this.type === "sub") {
				formdata.append("title_id", this.titleId_forsub);
				formdata.append(
					"sub_title_english",
					this.mainTitleForm.get("title_english").value || ""
				);
				formdata.append(
					"sub_title_arabic",
					this.mainTitleForm.get("title_arabic").value || ""
				);
				endPoint = "p/cp/create_sub_titles";
			} else if (this.isEdit && this.type === "sub") {
				formdata.append("title_id", this.titleId_forsub);
				formdata.append("sub_title_id", this.sub_title_id);
				formdata.append(
					"sub_title_english",
					this.mainTitleForm.get("title_english").value || ""
				);
				formdata.append(
					"sub_title_arabic",
					this.mainTitleForm.get("title_arabic").value || ""
				);
				endPoint = "p/cp/update_sub_titles";
			}
			this.spinner.show();
			this.ds.post(endPoint, formdata).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.dialogRef.close(true);
						this.sweetalert.successToast(res?.msg, 2000);
					} else {
						this.sweetalert.errorToast(res.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetalert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
		} else {
			this.sweetalert.errorToast(
				this.lang.transform("lang_fill_required_fields"),
				2000
			);
		}
	}
}
