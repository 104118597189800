import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-cpay-steps-table",
	templateUrl: "./cpay-steps-table.component.html",
	styleUrls: ["./cpay-steps-table.component.scss"],
})
export class CpayStepsTableComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	@Input() tableContent: any;
	show_ok = true;
	show_notok = true;
	show_notuse = true;
	constructor() {}

	ngOnInit(): void {}
}
