import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-vaction',
  templateUrl: './view-vaction.component.html',
  styleUrls: ['./view-vaction.component.scss']
})
export class ViewVactionComponent implements OnInit {
  pageTitle = 'View Vaction' 
  pageIcon = "fa fa-briefcase";
  constructor() { }

  ngOnInit(): void {
  }

}
