<breadcrumb></breadcrumb>
<section class="panel-expansion">
  <div class="container mb-10">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-xs-12 offset-md-3">
        <i *ngIf="!selectedBranch" class="red pad0 text-center">{{
          "lang_choose_branch" | language
        }}</i>
        <select
          name="selectedBranch"
          class="form-control almnabrformcontrol"
          matTooltip="{{ 'lang_branch' | language }}"
          [(ngModel)]="selectedBranch"
          (change)="selectBranch($event.target.value)"
        >
          <option value="0">{{ "lang_choose_options" | language }}</option>
          <option *ngFor="let branch of branches" value="{{ branch.id }}">
            {{ branch.title }}
          </option>
        </select>
      </div>
      <div
        class="col-md-3 col-sm-6 col-xs-12"
        *ngIf="selectedBranch && selectedBranch != '0'"
      >
        <ng-select
          [items]="finances"
          [multiple]="false"
          bindLabel="label"
          bindValue="value"
          clearAllText="Clear"
          appendTo="body"
          [(ngModel)]="finance_id"
          placeholder="{{ 'lang_financial_years' | language }}"
          (change)="selectFinance()"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <mat-accordion
    class="stylepanels"
    *ngIf="selectedBranch && selectedBranch != '0'"
  >
    <mat-expansion-panel class="mpanel accsettings" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_settings" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <div class="mbrl15 mb-0">
          <div class="row">
            <div class="col-xs-12 col-12 pad0">
              <mat-horizontal-stepper #stepper class="pad0">
                <mat-step>
                  <ng-template matStepLabel>
                    <div class="step">
                      <div class="st">
                        <i class="icon-5x fa fa-users fa-icon"></i>
                      </div>
                    </div>
                    <div>{{ "lang_permit_mentions" | language }}</div>
                  </ng-template>
                  <app-permit-settings></app-permit-settings>
                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>
                    <div class="step">
                      <div class="st">
                        <i class="icon-5x fa fa-list fa-icon"></i>
                      </div>
                    </div>
                    <div>{{ "lang_account_settings" | language }}</div>
                  </ng-template>
                  <app-account-settings></app-account-settings>
                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>
                    <div class="step">
                      <div class="st">
                        <i class="icon-5x fa fa-percent fa-icon"></i>
                      </div>
                    </div>
                    <div>{{ "lang_tax" | language }}</div>
                  </ng-template>
                  <app-tax-settings></app-tax-settings>
                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>
                    <div class="step">
                      <div class="st">
                        <i class="icon-5x fa fa-cog fa-icon"></i>
                      </div>
                    </div>
                    <div>{{ "lang_invoice_settings" | language }}</div>
                  </ng-template>
                  <app-invoice-settings></app-invoice-settings>
                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>
                    <div class="step">
                      <div class="st">
                        <i class="icon-5x fa fa-money fa-icon"></i>
                      </div>
                    </div>
                    <div>{{ "lang_financial_years" | language }}</div>
                  </ng-template>
                  <app-financeyear></app-financeyear>
                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>
                    <div class="step">
                      <div class="st">
                        <i class="icon-5x fa fa-money fa-icon"></i>
                      </div>
                    </div>
                    <div>{{ "lang_block_accounts" | language }}</div>
                  </ng-template>
                  <app-block-accounts></app-block-accounts>
                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>
                    <div class="step">
                      <div class="st">
                        <i class="icon-5x fa fa-key fa-icon"></i>
                      </div>
                    </div>
                    <div>{{ "lang_keys_company" | language }}</div>
                  </ng-template>
                  <app-create-keys
                    [branch_id]="selectedBranch"
                  ></app-create-keys>
                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>
                    <div class="step">
                      <div class="st">
                        <i class="icon-5x fa fa-file-text"></i>
                      </div>
                    </div>
                    <div>{{ "lang_reports_groups" | language }}</div>
                  </ng-template>
                  <app-reports-groups
                    [branch_id]="selectedBranch"
                  ></app-reports-groups>
                </mat-step>
              </mat-horizontal-stepper>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
