<section
	class="card matborderno activeiconstepper customdesignstepper"
	*ngIf="showStatus"
>
	<mat-accordion class="stylepanels">
		<mat-expansion-panel class="mb-2" [expanded]="true">
			<mat-expansion-panel-header
				>{{ "lang_form_details" | language }}
			</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-3 col-sm-6 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_transaction_number" | language
							}}</label>
							<div class="">
								{{ transaction_request?.transaction_request_id }}
							</div>
						</div>
						<div class="col-md-3 col-sm-6 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_project_title" | language }}</label>
							<div class="">{{ project_data?.project_title }}</div>
						</div>
						<div class="col-md-3 col-sm-6 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_supervision_id" | language }}</label>
							<div class="">
								{{ supervision_data?.projects_supervision_id }}
							</div>
						</div>
						<div class="col-md-3 col-sm-6 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_supervision_title" | language
							}}</label>
							<div class="">{{ supervision_data?.quotation_subject }}</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_forms_code" | language }}</label>
							<div class="">
								{{ transaction_request?.transaction_key }}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_form_name" | language }}</label>
							<div class="">{{ template_data?.platformname }}</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_form_specifications" | language
							}}</label>
							<div class="">
								{{ template_data?.template_platform_item_specifications }}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_preview" | language }}</label>
							<div>
								<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
							</div>
						</div>
					</div>
					<div
						class="row"
						[ngStyle]="
							lang_key == 'ar'
								? {
										'padding-left': '10px',
										'padding-right': '10px'
								  }
								: null
						"
					>
						<div class="col-xs-12 col-12 pad0">
							<div class="stepper-progress">
								<div class="stepTitle" id="stepTitle" #stepTitleEl>
									<p>{{ stepTitle }}</p>
								</div>
								<p class="step-counter">
									{{ "lang_step" | language }} <b>{{ selectedIndex + 1 }}</b>
									{{ "lang_of" | language }}
									<b>{{ totalStepsCount }}</b>
								</p>
								<mat-progress-bar mode="determinate" [value]="progressValue">
								</mat-progress-bar>
							</div>
							<div class="row pb-3">
								<div class="col-sm-2">
									<button
										(click)="moveStepper('prev')"
										class="carousel-btn-pre albutton mnabrbutton mat-button"
									>
										<i
											class="fa"
											[ngClass]="{
												'fa-chevron-left': lang_key == 'en',
												'fa-chevron-right': lang_key == 'ar'
											}"
										></i>
									</button>
								</div>
								<div class="col-sm-8"></div>
								<div class="col-sm-2">
									<button
										(click)="moveStepper('next')"
										class="carousel-btn-next albutton mnabrbutton mat-button"
									>
										<i
											class="fa"
											[ngClass]="{
												'fa-chevron-right': lang_key == 'en',
												'fa-chevron-left': lang_key == 'ar'
											}"
										></i>
									</button>
								</div>
							</div>

							<mat-horizontal-stepper
								#stepper
								class="pad0 steps13-stepper"
								[linear]="isLinear"
								[selectedIndex]="currentStep"
								(selectionChange)="selectionChange($event)"
							>
								<mat-step>
									<app-si-step1
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
									>
									</app-si-step1>
								</mat-step>
								<mat-step>
									<app-si-step2
										[form_url]="form_url"
										[form_key]="form_key"
										[allData]="allData"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
									></app-si-step2>
								</mat-step>
								<mat-step>
									<app-si-step3
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
									></app-si-step3>
								</mat-step>
								<mat-step>
									<app-si-step4
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
									></app-si-step4>
								</mat-step>
								<mat-step>
									<app-si-step5
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
									></app-si-step5>
								</mat-step>
								<mat-step>
									<app-si-step6
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
									></app-si-step6>
								</mat-step>
								<mat-step>
									<app-si-step7
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
									></app-si-step7>
								</mat-step>
								<mat-step>
									<app-si-step8
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
									></app-si-step8>
								</mat-step>
							</mat-horizontal-stepper>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
		<mat-expansion-panel class="mb-2" *ngIf="!(selectedIndex == 0)">
			<mat-expansion-panel-header> Notes </mat-expansion-panel-header>
			<div class="row">
				<div class="col-12">
					<div class="TableParts">
						<div class="table-responsive dropdownyes customResponsive">
							<table border="1" class="table CustomTable">
								<thead>
									<tr>
										<td width="5%">#</td>
										<td>{{ "lang_notes" | language }}</td>
										<td>{{ "lang_writer" | language }}</td>
										<td>{{ "lang_ondate" | language }}</td>
										<td>{{ "lang_action" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="notes.length != '0'" class="AppendList">
									<tr
										*ngFor="
											let note of notes;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
										class="makeback removerow{{ note.note_id }}"
									>
										<td>{{ i + 1 }}</td>
										<td>{{ note.description }}</td>
										<td>{{ note.writer }}</td>
										<td>{{ note.created_date }}</td>
										<td>----</td>
									</tr>
								</tbody>
								<tbody *ngIf="notes.length == '0'" class="AppendList">
									<tr class="odd">
										<td colspan="6" align="center">
											{{ "lang_no_data" | language }}
										</td>
									</tr>
								</tbody>
							</table>
							<div
								*ngIf="notes.length != '0'"
								class="card-footer padb0 bottomfooter"
							>
								<div class="row">
									<div class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
										<i
											class="fa fa-trash-o faicon red deleteIcon"
											aria-hidden="true"
											matTooltip="{{ 'lang_delete' | language }}"
										></i>
									</div>
									<div class="col-lg-2 col-sm-6 col-6 mb-10">
										{{ "lang_total" | language }} :
										{{ notes.page.total_records }}
									</div>
									<div class="col-lg-5 col-sm-6 col-12 mb-10">
										<mat-radio-group aria-label="Select an option">
											<mat-radio-button
												value="10"
												checked
												(click)="spinner.show(); by_page_size(10)"
											>
												10</mat-radio-button
											>
											<mat-radio-button
												value="20"
												(click)="spinner.show(); by_page_size(20)"
											>
												20
											</mat-radio-button>
											<mat-radio-button
												value="50"
												(click)="spinner.show(); by_page_size(50)"
											>
												50
											</mat-radio-button>
											<mat-radio-button
												value="100"
												(click)="spinner.show(); by_page_size(100)"
											>
												100
											</mat-radio-button>
											<mat-radio-button
												value="500"
												(click)="spinner.show(); by_page_size(500)"
											>
												500
											</mat-radio-button>
										</mat-radio-group>
									</div>
									<div class="col-lg-4 col-sm-6 col-12 mb-10">
										{{ "lang_goto" | language }} :
										<select
											class="customselect"
											(change)="
												spinner.show(); by_page_number($event.target.value)
											"
										>
											<option
												*ngFor="let k of ds.getrange(notes.page.total_pages)"
												value="{{ k }}"
											>
												{{ k }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
		<mat-expansion-panel class="mb-2" *ngIf="!(selectedIndex == 0)">
			<mat-expansion-panel-header>{{
				"lang_person_details" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body communication">
				<div class="mbrl15">
					<div class="col-md-12 pad0">
						<div class="table-responsive customResponsive">
							<table border="1" class="table CustomTable">
								<thead>
									<tr>
										<td>#</td>
										<td>{{ "lang_name" | language }}</td>
										<td>{{ "lang_type" | language }}</td>
										<td>{{ "lang_view" | language }}</td>
										<td>{{ "lang_view_time" | language }}</td>
										<td>{{ "lang_last_view_time" | language }}</td>
										<td>{{ "lang_action" | language }}</td>
										<td>{{ "lang_step" | language }}</td>
										<td>{{ "lang_datetime" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="persons.length > 0" class="AppendList">
									<tr
										*ngFor="
											let person of persons;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
									>
										<td>{{ i + 1 }}</td>
										<td>{{ person.first_name }} {{ person.last_name }}</td>
										<td>{{ person.transaction_persons_type }}</td>
										<td *ngIf="person.transactions_persons_view == '1'">
											<i
												class="fa fa-check faicon successgreen"
												aria-hidden="true"
											></i>
										</td>
										<td *ngIf="person.transactions_persons_view == '0'">
											<i
												class="fa fa-check faicon silver"
												aria-hidden="true"
											></i>
										</td>
										<td>{{ person.transactions_persons_view_datetime }}</td>
										<td>
											{{ person.transactions_persons_view_datetime_lastupdate }}
										</td>
										<td *ngIf="person.transactions_persons_action_status == 1">
											<i
												class="fa fa-check faicon successgreen"
												aria-hidden="true"
											></i>
										</td>
										<td *ngIf="person.transactions_persons_action_status == 0">
											<i
												class="fa fa-check faicon silver"
												aria-hidden="true"
											></i>
										</td>
										<td>{{ person.transactions_persons_last_step }}</td>
										<td>{{ person.transactions_persons_action_datetime }}</td>
									</tr>
								</tbody>
								<tbody *ngIf="persons.length < 1" class="AppendList">
									<tr class="odd">
										<td colspan="8" class="nodata" align="center">
											{{ "lang_no_data" | language }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
		<mat-expansion-panel class="mb-2" *ngIf="!(selectedIndex == 0)">
			<mat-expansion-panel-header> Attachments </mat-expansion-panel-header>
			<div class="row">
				<div class="col-12">
					<div class="TableParts">
						<div class="table-responsive dropdownyes customResponsive">
							<table border="1" class="table CustomTable">
								<thead>
									<tr>
										<td width="5%">#</td>
										<td>{{ "lang_notes" | language }}</td>
										<td>{{ "lang_writer" | language }}</td>
										<td>{{ "lang_ondate" | language }}</td>
										<td>{{ "lang_action" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="notes.length != '0'" class="AppendList">
									<tr
										*ngFor="
											let note of notes;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
										class="makeback removerow{{ note.note_id }}"
									>
										<td>{{ i + 1 }}</td>
										<td>{{ note.description }}</td>
										<td>{{ note.writer }}</td>
										<td>{{ note.created_date }}</td>
										<td>----</td>
									</tr>
								</tbody>
								<tbody *ngIf="notes.length == '0'" class="AppendList">
									<tr class="odd">
										<td colspan="6" align="center">
											{{ "lang_no_data" | language }}
										</td>
									</tr>
								</tbody>
							</table>
							<div
								*ngIf="notes.length != '0'"
								class="card-footer padb0 bottomfooter"
							>
								<div class="row">
									<div class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
										<i
											class="fa fa-trash-o faicon red deleteIcon"
											aria-hidden="true"
											matTooltip="{{ 'lang_delete' | language }}"
										></i>
									</div>
									<div class="col-lg-2 col-sm-6 col-6 mb-10">
										{{ "lang_total" | language }} :
										{{ notes.page.total_records }}
									</div>
									<div class="col-lg-5 col-sm-6 col-12 mb-10">
										<mat-radio-group aria-label="Select an option">
											<mat-radio-button
												value="10"
												checked
												(click)="spinner.show(); by_page_size(10)"
											>
												10</mat-radio-button
											>
											<mat-radio-button
												value="20"
												(click)="spinner.show(); by_page_size(20)"
											>
												20
											</mat-radio-button>
											<mat-radio-button
												value="50"
												(click)="spinner.show(); by_page_size(50)"
											>
												50
											</mat-radio-button>
											<mat-radio-button
												value="100"
												(click)="spinner.show(); by_page_size(100)"
											>
												100
											</mat-radio-button>
											<mat-radio-button
												value="500"
												(click)="spinner.show(); by_page_size(500)"
											>
												500
											</mat-radio-button>
										</mat-radio-group>
									</div>
									<div class="col-lg-4 col-sm-6 col-12 mb-10">
										{{ "lang_goto" | language }} :
										<select
											class="customselect"
											(change)="
												spinner.show(); by_page_number($event.target.value)
											"
										>
											<option
												*ngFor="let k of ds.getrange(notes.page.total_pages)"
												value="{{ k }}"
											>
												{{ k }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="card-footer">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
			<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
				<button
					type="button"
					(click)="showAction.emit({ mode: 'VIEW', data: null })"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_supervision_operations" | language }}
				</button>
			</div>
			<div class="col-md-7 col-sm-2 col-xs-12 mb-10"></div>
		</div>
	</div>
</section>
