<div class="row pt-5">
  <div class="col-md-3 mb-10">
    <form [formGroup]="lang_form">
      <div class="row">
        <div class="col-12">
          <label>
            {{ "lang_languages" | language }}
            <span class="red">*</span></label
          >
          <select
            class="form-control almnabrformcontrol"
            formControlName="lang_key"
          >
            <option value="">
              {{ "lang_choose_options" | language }}
            </option>
            <option *ngFor="let lang of languages" value="{{ lang.value }}">
              {{ lang.label }}
            </option>
          </select>
          <mat-error
            *ngIf="submitted && lang_form.controls['lang_key'].errors?.required"
            >{{ "lang_field_required" | language }}</mat-error
          >
        </div>
      </div>
      <div class="row pt-2 pb-2">
        <div class="col-sm-12">
          <app-alert id="default-alert"></app-alert>
        </div>
      </div>
    </form>
  </div>
</div>
<fieldset>
  <legend>Configurations</legend>
  <form [formGroup]="work_sites_form">
    <div class="row">
      <!-- <div class="col-md-9 pr-5">
		  <p class="mb-10">
			{{ "lang_related_platform_access_codes_requirements" | language }}
			:
			<span
			  class="red bold"
			  *ngIf="platformData.length != 0 && !platformData.NextButton"
			  >{{ "lang_platform_note" | language }} :
			  {{ platformData.Rule.all_platforms_required_result_fail }}</span
			>
		  </p>
		  <div class="table-responsive customResponsive">
			<table border="1" class="table CustomTable">
			  <thead>
				<tr>
				  <td>#</td>
				  <td>{{ "lang_platform_access_codes" | language }}</td>
				  <td>{{ "lang_evaluation_result" | language }}</td>
				  <td>{{ "lang_action" | language }}</td>
				</tr>
			  </thead>
			  <tbody
				*ngIf="platformData?.records?.length != 0"
				class="AppendList"
			  >
				<tr
				  *ngFor="
					let row of platformData?.records;
					let i = index;
					let odd = odd;
					let even = even
				  "
				  [ngClass]="{ odd: odd, even: even }"
				>
				  <td>{{ i + 1 }}</td>
				  <td>{{ row.unit }}</td>
				  <td>{{ row.work_level_label }}</td>
				  <td>{{ row.platform_label }} ({{ row.required_platform }})</td>
				  <td *ngIf="row.result_code">{{ row.result_code }}</td>
				  <td *ngIf="!row.result_code">----</td>
				  <td *ngIf="row.file_path">
					<i
					  class="fa fa-icon mnabricon fa-file-pdf-o"
					  (click)="ds.preview_pdf_file(row.file_path, row?.unit)"
					></i>
				  </td>
				  <td *ngIf="!row.file_path">----</td>
				</tr>
			  </tbody>
			  <tbody
				*ngIf="
				  platformData?.records?.length == 0 || !platformData?.records
				"
				class="AppendList"
			  >
				<tr class="odd">
				  <td colspan="6" class="nodata" align="center">
					{{ nodata }}
				  </td>
				</tr>
			  </tbody>
			</table>
		  </div>
		</div> -->
    </div>
    <div class="row mb-10 p-1">
      <div class="col-md-3 mb-10">
        <label class="mb-10"
          >{{ "lang_separate" | language }} :
          <span class="red">*</span>
          <i
            style="padding: 0 10px"
            class="fa fa-info-circle orangestatus-color"
            matTooltip="{{ 'lang_separate_tooltip' | language }}"
          ></i>
        </label>
        <div class="col-sm-12 p-0">
          <mat-radio-group
            formControlName="separate"
            (change)="work_sites_form.controls['work_site'].setValue('')"
          >
            <mat-radio-button value="1">{{
              "lang_yes" | language
            }}</mat-radio-button>
            <mat-radio-button value="0">{{
              "lang_no" | language
            }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- <div class="col-md-9 pr-5">
		  <div class="table-responsive customResponsive">
			<table border="1" class="table CustomTable">
			  <thead>
				<tr>
				  <td>#</td>
				  <td>{{ "lang_transaction_number" | language }}</td>
				  <td>{{ "lang_unit" | language }}</td>
				  <td>{{ "lang_work_level" | language }}</td>
				  <td>{{ "lang_barcode" | language }}</td>
				  <td>{{ "lang_evaluation_result" | language }}</td>
				  <td>
					{{ "lang_date" | language }}
				  </td>
				  <td>{{ "lang_action" | language }}</td>
				</tr>
			  </thead>
			  <tbody *ngIf="versions?.records?.length != 0" class="AppendList">
				<tr
				  *ngFor="
					let row of versions?.records;
					let i = index;
					let odd = odd;
					let even = even
				  "
				  [ngClass]="{ odd: odd, even: even }"
				>
				  <td>{{ i + 1 }}</td>
				  <td>{{ row.transaction_request_id }}</td>
				  <td>{{ row.unit_id }}</td>
				  <td>{{ row.levelname }}</td>
				  <td *ngIf="row.tbv_barcodeData">
					{{ row.tbv_barcodeData }}
				  </td>
				  <td *ngIf="!row.tbv_barcodeData">----</td>
				  <td
					*ngIf="row.result_code"
					[ngClass]="{
					  redstatus: row.color == 'RED',
					  greenstatus: row.color == 'GREEN'
					}"
				  >
					{{ row.result_code }}
				  </td>
				  <td *ngIf="!row.result_code">----</td>
				  <td>{{ row.transactions_date_datetime }}</td>
				  <td *ngIf="row.file_path">
					<i
					  class="fa fa-icon mnabricon fa-file-pdf-o"
					  (click)="
						ds.preview_pdf_file(
						  row.file_path,
						  row?.transaction_request_id
						)
					  "
					></i>
				  </td>
				  <td *ngIf="!row.file_path">----</td>
				</tr>
			  </tbody>
			  <tbody
				*ngIf="versions?.records?.length == 0 || !versions?.records"
				class="AppendList"
			  >
				<tr class="odd">
				  <td colspan="8" class="nodata" align="center">
					{{ nodata }}
				  </td>
				</tr>
			  </tbody>
			</table>
		  </div>
		</div> -->
      <div class="col-md-6 mb-10">
        <label class="mb-10"
          >{{ "lang_work_site" | language }} : <span class="red">*</span></label
        >
        <i
          style="padding: 0 10px"
          class="fa fa-info-circle orangestatus-color"
          matTooltip="{{ 'lang_work_site_tooltip' | language }}"
        ></i>
        <span style="margin: 0 20px" *ngIf="unitsOfTemplate">
          {{ "lang_available_units" | language }} :
          <span
            class="bold"
            matTooltip="{{ unitsOfTemplate }}"
            *ngIf="unitsOfTemplate?.length > 10"
            >{{ unitsOfTemplate | shortenLength: 10 }}</span
          >
          <span class="bold" *ngIf="unitsOfTemplate?.length < 10">{{
            unitsOfTemplate | shortenLength: 10
          }}</span>
        </span>
        <div>
          <mat-radio-group
            formControlName="work_site"
            (change)="
              get_work_levels();
              set_validation_step1();
              generalNumbersData = [];
              phasesData = []
            "
          >
            <mat-radio-button value="GN">{{
              "lang_by_general_number" | language
            }}</mat-radio-button>
            <mat-radio-button value="IM">{{
              "lang_by_phases" | language
            }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-error
          *ngIf="
            submitted && work_sites_form.controls['work_site'].errors?.required
          "
          >{{ "lang_field_required" | language }}</mat-error
        >
      </div>
      <div
        class="col-sm-6 mb-10 mt-2"
        *ngIf="work_sites_form.get('work_site').value == 'ALL'"
      >
        <label class="mb-10"
          >{{ "lang_fill_worklevels" | language }} :
          <span class="red">*</span></label
        >

        <ng-select
          [items]="work_levels_for_all"
          [multiple]="true"
          bindLabel="label"
          bindValue="value"
          clearAllText="Clear"
          [searchFn]="ds.customSearchFn"
          appendTo="body"
          [formControl]="work_levels_for_all_control"
          (change)="getUnitsForAll()"
          placeholder="{{ 'lang_fill_worklevels' | language }}"
          [notFoundText]="'lang_no_data' | language"
        >
        </ng-select>
        <mat-error *ngIf="work_levels_for_all_control.errors?.required">{{
          "lang_field_required" | language
        }}</mat-error>
      </div>
      <div
        class="col-sm-12 mb-2 pt-3"
        *ngIf="work_sites_form.get('work_site').value == 'GN'"
      >
        <div class="table-responsive dropdownyes customResponsive tablefields">
          <table border="1" class="table CustomTable">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <i
                    class="fa fa-plus green"
                    style="font-size: 15px"
                    (click)="addByGeneralNumbers()"
                    #tooltip="matTooltip"
                    matTooltip="{{ 'lang_add' | language }}"
                  ></i>
                </th>
                <th>{{ "lang_units" | language }}</th>
                <th>{{ "lang_work_levels" | language }}</th>
                <th>{{ "lang_barcode" | language }}</th>
                <th>{{ "lang_evaluation_result" | language }}</th>
                <th>{{ "lang_platform_access_codes" | language }}</th>
                <th>
                  {{ "lang_date" | language }}
                </th>
              </tr>
            </thead>
            <tbody *ngIf="generalNumbersData.length">
              <tr
                *ngFor="
                  let item of generalNumbersData;
                  let i = index;
                  let odd = odd;
                  let even = even
                "
              >
                <td>{{ i + 1 }}</td>
                <td>
                  <i
                    class="fa fa-trash red"
                    style="font-size: 15px"
                    (click)="deleteByGeneralNumbers(i)"
                    #tooltip="matTooltip"
                    matTooltip="{{ 'lang_delete' | language }}"
                  ></i>
                </td>
                <td>{{ item.units }}</td>
                <td>{{ item.displayWorklevels }}</td>
                <td>12</td>
                <td>{{ item.units }}</td>
                <td>{{ item.displayWorklevels }}</td>
                <td>16/11/2020</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row pb-1">
      <div
        class="col-sm-12 mb-2"
        *ngIf="work_sites_form.get('work_site').value == 'IM'"
      >
        <div class="table-responsive dropdownyes customResponsive tablefields">
          <table border="1" class="table CustomTable">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <i
                    class="fa fa-plus green"
                    style="font-size: 15px"
                    (click)="addByPhases()"
                    #tooltip="matTooltip"
                    matTooltip="{{ 'lang_add' | language }}"
                  ></i>
                </th>
                <th>{{ "lang_zones" | language }}</th>
                <th>{{ "lang_blocks" | language }}</th>
                <th>{{ "lang_clusters" | language }}</th>
                <th>{{ "lang_units" | language }}</th>
                <th>{{ "lang_work_level" | language }}</th>
              </tr>
            </thead>
            <tbody *ngIf="phasesData.length">
              <tr
                *ngFor="
                  let item of phasesData;
                  let i = index;
                  let odd = odd;
                  let even = even
                "
              >
                <td>{{ i + 1 }}</td>
                <td>
                  <i
                    class="fa fa-trash red"
                    style="font-size: 15px"
                    (click)="deleteByPhases(i)"
                    #tooltip="matTooltip"
                    matTooltip="{{ 'lang_delete' | language }}"
                  ></i>
                </td>
                <td>{{ item.zone }}</td>
                <td>{{ item.block }}</td>
                <td>{{ item.cluster }}</td>
                <td>{{ item.units }}</td>
                <td>{{ item.displayWorklevels }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <app-alert id="default-alert"></app-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-9 mb-10"></div>
      <div class="col-sm-3 mb-10">
        <button
          class="albutton mnabrbutton mat-accent"
          mat-button
          (click)="send_files_to_step2()"
        >
          {{ "lang_submit" | language }}
        </button>
      </div>
    </div>
  </form>
</fieldset>
<fieldset class="mt-5">
  <legend>Contractor</legend>
  <form>
    <div class="row mb-10 p-1">
      <div class="col-md-3 mb-10 mb-10">
        <label class="mb-10"
          >{{ "lang_show_location" | language }} :
          <span class="red">*</span></label
        >
        <i
          style="padding: 0 10px"
          class="fa fa-info-circle orangestatus-color"
          matTooltip="{{ 'lang_show_location_tooltip' | language }}"
        ></i>
        <div>
          <mat-radio-group>
            <mat-radio-button value="1">{{
              "lang_yes" | language
            }}</mat-radio-button>
            <mat-radio-button value="0">{{
              "lang_no" | language
            }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 mb-10 mb-10">
        <label class="mb-10"
          >{{ "lang_attached_test_certificates" | language }} :
          <span class="red">*</span></label
        >
        <i
          style="padding: 0 10px"
          class="fa fa-info-circle orangestatus-color"
          matTooltip="{{
            'lang_attached_test_certificates_tooltip' | language
          }}"
        ></i>
        <div>
          <mat-radio-group>
            <mat-radio-button value="1">{{
              "lang_yes" | language
            }}</mat-radio-button>
            <mat-radio-button value="0">{{
              "lang_no" | language
            }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div class="row mb-10 p-1">
      <div class="col-md-3 mb-10">
        <label class="mb-10"
          >{{ "lang_date_of_submission" | language }} :
        </label>
        <input
          type="text"
          value="1/12/2022"
          class="form-control almnabrformcontrol"
          disabled
        />
      </div>
      <div class="col-md-3 mb-10">
        <label class="mb-10"
          >{{ "lang_time_of_submission" | language }} :
        </label>
        <input
          type="text"
          class="form-control almnabrformcontrol"
          value="09:00:00 am"
          disabled
        />
      </div>
      <div class="col-md-3 mb-10">
        <label class="mb-10">{{ "lang_works_quantaty" | language }} : </label>
        <input
          type="text"
          class="form-control almnabrformcontrol"
          placeholder="lang_works_quantaty"
        />
      </div>
      <div class="col-md-3 mb-10">
        <label class="mb-10">{{ "lang_discipline" | language }} : </label>
        <ng-select
          [items]="discipline"
          [multiple]="true"
          bindLabel="label"
          bindValue="value"
          clearAllText="Clear"
          appendTo="body"
          placeholder="{{ 'lang_discipline' | language }}"
          [notFoundText]="'lang_no_data' | language"
        >
        </ng-select>
      </div>
      <div
        class="col-sm-6 mb-10 mt-2"
        *ngIf="work_sites_form.get('work_site').value == 'ALL'"
      >
        <label class="mb-10"
          >{{ "lang_fill_worklevels" | language }} :
          <span class="red">*</span></label
        >

        <ng-select
          [items]="work_levels_for_all"
          [multiple]="true"
          bindLabel="label"
          bindValue="value"
          clearAllText="Clear"
          [searchFn]="ds.customSearchFn"
          appendTo="body"
          [formControl]="work_levels_for_all_control"
          (change)="getUnitsForAll()"
          placeholder="{{ 'lang_fill_worklevels' | language }}"
          [notFoundText]="'lang_no_data' | language"
        >
        </ng-select>
        <mat-error *ngIf="work_levels_for_all_control.errors?.required">{{
          "lang_field_required" | language
        }}</mat-error>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <app-alert id="default-alert"></app-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-9 mb-10"></div>
      <div class="col-sm-3 mb-10">
        <button
          class="albutton mnabrbutton mat-accent"
          mat-button
          (click)="send_files_to_step2()"
        >
          {{ "lang_submit" | language }}
        </button>
      </div>
    </div>
  </form>
</fieldset>
<fieldset class="mt-5">
  <legend>Comments & Sign-off</legend>
  <form>
    <div class="row mb-10 p-1">
      <div class="col-md-3 mb-10 mb-10">
        <label class="mb-10"
          >{{ "lang_signature" | language }} : <span class="red">*</span></label
        >
        <i
          style="padding: 0 10px"
          class="fa fa-info-circle orangestatus-color"
          matTooltip="{{ 'lang_signature_tooltip' | language }}"
        ></i>
        <div>
          <mat-radio-group>
            <mat-radio-button value="1">{{
              "lang_yes" | language
            }}</mat-radio-button>
            <mat-radio-button value="0">{{
              "lang_no" | language
            }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div class="row p-1">
      <div class="col-md-3 mb-10">
        <label class="mb-10">{{ "lang_name" | language }} : </label>
        <input
          type="text"
          class="form-control almnabrformcontrol"
          placeholder="lang_name"
        />
      </div>
      <div class="col-md-3 mb-10">
        `
        <label class="mb-10">{{ "lang_designation" | language }} : </label>
        <input
          type="text"
          class="form-control almnabrformcontrol"
          value="SURVEYOR"
          placeholder="lang_designation"
        />
      </div>
      <div class="col-md-3 mb-10">
        <label class="mb-10">{{ "lang_date" | language }} : </label>
        <input
          type="text"
          class="form-control almnabrformcontrol"
          placeholder="lang_date"
        />
      </div>
      <div class="col-md-3 mb-10">
        <label class="mb-10">{{ "lang_section" | language }} : </label>
        <ng-select
          [items]="sections"
          [multiple]="true"
          bindLabel="label"
          bindValue="value"
          clearAllText="Clear"
          appendTo="body"
          placeholder="{{ 'lang_section' | language }}"
          [notFoundText]="'lang_no_data' | language"
        >
        </ng-select>
      </div>
    </div>
    <div class="row mb-10 p-1">
      <div class="col-md-6 mb-10 mt-xs-3 mt-0">
        <label class="mb-10">{{ "lang_comment" | language }} : </label>
        <textarea
          class="form-control almnabrformcontrol"
          placeholder="{{ 'lang_comment' | language }}"
        ></textarea>
      </div>
      <div class="col-md-6 mb-10 mt-xs-3 mt-0">
        <label class="mb-10"
          >{{ "lang_Engineer_Comments" | language }} :
        </label>
        <textarea
          class="form-control almnabrformcontrol"
          placeholder="{{ 'lang_Engineer_Comments' | language }}"
        ></textarea>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-sm-12">
        <app-alert id="default-alert"></app-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-9 mb-10"></div>
      <div class="col-sm-3 mb-10">
        <button
          class="albutton mnabrbutton mat-accent"
          mat-button
          (click)="send_files_to_step2()"
        >
          {{ "lang_submit" | language }}
        </button>
      </div>
    </div>
  </form>
</fieldset>
