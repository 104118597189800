import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-compeletion-rate-filters',
  templateUrl: './compeletion-rate-filters.component.html',
  styleUrls: ['./compeletion-rate-filters.component.scss']
})
export class CompeletionRateFiltersComponent implements OnInit {
  from_date                   = new FormControl(null);
  to_date                     = new FormControl(null);
  with_balance                = new FormControl(false);
  phase_zone_no               = null;
  platform_group1_code_system = null;
  projects_profile_id;
  projects_supervision_id;
  projects_work_area_id;
  zones                       = [];
  divisions                   = [];
  constructor(public dialogRef: MatDialogRef<CompeletionRateFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private spinner: PreloaderService, private ds: DataService, private sweetAlert: SweetAlertService) { 
      this.projects_profile_id     = data?.projects_profile_id;
      this.projects_supervision_id = data?.projects_supervision_id;
      this.projects_work_area_id   = data?.projects_work_area_id;
      
      if(data.completion_filters.from_date) {
        this.from_date.setValue(new Date(data.completion_filters.from_date));
      }
      if(data.completion_filters.to_date) {
        this.to_date.setValue(new Date(data.completion_filters.to_date));
      }
      if(data.completion_filters.with_balance) {
        if(data.completion_filters.with_balance == '1') {
          this.with_balance.setValue(true);
        } else {
          this.with_balance.setValue(false);
        }
      }
      if(data.completion_filters.phase_zone_no) {
        this.phase_zone_no = data.completion_filters.phase_zone_no?.split(',');
      }
      if(data.completion_filters.platform_group1_code_system) {
        this.platform_group1_code_system = data.completion_filters.platform_group1_code_system?.split(',');
      }
    }

  ngOnInit(): void {
    this.get_zones();
    this.get_divisions();
  }


  get_zones() {
    this.spinner.show();
    let phaseId = '0',
    param = new FormData();
    param.append('phase_parent_id', phaseId);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'joYF29rbEi/'+this.projects_profile_id+'/'+this.projects_supervision_id).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.zones = res?.records;
        }
    });
  }

  get_divisions() {
    this.spinner.show();
    this.ds.get('pr/get_system_division').subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.divisions = res?.result;
        }
    });
  }

  onSubmit() {
    let withBalanceValue = this.with_balance.value ? '1' : '0';
    this.dialogRef.close({
      from_date    : this.convertDateToString(this.from_date.value), 
      to_date      : this.convertDateToString(this.to_date.value), 
      phase_zone_no: this.phase_zone_no ? this.phase_zone_no?.toString() : '', 
      platform_group1_code_system: this.platform_group1_code_system ? this.platform_group1_code_system?.toString() : '',
      with_balance: withBalanceValue
    });
  }

  downloadExcel() {
    let withBalanceValue = this.with_balance.value ? '1' : '0';
    let formData = new FormData();
    formData.append('projects_work_area_id', this.projects_work_area_id ||'');
    formData.append('from_date', this.convertDateToString(this.from_date.value) ||'');
    formData.append('to_date', this.convertDateToString(this.to_date.value) ||'');
    formData.append('phase_zone_no', this.phase_zone_no?.toString() || '');
    formData.append('with_balance', withBalanceValue || '');
    this.spinner.show();
    this.ds.post(`pr/dashboard_units_result/${this.projects_profile_id}/${this.projects_supervision_id}`, formData).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.ds.downloadFile(res, 'dashboard_export_file');
      } else {
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.sweetAlert.errorMessage(err?.error?.error);
      this.spinner.hide();
    })
  }

  convertDateToString(date) {
    if(date) {
      var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("/");
    } else {
      return null;
    }
  }
}
