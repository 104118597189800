import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { PreloaderService } from "@core/bootstrap/preloader.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
	selector: "app-mobilization-contract",
	templateUrl: "./mobilization-contract.component.html",
	styleUrls: ["./mobilization-contract.component.scss"],
})
export class MobilizationContractComponent implements OnInit {
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, this.config);
	}
	modalRef: BsModalRef;
	@Input() supervision_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() project_data;
	@Input() filelevels;
	@Input() makeAction;
	mobcontractearchKey = "";
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	mobpageno = 1;
	mobpagesize = "10";
	mobListData: any = [];
	mobnodata: string = this.lang.transform("lang_loading");
	form: FormGroup;
	updateform: FormGroup;
	submitted = false;
	viewdata: any = [];
	directors: any = [];
	managers: any = [];
	public config = environment.modelconfig;
	isFile: boolean = false;
	mobilization_type = "";
	filename = this.lang.transform("lang_select_files");
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.load_supervision_mobcontract();
		this.buildform();
	}
	emptyform() {
		this.isFile = false;
		this.filename = this.lang.transform("lang_select_files");
		this.alert.clear();
		this.form.reset();
	}
	buildform() {
		this.form = new FormGroup({
			projects_profile_id: new FormControl(
				this.supervision_data.projects_profile_id
			),
			projects_supervision_id: new FormControl(
				this.supervision_data.projects_supervision_id
			),
			mobilization_type: new FormControl("", [Validators.required]),
			mobilization_description: new FormControl("", [Validators.required]),
			mobilization_attachment: new FormControl("", [Validators.required]),
			level_keys: new FormControl("", [Validators.required]),
		});
		this.updateform = new FormGroup({
			projects_profile_id: new FormControl("", [Validators.required]),
			projects_supervision_id: new FormControl("", [Validators.required]),
			mobilization_id: new FormControl("", [Validators.required]),
			sitehandle_description: new FormControl("", [Validators.required]),
			sitehandle_attachments: new FormControl(""),
			level_keys: new FormControl("", [Validators.required]),
		});
	}
	public submit() {
		this.spinner.show();
		this.form
			.get("projects_profile_id")
			.setValue(this.supervision_data.projects_profile_id);
		this.form
			.get("projects_supervision_id")
			.setValue(this.supervision_data.projects_supervision_id);
		this.ds
			.postActionByUrl(this.ds.json2formdata(this.form.value), "MOtcMnMzsY")
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.alert.success(res.msg);
						this.form.reset();
						this.load_supervision_mobcontract();
						setTimeout(() => {
							this.modalRef.hide();
						}, 1000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	emptyEditForm(list) {
		this.isFile = false;
		this.filename = this.lang.transform("lang_select_files");
		this.alert.clear();
		this.updateform.reset();
		this.updateform.patchValue({
			projects_profile_id: list.projects_profile_id,
			projects_supervision_id: list.projects_supervision_id,
			mobilization_id: list.mobilization_id,
			sitehandle_description: list.mobilization_description,
			level_keys: list.level_keys
				? list.level_keys.split(",")
				: list.level_keys,
		});
	}
	public UploadFile(event, form) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			this.isFile = true;
			this.filename = event.target.files[0].name;
			form.setValue(event.target.files[0], { emitEvent: true });
		} else {
			this.isFile = false;
			form.setValue(null);
			this.filename = this.lang.transform("lang_select_files");
		}
	}
	public removeFile() {
		this.isFile = false;
		this.updateform.get("sitehandle_attachments").setValue(null);
		this.filename = this.lang.transform("lang_select_files");
	}
	public update() {
		this.spinner.show();
		this.form
			.get("projects_profile_id")
			.setValue(this.supervision_data.projects_profile_id);
		this.form
			.get("projects_supervision_id")
			.setValue(this.supervision_data.projects_supervision_id);
		this.ds
			.postActionByUrl(
				this.ds.json2formdata(this.updateform.value),
				"tx4tLWOwJM/" + this.updateform.get("mobilization_id").value
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.alert.success(res.msg);
						this.updateform.reset();
						this.load_supervision_mobcontract();
						setTimeout(() => {
							this.modalRef.hide();
						}, 1000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	public search_supervision_mobcontract() {
		this.mobpageno = 1;
		this.mobpagesize = "10";
		this.load_supervision_mobcontract();
	}
	public load_supervision_mobcontract_page(page) {
		this.spinner.show();
		this.mobpageno = page;
		this.mobpagesize = this.mobpagesize;
		this.load_supervision_mobcontract();
	}
	public load_supervision_mobcontract_pagesize(size) {
		this.spinner.show();
		this.mobpageno = 1;
		this.mobpagesize = size;
		this.load_supervision_mobcontract();
	}
	load_supervision_mobcontract() {
		let formdata = new FormData();
		formdata.append("search_key", this.mobcontractearchKey);
		formdata.append("mobilization_type", this.mobilization_type);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formdata),
				"c90BbFMl20/" +
					this.project_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id +
					"/" +
					this.mobpageno +
					"/" +
					this.mobpagesize
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.mobListData = res;
					} else {
						this.mobListData = [];
						this.mobnodata = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.mobListData = [];
					this.mobnodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}
	checkAllItemsMob(event) {
		if (event.target.checked) {
			$(".mobilization_in_contract .eachItemMob").prop("checked", true);
			$(".mobilization_in_contract .makeback").addClass("bground");
			$(".mobilization_in_contract .deleteIconDiv").show();
		} else {
			$(".mobilization_in_contract .eachItemMob").prop("checked", false);
			$(".mobilization_in_contract .makeback").removeClass("bground");
			$(".mobilization_in_contract .deleteIconDiv").hide();
		}
	}
	checkEachItemMob(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".mobilization_in_contract .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".mobilization_in_contract .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".mobilization_in_contract .eachItemMob:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".mobilization_in_contract .eachItemMob").each(function () {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".mobilization_in_contract .deleteIconDiv").show();
		} else {
			$(".mobilization_in_contract .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".mobilization_in_contract #allItemsMob").prop("checked", true);
		} else {
			$(".mobilization_in_contract #allItemsMob").prop("checked", false);
		}
	}
	deleteBulkDataMob() {
		const checkedtotal = [];
		$(".mobilization_in_contract .eachItemMob:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: "400px",
				data: {
					title: this.lang.transform("lang_confirmation"),
					message: this.lang.transform("lang_are_you_sure"),
				},
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show();
					this.ds
						.deleteActionByUrl(
							checkedtotal,
							"DSROIJCjhC/" +
								this.supervision_data.projects_profile_id +
								"/" +
								this.supervision_data.projects_supervision_id
						)
						.subscribe(
							(data) => {
								this.spinner.hide();
								if (data.status) {
									this.load_supervision_mobcontract();
									$(".mobilization_in_contract #allItemsMob").prop(
										"checked",
										false
									);
									$(".mobilization_in_contract .deleteIconDiv").hide();
									this.ds.dialogf("", data.msg);
								} else {
									this.ds.dialogf("", data.error);
								}
							},
							(error) => {
								this.ds.dialogf(
									"",
									error && error.error && error.error.error
										? error.error.error
										: this.lang.transform("lang_internal_server_error")
								);
								this.spinner.hide();
							}
						);
				}
			});
		} else {
			this.ds.dialogf();
		}
	}
}
