<form [formGroup]="form" class="form w-100" (ngSubmit)="submit();">
    <div class="mbrl15">
		<div class="row">
			<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
				<div class="form-group">
					<label> {{'lang_subject' | language}} <span class="red">*</span></label>
					<input type="text" class="form-control almnabrformcontrol" readonly placeholder="{{'lang_subject' | language}}" formControlName="subject">
				</div>
			</div>
			<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
				<div class="form-group">
					<label> {{'lang_from_org' | language}} </label>
					<input type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_from_org' | language}}" formControlName="transaction_from">
					<mat-error *ngIf="submitted && form.controls['transaction_from'].errors?.required">{{'lang_field_required' | language}}</mat-error>
				</div>
			</div>
			<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
				<div class="form-group">
					<label> {{'lang_type' | language}} <span class="red">*</span></label>
					<select class="form-control almnabrformcontrol" formControlName="joining_type">
						<option value="">{{'lang_type' | language}}</option>
						<option *ngFor="let type of joining_type" value="{{type.value}}">{{type.label}}</option>
					</select>
					<mat-error *ngIf="submitted && form.controls['joining_type'].errors?.required">{{'lang_field_required' | language}}</mat-error>
				</div>
			</div>                        
		</div>
		<div class="row">
			<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
				<div class="form-group">
					<label for="joining_start_date_english">{{'lang_date' | language }} : <span class="red">*</span></label>
					<div class="positionrelatiove">
						<input formControlName="joining_start_date_english" type="text" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event, form.get('joining_start_date_arabic'));" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
						<mat-datepicker-toggle matSuffix [for]="BDeng" class="posiab"></mat-datepicker-toggle>
						<mat-datepicker #BDeng disabled="false"></mat-datepicker>
						<mat-error *ngIf="submitted && form.controls['joining_start_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
					</div>
				</div>
			</div>
			<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
				<div class="form-group">
					<label for="joining_start_date_arabic">{{'lang_human_resources_date_arabic' | language }} : <span class="red">*</span></label>
					<input formControlName="joining_start_date_arabic" (ngModelChange)="ds.setARTOENDate($event, form.get('joining_start_date_english'));" ngbDatepicker [readonly]="true" (click)="bdarab.toggle()" #bdarab="ngbDatepicker" type="text" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" />
					<mat-error *ngIf="submitted && form.controls['joining_start_date_arabic'].errors?.required">{{'lang_field_required' | language}}</mat-error>
				</div>
			</div>
			<div class="col-md-4 col-xs-12 col-sm-4 mb-10">
				<label class="mb-10">
					{{ "lang_direct_manager" | language }}
					<span class="red"> * </span> </label>

					<ng-select
					[items]="users"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					clearAllText="Clear"
					 
					appendTo="body"
					formControlName="direct_manager"
					placeholder="{{ 'lang_direct_manager' | language }}"
					(search)="search_users($event)"
					
					[searchFn]="ds.customSearchFn"
					 
				>
				</ng-select>
				<mat-error
					*ngIf="
					submitted &&
					form.controls['direct_manager'].errors?.required
					"
				>
					{{ "lang_field_required" | language }}
				</mat-error>
			 
			</div>
		</div>
		<div class="row mb-10">
			<div class="col-12 mb-10">
				<label> {{'lang_description' | language}} <span class="red">*</span></label>
				<textarea type="text" formControlName="joining_description" class="form-control almnabrformcontrol" rows="10"></textarea>
				<mat-error *ngIf="submitted && form.controls['joining_description'].errors?.required">{{'lang_field_required' | language}}</mat-error>
			</div>
		</div>
  
	</div>
	<div class="card-footer">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button type="button" routerLink="/human_resources/employees" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_human_resources_all_emp' | language}}</button>
			</div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button type="button" (click)="joiningEvent.emit()" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_human_resources_emp_details' | language}}</button>
			</div>
			<div class="col-md-6 col-sm-2 col-xs-12 mb-10">
				<app-alert id="default-alert"></app-alert>
			</div>
			<div class="col-md-1 col-sm-2 col-xs-12 mb-10">
				
			</div>
			<div class="col-md-1 col-sm-2 col-xs-12 mb-10">
				<input type="submit" value="{{'lang_save' | language}}" class="albutton mnabrbutton mat-accent" mat-button>
			</div>
		</div>
	</div>
</form>