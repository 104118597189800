<div class="mbrl15 martb0">
  <div class="p-1">
    <form class="form" [formGroup]="form">
      <div class="row">
        <div class="col mb-10">
          <div class="input-group input-group-sm mb-10">
            <input
              name="account_search"
              id="filter"
              #filter
              formControlName="search_value"
              (keyup)="search_employee_modules()"
              class="enablecode h34 form-control almnabrformcontrol"
              type="text"
              autocomplete="off"
              placeholder="{{ 'lang_search' | language }}"
            />
            <span class="input-group-btn">
              <button
                id="btnSearch"
                type="button"
                matTooltip="{{ 'lang_add' | language }}"
                class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-plus blue-tooltip mnabricon"
                (click)="onCreateLoan()"
              ></button>
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="row p-1">
    <div class="col-md-12">
      <div class="table-responsive dropdownyes customResponsive">
        <table border="1" class="table CustomTable">
          <thead>
            <tr>
              <td>#</td>
              <td>{{ "lang_employee_number" | language }}</td>
              <td>{{ "lang_project_name_arabic" | language }}</td>

              <td>{{ "lang_project_name_english" | language }}</td>
              <td>{{ "lang_project_start_date" | language }}</td>
              <td>{{ "lang_project_end_date" | language }}</td>
            </tr>
          </thead>
          <tbody class="AppendList" *ngIf="projects?.records?.length > '0'">
            <tr
              *ngFor="
                let item of projects?.records;
                let i = index;
                let odd = odd;
                let even = even
              "
              [ngClass]="{ odd: odd, even: even }"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ item.employee_number }}</td>
              <td>{{ item.project_name_arabic }}</td>
              <td>{{ item.project_name_english }}</td>
              <td>{{ item.project_start_date }}</td>
              <td>{{ item.project_end_date }}</td>
            </tr>
          </tbody>
          <tbody *ngIf="projects?.records?.length == '0'" class="AppendList">
            <tr class="odd">
              <td colspan="6" align="center">{{ errormessagef }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
    *ngIf="projects?.records?.length != 0 || projects?.page?.total_records > 0"
    class="card-footer padb0 customborder"
  >
    <div class="row">
      <!--  <div *ngIf="able2delete && requestfor !== 'profile' " class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                        <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataContr()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                    </div> -->
      <div class="col-lg-3 col-sm-6 col-6 mb-10">
        {{ "lang_total" | language }} {{ projects?.page?.total_records }}
      </div>
      <div class="col-lg-5 col-sm-6 col-12 mb-10">
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button
            value="10"
            checked
            (click)="spinner.show(); load_page_size(10)"
          >
            10
          </mat-radio-button>
          <mat-radio-button
            value="20"
            (click)="spinner.show(); load_page_size(20)"
          >
            20
          </mat-radio-button>
          <mat-radio-button
            value="50"
            (click)="spinner.show(); load_page_size(50)"
          >
            50
          </mat-radio-button>
          <mat-radio-button
            value="100"
            (click)="spinner.show(); load_page_size(100)"
          >
            100
          </mat-radio-button>
          <mat-radio-button
            value="500"
            (click)="spinner.show(); load_page_size(500)"
          >
            500
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-lg-4 col-sm-6 col-12 mb-10">
        {{ "lang_goto" | language }}
        <select
          class="customselect"
          (change)="spinner.show(); page_length($event.target.value)"
        >
          <option
            *ngFor="let k of ds.getrange(projects?.page?.total_pages)"
            value="{{ k }}"
          >
            {{ k }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
