<breadcrumb></breadcrumb>
<br>
<br>
<br>
<section class="panel-expansion">
    <div class="container mb-10">
        <div class="row">
            <div class="col-md-5 col-sm-6 col-xs-12 mar0auto">
                <mat-accordion  class="stylepanels">
                    <mat-expansion-panel class="mpanel change_password" [expanded]="true">
                      <mat-expansion-panel-header class="mpanel-header">{{'lang_change_password' | language}}</mat-expansion-panel-header>
                        <form [formGroup]="form" class="form" (ngSubmit)="submit();">
                            <div class="material-panel-body p-1">
                                <div class="mbrl15 martb0">
                                    <div class="row mb-10">
                                        <label for="password">{{'lang_old_password' | language }} : <span class="red">*</span></label>
                                        <input formControlName="opassword" autocomplete="old-password" type="password" placeholder="{{'lang_old_password' | language }}" class="form-control almnabrformcontrol password"/>
                                        <mat-error *ngIf="submitted && form.controls['password'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                      </div>
                                    <div class="row mb-10">
                                      <label for="password">{{'lang_password' | language }} : <span class="red">*</span></label>
                                      <input formControlName="password" minlength="6" autocomplete="new-password" type="password" placeholder="{{'lang_password' | language }}" class="form-control almnabrformcontrol password"/>
                                      <mat-error *ngIf="submitted && form.controls['password'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                      <mat-error *ngIf="submitted && form.controls['password'].errors?.minlength"> {{'lang_minimum' | language }} 6</mat-error>
                                    </div>
                                    <div class="row mb-10">
                                        <label for="cpassword">{{'lang_confirm_password' | language }} : <span class="red">*</span></label>
                                        <input formControlName="cpassword" minlength="6" autocomplete="new-cpassword" type="password" placeholder="{{'lang_confirm_password' | language }}" class="form-control almnabrformcontrol cpassword"/>
                                        <mat-error *ngIf="submitted && form.controls['cpassword'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                        <mat-error *ngIf="form.controls['cpassword'].errors?.minlength"> {{'lang_minimum' | language }} 6</mat-error>
                                        <mat-error *ngIf="form.get('password').value !== form.get('cpassword').value"> {{'lang_password_notmatch' | language }} </mat-error>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 mb-10">
                                        <app-alert id="default-alert"></app-alert>
                                    </div>
                                </div>                                
                            </div>
                            <div class="card-footer card-footer pb-0">
                                <div class="row">
                                    <div class="col-md-8 col-xs-12 mb-10"></div>
                                    <div class="col-md-4 col-xs-12 mb-10">
                                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>    
</section>