<div class="matero-sidebar-header" *ngIf="showHeader">
  <app-branding></app-branding>
  <span fxFlex></span>
  <!--
  <mat-slide-toggle (change)="toggleCollapsed.emit()" [checked]="toggleChecked" *ngIf="showToggle"></mat-slide-toggle>

  -->
</div>
<div class="matero-sidebar-main scrollbar-none">
  <app-user-panel></app-user-panel>
  <app-sidemenu ></app-sidemenu>
</div>