import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
  selector: "app-contracts",
  templateUrl: "./contracts.component.html",
  styleUrls: ["./contracts.component.scss"],
})
export class ContractsComponent implements OnInit {
  @Output() showContract = new EventEmitter();
  @Input() able2edit;
  @Input() able2delete;
  @Input() able2add;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  @Input() types;
  @Input() requestfor;
  @Input() user_data;
  @Input() attachments;
  pagenum = 1;
  pageSize = "10";
  prod = environment.production;
  csearchKey = "";
  wholedata: any = [];
  viewhistory = [];
  history;
  history_type;
  lodingdatas = this.lang.transform("lang_loading");
  modalRefV: BsModalRef;
  modalRefA: BsModalRef;
  modalRefC: BsModalRef;
  modalRefB: BsModalRef;
  expandstatus = false;
  contract_type;
  page = 1;
  size = 10;
  errormessagef: any = this.lang.transform("lang_loading");
  viewdata: any = [];
  employee_status = "0";
  employee_auth = true;
  btn_auth = true;
  employee_has_contract = true;
  manager_approved: any;
  old_new_history;
  main_history: any;
  employee_status_msg:string;
  constructor(
    public router: Router,
    public ds: DataService,
    public route: ActivatedRoute,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  ngOnInit(): void {
    this.expandstatus = this.requestfor == "profile" ? true : false;
    this.search_employee_contracts();
    this.checkEmployeeStatus();
    this.get_employee_auth();
    this.get_employee_ect();
    this.check_button_new_contract();
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public openModalB(template: TemplateRef<any>) {
    this.modalRefB = this.modalService.show(template, environment.modelconfig);
  }
  public openModalC(template: TemplateRef<any>) {
    this.modalRefC = this.modalService.show(template, environment.modelconfig);
  }
  public load_page_size(pageno) {
    this.size = pageno;
    this.get_employee_contracts();
  }
  public page_length(page) {
    this.page = page;
    this.get_employee_contracts();
  }
  public search_employee_contracts() {
    this.size = 10;
    this.page = 1;
    this.get_employee_contracts();
  }
  public get_employee_contracts() {
    const param = new FormData();
    param.append("search_key", this.csearchKey || "");
    param.append("id", this.id);
    let url =
      this.requestfor === "profile"
        ? "get_profile_contract_lists"
        : "hrcontracts";
    this.ds
      .getActionByUrl(
        this.ds.formData2string(param),
        url + "/" + this.page + "/" + this.size
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.wholedata = data;
            this.manager_approved =
              this.wholedata?.records[0]?.manager_approved;
            this.contract_type = data.records[0].contract_type;
          } else {
            this.wholedata = [];
            this.errormessagef = data.error;
          }
        },
        (error) => {
          this.spinner.hide();
          this.wholedata = [];
          this.errormessagef =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
  }
  public get_employee_auth() {
    let formData = new FormData();
    formData.append("employee_number", this.user_data.employee_number || "");
    this.ds
      .post("form/FORM_EPP/get_start_epp_date", formData)
      .subscribe((res) => {
        if (!res?.status) {
          this.employee_auth = false;
        }
      });
  }
  public check_button_new_contract() {
    let formData = new FormData();
    formData.append("employee_number", this.user_data.employee_number || "");
    this.ds
      .post("form/FORM_CT1/check_button_new_contract", formData)
      .subscribe((res) => {
        if (!res?.status) {
          this.btn_auth = false;
        }
      });
  }
  public get_employee_ect() {
    let formData = new FormData();
    let date = new Date();
    formData.append("employee_number", this.user_data.employee_number || "");
    formData.append("last_working_date_en", moment(date).format("YYYY/MM/DD"));
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "form/FORM_ECT1/get_contract_termination_cases"
      )
      .subscribe((res) => {
        if (!res?.status) {
          this.employee_has_contract = false;
        }
      });
  }
  public showError() {
    this.ds.dialogf("", this.lang.transform("lang_authentication_error"));
  }
  public loadContract(data, template) {
    this.viewdata = [];
    const param = new FormData();
    param.append("id", this.id);
    param.append("contract_id", data.contract_id);
    let url =
      this.requestfor === "profile"
        ? "get_profile_contract_lists"
        : "hrcontracts";
    this.ds
      .getActionByUrl(this.ds.formData2string(param), url + "/1/1")
      .subscribe(
        (data) => {
          console.log(data);

          this.spinner.hide();
          if (data.status) {
            this.openModalV(template);
            this.viewdata = data;
            this.contract_type = data.records[0].contract_type;
          } else {
            this.ds.dialogf("", data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  public checkAllContr(event) {
    if (event.target.checked) {
      $(".appcontracts .eachItemContr").prop("checked", true);
      $(".appcontracts .makeback").addClass("bground");
      $(".appcontracts .deleteIconDiv").show();
    } else {
      $(".appcontracts .eachItemContr").prop("checked", false);
      $(".appcontracts .makeback").removeClass("bground");
      $(".appcontracts .deleteIconDiv").hide();
    }
  }
  public checkEachItemContr(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appcontracts .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appcontracts .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appcontracts .eachItemContr:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appcontracts .eachItemContr").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appcontracts .deleteIconDiv").show();
    } else {
      $(".appcontracts .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appcontracts #allItemsContr").prop("checked", true);
    } else {
      $(".appcontracts #allItemsContr").prop("checked", false);
    }
  }
  public deleteBulkDataContr() {
    const checkedtotal = [];
    $(".appcontracts .eachItemContr:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true,
        width: "400px",
        data: {
          title: this.lang.transform("lang_confirmation"),
          message: this.lang.transform("lang_are_you_sure"),
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          this.ds
            .deleteActionByUrl(
              checkedtotal,
              "01f5086b879a62a05da4094dac203558/CONTRACT/" +
                this.id +
                "/" +
                this.branch_id
            )
            .subscribe(
              (data) => {
                this.spinner.hide();
                if (data.status) {
                  this.search_employee_contracts();
                  $(".appcontracts #allItemsContr").prop("checked", false);
                  $(".appcontracts .deleteIconDiv").hide();
                  this.ds.dialogf("", data.msg);
                } else {
                  this.ds.dialogf("", data.error);
                }
              },
              (error) => {
                this.ds.dialogf(
                  "",
                  error && error.error && error.error.error
                    ? error.error.error
                    : this.lang.transform("lang_internal_server_error")
                );
                this.spinner.hide();
              }
            );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }

  isCanRenew(con, type) {
    if (this.employee_status == "1") {
      this.showContract.emit({
        event: "CTRCR2",
        contract: con,
        status: type,
        contract_data: con,
      });
    } else {
      this.sweetAlert.errorMessage(
        this.lang.transform(this.employee_status_msg)
      );
    }
  }

  checkEmployeeStatus() {
    let formData = new FormData();
    formData.append("user_id", this.user_data?.user_id || "");
    this.ds
      .post("form/FORM_CT1/check_employee_vocation_status", formData)
      .subscribe(
        (res) => {
          if (res?.status) {
            this.employee_status = "1";
          } else {
            this.employee_status = "0";
            this.employee_status_msg = res?.msg || res?.records;
          }
        },
        (err) => {
          this.employee_status = "0";
          this.employee_status_msg = err?.error?.msg || err?.error?.records || err?.error?.error;
        }
      );
  }
  public view_history(data, template?, pagination?: boolean) {
    this.viewhistory = [];
    this.history = data;
    const param = new FormData();
    param.append("employee_number", data.employee_number);
    param.append("contract_id", data.contract_id);
    param.append("page_size", `${this.pageSize}`);
    param.append("page_no", `${this.pagenum}`);
    this.ds
      .getActionByUrl(
        this.ds.formData2string(param),
        "form/FORM_CT1/get_contract_edited_history"
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.viewhistory = data;
            if (!pagination) {
              this.openModalA(template);
            }
          } else {
            if (!pagination) {
              this.openModalA(template);
            }
            this.viewhistory = [];
            this.lodingdatas = this.lang.transform("lang_no_data");
          }
        },
        (error) => {
          this.spinner.hide();
          this.viewhistory = [];
          this.lodingdatas =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
  }
  public loadPageHis(page) {
    this.spinner.show();
    this.pagenum = page;
    this.pageSize = this.pageSize;
    this.view_history(this.history, true);
  }
  public loadHisPagesize(size) {
    this.spinner.show();
    this.pagenum = 1;
    this.pageSize = size;
    this.view_history(this.history, true);
  }
  choose_history(history, main_history, type, template) {
    this.main_history = main_history;
    if (type == "new") {
      this.history_type = "new";
    } else {
      this.history_type = "old";
    }
    this.old_new_history = JSON.parse(history);
    this.modalRefA.hide();
    this.spinner.show();
    setTimeout(() => {
      this.openModalB(template);
      this.spinner.hide();
    }, 500);
  }
}
