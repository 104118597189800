<div mat-dialog-title class="d-flex justify-content-between">
	<h4 *ngIf="!edit_mode">{{ "lang_add" | language }}</h4>
	<h4 *ngIf="edit_mode">{{ "lang_edit" | language }}</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="stage_form">
		<div class="p-2">
			<div class="row mb-3">
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{
							"lang_human_resources_nameen" | language
						}}</mat-label>
						<input
							matInput
							placeholder="{{ 'lang_human_resources_nameen' | language }}"
							formControlName="contract_stage_name_english"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{
							"lang_human_resources_namear" | language
						}}</mat-label>
						<input
							matInput
							placeholder="{{ 'lang_human_resources_namear' | language }}"
							formControlName="contract_stage_name_arabic"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_payment_stage" | language }}</mat-label>
						<input
							matInput
							placeholder="{{ 'lang_payment_stage' | language }}"
							autocomplete="off"
							type="number"
							formControlName="contract_stage_payment"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_stage" | language }}</mat-label>
						<mat-select formControlName="stage_id_constant">
							<mat-option
								*ngFor="let item of data_for_add?.stage_id"
								[value]="item.id"
							>
								{{ item?.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_status" | language }}</mat-label>
						<mat-select formControlName="contract_stage_payment_status">
							<mat-option
								*ngFor="let item of data_for_add?.payment_status"
								[value]="item.id"
							>
								{{ item.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-0 col-sm-9"></div>
	<div class="col-12 col-sm-3">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onSubmit()"
			*ngIf="!edit_mode"
		>
			{{ "lang_submit" | language }}
		</button>
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onUpdateProject()"
			*ngIf="edit_mode"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
