<fieldset
  class="cardback viewquotation fieldset mb-10 mt-3"
  [ngClass]="{ ' contentrtl': lang_key == 'ar' }"
>
  <legend>{{ "lang_end_contract" | language }}</legend>
  <div class="row mb-10">
    <div class="contentbodypanel width100 mt-0">
      <div class="col-12 contentpanel">
        <div class="itemsdata">
          <label> {{ "lang_info" | language }} : </label>
          <div class="TableParts">
            <div
              class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
            >
              <table class="table CustomTables text-center">
                <tbody class="AppendList quotationtable">
                  <tr>
                    <td class="bold">
                      {{ "lang_termination_case" | language }}
                    </td>
                    <td *ngIf="lang_key == 'ar'">
                      {{ formCt1Data?.main_data?.contract_termination_case_ar }}
                    </td>
                    <td *ngIf="lang_key == 'en'">
                      {{ formCt1Data?.main_data?.contract_termination_case_en }}
                    </td>
                    <td class="bold">
                      {{ "lang_rating_degree" | language }}
                    </td>
                    <td>
                      {{ formCt1Data?.main_data?.rating_degree }}
                    </td>

                    <td class="bold">
                      {{ "lang_direct_manager_name" | language }}
                    </td>
                    <td>
                      {{ formCt1Data?.main_data?.direct_manager_name }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bold">
                      {{ "lang_employee_name" | language }}
                    </td>
                    <td *ngIf="lang_key == 'ar'">
                      {{ formCt1Data?.main_data?.settings_name_arabic
                      }}{{ " " + formCt1Data?.main_data?.firstname_arabic
                      }}{{ " " + formCt1Data?.main_data?.lastname_arabic
                      }}{{ " " + formCt1Data?.main_data?.thirdname_arabic }}
                    </td>
                    <td *ngIf="lang_key == 'en'">
                      {{ formCt1Data?.main_data?.settings_name_english
                      }}{{ " " + formCt1Data?.main_data?.firstname_english
                      }}{{ " " + formCt1Data?.main_data?.lastname_english
                      }}{{ " " + formCt1Data?.main_data?.thirdname_english }}
                    </td>
                    <td class="bold">
                      {{ "lang_bank_name" | language }}
                    </td>
                    <td *ngIf="lang_key == 'en'">
                      {{ formCt1Data?.main_data?.bankname }}
                    </td>
                    <td *ngIf="lang_key == 'ar'">
                      {{ formCt1Data?.main_data?.banknameA }}
                    </td>

                    <td class="bold">
                      {{ "lang_job_descriptions" | language }}
                    </td>
                    <td *ngIf="lang_key == 'ar'">
                      {{ formCt1Data?.main_data?.job_descriptions }}
                    </td>
                    <td *ngIf="lang_key == 'en'">
                      {{ formCt1Data?.main_data?.job_title }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bold">
                      {{ "lang_type" | language }}
                    </td>
                    <td>
                      {{
                        formCt1Data?.transactions_request
                          ?.transaction_request_status
                      }}
                    </td>
                    <td class="bold">
                      {{ "lang_subject" | language }}
                    </td>

                    <td>
                      {{ formCt1Data?.main_data?.subject }}
                    </td>

                    <td class="bold">
                      {{ "lang_from_org" | language }}
                    </td>
                    <td>
                      {{ formCt1Data?.main_data?.branch }}
                    </td>
                  </tr>
                  <tr [ngSwitch]="formCt1Data?.main_data?.work_type">
                    <td class="bold">
                      {{ "lang_work_domain" | language }}
                    </td>
                    <td>
                      {{ formCt1Data?.main_data?.work_domain }}
                    </td>

                    <td class="bold">
                      {{ "lang_work_location" | language }}
                    </td>
                    <td>
                      {{ formCt1Data?.main_data?.work_location }}
                    </td>

                    <td class="bold">
                      {{ "lang_work_type" | language }}
                    </td>
                    <td *ngSwitchCase="'1'">
                      {{ "lang_fulltime" | language }}
                    </td>
                    <td *ngSwitchCase="'2'">
                      {{ "lang_parttime" | language }}
                    </td>
                    <td *ngSwitchCase="'3'">
                      {{ "lang_contract" | language }}
                    </td>
                    <td *ngSwitchCase="'4'">
                      {{ "lang_others" | language }}
                    </td>
                    <td *ngSwitchDefault>---</td>
                  </tr>
                  <tr>
                    <td class="bold">
                      {{ "lang_joining_date_arabic" | language }}
                    </td>
                    <td>
                      {{ formCt1Data?.data_end_contract?.joining_date_arabic }}
                    </td>
                    <td class="bold">
                      {{ "lang_joining_date_english" | language }}
                    </td>
                    <td>
                      {{ formCt1Data?.data_end_contract?.joining_date_english }}
                    </td>

                    <td class="bold">
                      {{ "lang_contract_end_date" | language }}
                    </td>
                    <td>
                      {{
                        formCt1Data?.data_end_contract
                          ?.contract_end_date_english
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bold">
                      {{ "lang_total_employee_worked_daysc" | language }}
                    </td>
                    <td>
                      {{
                        formCt1Data?.data_end_contract
                          ?.total_employee_worked_days
                      }}
                    </td>
                    <td class="bold">
                      {{ "lang_total_used_voc_paid_days" | language }}
                    </td>
                    <td>
                      {{
                        formCt1Data?.data_end_contract?.total_used_voc_paid_days
                      }}
                    </td>

                    <td class="bold">
                      {{ "lang_total_used_voc_unpaid_days" | language }}
                    </td>
                    <td>
                      {{
                        formCt1Data?.data_end_contract
                          ?.total_used_voc_unpaid_days
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bold">
                      {{ "lang_total_detection_vocation_days" | language }}
                    </td>
                    <td>
                      {{
                        formCt1Data?.data_end_contract
                          ?.total_detection_vocation_days
                      }}
                    </td>
                    <td class="bold">
                      {{ "lang_total_deserved_days" | language }}
                    </td>
                    <td>
                      {{ formCt1Data?.data_end_contract?.total_deserved_days }}
                    </td>

                    <td class="bold">
                      {{ "lang_remaining_working_days" | language }}
                    </td>
                    <td>
                      {{
                        formCt1Data?.data_end_contract
                          ?.remaining_working_days_on_active_contract
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bold">
                      {{ "lang_result_of_the_penalty_clause" | language }}
                    </td>
                    <td>
                      {{
                        formCt1Data?.data_end_contract
                          ?.result_of_the_penalty_clause
                      }}
                    </td>
                    <td class="bold">
                      {{ "lang_total_deserved_days" | language }}
                    </td>
                    <td>
                      {{ formCt1Data?.main_data?.last_working_date }}
                    </td>

                    <td class="bold">
                      {{ "lang_end_of_service_gratuity" | language }}
                    </td>
                    <td>
                      {{ formCt1Data?.main_data?.end_of_service_gratuity }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</fieldset>
