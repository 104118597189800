<div mat-dialog-title>
  <div class="d-flex justify-content-between">
    <h4>{{ "lang_cost_centers" | language }}</h4>
    <span mat-dialog-close><i class="fa fa-times"></i></span>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-12 col-sm-6 mb-3">
      <div
        class="card-item"
        style="background: linear-gradient(60deg, #97a1d7, #583399)"
      >
        <div class="item-text" style="width: 70%">
          <h5>{{ "lang_total_cost" | language }}</h5>
          <h4>{{ total_cost }}</h4>
        </div>
        <div class="item-icon">
          <i class="pi pi-money-bill"></i>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="costCenterForm">
    <div class="table-responsive dropdownyes customResponsive tablefields">
      <table
        border="1"
        class="table CustomTable"
        width="100%"
        formArrayName="cost_centers"
      >
        <thead>
          <tr>
            <td width="2%" style="padding: 5px; word-break: break-all">#</td>
            <td width="2%" style="padding: 5px; word-break: break-all">
              <mat-icon
                class="mnabricon"
                matTooltip="{{ 'lang_add' | language }}"
                (click)="addCostCentersRecord()"
                >add_box</mat-icon
              >
            </td>
            <td width="32%" style="padding: 5px; word-break: break-all">
              {{ "lang_cost" | language }} <span class="red">*</span>
            </td>
            <td width="32%" style="padding: 5px; word-break: break-all">
              {{ "lang_amount_vat" | language }} <span class="red">*</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let file of costCenters.controls;
              let i = index;
              let odd = odd;
              let even = even
            "
            [formGroupName]="i"
          >
            <td
              class="verticalalignmid"
              style="padding: 5px; word-break: break-all"
            >
              {{ i + 1 }}
            </td>
            <td class="verticalalignmid">
              <mat-icon
                (click)="removeCostCentersRecord(i)"
                class="mnabricon red"
                matTooltip="{{ 'lang_delete' | language }}"
                >delete_forever</mat-icon
              >
            </td>
            <td style="padding: 5px; word-break: break-all; position: relative">
              <ng-select
                [items]="
                  costCenterFormArray?.controls[i]?.get('costList')?.value
                "
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                clearAllText="Clear"
                [searchFn]="ds.customSearchFn"
                appendTo="body"
                formControlName="cid"
                placeholder="{{ 'lang_cost' | language }}"
                (search)="searchCosts($event, i)"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-search="searchTerm"
                  let-index="index"
                >
                  <span>{{ item.label }} ({{ item.value }})</span>
                </ng-template>
              </ng-select>
            </td>
            <td style="padding: 5px; word-break: break-all; position: relative">
              <input
                type="text"
                formControlName="amount"
                placeholder="{{ 'lang_amount_vat' | language }}"
                autocomplete="off"
                class="form-control almnabrformcontrol"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-4 col-xs-12">
    <button
      type="button"
      class="albutton mnabrbutton mat-accent"
      mat-button
      mat-dialog-close
    >
      {{ "lang_close" | language }}
    </button>
  </div>
  <div class="col-0 col-sm-4"></div>
  <div class="col-md-4 col-xs-12">
    <button
      type="button"
      class="albutton mnabrbutton mat-accent"
      mat-button
      (click)="onSubmit()"
    >
      {{ "lang_submit" | language }}
    </button>
  </div>
</mat-dialog-actions>
