<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_add" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="form">
		<div class="row">
			<div class="col-12 TableParts">
				<p class="mb-10">
					{{ "lang_snaglists" | language }}
					<span class="red" *ngIf="other_snaglists?.length">*</span>
				</p>
				<div
					class="table-responsive customResponsive tablefields quotationitemtable"
				>
					<table
						border="1"
						class="table CustomTable"
						width="100%"
						formArrayName="snaglists"
					>
						<thead>
							<tr>
								<td width="3%" style="padding: 5px; word-break: break-all">
									#
								</td>
								<td width="3%" style="padding: 5px; word-break: break-all">
									<mat-icon
										class="mnabricon"
										matTooltip="{{ 'lang_add' | language }}"
										(click)="addSnaglist()"
										>add_box</mat-icon
									>
								</td>
								<td width="28%" style="padding: 5px; word-break: break-all">
									{{ "lang_group_one" | language }}
								</td>
								<td width="28%" style="padding: 5px; word-break: break-all">
									{{ "lang_group_two" | language }}
								</td>
								<td width="28%" style="padding: 5px; word-break: break-all">
									{{ "lang_note" | language }}
								</td>
								<td width="10%" style="padding: 5px; word-break: break-all">
									{{ "lang_units" | language }}
								</td>
							</tr>
						</thead>
						<tbody>
							<ng-container
								*ngFor="
									let snaglist of snaglistFormGroup().controls;
									let snaglistIndex = index;
									let odd = odd;
									let even = even
								"
							>
								<ng-container [formGroupName]="snaglistIndex">
									<tr>
										<td
											class="verticalalignmid"
											style="padding: 5px; word-break: break-all"
										>
											{{ snaglistIndex + 1 }}
										</td>
										<td class="verticalalignmid">
											<mat-icon
												(click)="removeSnaglist(snaglistIndex)"
												class="mnabricon red"
												matTooltip="{{ 'lang_delete' | language }}"
												>delete_forever</mat-icon
											>
										</td>
										<td
											style="
												padding: 5px;
												word-break: break-all;
												position: relative;
											"
										>
											<ng-select
												[items]="group1_list"
												[multiple]="false"
												bindLabel="label"
												bindValue="value"
												appendTo="body"
												clearAllText="Clear"
												placeholder="{{ 'lang_group' | language }} 1"
												formControlName="platform_group1_code_system"
												[searchFn]="ds.customSearchFn"
												(change)="getGroup2List(snaglistIndex)"
												(search)="get_group1_list($event)"
											>
											</ng-select>
										</td>
										<td
											style="
												padding: 5px;
												word-break: break-all;
												position: relative;
											"
										>
											<ng-select
												[items]="group2_list"
												[multiple]="false"
												bindLabel="label"
												bindValue="value"
												appendTo="body"
												clearAllText="Clear"
												placeholder="{{ 'lang_group' | language }} 2"
												formControlName="platform_group2_code_system"
												[searchFn]="ds.customSearchFn"
												(change)="getNotesList(snaglistIndex)"
											>
											</ng-select>
										</td>
										<td
											style="
												padding: 5px;
												word-break: break-all;
												position: relative;
											"
										>
											<ng-select
												[items]="
													other_snaglists.controls[snaglistIndex].get(
														'notes_list'
													).value
												"
												[multiple]="false"
												bindLabel="label"
												appendTo="body"
												bindValue="value"
												clearAllText="Clear"
												formControlName="note_id"
												placeholder="{{ 'lang_note' | language }}"
												[searchFn]="ds.customSearchFn"
												id="note_id"
												name="note_id"
											>
											</ng-select>
										</td>
										<td
											class="verticalalignmid"
											style="
												padding: 5px;
												word-break: break-all;
												position: relative;
											"
										>
											<i
												class="pi pi-building"
												(click)="onShowUnits(snaglistIndex)"
												matTooltip="{{ 'lang_view' | language }}"
											></i>
											<i
												class="pi pi-plus ml-1 mr-1"
												(click)="addUnit(snaglistIndex)"
												matTooltip="{{ 'lang_add' | language }}"
											></i>
										</td>
									</tr>
									<tr
										*ngIf="
											other_snaglists.controls[snaglistIndex].get('show_notes')
												.value
										"
									>
										<td colspan="6">
											<div style="padding: 0px 70px">
												<mat-tab-group
													formArrayName="units"
													*ngIf="unitFormGroup(snaglistIndex).controls.length"
												>
													<mat-tab
														*ngFor="
															let unit of unitFormGroup(snaglistIndex).controls;
															let unitIndex = index
														"
													>
														<ng-template mat-tab-label>
															<h4
																class="mt-0 mb-0"
																[ngClass]="{
																	'mr-3': lang_key == 'en',
																	'ml-3': lang_key == 'ar'
																}"
															>
																{{
																	unitFormGroup(snaglistIndex).controls[
																		unitIndex
																	].get("unit_tab_tilte").value ||
																		("lang_units" | language)
																}}
															</h4>
															<i
																class="pi pi-times unit-close-icon"
																(click)="removeUnit(snaglistIndex, unitIndex)"
																matTooltip="{{ 'lang_close' | language }}"
															></i>
														</ng-template>
														<div class="pb-2 pt-2 text-justify">
															<div class="row" [formGroupName]="unitIndex">
																<div class="col-12 col-sm-4">
																	<ng-select
																		[items]="
																			unitFormGroup(snaglistIndex).controls[
																				unitIndex
																			].get('unit_list').value
																		"
																		[multiple]="false"
																		bindLabel="label"
																		appendTo="body"
																		bindValue="value"
																		clearAllText="Clear"
																		formControlName="general_number"
																		placeholder="{{
																			'lang_general_numbers' | language
																		}}"
																		(search)="
																			get_unit_general_List(
																				$event,
																				snaglistIndex,
																				unitIndex
																			)
																		"
																		[searchFn]="ds.customSearchFn"
																		id="unit_general_numbers"
																		name="unit_general_numbers"
																		(change)="
																			onChangeUnit(snaglistIndex, unitIndex)
																		"
																	>
																	</ng-select>
																</div>
																<div class="col-12 TableParts">
																	<div
																		class="table-responsive customResponsive tablefields quotationitemtable"
																	>
																		<table
																			border="1"
																			class="table CustomTable"
																			width="100%"
																			formArrayName="work_levels"
																		>
																			<thead>
																				<tr>
																					<td
																						width="3%"
																						style="
																							padding: 5px;
																							word-break: break-all;
																						"
																					>
																						#
																					</td>
																					<td
																						width="3%"
																						style="
																							padding: 5px;
																							word-break: break-all;
																						"
																					>
																						<mat-icon
																							class="mnabricon"
																							matTooltip="{{
																								'lang_add' | language
																							}}"
																							(click)="
																								addWorklevel(
																									snaglistIndex,
																									unitIndex
																								)
																							"
																							>add_box</mat-icon
																						>
																					</td>
																					<td
																						width="31%"
																						style="
																							padding: 5px;
																							word-break: break-all;
																						"
																					>
																						{{ "lang_work_level" | language }}
																					</td>
																					<td
																						width="31%"
																						style="
																							padding: 5px;
																							word-break: break-all;
																						"
																					>
																						{{ "lang_description" | language }}
																					</td>
																					<td
																						width="31%"
																						style="
																							padding: 5px;
																							word-break: break-all;
																						"
																					>
																						{{ "lang_file" | language }}
																					</td>
																				</tr>
																			</thead>
																			<tbody>
																				<ng-container
																					*ngFor="
																						let worklevel of worklevelFormGroup(
																							snaglistIndex,
																							unitIndex
																						).controls;
																						let worklevelIndex = index
																					"
																				>
																					<tr [formGroupName]="worklevelIndex">
																						<td>
																							{{ worklevelIndex + 1 }}
																						</td>
																						<td>
																							<mat-icon
																								class="red"
																								matTooltip="{{
																									'lang_remove' | language
																								}}"
																								(click)="
																									removeWorklevel(
																										snaglistIndex,
																										unitIndex,
																										worklevelIndex
																									)
																								"
																								>remove_circle</mat-icon
																							>
																						</td>
																						<td
																							style="
																								padding: 5px;
																								word-break: break-all;
																								position: relative;
																							"
																						>
																							<ng-select
																								[items]="
																									worklevelFormGroup(
																										snaglistIndex,
																										unitIndex
																									).controls[
																										worklevelIndex
																									].get('levels_list').value
																								"
																								[multiple]="false"
																								bindLabel="label"
																								bindValue="value"
																								appendTo="body"
																								clearAllText="Clear"
																								placeholder="{{
																									'lang_work_level' | language
																								}}"
																								formControlName="level_key"
																								[searchFn]="ds.customSearchFn"
																								(search)="
																									get_worklevels(
																										$event,
																										snaglistIndex,
																										unitIndex,
																										worklevelIndex
																									)
																								"
																							>
																							</ng-select>
																						</td>
																						<td
																							style="
																								padding: 5px;
																								word-break: break-all;
																								position: relative;
																							"
																						>
																							<input
																								type="text"
																								formControlName="level_description"
																								placeholder="{{
																									'lang_description' | language
																								}}"
																								autocomplete="off"
																								class="form-control almnabrformcontrol"
																							/>
																						</td>
																						<td
																							style="
																								padding: 5px;
																								word-break: break-all;
																								position: relative;
																							"
																						>
																							<input
																								name="file"
																								type="file"
																								required
																								onclick="this.value = null"
																								(change)="
																									ds.uploadSingleFile(
																										$event,
																										worklevelFormGroup(
																											snaglistIndex,
																											unitIndex
																										).controls[
																											worklevelIndex
																										].get('level_attachment'),
																										'file' + worklevelIndex,
																										filename
																									)
																								"
																								class="nghide albutton width100"
																							/>
																							<label
																								id="file{{ worklevelIndex }}"
																								class="albutton"
																								>{{ filename }}</label
																							>
																						</td>
																					</tr>
																				</ng-container>
																			</tbody>
																		</table>
																	</div>
																</div>
															</div>
														</div>
													</mat-tab>
												</mat-tab-group>
												<div
													class="no-tabs pt-2 pb-2"
													*ngIf="!unitFormGroup(snaglistIndex).controls.length"
												>
													<button
														(click)="addUnit(snaglistIndex)"
														mat-raised-button
														color="primary"
													>
														{{ "lang_add" | language }}
													</button>
												</div>
											</div>
										</td>
									</tr>
								</ng-container>
							</ng-container>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</form>
	<div class="p-2">
		<div class="row mt-2" *ngIf="err_msg">
			<div class="col-12">
				<p *ngIf="err_msg" class="red">{{ err_msg }}</p>
			</div>
			<div class="col-3 mb-3">
				<ng-select
					[items]="err_msg_types"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					[(ngModel)]="filterErrorMsgSelected"
					(change)="onFilterErrorMsg()"
				>
				</ng-select>
			</div>
			<div class="col-12">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<th>#</th>
							<th>{{ "lang_unit_id" | language }}</th>
							<th>{{ "lang_form_key" | language }}</th>
							<th>{{ "lang_platform_code_system" | language }}</th>
							<th>{{ "lang_report_type" | language }}</th>
							<th>{{ "lang_result_code" | language }}</th>
							<th>{{ "lang_transaction_request_id" | language }}</th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="
								let item of invalid_data
									| slice
										: (invalid_data_page - 1) * invalid_data_size
										: invalid_data_page * invalid_data_size;
								let i = index;
								let odd = odd;
								let even = even
							"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ item?.unit_id }}</td>
							<td>{{ item?.form_key }}</td>
							<td>{{ item?.platform_code_system }}</td>
							<td>{{ item?.report_type }}</td>
							<td>{{ item?.result_code }}</td>
							<td>{{ item?.transaction_request_id }}</td>
						</tr>
					</tbody>
					<tbody *ngIf="!invalid_data?.length">
						<tr>
							<td style="text-align: center" colspan="7">
								{{ "lang_no_data" | language }}
							</td>
						</tr>
					</tbody>
				</table>
				<div
					class="mt-2"
					*ngIf="invalid_data?.length > 0"
					style="
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
					"
				>
					<ngb-pagination
						*ngIf="invalid_data.length"
						[collectionSize]="invalid_data.length"
						[(page)]="invalid_data_page"
						[maxSize]="invalid_data_size"
						[boundaryLinks]="true"
					></ngb-pagination>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="add_snag_note()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
