import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TcreateComponent } from '../transactions/tcore/tcreate/tcreate.component';
import { TeditComponent } from '../transactions/tcore/tedit/tedit.component';
import { CommuncationListsComponent } from './communcation-lists/communcation-lists.component';
import { CcsettingsComponent } from './settings/ccsettings/ccsettings.component';
const routes: Routes = [
  {
    path:'settings',
    component : CcsettingsComponent,
    data: { title: 'Settings', titleI18n: 'lang_settings' },
  },
  {
    path:'1nuqwMtPcY',
    component : CommuncationListsComponent,
    data: { title: 'Communications', titleI18n: 'lang_communication_lists' },
  },
  {
    path: 'form/:form_key/cr/:form_request_id',
    component: TcreateComponent,
    data: { title: 'Transactions', titleI18n: 'lang_new_transactions' },
  },
  {
    path: 'form/:form_key/er/:form_request_id',
    component: TeditComponent,
    data: { title: 'Transactions', titleI18n: 'lang_edit_transactions' },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommunicationRoutingModule { }
