import { Appearance } from "@angular-material-extensions/google-maps-autocomplete";
import {
	trigger,
	state,
	style,
	transition,
	animate,
} from "@angular/animations";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { AddProjectComponent } from "../services/design/add-project/add-project.component";
import { DesignListsComponent } from "../services/design/design-lists/design-lists.component";
import { CreateManualSvwComponent } from "../services/supervision/create-manual-svw/create-manual-svw.component";
import { SupervisionListsComponent } from "../services/supervision/supervision-lists/supervision-lists.component";
import PlaceResult = google.maps.places.PlaceResult;
@Component({
	selector: "app-projects-view",
	templateUrl: "./projects-view.component.html",
	styleUrls: ["./projects-view.component.scss"],
	animations: [
		trigger("fadeIn", [
			state(
				"void",
				style({
					opacity: 0,
				})
			),
			transition("void <=> *", animate(1000)),
		]),
	],
})
export class ProjectsViewComponent implements OnInit {
	pageTitle = "Project View";
	pageIcon = "fa fa-briefcase";
	@ViewChild(SupervisionListsComponent) supervision: SupervisionListsComponent;
	@ViewChild(DesignListsComponent) design: DesignListsComponent;
	public project_profile_id = this.route.snapshot.paramMap.get("id");
	public appearance = Appearance;
	public mapaddress;
	public zoom: number = 19;
	public latitude: number;
	public longitude: number;
	public selectedAddress: PlaceResult;
	public add;
	public edit;
	public delete;
	public view;
	public wholedata: any = [];
	public servicedata: any = [];
	public projectdata: any = [];
	public filelevels: any = [];
	public user_type_id = this.ls.getItem("user_type_id");
	public is_admin = parseInt(this.ls.getItem("is_admin"));
	public show_quotation_tab = false;
	// variables declaration supervision quotation starts
	public show_quotation_lists = true;
	public show_quotation_create = false;
	public component: string = "list";
	// variables declaration  supervision quotation end
	constructor(
		public lang: LanguagePipe,
		public ds: DataService,
		public router: Router,
		public spinner: PreloaderService,
		public alert: AlertService,
		public route: ActivatedRoute,
		public ls: LocalStorage,
		private dialog: MatDialog
	) {}
	ngOnInit(): void {
		this.latitude = 52.520008;
		this.longitude = 13.404954;
		// this.setCurrentPosition();
		this.get_data_for_view();
		this.file_permission_levels();
	}
	public file_permission_levels() {
		this.filelevels = [];
		this.ds.getActionByUrl([], "lflevel").subscribe((res) => {
			if (res.status) {
				this.filelevels = res.records;
			}
		});
	}
	get_data_for_view() {
		this.spinner.show();
		this.ds
			.getActionByUrl([], "TEd1bgyHSC0GPcq/" + this.project_profile_id)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.wholedata = res;
						this.add = res.add;
						this.edit = res.edit;
						this.view = res.view;
						this.delete = res.delete;
						this.show_quotation_tab = this.show_quotation(
							res?.service_user_data?.users
						);
						this.mapaddress = res.data.projects_profile_location;
						if (res.data.longitude && res.data.latitude) {
							this.latitude = Number(res.data.latitude);
							this.longitude = Number(res.data.longitude);
						}
						this.zoom = 16;
					} else {
						this.alert.success(res.error);
						this.router.navigate(["BbVUrWitLE"]);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	show_quotation(users) {
		let user = users.find((user) => {
			return user.value == this.user_type_id;
		});
		if (user) {
			return true;
		} else {
			return false;
		}
	}
	public setCurrentPosition() {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.latitude = position.coords.latitude;
				this.longitude = position.coords.longitude;
			});
		}
	}
	// create supervision quotation starts
	show_create_quotation(key, projectdata, service) {
		this.servicedata = service;
		this.projectdata = projectdata;
		this.component = key;
	}
	quotationEvent() {
		this.component = "list";
	}
	// create supervision quotation end

	by_users_type(users, type) {
		let usersarray: any = [];
		if (users.length > 0) {
			users.forEach((v, k) => {
				if (v.position == type && v.label) {
					usersarray.push(v);
				}
			});
		}
		return usersarray;
	}
	public load_supervision_lists(event) {
		this.supervision.load_supervision_lists();
	}

	addDesignProject(service_id) {
		const dialogRef = this.dialog.open(AddProjectComponent, {
			width: "800px",
			data: {
				projects_profile_id: this.project_profile_id,
				service_id: service_id,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res?.status == "success") {
				this.spinner.show();
				this.design.load_design_lists();
			}
		});
	}

	addSupervisionProject(service_id) {
		const dialogRef = this.dialog.open(CreateManualSvwComponent, {
			width: "800px",
			data: {
				projects_profile_id: this.project_profile_id,
				service_id: service_id,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res?.status == "success") {
				this.spinner.show();
				this.supervision.load_supervision_lists();
			}
		});
	}
}
