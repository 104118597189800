<div class="mbrl15">
  <div class="row">
    <div class="col-md-3 col-sm-12 col-xs-12 mb-10">
      <select
        [(ngModel)]="statusLists"
        class="form-control almnabrformcontrol"
        matTooltip="{{ 'lang_paid_status' | language }}"
        matTooltipPosition="above"
        (change)="spinner.show(); getEmployeeInfo()"
      >
        <option disabled value="0">
          {{ "lang_choose_options" | language }}
        </option>
        <option *ngFor="let type of boolenStatus" value="{{ type.value }}">
          {{ type.title }}
        </option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="table-responsive dropdownyes customResponsive col-12">
      <table border="1" class="table CustomTable">
        <thead>
          <tr>
            <td>#</td>
            <td>{{ "lang_finance_type" | language }}</td>
            <td>{{ "lang_finance_description" | language }}</td>
            <td *ngIf="is_admin == 0">{{ "lang_credit_amount" | language }}</td>
            <td>{{ "lang_debit_amount" | language }}</td>
            <td *ngIf="is_admin == 1">{{ "lang_credit_amount" | language }}</td>
            <td>{{ "lang_finance_date" | language }}</td>
            <td>{{ "lang_paid_date" | language }}</td>
            <td>{{ "lang_action" | language }}</td>
          </tr>
        </thead>
        <tbody *ngIf="data?.status == true" class="AppendList">
          <tr
            *ngFor="
              let report of data?.records;
              let i = index;
              let odd = odd;
              let even = even
            "
            [ngClass]="{ odd: odd, even: even }"
          >
            <td>{{ report.finance_id }}</td>
            <td>
              {{ report.finance_type }}
            </td>
            <td>{{ report.finance_description }}</td>
            <td>{{ report.credit_amount }}</td>
            <td>{{ report.debit_amount }}</td>
            <td>{{ report.finance_date }}</td>
            <td>{{ report.paid_date }}</td>
            <td>
              <div class="icons">
                <i
                  class="mat-tooltip-trigger fa fa-eye mnabricon"
                  matTooltip="{{ 'lang_view' | language }}"
                  matTooltipPosition="above"
                  (click)="
                    this.spinner.show();
                    setTemplet(view);
                    getFinanceHistory(report?.finance_id)
                  "
                ></i>
                <i
                  class="fa fa-pencil-square-o fa-icon mnabricon"
                  matTooltip="{{ 'lang_update' | language }}"
                  matTooltipPosition="above"
                  (click)="
                    openModalA(update_report); setFinanceId(report.finance_id)
                  "
                ></i>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="data?.status == false" class="AppendList">
          <tr class="odd">
            <td colspan="8" align="center">{{ "lang_no_data" | language }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div *ngIf="data?.status == true" class="card-footer padb0 bottomfooter">
  <div class="row responsive_pagination">
    <div
      class="col-lg-3 col-sm-3 mb-10"
      style="height: fit-content; margin: auto 0"
    >
      {{ "lang_total" | language }} : {{ data?.page?.total_records }}
    </div>
    <div
      class="col-lg-5 col-sm-5 mb-10"
      style="display: flex; justify-content: center"
    >
      <ngb-pagination
        class="m-auto"
        [collectionSize]="data?.page?.total_records"
        [rotate]="true"
        [ellipses]="false"
        [maxSize]="3"
        [boundaryLinks]="true"
        [(page)]="apage"
        [pageSize]="apsize"
        (pageChange)="spinner.show(); reportlength($event)"
      ></ngb-pagination>
    </div>
    <div
      class="col-lg-3 col-sm-3 mb-10"
      style="display: flex; justify-content: end; margin: auto"
    >
      <mat-select
        [(ngModel)]="apsize"
        (selectionChange)="spinner.show(); reportpage($event.value)"
      >
        <mat-option class="text-center" value="10"
          >10 {{ "lang_per_page" | language }}
        </mat-option>
        <mat-option class="text-center" value="20"
          >20 {{ "lang_per_page" | language }}
        </mat-option>
        <mat-option class="text-center" value="50"
          >50 {{ "lang_per_page" | language }}
        </mat-option>
        <mat-option class="text-center" value="100"
          >100 {{ "lang_per_page" | language }}</mat-option
        >
        <mat-option class="text-center" value="500"
          >500 {{ "lang_per_page" | language }}</mat-option
        >
      </mat-select>
    </div>
  </div>
</div>

<ng-template #update_report>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_financial_report" | language }}</h4>
      <button type="button" class="close" (click)="modalRefA.hide()">
        &times;
      </button>
    </div>
    <form [formGroup]="attachform" (ngSubmit)="UpdateFinance()">
      <div class="modal-body">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <label for="paid_status"
                >{{ "lang_paid_status" | language }} :
                <span class="red">*</span></label
              >
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <select
                formControlName="paid_status"
                placeholder="{{ 'lang_paid_status' | language }}"
                class="form-control almnabrformcontrol"
              >
                <option disabled value="0">
                  {{ "lang_choose_options" | language }}
                </option>
                <option *ngFor="let type of status" value="{{ type.value }}">
                  {{ type.title }}
                </option>
              </select>
              <mat-error
                *ngIf="
                  attachform.controls['paid_status'].touched &&
                  attachform.controls['paid_status'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <label for="attachment"
                >{{ "lang_select_files" | language }} :
                <span class="red">*</span></label
              >
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <div class="IDDIV">
                  <input
                    name="attachment"
                    type="file"
                    required
                    onclick="this.value = null"
                    (change)="uploadIDFile($event)"
                    class="nghide albutton width100"
                    accept=".pdf"
                  />
                  <label id="uploadIDButton" class="albutton"
                    >{{ idfilename }}
                  </label>
                </div>
              </div>
              <mat-error
                *ngIf="
                  attachform.controls['attachment'].touched &&
                  attachform.controls['attachment'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              (click)="modalRefA.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #view>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_report_view" | language }}</h4>
      <button type="button" class="close" (click)="modalRefB.hide()">
        &times;
      </button>
    </div>
    <div class="modal-body p-0">
      <div class="mbrl15">
        <div class="row">
          <div class="table-responsive dropdownyes customResponsive col-12">
            <table border="1" class="table CustomTable">
              <thead>
                <tr>
                  <td>#</td>
                  <td>{{ "lang_paid_history_id" | language }}</td>
                  <td>{{ "lang_paid_method" | language }}</td>
                  <td>{{ "lang_paid_status" | language }}</td>
                  <td>{{ "lang_writer_name" | language }}</td>
                  <td>{{ "lang_created_at" | language }}</td>
                  <td>{{ "lang_action" | language }}</td>
                </tr>
              </thead>
              <tbody *ngIf="history?.status == true" class="AppendList">
                <tr
                  *ngFor="
                    let report of history.records;
                    let i = index;
                    let odd = odd;
                    let even = even
                  "
                  [ngClass]="{ odd: odd, even: even }"
                >
                  <td>{{ report.finance_id }}</td>
                  <td>
                    {{ report.paid_history_id | language }}
                  </td>
                  <td>{{ report.paid_method }}</td>
                  <td>{{ report.paid_status }}</td>
                  <td>{{ report.writer_name | language }}</td>
                  <td>{{ report.created_at }}</td>
                  <td>
                    <i
                      class="mat-tooltip-trigger fa fa-file-pdf-o mnabricon"
                      matTooltip="{{ 'lang_view' | language }}"
                      matTooltipPosition="above"
                      (click)="
                        modalRefB.hide();
                        this.spinner.show();
                        ds.preview_pdf_file(report.link, report?.paid_method)
                      "
                    ></i>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="history?.status == false" class="AppendList">
                <tr class="odd">
                  <td colspan="9" align="center">
                    {{ "lang_no_data" | language }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        *ngIf="history?.status == true"
        class="card-footer padb0 bottomfooter pb-0"
      >
        <div class="row responsive_pagination">
          <div
            class="col-lg-3 col-sm-3 mb-10"
            style="height: fit-content; margin: auto 0"
          >
            {{ "lang_total" | language }} : {{ history?.page?.total_records }}
          </div>
          <div
            class="col-lg-5 col-sm-5 mb-10"
            style="display: flex; justify-content: center"
          >
            <ngb-pagination
              class="m-auto"
              [collectionSize]="history?.page?.total_records"
              [rotate]="true"
              [ellipses]="false"
              [maxSize]="3"
              [boundaryLinks]="true"
              [(page)]="apage"
              [pageSize]="apsize"
              (pageChange)="spinner.show(); reportlength($event)"
            ></ngb-pagination>
          </div>
          <div
            class="col-lg-3 col-sm-3 mb-10"
            style="display: flex; justify-content: end; margin: auto"
          >
            <mat-select
              [(ngModel)]="apsize"
              (selectionChange)="spinner.show(); reportpage($event.value)"
            >
              <!-- <mat-option value="1">1  </mat-option> -->
              <mat-option class="text-center" value="10"
                >10 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="20"
                >20 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="50"
                >50 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="100"
                >100 {{ "lang_per_page" | language }}</mat-option
              >
              <mat-option class="text-center" value="500"
                >500 {{ "lang_per_page" | language }}</mat-option
              >
            </mat-select>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer pad15">
      <div class="row">
        <div class="col-12 mb-10">
          <app-alert id="default-alert"></app-alert>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 col-xs-12 mb-10"></div>
        <div class="col-md-3 col-xs-12 mb-10">
          <button
            type="button"
            class="btn btn-secondary secondarybutton"
            (click)="modalRefB.hide()"
          >
            {{ "lang_close" | language }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
