import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SendCodeComponent } from 'app/routes/transactions/FORM_PS_TR1/send-code/send-code.component';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { P } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-ncr-step4',
  templateUrl: './ncr-step4.component.html',
  styleUrls: ['./ncr-step4.component.scss']
})
export class NcrStep4Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  stepName = 'Contractor_Approval_Step1'
  form_request_id   = this.route.snapshot.paramMap.get('form_request_id');
  form_key2          = this.route.snapshot.paramMap.get('form_key');
  other_attachments:FormArray;
  template_id:any;
  projects_work_area_id:any; 
  platform_code_system:any;
  sendCodeForm:any;
  sendCodeStatus:boolean = false;
  rejectedValidators = [];
  verifyerrormsg: any = '';
  form:FormGroup;
  users:any   = [];
  lang_key:string;
  filename    = this.lang.transform('lang_select_files');
  page_no            = '1';
  page_size          = '10';
  work_name_array   = [];
  issues            = [];
  manager_contractor_required;
  manager_contractor_required_step2;
  stepNameTitle = this.lang.transform('lang_contractor_fixing')
  constructor(public ds: DataService, public router: Router, private sweetAlert:SweetAlertService, public route: ActivatedRoute, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id           : new FormControl(this.form_request_id, Validators.required),
      reason                           : new FormControl('', Validators.required),
      suggestion_to_fixing             : new FormControl('', Validators.required),
      preventive_action                : new FormControl('', Validators.required),
      fixing_date                      : new FormControl(null, Validators.required),
      attachments                      : this.fb.array([]),
      // transactions_persons_action_code : new FormControl('', [Validators.required])
    });
    this.other_attachments = this.form.get("attachments") as FormArray;
    
    this.manager_contractor_required = this.allData?.view_request?.form_ncr_data?.records[0]?.manager_contractor_required;
    this.manager_contractor_required_step2 = this.allData?.view_request?.form_ncr_data?.records[0]?.manager_contractor_required_step2;
    if(!parseInt(this.manager_contractor_required)) {
      this.addfiles({attach_title: 'Contractor Confirmed Document'})
      this.stepNameTitle = this.lang.transform('lang_consultant_fixing')
    }
  }
  get filesFormGroup() {
    return this.form.get("attachments") as FormArray;
  }

  public files(value?): FormGroup {
    return this.fb.group({
      delete_icon     : new FormControl(value?.attach_title || ''),
      attach_title    : new FormControl({value : (value) ? value?.attach_title : '', disabled : (value?.attach_title) ? true : false}, [Validators.required]),
      file            : new FormControl(null,  [Validators.required]),
    });
  }
  public addfiles(value?) {
    if (this.other_attachments.length < 10) {
      this.other_attachments.push(this.files(value));
      this.other_attachments.setValidators(Validators.required);
      this.other_attachments.updateValueAndValidity();
    }
  }
  public removefiles(index) {
    if(this.other_attachments.length == 1) {
      this.other_attachments.removeAt(index);
      this.other_attachments.clearValidators();
      this.other_attachments.updateValueAndValidity();
    } else {
      this.other_attachments.removeAt(index);
    }
  }
  checkValidation() {
    
    if(this.form.get('reason').invalid) {
      this.sweetAlert.errorMessage(this.lang.transform('lang_reason_required'));
      return false;
    } else if(this.form.get('suggestion_to_fixing').invalid) {
      this.sweetAlert.errorMessage(this.lang.transform('lang_suggestion_to_fixing_required'));
      return false;
    } else if(this.form.get('preventive_action').invalid) {
      this.sweetAlert.errorMessage(this.lang.transform('lang_preventive_action_required'));
      return false;
    } else if(this.form.get('fixing_date').invalid) {
      this.sweetAlert.errorMessage(this.lang.transform('lang_fixing_date_required'));
      return false;
    } else if(this.form.get('attachments').invalid && !parseInt(this.manager_contractor_required)) {
      this.sweetAlert.errorMessage(this.lang.transform('lang_attachments_required'));
      return false;
    } 
    // else if(this.form.get('transactions_persons_action_code').invalid) {
    //   this.sweetAlert.errorMessage(this.lang.transform('lang_send_code_required'));
    // } 
    else {
      return true;
    }  
  }

  completeStep2() {
    if(this.checkValidation()) {
      this.spinner.show();
      let param = new FormData();
      param.append('transaction_request_id', this.form_request_id)
      param.append('template_id', this.template_data?.template_id || this.template_id);
      param.append('ncr_issue_reason', this.form.get('reason').value);
      param.append('ncr_issue_fix_suggestion', this.form.get('suggestion_to_fixing').value);
      param.append('ncr_issue_preventive_action', this.form.get('preventive_action').value);
      param.append('ncr_issue_fixing_date', this.form.get('fixing_date').value);
      // param.append('transactions_persons_action_code', this.form.get('transactions_persons_action_code').value);

      if(this.form.get('attachments').value.length) {
        this.filesFormGroup.controls.forEach((v, k) => {
          param.append("attachments[" + k + "][file]", v.get('file').value);
          param.append("attachments[" + k + "][required]", 'Yes');
          param.append("attachments[" + k + "][attach_title]", v.get('attach_title').value);        
        });
      }
      this.ds.post(`/form/${this.form_key}/Contractor_Approval_Step1/0`, param).subscribe(data => {
        if(data.status) {
          this.spinner.hide();
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
          setTimeout(() => {
            this.refreshComponent.emit(true);
          }, 2000);
        } else {
          this.spinner.hide();
          this.alert.error(data.error)
        }
      }, (error) => {
        this.spinner.hide();
          this.alert.error(this.lang.transform('lang_server_error'));
      })
    } 
  }

  openModal() {
    const dialogRef = this.dialog.open(SendCodeComponent,{
      disableClose: true,
      width:'400px',
      data: {transaction_persons_type: 'signature', allData: this.allData, stepName:this.stepName,title:'lang_signature'}
      
    });
  }


}
