import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

import { Location } from "@angular/common";
import moment from "moment";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { environment } from "@env/environment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-hrv1-asPdf-view",
  templateUrl: "./hrv1-asPdf-view.component.html",
  styleUrls: ["./hrv1-asPdf-view.component.scss"],
})
export class hrv1AsPdfViewComponent implements OnInit {
  @Input() records;
  @Input() financial_details;
  @Input() notes;
  @Input() status;
  @Input() lang_key;
  @Input() vacation_data;

  modalRefV: BsModalRef;
  financial_details_list = [];
  net_amount;
  details = [];
  constructor(
    private sweetAlert: SweetAlertService,
    public router: Router,
    private route: ActivatedRoute,
    private Location: Location,
    public ds: DataService,
    public spinner: PreloaderService,
    public lang: LanguagePipe,
    private fb: FormBuilder,
    private alert: AlertService,
    private snackBar: MatSnackBar,
    public modalService: BsModalService
  ) {}

  ngOnInit(): void {}

  openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  get_details(month, year, temp) {
    this.spinner.show();
    let param = new FormData();
    param.append("employee_number", this.records.employee_number);
    param.append("year", year);
    param.append("month", month);
    this.subscriptions.add(
      this.ds
        .postActionByUrl(param, "form/FORM_HRV1/detailes_salary_amount")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res?.status) {
              this.details = res.data;
              this.net_amount = res?.data?.net_amount;
              this.financial_details_list = res?.data?.financial_details;
              if (this.financial_details_list?.length > 0) {
                this.financial_details_list = res?.data?.financial_details[0];
                this.sweetAlert.successToast(
                  this.lang.transform("lang_success"),
                  2000
                );
                this.openModalV(temp);
              } else {
                this.sweetAlert.errorToast(
                  this.lang.transform("lang_no_data"),
                  2000
                );
              }
            } else {
              this.sweetAlert.errorToast(res?.msg, 2000);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
