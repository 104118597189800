import { Component, Input, OnInit } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatSnackBar } from "@angular/material/snack-bar";
declare var $: any;
@Component({
	selector: "app-formc2-edit",
	templateUrl: "./formc2-edit.component.html",
	styleUrls: ["./formc2-edit.component.scss"],
})
export class Formc2EditComponent implements OnInit {
	@Input() form_request_id;
	apiurl = environment.SERVER_ORIGIN;
	reuploadstatus: boolean = false;
	fileslist: any = [];
	// public Editor = ClassicEditor;
	singleselection = environment.singledropdown;
	multipleselection = environment.multipledropdown;
	error_message: string = "";
	error_status: boolean = false;
	stamps: any = [];
	form_key = "FORM_C2";
	filename = this.lang.transform("lang_select_files");
	isFile: boolean = false;
	form: FormGroup;
	markstatus: boolean = false;
	reviewstatus: boolean = false;
	attachstatus: boolean = false;
	stampstatus: boolean = false;
	attachments: FormArray;
	languages: any = [];
	branches: any = [];
	loading = false;
	document_types: any = [];
	users: any = [];
	usersA: any = [];
	usersB: any = [];
	usersC: any = [];
	usersM: any = [];
	usersR: any = [];
	submitted = false;
	lang_key =
		localStorage.getItem("lang_key") == null
			? "en"
			: localStorage.getItem("lang_key");
	module_key: string;
	contractor_id: string;
	customer_id: string;
	employee_number: string;
	projects_profile_id: string;
	projects_work_area_id: string;
	constructor(
		public ds: DataService,
		private snackBar: MatSnackBar,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router
	) {}
	ngOnInit() {
		this.build_form();
		this.get_required_data("tc/tlanguages", "lang");
		this.get_required_data("tc/tbranches", "branch");
		this.get_required_data("tc/doctypes", "type");
		this.get_data_edit();
	}
	changeEditorDir(event) {
		this.lang_key = event != undefined ? event.value : this.lang_key;
		$(
			"ckeditor .ck.ck-reset.ck-editor, ckeditor .ck.ck-content.ck-editor__editable"
		).attr({
			style:
				event.value == "ar"
					? "text-align : right !important"
					: "text-align : left !important",
			lang: event.value,
			dir: event.value == "ar" ? "rtl" : "ltr",
		});
		this.useLanguage(event?.value);
	}
	useLanguage(language: string) {
		let dir = language == "en" ? "ltr" : "rtl";
		localStorage.setItem("lang_key", language);
		if (localStorage.getItem("direction") != dir) {
			localStorage.setItem("direction", dir);
			this.spinner.show();
			this.ds.get("change_lang/" + language).subscribe(
				(response) => {
					this.spinner.hide();
					if (response.status) {
						window.location.reload();
					}
				},
				(error) => {
					this.spinner.hide();
				}
			);
		}
	}
	public get_data_edit() {
		this.usersM = [];
		this.usersR = [];
		this.ds
			.getActionByUrl(
				[],
				"form/" + this.form_key + "/vr/" + this.form_request_id
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.fileslist = res.form_c2_files.status
							? res.form_c2_files.records
							: [];
						let data = {};
						data["subject"] = res.form_c2_data.records[0]["subject"];
						data["issued_number"] =
							res.form_c2_data.records[0]["issued_number"];
						data["issued_date_m"] =
							res.form_c2_data.records[0]["issued_date_m"];
						data["issued_date_h"] =
							res.form_c2_data.records[0]["issued_date_h"];
						data["attachmentstitle"] =
							res.form_c2_data.records[0]["attachment"];
						data["lang_key"] = res.transactions_request["lang_key"];
						data["transaction_to"] =
							res.form_c2_data.records[0]["transaction_to"];
						data["transaction_from"] =
							res.form_c2_data.records[0]["transaction_from"];
						const escapedData = res.form_c2_data.records[0]["content"];
						const contentData = escapedData.replace(/\\"/g, '"');
						data["content"] = contentData;
						if (res.form_c2_files && res.form_c2_files.status) {
							this.attachstatus = true;
						}
						let defaultusers = [];
						if (res.transactions_persons && res.transactions_persons.status) {
							let markers = [];
							res.transactions_persons.records.forEach((v, k) => {
								defaultusers.push({
									value: v.user_id,
									label: v.person_name,
								});
								//recepients
								if (v.transactions_persons_val1 === "C") {
									data["signature[C][title]"] = v.person_name;
									data["signature[C][user_id]"] = v.user_id;
								}
								//markers
								if (v.transaction_persons_type === "marks") {
									this.markstatus = true;
									data["needMark"] = "1"; // need Mark or not
									markers.push(v.user_id);
								}
							});
							data["marks"] = markers;
						}
						this.users = defaultusers;
						this.form.patchValue(data);
						for (let item of res?.transactions_extra?.records) {
							if (item?.extra_custom_key == "module_key") {
								this.module_key = item?.extra_custom_val;
							}
							if (item?.extra_custom_key == "contractor_id") {
								this.contractor_id = item?.extra_custom_val;
							}
							if (item?.extra_custom_key == "customer_id") {
								this.customer_id = item?.extra_custom_val;
							}
							if (item?.extra_custom_key == "projects_work_area_id") {
								this.projects_work_area_id = item?.extra_custom_val;
							}
							if (item?.extra_custom_key == "projects_profile_id") {
								this.projects_profile_id = item?.extra_custom_val;
							}
							if (item?.extra_custom_key == "employee_number") {
								this.employee_number = item?.extra_custom_val;
							}
						}
					} else {
						this.ds.dialogf("", res.error);
						this.router.navigate(["transactions/allforms/"]);
					}
				},
				(error) => {
					this.spinner.hide();
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	enable_upload_field() {
		this.attachments.controls = [];
		this.reuploadstatus = true;
		this.attachments.push(this.files());
	}
	disable_upload_field() {
		this.reuploadstatus = false;
		this.attachments.controls = [];
		this.form.get("attachments").setValue([]);
	}
	get_required_data(url, key) {
		this.languages = [];
		this.branches = [];
		this.document_types = [];
		this.ds.getActionByUrl([], url).subscribe((res) => {
			this.spinner.hide();
			if (res.status) {
				if (key === "lang") {
					this.languages = res.records;
				} else if (key === "branch") {
					this.branches = res.records;
				} else if (key === "type") {
					this.document_types = res.records;
				}
			}
		});
	}
	public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append("search", key.term || "");
		param.append("lang_key", this.form.get("lang_key").value || this.lang_key);
		param.append("user_type_id", "1");
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.users = res.list;
				}
			});
	}
	get filesFormGroup() {
		return this.form.get("attachments") as FormArray;
	}
	public build_form() {
		this.form = new FormGroup({
			lang_key: new FormControl(this.lang_key || null, [Validators.required]),
			subject: new FormControl("", [Validators.required]),
			transaction_from: new FormControl("", [Validators.required]),
			transaction_to: new FormControl(null, [Validators.required]),
			issued_number: new FormControl("", [Validators.required]),
			issued_date_m: new FormControl("", [Validators.required]),
			issued_date_h: new FormControl("", [Validators.required]),
			"signature[C][user_id]": new FormControl(null, [Validators.required]),
			content: new FormControl("", [Validators.required]),
			attachmentstitle: new FormControl(""),
			marks: new FormControl(null),
			attachments: this.fb.array([]),
		});
		this.attachments = this.form.get("attachments") as FormArray;
	}
	files(): FormGroup {
		return this.fb.group({
			attach_title: new FormControl("", [Validators.required]),
			file: new FormControl("", [Validators.required]),
		});
	}
	addfiles() {
		if (this.attachments.length < 5) {
			this.attachments.push(this.files());
		}
	}
	removefiles(index) {
		if (this.attachments.length > 1) {
			this.attachments.removeAt(index);
		} else {
			this.showMsg("lang_at_least_one_is_required", "ok", "error-snackbar");
		}
	}
	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 4000,
			panelClass: [className],
		});
	}
	getfilesgroup(index): FormGroup {
		const formGroup = this.attachments.controls[index] as FormGroup;
		return formGroup;
	}
	public UploadFile(event, index) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			const fileid = event.target.files[0];
			$("#uploadText" + index).text(fileid.name);
			this.attachments.controls[index]
				.get("file")
				.setValue(fileid, { emitEvent: true });
		} else {
			this.attachments.controls[index].get("file").setValue(null);
			$("#uploadText" + index).text(this.lang.transform("lang_select_files"));
		}
	}
	public removeFile() {
		this.isFile = false;
		this.form.get("upload").setValue(null);
		this.filename = this.lang.transform("lang_select_files");
	}
	public submitForm() {
		this.submitted = true;
		if (this.form.valid) {
			let param = new FormData();
			param.set("transaction_request_id", this.form_request_id);
			param.set("lang_key", this.form.get("lang_key").value || "");
			param.set("subject", this.form.get("subject").value);
			param.set("transaction_from", this.form.get("transaction_from").value);
			param.set("transaction_to", this.form.get("transaction_to").value || "");
			param.set("issued_number", this.form.get("issued_number").value);
			param.set("issued_date_m", this.form.get("issued_date_m").value);
			param.set("issued_date_h", this.form.get("issued_date_h").value);
			param.set(
				"attachmentstitle",
				this.form.get("attachmentstitle").value || ""
			);
			param.set(
				"signature[C][user_id]",
				this.form.get("signature[C][user_id]").value || ""
			);
			param.set("content", this.form.get("content").value);
			param.set(
				"needMark",
				this.form.get("marks").value && this.form.get("marks").value.length > 0
					? "1"
					: "0"
			);
			param.set(
				"marks",
				this.form.get("marks").value && this.form.get("marks").value.length > 0
					? this.form.get("marks").value.toString()
					: ""
			);
			param.set("needAttachEdit", this.reuploadstatus ? "1" : "0");
			this.filesFormGroup.value.forEach((v, k) => {
				param.set("attachments[" + k + "][file]", v.file);
				param.set("attachments[" + k + "][attach_title]", v.attach_title);
			});
			param.set("extra[module_key]", this.module_key);
			if (this.module_key == "projects") {
				param.set("extra[projects_work_area_id]", this.projects_work_area_id);
				param.set("extra[projects_profile_id]", this.projects_profile_id);
			} else if (this.module_key == "human_resources") {
				param.set("extra[employee_number]", this.employee_number);
			} else if (this.module_key == "customers") {
				param.set("extra[customer_id]", this.customer_id);
			} else if (this.module_key == "contractors") {
				param.set("extra[contractor_id]", this.contractor_id);
			}
			this.spinner.show();
			this.ds.postActionByUrl(param, "form/" + this.form_key + "/et").subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.alert.success(res.msg);
						this.form.reset();
						this.router.navigate([
							"transactions/form/" +
								this.form_key +
								"/vr/" +
								this.form_request_id,
						]);
					} else {
						this.error_message = res.error;
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
		}
	}
}
