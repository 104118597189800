import { Injectable } from "@angular/core";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { BehaviorSubject } from "rxjs";
import { Chat } from "../models/chat";

@Injectable({
	providedIn: "root",
})
export class ChatService {
	selectedChat: BehaviorSubject<Chat> = new BehaviorSubject(null);
	newContact: BehaviorSubject<any> = new BehaviorSubject(null);
	chatList: BehaviorSubject<any> = new BehaviorSubject([]);
	constructor() {}
}
