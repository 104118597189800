<div class="card supervision_teams">
	<div class="card-header">{{ "lang_owner" | language }}</div>
	<div class="card-body pad0">
		<mat-tab-group (selectedTabChange)="selectedListTabValue($event)">
			<mat-tab label="bank_payments">
				<ng-template mat-tab-label>{{
					"lang_bank_payments" | language
				}}</ng-template>
				<ng-container>
					<div class="p-2">
						<app-bank-payments
							[projects_work_area_id]="supervision_data?.projects_work_area_id"
							[projects_profile_id]="project_data?.projects_profile_id"
							[projects_supervision_id]="
								supervision_data?.projects_supervision_id
							"
						></app-bank-payments>
					</div>
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
