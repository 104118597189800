import { InvoiceClaimViewComponent } from "./transactions/invoice-claim/invoice-claim-view/invoice-claim-view.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AccountMasterListComponent } from "./account-masters/account-master-list/account-master-list.component";
import { CostCenterListComponent } from "./cost-center/cost-center-list/cost-center-list.component";
import { OpeningBalanceComponent } from "./opening-balance/opening-balance.component";
import { AccountTrialComponent } from "./reports/accounts/account-trial/account-trial.component";
import { BalanceSheetsComponent } from "./reports/accounts/balance-sheets/balance-sheets.component";
import { GeneralLedgerComponent } from "./reports/accounts/general-ledger/general-ledger.component";
import { IncomeStatementComponent } from "./reports/accounts/income-statement/income-statement.component";
import { ProfitLossStatementComponent } from "./reports/accounts/profit-loss-statement/profit-loss-statement.component";
import { StatementOfAccountsComponent } from "./reports/accounts/statement-of-accounts/statement-of-accounts.component";
import { TransactionListingComponent } from "./reports/accounts/transaction-listing/transaction-listing.component";
import { TrialBalanceComponent } from "./reports/accounts/trial-balance/trial-balance.component";
import { CostSummaryComponent } from "./reports/costs/cost-summary/cost-summary.component";
import { StatementOfCostsComponent } from "./reports/costs/statement-of-costs/statement-of-costs.component";
import { SettingsComponent } from "./settings/settings.component";
import { InvoiceNotesAddComponent } from "./transactions/invoice-notes/invoice-notes-add/invoice-notes-add.component";
import { InvoiceNotesListComponent } from "./transactions/invoice-notes/invoice-notes-list/invoice-notes-list.component";
import { InvoiceNotesViewComponent } from "./transactions/invoice-notes/invoice-notes-view/invoice-notes-view.component";
import { InvoiceReversalAddComponent } from "./transactions/invoice-reversal/invoice-reversal-add/invoice-reversal-add.component";
import { InvoiceReversalListComponent } from "./transactions/invoice-reversal/invoice-reversal-list/invoice-reversal-list.component";
import { InvoiceReversalViewComponent } from "./transactions/invoice-reversal/invoice-reversal-view/invoice-reversal-view.component";
import { JournalAddComponent } from "./transactions/journal/journal-add/journal-add.component";
import { JournalEditComponent } from "./transactions/journal/journal-edit/journal-edit.component";
import { JournalListComponent } from "./transactions/journal/journal-list/journal-list.component";
import { JournalViewComponent } from "./transactions/journal/journal-view/journal-view.component";
import { PaymentAddComponent } from "./transactions/payments/payment-add/payment-add.component";
import { PaymentEditComponent } from "./transactions/payments/payment-edit/payment-edit.component";
import { PaymentListComponent } from "./transactions/payments/payment-list/payment-list.component";
import { PaymentViewComponent } from "./transactions/payments/payment-view/payment-view.component";
import { PinvoiceAddComponent } from "./transactions/purchase-invoices/pinvoice-add/pinvoice-add.component";
import { PinvoiceEditComponent } from "./transactions/purchase-invoices/pinvoice-edit/pinvoice-edit.component";
import { PinvoiceListComponent } from "./transactions/purchase-invoices/pinvoice-list/pinvoice-list.component";
import { PinvoiceViewComponent } from "./transactions/purchase-invoices/pinvoice-view/pinvoice-view.component";
import { ReceiptAddComponent } from "./transactions/receipts/receipt-add/receipt-add.component";
import { ReceiptEditComponent } from "./transactions/receipts/receipt-edit/receipt-edit.component";
import { ReceiptListsComponent } from "./transactions/receipts/receipt-lists/receipt-lists.component";
import { ReceiptViewComponent } from "./transactions/receipts/receipt-view/receipt-view.component";
import { SinvoiceAddComponent } from "./transactions/selling-invoices/sinvoice-add/sinvoice-add.component";
import { SinvoiceEditComponent } from "./transactions/selling-invoices/sinvoice-edit/sinvoice-edit.component";
import { SinvoiceListComponent } from "./transactions/selling-invoices/sinvoice-list/sinvoice-list.component";
import { SinvoiceViewComponent } from "./transactions/selling-invoices/sinvoice-view/sinvoice-view.component";
import { TransactionViewerComponent } from "./transactions/transaction-viewer/transaction-viewer.component";
import { InvoiceClaimListComponent } from "./transactions/invoice-claim/invoice-claim-list/invoice-claim-list.component";
import { AddInvoiceClaimComponent } from "./transactions/invoice-claim/add-invoice-claim/add-invoice-claim.component";
import { InvoiceClaimEditComponent } from "./transactions/invoice-claim/invoice-claim-edit/invoice-claim-edit.component";
import { SubAccountTrialComponent } from "./reports/accounts/sub-account-trial/sub-account-trial.component";
import { NonEqualTransactionsComponent } from "./non-equal_transactions/non-equal-transactions/non-equal-transactions.component";
import { TellerReportsComponent } from "./teller-reports/teller-reports/teller-reports.component";
const routes: Routes = [
  {
    path: "asettings",
    component: SettingsComponent,
  },
  {
    path: "aam",
    component: AccountMasterListComponent,
  },
  {
    path: "acc",
    component: CostCenterListComponent,
  },
  // Routes For Receipt Starts
  {
    path: "reclist",
    component: ReceiptListsComponent,
  },
  {
    path: "nonequal_transactions",
    component: NonEqualTransactionsComponent,
  },
  {
    path: "teller_reports",
    component: TellerReportsComponent,
  },
  {
    path: "reccreate/:bid/:fid",
    component: ReceiptAddComponent,
  },
  {
    path: "recedit/:bid/:rid/:fid/:key",
    component: ReceiptEditComponent,
  },
  {
    path: "recview/:bid/:rid/:fid/:key",
    component: ReceiptViewComponent,
  },
  // Routes For Receipt End
  // Routes For Payment Starts
  {
    path: "paylist",
    component: PaymentListComponent,
  },
  {
    path: "paycreate/:bid/:fid",
    component: PaymentAddComponent,
  },
  {
    path: "payedit/:bid/:pid/:fid/:key",
    component: PaymentEditComponent,
  },
  {
    path: "payview/:bid/:pid/:fid/:key",
    component: PaymentViewComponent,
  },
  // Routes For Payment End
  // Routes For Journal Vouchers Starts
  {
    path: "jlist",
    component: JournalListComponent,
  },
  {
    path: "jcreate/:bid/:fid",
    component: JournalAddComponent,
  },
  {
    path: "jedit/:bid/:jid/:fid/:key",
    component: JournalEditComponent,
  },
  {
    path: "jview/:bid/:jid/:fid/:key",
    component: JournalViewComponent,
  },
  //  Routes For Journal Vouchers End
  //  Routes For Selling Invoices Start
  {
    path: "sinvoices",
    component: SinvoiceListComponent,
  },
  {
    path: "csinvoices/:bid/:fid",
    component: SinvoiceAddComponent,
  },
  {
    path: "esinvoices/:bid/:iid/:fid/:key",
    component: SinvoiceEditComponent,
  },
  {
    path: "vsinvoices/:bid/:iid/:fid/:key",
    component: SinvoiceViewComponent,
  },

  {
    path: "list_invoice_claims",
    component: InvoiceClaimListComponent,
  },
  {
    path: "create_invoice_claims/:bid/:fid",
    component: AddInvoiceClaimComponent,
  },
  {
    path: "view_invoice_claims/:bid/:fid/:iid",
    component: InvoiceClaimViewComponent,
  },
  {
    path: "edit_invoice_claims/:bid/:fid/:iid",
    component: InvoiceClaimEditComponent,
  },
  // Routes For Selling Invoices End
  // Routes For Purchase Invoices Start
  {
    path: "pinvoices",
    component: PinvoiceListComponent,
  },
  {
    path: "cpinvoices/:bid/:fid",
    component: PinvoiceAddComponent,
  },
  {
    path: "epinvoices/:bid/:iid/:fid/:key",
    component: PinvoiceEditComponent,
  },
  {
    path: "vpinvoices/:bid/:iid/:fid/:key",
    component: PinvoiceViewComponent,
  },
  // Routes For Purchase Invoices End
  // Routes For Reversal Invoices Start
  {
    path: "list_invoice_reversal",
    component: InvoiceReversalListComponent,
  },
  {
    path: "create_invoice_reversal/:bid/:fid",
    component: InvoiceReversalAddComponent,
  },
  {
    path: "create_invoice_notes/:bid/:fid",
    component: InvoiceNotesAddComponent,
  },
  {
    path: "list_invoice_notes",
    component: InvoiceNotesListComponent,
  },
  {
    path: "view_invoice_reversal/:bid/:iid/:fid",
    component: InvoiceReversalViewComponent,
  },
  {
    path: "view_invoice_notes/:bid/:iid/:fid",
    component: InvoiceNotesViewComponent,
  },
  // {
  // 	path: "epinvoices/:bid/:iid/:fid/:key",
  // 	component: PinvoiceEditComponent,
  // },
  // {
  // 	path: "vpinvoices/:bid/:iid/:fid/:key",
  // 	component: PinvoiceViewComponent,
  // },
  // Routes For Reversal Invoices End
  // Transaction Viewer Starts
  {
    path: "tviewer",
    component: TransactionViewerComponent,
  },
  // Reports
  {
    path: "statement_of_accounts",
    component: StatementOfAccountsComponent,
  },
  {
    path: "statement_of_accounts/:account_id",
    component: StatementOfAccountsComponent,
  },
  {
    path: "trial_balance",
    component: TrialBalanceComponent,
  },
  {
    path: "sub_account_trial",
    component: SubAccountTrialComponent,
  },
  {
    path: "accounts_trial",
    component: AccountTrialComponent,
  },
  {
    path: "general_ledger",
    component: GeneralLedgerComponent,
  },
  {
    path: "balance_sheets",
    component: BalanceSheetsComponent,
  },
  {
    path: "profit_loss",
    component: ProfitLossStatementComponent,
  },
  {
    path: "income_statements",
    component: IncomeStatementComponent,
  },
  {
    path: "transaction_listing",
    component: TransactionListingComponent,
  },
  {
    path: "statement_of_costs/:account_id",
    component: StatementOfCostsComponent,
  },
  {
    path: "statement_of_costs",
    component: StatementOfCostsComponent,
  },
  {
    path: "cost_summary",
    component: CostSummaryComponent,
  },
  //opening Balance
  {
    path: "openingB",
    component: OpeningBalanceComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountsRoutingModule {}
