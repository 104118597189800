import { Injectable } from "@angular/core";
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DataService } from "@core/bootstrap/data.service";
import { Router } from "@angular/router";
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
	constructor(
		private snackbar: MatSnackBar,
		private ds: DataService,
		private router: Router
	) {}
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap((ev: HttpEvent<any>) => {
				if (ev instanceof HttpResponse) {
					if (request.method != "GET") {
						if (ev.body?.msg) {
							//this.notifier(ev.body?.msg);
						} else if (ev.body?.error) {
							//this.notifier(ev.body?.error, 'error');
						} else {
							//this.notifier('Success !!!');
						}
					}
				}
			}),
			catchError((error: HttpErrorResponse) => {
				this.errorHandler(error);
				return throwError(error);
			})
		);
	}
	private errorHandler(error: any, type: string = "error") {
		error.error.status_code = +error.error?.status_code;
		switch (error.status) {
			case 401: {
				this.notifier(error.error.error, type);
				localStorage.clear();
				this.router.navigateByUrl("/auth/login");
				break;
			}
			case 400: {
				this.notifier(error.error.error, type);
				break;
			}
			case 404: {
				this.notifier(error.error.error, type);
				break;
			}
			case 500: {
				this.notifier(error.error.error, type);
				break;
			}
			case 0: {
				this.notifier(
					"Seems there is some problem with the server. Try later!",
					type
				);
				break;
			}
		}
	}
	private notifier(error: any, type: string = "success") {
		this.snackbar.open(this.removeHTML(error), "X", {
			panelClass:
				type == "error"
					? ["alert", "alert-danger"]
					: ["alert", "alert-success"],
			duration: 3000,
		});
	}
	private removeHTML(str) {
		var tmp = document.createElement("p");
		tmp.innerHTML = str;
		return tmp.textContent || tmp.innerText || "";
	}
}
