import { Component, OnInit } from "@angular/core";
import { formatDate } from "@angular/common";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { DataService } from "@core/bootstrap/data.service";
import { Router } from "@angular/router";

@Component({
	selector: "app-ep-header",
	templateUrl: "./ep-header.component.html",
	styleUrls: ["./ep-header.component.scss"],
})
export class EpHeaderComponent implements OnInit {
	time;
	msg;
	hours;
	livedate: string;
	today = formatDate(new Date(), "yyyy/MM/dd", "en");
	languages: any = this.ds.languages;
	constructor(
		private lang: LanguagePipe,
		private ds: DataService,
		private router: Router
	) {}

	ngOnInit(): void {
		setInterval(() => {
			this.time = new Date();
		}, 1000);
		this.clock_message_decide();
		this.get_arabic_date();
	}

	clock_message_decide() {
		this.hours = new Date().getHours();
		if (this.hours < 10) {
			this.msg = this.lang.transform("lang_good_morning");
		} else if (this.hours < 16) {
			this.msg = this.lang.transform("lang_good_afternoon");
		} else if (this.hours < 19) {
			this.msg = this.lang.transform("lang_good_evening");
		} else if (this.hours < 24) {
			this.msg = this.lang.transform("lang_good_night");
		}
	}
	public get_arabic_date() {
		this.ds
			.arabicToGiorgen("EN", formatDate(new Date(), "dd/MM/yyyy", "en"))
			.subscribe((date) => {
				if (date.status) {
					const d = date.data.hijri.date.split("-");
					let arabdate = d[2] + "/" + d[1] + "/" + d[0];
					this.livedate = this.today + " - " + arabdate;
				} else {
					this.livedate = "";
				}
			});
	}

	logout() {
		localStorage.removeItem("eptoken");
		localStorage.removeItem("eploggedin");
		this.router.navigate(["external-person/login"]);
	}

	switchlanguage(lang): void {
		localStorage.setItem("lang_key", lang.lang_key);
		if (localStorage.getItem("direction") != lang.lang_dir) {
			localStorage.setItem("direction", lang.lang_dir);
			window.location.reload();
		}
	}
}
