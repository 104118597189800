import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-formect1-view-asdpf",
  templateUrl: "./formect1-view-asdpf.component.html",
  styleUrls: ["./formect1-view-asdpf.component.scss"],
})
export class Formect1ViewAsdpfComponent implements OnInit {
  @Input() formCt1Data;
  @Input() allData;
  lang_key = localStorage.getItem("lang_key") || "en";
  constructor() {}

  ngOnInit(): void {}
}
