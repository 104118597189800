<h3 mat-dialog-title class="d-flex justify-content-between align-items-center">
	<span>{{ "lang_advanced_filter" | language }}</span>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</h3>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-12 mt-1">
			<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
				<mat-form-field appearance="outline" style="width: 100%">
					<mat-label
						>{{ "lang_ticket" | language }}
						{{ "lang_number" | language }}</mat-label
					>
					<input
						matInput
						placeholder="{{ 'lang_ticket' | language }} {{
							'lang_number' | language
						}}"
						[(ngModel)]="ticketID"
						autocomplete="off"
						(keypress)="isNumber($event)"
					/>
					<mat-icon matSuffix>theaters</mat-icon>
				</mat-form-field>
			</div>
		</div>

		<div class="col-12 mt-1">
			<ng-select
				[items]="user_list"
				[multiple]="true"
				appendTo="body"
				bindLabel="label"
				bindValue="value"
				[searchFn]="ds.customSearchFn"
				clearAllText="Clear"
				placeholder="{{ 'lang_users' | language }}"
				[searchable]="true"
				[(ngModel)]="user_filter"
				id="assigned_user"
				name="assigned_user"
				(search)="search_users($event)"
			>
				<ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
					<div class="ng-value" *ngFor="let item of items">
						<span class="ng-value-label">
							<img
								[src]="item.userAvatar"
								width="20px"
								height="20px"
								*ngIf="item.userAvatar"
							/>
							<img
								src="assets/images/avatar.png"
								width="20px"
								height="20px"
								*ngIf="!item.userAvatar"
							/>
							<span style="font-size: 11px; margin: 0 1px">{{
								item.label
							}}</span>
						</span>
						<span
							class="ng-value-icon right"
							(click)="clear(item)"
							aria-hidden="true"
							>×</span
						>
					</div>
				</ng-template>
				<ng-template
					ng-option-tmp
					let-item="item"
					let-index="index"
					let-search="searchTerm"
				>
					<div
						style="
							display: flex;
							justify-content: space-between;
							align-items: center;
							width: 100%;
						"
					>
						<img
							[src]="item.userAvatar"
							width="20px"
							height="20px"
							*ngIf="item.userAvatar"
						/>
						<img
							src="assets/images/avatar.png"
							width="20px"
							height="20px"
							*ngIf="!item.userAvatar"
						/>
						<span>{{ item?.label }}</span>
					</div>
				</ng-template>
			</ng-select>
		</div>
		<div class="col-12 mt-1">
			<ng-select
				[items]="status_list"
				[multiple]="false"
				appendTo="body"
				bindLabel="name"
				bindValue="id"
				clearAllText="Clear"
				[searchable]="false"
				placeholder="{{ 'lang_status' | language }}"
				[(ngModel)]="status_filter"
				class="mt-1"
			>
			</ng-select>
		</div>
		<div class="col-12 mt-1">
			<ng-select
				[items]="priority_list"
				[multiple]="false"
				appendTo="body"
				bindLabel="name"
				bindValue="id"
				clearAllText="Clear"
				[searchable]="false"
				placeholder="{{ 'lang_priority' | language }}"
				[(ngModel)]="priority_filter"
				class="mt-1"
			>
			</ng-select>
		</div>

		<div class="col-12 mt-1">
			<ng-select
				[items]="type_list"
				[multiple]="false"
				appendTo="body"
				bindLabel="name"
				bindValue="id"
				clearAllText="Clear"
				[searchable]="false"
				placeholder="{{ 'lang_type' | language }}"
				[(ngModel)]="type_filter"
				class="mt-1"
			>
			</ng-select>
		</div>
		<div class="col-12 mt-1">
			<ng-select
				[items]="signature_list"
				[multiple]="false"
				appendTo="body"
				bindLabel="name"
				bindValue="id"
				clearAllText="Clear"
				[searchable]="false"
				placeholder="{{ 'lang_signature' | language }}"
				[(ngModel)]="signatue_filter"
				class="mt-1"
			>
			</ng-select>
		</div>
		<div class="col-12 mt-1" *ngIf="admin">
			<ng-select
				[items]="filteradmin_list"
				[multiple]="false"
				appendTo="body"
				bindLabel="name"
				bindValue="id"
				[clearable]="false"
				[searchable]="false"
				[(ngModel)]="filteradmin"
				class="mt-1"
			>
			</ng-select>
		</div>
		<div class="col-12 mt-1">
			<ng-select
				[items]="module_list"
				[multiple]="false"
				appendTo="body"
				bindLabel="module_key"
				bindValue="module_key"
				clearAllText="Clear"
				[searchable]="false"
				placeholder="{{ 'lang_module_name' | language }}"
				[(ngModel)]="module_filter"
				class="mt-1"
			>
			</ng-select>
		</div>
		<div class="col-12 mt-1">
			<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
				<mat-form-field appearance="outline" style="width: 100%">
					<mat-date-range-input [formGroup]="range" [rangePicker]="picker">
						<input
							matStartDate
							formControlName="start"
							readonly
							placeholder="{{ 'lang_start_date' | language }}"
						/>
						<input
							matEndDate
							formControlName="end"
							readonly
							placeholder="{{ 'lang_end_date' | language }}"
						/>
					</mat-date-range-input>
					<mat-datepicker-toggle
						matSuffix
						[for]="picker"
					></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="col-12 mt-1">
			<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
				<mat-form-field appearance="outline" style="width: 100%">
					<mat-label>{{ "lang_link_issue" | language }}</mat-label>
					<input
						matInput
						placeholder="Link Issue"
						[(ngModel)]="link_issue"
						autocomplete="off"
					/>
				</mat-form-field>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="d-flex justify-content-between" style="width: 100%">
		<div style="width: 100px">
			<button type="button" class="albutton" (click)="resetFilterForm()">
				{{ "lang_reset" | language }}
			</button>
		</div>

		<div style="width: 100px">
			<button
				mat-button
				class="albutton mnabrbutton mat-accent"
				(click)="sendFilterForm()"
			>
				{{ "lang_save" | language }}
			</button>
		</div>
	</div>
</mat-dialog-actions>
