<nav aria-label="breadcrumb" class="breadcrumbmnabr">
	<ol class="matero-breadcrumb">
		<li
			class="matero-breadcrumb-item"
			*ngFor="let navlink of nav; trackBy: trackByNavlink; first as isFirst"
		>
			<span class="link" *ngIf="isFirst">
				<a routerLink="/">{{ navlink | language }}</a>
			</span>
			<ng-container *ngIf="!isFirst">
				<mat-icon class="chevron">{{
					lang_key == "en" ? "chevron_right" : "chevron_left"
				}}</mat-icon>
				<span class="link">{{ navlink | language }}</span>
			</ng-container>
		</li>
	</ol>
</nav>
