<div class="registered_sub_contractors">
	<div class="row">
		<div class="col-12 mb-10">
			<div class="input-group input-group-sm">
				<input
					type="text"
					class="form-control almnabrformcontrol h34"
					(keyup)="search_sub_contractors()"
					[(ngModel)]="searchKey"
					placeholder="{{ 'lang_search' | language }}"
				/>
				<span class="input-group-btn" *ngIf="makeAction">
					<button
						id="btnSearch"
						type="button"
						(click)="empty_form(); openModal(addcontractors)"
						matTooltip="{{ 'lang_add_contractor' | language }}"
						class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
					></button>
				</span>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_contractor_name" | language }}</td>
							<td>{{ "lang_email_address" | language }}</td>
							<td>{{ "lang_mobile_number" | language }}</td>
							<td>{{ "lang_vat_tax" | language }}</td>
							<td>{{ "lang_ondate" | language }}</td>
							<td>{{ "lang_writer" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody *ngIf="subData?.length != '0'" class="AppendList">
						<tr
							*ngFor="
								let user of subData?.records;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
							class="makeback removerow{{ user.contractor_group_id }}"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ user.contractor_name }}</td>
							<td>{{ user.contractor_email_address }}</td>
							<td>{{ user.contractor_mobile }}</td>
							<td>{{ user.contractor_tax_vat }}</td>
							<td>{{ user.contractor_createddatetime }}</td>
							<td>{{ user.writer }}</td>
							<td>
								<i
									class="fa fa-users mnabricon s2icon"
									(click)="
										viewcontractors(user); openModalU(contractusersmodel)
									"
									matTooltip="{{ 'lang_users' | language }}"
								></i>
								<i
									class="fa fa-trash s2icon red"
									*ngIf="makeAction"
									(click)="
										remove_contractor(user.contractor_group_contractor_id)
									"
									matTooltip="{{ 'lang_delete' | language }}"
								></i>
							</td>
						</tr>
					</tbody>
					<tbody *ngIf="subData?.length == '0'" class="AppendList">
						<tr class="odd">
							<td colspan="8" class="nodata" align="center">{{ nodata }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div *ngIf="subData?.records?.length != '0'" class="card-footer padb0">
		<div class="row responsive_pagination">
			<div
				class="col-lg-3 col-sm-3 mb-10"
				style="height: fit-content; margin: auto 0"
			>
				{{ "lang_total" | language }} : {{ subData?.page?.total_records }}
			</div>
			<div
				class="col-lg-6 col-sm-6 mb-10"
				style="display: flex; justify-content: center"
			>
				<ngb-pagination
					class="m-auto"
					[collectionSize]="subData?.page?.total_records"
					[rotate]="true"
					[ellipses]="false"
					[maxSize]="3"
					[boundaryLinks]="true"
					[(page)]="pageno"
					[pageSize]="pagesize"
					(pageChange)="page_load($event)"
				></ngb-pagination>
			</div>
			<div
				class="col-lg-3 col-sm-3"
				style="display: flex; justify-content: end; margin: auto"
			>
				<mat-select
					style="max-width: 150px"
					[(ngModel)]="pagesize"
					(selectionChange)="page_size($event.value)"
				>
					<mat-option class="text-center" value="10"
						>10 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="20"
						>20 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="50"
						>50 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="100"
						>100 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option class="text-center" value="500"
						>500 {{ "lang_per_page" | language }}</mat-option
					>
				</mat-select>
			</div>
		</div>
	</div>
</div>
<ng-template #addcontractors>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_add_contractor" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div class="modal-body">
				<div class="form-group">
					<label for="contractor_id"
						>{{ "lang_contractors" | language }} :
						<span class="red">*</span></label
					>
					<ng-select
						[items]="contractors"
						[multiple]="true"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						appendTo="body"
						formControlName="sub_contractors"
						placeholder="{{ 'lang_contractors' | language }}"
					>
					</ng-select>
					<mat-error
						*ngIf="
							form.controls['sub_contractors'].touched &&
							form.controls['sub_contractors'].errors?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #contractusersmodel class="contractusersmodel">
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">
				{{ "lang_contractor_users" | language }} : ({{
					contractordata.contractor_name
				}})
			</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefU.hide()"
			>
				&times;
			</button>
		</div>
		<div class="modal-body">
			<div *ngIf="datastatus">
				<app-contractor-users
					[supervision_data]="supervision_data"
					[contractortype]="contractortype"
					[able2add]="able2add"
					[able2edit]="able2edit"
					[project_data]="project_data"
					[contractor_id]="contractordata.contractor_group_contractor_id"
				></app-contractor-users>
			</div>
		</div>
	</div>
</ng-template>
