<br />
<div class="themesection panel-expansion">
  <div class="container {{ mainhideclass }}">
    <div class="row">
      <div
        class="col-lg-6 col-md-6 col-sm-12 col-xs-12 {{ employeehideclass }}"
        id="removableEmployeeDiv"
      >
        <mat-accordion class="appattachments">
          <mat-expansion-panel class="mpanel" [expanded]="true">
            <mat-expansion-panel-header class="mpanel-header">{{
              "lang_human_resources_emp_details" | language
            }}</mat-expansion-panel-header>
            <div class="material-panel-body">
              <div class="mbrl15 martb0">
                <form [formGroup]="employeeform" class="employeeform">
                  <mat-horizontal-stepper #stepper linear class="pad0">
                    <mat-step>
                      <ng-template matStepLabel>
                        <div class="step">
                          <div class="st">
                            <i class="icon-5x fa fa-user fa-icon"></i>
                          </div>
                        </div>
                        <div>
                          {{ "lang_human_resources_id_details" | language }}
                        </div>
                      </ng-template>
                      <div class="iddetails">
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="personal_id_number"
                                >{{
                                  "lang_human_resources_fname_english"
                                    | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="firstname_english"
                                autocomplete="off"
                                type="text"
                                placeholder="{{
                                  'lang_human_resources_fname_english'
                                    | language
                                }}"
                                class="form-control almnabrformcontrol"
                                (keypress)="
                                  ds.preventSpaces($event, 'keypress')
                                "
                                (paste)="ds.preventSpaces($event, 'paste')"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['firstname_english']
                                    .touched &&
                                  employeeform.controls['firstname_english']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="secondname_english"
                                >{{
                                  "lang_human_resources_sname_english"
                                    | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="secondname_english"
                                required
                                id="secondname_english"
                                type="text"
                                class="form-control almnabrformcontrol secondname_english"
                                placeholder="{{
                                  'lang_human_resources_sname_english'
                                    | language
                                }}"
                                (keypress)="
                                  ds.preventSpaces($event, 'keypress')
                                "
                                (paste)="ds.preventSpaces($event, 'paste')"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['secondname_english']
                                    .touched &&
                                  employeeform.controls['secondname_english']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="thirdname_english"
                                >{{
                                  "lang_human_resources_tname_english"
                                    | language
                                }}
                                :
                              </label>
                              <input
                                formControlName="thirdname_english"
                                id="thirdname_english"
                                type="text"
                                class="form-control almnabrformcontrol thirdname_english"
                                placeholder="{{
                                  'lang_human_resources_tname_english'
                                    | language
                                }}"
                                (keypress)="
                                  ds.preventSpaces($event, 'keypress')
                                "
                                (paste)="ds.preventSpaces($event, 'paste')"
                              />
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="lastname_english"
                                >{{
                                  "lang_human_resources_lname_english"
                                    | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="lastname_english"
                                required
                                type="text"
                                class="form-control almnabrformcontrol"
                                placeholder="{{
                                  'lang_human_resources_lname_english'
                                    | language
                                }}"
                                (keypress)="
                                  ds.preventSpaces($event, 'keypress')
                                "
                                (paste)="ds.preventSpaces($event, 'paste')"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['lastname_english']
                                    .touched &&
                                  employeeform.controls['lastname_english']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="firstname_arabic"
                                >{{
                                  "lang_human_resources_fname_arabic" | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="firstname_arabic"
                                required
                                id="firstname_arabic"
                                type="text"
                                class="form-control almnabrformcontrol firstname_arabic"
                                placeholder="{{
                                  'lang_human_resources_fname_arabic' | language
                                }}"
                                (keypress)="
                                  ds.preventSpaces($event, 'keypress')
                                "
                                (paste)="ds.preventSpaces($event, 'paste')"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['firstname_arabic']
                                    .touched &&
                                  employeeform.controls['firstname_arabic']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="secondname_arabic"
                                >{{
                                  "lang_human_resources_sname_arabic" | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="secondname_arabic"
                                required
                                id="secondname_arabic"
                                type="text"
                                class="form-control almnabrformcontrol secondname_arabic"
                                placeholder="{{
                                  'lang_human_resources_sname_arabic' | language
                                }}"
                                (keypress)="
                                  ds.preventSpaces($event, 'keypress')
                                "
                                (paste)="ds.preventSpaces($event, 'paste')"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['secondname_arabic']
                                    .touched &&
                                  employeeform.controls['secondname_arabic']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="thirdname_arabic"
                                >{{
                                  "lang_human_resources_tname_arabic" | language
                                }}
                                :
                              </label>
                              <input
                                formControlName="thirdname_arabic"
                                id="thirdname_arabic"
                                type="text"
                                class="form-control almnabrformcontrol thirdname_arabic"
                                placeholder="{{
                                  'lang_human_resources_tname_arabic' | language
                                }}"
                                (keypress)="
                                  ds.preventSpaces($event, 'keypress')
                                "
                                (paste)="ds.preventSpaces($event, 'paste')"
                              />
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="lastname_arabic"
                                >{{
                                  "lang_human_resources_lname_arabic" | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="lastname_arabic"
                                required
                                id="lastname_arabic"
                                type="text"
                                class="form-control almnabrformcontrol lastname_arabic"
                                placeholder="{{
                                  'lang_human_resources_lname_arabic' | language
                                }}"
                                (keypress)="
                                  ds.preventSpaces($event, 'keypress')
                                "
                                (paste)="ds.preventSpaces($event, 'paste')"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['lastname_arabic']
                                    .touched &&
                                  employeeform.controls['lastname_arabic']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="iqama_expiry_date_english"
                                >{{
                                  "lang_human_resources_id_expiry_data_english"
                                    | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <div class="positionrelatiove">
                                <input
                                  formControlName="iqama_expiry_date_english"
                                  id="iqama_expiry_date_english"
                                  class="form-control almnabrformcontrol iqama_expiry_date_english"
                                  (dateChange)="
                                    ds.setENTOARDate(
                                      $event,
                                      employeeform.get(
                                        'iqama_expiry_date_arabic'
                                      )
                                    )
                                  "
                                  [readonly]="true"
                                  [matDatepicker]="IDeng"
                                  placeholder="YYYY/MM/DD"
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="IDeng"
                                  class="posiab"
                                >
                                </mat-datepicker-toggle>
                                <mat-datepicker
                                  #IDeng
                                  disabled="false"
                                ></mat-datepicker>
                                <mat-error
                                  *ngIf="
                                    employeeform.controls[
                                      'iqama_expiry_date_english'
                                    ].touched &&
                                    employeeform.controls[
                                      'iqama_expiry_date_english'
                                    ].errors?.required
                                  "
                                >
                                  {{ "lang_field_required" | language }}
                                </mat-error>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="iqama_expiry_date_arabic"
                                >{{
                                  "lang_human_resources_id_expiry_data_arabic"
                                    | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="iqama_expiry_date_arabic"
                                ngbDatepicker
                                [readonly]="true"
                                (click)="idarab.toggle()"
                                #idarab="ngbDatepicker"
                                required
                                id="iqama_expiry_date_arabic"
                                type="text"
                                class="form-control almnabrformcontrol iqama_expiry_date_arabic"
                                placeholder="YYYY/MM/DD"
                                (ngModelChange)="
                                  ds.setARTOENDate(
                                    $event,
                                    employeeform.get(
                                      'iqama_expiry_date_english'
                                    )
                                  )
                                "
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls[
                                    'iqama_expiry_date_arabic'
                                  ].touched &&
                                  employeeform.controls[
                                    'iqama_expiry_date_arabic'
                                  ].errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="birth_date_english"
                                >{{
                                  "lang_human_resources_birth_date_english"
                                    | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <div class="positionrelatiove">
                                <input
                                  formControlName="birth_date_english"
                                  required
                                  id="birth_date_english"
                                  type="text"
                                  class="form-control almnabrformcontrol birth_date_english"
                                  (dateChange)="
                                    ds.setENTOARDate(
                                      $event,
                                      employeeform.get('birth_date_arabic')
                                    )
                                  "
                                  [readonly]="true"
                                  placeholder="YYYY/MM/DD"
                                  [matDatepicker]="BDeng"
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="BDeng"
                                  class="posiab"
                                ></mat-datepicker-toggle>
                                <mat-datepicker
                                  #BDeng
                                  disabled="false"
                                ></mat-datepicker>
                                <mat-error
                                  *ngIf="
                                    employeeform.controls['birth_date_english']
                                      .touched &&
                                    employeeform.controls['birth_date_english']
                                      .errors?.required
                                  "
                                >
                                  {{ "lang_field_required" | language }}
                                </mat-error>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="birth_date_arabic"
                                >{{
                                  "lang_human_resources_birth_date_arabic"
                                    | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="birth_date_arabic"
                                (ngModelChange)="
                                  ds.setARTOENDate(
                                    $event,
                                    employeeform.get('birth_date_english')
                                  )
                                "
                                ngbDatepicker
                                [readonly]="true"
                                (click)="bdarab.toggle()"
                                #bdarab="ngbDatepicker"
                                required
                                id="birth_date_arabic"
                                type="text"
                                class="form-control almnabrformcontrol birth_date_arabic"
                                placeholder="YYYY/MM/DD"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['birth_date_arabic']
                                    .touched &&
                                  employeeform.controls['birth_date_arabic']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="copy_number"
                                >{{
                                  "lang_human_resources_copy_number" | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="copy_number"
                                required
                                id="copy_number"
                                type="text"
                                class="form-control almnabrformcontrol copy_number"
                                placeholder="{{
                                  'lang_human_resources_copy_number' | language
                                }}"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['copy_number']
                                    .touched &&
                                  employeeform.controls['copy_number'].errors
                                    ?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="gender"
                                >{{
                                  "lang_human_resources_gender" | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <select
                                formControlName="gender"
                                required
                                id="gender"
                                type="text"
                                class="form-control almnabrformcontrol gender"
                                matTooltip="{{
                                  'lang_human_resources_gender' | language
                                }}"
                              >
                                <option value="">
                                  {{ "lang_choose_options" | language }}
                                </option>
                                <option value="M">
                                  {{ "lang_human_resources_male" | language }}
                                </option>
                                <option value="F">
                                  {{ "lang_human_resources_female" | language }}
                                </option>
                              </select>
                              <mat-error
                                *ngIf="
                                  employeeform.controls['gender'].touched &&
                                  employeeform.controls['gender'].errors
                                    ?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label
                                >{{ "lang_marital_status" | language }} :
                                <span class="red">*</span></label
                              >
                              <select
                                formControlName="marital_status"
                                type="text"
                                matTooltip="{{
                                  'lang_marital_status' | language
                                }}"
                                class="form-control almnabrformcontrol"
                              >
                                <option value="">
                                  {{ "lang_choose_options" | language }}
                                </option>
                                <option value="S">
                                  {{ "lang_single" | language }}
                                </option>
                                <option value="M">
                                  {{ "lang_married" | language }}
                                </option>
                                <option value="D">
                                  {{ "lang_diversed" | language }}
                                </option>
                                <option value="O">
                                  {{ "lang_others" | language }}
                                </option>
                              </select>
                              <mat-error
                                *ngIf="
                                  employeeform.controls['marital_status']
                                    .touched &&
                                  employeeform.controls['marital_status'].errors
                                    ?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label
                                >{{ "lang_religion" | language }} :
                                <span class="red">*</span></label
                              >
                              <input
                                formControlName="religion"
                                type="text"
                                placeholder="{{ 'lang_religion' | language }}"
                                class="form-control almnabrformcontrol"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['religion'].touched &&
                                  employeeform.controls['religion'].errors
                                    ?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="nationality"
                                >{{
                                  "lang_human_resources_nationality" | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <ng-select
                                [items]="nationality"
                                [multiple]="false"
                                bindLabel="label"
                                bindValue="value"
                                appendTo="body"
                                clearAllText="Clear"
                                placeholder="{{
                                  'lang_human_resources_nationality' | language
                                }}"
                                formControlName="nationality"
                              >
                                <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-search="searchTerm"
                                  let-index="index"
                                >
                                  <span
                                    class="widthunset"
                                    style="color: #333; min-width: 150px"
                                    >{{ item.label }}</span
                                  >
                                  <img
                                    *ngIf="item.flag"
                                    src="{{ apiurl }}{{ item.flag }}"
                                    class="searchingImage"
                                  />
                                </ng-template>
                              </ng-select>
                              <mat-error
                                *ngIf="
                                  employeeform.controls['nationality']
                                    .touched &&
                                  employeeform.controls['nationality'].errors
                                    ?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="job_title_iqama"
                                >{{
                                  "lang_human_resources_job_title_iqama"
                                    | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="job_title_iqama"
                                required
                                id="job_title_iqama"
                                type="text"
                                class="form-control almnabrformcontrol job_title_iqama"
                                placeholder="{{
                                  'lang_human_resources_job_title_iqama'
                                    | language
                                }}"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['job_title_iqama']
                                    .touched &&
                                  employeeform.controls['job_title_iqama']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="employee_title"
                                >{{
                                  "lang_human_resources_emptitle" | language
                                }}
                                :
                              </label>
                              <select
                                formControlName="employee_title"
                                id="employee_title"
                                type="text"
                                matTooltip="{{
                                  'lang_human_resources_emptitle' | language
                                }}"
                                class="form-control almnabrformcontrol employee_title"
                              >
                                <option value="">
                                  {{ "lang_choose_options" | language }}
                                </option>
                                <option
                                  *ngFor="let title of etitles"
                                  value="{{ title.value }}"
                                >
                                  {{ title.label }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 mb-10">
                            <button mat-button class="albutton" matStepperNext>
                              {{ "lang_next" | language }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </mat-step>
                    <mat-step>
                      <ng-template matStepLabel>
                        <div class="step">
                          <div class="st">
                            <i class="icon-5x fa fa-suitcase fa-icon"></i>
                          </div>
                        </div>
                        <div>{{ "lang_other_details" | language }}</div>
                      </ng-template>
                      <div class="otherdetails">
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="primary_address"
                                >{{
                                  "lang_human_resources_primary_address"
                                    | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="primary_address"
                                id="primary_address"
                                type="text"
                                class="form-control almnabrformcontrol primary_address"
                                placeholder="{{
                                  'lang_human_resources_primary_address'
                                    | language
                                }}"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['primary_address']
                                    .touched &&
                                  employeeform.controls['primary_address']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="account_number"
                                >{{
                                  "lang_human_resources_account_number"
                                    | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="account_number"
                                minlength="10"
                                maxlength="35"
                                id="account_number"
                                type="text"
                                placeholder="{{
                                  'lang_human_resources_account_number'
                                    | language
                                }}"
                                class="form-control almnabrformcontrol account_number"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform['controls']['account_number']
                                    .touched &&
                                  employeeform['controls']['account_number']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                              <mat-error
                                *ngIf="
                                  employeeform['controls']['account_number']
                                    .errors?.minlength
                                "
                              >
                                {{ "lang_minimum" | language }} 10
                              </mat-error>
                              <mat-error
                                *ngIf="
                                  employeeform['controls']['account_number']
                                    .errors?.maxlength
                                "
                              >
                                {{ "lang_maximum" | language }} 35
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="bank_short_code"
                                >{{ "lang_bank" | language }} :
                                <span class="red">*</span></label
                              >
                              <ng-select
                                [items]="banklists"
                                [multiple]="false"
                                bindLabel="label"
                                bindValue="value"
                                appendTo="body"
                                clearAllText="Clear"
                                placeholder="{{ 'lang_bank' | language }}"
                                formControlName="bank_short_code"
                              >
                              </ng-select>
                              <mat-error
                                *ngIf="
                                  employeeform.controls['bank_short_code']
                                    .touched &&
                                  employeeform.controls['bank_short_code']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="primary_education_level"
                                >{{
                                  "lang_human_resources_graduation" | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <select
                                formControlName="primary_education_level"
                                id="primary_education_level"
                                type="text"
                                matTooltip="{{
                                  'lang_human_resources_graduation' | language
                                }}"
                                class="form-control almnabrformcontrol primary_education_level"
                              >
                                <option value="SL">
                                  {{
                                    "lang_human_resources_belowsslc" | language
                                  }}
                                </option>
                                <option value="SS">
                                  {{ "lang_human_resources_sslc" | language }}
                                </option>
                                <option value="HS">
                                  {{ "lang_human_resources_hsc" | language }}
                                </option>
                                <option value="DP">
                                  {{
                                    "lang_human_resources_diploma" | language
                                  }}
                                </option>
                                <option value="UG">
                                  {{
                                    "lang_human_resources_bachelor" | language
                                  }}
                                </option>
                                <option value="PG">
                                  {{ "lang_human_resources_pg" | language }}
                                </option>
                                <option value="DC">
                                  {{ "lang_human_resources_doc" | language }}
                                </option>
                              </select>
                              <mat-error
                                *ngIf="
                                  employeeform['controls'][
                                    'primary_education_level'
                                  ].touched &&
                                  employeeform['controls'][
                                    'primary_education_level'
                                  ].errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="primary_graduation_year"
                                >{{
                                  "lang_human_resources_graduation_year"
                                    | language
                                }}
                                : <span class="red">*</span></label
                              >
                              <input
                                formControlName="primary_graduation_year"
                                minlength="4"
                                maxlength="4"
                                id="primary_graduation_year"
                                type="text"
                                placeholder="{{
                                  'lang_human_resources_graduation_year'
                                    | language
                                }}"
                                class="form-control almnabrformcontrol primary_graduation_year"
                                (keypress)="ds.numberOnly($event)"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls[
                                    'primary_graduation_year'
                                  ].touched &&
                                  employeeform.controls[
                                    'primary_graduation_year'
                                  ].errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                              <mat-error
                                *ngIf="
                                  employeeform.controls[
                                    'primary_graduation_year'
                                  ].errors?.minlength
                                "
                              >
                                {{ "lang_minimum" | language }} 4
                              </mat-error>
                              <mat-error
                                *ngIf="
                                  employeeform.controls[
                                    'primary_graduation_year'
                                  ].errors?.maxlength
                                "
                              >
                                {{ "lang_maximum" | language }} 4
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="passport_number"
                                >{{
                                  "lang_human_resources_passport_number"
                                    | language
                                }}
                                :
                              </label>
                              <input
                                formControlName="passport_number"
                                id="passport_number"
                                type="text"
                                placeholder="{{
                                  'lang_human_resources_passport_number'
                                    | language
                                }}"
                                class="form-control almnabrformcontrol passport_number"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform['controls']['passport_number']
                                    .touched &&
                                  employeeform['controls']['passport_number']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="passport_issue_date_english"
                                >{{
                                  "lang_human_resources_passport_issue_date_english"
                                    | language
                                }}
                                :</label
                              >
                              <div class="input-group input-group-sm">
                                <input
                                  [matDatepicker]="passport_issue_date_english"
                                  formControlName="passport_issue_date_english"
                                  class="form-control almnabrformcontrol"
                                  placeholder="YYYY/MM/DD"
                                  readonly
                                  (dateChange)="
                                    ds.setENTOARDate(
                                      $event,
                                      employeeform.get(
                                        'passport_issue_date_arabic'
                                      )
                                    )
                                  "
                                />
                                <span class="input-group-btn">
                                  <mat-datepicker-toggle
                                    class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                                    matSuffix
                                    [for]="passport_issue_date_english"
                                  ></mat-datepicker-toggle>
                                  <mat-datepicker
                                    #passport_issue_date_english
                                  ></mat-datepicker>
                                </span>
                                <span class="input-group-btn">
                                  <button
                                    (click)="
                                      employeeform
                                        .get('passport_issue_date_english')
                                        .setValue('');
                                      employeeform
                                        .get('passport_issue_date_arabic')
                                        .setValue(null)
                                    "
                                    matTooltip="{{ 'lang_empty' | language }}"
                                    type="button"
                                    class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
                                  ></button>
                                </span>
                              </div>
                              <mat-error
                                *ngIf="
                                  employeeform.controls[
                                    'passport_issue_date_english'
                                  ].touched &&
                                  employeeform.controls[
                                    'passport_issue_date_english'
                                  ].errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="passport_issue_date_arabic"
                                >{{
                                  "lang_human_resources_passport_issue_date_arabic"
                                    | language
                                }}
                                :</label
                              >
                              <input
                                formControlName="passport_issue_date_arabic"
                                [readonly]="true"
                                id="passport_issue_date_arabic"
                                type="text"
                                placeholder="YYYY/MM/DD"
                                ngbDatepicker
                                (click)="ppia.toggle()"
                                #ppia="ngbDatepicker"
                                (ngModelChange)="
                                  ds.setARTOENDate(
                                    $event,
                                    employeeform.get(
                                      'passport_issue_date_english'
                                    )
                                  )
                                "
                                class="form-control almnabrformcontrol passport_issue_date_arabic"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform['controls'][
                                    'passport_issue_date_arabic'
                                  ].touched &&
                                  employeeform['controls'][
                                    'passport_issue_date_arabic'
                                  ].errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="passport_expiry_date_english"
                                >{{
                                  "lang_human_resources_passport_expiry_date_english"
                                    | language
                                }}
                                :</label
                              >
                              <div class="input-group input-group-sm">
                                <input
                                  [matDatepicker]="passport_expiry_date_english"
                                  formControlName="passport_expiry_date_english"
                                  class="form-control almnabrformcontrol"
                                  placeholder="YYYY/MM/DD"
                                  readonly
                                  (dateChange)="
                                    ds.setENTOARDate(
                                      $event,
                                      employeeform.get(
                                        'passport_expiry_date_arabic'
                                      )
                                    )
                                  "
                                />
                                <span class="input-group-btn">
                                  <mat-datepicker-toggle
                                    class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                                    matSuffix
                                    [for]="passport_expiry_date_english"
                                  ></mat-datepicker-toggle>
                                  <mat-datepicker
                                    #passport_expiry_date_english
                                  ></mat-datepicker>
                                </span>
                                <span class="input-group-btn">
                                  <button
                                    (click)="
                                      employeeform
                                        .get('passport_expiry_date_english')
                                        .setValue('');
                                      employeeform
                                        .get('passport_expiry_date_arabic')
                                        .setValue(null)
                                    "
                                    matTooltip="{{ 'lang_empty' | language }}"
                                    type="button"
                                    class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
                                  ></button>
                                </span>
                              </div>
                              <mat-error
                                *ngIf="
                                  employeeform.controls[
                                    'passport_expiry_date_english'
                                  ].touched &&
                                  employeeform.controls[
                                    'passport_expiry_date_english'
                                  ].errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
                            <div class="form-group">
                              <label for="passport_expiry_date_arabic"
                                >{{
                                  "lang_human_resources_passport_expiry_date_arabic"
                                    | language
                                }}
                                :</label
                              >
                              <input
                                formControlName="passport_expiry_date_arabic"
                                id="passport_expiry_date_arabic"
                                type="text"
                                placeholder="YYYY/MM/DD"
                                class="form-control almnabrformcontrol passport_expiry_date_arabic"
                                ngbDatepicker
                                (click)="ppea.toggle()"
                                #ppea="ngbDatepicker"
                                (ngModelChange)="
                                  ds.setARTOENDate(
                                    $event,
                                    employeeform.get(
                                      'passport_expiry_date_english'
                                    )
                                  )
                                "
                              />
                              <mat-error
                                *ngIf="
                                  employeeform['controls'][
                                    'passport_issue_date_arabic'
                                  ].touched &&
                                  employeeform['controls'][
                                    'passport_issue_date_arabic'
                                  ].errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 mb-10">
                            <div class="form-group">
                              <label for="passport_issue_place"
                                >{{
                                  "lang_human_resources_passport_issue_place"
                                    | language
                                }}
                                :</label
                              >
                              <input
                                formControlName="passport_issue_place"
                                id="passport_issue_place"
                                type="text"
                                placeholder="{{
                                  'lang_human_resources_passport_issue_place'
                                    | language
                                }}"
                                class="form-control almnabrformcontrol passport_issue_place"
                              />
                              <mat-error
                                *ngIf="
                                  employeeform.controls['passport_issue_place']
                                    .touched &&
                                  employeeform.controls['passport_issue_place']
                                    .errors?.required
                                "
                              >
                                {{ "lang_field_required" | language }}
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 mb-10">
                            <button
                              mat-button
                              class="albutton"
                              matStepperPrevious
                            >
                              {{ "lang_previous" | language }}
                            </button>
                          </div>
                          <div class="col-6 mb-10">
                            <button mat-button class="albutton" matStepperNext>
                              {{ "lang_next" | language }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </mat-step>
                    <mat-step>
                      <ng-template matStepLabel>
                        <div class="step">
                          <div class="st">
                            <mat-icon class="mnabricon">gamepad</mat-icon>
                          </div>
                        </div>
                        <div>{{ "lang_signature_details" | language }}</div>
                      </ng-template>
                      <div class="signaturedetails">
                        <div class="row">
                          <div class="col-12 mb-10">
                            <div class="form-group">
                              <label for="signature"
                                >{{ "lang_signature" | language }} :
                                <span class="red">*</span></label
                              >
                              <div class="sig-container" #padElement>
                                <canvas
                                  #sPad
                                  width="{{ width }}"
                                  height="{{ height }}"
                                ></canvas>
                              </div>
                              <br />
                              <div fxLayout="row">
                                <div class="col-md-3 col-xs-12 mb-10 pad0">
                                  <button
                                    type="button"
                                    class="albutton"
                                    (click)="signaturePad.clear()"
                                  >
                                    {{ "lang_clear" | language }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 mb-10">
                            <div class="form-group">
                              <label for="mark"
                                >{{ "lang_mark" | language }} :
                                <span class="red">*</span></label
                              >
                              <div class="sig-container" #padElement>
                                <canvas
                                  #mPad
                                  width="{{ width }}"
                                  height="{{ height }}"
                                ></canvas>
                              </div>
                              <br />
                              <div fxLayout="row">
                                <div class="col-md-3 col-xs-12 mb-10 pad0">
                                  <button
                                    type="button"
                                    class="albutton"
                                    (click)="markPad.clear()"
                                  >
                                    {{ "lang_clear" | language }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 mb-10">
                            <button
                              mat-button
                              class="albutton"
                              matStepperPrevious
                            >
                              {{ "lang_previous" | language }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </mat-step>
                  </mat-horizontal-stepper>
                </form>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div
        class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
        [ngClass]="{ mar0auto: tokendata.user_type_id != '1' }"
      >
        <mat-accordion>
          <mat-expansion-panel class="mpanel" [expanded]="true">
            <mat-expansion-panel-header class="mpanel-header">{{
              "lang_human_resources_registration_details" | language
            }}</mat-expansion-panel-header>
            <div class="material-panel-body">
              <div class="mbrl15 martb0">
                <form
                  [formGroup]="registrationform"
                  class="registrationform"
                  (ngSubmit)="submit_registration()"
                >
                  <div class="row">
                    <div class="form-group col-6">
                      <label>{{ "lang_name" | language }} :</label>
                    </div>
                    <div class="form-group col-6">
                      <label
                        ><b>{{ tokendata.user_registration_name }}</b></label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-6">
                      <label>{{ "lang_mobile_number" | language }} :</label>
                    </div>
                    <div class="form-group col-6">
                      <label
                        ><b>{{ tokendata.user_mobile_number }}</b></label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-6">
                      <label>{{ "lang_email_address" | language }} :</label>
                    </div>
                    <div class="form-group col-6">
                      <label>
                        <b>{{ tokendata.user_email_address }}</b></label
                      >
                    </div>
                  </div>
                  <input type="hidden" FormControlName="token" />
                  <div class="row">
                    <div class="form-group col-12">
                      <label for="personal_id_number"
                        >{{ "lang_human_resources_id_number" | language }} :
                        <span class="red" *ngIf="tokendata.user_type_id == '1'"
                          >*</span
                        ></label
                      >
                      <input
                        (keypress)="ds.numberOnly($event)"
                        formControlName="personal_id_number"
                        minlength="10"
                        maxlength="10"
                        autocomplete="off"
                        type="text"
                        placeholder="{{
                          'lang_human_resources_id_number' | language
                        }}"
                        class="form-control almnabrformcontrol"
                        (change)="idExists($event.target.value)"
                      />
                      <mat-error
                        *ngIf="
                          registrationform.controls['personal_id_number']
                            .touched &&
                          registrationform.controls['personal_id_number'].errors
                            ?.required
                        "
                      >
                        {{ "lang_field_required" | language }}</mat-error
                      >
                      <mat-error
                        *ngIf="
                          registrationform.controls['personal_id_number'].errors
                            ?.minlength
                        "
                      >
                        {{ "lang_minimum" | language }} 10
                      </mat-error>
                      <mat-error
                        *ngIf="
                          registrationform.controls['personal_id_number'].errors
                            ?.maxlength
                        "
                      >
                        {{ "lang_minimum" | language }} 10
                      </mat-error>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12">
                      <label for="username"
                        >{{ "lang_username" | language }} :
                        <span class="red">*</span></label
                      >
                      <input
                        formControlName="username"
                        minlength="6"
                        type="text"
                        autocomplete="off"
                        placeholder="{{ 'lang_username' | language }}"
                        class="form-control almnabrformcontrol"
                        (change)="usernameExists($event.target.value)"
                      />
                      <mat-error
                        *ngIf="
                          registrationform.controls['username'].touched &&
                          registrationform.controls['username'].errors?.required
                        "
                      >
                        {{ "lang_field_required" | language }}
                      </mat-error>
                      <mat-error
                        *ngIf="
                          registrationform.controls['username'].errors
                            ?.minlength
                        "
                      >
                        {{ "lang_minimum" | language }} 6
                      </mat-error>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12">
                      <label for="password"
                        >{{ "lang_password" | language }} :
                        <span class="red">*</span></label
                      >
                      <input
                        formControlName="password"
                        minlength="6"
                        id="password"
                        autocomplete="off"
                        type="password"
                        placeholder="{{ 'lang_password' | language }}"
                        class="form-control almnabrformcontrol password"
                      />
                      <mat-error
                        *ngIf="
                          registrationform.controls['password'].touched &&
                          registrationform.controls['password'].errors?.required
                        "
                      >
                        {{ "lang_field_required" | language }}
                      </mat-error>
                      <mat-error
                        *ngIf="
                          registrationform.controls['password'].errors
                            ?.minlength
                        "
                      >
                        {{ "lang_minimum" | language }} 6
                      </mat-error>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12">
                      <label for="confirm_password"
                        >{{ "lang_confirm_password" | language }} :
                        <span class="red">*</span></label
                      >
                      <input
                        formControlName="confirm_password"
                        minlength="6"
                        id="confirm_password"
                        autocomplete="off"
                        type="password"
                        placeholder="{{ 'lang_confirm_password' | language }}"
                        class="form-control almnabrformcontrol confirm_password"
                      />
                      <mat-error
                        *ngIf="
                          registrationform.controls['confirm_password']
                            .touched &&
                          registrationform.controls['confirm_password'].errors
                            ?.required
                        "
                      >
                        {{ "lang_field_required" | language }}
                      </mat-error>
                      <mat-error
                        *ngIf="
                          registrationform.controls['confirm_password'].errors
                            ?.minlength
                        "
                      >
                        {{ "lang_minimum" | language }} 6
                      </mat-error>
                      <mat-error
                        *ngIf="
                          registrationform.controls['password'].value !==
                          registrationform.controls['confirm_password'].value
                        "
                      >
                        {{ "lang_password_notmatch" | language }}
                      </mat-error>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-10">
                      <div class="form-group">
                        <label for="terms_conditions" class=""
                          >{{ "lang_terms_conditions" | language }} :<span
                            class="red"
                            >*</span
                          ></label
                        >
                        <br />
                        <mat-checkbox
                          id="terms_conditions"
                          class="terms_conditions"
                          formControlName="terms_conditions"
                        >
                          <a
                            href="{{ apiurl }}terms_conditions"
                            target="_blank"
                            >{{ "lang_accept" | language }}</a
                          >
                        </mat-checkbox>
                        <mat-error
                          *ngIf="
                            registrationform['controls']['terms_conditions']
                              .touched &&
                            registrationform['controls']['terms_conditions']
                              .errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-xs-12 mb-10">
                      <button class="albutton">
                        {{ "lang_submit" | language }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="card-footer">
              <app-alert id="default-alert"></app-alert>
            </div>
            <div class="card-footer padb0">
              <div class="row text-center">
                <div class="col-md-6 mb-10">
                  <a routerLink="/signup">{{
                    "lang_join_almnabr" | language
                  }}</a>
                </div>
                <div class="col-md-6 mb-10">
                  <a routerLink="/auth/login">{{ "lang_signin" | language }}</a>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
