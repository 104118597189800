import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-snag-groups-add-edit',
  templateUrl: './snag-groups-add-edit.component.html',
  styleUrls: ['./snag-groups-add-edit.component.scss']
})
export class SnagGroupsAddEditComponent implements OnInit {
  lang_key        = localStorage.getItem('lang_key') || 'en';
  snag_form: FormGroup;
  snag_array: FormArray;
  group1_list: any = [];
  position_list: any = [];
  projects_profile_id: any;
  projects_work_area_id: any;
  constructor(private fb: FormBuilder, public ds: DataService, private sweetAlert: SweetAlertService, private lang: LanguagePipe, 
    private spinner: PreloaderService, private dialogRef: MatDialogRef<SnagGroupsAddEditComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data) {
      this.projects_profile_id = data.projects_profile_id;
      this.projects_work_area_id = data.projects_work_area_id;
    }

  ngOnInit(): void {
    this.get_group1_list({trem:''});
    this.get_position_list();
    this.buildForm();
    this.add_sang_records();
  }

  buildForm() {
    this.snag_form = this.fb.group({
      groups: this.fb.array([])
    });
    this.snag_array = this.snag_form.get('groups') as FormArray;
  }
  get snag_formarray() {
    return this.snag_form.get("groups") as FormArray;
  }
  snag_records(): FormGroup {
    return this.fb.group({
      platform_group1_code_system: [null, Validators.required],
      sgroup_position_id: [null, Validators.required]
    });
  }
  add_sang_records() {
    this.snag_array.push(this.snag_records());
  }
  removeSang(index) {
    this.snag_array.removeAt(index);
  }

  get_group1_list(event) {
    this.spinner.show();
    let param = new FormData();
    param.append('search_key',  event?.trem || '');
    param.append('lang_key', this.lang_key);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgone').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.group1_list = res?.records;
      } else {
        this.group1_list = [];
      }
    }, error => {
      this.spinner.hide();
      this.group1_list = [];
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    });
  }

  get_position_list() {
    this.spinner.show();
    this.ds.get(`p/snag/positions/${this.projects_profile_id}/${this.projects_work_area_id}`).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.position_list = res?.records;
      } else {
        this.position_list = [];
      }
    }, error => {
      this.spinner.hide();
      this.position_list = [];
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    });
  }

  add_snag() {
    if(this.snag_form.valid) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('projects_profile_id', this.projects_profile_id || '');
      formData.append('projects_work_area_id', this.projects_work_area_id || '');
      for(let i =0; i<this.snag_formarray.value.length; i++) {
        formData.append(`groups[${i}][platform_group1_code_systems]`, this.snag_formarray.controls[i].get('platform_group1_code_system').value);
        formData.append(`groups[${i}][sgroup_position_id]`, this.snag_formarray.controls[i].get('sgroup_position_id').value);
      }
      this.ds.postActionByUrl(formData, 'p/snag/gcreate').subscribe(res => {
        this.spinner.hide();
        if(res?.status) {
          this.dialogRef.close(true);
          this.sweetAlert.successToast(res?.msg, 2000);
        } else {
          this.sweetAlert.errorToast(res?.error, 2000);
        }
      }, error => {
        this.spinner.hide();
        this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
      });
    } else { 
      this.sweetAlert.errorToast(this.lang.transform('lang_fill_required_fields'), 2000);
    }
  }

}
