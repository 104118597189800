import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { DataService } from "@core/bootstrap/data.service";
import { PreloaderService } from "@core";
import { AlertService } from "@shared/components/alert/alert.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "app-invoice-reversal-add",
  templateUrl: "./invoice-reversal-add.component.html",
  styleUrls: ["./invoice-reversal-add.component.scss"],
})
export class InvoiceReversalAddComponent implements OnInit {
  public branch_id = this.route.snapshot.paramMap.get("bid");
  public finance_id = this.route.snapshot.paramMap.get("fid");
  financeList: any = [];
  defaultFinanceList: any = [];
  isdefaultFinanceList = true;
  View_Selling_list: any = [];
  invoiceList: any = [];
  branches: any = [];
  viewdata: any = [];
  viewdata_status: boolean = false;
  InvData: any = [];
  transactions: any = [];
  items: FormArray;
  myDate: any = new Date();
  accountData: any = [];
  accounts: any = [];
  form: FormGroup;
  taxs = [];
  get itemvalid() {
    return this.form.get("items") as FormArray;
  }
  private subscriptions = new Subscription();
  constructor(
    public ds: DataService,
    public spinner: PreloaderService,
    public alert: AlertService,
    public router: Router,
    public route: ActivatedRoute,
    public lang: LanguagePipe,
    private sweetAlert: SweetAlertService,
    public dialog: MatDialog,
    private _decimalPipe: DecimalPipe,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.load_branches();

    this.buildform();
    this.form.get("branch_id").valueChanges.subscribe((res) => {
      if (res) {
        this.getFinancialList();
        this.load_taxes();
      }
    });
  }

  ngAfterViewInit() {
    this.items.push(this.item_records());
  }

  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_add").subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.branches = res.records;
        }
      })
    );
  }
  public View_Selling_Invoice() {
    this.spinner.show();
    let formData = new FormData();
    formData.append("finance_id", this.form.get("finance_id").value);
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(formData),
          "accounts/view_se_inv/" +
            this.form.get("branch_id").value +
            "/" +
            this.form.get("invoice_id").value
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.viewdata_status = true;
              this.InvData = res;
              this.viewdata = res.records;
              this.transactions = res.transactions;
            } else {
              this.viewdata_status = false;
            }
          },
          (error) => {
            this.viewdata_status = false;
          }
        )
    );
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      finance_id: new FormControl(this.finance_id, [Validators.required]),
      new_finance_id: new FormControl(null, [Validators.required]),
      document_date: new FormControl(this.ds.encon(this.myDate)),
      debit_account: new FormControl(null, [Validators.required]),
      credit_account: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required]),
      invoice_id: new FormControl(null, [Validators.required]),
      tax_account_id: new FormControl(null, [Validators.required]),
      remarks1_invoice_id: new FormControl(null, [Validators.required]),
      remarks2: new FormControl(null, [Validators.required]),
      remarks3: new FormControl(null, [Validators.required]),
      items: this.fb.array([]),
    });
    this.getFinancialList();
    this.load_taxes();
    this.items = this.form.get("items") as FormArray;
  }

  public item_records(): FormGroup {
    return this.fb.group({
      invoice_item_name: new FormControl(null, [Validators.required]),
      invoice_item_unit: new FormControl(null, [Validators.required]),
      invoice_item_quantity: new FormControl(null, [Validators.required]),
      invoice_item_price: new FormControl(null, [Validators.required]),
      invoice_item_tax: new FormControl(null, [Validators.required]),
      invoice_item_description: new FormControl(null, [Validators.required]),
    });
  }
  public add_items() {
    this.items.push(this.item_records());
    console.log(this.items);
    console.log(this.form);
  }
  public removeItems(index) {
    if (this.items.length > 1) {
      this.items.removeAt(index);
    }
  }
  public items_group() {
    return this.form.get("items") as FormArray;
  }
  public create_invoice_reversal() {
    let param = new FormData();
    param.set("branch_id", this.form.get("branch_id").value);
    param.set("finance_id", this.form.get("finance_id").value || "");
    param.set(
      "document_date",
      moment(this.form.get("document_date").value)
        .lang("en")
        .format("YYYY/MM/DD") || ""
    );
    param.set("debit_account", this.form.get("debit_account").value || "");
    param.set("credit_account", this.form.get("credit_account").value || "");
    param.set("description", this.form.get("description").value || "");
    param.set("tax_account_id", this.form.get("tax_account_id").value || "");
    param.set("remarks1_invoice_id", this.form.get("invoice_id").value || "");
    param.set("remarks2", this.form.get("remarks2").value || "");
    param.set("remarks3", this.form.get("remarks3").value || "");
    //Items Data
    let items = this.form.get("items").value;
    items.forEach((v, k) => {
      param.append("items[" + k + "][invoice_item_name]", v.invoice_item_name);
      param.append("items[" + k + "][invoice_item_unit]", v.invoice_item_unit);
      param.append(
        "items[" + k + "][invoice_item_quantity]",
        v.invoice_item_quantity
      );
      param.append(
        "items[" + k + "][invoice_item_price]",
        v.invoice_item_price
      );
      param.append(
        "items[" + k + "][invoice_item_tax]",
        v.invoice_item_tax && v.invoice_item_tax.value
          ? v.invoice_item_tax.value
          : ""
      );
      param.append(
        "items[" + k + "][invoice_item_description]",
        v.invoice_item_description
      );
    });
    this.spinner.show();
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "/accounts/create_credit_notes").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.alert.success(res.msg);
            this.router.navigate(["accounts/list_invoice_reversal"]);
            this.form.reset();
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  getFinancialList() {
    this.form.get("new_finance_id").setValue(null);
    this.ds.get("financial/" + this.form.get("branch_id").value).subscribe(
      (res) => {
        if (res?.status) {
          if (this.isdefaultFinanceList) {
            this.defaultFinanceList = res?.records;
            this.financeList = res?.records;
            this.isdefaultFinanceList = false;
            this.form.get("finance_id").setValue(this.finance_id);
          } else {
            this.financeList = res?.records;
          }
        } else {
          this.sweetAlert.errorToast(res?.error, 2000);
          this.financeList = [];
        }
      },
      (err) => {
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          2000
        );
        this.financeList = [];
      }
    );
  }
  getInvoiceList() {
    this.form.get("invoice_id").setValue(null);
    this.ds
      .get("accounts/silists", {
        branch_id: this.form.get("branch_id").value,
        finance_id:
          this.form.get("new_finance_id").value ||
          this.form.get("finance_id").value,
      })
      .subscribe(
        (res) => {
          if (res?.status) {
            this.invoiceList = res?.records;
          } else {
            this.sweetAlert.errorToast(res?.error, 2000);
            this.invoiceList = [];
          }
        },
        (err) => {
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
          this.invoiceList = [];
        }
      );
  }
  public invcalc(item: any) {
    return (
      item.invoice_item_quantity * item.invoice_item_price -
      parseFloat(item.invoice_item_disc_amount) +
      parseFloat(item.invoice_item_tax_amount)
    );
  }
  openModal(templateRef) {
    let dialogRef = this.dialog.open(templateRef);
  }
  searchAccounts(key) {
    this.accountData = [];
    let param = new FormData();
    param.append("branch_id", this.form.get("branch_id").value);
    param.append("finance_id", this.form.get("finance_id").value);
    param.append("search_text", key.term);
    if (this.form.get("branch_id").value && key.term && key.term != undefined) {
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "sam/accounts_add").subscribe(
          (data) => {
            if (data.status) {
              this.accountData = data.records;
            } else {
              this.accountData = [];
            }
          },
          (error) => {
            this.accountData = [];
          }
        )
      );
    }
  }
  public searchTaxAccounts(key, field) {
    this.accounts = [];
    let param = new FormData();
    param.append("branch_id", this.form.get("branch_id").value);
    param.append("finance_id", this.form.get("finance_id").value);
    param.append("search_text", key.term);
    param.append("search_field", field);
    if (this.form.get("branch_id").value && key.term) {
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "sam/accounts_add").subscribe((data) => {
          if (data.status) {
            this.accounts = data.records;
          }
        })
      );
    }
  }
  public load_taxes() {
    if (this.form.get("branch_id").value) {
      this.taxs = [];
      this.subscriptions.add(
        this.ds
          .getActionByUrl([], "invtaxes/" + this.form.get("branch_id").value)
          .subscribe((res) => {
            this.spinner.hide();
            if (res.status) {
              this.taxs = res.records;
            }
          })
      );
    }
  }
  public calculate_total_calculation(percentage, givenamount) {
    let param = new FormData();
    param.append("givenamount", givenamount || "0");
    param.append("percentage", percentage.taxvalue || "0");
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "accounts/point_calculations"
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.form.get("tax").setValue(res?.tax_amount);
              this.form.get("amount_with_tax").setValue(res?.total_amount);
            } else {
              this.sweetAlert.errorToast(res?.error, 2000);
              this.form.get("tax").setValue("0.00");
              this.form.get("amount_with_tax").setValue("0.00");
            }
          },
          (error) => {
            this.spinner.hide();
            this.sweetAlert.errorToast(
              this.lang.transform("lang_internal_server_error"),
              2000
            );
            this.form.get("tax").setValue("0.00");
            this.form.get("amount_with_tax").setValue("0.00");
          }
        )
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
