import { DecimalPipe } from "@angular/common";
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
declare var $: any;

@Component({
  selector: "app-teller-reports",
  templateUrl: "./teller-reports.component.html",
  styleUrls: ["./teller-reports.component.scss"],
})
export class TellerReportsComponent implements OnInit, OnDestroy {
  page = 1;
  pageSize = 10;
  collectionSize;
  incomeCollectionSize;
  spendCollectionSize;
  listofdatas: [];
  form: FormGroup;
  formId: FormGroup;
  formCreate: FormGroup;
  branches: any = [];
  finances: any = [];
  totalsDate: any = [];
  totalsMonths: any = [];
  lodingdatas = this.lang.transform("lang_loading");
  fromaccounts: any = [];
  groupAccounts: any = [];
  toaccounts: any = [];
  finance_id: any = null;
  datastatus = false;
  data: any = [];
  titles: any = [];
  settings = environment.singledropdown;
  rt = "S";
  branch_id: any =
    localStorage.getItem("selectedBranch") != null
      ? localStorage.getItem("selectedBranch")
      : "0";

  transaction_request_type: any;
  transactionType = [
    {
      label: this.lang.transform("lang_selling_invoices"),
      value: "SIV",
    },
    {
      label: this.lang.transform("lang_purchase_invoice"),
      value: "PIV",
    },
    {
      label: this.lang.transform("lang_journal_voucher"),
      value: "JRN",
    },
    {
      label: this.lang.transform("lang_accounts_debit_notes"),
      value: "DTN",
    },
    {
      label: this.lang.transform("lang_credit_notes"),
      value: "CDN",
    },
    {
      label: this.lang.transform("lang_receipts"),
      value: "REC",
    },
    {
      label: this.lang.transform("lang_payments"),
      value: "PAY",
    },
  ];

  titlesPage = 1;
  titlesPageSize = 10;
  tt_tabindex = 0;
  searchKey = "";
  tellerGroups = [];
  acpage_no = "1";
  acpage_size = "10";
  searchGroupName: any;
  groups: any;
  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>, type: string, data?: any) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  @ViewChild("CreateReportGroup") dataview;
  incomeList = [];
  spendList = [];
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlertService: SweetAlertService
  ) {}
  ngOnInit(): void {
    this.buildform();
    this.buildformId();
    this.buildformCreate();
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != "0") {
      this.getFinancialList();
    }
  }

  public selectBrach(branch) {
    this.data = [];
    this.datastatus = false;
    if (branch && branch != "0") {
      localStorage.setItem("selectedBranch", branch);
      this.branch_id = branch;
      this.getFinancialList();
      this.getGroupsField({ term: "" });
      this.getGroups({ term: "" });
    } else {
      this.spinner.hide();
    }
  }
  public selectFinanceYear(financeid) {
    if (financeid && financeid != "0") {
      this.finance_id = financeid;
      this.getGroupsField({ term: "" });
      this.getGroups({ term: "" });
    }
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }

  public loadpagerec(page) {
    this.spinner.show();
    this.acpage_no = page;
    this.acpage_size = this.acpage_size;
    this.loadTellerReports();
  }
  public loadrecpagesize(size) {
    this.spinner.show();
    this.acpage_no = "1";
    this.acpage_size = size;
    this.loadTellerReports();
  }

  getFinancialList() {
    this.finances = [];
    this.finance_id = null;
    this.formId.get("finance_id").setValue(null);
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
                this.formId.get("finance_id").setValue(this.finance_id);
                this.form.get("period_from").setValue(v.finance_start_date);
                this.form.get("period_to").setValue(v.finance_end_date);
                this.getGroupsField({ term: "" });
                this.getGroups({ term: "" });
                this.loadTellerReports();
              }
            });
          } else {
            this.loadTellerReports();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public counter(i: number) {
    return new Array(i);
  }

  public buildform() {
    this.form = new FormGroup({
      report_type: new FormControl("S", [Validators.required]),
      period_from: new FormControl(null, [Validators.required]),
      period_to: new FormControl(null, [Validators.required]),
      group_by: new FormControl(null),
      transaction_request_type: new FormControl(null),
    });
  }
  public buildformId() {
    this.formId = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      finance_id: new FormControl(this.finance_id, [Validators.required]),
    });
  }
  public buildformCreate() {
    this.formCreate = new FormGroup({
      report_groups: new FormControl("", [Validators.required]),
    });
  }
  public getGroupsField(event) {
    let param = new FormData();
    param.append("branch_id", this.branch_id);
    param.append(
      "finance_id",
      this.formId.get("finance_id").value
        ? this.formId.get("finance_id").value
        : "0"
    );
    this.fromaccounts = [];
    if (this.branch_id) {
      this.subscriptions.add(
        this.ds
          .getActionByUrl(
            this.ds.formData2string(param),
            "/ac/teller/get_groupby_fields"
          )
          .subscribe(
            (data) => {
              if (data.status) {
                this.fromaccounts = data.records;
              }
            },
            (error) => {
              this.spinner.hide();
              this.ds.dialogf(
                "",
                error && error.error && error.error.error
                  ? error.error.error
                  : this.lang.transform("lang_internal_server_error")
              );
            }
          )
      );
    }
  }
  public getGroups(event) {
    let param = new FormData();
    param.append("branch_id", this.branch_id);
    param.append(
      "finance_id",
      this.formId.get("finance_id").value
        ? this.formId.get("finance_id").value
        : "0"
    );
    this.groupAccounts = [];
    if (this.branch_id) {
      this.subscriptions.add(
        this.ds
          .getActionByUrl(
            this.ds.formData2string(param),
            "ac/dyrt/get_report_groups"
          )
          .subscribe(
            (data) => {
              if (data.status) {
                this.groupAccounts = data.records;
              }
            },
            (error) => {
              this.spinner.hide();
              this.ds.dialogf(
                "",
                error && error.error && error.error.error
                  ? error.error.error
                  : this.lang.transform("lang_internal_server_error")
              );
            }
          )
      );
    }
  }
  report_type(val) {
    this.rt = val;
  }
  public fetch_statements(request_type: string) {
    if (this.form.valid) {
      this.spinner.show();
      let param = new FormData();
      param.set("branch_id", this.formId.get("branch_id").value);
      param.set(
        "finance_id",
        this.formId.get("finance_id").value
          ? this.formId.get("finance_id").value
          : "0"
      );
      param.set(
        "group_by",
        this.form.get("group_by").value != null
          ? this.form.get("group_by").value
          : ""
      );
      param.set(
        "start_date",
        this.form.get("period_from").value != null
          ? this.form.get("period_from").value
          : ""
      );
      param.set(
        "end_date",
        this.form.get("period_to").value != null
          ? this.form.get("period_to").value
          : ""
      );
      param.set(
        "transaction_request_type",
        this.form.get("transaction_request_type").value != null
          ? this.form.get("transaction_request_type").value
          : ""
      );
      param.set(
        "report_type",
        this.form.get("report_type").value != null
          ? this.form.get("report_type").value
          : ""
      );
      param.set("request_type", request_type || "");
      this.subscriptions.add(
        this.ds
          .getActionByUrl(
            this.ds.formData2string(param),
            "/ac/teller/get_teller_reports/" + this.page + "/" + this.pageSize
          )
          .subscribe(
            (res) => {
              this.spinner.hide();
              if (res.status && res.records.length > 0) {
                if (request_type == "income") {
                  this.incomeList = res?.records;
                  this.incomeCollectionSize = res?.page?.total_records;
                } else if (request_type == "spend") {
                  this.spendList = res?.records;
                  this.spendCollectionSize = res?.page?.total_records;
                }
                this.listofdatas = res?.records;
              } else {
                if (request_type == "income") {
                  this.incomeList = [];
                  this.incomeCollectionSize = 0;
                  this.sweetAlertService.errorToast(
                    this.lang.transform("lang_no_income_data"),
                    3000
                  );
                } else if (request_type == "spend") {
                  this.spendList = [];
                  this.spendCollectionSize = 0;
                  this.sweetAlertService.errorToast(
                    this.lang.transform("lang_no_spend_data"),
                    3000
                  );
                }
                this.lodingdatas = res.error;
                // this.ds.dialogf("", res.error);
              }
            },
            (error) => {
              this.lodingdatas =
                error && error.error && error.error.error
                  ? error.error.error
                  : this.lang.transform("lang_internal_server_error");
              this.spinner.hide();
              if (request_type == "income") {
                this.incomeList = [];
                this.incomeCollectionSize = 0;
                this.sweetAlertService.errorToast(
                  this.lang.transform("lang_no_income_data"),
                  3000
                );
              } else if (request_type == "spend") {
                this.spendList = [];
                this.spendCollectionSize = 0;
                this.sweetAlertService.errorToast(
                  this.lang.transform("lang_no_spend_data"),
                  3000
                );
              }
            }
          )
      );
    } else {
      this.spinner.hide();
      this.ds.dialogf("", this.lang.transform("lang_fill_required_fields"));
    }
  }
  public loadPageGroupTeller(page) {
    this.page = page;
    this.pageSize = this.pageSize;
    this.fetch_statements("income");
    this.fetch_statements("spend");
  }
  public refreshLists() {
    this.data = this.titles
      .map((list, i) => ({ id: i + 1, ...list }))
      .slice(
        (this.titlesPage - 1) * this.titlesPageSize,
        (this.titlesPage - 1) * this.titlesPageSize + this.titlesPageSize
      );
  }
  public absolute(amount) {
    if (parseInt(amount) >= 0) {
      let famount: any = Math.abs(amount);
      return amount == "0.00"
        ? "0.00"
        : this.ds.addCommas(parseFloat(famount).toFixed(2));
    } else {
      let famount: any = Math.abs(amount);
      return this.ds.addCommas(parseFloat(famount).toFixed(2));
    }
  }

  export_exl_pdf(request, iddiv = "#showPDFPreview") {
    this.spinner.show();
    let param = new FormData();
    param.set("branch_id", this.formId.get("branch_id").value);
    param.set(
      "finance_id",
      this.formId.get("finance_id").value
        ? this.formId.get("finance_id").value
        : "0"
    );
    param.set(
      "group_by",
      this.form.get("group_by").value != null
        ? this.form.get("group_by").value
        : ""
    );
    param.set(
      "start_date",
      this.form.get("period_from").value != null
        ? this.form.get("period_from").value
        : ""
    );
    param.set(
      "end_date",
      this.form.get("period_to").value != null
        ? this.form.get("period_to").value
        : ""
    );
    param.set(
      "transaction_request_type",
      this.form.get("transaction_request_type").value != null
        ? this.form.get("transaction_request_type").value
        : ""
    );
    param.set(
      "report_type",
      this.form.get("report_type").value != null
        ? this.form.get("report_type").value
        : ""
    );
    this.spinner.show();
    $(iddiv + " iframe").attr("src", "");
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          request == "E"
            ? "ac/teller/export_teller_reports_excel"
            : "ac/teller/export_teller_reports_pdf"
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              if (request == "P") {
                ($(iddiv) as any).modal({
                  backdrop: "static",
                  keyboard: false,
                });
                ($(iddiv) as any).modal("show");
                if (res.direct_data) {
                  document.querySelector("iframe").src = res.base64;
                  document.querySelector("iframe").style.height = "70vh";
                } else {
                  $(iddiv + " iframe").attr(
                    "src",
                    "data:" + res["content-type"] + ";base64," + res.base64
                  );
                  $(iddiv + " iframe").css("height:70vh");
                }
              } else if (request == "E") {
                this.ds.downloadFile(res);
              }
            } else {
              this.ds.dialogf("", res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : "Internal Server Error 501."
            );
          }
        )
    );
  }

  loadTellerReports() {
    let param = new FormData();
    param.append("branch_id", this.branch_id || "");
    param.set(
      "finance_id",
      this.formId.get("finance_id").value
        ? this.formId.get("finance_id").value
        : "0"
    );
    param.append("search_key", this.searchGroupName || "");
    param.append("groups", this.groups || "");
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "ac/dyrt/get_teller_report_groups_lists/" +
            this.acpage_no +
            "/" +
            this.acpage_size
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.tellerGroups = res;
            } else {
              this.tellerGroups = [];
              this.lodingdatas = res.error;
            }
          },
          (error) => {
            this.spinner.hide();
            this.tellerGroups = [];
            this.lodingdatas =
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error");
          }
        )
    );
  }

  public deleteTellerGroupData(data) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true,
      width: "400px",
      data: {
        title: this.lang.transform("lang_confirmation"),
        message: this.lang.transform("lang_are_you_sure"),
      },
    });
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          this.subscriptions.add(
            this.ds
              .deleteActionByExtraData(
                [data?.teller_report_id],
                "ac/dyrt/delete_teller_report_groups",
                {
                  branch_id: this.branch_id,
                  finance_id: this.formId.get("finance_id").value,
                }
              )
              .subscribe(
                (data) => {
                  this.spinner.hide();
                  if (data.status) {
                    this.loadTellerReports();
                    this.ds.dialogf("", data.msg);
                  } else {
                    this.ds.dialogf("", data.error);
                  }
                },
                (error) => {
                  this.spinner.hide();
                  this.ds.dialogf(
                    "",
                    error && error.error && error.error.error
                      ? error.error.error
                      : this.lang.transform("lang_internal_server_error")
                  );
                }
              )
          );
        }
      })
    );
  }

  submitModal() {
    this.spinner.show();
    let param = new FormData();
    param.append("branch_id", this.branch_id || "");
    param.set(
      "finance_id",
      this.formId.get("finance_id").value
        ? this.formId.get("finance_id").value
        : "0"
    );
    param.set(
      "report_groups",
      this.formCreate.get("report_groups").value || ""
    );
    this.subscriptions.add(
      this.ds
        .postActionByUrl(param, "ac/dyrt/create_teller_report_groups")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.form.reset();
              this.loadTellerReports();
              this.alert.success(res.msg);
              setTimeout(() => {
                this.modalRef.hide();
              }, 2000);
            } else {
              this.alert.error(res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }

  getTellerReportsTitles() {
    if (this.form.valid) {
      this.spinner.show();
      let param = new FormData();
      param.set("branch_id", this.formId.get("branch_id").value);
      param.set(
        "finance_id",
        this.formId.get("finance_id").value
          ? this.formId.get("finance_id").value
          : "0"
      );
      param.set(
        "group_by",
        this.form.get("group_by").value != null
          ? this.form.get("group_by").value
          : ""
      );
      param.set(
        "start_date",
        this.form.get("period_from").value != null
          ? this.form.get("period_from").value
          : ""
      );
      param.set(
        "end_date",
        this.form.get("period_to").value != null
          ? this.form.get("period_to").value
          : ""
      );
      param.set(
        "transaction_request_type",
        this.form.get("transaction_request_type").value != null
          ? this.form.get("transaction_request_type").value
          : ""
      );
      param.set(
        "report_type",
        this.form.get("report_type").value != null
          ? this.form.get("report_type").value
          : ""
      );
      this.subscriptions.add(
        this.ds
          .getActionByUrl(
            this.ds.formData2string(param),
            "/ac/teller/get_teller_reports_titles"
          )
          .subscribe(
            (res) => {
              this.datastatus = res.status;
              this.spinner.hide();
              if (res.status) {
                this.titles = res?.title;
                this.data = res?.title;
                this.totalsMonths = res?.totals_by_month;
                this.totalsDate = res?.totals_by_date;
              } else {
                this.lodingdatas = res.error;
                this.titles = [];
                this.data = [];
                this.totalsMonths = [];
                this.totalsDate = [];
                this.ds.dialogf("", res.error);
              }
            },
            (error) => {
              this.lodingdatas =
                error && error.error && error.error.error
                  ? error.error.error
                  : this.lang.transform("lang_internal_server_error");
              this.datastatus = false;
              this.listofdatas = [];
              this.titles = [];
              this.spinner.hide();
              this.data = [];
              this.totalsDate = [];
              this.totalsMonths = [];
              this.ds.dialogf(
                "",
                error && error.error && error.error.error
                  ? error.error.error
                  : this.lang.transform("lang_internal_server_error")
              );
            }
          )
      );
    } else {
      this.spinner.hide();
      this.ds.dialogf("", this.lang.transform("lang_fill_required_fields"));
    }
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
