<div class="card">
    <div class="width100">
        <div class="card-header"> {{'lang_human_resources_job_details' | language }} </div>
        <div class="card-body pad0" [formGroup]="regForm">
            <div class="mbrl15" formGroupName="jobDetails">
                <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                        <div class="form-groups">
                            <label for="job_title_id">{{'lang_human_resources_job_title_iqama' | language }} : <span class="red">*</span></label>
                            <ng-select 
                                [items]="jobLists" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_human_resources_job_title_iqama' | language}}"
                                formControlName="job_title_id"
                                (change)="getJobLicenses($event)">
                            </ng-select>
                            <mat-error *ngIf="regForm.get('jobDetails')['controls']['job_title_id'].touched && regForm.get('jobDetails')['controls']['job_title_id'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12 mb-10">
                        <div class="form-groups">
                            <label for="job_descriptions">{{'lang_job_descriptions' | language }} : <span class="red">*</span></label>
                            <textarea formControlName="job_descriptions" id="job_descriptions" type="text" placeholder="{{'lang_job_descriptions' | language }}" class="form-control almnabrformcontrol job_descriptions"></textarea>
                            <mat-error *ngIf="regForm.get('jobDetails')['controls']['job_descriptions'].touched && regForm.get('jobDetails')['controls']['job_descriptions'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                        </div>
                    </div>
                </div>
                <div formArrayName="liclists">
                    <label class="red" *ngIf="licencesstatus">{{'lang_need_licence_details' | language }}</label>
                    <hr *ngIf="licencesstatus">
                    <div *ngFor="let licence of licenceFormGroup.controls; let i = index; let jj = i; let aj = i; let ed = i; let ad = i;">
                        <div class="row mb-10" [formGroupName]="i">
                            <input type="hidden" formControlName="licence_list_id">
                            <input type="hidden" formControlName="settings_id">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label class="labelInput">{{'lang_licence_name' | language }}</label>
                                <input type="text" class="form-control almnabrformcontrol labelInput" formControlName="licence_name" placeholder="{{'lang_licence_name' | language }}" [readonly]="true">
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_number' | language }}</label>
                                <input type="text" class="form-control almnabrformcontrol" formControlName="licence_number" placeholder="{{'lang_licence_number' | language }}">
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_idate_english' | language }}:</label>
                                <div class="input-group input-group-sm">
                                    <input [matDatepicker]="licence_issue_date_englishU" formControlName="licence_issue_date_english" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly (dateChange)="ds.setENTOARDate($event, licencesList.controls[i].get('licence_issue_date_arabic'))">
                                    <span class="input-group-btn">
                                        <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="licence_issue_date_englishU"></mat-datepicker-toggle>
                                        <mat-datepicker #licence_issue_date_englishU></mat-datepicker>
                                    </span>
                                    <span class="input-group-btn">
                                        <button (click)="licencesList.controls[i].get('licence_issue_date_english').setValue('');licencesList.controls[i].get('licence_issue_date_arabic').setValue(null);" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_idate_arabic' | language }}:</label>
                                <input 
                                    type="text" 
                                    class="form-control almnabrformcontrol"
                                    ngbDatepicker 
                                    (click)="licdateia.toggle()" 
                                    #licdateia="ngbDatepicker"
                                    (ngModelChange)="ds.setARTOENDate($event, licencesList.controls[i].get('licence_issue_date_english'))" 
                                    formControlName="licence_issue_date_arabic"
                                    placeholder="YYYY/MM/DD"
                                    [readonly]="true">
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_edate_english' | language }}:</label>
                                <div class="input-group input-group-sm">
                                    <input [matDatepicker]="licence_expiry_date_englishU" formControlName="licence_expiry_date_english" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly (dateChange)="ds.setENTOARDate($event, licencesList.controls[i].get('licence_expiry_date_arabic'))">
                                    <span class="input-group-btn">
                                        <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="licence_expiry_date_englishU"></mat-datepicker-toggle>
                                        <mat-datepicker #licence_expiry_date_englishU></mat-datepicker>
                                    </span>
                                    <span class="input-group-btn">
                                        <button (click)="licencesList.controls[i].get('licence_expiry_date_english').setValue('');licencesList.controls[i].get('licence_expiry_date_arabic').setValue(null);" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_edate_arabic' | language }}:</label>
                                <input type="text" class="form-control almnabrformcontrol" ngbDatepicker (click)="licdateea.toggle()" #licdateea="ngbDatepicker" [readonly]="true" (ngModelChange)="ds.setARTOENDate($event, licencesList.controls[i].get('licence_expiry_date_english'))" formControlName="licence_expiry_date_arabic" placeholder="YYYY/MM/DD" [readonly]="true">
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="card-footer padb0">
                <div class="row">
                    <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
                        <button type="button" class="albutton" matStepperPrevious>{{'lang_previous' | language }}</button>
                    </div>
                    <div class="col-md-8 col-sm-6 col-xs-12 mb-10"></div>
                    <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
                        <button type="button" class="albutton" matStepperNext (click)="step7Submitted()">{{'lang_next' | language }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>