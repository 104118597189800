import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";

@Component({
  selector: "app-cscreate",
  templateUrl: "./cscreate.component.html",
  styleUrls: ["./cscreate.component.scss"],
})
export class CscreateComponent implements OnInit, OnDestroy {
  ishasNumber;
  registrationForm: FormGroup;
  users: any = [];
  accounts: any = [];
  branches: any = [];
  types: any = [];
  isFile: boolean = false;
  filename: any = this.lang.transform("lang_select_files");
  isEmailExists: boolean = false;
  isMobExists: boolean = false;
  emailErrMsg: string = "";
  mobErrMsg: string = "";
  constructor(
    public ds: DataService,
    public router: Router,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.registration_form();
    this.get_branches();
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public registration_form() {
    this.registrationForm = new FormGroup({
      branch_id: new FormControl("", [Validators.required]),
      customer_title_en: new FormControl("", [Validators.required]),
      customer_title_ar: new FormControl("", [Validators.required]),
      logo: new FormControl(""),
      customer_email_address: new FormControl("", [
        Validators.required,
        Validators.email,
      ]),
      customer_mobile: new FormControl("", [Validators.required]),
      customer_tax_vat: new FormControl("", [Validators.required]),
      customer_ccr_no: new FormControl(""),
      customer_managers: new FormControl("", [Validators.required]),
      customer_teams: new FormControl(""),
      customer_link_with_accounts: new FormControl(0, [Validators.required]),
      customer_address: new FormControl(""),
      customer_credit_days: new FormControl(""),
      customer_security_amount: new FormControl(""),
      customer_credit_limit: new FormControl(""),
      customer_discount_limit: new FormControl(""),
      customer_discount_onpay: new FormControl(""),
      customer_salesmancode: new FormControl(""),
      customer_salesmanarea: new FormControl(""),
      customer_stop_transaction: new FormControl(""),
      customer_account_id: new FormControl(""),
      street_name: new FormControl("", [Validators.required]),
      building_number: new FormControl("", [Validators.required]),
      plot_identification: new FormControl("", [Validators.required]),
      city_sub_division: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      postal_number: new FormControl("", [Validators.required]),
      registration_name: new FormControl("", [Validators.required]),
    });
  }
  public get_branches() {
    this.subscriptions.add(
      this.ds.getActionByUrl([], "cs/b/customers_add").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public emptyAccountFileds() {
    this.registrationForm.patchValue({
      customer_address: null,
      customer_credit_days: null,
      customer_security_amount: null,
      customer_credit_limit: null,
      customer_discount_limit: null,
      customer_discount_onpay: null,
      customer_salesmancode: null,
      customer_salesmanarea: null,
      customer_stop_transaction: null,
      customer_account_id: null,
    });
  }
  public accountSearch(key) {
    this.accounts = [];
    if (
      this.registrationForm.get("branch_id").value &&
      key != undefined &&
      key.term
    ) {
      let param = new FormData();
      param.append("branch_id", this.registrationForm.get("branch_id").value);
      param.append("search_text", key.term);
      param.append("status", "1");
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "sam/accounts_add").subscribe((data) => {
          if (data.status) {
            this.accounts = data.records;
          }
        })
      );
    }
  }
  public search_users(key) {
    if (this.registrationForm.get("branch_id").value && key != undefined) {
      let param = new FormData();
      param.append("branch_id", this.registrationForm.get("branch_id").value);
      param.append("search_text", key.term);
      param.append("permission_key", "customers_add");
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "cs/susers").subscribe((data) => {
          if (data.status) {
            this.users = data.records;
          }
        })
      );
    } else {
      this.ds.dialogf("", this.lang.transform("lang_choose_branch"));
    }
  }
  public uploadFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.isFile = true;
      this.registrationForm.get("logo").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.registrationForm.get("logo").setValue("");
      this.filename = this.lang.transform("lang_select_files");
    }
  }
  public removeFile() {
    this.isFile = false;
    this.registrationForm.get("logo").setValue("");
    this.filename = this.lang.transform("lang_select_files");
  }
  public createCustomer() {
    if (!this.isEmailExists && !this.isMobExists) {
      this.spinner.show();
      let param = this.ds.json2formdata(this.registrationForm.value);
      if (
        this.registrationForm.get("customer_link_with_accounts").value == "1"
      ) {
        param.set(
          "customer_account_id",
          this.registrationForm.get("customer_account_id").value || ""
        );
      }

      this.subscriptions.add(
        this.ds.postActionByUrl(param, "cs/create").subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
              this.alert.success(data.msg);
              this.registrationForm.reset();
              setTimeout(() => {
                this.router.navigate(["/customers/view/" + data.insert_id]);
              }, 1000);
            } else {
              this.alert.error(data.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
      );
    }
  }

  checkEmailExists() {
    let formData = new FormData();
    formData.append(
      "customer_email_address",
      this.registrationForm.get("customer_email_address").value || ""
    );
    this.ds.post("cs/emailexists", formData).subscribe((res) => {
      if (res?.error) {
        this.isEmailExists = true;
        this.emailErrMsg = res?.error[0]?.title;
      } else {
        this.isEmailExists = false;
        this.emailErrMsg = "";
      }
    });
  }
  checkMobExists() {
    let formData = new FormData();
    formData.append(
      "customer_mobile",
      this.registrationForm.get("customer_mobile").value || ""
    );
    this.ds.post("cs/mobileexists", formData).subscribe((res) => {
      if (res?.error) {
        this.isMobExists = true;
        this.mobErrMsg = res?.error[0]?.title;
      } else {
        this.isMobExists = false;
        this.mobErrMsg = "";
      }
    });
  }
}
