import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-add-project",
	templateUrl: "./add-project.component.html",
	styleUrls: ["./add-project.component.scss"],
})
export class AddProjectComponent implements OnInit {
	vat_list = [];
	vat_value = null;
	lang_key = localStorage.getItem("lang_key") || "en";
	project_form: FormGroup;
	attachments_array: FormArray;
	filename: string = this.lang.transform("lang_select_files");
	project_id;
	service_id;
	update_data;
	edit_mode = false;
	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<AddProjectComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private lang: LanguagePipe,
		public ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService
	) {
		this.project_id = data?.projects_profile_id;
		this.service_id = data?.service_id;
		this.update_data = data?.update_data;
		this.edit_mode = data?.update_data ? true : false;
	}

	ngOnInit(): void {
		this.build_form();
		// this.getVatList();
		this.vat_list = [
			{
				label: "5%",
				value: "5",
			},
			{
				label: "10%",
				value: "10",
			},
			{
				label: "15%",
				value: "15",
			},
		];
		if (this.edit_mode) {
			this.setEditForm();
		}
	}

	build_form() {
		this.project_form = this.fb.group({
			quotation_title_english: ["", Validators.required],
			quotation_title_arabic: ["", Validators.required],
			grand_total: [null, Validators.required],
			tax_amount: [null, Validators.required],
			attachments: this.fb.array([]),
		});
		this.attachments_array = this.project_form.get("attachments") as FormArray;
	}
	setEditForm() {
		this.project_form
			.get("quotation_title_english")
			.setValue(this.update_data?.quotation_title_english);
		this.project_form
			.get("quotation_title_arabic")
			.setValue(this.update_data?.quotation_title_arabic);
		this.project_form
			.get("grand_total")
			.setValue(this.update_data?.quotation_grand_total);
		this.project_form
			.get("tax_amount")
			.setValue(this.update_data?.quotation_tax_amount);
	}
	// getVatList() {
	// 	let param = new FormData();
	// 	param.append("lang_key", this.lang_key);
	// 	param.append("request_type", "vat");
	// 	this.ds
	// 		.getActionByUrl(this.ds.formData2string(param), "form/FORM_PQ2/requests")
	// 		.subscribe((res) => {
	// 			if (res?.status) {
	// 				this.vat_list = res.data;
	// 			}
	// 		});
	// }
	get attachments_form() {
		return this.project_form.get("attachments") as FormArray;
	}

	public attachment(value?): FormGroup {
		return this.fb.group({
			delete_icon: [value?.title_english || value?.title_arabic || ""],
			view: [value?.title_english || value?.title_arabic || null],
			title_english: [value?.title_english || ""],
			title_arabic: [value?.title_arabic || ""],
			file: [null],
		});
	}
	public addAttachment(value?) {
		this.attachments_array.push(this.attachment(value));
		this.attachments_array.setValidators(Validators.required);
		this.attachments_array.updateValueAndValidity();
	}
	public removeAttachment(index) {
		this.attachments_array.removeAt(index);
		this.attachments_array.clearValidators();
		this.attachments_array.updateValueAndValidity();
	}
	onSubmit() {
		if (this.project_form.valid) {
			this.spinner.show();
			let formData = new FormData();
			formData.append("projects_profile_id", this.project_id || "");
			formData.append("projects_ps_id", this.service_id || "");
			formData.append(
				"quotation_title_english",
				this.project_form.get("quotation_title_english").value || ""
			);
			formData.append(
				"quotation_title_arabic",
				this.project_form.get("quotation_title_arabic").value || ""
			);
			this.attachments_form.value.forEach((v, k) => {
				formData.append("attachments[" + k + "][file]", v.file);
				formData.append(
					"attachments[" + k + "][title_english]",
					this.project_form
						.get("attachments")
						["controls"][k].get("title_english").value
				);
				formData.append(
					"attachments[" + k + "][title_arabic]",
					this.project_form
						.get("attachments")
						["controls"][k].get("title_arabic").value
				);
			});
			this.ds.post("project_design/add_project_design", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.dialogRef.close({ status: "success" });
						this.sweetAlert.successToast(
							this.project_form.get("quotation_title_english").value +
								" " +
								"Created Successfuly",
							1000
						);
					} else {
						this.dialogRef.close({ status: "failed" });
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
		}
	}
	onUpdateProject() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("projects_profile_id", this.project_id || "");
		formData.append("projects_ps_id", this.service_id || "");
		formData.append(
			"quotation_title_english",
			this.project_form.get("quotation_title_english").value || ""
		);
		formData.append(
			"quotation_title_arabic",
			this.project_form.get("quotation_title_arabic").value || ""
		);
		formData.append(
			"quotation_grand_total",
			this.project_form.get("quotation_grand_total").value || ""
		);
		formData.append(
			"quotation_tax_amount",
			this.project_form.get("quotation_tax_amount").value || ""
		);
		formData.append(
			"projects_quotation_id",
			this.update_data?.projects_quotation_id || ""
		);
		this.ds
			.putActionByUrl(
				this.ds.formData2string(formData),
				"projects/update_manual_supervision_workarea"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.dialogRef.close({ status: "success" });
						this.sweetAlert.successToast(
							this.project_form.get("quotation_title_english").value +
								" " +
								"Updated Successfuly",
							1000
						);
					} else {
						this.dialogRef.close({ status: "failed" });
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
}
