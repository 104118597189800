<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_users_workarea" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="form">
		<div class="row">
			<div class="col-12 mb-10">
				<label for="workarea">{{ "lang_workarea" | language }}</label>
				<ng-select
					[items]="workareaList"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					[searchable]="false"
					placeholder="{{ 'lang_workarea' | language }}"
					formControlName="projects_work_area_id"
				>
				</ng-select>
			</div>
			<div class="col-12 mb-10">
				<label for="positions">{{ "lang_positions" | language }}</label>
				<ng-select
					[items]="positionList"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					[searchable]="false"
					placeholder="{{ 'lang_positions' | language }}"
					formControlName="position_id"
				>
				</ng-select>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12"></div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="onSubmit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
