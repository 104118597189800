<div class="row" *ngIf="actionMode != 'VIEW'">
	<div class="col-sm-2">
		<i
			class="fa fa-plus-square s2icon mnabricon mb-10"
			matTooltip="{{ 'lang_add_group_type' | language }}"
			(click)="get_extra_types(addExtraType)"
		></i>
	</div>
</div>
<form [formGroup]="form" class="form">
	<mat-tab-group
		mat-stretch-tabs
		class="example-stretched-tabs mat-elevation-z4 typemattab typemat"
		formArrayName="typesData"
	>
		<mat-tab
			*ngFor="
				let item of items_type().controls;
				let typeIndex = index;
				let odd = odd;
				let even = even
			"
			[formGroupName]="typeIndex"
		>
			<ng-template mat-tab-label>
				<span>{{
					typevalidate.controls[typeIndex] &&
						typevalidate.controls[typeIndex].get("platform_group_type_title")
							.value
				}}</span>
				<i
					*ngIf="actionMode != 'VIEW'"
					class="fa fa-trash red s2icon"
					matTooltip="{{ 'lang_delete' | language }}"
					(click)="delete_types_record(typeIndex)"
				></i>
			</ng-template>
			<div class="custom-panel-content">
				<div class="card">
					<div class="width100">
						<div class="card-header">
							<bdi
								innerHtml="{{ 'lang_total_price' | language }} {{
									typevalidate.controls[typeIndex].get(
										'platform_group_type_title'
									).value
								}}: {{
									typevalidate.controls[typeIndex].get(
										'platform_group_type_total'
									).value
								}}"
							></bdi>
							<span class="float-right"
								><bdi>{{
									typevalidate.controls[typeIndex] &&
										typevalidate.controls[typeIndex].get(
											"platform_group_type_code_system"
										).value
								}}</bdi></span
							>
						</div>
						<div class="card-body padding10">
							<div class="row">
								<div class="col-md-3 col-sm-6 col-xs-12 mb-10">
									<label>{{ "lang_total_ratio" | language }}</label>
									<input
										type="text"
										class="form-control almnabrformcontrol"
										formControlName="platform_group_type_total_ratio"
										disabled
										readonly
									/>
								</div>
							</div>
							<div class="row pt-3 pb-3" *ngIf="actionMode != 'VIEW'">
								<!--edited-->
								<div class="col-sm-6 col-md-3 mb-2 text-center">
									<button
										mat-button
										style="
											background-color: rgb(113 166 223 / 42%);
											width: 100%;
										"
										(click)="downloadExcel(typeIndex)"
									>
										<i
											class="fa fa-download"
											style="
												font-size: 20px;
												margin: 0 5px;
												color: rgb(19, 65, 146);
											"
										></i>
										<span
											style="
												text-transform: uppercase;
												font-weight: 600;
												color: rgb(19, 65, 146);
												font-size: 12px;
											"
											>Download Excel</span
										>
									</button>
								</div>
								<div class="col-sm-6 col-md-3 mb-2 text-center">
									<input
										type="file"
										#uploadExcelFile
										id="uploadExcelInput"
										accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
										hidden
										(change)="uploadExcel($event.target.files, typeIndex)"
									/>
									<button
										mat-button
										style="
											background-color: rgb(136 224 136 / 42%);
											width: 100%;
										"
										(click)="uploadExcelFile.click()"
									>
										<i
											class="fa fa-upload"
											style="
												font-size: 20px;
												margin: 0 5px;
												color: rgb(19, 146, 19);
											"
										></i>
										<span
											style="
												text-transform: uppercase;
												font-weight: 600;
												color: rgb(19, 146, 19);
												font-size: 12px;
											"
											>Upload Excel</span
										>
									</button>
								</div>
								<div class="col-sm-6 col-md-3 mb-2 text-center">
									<button
										mat-button
										style="
											background-color: rgb(224 136 200 / 42%);
											width: 100%;
										"
										(click)="openDialog(typeIndex)"
									>
										<i
											class="fa fa-download"
											style="
												font-size: 20px;
												margin: 0 5px;
												color: rgb(146, 19, 102);
											"
										></i>
										<span
											style="
												text-transform: uppercase;
												font-weight: 600;
												color: rgb(146, 19, 102);
												font-size: 12px;
											"
											>Download History</span
										>
									</button>
								</div>
								<div class="col-sm-6 col-md-3 mb-2 text-center">
									<button
										mat-button
										[matMenuTriggerFor]="downloadMenu"
										style="
											background-color: rgb(136 196 224 / 42%);
											width: 100%;
										"
									>
										<i
											class="fa fa-database"
											style="color: #246a7e; margin: 0 5px"
										></i>
										<span
											style="color: #246a7e; font-size: 12px; font-weight: 600"
											>Download Related Data</span
										>
									</button>
									<mat-menu #downloadMenu="matMenu">
										<button
											mat-menu-item
											(click)="
												downloadDataRequired('CR', 'Consultant_Required')
											"
										>
											Consultant Required
										</button>
										<button
											mat-menu-item
											(click)="
												downloadDataRequired('CRE', 'Consultant_Recomendations')
											"
										>
											Consultant Recomendations
										</button>
										<button
											mat-menu-item
											(click)="
												downloadDataRequired('SBC', 'Saudi_Building_Codes')
											"
										>
											Saudi Building Codes
										</button>
										<button
											mat-menu-item
											(click)="downloadDataRequired('SI', 'Site_Issues')"
										>
											Site Issues
										</button>
										<button
											mat-menu-item
											(click)="downloadDataRequired('POS', 'Positions')"
										>
											Positions
										</button>
										<button
											mat-menu-item
											(click)="downloadDataRequired('UNT', 'Units')"
										>
											Units
										</button>
									</mat-menu>
								</div>
								<!--edited-->
							</div>
							<app-group2
								[fconsultants]="fconsultants"
								[consultantr]="consultantr"
								[positions]="positions"
								[buildingCodes]="buildingCodes"
								[ncrSiIsssues]="ncrSiIsssues"
								[type_code]="
									typevalidate.controls[typeIndex].get(
										'platform_group_type_code_system'
									).value
								"
								(group2TotalEvent)="get_group2_total($event)"
								[group2_data]="
									typevalidate.controls[typeIndex].get('typesGroup2Data').value
								"
								[actionMode]="actionMode"
								[step2form]="step2form"
								[step1formvalue]="step1formvalue"
								[maintotalamount]="maintotalamount"
								[this_template_total_cost]="this_template_total_cost"
								[step2formvalue]="step2formvalue"
								[weightunits]="weightunits"
								formControlName="group2Data"
								[group1Index]="group1Index"
								[typeIndex]="typeIndex"
								[project_data]="project_data"
								[template_id]="template_id"
								[supervision_data]="supervision_data"
								[tree_data]="tree_data"
								[access_codes]="access_codes"
								(group2_total_ratio)="getGroup2Ratios($event, typeIndex)"
							></app-group2>
						</div>
					</div>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</form>
<ng-template #addExtraType>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_add_types" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefT.hide()"
			>
				&times;
			</button>
		</div>
		<div class="modal-body">
			<div class="form-group">
				<div class="row">
					<div class="col-12">
						<label
							>{{ "lang_group_types" | language }} :
							<span class="red">*</span></label
						>
					</div>
					<div class="col-12">
						<ng-select
							[items]="extratypes"
							[multiple]="true"
							clearAllText="Clear"
							[hideSelected]="true"
							appendTo="body"
							[(ngModel)]="extratypedata"
							placeholder="{{ 'lang_group_types' | language }}"
						>
						</ng-select>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer pad15">
			<div class="row">
				<div class="col-md-6 col-xs-12 mb-10">
					<button
						type="button"
						class="albutton"
						(click)="add_extra_types_items()"
					>
						{{ "lang_add" | language }}
					</button>
				</div>
				<div class="col-md-6 col-xs-12 mb-10">
					<button
						type="button"
						class="btn btn-secondary secondarybutton"
						data-dismiss="modal"
						(click)="modalRefT.hide()"
					>
						{{ "lang_close" | language }}
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>
