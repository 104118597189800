<div class="form-wrapper">
  <div class="card border-0">
    <div class="card-body">
      <div class="row w-100">
        <app-step-six
          *ngIf="requestInfo?.steps?.HUMAN_RESOURCE_MANAGER"
          [t_id]="t_id"
          [reviewers]="reviewers"
          [responseData]="responseData"
          [usersList]="usersResponse"
          [requestInfo]="requestInfo"
          [stepper]="stepper"
          (refreshpage)="refreshpage()"
          (resfreshUsers)="get_salary_form_employees()"
          (resfreshView)="view_request()"
          [refresh]="refreshSubject.asObservable()"
        ></app-step-six>
        <div
          class="not-allow"
          *ngIf="!requestInfo?.steps?.HUMAN_RESOURCE_MANAGER"
        >
          <div class="permission-img">
            <img
              src="assets/images/no-permission.gif"
              alt=""
              width="100%"
              height="100%"
            />
          </div>
          <p style="text-align: center; font-weight: 600">
            {{ "lang_step_permission" | language }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
