import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-project-workarea-forms',
  templateUrl: './project-workarea-forms.component.html',
  styleUrls: ['./project-workarea-forms.component.scss']
})
export class ProjectWorkareaFormsComponent implements OnInit {
  modalRefV: BsModalRef;
  @Input() project_data;
  @Input() supervision_data;
  @Output() templateAction    = new EventEmitter<any>();
  able2delete                 = JSON.parse(this.ls.getItem('is_admin'));
  collections:any             = [];
  worklevels:any              = [];
  lang_key                    = localStorage.getItem('lang_key') || 'en';
  searchKey                   = "";
  collection_id:any           = null;
  template_status             = "";
  wholedata:any               = [];
  page_no:any                 = 1;
  page_size:any               = '10';
  nodata: string              = this.lang.transform('lang_loading');
  viewdata:any                = [];

  templateLists:any           = [];
  typeLists:any               = [];
  group1List:any              = [];
  group2List:any              = [];


  typemodel:any               = null;
  group1model:any             = null;
  group2model:any             = null;
  templatemodel:any           = null;

  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.getTemplateList();
  }

  public getTemplateList() {
    this.templateLists = [];
    let formData = new FormData();
    formData.append('lang_key', this.lang_key);
    formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id);
    this.ds.getActionByUrl(this.ds.formData2string(formData), 'p/get_templates_for_transactions').subscribe(res =>{
      if(res.status) {
        this.templateLists = res.records;
      }
    })
  }
  public load_group1_type_group2_lists(type?) {
    let sendAPI = false;
    if(type == 'group1') {
      this.group1List = this.typeLists = this.group2List = [];
      if(this.templatemodel) {
        sendAPI = true;
      }
    } else if(type == 'type') {
      this.typeLists = this.group2List = [];
      if(this.templatemodel && this.group1model) {
        sendAPI = true;
      }
    } else if(type == 'group2') {
      this.group2List = [];
      if(this.templatemodel && this.group1model && this.typemodel) {
        sendAPI = true;
      }
    }
    
    if(sendAPI) {
      this.spinner.show();
      let param = new FormData();
      param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || '');
      param.append('template_id', this.templatemodel || '');
      if(type == 'group1') {
        param.append('required_type', 'group1');
        param.append('group1',  '');
        param.append('type',  '');
      }
      if(type == 'type') {
        param.append('required_type', 'type');
        param.append('group1', this.group1model || '');
        param.append('type', '');
      }
      if(type == 'group2') {
        param.append('required_type', 'group2');
        param.append('group1', this.group1model || '');
        param.append('type', this.typemodel || '');
      }
      
      this.ds.getActionByUrl(this.ds.formData2string(param), 'pforms/get_group1_type_group2').subscribe( res => {
        this.spinner.hide();
        if(res.status) {
          if(type == 'group1') {
            this.group1List = res.records;
          } else if(type == 'type') {
            this.typeLists = res.records;
          } else if(type == 'group2') {
            this.group2List = res.records;
          }
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }
  
  public page_number(page) {
    this.page_no      = page;
    this.page_size    = this.page_size;
    this.get_all_forms_for_transactions();
  }
  public by_page_size(size) {
    this.page_no      = 1;
    this.page_size    = size;
    this.get_all_forms_for_transactions();
  }
  public search_by() {
    this.page_no      = 1;
    this.page_size    = '10';
    this.get_all_forms_for_transactions();
  }
  public get_all_forms_for_transactions() {
    if((this.group1model && this.typemodel && this.group2model && this.templatemodel) || this.searchKey) {
      let param = new FormData();
      param.append('search_key',this.searchKey || '');
      param.append('projects_profile_id', this.supervision_data.projects_profile_id || '0');
      param.append('platform_group1_code_system', this.group1model || '');
      param.append('platform_group_type_code_system', this.typemodel || '');
      param.append('platform_group2_code_system', this.group2model || '');
      param.append('template_id', this.templatemodel || '');
      this.ds.getActionByUrl(this.ds.formData2string(param), 'p/formstransactions/'+this.supervision_data.projects_work_area_id+'/'+this.page_no+'/'+this.page_size).subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.wholedata  = res;
        } else {
          this.wholedata  = [];
          this.nodata     = res.error;
        }
      }, error => {
        this.spinner.hide();
        this.wholedata  = [];
        this.nodata     = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
      });
    }
  }
  public create_form_transaction(list) {
    this.templateAction.emit({mode : 'FTNEW', data : list, work_area_data : this.supervision_data, actionMode : 'FTNEW'})
  }
}
