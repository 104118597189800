import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
declare var $: any;
interface list {
	id?: number;
	transaction_request_id: string;
	transactions_records_datetime: string;
	transactions_records_id: string;
	transactions_records_note: string;
	transactions_records_user_id: string;
	transactions_records_user_name: string;
}
@Component({
	selector: "HRJ1-one-configrations",
	templateUrl: "./step-one-configrations.component.html",
	styleUrls: ["./step-one-configrations.component.scss"],
})
export class HRJ1StepOneConfigrationsComponent implements OnInit {
	// public Editor = ClassicEditor;
	@Output() joiningEvent = new EventEmitter();
	@Input() form_request_id;
	@Input() form_key;
	@Input() id;
	@Input() branch_id;
	form: FormGroup;
	lang_key = localStorage.getItem("lang_key") || "en";
	submitted = false;
	joining_type: any = [];
	users: any = [];

	employee_data: any = [];
	constructor(
		public ds: DataService,
		public datepipe: DatePipe,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public changeDetectorRef: ChangeDetectorRef
	) {}
	ngOnInit(): void {
		this.spinner.show();
		this.load_required_datas();
		this.get_data_edit();
		this.form = new FormGroup({
			subject: new FormControl("", [Validators.required]),
			transaction_from: new FormControl({ value: "", disabled: true }, [
				Validators.required,
			]),
			employee_number: new FormControl("", [Validators.required]),
			joining_type: new FormControl("", [Validators.required]),
			joining_start_date_english: new FormControl("", [Validators.required]),
			joining_start_date_arabic: new FormControl(null, [Validators.required]),
			joining_description: new FormControl(null, [Validators.required]),
			direct_manager: new FormControl(null, [Validators.required]),
		});
	}
	public load_required_datas() {
		let param = new FormData();
		param.append("lang_key", this.lang_key);
		//param.append('employee_number', this.employee_data.employee_number);
		let url = ["form/FORM_HRJ1/joining_type"];
		this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(
			(res) => {
				this.spinner.hide();
				if (res[0].status) {
					this.joining_type = res[0].records;
				}
			},
			(error) => {
				this.spinner.hide();
			}
		);
	}
	public get_data_edit() {
		this.ds
			.getActionByUrl(
				[],
				"form/" + this.form_key + "/vr/" + this.form_request_id
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						let data = {};
						this.employee_data = res.form_hrj1_data.records;
						data["subject"] = res.form_hrj1_data.records["subject"];
						data["employee_number"] =
							res.form_hrj1_data.records["employee_number"];
						data["joining_type"] =
							res.form_hrj1_data.records["tr_joining_type"];
						data["joining_start_date_english"] = this.ds.encon(
							res.form_hrj1_data.records["joining_start_date_english"]
						);
						data["joining_start_date_arabic"] = this.ds.arcon(
							res.form_hrj1_data.records["joining_start_date_arabic"]
						);
						data["lang_key"] = res.transactions_request["lang_key"];
						data["transaction_to"] =
							res.form_hrj1_data.records["transaction_from_name"];
						data["transaction_from"] =
							res.form_hrj1_data.records["transaction_from_name"];
						data["joining_description"] =
							res.form_hrj1_data.records["tr_joining_description"];
						data["direct_manager"] =
							res.form_hrj1_data.records["direct_manager"];
						if (
							res?.form_hrj1_data?.records?.direct_manager_name &&
							res?.form_hrj1_data?.records?.direct_manager
						) {
							this.users = [
								{
									label: res?.form_hrj1_data?.records?.direct_manager_name,
									value: res?.form_hrj1_data?.records?.direct_manager,
								},
							];
						}

						let defaultusers = [];
						if (res.transactions_persons && res.transactions_persons.status) {
							let markers = [];
							let reviewers = [];
							res.transactions_persons.records.forEach((v, k) => {
								defaultusers.push({
									value: v.user_id,
									label: v.person_name,
								});
							});
						}

						this.form.patchValue(data);
					} else {
						this.ds.dialogf("", res.error);
						this.router.navigate(["transactions/allforms/"]);
					}
				},
				(error) => {
					this.spinner.hide();
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	//Other Terms & Conditions End
	public search_users(key) {
		if (key) {
			this.users = [];
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.users = res.list;
					}
				});
		}
	}

	public submit() {
		this.alert.clear();
		this.submitted = true;
		if (this.form.valid) {
			this.submitted = false;
		}
		this.spinner.show();
		let param = new FormData();
		param.set("transaction_request_id", this.form_request_id);
		param.set("subject", this.form.get("subject").value || "");
		param.set("employee_number", this.form.get("employee_number").value || "");
		param.set(
			"transaction_from",
			this.form.get("transaction_from").value || ""
		);
		param.set("joining_type", this.form.get("joining_type").value || "");
		param.set(
			"joining_start_date_english",
			this.form.get("joining_start_date_english").value || ""
		);
		param.set(
			"joining_start_date_arabic",
			this.form.get("joining_start_date_arabic").value || ""
		);
		param.set(
			"joining_description",
			this.form.get("joining_description").value || ""
		);
		param.append("direct_manager", this.form.get("direct_manager").value || "");

		this.ds
			.postActionByUrl(param, "form/FORM_HRJ1/et/" + this.form_request_id)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.alert.success(this.lang.transform("lang_success"));
						this.form.reset();
						setTimeout(() => {
							location.reload();
						}, 2000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
}
