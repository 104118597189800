<form [formGroup]="form" (ngSubmit)="completeStep()">
	<div class="card">
		<div class="card-header">
			{{ "lang_contractor_request_close_NCR" | language }}
		</div>
		<div class="mbrl15">
			<div class="row p-3">
				<div class="col-sm-12 mt-2">
					<label>{{ "lang_note" | language }}</label>
					<textarea
						cols="30"
						rows="10"
						style="max-height: 200px; min-height: 70px; height: 70px"
						formControlName="note"
						placeholder="{{ 'lang_note' | language }}"
						autocomplete="off"
						class="form-control almnabrformcontrol"
					>
					</textarea>
				</div>
				<div class="col-md-12 mt-2 TableParts">
					<p class="mb-10">
						{{ "lang_attachments" | language }}
					</p>
					<div
						class="table-responsive customResponsive tablefields quotationitemtable"
					>
						<table
							border="1"
							class="table CustomTable"
							width="100%"
							formArrayName="attachments"
						>
							<thead>
								<tr>
									<td width="2%" style="padding: 5px; word-break: break-all">
										#
									</td>
									<td width="2%" style="padding: 5px; word-break: break-all">
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addfiles()"
											>add_box</mat-icon
										>
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_title" | language }}
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_file" | language }}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let file of filesFormGroup.controls;
										let i = index;
										let odd = odd;
										let even = even
									"
									[formGroupName]="i"
								>
									<td
										class="verticalalignmid"
										style="padding: 5px; word-break: break-all"
									>
										{{ i + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											*ngIf="
												!other_attachments.controls[i].get('delete_icon').value
											"
											(click)="removefiles(i)"
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											>delete_forever</mat-icon
										>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											type="text"
											formControlName="attach_title"
											placeholder="{{ 'lang_description' | language }}"
											autocomplete="off"
											class="form-control almnabrformcontrol"
										/>
									</td>
									<td
										class="verticalalignmid"
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											name="file"
											type="file"
											required
											onclick="this.value = null"
											(change)="
												ds.uploadSingleFile(
													$event,
													other_attachments.controls[i].get('file'),
													'file' + i,
													filename
												)
											"
											class="nghide albutton width100"
										/>
										<label id="file{{ i }}" class="albutton">{{
											filename
										}}</label>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="row mb-10">
				<!-- <div class="col-sm-6">
					<div class="form-group">
						<label for="verification_code"
							>{{ "lang_enter_verification_code" | language }}
							<span class="red">*</span>:
							<i
								style="padding: 0 10px"
								class="fa fa-info-circle orangestatus-color"
								matTooltip="{{
									'lang_if_you_not_have_code_hit_sendcode_button' | language
								}}"
							></i>
						</label>
						<input
							type="text"
							required
							class="form-control almnabrformcontrol"
							formControlName="transactions_persons_action_code"
						/>
					</div>
				</div> -->
				<div class="col-sm-12">
					<app-alert id="default-alert"></app-alert>
				</div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<!-- <div>
						<button
							class="albutton mnabrbutton mat-accent"
							type="button"
							(click)="openModal()"
							mat-button
						>
							{{ "lang_send_code" | language }}
						</button>
					</div> -->
				</div>
				<div class="col-sm-4 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<div>
						<input
							type="submit"
							value="{{ 'lang_submit' | language }}"
							class="albutton mnabrbutton mat-accent"
							mat-button
							
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<!--steps history-->
<app-ncr-steps-history
	[allData]="allData"
	[step_no]="9"
></app-ncr-steps-history>
