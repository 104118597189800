import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Observable, Subscription } from "rxjs";
import Swal from "sweetalert2";
import { ShowDeductionsComponent } from "../../show-deductions/show-deductions.component";

@Component({
  selector: "app-step-three",
  templateUrl: "./step-three.component.html",
  styleUrls: ["./step-three.component.scss"],
})
export class StepThreeComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key") || "en";
  @Input() stepper: MatStepper;
  @Input() t_id: any = "";
  @Input() requestInfo: any = "";
  @Input() type: any = "add";
  @Input() refresh!: Observable<void>;

  private subscriptions = new Subscription();
  @Output() UserListSetter = new EventEmitter<any>();
  @Output() resfreshUsers = new EventEmitter<any>();
  @Output() resfreshView = new EventEmitter<any>();
  usersList = [];
  @Input() set usersLists(data: any) {
    this.usersList = data;
    if (this.t_id != 0) {
      this.get_salary_form_employees();
    }
  }
  constructor(
    public ds: DataService,
    public lang: LanguagePipe,
    private _sweetAlertService: SweetAlertService,
    private spinner: PreloaderService,
    public dialog: MatDialog
  ) {}
  sendDataToParent(value: any) {
    this.UserListSetter.emit(value);
  }

  refreshData() {
    this.resfreshView.emit();
  }
  ngOnInit(): void {
    if (this.t_id != 0) {
      this.get_salary_form_employees();
    }
    /*  if(this.t_id != 0&& this.requestInfo.last_request_step == 'CONFIGURATION'){
      
    }
    */
    this.subscriptions.add(
      this.refresh.subscribe(() => this.get_salary_form_employees())
    );
  }
  logStepper() {}
  responseData;
  get_salary_form_employees() {
    this.spinner.show();
    this.ds
      .getActionByUrl(
        [],
        `form/FORM_SAL/get_salary_form_employees/${this.t_id}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.responseData = res;
            this.usersList = res.records.map((rec, index) => {
              return {
                ...rec,
                details: "",
                employee_name:
                  rec.firstname_english + " " + rec.lastname_english,
                isNew: false,
              };
            });
            this.sendDataToParent(this.usersList);
          } else {
            this.spinner.hide();
            this.usersList = [];

            this._sweetAlertService.errorMessage(res?.error);
            console.log(res?.error);

            this.sendDataToParent(this.usersList);
          }
        },
        (err) => {
          this.spinner.hide();
          this.usersList = [];
          this.sendDataToParent(this.usersList);
          this._sweetAlertService.errorMessage(err?.error.error);
        }
      );
  }
  update_configuration_step() {
    if (this.usersList.length == 0) {
      this._sweetAlertService.errorToast(
        this.lang.transform("lang_need_emp_and_data"),
        2000
      );
      return 0;
    }

    this.spinner.show();
    let formData = new FormData();

    if (this.type == "view") {
      formData.append(`Configration_Step`, "select_reviewers");
    } else {
      formData.append(`Configration_Step`, "select_reviewers");
    }

    this.ds
      .post(`form/FORM_SAL/update_configuration_step/${this.t_id}`, formData)
      .subscribe(
        (data) => {
          if (data.status) {
            this.refreshData();
            if (this.type == "add") {
              this.goForward(this.stepper);
            }
            this.resfreshUsers.emit();

            this.spinner.hide();
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(data.error);
            /*   this.showMsg(data.error, 'ok', 'error-snackbar'); */
          }
        },
        (error) => {
          this._sweetAlertService.errorMessage(error?.error?.error);
          this.spinner.hide();
          /*    this.showMsg(error?.error?.error, 'ok', 'error-snackbar'); */
        }
      );
  }
  setItemByLang(enItem, arItem) {
    if (this.lang_key == "ar") {
      return arItem;
    } else {
      return enItem;
    }
  }
  deleteTask(id: number) {
    Swal.fire({
      title: this.lang.transform("lang_are_you_sure"),
      text: "",
      icon: "warning",
      confirmButtonColor: "#0e3768",
      showCancelButton: true,
      confirmButtonText: this.lang.transform("lang_delete"),
      cancelButtonText: this.lang.transform("lang_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        /* this.spinner.show(); */
        /* Swal.fire(
          this.lang.transform('lang_deleted'),
          this.lang.transform('lang_success'),
          'success'
        ) */
        this.subscriptions.add(
          this.ds
            .delete(
              `form/FORM_SAL/delete_salary_form_employee/${this.t_id}/${id}`
            )
            .subscribe(
              (data) => {
                if (data.status) {
                  Swal.fire(
                    this.lang.transform("lang_deleted"),
                    this.lang.transform("lang_success"),
                    "success"
                  );
                  this.get_salary_form_employees();

                  this.resfreshUsers.emit();
                } else {
                  this._sweetAlertService.errorMessage(data?.error);
                }
                this.spinner.hide();
              },
              (err) => {
                this._sweetAlertService.errorMessage(err?.error.error);
                this.spinner.hide();
              }
            )

          /* this._AdminsService.DeleteAvailabilityById(id).subscribe(
          (res:any) => {

            const creds = this.thirdFormGroup.controls.availability as FormArray;
   
            creds.removeAt(index);
            Swal.fire(
              'Deleted!',
              'Availability has been deleted.',
              'success'
            )
          }) */
        );

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          `${this.lang.transform("lang_cancel")}`,
          `${
            this.lang.transform("lang_cancel") +
            " " +
            this.lang.transform("lang_completed")
          }`,
          "error"
        );
      }
    });
  }
  employee_details(emp_id, index) {
    if (this.usersList[index]?.details) {
      this.makeItemCollapsed(index);
      return 0;
    }
    this.spinner.show();
    this.ds
      .getActionByUrl([], `form/FORM_SAL/employee_details/${emp_id}`)
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            /* this.usersList = res.records.map(rec=>{
          return {
            ...rec,
            details:''
          }
        }) */
            this.makeItemCollapsed(index);
            this.usersList[index].details = res.records[0];
          } else {
            this.spinner.hide();
            /* this.usersList = []; */
          }
        },
        (err) => {
          this.spinner.hide();
          /*       this.usersList = []; */
        }
      );
  }

  makeItemCollapsed(index) {
    this.usersList[index].collapsed = !this.usersList[index].collapsed;
  }
  clearAll() {
    this.usersList = [];
  }
  deleteSingle(id: any, index) {
    this.usersList.splice(index, 1);
  }

  delete_salary_form_employee(emp_id: number) {
    if (this.usersList.length > 0) {
      this.spinner.show();
      this.ds
        .delete(
          `form/FORM_SAL/delete_salary_form_employee/${this.t_id}/${emp_id}`
        )
        .subscribe(
          (data) => {
            if (data.status) {
              this._sweetAlertService.successToast(data?.msg, 2000);
              this.spinner.hide();
            } else {
              this.spinner.hide();
              this._sweetAlertService.errorMessage(data.error);
            }
          },
          (error) => {
            this._sweetAlertService.errorMessage(error?.error?.error);
            this.spinner.hide();
          }
        );
    } else {
      this._sweetAlertService.warningToast(
        this.lang.transform("lang_select_anyone"),
        2000
      );
    }
  }

  goBack(stepper: MatStepper = this.stepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper = this.stepper) {
    stepper.next();
  }

  userTransactions = [];
  all_financial_transactions(emp_id, info, type) {
    this.spinner.show();
    this.ds
      .getActionByUrl(
        [],
        `form/FORM_SAL/all_financial_transactions/${this.t_id}/${emp_id}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.userTransactions = res.records;

            this.openDeduction(info, type);
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(res.error);
          }
        },
        (err) => {
          this.spinner.hide();
          this._sweetAlertService.errorMessage(err?.error?.error);
        }
      );
  }
  openDeduction(info, type) {
    const dialogRef = this.dialog.open(ShowDeductionsComponent, {
      width: "700px",
      data: {
        info: info,
        userTransactions: this.userTransactions,
        t_id: this.t_id,
        type: type,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.get_salary_form_employees();
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.unsubscribe();
  }
}
