<div class="card">
	<div class="width100">
		<i
			*ngIf="files && files.bank"
			matTooltip="{{ 'lang_recent_attachment' | language }} : {{
				files.bank_d
			}}"
			class="fa fa-paperclip s2icon mnabricon"
			(click)="ds.preview_pdf_file(files.bank)"
		></i>
		<div class="card-header">
			{{ "lang_human_resources_bank_details" | language }}
		</div>
		<div class="card-body pad0" [formGroup]="signupForm">
			<div class="mbrl15" formGroupName="bankDetails">
				<div class="row">
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="account_number"
								>{{ "lang_human_resources_account_number" | language }} :
								<span class="red">*</span></label
							>
							<input
								formControlName="account_number"
								minlength="10"
								maxlength="35"
								id="account_number"
								type="text"
								placeholder="{{
									'lang_human_resources_account_number' | language
								}}"
								class="form-control almnabrformcontrol account_number"
							/>
							<mat-error
								*ngIf="
									signupForm.get('bankDetails')['controls']['account_number']
										.touched &&
									signupForm.get('bankDetails')['controls']['account_number']
										.errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
							<mat-error
								*ngIf="
									signupForm.get('bankDetails')['controls']['account_number']
										.errors?.minlength
								"
							>
								{{ "lang_minimum" | language }} 10
							</mat-error>
							<mat-error
								*ngIf="
									signupForm.get('bankDetails')['controls']['account_number']
										.errors?.maxlength
								"
							>
								{{ "lang_maximum" | language }} 35
							</mat-error>
						</div>
					</div>
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="bank_short_code"
								>{{ "lang_bank" | language }} :
								<span class="red">*</span></label
							>
							<ng-select
								[items]="banklists"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
								clearAllText="Clear"
								placeholder="{{ 'lang_bank' | language }}"
								formControlName="bank_short_code"
							>
							</ng-select>
							<mat-error
								*ngIf="
									signupForm.get('bankDetails')['controls']['bank_short_code']
										.touched &&
									signupForm.get('bankDetails')['controls']['bank_short_code']
										.errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="bank_file_name"
								>{{ "lang_human_resources_filename" | language }} :
								<span class="red" *ngIf="action !== 'EDIT'">*</span></label
							>
							<input
								formControlName="bank_file_name"
								id="bank_file_name"
								type="text"
								placeholder="{{ 'lang_human_resources_filename' | language }}"
								class="form-control almnabrformcontrol bank_file_name"
							/>
							<mat-error
								*ngIf="
									signupForm.get('bankDetails')['controls']['bank_file_name']
										.touched &&
									signupForm.get('bankDetails')['controls']['bank_file_name']
										.errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<div *ngIf="!isFile && action !== 'EDIT'" for="bank_file">
								<i class="red">*</i>
							</div>
							<div *ngIf="!isFile && action == 'EDIT'" class="opacity0">
								<i class="red">*</i>
							</div>
							<div *ngIf="isFile" for="bank_file">
								<i
									aria-hidden="true"
									(click)="removeFile()"
									class="fa faicon fa-close red"
								></i>
							</div>
							<div class="IDDIV">
								<input
									name="bank_file"
									type="file"
									(change)="uploadIDFile($event)"
									onclick="this.value = null"
									class="nghide width100 albutton"
									accept=".pdf"
								/>
								<label id="uploadIDButton" class="albutton"
									>{{ idfilename }}
								</label>
							</div>
							<mat-error
								*ngIf="
									signupForm.get('bankDetails')['controls']['bank_file']
										.touched &&
									signupForm.get('bankDetails')['controls']['bank_file'].errors
										?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer padb0">
				<div class="row">
					<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
						<button type="button" class="albutton" matStepperPrevious>
							{{ "lang_previous" | language }}
						</button>
					</div>
					<div class="col-md-8 col-sm-6 col-xs-12 mb-10"></div>
					<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
						<button
							type="button"
							class="albutton"
							matStepperNext
							(click)="rstep5Submitted()"
						>
							{{ "lang_next" | language }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
