<breadcrumb></breadcrumb>
<section class="panel-expansion projects" [@fadeIn]>
	{{ "lang_list_of_projects" | language }}
	<div class="card mt-2 mb-2">
		<div class="material-panel-body">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-4 col-xs-12">
						<div class="form-group">
							<ng-select
								[items]="branches"
								[multiple]="true"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
								clearAllText="Clear"
								placeholder="{{ 'lang_branch' | language }}"
								[(ngModel)]="branchItems"
								(change)="spinner.show(); search_projects()"
							>
							</ng-select>
						</div>
					</div>
					<div class="col-md-4 col-xs-12">
						<div class="form-group">
							<ng-select
								[items]="services"
								[multiple]="true"
								bindLabel="label"
								bindValue="value"
								clearAllText="Clear"
								appendTo="body"
								placeholder="{{ 'lang_services' | language }}"
								[(ngModel)]="serviceItems"
								(change)="spinner.show(); search_projects()"
							>
							</ng-select>
						</div>
					</div>
					<div class="col-md-4 col-xs-12">
						<div class="form-group">
							<input
								type="text"
								class="form-control almnabrformcontrol search_key"
								id="search_key"
								[(ngModel)]="searchKey"
								(keyup)="search_projects()"
								placeholder="{{ 'lang_search' | language }}"
							/>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="table-responsive dropdownyes customResponsive">
							<table border="1" class="table CustomTable">
								<thead>
									<tr>
										<td>#</td>
										<td>
											<div
												class="checkbox"
												matTooltip="{{ 'lang_check_all' | language }}"
											>
												<input
													type="checkbox"
													class="allItems checkbox"
													id="allItems"
													(click)="checkAllItems($event)"
												/>
												<label for="checkbox"></label>
											</div>
										</td>
										<td>{{ "lang_project_number" | language }}</td>
										<td>{{ "lang_project_title" | language }}</td>
										<td>{{ "lang_branch" | language }}</td>
										<td>{{ "lang_customers" | language }}</td>
										<td>{{ "lang_customer_types" | language }}</td>
										<td>{{ "lang_ondate" | language }}</td>
										<td>{{ "lang_writer" | language }}</td>
										<td>{{ "lang_action" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="wholeData.length != '0'" class="AppendList">
									<tr
										*ngFor="
											let list of wholeData?.records;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
										class="makeback removerow{{ list.projects_profile_id }}"
									>
										<td>{{ i + 1 }}</td>
										<td>
											<div
												class="checkbox"
												matTooltip="{{ 'lang_check' | language }}"
											>
												<input
													type="checkbox"
													class="eachItem checkbox"
													value="{{ list.projects_profile_id }}"
													(click)="checkEachItem($event)"
												/>
												<label for="checkbox"></label>
											</div>
										</td>
										<td>
											<a
												routerLink="/projects/LtYh/{{
													list.projects_profile_id
												}}"
												matTooltip="{{ 'lang_view' | language }}"
												>{{ list.projects_profile_id }}</a
											>
										</td>
										<td>
											<a
												routerLink="/projects/LtYh/{{
													list.projects_profile_id
												}}"
												matTooltip="{{ list.project_title }}"
												>{{ ds.splitwords(list.project_title, 3) }}</a
											>
										</td>
										<td>
											<a
												routerLink="/projects/LtYh/{{
													list.projects_profile_id
												}}"
												matTooltip="{{ 'lang_view' | language }}"
												>{{ list.branch_name }}</a
											>
										</td>
										<td>
											<a
												routerLink="/projects/LtYh/{{
													list.projects_profile_id
												}}"
												matTooltip="{{ 'lang_view' | language }}"
												>{{ list.customer_name }}</a
											>
										</td>
										<td>
											<a
												routerLink="/projects/LtYh/{{
													list.projects_profile_id
												}}"
												matTooltip="{{ 'lang_view' | language }}"
												>{{ list.customer_type }}</a
											>
										</td>
										<td>{{ list.projects_profile_created_datetime }}</td>
										<td>{{ list.writer }}</td>
										<td>
											<mat-icon
												routerLink="/projects/LtYh/{{
													list.projects_profile_id
												}}"
												matTooltip="{{ 'lang_view' | language }}"
												class="mnabricon"
												>remove_red_eye</mat-icon
											>
											<mat-icon
												*ngIf="isAdmin"
												routerLink="/projects/NxBf/{{
													list.projects_profile_id
												}}"
												matTooltip="{{ 'lang_edit' | language }}"
												class="mnabricon"
												>edit</mat-icon
											>
										</td>
									</tr>
								</tbody>
								<tbody *ngIf="wholeData.length == '0'" class="AppendList">
									<tr class="odd">
										<td colspan="10" align="center" class="nodata">
											{{ nodata }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="wholeData?.records?.length > '0'" class="card-footer padb0">
				<div class="row responsive_pagination">
					<div class="col-lg-1 col-sm-1  mb-10">
						<i
							*ngIf="isAdmin"
							class="deleteIconDiv none material-icons red deleteIcon"
							(click)="deleteBulkData()"
							aria-hidden="true"
							matTooltip="{{ 'lang_delete' | language }}"
							>delete_forever</i
						>
					</div>
					<div class="col-lg-3 col-sm-3  mb-10" style="height: fit-content;margin: auto 0;">
						{{ "lang_total" | language }} : {{ wholedata?.page?.total_records }}
					</div>
					<div class="col-lg-5 col-sm-5 mb-10" style="display: flex;justify-content: center;" >
						<ngb-pagination class="m-auto" [collectionSize]="wholeData?.page?.total_records" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="pageno" [pageSize]="pagesize" (pageChange)="spinner.show(); loadpage($event)"></ngb-pagination>
					</div>
					<div class="col-lg-3 col-sm-3" style="display: flex;justify-content: end;margin: auto;">
						<mat-select  style="max-width: 150px;"  [(ngModel)]="pagesize" (selectionChange)="spinner.show(); loadpagesize($event.value)">
							<mat-option class="text-center" value="10">10 {{'lang_per_page'|language}} </mat-option>
							<mat-option class="text-center" value="20">20 {{'lang_per_page'|language}}  </mat-option>
							<mat-option class="text-center" value="50">50 {{'lang_per_page'|language}}  </mat-option>
							<mat-option class="text-center" value="100">100 {{'lang_per_page'|language}}</mat-option>
							<mat-option class="text-center" value="500">500 {{'lang_per_page'|language}}</mat-option>
						</mat-select>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
