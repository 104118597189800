import { Component, Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter, NgbDateAdapter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
const WEEKDAYS = ['ن', 'ث', 'ر', 'خ', 'ج', 'س', 'ح'];
const MONTHS = ['محرم', 'صفر', 'ربيع الأول', 'ربيع الآخر', 'جمادى الأولى', 'جمادى الآخرة', 'رجب', 'شعبان', 'رمضان', 'شوال',
  'ذو القعدة', 'ذو الحجة'];
@Injectable()
export class IslamicI18n extends NgbDatepickerI18n {
  getWeekdayShortName(weekday: number) {
    return WEEKDAYS[weekday - 1];
  }
  getMonthShortName(month: number) {
    return MONTHS[month - 1];
  }
  getMonthFullName(month: number) {
    return MONTHS[month - 1];
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.year}/${date.month}/${date.day}`;
  }
}
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  constructor(public datepipe: DatePipe){
    super();
  }
  readonly DELIMITER = '/';
  fromModel(value: string | null): NgbDateStruct | null {
    if (value && value.length !== undefined) {
      if (value.length >= 12) {
        const ss= this.datepipe.transform(value, 'dd/MM/yyyy');
        let date = ss.split(this.DELIMITER);
        return {
          day: parseInt(date[0]),
          month: parseInt(date[1]),
          year: parseInt(date[2])
        };
      } else {
        let date = value.split(this.DELIMITER);
        return {
          day: parseInt(date[0]),
          month: parseInt(date[1]),
          year: parseInt(date[2])
        };
      }
    }
    return null;
  }
  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? `${isNumber(date.day) ? padNumber(date.day) : ""}/${isNumber(date.month) ? padNumber(date.month) : ""}/${
      date.year
      }`
      : "";
  }
  format(date: NgbDateStruct | null): string {
    return date
      ? `${isNumber(date.day) ? padNumber(date.day) : ""}/${isNumber(date.month) ? padNumber(date.month) : ""}/${
      date.year
      }`
      : "";
  }
}
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';
  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }
  format(date: NgbDateStruct | null): string {
    return date
      ? `${date.year}/${isNumber(date.month) ? padNumber(date.month) : ""}/${isNumber(date.day) ? padNumber(date.day) : ""}`
      : "";
  }
}
export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}
export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}
export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return "";
  }
}