import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-form-acp-edit',
  templateUrl: './form-acp-edit.component.html',
  styleUrls: ['./form-acp-edit.component.scss']
})
export class FormAcpEditComponent implements OnInit {
  @Input() form_request_id;
  @Input() form_key;
  @Input() branch_id;
  form: FormGroup;
  @Output() actionChanges   = new EventEmitter<any>();
  branches: any             = [];
  accountData: any          = [];
  costData: any             = [];
  users:any                 = [];
  myDate: any               = new Date();
  showfield: boolean        = false;
  filename: any             = this.lang.transform('lang_select_files');
  isFile: boolean           = false;
  lang_key                  = localStorage.getItem('lang_key') || 'en';
  markstatus:boolean        = false;
  reviewstatus:boolean      = false;
  submitted:boolean         = false;
  constructor(public ds:DataService,public datePipe: DatePipe,public router : Router, public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.myDate = new Date(this.datePipe.transform(this.myDate, 'yyyy-MM-dd'));
    this.get_data_edit();
    this.buildform();
    this.formControlValueChanged();
  }
  public get_data_edit() {
    this.spinner.show();
    this.ds.getActionByUrl([], 'form/' + this.form_key + '/vr/' + this.form_request_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.accountData  = [
          {
            label : res.form_acp_data.records.debit_account,
            value : res.form_acp_data.records.payment_receipt_debit_account_id
          },
          {
            label : res.form_acp_data.records.credit_account,
            value : res.form_acp_data.records.payment_receipt_credit_account_id
          }
        ];
        this.costData     = [
          {
            label : res.form_acp_data.records.debit_cost,
            value : res.form_acp_data.records.payment_receipt_debit_cost_id
          },
          {
            label : res.form_acp_data.records.credit_cost,
            value : res.form_acp_data.records.payment_receipt_credit_cost_id
          }
        ];
        this.form.patchValue({
          branch_id                                 : res.form_acp_data.records.branch_id,
          subject                                   : res.form_acp_data.records.subject,
          transaction_from                          : res.form_acp_data.records.transaction_from_name,
          transaction_to                            : res.form_acp_data.records.branch_id,
          payment_receipt_date                      : this.ds.encon(res.form_acp_data.records.payment_receipt_date),
          payment_receipt_to_from                   : res.form_acp_data.records.payment_receipt_to_from,
          payment_receipt_mode                      : res.form_acp_data.records.payment_receipt_mode,
          payment_receipt_debit_account_id          : res.form_acp_data.records.payment_receipt_debit_account_id,
          payment_receipt_debit_cost_id             : res.form_acp_data.records.payment_receipt_debit_cost_id,
          payment_receipt_credit_account_id         : res.form_acp_data.records.payment_receipt_credit_account_id,
          payment_receipt_credit_cost_id            : res.form_acp_data.records.payment_receipt_credit_cost_id,
          payment_receipt_amount                    : res.form_acp_data.records.payment_receipt_amount,
          payment_receipt_document_number           : res.form_acp_data.records.payment_receipt_document_number,
          payment_receipt_document_date             : this.ds.encon(res.form_acp_data.records.payment_receipt_document_date),
          payment_receipt_bank_name                 : res.form_acp_data.records.payment_receipt_bank_name,
          payment_receipt_notes                     : res.form_acp_data.records.payment_receipt_notes,
          payment_receipt_description               : res.form_acp_data.records.payment_receipt_description,
        });
        let defaultusers = [];
				if (res.transactions_persons && res.transactions_persons.status) {
					let markers = [];
					let reviewers = [];
					res.transactions_persons.records.forEach((v, k) => {
						defaultusers.push({
							value: v.user_id,
							label: v.person_name
						});
						//signature users
						if (v.transaction_persons_type === "signature") {
							if (v.transactions_persons_val1 === "A" && v.transactions_persons_val2 && v.user_id && v.person_name) {
                this.form.get('signature[A][title]').setValue(v.transactions_persons_val2);
                this.form.get('signature[A][user_id]').setValue(v.user_id);
							}
							if (v.transactions_persons_val1 === "B" && v.transactions_persons_val2 && v.user_id && v.person_name) {
                this.form.get('signature[B][title]').setValue(v.transactions_persons_val2);
                this.form.get('signature[B][user_id]').setValue(v.user_id);
							}
							if (v.transactions_persons_val1 === "C" && v.transactions_persons_val2 && v.user_id && v.person_name) {
                this.form.get('signature[C][title]').setValue(v.transactions_persons_val2);
                this.form.get('signature[C][user_id]').setValue(v.user_id);
							}
						}
						//markers
						if (v.transaction_persons_type === "marks") {
							this.markstatus = true;
              this.form.get('needMark').setValue('1');
							markers.push(v.user_id);
						}
						//reviewers
						if (v.transaction_persons_type === "reviews") {
              this.reviewstatus = true;
              this.form.get('needReview').setValue('1');
							reviewers.push(v.user_id);
						}
					});
					if (markers.length > 0) {
						this.form.get('marks').setValidators(Validators.required);
						this.form.get('marks').updateValueAndValidity();
					}
					if (reviewers.length > 0) {
						this.form.get('reviews').setValidators(Validators.required);
						this.form.get('reviews').updateValueAndValidity();
          }
          this.form.get('marks').setValue(markers);
          this.form.get('reviews').setValue(reviewers);
				}
				this.users = defaultusers;
      } else {
        this.ds.dialogf('',res.error);
        this.router.navigate(['transactions/allforms/']);
      }
    }, error => {
        this.spinner.hide();
        this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id                             : new FormControl(this.branch_id, [Validators.required]),
      payment_receipt_date                  : new FormControl(this.myDate, [Validators.required]),
      payment_receipt_to_from               : new FormControl('', [Validators.required]),
      payment_receipt_mode                  : new FormControl('', [Validators.required]),
      payment_receipt_debit_account_id      : new FormControl(null, [Validators.required]),
      payment_receipt_debit_cost_id         : new FormControl(null),
      payment_receipt_credit_account_id     : new FormControl(null, [Validators.required]),
      payment_receipt_credit_cost_id        : new FormControl(null),
      payment_receipt_amount                : new FormControl('', [Validators.required]),
      payment_receipt_document_number       : new FormControl(''),
      payment_receipt_document_date         : new FormControl(''),
      payment_receipt_bank_name             : new FormControl(''),
      payment_receipt_notes                 : new FormControl(''),
      payment_receipt_description           : new FormControl('', [Validators.required]),
      payment_receipt_attachment            : new FormControl(''),
      subject                               : new FormControl(this.lang.transform('lang_payments'), [Validators.required]),
      transaction_from                      : new FormControl(this.branch_id, [Validators.required]),
      transaction_to                        : new FormControl(this.branch_id, [Validators.required]),
      'signature[A][title]'                 : new FormControl('', [Validators.required]),
      'signature[A][user_id]'               : new FormControl(null, [Validators.required]),
      'signature[B][title]'                 : new FormControl('', [Validators.required]),
      'signature[B][user_id]'               : new FormControl(null, [Validators.required]),
      'signature[C][title]'                 : new FormControl('', [Validators.required]),
      'signature[C][user_id]'               : new FormControl(null, [Validators.required]),
      needMark                              : new FormControl('0', [Validators.required]),
      marks                                 : new FormControl(null),
      needReview                            : new FormControl('0', [Validators.required]),
      reviews                               : new FormControl(null),
    });
  }
  public formControlValueChanged() {
    const payment_receipt_document_number   = this.form.get('payment_receipt_document_number');
    const payment_receipt_document_date     = this.form.get('payment_receipt_document_date');
    const payment_receipt_bank_name         = this.form.get('payment_receipt_bank_name');
    this.form.get('payment_receipt_mode').valueChanges.subscribe(
      (mode: string) => {
        this.showfield = false;
        if (mode !== 'cash') {
          this.showfield = true;
          payment_receipt_document_number.setValidators([Validators.required]);
          payment_receipt_document_date.setValidators([Validators.required]);
          payment_receipt_bank_name.setValidators([Validators.required]);
        } else {
          payment_receipt_document_number.clearValidators();
          payment_receipt_document_date.clearValidators();
          payment_receipt_bank_name.clearValidators();
        }
        payment_receipt_document_number.updateValueAndValidity();
        payment_receipt_document_date.updateValueAndValidity();
        payment_receipt_bank_name.updateValueAndValidity();
        payment_receipt_document_number.setValue('');
        payment_receipt_document_date.setValue('');
        payment_receipt_bank_name.setValue('');
        this.removeFile();
      });
  }
  public uploadFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.isFile = true;
      this.form.get("payment_receipt_attachment").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.form.get("payment_receipt_attachment").setValue('');
      this.filename = this.lang.transform('lang_select_files');
    }
  }
  public removeFile() {
    this.isFile     = false;
    this.form.get("payment_receipt_attachment").setValue('');
    this.filename   = this.lang.transform('lang_select_files');
  }
  public searchAccounts(key) {
    this.accountData = [];
    let param = new FormData();
    param.append('branch_id',   this.form.get('branch_id').value);
    param.append('search_text', key.term);
    if(this.form.get('branch_id').value && key.term && key.term != undefined) {
      this.ds.postActionByUrl(param, "form/FORM_ACP/search_account_master").subscribe(data => {
        if (data.status) {
          this.accountData = data.records;
        }
      });
    }
  }
  public searchCosts(key) {
    this.costData   = [];
    let param       = new FormData();
    param.append('branch_id', this.form.get('branch_id').value);
    param.append('search_text', key.term);
    if(this.form.get('branch_id').value && key.term && key.term != undefined) {
      this.ds.postActionByUrl(param, 'form/FORM_ACP/search_cost_center').subscribe(res => {
        if (res.status) {
          this.costData = res.records;
        }
      });
    }
  }
  public search_users(key) {
		this.users = [];
    if(key && key.term) {
      let param = new FormData();
      param.append('search', key.term);
      param.append('lang_key', this.lang_key);
      param.append('user_type_id', '1');
      this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      })
    }
	}
  public enable_field(field) {
		if (field == 'mark') {
      this.form.get('needMark').setValue('1');
		  this.form.get('marks').setValidators(Validators.required);
		  this.form.get('marks').updateValueAndValidity();
		  this.markstatus = true;
		} else if (field == 'review') {
      this.form.get('needReview').setValue('1');
		  this.form.get('reviews').setValidators(Validators.required);
		  this.form.get('reviews').updateValueAndValidity();
		  this.reviewstatus = true;
		}
    this.form.updateValueAndValidity();
	}
	public disable_field(field) {
		if (field == 'mark') {
      this.form.get('needMark').setValue('0');
			this.form.get('marks').setValue(null);
			this.form.get('marks').clearValidators();
			this.form.get('marks').updateValueAndValidity();
			this.markstatus = false;
		} else if (field == 'review') {
      this.form.get('needReview').setValue('0');
			this.form.get('reviews').setValue(null);
			this.form.get('reviews').clearValidators();
			this.form.get('reviews').updateValueAndValidity();
			this.reviewstatus = false;
		}
		this.form.updateValueAndValidity();
  }
  public update_payment() {
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'form/FORM_ACP/et/'+this.form_request_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.form.reset();
        this.alert.success(res.msg);
        this.form.reset();
        setTimeout(() => {
          this.router.navigate(['transactions/form/' + this.form_key + '/vr/' + this.form_request_id]);
        }, 1000);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }
}
