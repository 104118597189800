import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { UnitLevelShowComponent } from "@shared/components/unit-level-show/unit-level-show.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-salary-file-upload",
	templateUrl: "./salary-file-upload.component.html",
	styleUrls: ["./salary-file-upload.component.scss"],
})
export class SalaryFileUploadComponent implements OnInit {
	project_data;
	t_id;
	history_files = [];
	history_files_ED = [];
	loading_attachments = false;
	page = 1;
	size = 10;
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		private sweetAlert: SweetAlertService,
		public router: Router,
		private dialogRef: MatDialogRef<UnitLevelShowComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.t_id = data?.t_id;
		this.project_data = data?.project_data;
	}

	ngOnInit(): void {}

	responseInfo = "";

	download_ED_history(item) {
		this.spinner.show();
		this.ds.get2(item?.url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.ds.downloadFile(res, item.file_name);
				} else {
					this.sweetAlert.errorMessage(res?.error);
				}
			},
			(err) => {
				this.sweetAlert.errorMessage(err?.error.error);
				this.spinner.hide();
			}
		);
	}
	employee_details(emp_id, index) {
		/*   if(this.response[index].details){
    
      return 0;
    } */

		this.makeItemCollapsed(index);
	}

	makeItemCollapsed(index) {
		this.response[index].collapsed = !this.response[index].collapsed;
	}
	response: any = [];
	upload_i_phases(e) {
		if (e && e?.length) {
			let formData = new FormData();
			formData.append("attachments[1][file]", e[0] || "");
			formData.append("attachments[1][attach_title]", "file-txt");
			this.spinner.show();
			this.ds
				.post(`form/FORM_SAL/upload_file_and_review/${this.t_id}`, formData)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							/*  this.response = res.records.map(r=>{
            return {
              ...r,
              "collapsed":false,
            }
          })  */

							this.response = res.records.map((r) => {
								return {
									...r,
									collapsed: false,
								};
							});
							/*  this.sweetAlert.successMessage(res?.msg); */
							/* this.dialogRef.close({status:true,data:res}) */
						} else {
							this.sweetAlert.errorMessage(res?.error);
						}
					},
					(err) => {
						this.sweetAlert.errorMessage(
							this.lang.transform("lang_internal_server_error")
						);
						this.spinner.hide();
					}
				);
		}
		document.getElementById("uploadAttendance")["value"] = "";
	}

	confirm_file() {
		this.spinner.show();
		this.ds.get(`form/FORM_SAL/confirm_file/${this.t_id}`).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					/*  this.sweetAlert.successMessage(res?.msg); */
					this.dialogRef.close(true);
				} else {
					this.sweetAlert.errorMessage(res?.error);
				}
			},
			(err) => {
				this.sweetAlert.errorMessage(
					this.lang.transform("lang_internal_server_error")
				);
				this.spinner.hide();
			}
		);
	}

	closeModal() {
		this.dialogRef.close(true);
	}
}
