import {
	Component,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
	ITreeOptions,
	TreeComponent,
	TreeNode,
} from "@circlon/angular-tree-component";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { NgSelectComponent } from "@ng-select/ng-select";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TemplateDialogComponent } from "../supervision-my-transctions/template-dialog/template-dialog.component";
import { ZoneDialogComponent } from "../supervision-my-transctions/zone-dialog/zone-dialog.component";
import { IphasesDialogComponent } from "./iphases-dialog/iphases-dialog.component";
import * as echarts from "echarts";
import { CompeletionFiltersComponent } from "./compeletion-filters/compeletion-filters.component";

@Component({
	selector: "app-supervision-i-phases",
	templateUrl: "./supervision-i-phases.component.html",
	styleUrls: ["./supervision-i-phases.component.scss"],
})
export class SupervisionIPhasesComponent implements OnInit {
	modalRef: BsModalRef;
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}
	totalStepsCount: number;
	@Input() supervision_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() makeAction;
	@Input() project_data;
	@ViewChild(TreeComponent)
	private tree: TreeComponent;
	isAdmin = JSON.parse(this.ls.getItem("is_admin"));
	viewtype: string = "VIEW";
	lang_key = localStorage.getItem("lang_key");
	asyncChildren = [];
	options: ITreeOptions = {
		getChildren: (node: TreeNode) => {
			return [];
		},
		useCheckbox: false,
		rtl: this.lang_key == "ar" ? true : false,
	};
	phase_parent_id: any = "0";
	viewPhaseDataStatus: boolean = false;
	viewPhaseData: any = [];
	nodes: any = [];
	phaseData: any = [];
	treePath = "";
	hideTreeContent = false;
	hideTreeMenu = false;
	selectedNode = null;
	selectedTab = "unit_dashboard";
	//requests
	showFilter = false;
	showSort = false;
	isMob = false;
	showCustomTitles = true;
	TransactionsData = [];
	pages: any = "pages";
	page_no = 1;
	page_size = 10;
	collection_size = 0;
	paginationMaxSize = 3;
	showExel = false;
	filters_params = {
		transaction_start_date: null,
		transaction_end_date: null,
		form_request_id: null,
		template: null,
		group_type: null,
		group1: null,
		group2: null,
		platform_code_system: null,
		zone: null,
		block: null,
		cluster: null,
		phase_short_code: null,
		unit_id: null,
		level_key: null,
		barcode: null,
		result_code: null,
		version: "final_completed_versions",
	};
	sort_params = {
		form_request_id: null,
		template_id: null,
		platform_code_system: null,
		zone: null,
		block: null,
		cluster: null,
		barcode: null,
	};
	levels_arr = [];
	filters_saved: any;
	sort_saved: any;
	@ViewChild("filterSelect", { static: false }) filterSelect: NgSelectComponent;
	template = [];
	group_type = [];
	group1 = [];
	group2 = [];
	zone = [];
	block = [];
	cluster = [];
	filterList = [];
	filter_name: any;
	selectedFilter: any;
	filterForm: FormGroup;
	result_code_arr = [
		{
			label: "A",
			value: "A",
		},
		{
			label: "B",
			value: "B",
		},
		{
			label: "C",
			value: "C",
		},
		{
			label: "D",
			value: "D",
		},
		{
			label: "E",
			value: "E",
		},
		{
			label: "OPEN",
			value: "OPEN",
		},
		{
			label: "CLOSE",
			value: "CLOSE",
		},
	];

	status_arr = [
		{
			label: `${this.lang.transform("lang_completed")}`,
			value: "final_completed_versions",
		},
		{
			label: `${this.lang.transform("lang_all_completed_versions")}`,
			value: "all_completed_versions",
		},
		{
			label: `${this.lang.transform("lang_pending")}`,
			value: "all_pending_versions",
		},
	];
	//sort
	@ViewChild("filterSelectAtsort", { static: false })
	filterSelectAtsort: NgSelectComponent;
	sortForm: FormGroup = this.fb.group({
		form_request_id: null,
		template_id: null,
		platform_code_system: null,
		zone: null,
		block: null,
		cluster: null,
		barcode: null,
	});
	form_request_id_arr = [
		{
			label: "ASC",
			value: "ASC",
		},
		{
			label: "DESC",
			value: "DESC",
		},
	];
	template_id_arr = [
		{
			label: "ASC",
			value: "ASC",
		},
		{
			label: "DESC",
			value: "DESC",
		},
	];
	platform_code_system_arr = [
		{
			label: "ASC",
			value: "ASC",
		},
		{
			label: "DESC",
			value: "DESC",
		},
	];
	zone_arr = [
		{
			label: "ASC",
			value: "ASC",
		},
		{
			label: "DESC",
			value: "DESC",
		},
	];
	block_arr = [
		{
			label: "ASC",
			value: "ASC",
		},
		{
			label: "DESC",
			value: "DESC",
		},
	];
	cluster_arr = [
		{
			label: "ASC",
			value: "ASC",
		},
		{
			label: "DESC",
			value: "DESC",
		},
	];
	barcode_arr = [
		{
			label: "ASC",
			value: "ASC",
		},
		{
			label: "DESC",
			value: "DESC",
		},
	];
	count_data_loading = true;
	is_all_price = false;
	unit_details_data;
	formsChart: any;
	averageDivisionChart: any;
	wir_data = {};
	completion_filters = {
		from_date: null,
		to_date: null,
		platform_group1_code_system: null,
		with_balance: null,
	};
	from_date_compeletion;
	to_date_compeletion;
	constructor(
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public alert: AlertService,
		public modalService: BsModalService,
		public dialog: MatDialog,
		public fb: FormBuilder
	) {}
	ngOnInit(): void {
		this.get_implementation_phases();
		this.filterForm = this.fb.group({
			transaction_start_date: null,
			transaction_end_date: null,
			form_request_id: null,
			platform_code_system: null,
			zone: null,
			block: null,
			cluster: null,
			phase_short_code: null,
			unit_id: null,
			level_key: null,
			barcode: null,
			result_code: null,
			version: "final_completed_versions",
		});
	}
	ngAfterViewInit(): void {
		window.onresize = () => {
			this.formsChart?.resize();
			this.averageDivisionChart.resize();
		};
	}
	//get child of parent Nodes
	getChildren(node: any) {
		if (!node?.data?.children) {
			if (node?.data?.phase_type == "U") {
				this.sweetAlert.errorMessage(this.lang.transform("lang_no_data"));
			} else {
				this.spinner.show();
				let param = new FormData();
				param.append(
					"phase_type",
					node?.data?.phase_type == "Z"
						? "B"
						: node?.data?.phase_type == "B"
						? "C"
						: "U"
				);
				param.append("phase_parent_id", node.data.phase_id);
				this.ds
					.getActionByUrl(
						this.ds.formData2string(param),
						"joYF29rbEi/" +
							this.supervision_data.projects_profile_id +
							"/" +
							this.supervision_data.projects_supervision_id
					)
					.subscribe((res) => {
						this.spinner.hide();
						if (res.status) {
							node.data.children = res.records;
							this.tree.treeModel.update();
						}
					});
			}
		}
	}

	onToggleTree(e) {
		if (e.isExpanded) {
			this.getChildren(e.node);
		}
	}
	get_implementation_phases(treeModel?) {
		this.spinner.show();
		let param = new FormData();
		param.append("search_key", "");
		param.append("phase_type", "Z");
		param.append("phase_parent_id", this.phase_parent_id);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(param),
				"joYF29rbEi/" +
					this.supervision_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						if (this.phase_parent_id == "0") {
							this.nodes = res.records;
							if (!res?.records[0]?.phase_zone_custom_title) {
								this.showCustomTitles = false;
							}
						} else {
							treeModel.update();
						}
						this.phaseData = res.config;
					} else {
						this.nodes = [];
						this.phaseData = [];
					}
				},
				(error) => {
					this.spinner.hide();
					this.nodes = [];
					this.phaseData = [];
				}
			);
	}
	onChangeCustomTitle() {
		this.getSelectedPath(this.tree);
	}
	getSelectedPath(tree) {
		let ids = tree?.treeModel?.activeNodes[0]?.path;
		let path = "";
		for (let i = 0; i < ids.length; i++) {
			if (i < ids.length - 1) {
				if (tree?.treeModel?.getNodeById(ids[i])?.data?.phase_type == "Z") {
					let title = "";
					if (this.showCustomTitles) {
						title =
							tree?.treeModel?.getNodeById(ids[i])?.data
								?.phase_zone_custom_title || "---";
					} else {
						title = tree?.treeModel?.getNodeById(ids[i])?.data?.name || "---";
					}
					path =
						path +
						title +
						`<i class="fa fa-chevron-${
							this.lang_key == "ar" ? "left" : "right"
						} path-arrow"></i>`;
				} else if (
					tree?.treeModel?.getNodeById(ids[i])?.data?.phase_type == "B"
				) {
					let title = "";
					if (this.showCustomTitles) {
						title =
							tree?.treeModel?.getNodeById(ids[i])?.data
								?.phase_block_custom_title || "---";
					} else {
						title = tree?.treeModel?.getNodeById(ids[i])?.data?.name || "---";
					}
					path =
						path +
						title +
						`<i class="fa fa-chevron-${
							this.lang_key == "ar" ? "left" : "right"
						} path-arrow"></i>`;
				} else if (
					tree?.treeModel?.getNodeById(ids[i])?.data?.phase_type == "C"
				) {
					let title = "";
					if (this.showCustomTitles) {
						title =
							tree?.treeModel?.getNodeById(ids[i])?.data
								?.phase_cluster_custom_title || "---";
					} else {
						title = tree?.treeModel?.getNodeById(ids[i])?.data?.name || "---";
					}
					path =
						path +
						title +
						`<i class="fa fa-chevron-${
							this.lang_key == "ar" ? "left" : "right"
						} path-arrow"></i>`;
				} else if (
					tree?.treeModel?.getNodeById(ids[i])?.data?.phase_type == "U"
				) {
					let title = "";
					if (this.showCustomTitles) {
						title =
							tree?.treeModel?.getNodeById(ids[i])?.data
								?.phase_general_no_custom_title || "---";
					} else {
						title = tree?.treeModel?.getNodeById(ids[i])?.data?.name || "---";
					}
					path =
						path +
						title +
						`<i class="fa fa-chevron-${
							this.lang_key == "ar" ? "left" : "right"
						} path-arrow"></i>`;
				}
			} else {
				if (tree?.treeModel?.getNodeById(ids[i])?.data?.phase_type == "Z") {
					let title = "";
					if (this.showCustomTitles) {
						title =
							tree?.treeModel?.getNodeById(ids[i])?.data
								?.phase_zone_custom_title || "---";
					} else {
						title = tree?.treeModel?.getNodeById(ids[i])?.data?.name || "---";
					}
					path = path + title;
				} else if (
					tree?.treeModel?.getNodeById(ids[i])?.data?.phase_type == "B"
				) {
					let title = "";
					if (this.showCustomTitles) {
						title =
							tree?.treeModel?.getNodeById(ids[i])?.data
								?.phase_block_custom_title || "---";
					} else {
						title = tree?.treeModel?.getNodeById(ids[i])?.data?.name || "---";
					}
					path = path + title;
				} else if (
					tree?.treeModel?.getNodeById(ids[i])?.data?.phase_type == "C"
				) {
					let title = "";
					if (this.showCustomTitles) {
						title =
							tree?.treeModel?.getNodeById(ids[i])?.data
								?.phase_cluster_custom_title || "---";
					} else {
						title = tree?.treeModel?.getNodeById(ids[i])?.data?.name || "---";
					}
					path = path + title;
				} else if (
					tree?.treeModel?.getNodeById(ids[i])?.data?.phase_type == "U"
				) {
					let title = "";
					if (this.showCustomTitles) {
						title =
							tree?.treeModel?.getNodeById(ids[i])?.data
								?.phase_general_no_custom_title || "---";
					} else {
						title = tree?.treeModel?.getNodeById(ids[i])?.data?.name || "---";
					}
					path = path + title;
				}
				this.selectedNode = tree?.treeModel?.getNodeById(ids[i]);
			}
		}
		this.treePath = path;
	}
	public toggleViewAction(tree: any) {
		this.getSelectedPath(tree);
		if (tree?.treeModel?.activeNodes.length <= 0) {
			this.hideTreeContent = true;
		} else {
			this.hideTreeContent = false;
			if (
				this.selectedTab == "unit_dashboard" &&
				this.selectedNode?.data?.phase_type == "U"
			) {
				setTimeout(() => {
					this.initFormsChart();
					this.initAverageDivisionChart();
				});
				this.getUnitDashboard();
				this.getAverageData();
			} else if (
				this.selectedTab == "unit_requests" &&
				this.selectedNode?.data?.phase_type == "U"
			) {
				this.getTransactionsData(this.filters_params, this.sort_params, false);
			}
		}
	}
	deactivate(e) {
		if (e?.treeModel?.activeNodes?.length < 1) {
			this.hideTreeContent = true;
		} else {
			this.hideTreeContent = false;
		}
	}
	onHideTreeMenu(status) {
		this.hideTreeMenu = status;
		setTimeout(() => {
			this.tree?.treeModel
				?.getNodeById(this.selectedNode?.data?.id)
				.setActiveAndVisible();
			this.formsChart?.resize();
			this.averageDivisionChart?.resize();
		});
	}
	public clear(tree) {
		tree.collapseAll();
		this.viewPhaseDataStatus = false;
		if (tree) {
			tree.clearFilter();
			if (tree.getFocusedNode()?.isActive) {
				tree.getFocusedNode().toggleActivated();
			}
		}
	}

	selectedListTabValue(e) {
		if (e?.tab?.textLabel == "unit_dashboard") {
			this.selectedTab = "unit_dashboard";
			this.getUnitDashboard();
			this.getAverageData();
		} else if (e?.tab?.textLabel == "unit_requests") {
			this.selectedTab = "unit_requests";
			if (window.innerWidth < 600) {
				this.isMob = true;
				this.paginationMaxSize = 1;
			} else {
				this.isMob = false;
			}
			this.getWorkLevels();
			this.getFilterList();
			this.getTransactionsData(this.filters_params, this.sort_params, false);
		}
	}
	// unit dashboard
	getUnitDashboard() {
		this.spinner.show();
		this.ds
			.get(
				`pr/dashboard_qtp_unit/${this.supervision_data?.projects_work_area_id}/${this.supervision_data?.projects_supervision_id}/${this.selectedNode?.data?.phase_zone_block_cluster_g_no}`
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res?.status) {
					this.wir_data = res?.result?.unit_wir_data;
					this.updateFormsChart({ WIR: this.wir_data });
				}
			});
	}
	getAverageData(data?, filter?) {
		this.spinner.show();
		this.count_data_loading = true;
		let formData = new FormData();
		formData.append("from_date", data?.from_date || "");
		formData.append("to_date", data?.to_date || "");
		formData.append(
			"platform_group1_code_system",
			data?.platform_group1_code_system || ""
		);
		this.ds
			.post(
				`pr/dashboard_qtp_unit_details/${this.supervision_data?.projects_work_area_id}/${this.supervision_data?.projects_supervision_id}/${this.selectedNode?.data?.phase_zone_block_cluster_g_no}`,
				formData
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res?.status) {
					this.count_data_loading = false;
					this.is_all_price = res?.is_price_allow;
					this.unit_details_data = res?.result?.unit_details;
					this.from_date_compeletion = res?.result?.dates?.from_date;
					this.to_date_compeletion = res?.result?.dates?.to_date;
					this.updateAverageDivisionChart(
						res?.result?.unit_division_result,
						filter
					);
				}
			});
	}
	openAverageFilters() {
		const dialogRef = this.dialog.open(CompeletionFiltersComponent, {
			width: "80%",
			autoFocus: false,
			data: {
				projects_profile_id: this.project_data?.projects_profile_id,
				projects_supervision_id: this.supervision_data?.projects_supervision_id,
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
				completion_filters: this.completion_filters,
				unit_id: this.selectedNode?.data?.phase_zone_block_cluster_g_no,
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.averageDivisionChart.showLoading();
				this.completion_filters = result;
				this.getAverageData(result, true);
			}
		});
	}

	initFormsChart() {
		//forms chart
		this.formsChart = echarts.init(document.getElementById("forms-chart"));
		this.formsChart.showLoading();
	}
	updateFormsChart(data: any) {
		let formNames = [],
			pending_data = [],
			completed_data = [],
			accepted_data = [],
			rejected_data = [],
			acceptedA_data = [],
			acceptedB_data = [],
			rejectedC_data = [],
			rejectedD_data = [],
			accepted_requests_data = [],
			rejected_requests_data = [];
		formNames = Object.keys(data);
		for (let form of Object.values(data)) {
			pending_data.push(form["total_pending_request"]);
			completed_data.push(form["total_complete_requests"]);
			accepted_data.push(form["total_accepted_result"]);
			rejected_data.push(form["total_rejected_result"]);
			acceptedA_data.push(form["total_accepted_result_A"]);
			acceptedB_data.push(form["total_accepted_result_B"]);
			rejectedC_data.push(form["total_rejected_result_C"]);
			rejectedD_data.push(form["total_rejected_result_D"]);
			accepted_requests_data.push({
				value: form["total_accepted_requests"],
				completed_value: form["total_complete_requests"],
			});
			rejected_requests_data.push({
				value: form["total_rejected_requests"],
				completed_value: form["total_complete_requests"],
			});
		}
		formNames.push("DSR");
		formNames.push("MSR");
		formNames.push("TSR");
		formNames.push("SQR");
		const newOption = {
			tooltip: {
				trigger: "item",
				axisPointer: {
					type: "shadow",
				},
			},
			legend: { show: true, type: "scroll" },
			grid: {
				containLabel: true,
			},
			dataZoom: [
				{
					id: "dataZoomX",
					type: "slider",
					xAxisIndex: [0],
					filterMode: "filter",
				},
				{
					id: "dataZoomY",
					type: "slider",
					yAxisIndex: [0],
					filterMode: "empty",
				},
			],
			xAxis: [
				{
					type: "category",
					data: formNames,
				},
			],
			yAxis: [
				{
					type: "value",
				},
			],
			series: [
				{
					name: this.lang.transform("lang_accepted_requests"),
					type: "bar",
					label: {
						show: true,
					},
					itemStyle: {
						color: "#91ca8c",
					},
					stack: "P_C",
					emphasis: {
						focus: "series",
					},
					data: accepted_requests_data,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data?.value}</span> of ${params?.data?.completed_value}`;
						},
					},
				},
				{
					name: this.lang.transform("lang_rejected_requests"),
					type: "bar",
					label: {
						show: true,
					},
					itemStyle: {
						color: "#b93939",
					},
					stack: "P_C",
					emphasis: {
						focus: "series",
					},
					data: rejected_requests_data,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data?.value}</span> of ${params?.data?.completed_value}`;
						},
					},
				},
				{
					name: this.lang.transform("lang_pending"),
					type: "bar",
					label: {
						show: true,
					},
					stack: "P_C",
					itemStyle: {
						color: "#FFDB5C",
					},
					emphasis: {
						focus: "series",
					},
					data: pending_data,
				},
				{
					name: this.lang.transform("lang_accepted_result"),
					type: "bar",
					label: {
						show: true,
					},
					itemStyle: {
						color: "#91ca8c",
					},
					stack: "R_A",
					emphasis: {
						focus: "series",
					},
					data: accepted_data,
				},
				{
					name: this.lang.transform("lang_rejected_result"),
					type: "bar",
					label: {
						show: true,
					},
					itemStyle: {
						color: "#b93939",
					},
					stack: "R_A",
					emphasis: {
						focus: "series",
					},
					data: rejected_data,
				},

				{
					name: this.lang.transform("lang_accepted_a"),
					type: "bar",
					label: {
						show: true,
					},
					data: acceptedA_data,
					itemStyle: {
						color: "#8dc1a9",
					},
					stack: "A_B_C_D",
					emphasis: {
						focus: "series",
					},
				},
				{
					name: this.lang.transform("lang_accepted_b"),
					type: "bar",
					label: {
						show: true,
					},
					data: acceptedB_data,
					itemStyle: {
						color: "#759aa0",
					},
					stack: "A_B_C_D",
					emphasis: {
						focus: "series",
					},
				},
				{
					name: this.lang.transform("lang_rejected_c"),
					type: "bar",
					label: {
						show: true,
					},
					itemStyle: {
						color: "#c55a5a",
					},
					data: rejectedC_data,
					stack: "A_B_C_D",
					emphasis: {
						focus: "series",
					},
				},
				{
					name: this.lang.transform("lang_rejected_d"),
					type: "bar",
					label: {
						show: true,
					},
					data: rejectedD_data,
					itemStyle: {
						color: "#f76f6f",
					},
					stack: "A_B_C_D",
					emphasis: {
						focus: "series",
					},
				},
			],
		};
		this.formsChart.setOption(newOption);
		this.formsChart.hideLoading();
	}
	initAverageDivisionChart() {
		//AverageDivision chart
		this.averageDivisionChart = echarts.init(
			document.getElementById("division-result-chart")
		);
		this.averageDivisionChart.showLoading();
	}
	updateAverageDivisionChart(data: Array<any>, filter) {
		let division_names = [],
			general_number_division_ratio = [],
			total_approved_weight = [],
			total_balance_weight_with_balance = [];

		for (let item of data) {
			if (
				parseFloat(item?.general_number_division_ratio) ||
				parseFloat(item?.total_balance_weight_with_balance) ||
				parseFloat(item?.total_approved_weight)
			) {
				division_names.push(item?.platform_group1_title);
				general_number_division_ratio.push(item?.general_number_division_ratio);
				total_approved_weight.push(item?.total_approved_weight);
				total_balance_weight_with_balance.push(
					item?.total_balance_weight_with_balance
				);
			}
		}
		let notSelected = {};
		if (filter) {
			notSelected[this.lang.transform("lang_total_balance")] = false;
		} else {
			notSelected[this.lang.transform("lang_total_balance")] = true;
		}
		let option = {
			tooltip: {
				trigger: "item",
				axisPointer: {
					type: "shadow",
				},
			},
			legend: {
				show: true,
				right: "20px",
				type: "scroll",
				selected: notSelected,
			},
			grid: { containLabel: true },
			xAxis: {
				name: this.lang.transform("lang_group_one"),
				type: "category",
				data: division_names,
				axisLabel: {
					interval: 0,
					rotate: 70,
					fontSize: 10,
				},
			},
			yAxis: {
				name: this.lang.transform("lang_average"),
				type: "value",
			},
			series: [
				{
					name: this.lang.transform("lang_general_number_division_ratio"),
					data: general_number_division_ratio,
					type: "bar",
					label: {
						position: "top",
						show: true,
					},
					showBackground: true,
					backgroundStyle: {
						color: "rgba(180, 180, 180, 0.2)",
					},
					itemStyle: {
						color: "#53b17d",
					},
				},
				{
					name: this.lang.transform("lang_total_approved_weight"),
					data: total_approved_weight,
					type: "bar",
					label: {
						position: "top",
						show: true,
					},
					showBackground: true,
					backgroundStyle: {
						color: "rgba(180, 180, 180, 0.2)",
					},
					itemStyle: {
						color: "#FFDB5C",
					},
				},
				{
					name: this.lang.transform("lang_total_balance"),
					data: total_balance_weight_with_balance,
					type: "bar",
					label: {
						position: "top",
						show: true,
					},
					showBackground: true,
					backgroundStyle: {
						color: "rgba(180, 180, 180, 0.2)",
					},
					itemStyle: {
						color: "#517eb7",
					},
				},
			],
		};
		this.averageDivisionChart.setOption(option);
		this.averageDivisionChart.hideLoading();
	}
	// unit requests

	getWorkLevels() {
		let param = new FormData();
		param.append("lang_key", this.lang_key);
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "lpworklevel")
			.subscribe((res) => {
				if (res?.status) {
					this.levels_arr = res?.records;
				} else {
					this.levels_arr = [];
				}
			});
	}

	exportExcel() {
		this.getTransactionsData(this.filters_params, this.sort_params, true);
	}

	show_excel_func(filters: any) {
		let filtersValue = Object.values(filters);
		let count = 0;
		for (let item of filtersValue) {
			if (item) {
				count++;
			}
		}
		if (count >= 3) {
			this.showExel = true;
		} else {
			this.showExel = false;
		}
	}

	toggleFilter() {
		this.showFilter = !this.showFilter;
		this.showSort = false;
	}

	toggleSort() {
		this.showSort = !this.showSort;
		this.showFilter = false;
	}

	load_by_page_size(e) {
		if (e && e.value) {
			this.getTransactionsData(this.filters_params, this.sort_params, false);
		}
	}

	filterParams(e) {
		this.filters_params = e;
		this.getTransactionsData(this.filters_params, this.sort_params, false);
	}
	sortParams(e) {
		this.sort_params = e;
		this.getTransactionsData(
			{
				...this.filters_params,
				transaction_start_date: this.convertDateToString(
					this.filterForm.get("transaction_start_date").value
				),
				transaction_end_date: this.convertDateToString(
					this.filterForm.get("transaction_end_date").value
				),
			},
			this.sort_params,
			false
		);
	}

	public onPageChange(): void {
		this.getTransactionsData(this.filters_params, this.sort_params, false);
	}

	getTransactionsData(filters: any, sort: any, isExcel: boolean) {
		this.spinner.show();
		let formData = new FormData();
		formData.append(`unit_id`, this.selectedNode?.data?.value || "");
		formData.append(
			`projects_supervision_id`,
			this.supervision_data?.projects_supervision_id || ""
		);
		filters?.template?.forEach((element, index) => {
			formData.append(
				`filter[template][${index}][label]`,
				element?.label || ""
			);
			formData.append(
				`filter[template][${index}][id]`,
				element?.value || element?.id || ""
			);
		});
		filters?.group_type?.forEach((element, index) => {
			formData.append(
				`filter[group_type][${index}][label]`,
				element?.label || ""
			);
			formData.append(`filter[group_type][${index}][id]`, element?.value || "");
		});
		filters?.group1?.forEach((element, index) => {
			formData.append(`filter[group1][${index}][label]`, element?.label || "");
			formData.append(
				`filter[group1][${index}][id]`,
				element?.value || element?.id || ""
			);
		});
		filters?.group2?.forEach((element, index) => {
			formData.append(`filter[group2][${index}][label]`, element?.label || "");
			formData.append(`filter[group2][${index}][id]`, element?.value || "");
		});
		filters?.zone?.forEach((element, index) => {
			formData.append(`filter[zone][${index}][label]`, element?.label || "");
			formData.append(
				`filter[zone][${index}][id]`,
				element?.value || element?.id || ""
			);
		});
		filters?.block?.forEach((element, index) => {
			formData.append(`filter[block][${index}][label]`, element?.label || "");
			formData.append(
				`filter[block][${index}][id]`,
				element?.value || element?.id || ""
			);
		});
		filters?.cluster?.forEach((element, index) => {
			formData.append(`filter[cluster][${index}][label]`, element?.label || "");
			formData.append(
				`filter[cluster][${index}][id]`,
				element?.value || element?.id || ""
			);
		});
		formData.append(
			"filter[transaction_start_date]",
			filters?.transaction_start_date || ""
		);
		formData.append(
			"filter[transaction_end_date]",
			filters?.transaction_end_date || ""
		);
		formData.append(
			"filter[transaction_request_id]",
			filters?.form_request_id || ""
		);
		formData.append(
			"filter[platform_code_system]",
			filters?.platform_code_system || ""
		);
		formData.append(
			"filter[phase_short_code]",
			filters?.phase_short_code || ""
		);
		formData.append("filter[unit_id]", filters?.unit_id || "");
		formData.append("filter[level_key]", filters?.level_key || "");
		formData.append("filter[barcode]", filters?.barcode || "");
		formData.append("filter[result_code]", filters?.result_code || "");
		formData.append("filter[version]", filters?.version || "");
		formData.append("sort_by[barcode]", sort?.barcode || "");
		formData.append(
			"sort_by[transaction_request_id]",
			sort?.form_request_id || ""
		);
		formData.append("sort_by[template_id]", sort?.template_id || "");
		formData.append("sort_by[zone]", sort?.zone || "");
		formData.append("sort_by[block]", sort?.block || "");
		formData.append("sort_by[cluster]", sort?.cluster || "");
		formData.append(
			"sort_by[platform_code_system]",
			sort?.platform_code_system || ""
		);
		this.show_excel_func({
			...filters,
			template: filters?.template?.length,
			group_type: filters?.group_type?.length,
			group1: filters?.group1?.length,
			group2: filters?.group2?.length,
			zone: filters?.zone?.length,
			block: filters?.block?.length,
			cluster: filters?.cluster?.length,
		});
		if (isExcel) {
			this.pages = "excel";
		}
		this.ds
			.postActionByUrl(
				formData,
				`pr/qtp_unit/${this.supervision_data?.projects_work_area_id}/${this.pages}/${this.page_no}/${this.page_size}`
			)
			.subscribe(
				(res) => {
					if (res?.status) {
						this.spinner.hide();
						if (!isExcel) {
							this.TransactionsData = res?.records;
							this.collection_size = res?.page?.total_records;
						} else {
							let url = "data:" + res["content-type"] + ";base64," + res.base64;
							let downloadExcel = document.createElement("a");
							downloadExcel.style.display = "none";
							downloadExcel.href = url;
							downloadExcel.download = "Almnabr";
							document.body.appendChild(downloadExcel);
							downloadExcel.click();
							document.body.removeChild(downloadExcel);
							this.pages = "pages";
						}
					} else {
						this.spinner.hide();
						this.TransactionsData = [];
						this.collection_size = 0;
						this.page_no = 1;
					}
				},
				(err) => {
					this.spinner.hide();
					this.TransactionsData = [];
					this.collection_size = 0;
					this.page_no = 1;
				}
			);
	}

	saveFilters(filters) {
		this.filters_saved = filters;
		this.saveFiltersSort(this.filters_saved, this.sort_saved);
	}

	saveSorts(sort) {
		this.sort_saved = sort;
		this.saveFiltersSort(this.filters_saved, this.sort_saved);
	}

	saveFiltersSort(filters: any, sort: any) {
		this.spinner.show();
		let formData = new FormData();
		formData.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id || ""
		);
		formData.append("filter_key", "FP1");
		formData.append("filter_name", this?.filter_name || "");
		formData.append(
			"filter[transaction_request_id]",
			filters?.form_request_id || ""
		);
		filters?.template?.forEach((element, index) => {
			formData.append(
				`filter[template][${index}][label]`,
				element?.label || ""
			);
			formData.append(
				`filter[template][${index}][id]`,
				element?.value || element?.id || ""
			);
		});
		filters?.group_type?.forEach((element, index) => {
			formData.append(
				`filter[group_type][${index}][label]`,
				element?.label || ""
			);
			formData.append(`filter[group_type][${index}][id]`, element?.value || "");
		});
		filters?.group1?.forEach((element, index) => {
			formData.append(`filter[group1][${index}][label]`, element?.label || "");
			formData.append(
				`filter[group1][${index}][id]`,
				element?.value || element?.id || ""
			);
		});
		filters?.group2?.forEach((element, index) => {
			formData.append(`filter[group2][${index}][label]`, element?.label || "");
			formData.append(`filter[group2][${index}][id]`, element?.value || "");
		});
		filters?.zone?.forEach((element, index) => {
			formData.append(`filter[zone][${index}][label]`, element?.label || "");
			formData.append(
				`filter[zone][${index}][id]`,
				element?.value || element?.id || ""
			);
		});
		filters?.block?.forEach((element, index) => {
			formData.append(`filter[block][${index}][label]`, element?.label || "");
			formData.append(
				`filter[block][${index}][id]`,
				element?.value || element?.id || ""
			);
		});
		filters?.cluster?.forEach((element, index) => {
			formData.append(`filter[cluster][${index}][label]`, element?.label || "");
			formData.append(
				`filter[cluster][${index}][id]`,
				element?.value || element?.id || ""
			);
		});
		formData.append(
			"filter[transaction_start_date]",
			filters?.transaction_start_date || ""
		);
		formData.append(
			"filter[transaction_end_date]",
			filters?.transaction_end_date || ""
		);
		formData.append(
			"filter[platform_code_system]",
			filters?.platform_code_system || ""
		);
		formData.append(
			"filter[phase_short_code]",
			filters?.phase_short_code || ""
		);
		formData.append("filter[unit_id]", filters?.unit_id || "");
		formData.append("filter[level_key]", filters?.level_key || "");
		formData.append("filter[barcode]", filters?.barcode || "");
		formData.append("filter[result_code]", filters?.result_code || "");
		formData.append("filter[version]", filters?.version || "");
		formData.append("sort_by[barcode]", sort?.barcode || "");
		formData.append(
			"sort_by[transaction_request_id]",
			sort?.form_request_id || ""
		);
		formData.append("sort_by[template_id]", sort?.template_id || "");
		formData.append("sort_by[zone]", sort?.zone || "");
		formData.append("sort_by[block]", sort?.block || "");
		formData.append("sort_by[cluster]", sort?.cluster || "");
		formData.append(
			"sort_by[platform_code_system]",
			sort?.platform_code_system || ""
		);

		this.ds.postActionByUrl(formData, "pr/craete_update_filter").subscribe(
			(res) => {
				if (res?.status) {
					this.spinner.hide();
					this.getFilterList();
				} else {
					this.spinner.hide();
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}

	getFilterList() {
		let param = new FormData();
		param.append("filter_key", "FP1");
		param.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id || ""
		);
		this.spinner.show();
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "pr/get_filter_list")
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.filterList = res?.records;
						let selected = this.filterList.find(
							(el) => el.fliter_selected == "1"
						);
						if (selected) {
							this.selectedFilter = selected?.filter_id;
							this.getFilterDetails({ filter_id: selected?.filter_id });
						}
					} else {
						this.filterList = [];
					}
				},
				(err) => {
					this.spinner.hide();
				}
			);
	}

	getFilterDetails(e) {
		if (e) {
			let param = new FormData();
			param.append("filter_id", e?.filter_id);
			this.spinner.show();
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "pr/get_filter_details")
				.subscribe(
					(res) => {
						this.spinner.hide();
						this.filter_name = res?.records[0]?.filter_name;
						this.filterForm
							.get("form_request_id")
							.setValue(res?.records[0]?.filter_by?.transaction_request_id);
						if (res?.records[0]?.filter_by?.transaction_start_date) {
							this.filterForm
								.get("transaction_start_date")
								.setValue(
									new Date(res?.records[0]?.filter_by?.transaction_start_date)
								);
						} else {
							this.filterForm.get("transaction_start_date").setValue(null);
						}
						if (res?.records[0]?.filter_by?.transaction_start_date) {
							this.filterForm
								.get("transaction_end_date")
								.setValue(
									new Date(res?.records[0]?.filter_by?.transaction_end_date)
								);
						} else {
							this.filterForm.get("transaction_end_date").setValue(null);
						}
						this.filterForm
							.get("platform_code_system")
							.setValue(res?.records[0]?.filter_by?.platform_code_system);
						this.filterForm
							.get("phase_short_code")
							.setValue(res?.records[0]?.filter_by?.phase_short_code);
						this.filterForm
							.get("unit_id")
							.setValue(res?.records[0]?.filter_by?.unit_id);
						this.filterForm
							.get("barcode")
							.setValue(res?.records[0]?.filter_by?.barcode);
						this.filterForm
							.get("result_code")
							.setValue(res?.records[0]?.filter_by?.result_code);
						this.filterForm
							.get("version")
							.setValue(res?.records[0]?.filter_by?.version);
						this.filterForm
							.get("level_key")
							.setValue(res?.records[0]?.filter_by?.level_key);
						this.template = res?.records[0]?.filter_by?.template || [];
						this.group_type = res?.records[0]?.filter_by?.group_type || [];
						this.group1 = res?.records[0]?.filter_by?.group1 || [];
						this.group2 = res?.records[0]?.filter_by?.group2 || [];
						this.zone = res?.records[0]?.filter_by?.zone || [];
						this.block = res?.records[0]?.filter_by?.block || [];
						this.cluster = res?.records[0]?.filter_by?.cluster || [];
						this.sortForm
							.get("form_request_id")
							.setValue(
								res?.records[0]?.sort_by?.transaction_request_id || null
							);
						this.sortForm
							.get("template_id")
							.setValue(res?.records[0]?.sort_by?.template_id || null);
						this.sortForm
							.get("platform_code_system")
							.setValue(res?.records[0]?.sort_by?.platform_code_system || null);
						this.sortForm
							.get("zone")
							.setValue(res?.records[0]?.sort_by?.zone || null);
						this.sortForm
							.get("block")
							.setValue(res?.records[0]?.sort_by?.block || null);
						this.sortForm
							.get("cluster")
							.setValue(res?.records[0]?.sort_by?.block || null);
						this.sortForm
							.get("barcode")
							.setValue(res?.records[0]?.sort_by?.block || null);
						this.filters_saved = res?.records[0]?.filter_by;
						this.sort_saved = res?.records[0]?.sort_by;
						this.filters_params = {
							...this.filterForm?.value,
							template: this.template,
							group1: this.group1,
							group_type: this.group_type,
							group2: this.group2,
							zone: this.zone,
							block: this.block,
							cluster: this.cluster,
						};
						this.sort_params = this.sortForm?.value;
						this.sendFilterForm();
					},
					(err) => {
						this.spinner.hide();
					}
				);
		} else {
			this.filter_name = "";
			this.filterForm.get("form_request_id").setValue(null);
			this.filterForm.get("platform_code_system").setValue(null);
			this.filterForm.get("transaction_start_date").setValue(null);
			this.filterForm.get("transaction_end_date").setValue(null);
			this.filterForm.get("phase_short_code").setValue(null);
			this.filterForm.get("unit_id").setValue(null);
			this.filterForm.get("barcode").setValue(null);
			this.filterForm.get("result_code").setValue(null);
			this.filterForm.get("version").setValue(null);
			this.filterForm.get("level_key").setValue(null);
			this.template = [];
			this.group_type = [];
			this.group1 = [];
			this.group2 = [];
			this.zone = [];
			this.block = [];
			this.cluster = [];
			this.sortForm.get("form_request_id").setValue(null);
			this.sortForm.get("template_id").setValue(null);
			this.sortForm.get("platform_code_system").setValue(null);
			this.sortForm.get("zone").setValue(null);
			this.sortForm.get("block").setValue(null);
			this.sortForm.get("cluster").setValue(null);
			this.sortForm.get("barcode").setValue(null);
			this.filters_saved = null;
			this.sort_saved = null;
			this.filters_params = null;
			this.sort_params = null;
		}
	}

	removeFilter(event, filter) {
		event?.stopPropagation();
		this.sweetAlert.confirmMessage("are you sure?", "yes").then((result) => {
			if (result?.isConfirmed) {
				let param = new FormData();
				param.append("filter_id", filter?.filter_id || "");
				this.spinner.show();
				this.ds
					.getActionByUrl(this.ds.formData2string(param), "pr/delete_filter")
					.subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert?.successToast(
									"lang_filter_deleted_successfully",
									2000
								);
								this.filterSelect?.close();
								this.filterSelectAtsort?.close();
								this.getFilterList();
							}
						},
						(err) => {
							this.spinner.hide();
						}
					);
			}
		});
	}

	sendFilterForm() {
		this.filterParams({
			...this.filterForm?.value,
			transaction_start_date: this.convertDateToString(
				this.filterForm.get("transaction_start_date").value
			),
			transaction_end_date: this.convertDateToString(
				this.filterForm.get("transaction_end_date").value
			),
			template: this.template,
			group1: this.group1,
			group_type: this.group_type,
			group2: this.group2,
			zone: this.zone,
			block: this.block,
			cluster: this.cluster,
		});
	}

	resetFilterForm() {
		this.filterForm.reset();
		this.filterForm.get("version").setValue("final_completed_versions");
		this.template = [];
		this.group1 = [];
		this.group_type = [];
		this.group2 = [];
		this.zone = [];
		this.block = [];
		this.cluster = [];
		this.filter_name = null;
		this.selectedFilter = null;
		this.filterParams({
			...this.filterForm?.value,
			template: this.template,
			group1: this.group1,
			group_type: this.group_type,
			group2: this.group2,
			zone: this.zone,
			block: this.block,
			cluster: this.cluster,
		});
	}

	public isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	openTemplateFilter() {
		const dialogRef = this.dialog.open(TemplateDialogComponent, {
			width: "100%",
			data: {
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				let canAddTemplate = this.template
						?.map((el) => el.value)
						?.includes(result?.template?.value),
					canAddGroup1 = this.group1
						?.map((el) => el.id)
						?.includes(result?.group1?.id),
					canAddType = this.group_type
						?.map((el) => el.id)
						?.includes(result?.group_type?.id),
					canAddGroup2 = this.group2
						?.map((el) => el.value)
						?.includes(result?.group2?.value);

				if (result?.template && !canAddTemplate) {
					this.template.push(result?.template);
				}
				if (result?.group1 && !canAddGroup1) {
					this.group1.push(result?.group1);
				}
				if (result?.group_type && !canAddType) {
					this.group_type.push(result?.group_type);
				}
				if (result?.group2 && !canAddGroup2) {
					this.group2.push(result?.group2);
				}
			}
		});
	}

	removeTemplate(index) {
		if (index >= 0) {
			this.template.splice(index);
		}
	}

	removeGroup1(index) {
		if (index >= 0) {
			this.group1.splice(index);
		}
	}

	removeGroupType(index) {
		if (index >= 0) {
			this.group_type.splice(index);
		}
	}

	removeGroup2(index) {
		if (index >= 0) {
			this.group2.splice(index);
		}
	}

	openZoneFilter() {
		const dialogRef = this.dialog.open(ZoneDialogComponent, {
			width: "100%",
			data: {
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
				projects_profile_id: this.project_data?.projects_profile_id,
				projects_supervision_id: this.supervision_data?.projects_supervision_id,
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				let canAddZone = this.zone
						?.map((el) => el.value)
						?.includes(result?.zone?.value),
					canAddBlock = this.block
						?.map((el) => el.value)
						?.includes(result?.block?.value),
					canAddCluster = this.cluster
						?.map((el) => el.value)
						?.includes(result?.cluster?.value);

				if (result?.zone && !canAddZone) {
					this.zone.push(result?.zone);
				}
				if (result?.block && !canAddBlock) {
					this.block.push(result?.block);
				}
				if (result?.cluster && !canAddCluster) {
					this.cluster.push(result?.cluster);
				}
			}
		});
	}

	removeZone(index) {
		if (index >= 0) {
			this.zone.splice(index);
		}
	}

	removeBlock(index) {
		if (index >= 0) {
			this.block.splice(index);
		}
	}

	removeCluster(index) {
		if (index >= 0) {
			this.cluster.splice(index);
		}
	}

	saveFilter() {
		if (this.filter_name) {
			this.saveFilters({
				...this.filterForm?.value,
				transaction_start_date: this.convertDateToString(
					this.filterForm.get("transaction_start_date").value
				),
				transaction_end_date: this.convertDateToString(
					this.filterForm.get("transaction_end_date").value
				),
				template: this.template,
				group1: this.group1,
				group_type: this.group_type,
				group2: this.group2,
				zone: this.zone,
				block: this.block,
				cluster: this.cluster,
				filter_name: this.filter_name,
			});
		} else {
			this.sweetAlert.errorMessage("lang_filter_name_required");
		}
	}

	buildForm() {
		this.sortForm = this.fb.group({
			form_request_id: null,
			template_id: null,
			platform_code_system: null,
			zone: null,
			block: null,
			cluster: null,
			barcode: null,
		});
	}

	sendSortForm() {
		this.sortParams(this.sortForm?.value);
	}

	resetSortForm() {
		this.sortForm.reset();
		this.filter_name = null;
		this.selectedFilter = null;
		this.sortParams(this.sortForm?.value);
	}
	saveSortForm() {
		if (this.filter_name) {
			this.saveSorts(this.sortForm?.value);
		} else {
			this.sweetAlert.errorMessage("lang_filter_name_required");
		}
	}

	convertDateToString(date) {
		if (date) {
			var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
				day = ("0" + date.getDate()).slice(-2);
			return [date.getFullYear(), mnth, day].join("/");
		} else {
			return null;
		}
	}

	show_add_phases(type) {
		this.viewtype = type;
		if (type == "VIEW") {
			this.viewPhaseDataStatus = false;
			this.get_implementation_phases();
		}
	}
	completeAction() {
		this.viewPhaseData = [];
		this.viewPhaseDataStatus = false;
		this.get_implementation_phases();
		setTimeout(() => {
			this.viewtype = "VIEW";
		}, 1000);
	}
	show_delete_phases() {
		const dialogRef = this.dialog.open(ConfirmationDialog, {
			disableClose: true,
			width: "400px",
			data: {
				title: this.lang.transform("lang_confirmation"),
				message: this.lang.transform("lang_are_you_sure"),
			},
		});
		dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.spinner.show();
				this.ds
					.deleteActionByUrl(
						[],
						"HYpgE8K9rv/" +
							this.supervision_data.projects_profile_id +
							"/" +
							this.supervision_data.projects_supervision_id
					)
					.subscribe(
						(data) => {
							this.spinner.hide();
							if (data.status) {
								this.get_implementation_phases();
								this.ds.dialogf("", data.msg);
							} else {
								this.ds.dialogf("", data.error);
							}
						},
						(error) => {
							this.ds.dialogf(
								"",
								error && error.error && error.error.error
									? error.error.error
									: this.lang.transform("lang_internal_server_error")
							);
							this.spinner.hide();
						}
					);
			}
		});
	}

	IphasesDialog() {
		this.dialog.open(IphasesDialogComponent, {
			maxWidth: "1000px",
			width: "90%",
			autoFocus: false,
			restoreFocus: false,
			data: {
				supervision_data: this.supervision_data,
				project_data: this.project_data,
			},
		});
	}
	convertNumToCurrency(number) {
		return new Intl.NumberFormat("en-US", {
			maximumFractionDigits: 3,
			minimumFractionDigits: 3,
		}).format(number);
	}
}
