import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	ChangeDetectionStrategy,
	ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DataService } from "@core/bootstrap/data.service";
import { MessagingService } from "@core/bootstrap/messaging.service";
import { ToggleMenuService } from "@core/bootstrap/toggle-menu.service";
import { NotificationsComponent } from "@shared/components/notifications/notifications.component";
import * as screenfull from "screenfull";

declare var $: any;
@Component({
	selector: "app-header",
	host: {
		class: "matero-header",
	},
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
	@Input() showToggle = true;
	@Input() showBranding = false;

	@Output() toggleSidenav = new EventEmitter<void>();
	@Output() toggleSidenavNotice = new EventEmitter<void>();

	isMob: boolean = false;
	lang_key = localStorage.getItem("lang_key") || "en";
	private get screenfull(): screenfull.Screenfull {
		return screenfull as screenfull.Screenfull;
	}
	notifications = [];
	notifications_number = 0;
	constructor(
		private dialog: MatDialog,
		private messagingService: MessagingService,
		private ds: DataService,
		private toggleMenu: ToggleMenuService
	) {}

	ngOnInit() {
		this.notifications = [];
		if (window.innerWidth < 960) {
			this.isMob = true;
		} else {
			this.isMob = false;
		}
		this.messagingService.currentMessage.subscribe((res) => {
			if (res) {
				this.notifications_number++;
			}
		});
		this.getNotifications();
	}

	hamburger() {
		if (document.getElementById("nav-icon2").classList.contains("open")) {
			document.getElementById("nav-icon2").classList.remove("open");
			this.toggleMenu.isMob.next(false);
		} else {
			document.getElementById("nav-icon2").classList.add("open");
			this.toggleMenu.isMob.next(true);
		}
	}

	toggleFullscreen() {
		if (this.screenfull.isEnabled) {
			this.screenfull.toggle();
		}
	}

	showNotifications() {
		let modal_width = "40%";
		if (window.innerWidth <= 900) {
			modal_width = "80%";
		}
		if (window.innerWidth <= 600) {
			modal_width = "100%";
		}
		const dialogRef = this.dialog.open(NotificationsComponent, {
			width: modal_width,
			disableClose: true,
		});
		dialogRef.afterClosed().subscribe((result) => {
			this.notifications_number = result;
		});
	}

	getNotifications() {
		let params = new FormData();
		params.append("page", "1");
		this.ds
			.getActionByUrl(
				this.ds.formData2string(params),
				"notification/get_noti_list"
			)
			.subscribe(
				(res) => {
					if (res.status) {
						this.notifications = res.lists;
						this.notifications_number = res.total;
					}
				},
				(err) => {
					this.notifications = [];
					this.notifications_number = 0;
				}
			);
	}
}
