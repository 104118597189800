<breadcrumb></breadcrumb>
<div class="themesection">
  <div class="container mb-10">
    <div class="row">
      <div class="col-md-2 col-sm-12 col-xs-12"></div>
      <div class="col-md-3 col-sm-6 col-xs-12 mb-2">
        <div *ngIf="branch_id == '0'" class="red pad0 text-center">
          {{ "lang_choose_branch" | language }}
        </div>
        <select
          name="branch_id"
          class="form-control almnabrformcontrol"
          matTooltip="{{ 'lang_branch' | language }}"
          [(ngModel)]="branch_id"
          (change)="spinner.show(); selectBrach($event.target.value)"
        >
          <option value="0">{{ "lang_choose_options" | language }}</option>
          <option *ngFor="let branch of branches" value="{{ branch.id }}">
            {{ branch.title }}
          </option>
        </select>
      </div>
      <div
        class="col-md-3 col-sm-6 col-xs-12 mb-2"
        *ngIf="branch_id && branch_id != '0'"
      >
        <ng-select
          [items]="finances"
          [multiple]="false"
          bindLabel="label"
          bindValue="value"
          clearAllText="Clear"
          appendTo="body"
          [(ngModel)]="finance_id"
          placeholder="{{ 'lang_financial_years' | language }}"
          (change)="spinner.show(); load_transactions()"
        >
        </ng-select>
      </div>
      <div
        class="col-md-3 col-sm-6 col-xs-12 mb-2"
        *ngIf="branch_id && branch_id != '0'"
      >
        <ng-select
          [items]="transactionType"
          [multiple]="false"
          bindLabel="label"
          bindValue="value"
          clearAllText="Clear"
          appendTo="body"
          [(ngModel)]="transaction_request_type"
          placeholder="{{ 'lang_transaction_request_type' | language }}"
          (change)="this.load_transactions()"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <section
    class="panel-expansion matborderno"
    *ngIf="branch_id && branch_id != '0'"
  >
    <mat-accordion class="receipt_lists">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_non_equal_transactions" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive dropdownyes customResponsive">
                  <table border="1" class="table CustomTable">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>{{ "lang_request_id" | language }}</td>
                        <td>{{ "lang_transaction_id" | language }}</td>
                        <td>
                          {{ "lang_transaction_request_type" | language }}
                        </td>
                        <td>{{ "lang_total_credit" | language }}</td>
                        <td>{{ "lang_total_debit" | language }}</td>
                        <td>{{ "lang_difference" | language }}</td>
                        <td>
                          {{ "lang_transaction_history_date" | language }}
                        </td>
                      </tr>
                    </thead>
                    <tbody *ngIf="transData.length != '0'" class="AppendList">
                      <tr
                        *ngFor="
                          let data of transData.records;
                          let i = index;
                          let odd = odd;
                          let even = even
                        "
                      >
                        <td>{{ i + 1 }}</td>
                        <td>
                          <a>{{ data.request_id }}</a>
                        </td>
                        <td>
                          {{ data.transaction_id }}
                        </td>
                        <td>
                          {{ data.transaction_request_type }}
                        </td>
                        <td>
                          {{ data.total_credit }}
                        </td>
                        <td>
                          {{ ds.addCommas(data.total_debit) }}
                        </td>
                        <td>{{ data.difference }}</td>
                        <td>{{ data.transaction_history_date }}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="transData.length == '0'" class="AppendList">
                      <tr class="odd">
                        <td colspan="10" align="center">{{ lodingdatas }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="transData.length != '0'" class="card-footer padb0">
            <div class="row responsive_pagination">
              <div
                class="col-lg-3 col-sm-3 mb-10"
                style="height: fit-content; margin: auto 0"
              >
                {{ "lang_total" | language }} :
                {{ transData.page.total_records }}
              </div>
              <div
                class="col-lg-5 col-sm-5 mb-10"
                style="display: flex; justify-content: center"
              >
                <ngb-pagination
                  class="m-auto"
                  [collectionSize]="transData.page.total_records"
                  [rotate]="true"
                  [ellipses]="false"
                  [boundaryLinks]="true"
                  [(page)]="recpage_no"
                  [pageSize]="recpage_size"
                  (pageChange)="loadpagerec($event)"
                ></ngb-pagination>
              </div>
              <div
                class="col-lg-3 col-sm-3"
                style="display: flex; justify-content: end; margin: auto"
              >
                <mat-select
                  style="max-width: 150px"
                  [(ngModel)]="recpage_size"
                  (selectionChange)="loadrecpagesize($event.value)"
                >
                  <mat-option class="text-center" value="10"
                    >10 {{ "lang_per_page" | language }}
                  </mat-option>
                  <mat-option class="text-center" value="20"
                    >20 {{ "lang_per_page" | language }}
                  </mat-option>
                  <mat-option class="text-center" value="50"
                    >50 {{ "lang_per_page" | language }}
                  </mat-option>
                  <mat-option class="text-center" value="100"
                    >100 {{ "lang_per_page" | language }}</mat-option
                  >
                  <mat-option class="text-center" value="500"
                    >500 {{ "lang_per_page" | language }}</mat-option
                  >
                </mat-select>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
