import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
	selector: "app-supervision-schedules",
	templateUrl: "./supervision-schedules.component.html",
	styleUrls: ["./supervision-schedules.component.scss"],
})
export class SupervisionSchedulesComponent implements OnInit {
	modalRef: BsModalRef;
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}
	@Input() supervision_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() project_data;
	@Input() filelevels;
	@Input() makeAction;
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	users: any = [];
	positionItems: any = [];
	positions: any = [];
	timepageno = 1;
	timepagesize = "10";
	timeListData: any = [];
	timenodata: string = this.lang.transform("lang_loading");
	error_message: string = "";
	error_status: boolean = false;
	form: FormGroup;
	submitted = false;
	viewdata: any = [];
	public isFile;
	filename = this.lang.transform("lang_select_files");
	searchKey = "";
	scheduleStatus = "1";
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public alert: AlertService,
		public modalService: BsModalService,
		public dialog: MatDialog
	) {}
	ngOnInit(): void {
		this.buildform();
		this.load_supervision_time_schedules();
	}
	emptyform() {
		this.isFile = false;
		this.filename = this.lang.transform("lang_select_files");
		this.form.reset();
	}
	buildform() {
		this.form = new FormGroup({
			projects_profile_id: new FormControl(""),
			projects_supervision_id: new FormControl(""),
			time_schedule_id: new FormControl(""),
			time_schedule_description: new FormControl("", [Validators.required]),
			time_schedule_attachment_url: new FormControl("", [Validators.required]),
			level_keys: new FormControl("", [Validators.required]),
		});
	}
	public UploadFile(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			this.isFile = true;
			this.filename = event.target.files[0].name;
			this.form
				.get("time_schedule_attachment_url")
				.setValue(event.target.files[0], { emitEvent: true });
		} else {
			this.isFile = false;
			this.form.get("time_schedule_attachment_url").setValue(null);
			this.filename = this.lang.transform("lang_select_files");
		}
	}
	public removeFile() {
		this.isFile = false;
		this.form.get("time_schedule_attachment_url").setValue(null);
		this.filename = this.lang.transform("lang_select_files");
	}
	emptyEditform(list) {
		this.isFile = false;
		this.filename = this.lang.transform("lang_select_files");
		this.form.reset();
		this.form.patchValue({
			projects_profile_id: list.projects_profile_id,
			projects_supervision_id: list.projects_supervision_id,
			time_schedule_id: list.time_schedule_id,
			time_schedule_description: list.time_schedule_description,
			level_keys: list.level_keys ? list.level_keys.split(",") : null,
		});
	}
	submit() {
		this.spinner.show();
		this.form
			.get("projects_profile_id")
			.setValue(this.supervision_data.projects_profile_id);
		this.form
			.get("projects_supervision_id")
			.setValue(this.supervision_data.projects_supervision_id);
		let url = this.form.get("time_schedule_id").value
			? "9CEEuulDGGcw0UI/" + this.form.get("time_schedule_id").value
			: "fyxGbmJ8QUsEl0v";
		this.ds
			.postActionByUrl(this.ds.json2formdata(this.form.value), url)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.alert.success(res.msg);
						this.form.reset();
						this.load_supervision_time_schedules();
						setTimeout(() => {
							this.modalRef.hide();
						}, 1000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	public search_supervision_time_schedules() {
		this.timepageno = 1;
		this.timepagesize = "10";
		this.load_supervision_time_schedules();
	}
	public load_supervision_Schedules_page(page) {
		this.spinner.show();
		this.timepageno = page;
		this.timepagesize = this.timepagesize;
		this.load_supervision_time_schedules();
	}
	public load_supervision_Schedules_pagesize(size) {
		this.spinner.show();
		this.timepageno = 1;
		this.timepagesize = size;
		this.load_supervision_time_schedules();
	}
	load_supervision_time_schedules() {
		let formdata = new FormData();
		formdata.append("search_key", this.searchKey);
		formdata.append("time_schedule_status", this.scheduleStatus);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formdata),
				"bfjYr046TlLYoaH/" +
					this.project_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id +
					"/" +
					this.timepageno +
					"/" +
					this.timepagesize
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.timeListData = res;
					} else {
						this.timeListData = [];
						this.timenodata = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.timeListData = [];
					this.timenodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}
	checkAllItemsSch(event) {
		if (event.target.checked) {
			$(".supervision_time_schedules .eachItemSch").prop("checked", true);
			$(".supervision_time_schedules .makeback").addClass("bground");
			$(".supervision_time_schedules .deleteIconDiv").show();
		} else {
			$(".supervision_time_schedules .eachItemSch").prop("checked", false);
			$(".supervision_time_schedules .makeback").removeClass("bground");
			$(".supervision_time_schedules .deleteIconDiv").hide();
		}
	}
	checkEachItemSch(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".supervision_time_schedules .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".supervision_time_schedules .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".supervision_time_schedules .eachItemSch:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".supervision_time_schedules .eachItemSch").each(function () {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".supervision_time_schedules .deleteIconDiv").show();
		} else {
			$(".supervision_time_schedules .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".supervision_time_schedules #AllItemsSch").prop("checked", true);
		} else {
			$(".supervision_time_schedules #AllItemsSch").prop("checked", false);
		}
	}
	deleteBulkDataSch() {
		const checkedtotal = [];
		$(".supervision_time_schedules .eachItemSch:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: "400px",
				data: {
					title: this.lang.transform("lang_confirmation"),
					message: this.lang.transform("lang_are_you_sure"),
				},
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show();
					this.ds
						.deleteActionByUrl(
							checkedtotal,
							"hXCmsvWwdOZmw6e/" +
								this.supervision_data.projects_profile_id +
								"/" +
								this.supervision_data.projects_supervision_id
						)
						.subscribe(
							(data) => {
								this.spinner.hide();
								if (data.status) {
									this.load_supervision_time_schedules();
									$(".supervision_time_schedules #AllItemsSch").prop(
										"checked",
										false
									);
									$(".supervision_time_schedules .deleteIconDiv").hide();
									this.ds.dialogf("", data.msg);
								} else {
									this.ds.dialogf("", data.error);
								}
							},
							(error) => {
								this.ds.dialogf(
									"",
									error && error.error && error.error.error
										? error.error.error
										: this.lang.transform("lang_internal_server_error")
								);
								this.spinner.hide();
							}
						);
				}
			});
		} else {
			this.ds.dialogf();
		}
	}
}
