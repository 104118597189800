<div class="card wafi_report">
  <div class="card-header">{{ "lang_wafi_report" | language }}</div>
  <div class="card-body pad0">
    <mat-tab-group (selectedTabChange)="selectedListTabValue($event)">
      <mat-tab label="wafi_report">
        <ng-template mat-tab-label>{{
          "lang_wafi_report" | language
        }}</ng-template>
        <ng-container>
          <div class="p-2">
            <app-wafi-list
              [projects_work_area_id]="supervision_data?.projects_work_area_id"
              [projects_profile_id]="project_data?.projects_profile_id"
              [projects_supervision_id]="
                supervision_data?.projects_supervision_id
              "
            ></app-wafi-list>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="wafi_settings">
        <ng-template mat-tab-label>{{
          "lang_settings" | language
        }}</ng-template>
        <ng-container>
          <div class="p-2">
            <app-wafi-settings
              [projects_work_area_id]="supervision_data?.projects_work_area_id"
              [projects_profile_id]="project_data?.projects_profile_id"
              [projects_supervision_id]="
                supervision_data?.projects_supervision_id
              "
            ></app-wafi-settings>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
