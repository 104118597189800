import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { PreloaderService } from "@core";

@Component({
	selector: "app-cost-center-view-dialog",
	templateUrl: "./cost-center-view-dialog.component.html",
	styleUrls: ["./cost-center-view-dialog.component.scss"],
})
export class CostCenterViewDialogComponent implements OnInit {
	costCenters: any = [];
	page = 1;
	size = 10;
	constructor(
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public router: Router,
		private dialogRef: MatDialogRef<CostCenterViewDialogComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		if (this.data && this.data?.length) {
			this.costCenters = this.data;
		} else {
			this.costCenters = [];
		}
	}

	ngOnInit(): void {}
}
