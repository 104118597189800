<section class="panel-expansion attach_panel" *ngIf="admin">
	<mat-accordion class="stylepanels">
		<mat-expansion-panel class="mpanel" [expanded]="true">
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_shifts" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15">
					<div class="row">
                        <div class="col-12 mb-10">
                            <div class="input-group input-group-sm mb-10">
                                <input type="text" class="form-control almnabrformcontrol" id="searchKey" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}" (keyup)="getShifts()">
                                <span class="input-group-btn">
                                    <button id="btnSearch" type="button" [routerLink]="['/default/shifts/add']"  matTooltip="{{'lang_add' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"></button>
                                </span>
                            </div>
                        </div>
						<div class="col-12">
							<div class="TablePart1">
								<div class="table-responsive dropdownyes customResponsive">
									<table border="1" class="table CustomTable">
										<thead>
											<tr>
												<td>#</td>
												<td>
													<div
														class="checkbox"
														matTooltip="{{ 'lang_check_all' | language }}"
													>
														<input
															type="checkbox"
															class="allItemsAth checkbox"
															id="allItemsAth"
															(click)="checkAllItemsAth($event)"
														/>
														<label for="checkbox"></label>
													</div>
												</td>
												<td>{{ "lang_name_en" | language }}</td>
												<td>{{ "lang_name_ar" | language }}</td>
												<td>{{ "lang_created_at" | language }}</td>
												<td>{{ "lang_updated_datetime" | language }}</td>
												<td>{{ "lang_writer" | language }}</td>
												<td>{{ "lang_actions" | language }}</td>
											</tr>
										</thead>
										<tbody
											*ngIf="shifts?.length"
											class="AppendList"
										>
											<tr
												*ngFor="
													let item of shifts;
													let i = index;
													let odd = odd;
													let even = even
												"
												[ngClass]="{ odd: odd, even: even }"
												
											>
												<td>{{ i + 1 }}</td>
												<td>
													<div
														class="checkbox"
														matTooltip="{{ 'lang_check' | language }}"
													>
														<input
															type="checkbox"
															class="eachItemAth checkbox"
															value="{{ item?.shift_id }}"
															(click)="checkEachItemAth($event)"
														/>
														<label for="checkbox"></label>
													</div>
												</td>
												<td>{{ item?.shift_name_english }}</td>
												<td>{{ item?.shift_name_arabic }}</td>
												<td>{{ item?.shift_created_datetime }}</td>
												<td>{{ item?.shift_updated_datetime  || '--'}}</td>
												<td>{{ item?.writer_name }}</td>
												<td>
													<span routerLink="/default/shifts/update/{{item?.shift_id}}">
														<i class="fa fa-pencil-square-o mnabricon mr-1 ml-1" matTooltip="{{'lang_edit' | language }}"></i>
													</span>
													
												</td>
											</tr>
										</tbody>
										<tbody
											*ngIf="!shifts?.length"
											class="AppendList"
										>
											<tr class="odd">
												<td colspan="8" align="center">{{ 'lang_no_data' | language }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					*ngIf="shifts?.length"
					class="card-footer padb0 bottomfooter"
				>
					<div class="row responsive_pagination">
						<div
							*ngIf="admin"
							class="deleteIconDiv none col-lg-1 col-sm-1 mb-10"
						>
							<i
								class="fa fa-trash-o faicon red deleteIcon"
								(click)="deleteBulkDataAth()"
								aria-hidden="true"
								matTooltip="{{ 'lang_delete' | language }}"
							></i>
						</div>
						<div
							class="col-lg-2 col-sm-2 mb-10"
							style="height: fit-content; margin: auto 0"
						>
							{{ "lang_total" | language }} :
							{{ total_records }}
						</div>
						<div
							class="col-lg-6 col-sm-6 mb-10"
							style="display: flex; justify-content: center"
						>
							<ngb-pagination
								class="m-auto"
								[collectionSize]="total_records"
								[rotate]="true"
								[ellipses]="false"
								[maxSize]="3"
								[boundaryLinks]="true"
								[(page)]="page_no"
								[pageSize]="page_size"
								(pageChange)="getByPageNo($event)"
							></ngb-pagination>
						</div>
						<div
							class="col-lg-3 col-sm-3 mb-10"
							style="display: flex; justify-content: end; margin: auto"
						>
							<mat-select
								style="max-width: 150px"
								[(ngModel)]="page_size"
								(selectionChange)="getByPageSize($event.value)"
							>
								
								<mat-option class="text-center" value="10"
									>10 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="20"
									>20 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="50"
									>50 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="100"
									>100 {{ "lang_per_page" | language }}</mat-option
								>
								<mat-option class="text-center" value="500"
									>500 {{ "lang_per_page" | language }}</mat-option
								>
							</mat-select>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</section>