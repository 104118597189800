import { NgModule } from '@angular/core';
import { CustomersRoutingModule } from './customers-routing.module';
import { CssettingsComponent } from './cssettings/cssettings.component';
import { CscreateComponent } from './cscreate/cscreate.component';
import { CslistComponent } from './cslist/cslist.component';
import { CsviewComponent } from './csview/csview.component';
import { CseditComponent } from './csedit/csedit.component';
import { SharedModule } from '@shared';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/custom-material-config';
import { CsNotesComponent } from './cs-notes/cs-notes.component';
import { CsAttachmentsComponent } from './cs-attachments/cs-attachments.component';
import { CsUsersComponent } from './cs-users/cs-users.component';
import { ProjectsComponent } from './modules/projects/projects.component';
import { QuotationRequestsComponent } from './quotation-requests/quotation-requests.component';
import { CsCommunicationComponent } from './cs-communication/cs-communication.component';

const COMPONENTS = [CssettingsComponent, CscreateComponent, CslistComponent, CsviewComponent, CseditComponent, CsNotesComponent, CsAttachmentsComponent, CsUsersComponent, ProjectsComponent];
const COMPONENTS_DYNAMIC = [];
@NgModule({
  imports: [
    SharedModule,
    CustomersRoutingModule,
    ModalModule.forRoot()
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, QuotationRequestsComponent, CsCommunicationComponent],
  entryComponents: COMPONENTS_DYNAMIC,
  providers : [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ]
})
export class CustomersModule { }
