 
	<div class="form-wrapper">
		<h3 class="mb-2">{{'lang_edit_deduction'|language}}</h3>
		<mat-divider></mat-divider>

		<form [formGroup]="form" class="form" (ngSubmit)="submitForm()">
		
			<div class="row mb-10">
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_employee_no"|language }}</div>
					<div class="mb-10">
						<b> {{this.allData?.form_det1_data?.records[0]?.employee_number}} </b>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_name"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.employee_name}} </b
						>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_salary"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.basic_salary}}</b
						>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_current_salary"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.net_amount}}</b
						>
					</div>
				</div>

				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_joining_date"|language }}</div>
					<div class="mb-10">
						<b>
							{{emp_data.joining_start_date_english}}</b
						>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="form-group">
						<label> {{'lang_type' | language}} <span class="red">*</span></label>
						<input type="text" class="form-control almnabrformcontrol" readonly placeholder="{{'lang_type' | language}}" formControlName="financial_type">
					</div>
				</div>
			  
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="form-group">
						<label for="deduction_date_english">{{'lang_date' | language }} : <span class="red">*</span></label>
						<div class="positionrelatiove">
							<input formControlName="deduction_date_english" type="text" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event, form.get('deduction_date_arabic'));" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
							<mat-datepicker-toggle matSuffix [for]="BDeng" class="posiab"></mat-datepicker-toggle>
							<mat-datepicker #BDeng disabled="false"></mat-datepicker>
							<mat-error *ngIf="submitted && form.controls['deduction_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
						</div>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="form-group">
						<label> {{'lang_amount' | language}}  <span class="red">*</span> </label>
						<input type="number" (keypress)="isNumber($event)" class="form-control almnabrformcontrol" placeholder="{{'lang_amount' | language}}" formControlName="deduction_amount">
						<mat-error *ngIf="submitted && form.controls['deduction_amount'].errors?.required">{{'lang_field_required' | language}}</mat-error>
					</div>
				</div>

				<div class="col-md-4 col-xs-12 col-sm-4 mb-10">
					<label class="mb-10">
						{{ "lang_direct_manager" | language }}
						<span class="red"> * </span> </label>
	
						<ng-select
						[items]="users"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						 
						appendTo="body"
						formControlName="direct_manager"
						placeholder="{{ 'lang_direct_manager' | language }}"
						(search)="search_users($event)"
						
						[searchFn]="ds.customSearchFn"
						 
					>
					</ng-select>
					<mat-error
						*ngIf="
						submitted &&
						form.controls['direct_manager'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				 
				</div>

				
				<div class="col-md-12 col-sm-12 col-xs-12 mb-10">
					<div class="form-group">
						<label> {{'lang_reason' | language}}  <span class="red">*</span> </label>
						  
						<textarea class="form-control"  formControlName="deduction_reason" placeholder="{{'lang_reason' | language}}" id="exampleFormControlTextarea1" rows="5"></textarea>
					  
							
						<mat-error *ngIf="submitted && form.controls['deduction_reason'].errors?.required">{{'lang_field_required' | language}}</mat-error>
					</div>
				</div>
					 
			</div>
	  
		   
		   
			<div class="row pb-3 pt-3">
				<div class="col-sm-8 col-12"></div>
				<div class="col-sm-4 col-12">
					<button mat-button type="submit" class="mnabrbutton mat-accent test" >
						{{'lang_submit'|language}}
					</button>
				</div>
			</div>
		</form>
	</div>
 






<!-- <div class="row pb-3 pt-3">
	<div class="col-sm-8 col-12"></div>
	<div class="col-sm-4 col-12">
		<button mat-button type="button" class="mnabrbutton mat-accent test" (click)="onSubmit()">
			Submit
		</button>
	</div>
</div> -->