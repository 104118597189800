<div class="card">
	<div class="width100">
		<i
			*ngIf="files && files.pass"
			matTooltip="{{ 'lang_recent_attachment' | language }} : {{
				files.pass_d
			}}"
			class="fa fa-paperclip s2icon mnabricon"
			(click)="ds.preview_pdf_file(files.pass)"
		></i>
		<div class="card-header">
			{{ "lang_human_resources_passport_details" | language }}
		</div>
		<div class="card-body pad0" [formGroup]="signupForm">
			<div class="mbrl15" formGroupName="passportDetails">
				<div class="red text-center mb-10">
					{{ "lang_needpasport_details" | language }}
				</div>
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<div class="form-group">
							<label for="passport_number"
								>{{ "lang_human_resources_passport_number" | language }} :
							</label>
							<input
								formControlName="passport_number"
								id="passport_number"
								type="text"
								placeholder="{{
									'lang_human_resources_passport_number' | language
								}}"
								class="form-control almnabrformcontrol passport_number"
							/>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<div class="form-group">
							<label for="passport_issue_date_english"
								>{{
									"lang_human_resources_passport_issue_date_english" | language
								}}
								:</label
							>
							<div class="input-group input-group-sm">
								<input
									[matDatepicker]="passport_issue_date_english"
									(dateChange)="
										ds.setENTOARDate(
											$event,
											signupForm.get('passportDetails')['controls'][
												'passport_issue_date_arabic'
											]
										)
									"
									formControlName="passport_issue_date_english"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									readonly
								/>
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="passport_issue_date_english"
									></mat-datepicker-toggle>
									<mat-datepicker #passport_issue_date_english></mat-datepicker>
								</span>
								<span class="input-group-btn">
									<button
										(click)="
											signupForm
												.get('passportDetails')
												['controls']['passport_issue_date_english'].setValue(
													''
												);
											signupForm
												.get('passportDetails')
												['controls']['passport_issue_date_arabic'].setValue(
													null
												)
										"
										matTooltip="{{ 'lang_empty' | language }}"
										type="button"
										class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
									></button>
								</span>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<div class="form-group">
							<label for="passport_issue_date_arabic"
								>{{
									"lang_human_resources_passport_issue_date_arabic" | language
								}}
								:</label
							>
							<input
								formControlName="passport_issue_date_arabic"
								[readonly]="true"
								type="text"
								placeholder="YYYY/MM/DD"
								ngbDatepicker
								(click)="ppia.toggle()"
								#ppia="ngbDatepicker"
								(ngModelChange)="
									ds.setARTOENDate(
										$event,
										signupForm.get('passportDetails')['controls'][
											'passport_issue_date_english'
										]
									)
								"
								class="form-control almnabrformcontrol passport_issue_date_arabic"
							/>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<div class="form-group">
							<label for="passport_expiry_date_english"
								>{{
									"lang_human_resources_passport_expiry_date_english" | language
								}}
								:</label
							>
							<div class="input-group input-group-sm">
								<input
									[matDatepicker]="passport_expiry_date_english"
									(dateChange)="
										ds.setENTOARDate(
											$event,
											signupForm.get('passportDetails')['controls'][
												'passport_expiry_date_arabic'
											]
										)
									"
									formControlName="passport_expiry_date_english"
									class="form-control almnabrformcontrol"
									placeholder="YYYY/MM/DD"
									readonly
								/>
								<span class="input-group-btn">
									<mat-datepicker-toggle
										class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
										matSuffix
										[for]="passport_expiry_date_english"
									></mat-datepicker-toggle>
									<mat-datepicker
										#passport_expiry_date_english
									></mat-datepicker>
								</span>
								<span class="input-group-btn">
									<button
										(click)="
											signupForm
												.get('passportDetails')
												['controls']['passport_expiry_date_english'].setValue(
													''
												);
											signupForm
												.get('passportDetails')
												['controls']['passport_expiry_date_arabic'].setValue('')
										"
										matTooltip="{{ 'lang_empty' | language }}"
										type="button"
										class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
									></button>
								</span>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<div class="form-group">
							<label for="passport_expiry_date_arabic"
								>{{
									"lang_human_resources_passport_expiry_date_arabic" | language
								}}
								:</label
							>
							<input
								formControlName="passport_expiry_date_arabic"
								id="passport_expiry_date_arabic"
								type="text"
								placeholder="YYYY/MM/DD"
								class="form-control almnabrformcontrol passport_expiry_date_arabic"
								ngbDatepicker
								(click)="ppea.toggle()"
								#ppea="ngbDatepicker"
								(ngModelChange)="
									ds.setARTOENDate(
										$event,
										signupForm.get('passportDetails')['controls'][
											'passport_expiry_date_english'
										]
									)
								"
							/>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<div class="form-group">
							<label for="passport_issue_place"
								>{{
									"lang_human_resources_passport_issue_place" | language
								}}
								:</label
							>
							<input
								formControlName="passport_issue_place"
								id="passport_issue_place"
								type="text"
								placeholder="{{
									'lang_human_resources_passport_issue_place' | language
								}}"
								class="form-control almnabrformcontrol passport_issue_place"
							/>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="passport_file_name"
								>{{ "lang_human_resources_filename" | language }} :
							</label>
							<input
								formControlName="passport_file_name"
								id="passport_file_name"
								type="text"
								placeholder="{{ 'lang_human_resources_filename' | language }}"
								class="form-control almnabrformcontrol passport_file_name"
							/>
						</div>
					</div>
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<div *ngIf="!isFile" class="opacity0" for="passport_file">
								<i aria-hidden="true" class="fa faicon fa-close red"></i>
							</div>
							<div *ngIf="isFile" for="passport_file">
								<i
									aria-hidden="true"
									(click)="removeFile()"
									class="fa faicon fa-close red"
								></i>
							</div>
							<div class="IDDIV">
								<input
									name="passport_file"
									type="file"
									onclick="this.value = null"
									(change)="uploadEDUFile($event)"
									class="nghide albutton width100"
									accept=".pdf"
								/>
								<label id="uploadIDButton" class="albutton"
									>{{ idfilename }}
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer padb0">
				<div class="row">
					<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
						<button type="button" class="albutton" matStepperPrevious>
							{{ "lang_previous" | language }}
						</button>
					</div>
					<div class="col-md-8 col-sm-6 col-xs-12 mb-10"></div>
					<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
						<button type="button" class="albutton" matStepperNext>
							{{ "lang_next" | language }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
