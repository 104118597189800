<div class="card">
	<div class="card-header">{{ "lang_project_completion" | language }}</div>
	<div class="card-body pad0">
		<mat-tab-group (selectedTabChange)="selectedListTabValue($event)">
			<mat-tab label="settings">
				<ng-template mat-tab-label>{{
					"lang_settings" | language
				}}</ng-template>
				<ng-container>
					<div class="p-2">
						<mat-tab-group class="settings-tabs">
							<mat-tab label="snaglist_groups">
								<ng-template mat-tab-label>{{
									"lang_snaglist_groups" | language
								}}</ng-template>
								<app-snaglist-groups
									[supervision_data]="supervision_data"
									[able2add]="able2add"
									[able2edit]="able2edit"
									[makeActionGroups]="makeActionGroups"
									[project_data]="project_data"
								>
								</app-snaglist-groups>
							</mat-tab>
							<mat-tab label="initial_delivery_platform">
								<ng-template mat-tab-label>{{
									"lang_initial_delivery_platform" | language
								}}</ng-template>
								<app-initial-delivery-platform
									[supervision_data]="supervision_data"
									[able2add]="able2add"
									[able2edit]="able2edit"
									[makeActionInitial]="makeActionInitial"
									[project_data]="project_data"
								>
								</app-initial-delivery-platform>
							</mat-tab>
						</mat-tab-group>
					</div>
				</ng-container>
			</mat-tab>
			<mat-tab label="initial_delivery">
				<ng-template mat-tab-label>{{
					"lang_initial_delivery" | language
				}}</ng-template>
				<ng-container>
					<app-initial-delivery
						[supervision_data]="supervision_data"
						[able2add]="able2add"
						[able2edit]="able2edit"
						[makeActionInitial]="makeActionInitial"
						[project_data]="project_data"
					>
					</app-initial-delivery>
				</ng-container>
			</mat-tab>
			<mat-tab label="snag_note_operations">
				<ng-template mat-tab-label>{{
					"lang_snag_note_operations" | language
				}}</ng-template>
				<ng-container>
					<app-snag-note-operations
						[supervision_data]="supervision_data"
						[able2add]="able2add"
						[able2edit]="able2edit"
						[makeActionOperations]="makeActionOperations"
						[project_data]="project_data"
					>
					</app-snag-note-operations>
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
