<div class="card">
	<div class="card-header d-flex align-items-center justify-content-between">
		<span>Create Payment</span>
	</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-12 col-sm-4 mb-3">
					<div class="card-item" *ngIf="!isLoading && tableContent">
						<div class="item-icon">
							<i class="fa fa-map"></i>
						</div>
						<div class="item-text">
							<h5>Total Amount</h5>
							<h4 class="d-flex align-items-center">
								paid : {{ tableContent?.paid_total_amount }} - pending :
								{{ tableContent?.pending_total_amount }}
							</h4>
						</div>
					</div>
					<ngx-skeleton-loader
						style="width: 100%"
						*ngIf="isLoading && !tableContent"
						count="1"
						appearance="circle"
						[theme]="{
							width: '100%',
							height: '70px',
							'border-radius': '10px'
						}"
					></ngx-skeleton-loader>
				</div>
				<div class="col-12 col-sm-4 mb-3">
					<div
						class="card-item"
						*ngIf="!isLoading && tableContent"
						style="background: linear-gradient(60deg, #2644da, #006dc1)"
					>
						<div class="item-icon">
							<i class="fa fa-th-large"></i>
						</div>
						<div class="item-text">
							<h5>Total Ratio</h5>
							<h4 class="d-flex align-items-center">
								paid : {{ tableContent?.paid_total_ratio }} - pending :
								{{ tableContent?.pending_total_ratio }}
							</h4>
						</div>
					</div>
					<ngx-skeleton-loader
						style="width: 100%"
						*ngIf="isLoading && !tableContent"
						count="1"
						appearance="circle"
						[theme]="{
							width: '100%',
							height: '70px',
							'border-radius': '10px'
						}"
					></ngx-skeleton-loader>
				</div>
				<div class="col-12 col-sm-4 mb-3">
					<div
						class="card-item"
						*ngIf="!isLoading && tableContent"
						style="background: linear-gradient(60deg, #26dad2, #00c174)"
					>
						<div class="item-icon">
							<i class="fa fa-road"></i>
						</div>
						<div class="item-text">
							<h5>Total Weight</h5>
							<h4 class="d-flex align-items-center">
								paid : {{ tableContent?.paid_total_weight }} - pending :
								{{ tableContent?.pending_total_weight }}
							</h4>
						</div>
					</div>
					<ngx-skeleton-loader
						style="width: 100%"
						*ngIf="isLoading && !tableContent"
						count="1"
						appearance="circle"
						[theme]="{
							width: '100%',
							height: '70px',
							'border-radius': '10px'
						}"
					></ngx-skeleton-loader>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-sm-3 mb-10">
					<button
						class="albutton mnabrbutton"
						mat-raised-button
						(click)="onFilters()"
					>
						<i class="fa fa-filter"></i>
						{{ "lang_advanced_filter" | language }}
					</button>
				</div>
				<div class="col-0 col-sm-6">
					<div
						style="display: flex; justify-content: end; margin-bottom: 10px"
						*ngIf="tableContent"
					>
						<div class="table-arrows">
							<i class="fa fa-arrow-left" (click)="scrollLeft()"></i>
							<i class="fa fa-arrow-right" (click)="scrollRight()"></i>
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-3">
					<button
						class="albutton mnabrbutton"
						mat-raised-button
						(click)="onCreate()"
						*ngIf="tableContent && makeActionList"
					>
						<i class="fa fa-newspaper-o ml-1 mr-1" style="font-size: 18px"></i>
						<span>{{ "lang_submit" | language }}</span>
					</button>
				</div>
				<div class="col-md-12" *ngIf="tableContent">
					<div class="table-container" id="table-container">
						<table class="main-table">
							<thead>
								<tr>
									<th
										style="
											color: #035a99;
											width: 27px;
											border-left: 1px solid rgba(148, 148, 148, 0.747);
										"
									></th>
									<th style="color: #035a99; left: 23px"></th>
									<th style="color: #035a99; left: 23px"></th>
									<th style="color: #035a99; left: 23px"></th>
									<th style="color: #035a99; left: 23px"></th>
									<th
										style="color: #035a99; left: 23px"
										*ngFor="let title of tableContent?.titles"
									>
										{{ title?.titlename }}
									</th>
									<th style="color: #035a99; left: 23px"></th>
									<th style="color: #035a99; left: 23px"></th>
								</tr>
								<tr>
									<th
										style="
											color: #035a99;
											width: 27px;
											border-left: 1px solid rgba(148, 148, 148, 0.747);
										"
									>
										#
									</th>
									<th style="color: #035a99; left: 23px">
										{{ "lang_villa_no" | language }}
									</th>
									<th style="color: #035a99; left: 23px">
										{{ "lang_payment_cost" | language }}
									</th>
									<th style="color: #035a99; left: 23px">
										{{ "lang_total_payment" | language }}
									</th>
									<th style="color: #035a99; left: 23px">
										{{ "lang_pending_total_amount" | language }}
									</th>
									<th
										style="color: #035a99; left: 23px"
										*ngFor="let title of tableContent?.titles"
									>
										{{ title?.subtitlename }}
									</th>
									<th style="color: #035a99; left: 23px">
										{{ "lang_ratio" | language }}
									</th>
									<th style="color: #035a99; left: 23px">
										{{ "lang_weight" | language }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let item of tableContent?.units;
										let unit_index = index
									"
								>
									<td class="sticky" style="padding: 5px">
										{{ item?.unit_id }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ item?.unit_label }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ item?.total_amount }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ item?.paid_total_amount }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ item?.pending_total_amount }}
									</td>
									<td
										class="sticky"
										style="left: 23px; padding: 5px"
										*ngFor="let title of item?.notes"
									>
										{{ title?.total_amount }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ item?.total_ratio }}
									</td>
									<td class="sticky" style="left: 23px; padding: 5px">
										{{ item?.total_weight }}
									</td>
								</tr>
								<tr>
									<td
										class="sticky"
										style="padding: 5px; font-weight: 600"
										colspan="2"
									>
										{{ "lang_total" | language }}
									</td>
									<td class="sticky" style="padding: 5px; font-weight: 600">
										{{ tableContent?.total_amount }}
									</td>
									<td class="sticky" style="padding: 5px; font-weight: 600">
										{{ tableContent?.paid_total_amount }}
									</td>
									<td class="sticky" style="padding: 5px; font-weight: 600">
										{{ tableContent?.pending_total_amount }}
									</td>
									<td
										class="sticky"
										style="padding: 5px; font-weight: 600"
										*ngFor="let title of tableContent?.titles"
									>
										{{ title?.total_amount }}
									</td>
									<td class="sticky" style="padding: 5px; font-weight: 600">
										{{ tableContent?.total_ratio }}
									</td>
									<td class="sticky" style="padding: 5px; font-weight: 600">
										{{ tableContent?.total_weight }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
