import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-transaction-filters",
	templateUrl: "./transaction-filters.component.html",
	styleUrls: ["./transaction-filters.component.scss"],
})
export class TransactionFiltersComponent implements OnInit {
	modules: any = null;
	statusList = [
		{
			label: this.lang.transform("lang_all_pending_need_action"),
			value: "all_pending_need_action",
		},
		{
			label: this.lang.transform("lang_all_pending"),
			value: "all_pending",
		},
		{
			label: this.lang.transform("lang_all_complete"),
			value: "all_complete",
		},
		{
			label: this.lang.transform("lang_all_sent"),
			value: "all_sent",
		},
	];
	admin: any = JSON.parse(this.ls.getItem("is_admin"));
	selectedItems = [];
	listData: any = [];
	formlist: any = [];
	nodata: string = this.lang.transform("lang_loading");
	searchKey: string = localStorage.getItem("M_T_S_K") || "";
	filteradmin = localStorage.getItem("M_T_A") || "0";
	searchByForm: any = localStorage.getItem("M_T_F")
		? localStorage.getItem("M_T_F")
		: null;
	searchByModule: any = localStorage.getItem("M_T_M")
		? localStorage.getItem("M_T_M")?.split(",")
		: null;
	searchByStatus: any =
		localStorage.getItem("M_T_STATUS") || "all_pending_need_action";
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		private sweetAlert: SweetAlertService,
		public dialogRef: MatDialogRef<TransactionFiltersComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.module_lists();
		this.form_lists();
	}
	public module_lists() {
		this.modules = [];
		this.ds.getActionByUrl([], "tc/getmodulesmydoclist").subscribe((res) => {
			if (res.status) {
				this.modules = res.list;
			}
		});
	}
	public form_lists() {
		this.formlist = [];
		this.ds.getActionByUrl([], "tc/gettcmydoclist").subscribe((res) => {
			if (res.status) {
				this.formlist = res.list;
			}
		});
	}
	sendFilterForm() {
		this.dialogRef.close({
			searchByForm: this.searchByForm,
			filteradmin: this.filteradmin,
			searchByModule: this.searchByModule,
			searchByStatus: this.searchByStatus,
		});
	}
	resetFilterForm() {
		this.dialogRef.close({
			searchByForm: null,
			filteradmin: "0",
			searchByModule: null,
			searchByStatus: "all_pending_need_action",
		});
	}
}
