import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-msr-step10',
  templateUrl: './msr-step10.component.html',
  styleUrls: ['./msr-step10.component.scss']
})
export class MsrStep10Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  modalRefA: BsModalRef;
  form:FormGroup;
  searchKey:any             = "";
  page                      = 1;
  psize                     = 10;
  filename                  = this.lang.transform('lang_select_files');
  myData: any               = [];
  nodata: string            = this.lang.transform('lang_loading');
  attachments:FormArray;
  stepName:string           = 'Owners_Representative';
  users:any					        = [];
  lang_key:string           = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
  constructor(public ds: DataService, private sweetAlert:SweetAlertService, public router: Router, public route: ActivatedRoute, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id             : new FormControl(this.form_request_id, Validators.required),
      notes                              : new FormControl('', [Validators.required]),
      attachments                        : new FormArray([])
    });
    this.attachments = this.form.get('attachments') as FormArray;
  }
  get filesFormGroup() {
    return this.form.get("attachments") as FormArray;
  }
  public files(value?): FormGroup {
    return this.fb.group({
      delete_icon     : new FormControl(value?.attach_title || ''),
      attach_title    : new FormControl({value : (value) ? value?.attach_title : '', disabled : (value?.attach_title) ? true : false}, [Validators.required]),
      file            : new FormControl(null,  [Validators.required]),
    });
  }
  public addfiles() {
    this.attachments.push(this.files());
  }
  public removefiles(index) {
    this.attachments.removeAt(index);
    
  }
  public search_special_approvers(key?) {
    this.users  = [];
    let param   = new FormData();
    param.append('search', key.term || '');
    param.append('lang_key', this.lang_key);
    param.append('user_type_id', '1');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.users = res.list;
      }
    });
  }

  checkValidations() {
    if(this.form.get('notes').invalid) {
      this.alert.error(this.lang.transform('lang_notes_required'));
      return false;
    } else if(this.attachments.length && this.attachments.invalid) {
      this.alert.error(this.lang.transform('lang_attachments_required'));
      return false;
    } else {
      return true;
    }
  }
  completeStep10() {
    if(this.checkValidations()) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('transaction_request_id', this.form?.value?.transaction_request_id);
      formData.append('notes', this.form?.value?.notes);
      if(this.attachments.length) {
        this.form.value.attachments.forEach((v, k) => {
          formData.append('attachments['+k+'][attach_title]', v.attach_title);
          formData.append('attachments['+k+'][file]', v.file);
        });
      }
      this.ds.post(`form/${this.form_key}/Owners_Representative/0`, formData).subscribe(data => {
        if(data.status) {
          this.spinner.hide();
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
          setTimeout(() => {
            this.refreshComponent.emit(true);
          }, 2000);
        } else {
          this.spinner.hide();
          this.alert.error(data.error);
        }
      }, (error) => {
        this.spinner.hide();
        this.alert.error(error);
      })
    }
  }
}
