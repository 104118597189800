import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { ToggleMenuService } from "@core/bootstrap/toggle-menu.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { TicketDetailsComponent } from "../../view-ticket/ticket-details/ticket-details.component";
import { TemplateTaskComponent } from "../template-task/template-task.component";

@Component({
	selector: "app-template-view",
	templateUrl: "./template-view.component.html",
	styleUrls: ["./template-view.component.scss"],
})
export class TemplateViewComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	templateID = this.route.snapshot.paramMap.get("template_id");
	newList = [];
	inprogressList = [];
	confirmList = [];
	doneList = [];
	allTasks = [];
	constructor(
		private dialog: MatDialog,
		private route: ActivatedRoute,
		public ds: DataService,
		private router: Router,
		private spinner: PreloaderService,
		private toggleMenu: ToggleMenuService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		setTimeout(() => {
			this.toggleMenu.close();
		}, 500);
		this.getTicketTasks();
	}

	//start get ticket tasks
	getTicketTasks() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.templateID || "");
		formData.append("ticket_no", "");
		formData.append("ticket_type", "");
		formData.append("users", "");
		this.ds.post("tasks/get_data_task_temp", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.allTasks = res?.data;
					for (let task of res?.data) {
						switch (task?.task_status) {
							case "1": {
								this.newList.push(task);
								break;
							}
							case "2": {
								this.inprogressList.push(task);
								break;
							}
							case "3": {
								this.confirmList.push(task);
								break;
							}
							case "4": {
								this.doneList.push(task);
								break;
							}
						}
					}
				} else {
					this.allTasks = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.allTasks = [];
			}
		);
	}
	//end get ticket tasks
	//show ticket details
	showTicketDetials() {
		const dialogRef = this.dialog.open(TicketDetailsComponent, {
			width: "800px",
			height: "400px",
			// data: {
			//   ticket: this.ticketData
			// }
		});
	}

	//open task
	openTask(task) {
		const dialogRef = this.dialog.open(TemplateTaskComponent, {
			data: { task: task },
			width: "970px",
			height: "800px",
		});
	}

	//start show task labels
	showTaskLabels(event, id) {
		event.stopPropagation();
		let labelsContainer = document.getElementById(id);
		labelsContainer.classList.contains("mscli-labels-show")
			? labelsContainer.classList.remove("mscli-labels-show")
			: labelsContainer.classList.add("mscli-labels-show");
	}
	//end show task labels

	//use template
	useTemplate() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_id", this.templateID || "");
		this.ds.post("tasks/convert_temp_to_ticket", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_data_sent_successfully"),
						1500
					);
					this.router.navigate(["/tasks/view/" + res?.data]);
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}
}
