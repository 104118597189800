import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-uploads-history",
	templateUrl: "./uploads-history.component.html",
	styleUrls: ["./uploads-history.component.scss"],
})
export class UplaodsHistoryComponent implements OnInit {
	lang;
	uploadsArray: any[] = [];
	constructor(
		public dialog: MatDialog,
		private sweetAlert: SweetAlertService,
		public spinner: PreloaderService,
		public ds: DataService,
		@Inject(MAT_DIALOG_DATA) public data,
		private dialogRef: MatDialogRef<UplaodsHistoryComponent>
	) {
		this.uploadsArray = data?.uploadsArray;
		this.lang = data?.lang;
	}

	ngOnInit(): void {}

	public downlaodFile(url, code) {
		this.spinner.show();
		this.ds.getActionByUrl([], url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					let url = "data:" + res["content-type"] + ";base64," + res.base64;

					let tagA = document.createElement("a");
					tagA.style.display = "none";
					tagA.href = url;
					tagA.download = code;
					tagA.click();
				} else {
					this.sweetAlert.errorMessage(
						res.error
							? res.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			},
			(error) => {
				this.sweetAlert.errorMessage(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);

				this.spinner.hide();
			}
		);
	}

	closeModal() {
		this.dialogRef.close();
	}
}
