import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  projects_platform_weights_unit_id: string,
  projects_platform_weights_unit_code: string,
  projects_platform_weights_unit_subject_en: string,
  projects_platform_weights_unit_subject_ar: string,
  writer:string
}
@Component({
  selector: 'app-wunits',
  templateUrl: './wunits.component.html',
  styleUrls: ['./wunits.component.scss']
})
export class WunitsComponent implements OnInit {
  modalRefA: BsModalRef;
  form:FormGroup;
  tabledatas: list[];
  collectionSize;
  page                        = 1;
  pageSize                    = 10;
  searchKey                   = "";
  lang_key                    = localStorage.getItem('lang_key') || 'en';
  data:any                    = [];
  admin                       = JSON.parse(this.ls.getItem('is_admin'));
  tabledata:any               = [];
  nodata: string              = this.lang.transform('lang_loading');
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public spinner:PreloaderService, public alert:AlertService, public modalService: BsModalService, public dialog:MatDialog, public fb:FormBuilder) { }
  ngOnInit(): void {
    this.form = new FormGroup({
      projects_platform_weights_unit_id               : new FormControl(),
      projects_platform_weights_unit_code             : new FormControl('', [Validators.required]),
      projects_platform_weights_unit_subject_en       : new FormControl('', [Validators.required]),
      projects_platform_weights_unit_subject_ar       : new FormControl('', [Validators.required]),
    });
    this.load_weight_units();
  }
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public load_weight_units() {
    let param = new FormData();
    param.append('search_key',  this.searchKey || '');
    param.append('request',     'list');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpweightsunit').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.data           = res;
        this.tabledatas     = res.records;
        this.collectionSize = res.records.length;
        this.build_table_data();
      } else {
        this.data     = [];
        this.nodata   = res.error;
      }
    }, error=> {
      this.spinner.hide();
      this.data     = [];
      this.nodata   = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public submit() {
    this.spinner.show();
    if(this.form.get('projects_platform_weights_unit_id').value) {
      this.ds.putActionByUrl(this.ds.formData2string(this.ds.json2formdata(this.form.value)), 'upweightsunit/'+this.form.get('projects_platform_weights_unit_id').value).subscribe(res=>{
        this.spinner.hide();
        if(res.status) {
          this.form.reset();
          this.load_weight_units();
          setTimeout(() => {
            this.modalRefA.hide();
            this.alert.clear();
          }, 1000);
          this.alert.success(res.msg);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    } else {
      this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'cpweightsunit').subscribe(res=>{
        this.spinner.hide();
        if(res.status) {
          this.form.reset();
          this.load_weight_units();
          setTimeout(() => {
            this.modalRefA.hide();
            this.alert.clear();
          }, 1000);
          this.alert.success(res.msg);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    }
  }
  public checkAllItemsWunt(event) {
    if (event.target.checked) {
      $(".appweightsunits .eachItemWunt").prop("checked", true);
      $(".appweightsunits .makeback").addClass("bground");
      $(".appweightsunits .deleteIconDiv").show();
    } else {
      $(".appweightsunits .eachItemWunt").prop("checked", false);
      $(".appweightsunits .makeback").removeClass("bground");
      $(".appweightsunits .deleteIconDiv").hide();
    }
  }
  public checkEachItemWunt(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appweightsunits .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appweightsunits .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appweightsunits .eachItemWunt:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appweightsunits .eachItemWunt").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appweightsunits .deleteIconDiv").show();
    } else {
      $(".appweightsunits .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appweightsunits #allItemsWunt").prop("checked", true);
    } else {
      $(".appweightsunits #allItemsWunt").prop("checked", false);
    }
  }
  public deleteBulkDataWunt() {
    const checkedtotal = [];
    $(".appweightsunits .eachItemWunt:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'dpweightsunit').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.load_weight_units();
                $(".appweightsunits #allItemsWunt").prop("checked", false);
                $(".appweightsunits .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
