<breadcrumb></breadcrumb>
<div class="row" *ngIf="project_profile_id && project_supervision_id">
  <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
    <button
      type="button"
      routerLink="/projects/BbVUrWitLE"
      class="albutton"
      mat-button
    >
      {{ "lang_all_projects" | language }}
    </button>
  </div>
  <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
    <button
      type="button"
      routerLink="/projects/LtYh/{{ project_profile_id }}"
      class="albutton"
      mat-button
    >
      {{ "lang_go_to_project_view" | language }}
    </button>
  </div>
  <div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
  <div class="col-md-4 col-sm-3 col-xs-12 mb-10">
    <button
      type="button"
      routerLink="/projects/Rpq7t/{{ project_profile_id }}/{{
        project_supervision_id
      }}"
      class="albutton"
      mat-button
    >
      {{ "lang_supervision_operations" | language }}
    </button>
  </div>
</div>

<div class="row pt-2">
  <div class="col-12">
    <mat-card style="height: 95%">
      <mat-card-title>
        {{ "lang_units" | language }}
      </mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col-12 col-sm-3 mb-3">
            <div class="card-item" *ngIf="!count_data_loading">
              <div class="item-icon">
                <i class="fa fa-map"></i>
              </div>
              <div class="item-text">
                <h5>{{ "lang_zones_no" | language }}</h5>
                <h4 class="d-flex align-items-center">
                  {{ project_setting_data?.total_no_of_zones }}
                </h4>
              </div>
            </div>
            <ngx-skeleton-loader
              style="width: 100%"
              *ngIf="count_data_loading"
              count="1"
              appearance="circle"
              [theme]="{
                width: '100%',
                height: '70px',
                'border-radius': '10px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-12 col-sm-3 mb-3">
            <div
              class="card-item"
              *ngIf="!count_data_loading"
              style="background: linear-gradient(60deg, #2644da, #006dc1)"
            >
              <div class="item-icon">
                <i class="fa fa-th-large"></i>
              </div>
              <div class="item-text">
                <h5>{{ "lang_blocks_no" | language }}</h5>
                <h4 class="d-flex align-items-center">
                  {{ project_setting_data?.total_no_of_blocks }}
                </h4>
              </div>
            </div>
            <ngx-skeleton-loader
              style="width: 100%"
              *ngIf="count_data_loading"
              count="1"
              appearance="circle"
              [theme]="{
                width: '100%',
                height: '70px',
                'border-radius': '10px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-12 col-sm-3 mb-3">
            <div
              class="card-item"
              *ngIf="!count_data_loading"
              style="background: linear-gradient(60deg, #26dad2, #00c174)"
            >
              <div class="item-icon">
                <i class="fa fa-road"></i>
              </div>
              <div class="item-text">
                <h5>{{ "lang_clusters_no" | language }}</h5>
                <h4 class="d-flex align-items-center">
                  {{ project_setting_data?.total_no_of_clusters }}
                </h4>
              </div>
            </div>
            <ngx-skeleton-loader
              style="width: 100%"
              *ngIf="count_data_loading"
              count="1"
              appearance="circle"
              [theme]="{
                width: '100%',
                height: '70px',
                'border-radius': '10px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-12 col-sm-3 mb-3">
            <div
              class="card-item"
              *ngIf="!count_data_loading"
              style="background: linear-gradient(60deg, #be26dae3, #c15000d1)"
            >
              <div class="item-icon">
                <i class="fa fa-building"></i>
              </div>
              <div class="item-text">
                <h5>{{ "lang_units_no" | language }}</h5>
                <h4 class="d-flex align-items-center">
                  {{ project_setting_data?.total_no_of_units }}
                </h4>
              </div>
            </div>
            <ngx-skeleton-loader
              style="width: 100%"
              *ngIf="count_data_loading"
              count="1"
              appearance="circle"
              [theme]="{
                width: '100%',
                height: '70px',
                'border-radius': '10px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-sm-12">
    <mat-card>
      <mat-card-title class="d-flex justify-content-between flex-wrap">
        {{ "lang_project_plan" | language }}
      </mat-card-title>
      <mat-card-content>
        <div class="row">
          <div
            class="col-12 col-sm-6 col-md-4 mb-3"
            *ngIf="is_total_price_allow"
          >
            <div class="card-item">
              <div class="item-text" style="width: 70%">
                <h5>{{ "lang_sum_total_plan" | language }}</h5>
                <h4>{{ sum_total_plan }}</h4>
              </div>
              <div class="item-icon">
                {{ last_date_plan }}
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 mb-3"
            *ngIf="is_total_price_allow"
          >
            <div class="card-item">
              <div class="item-text" style="width: 70%">
                <h5>{{ "lang_sum_actual_total" | language }}</h5>
                <h4>{{ sum_actual_total }}</h4>
              </div>
              <div class="item-icon">
                {{ last_date_plan }}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div id="axis3-chart" class="axis3-chart"></div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-sm-12">
    <mat-card>
      <mat-card-title>
        {{ "lang_project_date" | language }}
      </mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col-12 col-md-5">
            <div class="row">
              <div class="col-12 mb-4 d-flex justify-content-around">
                <span style="font-size: 20px; font-weight: 600"
                  >{{ "lang_from" | language }}:
                  {{ project_setting_data?.supervision_start_date }}</span
                >
                <span style="font-size: 20px; font-weight: 600"
                  >{{ "lang_to" | language }}:
                  {{ project_setting_data?.supervision_expiry_date }}</span
                >
              </div>
              <div class="col-12 col-md-6 mb-3">
                <div
                  class="card-item"
                  style="background: linear-gradient(60deg, #2196f3, #3f51b5)"
                >
                  <div class="item-icon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div class="item-text">
                    <h5>{{ "lang_total_days" | language }}</h5>
                    <h4>
                      {{ project_setting_data?.total_days }}
                      {{ "lang_day" | language }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <div
                  class="card-item"
                  style="background: linear-gradient(60deg, #26dad2, #00c174)"
                >
                  <div class="item-icon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div class="item-text">
                    <h5>{{ "lang_done_days" | language }}</h5>
                    <h4>
                      {{ project_setting_data?.done_days }}
                      {{ "lang_day" | language }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <div
                  class="card-item"
                  style="background: linear-gradient(60deg, #ef7067, #ed3b78)"
                >
                  <div class="item-icon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div class="item-text">
                    <h5>{{ "lang_left_days" | language }}</h5>
                    <h4>
                      {{ project_setting_data?.left_days }}
                      {{ "lang_day" | language }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <div
                  class="card-item"
                  style="background: linear-gradient(60deg, #d7688d, #cd3535)"
                >
                  <div class="item-icon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div class="item-text">
                    <h5>{{ "lang_late_days" | language }}</h5>
                    <h4>
                      {{ project_setting_data?.late_days }}
                      {{ "lang_day" | language }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-7">
            <div id="project-time-chart" class="project-time-chart"></div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-12">
    <mat-card class="report1">
      <mat-card-title class="d-flex justify-content-between flex-wrap">
        <span>{{ "lang_used_unused_report" | language }}</span>
        <span>
          <mat-slide-toggle
            *ngIf="isAdmin"
            class="ml-3 mr-3"
            [(ngModel)]="report1_trigger"
            (change)="getReport1Data(false)"
            >{{ "lang_admin" | language }}</mat-slide-toggle
          >
          <i
            class="fa fa-file-excel-o mnabricon ml-1 mr-1"
            matTooltip="{{ 'lang_export_excel' | language }}"
            (click)="getReport1Data(true)"
          ></i>
          <i
            class="fa fa-filter mnabricon ml-1 mr-1"
            matTooltip="{{ 'lang_advanced_filter' | language }}"
            (click)="onFilterReport1()"
          ></i
        ></span>
      </mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col-12 col-sm-4 col-md-2 mb-3">
            <div
              class="card-item"
              style="background: linear-gradient(60deg, #26dad2, #00c174)"
            >
              <div class="item-text" style="width: 70%">
                <h5>{{ "lang_total_ok" | language }}</h5>
                <h4>{{ report1_total_ok }}</h4>
              </div>
              <div class="item-icon">
                <i class="pi pi-thumbs-up"></i>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 col-md-2 mb-3">
            <div class="card-item">
              <div class="item-text" style="width: 70%">
                <h5>{{ "lang_total_not_used" | language }}</h5>
                <h4>{{ report1_total_notused }}</h4>
              </div>
              <div class="item-icon">
                <i class="pi pi-exclamation-circle"></i>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 col-md-2 mb-3">
            <div
              class="card-item"
              style="background: linear-gradient(60deg, #d7688d, #cd3535)"
            >
              <div class="item-text" style="width: 70%">
                <h5>{{ "lang_total_not_ok" | language }}</h5>
                <h4>{{ report1_total_notok }}</h4>
              </div>
              <div class="item-icon">
                <i class="pi pi-times-circle"></i>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 col-md-3 mb-3">
            <div
              class="card-item"
              style="background: linear-gradient(60deg, #97a1d7, #673ab7)"
            >
              <div class="item-text" style="width: 70%">
                <h5>{{ "lang_consultant_late" | language }}</h5>
                <h4>{{ lateData?.late_supervision }}</h4>
              </div>
              <div class="item-icon">
                <i class="pi pi-exclamation-circle"></i>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 col-md-3 mb-3">
            <div
              class="card-item"
              style="background: linear-gradient(60deg, #97a1d7, #673ab7)"
            >
              <div class="item-text" style="width: 70%">
                <h5>{{ "lang_contractor_late" | language }}</h5>
                <h4>{{ lateData?.late_contractor }}</h4>
              </div>
              <div class="item-icon">
                <i class="pi pi-exclamation-circle"></i>
              </div>
            </div>
          </div>
          <div class="col-0 col-sm-9"></div>
          <div class="col-12 col-sm-3 mb-1" *ngIf="report1_data">
            <div
              style="display: flex; justify-content: end; margin-bottom: 10px"
            >
              <div class="table-arrows">
                <i class="fa fa-arrow-left" (click)="scrollLeft()"></i>
                <i class="fa fa-arrow-right" (click)="scrollRight()"></i>
              </div>
            </div>
          </div>
          <div class="col-12 mt-2" *ngIf="report1_data">
            <div class="table-container" id="table-container">
              <table class="main-table">
                <thead>
                  <tr>
                    <th
                      style="
                        color: #035a99;
                        width: 27px;
                        border-left: 1px solid rgba(148, 148, 148, 0.747);
                      "
                      class="sticky"
                    >
                      #
                    </th>
                    <th style="color: #035a99; left: 23px" class="sticky">
                      {{ "lang_unit" | language }}
                    </th>
                    <th
                      *ngFor="let item of report1_data?.table_headers"
                      colspan="3"
                      style="color: #035a99; width: 100px"
                    >
                      {{ item?.platform_group1_title }}
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2" class="sticky">
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: start;
                        "
                      >
                        <mat-checkbox
                          labelPosition="after"
                          [(ngModel)]="show_ok"
                        >
                          {{ "lang_ok" | language }}
                        </mat-checkbox>
                        <mat-checkbox
                          labelPosition="after"
                          [(ngModel)]="show_notok"
                        >
                          {{ "lang_not_ok" | language }}
                        </mat-checkbox>
                        <mat-checkbox
                          labelPosition="after"
                          [(ngModel)]="show_notuse"
                        >
                          {{ "lang_not_used" | language }}
                        </mat-checkbox>
                      </div>
                    </th>
                    <th
                      *ngFor="let item of report1_data?.table_headers"
                      style="padding: 0; width: 100px"
                      colspan="3"
                    >
                      <table
                        style="width: 100%; min-height: 50px"
                        class="no-border"
                      >
                        <tr>
                          <th
                            class="no-border"
                            style="
                              border-right: 1px solid rgba(148, 148, 148, 0.747);
                              background: none;
                              color: #4c4c4c;
                            "
                            *ngIf="show_ok"
                          >
                            <i
                              class="pi pi-thumbs-up"
                              matTooltip="{{ 'lang_ok' | language }}"
                            ></i>
                          </th>
                          <th
                            class="no-border"
                            style="background: none; color: #4c4c4c"
                            *ngIf="show_notok"
                          >
                            <i
                              class="pi pi-times-circle"
                              matTooltip="{{ 'lang_not_ok' | language }}"
                            ></i>
                          </th>
                          <th
                            class="no-border"
                            style="background: none; color: #4c4c4c"
                            *ngIf="show_notuse"
                          >
                            <i
                              class="pi pi-exclamation-circle"
                              matTooltip="{{ 'lang_not_used' | language }}"
                            ></i>
                          </th>
                        </tr>
                      </table>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of report1_data?.records; let i = index">
                    <td class="sticky" style="padding: 5px">
                      {{ i + 1 }}
                    </td>
                    <td class="sticky" style="left: 23px; padding: 5px">
                      {{ item.unit_title }} ({{
                        item?.unit_ratio | number: "1.0-2"
                      }})
                    </td>
                    <td
                      *ngFor="let el of item.data"
                      colspan="3"
                      style="padding: 0"
                    >
                      <table style="width: 100%" class="no-border">
                        <tr>
                          <td
                            class="no-border"
                            *ngIf="show_ok && el.OK == 0"
                            [attr.style]="
                              el.OK != 0
                                ? 'color: #ffffff;
														cursor: pointer;
														background: #00c174;padding: 5px'
                                : 'padding: 5px'
                            "
                          >
                            <span>{{ el.OK }}</span>
                          </td>
                          <td
                            class="no-border"
                            *ngIf="show_ok && el.OK != 0"
                            [attr.style]="
                              el.OK != 0
                                ? 'color: #ffffff;
														cursor: pointer;
														background: #00c174;padding: 5px'
                                : 'padding: 5px'
                            "
                            (click)="
                              openReport1Row(
                                item.unit_id,
                                el?.division_ID,
                                'OK'
                              )
                            "
                          >
                            <span style="color: #ffffff">{{ el.OK }}</span>
                          </td>
                          <td
                            class="no-border"
                            *ngIf="show_notok && el.NOT_OK == 0"
                            [attr.style]="
                              el.NOT_OK != 0
                                ? 'color: #ffffff;
														cursor: pointer;
														background:#cd3535;padding: 5px'
                                : 'padding: 5px'
                            "
                          >
                            <span>{{ el.NOT_OK }}</span>
                          </td>
                          <td
                            class="no-border"
                            *ngIf="show_notok && el.NOT_OK != 0"
                            [attr.style]="
                              el.NOT_OK != 0
                                ? 'color: #ffffff;
														cursor: pointer;
														background:#cd3535;padding: 5px'
                                : 'padding: 5px'
                            "
                            (click)="
                              openReport1Row(
                                item.unit_id,
                                el?.division_ID,
                                'NOT_OK'
                              )
                            "
                          >
                            <span style="color: #ffffff">{{ el.NOT_OK }}</span>
                          </td>
                          <td
                            class="no-border"
                            *ngIf="show_notuse && el.NOT_USED == 0"
                            [attr.style]="
                              el.NOT_USED != 0
                                ? 'color: #ffffff;
														cursor: pointer;
														background:#00acc1;padding: 5px'
                                : 'padding: 5px'
                            "
                          >
                            <span>{{ el.NOT_USED }}</span>
                          </td>
                          <td
                            class="no-border"
                            *ngIf="show_notuse && el.NOT_USED != 0"
                            [attr.style]="
                              el.NOT_USED != 0
                                ? 'color: #ffffff;
														cursor: pointer;
														background:#00acc1;padding: 5px'
                                : 'padding: 5px'
                            "
                            (click)="
                              openReport1Row(
                                item.unit_id,
                                el?.division_ID,
                                'NOT_USED'
                              )
                            "
                          >
                            <span style="color: #ffffff">{{
                              el.NOT_USED
                            }}</span>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              *ngIf="report1_data?.records?.length > 0"
              class="col-12 mt-2"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
              "
            >
              <p>
                {{ "lang_total" | language }}:
                {{ report1_data?.pages?.total_records }}
              </p>
              <ngb-pagination
                [collectionSize]="report1_data?.pages?.total_records"
                [rotate]="true"
                [maxSize]="3"
                [boundaryLinks]="true"
                [(page)]="report1_pageno"
                [pageSize]="report1_pagesize"
                (pageChange)="getReport1Data()"
              ></ngb-pagination>
              <mat-select
                class="customselect"
                [(ngModel)]="report1_pagesize"
                (selectionChange)="getReport1Data()"
              >
                <mat-option [value]="5"
                  >5 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option [value]="10"
                  >10 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option [value]="20"
                  >20 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option [value]="30"
                  >30 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option [value]="40"
                  >40 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option [value]="50"
                  >50 {{ "lang_per_page" | language }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-12">
    <mat-card>
      <mat-card-title>
        {{ "lang_total_forms" | language }}
        <span style="font-weight: 600; margin-left: 5px"></span>
      </mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="forms-chart" id="forms-chart"></div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="report3-chart" id="report3-chart"></div>
          </div>
          <div class="col-12 top-count-requests mt-3">
            <mat-card style="box-shadow: unset !important">
              <mat-card-title class="d-flex justify-content-between flex-wrap">
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="width: 100%"
                >
                  <span>{{ "lang_top_count_requests" | language }}</span>
                  <div style="width: 100px; position: relative">
                    <input
                      type="text"
                      class="form-control mb-1"
                      id="unit_id"
                      name="unit_id"
                      placeholder="Limit"
                      (keypress)="ds?.isNumber($event)"
                      [(ngModel)]="topCountRequestsLimit"
                    />
                    <i
                      class="fa fa-search mnabricon"
                      (click)="getTopCountRequests()"
                      [attr.style]="
                        lang_key == 'ar'
                          ? 'position: absolute; left: 7px;right:unset; top: 9px; font-size: 18px'
                          : 'position: absolute; right: 7px;left:unset; top: 9px; font-size: 18px'
                      "
                    ></i>
                  </div>
                </div>
              </mat-card-title>
              <mat-card-content>
                <div class="row">
                  <div class="col-12 mt-2">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{{ "lang_platform" | language }}</th>
                            <th>
                              {{ "lang_platform_code_system" | language }}
                            </th>
                            <th>{{ "lang_work_level" | language }}</th>
                            <th>{{ "lang_total" | language }}</th>
                            <th>{{ "lang_transactions" | language }}</th>
                            <th>{{ "lang_total_units" | language }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of topCountRequests;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                          >
                            <td>{{ i + 1 }}</td>
                            <td>{{ item?.platform_subject }}</td>
                            <td>{{ item?.platform_code_system }}</td>
                            <td>{{ item?.platform_work_level_title }}</td>
                            <td
                              (click)="openTopCountRequests(item)"
                              class="link-to-popup"
                            >
                              {{ item?.total }}
                            </td>
                            <td
                              (click)="openTopCountRequests(item)"
                              class="link-to-popup"
                            >
                              {{ item?.total_transactions }}
                            </td>
                            <td
                              (click)="openTopCountRequests(item)"
                              class="link-to-popup"
                            >
                              {{ item?.total_units }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-sm-12">
    <mat-card>
      <mat-card-title class="d-flex justify-content-between">
        <span>{{ "lang_compeletion_rate" | language }}</span>
        <span>
          <i
            class="fa fa-filter mnabricon"
            matTooltip="{{ 'lang_advanced_filter' | language }}"
            (click)="openAverageFilters()"
          ></i>
        </span>
      </mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-md-3 mb-4">
                <div
                  class="card-item"
                  style="background: linear-gradient(60deg, #688bd7, #35aacd)"
                >
                  <div class="item-icon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div
                    class="item-text"
                    style="align-items: start; width: 100%"
                  >
                    <h5
                      style="
                        font-size: 16px;
                        text-transform: capitalize;
                        letter-spacing: normal;
                        font-weight: 600;
                      "
                    >
                      {{ "lang_from" | language }} : {{ from_date_compeletion }}
                    </h5>
                    <h4 style="font-size: 16px; font-weight: 600">
                      {{ "lang_to" | language }} : {{ to_date_compeletion }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 mb-4">
                <div
                  class="card-item"
                  style="
                    background: linear-gradient(60deg, #689ad7, #2860c7);
                    flex-direction: column;
                    padding: 0px;
                    padding-top: 10px;
                  "
                >
                  <div
                    class="item-text"
                    style="
                      flex-direction: row;
                      justify-content: space-between;
                      padding: 16px;
                      width: 100%;
                      flex-wrap: wrap;
                    "
                  >
                    <h5
                      style="
                        font-size: 1rem;
                        font-weight: 600;
                        margin-bottom: 0;
                      "
                    >
                      {{ "lang_total_ratio" | language }}
                    </h5>
                    <h4 style="font-size: 1rem; font-weight: 600">
                      {{ project_total_ratio }} %
                    </h4>
                  </div>
                  <mat-progress-bar
                    mode="determinate"
                    [value]="project_total_ratio"
                    style="
                      margin: 0;
                      height: 5px !important;
                      border-bottom-left-radius: 5px;
                      border-bottom-right-radius: 5px;
                    "
                  ></mat-progress-bar>
                </div>
              </div>
              <div class="col-12 col-md-3 mb-4">
                <div
                  class="card-item"
                  style="
                    background: linear-gradient(60deg, #7780eb, #1e79e9);
                    flex-direction: column;
                    padding: 0px;
                    padding-top: 10px;
                  "
                >
                  <div
                    class="item-text"
                    style="
                      flex-direction: row;
                      justify-content: space-between;
                      padding: 16px;
                      width: 100%;
                      flex-wrap: wrap;
                    "
                  >
                    <h5
                      style="
                        font-size: 1rem;
                        font-weight: 600;
                        margin-bottom: 0;
                      "
                    >
                      {{ "lang_total_weight" | language }}
                    </h5>
                    <h4 style="font-size: 1rem; font-weight: 600">
                      {{ total_weight_result }} %
                    </h4>
                  </div>
                  <mat-progress-bar
                    mode="determinate"
                    [value]="total_weight_result"
                    style="
                      margin: 0;
                      height: 5px !important;
                      border-bottom-left-radius: 5px;
                      border-bottom-right-radius: 5px;
                    "
                  >
                  </mat-progress-bar>
                </div>
              </div>
              <div class="col-12 col-md-3 mb-4">
                <div
                  class="card-item"
                  style="
                    background: linear-gradient(60deg, #689ad7, #2860c7);
                    flex-direction: column;
                    padding: 0px;
                    padding-top: 10px;
                  "
                >
                  <div
                    class="item-text"
                    style="
                      flex-direction: row;
                      justify-content: space-between;
                      padding: 16px;
                      width: 100%;
                      flex-wrap: wrap;
                    "
                  >
                    <h5
                      style="
                        font-size: 1rem;
                        font-weight: 600;
                        margin-bottom: 0;
                      "
                    >
                      {{ "lang_total_opening" | language }}
                    </h5>
                    <h4 style="font-size: 1rem; font-weight: 600">
                      {{ total_opening }} %
                    </h4>
                  </div>
                  <mat-progress-bar
                    mode="determinate"
                    [value]="project_total_ratio"
                    style="
                      margin: 0;
                      height: 5px !important;
                      border-bottom-left-radius: 5px;
                      border-bottom-right-radius: 5px;
                    "
                  >
                  </mat-progress-bar>
                </div>
              </div>
              <div class="col-md-6">
                <div id="zone-result-chart1" class="zone-result-chart"></div>
              </div>
              <div class="col-md-6">
                <div id="zone-result-chart2" class="zone-result-chart"></div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="row">
              <div class="col-md-6">
                <div
                  id="division-result-chart1"
                  class="division-result-chart"
                ></div>
              </div>
              <div class="col-md-6">
                <div
                  id="division-result-chart2"
                  class="division-result-chart"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-sm-12" *ngIf="is_zone_price_allow || is_division_price_allow">
    <mat-card>
      <mat-card-title class="d-flex justify-content-between flex-wrap">
        <span>{{ "lang_project_price" | language }}</span>
        <span
          ><i
            class="fa fa-filter mnabricon ml-1 mr-1"
            matTooltip="{{ 'lang_advanced_filter' | language }}"
            (click)="openPriceFilters()"
          ></i
        ></span>
      </mat-card-title>
      <mat-card-content>
        <div class="row">
          <div
            class="col-12 col-sm-6 col-md-4 mb-3"
            *ngIf="is_total_price_allow"
          >
            <div class="card-item">
              <div class="item-text" style="width: 70%">
                <h5>
                  {{ "lang_projects_total_construction_cost" | language }}
                </h5>
                <h4>{{ convertNumToCurrency(total_construction_cost) }}</h4>
              </div>
              <div class="item-icon">
                {{ lang_key == "ar" ? "ريال" : "Riyal" }}
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 mb-3"
            *ngIf="is_total_price_allow"
          >
            <div class="card-item">
              <div class="item-text" style="width: 70%">
                <h5>{{ "lang_total_price" | language }}</h5>
                <h4>{{ convertNumToCurrency(total_project_price) }}</h4>
              </div>
              <div class="item-icon">
                {{ lang_key == "ar" ? "ريال" : "Riyal" }}
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 mb-3"
            *ngIf="is_total_price_allow"
          >
            <div class="card-item">
              <div class="item-text" style="width: 70%">
                <h5>{{ "lang_total_opening" | language }}</h5>
                <h4>{{ convertNumToCurrency(total_opening_price) }}</h4>
              </div>
              <div class="item-icon">
                {{ lang_key == "ar" ? "ريال" : "Riyal" }}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div id="zone-price-chart" class="zone-price-chart"></div>
          </div>
          <div class="col-12">
            <div id="division-price-chart" class="division-price-chart"></div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
