import { DatePipe } from "@angular/common";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import * as moment from "moment";

@Component({
  selector: "app-createEpp",
  templateUrl: "./create_epp.component.html",
  styleUrls: ["./create_epp.component.scss"],
})
export class CreateEPPComponent implements OnInit {
  @Output() loanEvent = new EventEmitter();
  @Input() employee_data;
  @Input() form_request_id;
  @Input() contract;
  @Input() form_key;
  @Input() id;
  emp_data: any = "";
  users: any = [];
  direct_anager: any = [];
  form: FormGroup;
  submitted: boolean = false;
  lang_key = localStorage.getItem("lang_key") || "en";
  constructor(
    public ds: DataService,
    public datepipe: DatePipe,
    private snackBar: MatSnackBar,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public fb: FormBuilder,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    private sweetAlert: SweetAlertService
  ) {}
  min = new Date();
  ngOnInit(): void {
    /*   this.spinner.show(); */
    moment.locale("en");
    this.form = this.fb.group({
      employee_number: [""],
      extend_probation_period: ["", [Validators.required, Validators.max(180)]],
      extra_probation_start_date_english: [null, Validators.required],
      extra_probation_start_date_arabic: [null, Validators.required],
      extra_probation_end_date_english: [null, Validators.required],
      extra_probation_end_date_arabic: [null, Validators.required],
      direct_manager: [null, Validators.required],
    });
    // this.contract.extra_probation_end_date_english
    // 			? new Date(this.contract.extra_probation_end_date_english)
    // 			: new Date()
    this.getStartDate();
  }
  setValueToFields(obj, enField: AbstractControl, ArField: AbstractControl) {
    enField.setValue(obj.enField);
    ArField.setValue(obj.arField);
  }

  calculateEndDate() {
    if (
      this.form.value.extend_probation_period > 180 ||
      !this.form.value.extra_probation_start_date_english ||
      !this.form.value.extend_probation_period
    ) {
      return 0;
    }
    let end = moment(this.form.value.extra_probation_start_date_english)
      .add(this.form.value.extend_probation_period, "day")
      .format();
    this.form.patchValue({
      extra_probation_end_date_english: new Date(end),
    });
    this.ds.setENTOARDate(
      this.form.get("extra_probation_end_date_english"),
      this.form.get("extra_probation_end_date_arabic")
    );
  }

  calculatePeriodDate() {
    if (
      this.form.value.extend_probation_period > 180 ||
      !this.form.value.extra_probation_start_date_english ||
      !this.form.value.extra_probation_end_date_english
    ) {
      return 0;
    }
    let diff = moment(this.form.value.extra_probation_end_date_english).diff(
      moment(this.form.value.extra_probation_start_date_english),
      "day"
    );

    this.form.patchValue({
      extend_probation_period: diff,
    });
    this.ds.setENTOARDate(
      this.form.get("extra_probation_end_date_english"),
      this.form.get("extra_probation_end_date_arabic")
    );
  }

  getStartDate() {
    let formData = new FormData();
    formData.append(
      "employee_number",
      this.employee_data.employee_number || ""
    );
    this.ds.post("form/FORM_EPP/get_start_epp_date", formData).subscribe(
      (res) => {
        if (res?.status) {
          this.form
            .get("extra_probation_start_date_english")
            .setValue(new Date(res?.records));
          this.ds.setENTOARDate(
            {
              value: this.form.get("extra_probation_start_date_english").value,
            },
            this.form.get("extra_probation_start_date_arabic")
          );
        } else {
          this.sweetAlert.errorToast(res.error, 3000);
          this.router.navigate(["/human_resources/employees"]);
        }
      },
      (error) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(
          error.error + (error && error.error && error.error.error)
            ? error.error.error
            : this.lang.transform("lang_internal_server_error"),
          3000
        );
        this.router.navigate(["/human_resources/employees"]);
      }
    );
  }

  submit() {
    this.submitted = true;
    if (this.form?.status == "VALID") {
      this.spinner.show();
      let formData = new FormData();
      formData.append("employee_number", this.employee_data.employee_number);
      formData.append(
        "extra_probation_start_date_english",
        moment(this.form.value.extra_probation_start_date_english).format(
          "YYYY/MM/DD"
        )
      );
      formData.append(
        "extra_probation_start_date_arabic",
        moment(
          this.form.value.extra_probation_start_date_arabic,
          "DD/MM/YYYY"
        ).format("YYYY/MM/DD")
      );
      formData.append(
        "extra_probation_end_date_english",
        moment(this.form.value.extra_probation_end_date_english).format(
          "YYYY/MM/DD"
        )
      );
      formData.append(
        "extra_probation_end_date_arabic",
        moment(
          this.form.value.extra_probation_end_date_arabic,
          "DD/MM/YYYY"
        ).format("YYYY/MM/DD")
      );
      formData.append(
        "extend_probation_period",
        this.form.value.extend_probation_period
      );

      formData.append("direct_manager", this.form.value.direct_manager || "");

      this.ds.post(`form/${this.form_key}/cr/0`, formData).subscribe(
        (data) => {
          if (data.status) {
            this.alert.success(
              data.msg || this.lang.transform("lang_data_sent_successfully")
            );
            this.submitted = false;
            this.form.reset();
            setTimeout(() => {
              this.router.navigate(["transactions/allforms/"]);

              /* if(this.lang_key == 'ar'){
          this.showMsg('تم انشاء السلفة بنجاح', 'ok', 'success-snackbar');
        }else{
          this.showMsg('Loan Created Successfully', 'ok', 'success-snackbar');
        } */
            }, 1000);
          } else {
            this.alert.error(data.error);
          }

          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(error);
        }
      );
    } else {
      this.showMsg("Please fill all fields first", "ok", "error-snackbar");
    }
  }

  public isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;

    if (charCode == 44 || charCode == 190) {
      return true;
    }
    if (
      (charCode > 31 && (charCode < 48 || charCode > 57 || charCode == 101)) ||
      charCode == 46
    ) {
      return false;
    }
    return true;
  }
  showMsg(msg: string, action: string, className: string) {
    this.snackBar.open(msg, action, {
      duration: 4000,
      panelClass: [className],
    });
  }

  public search_users(key) {
    if (key) {
      this.users = [];
      let param = new FormData();
      param.append("search", key.term);
      param.append("lang_key", this.lang_key);
      param.append("user_type_id", "1");
      this.ds
        .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
        .subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            this.users = res.list;
          }
        });
    }
  }
}
