<form [formGroup]="worklevelForm">
	<div
		mat-dialog-title
		class="d-flex align-items-center justify-content-between"
	>
		<h2 style="font-size: 18px; margin: 0">{{ "lang_create" | language }}</h2>
		<i class="fa fa-times mnabricon" mat-dialog-close></i>
	</div>
	<mat-dialog-content class="mat-typography">
		<div class="row">
			<div class="col-12 col-sm-6">
				<ng-select
					[items]="templateList"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					clearAllText="Clear"
					[searchFn]="ds.customSearchFn"
					appendTo="body"
					formControlName="template_id"
					placeholder="{{ 'lang_template' | language }}"
					(change)="onTemplateChange($event)"
				>
				</ng-select>
			</div>
			<div
				class="col-12 mt-4 TableParts"
				*ngIf="worklevelForm?.get('template_id').valid"
			>
				<div
					class="table-responsive customResponsive tablefields quotationitemtable"
				>
					<table
						border="1"
						class="table CustomTable"
						width="100%"
						formArrayName="platforms"
					>
						<thead>
							<tr>
								<td width="2%" style="padding: 5px; word-break: break-all">
									#
								</td>
								<td width="2%" style="padding: 5px; word-break: break-all">
									<mat-icon
										class="mnabricon"
										matTooltip="{{ 'lang_add' | language }}"
										(click)="addWorklevels()"
										>add_box</mat-icon
									>
								</td>
								<td width="48%" style="padding: 5px; word-break: break-all">
									{{ "lang_platform" | language }}
								</td>
								<td width="48%" style="padding: 5px; word-break: break-all">
									{{ "lang_worklevel" | language }}
								</td>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let worklevel of worklevelsGroup.controls;
									let i = index;
									let odd = odd;
									let even = even
								"
								[formGroupName]="i"
							>
								<td
									class="verticalalignmid"
									style="padding: 5px; word-break: break-all"
								>
									{{ i + 1 }}
								</td>
								<td class="verticalalignmid">
									<mat-icon
										(click)="removeWorklevels(i)"
										class="mnabricon red"
										matTooltip="{{ 'lang_delete' | language }}"
										>delete_forever</mat-icon
									>
								</td>
								<td
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									<ng-select
										[items]="other_platforms.controls[i].get('platforms').value"
										[multiple]="false"
										bindValue="value"
										clearAllText="Clear"
										[searchFn]="ds.customSearchFn"
										appendTo="body"
										formControlName="platform_code_system"
										placeholder="{{ 'lang_platform_code_systems' | language }}"
										(search)="getPlatforms($event, i)"
										(change)="getWorklevels(i)"
									>
										<ng-template ng-label-tmp let-item="item"
											>{{ item?.label }} ({{ item?.value }})</ng-template
										>
										<ng-template ng-option-tmp let-item="item"
											>{{ item?.label }} ({{ item?.value }})</ng-template
										>
									</ng-select>
								</td>
								<td
									class="verticalalignmid"
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									<ng-select
										[items]="
											other_platforms.controls[i].get('worklevelList').value
										"
										[multiple]="false"
										bindLabel="label"
										bindValue="value"
										clearAllText="Clear"
										[searchFn]="ds.customSearchFn"
										appendTo="body"
										formControlName="work_levels"
										placeholder="{{ 'lang_work_levels' | language }}"
									>
									</ng-select>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions class="justify-content-between align-items-center">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			style="width: 150px !important"
			mat-dialog-close
		>
			{{ "lang_cancel" | language }}
		</button>
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			style="width: 150px !important"
			type="submit"
			(click)="submit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</mat-dialog-actions>
</form>
