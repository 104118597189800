<div class="card mt-3 user_permissions">
	<div class="card-header d-flex justify-content-between">
		<span>{{ "lang_permit_mentions" | language }}</span>
		<i
			(click)="onAddPermission()"
			class="fa fa-plus s2icon mnabricon"
			matTooltip="{{ 'lang_permit_mentions' | language }}"
		></i>
	</div>
	<div class="card-body">
		<div class="material-panel-body">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<ng-select
							[items]="branches"
							[multiple]="false"
							bindLabel="label"
							bindValue="value"
							clearAllText="Clear"
							appendTo="body"
							[(ngModel)]="searchBranch"
							placeholder="{{ 'lang_search_branch' | language }}"
							(change)="spinner.show(); load_permission_mention()"
						>
						</ng-select>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<ng-select
							[items]="groups"
							[multiple]="false"
							bindLabel="label"
							bindValue="value"
							clearAllText="Clear"
							appendTo="body"
							[(ngModel)]="searchGroup"
							placeholder="{{ 'lang_group' | language }}"
							(change)="spinner.show(); load_permission_mention()"
						>
						</ng-select>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<ng-select
							[items]="users"
							[multiple]="false"
							bindLabel="label"
							bindValue="value"
							clearAllText="Clear"
							appendTo="body"
							[(ngModel)]="searchUser"
							placeholder="{{ 'lang_users' | language }}"
							(change)="spinner.show(); load_permission_mention()"
						>
						</ng-select>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="table-responsive dropdownyes customResponsive">
							<table border="1" class="table CustomTable">
								<thead>
									<tr>
										<td>#</td>
										<td>
											<div
												class="checkbox"
												matTooltip="{{ 'lang_check_all' | language }}"
											>
												<input
													type="checkbox"
													class="allItemsUsr checkbox"
													id="allItemsUsr"
													(click)="checkAllItemsUsr($event)"
												/>
												<label for="checkbox"></label>
											</div>
										</td>
										<td>{{ "lang_username" | language }}</td>
										<td>{{ "lang_branch" | language }}</td>
										<td>{{ "lang_group" | language }}</td>
										<td>{{ "lang_permission" | language }}</td>
										<td>{{ "lang_writer" | language }}</td>
										<td>{{ "lang_ondate" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="pmdata.length != '0'" class="AppendList">
									<tr
										*ngFor="
											let list of pmdata.records;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
										class="makeback removerow{{ list.mention_id }}"
									>
										<td>{{ i + 1 }}</td>
										<td>
											<div
												class="checkbox"
												matTooltip="{{ 'lang_check' | language }}"
											>
												<input
													type="checkbox"
													class="eachItemUsr checkbox"
													value="{{ list.mention_id }}"
													(click)="checkEachItemUsr($event)"
												/>
												<label for="checkbox"></label>
											</div>
										</td>
										<td *ngIf="list.mention_name">{{ list.mention_name }}</td>
										<td *ngIf="!list.mention_name">null</td>
										<td>{{ list.branch_name }}</td>
										<td>{{ list.group_name }}</td>
										<td *ngIf="list.private_value == 'all'">
											{{ list.permitname }} ({{ "lang_all" | language }})
										</td>
										<td *ngIf="list.private_value != 'all'">
											{{ list.permitname }} (ID - {{ list.private_value }})
										</td>
										<td *ngIf="list.creator_name">{{ list.creator_name }}</td>
										<td *ngIf="!list.creator_name">null</td>
										<td>{{ list.create_date }}</td>
									</tr>
								</tbody>
								<tbody *ngIf="pmdata.length == '0'" class="AppendList">
									<tr class="odd">
										<td colspan="8" align="center">{{ lodingdatas }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="pmdata?.records?.length != '0'" class="card-footer padb0">
				<div class="row responsive_pagination">
					<div
						*ngIf="able2delete"
						class="deleteIconDiv none col-lg-1 col-sm-1"
						style="margin: 0; align-items: center; justify-content: center"
					>
						<i
							class="fa fa-trash-o faicon red deleteIcon"
							(click)="deleteBulkDataUsr()"
							matTooltip="{{ 'lang_delete' | language }}"
						></i>
					</div>
					<div
						class="col-lg-3 col-sm-3 mb-10"
						style="height: fit-content; margin: auto 0"
					>
						{{ "lang_total" | language }} : {{ pmdata?.page?.total_records }}
					</div>
					<div
						class="col-lg-5 col-sm-5 mb-10"
						style="display: flex; justify-content: center"
					>
						<ngb-pagination
							class="m-auto"
							[collectionSize]="pmdata?.page?.total_records"
							[rotate]="true"
							[ellipses]="false"
							[maxSize]="3"
							[boundaryLinks]="true"
							[(page)]="pmpage_no"
							[pageSize]="pmpage_size"
							(pageChange)="loadpage($event)"
						></ngb-pagination>
					</div>
					<div
						class="col-lg-3 col-sm-3"
						style="display: flex; justify-content: end; margin: auto"
					>
						<mat-select
							[(ngModel)]="pmpage_size"
							(selectionChange)="loadpagesize($event.value)"
						>
							<mat-option class="text-center" value="10"
								>10 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="20"
								>20 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="50"
								>50 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="100"
								>100 {{ "lang_per_page" | language }}</mat-option
							>
							<mat-option class="text-center" value="500"
								>500 {{ "lang_per_page" | language }}</mat-option
							>
						</mat-select>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
