import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import {
	FormGroup,
	FormArray,
	FormBuilder,
	Validators,
	FormControl,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import * as moment from "moment";

declare var $: any;
@Component({
	selector: "bonus-step-one-configrations",
	templateUrl: "./step-one-configrations.component.html",
	styleUrls: ["./step-one-configrations.component.scss"],
})
export class BonusStepOneConfigrationsComponent implements OnInit {
	@Input() allData;

	@Input() form_request_id;
	@Input() form_key;
	@Input() id;

	users: any = [];
	direct_anager: any = [];
	form: FormGroup;
	submitted: boolean = false;
	lang_key = localStorage.getItem("lang_key") || "en";
	constructor(
		public ds: DataService,
		private snackBar: MatSnackBar,
		public datepipe: DatePipe,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public changeDetectorRef: ChangeDetectorRef
	) {}
	emp_data: any = "";
	ngOnInit(): void {
		this.spinner.show();

		this.form = this.fb.group({
			financial_type: ["Bonus"],
			employee_number: [
				this.allData?.form_bns1_data?.records[0]?.employee_number,
			],
			bonus_date_english: [
				moment(
					this.allData?.form_bns1_data?.records[0]?.employee_number
				).format(),
				Validators.required,
			],
			bonus_date_arabic: [
				this.allData?.form_bns1_data?.records[0]?.bonus_date_arabic,
				Validators.required,
			],
			bonus_amount: [
				this.allData?.form_bns1_data?.records[0]?.bonus_amount,
				Validators.required,
			],
			direct_manager: [
				this.allData?.form_bns1_data?.records[0]?.direct_manager,
				Validators.required,
			],
		});

		this.users = [
			{
				label: this.allData?.form_bns1_data?.records[0]?.direct_manager_name,
				value: this.allData?.form_bns1_data?.records[0]?.direct_manager,
			},
		];

		let data = new FormData();
		data.append(
			"employee_number",
			this.allData?.form_bns1_data?.records[0]?.employee_number
		);

		this.spinner.show();
		this.ds.post(`human_resources/get_emp_details`, data).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.emp_data = res.records;
				} else {
					this.spinner.hide();
					this.showMsg(
						res.error || this.lang.transform("lang_employee_have_no_contract"),
						"ok",
						"error-snackbar"
					);
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
		this.spinner.hide();
	}

	submitForm() {
		this.submitted = true;
		if (this.form?.status == "VALID") {
			this.spinner.show();
			let formData = new FormData();
			formData.append(
				"transaction_request_id",
				this.allData?.form_bns1_data?.records[0]?.transaction_request_id
			);
			formData.append(
				"employee_number",
				this.allData?.form_bns1_data?.records[0]?.employee_number
			);
			formData.append(
				"bonus_date_english",
				moment(this.form.value.bonus_date_english).format("YYYY/MM/DD")
			);
			formData.append("bonus_date_arabic", this.form.value.bonus_date_arabic);
			formData.append("bonus_amount", this.form.value.bonus_amount);

			formData.append("direct_manager", this.form.value.direct_manager || "");
			this.ds.post(`form/${this.form_key}/et`, formData).subscribe(
				(data) => {
					if (data.status) {
						this.showMsg(data.msg, "ok", "success-snackbar");

						//this.form.reset()
						setTimeout(() => {
							location.reload();
						}, 1000);
						this.spinner.hide();
					} else {
						this.spinner.hide();
						this.showMsg(data.error, "ok", "error-snackbar");
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(error);
				}
			);
		} else {
			this.showMsg("Please fill all fields first", "ok", "error-snackbar");
		}
	}

	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 4000,
			panelClass: [className],
		});
	}

	public search_users(key) {
		if (key) {
			this.users = [];
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.users = res.list;
					}
				});
		}
	}

	public isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 101 || charCode == 69) {
			return false;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
}
