import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormArray, FormBuilder, FormControl, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-dynamic-files-control',
  templateUrl: './dynamic-files-control.component.html',
  styleUrls: ['./dynamic-files-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicFilesControlComponent),
      multi: true
    }
  ]
})
export class DynamicFilesControlComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() actionMode;
  @Input() dynamic_attachments;
  @Input() required;
  @Output() getAttachments: EventEmitter<any> =   new EventEmitter();
  @Input() rawIndex:any;
  submitted:boolean = false;
  form:FormGroup;
  attachments:FormArray;
  modalRefA: BsModalRef;
  modalRefV: BsModalRef;
  filename = this.lang.transform('lang_select_files');
  attachmentData:any = [];
  constructor(private cdr: ChangeDetectorRef, public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnChanges(changes: SimpleChanges): void {
  }
  writeValue(obj: any): void {
    this.attachments.controls = [];
    if(obj && obj.length > 0) {
      obj.forEach((v, k) => {
        this.addfiles();
        if(this.actionMode == "VIEW") {
          this.attachments.controls[k].get('file').disable();
        }
        if(v.file && v.file.name) {
          setTimeout(() => {
            $("#uploadText" + k).text(v.file.name);
          }, 100);
        } else {
          $("#uploadText" + k).text(this.lang.transform('lang_select_files'));
        }
      });
    }
  }
  registerOnChange(fn: any): void {
    this.appendvalue(fn);
  }
  registerOnTouched(fn: any): void {
    this.appendvalue(fn);
  }
  setDisabledState?(isDisabled: boolean): void {}
  appendvalue(fn) {
    fn(this.filesFormGroup.value);
    this.form.valueChanges.subscribe(x => {
      fn(this.filesFormGroup.value);
    });
  }
  //add, edit model
  public openModalA(template: TemplateRef<any>) {
    if(this.attachments.controls.length == 0) {
      this.addfiles();
    }
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  ngOnInit(): void {
    //this.attachments.controls = [];
    this.form = new FormGroup({
      attachments : this.fb.array([]),
    });
    this.attachments = this.form.get('attachments') as FormArray;
    this.attachments.controls = [];
    if(this.actionMode == "ADD") {
      this.addfiles();
    }
  }
  public files(): FormGroup {
    return this.fb.group({
      file          : new FormControl('', this.required ? [Validators.required] : null),
      attach_title  : new FormControl('', this.required ? [Validators.required] : null)
    });
  }
  get filesFormGroup() {
    return this.form.get("attachments") as FormArray;
  }
  public getfilesgroup(index): FormGroup {
    return this.attachments.controls[index] as FormGroup;
  }
  public addfiles() {
    if (this.attachments.length < 5) {
      this.attachments.push(this.files());
    }
  }
  public removefiles(index) {
    this.attachments.removeAt(index);
    if (this.attachments.length > 1) {
      this.attachments.removeAt(index);
    }
  }
  public UploadFile(event, index) {
    if (event.target.files[0] && event.target.files[0] !== undefined && event.target.files[0].name) {
      const fileid = event.target.files[0];
      $("#uploadText" + index).text(fileid.name);
      this.attachments.controls[index].get('file').setValue(fileid, { emitEvent: true });
    } else {
      this.attachments.controls[index].get('file').setValue(null);
      $("#uploadText" + index).text(this.lang.transform('lang_select_files'));
    }
  }
  public closemodel() {
    if(this.required && this.form.valid) {
      this.modalRefA.hide();
    } else if(!this.required) {
      this.modalRefA.hide();
    } else if(this.actionMode == "VIEW") {
      this.modalRefA.hide();
    } else {
      this.alert.error(this.lang.transform('lang_all_fields_required'));
    }
  }
  public submit() {
    this.submitted = true;
    if(this.form?.valid) {
      this.submitted = false;
      this.getAttachments.emit({form:this.filesFormGroup, index:this.rawIndex});
    }
  }
  public getdataifany() {
    this.filesFormGroup.value.forEach((v, k) => {
      if(v.file && v.file.name) {
        setTimeout(() => {
          $("#uploadText" + k).text(v.file.name);
        }, 100);
      } else {
        $("#uploadText" + k).text(this.lang.transform('lang_select_files'));
      }
    });
  }
}