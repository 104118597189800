<section class="card matborderno activeiconstepper customdesignstepper">
	<mat-accordion class="stylepanels">
		<mat-expansion-panel class="mb-2" [expanded]="true">
			<mat-expansion-panel-header
				>{{ "lang_form_details" | language }}
			</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div
						class="row"
						[ngStyle]="
							lang_key == 'ar'
								? {
										'padding-left': '10px',
										'padding-right': '10px'
								  }
								: null
						"
					>
						<div class="col-xs-12 col-12 pad0">
							<mat-horizontal-stepper
								#stepper
								class="pad0 steps13-stepper"
								[linear]="isLinear"
								[selectedIndex]="currentStep"
								(selectionChange)="selectionChange($event)"
							>
								<mat-step>
									<app-pq1-step1
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[branch_id]="branch_id"
										[projects_profile_id]="project_id"
										[projects_ps_id]="service_id"
										[editMode]="false"
										*ngIf="selectedIndex == 0"
									></app-pq1-step1>
								</mat-step>
							</mat-horizontal-stepper>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="card-footer">
		<div class="row">
			<div class="col-sm-3 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
			<div class="col-sm-6 col-12"></div>
			<div class="col-sm-3 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/projects/LtYh/1"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_project_view" | language }}
				</button>
			</div>
		</div>
	</div>
</section>
