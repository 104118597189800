<form [formGroup]="form" (ngSubmit)="completeStep9()">
	<div class="card owners_approval_notes">
		<div class="card-header">
			{{ "lang_manager_approval" | language }}
			<i
				style="padding: 0 10px"
				class="fa fa-info-circle orangestatus-color"
				matTooltip="{{ 'lang_manager_approval_tooltip' | language }}"
			></i>
		</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row">
					<div class="col-sm-12 col-xs-12 mb-10">
						<label
							>{{ "lang_manager_approval" | language }} !?.
							<span class="red"> * </span>
						</label>
						<br />
						<mat-radio-group
							aria-label="Select an option"
							formControlName="manager_approval_approval_status"
						>
							<mat-radio-button value="Return">{{
								"lang_return_to_technical_assistant" | language
							}}</mat-radio-button>
							<mat-radio-button value="Approve">{{
								"lang_approve" | language
							}}</mat-radio-button>
						</mat-radio-group>
					</div>
					<div
						class="col-sm-12 col-xs-12 mb-10"
						*ngIf="
							form.get('manager_approval_approval_status').value == 'Approve'
						"
					>
						<label>
							{{ "lang_customer_representative_required" | language }} !?.
							<span class="red"> * </span> </label
						><br />
						<mat-radio-group
							aria-label="Select an option"
							formControlName="customr_representative_required"
						>
							<mat-radio-button value="No">
								{{ "lang_no" | language }}</mat-radio-button
							>
							<mat-radio-button value="Yes">
								{{ "lang_yes" | language }}
								<span
									class="bold red"
									*ngIf="
										form.get('customr_representative_required').value == 'Yes'
									"
									>( {{ "lang_note" | language }} :
									{{ "lang_customer_representative_note" | language }} )</span
								>
								<!-- راي الاستشاري الفني في هذا الطلب لايعتد به الا بعد
									قرار المالك -->
							</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
				<br />
				<div class="row mb-10">
					<div
						class="col-sm-12 col-xs-12 mb-10"
						*ngIf="
							form.get('manager_approval_approval_status').value == 'Return'
						"
					>
						<div>
							<label
								>{{ "lang_rejected_notes" | language }} :
								<span class="red">*</span></label
							>
							<textarea
								class="form-control almnabrformcontrol"
								formControlName="rejected_notes"
							></textarea>
						</div>
					</div>
					<div
						class="col-sm-12 col-xs-12 mb-10"
						*ngIf="
							form.get('customr_representative_required').value == 'Yes' &&
							form.get('manager_approval_approval_status').value == 'Approve'
						"
					>
						<div>
							<label>
								{{ "lang_customer_representative_type" | language }} :
								<span class="red">*</span></label
							>
							<br />
							<mat-radio-group
								aria-label="Select an option"
								formControlName="customer_representative_type"
							>
								<mat-radio-button value="upload_customer_approved_document">{{
									"lang_upload_customer_approved_document" | language
								}}</mat-radio-button>
								<!-- <mat-radio-button value="Owner_decision_form">{{
									"lang_owner_decision_form" | language
								}}</mat-radio-button> -->
								<mat-radio-button value="assign_owner_for_confirmation">{{
									"lang_assign_owner_for_confirmation" | language
								}}</mat-radio-button>
							</mat-radio-group>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 col-xs-12 mb-10">
						<div
							class="mb-10"
							style="display: flex; flex-direction: column"
							*ngIf="
								form.get('customer_representative_type').value ==
									'upload_customer_approved_document' &&
								form.get('customr_representative_required').value == 'Yes' &&
								form.get('manager_approval_approval_status').value == 'Approve'
							"
						>
							<label
								>{{ "lang_upload_attachment" | language }}
								<span class="red">*</span></label
							>
							<div
								class="table-responsive customResponsive tablefields quotationitemtable"
							>
								<table
									border="1"
									class="table CustomTable"
									width="100%"
									formArrayName="attachments"
								>
									<thead>
										<tr>
											<td
												width="2%"
												style="padding: 5px; word-break: break-all"
											>
												#
											</td>
											<td
												width="2%"
												style="padding: 5px; word-break: break-all"
											>
												<mat-icon
													class="mnabricon"
													matTooltip="{{ 'lang_add' | language }}"
													(click)="addfiles()"
													>add_box</mat-icon
												>
											</td>
											<td
												width="48%"
												style="padding: 5px; word-break: break-all"
											>
												{{ "lang_title" | language }}
											</td>
											<td
												width="48%"
												style="padding: 5px; word-break: break-all"
											>
												{{ "lang_file" | language }}
											</td>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="
												let file of filesFormGroup.controls;
												let i = index;
												let odd = odd;
												let even = even
											"
											[formGroupName]="i"
										>
											<td
												class="verticalalignmid"
												style="padding: 5px; word-break: break-all"
											>
												{{ i + 1 }}
											</td>
											<td class="verticalalignmid">
												<mat-icon
													*ngIf="
														!attachments.controls[i].get('delete_icon').value
													"
													(click)="removefiles(i)"
													class="mnabricon red"
													matTooltip="{{ 'lang_delete' | language }}"
													>delete_forever</mat-icon
												>
											</td>
											<td
												style="
													padding: 5px;
													word-break: break-all;
													position: relative;
												"
											>
												<input
													type="text"
													formControlName="attach_title"
													placeholder="{{ 'lang_description' | language }}"
													autocomplete="off"
													class="form-control almnabrformcontrol"
												/>
											</td>
											<td
												class="verticalalignmid"
												style="
													padding: 5px;
													word-break: break-all;
													position: relative;
												"
											>
												<input
													name="file"
													type="file"
													required
													onclick="this.value = null"
													(change)="
														ds.uploadSingleFile(
															$event,
															attachments.controls[i].get('file'),
															'file' + i,
															filename
														)
													"
													class="nghide albutton width100"
												/>
												<label id="file{{ i }}" class="albutton">{{
													filename
												}}</label>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div
							class="col-6 mb-10"
							*ngIf="
								form.get('customer_representative_type').value ==
									'assign_owner_for_confirmation' &&
								form.get('customr_representative_required').value == 'Yes' &&
								form.get('manager_approval_approval_status').value == 'Approve'
							"
						>
							<label
								>{{ "lang_owner_users" | language }}
								<span class="red">*</span></label
							>
							<ng-select
								[items]="users"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								clearAllText="Clear"
								[searchFn]="ds.customSearchFn"
								appendTo="body"
								formControlName="owner_users"
								placeholder="{{ 'lang_owner_users' | language }}"
							>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="row pt-3">
					<div class="col-sm-12">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<div class="row">
				<div class="col-sm-4 col-xs-12 mb-10">
					
				</div>
				<div class="col-sm-4 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<input
						type="submit"
						value="{{ 'lang_submit' | language }}"
						class="albutton mnabrbutton mat-accent"
						mat-button
					/>
				</div>
			</div>
		</div>
	</div>
</form>
