<breadcrumb></breadcrumb>accounts
<div class="themesection" *ngIf="viewdata_status">
  <section class="panel-expansion matborderno">
    <div class="card-footer footertop padb0">
      <div class="row">
        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
          <button type="button" class="albutton" routerLink="/accounts/reclist">
            {{ "lang_goto_list" | language }}
          </button>
        </div>
        <div class="col-sm-6 col-xs-12 mb-10"></div>
        <div class="col-sm-2 col-xs-12 mb-10">
          <button
            *ngIf="recData.permits.add"
            type="button"
            class="albutton"
            (click)="
              this.router.navigateByUrl(
                '/accounts/reccreate/' + branch_id + '/' + finance_id
              )
            "
          >
            {{ "lang_add" | language }}
          </button>
        </div>
        <div class="col-sm-2 col-xs-12 mb-10">
          <button
            type="button"
            class="albutton"
            *ngIf="
              recData.permits.edit &&
              recData.financial_year.finance_id == viewdata.finance_id
            "
            routerLink="/accounts/recedit/{{ branch_id }}/{{
              payment_receipt_id
            }}/{{ finance_id }}/edit"
          >
            {{ "lang_edit" | language }}
          </button>
        </div>
      </div>
    </div>
    <mat-accordion class="receipt_lists">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <i class="together-icon">
          <i
            *ngIf="viewdata.payment_receipt_attachment"
            class="fa fa-paperclip mnabricon s2icon"
            (click)="
              ds.preview_pdf_file(
                viewdata.payment_receipt_attachment,
                'receipt-' + viewdata?.transaction_id
              )
            "
            matTooltip="{{ 'lang_file' | language }}"
          ></i>
        </i>
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_receipts" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_receipt_no" | language }} :
                    <b>{{ viewdata.payreceipt_system_code }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_transaction_number" | language }} :
                    <b>{{ viewdata.transaction_id }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_branch" | language }} :
                    <b>{{ viewdata.branch_name }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_receipt_date" | language }} :
                    <b>{{ viewdata.payment_receipt_date }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_receipt_from" | language }} :
                    <b>{{ viewdata.payment_receipt_to_from }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label class="mb-10"
                    >{{ "lang_receipt_mode" | language }} :
                    <b *ngIf="viewdata.payment_receipt_mode == 'cash'">{{
                      "lang_cash" | language
                    }}</b>
                    <b *ngIf="viewdata.payment_receipt_mode == 'cheque'">{{
                      "lang_cheque" | language
                    }}</b>
                    <b *ngIf="viewdata.payment_receipt_mode == 'deposit'">{{
                      "lang_deposit" | language
                    }}</b>
                    <b *ngIf="viewdata.payment_receipt_mode == 'etransfer'">{{
                      "lang_etransfer" | language
                    }}</b>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_debit_account" | language }} :
                    <b>{{ viewdata.debit_account }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_debit_cost" | language }} :
                    <b *ngIf="viewdata.debit_cost">{{ viewdata.debit_cost }}</b>
                    <b *ngIf="!viewdata.debit_cost">----</b>
                  </label>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_credit_account" | language }} :
                    <b>{{ viewdata.credit_account }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_credit_cost" | language }} :
                    <b *ngIf="viewdata.credit_cost">{{
                      viewdata.credit_cost
                    }}</b>
                    <b *ngIf="!viewdata.credit_cost">----</b>
                  </label>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_receipt_amount" | language }} :
                    <b>{{
                      ds.addCommas(viewdata.payment_receipt_amount)
                    }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_description" | language }} :
                    <b>{{ viewdata.payment_receipt_description }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_notes" | language }} :
                    <b *ngIf="viewdata.payment_receipt_notes">{{
                      viewdata.payment_receipt_notes
                    }}</b>
                    <b *ngIf="!viewdata.payment_receipt_notes">----</b>
                  </label>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_document_number" | language }} :
                    <b *ngIf="viewdata.payment_receipt_document_number">{{
                      viewdata.payment_receipt_document_number
                    }}</b>
                    <b *ngIf="!viewdata.payment_receipt_document_number"
                      >----</b
                    >
                  </label>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_document_date" | language }} :
                    <b *ngIf="viewdata.payment_receipt_document_date">{{
                      viewdata.payment_receipt_document_date
                    }}</b>
                    <b *ngIf="!viewdata.payment_receipt_document_date">----</b>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_bank" | language }} :
                    <b *ngIf="viewdata.payment_receipt_bank_name">{{
                      viewdata.payment_receipt_bank_name
                    }}</b>
                    <b *ngIf="!viewdata.payment_receipt_bank_name">----</b>
                  </label>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_writer" | language }} :
                    <b>{{ viewdata.writer_name }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_ondate" | language }} :
                    <b>{{ viewdata.payment_receipt_created_date }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-4 col-sm-4 col-xs-12 mb-10"
                *ngIf="viewdata.payment_receipt_updated_date"
              >
                <div class="form-group">
                  <label
                    >{{ "lang_onupdate" | language }} :
                    <b>{{ viewdata.payment_receipt_updated_date }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_financial_date" | language }} :
                    <b
                      >{{ viewdata.finance_start_date }} -
                      {{ viewdata.finance_end_date }}</b
                    ></label
                  >
                </div>
              </div>
            </div>
            <div class="row" *ngIf="transactions.length > 0">
              <div class="col-md-12">
                <div class="table-responsive dropdownyes customResponsive">
                  <p class="red">
                    <b class="red">{{ "lang_transactions" | language }} : </b>
                  </p>
                  <table border="1" class="table CustomTable">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>{{ "lang_accounts_acccode" | language }}</td>
                        <td>{{ "lang_accounts_name" | language }}</td>
                        <td>{{ "lang_cost_center_code" | language }}</td>
                        <td>{{ "lang_description" | language }}</td>
                        <td>{{ "lang_debit_amount" | language }}</td>
                        <td>{{ "lang_credit_amount" | language }}</td>
                        <td>{{ "lang_cost_center" | language }}</td>
                      </tr>
                    </thead>
                    <tbody class="AppendList">
                      <tr
                        *ngFor="
                          let data of transactions;
                          let i = index;
                          let odd = odd;
                          let even = even
                        "
                        [ngClass]="{ odd: odd, even: even }"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>
                          {{ data.account_masters_code }}
                          <i
                            class="fa fa-exchange mnabricon s2icon"
                            routerLink="/accounts/statement_of_accounts/{{
                              data.account_masters_id
                            }}/"
                            matTooltip="{{
                              'lang_view_transactions' | language
                            }}"
                          ></i>
                        </td>
                        <td>
                          {{ data.asaccount_full_name }}
                        </td>
                        <td *ngIf="data.cost_name">
                          {{ data.cost_name }}
                          <i
                            class="fa fa-exchange mnabricon s2icon"
                            routerLink="/accounts/statement_of_costs/{{
                              data.cost_center_id
                            }}/"
                            matTooltip="{{
                              'lang_view_transactions' | language
                            }}"
                          ></i>
                        </td>
                        <td *ngIf="!data.cost_name">----</td>
                        <td>{{ data.transaction_history_description }}</td>
                        <td>{{ ds.addCommas(data.debit_amount) }}</td>
                        <td>{{ ds.addCommas(data.credit_amount) }}</td>
                        <td>
                          <i
                            class="fa fa-credit-card-alt mnabricon"
                            (click)="showCostCenter(data)"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer padb0">
            <div class="row">
              <div class="col-sm-2 col-xs-12 mb-10">
                <button
                  type="button"
                  class="albutton"
                  routerLink="/accounts/reclist"
                >
                  {{ "lang_goto_list" | language }}
                </button>
              </div>
              <div class="col-sm-6 col-xs-12 mb-10"></div>
              <div class="col-sm-2 col-xs-12 mb-10">
                <button
                  *ngIf="recData.permits.add"
                  type="button"
                  class="albutton"
                  (click)="
                    this.router.navigateByUrl(
                      '/accounts/reccreate/' + branch_id + '/' + finance_id
                    )
                  "
                >
                  {{ "lang_add" | language }}
                </button>
              </div>
              <div class="col-sm-2 col-xs-12 mb-10">
                <button
                  type="button"
                  class="albutton"
                  *ngIf="
                    recData.permits.edit &&
                    recData.financial_year.finance_id == viewdata.finance_id
                  "
                  routerLink="/accounts/recedit/{{ branch_id }}/{{
                    payment_receipt_id
                  }}/{{ finance_id }}/edit"
                >
                  {{ "lang_edit" | language }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="mpanel">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_history" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <app-account-history-log
            [account_operation]="'RECEIPT'"
            [id]="payment_receipt_id"
          ></app-account-history-log>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
<div class="modal" id="pdfPreviewRecPay">
  <div class="modal-dialog width70">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "lang_preview" | language }}</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="pdf-container">
          <iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "lang_close" | language }}
        </button>
      </div>
    </div>
  </div>
</div>
