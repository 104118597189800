import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MenuService } from './menu.service';
import { DataService } from './data.service';
import { ApiRoutes } from 'app/routes/apiroutes';
@Injectable({
  providedIn: 'root',
})
export class StartupService implements OnDestroy {
  constructor(
    private menu: MenuService,
    private ds : DataService
  ) {}
  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptions.add(
        this.ds
          .get(ApiRoutes.getSideNav, {})
          .pipe(
            catchError(res => {
              resolve(null);
              return throwError(res);
            })
          )
          .subscribe(
            (res: any) => {
              this.menu.recursMenuForTranslation(res.records, 'menu');
              this.menu.set(res.records);
            },
            () => {
              reject();
            },
            () => {
              resolve(null);
            }
          )
        )
      ;
    });
  }

  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
