import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { SendCodeComponent } from 'app/routes/transactions/FORM_PS_TR1/send-code/send-code.component';

@Component({
  selector: 'app-wri-step8',
  templateUrl: './wri-step8.component.html',
  styleUrls: ['./wri-step8.component.scss']
})
export class WriStep8Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  stepName:string = 'Authorized_Positions_Approval';
  form:FormGroup;
  reserved_status = false;
  constructor(public ds:DataService, public datepipe : DatePipe , public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef,
    public route: ActivatedRoute, private sweetAlert:SweetAlertService, public ls: LocalStorage,  public dialog: MatDialog) { }
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id                  : new FormControl(this.form_request_id, [Validators.required]),
      authorized_positions_approval_status    : new FormControl('', [Validators.required]),
      rejected_notes                          : new FormControl('', [Validators.required])
    });
  }
  public reserve_the_transaction(event) {
    this.reserved_status = false;
    this.form.get('authorized_positions_approval_status').setValue('');
    this.form.get('rejected_notes').setValue('');
    if(event?.checked) {
      this.reserved_status = true;
    }
  }

  checkValidation() {
    if(this.form.get('authorized_positions_approval_status').invalid) {
      this.alert.error(this.lang.transform('lang_transaction_approval_required'));
      return false;
    } else if(this.form.get('rejected_notes').invalid && this.form.get('authorized_positions_approval_status').value == 'Return') {
      this.alert.error(this.lang.transform('lang_rejected_notes_required'));
      return false;
    } else {
      return true;
    }
  }

  public completeStep8() {
    if(this.checkValidation()) {
      let formData = new FormData();
      formData.append('authorized_positions_approval_status', this.form.get('authorized_positions_approval_status').value);
      if(this.form.get('authorized_positions_approval_status').value == 'Return') {
        formData.append('rejected_notes', this.form.value.rejected_notes);
      }
      formData.append('transaction_request_id', this.form.value.transaction_request_id);
      this.sweetAlert.confirmMessage('Are you sure?', 'Confirm').then(result => {
        if(result.isConfirmed) {
          this.spinner.show();
          this.ds.post(`form/${this.form_key}/Authorized_Positions_Approval/0`, formData).subscribe(data => {
            if(data.status) {
              this.spinner.hide();
              this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
              setTimeout(() => {
                this.refreshComponent.emit(true);
              }, 2000);
            } else {
              this.spinner.hide();
              this.alert.error(data.error)
            }
          }, (error) => {
            this.spinner.hide();
              this.alert.error(error);
          })
        }
      })
      
    }
  }

  openModal() {
    const dialogRef = this.dialog.open(SendCodeComponent,{
      disableClose: true,
      width:'400px',
      data: {transaction_persons_type: 'signature', allData: this.allData, stepName:this.stepName,title:'lang_signature'}
      
    });
  }
}
