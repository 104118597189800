<div class="card apptemplates mt-3">
	<div class="card-header">{{ "lang_formations" | language }}</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-md-3 mb-10">
					<select
						class="form-control almnabrformcontrol"
						matTooltip="{{ 'lang_formations' | language }}"
						[(ngModel)]="template_status"
						(change)="spinner.show(); search_by()"
					>
						<option value="">
							{{ "lang_formations" | language }} - {{ "lang_all" | language }}
						</option>
						<option value="0">{{ "lang_draft" | language }}</option>
						<option value="1">{{ "lang_active" | language }}</option>
					</select>
				</div>
				<div class="col-md-9 mb-10">
					<div class="input-group input-group-sm">
						<input
							type="text"
							class="form-control almnabrformcontrol"
							(keyup)="search_by()"
							[(ngModel)]="searchKey"
							placeholder="{{ 'lang_search' | language }}"
						/>
						<span
							class="input-group-btn"
							*ngIf="ds.allowMe('projects', 'projects_template_permission')"
						>
							<button
								class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
								(click)="
									templateAction.emit({
										mode: 'TADD',
										data: null,
										template_id: ''
									})
								"
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_add' | language }}"
							></button>
						</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check_all' | language }}"
										>
											<input
												type="checkbox"
												class="allItems checkbox"
												id="AllItemsTem"
												(click)="checkAllItemsTem($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td>{{ "lang_template_type" | language }}</td>
									<td>{{ "lang_title_english" | language }}</td>
									<td>{{ "lang_title_arabic" | language }}</td>
									<td>{{ "lang_project_main_total_amount" | language }}</td>
									<td>{{ "lang_this_template_cost" | language }}</td>
									<td>{{ "lang_template_status" | language }}</td>
									<td>{{ "lang_writer" | language }}</td>
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody
								*ngIf="wholedata?.records?.length != '0'"
								class="AppendList"
							>
								<tr
									*ngFor="
										let list of wholedata?.records;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
									class="makeback removerow{{ list.template_id }}"
								>
									<td>{{ i + 1 }}</td>
									<td>
										<div
											class="checkbox"
											matTooltip="{{ 'lang_check' | language }}"
										>
											<input
												type="checkbox"
												class="eachItemTem checkbox"
												value="{{ list.template_id }}"
												(click)="checkEachItemTem($event)"
											/>
											<label for="checkbox"></label>
										</div>
									</td>
									<td *ngIf="list.template_type == '1'">
										{{ "lang_normal" | language }}
									</td>
									<td *ngIf="list.template_type != '1'">
										{{ "lang_private" | language }}
									</td>
									<td>{{ list.template_title_en }}</td>
									<td>{{ list.template_title_ar }}</td>
									<td>{{ ds.addCommas(list.each_unit_cost_of_template) }}</td>
									<td>{{ ds.addCommas(list.total_cost_of_this_template) }}</td>
									<td *ngIf="list.template_status == '0'">
										<i
											class="fa fa-circle mnabricon yellowcolor"
											(click)="changeStatus(list, '1')"
											aria-hidden="true"
											matTooltip="{{ 'lang_draft' | language }}"
										></i>
									</td>
									<td *ngIf="list.template_status == '1'">
										<i
											class="fa fa-circle mnabricon green"
											(click)="changeStatus(list, '0')"
											aria-hidden="true"
											matTooltip="{{ 'lang_active' | language }}"
										></i>
									</td>
									<td>{{ list.writer }}</td>
									<td>
										<button mat-icon-button [matMenuTriggerFor]="menu">
											<i class="fa fa-cog mnabricon s2icon"></i>
										</button>
										<mat-menu
											#menu="matMenu"
											[overlapTrigger]="false"
											xPosition="{{ lang_key == 'en' ? 'before' : 'after' }}"
										>
											<button
												mat-menu-item
												(click)="view_template_data_with_tree(list)"
											>
												<i
													class="fa fa-eye fa-icon mnabricon s2icon"
													style="margin: 0 10px"
												></i>
												<span>{{ "lang_view" | language }}</span>
											</button>
											<button
												mat-menu-item
												*ngIf="
													ds.allowMe(
														'projects',
														'projects_template_permission'
													) && list.template_status == '0'
												"
												(click)="edit_template_data(list)"
											>
												<i
													class="fa fa-pencil-square-o fa-icon mnabricon s2icon"
													style="margin: 0 10px"
												></i>
												<span>{{ "lang_edit" | language }}</span>
											</button>
											<button
												mat-menu-item
												(click)="
													show_units(list.phase_zone_block_cluster_g_nos)
												"
											>
												<i
													class="fa fa-home fa-icon mnabricon s2icon"
													style="margin: 0 10px"
												></i>
												<span>{{ "lang_show_units" | language }}</span>
											</button>
										</mat-menu>
										<button
											mat-icon-button
											matTooltip="{{ 'lang_run_trigger' | language }}"
											(click)="onRunTrigger(list)"
										>
											<i class="fa fa-user-secret mnabricon s2icon"></i>
										</button>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="wholedata?.records?.length == 0" class="AppendList">
								<tr class="odd">
									<td colspan="10" align="center">{{ nodata }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div *ngIf="wholedata?.records?.length != '0'" class="col-12 mt-3">
					<div class="row responsive_pagination">
						<div
							class="col-lg-3 col-sm-3 mb-10"
							style="height: fit-content; margin: auto 0"
						>
							{{ "lang_total" | language }} :
							{{ wholedata?.page?.total_records }}
						</div>
						<div
							class="col-lg-6 col-sm-6 mb-10"
							style="display: flex; justify-content: center"
						>
							<ngb-pagination
								class="m-auto"
								[collectionSize]="wholedata?.page?.total_records"
								[(page)]="page_no"
								[maxSize]="5"
								[pageSize]="page_size"
								[rotate]="true"
								[ellipses]="false"
								[maxSize]="3"
								[boundaryLinks]="true"
								(pageChange)="page_number($event)"
							></ngb-pagination>
						</div>
						<div
							class="col-lg-3 col-sm-3"
							style="display: flex; justify-content: end; margin: auto"
						>
							<mat-select
								[(ngModel)]="page_size"
								(selectionChange)="by_page_size($event)"
							>
								<mat-option class="text-center" value="10"
									>10 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="20"
									>20 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="50"
									>50 {{ "lang_per_page" | language }}
								</mat-option>
								<mat-option class="text-center" value="100"
									>100 {{ "lang_per_page" | language }}</mat-option
								>
								<mat-option class="text-center" value="500"
									>500 {{ "lang_per_page" | language }}</mat-option
								>
							</mat-select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="wholedata.length != '0'" class="padb0">
			<div class="row">
				<div
					*ngIf="ds.allowMe('projects', 'projects_template_permission')"
					class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10"
				>
					<i
						class="fa fa-trash-o faicon red deleteIcon"
						(click)="deleteBulkDataTem()"
						aria-hidden="true"
						matTooltip="{{ 'lang_delete' | language }}"
					></i>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #viewtemplatedata>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">
				{{ "lang_view" | language }} ({{ viewdata.template.templatename }})
			</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefV.hide()"
			>
				&times;
			</button>
		</div>
		<div class="modal-body">
			<div class="row">
				<div class="col-12">
					<div class="table-responsive customResponsive mb-10 tablefields">
						<table border="1" class="table CustomTable text-center">
							<tbody>
								<tr>
									<td class="verticalalignmid tamplateTable" width="25%">
										{{ "lang_projects_total_construction_cost" | language }}
									</td>
									<td class="verticalalignmid" width="25%">
										<b>{{
											ds.addCommas(supervision_data.total_construction_cost)
										}}</b>
									</td>
									<td class="verticalalignmid tamplateTable" width="25%">
										{{ "lang_project_main_total_amount" | language }}
									</td>
									<td class="verticalalignmid" width="25%">
										<b>{{
											ds.addCommas(viewdata.template.each_unit_cost_of_template)
										}}</b>
									</td>
								</tr>
								<tr>
									<td class="verticalalignmid tamplateTable" width="25%">
										{{ "lang_selected_units" | language }}
										<i
											class="fa fa-info-circle mnabricon s2icon"
											(click)="
												show_units(
													viewdata.template.phase_zone_block_cluster_g_nos
												)
											"
											matTooltip="{{ 'lang_click_to_show_units' | language }}"
										></i>
									</td>
									<td class="verticalalignmid" width="25%">
										<b
											>{{
												viewdata.template.phase_zone_block_cluster_g_nos.split(
													","
												).length
											}}
											/ {{ supervision_data.total_no_of_units }}</b
										>
									</td>
									<td class="verticalalignmid tamplateTable" width="25%">
										{{ "lang_this_template_cost" | language }}
									</td>
									<td class="verticalalignmid" width="25%">
										<b>{{
											ds.addCommas(
												viewdata.template.total_cost_of_this_template
											)
										}}</b>
									</td>
								</tr>
								<tr>
									<td class="verticalalignmid tamplateTable" width="25%">
										{{ "lang_manual_total_ratio" | language }}
									</td>
									<td class="verticalalignmid" width="25%">
										<b>{{ viewdata.template.manual_total_ratio }} %</b>
									</td>
									<td class="verticalalignmid tamplateTable" width="25%">
										{{ "lang_system_total_ratio" | language }}
									</td>
									<td class="verticalalignmid" width="25%">
										<b>{{ viewdata.template.system_total_ratio }} %</b>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="table-responsive customResponsive mb-10 tablefields">
					<table border="1" class="table CustomTable text-center bigtable">
						<thead>
							<tr>
								<td class="verticalalignmid">#</td>
								<td>{{ "lang_group1_code_system" | language }}</td>
								<td>{{ "lang_group_one" | language }}</td>
								<td>{{ "lang_group1_code_manual" | language }}</td>
								<td>{{ "lang_group_type" | language }}</td>
								<td>{{ "lang_group2_code_system" | language }}</td>
								<td>{{ "lang_group_two" | language }}</td>
								<td>{{ "lang_group2_code_manual" | language }}</td>
								<td>{{ "lang_platform_code_system" | language }}</td>
								<td>{{ "lang_platform_code_manual" | language }}</td>
								<td>{{ "lang_platform_title" | language }}</td>
								<td>{{ "lang_start_date" | language }}</td>
								<td>{{ "lang_end_date" | language }}</td>
								<td>{{ "lang_platform_code_systems" | language }}</td>
								<td>{{ "lang_units" | language }}</td>
								<td>{{ "lang_invoice_item_quantity" | language }}</td>
								<td>{{ "lang_unit_price" | language }}</td>
								<td>{{ "lang_total_price" | language }}</td>
								<td>{{ "lang_unit_ratio" | language }} %</td>
								<td
									*ngFor="
										let level of viewdata.template.template_work_level_keys.split(
											','
										)
									"
								>
									{{ build_work_level(level, "label") }}
								</td>
								<td>{{ "lang_final_ratio" | language }} %</td>
							</tr>
						</thead>
						<tbody class="AppendList">
							<tr
								*ngFor="
									let item of viewdata.platforms;
									let i = index;
									let odd = odd;
									let even = even
								"
								[ngClass]="{ odd: odd, even: even }"
							>
								<td>{{ i + 1 }}</td>
								<td>{{ item.template_platform_group1_code_system }}</td>
								<td>{{ item.group1name }}</td>
								<td>{{ item.template_platform_group1_code_manual }}</td>
								<td>
									{{ item.typename }} -
									{{ item.template_platform_group_type_code_system }}
								</td>
								<td>{{ item.template_platform_group2_code_system }}</td>
								<td>{{ item.group2name }}</td>
								<td>{{ item.template_platform_group2_code_manual }}</td>
								<td>{{ item.template_platform_code_system }}</td>
								<td>{{ item.template_platform_code_manual }}</td>
								<td>{{ item.platformname }}</td>
								<td>{{ item.template_platform_start_date }}</td>
								<td>{{ item.template_platform_end_date }}</td>
								<td>{{ item.template_platform_requirements_code_systems }}</td>
								<td>{{ item.unitname }}</td>
								<td>{{ item.template_platform_quantity }}</td>
								<td>{{ ds.addCommas(item.template_platform_unit_price) }}</td>
								<td>{{ ds.addCommas(item.template_platform_total) }}</td>
								<td>{{ item.template_platform_unit_ratio }}</td>
								<td
									*ngFor="
										let level of viewdata.template.template_work_level_keys.split(
											','
										)
									"
								>
									{{
										build_work_level(
											level,
											"value",
											"V",
											item.template_platform_code_system
										)
									}}
								</td>
								<td>{{ item.template_platform_total_ratio }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="card-footer pad15">
			<div class="row">
				<div class="col-md-10 col-xs-12 mb-10"></div>
				<div class="col-md-2 col-xs-12 mb-10">
					<button
						type="button"
						class="btn btn-secondary secondarybutton"
						data-dismiss="modal"
						(click)="modalRefV.hide()"
					>
						{{ "lang_close" | language }}
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>
