import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	TemplateRef,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
	selector: "app-extend-period-list",
	templateUrl: "./extend-period-list.component.html",
	styleUrls: ["./extend-period-list.component.scss"],
})
export class ExtendPeriodListComponent implements OnInit {
	@Output() showContract = new EventEmitter();
	@Input() able2edit;
	@Input() able2delete;
	@Input() able2add;
	@Input() id;
	@Input() branch_id;
	@Input() key;
	@Input() types;
	@Input() requestfor;
	@Input() user_data;
	@Input() attachments;
	prod = environment.production;
	csearchKey = "";
	wholedata: any = [];
	modalRefV: BsModalRef;
	expandstatus = false;
	page = 1;
	size = 10;
	errormessagef: any = this.lang.transform("lang_loading");
	viewdata: any = [];
	constructor(
		public router: Router,
		public ds: DataService,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.expandstatus = this.requestfor == "profile" ? true : false;
		this.search_employee_contracts();
	}
	public openModalV(template: TemplateRef<any>) {
		this.modalRefV = this.modalService.show(template, environment.modelconfig);
	}
	public load_page_size(pageno) {
		this.size = pageno;
		this.get_employee_contracts();
	}
	public page_length(page) {
		this.page = page;
		this.get_employee_contracts();
	}
	public search_employee_contracts() {
		this.size = 10;
		this.page = 1;
		this.get_employee_contracts();
	}
	public get_employee_contracts() {
		const param = new FormData();
		param.append("employee_number", this.id);
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "form/FORM_EPP/get_epp")
			.subscribe(
				(data) => {
					this.spinner.hide();
					if (data.status) {
						this.wholedata = data;
					} else {
						this.wholedata = [];
						this.errormessagef = data.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.wholedata = [];
					this.errormessagef =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}
}
