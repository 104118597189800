<div class="container" [attr.dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
	<div class="row">
		<div class="col-12 mt-2">
			<section class="category-list">
				<div class="cl-header">
					<div class="cl-title">
						<img
							src="assets/images/tickets-assets/template-category1.svg"
							alt="title icon"
							width="48px"
							height="48px"
						/>
						<h2 class="section-title">
							{{ module_name }} {{ "lang_templates" | language }}
						</h2>
					</div>
					<div class="cl-action">
						<input
							type="text"
							class="task-input"
							placeholder="{{ 'lang_search' | language }}"
						/>
					</div>
				</div>
				<div class="cl-list">
					<div
						class="cl-list-item"
						*ngFor="let item of templates"
						routerLink="/tasks/templates/view/{{ item?.ticket_id }}"
					>
						<div class="cl-list-item-header">
							<h3>{{ item?.temp_id }} - {{ item?.title }}</h3>
							<p>
								{{ "lang_by" | language }} {{ item?.firstname_english }}
								{{ item?.lastname_english }} @{{ item?.user_username }}
							</p>
						</div>
						<div class="cl-list-item-content">
							<p>{{ item?.description }}</p>
						</div>
						<div class="temp-actions">
							<i
								class="fa fa-trash red mr-1 ml-1"
								(click)="deleteTemplate($event, item?.temp_id)"
								*ngIf="user_id == item?.user_id"
								matTooltip="{{ 'lang_delete' | language }}"
							></i>
							<i
								class="pi pi-shield mr-1 ml-1 green"
								(click)="
									toggleTemplatePrivate($event, item?.temp_id, item?.is_view)
								"
								*ngIf="user_id == item?.user_id && item?.is_view == '1'"
								matTooltip="{{ 'lang_make_public' | language }}"
								style="font-weight: 600"
							></i>
							<i
								class="pi pi-shield mr-1 ml-1"
								(click)="
									toggleTemplatePrivate($event, item?.temp_id, item?.is_view)
								"
								*ngIf="user_id == item?.user_id && item?.is_view == '2'"
								matTooltip="{{ 'lang_make_private' | language }}"
								style="font-weight: 600"
							></i>
						</div>
					</div>
				</div>
				<no-data
					style="margin: auto"
					[Opened]="templates?.length < 1"
				></no-data>
			</section>
		</div>
	</div>
</div>
