export const platformRequirements  = {
    status : true,
    records : [
        {
            unit : 1,
            work_level : "level_1",
            platform_access_code : "1.TDRW.1.1",
            evaluation_result : 'B',
        },
        {
            unit : 2,
            work_level : "level_1",
            platform_access_code : "1.TDRW.1.1",
            evaluation_result : 'C',
        },
        {
            unit : 3,
            work_level : "level_1",
            platform_access_code : "1.TDRW.1.1",
            evaluation_result : 'B',
        },
        {
            unit : 1,
            work_level : "level_2",
            platform_access_code : "1.TDRW.1.1",
            evaluation_result : 'C',
        },
        {
            unit : 2,
            work_level : "level_2",
            platform_access_code : "1.TDRW.1.1",
            evaluation_result : 'C',
        },
        {
            unit : 3,
            work_level : "level_2",
            platform_access_code : "1.TDRW.1.1",
            evaluation_result : 'B',
        }
    ]
}
export const special_approval_notes = {
    status : true,
    records : [
        {
            note_id : 1,
            description : "HIiiiiiiiiiiiiiiiiiiiiiiiiiii",
            writer : 'Admin',
            created_date : "2021-04-25 12:12:12",
        },
        {
            note_id : 2,
            description : "HIiiiiiiiiiiiiiiiiiiiiiiiiiii",
            writer : 'Admin',
            created_date : "2021-04-25 12:12:12",
        },
        {
            note_id : 3,
            description : "HIiiiiiiiiiiiiiiiiiiiiiiiiiii",
            writer : 'Admin',
            created_date : "2021-04-25 12:12:12",
        },
        {
            note_id : 4,
            description : "HIiiiiiiiiiiiiiiiiiiiiiiiiiii",
            writer : 'Admin',
            created_date : "2021-04-25 12:12:12",
        },
        {
            note_id : 5,
            description : "HIiiiiiiiiiiiiiiiiiiiiiiiiiii",
            writer : 'Admin',
            created_date : "2021-04-25 12:12:12",
        },
        {
            note_id : 6,
            description : "HIiiiiiiiiiiiiiiiiiiiiiiiiiii",
            writer : 'Admin',
            created_date : "2021-04-25 12:12:12",
        },
        {
            note_id : 7,
            description : "HIiiiiiiiiiiiiiiiiiiiiiiiiiii",
            writer : 'Admin',
            created_date : "2021-04-25 12:12:12",
        },
        {
            note_id : 8,
            description : "HIiiiiiiiiiiiiiiiiiiiiiiiiiii",
            writer : 'Admin',
            created_date : "2021-04-25 12:12:12",
        },
    ],
    page : {
        offset: 0,
        page_no: "1",
        per_page: "10",
        total_pages: 1,
        total_records: 8
    }
};
export const technical_assistant = {
    status : true,
    records : [
        {
            other_assistance_title  : 'Hi,,,,,,,,,,,,,,,,,,,,,, WWWWWWAAAAAAAA',
            other_assistance_status : '0',
            other_assistance_result : 'A'

        },
        {
            other_assistance_title : 'Hi,,,,,,,,,,,,,,,,,,,,,, WWWWWW',
            other_assistance_status : '1',
            other_assistance_result : 'B'
        },
        {
            other_assistance_title : 'Hi,,,,,,,,,,,,,,,,,,,,,, SAAAAAAA',
            other_assistance_status : '1',
            other_assistance_result : 'C'
        },
        {
            other_assistance_title : 'Hi,,,,,,,,,,,,,,,,,,,,,, SSSSSS',
            other_assistance_status : '0',
            other_assistance_result : 'C'
        },
    ]
}
export const building_code = {
    status : true,
    records : [
        {
            other_building_code_title  : 'Hi,,,,,,,,,,,,,,,,,,,,,, WWWWWWAAAAAAAA',
            other_building_code_status : '0'
        },
        {
            other_building_code_title : 'Hi,,,,,,,,,,,,,,,,,,,,,, WWWWWW',
            other_building_code_status : '1'
        },
        {
            other_building_code_title : 'Hi,,,,,,,,,,,,,,,,,,,,,, SAAAAAAA',
            other_building_code_status : '1'
        },
        {
            other_building_code_title : 'Hi,,,,,,,,,,,,,,,,,,,,,, SSSSSS',
            other_building_code_status : '0'
        },
    ]
}
export const notes_or_recomandation = {
    status : true,
    records : [
        {
            other_recomandation_title : 'راي الاستشاري الفني في هذا الطلب لايعتد به الا بعد قرار المالك',
            other_recomandation_status : '0',
            other_recomandation_result : 'C'
        },
        {
            other_recomandation_title  : 'Hi,,,,,,,,,,,,,,,,,,,,,, WWWWWWAAAAAAAA',
            other_recomandation_status : '0',
            other_recomandation_result : 'A'

        },
        {
            other_recomandation_title : 'Hi,,,,,,,,,,,,,,,,,,,,,, WWWWWW',
            other_recomandation_status : '1',
            other_recomandation_result : 'B'
        },
        {
            other_recomandation_title : 'Hi,,,,,,,,,,,,,,,,,,,,,, SAAAAAAA',
            other_recomandation_status : '1',
            other_recomandation_result : 'C'
        },
        {
            other_recomandation_title : 'Hi,,,,,,,,,,,,,,,,,,,,,, SSSSSS',
            other_recomandation_status : '0',
            other_recomandation_result : 'C'
        }
    ]
}