import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService } from "ngx-bootstrap/modal";
@Component({
	selector: "app-phases-add",
	templateUrl: "./phases-add.component.html",
	styleUrls: ["./phases-add.component.scss"],
})
export class PhasesAddComponent implements OnInit {
	@ViewChild("stepper") private myStepper: MatStepper;
	@Output() onAddComplete = new EventEmitter();
	totalStepsCount: number;
	@Input() supervision_data;
	@Input() project_data;
	phase1form: FormGroup;
	phase2form: FormGroup;
	phase3form: FormGroup;
	phase4form: FormGroup;
	phase2items: FormArray;
	step1submitted: boolean = false;
	step2submitted: boolean = false;
	step3submitted: boolean = false;
	step4submitted: boolean = false;
	error_message: string = "";
	error_status: boolean = false;
	filenamephase3: string = this.lang.transform("lang_select_files");
	filenamephase4: string = this.lang.transform("lang_select_files");
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public alert: AlertService,
		public modalService: BsModalService,
		public dialog: MatDialog,
		public fb: FormBuilder
	) {}
	ngOnInit(): void {
		this.buildphaseform();
	}
	// Create the implementation Phases Start
	buildphaseform() {
		this.phase1form = new FormGroup({
			total_no_of_zones: new FormControl("", [Validators.required]),
			total_no_of_blocks: new FormControl("", [Validators.required]),
			total_no_of_clusters: new FormControl("", [Validators.required]),
			total_no_of_units: new FormControl("", [Validators.required]),
		});
		this.phase2form = new FormGroup({
			phase2items: this.fb.array([]),
		});
		this.phase2items = this.phase2form.get("phase2items") as FormArray;
		this.phase3form = new FormGroup({
			projects_profile_id: new FormControl(""),
			projects_supervision_id: new FormControl(""),
			cluster_attachment: new FormControl("", [Validators.required]),
		});
		this.phase4form = new FormGroup({
			projects_profile_id: new FormControl(""),
			projects_supervision_id: new FormControl(""),
			unit_attachment: new FormControl("", [Validators.required]),
		});
	}
	complete_step_one() {
		this.step1submitted = true;
		this.phase2items.controls = [];
		let A = parseInt(this.phase1form.get("total_no_of_zones").value);
		let B = parseInt(this.phase1form.get("total_no_of_blocks").value);
		let C = parseInt(this.phase1form.get("total_no_of_clusters").value);
		let D = parseInt(this.phase1form.get("total_no_of_units").value);
		if (A <= B && A <= C && A <= D && B <= C && B <= D && C <= D) {
			this.alert.clear();
			this.build_blocks();
		} else {
			this.alert.error(this.lang.transform("lang_valid_i_phases"));
		}
	}
	public items_group() {
		return this.phase2form.get("phase2items") as FormArray;
	}
	get itemsgroup() {
		return this.phase2form.get("phase2items") as FormArray;
	}
	public build_blocks() {
		for (
			let zoneno = 0;
			zoneno < parseInt(this.phase1form.get("total_no_of_zones").value);
			zoneno++
		) {
			this.phase2items.push(this.item_records(zoneno));
		}
	}
	public item_records(zoneno): FormGroup {
		return this.fb.group({
			specific_zone_no: new FormControl({ value: zoneno + 1, disabled: true }, [
				Validators.required,
			]),
			specific_zone_blocks_config: new FormControl("", [Validators.required]),
		});
	}

	complete_step_two(stepper: MatStepper) {
		this.alert.clear();
		this.step2submitted = true;
		let total: any = 0;
		this.itemsgroup.value.forEach((v, k) => {
			total += parseInt(v.specific_zone_blocks_config);
		});
		let status =
			parseInt(total) ===
			parseInt(this.phase1form.get("total_no_of_blocks").value)
				? true
				: false;
		if (this.phase1form.valid && this.phase2form.valid && status) {
			this.spinner.show();
			let param = new FormData();
			param.append(
				"projects_profile_id",
				this.supervision_data.projects_profile_id
			);
			param.append(
				"projects_supervision_id",
				this.supervision_data.projects_supervision_id
			);
			param.append(
				"total_no_of_zones",
				this.phase1form.get("total_no_of_zones").value
			);
			param.append(
				"total_no_of_blocks",
				this.phase1form.get("total_no_of_blocks").value
			);
			param.append(
				"total_no_of_clusters",
				this.phase1form.get("total_no_of_clusters").value
			);
			param.append(
				"total_no_of_units",
				this.phase1form.get("total_no_of_units").value
			);
			this.itemsgroup.value.forEach((v, k) => {
				param.append("specific_zone_no[" + k + "]", k + 1);
				param.append(
					"block_config_of_each_zone[" + k + "]",
					v.specific_zone_blocks_config
				);
			});
			this.ds.postActionByUrl(param, "ia0FSXblFk").subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						stepper.next();
						this.alert.success(res.msg);

						// this.ds.downloadFile(res, "phases");
						let tagA = document.createElement("a");
						tagA.style.display = "none";
						tagA.href = res.base64;
						tagA.download = "phases";
						tagA.click();
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
		} else {
			this.alert.error(this.lang.transform("lang_need_valid_data_and_blocks"));
		}
	}
	// Create the implementation Phases End
	uploadfile(event, key) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			if (key == "C") {
				this.filenamephase3 = event.target.files[0].name;
				this.phase3form
					.get("cluster_attachment")
					.setValue(event.target.files[0], { emitEvent: true });
			} else {
				this.filenamephase4 = event.target.files[0].name;
				this.phase4form
					.get("unit_attachment")
					.setValue(event.target.files[0], { emitEvent: true });
			}
		} else {
			if (key == "C") {
				this.phase3form.get("cluster_attachment").setValue("");
				this.filenamephase3 = this.lang.transform("lang_select_files");
			} else {
				this.phase4form.get("unit_attachment").setValue("");
				this.filenamephase4 = this.lang.transform("lang_select_files");
			}
		}
	}
	complete_step_three(stepper: MatStepper) {
		this.step3submitted = true;
		this.alert.clear();
		if (this.phase3form.valid) {
			this.step3submitted = false;
			this.spinner.show();
			this.phase3form
				.get("projects_profile_id")
				.setValue(this.supervision_data.projects_profile_id);
			this.phase3form
				.get("projects_supervision_id")
				.setValue(this.supervision_data.projects_supervision_id);
			this.ds
				.postActionByUrl(
					this.ds.json2formdata(this.phase3form.value),
					"d7l2G5QIif"
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							stepper.next();
							this.alert.success(res.msg);
							// this.ds.downloadFile(res, 'phases');
							let tagA = document.createElement("a");
							tagA.style.display = "none";
							tagA.href = res.base64;
							tagA.download = "phases";
							tagA.click();
						} else {
							this.alert.error(res.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				);
		}
	}
	complete_step_four(stepper: MatStepper) {
		this.step4submitted = true;
		this.alert.clear();
		if (this.phase4form.valid) {
			this.step4submitted = false;
			this.spinner.show();
			this.phase4form
				.get("projects_profile_id")
				.setValue(this.supervision_data.projects_profile_id);
			this.phase4form
				.get("projects_supervision_id")
				.setValue(this.supervision_data.projects_supervision_id);
			this.ds
				.postActionByUrl(
					this.ds.json2formdata(this.phase4form.value),
					"BKxaWIhtFE"
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							stepper.next();
							this.ds.dialogf("", res.msg);
							this.onAddComplete.emit("ADD");
						} else {
							this.alert.error(res.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				);
		}
	}
}
