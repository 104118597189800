import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-table-data",
	templateUrl: "./table-data.component.html",
	styleUrls: ["./table-data.component.scss"],
})
export class TableDataComponent implements OnInit {
	@Input() products: any[];
	@Input() cols: any[];
	_selectedColumns: any[];
	@Input() get selectedColumns(): any[] {
		return this._selectedColumns;
	}
	first = 0;

	rows = 10;
	constructor() {}

	ngOnInit() {
		this._selectedColumns = this.cols;
	}

	set selectedColumns(val: any[]) {
		//restore original order
		this._selectedColumns = this.cols.filter((col) => val.includes(col));
	}

	next() {
		this.first = this.first + this.rows;
	}

	prev() {
		this.first = this.first - this.rows;
	}

	reset() {
		this.first = 0;
	}

	isLastPage(): boolean {
		return this.products
			? this.first === this.products.length - this.rows
			: true;
	}

	isFirstPage(): boolean {
		return this.products ? this.first === 0 : true;
	}
}
