<section>
  <div class="row mb-3">
    <div class="col-12 col-sm-3 mb-2">
      <button
        mat-button
        style="
          background-color: rgb(224 136 200 / 42%);
          width: 100%;
          margin-bottom: 5px;
        "
        (click)="download_wafi()"
      >
        <i
          class="fa fa-download"
          style="font-size: 20px; margin: 0 5px; color: rgb(146, 19, 102)"
        ></i>
        <span
          style="
            text-transform: uppercase;
            font-weight: 600;
            color: rgb(146, 19, 102);
            font-size: 12px;
          "
          >{{ "lang_download" | language }}</span
        >
      </button>
    </div>
    <div class="col-12 col-sm-3 mb-2">
      <input
        type="file"
        #uploadWafiInput
        id="uploadWafiInput"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        hidden
        (change)="upload_wafi($event.target.files)"
      />
      <button
        matTooltip="{{ 'lang_must_download_first' | language }}"
        mat-button
        style="
          background-color: rgb(136 224 136 / 42%);
          width: 100%;
          margin-bottom: 5px;
        "
        (click)="uploadWafiInput.click()"
      >
        <i
          class="fa fa-upload"
          style="font-size: 20px; margin: 0 5px; color: rgb(19, 146, 19)"
        ></i>
        <span
          style="
            text-transform: uppercase;
            font-weight: 600;
            color: rgb(19, 146, 19);
            font-size: 12px;
          "
          >{{ "lang_upload" | language }}</span
        >
      </button>
    </div>
    <div class="col-12 col-sm-3 mb-2">
      <div class="form-group">
        <div style="position: relative">
          <input
            type="text"
            [formControl]="planMonthFilter"
            placeholder="{{ 'lang_project_month' | language }}"
            autocomplete="off"
            class="form-control almnabrformcontrol"
          />
          <span
            style="cursor: pointer; position: absolute; top: 8px; right: 10px"
            (click)="getManpowerList()"
            ><i class="fa fa-search mnabricon"></i
          ></span>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-3 mb-2">
      <button
        class="albutton mnabrbutton"
        mat-button
        (click)="onAddManpower(createTemp)"
      >
        <i class="fa fa-plus mnabricon mr-1 ml-1" style="color: #fff"></i>
        <span>{{ "lang_add" | language }}</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive dropdownyes customResponsive">
        <table border="1" class="table CustomTable">
          <thead>
            <tr>
              <td>#</td>
              <td>{{ "lang_project_month" | language }}</td>
              <td>{{ "lang_manpower_count" | language }}</td>
              <td>{{ "lang_engineer_count" | language }}</td>
              <td>{{ "lang_inspector_count" | language }}</td>
            </tr>
          </thead>
          <tbody class="AppendList">
            <tr
              *ngFor="
                let list of manpowerList;
                let i = index;
                let odd = odd;
                let even = even
              "
              [ngClass]="{ odd: odd, even: even }"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ list.project_month }}</td>
              <td>{{ list.manpower_count }}</td>
              <td>{{ list.engineer_count }}</td>
              <td>{{ list.inspector_count }}</td>
            </tr>
            <tr *ngIf="!manpowerList?.length">
              <td colspan="5" class="text-center">
                {{ "lang_no_data" | language }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<ng-template #createTemp>
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_add" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <!-- Modal body -->
    <div class="modal-body">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12 mb-2">
            <div class="table-responsive customResponsive quotationitemtable">
              <table
                border="1"
                class="table CustomTable"
                width="100%"
                formArrayName="manpower_plan"
              >
                <thead>
                  <tr>
                    <td width="2%" style="padding: 5px; word-break: break-all">
                      #
                    </td>
                    <td width="2%" style="padding: 5px; word-break: break-all">
                      <mat-icon
                        class="mnabricon"
                        matTooltip="{{ 'lang_add' | language }}"
                        (click)="addManpower()"
                        >add_box</mat-icon
                      >
                    </td>
                    <td width="24%" style="padding: 5px; word-break: break-all">
                      {{ "lang_project_month" | language }}
                    </td>
                    <td width="24%" style="padding: 5px; word-break: break-all">
                      {{ "lang_manpower_count" | language }}
                    </td>
                    <td width="24%" style="padding: 5px; word-break: break-all">
                      {{ "lang_engineer_count" | language }}
                    </td>
                    <td width="24%" style="padding: 5px; word-break: break-all">
                      {{ "lang_inspector_count" | language }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let item of manpowerFormGroup.controls;
                      let i = index;
                      let odd = odd;
                      let even = even
                    "
                    [formGroupName]="i"
                  >
                    <td
                      class="verticalalignmid"
                      style="padding: 5px; word-break: break-all"
                    >
                      {{ i + 1 }}
                    </td>
                    <td class="verticalalignmid">
                      <mat-icon
                        (click)="removeManpower(i)"
                        class="mnabricon red"
                        matTooltip="{{ 'lang_delete' | language }}"
                        >delete_forever</mat-icon
                      >
                    </td>
                    <td
                      style="
                        padding: 5px;
                        word-break: break-all;
                        position: relative;
                      "
                    >
                      <input
                        type="text"
                        formControlName="project_month"
                        placeholder="{{ 'lang_project_month' | language }}"
                        autocomplete="off"
                        class="form-control almnabrformcontrol shadow-none"
                      />
                    </td>
                    <td
                      class="verticalalignmid"
                      style="
                        padding: 5px;
                        word-break: break-all;
                        position: relative;
                      "
                    >
                      <input
                        type="text"
                        formControlName="manpower_count"
                        placeholder="{{ 'lang_manpower_count' | language }}"
                        autocomplete="off"
                        class="form-control almnabrformcontrol shadow-none"
                      />
                    </td>
                    <td
                      class="verticalalignmid"
                      style="
                        padding: 5px;
                        word-break: break-all;
                        position: relative;
                      "
                    >
                      <input
                        type="text"
                        formControlName="engineer_count"
                        placeholder="{{ 'lang_engineer_count' | language }}"
                        autocomplete="off"
                        class="form-control almnabrformcontrol shadow-none"
                      />
                    </td>
                    <td
                      class="verticalalignmid"
                      style="
                        padding: 5px;
                        word-break: break-all;
                        position: relative;
                      "
                    >
                      <input
                        type="text"
                        formControlName="inspector_count"
                        placeholder="{{ 'lang_inspector_count' | language }}"
                        autocomplete="off"
                        class="form-control almnabrformcontrol shadow-none"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer justify-content-between">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRef.hide()"
        style="width: 40%"
      >
        {{ "lang_close" | language }}
      </button>
      <button
        type="button"
        class="albutton mnabrbutton"
        style="width: 40% !important"
        (click)="onSubmit()"
        mat-button
      >
        {{ "lang_submit" | language }}
      </button>
    </div>
  </div>
</ng-template>
