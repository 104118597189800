<div mat-dialog-title>
  <div class="d-flex justify-content-between">
    <h4>{{ "lang_excel_history" | language }}</h4>
    <span mat-dialog-close><i class="fa fa-times"></i></span>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="table-responsive dropdownyes customResponsive tablefields">
    <table border="1" class="table CustomTable">
      <thead>
        <tr>
          <th>#</th>
          <th>{{ "lang_username" | language }}</th>
          <th>{{ "lang_date" | language }}</th>
          <th>{{ "lang_items_size" | language }}</th>
          <th>{{ "lang_old_file" | language }}</th>
          <th>{{ "lang_new_file" | language }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let item of historyArray;
            let i = index;
            let odd = odd;
            let even = even
          "
        >
          <td>{{ i + 1 }}</td>
          <td>{{ item?.user_username || "---" }}</td>
          <td>{{ item?.insert_date || "---" }}</td>
          <td>{{ item?.per_page || "---" }}</td>
          <td>
            <span matTooltip="export_old_excel"
              ><i
                class="fa fa-download"
                aria-hidden="true"
                (click)="downloadExl(item.log_id, '1')"
              ></i
            ></span>
          </td>
          <td>
            <span matTooltip="export_new_excel"
              ><i
                class="fa fa-download"
                aria-hidden="true"
                (click)="downloadExl(item.log_id, '2')"
              ></i
            ></span>
          </td>
        </tr>
        <tr *ngIf="!historyArray?.length">
          <td colspan="5">{{ "lang_no_data" | language }}</td>
        </tr>
      </tbody>
    </table>
    <div class="row responsive_pagination">
      <div
        class="col-sm-3 mb-10 col-lg-3"
        style="height: fit-content; margin: auto 0"
      >
        {{ "lang_total" | language }} :
        {{ total_records ? total_records : 0 }}
      </div>
      <div
        class="col-sm-6 mb-10 col-lg-6"
        style="display: flex; justify-content: center"
      >
        <ngb-pagination
          class="m-auto"
          [collectionSize]="total_records"
          [rotate]="true"
          [ellipses]="false"
          [maxSize]="3"
          [boundaryLinks]="true"
          [(page)]="page"
          [pageSize]="size"
          (pageChange)="getHistoryData()"
        ></ngb-pagination>
      </div>
      <div
        class="col-sm-3 col-lg-3"
        style="display: flex; justify-content: end; margin: auto"
      >
        <mat-select [(ngModel)]="size" (selectionChange)="getHistoryData()">
          <mat-option class="text-center" value="10"
            >10 {{ "lang_per_page" | language }}
          </mat-option>
          <mat-option class="text-center" value="20"
            >20 {{ "lang_per_page" | language }}
          </mat-option>
          <mat-option class="text-center" value="50"
            >50 {{ "lang_per_page" | language }}
          </mat-option>
          <mat-option class="text-center" value="100"
            >100 {{ "lang_per_page" | language }}</mat-option
          >
          <mat-option class="text-center" value="200"
            >200 {{ "lang_per_page" | language }}</mat-option
          >
        </mat-select>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="col-md-3 col-xs-12">
    <button
      type="button"
      class="albutton mnabrbutton mat-accent"
      mat-button
      mat-dialog-close
    >
      {{ "lang_close" | language }}
    </button>
  </div>
</mat-dialog-actions>
