<breadcrumb></breadcrumb>
<div class="themesection">
  <div class="container mb-10">
    <div class="row">
      <div class="col-md-2 col-sm-12 col-xs-12"></div>
      <div class="col-md-3 col-sm-6 col-xs-12 mb-2">
        <div *ngIf="branch_id == '0'" class="red pad0 text-center">
          {{ "lang_choose_branch" | language }}
        </div>
        <select
          name="branch_id"
          class="form-control almnabrformcontrol"
          matTooltip="{{ 'lang_branch' | language }}"
          [(ngModel)]="branch_id"
          (change)="spinner.show(); selectBrach($event.target.value)"
        >
          <option value="0">{{ "lang_choose_options" | language }}</option>
          <option *ngFor="let branch of branches" value="{{ branch.id }}">
            {{ branch.title }}
          </option>
        </select>
      </div>
      <div
        class="col-md-3 col-sm-6 col-xs-12 mb-2"
        *ngIf="branch_id && branch_id != '0'"
      >
        <ng-select
          [items]="finances"
          [multiple]="false"
          bindLabel="label"
          bindValue="value"
          clearAllText="Clear"
          appendTo="body"
          [(ngModel)]="finance_id"
          placeholder="{{ 'lang_financial_years' | language }}"
          (change)="spinner.show(); load_invoices_claim()"
        >
        </ng-select>
      </div>
      <div
        class="col-md-2 col-sm-6 col-xs-12 mb-10"
        *ngIf="branch_id && branch_id != '0'"
      >
        <button
          type="submit"
          class="albutton"
          (click)="
            this.router.navigateByUrl(
              '/accounts/create_invoice_claims/' + branch_id + '/' + finance_id
            )
          "
        >
          {{ "lang_add" | language }}
        </button>
      </div>
      <div class="col-md-2 col-sm-12 col-xs-12 mar0auto"></div>
    </div>
  </div>
  <section
    class="panel-expansion matborderno"
    *ngIf="branch_id && branch_id != '0'"
  >
    <mat-accordion class="invoices_claim_lists">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_invoices_claim" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="{{ 'lang_search' | language }}"
                    (keyup)="search_invoices()"
                    [(ngModel)]="searchKey"
                    class="form-control almnabrformcontrol search_key"
                    id="search_key"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive dropdownyes customResponsive">
                  <table border="1" class="table CustomTable">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>{{ "lang_invoice_no" | language }}</td>
                        <td>{{ "lang_transaction_number" | language }}</td>
                        <td>{{ "lang_invoice_date" | language }}</td>
                        <td>{{ "lang_customers" | language }}</td>
                        <td>{{ "lang_writer_id" | language }}</td>
                        <td>{{ "lang_ondate" | language }}</td>
                        <td>{{ "lang_action" | language }}</td>
                      </tr>
                    </thead>
                    <tbody *ngIf="InvData.length != '0'" class="AppendList">
                      <tr
                        *ngFor="
                          let data of InvData?.records;
                          let i = index;
                          let odd = odd;
                          let even = even
                        "
                        [ngClass]="{ odd: odd, even: even }"
                        class="makeback removerow{{ data.invoice_id }}"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>
                          <a
                            matTooltip="{{ 'lang_view' | language }}"
                            routerLink="/accounts/view_invoice_claims/{{
                              data.branch_id
                            }}/{{ data.finance_id }}/{{ data.invoice_id }}"
                            >{{ data.invoice_system_code }}</a
                          >
                        </td>
                        <td>
                          <a
                            matTooltip="{{ 'lang_view' | language }}"
                            routerLink="/accounts/view_invoice_claims/{{
                              data.branch_id
                            }}/{{ data.finance_id }}/{{ data.invoice_id }}"
                            >{{ data.transaction_id }}</a
                          >
                        </td>
                        <td>
                          <a
                            matTooltip="{{ 'lang_view' | language }}"
                            routerLink="/accounts/view_invoice_claims/{{
                              data.branch_id
                            }}/{{ data.finance_id }}/{{ data.invoice_id }}"
                            >{{ data.invoice_date }}</a
                          >
                        </td>
                        <td>{{ data.customer_account }}</td>
                        <td>{{ data.invoice_writer }}</td>
                        <td>{{ data.invoice_created_date }}</td>
                        <td class="dropdown">
                          <mat-icon class="mnabricon" [matMenuTriggerFor]="menu"
                            >settings</mat-icon
                          >
                          <mat-menu #menu="matMenu">
                            <button
                              mat-menu-item
                              matTooltip="{{ 'lang_view' | language }}"
                              routerLink="/accounts/view_invoice_claims/{{
                                data.branch_id
                              }}/{{ data.finance_id }}/{{ data.invoice_id }}"
                            >
                              <span>{{ "lang_view" | language }}</span>
                            </button>
                            <button
                              mat-menu-item
                              routerLink="/accounts/edit_invoice_claims/{{
                                data.branch_id
                              }}/{{ data.finance_id }}/{{ data.invoice_id }}"
                            >
                              <span>{{ "lang_edit" | language }}</span>
                            </button>
                            <button mat-menu-item [matMenuTriggerFor]="submenu">
                              <span>{{ "lang_export" | language }}</span>
                            </button>
                            <button
                              mat-menu-item
                              (click)="convert_to_selling(data.invoice_id)"
                            >
                              <span>{{
                                "lang_convert_to_selling_invoice" | language
                              }}</span>
                            </button>
                            <button
                              mat-menu-item
                              (click)="
                                remove_invoice_claim(
                                  data?.branch_id,
                                  data?.transaction_id,
                                  data?.invoice_id
                                )
                              "
                            >
                              <span>{{ "lang_delete" | language }}</span>
                            </button>
                          </mat-menu>
                          <mat-menu #submenu="matMenu">
                            <button
                              mat-menu-item
                              (click)="
                                ds.print_account_transaction(
                                  data.invoice_id,
                                  'CLAIM',
                                  'PDF',
                                  branch_id
                                )
                              "
                            >
                              <span>{{ "lang_print" | language }}</span>
                            </button>
                            <button
                              mat-menu-item
                              (click)="
                                ds.export_account_transaction(
                                  data.invoice_id,
                                  'CLAIM',
                                  'PDF',
                                  branch_id
                                )
                              "
                            >
                              <span>{{ "lang_export_pdf" | language }}</span>
                            </button>
                            <button
                              mat-menu-item
                              (click)="
                                ds.export_account_transaction(
                                  data.invoice_id,
                                  'CLAIM',
                                  'EXL',
                                  branch_id
                                )
                              "
                            >
                              <span>{{ "lang_export_excel" | language }}</span>
                            </button>
                            <button
                              mat-menu-item
                              (click)="
                                ds.export_account_transaction(
                                  data.invoice_id,
                                  'CLAIM',
                                  'EPDF',
                                  branch_id
                                )
                              "
                            >
                              <span>{{
                                "lang_export_pdf_to_email" | language
                              }}</span>
                            </button>
                            <button
                              mat-menu-item
                              (click)="
                                ds.export_account_transaction(
                                  data.invoice_id,
                                  'CLAIM',
                                  'EEXL',
                                  branch_id
                                )
                              "
                            >
                              <span>{{
                                "lang_export_excel_to_email" | language
                              }}</span>
                            </button>
                          </mat-menu>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="InvData.length == '0'" class="AppendList">
                      <tr class="odd">
                        <td colspan="8" align="center">
                          {{ "lang_no_data" | language }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="InvData.length != '0'" class="card-footer padb0">
            <div class="row responsive_pagination">
              <div
                class="col-lg-3 col-sm-3 mb-10"
                style="height: fit-content; margin: auto 0"
              >
                {{ "lang_total" | language }} :
                {{ InvData?.paging?.total_records }}
              </div>
              <div
                class="col-lg-6 col-sm-6 mb-10"
                style="display: flex; justify-content: center"
              >
                <ngb-pagination
                  class="m-auto"
                  [collectionSize]="InvData?.paging?.total_records"
                  [rotate]="true"
                  [ellipses]="false"
                  [maxSize]="3"
                  [boundaryLinks]="true"
                  [(page)]="Invpage_no"
                  [pageSize]="Invpage_size"
                  (pageChange)="loadPageInv($event)"
                ></ngb-pagination>
              </div>
              <div
                class="col-lg-3 col-sm-3"
                style="display: flex; justify-content: end; margin: auto"
              >
                <mat-select
                  style="max-width: 150px"
                  [(ngModel)]="Invpage_size"
                  (selectionChange)="loadInvPagesize($event.value)"
                >
                  <mat-option class="text-center" value="10"
                    >10 {{ "lang_per_page" | language }}
                  </mat-option>
                  <mat-option class="text-center" value="20"
                    >20 {{ "lang_per_page" | language }}
                  </mat-option>
                  <mat-option class="text-center" value="50"
                    >50 {{ "lang_per_page" | language }}
                  </mat-option>
                  <mat-option class="text-center" value="100"
                    >100 {{ "lang_per_page" | language }}</mat-option
                  >
                  <mat-option class="text-center" value="500"
                    >500 {{ "lang_per_page" | language }}</mat-option
                  >
                </mat-select>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
