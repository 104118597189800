import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
	selector: "app-platform-details",
	templateUrl: "./platform-details.component.html",
	styleUrls: ["./platform-details.component.scss"],
})
export class PlatformDetailsComponent implements OnInit {
	platformData: any;
	work_levels: any = [];
	building_codes: any = [];
	authorized_positions: any = [];
	receipient_positions: any = [];
	consultant_required_arr = [];
	new_consultant_required_arr = [];
	consultant_recommindations = [];
	building_codes_page = 1;
	building_codes_size = 5;
	authorized_positions_page = 1;
	authorized_positions_size = 5;
	receipient_positions_page = 1;
	receipient_positions_size = 5;
	consultant_required_page = 1;
	consultant_required_size = 5;
	consultant_recommindations_page = 1;
	consultant_recommindations_size = 5;
	work_levels_page = 1;
	work_levels_size = 5;
	check_lists_separated = [];
	constructor(
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private dialogRef: MatDialogRef<PlatformDetailsComponent>
	) {
		this.platformData = data?.platform;
		this.work_levels = Object?.values(data?.platform?.work_levels);
		this.building_codes = this.selected_items(
			data?.platform?.template_platform_building_codes,
			data?.buildingCodes
		);
		this.authorized_positions = this.selected_items(
			data?.platform?.authorized_positions,
			data?.positions
		);
		this.receipient_positions = this.selected_items(
			data?.platform?.receipient_positions,
			data?.positions
		);
		this.consultant_required_arr = this.selected_items(
			this.check_list_ids(data?.platform?.consultant_required),
			data?.fconsultants
		);
		this.consultant_recommindations = this.selected_items(
			data?.platform?.consultant_recomendations,
			data?.consultantr
		);

		for (let item of this.consultant_required_arr) {
			let result = this.check_lists_separated.find((el) => item.value == el.id);
			this.new_consultant_required_arr.push({ ...item, result: result.result });
		}
	}

	ngOnInit(): void {}

	selected_items(ids, arr) {
		let arrOfIds = ids.split(",");
		let newArr = [];
		for (let item of arrOfIds) {
			let element = arr?.find((el) => el.value == item);
			if (element) {
				newArr.push(element);
			}
		}
		return newArr;
	}

	check_list_ids(checklist) {
		let checkListArr = checklist.split(","),
			ids = [],
			results = [];
		for (let item of checkListArr) {
			if (item.includes("=")) {
				let id = item.split("=")[0];
				let result = item.split("=")[1];
				ids.push(id);
				results.push({ id: id, result: result });
			}
		}
		this.check_lists_separated = results;
		return ids.join(",");
	}

	closeModal() {
		this.dialogRef.close();
	}
}
