import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { NgbCalendar, NgbCalendarIslamicUmalqura, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { IslamicI18n } from '@shared/utils/arabicpicker';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n }
  ]
})
export class Step4Component implements OnInit {
  @Input() regForm: FormGroup;
  @Input() action;
  idfilename: any = this.lang.transform('lang_select_files');
  isFile: boolean = false;
  constructor(public router:Router, public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  get f() {
    return this.regForm.controls.educationDetails;
  }
  lang_key = localStorage.getItem('lang_key') ? localStorage.getItem('lang_key') : 'en';

  ngOnInit() {
    this.get_advanced_search_meta_data()
  }

  metaData = []

  public get_advanced_search_meta_data() {
    this.metaData = [];
    this.ds.getActionByUrl([], 'human_resources/get_advanced_search_meta_data').subscribe(data => {
      if (data.status) {
        this.metaData = data.records.rating_degrees.map(data=>{
          return {
            label:this.lang_key =='ar'? data.name_arabic : data.name_english,
            value:data.id
          }
        });
      }
    });
  }

  experiance_arr:any=[]
  uploadEDUFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.isFile = true;
      this.regForm.get("educationDetails").get("education_file").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.regForm.get("educationDetails").get("education_file").setValue([]);
      this.idfilename = this.lang.transform('lang_select_files');
    }
  }
  removeFile() {
    this.isFile = false;
    this.regForm.get("educationDetails").get("education_file").setValue([]);
    this.idfilename = this.lang.transform('lang_select_files');
  }
  step4Submitted() {
    this.regForm.get('educationDetails').get('primary_graduation_year').markAsTouched();
    this.regForm.get('educationDetails').get('primary_graduation_year').updateValueAndValidity();
    this.regForm.get('educationDetails').get('primary_education_level').markAsTouched();
    this.regForm.get('educationDetails').get('primary_education_level').updateValueAndValidity();
    this.regForm.get('educationDetails').get('rating_degree').markAsTouched();
    this.regForm.get('educationDetails').get('rating_degree').updateValueAndValidity();
    if(this.action !=="EDIT"){
      this.regForm.get('educationDetails').get('edu_file_name').markAsTouched();
      this.regForm.get('educationDetails').get('edu_file_name').updateValueAndValidity();
      this.regForm.get('educationDetails').get('education_file').markAsTouched();
      this.regForm.get('educationDetails').get('education_file').updateValueAndValidity();
    }
  }
}
