<div mat-dialog-title class="d-flex justify-content-between">
	<h4 *ngIf="!editMode">
		{{ "lang_add" | language }}
	</h4>
	<h4 *ngIf="editMode">
		{{ "lang_edit" | language }}
	</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="point_form">
		<div class="p-2">
			<div class="row">
				<div class="col-12 col-sm-6">
					<div class="title">
						<mat-label class="item-label">{{
							"lang_title" | language
						}}</mat-label>
						<input
							type="text"
							placeholder="{{ 'lang_title' | language }}"
							formControlName="point_notes"
						/>
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div class="title">
						<mat-label class="item-label">{{
							"lang_hour_no" | language
						}}</mat-label>
						<input
							type="text"
							placeholder="{{ 'lang_hour_no' | language }}"
							formControlName="hour_no"
							(keypress)="ds.isNumber($event)"
						/>
					</div>
				</div>
				<div class="col-12 col-sm-6 mb-2">
					<label class="item-label">{{ "lang_members" | language }}</label>
					<ng-select
						[items]="search_point_users"
						[multiple]="true"
						bindLabel="label"
						bindValue="value"
						appendTo="body"
						clearAllText="Clear"
						[searchFn]="ds.customSearchFn"
						formControlName="users"
						placeholder="{{ 'lang_members' | language }}"
						(search)="search_users($event)"
					>
					</ng-select>
				</div>
				<div
					class="col-12 col-sm-6 mb-2"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<label class="item-label">{{ "lang_date" | language }}</label>
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_date" | language }}</mat-label>
						<input matInput required readonly formControlName="end_date" />
						<mat-datepicker-toggle
							matSuffix
							(click)="picker.open()"
						></mat-datepicker-toggle>
						<mbsc-datepicker
							[controls]="['calendar']"
							select="date"
							[touchUi]="false"
							dateFormat="YYYY/MM/DD"
							[(ngModel)]="dateMobiscroll"
							[buttons]="autoButtons"
							[ngModelOptions]="{ standalone: true }"
							(onClose)="custmizeDate('end_date')"
							style="visibility: hidden; height: 0"
							#picker
						>
						</mbsc-datepicker>
					</mat-form-field>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-0 col-sm-9"></div>
	<div class="col-12 col-sm-3">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="addUpdatePoint()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
