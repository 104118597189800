<breadcrumb></breadcrumb>
<div class="themesection">
  <section class="panel-expansion matborderno">
    <mat-accordion class="accounttrial">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header printnone">{{
          "lang_sub_accounts_trial" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15">
            <form
              class="form"
              [formGroup]="form"
              (ngSubmit)="fetch_statements()"
            >
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                  <select
                    formControlName="branch_id"
                    class="form-control almnabrformcontrol"
                    matTooltip="{{ 'lang_branch' | language }}"
                    (change)="spinner.show(); selectBrach($event.target.value)"
                  >
                    <option value="0">
                      {{ "lang_choose_options" | language }}
                    </option>
                    <option
                      *ngFor="let branch of branches"
                      value="{{ branch.id }}"
                    >
                      {{ branch.title }}
                    </option>
                  </select>
                </div>
                <div
                  class="col-md-6 col-sm-6 col-xs-12 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0'
                  "
                >
                  <ng-select
                    [items]="finances"
                    [multiple]="false"
                    bindLabel="label"
                    bindValue="value"
                    appendTo="body"
                    clearAllText="Clear"
                    formControlName="finance_id"
                    placeholder="{{ 'lang_financial_years' | language }}"
                    (change)="ds.setDate($event, form)"
                  >
                  </ng-select>
                </div>
              </div>
              <!-- <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
									<div class="form-group">
										<label>{{ "lang_account_from" | language }} : </label><br />
										<ng-select
											[items]="fromaccounts"
											[multiple]="false"
											bindLabel="label"
											bindValue="value"
											appendTo="body"
											clearAllText="Clear"
											formControlName="account_code_from"
											placeholder="{{ 'lang_account_from' | language }}"
											(search)="getParentAccounts($event)"
										>
										</ng-select>
									</div>
								</div>
								<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
									<div class="form-group">
										<label>{{ "lang_account_to" | language }} : </label><br />
										<ng-select
											[items]="fromaccounts"
											[multiple]="false"
											bindLabel="label"
											bindValue="value"
											appendTo="body"
											clearAllText="Clear"
											formControlName="account_code_to"
											placeholder="{{ 'lang_account_to' | language }}"
											(search)="getParentAccounts($event)"
										>
										</ng-select>
									</div>
								</div>
                            </div> -->
              <div class="row">
                <div
                  class="col-md-6 col-sm-6 col-xs-12 mb-10"
                  *ngIf="
                    form.get('finance_id').value &&
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0'
                  "
                >
                  <div class="form-group">
                    <label
                      >{{ "lang_period_from" | language }} :
                      <span class="red">*</span> </label
                    ><br />
                    <input
                      formControlName="period_from"
                      id="period_from"
                      class="form-control almnabrformcontrol period_from"
                      placeholder="YYYY/MM/DD"
                    />
                  </div>
                </div>
                <div
                  class="col-md-6 col-sm-6 col-xs-12 mb-10"
                  *ngIf="
                    form.get('finance_id').value &&
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0'
                  "
                >
                  <div class="form-group">
                    <label
                      >{{ "lang_period_to" | language }} :<span class="red"
                        >*</span
                      > </label
                    ><br />
                    <input
                      formControlName="period_to"
                      id="period_to"
                      class="form-control almnabrformcontrol period_to"
                      placeholder="YYYY/MM/DD"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                  <div class="form-group">
                    <label>{{ "lang_account" | language }} : </label><br />
                    <ng-select
                      [items]="fromaccounts"
                      [multiple]="false"
                      bindLabel="label"
                      bindValue="value"
                      appendTo="body"
                      clearAllText="Clear"
                      formControlName="account_code_from"
                      placeholder="{{ 'lang_account' | language }}"
                      (search)="getParentAccounts($event)"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                  <div class="form-group">
                    <label
                      >{{ "lang_report_type" | language }} :
                      <span class="red">*</span> </label
                    ><br />
                  </div>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                  <div class="form-group">
                    <mat-radio-group
                      aria-label="Select an option"
                      formControlName="report_type"
                      (change)="report_type(form.get('report_type').value)"
                    >
                      <mat-radio-button value="S">{{
                        "lang_summary" | language
                      }}</mat-radio-button>
                      <mat-radio-button value="D">{{
                        "lang_details" | language
                      }}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <div
                class="row"
                *ngIf="
                  form.get('finance_id').value &&
                  form.get('branch_id').value &&
                  form.get('branch_id').value != '0'
                "
              >
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                  <button type="submit" class="albutton">
                    {{ "lang_submit" | language }}
                  </button>
                </div>
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                  <button
                    type="button"
                    class="albutton"
                    (click)="export_exl_pdf('E')"
                  >
                    {{ "lang_export_excel" | language }}
                  </button>
                </div>
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                  <button
                    type="button"
                    class="albutton"
                    (click)="export_exl_pdf('P')"
                  >
                    {{ "lang_export_pdf" | language }}
                  </button>
                </div>
                <div
                  class="col-md-2 col-sm-2 col-xs-12 mb-10"
                  *ngIf="data.length != '0'"
                >
                  <button type="button" class="albutton" (click)="ds.print()">
                    {{ "lang_print" | language }}
                  </button>
                </div>
              </div>
            </form>
            <div
              class="row"
              *ngIf="
                datastatus &&
                form.get('branch_id').value &&
                form.get('branch_id').value != '0'
              "
            >
              <div class="col-md-12">
                <div
                  class="table-responsive dropdownyes customResponsive tablefields"
                >
                  <table border="1" class="table CustomTable">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>{{ "lang_accounts_acccode" | language }}</td>
                        <td>{{ "lang_account" | language }}</td>
                        <td *ngIf="rt == 'D'">
                          {{ "lang_opening_debit" | language }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ "lang_opening_credit" | language }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ "lang_accounts_opening_balance" | language }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ "lang_transaction_debit" | language }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ "lang_transaction_credit" | language }}
                        </td>
                        <td>{{ "lang_closing_debit" | language }}</td>
                        <td>{{ "lang_closing_credit" | language }}</td>
                      </tr>
                    </thead>
                    <tbody *ngIf="data.length != '0'" class="AppendList">
                      <tr
                        *ngFor="
                          let tran of data;
                          let i = index;
                          let odd = odd;
                          let even = even
                        "
                        [ngClass]="{ odd: odd, even: even }"
                        class="{{ tran.class }}"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>{{ tran.account_code }}</td>
                        <td>{{ tran.account_name }}</td>
                        <td *ngIf="rt == 'D'">
                          {{ absolute(tran.opening_debit) }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ absolute(tran.opening_credit) }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ absolute(tran.opening_balance) }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ absolute(tran.transaction_debit) }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ absolute(tran.transaction_credit) }}
                        </td>
                        <td>{{ absolute(tran.closing_debit) }}</td>
                        <td>{{ absolute(tran.closing_credit) }}</td>
                      </tr>
                      <tr class="bold bground text-center">
                        <td>-----</td>
                        <td>-----</td>
                        <td>{{ "lang_grand_total" | language }}</td>
                        <td *ngIf="rt == 'D'">
                          {{ absolute(totals.total_opening_debit) }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ absolute(totals.total_opening_credit) }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ absolute(totals.total_opening_balance) }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ absolute(totals.total_transaction_debit) }}
                        </td>
                        <td *ngIf="rt == 'D'">
                          {{ absolute(totals.total_transaction_credit) }}
                        </td>
                        <td>{{ absolute(totals.total_closing_debit) }}</td>
                        <td>{{ absolute(totals.total_closing_credit) }}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="data.length == '0'" class="AppendList">
                      <tr class="odd">
                        <td *ngIf="rt == 'D'" colspan="10" align="center">
                          {{ lodingdatas }}
                        </td>
                        <td *ngIf="rt == 'S'" colspan="5" align="center">
                          {{ lodingdatas }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    class="d-flex justify-content-between p-2 printnone"
                    *ngIf="data.length != '0'"
                  >
                    <ngb-pagination
                      [collectionSize]="collectionSize"
                      [rotate]="true"
                      [ellipses]="false"
                      [maxSize]="3"
                      [boundaryLinks]="true"
                      [(page)]="page"
                      [pageSize]="pageSize"
                      (pageChange)="refreshLists()"
                    ></ngb-pagination>
                    <label
                      >{{ "lang_total" | language }} :
                      {{ collectionSize }}</label
                    >
                    <select
                      class="customselect"
                      [(ngModel)]="pageSize"
                      (change)="refreshLists()"
                    >
                      <option [ngValue]="20">20</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                      <option [ngValue]="250">250</option>
                      <option [ngValue]="500">500</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
