<router-outlet></router-outlet>
<div class="modal" id="showPDFPreview">
  <div class="modal-dialog width70">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'lang_preview' | language }}</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="pdf-container">
          <iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'lang_close' | language }}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="showSMPreview">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'lang_preview' | language }}</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="pdf-container text-center">
          <img id="SMnabrPdf" class="img-responsive" src=""/>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'lang_close' | language }}</button>
      </div>
    </div>
  </div>
</div>