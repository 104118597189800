<form [formGroup]="form" class="form" (ngSubmit)="submit()">
	<section class="panel-expansion ckeditorhidetoolbar">
		<div class="card mt-3 mb-3">
			<div class="card-header">
				{{ "lang_quotation" | language }}
			</div>
			<div
				class="mbrl15 pt-2 pb-2"
				[ngClass]="{ ' contentrtl': lang_key == 'ar' }"
			>
				<div class="row mb-2">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label>
								{{ "lang_languages" | language }}
								<span class="red">*</span></label
							>
							<ng-select
								[items]="languages"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								clearAllText="Clear"
								appendTo="body"
								formControlName="lang_key"
								placeholder="{{ 'lang_languages' | language }}"
								(change)="spinner.show(); changedirection($event)"
							>
							</ng-select>
							<mat-error
								*ngIf="submitted && form.controls['lang_key'].errors?.required"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div>
					<div
						class="col-md-4 col-sm-4 col-xs-12 mb-2"
						*ngIf="quotation_type_status"
					>
						<div class="form-group">
							<label>
								{{ "lang_quotation_type" | language }}
								<span class="red">*</span></label
							>
							<ng-select
								[items]="quottypes"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								clearAllText="Clear"
								appendTo="body"
								formControlName="quotation_types_keyword"
								placeholder="{{ 'lang_quotation_type' | language }}"
								(change)="spinner.show(); quotation_select($event)"
							>
							</ng-select>
							<mat-error
								*ngIf="
									submitted &&
									form.controls['quotation_types_keyword'].errors?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div>
					<div
						class="col-md-4 col-sm-4 col-xs-12 mb-2"
						*ngIf="quotation_type_status"
					>
						<div class="form-group">
							<label>
								{{ "lang_vat" | language }} <span class="red">*</span></label
							>
							<select
								class="form-control almnabrformcontrol"
								formControlName="vat_key"
							>
								<option value="">{{ "lang_choose_options" | language }}</option>
								<option *ngFor="let v of vat" value="{{ v.keyword }}">
									{{ v.label }}
								</option>
							</select>
							<mat-error
								*ngIf="submitted && form.controls['vat_key'].errors?.required"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-md-6 col-sm-6 col-xs-12 mb-2">
						<div class="form-group">
							<label>
								{{ "lang_subject" | language }}
								<span class="red">*</span></label
							>
							<input
								type="text"
								class="form-control almnabrformcontrol"
								formControlName="transaction_request_description"
							/>
							<mat-error
								*ngIf="
									submitted &&
									form.controls['transaction_request_description'].errors
										?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div>
					<div class="col-md-6 col-sm-6 col-xs-12 mb-2">
						<div class="form-group">
							<label> {{ "lang_care" | language }}</label>
							<input
								type="text"
								placeholder="{{ 'lang_care' | language }}"
								class="form-control almnabrformcontrol"
								formControlName="care"
							/>
						</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-12 mb-2">
						<label>
							{{ "lang_description" | language }}
							<span class="red">*</span></label
						>
						<ckeditor
							#editor
							[config]="config"
							(ready)="onCkEditorReady($event)"
							formControlName="content"
							[ngClass]="{ ' contentrtl': lang_key == 'ar' }"
						></ckeditor>
						<mat-error
							*ngIf="submitted && form.controls['content'].errors?.required"
							>{{ "lang_field_required" | language }}</mat-error
						>
					</div>
				</div>
				<div
					class="itemsdata"
					*ngIf="quotation_type_status && show_item_fileds"
				>
					<label>
						{{ "lang_teams" | language }} <span class="red">*</span></label
					>
					<div class="TableParts">
						<div
							class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
						>
							<table border="1" class="table CustomTable text-center">
								<thead>
									<tr>
										<td>#</td>
										<td>
											<mat-icon
												class="mnabricon"
												matTooltip="{{ 'lang_add' | language }}"
												(click)="add_new_items()"
												>add_box</mat-icon
											>
										</td>
										<td>
											<span matTooltip="{{ 'lang_position' | language }}">{{
												"lang_position" | language
											}}</span>
											<i class="red"> *</i>
										</td>
										<td>
											<span matTooltip="{{ 'lang_presence' | language }}">{{
												"lang_presence" | language
											}}</span>
											<i class="red"> *</i>
										</td>
										<td>
											<span
												matTooltip="{{
													'lang_projects_nationality' | language
												}}"
												>{{ "lang_projects_nationality" | language }}</span
											>
											<i class="red"> *</i>
										</td>
										<td>
											<span matTooltip="{{ 'lang_team_number' | language }}">{{
												"lang_team_number" | language
											}}</span
											><i class="red"> *</i>
										</td>
										<td>
											<span matTooltip="{{ 'lang_experience' | language }}">{{
												"lang_experience" | language
											}}</span
											><i class="red"> *</i>
										</td>
										<td>
											<span matTooltip="{{ 'lang_proit_ratio' | language }}">{{
												"lang_proit_ratio" | language
											}}</span
											><i class="red"> *</i>
										</td>
										<td>
											<span
												matTooltip="{{
													'lang_total_salary_per_person_month' | language
												}}"
												>{{
													"lang_total_salary_per_person_month" | language
												}}</span
											><i class="red"> *</i>
										</td>
										<td>
											<span
												matTooltip="{{
													'lang_duration_number_months' | language
												}}"
												>{{ "lang_duration_number_months" | language }}</span
											><i class="red"> *</i>
										</td>
										<td>
											<span
												matTooltip="{{ 'lang_total_no_of_visits' | language }}"
												>{{ "lang_total_no_of_visits" | language }}</span
											><i class="red"> *</i>
										</td>
										<td>
											<span matTooltip="{{ 'lang_toal_bid' | language }}">{{
												"lang_toal_bid" | language
											}}</span
											><i class="red"> *</i>
										</td>
									</tr>
								</thead>
								<tbody formArrayName="items" class="AppendList quotationtable">
									<tr
										*ngFor="
											let item of items_group().controls;
											let itemIndex = index;
											let odd = odd;
											let even = even
										"
										[formGroupName]="itemIndex"
										style="background: #ffffff"
									>
										<td class="verticalalignmid">{{ itemIndex + 1 }}</td>
										<td class="verticalalignmid">
											<mat-icon
												class="mnabricon red"
												matTooltip="{{ 'lang_delete' | language }}"
												(click)="removeItems(itemIndex)"
												>close</mat-icon
											>
										</td>
										<td width="10%">
											<ng-select
												[items]="data"
												[multiple]="false"
												clearAllText="Clear"
												appendTo="body"
												formControlName="position"
												placeholder="{{ 'lang_position' | language }}"
												(change)="
													setDataAction($event, itemIndex);
													calculate_quotation(itemIndex)
												"
											>
											</ng-select>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get('position').errors
														?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
										<td width="10%">
											<ng-select
												[items]="data2"
												[multiple]="false"
												clearAllText="Clear"
												appendTo="body"
												formControlName="presence"
												placeholder="{{ 'lang_presence' | language }}"
												(change)="
													setDataAction($event, itemIndex);
													calculate_quotation(itemIndex)
												"
											>
											</ng-select>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get('presence').errors
														?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
										<td width="10%">
											<ng-select
												[items]="nationality"
												[multiple]="false"
												bindLabel="label"
												bindValue="value"
												clearAllText="Clear"
												appendTo="body"
												formControlName="nationality"
												placeholder="{{
													'lang_human_resources_nationality' | language
												}}"
											>
											</ng-select>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get('nationality')
														.errors?.required
												"
												>{{
													"lang_human_resources_nationality" | language
												}}</mat-error
											>
										</td>
										<td>
											<input
												type="text"
												placeholder="{{ 'lang_team_number' | language }}"
												formControlName="team_number"
												class="form-control almnabrformcontrol"
												(keyup)="calculate_quotation(itemIndex)"
											/>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get('team_number')
														.errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
										<td>
											<input
												type="text"
												placeholder="{{ 'lang_experience' | language }}"
												formControlName="experience"
												class="form-control almnabrformcontrol"
												(keyup)="calculate_quotation(itemIndex)"
											/>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get('experience')
														.errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
										<td>
											<input
												type="text"
												placeholder="{{ 'lang_proit_ratio' | language }}"
												formControlName="profit_ratio"
												class="form-control almnabrformcontrol"
												(change)="
													ds.CheckDecimal(
														$event.target.value,
														items.controls[itemIndex].get('profit_ratio')
													);
													calculate_quotation(itemIndex)
												"
												(keyup)="calculate_quotation(itemIndex)"
											/>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get('profit_ratio')
														.errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
										<td class="readonlybyaction">
											<input
												type="text"
												placeholder="{{
													'lang_total_salary_per_person_month' | language
												}}"
												formControlName="total_salary_per_person_month"
												readonly
												class="form-control almnabrformcontrol readonlyinput"
												(keyup)="calculate_quotation(itemIndex)"
											/>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get(
														'total_salary_per_person_month'
													).errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
										<td>
											<input
												type="text"
												placeholder="{{
													'lang_duration_number_months' | language
												}}"
												formControlName="duration_of_months"
												class="form-control almnabrformcontrol"
												(keyup)="calculate_quotation(itemIndex)"
											/>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get(
														'duration_of_months'
													).errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
										<td class="rowdisable{{ itemIndex }}">
											<input
												type="text"
												placeholder="{{ 'lang_total_no_of_visits' | language }}"
												formControlName="visits_per_month"
												class="form-control almnabrformcontrol inputdisable"
												(keyup)="calculate_quotation(itemIndex)"
											/>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get('visits_per_month')
														.errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
										<td class="readonlybyaction">
											<input
												type="text"
												placeholder="{{ 'lang_toal_bid' | language }}"
												formControlName="total_bid_of_project"
												readonly
												class="form-control almnabrformcontrol readonlyinput"
												(change)="
													ds.CheckDecimal(
														$event.target.value,
														items.controls[itemIndex].get(
															'total_bid_of_project'
														)
													)
												"
												(keyup)="calculate_quotation(itemIndex)"
											/>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get(
														'total_bid_of_project'
													).errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="TableParts">
						<div class="table-responsive customResponsive mb-10 tablefields">
							<table border="1" class="table CustomTable text-center">
								<thead>
									<tr>
										<td>#</td>
										<td>
											<span
												matTooltip="{{
													'lang_position_description' | language
												}}"
												>{{ "lang_position_description" | language }}</span
											>
											<i class="red"> *</i>
										</td>
									</tr>
								</thead>
								<tbody formArrayName="items" class="AppendList">
									<tr
										*ngFor="
											let item of items_group().controls;
											let itemIndex = index;
											let odd = odd;
											let even = even
										"
										[formGroupName]="itemIndex"
										style="background: #ffffff"
									>
										<td width="1%" class="verticalalignmid">
											{{ itemIndex + 1 }}
										</td>
										<td>
											<input
												type="text"
												formControlName="positions_task"
												class="form-control almnabrformcontrol"
												placeholder="{{
													'lang_position_description' | language
												}}"
											/>
											<mat-error
												*ngIf="
													submitted &&
													itemsgroup.controls[itemIndex].get('positions_task')
														.errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="row mb-2" *ngIf="dynamiccontents && quotation_type_status">
					<div class="col-12">
						<fieldset class="cardback fieldset">
							<legend>{{ "lang_terms_conditions" | language }}</legend>
							<div
								class="contentpanel"
								[ngClass]="{ ' contentrtl': lang_key == 'ar' }"
								#contentData
							></div>
						</fieldset>
					</div>
				</div>
				<div
					class="row mb-2"
					*ngIf="dynamicrestrictions && quotation_type_status"
				>
					<div class="col-12">
						<fieldset class="cardback fieldset">
							<legend>{{ "lang_optional" | language }}</legend>
							<section class="example-section">
								<mat-checkbox
									*ngFor="let d of restrictions_fixed_optional_items"
									class="inlinecheckbox"
									[checked]="d.checked"
									(change)="d.checked = !d.checked"
									>{{ d.label }}</mat-checkbox
								>
							</section>
						</fieldset>
					</div>
				</div>
				<input type="hidden" formControlName="needOthers" />
				<div class="row mb-2" *ngIf="othersstatus">
					<div class="col-md-12 col-sm-12 col-xs-12 mb-2">
						<fieldset class="cardback fieldset">
							<legend>
								{{ "lang_others" | language }}
								<span class="red"
									>*
									<mat-icon
										class="red mnabricon"
										matTooltip="{{ 'lang_close' | language }}"
										(click)="disable_field('others')"
										>close</mat-icon
									></span
								>
							</legend>
							<div class="table-responsive customResponsive tablefields">
								<table
									border="1"
									class="table CustomTable"
									width="100%"
									formArrayName="others"
								>
									<thead>
										<tr>
											<td style="padding: 5px; word-break: break-all">
												<mat-icon
													class="mnabricon"
													matTooltip="{{ 'lang_add' | language }}"
													(click)="add_new_others()"
													>add_box</mat-icon
												>
											</td>
											<td style="padding: 5px; word-break: break-all">
												{{ "lang_title_english" | language }}
											</td>
											<td style="padding: 5px; word-break: break-all">
												{{ "lang_title_arabic" | language }}
											</td>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="
												let otr of othersgroup.controls;
												let i = index;
												let odd = odd;
												let even = even
											"
											[ngClass]="{ odd: odd, even: even }"
											[formGroupName]="i"
											style="background: #ffffff"
										>
											<td width="1%" class="verticalalignmid">
												<mat-icon
													class="mnabricon red"
													matTooltip="{{ 'lang_delete' | language }}"
													(click)="remove_other_items(i)"
													>close</mat-icon
												>
											</td>
											<td
												style="padding: 5px; word-break: break-all"
												style="position: relative"
											>
												<input
													type="text"
													formControlName="qror_name_en"
													placeholder="{{ 'lang_title_english' | language }}"
													autocomplete="off"
													class="form-control almnabrformcontrol"
												/>
												<mat-error
													*ngIf="
														submitted &&
														others_fields.controls[i].get('qror_name_en').errors
															?.required
													"
												>
													{{ "lang_field_required" | language }}
												</mat-error>
											</td>
											<td
												style="padding: 5px; word-break: break-all"
												style="position: relative"
											>
												<input
													type="text"
													formControlName="qror_name_ar"
													placeholder="{{ 'lang_title_arabic' | language }}"
													autocomplete="off"
													class="form-control almnabrformcontrol"
												/>
												<mat-error
													*ngIf="
														submitted &&
														others_fields.controls[i].get('qror_name_ar').errors
															?.required
													"
												>
													{{ "lang_field_required" | language }}
												</mat-error>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</fieldset>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!markstatus">
						<button
							type="button"
							class="albutton mnabrbutton mat-accent"
							mat-button
							(click)="enable_field('mark')"
						>
							<i class="fa fa-plus"></i> {{ "lang_need_mark" | language }}
						</button>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!reviewstatus">
						<button
							type="button"
							class="albutton mnabrbutton mat-accent"
							mat-button
							(click)="enable_field('review')"
						>
							<i class="fa fa-plus"></i> {{ "lang_need_review" | language }}
						</button>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!viewerstatus">
						<button
							type="button"
							class="albutton mnabrbutton mat-accent"
							mat-button
							(click)="enable_field('viewer')"
						>
							<i class="fa fa-plus"></i> {{ "lang_need_viewer" | language }}
						</button>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!attachstatus">
						<button
							type="button"
							class="albutton mnabrbutton mat-accent"
							mat-button
							(click)="enable_field('attachment')"
						>
							<i class="fa fa-plus"></i> {{ "lang_need_attachment" | language }}
						</button>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-12 mb-2" *ngIf="!othersstatus">
						<button
							type="button"
							class="albutton mnabrbutton mat-accent"
							mat-button
							(click)="enable_field('others')"
						>
							<i class="fa fa-plus"></i> {{ "lang_others" | language }}
						</button>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-12">
						<fieldset class="cardback fieldset">
							<legend>{{ "lang_signature_stamp" | language }}</legend>
							<div class="row">
								<div class="col-sm-6 col-md-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_human_resources_job_title_iqama" | language }} A
										</label>
										<input
											type="text"
											formControlName="signature[A][title]"
											placeholder="{{
												'lang_human_resources_job_title_iqama' | language
											}} A"
											class="form-control almnabrformcontrol"
										/>
										<mat-error
											*ngIf="
												submitted &&
												form.controls['signature[A][title]'].errors?.required
											"
										>
											{{ "lang_field_required" | language }}
										</mat-error>
									</div>
								</div>
								<div class="col-sm-6 col-md-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_human_resources_job_title_iqama" | language }} B
										</label>
										<input
											type="text"
											formControlName="signature[B][title]"
											placeholder="{{
												'lang_human_resources_job_title_iqama' | language
											}} B"
											class="form-control almnabrformcontrol"
										/>
										<mat-error
											*ngIf="
												submitted &&
												form.controls['signature[B][title]'].errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-sm-6 col-md-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_human_resources_job_title_iqama" | language }} C
										</label>
										<input
											type="text"
											formControlName="signature[C][title]"
											placeholder="{{
												'lang_human_resources_job_title_iqama' | language
											}} C"
											class="form-control almnabrformcontrol"
										/>
										<mat-error
											*ngIf="
												submitted &&
												form.controls['signature[C][title]'].errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-sm-6 col-md-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_human_resources_employee_name" | language }} A
										</label>
										<ng-select
											[items]="users"
											[multiple]="false"
											bindLabel="label"
											bindValue="value"
											clearAllText="Clear"
											[searchFn]="ds.customSearchFn"
											appendTo="body"
											formControlName="signature[A][user_id]"
											placeholder="{{
												'lang_human_resources_employee_name' | language
											}}"
											(search)="search_users($event)"
											(focus)="users = []"
										>
										</ng-select>
										<mat-error
											*ngIf="
												submitted &&
												form.controls['signature[A][user_id]'].errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-sm-6 col-md-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_human_resources_employee_name" | language }} B
										</label>
										<ng-select
											[items]="users"
											[multiple]="false"
											bindLabel="label"
											bindValue="value"
											clearAllText="Clear"
											[searchFn]="ds.customSearchFn"
											appendTo="body"
											formControlName="signature[B][user_id]"
											placeholder="{{
												'lang_human_resources_employee_name' | language
											}}"
											(search)="search_users($event)"
											(focus)="users = []"
										>
										</ng-select>
										<mat-error
											*ngIf="
												submitted &&
												form.controls['signature[B][user_id]'].errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-sm-6 col-md-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_human_resources_employee_name" | language }} C
										</label>
										<ng-select
											[items]="users"
											[multiple]="false"
											bindLabel="label"
											bindValue="value"
											clearAllText="Clear"
											[searchFn]="ds.customSearchFn"
											appendTo="body"
											formControlName="signature[C][user_id]"
											placeholder="{{
												'lang_human_resources_employee_name' | language
											}}"
											(search)="search_users($event)"
											(focus)="users = []"
										>
										</ng-select>
										<mat-error
											*ngIf="
												submitted &&
												form.controls['signature[C][user_id]'].errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
				<div
					class="row mb-2"
					*ngIf="markstatus || reviewstatus || viewerstatus"
				>
					<div class="col-md-4 col-xs-12 col-sm-4 mb-2">
						<fieldset class="cardback fieldset" *ngIf="markstatus">
							<input type="hidden" formControlName="needMark" />
							<legend>
								{{ "lang_markers" | language }}
								<span class="red"
									>*
									<mat-icon
										class="red mnabricon red"
										matTooltip="{{ 'lang_close' | language }}"
										(click)="disable_field('mark')"
										>close</mat-icon
									>
								</span>
							</legend>
							<ng-select
								[items]="users"
								[multiple]="true"
								bindLabel="label"
								bindValue="value"
								clearAllText="Clear"
								[searchFn]="ds.customSearchFn"
								appendTo="body"
								formControlName="marks"
								placeholder="{{ 'lang_markers' | language }}"
								(search)="search_users($event)"
								(focus)="users = []"
							>
							</ng-select>
							<mat-error
								*ngIf="submitted && form.controls['marks'].errors?.required"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</fieldset>
					</div>
					<div class="col-md-4 col-xs-12 col-sm-4 mb-2">
						<fieldset class="cardback fieldset" *ngIf="reviewstatus">
							<input type="hidden" formControlName="needReview" />
							<legend>
								{{ "lang_reviewers" | language }}
								<span class="red"
									>*
									<mat-icon
										class="red mnabricon red"
										matTooltip="{{ 'lang_close' | language }}"
										(click)="disable_field('review')"
										>close</mat-icon
									>
								</span>
							</legend>
							<ng-select
								[items]="users"
								[multiple]="true"
								bindLabel="label"
								bindValue="value"
								clearAllText="Clear"
								[searchFn]="ds.customSearchFn"
								appendTo="body"
								formControlName="reviews"
								placeholder="{{ 'lang_reviewers' | language }}"
								(search)="search_users($event)"
								(focus)="users = []"
							>
							</ng-select>
							<mat-error
								*ngIf="submitted && form.controls['reviews'].errors?.required"
							>
								{{ "lang_field_required" | language }}</mat-error
							>
						</fieldset>
					</div>
					<div class="col-md-4 col-xs-12 col-sm-4 mb-2">
						<fieldset class="cardback fieldset" *ngIf="viewerstatus">
							<input type="hidden" formControlName="needViewers" />
							<legend>
								{{ "lang_viewers" | language }}
								<span class="red"
									>*
									<mat-icon
										class="red mnabricon"
										matTooltip="{{ 'lang_close' | language }}"
										(click)="disable_field('viewer')"
										>close</mat-icon
									></span
								>
							</legend>
							<ng-select
								[items]="users"
								[multiple]="true"
								bindLabel="label"
								bindValue="value"
								clearAllText="Clear"
								[searchFn]="ds.customSearchFn"
								appendTo="body"
								formControlName="viewers"
								placeholder="{{ 'lang_viewers' | language }}"
								(search)="search_users($event)"
								(focus)="users = []"
							>
							</ng-select>
							<mat-error
								*ngIf="submitted && form.controls['viewers'].errors?.required"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</fieldset>
					</div>
				</div>
				<input type="hidden" formControlName="needAttach" />
				<div class="row mb-2" *ngIf="attachstatus">
					<div class="col-md-12 col-sm-12 col-xs-12 mb-2">
						<fieldset class="cardback fieldset">
							<legend>
								{{ "lang_attachments" | language }}
								<span class="red"
									>*
									<mat-icon
										class="red mnabricon"
										matTooltip="{{ 'lang_close' | language }}"
										(click)="disable_field('attachment')"
										>close</mat-icon
									>
								</span>
								<mat-icon
									class="mnabricon"
									matTooltip="{{ 'lang_add' | language }}"
									(click)="addfiles()"
									>add_box</mat-icon
								>
							</legend>
							<div class="table-responsive customResponsive">
								<table
									class="table CustomTable"
									width="100%"
									formArrayName="attachments"
								>
									<thead>
										<tr>
											<th style="padding: 5px; word-break: break-all">#</th>
											<th style="padding: 5px; word-break: break-all">
												{{ "lang_title" | language }}
											</th>
											<th style="padding: 5px; word-break: break-all">
												{{ "lang_file" | language }}
											</th>
											<th style="padding: 5px; word-break: break-all">
												{{ "lang_attach_with_pdf" | language }}
											</th>
											<th style="padding: 5px; word-break: break-all">
												{{ "lang_official_paper" | language }}
											</th>
											<th style="padding: 5px; word-break: break-all">
												{{ "lang_action" | language }}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="
												let file of filesFormGroup.controls;
												let i = index;
												let odd = odd;
												let even = even
											"
											[formGroupName]="i"
										>
											<td
												class="verticalalignmid"
												style="padding: 5px; word-break: break-all"
											>
												{{ i + 1 }}
											</td>
											<td
												style="
													padding: 5px;
													word-break: break-all;
													position: relative;
												"
											>
												<input
													type="text"
													formControlName="attach_title"
													placeholder="{{ 'lang_description' | language }}"
													autocomplete="off"
													class="form-control almnabrformcontrol"
												/>
												<mat-error
													*ngIf="
														submitted &&
														this.attachments.controls[i].get('attach_title')
															.errors?.required
													"
													>{{ "lang_field_required" | language }}</mat-error
												>
											</td>
											<td
												class="verticalalignmid"
												style="
													padding: 5px;
													word-break: break-all;
													position: relative;
												"
											>
												<input
													style="display: block"
													name="file"
													type="file"
													required
													onclick="this.value = null"
													(change)="UploadFile($event, i)"
													class="nghide width100"
												/>
												<label
													id="uploadText{{ i }}"
													class="albutton mat-accent"
													mat-button
													><i class="fa fa-upload"></i>
												</label>
												<mat-error
													*ngIf="
														submitted &&
														this.attachments.controls[i].get('file').errors
															?.required
													"
													>{{ "lang_field_required" | language }}</mat-error
												>
											</td>
											<td
												class="verticalalignmid"
												style="padding: 5px; word-break: break-all"
											>
												<mat-checkbox
													formControlName="attach_with_the_document"
													class="checkbox-radio-button"
												></mat-checkbox>
											</td>
											<td
												class="verticalalignmid"
												style="padding: 5px; word-break: break-all"
											>
												<mat-checkbox
													formControlName="print_official_paper"
													class="checkbox-radio-button"
												></mat-checkbox>
											</td>
											<td class="verticalalignmid">
												<mat-icon
													class="red mnabricon"
													matTooltip="{{ 'lang_delete' | language }}"
													(click)="removefiles(i)"
													>close</mat-icon
												>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-2 col-md-3 col-sm-3 col-12 mb-2">
				<button
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
			<div class="col-lg-2 col-md-3 col-sm-3 col-12 mb-2">
				<button
					type="button"
					mat-button
					(click)="quotEvent.emit()"
					class="albutton mnabrbutton mat-accent"
				>
					{{ "lang_go_to_project_view" | language }}
				</button>
			</div>
			<div class="col-lg-6 col-md-3 col-sm-3 col-12 mb-2"></div>
			<div class="col-lg-2 col-md-3 col-sm-3 col-12 mb-2">
				<button
					type="submit"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_save" | language }}
				</button>
			</div>
		</div>
	</section>
</form>
