import { Component, Inject, OnInit } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { almnabrAttachment } from "@shared/validators/almnabr-attachment";
import * as moment from "moment";
import { UserActiveTasksComponent } from "../../user-active-tasks/user-active-tasks.component";
@Component({
	selector: "app-add-update-task",
	templateUrl: "./add-update-task.component.html",
	styleUrls: ["./add-update-task.component.scss"],
})
export class AddUpdateTaskComponent implements OnInit {
	user_list = [];
	tasks_list = [];
	priority_list = [];
	status_done_list = [];
	type_work_list = [];
	shift_list = [];
	group_list = [];
	group_type_list = [
		{
			label: this.lang.transform("lang_system_groups"),
			value: "1",
		},
		{
			label: this.lang.transform("lang_user_groups"),
			value: "2",
		},
	];

	lang_key = localStorage.getItem("lang_key") || "en";
	other_attachments: FormArray;
	filename: string = this.lang.transform("lang_select_files");
	task_form: FormGroup;
	ticketID;
	task_data;
	task_id;
	startDate;
	endDate;
	ticketTimework;
	validDays = false;
	task_status = "new";
	startDateMobiscroll = null;
	endDateMobiscroll = null;
	autoButtons = [
		{
			text: this.lang.transform("lang_save"),
			handler: "set",
		},
	];
	userCounts = [];
	showGroups = false;
	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<AddUpdateTaskComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private lang: LanguagePipe,
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		private spinner: PreloaderService,
		private dialog: MatDialog
	) {
		this.ticketID = data?.ticket_id;
		this.task_data = data?.task;
		this.task_id = data?.task?.task_id;
		this.startDate = data?.startDate;
		this.endDate = data?.endDate;
		this.ticketTimework = data?.ticketTimework;
		this.task_status = data?.task_status;
		this.tasks_list = data?.tasks;
		this.buildForm();
		this.getFormLists();
		if (this.task_data) {
			this.setEditData();
			this.tasks_list = data?.tasks?.filter(
				(el) => el?.task_id != this.task_id
			);
		} else {
			this.getGroups();
		}
	}

	ngOnInit(): void {
		moment.locale("en");
		let firstTime = 0;
		this.task_form.get("assigned_user").valueChanges.subscribe((res) => {
			if (res?.length && firstTime) {
				this.getUserTasks();
			}
			firstTime++;
		});
		this.task_form.get("end_date").valueChanges.subscribe((res) => {
			if (res?.length && firstTime) {
				this.getUserTasks();
			}
			firstTime++;
		});
	}

	getFormLists() {
		this.ds.post("tasks/get_add_task", {}).subscribe(
			(res) => {
				if (res?.status) {
					this.priority_list = res?.data?.important;
					this.status_done_list = res?.data?.task_status_done;
					this.shift_list = res?.data?.shifting_type;
					this.type_work_list = res?.data?.type_work;
				} else {
					this.priority_list = [];
					this.status_done_list = [];
					this.shift_list = [];
					this.type_work_list = [];
				}
			},
			(err) => {
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	getGroups() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("type", this.task_form.get("group_type_id").value || "");
		this.ds.post("tasks/get_group_by_type", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.group_list = res?.data;
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

	search_users(key) {
		this.user_list = [];
		if (key && key.term) {
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.user_list = res.list;
					}
				});
		}
	}

	buildForm() {
		this.task_form = this.fb.group({
			task_description: [null, Validators.required],
			assigned_user: [[]],
			title: ["", Validators.required],
			task_time: [null, Validators.required],
			start_date: [null, Validators.required],
			end_date: [{ value: null, disabled: true }, Validators.required],
			important_id: [null],
			related_id: [[]],
			task_status_done: [null],
			type_work: [null],
			shift_id: [null],
			group_type_id: ["1"],
			group_id: [null],
			attachments: this.fb.array([]),
		});
		this.other_attachments = this.task_form.get("attachments") as FormArray;
	}
	get filesFormGroup() {
		return this.task_form.get("attachments") as FormArray;
	}

	public files(value?): FormGroup {
		return this.fb.group({
			delete_icon: new FormControl(value?.attach_title || ""),
			view: new FormControl(value?.file || null),
			attach_title: new FormControl(
				{
					value: value ? value?.attach_title : "",
					disabled: value?.attach_title ? true : false,
				},
				[Validators.required]
			),
			file: new FormControl(value?.file || "", [
				Validators.required,
				almnabrAttachment(),
			]),
		});
	}
	public addfiles(value?) {
		if (this.other_attachments.length < 10) {
			this.other_attachments.push(this.files(value));
			this.other_attachments.setValidators(Validators.required);
			this.other_attachments.updateValueAndValidity();
		}
	}
	public removefiles(index) {
		if (this.other_attachments.length == 1) {
			this.other_attachments.removeAt(index);
			this.other_attachments.clearValidators();
			this.other_attachments.updateValueAndValidity();
		} else {
			this.other_attachments.removeAt(index);
		}
	}
	setEditData() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		this.ds.post("tasks/get_task_only", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.task_form
						.get("task_description")
						.setValue(res?.data?.task_detailes);
					this.task_form.get("title").setValue(res?.data?.title);
					this.task_form.get("task_time").setValue(res?.data?.task_time);
					this.task_form
						.get("start_date")
						.setValue(
							moment(new Date(res?.data?.start_date)).format(
								"YYYY/MM/DD HH:mm:ss"
							)
						);
					this.task_form
						.get("end_date")
						.setValue(
							moment(new Date(res?.data?.end_nearly)).format(
								"YYYY/MM/DD HH:mm:ss"
							)
						);
					this.task_form
						.get("related_id")
						.setValue(res?.data?.relateds_numbers?.map((el) => el?.task_id));
					this.task_form.get("important_id").setValue(res?.data?.important_id);
					this.task_form.get("type_work").setValue(res?.data?.type_work);
					this.task_form.get("shift_id").setValue(res?.data?.shift_id);
					this.task_form
						.get("group_type_id")
						.setValue(`${res?.data?.group_type_id}`);
					this.task_form.get("group_id").setValue(res?.data?.group_id);
					if (res?.data?.task_status_done == "0") {
						this.task_form.get("task_status_done").setValue(null);
					} else {
						this.task_form
							.get("task_status_done")
							.setValue(res?.data?.task_status_done);
					}
					this.getTaskUsers();
					this.getGroups();
					this.taskTimeValidation();
				} else {
					this.spinner.hide();
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}

	getTaskUsers() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		this.ds.post("tasks/get_emp_in_task", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					res?.data.map((el) => {
						if (this.lang_key == "ar") {
							this.user_list.push({
								label: el?.firstname_arabic + " " + el?.lastname_arabic,
								value: el?.emp_id,
							});
						} else {
							this.user_list.push({
								label: el?.firstname_english + " " + el?.lastname_english,
								value: el?.emp_id,
							});
						}
					});
					this.task_form.get("assigned_user").setValue(
						res?.data?.map((el) => {
							if (this.lang_key == "ar") {
								return {
									label: el?.firstname_arabic + " " + el?.lastname_arabic,
									value: el?.emp_id,
								};
							} else {
								return {
									label: el?.firstname_english + " " + el?.lastname_english,
									value: el?.emp_id,
								};
							}
						})
					);
				} else {
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}
	checkValidation() {
		if (this.task_form.get("task_description").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_task_description")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.task_form.get("title").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_title")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.task_form.get("assigned_user").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_assigned_user")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.task_form.get("task_time").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_task_time")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.task_form.get("start_date").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_start_date")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.task_form.get("end_date").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_end_date")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else if (this.validDays == false) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("Task Time Must be during ticket time")}`,
				1500
			);
			return false;
		} else if (this.task_form.get("attachments").invalid) {
			this.sweetAlert.errorToast(
				`${this.lang.transform("lang_attachments")} ${this.lang.transform(
					"lang_field_required"
				)}`,
				1500
			);
			return false;
		} else {
			return true;
		}
	}
	addTask() {
		if (this.checkValidation()) {
			this.spinner.show();
			let formData = new FormData();
			if (this.task_data) {
				formData.append("task_id", this.task_id || "");
			}
			formData.append(
				"task_detailes",
				this.task_form.get("task_description").value || ""
			);
			formData.append("status", this.task_status || "");
			formData.append("ticket_id", this.ticketID || "");
			formData.append("title", this.task_form.get("title").value || "");
			formData.append("task_time", this.task_form.get("task_time").value || "");
			formData.append(
				"start_date",
				this.task_form.get("start_date").value || ""
			);
			formData.append("end_date", this.task_form.get("end_date").value || "");
			formData.append(
				"related_id",
				this.task_form.get("related_id").value?.toString() || ""
			);
			formData.append(
				"important_id",
				this.task_form.get("important_id").value || ""
			);
			formData.append(
				"task_status_done",
				this.task_form.get("task_status_done").value || ""
			);
			formData.append("type_work", this.task_form.get("type_work").value || "");
			formData.append("shift_id", this.task_form.get("shift_id").value || "");
			formData.append("group_id", this.task_form.get("group_id").value || "");
			formData.append(
				"users",
				this.task_form
					.get("assigned_user")
					?.value?.map((el) => el?.value)
					?.toString() || ""
			);

			this.filesFormGroup.value.forEach((v, k) => {
				formData.set("attachments[" + k + "][file]", v.file);
				formData.set(
					"attachments[" + k + "][attach_title]",
					this.other_attachments.controls[k].get("attach_title").value
				);
			});
			let url = "tasks/add_task";
			if (this.task_data) {
				url = "tasks/update_task";
			}
			this.ds.post(url, formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.dialogRef.close();
						this.sweetAlert.successToast(
							this.lang.transform("lang_data_sent_successfully"),
							2000
						);
					} else {
						this.sweetAlert.errorToast(this.lang.transform(res?.error), 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.dialogRef.close();
					this.sweetAlert.errorMessage(
						this.lang.transform("lang_internal_server_error")
					);
				}
			);
		}
	}

	isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	taskTimeValidation() {
		this.validDays = false;
		if (this.task_form.get("task_time").value && this.ticketTimework) {
			if (+this.task_form.get("task_time").value <= +this.ticketTimework) {
				this.validDays = true;
			} else {
				this.validDays = false;
			}
		} else {
			this.validDays = false;
		}
	}

	calcEndDate(e, type) {
		if (type == "datechange") {
			let numOfDays = this.task_form.get("task_time").value,
				startDateInForm = this.task_form.get("start_date").value;
			if (startDateInForm && numOfDays) {
				const endDateFormatted = moment(
					this.addDays(startDateInForm, numOfDays)
				).format("YYYY/MM/DD HH:mm:ss");
				this.task_form.get("end_date").setValue(endDateFormatted);
			}
		} else if (type == "dayschange") {
			let numOfDays = this.task_form.get("task_time").value,
				startDate = this.task_form.get("start_date").value;
			if (e?.target?.value && numOfDays && startDate) {
				const endDateFormatted = moment(
					this.addDays(startDate, numOfDays)
				).format("YYYY/MM/DD HH:mm:ss");
				this.task_form.get("end_date").setValue(endDateFormatted);
			}
		}
	}
	addDays(date, days) {
		let result = new Date(date);
		result.setDate(result.getDate() + Number(days) - 1);
		return result;
	}
	custmizeDate(type) {
		if (type == "start_date") {
			const start_data_formatted = moment(this.startDateMobiscroll).format(
				"YYYY/MM/DD HH:mm:ss"
			);
			this.task_form.get("start_date").setValue(start_data_formatted);
			if (this.task_form.get("type_work")?.value == "1") {
				this.getShiftEndDate();
			} else {
				this.calcEndDate(null, "datechange");
			}
		}
	}
	onChangeGroup() {
		if (this.task_form.get("group_id").value) {
			this.spinner.show();
			let formData = new FormData();
			let url = "tasks/get_group";
			if (this.task_form.get("group_type_id").value == "2") {
				url = "tasks/get_for_user_group";
			} else {
				url = "tasks/get_group";
			}
			formData.append("group_id", this.task_form.get("group_id").value || "");
			this.ds.post(url, formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.task_form.get("assigned_user").setValue(
							res?.data?.users?.map((el) => {
								if (this.lang_key == "ar") {
									return {
										label: el?.firstname_arabic + " " + el?.lastname_arabic,
										value: el?.user_id,
									};
								} else {
									return {
										label: el?.firstname_english + " " + el?.lastname_english,
										value: el?.user_id,
									};
								}
							})
						);
						this.user_list = res?.data?.users?.map((el) => {
							return {
								label:
									this.lang_key == "ar"
										? el?.firstname_arabic + " " + el?.lastname_arabic
										: el?.firstname_english + " " + el?.lastname_english,
								value: el?.user_id,
							};
						});
					} else {
						this.sweetAlert.errorToast(res?.error, 3000);
						this.task_form.get("group_id").setValue(null);
					}
				},
				(err) => {
					this.spinner.hide();
					this.task_form.get("group_id").setValue(null);
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
		}
	}

	onSelectUser(id) {
		const userId = id;
		this.spinner.show();
		let formData = new FormData();
		formData.append("emp_id", userId || "");
		formData.append("start_date", this.task_form.get("start_date").value || "");
		formData.append("end_date", this.task_form.get("end_date").value || "");
		this.ds.post("tasks/check_emp_is_tasks", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					const dialogRef = this.dialog.open(UserActiveTasksComponent, {
						width: "600px",
						data: {
							user_id: userId,
							usersTasks: res?.data,
						},
						autoFocus: false,
					});
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}

	getUserTasks() {
		let formData = new FormData();
		formData.append(
			"emp_id",
			this.task_form
				.get("assigned_user")
				.value?.map((el) => el?.value)
				?.toString() || ""
		);
		formData.append("start_date", this.task_form.get("start_date").value || "");
		formData.append("end_date", this.task_form.get("end_date").value || "");
		this.ds.post("tasks/get_count_emp_is_tasks", formData).subscribe(
			(res) => {
				if (res?.status) {
					this.userCounts = res?.data;
				} else {
					this.userCounts = [];
				}
			},
			(err) => {
				this.userCounts = [];
			}
		);
	}
	onChangeStartDate() {
		if (this.task_form.get("type_work")?.value == "1") {
			this.getShiftEndDate();
		} else {
			this.calcEndDate(null, "datechange");
		}
	}
	getShiftEndDate() {
		let formData = new FormData();
		formData.append("shift_id", this.task_form.get("shift_id").value || "");
		formData.append("start_date", this.task_form.get("start_date").value || "");
		this.spinner.show();
		this.ds.post("tasks/get_end_date_by_shift", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					console.log(res);
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
