import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-rstep1',
  templateUrl: './rstep1.component.html',
  styleUrls: ['./rstep1.component.scss']
})
export class Rstep1Component implements OnInit {
  @Input() signupForm: FormGroup;
  @Input() action;
  constructor(private cdRef: ChangeDetectorRef, public fb:FormBuilder ,public ds: DataService, public route:ActivatedRoute ,public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {
  }
  get f() {
    return this.signupForm.controls.loginDetails;
  }
  usernameExists(username){
    this.ds.getActionByNoToken([], 'signup/username_exists/' + username).subscribe(data => {
      if (data.status) {
        this.signupForm.controls.loginDetails.get('username').setValue('');
        this.ds.dialogf('', data.msg);
      }
    }, error => {
        this.signupForm.controls.loginDetails.get('username').setValue('');
        this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  rstep1Submitted() {
    this.signupForm.get("loginDetails").get("username").markAsTouched();
    this.signupForm.get("loginDetails").get("username").updateValueAndValidity();
    this.signupForm.get("loginDetails").get("password").markAsTouched();
    this.signupForm.get("loginDetails").get("password").updateValueAndValidity();
    this.signupForm.get("loginDetails").get("confirm_password").markAsTouched();
    this.signupForm.get("loginDetails").get("confirm_password").updateValueAndValidity();
  }
}
