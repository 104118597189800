<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>Request Details</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-12 col-sm-3 mb-3">
			<div
				class="card-item"
				style="background: linear-gradient(60deg, #97a1d7, #583399)"
			>
				<div class="item-text" style="width: 70%">
					<h5>Unit ID</h5>
					<h4>{{ dataList?.records[0]?.unit_id }}</h4>
				</div>
				<div class="item-icon">
					<i class="pi pi-thumbs-up"></i>
				</div>
			</div>
		</div>
		<div
			class="col-12 col-sm-3 mb-3"
			*ngIf="dataList?.records[0]?.request_status == 'OK'"
		>
			<div
				class="card-item"
				style="background: linear-gradient(60deg, #26dad2, #00c174)"
			>
				<div class="item-text" style="width: 70%">
					<h5>Status</h5>
					<h4>{{ dataList?.records[0]?.request_status }}</h4>
				</div>
				<div class="item-icon">
					<i class="pi pi-thumbs-up"></i>
				</div>
			</div>
		</div>
		<div
			class="col-12 col-sm-3 mb-3"
			*ngIf="dataList?.records[0]?.request_status == 'NOT_USED'"
		>
			<div
				class="card-item"
				style="background: linear-gradient(60deg, #26c6da, #00acc1)"
			>
				<div class="item-text" style="width: 70%">
					<h5>Status</h5>
					<h4>{{ dataList?.records[0]?.request_status }}</h4>
				</div>
				<div class="item-icon">
					<i class="pi pi-exclamation-circle"></i>
				</div>
			</div>
		</div>
		<div
			class="col-12 col-sm-3 mb-3"
			*ngIf="dataList?.records[0]?.request_status == 'NOT_OK'"
		>
			<div
				class="card-item"
				style="background: linear-gradient(60deg, #d7688d, #cd3535)"
			>
				<div class="item-text" style="width: 70%">
					<h5>Status</h5>
					<h4>{{ dataList?.records[0]?.request_status }}</h4>
				</div>
				<div class="item-icon">
					<i class="pi pi-times-circle"></i>
				</div>
			</div>
		</div>
	</div>
	<div
		class="table-responsive dropdownyes customResponsive tablefields"
		*ngIf="dataList"
	>
		<table border="1" class="table CustomTable">
			<thead>
				<tr>
					<th>#</th>
					<th>{{ "lang_request_no" | language }}</th>
					<th>{{ "lang_platform" | language }}</th>
					<th>{{ "lang_platform_code_system" | language }}</th>
					<th>{{ "lang_result" | language }}</th>
					<th>{{ "lang_platform_weight" | language }}</th>
					<th>{{ "lang_work_level" | language }}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="
						let item of dataList?.records;
						let i = index;
						let odd = odd;
						let even = even
					"
				>
					<td>{{ i + 1 }}</td>
					<td>{{ item?.transaction_request_id || "---" }}</td>
					<td>{{ item?.platform_subject || "---" }}</td>
					<td>{{ item?.platform_code_system || "---" }}</td>
					<td>{{ item?.result_code || "---" }}</td>
					<td>{{ item?.template_platform_weight || "---" }}</td>
					<td>{{ item?.work_level_title || "---" }}</td>
				</tr>
			</tbody>
		</table>
		<div
			class="col-12 mt-2"
			style="
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
			"
			*ngIf="dataList?.records?.length > 0"
		>
			<p>
				{{ "lang_total" | language }}:
				{{ dataList?.pages?.total_records }}
			</p>
			<ngb-pagination
				[collectionSize]="dataList?.pages?.total_records"
				[rotate]="true"
				[maxSize]="3"
				[boundaryLinks]="true"
				[(page)]="page_no"
				[pageSize]="page_size"
				(pageChange)="getList()"
			></ngb-pagination>
			<mat-select
				class="customselect"
				[(ngModel)]="page_size"
				(selectionChange)="getList()"
				style="width: 120px"
			>
				<mat-option [value]="10"
					>10 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option [value]="50"
					>50 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option [value]="100"
					>100 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option [value]="200"
					>200 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option [value]="400"
					>400 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option [value]="500"
					>500 {{ "lang_per_page" | language }}</mat-option
				>
			</mat-select>
		</div>
	</div>
	<div class="mt-3 mb-3 text-center" *ngIf="!dataList">
		<p style="font-size: 18px">{{ dataList_msg }}</p>
	</div>
</mat-dialog-content>
