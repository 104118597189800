<div class="row w-100 justify-content-between mx-1">
    <h5>
      {{'lang_rejected_reason'|language}}
    </h5>
    <mat-icon style="color: brown; position: relative;" (click)="cancel()">cancel_presentation</mat-icon>

    <div class="col-md-12 col-sm-12 col-xs-12 mb-10" [formGroup]="formGroup">
        <div class="form-group">
            <label> {{'lang_reason' | language}} </label>
              
            <textarea class="form-control"  formControlName="reason" placeholder="{{'lang_reason' | language}}" 
            id="exampleFormControlTextarea1" rows="5"></textarea>
          
                
           
        </div>
    </div>
</div>
 

  <mat-dialog-actions>
    <div class="d-flex justify-content-between" style="width: 100%">
      <div style="width: 100px">
        <button type="button" class="albutton" (click)="cancel()">
          {{ "lang_cancel" | language }}
        </button>
      </div>
  
      <div style="width: 100px">
        <button
          mat-button
          class="albutton mnabrbutton mat-accent"
          (click)="sendFilterForm()"
        >
          {{ "lang_save" | language }}
        </button>
      </div>
    </div>
  </mat-dialog-actions>