<div mat-dialog-title class="d-flex justify-content-between">
	<h4 *ngIf="!edit_mode">{{ "lang_add" | language }}</h4>
	<h4 *ngIf="edit_mode">{{ "lang_edit" | language }}</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="constantForm">
		<div class="p-2">
			<div class="row">
				<div class="col-12 mb-2">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_name" | language }}</mat-label>
						<input
							matInput
							placeholder="{{ 'lang_name' | language }}"
							formControlName="constant_name"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 mb-2">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{
							"lang_human_resources_nameen" | language
						}}</mat-label>
						<input
							matInput
							placeholder="{{ 'lang_human_resources_nameen' | language }}"
							formControlName="constant_name_en"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 mb-2">
					<ng-select
						[items]="constant_type_list"
						[multiple]="false"
						appendTo="body"
						bindLabel="name"
						bindValue="id"
						[searchFn]="ds.customSearchFn"
						clearAllText="Clear"
						placeholder="{{'lang_content_type'|language}}"
						formControlName="contant_type"
						[notFoundText]="'lang_no_data' | language"
					>
					</ng-select>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-0 col-sm-9"></div>
	<div class="col-12 col-sm-3">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onSubmit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
