import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import * as Countries from "../../../../../shared/utils/countries_en";

@Component({
	selector: "app-filter-employee-card",
	templateUrl: "./filter-card.component.html",
	styleUrls: ["./filter-card.component.scss"],
})
export class FilterEmployeeCardComponent implements OnInit {
	@Output() filterParams = new EventEmitter<any>();
	@Input() projects: any = [];
	@Input() branches: any = [];
	@Input() metaData: any = [];
	filterForm: FormGroup = this.fb.group({
		nationality_type: "all",
		project_id: null,
		branch_id: null,
		national_id: null,
		employee_id: null,
		emp_user_id: null,
		employee_number: null,
		employee_name: null,
		nationality: null,
		position: null,
		iqama_expiry_date_from: null,
		iqama_expiry_date_to: null,
		passport_expiry_date_from: null,
		passport_expiry_date_to: null,
		membership_expiry_date_to: null,
		membership_expiry_date_from: null,
		contract_expiry_date_from: null,
		contract_expiry_date_to: null,
		employee_status: null,
	});
	lang_key = localStorage.getItem("lang_key")
		? localStorage.getItem("lang_key")
		: "en";

	countries = Countries.countries;
	formFromLocalStorage: any;
	nationality_type = [
		{
			label: this.lang.transform("lang_all"),
			value: "all",
		},
		{
			label: this.lang.transform("lang_saudi"),
			value: "saudi",
		},
		{
			label: this.lang.transform("lang_non_saudi"),
			value: "nonsaudi",
		},
	];

	constructor(private fb: FormBuilder, private lang: LanguagePipe) {}

	ngOnInit(): void {
		this.getFilterParams();
	}

	buildForm() {
		this.filterForm = this.fb.group({
			nationality_type: this.formFromLocalStorage?.nationality_type,
			project_id: [this.formFromLocalStorage?.project_id],
			branch_id: [this.formFromLocalStorage?.branch_id],
			national_id: this.formFromLocalStorage?.national_id,
			employee_name: this.formFromLocalStorage?.employee_name,
			employee_id: this.formFromLocalStorage?.employee_id,
			emp_user_id: this.formFromLocalStorage?.emp_user_id,
			employee_number: this.formFromLocalStorage?.employee_number,

			nationality: [this.formFromLocalStorage?.nationality],
			position: [this.formFromLocalStorage?.position],
			iqama_expiry_date_from: this.formFromLocalStorage?.iqama_expiry_date_from,
			iqama_expiry_date_to: this.formFromLocalStorage?.iqama_expiry_date_to,
			passport_expiry_date_from:
				this.formFromLocalStorage?.passport_expiry_date_from,
			passport_expiry_date_to:
				this.formFromLocalStorage?.passport_expiry_date_to,
			membership_expiry_date_to:
				this.formFromLocalStorage?.membership_expiry_date_to,
			membership_expiry_date_from:
				this.formFromLocalStorage?.membership_expiry_date_from,
			contract_expiry_date_from:
				this.formFromLocalStorage?.contract_expiry_date_from,
			contract_expiry_date_to:
				this.formFromLocalStorage?.contract_expiry_date_to,
			employee_status: [this.formFromLocalStorage?.employee_status],
		});
	}

	changeVal(e) {}
	getFilterParams() {
		this.formFromLocalStorage = localStorage.getItem("employee_filterParams")
			? JSON.parse(localStorage.getItem("employee_filterParams"))
			: localStorage.setItem(
					"employee_filterParams",
					JSON.stringify(this.filterForm?.value)
			  );
		this.buildForm();
	}

	sendFilterForm() {
		this.filterParams.emit(this.filterForm?.value);
		localStorage.setItem(
			"employee_filterParams",
			JSON.stringify(this.filterForm?.value)
		);
	}

	resetFilterForm() {
		this.filterForm.reset();
		/*    this.filterForm.get('version').setValue('final_completed_versions'); */
		this.filterParams.emit(this.filterForm?.value);
		localStorage.setItem(
			"employee_filterParams",
			JSON.stringify(this.filterForm?.value)
		);
	}

	public isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
}
