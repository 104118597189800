<div mat-dialog-title class="d-flex justify-content-between">
	<div class="ticket-title">
		<div class="tt-up">
			<i class="pi pi-credit-card"></i>
			<h3>{{ ticket?.ticket_title }}</h3>
		</div>
		<div class="tt-down">
			<p>
				{{ "lang_ticket_number" | language }}
				<span>{{ ticket?.ticket_no }}</span>
			</p>
		</div>
	</div>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<div class="p-2">
		<div class="row mb-3">
			<div class="col-12 col-md-6 mb-3">
				<!-- start ticket-Description -->
				<div class="ticket-description">
					<div class="title">
						<i class="pi pi-align-left"></i>
						<h4>{{ "lang_description" | language }}</h4>
					</div>
					<div class="body">
						<p>{{ ticket?.ticket_description }}</p>
					</div>
				</div>
				<!-- end ticket-Description -->
			</div>
			<div class="col-12 col-md-6 mb-3">
				<!-- start ticket-Notes -->
				<div class="ticket-notes">
					<div class="title">
						<i class="pi pi-book"></i>
						<h4>{{ "lang_note" | language }}</h4>
					</div>
					<div class="body">
						<p *ngIf="ticket?.note">{{ ticket?.note }}</p>
						<p *ngIf="!ticket?.note">-- -- --</p>
					</div>
				</div>
				<!-- end ticket-Notes -->
			</div>
			<div class="col-12 col-md-6 mb-3">
				<!-- start ticket-Notes -->
				<div class="ticket-notes">
					<div class="title">
						<i class="pi pi-calendar-plus"></i>
						<h4>{{ "lang_start_date" | language }}</h4>
					</div>
					<div class="body">
						<p *ngIf="ticket?.start_date">{{ ticket?.start_date }}</p>
						<p *ngIf="!ticket?.start_date">-- -- --</p>
					</div>
				</div>
				<!-- end ticket-Notes -->
			</div>
			<div class="col-12 col-md-6 mb-3">
				<!-- start ticket-Notes -->
				<div class="ticket-notes">
					<div class="title">
						<i class="pi pi-calendar-minus"></i>
						<h4>{{ "lang_end_date" | language }}</h4>
					</div>
					<div class="body">
						<p *ngIf="ticket?.end_date">{{ ticket?.end_date }}</p>
						<p *ngIf="!ticket?.end_date">-- -- --</p>
					</div>
				</div>
				<!-- end ticket-Notes -->
			</div>
			<div class="col-12 col-md-6 mb-3">
				<!-- start ticket-Notes -->
				<div class="ticket-notes">
					<div class="title">
						<i class="pi pi-calendar-times"></i>
						<h4>{{ "lang_end_date_nearly" | language }}</h4>
					</div>
					<div class="body">
						<p *ngIf="ticket?.end_date_nearly">{{ ticket?.end_date_nearly }}</p>
						<p *ngIf="!ticket?.end_date_nearly">-- -- --</p>
					</div>
				</div>
				<!-- end ticket-Notes -->
			</div>
		</div>
	</div>
</mat-dialog-content>
