import { Component, Inject, Input, OnInit } from "@angular/core";
import {
	FormGroup,
	FormControl,
	Validators,
	FormBuilder,
} from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-add-reviewers",
	templateUrl: "./add-reviewers.component.html",
	styleUrls: ["./add-reviewers.component.scss"],
})
export class AddReviewersComponent implements OnInit {
	modalRefA: BsModalRef;
	form: FormGroup;
	form_request_id: any;
	request: any = [];
	allData: any;
	lang_key = localStorage.getItem("lang_key") || "en";

	groups: any = [];
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		private snackBar: MatSnackBar,
		public lang: LanguagePipe,
		private fb: FormBuilder,
		private _sweetAlertService: SweetAlertService,
		public spinner: PreloaderService,
		public alert: AlertService,
		public router: Router,
		private dialogRef: MatDialogRef<AddReviewersComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.buildform();
	}

	buildform() {
		this.form = this.fb.group({
			groups: [[], [Validators.required]],
		});
	}

	ngOnInit(): void {}

	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 4000,
			panelClass: [className],
		});
	}

	close() {
		this.dialogRef.close({ saved: false });
	}

	submitForm() {
		if (this.form?.status == "VALID") {
			this.spinner.show();
			let formData = new FormData();

			for (let index = 0; index < this.form.value.groups.length; index++) {
				const element = this.form.value.groups[index];
				formData.append(`users_ids[${index + 1}]`, element);
			}

			this.ds
				.post(`form/FORM_SAL/store_employees_as_reviewers`, formData)
				.subscribe(
					(data) => {
						if (data.status) {
							this.dialogRef.close({ saved: true });
							this._sweetAlertService.successToast(data.msg, 2000);
							this.spinner.hide();
						} else {
							this.spinner.hide();
							this._sweetAlertService.errorToast(data.error, 2000);
						}
					},
					(error) => {
						this._sweetAlertService.errorMessage(error?.error?.error);
						this.spinner.hide();
					}
				);
		} else {
			this.showMsg("Please fill all fields first", "ok", "error-snackbar");
		}
	}

	users = [];

	public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append("search", key.term || "");
		param.append("lang_key", this.lang_key);
		param.append("user_type_id", "1");
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.users = res.list;
				}
			});
	}

	closeModal() {
		this.dialogRef.close({ formValue: null, days: null });
	}
}
