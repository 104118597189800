import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { TemplateDialogComponent } from "app/routes/projects/services/supervision/supervision-my-transctions/template-dialog/template-dialog.component";
import { ZoneDialogComponent } from "app/routes/projects/services/supervision/supervision-my-transctions/zone-dialog/zone-dialog.component";
import { Subscription } from "rxjs";
import { CreateProjectTransactionComponent } from "../create-project-transaction/create-project-transaction.component";

@Component({
  selector: "app-user-transactions",
  templateUrl: "./user-transactions.component.html",
  styleUrls: ["./user-transactions.component.scss"],
})
export class UserTransactionsComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key")
    ? localStorage.getItem("lang_key")
    : "en";
  searchKey = "";
  showFilter = false;
  showSort = false;
  isMob = false;
  TransactionsData = [];
  pages: any = "pages";
  page_no = 1;
  page_size = 10;
  collection_size = 0;
  paginationMaxSize = 3;
  showExel = false;
  filters_params = {
    transaction_start_date: null,
    transaction_end_date: null,
    form_request_id: null,
    template: null,
    group_type: null,
    group1: null,
    group2: null,
    platform_code_system: null,
    zone: null,
    block: null,
    cluster: null,
    phase_short_code: null,
    unit_id: null,
    level_key: null,
    barcode: null,
    result_code: null,
    version: "final_completed_versions",
  };
  sort_params = {
    form_request_id: null,
    template_id: null,
    platform_code_system: null,
    zone: null,
    block: null,
    cluster: null,
    barcode: null,
  };
  levels_arr = [];
  filters_saved: any;
  sort_saved: any;
  template = [];
  group_type = [];
  group1 = [];
  group2 = [];
  zone = [];
  block = [];
  cluster = [];
  filterList = [];
  filter_name: any;
  selectedFilter: any;
  filterForm: FormGroup;
  result_code_arr = [
    {
      label: "A",
      value: "A",
    },
    {
      label: "B",
      value: "B",
    },
    {
      label: "C",
      value: "C",
    },
    {
      label: "D",
      value: "D",
    },
    {
      label: "E",
      value: "E",
    },
    {
      label: "OPEN",
      value: "OPEN",
    },
    {
      label: "CLOSE",
      value: "CLOSE",
    },
  ];

  status_arr = [
    {
      label: `${this.lang.transform("lang_completed")}`,
      value: "final_completed_versions",
    },
    {
      label: `${this.lang.transform("lang_all_completed_versions")}`,
      value: "all_completed_versions",
    },
    {
      label: `${this.lang.transform("lang_pending")}`,
      value: "all_pending_versions",
    },
    {
      label: `${this.lang.transform("lang_duplicated_versions")}`,
      value: "all_duplicated_versions",
    },
  ];
  //sort
  sortForm: FormGroup = this.fb.group({
    form_request_id: null,
    template_id: null,
    platform_code_system: null,
    zone: null,
    block: null,
    cluster: null,
    barcode: null,
  });
  form_request_id_arr = [
    {
      label: "ASC",
      value: "ASC",
    },
    {
      label: "DESC",
      value: "DESC",
    },
  ];
  template_id_arr = [
    {
      label: "ASC",
      value: "ASC",
    },
    {
      label: "DESC",
      value: "DESC",
    },
  ];
  platform_code_system_arr = [
    {
      label: "ASC",
      value: "ASC",
    },
    {
      label: "DESC",
      value: "DESC",
    },
  ];
  zone_arr = [
    {
      label: "ASC",
      value: "ASC",
    },
    {
      label: "DESC",
      value: "DESC",
    },
  ];
  block_arr = [
    {
      label: "ASC",
      value: "ASC",
    },
    {
      label: "DESC",
      value: "DESC",
    },
  ];
  cluster_arr = [
    {
      label: "ASC",
      value: "ASC",
    },
    {
      label: "DESC",
      value: "DESC",
    },
  ];
  barcode_arr = [
    {
      label: "ASC",
      value: "ASC",
    },
    {
      label: "DESC",
      value: "DESC",
    },
  ];
  isloading = false;
  apisSubscription = new Subscription();
  workareaList = [];
  selected_projects_workarea =
    localStorage.getItem("selected_projects_workarea") || null;
  projects_profile_id = localStorage.getItem("projects_profile_id") || null;
  projects_supervision_id =
    localStorage.getItem("projects_supervision_id") || null;
  constructor(
    private dialog: MatDialog,
    public ds: DataService,
    private spinner: PreloaderService,
    private fb: FormBuilder,
    private lang: LanguagePipe,
    private sweetAlert: SweetAlertService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth < 600) {
      this.isMob = true;
      this.paginationMaxSize = 1;
    } else {
      this.isMob = false;
    }
    this.filterForm = this.fb.group({
      projects_workarea: null,
      transaction_start_date: null,
      transaction_end_date: null,
      form_request_id: null,
      platform_code_system: null,
      zone: null,
      block: null,
      cluster: null,
      phase_short_code: null,
      unit_id: null,
      level_key: null,
      barcode: null,
      result_code: null,
      version: "final_completed_versions",
    });
    this.getWorkLevels();
    this.getWorkAreaList();
    if (this.selected_projects_workarea) {
      this.getTransactionsData(this.filters_params, this.sort_params, false);
    }
  }

  getWorkLevels() {
    let param = new FormData();
    param.append("lang_key", this.lang_key);
    this.apisSubscription.add(
      this.ds
        .getActionByUrl(this.ds.formData2string(param), "lpworklevel")
        .subscribe((res) => {
          if (res?.status) {
            this.levels_arr = res?.records;
          } else {
            this.levels_arr = [];
          }
        })
    );
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.showSort = false;
  }

  toggleSort() {
    this.showSort = !this.showSort;
    this.showFilter = false;
  }

  load_by_page_size(e) {
    if (e && e.value) {
      this.getTransactionsData(this.filters_params, this.sort_params, false);
    }
  }

  filterParams(e) {
    this.filters_params = e;
    this.getTransactionsData(this.filters_params, this.sort_params, false);
  }
  sortParams(e) {
    this.sort_params = e;
    this.getTransactionsData(
      {
        ...this.filters_params,
        transaction_start_date: this.convertDateToString(
          this.filterForm.get("transaction_start_date").value
        ),
        transaction_end_date: this.convertDateToString(
          this.filterForm.get("transaction_end_date").value
        ),
      },
      this.sort_params,
      false
    );
  }

  public onPageChange(): void {
    this.getTransactionsData(this.filters_params, this.sort_params, false);
  }

  getTransactionsData(filters: any, sort: any, isExcel: boolean) {
    this.isloading = true;
    let formData = new FormData();
    formData.append(
      "filter[projects_work_area_id]",
      this.selected_projects_workarea || ""
    );
    formData.append(
      "filter[template_id]",
      filters?.template?.map((el) => el?.id)?.toString() || ""
    );
    formData.append(
      "filter[group_type_id]",
      filters?.group_type?.map((el) => el?.id)?.toString() || ""
    );
    formData.append(
      "filter[group1_id]",
      filters?.group1?.map((el) => el?.id)?.toString() || ""
    );
    formData.append(
      "filter[group2_id]",
      filters?.group2?.map((el) => el?.id)?.toString() || ""
    );
    formData.append(
      "filter[zone_id]",
      filters?.zone?.map((el) => el?.id)?.toString() || ""
    );
    formData.append(
      "filter[block]",
      filters?.block?.map((el) => el?.id)?.toString() || ""
    );
    formData.append(
      "filter[cluster]",
      filters?.cluster?.map((el) => el?.id)?.toString() || ""
    );
    formData.append(
      "filter[transaction_start_date]",
      filters?.transaction_start_date || ""
    );
    formData.append(
      "filter[transaction_end_date]",
      filters?.transaction_end_date || ""
    );
    formData.append(
      "filter[transaction_request_id]",
      filters?.form_request_id || ""
    );
    formData.append(
      "filter[platform_code_system]",
      filters?.platform_code_system || ""
    );
    formData.append(
      "filter[phase_short_code]",
      filters?.phase_short_code || ""
    );
    formData.append("filter[unit_id]", filters?.unit_id || "");
    formData.append("filter[level_key]", filters?.level_key || "");
    formData.append("filter[barcode]", filters?.barcode || "");
    formData.append("filter[result_code]", filters?.result_code || "");
    formData.append("filter[version]", filters?.version || "");
    formData.append("sort_by[barcode]", sort?.barcode || "");
    formData.append(
      "sort_by[transaction_request_id]",
      sort?.form_request_id || ""
    );
    formData.append("sort_by[template_id]", sort?.template_id || "");
    formData.append("sort_by[zone]", sort?.zone || "");
    formData.append("sort_by[block]", sort?.block || "");
    formData.append("sort_by[cluster]", sort?.cluster || "");
    formData.append(
      "sort_by[platform_code_system]",
      sort?.platform_code_system || ""
    );
    if (isExcel) {
      this.pages = "excel";
    }
    this.apisSubscription.add(
      this.ds
        .postActionByUrl(
          formData,
          `pr/get_qtp_for_user/${this.page_no}/${this.page_size}`
        )
        .subscribe(
          (res) => {
            if (res?.status) {
              this.isloading = false;
              this.TransactionsData = res?.records;
              this.collection_size = res?.page?.total_records;
            } else {
              this.isloading = false;
              this.TransactionsData = [];
              this.collection_size = 0;
              this.page_no = 1;
            }
          },
          (err) => {
            this.isloading = false;
            this.TransactionsData = [];
            this.collection_size = 0;
            this.page_no = 1;
          }
        )
    );
  }

  sendFilterForm() {
    this.filterParams({
      ...this.filterForm?.value,
      transaction_start_date: this.convertDateToString(
        this.filterForm.get("transaction_start_date").value
      ),
      transaction_end_date: this.convertDateToString(
        this.filterForm.get("transaction_end_date").value
      ),
      template: this.template,
      group1: this.group1,
      group_type: this.group_type,
      group2: this.group2,
      zone: this.zone,
      block: this.block,
      cluster: this.cluster,
    });
  }

  resetFilterForm() {
    this.filterForm.reset();
    this.filterForm.get("version").setValue("final_completed_versions");
    this.template = [];
    this.group1 = [];
    this.group_type = [];
    this.group2 = [];
    this.zone = [];
    this.block = [];
    this.cluster = [];
    this.filter_name = null;
    this.selectedFilter = null;
    this.filterParams({
      ...this.filterForm?.value,
      template: this.template,
      group1: this.group1,
      group_type: this.group_type,
      group2: this.group2,
      zone: this.zone,
      block: this.block,
      cluster: this.cluster,
    });
  }

  openTemplateFilter() {
    const dialogRef = this.dialog.open(TemplateDialogComponent, {
      width: "100%",
      data: {
        projects_work_area_id: this.selected_projects_workarea,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let canAddTemplate = this.template
            ?.map((el) => el.value)
            ?.includes(result?.template?.value),
          canAddGroup1 = this.group1
            ?.map((el) => el.id)
            ?.includes(result?.group1?.id),
          canAddType = this.group_type
            ?.map((el) => el.id)
            ?.includes(result?.group_type?.id),
          canAddGroup2 = this.group2
            ?.map((el) => el.value)
            ?.includes(result?.group2?.value);

        if (result?.template && !canAddTemplate) {
          this.template.push(result?.template);
        }
        if (result?.group1 && !canAddGroup1) {
          this.group1.push(result?.group1);
        }
        if (result?.group_type && !canAddType) {
          this.group_type.push(result?.group_type);
        }
        if (result?.group2 && !canAddGroup2) {
          this.group2.push(result?.group2);
        }
      }
    });
  }

  removeTemplate(index) {
    if (index >= 0) {
      this.template.splice(index);
    }
  }

  removeGroup1(index) {
    if (index >= 0) {
      this.group1.splice(index);
    }
  }

  removeGroupType(index) {
    if (index >= 0) {
      this.group_type.splice(index);
    }
  }

  removeGroup2(index) {
    if (index >= 0) {
      this.group2.splice(index);
    }
  }

  openZoneFilter() {
    const dialogRef = this.dialog.open(ZoneDialogComponent, {
      width: "100%",
      data: {
        projects_work_area_id: this.selected_projects_workarea,
        projects_profile_id: this.projects_profile_id,
        projects_supervision_id: this.projects_supervision_id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let canAddZone = this.zone
            ?.map((el) => el.value)
            ?.includes(result?.zone?.value),
          canAddBlock = this.block
            ?.map((el) => el.value)
            ?.includes(result?.block?.value),
          canAddCluster = this.cluster
            ?.map((el) => el.value)
            ?.includes(result?.cluster?.value);

        if (result?.zone && !canAddZone) {
          this.zone.push(result?.zone);
        }
        if (result?.block && !canAddBlock) {
          this.block.push(result?.block);
        }
        if (result?.cluster && !canAddCluster) {
          this.cluster.push(result?.cluster);
        }
      }
    });
  }

  removeZone(index) {
    if (index >= 0) {
      this.zone.splice(index);
    }
  }

  removeBlock(index) {
    if (index >= 0) {
      this.block.splice(index);
    }
  }

  removeCluster(index) {
    if (index >= 0) {
      this.cluster.splice(index);
    }
  }

  buildForm() {
    this.sortForm = this.fb.group({
      form_request_id: null,
      template_id: null,
      platform_code_system: null,
      zone: null,
      block: null,
      cluster: null,
      barcode: null,
    });
  }

  sendSortForm() {
    this.sortParams(this.sortForm?.value);
  }

  resetSortForm() {
    this.sortForm.reset();
    this.filter_name = null;
    this.selectedFilter = null;
    this.sortParams(this.sortForm?.value);
  }

  convertDateToString(date) {
    if (date) {
      var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("/");
    } else {
      return null;
    }
  }
  getWorkAreaList(event?) {
    this.ds
      .get(
        `dashboard/get_workarea?lang_key=${this.lang_key}&wsearch_key=${
          event?.term || ""
        }&projects_work_area_id=`
      )
      .subscribe((res) => {
        if (res?.status) {
          this.workareaList = res?.records;
          if (
            this.workareaList?.length == 1 &&
            !this.selected_projects_workarea
          ) {
            this.selected_projects_workarea = this.workareaList[0]?.value;
            this.projects_profile_id =
              this.workareaList[0]?.projects_profile_id;
            this.projects_supervision_id =
              this.workareaList[0]?.projects_supervision_id;
            localStorage.setItem(
              "selected_projects_workarea",
              this.selected_projects_workarea
            );
            localStorage.setItem(
              "projects_profile_id",
              this.projects_profile_id
            );
            localStorage.setItem(
              "projects_supervision_id",
              this.projects_supervision_id
            );
          }
        } else {
          this.workareaList = [];
        }
      });
  }

  onSelectWorkarea(event) {
    if (this.selected_projects_workarea) {
      let item = this.workareaList?.find(
        (el) => el?.value == this.selected_projects_workarea
      );
      this.projects_profile_id = item?.projects_profile_id;
      this.projects_supervision_id = item?.projects_supervision_id;
      localStorage.setItem(
        "selected_projects_workarea",
        this.selected_projects_workarea
      );
      localStorage.setItem("projects_profile_id", this.projects_profile_id);
      localStorage.setItem(
        "projects_supervision_id",
        this.projects_supervision_id
      );
      this.getTransactionsData(this.filters_params, this.sort_params, false);
      this.ds.general_work_area_id.next(true);
    }
  }

  onCreateTransaction() {
    const dialogRef = this.dialog.open(CreateProjectTransactionComponent, {
      width: "900px",
      autoFocus: false,
      data: {
        projects_profile_id: this.projects_profile_id,
        projects_work_area_id: this.selected_projects_workarea,
        projects_supervision_id: this.projects_supervision_id,
      },
    });
  }

  ngOnDestroy() {
    this.apisSubscription.unsubscribe();
    this.dialog.closeAll();
  }
}
