<div class="row">
	<div class="col-12" style="min-height: 130px; margin-bottom: 15px">
		<div class="main-status">
			<div class="ms-header">
				<h3 class="ms-header-title" *ngIf="!ticket_list?.data?.length">
					{{ "lang_ticket" | language }} {{ "lang_list" | language }}
				</h3>
				<h3 class="ms-header-title" *ngIf="ticket_list?.data?.length">
					{{ "lang_ticket" | language }}
					{{ "lang_list" | language }} ({{ ticket_list?.page?.total_records }})
				</h3>
				<button class="add-btn" (click)="onAddTicket()">
					<i class="pi pi-plus"></i>
					<span>{{ "lang_add" | language }}</span>
				</button>
			</div>
			<div class="ms-content">
				<div class="msc-list">
					<div class="row" *ngIf="!ticketLoading && ticket_list?.data?.length">
						<div
							class="col-12 col-sm-6"
							*ngFor="let item of ticket_list?.data; let i = index"
						>
							<div
								class="mscl-item"
								routerLink="/tasks/view/{{ item?.ticket_id }}"
							>
								<div class="taskNumTag">
									<span>{{ item?.ticket_no }}</span>
								</div>
								<div
									class="mscli-labels mscli-labels-show"
									style="padding: 0 50px"
								>
									<div
										class="mscli-labels-label"
										[attr.style]="
											item?.ticket_status_name == 'new'
												? 'background-color:#61bd4f'
												: 'background-color:#0079BF'
										"
									>
										<span style="color: #fff">{{
											item?.ticket_status_name
										}}</span>
									</div>
								</div>
								<div
									class="mscli-header"
									[ngClass]="{
										no_labels: !item?.ticket_titel
									}"
								>
									<h4 class="mscli-header-title">
										{{ item?.ticket_titel }}
									</h4>
									<div class="d-flex">
										<span
											class="mscli-header-icon mr-1 ml-1"
											*ngIf="item?.can_edit"
											(click)="$event?.stopPropagation()"
											routerLink="/tasks/edit/{{ item?.ticket_id }}"
											matTooltip="{{ 'lang_edit' | language }}"
										>
											<i class="fa fa-pencil mnabricon"></i>
										</span>
										<span
											class="mscli-header-icon"
											*ngIf="item?.can_delete"
											(click)="deleteTicket($event, item?.ticket_id)"
											matTooltip="{{ 'lang_delete' | language }}"
										>
											<i class="fa fa-trash mnabricon"></i>
										</span>
									</div>
								</div>
								<div class="mscli-content">
									<div class="task-badge" *ngIf="item?.barcode">
										<i class="pi pi-qrcode"></i>
										<span>{{ item?.barcode }}</span>
									</div>
									<div class="task-badge" *ngIf="item?.ref_model">
										<i class="pi pi-check-square" style="font-size: 14px"></i>
										<span>{{ item?.ref_model }}</span>
									</div>
									<div class="task-badge" *ngIf="item?.insert_date">
										<i class="pi pi-clock"></i>
										<span>{{ item?.insert_date }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row" *ngIf="ticketLoading">
						<div class="col-12 col-sm-6">
							<ngx-skeleton-loader
								count="5"
								[theme]="{ 'border-radius': '3px', height: '70px' }"
							></ngx-skeleton-loader>
						</div>
						<div class="col-12 col-sm-6">
							<ngx-skeleton-loader
								count="5"
								[theme]="{ 'border-radius': '3px', height: '70px' }"
							></ngx-skeleton-loader>
						</div>
					</div>
				</div>
				<no-data
					style="margin: auto"
					[Opened]="ticket_list == null && !ticketLoading"
				></no-data>
			</div>
			<div class="ms-footer" *ngIf="ticket_list?.data?.length > 0">
				<div class="ms-footer-pagination">
					<ngb-pagination
						[collectionSize]="ticket_list?.page?.total_records"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="+tickets_page_no"
						[pageSize]="+tickets_page_size"
						(pageChange)="load_by_page_no($event)"
					></ngb-pagination>
					<mat-select
						style="max-width: 150px"
						[(ngModel)]="tickets_page_size"
						(selectionChange)="load_by_page_size($event.value)"
					>
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
	<div class="col-12">
		<hr style="width: 80%; margin: 15px auto" />
	</div>
	<div class="col-12">
		<app-design-tickets-calendar
			[projects_work_area_id]="projects_work_area_id"
			*ngIf="projects_work_area_id"
		></app-design-tickets-calendar>
	</div>
</div>
