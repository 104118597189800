<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_contracts" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div
		class="table-responsive dropdownyes customResponsive tablefields"
		*ngIf="contracts?.length"
	>
		<table border="1" class="table CustomTable">
			<thead>
				<tr>
					<th>{{"lang_id" | language}}</th>
					<th>{{ "lang_title_english" | language }}</th>
					<th>{{ "lang_title_arabic" | language }}</th>
					<th>{{ "lang_action" | language }}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="
						let item of contracts;
						let i = index;
						let odd = odd;
						let even = even
					"
				>
					<td>{{ item?.contract_id }}</td>
					<td>{{ item?.contact_titel }}</td>
					<td>{{ item?.contact_titel_en }}</td>
					<td>
                        <i class="pi pi-sitemap ml-1 mr-1" matTooltip="{{'lang_merge' | language}}" (click)="mergeTicketContract(item?.contract_id)"></i>
                        <i class="fa fa-chain-broken ml-1 mr-1" style="font-size: 18px;" matTooltip="{{'lang_unmerge' | language}}" (click)="unMergeTicketContract(item?.contract_id)"></i>
                    </td>
				</tr>
			</tbody>
		</table>
		<div
			class="mt-2"
			*ngIf="contracts?.length > 0"
			style="
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
			"
		>
			<ngb-pagination
				*ngIf="contracts.length"
				[collectionSize]="contracts.length"
				[(page)]="pageno"
				[maxSize]="pageSize"
				[boundaryLinks]="true"
			></ngb-pagination>
		</div>
	</div>
	<div
		class="mt-3 mb-3 text-center"
		*ngIf="!contracts?.length"
	>
		<p style="font-size: 18px">{{ "lang_no_data" | language}}</p>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</mat-dialog-actions>
