import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-project-transaction-form-create",
  templateUrl: "./project-transaction-form-create.component.html",
  styleUrls: ["./project-transaction-form-create.component.scss"],
})
export class ProjectTransactionFormCreateComponent implements OnInit {
  formName = localStorage.getItem("formName");
  formKey = localStorage.getItem("formKey");
  projects_work_area_id = localStorage.getItem("projects_work_area_id");
  template_id = localStorage.getItem("template_id");

  constructor() {}

  ngOnInit(): void {
    console.log(this.formKey);
  }
}
