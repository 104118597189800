<div class="card supervision_time_schedules">
	<div class="card-header">{{ "lang_bill_of_quantity" | language }}</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="" *ngIf="billdata">
				<div class="row">
					<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
						<div class="form-group">
							<label>{{ "lang_description" | language }}</label>
							<div class="">
								<b>{{ billdata.bill_quantity_description }}</b>
							</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
						<div class="form-group">
							<label>{{ "lang_unit_total" | language }}</label>
							<div class="">
								<b>{{ ds.addCommas(billdata.bill_quantity_unit_total) }} SR</b>
							</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
						<div class="form-group">
							<label>{{ "lang_tax" | language }} %</label>
							<div class="">
								<b>{{ billdata.bill_quantity_tax }}</b>
							</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
						<div class="form-group">
							<label>{{ "lang_total_with_tax" | language }}</label>
							<div class="">
								<b
									>{{
										ds.addCommas(billdata.bill_quantity_with_tax_amount)
									}}
									SR</b
								>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
						<div class="form-group">
							<label>{{ "lang_file" | language }}</label>
							<div class="">
								<mat-icon
									class="mnabricon"
									matTooltip="{{ 'lang_view' | language }}"
									(click)="
										ds.preview_pdf_file(
											billdata.bill_quantity_file_url,
											billdata?.bill_quantity_description
										)
									"
									>picture_as_pdf</mat-icon
								>
							</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
						<div class="form-group">
							<label>{{ "lang_writer" | language }}</label>
							<div class="">
								<b>{{ writer }}</b>
							</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
						<div class="form-group">
							<label>{{ "lang_ondate" | language }}</label>
							<div class="">
								<b>{{ billdata.bill_quantity_created_datetime }}</b>
							</div>
						</div>
					</div>
					<div
						class="col-md-3 col-sm-3 col-xs-12 mb-10"
						*ngIf="billdata.bill_quantity_updated_datetime"
					>
						<div class="form-group">
							<label>{{ "lang_onupdate" | language }}</label>
							<div class="">
								<b>{{ billdata.bill_quantity_updated_datetime }}</b>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="input-group input-group-sm mb-10">
						<input
							type="text"
							class="form-control almnabrformcontrol h34"
							[(ngModel)]="billsearchKey"
							(keyup)="search_supervision_quantities()"
							placeholder="{{ 'lang_search' | language }}"
						/>
						<span class="input-group-btn" *ngIf="!billdata && makeAction">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_add' | language }}"
								class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
								(click)="emptyaddform(); openModal(billofquantityform)"
							></button>
						</span>
						<span class="input-group-btn" *ngIf="billdata && makeAction">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_edit' | language }}"
								class="btnSearch h34 btn btn-md btn-gray fa fa-pencil-square-o mnabricon"
								(click)="emptyEditform(); openModal(billofquantityform)"
							></button>
						</span>
						<span class="input-group-btn" *ngIf="billdata && makeAction">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_delete' | language }}"
								class="btnSearch h34 btn btn-md btn-gray red fa fa-trash-o mnabricon"
								(click)="deletebillof_quantity()"
							></button>
						</span>
						<span class="input-group-btn" *ngIf="samplefile">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_sample_billof_quantityfile' | language }}"
								class="btnSearch h34 btn btn-md btn-gray red fa fa-file-excel-o mnabricon"
								(click)="ds.preview_pdf_file(samplefile)"
							></button>
						</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td>#</td>
									<td>{{ "lang_item_description" | language }}</td>
									<td>{{ "lang_invoice_item_code" | language }}</td>
									<td>{{ "lang_invoice_item_unit" | language }}</td>
									<td>{{ "lang_invoice_item_quantity" | language }}</td>
									<td>{{ "lang_invoice_item_price" | language }}</td>
									<td>{{ "lang_total_price" | language }}</td>
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody *ngIf="billListData.length != '0'" class="AppendList">
								<tr
									*ngFor="
										let list of billListData.records;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
									class="makeback removerow{{ list.time_schedule_id }}"
								>
									<td>{{ i + 1 }}</td>
									<td>
										<span matTooltip="{{ list.item_description }}">{{
											ds.splitwords(list.item_description, 4)
										}}</span>
									</td>
									<td>{{ list.item_code }}</td>
									<td>{{ list.item_unit }}</td>
									<td>{{ list.item_quantity }}</td>
									<td>{{ ds.addCommas(list.item_unit_price) }}</td>
									<td>{{ ds.addCommas(list.item_total_price) }}</td>
									<td>
										<i class="together-icons">
											<mat-icon
												class="mnabricon"
												matTooltip="{{ 'lang_view' | language }}"
												(click)="viewitems(list); openModal(viewbillofquantity)"
												>remove_red_eye</mat-icon
											>
										</i>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="billListData.length == '0'" class="AppendList">
								<tr class="odd">
									<td colspan="8" align="center">{{ billnodata }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="billListData?.records?.length != '0'" class="card-footer padb0">
			<div class="row responsive_pagination">
				<div
					class="col-lg-3 col-sm-3 mb-10"
					style="height: fit-content; margin: auto 0"
				>
					{{ "lang_total" | language }} :
					{{ billListData?.page?.total_records }}
				</div>
				<div
					class="col-lg-6 col-sm-6 mb-10"
					style="display: flex; justify-content: center"
				>
					<ngb-pagination
						class="m-auto"
						[collectionSize]="billListData?.page?.total_records"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="billpageno"
						[pageSize]="billpagesize"
						(pageChange)="load_supervision_quantities_page($event)"
					></ngb-pagination>
				</div>
				<div
					class="col-lg-3 col-sm-3"
					style="display: flex; justify-content: end; margin: auto"
				>
					<mat-select
						[(ngModel)]="billpagesize"
						(selectionChange)="
							load_supervision_quantities_pagesize($event.value)
						"
					>
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #viewbillofquantity>
	<div class="modal-content">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_items" | language }}</h4>
				<button
					type="button"
					class="close"
					data-dismiss="modal"
					(click)="modalRef.hide()"
				>
					&times;
				</button>
			</div>
			<div class="modal-body" *ngIf="viewitemdata.length != '0'">
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_invoice_item_code" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{ viewitemdata.item_code }}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_item_description" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{ viewitemdata.item_description }}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_invoice_item_quantity" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{ viewitemdata.item_quantity }}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_invoice_item_unit" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{ viewitemdata.item_unit }}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_item_specification" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{ viewitemdata.item_specification }}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_measuring_unit" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{ viewitemdata.item_measuring_unit }}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_unit_price" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{
								ds.addCommas(viewitemdata.item_unit_price)
							}}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_unit_price_words" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b
								class="breakall"
								*ngIf="viewitemdata.item_unit_price_inwords"
								>{{ viewitemdata.item_unit_price_inwords }}</b
							>
							<b class="breakall" *ngIf="!viewitemdata.item_unit_price_inwords"
								>----</b
							>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_unit_price_by_system_words" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{
								viewitemdata.item_unit_price_inwords_bysystem_en
							}}</b
							><br />
							<b class="breakall">{{
								viewitemdata.item_unit_price_inwords_bysystem_ar
							}}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_total_price" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{
								ds.addCommas(viewitemdata.item_total_price)
							}}</b>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_total_price_in_words" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b
								class="breakall"
								*ngIf="viewitemdata.item_total_price_inwords"
								>{{ viewitemdata.item_total_price_inwords }}</b
							>
							<b class="breakall" *ngIf="!viewitemdata.item_total_price_inwords"
								>----</b
							>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-md-6 col-sm-6 col-xs-12"
							>{{ "lang_total_price_in_words_system" | language }} :</label
						>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<b class="breakall">{{
								viewitemdata.item_total_price_inwords_by_system_en
							}}</b>
							<br />
							<b class="breakall">{{
								viewitemdata.item_total_price_inwords_by_system_ar
							}}</b>
						</div>
					</div>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button
					type="button"
					class="btn btn-secondary secondarybutton"
					data-dismiss="modal"
					(click)="modalRef.hide()"
				>
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #billofquantityform>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_bill_of_quantity" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div class="modal-body">
				<div class="form-group">
					<label for="bill_quantity_description"
						>{{ "lang_description" | language }}:<span class="red"
							>*</span
						></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="bill_quantity_description"
					/>
					<mat-error
						*ngIf="
							form.controls['bill_quantity_description'].touched &&
							form.controls['bill_quantity_description'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="bill_quantity_tax"
						>{{ "lang_tax" | language }}:<span class="red">*</span></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="bill_quantity_tax"
					/>
					<mat-error
						*ngIf="
							form.controls['bill_quantity_tax'].touched &&
							form.controls['bill_quantity_tax'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="bill_quantity_file"
						>{{ "lang_bill_of_quantity" | language }}:
						<span class="red" *ngIf="!form.get('bill_quantity_id').value">
							*
						</span>
						<span *ngIf="isFile" for="bill_quantity_file">
							<i
								aria-hidden="true"
								(click)="removeFile()"
								class="fa faicon fa-close red"
							></i>
						</span>
					</label>
					<div class="IDDIV">
						<input
							style="display: block"
							name="bill_quantity_file"
							type="file"
							required
							onclick="this.value = null"
							(change)="UploadFile($event)"
							class="nghide albutton width100"
						/>
						<label id="uploadText" class="albutton">{{ filename }} </label>
					</div>
					<mat-error
						*ngIf="
							form.controls['bill_quantity_file'].touched &&
							form.controls['bill_quantity_file'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<div class="modal" id="pdfPreviewBill">
	<div class="modal-dialog width70">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_preview" | language }}</h4>
				<button type="button" class="close" data-dismiss="modal">
					&times;
				</button>
			</div>
			<div class="modal-body">
				<div class="pdf-container">
					<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</div>
