import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { UnitLevelShowComponent } from '@shared/components/unit-level-show/unit-level-show.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-iphases-dialog',
  templateUrl: './iphases-dialog.component.html',
  styleUrls: ['./iphases-dialog.component.scss']
})
export class IphasesDialogComponent implements OnInit {
  project_data;
  supervision_data;
  history_files = [];
  history_files_ED = [];
  loading_attachments = false;
  page = 1;
  size = 10;
  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe,
    public spinner : PreloaderService, public alert:AlertService, private sweetAlert:SweetAlertService, public router:Router, private dialogRef: MatDialogRef<UnitLevelShowComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data) { 
      this.supervision_data = data?.supervision_data;
      this.project_data = data?.project_data;
    }

  ngOnInit(): void {
  }



  download_i_phases() {
    this.spinner.show();
    let projects_profile_id = this.project_data.projects_profile_id;
    let projects_supervision_id = this.supervision_data.projects_supervision_id;
    this.ds.get(`p/download_units_for_opening_balance/${projects_profile_id}/${projects_supervision_id}`).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.sweetAlert.successMessage(res?.msg);
        this.ds.downloadFile(res,  'implementation_phases')
      } else {
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.sweetAlert.errorMessage(err?.error.error);
      this.spinner.hide();
    })
  }

  download_i_phases_history(item) {
    this.spinner.show();
    this.ds.get(item?.file).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.ds.downloadFile(res,  'implementation_phases_'+ item?.created_datetime);
      } else {
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.sweetAlert.errorMessage(err?.error.error);
      this.spinner.hide();
    })
  }

  get_i_phases_history() {
    this.loading_attachments = true;
    this.history_files_ED = [];
    let projects_profile_id = this.project_data.projects_profile_id;
    let projects_supervision_id = this.supervision_data.projects_supervision_id;
    this.ds.post(`s6df54s6df/${projects_profile_id}/${projects_supervision_id}`, {}).subscribe(res => {
      this.loading_attachments = false;
      if(res.status) {
        this.history_files = res?.records;
      } else {
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.sweetAlert.errorMessage(err?.error.error);
      this.loading_attachments = false;
    })
  }

  upload_i_phases(e) {
    if(e && e?.length) {
      let projects_profile_id = this.project_data.projects_profile_id;
      let projects_supervision_id = this.supervision_data.projects_supervision_id;
      let formData = new FormData();
      formData.append('import_balance_file', e[0] || '');
      this.spinner.show();
      this.ds.post(`p/upload_units_for_opening_balance/${projects_profile_id}/${projects_supervision_id}`, formData).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.sweetAlert.successMessage(res?.msg);
        } else {
          this.sweetAlert.errorMessage(res?.error);
        }
      }, (err) => {
        this.spinner.hide();
      })
    }
    document.getElementById('uploadIPhasesInput')['value'] = '';
  }

  download_units_file_configuration() {
    this.spinner.show();
    let projects_profile_id = this.project_data.projects_profile_id;
    let projects_supervision_id = this.supervision_data.projects_supervision_id;
    this.ds.post(`p/download_units_for_file_configuration/${projects_profile_id}/${projects_supervision_id}`, {}).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.sweetAlert.successMessage(res?.msg);
        this.ds.downloadFile(res,  'units_file_configuration')
      } else {
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.sweetAlert.errorMessage(err?.error.error);
      this.spinner.hide();
    })
  }

  upload_platform_codes(e) {
    if(e && e?.length) {
      let projects_profile_id = this.project_data.projects_profile_id;
      let projects_supervision_id = this.supervision_data.projects_supervision_id;
      let formData = new FormData();
      formData.append('import_attachment_file', e[0] || '');
      this.spinner.show();
      this.ds.post(`p/upload_units_for_file_configuration/${projects_profile_id}/${projects_supervision_id}`, formData).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.sweetAlert.successMessage(res?.msg);
        } else {
          this.sweetAlert.errorMessage(res?.error);
        }
      }, (err) => {
        this.spinner.hide();
      })
    }
    document.getElementById('uploadPlatformCodes')['value'] = '';
  }

  download_worklevels_file_configuration() {
    this.spinner.show();
    let projects_profile_id = this.project_data.projects_profile_id;
    let projects_supervision_id = this.supervision_data.projects_supervision_id;
    this.ds.post(`p/download_work_levels_for_file_configuration/${projects_profile_id}/${projects_supervision_id}`, {}).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.sweetAlert.successMessage(res?.msg);
        this.ds.downloadFile(res,  'worklevels_file_configuration')
      } else {
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.sweetAlert.errorMessage(err?.error.error);
      this.spinner.hide();
    })
  }

  upload_extra_data(e) {
    if(e && e?.length) {
      let projects_profile_id = this.project_data.projects_profile_id;
      let projects_supervision_id = this.supervision_data.projects_supervision_id;
      let formData = new FormData();
      formData.append('import_extra_data_file', e[0] || '');
      this.spinner.show();
      this.ds.post(`p/upload_units_for_extra_data/${projects_profile_id}/${projects_supervision_id}`, formData).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.sweetAlert.successMessage(res?.msg);
        } else {
          this.sweetAlert.errorMessage(res?.error);
        }
      }, (err) => {
        this.spinner.hide();
      })
    }
    document.getElementById('uploadExtraData')['value'] = '';
  }

  download_extra_data() {
    this.spinner.show();
    let projects_profile_id = this.project_data.projects_profile_id;
    let projects_supervision_id = this.supervision_data.projects_supervision_id;
    this.ds.get(`p/download_units_for_extra_data/${projects_profile_id}/${projects_supervision_id}`).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.sweetAlert.successMessage(res?.msg);
        this.ds.downloadFile(res,  'phases_extra_data')
      } else {
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.sweetAlert.errorMessage(err?.error.error);
      this.spinner.hide();
    })
  }

  get_extra_data_history() {
    this.loading_attachments    = true;
    this.history_files          = [];
    let projects_profile_id     = this.project_data.projects_profile_id,
        projects_supervision_id = this.supervision_data.projects_supervision_id,
        formData                = new FormData();
        formData.append('required_type', 'ED');
    this.ds.post(`s6df54s6df/${projects_profile_id}/${projects_supervision_id}`, formData).subscribe(res => {
      this.loading_attachments = false;
      if(res.status) {
        this.history_files_ED = res?.records;
      } else {
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.sweetAlert.errorMessage(err?.error.error);
      this.loading_attachments = false;
    })
  }

  download_ED_history(item) {
    this.spinner.show();
    this.ds.get(item?.file).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.ds.downloadFile(res,  'iphases_Extra_Data'+ item?.created_datetime);
      } else {
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.sweetAlert.errorMessage(err?.error.error);
      this.spinner.hide();
    })
  }

  closeModal() {
    this.dialogRef.close();
  }
}
