import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService } from "ngx-bootstrap/modal";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
  selector: "app-rstep5",
  templateUrl: "./rstep5.component.html",
  styleUrls: ["./rstep5.component.scss"],
})
export class Rstep5Component implements OnInit {
  @Input() signupForm: FormGroup;
  @Input() action;
  singleselection = environment.singledropdown;
  public fileurl;
  @Input() files;
  apiurl = environment.SERVER_ORIGIN;
  idfilename = this.lang.transform("lang_select_files");
  isFile: boolean;
  banklists: any;
  lang_key = localStorage.getItem("lang_key") || "en";
  constructor(
    private cdRef: ChangeDetectorRef,
    public fb: FormBuilder,
    public ds: DataService,
    public route: ActivatedRoute,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public router: Router,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  get f() {
    return this.signupForm.controls.bankDetails;
  }
  ngOnInit() {
    this.loadBankDetails();
  }
  loadBankDetails() {
    this.banklists = [];
    this.ds
      .getActionByNoToken([], "signup/get_settings/BANK/" + this.lang_key)
      .subscribe((data) => {
        if (data.status) {
          this.banklists = data.titles;
        }
      });
  }
  uploadIDFile(event) {
    if (event.target.files[0].type == "application/pdf") {
      if (
        event.target.files[0] &&
        event.target.files[0] !== undefined &&
        event.target.files[0].name
      ) {
        const fileid = event.target.files[0];
        if (fileid.size > 512000) {
          this.sweetAlert.errorToast(
            this.lang.transform("lang_file_size_too_large"),
            3000
          );
          return;
        }
        this.idfilename = fileid.name;
        this.isFile = true;
        this.signupForm
          .get("bankDetails")
          .get("bank_file")
          .setValue(fileid, { emitEvent: true });
      } else {
        this.isFile = false;
        this.signupForm.get("bankDetails").get("bank_file").setValue("");
        this.idfilename = this.lang.transform("lang_select_files");
      }
    } else {
      this.sweetAlert.errorToast(this.lang.transform("lang_pdf_only"), 3000);
    }
  }
  removeFile() {
    this.isFile = false;
    this.signupForm.get("bankDetails").get("bank_file").setValue("");
    this.idfilename = this.lang.transform("lang_select_files");
  }
  rstep5Submitted() {
    this.signupForm.get("bankDetails").get("account_number").markAsTouched();
    this.signupForm
      .get("bankDetails")
      .get("account_number")
      .updateValueAndValidity();
    this.signupForm.get("bankDetails").get("bank_short_code").markAsTouched();
    this.signupForm
      .get("bankDetails")
      .get("bank_short_code")
      .updateValueAndValidity();
    this.signupForm.get("bankDetails").get("bank_file_name").markAsTouched();
    this.signupForm
      .get("bankDetails")
      .get("bank_file_name")
      .updateValueAndValidity();
    this.signupForm.get("bankDetails").get("bank_file").markAsTouched();
    this.signupForm
      .get("bankDetails")
      .get("bank_file")
      .updateValueAndValidity();
  }
}
