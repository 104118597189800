import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { ToggleMenuService } from "@core/bootstrap/toggle-menu.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import * as echarts from "echarts";
import { Subscription } from "rxjs";

@Component({
	selector: "app-wafi-view",
	templateUrl: "./wafi-view.component.html",
	styleUrls: ["./wafi-view.component.scss"],
})
export class WafiViewComponent implements OnInit {
	report_id = this.route.snapshot.paramMap.get("wid");
	report_data;
	sum_total_plan = 0;
	sum_actual_total = 0;
	last_date_plan = "";
	axis3Chart;
	apisSubscription = new Subscription();
	constructor(
		private route: ActivatedRoute,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private spinner: PreloaderService,
		private toggleMenu: ToggleMenuService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		setTimeout(() => {
			this.toggleMenu.close();
		}, 1000);
		this.getWafiReportData();
		this.initAxis3Chart();
		window.onresize = () => {
			this.axis3Chart.resize();
		};
		this.toggleMenu.isMob.subscribe((res) => {
			setTimeout(() => {
				this.axis3Chart.resize();
			}, 200);
		});
	}

	getWafiReportData() {
		let formData = new FormData();
		formData.append("wafi_report_id", this.report_id || "");
		this.spinner.show();
		this.apisSubscription.add(
			this.ds
				.getActionByUrl(
					this.ds.formData2string(formData),
					"pr/report/get_wafi_report"
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res?.error) {
							this.sweetAlert.errorToast(res?.error, 3000);
							return;
						}
						this.report_data = res;
						this.getMonthlyData();
					},
					(err) => {
						this.spinner.hide();
						this.sweetAlert.errorToast(
							this.lang.transform("lang_internal_server_error"),
							3000
						);
					}
				)
		);
	}

	initAxis3Chart() {
		//axis3 chart
		this.axis3Chart = echarts.init(document.getElementById("axis3-chart"));
		this.axis3Chart.showLoading();
	}

	getMonthlyData() {
		let formData = new FormData();
		formData.append(
			"projects_work_area_id",
			this.report_data?.report_work_area_info?.projects_work_area_id || ""
		);
		this.apisSubscription.add(
			this.ds
				.post(
					`pr/plan_results/${
						this.report_data?.report_work_area_info?.projects_profile_id
					}/${
						this.report_data?.report_work_area_info?.project_supervision_id ||
						"1"
					}`,
					formData
				)
				.subscribe((res) => {
					if (res?.status) {
						let customData = res?.result?.map((el, index) => {
							let actual_data = res?.result?.slice(0, index + 1);
							let actual_numbers = actual_data?.map((item) => {
								if (item?.monthly_actual_total) {
									return parseFloat(item?.monthly_actual_total);
								} else {
									return 0.0;
								}
							});

							let sum_actual_total = actual_numbers.reduce((accumulator, a) => {
								return accumulator + a;
							}, 0);
							return {
								month_plan_setting: el?.month_plan_setting,
								monthly_total_plan: el?.monthly_total_plan
									? this.customizeFraction(el?.monthly_total_plan)
									: 0.0,
								sum_total_plan: el?.sum_total_plan
									? this.customizeFraction(el?.sum_total_plan)
									: 0.0,
								monthly_actual_total: el?.monthly_actual_total
									? this.customizeFraction(el?.monthly_actual_total)
									: 0.0,
								monthly_sum_actual_total: sum_actual_total
									? this.customizeFraction(sum_actual_total + "")
									: 0.0,
								forecast_average: el?.forecast_average || 0,
							};
						});
						this.updateAxis3Chart(customData);
					}
				})
		);
	}

	updateAxis3Chart(data: any) {
		let xAxisData = [],
			monthly_actual_total = [],
			monthly_total_plan = [],
			sum_total_plan = [],
			monthly_sum_actual_total = [],
			forcast_line = [],
			forcast_bar = [];

		let currentDate = new Date();
		let currentMonth =
			("0" + (currentDate.getMonth() + 1)).slice(-2) +
			"/" +
			currentDate.getFullYear();
		let currentMonthIndex = data
			.map((el) => el?.month_plan_setting)
			.indexOf(currentMonth);
		for (let i = 0; i < data.length; i++) {
			xAxisData.push(data[i]?.month_plan_setting);
			monthly_actual_total.push(data[i]?.monthly_actual_total);
			monthly_total_plan.push(data[i]?.monthly_total_plan);
			monthly_sum_actual_total.push(data[i]?.monthly_sum_actual_total);
			sum_total_plan.push(data[i]?.sum_total_plan);
			if (i >= currentMonthIndex) {
				forcast_line.push(+data[i]?.sum_total_plan + data[i]?.forecast_average);
				forcast_bar.push(data[i]?.forecast_average);
			} else {
				forcast_line.push(data[i]?.sum_total_plan);
				forcast_bar.push(null);
			}
		}

		monthly_sum_actual_total = monthly_sum_actual_total.slice(
			0,
			xAxisData.indexOf(currentMonth) + 1
		);
		this.sum_actual_total =
			monthly_sum_actual_total[monthly_sum_actual_total.length - 1];
		this.sum_total_plan = sum_total_plan[monthly_sum_actual_total.length - 1];
		this.last_date_plan = xAxisData[monthly_sum_actual_total.length - 1];

		let newOption = {
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "cross",
					label: {
						backgroundColor: "#283b56",
					},
				},
			},
			legend: {},
			grid: {
				containLabel: true,
			},
			dataZoom: [
				{
					id: "dataZoomX",
					type: "slider",
					xAxisIndex: [0],
					filterMode: "filter",
				},
				{
					id: "dataZoomY",
					type: "slider",
					yAxisIndex: [0, 1],
					filterMode: "empty",
				},
			],
			xAxis: [
				{
					type: "category",
					boundaryGap: true,
					showMaxLabel: false,
					data: xAxisData,
					axisLabel: {
						hideOverlap: false,
						fontSize: 12,
						rotate: 40,
						interval: 0,
					},
				},
			],
			yAxis: [
				{
					type: "value",
					scale: true,
					max: 15,
					min: 0,
					boundaryGap: [0.2, 0.2],
				},
				{
					type: "value",
					scale: true,
					max: 120,
					min: 0,
					boundaryGap: [0.2, 0.2],
				},
			],
			series: [
				{
					name: this.lang.transform("lang_monthly_total_plan"),
					type: "bar",
					yAxisIndex: 0,
					data: monthly_total_plan,
					stack: "forcast_monthly_plan",
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_forcast"),
					type: "bar",
					yAxisIndex: 0,
					data: forcast_bar,
					stack: "forcast_monthly_plan",
					itemStyle: {
						color: "#e9c74d",
					},
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_actual_total"),
					type: "bar",
					yAxisIndex: 0,
					data: monthly_actual_total,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_sum_total_plan"),
					type: "line",
					yAxisIndex: 1,
					data: sum_total_plan,
					itemStyle: {
						color: "#e7d42f",
					},
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_forcast"),
					type: "line",
					yAxisIndex: 1,
					data: forcast_line,
					itemStyle: {
						color: "#e9c74d",
					},
					lineStyle: {
						type: "dotted",
					},
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
				},
				{
					name: this.lang.transform("lang_sum_actual_total"),
					type: "line",
					yAxisIndex: 1,
					data: monthly_sum_actual_total,
					tooltip: {
						formatter: (params) => {
							return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`;
						},
					},
					itemStyle: {
						color: "#56ab59",
					},
				},
			],
		};
		this.axis3Chart.setOption(newOption);
		this.axis3Chart.hideLoading();
	}

	customizeFraction(number) {
		let customNumber = "0";
		if (number) {
			let numbers = number?.split(".");
			if (numbers.length > 1) {
				if (numbers[1]?.length > 2) {
					return (customNumber =
						numbers[0] + "." + numbers[1][0] + numbers[1][1] + numbers[1][2]);
				} else if (numbers[1].length > 1) {
					return (customNumber =
						numbers[0] + "." + numbers[1][0] + numbers[1][1]);
				} else {
					return (customNumber = numbers[0] + "." + numbers[1][0]);
				}
			} else {
				return (customNumber = numbers[0]);
			}
		} else {
			return customNumber;
		}
	}
}
