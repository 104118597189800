<section class="panel-expansion matborderno" *ngIf="branch_id && branch_id !='0' ">
    <mat-accordion class="accounts_settings" >
        <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_account_tax_settings' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15">
                    <form class="taxform" [formGroup]="taxform" (ngSubmit)="save_tax()">
                        <input type="hidden" formControlName="branch_id">
                        <div class="row">
                            <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                <div class="form-group">
                                    <mat-checkbox formControlName="item_tax" class="example-margin">{{'lang_accounts_item_tax' | language }}</mat-checkbox>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                <div class="form-group">
                                    <mat-checkbox formControlName="global_tax" class="example-margin">{{'lang_accounts_global_item_tax' | language }}</mat-checkbox>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                <div class="form-group">
                                    <mat-checkbox formControlName="item_discount" class="example-margin">{{'lang_accounts_item_discount' | language }}</mat-checkbox>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                <div class="form-group">
                                    <mat-checkbox formControlName="global_discount" class="example-margin">{{'lang_accounts_global_item_discount' | language }}</mat-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 col-sm-3 col-xs-12 mar0auto">
                                <button class="albutton" type="submit">{{'lang_update' | language }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>