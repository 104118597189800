<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_groups" | language }}</h4>
		<span><i class="fa fa-times" mat-dialog-close></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="usersFormGroup">
		<div>
			<div class="row">
				<div class="col-12 col-sm-6 mb-4">
					<ng-select
						[items]="group_type_list"
						[multiple]="false"
						appendTo="body"
						bindLabel="label"
						bindValue="value"
						[searchable]="false"
						placeholder="{{ 'lang_group_type' | language }}"
						formControlName="group_type_id"
						(change)="getGroups()"
					>
					</ng-select>
				</div>
				<div class="col-12 col-sm-6 mb-4">
					<ng-select
						[items]="group_list"
						[multiple]="false"
						appendTo="body"
						bindLabel="group_name"
						bindValue="group_id"
						clearAllText="Clear"
						[searchable]="false"
						placeholder="{{ 'lang_group' | language }}"
						formControlName="group_id"
					>
					</ng-select>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col 0 col-sm-4"></div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="onSubmit()"
			[disabled]="usersFormGroup?.invalid"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
