import { DOCUMENT } from "@angular/common";
import { Component, OnInit, AfterViewInit, Inject } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { MessagingService } from "@core/bootstrap/messaging.service";
import { SocketServiceService } from "@core/bootstrap/socket-service.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
	private userToken = this.ls.getItem("token");
	constructor(
		public ds: DataService,
		private preloader: PreloaderService,
		private messagingService: MessagingService,
		private translate: TranslateService,
		@Inject(DOCUMENT) private document: Document,
		private ls: LocalStorage,
		private socketService: SocketServiceService,
		private router: Router
	) {
		this.router.events.subscribe((ev) => {
			if (ev instanceof NavigationEnd) { 
				if(this.router.url!='/auth/login'){
					this.socketService.setPath(this.router.url);
				}
			}
		  });
		localStorage.setItem("lang_key", localStorage.getItem("lang_key") || "en");
		localStorage.setItem(
			"direction",
			localStorage.getItem("direction") || "ltr"
		);
		const defaultLang = localStorage.getItem("lang_key") || "en";
		this.translate.setDefaultLang(defaultLang);
		this.document.body.dir = localStorage.getItem("direction") || "ltr";
	}
	ngOnInit() {
		if (this.userToken) {
			this.messagingService.requestPermission();
			this.messagingService.receiveMessage();
			this.socketService.connectToServer();
			this.ds.checkEmployeeStatus(this.ls.getItem("user_id"));
		}
	}
}
