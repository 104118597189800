import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";

declare var $: any;

@Component({
  selector: "app-ste-step1-config",
  templateUrl: "./ste-step1-config.component.html",
  styleUrls: ["./ste-step1-config.component.scss"],
})
export class SteStep1ConfigComponent implements OnInit {
  users: any = [];
  @Output() contractEvent = new EventEmitter();
  @Input() account_operation: string;
  @Input() employee_data;
  @Input() form_request_id;
  @Input() form_key;
  @Input() formSTEData;
  @Output() updateForm = new EventEmitter();
  submitForm: boolean = false;
  invoiceList: any = [];
  lang_key = localStorage.getItem("lang_key") || "en";
  usr_date = JSON.parse(localStorage.getItem("usr"));
  form: FormGroup;
  branches: any = [];
  contract_termination_list: any = [];
  submitted: boolean = false;
  constructor(
    public spinner: PreloaderService,
    public ds: DataService,
    public alert: AlertService,
    public lang: LanguagePipe,
    public router: Router,
    private snackBar: MatSnackBar,
    private sweetAlert: SweetAlertService,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.buildform();
    this.get_contract_termination_cases();
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  show_contracts() {}
  public buildform() {
    this.form = new FormGroup({
      employee_number: new FormControl("", [Validators.required]),
      settlement_reasons: new FormControl("", [Validators.required]),
      financial_notes: new FormControl(null, Validators.required),
    });
  }
  showMsg(msg: string, action: string, className: string) {
    this.snackBar.open(msg, action, {
      duration: 4000,
      panelClass: [className],
    });
  }
  get_contract_termination_cases() {
    this.ds.getActionByUrl([], "form/FORM_STE/settlement_reasons").subscribe(
      (res) => {
        if (res?.status) {
          this.contract_termination_list = res;
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            res?.error || this.lang.transform("lang_no_data"),
            2000
          );
          this.contract_termination_list = [];
        }
      },
      (err) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          2000
        );
        this.invoiceList = [];
      }
    );
  }
  public update_settlement() {
    let param = new FormData();
    param.set("employee_number", this.usr_date?.id);
    param.set("settlement_reasons", this.form.get("settlement_reasons").value);
    param.set("financial_notes", this.form.get("financial_notes").value);
    param.set("transaction_request_id", this.form_request_id);
    this.spinner.show();
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "form/FORM_STE/et").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.spinner.hide();
            this.alert.success(res.msg);
            this.submitForm = false;
            this.updateForm.emit("");
          } else {
            this.spinner.hide();
            this.alert.error(res?.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
}
