import { environment } from "./../../../../../environments/environment";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";

@Component({
  selector: "app-account-master-list",
  templateUrl: "./account-master-list.component.html",
  styleUrls: ["./account-master-list.component.scss"],
})
export class AccountMasterListComponent implements OnInit, OnDestroy {
  options = { useCheckbox: false };
  equalheight = "";
  style: any;
  costcenters: any = [];
  currencies: any = [];
  acctypes: any = [];
  balancesheets: any = [];
  multisettings = environment.multipledropdown;
  singlesettings: any;
  nodes: any = [];
  branches: any = [];
  branch_id = localStorage.getItem("selectedBranch");
  form_show_status = "ADD";
  add: boolean = false;
  edit: boolean = false;
  delete = false;
  viewstatus: boolean = false;
  viewdata: any = [];
  accountmasterform: FormGroup;
  financeList: any = [];
  finance_id = null;
  account_create_type: any = false;
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.load_branches();
    this.build_form();
    if (this.branch_id !== null && this.branch_id != "0") {
      this.load_account_tree();
      this.searchCurrencies();
      this.searchAccountTypes();
      this.getFinancialList();
    }
    this.singlesettings = {
      ...environment.singledropdown,
      text: this.lang.transform("lang_select"),
    };
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public selectBrach(branch) {
    if (branch && branch != "0") {
      localStorage.setItem("selectedBranch", branch);
      this.accountmasterform.get("branch_id").setValue(branch);
      this.branch_id = branch;
      this.load_account_tree();
      this.searchCurrencies();
      this.searchAccountTypes();
      this.getFinancialList();
    }
  }
  public build_form() {
    this.accountmasterform = new FormGroup({
      account_masters_id: new FormControl(null),
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      account_master_root: new FormControl(1, [Validators.required]),
      account_masters_code: new FormControl(null),
      account_masters_name_en: new FormControl(null, [Validators.required]),
      account_masters_name_ar: new FormControl(null, [Validators.required]),
      account_masters_currency_id: new FormControl(null, [Validators.required]),
      account_masters_type: new FormControl(null, [Validators.required]),
      account_create_type: new FormControl(null, [Validators.required]),
      account_masters_balance_sheet_id: new FormControl(null, [
        Validators.required,
      ]),
      account_masters_support: new FormControl(0),
      cost_center_support: new FormControl(0),
      account_masters_cost_center_id: new FormControl(null),
      hold_account_from_transaction: new FormControl(false),
      set_profit_loss_account: new FormControl(false),
      account_vat_number: new FormControl(""),
      finance_id: new FormControl(null),
    });
  }
  public load_account_tree() {
    this.accountmasterform.get("finance_id").setValue(this.finance_id);
    this.account_create_type = false;
    this.spinner.show();
    let formData = new FormData();
    formData.append("finance_id", this.finance_id || "");
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(formData),
          "amlist/" + this.branch_id
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.nodes = res.records;
            } else {
              this.nodes = [];
            }
          },
          (error) => {
            this.spinner.hide();
            this.nodes = [];
          }
        )
    );
  }
  public clear(tree) {
    tree.collapseAll();
    this.form_show_status = "ADD";
    this.accountmasterform.reset();
    this.accountmasterform.get("account_master_root").setValue("1");
    this.accountmasterform.get("branch_id").setValue(this.branch_id);
    if (tree) {
      tree.clearFilter();
      this.viewstatus = false;
      if (tree.getFocusedNode()?.isActive) {
        tree.getFocusedNode().toggleActivated();
      }
    }
  }
  public toggleAction(tree: any) {
    this.add = this.edit = this.delete = this.viewstatus = false;
    this.form_show_status = "ADD";
    this.accountmasterform.reset();
    this.accountmasterform.get("account_master_root").setValue("1");
    this.accountmasterform.get("account_masters_id").setValue(null);
    this.accountmasterform.get("account_masters_code").setValue(null);
    if (tree.activeNodes.length > 0) {
      this.account_view_data(tree.activeNodes[0].data.account_masters_id);
      this.viewstatus = true;
      this.accountmasterform.get("account_master_root").setValue(0);
      this.accountmasterform
        .get("account_masters_id")
        .setValue(tree.activeNodes[0].data.account_masters_id);
      this.accountmasterform
        .get("account_masters_code")
        .setValue(tree.activeNodes[0].data.account_masters_code);
      if (
        tree.activeNodes[0].data.account_masters_support == "1" &&
        this.ds.allowMe("accounts", "accounts_add")
      ) {
        // need Add permission and sub account support 1
        this.add = true;
      }
      if (this.ds.allowMe("accounts", "accounts_edit")) {
        //need edit permission
        this.edit = true;
      }
      if (
        tree.activeNodes[0].data.account_masters_support == "0" &&
        this.ds.allowMe("accounts", "accounts_delete")
      ) {
        // need permission and sub account 0
        this.delete = true;
      }
    }
  }
  public treeAction(tree: any, action: any) {
    if (tree.activeNodes.length > 0) {
      let data = tree.activeNodes[0].data;
      if (action == "view") {
        this.form_show_status = "VIEW";
        this.account_view_data(data.account_masters_id);
      } else if (action == "add") {
        if (this.accountmasterform.contains("finance_id")) {
          this.accountmasterform.removeControl("finance_id");
        }
        this.accountmasterform.reset();

        this.accountmasterform.get("account_master_root").setValue(0);
        this.accountmasterform.get("branch_id").setValue(this.branch_id);

        // By Raja
        if (data.account_masters_support == "0") {
          this.accountmasterform.get("account_create_type").setValue("next");
          this.account_create_type = false;
        } else {
          this.account_create_type = true;
          this.accountmasterform.get("account_create_type").setValue("");
        }

        this.accountmasterform
          .get("account_masters_id")
          .setValue(data.account_masters_id);
        this.accountmasterform
          .get("account_masters_code")
          .setValue(data.account_masters_code);
        this.accountmasterform
          .get("account_masters_support")
          .setValue(data.account_masters_support);
        this.accountmasterform
          .get("cost_center_support")
          .setValue(data.cost_center_support);
        this.accountmasterform.get("account_masters_currency_id").setValue([
          {
            label: data.currencylabel,
            value: data.currencyvalue,
          },
        ]);
        this.accountmasterform.get("account_masters_type").setValue([
          {
            label: data.accountlabel,
            value: data.accountvalue,
          },
        ]);
        this.accountmasterform
          .get("account_masters_balance_sheet_id")
          .setValue([
            {
              label: data.balancelabel,
              value: data.balancevalue,
            },
          ]);
        this.form_show_status = "ADD";
      } else if (action == "edit") {
        this.spinner.show();
        if (!this.accountmasterform.contains("finance_id")) {
          this.accountmasterform.addControl(
            "finance_id",
            new FormControl(null)
          );
        }
        this.subscriptions.add(
          this.ds
            .getActionByUrl(
              [],
              "amedit/" + this.branch_id + "/" + data.account_masters_id
            )
            .subscribe(
              (res) => {
                this.spinner.hide();
                if (res.status) {
                  let hold_account_from_transaction =
                    res?.records?.hold_account_from_transaction == "1"
                      ? true
                      : false;
                  let set_profit_loss_account =
                    res?.records?.set_profit_loss_account == "1" ? true : false;
                  this.accountmasterform.patchValue({
                    ...res.records,
                    hold_account_from_transaction:
                      hold_account_from_transaction,
                    set_profit_loss_account: set_profit_loss_account,
                  });

                  // By Raja
                  /*if(res.records.account_masters_support == "0" ) {
										this.accountmasterform.get("account_create_type").value("next");
										this.account_create_type = false;
									} else {
										this.account_create_type = true;
										this.accountmasterform.get("account_create_type").value("");
									}*/

                  this.searchBalanceSheets();
                } else {
                  this.ds.dialogf("", res.error);
                }
              },
              (error) => {
                this.spinner.hide();
                this.ds.dialogf(
                  "",
                  error && error.error && error.error.error
                    ? error.error.error
                    : this.lang.transform("lang_internal_server_error")
                );
              }
            )
        );

        this.form_show_status = "EDIT";
      } else if (action == "delete") {
        const dialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: true,
          width: "400px",
          data: {
            title: this.lang.transform("lang_confirmation"),
            message: this.lang.transform("lang_are_you_sure"),
          },
        });
        this.subscriptions.add(
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.spinner.show();
              this.ds
                .deleteActionByUrl(
                  [],
                  "amdelete/" + this.branch_id + "/" + data.account_masters_id
                )
                .subscribe(
                  (data) => {
                    this.spinner.hide();
                    if (data.status) {
                      this.nodes = data.records;
                      let element: HTMLElement = document.getElementById(
                        "btnSearch"
                      ) as HTMLElement;
                      element.click();
                    } else {
                      this.ds.dialogf("", data.error);
                    }
                  },
                  (error) => {
                    this.spinner.hide();
                    this.ds.dialogf(
                      "",
                      error && error.error && error.error.error
                        ? error.error.error
                        : this.lang.transform("lang_internal_server_error")
                    );
                  }
                );
            }
          })
        );
      }
    } else {
      this.ds.dialogf("", this.lang.transform("lang_select_anyone"));
    }
  }
  public cost_support() {
    this.costcenters = [];
    this.accountmasterform.get("account_masters_cost_center_id").setValue([]);
  }
  public searchCurrencies() {
    this.currencies = [];
    let param = new FormData();
    param.append("branch_id", this.branch_id);
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "currencies").subscribe(
        (res) => {
          if (res.status) {
            this.currencies = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  export_exl_pdf(type) {
    this.spinner.show();
    let param = new FormData();
    param.set("branch_id", this.accountmasterform.get("branch_id").value);
    param.set(
      "finance_id",
      this.accountmasterform.get("finance_id").value
        ? this.accountmasterform.get("finance_id").value
        : "0"
    );
    param.set("export_type", type);
    param.set("account_report_type", "AM");
    this.ds.export_account_reports(param, type);
  }
  public searchAccountTypes() {
    this.acctypes = [];
    let param = new FormData();
    param.append("branch_id", this.branch_id);
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "actypes").subscribe(
        (res) => {
          if (res.status) {
            this.acctypes = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public emptyBalanceSheets(item: any, action: any) {
    this.balancesheets = [];
    this.accountmasterform
      .get("account_masters_balance_sheet_id")
      .setValue(null);
    if (action == "select") {
      this.searchBalanceSheets();
    }
  }
  public searchBalanceSheets() {
    this.balancesheets = [];
    let param = new FormData();
    param.append("branch_id", this.branch_id);
    if (this.accountmasterform.get("account_masters_type").value) {
      param.append(
        "account_type",
        this.accountmasterform.get("account_masters_type").value[0].value
      );
    } else {
      param.append("account_type", "");
    }
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "bsheets").subscribe(
        (res) => {
          if (res.status) {
            this.balancesheets = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public searchCostCenter(event) {
    this.costcenters = [];
    let param = new FormData();
    param.append("branch_id", this.branch_id);
    param.append("search_text", event.target.value);
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "cctransactions").subscribe(
        (res) => {
          if (res.status) {
            this.costcenters = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public create_account_master(tree) {
    this.alert.clear();
    let hold_account_from_transaction =
      this.accountmasterform.get("hold_account_from_transaction").value == true
        ? "1"
        : "0";
    let set_profit_loss_account =
      this.accountmasterform.get("set_profit_loss_account").value == true
        ? "1"
        : "0";
    let data = this.ds.json2formdata({
      ...this.accountmasterform.value,
      hold_account_from_transaction: hold_account_from_transaction,
      set_profit_loss_account: set_profit_loss_account,
    });
    data.set("branch_id", this.branch_id);
    data.set("finance_id", this.finance_id);

    this.spinner.show();
    this.subscriptions.add(
      this.ds.postActionByUrl(data, "amcreate").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.nodes = res.records;
            tree;
            let element: HTMLElement = document.getElementById(
              "btnSearch"
            ) as HTMLElement;
            element.click();
            this.alert.success(res.msg);
            tree?.update();
            setTimeout(() => {
              let currentNode = tree.getNodeById(res?.active_code);
              console.log(currentNode);
              currentNode.setActiveAndVisible();
              currentNode.expand();
              this.treeAction(tree, "view");
            }, 200);
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
          this.spinner.hide();
        }
      )
    );
  }
  public account_view_data(account_masters_id) {
    this.viewdata = [];
    this.form_show_status = "VIEW";
    this.spinner.show();
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          [],
          "amview/" + this.branch_id + "/" + account_masters_id
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.viewdata = res.records;
            } else {
              this.viewdata = [];
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }
  public update_account_master(tree) {
    let hold_account_from_transaction =
      this.accountmasterform.get("hold_account_from_transaction").value == true
        ? "1"
        : "0";
    let set_profit_loss_account =
      this.accountmasterform.get("set_profit_loss_account").value == true
        ? "1"
        : "0";
    let data = this.ds.json2formdata({
      ...this.accountmasterform.value,
      hold_account_from_transaction: hold_account_from_transaction,
      set_profit_loss_account: set_profit_loss_account,
    });
    data.set("branch_id", this.branch_id);
    data.set("finance_id", this.finance_id);
    this.spinner.show();
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          data,
          "amupdate/" + this.accountmasterform.get("account_masters_id").value
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.nodes = res.records;
              let element: HTMLElement = document.getElementById(
                "btnSearch"
              ) as HTMLElement;
              element.click();
              this.alert.success(res.msg);
              tree?.update();
              setTimeout(() => {
                let currentNode = tree.getNodeById(res?.active_code);
                console.log(currentNode);
                currentNode.setActiveAndVisible();
                currentNode.expand();
                this.treeAction(tree, "view");
              }, 200);
            } else {
              this.alert.error(res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }

  getFinancialList() {
    this.ds.get("financial/" + this.branch_id).subscribe(
      (res) => {
        if (res?.status) {
          this.financeList = res?.records;
        } else {
          this.sweetAlert.errorToast(res?.error, 2000);
        }
      },
      (err) => {
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          2000
        );
      }
    );
  }
}
