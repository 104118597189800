<div class="mnabrDialog">
    <h1 class="mattitle">{{title}} <mat-icon class="dialogicon" style="background-color: #ff0000 !important;" matTooltip="{{'lang_close' | language}}" (click)="close()">close</mat-icon></h1>
    <div class="matbody">
        <div class="row">
            <div class="col-12">
                {{message}}
            </div>
        </div>
        <hr class="hrclass">
        <div class="row">
            <div class="col-6">
                <button mat-raised-button mat-dialog-close class="mnabrbutton" tabindex="-1">{{'lang_cancel' | language}}</button>
            </div>
            <div class="col-6">
                <button mat-raised-button class="mnabrbutton" (click)="confirm()" tabindex="1">{{'lang_yes' | language}}</button>
            </div>
        </div>
    </div>
</div>