<br>
<br>
<br>
<div class="themesection panel-expansion">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12 mar0auto">
                <mat-accordion class="appforgotpassword" >
                    <mat-expansion-panel class="mpanel" [expanded]="true">
                        <mat-expansion-panel-header class="mpanel-header">{{'lang_forgot_password' | language }}</mat-expansion-panel-header>
                        <div class="material-panel-body">
                            <div class="mbrl15 martb0">
                                <form [formGroup]="form" class="editForm" (ngSubmit)="submit();">
                                    <div class="form-group">
                                        <label for="user_username">{{'lang_username' | language }} : <span class="red">*</span></label>
                                        <input formControlName="user_username" type="text" placeholder="{{'lang_username' | language }}" class="form-control almnabrformcontrol"/>
                                        <mat-error *ngIf="form.controls['user_username'].touched && form.controls['user_username'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                        <mat-error *ngIf="form.controls['user_username'].errors?.minlength"> {{'lang_minimum' | language }} 6 </mat-error>
                                    </div>
                                    <div class="form-group">
                                        <label for="user_email">{{'lang_email_address' | language }} : <span class="red">*</span></label>
                                        <input formControlName="user_email" type="text" placeholder="{{'lang_email_address' | language }}" class="form-control almnabrformcontrol"/>
                                        <mat-error *ngIf="form.controls['user_email'].touched && form.controls['user_email'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                        <mat-error *ngIf="form.controls['user_email'].errors?.email"> {{'lang_field_valid_email' | language }} </mat-error>
                                    </div>
                                    <div class="row" *ngIf="form.valid">
                                        <div class="col-md-3 col-xs-12"></div>
                                        <div class="col-md-6 col-xs-12 mt-2">
                                            <button class="albutton">{{'lang_submit' | language }}</button>
                                        </div>
                                        <div class="col-md-3 col-xs-12"></div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer" *ngIf="form.valid">
                                <app-alert id="default-alert"></app-alert>
                            </div>
                            <div class="card-footer padb0">
                                <div class="row text-center">
                                    <div class="col-md-6 mb-10">
                                        <a routerLink="/signup">{{'lang_join_almnabr' | language }}</a>
                                    </div>
                                    <div class="col-md-6 mb-10">
                                        <a routerLink="/auth/login">{{'lang_signin' | language }}</a>
                                    </div>
                                </div>
                                <div class="row text-center">
                                    <div class="col-md-6 mb-10">
                                        <a routerLink="/status">{{'lang_check_app_status' | language }}</a>
                                    </div>
                                    <div class="col-md-6 mb-10">
                                        <a routerLink="/reset">{{'lang_reset_login' | language }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>