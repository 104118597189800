import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { QcAddEditComponent } from "./qc-add-edit/qc-add-edit.component";

@Component({
	selector: "app-quotation-conditions",
	templateUrl: "./quotation-conditions.component.html",
	styleUrls: ["./quotation-conditions.component.scss"],
})
export class QuotationConditionsComponent implements OnInit {
	searchKey = "";
	pageno = 1;
	pagesize = "10";
	collectionSize: any;
	conditionsList = [];
	nodata: string = this.lang.transform("lang_loading");
	constructor(
		private ds: DataService,
		private spinner: PreloaderService,
		private lang: LanguagePipe,
		private sweetAlert: SweetAlertService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.getConditionsList();
	}

	getConditionsList() {
		this.spinner.show();
		// let formData = new FormData();
		// formData.append("search_key", this.searchKey || "");
		this.ds
			.get(`quotation_setting/get_all_conditions`)
			.subscribe((res: any) => {
				this.spinner.hide();
				if (res.status) {
					this.conditionsList = res?.records;
					// this.collectionSize = res?.page?.total_records;
				} else {
					this.conditionsList = [];
					// this.collectionSize = 0;
					this.nodata = res.error;
				}
			});
	}
	onAddEditCondition(data?) {
		const dialogRef = this.dialog.open(QcAddEditComponent, {
			width: "500px",
			data: {
				conditions_data: data,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.getConditionsList();
			}
		});
	}

	deleteCondition(id) {
		this.sweetAlert
			.confirmMessage(
				this.lang.transform("lang_are_you_sure"),
				this.lang.transform("lang_yes")
			)
			.then((resConfirm) => {
				if (resConfirm.isConfirmed) {
					this.spinner.show();
					this.ds
						.post(`quotation_setting/delete_condition/${id}`, {})
						.subscribe((res) => {
							this.spinner.hide();
							if (res.status) {
								this.sweetAlert.successToast(
									this.lang.transform("lang_success"),
									2000
								);
								this.getConditionsList();
							} else {
								this.sweetAlert.errorToast(res.error, 2000);
							}
						});
				}
			});
	}
}
