import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-ds-add-group-user",
	templateUrl: "./ds-add-group-user.component.html",
	styleUrls: ["./ds-add-group-user.component.scss"],
})
export class DsAddGroupUserComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	group_data;
	userList = [];
	users = [];
	constructor(
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private spinner: PreloaderService,
		private dialogRef: MatDialogRef<DsAddGroupUserComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.group_data = data?.group_data;
	}

	ngOnInit(): void {}

	search_users(key) {
		this.userList = [];
		if (key && key.term) {
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.userList = res.list;
					}
				});
		}
	}

	addGroupUser() {
		if (this.users?.length) {
			this.spinner.show();
			let param = new FormData();
			param.append("group_id", this.group_data.group_id || "");
			param.append("users", this.users.toString() || "");
			this.ds.post("project_design/add_group_user", param).subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successToast(res.message, 2000);
					this.dialogRef.close(true);
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			});
		}
	}
}
