<div class="row">
	<div
		class="col-md-4 col-sm-4 col-xs-4 mb-10"
		*ngIf="makeAction && !datastatus"
	>
		<i
			class="fa fa-plus fa-icon mnabricon s2icon"
			(click)="emptyform('add'); openModal(contractors)"
			matTooltip="{{ 'lang_add' | language }}"
		></i>
	</div>
	<div
		class="col-md-4 col-sm-4 col-xs-4 mb-10"
		*ngIf="makeAction && datastatus"
	>
		<i
			class="fa fa-pencil-square-o fa-icon mnabricon s2icon"
			(click)="emptyform('edit'); openModal(contractors)"
			matTooltip="{{ 'lang_edit' | language }}"
		></i>
	</div>
	<div
		class="col-md-4 col-sm-4 col-xs-4 mb-10"
		*ngIf="makeAction && datastatus"
	>
		<i
			class="fa fa-trash red fa-icon s2icon"
			(click)="remove_contractor()"
			matTooltip="{{ 'lang_delete' | language }}"
		></i>
	</div>
</div>
<div class="maindata" *ngIf="datastatus">
	<div class="row">
		<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
			<label>{{ "lang_contractor_name_english" | language }}</label>
			<div>
				<b>{{ contractordata.contractor_title_en }}</b>
			</div>
		</div>
		<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
			<label>{{ "lang_contractor_name_arabic" | language }}</label>
			<div>
				<b>{{ contractordata.contractor_title_ar }}</b>
			</div>
		</div>
		<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
			<label>{{ "lang_mobile_number" | language }}</label>
			<div>
				<b>{{ contractordata.contractor_mobile }}</b>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
			<label>{{ "lang_email_address" | language }}</label>
			<div>
				<b>{{ contractordata.contractor_email_address }}</b>
			</div>
		</div>
		<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
			<label>{{ "lang_ondate" | language }}</label>
			<div>
				<b>{{ contractordata.contractor_group_created_datetime }}</b>
			</div>
		</div>
		<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
			<label>{{ "lang_writer" | language }}</label>
			<div>
				<b>{{ contractordata.writer }}</b>
			</div>
		</div>
	</div>
</div>
<div *ngIf="datastatus">
	<app-contractor-users
		[supervision_data]="supervision_data"
		[contractortype]="contractortype"
		[makeAction]="makeAction"
		[able2add]="able2add"
		[able2edit]="able2edit"
		[project_data]="project_data"
		[contractor_id]="contractordata.contractor_group_contractor_id"
	></app-contractor-users>
</div>
<ng-template #contractors>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_contractors" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div class="modal-body">
				<div class="form-group">
					<label for="contractor_id"
						>{{ "lang_contractors" | language }}:<span class="red"
							>*</span
						></label
					>
					<ng-select
						[items]="contractorsList"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						formControlName="contractor_id"
						placeholder="{{ 'lang_contractors' | language }}"
					>
					</ng-select>
					<mat-error
						*ngIf="
							form.controls['contractor_id'].touched &&
							form.controls['contractor_id'].errors?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
			</div>
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
