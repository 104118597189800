<fieldset
  class="cardback viewquotation fieldset mb-10 mt-3"
  [ngClass]="{ ' contentrtl': lang_key == 'ar' }"
>
  <legend>{{ "lang_settlement" | language }}</legend>
  <div class="row mb-10">
    <div class="contentbodypanel width100 mt-0">
      <div class="col-12 contentpanel">
        <div class="itemsdata">
          <label> {{ "lang_info" | language }} : </label>
          <div class="TableParts">
            <div
              class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
            >
              <table class="table CustomTables text-center">
                <tbody class="AppendList quotationtable">
                  <tr>
                    <td class="bold">
                      {{ "lang_subject" | language }}
                    </td>
                    <td>
                      {{ formSTEData?.main_data?.subject }}
                    </td>
                    <td class="bold">
                      {{ "lang_termination_key" | language }}
                    </td>
                    <td>
                      {{ formSTEData?.main_data?.transaction_key }}
                    </td>
                    <td class="bold">
                      {{ "lang_rating_degree" | language }}
                    </td>
                    <td>
                      {{ formSTEData?.main_data?.rating_degree }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bold">
                      {{ "lang_employee_name" | language }}
                    </td>
                    <td *ngIf="lang_key == 'ar'">
                      {{ formSTEData?.main_data?.settings_name_arabic
                      }}{{ " " + formSTEData?.main_data?.firstname_arabic
                      }}{{ " " + formSTEData?.main_data?.lastname_arabic
                      }}{{ " " + formSTEData?.main_data?.thirdname_arabic }}
                    </td>
                    <td *ngIf="lang_key == 'en'">
                      {{ formSTEData?.main_data?.settings_name_english
                      }}{{ " " + formSTEData?.main_data?.firstname_english
                      }}{{ " " + formSTEData?.main_data?.lastname_english
                      }}{{ " " + formSTEData?.main_data?.thirdname_english }}
                    </td>
                    <td class="bold">
                      {{ "lang_branch_name" | language }}
                    </td>
                    <td>
                      {{ formSTEData?.main_data?.branch }}
                    </td>
                    <td class="bold">
                      {{ "lang_job_descriptions" | language }}
                    </td>
                    <td *ngIf="lang_key == 'ar'">
                      {{ formSTEData?.main_data?.job_descriptions }}
                    </td>
                    <td *ngIf="lang_key == 'en'">
                      {{ formSTEData?.main_data?.job_title }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bold">
                      {{ "lang_amount" | language }}
                    </td>
                    <td>
                      {{ formSTEData?.main_data?.amount }}
                    </td>
                    <td class="bold">
                      {{ "lang_notes" | language }}
                    </td>

                    <td>
                      {{ formSTEData?.main_data?.notes }}
                    </td>

                    <td class="bold">
                      {{ "lang_employee_vocation_status" | language }}
                    </td>
                    <td>
                      {{ formSTEData?.main_data?.employee_vocation_status }}
                    </td>
                  </tr>
                  <tr [ngSwitch]="formSTEData?.main_data?.work_type">
                    <td class="bold">
                      {{ "lang_work_domain" | language }}
                    </td>
                    <td>
                      {{ formSTEData?.main_data?.work_domain }}
                    </td>

                    <td class="bold">
                      {{ "lang_work_location" | language }}
                    </td>
                    <td>
                      {{ formSTEData?.main_data?.work_location }}
                    </td>

                    <td class="bold">
                      {{ "lang_work_type" | language }}
                    </td>
                    <td *ngSwitchCase="'1'">
                      {{ "lang_fulltime" | language }}
                    </td>
                    <td *ngSwitchCase="'2'">
                      {{ "lang_parttime" | language }}
                    </td>
                    <td *ngSwitchCase="'3'">
                      {{ "lang_contract" | language }}
                    </td>
                    <td *ngSwitchCase="'4'">
                      {{ "lang_others" | language }}
                    </td>
                    <td *ngSwitchDefault>---</td>
                  </tr>
                  <tr>
                    <td class="bold">
                      {{ "lang_birth_date_arabic" | language }}
                    </td>
                    <td>
                      {{ formSTEData?.main_data?.birth_date_arabic }}
                    </td>
                    <td class="bold">
                      {{ "lang_birth_date_english" | language }}
                    </td>
                    <td>
                      {{ formSTEData?.main_data?.birth_date_english }}
                    </td>

                    <td class="bold">
                      {{ "lang_created_datetime" | language }}
                    </td>
                    <td>
                      {{ formSTEData?.main_data?.created_datetime }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</fieldset>
