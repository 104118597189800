<div class="p-2">
	<div class="row">
		<div class="col-12 mb-3">
			<div class="d-flex justify-content-end">
				<button class="add-attachment-btn" (click)="add_update_component()">
					<i class="pi pi-plus"></i>
					<span>{{ "lang_add" | language }}</span>
				</button>
			</div>
		</div>
		<div class="col-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>Name English</td>
							<td>Name Arabic</td>
							<td>Quantity</td>
							<td>Level Quantity</td>
							<td>Estimated Space</td>
							<td>Writer</td>
							<td>Created Date</td>
							<td>Action</td>
						</tr>
					</thead>
					<tbody *ngIf="contract_components?.length" class="AppendList">
						<ng-container
							*ngFor="
								let list of contract_components;
								let i = index;
								let odd = odd;
								let even = even
							"
						>
							<tr>
								<td>{{ i + 1 }}</td>
								<td>{{ list.component_building_name_english }}</td>
								<td>{{ list.component_building_name_arabic }}</td>
								<td>{{ list.component_building_quantity }}</td>
								<td>{{ list.component_level_quantity }}</td>
								<td>{{ list.component_estimated_space }}</td>
								<td>{{ list.user_username }}</td>
								<td>{{ list.insert_date }}</td>
								<td>
									<i
										class="fa fa-pencil-square-o mnabricon mr-1 ml-1"
										(click)="add_update_component(list)"
										matTooltip="{{ 'lang_edit' | language }}"
									></i>
									<i
										class="fa fa-trash red mr-1 ml-1"
										(click)="deleteComponent(list?.project_component_id)"
										matTooltip="{{ 'lang_delete' | language }}"
									></i>
								</td>
							</tr>
						</ng-container>
					</tbody>
					<tbody class="AppendList" *ngIf="!contract_components?.length">
						<tr>
							<td colspan="9">{{ "lang_no_data" | language }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
