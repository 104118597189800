import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";
import { ConfirmCpayCreateComponent } from "./confirm-cpay-create/confirm-cpay-create.component";
import { PaymentFiltersComponent } from "./payment-filters/payment-filters.component";

@Component({
	selector: "app-contract-payment-create",
	templateUrl: "./contract-payment-create.component.html",
	styleUrls: ["./contract-payment-create.component.scss"],
})
export class ContractPaymentCreateComponent implements OnInit, OnDestroy {
	lang_key = localStorage.getItem("lang_key") || "en";
	@Input() supervision_data;
	@Input() project_data;
	@Input() makeActionList;
	tableContent: any = null;
	show_ok = true;
	show_notok = true;
	show_notuse = true;
	cpay_filters: any;
	isLoading = false;
	contractor_manager_step_require = "0";
	apiSubscriptions = new Subscription();
	constructor(
		private ds: DataService,
		private dialog: MatDialog,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private router: Router,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.getBuildData();
	}

	//start get build data
	getBuildData() {
		this.tableContent = null;
		this.isLoading = true;
		let formData = new FormData();
		formData.append(
			"projects_profile_id",
			this.project_data.projects_profile_id
		);
		formData.append(
			"projects_work_area_id",
			this.supervision_data.projects_work_area_id
		);
		formData.append(
			"projects_supervision_id",
			this.supervision_data.projects_supervision_id
		);
		formData.append("unit_ids", this.cpay_filters?.unit_ids?.toString() || "");
		formData.append(
			"title_ids",
			this.cpay_filters?.title_ids?.toString() || ""
		);
		formData.append(
			"sub_title_ids",
			this.cpay_filters?.sub_title_ids?.toString() || ""
		);
		formData.append(
			"template_ids",
			this.cpay_filters?.template_ids?.toString() || ""
		);
		formData.append(
			"platform_code_systems",
			this.cpay_filters?.platform_code_systems?.toString() || ""
		);
		formData.append(
			"platform_work_level_keys",
			this.cpay_filters?.platform_work_level_keys?.toString() || ""
		);
		formData.append(
			"platform_group1_code_systems",
			this.cpay_filters?.platform_group1_code_systems?.toString() || ""
		);
		formData.append(
			"platform_group_type_code_systems",
			this.cpay_filters?.platform_group_type_code_systems?.toString() || ""
		);
		formData.append(
			"platform_group2_code_systems",
			this.cpay_filters?.platform_group2_code_systems?.toString() || ""
		);
		formData.append(
			"phase_zone_nos",
			this.cpay_filters?.phase_zone_nos?.phase_zone_no || ""
		);
		formData.append(
			"phase_zone_block_nos",
			this.cpay_filters?.phase_zone_block_nos?.phase_zone_block_no || ""
		);
		formData.append(
			"phase_zone_block_cluster_nos",
			this.cpay_filters?.phase_zone_block_cluster_nos
				?.phase_zone_block_cluster_no || ""
		);
		formData.append(
			"transaction_date_start",
			this.cpay_filters?.transaction_date_start || ""
		);
		formData.append(
			"transaction_date_end",
			this.cpay_filters?.transaction_date_end || ""
		);
		formData.append("submit_data", "0");
		formData.append(
			"pending_levels",
			this.cpay_filters?.pending_levels ? "1" : "0"
		);
		formData.append("paid_levels", this.cpay_filters?.paid_levels ? "1" : "0");
		this.apiSubscriptions.add(
			this.ds
				.getActionByUrl(
					this.ds.formData2string(formData),
					"form/FORM_CPAY/build_data_for_excel_operations"
				)
				.subscribe(
					(res) => {
						this.isLoading = false;
						if (res?.status) {
							this.tableContent = res?.data;
						} else {
							this.tableContent = null;
						}
					},
					(err) => {
						this.isLoading = false;
						this.tableContent = null;
						this.sweetAlert.errorToast(
							this.lang.transform("lang_internal_server_error"),
							2000
						);
					}
				)
		);
	}
	//end get build data

	//open filters
	onFilters() {
		const dialogRef = this.dialog.open(PaymentFiltersComponent, {
			width: "600px",
			autoFocus: false,
			data: {
				oldFilters: this.cpay_filters,
				projects_work_area_id: this.supervision_data.projects_work_area_id,
				projects_profile_id: this.project_data.projects_profile_id,
				projects_supervision_id: this.supervision_data.projects_supervision_id,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.cpay_filters = res;
				this.getBuildData();
			}
		});
	}
	//on create contract payment
	onCreate() {
		const dialogRef = this.dialog.open(ConfirmCpayCreateComponent, {
			width: "500px",
			autoFocus: false,
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res?.status) {
				this.contractor_manager_step_require =
					res?.contractor_manager_step_require;
				this.createForm();
			}
		});
	}

	createForm() {
		this.spinner.show();
		let formData = new FormData();
		formData.append(
			"projects_profile_id",
			this.project_data.projects_profile_id
		);
		formData.append(
			"projects_work_area_id",
			this.supervision_data.projects_work_area_id
		);
		formData.append(
			"projects_supervision_id",
			this.supervision_data.projects_supervision_id
		);
		formData.append("lang_key", this.lang_key);
		formData.append(
			"contractor_manager_step_require",
			this.contractor_manager_step_require || ""
		);
		formData.append("unit_ids", this.cpay_filters?.unit_ids?.toString() || "");
		formData.append(
			"title_ids",
			this.cpay_filters?.title_ids?.toString() || ""
		);
		formData.append(
			"sub_title_ids",
			this.cpay_filters?.sub_title_ids?.toString() || ""
		);
		formData.append(
			"template_ids",
			this.cpay_filters?.template_ids?.toString() || ""
		);
		formData.append(
			"platform_code_systems",
			this.cpay_filters?.platform_code_systems?.toString() || ""
		);
		formData.append(
			"platform_work_level_keys",
			this.cpay_filters?.platform_work_level_keys?.toString() || ""
		);
		formData.append(
			"platform_group1_code_systems",
			this.cpay_filters?.platform_group1_code_systems?.toString() || ""
		);
		formData.append(
			"platform_group_type_code_systems",
			this.cpay_filters?.platform_group_type_code_systems?.toString() || ""
		);
		formData.append(
			"platform_group2_code_systems",
			this.cpay_filters?.platform_group2_code_systems?.toString() || ""
		);
		formData.append(
			"phase_zone_nos",
			this.cpay_filters?.phase_zone_nos?.phase_zone_no || ""
		);
		formData.append(
			"phase_zone_block_nos",
			this.cpay_filters?.phase_zone_block_nos?.phase_zone_block_no || ""
		);
		formData.append(
			"phase_zone_block_cluster_nos",
			this.cpay_filters?.phase_zone_block_cluster_nos
				?.phase_zone_block_cluster_no || ""
		);
		formData.append(
			"transaction_date_start",
			this.cpay_filters?.transaction_date_start || ""
		);
		formData.append(
			"transaction_date_end",
			this.cpay_filters?.transaction_date_end || ""
		);
		formData.append("print_custom_title", "");
		formData.append("print_custom_system_unit_title", "");
		formData.append("print_template", "");
		this.apiSubscriptions.add(
			this.ds.post("form/FORM_CPAY/create_request", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast("lang_success", 2000);
						this.router.navigate(["/tasks/alltasks"]);
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast("lang_internal_server_error", 2000);
				}
			)
		);
	}
	scrollLeft() {
		document.getElementById("table-container").scrollLeft -= 150;
	}
	scrollRight() {
		document.getElementById("table-container").scrollLeft += 150;
	}
	ngOnDestroy() {
		this.apiSubscriptions.unsubscribe();
	}
}
