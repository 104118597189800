import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddEditGroupComponent } from "./add-edit-group/add-edit-group.component";
import { DsAddGroupUserComponent } from "./ds-add-group-user/ds-add-group-user.component";
import { DsViewGroupComponent } from "./ds-view-group/ds-view-group.component";

@Component({
	selector: "app-ds-groups",
	templateUrl: "./ds-groups.component.html",
	styleUrls: ["./ds-groups.component.scss"],
})
export class DSGroupsComponent implements OnInit {
	searchKey = "";
	pageno = 1;
	pagesize = "10";
	collectionSize: any;
	groupsList = [];
	nodata: string = this.lang.transform("lang_loading");
	constructor(
		private ds: DataService,
		private spinner: PreloaderService,
		private lang: LanguagePipe,
		private sweetAlert: SweetAlertService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.getGroupsList();
	}

	getGroupsList() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("search_key", this.searchKey || "");
		this.ds
			.post(
				`project_design/data_groups/${this.pageno}/${this.pagesize}`,
				formData
			)
			.subscribe((res: any) => {
				this.spinner.hide();
				if (res.status) {
					this.groupsList = res?.data;
					this.collectionSize = res?.page?.total_records;
				} else {
					this.groupsList = [];
					this.collectionSize = 0;
					this.nodata = res.error;
				}
			});
	}
	onAddEditGroup(data?) {
		const dialogRef = this.dialog.open(AddEditGroupComponent, {
			width: "500px",
			data: {
				group_data: data,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.getGroupsList();
			}
		});
	}
	onViewGroup(data?) {
		const dialogRef = this.dialog.open(DsViewGroupComponent, {
			width: "700px",
			data: {
				group_data: data,
			},
		});
	}

	deleteGroup(id) {
		this.sweetAlert
			.confirmMessage(
				this.lang.transform("lang_are_you_sure"),
				this.lang.transform("lang_yes")
			)
			.then((resConfirm) => {
				if (resConfirm.isConfirmed) {
					this.spinner.show();
					this.ds
						.post(`project_design/delete_group/${id}`, {})
						.subscribe((res) => {
							this.spinner.hide();
							if (res.status) {
								this.sweetAlert.successToast(res.message, 2000);
								this.getGroupsList();
							} else {
								this.sweetAlert.errorToast(res.error, 2000);
							}
						});
				}
			});
	}

	onAddUserGroup(data) {
		const dialogRef = this.dialog.open(DsAddGroupUserComponent, {
			width: "500px",
			data: {
				group_data: data,
			},
		});
	}
}
