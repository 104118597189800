<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_units" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-12 col-sm-6 mb-3">
			<label for="unit_type">{{ "lang_units" | language }} </label>
			<ng-select
				[items]="work_type_list"
				[multiple]="false"
				bindLabel="label"
				appendTo="body"
				bindValue="value"
				[clearable]="false"
				[(ngModel)]="unit_type"
				placeholder="{{ 'lang_units' | language }}"
				id="unit_type"
				name="unit_type"
				(change)="getAcceptedUnacceptedUnits()"
			>
			</ng-select>
		</div>
		<div class="col-12 col-sm-6 mb-3">
			<label for="blocks">{{ "lang_blocks" | language }} </label>
			<ng-select
				[items]="work_site_blocks_list"
				[multiple]="true"
				bindLabel="label"
				appendTo="body"
				bindValue="value"
				clearAllText="Clear"
				[(ngModel)]="work_site_blocks"
				placeholder="{{ 'lang_blocks' | language }}"
				[searchFn]="ds.customSearchFn"
				id="blocks"
				name="blocks"
				(change)="getAcceptedUnacceptedUnits()"
			>
			</ng-select>
		</div>
	</div>
	<div class="customResponsive tablefields" *ngIf="units?.length">
		<table border="1" class="table CustomTable">
			<thead>
				<tr>
					<th>#</th>
					<th>{{ "lang_units" | language }}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="
						let item of units;
						let i = index;
						let odd = odd;
						let even = even
					"
				>
					<td>{{ i + 1 }}</td>
					<td>{{ item?.label || item?.unit_id }}</td>
				</tr>
			</tbody>
		</table>
		<div class="row responsive_pagination mt-3">
			<div
				class="col-lg-3 col-sm-3 mb-10"
				style="height: fit-content; margin: auto 0"
			>
				{{ "lang_total" | language }} :
				{{ total_records }}
			</div>
			<div
				class="col-lg-6 col-sm-6 mb-10"
				style="display: flex; justify-content: center"
			>
				<ngb-pagination
					class="m-auto"
					[collectionSize]="total_records"
					[rotate]="true"
					[ellipses]="false"
					[maxSize]="3"
					[boundaryLinks]="true"
					[(page)]="page_no"
					[pageSize]="page_size"
					(pageChange)="getAcceptedUnacceptedUnits()"
				></ngb-pagination>
			</div>
			<div
				class="col-lg-3 col-sm-3 mb-10"
				style="display: flex; justify-content: end; margin: auto"
			>
				<mat-select
					[(ngModel)]="page_size"
					(selectionChange)="getAcceptedUnacceptedUnits()"
				>
					<mat-option class="text-center" [value]="10"
						>10 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" [value]="20"
						>20 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" [value]="50"
						>50 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" [value]="100"
						>100 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option class="text-center" [value]="500"
						>500 {{ "lang_per_page" | language }}</mat-option
					>
				</mat-select>
			</div>
		</div>
	</div>
	<div class="mt-3 mb-3 text-center" *ngIf="unit_msg && !units?.length">
		<p style="font-size: 18px">{{ unit_msg }}</p>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</mat-dialog-actions>
