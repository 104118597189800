import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

@Component({
	selector: "app-filters-model",
	templateUrl: "./filters-model.component.html",
	styleUrls: ["./filters-model.component.scss"],
})
export class FiltersModelComponent implements OnInit {
	metaData: any = [];
	admin: any = JSON.parse(this.ls.getItem("is_admin"));
	lang_key = localStorage.getItem("lang_key")
		? localStorage.getItem("lang_key")
		: "en";
	filterForm: FormGroup = this.fb.group({
		ticket_no: null,
		ticket_type: null,
		start_date_task: null,
		end_date_task: null,
		start_date_ticket: null,
		end_date_ticket: null,
		task_no: null,
		important_task: null,
	});
	filteradmin_list = [
		{
			id: "1",
			name: this.lang.transform("lang_users"),
		},
		{
			id: "2",
			name: this.lang.transform("lang_admin"),
		},
	];
	formFromLocalStorage: any;

	lastValues;

	constructor(
		public dialogRef: MatDialogRef<FiltersModelComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private lang: LanguagePipe,
		public ls: LocalStorage
	) {}

	ngOnInit(): void {
		this.metaData = this.data.metaData;
		this.lastValues = this.data.lastValues;

		this.buildForm();
	}

	buildForm() {
		this.filterForm = this.fb.group({
			ticket_no: this.lastValues.ticket_no,
			ticket_type: this.lastValues.ticket_type,
			start_date_task: this.lastValues.start_date_task,
			end_date_task: this.lastValues.end_date_task,
			start_date_ticket: this.lastValues.start_date_ticket,
			end_date_ticket: this.lastValues.end_date_ticket,
			task_no: this.lastValues.task_no,
			important_task: this.lastValues.important_task,
		});
	}

	sendFilterForm() {
		this.dialogRef.close(this.filterForm?.value);
	}
	resetFilterForm() {
		this.filterForm.reset();
		this.dialogRef.close(this.filterForm?.value);
	}
}
