<div mat-dialog-title class="d-flex justify-content-between">
    <h4>{{'lang_edit' | language}} {{'lang_comment' | language}}</h4>
    <i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="comment_form">
        <div class="p-2">
            <div class="row mb-3">
                <div class="col-12 mb-2" [dir]="lang_key == 'ar'? 'rtl':'ltr'">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{'lang_comment' | language}}</mat-label>
                        <textarea name="" id="" rows="3" matInput placeholder="{{'lang_comment' | language}}" formControlName="comment_description"></textarea>
                        <i class="fa fa-ticket mnabricon" matSuffix></i>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="col-0 col-sm-9"></div>
    <div class="col-12 col-sm-3">
        <button mat-button class="albutton mnabrbutton mat-accent" (click)="update()">{{'lang_submit' | language}}</button>
    </div>
</mat-dialog-actions>

