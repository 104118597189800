import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@core/bootstrap/data.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DwsrService {
  data: Subject<Object> = new Subject<Object>();
  allData:any;
  form_key;
  form_request_id;
  constructor(public ds: DataService,) {
    
  }

  getData(form_request_id?) {
    if(this.form_request_id || form_request_id) {
      return this.ds.get(`form/${this.form_key}/vr/${this.form_request_id}`);
    }
    
  }

  refinementData(data) {
    const allData = data;
    let allLevels = [];
    allData.forEach(element => {
      allLevels.push(...element.work_levels.split(','));
    });
    allLevels = allLevels.filter(this.onlyUnique);
    
    const ob = {};
    allLevels.forEach((element) => {
      allData.forEach(el => {
        if(el.work_levels.includes(element)) {
          ob[element] += `,${el.units}`;
        }
      });
    })

    for(let key in ob) {
      ob[key] = ob[key].replace('undefined,','');
      let value = ob[key].split(',')
      value = value.filter(this.onlyUnique);
      ob[key] = value.toString();
    }
    return ob;
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
}
