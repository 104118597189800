<breadcrumb></breadcrumb>
<div class="themesection">
  <section class="panel-expansion matborderno">
    <mat-accordion class="accounts_masters">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_edit_journal_voucher" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <form [formGroup]="form" (ngSubmit)="update_journal()" class="form">
            <input type="hidden" formControlName="branch_id" />
            <div class="mbrl15">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                  <div class="form-group">
                    <label
                      >{{ "lang_journal_date" | language }} :<span class="red"
                        >*</span
                      ></label
                    >
                    <div class="input-group input-group-sm">
                      <input
                        [matDatepicker]="jvd"
                        formControlName="journal_voucher_date"
                        class="form-control almnabrformcontrol"
                        placeholder="YYYY/MM/DD"
                        readonly
                      />
                      <span class="input-group-btn">
                        <mat-datepicker-toggle
                          class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                          matSuffix
                          [for]="jvd"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #jvd></mat-datepicker>
                      </span>
                      <span class="input-group-btn">
                        <button
                          (click)="
                            form.get('journal_voucher_date').setValue('')
                          "
                          matTooltip="{{ 'lang_empty' | language }}"
                          type="button"
                          class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
                        ></button>
                      </span>
                    </div>
                    <mat-error
                      *ngIf="
                        form.controls['journal_voucher_date'].touched &&
                        form.controls['journal_voucher_date'].errors?.required
                      "
                    >
                      {{ "lang_field_required" | language }}</mat-error
                    >
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                  <div class="form-group">
                    <label
                      >{{ "lang_journal_no" | language }} :<span class="red"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      formControlName="journal_voucher_id"
                      class="form-control almnabrformcontrol"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                  <div class="form-group">
                    <label
                      >{{ "lang_debit_total" | language }} :<span class="red"
                        >*</span
                      ></label
                    >
                    <input
                      class="form-control almnabrformcontrol"
                      formControlName="journal_voucher_debit_total"
                      placeholder="{{ 'lang_debit_total' | language }}"
                      readonly
                    />
                    <mat-error
                      *ngIf="
                        form.controls['journal_voucher_debit_total'].touched &&
                        form.controls['journal_voucher_debit_total'].errors
                          ?.required
                      "
                      >{{ "lang_field_required" | language }}</mat-error
                    >
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                  <div class="form-group">
                    <label
                      >{{ "lang_credit_total" | language }} :<span class="red"
                        >*</span
                      ></label
                    >
                    <input
                      class="form-control almnabrformcontrol"
                      formControlName="journal_voucher_credit_total"
                      placeholder="{{ 'lang_credit_total' | language }}"
                      readonly
                    />
                    <mat-error
                      *ngIf="
                        form.controls['journal_voucher_credit_total'].touched &&
                        form.controls['journal_voucher_credit_total'].errors
                          ?.required
                      "
                      >{{ "lang_field_required" | language }}</mat-error
                    >
                  </div>
                </div>
                <div
                  class="col-md-4 col-sm-4 col-xs-12 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0'
                  "
                >
                  <div class="form-group">
                    <label
                      >{{ "lang_finance" | language }} :<span class="red"
                        >*</span
                      >
                    </label>
                    <ng-select
                      [items]="financeList"
                      [multiple]="false"
                      bindLabel="label"
                      bindValue="value"
                      clearAllText="Clear"
                      [searchFn]="ds.customSearchFn"
                      appendTo="body"
                      formControlName="finance_id"
                      placeholder="{{ 'lang_finance' | language }}"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="TableParts">
                <div
                  class="table-responsive customResponsive mb-10 tablefields"
                >
                  <table border="1" class="table CustomTable text-center">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>
                          <i
                            class="fa fa-plus-square fa-icon"
                            matTooltip="{{ 'lang_add' | language }}"
                            (click)="
                              add_journal_records(); calculateTotalDebitCredit()
                            "
                          ></i>
                        </td>
                        <td width="15%">
                          {{ "lang_accounts_acccode" | language }}
                          <i class="red">*</i>
                        </td>
                        <td>
                          {{ "lang_debit_amount" | language }}
                          <i class="red">*</i>
                        </td>
                        <td>
                          {{ "lang_credit_amount" | language }}
                          <i class="red">*</i>
                        </td>
                        <td>
                          {{ "lang_description" | language }}
                        </td>
                        <td width="15%">
                          {{ "lang_cost_center_code" | language }}
                        </td>
                        <td>{{ "lang_reference_no" | language }}</td>
                        <td>{{ "lang_notes" | language }}</td>
                        <td>{{ "lang_tags" | language }}</td>
                      </tr>
                    </thead>
                    <tbody formArrayName="journal_records" class="AppendList">
                      <tr
                        *ngFor="
                          let item of journal_records_group().controls;
                          let itemIndex = index;
                          let odd = odd;
                          let even = even
                        "
                        [formGroupName]="itemIndex"
                      >
                        <td class="verticalalignmid">
                          {{ itemIndex + 1 }}
                        </td>
                        <td class="verticalalignmid">
                          <i
                            (click)="
                              removeJournal(itemIndex);
                              calculateTotalDebitCredit()
                            "
                            class="fa fa-minus-square fa-icon red mnabricon"
                            matTooltip="{{ 'lang_delete' | language }}"
                          ></i>
                        </td>
                        <td>
                          <ng-select
                            [items]="accountData"
                            [multiple]="false"
                            bindLabel="label"
                            bindValue="value"
                            clearAllText="Clear"
                            [searchFn]="ds.customSearchFn"
                            appendTo="body"
                            formControlName="account_masters_id"
                            placeholder="{{
                              'lang_accounts_acccode' | language
                            }}"
                            (search)="
                              searchAccounts(
                                $event,
                                validate.controls[itemIndex]
                              )
                            "
                            (focus)="accountData = []"
                            (change)="calculateTotalDebitCredit()"
                          >
                          </ng-select>
                          <mat-error
                            *ngIf="
                              validate.controls[itemIndex].get(
                                'account_masters_id'
                              ).touched &&
                              validate.controls[itemIndex].get(
                                'account_masters_id'
                              ).errors?.required
                            "
                          >
                            {{ "lang_field_required" | language }}
                          </mat-error>
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="0.00"
                            formControlName="debit_amount"
                            class="form-control almnabrformcontrol debit_amount"
                            (keypress)="
                              validate.controls[itemIndex]
                                .get('credit_amount')
                                .setValue('0.00')
                            "
                            (change)="
                              ds.CheckDecimal(
                                $event.target.value,
                                validate.controls[itemIndex].get('debit_amount')
                              );
                              calculateTotalDebitCredit()
                            "
                          />
                        </td>
                        <td>
                          <input
                            pattern="[0-9]+([,\.][0-9]+)?"
                            type="text"
                            placeholder="0.00"
                            formControlName="credit_amount"
                            class="form-control almnabrformcontrol credit_amount"
                            (keypress)="
                              validate.controls[itemIndex]
                                .get('debit_amount')
                                .setValue('0.00')
                            "
                            (change)="
                              ds.CheckDecimal(
                                $event.target.value,
                                validate.controls[itemIndex].get(
                                  'credit_amount'
                                )
                              );
                              calculateTotalDebitCredit()
                            "
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="{{ 'lang_description' | language }}"
                            formControlName="transaction_history_description"
                            class="form-control almnabrformcontrol transaction_history_description"
                          />
                          <mat-error
                            *ngIf="
                              validate.controls[itemIndex].get(
                                'transaction_history_description'
                              ).touched &&
                              validate.controls[itemIndex].get(
                                'transaction_history_description'
                              ).errors?.required
                            "
                            >{{ "lang_field_required" | language }}</mat-error
                          >
                        </td>
                        <td>
                          <button
                            class="mnabrbutton d-flex justify-content-center align-items-center"
                            type="button"
                            (click)="onAddUpdateCostCenter(itemIndex)"
                          >
                            <i class="pi pi-money-bill mr-2 ml-2"></i>
                            <span>Cost Center</span>
                          </button>
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="{{ 'lang_reference_no' | language }}"
                            formControlName="transaction_history_ref_number"
                            class="form-control almnabrformcontrol transaction_history_ref_number"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="{{ 'lang_notes' | language }}"
                            formControlName="transaction_history_notes"
                            class="form-control almnabrformcontrol transaction_history_notes"
                          />
                        </td>
                        <td>
                          <ng-select
                            [items]="tags"
                            [multiple]="true"
                            bindLabel="transaction_tages"
                            bindValue="transaction_tages"
                            appendTo="body"
                            clearAllText="Clear"
                            formControlName="tags"
                            id="tags"
                            name="tags"
                            placeholder="{{ 'lang_tags' | language }}"
                            addTagText="Add New"
                            [addTag]="addNewTag"
                            (search)="getTags($event)"
                          >
                          </ng-select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer padb0">
              <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                  <button
                    type="button"
                    class="albutton"
                    routerLink="/accounts/jlist"
                  >
                    {{ "lang_goto_list" | language }}
                  </button>
                </div>
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                  <button
                    type="button"
                    class="albutton"
                    routerLink="/accounts/jview/{{ branch_id }}/{{
                      journal_voucher_id
                    }}/{{ finance_id }}/view"
                  >
                    {{ "lang_view" | language }}
                  </button>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                  <app-alert id="default-alert"></app-alert>
                </div>
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                  <button type="submit" class="albutton">
                    {{ "lang_submit" | language }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
