import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService } from "ngx-bootstrap/modal";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
  selector: "app-rstep3",
  templateUrl: "./rstep3.component.html",
  styleUrls: ["./rstep3.component.scss"],
})
export class Rstep3Component implements OnInit {
  id = this.route.snapshot.paramMap.get("id");
  code = this.route.snapshot.paramMap.get("code");
  @Input() signupForm: FormGroup;
  @Input() action;
  @Input() files;
  apiurl = environment.SERVER_ORIGIN;
  public fileurl;
  idfilename = this.lang.transform("lang_select_files");
  etitles: any = [];
  types: any;
  licencesList: FormArray;
  jobLists: any;
  licencesstatus: any;
  isFile: boolean;
  lang_key = localStorage.getItem("lang_key") || "en";
  constructor(
    private cdRef: ChangeDetectorRef,
    public fb: FormBuilder,
    public ds: DataService,
    public route: ActivatedRoute,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public router: Router,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  get f() {
    return this.signupForm.controls.jobDetails;
  }
  get licenceFormGroup() {
    return this.signupForm.get("jobDetails").get("liclists") as FormArray;
  }
  ngOnInit(): void {
    this.jobTitles();
    this.licencesList = this.signupForm
      .get("jobDetails")
      .get("liclists") as FormArray;
    if (this.action === "EDIT") {
      this.loadmylicenses();
    }
  }
  loadmylicenses() {
    this.licencesList.controls = [];
    this.licencesstatus = false;
    this.ds
      .getActionByNoToken(
        [],
        "signup/load_my_licences/" +
          this.id +
          "/" +
          this.code +
          "/" +
          this.lang_key
      )
      .subscribe((data) => {
        this.licencesstatus = data.status;
        if (data.status) {
          data.data.forEach((lic) => {
            this.licencesList.push(this.appendLicences(lic));
          });
        }
      });
  }
  appendLicences(item) {
    return this.fb.group({
      licence_list_id: new FormControl(item.licence_list_id),
      settings_id: new FormControl(item.settings_id),
      licence_name: new FormControl({
        value: item.licence_name,
        disabled: true,
      }),
      licence_number: new FormControl(item.licence_number),
      licence_issue_date_english: new FormControl(
        this.ds.encon(item.licence_issue_date_english)
      ),
      licence_issue_date_arabic: new FormControl(
        this.ds.arcon(item.licence_issue_date_arabic)
      ),
      licence_expiry_date_english: new FormControl(
        this.ds.encon(item.licence_expiry_date_english)
      ),
      licence_expiry_date_arabic: new FormControl(
        this.ds.arcon(item.licence_expiry_date_arabic)
      ),
    });
  }
  jobTitles() {
    this.jobLists = [];
    this.ds
      .getActionByNoToken([], "signup/get_settings/JTIT/" + this.lang_key)
      .subscribe((data) => {
        if (data.status) {
          this.jobLists = data.titles;
        }
      });
  }
  emptyLicenses() {
    this.licencesList.controls = [];
    this.licencesstatus = false;
  }
  createLicences(item): FormGroup {
    return this.fb.group({
      licence_list_id: new FormControl(item.licence_list_id),
      settings_id: new FormControl(item.settings_id),
      licence_name: new FormControl({
        value: item.licence_name,
        disabled: true,
      }),
      licence_number: new FormControl(""),
      licence_issue_date_english: new FormControl("", []),
      licence_issue_date_arabic: new FormControl(null, []),
      licence_expiry_date_english: new FormControl("", []),
      licence_expiry_date_arabic: new FormControl(null, []),
    });
  }
  get_needed_licences(evt) {
    //this.signupForm.get('jobDetails').get('liclists').setValue([]);
    this.licencesList.controls = [];
    this.licencesstatus = false;
    if (evt && evt.value) {
      this.spinner.show();
      this.ds
        .getActionByNoToken(
          [],
          "signup/get_needed_licences/" + evt.value + "/" + this.lang_key
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            this.licencesstatus = data.status;
            if (data.status) {
              data.licenses.forEach((item) => {
                this.licencesList.push(this.createLicences(item));
              });
            }
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }
  uploadIDFile(event) {
    if (event.target.files[0].type == "application/pdf") {
      if (
        event.target.files[0] &&
        event.target.files[0] !== undefined &&
        event.target.files[0].name
      ) {
        const fileid = event.target.files[0];
        if (fileid.size > 512000) {
          this.sweetAlert.errorToast(
            this.lang.transform("lang_file_size_too_large"),
            3000
          );
          return;
        }
        this.idfilename = fileid.name;
        this.isFile = true;
        this.signupForm
          .get("jobDetails")
          .get("resume_file")
          .setValue(fileid, { emitEvent: true });
      } else {
        this.isFile = false;
        this.signupForm.get("jobDetails").get("resume_file").setValue([]);
        this.idfilename = this.lang.transform("lang_select_files");
      }
    } else {
      this.sweetAlert.errorToast(this.lang.transform("lang_pdf_only"), 3000);
    }
  }
  removeFile() {
    this.isFile = false;
    this.signupForm.get("jobDetails").get("resume_file").setValue([]);
    this.idfilename = this.lang.transform("lang_select_files");
  }
  rstep3Submitted() {
    this.signupForm.get("jobDetails").get("job_title_id").markAsTouched();
    this.signupForm
      .get("jobDetails")
      .get("job_title_id")
      .updateValueAndValidity();
    this.signupForm.get("jobDetails").get("resume_title").markAsTouched();
    this.signupForm
      .get("jobDetails")
      .get("resume_title")
      .updateValueAndValidity();
    this.signupForm.get("jobDetails").get("resume_file").markAsTouched();
    this.signupForm
      .get("jobDetails")
      .get("resume_file")
      .updateValueAndValidity();
    this.signupForm.get("jobDetails").get("job_descriptions").markAsTouched();
    this.signupForm
      .get("jobDetails")
      .get("job_descriptions")
      .updateValueAndValidity();
  }
}
