<section class="panel-expansion ckeditorhidetoolbar">
  <mat-accordion class="stylepanels">
    <mat-expansion-panel class="mpanel" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">
        {{ "lang_settlement" | language }}
        (
        <span *ngIf="lang_key == 'en'">
          {{ employee_data.firstname_english }}
          {{ employee_data.lastname_english }}</span
        >
        <span *ngIf="lang_key == 'ar'">
          {{ employee_data.firstname_arabic }}
          {{ employee_data.lastname_arabic }}
        </span>
        )
      </mat-expansion-panel-header>
      <div class="mbrl15">
        <form [formGroup]="form" class="form">
          <div class="mbrl15">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12">
                <div class="form-group terminations">
                  <label
                    >{{ "lang_settlement_reasons" | language }} :<span
                      class="red"
                      >*</span
                    ></label
                  >
                  <select
                    class="form-control almnabrformcontrol"
                    formControlName="settlement_reasons"
                    #settlement_reasons
                  >
                    <option value="">
                      {{ "lang_choose_options" | language }}
                    </option>
                    <ng-container
                      *ngFor="let term of contract_termination_list.records"
                    >
                      <option value="{{ term.value }}">
                        <span>{{ term.label }}</span>
                      </option>
                    </ng-container>
                  </select>
                  <mat-error
                    *ngIf="
                      form.controls['settlement_reasons'].touched &&
                      form.controls['settlement_reasons'].errors?.required
                    "
                    >{{ "lang_field_required" | language }}</mat-error
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                <label class="mb-10">
                  {{ "lang_financial_notes" | language }}
                  <span class="red"> * </span>
                </label>
                <input
                  formControlName="financial_notes"
                  class="form-control almnabrformcontrol"
                  placeholder="{{ 'lang_notes' | language }}"
                />
                <mat-error
                  *ngIf="
                    form.controls['financial_notes'].touched &&
                    form.controls['financial_notes'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row justify-content-between">
          <div class="col-md-10 col-sm-10 col-xs-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
          <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
            <button
              type="button"
              (click)="create_settlement()"
              class="albutton mnabrbutton mat-accent ml-auto"
              mat-button
            >
              {{ "lang_submit" | language }}
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
