import { Injectable } from "@angular/core";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { SocketServiceService } from "@core/bootstrap/socket-service.service";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class WhatsappSocketService {
	socket = this.socketService.socket;
	userId = this.localStorageService.getItem("user_id");
	constructor(
		private socketService: SocketServiceService,
		private localStorageService: LocalStorage
	) {}

	listenToMessages(chat_id) {
		console.log(`whatsapp_chat_${chat_id}_messages_${this.userId}`);
		return Observable.create((observer) => {
			this.socket.on(
				`whatsapp_chat_${chat_id}_messages_${this.userId}`,
				(message) => {
					console.log(`whatsapp_chat_${chat_id}_messages_${this.userId}`);

					observer.next(message);
				}
			);
		});
	}
}
