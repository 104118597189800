import { DatePipe, DecimalPipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { JournalCostcenterComponent } from "../journal-costcenter/journal-costcenter.component";

@Component({
  selector: "app-journal-add",
  templateUrl: "./journal-add.component.html",
  styleUrls: ["./journal-add.component.scss"],
})
export class JournalAddComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading = false;
  branches: any = [];
  accountData: any = [];
  costData: any = [];
  myDate: any = new Date();
  records: FormArray;
  financeList = [];
  tags = [];
  public branch_id = this.route.snapshot.paramMap.get("bid");
  public finance_id = this.route.snapshot.paramMap.get("fid");
  constructor(
    public ds: DataService,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    public router: Router,
    private sweetAlert: SweetAlertService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private _decimalPipe: DecimalPipe
  ) {}
  ngOnInit(): void {
    this.myDate = new Date(this.datePipe.transform(this.myDate, "yyyy/MM/dd"));
    this.spinner.show();
    this.load_branches();
    this.buildform();
    this.form.get("branch_id").valueChanges.subscribe((res) => {
      if (res) {
        this.getFinancialList();
      }
    });
    this.getTags();
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  ngAfterViewInit() {
    for (let index = 0; index < 4; index++) {
      this.records.push(this.journal_records());
    }
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_add").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      finance_id: new FormControl(this.finance_id, [Validators.required]),
      journal_voucher_date: new FormControl(this.myDate, [Validators.required]),
      journal_voucher_debit_total: new FormControl("", [Validators.required]),
      journal_voucher_credit_total: new FormControl("", [Validators.required]),
      journal_records: this.fb.array([]),
    });
    this.records = this.form.get("journal_records") as FormArray;
    this.getFinancialList();
  }
  public journal_records_group() {
    return this.form.get("journal_records") as FormArray;
  }
  get validate() {
    return this.form.get("journal_records") as FormArray;
  }
  public journal_records(): FormGroup {
    return this.fb.group({
      account_masters_id: new FormControl(null, [Validators.required]),
      debit_amount: new FormControl(""),
      credit_amount: new FormControl(""),
      transaction_history_description: new FormControl(""),
      transaction_history_ref_number: new FormControl(""),
      cost_centers: [],
      transaction_history_notes: new FormControl(""),
      tags: new FormControl(null, [Validators.required]),
    });
  }
  public add_journal_records() {
    this.records.push(this.journal_records());
  }
  public removeJournal(index) {
    if (this.records.length > 1) {
      this.records.removeAt(index);
    }
  }
  public calculateTotalDebitCredit() {
    let dtotal: any = 0.0;
    let ctotal: any = 0.0;
    this.validate.value.forEach((x) => {
      dtotal += +x.debit_amount;
      ctotal += +x.credit_amount;
    });
    this.form
      .get("journal_voucher_debit_total")
      .setValue(parseFloat(dtotal).toFixed(2));
    this.form
      .get("journal_voucher_credit_total")
      .setValue(parseFloat(ctotal).toFixed(2));
  }
  public clear_journal() {
    for (let k = this.records.length - 1; k > -1; k--) {
      if (
        this.validate.controls[k].get("account_masters_id").value == null &&
        this.validate.controls[k].get("debit_amount").value == "" &&
        this.validate.controls[k].get("transaction_history_description")
          .value == "" &&
        this.validate.controls[k].get("transaction_history_ref_number").value ==
          "" &&
        this.validate.controls[k].get("credit_amount").value == "" &&
        this.validate.controls[k].get("transaction_history_notes").value ==
          "" &&
        this.validate.controls[k].get("cost_centers").value == null
      ) {
        this.removeJournal(k);
      }
    }
  }
  public create_journal() {
    console.log(this.journal_records_group());
    this.clear_journal();
    let param = new FormData();
    param.set("branch_id", this.form.get("branch_id").value);
    param.set("finance_id", this.form.get("finance_id").value || "");
    param.set(
      "journal_voucher_date",
      this.form.get("journal_voucher_date").value || this.myDate
    );
    param.set(
      "journal_voucher_debit_total",
      this.form.get("journal_voucher_debit_total").value
    );
    param.set(
      "journal_voucher_credit_total",
      this.form.get("journal_voucher_credit_total").value
    );
    this.validate?.value?.forEach((v, k) => {
      if (
        !(
          this.validate.controls[k].get("account_masters_id").value == null &&
          this.validate.controls[k].get("debit_amount").value == "" &&
          this.validate.controls[k].get("transaction_history_description")
            .value == "" &&
          this.validate.controls[k].get("transaction_history_ref_number")
            .value == "" &&
          this.validate.controls[k].get("credit_amount").value == "" &&
          this.validate.controls[k].get("transaction_history_notes").value ==
            "" &&
          this.validate.controls[k].get("cost_centers").value == null
        )
      ) {
        param.set(
          "journal_records[" + k + "][account_masters_id]",
          this.validate.controls[k].get("account_masters_id").value
            ? this.validate.controls[k].get("account_masters_id").value
            : "0"
        );

        param.set(
          "journal_records[" + k + "][credit_amount]",
          `${+this.validate.controls[k].get("credit_amount").value}`
        );
        param.set(
          "journal_records[" + k + "][debit_amount]",
          `${+this.validate.controls[k].get("debit_amount").value}`
        );
        param.set(
          "journal_records[" + k + "][transaction_history_description]",
          this.validate.controls[k].get("transaction_history_description").value
        );
        param.set(
          "journal_records[" + k + "][transaction_history_notes]",
          this.validate.controls[k].get("transaction_history_notes").value
        );
        param.set(
          "journal_records[" + k + "][transaction_history_ref_number]",
          this.validate.controls[k].get("transaction_history_ref_number").value
        );
        param.set(
          "journal_records[" + k + "][transaction_history_tages]",
          this.validate.controls[k].get("tags").value
        );
        this.records?.controls[k]?.value["cost_centers"]?.forEach((el, i) => {
          param.set(
            `journal_records[${k}][cost_center_ids][${i}][cid]`,
            this.records.controls[k].get("cost_centers")?.value[i]?.cid
          );
          param.set(
            `journal_records[${k}][cost_center_ids][${i}][amount]`,
            this.records.controls[k].get("cost_centers")?.value[i]?.amount
          );
        });
      }
    });
    this.spinner.show();
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "createjournal").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.alert.success(res.msg);
            this.router.navigate([
              "accounts/jview/" +
                res.branch_id +
                "/" +
                res.journal_voucher_id +
                "/" +
                this.form.get("finance_id").value +
                "/view",
            ]);
            this.form.reset();
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public searchAccounts(key, control) {
    this.accountData = [];
    let param = new FormData();
    param.append("branch_id", this.form.get("branch_id").value);
    param.append("finance_id", this.form.get("finance_id").value);
    param.append("search_text", key.term);
    if (this.form.get("branch_id").value && key.term) {
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "sam/accounts_add").subscribe((data) => {
          if (data.status) {
            this.accountData = data.records;
          }
        })
      );
    }
    this.calculate_equal_debit_credit(control);
  }
  private calculate_equal_debit_credit(control) {
    let debit_total: any = 0.0;
    let credit_total: any = 0.0;
    this.validate.value.forEach((x) => {
      debit_total += +x.debit_amount;
      credit_total += +x.credit_amount;
    });
    if (credit_total !== debit_total) {
      let remaining_amount: any = credit_total - debit_total;
      let type = "";
      if (remaining_amount < 0) {
        type = "CRD"; //credit amount
      } else {
        type = "DBT"; //debit amount
      }
      let debit_field_value = control.get("debit_amount").value;
      let credit_field_value = control.get("credit_amount").value;
      if (
        (debit_field_value && parseInt(debit_field_value) > 0) ||
        (credit_field_value && parseInt(credit_field_value) > 0)
      ) {
      } else {
        let value = isNaN(remaining_amount)
          ? ""
          : Math.abs(parseFloat(remaining_amount));
        if (type == "CRD") {
          control.get("credit_amount").setValue(value);
        } else if (type == "DBT") {
          control.get("debit_amount").setValue(value);
        } else {
          // upcoming calculations
        }
      }
    }
    this.calculateTotalDebitCredit();
  }
  getFinancialList() {
    this.ds.get("financial/" + this.form.get("branch_id").value).subscribe(
      (res) => {
        if (res?.status) {
          this.financeList = res?.records;
          this.form?.get("finance_id")?.setValue(this.finance_id);
        } else {
          this.sweetAlert.errorToast(res?.error, 2000);
        }
      },
      (err) => {
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          2000
        );
      }
    );
  }
  onAddUpdateCostCenter(index) {
    const totalCost = +this.records.controls[index].get("debit_amount")?.value
      ? +this.records.controls[index].get("debit_amount")?.value
      : +this.records.controls[index].get("credit_amount")?.value;
    const dialogRef = this.dialog.open(JournalCostcenterComponent, {
      width: "600px",
      data: {
        branch_id: this.form.get("branch_id").value,
        total_cost: totalCost,
        cost_centers: this.records.controls[index].get("cost_centers")?.value,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.records.controls[index]
          .get("cost_centers")
          .setValue(res?.costCenters);
      }
    });
  }
  preload() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }

  addNewTag(tag: string) {
    return {
      label: tag,
      value: tag,
    };
  }

  getTags(event?) {
    const bodyReq = new FormData();
    bodyReq.append("finance_id", this.finance_id || "");
    bodyReq.append("branch_id", this.branch_id || "");
    bodyReq.append("key_search", event?.term || "");
    this.ds.post(`accounts/get_transaction_tages`, bodyReq).subscribe((res) => {
      console.log(res);
      this.tags = res?.records;
    });
  }
}
