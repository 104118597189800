import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";

@Component({
	selector: "app-top-count-requests",
	templateUrl: "./top-count-requests.component.html",
	styleUrls: ["./top-count-requests.component.scss"],
})
export class TopCountRequestsComponent implements OnInit {
	projects_work_area_id: any;
	dataList = null;
	itemData: any;
	dataList_msg = "";
	page_no = 1;
	page_size = 10;
	constructor(
		private dialogRef: MatDialogRef<TopCountRequestsComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private ds: DataService,
		private spinner: PreloaderService,
		private router: Router
	) {
		this.projects_work_area_id = data?.projects_work_area_id;
		this.itemData = data?.itemRow;
	}

	ngOnInit(): void {
		this.getList();
	}

	getList() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append(
			"platform_code_system",
			this.itemData?.platform_code_system || ""
		);
		formData.append("platform_work_level_key", this.itemData?.work_level || "");
		this.ds
			.post(
				`pr/get_top_count_requests_details/${this.page_no}/${this.page_size}`,
				formData
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.dataList = res;
				} else {
					this.dataList_msg = res?.error;
				}
			});
	}
	onMoveToRequest(transaction_key, transaction_id) {
		this.router.navigate([
			`/transactions/form/${transaction_key}/vr/${transaction_id}`,
		]);
		this.dialogRef.close();
	}
}
