import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormControl,
	FormGroup,
	Validators
} from '@angular/forms';
import {
	Appearance,
	GermanAddress
} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import {
	DataService
} from '@core/bootstrap/data.service';
import {
	ApiRoutes
} from 'app/routes/apiroutes';
import {
	AlertService
} from '@shared/components/alert/alert.service';
import {
	Router
} from '@angular/router';
import {
	PreloaderService
} from '@core';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { trigger, state, style, transition, animate } from '@angular/animations';
@Component({
	selector: 'app-projects-create',
	templateUrl: './projects-create.component.html',
	styleUrls: ['./projects-create.component.scss'],
	animations: [
	  trigger("fadeIn", [
		state(
		  "void",
		  style({
			opacity: 0
		  })
		),
		transition("void <=> *", animate(1000))
	  ]),
	]
})
export class ProjectsCreateComponent implements OnInit {
	public appearance = Appearance;
	public zoom: number;
	public latitude: number;
	public longitude: number;
	public selectedAddress: PlaceResult;
	form: FormGroup;
	branches: any = [];
	customers: any = [];
	customer_types: any = [];
	services: any = [];
	users: any = [];
	reviewers: any = [];
	constructor(
		public lang:LanguagePipe,
		public ds: DataService,
		public spinner: PreloaderService,
		public alert: AlertService,
		public router: Router,
	) {}
	ngOnInit(): void {
		this.spinner.show();
		this.zoom = 10;
		this.latitude = 52.520008;
		this.longitude = 13.404954;
		this.setCurrentPosition();
		this.buildform();
		this.get_branches();
		this.get_customer_type();
		this.get_services();
	}
	public buildform() {
		this.form = new FormGroup({
			branch_id: new FormControl(null, [Validators.required]),
			projects_profile_name_en: new FormControl('', [Validators.required]),
			projects_profile_name_ar: new FormControl('', [Validators.required]),
			customer_id: new FormControl(null, [Validators.required]),
			customer_type_id: new FormControl(null, [Validators.required]),
			projects_profile_location: new FormControl(''),
			latitude: new FormControl(''),
			longitude: new FormControl(''),
			project_scope_types: new FormControl(null, [Validators.required]),
			project_managers: new FormControl('', [Validators.required]),
			project_users: new FormControl(''),
		})
	}
	public get_branches() {
		this.branches = [];
		this.ds.get('jQUW6F52AgToEsm/projects_add').subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.branches = res.records;
			}
		}, err => {
			this.spinner.hide();
		})
	}
	public get_customers() {
		this.customers = [];
		this.spinner.show();
		this.ds.get('c91SZ4xopgmOM6A/projects_add/' + this.form.get('branch_id').value).subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.customers = res.records;
			}
		}, error => {
			this.spinner.hide();
		})
	}
	public get_customer_type() {
		this.customer_types = [];
		this.ds.get('Y3ZjDZmYyUy5Rlx/projects_add').subscribe(res => {
			if (res.status) {
				this.customer_types = res.records;
			}
		})
	}
	public get_services() {
		this.services = [];
		this.ds.get('aB8Vlmue0xVSv8O/projects_add').subscribe(res => {
			if (res.status) {
				this.services = res.records;
			}
		})
	}
	public search_users(key) {
		this.users = [];
		if (key.term && this.form.get('branch_id').value) {
			let param = new FormData();
			param.append('search', key.term || '');
			param.append('user_type_id', '1');
			this.ds.getActionByUrl(this.ds.formData2string(param), 'sZMVmoFEATU9YlN/projects_add').subscribe(res => {
				if (res.status) {
					this.users = res.records;
				}
			})
		}
	}
	public setCurrentPosition() {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.latitude = position.coords.latitude;
				this.longitude = position.coords.longitude;
				this.zoom = 12;
			});
		}
	}
	public onGermanAddressMapped($event: GermanAddress) {
		this.form.get('latitude').setValue($event.geoLocation.latitude);
		this.form.get('longitude').setValue($event.geoLocation.longitude);
		this.form.get('projects_profile_location').setValue($event.displayAddress);
		this.latitude = $event.geoLocation.latitude;
		this.longitude = $event.geoLocation.longitude;
	}
	public create() {
		this.spinner.show();
		let param = new FormData();
		param.set('branch_id', this.form.get('branch_id').value || '');
		param.set('projects_profile_name_en', this.form.get('projects_profile_name_en').value || '');
		param.set('projects_profile_name_ar', this.form.get('projects_profile_name_ar').value || '');
		param.set('customer_id', this.form.get('customer_id').value || '');
		param.set('customer_type_id', this.form.get('customer_type_id').value || '');
		param.set('project_scope_types', (this.form.get('project_scope_types').value) ? this.form.get('project_scope_types').value.toString() : '');
		param.set('project_managers', this.form.get('project_managers').value.toString());
		param.set('project_users', this.form.get('project_users').value.toString());
		param.set('projects_profile_location', this.form.get('projects_profile_location').value || '');
		param.set('latitude', this.form.get('latitude').value || '');
		param.set('longitude', this.form.get('longitude').value || '');
		this.ds.post(ApiRoutes.createProject, param).subscribe(
			(data) => {
				this.spinner.hide();
				if (data.status) {
					this.alert.success(data.msg);
					this.form.reset();
					setTimeout(() => {
						this.router.navigate(['projects/LtYh/' + data.project_profile_id]);
					}, 2000);
				} else {
					this.alert.error(data.error);
				}
			},
			(error) => {
				this.spinner.hide();
				this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
			}
		);
	}
}