import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
declare var $:any;
@Component({
  selector: 'app-permit-settings',
  templateUrl: './permit-settings.component.html',
  styleUrls: ['./permit-settings.component.scss']
})
export class PermitSettingsComponent implements OnInit ,OnDestroy {
  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  branch_id: any = (localStorage.getItem('selectedBranch') != null) ? localStorage.getItem('selectedBranch') : '0';
  form: FormGroup;
  groups: any = [];
  users: any = [];
  mention_allow: boolean = false;
  able2delete = JSON.parse(this.ls.getItem('is_admin'));
  acpage_no = 1;
  acpage_size = '10';
  acdata: any = [];
  lodingdatas = this.lang.transform('lang_loading');
  filltergroup = null;
  fillterusers = null;
  constructor(public ds:DataService,public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.buildform();
    if (this.branch_id && this.branch_id != '0') {
      this.spinner.show();
      this.get_groups();
      this.get_users();
      this.get_permission_mentions();
    }
  }
  changeBranch(branch) {
    this.spinner.show();
    this.branch_id = branch;
    this.get_groups();
    this.get_users();
    this.get_permission_mentions();
  }
  buildform() {
    this.form = this.fb.group({
      branch_id: [this.branch_id, [Validators.required]],
      group_id: ['', [Validators.required]],
      users_id: ['', [Validators.required]],
    });
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  get_groups() {
    this.groups = [];
    this.subscriptions.add(
              this.ds.getActionByUrl([], 'acgroups').subscribe(res => {
          this.mention_allow = res.mention_allow;
          if (res.status) {
            this.groups = res.records;
          } else {
          }
        }, error => {
            this.mention_allow = false;
        })
      )

  }
  get_users() {
    this.users = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], 'acusers/' + this.branch_id).subscribe(res => {
        if (res.status) {
          this.users = res.records;
        }
      })
      )

  }
  emptypermissionfields() {
    this.form.reset();
    this.form.get('branch_id').setValue(this.branch_id);
    this.form.get('group_id').setValue('');
  }
  savepermissions() {
    this.alert.clear();
    this.spinner.show();
    let param = new FormData();
    param.set('branch_id', this.branch_id);
    param.set('group_id', this.form.get('group_id').value);
    param.set('users_id', (this.form.get('users_id').value && this.form.get('users_id').value != null) ? this.form.get('users_id').value.toString() : '');
    this.subscriptions.add(
          this.ds.postActionByUrl(param, 'acsavehrpermissions').subscribe((data) => {
            this.spinner.hide();
            if (data.status) {
              this.alert.success(data.msg);
              this.get_permission_mentions();
              this.form.reset();
              setTimeout(() => {
                this.emptypermissionfields();
                this.modalRef.hide();
              }, 2000);
            } else {
              this.alert.error(data.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          }
        )
      )
    ;
  }
  loadpageac(page) {
    this.spinner.show();
    this.acpage_no = page;
    this.acpage_size = this.acpage_size;
    this.get_permission_mentions();
  }
  acloadpagesize(size) {
    this.spinner.show();
    this.acpage_no = 1;
    this.acpage_size = size;
    this.get_permission_mentions();
  }
  loadpermissionmention() {
    this.acpage_no = 1;
    this.acpage_size = '10';
    this.get_permission_mentions();
  }
  get_permission_mentions() {
    let formdata = new FormData();
    formdata.append('search[branch_id]', this.branch_id);
    formdata.append('search[group_key]', (this.filltergroup != null) ? this.filltergroup : '');
    formdata.append('search[user_id]', (this.fillterusers != null) ? this.fillterusers : '');
    this.subscriptions.add(
              this.ds.postActionByUrl(formdata, 'acpermission_mentions/' + this.acpage_no + '/' + this.acpage_size,).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.acdata = res;
          } else {
            this.acdata = [];
            this.lodingdatas = res.error;
          }
        }, error => {
          this.spinner.hide();
          this.acdata = [];
          this.lodingdatas = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
        })
      )

  }
  public checkAllItemsAcp(event) {
    if (event.target.checked) {
      $(".accounts_permissions .eachItemAcp").prop("checked", true);
      $(".accounts_permissions .makeback").addClass("bground");
      $(".accounts_permissions .deleteIconDiv").show();
    } else {
      $(".accounts_permissions .eachItemAcp").prop("checked", false);
      $(".accounts_permissions .makeback").removeClass("bground");
      $(".accounts_permissions .deleteIconDiv").hide();
    }
  }
  public checkEachItemAcp(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".accounts_permissions .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".accounts_permissions .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".accounts_permissions .eachItemAcp:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".accounts_permissions .eachItemAcp").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".accounts_permissions .deleteIconDiv").show();
    } else {
      $(".accounts_permissions .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".accounts_permissions #allItemsAcp").prop("checked", true);
    } else {
      $(".accounts_permissions #allItemsAcp").prop("checked", false);
    }
  }
  public deleteBulkDataAcp() {
    const checkedtotal = [];
    $(".accounts_permissions .eachItemAcp:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      this.subscriptions.add(
            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                this.spinner.show()
                this.subscriptions.add(
                    this.ds.deleteActionByUrl(checkedtotal, 'acdelete_permits').subscribe(
                      (data) => {
                        this.spinner.hide()
                        if (data.status) {
                          this.get_permission_mentions();
                          $(".accounts_permissions #allItemsAcp").prop("checked", false);
                          $(".accounts_permissions .deleteIconDiv").hide();
                          this.ds.dialogf('', data.msg);
                        } else {
                          this.ds.dialogf('', data.error);
                        }
                      },
                      (error) => {
                        this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                        this.spinner.hide()
                      }
                    )      
                  )
                ;
              }
            })      
        )
;
    } else {
      this.ds.dialogf();
    }
  }
}