import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MenuService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidemenuComponent {
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = false;
  menu$ = this.menuSrv.getAll();
  buildRoute = this.menuSrv.buildRoute;
  constructor(private menuSrv: MenuService, public ds:DataService) {}  
}
