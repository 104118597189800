import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { AddPermissionComponent } from "./add-permission/add-permission.component";
declare var $: any;

@Component({
	selector: "app-permissions",
	templateUrl: "./permissions.component.html",
	styleUrls: ["./permissions.component.scss"],
})
export class PermissionsComponent implements OnInit {
	admin = JSON.parse(this.ls.getItem("is_admin"));
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	branches: any = [];
	groups: any = [];
	users: any = [];
	mention_allow: boolean = false;
	pmpage_no = 1;
	pmpage_size = "10";
	pmdata: any = [];
	lodingdatas = this.lang.transform("lang_loading");
	searchBranch = null;
	searchGroup = null;
	searchUser = null;
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService,
		private sweetalert: SweetAlertService
	) {}
	ngOnInit(): void {
		this.spinner.show();
		this.get_branches();
		this.get_groups();
		this.get_users(0);
		this.get_permission_mentions();
	}
	public get_branches() {
		this.branches = [];
		this.ds.getActionByUrl([], "taskssettings/get_branches").subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.mention_allow = res.mention_allow;
					this.branches = res.records;
				}
			},
			(error) => {
				this.spinner.hide();
			}
		);
	}
	public get_groups() {
		this.groups = [];
		this.ds.getActionByUrl([], "taskssettings/get_groups").subscribe((res) => {
			if (res.status) {
				this.groups = res.records;
			}
		});
	}
	public get_users(value) {
		this.users = [];
		let bid = value ? value : 0;
		let param = new FormData();
		param.append("user_type_id", "1");
		this.ds.postActionByUrl(param, "taskssettings/get_users/" + bid).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.users = res.records;
				}
			},
			(error) => {
				this.spinner.hide();
			}
		);
	}

	public loadpage(page) {
		this.pmpage_no = page;
		this.pmpage_size = this.pmpage_size;
		this.get_permission_mentions();
	}
	public loadpagesize(size) {
		this.pmpage_no = 1;
		this.pmpage_size = size;
		this.get_permission_mentions();
	}
	public load_permission_mention() {
		this.pmpage_no = 1;
		this.pmpage_size = "10";
		this.get_permission_mentions();
	}
	public get_permission_mentions() {
		let formdata = new FormData();
		formdata.append("search[branch_id]", this.searchBranch || "");
		formdata.append("search[group_key]", this.searchGroup || "");
		formdata.append("search[user_id]", this.searchUser || "");
		this.ds
			.postActionByUrl(
				formdata,
				"taskssettings/get_permission_mentions/" +
					this.pmpage_no +
					"/" +
					this.pmpage_size
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.pmdata = res;
					} else {
						this.pmdata = [];
						this.lodingdatas = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.pmdata = [];
					this.lodingdatas =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}

	onAddPermission() {
		const dialogRef = this.dialog.open(AddPermissionComponent, {
			width: "500px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.get_permission_mentions();
			}
		});
	}
	public checkAllItemsUsr(event) {
		if (event.target.checked) {
			$(".user_permissions .eachItemUsr").prop("checked", true);
			$(".user_permissions .makeback").addClass("bground");
			$(".user_permissions .deleteIconDiv").show();
		} else {
			$(".user_permissions .eachItemUsr").prop("checked", false);
			$(".user_permissions .makeback").removeClass("bground");
			$(".user_permissions .deleteIconDiv").hide();
		}
	}
	public checkEachItemUsr(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".user_permissions .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".user_permissions .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".user_permissions .eachItemUsr:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".user_permissions .eachItemUsr").each(function () {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".user_permissions .deleteIconDiv").show();
		} else {
			$(".user_permissions .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".user_permissions #allItemsUsr").prop("checked", true);
		} else {
			$(".user_permissions #allItemsUsr").prop("checked", false);
		}
	}
	public deleteBulkDataUsr() {
		const checkedtotal = [];
		$(".user_permissions .eachItemUsr:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: "400px",
				data: {
					title: this.lang.transform("lang_confirmation"),
					message: this.lang.transform("lang_are_you_sure"),
				},
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show();
					this.ds
						.deleteActionByUrl(checkedtotal, "taskssettings/delete_permits")
						.subscribe(
							(data) => {
								this.spinner.hide();
								if (data.status) {
									this.get_permission_mentions();
									$(".user_permissions #allItemsUsr").prop("checked", false);
									$(".user_permissions .deleteIconDiv").hide();
									this.sweetalert.successToast(data.msg, 2000);
								} else {
									this.sweetalert.errorToast(data.error, 2000);
								}
							},
							(error) => {
								this.ds.dialogf(
									"",
									error && error.error && error.error.error
										? error.error.error
										: this.lang.transform("lang_internal_server_error")
								);
								this.spinner.hide();
							}
						);
				}
			});
		} else {
			this.ds.dialogf();
		}
	}
}
