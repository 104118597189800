import { Pipe, PipeTransform } from '@angular/core';
import { LanguagePipe } from './language.pipe';

@Pipe({
  name: 'shortenLength'
})
export class ShortenLengthPipe implements PipeTransform {

  constructor(private lang:LanguagePipe)
  {

  }  

  transform(value: string, length:number): string {
    if(value?.length <= length) {
      return value;
    }
    
    if(value?.length > length) {
      return value.slice(0, length) + '...';
    }
    return value;
  }

}
