<form [formGroup]="form" class="form" (ngSubmit)="completeStep4()">
	<div class="card">
		<div class="card-header">Confirm Receive SI</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<label>Confirm Receive SI !!!</label>
						<section class="example-section">
							<mat-checkbox
								formControlName="confirm_receive_SI"
								class="example-margin"
							></mat-checkbox>
						</section>
					</div>
				</div>
			</div>
		</div>
		<div class="pl-2 pr-2">
			<div class="row">
				<div class="col-sm-8 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<input
						type="submit"
						value="{{ 'lang_submit' | language }}"
						class="albutton mnabrbutton mat-accent"
						mat-button
					/>
				</div>
			</div>
		</div>
	</div>
</form>
