import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
@Component({
  selector: "app-formation-add",
  templateUrl: "./formation-add.component.html",
  styleUrls: ["./formation-add.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FormationAddComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_edit_data;
  @Input() template_id;
  @Input() actionMode;
  modalRefA: BsModalRef;
  step1form: FormGroup;
  step2form: FormGroup;
  group1formarray: FormArray;
  @ViewChild("stepper") private myStepper: MatStepper;
  unitsarray: FormArray;
  modalRefEG1: BsModalRef;

  @Output() showAction = new EventEmitter<any>();
  step1submitted = false;
  step2submitted = false;
  lang_key = localStorage.getItem("lang_key") || "en";
  returndata: any = [];
  weightunits: any = [];
  access_codes: any = [];
  project_units: any = [];
  totalunitspend = "";
  collections: any = [];
  worklevels: any = [];
  maintotalamount: any = "0.00";
  this_template_total_cost: any = "0.00";
  manual_total_ratio_of_this_template: any = "0.00";
  system_total_ratio_of_this_template: any = "0.00";
  fsystem_total_amount: any = 0.0;
  fmanual_total_amount: any = 0.0;
  fsystem_total_ratio: any = 0.0;
  fmanual_total_ratio: any = 0.0;
  each_unit_cost_of_template = 0;
  //extra group 1
  extragroup1: any = []; //array list
  extragroup1data: any = null; //ngmodel
  selectedunitsmsg: any = "";

  buildingCodes: any = [];
  fconsultants: any = [];
  consultantr: any = [];
  positions: any = [];
  ncrSiIsssues: any = [];

  step1Validate = new FormControl(null, [Validators.required]);
  constructor(
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    public ds: DataService,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {
    this.get_collections_work_levels();
    this.get_all_options();
  }
  public get_all_options() {
    let param = new FormData();
    param.append("lang_key", this.lang_key);
    let url = [
      "fconsultant",
      "consultantreq",
      "366484fd45",
      "building_codes",
      "site_issues",
    ];
    this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(
      (res) => {
        this.spinner.hide();
        if (res[0].status) {
          //From Consultant
          this.fconsultants = res[0].records;
        }
        if (res[1].status) {
          //Consultant Requirements records
          this.consultantr = res[1].records;
        }
        if (res[2].status) {
          //Platforms records
          this.positions = res[2].records;
        }
        if (res[3].status) {
          //Building Codes
          this.buildingCodes = res[3].records;
        }
        if (res[4].status) {
          //Building Codes
          this.ncrSiIsssues = res[4].records;
        }
      },
      (err) => {
        this.spinner.hide();
        this.sweetAlert.errorMessage(
          this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.template_edit_data = this.template_edit_data;
  }
  ngOnInit(): void {
    this.spinner.show();
    this.get_weight_units();
    this.step1form = new FormGroup({
      projects_work_area_id: new FormControl(
        this.supervision_data.projects_work_area_id,
        [Validators.required]
      ),
      projects_profile_id: new FormControl(
        this.supervision_data.projects_profile_id,
        [Validators.required]
      ),
      projects_supervision_id: new FormControl(
        this.supervision_data.projects_supervision_id,
        [Validators.required]
      ),
      template_id: new FormControl(""),
      template_type: new FormControl("", [Validators.required]),
      formation_title_en: new FormControl("", [Validators.required]),
      formation_title_ar: new FormControl("", [Validators.required]),

      work_levels: new FormControl(null, [Validators.required]),
      units: this.fb.array([]),
    });
    this.step2form = new FormGroup({
      group1Data: this.fb.array([]),
    });
    this.unitsarray = this.step1form.get("units") as FormArray;
    this.group1formarray = this.step2form.get("group1Data") as FormArray;
    this.addunit();
    if (
      this.template_edit_data != null &&
      this.template_edit_data.template_json_data
    ) {
      let formdata = JSON.parse(this.template_edit_data.template_json_data);
      this.unitvalidate.controls = [];
      if (formdata && formdata.units && formdata.units.length > 0) {
        this.build_units_for_edit(this.template_edit_data);
        formdata.units.forEach((element) => {
          this.unitsarray.push(this.unit_records());
        });
      }
      this.step1form.patchValue(formdata);
      this.step1form.get("template_id").setValue(this.template_id);
    }

    if (this.actionMode === "VIEW") {
      this.step1form.disable();
      this.step2form.disable();
    } else {
      this.step1form.enable();
      this.step2form.enable();
    }
  }
  //step 1 Starts
  public get_collections_work_levels() {
    this.collections = this.worklevels = [];
    let param = new FormData();
    param.append("lang_key", this.lang_key);
    let url = ["lpcollection", "lpworklevel"];
    this.spinner.show();
    this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(
      (res) => {
        this.spinner.hide();
        if (res[0].status) {
          //collection records
          this.collections = res[0].records;
        }
        if (res[1].status) {
          // work levels
          this.worklevels = res[1].records;
          if (
            this.template_edit_data != null &&
            this.template_edit_data.template_json_data
          ) {
            let arr = [];
            this.worklevels.forEach((v, k) => {
              let levels =
                this.template_edit_data.template_work_level_keys.split(",");
              if (levels.indexOf(v.value) !== -1) {
                arr.push(v);
              }
            });
            this.step1form.get("work_levels").setValue(arr);
          }
        }
      },
      (error) => {
        this.spinner.hide();
        this.sweetAlert.errorMessage(
          this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }

  set_worl_levels() {
    this.step1form.get("work_levels").setValue(null);
    if (this.step1form.get("template_type").value == "2") {
      this.step1form.get("work_levels").setValue(this.worklevels);
    }
  }

  public search_project_units(key) {
    this.project_units = [];
    if (key && key.term) {
      let param = new FormData();
      param.append("search_key", key.term);
      param.append("template_type", this.step1form.get("template_type").value);
      if (this.step1form.get("template_id").value) {
        param.append("template_id", this.step1form.get("template_id").value);
      }
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "gsunits/" +
            this.supervision_data.projects_profile_id +
            "/" +
            this.supervision_data.projects_supervision_id +
            "/" +
            this.supervision_data.projects_work_area_id
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.project_units = res.records;
            }
          },
          (err) => {
            this.spinner.hide();
            this.sweetAlert.errorMessage(
              this.lang.transform("lang_internal_server_error")
            );
          }
        );
    }
  }
  public get_weight_units() {
    this.weightunits = [];
    let param = new FormData();
    param.append("lang_key", this.lang_key);
    this.spinner.show();
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "lpweightsunit")
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.weightunits = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
          this.sweetAlert.errorMessage(
            this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  //units array starts
  public items_unit() {
    return this.step1form.get("units") as FormArray;
  }
  get unitvalidate() {
    return this.step1form.get("units") as FormArray;
  }
  public addunit() {
    this.unitsarray.push(this.unit_records());
  }
  public removeunit(index) {
    if (this.unitsarray.length > 1) {
      this.unitsarray.removeAt(index);
    }
  }
  public unit_records(value?): FormGroup {
    return this.fb.group({
      unit_from: new FormControl(null),
      unit_to: new FormControl(null),
    });
  }
  //units array end
  //step 1 End

  //step 2 Starts
  public go_to_step2(stepper: MatStepper) {
    this.alert.clear();
    this.group1formarray.controls = [];
    if (this.step1form.get("template_type").value == "1") {
      this.build_units_string();
    }
    this.step1submitted = true;
    if (this.step1form.valid || this.actionMode == "VIEW") {
      this.group1validate.controls = [];
      this.step1submitted = false;
      let param = this.ds.json2formdata(this.step1form.value);
      param.append("lang_key", this.lang_key);
      param.append(
        "projects_services_id",
        this.supervision_data.projects_services_id
      );
      this.spinner.show();
      this.ds
        .postActionByUrl(param, "pforms/create_or_update_template")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.step1Validate.setValue(true);
              // this.generate_fields(res.records);
              // if(this.actionMode != 'VIEW') {
              //   this.template_id = res?.template_id;
              // }
              if (res?.template_id) {
                this.template_id = res?.template_id;
              }
              this.getTemplateView();
              this.access_codes = res.access_codes;
              this.totalunitspend = res.unit_spend;
              // this.selectedunitsmsg = " ( " +res.unit_from + " - " +res.unit_to + " ) ";

              stepper.next();
              this.step1Validate.setValue(null);
            } else {
              this.step1Validate.setValue(null);
              this.totalunitspend = res.unit_spend;
              // this.selectedunitsmsg = " ( " +res.unit_from + " - " +res.unit_to + " ) ";
              // this.template_id = res?.template_id;
              if (res?.template_id) {
                this.template_id = res?.template_id;
              }
              this.returndata = [];
              this.alert.error(res.error);
            }
          },
          (error) => {
            this.returndata = [];
            this.spinner.hide();
            this.sweetAlert.errorMessage(
              this.lang.transform("lang_internal_server_error")
            );
          }
        );
    }
  }
  //group 1 form array Starts
  public group1_title_total(controls) {
    let lang = this.lang.transform("lang_projects_total_title");
    let one = lang.replace(
      "%S",
      controls.get("platform_group1_code_title").value
    );
    return one.replace(
      "%T",
      "<b>" +
        this.ds.addCommas(
          parseInt(controls.get("platform_group1_code_total").value) >= 0
            ? parseFloat(
                controls.get("platform_group1_code_total").value
              ).toFixed(2)
            : 0.0
        ) +
        "</b>"
    );
  }
  //total Ratio
  public group1_title_total_ratio(controls) {
    let lang = this.lang.transform("lang_projects_total_ratio_title");
    let one = lang.replace(
      "%S",
      controls.get("platform_group1_code_title").value
    );
    return one.replace(
      "%R",
      "<b>" +
        this.ds.addCommas(
          controls.get("platform_group1_code_total_ratio").value
        ) +
        "</b>"
    );
  }
  public items_group1() {
    return this.step2form.get("group1Data") as FormArray;
  }
  get group1validate() {
    return this.step2form.get("group1Data") as FormArray;
  }
  public group1_records(value): FormGroup {
    return this.fb.group({
      platform_group1_count: new FormControl(value.group1count),
      platform_group1_code_system: new FormControl(
        value.platform_group1_code_system,
        [Validators.required]
      ),
      platform_group1_code_manual: new FormControl({
        value: value.platform_group1_code_manual,
        disabled: this.actionMode == "VIEW" ? true : false,
      }),
      platform_group1_code_title: new FormControl(value.group1name),
      platform_group1_code_total: new FormControl(
        value?.platform_group1_code_total
      ),
      platform_group1_code_total_ratio: new FormControl(
        value?.platform_group1_code_total_ratio
      ),
      system_total_amount: new FormControl(value?.system_total_amount),
      manual_total_amount: new FormControl(value?.manual_total_amount),
      system_total_ratio: new FormControl(value?.system_total_ratio),
      manual_total_ratio: new FormControl(value?.manual_total_ratio),
      group1TypesData: new FormControl(value?.types),
      typesData: new FormControl(value?.typesData, [Validators.required]),
    });
  }
  private generate_fields(records) {
    this.group1formarray.controls = [];
    var group1 = [];
    if (records != "undefined" && records != null) {
      Object.keys(records).forEach(function (key) {
        group1.push(records[key]);
      });
    }
    this.returndata = group1;
    group1.forEach((v, k) => {
      this.group1formarray.push(this.group1_records(v));
    });
  }
  //delete group 1 record
  public delete_group1_record(index) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true,
      width: "400px",
      data: {
        title: this.lang.transform("lang_confirmation"),
        message: this.lang.transform("lang_are_you_sure"),
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        //this.group1validate.controls = [];
        //this.group1validate[index].controls = [];
        this.group1formarray.removeAt(index);
      }
    });
  }
  //group 1 form array End
  public get_type_total(event) {
    if (!isNaN(event.total)) {
      this.group1validate.controls[event.index]
        .get("platform_group1_code_total")
        .setValue(parseFloat(event.total).toFixed(2));
    }
    if (!isNaN(event.total_ratio)) {
      this.group1validate.controls[event.index]
        .get("platform_group1_code_total_ratio")
        .setValue(parseFloat(event.total_ratio).toFixed(2));
    }
    if (!isNaN(event.system_total_amount)) {
      this.group1validate.controls[event.index]
        .get("system_total_amount")
        .setValue(parseFloat(event.system_total_amount).toFixed(2));
    }
    if (!isNaN(event.manual_total_amount)) {
      this.group1validate.controls[event.index]
        .get("manual_total_amount")
        .setValue(parseFloat(event.manual_total_amount).toFixed(2));
    }
    if (!isNaN(event.system_total_ratio)) {
      this.group1validate.controls[event.index]
        .get("system_total_ratio")
        .setValue(parseFloat(event.system_total_ratio).toFixed(2));
    }
    if (!isNaN(event.manual_total_ratio)) {
      this.group1validate.controls[event.index]
        .get("manual_total_ratio")
        .setValue(parseFloat(event.manual_total_ratio).toFixed(2));
    }
  }
  //add Extra Group 1
  public openModalEG1(template: TemplateRef<any>) {
    this.modalRefEG1 = this.modalService.show(
      template,
      environment.modelconfig
    );
  }
  public get_extra_group1(template) {
    this.extragroup1 = [];
    let group1array = [];
    let group1count = [];
    if (this.group1validate && this.group1validate.length > 0) {
      this.group1validate.value.forEach((v, k) => {
        group1array.push(v.platform_group1_code_system);
        group1count.push(v.platform_group1_count);
      });
    }
    let param = new FormData();
    param.append("platform_group1_count_not_in", group1count.toString());
    param.append("platform_group1_code_system_not_in", group1array.toString());
    param.append(
      "projects_services_ids",
      this.supervision_data.projects_services_id
    );
    this.spinner.show();
    this.ds.getActionByUrl(this.ds.formData2string(param), "lpgone").subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.extragroup1 = res.records;
          this.openModalEG1(template);
        } else {
          this.ds.dialogf("", res.error);
        }
      },
      (error) => {
        this.sweetAlert.errorMessage(
          this.lang.transform("lang_internal_server_error")
        );
        this.spinner.hide();
      }
    );
  }
  public add_extra_group1_items() {
    this.spinner.show();
    if (this.extragroup1data && this.extragroup1data.length > 0) {
      this.extragroup1data.forEach((v, k) => {
        this.group1formarray.push(
          this.group1_records({
            group1count: v.group1count,
            platform_group1_code_system: v.value,
            platform_group1_code_manual: v.code,
            group1name: v.label,
          })
        );
      });
    }
    this.extragroup1 = [];
    this.extragroup1data = null;
    setTimeout(() => {
      this.spinner.hide();
      this.modalRefEG1.hide();
    }, 200);
  }
  //calculations End
  //save the data as draft
  public save_as_draft() {
    this.spinner.show();
    let wholeData: any = {};
    Object.assign(wholeData, this.step1form.getRawValue());
    Object.assign(wholeData, this.step2form.getRawValue());
    let url = this.template_edit_data ? "eptemplate_draft" : "cptemplate_draft";
    this.ds.postActionByUrl(this.ds.json2formdata(wholeData), url).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.step1form.get("template_id").setValue(res.template_id);
          this.alert.success(res.msg);
          setTimeout(() => {
            this.alert.clear();
            this.showAction.emit({ mode: "VIEW", data: null });
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.sweetAlert.errorMessage(
          this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  //submit the data
  public submit() {
    this.spinner.show();
    let wholeData: any = {};
    Object.assign(wholeData, this.step1form.getRawValue());
    Object.assign(wholeData, this.step2form.getRawValue());
    let mode = this.actionMode || "ADD";
    this.ds
      .postActionByUrl(this.ds.json2formdata(wholeData), "ctemplate/" + mode)
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.alert.success(res.msg);
            this.step1form.reset();
            this.step2form.reset();
            setTimeout(() => {
              this.alert.clear();
              this.showAction.emit({ mode: "VIEW", data: null });
            }, 1000);
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.sweetAlert.errorMessage(
            this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  public build_units_string() {
    let message = ""; //this.lang.transform('lang_selected_units');
    let data = this.unitvalidate.getRawValue();
    if (data && data.length > 0) {
      data.forEach((v, k) => {
        message += " ( " + v.unit_from + " - " + v.unit_to + " ) ";
      });
      this.selectedunitsmsg = message;
    } else {
      this.selectedunitsmsg = "";
    }
  }
  public makesum(a, b) {
    let c: any = parseFloat(a) + parseFloat(b);
    return parseFloat(c).toFixed(2);
  }
  //eidt template Works
  public build_units_for_edit(template_edit_data) {
    if (
      template_edit_data &&
      template_edit_data.phase_zone_block_cluster_g_nos
    ) {
      let units = [];
      template_edit_data.phase_zone_block_cluster_g_nos
        .split(",")
        .forEach((v, k) => {
          units.push({
            label: this.lang.transform("lang_units") + " " + v,
            value: v,
          });
        });
      this.project_units = units;
    }
  }
  public build_edit_data() {
    // this.group1formarray.controls = [];
    // this.group1validate.controls = [];
    // if(this.template_edit_data && this.template_edit_data.template_json_data) {
    //   let editdata = JSON.parse(this.template_edit_data.template_json_data);
    //   editdata.group1Data.forEach((v, k) => {
    //     this.group1formarray.push(this.group1_records({
    //       group1count                         : v.platform_group1_count,
    //       platform_group1_code_system         : v.platform_group1_code_system,
    //       platform_group1_code_manual         : v.platform_group1_code_manual,
    //       group1name                          : v.platform_group1_code_title,
    //       platform_group1_code_total          : v.platform_group1_code_total,
    //       platform_group1_code_total_ratio    : v.platform_group1_code_total_ratio,
    //       system_total_amount                 : v.system_total_amount,
    //       manual_total_amount                 : v.manual_total_amount,
    //       system_total_ratio                  : v.system_total_ratio,
    //       manual_total_ratio                  : v.manual_total_ratio,
    //       typesData                           : v.typesData
    //     }));
    //   });
    //   //this.step2form.patchValue(JSON.parse(this.template_edit_data.template_json_data));
    // }
  }
  //step 2 End

  getTemplateView() {
    this.ds
      .getActionByUrl(
        [],
        `vtemplate/${this.supervision_data.projects_profile_id}/${this.supervision_data.projects_supervision_id}/${this.template_id}`
      )
      .subscribe(
        (res) => {
          if (res.status) {
            this.totalunitspend =
              res?.template?.phase_zone_block_cluster_g_nos?.split(",")?.length;
            this.fmanual_total_ratio = res?.template?.manual_total_ratio;
            this.fsystem_total_ratio = res?.template?.system_total_ratio;
            this.each_unit_cost_of_template =
              res?.template?.each_unit_cost_of_template;

            this.generate_fields(res?.platformsTree);
          }
        },
        (err) => {
          this.sweetAlert.errorMessage(
            this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }

  getTypeRatios(e, indx) {
    this.group1validate.controls[indx]
      .get("platform_group1_code_total_ratio")
      .setValue(e?.total_ratio);
    this.group1validate.controls[indx]
      .get("platform_group1_code_total")
      .setValue(e?.total_price);
  }
}
