import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { environment } from "@env/environment";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { PreloaderService } from "@core/bootstrap/preloader.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
  selector: "app-finance-report",
  templateUrl: "./finance-report.component.html",
  styleUrls: ["./finance-report.component.scss"],
})
export class FinanceReportComponent implements OnInit {
  @Input() user_data;
  viewData: any;
  data: any;
  apage = 1;
  apsize = "10";
  history: any;
  boolenStatus = [
    { title: "paid", value: "true" },
    { title: "unpaid", value: "false" },
  ];
  status = [
    { title: "paid", value: "paid" },
    { title: "unpaid", value: "unpaid" },
  ];
  statusLists = "true";
  idfilename = this.lang.transform("lang_select_files");
  attachform: FormGroup;
  modalRefA: BsModalRef;
  modalRefB: BsModalRef;
  uform: FormGroup;
  templetVeiw: TemplateRef<any>;
  is_admin: any = "";
  constructor(
    public router: Router,
    public ds: DataService,
    public route: ActivatedRoute,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {
    this.is_admin = this.ls.getItemString("is_admin");
  }

  ngOnInit(): void {
    this.getEmployeeInfo();
    this.update_form();
  }
  getEmployeeInfo() {
    let param = new FormData();
    param.append("employee_number", this.user_data.employee_number);
    param.append("paid_status", this.statusLists);
    this.ds
      .postActionByUrl(
        param,
        `human_resources/get_employee_finance_report/${this.apage}/${this.apsize}`
      )
      .subscribe(
        (res) => {
          if (res.status) {
            this.spinner.hide();
            this.data = res;
          } else {
            this.spinner.hide();
            this.data = res;
          }
        },
        (error) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
  }
  getFinanceHistory(finance_id) {
    this.spinner.show();
    let param = new FormData();
    param.append("finance_id", finance_id);
    this.ds
      .postActionByUrl(
        param,
        `/human_resources/get_finance_history/${this.apage}/${this.apsize}`
      )
      .subscribe(
        (res) => {
          if (res.status) {
            this.spinner.hide();
            this.history = res;
            this.openModalB(this.templetVeiw);
          } else {
            this.spinner.hide();
            this.ds.dialogf("", this.lang.transform("lang_no_data"));
          }
        },
        (error) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
  }
  public reportpage(size) {
    this.apsize = size;
    this.apage = 1;
    this.getEmployeeInfo();
  }
  public reportlength(page) {
    this.apage = page;
    this.getEmployeeInfo();
  }
  public update_form() {
    this.attachform = new FormGroup({
      paid_status: new FormControl("paid", [Validators.required]),
      finance_id: new FormControl("", [Validators.required]),
      attachment: new FormControl("", [Validators.required]),
    });
  }
  public setFinanceId(finance_id) {
    this.attachform.get("finance_id").setValue(finance_id);
  }
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public setTemplet(template: TemplateRef<any>) {
    this.templetVeiw = template;
  }
  public openModalB(template: TemplateRef<any>) {
    this.modalRefB = this.modalService.show(template, environment.modelconfig);
  }
  public uploadIDFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      let max_size = 5 * 1024 * 1024; // 5MB
      const fileid = event.target.files[0];
      if (fileid?.size > max_size) {
        this.sweetAlert.errorToast(
          this.lang.transform("lang_file_uploaded_size_error"),
          3000
        );
        return 0;
      } else {
        this.idfilename = fileid.name;
        this.attachform.get("attachment").setValue(fileid, { emitEvent: true });
      }
    } else {
      this.attachform.get("attachment").setValue(null);
      this.idfilename = this.lang.transform("lang_select_files");
    }
  }
  public UpdateFinance() {
    this.spinner.show();
    this.ds
      .postActionByUrl(
        this.ds.json2formdata(this.attachform.value),
        "human_resources/update_finance_paid_unpaid"
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.getEmployeeInfo();
            setTimeout(() => {
              this.modalRefA.hide();
            }, 1000);
            this.alert.success(data.msg);
          } else {
            this.alert.error(data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error.error + (error && error.error && error.error.error)
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  public MakeApproveFinance(approve_status, paid_history_id, finance_id) {
    this.spinner.show();
    this.modalRefB.hide();
    let param = new FormData();
    param.append("manager_approval", approve_status);
    param.append("paid_history_id", paid_history_id);
    this.ds
      .postActionByUrl(param, "human_resources/approve_finance_transaction")
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.sweetAlert.successToast(data?.msg, 2000);
            this.getFinanceHistory(finance_id);
          } else {
            this.sweetAlert.errorMessage(data?.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.sweetAlert.errorMessage(
            error.error + (error && error.error && error.error.error)
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
}
