import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { Subscription } from "rxjs";
import { AccountSettingsComponent } from "./account-settings/account-settings.component";
import { FinanceyearComponent } from "./financeyear/financeyear.component";
import { InvoiceSettingsComponent } from "./invoice-settings/invoice-settings.component";
import { PermitSettingsComponent } from "./permit-settings/permit-settings.component";
import { TaxSettingsComponent } from "./tax-settings/tax-settings.component";
import { ReportsGroupsComponent } from "./reports-groups/reports-groups/reports-groups.component";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit, OnDestroy {
  @ViewChild(AccountSettingsComponent) account: AccountSettingsComponent;
  @ViewChild(InvoiceSettingsComponent) invoice: InvoiceSettingsComponent;
  @ViewChild(TaxSettingsComponent) tax: TaxSettingsComponent;
  @ViewChild(PermitSettingsComponent) permit: PermitSettingsComponent;
  @ViewChild(FinanceyearComponent) financial: FinanceyearComponent;
  @ViewChild(ReportsGroupsComponent) reportsGroups: ReportsGroupsComponent;

  branches: any = [];
  selectedBranch = localStorage.getItem("selectedBranch");
  taxform: FormGroup;
  finances: any = [];
  finance_id: any = null;
  constructor(public ds: DataService, public spinner: PreloaderService) {}
  ngOnInit(): void {
    this.load_branches();
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
            this.load_financial_years();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public selectBranch(branch) {
    if (branch && branch != "0") {
      localStorage.setItem("selectedBranch", branch);
      this.selectedBranch = branch;
      this.account.changeBranch(branch);
      this.permit.changeBranch(branch);
      this.tax.changeBranch(branch);
      this.invoice.changeBranch(branch);
      this.financial.changeBranch(branch);
      this.reportsGroups.changeBranch(branch);
      this.load_financial_years();
    }
  }

  selectFinance() {
    this.account.onChangeFinance(this.finance_id);
    this.invoice.selectFinance(this.finance_id);
    this.reportsGroups.selectFinance(this.finance_id);
  }

  load_financial_years() {
    this.finances = [];
    this.finance_id = null;
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.selectedBranch).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
                this.selectFinance();
              }
            });
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
}
