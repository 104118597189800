<h3 mat-dialog-title class="d-flex justify-content-between align-items-center">
	<span>{{ "lang_timeline_advanced_filter" | language }}</span>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</h3>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="filterForm">
		<div class="row">
			<div class="col-12 mt-1">
				<label for="lang_nationality_type">{{
					"lang_priority" | language
				}}</label>
				<ng-select
					[items]="metaData.priority_list"
					[multiple]="false"
					appendTo="body"
					bindLabel="name"
					bindValue="id"
					clearAllText="Clear"
					[searchable]="false"
					placeholder="{{ 'lang_priority' | language }}"
					formControlName="important_task"
					class="mt-1"
				>
				</ng-select>
			</div>
			<div class="col-12 mt-1">
				<label for="lang_nationality">{{ "lang_type" | language }}</label>
				<ng-select
					[items]="metaData?.type_list"
					[multiple]="false"
					appendTo="body"
					bindLabel="name"
					bindValue="id"
					clearAllText="Clear"
					[searchable]="false"
					placeholder="{{ 'ticket_type' | language }}"
					formControlName="ticket_type"
					class="mt-1"
				>
				</ng-select>
			</div>

			<div class="col-12 mt-1">
				<label for="lang_iqama_expiry_date">{{
					"lang_task_date" | language
				}}</label>

				<mat-form-field appearance="outline" style="width: 100%">
					<!-- <mat-label>{{'lang_iqama_expiry_date'|language}}</mat-label> -->
					<mat-date-range-input [rangePicker]="picker">
						<input
							matStartDate
							formControlName="start_date_task"
							readonly
							placeholder="{{ 'lang_task_start' | language }}"
						/>
						<input
							matEndDate
							formControlName="end_date_task"
							readonly
							placeholder="{{ 'lang_task_end' | language }}"
						/>
					</mat-date-range-input>
					<mat-datepicker-toggle
						matSuffix
						[for]="picker"
					></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>

					<mat-error
						*ngIf="
							filterForm.controls.start_date_task?.hasError(
								'matStartDateInvalid'
							)
						"
						>Invalid start date</mat-error
					>
					<mat-error
						*ngIf="
							filterForm.controls.end_date_task?.hasError('matEndDateInvalid')
						"
						>Invalid end date</mat-error
					>
				</mat-form-field>
			</div>

			<div class="col-12 mt-1">
				<label for="lang_iqama_expiry_date">{{
					"lang_ticket_date" | language
				}}</label>
				<mat-form-field appearance="outline" style="width: 100%">
					<!-- 	<mat-label>{{'lang_passport_expiry_date'|language}}</mat-label> -->
					<mat-date-range-input [rangePicker]="picker2">
						<input
							matStartDate
							formControlName="start_date_ticket"
							readonly
							placeholder="{{ 'lang_ticket_start' | language }}"
						/>
						<input
							matEndDate
							formControlName="end_date_ticket"
							readonly
							placeholder="{{ 'lang_ticket_end' | language }}"
						/>
					</mat-date-range-input>
					<mat-datepicker-toggle
						matSuffix
						[for]="picker2"
					></mat-datepicker-toggle>
					<mat-date-range-picker #picker2></mat-date-range-picker>

					<mat-error
						*ngIf="
							filterForm.controls.start_date_ticket?.hasError(
								'matStartDateInvalid'
							)
						"
						>Invalid start date</mat-error
					>
					<mat-error
						*ngIf="
							filterForm.controls.end_date_ticket?.hasError('matEndDateInvalid')
						"
						>Invalid end date</mat-error
					>
				</mat-form-field>
			</div>

			<div class="col-12 mt-1">
				<label for="ticket_no">{{ "lang_ticket_no" | language }}</label>
				<input
					type="text"
					class="form-control"
					id="ticket_no"
					name="ticket_no"
					formControlName="ticket_no"
				/>
			</div>

			<div class="col-12 mt-1">
				<label for="task_no">{{ "lang_task_no" | language }}</label>
				<input
					type="text"
					class="form-control"
					id="task_no"
					name="task_no"
					formControlName="task_no"
				/>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="d-flex justify-content-between" style="width: 100%">
		<div style="width: 100px">
			<button type="button" class="albutton" (click)="resetFilterForm()">
				{{ "lang_reset" | language }}
			</button>
		</div>

		<div style="width: 100px">
			<button
				mat-button
				class="albutton mnabrbutton mat-accent"
				(click)="sendFilterForm()"
			>
				{{ "lang_save" | language }}
			</button>
		</div>
	</div>
</mat-dialog-actions>
