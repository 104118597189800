import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";

@Component({
	selector: "app-report1-modal",
	templateUrl: "./report1-modal.component.html",
	styleUrls: ["./report1-modal.component.scss"],
})
export class Report1ModalComponent implements OnInit {
	projects_work_area_id: any;
	dataList = null;
	dataList_msg = "";
	page_no = 1;
	page_size = 10;
	constructor(
		private dialogRef: MatDialogRef<Report1ModalComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private ds: DataService,
		private spinner: PreloaderService
	) {
		this.projects_work_area_id = data?.projects_work_area_id;
	}

	ngOnInit(): void {
		this.getList();
	}

	getList() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("unit_id", this.data.unit_id || "");
		formData.append("group_1", this.data.group_1 || "");
		formData.append("request_status", this.data.request_status || "");
		formData.append("from_trigger", this.data.isTrigger ? "1" : "0");
		this.ds
			.post(
				`pr/get_used_unused_requests_details/${this.page_no}/${this.page_size}`,
				formData
			)
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.dataList = res;
				} else {
					this.dataList_msg = res?.error;
				}
			});
	}
}
