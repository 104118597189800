import { Injectable, OnDestroy } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { DataService } from "./data.service";
import { LocalStorage } from "./localstorage.service";

@Injectable({
	providedIn: "root",
})
export class MessagingService implements OnDestroy {
	currentMessage = new BehaviorSubject(null);
	fireToken = "";
	loggedin = localStorage.getItem("loggedin");
	constructor(
		private angularFireMessaging: AngularFireMessaging,
		private ls: LocalStorage,
		private _snackBar: MatSnackBar,
		private sweetAlert: SweetAlertService,
		private ds: DataService
	) {
		this.angularFireMessaging.messages.subscribe((_messaging: any) => {
			_messaging.onMessage =
				_messaging.onMessage.bind(_messaging) ||
				_messaging.onBackgroundMessage.bind(_messaging);
			_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
			this.fireToken = _messaging.token;
		});
		if (this.loggedin == "1" && navigator.userAgent.indexOf("Chrome") != -1) {
			this.subscriptions.add(
				this.angularFireMessaging.tokenChanges.subscribe((token) => {
					console.log("updated noti token", token);

					let formData = new FormData();
					formData.append("noti_registrationId", token);
					this.subscriptions.add(
						this.ds
							.post("user/update/noti_registrationId", formData)
							.subscribe((res) => {})
					);
				})
			);
		}
	}
	requestPermission() {
		this.subscriptions.add(
			this.angularFireMessaging.requestToken.subscribe(
				(token) => {
					console.log(token);
					if (
						this.loggedin == "1" &&
						navigator.userAgent.indexOf("Chrome") != -1
					) {
						let formData = new FormData();
						formData.append("noti_registrationId", token);
						this.subscriptions.add(
							this.ds
								.post("user/update/noti_registrationId", formData)
								.subscribe((res) => {})
						);
					}
					this.fireToken = token;
				},
				(err) => {
					console.error("Unable to get permission to notify.", err);
				}
			)
		);
	}
	receiveMessage() {
		return this.subscriptions.add(
			this.angularFireMessaging.messages.subscribe((message) => {
				console.log(message);

				this.currentMessage.next(message);
				this.openSnackBar(message);
				let audio = new Audio("../../../assets/audios/notification3.mp3");
				audio.play();
			})
		);
	}
	openSnackBar(message: any) {
		this.sweetAlert.notifyToast(message, 5000);
	}

	getNotifications(page) {
		let params = new FormData();
		params.append("page", `${page}`);
		return this.ds.getActionByUrl(
			this.ds.formData2string(params),
			"notification/get_noti_list"
		);
	}

	private subscriptions = new Subscription();
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
