<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>Send Code</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-12 mb-2" *ngIf="type == 'phone'">
			<label for="phone_num">Phone Number</label>
			<input
				type="text"
				id="phone_num"
				name="phone_num"
				[(ngModel)]="phone_num"
				(keypress)="isNumber($event)"
				class="form-control"
			/>
		</div>
		<div class="col-12 mb-2" *ngIf="phone_num && type == 'phone'">
			<label id="sender_type"></label>
			<mat-radio-group
				aria-labelledby="sender_type"
				[(ngModel)]="sender_type"
				class="example-radio-group"
			>
				<mat-radio-button value="phone" class="example-radio-button">
					{{ "lang_phone" | language }}
				</mat-radio-button>
				<mat-radio-button value="whatsapp" class="example-radio-button">
					{{ "lang_whatsapp" | language }}
				</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="col-12 mb-2" *ngIf="type == 'code'">
			<input
				type="text"
				id="code"
				name="code"
				[(ngModel)]="code"
				(keypress)="isNumber($event)"
				class="form-control"
			/>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-12 col-sm-3">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-12 col-sm-6"></div>
	<div class="col-12 col-sm-3">
		<button
			type="submit"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="onSubmit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
