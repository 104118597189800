import { Component, Input, OnInit } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
	FormArray,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SendCodeComponent } from "app/routes/transactions/FORM_PS_TR1/send-code/send-code.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
	selector: "app-si-step4",
	templateUrl: "./si-step4.component.html",
	styleUrls: ["./si-step4.component.scss"],
})
export class SiStep4Component implements OnInit {
	modalRefA: BsModalRef;
	stepName = "Contractor_Team_Approval";
	@Input() form_key;
	// @Input() form_request_id;
	form_request_id = this.route.snapshot.paramMap.get("form_request_id");
	form_key2 = this.route.snapshot.paramMap.get("form_key");
	@Input() project_data;
	@Input() supervision_data;
	@Input() template_data;
	@Input() allData;
	sendCodeForm: any;
	sendCodeStatus: boolean = false;
	rejectedValidators = [];
	verifyerrormsg: any = "";
	form: FormGroup;
	users: any = [];
	filename = this.lang.transform("lang_select_files");
	page = 1;
	psize = 10;
	si_name_array = [];
	constructor(
		public ds: DataService,
		public router: Router,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.form = new FormGroup({
			transaction_request_id: new FormControl(
				this.form_request_id,
				Validators.required
			),
			si_name: new FormControl({ value: null, disabled: true }),
			new_si_name: new FormControl({ value: null, disabled: true }),
			action_taken: new FormControl(""),
			attachments: new FormArray([]),
			transactions_persons_action_code: new FormControl("", [
				Validators.required,
			]),
		});

		// demo code
		this.si_name_array = [
			{
				label: "work one",
				value: 1,
			},
			{
				label: "work two",
				value: 2,
			},
			{
				label: "work three",
				value: 3,
			},
		];
	}

	getAttachments(data) {
		let other_assistance_files = this.form.get("attachments") as FormArray;
		other_assistance_files.clear();
		for (let file of data.form.controls) {
			other_assistance_files.push(file);
		}
	}

	confirmVerification() {
		this.spinner.show();
		this.ds
			.postActionByUrl(
				this.ds.json2formdata({
					transaction_request_id: this.sendCodeForm.transaction_request_id,
					transactions_persons_action_code: this.form.get(
						"transactions_persons_action_code"
					).value,
				}),
				"tc/sender/complete_action"
			)
			.subscribe(
				(data) => {
					this.spinner.hide();
					if (data.status) {
						this.alert.success(this.lang.transform("lang_success"));
						setTimeout(() => {
							window.location.reload();
						}, 1000);
					} else {
						this.alert.error(data.error);
						this.verifyerrormsg = data.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}

	completeStep2() {
		if (this.form.valid) {
			this.spinner.show();

			this.ds
				.post(
					`/form/${this.form_key}/Contractor_Team_Approval/0`,
					this.form.value
				)
				.subscribe(
					(data) => {
						if (data.status) {
							this.spinner.hide();
							this.alert.success("data sent successfully");
						} else {
							this.spinner.hide();
							this.alert.error("something wrong");
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(error);
					}
				);
		}
	}

	openModal() {
		const dialogRef = this.dialog.open(SendCodeComponent, {
			disableClose: true,
			width: "400px",
			data: {
				transaction_persons_type: "signature",
				allData: this.allData,
				stepName: this.stepName,
				title: "lang_signature",
			},
		});
		dialogRef.afterClosed().subscribe((data) => {
			this.sendCodeForm = data.formValue;
			this.sendCodeStatus = data.sendstatus;
		});
	}
}
