import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LanguagePipe } from '@shared/pipes/language.pipe';

@Component({
  selector: 'app-chart-data-information',
  templateUrl: './chart-data-information.component.html',
  styleUrls: ['./chart-data-information.component.scss']
})
export class ChartDataInformationComponent implements OnInit {
  lang_key = localStorage.getItem('lang_key') ? localStorage.getItem('lang_key') : 'en';
  emps:any[] = []
  constructor(public dialogRef: MatDialogRef<ChartDataInformationComponent>,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any,   private lang:LanguagePipe) {
      this.emps = this.data.emps
     }
  

  ngOnInit(): void {
  }
  close(): void {
    this.dialogRef.close();
  }

  navigate(item){
  
    this.router.navigate(['/human_resources/view/'+item.employee_number+'/view/'+item.branch_id])
    this.close() 
  
  }
}
