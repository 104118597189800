<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_test_report" | language }}</h4>
		<span (click)="closeModal()"><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
        <div class="col-md-12 mt-2">
			<div
				class="table-responsive customResponsive tablefields quotationitemtable"
			>
				<table
					border="1"
					class="table CustomTable"
					width="100%"
				>
					<thead>
						<tr>
							<td style="padding: 5px; width: 5%" class="text-center">
								#
							</td>
							<td style="padding: 5px;width: 15%" class="text-center">
								{{ "lang_sample_number" | language }} <span class="red">*</span>
							</td>
							<td style="padding: 5px;" class="text-center">
								{{ "lang_strength_result" | language }} <span class="red">*</span>
							</td>
							<td style="padding: 5px;width: 15%" class="text-center" *ngIf="list_type == 'soil'">
								{{ "lang_layer_number" | language }} <span class="red">*</span>
							</td>
							<td style="padding: 5px;width: 15%" class="text-center" *ngIf="list_type == 'soil'">
								{{ "lang_depth_number" | language }} <span class="red">*</span>
							</td>
							<td style="padding: 5px;width: 25%" class="text-center" *ngIf="list_type == 'soil'">
								{{ "lang_material_description" | language }} <span class="red">*</span>
							</td>
							<td style="padding: 5px;width: 25%" class="text-center" *ngIf="list_type == 'soil'">
								{{ "lang_percent_compaction" | language }} <span class="red">*</span>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="
								let item of historyViewData?.records;
								let i = index;
								let odd = odd;
								let even = even
							"
						>
							<td
								class="verticalalignmid text-center"
								style="padding: 5px; word-break: break-all"
							>
								{{ i + 1 }}
							</td>
							
							<td
								style="padding: 5px; position: relative"
								class="text-center"
							>
								{{item?.sample_number}}
							</td>
							<td
								style="padding: 5px; position: relative"
								class="text-center"
							>
							{{item?.strength_result}}
							</td>
							<td
								style="padding: 5px; position: relative"
								class="text-center"
								*ngIf="list_type == 'soil'"
							>
							{{item?.layer_number}}
							</td>
							<td
								style="padding: 5px; position: relative"
								class="text-center"
								*ngIf="list_type == 'soil'"
							>
							{{item?.depth_number}}
							</td>
							<td
								style="padding: 5px; position: relative"
								class="text-center"
								*ngIf="list_type == 'soil'"
							>
							{{item?.material_description}}
							</td>
							<td
								style="padding: 5px; position: relative"
								class="text-center"
								*ngIf="list_type == 'soil'"
							>
							{{item?.percent_compaction}}
							</td>
							
						</tr>
					</tbody>
				</table>
			</div>
			<div class="col-12 mt-3">
				<p class="mb-10">
					{{ "lang_attachments" | language }}
				</p>
				<div
					class="table-responsive customResponsive tablefields quotationitemtable"
				>
					<table
						border="1"
						class="table CustomTable"
						width="100%"
					>
						<thead>
							<tr>
								<td
									width="2%"
									style="padding: 5px; word-break: break-all"
								>
									#
								</td>
								<td
									width="50%"
									style="padding: 5px; word-break: break-all"
								>
									{{ "lang_title" | language }}
								</td>
								<td
									width="20%"
									style="padding: 5px; word-break: break-all"
								>
									{{ "lang_date" | language }}
								</td>
								<td
									width="20%"
									style="padding: 5px; word-break: break-all"
								>
									{{ "lang_file" | language }}
								</td>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let file of historyViewData?.files;
									let i = index;
									let odd = odd;
									let even = even
								"
							>
								<td
									class="verticalalignmid"
									style="padding: 5px; word-break: break-all"
								>
									{{ i + 1 }}
								</td>
								<td
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									{{file?.file_name_en}}
								</td>
								<td
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									{{file?.created_datetime}}
								</td>
								<td
									class="verticalalignmid"
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									<i class="fa fa-file-pdf-o" (click)="previewPDF(file?.file_path)"></i>
								</td>
								
							</tr>
						</tbody>
					</table>
					
				</div>
			</div>
		</div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="closeModal()"
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</mat-dialog-actions>
