import { Component, Input, OnInit } from "@angular/core";
import { PreloaderService } from "@core";
import { MatDialog } from "@angular/material/dialog";
import { PreviewPdfComponent } from "@shared/components/preview-pdf/preview-pdf.component";
import { DataService } from "@core/bootstrap/data.service";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { Router } from "@angular/router";

interface list {
  id?: number;
  transaction_request_id: string;
  transactions_records_datetime: string;
  transactions_records_id: string;
  transactions_records_note: string;
  transactions_records_user_id: string;
  transactions_records_user_name: string;
}
@Component({
  selector: "app-form-ste-view",
  templateUrl: "./form-ste-view.component.html",
  styleUrls: ["./form-ste-view.component.scss"],
})
export class FORMSTEVIEWComponent implements OnInit {
  constructor(
    public spinner: PreloaderService,
    public lang: LanguagePipe,
    public dialog: MatDialog,
    public ds: DataService,
    private sweetAlert: SweetAlertService,
    public alert: AlertService,
    public router: Router
  ) {}
  @Input() actionPage;
  @Input() form_key;
  @Input() form_request_id;
  All_Attachments = [];
  page = 1;
  pageSize = 10;
  actionbuttons: any = [];
  request: any = [];
  viewdata: any = [];
  files: any = [];
  dates: any = [];
  notes: any = [];
  persons: any = [];
  history: any = [];
  additionalterms: any = [];
  otherallowances: any = [];
  barcode: any = [];
  collectionSize;
  listofdatas: list[];
  allData = "";
  submitter: any = [];
  ngOnInit(): void {
    this.get_data_view();
  }
  public preview_form_data(isView: boolean) {
    let url = "form/" + this.form_key + "/pr/" + this.form_request_id;
    if (isView) {
      url = this.request?.view_link;
    }
    this.spinner.show();
    this.ds.getActionByUrl([], url).subscribe((res) => {
      this.spinner.hide();
      if (res.status) {
        if (res.extension == "pdf") {
          let src = "data:" + res["content-type"] + ";base64," + res.base64;
          const dialogRef = this.dialog.open(PreviewPdfComponent, {
            disableClose: false,
            width: "100%",
            data: {
              src: src,
              base64: res.base64,
            },
          });
        } else {
          this.sweetAlert.errorMessage("The attachment must be a PDF");
        }
      }
    });
    this.preview();
  }
  preview(data = "form/" + this.form_key + "/pr/" + this.form_request_id) {
    this.ds.getActionByUrl([], data).subscribe(
      (res) => {
        if (res.status) {
          if (res.extension == "pdf") {
            let src = "data:" + res["content-type"] + ";base64," + res.base64;
            const dialogRef = this.dialog.open(PreviewPdfComponent, {
              disableClose: false,
              width: "100%",
              data: {
                src: src,
                base64: res.base64,
              },
            });
          } else {
            this.ds.showPreviewWithoutAPi(res);
          }
        } else {
          this.alert.error(res.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.alert.error(
          error.error + (error && error.error && error.error.error)
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  public get_data_view() {
    console.log("get_data_view");
    this.viewdata = [];
    this.ds
      .getActionByUrl(
        [],
        "form/" + this.form_key + "/vr/" + this.form_request_id
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.allData = res;
            this.request = res.transactions_request
              ? res.transactions_request
              : [];
            this.submitter = res.transactions_submitter.status
              ? res.transactions_submitter.records
              : "";
            this.actionbuttons = res?.transactions_buttons;
            if (res?.form_ste_data?.status) {
              this.viewdata = res?.form_ste_data?.records[0];
            }
            if (res?.transactions_persons?.status) {
              this.persons = res?.transactions_persons?.records;
            }
            if (res?.transactions_records?.status) {
              this.history = res?.transactions_records?.records;
            }
          } else {
            this.ds.dialogf("", res.error);
            this.router.navigate(["transactions/allforms/"]);
          }
        },
        (error) => {
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  public refreshLists() {
    this.history = this.listofdatas
      .map((list, i) => ({ id: i + 1, ...list }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }
}
