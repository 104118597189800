import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { SendCodeComponent } from 'app/routes/transactions/FORM_PS_TR1/send-code/send-code.component';

@Component({
  selector: 'app-ncr-step11',
  templateUrl: './ncr-step11.component.html',
  styleUrls: ['./ncr-step11.component.scss']
})
export class NcrStep11Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  stepName = 'Receipient_Team_Reply_Step11'
  @Input() form_key;
  form_request_id   = this.route.snapshot.paramMap.get('form_request_id');
  form_key2          = this.route.snapshot.paramMap.get('form_key');
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  template_id:any;
  projects_work_area_id:any; 
  platform_code_system:any;
  form:FormGroup;
  lang_key:string;
  close_ncr_request_arr = [
    {
      label:this.lang.transform("lang_close"),
      value: 'B'
    },
    {
      label:this.lang.transform("lang_open"),
      value: 'D'
    }
  ];
  manager_contractor_required;
  stepNameTitle = this.lang.transform('lang_contractor_close_NCR');
  constructor(public ds: DataService, public router: Router, private sweetAlert:SweetAlertService, public route: ActivatedRoute, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id           : new FormControl(this.form_request_id, Validators.required),
      close_ncr_request_status         : new FormControl(null, [Validators.required]),
      note                             : new FormControl(''),
      // transactions_persons_action_code : new FormControl('', [Validators.required])
    });
    this.manager_contractor_required = this.allData?.view_request?.form_ncr_data?.records[0]?.manager_contractor_required; 
    if(!parseInt(this.manager_contractor_required)) {
      this.stepNameTitle = this.lang.transform('lang_consultant_confirm_close_ncr');
    }
    
  }

  
  checkValidation() {
    if(this.form.get('close_ncr_request_status').invalid) {
      this.sweetAlert.errorMessage(this.lang.transform('lang_close_ncr_request_required'));
      return false;
    } 
    // else if(this.form.get('transactions_persons_action_code').invalid) {
    //   this.sweetAlert.errorMessage(this.lang.transform('lang_send_code_required'));
    //   return false;
    // } 
    else {
      return true;
    }  
  }

  completeStep() {
    if(this.checkValidation()) {
      this.spinner.show();
      let param = new FormData();
      param.append('transaction_request_id', this.form_request_id)
      param.append('close_ncr_request_status', this.form.get('close_ncr_request_status').value);
      param.append('close_ncr_request_notes', this.form.get('note').value);
      // param.append('transactions_persons_action_code', this.form.get('transactions_persons_action_code').value);
      
      this.ds.post(`/form/${this.form_key}/Recipient_Team_Reply_Step11/0`, param).subscribe(data => {
        if(data.status) {
          this.spinner.hide();
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
          setTimeout(() => {
            this.refreshComponent.emit(true);
          }, 2000);
        } else {
          this.spinner.hide();
          this.alert.error(data.error)
        }
      }, (error) => {
        this.spinner.hide();
          this.alert.error(this.lang.transform('lang_server_error'));
      })
    } 
  }

  openModal() {
    const dialogRef = this.dialog.open(SendCodeComponent,{
      disableClose: true,
      width:'400px',
      data: {transaction_persons_type: 'signature', allData: this.allData, stepName:this.stepName,title:'lang_signature'}
      
    });
  }
}
