<breadcrumb></breadcrumb>

<section class="panel-expansion">
  <mat-accordion class="stylepanel groups_data">
    <mat-expansion-panel class="mpanel" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_shift_times" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body tlist">
        <div class="mbrl15 fromtransactions">
          <div class="row">
            <div class="col-md-12">
              <div class="View-title row col-md-12 m-auto">
                <div class="col-12 col-sm-4 mb-2">
                  <button
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="
                      closeOpenMonthViewDay('previous'); loadShiftsOnChange()
                    "
                    class="albutton mnabrbutton"
                    mat-button
                  >
                    {{ "lang_previous" | language }}
                  </button>
                </div>
                <div class="col-12 col-md-4 text-center">
                  <h3 style="padding: 5px 60px">
                    {{ viewDate | calendarDate: view + "ViewTitle":"en" }}
                  </h3>
                </div>

                <div class="col-12 col-sm-4 mb-2">
                  <button
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="
                      closeOpenMonthViewDay('next'); loadShiftsOnChange()
                    "
                    class="albutton mnabrbutton"
                    mat-button
                  >
                    {{ "lang_next" | language }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-12 booking-pages ov_flow">
              <div
                class="Calendar-view"
                [ngClass]="{ 'backgorund-calender': activeDayIsOpen }"
              >
                <mwl-calendar-month-view
                  [viewDate]="viewDate"
                  class="scroll-auto"
                  style="width: 100%"
                  [events]="form.value.month_shifts[index]?.times"
                  [refresh]="refresh"
                  [activeDayIsOpen]="activeDayIsOpen"
                  (dayClicked)="dayClicked($event.day)"
                  (eventClicked)="handleEvent('Clicked', $event.event)"
                  (eventTimesChanged)="eventTimesChanged($event)"
                  [cellTemplate]="monthCellTemplate"
                >
                  {{ "Month" | language }}
                </mwl-calendar-month-view>
              </div>
            </div>
          </div>

          <ng-template #monthCellTemplate let-day="day" let-locale="locale">
            <span
              class="cal-day-number"
              style="letter-spacing: 0px; color: #5c606b !important"
              >{{ day.date | date: "dd" }}
            </span>
            <ng-container *ngFor="let item of day.events; let i = index">
              <ng-container *ngIf="i == 0 || i == 1">
                <div
                  class="event event-details-month"
                  style="width: 98%; text-align: start"
                  [ngClass]="[
                    item?.shift_time_day_off == 1
                      ? 'event-details__confirmed'
                      : item?.shift_time_day_off == 0 && item?.shift_start_time
                      ? 'event-details__available'
                      : item?.shift_time_day_off == 0 && !item?.shift_start_time
                      ? 'event-details__cancelled'
                      : ''
                  ]"
                  [contextMenu]="basicMenu"
                  [contextMenuSubject]="item.data"
                >
                  <span>
                    <span
                      class="calendar-time-details"
                      style="
                        font-weight: bold;

                        font-size: 10px;
                      "
                    >
                      <!--  <span *ngIf="item.data.status != 'vacation'"> 
                                            {{item.start | date: "hh:mm a"}}  
                                        </span> -->

                      <span
                        style="
                          margin-left: 5px;
                          font-weight: bold;
                          font-family: 'Times New Roman', Times, serif;
                          font-size: 10px;
                        "
                      >
                        <span
                          *ngIf="item.shift_start_time && item.shift_end_time"
                        >
                          {{ "lang_from" | language }}:
                          {{ ShowTime(item.shift_start_time) }}
                          {{ "lang_to" | language }}:
                          {{ ShowTime(item.shift_end_time) }}
                        </span>

                        <span
                          *ngIf="
                            (!item.shift_start_time || !item.shift_end_time) &&
                            !item.shift_time_day_off
                          "
                        >
                          {{ "lang_click_to_add_shift" | language }}
                        </span>

                        <span
                          *ngIf="
                            (!item.shift_start_time || !item.shift_end_time) &&
                            item.shift_time_day_off
                          "
                        >
                          {{ "lang_day_off" | language }}
                        </span>
                      </span>

                      <!--  <span style=" margin-left:5px; font-weight: bold;
                                            font-family: 'Times New Roman', Times, serif;
                                            font-size: 13px;" *ngIf="item.data.type != 'booking'">
                                                {{item.title}}
                                            </span> -->
                    </span>
                    <!--  <span class="fl-rit" *ngIf="item.data.status === 'available' && tutorId != '0'">
                                            <span (mwlClick)="edit(item)"><i class="far fa-edit custom_fontSize color-edit mr-7"></i></span>                    
                                            <span (mwlClick)="onDeleteSession(item)"><i class="far light-brown fa-trash-alt custom_fontSize mr-5"></i></span>
                                        </span> -->
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </ng-template>

          <div
            class="row col-md-12 attendence mb-4"
            style="justify-content: space-between"
          >
            <div class="col-md-3 mb-2">
              <button
                type="button"
                routerLink="/human_resources/attendances/all-shifts"
                class="albutton mnabrbutton mat-accent"
                mat-button
              >
                {{ "lang_previous" | language }}
              </button>
            </div>

            <div class="col-md-3 mb-2">
              <button
                type="button"
                (click)="submitForm()"
                class="albutton mnabrbutton mat-accent"
                mat-button
              >
                {{ "lang_save" | language }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <app-add-hollidays></app-add-hollidays>
</section>
