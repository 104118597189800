<form [formGroup]="form" class="form">
	<div>
		<div class="row pt-3">
			<div
				class="col-12 col-sm-6 col-md-4"
				*ngFor="
					let item of items_platform().controls;
					let platformindex = index;
					let odd = odd;
					let even = even
				"
			>
				<mat-card class="card" (click)="openPlatformModal(platformindex)">
					<mat-card-title>
						<div class="serial_number">{{ platformindex + 1 }}</div>
					</mat-card-title>
					<mat-card-content>
						<div class="row">
							<div class="col-12">
								<div class="card-body-table">
									<table style="width: 100%" class="platform-table">
										<tr>
											<td
												colspan="2"
												style="
													text-align: center;
													text-transform: uppercase;
													color: #0e3768 !important;
													font-weight: 600;
												"
											>
												{{ getPlatformByIndex(platformindex)?.platformname }}
											</td>
										</tr>
										<tr>
											<th>{{ "lang_platform_code_system" | language }}</th>
											<td>
												<bdi>{{ item.get("platform_code_system").value }}</bdi>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
	</div>
</form>
<ng-template #addExtraPlatforms>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_add_platform" | language }} 2</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefP.hide()"
			>
				&times;
			</button>
		</div>
		<div class="modal-body">
			<div class="form-group">
				<div class="row">
					<div class="col-12">
						<label
							>{{ "lang_platform" | language }} :
							<span class="red">*</span></label
						>
					</div>
					<div class="col-12">
						<ng-select
							[hideSelected]="true"
							[items]="extraplatformslist"
							[multiple]="true"
							clearAllText="Clear"
							[hideSelected]="true"
							appendTo="body"
							[(ngModel)]="extraplatformsdata"
							placeholder="{{ 'lang_platform' | language }} 2"
						>
						</ng-select>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer pad15">
			<div class="row">
				<div class="col-md-6 col-xs-12 mb-10">
					<button
						type="button"
						class="albutton"
						(click)="add_extra_platforms_items()"
					>
						{{ "lang_add" | language }}
					</button>
				</div>
				<div class="col-md-6 col-xs-12 mb-10">
					<button
						type="button"
						class="btn btn-secondary secondarybutton"
						data-dismiss="modal"
						(click)="modalRefP.hide()"
					>
						{{ "lang_close" | language }}
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #ShowConsultantReq>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_consultant_req" | language }}</h4>
			<button type="button" class="close" mat-dialog-close>&times;</button>
		</div>

		<div class="modal-body">
			<div class="material-panel-body communication add_overflow">
				<div class="mbrl15">
					<div class="col-md-12 pad0">
						<div class="table-responsive customResponsive">
							<table border="1" class="table CustomTable">
								<thead>
									<tr>
										<td>{{ "lang_select" | language }}</td>
										<td>#</td>
										<td>{{ "lang_consultant_req_name" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="ConsultantData.length > 0" class="AppendList">
									<tr
										*ngFor="
											let person of ConsultantData;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
									>
										<td for="exampleCheck{{ i }}">
											<input
												type="checkbox"
												class="form-check-input p-relative"
												[checked]="consaltant.checked"
												(change)="
													toggleVlaue($event.target.checked, consaltant, i)
												"
												id="exampleCheck{{ i }}"
											/>
										</td>

										<td>{{ person.value }}</td>
										<td style="text-align: start">{{ person.label }}</td>
									</tr>
								</tbody>
								<tbody *ngIf="ConsultantData.length < 1" class="AppendList">
									<tr class="odd">
										<td colspan="2" class="nodata" align="center">
											{{ "lang_no_data" | language }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer pad15">
			<div class="row">
				<div class="col-12 mb-10">
					<app-alert id="default-alert"></app-alert>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 col-xs-12 mb-10 text-center">
					<button
						type="button"
						style="width: fit-content"
						class="btn btn-secondary secondarybutton m-auto"
						data-dismiss="modal"
						mat-dialog-close
					>
						{{ "lang_close" | language }}
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #ShowFConsultantReq>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_f_consultant_req" | language }}</h4>
			<button type="button" class="close" mat-dialog-close>&times;</button>
		</div>

		<div class="modal-body">
			<div class="material-panel-body communication add_overflow">
				<div class="mbrl15">
					<div class="col-md-12 pad0">
						<div class="table-responsive customResponsive">
							<table border="1" class="table CustomTable">
								<thead>
									<tr>
										<td>{{ "lang_select" | language }}</td>
										<td>#</td>
										<td>{{ "lang_f_consultant_req_name" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="ConsultantData.length > 0" class="AppendList">
									<tr
										*ngFor="
											let consaltant of ConsultantData;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
									>
										<td
											for="exampleCheck{{ i }}"
											(click)="toggleVlaue(!consaltant.checked, consaltant, i)"
										>
											<input
												type="checkbox"
												class="form-check-input p-relative"
												[checked]="consaltant.checked"
												(change)="
													toggleVlaue($event.target.checked, consaltant, i)
												"
												id="exampleCheck{{ i }}"
											/>
										</td>

										<td
											for="exampleCheck{{ i }}"
											(click)="toggleVlaue(!consaltant.checked, consaltant, i)"
										>
											{{ consaltant.value }}
										</td>
										<td
											for="exampleCheck{{ i }}"
											(click)="toggleVlaue(!consaltant.checked, consaltant, i)"
											style="text-align: start"
										>
											{{ consaltant.label }}
										</td>
									</tr>
								</tbody>
								<tbody *ngIf="ConsultantData.length < 1" class="AppendList">
									<tr class="odd">
										<td colspan="2" class="nodata" align="center">
											{{ "lang_no_data" | language }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer pad15">
			<div class="row">
				<div class="col-12 mb-10">
					<app-alert id="default-alert"></app-alert>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 col-xs-12 mb-10 text-center">
					<button
						type="button"
						style="width: fit-content"
						class="btn btn-secondary secondarybutton m-auto"
						data-dismiss="modal"
						mat-dialog-close
					>
						{{ "lang_close" | language }}
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>
