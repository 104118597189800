import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-create-update-user',
  templateUrl: './create-update-user.component.html',
  styleUrls: ['./create-update-user.component.scss']
})
export class CreateUpdateUserComponent implements OnInit ,OnDestroy {
  lang_key = localStorage.getItem('lang_key') || 'en';
  form:FormGroup;
  user_type_id = this.localstorage.getItem('user_type_id');
  editMode = false;
  editData:any;
  constructor(private fb: FormBuilder, public ds: DataService, private sweetAlert: SweetAlertService, private lang: LanguagePipe, 
    private spinner: PreloaderService, private dialogRef: MatDialogRef<CreateUpdateUserComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data, private localstorage: LocalStorage) { 
      this.buildForm();
      if(data?.userData) {
        this.editData = data?.userData;
        this.editMode = true;
        this.form.patchValue({
          user_username: data?.userData?.user_username,
          user_email: data?.userData?.user_email,
          user_password: data?.userData?.user_password,
          user_phone: data?.userData?.user_phone
        });
      }
    }

  ngOnInit(): void {
    
  }
  private subscriptions = new Subscription();
	ngOnDestroy(): void {
	  this.subscriptions.unsubscribe();
	}
  buildForm() {
    this.form = this.fb.group({
      user_username: ['', Validators.required],
      user_password: [''],
      user_email:    ['', [Validators.required, Validators.email]],
      user_phone:    ['', Validators.required]
    });
  }
  submit() {
    this.spinner.show();
    let url = 'user/create_user';
    if(this.editMode) {
      url = 'user/update_user';
    }
    let formData = new FormData();
    formData.append('user_username', this.form.value.user_username);
    formData.append('user_password', this.form.value.user_password);
    formData.append('user_email',    this.form.value.user_email);
    formData.append('user_phone',    this.form.value.user_phone);
    formData.append('user_type_id',  this.user_type_id);
    if(this.form.valid && !this.editMode) {
      this.subscriptions.add(
          this.ds.post(url, formData).subscribe(res => {
            this.spinner.hide();
            if(res.status) {
              this.dialogRef.close(true);
              this.sweetAlert.successToast(res.msg, 2000);
            } else {
              this.sweetAlert.errorToast(res.error, 2000);
            }
          }, error => {
            this.spinner.hide();
            this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
          })			
        )

    } else if(this.form.valid && this.editMode) {
      formData.append('user_id',       this.editData?.user_id);
      formData.append('user_status',   this.editData?.user_status);
      this.subscriptions.add(
          this.ds.putActionByUrl(this.ds.formData2string(formData), url).subscribe(res => {
            this.spinner.hide();
            if(res.status) {
              this.dialogRef.close(true);
              this.sweetAlert.successToast(res.msg, 2000);
            } else {
              this.sweetAlert.errorToast(res.error, 2000);
            }
          }, error => {
            this.spinner.hide();
            this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
          })			
        )

    }
  }
}
