<div class="treeheader albutton"> {{'lang_view' | language }}</div>
<div *ngIf="viewdata" class="padding15">
    <div class="form-group">
        <div class="row">
            <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_branch' | language }} :</label>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <b class="breakall">{{viewdata.branch_name}}</b>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_cost_center_code' | language }} :</label>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <b class="breakall">{{viewdata.cost_center_code}}</b>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_title_english' | language }} :</label>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <b class="breakall">{{viewdata.cost_center_name_en}}</b>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_title_arabic' | language }} :</label>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <b class="breakall">{{viewdata.cost_center_name_ar}}</b>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_sub_account' | language }} :</label>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <b class="breakall" *ngIf="viewdata.cost_center_sub == '1' ">{{'lang_yes' | language }}</b>
                <b class="breakall" *ngIf="viewdata.cost_center_sub == '0' ">{{'lang_no' | language }}</b>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_writer' | language }} :</label>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <b class="breakall">{{viewdata.writer_name}}</b>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_created_date' | language }} :</label>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <b class="breakall">{{viewdata.cost_center_created_datetime}}</b>
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="viewdata.cost_center_updated_datetime">
        <div class="row">
            <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_onupdate' | language }}
                :</label>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <b class="breakall">{{viewdata.cost_center_updated_datetime}}</b>
            </div>
        </div>
    </div>
</div>