import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SendCodeComponent } from "app/routes/transactions/FORM_PS_TR1/send-code/send-code.component";

@Component({
	selector: "app-si-step3",
	templateUrl: "./si-step3.component.html",
	styleUrls: ["./si-step3.component.scss"],
})
export class SiStep3Component implements OnInit {
	@Input() form_key;
	@Input() form_request_id;
	@Input() project_data;
	@Input() supervision_data;
	@Input() template_data;
	@Input() allData;
	sendCodeForm: any;
	sendCodeStatus: boolean = false;
	rejectedValidators = [];
	stepName = "Confirm_Receive_NCR";
	form: FormGroup;
	users: any = [];
	lang_key: string =
		localStorage.getItem("lang_key") == null
			? "en"
			: localStorage.getItem("lang_key");
	constructor(
		public ds: DataService,
		public datepipe: DatePipe,
		public dialog: MatDialog,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public changeDetectorRef: ChangeDetectorRef
	) {}
	ngOnInit(): void {
		this.form = new FormGroup({
			transaction_request_id: new FormControl(this.form_request_id, [
				Validators.required,
			]),
			confirm_receive_SI: new FormControl(null, [Validators.required]),
		});
	}

	public completeStep4() {
		let confirm_receive_SI =
			this.form.get("confirm_receive_SI").value == true ? "1" : "0";
		let formValue = {
			...this.form.value,
			confirm_receive_SI: confirm_receive_SI,
		};
		if (this.form.valid) {
			this.spinner.show();
			this.ds
				.post(`/form/${this.form_key}/Recipient_Verification/0`, formValue)
				.subscribe(
					(data) => {
						if (data.status) {
							this.spinner.hide();
							this.alert.success("data sent successfully");
						} else {
							this.spinner.hide();
							this.alert.error(data.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(error);
					}
				);
		}
	}

	openModal() {
		const dialogRef = this.dialog.open(SendCodeComponent, {
			disableClose: true,
			width: "400px",
			data: {
				transaction_persons_type: "signature",
				allData: this.allData,
				stepName: this.stepName,
			},
		});
		dialogRef.afterClosed().subscribe((data) => {
			this.sendCodeForm = data.formValue;
			this.sendCodeStatus = data.sendstatus;
		});
	}
}
