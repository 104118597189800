<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_new_joining" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="form">
		<div class="mbrl15">
			<div class="row">
				<div class="col-md-6 col-xs-12 col-sm-4 mb-10">
					<label class="mb-10">
						{{ "lang_direct_manager" | language }}
						<span class="red"> * </span>
					</label>

					<ng-select
						[items]="users"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						appendTo="body"
						formControlName="direct_manager"
						placeholder="{{ 'lang_direct_manager' | language }}"
						(search)="search_users($event)"
						(focus)="users = []"
						[searchFn]="ds.customSearchFn"
						[notFoundText]="'lang_no_data' | language"
					>
					</ng-select>
					<mat-error
						*ngIf="
							submitted && form.controls['direct_manager'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="col-sm-6 col-xs-12 mb-10">
					<div class="form-group">
						<label for="joining_start_date_english"
							>{{ "lang_date" | language }} : <span class="red">*</span></label
						>
						<div class="positionrelatiove">
							<input
								formControlName="joining_start_date_english"
								type="text"
								class="form-control almnabrformcontrol"
								(dateChange)="
									ds.setENTOARDate(
										$event,
										form.get('joining_start_date_arabic')
									)
								"
								[readonly]="true"
								placeholder="YYYY/MM/DD"
								[matDatepicker]="BDeng"
							/>
							<mat-datepicker-toggle
								matSuffix
								[for]="BDeng"
								class="posiab"
							></mat-datepicker-toggle>
							<mat-datepicker #BDeng disabled="false"></mat-datepicker>
							<mat-error
								*ngIf="
									submitted &&
									form.controls['joining_start_date_english'].errors?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-xs-12 mb-10">
					<div class="form-group">
						<label for="joining_start_date_arabic"
							>{{ "lang_human_resources_date_arabic" | language }} :
							<span class="red">*</span></label
						>
						<input
							formControlName="joining_start_date_arabic"
							(ngModelChange)="
								ds.setARTOENDate($event, form.get('joining_start_date_english'))
							"
							ngbDatepicker
							[readonly]="true"
							(click)="bdarab.toggle()"
							#bdarab="ngbDatepicker"
							type="text"
							class="form-control almnabrformcontrol"
							placeholder="YYYY/MM/DD"
						/>
						<mat-error
							*ngIf="
								submitted &&
								form.controls['joining_start_date_arabic'].errors?.required
							"
							>{{ "lang_field_required" | language }}</mat-error
						>
					</div>
				</div>
			</div>
			<div class="row mb-10">
				<div class="col-12 mb-10">
					<label>
						{{ "lang_description" | language }}
						<span class="red">*</span></label
					>
					<textarea
						type="text"
						formControlName="joining_description"
						class="form-control almnabrformcontrol"
						rows="5"
					></textarea>
					<mat-error
						*ngIf="
							submitted && form.controls['joining_description'].errors?.required
						"
						>{{ "lang_field_required" | language }}</mat-error
					>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="submit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
