<div class="themesection">
  <section class="panel-expansion matborderno">
    <mat-accordion class="create_selling_invoice">
      <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_keys_company" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <form class="form" [formGroup]="form">
            <div class="mbrl15 mt-5">
              <div class="row mb-md-2">
                <div class="col-md-6 pr-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_is_phase_two" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <select
                          class="form-control almnabrformcontrol"
                          #is_phase_two
                          formControlName="is_phase_two"
                        >
                          <option value="" disabled>
                            {{ "lang_choose_options" | language }}
                          </option>
                          <option value="1">
                            {{ "lang_yes" | language }}
                          </option>
                          <option value="0">
                            {{ "lang_no" | language }}
                          </option>
                        </select>
                        <mat-error
                          *ngIf="
                            form.controls['is_phase_two'].touched &&
                            form.controls['is_phase_two'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_is_production" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <select
                          class="form-control almnabrformcontrol"
                          #is_production
                          formControlName="is_production"
                        >
                          <option value="" disabled>
                            {{ "lang_choose_options" | language }}
                          </option>
                          <option value="1">
                            {{ "lang_yes" | language }}
                          </option>
                          <option value="0">
                            {{ "lang_no" | language }}
                          </option>
                        </select>
                        <mat-error
                          *ngIf="
                            form.controls['is_production'].touched &&
                            form.controls['is_production'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-md-2">
                <div class="col-md-6 pr-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_street_name" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          type="text"
                          formControlName="street_name"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_street_name' | language }}"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['street_name'].touched &&
                            form.controls['street_name'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label
                        >{{ "lang_commercial_registration_number" | language }}
                      </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          maxlength="10"
                          type="text"
                          formControlName="commercial_registration_number"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_must_be' | language }} 10 {{
                            'lang_digit' | language
                          }}"
                          onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['commercial_registration_number']
                              .touched &&
                            form.controls['commercial_registration_number']
                              .errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-md-2">
                <div class="col-md-6 pr-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_building_number" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          maxlength="4"
                          type="text"
                          formControlName="building_number"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_must_be' | language }} 4 {{
                            'lang_digit' | language
                          }}"
                          onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['building_number'].touched &&
                            form.controls['building_number'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label
                        >{{ "lang_plot_identification" | language }}
                      </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          maxlength="4"
                          type="text"
                          formControlName="plot_identification"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_must_be' | language }} 4 {{
                            'lang_digit' | language
                          }}"
                          onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['plot_identification'].touched &&
                            form.controls['plot_identification'].errors
                              ?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-md-2">
                <div class="col-md-6 pr-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_city_sub_division" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          type="text"
                          formControlName="city_sub_division"
                          class="form-control almnabrformcontrol"
                          placeholder="{{
                            'lang_city_sub_division' | language
                          }}"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['city_sub_division'].touched &&
                            form.controls['city_sub_division'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_postal_number" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          maxlength="5"
                          type="text"
                          formControlName="postal_number"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_must_be' | language }} 5 {{
                            'lang_digit' | language
                          }}"
                          onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['postal_number'].touched &&
                            form.controls['postal_number'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-md-2">
                <div class="col-md-6 pr-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_city" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          type="text"
                          formControlName="city"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_city' | language }}"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['city'].touched &&
                            form.controls['city'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_businessCategory" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          type="text"
                          formControlName="businessCategory"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_businessCategory' | language }}"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['businessCategory'].touched &&
                            form.controls['businessCategory'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-md-2">
                <div class="col-md-6 pr-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_commonName" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          type="text"
                          formControlName="commonName"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_commonName' | language }}"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['commonName'].touched &&
                            form.controls['commonName'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_egs_serial_number" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          type="text"
                          formControlName="egs_serial_number"
                          class="form-control almnabrformcontrol"
                          placeholder="{{
                            'lang_egs_serial_number' | language
                          }}"
                          onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['egs_serial_number'].touched &&
                            form.controls['egs_serial_number'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-md-2">
                <div class="col-md-6 pr-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label
                        >{{ "lang_organizationalUnitName" | language }}
                      </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          type="text"
                          formControlName="organizationalUnitName"
                          class="form-control almnabrformcontrol"
                          placeholder="{{
                            'lang_organizationalUnitName' | language
                          }}"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['organizationalUnitName'].touched &&
                            form.controls['organizationalUnitName'].errors
                              ?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_organizationName" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          type="text"
                          formControlName="organizationName"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_organizationName' | language }}"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['organizationName'].touched &&
                            form.controls['organizationName'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-md-2">
                <div class="col-md-6 pr-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_registeredAddress" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          type="text"
                          formControlName="registeredAddress"
                          class="form-control almnabrformcontrol"
                          placeholder="{{
                            'lang_registeredAddress' | language
                          }}"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['registeredAddress'].touched &&
                            form.controls['registeredAddress'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_countryName" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          maxlength="2"
                          type="text"
                          formControlName="countryName"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_must_be' | language }} 2 {{
                            'lang_digit' | language
                          }}"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['countryName'].touched &&
                            form.controls['countryName'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-md-2">
                <div class="col-md-6 pr-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_invoice_type" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <ng-select
                          [items]="invoiceTypes"
                          bindLabel="label"
                          bindValue="value"
                          [multiple]="false"
                          clearAllText="Clear"
                          appendTo="body"
                          type="text"
                          placeholder="{{ 'lang_invoice_type' | language }}"
                          formControlName="invoice_type"
                        >
                        </ng-select>
                        <!-- <input
                          maxlength="4"
                          type="text"
                          formControlName="invoice_type"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_must_be' | language }} 4 {{
                            'lang_digit' | language
                          }}"
                          onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                        /> -->
                        <mat-error
                          *ngIf="
                            form.controls['invoice_type'].touched &&
                            form.controls['invoice_type'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_otp" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          maxlength="6"
                          type="text"
                          formControlName="otp"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_must_be' | language }} 6 {{
                            'lang_digit' | language
                          }}"
                          onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['otp'].touched &&
                            form.controls['otp'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-md-2">
                <div class="col-md-6 pr-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_emailAddress" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          type="text"
                          formControlName="emailAddress"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_emailAddress' | language }}"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['emailAddress'].touched &&
                            form.controls['emailAddress'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-md-5">
                  <div class="row">
                    <div class="col-md-4 mb-md-10 p-2 label">
                      <label>{{ "lang_tax_number" | language }} </label>
                    </div>
                    <div class="col-md-8 mb-1">
                      <div class="form-group">
                        <input
                          maxlength="15"
                          type="text"
                          formControlName="tax_number"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_must_be' | language }} 15 {{
                            'lang_digit' | language
                          }}"
                          onkeypress="return event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                        />
                        <mat-error
                          *ngIf="
                            form.controls['tax_number'].touched &&
                            form.controls['tax_number'].errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-md-2">
                <div class="col-md-6 pl-md-5"></div>
              </div>
            </div>
            <div class="card-footer padb0">
              <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10"></div>
                <div class="col-md-8 col-sm-8 col-xs-12 mb-10">
                  <app-alert id="default-alert"></app-alert>
                </div>
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                  <button
                    *ngIf="!check_update"
                    type="submit"
                    class="albutton"
                    (click)="create_keys_company()"
                  >
                    {{ "lang_submit" | language }}
                  </button>
                  <button
                    *ngIf="check_update"
                    type="submit"
                    class="albutton"
                    (click)="update_keys_company()"
                  >
                    {{ "lang_update" | language }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div></mat-expansion-panel
      ></mat-accordion
    >
  </section>
</div>
