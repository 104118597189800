<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_edit" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="snag_form">
		<div class="row">
			<div class="col-12 col-sm-6 mb-4">
				<ng-select
					[items]="group1_list"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_group' | language }} 1"
					formControlName="platform_group1_code_system"
					[searchFn]="ds.customSearchFn"
					(search)="get_group1_list($event)"
					(change)="get_group2_list({ trem: '' })"
				>
				</ng-select>
				<mat-error
					*ngIf="
                    snag_form.get(
							'platform_group1_code_system'
						).touched &&
						snag_form.get(
							'platform_group1_code_system'
						).errors?.required
					"
				>
					{{ "lang_field_required" | language }}
				</mat-error>
			</div>
			<div class="col-12 col-sm-6 mb-4">
				<ng-select
					[items]="group2_list"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_group' | language }} 1"
					formControlName="platform_group2_code_system"
					[searchFn]="ds.customSearchFn"
					(search)="get_group2_list($event)"
				>
				</ng-select>
				<mat-error
					*ngIf="
                    snag_form.get(
							'platform_group2_code_system'
						).touched &&
						snag_form.get(
							'platform_group2_code_system'
						).errors?.required
					"
				>
					{{ "lang_field_required" | language }}
				</mat-error>
			</div>
            <div class="col-12 col-sm-6 mb-4">
                <input type="text" placeholder="{{'lang_title_english' | language }}" formControlName="note_title_english" class="form-control almnabrformcontrol">
                <mat-error *ngIf="snag_form.get('note_title_english').touched && snag_form.get('note_title_english').errors?.required"> {{'lang_field_required' | language }} </mat-error>
            </div>
            <div class="col-12 col-sm-6 mb-4">
                <input type="text" placeholder="{{'note_title_arabic' | language }}" formControlName="note_title_arabic" class="form-control almnabrformcontrol">
                <mat-error *ngIf="snag_form.get('note_title_english').touched && snag_form.get('note_title_english').errors?.required"> {{'lang_field_required' | language }} </mat-error>
            </div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="edit_snag()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
