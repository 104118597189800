import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-transaction-notes-modal',
  templateUrl: './transaction-notes-modal.component.html',
  styleUrls: ['./transaction-notes-modal.component.scss']
})
export class TransactionNotesModalComponent implements OnInit {
  notes: any;
  notes_columns = ['name', 'transactions_notes_step', 'transactions_notes_text', 'transactions_notes_datetime'];
  notesList = [];
  isShowAddNotes = false;
  lodingdatas = this.lang.transform('lang_loading');
  constructor (public dialog : MatDialog , private ds: DataService,
    private dialogRef: MatDialogRef<TransactionNotesModalComponent>, public lang: LanguagePipe,
		@Inject(MAT_DIALOG_DATA) public data:any, private sweetAlert: SweetAlertService, private spinner: PreloaderService,
    ) {}

  ngOnInit(): void {
    this.getTransactionData(this.data.type);
  }

  closeDialog() {
    this.dialogRef.close()
  }

  onSubmit() {
    const formData = new FormData;
    formData.append("notes", this.notes || "");
    formData.append("transaction_request_id", this.data?.transaction_request_id || "");
    formData.append("step_name", this.data?.last_step || "");
    this.spinner.show();
    this.ds.post('np/transaction/create_transaction_notes', formData).subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.sweetAlert.successToast(res?.msg, 3000);
        this.getTransactionData(this.data.type);
      } else {
        this.sweetAlert.errorToast(res?.error, 3000);
      }
    }, err => {
      this.spinner.hide();
    })
  }

  getTransactionData(type: string) {
    if(type == 'FORM_C2') {
      this.spinner.show();
      this.ds.getOutgoingFormData(this.data?.transaction_request_id).pipe(tap((response:any) => {
        this.spinner.hide();
        if(response?.status) {
          this.notesList = response?.transactions_notes.records;
        } else {
          this.notesList = [];
        }
      }),
      catchError(err => {
        this.spinner.hide();
        this.notesList = [];
        return of(0);
      })
      ).subscribe();
    } else if(type == 'FORM_C1') {
      this.spinner.show();
      this.ds.getIncomingFormData(this.data?.transaction_request_id).pipe(tap((response:any) => {
        this.spinner.hide();
        if(response?.status) {
          this.notesList = response?.transactions_notes.records
        } else {
          this.notesList =  [];
        }
      }),
      catchError(err => {
        this.spinner.hide();
        this.notesList = [];
        return of(0);
      })
      ).subscribe();
    }
  }

  onAddNotes() {
    this.isShowAddNotes = !this.isShowAddNotes;
  }

}
