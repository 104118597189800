import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-infrastructure-works",
  templateUrl: "./infrastructure-works.component.html",
  styleUrls: ["./infrastructure-works.component.scss"],
})
export class InfrastructureWorksComponent implements OnInit {
  @Input() report_data;
  constructor() {}

  ngOnInit(): void {}
}
