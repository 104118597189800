<form [formGroup]="form" class="form" (ngSubmit)="completeStep8()">
	<div class="card">
		<div class="card-header">
			{{ "lang_consultant_manager_approval" | language }}
			<i
				style="padding: 0 10px"
				class="fa fa-info-circle orangestatus-color"
				matTooltip="{{
					'lang_authorized_positions_approval_tooltip' | language
				}}"
			></i>
		</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row">
					<div class="col-sm-12 col-xs-12 mb-10">
						<label class="mb-10">
							{{ "lang_transaction_approval" | language }} !?.
							<span class="red"> * </span> </label
						><br />
						<mat-radio-group
							aria-label="Select an option"
							formControlName="authorized_positions_approval_status"
							(change)="form.get('rejected_notes').setValue('')"
						>
							<mat-radio-button value="Return"
								>{{ "lang_return" | language }} !?</mat-radio-button
							>
							<mat-radio-button value="Approve">{{
								"lang_approve" | language
							}}</mat-radio-button>
							<mat-radio-button value="Rejected_Attachment"
								>Reject by attachment</mat-radio-button
							>
						</mat-radio-group>
					</div>
				</div>
				<div class="row mb-10">
					<div class="col-sm-12 col-xs-12 mb-10">
						<div
							*ngIf="
								form.get('authorized_positions_approval_status').value ==
								'Return'
							"
						>
							<label
								>{{ "lang_rejected_notes" | language }} :
								<span class="red">*</span></label
							>
							<textarea
								class="form-control almnabrformcontrol"
								formControlName="rejected_notes"
							></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<div class="row mb-10">
				<div class="col-sm-4 col-xs-12 mb-10">
					<div></div>
				</div>
				<div class="col-sm-4 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<div>
						<input
							type="submit"
							value="{{ 'lang_submit' | language }}"
							class="albutton mnabrbutton mat-accent"
							mat-button
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<!--Contract Payment Table-->
<app-cpay-steps-table
	[tableContent]="allData?.view_request?.form_cpay_table_data?.records"
></app-cpay-steps-table>
