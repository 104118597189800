import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CscreateComponent } from './cscreate/cscreate.component';
import { CseditComponent } from './csedit/csedit.component';
import { CslistComponent } from './cslist/cslist.component';
import { CssettingsComponent } from './cssettings/cssettings.component';
import { CsviewComponent } from './csview/csview.component';
const routes: Routes = [
  {
    path: 'settings',
    component: CssettingsComponent,
    data: { title: 'Customer Settings' }
  },
  {
    path: 'create',
    component: CscreateComponent,
    data: { title: 'Create Customers' }
  },
  {
    path: 'lists',
    component: CslistComponent,
    data: { title: 'All Customers' }
  },
  {
    path: 'view/:id',
    component: CsviewComponent,
    data: { title: 'View Customers' }
  },
  {
    path: 'edit/:id',
    component: CseditComponent,
    data: { title: 'Edit Customers' }
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule { }