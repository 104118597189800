<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_platform_access_codes" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div
		class="table-responsive dropdownyes customResponsive tablefields mb-3"
		*ngIf="platformAccessCodes?.length"
	>
		<table border="1" class="table CustomTable">
			<thead>
				<tr>
					<th>#</th>
					<th>{{ "lang_units" | language }}</th>
					<th>{{ "lang_work_levels" | language }}</th>
					<th>{{ "lang_platform" | language }}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="
						let item of platformAccessCodes;
						let i = index;
						let odd = odd;
						let even = even
					"
				>
					<td>{{ i + 1 }}</td>
					<td>{{ item?.unit }}</td>
					<td>{{ item?.work_level_label }}</td>
					<td>{{ item?.platform_label }} ({{ item?.required_platform }})</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="platformAccessCodes?.length">
		<div class="row responsive_pagination">
			<div
				class="col-lg-3 col-sm-3 mb-10"
				style="height: fit-content; margin: auto 0"
			>
				{{ "lang_total" | language }} :
				{{ total_records }}
			</div>
			<div
				class="col-lg-6 col-sm-6 mb-10"
				style="display: flex; justify-content: center"
			>
				<ngb-pagination
					class="m-auto"
					[collectionSize]="total_records"
					[rotate]="true"
					[ellipses]="false"
					[maxSize]="3"
					[boundaryLinks]="true"
					[(page)]="page"
					[pageSize]="size"
					(pageChange)="getPlatformCodes()"
				></ngb-pagination>
			</div>
			<div
				class="col-lg-3 col-sm-3"
				style="display: flex; justify-content: end; margin: auto"
			>
				<mat-select [(ngModel)]="size" (selectionChange)="getPlatformCodes()">
					<mat-option class="text-center" value="10"
						>10 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="20"
						>20 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="50"
						>50 {{ "lang_per_page" | language }}
					</mat-option>
					<mat-option class="text-center" value="100"
						>100 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option class="text-center" value="500"
						>500 {{ "lang_per_page" | language }}</mat-option
					>
				</mat-select>
			</div>
		</div>
	</div>
	<div
		class="mt-3 mb-3 text-center"
		*ngIf="platformAccessCodesMsg && !platformAccessCodes?.length"
	>
		<span style="font-size: 18px">{{ platformAccessCodesMsg }}</span>
	</div>
</mat-dialog-content>
