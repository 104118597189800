<div class="viewquotation stylepanels" *ngIf="status">
  <div class="material-panel-body communication">
    <fieldset
      class="cardback fieldset mb-10"
      [ngClass]="{ ' contentrtl': lang_key == 'ar' }"
    >
      <legend>{{ "lang_vacation" | language }}</legend>
      <div class="row mb-10">
        <div class="contentbodypanel width100 mt-0">
          <div class="col-12 contentpanel">
            <div class="itemsdata">
              <label>
                {{ "lang_human_resources_emp_details" | language }} :
              </label>
              <div class="TableParts">
                <div
                  class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
                >
                  <table class="table CustomTables text-center">
                    <tbody class="AppendList quotationtable">
                      <tr>
                        <td colspan="3" class="text-start">
                          {{ "lang_human_resources_employee_name" | language }}
                          :
                        </td>
                        <td colspan="3">
                          {{ records?.employee_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-start">
                          {{
                            "lang_human_resources_job_title_iqama" | language
                          }}
                          :
                        </td>
                        <td>
                          {{ records?.jobname }}
                        </td>

                        <td class="text-start">
                          {{
                            "lang_human_resources_start_joining_date" | language
                          }}
                          :
                        </td>
                        <td>
                          {{ vacation_data?.first_joining_date }}
                        </td>

                        <td class="text-start">
                          {{
                            "lang_human_resources_last_joining_date" | language
                          }}
                          :
                        </td>
                        <td>
                          {{ records?.joining_start_date_english }} -
                          {{ records?.joining_start_date_arabic }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-start">
                          {{ "lang_mobile_number" | language }} :
                        </td>
                        <td>
                          {{ records?.primary_mobile }}
                        </td>

                        <td class="text-start">
                          {{ "lang_branch" | language }} :
                        </td>
                        <td>
                          {{ records?.branchname }}
                        </td>

                        <td class="text-start">
                          {{ "lang_email" | language }} :
                        </td>
                        <td>
                          {{ records?.primary_email }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
            </div>

            <div class="itemsdata">
              <label>
                {{ "lang_human_resources_vocation_details" | language }} :
              </label>
              <div class="TableParts">
                <div
                  class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
                >
                  <table class="table CustomTables text-center">
                    <tbody class="AppendList quotationtable">
                      <tr>
                        <td colspan="3">{{ "lang_type" | language }} :</td>
                        <td colspan="3">
                          {{ records?.vacation_type }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-start">
                          {{ "lang_days" | language }} :
                        </td>
                        <td>
                          {{ records?.vacation_total_days }}
                        </td>

                        <td class="text-start">
                          {{ "lang_start_date" | language }} :
                        </td>
                        <td>
                          {{ records?.vacation_start_date_english }} -
                          {{ records?.vacation_start_date_arabic }}
                        </td>

                        <td class="text-start">
                          {{ "lang_end_date" | language }} :
                        </td>
                        <td>
                          {{ records?.vacation_end_date_english }} -
                          {{ records?.vacation_end_date_arabic }}
                        </td>
                      </tr>

                      <tr>
                        <td class="text-start">
                          {{ "lang_joining_type_after_vacation" | language }} :
                        </td>
                        <td>
                          {{ records?.after_vacation_working_date_english }} -
                          {{ records?.after_vacation_working_date_arabic }}
                        </td>

                        <td class="text-start">
                          {{ "lang_paid_amount" | language }} :
                        </td>
                        <td>
                          {{
                            records?.vacation_total_paid_amount | number: ".2-2"
                          }}
                        </td>

                        <td class="text-start">
                          {{ "lang_direct_manager" | language }} :
                        </td>
                        <td>
                          {{ records?.direct_manager_name }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
            </div>

            <div class="itemsdata" *ngIf="financial_details?.length > 0">
              <label>
                {{ "lang_human_resources_financial_details" | language }} :
              </label>
              <div class="row mb-2 mt-2">
                <div class="col-md-12 pad0">
                  <div class="table-responsive customResponsive">
                    <table border="1" class="table CustomTable">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>{{ "lang_date" | language }}</td>
                          <td>
                            {{
                              "lang_human_resources_vacation_paid_days"
                                | language
                            }}
                          </td>
                          <td>{{ "lang_finance_unpaid_days" | language }}</td>
                          <td>{{ "lang_credit_amount" | language }}</td>
                          <!-- <td *ngIf="isSickCase">{{ "lang_paid_days" | language }} 75</td>
                                                <td *ngIf="isSickCase">{{ "lang_credit_amount" | language }} 25</td>
                                                <td *ngIf="isSickCase">{{ "lang_credit_amount" | language }} 75</td> -->
                          <td>{{ "lang_debit_amount" | language }}</td>
                          <td>
                            {{
                              "lang_total_salary_per_person_month" | language
                            }}
                          </td>
                          <td>{{ "lang_note" | language }}</td>
                        </tr>
                      </thead>
                      <tbody class="AppendList">
                        <tr
                          *ngFor="
                            let finance of financial_details;
                            let i = index;
                            let odd = odd;
                            let even = even
                          "
                        >
                          <td>{{ i + 1 }}</td>
                          <td>
                            {{ finance?.finance_month }} /
                            {{ finance?.finance_year }}
                          </td>
                          <td>{{ finance?.paid_days }}</td>
                          <td>{{ finance?.unpaid_days }}</td>
                          <td>{{ finance?.credit_amount }}</td>
                          <!-- 	<td *ngIf="isSickCase">{{ finance?.paid_days_75 ? finance?.paid_days_75?.toFixed(2) :0}}</td>
                                                <td *ngIf="isSickCase">{{ finance?.credit_amount_25 ?finance?.credit_amount_25?.toFixed(2):0 }}</td>
                                                <td *ngIf="isSickCase">{{ finance?.credit_amount_75 ?finance?.credit_amount_75?.toFixed(2):0 }}</td> -->
                          <td>{{ finance?.debit_amount }}</td>
                          <td
                            class="details"
                            matTooltip="{{ 'lang_details' | language }}"
                            (click)="
                              get_details(
                                finance?.finance_month,
                                finance?.finance_year,
                                details
                              )
                            "
                          >
                            {{ finance?.net_salary_amount }}
                          </td>
                          <td>
                            <div
                              matTooltip="{{ finance.finance_description }}"
                              *ngIf="finance.finance_description"
                              class="note"
                            ></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div class="itemsdata" *ngIf="notes?.length > 0">
              <label> {{ "lang_history" | language }} : </label>
              <div class="row mb-2 mt-2">
                <div class="col-md-12 pad0">
                  <div class="table-responsive customResponsive">
                    <table border="1" class="table CustomTable">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>{{ "lang_name" | language }}</td>
                          <td>{{ "lang_ondate" | language }}</td>
                          <td>{{ "lang_notes" | language }}</td>
                        </tr>
                      </thead>
                      <tbody class="AppendList">
                        <tr
                          *ngFor="
                            let his of notes;
                            let i = index;
                            let odd = odd;
                            let even = even
                          "
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ his.transactions_notes_user_name }}</td>

                          <td>{{ his.transactions_notes_datetime }}</td>
                          <td>{{ his.transactions_notes_text }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</div>
<ng-template #details>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ "lang_financial_details_list" | language }}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        &times;
      </button>
    </div>
    <div class="modal-body">
      <div class="mbrl15">
        <div class="row text-center">
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_employee_numbers" | language }}</div>
            <div class="mb-10">
              <b> {{ financial_details_list?.employee_number || 0 }}</b>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_net_amount" | language }}
            </div>
            <div class="mb-10">
              <b> {{ net_amount }}</b>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_finance_year" | language }}</div>
            <div class="mb-10">
              <b> {{ financial_details_list?.finance_year || 0 }}</b>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_finance_month" | language }}
            </div>
            <div class="mb-10">
              <b> {{ financial_details_list?.finance_month || 0 }}</b>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_credit_amount" | language }}</div>
            <div class="mb-10">
              <b> {{ financial_details_list?.credit_amount || 0 }}</b>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_debit_amount" | language }}
            </div>
            <div class="mb-10">
              <b> {{ financial_details_list?.debit_amount || 0 }}</b>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_paid_days" | language }}</div>
            <div class="mb-10">
              <b> {{ financial_details_list?.paid_days || 0 }}</b>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_unpaid_days" | language }}
            </div>
            <div class="mb-10">
              <b> {{ financial_details_list?.unpaid_days || 0 }}</b>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">{{ "lang_finance_description" | language }}</div>
            <div class="mb-10">
              <b> {{ financial_details_list?.finance_description || 0 }}</b>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
            <div class="mb-10">
              {{ "lang_finance_created_data" | language }}
            </div>
            <div class="mb-10">
              <b> {{ financial_details_list?.finance_created_data || 0 }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal footer -->
  </div>
</ng-template>
