import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ncr-steps-history',
  templateUrl: './ncr-steps-history.component.html',
  styleUrls: ['./ncr-steps-history.component.scss']
})
export class NcrStepsHistoryComponent implements OnInit {
  @Input() step_no:number;
  @Input() allData:any;
  steps_data;
  lang_key = localStorage.getItem('lang_key') || 'en';
  constructor() {
    setTimeout(() => {
      this.getStepsHistory();
    });
  }

  ngOnInit(): void {
  }

  getStepsHistory() {
    this.steps_data = {
      work_name                     : this.allData?.view_request?.form_ncr_data?.records[0]?.wriplatformname,
      issue                         : this.allData?.view_request?.form_ncr_data?.records[0]?.issuename || this.allData?.view_request?.form_ncr_data?.records[0]?.new_issue_title,
      ncr_issue_fix_suggestion      : this.allData?.view_request?.form_ncr_data?.records[0]?.ncr_issue_fix_suggestion,
      ncr_issue_reason              : this.allData?.view_request?.form_ncr_data?.records[0]?.ncr_issue_reason,
      ncr_issue_preventive_action   : this.allData?.view_request?.form_ncr_data?.records[0]?.ncr_issue_preventive_action,
      ncr_issue_fixing_date         : this.allData?.view_request?.form_ncr_data?.records[0]?.ncr_issue_fixing_date,
      ncr_issue_confirm_fixing_way  : this.allData?.view_request?.form_ncr_data?.records[0]?.ncr_issue_confirm_fixing_way,
      ncr_issue_confirm_fixing_note : this.allData?.view_request?.form_ncr_data?.records[0]?.ncr_issue_confirm_fixing_note,
      close_ncr_request_status      : this.allData?.view_request?.form_ncr_data?.records[0]?.close_ncr_request_status,
    }
  }
}
