<form [formGroup]="form" class="form" (ngSubmit)="completeStep()">
	<div class="card">
		<div class="card-header">
			{{ step_lang }}
			<i
				style="padding: 0 10px"
				class="fa fa-info-circle orangestatus-color"
				matTooltip="{{ 'lang_recipient_reservation_tooltip' | language }}"
			></i>
		</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<label>{{ "lang_reserve_the_transaction" | language }} ?!</label>
						<section class="example-section">
							<mat-checkbox
								formControlName="teserve_transaction"
								class="example-margin"
							></mat-checkbox>
						</section>
					</div>
					<div class="col-md-12 TableParts">
						<p class="mb-10">
							{{ "lang_attachments" | language }}
						</p>
						<div
							class="table-responsive customResponsive tablefields quotationitemtable"
						>
							<table
								border="1"
								class="table CustomTable"
								width="100%"
								formArrayName="attachments"
							>
								<thead>
									<tr>
										<td
											width="2%"
											style="padding: 5px; word-break: break-all"
										>
											#
										</td>
										<td
											width="2%"
											style="padding: 5px; word-break: break-all"
										>
											<mat-icon
												class="mnabricon"
												matTooltip="{{ 'lang_add' | language }}"
												(click)="addfiles()"
												>add_box</mat-icon
											>
										</td>
										<td
											width="48%"
											style="padding: 5px; word-break: break-all"
										>
											{{ "lang_title" | language }}
										</td>
										<td
											width="48%"
											style="padding: 5px; word-break: break-all"
										>
											{{ "lang_file" | language }}
										</td>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="
											let file of filesFormGroup.controls;
											let i = index;
											let odd = odd;
											let even = even
										"
										[formGroupName]="i"
									>
										<td
											class="verticalalignmid"
											style="padding: 5px; word-break: break-all"
										>
											{{ i + 1 }}
										</td>
										<td class="verticalalignmid">
											<mat-icon
												*ngIf="
													!other_attachments.controls[i].get('delete_icon')
														.value
												"
												(click)="removefiles(i)"
												class="mnabricon red"
												matTooltip="{{ 'lang_delete' | language }}"
												>delete_forever</mat-icon
											>
										</td>
										<td
											style="
												padding: 5px;
												word-break: break-all;
												position: relative;
											"
										>
											<input
												type="text"
												formControlName="attach_title"
												placeholder="{{ 'lang_description' | language }}"
												autocomplete="off"
												class="form-control almnabrformcontrol"
											/>
										</td>
										<td
											class="verticalalignmid"
											style="
												padding: 5px;
												word-break: break-all;
												position: relative;
											"
										>
											<input
												name="file"
												type="file"
												required
												onclick="this.value = null"
												(change)="
													ds.uploadSingleFile(
														$event,
														other_attachments.controls[i].get('file'),
														'file' + i,
														filename
													)
												"
												class="nghide albutton width100"
											/>
											<label id="file{{ i }}" class="albutton">{{
												filename
											}}</label>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pl-2 pr-2">
			<div class="row">
				<div class="col-md-12 col-sm-8 col-xs-12 mb-10">
					<app-alert id="default-alert"></app-alert>
				</div>
				<div class="col-sm-8"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<input
						type="submit"
						value="{{ 'lang_submit' | language }}"
						class="albutton mnabrbutton mat-accent"
						mat-button
					/>
				</div>
			</div>
		</div>
	</div>
</form>

<!--steps history-->
<app-ncr-steps-history
	[allData]="allData"
	[step_no]="8"
></app-ncr-steps-history>
