import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { PreviewPdfComponent } from "@shared/components/preview-pdf/preview-pdf.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-design-communication",
	templateUrl: "./design-communication.component.html",
	styleUrls: ["./design-communication.component.scss"],
})
export class DesignCommunicationComponent implements OnInit {
	@Input() filelevels: any;
	@Input() design_data: any;
	@Input() project_data: any;
	@Input() able2add: Boolean;
	@Input() able2edit: Boolean;
	transaction_keys = [
		{
			label: this.lang.transform("lang_form_outgoing"),
			value: "FORM_C1",
		},
		{
			label: this.lang.transform("lang_form_incoming"),
			value: "FORM_C2",
		},
	];
	wholedata: any;
	searchKey = "";
	size = "10";
	page = 1;
	transaction_key = "FORM_C1";
	constructor(
		public router: Router,
		public ds: DataService,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		private dialog: MatDialog,
		private sweetAlert: SweetAlertService
	) {}

	ngOnInit(): void {
		this.get_workarea_communications();
	}
	get_workarea_communications() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("searchKey", this.searchKey || "");
		formData.append(
			"extra[projects_profile_id]",
			this.project_data?.projects_profile_id || ""
		);
		formData.append(
			"extra[projects_work_area_id]",
			this.design_data?.projects_work_area_id || ""
		);
		formData.append(
			"extra[transaction_key]",
			this.transaction_key || "FORM_C1"
		);
		this.ds
			.post(`ccustom/projects_inout_lists/${this.page}/${this.size}`, formData)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.wholedata = res;
					} else {
						this.wholedata = null;
					}
				},
				(error) => {
					this.spinner.hide();
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}

	newCommunication(name) {
		if (name == "incoming") {
			this.router.navigate(["/Communication/form/FORM_C2/cr/0"], {
				state: {
					from: "projects",
					id: this.design_data?.projects_work_area_id,
				},
			});
		} else if (name == "outgoing") {
			this.router.navigate(["/Communication/form/FORM_C1/cr/0"], {
				state: {
					from: "projects",
					id: this.design_data?.projects_work_area_id,
				},
			});
		}
	}
	onViewFile(data) {
		this.ds.getActionByUrl([], data).subscribe(
			(res) => {
				if (res.status) {
					if (res.extension == "pdf") {
						let src = "data:" + res["content-type"] + ";base64," + res.base64;
						const dialogRef = this.dialog.open(PreviewPdfComponent, {
							disableClose: false,
							width: "100%",
							data: {
								src: src,
								base64: res.base64,
							},
						});
					} else {
						this.ds.showPreviewWithoutAPi(res);
					}
				} else {
					this.sweetAlert.errorToast(data.error, 2000);
				}
			},
			(error) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					error.error + (error && error.error && error.error.error)
						? error.error.error
						: this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

	public load_page_size(pageno) {
		this.size = pageno;
		this.get_workarea_communications();
	}
	public page_length(page) {
		this.page = page;
		this.get_workarea_communications();
	}
}
