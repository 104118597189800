<div class="mbrl15 martb0">
	<div class="row">
		<div class="col-12">
			<div class="input-group input-group-sm mb-10">
				<input
					type="text"
					class="form-control almnabrformcontrol"
					(keyup)="getConditionsList()"
					[(ngModel)]="searchKey"
					placeholder="{{ 'lang_search' | language }}"
				/>
				<span class="input-group-btn">
					<button
						class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
						id="btnSearch"
						(click)="onAddEditCondition()"
						type="button"
						matTooltip="{{ 'lang_add' | language }}"
					></button>
				</span>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_title_english" | language }}</td>
							<td>{{ "lang_title_arabic" | language }}</td>
							<td>{{ "lang_sort" | language }}</td>
							<td>{{ "lang_required_or_optional" | language }}</td>
							<td>{{ "lang_condition_type" | language }}</td>
							<td>{{ "lang_quotation_types_keyword" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody *ngIf="conditionsList?.length != 0" class="AppendList">
						<tr
							*ngFor="
								let row of conditionsList;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ row.rf_name }}</td>
							<td>{{ row.rf_name }}</td>
							<td>{{ row.rf_sort }}</td>
							<td>{{ row.rf_required_or_optional }}</td>
							<td>{{ row.condition_type }}</td>
							<td>{{ row.quotation_types_keyword }}</td>
							<td>
								<i
									class="fa fa-cog mnabricon"
									[matMenuTriggerFor]="menu"
									matTooltip="{{ 'lang_settings' | language }}"
								></i>
								<mat-menu #menu="matMenu">
									<button
										mat-menu-item
										class="d-flex justify-content-center align-items-center"
										(click)="onAddEditCondition(row)"
									>
										<span>{{ "lang_edit" | language }}</span>
										<i
											class="fa fa-pencil-square-o ml-1 mr-1 fa-icon mnabricon"
										></i>
									</button>
									<button
										mat-menu-item
										class="d-flex justify-content-center align-items-center"
										(click)="deleteCondition(row?.rf_id)"
									>
										<span>{{ "lang_delete" | language }}</span>
										<i class="fa fa-trash ml-1 mr-1 fa-icon red"></i>
									</button>
								</mat-menu>
							</td>
						</tr>
					</tbody>
					<tbody *ngIf="conditionsList?.length == 0" class="AppendList">
						<tr class="odd">
							<td colspan="10" align="center">{{ nodata }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
