import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SpcreateComponent } from './spcreate/spcreate.component';
import { SpeditComponent } from './spedit/spedit.component';
import { SplistComponent } from './splist/splist.component';
import { SpsettingsComponent } from './spsettings/spsettings.component';
import { SpviewComponent } from './spview/spview.component';

const routes: Routes = [
  {
    path: 'settings',
    component: SpsettingsComponent,
    data: { title: 'Supplier Settings' }
  },
  {
    path: 'create',
    component: SpcreateComponent,
    data: { title: 'Create Supplier' }
  },
  {
    path: 'lists',
    component: SplistComponent,
    data: { title: 'All Supplier' }
  },
  {
    path: 'view/:id',
    component: SpviewComponent,
    data: { title: 'View Supplier' }
  },
  {
    path: 'edit/:id',
    component: SpeditComponent,
    data: { title: 'Edit Supplier' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuppliersRoutingModule { }
