import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddEditSubtaskComponent } from "./add-edit-subtask/add-edit-subtask.component";

@Component({
	selector: "app-sub-tasks",
	templateUrl: "./sub-tasks.component.html",
	styleUrls: ["./sub-tasks.component.scss"],
})
export class SubTasksComponent implements OnInit {
	searchKey = "";
	pageno = 1;
	pagesize = "10";
	collectionSize: any;
	subtaskList = [];
	nodata: string = this.lang.transform("lang_loading");
	constructor(
		private ds: DataService,
		private spinner: PreloaderService,
		private lang: LanguagePipe,
		private sweetAlert: SweetAlertService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.getSubtaskList();
	}

	getSubtaskList() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("sub_task_id", "");
		formData.append("search_key", this.searchKey || "");
		this.ds
			.post(
				`project_design/get_settings_sub_tasks/${this.pageno}/${this.pagesize}`,
				formData
			)
			.subscribe((res: any) => {
				this.spinner.hide();
				if (res.status) {
					this.subtaskList = res?.data;
					this.collectionSize = res?.page?.total_records;
				} else {
					this.subtaskList = [];
					this.collectionSize = 0;
					this.nodata = res.error;
				}
			});
	}
	onAddEditSubtask(data?) {
		const dialogRef = this.dialog.open(AddEditSubtaskComponent, {
			width: "600px",
			data: {
				subtask_data: data,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.getSubtaskList();
			}
		});
	}
	// deleteSubtask(id) {
	// 	this.sweetAlert
	// 		.confirmMessage(
	// 			this.lang.transform("lang_are_you_sure"),
	// 			this.lang.transform("lang_yes")
	// 		)
	// 		.then((resConfirm) => {
	// 			if (resConfirm.isConfirmed) {
	// 				this.spinner.show();
	// 				this.ds.post(`tasks/delete_group/${id}`, {}).subscribe((res) => {
	// 					this.spinner.hide();
	// 					if (res.status) {
	// 						this.sweetAlert.successToast(res.message, 2000);
	// 						this.getSubtaskList();
	// 					} else {
	// 						this.sweetAlert.errorToast(res.error, 2000);
	// 					}
	// 				});
	// 			}
	// 		});
	// }
}
