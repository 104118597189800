import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "@core/bootstrap/data.service";

@Component({
	selector: "app-confirm-cpay-create",
	templateUrl: "./confirm-cpay-create.component.html",
	styleUrls: ["./confirm-cpay-create.component.scss"],
})
export class ConfirmCpayCreateComponent implements OnInit {
	contractor_manager_step_require = "0";
	constructor(
		public dialogRef: MatDialogRef<ConfirmCpayCreateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private ds: DataService
	) {}

	ngOnInit(): void {}

	onSubmit() {
		this.dialogRef.close({
			status: true,
			contractor_manager_step_require: this.contractor_manager_step_require,
		});
	}
}
