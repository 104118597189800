<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno">
	<mat-accordion class="stylepanels">
		<mat-expansion-panel class="mpanel projectsettings" [expanded]="true">
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_settings" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 mb-0">
					<div class="row">
						<div class="col-xs-12 col-12 pad0">
							<mat-horizontal-stepper #stepper class="pad0">
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-users fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_permit_mentions" | language }}</div>
									</ng-template>
									<app-permission-mentions></app-permission-mentions>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_group_types" | language }}</div>
									</ng-template>
									<app-wtypes
										(actionEvent)="
											load_child_data($event);
											load_child_data_collection($event)
										"
									></app-wtypes>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_service_group1" | language }} 1</div>
									</ng-template>
									<app-wgroup1
										(actionEvent)="
											load_child_data($event);
											load_child_data_collection($event)
										"
									></app-wgroup1>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-object-group fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_service_group1" | language }} 2</div>
									</ng-template>
									<app-wgroup2
										(actionEvent)="
											load_child_data($event);
											load_child_data_collection($event)
										"
									></app-wgroup2>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_project_platforms" | language }}</div>
									</ng-template>
									<app-wplatforms
										(actionEvent)="
											load_child_data($event);
											load_child_data_collection($event)
										"
									></app-wplatforms>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_work_level" | language }}</div>
									</ng-template>
									<app-worklevels></app-worklevels>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_units" | language }}</div>
									</ng-template>
									<app-wunits></app-wunits>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_collections" | language }}</div>
									</ng-template>
									<app-collection
										(actionEvent)="load_child_data_collection($event)"
									></app-collection>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_collection_details" | language }}</div>
									</ng-template>
									<app-collection-details></app-collection-details>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_snaglist" | language }}</div>
									</ng-template>
									<app-snaglist></app-snaglist>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_design_settings" | language }}</div>
									</ng-template>
									<app-design-settings></app-design-settings>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_quotation_settings" | language }}</div>
									</ng-template>
									<app-quotation-settings></app-quotation-settings>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_bank_payments" | language }}</div>
									</ng-template>
									<app-b-payments></app-b-payments>
								</mat-step>
							</mat-horizontal-stepper>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</section>
