import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-trw-dialog',
  templateUrl: './trw-dialog.component.html',
  styleUrls: ['./trw-dialog.component.scss']
})
export class TRWDialogComponent implements OnInit {
  lang_key = localStorage.getItem('lang_key') || 'en';
  projects_supervision_id = '';
  projects_profile_id = '';
  projects_work_area_id = '';
  transaction_request_id = '';
  tbv_barcodeData     = '';
  tsr_report_id = '';
  list_type = '';
  form: FormGroup;
  other_data:FormArray;
  other_attachments:FormArray;
  filename:string  = this.lang.transform('lang_select_files');
  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe, private sweetAlert:SweetAlertService,
    public spinner : PreloaderService, public alert:AlertService, private fb:FormBuilder, private dialogRef: MatDialogRef<TRWDialogComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data) {
      this.projects_supervision_id = data?.projects_supervision_id;
      this.projects_profile_id = data?.projects_profile_id;
      this.projects_work_area_id = data?.projects_work_area_id;
      this.transaction_request_id = data?.transaction_request_id;
      this.tbv_barcodeData = data?.tbv_barcodeData;
      this.tsr_report_id = data?.tsr_report_id;
      this.list_type = data?.list_type;
      this.buildForm();
      this.other_data = this.form.get("data") as FormArray;
      this.other_attachments = this.form.get("attachments") as FormArray; 
    }

  ngOnInit(): void {
    this.add_new_data();
    this.addfiles({attach_title: this.lang.transform('lang_static_file')});
  }

  buildForm() {
    this.form = this.fb.group({
      report_number: [null, Validators.required],
      data         : this.fb.array([]),
      attachments  : this.fb.array([])
    })
  }
  dataRow() {
    if(this.list_type == 'soil') { 
      return this.fb.group({
        sample_number: [null, Validators.required],
        layer_number: [null, Validators.required],
        depth_number: [null, Validators.required],
        material_description: [null, Validators.required],
        percent_compaction: [null, Validators.required]
      });
    } else {
      return this.fb.group({
        sample_number: [null, Validators.required],
        strength_result: [null, Validators.required]
      });
    }
    
  }
  get dataList() {
    return this.form.get("data") as FormArray;
  }

  add_new_data() {
    this.other_data.push(this.dataRow());
  }

  remove_data(index) {
    this.other_data.removeAt(index);
  }

  get filesFormGroup() {
    return this.form.get("attachments") as FormArray;
  }

  public files(value?): FormGroup {
    return this.fb.group({
      delete_icon     : new FormControl(value?.attach_title || ''),
      view            : new FormControl(value?.file || null),
      attach_title    : new FormControl({value : (value) ? value?.attach_title : '', disabled : (value?.attach_title) ? true : false}, [Validators.required]),
      file            : new FormControl(value?.file || null,  [Validators.required]),
    });
  }
  public addfiles(value?) {
    if (this.other_attachments.length < 10) {
      this.other_attachments.push(this.files(value));
      this.other_attachments.setValidators(Validators.required);
      this.other_attachments.updateValueAndValidity();
    }
  }
  public removefiles(index) {
    if(this.other_attachments.length == 1) {
      this.other_attachments.removeAt(index);
      this.other_attachments.clearValidators();
      this.other_attachments.updateValueAndValidity();
    } else {
      this.other_attachments.removeAt(index);
    }
  }

  checkValidators() {
    if(this.form.get('report_number').invalid) {
      this.sweetAlert.errorMessage('lang_report_number_required');
      return false;
    } else if(this.form.get('data').invalid) {
      this.sweetAlert.errorMessage('lang_all_fields_required');
      return false;
    } else if(this.form.get('attachments').invalid) {
      this.sweetAlert.errorMessage('lang_attachments_required');
      return false;
    } else {
      return true;
    }
  }

  submit() {
    if(this.checkValidators()) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('projects_supervision_id', this.projects_supervision_id || '');
      formData.append('report_number', this.form.get('report_number').value || '');
      formData.append('transaction_request_id', this.transaction_request_id || '');
      formData.append('tbv_barcodeData', this.tbv_barcodeData || '');
      this.other_data?.value?.forEach((element, index) => {
        formData.append(`data[${index}][sample_number]`, element?.sample_number || '');
        if(this.list_type == 'concrete') {
          formData.append(`data[${index}][strength_result]`, element?.strength_result || '');
        }
        if(this.list_type == 'soil') {
          formData.append(`data[${index}][layer_number]`, element?.layer_number || '');
          formData.append(`data[${index}][depth_number]`, element?.depth_number || '');
          formData.append(`data[${index}][material_description]`, element?.material_description || '');
          formData.append(`data[${index}][percent_compaction]`, element?.percent_compaction || '');
        }
      });
      this.other_attachments?.value?.forEach((element, index) => {
        formData.append(`attach[${index}][description]`, element?.attach_title || '');
        formData.append(`attach[${index}][file]`, element?.file || '');
      });
      let url = `ptesting/action_soil_testing_reports/${this.projects_profile_id}/${this.projects_work_area_id}/${this.tsr_report_id}`;
      if(this.list_type == 'concrete') {
        url = `ptesting/action_concreate_testing_reports/${this.projects_profile_id}/${this.projects_work_area_id}/${this.tsr_report_id}`;
      }
      this.ds.postActionByUrl(formData, url).subscribe(res => {
          this.spinner.hide();
          this.dialogRef.close(this.list_type);
          if(res?.status) {
            this.sweetAlert.successMessage(res?.msg);
          } else {
            this.sweetAlert.errorMessage(res?.error);
          }
          
        }, err  => {
          this.dialogRef.close(this.list_type);
          this.sweetAlert.errorMessage(err?.error?.error);
        })
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
