<div class="card">
	<i class="together-icon">
		<i
			*ngIf="makeAction"
			matTooltip="{{ 'lang_add_or_edit' | language }}"
			(click)="emptyFormEdit(); openModal(createupdatesetting)"
			class="fa fa-pencil-square-o s2icon mnabricon"
		></i>
		<i
			matTooltip="{{ 'lang_export_plan_file' | language }}"
			(click)="downloadPlanFile()"
			class="fa fa-download s2icon mnabricon"
		></i>
		<i
			matTooltip="{{ 'lang_show_plan_history' | language }}"
			(click)="get_plan_history()"
			class="fa fa-history s2icon mnabricon"
		></i>
	</i>
	<div class="card-header">{{ "lang_settings" | language }}</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label>{{
							"lang_projects_total_construction_cost" | language
						}}</label>
						<div class="" *ngIf="setting_data.length != '0'">
							<b *ngIf="setting_data">{{
								ds.addCommas(setting_data.total_construction_cost)
							}}</b>
						</div>
						<div class="" *ngIf="setting_data.length == '0'"><b>----</b></div>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label>{{ "lang_projects_rfi_alert_days" | language }}</label>
						<div class="" *ngIf="setting_data.length != '0'">
							<b
								>{{ setting_data.supervision_settings_rfialert_expire }}
								{{ "lang_days" | language }}</b
							>
						</div>
						<div class="" *ngIf="setting_data.length == '0'"><b>----</b></div>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label>{{
							"lang_projects_drawing_submital_days" | language
						}}</label>
						<div class="" *ngIf="setting_data.length != '0'">
							<b
								>{{
									setting_data.supervision_settings_drawing_submittal_alert_expire
								}}
								{{ "lang_days" | language }}</b
							>
						</div>
						<div class="" *ngIf="setting_data.length == '0'"><b>----</b></div>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label>{{
							"lang_projects_material_submital_days" | language
						}}</label>
						<div class="" *ngIf="setting_data.length != '0'">
							<b
								>{{
									setting_data.supervision_settings_material_submittal_alert_expire
								}}
								{{ "lang_days" | language }}</b
							>
						</div>
						<div class="" *ngIf="setting_data.length == '0'"><b>----</b></div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label>{{ "lang_implimentation_required" | language }}</label>
						<div class="" *ngIf="setting_data.length != '0'">
							<b
								*ngIf="
									setting_data &&
									setting_data.implimentation_phases_required == '1'
								"
								>{{ "lang_yes" | language }}</b
							>
							<b
								*ngIf="
									setting_data &&
									setting_data.implimentation_phases_required == '0'
								"
								>{{ "lang_no" | language }}</b
							>
						</div>
						<div class="" *ngIf="setting_data.length == '0'"><b>----</b></div>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label>{{ "lang_project_logo" | language }}</label>
						<div
							class=""
							*ngIf="
								setting_data.length != '0' &&
								setting_data.supervision_settings_project_logo_url
							"
						>
							<i
								class="fa fa-picture-o fa-icon"
								matTooltip="{{ 'lang_view' | language }}"
								(click)="
									ds?.preview_pdf_file(
										setting_data.supervision_settings_project_logo_url,
										'#pdfPreview'
									)
								"
							></i>
						</div>
						<div
							*ngIf="setting_data.supervision_settings_project_logo_url == null"
						>
							<b>----</b>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label>{{ "lang_drawing_file" | language }}</label>
						<div
							class=""
							*ngIf="
								setting_data.length != '0' &&
								setting_data.supervision_settings_project_drawing_file
							"
						>
							<i
								class="fa fa-file-pdf-o fa-icon"
								matTooltip="{{ 'lang_view' | language }}"
								(click)="
									ds?.preview_pdf_file(
										setting_data.supervision_settings_project_drawing_file
									)
								"
							></i>
						</div>
						<div
							*ngIf="
								setting_data.supervision_settings_project_drawing_file == null
							"
						>
							<b>----</b>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label>{{ "lang_writer" | language }}</label>
						<div class="" *ngIf="setting_data.length != '0'">
							<b>{{ setting_data.writer }}</b>
						</div>
						<div class="" *ngIf="setting_data.length == '0'"><b>----</b></div>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label>{{ "lang_ondate" | language }}</label>
						<div class="" *ngIf="setting_data.length != '0'">
							<b>{{ setting_data.supervision_settings_created_datetime }}</b>
						</div>
						<div class="" *ngIf="setting_data.length == '0'"><b>----</b></div>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label>{{ "lang_start_date" | language }}</label>
						<div *ngIf="setting_data.supervision_start_date">
							<b>{{ setting_data.supervision_start_date }}</b>
						</div>
						<div *ngIf="!setting_data.supervision_start_date"><b>----</b></div>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label>{{ "lang_expiry_date" | language }}</label>
						<div class="" *ngIf="setting_data.supervision_expiry_date">
							<b>{{ setting_data.supervision_expiry_date }}</b>
						</div>
						<div class="" *ngIf="!setting_data.supervision_expiry_date">
							<b>----</b>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 mt-3">
					<ngx-skeleton-loader
						*ngIf="loading_attachments"
						count="5"
						[theme]="{ 'border-radius': '3px', height: '40px' }"
					></ngx-skeleton-loader>
					<div
						class="table-responsive dropdownyes customResponsive tablefields"
						*ngIf="history_files?.length && !loading_attachments"
					>
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<th>#</th>
									<th>{{ "lang_type_code_system" | language }}</th>
									<th>{{ "lang_writer" | language }}</th>
									<th>{{ "lang_created_date" | language }}</th>
									<th>{{ "lang_action" | language }}</th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let item of history_files
											| slice: (page - 1) * size:page * size;
										let i = index;
										let odd = odd;
										let even = even
									"
								>
									<td>{{ i + 1 }}</td>
									<td>{{ item?.type_code_system }}</td>
									<td>{{ item?.writer }}</td>
									<td>{{ item?.created_datetime }}</td>
									<td>
										<i
											class="fa fa-download"
											style="
												font-size: 20px;
												margin: 0 5px;
												color: rgb(19, 65, 146);
											"
											(click)="download_plan_history(item)"
										></i>
									</td>
								</tr>
							</tbody>
						</table>
						<div
							*ngIf="history_files?.length > 0"
							class="mt-2"
							style="
								height: 40px;
								display: flex;
								justify-content: center;
								align-items: center;
							"
						>
							<ngb-pagination
								*ngIf="history_files.length"
								[collectionSize]="history_files.length"
								[(page)]="page"
								[maxSize]="size"
								[boundaryLinks]="true"
							></ngb-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #createupdatesetting>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_settings" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div class="modal-body">
				<div class="form-group">
					<label for="supervision_settings_rfialert_expire"
						>{{ "lang_projects_total_construction_cost" | language }}:<span
							class="red"
							>*</span
						></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="total_construction_cost"
						(change)="
							ds.CheckDecimal(
								$event.target.value,
								form.get('total_construction_cost')
							)
						"
						placeholder="{{
							'lang_projects_total_construction_cost' | language
						}}"
					/>
					<mat-error
						*ngIf="
							form.controls['total_construction_cost'].touched &&
							form.controls['total_construction_cost'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="supervision_settings_rfialert_expire"
						>{{ "lang_projects_rfi_alert_days" | language }}:<span class="red"
							>*</span
						></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="supervision_settings_rfialert_expire"
						placeholder="{{ 'lang_projects_rfi_alert_days' | language }}"
					/>
					<mat-error
						*ngIf="
							form.controls['supervision_settings_rfialert_expire'].touched &&
							form.controls['supervision_settings_rfialert_expire'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="supervision_settings_drawing_submittal_alert_expire"
						>{{ "lang_projects_drawing_submital_days" | language }}:<span
							class="red"
							>*</span
						></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="supervision_settings_drawing_submittal_alert_expire"
						placeholder="{{ 'lang_projects_drawing_submital_days' | language }}"
					/>
					<mat-error
						*ngIf="
							form.controls[
								'supervision_settings_drawing_submittal_alert_expire'
							].touched &&
							form.controls[
								'supervision_settings_drawing_submittal_alert_expire'
							].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="supervision_settings_material_submittal_alert_expire"
						>{{ "lang_projects_material_submital_days" | language }}:<span
							class="red"
							>*</span
						></label
					>
					<input
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="supervision_settings_material_submittal_alert_expire"
						placeholder="{{
							'lang_projects_material_submital_days' | language
						}}"
					/>
					<mat-error
						*ngIf="
							form.controls[
								'supervision_settings_material_submittal_alert_expire'
							].touched &&
							form.controls[
								'supervision_settings_material_submittal_alert_expire'
							].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="implimentation_phases_required"
						>{{ "lang_implimentation_required" | language }}:<span class="red"
							>*</span
						></label
					>
					<select
						type="text"
						class="form-control almnabrformcontrol"
						formControlName="implimentation_phases_required"
					>
						<option value="1">{{ "lang_yes" | language }}</option>
						<option value="0">{{ "lang_no" | language }}</option>
					</select>
					<mat-error
						*ngIf="
							form.controls['implimentation_phases_required'].touched &&
							form.controls['implimentation_phases_required'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</div>
				<div class="form-group">
					<label for="supervision_start_date"
						>{{ "lang_start_date" | language }}:<span class="red"
							>*</span
						></label
					>
					<div class="input-group input-group-sm">
						<input
							[matDatepicker]="supervision_start_date"
							formControlName="supervision_start_date"
							class="form-control almnabrformcontrol"
							placeholder="YYYY/MM/DD"
							required
							readonly
						/>
						<span class="input-group-btn">
							<mat-datepicker-toggle
								class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
								matSuffix
								[for]="supervision_start_date"
							></mat-datepicker-toggle>
							<mat-datepicker #supervision_start_date></mat-datepicker>
						</span>
					</div>
				</div>
				<div class="form-group">
					<label for="supervision_expiry_date"
						>{{ "lang_expiry_date" | language }}:<span class="red"
							>*</span
						></label
					>
					<div class="input-group input-group-sm">
						<input
							[matDatepicker]="supervision_expiry_date"
							formControlName="supervision_expiry_date"
							class="form-control almnabrformcontrol"
							placeholder="YYYY/MM/DD"
							required
							readonly
						/>
						<span class="input-group-btn">
							<mat-datepicker-toggle
								class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
								matSuffix
								[for]="supervision_expiry_date"
							></mat-datepicker-toggle>
							<mat-datepicker #supervision_expiry_date></mat-datepicker>
						</span>
					</div>
				</div>
				<div class="form-group">
					<label for="implimentation_phases_required"
						>{{ "lang_project_logo" | language }}:
						<mat-icon
							*ngIf="isFile1"
							matTooltip="{{ 'lang_close' | language }}"
							(click)="removeFile1()"
							class="red mnabricon"
							>close</mat-icon
						></label
					>
					<div class="IDDIV">
						<input
							style="display: block"
							name="supervision_settings_project_logo_url"
							type="file"
							required
							onclick="this.value = null"
							(change)="UploadFile1($event)"
							class="nghide albutton width100"
						/>
						<label id="uploadText1" class="albutton">{{ filename1 }} </label>
					</div>
				</div>
				<div class="form-group">
					<label for="implimentation_phases_required"
						>{{ "lang_drawing_file" | language }}:
						<mat-icon
							*ngIf="isFile2"
							matTooltip="{{ 'lang_close' | language }}"
							(click)="removeFile2()"
							class="red mnabricon"
							>close</mat-icon
						></label
					>
					<div class="IDDIV">
						<input
							style="display: block"
							name="supervision_settings_project_drawing_file"
							type="file"
							required
							onclick="this.value = null"
							(change)="UploadFile2($event)"
							class="nghide albutton width100"
						/>
						<label id="uploadText2" class="albutton">{{ filename2 }} </label>
					</div>
				</div>
				<div class="form-group">
					<label for="implimentation_phases_required"
						>{{ "lang_plan_file" | language }}:
						<mat-icon
							*ngIf="isFile3"
							matTooltip="{{ 'lang_close' | language }}"
							(click)="removeFile3()"
							class="red mnabricon"
							>close</mat-icon
						></label
					>
					<div class="IDDIV">
						<input
							style="display: block"
							name="supervision_settings_project_plan_file"
							type="file"
							required
							onclick="this.value = null"
							(change)="UploadFile3($event)"
							class="nghide albutton width100"
						/>
						<label id="uploadText3" class="albutton">{{ filename3 }} </label>
					</div>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="card-footer pad15">
				<div class="row">
					<div class="col-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-xs-12 mb-10">
						<button type="submit" class="albutton">
							{{ "lang_submit" | language }}
						</button>
					</div>
					<div class="col-md-6 col-xs-12 mb-10">
						<button
							type="button"
							class="btn btn-secondary secondarybutton"
							data-dismiss="modal"
							(click)="modalRef.hide()"
						>
							{{ "lang_close" | language }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<div class="modal" id="pdfPreview">
	<div class="modal-dialog width70">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_preview" | language }}</h4>
				<button type="button" class="close" data-dismiss="modal">
					&times;
				</button>
			</div>
			<div class="modal-body">
				<div class="pdf-container">
					<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</div>
