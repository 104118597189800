import { E } from "@angular/cdk/keycodes";
import { Component, Inject, OnInit } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	Validators,
	FormArray,
	FormControl,
} from "@angular/forms";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-snag-note-add",
	templateUrl: "./snag-note-add.component.html",
	styleUrls: ["./snag-note-add.component.scss"],
})
export class SnagNoteAddComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	form: FormGroup;
	projects_profile_id = "";
	projects_work_area_id = "";
	projects_supervision_id = "";
	other_snaglists: FormArray;
	notes_List = [];
	group1_list = [];
	group2_list = [];
	filename: string = this.lang.transform("lang_select_files");
	//invalid data
	invalid_data = [];
	cplatforms = [];
	pending = [];
	platforms = [];
	invalid_data_page = 1;
	invalid_data_size = 10;
	err_msg = "";
	err_msg_types = [
		{
			label: this.lang.transform("lang_cplatforms"),
			value: "cplatforms",
		},
		{
			label: this.lang.transform("lang_pending"),
			value: "pending",
		},
		{
			label: this.lang.transform("lang_platforms"),
			value: "platforms",
		},
	];
	filterErrorMsgSelected = "";
	constructor(
		private fb: FormBuilder,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private spinner: PreloaderService,
		private dialogRef: MatDialogRef<SnagNoteAddComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.projects_profile_id = data.projects_profile_id;
		this.projects_work_area_id = data.projects_work_area_id;
		this.projects_supervision_id = data.projects_supervision_id;
	}

	ngOnInit(): void {
		this.buildForm();
		this.get_group1_list({ term: "" });
	}
	buildForm() {
		this.form = this.fb.group({
			snaglists: this.fb.array([], Validators.required),
		});
		this.other_snaglists = this.form.get("snaglists") as FormArray;
		this.addSnaglist();
	}
	get_group1_list(event) {
		this.spinner.show();
		let param = new FormData();
		param.append("search_key", event?.trem || "");
		param.append("lang_key", this.lang_key);
		this.ds.getActionByUrl(this.ds.formData2string(param), "lpgone").subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.group1_list = res?.records;
				} else {
					this.group1_list = [];
				}
			},
			(error) => {
				this.spinner.hide();
				this.group1_list = [];
				this.sweetAlert.errorToast(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	getGroup2List(snagIndex) {
		let param = new FormData();
		param.append("search_key", "");
		param.append("lang_key", this.lang_key);
		param.append(
			"platform_group1_code_system",
			this.other_snaglists.controls[snagIndex].get(
				"platform_group1_code_system"
			).value || ""
		);
		this.ds.getActionByUrl(this.ds.formData2string(param), "lpgtwo").subscribe(
			(res) => {
				if (res.status) {
					this.group2_list = res?.records;
				} else {
					this.group2_list = [];
				}
			},
			(error) => {
				this.group2_list = [];
				this.sweetAlert.errorToast(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	getNotesList(snagIndex) {
		this.spinner.show();
		let param = new FormData();
		param.append("lang_key", this.lang_key);
		param.append("search_key", "");
		param.append(
			"platform_group1_code_system",
			this.other_snaglists.controls[snagIndex].get(
				"platform_group1_code_system"
			).value || ""
		);
		param.append(
			"platform_group2_code_system",
			this.other_snaglists.controls[snagIndex].get(
				"platform_group2_code_system"
			).value || ""
		);
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "p/snag/snaglistnotes")
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.other_snaglists.controls[snagIndex]
							.get("notes_list")
							.setValue(res?.records);
					} else {
						this.other_snaglists.controls[snagIndex]
							.get("notes_list")
							.setValue([]);
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.other_snaglists.controls[snagIndex]
						.get("notes_list")
						.setValue([]);
					this.sweetAlert.errorToast(
						err && err.error && err.error.error
							? err.error.error
							: this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}

	snaglistFormGroup(): FormArray {
		return this.form.get("snaglists") as FormArray;
	}
	public snaglist(value?): FormGroup {
		return this.fb.group({
			delete_icon: new FormControl(value?.note_id || ""),
			platform_group1_code_system: new FormControl(
				value?.platform_group1_code_system || null
			),
			platform_group2_code_system: new FormControl(
				value?.platform_group2_code_system || null
			),
			note_id: new FormControl(value?.note_id || null),
			notes_list: new FormControl([]),
			units: this.fb.array([], Validators.required),
			show_notes: new FormControl(value?.show_notes || false),
		});
	}
	public addSnaglist(value?) {
		this.other_snaglists.push(this.snaglist(value));
		this.other_snaglists.setValidators(Validators.required);
		this.other_snaglists.updateValueAndValidity();
	}
	public removeSnaglist(index) {
		if (this.other_snaglists.length == 1) {
			this.other_snaglists.removeAt(index);
			this.other_snaglists.clearValidators();
			this.other_snaglists.updateValueAndValidity();
		} else {
			this.other_snaglists.removeAt(index);
		}
	}
	unitFormGroup(snagIndex): FormArray {
		return this.snaglistFormGroup().controls[snagIndex].get(
			"units"
		) as FormArray;
	}
	public unit(value?): FormGroup {
		return this.fb.group({
			delete_icon: new FormControl(value?.general_number || ""),
			general_number: new FormControl(value?.general_number || null),
			unit_list: new FormControl([]),
			work_levels: this.fb.array([], Validators.required),
			unit_tab_tilte: "",
		});
	}
	public addUnit(index, value?) {
		const unitFormArray = this.other_snaglists
			.at(index)
			.get("units") as FormArray;
		unitFormArray.push(this.unit(value));
		unitFormArray.setValidators(Validators.required);
		unitFormArray.updateValueAndValidity();
		this.other_snaglists.at(index).get("show_notes").setValue(true);
	}
	public removeUnit(snaglistIndex, unitIndex) {
		const unitFormArray = this.other_snaglists
			.at(snaglistIndex)
			.get("units") as FormArray;
		if (unitFormArray.length == 1) {
			unitFormArray.removeAt(unitIndex);
			unitFormArray.clearValidators();
			unitFormArray.updateValueAndValidity();
		} else {
			unitFormArray.removeAt(unitIndex);
		}
	}
	worklevelFormGroup(snagIndex, unitIndex): FormArray {
		return this.unitFormGroup(snagIndex).controls[unitIndex].get(
			"work_levels"
		) as FormArray;
	}
	public worklevel(value?): FormGroup {
		return this.fb.group({
			delete_icon: new FormControl(value?.level_key || ""),
			level_key: new FormControl(value?.level_key || null),
			level_description: new FormControl(value?.level_description || null),
			level_attachment: new FormControl(value?.level_attachment || null),
			levels_list: new FormControl([]),
		});
	}
	public addWorklevel(snagIndex, unitIndex, value?) {
		const unitFormArray = this.other_snaglists
			.at(snagIndex)
			.get("units") as FormArray;
		const worklevelsFormArray = unitFormArray.controls[unitIndex].get(
			"work_levels"
		) as FormArray;
		worklevelsFormArray.push(this.worklevel(value));
		worklevelsFormArray.setValidators(Validators.required);
		worklevelsFormArray.updateValueAndValidity();
		this.get_worklevels(
			{ term: "" },
			snagIndex,
			unitIndex,
			worklevelsFormArray?.length - 1
		);
	}
	public removeWorklevel(snaglistIndex, unitIndex, worklevelIndex) {
		const unitFormArray = this.other_snaglists
			.at(snaglistIndex)
			.get("units") as FormArray;
		const worklevelsFormArray = unitFormArray.controls[unitIndex].get(
			"work_levels"
		) as FormArray;
		if (worklevelsFormArray.length == 1) {
			worklevelsFormArray.removeAt(worklevelIndex);
			worklevelsFormArray.clearValidators();
			worklevelsFormArray.updateValueAndValidity();
		} else {
			worklevelsFormArray.removeAt(worklevelIndex);
		}
	}
	add_snag_note() {
		if (this.form.valid) {
			this.spinner.show();
			let formData = new FormData();
			formData.append("projects_profile_id", this.projects_profile_id || "");
			formData.append(
				"projects_work_area_id",
				this.projects_work_area_id || ""
			);
			formData.append(
				"projects_supervision_id",
				this.projects_supervision_id || ""
			);
			for (let i = 0; i < this.form.value.snaglists.length; i++) {
				formData.append(
					`snaglists[${i}][note_id]`,
					this.form.value.snaglists[i].note_id || ""
				);
				for (let j = 0; j < this.form.value.snaglists[i].units.length; j++) {
					formData.append(
						`snaglists[${i}][units][${j}][general_number]`,
						this.form.value.snaglists[i].units[j].general_number || ""
					);
					for (
						let k = 0;
						k < this.form.value.snaglists[i].units[j].work_levels.length;
						k++
					) {
						formData.append(
							`snaglists[${i}][units][${j}][work_levels][${k}][level_key]`,
							this.form.value.snaglists[i].units[j].work_levels[k].level_key ||
								""
						);
						formData.append(
							`snaglists[${i}][units][${j}][work_levels][${k}][level_description]`,
							this.form.value.snaglists[i].units[j].work_levels[k]
								.level_description || ""
						);
						formData.append(
							`snaglists[${i}][units][${j}][work_levels][${k}][level_attachment]`,
							this.form.value.snaglists[i].units[j].work_levels[k]
								.level_attachment || ""
						);
					}
				}
			}
			this.ds.post("p/snag/snagnotecreate", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.dialogRef.close(true);
						this.sweetAlert.successToast(res.msg, 2000);
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
						this.err_msg = res?.error;
						this.cplatforms = res?.cplatforms;
						this.pending = res?.pending;
						this.platforms = res?.platforms;
						if (this.cplatforms?.length) {
							this.invalid_data = this.cplatforms;
							this.filterErrorMsgSelected = "cplatforms";
						} else if (this.pending?.length) {
							this.invalid_data = this.pending;
							this.filterErrorMsgSelected = "pending";
						} else {
							this.invalid_data = this.platforms;
							this.filterErrorMsgSelected = "platforms";
						}
					}
				},
				(error) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
		} else {
			this.sweetAlert.errorToast(
				this.lang.transform("lang_fill_required_fields"),
				2000
			);
		}
	}

	onShowUnits(index) {
		this.other_snaglists
			.at(index)
			.get("show_notes")
			.setValue(!this.other_snaglists.at(index).get("show_notes").value);
	}

	get_unit_general_List(event, snagIndex, unitIndex) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("projects_profile_id", this.projects_profile_id || "");
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append(
			"projects_supervision_id",
			this.projects_supervision_id || ""
		);
		formData.append("lang_key", this.lang_key || "");
		formData.append("search_key", event?.term || "");
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"p/snag/snaglistnotesunits"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.unitFormGroup(snagIndex)
							.controls[unitIndex].get("unit_list")
							.setValue(res.records);
					} else {
						this.unitFormGroup(snagIndex)
							.controls[unitIndex].get("unit_list")
							.setValue([]);
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.unitFormGroup(snagIndex)
						.controls[unitIndex].get("unit_list")
						.setValue([]);
					this.sweetAlert.errorToast(
						err && err.error && err.error.error
							? err.error.error
							: this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
	get_worklevels(event, snagIndex, unitIndex, worklevelIndex) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("projects_profile_id", this.projects_profile_id || "");
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append(
			"projects_supervision_id",
			this.projects_supervision_id || ""
		);
		formData.append("lang_key", this.lang_key || "");
		formData.append("search_key", event?.term || "");
		formData.append(
			"general_number",
			this.unitFormGroup(snagIndex).controls[unitIndex].get("general_number")
				.value || ""
		);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"p/snag/snaglistnotesworklevels"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.worklevelFormGroup(snagIndex, unitIndex)
							.controls[worklevelIndex].get("levels_list")
							.setValue(res.records);
					} else {
						this.worklevelFormGroup(snagIndex, unitIndex)
							.controls[worklevelIndex].get("levels_list")
							.setValue([]);
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.worklevelFormGroup(snagIndex, unitIndex)
						.controls[worklevelIndex].get("levels_list")
						.setValue([]);
					this.sweetAlert.errorToast(
						err && err.error && err.error.error
							? err.error.error
							: this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}

	onChangeUnit(snaglistIndex, unitIndex) {
		const unitFormArray = this.other_snaglists
			.at(snaglistIndex)
			.get("units") as FormArray;
		const unitId =
			unitFormArray.controls[unitIndex].get("general_number")?.value;
		const unitLabel = unitFormArray.controls[unitIndex]
			.get("unit_list")
			?.value?.find((el) => {
				return el?.value == unitId;
			})?.label;
		unitFormArray.controls[unitIndex].get("unit_tab_tilte").setValue(unitLabel);
	}

	onFilterErrorMsg() {
		if (this.filterErrorMsgSelected == "cplatforms") {
			this.invalid_data = this.cplatforms;
		} else if (this.filterErrorMsgSelected == "pending") {
			this.invalid_data = this.pending;
		} else {
			this.invalid_data = this.platforms;
		}
	}
}
