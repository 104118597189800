<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_add" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="form">
		<div class="row">
			<div class="col-12 col-sm-6">
				<label id="example-radio-group-label" style="display: block"
					>{{ "lang_work_site" | language }}
				</label>
				<mat-radio-group
					aria-labelledby="example-radio-group-label"
					formControlName="work_site_type"
					(change)="onChangeWorksiteType()"
				>
					<mat-radio-button [value]="'GN'">
						{{ "lang_general_numbers" | language }}
					</mat-radio-button>
					<mat-radio-button [value]="'IP'">
						{{ "lang_blocks" | language }}
					</mat-radio-button>
				</mat-radio-group>
			</div>
			<div
				class="col-12 col-sm-6"
				*ngIf="form?.get('work_site_type').value == 'GN'"
			>
				<label for="unit_general_numbers">{{
					"lang_general_numbers" | language
				}}</label>
				<ng-select
					[items]="unit_general_List"
					[multiple]="true"
					bindLabel="label"
					appendTo="body"
					bindValue="value"
					clearAllText="Clear"
					formControlName="unit_general_numbers"
					placeholder="{{ 'lang_general_numbers' | language }}"
					(search)="get_unit_general_List($event)"
					[searchFn]="ds.customSearchFn"
					id="unit_general_numbers"
					name="unit_general_numbers"
				>
				</ng-select>
			</div>
			<div
				class="col-12 col-sm-6"
				*ngIf="form?.get('work_site_type').value == 'IP'"
			>
				<label for="blocks">{{ "lang_blocks" | language }} </label>
				<ng-select
					[items]="blocks_List"
					[multiple]="true"
					bindLabel="label"
					appendTo="body"
					bindValue="value"
					clearAllText="Clear"
					formControlName="work_site_blocks"
					placeholder="{{ 'lang_blocks' | language }}"
					[searchFn]="ds.customSearchFn"
					id="blocks"
					name="blocks"
				>
				</ng-select>
			</div>
			<div class="col-12 TableParts" *ngIf="user_type_id == '1'">
				<p class="mb-10">
					{{ "lang_attachments" | language }}
					<span class="red" *ngIf="other_attachments?.length">*</span>
				</p>
				<div
					class="table-responsive customResponsive tablefields quotationitemtable"
				>
					<table
						border="1"
						class="table CustomTable"
						width="100%"
						formArrayName="attachments"
					>
						<thead>
							<tr>
								<td width="2%" style="padding: 5px; word-break: break-all">
									#
								</td>
								<td width="2%" style="padding: 5px; word-break: break-all">
									<mat-icon
										class="mnabricon"
										matTooltip="{{ 'lang_add' | language }}"
										(click)="addfiles()"
										>add_box</mat-icon
									>
								</td>
								<td width="32%" style="padding: 5px; word-break: break-all">
									{{ "lang_title_english" | language }}
								</td>
								<td width="32%" style="padding: 5px; word-break: break-all">
									{{ "lang_title_arabic" | language }}
								</td>
								<td width="32%" style="padding: 5px; word-break: break-all">
									{{ "lang_file" | language }}
								</td>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let file of filesFormGroup.controls;
									let i = index;
									let odd = odd;
									let even = even
								"
								[formGroupName]="i"
							>
								<td
									class="verticalalignmid"
									style="padding: 5px; word-break: break-all"
								>
									{{ i + 1 }}
								</td>
								<td class="verticalalignmid">
									<mat-icon
										*ngIf="
											!other_attachments.controls[i].get('delete_icon').value
										"
										(click)="removefiles(i)"
										class="mnabricon red"
										matTooltip="{{ 'lang_delete' | language }}"
										>delete_forever</mat-icon
									>
								</td>
								<td
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									<input
										type="text"
										formControlName="title_english"
										placeholder="{{ 'lang_title_english' | language }}"
										autocomplete="off"
										class="form-control almnabrformcontrol"
									/>
								</td>
								<td
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									<input
										type="text"
										formControlName="title_arabic"
										placeholder="{{ 'lang_title_arabic' | language }}"
										autocomplete="off"
										class="form-control almnabrformcontrol"
									/>
								</td>
								<td
									class="verticalalignmid"
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
									*ngIf="!other_attachments.controls[i].get('view').value"
								>
									<input
										name="file"
										type="file"
										required
										onclick="this.value = null"
										(change)="
											ds.uploadSingleFile(
												$event,
												other_attachments.controls[i].get('file'),
												'file' + i,
												filename
											)
										"
										class="nghide albutton width100"
									/>
									<label id="file{{ i }}" class="albutton">{{
										filename
									}}</label>
								</td>
								<td *ngIf="other_attachments.controls[i].get('view').value">
									<i
										class="fa fa-file-pdf-o"
										aria-hidden="true"
										(click)="
											ds?.preview_pdf_file(
												other_attachments.controls[i].get('file').value
											)
										"
									></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="add_initial_delivery()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
