<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno">
    <mat-accordion class="notifications" >
        <mat-expansion-panel class="mpanel" [expanded]="true">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_notifications' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15 martb0">
                    <div class="row">
                        <div class="col-xs-12 col-12 pad0">
                            <mat-horizontal-stepper #stepper class="pad0">
                                <mat-step>
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-id-card fa-icon"></i>
                                                <span class="badge bg-red-500" *ngIf="id_count > 0 ">{{id_count}}</span>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_id_details' | language }}</div>
                                    </ng-template>
                                    <div class="card">
                                        <div class="width100">
                                            <div class="card-header">{{'lang_human_resources_id_details' | language }}</div>
                                            <div class="card-body pad0">
                                                <div class="mbrl15">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="table-responsive customResponsive">
                                                                <table border="1" class="table CustomTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <td>#</td>
                                                                            <td>{{'lang_human_resources_number' | language }}
                                                                            </td>
                                                                            <td>{{'lang_human_resources_id_number' | language }}
                                                                            </td>
                                                                            <td>{{'lang_human_resources_employee_name' | language }}
                                                                            </td>
                                                                            <td>{{'lang_expiry_date' | language }}</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="AppendList" *ngIf="id_count > 0 ">
                                                                        <tr *ngFor="let record of id_data.records; let i = index let odd=odd; let even=even"
                                                                            [ngClass]="{ odd: odd, even: even }">
                                                                            <td>{{i+1}}</td>
                                                                            <td>{{record.employee_number}}</td>
                                                                            <td class="blue" routerLink="/human_resources/view/{{record.id}}/view/{{record.branch_id}}"> {{record.employee_id_number}}</td>
                                                                            <td class="blue" routerLink="/human_resources/view/{{record.id}}/view/{{record.branch_id}}"> {{record.employee_name}}</td>
                                                                            <td *ngIf="record.days >= 0">{{record.days}} {{'lang_days_remaining' | language }} || {{record.date_of_expiry}}</td>
                                                                            <td *ngIf="record.days < 0" class="red"> {{record.days}} {{'lang_days_expired' | language }} || {{record.date_of_expiry}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody class="AppendList" *ngIf="id_count == '0' ">
                                                                        <tr class="odd">
                                                                            <td colspan="5">{{id_error_msg}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="id_count > 0" class="card-footer padb0">
                                                <div class="row">
                                                    <div class="col-lg-3 col-sm-6 col-6 mb-10">
                                                        {{'lang_total' | language }} : {{id_data.page.total_records}}
                                                    </div>
                                                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                                                        <mat-radio-group>
                                                            <mat-radio-button value="10" checked (click)="id_notify_page(1,10)"> 10 </mat-radio-button>
                                                            <mat-radio-button value="20" (click)="id_notify_page(1,20)"> 20</mat-radio-button>
                                                            <mat-radio-button value="50" (click)="id_notify_page(1,50)"> 50</mat-radio-button>
                                                            <mat-radio-button value="100" (click)="id_notify_page(1,100)"> 100 </mat-radio-button> 
                                                            <mat-radio-button value="500" (click)="id_notify_page(1,500)"> 500 </mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-6 col-12 mb-10">
                                                        {{'lang_goto' | language }} :
                                                        <select class="customselect" (change)="id_notify_length($event.target.value)">
                                                            <option *ngFor="let k of ds.getrange(id_data.page.total_pages)" value="{{k}}">{{k}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step>
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-briefcase fa-icon"></i>
                                                <span class="badge bg-red-500" *ngIf="interview_count > 0 ">{{interview_count}}</span>
                                            </div>
                                        </div>
                                        <div>{{'lang_interviews' | language }}</div>
                                    </ng-template>
                                    <div class="card">
                                        <div class="width100">
                                            <div class="card-header">{{'lang_interviews' | language }}
                                            </div>
                                            <div class="card-body pad0">
                                                <div class="mbrl15">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="table-responsive customResponsive">
                                                                <table border="1" class="table CustomTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <td>#</td>
                                                                            <td>{{'lang_human_resources_id_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_employee_name' | language }}</td>
                                                                            <td>{{'lang_expiry_date' | language }}</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="AppendList" *ngIf="interview_count > 0 ">
                                                                        <tr *ngFor="let record of interview_data.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }">
                                                                            <td>{{i+1}}</td>
                                                                            <td class="blue" routerLink="/human_resources/applicationview/{{record.id}}/view/"> {{record.employee_id_number}}</td>
                                                                            <td class="blue" routerLink="/human_resources/applicationview/{{record.id}}/view/"> {{record.employee_name}}</td>
                                                                            <td *ngIf="record.days >= 0">{{record.days}} {{'lang_days_remaining' | language }} || {{record.date_of_expiry}}</td>
                                                                            <td *ngIf="record.days < 0" class="red"> {{record.days}} {{'lang_days_expired' | language }} || {{record.date_of_expiry}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody class="AppendList" *ngIf="interview_count == '0' ">
                                                                        <tr class="odd">
                                                                            <td colspan="4">{{interview_error_msg}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="interview_count > 0" class="card-footer padb0">
                                                <div class="row">
                                                    <div class="col-lg-3 col-sm-6 col-6 mb-10"> {{'lang_total' | language }} : {{interview_data.page.total_records}}</div>
                                                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                                                        <mat-radio-group>
                                                            <mat-radio-button value="10" checked (click)="interview_notify_page(1,10)"> 10 </mat-radio-button>
                                                            <mat-radio-button value="20" (click)="interview_notify_page(1,20)"> 20 </mat-radio-button>
                                                            <mat-radio-button value="50" (click)="interview_notify_page(1,50)"> 50 </mat-radio-button>
                                                            <mat-radio-button value="100" (click)="interview_notify_page(1,100)"> 100 </mat-radio-button>
                                                            <mat-radio-button value="500" (click)="interview_notify_page(1,500)"> 500 </mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-6 col-12 mb-10">
                                                        {{'lang_goto' | language }} :
                                                        <select class="customselect" (change)="interview_notify_length($event.target.value)">
                                                            <option *ngFor="let k of ds.getrange(interview_data.page.total_pages)" value="{{k}}"> {{k}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step>
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-book fa-icon"></i>
                                                <span class="badge bg-red-500" *ngIf="passport_count > 0 ">{{passport_count}}</span>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_passport_details' | language }}</div>
                                    </ng-template>
                                    <div class="card">
                                        <div class="width100">
                                            <div class="card-header">
                                                {{'lang_human_resources_passport_details' | language }}
                                            </div>
                                            <div class="card-body pad0">
                                                <div class="mbrl15">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="table-responsive customResponsive">
                                                                <table border="1" class="table CustomTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <td>#</td>
                                                                            <td>{{'lang_human_resources_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_id_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_employee_name' | language }}</td>
                                                                            <td>{{'lang_expiry_date' | language }}</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="AppendList" *ngIf="passport_count > 0 ">
                                                                        <tr *ngFor="let record of passport_data.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }">
                                                                            <td>{{i+1}}</td>
                                                                            <td>{{record.employee_number}}</td>
                                                                            <td class="blue" routerLink="/human_resources/view/{{record.id}}/view/{{record.branch_id}}"> {{record.employee_id_number}}</td>
                                                                            <td class="blue" routerLink="/human_resources/view/{{record.id}}/view/{{record.branch_id}}"> {{record.employee_name}}
                                                                            </td>
                                                                            <td *ngIf="record.days >= 0">{{record.days}} {{'lang_days_remaining' | language }} || {{record.date_of_expiry}}</td>
                                                                            <td *ngIf="record.days < 0" class="red"> {{record.days}} {{'lang_days_expired' | language }} || {{record.date_of_expiry}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody class="AppendList" *ngIf="passport_count == '0' ">
                                                                        <tr class="odd">
                                                                            <td colspan="5">{{passport_error_msg}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="passport_count > 0" class="card-footer padb0">
                                                <div class="row">
                                                    <div class="col-lg-3 col-sm-6 col-6 mb-10">
                                                        {{'lang_total' | language }} : {{passport_data.page.total_records}}
                                                    </div>
                                                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                                                        <mat-radio-group>
                                                            <mat-radio-button value="10" checked (click)="passport_notify_page(1,10)"> 10 </mat-radio-button>
                                                            <mat-radio-button value="20" (click)="passport_notify_page(1,20)"> 20 </mat-radio-button>
                                                            <mat-radio-button value="50" (click)="passport_notify_page(1,50)"> 50 </mat-radio-button>
                                                            <mat-radio-button value="100" (click)="passport_notify_page(1,100)"> 100</mat-radio-button>
                                                            <mat-radio-button value="500" (click)="passport_notify_page(1,500)"> 500 </mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-6 col-12 mb-10">
                                                        {{'lang_goto' | language }} :
                                                        <select class="customselect" (change)="passport_notify_length($event.target.value)">
                                                            <option *ngFor="let k of ds.getrange(passport_data.page.total_pages)" value="{{k}}">{{k}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step>
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-hospital-o fa-icon"></i>
                                                <span class="badge bg-red-500" *ngIf="insurance_count > 0 ">{{insurance_count}}</span>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_insurance_details' | language }}</div>
                                    </ng-template>
                                    <div class="card">
                                        <div class="width100">
                                            <div class="card-header">
                                                {{'lang_human_resources_insurance_details' | language }}
                                            </div>
                                            <div class="card-body pad0">
                                                <div class="mbrl15">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="table-responsive customResponsive">
                                                                <table border="1" class="table CustomTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <td>#</td>
                                                                            <td>{{'lang_human_resources_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_id_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_employee_name' | language }}</td>
                                                                            <td>{{'lang_expiry_date' | language }}</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="AppendList" *ngIf="insurance_count > 0 ">
                                                                        <tr *ngFor="let record of insurance_data.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }">
                                                                            <td>{{i+1}}</td>
                                                                            <td>{{record.employee_number}}</td>
                                                                            <td class="blue" routerLink="/human_resources/view/{{record.id}}/view/{{record.branch_id}}">{{record.employee_id_number}}</td>
                                                                            <td class="blue" routerLink="/human_resources/view/{{record.id}}/view/{{record.branch_id}}">{{record.employee_name}}</td>
                                                                            <td *ngIf="record.days >= 0">{{record.days}} {{'lang_days_remaining' | language }} || {{record.date_of_expiry}}</td>
                                                                            <td *ngIf="record.days < 0" class="red"> {{record.days}} {{'lang_days_expired' | language }} || {{record.date_of_expiry}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody class="AppendList" *ngIf="insurance_count == '0' ">
                                                                        <tr class="odd">
                                                                            <td colspan="5">{{insurance_error_msg}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="insurance_count > 0" class="card-footer padb0">
                                                <div class="row">
                                                    <div class="col-lg-3 col-sm-6 col-6 mb-10">
                                                        {{'lang_total' | language }} :
                                                        {{insurance_data.page.total_records}}
                                                    </div>
                                                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                                                        <mat-radio-group>
                                                            <mat-radio-button value="10" checked (click)="insurance_notify_page(1,10)"> 10</mat-radio-button>
                                                            <mat-radio-button value="20"(click)="insurance_notify_page(1,20)"> 20</mat-radio-button>
                                                            <mat-radio-button value="50"(click)="insurance_notify_page(1,50)"> 50</mat-radio-button>
                                                            <mat-radio-button value="100"(click)="insurance_notify_page(1,100)"> 100</mat-radio-button>
                                                            <mat-radio-button value="500" (click)="insurance_notify_page(1,500)"> 500</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-6 col-12 mb-10">
                                                        {{'lang_goto' | language }} :
                                                        <select class="customselect" (change)="insurance_notify_length($event.target.value)">
                                                            <option *ngFor="let k of ds.getrange(insurance_data.page.total_pages)" value="{{k}}"> {{k}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step>
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-id-card-o fa-icon"></i>
                                                <span class="badge bg-red-500" *ngIf="licence_count > 0 ">{{licence_count}}</span>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_licence_details' | language }}</div>
                                    </ng-template>
                                    <div class="card">
                                        <div class="width100">
                                            <div class="card-header">{{'lang_human_resources_licence_details' | language }}</div>
                                            <div class="card-body pad0">
                                                <div class="mbrl15">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="table-responsive customResponsive">
                                                                <table border="1" class="table CustomTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <td>#</td>
                                                                            <td>{{'lang_human_resources_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_id_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_employee_name' | language }}</td>
                                                                            <td>{{'lang_expiry_date' | language }}</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="AppendList" *ngIf="licence_count > 0 ">
                                                                        <tr *ngFor="let record of licence_data.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }">
                                                                            <td>{{i+1}}</td>
                                                                            <td>{{record.employee_number}}</td>
                                                                            <td class="blue" routerLink="/human_resources/view/{{record.id}}/view/{{record.branch_id}}">{{record.employee_id_number}}</td>
                                                                            <td class="blue" routerLink="/human_resources/view/{{record.id}}/view/{{record.branch_id}}">{{record.employee_name}}</td>
                                                                            <td *ngIf="record.days >= 0">{{record.days}} {{'lang_days_remaining' | language }} || {{record.date_of_expiry}}</td>
                                                                            <td *ngIf="record.days < 0" class="red"> {{record.days}} {{'lang_days_expired' | language }} || {{record.date_of_expiry}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody class="AppendList" *ngIf="licence_count == '0' ">
                                                                        <tr class="odd">
                                                                            <td colspan="5">{{licence_error_msg}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="licence_count > 0" class="card-footer padb0">
                                                <div class="row">
                                                    <div class="col-lg-3 col-sm-6 col-6 mb-10">
                                                        {{'lang_total' | language }} : {{licence_data.page.total_records}}
                                                    </div>
                                                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                                                        <mat-radio-group>
                                                            <mat-radio-button value="10" checked (click)="licence_notify_page(1,10)"> 10</mat-radio-button>
                                                            <mat-radio-button value="20" (click)="licence_notify_page(1,20)"> 20 </mat-radio-button>
                                                            <mat-radio-button value="50" (click)="licence_notify_page(1,50)"> 50 </mat-radio-button>
                                                            <mat-radio-button value="100" (click)="licence_notify_page(1,100)"> 100 </mat-radio-button>
                                                            <mat-radio-button value="500" (click)="licence_notify_page(1,500)"> 500</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-6 col-12 mb-10">
                                                        {{'lang_goto' | language }} :
                                                        <select class="customselect" (change)="licence_notify_length($event.target.value)">
                                                            <option *ngFor="let k of ds.getrange(licence_data.page.total_pages)" value="{{k}}">{{k}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-step>
                                <!--<mat-step>
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-handshake-o fa-icon"></i>
                                                <span class="badge bg-red-500" *ngIf="contract_count > 0 ">{{contract_count}}</span>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_contract_details' | language }}</div>
                                    </ng-template>
                                    <div class="card">
                                        <div class="width100">
                                            <div class="card-header">{{'lang_human_resources_contract_details' | language }}
                                            </div>
                                            <div class="card-body pad0">
                                                <div class="mbrl15">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="table-responsive customResponsive">
                                                                <table border="1" class="table CustomTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <td>#</td>
                                                                            <td>{{'lang_human_resources_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_id_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_employee_name' | language }}</td>
                                                                            <td>{{'lang_expiry_date' | language }}</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="AppendList" *ngIf="contract_data.length > 0">
                                                                    </tbody>
                                                                    <tbody class="AppendList" *ngIf="contract_data.length < 1">
                                                                        <tr class="odd">
                                                                            <td colspan="5">{{contract_error_msg}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="contract_data.length > 0" class="card-footer padb0">
                                                <div class="row">
                                                    <div class="col-lg-3 col-sm-6 col-6 mb-10">
                                                        {{'lang_total' | language }} : {{contract_data.page.total_records}}
                                                    </div>
                                                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                                                        <mat-radio-group>
                                                            <mat-radio-button value="10" checked (click)="contract_notify_page(1,10)"> 10
                                                            </mat-radio-button>
                                                            <mat-radio-button value="20" (click)="contract_notify_page(1,20)"> 20 </mat-radio-button>
                                                            <mat-radio-button value="50" (click)="contract_notify_page(1,50)"> 50 </mat-radio-button>
                                                            <mat-radio-button value="100" (click)="contract_notify_page(1,100)"> 100 </mat-radio-button>
                                                            <mat-radio-button value="500" (click)="contract_notify_page(1,500)"> 500 </mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-6 col-12 mb-10">
                                                        {{'lang_goto' | language }} :
                                                        <select class="customselect" (change)="contract_notify_length($event.target.value)">
                                                            <option *ngFor="let k of ds.getrange(contract_data.page.total_pages)" value="{{k}}">
                                                                {{k}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                
                                </mat-step>
                                <mat-step>
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fa fa-plane fa-icon"></i>
                                                <span class="badge bg-red-500" *ngIf="vocation_count > 0 ">{{vocation_count}}</span>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_vocation_details' | language }}</div>
                                    </ng-template>
                                    <div class="card">
                                        <div class="width100">
                                            <div class="card-header">{{'lang_human_resources_vocation_details' | language }}
                                                
                                            </div>
                                            <div class="card-body pad0">
                                                <div class="mbrl15">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="table-responsive customResponsive">
                                                                <table border="1" class="table CustomTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <td>#</td>
                                                                            <td>{{'lang_human_resources_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_id_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_employee_name' | language }}</td>
                                                                            <td>{{'lang_expiry_date' | language }}</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="AppendList" *ngIf="vocation_data.length > 0">
                                                                    </tbody>
                                                                    <tbody class="AppendList" *ngIf="vocation_data.length < 1">
                                                                        <tr class="odd">
                                                                            <td colspan="5">{{vocation_error_msg}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="vocation_data.length > 0" class="card-footer padb0">
                                                <div class="row">
                                                    <div class="col-lg-3 col-sm-6 col-6 mb-10">
                                                        {{'lang_total' | language }} : {{vocation_data.page.total_records}}
                                                    </div>
                                                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                                                        <mat-radio-group>
                                                            <mat-radio-button value="10" checked (click)="vocation_notify_page(1,10)"> 10
                                                            </mat-radio-button>
                                                            <mat-radio-button value="20" (click)="vocation_notify_page(1,20)"> 20 </mat-radio-button>
                                                            <mat-radio-button value="50" (click)="vocation_notify_page(1,50)"> 50 </mat-radio-button>
                                                            <mat-radio-button value="100" (click)="vocation_notify_page(1,100)"> 100 </mat-radio-button>
                                                            <mat-radio-button value="500" (click)="vocation_notify_page(1,500)"> 500 </mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-6 col-12 mb-10">
                                                        {{'lang_goto' | language }} :
                                                        <select class="customselect" (change)="vocation_notify_length($event.target.value)">
                                                            <option *ngFor="let k of ds.getrange(vocation_data.page.total_pages)" value="{{k}}">
                                                                {{k}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step>
                                    <ng-template matStepLabel>
                                        <div class="step">
                                            <div class="st">
                                                <i class="icon-5x fas fa-walking fa-icon"></i>
                                                <span class="badge bg-red-500" *ngIf="period_count > 0 ">{{period_count}}</span>
                                            </div>
                                        </div>
                                        <div>{{'lang_human_resources_propation_period' | language }}</div>
                                    </ng-template>
                                    <div class="card">
                                        <div class="width100">
                                            <div class="card-header">{{'lang_human_resources_propation_period' | language }}
                                                
                                            </div>
                                            <div class="card-body pad0">
                                                <div class="mbrl15">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="table-responsive customResponsive">
                                                                <table border="1" class="table CustomTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <td>#</td>
                                                                            <td>{{'lang_human_resources_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_id_number' | language }}</td>
                                                                            <td>{{'lang_human_resources_employee_name' | language }}</td>
                                                                            <td>{{'lang_expiry_date' | language }}</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="AppendList" *ngIf="period_data.length > 0">
                                                                    </tbody>
                                                                    <tbody class="AppendList" *ngIf="period_data.length < 1">
                                                                        <tr class="odd">
                                                                            <td colspan="5">{{period_error_msg}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="period_data.length > 0" class="card-footer padb0">
                                                <div class="row">
                                                    <div class="col-lg-3 col-sm-6 col-6 mb-10">
                                                        {{'lang_total' | language }} : {{period_data.page.total_records}}
                                                    </div>
                                                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                                                        <mat-radio-group>
                                                            <mat-radio-button value="10" checked (click)="period_notify_page(1,10)"> 10 </mat-radio-button>
                                                            <mat-radio-button value="20" (click)="period_notify_page(1,20)"> 20 </mat-radio-button>
                                                            <mat-radio-button value="50" (click)="period_notify_page(1,50)"> 50 </mat-radio-button>
                                                            <mat-radio-button value="100" (click)="period_notify_page(1,100)"> 100 </mat-radio-button>
                                                            <mat-radio-button value="500" (click)="period_notify_page(1,500)"> 500 </mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-6 col-12 mb-10">
                                                        {{'lang_goto' | language }} :
                                                        <select class="customselect" (change)="period_notify_length($event.target.value)">
                                                            <option *ngFor="let k of ds.getrange(period_data.page.total_pages)" value="{{k}}">
                                                                {{k}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-step>-->
                            </mat-horizontal-stepper>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>