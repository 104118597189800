import { HttpParams } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import {
  locale,
  MbscCalendarEvent,
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  hijriCalendar,
  MbscPopup,
  MbscPopupOptions,
  Notifications,
} from "@mobiscroll/angular";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import moment from "moment";
import { FiltersModelComponent } from "../ticket-list/filters-model/filters-model.component";
import { ViewTaskComponent } from "../view-ticket/view-task/view-task.component";
import { TicketFiltersComponent } from "./ticket-filters/ticket-filters.component";
import { TransactionFiltersComponent } from "./transaction-filters/transaction-filters.component";

@Component({
  selector: "app-tickets-transactions",
  templateUrl: "./tickets-transactions.component.html",
  styleUrls: ["./tickets-transactions.component.scss"],
})
export class TicketsTransactionsComponent implements OnInit {
  transactions = [];
  transactionSearchKey = "";
  admin: any = JSON.parse(this.ls.getItem("is_admin"));
  complete: boolean = false;
  filteradmin = localStorage.getItem("M_T_A") || "0";
  filterAdminToggle = localStorage.getItem("M_T_A") == "1" ? true : false;
  filterstatusToggle =
    localStorage.getItem("M_T_STATUS") == "all_complete" ? true : false;
  searchByForm: any = localStorage.getItem("M_T_F")
    ? localStorage.getItem("M_T_F")
    : null;
  searchByModule: any = localStorage.getItem("M_T_M")
    ? localStorage.getItem("M_T_M")?.split(",")
    : null;
  searchByStatus: any =
    localStorage.getItem("M_T_STATUS") || "all_pending_need_action";
  transactionPageno: any = localStorage.getItem("M_T_P") || 1;
  transactionPagesize: any = localStorage.getItem("M_T_S") || "10";
  transactionCollectionSize: any = 0;
  transactionNoData: any;
  transactionLoading = false;
  tt_tabindex = 0;
  isOnInit = true;
  //tickets
  searchKey = "";
  status_list = [];
  user_list = [];
  priority_list = [];
  signature_list = [];
  module_list = [];
  type_list = [];
  lang_key = localStorage.getItem("lang_key") || "en";
  nodata = this.lang.transform("lang_no_data");
  ticketID = "";
  link_issue = "";
  type_filter = null;
  user_filter = [];
  status_filter = null;
  priority_filter = null;
  signatue_filter = null;
  module_filter = null;
  filteradminTickets = "1";
  range = this.fb.group({
    start: null,
    end: null,
  });
  tickets_page_no = 1;
  tickets_page_size = "10";
  ticket_list = null;
  ticketLoading = true;
  showFilter = false;
  isMob = false;
  //calendar
  hijriCalendar = hijriCalendar;
  localeAll = locale;
  view = "month";
  myTheme = "ios";
  resource_type = "gantt";
  calendarTheme = "ios";
  ticketUsers = [];
  filters_params = {
    ticket_no: null,
    ticket_type: null,
    start_date_task: null,
    end_date_task: null,
    start_date_ticket: null,
    end_date_ticket: null,
    task_no: null,
    important_task: null,
    admin_or_user: "1",
  };
  statusResources = [
    {
      id: 1,
      name: this.lang.transform("lang_new"),
      color: "#e20000",
    },
    {
      id: 2,
      name: this.lang.transform("lang_in_progress"),
      color: "#60e81a",
    },
    {
      id: 3,
      name: this.lang.transform("lang_confirm"),
      color: "#3ba7ff",
    },
    {
      id: 4,
      name: this.lang.transform("lang_done"),
      color: "#e25dd2",
    },
  ];
  membersResources = [];
  ganttResources = [];
  status_events = [];
  member_events = [];
  gantt_events = [];
  allEvents = [];
  myEvents: MbscCalendarEvent[] = [];
  myResources = [...this.statusResources];
  filter_user = [];
  currentView = "timeline";
  @ViewChild("popup", { static: false }) tooltip!: MbscPopup;
  anchor: HTMLElement | undefined;
  currentEvent: any;
  popupOptions: MbscPopupOptions = {
    display: "anchored",
    touchUi: false,
    showOverlay: false,
    contentPadding: false,
    closeOnOverlayClick: true,
    width: 350,
  };
  isTimeline = true;
  connections = [];
  //search vars
  currentDate: any = new Date();
  mySelectedEvent: MbscCalendarEvent[] = [];
  @ViewChild("searchInput", { static: false })
  searchInputElm!: any;
  searchInput: HTMLElement | undefined;
  @ViewChild("popupSearch", { static: false })
  popupSearch!: MbscPopup;
  listEvents: MbscCalendarEvent[] = [];
  timer: any;
  listView: MbscEventcalendarView = {
    agenda: {
      type: "year",
      size: 5,
    },
  };
  constructor(
    private ds: DataService,
    private ls: LocalStorage,
    private lang: LanguagePipe,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private sweetAlert: SweetAlertService,
    private spinner: PreloaderService,
    private notify: Notifications
  ) {}

  ngOnInit(): void {
    this.isOnInit = true;
    moment.locale("en");
    if (window.innerWidth < 600) {
      this.isMob = true;
    } else {
      this.isMob = false;
    }
    this.getCalenderData();
    this.getUsers();
    this.get_form_lists();
    this.getTickets();
  }
  //transactions
  public get_form_lists() {
    this.transactionLoading = true;
    localStorage.setItem("M_T_S_K", this.transactionSearchKey || "");
    localStorage.setItem("M_T_A", this.admin ? this.filteradmin : "0");
    localStorage.setItem("M_T_F", this.searchByForm || "");
    localStorage.setItem(
      "M_T_M",
      this.searchByModule &&
        this.searchByModule &&
        this.searchByModule.length > 0
        ? this.searchByModule
        : ""
    );
    localStorage.setItem("M_T_P", this.transactionPageno);
    localStorage.setItem("M_T_S", this.transactionPagesize);
    localStorage.setItem(
      "M_T_STATUS",
      this.searchByStatus || "all_pending_need_action"
    );
    let param = new FormData();
    param.append("searchKey", this.transactionSearchKey || "");
    param.append("searchAdmin", this.admin ? this.filteradmin : "0");
    param.append("searchByForm", this.searchByForm || "");
    param.append(
      "searchByModule",
      this.searchByModule &&
        this.searchByModule != null &&
        this.searchByModule.length > 0
        ? this.searchByModule.toString()
        : ""
    );
    param.append("searchByStatus", this.searchByStatus || "");
    this.ds
      .getActionByUrl(
        this.ds.formData2string(param),
        "tc/list/" + this.transactionPageno + "/" + this.transactionPagesize
      )
      .subscribe(
        (res) => {
          this.transactionLoading = false;
          if (res.status) {
            this.transactions = res?.list?.records;
            this.transactionCollectionSize = res?.page?.total_records;
            this.isOnInit = false;
          } else {
            this.transactions = [];
            this.transactionCollectionSize = 0;
            this.transactionNoData = res.error;
            if (this.isOnInit) {
              this.tt_tabindex = 1;
            }
          }
        },
        (error) => {
          this.transactionLoading = false;
          this.transactionCollectionSize = 0;
          this.transactions = [];
          this.transactionNoData =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
    this.filterAdminToggle =
      localStorage.getItem("M_T_A") == "1" ? true : false;
    this.filterstatusToggle =
      localStorage.getItem("M_T_STATUS") == "all_complete" ? true : false;
  }
  public loadPageFrm(page) {
    this.transactionPageno = page;
    this.transactionPagesize = this.transactionPagesize;
    this.get_form_lists();
  }
  public loadFrmPagesize(size) {
    this.transactionPageno = 1;
    this.transactionPagesize = size;
    this.get_form_lists();
  }
  deleteTransaction(event, list) {
    event?.stopPropagation();
    let body = new HttpParams();
    body.append("transaction_request_id", list?.transaction_request_id);
    this.sweetAlert
      .confirmMessage("Are you sure?", "Confirm")
      .then((result) => {
        if (result.isConfirmed) {
          let data = new FormData();
          data.append("transaction_request_id", list?.transaction_request_id);
          this.spinner.show();
          if (
            list?.transaction_key == "FORM_HRV1" ||
            list?.transaction_key == "FORM_HRJ1" ||
            list?.transaction_key == "FORM_OVR1" ||
            list?.transaction_key == "FORM_VOL1" ||
            list?.transaction_key == "FORM_BNS1" ||
            list?.transaction_key == "FORM_DET1" ||
            list?.transaction_key == "FORM_C1" ||
            list?.transaction_key == "FORM_C2" ||
            list?.transaction_key == "FORM_PQ1" ||
            list?.transaction_key == "FORM_JF" ||
            list?.transaction_key == "FORM_HRLN1" ||
            list?.transaction_key == "FORM_SAL"
          ) {
            this.ds.post(`form/${list?.transaction_key}/dr`, data).subscribe(
              (res) => {
                this.spinner.hide();
                if (res.status) {
                  this.sweetAlert.successMessage(
                    this.lang.transform("lang_transaction_deleted_successfully")
                  );
                  this.get_form_lists();
                } else {
                  this.sweetAlert.errorMessage(res.error);
                }
              },
              (err) => {
                this.spinner.hide();
              }
            );
          } else {
            this.ds
              .deleteActionById(
                list?.transaction_request_id,
                `form/${list?.transaction_key}/dr`
              )
              .subscribe(
                (res) => {
                  this.spinner.hide();
                  if (res.status) {
                    this.sweetAlert.successMessage(
                      this.lang.transform(
                        "lang_transaction_deleted_successfully"
                      )
                    );
                    this.get_form_lists();
                  } else {
                    this.sweetAlert.errorMessage(res.error);
                  }
                },
                (err) => {
                  this.spinner.hide();
                }
              );
          }
        }
      });
  }

  cancelTransaction(event, item) {
    event?.stopPropagation();
    
    let requestBody = new FormData();
    requestBody.append('transaction_request_id', item?.transaction_request_id);
    this.ds
    .post(
      `form/${item?.transaction_key}/cancel_request`,
      requestBody
    )
    .subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.sweetAlert.successMessage(
            this.lang.transform(
              "lang_transaction_cancelled_successfully"
            )
          );
          this.get_form_lists();
        } else {
          this.sweetAlert.errorMessage(res.error);
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  showTransactionFilters() {
    const dialogRef = this.dialog.open(TransactionFiltersComponent, {
      width: "600px",
      data: {
        lastValues: {
          searchByForm: this.searchByForm,
          filteradmin: this.filteradmin,
          searchByModule: this.searchByModule,
          searchByStatus: this.searchByStatus,
        },
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.searchByForm = res.searchByForm;
        this.filteradmin = res.filteradmin;
        this.searchByModule = res.searchByModule;
        this.searchByStatus = res.searchByStatus;
        this.get_form_lists();
      }
    });
  }
  //tickets
  getTickets() {
    this.ticketLoading = true;
    let formData = new FormData();
    formData.append("ticket_no", this.ticketID || "");
    formData.append("link_issue", this.link_issue || "");
    formData.append("ticket_type", this.type_filter || "");
    formData.append("users", this.user_filter?.toString() || "");
    formData.append("ticket_status", this.status_filter || "");
    formData.append("important_id", this.priority_filter || "");
    formData.append("sig_id", this.signatue_filter || "");
    formData.append("start_date", this.range.get("start").value || "");
    formData.append("end_date", this.range.get("end").value || "");
    formData.append("ref_model", this.module_filter || "");
    formData.append("user_or_admin", this.filteradminTickets || "");
    this.ds
      .post(
        `tasks/get_data_ticket/${this.tickets_page_no}/${this.tickets_page_size}`,
        formData
      )
      .subscribe(
        (res) => {
          this.ticketLoading = false;
          if (res?.status) {
            this.ticket_list = res;
          } else {
            this.ticket_list = null;
            this.tickets_page_no = 1;
            this.tickets_page_size = "10";
          }
        },
        (err) => {
          this.ticketLoading = false;
          this.ticket_list = null;
          this.tickets_page_no = 1;
          this.tickets_page_size = "10";
        }
      );
  }
  load_by_page_no(page) {
    this.tickets_page_no = page;
    this.getTickets();
  }
  load_by_page_size(size) {
    this.tickets_page_size = size;
    this.getTickets();
  }
  showTicketsFilters() {
    const dialogRef = this.dialog.open(TicketFiltersComponent, {
      width: "600px",
      data: {
        lastValues: {
          ticketID: this.ticketID,
          link_issue: this.link_issue,
          type_filter: this.type_filter,
          user_filter: this.user_filter,
          status_filter: this.status_filter,
          priority_filter: this.priority_filter,
          signatue_filter: this.signatue_filter,
          module_filter: this.module_filter,
          filteradmin: this.filteradminTickets,
          start_date: this.range.get("start").value,
          end_date: this.range.get("end").value,
        },
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.ticketID = res?.ticketID;
        this.link_issue = res?.link_issue;
        this.type_filter = res?.type_filter;
        this.user_filter = res?.user_filter;
        this.status_filter = res?.status_filter;
        this.priority_filter = res?.priority_filter;
        this.signatue_filter = res?.signatue_filter;
        this.module_filter = res?.module_filter;
        this.filteradminTickets = res?.filteradmin;
        this.range.get("start").setValue(res?.start_date);
        this.range.get("end").setValue(res?.end_date);
        this.getTickets();
      }
    });
  }
  deleteTicket(event, ticketId) {
    event?.stopPropagation();
    this.sweetAlert.confirmMessage("Are You Sure?!", "Yes").then((res) => {
      if (res?.isConfirmed) {
        this.spinner.show();
        let formData = new FormData();
        formData.append("ticket_id", ticketId);
        this.ds.post("tasks/delete_ticket", formData).subscribe(
          (res) => {
            this.spinner.hide();
            if (res?.status) {
              this.sweetAlert.successToast("Ticket Deleted Successfully", 1000);
              this.getTickets();
            }
          },
          (err) => {
            this.spinner.hide();
          }
        );
      }
    });
  }
  //calendar
  refDate = moment().format("YYYY-MM-DD");
  getCalenderData(filters?) {
    let formData = new FormData();
    moment.locale("en");
    formData.append("ticket_no", filters?.ticket_no || "");
    formData.append("ticket_type", filters?.ticket_type || "");
    formData.append("start_date_task", filters?.start_date_task || "");
    formData.append("end_date_task", filters?.end_date_task || "");
    formData.append("start_date_ticket", filters?.start_date_ticket || "");
    formData.append("end_date_ticket", filters?.end_date_ticket || "");
    formData.append("task_no", filters?.task_no || "");
    formData.append("important_task", filters?.important_task || "");
    formData.append("admin_or_user", filters?.admin_or_user || "1");
    this.member_events = [];
    this.status_events = [];
    this.allEvents = [];
    this.connections = [];
    this.ds.post("tasks/get_tasks_calendar_all", formData).subscribe((res) => {
      if (res?.status) {
        this.allEvents = res?.data;
        if (this.currentView == "timeline") {
          this.connections = res?.connections;
          this.calCalendarView.timeline.startDay =
            this.allEvents.length > 0
              ? this.allEvents[0].start_date_task
              : this.refDate;
        }
        this.status_events = this.allEvents.map((el) => {
          return {
            start: el?.start_date_task,
            end: el?.end_nearly_task,
            title: el?.title,
            resource: +el?.task_status,
            task_id: el?.task_id,
            id: el?.task_id,
            is_can_view: el?.is_can_view,
            ticket_id: el?.ticket_id,
            task_no: el?.task_no,
            ticket_title: el?.ticket_titel,
            start_date_ticket: el?.start_date_ticket,
            start_date_task: el?.start_date_task,
            end_date_task: el?.end_date_task || el?.end_nearly_task,
            task_progress: el?.task_progress,
            end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
            color:
              el?.task_status == "1"
                ? "#e20000"
                : el?.task_status == "2"
                ? "#60e81a"
                : el?.task_status == "3"
                ? "#3ba7ff"
                : "#e25dd2",
            bgColor:
              el?.task_status == "1"
                ? "#e2000087"
                : el?.task_status == "2"
                ? "#3ca7069e"
                : el?.task_status == "3"
                ? "#1b88e18c"
                : "#e25dd2b8",
          };
        });
        this.member_events = this.allEvents.map((el) => {
          return {
            start: el?.start_date_task,
            end: el?.end_nearly_task,
            title: el?.title,
            resource: el?.emps?.split("||"),
            task_id: el?.task_id,
            id: el?.task_id,
            is_can_view: el?.is_can_view,
            ticket_id: el?.ticket_id,
            task_no: el?.task_no,
            ticket_title: el?.ticket_titel,
            start_date_ticket: el?.start_date_ticket,
            start_date_task: el?.start_date_task,
            end_date_task: el?.end_date_task || el?.end_nearly_task,
            task_progress: el?.task_progress,
            end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
            color:
              el?.task_status == "1"
                ? "#e20000"
                : el?.task_status == "2"
                ? "#60e81a"
                : el?.task_status == "3"
                ? "#3ba7ff"
                : "#e25dd2",
            bgColor:
              el?.task_status == "1"
                ? "#e2000087"
                : el?.task_status == "2"
                ? "#3ca7069e"
                : el?.task_status == "3"
                ? "#1b88e18c"
                : "#e25dd2b8",
          };
        });
        this.gantt_events = this.allEvents.map((el) => {
          return {
            start: el?.start_date_task,
            end: el?.end_nearly_task,
            title: `${el?.title} ${this.lang.transform("lang_progress")}: (${
              el?.task_progress
            })%`,
            resource: el?.task_id,
            task_id: el?.task_id,
            id: el?.task_id,
            is_can_view: el?.is_can_view,
            ticket_id: el?.ticket_id,
            task_no: el?.task_no,
            ticket_title: el?.ticket_titel,
            start_date_ticket: el?.start_date_ticket,
            start_date_task: el?.start_date_task,
            end_date_task: el?.end_date_task || el?.end_nearly_task,
            task_progress: el?.task_progress,
            end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
            color:
              el?.task_status == "1"
                ? "#e20000"
                : el?.task_status == "2"
                ? "#60e81a"
                : el?.task_status == "3"
                ? "#3ba7ff"
                : "#e25dd2",
            bgColor:
              el?.task_status == "1"
                ? "#e2000087"
                : el?.task_status == "2"
                ? "#3ca7069e"
                : el?.task_status == "3"
                ? "#1b88e18c"
                : "#e25dd2b8",
          };
        });
        this.ganttResources = this.customizeTaskData(this.allEvents).map(
          (el, index) => {
            return {
              id: el?.id,
              name: el?.name,
              collapsed: index == 0 ? false : true,
              children: el?.tasks?.map((task) => {
                return {
                  id: task?.task_id,
                  name: task?.title,
                  start: task?.start_date_task,
                  end: task?.end_nearly_task,
                  task_id: task?.task_id,
                  is_can_view: task?.is_can_view,
                  ticket_id: task?.ticket_id,
                  task_no: task?.task_no,
                  ticket_title: task?.ticket_titel,
                  start_date_ticket: task?.start_date_ticket,
                  start_date_task: task?.start_date_task,
                  end_date_task: task?.end_date_task || task?.end_nearly_task,
                  task_progress: task?.task_progress,
                  end_date_ticket:
                    task?.end_date_ticket || task?.end_date_nearly_ticket,
                  color:
                    task?.task_status == "1"
                      ? "#e20000"
                      : task?.task_status == "2"
                      ? "#60e81a"
                      : task?.task_status == "3"
                      ? "#3ba7ff"
                      : "#e25dd2",
                  bgColor:
                    task?.task_status == "1"
                      ? "#e2000087"
                      : task?.task_status == "2"
                      ? "#3ca7069e"
                      : task?.task_status == "3"
                      ? "#1b88e18c"
                      : "#e25dd2b8",
                };
              }),
            };
          }
        );

        if (this.resource_type == "status") {
          this.myEvents = [];
          this.myEvents = [...this.status_events];
        } else if (this.resource_type == "members") {
          this.myEvents = [];
          this.myEvents = [...this.member_events];
        } else if (this.resource_type == "gantt") {
          this.myEvents = [];
          this.myEvents = [...this.gantt_events];
          this.myResources = [];
          this.myResources = [...this.ganttResources];
        }
      } else {
        this.member_events = [];
        this.status_events = [];
        this.gantt_events = [];
        this.myEvents = [];
        this.connections = [];
        this.sweetAlert.errorToast(res?.error, 2000);
      }
    });
  }
  getUsers() {
    this.ds.post("tasks/get_emp_all_task_calender", {}).subscribe((res) => {
      if (res?.status) {
        this.ticketUsers = res?.data;
        this.membersResources = res?.data?.map((el) => {
          return {
            id: el?.emp_id,
            name: el?.emp_name,
            color: "#e25dd2",
          };
        });
      }
    });
  }

  changeView(): void {
    setTimeout(() => {
      if (this.view == "day" && this.currentView == "timeline") {
        this.calCalendarView = {
          timeline: {
            type: "day",
            eventList: true,
            startDay: 1,
            endDay: 5,
          },
        };
      } else if (this.view == "week" && this.currentView == "timeline") {
        this.calCalendarView = {
          timeline: {
            type: "week",
            eventList: true,
          },
        };
      } else if (this.view == "month" && this.currentView == "timeline") {
        this.calCalendarView = {
          timeline: {
            type: "month",
            eventList: true,
          },
        };
      } else if (this.view == "day" && this.currentView == "calendar") {
        this.calCalendarView = {
          schedule: {
            type: "day",
          },
        };
      } else if (this.view == "week" && this.currentView == "calendar") {
        this.calCalendarView = {
          calendar: {
            type: "week",
          },
        };
      } else if (this.view == "month" && this.currentView == "calendar") {
        this.calCalendarView = {
          calendar: {
            type: "month",
          },
        };
      } else if (this.view == "year") {
        this.calCalendarView = {
          calendar: {
            type: "year",
          },
        };
      }
    });
  }

  changeType(): void {
    if (this.resource_type == "status") {
      this.myResources = [];
      this.myResources = [...this.statusResources];
      this.myEvents = [];
      this.myEvents = [...this.status_events];
    } else if (this.resource_type == "members") {
      this.myResources = [];
      this.myResources = [...this.membersResources];
      this.myEvents = [];
      this.myEvents = [...this.member_events];
    } else if (this.resource_type == "gantt") {
      this.myResources = [];
      this.myResources = [...this.ganttResources];
      this.myEvents = [];
      this.myEvents = [...this.gantt_events];
    }
  }

  changeUsers() {
    if (this.filter_user.length) {
      const memberSelectedResources = this.membersResources.filter((el) =>
        this.filter_user?.includes(el?.id)
      );
      const memberSelectedEvents = this.allEvents.filter((el) => {
        let returnVal = false;
        if (el?.emps) {
          for (let item of el?.emps?.split("||")) {
            if (this.filter_user?.includes(item)) {
              returnVal = true;
              break;
            } else {
              returnVal = false;
            }
          }
        }
        return returnVal;
      });
      this.member_events = memberSelectedEvents.map((el) => {
        return {
          start: el?.start_date_task,
          end: el?.end_nearly_task,
          title: el?.title,
          resource: el?.emps?.split("||"),
          task_id: el?.task_id,
          is_can_view: el?.is_can_view,
          ticket_id: el?.ticket_id,
          task_no: el?.task_no,
          ticket_title: el?.ticket_titel,
          start_date_ticket: el?.start_date_ticket,
          start_date_task: el?.start_date_task,
          end_date_task: el?.end_date_task || el?.end_nearly_task,
          task_progress: el?.task_progress,
          end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
          color:
            el?.task_status == "1"
              ? "#e20000"
              : el?.task_status == "2"
              ? "#60e81a"
              : el?.task_status == "3"
              ? "#3ba7ff"
              : "#e25dd2",
          bgColor:
            el?.task_status == "1"
              ? "#e2000087"
              : el?.task_status == "2"
              ? "#3ca7069e"
              : el?.task_status == "3"
              ? "#1b88e18c"
              : "#e25dd2b8",
        };
      });
      this.myResources = [...memberSelectedResources];
      this.myEvents = [...this.member_events];
    } else {
      this.myResources = [...this.membersResources];
      this.member_events = this.allEvents.map((el) => {
        return {
          start: el?.start_date_task,
          end: el?.end_nearly_task,
          title: el?.title,
          resource: el?.emps?.split("||"),
          task_id: el?.task_id,
          is_can_view: el?.is_can_view,
          ticket_id: el?.ticket_id,
          task_no: el?.task_no,
          ticket_title: el?.ticket_titel,
          start_date_ticket: el?.start_date_ticket,
          start_date_task: el?.start_date_task,
          end_date_task: el?.end_date_task || el?.end_nearly_task,
          task_progress: el?.task_progress,
          end_date_ticket: el?.end_date_ticket || el?.end_date_nearly_ticket,
          color:
            el?.task_status == "1"
              ? "#e20000"
              : el?.task_status == "2"
              ? "#60e81a"
              : el?.task_status == "3"
              ? "#3ba7ff"
              : "#e25dd2",
          bgColor:
            el?.task_status == "1"
              ? "#e2000087"
              : el?.task_status == "2"
              ? "#3ca7069e"
              : el?.task_status == "3"
              ? "#1b88e18c"
              : "#e25dd2b8",
        };
      });
      this.myEvents = [...this.member_events];
    }
  }

  showFilters() {
    const dialogRef = this.dialog.open(FiltersModelComponent, {
      width: "700px",
      data: {
        metaData: {
          priority_list: this.priority_list,
          type_list: this.type_list,
        },
        lastValues: this.filters_params,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filters_params = result;
        this.getCalenderData(this.filters_params);
      }
    });
  }
  //calendar
  calendarOptions: MbscEventcalendarOptions = {
    dragToCreate: false,
    dragToMove: true,
    dragToResize: true,
    dragTimeStep: 60,
    height: "700px",
    view: {
      timeline: {
        type: "month",
        eventList: true,
      },
    },
    onEventClick: (event, inst) => {
      if (event.event.is_can_view) {
        const dialogRef = this.dialog.open(ViewTaskComponent, {
          data: {
            ticketID: this.ticketID,
            task: {
              task_id: event.event.task_id,
              ticket_id: event?.event?.ticket_id,
            },
          },
          width: "970px",
          height: "800px",
          closeOnNavigation: true,
        });
      } else {
        this.sweetAlert.errorToast(
          this.lang.transform("lang_access_denied"),
          2000
        );
      }
    },
    onEventHoverIn: (args, inst) => {
      const event: any = args.event;
      this.currentEvent = event;
      this.anchor = args.domEvent.target;
      this.tooltip.open();
    },
    onEventDragStart: (args) => {
      this.tooltip.close();
    },
    onEventUpdated: (args) => {
      setTimeout(() => {
        this.notify.confirm({
          title: "Are you sure you want to proceed?",
          message: "If you press yes, you will update the task dates.",
          okText: "Yes",
          cancelText: "No",
          callback: (res) => {
            if (res) {
              this.changeTaskDates(args?.event, args);
            } else {
              const index = this.myEvents.findIndex(
                (x) => x.id === args.event.id
              );
              const newEventList = [...this.myEvents];
              newEventList.splice(index, 1, args.oldEvent);
              this.myEvents = newEventList;
            }
          },
        });
      });
    },
  };

  calCalendarView: MbscEventcalendarView = {
    timeline: {
      type: "month",
      eventList: true,
    },
  };

  changeCalendarTimeline(event) {
    if (event?.checked) {
      this.currentView = "timeline";
      if (this.view == "day") {
        this.calCalendarView = {
          timeline: {
            type: "day",
            eventList: true,
          },
        };
      } else if (this.view == "week") {
        this.calCalendarView = {
          timeline: {
            type: "week",
            eventList: true,
          },
        };
      } else if (this.view == "month") {
        this.calCalendarView = {
          timeline: {
            type: "month",
            eventList: true,
          },
        };
      }
    } else {
      this.currentView = "calendar";
      if (this.view == "day") {
        this.calCalendarView = {
          schedule: {
            type: "day",
          },
        };
      } else if (this.view == "week") {
        this.calCalendarView = {
          calendar: {
            type: "week",
          },
        };
      } else if (this.view == "month") {
        this.calCalendarView = {
          calendar: {
            type: "month",
          },
        };
      }
    }
  }
  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 44) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  onFilterAdmin(event) {
    if (event?.checked) {
      this.filters_params.admin_or_user = "2";
    } else {
      this.filters_params.admin_or_user = "1";
    }
    this.getCalenderData(this.filters_params);
  }
  mouseLeave(): void {
    this.tooltip.close();
  }

  onFilterAdminTransactions(event) {
    if (event?.checked) {
      this.filteradmin = "1";
      if (this.searchByStatus == "all_pending_need_action") {
        this.searchByStatus = "all_pending";
      }
      this.filterAdminToggle = true;
    } else {
      this.filteradmin = "0";
      this.filterAdminToggle = false;
    }
    this.get_form_lists();
  }
  onFilterstatusTransactions(event) {
    if (event?.checked) {
      this.searchByStatus = "all_complete";
      this.filterstatusToggle = true;
    } else {
      this.searchByStatus = "all_pending";
      this.filterstatusToggle = false;
    }
    this.get_form_lists();
  }
  onFilterAdminTickets(event) {
    if (event?.checked) {
      this.filteradminTickets = "1";
    } else {
      this.filteradminTickets = "0";
    }
    this.getTickets();
  }

  //change task dates
  changeTaskDates(event, args?) {
    let start_data_formatted: any, end_data_formatted: any;
    if (event) {
      start_data_formatted = moment(event?.start).format("YYYY/MM/DD HH:mm:ss");
      end_data_formatted = moment(event?.end).format("YYYY/MM/DD HH:mm:ss");
    }
    if (start_data_formatted && end_data_formatted) {
      const difference_In_Time =
        event?.end?.getTime() - event?.start?.getTime();
      const difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      let formData = new FormData();
      formData.append("task_id", event?.task_id || "");
      formData.append(
        "task_time",
        Math.ceil(difference_In_Days) + 1 + "" || ""
      );
      formData.append("start_date", start_data_formatted || "");
      formData.append("end_date", end_data_formatted || "");
      this.spinner.show();
      this.ds.post("tasks/update_start_end_task", formData).subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.sweetAlert.successToast(
              this.lang.transform("lang_success"),
              2000
            );
            this.notify.toast({
              message: "Event updated",
            });
          } else {
            this.sweetAlert.errorToast(res?.error, 4000);
            const index = this.myEvents.findIndex(
              (x) => x.id === args.event.id
            );
            const newEventList = [...this.myEvents];
            newEventList.splice(index, 1, args.oldEvent);
            this.myEvents = newEventList;
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            4000
          );
          const index = this.myEvents.findIndex((x) => x.id === args.event.id);
          const newEventList = [...this.myEvents];
          newEventList.splice(index, 1, args.oldEvent);
          this.myEvents = newEventList;
        }
      );
    }
  }

  customizeTaskData(data) {
    let o = {};
    let result = data.reduce((r, el) => {
      let e = el.ticket_id.slice(0, 2);
      if (!o[e]) {
        o[e] = {
          id: el.ticket_id,
          name: el.ticket_titel,
          tasks: [],
        };
        r.push(o[e]);
      }
      o[e].tasks.push(el);
      return r;
    }, []);
    return result;
  }

  initPopup(): void {
    setTimeout(() => {
      this.searchInput = this.searchInputElm.nativeElement;
    });
  }

  onSearch(ev: any): void {
    const text = ev.target.value;
    clearTimeout(this.timer);
    this.timer = null;
    if (!this.timer) {
      this.timer = setTimeout(() => {
        if (text.length > 0) {
          if (this.resource_type == "status") {
            const filteredEvents = this.status_events.filter((el) =>
              el?.title?.includes(text)
            );
            this.listEvents = filteredEvents;
          } else if (this.resource_type == "members") {
            const filteredEvents = this.member_events.filter((el) =>
              el?.title?.includes(text)
            );
            this.listEvents = filteredEvents;
          } else if (this.resource_type == "gantt") {
            const filteredEvents = this.gantt_events.filter((el) =>
              el?.title?.includes(text)
            );
            this.listEvents = filteredEvents;
          }
          this.popupSearch.open();
        } else {
          this.popupSearch.close();
        }
      }, 200);
    }
  }
  eventClick(args: any): void {
    this.currentDate = args.event.start;
    this.mySelectedEvent = [args.event];
    this.popupSearch.close();
  }
}
