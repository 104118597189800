<div class="p-2 checklist-details">
	<div class="row">
		<div class="col-12">
			<div class="card-title-view">
				<h4>{{ "lang_checklist_item" | language }}</h4>
				<i
					class="fa fa-times mnabricon"
					(click)="hideView.emit(true)"
					matTooltip="{{ 'lang_close' | language }}"
				></i>
			</div>
			<hr />
		</div>
	</div>
	<form [formGroup]="pointForm">
		<div class="row">
			<div class="col-12 col-sm-8">
				<div class="title">
					<mat-label class="item-label">{{
						"lang_title" | language
					}}</mat-label>
					<input
						type="text"
						placeholder="{{ 'lang_title' | language }}"
						formControlName="title"
					/>
				</div>
			</div>
			<div class="col-12 col-sm-4">
				<div class="point-progress">
					<mat-label class="item-label">{{
						"lang_progress" | language
					}}</mat-label>
					<input
						type="number"
						min="0"
						max="100"
						placeholder="{{ 'lang_progress' | language }}"
						formControlName="prg_done"
					/>
				</div>
			</div>
			<div class="col-12 mb-2">
				<label class="item-label">{{ "lang_description" | language }}</label>
				<ckeditor
					#editor
					[config]="config"
					formControlName="more_details"
					[ngClass]="{ ' contentrtl': lang_key == 'ar' }"
				></ckeditor>
			</div>
			<div class="col-12 mb-5">
				<label class="item-label">{{ "lang_date" | language }}</label>
				<mbsc-datepicker
					[controls]="['calendar', 'time']"
					select="range"
					[touchUi]="false"
					dateFormat="YYYY/MM/DD HH:mm:ss"
					[(ngModel)]="task_dates"
					[ngModelOptions]="{ standalone: true }"
					[rtl]="lang_key == 'ar' ? true : false"
					display="inline"
				>
				</mbsc-datepicker>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label class="d-flex align-items-center justify-content-between">
					<span class="item-label">
						{{ "lang_members" | language }}
					</span>
					<span (click)="onAddUpdateGroupUser()" style="cursor: pointer">
						({{ "lang_user_group" | language }}
						<i
							class="fa fa-users mnabricon"
							style="font-size: 14px; margin: 0 5px"
						></i
						>)</span
					>
				</label>
				<ng-select
					[items]="search_point_users"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					[searchFn]="ds.customSearchFn"
					formControlName="users"
					placeholder="{{ 'lang_members' | language }}"
					(search)="search_users($event)"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6">
				<div class="point-progress">
					<label for="hour_no" class="item-label">{{
						"lang_hour_no" | language
					}}</label>
					<input
						type="number"
						placeholder="{{ 'lang_hour_no' | language }}"
						formControlName="hour_no"
						id="hour_no"
						name="hour_no"
					/>
				</div>
			</div>
			<div class="col-12 TableParts">
				<p class="mb-10 item-label">
					{{ "lang_attachments" | language }}
					<span class="red" *ngIf="other_attachments?.length">*</span>
				</p>
				<div class="table-responsive customResponsive quotationitemtable">
					<table
						border="1"
						class="table CustomTable"
						style="width: 99% !important"
						formArrayName="attachments"
					>
						<thead>
							<tr>
								<td width="2%" style="padding: 5px; word-break: break-all">
									#
								</td>
								<td width="2%" style="padding: 5px; word-break: break-all">
									<mat-icon
										class="mnabricon"
										matTooltip="{{ 'lang_add' | language }}"
										(click)="addfiles()"
										>add_box</mat-icon
									>
								</td>
								<td width="48%" style="padding: 5px; word-break: break-all">
									{{ "lang_title" | language }}
								</td>
								<td width="48%" style="padding: 5px; word-break: break-all">
									{{ "lang_file" | language }}
								</td>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let file of filesFormGroup.controls;
									let i = index;
									let odd = odd;
									let even = even
								"
								[formGroupName]="i"
							>
								<td
									class="verticalalignmid"
									style="padding: 5px; word-break: break-all"
								>
									{{ i + 1 }}
								</td>
								<td class="verticalalignmid">
									<mat-icon
										*ngIf="
											!other_attachments.controls[i].get('delete_icon').value
										"
										(click)="removefiles(i)"
										class="mnabricon red"
										matTooltip="{{ 'lang_delete' | language }}"
										>delete_forever</mat-icon
									>
								</td>
								<td
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									<input
										type="text"
										formControlName="attach_title"
										placeholder="{{ 'lang_description' | language }}"
										autocomplete="off"
										class="form-control almnabrformcontrol shadow-none"
									/>
								</td>
								<td
									class="verticalalignmid"
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									<input
										type="text"
										formControlName="file"
										placeholder="{{ 'lang_file' | language }}"
										autocomplete="off"
										class="form-control almnabrformcontrol shadow-none"
										[class.border-danger]="
											other_attachments.controls[i].get('file').errors?.urlWrong
										"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="row pt-2">
			<div class="col-0 col-sm-9"></div>
			<div class="col-12 col-sm-3">
				<button
					mat-button
					class="albutton mnabrbutton mat-accent"
					(click)="onSubmit()"
				>
					{{ "lang_save" | language }}
				</button>
			</div>
		</div>
	</form>
</div>
