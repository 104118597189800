import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-tcreate',
  templateUrl: './tcreate.component.html',
  styleUrls: ['./tcreate.component.scss']
})
export class TcreateComponent implements OnInit {
  form_key = this.route.snapshot.paramMap.get('form_key');
  form_request_id = this.route.snapshot.paramMap.get('form_request_id');
  constructor(public route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.form_key = param.form_key;
      this.form_request_id = param.form_request_id;
    });
  }
}
