<form [formGroup]="termsForm" (ngSubmit)="submit()">
	<div class="row p-1">
		<div class="col-12 col-sm-6 mb-4">
			<div class="itemsdata">
				<label>
					{{ "lang_terms_conditions" | language }}: ({{ "lang_FP" | language }})
					<span class="red" *ngIf="termsFP?.length">*</span></label
				>
				<div class="TableParts">
					<div class="table-responsive customResponsive mb-10 tablefields">
						<table border="1" class="table CustomTable text-center">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addTermsFP()"
											>add_box</mat-icon
										>
									</td>
									<td>
										<span matTooltip="{{ 'lang_title_english' | language }}">{{
											"lang_title_english" | language
										}}</span>
										<i class="red" *ngIf="termsFP?.length"> *</i>
									</td>
									<td>
										<span matTooltip="{{ 'lang_title_arabic' | language }}">{{
											"lang_title_arabic" | language
										}}</span>
										<i class="red" *ngIf="termsFP?.length"> *</i>
									</td>
								</tr>
							</thead>
							<tbody formArrayName="termsFP" class="AppendList quotationtable">
								<tr
									*ngFor="
										let item of getTermsGroupFP.controls;
										let itemIndex = index;
										let odd = odd;
										let even = even
									"
									[formGroupName]="itemIndex"
									style="background: #ffffff"
								>
									<td class="verticalalignmid">
										{{ itemIndex + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											(click)="removeTermsFP(itemIndex)"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
											>close</mat-icon
										>
									</td>
									<td>
										<input
											type="text"
											formControlName="rf_name_en"
											placeholder="{{ 'lang_title_english' | language }}"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
										/>
										<span
											*ngIf="
												item.get('rf_required_or_optional')?.value == 'required'
											"
											>{{ item.get("rf_name_en")?.value }}</span
										>
									</td>
									<td>
										<input
											type="text"
											formControlName="rf_name_ar"
											placeholder="{{ 'lang_title_arabic' | language }}"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
										/>
										<span
											*ngIf="
												item.get('rf_required_or_optional')?.value == 'required'
											"
											>{{ item.get("rf_name_ar")?.value }}</span
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6 mb-4">
			<div class="itemsdata">
				<label>
					{{ "lang_terms_conditions" | language }}: ({{ "lang_SP" | language }})
					<span class="red" *ngIf="termsSP?.length">*</span></label
				>
				<div class="TableParts">
					<div class="table-responsive customResponsive mb-10 tablefields">
						<table border="1" class="table CustomTable text-center">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addTermsSP()"
											>add_box</mat-icon
										>
									</td>
									<td>
										<span matTooltip="{{ 'lang_title_english' | language }}">{{
											"lang_title_english" | language
										}}</span>
										<i class="red" *ngIf="termsSP?.length"> *</i>
									</td>
									<td>
										<span matTooltip="{{ 'lang_title_arabic' | language }}">{{
											"lang_title_arabic" | language
										}}</span>
										<i class="red" *ngIf="termsSP?.length"> *</i>
									</td>
								</tr>
							</thead>
							<tbody formArrayName="termsSP" class="AppendList quotationtable">
								<tr
									*ngFor="
										let item of getTermsGroupSP.controls;
										let itemIndex = index;
										let odd = odd;
										let even = even
									"
									[formGroupName]="itemIndex"
									style="background: #ffffff"
								>
									<td class="verticalalignmid">
										{{ itemIndex + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											(click)="removeTermsSP(itemIndex)"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
											>close</mat-icon
										>
									</td>
									<td>
										<input
											type="text"
											formControlName="rf_name_en"
											placeholder="{{ 'lang_title_english' | language }}"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
										/>
										<span
											*ngIf="
												item.get('rf_required_or_optional')?.value == 'required'
											"
											>{{ item.get("rf_name_en")?.value }}</span
										>
									</td>
									<td>
										<input
											type="text"
											formControlName="rf_name_ar"
											placeholder="{{ 'lang_title_arabic' | language }}"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
										/>
										<span
											*ngIf="
												item.get('rf_required_or_optional')?.value == 'required'
											"
											>{{ item.get("rf_name_ar")?.value }}</span
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6 mb-4">
			<div class="itemsdata">
				<label>
					{{ "lang_terms_conditions" | language }}: ({{ "lang_WS" | language }})
					<span class="red" *ngIf="termsWS?.length">*</span></label
				>
				<div class="TableParts">
					<div class="table-responsive customResponsive mb-10 tablefields">
						<table border="1" class="table CustomTable text-center">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addTermsWS()"
											>add_box</mat-icon
										>
									</td>
									<td>
										<span matTooltip="{{ 'lang_title_english' | language }}">{{
											"lang_title_english" | language
										}}</span>
										<i class="red" *ngIf="termsWS?.length"> *</i>
									</td>
									<td>
										<span matTooltip="{{ 'lang_title_arabic' | language }}">{{
											"lang_title_arabic" | language
										}}</span>
										<i class="red" *ngIf="termsWS?.length"> *</i>
									</td>
								</tr>
							</thead>
							<tbody formArrayName="termsWS" class="AppendList quotationtable">
								<tr
									*ngFor="
										let item of getTermsGroupWS.controls;
										let itemIndex = index;
										let odd = odd;
										let even = even
									"
									[formGroupName]="itemIndex"
									style="background: #ffffff"
								>
									<td class="verticalalignmid">
										{{ itemIndex + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											(click)="removeTermsWS(itemIndex)"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
											>close</mat-icon
										>
									</td>
									<td>
										<input
											type="text"
											formControlName="rf_name_en"
											placeholder="{{ 'lang_title_english' | language }}"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
										/>
										<span
											*ngIf="
												item.get('rf_required_or_optional')?.value == 'required'
											"
											>{{ item.get("rf_name_en")?.value }}</span
										>
									</td>
									<td>
										<input
											type="text"
											formControlName="rf_name_ar"
											placeholder="{{ 'lang_title_arabic' | language }}"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
										/>
										<span
											*ngIf="
												item.get('rf_required_or_optional')?.value == 'required'
											"
											>{{ item.get("rf_name_ar")?.value }}</span
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6 mb-4">
			<div class="itemsdata">
				<label>
					{{ "lang_terms_conditions" | language }}: ({{ "lang_FC" | language }})
					<span class="red" *ngIf="termsFC?.length">*</span></label
				>
				<div class="TableParts">
					<div class="table-responsive customResponsive mb-10 tablefields">
						<table border="1" class="table CustomTable text-center">
							<thead>
								<tr>
									<td>#</td>
									<td>
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addTermsFC()"
											>add_box</mat-icon
										>
									</td>
									<td>
										<span matTooltip="{{ 'lang_title_english' | language }}">{{
											"lang_title_english" | language
										}}</span>
										<i class="red" *ngIf="termsFC?.length"> *</i>
									</td>
									<td>
										<span matTooltip="{{ 'lang_title_arabic' | language }}">{{
											"lang_title_arabic" | language
										}}</span>
										<i class="red" *ngIf="termsFC?.length"> *</i>
									</td>
								</tr>
							</thead>
							<tbody formArrayName="termsFC" class="AppendList quotationtable">
								<tr
									*ngFor="
										let item of getTermsGroupFC.controls;
										let itemIndex = index;
										let odd = odd;
										let even = even
									"
									[formGroupName]="itemIndex"
									style="background: #ffffff"
								>
									<td class="verticalalignmid">
										{{ itemIndex + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											(click)="removeTermsFC(itemIndex)"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
											>close</mat-icon
										>
									</td>
									<td>
										<input
											type="text"
											formControlName="rf_name_en"
											placeholder="{{ 'lang_title_english' | language }}"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
										/>
										<span
											*ngIf="
												item.get('rf_required_or_optional')?.value == 'required'
											"
											>{{ item.get("rf_name_en")?.value }}</span
										>
									</td>
									<td>
										<input
											type="text"
											formControlName="rf_name_ar"
											placeholder="{{ 'lang_title_arabic' | language }}"
											*ngIf="
												item.get('rf_required_or_optional')?.value != 'required'
											"
										/>
										<span
											*ngIf="
												item.get('rf_required_or_optional')?.value == 'required'
											"
											>{{ item.get("rf_name_ar")?.value }}</span
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-8 col-xs-12 mb-10"></div>
		<div class="col-sm-4 col-xs-12 mb-10">
			<div>
				<input
					type="submit"
					value="{{ 'lang_submit' | language }}"
					class="albutton mnabrbutton mat-accent"
					mat-button
				/>
			</div>
		</div>
	</div>
</form>

<!--steps history-->
<app-pq1-steps-history
	[allData]="allData"
	[step_no]="3"
></app-pq1-steps-history>
