import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
declare var $: any;
@Component({
  selector: "app-pq1-step2",
  templateUrl: "./pq1-step2.component.html",
  styleUrls: ["./pq1-step2.component.scss"],
})
export class Pq1Step2Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() form_url;
  @Input() editMode;
  @Input() allData: any;
  teamsForm: FormGroup;
  quotation_types = [];
  vat_list = [];
  other_attachments: FormArray;
  othersArray: FormArray;
  nodata: string = this.lang.transform("lang_no_data");
  filename: string = this.lang.transform("lang_select_files");
  user_type = this.ls.getItem("user_type_id") || 0;
  lang_key =
    localStorage.getItem("lang_key") == null
      ? "en"
      : localStorage.getItem("lang_key");
  teams: FormArray;
  fieldenable = false;
  positionList = [];
  presenceList = [];
  nationalityList = [];
  constructor(
    public ls: LocalStorage,
    private sweetAlert: SweetAlertService,
    public ds: DataService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public fb: FormBuilder,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.teamsForm = this.fb.group({
      teams: this.fb.array([], Validators.required),
    });
    this.teams = this.teamsForm.get("teams") as FormArray;
    let teamsOld = this.allData?.form_pq1_quotation_teams_request?.records;
    if (teamsOld?.length) {
      for (let item of teamsOld) {
        this.add_new_items(item);
      }
    }
  }

  public items_group() {
    return this.teamsForm.get("teams") as FormArray;
  }
  get itemsgroup() {
    return this.teamsForm.get("teams") as FormArray;
  }
  public add_new_items(data?) {
    this.teams.push(this.item_records(data));
    this.getNationality({ term: "" }, this.teams?.length - 1);
    this.getPresence({ term: "" }, this.teams?.length - 1);
    this.getPositions({ term: "" }, this.teams?.length - 1);
  }
  public removeItems(index) {
    if (this.teams.length > 1) {
      this.teams.removeAt(index);
    }
  }
  public item_records(data?): FormGroup {
    return this.fb.group({
      position: [data?.position || null, [Validators.required]],
      presence: [data?.presence || null, [Validators.required]],
      nationality: [data?.nationality || null, [Validators.required]],
      team_number: [data?.team_number || "", [Validators.required]],
      experience: [data?.experience || "", [Validators.required]],
      positions_salary: [data?.positions_salary || "", [Validators.required]],
      basic_salary: [data?.basic_salary || "", [Validators.required]],
      salary_allowances: [data?.salary_allowances || "", [Validators.required]],
      actual_salary: [data?.actual_salary || "", [Validators.required]],
      administrative_costs: [
        data?.administrative_costs || "",
        [Validators.required],
      ],
      total_cost_per_person_month: [
        data?.total_cost_per_person_month || "",
        [Validators.required],
      ],
      profit_ratio: [data?.profit_ratio || "", [Validators.required]],
      total_salary_per_person_month: [
        data?.total_salary_per_person_month || "",
        [Validators.required],
      ],
      duration_of_months: [
        data?.duration_of_months || "",
        [Validators.required],
      ],
      visits_per_month: [data?.visits_per_month || "", [Validators.required]],
      total_crew_cost_project: [
        data?.total_crew_cost_project || "",
        [Validators.required],
      ],
      total_bid_of_project: [
        data?.total_bid_of_project || "",
        [Validators.required],
      ],
      positions_task: [data?.positions_task || "", [Validators.required]],
      //lists
      nationalityList: [[]],
      presenceList: [[]],
      positionList: [[]],
    });
  }

  getNationality(event, index) {
    let formData = new FormData();
    formData.append("lang_key", this.lang_key || "");
    formData.append("qss_keyword", event?.term || "");
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "form/FORM_PQ1/nationality"
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.teams.controls[index]
              .get("nationalityList")
              .setValue(res?.records);
          } else {
            this.teams.controls[index].get("nationalityList").setValue([]);
          }
        },
        (err) => {
          this.teams.controls[index].get("nationalityList").setValue([]);
        }
      );
  }
  getPresence(event, index) {
    let formData = new FormData();
    formData.append("lang_key", this.lang_key || "");
    formData.append("qss_keyword", event?.term || "");
    this.ds
      .getActionByUrl(this.ds.formData2string(formData), "form/FORM_PQ1/pres")
      .subscribe(
        (res) => {
          if (res?.status) {
            this.teams.controls[index]
              .get("presenceList")
              .setValue(res?.records);
          } else {
            this.teams.controls[index].get("presenceList").setValue([]);
          }
        },
        (err) => {
          this.teams.controls[index].get("presenceList").setValue([]);
        }
      );
  }
  getPositions(event, index) {
    let formData = new FormData();
    formData.append("lang_key", this.lang_key || "");
    formData.append("settings_short_code", event?.term || "");
    this.ds
      .getActionByUrl(this.ds.formData2string(formData), "form/FORM_PQ1/po")
      .subscribe(
        (res) => {
          if (res?.status) {
            this.teams.controls[index]
              .get("positionList")
              .setValue(res?.records);
          } else {
            this.teams.controls[index].get("positionList").setValue([]);
          }
        },
        (err) => {
          this.teams.controls[index].get("positionList").setValue([]);
        }
      );
  }

  public setDataAction(event, index) {
    if (event == undefined) {
      this.DesetData(index);
    } else {
      this.setData(index);
    }
  }
  // other dynamics end
  public setData(index) {
    let positions_salary: any = 0.0;
    let salary_allowances: any = 0.0;
    let administrative_costs: any = 0.0;
    let positions = this.teams.controls[index].get("position").value;
    if (positions && positions != undefined) {
      positions_salary =
        parseFloat(positions.positions_salary) >= 0
          ? parseFloat(positions.positions_salary)
          : 0.0;
      salary_allowances =
        parseFloat(positions.salary_allowances) >= 0
          ? parseFloat(positions.salary_allowances)
          : 0.0;
      administrative_costs =
        parseFloat(positions.administrative_costs) >= 0
          ? parseFloat(positions.administrative_costs)
          : 0.0;
      this.teams.controls[index]
        .get("positions_salary")
        .setValue(parseFloat(positions_salary).toFixed(2));
      this.teams.controls[index]
        .get("salary_allowances")
        .setValue(parseFloat(salary_allowances).toFixed(2));
      this.teams.controls[index]
        .get("administrative_costs")
        .setValue(parseFloat(administrative_costs).toFixed(2));
      this.teams.controls[index]
        .get("positions_task")
        .setValue(positions.positions_task);
    }
    this.teams.controls[index].get("profit_ratio").setValue("1.25");
    this.teams.controls[index].get("visits_per_month").setValue("");
  }
  public DesetData(index) {
    this.teams.controls[index].get("positions_salary").setValue(0);
    this.teams.controls[index].get("salary_allowances").setValue(0);
    this.teams.controls[index].get("administrative_costs").setValue(0);
    this.teams.controls[index].get("positions_task").setValue(0);
    this.teams.controls[index].get("visits_per_month").setValue("");
    this.teams.controls[index].get("profit_ratio").setValue(0);
  }
  public calculate_quotation(index) {
    let presence = this.teams.controls[index].get("presence").value;
    let positions_salary: any =
      parseFloat(this.teams.controls[index].get("positions_salary").value) >= 0
        ? parseFloat(this.teams.controls[index].get("positions_salary").value)
        : 0;
    let salary_allowances: any =
      parseFloat(this.teams.controls[index].get("salary_allowances").value) >= 0
        ? parseFloat(this.teams.controls[index].get("salary_allowances").value)
        : 0;
    let administrative_costs: any =
      parseFloat(
        this.teams.controls[index].get("administrative_costs").value
      ) >= 0
        ? parseFloat(
            this.teams.controls[index].get("administrative_costs").value
          )
        : 0;
    let team_number: any =
      parseFloat(this.teams.controls[index].get("team_number").value) >= 0
        ? parseFloat(this.teams.controls[index].get("team_number").value)
        : 0;
    let experience: any =
      parseFloat(this.teams.controls[index].get("experience").value) >= 0
        ? parseFloat(this.teams.controls[index].get("experience").value)
        : 0;
    let profit_ratio: any =
      parseFloat(this.teams.controls[index].get("profit_ratio").value) >= 0
        ? parseFloat(this.teams.controls[index].get("profit_ratio").value)
        : 0;
    let basic_salary: any =
      parseFloat(positions_salary) + (parseFloat(experience) / 2) * 1000;
    this.teams.controls[index]
      .get("basic_salary")
      .setValue(parseFloat(basic_salary).toFixed(2));
    let actual_salary: any =
      parseFloat(salary_allowances) * parseFloat(basic_salary);
    this.teams.controls[index]
      .get("actual_salary")
      .setValue(parseFloat(actual_salary).toFixed(2));
    let total_cost_per_person_month: any = 0.0;
    let presence_type =
      presence && presence != undefined ? presence.keyword : "";
    if (presence_type == "resident") {
      this.fieldenable = false;
      $(".rowdisable" + index).addClass("disablebyaction");
      this.teams.controls[index].get("visits_per_month").disable();
      total_cost_per_person_month =
        parseFloat(administrative_costs) *
        parseFloat(actual_salary) *
        parseFloat(team_number);
    } else if (presence_type == "visitor") {
      $(".rowdisable" + index).removeClass("disablebyaction");
      this.teams.controls[index].get("visits_per_month").enable();
      total_cost_per_person_month =
        (parseFloat(administrative_costs) *
          parseFloat(actual_salary) *
          parseFloat(team_number)) /
        30;
    } else {
      $(".rowdisable" + index).addClass("disablebyaction");
      this.teams.controls[index].get("visits_per_month").disable();
    }
    this.teams.controls[index]
      .get("total_cost_per_person_month")
      .setValue(parseFloat(total_cost_per_person_month).toFixed(2));
    let total_salary_per_person_month: any =
      parseFloat(profit_ratio) * parseFloat(total_cost_per_person_month);
    this.teams.controls[index]
      .get("total_salary_per_person_month")
      .setValue(parseFloat(total_salary_per_person_month).toFixed(2));
    let total_crew_cost_project: any = 0;
    let total_bid_of_project: any = 0;
    let duration_of_months: any =
      parseInt(this.teams.controls[index].get("duration_of_months").value) >= 0
        ? parseInt(this.teams.controls[index].get("duration_of_months").value)
        : 0;
    let visits_per_month: any =
      parseInt(this.teams.controls[index].get("visits_per_month").value) >= 0
        ? parseInt(this.teams.controls[index].get("visits_per_month").value)
        : 0;
    if (presence_type == "resident") {
      total_crew_cost_project =
        parseFloat(total_cost_per_person_month) * parseInt(duration_of_months);
      total_bid_of_project =
        parseFloat(total_salary_per_person_month) *
        parseInt(duration_of_months);
    } else if (presence_type == "visitor") {
      total_crew_cost_project =
        parseFloat(total_cost_per_person_month) *
        parseInt(duration_of_months) *
        parseInt(visits_per_month);
      total_bid_of_project =
        parseFloat(total_salary_per_person_month) *
        parseInt(duration_of_months) *
        parseInt(visits_per_month);
    }
    this.teams.controls[index]
      .get("total_crew_cost_project")
      .setValue(parseFloat(total_crew_cost_project).toFixed(2));
    this.teams.controls[index]
      .get("total_bid_of_project")
      .setValue(parseFloat(total_bid_of_project).toFixed(2));
  }

  submit() {
    if (this.teamsForm.valid) {
      let formData = new FormData();
      this.teams.controls.forEach((el, index) => {
        formData.append(
          `teams[${index}][position]`,
          el.get("position")?.value?.value
            ? el.get("position")?.value?.value
            : el.get("position")?.value
            ? el.get("position")?.value
            : ""
        );
        formData.append(
          `teams[${index}][presence]`,
          el.get("presence")?.value?.value
            ? el.get("presence")?.value?.value
            : el.get("presence")?.value
            ? el.get("presence")?.value
            : ""
        );
        formData.append(
          `teams[${index}][nationality]`,
          el.get("nationality").value || ""
        );
        formData.append(
          `teams[${index}][team_number]`,
          el.get("team_number").value || ""
        );
        formData.append(
          `teams[${index}][experience]`,
          el.get("experience").value || ""
        );
        formData.append(
          `teams[${index}][positions_salary]`,
          el.get("positions_salary").value || ""
        );
        formData.append(
          `teams[${index}][basic_salary]`,
          el.get("basic_salary").value || ""
        );
        formData.append(
          `teams[${index}][salary_allowances]`,
          el.get("salary_allowances").value || ""
        );
        formData.append(
          `teams[${index}][actual_salary]`,
          el.get("actual_salary").value || ""
        );
        formData.append(
          `teams[${index}][administrative_costs]`,
          el.get("administrative_costs").value || ""
        );
        formData.append(
          `teams[${index}][total_cost_per_person_month]`,
          el.get("total_cost_per_person_month").value || ""
        );
        formData.append(
          `teams[${index}][profit_ratio]`,
          el.get("profit_ratio").value || ""
        );
        formData.append(
          `teams[${index}][total_salary_per_person_month]`,
          el.get("total_salary_per_person_month").value || ""
        );
        formData.append(
          `teams[${index}][total_crew_cost_project]`,
          el.get("total_crew_cost_project").value || ""
        );
        formData.append(
          `teams[${index}][total_bid_of_project]`,
          el.get("total_bid_of_project").value || ""
        );
        formData.append(
          `teams[${index}][duration_of_months]`,
          el.get("duration_of_months").value || ""
        );
        formData.append(
          `teams[${index}][visits_per_month]`,
          el.get("visits_per_month").value || ""
        );
        formData.append(
          `teams[${index}][positions_task]`,
          el.get("positions_task").value || ""
        );
      });
      this.spinner.show();
      this.ds
        .post(`form/FORM_PQ1/add_teams/${this.form_request_id}/0`, formData)
        .subscribe(
          (res) => {
            if (res.status) {
              this.spinner.hide();
              this.sweetAlert.successToast(
                this.lang.transform("lang_data_sent_successfully"),
                1700
              );
              setTimeout(() => {
                this.refreshComponent.emit(true);
              }, 2000);
            } else {
              this.spinner.hide();
              this.sweetAlert.errorToast(res?.error, 3000);
            }
          },
          (err) => {
            this.spinner.hide();
            this.sweetAlert.errorToast(
              this.lang.transform("lang_internal_server_error"),
              3000
            );
          }
        );
    }
  }
}
