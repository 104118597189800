import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import {
	FormGroup,
	FormArray,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
@Component({
	selector: "app-main-contractors",
	templateUrl: "./main-contractors.component.html",
	styleUrls: ["./main-contractors.component.scss"],
})
export class MainContractorsComponent implements OnInit {
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}
	datastatus: boolean = false;
	modalRef: BsModalRef;
	@Input() supervision_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() project_data;
	@Input() makeAction;
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	mainnodata = "";
	contractordata: any = [];
	contractorsList: any = [];
	form: FormGroup;
	submitkey = "";
	contractortype = "MAIN";
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.form = new FormGroup({
			contractor_id: new FormControl(null, [Validators.required]),
		});
		this.get_contractors();
		this.get_main_contractor_data();
	}
	public get_main_contractor_data() {
		this.spinner.show();
		this.ds
			.getActionByUrl(
				[],
				"FRxZs9spHa/" +
					this.supervision_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					this.datastatus = res.status;
					if (res.status) {
						this.contractordata = res.data;
					} else {
						this.contractordata = [];
						this.mainnodata = res.error;
					}
				},
				(error) => {
					this.datastatus = false;
					this.spinner.hide();
					this.contractordata = [];
					this.mainnodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}
	public emptyform(key) {
		this.submitkey = key;
		this.form.get("contractor_id").setValue(null);
		if (key == "edit" && this.datastatus) {
			this.form
				.get("contractor_id")
				.setValue(this.contractordata.contractor_group_contractor_id);
		}
	}
	public get_contractors(permit = "projects_add") {
		this.contractorsList = [];
		this.form.get("contractor_id").setValue(null);
		this.spinner.show();
		this.ds
			.getActionByUrl(
				[],
				"Zxohs3VKKY/" +
					this.supervision_data.projects_profile_id +
					"/" +
					this.supervision_data.projects_supervision_id +
					"/" +
					permit
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.contractorsList = res.records;
						if (this.submitkey == "add") {
							//this.form.get('contractor_id').setValue(null);
						}
					}
				},
				(error) => {
					this.spinner.hide();
				}
			);
	}
	public submit() {
		this.spinner.show();
		let param = new FormData();
		param.append(
			"projects_profile_id",
			this.supervision_data.projects_profile_id
		);
		param.append(
			"projects_supervision_id",
			this.supervision_data.projects_supervision_id
		);
		param.append("contractor_id", this.form.get("contractor_id").value || "");
		let url = this.submitkey == "add" ? "aAhO155mwS/" : "emUu89hqYr/";
		this.ds.postActionByUrl(param, url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.form.reset();
					this.get_main_contractor_data();
					this.alert.success(res.msg);
					setTimeout(() => {
						this.modalRef.hide();
					}, 2000);
				} else {
					this.alert.error(res.error);
				}
			},
			(error) => {
				this.spinner.hide();
				this.alert.error(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}
	public remove_contractor() {
		const dialogRef = this.dialog.open(ConfirmationDialog, {
			disableClose: true,
			width: "400px",
			data: {
				title: this.lang.transform("lang_confirmation"),
				message: this.lang.transform("lang_are_you_sure"),
			},
		});
		dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.spinner.show();
				this.ds
					.deleteActionByUrl(
						[],
						"6yXbdIcRl7/" +
							this.supervision_data.projects_profile_id +
							"/" +
							this.supervision_data.projects_supervision_id
					)
					.subscribe(
						(data) => {
							this.spinner.hide();
							if (data.status) {
								this.datastatus = false;
								this.ds.dialogf("", data.msg);
							} else {
								this.ds.dialogf("", data.error);
							}
						},
						(error) => {
							this.spinner.hide();
							this.ds.dialogf(
								"",
								error && error.error && error.error.error
									? error.error.error
									: this.lang.transform("lang_internal_server_error")
							);
						}
					);
			}
		});
	}
}
