import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styles: [
  ]
})
export class Step2Component implements OnInit {
  @Input() regForm: FormGroup;
  @Input() action;
  idfilename = this.lang.transform('lang_select_files');
  isFile: boolean = false;
  constructor(public router:Router, public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
  }
  get f() {
    return this.regForm.controls.contactDetails;
  }
  step2Submitted() {
    this.regForm.get('contactDetails').get('primary_mobile').markAsTouched();
    this.regForm.get('contactDetails').get('primary_mobile').updateValueAndValidity();
    this.regForm.get('contactDetails').get('primary_email').markAsTouched();
    this.regForm.get('contactDetails').get('primary_email').updateValueAndValidity();
    this.regForm.get('contactDetails').get('primary_address').markAsTouched();
    this.regForm.get('contactDetails').get('primary_address').updateValueAndValidity();
  }
  uploadIDFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.isFile = true;
      this.regForm.get("contactDetails").get("address_file").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.regForm.get("contactDetails").get("address_file").setValue([]);
      this.idfilename = this.lang.transform('lang_select_files');
    }
  }
  removeFile() {
    this.isFile = false;
    this.regForm.get("contactDetails").get("address_file").setValue([]);
    this.idfilename = this.lang.transform('lang_select_files');
  }
}
