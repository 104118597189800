import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-cp-excel-bulk",
	templateUrl: "./cp-excel-bulk.component.html",
	styleUrls: ["./cp-excel-bulk.component.scss"],
})
export class CpExcelBulkComponent implements OnInit {
	projects_profile_id;
	projects_work_area_id;
	projects_supervision_id;
	lang_key = localStorage.getItem("lang_key") || "en";
	history_files = [];
	page = 1;
	size = 10;
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public sweetAlert: SweetAlertService,
		public dialog: MatDialog,
		public fb: FormBuilder,
		public dialogRef: MatDialogRef<CpExcelBulkComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.projects_profile_id = data?.projects_profile_id;
		this.projects_work_area_id = data?.projects_work_area_id;
		this.projects_supervision_id = data?.projects_supervision_id;
	}

	ngOnInit(): void {}

	download_titles() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("projects_profile_id", this.projects_profile_id);
		formData.append("projects_work_area_id", this.projects_work_area_id);
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"p/cp/export_platform_wlevels"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetAlert.successMessage(res?.msg);
						this.ds.downloadFile(res, "platform_wlevels");
					} else {
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.sweetAlert.errorMessage(err?.error.error);
					this.spinner.hide();
				}
			);
	}
	uploadPlatformWlevels(e) {
		if (e && e?.length) {
			let formData = new FormData();
			formData.append("projects_profile_id", this.projects_profile_id || "");
			formData.append(
				"projects_work_area_id",
				this.projects_work_area_id || ""
			);
			formData.append("import_title_attachment", e[0] || "");
			this.spinner.show();
			this.ds.post("p/cp/import_platform_wlevels", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetAlert.successMessage(res?.msg);
					} else {
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.spinner.hide();
				}
			);
		}
		document.getElementById("uploadPlatformWlevelsInput")["value"] = "";
	}
	getPlatformWlevesHistory() {
		this.spinner.show();
		this.history_files = [];
		let projects_profile_id = this.projects_profile_id;
		let projects_supervision_id = this.projects_supervision_id;
		let formData = new FormData();
		formData.append("required_type", "CP");
		this.ds
			.postActionByUrl(
				this.ds.formData2string(formData),
				`s6df54s6df/${projects_profile_id}/${projects_supervision_id}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.history_files = res?.records;
					} else {
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.sweetAlert.errorMessage(err?.error.error);
				}
			);
	}
	downloadPlatformWlevelsHistory(item) {
		this.spinner.show();
		this.ds.get(item?.file).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.ds.downloadFile(
						res,
						"platform_wlevels" + item?.created_datetime
					);
				} else {
					this.sweetAlert.errorMessage(res?.error);
				}
			},
			(err) => {
				this.sweetAlert.errorMessage(err?.error.error);
				this.spinner.hide();
			}
		);
	}
}
