<div class="card">
    <div class="width100">
        <div class="card-header"> {{'lang_signature_details' | language }} </div>
        <div class="card-body pad0" [formGroup]="signupForm">
            <div class="mbrl15" formGroupName="signatureDetails">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="signature">{{'lang_signature' | language }} : <span class="red">*</span></label>
                            <div class="sig-container" #padElement>
                                <canvas #sPad width="{{width}}" height="{{height}}"></canvas>
                            </div>
                            <br>
                            <div fxLayout="row">
                                <div class="col-md-3 col-xs-12 mb-10 pad0">
                                    <button type="button" class="albutton" (click)="signaturePad.clear()">{{'lang_clear' | language }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="mark">{{'lang_mark' | language }} : <span class="red">*</span></label>
                            <div class="sig-container" #padElement>
                                <canvas #mPad width="{{width}}" height="{{height}}"></canvas>
                            </div>
                            <br>
                            <div fxLayout="row">
                                <div class="col-md-3 col-xs-12 mb-10 pad0">
                                    <button type="button" class="albutton" (click)="markPad.clear()">{{'lang_clear' | language }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-12 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="verification_method">{{'lang_complete_verification' | language }} :<span class="red">*</span></label>
                            <select formControlName="verification_method" #vm class="form-control almnabrformcontrol" (change)="sendVerificationcode(vm.value)">
                                <option value="">{{'lang_choose_options' | language }}</option>
                                <option value="email">{{'lang_email' | language }} ({{ds.secureemail(eml)}})</option>
                                <option value="mobile">{{'lang_mobile' | language }} ({{ds.securemobile(mob)}})</option>
                                <option value="whatsapp">{{'lang_whatsapp' | language }} ({{ds.securemobile(mob)}})</option>
                            </select>
                            <mat-error *ngIf="signupForm.get('signatureDetails')['controls']['verification_method'].touched && signupForm.get('signatureDetails')['controls']['verification_method'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                            <br>
                            <div class="entercode" *ngIf="allowsubmit">
                                <input type="text" formControlName="verification_code" class="form-control almnabrformcontrol" placeholder="{{'lang_enter_verification_code' | language }}" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-12 mb-10" *ngIf="action !=='EDIT'">
                        <div class="form-group">
                            <label for="terms_conditions" class="">{{'lang_terms_conditions' | language }} :<span class="red">*</span></label>
                            <br>
                            <mat-checkbox id="terms_conditions" class="terms_conditions" formControlName="terms_conditions">
                                <a href="{{apiurl}}terms_conditions" target="_blank">{{'lang_accept' | language }}</a>
                            </mat-checkbox>
                            <mat-error *ngIf="signupForm.get('signatureDetails')['controls']['terms_conditions'].touched && signupForm.get('signatureDetails')['controls']['terms_conditions'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer padb0">
                <div class="row">
                    <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
                        <button type="button" class="albutton" matStepperPrevious>{{'lang_previous' | language }}</button>
                    </div>
                    <div class="col-md-8 col-sm-6 col-xs-12 mb-10"></div>
                    <div class="col-md-2 col-sm-3 col-xs-12 mb-10" *ngIf="allowsubmit">
                        <button (click)="rstep8Submitted()" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>