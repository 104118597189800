<div class="card mt-3 user_permissions">
	<div class="card-header d-flex justify-content-between">
		<span>{{ "lang_external_persons" | language }}</span>
		<i
			(click)="onAddUpdateExternalPersons()"
			class="fa fa-plus s2icon mnabricon"
			matTooltip="{{ 'lang_add' | language }}"
		></i>
	</div>
	<div class="card-body">
		<div class="material-panel-body">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-12">
						<div class="table-responsive dropdownyes customResponsive">
							<table border="1" class="table CustomTable">
								<thead>
									<tr>
										<td>#</td>
										<td>{{ "lang_name" | language }}</td>
										<td>{{ "lang_mobile_number" | language }}</td>
										<td>{{ "lang_email" | language }}</td>
										<td>{{ "lang_ondate" | language }}</td>
										<td>{{ "lang_action" | language }}</td>
									</tr>
								</thead>
								<tbody *ngIf="pedata.length != '0'" class="AppendList">
									<tr
										*ngFor="
											let list of pedata.data;
											let i = index;
											let odd = odd;
											let even = even
										"
										[ngClass]="{ odd: odd, even: even }"
									>
										<td>{{ i + 1 }}</td>

										<td>{{ list.person_name }}</td>
										<td>{{ list.mobile_no }}</td>
										<td>{{ list.email }}</td>
										<td>{{ list.insert_date }}</td>
										<td>
											<i
												class="fa fa-pencil-square-o mnabricon mr-2 ml-2"
												(click)="onAddUpdateExternalPersons(list)"
												matTooltip="{{ 'lang_edit' | language }}"
											></i>
											<i
												class="fa fa-trash red"
												(click)="deleteExternalPerson(list?.person_id)"
												matTooltip="{{ 'lang_delete' | language }}"
											></i>
										</td>
									</tr>
								</tbody>
								<tbody *ngIf="pedata.length == '0'" class="AppendList">
									<tr class="odd">
										<td colspan="8" align="center">{{ lodingdatas }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="pedata?.data?.length" class="card-footer padb0">
				<div class="row responsive_pagination">
					<div
						class="col-lg-3 col-sm-3 mb-10"
						style="height: fit-content; margin: auto 0"
					>
						{{ "lang_total" | language }} : {{ pedata?.page?.total_records }}
					</div>
					<div
						class="col-lg-6 col-sm-6 mb-10"
						style="display: flex; justify-content: center"
					>
						<ngb-pagination
							class="m-auto"
							[collectionSize]="pedata?.page?.total_records"
							[rotate]="true"
							[ellipses]="false"
							[maxSize]="3"
							[boundaryLinks]="true"
							[(page)]="pepage_no"
							[pageSize]="pepage_size"
							(pageChange)="loadpage($event)"
						></ngb-pagination>
					</div>
					<div
						class="col-lg-3 col-sm-3"
						style="display: flex; justify-content: end; margin: auto"
					>
						<mat-select
							[(ngModel)]="pepage_size"
							(selectionChange)="loadpagesize($event.value)"
						>
							<mat-option class="text-center" value="10"
								>10 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="20"
								>20 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="50"
								>50 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="100"
								>100 {{ "lang_per_page" | language }}</mat-option
							>
							<mat-option class="text-center" value="500"
								>500 {{ "lang_per_page" | language }}</mat-option
							>
						</mat-select>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
