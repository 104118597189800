export const countries =  [
    {
        "label": "Bangladesh",
        "value": "BD",
        "flag": "/public/images/flag/bd.svg"
    },
    {
        "label": "India",
        "value": "IN",
        "flag": "/public/images/flag/in.svg"
    },
    {
        "label": "Indonesia",
        "value": "ID",
        "flag": "/public/images/flag/id.svg"
    },
    {
        "label": "Jordan",
        "value": "JO",
        "flag": "/public/images/flag/jo.svg"
    },
    {
        "label": "Libya",
        "value": "LY",
        "flag": "/public/images/flag/ly.svg"
    },
    {
        "label": "Pakistan",
        "value": "PK",
        "flag": "/public/images/flag/pk.svg"
    },
    {
        "label": "Palestinian Territory, Occupied",
        "value": "PS",
        "flag": "/public/images/flag/ps.svg"
    },
    {
        "label": "Philippines",
        "value": "PH",
        "flag": "/public/images/flag/ph.svg"
    },
    {
        "label": "Sri Lanka",
        "value": "LK",
        "flag": "/public/images/flag/lk.svg"
    },
    {
        "label": "Sudan",
        "value": "SD",
        "flag": "/public/images/flag/sd.svg"
    },
    {
        "label": "Saudi Arabia",
        "value": "SA",
        "flag": "/public/images/flag/sa.svg"
    },
    {
        "label": "Syrian Arab Republic",
        "value": "SY",
        "flag": "/public/images/flag/sy.svg"
    },
    {
        "label": "Yemen",
        "value": "YE",
        "flag": "/public/images/flag/ye.svg"
    },
    {
        "label": "Egypt",
        "value": "EG",
        "flag": "/public/images/flag/eg.svg"
    }

]