import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { DwsrService } from 'app/routes/transactions/FORM_PS_TR1/services/dwsr.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sqr-step9',
  templateUrl: './sqr-step9.component.html',
  styleUrls: ['./sqr-step9.component.scss']
})
export class SqrStep9Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  stepName:string = 'Manager_Approval';
  form:FormGroup;
  users:any   = [];
  attachments:FormArray;
  projects_work_area_id;
  rawIndex:any;
  filename    = this.lang.transform('lang_select_files');
  lang_key:string     = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
  constructor(public ds: DataService, private sweetAlert:SweetAlertService, private dwsrService:DwsrService, public router: Router, public route: ActivatedRoute, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id              : new FormControl(this.form_request_id, [Validators.required]),
      customr_representative_required     : new FormControl('', [Validators.required]),
      manager_approval_approval_status    : new FormControl('', [Validators.required]),
      rejected_notes                      : new FormControl('', [Validators.required]),
      customer_representative_type        : new FormControl('', [Validators.required]),
      attachments                         : new FormArray([], [Validators.required]),
      owner_users                         : new FormControl(null, [Validators.required])
    });
    this.attachments = this.form.get('attachments') as FormArray;
    this.dwsrService.getData().subscribe(data => {
      this.projects_work_area_id = data?.view_request?.work_area_info?.records?.projects_work_area_id;
      this.customer_users(null);
    });
  }
  
  public customer_users(key) {
    this.users  = [];
		let param   = new FormData();
		param.append('lang_key', this.lang_key);
    param.append('user_position', 'CST001,CSM001');
    param.append('projects_work_area_id', this.projects_work_area_id);
		this.ds.getActionByUrl(this.ds.formData2string(param), `form/${this.form_key}/customer_users`).subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.users = res.records;
			}
		});
  }
  get filesFormGroup() {
    return this.form.get("attachments") as FormArray;
  }
  public files(value?): FormGroup {
    return this.fb.group({
      delete_icon     : new FormControl(value?.attach_title || ''),
      attach_title    : new FormControl({value : (value) ? value?.attach_title : '', disabled : (value?.attach_title) ? true : false}, [Validators.required]),
      file            : new FormControl(null,  [Validators.required]),
    });
  }
  public addfiles() {
    this.attachments.push(this.files());
  }
  public removefiles(index) {
    this.attachments.removeAt(index);
    
  }

  checkValidations() {
    if(this.form.get('manager_approval_approval_status').invalid) {
      this.alert.error(this.lang.transform('lang_manager_approval_required'));
      return false;
    } else if(this.form.get('manager_approval_approval_status').value == 'Return' && this.form.get('rejected_notes').invalid) {
      this.alert.error(this.lang.transform('lang_rejected_notes_required'));
      return false;
    } else if(this.form.get('manager_approval_approval_status').value == 'Approve' && this.form.get('customr_representative_required').invalid) {
      this.alert.error(this.lang.transform('lang_customer_representative_required'));
      return false;
    } else if(this.form.get('manager_approval_approval_status').value == 'Approve' &&
    this.form.get('customr_representative_required').value == 'Yes' && this.form.get('customer_representative_type').invalid) {
      this.alert.error(this.lang.transform('lang_customer_representative_type_required'));
      return false;
    } else if(this.form.get('manager_approval_approval_status').value == 'Approve' &&
    this.form.get('customr_representative_required').value == 'Yes' &&
    this.form.get('customer_representative_type').value == 'assign_owner_for_confirmation' &&
    this.form.get('owner_users').invalid) {
      this.alert.error(this.lang.transform('lang_owner_users_required'));
      return false;
    } else if(this.form.get('manager_approval_approval_status').value == 'Approve' &&
    this.form.get('customr_representative_required').value == 'Yes' &&
    this.form.get('customer_representative_type').value == 'upload_customer_approved_document' &&
    this.form.get('attachments').invalid) {
      this.alert.error(this.lang.transform('lang_attachments_required'));
      return false;
    } else {
      return true;
    }
  }

  public completeStep9() {
    if(this.checkValidations()) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('transaction_request_id', this.form.value.transaction_request_id);
      formData.append('manager_approval_approval_status', this.form.value.manager_approval_approval_status);
      if(this.form.get('manager_approval_approval_status').value == 'Return') {
        formData.append('rejected_notes', this.form.value.rejected_notes);
      }
      if(this.form.get('manager_approval_approval_status').value == 'Approve') {
        formData.append('customr_representative_required', this.form.value.customr_representative_required);
      }
      if(this.form.get('customr_representative_required').value == 'Yes') {
        formData.append('customer_representative_type', this.form.value.customer_representative_type);
      }
      if(this.form.get('customer_representative_type').value == 'upload_customer_approved_document') {
        this.form.value.attachments.forEach((v, k) => {
          formData.append('attachments['+k+'][attach_title]', v.attach_title);
          formData.append('attachments['+k+'][file]', v.file);
        });
      }
      if(this.form.get('customer_representative_type').value == 'assign_owner_for_confirmation') {
        formData.append('owner_users', this.form.value.owner_users);
      }
      this.ds.post(`form/${this.form_key}/Manager_Approval/0`, formData).subscribe((res) => {
        this.spinner.hide();
        if(res.status) {
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
          setTimeout(() => {
            this.refreshComponent.emit(true);
          }, 2000);
        } else {
          this.alert.error(res.error);
        }
      }, (error) => {
        this.spinner.hide();
        this.alert.error(error)
      })
    }
  }
}
