
<mat-dialog-content>
    <div>
        <div class="modal-header">
            <h4 class="modal-title mb-2">{{ 'lang_notes' | language }}</h4>
            <span class="x-close" (click)="closeDialog()">
              <i class="fa fa-times"></i>
            </span>
          </div>
          <div class="modal-body">
            <div class="row">
                <div class="col-12 mt-3 d-flex justify-content-end">
                    <button
                          type="button"
                          color="primary"
                          mat-raised-button
                          class="width-150px"
                          (click)="onAddNotes()"
                        >
                          {{ 'lang_add' | language }}
                    </button>
                </div>
                <div class="col-12 mt-2" *ngIf="isShowAddNotes">
                    <div class="row">
                        <div class="my-4 col-lg-8 col-md-12 col-12">
                            <mat-form-field class="transaction-notes w-100" appearance="outline">
                                <mat-label>{{ 'lang_notes' | language }}</mat-label>
                                <textarea
                                  matInput
                                  [(ngModel)]="notes"
                                  [required]="true"
                                  class="transaction-notes-input"
                                ></textarea>
                              </mat-form-field>
                        </div>
                      </div>
                    <div class="row mt-3">
                      <div class="col-md-4 col-xs-12">
                        <button
                          type="button"
                          mat-raised-button
                          class="width-150px"
                          (click)="closeDialog()"
                        >
                          {{ 'lang_close' | language }}
                        </button>
                      </div>
                      <div class="col-12 col-sm-4"></div>
                      <div class="col-md-4 col-xs-12 d-flex justify-content-end">
                        <button
                          type="button"
                          color="primary"
                          mat-raised-button
                          class="width-150px"
                          [disabled]="!notes"
                          (click)="onSubmit()"
                        >
                          {{ 'lang_submit' | language }}
                        </button>
                      </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <div class="card bg-light border-0 mt-4">
                      <div class="table-responsive dropdownyes customResponsive">
                        <table border="1" class="table CustomTable">
                          <thead>
                            <tr>
                              <td>#</td>
                              <td>{{ "lang_name" | language }}</td>
                              <td>{{ "lang_date" | language }}</td>
                              <td>{{ "lang_step" | language }}</td>
                              <td>{{ "lang_note" | language }}</td>
                            </tr>
                          </thead>
                          <tbody *ngIf="notesList?.length" class="AppendList">
                            <tr
                              *ngFor="
                                let data of notesList;
                                let i = index;
                                let odd = odd;
                                let even = even
                              "
                              [ngClass]="{ odd: odd, even: even }"
                            >
                              <td>
                                {{ data.transactions_notes_user_name }}
                              </td>
                              <td>
                                {{ data.transaction_id }}
                              </td>
                              <td>
                                {{ data.transactions_notes_datetime | date : 'dd-MM-yyyy' }}
                              </td>
                              <td>
                                {{ data.transactions_notes_step }}
                              </td>
                              <td>{{ data.transactions_notes_text }}</td>
                            </tr>
                          </tbody>
                          <tbody *ngIf="!notesList?.length" class="AppendList">
                            <tr class="odd">
                              <td colspan="10" align="center">{{ lodingdatas }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
  </mat-dialog-content>