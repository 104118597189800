<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4 *ngIf="!editMode">{{ "lang_add" | language }}</h4>
		<h4 *ngIf="editMode">{{ "lang_update" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="blockAccountForm">
		<div class="table-responsive dropdownyes customResponsive tablefields">
			<table
				border="1"
				class="table CustomTable"
				width="100%"
				formArrayName="dates"
			>
				<thead>
					<tr>
						<td width="2%" style="padding: 5px; word-break: break-all">#</td>
						<td width="2%" style="padding: 5px; word-break: break-all">
							<mat-icon
								class="mnabricon"
								matTooltip="{{ 'lang_add' | language }}"
								(click)="addDatesRecord()"
								>add_box</mat-icon
							>
						</td>
						<td width="32%" style="padding: 5px; word-break: break-all">
							{{ "lang_start_date" | language }} <span class="red">*</span>
						</td>
						<td width="32%" style="padding: 5px; word-break: break-all">
							{{ "lang_end_date" | language }} <span class="red">*</span>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr
						*ngFor="
							let item of dates.controls;
							let i = index;
							let odd = odd;
							let even = even
						"
						[formGroupName]="i"
					>
						<td
							class="verticalalignmid"
							style="padding: 5px; word-break: break-all"
						>
							{{ i + 1 }}
						</td>
						<td class="verticalalignmid">
							<mat-icon
								(click)="removeDatesRecord(i)"
								class="mnabricon red"
								matTooltip="{{ 'lang_delete' | language }}"
								>delete_forever</mat-icon
							>
						</td>
						<td style="padding: 5px; word-break: break-all; position: relative">
							<mat-form-field appearance="outline">
								<mat-label>{{ "lang_start_date" | language }}</mat-label>
								<input
									matInput
									[matDatepicker]="start_date"
									required
									readonly
									formControlName="start_date"
								/>
								<mat-datepicker-toggle
									matSuffix
									[for]="start_date"
								></mat-datepicker-toggle>
								<mat-datepicker #start_date></mat-datepicker>
							</mat-form-field>
						</td>
						<td style="padding: 5px; word-break: break-all; position: relative">
							<mat-form-field appearance="outline">
								<mat-label>{{ "lang_end_date" | language }}</mat-label>
								<input
									matInput
									[matDatepicker]="end_date"
									required
									readonly
									formControlName="end_date"
								/>
								<mat-datepicker-toggle
									matSuffix
									[for]="end_date"
								></mat-datepicker-toggle>
								<mat-datepicker #end_date></mat-datepicker>
							</mat-form-field>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-0 col-sm-4"></div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="onSubmit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
