import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";

@Component({
	selector: "app-payment-edit",
	templateUrl: "./payment-edit.component.html",
	styleUrls: ["./payment-edit.component.scss"],
})
export class PaymentEditComponent implements OnInit, OnDestroy {
	public branch_id = this.route.snapshot.paramMap.get("bid");
	edidata_status: boolean = false;
	public payment_receipt_id = this.route.snapshot.paramMap.get("pid");
	public finance_id = this.route.snapshot.paramMap.get("fid");
	branches: any = [];
	accountData: any = [];
	costData: any = [];
	settings = environment.singledropdown;
	showfield: boolean = false;
	filename: any = this.lang.transform("lang_select_files");
	isFile: boolean = false;
	form: FormGroup;
	financeList = [];

	constructor(
		public ds: DataService,
		public route: ActivatedRoute,
		public datePipe: DatePipe,
		private sweetAlert: SweetAlertService,
		public router: Router,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public fb: FormBuilder,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.buildform();
		this.formControlValueChanged();
		this.getdata_foredit();
		this.form.get("branch_id").valueChanges.subscribe((res) => {
			if (res) {
				this.getFinancialList();
			}
		});
	}
	private subscriptions = new Subscription();
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	public getdata_foredit() {
		this.spinner.show();
		this.subscriptions.add(
			this.ds
				.getActionByUrl(
					[],
					"editpayment/" + this.branch_id + "/" + this.payment_receipt_id
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.edidata_status = true;
							let accountdatas = [];
							if (
								res.records.payment_receipt_debit_account_id &&
								res.records.debit_account
							) {
								// debit accounts
								accountdatas.push({
									label: res.records.debit_account,
									value: res.records.payment_receipt_debit_account_id,
								});
							}
							if (
								res.records.payment_receipt_credit_account_id &&
								res.records.credit_account
							) {
								//credit accounts
								accountdatas.push({
									label: res.records.credit_account,
									value: res.records.payment_receipt_credit_account_id,
								});
							}
							let costdatas = [];
							if (
								res.records.payment_receipt_debit_cost_id &&
								res.records.debit_cost
							) {
								//debit cost centers
								costdatas.push({
									label: res.records.debit_cost,
									value: res.records.payment_receipt_debit_cost_id,
								});
							}
							if (
								res.records.payment_receipt_credit_cost_id &&
								res.records.credit_cost
							) {
								// credit cost centers
								costdatas.push({
									label: res.records.credit_cost,
									value: res.records.payment_receipt_credit_cost_id,
								});
							}
							this.accountData = accountdatas;
							this.costData = costdatas;
							this.form.patchValue(res.records);
							this.form
								.get("payment_receipt_date")
								.setValue(this.ds.encon(res.records.payment_receipt_date));
							this.form
								.get("payment_receipt_document_date")
								.setValue(
									this.ds.encon(res.records.payment_receipt_document_date)
								);
							this.form.get("payment_receipt_attachment").setValue("");
						} else {
							this.edidata_status = false;
							this.ds.dialogf("", res.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.ds.dialogf(
							"",
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}
	public buildform() {
		this.form = new FormGroup({
			branch_id: new FormControl(this.branch_id, [Validators.required]),
			payment_receipt_date: new FormControl("", [Validators.required]),
			payment_receipt_to_from: new FormControl("", [Validators.required]),
			payment_receipt_mode: new FormControl("", [Validators.required]),
			payment_receipt_debit_account_id: new FormControl(null, [
				Validators.required,
			]),
			payment_receipt_debit_cost_id: new FormControl(null),
			payment_receipt_credit_account_id: new FormControl(null, [
				Validators.required,
			]),
			payment_receipt_credit_cost_id: new FormControl(null),
			payment_receipt_amount: new FormControl("", [Validators.required]),
			payment_receipt_document_number: new FormControl(""),
			payment_receipt_document_date: new FormControl(""),
			payment_receipt_bank_name: new FormControl(""),
			payment_receipt_notes: new FormControl(""),
			payment_receipt_description: new FormControl("", [Validators.required]),
			payment_receipt_attachment: new FormControl(""),
			finance_id: new FormControl(null, [Validators.required]),
		});
	}
	public formControlValueChanged() {
		const payment_receipt_document_number = this.form.get(
			"payment_receipt_document_number"
		);
		const payment_receipt_document_date = this.form.get(
			"payment_receipt_document_date"
		);
		const payment_receipt_bank_name = this.form.get(
			"payment_receipt_bank_name"
		);
		this.subscriptions.add(
			this.form
				.get("payment_receipt_mode")
				.valueChanges.subscribe((mode: string) => {
					this.showfield = false;
					if (mode !== "cash") {
						this.showfield = true;
						payment_receipt_document_number.setValidators([
							Validators.required,
						]);
						payment_receipt_document_date.setValidators([Validators.required]);
						payment_receipt_bank_name.setValidators([Validators.required]);
					} else {
						payment_receipt_document_number.clearValidators();
						payment_receipt_document_date.clearValidators();
						payment_receipt_bank_name.clearValidators();
					}
					payment_receipt_document_number.updateValueAndValidity();
					payment_receipt_document_date.updateValueAndValidity();
					payment_receipt_bank_name.updateValueAndValidity();
				})
		);
	}
	public uploadFile(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			const fileid = event.target.files[0];
			this.filename = fileid.name;
			this.isFile = true;
			this.form
				.get("payment_receipt_attachment")
				.setValue(fileid, { emitEvent: true });
		} else {
			this.isFile = false;
			this.form.get("payment_receipt_attachment").setValue("");
			this.filename = this.lang.transform("lang_select_files");
		}
	}
	public removeFile() {
		this.isFile = false;
		this.form.get("payment_receipt_attachment").setValue("");
		this.filename = this.lang.transform("lang_select_files");
	}
	public update_payment() {
		this.spinner.show();
		let param = new FormData();
		param.set("branch_id", this.form.get("branch_id").value);
		param.set("finance_id", this.form.get("finance_id").value || "");
		param.set(
			"payment_receipt_date",
			this.form.get("payment_receipt_date").value || ""
		);
		param.set(
			"payment_receipt_to_from",
			this.form.get("payment_receipt_to_from").value
		);
		param.set(
			"payment_receipt_mode",
			this.form.get("payment_receipt_mode").value
		);
		param.set(
			"payment_receipt_debit_account_id",
			this.form.get("payment_receipt_debit_account_id").value
				? this.form.get("payment_receipt_debit_account_id").value
				: "0"
		);
		param.set(
			"payment_receipt_debit_cost_id",
			this.form.get("payment_receipt_debit_cost_id").value
				? this.form.get("payment_receipt_debit_cost_id").value
				: "0"
		);
		param.set(
			"payment_receipt_credit_account_id",
			this.form.get("payment_receipt_credit_account_id").value
				? this.form.get("payment_receipt_credit_account_id").value
				: "0"
		);
		param.set(
			"payment_receipt_credit_cost_id",
			this.form.get("payment_receipt_credit_cost_id").value
				? this.form.get("payment_receipt_credit_cost_id").value
				: "0"
		);
		param.set(
			"payment_receipt_amount",
			this.form.get("payment_receipt_amount").value
		);
		param.set(
			"payment_receipt_document_number",
			this.form.get("payment_receipt_document_number").value
		);
		param.set(
			"payment_receipt_document_date",
			this.form.get("payment_receipt_document_date").value || ""
		);
		param.set(
			"payment_receipt_bank_name",
			this.form.get("payment_receipt_bank_name").value
		);
		param.set(
			"payment_receipt_notes",
			this.form.get("payment_receipt_notes").value
		);
		param.set(
			"payment_receipt_description",
			this.form.get("payment_receipt_description").value
		);
		param.set(
			"payment_receipt_attachment",
			this.form.get("payment_receipt_attachment").value
		);
		this.subscriptions.add(
			this.ds
				.postActionByUrl(param, "payupdate/" + this.payment_receipt_id)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.form.reset();
							this.alert.success(res.msg);
							this.router.navigate([
								"accounts/payview/" +
									res.branch_id +
									"/" +
									res.payment_receipt_id +
									"/view",
							]);
						} else {
							this.alert.error(res.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}
	public searchAccounts(key) {
		this.accountData = [];
		let param = new FormData();
		param.append("branch_id", this.form.get("branch_id").value);
		param.append("finance_id", this.form.get("finance_id").value || "");
		param.append("search_text", key.term);
		if (this.form.get("branch_id").value && key.term && key.term != undefined) {
			this.subscriptions.add(
				this.ds.postActionByUrl(param, "sam/accounts_edit").subscribe(
					(data) => {
						if (data.status) {
							this.accountData = data.records;
						} else {
							this.accountData = [];
						}
					},
					(error) => {
						this.accountData = [];
					}
				)
			);
		}
	}
	public searchCosts(key) {
		this.costData = [];
		let param = new FormData();
		param.append("branch_id", this.form.get("branch_id").value);
		param.append("search_text", key.term);
		if (this.form.get("branch_id").value && key.term && key.term != undefined) {
			this.subscriptions.add(
				this.ds.postActionByUrl(param, "cctransactions").subscribe(
					(res) => {
						if (res.status) {
							this.costData = res.records;
						} else {
							this.costData = [];
						}
					},
					(error) => {
						this.costData = [];
					}
				)
			);
		}
	}
	getFinancialList() {
		let formData = new FormData();
		formData.append("branch_id", this.form.get("branch_id").value || "");
		this.ds
			.getActionByUrl(this.ds.formData2string(formData), "acc/current_fyears")
			.subscribe(
				(res) => {
					if (res?.status) {
						this.financeList = res?.records.map((el) => {
							return {
								label: el?.finance_start_date + " - " + el?.finance_end_date,
								value: el?.finance_id,
							};
						});
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
}
