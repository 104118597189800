<mat-tab-group
	mat-align-tabs="center"
	(selectedTabChange)="selectedTabValue($event)"
>
	<mat-tab label="generate_contract">
		<ng-template mat-tab-label>Generate Contract</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12">Generate Contract Works!!</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab label="upload_contract">
		<ng-template mat-tab-label>Upload Contract</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12">
					<button class="add-contract-btn" (click)="onAddUpdateContract()">
						<i class="pi pi-plus"></i>
						<span>Add Contract</span>
					</button>
				</div>
				<div class="col-12 mt-2">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td style="width: 5%">#</td>
									<td style="width: 27%">Contract Number</td>
									<td style="width: 27%">Contract Days</td>
									<td style="width: 26%">Status</td>
									<td style="width: 10%">Action</td>
								</tr>
							</thead>
							<tbody *ngIf="contractList.length" class="AppendList">
								<ng-container
									*ngFor="
										let list of contractList;
										let contract_index = index;
										let odd = odd;
										let even = even
									"
								>
									<tr
										[ngClass]="{
											odd: odd,
											even: even,
											expanded: list.expanded
										}"
										(click)="toggleContractor(contract_index)"
									>
										<td>{{ contract_index + 1 }}</td>
										<td>{{ list.contract_id }}</td>
										<td>{{ list.contact_age }}</td>
										<td>
											<i
												*ngIf="list?.contract_status == '1'"
												class="fa fa-circle mnabricon green"
												(click)="changeContractStatus($event, list, 'close')"
												matTooltip="{{ list.contract_status_name }}"
											></i>
											<i
												*ngIf="list?.contract_status != '1'"
												class="fa fa-circle mnabricon yellowcolor"
												(click)="changeContractStatus($event, list, 'open')"
												matTooltip="{{ list.contract_status_name }}"
											></i>
										</td>
										<td>
											<i
												class="fa fa-pencil-square-o mnabricon edit-icon"
												matTooltip="{{ 'lang_edit' | language }}"
												(click)="onAddUpdateContract($event, list)"
											></i>
										</td>
									</tr>
									<tr *ngIf="list?.expanded" class="list-level2">
										<td></td>
										<td colspan="4">
											<mat-tab-group
												class="sub-mat-tab"
												(selectedTabChange)="selectedSubtab($event)"
											>
												<mat-tab label="attachments">
													<ng-template mat-tab-label>Attachments</ng-template>
													<app-contract-attachments
														[contract_id]="list.contract_id"
													></app-contract-attachments>
												</mat-tab>
												<mat-tab label="components">
													<ng-template mat-tab-label
														>Building Components</ng-template
													>
													<app-contract-components
														[contract_id]="list.contract_id"
													></app-contract-components>
												</mat-tab>
											</mat-tab-group>
										</td>
									</tr>
								</ng-container>
							</tbody>
							<tbody *ngIf="!contractList.length">
								<tr>
									<td colspan="5">
										<no-data
											style="margin: auto"
											[Opened]="contractList.length < 1"
										></no-data>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div *ngIf="contractList?.length" class="card-footer padb0 customborder">
				<div class="row responsive_pagination">
					<div
						class="col-lg-3 col-sm-3 mb-10"
						style="height: fit-content; margin: auto 0"
					>
						{{ "lang_total" | language }} {{ collectionSize }}
					</div>
					<div
						class="col-lg-6 col-sm-6 mb-10"
						style="display: flex; justify-content: center"
					>
						<ngb-pagination
							class="m-auto"
							[collectionSize]="collectionSize"
							[rotate]="true"
							[ellipses]="false"
							[maxSize]="3"
							[boundaryLinks]="true"
							[(page)]="contract_pageno"
							[pageSize]="contract_pagesize"
							(pageChange)="getContractList()"
						></ngb-pagination>
					</div>
					<div
						class="col-lg-3 col-sm-3 mb-10"
						style="display: flex; justify-content: end; margin: auto"
					>
						<mat-select
							[(ngModel)]="contract_pagesize"
							(selectionChange)="getContractList()"
						>
							<mat-option class="text-center" value="10"
								>10 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="20"
								>20 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="50"
								>50 {{ "lang_per_page" | language }}
							</mat-option>
							<mat-option class="text-center" value="100"
								>100 {{ "lang_per_page" | language }}</mat-option
							>
							<mat-option class="text-center" value="500"
								>500 {{ "lang_per_page" | language }}</mat-option
							>
						</mat-select>
					</div>
				</div>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>
