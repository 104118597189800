<div class="card">
    <div class="width100">
        <div class="card-header">{{'lang_login_details' | language }}</div>
        <div class="card-body pad0" [formGroup]="signupForm">
            <div class="mbrl15" formGroupName="loginDetails">
                <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="username">{{'lang_username' | language }} : <span class="red">*</span></label>
                            <input formControlName="username" minlength="6" autocomplete="username" maxlength="20" id="username" type="text" placeholder="{{'lang_username' | language }}" class="form-control almnabrformcontrol username" (change)="usernameExists($event.target.value)" />
                            <mat-error *ngIf="signupForm.get('loginDetails')['controls']['username'].touched && signupForm.get('loginDetails')['controls']['username'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                            <mat-error *ngIf="signupForm.get('loginDetails')['controls']['username'].errors?.minlength"> {{'lang_minimum' | language }} 6 </mat-error>
                            <mat-error *ngIf="signupForm.get('loginDetails')['controls']['username'].errors?.maxlength"> {{'lang_maximum' | language }} 20 </mat-error>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="password">{{'lang_password' | language }} : <span class="red">*</span></label>
                            <input formControlName="password" minlength="6" id="password" autocomplete="new-password" type="password" placeholder="{{'lang_password' | language }}" class="form-control almnabrformcontrol password"/>
                            <mat-error *ngIf="signupForm.get('loginDetails')['controls']['password'].touched && signupForm.get('loginDetails')['controls']['password'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                            <mat-error *ngIf="signupForm.get('loginDetails')['controls']['password'].errors?.minlength"> {{'lang_minimum' | language }} 6 </mat-error>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="confirm_password">{{'lang_confirm_password' | language }} : <span class="red">*</span></label>
                            <input formControlName="confirm_password" minlength="6" autocomplete="new-password" id="confirm_password" type="password" placeholder="{{'lang_confirm_password' | language }}" class="form-control almnabrformcontrol confirm_password" />
                            <mat-error *ngIf="signupForm.get('loginDetails')['controls']['confirm_password'].touched && signupForm.get('loginDetails')['controls']['confirm_password'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                            <mat-error *ngIf="signupForm.get('loginDetails')['controls']['confirm_password'].errors?.minlength"> {{'lang_minimum' | language }} 6 </mat-error>
                            <mat-error *ngIf="signupForm.get('loginDetails')['controls']['password'].value !== signupForm.get('loginDetails')['controls']['confirm_password'].value"> {{'lang_password_notmatch' | language }} </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer padb0">
                <div class="row">
                    <div class="col-md-10 col-sm-9 col-xs-12"></div>
                    <div class="col-md-2 col-sm-3 col-xs-12">
                        <button type="button" class="albutton" matStepperNext (click)="rstep1Submitted()">{{'lang_next' | language }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>