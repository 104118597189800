import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-spcreate',
  templateUrl: './spcreate.component.html',
  styleUrls: ['./spcreate.component.scss']
})
export class SpcreateComponent implements OnInit {
  registrationForm: FormGroup;
  users:any           = [];
  accounts:any        = [];
  branches: any       = [];
  types: any          = [];  
  isFile: boolean     = false;
  filename: any       = this.lang.transform('lang_select_files');
  constructor(public ds: DataService,public router:Router ,public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
  ngOnInit(): void {
    this.spinner.show();
    this.registration_form();
    this.get_branches();
  }
  public registration_form() {
    this.registrationForm = new FormGroup({
      branch_id: new FormControl('', [Validators.required]),
      supplier_title_en: new FormControl('', [Validators.required]),
      supplier_title_ar: new FormControl('', [Validators.required]),
      logo : new FormControl(''),
      supplier_email_address: new FormControl('', [Validators.required, Validators.email]),
      supplier_mobile: new FormControl('', [Validators.required]),
      supplier_tax_vat: new FormControl('', [Validators.required]),
      supplier_ccr_no: new FormControl(''),
      supplier_managers: new FormControl('', [Validators.required]),
      supplier_teams: new FormControl('', [Validators.required]),
      supplier_link_with_accounts: new FormControl(0, [Validators.required]),
      supplier_address: new FormControl(''),
      supplier_credit_days: new FormControl(''),
      supplier_security_amount: new FormControl(''),
      supplier_credit_limit: new FormControl(''),
      supplier_discount_limit: new FormControl(''),
      supplier_discount_onpay: new FormControl(''),
      supplier_salesmancode: new FormControl(''),
      supplier_salesmanarea: new FormControl(''),
      supplier_stop_transaction: new FormControl(''),
      supplier_account_id: new FormControl(''),
    })
  }
  public get_branches() {
    this.ds.getActionByUrl([], 'sp/b/suppliers_add').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.branches = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public emptyAccountFileds() {
    this.registrationForm.patchValue({
      supplier_address            : null,
      supplier_credit_days        : null,
      supplier_security_amount    : null,
      supplier_credit_limit       : null,
      supplier_discount_limit     : null,
      supplier_discount_onpay     : null,
      supplier_salesmancode       : null,
      supplier_salesmanarea       : null,
      supplier_stop_transaction   : null,
      supplier_account_id               : null,
    })
  }
  public accountSearch(key) {
    this.accounts = [];
    if (this.registrationForm.get('branch_id').value && key != undefined && key.term) {
      let param = new FormData();
      param.append('branch_id', this.registrationForm.get('branch_id').value);
      param.append('search_text', key.term);
      param.append('status', "1");
      this.ds.postActionByUrl(param, "sam/accounts_add").subscribe(data => {
        if (data.status) {
          this.accounts = data.records;
        }
      });
    }
  }
  public search_users(key) {
    if (this.registrationForm.get('branch_id').value && key != undefined) {
      let param = new FormData();
      param.append('branch_id', this.registrationForm.get('branch_id').value);
      param.append('search_text', key.term);
      param.append('permission_key', "suppliers_add");
      this.ds.postActionByUrl(param, "sp/susers").subscribe(data => {
          if(data.status){
            this.users = data.records;
          }
      });
    } else {
      this.ds.dialogf('', this.lang.transform('lang_choose_branch'));
    }
  }
  public uploadFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.isFile = true;
      this.registrationForm.get("logo").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.registrationForm.get("logo").setValue('');
      this.filename = this.lang.transform('lang_select_files');
    }
  }
  public removeFile() {
    this.isFile = false;
    this.registrationForm.get("logo").setValue('');
    this.filename = this.lang.transform('lang_select_files');
  }
  public create() {
    this.spinner.show();
    let param = this.ds.json2formdata(this.registrationForm.value);
    if (this.registrationForm.get('supplier_link_with_accounts').value == '1') {
      param.set('supplier_account_id', this.registrationForm.get('supplier_account_id').value || '');
    }
    this.ds.postActionByUrl(param, 'sp/create').subscribe((data) => {
        this.spinner.hide();
        if (data.status) {
          this.alert.success(data.msg);
          this.registrationForm.reset();
          setTimeout(() => {
            this.router.navigate(['/suppliers/view/' + data.insert_id]);
          }, 1000);
        } else {
          this.alert.error(data.error);
        }
      },(error) => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      }
    );
  }
}
