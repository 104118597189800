import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DataService } from "@core/bootstrap/data.service";
import { PreloaderService } from "@core/bootstrap/preloader.service";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-ds-view-group",
	templateUrl: "./ds-view-group.component.html",
	styleUrls: ["./ds-view-group.component.scss"],
})
export class DsViewGroupComponent implements OnInit {
	group_id;
	group;
	constructor(
		private spinner: PreloaderService,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private dialogRef: MatDialogRef<DsViewGroupComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.group_id = data.group_data?.group_id;
	}

	ngOnInit(): void {
		this.getGroupData();
	}

	getGroupData() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("group_id", this.group_id);
		this.ds.post("project_design/get_group", formData).subscribe((res) => {
			this.spinner.hide();
			if (res.status) {
				this.group = res.data;
			} else {
				this.sweetAlert.errorToast(res.error, 2000);
			}
		});
	}
	removeUser(user_id) {
		this.spinner.show();
		this.ds
			.post(`project_design/delete_group_user/${this.group_id}/${user_id}`, {})
			.subscribe((res) => {
				this.spinner.hide();
				if (res.status) {
					this.getGroupData();
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			});
	}
}
