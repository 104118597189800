import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  //variable declaration for id notifications starts//
  id_count: any = 0;
  id_error_msg: string = this.lang.transform('lang_loading');
  id_data: any = [];
  idsize: number = 10;
  //variable declaration for contract notifications starts//
  contract_count: any = 0;
  contract_error_msg: string = this.lang.transform('lang_loading');
  contract_data: any = [];
  consize: number = 10;
  //variable declaration for vocation notifications starts//
  vocation_count: any = 0;
  vocation_error_msg: string = this.lang.transform('lang_loading');
  vocation_data: any = [];
  vocsize: number = 10;
  //variable declaration for licence notifications starts//
  licence_count: any = 0;
  licence_error_msg: string = this.lang.transform('lang_loading');
  licence_data: any = [];
  licsize: number = 10;
  //variable declaration for passport notifications starts//
  passport_count: any = 0;
  passport_error_msg: string = this.lang.transform('lang_loading');
  passport_data: any = [];
  passize: number = 10;
  //variable declaration for insurance notifications starts//
  insurance_count: any = 0;
  insurance_error_msg: string = this.lang.transform('lang_loading');
  insurance_data: any = [];
  inssize: number = 10;
  //variable declaration for period notifications starts//
  period_count: any = 0;
  period_error_msg: string = this.lang.transform('lang_loading');
  period_data: any = [];
  prdsize: number = 10;
  //variable declaration for Interview notifications starts//
  interview_count: any = 0;
  interview_error_msg: string = this.lang.transform('lang_loading');
  interview_data: any = [];
  intrsize: number = 10;
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.load_my_notifications(1, 10, 'ID');
    this.load_my_notifications(1, 10, 'INTERVIEW');
    this.load_my_notifications(1, 10, 'PASSPORT');
    this.load_my_notifications(1, 10, 'INSURANCE');
    this.load_my_notifications(1, 10, 'LICENCE');
  }
  public id_notify_page(page, size) {
    this.idsize = size;
    this.load_my_notifications(page, size, 'ID');
  }
  public id_notify_length(page) {
    this.load_my_notifications(page, this.idsize, 'ID');
  }
  public interview_notify_page(page, size) {
    this.intrsize = size;
    this.load_my_notifications(page, size, 'INTERVIEW');
  }
  public interview_notify_length(page) {
    this.load_my_notifications(page, this.intrsize, 'INTERVIEW');
  }
  public passport_notify_page(page, size) {
    this.passize = size;
    this.load_my_notifications(page, size, 'PASSPORT');
  }
  public passport_notify_length(page) {
    this.load_my_notifications(page, this.passize, 'PASSPORT');
  }  
  public licence_notify_page(page, size) {
    this.licsize = size;
    this.load_my_notifications(page, size, 'LICENCE');
  }
  public licence_notify_length(page) {
    this.load_my_notifications(page, this.licsize, 'LICENCE');
  }
  public insurance_notify_page(page, size) {
    this.inssize = size;
    this.load_my_notifications(page, size, 'INSURANCE');
  }
  public insurance_notify_length(page) {
    this.load_my_notifications(page, this.inssize, 'INSURANCE');
  }
  public load_my_notifications(page: number, size: number, method: string) {
    this.ds.getActionByUrl([], 'human_resources_notifications/' + page + '/' + size + '/' + method).subscribe(result => {
      this.spinner.hide();
      if(result.status){
        this.error_or_no_data(method, '', result.page.total_records, result);
      } else{
        this.error_or_no_data(method, result.error, 0, []);
      }
    }, error => {
      this.spinner.hide();
        this.error_or_no_data(method, (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 0, []);
    })
  }
  public error_or_no_data(method:string, error:string,count:number, data:any[]) {
    if(method == 'ID'){
      this.id_count = count;
      this.id_error_msg = error;
      this.id_data = data;
    } else if (method == 'INTERVIEW') {
        this.interview_count = count;
        this.interview_error_msg = error;
        this.interview_data = data;
    } else if (method == 'PASSPORT') {
      this.passport_count = count;
      this.passport_error_msg = error;
      this.passport_data = data;
    } else if (method == 'LICENCE') {
      this.licence_count = count;
      this.licence_error_msg = error;
      this.licence_data = data;
    } else if (method == 'INSURANCE') {
      this.insurance_count = count;
      this.insurance_error_msg = error;
      this.insurance_data = data;
    } 
  }
}
