<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_units_levels" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-12">
			<label>
				{{ "lang_preview_type" | language }}
				<span class="red">*</span></label
			>
			<ng-select
				[items]="preview_type_list"
				[multiple]="false"
				bindLabel="label"
				bindValue="value"
				appendTo="body"
				[(ngModel)]="preview_type"
				placeholder="{{ 'lang_preview_type' | language }}"
			>
			</ng-select>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="onSubmit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
