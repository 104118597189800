<form [formGroup]="form" class="form">
	<mat-tab-group
		mat-stretch-tabs
		class="example-stretched-tabs mat-elevation-z4 group2mat group2mattab"
		formArrayName="group2array"
	>
		<mat-tab
			*ngFor="
				let item2 of items_group2().controls;
				let group2Index = index;
				let odd = odd;
				let even = even
			"
			[formGroupName]="group2Index"
		>
			<ng-template mat-tab-label>
				<span>{{
					group2validate.controls[group2Index] &&
						group2validate.controls[group2Index].get(
							"platform_group2_code_title"
						).value
				}}</span>
			</ng-template>
			<div class="custom-panel-content">
				<div class="card">
					<div class="width100">
						<div class="card-header">
							<bdi
								innerHtml="{{ 'lang_total_price' | language }} {{
									group2validate.controls[group2Index].get(
										'platform_group2_code_title'
									).value
								}}: {{
									group2validate.controls[group2Index].get(
										'platform_group2_code_total'
									).value
								}}"
							></bdi>
							<span class="float-right"
								><bdi>{{
									group2validate.controls[group2Index] &&
										group2validate.controls[group2Index].get(
											"platform_group2_code_system"
										).value
								}}</bdi></span
							>
						</div>
						<div class="card-body padding10">
							<div class="row">
								<div class="col-md-3 col-sm-6 col-xs-12 mb-10">
									<label>{{ "lang_group2_code_manual" | language }}</label>
									<input
										class="form-control almnabrformcontrol"
										formControlName="platform_group2_code_manual"
										type="text"
									/>
								</div>
								<div class="col-md-3 col-sm-6 col-xs-12 mb-10">
									<label>{{ "lang_total_ratio" | language }}</label>
									<input
										type="text"
										class="form-control almnabrformcontrol"
										formControlName="platform_group2_code_total_ratio"
										disabled
										readonly
									/>
								</div>
							</div>

							<app-platforms
								[fconsultants]="fconsultants"
								[consultantr]="consultantr"
								[positions]="positions"
								[buildingCodes]="buildingCodes"
								[ncrSiIsssues]="ncrSiIsssues"
								[type_code]="type_code"
								[platforms_data]="
									group2validate.controls[group2Index].get('group2Platforms')
										.value
								"
								[actionMode]="actionMode"
								[step2form]="step2form"
								[step1formvalue]="step1formvalue"
								[maintotalamount]="maintotalamount"
								[this_template_total_cost]="this_template_total_cost"
								[step2formvalue]="step2formvalue"
								[access_codes]="access_codes"
								[weightunits]="weightunits"
								formControlName="plaftformData"
								[group1Index]="group1Index"
								[typeIndex]="typeIndex"
								[group2Index]="group2Index"
								[project_data]="project_data"
								[supervision_data]="supervision_data"
								[tree_data]="tree_data"
								(platforms_total_ratio)="getPlatformsRatio($event, group2Index)"
							></app-platforms>
						</div>
					</div>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</form>
<ng-template #addExtraGroup2>
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_add_group" | language }} 2</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRefEG2.hide()"
			>
				&times;
			</button>
		</div>
		<div class="modal-body">
			<div class="form-group">
				<div class="row">
					<div class="col-12">
						<label
							>{{ "lang_group" | language }} 2 :
							<span class="red">*</span></label
						>
					</div>
					<div class="col-12">
						<ng-select
							[items]="extragroup2"
							[multiple]="true"
							clearAllText="Clear"
							[hideSelected]="true"
							appendTo="body"
							[(ngModel)]="extragroup2data"
							placeholder="{{ 'lang_group' | language }} 2"
						>
						</ng-select>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer pad15">
			<div class="row">
				<div class="col-md-6 col-xs-12 mb-10">
					<button
						type="button"
						class="albutton"
						(click)="add_extra_group2_items()"
					>
						{{ "lang_add" | language }}
					</button>
				</div>
				<div class="col-md-6 col-xs-12 mb-10">
					<button
						type="button"
						class="btn btn-secondary secondarybutton"
						data-dismiss="modal"
						(click)="modalRefEG2.hide()"
					>
						{{ "lang_close" | language }}
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>
