<mat-tab-group class="design-mat-tab">
	<mat-tab>
		<ng-template mat-tab-label>Groups</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12">
					<app-ds-groups></app-ds-groups>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>Sub Tasks</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12">
					<app-sub-tasks></app-sub-tasks>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>Constants</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12">
					<app-ds-constants></app-ds-constants>
				</div>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>
