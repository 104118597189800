<div class="back-to-meetings">
	<div class="arrow-btn" (click)="back_to_meeting()">
		<i class="fa fa-long-arrow-left mnabricon"></i>
		<span class="mr-2 ml-2">{{ "lang_meeting" | language }}</span>
	</div>
</div>
<!-- start task-checklist -->
<div class="task-checklist-list" *ngIf="meeting?.points?.length">
	<!-- start task-checklist list -->
	<div
		class="task-checklist"
		*ngFor="let checklist of meeting?.points; let checklist_index = index"
	>
		<div class="tc-header">
			<div class="tch-title">
				<i class="pi pi-check-square"></i>
				<h5>
					{{ checklist?.point_notes }}
				</h5>
			</div>
			<div class="tc-action">
				<i
					class="fa fa-trash"
					style="color: #b04632"
					matTooltip="{{ 'lang_delete' | language }}"
					(click)="deleteChecklist(meeting?.meeting_id, checklist?.point_id)"
					*ngIf="userId == meeting?.user_add_id"
				></i>
			</div>
		</div>
		<div class="tc-body">
			<!-- start task-checklist list items -->
			<div class="tc-items">
				<ng-container
					*ngFor="
						let checklist_item of checklist?.points_sub;
						let checklistItemIndex = index
					"
				>
					<div class="tc-item">
						<div class="tc-item-container">
							<div class="tci-content">
								<span class="tci-content-title">
									<h5>
										{{ checklist_item?.point_notes }}
									</h5>
									<small
										>{{ "lang_created_date" | language }}
										{{ checklist_item?.insert_date }}</small
									>
								</span>
								<div class="tci-content-action">
									<span class="d-flex">
										<i
											class="fa fa-trash mr-1 ml-1"
											(click)="
												deleteChecklistItem(checklist_item?.point_sub_id)
											"
											matTooltip="{{ 'lang_delete' | language }}"
										></i>
									</span>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
			<!-- end task-checklist list items -->
		</div>
		<div class="tc-add">
			<button
				class="tc-open-add-btn task-btn"
				(click)="onAddSubPoint(checklist?.point_id)"
			>
				<i class="pi pi-plus"></i>
				<span>{{ "lang_add" | language }} {{ "lang_items" | language }}</span>
			</button>
		</div>
		<!-- end add Checklist -->
	</div>
	<!-- end task-checklist list -->
</div>
<!-- end task-checklist -->

<div *ngIf="!meeting?.points">
	<no-data style="margin: auto" [Opened]="!meeting?.points?.length"></no-data>
</div>
