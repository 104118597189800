<mat-accordion class="mt-2">
	<mat-expansion-panel
		class="mb-2"
		style="box-shadow: none !important; border: 1px solid #dee2e6"
	>
		<mat-expansion-panel-header
			>{{ "lang_steps_history" | language }}
		</mat-expansion-panel-header>
		<div class="material-panel-body">
			<div class="mbrl15 martb0">
				<div class="row">
					<div class="col-12 mt-2" *ngIf="step_no >= 2">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_configurations" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_work_name" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.work_name
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_issue" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.issue
										}}</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 mt-4" *ngIf="step_no >= 3">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_manager_approval" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Approved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 4">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_contractor_reservation" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Reserved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 5">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ " lang_contractor_approval" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_reason" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.ncr_issue_reason
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_suggestion_to_fixing" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.ncr_issue_fix_suggestion
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_preventive_action" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.ncr_issue_preventive_action
										}}</span>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_fixing_date" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.ncr_issue_fixing_date
										}}</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 6">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_recipient_reservation" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Reserved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 7">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_receipient_team_reply" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_confirm_fixing_way" | language }} :
										<span
											style="font-weight: 500"
											[attr.style]="
												steps_data?.ncr_issue_confirm_fixing_way == '1'
													? 'color: #20a620 !important'
													: 'color: #d02a2a !important'
											"
											>{{
												steps_data?.ncr_issue_confirm_fixing_way == "1"
													? "Accepted"
													: "Rejected"
											}}</span
										>
									</p>
								</div>
								<div class="col-12 col-sm-6 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_note" | language }} :
										<span style="font-weight: 500">{{
											steps_data?.ncr_issue_confirm_fixing_note
										}}</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 8">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_manager_approval" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Approved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 9">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_receipient_position" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Reserved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 10">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_contractor_close_NCR" | language }}
							</div>
							<div class="row">
								<div class="col-12 mt-2 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_note" | language }} :
										<span style="font-weight: 500"></span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 11">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_recipient_reservation" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Reserved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 12">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_contractor_close_NCR" | language }}
							</div>
							<div class="row">
								<div class="col-12 mt-2 mb-1">
									<p style="font-weight: 600; margin: 0">
										{{ "lang_close_ncr_request" | language }} :
										<span
											style="font-weight: 500"
											[attr.style]="
												steps_data?.close_ncr_request_status == 'B'
													? 'color: #20a620 !important'
													: 'color: #d02a2a !important'
											"
											>{{ steps_data?.close_ncr_request_status }}</span
										>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 13">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_manager_approval" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Approved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 14">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_QAQC_reservation" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Reserved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mt-4" *ngIf="step_no >= 15">
						<div class="step-history">
							<div
								class="step-header"
								[ngStyle]="{
									left: lang_key == 'en' ? '20px' : 'unset',
									right: lang_key == 'ar' ? '20px' : 'unset'
								}"
							>
								{{ "lang_QAQC_approval_operation" | language }}
							</div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-1">
									<p style="margin: 0; color: #20a620 !important">
										Request was Approved
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-expansion-panel>
</mat-accordion>
