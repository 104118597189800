import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AvailableUnitsComponent } from "./available-units/available-units.component";
import { InitialDeliveryListAddComponent } from "./initial-delivery-list-add/initial-delivery-list-add.component";

@Component({
	selector: "app-initial-delivery-list",
	templateUrl: "./initial-delivery-list.component.html",
	styleUrls: ["./initial-delivery-list.component.scss"],
})
export class InitialDeliveryListComponent implements OnInit {
	@Input() supervision_data: any;
	@Input() able2add: any;
	@Input() able2edit: any;
	@Input() project_data: any;
	@Input() makeActionInitial: any;
	lang_key = localStorage.getItem("lang_key") || "en";
	group1List = [];
	selected_group1 = null;
	group2List = [];
	selected_group2 = null;
	searchKey = "";
	initial_delivery_list = [];
	page_no = 1;
	page_size = 10;
	collection_size = 0;
	constructor(
		private spinner: PreloaderService,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.get_initial_delivery_list();
	}

	get_initial_delivery_list() {
		let param = new FormData();
		param.append("search_key", this.searchKey);
		param.append("projects_profile_id", this.project_data?.projects_profile_id);
		param.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id
		);
		param.append("initial_delivery_id", "");
		this.spinner.show();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(param),
				`p/snag/listinitialdelivery/${this.page_no}/${this.page_size}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.initial_delivery_list = res.records;
						this.collection_size = res.page?.total_records;
					} else {
						this.initial_delivery_list = [];
						this.collection_size = 0;
					}
				},
				(err) => {
					this.spinner.hide();
					this.initial_delivery_list = [];
					this.collection_size = 0;
				}
			);
	}
	createUpdateInitialDelivery() {
		this.dialog
			.open(InitialDeliveryListAddComponent, {
				width: "900px",
				data: {
					projects_profile_id: this.project_data?.projects_profile_id,
					projects_work_area_id: this.supervision_data?.projects_work_area_id,
					projects_supervision_id:
						this.supervision_data?.projects_supervision_id,
				},
			})
			.afterClosed()
			.subscribe((res) => {
				if (res) {
					this.get_initial_delivery_list();
				}
			});
	}

	deleteInitialDelivery(id) {
		this.sweetAlert
			.confirmMessage(
				this.lang.transform("lang_are_you_sure"),
				this.lang.transform("lang_yes")
			)
			.then((res) => {
				if (res?.isConfirmed) {
					this.spinner.show();
					this.ds
						.deleteActionByUrl(
							[id],
							`p/snag/deleteinitialdelivery/${this.project_data?.projects_profile_id}/${this.supervision_data?.projects_work_area_id}`
						)
						.subscribe(
							(res) => {
								this.spinner.hide();
								if (res.status) {
									this.sweetAlert.successToast(res.msg, 2000);
									this.get_initial_delivery_list();
								} else {
									this.sweetAlert.errorToast(res.message, 2000);
								}
							},
							(err) => {
								this.spinner.hide();
								this.sweetAlert.errorToast(
									err && err.error && err.error.error
										? err.error.error
										: this.lang.transform("lang_internal_server_error"),
									2000
								);
							}
						);
				}
			});
	}

	acceptedUnacceptedUnits() {
		const dialogRef = this.dialog.open(AvailableUnitsComponent, {
			width: "700px",
			autoFocus: false,
			closeOnNavigation: true,
			data: {
				projects_profile_id: this.project_data?.projects_profile_id,
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
				projects_supervision_id: this.supervision_data?.projects_supervision_id,
			},
		});
	}
}
