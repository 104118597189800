import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
declare var $: any;
@Component({
	selector: "app-account-settings",
	templateUrl: "./account-settings.component.html",
	styleUrls: ["./account-settings.component.scss"],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
	modalRef1: BsModalRef;
	modalRef: BsModalRef | null;
	modalRef2: BsModalRef;
	@ViewChild("viewsettings") dataview;
	@ViewChild("SettingsOperation") dataedit;
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}
	openModal2(template: TemplateRef<any>) {
		this.modalRef2 = this.modalService.show(template, {
			id: 2,
			class: "second",
		});
	}
	settings = environment.multipledropdown;
	accounttypes: any = [];
	types: any = [];
	aspage_no = 1;
	asviewdata: any = [];
	branch_id: any =
		localStorage.getItem("selectedBranch") != null
			? localStorage.getItem("selectedBranch")
			: "0";
	aspage_size = "10";
	asdata: any = [];
	accounts: any = [];
	able2delete = JSON.parse(this.ls.getItem("is_admin"));
	form: FormGroup;
	lodingdatas = this.lang.transform("lang_loading");
	searchKey = "";
	searchType = "";
	finance_id = null;
	constructor(
		public ds: DataService,
		public ls: LocalStorage,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public fb: FormBuilder,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.account_setting_form();
		if (this.branch_id && this.branch_id != "0") {
			this.get_account_settings();
			this.get_account_types();
		}
	}
	public changeBranch(branch) {
		this.spinner.show();
		this.branch_id = branch;
		this.load_account_settings();
		this.get_account_types();
	}
	onChangeFinance(finance_id) {
		this.finance_id = finance_id;
	}
	public emptyformdata() {
		this.accounts = [];
		this.form.reset();
		this.form.get("branch_id").setValue(this.branch_id);
	}
	private subscriptions = new Subscription();
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
	public emptyvalue(value) {
		this.form.get("setting_tax_value").setValue(null);
		this.form.get("account_config").setValue(null);
		this.form.get("setting_parent").setValue(null);
		this.types = [];
		if (value == "BLS") {
			this.spinner.show();
			this.subscriptions.add(
				this.ds
					.getActionByUrl([], "gcs/accounts_view/" + this.branch_id + "/0/")
					.subscribe((data) => {
						this.spinner.hide();
						if (data.status) {
							this.types = data.records;
						}
					})
			);
		}
	}
	public showASData(data) {
		this.asviewdata = [];
		this.spinner.show();
		this.subscriptions.add(
			this.ds
				.getActionByUrl(
					[],
					"vs/" + this.branch_id + "/" + data.account_setting_id
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.asviewdata = res.records[0];
							this.modalRef1 = this.modalService.show(this.dataview);
						} else {
							this.modalRef1.hide();
							this.ds.dialogf("", res.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.modalRef1.hide();
						this.ds.dialogf(
							"",
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)
		);
	}
	public editASData(id) {
		this.spinner.show();
		this.form.reset();
		this.subscriptions.add(
			this.ds.getActionByUrl([], "es/" + this.branch_id + "/" + id).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.form.patchValue(res.records[0]);
						this.modalRef = this.modalService.show(this.dataedit);
					} else {
						this.modalRef.hide();
						this.ds.dialogf("", res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.modalRef.hide();
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			)
		);
	}
	public submit() {
		if (this.form.get("account_setting_id").value) {
			this.putform();
		} else {
			this.postform();
		}
	}
	public postform() {
		this.spinner.show();
		let data = new FormData();
		data.append(
			"branch_id",
			this.form.get("branch_id").value ? this.form.get("branch_id").value : ""
		);
		data.append(
			"setting_type_code",
			this.form.get("setting_type_code").value
				? this.form.get("setting_type_code").value
				: ""
		);
		data.append(
			"setting_parent",
			this.form.get("setting_parent").value
				? this.form.get("setting_parent").value
				: ""
		);
		data.append(
			"setting_title_english",
			this.form.get("setting_title_english").value
				? this.form.get("setting_title_english").value
				: ""
		);
		data.append(
			"setting_title_arabic",
			this.form.get("setting_title_arabic").value
				? this.form.get("setting_title_arabic").value
				: ""
		);
		data.append(
			"setting_key_code",
			this.form.get("setting_key_code").value
				? this.form.get("setting_key_code").value
				: ""
		);
		data.append(
			"setting_tax_value",
			this.form.get("setting_tax_value").value
				? this.form.get("setting_tax_value").value
				: ""
		);
		data.append(
			"setting_status",
			this.form.get("setting_status").value
				? this.form.get("setting_status").value
				: ""
		);
		data.append(
			"account_config",
			this.ds.select_array_string(this.form, "account_config", "value")
		);
		data.append(
			"cost_center_required",
			this.form.get("setting_type_code").value == "ACC"
				? this.form.get("cost_center_required").value
				: "0"
		);
		this.subscriptions.add(
			this.ds.postActionByUrl(data, "cs").subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.form.reset();
						this.get_account_settings();
						this.alert.success(res.msg);
						setTimeout(() => {
							this.modalRef.hide();
						}, 2000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			)
		);
	}
	public putform() {
		this.spinner.show();
		let data = new FormData();
		data.append(
			"account_setting_id",
			this.form.get("account_setting_id").value || ""
		);
		data.append("branch_id", this.form.get("branch_id").value || "");
		data.append(
			"setting_type_code",
			this.form.get("setting_type_code").value || ""
		);
		data.append("setting_parent", this.form.get("setting_parent").value || "");
		data.append(
			"setting_title_english",
			this.form.get("setting_title_english").value || ""
		);
		data.append(
			"setting_title_arabic",
			this.form.get("setting_title_arabic").value || ""
		);
		data.append(
			"setting_key_code",
			this.form.get("setting_key_code").value || ""
		);
		data.append(
			"setting_tax_value",
			this.form.get("setting_tax_value").value || ""
		);
		data.append("setting_status", this.form.get("setting_status").value || "");
		data.append(
			"account_config",
			this.ds.select_array_string(this.form, "account_config", "value")
		);
		data.append(
			"cost_center_required",
			this.form.get("setting_type_code").value == "ACC"
				? this.form.get("cost_center_required").value
				: "0"
		);
		this.subscriptions.add(
			this.ds.putActionByUrl(this.ds.formData2string(data), "us").subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.get_account_settings();
						this.form.reset();
						this.alert.success(res.msg);
						setTimeout(() => {
							this.modalRef.hide();
						}, 2000);
					} else {
						this.alert.error(res.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			)
		);
	}
	public searchAccounts(evt: any) {
		this.accounts = [];
		if (this.form.get("branch_id").value) {
			let param = new FormData();
			param.append("branch_id", this.form.get("branch_id").value);
			param.append("finance_id", this.finance_id || "");
			param.append("search_text", evt.target.value);
			let permit_key = this.form.get("account_setting_id").value
				? "accounts_edit"
				: "accounts_add";
			this.subscriptions.add(
				this.ds
					.postActionByUrl(param, "sam/" + permit_key)
					.subscribe((data) => {
						if (data.status) {
							this.accounts = data.records;
						}
					})
			);
		} else {
			this.ds.dialogf("", this.lang.transform("lang_choose_branch"));
		}
	}
	public get_account_types() {
		this.accounttypes = [];
		this.spinner.show();
		this.subscriptions.add(
			this.ds.getActionByUrl([], "at").subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.accounttypes = res.records;
					}
				},
				(error) => {
					this.spinner.hide();
				}
			)
		);
	}
	public account_setting_form() {
		this.form = new FormGroup({
			account_setting_id: new FormControl(""),
			branch_id: new FormControl(this.branch_id, [Validators.required]),
			setting_type_code: new FormControl("", [Validators.required]),
			setting_parent: new FormControl(""),
			setting_title_english: new FormControl("", [Validators.required]),
			setting_title_arabic: new FormControl("", [Validators.required]),
			setting_key_code: new FormControl("", [Validators.required]),
			setting_tax_value: new FormControl(""),
			setting_status: new FormControl(1, [Validators.required]),
			account_config: new FormControl(""),
			cost_center_required: new FormControl(""),
		});
	}
	public loadpageas(page) {
		this.spinner.show();
		this.aspage_no = page;
		this.aspage_size = this.aspage_size;
		this.get_account_settings();
	}
	public loadapagesize(size) {
		this.spinner.show();
		this.aspage_no = 1;
		this.aspage_size = size;
		this.get_account_settings();
	}
	public load_account_settings() {
		this.aspage_no = 1;
		this.aspage_size = "10";
		this.get_account_settings();
	}
	public get_account_settings() {
		let formdata = new FormData();
		if (this.branch_id) {
			this.branch_id = this.branch_id;
		} else {
			this.branch_id = "0";
		}
		formdata.append("search_key", this.searchKey);
		formdata.append("search_type", this.searchType);
		this.subscriptions.add(
			this.ds
				.postActionByUrl(
					formdata,
					"ls/" + this.branch_id + "/" + this.aspage_no + "/" + this.aspage_size
				)
				.subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.asdata = res;
						} else {
							this.asdata = [];
							this.lodingdatas = res.error;
						}
					},
					(error) => {
						this.spinner.hide();
						this.asdata = [];
						this.lodingdatas =
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error");
					}
				)
		);
	}
	public checkAllItemsSet(event) {
		if (event.target.checked) {
			$(".accounts_settings .eachItemSet").prop("checked", true);
			$(".accounts_settings .makeback").addClass("bground");
			$(".accounts_settings .deleteIconDiv").show();
		} else {
			$(".accounts_settings .eachItemSet").prop("checked", false);
			$(".accounts_settings .makeback").removeClass("bground");
			$(".accounts_settings .deleteIconDiv").hide();
		}
	}
	public checkEachItemSet(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".accounts_settings .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".accounts_settings .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".accounts_settings .eachItemSet:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".accounts_settings .eachItemSet").each(function () {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".accounts_settings .deleteIconDiv").show();
		} else {
			$(".accounts_settings .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".accounts_settings #allItemsSet").prop("checked", true);
		} else {
			$(".accounts_settings #allItemsSet").prop("checked", false);
		}
	}
	public deleteBulkDataSet() {
		const checkedtotal = [];
		$(".accounts_settings .eachItemSet:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: "400px",
				data: {
					title: this.lang.transform("lang_confirmation"),
					message: this.lang.transform("lang_are_you_sure"),
				},
			});
			this.subscriptions.add(
				dialogRef.afterClosed().subscribe((confirmed: boolean) => {
					if (confirmed) {
						this.spinner.show();
						this.subscriptions.add(
							this.ds
								.deleteActionByUrl(checkedtotal, "ds/" + this.branch_id)
								.subscribe(
									(data) => {
										this.spinner.hide();
										if (data.status) {
											this.get_account_settings();
											$(".accounts_settings #allItemsSet").prop(
												"checked",
												false
											);
											$(".accounts_settings .deleteIconDiv").hide();
											this.ds.dialogf("", data.msg);
										} else {
											this.ds.dialogf("", data.error);
										}
									},
									(error) => {
										this.ds.dialogf(
											"",
											error && error.error && error.error.error
												? error.error.error
												: this.lang.transform("lang_internal_server_error")
										);
										this.spinner.hide();
									}
								)
						);
					}
				})
			);
		} else {
			this.ds.dialogf();
		}
	}
}
