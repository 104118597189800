import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { MnabrthemeService } from '@core/bootstrap/mnabrtheme.service';
import { environment } from '@env/environment';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BrandingComponent } from '@theme/header/widgets/branding.component';
declare var $:any;
@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {
  @ViewChild(BrandingComponent) 
  logo : BrandingComponent;
  acolor = environment.theme.acolor;
  bcolor = environment.theme.bcolor;
  ccolor = environment.theme.ccolor;
  dcolor = environment.theme.dcolor;
  fg: FormGroup;
  constructor(private fb: FormBuilder, public ds:DataService, public spinner:PreloaderService, public lang:LanguagePipe, public theme : MnabrthemeService) {
    this.fg = this.fb.group({
      acolor: [this.acolor, Validators.required],
      bcolor: [this.bcolor, Validators.required],
      ccolor: [this.ccolor, Validators.required],
      dcolor: [this.dcolor, Validators.required],
      action: ['SET']
    });
  }
  ngOnInit(): void {
    this.gettheme();
  }
  gettheme() {
    this.ds.getActionByUrl([], 'gettheme').subscribe(data => {
      if (data.status) {
        this.acolor = data.data.acolor;
        this.bcolor = data.data.bcolor;
        this.ccolor = data.data.ccolor;
        this.dcolor = data.data.dcolor;
      }
    });
  }
  reset() {
    this.onSubmit('RESET');
  }
  onSubmit(action = 'SET') {
    this.spinner.show();
    let param = new FormData();
      param.append('acolor', this.acolor);
      param.append('bcolor', this.bcolor);
      param.append('ccolor', this.ccolor);
      param.append('dcolor', this.dcolor);
      param.append('action', action);
      this.ds.postActionByUrl(param, 'changetheme').subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          //this.theme.load();
          window.location.reload();
          //this.logo.load_theme_logo();
          //this.ds.dialogf('', res.msg);
        } else {
          this.ds.dialogf('', res.error);
        }
      }, error=> {
        this.spinner.hide();
        this.ds.dialogf('', error.error.error);  
      });
  }
}
