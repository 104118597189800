<div class="card supervision_implementation_phases">
	<div class="card-header d-flex align-items-center justify-content-between">
		<span>{{ "lang_implementation_phases" | language }}</span>
		<div>
			<i
				*ngIf="nodes.length != '0'"
				matTooltip="{{ 'lang_view' | language }}"
				(click)="show_add_phases('VIEW')"
				class="fa fa-eye s2icon mnabricon"
			></i>
			<i
				*ngIf="makeAction"
				matTooltip="{{ 'lang_add' | language }}"
				(click)="show_add_phases('ADD')"
				class="fa fa-plus s2icon mnabricon"
			></i>
			<i
				*ngIf="makeAction"
				matTooltip="{{ 'lang_edit' | language }}"
				(click)="show_add_phases('EDIT')"
				class="fa fa-pencil-square-o s2icon mnabricon"
			></i>
			<i
				*ngIf="makeAction"
				matTooltip="{{ 'lang_delete' | language }}"
				(click)="show_delete_phases()"
				class="fa fa-trash-o s2icon red"
			></i>
			<i
				class="fa fa-cog s2icon mnabricon"
				(click)="IphasesDialog()"
				*ngIf="makeAction"
			></i>
			<mat-slide-toggle
				class="ml-3 mr-3"
				[(ngModel)]="showCustomTitles"
				(change)="onChangeCustomTitle()"
				>{{ "lang_custom_titles" | language }}</mat-slide-toggle
			>
		</div>
	</div>
	<div class="card-body pad0" *ngIf="viewtype == 'VIEW'">
		<div class="mbrl15 martb0">
			<div class="row" *ngIf="phaseData.length != 0">
				<div
					class="col-12 mb-10"
					*ngIf="phaseData.implementation_phases_status != '1'"
				>
					<b class="red"
						>{{ "lang_phase_not_completed" | language }} :
						{{ phaseData.total_no_of_zones }}</b
					>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-3">
					<b
						>{{ "lang_total_zones" | language }} :
						{{ phaseData.total_no_of_zones }}</b
					>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-3">
					<b
						>{{ "lang_total_blocks" | language }} :
						{{ phaseData.total_no_of_blocks }}</b
					>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-3">
					<b
						>{{ "lang_total_clusters" | language }} :
						{{ phaseData.total_no_of_clusters }}</b
					>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-3">
					<b
						>{{ "lang_total_units" | language }} :
						{{ phaseData.total_no_of_units }}</b
					>
				</div>
			</div>
			<div class="row">
				<div class="col-12 mt9">
					<div class="input-group input-group-sm mb-10">
						<input
							name="account_search"
							id="filter"
							#filter
							class="enablecode h34 form-control almnabrformcontrol"
							type="text"
							(keyup)="tree.treeModel.filterNodes(filter.value)"
							autocomplete="off"
							placeholder="{{ 'lang_search' | language }}"
						/>
						<span class="input-group-btn">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_clear' | language }}"
								class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-times red blue-tooltip"
								(click)="filter.value = ''; clear(tree.treeModel)"
							></button>
						</span>
						<span class="input-group-btn">
							<button
								type="button"
								matTooltip="{{ 'lang_refresh' | language }}"
								class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-refresh red blue-tooltip"
								(click)="
									phase_parent_id = '0';
									get_implementation_phases();
									clear(tree.treeModel)
								"
							></button>
						</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-3 mb-4" *ngIf="!hideTreeMenu">
					<div>
						<div class="tree-title">
							<span>Tree Menu</span>
							<i class="fa fa-close" (click)="onHideTreeMenu(true)"></i>
						</div>
						<div class="tree-body">
							<div class="contractor-tree">
								<tree-root
									#tree
									[options]="options"
									[nodes]="nodes"
									(toggleExpanded)="onToggleTree($event)"
									(activate)="toggleViewAction(tree)"
									(deactivate)="deactivate($event)"
								>
									<ng-template #treeNodeTemplate let-node let-index="index">
										<span
											*ngIf="node?.data?.phase_type == 'Z' && showCustomTitles"
											>{{ node?.data?.phase_zone_custom_title || "---" }}</span
										>
										<span
											*ngIf="node?.data?.phase_type == 'Z' && !showCustomTitles"
											>{{ node?.data?.name || "---" }}</span
										>
										<span
											*ngIf="node?.data?.phase_type == 'B' && showCustomTitles"
											>{{ node?.data?.phase_block_custom_title || "---" }}</span
										>
										<span
											*ngIf="node?.data?.phase_type == 'B' && !showCustomTitles"
											>{{ node?.data?.name || "---" }}</span
										>
										<span
											*ngIf="node?.data?.phase_type == 'C' && showCustomTitles"
											>{{
												node?.data?.phase_cluster_custom_title || "---"
											}}</span
										>
										<span
											*ngIf="node?.data?.phase_type == 'C' && !showCustomTitles"
											>{{ node?.data?.name || "---" }}</span
										>
										<span
											*ngIf="node?.data?.phase_type == 'U' && showCustomTitles"
											>{{
												node?.data?.phase_general_no_custom_title || "---"
											}}</span
										>
										<span
											*ngIf="node?.data?.phase_type == 'U' && !showCustomTitles"
											>{{ node?.data?.name || "---" }}</span
										>
									</ng-template>
								</tree-root>
							</div>
						</div>
					</div>
				</div>
				<div
					class=""
					[ngClass]="{
						'col-12 col-md-9': !hideTreeMenu,
						'col-12 col-md-12': hideTreeMenu
					}"
				>
					<div style="display: flex; align-items: center">
						<span class="show-tree-menu" *ngIf="hideTreeMenu">
							<i class="fa fa-expand" (click)="onHideTreeMenu(false)"></i>
						</span>
						<p
							innerHTML="{{ treePath }}"
							*ngIf="treePath && !hideTreeContent"
							class="tree-path"
						></p>
					</div>
					<div
						*ngIf="!hideTreeContent && selectedNode?.data?.phase_type == 'U'"
					>
						<mat-tab-group (selectedTabChange)="selectedListTabValue($event)">
							<mat-tab label="unit_dashboard">
								<ng-template mat-tab-label>{{
									"lang_dashboard" | language
								}}</ng-template>
								<div class="p-2">
									<div class="row">
										<div class="col-12 col-sm-6 mb-3">
											<div
												class="card-item"
												*ngIf="!count_data_loading"
												style="
													background: linear-gradient(
														60deg,
														#be26dae3,
														#c15000d1
													);
												"
											>
												<div class="item-icon">
													<i class="fa fa-building"></i>
												</div>
												<div class="item-text">
													<h5>{{ "lang_total_average" | language }}</h5>
													<h4>{{ unit_details_data?.total_average }}</h4>
												</div>
											</div>
											<ngx-skeleton-loader
												style="width: 100%"
												*ngIf="count_data_loading"
												count="1"
												appearance="circle"
												[theme]="{
													width: '100%',
													height: '70px',
													'border-radius': '10px'
												}"
											></ngx-skeleton-loader>
										</div>
										<div class="col-12 col-sm-6 mb-3" *ngIf="is_all_price">
											<div
												class="card-item"
												*ngIf="!count_data_loading"
												style="
													background: linear-gradient(60deg, #2644da, #006dc1);
												"
											>
												<div class="item-icon">
													<i class="fa fa-th-large"></i>
												</div>
												<div class="item-text">
													<h5>{{ "lang_total_balance" | language }}</h5>
													<h4>
														{{
															convertNumToCurrency(
																unit_details_data?.total_balance
															)
														}}
													</h4>
												</div>
											</div>
											<ngx-skeleton-loader
												style="width: 100%"
												*ngIf="count_data_loading"
												count="1"
												appearance="circle"
												[theme]="{
													width: '100%',
													height: '70px',
													'border-radius': '10px'
												}"
											></ngx-skeleton-loader>
										</div>
									</div>
									<div class="row pt-3">
										<div class="col-12">
											<mat-card class="mb-3" *ngIf="unit_details_data">
												<mat-card-title>{{
													"lang_unit_details" | language
												}}</mat-card-title>
												<mat-card-content>
													<div class="row">
														<div class="col-12 col-sm-6 col-md-4 mb-2">
															<p class="label-title">
																{{ "lang_unit" | language }}
															</p>
															<p>
																{{
																	selectedNode?.data
																		?.phase_general_no_custom_title
																}}
																(
																{{ selectedNode?.data?.name }}
																)
															</p>
														</div>
														<div class="col-12 col-sm-6 col-md-4 mb-2">
															<p class="label-title">
																{{ "lang_phase_short_code" | language }}
															</p>
															<p>
																{{ selectedNode?.data?.phase_short_code }}
															</p>
														</div>
														<div class="col-12 col-sm-6 col-md-4 mb-2">
															<p class="label-title">
																{{
																	"lang_project_main_total_amount" | language
																}}
															</p>
															<p>
																{{
																	convertNumToCurrency(
																		unit_details_data?.each_unit_cost_of_template
																	)
																}}
															</p>
														</div>
														<div class="col-12 col-sm-6 col-md-4 mb-2">
															<p class="label-title">
																{{ "lang_lang_total_opening" | language }}
															</p>
															<p>
																{{
																	convertNumToCurrency(
																		unit_details_data?.total_ratio_balance
																	)
																}}
															</p>
														</div>
														<div class="col-12 col-sm-6 col-md-4 mb-2">
															<p class="label-title">
																{{ "lang_total_ratio" | language }}
															</p>
															<p>
																{{
																	convertNumToCurrency(
																		unit_details_data?.total_ratio
																	)
																}}
															</p>
														</div>
														<div class="col-12 col-sm-6 col-md-4 mb-2">
															<p class="label-title">
																{{ "lang_total_approved_weight" | language }}
															</p>
															<p>
																{{
																	convertNumToCurrency(
																		unit_details_data?.total_approved_weight
																	)
																}}
															</p>
														</div>
														<div class="col-12 col-sm-6 col-md-4 mb-2">
															<p class="label-title">
																{{
																	"lang_total_approved_weight_price" | language
																}}
															</p>
															<p>
																{{
																	convertNumToCurrency(
																		unit_details_data?.total_approved_weight_price
																	)
																}}
															</p>
														</div>
													</div>
												</mat-card-content>
											</mat-card>
											<mat-card>
												<mat-card-title>
													{{ "lang_total_forms" | language }}
												</mat-card-title>
												<mat-card-content>
													<div class="row">
														<div class="col-12">
															<div class="forms-chart" id="forms-chart"></div>
														</div>
													</div>
												</mat-card-content>
											</mat-card>
										</div>
										<div class="col-12 mt-3">
											<mat-card>
												<mat-card-title class="d-flex justify-content-between">
													<span>{{ "lang_compeletion_rate" | language }}</span>
													<span>
														<i
															class="fa fa-filter mnabricon"
															matTooltip="{{
																'lang_advanced_filter' | language
															}}"
															(click)="openAverageFilters()"
														></i>
													</span>
												</mat-card-title>
												<mat-card-content>
													<div class="row">
														<div class="col-12">
															<div class="row">
																<div class="col-12 col-md-3 mb-4">
																	<div
																		class="card-item"
																		style="
																			background: linear-gradient(
																				60deg,
																				#688bd7,
																				#35aacd
																			);
																		"
																	>
																		<div class="item-icon">
																			<i class="fa fa-calendar"></i>
																		</div>
																		<div
																			class="item-text"
																			style="align-items: start; width: 100%"
																		>
																			<h5
																				style="
																					font-size: 16px;
																					text-transform: capitalize;
																					letter-spacing: normal;
																					font-weight: 600;
																				"
																			>
																				{{ "lang_from" | language }} :
																				{{ from_date_compeletion }}
																			</h5>
																			<h4
																				style="
																					font-size: 16px;
																					font-weight: 600;
																				"
																			>
																				{{ "lang_to" | language }} :
																				{{ to_date_compeletion }}
																			</h4>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="col-12">
															<div
																id="division-result-chart"
																class="division-result-chart"
															></div>
														</div>
													</div>
												</mat-card-content>
											</mat-card>
										</div>
									</div>
								</div>
							</mat-tab>
							<mat-tab label="unit_requests">
								<ng-template mat-tab-label>{{
									"lang_unit_requests" | language
								}}</ng-template>
								<div class="p-2">
									<div class="row">
										<div class="col-12 col-sm-4 mb-2">
											<button
												class="albutton mnabrbutton"
												mat-raised-button
												(click)="toggleSort()"
											>
												<i class="fa fa-sort"></i>
												{{ "lang_sorting_order" | language }}
											</button>
										</div>
										<div class="col-12 col-sm-4 mb-2">
											<button
												class="albutton mnabrbutton"
												mat-raised-button
												(click)="toggleFilter()"
											>
												<i class="fa fa-filter"></i>
												{{ "lang_advanced_filter" | language }}
											</button>
										</div>
										<div class="col-12 col-sm-4 mb-2">
											<button
												class="albutton mnabrbutton"
												mat-raised-button
												(click)="exportExcel()"
												*ngIf="showExel && TransactionsData.length"
											>
												<i class="fa fa-file-excel-o"></i>
												{{ "lang_export_excel" | language }}
											</button>
										</div>
									</div>
									<div class="row pt-3">
										<div
											[ngClass]="{
												'col-12 col-md-8': showFilter || showSort,
												'col-12': !showFilter || !showSort
											}"
											[attr.style]="isMob ? 'order:2' : 'order:1'"
										>
											<div class="row">
												<ng-container
													*ngFor="let item of TransactionsData; let i = index"
												>
													<div
														[ngClass]="{
															'col-12 col-sm-6 col-md-4':
																!showFilter || !showSort,
															'col-12 col-sm-6 col-md-6': showFilter || showSort
														}"
													>
														<mat-card
															class="card"
															[style.border-left-color]="
																item?.color == 'GREEN'
																	? '#28a745 !important'
																	: item?.color == 'RED'
																	? '#dc3545 !important'
																	: '#fff !important'
															"
														>
															<mat-card-title class="card-title">
																<div class="d-flex">
																	<span *ngIf="item?.result_code">
																		<span class="result_code">{{
																			item?.result_code
																		}}</span></span
																	>
																	<div
																		class="ml-3 mr-3"
																		*ngIf="item?.file_path"
																	>
																		<i
																			class="fa fa-file-pdf-o"
																			style="color: #0e3768; font-size: 25px"
																			matTooltip="{{
																				'lang_preview' | language
																			}}"
																			(click)="
																				ds?.preview_pdf_file(
																					item?.file_path,
																					item?.group_type_name
																				)
																			"
																		></i>
																	</div>
																</div>
																<mat-menu
																	#menu="matMenu"
																	[overlapTrigger]="false"
																	xPosition="{{
																		lang_key == 'en' ? 'before' : 'after'
																	}}"
																>
																	<button
																		mat-menu-item
																		routerLink="/transactions/form/{{
																			item?.transaction_key
																		}}/vr/{{ item?.transaction_request_id }}"
																	>
																		<mat-icon
																			style="
																				color: rgba(0, 0, 0, 0.54) !important;
																			"
																			>assignment</mat-icon
																		>
																		<span>{{ "lang_view" | language }}</span>
																	</button>
																</mat-menu>
																<button
																	mat-icon-button
																	[matMenuTriggerFor]="menu"
																>
																	<i class="fa fa-ellipsis-v"></i>
																</button>
															</mat-card-title>
															<mat-card-content>
																<div class="row pt-3">
																	<div class="col-12">
																		<div class="card-body-table">
																			<table style="width: 100%">
																				<tr *ngIf="item?.group_type_name">
																					<td
																						colspan="2"
																						style="
																							text-align: center;
																							text-transform: uppercase;
																							color: #0e3768 !important;
																							font-weight: 600;
																						"
																					>
																						{{ item?.group_type_name }}
																					</td>
																				</tr>
																				<tr>
																					<th>
																						{{
																							"lang_platform_title" | language
																						}}
																					</th>
																					<td>
																						<span
																							*ngIf="
																								item?.platform_name?.length >=
																								30
																							"
																							matTooltip="{{
																								item?.platform_name
																							}}"
																							>{{
																								item?.platform_name
																									| shortenLength: 30
																							}}</span
																						>
																						<span
																							*ngIf="
																								item?.platform_name?.length <=
																								30
																							"
																							>{{ item?.platform_name }}</span
																						>
																					</td>
																				</tr>
																				<tr>
																					<th>
																						{{ "lang_group_one" | language }}
																					</th>
																					<td>{{ item?.group1_name }}</td>
																				</tr>
																				<tr>
																					<th>
																						{{ "lang_group_two" | language }}
																					</th>
																					<td>{{ item?.group2_name }}</td>
																				</tr>
																				<tr>
																					<th>
																						{{
																							"lang_platform_code_system"
																								| language
																						}}
																					</th>
																					<td style="direction: ltr">
																						<span style="direction: ltr">{{
																							item?.platform_code_system
																						}}</span>
																					</td>
																				</tr>
																				<tr>
																					<th>{{ "lang_unit" | language }}</th>
																					<td>{{ item?.unit_id }}</td>
																				</tr>
																				<tr>
																					<th>
																						{{ "lang_by_phases" | language }}
																					</th>
																					<td>{{ item?.phase_short_code }}</td>
																				</tr>
																				<tr>
																					<th>{{ "lang_level" | language }}</th>
																					<td>
																						{{ item?.level_name }}
																					</td>
																				</tr>
																				<tr>
																					<th>
																						{{ "lang_barcode" | language }}
																					</th>
																					<td style="direction: ltr">
																						<span style="direction: ltr">{{
																							item?.barcode
																						}}</span>
																					</td>
																				</tr>
																				<tr>
																					<th>
																						{{
																							"lang_request_number" | language
																						}}
																					</th>
																					<td>
																						{{ item?.transaction_request_id }}
																					</td>
																				</tr>
																				<tr>
																					<th>
																						{{
																							"lang_last_step_opened" | language
																						}}
																					</th>
																					<td>
																						{{
																							item?.transaction_request_last_step
																						}}
																					</td>
																				</tr>
																			</table>
																		</div>
																	</div>
																</div>
															</mat-card-content>
														</mat-card>
													</div>
												</ng-container>
												<div
													class="col-12"
													style="
														min-height: 200px;
														display: flex;
														align-items: center;
														justify-content: center;
														font-size: 20px;
													"
													*ngIf="TransactionsData.length == 0"
												>
													<p>{{ "lang_no_data" | language }}</p>
												</div>
											</div>
											<div class="row pt-2">
												<div
													class="col-12 col-sm-4 d-flex align-items-center"
													*ngIf="TransactionsData.length != 0"
												>
													<p style="font-size: 16px; margin: 0">
														{{ "lang_total" | language }}: {{ collection_size }}
													</p>
												</div>
												<div
													class="col-12 col-sm-6 mt-2 mt-sm-0 d-flex align-items-center justify-content-center"
													*ngIf="TransactionsData.length != 0"
												>
													<ngb-pagination
														[collectionSize]="collection_size"
														[(page)]="page_no"
														[maxSize]="paginationMaxSize"
														[pageSize]="page_size"
														[boundaryLinks]="true"
														[rotate]="true"
														(pageChange)="onPageChange()"
													></ngb-pagination>
												</div>
												<div
													class="col-7 col-sm-2 mt-2 mt-sm-0 d-flex align-items-center justify-content-end"
													*ngIf="TransactionsData.length != 0"
												>
													<mat-select
														[(value)]="page_size"
														(selectionChange)="load_by_page_size($event)"
													>
														<mat-option [value]="10"
															>10 {{ "lang_per_page" | language }}</mat-option
														>
														<mat-option [value]="20"
															>20 {{ "lang_per_page" | language }}</mat-option
														>
														<mat-option [value]="50"
															>50 {{ "lang_per_page" | language }}</mat-option
														>
														<mat-option [value]="100"
															>100 {{ "lang_per_page" | language }}</mat-option
														>
													</mat-select>
												</div>
											</div>
										</div>
										<div
											class="col-12 col-md-4"
											*ngIf="showFilter && !showSort"
											[attr.style]="isMob ? 'order:1' : 'order:2'"
										>
											<mat-card class="card">
												<mat-card-title class="card-title">
													<h4>{{ "lang_advanced_filter" | language }}</h4>
												</mat-card-title>
												<mat-card-content>
													<div class="row">
														<div class="col-12">
															<label for="filter">{{
																"lang_filter" | language
															}}</label>
															<ng-select
																[items]="filterList"
																class="customSelect"
																id="filter"
																name="filter"
																#filterSelect
																placeholder="Select Filter"
																bindLabel="filter_name"
																bindValue="filter_id"
																(change)="getFilterDetails($event)"
																[(ngModel)]="selectedFilter"
																[notFoundText]="'lang_no_data' | language"
															>
																<ng-template
																	ng-option-tmp
																	let-item="item"
																	let-index="index"
																>
																	<b>{{ item.filter_name }}</b>
																	<span (click)="removeFilter($event, item)"
																		><i class="fa fa-trash red"></i
																	></span>
																</ng-template>
															</ng-select>
														</div>
														<div class="col-12">
															<label for="filter_name">{{
																"lang_filter_name" | language
															}}</label>
															<input
																type="text"
																class="form-control"
																id="filter_name"
																name="filter_name"
																[(ngModel)]="filter_name"
															/>
														</div>
													</div>
													<form [formGroup]="filterForm">
														<div class="row">
															<div class="col-12 mt-2">
																<mat-form-field
																	appearance="outline"
																	style="width: 100%"
																>
																	<mat-label>{{
																		"lang_date" | language
																	}}</mat-label>
																	<mat-date-range-input [rangePicker]="picker">
																		<input
																			matStartDate
																			formControlName="transaction_start_date"
																			placeholder="YYYY/MM/DD"
																			readonly
																			id="transaction_start_date"
																			name="transaction_start_date"
																		/>
																		<input
																			matEndDate
																			formControlName="transaction_end_date"
																			placeholder="YYYY/MM/DD"
																			readonly
																			id="transaction_end_date"
																			name="transaction_end_date"
																		/>
																	</mat-date-range-input>
																	<mat-datepicker-toggle
																		matSuffix
																		[for]="picker"
																	></mat-datepicker-toggle>
																	<mat-date-range-picker
																		#picker
																	></mat-date-range-picker>
																</mat-form-field>
															</div>
															<div class="col-12 mt-1">
																<label for="form_request_id">{{
																	"lang_transaction_number" | language
																}}</label>
																<input
																	type="text"
																	class="form-control"
																	id="form_request_id"
																	name="form_request_id"
																	(keypress)="isNumber($event)"
																	formControlName="form_request_id"
																/>
															</div>
															<div class="col-12 mt-1">
																<div
																	class="box"
																	style="
																		border: 2px solid rgba(165, 161, 165, 0.467);
																		margin: 5px 0;
																		padding: 10px;
																		border-radius: 5px;
																	"
																>
																	<div class="row">
																		<div class="col-12 mt-1">
																			<div
																				class="d-flex justify-content-between mb-2"
																			>
																				<span>{{
																					"lang_template_id" | language
																				}}</span>
																				<i
																					class="fa fa-plus mnabricon"
																					(click)="openTemplateFilter()"
																				></i>
																			</div>
																			<mat-chip-list>
																				<mat-chip
																					color="primary"
																					*ngFor="
																						let item of template;
																						let i = index
																					"
																					(removed)="removeTemplate(i)"
																				>
																					{{ item?.label }}
																					<button matChipRemove>
																						<mat-icon>cancel</mat-icon>
																					</button>
																				</mat-chip>
																				<div
																					*ngIf="template?.length == 0"
																					style="
																						font-size: 12px;
																						color: #333333d6;
																						margin: 0 5px;
																					"
																				>
																					{{ "lang_no_data" | language }}
																				</div>
																			</mat-chip-list>
																		</div>
																		<div class="col-12 mt-1">
																			<label for="group1_id">{{
																				"lang_group_one" | language
																			}}</label>
																			<mat-chip-list>
																				<mat-chip
																					color="primary"
																					*ngFor="
																						let item of group1;
																						let i = index
																					"
																					(removed)="removeGroup1(i)"
																				>
																					{{ item?.label }}
																					<button matChipRemove>
																						<mat-icon>cancel</mat-icon>
																					</button>
																				</mat-chip>
																				<div
																					*ngIf="group1?.length == 0"
																					style="
																						font-size: 12px;
																						color: #333333d6;
																						margin: 0 5px;
																					"
																				>
																					{{ "lang_no_data" | language }}
																				</div>
																			</mat-chip-list>
																		</div>
																		<div class="col-12 mt-1">
																			<label for="group_type_id">{{
																				"lang_group_type" | language
																			}}</label>
																			<mat-chip-list>
																				<mat-chip
																					color="primary"
																					*ngFor="
																						let item of group_type;
																						let i = index
																					"
																					(removed)="removeGroupType(i)"
																				>
																					{{ item?.label }}
																					<button matChipRemove>
																						<mat-icon>cancel</mat-icon>
																					</button>
																				</mat-chip>
																				<div
																					*ngIf="group_type?.length == 0"
																					style="
																						font-size: 12px;
																						color: #333333d6;
																						margin: 0 5px;
																					"
																				>
																					{{ "lang_no_data" | language }}
																				</div>
																			</mat-chip-list>
																		</div>
																		<div class="col-12 mt-1">
																			<label for="group2_id">{{
																				"lang_group_two" | language
																			}}</label>
																			<mat-chip-list>
																				<mat-chip
																					color="primary"
																					*ngFor="
																						let item of group2;
																						let i = index
																					"
																					(removed)="removeGroup2(i)"
																				>
																					{{ item?.label }}
																					<button
																						matChipRemove
																						style="border: none"
																					>
																						<mat-icon
																							style="color: #000 !important"
																							>cancel</mat-icon
																						>
																					</button>
																				</mat-chip>
																				<div
																					*ngIf="group2?.length == 0"
																					style="
																						font-size: 12px;
																						color: #333333d6;
																						margin: 0 5px;
																					"
																				>
																					{{ "lang_no_data" | language }}
																				</div>
																			</mat-chip-list>
																		</div>
																	</div>
																</div>
															</div>

															<div class="col-12 mt-1">
																<label for="platform_code_system">{{
																	"lang_platform_code_system" | language
																}}</label>
																<input
																	type="text"
																	class="form-control"
																	id="platform_code_system"
																	name="platform_code_system"
																	formControlName="platform_code_system"
																/>
															</div>
															<div class="col-12 mt-1">
																<div
																	class="box"
																	style="
																		border: 2px solid rgba(165, 161, 165, 0.467);
																		margin: 5px 0;
																		padding: 10px;
																		border-radius: 5px;
																	"
																>
																	<div class="row">
																		<div class="col-12 mt-1">
																			<div
																				class="d-flex justify-content-between mb-2"
																			>
																				<label for="zone">{{
																					"lang_zone" | language
																				}}</label>
																				<i
																					class="fa fa-plus mnabricon"
																					(click)="openZoneFilter()"
																				></i>
																			</div>
																			<mat-chip-list>
																				<mat-chip
																					color="primary"
																					*ngFor="
																						let item of zone;
																						let i = index
																					"
																					(removed)="removeZone(i)"
																				>
																					{{ item?.label }}
																					<button matChipRemove>
																						<mat-icon>cancel</mat-icon>
																					</button>
																				</mat-chip>
																				<div
																					*ngIf="zone?.length == 0"
																					style="
																						font-size: 12px;
																						color: #333333d6;
																						margin: 0 5px;
																					"
																				>
																					{{ "lang_no_data" | language }}
																				</div>
																			</mat-chip-list>
																		</div>
																		<div class="col-12 mt-1">
																			<label for="block">{{
																				"lang_blocks" | language
																			}}</label>
																			<mat-chip-list>
																				<mat-chip
																					color="primary"
																					*ngFor="
																						let item of block;
																						let i = index
																					"
																					(removed)="removeBlock(i)"
																				>
																					{{ item?.label }}
																					<button matChipRemove>
																						<mat-icon>cancel</mat-icon>
																					</button>
																				</mat-chip>
																				<div
																					*ngIf="block?.length == 0"
																					style="
																						font-size: 12px;
																						color: #333333d6;
																						margin: 0 5px;
																					"
																				>
																					{{ "lang_no_data" | language }}
																				</div>
																			</mat-chip-list>
																		</div>
																		<div class="col-12 mt-1">
																			<label for="cluster">{{
																				"lang_clusters" | language
																			}}</label>
																			<mat-chip-list>
																				<mat-chip
																					color="primary"
																					*ngFor="
																						let item of cluster;
																						let i = index
																					"
																					(removed)="removeCluster(i)"
																				>
																					{{ item?.label }}
																					<button matChipRemove>
																						<mat-icon>cancel</mat-icon>
																					</button>
																				</mat-chip>
																				<div
																					*ngIf="cluster?.length == 0"
																					style="
																						font-size: 12px;
																						color: #333333d6;
																						margin: 0 5px;
																					"
																				>
																					{{ "lang_no_data" | language }}
																				</div>
																			</mat-chip-list>
																		</div>
																	</div>
																</div>
															</div>

															<div class="col-12 mt-1">
																<div
																	class="box"
																	style="
																		border: 2px solid rgba(165, 161, 165, 0.467);
																		margin: 5px 0;
																		padding: 10px;
																		border-radius: 5px;
																	"
																>
																	<label for="phase_short_code">{{
																		"lang_by_phases" | language
																	}}</label>
																	<input
																		type="text"
																		class="form-control mb-1"
																		id="phase_short_code"
																		name="phase_short_code"
																		formControlName="phase_short_code"
																	/>
																	<label for="unit_id">{{
																		"lang_general_numbers" | language
																	}}</label>
																	<input
																		type="text"
																		class="form-control mb-1"
																		id="unit_id"
																		name="unit_id"
																		(keypress)="isNumber($event)"
																		formControlName="unit_id"
																	/>
																	<label for="level_key">{{
																		"lang_level_key" | language
																	}}</label>
																	<ng-select
																		[items]="levels_arr"
																		[multiple]="false"
																		bindLabel="label"
																		bindValue="value"
																		appendTo="body"
																		clearAllText="Clear"
																		formControlName="level_key"
																		placeholder="{{
																			'lang_level_key' | language
																		}}"
																		[notFoundText]="'lang_no_data' | language"
																	></ng-select>
																</div>
															</div>
															<div class="col-12 mt-1">
																<label for="result_code">{{
																	"lang_result" | language
																}}</label>
																<ng-select
																	[items]="result_code_arr"
																	[multiple]="true"
																	bindLabel="label"
																	bindValue="value"
																	appendTo="body"
																	clearAllText="Clear"
																	formControlName="result_code"
																	placeholder="{{ 'lang_result' | language }}"
																	[notFoundText]="'lang_no_data' | language"
																>
																</ng-select>
															</div>
															<div class="col-12 mt-1">
																<label for="version">{{
																	"lang_status" | language
																}}</label>
																<ng-select
																	[items]="status_arr"
																	[multiple]="false"
																	bindLabel="label"
																	bindValue="value"
																	appendTo="body"
																	clearAllText="Clear"
																	formControlName="version"
																	placeholder="{{ 'lang_status' | language }}"
																	[notFoundText]="'lang_no_data' | language"
																>
																</ng-select>
															</div>
															<div class="col-12 mt-1">
																<label for="barcode">{{
																	"lang_barcode" | language
																}}</label>
																<input
																	type="text"
																	class="form-control"
																	id="barcode"
																	name="barcode"
																	formControlName="barcode"
																/>
															</div>

															<div
																class="col-12 mt-2 d-flex justify-content-between"
															>
																<button
																	type="button"
																	class="albutton"
																	style="width: 40% !important"
																	(click)="resetFilterForm()"
																>
																	{{ "lang_reset" | language }}
																</button>

																<div
																	class="btn-group"
																	role="group"
																	aria-label="Basic example"
																	style="width: 50% !important"
																>
																	<button
																		type="submit"
																		class="btn albutton filter-btn"
																		(click)="sendFilterForm()"
																	>
																		{{ "lang_submit" | language }}
																	</button>
																	<button
																		type="button"
																		class="btn albutton filter-btn"
																		(click)="saveFilter()"
																	>
																		{{ "lang_save" | language }}
																	</button>
																</div>
															</div>
														</div>
													</form>
												</mat-card-content>
											</mat-card>
										</div>
										<div
											class="col-12 col-md-4"
											*ngIf="!showFilter && showSort"
											[attr.style]="isMob ? 'order:1' : 'order:2'"
										>
											<mat-card class="card">
												<mat-card-title class="card-title">
													<h4>{{ "lang_sorting_order" | language }}</h4>
												</mat-card-title>
												<mat-card-content>
													<div class="row">
														<div class="col-12">
															<label for="filter">{{
																"lang_filter" | language
															}}</label>
															<ng-select
																[items]="filterList"
																class="customSelect"
																id="filter"
																name="filter"
																#filterSelectAtsort
																placeholder="Select Filter"
																bindLabel="filter_name"
																bindValue="filter_id"
																(change)="getFilterDetails($event)"
																[(ngModel)]="selectedFilter"
																[notFoundText]="'lang_no_data' | language"
															>
																<ng-template
																	ng-option-tmp
																	let-item="item"
																	let-index="index"
																>
																	<b>{{ item.filter_name }}</b>
																	<span (click)="removeFilter($event, item)"
																		><i class="fa fa-trash red"></i
																	></span>
																</ng-template>
															</ng-select>
														</div>
														<div class="col-12">
															<label for="filter_name">{{
																"lang_filter_name" | language
															}}</label>
															<input
																type="text"
																class="form-control"
																id="filter_name"
																name="filter_name"
																[(ngModel)]="filter_name"
															/>
														</div>
													</div>
													<form [formGroup]="sortForm">
														<div class="row">
															<div class="col-12">
																<label for="form_request_id">{{
																	"lang_transaction_number" | language
																}}</label>
																<ng-select
																	[items]="form_request_id_arr"
																	[multiple]="false"
																	bindLabel="label"
																	bindValue="value"
																	appendTo="body"
																	clearAllText="Clear"
																	formControlName="form_request_id"
																	placeholder="{{
																		'lang_transaction_number' | language
																	}}"
																	[notFoundText]="'lang_no_data' | language"
																></ng-select>
															</div>
															<div class="col-12 mt-1">
																<label for="template_id">{{
																	"lang_template_id" | language
																}}</label>
																<ng-select
																	[items]="template_id_arr"
																	[multiple]="false"
																	bindLabel="label"
																	bindValue="value"
																	appendTo="body"
																	clearAllText="Clear"
																	formControlName="template_id"
																	placeholder="{{
																		'lang_template_id' | language
																	}}"
																	[notFoundText]="'lang_no_data' | language"
																></ng-select>
															</div>

															<div class="col-12 mt-1">
																<label for="platform_code_system">{{
																	"lang_platform_code_system" | language
																}}</label>
																<ng-select
																	[items]="platform_code_system_arr"
																	[multiple]="false"
																	bindLabel="label"
																	bindValue="value"
																	appendTo="body"
																	clearAllText="Clear"
																	formControlName="platform_code_system"
																	placeholder="{{
																		'lang_platform_code_system' | language
																	}}"
																	[notFoundText]="'lang_no_data' | language"
																></ng-select>
															</div>
															<div class="col-12 mt-1">
																<label for="zone">{{
																	"lang_zone" | language
																}}</label>
																<ng-select
																	[items]="zone_arr"
																	[multiple]="false"
																	bindLabel="label"
																	bindValue="value"
																	appendTo="body"
																	clearAllText="Clear"
																	formControlName="zone"
																	placeholder="{{ 'lang_zone' | language }}"
																	[notFoundText]="'lang_no_data' | language"
																></ng-select>
															</div>
															<div class="col-12 mt-1">
																<label for="block">{{
																	"lang_blocks" | language
																}}</label>
																<ng-select
																	[items]="block_arr"
																	[multiple]="false"
																	bindLabel="label"
																	bindValue="value"
																	appendTo="body"
																	clearAllText="Clear"
																	formControlName="block"
																	placeholder="{{ 'lang_blocks' | language }}"
																	[notFoundText]="'lang_no_data' | language"
																></ng-select>
															</div>
															<div class="col-12 mt-1">
																<label for="cluster">{{
																	"lang_clusters" | language
																}}</label>
																<ng-select
																	[items]="cluster_arr"
																	[multiple]="false"
																	bindLabel="label"
																	bindValue="value"
																	appendTo="body"
																	clearAllText="Clear"
																	formControlName="cluster"
																	placeholder="{{ 'lang_clusters' | language }}"
																	[notFoundText]="'lang_no_data' | language"
																>
																</ng-select>
															</div>

															<div class="col-12 mt-1">
																<label for="barcode">{{
																	"lang_barcode" | language
																}}</label>
																<ng-select
																	[items]="barcode_arr"
																	[multiple]="false"
																	bindLabel="label"
																	bindValue="value"
																	appendTo="body"
																	clearAllText="Clear"
																	formControlName="barcode"
																	placeholder="{{ 'lang_barcode' | language }}"
																	[notFoundText]="'lang_no_data' | language"
																>
																</ng-select>
															</div>
															<div
																class="col-12 mt-2 d-flex justify-content-between"
															>
																<button
																	type="button"
																	class="albutton"
																	style="width: 40% !important"
																	(click)="resetSortForm()"
																>
																	{{ "lang_reset" | language }}
																</button>
																<div
																	class="btn-group"
																	role="group"
																	aria-label="Basic example"
																	style="width: 50% !important"
																>
																	<button
																		type="submit"
																		class="btn albutton filter-btn"
																		(click)="sendSortForm()"
																	>
																		{{ "lang_submit" | language }}
																	</button>
																	<button
																		type="button"
																		class="btn albutton filter-btn"
																		(click)="saveSortForm()"
																	>
																		{{ "lang_save" | language }}
																	</button>
																</div>
															</div>
														</div>
													</form>
												</mat-card-content>
											</mat-card>
										</div>
									</div>
								</div>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card-body pad0" *ngIf="viewtype == 'ADD'">
		<app-phases-add
			[supervision_data]="supervision_data"
			[project_data]="project_data"
			(onAddComplete)="completeAction()"
		></app-phases-add>
	</div>
	<div class="card-body" *ngIf="viewtype == 'EDIT'">
		<app-phases-edit
			[supervision_data]="supervision_data"
			[project_data]="project_data"
			(onAddComplete)="completeAction()"
		></app-phases-edit>
	</div>
</div>
