<div class="card titles">
	<div class="card-header d-flex align-items-center justify-content-between">
		<span>Titles</span>
		<i
			class="fa fa-cog s2icon mnabricon"
			(click)="opencpExcel()"
			matTooltip="Platfom Excel"
		></i>
	</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-12 mb-10">
					<div class="input-group input-group-sm">
						<input
							type="text"
							class="form-control almnabrformcontrol"
							[(ngModel)]="searchKey"
							(keyup)="search_main_titles()"
							placeholder="{{ 'lang_search' | language }}"
						/>
						<span class="input-group-btn">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_add' | language }}"
								class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
								(click)="onAddUpdateMainTitle()"
								*ngIf="makeActionConfig"
							></button>
						</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td>#</td>
									<td>Title English</td>
									<td>Title Arabic</td>
									<td>{{ "lang_ondate" | language }}</td>
									<td>Writer</td>
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody *ngIf="mainTitles.length" class="AppendList">
								<ng-container
									*ngFor="
										let list of mainTitles;
										let i = index;
										let odd = odd;
										let even = even
									"
								>
									<tr
										[ngClass]="{
											odd: odd,
											even: even,
											expanded: list.expanded
										}"
									>
										<td>{{ i + 1 }}</td>
										<td>{{ list.title_english }}</td>
										<td>{{ list.title_arabic }}</td>
										<td>{{ list.title_createdatetime }}</td>
										<td>{{ list.writer }}</td>
										<td>
											<i
												matTooltip="{{ 'lang_delete' | language }}"
												(click)="onDeleteMainTitle(list.title_id)"
												class="fa fa-trash s2icon red icon"
												*ngIf="makeActionConfig"
											></i>
											<i
												matTooltip="{{ 'lang_edit' | language }}"
												(click)="onAddUpdateMainTitle(list)"
												class="fa fa-pencil-square-o fa-icon s2icon mnabricon icon"
												*ngIf="makeActionConfig"
											></i>
											<i
												matTooltip="{{ 'lang_subtitles' | language }}"
												(click)="toggleMainTitle(i)"
												class="pi pi-list s2icon mnabricon icon"
											></i>
										</td>
									</tr>
									<tr *ngIf="list?.expanded" class="list-level2">
										<td></td>
										<td colspan="5">
											<mat-tab-group class="sub-mat-tab">
												<mat-tab label="sub_titles">
													<ng-template mat-tab-label>Sub Titles</ng-template>
													<app-cp-subtitles
														[title_id]="list.title_id"
														[projects_profile_id]="
															project_data?.projects_profile_id
														"
														[projects_work_area_id]="
															supervision_data?.projects_work_area_id
														"
														[makeActionConfig]="makeActionConfig"
													></app-cp-subtitles>
												</mat-tab>
											</mat-tab-group>
										</td>
									</tr>
								</ng-container>
							</tbody>
							<tbody *ngIf="!mainTitles?.length" class="AppendList">
								<tr class="odd">
									<td colspan="7" align="center">{{ nodata }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="mainTitles?.length" class="card-footer padb0">
			<div class="row responsive_pagination">
				<div
					class="col-lg-3 col-sm-3 mb-10"
					style="height: fit-content; margin: auto 0"
				>
					{{ "lang_total" | language }} : {{ mainTitlesCollectionSize }}
				</div>
				<div
					class="col-lg-5 col-sm-5 mb-10"
					style="display: flex; justify-content: center"
				>
					<ngb-pagination
						class="m-auto"
						[collectionSize]="mainTitlesCollectionSize"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="mainTitlesPageno"
						[pageSize]="mainTitlesPagesize"
						(pageChange)="load_main_titles_page($event)"
					></ngb-pagination>
				</div>
				<div
					class="col-lg-3 col-sm-3"
					style="display: flex; justify-content: end; margin: auto"
				>
					<mat-select
						[(ngModel)]="mainTitlesPagesize"
						(selectionChange)="load_main_titles_pagesize($event.value)"
					>
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
</div>
