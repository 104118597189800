<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4 *ngIf="!edit_mode">{{ "lang_add" | language }}</h4>
		<h4 *ngIf="edit_mode">{{ "lang_edit" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="groupForm">
		<div class="p-2">
			<div class="row">
				<div class="col-12">
					<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
						<mat-form-field appearance="outline" style="width: 100%">
							<mat-label>Group Name</mat-label>
							<input
								matInput
								placeholder="Group Name"
								type="text"
								formControlName="group_name"
							/>
						</mat-form-field>
					</div>
				</div>
				<div class="col-12">
					<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
						<mat-form-field appearance="outline" style="width: 100%">
							<mat-label>Group Email</mat-label>
							<input
								matInput
								placeholder="Group Email"
								type="email"
								formControlName="group_email"
							/>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="addEditGroup()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
