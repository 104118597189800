import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-trigger-dialog",
	templateUrl: "./trigger-dialog.component.html",
	styleUrls: ["./trigger-dialog.component.scss"],
})
export class TriggerDialogComponent implements OnInit {
	projects_work_area_id;
	template_id;
	platforms = [];
	platform_msg = "";
	page = 1;
	size = 10;
	constructor(
		private fb: FormBuilder,
		public ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private dialogRef: MatDialogRef<TriggerDialogComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.projects_work_area_id = data?.projects_work_area_id;
		this.template_id = data?.template_id;
	}

	ngOnInit(): void {
		this.getPlatformsTriggered();
	}

	getPlatformsTriggered() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("template_id", this.template_id || "");
		this.spinner.show();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				`p/triggers/get_platform_triggers_updates/${this.page}/${this.size}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.platforms = res?.records;
					} else {
						this.platform_msg = res?.error;
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
	}

	runTrigger() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("template_id", this.template_id || "");
		this.spinner.show();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"p/triggers/update_platform_triggers"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_success"),
							3000
						);
						this.dialogRef.close(true);
					} else {
						this.sweetAlert.errorToast(res?.error, 3000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
	}
}
