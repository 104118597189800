<div class="viewquotation stylepanels" *ngIf="status">
    <div class="material-panel-body communication">
        <fieldset
        class="cardback fieldset mb-10"
        [ngClass]="{ ' contentrtl': lang_key == 'ar' }"
    >
        <legend>{{ "lang_deduction_form" | language }}</legend>
        <div class="row mb-10">
            <div class="contentbodypanel width100 mt-0">
                <div class="col-12 contentpanel">
            
                   
                 
                 <div class="itemsdata">
                        <label>
                          {{'lang_human_resources_employee_details'|language}} :
                        </label>
                        <div class="TableParts">
                            <div
                                class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
                            >
                                <table class="table CustomTables text-center">
                                  
                                    <tbody class="AppendList quotationtable">
                                        <tr>
                                            <td class="text-start">
                                                {{'lang_human_resources_employee_name'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.firstname_english+' '+records?.lastname_english}}
                                            </td>

                                            <td class="text-start">
                                                {{'lang_human_resources_job_title_iqama'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.jobname}}
                                            </td>

                                            <td class="text-start">
                                                {{'lang_human_resources_joining_date'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.joining_start_date_english}} -
                                                {{records?.joining_start_date_arabic}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-start">
                                                {{'lang_mobile_number'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.primary_mobile}}
                                            </td>

                                            <td class="text-start">
                                                {{'lang_branch'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.branch}}
                                            </td>

                                            <td class="text-start">
                                                {{'lang_email'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.primary_email}} 
                                            </td>

 
                                        
                                        </tr>
                                    

                                      
                                    </tbody> 
                                </table>
                            </div>
                        </div>
                        <br />
                     
                    </div>  




                    <div class="itemsdata">
                        <label>
                          {{'lang_human_resources_deduction_details'|language}} :
                        </label>
                        <div class="TableParts">
                            <div
                                class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
                            >
                                <table class="table CustomTables text-center">
                                  
                                    <tbody class="AppendList quotationtable">
                                        <tr>
                                            <td class="text-start">
                                                {{'lang_reason'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.deduction_reason}}
                                            </td>

                                            <td class="text-start">
                                                {{'lang_deduction_percentage'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.deduction_percentage}}
                                            </td>

                                          
                                        </tr>
                                      

                                       
                                        <tr>
                                            <td class="text-start">
                                                {{'lang_deduction_amount'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.deduction_amount}} 
                                            </td>
                                            <td class="text-start">
                                                {{'lang_date'|language}} :
                                                
                                            </td>
                                            <td >
                                                {{records?.deduction_date_english}} - {{records?.deduction_date_arabic}}
                                            </td>
                                        </tr>
                                        

                                      
                                    </tbody> 
                                </table>
                            </div>
                        </div>
                        <br />
                     
                    </div>  


<!-- 
                    <div class="itemsdata">
                        <label>
                          {{'lang_human_resources_vocation_details'|language}} :
                        </label>
                        <div class="TableParts">
                            <div
                                class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
                            >
                                <table class="table CustomTables text-center">
                                  
                                    <tbody class="AppendList quotationtable">
                                        <tr>
                                            <td colspan="3" >
                                                {{'lang_type'|language}} :
                                                
                                            </td>
                                            <td  colspan="3">
                                                {{records?.vacation_type}}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td class="text-start">
                                                {{'lang_days'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.vacation_total_days}}
                                            </td>

                                            <td class="text-start">
                                                {{'lang_start_date'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.vacation_start_date_english}} -
                                                {{records?.vacation_start_date_arabic}}
                                            </td>

                                            <td class="text-start">
                                                {{'lang_end_date'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.vacation_end_date_english}} -
                                                {{records?.vacation_end_date_arabic}}
                                            </td>
                                        </tr>
                                    

                                        <tr>
                                            <td class="text-start">
                                                {{'lang_joining_type_after_vacation'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.vacation_start_date_english}} - 
                                                {{records?.vacation_start_date_arabic}} 
                                            </td>

                                            <td class="text-start">
                                                {{'lang_paid_amount'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.vacation_total_paid_amount |number :'.2-2' }} 
                                            </td>

                                            <td class="text-start">
                                                {{'lang_direct_manager'|language}} :
                                                
                                            </td>
                                            <td>
                                                {{records?.direct_manager_name}}  
                                            </td>
                                        </tr>
                                        

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                         
                    </div>   -->



                    <!-- <div class="itemsdata" *ngIf="financial_details.length > 0">
                        <label>
                          {{'lang_human_resources_financial_details'|language}} :
                        </label>
                        <div class="row mb-2 mt-2"  >
                            <div class="col-md-12 pad0">
                                <div class="table-responsive customResponsive">
                                    <table border="1" class="table CustomTable" >
                                        <thead>
                                            <tr>
                                                <td>#</td>
                                                <td>{{ "lang_date" | language }}</td>
                                                <td>{{ "lang_human_resources_vacation_paid_days" | language }}</td>
                                                <td>{{ "lang_finance_unpaid_days" | language }}</td>
                                                <td>{{ "lang_credit_amount" | language }}</td>
                                              
                                                <td>{{ "lang_debit_amount" | language }}</td>
                                                <td>{{ "lang_note" | language }}</td>
                                            </tr>
                                        </thead>
                                        <tbody  class="AppendList">
                                            <tr
                                                *ngFor="
                                                    let finance of financial_details;
                                                    let i = index;
                                                    let odd = odd;
                                                    let even = even
                                                "
                                                
                                            >
                                                <td>{{ i + 1 }}</td>
                                                <td>{{ finance?.finance_month  }} / {{ finance?.finance_year  }} </td>
                                                <td>{{ finance?.paid_days }}</td>
                                                <td>{{ finance?.unpaid_days }}  </td>
                                                <td>{{ finance?.credit_amount.toFixed(2) }}</td>
                                            
                                                <td>{{ finance?.debit_amount.toFixed(2) }}  </td>
                                                <td >
                                                    <div matTooltip="{{finance.finance_description}}" *ngIf="finance.finance_description" class="note">
                                                                
                                                    </div>
                                                  </td>
                                         
                                            </tr>
                                        </tbody>
         
                                    </table>
                                     
        
                                </div>
                            </div>
                        </div>
                        <br />
                         
                    </div>   -->







             <div class="itemsdata" *ngIf="notes.length > 0">
                        <label>
                          {{'lang_history'|language}} :
                        </label>
                        <div class="row mb-2 mt-2"  >
                            <div class="col-md-12 pad0">
                                <div class="table-responsive customResponsive">
                                    <table border="1" class="table CustomTable" >
                                        <thead>
                                            <tr>
                                                <td>#</td>
                                                <td>{{ "lang_name" | language }}</td>
                                                <td>{{ "lang_ondate" | language }}</td>
                                                <td>{{ "lang_notes" | language }}</td>
                                             
                                               
                                            </tr>
                                        </thead>
                                        <tbody  class="AppendList">
                                            <tr
                                                *ngFor="
                                                    let his of notes;
                                                    let i = index;
                                                    let odd = odd;
                                                    let even = even
                                                "
                                                
                                            >
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ his.transactions_records_user_name }}</td>
                                           
                                            <td>{{ his.transactions_records_datetime }}</td>
                                            <td>{{ his.transactions_records_note }}</td>
                                            </tr>
                                        </tbody>
         
                                    </table>
                                     
        
                                </div>
                            </div>
                        </div>
                        <br />
                         
                    </div> 
                </div>
            </div>
        </div>
    </fieldset>


    
    </div>
    
</div>