<app-page-title [title]="pageTitle" [icon]="pageIcon"></app-page-title>
<section
	class="panel-expansion"
	*ngIf="wholedata.length != '0' && component == 'list'"
	[@fadeIn]
>
	<div class="card-footers padb0">
		<div class="row">
			<div class="col-md-3 col-sm-3 col-xs-12 mb-2">
				<button
					*ngIf="view"
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-2">
				<button
					*ngIf="add"
					type="button"
					routerLink="/projects/kxzaCSCss2"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_add" | language }}
				</button>
			</div>
			<div class="col-md-5 col-sm-5 col-xs-12 mb-2"></div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-2">
				<button
					*ngIf="edit"
					type="button"
					routerLink="/projects/NxBf/{{ project_profile_id }}"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_edit" | language }}
				</button>
			</div>
		</div>
	</div>

	<div class="card mb-3 mt-3">
		<div class="card-header">
			{{ "lang_projects_details" | language }}
		</div>
		<div class="material-panel-body">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text">{{ "lang_branch" | language }} :</label>
							<div>
								<b>{{ wholedata.data.branch_name }}</b>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text"
								>{{ "lang_project_name_en" | language }} :</label
							>
							<div>
								<b>{{ wholedata.data.projects_profile_name_en }}</b>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text"
								>{{ "lang_project_name_ar" | language }} :</label
							>
							<div>
								<b>{{ wholedata.data.projects_profile_name_ar }}</b>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text"
								>{{ "lang_customers" | language }} :</label
							>
							<div>
								<b>{{ wholedata.data.customer_name }}</b>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text"
								>{{ "lang_customer_types" | language }} :</label
							>
							<div>
								<b>{{ wholedata.data.customer_type }}</b>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text"
								>{{ "lang_project_scope" | language }} :</label
							>
							<div *ngIf="wholedata.service_user_data.services">
								<ol>
									<li
										*ngFor="let service of wholedata.service_user_data.services"
									>
										<b>{{ service.label }}</b>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text">{{ "lang_writer" | language }}</label>
							<div>
								<b>{{ wholedata.data.writer }}</b>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text"
								>{{ "lang_project_managers" | language }} :</label
							>
							<div *ngIf="wholedata.service_user_data.users">
								<ol>
									<div
										*ngFor="
											let user of by_users_type(
												wholedata.service_user_data.users,
												'PTM'
											)
										"
									>
										<li>
											<b>{{ user.label }}</b>
										</li>
									</div>
								</ol>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text"
								>{{ "lang_project_users" | language }} :</label
							>
							<div *ngIf="wholedata.service_user_data.users">
								<ol>
									<div
										*ngFor="
											let user of by_users_type(
												wholedata.service_user_data.users,
												'PTV'
											)
										"
									>
										<li>
											<b>{{ user.label }}</b>
										</li>
									</div>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text">{{
								"lang_created_date" | language
							}}</label>
							<div>
								<b>{{ wholedata.data.projects_profile_created_datetime }}</b>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text">{{ "lang_onupdate" | language }}</label>
							<div>
								<b *ngIf="wholedata.data.projects_profile_updated_datetime">{{
									wholedata.data.projects_profile_updated_datetime
								}}</b>
								<b *ngIf="!wholedata.data.projects_profile_updated_datetime"
									>----</b
								>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-2">
						<div class="form-group">
							<label class="label-text"
								>{{ "lang_project_location" | language }} :</label
							>
							<div
								*ngIf="
									wholedata.data.projects_profile_location &&
									wholedata.data.latitude &&
									wholedata.data.longitude &&
									wholedata.data.projects_profile_location != 'undefined'
								"
							>
								<b>{{ wholedata.data.projects_profile_location }}</b>
							</div>
							<div
								*ngIf="
									!wholedata.data.projects_profile_location ||
									!wholedata.data.latitude ||
									!wholedata.data.longitude
								"
							>
								<b>----</b>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card mt-3 mb-3" *ngIf="user_type_id == '1'">
		<div class="card-header">
			{{ "lang_services" | language }}
		</div>
		<div class="material-panel-body">
			<mat-horizontal-stepper #stepper class="pad0">
				<mat-step *ngFor="let service of wholedata.service_user_data.services">
					<ng-template matStepLabel>
						<div class="step">
							<div class="st">
								<i class="icon-5x fa fa-code-fork fa-icon"></i>
							</div>
						</div>
						<div>{{ service.label }}</div>
					</ng-template>
					<div class="margin15">
						<mat-accordion>
							<mat-tab-group
								mat-stretch-tabs
								class="example-stretched-tabs mat-elevation-z4"
							>
								<mat-tab
									label="{{ 'lang_quotation' | language }}"
									*ngIf="add || is_admin || show_quotation_tab"
								>
									<div class="custom-panel-content padding15">
										<div class="card">
											<div class="width100">
												<i
													*ngIf="service.keyword == 'S' && add"
													matTooltip="{{ 'lang_create_quotation' | language }}"
													class="fa fa-plus-square linkicon mnabricon"
													routerLink="/projects/{{ project_profile_id }}/{{
														wholedata?.data?.branch_id
													}}/{{
														service?.projects_ps_id
													}}/create_supervision_quotation"
												></i>
												<i
													*ngIf="service.keyword == 'D' && add"
													matTooltip="{{ 'lang_create_quotation' | language }}"
													class="fa fa-plus-square linkicon mnabricon"
													(click)="
														show_create_quotation(
															'dcreate',
															wholedata.data,
															service
														)
													"
												></i>
												<div class="card-header">
													{{ "lang_quotation" | language }}
												</div>
												<div class="card-body pad0">
													<div
														*ngIf="
															service.keyword == 'S' && component == 'list'
														"
													>
														<app-quotations-lists
															[filelevels]="filelevels"
															(actionQuotEvent)="load_supervision_lists($event)"
															[service_data]="service"
															[able2add]="add"
															[project_data]="wholedata.data"
															[able2edit]="edit"
															[able2delete]="delete"
														></app-quotations-lists>
													</div>
												</div>
											</div>
										</div>
									</div>
								</mat-tab>
								<mat-tab label="{{ 'lang_projects' | language }}">
									<div class="custom-panel-content padding15">
										<div class="card" style="border-radius: 5px 5px 0 0">
											<div class="width100">
												<div
													class="card-header d-flex justify-content-between align-items-center"
												>
													<span>{{ "lang_projects" | language }}</span>
													<i
														class="fa fa-plus-square mnabricon"
														matTooltip="{{ 'lang_add' | language }}"
														(click)="
															addSupervisionProject(service?.projects_ps_id)
														"
														*ngIf="
															service.keyword == 'S' && component == 'list'
														"
													></i>
													<i
														class="fa fa-plus-square mnabricon"
														matTooltip="{{ 'lang_add' | language }}"
														(click)="addDesignProject(service?.projects_ps_id)"
														*ngIf="
															service.keyword == 'D' && component == 'list'
														"
													></i>
												</div>
												<div class="card-body pad0">
													<div
														*ngIf="
															service.keyword == 'S' && component == 'list'
														"
													>
														<app-supervision-lists
															[filelevels]="filelevels"
															[service_data]="service"
															[able2add]="add"
															[project_data]="wholedata.data"
															[able2edit]="edit"
															[able2delete]="delete"
															[isQuotationExists]="
																add || is_admin || show_quotation_tab
															"
														></app-supervision-lists>
													</div>
													<div
														*ngIf="
															service.keyword == 'D' && component == 'list'
														"
													>
														<app-design-lists
															[filelevels]="filelevels"
															[service_data]="service"
															[able2add]="add"
															[project_data]="wholedata.data"
															[able2edit]="edit"
															[able2delete]="delete"
															[isQuotationExists]="
																add || is_admin || show_quotation_tab
															"
														></app-design-lists>
													</div>
												</div>
											</div>
										</div>
									</div>
								</mat-tab>
							</mat-tab-group>
						</mat-accordion>
					</div>
				</mat-step>
			</mat-horizontal-stepper>
		</div>
	</div>

	<div class="card mt-3 mb-3" *ngIf="user_type_id != '1'">
		<div class="card-header">
			{{ "lang_services" | language }}
		</div>
		<div class="material-panel-body">
			<mat-horizontal-stepper #stepper class="pad0">
				<mat-step *ngFor="let service of wholedata.service_user_data.services">
					<ng-template matStepLabel>
						<div class="step">
							<div class="st">
								<i class="icon-5x fa fa-code-fork fa-icon"></i>
							</div>
						</div>
						<div>{{ service.label }}</div>
					</ng-template>
					<div class="margin15">
						<div class="card">
							<div class="width100">
								<div class="card-header">
									{{ "lang_projects" | language }}
								</div>
								<div class="card-body pad0">
									<div *ngIf="service.keyword == 'S' && component == 'list'">
										<app-supervision-lists
											[filelevels]="filelevels"
											[service_data]="service"
											[able2add]="add"
											[project_data]="wholedata.data"
											[able2edit]="edit"
											[able2delete]="delete"
										></app-supervision-lists>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-step>
			</mat-horizontal-stepper>
		</div>
	</div>
	<div class="card-footers padb0">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-2">
				<button
					*ngIf="view"
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-2">
				<button
					*ngIf="add"
					type="button"
					routerLink="/projects/kxzaCSCss2"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_add" | language }}
				</button>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12 mb-2"></div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-2">
				<button
					*ngIf="edit"
					type="button"
					routerLink="/projects/NxBf/{{ project_profile_id }}"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_edit" | language }}
				</button>
			</div>
		</div>
	</div>
</section>
<div *ngIf="component != 'list' && component == 'screate' && add">
	<app-formpq1-add
		[form_key]="'FORM_PQ1'"
		[form_request_id]="'0'"
		[project_data]="projectdata"
		[service_data]="servicedata"
		(quotEvent)="quotationEvent()"
	></app-formpq1-add>
</div>
<div *ngIf="component != 'list' && component == 'dcreate' && add">
	<app-formpq2-add
		[form_key]="'FORM_PQ2'"
		[form_request_id]="'0'"
		[project_data]="projectdata"
		[service_data]="servicedata"
		(quotEvent)="quotationEvent()"
	></app-formpq2-add>
</div>
