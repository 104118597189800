import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { TransactionsRoutingModule } from "./transactions-routing.module";
import { ApproveTransactionsComponent } from "./tcore/approve-transactions/approve-transactions.component";
import { CronComponent } from "./tcore/cron/cron.component";
import { TcreateComponent } from "./tcore/tcreate/tcreate.component";
import { TeditComponent } from "./tcore/tedit/tedit.component";
import { TlistComponent } from "./tcore/tlist/tlist.component";
import { TviewComponent } from "./tcore/tview/tview.component";
import { QrcodepdfComponent } from "./tcore/qrcodepdf/qrcodepdf.component";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { SharedModule } from "@shared/shared.module";
import { Formc1AddComponent } from "./FORM_C1/formc1-add/formc1-add.component";
import { Formc1EditComponent } from "./FORM_C1/formc1-edit/formc1-edit.component";
import { Formc1ViewComponent } from "./FORM_C1/formc1-view/formc1-view.component";
import { Formc2AddComponent } from "./FORM_C2/formc2-add/formc2-add.component";
import { Formc2EditComponent } from "./FORM_C2/formc2-edit/formc2-edit.component";
import { Formc2ViewComponent } from "./FORM_C2/formc2-view/formc2-view.component";
import { Formpq1AddComponent } from "./FORM_PQ1/formpq1-add/formpq1-add.component";
import { Formpq1EditComponent } from "./FORM_PQ1/formpq1-edit/formpq1-edit.component";
import { Formpq1ViewComponent } from "./FORM_PQ1/formpq1-view/formpq1-view.component";
import { DynamicFormSelectPq1Component } from "./FORM_PQ1/dynamic-form-select-pq1/dynamic-form-select-pq1.component";
import { NgbPaginationModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Formpq1Edit2Component } from "./FORM_PQ1/formpq1-edit2/formpq1-edit2.component";
import { Formct1AddComponent } from "./FORM_CT1/formct1-add/formct1-add.component";
import { Formct1ViewComponent } from "./FORM_CT1/formct1-view/formct1-view.component";
import { Formct1EditComponent } from "./FORM_CT1/formct1-edit/formct1-edit.component";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "app/custom-material-config";
import { FormAcrAddComponent } from "./FORM_ACR/form-acr-add/form-acr-add.component";
import { FormAcrEditComponent } from "./FORM_ACR/form-acr-edit/form-acr-edit.component";
import { FormAcrViewComponent } from "./FORM_ACR/form-acr-view/form-acr-view.component";
import { FormAcpAddComponent } from "./FORM_ACP/form-acp-add/form-acp-add.component";
import { FormAcpEditComponent } from "./FORM_ACP/form-acp-edit/form-acp-edit.component";
import { FormAcpViewComponent } from "./FORM_ACP/form-acp-view/form-acp-view.component";
import { FormAcjAddComponent } from "./FORM_ACJ/form-acj-add/form-acj-add.component";
import { FormAcjEditComponent } from "./FORM_ACJ/form-acj-edit/form-acj-edit.component";
import { FormAcjViewComponent } from "./FORM_ACJ/form-acj-view/form-acj-view.component";
import { FormPsTr1AddComponent } from "./FORM_PS_TR1/form-ps-tr1-add/form-ps-tr1-add.component";
import { FormPsTr1ViewComponent } from "./FORM_PS_TR1/form-ps-tr1-view/form-ps-tr1-view.component";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { NgStepperModule } from "angular-ng-stepper";
import { TStep1Component } from "./FORM_PS_TR1/steps/t-step1/t-step1.component";
import { TStep2Component } from "./FORM_PS_TR1/steps/t-step2/t-step2.component";
import { TStep3Component } from "./FORM_PS_TR1/steps/t-step3/t-step3.component";
import { TStep4Component } from "./FORM_PS_TR1/steps/t-step4/t-step4.component";
import { TStep5Component } from "./FORM_PS_TR1/steps/t-step5/t-step5.component";
import { TStep6Component } from "./FORM_PS_TR1/steps/t-step6/t-step6.component";
import { TStep7Component } from "./FORM_PS_TR1/steps/t-step7/t-step7.component";
import { TStep8Component } from "./FORM_PS_TR1/steps/t-step8/t-step8.component";
import { TStep9Component } from "./FORM_PS_TR1/steps/t-step9/t-step9.component";
import { TStep10Component } from "./FORM_PS_TR1/steps/t-step10/t-step10.component";
import { TStep11Component } from "./FORM_PS_TR1/steps/t-step11/t-step11.component";
import { Formhrj1AddComponent } from "./FORM_HRJ1/formhrj1-add/formhrj1-add.component";
import { Formhrj1ViewComponent } from "./FORM_HRJ1/formhrj1-view/formhrj1-view.component";
import { Formhrj1EditComponent } from "./FORM_HRJ1/formhrj1-edit/formhrj1-edit.component";
import { Formpq2AddComponent } from "./FORM_PQ2/formpq2-add/formpq2-add.component";
import { Formpq2ViewComponent } from "./FORM_PQ2/formpq2-view/formpq2-view.component";
import { Formpq2EditComponent } from "./FORM_PQ2/formpq2-edit/formpq2-edit.component";
import { Formpq2Edit2Component } from "./FORM_PQ2/formpq2-edit2/formpq2-edit2.component";
import { MatStepperModule } from "@angular/material/stepper";
import { Formhrv1EditComponent } from "./FORM_HRV1/formhrv1-edit/formhrv1-edit.component";
import { Formhrv1ViewComponent } from "./FORM_HRV1/formhrv1-view/formhrv1-view.component";
import { SendCodeComponent } from "./FORM_PS_TR1/send-code/send-code.component";
import { GeneralNumbersComponent } from "./FORM_PS_TR1/steps/t-step1/general-numbers/general-numbers.component";
import { ByPhasesComponent } from "./FORM_PS_TR1/steps/t-step1/by-phases/by-phases.component";
import { FormNcrAddComponent } from "./FORM_NCR/form-ncr-add/form-ncr-add.component";
import { NcrStep1Component } from "./FORM_NCR/steps/ncr-step1/ncr-step1.component";
import { NcrStep2Component } from "./FORM_NCR/steps/ncr-step2/ncr-step2.component";
import { NcrStep3Component } from "./FORM_NCR/steps/ncr-step3/ncr-step3.component";
import { NcrStep4Component } from "./FORM_NCR/steps/ncr-step4/ncr-step4.component";
import { NcrStep5Component } from "./FORM_NCR/steps/ncr-step5/ncr-step5.component";
import { NcrStep6Component } from "./FORM_NCR/steps/ncr-step6/ncr-step6.component";
import { NcrStep7Component } from "./FORM_NCR/steps/ncr-step7/ncr-step7.component";
import { NcrStep8Component } from "./FORM_NCR/steps/ncr-step8/ncr-step8.component";
import { NcrStep9Component } from "./FORM_NCR/steps/ncr-step9/ncr-step9.component";
import { NcrStep10Component } from "./FORM_NCR/steps/ncr-step10/ncr-step10.component";
import { FormWriAddComponent } from "./FORM_WRI/form-wri-add/form-wri-add.component";
import { WriStep1Component } from "./FORM_WRI/steps/wri-step1/wri-step1.component";
import { WriStep10Component } from "./FORM_WRI/steps/wri-step10/wri-step10.component";
import { WriStep11Component } from "./FORM_WRI/steps/wri-step11/wri-step11.component";
import { WriStep2Component } from "./FORM_WRI/steps/wri-step2/wri-step2.component";
import { WriStep3Component } from "./FORM_WRI/steps/wri-step3/wri-step3.component";
import { WriStep4Component } from "./FORM_WRI/steps/wri-step4/wri-step4.component";
import { WriStep5Component } from "./FORM_WRI/steps/wri-step5/wri-step5.component";
import { WriStep6Component } from "./FORM_WRI/steps/wri-step6/wri-step6.component";
import { WriStep7Component } from "./FORM_WRI/steps/wri-step7/wri-step7.component";
import { WriStep8Component } from "./FORM_WRI/steps/wri-step8/wri-step8.component";
import { WriStep9Component } from "./FORM_WRI/steps/wri-step9/wri-step9.component";
import { NcrStep11Component } from "./FORM_NCR/steps/ncr-step11/ncr-step11.component";
import { NcrStep12Component } from "./FORM_NCR/steps/ncr-step12/ncr-step12.component";
import { NcrStep13Component } from "./FORM_NCR/steps/ncr-step13/ncr-step13.component";
import { FormSiAddComponent } from "./FORM_SI/form-si-add/form-si-add.component";
import { SiStep1Component } from "./FORM_SI/steps/si-step1/si-step1.component";
import { SiStep2Component } from "./FORM_SI/steps/si-step2/si-step2.component";
import { SiStep3Component } from "./FORM_SI/steps/si-step3/si-step3.component";
import { SiStep4Component } from "./FORM_SI/steps/si-step4/si-step4.component";
import { SiStep5Component } from "./FORM_SI/steps/si-step5/si-step5.component";
import { SiStep6Component } from "./FORM_SI/steps/si-step6/si-step6.component";
import { SiStep7Component } from "./FORM_SI/steps/si-step7/si-step7.component";
import { SiStep8Component } from "./FORM_SI/steps/si-step8/si-step8.component";
import { FormWriViewComponent } from "./FORM_WRI/form-wri-view/form-wri-view.component";
import { MsrStep10Component } from "./FORM_MSR/steps/msr-step10/msr-step10.component";
import { MsrStep11Component } from "./FORM_MSR/steps/msr-step11/msr-step11.component";
import { MsrStep1Component } from "./FORM_MSR/steps/msr-step1/msr-step1.component";
import { MsrStep2Component } from "./FORM_MSR/steps/msr-step2/msr-step2.component";
import { MsrStep3Component } from "./FORM_MSR/steps/msr-step3/msr-step3.component";
import { MsrStep4Component } from "./FORM_MSR/steps/msr-step4/msr-step4.component";
import { MsrStep5Component } from "./FORM_MSR/steps/msr-step5/msr-step5.component";
import { MsrStep6Component } from "./FORM_MSR/steps/msr-step6/msr-step6.component";
import { MsrStep7Component } from "./FORM_MSR/steps/msr-step7/msr-step7.component";
import { MsrStep8Component } from "./FORM_MSR/steps/msr-step8/msr-step8.component";
import { MsrStep9Component } from "./FORM_MSR/steps/msr-step9/msr-step9.component";
import { FormMsrAddComponent } from "./FORM_MSR/form-msr-add/form-msr-add.component";
import { FormMsrViewComponent } from "./FORM_MSR/form-msr-view/form-msr-view.component";
import { StepsComponent } from "./FORM_HRV1/formhrv1-view/steps/steps.component";
import { StepOneConfigrationsComponent } from "./FORM_HRV1/formhrv1-view/steps/step-one-configrations/step-one-configrations.component";
import { StepTwoComponent } from "./FORM_HRV1/formhrv1-view/steps/step-two/step-two.component";
import { StepThreeComponent } from "./FORM_HRV1/formhrv1-view/steps/step-three/step-three.component";
import { StepFourComponent } from "./FORM_HRV1/formhrv1-view/steps/step-four/step-four.component";
import { StepFiveComponent } from "./FORM_HRV1/formhrv1-view/steps/step-five/step-five.component";
import { StepSixComponent } from "./FORM_HRV1/formhrv1-view/steps/step-six/step-six.component";
import { FormSqrAddComponent } from "./FORM_SQR/form-sqr-add/form-sqr-add.component";
import { FormSqrViewComponent } from "./FORM_SQR/form-sqr-view/form-sqr-view.component";
import { SqrStep1Component } from "./FORM_SQR/steps/sqr-step1/sqr-step1.component";
import { SqrStep2Component } from "./FORM_SQR/steps/sqr-step2/sqr-step2.component";
import { SqrStep3Component } from "./FORM_SQR/steps/sqr-step3/sqr-step3.component";
import { SqrStep4Component } from "./FORM_SQR/steps/sqr-step4/sqr-step4.component";
import { SqrStep5Component } from "./FORM_SQR/steps/sqr-step5/sqr-step5.component";
import { SqrStep6Component } from "./FORM_SQR/steps/sqr-step6/sqr-step6.component";
import { SqrStep7Component } from "./FORM_SQR/steps/sqr-step7/sqr-step7.component";
import { SqrStep8Component } from "./FORM_SQR/steps/sqr-step8/sqr-step8.component";
import { SqrStep9Component } from "./FORM_SQR/steps/sqr-step9/sqr-step9.component";
import { SqrStep10Component } from "./FORM_SQR/steps/sqr-step10/sqr-step10.component";
import { SqrStep11Component } from "./FORM_SQR/steps/sqr-step11/sqr-step11.component";
import { FormMirAddComponent } from "./FORM_MIR/form-mir-add/form-mir-add.component";
import { FormMirViewComponent } from "./FORM_MIR/form-mir-view/form-mir-view.component";
import { MirStep1Component } from "./FORM_MIR/steps/mir-step1/mir-step1.component";
import { MirStep2Component } from "./FORM_MIR/steps/mir-step2/mir-step2.component";
import { MirStep3Component } from "./FORM_MIR/steps/mir-step3/mir-step3.component";
import { MirStep4Component } from "./FORM_MIR/steps/mir-step4/mir-step4.component";
import { MirStep5Component } from "./FORM_MIR/steps/mir-step5/mir-step5.component";
import { MirStep6Component } from "./FORM_MIR/steps/mir-step6/mir-step6.component";
import { MirStep7Component } from "./FORM_MIR/steps/mir-step7/mir-step7.component";
import { MirStep8Component } from "./FORM_MIR/steps/mir-step8/mir-step8.component";
import { MirStep9Component } from "./FORM_MIR/steps/mir-step9/mir-step9.component";
import { MirStep10Component } from "./FORM_MIR/steps/mir-step10/mir-step10.component";
import { MirStep11Component } from "./FORM_MIR/steps/mir-step11/mir-step11.component";
import { SqrAddSupplierComponent } from "./FORM_SQR/steps/sqr-step1/sqr-add-supplier/sqr-add-supplier.component";
import { HRJ1StepOneConfigrationsComponent } from "./FORM_HRJ1/formhrj1-view/steps/step-one-configrations/step-one-configrations.component";
import { HRJ1StepTwoComponent } from "./FORM_HRJ1/formhrj1-view/steps/step-two/step-two.component";
import { HRJ1StepThreeComponent } from "./FORM_HRJ1/formhrj1-view/steps/step-three/step-three.component";
import { HRJ1StepFourComponent } from "./FORM_HRJ1/formhrj1-view/steps/step-four/step-four.component";
import { HRJ1StepFiveComponent } from "./FORM_HRJ1/formhrj1-view/steps/step-five/step-five.component";
import { HRJ1StepSixComponent } from "./FORM_HRJ1/formhrj1-view/steps/step-six/step-six.component";
import { HRJ1StepsComponent } from "./FORM_HRJ1/formhrj1-view/steps/steps.component";
import { CreateLOANComponent } from "./FORM_FD/LOAN/createLOAN/createLOAN.component";
import { CreateOverTImeComponent } from "./FORM_FD/OVERTIME/createOverTIme/createOverTIme.component";
import { CreateBonusComponent } from "./FORM_FD/BONUS/createBonus/createBonus.component";
import { CreateDeductionComponent } from "./FORM_FD/DEDUCTION/createDeduction/createDeduction.component";
import { StepSevenComponent } from "./FORM_HRV1/formhrv1-view/steps/step-seven/step-seven.component";
import { HRJ1StepSevenComponent } from "./FORM_HRJ1/formhrj1-view/steps/step-seven/step-seven.component";
import { FormNcrViewComponent } from "./FORM_NCR/form-ncr-view/form-ncr-view.component";
import { NcrStep14Component } from "./FORM_NCR/steps/ncr-step14/ncr-step14.component";
import { NcrStep15Component } from "./FORM_NCR/steps/ncr-step15/ncr-step15.component";
import { NcrStepsHistoryComponent } from "./FORM_NCR/steps/ncr-steps-history/ncr-steps-history.component";
import { ViewLoanComponent } from "./FORM_FD/LOAN/viewLoan/viewLoan.component";
import { LoanStepFiveComponent } from "./FORM_FD/LOAN/viewLoan/steps/step-five/step-five.component";
import { LoanStepFourComponent } from "./FORM_FD/LOAN/viewLoan/steps/step-four/step-four.component";
import { LoanStepOneConfigrationsComponent } from "./FORM_FD/LOAN/viewLoan/steps/step-one-configrations/step-one-configrations.component";
import { LoanStepSevenComponent } from "./FORM_FD/LOAN/viewLoan/steps/step-seven/step-seven.component";
import { LoanStepSixComponent } from "./FORM_FD/LOAN/viewLoan/steps/step-six/step-six.component";
import { LoanStepThreeComponent } from "./FORM_FD/LOAN/viewLoan/steps/step-three/step-three.component";
import { LoanStepTwoComponent } from "./FORM_FD/LOAN/viewLoan/steps/step-two/step-two.component";
import { LoanStepsComponent } from "./FORM_FD/LOAN/viewLoan/steps/steps.component";
import { ViewOvertimeComponent } from "./FORM_FD/OVERTIME/viewOvertime/viewOvertime.component";
import { OvertimeStepsComponent } from "./FORM_FD/OVERTIME/viewOvertime/steps/steps.component";
import { OvertimeStepTwoComponent } from "./FORM_FD/OVERTIME/viewOvertime/steps/step-two/step-two.component";
import { OvertimeStepThreeComponent } from "./FORM_FD/OVERTIME/viewOvertime/steps/step-three/step-three.component";
import { OvertimeStepSixComponent } from "./FORM_FD/OVERTIME/viewOvertime/steps/step-six/step-six.component";
import { OvertimeStepSevenComponent } from "./FORM_FD/OVERTIME/viewOvertime/steps/step-seven/step-seven.component";
import { OvertimeStepOneConfigrationsComponent } from "./FORM_FD/OVERTIME/viewOvertime/steps/step-one-configrations/step-one-configrations.component";
import { OvertimeStepFourComponent } from "./FORM_FD/OVERTIME/viewOvertime/steps/step-four/step-four.component";
import { OvertimeStepFiveComponent } from "./FORM_FD/OVERTIME/viewOvertime/steps/step-five/step-five.component";
import { ViewDeductionComponent } from "./FORM_FD/DEDUCTION/viewDeduction/viewDeduction.component";
import { DeductionStepsComponent } from "./FORM_FD/DEDUCTION/viewDeduction/steps/steps.component";
import { DeductionStepTwoComponent } from "./FORM_FD/DEDUCTION/viewDeduction/steps/step-two/step-two.component";
import { DeductionStepThreeComponent } from "./FORM_FD/DEDUCTION/viewDeduction/steps/step-three/step-three.component";
import { DeductionStepSixComponent } from "./FORM_FD/DEDUCTION/viewDeduction/steps/step-six/step-six.component";
import { DeductionStepSevenComponent } from "./FORM_FD/DEDUCTION/viewDeduction/steps/step-seven/step-seven.component";
import { DeductionStepOneConfigrationsComponent } from "./FORM_FD/DEDUCTION/viewDeduction/steps/step-one-configrations/step-one-configrations.component";
import { DeductionStepFourComponent } from "./FORM_FD/DEDUCTION/viewDeduction/steps/step-four/step-four.component";
import { DeductionStepFiveComponent } from "./FORM_FD/DEDUCTION/viewDeduction/steps/step-five/step-five.component";
import { ViewBonusComponent } from "./FORM_FD/BONUS/viewBonus/viewBonus.component";
import { BonusStepFiveComponent } from "./FORM_FD/BONUS/viewBonus/steps/step-five/step-five.component";
import { BonusStepFourComponent } from "./FORM_FD/BONUS/viewBonus/steps/step-four/step-four.component";
import { BonusStepOneConfigrationsComponent } from "./FORM_FD/BONUS/viewBonus/steps/step-one-configrations/step-one-configrations.component";
import { BonusStepSevenComponent } from "./FORM_FD/BONUS/viewBonus/steps/step-seven/step-seven.component";
import { BonusStepSixComponent } from "./FORM_FD/BONUS/viewBonus/steps/step-six/step-six.component";
import { BonusStepThreeComponent } from "./FORM_FD/BONUS/viewBonus/steps/step-three/step-three.component";
import { BonusStepTwoComponent } from "./FORM_FD/BONUS/viewBonus/steps/step-two/step-two.component";
import { BonusStepsComponent } from "./FORM_FD/BONUS/viewBonus/steps/steps.component";
import { CreateViolationComponent } from "./FORM_FD/Violation/createViolation/createViolation.component";
import { MatRadioModule } from "@angular/material/radio";
import { ViolationStepFiveComponent } from "./FORM_FD/Violation/viewViolation/steps/step-five/step-five.component";
import { ViolationStepFourComponent } from "./FORM_FD/Violation/viewViolation/steps/step-four/step-four.component";
import { ViolationStepOneConfigrationsComponent } from "./FORM_FD/Violation/viewViolation/steps/step-one-configrations/step-one-configrations.component";
import { ViolationStepSevenComponent } from "./FORM_FD/Violation/viewViolation/steps/step-seven/step-seven.component";
import { ViolationStepSixComponent } from "./FORM_FD/Violation/viewViolation/steps/step-six/step-six.component";
import { ViolationStepThreeComponent } from "./FORM_FD/Violation/viewViolation/steps/step-three/step-three.component";
import { ViolationStepTwoComponent } from "./FORM_FD/Violation/viewViolation/steps/step-two/step-two.component";
import { ViolationStepsComponent } from "./FORM_FD/Violation/viewViolation/steps/steps.component";
import { ViewViolationComponent } from "./FORM_FD/Violation/viewViolation/viewViolation.component";
import { ViolationHistoryComponent } from "./FORM_FD/Violation/send-code/violation-history.component";
import { FormCpAddComponent } from "./FORM_CP/form-cp-add/form-cp-add.component";
import { FormCpViewComponent } from "./FORM_CP/form-cp-view/form-cp-view.component";
import { CpStep1Component } from "./FORM_CP/steps/cp-step1/cp-step1.component";
import { CpStep2Component } from "./FORM_CP/steps/cp-step2/cp-step2.component";
import { CpStep3Component } from "./FORM_CP/steps/cp-step3/cp-step3.component";
import { CpStep4Component } from "./FORM_CP/steps/cp-step4/cp-step4.component";
import { TreeModule } from "@circlon/angular-tree-component";
import { PayroleModule } from "../humanresources/payrole/payrole.module";
import { Formct1AddNewComponent } from "./FORM_CT1/formct1-add-new/formct1-add-new.component";
import { FormCpayViewComponent } from "./FORM_CPAY/form-cpay-view/form-cpay-view.component";
import { CpayStep1Component } from "./FORM_CPAY/steps/cpay-step1/cpay-step1.component";
import { CpayStep2Component } from "./FORM_CPAY/steps/cpay-step2/cpay-step2.component";
import { CpayStep3Component } from "./FORM_CPAY/steps/cpay-step3/cpay-step3.component";
import { CpayStep4Component } from "./FORM_CPAY/steps/cpay-step4/cpay-step4.component";
import { CpayStep5Component } from "./FORM_CPAY/steps/cpay-step5/cpay-step5.component";
import { CpayStep6Component } from "./FORM_CPAY/steps/cpay-step6/cpay-step6.component";
import { CpayStep7Component } from "./FORM_CPAY/steps/cpay-step7/cpay-step7.component";
import { CpayStep8Component } from "./FORM_CPAY/steps/cpay-step8/cpay-step8.component";
import { CpayStepsTableComponent } from "./FORM_CPAY/steps/cpay-steps-table/cpay-steps-table.component";
import { FormJFViewComponent } from "../humanresources/applications/view-jop-offer/view-jop-offer.component";
import { JFStepsComponent } from "../humanresources/applications/view-jop-offer/steps/JFsteps.component";
import { JFStepOneConfigrationsComponent } from "../humanresources/applications/view-jop-offer/steps/step-one-configrations/JFstep-one-configrations.component";
import { JFStepFourComponent } from "../humanresources/applications/view-jop-offer/steps/step-four/step-four.component";
import { JFStepThreeComponent } from "../humanresources/applications/view-jop-offer/steps/step-three/step-three.component";
import { JFStepTwoComponent } from "../humanresources/applications/view-jop-offer/steps/step-two/step-two.component";
import { FormCT1ViewComponent } from "./FORM_CT1/view-new-contract/view-contract.component";
import { CT1StepsComponent } from "./FORM_CT1/view-new-contract/steps/Ct1Steps.component";
import { CT1StepOneConfigrationsComponent } from "./FORM_CT1/view-new-contract/steps/step-one-configrations/CT1step-one-configrations.component";
import { CT1StepThreeComponent } from "./FORM_CT1/view-new-contract/steps/step-three/step-three.component";
import { CT1StepTwoComponent } from "./FORM_CT1/view-new-contract/steps/step-two/step-two.component";
import { CT1StepFourComponent } from "./FORM_CT1/view-new-contract/steps/step-four/step-four.component";
import { CT1StepEmployeeComponent } from "./FORM_CT1/view-new-contract/steps/step-employee/step-employee.component";
import { CT1StepFirstPartyComponent } from "./FORM_CT1/view-new-contract/steps/step-first-party/step-first-party.component";
import { SelectPreviewComponent } from "./FORM_CPAY/form-cpay-view/select-preview/select-preview.component";
import { JFStepFirstPartyComponent } from "../humanresources/applications/view-jop-offer/steps/step-first-party/step-first-party.component";
import { hrv1AsPdfViewComponent } from "./FORM_HRV1/formhrv1-view/hrv-asPdf-view/hrv1-asPdf-view.component";
import { hrj1AsPdfViewComponent } from "./FORM_HRJ1/formhrj1-view/hrj1-asPdf-view/hrj1-asPdf-view.component";
import { deductionAsPdfViewComponent } from "./FORM_FD/DEDUCTION/viewDeduction/deduction-asPdf-view/deduction-asPdf-view.component";
import { bonusAsPdfViewComponent } from "./FORM_FD/BONUS/viewBonus/bonus-asPdf-view/bonus-asPdf-view.component";
import { OverTimeAsPdfViewComponent } from "./FORM_FD/OVERTIME/viewOvertime/OverTime-asPdf-view/OverTime-asPdf-view.component";
import { EPPAsPdfViewComponent } from "./FORM_EPP/view/EPP-asPdf-view/EPP-asPdf-view.component";
import { EPPStepTwoComponent } from "./FORM_EPP/view/steps/step-two/EPP-step-two.component";
import { EPPStepOneConfigrationsComponent } from "./FORM_EPP/view/steps/step-one-configrations/step-one-configrations.component";
import { EPPStepThreeComponent } from "./FORM_EPP/view/steps/step-three/step-three.component";
import { EPPStepFourComponent } from "./FORM_EPP/view/steps/step-four/step-four.component";
import { EPPStepSevenComponent } from "./FORM_EPP/view/steps/step-seven/step-seven.component";
import { EPPStepsComponent } from "./FORM_EPP/view/steps/steps.component";
import { ViewEPPComponent } from "./FORM_EPP/view/viewEPP.component";
import { FormPq1AddComponent } from "./FORM_PQ1_new/form-pq1-add/form-pq1-add.component";
import { FormPq1ViewComponent } from "./FORM_PQ1_new/form-pq1-view/form-pq1-view.component";
import { Pq1Step1Component } from "./FORM_PQ1_new/steps/pq1-step1/pq1-step1.component";
import { Pq1Step2Component } from "./FORM_PQ1_new/steps/pq1-step2/pq1-step2.component";
import { Pq1Step3Component } from "./FORM_PQ1_new/steps/pq1-step3/pq1-step3.component";
import { Pq1Step4Component } from "./FORM_PQ1_new/steps/pq1-step4/pq1-step4.component";
import { Pq1Step5Component } from "./FORM_PQ1_new/steps/pq1-step5/pq1-step5.component";
import { Pq1Step6Component } from "./FORM_PQ1_new/steps/pq1-step6/pq1-step6.component";
import { Pq1StepsHistoryComponent } from "./FORM_PQ1_new/steps/pq1-steps-history/pq1-steps-history.component";
import { Formct1ViewAspdfComponent } from "./FORM_CT1/view-new-contract/formct1-view-aspdf/formct1-view-aspdf.component";
import { ProjectTransactionFormCreateComponent } from "./project-transaction-form-create/project-transaction-form-create.component";
import { CKEditorModule } from "ckeditor4-angular";
import { StepExecutiveManagerComponent } from "./FORM_FD/LOAN/viewLoan/steps/step-executive-manager/step-executive-manager.component";
import { Formhrj1AddModalComponent } from "./FORM_HRJ1/formhrj1-add/formhrj1-add-modal/formhrj1-add-modal.component";
import { FormWirNewComponent } from "./FORM_WRI/form-wir-new/form-wir-new.component";
import { FormEct1AddComponent } from "./FORM_ECT1/form-ect1-add/form-ect1-add.component";
import { FormEct1ViewComponent } from "./FORM_ECT1/form-ect1-view/form-ect1-view.component";
import { Ect1Step2Component } from "./FORM_ECT1/steps/ect1-step2/ect1-step2.component";
import { Ect1Step3Component } from "./FORM_ECT1/steps/ect1-step3/ect1-step3.component";
import { Ect1Step4Component } from "./FORM_ECT1/steps/ect1-step4/ect1-step4.component";
import { Ect1Step5Component } from "./FORM_ECT1/steps/ect1-step5/ect1-step5.component";
import { Ect1Step6Component } from "./FORM_ECT1/steps/ect1-step6/ect1-step6.component";
import { Ect1Step1ConfigComponent } from "./FORM_ECT1/steps/ect1-step1-config/ect1-step1-config.component";
import { Ect1Step7Component } from "./FORM_ECT1/steps/ect1-step7/ect1-step7.component";
import { Etc1StepsComponent } from "./FORM_ECT1/steps/etc1-steps/etc1-steps.component";
import { FormsComponent } from "./FORM_WRI/form-wir-new/forms/forms.component";
import { FORMSTEADDComponent } from "./FORM_STE/form-ste-add/form-ste-add.component";
import { FORMSTEVIEWComponent } from "./FORM_STE/form-ste-view/form-ste-view.component";
import { FormesteViewAsdpfComponent } from "./FORM_STE/form-ste-view/formeste-view-asdpf/formeste-view-asdpf.component";
import { Formect1ViewAsdpfComponent } from "./FORM_ECT1/form-ect1-view/formect1-view-asdpf/formect1-view-asdpf.component";
import { SteStep1ConfigComponent } from "./FORM_STE/steps/ste-step1-config/ste-step1-config.component";
import { SteStep2Component } from "./FORM_STE/steps/ste-step2/ste-step2.component";
import { SteStep3Component } from "./FORM_STE/steps/ste-step3/ste-step3.component";
import { SteStep4Component } from "./FORM_STE/steps/ste-step4/ste-step4.component";
import { SteStep5Component } from "./FORM_STE/steps/ste-step5/ste-step5.component";
import { SteStepsComponent } from "./FORM_STE/steps/ste-steps/ste-steps.component";
import { FORMHRPRComponent } from "./form-hrpr/form-hrpr.component";
const COMPONENTS = [
  FormEct1AddComponent,
  ViewEPPComponent,
  EPPStepsComponent,
  EPPStepSevenComponent,
  EPPStepFourComponent,
  EPPStepThreeComponent,
  EPPStepOneConfigrationsComponent,
  EPPStepTwoComponent,
  EPPAsPdfViewComponent,
  OverTimeAsPdfViewComponent,
  bonusAsPdfViewComponent,
  deductionAsPdfViewComponent,
  hrj1AsPdfViewComponent,
  hrv1AsPdfViewComponent,
  JFStepFirstPartyComponent,
  CT1StepFirstPartyComponent,
  CT1StepEmployeeComponent,
  CT1StepFourComponent,
  CT1StepTwoComponent,
  CT1StepThreeComponent,
  CT1StepOneConfigrationsComponent,
  FormCT1ViewComponent,
  CT1StepsComponent,
  FormEct1ViewComponent,
  Etc1StepsComponent,
  Ect1Step2Component,
  Ect1Step3Component,
  Ect1Step4Component,
  Ect1Step5Component,
  Ect1Step6Component,
  Ect1Step7Component,
  Ect1Step1ConfigComponent,
  Formect1ViewAsdpfComponent,
  JFStepFourComponent,
  JFStepThreeComponent,
  JFStepTwoComponent,
  JFStepOneConfigrationsComponent,
  FormJFViewComponent,
  JFStepsComponent,
  Formct1AddNewComponent,
  CreateViolationComponent,
  ViolationHistoryComponent,
  ViewViolationComponent,
  ViolationStepsComponent,
  ViolationStepTwoComponent,
  ViolationStepThreeComponent,
  ViolationStepSixComponent,
  ViolationStepSevenComponent,
  ViolationStepOneConfigrationsComponent,
  ViolationStepFourComponent,
  ViolationStepFiveComponent,
  BonusStepsComponent,
  BonusStepTwoComponent,
  BonusStepThreeComponent,
  BonusStepSixComponent,
  BonusStepSevenComponent,
  BonusStepOneConfigrationsComponent,
  BonusStepFourComponent,
  BonusStepFiveComponent,
  ViewBonusComponent,
  DeductionStepFiveComponent,
  DeductionStepFourComponent,
  DeductionStepOneConfigrationsComponent,
  DeductionStepSevenComponent,
  DeductionStepSixComponent,
  DeductionStepThreeComponent,
  DeductionStepTwoComponent,
  DeductionStepsComponent,
  ViewDeductionComponent,
  OvertimeStepFiveComponent,
  OvertimeStepFourComponent,
  OvertimeStepOneConfigrationsComponent,
  OvertimeStepSevenComponent,
  OvertimeStepSixComponent,
  OvertimeStepThreeComponent,
  OvertimeStepTwoComponent,
  OvertimeStepsComponent,
  ViewOvertimeComponent,
  LoanStepsComponent,
  LoanStepTwoComponent,
  LoanStepThreeComponent,
  LoanStepSixComponent,
  LoanStepSevenComponent,
  LoanStepOneConfigrationsComponent,
  LoanStepFourComponent,
  LoanStepFiveComponent,
  ViewLoanComponent,
  HRJ1StepSevenComponent,
  CreateDeductionComponent,
  FormNcrViewComponent,
  CreateBonusComponent,
  CreateOverTImeComponent,
  CreateLOANComponent,
  ApproveTransactionsComponent,
  SqrAddSupplierComponent,
  HRJ1StepOneConfigrationsComponent,
  HRJ1StepThreeComponent,
  HRJ1StepTwoComponent,
  HRJ1StepFiveComponent,
  HRJ1StepFourComponent,
  HRJ1StepsComponent,
  HRJ1StepSixComponent,
  FormSqrViewComponent,
  FormMirAddComponent,
  FormMirViewComponent,
  MirStep1Component,
  MirStep2Component,
  MirStep3Component,
  MirStep4Component,
  MirStep5Component,
  MirStep6Component,
  MirStep7Component,
  MirStep8Component,
  MirStep9Component,
  MirStep10Component,
  MirStep11Component,
  StepsComponent,
  FormSqrAddComponent,
  FormSqrViewComponent,
  SqrStep1Component,
  SqrStep2Component,
  SqrStep3Component,
  SqrStep4Component,
  SqrStep5Component,
  SqrStep6Component,
  SqrStep7Component,
  SqrStep8Component,
  SqrStep9Component,
  SqrStep10Component,
  SqrStep11Component,
  StepOneConfigrationsComponent,
  StepTwoComponent,
  StepThreeComponent,
  StepFourComponent,
  StepFiveComponent,
  CronComponent,
  TcreateComponent,
  TeditComponent,
  TlistComponent,
  TviewComponent,
  QrcodepdfComponent,
  StepSixComponent,
  DynamicFormSelectPq1Component,
  Formpq1ViewComponent,
  Formpq1EditComponent,
  Formpq1AddComponent,
  Formc1AddComponent,
  Formc1EditComponent,
  Formc1ViewComponent,
  Formc2ViewComponent,
  Formc2AddComponent,
  Formc2EditComponent,
  Formpq1Edit2Component,
  Formct1AddComponent,
  Formct1ViewComponent,
  Formct1EditComponent,
  FormAcrAddComponent,
  FormAcrEditComponent,
  FormAcrViewComponent,
  FormAcpAddComponent,
  FormAcpEditComponent,
  FormAcpViewComponent,
  FormAcjAddComponent,
  FormAcjEditComponent,
  FormAcjViewComponent,
  FormPsTr1AddComponent,
  FormPsTr1ViewComponent,
  TStep1Component,
  TStep2Component,
  TStep3Component,
  TStep4Component,
  TStep5Component,
  TStep6Component,
  TStep7Component,
  TStep8Component,
  TStep9Component,
  TStep10Component,
  TStep11Component,
  Formhrj1AddComponent,
  Formhrj1ViewComponent,
  Formhrj1EditComponent,
  Formpq2AddComponent,
  Formpq2ViewComponent,
  Formpq2EditComponent,
  Formpq2Edit2Component,
  Formhrv1EditComponent,
  Formhrv1ViewComponent,
  FormNcrAddComponent,
  NcrStep1Component,
  NcrStep2Component,
  NcrStep3Component,
  NcrStep4Component,
  NcrStep5Component,
  NcrStep6Component,
  NcrStep7Component,
  NcrStep8Component,
  NcrStep9Component,
  NcrStep10Component,
  FormWriAddComponent,
  WriStep1Component,
  WriStep2Component,
  WriStep3Component,
  WriStep4Component,
  WriStep5Component,
  WriStep6Component,
  WriStep7Component,
  WriStep8Component,
  WriStep9Component,
  WriStep10Component,
  WriStep11Component,
  SendCodeComponent,
  GeneralNumbersComponent,
  ByPhasesComponent,
  NcrStep11Component,
  NcrStep12Component,
  NcrStep13Component,
  FormSiAddComponent,
  SiStep1Component,
  SiStep2Component,
  SiStep3Component,
  SiStep4Component,
  SiStep5Component,
  SiStep6Component,
  SiStep7Component,
  SiStep8Component,
  FormWriViewComponent,
  FormWirNewComponent,
  FormMsrAddComponent,
  FormMsrViewComponent,
  MsrStep1Component,
  MsrStep2Component,
  MsrStep3Component,
  MsrStep4Component,
  MsrStep5Component,
  MsrStep6Component,
  MsrStep7Component,
  MsrStep8Component,
  MsrStep9Component,
  MsrStep10Component,
  MsrStep11Component,
  FORMSTEADDComponent,
  FormesteViewAsdpfComponent,
  SteStep1ConfigComponent,
  SteStep2Component,
  SteStep3Component,
  SteStep4Component,
  SteStep5Component,
  SteStepsComponent,
  FORMHRPRComponent,
];

const COMPONENTS_DYNAMIC = [];

@NgModule({
  imports: [
    SharedModule,
    PayroleModule,
    TransactionsRoutingModule,
    AngularMultiSelectModule,
    NgbPaginationModule,
    NgbModule,
    CdkStepperModule,
    NgStepperModule,
    MatStepperModule,
    MatRadioModule,
    TreeModule,
    CKEditorModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_DYNAMIC,

    StepSevenComponent,
    NcrStep14Component,
    NcrStep15Component,
    NcrStepsHistoryComponent,
    FormCpAddComponent,
    FormCpViewComponent,
    CpStep1Component,
    CpStep2Component,
    CpStep3Component,
    CpStep4Component,
    FormCpayViewComponent,
    CpayStep1Component,
    CpayStep2Component,
    CpayStep3Component,
    CpayStep4Component,
    CpayStep5Component,
    CpayStep6Component,
    CpayStep7Component,
    CpayStep8Component,
    CpayStepsTableComponent,
    SelectPreviewComponent,
    FormPq1AddComponent,
    FormPq1ViewComponent,
    Pq1Step1Component,
    Pq1Step2Component,
    Pq1Step3Component,
    Pq1Step4Component,
    Pq1Step5Component,
    Pq1Step6Component,
    Pq1StepsHistoryComponent,
    Formct1ViewAspdfComponent,
    ProjectTransactionFormCreateComponent,
    StepExecutiveManagerComponent,
    Formhrj1AddModalComponent,
    FormsComponent,
    FORMSTEADDComponent,
    FORMSTEVIEWComponent,
    FormesteViewAsdpfComponent,
    Formect1ViewAsdpfComponent,
    SteStep1ConfigComponent,
    SteStep2Component,
    SteStep3Component,
    SteStep4Component,
    SteStep5Component,
    SteStepsComponent,
    FORMHRPRComponent,
  ],
  exports: [...COMPONENTS, ...COMPONENTS_DYNAMIC],
  entryComponents: COMPONENTS_DYNAMIC,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class TransactionsModule {}
