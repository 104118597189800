<div class="row wafi_header">
  <div class="table-responsive customResponsive">
    <table border="1" class="table wafi_title" width="100%">
      <tbody>
        <tr>
          <td width="130px">
            <img src="assets/images/logoawafi.PNG" alt="" />
          </td>
          <td width="87.5%">
            <div class="title_content">
              {{
                "supervision consultant monthly report for july-2022" | language
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row content_header">
  <div class="col">
    {{ "lang_quality_management" | language }}
  </div>
</div>
<div class="row quality_plan">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody>
        <tr>
          <th rowspan="4">{{ "lang_quality_plan" | language }}</th>
          <th>{{ "lang_quality_management_plan_status" | language }}</th>
          <th>{{ "lang_procedures,_forms,_templates" | language }}</th>
          <th>{{ "lang_itp" | language }}</th>
          <th rowspan="4" class="another_title">
            {{ "lang_quality_assurance" | language }}
          </th>
          <th>
            {{
              "lang_monthly_planned_consultant_staff_according_to_the_deployment_plan"
                | language
            }}
            ({{ "lang_engineers" | language }})
          </th>
          <th>
            {{
              "lang_monthly_planned_consultant_staff_according_to_the_deployment_plan"
                | language
            }}
            ({{ "lang_inspectors" | language }})
          </th>
          <th>
            {{ "lang_method_statements" | language }}
          </th>
        </tr>
        <tr>
          <td class="highlight"></td>
          <td class="highlight"></td>
          <td class="highlight"></td>
          <td>
            {{ "20" }}
          </td>
          <td>
            {{ "0" }}
          </td>
          <td class="highlight"></td>
        </tr>
        <tr>
          <td>{{ "lang_existing_approved" | language }}</td>
          <td>{{ "lang_existing_approved" | language }}</td>
          <td>{{ "lang_existing_approved" | language }}</td>
          <th style="background-color: #595959">
            {{ "lang_actual_consaltant_staff" | language }} ({{
              "lang_engineers" | language
            }})
          </th>
          <th>
            {{ "lang_actual_consaltant_staff" | language }} ({{
              "lang_inspectors" | language
            }})
          </th>
          <td>{{ "lang_existing_approved" | language }}</td>
        </tr>
        <tr>
          <td class="highlight"></td>
          <td class="highlight"></td>
          <td class="highlight"></td>
          <td>
            {{ "20" }}
          </td>
          <td>
            {{ "0" }}
          </td>
          <td class="highlight"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row quality_control">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody>
        <tr>
          <th rowspan="20">{{ "lang_quality_control" | language }}</th>
          <th style="background-color: #fff2cc; color: #000" colspan="7">
            {{ "lang_wir" | language }}
          </th>
        </tr>
        <tr>
          <th style="background-color: #595959">
            {{ "lang_desciplines" | language }}
          </th>
          <th>
            {{ "lang_period" | language }}
          </th>
          <th>
            {{ "lang_no_of_approved" | language }} ({{
              "lang_wirs" | language
            }})
          </th>
          <th>
            {{ "lang_no_of_rejects" | language }} ({{ "lang_wirs" | language }})
          </th>
          <th>
            {{ "lang_no_of_under_review" | language }} ({{
              "lang_wirs" | language
            }})
          </th>
          <th>
            {{ "lang_total_no_of" | language }} ({{ "lang_wirs" | language }})
          </th>
          <th>{{ "lang_notes" | language }} ({{ "lang_wirs" | language }})</th>
        </tr>
        <tr>
          <td rowspan="2" class="highlight2">
            {{ "lang_civil_works" | language }}
          </td>
          <td class="highlight3">
            {{ "jul-22" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "-" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="highlight3">
            {{ "lang_cumulative" | language }}
          </td>
          <td>
            {{ "9,898" }}
          </td>
          <td>
            {{ "3,154" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "13,052" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td rowspan="2" class="highlight2">
            {{ "lang_architectural_works" | language }} ({{
              "lang_finishing" | language
            }})
          </td>
          <td class="highlight3">
            {{ "jul-22" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "-" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="highlight3">
            {{ "lang_cumulative" | language }}
          </td>
          <td>
            {{ "9,898" }}
          </td>
          <td>
            {{ "3,154" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "13,052" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td rowspan="2" class="highlight2">
            {{ "lang_mechanical_works" | language }}
          </td>
          <td class="highlight3">
            {{ "jul-22" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "-" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="highlight3">
            {{ "lang_cumulative" | language }}
          </td>
          <td>
            {{ "9,898" }}
          </td>
          <td>
            {{ "3,154" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "13,052" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td rowspan="2" class="highlight2">
            {{ "lang_electrical_works" | language }} ({{
              "lang_finishing" | language
            }})
          </td>
          <td class="highlight3">
            {{ "jul-22" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "-" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="highlight3">
            {{ "lang_cumulative" | language }}
          </td>
          <td>
            {{ "9,898" }}
          </td>
          <td>
            {{ "3,154" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "13,052" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td rowspan="2" class="highlight2">
            {{ "lang_overall" | language }}
          </td>
          <td class="highlight3">
            {{ "jul-22" }}
          </td>
          <td class="highlight">
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "-" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="highlight3">
            {{ "lang_cumulative" | language }}
          </td>
          <td class="highlight">
            {{ "9,898" }}
          </td>
          <td class="highlight">
            {{ "3,154" }}
          </td>
          <td class="highlight">
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "13,052" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <th style="background-color: #fff2cc; color: #000" colspan="7">
            {{ "lang_mir" | language }}
          </th>
        </tr>
        <tr>
          <th style="background-color: #595959">
            {{ "lang_desciplines" | language }}
          </th>
          <th>
            {{ "lang_period" | language }}
          </th>
          <th>
            {{ "lang_no_of_approved" | language }} ({{
              "lang_mirs" | language
            }})
          </th>
          <th>
            {{ "lang_no_of_rejects" | language }} ({{ "lang_mirs" | language }})
          </th>
          <th>
            {{ "lang_no_of_under_review" | language }} ({{
              "lang_mirs" | language
            }})
          </th>
          <th>
            {{ "lang_total_no_of" | language }} ({{ "lang_mirs" | language }})
          </th>
          <th>{{ "lang_notes" | language }} ({{ "lang_mirs" | language }})</th>
        </tr>
        <tr>
          <td rowspan="2" class="highlight2">
            {{ "lang_civil_works" | language }}
          </td>
          <td class="highlight3">
            {{ "jul-22" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "-" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="highlight3">
            {{ "lang_cumulative" | language }}
          </td>
          <td>
            {{ "9,898" }}
          </td>
          <td>
            {{ "3,154" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "13,052" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <th style="background-color: #fff2cc; color: #000" colspan="7">
            {{ "lang_ncr" | language }}
          </th>
        </tr>
        <tr>
          <th style="background-color: #595959">
            {{ "lang_desciplines" | language }}
          </th>
          <th>
            {{ "lang_period" | language }}
          </th>
          <th>
            {{ "lang_no_of_approved" | language }} ({{ "lang_ncr" | language }})
          </th>
          <th>
            {{ "lang_no_of_rejects" | language }} ({{ "lang_ncrs" | language }})
          </th>
          <th>
            {{ "lang_no_of_under_review" | language }} ({{
              "lang_ncrs" | language
            }})
          </th>
          <th>
            {{ "lang_total_no_of" | language }} ({{ "lang_ncrs" | language }})
          </th>
          <th>{{ "lang_notes" | language }} ({{ "lang_ncrs" | language }})</th>
        </tr>
        <tr>
          <td rowspan="2" class="highlight2">
            {{ "lang_civil_works" | language }}
          </td>
          <td class="highlight3">
            {{ "jul-22" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "-" }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="highlight3">
            {{ "lang_cumulative" | language }}
          </td>
          <td>
            {{ "9,898" }}
          </td>
          <td>
            {{ "3,154" }}
          </td>
          <td>
            {{ "-" }}
          </td>
          <td class="highlight">
            {{ "13,052" }}
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
