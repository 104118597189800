<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{"lang_replace_user" | language}}</h4>
		<span (click)="closeModal()"><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
        <div class="col-12 col-sm-4">
			<ng-select
                [items]="search_users_list"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                clearAllText="Clear"
                (search)="search_users($event)"
                (change)="search_users($event)"
                [searchFn]="ds.customSearchFn"
                appendTo="body"
                [(ngModel)]="user"
                placeholder="{{ 'lang_replace_user' | language }}"
			>
			</ng-select>
        </div>
        <div class="col-12 mt-3">
            <h4>{{"lang_available_users" | language}}</h4>
        </div>
        <div class="col-md-12 mt-1">
            <div
                class="table-responsive customResponsive tablefields quotationitemtable"
            >
                <table
                    border="1"
                    class="table CustomTable"
                    width="100%"
                >
                    <thead>
                        <tr>
                            <td style="padding: 5px; width: 5%" class="text-center">
                                #
                            </td>
                            <td style="padding: 5px; width: 5%" class="text-center">
                                {{'lang_name' | language}}
                            </td>
                            <td style="padding: 5px;width: 15%" class="text-center">
                                {{ "lang_position" | language }} <span class="red">*</span>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let item of users;
                                let i = index;
                                let odd = odd;
                                let even = even
                            "
                        >
                            <td
                                class="verticalalignmid text-center"
                                style="padding: 5px; word-break: break-all"
                            >
                                {{ i + 1 }}
                            </td>
                            <td
                                style="padding: 5px; position: relative"
                                class="text-center"
                            >
                                {{item?.first_name + ' ' + item?.last_name}}
                            </td>
                            <td
                                style="padding: 5px; position: relative"
                                class="text-center"
                                *ngIf="list_type == 'soil'"
                            >
                                
                            </td>
                            <td
                                style="padding: 5px; position: relative"
                                class="text-center"
                            >
                                {{item?.transactions_persons_val1}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
	
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-button
        class="albutton mnabrbutton mat-accent"
        style="width: 200px!important;"
        (click)="sendUser()"
        >
		{{ "lang_save" | language }}
	</button>
</mat-dialog-actions>