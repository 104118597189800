<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{'lang_upload' | language }} {{'lang_file' | language }}</h4>
		<span (click)="closeModal()"><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row justify-content-center my-5">
        <!-- <div class="col-12 col-md-12">
            <p>{{'lang_upload' | language }} {{'lang_attendance' | language }}</p>
        </div> -->
        <div class="col-12 col-md-6">
         
                <input
                type="file"
                #uploadAttendance
                id="uploadAttendance"
                accept="application/txt"
                hidden
                (change)="upload_i_phases($event.target.files)"
            />
            <button
                mat-button
                style="
                    background-color: rgb(136 224 136 / 42%);
                    width: 100%;margin-bottom: 5px;
                "
                (click)="uploadAttendance.click()"
            >
                <i
                    class="fa fa-upload"
                    style="
                        font-size: 20px;
                        margin: 0 5px;
                        color: rgb(19, 146, 19);
                    "
                ></i>
                <span
                    style="
                        text-transform: uppercase;
                        font-weight: 600;
                        color: rgb(19, 146, 19);
                        font-size: 12px;
                    "
                    >{{ "lang_upload" | language }}</span
                >
            </button>
        
        </div>
      
   
   
    </div>

    <div class="row my-2 mx-2 mt-4  div-overflow" *ngIf="response.length>0" >
        <table class="table table-striped custom-payrole-table">
          <thead>
            <tr>
        
             <th scope="col">{{'lang_human_resources_employee_name'|language}}</th>
             <th scope="col">{{'lang_human_resources_id_number'|language}}</th>
             <th scope="col">{{'lang_human_resources_number'|language}}</th>
              
    
              <th scope="col">{{'lang_status'|language}}</th>
      
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of response;let i = index">
                <ng-container *ngFor="let it of item.data" >
                    <tr >
              
                        <td class="changeColor" (click)="employee_details(it.employee_number,i)">{{it.firstname_english +' '+it.secondname_english+' '+it.thirdname_english+' '+it.lastname_english}}</td>
                        <td class="changeColor" (click)="employee_details(it.employee_number,i)">{{it.employee_number}}</td>
                        <td class="changeColor" (click)="employee_details(it.employee_number,i)">{{it.employee_id_number}}</td>
                         
                            <td > <!-- **  success  -->
                              <span *ngIf="item.status == 'Failed'" class="failed">
                                {{'lang_failed'|language}}
                              </span>
                              <span *ngIf="item.status == 'Success'" class="success">
                                {{'lang_success'|language}}
                              </span>
                              <span *ngIf="item.status == null" class="pending">
                                {{'lang_pending'|language}}
                              </span>
                            </td>
                            
                          </tr>
                            <tr *ngIf="item.collapsed">
                            
                            <td colspan="4">
                              <div class="width-100 p-1 mt-1">
                              
              
                               <mat-tab-group>
                                 <mat-tab label="{{'lang_details'|language}}"> 
                                  <div class="row my-1  w-100">
                                    
                                    <div class="col-md-2 d-block col-12 text-start">
                                      <label for="">{{'lang_human_resources_id_expiry_data_english'|language}}</label>
                                     <p class="value">{{it?.iqama_expiry_date_english}}</p>
                                   </div>
                                   <div class="col-md-2 col-sm-4 col-12 text-start">
                                     <label for="">{{'lang_human_resources_copy_number'|language}}</label>
                                     <p class="value">{{it?.copy_number}}</p>
                                   </div>
                                   <div class="col-md-2 col-sm-4 col-12 text-start">
                                     <label for="">{{'lang_human_resources_birth_date_english'|language}}</label>
                                     <p class="value">{{it?.birth_date_english}}</p>
                                   </div>
                                   <div class="col-md-2 col-sm-4 col-12 text-start">
                                     <label for="">{{'lang_human_resources_gender'|language}}</label>
                                     <p class="value">{{it?.gender}}</p>
                                   </div>
                                   <div class="col-md-2 col-sm-4 col-12 text-start">
                                     <label for="">{{'lang_human_resources_nationality'|language}}</label>
                                     <p class="value">{{it?.nationality}}</p>
                                   </div>
                                   <div class="col-md-2 col-sm-4 col-12 text-start">
                                     <label for="">{{'lang_human_resources_job_title_iqama'|language}}</label>
                                     <p class="value">{{it?.job_title_iqama}}</p>
                                   </div>
                                   <div class="col-md-2 col-sm-4 col-12 text-start">
                                     <label for="">{{'lang_username'|language}}</label>
                                     <p class="value">{{it?.username}}</p>
                                   </div>
                                   <div class="col-md-2 col-sm-4 col-12 text-start">
                                    <label for="">{{'lang_human_resources_primary_mobile'|language}}</label>
                                    <p class="value">{{it?.primary_mobile}}</p>
                                  </div>
                                  <div class="col-md-2 col-sm-4 col-12 text-start">
                                    <label for="">{{'lang_human_resources_primary_email'|language}}</label>
                                    <p class="value">{{it?.primary_email}}</p>
                                  </div>
                                  <div class="col-md-2 col-sm-4 col-12 text-start">
                                    <label for="">{{'lang_human_resources_primary_address'|language}}</label>
                                    <p class="value">{{it?.primary_address}}</p>
                                  </div>
                                  <div class="col-md-2 col-sm-4 col-12 text-start">
                                    <label for="">{{'lang_human_resources_graduation_year'|language}}</label>
                                    <p class="value">{{it?.primary_graduation_year}}</p>
                                  </div>
                                  <div class="col-md-2 col-sm-4 col-12 text-start">
                                    <label for="">{{'lang_human_resources_graduation'|language}}</label>
                                    <p class="value">{{it?.primary_education_level}}</p>
                                  </div>
                                  <div class="col-md-2 col-sm-4 col-12 text-start">
                                    <label for="">{{'lang_human_resources_passport_number'|language}}</label>
                                    <p class="value">{{it?.passport_number}}</p>
                                  </div>
                                  <div class="col-md-2 col-sm-4 col-12 text-start">
                                    <label for="">{{'lang_human_resources_passport_issue_date_english'|language}}</label>
                                    <p class="value">{{it?.passport_issue_date_english}}</p>
                                  </div>
                                  <div class="col-md-2 col-sm-4 col-12 text-start">
                                    <label for="">{{'lang_human_resources_passport_expiry_date_english'|language}}</label>
                                    <p class="value">{{it?.passport_expiry_date_english}}</p>
                                  </div>
              
              
                                  <div class="col-md-2 col-sm-4 col-12 text-start">
                                    <label for="">{{'lang_insurance_number'|language}}</label>
                                    <p class="value">{{item?.insurance_number}}</p>
                                  </div>
              
                                  <div class="col-md-2 col-sm-4 col-12 text-start">
                                    <label for="">{{'lang_insurance_date'|language}}</label>
                                    <p class="value">{{item?.insurance_date}}</p>
                                  </div>
              
                                  <div class="col-md-2 col-sm-4 col-12 text-start">
                                    <label for="">{{'lang_insurance_type_class'|language}}</label>
                                    <p class="value">{{item?.insurance_type_class}}</p>
                                  </div>
               
                                   
                                  <div class="col-md-2 d-block col-12 text-start">
                                    <label for="">{{'lang_human_resources_account_number'|language}}</label>
                                   <p class="value">
                                     {{item?.account_number}}
                                   </p>
                                 </div>
                                   
                                  </div> 
                                 </mat-tab>
                                <!--  <mat-tab label="{{'lang_financial_details'|language}}">
                                   <div class="row my-1 w-100">
                                     <div class="col-md-1 d-block col-12 text-start">
                                    
                                    </div>
                                     <div class="col-md-2 d-block col-12 text-start">
                                       <label for="">{{'lang_bank'|language}}</label>
                                      <p class="value">
                                        {{item?.bankname}}
                                      </p>
                                    </div>
                                    <div class="col-md-2 col-sm-4 col-12 text-start">
                                      <label for="">{{'lang_basic_salary'|language}}</label>
                                      <p class="value">
                                        {{item?.basic_salary}}
                                      </p>
                                    </div>
                                    <div class="col-md-2 col-sm-4 col-12 text-start">
                                      <label for="">{{'lang_other_allowances'|language}}</label>
                                      <p class="value">
                                        {{item?.home_allowance}}
                                      </p>
                                    </div>
                                    <div class="col-md-2 col-sm-4 col-12 text-start">
                                      <label for="">{{'lang_other_earnings'|language}}</label>
                                      <p class="value " >
                                        {{item?.Other_Earnings}}</p>
                                    </div>
                                    <div class="col-md-2 col-sm-4 col-12 text-start">
                                      <label for="">{{'lang_deductions'|language}}</label>
                                      <p class="value " >  
                                        {{item?.Deductions}}</p>
                                    </div>
                                    <div class="col-md-1 d-block col-12 text-start">
                                    
                                    </div>
                                    <div class="col-md-1 d-block col-12 text-start">
                                    
                                    </div>
                                    <div class="col-md-2 d-block col-12 text-start">
                                      <label for="">{{'lang_human_resources_account_number'|language}}</label>
                                     <p class="value">
                                       {{item?.account_number}}
                                     </p>
                                   </div>
                                   <div class="col-md-2 d-block col-12 text-start">
                                    <label for="">{{'lang_net_salary'|language}}</label>
                                   <p class="value">
                                     {{item?.net_amount}}
                                   </p>
                                 </div>
              
                                   
              
                                   </div> 
                                 </mat-tab> -->
              
                               </mat-tab-group>
                              </div>
                           </td>
                          </tr>
              
                </ng-container>
          
              </ng-container>
                <!-- <tr >
                  <th scope="row">
                    <div class="checkbox m-auto" >
                      
                      <input type="checkbox"  class="eachItemHR checkbox" />
                      <label for="checkbox">
                   
                      </label>
                   
                  </div>
                  </th>
                  <td>{{item.label}}</td>
                  <td>{{item.value}}</td>
                  <td>123456789</td>
                  <td >Branch</td>
                  <td >Project 1</td>
                  <td >
                    <span class="success">
                      Success
                    </span>
                  </td>
                  <td >
                    
                  </td>
                </tr> -->
    
           
          
           <!--  
            <tr *ngIf="response?.length == 0">
                <th colspan="4">
                 <div class="no_data">
                     {{'lang_no_employees'|language}}
                 </div>
             </th> 
               
            </tr>-->
           
          </tbody>
     
          
        </table>
     
     
       </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="justify-content-between">

    <div class="col-md-3 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="closeModal()"
		>
			{{ "lang_close" | language }}
		</button>
	</div>

    <div class="col-md-3 col-xs-12">
		<button
        *ngIf="response.length>0"
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="confirm_file()"
		>
			{{ "lang_confirm" | language }}
		</button>
	</div>

</mat-dialog-actions>
