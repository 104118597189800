import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "app-task-meeting",
	templateUrl: "./task-meeting.component.html",
	styleUrls: ["./task-meeting.component.scss"],
})
export class TaskMeetingComponent implements OnInit {
	selectedTab = 0;
	viewMeetingId;
	viewMeetingIdForChecklist;
	selectedMeeting;
	@Output() hideMeetings = new EventEmitter<any>();
	@Input() taskId;
	constructor() {}

	ngOnInit(): void {}

	onHideMeetings() {
		this.hideMeetings.emit(true);
	}

	onShowMeeting(event) {
		if (event?.isView) {
			this.selectedTab = 1;
			this.viewMeetingId = event?.meetingId;
		}
	}
	onHideViewMeeting(event) {
		if (event?.isViewMeetings) {
			this.selectedTab = 0;
			this.viewMeetingId = null;
		}
	}
	onShowChecklist(event) {
		if (event?.showChecklist) {
			this.selectedTab = 2;
			this.viewMeetingIdForChecklist = event?.meetingId;
			this.selectedMeeting = event?.meeting;
		}
	}
	onHideViewChecklist(event) {
		if (event?.isViewMeetings) {
			this.selectedTab = 1;
			this.viewMeetingIdForChecklist = null;
		}
	}
}
