<section class="board" [ngClass]="{ rtl: lang_key == 'ar' }">
	<div class="board-header">
		<div class="bh-left">
			<div class="bh-users ml-2 mr-2">
				<div
					class="bhu-user"
					[popover]="test"
					[outsideClick]="true"
					placement="bottom"
					*ngFor="let item of ticketUsersList | slice: 0:5"
					(onShown)="onShowUser(item)"
				>
					<img
						src="assets/images/avatar.png"
						alt="avatar"
						title="{{ item?.userName }}"
						*ngIf="!item?.profile_image_64"
					/>
					<img
						src="{{ item?.profile_image_64 }}"
						alt="avatar"
						title="{{ item?.userName }}"
						*ngIf="item?.profile_image_64"
					/>
				</div>
				<div
					class="bhu-user bhu-user-count"
					*ngIf="ticketUsersList?.length > 5"
					(click)="onShowTicketUsers()"
				>
					<span>+{{ ticketUsersList?.length - 5 }}</span>
				</div>
				<i
					class="pi pi-user-edit bh-users-edit"
					[ngClass]="{
						'ml-3 mr-3': lang_key == 'ar',
						'ml-2 mr-2': lang_key == 'en'
					}"
					(click)="openUsersModal()"
				></i>
			</div>
		</div>
		<div class="bh-center">
			<div
				class="bh-box-txt"
				matTooltip="{{ 'lang_ticket_number' | language }}"
			>
				<h2>{{ ticketData?.ticket_no }}</h2>
			</div>
			<div class="bh-divider"></div>
			<div class="bh-box-txt" matTooltip="{{ 'lang_ticket_title' | language }}">
				<h2>{{ ticketData?.ticket_title }}</h2>
			</div>
			<i
				class="fa fa-info-circle mnabricon"
				style="margin: 0 10px"
				matTooltip="More Details"
				(click)="showTicketDetials()"
			></i>
		</div>
		<div class="bh-right">
			<span
				class="ticket-status greenbg"
				*ngIf="task_status_done == '2'"
				matTooltip="{{ 'lang_in_progress' | language }}"
				(click)="onChangeStatusDone('1')"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></span>
			<span
				class="ticket-status yellowbgcolor"
				*ngIf="task_status_done == '1'"
				matTooltip="{{ 'lang_pending' | language }}"
				(click)="onChangeStatusDone('3')"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></span>
			<span
				class="ticket-status redstatus"
				*ngIf="task_status_done == '3'"
				matTooltip="{{ 'lang_closed' | language }}"
				(click)="onChangeStatusDone('2')"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></span>
			<!-- actions -->
			<i
				class="fa fa-list-ul menu-icon mnabricon"
				matTooltip="{{ 'lang_ticket_list' | language }}"
				routerLink="/tasks/alltasks"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></i>
			<i
				class="fa fa-edit menu-icon mnabricon"
				matTooltip="{{ 'lang_edit' | language }}"
				routerLink="/tasks/edit/{{ ticketID }}"
				*ngIf="ticketData?.is_ticket_admin"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></i>
			<i
				class="pi pi-minus-circle red"
				matTooltip="{{ 'lang_unfollow_ticket' | language }}"
				(click)="unfollowTicket()"
				*ngIf="ticketData?.is_delete == '1'"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></i>
			<svg
				width="24"
				height="24"
				role="presentation"
				focusable="false"
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
				matTooltip="{{ 'lang_timeline' | language }}"
				routerLink="/tasks/timeline/{{ ticketID }}"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M4.5 4H11.5C12.8807 4 14 5.11929 14 6.5C14 7.88071 12.8807 9 11.5 9H4.5C3.11929 9 2 7.88071 2 6.5C2 5.11929 3.11929 4 4.5 4ZM11.5 7C11.7761 7 12 6.77614 12 6.5C12 6.22386 11.7761 6 11.5 6H4.5C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7H11.5Z"
					fill="#0e3768"
				></path>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M6.5 10H13.5C14.8807 10 16 11.1193 16 12.5C16 13.8807 14.8807 15 13.5 15H6.5C5.11929 15 4 13.8807 4 12.5C4 11.1193 5.11929 10 6.5 10ZM13.5 13C13.7761 13 14 12.7761 14 12.5C14 12.2239 13.7761 12 13.5 12H6.5C6.22386 12 6 12.2239 6 12.5C6 12.7761 6.22386 13 6.5 13H13.5Z"
					fill="#0e3768"
				></path>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M18.5 16H11.5C10.1193 16 9 17.1193 9 18.5C9 19.8807 10.1193 21 11.5 21H18.5C19.8807 21 21 19.8807 21 18.5C21 17.1193 19.8807 16 18.5 16ZM11.5 19C11.2239 19 11 18.7761 11 18.5C11 18.2239 11.2239 18 11.5 18H18.5C18.7761 18 19 18.2239 19 18.5C19 18.7761 18.7761 19 18.5 19H11.5Z"
					fill="#0e3768"
				></path>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M17.5 4H19.5C20.8807 4 22 5.11929 22 6.5C22 7.88071 20.8807 9 19.5 9H17.5C16.1193 9 15 7.88071 15 6.5C15 5.11929 16.1193 4 17.5 4ZM19.5 7C19.7761 7 20 6.77614 20 6.5C20 6.22386 19.7761 6 19.5 6H17.5C17.2239 6 17 6.22386 17 6.5C17 6.77614 17.2239 7 17.5 7H19.5Z"
					fill="#0e3768"
				></path>
			</svg>
			<i
				class="fa fa-inbox mnabricon"
				aria-hidden="true"
				matTooltip="{{ 'lang_make_template' | language }}"
				(click)="convertTicketToTemplate()"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></i>
			<i
				class="pi pi-copy mnabricon"
				aria-hidden="true"
				matTooltip="{{ 'lang_contracts' | language }}"
				(click)="showContracts()"
				[ngClass]="{ 'mr-3': lang_key == 'en', 'ml-3': lang_key == 'ar' }"
			></i>
			<mat-menu
				#menu="matMenu"
				[xPosition]="lang_key == 'ar' ? 'after' : 'before'"
			>
				<button mat-menu-item routerLink="/tasks/alltasks">
					<div class="d-flex justify-content-between align-items-center">
						<span>{{ "lang_ticket_list" | language }}</span>
						<i class="fa fa-list-ul menu-icon mnabricon"></i>
					</div>
				</button>
				<button
					mat-menu-item
					routerLink="/tasks/edit/{{ ticketID }}"
					*ngIf="ticketData?.is_ticket_admin"
				>
					<div class="d-flex justify-content-between align-items-center">
						<span>{{ "lang_edit" | language }}</span>
						<i class="fa fa-edit menu-icon mnabricon"></i>
					</div>
				</button>
				<button
					mat-menu-item
					(click)="unfollowTicket()"
					*ngIf="ticketData?.is_delete == '1'"
				>
					<div class="d-flex justify-content-between align-items-center">
						<span>{{ "lang_unfollow_ticket" | language }}</span>
						<i class="pi pi-minus-circle red"></i>
					</div>
				</button>
				<button mat-menu-item routerLink="/tasks/timeline/{{ ticketID }}">
					<div class="d-flex justify-content-between align-items-center">
						<span>{{ "lang_timeline" | language }}</span>
						<svg
							width="24"
							height="24"
							role="presentation"
							focusable="false"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M4.5 4H11.5C12.8807 4 14 5.11929 14 6.5C14 7.88071 12.8807 9 11.5 9H4.5C3.11929 9 2 7.88071 2 6.5C2 5.11929 3.11929 4 4.5 4ZM11.5 7C11.7761 7 12 6.77614 12 6.5C12 6.22386 11.7761 6 11.5 6H4.5C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7H11.5Z"
								fill="#0e3768"
							></path>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 10H13.5C14.8807 10 16 11.1193 16 12.5C16 13.8807 14.8807 15 13.5 15H6.5C5.11929 15 4 13.8807 4 12.5C4 11.1193 5.11929 10 6.5 10ZM13.5 13C13.7761 13 14 12.7761 14 12.5C14 12.2239 13.7761 12 13.5 12H6.5C6.22386 12 6 12.2239 6 12.5C6 12.7761 6.22386 13 6.5 13H13.5Z"
								fill="#0e3768"
							></path>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M18.5 16H11.5C10.1193 16 9 17.1193 9 18.5C9 19.8807 10.1193 21 11.5 21H18.5C19.8807 21 21 19.8807 21 18.5C21 17.1193 19.8807 16 18.5 16ZM11.5 19C11.2239 19 11 18.7761 11 18.5C11 18.2239 11.2239 18 11.5 18H18.5C18.7761 18 19 18.2239 19 18.5C19 18.7761 18.7761 19 18.5 19H11.5Z"
								fill="#0e3768"
							></path>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M17.5 4H19.5C20.8807 4 22 5.11929 22 6.5C22 7.88071 20.8807 9 19.5 9H17.5C16.1193 9 15 7.88071 15 6.5C15 5.11929 16.1193 4 17.5 4ZM19.5 7C19.7761 7 20 6.77614 20 6.5C20 6.22386 19.7761 6 19.5 6H17.5C17.2239 6 17 6.22386 17 6.5C17 6.77614 17.2239 7 17.5 7H19.5Z"
								fill="#0e3768"
							></path>
						</svg>
					</div>
				</button>
				<button
					mat-menu-item
					(click)="convertTicketToTemplate()"
					matTooltip="Make Template"
				>
					<div class="d-flex justify-content-between align-items-center">
						<span>{{ "lang_make_template" | language }}</span>
						<i class="fa fa-inbox mnabricon" aria-hidden="true"></i>
					</div>
				</button>
				<button mat-menu-item (click)="showContracts()">
					<div class="d-flex justify-content-between align-items-center">
						<span>{{ "lang_contracts" | language }}</span>
						<i class="pi pi-copy mnabricon" aria-hidden="true"></i>
					</div>
				</button>
			</mat-menu>
		</div>
	</div>

	<div class="board-tasks" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
		<div class="row">
			<!-- start main status new -->
			<div
				class="col-12 col-sm-6 col-md-3"
				style="min-height: 130px; margin-bottom: 15px"
			>
				<div class="main-status">
					<div class="ms-header">
						<h3 class="ms-header-title" *ngIf="!newList?.length">
							{{ "lang_new" | language }}
						</h3>
						<h3 class="ms-header-title" *ngIf="newList?.length">
							{{ "lang_new" | language }} ({{ newList?.length }})
						</h3>
					</div>
					<div class="ms-content">
						<div
							class="msc-list"
							cdkDropList
							#newListRef="cdkDropList"
							[cdkDropListData]="newList"
							[cdkDropListConnectedTo]="[
								inprogressListRef,
								confirmListRef,
								doneListRef
							]"
							(cdkDropListDropped)="drop($event)"
							[id]="'new'"
						>
							<ng-container *ngFor="let item of newList">
								<div
									class="mscl-item"
									cdkDrag
									routerLink="/tasks/view/{{ ticketID }}/task/{{
										item?.task_id
									}}"
									*ngIf="item?.is_can_view"
								>
									<div class="taskNumTag">
										<span>{{ item?.task_no }}</span>
									</div>
									<div
										class="mscli-labels"
										id="mscli-labels{{ item?.task_id }}"
										*ngIf="
											item?.important_id != null && item?.important_id != '0'
										"
									>
										<div
											class="mscli-labels-label"
											[ngClass]="{
												'scale-animation': item?.important_id == '2'
											}"
											[attr.style]="
												item?.important_id == '1'
													? 'background-color:#0079bf'
													: item?.important_id == '2'
													? 'background-color:#f12b54'
													: 'background-color:#e3ac07'
											"
										>
											<span>{{ item?.important_name }}</span>
										</div>
										<div
											class="mscli-labels-label"
											[attr.style]="
												item?.status_done_name == 'متاخر'
													? 'background-color: #f12b54'
													: item?.status_done_name == 'متعثر'
													? 'background-color:#e3ac07'
													: 'background-color: #0079bf'
											"
											*ngIf="item?.status_done_name"
										>
											<span>{{ item?.status_done_name }}</span>
										</div>
									</div>
									<div
										class="mscli-header"
										[ngClass]="{
											no_labels:
												item?.important_id == null || item?.important_id == '0'
										}"
									>
										<h4 class="mscli-header-title" #itemref>
											{{ item?.title }}
										</h4>
										<div class="d-flex align-items-center">
											<span
												class="mscli-header-icon"
												(click)="updateTask($event, item)"
												*ngIf="item?.is_can_edit"
												matTooltip="{{ 'lang_edit' | language }}"
											>
												<i class="fa fa-pencil"></i>
											</span>
											<span
												class="mscli-header-icon"
												(click)="dublicateTask($event, item)"
												matTooltip="{{ 'lang_clone' | language }}"
											>
												<i class="fa fa-clone"></i>
											</span>
										</div>
									</div>
									<div class="mscli-content">
										<div class="task-badge">
											<i class="pi pi-credit-card"></i>
											<span>{{ item?.full_task_number }}</span>
										</div>
										<div
											class="task-badge"
											*ngIf="
												item?.total_points != '0' && item?.total_points != null
											"
										>
											<i
												class="pi pi-check-square"
												style="font-size: 14px"
												[ngStyle]="{
													color:
														(item?.total_checked_points ==
															item?.total_points) !=
														'0'
															? '#61bd4f'
															: '#5e6c84'
												}"
											></i>
											<span
												>{{ item?.total_checked_points }}/{{
													item?.total_points
												}}</span
											>
										</div>
										<div class="task-badge" *ngIf="item?.relateds_numbers">
											<i class="pi pi-share-alt"></i>
											<span>{{ item?.relateds_numbers | arrToStr }}</span>
										</div>
										<div
											class="task-badge"
											matTooltip="{{ 'lang_progress' | language }}"
										>
											<span>{{ item?.progres }} </span>
											<i class="pi pi-percentage" style="font-size: 12px"></i>
										</div>
									</div>
								</div>
								<div class="mscl-item" *ngIf="!item?.is_can_view">
									<div class="block-task">
										<i class="pi pi-lock"></i>
									</div>
									<div
										class="mscli-header"
										[ngClass]="{
											no_labels:
												item?.important_id == null || item?.important_id == '0'
										}"
									>
										<h4 class="mscli-header-title">{{ item?.title }}</h4>
										<span class="mscli-header-icon"> </span>
									</div>
									<div class="mscli-content">
										<div>
											<i class="pi pi-credit-card"></i>
											<span>{{ item?.full_task_number }}</span>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="ms-footer">
						<div class="ms-footer-add-btn">
							<button
								mat-button
								(click)="onAddTask('1')"
								*ngIf="ticketData?.canAddTask"
							>
								<i class="fa fa-plus"></i>
								<span>{{ "lang_add_task" | language }}</span>
							</button>
						</div>
						<div class="ms-footer-add-task" *ngIf="false"></div>
					</div>
				</div>
			</div>
			<!-- end main status new -->
			<!-- start main status in progress -->
			<div
				class="col-12 col-sm-6 col-md-3"
				style="min-height: 130px; margin-bottom: 15px"
			>
				<div class="main-status">
					<div class="ms-header">
						<h3 class="ms-header-title" *ngIf="!inprogressList.length">
							{{ "lang_in_progress" | language }}
						</h3>
						<h3 class="ms-header-title" *ngIf="inprogressList.length">
							{{ "lang_in_progress" | language }} ({{ inprogressList.length }})
						</h3>
					</div>
					<div class="ms-content">
						<div
							class="msc-list"
							cdkDropList
							#inprogressListRef="cdkDropList"
							[cdkDropListData]="inprogressList"
							[cdkDropListConnectedTo]="[
								newListRef,
								confirmListRef,
								doneListRef
							]"
							(cdkDropListDropped)="drop($event)"
							[id]="'inprogress'"
						>
							<ng-container *ngFor="let item of inprogressList">
								<div
									class="mscl-item"
									cdkDrag
									routerLink="/tasks/view/{{ ticketID }}/task/{{
										item?.task_id
									}}"
									*ngIf="item?.is_can_view"
								>
									<div class="taskNumTag">
										<span>{{ item?.task_no }}</span>
									</div>
									<div
										class="mscli-labels"
										id="mscli-labels{{ item?.task_id }}"
										*ngIf="
											item?.important_id != null && item?.important_id != '0'
										"
									>
										<div
											class="mscli-labels-label"
											[ngClass]="{
												'scale-animation': item?.important_id == '2'
											}"
											[attr.style]="
												item?.important_id == '1'
													? 'background-color:#0079bf'
													: item?.important_id == '2'
													? 'background-color:#f12b54'
													: 'background-color:#e3ac07'
											"
										>
											<span>{{ item?.important_name }}</span>
										</div>
										<div
											class="mscli-labels-label"
											[attr.style]="
												item?.status_done_name == 'متاخر'
													? 'background-color: #f12b54'
													: item?.status_done_name == 'متعثر'
													? 'background-color:#e3ac07'
													: 'background-color: #0079bf'
											"
											*ngIf="item?.status_done_name"
										>
											<span>{{ item?.status_done_name }}</span>
										</div>
									</div>
									<div
										class="mscli-header"
										[ngClass]="{
											no_labels:
												item?.important_id == null || item?.important_id == '0'
										}"
									>
										<h4 class="mscli-header-title">{{ item?.title }}</h4>
										<div class="d-flex align-items-center">
											<span
												class="mscli-header-icon"
												(click)="updateTask($event, item)"
												*ngIf="item?.is_can_edit"
												matTooltip="{{ 'lang_edit' | language }}"
											>
												<i class="fa fa-pencil"></i>
											</span>
											<span
												class="mscli-header-icon"
												(click)="dublicateTask($event, item)"
												matTooltip="{{ 'lang_clone' | language }}"
											>
												<i class="fa fa-clone"></i>
											</span>
										</div>
									</div>
									<div class="mscli-content">
										<div class="task-badge">
											<i class="pi pi-credit-card"></i>
											<span>{{ item?.full_task_number }}</span>
										</div>
										<div
											class="task-badge"
											*ngIf="
												item?.total_points != '0' && item?.total_points != null
											"
										>
											<i
												class="pi pi-check-square"
												style="font-size: 14px"
												[ngStyle]="{
													color:
														(item?.total_checked_points ==
															item?.total_points) !=
														'0'
															? '#61bd4f'
															: '#5e6c84'
												}"
											></i>
											<span
												>{{ item?.total_checked_points }}/{{
													item?.total_points
												}}</span
											>
										</div>
										<div class="task-badge" *ngIf="item?.relateds_numbers">
											<i class="pi pi-share-alt"></i>
											<span>{{ item?.relateds_numbers | arrToStr }}</span>
										</div>
										<div
											class="task-badge"
											matTooltip="{{ 'lang_progress' | language }}"
										>
											<span>{{ item?.progres }} </span>
											<i class="pi pi-percentage" style="font-size: 12px"></i>
										</div>
									</div>
								</div>
								<div class="mscl-item" *ngIf="!item?.is_can_view">
									<div class="block-task">
										<i class="pi pi-lock"></i>
									</div>
									<div
										class="mscli-header"
										[ngClass]="{
											no_labels:
												item?.important_id == null || item?.important_id == '0'
										}"
									>
										<h4 class="mscli-header-title">{{ item?.title }}</h4>
										<span class="mscli-header-icon">
											<!-- <i class="fa fa-pencil"></i> -->
										</span>
									</div>
									<div class="mscli-content">
										<div>
											<i class="pi pi-credit-card"></i>
											<span>{{ item?.full_task_number }}</span>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="ms-footer">
						<div class="ms-footer-add-btn">
							<button
								mat-button
								(click)="onAddTask('2')"
								*ngIf="ticketData?.canAddTask"
							>
								<i class="fa fa-plus"></i>
								<span>{{ "lang_add_task" | language }}</span>
							</button>
						</div>
						<div class="ms-footer-add-task" *ngIf="false"></div>
					</div>
				</div>
			</div>
			<!-- end main status in progress -->
			<!-- start main status confirm -->
			<div
				class="col-12 col-sm-6 col-md-3"
				style="min-height: 130px; margin-bottom: 15px"
			>
				<div class="main-status">
					<div class="ms-header">
						<h3 class="ms-header-title" *ngIf="!confirmList.length">
							{{ "lang_confirm" | language }}
						</h3>
						<h3 class="ms-header-title" *ngIf="confirmList.length">
							{{ "lang_confirm" | language }} ({{ confirmList?.length }})
						</h3>
					</div>
					<div class="ms-content">
						<div
							class="msc-list"
							cdkDropList
							#confirmListRef="cdkDropList"
							[cdkDropListData]="confirmList"
							[cdkDropListConnectedTo]="[
								newListRef,
								inprogressListRef,
								doneListRef
							]"
							(cdkDropListDropped)="drop($event)"
							[id]="'confirm'"
						>
							<ng-container *ngFor="let item of confirmList">
								<div
									class="mscl-item"
									cdkDrag
									routerLink="/tasks/view/{{ ticketID }}/task/{{
										item?.task_id
									}}"
									*ngIf="item?.is_can_view"
								>
									<div class="taskNumTag">
										<span>{{ item?.task_no }}</span>
									</div>
									<div
										class="mscli-labels"
										id="mscli-labels{{ item?.task_id }}"
										*ngIf="
											item?.important_id != null && item?.important_id != '0'
										"
									>
										<div
											class="mscli-labels-label"
											[ngClass]="{
												'scale-animation': item?.important_id == '2'
											}"
											[attr.style]="
												item?.important_id == '1'
													? 'background-color:#0079bf'
													: item?.important_id == '2'
													? 'background-color:#f12b54'
													: 'background-color:#e3ac07'
											"
										>
											<span>{{ item?.important_name }}</span>
										</div>
										<div
											class="mscli-labels-label"
											[attr.style]="
												item?.status_done_name == 'متاخر'
													? 'background-color: #f12b54'
													: item?.status_done_name == 'متعثر'
													? 'background-color:#e3ac07'
													: 'background-color: #0079bf'
											"
											*ngIf="item?.status_done_name"
										>
											<span>{{ item?.status_done_name }}</span>
										</div>
									</div>
									<div
										class="mscli-header"
										[ngClass]="{
											no_labels:
												item?.important_id == null || item?.important_id == '0'
										}"
									>
										<h4 class="mscli-header-title">{{ item?.title }}</h4>
										<div class="d-flex align-items-center">
											<span
												class="mscli-header-icon"
												(click)="updateTask($event, item)"
												*ngIf="item?.is_can_edit"
												matTooltip="{{ 'lang_edit' | language }}"
											>
												<i class="fa fa-pencil"></i>
											</span>
											<span
												class="mscli-header-icon"
												(click)="dublicateTask($event, item)"
												matTooltip="{{ 'lang_clone' | language }}"
											>
												<i class="fa fa-clone"></i>
											</span>
										</div>
									</div>
									<div class="mscli-content">
										<div class="task-badge">
											<i class="pi pi-credit-card"></i>
											<span>{{ item?.full_task_number }}</span>
										</div>
										<div
											class="task-badge"
											*ngIf="
												item?.total_points != '0' && item?.total_points != null
											"
										>
											<i
												class="pi pi-check-square"
												style="font-size: 14px"
												[ngStyle]="{
													color:
														(item?.total_checked_points ==
															item?.total_points) !=
														'0'
															? '#61bd4f'
															: '#5e6c84'
												}"
											></i>
											<span
												>{{ item?.total_checked_points }}/{{
													item?.total_points
												}}</span
											>
										</div>
										<div class="task-badge" *ngIf="item?.relateds_numbers">
											<i class="pi pi-share-alt"></i>
											<span>{{ item?.relateds_numbers | arrToStr }}</span>
										</div>
										<div
											class="task-badge"
											matTooltip="{{ 'lang_progress' | language }}"
										>
											<span>{{ item?.progres }} </span>
											<i class="pi pi-percentage" style="font-size: 12px"></i>
										</div>
									</div>
								</div>
								<div class="mscl-item" *ngIf="!item?.is_can_view">
									<div class="block-task">
										<i class="pi pi-lock"></i>
									</div>
									<div
										class="mscli-header"
										[ngClass]="{
											no_labels:
												item?.important_id == null || item?.important_id == '0'
										}"
									>
										<h4 class="mscli-header-title">{{ item?.title }}</h4>
										<span class="mscli-header-icon">
											<!-- <i class="fa fa-pencil"></i> -->
										</span>
									</div>
									<div class="mscli-content">
										<div>
											<i class="pi pi-credit-card"></i>
											<span>{{ item?.full_task_number }}</span>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="ms-footer">
						<div class="ms-footer-add-btn">
							<button
								mat-button
								(click)="onAddTask('3')"
								*ngIf="ticketData?.canAddTask"
							>
								<i class="fa fa-plus"></i>
								<span>{{ "lang_add_task" | language }}</span>
							</button>
						</div>
						<div class="ms-footer-add-task" *ngIf="false"></div>
					</div>
				</div>
			</div>
			<!-- end main status confirm -->
			<!-- start main status done -->
			<div
				class="col-12 col-sm-6 col-md-3"
				style="min-height: 130px; margin-bottom: 15px"
			>
				<div class="main-status">
					<div class="ms-header">
						<h3 class="ms-header-title" *ngIf="!doneList.length">
							{{ "lang_done" | language }}
						</h3>
						<h3 class="ms-header-title" *ngIf="doneList.length">
							{{ "lang_done" | language }} ({{ doneList?.length }})
						</h3>
					</div>
					<div class="ms-content">
						<div
							class="msc-list"
							cdkDropList
							#doneListRef="cdkDropList"
							[cdkDropListData]="doneList"
							[cdkDropListConnectedTo]="[
								newListRef,
								inprogressListRef,
								confirmListRef
							]"
							(cdkDropListDropped)="drop($event)"
							[id]="'done'"
						>
							<ng-container *ngFor="let item of doneList">
								<div
									class="mscl-item"
									cdkDrag
									routerLink="/tasks/view/{{ ticketID }}/task/{{
										item?.task_id
									}}"
									*ngIf="item?.is_can_view"
								>
									<div class="taskNumTag">
										<span>{{ item?.task_no }}</span>
									</div>
									<div
										class="mscli-labels"
										id="mscli-labels{{ item?.task_id }}"
										*ngIf="
											item?.important_id != null && item?.important_id != '0'
										"
									>
										<div
											class="mscli-labels-label"
											[ngClass]="{
												'scale-animation': item?.important_id == '2'
											}"
											[attr.style]="
												item?.important_id == '1'
													? 'background-color:#0079bf'
													: item?.important_id == '2'
													? 'background-color:#f12b54'
													: 'background-color:#e3ac07'
											"
										>
											<span>{{ item?.important_name }}</span>
										</div>
										<div
											class="mscli-labels-label"
											[attr.style]="
												item?.status_done_name == 'متاخر'
													? 'background-color: #f12b54'
													: item?.status_done_name == 'متعثر'
													? 'background-color:#e3ac07'
													: 'background-color: #0079bf'
											"
											*ngIf="item?.status_done_name"
										>
											<span>{{ item?.status_done_name }}</span>
										</div>
									</div>
									<div
										class="mscli-header"
										[ngClass]="{
											no_labels:
												item?.important_id == null || item?.important_id == '0'
										}"
									>
										<h4 class="mscli-header-title">{{ item?.title }}</h4>
										<div class="d-flex align-items-center">
											<span
												class="mscli-header-icon"
												(click)="updateTask($event, item)"
												*ngIf="item?.is_can_edit"
												matTooltip="{{ 'lang_edit' | language }}"
											>
												<i class="fa fa-pencil"></i>
											</span>
											<span
												class="mscli-header-icon"
												(click)="dublicateTask($event, item)"
												matTooltip="{{ 'lang_clone' | language }}"
											>
												<i class="fa fa-clone"></i>
											</span>
										</div>
									</div>
									<div class="mscli-content">
										<div class="task-badge">
											<i class="pi pi-credit-card"></i>
											<span>{{ item?.full_task_number }}</span>
										</div>
										<div
											class="task-badge"
											*ngIf="
												item?.total_points != '0' && item?.total_points != null
											"
										>
											<i
												class="pi pi-check-square"
												style="font-size: 14px"
												[ngStyle]="{
													color:
														(item?.total_checked_points ==
															item?.total_points) !=
														'0'
															? '#61bd4f'
															: '#5e6c84'
												}"
											></i>
											<span
												>{{ item?.total_checked_points }}/{{
													item?.total_points
												}}</span
											>
										</div>
										<div class="task-badge" *ngIf="item?.relateds_numbers">
											<i class="pi pi-share-alt"></i>
											<span>{{ item?.relateds_numbers | arrToStr }}</span>
										</div>
										<div
											class="task-badge"
											matTooltip="{{ 'lang_progress' | language }}"
										>
											<span>{{ item?.progres }} </span>
											<i class="pi pi-percentage" style="font-size: 12px"></i>
										</div>
									</div>
								</div>
								<div class="mscl-item" *ngIf="!item?.is_can_view">
									<div class="block-task">
										<i class="pi pi-lock"></i>
									</div>
									<div
										class="mscli-header"
										[ngClass]="{
											no_labels:
												item?.important_id == null || item?.important_id == '0'
										}"
									>
										<h4 class="mscli-header-title">{{ item?.title }}</h4>
										<span class="mscli-header-icon">
											<!-- <i class="fa fa-pencil"></i> -->
										</span>
									</div>
									<div class="mscli-content">
										<div>
											<i class="pi pi-credit-card"></i>
											<span>{{ item?.full_task_number }}</span>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="ms-footer">
						<div class="ms-footer-add-btn">
							<button
								mat-button
								(click)="onAddTask('4')"
								*ngIf="ticketData?.canAddTask"
							>
								<i class="fa fa-plus"></i>
								<span>{{ "lang_add_task" | language }}</span>
							</button>
						</div>
						<div class="ms-footer-add-task" *ngIf="false"></div>
					</div>
				</div>
			</div>
			<!-- end main status in done -->
		</div>
	</div>

	<div>
		<div class="row pt-4 mb-4">
			<!-- start comments -->
			<div class="col-12 col-md-6 col-lg-4">
				<div class="board-comments">
					<div class="bc-header">
						<i class="pi pi-th-large"></i>
						<h4>{{ "lang_activity" | language }}</h4>
					</div>
					<div class="bc-body">
						<div class="bcb-write-comment">
							<div class="bcbwc-avatar">
								<img src="{{ user_avatar }}" alt="avatar" *ngIf="user_avatar" />
								<img
									src="assets/images/avatar.png"
									alt="avatar"
									*ngIf="!user_avatar"
								/>
							</div>
							<div class="bcbwc-frame">
								<div class="bcbwc-box">
									<div>
										<textarea
											id=""
											name=""
											placeholder="{{ 'lang_comment' | language }}"
											(input)="autoResizeTxtarea(writeCommentTxtarea)"
											(focus)="onWriteCommentFocusin()"
											(focusout)="onWriteCommentFocusout()"
											[(ngModel)]="write_comment.description"
											#writeCommentTxtarea
										>
										</textarea>
										<flx-mentions
											[textInputElement]="writeCommentTxtarea"
											[menuTemplate]="menuTemplate"
											[triggerCharacter]="'@'"
											[getChoiceLabel]="getChoiceLabel"
											[searchRegexp]="'^([-&.\\w]+ *){0,3}$'"
											(search)="loadCommentMentions($event)"
											[closeMenuOnBlur]="true"
											[tagCssClass]="'mentionStyle'"
											[useCDKOverlay]="true"
											(selectedChoicesChange)="onMentionsCommentChange($event)"
											(menuHide)="onCommentMenuHide()"
										>
										</flx-mentions>
										<ng-template #menuTemplate let-selectChoice="selectChoice">
											<ul
												class="flx-selectable-list"
												flxLoader
												[flxLoaderVisible]="commentMentionLoading"
											>
												<li
													*ngFor="let user of commentMentionChoices"
													class="flx-selectable-list-item"
													(click)="selectChoice(user)"
												>
													<span
														title="{{
															lang_key == 'ar'
																? user.firstname_arabic +
																  ' ' +
																  user.lastname_arabic
																: user.firstname_english +
																  ' ' +
																  user.lastname_english
														}}"
														>{{
															lang_key == "ar"
																? user.firstname_arabic +
																  " " +
																  user.lastname_arabic
																: user.firstname_english +
																  " " +
																  user.lastname_english
														}}</span
													>
												</li>
												<li *ngIf="!commentMentionChoices?.length">
													<span>{{ "lang_no_data" | language }}</span>
												</li>
											</ul>
										</ng-template>
									</div>
									<div class="bcbwc-controls">
										<button
											class="bcbwc-save"
											(click)="onAddComment(writeCommentTxtarea)"
										>
											{{ "lang_save" | language }}
										</button>
										<div class="bcbwc-actions">
											<span></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="bcb-comments">
							<!-- start comment -->
							<ng-container
								*ngFor="let comment of ticketComments; let commentIndex = index"
							>
								<div class="bcbc-comment">
									<div class="bcbc-avatar">
										<img
											src="assets/images/avatar.png"
											alt="avatar"
											*ngIf="!comment?.user_image_64"
										/>
										<img
											src="{{ comment?.user_image_64 }}"
											alt="avatar"
											*ngIf="comment?.user_image_64"
										/>
									</div>
									<div class="bcbc-container">
										<div class="bcbc-container-name">
											<h4>{{ comment?.emp_name }}</h4>
											<span>{{ comment?.insert_date }}</span>
										</div>
										<div class="bcbc-frame">
											<div class="bcbc-box">
												<p>{{ comment?.notes_history }}</p>
											</div>
										</div>
										<div class="bcbc-actions">
											<span
												class="bcbc-actions-item"
												(click)="onUpdateComment(comment, 'comment')"
												*ngIf="comment?.emp_id == userId"
												>{{ "lang_edit" | language }}</span
											>
											<div
												class="bcbc-divider"
												*ngIf="comment?.emp_id == userId"
											></div>
											<span
												class="bcbc-actions-item"
												(click)="deleteComment(comment, commentIndex)"
												*ngIf="comment?.emp_id == userId"
												>{{ "lang_delete" | language }}</span
											>
											<div
												class="bcbc-divider"
												*ngIf="comment?.emp_id == userId"
											></div>
											<span
												class="bcbc-actions-item"
												(click)="comment.write_reply = true"
												*ngIf="!comment.write_reply"
												>{{ "lang_reply" | language }}</span
											>
											<span
												class="bcbc-actions-item"
												(click)="comment.write_reply = false"
												*ngIf="comment.write_reply"
												>{{ "lang_undo" | language }}</span
											>
											<div
												class="bcbc-divider"
												*ngIf="comment?.reply?.length"
											></div>
											<span
												class="bcbc-actions-item"
												(click)="comment.show_replys = true"
												*ngIf="!comment.show_replys && comment?.reply?.length"
												>{{ comment?.reply.length }}
												{{ "lang_replys" | language }}</span
											>
											<span
												class="bcbc-actions-item"
												(click)="comment.show_replys = false"
												*ngIf="comment.show_replys && comment?.reply?.length"
												>{{ "lang_hide" | language }}
												{{ "lang_replys" | language }}</span
											>
										</div>
									</div>
								</div>
								<!-- start replys -->
								<div class="bcbc-replys">
									<div class="bcbcr-list" *ngIf="comment?.show_replys">
										<div
											class="bcbcr-comment"
											*ngFor="
												let reply of comment?.reply;
												let replyIndex = index
											"
										>
											<div class="bcbcr-avatar">
												<img
													src="assets/images/avatar.png"
													alt="avatar"
													*ngIf="!reply?.user_image_64"
												/>
												<img
													src="{{ reply?.user_image_64 }}"
													alt="avatar"
													*ngIf="reply?.user_image_64"
												/>
											</div>
											<div class="bcbcr-container">
												<div class="bcbcr-container-name">
													<h4>{{ reply?.userName }}</h4>
													<span>{{ reply?.comment_date }}</span>
												</div>
												<div class="bcbcr-frame">
													<div class="bcbc-box">
														<p>{{ reply?.comment_content }}</p>
													</div>
												</div>
												<div class="bcbcr-actions">
													<span
														class="bcbcr-actions-item"
														(click)="onUpdateComment(reply, 'reply')"
														*ngIf="reply?.emp_id == userId"
														>{{ "lang_edit" | language }}</span
													>
													<div
														class="bcbcr-divider"
														*ngIf="reply?.emp_id == userId"
													></div>
													<span
														class="bcbcr-actions-item"
														(click)="deleteReply(reply)"
														*ngIf="reply?.emp_id == userId"
														>{{ "lang_delete" | language }}</span
													>
												</div>
											</div>
										</div>
									</div>
									<div
										class="bcbcr-list-write-reply"
										*ngIf="comment?.write_reply"
									>
										<div class="bcbcrlwr-avatar">
											<img
												src="{{ user_avatar }}"
												alt="avatar"
												*ngIf="user_avatar"
											/>
											<img
												src="assets/images/avatar.png"
												alt="avatar"
												*ngIf="!user_avatar"
											/>
										</div>
										<div class="bcbcrlwr-frame">
											<div class="bcbcrlwr-box">
												<div>
													<textarea
														placeholder="{{ 'lang_reply' | language }}"
														(input)="autoResizeTxtarea(writeReplyTxtarea)"
														(focus)="onWriteReplyFocusin()"
														(focusout)="onWriteReplyFocusout(commentIndex)"
														[(ngModel)]="write_reply[commentIndex].description"
														#writeReplyTxtarea
													>
													</textarea>
													<flx-mentions
														[textInputElement]="writeReplyTxtarea"
														[menuTemplate]="menuTemplate"
														[triggerCharacter]="'@'"
														[getChoiceLabel]="getChoiceLabel"
														[searchRegexp]="'^([-&.\\w]+ *){0,3}$'"
														(search)="loadReplyMentions($event)"
														[closeMenuOnBlur]="true"
														[tagCssClass]="'mentionStyle'"
														[useCDKOverlay]="true"
														(selectedChoicesChange)="
															onMentionsReplyChange($event)
														"
														(menuHide)="onReplyMenuHide()"
													>
													</flx-mentions>
													<ng-template
														#menuTemplate
														let-selectChoice="selectChoice"
													>
														<ul class="flx-selectable-list">
															<li
																*ngFor="let user of replyMentionChoices"
																class="flx-selectable-list-item"
																(click)="selectChoice(user)"
															>
																<span title="{{ user.emp_name }}">{{
																	user.emp_name
																}}</span>
															</li>
															<li *ngIf="!replyMentionChoices?.length">
																<span>Not Found</span>
															</li>
														</ul>
													</ng-template>
												</div>
												<div class="bcbcrlwr-controls">
													<button
														class="bcbcrlwr-save"
														(click)="
															onAddReply(commentIndex, comment?.history_id)
														"
													>
														{{ "lang_save" | language }}
													</button>
													<div class="bcbcrlwr-actions">
														<span></span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- end replys -->
							</ng-container>
							<!-- end comment -->
						</div>
					</div>
				</div>
			</div>
			<!-- end comments -->
			<!-- start ticket attachments -->
			<div class="col-12 col-md-6 col-lg-5">
				<div class="ticket-attachments">
					<div class="ta-header">
						<i class="pi pi-paperclip"></i>
						<h4>{{ "lang_attachments" | language }}</h4>
					</div>
					<div class="ta-list">
						<div
							class="tal-file"
							*ngFor="
								let attachment of ticket_attachments
									| slice: 0:ticketAttachmentsSlice
							"
						>
							<div
								class="talf-type"
								(click)="navigateNewTab(attachment?.path_file)"
							>
								<i class="fa fa-link" aria-hidden="true"></i>
							</div>
							<div class="talf-content">
								<div class="talf-content-title">
									{{ attachment?.file_name_en }}
								</div>
								<div class="talf-content-body">
									<span class="talfcb-date"
										>{{ "lang_created_date" | language }}
										{{ attachment?.insert_date }}</span
									>
									<div class="talfcb-divider"></div>
									<span
										class="talf-content-body-delete"
										(click)="onDeleteAttachment(attachment?.file_records_id)"
										>{{ "lang_delete" | language }}</span
									>
								</div>
							</div>
						</div>
						<div
							class="tal-file talf-action-size"
							*ngIf="ticket_attachments?.length > 2"
						>
							<span
								class="tal-file-action-size"
								*ngIf="ticketAttachmentsSlice == 2"
								(click)="ticketAttachmentsSlice = ticket_attachments?.length"
								>{{ "lang_view" | language }} {{ "lang_all" | language }}
								{{ "lang_attachments" | language }} ({{
									ticket_attachments?.length - ticketAttachmentsSlice
								}}
								{{ "lang_hidden" | language }})</span
							>
							<span
								class="tal-file-action-size"
								*ngIf="ticketAttachmentsSlice > 2"
								(click)="ticketAttachmentsSlice = 2"
								>{{ "lang_show_fewer" | language }}
								{{ "lang_attachments" | language }}</span
							>
						</div>
					</div>
					<!-- start add attachment -->
					<!-- <input
						type="file"
						hidden
						#attachmentInput
						id="attachmentInput"
						(change)="onAddAttachment($event.target.files)"
					/> -->
					<div class="ta-add">
						<button
							class="ta-add-btn task-btn"
							(click)="isAddAttach = true"
							*ngIf="!isAddAttach"
						>
							{{ "lang_add" | language }} {{ "lang_attachments" | language }}
						</button>
						<div *ngIf="isAddAttach" style="margin: 20px 30px">
							<div class="ta-add-inpts">
								<input
									type="text"
									[(ngModel)]="attachTitle"
									placeholder="{{ 'lang_attach_title' | language }}"
									class="form-control shadow-none"
								/>
								<input
									type="text"
									[(ngModel)]="attachFile"
									placeholder="{{ 'lang_file' | language }}"
									class="form-control shadow-none"
								/>
							</div>

							<div class="d-flex align-items-center">
								<button (click)="onAddAttachment()" class="add-attach-btn">
									{{ "lang_save" | language }}
								</button>
								<i
									class="fa fa-times red mr-2 ml-2"
									(click)="
										isAddAttach = false; attachFile = ''; attachTitle = ''
									"
								></i>
							</div>
						</div>
					</div>

					<!-- end add attachment -->
				</div>
			</div>
			<!-- end ticket attachments -->
			<!-- start ticket timeline -->
			<div class="col-12 col-lg-3">
				<div class="ticket-timeline">
					<div class="tt-header">
						<i class="pi pi-align-left"></i>
						<h4>
							<span>{{ "lang_history" | language }}</span>
							<span style="font-size: 13px; font-weight: 600">
								({{ timelineData?.length }})</span
							>
						</h4>
					</div>
					<div class="tt-body">
						<div class="ttb-list">
							<div class="ttb-list-item" *ngFor="let item of timelineData">
								<!-- <div class="ttbli-avatar">
									<img
										src="{{ item?.user_image_64 }}"
										alt="avatar"
										*ngIf="item?.user_image_64"
									/>
									<img
										src="assets/images/avatar.png"
										alt="avatar"
										*ngIf="!item?.user_image_64"
									/>
								</div> -->
								<div class="ttbli-content">
									<div class="ttbli-content-header">
										<p class="m-0 d-flex flex-wrap">
											<span class="bold" *ngIf="lang_key == 'en'">{{
												item?.firstname_english && item?.lastname_english
													? item?.firstname_english +
													  " " +
													  item?.lastname_english
													: item?.emp_name
											}}</span>
											<span class="bold" *ngIf="lang_key == 'ar'">{{
												item?.firstname_arabic && item?.lastname_arabic
													? item?.firstname_arabic + " " + item?.lastname_arabic
													: item?.emp_name
											}}</span>
											<span class="desc" *ngIf="lang_key == 'en'">{{
												item?.en_title
											}}</span>
											<span class="desc" *ngIf="lang_key == 'ar'">{{
												item?.ar_title
											}}</span>
										</p>
									</div>
									<div class="ttbli-content-date">{{ item?.insert_date }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end ticket timeline -->
		</div>
	</div>
</section>

<!-- user template popover -->
<ng-template #test>
	<div class="user-popover">
		<div class="up-header">
			<div class="up-header-content">
				<div class="up-header-img">
					<img src="assets/images/avatar.png" alt="" />
				</div>
				<div class="up-header-details">
					<h3>{{ user_popover.first_name }} {{ user_popover.last_name }}</h3>
				</div>
			</div>
		</div>
		<div class="up-body"></div>
	</div>
</ng-template>
