import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { PreviewPdfComponent } from "@shared/components/preview-pdf/preview-pdf.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import moment from "moment";
@Component({
  selector: "app-formct1-add-new",
  templateUrl: "./formct1-add-new.component.html",
  styleUrls: ["./formct1-add-new.component.scss"],
})
export class Formct1AddNewComponent implements OnInit {
  @Output() contractEvent = new EventEmitter();
  @Input() employee_data;
  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
  @Input() branch_id;
  @Input() contract_type;
  @Input() contract_data;
  form: FormGroup;
  other_allownces_datas: FormArray;
  other_terms_datas1: FormArray;
  other_terms_datas2: FormArray;
  lang_key = localStorage.getItem("lang_key") || "en";
  submitted = false;
  cperiods: any = [];
  pperiods: any = [];
  vacationdays: any = [];
  workingdays: any = [];
  workinghours: any = [];
  languages: any = [];
  users: any = [];
  markstatus = false;
  reviewstatus = false;
  otherallowances = false;
  otherterms = false;
  showmarkdelete = true;
  showreviewdelete = true;
  probationenddate = "";
  net_amount: any = 0.0;
  vacation_custom_date = "";
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  filename: string = this.lang.transform("lang_select_files");
  termsRemoved = [];
  contract_type_title = "";
  constructor(
    public ds: DataService,
    private _sweetAlert: SweetAlertService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public fb: FormBuilder,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    if (
      this.contract_type == "system_edit" ||
      this.contract_type == "manual_edit"
    ) {
      this.ds.dialogf(
        "",
        this.lang.transform("lang_any_edit_will_affect_from_the_strat_date")
      );
    }
    this.load_required_datas();
    moment.locale("en");
    if (this.contract_type == "system_renew") {
      this.contract_type_title = this.lang.transform("lang_system_renew");
    } else if (this.contract_type == "manual_renew") {
      this.contract_type_title = this.lang.transform("lang_manual_renew");
    } else if (this.contract_type == "system_edit") {
      this.contract_type_title = this.lang.transform("lang_system_edit");
    } else if (this.contract_type == "manual_edit") {
      this.contract_type_title = this.lang.transform("lang_manual_edit");
    }
    this.form = new FormGroup({
      signature_a_title: new FormControl("", [Validators.required]),
      signature_a_user_id: new FormControl(null, [Validators.required]),
      signature_b_title: new FormControl(""),
      signature_b_user_id: new FormControl(null),
      signature_c_title: new FormControl({ value: "", disabled: true }),
      signature_c_user_id: new FormControl({ value: null, disabled: true }),

      penalty_clause_first_party: new FormControl("", [Validators.required]),
      penalty_clause_second_party: new FormControl("", [Validators.required]),
      needOtherTerms: new FormControl("0", [Validators.required]),
      other_terms1: this.fb.array([]),
      other_terms2: this.fb.array([]),
    });

    this.firstFormGroup = new FormGroup({
      contract_source: new FormControl("", [Validators.required]),
      contract_source_id: new FormControl(""),
    });

    this.secondFormGroup = new FormGroup({
      subject: new FormControl(this.lang.transform("lang_create_contract"), [
        Validators.required,
      ]),
      transaction_from: new FormControl(
        { value: this.employee_data.branch_name, disabled: true },
        [Validators.required]
      ),
      transaction_to: new FormControl(this.employee_data.branch_id, [
        Validators.required,
      ]),
      work_domain: new FormControl(this.employee_data.work_domain || null, [
        Validators.required,
      ]),
      work_location: new FormControl(this.employee_data.work_location, [
        Validators.required,
      ]),
      work_type: new FormControl(this.employee_data.work_type, [
        Validators.required,
      ]),
      employee_number: new FormControl(this.employee_data.employee_number, [
        Validators.required,
      ]),
    });
    let isRenew = false;
    if (
      this.contract_type == "system_renew" ||
      this.contract_type == "manual_renew"
    ) {
      isRenew = true;
    }
    this.thirdFormGroup = new FormGroup({
      joining_date_english: new FormControl("", [Validators.required]),
      joining_date_arabic: new FormControl(null, [Validators.required]),
      notification_period: new FormControl(null, [Validators.required]),
      probation_period: new FormControl(
        isRenew ? "0" : null,
        isRenew ? [] : [Validators.required, Validators.max(90)]
      ),
      contract_start_date_english: new FormControl("", [Validators.required]),
      contract_period: new FormControl(null, [Validators.required]),
      auto_renew: new FormControl("0", [Validators.required]),
      contract_end_date_english: new FormControl(
        { value: "", disabled: true },
        [Validators.required]
      ),
    });

    this.fourthFormGroup = new FormGroup({
      vacation_paid_days: new FormControl(null, [Validators.required]),
      vacation_custom_date: new FormControl(""),
      working_days_per_week: new FormControl(null),
      working_hours: new FormControl(null, [Validators.required]),
      working_hours_type: new FormControl("", [Validators.required]),
      basic_salary: new FormControl(""),
      home_allowance: new FormControl(""),
      ticket_amount: new FormControl(""),
      needOtherAllowances: new FormControl("0", [Validators.required]),
      other_allowances: this.fb.array([]),
    });

    this.fifthFormGroup = new FormGroup({
      bank_short_code: new FormControl(this.employee_data.bank_short_code),
      account_number: new FormControl(this.employee_data.account_number),
    });

    this.other_allownces_datas = this.fourthFormGroup.get(
      "other_allowances"
    ) as FormArray;
    this.other_terms_datas1 = this.form.get("other_terms1") as FormArray;
    this.other_terms_datas2 = this.form.get("other_terms2") as FormArray;
    this.get_advanced_search_meta_data();
    this.loadBankDetails();
    if (
      this.contract_type == "system_edit" ||
      this.contract_type == "manual_edit"
    ) {
      this.thirdFormGroup.addControl(
        "notes",
        new FormControl(null, Validators.required)
      );
    }
    if (
      this.contract_type == "system_renew" ||
      this.contract_type == "manual_renew" ||
      this.contract_type == "system_edit" ||
      this.contract_type == "manual_edit"
    ) {
      this.firstFormGroup.patchValue(this.contract_data);
      if (this.firstFormGroup.value.contract_source == 4) {
        this.form.addControl(
          "attachment[file]",
          new FormControl(null, Validators.required)
        );
        this.form.addControl(
          "attachment[title]",
          new FormControl("", Validators.required)
        );
      }
      this.show_fillDataInForm();
    }
  }

  positions = [];
  public get_advanced_search_meta_data() {
    this.positions = [];
    this.ds
      .getActionByUrl([], "human_resources/get_advanced_search_meta_data")
      .subscribe((data) => {
        if (data.status) {
          this.positions = data.records.positions;
        }
      });
  }

  showContractInput = false;
  show_contracts() {
    if (this.firstFormGroup.value.contract_source == 2) {
      this.loadContract();
      /*  this.loadContract() */
      if (this.form.contains("attachment[file]")) {
        this.form.removeControl("attachment[file]");
      }
      if (this.form.contains("attachment[title]")) {
        this.form.removeControl("attachment[title]");
      }
    } else if (this.firstFormGroup.value.contract_source == 3) {
      this.loadJopOffers();
      if (this.form.contains("attachment[file]")) {
        this.form.removeControl("attachment[file]");
      }
      if (this.form.contains("attachment[title]")) {
        this.form.removeControl("attachment[title]");
      }
    } else if (this.firstFormGroup.value.contract_source == 4) {
      this.form.addControl(
        "attachment[file]",
        new FormControl(null, Validators.required)
      );
      this.form.addControl(
        "attachment[title]",
        new FormControl("", Validators.required)
      );
    } else {
      if (this.form.contains("attachment[file]")) {
        this.form.removeControl("attachment[file]");
      }
      if (this.form.contains("attachment[title]")) {
        this.form.removeControl("attachment[title]");
      }
      this.showContractInput = false;
      this.secondFormGroup.reset();
      this.thirdFormGroup.reset();
      this.fourthFormGroup.reset();
      this.firstFormGroup.patchValue({
        contract_source_id: "",
      });
      this.secondFormGroup.patchValue({
        subject: this.lang.transform("lang_create_contract"),
        transaction_from: this.employee_data.branch_name,
        transaction_to: this.employee_data.branch_id,
        work_domain: this.employee_data.work_domain,
        work_location: this.employee_data.work_location,
        employee_number: this.employee_data.employee_number,
        work_type: this.employee_data.work_type,
      });
      this.fourthFormGroup.patchValue({
        working_hours_type: "day",
        needOtherAllowances: "0",
        other_allowances: [],
      });
      this.firstFormGroup.controls.contract_source_id.clearValidators();
      this.firstFormGroup.controls.contract_source_id.updateValueAndValidity();
    }
  }

  getContractStartDate() {
    let formData = new FormData();
    formData.append("employee_no", this.employee_data?.employee_number || "");
    this.ds.post("form/FORM_CT1/get_contract_renew_date", formData).subscribe(
      (res) => {
        if (res?.status) {
          this.thirdFormGroup
            .get("contract_start_date_english")
            .setValue(new Date(res?.records));
          this.calculate_contract_enddate();
        } else {
          this._sweetAlert.errorToast(res?.error, 3000);
          this.thirdFormGroup.get("contract_start_date_english").setValue(null);
          this.thirdFormGroup.get("contract_end_date_english").setValue(null);
        }
      },
      (err) => {
        this._sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          3000
        );
        this.thirdFormGroup.get("contract_start_date_english").setValue(null);
        this.thirdFormGroup.get("contract_end_date_english").setValue(null);
      }
    );
  }

  show_fillDataInForm() {
    if (
      this.contract_type == "system_renew" ||
      this.contract_type == "system_edit"
    ) {
      this.firstFormGroup.get("contract_source").setValue("1");
    } else if (
      this.contract_type == "manual_renew" ||
      this.contract_type == "manual_edit"
    ) {
      this.firstFormGroup.get("contract_source").setValue("4");
      this.form.addControl(
        "attachment[file]",
        new FormControl(null, Validators.required)
      );
      this.form.addControl(
        "attachment[title]",
        new FormControl("", Validators.required)
      );
    }
    if (
      this.contract_type == "system_renew" ||
      this.contract_type == "manual_renew" ||
      this.contract_type == "system_edit" ||
      this.contract_type == "manual_edit"
    ) {
      let isRenew = false;
      let isEdit = false;
      if (
        this.contract_type == "system_renew" ||
        this.contract_type == "manual_renew"
      ) {
        isRenew = true;
      }
      if (
        this.contract_type == "system_edit" ||
        this.contract_type == "manual_edit"
      ) {
        isEdit = true;
      }
      const param = new FormData();
      param.append(
        "contract_id",
        isRenew
          ? this.contract_data?.contract_id
          : isEdit
          ? this.contract_data?.contract_id
          : this.firstFormGroup.value.contract_source_id
      );
      let url = "form/FORM_CT1/select_contract";
      this.ds.postActionByUrl(param, url).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            let contract = data.records.data;
            let response = data.records;
            this.form.patchValue(contract);
            this.secondFormGroup.patchValue({
              subject: isEdit
                ? this.lang.transform("lang_edit_contract")
                : this.lang.transform("lang_renew_contract"),
              transaction_from: this.employee_data.branch_name,
              transaction_to: this.employee_data.branch_id,
              work_domain: contract?.work_domain,
              work_location: contract?.work_location,
              work_type: contract?.work_type,
              employee_number: contract?.employee_number,
            });

            this.thirdFormGroup.patchValue({
              joining_date_english: contract?.joining_date_english
                ? new Date(contract?.joining_date_english)
                : "",
              notification_period: contract?.notification_period
                ? contract?.notification_period
                : "",
              joining_date_arabic: contract?.joining_date_arabic
                ? this.ds.arcon(contract?.joining_date_arabic)
                : "",
              probation_period: isRenew ? "0" : contract?.probation_period,
              contract_start_date_english: contract?.contract_start_date_english
                ? new Date(contract?.contract_start_date_english)
                : "",
              contract_period: contract?.contract_period,
              contract_end_date_english: contract?.contract_end_date_english
                ? moment(
                    contract?.contract_end_date_english,
                    "YYYY/MM/DD"
                  ).format("DD/MM/YYYY")
                : "",
            });

            this.fourthFormGroup.patchValue({
              vacation_paid_days: contract?.vacation_paid_days,
              vacation_custom_date: contract?.vacation_custom_date
                ? new Date(contract?.vacation_custom_date)
                : "",
              working_days_per_week: contract?.working_days_per_week,
              working_hours: contract?.working_hours,
              basic_salary: contract?.basic_salary,
              ticket_amount:
                contract?.ticket_amount == "0.00"
                  ? ""
                  : contract?.ticket_amount,
              working_hours_type: contract?.working_hours_type,
              home_allowance:
                contract?.home_allowance == "0.00"
                  ? ""
                  : contract?.home_allowance,
              needOtherAllowances:
                response.form_ct1_other_allowances?.records &&
                response.form_ct1_other_allowances?.records?.length > 0
                  ? 1
                  : 0,
            });
            if (
              response.form_ct1_other_allowances?.records &&
              response.form_ct1_other_allowances?.records?.length > 0
            ) {
              this.otherallowances = true;
              for (
                let index = 0;
                index < response.form_ct1_other_allowances?.records?.length;
                index++
              ) {
                const element =
                  response.form_ct1_other_allowances?.records[index];
                this.add_new_others({
                  other_allowance_description_en:
                    element.other_allowance_description_en,
                  other_allowance_description_ar:
                    element.other_allowance_description_ar,
                  other_allowance_amount: element.other_allowance_amount,
                });
              }
            }

            this.calculate_net_amount();
            if (isRenew) {
              this.getContractStartDate();
              this.other_terms_datas1.clear();
              this.other_terms_datas2.clear();
            }
            if (isEdit) {
              this.other_terms_datas1.clear();
              this.other_terms_datas2.clear();
            }
            if (
              response.form_ct1_data_additional_terms?.records &&
              response.form_ct1_data_additional_terms?.records?.length > 0
            ) {
              this.form.patchValue({
                needOtherTerms: 1,
              });
              this.otherterms = true;
              for (
                let index = 0;
                index <
                response?.form_ct1_data_additional_terms?.records?.length;
                index++
              ) {
                const element =
                  response?.form_ct1_data_additional_terms?.records[index];
                if (element.terms_type == "FP") {
                  this.add_new_terms1({
                    terms_description_english: element.terms_content_english,
                    terms_description_arabic: element.terms_content_arabic,
                    terms_type: element.terms_type || "FP",
                    required_status: element.required_status || "optional",
                    setting_contract_terms_id:
                      element?.setting_contract_terms_id || null,
                  });
                } else if (element.terms_type == "SP") {
                  this.add_new_terms2({
                    terms_description_english: element.terms_content_english,
                    terms_description_arabic: element.terms_content_arabic,
                    terms_type: element.terms_type || "SP",
                    required_status: element.required_status || "optional",
                    setting_contract_terms_id:
                      element?.setting_contract_terms_id || null,
                  });
                }
              }
            }
          } else {
            /* this.ds.dialogf('', data.error); */
            this._sweetAlert.errorMessage(data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this._sweetAlert.errorMessage(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );

      /*  this.loadContract() */
    } else if (this.firstFormGroup.value.contract_source == 3) {
      const param = new FormData();
      param.append("offer_id", this.firstFormGroup.value.contract_source_id);

      let url = `form/FORM_CT1/select_job_offer`;
      this.ds.postActionByUrl(param, url).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            let contract = data.records.data;
            let response = data.records;
            this.secondFormGroup.patchValue({
              subject: this.lang.transform("lang_new_contract"),
              transaction_from: this.employee_data.branch_name,
              transaction_to: this.employee_data.branch_id,
              work_domain: contract?.work_domain,
              work_location: contract?.work_location,
              work_type: contract?.work_type,

              employee_number: contract?.employee_number,
            });

            this.thirdFormGroup.patchValue({
              joining_date_english: contract?.joining_date_english
                ? new Date(contract?.joining_date_english)
                : "",
              notification_period: contract?.notification_period
                ? contract?.notification_period
                : "",
              joining_date_arabic: contract?.joining_date_arabic
                ? this.ds.arcon(contract?.joining_date_arabic)
                : "",
              probation_period: contract?.probation_period,
              contract_start_date_english: contract?.contract_start_date_english
                ? new Date(contract?.contract_start_date_english)
                : "",
              contract_period: contract?.contract_period,
              contract_end_date_english: contract?.contract_end_date_english
                ? moment(
                    contract?.contract_end_date_english,
                    "YYYY/MM/DD"
                  ).format("DD/MM/YYYY")
                : "",
            });

            this.fourthFormGroup.patchValue({
              vacation_paid_days: contract?.vacation_paid_days,
              vacation_custom_date: contract?.vacation_custom_date
                ? new Date(contract?.vacation_custom_date)
                : "",
              working_days_per_week: contract?.working_days_per_week,
              working_hours: contract?.working_hours,
              ticket_amount:
                contract?.ticket_amount == "0.00"
                  ? ""
                  : contract?.ticket_amount,
              working_hours_type: contract?.working_hours_type,
              basic_salary: contract?.basic_salary,
              home_allowance: contract?.home_allowance,
              needOtherAllowances:
                response.form_jf_other_allowances?.records &&
                response.form_jf_other_allowances?.records?.length > 0
                  ? 1
                  : 0,
            });
            if (
              response.form_jf_other_allowances?.records &&
              response.form_jf_other_allowances?.records?.length > 0
            ) {
              this.otherallowances = true;
              for (
                let index = 0;
                index < response.form_jf_other_allowances?.records?.length;
                index++
              ) {
                const element =
                  response.form_jf_other_allowances?.records[index];
                this.add_new_others({
                  other_allowance_description_en:
                    element.other_allowance_description_en,
                  other_allowance_description_ar:
                    element.other_allowance_description_ar,
                  other_allowance_amount: element.other_allowance_amount,
                });
              }
            }

            this.calculate_net_amount();

            if (
              response.form_jf_data_additional_terms?.records &&
              response.form_jf_data_additional_terms?.records?.length > 0
            ) {
              this.form.patchValue({
                needOtherTerms: 1,
              });
              this.otherterms = true;
              for (
                let index = 0;
                index <
                response?.form_jf_data_additional_terms?.records?.length;
                index++
              ) {
                const element =
                  response?.form_jf_data_additional_terms?.records[index];
                if (element.terms_type == "FP") {
                  this.add_new_terms1({
                    terms_description_english: element.terms_content_english,
                    terms_description_arabic: element.terms_content_arabic,
                    terms_type: element.terms_type || "FP",
                    setting_contract_terms_id:
                      element?.setting_contract_terms_id || null,
                  });
                } else if (element.terms_type == "SP") {
                  this.add_new_terms2({
                    terms_description_english: element.terms_content_english,
                    terms_description_arabic: element.terms_content_arabic,
                    terms_type: element.terms_type || "SP",
                    setting_contract_terms_id:
                      element?.setting_contract_terms_id || null,
                  });
                }
              }
            }
          } else {
            this._sweetAlert.errorMessage(data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this._sweetAlert.errorMessage(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
          /* this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error')); */
        }
      );

      /* this.firstFormGroup.controls.contract_source_id.clearValidators()
      this.firstFormGroup.controls.contract_source_id.updateValueAndValidity() */
    }
  }

  contracts = [];
  public loadContract() {
    this.contracts = [];
    const param = new FormData();
    param.append("id", this.employee_data.employee_number);

    let url = "hrcontracts";
    this.ds
      .getActionByUrl(this.ds.formData2string(param), url + "/1/10000")
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.showContractInput = true;
            this.firstFormGroup.controls.contract_source_id.setValidators(
              Validators.required
            );
            this.firstFormGroup.controls.contract_source_id.updateValueAndValidity();
            this.contracts = data.records;
          } else {
            this._sweetAlert.errorMessage(data.error);
            /* this.ds.dialogf('', data.error); */
          }
        },
        (error) => {
          this.spinner.hide();
          this._sweetAlert.errorMessage(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
          /* this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error')); */
        }
      );
  }

  public loadJopOffers() {
    this.contracts = [];
    const param = new FormData();
    param.append("employee_number", this.employee_data.employee_number);
    let url = `form/FORM_CT1/get_job_offers`;

    this.ds.getActionByUrl(this.ds.formData2string(param), url).subscribe(
      (data) => {
        this.spinner.hide();
        if (data.status) {
          this.showContractInput = true;
          this.firstFormGroup.controls.contract_source_id.setValidators(
            Validators.required
          );
          this.firstFormGroup.controls.contract_source_id.updateValueAndValidity();
          this.contracts = data.records;
        } else {
          this._sweetAlert.errorMessage(data.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this._sweetAlert.errorMessage(
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }

  public isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;

    if (charCode == 44 || charCode == 190) {
      return true;
    }
    if (
      (charCode > 31 && (charCode < 48 || charCode > 57 || charCode == 101)) ||
      charCode == 46
    ) {
      return false;
    }
    return true;
  }
  contract_types: any = [];
  contract_terms = [];
  public load_required_datas() {
    let param = new FormData();
    param.append("lang_key", this.lang_key);
    param.append("employee_number", this.employee_data.employee_number);
    let url = [
      "form/FORM_CT1/ctperiods",
      "form/FORM_CT1/pbperiods",
      "form/FORM_CT1/vacationdays",
      "form/FORM_CT1/weekdays",
      "form/FORM_CT1/workinghours",
      "form/FORM_CT1/appusers",
      `form/FORM_CT1/get_contracts`,
      "form/FORM_CT1/get_contracts_type",
    ];
    this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(
      (res) => {
        this.spinner.hide();
        if (res[0].status) {
          this.cperiods = res[0].records;
        }
        if (res[1].status) {
          this.pperiods = res[1].records;
        }
        if (res[2].status) {
          this.vacationdays = res[2].records;
        }
        if (res[3].status) {
          this.workingdays = res[3].records;
        }
        if (res[4].status) {
          this.workinghours = res[4].records;
        }
        if (res[5].status) {
          this.users = res[5].records;
          let marks = [];
          let reviews = [];
          this.users.forEach((v, k) => {
            if (v.position == "A" && v.value) {
              this.form.get("signature_a_title").setValue(v.title);
              this.form.get("signature_a_user_id").setValue(v.value);
            }
            if (v.position == "B" && v.value) {
              this.form.get("signature_b_title").setValue(v.title);
              this.form.get("signature_b_user_id").setValue(v.value);
            }
            if (v.position == "C" && v.value) {
              this.form.get("signature_c_title").setValue(v.title);
              this.form.get("signature_c_user_id").setValue(v.value);
            }
            if (v.position == "M" && v.value) {
              marks.push(v.value);
            }
            if (v.position == "R" && v.value) {
              reviews.push(v.value);
            }
          });
          if (this.form.get("signature_b_user_id").value) {
          } else {
            this.form.get("signature_b_title").setValue("");
            this.form.get("signature_b_title").enable();
            this.form.get("signature_b_user_id").setValue(null);
            this.form.get("signature_b_user_id").enable();
          }
        }
        if (res[6].status) {
          this.contracts = res[6].records;
        }
        if (res[7].status) {
          this.contract_types = res[7].records;
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
    this.spinner.show();
    this.ds.get("human_resources/get_setting_contract_terms", []).subscribe(
      (data) => {
        this.spinner.hide();
        if (data.status) {
          this.contract_terms = data.records;
          this.optionsFP_ar = this.contract_terms.map((ct) => {
            if (ct?.terms_type == "FP") {
              return ct.terms_content_arabic;
            }
          });
          this.optionsFP_ar = this.optionsFP_ar.filter((el) => el != undefined);
          this.optionsSP_ar = this.contract_terms.map((ct) => {
            if (ct?.terms_type == "SP") {
              return ct.terms_content_arabic;
            }
          });
          this.optionsSP_ar = this.optionsSP_ar.filter((el) => el != undefined);
          this.optionsFP = this.contract_terms.map((ct) => {
            if (ct?.terms_type == "FP") {
              return ct.terms_content_english;
            }
          });
          this.optionsFP = this.optionsFP.filter((el) => el != undefined);
          this.optionsSP = this.contract_terms.map((ct) => {
            if (ct?.terms_type == "SP") {
              return ct.terms_content_english;
            }
          });
          this.optionsSP = this.optionsSP.filter((el) => el != undefined);
          if (this.contract_terms.length > 0) {
            this.form.patchValue({
              needOtherTerms: 1,
            });
            this.otherterms = true;
          }

          for (let index = 0; index < this.contract_terms.length; index++) {
            const element = this.contract_terms[index];
            if (element.terms_type == "FP") {
              this.add_new_terms1({
                terms_description_english: element.terms_content_english,
                terms_description_arabic: element.terms_content_arabic,
                terms_type: element.terms_type ? element.terms_type : "FP",
                required_status: element.required_status,
                setting_contract_terms_id:
                  element?.setting_contract_terms_id || null,
              });
            } else if (element.terms_type == "SP") {
              this.add_new_terms2({
                terms_description_english: element.terms_content_english,
                terms_description_arabic: element.terms_content_arabic,
                terms_type: element.terms_type ? element.terms_type : "SP",
                required_status: element.required_status,
                setting_contract_terms_id:
                  element?.setting_contract_terms_id || null,
              });
            }
          }
        } else {
          this._sweetAlert.errorMessage(data.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this._sweetAlert.errorMessage(
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  banklists = [];
  loadBankDetails() {
    this.banklists = [];
    this.ds
      .getActionByUrl([], "human_resources/getbanks/")
      .subscribe((data) => {
        if (data.status) {
          this.banklists = data.banks;
        }
      });
  }
  filteredOptionsFP: any;
  filteredOptionsFP_ar: any;
  filteredOptionsSP: any;
  filteredOptionsSP_ar: any;
  optionsFP: string[] = [];
  optionsFP_ar: string[] = [];
  optionsSP: string[] = [];
  optionsSP_ar: string[] = [];
  _filter(value: string, lang = "en", type = "fp"): any {
    const filterValue = value.toLowerCase();
    if (lang == "ar" && type == "fp") {
      this.filteredOptionsFP_ar = this.optionsFP_ar.filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else if (lang == "ar" && type == "sp") {
      this.filteredOptionsSP_ar = this.optionsSP_ar.filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else if (lang == "en" && type == "fp") {
      this.filteredOptionsFP = this.optionsFP.filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else if (lang == "en" && type == "sp") {
      this.filteredOptionsSP = this.optionsSP.filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    }
  }

  checkForRequired() {
    let required = false;
    for (let index = 0; index < this.contract_terms.length; index++) {
      const element = this.contract_terms[index];
      if (element.required_status == "required") {
        required = true;
        break;
      }
    }
    return required;
  }
  //Other Allowances Start
  get othersgroup() {
    return this.fourthFormGroup.get("other_allowances") as FormArray;
  }
  public others_group() {
    return this.fourthFormGroup.get("other_allowances") as FormArray;
  }
  public add_new_others(obj: any = false) {
    if (this.other_allownces_datas.length < 5) {
      this.other_allownces_datas.push(this.other_records(obj));
    }
  }
  public other_records(obj: any = false): FormGroup {
    return this.fb.group({
      other_allowance_description_en: new FormControl(
        obj.other_allowance_description_en,
        [Validators.required]
      ),
      other_allowance_amount: new FormControl(obj.other_allowance_amount, [
        Validators.required,
      ]),
      other_allowance_description_ar: new FormControl(
        obj.other_allowance_description_ar,
        [Validators.required]
      ),
    });
  }
  public remove_other_items(index) {
    if (this.other_allownces_datas.length > 1) {
      this.other_allownces_datas.removeAt(index);
    }
  }
  //Other Allowances End
  //Other Terms & Conditions Start
  get termsgroup1() {
    return this.form.get("other_terms1") as FormArray;
  }
  public terms_group1() {
    return this.form.get("other_terms1") as FormArray;
  }
  public add_new_terms1(obj: any = false) {
    this.other_terms_datas1.push(this.terms_records1(obj));
  }
  public terms_records1(obj: any = false): FormGroup {
    return this.fb.group({
      terms_description_english: new FormControl(
        obj.terms_description_english,
        [Validators.required]
      ),
      terms_description_arabic: new FormControl(obj.terms_description_arabic, [
        Validators.required,
      ]),
      terms_type: new FormControl(obj.terms_type || "FP", [
        Validators.required,
      ]),
      required_status: [obj.required_status || "optional"],
      setting_contract_terms_id: [obj?.setting_contract_terms_id || null],
    });
  }
  public remove_terms_items1(index) {
    if (this.other_terms_datas1.length > 1) {
      this.other_terms_datas1.removeAt(index);
    }
  }
  //Other Terms & Conditions Start
  get termsgroup2() {
    return this.form.get("other_terms2") as FormArray;
  }
  public terms_group2() {
    return this.form.get("other_terms2") as FormArray;
  }
  public add_new_terms2(obj: any = false) {
    this.other_terms_datas2.push(this.terms_records2(obj));
  }
  public terms_records2(obj: any = false): FormGroup {
    return this.fb.group({
      terms_description_english: new FormControl(
        obj.terms_description_english,
        [Validators.required]
      ),
      terms_description_arabic: new FormControl(obj.terms_description_arabic, [
        Validators.required,
      ]),
      terms_type: new FormControl(obj.terms_type || "SP", [
        Validators.required,
      ]),
      required_status: [obj.required_status || "optional"],
      setting_contract_terms_id: [obj?.setting_contract_terms_id || null],
    });
  }
  public remove_terms_items2(index) {
    if (this.other_terms_datas2.length > 1) {
      this.other_terms_datas2.removeAt(index);
    }
  }
  public calculate_net_amount() {
    let amount: any = 0;
    let basic_salary: any = parseFloat(
      this.fourthFormGroup.get("basic_salary").value || 0
    );
    let home_allowance: any = parseFloat(
      this.fourthFormGroup.get("home_allowance").value || 0
    );
    this.other_allownces_datas.value.forEach((v, k) => {
      amount += parseFloat(v.other_allowance_amount || 0);
    });
    this.net_amount = (
      parseFloat(basic_salary) +
      parseFloat(home_allowance) +
      parseFloat(amount)
    ).toFixed(2);
  }
  //Other Terms & Conditions End
  public search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append("search", key.term);
    param.append("lang_key", this.lang_key);
    param.append("user_type_id", "1");
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
      .subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      });
  }
  public enable_field(field) {
    if (field == "mark") {
      this.form.get("needMark").setValue("1");
      this.form.get("marks").setValidators(Validators.required);
      this.form.get("marks").updateValueAndValidity();
      this.markstatus = true;
    } else if (field == "review") {
      this.form.get("needReview").setValue("1");
      this.form.get("reviews").setValidators(Validators.required);
      this.form.get("reviews").updateValueAndValidity();
      this.reviewstatus = true;
    } else if (field == "allowances") {
      this.otherallowances = true;
      this.other_allownces_datas.clear();
      this.fourthFormGroup.get("needOtherAllowances").setValue("1");
      this.add_new_others();
    } else if (field == "terms") {
      this.otherterms = true;
      this.other_terms_datas1.clear();
      this.other_terms_datas2.clear();
      this.form.get("needOtherTerms").setValue("1");
      this.add_new_terms1();
      this.add_new_terms2();
    }
  }
  public disable_field(field) {
    if (field == "mark") {
      this.form.get("needMark").setValue("0");
      this.form.get("marks").setValue(null);
      this.form.get("marks").clearValidators();
      this.form.get("marks").updateValueAndValidity();
      this.markstatus = false;
    } else if (field == "review") {
      this.form.get("needReview").setValue("0");
      this.form.get("reviews").setValue(null);
      this.form.get("reviews").clearValidators();
      this.form.get("reviews").updateValueAndValidity();
      this.reviewstatus = false;
    } else if (field == "allowances") {
      this.fourthFormGroup.get("needOtherAllowances").setValue("0");
      this.other_allownces_datas.clear();
      this.otherallowances = false;
    } else if (field == "terms") {
      this.form.get("needOtherTerms").setValue("0");
      this.other_terms_datas1.clear();
      this.other_terms_datas2.clear();
      this.otherterms = false;
    }
    this.form.updateValueAndValidity();
  }
  public calculate_contract_enddate() {
    this.thirdFormGroup.get("contract_end_date_english").setValue("");
    if (
      this.thirdFormGroup.get("contract_start_date_english").value &&
      this.thirdFormGroup.get("contract_period").value &&
      this.thirdFormGroup.get("contract_period").value != "0"
    ) {
      let date = new Date(
        this.thirdFormGroup.get("contract_start_date_english").value
      );
      /*  let finaldate = date.setFullYear(date.getFullYear() + parseInt(this.thirdFormGroup.get('contract_period').value));
      let Edate = new Date(this.datepipe.transform(new Date(finaldate), 'yyyy/MM/dd')); */
      let endDate = moment(date)
        .add(this.thirdFormGroup.get("contract_period").value, "day")
        .format();
      this.thirdFormGroup
        .get("contract_end_date_english")
        .setValue(this.datepipe.transform(endDate, "yyyy/MM/dd"));
    }
  }
  public probation_enddate() {
    this.probationenddate = "";
    if (
      this.thirdFormGroup.get("joining_date_english").value &&
      this.thirdFormGroup.get("contract_start_date_english").value !=
        undefined &&
      this.thirdFormGroup.get("probation_period").value &&
      this.thirdFormGroup.get("probation_period").value != "0"
    ) {
      let date = new Date(
        this.thirdFormGroup.get("joining_date_english").value
      );
      let endDate = moment(date)
        .add(this.thirdFormGroup.get("probation_period").value, "day")
        .format();
      /* let finaldate = date.setMonth(date.getMonth() + parseInt(this.thirdFormGroup.get('probation_period').value)); */
      this.probationenddate = this.datepipe.transform(
        new Date(endDate),
        "yyyy/MM/dd"
      );
    }
  }
  public preview_form() {
    this.alert.clear();
    this.submitted = true;
    if (this.form.valid) {
      this.submitted = false;
      this.spinner.show();
      this.ds
        .postActionByUrl(this.getformdata(), "form/FORM_CT1/pr/0")
        .subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
              let id = "showPDFPreview";
              if (data.extension != "zip") {
                let src =
                  "data:" + data["content-type"] + ";base64," + data.base64;
                const dialogRef = this.dialog.open(PreviewPdfComponent, {
                  disableClose: false,
                  width: "100%",
                  data: {
                    src: src,
                    base64: data.base64,
                  },
                });

                /* ($('#' + id) as any).modal({
                backdrop: 'static',
                keyboard: false
              });
              ($('#' + id) as any).modal('show'); */
              }
              document.querySelector("iframe").src =
                "data:" + data["content-type"] + ";base64," + data.base64;
            } else {
              this.alert.error(data.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        );
    } else {
      this.alert.error(this.lang.transform("lang_fill_required_fields"));
    }
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  public submit(stepper) {
    this.alert.clear();
    this.submitted = true;
    if (this.form.valid) {
      this.submitted = false;
      this.spinner.show();
      this.ds
        .postActionByUrl(this.getformdata(), "form/FORM_CT1/cr/0")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              //this.alert.success(res.msg);
              this.goForward(stepper);
              this.alert.success(this.lang.transform("lang_success"));
              this.form.reset();
              this.firstFormGroup.reset();
              this.secondFormGroup.reset();
              this.thirdFormGroup.reset();
              this.fourthFormGroup.reset();
              this.fifthFormGroup.reset();
              setTimeout(() => {
                this.router.navigate(["/tasks/alltasks"]);
              }, 1000);
            } else {
              this.alert.error(res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        );
    }
  }
  governmentSubmit(stepper) {
    this.alert.clear();
    this.submitted = true;
    if (this.form.valid) {
      this.submitted = false;
      this.spinner.show();
      this.ds
        .postActionByUrl(this.getformdata(), "form/FORM_CT1/create_C_contracts")
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              //this.alert.success(res.msg);
              this.goForward(stepper);
              this.alert.success(this.lang.transform("lang_success"));
              this.form.reset();
              this.firstFormGroup.reset();
              this.secondFormGroup.reset();
              this.thirdFormGroup.reset();
              this.fourthFormGroup.reset();
              this.fifthFormGroup.reset();
              setTimeout(() => {
                this.contractEvent.emit();
              }, 1000);
            } else {
              this.alert.error(res.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        );
    }
  }
  private getformdata() {
    if (
      this.contract_type == "system_edit" ||
      this.contract_type == "manual_edit" ||
      this.contract_type == "system_renew" ||
      this.contract_type == "manual_renew"
    ) {
      this.firstFormGroup.patchValue({
        contract_source_id: this.contract_data?.contract_id,
      });
    }
    let param = this.ds.json2formdata({
      ...this.firstFormGroup.value,
      ...this.secondFormGroup.value,
      ...this.thirdFormGroup.value,
      contract_period: this.thirdFormGroup.value.contract_period
        ? this.thirdFormGroup.value.contract_period | 0
        : 0,
      ...this.fourthFormGroup.value,
      ...this.fifthFormGroup.value,
    });
    param.append(
      "signature_a_title",
      this.form.get("signature_a_title").value || ""
    );
    param.append(
      "signature_a_user_id",
      this.form.get("signature_a_user_id").value || ""
    );
    param.append(
      "signature_b_title",
      this.form.get("signature_b_title").value || ""
    );
    param.append(
      "signature_b_user_id",
      this.form.get("signature_b_user_id").value || ""
    );
    param.append(
      "signature_c_title",
      this.form.get("signature_c_title").value || ""
    );
    param.append(
      "signature_c_user_id",
      this.form.get("signature_c_user_id").value || ""
    );
    param.append(
      "penalty_clause_first_party",
      this.form.get("penalty_clause_first_party").value || ""
    );
    param.append(
      "penalty_clause_first_party",
      this.form.get("penalty_clause_first_party").value || ""
    );
    param.append(
      "penalty_clause_second_party",
      this.form.get("penalty_clause_second_party").value || ""
    );
    param.append("needOtherTerms", this.form.get("needOtherTerms").value || "");
    let contract_type = "new";
    if (
      this.contract_type == "system_renew" ||
      this.contract_type == "manual_renew"
    ) {
      contract_type = "renew";
    }
    if (
      this.contract_type == "system_edit" ||
      this.contract_type == "manual_edit"
    ) {
      contract_type = "edit";
    }
    param.set("contract_type", contract_type || "new");
    param.set(
      "contract_start_date_english",
      moment(
        this.thirdFormGroup.get("contract_start_date_english").value
      ).format("YYYY/MM/DD") || ""
    );
    param.set(
      "joining_date_english",
      moment(this.thirdFormGroup.get("joining_date_english").value).format(
        "YYYY/MM/DD"
      ) || ""
    );
    param.set(
      "vacation_custom_date",
      this.fourthFormGroup.get("vacation_custom_date").value
        ? moment(this.fourthFormGroup.get("vacation_custom_date").value).format(
            "YYYY/MM/DD"
          )
        : ""
    );
    param.append(
      "first_party_user",
      this.form.get("signature_a_user_id").value || ""
    );
    let terms1 = this.other_terms_datas1?.value?.filter((el) => {
      return el?.required_status != "required";
    });

    let terms2 = terms1.concat(
      this.other_terms_datas2?.value?.filter((el) => {
        return el?.required_status != "required";
      })
    );
    for (let index = 0; index < terms2?.length; index++) {
      const element = terms2[index];
      param.set(
        `other_terms[${index}][terms_description_english]`,
        element?.terms_description_english
      );
      param.set(
        `other_terms[${index}][terms_description_arabic]`,
        element?.terms_description_arabic
      );
      param.set(`other_terms[${index}][terms_type]`, element?.terms_type);
      param.set(
        `other_terms[${index}][required_status]`,
        element?.required_status
      );
    }
    param.set("not_required", this.termsRemoved?.toString() || "");
    if (this.firstFormGroup.value.contract_source == 4) {
      param.set(
        "attachment[title]",
        this.form.get("attachment[title]").value || ""
      );
      param.set(
        "attachment[file]",
        this.form.get("attachment[file]").value || ""
      );
    }
    return param;
  }

  selected;
  isLinear = true;
  selectedIndex: number = 0;
  selectionChange(e: any) {
    this.selectedIndex = e.selectedIndex;
  }

  onRemoveTerms(termsId) {
    if (termsId && !this.termsRemoved.includes(termsId)) {
      this.termsRemoved.push(termsId);
    }
  }
}
