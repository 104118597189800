import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguagePipe } from '@shared/pipes/language.pipe';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html'
})
export class AlertDialogComponent {
  message: string = this.lang.transform('lang_error');
  title:string = this.lang.transform('lang_alert');
  constructor(
    private lang: LanguagePipe,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AlertDialogComponent>) {
      this.title = data.title || this.title;
      this.message = data.message || this.message;
      this.dialogRef.updateSize('300vw','300vw')
  }
  close(): void {
    this.dialogRef.close();
  }
}