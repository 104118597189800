<section class="panel-expansion">
    <mat-accordion class="appusergroups" >
        <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="'true'">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_group' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15 martb0">
                    <div class="row">
                        <div class="col-12">
                            <input type="text" class="form-control almnabrformcontrol mb-10" (keyup)="get_groups();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                            <!--<div class="input-group input-group-sm mb-10">
                                <input type="text" class="form-control almnabrformcontrol" (keyup)="get_groups();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                                <span class="input-group-btn">
                                    <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" (click)="openModalA(userGroupAction);form.reset();form.get('module_key').setValue('');form.get('active_to_users').setValue('1');" id="btnSearch" type="button" matTooltip="{{'lang_add' | language }}"></button>
                                </span>
                            </div>-->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsuGrp checkbox" id="allItemsuGrp" (click)="checkallItemsuGrp($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_module_name' | language }}</td>
                                            <td>{{'lang_group_key' | language }}</td>
                                            <td>{{'lang_title_english' | language }}</td>
                                            <td>{{'lang_title_arabic' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="wholeData.length != '0' " class="AppendList">
                                        <tr *ngFor="let row of tabledata; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{row.group_key}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemuGrp checkbox" value="{{row.group_key}}" (click)="checkeachItemuGrp($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{row.modulename}}</td>
                                            <td>{{row.group_key}}</td>
                                            <td>{{row.group_name_english}}</td>
                                            <td>{{row.group_name_arabic}}</td>
                                            <td>
                                                ----
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="wholeData.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="7" align="center">{{nodata}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="tabledata.length != '0' " class="card-footer padb0">
                    <div class="row">
                        <div *ngIf="admin" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon none" (click)="deleteBulkDatauGrp();" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-6 mb-10">
                            {{'lang_total' | language }} : {{collectionSize}}
                        </div>
                        <div class="col-lg-5 col-sm-6 mb-10">
                            <ngb-pagination [collectionSize]="collectionSize" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [(page)]="page" [pageSize]="pageSize" (pageChange)="build_table_data()"></ngb-pagination>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <select class="customselect" [(ngModel)]="pageSize" (change)="build_table_data()">
                                <option [ngValue]="10">10</option>
                                <option [ngValue]="20">20</option>
                                <option [ngValue]="50">50</option>
                                <option [ngValue]="100">100</option>
                                <option [ngValue]="250">250</option>
                                <option [ngValue]="500">500</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>









<ng-template #userGroupAction>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_group' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefA.hide();">&times;</button>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit();">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="group_key">{{'lang_group_key' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="group_name_english" placeholder="{{'lang_group_key' | language }}"/>
                            <mat-error *ngIf="form.controls['group_key'].touched && form.controls['group_key'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="module_key">{{'lang_module_name' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <select type="text" class="form-control almnabrformcontrol" formControlName="module_key" matTooltip="{{'lang_module_name' | language }}">
                                <option value="">{{'lang_choose_options' | language}}</option>
                                <option *ngFor="let module of modules" value="{{module.module_key}}">{{module.module_phrase_val}}</option>
                            </select>
                            <mat-error *ngIf="form.controls['module_key'].touched && form.controls['module_key'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="group_name_english">{{'lang_title_english' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="group_name_english" placeholder="{{'lang_title_english' | language }}"/>
                            <mat-error *ngIf="form.controls['group_name_english'].touched && form.controls['group_name_english'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="group_name_arabic">{{'lang_title_arabic' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="group_name_arabic" placeholder="{{'lang_title_arabic' | language }}"/>
                            <mat-error *ngIf="form.controls['group_name_arabic'].touched && form.controls['group_name_arabic'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="active_to_users">{{'lang_allow_to_users' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <select type="text" class="form-control almnabrformcontrol" formControlName="active_to_users" matTooltip="{{'lang_allow_to_users' | language }}">
                                <option value="">{{'lang_choose_options' | language}}</option>
                                <option value="1">{{'lang_yes' | language}}</option>
                                <option value="0">{{'lang_no' | language}}</option>
                            </select>
                            <mat-error *ngIf="form.controls['active_to_users'].touched && form.controls['active_to_users'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefA.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>