<section>
  <div class="row mb-3">
    <div class="col-12 col-sm-9 mb-2"></div>
    <div class="col-12 col-sm-3 mb-2">
      <button
        class="albutton mnabrbutton"
        mat-button
        (click)="onAddEditCustomDivision(createTemp)"
      >
        <i class="fa fa-plus mnabricon mr-1 ml-1" style="color: #fff"></i>
        <span>{{ "lang_add" | language }}</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive dropdownyes customResponsive">
        <table border="1" class="table CustomTable">
          <thead>
            <tr>
              <td>#</td>
              <td>{{ "lang_title_english" | language }}</td>
              <td>{{ "lang_title_arabic" | language }}</td>
              <td>{{ "lang_group_type_title_english" | language }}</td>
              <td>{{ "lang_group_type_title_arabic" | language }}</td>
              <td>{{ "lang_action" | language }}</td>
            </tr>
          </thead>
          <tbody class="AppendList">
            <tr
              *ngFor="
                let list of customDivisionList;
                let i = index;
                let odd = odd;
                let even = even
              "
              [ngClass]="{ odd: odd, even: even }"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ list.division_custom_name_en }}</td>
              <td>{{ list.division_custom_name_ar }}</td>
              <td>{{ list.platform_group1_title_english }}</td>
              <td>{{ list.platform_group1_title_arabic }}</td>
              <td>
                <i
                  class="fa fa-pencil-square mnabricon ml-2 mr-2"
                  (click)="onAddEditCustomDivision(createTemp, list)"
                ></i>
                <i
                  class="fa fa-trash red"
                  (click)="deleteCustomDivision(list)"
                ></i>
              </td>
            </tr>
            <tr *ngIf="!customDivisionList?.length">
              <td colspan="6" class="text-center">
                {{ "lang_no_data" | language }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<ng-template #createTemp>
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_add" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <!-- Modal body -->
    <div class="modal-body">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12 mb-2">
            <div class="form-group">
              <label for="division_custom_name_en">{{
                "lang_title_english" | language
              }}</label>
              <input
                type="text"
                class="form-control"
                name="division_custom_name_en"
                id="division_custom_name_en"
                formControlName="division_custom_name_en"
              />
            </div>
          </div>
          <div class="col-12 mb-2">
            <div class="form-group">
              <label for="division_custom_name_ar">{{
                "lang_title_arabic" | language
              }}</label>
              <input
                type="text"
                class="form-control"
                name="division_custom_name_ar"
                id="division_custom_name_ar"
                formControlName="division_custom_name_ar"
              />
            </div>
          </div>
          <div class="col-12 mb-2">
            <div class="form-group">
              <label for="division_ids">{{
                "lang_group_one" | language
              }}</label>
              <ng-select
                [items]="group1List"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                appendTo="body"
                clearAllText="Clear"
                placeholder="{{ 'lang_group_one' | language }}"
                formControlName="division_ids"
                id="group1_id"
                (search)="get_divisions($event)"
              >
              </ng-select>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer justify-content-between">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRef.hide()"
        style="width: 40%"
      >
        {{ "lang_close" | language }}
      </button>
      <button
        type="button"
        class="albutton mnabrbutton"
        style="width: 40% !important"
        (click)="onSubmit()"
        mat-button
      >
        {{ "lang_submit" | language }}
      </button>
    </div>
  </div>
</ng-template>
