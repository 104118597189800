<h3 mat-dialog-title class="d-flex align-items-center justify-content-between">
	<span>{{ "lang_add" | language }}</span>
	<i class="fa fa-close mnabricon" mat-dialog-close></i>
</h3>
<mat-dialog-content class="mat-typography">
	<div class="row">
		<div class="col-12 col-sm-6 mb-2 mt-2">
			<label class="mb-10"
				>{{ "lang_contractor_manager_step_require" | language }} :
				<span class="red">*</span>
			</label>
			<br />
			<mat-radio-group [(ngModel)]="contractor_manager_step_require">
				<mat-radio-button value="1">{{
					"lang_yes" | language
				}}</mat-radio-button>
				<mat-radio-button value="0">{{
					"lang_no" | language
				}}</mat-radio-button>
			</mat-radio-group>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div style="width: 100px">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onSubmit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
