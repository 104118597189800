<breadcrumb></breadcrumb>

<div
  *ngIf="
    actionmethod !== 'CTRCR' &&
    actionmethod !== 'ECTRCR' &&
    actionmethod !== 'JNGCR' &&
    actionmethod !== 'V1CR' &&
    actionmethod !== 'LNCR' &&
    actionmethod !== 'HRPR' &&
    actionmethod !== 'DECR' &&
    actionmethod !== 'BNCE' &&
    actionmethod !== 'OTCR' &&
    actionmethod !== 'STE' &&
    actionmethod !== 'VOL1' &&
    actionmethod !== 'EPP' &&
    actionmethod !== 'VioView' &&
    ds.allowMe('human_resources', 'human_resources_view') &&
    viewdata
  "
>
  <section class="panel-expansion matborderno">
    <div class="row">
      <div class="col-md-2 col-sm-4 col-xs-12 mb-10">
        <a routerLink="/human_resources/employees/showAll">
          <button type="button" class="albutton">
            {{ "lang_dashboard" | language }}
          </button>
        </a>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-12 mb-10">
        <a routerLink="/human_resources/employees">
          <button type="button" class="albutton">
            {{ "lang_goto_list" | language }}
          </button>
        </a>
      </div>
      <div
        class="col-md-2 col-sm-4 col-xs-12 mb-10"
        *ngIf="(edit || is_admin) && viewdata.employee_status == '1'"
      >
        <a routerLink="/human_resources/edit/{{ id }}/edit/{{ branch_id }}">
          <button type="button" class="albutton">
            {{ "lang_edit" | language }}
          </button>
        </a>
      </div>
    </div>
    <mat-accordion class="stylepanels">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_human_resources_emp_details" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15 mb-3">
            <div
              class="row"
              [ngStyle]="
                lang_key == 'ar'
                  ? {
                      'padding-left': '10px',
                      'padding-right': '10px'
                    }
                  : null
              "
            >
              <div class="col-xs-12 col-12 pad0 suppervision-stepper">
                <div class="wrapper">
                  <owl-carousel-o [options]="customOptions" #stepperCar>
                    <ng-template carouselSlide id="s1">
                      <div (click)="moveStepper('human_resources_id_details')">
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-user fa-icon stepper-header-icon stepper-active human_resources_id_details"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{
                            'lang_human_resources_id_details' | language
                          }}"
                          style="text-align: center"
                        >
                          {{ "lang_human_resources_id_details" | language }}
                        </div>
                      </div>
                    </ng-template>

                    <ng-template carouselSlide id="s2">
                      <div
                        (click)="moveStepper('human_resources_other_details')"
                      >
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-university fa-icon stepper-header-icon human_resources_other_details"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{ 'lang_other_details' | language }}"
                          style="text-align: center"
                        >
                          {{ "lang_other_details" | language }}
                        </div>
                      </div>
                    </ng-template>

                    <ng-template carouselSlide id="s3">
                      <div
                        (click)="
                          moveStepper('human_resources_contract_details')
                        "
                      >
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-files-o fa-icon stepper-header-icon human_resources_contract_details"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{
                            'lang_human_resources_contract_details' | language
                          }}"
                          style="text-align: center"
                        >
                          {{
                            "lang_human_resources_contract_details" | language
                          }}
                        </div>
                      </div>
                    </ng-template>
                    <ng-template carouselSlide id="s4">
                      <div (click)="moveStepper('human_resources_document')">
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-link fa-icon stepper-header-icon human_resources_document"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{ 'lang_documents' | language }}"
                          style="text-align: center"
                        >
                          {{ "lang_documents" | language }}
                        </div>
                      </div>
                    </ng-template>
                    <ng-container>
                      <ng-template carouselSlide id="s5">
                        <div (click)="moveStepper('vacations')">
                          <div class="step">
                            <div class="st">
                              <i
                                class="icon-5x fa fa-address-book fa-icon stepper-header-icon vacations"
                              ></i>
                            </div>
                          </div>
                          <div
                            matTooltip="{{ 'lang_vacations' | language }}"
                            style="text-align: center"
                          >
                            {{ "lang_vacations" | language }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>

                    <ng-container>
                      <ng-template carouselSlide id="s6">
                        <div (click)="moveStepper('modules')">
                          <div class="step">
                            <div class="st">
                              <i
                                class="icon-5x fa fa-address-book fa-icon stepper-header-icon modules"
                              ></i>
                            </div>
                          </div>
                          <div
                            matTooltip="{{ 'lang_modules' | language }}"
                            style="text-align: center"
                          >
                            {{ "lang_modules" | language }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-container>
                      <ng-template carouselSlide id="s7">
                        <div (click)="moveStepper('finanial_details')">
                          <div class="step">
                            <div class="st">
                              <!-- 	<i
																class="icon-5x fa fa-comments-o fa-icon stepper-header-icon finanial_details"
															></i> -->
                              <mat-icon
                                class="icon-5x stepper-header-icon finanial_details"
                                >attach_money</mat-icon
                              >
                            </div>
                          </div>
                          <div
                            matTooltip="{{
                              'lang_finanial_details' | language
                            }}"
                            style="text-align: center"
                          >
                            {{ "lang_finanial_details" | language }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-container>
                      <ng-template carouselSlide id="s8">
                        <div (click)="moveStepper('projects')">
                          <div class="step">
                            <div class="st">
                              <!-- 	<i
																class="icon-5x fa fa-comments-o fa-icon stepper-header-icon finanial_details"
															></i> -->
                              <mat-icon
                                class="icon-5x stepper-header-icon projects"
                                >attach_money</mat-icon
                              >
                            </div>
                          </div>
                          <div
                            matTooltip="{{ 'lang_projects' | language }}"
                            style="text-align: center"
                          >
                            {{ "lang_projects" | language }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                  </owl-carousel-o>
                  <button
                    (click)="
                      lang_key == 'en' ? stepperCar.prev() : stepperCar.next()
                    "
                    class="carousel-btn-pre albutton mnabrbutton"
                  >
                    <i class="fa fa-chevron-left"></i>
                  </button>
                  <button
                    (click)="
                      lang_key == 'en' ? stepperCar.next() : stepperCar.prev()
                    "
                    class="carousel-btn-next albutton mnabrbutton"
                  >
                    <i class="fa fa-chevron-right"></i>
                  </button>
                </div>

                <mat-horizontal-stepper
                  #stepper
                  class="pad0"
                  (selectionChange)="selectionChange($event)"
                >
                  <mat-step>
                    <ng-container
                      *ngIf="selectedName == 'human_resources_id_details'"
                    >
                      <div class="card">
                        <i class="together-icon">
                          <i
                            *ngIf="viewdata.employee_status == '1' && add"
                            matTooltip="{{
                              'lang_upload_attachments' | language
                            }}"
                            class="fa fa-upload s2icon mnabricon"
                            (click)="
                              openModalA(addNeedUploads);
                              emptyAttachForm('ID0001')
                            "
                          ></i>
                          <i
                            *ngIf="attachments.id0001"
                            matTooltip="{{
                              'lang_recent_attachment' | language
                            }} : {{ attachments.id0001_d }}"
                            class="fa fa-paperclip s2icon mnabricon"
                            (click)="preview(attachments.id0001)"
                          ></i>
                        </i>
                        <div class="card-header">
                          {{ "lang_human_resources_id_details" | language }}
                        </div>
                        <div class="card-body p-0">
                          <div class="mbrl15 martb0">
                            <div class="row">
                              <div class="col-md-9 col-sm-12 col-xs-12">
                                <div class="row">
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{
                                        "lang_human_resources_id_number"
                                          | language
                                      }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.employee_id_number }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_reference_no" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.e_reference_no }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_branch" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.branch_name }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{
                                        "lang_human_resources_nameen" | language
                                      }}
                                    </div>
                                    <div class="mb-10">
                                      <b
                                        >{{ viewdata.settings_name_english }}
                                        {{ viewdata.firstname_english }}
                                        {{ viewdata.secondname_english }}
                                        {{ viewdata.thirdname_english }}
                                        {{ viewdata.lastname_english }}</b
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{
                                        "lang_human_resources_namear" | language
                                      }}
                                    </div>
                                    <div class="mb-10">
                                      <b
                                        >{{ viewdata.settings_name_arabic }}
                                        {{ viewdata.firstname_arabic }}
                                        {{ viewdata.secondname_arabic }}
                                        {{ viewdata.thirdname_arabic }}
                                        {{ viewdata.lastname_arabic }}</b
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_expiry_date" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b
                                        >{{
                                          viewdata.iqama_expiry_date_english
                                        }}
                                        -
                                        {{
                                          viewdata.iqama_expiry_date_arabic
                                        }}</b
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{
                                        "lang_human_resources_copy_number"
                                          | language
                                      }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.copy_number }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{
                                        "lang_human_resources_birth_date"
                                          | language
                                      }}
                                    </div>
                                    <div class="mb-10">
                                      <b
                                        >{{ viewdata.birth_date_english }} -
                                        {{ viewdata.birth_date_arabic }}</b
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{
                                        "lang_human_resources_gender" | language
                                      }}
                                    </div>
                                    <div class="mb-10">
                                      <b *ngIf="viewdata.gender == 'M'">{{
                                        "lang_human_resources_male" | language
                                      }}</b>
                                    </div>
                                    <div class="mb-10">
                                      <b *ngIf="viewdata.gender == 'F'">{{
                                        "lang_human_resources_female" | language
                                      }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{
                                        "lang_human_resources_nationality"
                                          | language
                                      }}
                                    </div>
                                    <div class="mb-10">
                                      <img
                                        *ngIf="viewdata.nationality"
                                        src="./assets/images/nations/{{
                                          viewdata.nationality.toLowerCase()
                                        }}.svg"
                                        class="img-responsive cntryimage"
                                      />
                                      <b> {{ viewdata.countryname }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_user_type" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.typename }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{
                                        "lang_human_resources_job_title_iqama"
                                          | language
                                      }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.job_title_iqama }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{
                                        "lang_human_resources_job_title"
                                          | language
                                      }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.jobname }}</b>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_status" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b
                                        *ngIf="viewdata.employee_status == '0'"
                                        class="red"
                                        >{{ "lang_inactive" | language }}</b
                                      >
                                      <b
                                        *ngIf="viewdata.employee_status == '1'"
                                        class="green"
                                        >{{ "lang_active" | language }}</b
                                      >
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_marital_status" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b
                                        *ngIf="viewdata.marital_status == 'S'"
                                        >{{ "lang_single" | language }}</b
                                      >
                                      <b
                                        *ngIf="viewdata.marital_status == 'M'"
                                        >{{ "lang_married" | language }}</b
                                      >
                                      <b
                                        *ngIf="viewdata.marital_status == 'D'"
                                        >{{ "lang_diversed" | language }}</b
                                      >
                                      <b
                                        *ngIf="viewdata.marital_status == 'O'"
                                        >{{ "lang_others" | language }}</b
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_religion" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.religion }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_work_domain" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.work_domain }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_work_location" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.work_location }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_work_type" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b *ngIf="viewdata.work_type == '1'">{{
                                        "lang_fulltime" | language
                                      }}</b>
                                      <b *ngIf="viewdata.work_type == '2'">{{
                                        "lang_parttime" | language
                                      }}</b>
                                      <b *ngIf="viewdata.work_type == '3'">{{
                                        "lang_contract" | language
                                      }}</b>
                                      <b *ngIf="viewdata.work_type == '4'">{{
                                        "lang_others" | language
                                      }}</b>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_ondate" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.created_datetime }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{
                                        "lang_legal_situation_status" | language
                                      }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{
                                        viewdata.legal_situation_status == "1"
                                          ? ("lang_yes" | language)
                                          : ("lang_no" | language)
                                      }}</b>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-3 col-sm-12 col-xs-12 pad0 text-center"
                              >
                                <p>
                                  {{ "lang_myprofile" | language }} :
                                  {{ profile_percentage }}%
                                </p>
                                <ngb-progressbar
                                  class="mb-10"
                                  [value]="profile_percentage"
                                  [striped]="true"
                                  [animated]="true"
                                  ><i
                                    >{{ profile_percentage }}%</i
                                  ></ngb-progressbar
                                >
                                <b class="green">
                                  {{ viewdata.age_in_years + " "
                                  }}{{ "lang_age" | language }}</b
                                >
                                <div class="row">
                                  <div
                                    class="col-md-12 col-sm-12 col-xs-12 pad0"
                                  >
                                    <div class="text-center">
                                      <label
                                        >{{
                                          "lang_human_resources_profile_image"
                                            | language
                                        }}
                                        :
                                      </label>
                                      <div class="avatar-upload">
                                        <div
                                          *ngIf="
                                            (edit || is_admin) &&
                                            viewdata.employee_status == '1'
                                          "
                                          class="avatar-edit"
                                          matTooltip="{{
                                            'lang_select_files' | language
                                          }}"
                                          placement="top"
                                        >
                                          <input
                                            #profileImage
                                            name="profile_image"
                                            (click)="
                                              this.value = null;
                                              getemployeedetails()
                                            "
                                            accept=".png, .jpg, .jpeg"
                                            id="profile_image"
                                            type="file"
                                            class="form-control almnabrformcontrol profile_image"
                                            (change)="onFileChange($event)"
                                          />
                                          <label
                                            (click)="profileImage?.click()"
                                            for="imageUpload"
                                          ></label>
                                        </div>
                                        <img
                                          *ngIf="fileurl"
                                          src="{{ fileurl }}"
                                          class="avatar-preview"
                                        />
                                        <img
                                          *ngIf="
                                            ((!viewdata.profile_image &&
                                              viewdata.gender == 'M' &&
                                              !view_profile_image) ||
                                              (viewdata.profile_image &&
                                                !view_profile_image &&
                                                viewdata.gender == 'M')) &&
                                            !fileurl
                                          "
                                          src="./assets/images/male.png"
                                          class="avatar-preview redborder"
                                          id="imagePreview"
                                        />
                                        <img
                                          *ngIf="
                                            ((!viewdata.profile_image &&
                                              viewdata.gender == 'F' &&
                                              !view_profile_image) ||
                                              (viewdata.profile_image &&
                                                !view_profile_image &&
                                                viewdata.gender == 'F')) &&
                                            !fileurl
                                          "
                                          src="./assets/images/female.png"
                                          class="avatar-preview redborder"
                                          id="imagePreview"
                                        />
                                        <img
                                          *ngIf="
                                            viewdata.profile_image &&
                                            view_profile_image &&
                                            !fileurl
                                          "
                                          src="{{ view_profile_image }}"
                                          class="avatar-preview"
                                          id="imagePreview"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div
                                    class="col-md-12 col-sm-12 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10 mt-3">
                                      {{
                                        "lang_human_resources_number" | language
                                      }}
                                      : <b>{{ viewdata.employee_number }}</b>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  *ngIf="viewdata.device_id"
                                  class="row m-1"
                                  style="
                                    background-color: #fbfbfb;
                                    padding: 10px;
                                    border-radius: 5px;
                                  "
                                >
                                  <mat-icon
                                    style="margin-left: auto"
                                    (click)="openModalB(updatePerson)"
                                    >edit</mat-icon
                                  >
                                  <div class="mb-10 col-12">
                                    <div class="mb-10">
                                      {{ "lang_device_name" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.device_name }}</b>
                                    </div>
                                  </div>
                                  <div class="col-12 mb-10">
                                    <div class="mb-10">
                                      {{ "lang_device_id" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.device_id }}</b>
                                    </div>
                                  </div>
                                  <div class="col-12 mb-10">
                                    <div class="mb-10">
                                      {{ "lang_device_key" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.device_key }}</b>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  *ngIf="
                                    (edit || is_admin) &&
                                    viewdata.employee_status == '1' &&
                                    viewdata.profile_image &&
                                    view_profile_image &&
                                    !viewdata.device_id
                                  "
                                  class="row m-1"
                                  style="
                                    background-color: #fbfbfb;
                                    padding: 10px;
                                    border-radius: 5px;
                                  "
                                >
                                  <div
                                    class="col-md-12 col-sm-12 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      <b>
                                        {{ "lang_add_person" | language }}
                                      </b>
                                    </div>
                                  </div>
                                  <div class="col-12 mb-3">
                                    <ng-select
                                      [items]="devices"
                                      [multiple]="false"
                                      bindLabel="label"
                                      bindValue="value"
                                      appendTo="body"
                                      clearAllText="Clear"
                                      [(ngModel)]="device"
                                      placeholder="{{
                                        'lang_devices' | language
                                      }}"
                                    ></ng-select>
                                  </div>
                                  <div class="col-12 mb-3">
                                    <button
                                      type="button"
                                      [disabled]="device == null"
                                      class="albutton"
                                      (click)="
                                        add_person(
                                          viewdata.employee_number,
                                          view_profile_image
                                        )
                                      "
                                    >
                                      {{ "lang_submit" | language }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </mat-step>
                  <mat-step>
                    <ng-container
                      *ngIf="selectedName == 'human_resources_other_details'"
                    >
                      <mat-tab-group>
                        <mat-tab>
                          <ng-template mat-tab-label>{{
                            "lang_human_resources_contact_details" | language
                          }}</ng-template>
                          <app-contacts
                            [id]="id"
                            [filepermissionlevels]="filepermissionlevels"
                            (childToParentAttach)="
                              load_updated_attachments($event)
                            "
                            [able2add]="add"
                            [able2delete]="delete || is_admin"
                            [user_data]="viewdata"
                            [attachments]="attachments"
                            [branch_id]="branch_id"
                            [key]="key"
                            [able2edit]="edit || is_admin"
                            [requestfor]="module"
                            (resfreshview_details2)="get_view_details()"
                          ></app-contacts>
                        </mat-tab>
                        <mat-tab>
                          <ng-template mat-tab-label>{{
                            "lang_human_resources_bank_details" | language
                          }}</ng-template>
                          <div class="card">
                            <i
                              *ngIf="attachments.bk0001"
                              matTooltip="{{
                                'lang_recent_attachment' | language
                              }} : {{ attachments.bk0001_d }}"
                              class="fa fa-paperclip linkicon mnabricon"
                              (click)="
                                ds.preview_pdf_file(
                                  attachments.bk0001,
                                  'bk0001'
                                )
                              "
                            ></i>
                            <div class="card-header">
                              {{
                                "lang_human_resources_bank_details" | language
                              }}
                            </div>
                            <div class="card-body">
                              <div class="martb0">
                                <div class="row">
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{
                                        "lang_human_resources_account_number"
                                          | language
                                      }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.account_number }}</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                  >
                                    <div class="mb-10">
                                      {{ "lang_bank" | language }}
                                    </div>
                                    <div class="mb-10">
                                      <b>{{ viewdata.bankname }}</b>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </mat-tab>
                        <mat-tab>
                          <ng-template mat-tab-label>{{
                            "lang_human_resources_education_details" | language
                          }}</ng-template>
                          <app-education
                            [id]="id"
                            [filepermissionlevels]="filepermissionlevels"
                            (childToParentAttach)="
                              load_updated_attachments($event)
                            "
                            [able2add]="add"
                            [able2delete]="delete || is_admin"
                            [attachform]="attachform"
                            [user_data]="viewdata"
                            [attachments]="attachments"
                            [branch_id]="branch_id"
                            [key]="key"
                            [able2edit]="edit || is_admin"
                            [requestfor]="module"
                          ></app-education>
                        </mat-tab>
                        <mat-tab>
                          <ng-template mat-tab-label>{{
                            "lang_human_resources_passport_details" | language
                          }}</ng-template>
                          <div class="card">
                            <i class="together-icon">
                              <i
                                *ngIf="viewdata.employee_status == '1' && add"
                                matTooltip="{{
                                  'lang_upload_attachments' | language
                                }}"
                                class="fa fa-upload s2icon mnabricon"
                                (click)="
                                  openModalA(addNeedUploads);
                                  emptyAttachForm('PS0001')
                                "
                              ></i>
                              <i
                                *ngIf="attachments.ps0001"
                                (click)="
                                  spinner.show();
                                  ds.preview_pdf_file(
                                    attachments.ps0001,
                                    'ps0001'
                                  )
                                "
                                matTooltip="{{
                                  'lang_recent_attachment' | language
                                }} : {{ attachments.ps0001_d }}"
                                class="fa fa-paperclip s2icon mnabricon"
                              ></i>
                            </i>
                            <div class="card-header">
                              {{
                                "lang_human_resources_passport_details"
                                  | language
                              }}
                            </div>
                            <div class="card-body">
                              <div class="martb0">
                                <div class="row">
                                  <div
                                    class="col-md-3 col-sm-3 col-xs-12 mb-10"
                                  >
                                    <div
                                      class="mb-10"
                                      [ngClass]="{
                                        red: !viewdata.passport_number
                                      }"
                                    >
                                      {{
                                        "lang_human_resources_passport_number"
                                          | language
                                      }}
                                    </div>
                                    <div
                                      class="mb-10"
                                      [ngClass]="{
                                        red: !viewdata.passport_number
                                      }"
                                    >
                                      <b *ngIf="viewdata.passport_number">{{
                                        viewdata.passport_number
                                      }}</b>
                                      <b *ngIf="!viewdata.passport_number">-</b>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-3 col-sm-3 col-xs-12 mb-10"
                                  >
                                    <div
                                      class="mb-10"
                                      [ngClass]="{
                                        red: !viewdata.passport_issue_date_english
                                      }"
                                    >
                                      {{ "lang_issue_date" | language }}
                                    </div>
                                    <div
                                      class="mb-10"
                                      [ngClass]="{
                                        red: !viewdata.passport_issue_date_english
                                      }"
                                    >
                                      <b
                                        >{{
                                          viewdata.passport_issue_date_english
                                        }}
                                        -
                                        {{
                                          viewdata.passport_issue_date_arabic
                                        }}</b
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-3 col-sm-3 col-xs-12 mb-10"
                                  >
                                    <div
                                      class="mb-10"
                                      [ngClass]="{
                                        red: !viewdata.passport_expiry_date_english
                                      }"
                                    >
                                      {{ "lang_expiry_date" | language }}
                                    </div>
                                    <div
                                      class="mb-10"
                                      [ngClass]="{
                                        red: !viewdata.passport_expiry_date_english
                                      }"
                                    >
                                      <b
                                        >{{
                                          viewdata.passport_expiry_date_english
                                        }}
                                        -
                                        {{
                                          viewdata.passport_expiry_date_arabic
                                        }}</b
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-3 col-sm-3 col-xs-12 mb-10"
                                  >
                                    <div
                                      class="mb-10"
                                      [ngClass]="{
                                        red: !viewdata.passport_issue_place
                                      }"
                                    >
                                      {{
                                        "lang_human_resources_passport_issue_place"
                                          | language
                                      }}
                                    </div>
                                    <div
                                      class="mb-10"
                                      [ngClass]="{
                                        red: !viewdata.passport_issue_place
                                      }"
                                    >
                                      <b
                                        *ngIf="viewdata.passport_issue_place"
                                        >{{ viewdata.passport_issue_place }}</b
                                      >
                                      <b *ngIf="!viewdata.passport_issue_place"
                                        >-</b
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </mat-tab>
                        <mat-tab>
                          <ng-template mat-tab-label>{{
                            "lang_human_resources_insurance_details" | language
                          }}</ng-template>
                          <app-insurance
                            [filepermissionlevels]="filepermissionlevels"
                            (childToParentAttach)="
                              load_updated_attachments($event)
                            "
                            [id]="id"
                            [able2add]="add"
                            [able2delete]="delete || is_admin"
                            [attachform]="attachform"
                            [user_data]="viewdata"
                            [attachments]="attachments"
                            [branch_id]="branch_id"
                            [key]="key"
                            [able2edit]="edit || is_admin"
                            [requestfor]="module"
                          ></app-insurance>
                        </mat-tab>
                      </mat-tab-group>
                    </ng-container>
                  </mat-step>
                  <mat-step>
                    <ng-container
                      *ngIf="selectedName == 'human_resources_contract_details'"
                    >
                      <mat-tab-group>
                        <mat-tab>
                          <ng-template mat-tab-label>{{
                            "lang_human_resources_contract_details" | language
                          }}</ng-template>
                          <app-contracts
                            [id]="id"
                            [filepermissionlevels]="filepermissionlevels"
                            (showContract)="create_contract_form($event)"
                            (childToParentAttach)="
                              load_updated_attachments($event)
                            "
                            [able2add]="add"
                            [branch_id]="branch_id"
                            [key]="key"
                            [able2edit]="edit || is_admin"
                            [able2delete]="delete || is_admin"
                            [user_data]="viewdata"
                            [requestfor]="module"
                          ></app-contracts>
                        </mat-tab>
                        <mat-tab>
                          <ng-template mat-tab-label>{{
                            "lang_human_resources_joining_details" | language
                          }}</ng-template>
                          <app-joining
                            [id]="id"
                            [filepermissionlevels]="filepermissionlevels"
                            (showJoining)="create_joining_form($event)"
                            (childToParentAttach)="
                              load_updated_attachments($event)
                            "
                            [able2add]="add"
                            [branch_id]="branch_id"
                            [key]="key"
                            [able2edit]="edit || is_admin"
                            [able2delete]="delete || is_admin"
                            [user_data]="viewdata"
                            [requestfor]="module"
                          ></app-joining>
                        </mat-tab>
                        <mat-tab>
                          <ng-template mat-tab-label>{{
                            "lang_human_resources_job_details" | language
                          }}</ng-template>
                          <app-positions
                            [id]="id"
                            [filepermissionlevels]="filepermissionlevels"
                            (childToParentAttach)="
                              load_updated_attachments($event)
                            "
                            [able2add]="add"
                            [branch_id]="branch_id"
                            [attachments]="attachments"
                            [key]="key"
                            [able2edit]="edit || is_admin"
                            [able2delete]="delete || is_admin"
                            [user_data]="viewdata"
                            [requestfor]="module"
                          ></app-positions>
                        </mat-tab>
                        <mat-tab>
                          <ng-template mat-tab-label>{{
                            "lang_extend_period" | language
                          }}</ng-template>
                          <app-extend-period-list
                            [id]="id"
                            [filepermissionlevels]="filepermissionlevels"
                            (childToParentAttach)="
                              load_updated_attachments($event)
                            "
                            [able2add]="add"
                            [branch_id]="branch_id"
                            [attachments]="attachments"
                            [key]="key"
                            [able2edit]="edit || is_admin"
                            [able2delete]="delete || is_admin"
                            [user_data]="viewdata"
                            [requestfor]="module"
                          ></app-extend-period-list>
                        </mat-tab>
                      </mat-tab-group>
                    </ng-container>
                  </mat-step>
                  <mat-step>
                    <ng-container
                      *ngIf="selectedName == 'human_resources_document'"
                    >
                      <mat-tab-group>
                        <mat-tab>
                          <ng-template mat-tab-label>{{
                            "lang_communications" | language
                          }}</ng-template>
                          <app-communication
                            [user_data]="viewdata"
                            [able2add]="add"
                          ></app-communication>
                        </mat-tab>
                        <mat-tab>
                          <ng-template mat-tab-label>{{
                            "lang_attachments" | language
                          }}</ng-template>
                          <app-attachments
                            [filepermissionlevels]="filepermissionlevels"
                            (childToParentAttach)="get_view_details(true)"
                            [id]="id"
                            [able2add]="add"
                            [able2delete]="delete || is_admin"
                            [user_data]="viewdata"
                            [branch_id]="branch_id"
                            [attachments]="attachments"
                            [key]="key"
                            [able2edit]="edit || is_admin"
                            [requestfor]="module"
                          ></app-attachments>
                        </mat-tab>
                      </mat-tab-group>
                    </ng-container>
                  </mat-step>
                  <mat-step>
                    <ng-container *ngIf="selectedName == 'vacations'">
                      <app-vaction
                        [filepermissionlevels]="filepermissionlevels"
                        (childToParentAttach)="get_view_details(true)"
                        (showVacation)="create_vacation_form($event)"
                        [id]="id"
                        [able2add]="add"
                        [able2delete]="delete || is_admin"
                        [user_data]="viewdata"
                        [branch_id]="branch_id"
                        [attachments]="attachments"
                        [key]="key"
                        [able2edit]="edit || is_admin"
                        [requestfor]="module"
                      ></app-vaction>
                    </ng-container>
                  </mat-step>
                  <mat-step>
                    <ng-container *ngIf="selectedName == 'modules'">
                      <app-modules
                        [filepermissionlevels]="filepermissionlevels"
                        (childToParentAttach)="get_view_details(true)"
                        [id]="id"
                        [able2add]="add"
                        [able2delete]="delete || is_admin"
                        [user_data]="viewdata"
                        [branch_id]="branch_id"
                        [attachments]="attachments"
                        [key]="key"
                        [able2edit]="edit || is_admin"
                        [requestfor]="module"
                        [viewdata]="viewdata"
                      ></app-modules>
                    </ng-container>
                  </mat-step>

                  <mat-step>
                    <ng-container *ngIf="selectedName == 'finanial_details'">
                      <app-finanial-details
                        [filepermissionlevels]="filepermissionlevels"
                        (childToParentAttach)="get_view_details(true)"
                        (showForm)="create_loan_form($event)"
                        (showSettlement)="create_settlement_form($event)"
                        [id]="id"
                        [able2add]="add"
                        [able2delete]="delete || is_admin"
                        [user_data]="viewdata"
                        [branch_id]="branch_id"
                        [attachments]="attachments"
                        [key]="key"
                        [able2edit]="edit || is_admin"
                        [requestfor]="module"
                      ></app-finanial-details>
                    </ng-container>
                  </mat-step>
                  <mat-step>
                    <ng-container *ngIf="selectedName == 'projects'">
                      <app-hr-projects
                        [filepermissionlevels]="filepermissionlevels"
                        (childToParentAttach)="get_view_details(true)"
                        (showForm)="create_hr_project($event)"
                        (showSettlement)="create_settlement_form($event)"
                        [id]="id"
                        [able2add]="add"
                        [able2delete]="delete || is_admin"
                        [user_data]="viewdata"
                        [branch_id]="branch_id"
                        [attachments]="attachments"
                        [key]="key"
                        [able2edit]="edit || is_admin"
                        [requestfor]="module"
                      ></app-hr-projects>
                    </ng-container>
                  </mat-step>
                </mat-horizontal-stepper>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_calendar_view" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15 mb-3">
            <div class="row">
              <div class="col-12">
                <app-calender
                  [singleUser]="singleUser"
                  [userInfo]="viewdata"
                  [isShowTotalHours]="true"
                ></app-calender>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_notes" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15 mb-3">
            <div class="row">
              <div class="col-12">
                <app-notes
                  [filepermissionlevels]="filepermissionlevels"
                  [id]="id"
                  [able2add]="add"
                  [able2delete]="delete || is_admin"
                  [user_data]="viewdata"
                  [attachments]="attachments"
                  [branch_id]="branch_id"
                  [key]="key"
                  [able2edit]="edit || is_admin"
                  [requestfor]="module"
                ></app-notes>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="row">
      <div class="col-md-2 col-sm-4 col-xs-12 mb-10">
        <a routerLink="/human_resources/employees">
          <button type="button" class="albutton">
            {{ "lang_goto_list" | language }}
          </button>
        </a>
      </div>
      <div
        class="col-md-2 col-sm-4 col-xs-12 mb-10"
        *ngIf="(edit || is_admin) && viewdata.employee_status == '1'"
      >
        <a routerLink="/human_resources/edit/{{ id }}/edit/{{ branch_id }}">
          <button type="button" class="albutton">
            {{ "lang_edit" | language }}
          </button>
        </a>
      </div>
    </div>
  </section>
  <ng-template #addNeedUploads>
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "lang_attachments" | language }}</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="modalRefA.hide()"
        >
          &times;
        </button>
      </div>
      <form [formGroup]="attachform" (ngSubmit)="upload_files()">
        <div class="modal-body">
          <div class="form-group">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <label for="level_keys"
                  >{{ "lang_file_secutrity_levels" | language }} :
                  <span class="red">*</span></label
                >
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <ng-select
                  [items]="filepermissionlevels"
                  [multiple]="true"
                  bindLabel="label"
                  appendTo="body"
                  bindValue="value"
                  clearAllText="Clear"
                  placeholder="{{ 'lang_file_secutrity_levels' | language }}"
                  formControlName="level_keys"
                >
                </ng-select>
                <mat-error
                  *ngIf="
                    attachform.controls['level_keys'].touched &&
                    attachform.controls['level_keys'].errors?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <label for="attachment_descriptions"
                  >{{ "lang_description" | language }} :
                  <span class="red">*</span></label
                >
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <input
                  type="text"
                  formControlName="attachment_descriptions"
                  class="form-control almnabrformcontrol"
                  placeholder="{{ 'lang_description' | language }}"
                />
                <mat-error
                  *ngIf="
                    attachform.controls['attachment_descriptions'].touched &&
                    attachform.controls['attachment_descriptions'].errors
                      ?.required
                  "
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <label for="attachment_link"
                  >{{ "lang_select_files" | language }} :
                  <span class="red">*</span></label
                >
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <div class="IDDIV">
                    <input
                      name="attachment_link"
                      type="file"
                      required
                      onclick="this.value = null"
                      (change)="uploadIDFile($event)"
                      class="nghide albutton width100"
                    />
                    <label id="uploadIDButton" class="albutton changeFileName"
                      >{{ idfilename }}
                    </label>
                    <mat-error
                      *ngIf="
                        attachform.controls['attachment_link'].touched &&
                        attachform.controls['attachment_link'].errors?.required
                      "
                    >
                      {{ "lang_field_required" | language }}
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer pad15">
          <div class="row">
            <div class="col-12 mb-10">
              <app-alert id="default-alert"></app-alert>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-xs-12 mb-10">
              <button type="submit" class="albutton">
                {{ "lang_submit" | language }}
              </button>
            </div>
            <div class="col-md-6 col-xs-12 mb-10">
              <button
                type="button"
                class="btn btn-secondary secondarybutton"
                data-dismiss="modal"
                (click)="modalRefA.hide()"
              >
                {{ "lang_close" | language }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #updatePerson>
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "lang_update_person" | language }}</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="modalRefB.hide()"
        >
          &times;
        </button>
      </div>
      <div
        class="row m-1"
        style="background-color: #fbfbfb; padding: 10px; border-radius: 5px"
      >
        <div class="col-md-12 col-sm-12 col-xs-12 mb-10"></div>
        <div class="col-12 mb-3">
          <ng-select
            [items]="devices"
            [multiple]="false"
            bindLabel="label"
            bindValue="value"
            appendTo="body"
            clearAllText="Clear"
            [(ngModel)]="device"
            placeholder="{{ 'lang_devices' | language }}"
          ></ng-select>
        </div>
        <div class="col-12 mb-3">
          <button
            type="button"
            [disabled]="device == null"
            class="albutton"
            (click)="add_person(viewdata.employee_number, view_profile_image)"
          >
            {{ "lang_submit" | language }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div *ngIf="actionmethod == 'CTRCR' && viewdata && default">
  <app-formct1-add
    [form_key]="'FORM_CT1'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    (contractEvent)="showEmployeeView($event)"
  ></app-formct1-add>
</div>

<div *ngIf="actionmethod == 'CTRCR' && viewdata && !default">
  <app-formct1-add-new
    [form_key]="'FORM_CT1'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    [contract_type]="contract_status"
    [contract_data]="contract_data"
    (contractEvent)="showEmployeeView($event)"
  ></app-formct1-add-new>
</div>
<div *ngIf="actionmethod == 'ECTRCR' && viewdata && !default">
  <app-form-ect1-add
    [form_key]="'FORM_ECT1'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    [contract_type]="contract_status"
    [contract_data]="contract_data"
    (contractEvent)="showEmployeeView($event)"
  ></app-form-ect1-add>
</div>

<div *ngIf="actionmethod == 'JNGCR' && viewdata">
  <app-formhrj1-add
    [form_key]="'FORM_HRJ1'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    (joiningEvent)="showEmployeeView($event)"
  ></app-formhrj1-add>
</div>
<div *ngIf="actionmethod == 'V1CR' && viewdata">
  <app-formhrv1-add
    [form_key]="'FORM_HRV1'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    (vacationEvent)="showMainPage($event)"
  ></app-formhrv1-add>
</div>
<div *ngIf="actionmethod == 'LNCR' && viewdata">
  <app-createLOAN
    [form_key]="'FORM_HRLN1'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    (loanEvent)="showEmployeeView($event)"
  ></app-createLOAN>
</div>
<div *ngIf="actionmethod == 'HRPR' && viewdata">
  <app-form-hrpr
    [form_key]="'FORM_HRPR'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    (loanEvent)="showEmployeeView($event)"
    (pageback)="back_to_hr_project()"
  ></app-form-hrpr>
</div>

<div *ngIf="actionmethod == 'DECR' && viewdata">
  <app-createDeduction
    [form_key]="'FORM_DET1'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    (loanEvent)="showEmployeeView($event)"
  ></app-createDeduction>
</div>

<div *ngIf="actionmethod == 'BNCE' && viewdata">
  <app-createBonus
    [form_key]="'FORM_BNS1'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    (loanEvent)="showEmployeeView($event)"
  ></app-createBonus>
</div>

<div *ngIf="actionmethod == 'OTCR' && viewdata">
  <app-createOverTIme
    [form_key]="'FORM_OVR1'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    (loanEvent)="showEmployeeView($event)"
  ></app-createOverTIme>
</div>
<div *ngIf="actionmethod == 'STE' && viewdata">
  <app-form-ste-add
    [form_key]="'FORM_STE'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    (loanEvent)="showEmployeeView($event)"
  ></app-form-ste-add>
</div>

<div *ngIf="actionmethod == 'VOL1' && viewdata">
  <app-createViolation
    [form_key]="'FORM_VOL1'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    (loanEvent)="showEmployeeView($event)"
  ></app-createViolation>
</div>

<div *ngIf="actionmethod == 'EPP' && viewdata">
  <app-createEpp
    [form_key]="'FORM_EPP'"
    [form_request_id]="'0'"
    [employee_data]="viewdata"
    [contract]="contractObj"
    (loanEvent)="showEmployeeView($event)"
  ></app-createEpp>
</div>
<ng-template #NewFinancial>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_add_financial_year" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="form" class="from" (ngSubmit)="submit()">
      <div class="modal-body">
        <input type="hidden" formControlName="branch_id" />
        <div class="form-group">
          <label
            >{{ "lang_financial_start_date" | language }} :<span class="red"
              >*</span
            ></label
          >
          <div class="input-group input-group-sm">
            <input
              [matDatepicker]="rcd"
              formControlName="financial_start_date"
              class="form-control almnabrformcontrol"
              placeholder="YYYY/MM/DD"
              required
              readonly
            />
            <span class="input-group-btn">
              <mat-datepicker-toggle
                class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                matSuffix
                [for]="rcd"
              ></mat-datepicker-toggle>
              <mat-datepicker #rcd></mat-datepicker>
            </span>
            <span class="input-group-btn">
              <button
                (click)="form.get('financial_start_date').setValue('')"
                matTooltip="{{ 'lang_empty' | language }}"
                type="button"
                class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
              ></button>
            </span>
          </div>
          <mat-error
            *ngIf="
              form.controls['financial_start_date'].touched &&
              form.controls['financial_start_date'].errors?.required
            "
          >
            {{ "lang_field_required" | language }}
          </mat-error>
        </div>
        <div class="form-group">
          <label
            >{{ "lang_financial_end_date" | language }} :<span class="red"
              >*</span
            ></label
          >
          <div class="input-group input-group-sm">
            <input
              [matDatepicker]="ee"
              formControlName="financial_end_date"
              class="form-control almnabrformcontrol"
              placeholder="YYYY/MM/DD"
              required
              readonly
            />
            <span class="input-group-btn">
              <mat-datepicker-toggle
                class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                matSuffix
                [for]="ee"
              ></mat-datepicker-toggle>
              <mat-datepicker #ee></mat-datepicker>
            </span>
            <span class="input-group-btn">
              <button
                (click)="form.get('financial_end_date').setValue('')"
                matTooltip="{{ 'lang_empty' | language }}"
                type="button"
                class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
              ></button>
            </span>
          </div>
          <mat-error
            *ngIf="
              form.controls['financial_end_date'].touched &&
              form.controls['financial_end_date'].errors?.required
            "
            >{{ "lang_field_required" | language }}</mat-error
          >
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              data-dismiss="modal"
              (click)="modalRef.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
