import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddContractAttachmentsComponent } from "./add-contract-attachments/add-contract-attachments.component";

@Component({
	selector: "app-contract-attachments",
	templateUrl: "./contract-attachments.component.html",
	styleUrls: ["./contract-attachments.component.scss"],
})
export class ContractAttachmentsComponent implements OnInit {
	@Input() contract_id: any;
	contract_attachments = [];
	constructor(
		private ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private dialog: MatDialog,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.getContractAttachments();
	}

	getContractAttachments() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("contract_id", this.contract_id || "");
		this.ds
			.post("project_design/get_files_in_project_contract", formData)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.contract_attachments = res.files;
					} else {
						this.contract_attachments = [];
					}
				},
				(error) => {
					this.spinner.hide();
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	deleteAttachment(file_id) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("file_id", file_id || "");
		this.ds
			.post("project_design/delete_file_project_contract", formData)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetAlert.successToast(res?.message?.msg, 2000);
						this.getContractAttachments();
					} else {
						this.sweetAlert.errorToast(res.error, 2000);
					}
				},
				(error) => {
					this.spinner.hide();
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			);
	}
	downloadAttachment(url) {
		this.spinner.show();
		this.ds.get(url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.ds.downloadFile(res, "contract_attachment");
				} else {
					this.sweetAlert.errorMessage(res?.error);
				}
			},
			(err) => {
				this.sweetAlert.errorToast(err?.error.error, 2000);
				this.spinner.hide();
			}
		);
	}

	add_attachments() {
		const dialogRef = this.dialog.open(AddContractAttachmentsComponent, {
			width: "600px",
			data: {
				contract_id: this.contract_id,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.getContractAttachments();
			}
		});
	}
}
