import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { environment } from "@env/environment";

@Component({
	selector: "app-payments",
	templateUrl: "./payments.component.html",
	styleUrls: ["./payments.component.scss"],
})
export class PaymentsComponent implements OnInit {
	@Input() projects_work_area_id;
	@Input() shortCodeList;
	modalRef: BsModalRef;
	form: FormGroup;
	paymentList = [];
	other_bps: FormArray;
	filter_bank_short_code = null;
	isEdit = false;
	constructor(
		public ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		public modalService: BsModalService,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
			bank_short_code: [null, Validators.required],
			bank_payment_settings: this.fb.array([], Validators.required),
		});
		this.other_bps = this.form.get("bank_payment_settings") as FormArray;
	}

	get bpsFormGroup() {
		return this.form.get("bank_payment_settings") as FormArray;
	}

	public bank_payment_settings(value?): FormGroup {
		return this.fb.group({
			payment_completion_ratio: new FormControl(
				value ? value?.payment_completion_ratio : "",
				[Validators.required]
			),
			payment_due_ratio: new FormControl(
				value ? value?.payment_due_ratio : "",
				[Validators.required]
			),
		});
	}
	public addBps(value?) {
		this.other_bps.push(this.bank_payment_settings(value));
		this.other_bps.setValidators(Validators.required);
		this.other_bps.updateValueAndValidity();
	}
	public removeBps(index) {
		if (this.other_bps.length == 1) {
			this.other_bps.removeAt(index);
			this.other_bps.clearValidators();
			this.other_bps.updateValueAndValidity();
		} else {
			this.other_bps.removeAt(index);
		}
	}

	onCreateUpdateBank(template: TemplateRef<any>, type) {
		this.form.reset();
		this.other_bps.clear();
		if (type == "update") {
			this.isEdit = true;
			this.form.get("bank_short_code").setValue(this.filter_bank_short_code);
			for (let item of this.paymentList) {
				this.addBps(item);
			}
		} else {
			this.addBps();
			this.isEdit = false;
		}
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}

	getAllPayments() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("bank_short_code", this.filter_bank_short_code || "");
		this.spinner.show();
		this.ds
			.post("pr/bankpay/get_payment_setting_work_area", formData)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.paymentList = res?.records;
					} else {
						this.sweetAlert.errorToast(res?.error, 3000);
						this.paymentList = [];
					}
				},
				(err) => {
					this.paymentList = [];
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
	}

	deletePayment() {}

	onSubmit() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append(
			"bank_short_code",
			this.form.get("bank_short_code").value || ""
		);
		this.other_bps.controls.forEach((el, key) => {
			formData.append(
				`bank_payment_settings[${key}][payment_completion_ratio]`,
				el.get("payment_completion_ratio").value || ""
			);
			formData.append(
				`bank_payment_settings[${key}][payment_due_ratio]`,
				el.get("payment_due_ratio").value || ""
			);
		});
		let url = "pr/bankpay/add_bank_payment_setting";
		if (this.isEdit) {
			url = "pr/bankpay/edit_bank_payment_setting";
		}
		this.spinner.show();
		this.ds.post(url, formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast("lang_success", 3000);
					this.modalRef.hide();
					this.getAllPayments();
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
