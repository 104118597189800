import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { UnitLevelShowComponent } from '@shared/components/unit-level-show/unit-level-show.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-attendance-upload',
  templateUrl: './attendance-upload.component.html',
  styleUrls: ['./attendance-upload.component.scss']
})
export class AttendanceUploadComponent implements OnInit ,OnDestroy {
  project_data;
  supervision_data;
  history_files = [];
  history_files_ED = [];
  loading_attachments = false;
  page = 1;
  size = 10;
  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe,
    public spinner : PreloaderService, public alert:AlertService, private sweetAlert:SweetAlertService, public router:Router, private dialogRef: MatDialogRef<UnitLevelShowComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data) { 
      this.supervision_data = data?.supervision_data;
      this.project_data = data?.project_data;
    }

  ngOnInit(): void {
  }


 

  private subscriptions = new Subscription();
	ngOnDestroy(): void {
	  this.subscriptions.unsubscribe();
	}

  responseInfo = ''

  get_i_phases_history() {
    this.loading_attachments = true;
    this.history_files_ED = [];
    this.subscriptions.add(
        this.ds.get(`at/getFilesLogs/${this.page}/${this.size}`, {}).subscribe(res => {
          this.loading_attachments = false;
          if(res.status) {
            this.history_files_ED = res?.records;
            this.responseInfo = res?.page;
          } else {
            this.sweetAlert.errorMessage(res?.error);
          }
        }, (err) => {
          this.sweetAlert.errorMessage(err?.error.error);
          this.loading_attachments = false;
        })				
			)

  }
  download_ED_history(item) {
    this.spinner.show();
    this.subscriptions.add(
        this.ds.get2(item?.url).subscribe(res => {
          this.spinner.hide();
          if(res.status) {
            this.ds.downloadFile(res,  item.file_name);
          } else {
            this.sweetAlert.errorMessage(res?.error);
          }
        }, (err) => {
          this.sweetAlert.errorMessage(err?.error.error);
          this.spinner.hide();
        })				
			)

  }
  upload_i_phases(e) {
    if(e && e?.length) {
 
      let formData = new FormData();
      formData.append('attendance_attachment', e[0] || '');
      this.spinner.show();
      this.subscriptions.add(
            this.ds.post(`at/upload`, formData).subscribe(res => {
              this.spinner.hide();
              if(res.status) {
                this.sweetAlert.successMessage(res?.msg);
              } else {
                this.sweetAlert.errorMessage(res?.error);
              }
            }, (err) => {
              this.sweetAlert.errorMessage(this.lang.transform('lang_internal_server_error'));
              this.spinner.hide();
            })				
        )

    }
    document.getElementById('uploadAttendance')['value'] = '';
  }
 
 
  closeModal() {
    this.dialogRef.close(true);
  }
}
