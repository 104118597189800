<form [formGroup]="form">
	<div mat-dialog-title>
		<div class="d-flex justify-content-between">
            <ng-container *ngIf="this.data">
                <h4>{{ 'lang_update' | language }} {{ 'lang_shifts' | language }}</h4>
            </ng-container>
             <ng-container *ngIf="!this.data">
                <h4>{{ 'lang_add' | language }} {{ 'lang_shifts' | language }}</h4>
            </ng-container>
		

			<span (click)="close()"><i class="fa fa-times"></i></span>
		</div>
	</div>
	<mat-dialog-content class="mat-typography">
	 
		<form [formGroup]="form">
			<div
			
	 
		>
			<div class="row">
		     
			
				<div
					class="col-12 col-sm-6"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_title_english" | language
						}}</mat-label>

						<input
							matInput
							type="text"
							 
							formControlName="shift_title_english"
							required
						/>
						<mat-error
							*ngIf="
								form.controls[
									'shift_title_english'
								].touched &&
								form.controls[
								'shift_title_english'].errors
									?.required
							"
						>
							{{ "lang_field_required" | language }}
						</mat-error>
					</mat-form-field>
				</div>

				<div
					class="col-12 col-sm-6"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_title_arabic" | language
						}}</mat-label>

						<input
							matInput
							type="text"
							formControlName="shift_title_arabic"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'shift_title_arabic'
							].touched &&
							form.controls[
							'shift_title_arabic'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>
 

		 
                <div class="col-12 col-sm-12 mb-10">
                    <label class="mb-10">
                        {{ "lang_group" | language }}
                        <span class="red"> * </span> </label>
    
                        <ng-select
                        [items]="groups"
                        [multiple]="true"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                         
                        appendTo="body"
                        formControlName="groups"
                        placeholder="{{ 'lang_group' | language }}"
                       
                        
                         
                         
                    >
                    </ng-select>
                    <mat-error *ngIf="
                    form.controls['groups'].touched &&
                    form.controls['groups'].errors
                        ?.required
                      ">
                    {{ "lang_field_required" | language }}
                    </mat-error>
                 
                </div>
                

			


			 
 
				
			</div>
		 
		</div>
		  </form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">

		<div class="col-md-4 col-xs-12">
			<button
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				(click)="closeModal()"
			>
				{{ "lang_close" | language }}
			</button>
		</div>
		<div class="col-md-4 col-xs-12"></div>
		<div class="col-md-4 col-xs-12">
			<button
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				[disabled]="form.invalid"
				(click)="submitForm()"
			>
			<!-- [disabled]="form.invalid" -->
				

                <ng-container *ngIf="this.data">
                    
                    {{ 'lang_update'|language }}
                </ng-container>
                 <ng-container *ngIf="!this.data">
                   
                    {{ 'lang_save'|language }}
                </ng-container>
            
			</button>
		</div>
		
	</mat-dialog-actions>
</form>
