<breadcrumb></breadcrumb>
<section class="panel-expansion" [@fadeIn]>
  <mat-accordion class="stylepanel groups_data">
    <mat-expansion-panel class="mpanel" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_group" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body tlist">
        <div class="mbrl15 fromtransactions">
          <div
            class="row col-md-12 attendence mb-4"
            style="justify-content: space-between"
          >
            <div class="col-md-2 p-0 align-items-center">
              <!-- (click)="AttendanceDialog()" -->
              <!-- {{ "lang_upload" | language }}
								{{ "lang_attendance" | language }} -->
              <div (click)="openModal(updateEmployeeStatus)" class="icon-box">
                <i class="fa fa-circle faicon"></i>
                {{ "lang_update" | language }}
                {{ "lang_status" | language }}
              </div>
            </div>
            <div class="col-md-2 p-0 align-items-center">
              <div
                routerLink="/human_resources/attendances/all-shifts"
                class="icon-box"
              >
                <div>
                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                  {{ "lang_shifts" | language }}
                </div>
              </div>
            </div>
            <div class="col-md-3 p-0 align-items-center">
              <div class="icon-box" (click)="openModal(createShift)">
                <i class="fa fa-calendar-plus-o" aria-hidden="true"></i>
                {{ "lang_update_shift" | language }}
              </div>
            </div>
            <div class="col-md-3 p-0 align-items-center">
              <div
                routerLink="/human_resources/attendances/add-group"
                class="icon-box"
              >
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                {{ "lang_add_group" | language }}
              </div>
            </div>
            <div class="col-md-2 p-0 align-items-center">
              <div
                routerLink="/human_resources/attendances/devices"
                class="icon-box"
              >
                <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                {{ "lang_devices" | language }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive customResponsive">
                <table border="1" class="table CustomTable">
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>
                        <div
                          class="checkbox"
                          matTooltip="{{ 'lang_check_all' | language }}"
                        >
                          <input
                            type="checkbox"
                            class="allItemsUsr checkbox"
                            id="allItemsUsr"
                            (click)="checkAllItemsUsr($event)"
                          />
                          <label for="checkbox"></label>
                        </div>
                      </td>
                      <td>{{ "lang_human_resources_id_number" | language }}</td>
                      <td>{{ "lang_human_resources_nameen" | language }}</td>
                      <td>{{ "lang_human_resources_namear" | language }}</td>
                      <td>{{ "lang_status" | language }}</td>
                      <td>{{ "lang_writer" | language }}</td>
                      <td>{{ "lang_action" | language }}</td>
                    </tr>
                  </thead>
                  <tbody
                    *ngIf="listData?.records?.length != '0'"
                    class="AppendList"
                  >
                    <tr
                      *ngFor="
                        let list of listData?.records;
                        let i = index;
                        let odd = odd;
                        let even = even
                      "
                      [ngClass]="{ odd: odd, even: even }"
                      class="{{ list.row_color }}{{ list.row_color_flash }}
											makeback removerow{{ list.mention_id }}
											"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>
                        <div
                          class="checkbox"
                          matTooltip="{{ 'lang_check' | language }}"
                        >
                          <input
                            type="checkbox"
                            class="eachItemUsr checkbox"
                            value="{{ list.group_id }}"
                            (click)="checkEachItemUsr($event)"
                          />
                          <label for="checkbox"></label>
                        </div>
                      </td>
                      <td>{{ list.group_id }}</td>
                      <td>{{ list.group_title_english }}</td>

                      <td>{{ list.group_title_arabic }}</td>
                      <td>
                        {{
                          list.un_restricted_group == 1
                            ? ("lang_manager" | language)
                            : ("lang_company_employees" | language)
                        }}
                      </td>
                      <td>{{ list.writer }}</td>

                      <td>
                        <mat-icon
                          routerLink="/human_resources/attendances/update-group/{{
                            list.group_id
                          }}"
                          matTooltip="{{ 'lang_edit' | language }}"
                          class="mnabricon"
                          >edit</mat-icon
                        >
                        <mat-icon
                          matTooltip="{{ 'lang_delete' | language }}"
                          class="mnabricon red"
                          (click)="deleteSingleData(list.group_id)"
                          >delete</mat-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="loading">
                    <tr>
                      <td colspan="14" class="nodata" align="center">
                        <ngx-skeleton-loader
                          count="5"
                          [theme]="{ 'border-radius': '3px', height: '50px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    *ngIf="listData?.records?.length == '0' && !loading"
                    class="AppendList"
                  >
                    <tr class="odd">
                      <td colspan="14" class="nodata" align="center">
                        {{ nodata }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="listData?.records?.length != '0'" class="card-footer padb0">
          <div class="row responsive_pagination">
            <div class="deleteIconDiv none col-lg-1 col-sm-1 mb-10">
              <i
                class="fa fa-trash-o faicon red deleteIcon"
                (click)="deleteBulkData()"
                matTooltip="{{ 'lang_delete' | language }}"
              ></i>
            </div>
            <div
              class="col-lg-3 col-sm-3 mb-10"
              style="height: fit-content; margin: auto 0"
            >
              {{ "lang_total" | language }} :
              {{ listData?.page?.total_records }}
            </div>
            <div
              class="col-lg-5 col-sm-5 mb-10"
              style="display: flex; justify-content: center"
            >
              <ngb-pagination
                class="m-auto"
                [collectionSize]="listData?.page?.total_records"
                [rotate]="true"
                [ellipses]="false"
                [maxSize]="3"
                [boundaryLinks]="true"
                [(page)]="page_no"
                [pageSize]="pagesize"
                (pageChange)="loadPageFrm($event)"
              ></ngb-pagination>
            </div>
            <div
              class="col-lg-3 col-sm-3"
              style="display: flex; justify-content: end; margin: auto"
            >
              <mat-select
                style="max-width: 150px"
                [(ngModel)]="pagesize"
                (selectionChange)="loadFrmPagesize($event.value)"
              >
                <!-- <mat-option value="1">1  </mat-option> -->
                <mat-option class="text-center" value="10"
                  >10 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="20"
                  >20 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="50"
                  >50 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="100"
                  >100 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option class="text-center" value="500"
                  >500 {{ "lang_per_page" | language }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion class="all_employees">
    <mat-expansion-panel class="mt-2" [expanded]="true">
      <mat-expansion-panel-header>{{
        "lang_calender" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <div class="mbrl15 martb0">
          <div class="row">
            <div class="col-12 col-md-4">
              <input
                type="file"
                #uploadAttendance
                id="uploadAttendance"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                (change)="uploadFile($event)"
              />
              <button
                mat-button
                style="
                  background-color: rgb(136 224 136 / 42%);
                  width: 100%;
                  margin-bottom: 5px;
                "
                (click)="uploadAttendance.click()"
              >
                <i
                  class="fa fa-upload"
                  style="
                    font-size: 20px;
                    margin: 0 5px;
                    color: rgb(19, 146, 19);
                  "
                ></i>
                <span
                  style="
                    text-transform: uppercase;
                    font-weight: 600;
                    color: rgb(19, 146, 19);
                    font-size: 12px;
                  "
                  >{{ "lang_upload" | language }}</span
                >
              </button>
            </div>
            <div class="col-12 col-md-4">
              <button
                mat-button
                style="
                  background-color: rgb(113 166 223 / 42%);
                  width: 100%;
                  margin-bottom: 5px;
                "
                (click)="previewAttendance()"
              >
                <i
                  class="fa fa-download"
                  style="
                    font-size: 20px;
                    margin: 0 5px;
                    color: rgb(19, 65, 146);
                  "
                ></i>
                <span
                  style="
                    text-transform: uppercase;
                    font-weight: 600;
                    color: rgb(19, 65, 146);
                    font-size: 12px;
                  "
                  >{{ "lang_download" | language }}</span
                >
              </button>
            </div>
            <div class="col-12 col-md-4 mb-3 mt-2 d-flex justify-content-end">
              <mat-slide-toggle
                class="ml-3 mr-3"
                (change)="onShowCalendar($event); restdate()"
                [color]="'primary'"
                [(ngModel)]="calendarToggle"
                >{{ "lang_calender" | language }}
              </mat-slide-toggle>
            </div>
            <div class="col-12" *ngIf="calendarToggle">
              <app-calender
                [refresh2]="refreshSubject.asObservable()"
                (month)="addmonth($event)"
                (year)="addyear($event)"
                [isShowTotalHours]="false"
              ></app-calender>
            </div>

            <div class="col-12" *ngIf="!calendarToggle">
              <app-calendar-report
                [month]="month"
                [year]="year"
              ></app-calendar-report>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion class="stylepanel groups_data">
    <mat-expansion-panel class="mpanel" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_reasons" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body tlist">
        <div class="mbrl15 fromtransactions">
          <div class="row mb-4">
            <div class="col-md-4 mt-2 mb-2">
              <mat-form-field appearance="outline" class="reason-date">
                <mat-label>{{ "lang_date" | language }}</mat-label>
                <mat-date-range-input
                  [formGroup]="reasonFromToDate"
                  [rangePicker]="picker"
                >
                  <input
                    matStartDate
                    formControlName="start"
                    readonly
                    placeholder="{{ 'lang_start_date' | language }}"
                  />
                  <input
                    matEndDate
                    formControlName="end"
                    readonly
                    placeholder="{{ 'lang_end_date' | language }}"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker (closed)="get_report_reasons()">
                </mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-md-2 pl-3 pr-3 mb-2 d-flex align-items-center">
              <ng-select
                [multiple]="false"
                appendTo="body"
                [searchable]="false"
                clearAllText="Clear"
                placeholder="{{ 'lang_type' | language }}"
                [(ngModel)]="reasonFilterType"
                (change)="get_report_reasons()"
                class="reason-type"
              >
                <ng-option value="absent">{{
                  "lang_absent" | language
                }}</ng-option>
                <ng-option value="not_ok">
                  {{ "lang_not_ok" | language }}</ng-option
                >
                <ng-option value="record_missing">
                  {{ "lang_missing_record" | language }}</ng-option
                >
              </ng-select>
            </div>
            <div class="col-md-4 d-flex align-items-center">
              <button
                class="reset-reason-filters albutton mnabrbutton"
                mat-button
                (click)="openChartData()"
              >
                {{ "lang_create_deduction" | language }}
              </button>
            </div>
            <div class="col-md-2 d-flex align-items-center">
              <button
                class="reset-reason-filters albutton mnabrbutton"
                mat-button
                (click)="onResetReasonFilters()"
              >
                {{ "lang_reset" | language }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive customResponsive">
                <table border="1" class="table CustomTable">
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>{{ "lang_human_resources_number" | language }}</td>
                      <td>
                        {{ "lang_human_resources_employee_name" | language }}
                      </td>
                      <td>{{ "lang_reason" | language }}</td>
                      <td>{{ "lang_date" | language }}</td>
                      <td>{{ "lang_state" | language }}</td>
                      <td>{{ "lang_status" | language }}</td>
                      <td>{{ "lang_attachment" | language }}</td>
                      <td>{{ "lang_actions" | language }}</td>
                    </tr>
                  </thead>
                  <tbody
                    *ngIf="reasonsData?.records?.length != '0'"
                    class="AppendList"
                  >
                    <tr
                      *ngFor="
                        let list of reasonsData?.records;
                        let i = index;
                        let odd = odd;
                        let even = even
                      "
                      [ngClass]="{ odd: odd, even: even }"
                      class="{{ list.row_color }}{{ list.row_color_flash }}
											makeback removerow{{ list.mention_id }}
											"
                    >
                      <td>{{ i + 1 }}</td>

                      <td>{{ list.employee_number }}</td>
                      <td>{{ list.employee_name }}</td>

                      <td>{{ list.reason }}</td>

                      <td>
                        {{ list.violation_date }}
                      </td>

                      <td>{{ list.state }}</td>

                      <td>
                        <p
                          *ngIf="list.approved_status != null"
                          class="rejected-statement"
                        >
                          Rejected
                        </p>
                      </td>

                      <td>
                        <i
                          class="fa fa-paperclip download-icon"
                          matTooltip="{{ 'lang_attachment' | language }}"
                          (click)="ds.preview_pdf_file(list.attachment)"
                          aria-hidden="true"
                        ></i>
                      </td>

                      <td>
                        <i
                          *ngIf="list.approved_status == null"
                          class="fa fa-check-circle-o approved-icon"
                          matTooltip="{{ 'lang_approve' | language }}"
                          aria-hidden="true"
                          (click)="onApproveRejectReason(list, 'Approve')"
                        ></i>
                        <i
                          *ngIf="list.approved_status == null"
                          class="fa fa-ban banned-icon"
                          matTooltip="{{ 'lang_reject' | language }}"
                          aria-hidden="true"
                          (click)="onApproveRejectReason(list, 'Reject')"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="loading">
                    <tr>
                      <td colspan="14" class="nodata" align="center">
                        <ngx-skeleton-loader
                          count="5"
                          [theme]="{ 'border-radius': '3px', height: '50px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    *ngIf="!reasonsData?.records?.length"
                    class="AppendList"
                  >
                    <tr class="odd">
                      <td colspan="8" class="nodata" align="center">
                        {{ "lang_no_data" | language }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="reasonsData?.records?.length != '0'"
          class="card-footer padb0"
        >
          <div class="row responsive_pagination">
            <div
              class="col-lg-3 col-sm-3 mb-10"
              style="height: fit-content; margin: auto 0"
            >
              {{ "lang_total" | language }} :
              {{ reasonsData?.page?.total_records }}
            </div>
            <div
              class="col-lg-5 col-sm-5 mb-10"
              style="display: flex; justify-content: center"
            >
              <ngb-pagination
                class="m-auto"
                [collectionSize]="reasonsData?.page?.total_records"
                [rotate]="true"
                [ellipses]="false"
                [maxSize]="3"
                [boundaryLinks]="true"
                [(page)]="reasonPageNo"
                [pageSize]="reasonPageSize"
                (pageChange)="get_report_reasons()"
              ></ngb-pagination>
            </div>
            <div
              class="col-lg-3 col-sm-3"
              style="display: flex; justify-content: end; margin: auto"
            >
              <mat-select
                style="max-width: 150px"
                [(ngModel)]="reasonPageSize"
                (selectionChange)="get_report_reasons()"
              >
                <!-- <mat-option value="1">1  </mat-option> -->
                <mat-option class="text-center" value="10"
                  >10 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="20"
                  >20 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="50"
                  >50 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="100"
                  >100 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option class="text-center" value="500"
                  >500 {{ "lang_per_page" | language }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- <mat-accordion class="report_reasons">
    <mat-expansion-panel class="mt-2" [expanded]="true">
      <mat-expansion-panel-header>{{
        "lang_report_reasons" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <div class="mbrl15 martb0">
          <div class="row">
            <div class="col-12">
              <h3>hhh</h3>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion> -->
</section>
<ng-template #createShift>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_update_shift" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="shfitUserFG">
      <div class="container" style="margin-top: 25px; margin-bottom: 25px">
        <div class="row">
          <div class="col-6" style="margin: 10px 0">
            <div class="12hr-example">
              <label for="shift_start_time">{{
                "lang_shift_start_time" | language
              }}</label>
              <input
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_shift_start_time' | language }}"
                aria-label="12hr format"
                [ngxTimepicker]="default"
                readonly
                formControlName="shift_start_time"
                [format]="24"
              />
              <ngx-material-timepicker #default></ngx-material-timepicker>
            </div>
          </div>
          <div class="col-6" style="margin: 10px 0">
            <div class="12hr-example">
              <label for="shift_end_time">{{
                "lang_shift_end_time" | language
              }}</label>
              <input
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_shift_end_time' | language }}"
                aria-label="12hr format"
                [ngxTimepicker]="default2"
                readonly
                formControlName="shift_end_time"
                [format]="24"
              />
              <ngx-material-timepicker #default2></ngx-material-timepicker>
            </div>
          </div>
          <div class="col-6" style="margin: 10px 0">
            <div class="12hr-example">
              <label for="check_in_start">{{
                "lang_check_in_start" | language
              }}</label>
              <input
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_check_in_start' | language }}"
                aria-label="12hr format"
                [ngxTimepicker]="default3"
                readonly
                formControlName="check_in_start"
                [format]="24"
              />
              <ngx-material-timepicker #default3></ngx-material-timepicker>
            </div>
          </div>
          <div class="col-6" style="margin: 10px 0">
            <div class="12hr-example">
              <label for="check_in_end">{{
                "lang_check_in_end" | language
              }}</label>
              <input
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_check_in_end' | language }}"
                aria-label="12hr format"
                [ngxTimepicker]="default4"
                readonly
                formControlName="check_in_end"
                [format]="24"
              />
              <ngx-material-timepicker #default4></ngx-material-timepicker>
            </div>
          </div>
          <div class="col-6" style="margin: 10px 0">
            <div class="12hr-example">
              <label for="check_out_start">{{
                "lang_check_out_start" | language
              }}</label>
              <input
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_check_out_start' | language }}"
                aria-label="12hr format"
                [ngxTimepicker]="default5"
                readonly
                formControlName="check_out_start"
                [format]="24"
              />
              <ngx-material-timepicker #default5></ngx-material-timepicker>
            </div>
          </div>
          <div class="col-6" style="margin: 10px 0">
            <div class="12hr-example">
              <label for="check_out_end">{{
                "lang_check_out_end" | language
              }}</label>
              <input
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_check_out_end' | language }}"
                aria-label="12hr format"
                [ngxTimepicker]="default6"
                readonly
                formControlName="check_out_end"
                [format]="24"
              />
              <ngx-material-timepicker #default6></ngx-material-timepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-12 col-sm-6">
          <label>{{ "lang_users" | language }}</label>
          <ng-select
            [items]="users"
            [multiple]="true"
            bindLabel="label"
            bindValue="value"
            clearAllText="Clear"
            appendTo="body"
            formControlName="user"
            placeholder="{{ 'lang_users' | language }}"
            (search)="search_users($event)"
            [searchFn]="ds.customSearchFn"
            class="update-shit-select"
          >
          </ng-select>
        </div>
        <div class="col-12 col-sm-6">
          <label>{{ "lang_date" | language }}</label>
          <mat-form-field appearance="outline" style="width: 100%">
            <ngx-multiple-dates
              [matDatepicker]="picker"
              placeholder="{{ 'lang_date' | language }}"
              formControlName="date"
            >
            </ngx-multiple-dates>
            <mat-label>{{ "lang_date" | language }}</mat-label>
            <mat-datepicker-toggle
              matPrefix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-0 col-sm-6"></div>
        <div class="col-12 col-sm-6 mt-3">
          <button
            type="button"
            class="albutton mnabrbutton mat-accent"
            mat-button
            (click)="submitForm()"
          >
            {{ "lang_submit" | language }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #updateEmployeeStatus>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ "lang_update" | language }} {{ "lang_status" | language }}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="empStatusFG">
      <div class="row">
        <div class="col-12 mb-2">
          <label>{{ "lang_status" | language }}</label>
          <ng-select
            [items]="statusList"
            [multiple]="false"
            bindLabel="label"
            bindValue="value"
            appendTo="body"
            formControlName="state"
            placeholder="{{ 'lang_status' | language }}"
          >
          </ng-select>
        </div>
        <div class="col-12 mb-2">
          <label>{{ "lang_users" | language }}</label>
          <ng-select
            [items]="users"
            [multiple]="false"
            bindLabel="label"
            bindValue="value"
            clearAllText="Clear"
            appendTo="body"
            formControlName="user_id"
            placeholder="{{ 'lang_users' | language }}"
            (search)="search_users($event)"
            [searchFn]="ds.customSearchFn"
          >
          </ng-select>
        </div>
        <div class="col-12 mb-2" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
          <label>{{ "lang_date" | language }}</label>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ "lang_date" | language }}</mat-label>
            <input
              matInput
              [matDatepicker]="uud"
              required
              readonly
              formControlName="date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="uud"
            ></mat-datepicker-toggle>
            <mat-datepicker #uud></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-0 col-sm-6"></div>
        <div class="col-12 col-sm-6 mt-3">
          <button
            type="button"
            class="albutton mnabrbutton mat-accent"
            mat-button
            (click)="onUpdateEmployeeStatus()"
          >
            {{ "lang_submit" | language }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
