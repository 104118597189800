import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-design-main-dashboard',
  templateUrl: './design-main-dashboard.component.html',
  styleUrls: ['./design-main-dashboard.component.scss']
})
export class DesignMainDashboardComponent implements OnInit {
  public project_profile_id  = this.route.snapshot.paramMap.get("pid");
  public project_design_id   = this.route.snapshot.paramMap.get("did");
  constructor(public route: ActivatedRoute,) { }

  ngOnInit(): void {
  }

}
