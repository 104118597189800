<!-- <select (change)="handleOnProvinceChange($event)" (focus)="onTouched()" [disabled]="isDisabled">
    <option disabled hidden>-- Select a province --</option>
    <option *ngFor="let province of provincesList" [value]="province.id" [selected]="isSelect(province.id)">{{province.name}}</option>
  </select> -->


 <!--  <mat-form-field  >
    <input matInput [matDatepicker]="picker" 
    [formControl]="parentFormControlName" 
    [value]="dateValue" [placeholder]="label"        
    (dateChange)="addEventChange('change', $event)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker >
    </mat-datepicker>
</mat-form-field>
   -->


<div class="input-group input-group-sm" [formGroup]="parentForm">
    <input
        [matDatepicker]="bvwd"
    
        
        class="form-control almnabrformcontrol"
        placeholder="YYYY/MM/DD"
        [ngClass]="{'hidden':isArabic }"
        [placeholder]="label"    
        type="text"    
   
        [ngModelOptions]="{standalone: true}"
        [(ngModel)]="_dateValue" (ngModelChange)="addEventChange('change',$event)"
         
        required
        readonly
    />

    <input
       
        
        
        class="form-control almnabrformcontrol"
        placeholder="YYYY/MM/DD"
        [(ngModel)]="arDate" (ngModelChange)="addEventChange('change',$event)"
        [placeholder]="label"        
        [ngModelOptions]="{standalone: true}"
        ngbDatepicker
        type="text"  
        (click)="readOnly ? '':idarab.toggle()"
        #idarab="ngbDatepicker"
        #datePicked
        required
        readonly
        [ngClass]="{'hidden':!isArabic }"
    />
    <button (click)="toggleDates()" type="button"   class="input-group-btn btn lang" 
    [ngClass]="{'active': isArabic}" >
        <span>
            AR
        </span>
       
     </button>
     <span (click)="readOnly ? '':clickOnIt()"  *ngIf="isArabic" class="input-group-btn">
       <mat-datepicker-toggle
        
       [disabled]="readOnly"
            class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
        
            
        ></mat-datepicker-toggle>  
     
    </span>
    <span class="input-group-btn"  [ngClass]="{'hidden':isArabic , 'disabled':readOnly}">
        <mat-datepicker-toggle
     
        [disabled]="readOnly"
            class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
            matSuffix
            [for]="bvwd"
        ></mat-datepicker-toggle>
        <mat-datepicker #bvwd></mat-datepicker>
    </span>

 
</div>