<mat-tab-group
	mat-align-tabs="center"
	(selectedTabChange)="selectedTabValue($event)"
>
	<mat-tab
		label="setting"
		*ngIf="ds.allowMe('projects', 'projects_template_permission')"
	>
		<ng-template mat-tab-label>{{ "lang_setting" | language }}</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12 col-md-6 mt-1">
					<mat-label style="margin: 15px 5px; font-size: 17px">{{
						"lang_setting_type" | language
					}}</mat-label>
					<br />
					<mat-radio-group
						aria-labelledby="example-radio-group-label"
						[(ngModel)]="setting_type"
						(change)="loading_files_btns_func()"
						style="display: flex; flex-direction: column"
					>
						<mat-radio-button value="soil">
							{{ "lang_soil" | language }}
						</mat-radio-button>
						<mat-radio-button value="concrete">
							{{ "lang_concrete" | language }}
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<div
					class="col-12 col-md-6 mb-2 mt-2 text-center"
					*ngIf="loading_files_btns"
				>
					<ngx-skeleton-loader
						count="3"
						[theme]="{ 'border-radius': '3px', height: '40px' }"
					></ngx-skeleton-loader>
				</div>
				<div
					class="col-12 col-md-6 mb-2 text-center"
					*ngIf="!loading_files_btns"
				>
					<button
						mat-button
						style="
							background-color: rgb(113 166 223 / 42%);
							width: 100%;
							margin-top: 10px;
						"
						(click)="downloadExcel(false)"
					>
						<i
							class="fa fa-download"
							style="font-size: 20px; margin: 0 5px; color: rgb(19, 65, 146)"
						></i>
						<span
							style="
								text-transform: uppercase;
								font-weight: 600;
								color: rgb(19, 65, 146);
								font-size: 12px;
							"
							>{{ "lang_download_excel" | language }}</span
						>
					</button>

					<input
						type="file"
						#uploadExcelFile
						id="uploadExcelInput"
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						hidden
						(change)="uploadExcel($event.target.files)"
					/>
					<button
						mat-button
						style="
							background-color: rgb(136 224 136 / 42%);
							width: 100%;
							margin-top: 10px;
						"
						(click)="uploadExcelFile.click()"
						*ngIf="makeAction"
					>
						<i
							class="fa fa-upload"
							style="font-size: 20px; margin: 0 5px; color: rgb(19, 146, 19)"
						></i>
						<span
							style="
								text-transform: uppercase;
								font-weight: 600;
								color: rgb(19, 146, 19);
								font-size: 12px;
							"
							>{{ "lang_upload_excel" | language }}</span
						>
					</button>
					<button
						mat-button
						style="
							background-color: rgb(224 136 200 / 42%);
							width: 100%;
							margin-top: 10px;
						"
						(click)="soil_concrete_history()"
					>
						<i
							class="fa fa-download"
							style="font-size: 20px; margin: 0 5px; color: rgb(146, 19, 102)"
						></i>
						<span
							style="
								text-transform: uppercase;
								font-weight: 600;
								color: rgb(146, 19, 102);
								font-size: 12px;
							"
							>{{ "lang_history" | language }}</span
						>
					</button>
				</div>
				<div class="col-12 mt-2">
					<div
						class="table-responsive dropdownyes customResponsive tablefields"
						*ngIf="history_files?.length"
					>
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<th>#</th>
									<th>{{ "lang_writer" | language }}</th>
									<th>{{ "lang_date" | language }}</th>
									<th>{{ "lang_action" | language }}</th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let item of history_files
											| slice
												: (history_files_page - 1) * history_files_size
												: history_files_page * history_files_size;
										let i = index;
										let odd = odd;
										let even = even
									"
								>
									<td>{{ i + 1 }}</td>
									<td>{{ item?.writer }}</td>
									<td>{{ item?.created_datetime }}</td>
									<td>
										<i
											class="fa fa-download mnabricon"
											(click)="downloadExcel(item)"
										></i>
									</td>
								</tr>
							</tbody>
						</table>
						<div
							class="mt-2"
							style="
								height: 40px;
								display: flex;
								justify-content: center;
								align-items: center;
							"
						>
							<ngb-pagination
								*ngIf="history_files.length"
								[collectionSize]="history_files.length"
								[(page)]="history_files_page"
								[maxSize]="3"
								[pageSize]="history_files_size"
								[boundaryLinks]="true"
							></ngb-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab label="test_result_list">
		<ng-template mat-tab-label>
			{{ "lang_test_result" | language }}
		</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12">
					<mat-tab-group
						class="test-tabs"
						(selectedTabChange)="selectedListTabValue($event)"
					>
						<mat-tab label="soil" class="mat-tab">
							<ng-template mat-tab-label>{{
								"lang_soil" | language
							}}</ng-template>
							<div class="p-2">
								<div class="row">
									<div class="col-12 col-sm-4 mb-2">
										<button
											class="albutton mnabrbutton"
											mat-raised-button
											(click)="showSoilFilter = !showSoilFilter"
										>
											<i class="fa fa-filter"></i>
											{{ "lang_advanced_filter" | language }}
										</button>
									</div>
									<div class="col-12 col-sm-8 mb-10">
										<div class="input-group input-group-sm">
											<input
												type="text"
												class="form-control almnabrformcontrol"
												style="height: 38px !important"
												[(ngModel)]="soil_search_key"
												placeholder="{{ 'lang_search' | language }}"
											/>
											<span class="input-group-btn">
												<button
													id="btnSearch"
													type="button"
													style="height: 38px !important"
													class="btnSearch h34 btn btn-md btn-gray fa fa-search mnabricon"
													(click)="getTestReportListSo()"
												></button>
											</span>
										</div>
									</div>
								</div>
								<div class="row">
									<div
										[ngClass]="{
											'col-12 col-md-8': showSoilFilter,
											'col-12': !showSoilFilter
										}"
										[attr.style]="isMob ? 'order:2' : 'order:1'"
									>
										<div class="row">
											<div class="col-md-12 mt-2">
												<div class="row">
													<ng-container
														*ngFor="
															let item of test_report_list_so;
															let i = index
														"
													>
														<div
															[ngClass]="{
																'col-12 col-sm-6 col-md-4': !showSoilFilter,
																'col-12 col-sm-6 col-md-6': showSoilFilter
															}"
														>
															<mat-card
																class="card"
																[style.border-left-color]="
																	item?.report_status == 'S'
																		? '#28a745 !important'
																		: item?.report_status == 'F'
																		? '#dc3545 !important'
																		: item?.report_status == 'NC'
																		? 'rgb(131 17 8) !important'
																		: '#0e3768 !important'
																"
															>
																<mat-card-title class="card-title">
																	<div class="d-flex">
																		<span *ngIf="item?.report_status">
																			<span
																				class="result_code"
																				[style.background-color]="
																					item?.report_status == 'S'
																						? '#28a745 !important'
																						: item?.report_status == 'F'
																						? '#dc3545 !important'
																						: item?.report_status == 'NC'
																						? 'rgb(131 17 8) !important'
																						: '#0e3768 !important'
																				"
																				>{{ item?.report_status }}</span
																			></span
																		>
																	</div>
																	<mat-menu
																		#menu="matMenu"
																		[overlapTrigger]="false"
																		xPosition="{{
																			lang_key == 'en' ? 'before' : 'after'
																		}}"
																	>
																		<button
																			mat-menu-item
																			class="d-flex align-items-center justify-content-between"
																			(click)="openForm(item)"
																			*ngIf="makeAction"
																		>
																			<span>{{
																				"lang_close_request_form" | language
																			}}</span>
																			<i class="fa fa-wpforms mnabricon"></i>
																		</button>
																		<button
																			mat-menu-item
																			class="d-flex align-items-center justify-content-between"
																			(click)="viewHistory(item, 'soil')"
																			*ngIf="item?.report_status != 'P'"
																		>
																			<span>{{
																				"lang_history" | language
																			}}</span>
																			<i class="pi pi-history mnabricon"></i>
																		</button>
																		<button
																			mat-menu-item
																			class="d-flex align-items-center justify-content-between"
																			(click)="viewTestData(item)"
																			*ngIf="item?.report_status != 'P'"
																		>
																			<span>{{
																				"lang_view_details" | language
																			}}</span>
																			<i class="fa fa-wpforms mnabricon"></i>
																		</button>
																	</mat-menu>
																	<button
																		mat-icon-button
																		[matMenuTriggerFor]="menu"
																		*ngIf="
																			makeAction || item?.report_status != 'P'
																		"
																	>
																		<i class="fa fa-ellipsis-v"></i>
																	</button>
																</mat-card-title>
																<mat-card-content>
																	<div class="row pt-3">
																		<div class="col-12">
																			<div class="card-body-table">
																				<table style="width: 100%">
																					<tr *ngIf="item?.group_type_name">
																						<td
																							colspan="2"
																							style="
																								text-align: center;
																								text-transform: uppercase;
																								color: #0e3768 !important;
																								font-weight: 600;
																							"
																						>
																							{{ item?.group_type_name }}
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{
																								"lang_platform_title" | language
																							}}
																						</th>
																						<td>
																							<span
																								*ngIf="
																									item?.platform_name?.length >=
																									30
																								"
																								matTooltip="{{
																									item?.platform_name
																								}}"
																								>{{
																									item?.platform_name
																										| shortenLength: 30
																								}}</span
																							>
																							<span
																								*ngIf="
																									item?.platform_name?.length <=
																									30
																								"
																								>{{ item?.platformname }}</span
																							>
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{ "lang_group_one" | language }}
																						</th>
																						<td>{{ item?.group1name }}</td>
																					</tr>
																					<tr>
																						<th>
																							{{ "lang_work_level" | language }}
																						</th>
																						<td>
																							{{ item?.work_level_label }}
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{
																								"lang_platform_code_system"
																									| language
																							}}
																						</th>
																						<td style="direction: ltr">
																							<span style="direction: ltr">{{
																								item?.projects_platform_code_system
																							}}</span>
																						</td>
																					</tr>

																					<tr>
																						<th>
																							{{ "lang_by_phases" | language }}
																						</th>
																						<td>
																							{{ item?.phase_short_code }}
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{
																								"lang_request_number" | language
																							}}
																						</th>
																						<td>
																							<span
																								*ngIf="
																									item?.report_status != 'P'
																								"
																								>{{
																									item?.transaction_request_id
																								}}</span
																							>
																							<span
																								*ngIf="
																									item?.report_status == 'P'
																								"
																								style="
																									color: #0e3768;
																									font-weight: 600;
																								"
																								matTooltip="{{
																									'lang_preview' | language
																								}}"
																								(click)="
																									ds?.preview_pdf_file(
																										item?.file_path,
																										item?.transaction_request_id
																									)
																								"
																								>{{
																									item?.transaction_request_id
																								}}</span
																							>
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{
																								"lang_natural_ground_level"
																									| language
																							}}
																						</th>
																						<td>
																							{{ item?.natural_ground_level }}
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{
																								"lang_foundation_level"
																									| language
																							}}
																						</th>
																						<td>
																							{{ item?.foundation_level }}
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{
																								"lang_expected_level" | language
																							}}
																						</th>
																						<td>{{ item?.expected_level }}</td>
																					</tr>
																					<tr>
																						<th>
																							{{
																								"lang_accepted_persent_compaction"
																									| language
																							}}
																						</th>
																						<td>
																							{{
																								item?.accepted_persent_compaction
																							}}
																						</td>
																					</tr>
																					<tr *ngIf="item?.report_result">
																						<th>
																							{{
																								"lang_report_result" | language
																							}}
																						</th>
																						<td>{{ item?.report_result }}</td>
																					</tr>
																				</table>
																			</div>
																		</div>
																	</div>
																</mat-card-content>
															</mat-card>
														</div>
													</ng-container>
													<div
														class="col-12"
														style="
															min-height: 200px;
															display: flex;
															align-items: center;
															justify-content: center;
															font-size: 20px;
														"
														*ngIf="test_report_list_so.length == 0"
													>
														<p>{{ "lang_no_data" | language }}</p>
													</div>
													<div
														class="col-12"
														style="
															display: flex;
															align-items: center;
															justify-content: space-between;
															flex-wrap: wrap;
														"
														*ngIf="test_report_list_so.length"
													>
														<p>
															{{ "lang_total" | language }}:
															{{ test_report_collectionsize_so }}
														</p>
														<ngb-pagination
															[collectionSize]="test_report_collectionsize_so"
															[(page)]="test_report_pageno_so"
															[pageSize]="test_report_pagesize_so"
															[maxSize]="3"
															[boundaryLinks]="true"
															(pageChange)="onSoilPageNoChange($event)"
														></ngb-pagination>
														<mat-select
															style="max-width: 150px"
															[(ngModel)]="test_report_pagesize_so"
															(selectionChange)="
																onSoilPageSizeChange($event.value)
															"
														>
															<mat-option class="text-center" value="10"
																>10 {{ "lang_per_page" | language }}
															</mat-option>
															<mat-option class="text-center" value="20"
																>20 {{ "lang_per_page" | language }}
															</mat-option>
															<mat-option class="text-center" value="50"
																>50 {{ "lang_per_page" | language }}
															</mat-option>
															<mat-option class="text-center" value="100"
																>100
																{{ "lang_per_page" | language }}</mat-option
															>
															<mat-option class="text-center" value="500"
																>500
																{{ "lang_per_page" | language }}</mat-option
															>
														</mat-select>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										class="col-12 col-md-4 mt-2"
										*ngIf="showSoilFilter"
										[attr.style]="isMob ? 'order:1' : 'order:2'"
									>
										<mat-card class="card">
											<mat-card-title class="card-title">
												<h4>{{ "lang_advanced_filter" | language }}</h4>
											</mat-card-title>
											<mat-card-content>
												<form [formGroup]="filterSoilForm">
													<div class="row">
														<div class="col-12">
															<div class="form-group">
																<label>{{
																	"lang_report_status" | language
																}}</label>
																<ng-select
																	[items]="report_status_list"
																	[multiple]="true"
																	bindLabel="label"
																	bindValue="value"
																	[searchFn]="ds.customSearchFn"
																	appendTo="body"
																	formControlName="report_status_so"
																	placeholder="{{
																		'lang_report_status' | language
																	}}"
																>
																</ng-select>
															</div>
														</div>
														<div class="col-12 mt-1">
															<label for="group_one">{{
																"lang_group_one" | language
															}}</label>
															<ng-select
																[items]="group1ListS"
																[multiple]="false"
																bindLabel="label"
																bindValue="value"
																appendTo="body"
																clearAllText="Clear"
																placeholder="{{ 'lang_group_one' | language }}"
																formControlName="group1_id"
																id="group1_id"
															>
															</ng-select>
														</div>
														<div class="col-12 mt-1">
															<label for="platform_code_system">{{
																"lang_platform_code_system" | language
															}}</label>
															<input
																type="text"
																class="form-control"
																id="platform_code_system"
																name="platform_code_system"
																formControlName="platform_code_system"
															/>
														</div>
														<div class="col-12 mt-1">
															<label for="level_key">{{
																"lang_level_key" | language
															}}</label>
															<ng-select
																[items]="levels_arr"
																[multiple]="true"
																bindLabel="label"
																bindValue="value"
																appendTo="body"
																clearAllText="Clear"
																formControlName="level_key"
																placeholder="{{ 'lang_level_key' | language }}"
															></ng-select>
														</div>
														<div class="col-12 mt-1">
															<label for="sort_by">{{
																"lang_sort_by" | language
															}}</label>
															<ng-select
																[items]="sort_by_arr"
																[multiple]="false"
																bindLabel="label"
																bindValue="value"
																appendTo="body"
																clearAllText="Clear"
																formControlName="sort_by"
																placeholder="{{ 'lang_sort_by' | language }}"
															></ng-select>
														</div>
														<div class="col-12 mt-1">
															<label for="order_by">{{
																"lang_order_by" | language
															}}</label>
															<ng-select
																[items]="order_by_arr"
																[multiple]="false"
																bindLabel="label"
																bindValue="value"
																appendTo="body"
																clearAllText="Clear"
																formControlName="order_by"
																placeholder="{{ 'lang_order_by' | language }}"
															></ng-select>
														</div>
														<div class="col-12 mt-1">
															<label style="display: block">{{
																"lang_previous_transaction_reports" | language
															}}</label>
															<mat-slide-toggle
																[color]="'primary'"
																(change)="onSoilPreviousChange($event)"
															>
															</mat-slide-toggle>
														</div>
														<div
															class="col-12 mt-5 d-flex justify-content-between"
														>
															<button
																type="button"
																class="albutton"
																style="width: 40% !important"
																(click)="resetFilterSoilForm()"
															>
																{{ "lang_reset" | language }}
															</button>

															<button
																type="submit"
																class="btn albutton filter-btn"
																style="width: 40% !important"
																(click)="onfilterSoilForm()"
															>
																{{ "lang_submit" | language }}
															</button>
														</div>
													</div>
												</form>
											</mat-card-content>
										</mat-card>
									</div>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="concrete" class="mat-tab">
							<ng-template mat-tab-label>{{
								"lang_concrete" | language
							}}</ng-template>
							<div class="p-2">
								<div class="row">
									<div class="col-12 col-sm-4 mb-2">
										<button
											class="albutton mnabrbutton"
											mat-raised-button
											(click)="showConcreteFilter = !showConcreteFilter"
										>
											<i class="fa fa-filter"></i>
											{{ "lang_advanced_filter" | language }}
										</button>
									</div>
									<div class="col-12 col-sm-8 mb-10">
										<div class="input-group input-group-sm">
											<input
												type="text"
												class="form-control almnabrformcontrol"
												style="height: 38px !important"
												[(ngModel)]="concrete_search_key"
												placeholder="{{ 'lang_search' | language }}"
											/>
											<span class="input-group-btn">
												<button
													id="btnSearch"
													type="button"
													style="height: 38px !important"
													class="btnSearch h34 btn btn-md btn-gray fa fa-search mnabricon"
													(click)="getTestReportListCo()"
												></button>
											</span>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 mt-2">
										<div class="row">
											<div
												[ngClass]="{
													'col-12 col-md-8': showConcreteFilter,
													'col-12': !showConcreteFilter
												}"
												[attr.style]="isMob ? 'order:2' : 'order:1'"
											>
												<div class="row">
													<ng-container
														*ngFor="
															let item of test_report_list_co;
															let i = index
														"
													>
														<div
															[ngClass]="{
																'col-12 col-sm-6 col-md-4': !showConcreteFilter,
																'col-12 col-sm-6 col-md-6': showConcreteFilter
															}"
														>
															<mat-card
																class="card"
																[style.border-left-color]="
																	item?.report_status == 'S'
																		? '#28a745 !important'
																		: item?.report_status == 'F'
																		? '#dc3545 !important'
																		: item?.report_status == 'NC'
																		? 'rgb(131 17 8) !important'
																		: '#0e3768 !important'
																"
															>
																<mat-card-title class="card-title">
																	<div class="d-flex">
																		<span *ngIf="item?.report_status">
																			<span
																				class="result_code"
																				[style.background-color]="
																					item?.report_status == 'S'
																						? '#28a745 !important'
																						: item?.report_status == 'F'
																						? '#dc3545 !important'
																						: item?.report_status == 'NC'
																						? 'rgb(131 17 8) !important'
																						: '#0e3768 !important'
																				"
																				>{{ item?.report_status }}</span
																			></span
																		>
																	</div>
																	<mat-menu
																		#menu="matMenu"
																		[overlapTrigger]="false"
																		xPosition="{{
																			lang_key == 'en' ? 'before' : 'after'
																		}}"
																	>
																		<button
																			mat-menu-item
																			class="d-flex align-items-center justify-content-between"
																			(click)="openForm(item)"
																			*ngIf="makeAction"
																		>
																			<span>{{
																				"lang_close_request_form" | language
																			}}</span>
																			<i class="fa fa-wpforms mnabricon"></i>
																		</button>
																		<button
																			mat-menu-item
																			class="d-flex align-items-center justify-content-between"
																			(click)="viewHistory(item, 'concrete')"
																			*ngIf="item?.report_status != 'P'"
																		>
																			<span>{{
																				"lang_history" | language
																			}}</span>
																			<i class="pi pi-history mnabricon"></i>
																		</button>
																		<button
																			mat-menu-item
																			class="d-flex align-items-center justify-content-between"
																			(click)="viewTestData(item)"
																			*ngIf="item?.report_status != 'P'"
																		>
																			<span>{{
																				"lang_view_details" | language
																			}}</span>
																			<i class="fa fa-wpforms mnabricon"></i>
																		</button>
																	</mat-menu>
																	<button
																		mat-icon-button
																		[matMenuTriggerFor]="menu"
																		*ngIf="
																			makeAction || item?.report_status != 'P'
																		"
																	>
																		<i class="fa fa-ellipsis-v"></i>
																	</button>
																</mat-card-title>
																<mat-card-content>
																	<div class="row pt-3">
																		<div class="col-12">
																			<div class="card-body-table">
																				<table style="width: 100%">
																					<tr *ngIf="item?.group_type_name">
																						<td
																							colspan="2"
																							style="
																								text-align: center;
																								text-transform: uppercase;
																								color: #0e3768 !important;
																								font-weight: 600;
																							"
																						>
																							{{ item?.group_type_name }}
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{
																								"lang_platform_title" | language
																							}}
																						</th>
																						<td>
																							<span
																								*ngIf="
																									item?.platform_name?.length >=
																									30
																								"
																								matTooltip="{{
																									item?.platform_name
																								}}"
																								>{{
																									item?.platform_name
																										| shortenLength: 30
																								}}</span
																							>
																							<span
																								*ngIf="
																									item?.platform_name?.length <=
																									30
																								"
																								>{{ item?.platformname }}</span
																							>
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{ "lang_group_one" | language }}
																						</th>
																						<td>{{ item?.group1name }}</td>
																					</tr>
																					<tr>
																						<th>
																							{{ "lang_work_level" | language }}
																						</th>
																						<td>
																							{{ item?.work_level_label }}
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{
																								"lang_platform_code_system"
																									| language
																							}}
																						</th>
																						<td style="direction: ltr">
																							<span style="direction: ltr">{{
																								item?.projects_platform_code_system
																							}}</span>
																						</td>
																					</tr>

																					<tr>
																						<th>
																							{{ "lang_by_phases" | language }}
																						</th>
																						<td>
																							{{ item?.phase_short_code }}
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{
																								"lang_request_number" | language
																							}}
																						</th>
																						<td>
																							<span
																								*ngIf="
																									item?.report_status != 'P'
																								"
																								>{{
																									item?.transaction_request_id
																								}}</span
																							>
																							<span
																								*ngIf="
																									item?.report_status == 'P'
																								"
																								style="
																									color: #0e3768;
																									font-weight: 600;
																								"
																								matTooltip="{{
																									'lang_preview' | language
																								}}"
																								(click)="
																									ds?.preview_pdf_file(
																										item?.file_path,
																										item?.transaction_request_id
																									)
																								"
																								>{{
																									item?.transaction_request_id
																								}}</span
																							>
																						</td>
																					</tr>
																					<tr>
																						<th>
																							{{
																								"lang_concrete_strength_type"
																									| language
																							}}
																						</th>
																						<td>
																							{{
																								item?.concrete_strength_type ==
																								"S"
																									? "7 days"
																									: "28 days"
																							}}
																						</td>
																					</tr>
																					<tr *ngIf="item?.report_result">
																						<th>
																							{{
																								"lang_report_result" | language
																							}}
																						</th>
																						<td>
																							{{
																								customizeFraction(
																									item?.report_result
																								)
																							}}
																						</td>
																					</tr>
																					<tr
																						*ngIf="
																							item?.concrete_strength_type ==
																								'T' ||
																							item?.concrete_strength_type ==
																								'S'
																						"
																					>
																						<th>
																							{{
																								"lang_concrete_required_strength"
																									| language
																							}}
																						</th>
																						<td>
																							{{
																								item?.concrete_strength_value
																							}}
																							{{ "lang_MPa" | language }}
																						</td>
																					</tr>
																				</table>
																			</div>
																		</div>
																	</div>
																</mat-card-content>
															</mat-card>
														</div>
													</ng-container>
													<div
														class="col-12"
														style="
															min-height: 200px;
															display: flex;
															align-items: center;
															justify-content: center;
															font-size: 20px;
														"
														*ngIf="test_report_list_co.length == 0"
													>
														<p>{{ "lang_no_data" | language }}</p>
													</div>
													<div
														class="col-12"
														style="
															display: flex;
															align-items: center;
															justify-content: space-between;
															flex-wrap: wrap;
														"
														*ngIf="test_report_list_co.length > 0"
													>
														<p>
															{{ "lang_total" | language }}:
															{{ test_report_collectionsize_co }}
														</p>
														<ngb-pagination
															[collectionSize]="test_report_collectionsize_co"
															[(page)]="test_report_pageno_co"
															[pageSize]="test_report_pagesize_co"
															[maxSize]="3"
															[boundaryLinks]="true"
															(pageChange)="onConcretePageNoChange($event)"
														></ngb-pagination>
														<mat-select
															style="max-width: 150px"
															[(ngModel)]="test_report_pagesize_co"
															(selectionChange)="
																onConcretePageSizeChange($event.value)
															"
														>
															<mat-option class="text-center" value="10"
																>10 {{ "lang_per_page" | language }}
															</mat-option>
															<mat-option class="text-center" value="20"
																>20 {{ "lang_per_page" | language }}
															</mat-option>
															<mat-option class="text-center" value="50"
																>50 {{ "lang_per_page" | language }}
															</mat-option>
															<mat-option class="text-center" value="100"
																>100
																{{ "lang_per_page" | language }}</mat-option
															>
															<mat-option class="text-center" value="500"
																>500
																{{ "lang_per_page" | language }}</mat-option
															>
														</mat-select>
													</div>
												</div>
											</div>
											<div
												class="col-12 col-md-4"
												*ngIf="showConcreteFilter"
												[attr.style]="isMob ? 'order:1' : 'order:2'"
											>
												<mat-card class="card">
													<mat-card-title class="card-title">
														<h4>{{ "lang_advanced_filter" | language }}</h4>
													</mat-card-title>
													<mat-card-content>
														<form [formGroup]="filterConcreteForm">
															<div class="row">
																<div class="col-12">
																	<div class="form-group">
																		<label>{{
																			"lang_report_status" | language
																		}}</label>
																		<ng-select
																			[items]="report_status_list"
																			[multiple]="true"
																			bindLabel="label"
																			bindValue="value"
																			[searchFn]="ds.customSearchFn"
																			appendTo="body"
																			formControlName="report_status"
																			placeholder="{{
																				'lang_report_status' | language
																			}}"
																		>
																		</ng-select>
																	</div>
																</div>
																<div class="col-12 mt-1">
																	<div class="form-group">
																		<label>{{
																			"lang_concrete_age" | language
																		}}</label>
																		<ng-select
																			[items]="compressive_strength_list"
																			[multiple]="false"
																			bindLabel="label"
																			bindValue="value"
																			[searchFn]="ds.customSearchFn"
																			appendTo="body"
																			formControlName="compressive_strength"
																			placeholder="{{
																				'lang_concrete_age' | language
																			}}"
																		>
																		</ng-select>
																	</div>
																</div>
																<div class="col-12 mt-1">
																	<label for="group_one">{{
																		"lang_group_one" | language
																	}}</label>
																	<ng-select
																		[items]="group1ListC"
																		[multiple]="false"
																		bindLabel="label"
																		bindValue="value"
																		appendTo="body"
																		clearAllText="Clear"
																		placeholder="{{
																			'lang_group_one' | language
																		}}"
																		formControlName="group1_id"
																		id="group1_id"
																	>
																	</ng-select>
																</div>
																<div class="col-12 mt-1">
																	<label for="platform_code_system">{{
																		"lang_platform_code_system" | language
																	}}</label>
																	<input
																		type="text"
																		class="form-control"
																		id="platform_code_system"
																		name="platform_code_system"
																		formControlName="platform_code_system"
																	/>
																</div>
																<div class="col-12 mt-1">
																	<label for="level_key">{{
																		"lang_level_key" | language
																	}}</label>
																	<ng-select
																		[items]="levels_arr"
																		[multiple]="true"
																		bindLabel="label"
																		bindValue="value"
																		appendTo="body"
																		clearAllText="Clear"
																		formControlName="level_key"
																		placeholder="{{
																			'lang_level_key' | language
																		}}"
																	></ng-select>
																</div>
																<div class="col-12 mt-1">
																	<label for="sort_by">{{
																		"lang_sort_by" | language
																	}}</label>
																	<ng-select
																		[items]="sort_by_arr"
																		[multiple]="false"
																		bindLabel="label"
																		bindValue="value"
																		appendTo="body"
																		clearAllText="Clear"
																		formControlName="sort_by"
																		placeholder="{{
																			'lang_sort_by' | language
																		}}"
																	></ng-select>
																</div>
																<div class="col-12 mt-1">
																	<label for="order_by">{{
																		"lang_order_by" | language
																	}}</label>
																	<ng-select
																		[items]="order_by_arr"
																		[multiple]="false"
																		bindLabel="label"
																		bindValue="value"
																		appendTo="body"
																		clearAllText="Clear"
																		formControlName="order_by"
																		placeholder="{{
																			'lang_order_by' | language
																		}}"
																	></ng-select>
																</div>
																<div class="col-12 mt-1">
																	<label style="display: block">{{
																		"lang_previous_transaction_reports"
																			| language
																	}}</label>
																	<mat-slide-toggle
																		[color]="'primary'"
																		(change)="onConcretePreviousChange($event)"
																	>
																	</mat-slide-toggle>
																</div>
																<div class="col-12 mt-1">
																	<label style="display: block">{{
																		"lang_due_dates" | language
																	}}</label>
																	<mat-slide-toggle
																		[color]="'primary'"
																		(change)="dueDatesChange($event)"
																	>
																	</mat-slide-toggle>
																</div>
																<div
																	class="col-12 mt-5 d-flex justify-content-between"
																>
																	<button
																		type="button"
																		class="albutton"
																		style="width: 40% !important"
																		(click)="resetFilterConcreteForm()"
																	>
																		{{ "lang_reset" | language }}
																	</button>

																	<button
																		type="submit"
																		class="btn albutton filter-btn"
																		style="width: 40% !important"
																		(click)="onfilterConcreteForm()"
																	>
																		{{ "lang_submit" | language }}
																	</button>
																</div>
															</div>
														</form>
													</mat-card-content>
												</mat-card>
											</div>
										</div>
									</div>
								</div>
							</div>
						</mat-tab>
					</mat-tab-group>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab label="report" *ngIf="false">
		<ng-template mat-tab-label>{{ "lang_report" | language }}</ng-template>
		<div class="p-2">Content 3</div>
	</mat-tab>
</mat-tab-group>
