import { Component, Input, OnInit } from "@angular/core";

/* import moment, { Moment } from 'moment'; */
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatRangeDateSelectionModel,
  MatDatepicker,
} from "@angular/material/datepicker";
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { DataService } from "@core/bootstrap/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Output, EventEmitter } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { PreloaderService } from "@core";
import { Subscription } from "rxjs";
const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-step-one",
  templateUrl: "./step-one.component.html",
  styleUrls: ["./step-one.component.scss"],
  providers: [
    /*   {
        provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
        useClass: DefaultMatCalendarRangeStrategy
      },
      DefaultMatCalendarRangeStrategy,
       */
    MatRangeDateSelectionModel,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class StepOneComponent implements OnInit {
  @Input() firstFormGroup: FormGroup = this._formBuilder.group({
    start: [""],
    startAr: [""],
    salary_month: [moment(), Validators.required],
    end: [""],
    endAr: [],
    branch: [""],
  });
  @Input() requestInfo: any = "";
  @Input() stepper: MatStepper;
  @Input() t_id: any = "";
  @Output() formValueOutput = new EventEmitter<any>();
  branches: any;
  constructor(
    private _formBuilder: FormBuilder,
    public ds: DataService,
    private router: Router,
    private route: ActivatedRoute,
    public lang: LanguagePipe,
    private _sweetAlertService: SweetAlertService,
    private spinner: PreloaderService
  ) {}

  ngOnInit(): void {
    this.load_branches();
    moment.locale("en");
    if (this.t_id == 0) {
      this.setMonthAndYear(moment());
    }
  }

  sendDataToParent(value: any) {
    this.formValueOutput.emit(value);
  }
  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker?: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.firstFormGroup.value.salary_month;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.firstFormGroup.get("salary_month").setValue(ctrlValue);
    this.firstFormGroup.patchValue({
      start: moment(ctrlValue).startOf("month").format("YYYY/MM/DD"),
      end: moment(ctrlValue).endOf("month").format("YYYY/MM/DD"),
    });
    this.ds
      .arabicToGiorgen(
        "EN",
        moment(ctrlValue).startOf("month").format("DD/MM/YYYY")
      )
      .subscribe(
        (data) => {
          if (data.status) {
            let r = data.data.hijri.date.split("-");
            let reqdate = r[0] + "/" + r[1] + "/" + r[2];
            this.firstFormGroup.get("startAr").setValue(reqdate);
            this.sendDataToParent(this.firstFormGroup.value);
          } else {
            return "";
          }
        },
        (error) => {
          return "";
        }
      );

    this.ds
      .arabicToGiorgen(
        "EN",
        moment(ctrlValue).endOf("month").format("DD/MM/YYYY")
      )
      .subscribe(
        (data) => {
          if (data.status) {
            let r = data.data.hijri.date.split("-");
            let reqdate = r[0] + "/" + r[1] + "/" + r[2];
            this.firstFormGroup.get("endAr").setValue(reqdate);
            this.sendDataToParent(this.firstFormGroup.value);
          } else {
            return "";
          }
        },
        (error) => {
          return "";
        }
      );
    if (datepicker) {
      datepicker.close();
    }
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  StepOneSubmit() {
    if (this.firstFormGroup.valid) {
      this.spinner.show();
      let formData = new FormData();

      formData.append(
        `form_start_date_en`,
        moment(this.firstFormGroup.value.start).format("YYYY/MM/DD")
      );
      formData.append(`form_start_date_ar`, this.firstFormGroup.value.startAr);
      formData.append(
        `form_end_date_en`,
        moment(this.firstFormGroup.value.end).format("YYYY/MM/DD")
      );
      formData.append(`form_end_date_ar`, this.firstFormGroup.value.endAr);
      formData.append(`branch_id`, this.firstFormGroup.value.branch);
      formData.append(
        `salary_month`,
        `${parseInt(
          moment(this.firstFormGroup.value.salary_month).format("MM"),
          10
        )}`
      );
      formData.append(
        `salary_year`,
        moment(this.firstFormGroup.value.salary_month).format("YYYY")
      );

      this.ds.post(`form/FORM_SAL/create_request/0`, formData).subscribe(
        (data) => {
          if (data.status) {
            this.t_id = data.transaction_request_id;
            this._sweetAlertService.successToast(
              this.lang.transform("lang_data_sent_successfully"),
              2000
            );
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: { t_id: data.transaction_request_id },
              queryParamsHandling: "merge",
            });
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(data.error);
          }
        },
        (error) => {
          this._sweetAlertService.errorMessage(error?.error?.error);
          this.spinner.hide();
          /*    this.showMsg(error?.error?.error, 'ok', 'error-snackbar'); */
        }
      );
    } else {
      this._sweetAlertService.warningToast(
        "Please fill all fields first",
        2000
      );
    }
  }

  update_configuration_step() {
    this.spinner.show();
    let formData = new FormData();

    formData.append(`Configration_Step`, "select_employees");

    this.ds
      .post(`form/FORM_SAL/update_configuration_step/${this.t_id}`, formData)
      .subscribe(
        (data) => {
          if (data.status) {
            this.goForward(this.stepper);
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(data.error);
            /*   this.showMsg(data.error, 'ok', 'error-snackbar'); */
          }
        },
        (error) => {
          this._sweetAlertService.errorMessage(error?.error?.error);
          this.spinner.hide();
          /*    this.showMsg(error?.error?.error, 'ok', 'error-snackbar'); */
        }
      );
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds
        .getActionByUrl([], "form/FORM_SAL/get_branches")
        .subscribe((res) => {
          if (res.status) {
            this.branches = res.records;
          }
        })
    );
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
