<div class="mbrl15 martb0">
  <span class="back-to-shifts" routerLink="/default/shifts"> 
    <i class="fa fa-angle-double-left" aria-hidden="true"></i>
    <span>{{"lang_shifts" | language}}</span>
  </span>
</div>
<div class="card mbrl15 martb0">
    <mat-horizontal-stepper [linear]="true" #stepper class="pad0 steps13-stepper">
        <mat-step [stepControl]="shiftNameForm">
            <form [formGroup]="shiftNameForm">
                <ng-template matStepLabel>Shift Name</ng-template>
                <div class="row">
                    <div class="col-12">
                        <h4 class="mb-4">Shift Name</h4>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{"lang_name_en" | language}}</mat-label>
                            <input matInput formControlName="shift_name_english" placeholder="{{'lang_name_en' | language}}" required>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{"lang_name_ar" | language}}</mat-label>
                            <input matInput formControlName="shift_name_arabic" placeholder="{{'lang_name_ar' | language}}" required>
                        </mat-form-field>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <div class="col-12 col-sm-3">
                        <button type="submit" class="albutton" (click)="submitShiftName()">Next</button>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="shiftTimeForm">
            <form >
                <ng-template matStepLabel>Shift Setup</ng-template>
                <div class="col-12">
                    <h4>Shift Setup 
                      <span>
                        ({{shiftNameForm?.get('shift_name_english')?.value}})
                      </span>
                    </h4>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="small-title">Work Days</h5>
                </div>
                <div class="days-off">
                    <div class="day-off">
                        <p>Monday</p>
                        <p-toggleButton onIcon="pi pi-check" offIcon="pi pi-times" [(ngModel)]="mondayDayOff" [ngModelOptions]="{standalone: true}"></p-toggleButton>
                    </div>
                    <div class="day-off">
                        <p>Tuesday</p>
                        <p-toggleButton onIcon="pi pi-check" offIcon="pi pi-times" [(ngModel)]="tuesdayDayOff" [ngModelOptions]="{standalone: true}"></p-toggleButton>
                    </div>
                    <div class="day-off">
                        <p>Wednesday</p>
                        <p-toggleButton onIcon="pi pi-check" offIcon="pi pi-times" [(ngModel)]="wednesdayDayOff" [ngModelOptions]="{standalone: true}"></p-toggleButton>
                    </div>
                    <div class="day-off">
                        <p>Thursday</p>
                        <p-toggleButton onIcon="pi pi-check" offIcon="pi pi-times" [(ngModel)]="thursdayDayOff" [ngModelOptions]="{standalone: true}"></p-toggleButton>
                    </div>
                    <div class="day-off">
                        <p>Friday</p>
                        <p-toggleButton onIcon="pi pi-check" offIcon="pi pi-times" [(ngModel)]="fridayDayOff" [ngModelOptions]="{standalone: true}"></p-toggleButton>
                    </div>
                    <div class="day-off">
                        <p>Saturday</p>
                        <p-toggleButton onIcon="pi pi-check" offIcon="pi pi-times" [(ngModel)]="saturdayDayOff" [ngModelOptions]="{standalone: true}"></p-toggleButton>
                    </div>
                    <div class="day-off">
                        <p>Sunday</p>
                        <p-toggleButton onIcon="pi pi-check" offIcon="pi pi-times" [(ngModel)]="sundayDayOff" [ngModelOptions]="{standalone: true}"></p-toggleButton>
                    </div>
                </div>
                <div class="col-12 mt-3">
									<div class="row mt-4">
										<div class="col-md-4">
												<h5 class="small-title">Shift time</h5>
										</div>
										<div class="col-md-6"></div>
										<div class="col-md-2">
												<button class="albutton" 
												(click)="addshiftsRecord()"  
												type="button">
												{{'lang_add' | language }}
											</button>
										</div>
									</div>
                    <form [formGroup]="shiftTimeForm">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                              <div class="col-12 TableParts">
                                <div
                                  class="table-responsive customResponsive tablefields quotationitemtable"
                                >
                                  <table
                                    border="1"
                                    class="table CustomTable"
                                    width="100%"
                                    formArrayName="shifts"
                                  >
                                    <thead>
                                      <tr>
                                        <td
                                          width="2%"
                                          style="padding: 5px; word-break: break-all"
                                        >
                                          #
                                        </td>
                                        <td
                                          style="
                                            padding: 5px;
                                            word-break: break-all;
                                            width: 20%;
                                          "
                                        >
                                          {{ "lang_type" | language }}
                                        </td>
                                        <td
                                          style="
                                            padding: 5px;
                                            word-break: break-all;
                                            width: 20%;
                                          "
                                        >
																				Date
                                        </td>
                                        <td
                                          style="
                                            padding: 5px;
                                            word-break: break-all;
                                            width: 20%;
                                          "
                                        >
                                          {{ "lang_shift_start_time" | language }}
                                        </td>
                                        <td
                                          style="
                                            padding: 5px;
                                            word-break: break-all;
                                            width: 20%;
                                          "
                                        >
                                          {{ "lang_shift_end_time" | language }}
                                        </td>
                                        <td
                                          style="
                                            padding: 5px;
                                            word-break: break-all;
                                            width: 20%;
                                          "
                                        >
                                          {{ "lang_actions" | language }}
                                        </td>
                                      </tr>
                                    </thead>
                                    <tbody >
                                      <tr
                                        *ngFor="
                                          let item of shifts.controls;
                                          let i = index;
                                          let odd = odd;
                                          let even = even
                                        "
                                        [formGroupName]="i"
                                      >
                                        <td class="verticalalignmid" style="padding: 5px">
                                          {{ i + 1 }}
                                        </td>
                                        <td>
                                          <ng-select
                                            [items]="shiftDataTypes"
                                            [multiple]="false"
                                            bindLabel="label"
                                            bindValue="value"
                                            [searchable]="false"
																						[clearable]="false"
                                            appendTo="body"
                                            formControlName="shift_custom_data_type"
                                            placeholder="{{ 'lang_type' | language }}"
                                          >
                                          </ng-select>
                                        </td>
                                        <td>
                                          <p-calendar formControlName="date"
                                          *ngIf="shifts?.controls[i]?.get('shift_custom_data_type')?.value == 'CT' || shifts?.controls[i]?.get('shift_custom_data_type')?.value == 'H'"
                                              selectionMode="range" 
                                              appendTo="body"
                                              dateFormat="yy-mm-dd"
                                              [disabled]="shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'CT' && shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'H'"
                                              placeholder="Date"
                                              [readonlyInput]="true" 
                                              inputId="range">
                                          </p-calendar>
                                          <span *ngIf="shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'CT' && shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'H'">--</span>
                                        </td>
                                        <td>
                                          <p-calendar formControlName="shiftStartTime" 
                                              *ngIf="shifts?.controls[i]?.get('shift_custom_data_type')?.value == 'CT' || shifts?.controls[i]?.get('shift_custom_data_type')?.value == 'T'"
                                              [timeOnly]="true" 
                                              placeholder="{{ 'lang_shift_start_time' | language }}"
                                              appendTo="body"
                                              [disabled]="shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'CT' && shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'T'"
                                              hourFormat="24"
                                              inputId="timeonly">
                                          </p-calendar>
                                          <span *ngIf="shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'CT' && shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'T'">--</span>
                                        </td>
                                        <td>
                                          <p-calendar formControlName="shiftEndTime" 
                                              *ngIf="shifts?.controls[i]?.get('shift_custom_data_type')?.value == 'CT' || shifts?.controls[i]?.get('shift_custom_data_type')?.value == 'T'"
                                              [timeOnly]="true" 
                                              placeholder="{{ 'lang_shift_end_time' | language }}"
                                              appendTo="body"
                                              [disabled]="shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'CT' && shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'T'"
                                              hourFormat="24"
                                              inputId="timeonly">
                                          </p-calendar>
                                          <span *ngIf="shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'CT' && shifts?.controls[i]?.get('shift_custom_data_type')?.value != 'T'">--</span>
                                        </td>
                                        <td>
                                          <div class="d-flex align-items-center justify-content-center">
                                            <span (click)="onRemoveItem(i)" class="mr-2 ml-2 ">
                                              <i class="fa fa-trash red"></i>
                                            </span>
                                            <span class="apply-all-shifts">
                                              <p-inputSwitch formControlName="apply_all_shifts" matTooltip="Apply on all shifts" [matTooltipPosition]="'above'"></p-inputSwitch>
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody
                                      *ngIf="!shiftsFormArray.length"
                                      class="AppendList"
                                    >
                                      <tr class="odd">
                                        <td colspan="6" align="center">
                                          {{ "lang_no_data" | language }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-4">
                    <div class="col-12 col-sm-3">
                        <button type="button" class="albutton" matStepperPrevious>Back</button>
                    </div>
                    <div class="col-12 col-sm-3">
                        <button type="button" class="albutton" (click)="onSubmit()">Submit</button>
                    </div>
                </div>
            </form>
        </mat-step>
    
    </mat-horizontal-stepper>
</div>