<div class="card">
	<div class="card-header">{{ "lang_stages" | language }}</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-12 mb-10">
					<div class="input-group input-group-sm">
						<input
							type="text"
							class="form-control almnabrformcontrol"
							placeholder="{{ 'lang_search' | language }}"
						/>
						<span class="input-group-btn" *ngIf="activeContractId">
							<button
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_add' | language }}"
								class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
								(click)="onAddUpdateStage()"
							></button>
						</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 mt-2">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td style="width: 6%">#</td>
									<td style="width: 15%">Stage Title</td>
									<td style="width: 6%">Payment</td>
									<td style="width: 10%">Payment Status</td>
									<td style="width: 10%">Action</td>
								</tr>
							</thead>
							<tbody *ngIf="stagestList.length" class="AppendList">
								<ng-container
									*ngFor="
										let list of stagestList;
										let stage_index = index;
										let odd = odd;
										let even = even
									"
								>
									<tr
										[ngClass]="{
											odd: odd,
											even: even,
											expanded: list.expanded
										}"
									>
										<td>{{ stage_index + 1 }}</td>
										<td>{{ list.contract_stage_name_english }}</td>
										<td>{{ list.contract_stage_payment }}</td>
										<td>{{ list.payment_status }}</td>
										<td>
											<i
												class="fa fa-tasks mnabricon edit-icon ml-2 mr-2"
												matTooltip="{{ 'lang_stage_tasks' | language }}"
												(click)="
													getStageTasks(list?.contract_stage_id, viewStageTask)
												"
											></i>
											<i
												class="fa fa-plus-square mnabricon edit-icon"
												matTooltip="{{ 'lang_add_task_to_stage' | language }}"
												(click)="onAddTaskToStage(list, addStageTask)"
											></i>
											<i
												class="fa fa-pencil-square-o mnabricon edit-icon ml-2 mr-2"
												matTooltip="{{ 'lang_edit' | language }}"
												(click)="onAddUpdateStage(list)"
											></i>
											<i
												class="fa fa-trash red edit-icon"
												matTooltip="{{ 'lang_delete' | language }}"
												(click)="deleteStage(list?.contract_stage_id)"
											></i>
										</td>
									</tr>
								</ng-container>
							</tbody>
							<tbody *ngIf="!stagestList.length">
								<tr>
									<td colspan="8">
										<no-data
											style="margin: auto"
											[Opened]="stagestList.length < 1"
										></no-data>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer padb0">
			<div class="row" *ngIf="stagestList.length > 0">
				<div
					class="col-lg-3 col-sm-6 col-6 mb-10"
					style="height: fit-content; margin: auto 0"
				>
					{{ "lang_total" | language }} : {{ stagestList?.length }}
				</div>
				<div
					class="col-lg-6 col-sm-6 mb-10"
					style="display: flex; justify-content: center"
				>
					<ngb-pagination
						class="m-auto"
						[collectionSize]="stagesCollectionSize"
						[rotate]="true"
						[ellipses]="false"
						[maxSize]="3"
						[boundaryLinks]="true"
						[(page)]="pageno"
						[pageSize]="pageSize"
					></ngb-pagination>
				</div>
				<div
					class="col-lg-3 col-sm-6"
					style="display: flex; justify-content: end; margin: auto"
				>
					<mat-select
						style="max-width: 150px"
						[(ngModel)]="pageSize"
						(selectionChange)="getStages()"
					>
						<mat-option class="text-center" value="10"
							>10 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="20"
							>20 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="50"
							>50 {{ "lang_per_page" | language }}
						</mat-option>
						<mat-option class="text-center" value="100"
							>100 {{ "lang_per_page" | language }}</mat-option
						>
						<mat-option class="text-center" value="500"
							>500 {{ "lang_per_page" | language }}</mat-option
						>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #addStageTask>
	<div class="modal-content">
		<!-- Modal Header -->
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_tasks" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<!-- Modal body -->
		<div class="modal-body">
			<form [formGroup]="taskStageForm">
				<div class="row">
					<div class="col-12">
						<ng-select
							[items]="taskList"
							[multiple]="false"
							bindLabel="title"
							bindValue="task_id"
							clearAllText="Clear"
							[searchFn]="ds.customSearchFn"
							appendTo="body"
							formControlName="task_id"
							placeholder="{{ 'lang_task' | language }}"
						>
						</ng-select>
					</div>
				</div>
			</form>
		</div>
		<!-- Modal footer -->
		<div class="modal-footer justify-content-between">
			<button
				type="button"
				class="btn btn-secondary"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				{{ "lang_close" | language }}
			</button>
			<button
				type="button"
				class="albutton mnabrbutton"
				mat-button
				(click)="submitTaskToStage()"
				style="width: 100px !important"
			>
				{{ "lang_submit" | language }}
			</button>
		</div>
	</div>
</ng-template>

<ng-template #viewStageTask>
	<div class="modal-content">
		<!-- Modal Header -->
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_tasks" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<!-- Modal body -->
		<div class="modal-body">
			<div class="row">
				<div class="col-12 stage-tasks-table">
					<table border="1" class="table CustomTable">
						<thead>
							<tr>
								<th width="10%">{{ "lang_ticket_number" | language }}</th>
								<th width="10%">{{ "lang_task_number" | language }}</th>
								<th width="30%">{{ "lang_title" | language }}</th>
								<th width="30%">{{ "lang_start_date" | language }}</th>
								<th width="20%">{{ "lang_end_date" | language }}</th>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let item of stageTasks;
									let i = index;
									let odd = odd;
									let even = even
								"
							>
								<td>{{ item?.ticket_no }}</td>
								<td>{{ item?.task_no }}</td>
								<td>{{ item?.title }}</td>
								<td>{{ item?.start_date_task }}</td>
								<td>
									{{ item?.end_date_task || item?.end_date_nearly_ticket }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!-- Modal footer -->
		<div class="modal-footer">
			<button
				type="button"
				class="btn btn-secondary"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				{{ "lang_close" | language }}
			</button>
		</div>
	</div>
</ng-template>
