<section class="panel-expansion ckeditorhidetoolbar">
	<mat-accordion class="stylepanels">
		<mat-expansion-panel class="mpanel" [expanded]="true">
			<mat-expansion-panel-header class="mpanel-header"
				>{{ "lang_jop_offer" | language }} (
				<span *ngIf="lang_key == 'en'">
					{{ employee_data?.firstname_english }}
					{{ employee_data?.lastname_english }}</span
				>
				<span *ngIf="lang_key == 'ar'">
					{{ employee_data?.firstname_arabic }}
					{{ employee_data?.lastname_arabic }}
				</span>
				)
			</mat-expansion-panel-header>
			<div class="mbrl15">
				<mat-horizontal-stepper
					[linear]="isLinear"
					[selectedIndex]="selectedIndex"
					(selectionChange)="selectionChange($event)"
					class="w-100 cuatomMat"
					[ngClass]="{
						ltr: lang_key != 'ar',
						rtl: lang_key == 'ar',
						fisrtClass: selectedIndex >= 0,
						secondClass: selectedIndex >= 1,
						thirdClass: selectedIndex >= 2,
						fourthClass: selectedIndex >= 3,
						fifthClass: selectedIndex >= 4,
						sixClass: selectedIndex >= 5
					}"
					labelPosition="bottom"
					#stepper
				>
					<!--   <mat-step [stepControl]="firstFormGroup" class="mt-3">
                        <div [formGroup]="firstFormGroup" class="mx-3">
                          <ng-template matStepLabel>
                            
                            <div class="d-block inactive textColor" >
                              <div class="custom">
                
                              </div>
                              {{'lang_select'|language}} {{'lang_type'|language}}
                            </div>
                            
                          </ng-template>
                          
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label> {{'lang_select' | language}} {{'lang_type' | language}} <span class="red">*</span></label>
                                    <select formControlName="typeOfContract" type="text" matTooltip="{{'lang_select' | language}} {{'lang_type' | language}}" class="form-control almnabrformcontrol">
                                        <option value="">{{'lang_choose_options' | language }}</option>
                                        <option value="1">{{'lang_new_contract' | language}}</option>
                                        <option value="2">{{'lang_jop_offer' | language}}</option>
                                        <option value="3">{{'lang_update_contract' | language}}</option>
                                        
                                    </select>
                                    <mat-error *ngIf="submitted && firstFormGroup.controls['typeOfContract'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                </div>
                            </div>


                          </div>
                          <div class="w-100 text-end mt-3">
                            <button  class="btn mb-2 btn-cancel" matStepperPrevious >{{'lang_cancel'|language}}</button>
                            <button  class="btn mb-2 mx-2 btn-apply" matStepperNext >{{'lang_next'|language}}</button>
                          </div>
               
                         
                        </div>
                      </mat-step> -->

					<mat-step [stepControl]="secondFormGroup" class="mt-3">
						<div [formGroup]="secondFormGroup">
							<ng-template matStepLabel>
								<div class="d-block inactive textColor">
									<div class="custom"></div>
									{{ "lang_info" | language }}
								</div>
							</ng-template>

							<div class="row">
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_subject" | language }}
											<span class="red">*</span></label
										>
										<input
											type="text"
											class="form-control almnabrformcontrol"
											readonly
											placeholder="{{ 'lang_subject' | language }}"
											formControlName="subject"
										/>
										<mat-error
											*ngIf="
												submitted &&
												secondFormGroup.controls['subject'].errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_from_organization" | language }}
											<span class="red">*</span></label
										>
										<ng-select
											[items]="branches"
											[multiple]="false"
											bindLabel="label"
											bindValue="value"
											clearAllText="Clear"
											appendTo="body"
											formControlName="transaction_from"
											placeholder="{{ 'lang_from_org' | language }}"
										>
										</ng-select>
										<!--    <input
                                        type="text"
                                        formControlName="transaction_from"
                                        placeholder="{{ 'lang_from_organization' | language }}"
                                        class="form-control almnabrformcontrol"
                                    /> -->
										<mat-error
											*ngIf="
												submitted &&
												secondFormGroup.controls['transaction_from'].touched &&
												secondFormGroup.controls['transaction_from'].errors
													?.required
											"
										>
											{{ "lang_field_required" | language }}
										</mat-error>
									</div>
								</div>
								<!--   <div class="col-md-4 col-sm-4 col-xs-12">
                             <div class="form-group">
                                    <label> {{'lang_from_org' | language}} </label>
                                    <input type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_from_org' | language}}" formControlName="transaction_from">
                                    <mat-error *ngIf="submitted && secondFormGroup.controls['transaction_from'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                </div> -->

								<!--     <div class="form-group">
                                 <label>
                                        {{ "lang_to" | language }} <span class="red">*</span></label
                                    >

                                    <input
                                    type="text"
                                    formControlName="transaction_to"
                                    placeholder="{{ 'lang_to' | language }}"
                                    class="form-control almnabrformcontrol"
                                />
                               
                                    <mat-error
                                        *ngIf="submitted && 
                                        secondFormGroup.controls['transaction_to'].touched &&
                                        secondFormGroup.controls['transaction_to'].errors?.required
                                        "
                                    >
                                        {{ "lang_field_required" | language }}
                                    </mat-error> 
                                </div>
                            </div>-->

								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_work_domain" | language }}
											<span class="red">*</span></label
										>
										<input
											type="text"
											class="form-control almnabrformcontrol"
											placeholder="{{ 'lang_work_domain' | language }}"
											formControlName="work_domain"
										/>
										<mat-error
											*ngIf="
												submitted &&
												secondFormGroup.controls['work_domain'].errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_work_location" | language }}
											<span class="red">*</span></label
										>
										<input
											type="text"
											class="form-control almnabrformcontrol"
											placeholder="{{ 'lang_work_location' | language }}"
											formControlName="work_location"
										/>
										<mat-error
											*ngIf="
												submitted &&
												secondFormGroup.controls['work_location'].errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_work_type" | language }}
											<span class="red">*</span></label
										>
										<select
											formControlName="work_type"
											type="text"
											matTooltip="{{ 'lang_work_type' | language }}"
											class="form-control almnabrformcontrol"
										>
											<option value="">
												{{ "lang_choose_options" | language }}
											</option>
											<option value="1">
												{{ "lang_fulltime" | language }}
											</option>
											<option value="2">
												{{ "lang_parttime" | language }}
											</option>
											<option value="3">
												{{ "lang_contract" | language }}
											</option>
											<option value="4">{{ "lang_others" | language }}</option>
										</select>
										<mat-error
											*ngIf="
												submitted &&
												secondFormGroup.controls['work_type'].errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
							</div>

							<div class="w-100 text-end mt-3">
								<button class="btn mb-2 mx-2 btn-apply" matStepperNext>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
						<!-- <app-step-two  
                          [usersList]="usersList"
                          [t_id]="t_id"
                          [projects]="projects"
                          [branches]="branches"
                          [stepper]="stepper" 
                          (UserListSetter)="fillUserListFromStepTwo($event)" (sendComponentUpdate)="emitEventToChild()" ></app-step-two> -->
					</mat-step>
					<mat-step [stepControl]="thirdFormGroup" class="mt-3">
						<div [formGroup]="thirdFormGroup">
							<ng-template matStepLabel>
								<div class="d-block inactive textColor">
									<div class="custom"></div>
									{{ "lang_work" | language }}
								</div>
							</ng-template>

							<div class="row">
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label for="joining_date_english"
											>{{
												"lang_human_resources_joining_date_english" | language
											}}
											: <span class="red">*</span></label
										>
										<div class="positionrelatiove">
											<input
												formControlName="joining_date_english"
												type="text"
												class="form-control almnabrformcontrol"
												(dateChange)="
													ds.setENTOARDate(
														$event,
														thirdFormGroup.get('joining_date_arabic')
													);
													probation_enddate()
												"
												[readonly]="true"
												placeholder="YYYY/MM/DD"
												[matDatepicker]="BDeng"
											/>
											<mat-datepicker-toggle
												matSuffix
												[for]="BDeng"
												class="posiab"
											></mat-datepicker-toggle>
											<mat-datepicker #BDeng disabled="false"></mat-datepicker>
											<mat-error
												*ngIf="
													submitted &&
													thirdFormGroup.controls['joining_date_english'].errors
														?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</div>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label for="joining_date_arabic"
											>{{
												"lang_human_resources_joining_date_arabic" | language
											}}
											: <span class="red">*</span></label
										>
										<input
											formControlName="joining_date_arabic"
											(ngModelChange)="
												ds.setARTOENDate(
													$event,
													thirdFormGroup.get('joining_date_english')
												);
												probation_enddate()
											"
											ngbDatepicker
											[readonly]="true"
											(click)="bdarab.toggle()"
											#bdarab="ngbDatepicker"
											type="text"
											class="form-control almnabrformcontrol"
											placeholder="YYYY/MM/DD"
										/>
										<mat-error
											*ngIf="
												submitted &&
												thirdFormGroup.controls['joining_date_arabic'].errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label for="probation_period"
											>{{
												"lang_human_resources_propation_period" | language
											}}
											: <span class="red"> * </span>
											<span *ngIf="probationenddate" class="red"
												>{{ "lang_end_date" | language }} :
												{{ probationenddate }}</span
											>
										</label>
										<ng-select
											[items]="pperiods"
											[multiple]="false"
											bindLabel="label"
											bindValue="value"
											clearAllText="Clear"
											appendTo="body"
											formControlName="probation_period"
											placeholder="{{
												'lang_human_resources_propation_period' | language
											}}"
											(change)="probation_enddate()"
										>
										</ng-select>
										<mat-error
											*ngIf="
												submitted &&
												thirdFormGroup.controls['probation_period'].errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label for="contract_start_date_english"
											>{{
												"lang_human_resources_contract_start_date" | language
											}}
											: <span class="red">*</span></label
										>
										<div class="positionrelatiove">
											<input
												formControlName="contract_start_date_english"
												type="text"
												class="form-control almnabrformcontrol"
												(dateChange)="
													calculate_contract_enddate(); vacation_enddate()
												"
												[readonly]="true"
												placeholder="YYYY/MM/DD"
												[matDatepicker]="ctrse"
											/>
											<mat-datepicker-toggle
												matSuffix
												[for]="ctrse"
												class="posiab"
											></mat-datepicker-toggle>
											<mat-datepicker #ctrse disabled="false"></mat-datepicker>
											<mat-error
												*ngIf="
													submitted &&
													thirdFormGroup.controls['contract_start_date_english']
														.errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</div>
									</div>
								</div>

								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label for="contract_period"
											>{{ "lang_human_resources_contract_period" | language }} :
											<span class="red">*</span></label
										>
										<ng-select
											[items]="cperiods"
											[multiple]="false"
											bindLabel="label"
											bindValue="value"
											clearAllText="Clear"
											appendTo="body"
											formControlName="contract_period"
											placeholder="{{
												'lang_human_resources_contract_period' | language
											}}"
											(change)="
												calculate_contract_enddate(); vacation_enddate()
											"
										>
										</ng-select>
										<mat-error
											*ngIf="
												submitted &&
												thirdFormGroup.controls['contract_period'].errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label for="contract_end_date_english"
											>{{ "lang_human_resources_contract_end_date" | language }}
											:
											<span
												class="red"
												*ngIf="
													thirdFormGroup.get('contract_period').value &&
													thirdFormGroup.get('contract_period').value != '0'
												"
												>*</span
											></label
										>
										<div class="positionrelatiove">
											<input
												formControlName="contract_end_date_english"
												type="text"
												class="form-control almnabrformcontrol"
												[readonly]="true"
												placeholder="YYYY/MM/DD"
											/>
											<mat-error
												*ngIf="
													submitted &&
													thirdFormGroup.controls['contract_end_date_english']
														.errors?.required
												"
												>{{ "lang_field_required" | language }}</mat-error
											>
										</div>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label for="contract_period"
											>{{
												"lang_human_resources_notification_period" | language
											}}
											: <span class="red">*</span></label
										>
										<input
											type="number"
											(keypress)="isNumber($event)"
											class="form-control almnabrformcontrol"
											placeholder="{{
												'lang_human_resources_notification_period' | language
											}}"
											formControlName="notification_period"
										/>

										<mat-error
											*ngIf="
												submitted &&
												thirdFormGroup.controls['notification_period'].errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>

								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_auto_renew" | language }}
											<span class="red">*</span></label
										>
										<select
											formControlName="auto_renew"
											type="text"
											matTooltip="{{ 'lang_auto_renew' | language }}"
											class="form-control almnabrformcontrol"
										>
											<option value="">
												{{ "lang_choose_options" | language }}
											</option>
											<option value="0">{{ "lang_no" | language }}</option>
											<option value="1">{{ "lang_yes" | language }}</option>
										</select>
										<mat-error
											*ngIf="
												submitted &&
												thirdFormGroup.controls['auto_renew'].errors?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
							</div>

							<div class="w-100 text-end mt-3">
								<button class="btn mb-2 btn-cancel" matStepperPrevious>
									{{ "lang_previous" | language }}
								</button>
								<button class="btn mb-2 mx-2 btn-apply" matStepperNext>
									{{ "lang_next" | language }}
								</button>
							</div>
							<!-- <app-step-three [t_id]="t_id" 
                        [stepper]="stepper"  (UserListSetter)="fillUserListFromStepThree($event)"
                          [refresh]="refreshSubject.asObservable()"></app-step-three> -->
						</div>
					</mat-step>
					<mat-step [stepControl]="fourthFormGroup" class="mt-3">
						<div [formGroup]="fourthFormGroup">
							<ng-template matStepLabel>
								<!-- <div class="d-block inactive textColor" >
                              <div class="custom">
                
                              </div>
                              {{'lang_reviewers'|language}}
                            </div>
                          </ng-template> -->

								<div class="d-block inactive textColor">
									<div class="custom"></div>
									{{ "lang_salary" | language }}
								</div>
							</ng-template>

							<div class="row">
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="row">
										<div class="col-12 col-xs-12 mb-10">
											<div class="form-group">
												<label for="vacation_paid_days"
													>{{
														"lang_human_resources_vacation_paid_days" | language
													}}
													: <span class="red">*</span>
													<span class="red" *ngIf="vacation_custom_date">{{
														vacation_custom_date
													}}</span></label
												>

												<input
													type="number"
													(keypress)="isNumber($event)"
													class="form-control almnabrformcontrol"
													placeholder="{{
														'lang_human_resources_vacation_paid_days' | language
													}}"
													formControlName="vacation_paid_days"
												/>
												<mat-error
													*ngIf="
														submitted &&
														fourthFormGroup.controls['vacation_paid_days']
															.errors?.required
													"
													>{{ "lang_field_required" | language }}</mat-error
												>
											</div>
										</div>
										<!--  <div class="col-6 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label for="vacation_custom_date">{{'lang_human_resources_custom_vacation_date' | language }} : </label>
                                            <div class="input-group input-group-sm">
                                                <input [matDatepicker]="vacation_custom_dateU" formControlName="vacation_custom_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly>
                                                <span class="input-group-btn">
                                                    <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="vacation_custom_dateU"></mat-datepicker-toggle>
                                                    <mat-datepicker #vacation_custom_dateU></mat-datepicker>
                                                </span>
                                                <span class="input-group-btn">
                                                    <button (click)="fourthFormGroup.get('vacation_custom_date').setValue('');" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                                </span>
                                            </div>
                                        </div>
                                    </div> -->
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label for="working_days_per_week"
											>{{
												"lang_human_resources_working_days_per_week" | language
											}}
											: <span class="red">*</span></label
										>
										<ng-select
											[items]="workingdays"
											[multiple]="false"
											bindLabel="label"
											bindValue="value"
											clearAllText="Clear"
											appendTo="body"
											formControlName="working_days_per_week"
											placeholder="{{
												'lang_human_resources_working_days_per_week' | language
											}}"
										>
										</ng-select>
										<mat-error
											*ngIf="
												submitted &&
												fourthFormGroup.controls['working_days_per_week'].errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label for="working_hours"
											>{{ "lang_human_resources_working_hours" | language }} :
											<span class="red">*</span></label
										>
										<ng-select
											[items]="workinghours"
											[multiple]="false"
											bindLabel="label"
											bindValue="value"
											clearAllText="Clear"
											appendTo="body"
											formControlName="working_hours"
											placeholder="{{
												'lang_human_resources_working_hours' | language
											}}"
										>
										</ng-select>
										<mat-error
											*ngIf="
												submitted &&
												fourthFormGroup.controls['working_hours'].errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_ticket_amount" | language }}
										</label>
										<input
											type="number"
											(keypress)="isNumber($event)"
											class="form-control almnabrformcontrol"
											placeholder="{{ 'lang_ticket_amount' | language }}"
											formControlName="ticket_amount"
										/>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label>
											{{ "lang_basic_salary" | language }}
											<span class="red">*</span></label
										>
										<input
											type="number"
											(change)="
												ds.CheckDecimal(
													$event.target.value,
													fourthFormGroup.get('basic_salary')
												);
												calculate_net_amount()
											"
											(keyup)="calculate_net_amount()"
											(keypress)="isNumber($event)"
											class="form-control almnabrformcontrol"
											placeholder="{{ 'lang_basic_salary' | language }}"
											formControlName="basic_salary"
										/>
										<mat-error
											*ngIf="
												submitted &&
												fourthFormGroup.controls['basic_salary'].errors
													?.required
											"
											>{{ "lang_field_required" | language }}</mat-error
										>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label> {{ "lang_home_allowance" | language }} </label>
										<input
											type="text"
											(change)="
												ds.CheckDecimal(
													$event.target.value,
													fourthFormGroup.get('home_allowance')
												);
												calculate_net_amount()
											"
											(keyup)="calculate_net_amount()"
											class="form-control almnabrformcontrol"
											placeholder="{{ 'lang_home_allowance' | language }}"
											formControlName="home_allowance"
										/>
									</div>
								</div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<div class="form-group">
										<label> {{ "lang_net_amount" | language }} </label>
										<input
											type="text"
											class="form-control almnabrformcontrol"
											placeholder="{{ 'lang_net_amount' | language }}"
											readonly
											disabled
											[ngModelOptions]="{ standalone: true }"
											[(ngModel)]="net_amount"
										/>
									</div>
								</div>
							</div>
							<div class="row mt-3">
								<div class="col-md-8"></div>
								<div class="col-md-4 col-sm-4 col-xs-12">
									<button
										*ngIf="!otherallowances"
										class="albutton mnabrbutton mat-accent"
										mat-button
										(click)="enable_field('allowances')"
									>
										<i class="fa fa-plus"></i>
										{{ "lang_other_allowances" | language }}
									</button>
								</div>
							</div>
							<div class="row mb-10" *ngIf="otherallowances">
								<div class="col-md-12 col-sm-12 col-xs-12 mb-10">
									<fieldset class="cardback fieldset">
										<legend>
											{{ "lang_other_allowances" | language }}
											<span class="red"
												>*
												<mat-icon
													class="red mnabricon"
													(click)="
														disable_field('allowances'); calculate_net_amount()
													"
													matTooltip="{{ 'lang_close' | language }}"
													>close</mat-icon
												></span
											>
										</legend>
										<div class="table-responsive customResponsive tablefields">
											<table
												border="1"
												class="table CustomTable"
												width="100%"
												formArrayName="other_allowances"
											>
												<thead>
													<tr>
														<td style="padding: 5px; word-break: break-all">
															<mat-icon
																class="mnabricon"
																matTooltip="{{ 'lang_add' | language }}"
																(click)="
																	add_new_others(); calculate_net_amount()
																"
																>add_box</mat-icon
															>
														</td>
														<td style="padding: 5px; word-break: break-all">
															{{ "lang_desc_english" | language }}
														</td>
														<td style="padding: 5px; word-break: break-all">
															{{ "lang_invoice_item_amount" | language }}
														</td>
														<td style="padding: 5px; word-break: break-all">
															{{ "lang_desc_arabic" | language }}
														</td>
													</tr>
												</thead>
												<tbody>
													<tr
														*ngFor="
															let otr of othersgroup.controls;
															let i = index;
															let odd = odd;
															let even = even
														"
														[ngClass]="{ odd: odd, even: even }"
														[formGroupName]="i"
														style="background: #ffffff"
													>
														<td width="1%" class="verticalalignmid">
															<mat-icon
																class="mnabricon red"
																matTooltip="{{ 'lang_delete' | language }}"
																(click)="
																	remove_other_items(i); calculate_net_amount()
																"
																>close</mat-icon
															>
														</td>
														<td
															style="padding: 5px; word-break: break-all"
															style="position: relative"
														>
															<input
																type="text"
																formControlName="other_allowance_description_en"
																placeholder="{{
																	'lang_description' | language
																}}"
																autocomplete="off"
																class="form-control almnabrformcontrol"
															/>
															<mat-error
																*ngIf="
																	submitted &&
																	otherallowances &&
																	other_allownces_datas.controls[i].get(
																		'other_allowance_description_en'
																	).errors?.required
																"
																>{{ "lang_field_required" | language }}
															</mat-error>
														</td>
														<td
															style="padding: 5px; word-break: break-all"
															style="position: relative"
														>
															<input
																type="text"
																formControlName="other_allowance_amount"
																placeholder="0.00"
																(change)="
																	ds.CheckDecimal(
																		$event.target.value,
																		other_allownces_datas.controls[i].get(
																			'other_allowance_amount'
																		)
																	);
																	calculate_net_amount()
																"
																(keyup)="calculate_net_amount()"
																autocomplete="off"
																class="form-control almnabrformcontrol"
															/>
															<mat-error
																*ngIf="
																	submitted &&
																	otherallowances &&
																	other_allownces_datas.controls[i].get(
																		'other_allowance_amount'
																	).errors?.required
																"
															>
																{{ "lang_field_required" | language }}
															</mat-error>
														</td>
														<td
															style="padding: 5px; word-break: break-all"
															style="position: relative"
														>
															<input
																type="text"
																formControlName="other_allowance_description_ar"
																placeholder="{{
																	'lang_description' | language
																}}"
																autocomplete="off"
																class="form-control almnabrformcontrol"
															/>
															<mat-error
																*ngIf="
																	submitted &&
																	otherallowances &&
																	other_allownces_datas.controls[i].get(
																		'other_allowance_description_ar'
																	).errors?.required
																"
																>{{ "lang_field_required" | language }}
															</mat-error>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</fieldset>
								</div>
							</div>

							<div class="w-100 text-end mt-3">
								<button class="btn mb-2 btn-cancel" matStepperPrevious>
									{{ "lang_previous" | language }}
								</button>
								<button class="btn mb-2 mx-2 btn-apply" matStepperNext>
									{{ "lang_next" | language }}
								</button>
							</div>
							<!--  <app-step-four [t_id]="t_id" 
                          (reviewersOutput)="fillReviewers($event)"
                          [stepper]="stepper" 
                          [reviewers]="reviewers"></app-step-four> -->
						</div>
					</mat-step>
					<mat-step [stepControl]="form" class="mt-3">
						<div [formGroup]="form">
							<ng-template matStepLabel>
								<div class="d-block inactive textColor">
									<div class="custom"></div>
									{{ "lang_terms_conditions" | language }}
								</div>
							</ng-template>

							<div class="row mb-10">
								<!--  <div class="col-md-4 col-sm-4 col-xs-12 mb-10" *ngIf="!markstatus">
                                <button type="button" class="albutton mnabrbutton mat-accent" mat-button (click)="enable_field('mark')"> <i class="fa fa-plus"></i> {{'lang_need_mark' | language}}</button>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10" *ngIf="!reviewstatus">
                                <button type="button" class="albutton mnabrbutton mat-accent" mat-button (click)="enable_field('review')"> <i class="fa fa-plus"></i> {{'lang_need_review' | language}}</button>
                            </div> -->
								<div
									class="col-md-4 col-sm-4 col-xs-12 mb-10"
									*ngIf="!otherterms"
								>
									<button
										type="button"
										class="albutton mnabrbutton mat-accent"
										mat-button
										(click)="enable_field('terms')"
									>
										<i class="fa fa-plus"></i>
										{{ "lang_additional_terms" | language }}
									</button>
								</div>
							</div>

							<div class="row mb-10" *ngIf="otherterms">
								<div class="col-md-12 col-sm-12 col-xs-12 mb-10">
									<fieldset class="cardback fieldset">
										<legend>
											{{ "lang_additional_terms" | language }} ({{
												"lang_FP" | language
											}})
											<span class="red"
												>*
												<mat-icon
													class="red mnabricon"
													*ngIf="!checkForRequired()"
													(click)="disable_field('terms')"
													matTooltip="{{ 'lang_close' | language }}"
													>close</mat-icon
												></span
											>
										</legend>
										<div class="table-responsive customResponsive tablefields">
											<table
												border="1"
												class="table CustomTable"
												width="100%"
												formArrayName="other_terms1"
											>
												<thead>
													<tr>
														<td style="padding: 5px; word-break: break-all">
															<mat-icon
																class="mnabricon"
																matTooltip="{{ 'lang_add' | language }}"
																(click)="add_new_terms1()"
																>add_box</mat-icon
															>
														</td>
														<td style="padding: 5px; word-break: break-all">
															{{ "lang_desc_english" | language }}
														</td>
														<td style="padding: 5px; word-break: break-all">
															{{ "lang_desc_arabic" | language }}
														</td>
													</tr>
												</thead>
												<tbody>
													<tr
														*ngFor="
															let otr of termsgroup1.controls;
															let i = index;
															let odd = odd;
															let even = even
														"
														[ngClass]="{ odd: odd, even: even }"
														[formGroupName]="i"
														style="background: #ffffff"
													>
														<td width="1%" class="verticalalignmid">
															<mat-icon
																class="mnabricon red"
																*ngIf="
																	other_terms_datas1.controls[i].get(
																		'required_status'
																	).value != 'required'
																"
																matTooltip="{{ 'lang_delete' | language }}"
																(click)="
																	remove_terms_items1(i);
																	onRemoveTerms(
																		other_terms_datas1.controls[i]?.get(
																			'setting_contract_terms_id'
																		)?.value
																	)
																"
																>close</mat-icon
															>
														</td>
														<td
															style="padding: 5px; word-break: break-all"
															style="position: relative"
														>
															<!--   <input type="text" formControlName="terms_description_english" placeholder="{{'lang_desc_english' | language}}" autocomplete="off" class="form-control almnabrformcontrol" /> -->
															<input
																type="text"
																class="w-100"
																placeholder="Pick one"
																aria-label="{{
																	'lang_desc_english' | language
																}}"
																matInput
																(keyup)="
																	_filter($event?.target?.value, 'en', 'fp')
																"
																formControlName="terms_description_english"
																[matAutocomplete]="auto"
																[disabled]="
																	other_terms_datas1.controls[i].get(
																		'required_status'
																	).value == 'required'
																"
															/>
															<mat-autocomplete
																autoActiveFirstOption
																#auto="matAutocomplete"
															>
																<mat-option
																	*ngFor="let option of filteredOptionsFP"
																	[value]="option"
																>
																	{{ option }}
																</mat-option>
															</mat-autocomplete>

															<mat-error
																*ngIf="
																	submitted &&
																	otherterms &&
																	other_terms_datas1.controls[i].get(
																		'terms_description_english'
																	).errors?.required
																"
																>{{ "lang_field_required" | language }}
															</mat-error>
														</td>
														<td
															style="padding: 5px; word-break: break-all"
															style="position: relative"
														>
															<!--  <input type="text" formControlName="terms_description_arabic" placeholder="{{'lang_desc_arabic' | language}}" autocomplete="off" class="form-control almnabrformcontrol" /> -->
															<input
																type="text"
																class="w-100"
																placeholder="Pick one"
																aria-label="{{ 'lang_desc_arabic' | language }}"
																matInput
																(keyup)="
																	_filter($event?.target?.value, 'ar', 'fp')
																"
																formControlName="terms_description_arabic"
																[matAutocomplete]="auto2"
																[disabled]="
																	other_terms_datas1.controls[i].get(
																		'required_status'
																	).value == 'required'
																"
															/>
															<mat-autocomplete
																autoActiveFirstOption
																#auto2="matAutocomplete"
															>
																<mat-option
																	*ngFor="let option of filteredOptionsFP_ar"
																	[value]="option"
																>
																	{{ option }}
																</mat-option>
															</mat-autocomplete>

															<mat-error
																*ngIf="
																	submitted &&
																	otherterms &&
																	other_terms_datas1.controls[i].get(
																		'terms_description_arabic'
																	).errors?.required
																"
															>
																{{ "lang_field_required" | language }}
															</mat-error>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</fieldset>
								</div>

								<div class="mt-3 col-md-12 col-sm-12 col-xs-12 mb-10">
									<fieldset class="cardback fieldset">
										<legend>
											{{ "lang_additional_terms" | language }} ({{
												"lang_SP" | language
											}})
											<span class="red"
												>*
												<mat-icon
													class="red mnabricon"
													*ngIf="!checkForRequired()"
													(click)="disable_field('terms')"
													matTooltip="{{ 'lang_close' | language }}"
													>close</mat-icon
												></span
											>
										</legend>
										<div class="table-responsive customResponsive tablefields">
											<table
												border="1"
												class="table CustomTable"
												width="100%"
												formArrayName="other_terms2"
											>
												<thead>
													<tr>
														<td style="padding: 5px; word-break: break-all">
															<mat-icon
																class="mnabricon"
																matTooltip="{{ 'lang_add' | language }}"
																(click)="add_new_terms2()"
																>add_box</mat-icon
															>
														</td>
														<td style="padding: 5px; word-break: break-all">
															{{ "lang_desc_english" | language }}
														</td>
														<td style="padding: 5px; word-break: break-all">
															{{ "lang_desc_arabic" | language }}
														</td>
													</tr>
												</thead>
												<tbody>
													<tr
														*ngFor="
															let otr of termsgroup2.controls;
															let i = index;
															let odd = odd;
															let even = even
														"
														[ngClass]="{ odd: odd, even: even }"
														[formGroupName]="i"
														style="background: #ffffff"
													>
														<td width="1%" class="verticalalignmid">
															<mat-icon
																class="mnabricon red"
																*ngIf="
																	other_terms_datas2.controls[i].get(
																		'required_status'
																	).value != 'required'
																"
																matTooltip="{{ 'lang_delete' | language }}"
																(click)="
																	remove_terms_items2(i);
																	onRemoveTerms(
																		other_terms_datas2?.controls[i]?.get(
																			'setting_contract_terms_id'
																		)?.value
																	)
																"
																>close</mat-icon
															>
														</td>
														<td
															style="padding: 5px; word-break: break-all"
															style="position: relative"
														>
															<!--   <input type="text" formControlName="terms_description_english" placeholder="{{'lang_desc_english' | language}}" autocomplete="off" class="form-control almnabrformcontrol" /> -->
															<input
																type="text"
																class="w-100"
																placeholder="Pick one"
																aria-label="{{
																	'lang_desc_english' | language
																}}"
																matInput
																(keyup)="
																	_filter($event?.target?.value, 'en', 'sp')
																"
																formControlName="terms_description_english"
																[matAutocomplete]="auto"
																[disabled]="
																	other_terms_datas2.controls[i].get(
																		'required_status'
																	).value == 'required'
																"
															/>
															<mat-autocomplete
																autoActiveFirstOption
																#auto="matAutocomplete"
															>
																<mat-option
																	*ngFor="let option of filteredOptionsSP"
																	[value]="option"
																>
																	{{ option }}
																</mat-option>
															</mat-autocomplete>

															<mat-error
																*ngIf="
																	submitted &&
																	otherterms &&
																	other_terms_datas2.controls[i].get(
																		'terms_description_english'
																	).errors?.required
																"
																>{{ "lang_field_required" | language }}
															</mat-error>
														</td>
														<td
															style="padding: 5px; word-break: break-all"
															style="position: relative"
														>
															<!--  <input type="text" formControlName="terms_description_arabic" placeholder="{{'lang_desc_arabic' | language}}" autocomplete="off" class="form-control almnabrformcontrol" /> -->
															<input
																type="text"
																class="w-100"
																placeholder="Pick one"
																aria-label="{{ 'lang_desc_arabic' | language }}"
																matInput
																(keyup)="
																	_filter($event?.target?.value, 'ar', 'sp')
																"
																formControlName="terms_description_arabic"
																[matAutocomplete]="auto2"
																[disabled]="
																	other_terms_datas2.controls[i].get(
																		'required_status'
																	).value == 'required'
																"
															/>
															<mat-autocomplete
																autoActiveFirstOption
																#auto2="matAutocomplete"
															>
																<mat-option
																	*ngFor="let option of filteredOptionsSP_ar"
																	[value]="option"
																>
																	{{ option }}
																</mat-option>
															</mat-autocomplete>

															<mat-error
																*ngIf="
																	submitted &&
																	otherterms &&
																	other_terms_datas2.controls[i].get(
																		'terms_description_arabic'
																	).errors?.required
																"
															>
																{{ "lang_field_required" | language }}
															</mat-error>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</fieldset>
								</div>
							</div>
							<div class="row mb-10">
								<div class="col-12">
									<fieldset class="cardback fieldset">
										<legend>{{ "lang_penalty_clause" | language }}</legend>
										<div class="row">
											<div class="col-md-12 col-sm-12 col-xs-12">
												<h4>
													{{ "lang_the_two_parties_agreed_that" | language }}:
												</h4>
											</div>
											<div class="col-md-6 col-sm-12 col-xs-12">
												<div class="form-group">
													<p>
														{{
															"lang_first_party_to_pay_the_second_party"
																| language
														}}

														<input
															type="number"
															(keypress)="isNumber($event)"
															style="max-width: 150px; display: inline"
															formControlName="penalty_clause_first_party"
															placeholder="......"
															class="form-control almnabrformcontrol"
														/>
														{{ "lang_SAR" | language }}
													</p>
													<!--   <label> {{'lang_first_party' | language}} <span class="red">*</span></label> -->

													<mat-error
														*ngIf="
															submitted &&
															form?.controls['penalty_clause_first_party']
																.errors?.required
														"
													>
														{{ "lang_field_required" | language }}
													</mat-error>
												</div>
											</div>

											<div class="col-md-6 col-sm-12 col-xs-12">
												<div class="form-group">
													<p>
														{{
															"lang_second_party_to_pay_the_first_party"
																| language
														}}

														<input
															type="number"
															(keypress)="isNumber($event)"
															style="max-width: 150px; display: inline"
															formControlName="penalty_clause_second_party"
															placeholder="......"
															class="form-control almnabrformcontrol"
														/>
														{{ "lang_SAR" | language }}
													</p>
													<!--   <label> {{'lang_first_party' | language}} <span class="red">*</span></label> -->

													<mat-error
														*ngIf="
															submitted &&
															form?.controls['penalty_clause_second_party']
																.errors?.required
														"
													>
														{{ "lang_field_required" | language }}
													</mat-error>
												</div>
											</div>
										</div>
									</fieldset>
								</div>
							</div>
							<div class="row mb-10">
								<div class="col-12">
									<fieldset class="cardback fieldset">
										<legend>{{ "lang_signature_stamp" | language }}</legend>
										<div class="row">
											<div class="col-md-6 col-sm-12 col-xs-12">
												<div class="form-group">
													<label>
														{{ "lang_first_party" | language }}
														<span class="red">*</span></label
													>
													<input
														type="text"
														formControlName="signature_a_title"
														placeholder="{{ 'lang_first_party' | language }}"
														class="form-control almnabrformcontrol"
													/>
													<mat-error
														*ngIf="
															submitted &&
															form.controls['signature_a_title'].errors
																?.required
														"
													>
														{{ "lang_field_required" | language }}
													</mat-error>
												</div>
											</div>
											<div class="col-md-6 col-sm-12 col-xs-12">
												<div class="form-group">
													<label>
														{{
															"lang_human_resources_job_title_iqama" | language
														}}
														B
													</label>
													<input
														type="text"
														formControlName="signature_b_title"
														placeholder="{{
															'lang_human_resources_job_title_iqama' | language
														}} B"
														class="form-control almnabrformcontrol"
													/>
													<mat-error
														*ngIf="
															submitted &&
															form.controls['signature_b_title'].errors
																?.required
														"
														>{{ "lang_field_required" | language }}</mat-error
													>
												</div>
											</div>
											<!--   <div class="col-md-4 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label> {{'lang_second_party' | language}} <span class="red">*</span></label>
                                                <input type="text" formControlName="signature_c_title" placeholder="{{'lang_second_party' | language}}" class="form-control almnabrformcontrol">
                                                <mat-error *ngIf="submitted && form.controls['signature_c_title'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                            </div>
                                        </div> -->
										</div>
										<div class="row">
											<div class="col-md-6 col-sm-12 col-xs-12">
												<div class="form-group">
													<label>
														{{
															"lang_human_resources_employee_name" | language
														}}
														A
													</label>
													<ng-select
														[items]="users"
														[multiple]="false"
														bindLabel="label"
														bindValue="value"
														clearAllText="Clear"
														[searchFn]="ds.customSearchFn"
														appendTo="body"
														formControlName="signature_a_user_id"
														placeholder="{{
															'lang_human_resources_employee_name' | language
														}}"
														(search)="search_users($event)"
														(focus)="users = []"
													>
													</ng-select>
													<mat-error
														*ngIf="
															submitted &&
															form.controls['signature_a_user_id'].errors
																?.required
														"
														>{{ "lang_field_required" | language }}</mat-error
													>
												</div>
											</div>
											<div class="col-md-6 col-sm-12 col-xs-12">
												<div class="form-group">
													<label>
														{{
															"lang_human_resources_employee_name" | language
														}}
														B
													</label>
													<ng-select
														[items]="users"
														[multiple]="false"
														bindLabel="label"
														bindValue="value"
														clearAllText="Clear"
														[searchFn]="ds.customSearchFn"
														appendTo="body"
														formControlName="signature_b_user_id"
														placeholder="{{
															'lang_human_resources_employee_name' | language
														}}"
														(search)="search_users($event)"
														(focus)="users = []"
													>
													</ng-select>
													<mat-error
														*ngIf="
															submitted &&
															form.controls['signature_b_user_id'].errors
																?.required
														"
														>{{ "lang_field_required" | language }}</mat-error
													>
												</div>
											</div>
											<!--     <div class="col-md-4 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label> {{'lang_human_resources_employee_name' | language}} C </label>
                                                <ng-select 
                                                    [items]="users" 
                                                    [multiple]="false" 
                                                    bindLabel="label" 
                                                    bindValue="value" 
                                                    clearAllText="Clear"
                                                    [searchFn]="ds.customSearchFn"
                                                    appendTo="body" 
                                                    formControlName="signature_c_user_id"
                                                    placeholder="{{'lang_human_resources_employee_name' | language}}"
                                                    (search)="search_users($event)"
                                                    (focus)="users = []">
                                                </ng-select>
                                                <mat-error *ngIf="submitted && form.controls['signature_c_user_id'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                            </div>
                                        </div> -->
										</div>
									</fieldset>
								</div>
							</div>
							<!--      <div class="row mb-10" *ngIf="markstatus || reviewstatus">
                            <div class="col-md-6 col-xs-12 col-sm-4 mb-10">
                                <fieldset class="cardback fieldset" *ngIf="markstatus">
                                    <input type="hidden" formControlName="needMark">
                                    <legend>{{'lang_markers' | language}} 
                                        <span class="red"> * 
                                            <mat-icon class="red mnabricon red" matTooltip="{{'lang_close' | language }}" (click)="disable_field('mark')">close</mat-icon>
                                        </span>
                                    </legend>
                                    <ng-select 
                                        [items]="users" 
                                        [multiple]="true" 
                                        bindLabel="label" 
                                        bindValue="value" 
                                        clearAllText="Clear"
                                        [searchFn]="ds.customSearchFn"
                                        appendTo="body" 
                                        formControlName="marks"
                                        placeholder="{{'lang_markers' | language}}"
                                        (search)="search_users($event)"
                                        (focus)="users = []">
                                    </ng-select>
                                    <mat-error *ngIf="submitted && form.controls['marks'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                </fieldset>
                            </div>
                            <div class="col-md-6 col-xs-12 col-sm-4 mb-10">
                                <fieldset class="cardback fieldset" *ngIf="reviewstatus">
                                    <input type="hidden" formControlName="needReview">
                                    <legend>{{'lang_reviewers' | language}} 
                                        <span class="red">* 
                                            <mat-icon class="red mnabricon red" matTooltip="{{'lang_close' | language }}" (click)="disable_field('review')">close</mat-icon>
                                        </span>
                                    </legend>
                                    <ng-select 
                                        [items]="users" 
                                        [multiple]="true" 
                                        bindLabel="label" 
                                        bindValue="value" 
                                        clearAllText="Clear"
                                        [searchFn]="ds.customSearchFn"
                                        appendTo="body" 
                                        formControlName="reviews"
                                        placeholder="{{'lang_reviewers' | language}}"
                                        (search)="search_users($event)"
                                        (focus)="users = []">
                                    </ng-select>
                                    <mat-error *ngIf="submitted && form.controls['reviews'].errors?.required"> {{'lang_field_required' | language}}</mat-error>
                                </fieldset>
                            </div>
                        </div>  -->
							<div class="w-100 text-end mt-3">
								<button class="btn mb-2 btn-cancel" matStepperPrevious>
									{{ "lang_previous" | language }}
								</button>
								<button
									class="btn mb-2 mx-2 btn-apply"
									(click)="submit(stepper)"
								>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</mat-step>

					<!-- <mat-step>
                      
                          <ng-template matStepLabel>
                            <div class="d-block inactive textColor" >
                              <div class="custom">
                
                              </div>
                              {{'lang_add_to_review'|language}}
                            </div>
                          </ng-template>
                
                     
                          <app-step-five [t_id]="t_id" [stepper]="stepper"  [refresh]="refreshSubject.asObservable()"></app-step-five>
                      </mat-step> -->
					<mat-step class="mt-3">
						<form>
							<ng-template matStepLabel>
								<div class="d-block inactive textColor">
									<div class="custom"></div>
									{{ "lang_completed" | language }}
								</div>
							</ng-template>

							<app-completed-step></app-completed-step>
						</form>
					</mat-step>
					<!--  <mat-step>
                        <ng-template matStepLabel>Done</ng-template>
                        <p>You are now done.</p>
                        <div>
                          <button mat-button matStepperPrevious>Back</button>
                          <button mat-button (click)="stepper.reset()">Reset</button>
                        </div>
                      </mat-step> -->
				</mat-horizontal-stepper>

				<!--  <div class="row">
                     
                      
                    </div>
                    <div class="row">
                        
                   
                    </div>
                    -->
			</div>
			<div class="card-footer">
				<div class="row justify-content-between">
					<!--   <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" routerLink="/human_resources/employees" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_human_resources_all_emp' | language}}</button>
                        </div> -->
					<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
						<button
							type="button"
							[routerLink]="['/human_resources/applications']"
							class="albutton mnabrbutton mat-accent"
							mat-button
						>
							{{ "lang_previous" | language }}
						</button>
					</div>
					<div class="col-md-5 col-sm-2 col-xs-12 mb-10">
						<app-alert id="default-alert"></app-alert>
					</div>
					<div class="col-md-3 col-sm-4 col-xs-12 mb-10">
						<button
							type="button"
							[routerLink]="[
								'/human_resources/applicationview/' + id + '/view'
							]"
							class="albutton mnabrbutton mat-accent"
							mat-button
						>
							{{ "lang_view" | language }}
							{{ "lang_human_resources_jobapp" | language }}
						</button>
					</div>
					<!--  <div class="col-md-1 col-sm-2 col-xs-12 mb-10">
                            <input type="submit" value="{{'lang_save' | language}}" class="albutton mnabrbutton mat-accent" mat-button>
                        </div> -->
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</section>
