<breadcrumb></breadcrumb>
<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 mb-3">
        <div class="card-item">
          <div class="item-icon">
            <i class="fa fa-tasks"></i>
          </div>
          <div class="item-text">
            <h5>{{ "lang_incomplete_tasks" | language }}</h5>
            <h6 class="d-flex align-items-center">
              {{ employee_tasks?.incomplete_tasks }}
            </h6>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 mb-3">
        <div
          class="card-item"
          style="background: linear-gradient(60deg, #2644da, #006dc1)"
        >
          <div class="item-icon">
            <i class="fa fa-exchange"></i>
          </div>
          <div class="item-text">
            <h5>{{ "lang_pending_transaction" | language }}</h5>
            <h6 class="d-flex align-items-center">
              {{ employee_tasks?.total_pending_transaction }}
            </h6>
          </div>
        </div>
      </div>
      <div
        *ngIf="empInfo?.employee_name"
        class="col-12 col-sm-12 col-md-4 mt-2 mt-md-0 d-flex align-items-center justify-content-end"
      >
        <i
          class="fa fa-ellipsis-v mnabricon"
          [matMenuTriggerFor]="dashboardActions"
        ></i>
        <mat-menu #dashboardActions="matMenu">
          <button mat-menu-item (click)="onCreateJoining()">
            <div class="d-flex align-items-center justify-content-between">
              <span>{{ "lang_new_joining" | language }} </span>
              <i class="fa fa-plus mnabricon ml-2 mr-2"></i>
            </div>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="empInfo?.employee_name || user_type_id == '4'">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true" *ngIf="empInfo?.employee_name">
        <mat-expansion-panel-header>
          {{ "lang_employee_info" | language }}
        </mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15">
            <div class="row">
              <div class="col-12 col-sm-9 order-2 order-sm-1">
                <div class="row">
                  <div class="col-12 col-sm-3 mb-3">
                    <div class="content-info">
                      <label for="" class="content-info__label">{{
                        "lang_human_resources_employee_name" | language
                      }}</label>
                      <span>{{ empInfo?.employee_name }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3 mb-3">
                    <div class="content-info">
                      <label for="" class="content-info__label">{{
                        "lang_status" | language
                      }}</label>
                      <span>{{
                        empInfo?.employee_status == "1" ? "Active" : "Deactive"
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3 mb-3">
                    <div class="content-info">
                      <label for="" class="content-info__label">{{
                        "lang_contract_actual_end_date" | language
                      }}</label>
                      <span>{{
                        empInfo?.contract_actual_end_date || "---"
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3 mb-3">
                    <div class="content-info">
                      <label for="" class="content-info__label">{{
                        "lang_human_resources_joining_date_english" | language
                      }}</label>
                      <span>{{ empInfo?.first_joining_date }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3 mb-3">
                    <div class="content-info">
                      <label for="" class="content-info__label">{{
                        "lang_human_resources_passport_expiry_date_english"
                          | language
                      }}</label>
                      <span>{{ empInfo?.passport_expiry_date_english }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3 mb-3">
                    <div class="content-info">
                      <label for="" class="content-info__label">{{
                        "lang_iqama_expiry_date" | language
                      }}</label>
                      <span>{{ empInfo?.iqama_expiry_date_english }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3 mb-3">
                    <div class="content-info">
                      <label for="" class="content-info__label">{{
                        "lang_human_resources_job_title_iqama" | language
                      }}</label>
                      <span>{{ empInfo?.job_title_iqama }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3 mb-3">
                    <div class="content-info">
                      <label for="" class="content-info__label">{{
                        "lang_human_resources_nationality" | language
                      }}</label>
                      <img
                        [src]="getIcon(empInfo?.nationality)"
                        alt="{{ empInfo?.nationality }}"
                        title="{{ empInfo?.nationality }}"
                        style="width: 25px"
                        *ngIf="empInfo?.nationality"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-sm-3 order-1 order-sm-2"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div class="content-img">
                  <img
                    src="{{ avatar || '../../../assets/images/avatar.png' }}"
                    alt=""
                  />
                  <span
                    ><span style="font-weight: bold">{{
                      "lang_human_resources_number" | language
                    }}</span>
                    : {{ empInfo?.employee_number }}</span
                  >
                  <span
                    class="userStatusIcon employee-status"
                    [ngClass]="{
                      greenbg: employee_status == '1',
                      yellowbgcolor: employee_status == '0'
                    }"
                  >
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <hr style="width: 80%; margin: 15px auto" />
              <div class="col-12 col-sm-3 mb-3">
                <div class="content-info">
                  <label class="content-info__label">{{
                    "lang_vacation_paid_days" | language
                  }}</label>
                  <span>{{ employee_extra_data?.vacation_paid_days }}</span>
                </div>
              </div>
              <div class="col-12 col-sm-3 mb-3">
                <div class="content-info">
                  <label class="content-info__label">{{
                    "lang_vacation_deserved_paid_days" | language
                  }}</label>
                  <span>{{
                    employee_extra_data?.vacation_deserved_paid_days
                  }}</span>
                </div>
              </div>
              <div class="col-12 col-sm-3 mb-3">
                <div class="content-info">
                  <label class="content-info__label">{{
                    "lang_total_detection_days" | language
                  }}</label>
                  <span>{{ employee_extra_data?.total_detection_days }}</span>
                </div>
              </div>
              <div class="col-12 col-sm-3 mb-3">
                <div class="content-info">
                  <label class="content-info__label">{{
                    "lang_paid_days" | language
                  }}</label>
                  <span>{{ employee_extra_data?.total_paid_days }}</span>
                </div>
              </div>
              <div class="col-12 col-sm-3 mb-3">
                <div class="content-info">
                  <label class="content-info__label">{{
                    "lang_unpaid_days" | language
                  }}</label>
                  <span>{{ employee_extra_data?.total_unpaid_days }}</span>
                </div>
              </div>
              <div class="col-12 col-sm-3 mb-3">
                <div class="content-info">
                  <label class="content-info__label">{{
                    "lang_employee_all_contract_worked_days" | language
                  }}</label>
                  <span>{{ employee_extra_data?.total_working_days }}</span>
                </div>
              </div>
              <div class="col-12 col-sm-3 mb-3">
                <div class="content-info">
                  <label class="content-info__label">{{
                    "lang_employee_active_contract_total_days" | language
                  }}</label>
                  <span>{{
                    employee_extra_data?.employee_active_contract_total_days
                  }}</span>
                </div>
              </div>
              <div class="col-12 col-sm-3 mb-3">
                <div class="content-info">
                  <label class="content-info__label">{{
                    "lang_remaining_days" | language
                  }}</label>
                  <span>{{ employee_extra_data?.total_remaining_days }}</span>
                </div>
              </div>
              <div class="col-12 col-sm-3 mb-3">
                <div class="content-info">
                  <label class="content-info__label">{{
                    "lang_working_days" | language
                  }}</label>
                  <span>{{ employee_extra_data?.working_days }}</span>
                </div>
              </div>
              <div class="col-12 col-sm-3 mb-3">
                <div class="content-info">
                  <label class="content-info__label">{{
                    "lang_membership_expiry_date_english" | language
                  }}</label>
                  <span>{{
                    empInfo?.membership_expiry_date_english || "---"
                  }}</span>
                </div>
              </div>
            </div>
            <hr style="width: 80%; margin: 15px auto" />
            <div class="row" *ngIf="empInfo?.employee_name">
              <div class="col-12">
                <app-calender
                  [singleUser]="true"
                  [isShowTotalHours]="true"
                  [userInfo]="empInfo"
                ></app-calender>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="user_type_id == '4' ? true : false">
        <mat-expansion-panel-header>
          {{ "lang_workarea_transactions" | language }}
        </mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15">
            <app-user-transactions></app-user-transactions>
            <hr style="width: 80%; margin: 15px auto" />
            <app-project-supervision-dashboard></app-project-supervision-dashboard>
          </div>
        </div>
      </mat-expansion-panel>
      <!-- <mat-expansion-panel>
				<mat-expansion-panel-header>
					{{ "lang_org_chart" | language }}
				</mat-expansion-panel-header>
				<div class="material-panel-body">
					<div class="mbrl15">
						<app-nahidh-org-chart></app-nahidh-org-chart>
					</div>
				</div>
			</mat-expansion-panel> -->
    </mat-accordion>
  </div>
</div>
<app-table-data
  [products]="products"
  [cols]="cols"
  *ngIf="false"
></app-table-data>
