import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cost-center-list',
  templateUrl: './cost-center-list.component.html',
  styleUrls: ['./cost-center-list.component.scss']
})
export class CostCenterListComponent implements OnInit ,OnDestroy {
  options = {useCheckbox: false};
  nodes: any = [];
  branches: any = [];
  branch_id = localStorage.getItem('selectedBranch');
  form_show_status = "ADD";
  add: boolean = false;
  edit: boolean = false;
  delete = false;
  node_status: boolean = false;
  viewstatus: boolean = false;
  viewdata: any = [];
  costcenterform: FormGroup;
  constructor(public ds:DataService,public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.build_form();
    this.load_branches('accounts_view');
    if (this.branch_id !== null && this.branch_id != '0' ) {
      this.load_tree();
    }
  }
  public build_form() {
    this.costcenterform = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      cost_center_root: new FormControl(1, [Validators.required]),
      cost_center_id: new FormControl(null),
      cost_center_code: new FormControl(null),
      cost_center_name_en: new FormControl(null, [Validators.required]),
      cost_center_name_ar: new FormControl(null, [Validators.required]),
      cost_center_sub: new FormControl(0, [Validators.required]),
    });
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public selectBrach(branch) {
    if (branch && branch != '0' ) {
      localStorage.setItem('selectedBranch', branch);
      this.branch_id = branch;
      this.costcenterform.get('branch_id').setValue(branch);
      this.load_tree();
    }
  }
  public load_branches(permit) {
    this.subscriptions.add(
            this.ds.getActionByUrl([], 'ab/' + permit).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          } else {
            this.branches = [];
          }
        }, error => {
          this.spinner.hide();
          this.branches = [];
        })
      )
  
  }
  public load_tree() {
    this.spinner.show();
    this.subscriptions.add(
              this.ds.getActionByUrl([], 'cclist/' + this.branch_id).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.node_status = true;
            this.nodes = res.records;
          } else {
            this.node_status = false;
            this.nodes = [];
          }
        }, error => {
          this.spinner.hide();
          this.node_status = false;
          this.nodes = [];
        })
      )

  }
  public clear(tree) {
    tree.collapseAll();
    this.form_show_status = "ADD";
    this.costcenterform.reset();
    this.costcenterform.get('cost_center_root').setValue('1');
    this.costcenterform.get('branch_id').setValue(this.branch_id);
    if (tree) {
      tree.clearFilter();
      this.viewstatus = false;
      if (tree.getFocusedNode()?.isActive) {
        tree.getFocusedNode().toggleActivated();
      }
    }
  }
  public toggleAction(tree: any) {
    this.add = this.edit = this.delete = this.viewstatus = false;
    this.form_show_status = "ADD";
    this.costcenterform.reset();
    this.costcenterform.get('cost_center_root').setValue('1');
    this.costcenterform.get('cost_center_id').setValue(null);
    this.costcenterform.get('cost_center_code').setValue(null);
    if (tree.activeNodes.length > 0) {
      this.viewdata = tree.activeNodes[0].data;
      this.form_show_status = "VIEW";
      this.viewstatus = true;
      this.costcenterform.get('cost_center_root').setValue(0);
      this.costcenterform.get('cost_center_id').setValue(this.viewdata.cost_center_id);
      this.costcenterform.get('cost_center_code').setValue(this.viewdata.cost_center_code);
      if (tree.activeNodes[0].data.cost_center_sub == "1" && this.ds.allowMe("accounts", "accounts_add")) {// need Add permission and sub account support 1
        this.add = true;
      }
      if (this.ds.allowMe("accounts", "accounts_edit")) { //need edit permission
        this.edit = true;
      }
      if (tree.activeNodes[0].data.cost_center_sub == "0" && this.ds.allowMe("accounts", "accounts_delete")) { // need permission and sub account 0
        this.delete = true;
      }
    }
  }
  public treeAction(tree: any, action: any) {
    if (tree.activeNodes.length > 0) {
      let data = tree.activeNodes[0].data;
      if (action == 'view') {
        this.viewdata = data;
        this.form_show_status = "VIEW";
      } else if (action == 'add') {
        this.costcenterform.reset();
        this.costcenterform.get('cost_center_root').setValue(0);
        this.costcenterform.get('branch_id').setValue(this.branch_id);
        this.costcenterform.get('cost_center_id').setValue(data.cost_center_id);
        this.costcenterform.get('cost_center_code').setValue(data.cost_center_code);
        this.form_show_status = "ADD";
      } else if (action == 'edit') {
        this.costcenterform.patchValue(data);
        this.costcenterform.get('cost_center_sub').setValue(JSON.parse(data.cost_center_sub));
        this.costcenterform.get('cost_center_code').setValue(data.cost_center_code);
        this.form_show_status = "EDIT";
      } else if (action == 'delete') {
        const dialogRef = this.dialog.open(ConfirmationDialog,{
          disableClose: true,
          width:'400px',
          data:{
            title: this.lang.transform('lang_confirmation'),
            message: this.lang.transform('lang_are_you_sure'),          
          }
        });

        this.subscriptions.add(
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                this.spinner.show();
                this.subscriptions.add(
                  this.ds.deleteActionByUrl([], 'ccdelete/' + this.branch_id + '/' + data.cost_center_id).subscribe(data => {
                    this.spinner.hide();
                    if (data.status) {
                      this.nodes = data.records;
                      setTimeout(() => {
                        let element: HTMLElement = document.getElementById('btnSearch') as HTMLElement;
                        element.click();
                      }, 3000);
                    } else {
                      this.ds.dialogf('',data.error);
                    }
                  },
                    (error) => {
                      this.spinner.hide();
                      this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                    }
                  )
                  )
                ;
              }
            })
          )
       ;
      }
    } else {
      this.ds.dialogf('',this.lang.transform('lang_select_anyone'));
    }
  }
  //create cost center
  public create_cost_center_operation(tree) {
    let data = this.ds.json2formdata(this.costcenterform.value);
    data.set('branch_id', this.branch_id);
    this.spinner.show();
    this.ds.postActionByUrl(data, 'cccreate').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.nodes = res.records;
        let element: HTMLElement = document.getElementById('btnSearch') as HTMLElement;
        element.click();
        this.alert.success(res.msg);
        //tree.getNodeById(res.active_code).setActiveAndVisible();
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      this.spinner.hide();
    })
  }
  //update cost center
  public update_cost_center_operation(tree) {
    let data = this.ds.json2formdata(this.costcenterform.value);
    data.set('branch_id', this.branch_id);
    this.spinner.show();
    this.subscriptions.add(
            this.ds.putActionByUrl(this.ds.formData2string(data), 'ccupdate/' + this.costcenterform.get('cost_center_id').value).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.nodes = res.records;
          let element: HTMLElement = document.getElementById('btnSearch') as HTMLElement;
          element.click();
          this.alert.success(res.msg);
          //tree.getNodeById(res.active_code).setActiveAndVisible();
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
        this.spinner.hide();
      })
      )

  }
}
