<breadcrumb></breadcrumb>

<form class="form" [formGroup]="form">
    <section class="panel-expansion ckeditorhidetoolbar">
      <mat-accordion class="stylepanels">
        <mat-expansion-panel class="mpanel" [expanded]="true">
          <mat-expansion-panel-header class="mpanel-header">
            <span *ngIf="group_id">
              {{"lang_update" 
              | language
            }}
            </span> 
            <span *ngIf="!group_id">
              {{"lang_add" 
              | language
            }}
            </span> &nbsp; {{ 
       
              "lang_group" 
              | language
            }}
          </mat-expansion-panel-header>
          <div class="mbrl15" [ngClass]="{ ' contentrtl': lang_key == 'ar' }">
          
            <div class="container p-0 m-0" style="max-width: 99%;">
                <div class="row">
                  
               

                  <div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                    <mat-form-field appearance="outline" style="width: 100%;">
                      <mat-label>{{ "lang_title_english" | language }}</mat-label>
                      <input matInput formControlName="group_title_english" required />
                      <mat-error *ngIf="
                                                      form.controls['group_title_english'].touched &&
                                                      form.controls['group_title_english'].errors
                                                          ?.required
                                                  ">
                        {{ "lang_field_required" | language }}
                      </mat-error>
                    </mat-form-field>
                  </div>

              
                  <div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                    <mat-form-field appearance="outline" style="width: 100%;">
                      <mat-label>{{ "lang_title_arabic" | language }}</mat-label>
                      <input matInput formControlName="group_title_arabic" required />
                      <mat-error *ngIf="
                                                      form.controls['group_title_arabic'].touched &&
                                                      form.controls['group_title_arabic'].errors
                                                          ?.required
                                                  ">
                        {{ "lang_field_required" | language }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-sm-12 mb-10">
                    <label class="mb-10">
                        {{ "lang_users" | language }}
                        <span class="red"> * </span> </label>
    
                        <ng-select
                        [items]="users"
                        [multiple]="true"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                         
                        appendTo="body"
                        formControlName="group_users"
                        placeholder="{{ 'lang_user' | language }}"
                        (search)="search_users($event)"
                        
                        [searchFn]="ds.customSearchFn"
                         
                    >
                    </ng-select>
                    <mat-error *ngIf="
                    form.controls['group_users'].touched &&
                    form.controls['group_users'].errors
                        ?.required
                      ">
                    {{ "lang_field_required" | language }}
                    </mat-error>
                 
                </div>
                
                <div class="col-12 col-sm-12 mb-10 mt-2">
              <!--     <label class="mb-10">
                    {{ "lang_" | language }}
                     </label> -->
                 <!--  <p class="d-block">Slide Toggle inside of a Reactive form</p> -->
                  <mat-slide-toggle formControlName="un_restricted_group">{{'lang_manager'|language}}</mat-slide-toggle>
                </div>
               

 
              </div>
              <div class="row">
                <div class="col-12 mb-10">
                    <app-alert id="default-alert"></app-alert>
                </div>
            </div>

          </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                <button type="button" routerLink="/human_resources/attendances" class="albutton mnabrbutton mat-accent"
                  mat-button>
                  {{ "lang_group" | language }}
                </button>
              </div>
              <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
               <button type="submit" (click)="submitForm()" class="albutton mnabrbutton mat-accent" mat-button>
                  {{ "lang_submit" | language }}
                </button> 
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
  
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </section>
  </form>