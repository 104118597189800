<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno" *ngIf="data_status">
	<div class="card-footers padb0">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/customers/lists"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_goto_list" | language }}
				</button>
			</div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					*ngIf="able2add"
					type="button"
					routerLink="/customers/create"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_add" | language }}
				</button>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					*ngIf="able2edit"
					type="button"
					routerLink="/customers/edit/{{ id }}"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_edit" | language }}
				</button>
			</div>
		</div>
	</div>
	<mat-accordion class="stylepanels">
		<mat-expansion-panel class="mpanel customer_details" [expanded]="true">
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_customer_details" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-xs-12 col-12 pad0">
							<mat-horizontal-stepper #stepper class="pad0">
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-user fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_customer_details" | language }}</div>
									</ng-template>
									<div class="card">
										<i class="together-icon">
											<i
												*ngIf="customer_data.customer_logo"
												class="fa fa-paperclip mnabricon s2icon"
												(click)="
													ds.preview_pdf_file(customer_data.customer_logo)
												"
												matTooltip="{{ 'lang_logo' | language }}"
											></i>
										</i>
										<div class="card-header">
											{{ "lang_customer_details" | language }}
										</div>
										<div class="card-body p-0">
											<div class="mbrl15 martb0">
												<div class="row">
													<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label>{{ "lang_branch" | language }} :</label>
															<div class="bold">
																<b>{{ customer_data.branch_name }}</b>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{
																	"lang_human_resources_nameen" | language
																}}
																:</label
															>
															<div class="bold">
																<b>{{ customer_data.customer_title_en }}</b>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{
																	"lang_human_resources_namear" | language
																}}
																:</label
															>
															<div class="bold">
																<b>{{ customer_data.customer_title_ar }}</b>
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{ "lang_email_address" | language }} :</label
															>
															<div class="bold">
																<b>{{
																	customer_data.customer_email_address
																}}</b>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{ "lang_mobile_number" | language }} :</label
															>
															<div class="bold">
																<b>{{ customer_data.customer_mobile }}</b>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label>{{ "lang_vat_tax" | language }} :</label>
															<div class="bold">
																<b>{{ customer_data.customer_tax_vat }}</b>
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label>{{ "lang_ccr_no" | language }} : </label>
															<div
																class="bold"
																*ngIf="customer_data.customer_ccr_no"
															>
																<b>{{ customer_data.customer_ccr_no }}</b>
															</div>
															<div
																class="bold"
																*ngIf="!customer_data.customer_ccr_no"
															>
																<b>----</b>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label>{{ "lang_manager" | language }} :</label>
															<div
																class="bold"
																*ngIf="customer_data.customer_managers"
															>
																<b
																	*ngFor="
																		let item of customer_data.customer_managers
																	"
																	class="mb-10"
																	style="display: block"
																>
																	<label *ngIf="item.label">{{
																		item.label
																	}}</label>
																</b>
															</div>
															<div
																class="bold"
																*ngIf="!customer_data.customer_managers"
															>
																<b>----</b>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label>{{ "lang_users" | language }} :</label>
															<div
																class="bold"
																*ngIf="customer_data.customer_teams"
															>
																<b
																	*ngFor="
																		let item of customer_data.customer_teams
																	"
																	class="mb-10"
																	style="display: block"
																	><label *ngIf="item.label">{{
																		item.label
																	}}</label></b
																>
															</div>
															<div
																class="bold"
																*ngIf="!customer_data.customer_teams"
															>
																<b>----</b>
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label>{{ "lang_status" | language }} :</label>
															<div class="bold">
																<b
																	*ngIf="customer_data.customer_status == '1'"
																	>{{ "lang_active" | language }}</b
																>
																<b
																	*ngIf="customer_data.customer_status == '0'"
																	>{{ "lang_inactive" | language }}</b
																>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{
																	"lang_link_with_accounts" | language
																}}
																:</label
															>
															<div class="bold">
																<b
																	*ngIf="
																		customer_data.customer_link_with_accounts ==
																		'1'
																	"
																	>{{ "lang_yes" | language }}</b
																>
																<b
																	*ngIf="
																		customer_data.customer_link_with_accounts ==
																		'0'
																	"
																	>{{ "lang_no" | language }}</b
																>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</mat-step>
								<mat-step *ngIf="user_type_id == '1'">
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-money fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_account_info" | language }}</div>
									</ng-template>
									<div class="card">
										<div class="card-header">
											{{ "lang_account_info" | language }}
										</div>
										<div class="card-body p-0">
											<div class="mbrl15 martb0">
												<div class="row">
													<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{ "lang_address_text" | language }} :</label
															>
															<div class="bold">
																<b *ngIf="customer_data.customer_address">{{
																	customer_data.customer_address
																}}</b>
																<b *ngIf="!customer_data.customer_address"
																	>----</b
																>
															</div>
														</div>
													</div>
													<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{ "lang_credit_days" | language }} :
															</label>
															<div class="bold">
																<b *ngIf="customer_data.customer_credit_days">{{
																	customer_data.customer_credit_days
																}}</b>
																<b *ngIf="!customer_data.customer_credit_days"
																	>----</b
																>
															</div>
														</div>
													</div>
													<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{ "lang_security_amount" | language }} :
															</label>
															<div class="bold">
																<b
																	*ngIf="customer_data.customer_security_amount"
																	>{{
																		customer_data.customer_security_amount
																	}}</b
																>
																<b
																	*ngIf="
																		!customer_data.customer_security_amount
																	"
																	>----</b
																>
															</div>
														</div>
													</div>
													<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{ "lang_credit_limit" | language }} :
															</label>
															<div class="bold">
																<b
																	*ngIf="customer_data.customer_credit_limit"
																	>{{ customer_data.customer_credit_limit }}</b
																>
																<b *ngIf="!customer_data.customer_credit_limit"
																	>----</b
																>
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{ "lang_discount_limit" | language }} :
															</label>
															<div class="bold">
																<b
																	*ngIf="customer_data.customer_discount_limit"
																	>{{
																		customer_data.customer_discount_limit
																	}}</b
																>
																<b
																	*ngIf="!customer_data.customer_discount_limit"
																	>----</b
																>
															</div>
														</div>
													</div>
													<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{ "lang_discount_on_payment" | language }} :
															</label>
															<div class="bold">
																<b
																	*ngIf="customer_data.customer_discount_onpay"
																	>{{
																		customer_data.customer_discount_onpay
																	}}</b
																>
																<b
																	*ngIf="!customer_data.customer_discount_onpay"
																	>----</b
																>
															</div>
														</div>
													</div>
													<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label>{{ "lang_salesman" | language }} : </label>
															<div class="bold">
																<b
																	*ngIf="customer_data.customer_salesmancode"
																	>{{ customer_data.customer_salesmancode }}</b
																>
																<b *ngIf="!customer_data.customer_salesmancode"
																	>----</b
																>
															</div>
														</div>
													</div>
													<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{ "lang_salesman_area" | language }} :
															</label>
															<div class="bold">
																<b
																	*ngIf="customer_data.customer_salesmanarea"
																	>{{ customer_data.customer_salesmanarea }}</b
																>
																<b *ngIf="!customer_data.customer_salesmanarea"
																	>----</b
																>
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label
																>{{ "lang_stop_transaction" | language }} :
															</label>
															<div class="bold">
																<b
																	*ngIf="
																		customer_data.customer_stop_transaction ==
																		'1'
																	"
																	>{{ "lang_yes" | language }}</b
																>
																<b
																	*ngIf="
																		customer_data.customer_stop_transaction ==
																			'0' ||
																		!customer_data.customer_stop_transaction
																	"
																	>{{ "lang_no" | language }}</b
																>
															</div>
														</div>
													</div>
													<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
														<div class="form-group">
															<label>{{ "lang_account" | language }} : </label>
															<div class="bold">
																<b
																	*ngIf="
																		customer_data.customer_account_id &&
																		customer_data.customer_link_with_accounts ==
																			'1'
																	"
																	>{{
																		customer_data.customer_account_id[0].label
																	}}</b
																>
																<b *ngIf="!customer_data.customer_account_id"
																	>-----</b
																>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-users fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_users" | language }}</div>
									</ng-template>
									<app-cs-users
										[customer_id]="id"
										[type]="type"
										[able2add]="able2add"
										[action_access]="action_access"
										[able2edit]="able2edit"
										[able2delete]="able2delete"
										[user_position]="user_position"
										[customer_data]="customer_data"
									></app-cs-users>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-link fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_communications" | language }}</div>
									</ng-template>
									<app-cs-communication
										[customer_id]="id"
										[type]="type"
										[able2add]="able2add"
										[able2edit]="able2edit"
										[able2delete]="able2delete"
										[comm_access]="comm_access"
										[customer_data]="customer_data"
									></app-cs-communication>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-list fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_notes" | language }}</div>
									</ng-template>
									<app-cs-notes
										[customer_id]="id"
										[type]="type"
										[able2add]="able2add"
										[action_access]="action_access"
										[able2edit]="able2edit"
										[able2delete]="able2delete"
										[customer_data]="customer_data"
									></app-cs-notes>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-files-o fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_attachments" | language }}</div>
									</ng-template>
									<app-cs-attachments
										[customer_id]="id"
										[type]="type"
										[able2add]="able2add"
										[action_access]="action_access"
										[able2edit]="able2edit"
										[able2delete]="able2delete"
										[customer_data]="customer_data"
									></app-cs-attachments>
								</mat-step>
								<mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-building fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_projects" | language }}</div>
									</ng-template>
									<app-projects
										[customer_id]="id"
										[type]="type"
										[able2add]="able2add"
										[able2edit]="able2edit"
										[able2delete]="able2delete"
										[customer_data]="customer_data"
									></app-projects>
								</mat-step>
								<!-- <mat-step>
									<ng-template matStepLabel>
										<div class="step">
											<div class="st">
												<i class="icon-5x fa fa-building fa-icon"></i>
											</div>
										</div>
										<div>{{ "lang_quotation_requests" | language }}</div>
									</ng-template>
									<app-quotation-requests
										[customer_id]="id"
										[type]="type"
										[able2add]="able2add"
										[able2edit]="able2edit"
										[able2delete]="able2delete"
										[customer_data]="customer_data"
									></app-quotation-requests>
								</mat-step> -->
							</mat-horizontal-stepper>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="card-footers padb0">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/customers/lists"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_goto_list" | language }}
				</button>
			</div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					*ngIf="able2add"
					type="button"
					routerLink="/customers/create"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_add" | language }}
				</button>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					*ngIf="able2edit"
					type="button"
					routerLink="/customers/edit/{{ id }}"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_edit" | language }}
				</button>
			</div>
		</div>
	</div>
</section>
<div class="modal" id="pdfPreviewRecPay">
	<div class="modal-dialog width70">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_preview" | language }}</h4>
				<button type="button" class="close" data-dismiss="modal">
					&times;
				</button>
			</div>
			<div class="modal-body">
				<div class="pdf-container">
					<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</div>
