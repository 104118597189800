<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno" [@fadeIn]>
    <mat-accordion class="contractors" >
        <mat-expansion-panel class="mpanel" [expanded]="true">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_contractors' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <select class="form-control almnabrformcontrol" [(ngModel)]="searchBranch" (change)="spinner.show();load_contractors()" matTooltip="{{'lang_search_all' | language }}">
                                <option value="">{{'lang_all' | language }}</option>
                                <option *ngFor="let branch of branches" value="{{branch.branch_id}}">{{branch.branch_name}}</option>
                            </select>
                        </div>
                        <div class="col-md-9 col-sm-9 col-xs-12 mb-10">
                            <input type="text" class="form-control almnabrformcontrol searchKey" [(ngModel)]="searchKey" (keyup)="load_contractors()" placeholder="{{'lang_search' | language }}" id="searchKey" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsCTors checkbox" id="allItemsCTors" (click)="checkAllItemsCTors($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_branch' | language }}</td>
                                            <td>{{'lang_name' | language }}</td>
                                            <td>{{'lang_email_address' | language }}</td>
                                            <td>{{'lang_mobile' | language }}</td>
                                            <td>{{'lang_vat_tax' | language }}</td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_ondate' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="cData.length != '0' " class="AppendList">
                                        <tr *ngFor="let data of cData.records; let i = index;let odd=odd; let even=even"
                                            [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{data.contractor_id}}" [attr.note_alert]="data.note_alert">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemCTors checkbox" value="{{data.contractor_id}}" (click)="checkEachItemCTors($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td> <a matTooltip="{{'lang_view' | language }}" routerLink="/contractors/view/{{data.contractor_id}}">{{data.branch_name}}</a></td>
                                            <td> <a matTooltip="{{'lang_view' | language }}" routerLink="/contractors/view/{{data.contractor_id}}">{{data.title}}</a></td>
                                            <td> <a matTooltip="{{'lang_view' | language }}" routerLink="/contractors/view/{{data.contractor_id}}">{{data.contractor_email_address}}</a></td>
                                            <td> <a matTooltip="{{'lang_view' | language }}" routerLink="/contractors/view/{{data.contractor_id}}">{{data.contractor_mobile}}</a></td>
                                            <td> <a matTooltip="{{'lang_view' | language }}" routerLink="/contractors/view/{{data.contractor_id}}">{{data.contractor_tax_vat}}</a></td>
                                            <td>{{data.name}}</td>
                                            <td>{{data.contractor_createddatetime}}</td>
                                            <td class="dropdown">
                                                <mat-icon class="mnabricon" [matMenuTriggerFor]="menu">settings</mat-icon>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item routerLink="/contractors/view/{{data.contractor_id}}"><span>{{'lang_view' | language }}</span></button>
                                                    <button mat-menu-item routerLink="/contractors/edit/{{data.contractor_id}}" *ngIf="admin"><span>{{'lang_edit' | language }}</span></button>
                                                </mat-menu>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="cData.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="10" align="center">{{lodingdatas}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="cData.length != '0' " class="card-footer padb0">
                    <div class="row responsive_pagination">
                        <div *ngIf="admin || ds.allowMe(contractors, 'contractors_delete')" class="deleteIconDiv none col-lg-1 col-sm-1 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataCTors()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-3  mb-10"  style="height: fit-content;margin: auto 0;">
                            {{'lang_total' | language }} : {{cData.page.total_records}}
                        </div>
                        <div class="col-lg-5 col-sm-5 mb-10"  style="display: flex;justify-content: center;">
                           <!--  <mat-radio-group>
                                <mat-radio-button value="10" [checked]="(page_size =='10') ? true : false" (click)="spinner.show();loadpagesize(10)"> 10 </mat-radio-button>
                                <mat-radio-button value="20" [checked]="(page_size =='20') ? true : false" (click)="spinner.show();loadpagesize(20)"> 20 </mat-radio-button>
                                <mat-radio-button value="50" [checked]="(page_size =='50') ? true : false" (click)="spinner.show();loadpagesize(50)"> 50 </mat-radio-button>
                                <mat-radio-button value="100" [checked]="(page_size =='100') ? true : false" (click)="spinner.show();loadpagesize(100)"> 100 </mat-radio-button>
                                <mat-radio-button value="500" [checked]="(page_size =='500') ? true : false" (click)="spinner.show();loadpagesize(500)"> 500 </mat-radio-button>
                            </mat-radio-group> -->
                            <ngb-pagination class="m-auto" [collectionSize]="cData?.page?.total_records" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="page_no" [pageSize]="page_size" (pageChange)="spinner.show();loadpage($event)"></ngb-pagination>
                        </div>
                        <div class="col-lg-3 col-sm-3"  style="display: flex;justify-content: end;margin: auto;">
                            <!-- {{'lang_goto' | language }} :
                            <select class="customselect" (change)="spinner.show();loadpage($event.target.value)">
                                <option *ngFor="let ks of ds.getrange(cData.page.total_pages)" [selected]="(page_no == ks) ? true : false" value="{{ks}}">{{ks}}</option>
                            </select> -->
                            <mat-select  [(ngModel)]="page_size" (selectionChange)="spinner.show();loadpagesize($event.value)">
                                     
                                <!-- <mat-option value="1">1  </mat-option> -->
                                <mat-option class="text-center" value="10">10 {{'lang_per_page'|language}} </mat-option>
                                <mat-option class="text-center" value="20">20 {{'lang_per_page'|language}}  </mat-option>
                                <mat-option class="text-center" value="50">50 {{'lang_per_page'|language}}  </mat-option>
                                <mat-option class="text-center" value="100">100 {{'lang_per_page'|language}}</mat-option>
                                <mat-option class="text-center" value="500">500 {{'lang_per_page'|language}}</mat-option>
                              </mat-select>

                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>