<div class="card special_approval_notes" [formGroup]="form">
	<div class="card-header">
		{{ "lang_special_approval_confirmation" | language }}
		<i
			style="padding: 0 10px"
			class="fa fa-info-circle orangestatus-color"
			matTooltip="{{ 'lang_special_approval_confirmation_tooltip' | language }}"
		></i>
	</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<div class="row">
				<div class="col-sm-12 mb-3">
					<div class="form-group">
						<label for="notes"
							>{{ "lang_notes" | language }}: <span class="red">*</span></label
						>
						<textarea
							type="text"
							required
							class="form-control almnabrformcontrol"
							formControlName="notes"
							name="notes"
							id="notes"
						></textarea>
					</div>
				</div>
			</div>
			<div class="row pt-3">
				<div class="col-sm-12">
					<app-alert id="default-alert"></app-alert>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-sm-8"></div>
				<div class="col-12 col-sm-4">
					<div>
						<button
							type="button"
							(click)="completeStep6()"
							class="albutton mnabrbutton mat-accent"
							mat-button
						>
							{{ "lang_submit" | language }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
