import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-ncr-step13',
  templateUrl: './ncr-step13.component.html',
  styleUrls: ['./ncr-step13.component.scss']
})
export class NcrStep13Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  form:FormGroup;
  users:any           = [];
  lang_key:string     = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
  constructor(public ds:DataService, private sweetAlert:SweetAlertService, public datepipe : DatePipe , public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router) { }
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id : new FormControl(this.form_request_id, [Validators.required]),
      teserve_transaction    : new FormControl(null, [Validators.required])
    });
  }
  
  public completeStep() {
    let teserve_transaction = this.form.get('teserve_transaction').value == true? '1': '0';
    if(this.form.valid) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('transaction_request_id', this.form.value.transaction_request_id);
      formData.append('teserve_transaction', teserve_transaction);

      this.ds.post(`form/${this.form_key}/QAQC_Reservation/0`, formData).subscribe(data => {
        if(data.status) {
          this.spinner.hide();
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
          setTimeout(() => {
            this.refreshComponent.emit(true);
          }, 2000);
        } else {
          this.spinner.hide();
          this.alert.error(data.error)
        }
      }, (error) => {
        this.spinner.hide();
          this.alert.error(error);
      })
    } else {
      this.alert.error(this.lang.transform('lang_all_fields_required'));
    }
  }
}
