<h3 mat-dialog-title>{{ "lang_template_filter" | language }}</h3>
<mat-dialog-content class="mat-typography">
	<div class="row">
        <div class="col-12 col-sm-6 mb-2">
            <label for="template_id">{{ "lang_template_id" | language }}</label>
            <ng-select
                [items]="templateList"
                [multiple]="false"
                bindLabel="label"
                appendTo="body"
                clearAllText="Clear"
                placeholder="{{ 'lang_template_id' | language }}"
                [(ngModel)]="template_id"
                (change)="load_group1_type_group2_lists('group1')"
                id="template_id"
            >
            </ng-select>
        </div>
        <div class="col-12 col-sm-6 mb-2">
            <label for="group1_id">{{ "lang_group_one" | language }}</label>
            <ng-select
                [items]="group1List"
                [multiple]="false"
                bindLabel="label"
                appendTo="body"
                clearAllText="Clear"
                placeholder="{{ 'lang_group_one' | language }}"
                [(ngModel)]="group1_id"
                (change)="load_group1_type_group2_lists('type')"
                id="group1_id"
            >
            </ng-select>
        </div>
        <div class="col-12 col-sm-6 mb-2">
            <label for="group_type_id">{{
                "lang_group_type" | language
            }}</label>
            <ng-select
                [items]="typeList"
                [multiple]="false"
                bindLabel="label"
                appendTo="body"
                clearAllText="Clear"
                placeholder="{{ 'lang_type' | language }}"
                [(ngModel)]="group_type_id"
                (change)="load_group1_type_group2_lists('group2')"
                id="group_type_id"
            >
            </ng-select>
        </div>
        <div class="col-12 col-sm-6 mb-2">
            <label for="group2_id">{{ "lang_group_two" | language }}</label>
            <ng-select
                [items]="group2List"
                [multiple]="false"
                bindLabel="label"
                appendTo="body"
                clearAllText="Clear"
                placeholder="{{ 'lang_group_two' | language }}"
                [(ngModel)]="group2_id"
                id="group2_id"
            >
            </ng-select>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div style="width: 100px">
        <button
            mat-button
            class="albutton mnabrbutton mat-accent"
            (click)="saveTemplateFilter()"
        >
            {{ "lang_save" | language }}
        </button>
    </div>
</mat-dialog-actions>
