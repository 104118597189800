<section class="panel-expansion">
    <mat-accordion class="appcollectiondetails" >
        <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_collection_details' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15 martb0">
                    <div class="row">
                        <div class="col-12">
                            <div class="input-group input-group-sm mb-10">
                                <input type="text" class="form-control almnabrformcontrol" (keyup)="list_of_collection_details();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                                <span class="input-group-btn">
                                    <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" id="btnSearch" (click)="openModalA(addsettings);editdata=[];action ='';" type="button" matTooltip="{{'lang_add' | language }}"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2 col-sm-12 col-xs-12 mb-10">
                            <ng-select 
                                [items]="collections" 
                                [multiple]="false" 
                                bindLabel="label" 
                                appendTo="body" 
                                bindValue="value" 
                                clearAllText="Clear"
                                placeholder="{{'lang_collections' | language}}"
                                [(ngModel)]="collection_search_field"
                                (change)="spinner.show();list_of_collection_details();group2_search();platform_search();">
                            </ng-select>
                        </div>
                        <div class="col-md-2 col-sm-12 col-xs-12 mb-10">
                            <ng-select 
                                [items]="group1codes" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_group' | language}} 1"
                                [(ngModel)]="collection_search_group1"
                                (change)="spinner.show();list_of_collection_details();group2_search();platform_search();">
                            </ng-select>
                        </div>
                        <div class="col-md-2 col-sm-12 col-xs-12 mb-10">
                            <ng-select 
                                [items]="types" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_group_types' | language}}"
                                [(ngModel)]="collection_search_type"
                                (change)="spinner.show();list_of_collection_details();group2_search();platform_search();">
                            </ng-select>
                        </div>
                        <div class="col-md-3 col-sm-12 col-xs-12 mb-10">
                            <ng-select 
                                [items]="group2codes" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_group' | language}} 2"
                                [(ngModel)]="collection_search_group2"
                                (change)="spinner.show();list_of_collection_details();platform_search();">
                            </ng-select>
                        </div>
                        <div class="col-md-3 col-sm-12 col-xs-12 mb-10">
                            <ng-select 
                                [items]="platforms" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_project_platforms' | language}}"
                                [(ngModel)]="collection_search_platform"
                                (change)="spinner.show();list_of_collection_details();">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsCollDet checkbox" id="allItemsCollDet" (click)="checkallItemsCollDet($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_collections' | language }}</td>
                                            <td>{{'lang_group' | language }} 1</td>
                                            <td>{{'lang_group_type' | language }}</td>
                                            <td>{{'lang_group' | language }} 2</td>
                                            <td>{{'lang_project_platforms' | language }}</td>
                                            <td>{{'lang_platform_code_systems' | language }}</td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="data.length != '0' " class="AppendList">
                                        <tr *ngFor="let row of tabledata; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{row.collection_details_id}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemCollDet checkbox" value="{{row.collection_details_id}}" (click)="checkeachItemCollDet($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{row.collectionname}}</td>
                                            <td>{{row.group1name}}</td>
                                            <td>{{row.typename}}</td>
                                            <td>{{row.group2name}}</td>
                                            <td>{{row.platformname}}</td>
                                            <td *ngIf="row.platform_code_systems">{{row.platform_code_systems}}</td>
                                            <td *ngIf="!row.platform_code_systems">----</td>
                                            <td>{{row.writer}}</td>
                                            <td>
                                                <i class="fa fa-eye s2icon fa-icon mnabricon" matTooltip="{{'lang_view' | language }}" (click)="load_view_data(row, viewtemplate);"></i>
                                                <i class="fa fa-pencil-square-o s2icon fa-icon mnabricon" matTooltip="{{'lang_edit' | language }}" (click)="openModalA(addsettings);action = 'edit'; editdata = row;"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="data.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="10" align="center">{{nodata}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="tabledata.length > 0 " class="card-footer padb0">
                    <div class="row">
                        <div *ngIf="admin" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataCollDet();" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-6 mb-10">
                            {{'lang_total' | language }} : {{collectionSize}}
                        </div>
                        <div class="col-lg-5 col-sm-6 mb-10">
                            <ngb-pagination [collectionSize]="collectionSize" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [(page)]="page" [pageSize]="pageSize" (pageChange)="build_table_data()"></ngb-pagination>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <select class="customselect" [(ngModel)]="pageSize" (change)="build_table_data()">
                                <option [ngValue]="10">10</option>
                                <option [ngValue]="20">20</option>
                                <option [ngValue]="50">50</option>
                                <option [ngValue]="100">100</option>
                                <option [ngValue]="250">250</option>
                                <option [ngValue]="500">500</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<ng-template #addsettings>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_collection_details' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefA.hide();">&times;</button>
        </div>
        <app-cdetails-add [collections]="collections" [group1codes]="group1codes" [grouptypes]="types" (addEvent)="list_of_collection_details($event);" [action]="action" [editdata]="editdata" (closeModel)="modalRefA.hide();"></app-cdetails-add>
    </div>
</ng-template>
<ng-template #viewtemplate>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_collection_details' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefV.hide();">&times;</button>
        </div>
        <div class="modal-body" *ngIf="viewdata">
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_collections' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.data.collectionname}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_group' | language }} 1:</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.data.group1name}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_group_types' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.data.typename}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_group' | language }} 2:</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.data.group2name}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_project_platforms' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.data.platformname}} ( {{viewdata.data.platform_code_system}} )</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.data.platform_code_systems">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_platform_code_systems' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <ul style="padding: 0 20px;list-style-type:square">
                            <li class="breakall" *ngFor="let list of viewdata.codes"><b>{{list.fulltext}}</b></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modalRefV.hide();">{{'lang_close' | language }}</button>
        </div>
    </div>
</ng-template>