<section class="panel-expansion">
  <mat-accordion class="appuserslists">
    <mat-expansion-panel class="mpanel" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_users_lists" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <div class="mbrl15 martb0">
          <div class="row">
            <div class="col-md-3 col-xs-12 mb-10">
              <ng-select
                [items]="types"
                [multiple]="false"
                [bindLabel]="
                  lang_key == 'ar' ? 'user_type_name_ar' : 'user_type_name_en'
                "
                bindValue="value"
                appendTo="body"
                clearAllText="Clear"
                placeholder="{{ 'lang_user_type' | language }}"
                [(ngModel)]="userType"
                (change)="spinner.show(); get_user_list()"
              >
              </ng-select>
            </div>
            <div class="col-md-3 col-xs-12 mb-10">
              <select
                class="form-control"
                [(ngModel)]="userStatus"
                (change)="spinner.show(); get_user_list()"
              >
                <option value="">{{ "lang_all" | language }}</option>
                <option value="1">{{ "lang_active" | language }}</option>
                <option value="0">{{ "lang_inactive" | language }}</option>
              </select>
            </div>
            <div class="col-md-6 col-xs-12 mb-10">
              <div class="input-group input-group-sm">
                <input
                  type="text"
                  class="form-control almnabrformcontrol"
                  (keyup)="get_user_list()"
                  [(ngModel)]="searchKey"
                  placeholder="{{ 'lang_search' | language }}"
                />
                <span class="input-group-btn">
                  <button
                    class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
                    id="btnSearch"
                    type="button"
                    matTooltip="{{ 'lang_add' | language }}"
                    (click)="onAddUpdateUser()"
                  ></button>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive dropdownyes customResponsive">
                <table border="1" class="table CustomTable">
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>
                        <div
                          class="checkbox"
                          matTooltip="{{ 'lang_check_all' | language }}"
                        >
                          <input
                            type="checkbox"
                            class="allItemsUsl checkbox"
                            id="allItemsUsl"
                            (click)="checkallItemsUsl($event)"
                          />
                          <label for="checkbox"></label>
                        </div>
                      </td>
                      <td>{{ "lang_user_id" | language }}</td>
                      <td>{{ "lang_username" | language }}</td>
                      <td>{{ "lang_email_address" | language }}</td>
                      <td>{{ "lang_mobile" | language }}</td>
                      <td>{{ "lang_iqama" | language }}</td>
                      <td>{{ "lang_user_status" | language }}</td>
                      <td>{{ "lang_user_role" | language }}</td>
                      <td>{{ "lang_user_type" | language }}</td>
                      <td>{{ "lang_action" | language }}</td>
                    </tr>
                  </thead>
                  <tbody *ngIf="wholeData.length != '0'" class="AppendList">
                    <tr
                      *ngFor="
                        let row of tabledata;
                        let i = index;
                        let odd = odd;
                        let even = even
                      "
                      [ngClass]="{ odd: odd, even: even }"
                      class="makeback removerow{{ row.user_id }}"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>
                        <div
                          class="checkbox"
                          matTooltip="{{ 'lang_check' | language }}"
                        >
                          <input
                            type="checkbox"
                            class="eachItemUsl checkbox"
                            value="{{ row.user_id }}"
                            (click)="checkeachItemUsl($event)"
                          />
                          <label for="checkbox"></label>
                        </div>
                      </td>
                      <td>{{ row.user_id }}</td>
                      <td>{{ row.user_username }}</td>
                      <td *ngIf="row.user_email">{{ row.user_email }}</td>
                      <td *ngIf="!row.user_email">----</td>
                      <td *ngIf="row.user_phone">{{ row.user_phone }}</td>
                      <td *ngIf="!row.user_phone">----</td>
                      <td *ngIf="row.iqama_id">{{ row.iqama_id }}</td>
                      <td *ngIf="!row.iqama_id">----</td>
                      <td *ngIf="!row.user_status">-----</td>
                      <td *ngIf="row.user_status == '0'">
                        <i
                          class="fa fa-icon fa-circle red"
                          matTooltip="{{ 'lang_inactive' | language }}"
                        ></i>
                      </td>
                      <td *ngIf="row.user_status == '1'">
                        <i
                          class="fa fa-icon fa-check-circle-o green"
                          matTooltip="{{ 'lang_active' | language }}"
                        ></i>
                      </td>
                      <td *ngIf="row.user_status == '2'">
                        <i
                          class="fa fa-icon fa-clock-o yellowcolor"
                          matTooltip="{{ 'lang_away' | language }}"
                        ></i>
                      </td>
                      <td *ngIf="row.user_status == '3'">
                        <i
                          class="fa fa-icon fa-minus-circle red"
                          matTooltip="{{ 'lang_dont_distrub' | language }}"
                        ></i>
                      </td>
                      <td *ngIf="row.user_status == '4'">
                        <i
                          class="fa fa-icon fa-circle graycolor"
                          matTooltip="{{ 'lang_invisible' | language }}"
                        ></i>
                      </td>
                      <td *ngIf="row.is_admin == '1'">
                        {{ "lang_admin" | language }}
                      </td>
                      <td *ngIf="row.is_admin != '1'">
                        {{ "lang_users" | language }}
                      </td>
                      <td *ngIf="lang_key != 'ar'">
                        {{ row.user_type_name_en }}
                      </td>
                      <td *ngIf="lang_key == 'ar'">
                        {{ row.user_type_name_ar }}
                      </td>
                      <td>
                        <i
                          class="fa fa-pencil mnabricon"
                          matTooltip="{{ 'lang_edit' | language }}"
                          (click)="onAddUpdateUser(row)"
                        ></i>
                      </td>
                    </tr>
                    <tr *ngIf="admin" class="deleteIconDiv none">
                      <td></td>
                      <td>
                        <div *ngIf="admin" class="deleteIconDiv none">
                          <i
                            class="fa fa-trash-o faicon red deleteIcon"
                            (click)="deleteBulkDataUsl()"
                            aria-hidden="true"
                            matTooltip="{{ 'lang_delete' | language }}"
                          ></i>
                        </div>
                      </td>
                      <td colspan="9"></td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="wholeData.length == '0'" class="AppendList">
                    <tr class="odd">
                      <td colspan="11" align="center">{{ nodata }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tabledata?.length != '0'" class="card-footer padb0">
          <div class="row">
            <div class="col-lg-3 col-sm-6 col-12 mb-10">
              {{ "lang_total" | language }} : {{ collectionSize }}
            </div>
            <div
              class="col-lg-6 col-sm-6 col-12 mb-10 d-flex justify-content-center"
            >
              <ngb-pagination
                [collectionSize]="collectionSize"
                [maxSize]="3"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="get_user_list()"
              ></ngb-pagination>
            </div>
            <div class="col-lg-3 col-sm-6 col-12 d-flex justify-content-end">
              <mat-select
                [(ngModel)]="pageSize"
                (selectionChange)="get_user_list()"
                style="width: 130px"
              >
                <mat-option [value]="10"
                  >10 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option [value]="20"
                  >20 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option [value]="50"
                  >50 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option [value]="100"
                  >100 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option [value]="250"
                  >250 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option [value]="500"
                  >500 {{ "lang_per_page" | language }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
