import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { ConfirmCpayCreateComponent } from "../contract-payment-create/confirm-cpay-create/confirm-cpay-create.component";
import { PaymentFiltersComponent } from "../contract-payment-create/payment-filters/payment-filters.component";
import { ExcelHistoryModalComponent } from "../excel-history-modal/excel-history-modal.component";

@Component({
	selector: "app-contractor-payment-division-template",
	templateUrl: "./contractor-payment-division-template.component.html",
	styleUrls: ["./contractor-payment-division-template.component.scss"],
})
export class ContractorPaymentDivisionTemplateComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	@Input() supervision_data;
	@Input() project_data;
	@Input() makeActionList;
	tableContent: any = null;
	show_ok = true;
	show_notok = true;
	show_notuse = true;
	cpay_filters: any;
	isLoading = false;
	contractor_manager_step_require = "0";

	// By Raja
	divTitles: any = [];
	divAmounts: any = [];
	mainData: any = [];
	unitData: any = [];
	unitPage: any = [];
	page_no: any = 1;
	page_size: any = "10";
	total_units: any = 0;
	pend_unit_total: any;
	paid_unit_total: any;
	total_templates: any = 0;
	group1Names = [];
	group2Names = [];
	platformNames = [];
	worklevelNames = [];
	constructor(
		private ds: DataService,
		private dialog: MatDialog,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private router: Router,
		private lang: LanguagePipe
	) {}
	ngOnInit(): void {
		this.main_details_with_division_titles();
		this.get_payments_by_units();
	}
	public customformData() {
		let formData = new FormData();
		formData.append(
			"projects_profile_id",
			this.project_data.projects_profile_id
		);
		formData.append(
			"projects_work_area_id",
			this.supervision_data.projects_work_area_id
		);
		formData.append(
			"projects_supervision_id",
			this.supervision_data.projects_supervision_id
		);
		formData.append("unit_ids", this.cpay_filters?.unit_ids?.toString() || "");
		formData.append(
			"title_ids",
			this.cpay_filters?.title_ids?.toString() || ""
		);
		formData.append(
			"sub_title_ids",
			this.cpay_filters?.sub_title_ids?.toString() || ""
		);
		formData.append(
			"template_ids",
			this.cpay_filters?.template_ids?.toString() || ""
		);
		formData.append(
			"platform_code_systems",
			this.cpay_filters?.platform_code_systems?.toString() || ""
		);
		formData.append(
			"platform_work_level_keys",
			this.cpay_filters?.platform_work_level_keys?.toString() || ""
		);
		formData.append(
			"platform_group1_code_systems",
			this.cpay_filters?.platform_group1_code_systems?.toString() || ""
		);
		formData.append(
			"platform_group_type_code_systems",
			this.cpay_filters?.platform_group_type_code_systems?.toString() || ""
		);
		formData.append(
			"platform_group2_code_systems",
			this.cpay_filters?.platform_group2_code_systems?.toString() || ""
		);
		formData.append(
			"phase_zone_nos",
			this.cpay_filters?.phase_zone_nos?.phase_zone_no || ""
		);
		formData.append(
			"phase_zone_block_nos",
			this.cpay_filters?.phase_zone_block_nos?.phase_zone_block_no || ""
		);
		formData.append(
			"phase_zone_block_cluster_nos",
			this.cpay_filters?.phase_zone_block_cluster_nos
				?.phase_zone_block_cluster_no || ""
		);
		formData.append(
			"transaction_date_start",
			this.cpay_filters?.transaction_date_start || ""
		);
		formData.append(
			"transaction_date_end",
			this.cpay_filters?.transaction_date_end || ""
		);
		formData.append("submit_data", "0");
		return formData;
	}
	public convertO2A(divTitles: any = []) {
		let titles = [];
		Object.keys(divTitles).forEach(function (key) {
			titles.push(divTitles[key]);
		});
		return titles;
	}
	public main_details_with_division_titles() {
		this.divAmounts = [];
		this.divTitles = [];
		this.isLoading = true;
		let formData = this.customformData();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"form/FORM_CPAY/get_payments_by_divisions"
			)
			.subscribe(
				(res) => {
					this.isLoading = false;
					this.spinner.hide();
					if (res?.status) {
						this.divAmounts = res?.amounts;
						this.divTitles = res?.records;
						this.group1Names = this.convertO2A(res?.records);
						console.log(this.group1Names);

						for (let group1 of this.group1Names) {
							this.group2Names.push(...this.convertO2A(group1?.group2));
						}
						console.log(this.group2Names);
						for (let group2 of this.group2Names) {
							this.platformNames.push(...this.convertO2A(group2?.platforms));
						}
						for (let platform of this.platformNames) {
							this.worklevelNames.push(...this.convertO2A(platform?.levels));
						}
					}
				},
				(err) => {
					this.spinner.hide();
					this.isLoading = false;
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}

	public getBuildDataPage(page) {
		this.spinner.show();
		this.isLoading = true;
		this.page_no = page;
		this.page_size = this.page_size;
		this.get_payments_by_units();
	}
	public getBuildDataPageSize(size) {
		this.spinner.show();
		this.isLoading = true;
		this.page_no = 1;
		this.page_size = size;
		this.get_payments_by_units();
	}
	//start get build data
	public get_payments_by_units() {
		this.unitData = [];
		this.isLoading = true;
		let formData = this.customformData();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"form/FORM_CPAY/get_payments_by_units/" +
					this.page_no +
					"/" +
					this.page_size
			)
			.subscribe(
				(res) => {
					this.isLoading = false;
					this.spinner.hide();
					if (res?.status) {
						this.unitData = res?.records;
						this.unitPage = res?.page;
						this.total_units = res?.total;
						this.pend_unit_total = res?.pend_unit_total;
						this.paid_unit_total = res?.paid_unit_total;
						this.total_templates = res?.total_templates;
					} else {
						this.total_units = 0;
						this.pend_unit_total = 0;
						this.paid_unit_total = 0;
						this.unitData = [];
						this.unitPage = [];
					}
				},
				(err) => {
					this.spinner.hide();
					this.isLoading = false;
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
	//end get build data
	//open filters
	onFilters() {
		const dialogRef = this.dialog.open(PaymentFiltersComponent, {
			width: "600px",
			autoFocus: false,
			data: {
				oldFilters: this.cpay_filters,
				projects_work_area_id: this.supervision_data.projects_work_area_id,
				projects_profile_id: this.project_data.projects_profile_id,
				projects_supervision_id: this.supervision_data.projects_supervision_id,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.spinner.show();
				this.isLoading = true;
				this.cpay_filters = res;
				this.mainData = [];
				this.unitData = [];
				this.unitPage = [];
				this.main_details_with_division_titles();
				this.get_payments_by_units();
			}
		});
	}
	//on create contract payment
	onCreate() {
		const dialogRef = this.dialog.open(ConfirmCpayCreateComponent, {
			width: "500px",
			autoFocus: false,
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res?.status) {
				this.contractor_manager_step_require =
					res?.contractor_manager_step_require;
				this.createForm();
			}
		});
	}
	createForm() {
		this.spinner.show();
		let formData = new FormData();
		formData.append(
			"projects_profile_id",
			this.project_data.projects_profile_id
		);
		formData.append(
			"projects_work_area_id",
			this.supervision_data.projects_work_area_id
		);
		formData.append(
			"projects_supervision_id",
			this.supervision_data.projects_supervision_id
		);
		formData.append("lang_key", this.lang_key);
		formData.append(
			"contractor_manager_step_require",
			this.contractor_manager_step_require || ""
		);
		formData.append("unit_ids", this.cpay_filters?.unit_ids?.toString() || "");
		formData.append(
			"title_ids",
			this.cpay_filters?.title_ids?.toString() || ""
		);
		formData.append(
			"sub_title_ids",
			this.cpay_filters?.sub_title_ids?.toString() || ""
		);
		formData.append(
			"template_ids",
			this.cpay_filters?.template_ids?.toString() || ""
		);
		formData.append(
			"platform_code_systems",
			this.cpay_filters?.platform_code_systems?.toString() || ""
		);
		formData.append(
			"platform_work_level_keys",
			this.cpay_filters?.platform_work_level_keys?.toString() || ""
		);
		formData.append(
			"platform_group1_code_systems",
			this.cpay_filters?.platform_group1_code_systems?.toString() || ""
		);
		formData.append(
			"platform_group_type_code_systems",
			this.cpay_filters?.platform_group_type_code_systems?.toString() || ""
		);
		formData.append(
			"platform_group2_code_systems",
			this.cpay_filters?.platform_group2_code_systems?.toString() || ""
		);
		formData.append(
			"phase_zone_nos",
			this.cpay_filters?.phase_zone_nos?.phase_zone_no || ""
		);
		formData.append(
			"phase_zone_block_nos",
			this.cpay_filters?.phase_zone_block_nos?.phase_zone_block_no || ""
		);
		formData.append(
			"phase_zone_block_cluster_nos",
			this.cpay_filters?.phase_zone_block_cluster_nos
				?.phase_zone_block_cluster_no || ""
		);
		formData.append(
			"transaction_date_start",
			this.cpay_filters?.transaction_date_start || ""
		);
		formData.append(
			"transaction_date_end",
			this.cpay_filters?.transaction_date_end || ""
		);
		formData.append("print_custom_title", "");
		formData.append("print_custom_system_unit_title", "");
		formData.append("print_template", "");
		this.ds.post("form/FORM_CPAY/create_request", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast("lang_success", 3000);
					this.router.navigate(["/tasks/alltasks"]);
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast("lang_internal_server_error", 3000);
			}
		);
	}

	show_payment_records(title: any, type: any) {
		console.log(type);
		console.log(title);
	}

	public fixedA(num: any) {
		if (isNaN(num)) {
			return "0.00";
		}
		return parseFloat(num).toFixed(2);
	}
	ratio(ratio: any = 0, type: any = "C") {
		if (ratio > 0) {
			if (type == "C") {
				// if current
				return (ratio / this.total_units).toFixed(2) + "%";
			} else if (type == "P") {
				// if pending
				return (ratio / this.pend_unit_total).toFixed(2) + "%";
			} else if (type == "D") {
				//if done
				return (ratio / this.paid_unit_total).toFixed(2) + "%";
			} else {
				return "0%";
			}
		}
		return ratio;
	}
	public remainingCost(item) {
		return (
			item.unit_cost -
			(item.total_amount + item.pending_total_amount + item.paid_total_amount)
		);
	}
	getGroup1Colspan(group2List) {
		let colspan = 0;
		for (let group2 of group2List) {
			for (let platform of this.convertO2A(group2?.platforms)) {
				colspan += this.convertO2A(platform?.levels)?.length;
			}
		}
		return colspan;
	}
	getGroup2Colspan(platforms) {
		let colspan = 0;
		for (let item of platforms) {
			colspan += this.convertO2A(item?.levels)?.length;
		}
		return colspan;
	}

	downloadExcel() {
		let formData = new FormData();
		formData.append(
			"projects_profile_id",
			this.project_data.projects_profile_id
		);
		formData.append(
			"projects_work_area_id",
			this.supervision_data.projects_work_area_id
		);
		formData.append(
			"projects_supervision_id",
			this.supervision_data.projects_supervision_id
		);
		formData.append("lang_key", this.lang_key);
		formData.append(
			"contractor_manager_step_require",
			this.contractor_manager_step_require || ""
		);
		formData.append("unit_ids", this.cpay_filters?.unit_ids?.toString() || "");
		formData.append(
			"title_ids",
			this.cpay_filters?.title_ids?.toString() || ""
		);
		formData.append(
			"sub_title_ids",
			this.cpay_filters?.sub_title_ids?.toString() || ""
		);
		formData.append(
			"template_ids",
			this.cpay_filters?.template_ids?.toString() || ""
		);
		formData.append(
			"platform_code_systems",
			this.cpay_filters?.platform_code_systems?.toString() || ""
		);
		formData.append(
			"platform_work_level_keys",
			this.cpay_filters?.platform_work_level_keys?.toString() || ""
		);
		formData.append(
			"platform_group1_code_systems",
			this.cpay_filters?.platform_group1_code_systems?.toString() || ""
		);
		formData.append(
			"platform_group_type_code_systems",
			this.cpay_filters?.platform_group_type_code_systems?.toString() || ""
		);
		formData.append(
			"platform_group2_code_systems",
			this.cpay_filters?.platform_group2_code_systems?.toString() || ""
		);
		formData.append(
			"phase_zone_nos",
			this.cpay_filters?.phase_zone_nos?.phase_zone_no || ""
		);
		formData.append(
			"phase_zone_block_nos",
			this.cpay_filters?.phase_zone_block_nos?.phase_zone_block_no || ""
		);
		formData.append(
			"phase_zone_block_cluster_nos",
			this.cpay_filters?.phase_zone_block_cluster_nos
				?.phase_zone_block_cluster_no || ""
		);
		formData.append(
			"transaction_date_start",
			this.cpay_filters?.transaction_date_start || ""
		);
		formData.append(
			"transaction_date_end",
			this.cpay_filters?.transaction_date_end || ""
		);
		this.spinner.show();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"form/FORM_CPAY/export_payments_by_divisions"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetAlert.successMessage(res?.msg);
						this.ds.downloadFile(res, "contract_payments_file");
					} else {
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.sweetAlert.errorMessage(
						this.lang.transform("lang_internal_server_error")
					);
					this.spinner.hide();
				}
			);
	}

	uploadExcel(e) {
		if (e && e?.length) {
			let url = "form/FORM_CPAY/import_payments_by_divisions";
			let formData = new FormData();
			formData.append(
				"projects_profile_id",
				this.project_data?.projects_profile_id || ""
			);
			formData.append(
				"projects_supervision_id",
				this.supervision_data?.projects_supervision_id || ""
			);
			formData.append(
				"projects_work_area_id",
				this.supervision_data?.projects_work_area_id || ""
			);
			formData.append("paid_file_data", e[0] || "");
			this.spinner.show();
			this.ds.post(url, formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetAlert.successMessage(res?.msg);
					} else {
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.sweetAlert.errorMessage(err?.error?.error);
					this.spinner.hide();
				}
			);
		}
		document.getElementById("uploadExcelInput")["value"] = "";
	}

	show_history() {
		const dialogRef = this.dialog.open(ExcelHistoryModalComponent, {
			autoFocus: false,
			data: {
				projects_profile_id: this.project_data?.projects_profile_id,
				projects_supervision_id: this.supervision_data?.projects_supervision_id,
			},
		});
	}
}
