import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import moment from "moment";
import { ChatService } from "app/routes/whatsapp/services/chat.service";
import { WhatsappSocketService } from "app/routes/whatsapp/services/whatsapp-socket.service";
import { WContactsModalComponent } from "app/routes/whatsapp/components/w-contacts-modal/w-contacts-modal.component";
import { WTemplatesModalComponent } from "app/routes/whatsapp/components/w-templates-modal/w-templates-modal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-hr-whatsapp",
  templateUrl: "./hr-whatsapp.component.html",
  styleUrls: ["./hr-whatsapp.component.scss"],
})
export class HrWhatsappComponent implements OnInit, AfterViewInit {
  modalRef: BsModalRef;
  @Output() resfreshview_details = new EventEmitter<any>();
  request = 0;
  User_data;
  @Input() set user_data(data: any) {
    if (data?.whatsapp_registration_id == null) {
      if (this.request == 0) {
        this.createContact(data?.employee_number, data?.branch_id);
        this.request = 1;
      }
    } else {
      this.getAllContacts(data?.whatsapp_registration_id);
    }
  }
  chat = {};
  lang_key = localStorage.getItem("lang_key") || "en";
  server_origin = environment.SERVER_ORIGIN;
  whatsappSettings;
  isChatView = false;
  receiverData;
  chatData;
  messages = [];
  isLoadingMessages = false;
  replyMessage = null;
  isSendTemplate = false;
  sendTxtMsg = "";
  canText = false;
  //attachment
  isUploadedAttachment = false;
  attachmentSelected;
  attachmentSelectedName = "";
  attachmentSelectedSize = "";
  attachmentSelectedType = "";
  sendAttachmentMsg = "";
  showAttachImgPreview = false;
  attachImgPreview: any = "";
  uploadedFiles = [];
  zoom = 10;
  page_no = 1;
  page_size = 10;
  viewers_list = [];
  total_records: any;
  tempopen = false;
  msg_histories_id = null;
  constructor(
    private chatService: ChatService,
    private ds: DataService,
    private spinner: PreloaderService,
    private dialog: MatDialog,
    private sweetAlert: SweetAlertService,
    private lang: LanguagePipe,
    private whatsappSocketService: WhatsappSocketService,
    public modalService: BsModalService
  ) {}

  ngOnInit(): void {
    moment.locale(this.lang_key || "en");
    this.getSettingData();
  }
  openRecentChat(chat) {
    this.chatService.selectedChat.next(chat);
  }
  ngAfterViewInit() {}
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  initInputValues() {
    this.replyMessage = null;
    this.isSendTemplate = false;
    this.sendTxtMsg = "";
    //attachment
    this.isUploadedAttachment = false;
    this.attachmentSelected;
    this.attachmentSelectedName = "";
    this.attachmentSelectedSize = "";
    this.attachmentSelectedType = "";
    this.sendAttachmentMsg = "";
  }
  //get whatsapp setting data.
  getSettingData() {
    this.spinner.show();
    this.ds.get("WhatsappApi/getAllWabaNumbers").subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.whatsappSettings = res?.wabanumbers[0];
        } else {
          this.whatsappSettings = null;
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  //get Chat Messages by contact id
  getChatMessages(id, isLoadingMessages) {
    if (isLoadingMessages == "LoadingMessages") {
      this.isLoadingMessages = true;
    }
    let formData = new FormData();
    formData.append("id", id || "");
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "WhatsappApi/getAllMesseges"
      )
      .subscribe(
        (res) => {
          this.messages = res?.messages;
          this.scrollToBottom();
          setTimeout(() => {
            this.isLoadingMessages = false;
          }, 700);
        },
        (err) => {
          this.isLoadingMessages = false;
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
  }

  //on reply on message
  onReply(message) {
    const chatFooterEL = document.getElementById("chatFooter"),
      chatViewBody = document.getElementById("chatViewBody");
    chatViewBody.style.height = "calc(100vh - 379px)";
    chatFooterEL.style.height = "180px";
    this.replyMessage = message;
  }

  onCancelReply() {
    this.replyMessage = null;
    const chatFooterEL = document.getElementById("chatFooter"),
      chatViewBody = document.getElementById("chatViewBody");
    chatViewBody.style.height = "calc(100vh - 269px)";
    chatFooterEL.style.height = "70px";
  }

  onSendMessage() {
    if (this.replyMessage) {
      this.sendTextReplyMsg();
    } else {
      this.sendTextMessage();
    }
  }

  sendTextMessage() {
    let formData = new FormData();
    formData.append("waInitMsgNum", this.chatData.number || "");
    formData.append("waMsg", this.sendTxtMsg || "");
    formData.append("waSenderId", this.whatsappSettings?.id || "");
    formData.append("waReceiverId", this.chatData.id || "");
    if (this.sendTxtMsg) {
      this.ds.post("WhatsappApi/sendTextMessage", formData).subscribe(
        (res) => {
          this.getChatMessages(this.chatData.id, "noLoadingMessages");
          this.sendTxtMsg = "";
        },
        (err) => {
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
    } else {
      this.sweetAlert.errorToast(
        this.lang.transform("lang_please_type_msg"),
        3000
      );
    }
  }

  sendTextReplyMsg() {
    let formData = new FormData();
    formData.append("waInitMsgNum", this.chatData.number || "");
    formData.append("waMsg", this.sendTxtMsg || "");
    formData.append("waSenderId", this.whatsappSettings?.id || "");
    formData.append("waReceiverId", this.chatData.id || "");
    formData.append("message_id", this.replyMessage?.all?.wa_id || "");
    if (this.sendTxtMsg) {
      this.ds.post("WhatsappApi/sendTextMessage", formData).subscribe(
        (res) => {
          this.getChatMessages(this.chatData.id, "noLoadingMessages");
          this.sendTxtMsg = "";
          this.onCancelReply();
        },
        (err) => {
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
    } else {
      this.sweetAlert.errorToast(
        this.lang.transform("lang_please_type_msg"),
        3000
      );
    }
  }

  sendTemplateMessage(template) {
    let formData = new FormData();
    formData.append("initMsg", "1");
    formData.append("waInitMsgNum", this.chatData.number || "");
    formData.append("waMsg", template?.value || "");
    formData.append("message_value", template?.label || "");
    formData.append("waSenderId", this.whatsappSettings?.id || "");
    formData.append("waReceiverId", this.chatData.id || "");
    this.ds.post("WhatsappApi/sendTemplateMessage", formData).subscribe(
      (res) => {
        this.getChatMessages(this.chatData.id, "noLoadingMessages");
      },
      (err) => {
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          3000
        );
      }
    );
  }

  //onSendTemplate
  onSendTemplate() {
    const dialogRef = this.dialog.open(WTemplatesModalComponent, {
      autoFocus: false,
      width: "430px",
      data: {
        waToken: this.whatsappSettings?.waToken,
        waPhonenumberID: this.whatsappSettings?.wabaId,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.sendTemplateMessage(res);
      }
    });
  }

  //sendAttachmentMessage
  onSendAttachment(files) {
    if (files && files[0]) {
      if (files[0]?.type?.includes("image")) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          const file = {
            file: files[0],
            file_name: files[0].name,
            file_type: files[0].type.split("/")[1].toUpperCase(),
            file_size: (files[0].size / 1024).toFixed(2),
            imgSrc: reader.result,
            caption: "",
          };
          this.uploadedFiles.push(file);
          this.attachmentSelected = file;
          this.isUploadedAttachment = true;
          document.getElementById("chatAttachInput")["value"] = "";
        };
      } else {
        const file = {
          file: files[0],
          file_name: files[0].name,
          file_type: files[0].type.split("/")[1].toUpperCase(),
          file_size: (files[0].size / 1024).toFixed(2),
          imgSrc: null,
          caption: "",
        };
        this.uploadedFiles.push(file);
        this.attachmentSelected = file;
        this.isUploadedAttachment = true;
        document.getElementById("chatAttachInput")["value"] = "";
      }
    }
  }
  sendAttachmentMessage() {
    if (this.uploadedFiles.length) {
      let formData = new FormData();
      formData.append("waAttachmentReceiverId", this.chatData.id || "");
      formData.append("waAttachmentToNum", this.chatData.number || "");
      this.uploadedFiles.forEach((el, key) => {
        formData.append(`files[${key}]`, el?.file || "");
        formData.append(`caption[${key}]`, el?.caption || "");
      });
      this.ds.post("WhatsappApi/sendAttachment", formData).subscribe((res) => {
        this.getChatMessages(this.chatData.id, "noLoadingMessages");
        this.isUploadedAttachment = false;
        this.uploadedFiles = [];
      });
    }
  }

  onDownloadAttachment(
    attachmentUrl: string,
    attachmentTitle: string,
    mediaId: string
  ) {
    if (attachmentUrl) {
      this.downloadAttachment(attachmentUrl, attachmentTitle);
    } else {
      this.downloadAttachmentById(mediaId, attachmentTitle);
    }
  }
  downloadAttachment(attachmentUrl: string, attachmentTitle: string) {
    this.spinner.show();
    this.ds.get(attachmentUrl).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.ds.downloadFile(res, attachmentTitle);
        } else {
          this.sweetAlert.errorToast(res?.error, 3000);
        }
      },
      (err) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          3000
        );
      }
    );
  }
  downloadAttachmentById(mediaId: string, attachmentTitle: string) {
    let formData = new FormData();
    formData.append("mediaId", mediaId || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "WhatsappApi/downloadMedia"
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.downloadAttachment(res?.link, attachmentTitle);
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  declineAttachment(itemIndex) {
    this.uploadedFiles = this.uploadedFiles.filter(
      (el, index) => itemIndex != index
    );
    if (this.uploadedFiles.length) {
      this.attachmentSelected = this.uploadedFiles[0];
    } else {
      this.onCloseAttachPreview();
    }
  }

  onCloseAttachPreview() {
    this.isUploadedAttachment = false;
    this.showAttachImgPreview = false;
    this.attachImgPreview = "";
    this.attachmentSelected;
    this.attachmentSelectedName = "";
    this.attachmentSelectedSize = "";
    this.attachmentSelectedType = "";
    this.sendAttachmentMsg = "";
    this.uploadedFiles = [];
  }

  // send contact
  onSendContact() {
    const dialogRef = this.dialog.open(WContactsModalComponent, {
      autoFocus: false,
      width: "430px",
      data: {
        type: "send_contact",
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.sendContactMessage(res);
      }
    });
  }

  sendContactMessage(contact) {
    let formData = new FormData();
    formData.append("waContactListContactPerson", contact?.contact_type || "");
    formData.append("waContactListReceiverId", this.chatData?.id || "");
    formData.append("waContactListToNum", contact?.contact?.number || "");
    this.ds.post("WhatsappApi/sendContact", formData).subscribe(
      (res) => {
        if (res?.status) {
          this.getChatMessages(this.chatData?.id, "noLoadingMessages");
        } else {
          this.sweetAlert.errorToast(res?.error, 3000);
        }
      },
      (err) => {
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          3000
        );
      }
    );
  }

  onOpenChat(message) {
    this.chatService.selectedChat.next({
      id: message?.contact_id,
      name: "",
      number: message?.all?.wa_cus_no,
      pic: "",
      lastSeen: "",
      messages: [],
      contact_id: "",
    });
  }

  checkSendMsg() {
    this.ds
      .get(`WhatsappApi/contacts/${this.chatData.id}/check`)
      .subscribe((res) => {
        this.canText = res?.data;
      });
  }

  //ui logic
  scrollToBottom() {
    const chatViewBody = document.getElementById("chatViewBody");
    setTimeout(() => {
      chatViewBody.scrollTop = chatViewBody.scrollHeight;
    }, 200);
  }

  //socket logic
  listenToMessages() {
    this.whatsappSocketService
      .listenToMessages(this.chatData.id)
      .subscribe((res) => {
        console.log("message socket result: ", res);
        if (res?.type == "add_message") {
          this.messages.push(res?.content);
          this.scrollToBottom();
          this.checkSendMsg();
        }
      });
  }
  createContact(employee_number, branch_id) {
    let formData = new FormData();
    formData.append("employee_number", employee_number);
    if (employee_number != null) {
      this.ds.post("WhatsappApi/create_contact_from_hr", formData).subscribe(
        (res) => {
          if (res.status) {
            this.resfreshview_details.emit();
          } else {
            this.ds.dialogf("", res.error);
          }
        },
        (err) => {
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
    } else {
      this.sweetAlert.errorToast(
        this.lang.transform("lang_please_type_msg"),
        3000
      );
    }
  }
  get_viewers(message, temp) {
    this.msg_histories_id = message?.id;
    let formData = new FormData();
    formData.append("contact_id", this.chatData?.id);
    formData.append("message_id", message?.id);
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "WhatsappApi/view_msg_histories/" + this.page_no + "/" + this.page_size
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.viewers_list = res?.records;
            this.total_records = res?.page?.total_records;
            this.openModal(temp);
          } else {
            this.viewers_list = [];
            this.total_records = 0;
            this.sweetAlert.errorToast(res?.error, 2000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.viewers_list = [];
          this.total_records = 0;
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
  }
  get_viewers_pagination() {
    let formData = new FormData();
    formData.append("contact_id", this.chatData?.id);
    formData.append("message_id", this.msg_histories_id);
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "WhatsappApi/view_msg_histories/" + this.page_no + "/" + this.page_size
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.viewers_list = res?.records;
            this.total_records = res?.page?.total_records;
          } else {
            this.viewers_list = [];
            this.total_records = 0;
            this.sweetAlert.errorToast(res?.error, 2000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.viewers_list = [];
          this.total_records = 0;
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
  }
  getAllContacts(contact_id) {
    let formData = new FormData();
    formData.append("contact_id", contact_id);
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "WhatsappApi/getAllContacts"
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.chat = res?.contactList[0];
            this.openRecentChat(this.chat);
            this.initInputValues();
            this.chatData = this.chat;
            this.canText = this.chatData?.canText;
            this.getChatMessages(this.chatData?.id, "LoadingMessages");
            this.isChatView = true;
            this.listenToMessages();
            this.checkSendMsg();
          } else {
            this.sweetAlert.errorToast(res?.error, 2000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      );
  }
  ngOnDestroy(): void {
    this.chatService.selectedChat.next(null);
  }
}
