import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-journal-list',
  templateUrl: './journal-list.component.html',
  styleUrls: ['./journal-list.component.scss']
})
export class JournalListComponent implements OnInit ,OnDestroy {
  branches: any = [];
  jrnData: any = [];
  finances: any = [];
  branch_id: any = (localStorage.getItem('selectedBranch') != null) ? localStorage.getItem('selectedBranch') : '0';
  lodingdatas = this.lang.transform('lang_loading');  
  jrnpage_no:any    = localStorage.getItem('AC_JR_P') || 1;
  jrnpage_size:any  = localStorage.getItem('AC_JR_S') || '10';
  finance_id: any   = localStorage.getItem('AC_JR_S_F') || null;
  searchKey:any     = localStorage.getItem('AC_JR_S_K') || "";
  constructor(public ds:DataService,public route : ActivatedRoute, public datePipe: DatePipe,public router : Router, public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != '0') {
      this.load_financial_years();
    }
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public selectBrach(branch) {
    if (branch && branch != '0' ) {
      localStorage.setItem('selectedBranch', branch);
      this.branch_id = branch;
      this.load_financial_years();
    } else{
       this.spinner.hide();
    }
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
        this.ds.getActionByUrl([], 'ab/accounts_view').subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        }, error => {
          this.spinner.hide();
        })      
      )

  }
  public load_financial_years() {
    this.finances = [];
    this.finance_id = null;
    this.subscriptions.add(
        this.ds.getActionByUrl([], 'financial/' + this.branch_id).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
              }
            });
          }
          this.load_journal_vouchers();
        }, error => {
          this.spinner.hide();
        })      
      )

  }
  public loadPageJrn(page) {
    this.spinner.show();
    this.jrnpage_no = page;
    this.jrnpage_size = this.jrnpage_size;
    this.load_journal_vouchers();
  }
  public loadJrnPagesize(size) {
    this.spinner.show();
    this.jrnpage_no = 1;
    this.jrnpage_size = size;
    this.load_journal_vouchers();
  }
  public search_journals() {
    this.jrnpage_no = 1;
    this.jrnpage_size = '10';
    this.load_journal_vouchers();
  }
  public load_journal_vouchers() {
    //Accounts Journal Voucher
		localStorage.setItem('AC_JR_S_K', this.searchKey);
		localStorage.setItem('AC_JR_S_F', this.finance_id);
		localStorage.setItem('AC_JR_P', this.jrnpage_no);
		localStorage.setItem('AC_JR_S', this.jrnpage_size);
    let formdata = new FormData();
    this.branch_id = (this.branch_id) ? this.branch_id : 0;
    formdata.append('search_key', this.searchKey);
    formdata.append('finance_id', this.finance_id || "");
    this.subscriptions.add(
        this.ds.postActionByUrl(formdata, 'listjournal/' + this.branch_id + '/' + this.jrnpage_no + '/' + this.jrnpage_size).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.jrnData = res;
          } else {
            this.jrnData = [];
            this.lodingdatas = res.error;
          }
        }, error => {
          this.spinner.hide();
            this.jrnData = [];
            this.lodingdatas = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
        })      
      )

  }
  public remove_journal(data) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    this.subscriptions.add(
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.spinner.show();
            this.subscriptions.add(
            this.ds.deleteActionByUrl([], 'deletejournal/' + this.branch_id + '/' + data.transaction_id + '/' + data.journal_voucher_id).subscribe(
                    (data) => {
                      this.spinner.hide();
                      if (data.status) {
                        this.load_journal_vouchers();
                        this.ds.dialogf('',data.msg);
                      } else {
                        this.ds.dialogf('',data.error);
                      }
                    },
                    (error) => {
                      this.spinner.hide();
                      this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                    }
                  )
              )
           ;
          }
        })      
      )
;
  }
}