import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AddUpdateStageComponent } from "./add-update-stage/add-update-stage.component";

@Component({
	selector: "app-design-stages",
	templateUrl: "./design-stages.component.html",
	styleUrls: ["./design-stages.component.scss"],
})
export class DesignStagesComponent implements OnInit {
	@Input() project_data;
	@Input() design_data;
	@Input() service_data;
	@Input() filelevels;
	@Input() able2add;
	@Input() able2edit;
	@Input() able2delete;
	@Input() isQuotationExists;
	stagestList = [];
	pageno = 1;
	pageSize = "10";
	stagesCollectionSize = 0;
	activeContractId;
	modalRef: BsModalRef;
	taskStageForm: FormGroup;
	taskList = [];
	stageTasks = [];
	constructor(
		public ds: DataService,
		private lang: LanguagePipe,
		private dialog: MatDialog,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		public modalService: BsModalService,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.getActiveContract();
		this.taskStageForm = this.fb.group({
			stage_id: "",
			task_id: null,
		});
	}

	getActiveContract() {
		this.spinner.show();
		let formData = new FormData();
		formData.append(
			"project_design_id",
			this.design_data?.project_design_id || ""
		);
		this.ds.post("project_design/get_row_active_contract", formData).subscribe(
			(res: any) => {
				this.spinner.hide();
				if (res?.status) {
					if (res?.message?.contract_id) {
						this.activeContractId = res?.message?.contract_id;
						this.getStages(res?.message?.contract_id);
					}
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	getStages(contract_id) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("contract_id", contract_id || "");
		this.ds
			.post(
				`project_design/get_rows_stage_by_contract/${this.pageno}/${this.pageSize}`,
				formData
			)
			.subscribe(
				(res: any) => {
					this.spinner.hide();
					if (res?.status) {
						this.stagestList = res?.data;
						this.stagesCollectionSize = res?.data?.length;
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}
	onAddUpdateStage(data?) {
		const dialogRef = this.dialog.open(AddUpdateStageComponent, {
			width: "700px",
			data: {
				stage: data,
				contract_id: this.activeContractId,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.getStages(this.activeContractId);
			}
		});
	}
	deleteStage(stage_id) {
		let formData = new FormData();
		formData.append("stage_id", stage_id || "");
		this.spinner.show();
		this.ds.post("project_design/delete_stage_row", formData).subscribe(
			(res: any) => {
				this.spinner.hide();
				if (res?.status) {
					this.getStages(this.activeContractId);
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

	onAddTaskToStage(item, template) {
		this.getTaskList();
		this.taskStageForm.reset();
		this.taskStageForm.get("stage_id").setValue(item?.contract_stage_id);
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}

	getTaskList() {
		let formData = new FormData();
		formData.append(
			"work_area_id",
			this.design_data?.projects_work_area_id || ""
		);
		formData.append("start_date_task", "");
		formData.append("end_date_task", "");
		formData.append("start_date_ticket", "");
		formData.append("end_date_ticket", "");
		this.taskList = [];
		this.spinner.show();
		this.ds
			.post("project_design/get_data_task_by_work_area", formData)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.taskList = res?.data;
					} else {
						this.taskList = [];
						this.sweetAlert.errorToast(res?.error, 3000);
					}
				},
				(err) => {
					this.taskList = [];
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
	}

	submitTaskToStage() {
		let formData = new FormData();
		formData.append("stage_id", this.taskStageForm.get("stage_id").value || "");
		formData.append("task_id", this.taskStageForm.get("task_id").value || "");
		this.spinner.show();
		this.ds.post("project_design/add_stage_task_mulit", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.modalRef.hide();
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}

	getStageTasks(stage_id, template) {
		let formData = new FormData();
		formData.append("stage_id", stage_id || "");
		this.spinner.show();
		this.ds.post("project_design/get_stage_task_mulit", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.stageTasks = res?.data;
					this.modalRef = this.modalService.show(
						template,
						environment.modelconfig
					);
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
