import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { MbscDatepicker } from "@mobiscroll/angular";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Checklist } from "app/routes/tasks/models/checklist";
import { Task } from "app/routes/tasks/models/task";
import { TaskMembers } from "app/routes/tasks/models/task-members";
import { TicketSocket } from "app/routes/tasks/ticketSocket.service";
import { UserGroupComponent } from "app/routes/tasks/user-group/user-group.component";
import * as moment from "moment";
import { EditCommentComponent } from "../../edit-comment/edit-comment.component";
import { TicketTaskUsersComponent } from "../../ticket-task-users/ticket-task-users.component";

const DragConfig = {
	dragStartThreshold: 0,
	pointerDirectionChangeThreshold: 5,
	zIndex: 10000,
};
@Component({
	selector: "app-view-task-full",
	templateUrl: "./view-task-full.component.html",
	styleUrls: ["./view-task-full.component.scss"],
})
export class ViewTaskFullComponent implements OnInit {
	// public Editor = ClassicEditor;
	lang_key = localStorage.getItem("lang_key") || "en";
	userId = this.localstorageService.getItem("user_id");
	user_avatar = this.localstorageService.getItem("user_avatar");
	baseUrl = environment.SERVER_ORIGIN;
	ticketID = this.route.snapshot.paramMap.get("ticket_id");
	task_id = this.route.snapshot.paramMap.get("task_id");
	task: Task;
	task_members: TaskMembers[];
	task_members_full_data: any;
	memberCardData: TaskMembers;
	task_dates: any = [];
	task_reminder: any;
	range = new FormGroup({
		start: new FormControl(null, Validators.required),
		end: new FormControl(null, Validators.required),
	});
	point_date_range = new FormGroup({
		start: new FormControl(null, Validators.required),
		end: new FormControl(null, Validators.required),
	});
	add_checklist = "";
	search_point_users = [];
	add_check_point_users = [];
	add_point_attachfile = new FormControl(null);
	add_point_title: string = this.lang.transform("lang_select_files");
	checklist_arr: Array<Checklist> = [];
	task_members_selected = [];
	task_members_list = [];
	task_attachments = [];
	taskAttachmentsSlice = 2;
	attachTitle = "";
	attachFile = "";
	isAddAttach = false;
	isCommentsLoading = false;
	taskComments = [];
	write_comment = {
		description: "",
	};
	write_reply = [];
	timelineData = [];
	checklist_editable = [];
	task_status_done = null;
	status_done_list = [];
	@ViewChild("task_datesPicker") task_datesPicker: MbscDatepicker;
	@ViewChild("task_reminderPicker") task_reminderPicker: MbscDatepicker;
	autoButtons = [
		{
			text: this.lang.transform("lang_save"),
			handler: () => {
				this.changeTaskDates();
			},
		},
	];
	reminderButtons = [
		{
			text: this.lang.transform("lang_save"),
			handler: () => {
				this.changeTaskReminder();
			},
		},
	];
	checkIsDisabled = false;
	group_id: any;
	group_type_id: any;
	selectedPointForView: any;
	showPointView = false;
	selectedPointForUpdate: any;
	parent_check_id_update: any;
	showPointAdd = false;
	showPointUpdate = false;
	showMeetings = false;
	constructor(
		private spinner: PreloaderService,
		private ds: DataService,
		private localstorageService: LocalStorage,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private ticketSocketService: TicketSocket,
		private dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.task = {
			task_id: "",
			title: "",
			task_status: "",
			task_time: "",
			task_status_name: "",
			description: "",
			start_date: "",
			end_date: "",
			user_add_id: "",
			task_progress: "",
			ticket_no: "",
			ticket_id: "",
			full_task_number: "",
			task_no: "",
		};
		this.getFormLists();
		this.getTaskData();
		this.getTaskMembers();
		this.getChecklist();
		this.getTaskComments();
		this.getTimelineData();
		//listen task comments
		this.listenTaskComments();
		this.listenTaskAttachments();
		this.listenTaskTimeline();
		this.listenDateChanges();
		this.listenMembersChanges();
		this.listenChecklistsChanges();
	}

	ngOnInit(): void {
		moment.locale("en");
	}
	getFormLists() {
		this.ds.post("tasks/get_add_task", {}).subscribe((res) => {
			if (res?.status) {
				this.status_done_list = res?.data?.task_status_done;
			} else {
				this.status_done_list = [];
			}
		});
	}
	//get task data
	getTaskData() {
		this.spinner.show();
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		this.ds.post("tasks/get_task_only", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.task.task_id = res?.data?.task_id;
					this.task.title = res?.data?.title;
					this.task.description = res?.data?.task_detailes;
					this.task.task_status = res?.data?.task_status;
					this.task.task_time = res?.data?.task_time;
					this.task.start_date = res?.data?.start_date;
					this.task.end_date = res?.data?.end_nearly;
					this.task.user_add_id = res?.data?.user_add_id;
					this.task.task_progress = res?.data?.progres;
					this.task.ticket_no = res?.data?.ticket_no;
					this.task.ticket_id = res?.data?.ticket_id;
					this.task.full_task_number = res?.data?.full_task_number;
					this.task.task_no = res?.data?.task_no;
					this.task_attachments = res?.data?.files;
					this.task_status_done = res?.data?.task_status_done;
					this.group_id = res?.data?.group_id;
					this.group_type_id = res?.data?.group_type_id;
					this.task_dates = [];
					if (res?.data?.start_date) {
						this.task_dates[0] = new Date(res?.data?.start_date);
					}
					if (res?.data?.end_nearly) {
						this.task_dates[1] = new Date(res?.data?.end_nearly);
					}
					if (res?.data?.reminder_date) {
						this.task_reminder = new Date(res?.data?.reminder_date);
					}
					switch (res?.data?.task_status) {
						case "1": {
							this.task.task_status_name = "New";
							break;
						}
						case "2": {
							this.task.task_status_name = "In Progress";
							break;
						}
						case "3": {
							this.task.task_status_name = "Confirm";
							break;
						}
						case "4": {
							this.task.task_status_name = "Done";
							break;
						}
					}
					this.ds.taskDates.next({
						startDate: this.task.start_date,
						endDate: this.task.end_date,
					});
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
					this.router.navigate([`/tasks/view/${this.ticketID}`]);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
				this.router.navigate([`/tasks/view/${this.ticketID}`]);
			}
		);
	}
	//get task members
	getTaskMembers() {
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		this.ds.post("tasks/get_emp_in_task", formData).subscribe(
			(res) => {
				this.task_members_full_data = res?.data;
				this.task_members = res?.data?.map((el) => {
					//set selected task members
					this.task_members_selected.push(el?.emp_id);
					this.task_members_list.push({
						label: el?.emp_name,
						value: el?.emp_id,
					});
					return {
						firstName:
							(this.lang_key == "ar"
								? el?.firstname_arabic
								: el?.firstname_english) || "Almnabr",
						lastName:
							(this.lang_key == "ar"
								? el?.lastname_arabic
								: el?.lastname_english) || "User",
						userName: el?.emp_name,
						userId: el?.emp_id,
						profile_image_64: el?.profile_image_64,
					};
				});
			},
			(err) => {}
		);
	}
	onShowAddMember() {
		this.task_members_selected = [];
		this.task_members_list = [];
		this.task_members?.map((el) => {
			//set selected task members
			this.task_members_selected.push(el?.userId);
			this.task_members_list.push({
				label: el?.userName,
				value: el?.userId,
			});
		});
	}

	onShowMemberCard(member) {
		this.memberCardData = {
			firstName: member?.firstName || "Almnabr",
			lastName: member?.lastName || "User",
			userName: member?.userName || member?.username,
			userId: member?.userId || member?.user_id,
			profile_image_64: member?.profile_image_64,
		};
	}
	//get checklist
	getChecklist() {
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		this.ds.post("tasks/get_points_task_main", formData).subscribe(
			(res) => {
				if (res?.status) {
					this.checklist_arr = res?.data?.map((el) => {
						return {
							id: el?.check_id,
							title: el?.title,
							progress: el?.progres,
							chicklist_items: el?.sub_checks?.map((item) => {
								return {
									...item,
									show_details: false,
									checked: item?.is_done == "2" ? true : false,
								};
							}),
							is_add: false,
							user_add_id: el?.user_add_id,
							hideChecked: true,
							char_item: el?.char_item,
						};
					});
					this.buildChecklistEditable();
				} else {
					this.checklist_arr = [];
					this.buildChecklistEditable();
				}
			},
			(err) => {
				this.checklist_arr = [];
				this.buildChecklistEditable();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	//start add check list
	onAddChecklist() {
		if (this.add_checklist) {
			let formData = new FormData();
			formData.append("task_id", this.task_id || "");
			formData.append("title", this.add_checklist || "");
			this.ds.post("tasks/insert_task_points_main", formData).subscribe(
				(res) => {
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform(res?.message),
							2000
						);
					} else {
						this.sweetAlert.errorToast(this.lang.transform(res?.error), 2000);
					}
				},
				(err) => {
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
			this.add_checklist = "";
		}
	}
	//end add check list
	deleteChecklist(checklist_id) {
		let formData = new FormData();
		formData.append("point_id", checklist_id || "");
		this.sweetAlert
			.confirmMessage("Are you sure?", "Yes, Delete")
			.then((confirmRes) => {
				if (confirmRes.isConfirmed) {
					this.spinner.show();
					this.ds.post("tasks/delete_task_point_main", formData).subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert.successToast(
									this.lang.transform(res?.message),
									2000
								);
							} else {
								this.sweetAlert.errorToast(
									this.lang.transform(res?.error),
									2000
								);
							}
						},
						(err) => {
							this.spinner.hide();
							this.sweetAlert.errorToast(
								this.lang.transform("lang_internal_server_error"),
								2000
							);
						}
					);
				}
			});
	}
	//delete check list item
	deleteChecklistItem(point_id) {
		let formData = new FormData();
		formData.append("point_id", point_id || "");
		this.sweetAlert
			.confirmMessage("Are you sure?", "Yes, Delete")
			.then((confirmRes) => {
				if (confirmRes.isConfirmed) {
					this.spinner.show();
					this.ds.post("tasks/delete_task_point", formData).subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert.successToast(
									this.lang.transform(res?.message),
									2000
								);
							} else {
								this.sweetAlert.errorToast(
									this.lang.transform(res?.error),
									2000
								);
							}
						},
						(err) => {
							this.spinner.hide();
							this.sweetAlert.errorToast(
								this.lang.transform("lang_internal_server_error"),
								2000
							);
						}
					);
				}
			});
	}
	//end add check list item
	//start change check list item
	onChangePoint(event, check_item_id, check_main_index, check_sub_index) {
		let oldStatus = !event?.checked;
		this.checkIsDisabled = true;
		let formData = new FormData();
		formData.append("point_id", check_item_id || "");
		this.ds.post("tasks/change_task_point", formData).subscribe(
			(res) => {
				setTimeout(() => {
					this.checkIsDisabled = false;
				}, 1000);
				if (res?.status) {
					this.sweetAlert.successToast(this.lang.transform(res?.message), 2000);
				} else {
					this.sweetAlert.errorToast(this.lang.transform(res?.error), 2000);
					this.checklist_arr[check_main_index].chicklist_items[
						check_sub_index
					].checked = oldStatus;
				}
			},
			(err) => {
				setTimeout(() => {
					this.checkIsDisabled = false;
				}, 1000);
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
				this.checklist_arr[check_main_index].chicklist_items[
					check_sub_index
				].checked = oldStatus;
			}
		);
	}
	//start on change checklist title.
	onChangeTCTitle(event, id: string, type: string) {
		let end_point = "",
			formData = new FormData();
		if (type == "checklist" && event?.target?.value) {
			end_point = "tasks/update_task_point_main";
			formData.append("point_id", id || "");
			formData.append("title", event?.target?.value || "");
			this.ds.post(end_point, formData).subscribe((res) => {});
		} else if (type == "checklist_item" && event?.target?.value) {
			end_point = "tasks/update_task_point";
			formData.append("point_id", id || "");
			formData.append("notes", event?.target?.value || "");
			this.ds.post(end_point, formData).subscribe((res) => {});
		}
	}
	buildChecklistEditable() {
		this.checklist_editable = [];
		for (let i = 0; i < this.checklist_arr?.length; i++) {
			this.checklist_editable.push({ edit: false, items: [] });
			if (this.checklist_arr[i]?.chicklist_items.length) {
				for (let item of this.checklist_arr[i]?.chicklist_items) {
					this.checklist_editable[i]?.items.push({ edit: false });
				}
			}
		}
	}
	onEditableChecklist(checklistIndex, inputId, type, checklistItemIndex?) {
		if (type == "checklist") {
			this.checklist_editable[checklistIndex].edit = true;
		} else if (type == "checklist_item") {
			this.checklist_editable[checklistIndex].items[checklistItemIndex].edit =
				true;
		}
		setTimeout(() => {
			document.getElementById(inputId).focus();
		}, 200);
	}
	//on add file point
	uploadPointFile(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			const fileid = event.target.files[0];
			this.add_point_title = fileid.name;
			this.add_point_attachfile.setValue(fileid);
		}
	}
	//start on change task Members
	onChangeTaskMembers() {
		if (this.task_members_selected && this.task_members_selected?.length) {
			let formData = new FormData();
			formData.append("task_id", this.task_id || "");
			formData.append("emp_id", this.task_members_selected.toString() || "");
			this.spinner.show();
			this.ds.post("tasks/add_emp_on_task", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_success"),
							2000
						);
						this.task_members = this.task_members_list.map((el) => {
							return {
								firstName: null,
								lastName: null,
								userName: el?.label,
								userId: el?.value,
								profile_image_64: null,
							};
						});
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
				}
			);
		} else {
			this.sweetAlert.errorToast("Task Members Required!", 2000);
		}
	}
	//end on change task Members
	//start on add task attachment
	upload_task_attachment(files) {
		if (files && files?.length) {
			let formData = new FormData();
			formData.append("task_id", this.task_id || "");
			formData.append(
				"attachments[0][attach_title]",
				files[0]?.name?.split(".")[0] || ""
			);
			formData.append("attachments[0][file]", files[0] || "");
			this.spinner.show();
			this.ds.post("tasks/add_files_in_task", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast("File Added Successfully", 2000);
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_error_server"),
						2000
					);
				}
			);
		}
		document.getElementById("taskAttachmentInput")["value"] = "";
	}
	//end on add task attachment
	//start downloadFile (task attachments)
	// downloadFile(file_url, title?) {
	// 	this.spinner.show();
	// 	this.ds.get(file_url).subscribe(
	// 		(res) => {
	// 			this.spinner.hide();
	// 			if (res?.status) {
	// 				this.ds.downloadFile(res, title);
	// 			}
	// 		},
	// 		(err) => {
	// 			this.spinner.hide();
	// 		}
	// 	);
	// }
	//end downloadFile (task attachments)
	onAddAttachment() {
		if (
			this.attachTitle &&
			this.attachFile &&
			this.attachFile.startsWith("https://drive.almnabr.com")
		) {
			let formData = new FormData();
			formData.append("task_id", this.task_id || "");
			formData.append("attachments[0][attach_title]", this.attachTitle || "");
			formData.append("attachments[0][file]", this.attachFile || "");
			this.spinner.show();
			this.ds.post("tasks/add_files_in_task", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_success"),
							2000
						);
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_error_server"),
						2000
					);
				}
			);
			this.attachTitle = "";
			this.attachFile = "";
			this.isAddAttach = false;
		} else {
			this.sweetAlert.errorToast(
				this.lang.transform("lang_field_required"),
				3000
			);
		}
	}
	//open attachment in new tab
	navigateNewTab(url) {
		window.open(url, "_blank");
	}
	//start delete ticket attachment
	onDeleteAttachment(file_id) {
		let formData = new FormData();
		formData.append("file_id", file_id || "");
		this.sweetAlert
			.confirmMessage("Are you sure?", "Yes, Delete")
			.then((confirmRes) => {
				if (confirmRes?.isConfirmed) {
					this.spinner.show();
					this.ds.post("tasks/delete_file_task", formData).subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert.successToast("File Deleted Successfully", 2000);
							} else {
								this.sweetAlert.errorToast(res?.error, 2000);
							}
						},
						(err) => {
							this.spinner.hide();
							this.sweetAlert.errorToast(
								this.lang.transform("lang_internal_error_server"),
								2000
							);
						}
					);
				}
			});
	}
	//end delete ticket attachment
	//search users
	public search_users(key) {
		this.task_members_list = [];
		this.search_point_users = [];
		if (key && key.term) {
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.task_members_list = res.list;
						this.search_point_users = res.list;
					}
				});
		}
	}

	//get task comments
	getTaskComments() {
		this.isCommentsLoading = true;
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		this.ds.post(`tasks/get_comment_tasks`, formData).subscribe(
			(res) => {
				this.isCommentsLoading = false;
				if (res?.status) {
					this.taskComments = res?.data;
					this.write_comment.description = "";
					this.buildReplysInputs();
				} else {
					this.taskComments = [];
					this.buildReplysInputs();
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_error_server"),
					2000
				);
			}
		);
	}
	//add comment
	onAddComment(element?) {
		if (this.write_comment.description) {
			let formData = new FormData();
			formData.append("task_id", this.task_id || "");
			formData.append("notes", this.write_comment.description || "");
			this.ds.post("tasks/add_comment_task", formData).subscribe(
				(res) => {
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_comment_added_successfully"),
							1000
						);
						this.buildReplysInputs();
						this.write_comment.description = "";
					} else {
						this.sweetAlert.errorMessage(this.lang.transform(res?.error));
						this.write_comment.description = "";
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_error_server"),
						2000
					);
				}
			);
		}
		this.write_comment.description = "";
		this.onWriteCommentFocusout();
		this.buildReplysInputs();
		if (element) {
			element.style.height = "5px";
		}
	}
	//delete comment
	deleteComment(comment) {
		let formData = new FormData();
		formData.append("comment_id", comment?.history_id || "");
		this.sweetAlert
			.confirmMessage("Are you sure?", "Yes, Delete")
			.then((confirmRes) => {
				if (confirmRes.isConfirmed) {
					this.spinner.show();
					this.ds.post("tasks/delete_comment_reply", formData).subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert.successToast(
									this.lang.transform("lang_comment_deleted_successfully"),
									1000
								);
								this.buildReplysInputs();
							} else {
								this.sweetAlert.errorMessage(this.lang.transform(res?.error));
							}
						},
						(err) => {
							this.spinner.hide();
							this.sweetAlert.errorToast(
								this.lang.transform("lang_internal_error_server"),
								2000
							);
						}
					);
				}
			});
	}
	//edit comment
	onUpdateComment(comment, type) {
		const dialogRef = this.dialog.open(EditCommentComponent, {
			width: "800px",
			data: { content: comment, type: type },
		});
		dialogRef.afterClosed().subscribe((res) => {});
	}
	//get ticket comments
	buildReplysInputs() {
		this.write_reply = [];
		for (let item of this.taskComments) {
			this.write_reply.push({
				description: "",
			});
		}
	}
	//add reply
	onAddReply(index, comment_id) {
		if (this.write_reply[index].description) {
			let formData = new FormData();
			formData.append("task_id", this.task_id || "");
			formData.append("comment_id", comment_id || "");
			formData.append("notes", this.write_reply[index].description || "");
			this.ds.post("tasks/add_reply_task", formData).subscribe(
				(res) => {
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_comment_added_successfully"),
							1000
						);
						this.write_reply[index].description = "";
					} else {
						this.sweetAlert.errorMessage(this.lang.transform(res?.error));
						this.write_reply[index].description = "";
					}
				},
				(err) => {
					this.spinner.hide();
				}
			);
			this.taskComments[index].show_replys = true;
			this.onWriteReplyFocusout(index);
		}
	}
	//delete reply
	deleteReply(reply) {
		let formData = new FormData();
		formData.append("comment_id", reply?.history_id || "");
		this.sweetAlert
			.confirmMessage("Are you sure?", "Yes, Delete")
			.then((confirmRes) => {
				if (confirmRes.isConfirmed) {
					this.spinner.show();
					this.ds.post("tasks/delete_comment_reply", formData).subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert.successToast(
									this.lang.transform("lang_reply_deleted_successfully"),
									1000
								);
							} else {
								this.sweetAlert.errorMessage(this.lang.transform(res?.error));
							}
						},
						(err) => {
							this.spinner.hide();
							this.sweetAlert.errorToast(
								this.lang.transform("lang_internal_error_server"),
								2000
							);
						}
					);
				}
			});
	}
	//get timeline data
	getTimelineData() {
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		formData.append("type", "2");
		this.ds.post(`tasks/time_line_task/desc`, formData).subscribe(
			(res) => {
				if (res?.status) {
					this.timelineData = res?.data;
				} else {
					this.timelineData = [];
				}
			},
			(err) => {
				this.timelineData = [];
			}
		);
	}

	//change task dates
	changeTaskDates() {
		let start_data_formatted: any, end_data_formatted: any;
		if (this.task_dates) {
			start_data_formatted = moment(this.task_dates[0]).format(
				"YYYY/MM/DD HH:mm:ss"
			);
			end_data_formatted = moment(this.task_dates[1]).format(
				"YYYY/MM/DD HH:mm:ss"
			);
		}
		if (start_data_formatted && end_data_formatted) {
			const difference_In_Time =
				this.task_dates[1].getTime() - this.task_dates[0].getTime();
			const difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
			let formData = new FormData();
			formData.append("task_id", this.task_id || "");
			formData.append("task_time", Math.ceil(difference_In_Days) + "" || "");
			formData.append("start_date", start_data_formatted || "");
			formData.append("end_date", end_data_formatted || "");
			this.spinner.show();
			this.ds.post("tasks/update_start_end_task", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_success"),
							2000
						);
						this.task_datesPicker.close();
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
		}
	}
	//change task dates
	changeTaskReminder() {
		let date_formatted: any;
		if (this.task_reminder) {
			date_formatted = moment(this.task_reminder).format("YYYY/MM/DD HH:mm:ss");
		}
		if (date_formatted) {
			let formData = new FormData();
			formData.append("task_id", this.task_id || "");
			formData.append("reminder_date", date_formatted || "");
			this.spinner.show();
			this.ds.post("tasks/update_reminder_date_task", formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_success"),
							2000
						);
						this.task_reminderPicker.close();
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
		}
	}
	//delete task
	deleteTask() {
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		this.sweetAlert
			.confirmMessage("Are you sure?", "Yes, delete")
			.then((confirmRes) => {
				if (confirmRes.isConfirmed) {
					this.spinner.show();
					this.ds.post("tasks/delete_task", formData).subscribe(
						(res) => {
							this.spinner.hide();
							if (res?.status) {
								this.sweetAlert.successToast(
									"Task deleted successfully.",
									2000
								);
							} else {
								this.spinner.hide();
								this.sweetAlert.errorToast(res?.error, 2000);
							}
						},
						(err) => {
							this.spinner.hide();
							this.sweetAlert.errorToast(
								this.lang.transform("lang_internal_server_error"),
								2000
							);
						}
					);
				}
			});
	}

	//start listen on comments
	listenTaskComments() {
		this.ticketSocketService
			.listenToTaskComments(this.ticketID, this.task_id)
			.subscribe((res) => {
				if (res?.type == "add_comment") {
					this.taskComments.unshift(res?.content);
					this.buildReplysInputs();
				} else if (res?.type == "edit_comment") {
					const commentIndex = this.taskComments.findIndex(
						(el) => el?.history_id == res?.content?.history_id
					);
					this.taskComments[commentIndex].notes_history =
						res?.content?.notes_history;
				} else if (res?.type == "delete_comment") {
					const commentIndex = this.taskComments.findIndex(
						(el) => el?.history_id == res?.content?.history_id
					);
					this.taskComments.splice(commentIndex, 1);
					this.buildReplysInputs();
				} else if (res?.type == "add_reply") {
					const commentIndex = this.taskComments.findIndex(
						(el) => el?.history_id == res?.content?.comment_id
					);
					if (this.taskComments[commentIndex]?.reply) {
						this.taskComments[commentIndex]?.reply?.unshift(
							res?.content?.reply
						);
					} else {
						this.taskComments[commentIndex].reply = [res?.content?.reply];
					}
				} else if (res?.type == "edit_reply") {
					const commentIndex = this.taskComments.findIndex(
						(el) => el?.history_id == res?.content?.comment_id
					);
					const replyIndex = this.taskComments[commentIndex]?.reply?.findIndex(
						(el) => el?.history_id == res?.content?.reply?.history_id
					);
					this.taskComments[commentIndex].reply[replyIndex].comment_content =
						res?.content?.reply?.comment_content;
				} else if (res?.type == "delete_reply") {
					const commentIndex = this.taskComments.findIndex(
						(el) => el?.history_id == res?.content?.comment_id
					);
					const replyIndex = this.taskComments[commentIndex]?.reply?.findIndex(
						(el) => el?.history_id == res?.content?.reply?.history_id
					);
					this.taskComments[commentIndex].reply.splice(replyIndex, 1);
				}
			});
	}
	//end listen on comments
	//start listen on Attachments
	listenTaskAttachments() {
		this.ticketSocketService
			.listenToTaskAttachments(this.ticketID, this.task_id)
			.subscribe((res) => {
				if (res?.type == "files_ticket") {
					this.task_attachments.unshift(res?.content);
				} else if (res?.type == "delete_attachment") {
					const attachmentIndex = this.task_attachments.findIndex(
						(el) => el?.file_records_id == res?.content?.file_records_id
					);
					this.task_attachments.splice(attachmentIndex, 1);
				}
			});
	}
	//end listen on Attachments
	//start listen on Timeline
	listenTaskTimeline() {
		this.ticketSocketService
			.listenToTaskTimeline(this.ticketID, this.task_id)
			.subscribe((res) => {
				this.timelineData.unshift(res?.content);
			});
	}
	//end listen on Timeline
	//start listen on task Date
	listenDateChanges() {
		this.ticketSocketService
			.listenToTaskDate(this.ticketID, this.task_id)
			.subscribe((res) => {
				this.task.start_date = res?.content?.start_date;
				this.task.end_date = res?.content?.end_date || res?.content?.end_nearly;
			});
	}
	//end listen on task Date
	//start listen on MembersChanges
	listenMembersChanges() {
		this.ticketSocketService
			.listenToTaskMembers(this.ticketID, this.task_id)
			.subscribe((res) => {
				this.task_members_selected = [];
				this.task_members_list = [];
				this.task_members = res?.content?.map((el) => {
					//set selected task members
					this.task_members_selected.push(el?.emp_id);
					this.task_members_list.push({
						label: el?.emp_name,
						value: el?.emp_id,
					});
					return {
						firstName:
							(this.lang_key == "ar"
								? el?.firstname_arabic
								: el?.firstname_english) || "Almnabr",
						lastName:
							(this.lang_key == "ar"
								? el?.lastname_arabic
								: el?.lastname_english) || "User",
						userName: el?.emp_name,
						userId: el?.emp_id,
					};
				});
			});
	}
	//end listen on MembersChanges
	//start listen on Checklists
	listenChecklistsChanges() {
		this.ticketSocketService
			.listenToTaskChecklists(this.ticketID, this.task_id)
			.subscribe((res) => {
				console.log(res);

				if (res?.type == "add_checklist") {
					let checklist = {
						id: `${res?.content?.check_id}`,
						title: res?.content?.title,
						progress: res?.content?.progres,
						chicklist_items: res?.content?.sub_checks
							? res?.content?.sub_checks?.map((item) => {
									return { ...item, show_details: false };
							  })
							: [],
						is_add: false,
						user_add_id: res?.content?.user_add_id,
						hideChecked: true,
						char_item: res?.content?.char_item,
					};
					this.checklist_arr.unshift(checklist);
				} else if (res?.type == "update_checklist") {
					let checklist_index = this.checklist_arr.findIndex(
						(el) => el?.id == res?.content?.check_id
					);
					let checklist = {
						id: `${res?.content?.check_id}`,
						title: res?.content?.title,
						progress: res?.content?.progres,
						chicklist_items: this.checklist_arr[checklist_index].chicklist_items
							? this.checklist_arr[checklist_index].chicklist_items?.map(
									(item) => {
										return { ...item, show_details: false };
									}
							  )
							: [],
						is_add: false,
						user_add_id: res?.content?.user_add_id,
						hideChecked: true,
						char_item: res?.content?.char_item,
					};
					this.checklist_arr[checklist_index] = checklist;
				} else if (res?.type == "delete_checklist") {
					const checklistIndex = this.checklist_arr.findIndex(
						(el) => `${el?.id}` == `${res?.content?.check_id}`
					);
					this.checklist_arr.splice(checklistIndex, 1);
				} else if (res?.type == "add_checklist_item") {
					const checklistIndex = this.checklist_arr.findIndex(
						(el) => `${el?.id}` == `${res?.content?.check_id}`
					);
					this.checklist_arr[checklistIndex].chicklist_items?.unshift({
						...res?.content?.check_item,
						show_details: false,
					});
				} else if (res?.type == "delete_checklist_item") {
					const checklistIndex = this.checklist_arr.findIndex(
						(el) => `${el?.id}` == `${res?.content?.check_id}`
					);
					const checklistItemIndex = this.checklist_arr[
						checklistIndex
					].chicklist_items?.findIndex(
						(el) => `${el?.check_id}` == `${res?.content?.check_item_id}`
					);
					this.checklist_arr[checklistIndex].chicklist_items?.splice(
						checklistItemIndex,
						1
					);
				} else if (res?.type == "update_checklist_item") {
					const checklistIndex = this.checklist_arr.findIndex(
						(el) => `${el?.id}` == `${res?.content?.check_id}`
					);
					const checklistItemIndex = this.checklist_arr[
						checklistIndex
					].chicklist_items?.findIndex(
						(el) => `${el?.check_id}` == `${res?.content?.check_item?.check_id}`
					);
					this.checklist_arr[checklistIndex].chicklist_items[
						checklistItemIndex
					] = { ...res?.content?.check_item, show_details: false };
				} else if (res?.type == "change_progress") {
					const checklistIndex = this.checklist_arr.findIndex(
						(el) => `${el?.id}` == `${res?.content?.check_id}`
					);
					this.checklist_arr[checklistIndex].progress =
						res?.content?.progres_point || "0";
					this.task.task_progress = res?.content?.progres_task || "0";
				}
				this.buildChecklistEditable();
			});
	}
	//end listen on Checklists

	//start auto sizing textarea of comment box
	autoResizeTxtarea(element) {
		element.style.height = "5px";
		element.style.height = element.scrollHeight + "px";
	}
	onWriteCommentFocusin() {
		document.querySelector(".tabwc-box").classList.add("is_focused");
	}
	onWriteCommentFocusout() {
		if (!this.write_comment.description) {
			document.querySelector(".tabwc-box").classList.remove("is_focused");
		}
	}
	//end auto sizing textarea of comment box
	//start auto sizing textarea of comment box
	onWriteReplyFocusin() {
		document.querySelector(".tabcrlwr-box").classList.add("is_focused");
	}
	onWriteReplyFocusout(index) {
		if (!this.write_reply[index].description) {
			document.querySelector(".tabcrlwr-box").classList.remove("is_focused");
		}
	}
	//end auto sizing textarea of comment box
	//start drag drop functionality
	drop(event: CdkDragDrop<any[]>, type: string, index?: number, id?: string) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			if (type == "checklist") {
				const ids = this.checklist_arr?.map((el) => el?.id)?.toString();
				this.arrangeChecklist(ids, type);
			} else if (type == "checklist_item") {
				const ids = this.checklist_arr[index]?.chicklist_items
					?.map((el) => el?.check_id)
					?.toString();
				this.arrangeChecklist(ids, type);
			}
		}
	}
	//end drag drop functionality
	//send checklist arrange
	arrangeChecklist(ids: string, type: string) {
		let formData = new FormData(),
			end_point = "";
		if (type == "checklist") {
			end_point = "tasks/update_order_points_main";
		} else if (type == "checklist_item") {
			end_point = "tasks/update_order_points_sub";
		}
		formData.append("task_id", this.task_id || "");
		formData.append("points", ids || "");
		this.ds.post(end_point, formData).subscribe((res) => {});
	}
	//change status done
	onChangeStatusDone() {
		let formData = new FormData();
		formData.append("task_id", this.task_id || "");
		formData.append("status", this.task_status_done || "");
		this.ds.post("tasks/change_status_done", formData).subscribe(
			(res) => {
				if (res?.status) {
					this.sweetAlert.successToast("Status changed successfully", 2000);
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(err) => {
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
	openChecklistDetails(item?, parent_check_id?) {
		this.selectedPointForUpdate = item;
		this.parent_check_id_update = parent_check_id;
		this.showPointView = false;
		this.showMeetings = false;
		if (item) {
			this.showPointAdd = false;
			this.showPointUpdate = true;
		} else {
			this.showPointUpdate = false;
			this.showPointAdd = true;
		}
	}
	viewChecklistDetails(item) {
		this.selectedPointForView = item;
		this.showPointAdd = false;
		this.showPointUpdate = false;
		this.showMeetings = false;
		this.showPointView = true;
	}
	startStopTimer(item, timerType, mainIndex, subIndex) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("point_id", item.check_id || "");
		this.ds.post("tasks/start_end_timer_check", formData).subscribe((res) => {
			this.spinner.hide();
			if (res?.status) {
				this.sweetAlert.successToast(this.lang.transform("lang_success"), 2000);
				if (timerType == "start") {
					this.checklist_arr[mainIndex].chicklist_items[
						subIndex
					].start_date_timer = moment().format("YYYY-MM-DD HH:mm:ss");
				} else if (timerType == "end") {
					this.checklist_arr[mainIndex].chicklist_items[
						subIndex
					].end_date_timer = moment().format("YYYY-MM-DD HH:mm:ss");
				}
			} else {
				this.sweetAlert.errorToast(res?.error, 2000);
			}
		});
	}

	navigateTo(url) {
		this.router.navigate([url]);
	}

	onShowTaskUsers() {
		const dialogRef = this.dialog.open(TicketTaskUsersComponent, {
			data: {
				users: this.task_members_full_data,
			},
			width: "700px",
		});
	}

	onAddUpdateGroupUser() {
		const dialogRef = this.dialog.open(UserGroupComponent, {
			width: "600px",
			data: {
				group_type_id: this.group_type_id,
				group_id: this.group_id,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res?.status && res) {
				this.group_type_id = res?.group_type_id;
				this.group_id = res?.group_id;
				res?.users?.map((el) => {
					//set selected task members
					this.task_members_selected = [];
					this.task_members_list = [];
					this.task_members_selected.push(el?.user_id);
					this.task_members_list.push({
						label:
							this.lang_key == "ar"
								? el?.firstname_arabic + " " + el?.lastname_arabic
								: el?.firstname_english + " " + el?.lastname_english,
						value: el?.user_id,
					});
					return {
						firstName:
							(this.lang_key == "ar"
								? el?.firstname_arabic
								: el?.firstname_english) || "Almnabr",
						lastName:
							(this.lang_key == "ar"
								? el?.lastname_arabic
								: el?.lastname_english) || "User",
						userName: el?.emp_name,
						userId: el?.user_id,
						profile_image_64: el?.profile_image_64,
					};
				});
			}
		});
	}

	//view point checklist
	onHidePointView(event) {
		if (event) {
			this.showPointView = false;
		}
	}
	//add point checklist
	onHidePointAdd(event) {
		if (event) {
			this.showPointAdd = false;
		}
	}
	//update point checklist
	onHidePointUpdate(event) {
		if (event) {
			this.showPointUpdate = false;
		}
	}
	//meetings
	onShowMeetings() {
		this.showPointView = false;
		this.showPointUpdate = false;
		this.showPointAdd = false;
		this.showMeetings = true;
	}

	onHideMeetings() {
		this.showMeetings = false;
	}
}
