<br />
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <section class="panel-expansion matborderno">
        <mat-accordion>
          <mat-expansion-panel class="mpanel" [expanded]="true">
            <mat-expansion-panel-header class="mpanel-header">{{
              "lang_human_resources_registration_details" | language
            }}</mat-expansion-panel-header>
            <div class="material-panel-body">
              <div class="mbrl15 martb0">
                <div class="row">
                  <div class="col-xs-12 col-12 pad0">
                    <form
                      id="signupForm"
                      [formGroup]="RegistrationForm"
                      class="editForm"
                      (ngSubmit)="register()"
                    >
                      <mat-horizontal-stepper #stepper class="pad0">
                        <mat-step
                          formGroupName="loginDetails"
                          [stepControl]="RegistrationForm.get('loginDetails')"
                        >
                          <ng-template matStepLabel>
                            <div class="step">
                              <div class="st">
                                <i class="icon-5x fa fa-sign-in fa-icon"></i>
                              </div>
                            </div>
                            <div>{{ "lang_login_details" | language }}</div>
                          </ng-template>
                          <app-rstep1
                            [signupForm]="RegistrationForm"
                            [action]="action"
                          ></app-rstep1>
                        </mat-step>
                        <mat-step
                          formGroupName="idDetails"
                          [stepControl]="RegistrationForm.get('idDetails')"
                        >
                          <ng-template matStepLabel>
                            <div class="step">
                              <div class="st">
                                <i class="icon-5x fa fa-user fa-icon"></i>
                              </div>
                            </div>
                            <div>
                              {{ "lang_human_resources_id_details" | language }}
                            </div>
                          </ng-template>
                          <app-rstep2
                            [signupForm]="RegistrationForm"
                            [action]="action"
                          ></app-rstep2>
                        </mat-step>
                        <mat-step
                          formGroupName="jobDetails"
                          [stepControl]="RegistrationForm.get('jobDetails')"
                        >
                          <ng-template matStepLabel>
                            <div class="step">
                              <div class="st">
                                <i class="icon-5x fa fa-suitcase fa-icon"></i>
                              </div>
                            </div>
                            <div>
                              {{
                                "lang_human_resources_job_details" | language
                              }}
                            </div>
                          </ng-template>
                          <app-rstep3
                            [signupForm]="RegistrationForm"
                            [action]="action"
                          ></app-rstep3>
                        </mat-step>
                        <mat-step
                          formGroupName="contactDetails"
                          [stepControl]="RegistrationForm.get('contactDetails')"
                        >
                          <ng-template matStepLabel>
                            <div class="step">
                              <div class="st">
                                <i
                                  class="icon-5x fa fa-address-book fa-icon"
                                ></i>
                              </div>
                            </div>
                            <div>
                              {{
                                "lang_human_resources_contact_details"
                                  | language
                              }}
                            </div>
                          </ng-template>
                          <app-rstep4
                            [signupForm]="RegistrationForm"
                            [action]="action"
                          ></app-rstep4>
                        </mat-step>
                        <mat-step
                          formGroupName="bankDetails"
                          [stepControl]="RegistrationForm.get('bankDetails')"
                        >
                          <ng-template matStepLabel>
                            <div class="step">
                              <div class="st">
                                <i class="icon-5x fa fa-university fa-icon"></i>
                              </div>
                            </div>
                            <div>
                              {{
                                "lang_human_resources_bank_details" | language
                              }}
                            </div>
                          </ng-template>
                          <app-rstep5
                            [signupForm]="RegistrationForm"
                            [action]="action"
                          ></app-rstep5>
                        </mat-step>
                        <mat-step
                          formGroupName="educationDetails"
                          [stepControl]="
                            RegistrationForm.get('educationDetails')
                          "
                        >
                          <ng-template matStepLabel>
                            <div class="step">
                              <div class="st">
                                <i class="icon-5x fa fa-book fa-icon"></i>
                              </div>
                            </div>
                            <div>
                              {{
                                "lang_human_resources_education_details"
                                  | language
                              }}
                            </div>
                          </ng-template>
                          <app-rstep6
                            [signupForm]="RegistrationForm"
                            [action]="action"
                          ></app-rstep6>
                        </mat-step>
                        <mat-step
                          formGroupName="passportDetails"
                          [stepControl]="
                            RegistrationForm.get('passportDetails')
                          "
                        >
                          <ng-template matStepLabel>
                            <div class="step">
                              <div class="st">
                                <i class="icon-5x fa fa-plane fa-icon"></i>
                              </div>
                            </div>
                            <div>
                              {{
                                "lang_human_resources_passport_details"
                                  | language
                              }}
                            </div>
                          </ng-template>
                          <app-rstep7
                            [signupForm]="RegistrationForm"
                            [action]="action"
                          ></app-rstep7>
                        </mat-step>
                        <mat-step
                          formGroupName="insuranceDetails"
                          [stepControl]="
                            RegistrationForm.get('insuranceDetails')
                          "
                        >
                          <ng-template matStepLabel>
                            <div class="step">
                              <div class="st">
                                <i class="icon-5x fa fa-hospital-o fa-icon"></i>
                              </div>
                            </div>
                            <div>
                              {{
                                "lang_human_resources_insurance_details"
                                  | language
                              }}
                            </div>
                          </ng-template>
                          <app-rstep9
                            [signupForm]="RegistrationForm"
                            [action]="action"
                          ></app-rstep9>
                        </mat-step>
                        <mat-step
                          formGroupName="signatureDetails"
                          [stepControl]="
                            RegistrationForm.get('signatureDetails')
                          "
                        >
                          <ng-template matStepLabel>
                            <div class="step">
                              <div class="st">
                                <mat-icon class="mnabricon">gamepad</mat-icon>
                              </div>
                            </div>
                            <div>{{ "lang_signature" | language }}</div>
                          </ng-template>
                          <app-rstep8
                            [signupForm]="RegistrationForm"
                            [action]="action"
                          ></app-rstep8>
                        </mat-step>
                      </mat-horizontal-stepper>
                    </form>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <app-alert id="default-alert"></app-alert>
              </div>
              <div class="card-footer padb0">
                <div class="row text-center">
                  <div class="col-md-3 mb-10">
                    <a routerLink="/status">{{
                      "lang_check_app_status" | language
                    }}</a>
                  </div>
                  <div class="col-md-3 mb-10">
                    <a routerLink="/auth/login">{{
                      "lang_signin" | language
                    }}</a>
                  </div>
                  <div class="col-md-3 mb-10">
                    <a routerLink="/forget">{{
                      "lang_forgot_password" | language
                    }}</a>
                  </div>
                  <div class="col-md-3 mb-10">
                    <a routerLink="/reset">{{
                      "lang_reset_login" | language
                    }}</a>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </section>
    </div>
  </div>
</div>
