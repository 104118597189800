import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';


@Component({
	selector: "app-cs-users",
	templateUrl: "./cs-users.component.html",
	styleUrls: ["./cs-users.component.scss"],
})
export class CsUsersComponent implements OnInit ,OnDestroy {
	modalRefU: BsModalRef;
	modalRefV: BsModalRef;
	@Input() customer_id;
	@Input() type;
	@Input() able2add;
	@Input() action_access;
	@Input() able2edit;
	@Input() able2delete;
	@Input() user_position;
	@Input() customer_data;
	userform: FormGroup;
	users: any = [];
	upsize: any = "10";
	upage: any = 1;
	userdata: any = [];
	groups: any = [];
	usererrormsg = this.lang.transform("lang_loading");
	userviewdata: any = [];
	user_error_status: boolean = false;
	usererrorMsg: string = "";
	viewdata: any = [];
	searchKey = "";
	searchStatus = "";
	constructor(
		public ds: DataService,
		public router: Router,
		public route: ActivatedRoute,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public alert: AlertService,
		public dialog: MatDialog,
		public modalService: BsModalService
	) {}
	ngOnInit(): void {
		this.builduserform();
		this.get_groups();
		this.list_of_users();
	}

	private subscriptions = new Subscription();
	ngOnDestroy(): void {
	  this.subscriptions.unsubscribe();
	}
	public get_groups() {
		this.groups = [];
		this.subscriptions.add(
				this.ds.getActionByUrl([], "cs/g").subscribe((res) => {
					if (res.status) {
						this.groups = res.records;
					}
				})     
			)

	}
	openModalU(template: TemplateRef<any>) {
		this.modalRefU = this.modalService.show(template, environment.modelconfig);
	}
	openModalV(template: TemplateRef<any>) {
		this.modalRefV = this.modalService.show(template, environment.modelconfig);
	}
	//build form
	public builduserform() {
		this.userform = new FormGroup({
			group_key: new FormControl(null, [Validators.required]),
			user_registration_firstname_en: new FormControl(null, [
				Validators.required,
			]),
			user_registration_lastname_en: new FormControl(null, [
				Validators.required,
			]),
			user_registration_firstname_ar: new FormControl(null, [
				Validators.required,
			]),
			user_registration_lastname_ar: new FormControl(null, [
				Validators.required,
			]),
			user_email_address: new FormControl(null, [
				Validators.required,
				Validators.email,
			]),
			user_mobile_number: new FormControl(null, [
				Validators.required,
				Validators.minLength(12),
				Validators.maxLength(12),
			]),
			personal_id_number: new FormControl(null),
		});
	}
	//empty userform
	public emptyUserForm() {
		this.userform.reset();
		this.userform.get("group_key").setValue("");
	}
	//user operation functions
	public user_operations() {
		this.spinner.show();
		const formData = this.ds.json2formdata(this.userform.value);
		formData.append("branch_id", this.customer_data.branch_id);
		formData.append("module_key", "customers");
		if (
			this.userform.get("group_key").value == "CSM" ||
			this.userform.get("group_key").value == "CST"
		) {
			formData.append("permission_key", "customers_view");
		} else {
			formData.append("permission_key", "customers_add");
		}
		formData.append("private_key", "customer_id");
		formData.append("private_value", this.customer_id);
		formData.append("user_type_id", this.customer_data.user_type_id);
		formData.append("user_registration_type", "D");
		this.subscriptions.add(
				this.ds.postActionByUrl(formData, "reg").subscribe(
					(res) => {
						this.spinner.hide();
						if (res.status) {
							this.userform.reset();
							this.list_of_users();
							this.alert.success(res.msg);
							setTimeout(() => {
								this.modalRefU.hide();
							}, 1000);
						} else {
							this.alert.error(res.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.alert.error(
							error && error.error && error.error.error
								? error.error.error
								: this.lang.transform("lang_internal_server_error")
						);
					}
				)      
			)

	}
	//listing starts
	public userpage(size) {
		this.upsize = size;
		this.upage = 1;
		this.list_of_users();
	}
	public userlength(page) {
		this.upage = page;
		this.list_of_users();
	}
	public search_rp_users() {
		this.upage = 1;
		this.upsize = "10";
		this.list_of_users();
	}
	public list_of_users() {
		let formData = new FormData();
		formData.append("branch_id", this.customer_data.branch_id);
		formData.append("module_key", "customers");
		formData.append("permission_key", "customers_view");
		formData.append("private_key", "customer_id");
		formData.append("private_value", this.customer_id);
		formData.append("search_key", this.searchKey);
		formData.append("search_status", this.searchStatus);
		this.subscriptions.add(
			this.ds
						.postActionByUrl(formData, "reg/u/" + this.upage + "/" + this.upsize)
						.subscribe(
							(res) => {
								this.spinner.hide();
								if (res.status) {
									this.users = res.records;
									this.userdata = res;
								} else {
									this.users = [];
									this.usererrormsg = res.error;
								}
							},
							(error) => {
								this.spinner.hide();
								this.users = [];
								this.userdata = [];
								this.usererrormsg =
									error && error.error && error.error.error
										? error.error.error
										: this.lang.transform("lang_internal_server_error");
							}
						)      
			)
		;
	}
	//listing ends
	public send_signup_link(user) {
		this.spinner.show();
		this.subscriptions.add(
			this.ds
						.getActionByUrl([], "reg/link/" + user.user_registration_link)
						.subscribe(
							(data) => {
								this.spinner.hide();
								if (data.status) {
									this.ds.dialogf("", data.msg);
								} else {
									this.ds.dialogf("", data.error);
								}
							},
							(error) => {
								this.spinner.hide();
								this.ds.dialogf(
									"",
									error && error.error && error.error.error
										? error.error.error
										: this.lang.transform("lang_internal_server_error")
								);
							}
						)      
			)
		;
	}
	//view user signup data
	public signup_data_view(viewdata) {
		this.viewdata = viewdata;
	}
}
