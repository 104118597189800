<br>
<br>
<br>
<div class="themesection panel-expansion">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12 mar0auto">
                <mat-accordion class="appattachments" >
                    <mat-expansion-panel class="mpanel" [expanded]="true">
                        <mat-expansion-panel-header class="mpanel-header">{{'lang_complete_verification' | language }}</mat-expansion-panel-header>
                        <div class="material-panel-body">
                            <div class="mbrl15 martb0">
                                <form [formGroup]="form" class="editForm" (ngSubmit)="confirmVerification()">
                                    <div class="form-group">
                                        <label for="id_number">{{'lang_human_resources_id_number' | language }} : <span class="red">*</span></label>
                                        <input formControlName="id_number" #id [readonly]="readonly" minlength="10" maxlength="10" id="id_number" type="text" placeholder="{{'lang_human_resources_id_number' | language }}" class="form-control almnabrformcontrol" (keypress)="ds.numberOnly($event)" (keyup)="get_verify_options($event.target.value)" />
                                        <mat-error *ngIf="form.controls['id_number'].touched && form.controls['id_number'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                        <mat-error *ngIf="form.controls['id_number'].errors?.minlength"> {{'lang_minimum' | language }} 10 </mat-error>
                                        <mat-error *ngIf="form.controls['id_number'].errors?.maxlength"> {{'lang_maximum' | language }} 10 </mat-error>
                                    </div>
                                    <div class="form-group" *ngIf="apistatus">
                                        <label class="mb-10">{{'lang_how_to_send_verification' | language }} : <span class="red">*</span></label>
                                        <div class="custom-control custom-radio mb-10">
                                            <input type="radio" class="custom-control-input zindex9999" value="email" id="vm1" formControlName="verification_method"/>
                                            <label class="custom-control-label">{{'lang_email' | language }} <b>({{ds.secureemail(data.primary_email)}}) </b> </label>
                                        </div>
                                        <div class="custom-control custom-radio mb-10">
                                            <input type="radio" class="custom-control-input zindex9999" value="mobile" id="vm2" formControlName="verification_method" />
                                            <label class="custom-control-label">{{'lang_mobile' | language }} <b>({{ds.securemobile(data.primary_mobile)}}) </b> </label>
                                        </div>
                                        <div class="custom-control custom-radio mb-10">
                                            <input type="radio" class="custom-control-input zindex9999" value="whatsapp" id="vm3" formControlName="verification_method" />
                                            <label class="custom-control-label">{{'lang_whatsapp' | language }} <b>({{ds.securemobile(data.primary_mobile)}}) </b> </label>
                                        </div>
                                    </div>
                                    <div *ngIf="sendstatus" id="verification_send">
                                        <div class="form-group">
                                            <label for="verification_code">{{'lang_enter_verification_code' | language }}: <span class="red">*</span></label>
                                            <input type="text" required minlength="4" maxlength="4" class="form-control almnabrformcontrol" (keypress)="ds.numberOnly($event)" id="verification_code" formControlName="verification_code">
                                            <mat-error *ngIf="form.controls['verification_code'].touched && form.controls['verification_code'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                            <mat-error *ngIf="form.controls['verification_code'].errors?.minlength"> {{'lang_minimum' | language }} 4 </mat-error>
                                            <mat-error *ngIf="form.controls['verification_code'].errors?.maxlength"> {{'lang_maximum' | language }} 4 </mat-error>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="id.value && id.value.length == 10 && apistatus">
                                        <div class="col-md-6 col-xs-12 mb-10">
                                            <button type="button" class="albutton" (click)="sendVerificationCode()" [disabled]="sendcodestatus">{{codemsg}}</button>
                                        </div>
                                        <div class="col-md-6 col-xs-12 mb-10">
                                            <button class="albutton" [disabled]="!form.valid">{{'lang_do_verification' | language }}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer">
                                <app-alert id="default-alert"></app-alert>
                            </div>
                            <div class="card-footer padb0">
                                <div class="row text-center">
                                    <div class="col-md-6 mb-10">
                                        <a routerLink="/signup">{{'lang_join_almnabr' | language }}</a>
                                    </div>
                                    <div class="col-md-6 mb-10">
                                        <a routerLink="/auth/login">{{'lang_signin' | language }}</a>
                                    </div>
                                </div>
                                <div class="row text-center">
                                    <div class="col-md-6 mb-10">
                                        <a routerLink="/forget">{{'lang_forgot_password' | language }}</a>
                                    </div>
                                    <div class="col-md-6 mb-10">
                                        <a routerLink="/reset">{{'lang_reset_login' | language }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>