<app-ep-header></app-ep-header>
<div class="m-3 mb-0" style="min-height: calc(100vh - 155px)">
	<div class="d-flex align-items-center justify-content-between">
		<div class="task-title">
			<div class="tt-up">
				<i class="pi pi-credit-card"></i>
				<h3>{{ task?.title }}</h3>
			</div>
			<div class="tt-down">
				<p>
					{{ "lang_status" | language }}
					<span>{{ task?.task_status_name }}</span>
				</p>
			</div>
		</div>
		<div class="actions d-flex align-items-center">
			<div style="width: 150px; margin: 0 10px">
				<button
					mat-button
					class="albutton"
					routerLink="/external-person/tickets"
				>
					{{ "lang_ticket" | language }} {{ "lang_list" | language }}
				</button>
			</div>
			<div style="width: 150px">
				<button
					mat-button
					class="albutton"
					routerLink="/external-person/tickets/{{ task?.ticket_id }}"
				>
					{{ "lang_ticket" | language }}
				</button>
			</div>
		</div>
	</div>

	<div class="p-2" [ngClass]="{ rtl: lang_key == 'ar' }">
		<div class="row">
			<div class="col-12 col-md-5" style="min-height: 450px">
				<!-- start task-members-dates -->
				<div class="members-dates">
					<div class="task-dates">
						<h5 class="task-dates-title">{{ "lang_date" | language }}</h5>
						<div class="task-dates-content task-btn">
							{{ "lang_from" | language }} {{ task?.start_date }}
							{{ "lang_to" | language }} {{ task?.end_date }}
						</div>
					</div>
					<div class="ticket-no">
						<h5 class="ticket-no-title">
							{{ "lang_ticket_number" | language }}
						</h5>
						<button class="task-btn">
							<i
								class="fa fa-undo mnabricon mr-2 ml-2"
								matTooltip="{{ 'lang_ticket' | language }}"
								routerLink="/external-person/tickets/{{ task?.ticket_id }}"
							></i>
							<span
								routerLink="/external-person/tickets/{{ task?.ticket_id }}"
								style="
									text-decoration: underline;
									color: #5e6c84;
									cursor: pointer;
									font-size: 14px;
								"
								>{{ task?.ticket_no }}
							</span>
							<span style="color: #5e6c84; font-size: 14px">
								- {{ task?.task_no }}</span
							>
						</button>
					</div>
				</div>
				<!-- end task-members-dates -->
				<!-- start task-Description -->
				<div class="task-description">
					<div class="td-title">
						<i class="pi pi-align-left"></i>
						<h4>{{ "lang_description" | language }}</h4>
					</div>
					<div class="td-body">
						<p>{{ task.description }}</p>
					</div>
				</div>
				<!-- end task-Description -->
				<!-- start task-checklist -->
				<div class="task-checklist-list">
					<!-- start task-checklist list -->
					<div
						class="task-checklist"
						*ngFor="let checklist of checklist_arr; let checklist_index = index"
					>
						<div class="tc-header">
							<div class="tch-title">
								<i class="pi pi-check-square"></i>
								<h5>{{ checklist?.char_item }} - {{ checklist?.title }}</h5>
							</div>
						</div>
						<div class="tc-progress">
							<span class="tc-progress-no"
								>{{ checklist?.progress || "0" }}%</span
							>
							<div class="tc-progress-bar">
								<div
									class="tcpb-current"
									[style]="'width:' + checklist?.progress + '%'"
								></div>
							</div>
						</div>
						<div class="tc-body">
							<!-- start task-checklist list items -->
							<div class="tc-items">
								<ng-container
									*ngFor="
										let checklist_item of checklist?.chicklist_items;
										let checklistItemIndex = index
									"
								>
									<div
										class="tc-item"
										(click)="viewChecklistDetails(checklist_item)"
									>
										<div class="tc-item-container">
											<div class="tci-check">
												<mat-checkbox
													[checked]="checklist_item?.checked"
													[(ngModel)]="checklist_item.checked"
													[disabled]="true"
												></mat-checkbox>
											</div>
											<div class="tci-content">
												<span class="tci-content-title">
													<h5>
														{{ checklist_item?.char_item }} -
														{{ checklist_item?.notes }}
													</h5>
													<small
														>{{ "lang_created_by" | language }}
														{{
															lang_key == "ar"
																? checklist_item?.firstname_arabic
																: checklist_item?.firstname_english
														}}
														{{
															lang_key == "ar"
																? checklist_item?.lastname_arabic
																: checklist_item?.lastname_english
														}}</small
													>
												</span>
											</div>
										</div>
									</div>
								</ng-container>
							</div>
							<!-- end task-checklist list items -->
						</div>
						<!-- end add Checklist -->
					</div>
					<!-- end task-checklist list -->
				</div>
				<!-- end task-checklist -->
			</div>
			<div class="col-12 col-md-7" style="min-height: 450px">
				<app-ep-checkpoint-details-view
					[point]="selectedPointForView"
					(hideView)="onHidePointView($event)"
					*ngIf="showPointView"
				></app-ep-checkpoint-details-view>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>
