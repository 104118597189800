import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { TRWDialogComponent } from "./trw-dialog/trw-dialog.component";
import { TrwViewDialogComponent } from "./trw-view-dialog/trw-view-dialog.component";

@Component({
	selector: "app-supervision-test-report-workarea",
	templateUrl: "./supervision-test-report-workarea.component.html",
	styleUrls: ["./supervision-test-report-workarea.component.scss"],
})
export class SupervisionTestReportWorkareaComponent implements OnInit {
	@Input() supervision_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() project_data;
	@Input() makeAction;
	setting_type = "soil";
	list_type = "soil";
	lang_key = localStorage.getItem("lang_key") || "en";
	loading_files_btns = false;
	history_files = [];
	history_files_page = 1;
	history_files_size = 5;
	report_status_co = "P";
	report_status_list = [
		{
			label: this.lang.transform("lang_all"),
			value: "",
		},
		{
			label: this.lang.transform("lang_pending"),
			value: "P",
		},
		{
			label: this.lang.transform("lang_failed"),
			value: "F",
		},
		{
			label: this.lang.transform("lang_successfull"),
			value: "S",
		},
		{
			label: this.lang.transform("lang_need_review"),
			value: "NR",
		},
		{
			label: this.lang.transform("lang_not_correct"),
			value: "NC",
		},
	];
	compressive_strength = "S";
	compressive_strength_list = [
		{
			label: this.lang.transform("lang_all"),
			value: "",
		},
		{
			label: "7 " + this.lang.transform("lang_days"),
			value: "S",
		},
		{
			label: "28 " + this.lang.transform("lang_days"),
			value: "T",
		},
	];
	test_report_list_so = [];
	test_report_pageno_so = 1;
	test_report_pagesize_so = "10";
	test_report_collectionsize_so = 0;
	test_report_list_co = [];
	test_report_pageno_co = 1;
	test_report_pagesize_co = "10";
	test_report_collectionsize_co = 0;
	isMob = false;
	//Soil Filter
	soil_search_key = "";
	show_previous_soil_reports = "0";
	showSoilFilter: boolean = false;
	filterSoilForm: FormGroup;
	group1ListS = [];
	group1ListC = [];
	levels_arr = [];
	sort_by_arr = [
		{
			label: this.lang.transform("lang_request_number"),
			value: "transaction_request_id",
		},
		{
			label: this.lang.transform("lang_platform_code_system"),
			value: "platform_code_system",
		},
		{
			label: this.lang.transform("lang_template_id"),
			value: "template_id",
		},
		{
			label: this.lang.transform("lang_unit"),
			value: "unit_id",
		},
		{
			label: this.lang.transform("lang_level"),
			value: "work_level",
		},
		{
			label: this.lang.transform("lang_result"),
			value: "result_code",
		},
	];
	order_by_arr = [
		{
			label: "ASC",
			value: "ASC",
		},
		{
			label: "DESC",
			value: "DESC",
		},
	];
	//Concrete Filter
	concrete_search_key = "";
	show_previous_concrete_reports = "0";
	showConcreteFilter: boolean = false;
	filterConcreteForm: FormGroup;
	concrete_due_dates = false;
	constructor(
		private router: Router,
		public dialog: MatDialog,
		private spinner: PreloaderService,
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		if (window.innerWidth < 600) {
			this.isMob = true;
		} else {
			this.isMob = false;
		}
		if (!this.ds.allowMe("projects", "projects_template_permission")) {
			this.buildSoilFilters();
			this.getTestReportListSo();
			this.getWorkLevels();
			this.get_divisions("S");
		}
	}

	selectedTabValue(e) {
		if (e?.tab?.textLabel == "test_result_list") {
			this.buildSoilFilters();
			this.getWorkLevels();
			if (this.list_type == "soil") {
				this.get_divisions("S");
			} else {
				this.get_divisions("C");
			}

			this.getTestReportListSo();
			this.buildConcreteFilters();
		}
	}

	selectedListTabValue(e) {
		if (e?.tab?.textLabel == "soil") {
			this.list_type = "soil";
			this.buildSoilFilters();
			this.getTestReportListSo();
		} else if (e?.tab?.textLabel == "concrete") {
			this.list_type = "concrete";
			this.buildConcreteFilters();
			this.getTestReportListCo();
		}
	}

	loading_files_btns_func() {
		this.loading_files_btns = true;
		setTimeout(() => {
			this.loading_files_btns = false;
		}, 500);

		this.history_files = [];
	}

	downloadExcel(item) {
		let url = `ptesting/download_soil_concrete_testing_report_config/${this.project_data?.projects_profile_id}/${this.supervision_data?.projects_supervision_id}/${this.setting_type}`;
		if (item) {
			url = item?.file;
		}

		this.spinner.show();
		this.ds.get(url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successMessage(res?.msg);
					if (item) {
						this.ds.downloadFile(
							res,
							item?.type_code_system + item?.created_datetime + "_file"
						);
					} else {
						this.ds.downloadFile(res, this.setting_type + "_file");
					}
				} else {
					this.sweetAlert.errorMessage(res?.error);
				}
			},
			(err) => {
				this.sweetAlert.errorMessage(err?.error?.error);
				this.spinner.hide();
			}
		);
	}

	uploadExcel(e) {
		if (e && e?.length) {
			let url = "ptesting/upload_soil_testing_report_config";
			if (this.setting_type == "concrete") {
				url = "ptesting/upload_concreate_testing_report_config";
			}
			let formData = new FormData();
			formData.append(
				"projects_profile_id",
				this.project_data?.projects_profile_id || ""
			);
			formData.append(
				"projects_supervision_id",
				this.supervision_data?.projects_supervision_id || ""
			);
			if (this.setting_type == "concrete") {
				formData.append("concreate_testing_report_file", e[0] || "");
			} else {
				formData.append("soil_testing_report_file", e[0] || "");
			}

			this.spinner.show();
			this.ds.post(url, formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetAlert.successMessage(res?.msg);
					} else {
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.sweetAlert.errorMessage(err?.error?.error);
					this.spinner.hide();
				}
			);
		}
		document.getElementById("uploadExcelInput")["value"] = "";
	}

	soil_concrete_history() {
		let formData = new FormData();
		if (this.setting_type == "concrete") {
			formData.append("required_type", "C");
		} else {
			formData.append("required_type", "S");
		}

		this.spinner.show();
		this.ds
			.post(
				`s6df54s6df/${this.project_data?.projects_profile_id}/${this.supervision_data?.projects_supervision_id}`,
				formData
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.history_files = res?.records;
					} else {
						this.history_files = [];
						this.sweetAlert.errorMessage(res?.error);
					}
				},
				(err) => {
					this.sweetAlert.errorMessage(err?.error?.error);
					this.spinner.hide();
				}
			);
	}

	getTestReportListSo() {
		this.spinner.show();
		let url = `ptesting/get_soil_testing_reports/${this.test_report_pageno_so}/${this.test_report_pagesize_so}`;
		let formData = new FormData();
		formData.append(
			"projects_profile_id",
			this.project_data?.projects_profile_id || ""
		);
		formData.append(
			"projects_supervision_id",
			this.supervision_data?.projects_supervision_id || ""
		);
		formData.append(
			"report_status",
			this.filterSoilForm.get("report_status_so").value?.toString() || ""
		);
		formData.append(
			"group1_code_system",
			this.filterSoilForm.get("group1_id").value || ""
		);
		formData.append(
			"platform_code_system",
			this.filterSoilForm.get("platform_code_system").value || ""
		);
		formData.append(
			"work_level_key",
			this.filterSoilForm.get("level_key").value || ""
		);
		formData.append("search_key", this.soil_search_key || "");
		formData.append("sort_by", this.filterSoilForm.get("sort_by").value || "");
		formData.append(
			"order_by",
			this.filterSoilForm.get("order_by").value || ""
		);
		formData.append(
			"show_previous_transaction_reports",
			this.show_previous_soil_reports || ""
		);
		this.ds.getActionByUrl(this.ds.formData2string(formData), url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.test_report_list_so = res?.records;
					this.test_report_collectionsize_so = res?.page?.total_records;
				} else {
					this.test_report_list_so = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.test_report_list_so = [];
			}
		);
	}
	onSoilPageNoChange(e) {
		this.test_report_pageno_so = e;
		this.getTestReportListSo();
	}
	onSoilPageSizeChange(e) {
		this.getTestReportListSo();
	}
	//soil filters
	getWorkLevels() {
		let param = new FormData();
		param.append("lang_key", this.lang_key);
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "lpworklevel")
			.subscribe((res) => {
				if (res?.status) {
					this.levels_arr = res?.records;
				} else {
					this.levels_arr = [];
				}
			});
	}

	get_divisions(type) {
		this.ds
			.get(
				`ptesting/list_of_divisions/${this.project_data?.projects_profile_id}/${this.supervision_data?.projects_work_area_id}/${type}`
			)
			.subscribe((res) => {
				if (res.status) {
					if (type == "S") {
						this.group1ListS = res?.records;
					} else if (type == "C") {
						this.group1ListC = res?.records;
					}
				}
			});
	}

	onSoilPreviousChange(e) {
		if (e?.checked == true) {
			this.show_previous_soil_reports = "1";
		} else {
			this.show_previous_soil_reports = "0";
		}
	}

	buildSoilFilters() {
		this.filterSoilForm = this.fb.group({
			report_status_so: [["P"]],
			group1_id: [null],
			platform_code_system: [null],
			level_key: [null],
			sort_by: [null],
			order_by: [null],
		});
	}

	resetFilterSoilForm() {
		this.filterSoilForm.setValue({
			report_status_so: [["P"]],
			group1_id: null,
			platform_code_system: null,
			level_key: null,
			sort_by: null,
			order_by: null,
		});
		this.getTestReportListSo();
	}
	onfilterSoilForm() {
		this.getTestReportListSo();
	}
	//concrete
	getTestReportListCo() {
		this.spinner.show();
		let formData = new FormData();
		formData.append(
			"projects_profile_id",
			this.project_data?.projects_profile_id || ""
		);
		formData.append(
			"projects_supervision_id",
			this.supervision_data?.projects_supervision_id || ""
		);
		formData.append(
			"report_status",
			this.filterConcreteForm.get("report_status").value?.toString() || ""
		);
		formData.append(
			"compressive_strength",
			this.filterConcreteForm.get("compressive_strength").value || ""
		);
		formData.append(
			"group1_code_system",
			this.filterConcreteForm.get("group1_id").value || ""
		);
		formData.append(
			"platform_code_system",
			this.filterConcreteForm.get("platform_code_system").value || ""
		);
		formData.append(
			"work_level_key",
			this.filterConcreteForm.get("level_key").value || ""
		);
		formData.append("search_key", this.concrete_search_key || "");
		formData.append(
			"sort_by",
			this.filterConcreteForm.get("sort_by").value || ""
		);
		formData.append(
			"order_by",
			this.filterConcreteForm.get("order_by").value || ""
		);
		formData.append(
			"show_previous_transaction_reports",
			this.show_previous_concrete_reports || ""
		);
		if (this.concrete_due_dates) {
			formData.append("filter_by_due_dates", "1");
			if (this.compressive_strength == "S") {
				formData.append("seven_days_due", "5");
				formData.append("tfive_days_due", "");
			} else if (this.compressive_strength == "T") {
				formData.append("seven_days_due", "");
				formData.append("tfive_days_due", "25");
			}
		} else {
			formData.append("filter_by_due_dates", "");
			formData.append("seven_days_due", "");
			formData.append("tfive_days_due", "");
		}

		let url = `ptesting/get_concreate_testing_reports/${this.test_report_pageno_co}/${this.test_report_pagesize_co}`;

		this.ds.getActionByUrl(this.ds.formData2string(formData), url).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.test_report_list_co = res?.records;
					this.test_report_collectionsize_co = res?.page?.total_records;
				} else {
					this.test_report_list_co = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.test_report_list_co = [];
			}
		);
	}
	onConcretePageNoChange(e) {
		this.test_report_pageno_co = e;
		this.getTestReportListCo();
	}
	onConcretePageSizeChange(e) {
		this.getTestReportListCo();
	}
	//concrete filters
	buildConcreteFilters() {
		this.filterConcreteForm = this.fb.group({
			report_status: [["P"]],
			compressive_strength: [null],
			group1_id: [null],
			platform_code_system: [null],
			level_key: [null],
			sort_by: [null],
			order_by: [null],
			filter_by_due_dates: ["0"],
			seven_days_due: [""],
			tfive_days_due: [""],
		});
	}

	resetFilterConcreteForm() {
		this.filterConcreteForm.setValue({
			report_status: [["P"]],
			compressive_strength: null,
			group1_id: null,
			platform_code_system: null,
			level_key: null,
			sort_by: null,
			order_by: null,
			filter_by_due_dates: "0",
			seven_days_due: "",
			tfive_days_due: "",
		});
		this.getTestReportListCo();
	}
	onfilterConcreteForm() {
		this.getTestReportListCo();
	}
	onConcretePreviousChange(e) {
		if (e?.checked == true) {
			this.show_previous_concrete_reports = "1";
		} else {
			this.show_previous_concrete_reports = "0";
		}
	}

	viewTestData(item) {
		const dialogRef = this.dialog.open(TrwViewDialogComponent, {
			width: "80%",
			data: {
				projects_supervision_id: this.supervision_data?.projects_supervision_id,
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
				projects_profile_id: this.project_data?.projects_profile_id,
				tsr_report_id: item?.tsr_report_id,
				action_auto_id: item?.action_auto_id,
				setting_type: this.setting_type,
			},
		});
	}

	// open form dialog
	openForm(item) {
		const dialogRef = this.dialog.open(TRWDialogComponent, {
			width: "80%",
			disableClose: true,
			data: {
				projects_supervision_id: this.supervision_data?.projects_supervision_id,
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
				projects_profile_id: this.project_data?.projects_profile_id,
				transaction_request_id: item?.transaction_request_id,
				tbv_barcodeData: item?.tbv_barcodeData,
				tsr_report_id: item?.tsr_report_id,
				list_type: this.list_type,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res == "soil") {
				this.getTestReportListSo();
			} else if (res == "concrete") {
				this.getTestReportListCo();
			}
		});
	}

	viewHistory(item, type) {
		let urlHashed = environment.useHash
			? "/#/projects/Rpq7t/1/1/tsr/history"
			: "/projects/Rpq7t/1/1/tsr/history";
		let url = this.router.serializeUrl(
			this.router.createUrlTree(
				[`${urlHashed}/${type}/${item?.tsr_report_id}`],
				{
					queryParams: {
						workarea: this.supervision_data?.projects_work_area_id,
						transaction_request_id: item?.transaction_request_id,
						tbv_barcodeData: item?.tbv_barcodeData,
					},
				}
			)
		);
		url = url.replace(/%23/g, "#");
		window.open(url, "_blank");
	}

	dueDatesChange(e) {
		this.concrete_due_dates = e?.checked;
	}

	public isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	customizeFraction(number) {
		let customNumber = "0";
		if (number) {
			let numbers = number?.split(".");
			if (numbers.length > 1) {
				if (numbers[1]?.length > 2) {
					return (customNumber =
						numbers[0] + "." + numbers[1][0] + numbers[1][1] + numbers[1][2]);
				} else if (numbers[1].length > 1) {
					return (customNumber =
						numbers[0] + "." + numbers[1][0] + numbers[1][1]);
				} else {
					return (customNumber = numbers[0] + "." + numbers[1][0]);
				}
			} else {
				return (customNumber = numbers[0]);
			}
		} else {
			return customNumber;
		}
	}
}
