<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_user_tasks" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times mnabricon"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div
		class="table-responsive dropdownyes customResponsive tablefields"
		*ngIf="usersTasks?.length"
	>
		<table border="1" class="table CustomTable">
			<thead>
				<tr>
					<th>#</th>
					<th>{{ "lang_ticket_number" | language }}</th>
					<th>{{ "lang_task_number" | language }}</th>
					<th>{{ "lang_title" | language }}</th>
					<th>{{ "lang_created_date" | language }}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="
						let item of usersTasks | slice: (page - 1) * size:page * size;
						let i = index;
						let odd = odd;
						let even = even
					"
				>
					<td>{{ i + 1 }}</td>
					<td *ngIf="!item?.is_can_view">{{ item?.ticket_no || "---" }}</td>
					<td
						*ngIf="item?.is_can_view"
						class="link-item"
						(click)="moveToTask(item)"
					>
						{{ item?.ticket_no || "---" }}
					</td>
					<td *ngIf="!item?.is_can_view">{{ item?.task_no || "---" }}</td>
					<td
						*ngIf="item?.is_can_view"
						class="link-item"
						(click)="moveToTask(item)"
					>
						{{ item?.task_no || "---" }}
					</td>
					<td *ngIf="!item?.is_can_view">{{ item?.title_task || "---" }}</td>
					<td
						*ngIf="item?.is_can_view"
						class="link-item"
						(click)="moveToTask(item)"
					>
						{{ item?.title_task || "---" }}
					</td>
					<td>{{ item?.insert_date || "---" }}</td>
				</tr>
			</tbody>
		</table>
		<div
			class="mt-2"
			*ngIf="usersTasks?.length > 0"
			style="
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
			"
		>
			<ngb-pagination
				*ngIf="usersTasks.length"
				[collectionSize]="usersTasks.length"
				[(page)]="page"
				[maxSize]="size"
				[boundaryLinks]="true"
			></ngb-pagination>
		</div>
	</div>
	<div class="mt-3 mb-3 text-center" *ngIf="!usersTasks?.length">
		<p style="font-size: 18px">{{ "lang_no_data" | language }}</p>
	</div>
</mat-dialog-content>
