import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddUpdateMeetingComponent } from "../add-update-meeting/add-update-meeting.component";

@Component({
	selector: "app-meeting-list",
	templateUrl: "./meeting-list.component.html",
	styleUrls: ["./meeting-list.component.scss"],
})
export class MeetingListComponent implements OnInit {
	@Output() viewMeeting = new EventEmitter<any>();
	@Input() taskId;
	meetings;
	page_no = 1;
	page_size = 10;
	collection_size;
	constructor(
		private dialog: MatDialog,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private spinner: PreloaderService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.getMeetingList();
	}

	onViewMeeting(id) {
		this.viewMeeting.emit({
			isView: true,
			meetingId: id,
		});
	}

	onAddUpdateMeeting(event?, data?) {
		if (event) {
			event?.stopPropagation();
		}
		const dialogRef = this.dialog.open(AddUpdateMeetingComponent, {
			width: "600px",
			autoFocus: false,
			data: {
				meeting: data,
				task_id: this.taskId,
			},
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.getMeetingList();
			}
		});
	}

	getMeetingList() {
		let formData = new FormData();
		formData.append("task_id", this.taskId || "");
		this.ds
			.post("tasks/get_tickets_task_meetings/1/10", formData)
			.subscribe((res) => {
				if (res?.status) {
					this.meetings = res?.data;
					this.collection_size = res?.page?.total_records;
				} else {
					this.meetings = [];
					this.collection_size = 0;
				}
			});
	}

	deleteMeeting(event, id) {
		event?.stopPropagation();
		let formData = new FormData();
		formData.append("meeting_id", id || "");
		this.spinner.show();
		this.ds.post("tasks/delete_task_meetings", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
					this.getMeetingList();
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
	convertMeetingToChecklist(event, id) {
		event?.stopPropagation();
		let formData = new FormData();
		formData.append("meeting_id", id || "");
		this.spinner.show();
		this.ds.post("tasks/convert_meeting_checklist", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
