import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { UplaodsHistoryComponent } from './uploads-history/uploads-history.component';
@Component({
  selector: 'app-types',
  templateUrl: './types.component.html',
  styleUrls: ['./types.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypesComponent),
      multi: true
    }
  ]
})
export class TypesComponent implements OnInit, ControlValueAccessor,OnChanges {
  @Input() project_data;
  @Input() template_id;
  @Input() supervision_data;
  @Input() tree_data;
  @Input() group1Index;
  @Input() step1formvalue;
  @Input() weightunits;
  @Input() access_codes;
  @Input() maintotalamount;
  @Input() this_template_total_cost;
  @Input() step2formvalue;
  @Input() actionMode;
  @Input() type_data;
  @Input() fconsultants;
  @Input() consultantr;
  @Input() positions;
  @Input() buildingCodes;
  @Input() ncrSiIsssues;
  @Input() platform_group1_code_system;
  @ViewChild('uploadExcelFile', {static:true}) uploadExcelFile:any;
  @Input() step2form : FormGroup;
  @Output()  typeTotalEvent = new EventEmitter<any>();
  @Output()  groupTypeEvent = new EventEmitter<any>();
  @Output() type_total_ratio = new EventEmitter<any>();
  form:FormGroup;
  typeformarray:FormArray;
  //extra Type
  modalRefT: BsModalRef;
  extratypes:any = []; //array list
  extratypedata:any = null; //ngmodel 
  constructor(
    private cdr: ChangeDetectorRef,
    public ds: DataService,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
 }
  writeValue(obj: any): void {
    if(obj) {
      this.typeformarray.controls = [];
      this.typevalidate.controls = [];
      obj.forEach((v, k) => {
        this.typeformarray.push(this.type_records({
          platform_group_type_code_system   : v.platform_group_type_code_system,
          typename                          : v.platform_group_type_title,
          manual_total_amount               : v.manual_total_amount,
          manual_total_ratio                : v.manual_total_ratio,
          platform_group_type_total         : v.platform_group_type_total,
          platform_group_type_total_ratio   : v.platform_group_type_total_ratio,
          system_total_amount               : v.system_total_amount,
          system_total_ratio                : v.system_total_ratio,
          group2Data                        : v.group2Data
        }));
      });
    }
  }
  registerOnChange(fn: any): void {
    fn(this.typevalidate.value);
    this.form.valueChanges.subscribe(x => {
        fn(this.typevalidate.value);
    })
  }
  registerOnTouched(fn: any): void {
    fn(this.typevalidate.value);
    this.form.valueChanges.subscribe(x => {
        fn(this.typevalidate.value);
    })
  }
  setDisabledState?(isDisabled: boolean): void {}
  ngOnInit(): void {
    this.form = new FormGroup({
      typesData: this.fb.array([]),
    });
    this.typeformarray = this.form.get("typesData") as FormArray;
    this.appendcontrols();
    this.form.valueChanges.subscribe(x => {
        this.get_type_total();
    });
  }
  ngOnChanges(changes: SimpleChanges): void {}
  public type_title_total(controls) {
    let lang    = this.lang.transform('lang_projects_total_title');
    let one     = lang.replace('%S', controls.get('platform_group_type_title').value) ;
    return one.replace('%T', '<b>' + this.ds.addCommas((parseInt(controls.get('platform_group_type_total').value) >=0 ) ? parseFloat(controls.get('platform_group_type_total').value).toFixed(2) : 0.00) + '</b>');
  }
  //total Ratio
  public type_title_total_ratio(controls) {
    let lang    = this.lang.transform('lang_projects_total_ratio_title');
    let one     = lang.replace('%S', controls.get('platform_group_type_title').value) ;
    return one.replace('%R', '<b>' + this.ds.addCommas(controls.get('platform_group_type_total_ratio').value) + '</b>');
  }
  public items_type() {
    return this.form.get("typesData") as FormArray;
  }
  get typevalidate() {
    return this.form.get("typesData") as FormArray;
  }
  public type_records(value): FormGroup {
    return this.fb.group({
      platform_group_type_code_system   : new FormControl(value.platform_group_type_code_system),
      platform_group_type_title         : new FormControl(value.typename),
      platform_group_type_total         : new FormControl(value?.platform_group_type_total),
      platform_group_type_total_ratio   : new FormControl(value?.platform_group_type_total_ratio),
      system_total_amount               : new FormControl(value?.system_total_amount),
      manual_total_amount               : new FormControl(value?.manual_total_amount),
      system_total_ratio                : new FormControl(value?.system_total_ratio),
      manual_total_ratio                : new FormControl(value?.manual_total_ratio),
      typesGroup2Data                   : new FormControl(value?.group2),
      group2Data                        : new FormControl(value?.group2Data, [Validators.required]),
    });
  }
  private appendcontrols() {
    this.typeformarray.controls = [];
    if(this.type_data) {
      var types   = [];
      let records = this.type_data;
      Object.keys(records).forEach(function(key) {
        types.push(records[key]);
      });
      if(types.length > 0) {
        types.forEach((v, k) => {
          this.typeformarray.push(this.type_records(v));
        });
      }
    }
  }
  public openModalT(template: TemplateRef<any>) {
    this.modalRefT = this.modalService.show(template, environment.modelconfig);
  }
  public delete_types_record(index) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if(this.typevalidate.controls[index].get('group2Data')?.value?.length != 0) {
          this.deleteType(index);
        } else {
          this.typeformarray.removeAt(index);
        }
      }
    });
  }
  public get_extra_types(template) {
    this.extratypes = [];
    let typearray = [];
    this.typevalidate.value.forEach((v, k) => {
      typearray.push(v.platform_group_type_code_system);
    });
    let param = new FormData();
    param.append('platform_group_type_code_system_not_in', typearray.toString());
    this.spinner.show();
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgtype').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.extratypes = res.records;
        this.openModalT(template);
      } else {
        this.ds.dialogf('', res.error);
      }
    }, error => {
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      this.spinner.hide();
    });
  }
  public add_extra_types_items() {
    this.spinner.show();
    if(this.extratypedata && this.extratypedata.length > 0) {
      this.extratypedata.forEach((v, k) => {
        this.typeformarray.push(this.type_records({
          platform_group_type_code_system: v.value,
          typename : v.label, 
          platform_group_type_total       : '0.00',
          platform_group_type_total_ratio : '0.00',
        }));
      });
    }
    this.extratypes = [];
    this.extratypedata = null;
    setTimeout(() => {
      this.spinner.hide();
      this.modalRefT.hide();
    }, 200);
  }
  public get_group2_total(event) {
    if(!isNaN(event.total)) {
      this.typevalidate.controls[event.index].get('platform_group_type_total').setValue(parseFloat(event.total).toFixed(2));
    }    
    if(!isNaN(event.total_ratio)) {
      this.typevalidate.controls[event.index].get('platform_group_type_total_ratio').setValue(parseFloat(event.total_ratio).toFixed(2));
    }
    if(!isNaN(event.system_total_amount)) {
      this.typevalidate.controls[event.index].get('system_total_amount').setValue(parseFloat(event.system_total_amount).toFixed(2));
    }
    if(!isNaN(event.manual_total_amount)) {
      this.typevalidate.controls[event.index].get('manual_total_amount').setValue(parseFloat(event.manual_total_amount).toFixed(2));
    }
    if(!isNaN(event.system_total_ratio)) {
      this.typevalidate.controls[event.index].get('system_total_ratio').setValue(parseFloat(event.system_total_ratio).toFixed(2));
    }
    if(!isNaN(event.manual_total_ratio)) {
      this.typevalidate.controls[event.index].get('manual_total_ratio').setValue(parseFloat(event.manual_total_ratio).toFixed(2));
    }
  }
  public get_type_total() {
    let system_total_amount:any   = 0.00;
    let manual_total_amount:any   = 0.00;
    let system_total_ratio:any    = 0.00;
    let manual_total_ratio:any    = 0.00;
    let total:any                 = 0.00;
    let total_ratio:any           = 0.00;
    this.typevalidate.value.forEach((v, k) => {
      if(!isNaN(v.platform_group_type_total)) {
        total += parseFloat(v.platform_group_type_total);
      }
      if(!isNaN(v.platform_group_type_total_ratio)) {
        total_ratio += parseFloat(v.platform_group_type_total_ratio);
      }
      if(!isNaN(v.system_total_amount)) {
        system_total_amount += parseFloat(v.system_total_amount);
      }
      if(!isNaN(v.manual_total_amount)) {
        manual_total_amount += parseFloat(v.manual_total_amount);
      }
      if(!isNaN(v.system_total_ratio)) {
        system_total_ratio += parseFloat(v.system_total_ratio);
      }
      if(!isNaN(v.manual_total_ratio)) {
        manual_total_ratio += parseFloat(v.manual_total_ratio);
      }
    });
    this.typeTotalEvent.emit({
      index                     : this.group1Index,
      total                     : (parseInt(total) >= 0) ? parseFloat(total).toFixed(2) : 0.00,
      total_ratio               : (parseInt(total_ratio) >= 0) ? parseFloat(total_ratio).toFixed(2) : 0.00,      
      system_total_amount       : (parseInt(system_total_amount) >= 0) ? parseFloat(system_total_amount).toFixed(2) : 0.00,
      manual_total_amount       : (parseInt(manual_total_amount) >= 0) ? parseFloat(manual_total_amount).toFixed(2) : 0.00,
      system_total_ratio        : (parseInt(system_total_ratio) >= 0) ? parseFloat(system_total_ratio).toFixed(2) : 0.00,
      manual_total_ratio        : (parseInt(manual_total_ratio) >= 0) ? parseFloat(manual_total_ratio).toFixed(2) : 0.00,
    });
  }

  public uploadExcel(e, typeIndex) {
    if(e && e?.length) {
      let platform_group_type_code_system = this.typevalidate.controls[typeIndex].get(
        'platform_group_type_code_system'
      ).value;
      let formData = new FormData();
      formData.append('template_id', this.template_id || '');
      formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || '');
      formData.append('platform_group1_code_system', this.platform_group1_code_system || '');
      formData.append('platform_group_type_code_system', platform_group_type_code_system || '');
      formData.append('import_platform_file', e[0] || '');
      this.spinner.show();
      this.ds.post('pforms/import_platform_template', formData).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.groupTypeEvent.emit();
          this.sweetAlert.successMessage(res?.msg);
        } else {
          this.sweetAlert.errorMessage(res?.error);
        }
      })
    }
    document.getElementById('uploadExcelInput')['value'] = '';
  }

  downloadDataRequired(type:string, title:string) {
    this.spinner.show();
    let formData = new FormData();
    formData.append('required_type', type || '');
    this.ds.post('pforms/export_data_required', formData).subscribe(res => {
      if(res.status) {
        this.spinner.hide();
        // this.sweetAlert.successMessage(res?.msg);
        this.ds.downloadFile(res, title)
      }
    })
  }

  downloadExcel(typeIndex) {
    this.spinner.show();
    let platform_group_type_code_system = this.typevalidate.controls[typeIndex].get(
      'platform_group_type_code_system'
    ).value;
    let formData = new FormData();
    formData.append('template_id', this.template_id || '');
    formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || '');
    formData.append('platform_group1_code_system', this.platform_group1_code_system || '');
    formData.append('platform_group_type_code_system', platform_group_type_code_system || '');
    this.ds.post('pforms/export_platform_template', formData).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.sweetAlert.successMessage(res?.msg);
        this.ds.downloadFile(res, platform_group_type_code_system + '_template')
      } else {
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.spinner.hide();
    })
  }
  

  uploadsArray :any[]= [];
  public openDialog(typeIndex) {
    let param = new FormData();
    this.spinner.show();

    let platform_group_type_code_system = this.typevalidate.controls[typeIndex].get(
      'platform_group_type_code_system'
    ).value;
    param.append('template_id', this.template_id || '');
		param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id ||"" );
    param.append('platform_group1_code_system', this.platform_group1_code_system || '');
    param.append('platform_group_type_code_system',platform_group_type_code_system || '');
    this.ds.post(`pforms/previous_excel_platforms`,param).subscribe(res => {
      this.spinner.hide();
      if (res?.status) {
        let uploadsArray = res.records
        this.dialog.open(UplaodsHistoryComponent,{
          data:{uploadsArray:uploadsArray, lang:this.lang},
          width:'80%',
          
        });
      }else{
        this.sweetAlert.errorMessage((res.error) ? res.error: this.lang.transform('lang_internal_server_error'))
      }
    },error => {
      this.sweetAlert.errorMessage((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'))
      this.spinner.hide();
    })
  }

  deleteType(typeIndex:any) {
    this.spinner.show();
    let formData = new FormData();
    let platform_group_type_code_system = this.typevalidate.controls[typeIndex].get(
      'platform_group_type_code_system'
    ).value;
    formData.append('template_id', this.template_id || '');
		formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id ||"" );
    formData.append('platform_group1_code_system', this.platform_group1_code_system || '');
    formData.append('platform_group_type_code_system',platform_group_type_code_system || '');
    this.ds.post('pforms/delete_platform_template', formData).subscribe(res => {
      this.spinner.hide();
      if (res?.status) {
        this.typeformarray.removeAt(typeIndex);
        this.sweetAlert.successMessage(this.lang.transform('lang_deleted_successfully'));
      } else{
        this.sweetAlert.errorMessage((res.error) ? res.error: this.lang.transform('lang_internal_server_error'))
      }
    },error => {
      this.sweetAlert.errorMessage((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'))
      this.spinner.hide();
    })
  }

  getGroup2Ratios(e, indx) {
    this.typevalidate.controls[indx].get(
      'platform_group_type_total_ratio'
    ).setValue(e?.total_ratio);
    this.typevalidate.controls[indx].get(
      'platform_group_type_total'
    ).setValue(e?.total_price);
    this.getTypesTotalRatio();
  }

  getTypesTotalRatio() {
    let type = this.typevalidate.value;
    let ratioArr = type.map(el => el['platform_group_type_total_ratio']);
    let priceArr = type.map(el => el['platform_group_type_total']);
    let typeTotalRatio = parseFloat('0.00');
    let typeTotalPrice = parseFloat('0.00');
    for(let ratio of ratioArr) {
      typeTotalRatio += parseFloat(ratio);
    }
    for(let price of priceArr) {
      typeTotalPrice += parseFloat(price);
    }
    this.type_total_ratio.emit({total_ratio:typeTotalRatio.toFixed(2), total_price:typeTotalPrice.toFixed(2)});
  }

}