<div *ngIf="projects_work_area_id">
	<div class="row">
		<div class="col-12 col-sm-3">
			<div class="content-info">
				<label class="content-info__label">{{
					"lang_zones_no" | language
				}}</label>
				<span>{{ project_setting_data?.total_no_of_zones }}</span>
			</div>
		</div>
		<div class="col-12 col-sm-3">
			<div class="content-info">
				<label class="content-info__label">{{
					"lang_blocks_no" | language
				}}</label>
				<span>{{ project_setting_data?.total_no_of_blocks }}</span>
			</div>
		</div>
		<div class="col-12 col-sm-3">
			<div class="content-info">
				<label class="content-info__label">{{
					"lang_clusters_no" | language
				}}</label>
				<span>{{ project_setting_data?.total_no_of_clusters }}</span>
			</div>
		</div>
		<div class="col-12 col-sm-3">
			<div class="content-info">
				<label class="content-info__label">{{
					"lang_units_no" | language
				}}</label>
				<span>{{ project_setting_data?.total_no_of_units }}</span>
			</div>
		</div>
	</div>
	<hr style="width: 80%; margin: 15px auto" />
	<div class="row pt-3">
		<div class="col-12">
			{{ "lang_project_plan" | language }}
		</div>
		<div class="col-12">
			<div id="axis3-chart" class="axis3-chart"></div>
		</div>
	</div>
	<hr style="width: 80%; margin: 15px auto" />
	<div class="row pt-3">
		<div class="col-12 col-sm-4">
			<div id="project-time-chart" class="project-time-chart"></div>
			<div class="row">
				<div class="col-12 col-sm-6">
					<div class="content-info">
						<label class="content-info__label">{{
							"lang_from" | language
						}}</label>
						<span>{{ project_setting_data?.supervision_start_date }}</span>
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div class="content-info">
						<label class="content-info__label">{{
							"lang_to" | language
						}}</label>
						<span>{{ project_setting_data?.supervision_expiry_date }}</span>
					</div>
				</div>
			</div>
			<hr style="width: 80%; margin: 15px auto" />
			<div class="row">
				<div class="col-12 col-sm-6">
					<div class="content-info">
						<label class="content-info__label">{{
							"lang_total_days" | language
						}}</label>
						<span
							>{{ project_setting_data?.total_days }}
							{{ "lang_day" | language }}</span
						>
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div class="content-info">
						<label class="content-info__label">{{
							"lang_done_days" | language
						}}</label>
						<span
							>{{ project_setting_data?.done_days }}
							{{ "lang_day" | language }}</span
						>
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div class="content-info">
						<label class="content-info__label">{{
							"lang_left_days" | language
						}}</label>
						<span
							>{{ project_setting_data?.left_days }}
							{{ "lang_day" | language }}</span
						>
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div class="content-info">
						<label class="content-info__label">{{
							"lang_late_days" | language
						}}</label>
						<span
							>{{ project_setting_data?.late_days }}
							{{ "lang_day" | language }}</span
						>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-8">
			<div class="row">
				<div class="col-12 d-flex align-items-center justify-content-between">
					<span>{{ "lang_top_count_requests" | language }}</span>
					<div style="width: 100px; position: relative">
						<input
							type="text"
							class="form-control mb-1"
							id="unit_id"
							name="unit_id"
							placeholder="Limit"
							(keypress)="ds?.isNumber($event)"
							[(ngModel)]="topCountRequestsLimit"
						/>
						<i
							class="fa fa-search mnabricon"
							(click)="getTopCountRequests()"
							[attr.style]="
								lang_key == 'ar'
									? 'position: absolute; left: 7px;right:unset; top: 9px; font-size: 18px'
									: 'position: absolute; right: 7px;left:unset; top: 9px; font-size: 18px'
							"
						></i>
					</div>
				</div>
				<div class="col-12 mt-2 top-count-requests">
					<div
						class="table-responsive dropdownyes customResponsive tablefields"
					>
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<th>#</th>
									<th>{{ "lang_platform" | language }}</th>
									<th>
										{{ "lang_platform_code_system" | language }}
									</th>
									<th>{{ "lang_work_level" | language }}</th>
									<th>{{ "lang_total" | language }}</th>
									<th>{{ "lang_transactions" | language }}</th>
									<th>{{ "lang_total_units" | language }}</th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let item of topCountRequests;
										let i = index;
										let odd = odd;
										let even = even
									"
								>
									<td>{{ i + 1 }}</td>
									<td>{{ item?.platform_subject }}</td>
									<td>{{ item?.platform_code_system }}</td>
									<td>{{ item?.platform_work_level_title }}</td>
									<td
										(click)="openTopCountRequests(item)"
										class="link-to-popup"
									>
										{{ item?.total }}
									</td>
									<td
										(click)="openTopCountRequests(item)"
										class="link-to-popup"
									>
										{{ item?.total_transactions }}
									</td>
									<td
										(click)="openTopCountRequests(item)"
										class="link-to-popup"
									>
										{{ item?.total_units }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<hr style="width: 80%; margin: 15px auto" />
	<div class="row pt-3">
		<div class="col-12 d-flex align-items-center justify-content-between mb-3">
			<span>{{ "lang_used_unused_report" | language }}</span>
			<span>
				<mat-slide-toggle
					*ngIf="isAdmin"
					class="ml-3 mr-3"
					[(ngModel)]="report1_trigger"
					(change)="getReport1Data(false)"
					>{{ "lang_admin" | language }}</mat-slide-toggle
				>
				<i
					class="fa fa-file-excel-o mnabricon ml-1 mr-1"
					matTooltip="{{ 'lang_export_excel' | language }}"
					(click)="getReport1Data(true)"
				></i>
				<i
					class="fa fa-filter mnabricon ml-1 mr-1"
					matTooltip="{{ 'lang_advanced_filter' | language }}"
					(click)="onFilterReport1()"
				></i
			></span>
		</div>
		<div class="col-12 col-sm-4 col-md-2 mb-3">
			<div
				class="card-item"
				style="background: linear-gradient(60deg, #26dad2, #00c174)"
			>
				<div class="item-text" style="width: 70%">
					<h5>{{ "lang_total_ok" | language }}</h5>
					<h4>{{ report1_total_ok }}</h4>
				</div>
				<div class="item-icon">
					<i class="pi pi-thumbs-up"></i>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-4 col-md-2 mb-3">
			<div class="card-item">
				<div class="item-text" style="width: 70%">
					<h5>{{ "lang_total_not_used" | language }}</h5>
					<h4>{{ report1_total_notused }}</h4>
				</div>
				<div class="item-icon">
					<i class="pi pi-exclamation-circle"></i>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-4 col-md-2 mb-3">
			<div
				class="card-item"
				style="background: linear-gradient(60deg, #d7688d, #cd3535)"
			>
				<div class="item-text" style="width: 70%">
					<h5>{{ "lang_total_not_ok" | language }}</h5>
					<h4>{{ report1_total_notok }}</h4>
				</div>
				<div class="item-icon">
					<i class="pi pi-times-circle"></i>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-4 col-md-3 mb-3">
			<div
				class="card-item"
				style="background: linear-gradient(60deg, #97a1d7, #673ab7)"
			>
				<div class="item-text" style="width: 70%">
					<h5>{{ "lang_consultant_late" | language }}</h5>
					<h4>{{ lateData?.late_supervision }}</h4>
				</div>
				<div class="item-icon">
					<i class="pi pi-exclamation-circle"></i>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-4 col-md-3 mb-3">
			<div
				class="card-item"
				style="background: linear-gradient(60deg, #97a1d7, #673ab7)"
			>
				<div class="item-text" style="width: 70%">
					<h5>{{ "lang_contractor_late" | language }}</h5>
					<h4>{{ lateData?.late_contractor }}</h4>
				</div>
				<div class="item-icon">
					<i class="pi pi-exclamation-circle"></i>
				</div>
			</div>
		</div>
		<div class="col-0 col-sm-9"></div>
		<div class="col-12 col-sm-3 mb-1" *ngIf="report1_data">
			<div style="display: flex; justify-content: end; margin-bottom: 10px">
				<div class="table-arrows">
					<i class="fa fa-arrow-left" (click)="scrollLeft()"></i>
					<i class="fa fa-arrow-right" (click)="scrollRight()"></i>
				</div>
			</div>
		</div>
		<div class="col-12 mt-2" *ngIf="report1_data">
			<div class="table-container" id="table-container">
				<table class="main-table">
					<thead>
						<tr>
							<th
								style="
									color: #035a99;
									width: 27px;
									border-left: 1px solid rgba(148, 148, 148, 0.747);
								"
								class="sticky"
							>
								#
							</th>
							<th style="color: #035a99; left: 23px" class="sticky">
								{{ "lang_unit" | language }}
							</th>
							<th
								*ngFor="let item of report1_data?.table_headers"
								colspan="3"
								style="color: #035a99; width: 100px"
							>
								{{ item?.platform_group1_title }}
							</th>
						</tr>
						<tr>
							<th colspan="2" class="sticky">
								<div
									style="
										display: flex;
										flex-direction: column;
										justify-content: center;
										align-items: start;
									"
								>
									<mat-checkbox labelPosition="after" [(ngModel)]="show_ok">
										{{ "lang_ok" | language }}
									</mat-checkbox>
									<mat-checkbox labelPosition="after" [(ngModel)]="show_notok">
										{{ "lang_not_ok" | language }}
									</mat-checkbox>
									<mat-checkbox labelPosition="after" [(ngModel)]="show_notuse">
										{{ "lang_not_used" | language }}
									</mat-checkbox>
								</div>
							</th>
							<th
								*ngFor="let item of report1_data?.table_headers"
								style="padding: 0; width: 100px"
								colspan="3"
							>
								<table style="width: 100%; min-height: 50px" class="no-border">
									<tr>
										<th
											class="no-border"
											style="
												border-right: 1px solid rgba(148, 148, 148, 0.747);
												background: none;
												color: #4c4c4c;
											"
											*ngIf="show_ok"
										>
											<i
												class="pi pi-thumbs-up"
												matTooltip="{{ 'lang_ok' | language }}"
											></i>
										</th>
										<th
											class="no-border"
											style="background: none; color: #4c4c4c"
											*ngIf="show_notok"
										>
											<i
												class="pi pi-times-circle"
												matTooltip="{{ 'lang_not_ok' | language }}"
											></i>
										</th>
										<th
											class="no-border"
											style="background: none; color: #4c4c4c"
											*ngIf="show_notuse"
										>
											<i
												class="pi pi-exclamation-circle"
												matTooltip="{{ 'lang_not_used' | language }}"
											></i>
										</th>
									</tr>
								</table>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of report1_data?.records; let i = index">
							<td class="sticky" style="padding: 5px">
								{{ i + 1 }}
							</td>
							<td class="sticky" style="left: 23px; padding: 5px">
								{{ item.unit_title }}
							</td>
							<td *ngFor="let el of item.data" colspan="3" style="padding: 0">
								<table style="width: 100%" class="no-border">
									<tr>
										<td
											class="no-border"
											*ngIf="show_ok && el.OK == 0"
											[attr.style]="
												el.OK != 0
													? 'color: #ffffff;
											cursor: pointer;
											background: #00c174;padding: 5px'
													: 'padding: 5px'
											"
										>
											<span>{{ el.OK }}</span>
										</td>
										<td
											class="no-border"
											*ngIf="show_ok && el.OK != 0"
											[attr.style]="
												el.OK != 0
													? 'color: #ffffff;
											cursor: pointer;
											background: #00c174;padding: 5px'
													: 'padding: 5px'
											"
											(click)="
												openReport1Row(item.unit_id, el?.division_ID, 'OK')
											"
										>
											<span style="color: #ffffff">{{ el.OK }}</span>
										</td>
										<td
											class="no-border"
											*ngIf="show_notok && el.NOT_OK == 0"
											[attr.style]="
												el.NOT_OK != 0
													? 'color: #ffffff;
											cursor: pointer;
											background:#cd3535;padding: 5px'
													: 'padding: 5px'
											"
										>
											<span>{{ el.NOT_OK }}</span>
										</td>
										<td
											class="no-border"
											*ngIf="show_notok && el.NOT_OK != 0"
											[attr.style]="
												el.NOT_OK != 0
													? 'color: #ffffff;
											cursor: pointer;
											background:#cd3535;padding: 5px'
													: 'padding: 5px'
											"
											(click)="
												openReport1Row(item.unit_id, el?.division_ID, 'NOT_OK')
											"
										>
											<span style="color: #ffffff">{{ el.NOT_OK }}</span>
										</td>
										<td
											class="no-border"
											*ngIf="show_notuse && el.NOT_USED == 0"
											[attr.style]="
												el.NOT_USED != 0
													? 'color: #ffffff;
											cursor: pointer;
											background:#00acc1;padding: 5px'
													: 'padding: 5px'
											"
										>
											<span>{{ el.NOT_USED }}</span>
										</td>
										<td
											class="no-border"
											*ngIf="show_notuse && el.NOT_USED != 0"
											[attr.style]="
												el.NOT_USED != 0
													? 'color: #ffffff;
											cursor: pointer;
											background:#00acc1;padding: 5px'
													: 'padding: 5px'
											"
											(click)="
												openReport1Row(
													item.unit_id,
													el?.division_ID,
													'NOT_USED'
												)
											"
										>
											<span style="color: #ffffff">{{ el.NOT_USED }}</span>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div
				*ngIf="report1_data?.records?.length > 0"
				class="col-12 mt-2"
				style="
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
				"
			>
				<p>
					{{ "lang_total" | language }}:
					{{ report1_data?.pages?.total_records }}
				</p>
				<ngb-pagination
					[collectionSize]="report1_data?.pages?.total_records"
					[rotate]="true"
					[maxSize]="3"
					[boundaryLinks]="true"
					[(page)]="report1_pageno"
					[pageSize]="report1_pagesize"
					(pageChange)="getReport1Data()"
				></ngb-pagination>
				<mat-select
					class="customselect"
					[(ngModel)]="report1_pagesize"
					(selectionChange)="getReport1Data()"
				>
					<mat-option [value]="5"
						>5 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option [value]="10"
						>10 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option [value]="20"
						>20 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option [value]="30"
						>30 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option [value]="40"
						>40 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option [value]="50"
						>50 {{ "lang_per_page" | language }}</mat-option
					>
				</mat-select>
			</div>
		</div>
	</div>
</div>
