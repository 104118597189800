import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-quality-management",
  templateUrl: "./quality-management.component.html",
  styleUrls: ["./quality-management.component.scss"],
})
export class QualityManagementComponent implements OnInit {
  @Input() report_data;
  constructor() {}

  ngOnInit(): void {}
}
