import { Component, OnInit } from '@angular/core';
 
declare var $:any;
@Component({
  selector: 'app-profileCreateVacation',
  templateUrl: './profileCreateVacation.component.html',
  styleUrls: ['./profileCreateVacation.component.scss']
})
export class ProfileCreateVacationComponent implements OnInit {
   
  constructor(   ) {}
  ngOnInit(): void {
   
  }
 
  ngAfterViewInit(): void {
     
  }
 
}