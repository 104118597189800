import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrToStr'
})
export class ArrToStrPipe implements PipeTransform {

  transform(value: Array<any>): string {
    return value.map(el => el?.sub_tasks_numbers).toString();
  }
}
