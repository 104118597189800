import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { NgbCalendar, NgbCalendarIslamicUmalqura, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { IslamicI18n } from '@shared/utils/arabicpicker';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n }
  ]
})
export class Step3Component implements OnInit {
  @Input() regForm: FormGroup;
  @Input() action;
  idfilename = this.lang.transform('lang_select_files');
  singleselection = environment.singledropdown;
  isFile:boolean = false;
  banklists:any = [];
  constructor(public router:Router, public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  get f() {
    return this.regForm.controls.bankDetails;
  }
  ngOnInit() {
    this.loadBankDetails();
  }
  loadBankDetails() {
    this.banklists = [];
    this.ds.getActionByUrl([], 'human_resources/getbanks/').subscribe(data => {
      if(data.status) {
        this.banklists = data.banks;
      }
    });
  }
  uploadIDFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.isFile = true;
      this.regForm.get("bankDetails").get("bank_file").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.regForm.get("bankDetails").get("bank_file").setValue('');
      this.idfilename = this.lang.transform('lang_select_files');
    }
  }
  removeFile() {
    this.isFile = false;
    this.regForm.get("bankDetails").get("bank_file").setValue([]);
    this.idfilename = this.lang.transform('lang_select_files');
  }
  step3Submitted() {
    this.regForm.get('bankDetails').get('account_number').markAsTouched();
    this.regForm.get('bankDetails').get('account_number').updateValueAndValidity();
    this.regForm.get('bankDetails').get('bank_short_code').markAsTouched();
    this.regForm.get('bankDetails').get('bank_short_code').updateValueAndValidity();
  }
}
