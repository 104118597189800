import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MatDividerModule } from "@angular/material/divider";
import { PayroleRoutingModule } from "./payrole-routing.module";
import { PayroleListComponent } from "./components/payrole-list/payrole-list.component";
import { SharedModule } from "@shared";
import { PayroleCreateComponent } from "./components/payrole-create/payrole-create.component";
import { MatStepperModule } from "@angular/material/stepper";
import { PayroleAddEmployeesComponent } from "./components/payrole-add-employees/payrole-add-employees.component";
import { MultiSelectModule } from "primeng/multiselect";
import { ShowDeductionsComponent } from "./components/show-deductions/show-deductions.component";
import { ReviewersListComponent } from "./components/reviewers/reviewers-list/reviewers-list.component";
import { AddReviewersComponent } from "./components/reviewers/add-reviewers/add-reviewers.component";
import { StepOneComponent } from "./components/steps/step-one/step-one.component";
import { StepTwoComponent } from "./components/steps/step-two/step-two.component";
import { StepThreeComponent } from "./components/steps/step-three/step-three.component";
import { StepFourComponent } from "./components/steps/step-four/step-four.component";
import { StepFiveComponent } from "./components/steps/step-five/step-five.component";
import { StepSixComponent } from "./components/steps/step-six/step-six.component";
import { RefuseReasonComponent } from "./components/refuse-reason/refuse-reason.component";
import { PayroleViewComponent } from "./components/payrole-view/payrole-view.component";
import { SalaryFileUploadComponent } from "./components/salary-file-upload/salary-file-upload.component";
import { PayroleAspdfViewComponent } from "./components/payrole-aspdf-view/payrole-aspdf-view.component";
import { allStepsComponent } from "./components/requests_steps/steps/steps.component";
import { Step1Component } from "./components/requests_steps/step1/step1.component";
import { Step2Component } from "./components/requests_steps/step2/step2.component";
import { Step3Component } from "./components/requests_steps/step3/step3.component";
import { Step4Component } from "./components/requests_steps/step4/step4.component";

@NgModule({
  declarations: [
    PayroleListComponent,
    PayroleCreateComponent,
    PayroleAddEmployeesComponent,
    ShowDeductionsComponent,
    AddReviewersComponent,
    ReviewersListComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    StepFiveComponent,
    StepSixComponent,

    PayroleViewComponent,
    RefuseReasonComponent,
    SalaryFileUploadComponent,
    PayroleAspdfViewComponent,

    allStepsComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
  ],
  imports: [
    CommonModule,
    MatStepperModule,
    PayroleRoutingModule,
    MultiSelectModule,
    SharedModule,
    MatDividerModule,
  ],
  exports: [
    PayroleListComponent,
    PayroleCreateComponent,
    PayroleAddEmployeesComponent,
    ShowDeductionsComponent,
    AddReviewersComponent,
    ReviewersListComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    StepFiveComponent,
    StepSixComponent,

    PayroleViewComponent,
    RefuseReasonComponent,

    PayroleAspdfViewComponent,
    allStepsComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
  ],
})
export class PayroleModule {}
