import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

import { ShowDeductionsComponent } from "../../show-deductions/show-deductions.component";

import { Observable } from "rxjs";
import Swal from "sweetalert2";
import { SalaryFileUploadComponent } from "../../salary-file-upload/salary-file-upload.component";

@Component({
  selector: "app-step-six",
  templateUrl: "./step-six.component.html",
  styleUrls: ["./step-six.component.scss"],
})
export class StepSixComponent implements OnInit {
  /*   usersList=[]; */
  @Input() stepper: MatStepper;
  @Input() t_id: any = "";
  @Input() requestInfo: any = "";
  @Input() refresh!: Observable<void>;
  @Output() resfreshUsers = new EventEmitter<any>();
  @Output() resfreshView = new EventEmitter<any>();
  @Output() refreshpage = new EventEmitter<any>();
  showUpload = true;
  rejetcedData: any;
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    private router: Router,
    private _sweetAlertService: SweetAlertService,
    private spinner: PreloaderService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    /* if(this.requestInfo.last_request_step == 'HUMAN_RESOURCE_MANAGER'){
      this.get_salary_form_employees()
    } */
    this.getRejectedEmployees();
  }

  /*   ngOnChanges(){
    if(this.t_id != 0 && this.requestInfo.last_request_step == 'REVIEWER'){
      this.get_salary_form_employees()
    }
  }
 */
  allSelected = false;
  /*   responseData :any = ''; */
  @Input() usersList = [];
  @Input() responseData: any = "";

  update_configuration_step() {
    this.spinner.show();
    let formData = new FormData();

    formData.append(`Configration_Step`, "selected_employees_datils");

    this.ds
      .post(`form/FORM_SAL/update_configuration_step/${this.t_id}`, formData)
      .subscribe(
        (data) => {
          if (data.status) {
            Swal.fire(
              this.lang.transform("lang_returned"),
              data.msg,
              "success"
            );
            this.router.navigate(["/tasks/alltasks"]);
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(data.error);
            /*   this.showMsg(data.error, 'ok', 'error-snackbar'); */
          }
        },
        (error) => {
          this._sweetAlertService.errorMessage(error?.error?.error);
          this.spinner.hide();
          /*    this.showMsg(error?.error?.error, 'ok', 'error-snackbar'); */
        }
      );
  }
  returnTask() {
    if (!this.AtLeastOneSelected()) {
      this._sweetAlertService.warningToast(
        this.lang.transform("lang_select_anyone"),
        1500
      );
      return 0;
    }

    Swal.fire({
      title: this.lang.transform("lang_are_you_sure"),
      text: "",
      icon: "warning",
      confirmButtonColor: "#0e3768",
      showCancelButton: true,
      confirmButtonText: this.lang.transform("lang_approve"),
      cancelButtonText: this.lang.transform("lang_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        /* this.spinner.show(); */
        /*   Swal.fire(
          this.lang.transform('lang_deleted'),
          this.lang.transform('lang_success'),
          'success'
        ) */

        let formData = new FormData();
        let currentIndex = 1;
        for (let index = 0; index < this.usersList.length; index++) {
          const element = this.usersList[index];
          if (element.select_status == "selected") {
            formData.append(
              `employee_number[${currentIndex}]`,
              element.employee_number
            );

            currentIndex++;
          }
        }

        this.ds
          .postActionByUrl(
            formData,
            `form/FORM_SAL/return_employees_to_configration_step/${this.t_id}`
          )
          .subscribe(
            (data) => {
              if (data.status) {
                this.update_configuration_step();
              } else {
                this._sweetAlertService.errorMessage(data?.error);
              }
              this.spinner.hide();
            },
            (err) => {
              this._sweetAlertService.errorMessage(err?.error.error);
              this.spinner.hide();
            }
          );

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        /*  Swal.fire(
          `${this.lang.transform('lang_cancel')}`,
          `${this.lang.transform('lang_cancel')+' '+this.lang.transform('lang_completed')}`,
          'error'
        ) */
      }
    });
  }

  selectAll(e) {
    if (e?.target.checked) {
      this.usersList = this.usersList.map((t) => {
        return {
          ...t,
          select_status: "selected",
        };
      });

      this.allSelected = true;
    } else {
      this.usersList = this.usersList.map((t) => {
        return {
          ...t,
          select_status: "unselected",
        };
      });
    }
    /*   this.oneSelected = false
    this.allSelected = false */
  }

  makeItemCollapsed(index) {
    this.usersList[index].collapsed = !this.usersList[index].collapsed;
  }

  employee_details(emp_id, index) {
    if (this.usersList[index].details) {
      this.makeItemCollapsed(index);
      return 0;
    }
    this.spinner.show();
    this.ds
      .getActionByUrl([], `form/FORM_SAL/employee_details/${emp_id}`)
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            /* this.usersList = res.records.map(rec=>{
          return {
            ...rec,
            details:''
          }
        }) */
            this.makeItemCollapsed(index);
            this.usersList[index].details = res.records[0];
          } else {
            this.spinner.hide();
            /* this.usersList = []; */
            this._sweetAlertService.errorMessage(res?.error);
          }
        },
        (err) => {
          this.spinner.hide();
          this._sweetAlertService.errorMessage(err?.error.error);
          /*       this.usersList = []; */
        }
      );
  }
  makeTooltip(item, reject = true) {
    if (reject) {
      let data = `${this.lang.transform("lang_name")}: ${item.name}\n 
      ${this.lang.transform("lang_date")}: ${item.date}\n 
      ${this.lang.transform("lang_reason")}: ${
        item.reason ? item.reason : "--"
      }\n`;
      return data;
    } else {
      let data = `${this.lang.transform("lang_name")}: ${item.name}\n 
      ${this.lang.transform("lang_date")}: ${item.date}\n`;
      return data;
    }
  }

  checkEachItemHR(e, index) {
    if (e?.target.checked) {
      this.usersList[index].select_status = "selected";
    } else {
      this.usersList[index].select_status = "unselected";
    }
    this.checkSelect();
  }

  checkSelect() {
    let allLocalSelected = true;
    for (let index = 0; index < this.usersList.length; index++) {
      const element = this.usersList[index];
      if (element.select_status == "unselected") {
        allLocalSelected = false;
      }
    }
    if (allLocalSelected) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }
  download_PDF(type = "pdf") {
    let url = "";
    if (type == "pdf") {
      url = `form/FORM_SAL/get_preview/${this.t_id}`;
    } else {
      url = `form/FORM_SAL/build_excel1/${this.t_id}`;
    }
    this.spinner.show();
    this.ds.getActionByUrl([], url).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.ds.downloadFile(res, "Salary");
        } else {
          this._sweetAlertService.errorMessage(res?.error);
        }
      },
      (err) => {
        this._sweetAlertService.errorMessage(err?.error.error);
        this.spinner.hide();
      }
    );
  }

  dialogRef: any;
  SalaryDialog() {
    this.dialogRef = this.dialog.open(SalaryFileUploadComponent, {
      /*  maxWidth:'1000px', */
      width: "70%",
      autoFocus: false,
      restoreFocus: false,
      data: {
        t_id: this.t_id,
      },
    });
    this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.resfreshView.emit();
        this.refreshpage.emit();
      }
    });
  }

  AtLeastOneSelected() {
    let selected = false;
    for (let index = 0; index < this.usersList.length; index++) {
      const element = this.usersList[index];
      if (element.select_status == "selected") {
        selected = true;
        break;
      }
    }
    return selected;
  }
  AllSelected() {
    let selected = true;
    for (let index = 0; index < this.usersList.length; index++) {
      const element = this.usersList[index];
      if (element.select_status == "unselected") {
        selected = false;
        break;
      }
    }
    return selected;
  }
  userTransactions = [];
  all_financial_transactions(emp_id, info, type) {
    this.spinner.show();
    this.ds
      .getActionByUrl(
        [],
        `form/FORM_SAL/all_financial_transactions/${this.t_id}/${emp_id}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.userTransactions = res.records;

            this.openDeduction(info, type);
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(res.error);
          }
        },
        (err) => {
          this.spinner.hide();
          this._sweetAlertService.errorMessage(err?.error?.error);
        }
      );
  }
  openDeduction(info, type) {
    const dialogRef = this.dialog.open(ShowDeductionsComponent, {
      width: "700px",
      data: {
        info: info,
        userTransactions: this.userTransactions,
        t_id: this.t_id,
        type: type,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.get_salary_form_employees();
      }
    });
  }
  get_salary_form_employees() {
    this.spinner.show();
    this.ds
      .getActionByUrl(
        [],
        `form/FORM_SAL/get_salary_form_employees/${this.t_id}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.responseData = res;
            this.allSelected = false;
            this.usersList = res.records.map((rec) => {
              let action = rec.reviews.map((rev) => {
                return {
                  action: rev.value == "Reject" ? false : true,
                  name: "Eslam Ahmed 2",
                  date: "20/12/2020",
                  reason: rev.comment,
                };
              });
              return {
                ...rec,
                details: "",
                select_status: "unselected",
                reviews: action,
                employee_name:
                  rec.firstname_english + " " + rec.lastname_english,
              };
            });
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(res?.error);
            this.usersList = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.usersList = [];
          this._sweetAlertService.errorMessage(err?.error.error);
        }
      );
  }
  total = 0;
  size = 10;
  page = 1;
  /* employeesByNames = []
  employeesByProjects = []
  employeesByBranches = [] */

  load_by_page_no(page) {
    this.page = page;
    this.getRejectedEmployees();
  }
  load_by_page_size(size) {
    this.size = size;
    this.page = 1;
    this.getRejectedEmployees();
  }
  getRejectedEmployees() {
    this.spinner.show();
    let param = new FormData();
    param.append("transaction_request_id", this.t_id);
    this.ds
      .getActionByUrl(
        this.ds.formData2string(param),
        `form/FORM_SAL/get_rejected_employees/${this.page}/${this.size}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.rejetcedData = res.records;
            this.total = res?.page?.total_records;
          } else {
            this.spinner.hide();
            this.rejetcedData = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.rejetcedData = [];
        }
      );
  }
}
