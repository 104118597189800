import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { SnagExcelHistoryComponent } from './snag-excel-history/snag-excel-history.component';
import { SnaglistAddComponent } from './snaglist-add/snaglist-add.component';
import { SnaglistEditComponent } from './snaglist-edit/snaglist-edit.component';

@Component({
  selector: 'app-snaglist',
  templateUrl: './snaglist.component.html',
  styleUrls: ['./snaglist.component.scss']
})
export class SnaglistComponent implements OnInit {
  lang_key        = localStorage.getItem('lang_key') || 'en';
  searchKey       = '';
  snag_list       = null;
  page            = 1;
  pageSize        = 10;
  group1_list     =  [];
  group1_selected = null;
  group2_list     =  [];
  group2_selected = null;
  constructor(private ds: DataService, private spinner: PreloaderService, private lang:LanguagePipe, private sweetAlert: SweetAlertService, 
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.get_group1_list();
    this.list_of_datas();
  }

  list_of_datas() {
    let param = new FormData();
    param.append('search_key',  this.searchKey || '');
    param.append('platform_group1_code_system', this.group1_selected || '');
    param.append('platform_group2_code_system', this.group2_selected || '');
    this.spinner.show();
    this.ds.getActionByUrl(this.ds.formData2string(param), `p/snag/list/${this.page}/${this.pageSize}`).subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.snag_list = res;
      } else {
        this.snag_list = null;
      }
    }, error=> {
      this.spinner.hide();
      this.snag_list = null;
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    })
  }

  get_group1_list() {
    let param = new FormData();
    param.append('search_key',  this.searchKey || '');
    param.append('lang_key', this.lang_key);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgone').subscribe(res => {
      if(res.status) {
        this.group1_list = res?.records;
      } else {
        this.group1_list = [];
      }
    }, error => {
      this.group1_list = [];
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    });
  }

  get_group2_list() {
    let param = new FormData();
    param.append('search_key',  this.searchKey || '');
    param.append('lang_key', this.lang_key);
    param.append('platform_group1_code_system', this.group1_selected || '');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpgtwo').subscribe(res => {
      if(res.status) {
        this.group2_list = res?.records;
      } else {
        this.group2_list = [];
      }
    }, error => {
      this.group2_list = [];
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    });
  }

  onAddSnaglist() {
    const dialogRef = this.dialog.open(SnaglistAddComponent, {
      width:'900px'
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.list_of_datas();
      }
    })
  }

  onEditSnaglist(row) {
    const dialogRef = this.dialog.open(SnaglistEditComponent, {
      width:'900px',
      data: row
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.list_of_datas();
      }
    })
  }

  deleteSnag(id) {
    this.sweetAlert.confirmMessage(this.lang.transform('lang_are_you_sure'), this.lang.transform('lang_yes')).then(res => {
      if(res?.isConfirmed) {
        this.spinner.show();
        this.ds.deleteActionByUrl([id], 'p/snag/delete').subscribe(res => {
          this.spinner.hide();
          if(res.status) {
            this.list_of_datas();
            this.sweetAlert.successToast(res.msg, 2000);
          } else {
            this.sweetAlert.errorToast(res.error, 2000);
          }
        }, error => {
          this.spinner.hide();
          this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
        });
      }
    });
  }

  downloadSnaglistExcel() {
    this.spinner.show();
    this.ds.get('p/snag/download').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.ds.downloadFile(res);
        this.sweetAlert.successMessage(res?.msg);
      } else {
        this.sweetAlert.errorToast(res.error, 2000);
      }
    }, error => {
      this.spinner.hide();
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    })
  }
  uploadSnaglistExcel(files) {
    this.spinner.show();
    let formData = new FormData();
    formData.append('import_snag_note_file', files[0]);
    this.ds.post('p/snag/upload', formData).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.list_of_datas();
        this.sweetAlert.successMessage(res?.msg);
      } else {
        this.sweetAlert.errorToast(res.error, 2000);
      }
    }, error => {
      this.spinner.hide();
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    });
    document.getElementById('attachmentInput')['value'] = '';
  }
  openSnagExcelHistory() {
    const dialogRef = this.dialog.open(SnagExcelHistoryComponent, {
      width:'900px',
      minHeight:'200px',
      autoFocus: false
    });
  }
}
