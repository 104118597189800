import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PreloaderService, SettingsService } from '@core';
import { DOCUMENT } from '@angular/common';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
@Component({
  selector: 'app-translate',
  template: `
    <button mat-icon-button class="matero-toolbar-button" [matMenuTriggerFor]="menu">
      <mat-icon>translate</mat-icon>
    </button>
    <mat-menu class="header-menu" #menu="matMenu">
      <button mat-menu-item *ngFor="let lang of languages" (click)="useLanguage(lang.lang_key)">
        <span>{{ lang.lang_name | language }}</span>
      </button>
    </mat-menu>
  `,
  styles: [],
})
export class TranslateComponent implements OnInit {
  options = this.settings.getOptions();
  @Output() optionsChange = new EventEmitter<object>();
  languages:any = this.ds.languages;
  @Input() login;
  constructor(private translate: TranslateService, private settings: SettingsService,
    private spinner : PreloaderService,
    private ds: DataService,
    @Inject(DOCUMENT) private document: Document,
    private ls: LocalStorage,
    ) {
  }
  ngOnInit() {}
  useLanguage(language: string) {
    let dir = (language == "en") ? 'ltr' : 'rtl';
    localStorage.setItem('lang_key', language);
    if(localStorage.getItem('direction') != dir) {
      localStorage.setItem('direction', dir);
      this.spinner.show();
      this.ds.get('change_lang/' + language).subscribe(response => {
        this.spinner.hide();
        if (response.status) {
          window.location.reload();
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }
}
