<div class="card issues">
  <div class="card-header">{{ "lang_issues" | language }}</div>
  <div class="card-body pad0">
    <div class="mbrl15">
      <div class="row">
        <div class="col-sm-3 col-xs-12 mb-10">
          <select
            matTooltip="{{ 'lang_type' | language }}"
            [(ngModel)]="issue_type"
            (change)="getIssues()"
            class="form-control almnabrformcontrol"
          >
            <option value="">{{ "lang_all" | language }}</option>
            <option
              value="{{ item?.value }}"
              *ngFor="let item of issue_type_list"
            >
              {{ item?.label }}
            </option>
          </select>
        </div>
        <div class="col-sm-3 col-xs-12 mb-10">
          <select
            matTooltip="{{ 'lang_issue_level' | language }}"
            [(ngModel)]="issue_level"
            (change)="getIssues()"
            class="form-control almnabrformcontrol"
          >
            <option value="">{{ "lang_all" | language }}</option>
            <option
              value="{{ item?.value }}"
              *ngFor="let item of issue_level_list"
            >
              {{ item?.label }}
            </option>
          </select>
        </div>
        <div class="col-sm-3 col-xs-12 mb-10">
          <select
            matTooltip="{{ 'lang_status' | language }}"
            [(ngModel)]="issue_status"
            (change)="getIssues()"
            class="form-control almnabrformcontrol"
          >
            <option value="">{{ "lang_all" | language }}</option>
            <option
              value="{{ item?.value }}"
              *ngFor="let item of issue_status_list"
            >
              {{ item?.label }}
            </option>
          </select>
        </div>
        <div class="col-sm-3 col-xs-12 mb-10">
          <button
            id="btnSearch"
            type="button"
            mat-raised-button
            color="primary"
            class="add-btn"
            (click)="onAddUpdateIssue(createupdateissue)"
          >
            <i class="fa fa-plus mr-2 ml-2 add-btn-icon"></i>
            <span class="add-btn-text">{{ "lang_add" | language }}</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive dropdownyes customResponsive">
            <table border="1" class="table CustomTable">
              <thead>
                <tr>
                  <td>#</td>
                  <td>
                    {{ "lang_title" | language }}
                  </td>
                  <td>{{ "lang_type" | language }}</td>
                  <td>{{ "lang_issue_level" | language }}</td>
                  <td>{{ "lang_start_date" | language }}</td>
                  <td>{{ "lang_issue_expected_closure_date" | language }}</td>
                  <td>{{ "lang_end_date" | language }}</td>
                  <td>{{ "lang_issue_owner" | language }}</td>
                  <td>{{ "lang_ondate" | language }}</td>
                  <td>{{ "lang_status" | language }}</td>
                  <td>{{ "lang_action" | language }}</td>
                </tr>
              </thead>
              <tbody *ngIf="issues_list.length" class="AppendList">
                <tr
                  *ngFor="
                    let list of issues_list;
                    let i = index;
                    let odd = odd;
                    let even = even
                  "
                  [ngClass]="{ odd: odd, even: even }"
                >
                  <td>{{ i + 1 }}</td>
                  <td *ngIf="lang_key == 'en'">
                    {{ list.issue_description_en }}
                  </td>
                  <td *ngIf="lang_key == 'ar'">
                    {{ list.issue_description_ar }}
                  </td>
                  <td>{{ list.issue_type }}</td>
                  <td>{{ list.issue_level }}</td>
                  <td>{{ list.issue_start_date }}</td>
                  <td>{{ list.issue_expected_closure_date }}</td>
                  <td>{{ list.issue_end_date }}</td>
                  <td>{{ list.issue_owner }}</td>
                  <td>{{ list.created_at }}</td>
                  <td>
                    <span
                      [ngClass]="{
                        badgesuccess: list.issue_status == 'open',
                        badgered: list.issue_status == 'close'
                      }"
                    >
                      {{ list.issue_status }}
                    </span>
                  </td>
                  <td>
                    <i
                      matTooltip="{{ 'lang_edit' | language }}"
                      (click)="onAddUpdateIssue(createupdateissue, list)"
                      class="fa fa-pencil-square-o fa-icon mnabricon mr-2 ml-2"
                    ></i>
                    <i
                      matTooltip="{{ 'lang_delete' | language }}"
                      (click)="deleteIssue(list)"
                      class="fa fa-trash fa-icon red"
                    ></i>
                    <i
                      [matMenuTriggerFor]="menu"
                      matTooltip="{{ 'lang_change_status' | language }}"
                      class="fa fa-refresh fa-icon mnabricon mr-2 ml-2"
                    ></i>
                    <mat-menu #menu="matMenu">
                      <button
                        (click)="onChangeIssueStatus(list, 'open')"
                        mat-menu-item
                      >
                        <span>{{ "lang_open" | language }}</span>
                      </button>
                      <button
                        mat-menu-item
                        (click)="onChangeIssueStatus(list, 'close')"
                      >
                        <span>{{ "lang_close" | language }}</span>
                      </button>
                    </mat-menu>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="!issues_list.length" class="AppendList">
                <tr class="odd">
                  <td colspan="11" align="center">
                    {{ "lang_no_data" | language }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="issues_list?.length" style="padding: 0 15px">
      <div class="row responsive_pagination">
        <div
          class="col-lg-3 col-sm-3 mb-10"
          style="height: fit-content; margin: auto 0"
        >
          {{ "lang_total" | language }} : {{ total_records }}
        </div>
        <div
          class="col-lg-5 col-sm-5 mb-10"
          style="display: flex; justify-content: center"
        >
          <ngb-pagination
            class="m-auto"
            [collectionSize]="total_records"
            [rotate]="true"
            [ellipses]="false"
            [maxSize]="3"
            [boundaryLinks]="true"
            [(page)]="issues_pageno"
            [pageSize]="issues_pagesize"
            (pageChange)="getIssues()"
          ></ngb-pagination>
        </div>
        <div
          class="col-lg-3 col-sm-3"
          style="display: flex; justify-content: end; margin: auto"
        >
          <mat-select
            [(ngModel)]="issues_pagesize"
            (selectionChange)="getIssues()"
          >
            <mat-option class="text-center" value="10"
              >10 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" value="20"
              >20 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" value="50"
              >50 {{ "lang_per_page" | language }}
            </mat-option>
            <mat-option class="text-center" value="100"
              >100 {{ "lang_per_page" | language }}</mat-option
            >
            <mat-option class="text-center" value="500"
              >500 {{ "lang_per_page" | language }}</mat-option
            >
          </mat-select>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #createupdateissue>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="!isEdit">{{ "lang_add" | language }}</h4>
      <h4 class="modal-title" *ngIf="isEdit">{{ "lang_edit" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="issue_description_en">{{
                "lang_title_english" | language
              }}</label>
              <input
                type="text"
                class="form-control"
                id="issue_description_en"
                name="issue_description_en"
                formControlName="issue_description_en"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="issue_description_ar">{{
                "lang_title_arabic" | language
              }}</label>
              <input
                type="text"
                class="form-control"
                id="issue_description_ar"
                name="issue_description_ar"
                formControlName="issue_description_ar"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="type"
                >{{ "lang_type" | language }}:<span class="red">*</span></label
              >
              <select
                class="form-control almnabrformcontrol"
                formControlName="issue_type"
              >
                <option value="">{{ "lang_choose_options" | language }}</option>
                <option
                  value="{{ item?.value }}"
                  *ngFor="let item of issue_type_list"
                >
                  {{ item?.label }}
                </option>
              </select>
              <mat-error
                *ngIf="
                  form.controls['issue_type'].touched &&
                  form.controls['issue_type'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="issue_level"
                >{{ "lang_issue_level" | language }}:<span class="red"
                  >*</span
                ></label
              >
              <select
                class="form-control almnabrformcontrol"
                formControlName="issue_level"
              >
                <option value="">{{ "lang_choose_options" | language }}</option>
                <option
                  value="{{ item?.value }}"
                  *ngFor="let item of issue_level_list"
                >
                  {{ item?.label }}
                </option>
              </select>
              <mat-error
                *ngIf="
                  form.controls['issue_level'].touched &&
                  form.controls['issue_level'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="issue_status"
                >{{ "lang_status" | language }}:<span class="red"
                  >*</span
                ></label
              >
              <select
                class="form-control almnabrformcontrol"
                formControlName="issue_status"
              >
                <option value="">{{ "lang_choose_options" | language }}</option>
                <option
                  value="{{ item?.value }}"
                  *ngFor="let item of issue_status_list"
                >
                  {{ item?.label }}
                </option>
              </select>
              <mat-error
                *ngIf="
                  form.controls['issue_status'].touched &&
                  form.controls['issue_status'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="issue_owner">{{
                "lang_issue_owner" | language
              }}</label>
              <input
                type="text"
                class="form-control"
                id="issue_owner"
                name="issue_owner"
                formControlName="issue_owner"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="issue_action_by">{{
                "lang_issue_action_by" | language
              }}</label>
              <input
                type="text"
                class="form-control"
                id="issue_action_by"
                name="issue_action_by"
                formControlName="issue_action_by"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <label for="issue_recommended_action">{{
                "lang_issue_recommended_action" | language
              }}</label>
              <input
                type="text"
                class="form-control"
                id="issue_recommended_action"
                name="issue_recommended_action"
                formControlName="issue_recommended_action"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <mat-label class="d-block">{{
                "lang_start_date" | language
              }}</mat-label>
              <mat-form-field appearance="outline" style="width: 100%">
                <input
                  matInput
                  [matDatepicker]="issue_start_date"
                  placeholder="{{ 'lang_start_date' | language }}"
                  readonly
                  formControlName="issue_start_date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="issue_start_date"
                ></mat-datepicker-toggle>
                <mat-datepicker #issue_start_date></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <mat-label class="d-block">{{
                "lang_issue_expected_closure_date" | language
              }}</mat-label>
              <mat-form-field appearance="outline" style="width: 100%">
                <input
                  matInput
                  [matDatepicker]="issue_expected_closure_date"
                  placeholder="{{
                    'lang_issue_expected_closure_date' | language
                  }}"
                  readonly
                  formControlName="issue_expected_closure_date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="issue_expected_closure_date"
                ></mat-datepicker-toggle>
                <mat-datepicker #issue_expected_closure_date></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <div class="form-group">
              <mat-label class="d-block">{{
                "lang_end_date" | language
              }}</mat-label>
              <mat-form-field appearance="outline" style="width: 100%">
                <input
                  matInput
                  [matDatepicker]="issue_end_date"
                  placeholder="{{ 'lang_end_date' | language }}"
                  readonly
                  formControlName="issue_end_date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="issue_end_date"
                ></mat-datepicker-toggle>
                <mat-datepicker #issue_end_date></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              data-dismiss="modal"
              (click)="modalRef.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
