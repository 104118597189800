<section
  class="panel-expansion matborderno"
  *ngIf="branch_id && branch_id != '0' && mention_allow"
>
  <mat-accordion class="accounts_permissions">
    <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
      <i class="together-icon">
        <i
          (click)="emptypermissionfields(); openModal(createACGroups)"
          class="fa fa-plus s2icon mnabricon"
          matTooltip="{{ 'lang_permit_mentions' | language }}"
        ></i>
      </i>
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_permit_mentions" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <div class="mbrl15">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
              <ng-select
                [items]="groups"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                clearAllText="Clear"
                appendTo="body"
                [(ngModel)]="filltergroup"
                placeholder="{{ 'lang_group' | language }}"
                (change)="spinner.show(); loadpermissionmention()"
              >
              </ng-select>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
              <ng-select
                [items]="users"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                clearAllText="Clear"
                appendTo="body"
                [(ngModel)]="fillterusers"
                placeholder="{{ 'lang_users' | language }}"
                (change)="spinner.show(); loadpermissionmention()"
              >
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive dropdownyes customResponsive">
                <table border="1" class="table CustomTable">
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>
                        <div
                          class="checkbox"
                          matTooltip="{{ 'lang_check_all' | language }}"
                        >
                          <input
                            type="checkbox"
                            class="allItemsAcp checkbox"
                            id="allItemsAcp"
                            (click)="checkAllItemsAcp($event)"
                          />
                          <label for="checkbox"></label>
                        </div>
                      </td>
                      <td>{{ "lang_username" | language }}</td>
                      <td>{{ "lang_branch" | language }}</td>
                      <td>{{ "lang_group" | language }}</td>
                      <td>{{ "lang_permission" | language }}</td>
                      <td>{{ "lang_writer" | language }}</td>
                      <td>{{ "lang_ondate" | language }}</td>
                    </tr>
                  </thead>
                  <tbody *ngIf="acdata.length != '0'" class="AppendList">
                    <tr
                      *ngFor="
                        let list of acdata.records;
                        let i = index;
                        let odd = odd;
                        let even = even
                      "
                      [ngClass]="{ odd: odd, even: even }"
                      class="makeback removerow{{ list.mention_id }}"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>
                        <div
                          class="checkbox"
                          matTooltip="{{ 'lang_check' | language }}"
                        >
                          <input
                            type="checkbox"
                            class="eachItemAcp checkbox"
                            value="{{ list.mention_id }}"
                            (click)="checkEachItemAcp($event)"
                          />
                          <label for="checkbox"></label>
                        </div>
                      </td>
                      <td *ngIf="list.mention_name">{{ list.mention_name }}</td>
                      <td *ngIf="!list.mention_name">null</td>
                      <td>{{ list.branch_name }}</td>
                      <td>{{ list.group_name }}</td>
                      <td *ngIf="list.private_value == 'all'">
                        {{ list.permitname }} ({{ "lang_all" | language }})
                      </td>
                      <td *ngIf="list.private_value != 'all'">
                        {{ list.permitname }} (ID - {{ list.private_value }})
                      </td>
                      <td *ngIf="list.creator_name">{{ list.creator_name }}</td>
                      <td *ngIf="!list.creator_name">null</td>
                      <td>{{ list.create_date }}</td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="acdata.length == '0'" class="AppendList">
                    <tr class="odd">
                      <td colspan="8" align="center">{{ lodingdatas }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="acdata.length != '0'" class="card-footer padb0">
          <div class="row responsive_pagination">
            <div
              *ngIf="able2delete"
              class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10"
            >
              <i
                class="fa fa-trash-o faicon red deleteIcon"
                (click)="deleteBulkDataAcp()"
                aria-hidden="true"
                matTooltip="{{ 'lang_delete' | language }}"
              ></i>
            </div>
            <div
              class="col-lg-3 col-sm-3 mb-10"
              style="height: fit-content; margin: auto 0"
            >
              {{ "lang_total" | language }} : {{ acdata.page.total_records }}
            </div>
            <div
              class="col-lg-5 col-sm-5 mb-10"
              style="display: flex; justify-content: center"
            >
              <ngb-pagination
                class="m-auto"
                [collectionSize]="acdata?.page?.total_records"
                [rotate]="true"
                [ellipses]="false"
                [maxSize]="3"
                [boundaryLinks]="true"
                [(page)]="acpage_no"
                [pageSize]="acpage_size"
                (pageChange)="loadpageac($event)"
              ></ngb-pagination>
            </div>
            <div
              class="col-lg-3 col-sm-3"
              style="display: flex; justify-content: end; margin: auto"
            >
              <mat-select
                style="max-width: 150px"
                [(ngModel)]="acpage_size"
                (selectionChange)="acloadpagesize($event.value)"
              >
                <mat-option class="text-center" value="10"
                  >10 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="20"
                  >20 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="50"
                  >50 {{ "lang_per_page" | language }}
                </mat-option>
                <mat-option class="text-center" value="100"
                  >100 {{ "lang_per_page" | language }}</mat-option
                >
                <mat-option class="text-center" value="500"
                  >500 {{ "lang_per_page" | language }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
<ng-template #createACGroups>
  <div class="modal-content">
    <div class="modal-header mb-3">
      <h4 class="modal-title">{{ "lang_permit_mentions" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="form" (ngSubmit)="savepermissions()">
      <div class="modal-body">
        <input type="hidden" formControlName="branch_id" />
        <div class="form-group">
          <label
            >{{ "lang_group" | language }} :<span class="red">*</span></label
          >
          <select
            class="form-control almnabrformcontrol"
            formControlName="group_id"
          >
            <option value="">{{ "lang_choose_options" | language }}</option>
            <option *ngFor="let group of groups" value="{{ group.value }}">
              {{ group.label }}
            </option>
          </select>
          <mat-error
            *ngIf="
              form.controls['group_id'].touched &&
              form.controls['group_id'].errors?.required
            "
          >
            {{ "lang_field_required" | language }}
          </mat-error>
        </div>
        <div class="form-group">
          <label>{{ "lang_users" | language }}:</label>
          <ng-select
            [items]="users"
            [multiple]="true"
            bindLabel="label"
            bindValue="value"
            clearAllText="Clear"
            appendTo="body"
            formControlName="users_id"
            placeholder="{{ 'lang_users' | language }}"
          >
          </ng-select>
          <mat-error
            *ngIf="
              form.controls['users_id'].touched &&
              form.controls['users_id'].errors?.required
            "
          >
            {{ "lang_field_required" | language }}
          </mat-error>
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              data-dismiss="modal"
              (click)="modalRef.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
