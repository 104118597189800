<div class="row">
	<div class="col-12 col-sm-4 mb-2">
		<button
			class="albutton mnabrbutton"
			mat-raised-button
			(click)="toggleSort()"
		>
			<i class="fa fa-sort"></i>
			{{ "lang_sorting_order" | language }}
		</button>
	</div>
	<div class="col-12 col-sm-4 mb-2">
		<button
			class="albutton mnabrbutton"
			mat-raised-button
			(click)="toggleFilter()"
		>
			<i class="fa fa-filter"></i>
			{{ "lang_advanced_filter" | language }}
		</button>
	</div>
	<div class="col-12 col-sm-4 mb-2">
		<button
			class="albutton mnabrbutton"
			mat-raised-button
			(click)="exportExcel()"
			*ngIf="showExel && TransactionsData.length"
		>
			<i class="fa fa-file-excel-o"></i>
			{{ "lang_export_excel" | language }}
		</button>
	</div>
</div>
<div class="row pt-3">
	<div
		[ngClass]="{
			'col-12 col-md-8': showFilter || showSort,
			'col-12': !showFilter || !showSort
		}"
		[attr.style]="isMob ? 'order:2' : 'order:1'"
	>
		<div class="row">
			<ng-container *ngIf="TransactionsData.length && !isloading">
				<ng-container *ngFor="let item of TransactionsData; let i = index">
					<div
						[ngClass]="{
							'col-12 col-sm-6 col-md-4': !showFilter || !showSort,
							'col-12 col-sm-6 col-md-6': showFilter || showSort
						}"
					>
						<mat-card
							class="card"
							[style.border-left-color]="
								item?.color == 'GREEN'
									? '#28a745 !important'
									: item?.color == 'RED'
									? '#dc3545 !important'
									: '#fff !important'
							"
						>
							<mat-card-title class="card-title">
								<div class="d-flex">
									<span *ngIf="item?.result_code">
										<span class="result_code">{{
											item?.result_code
										}}</span></span
									>
									<div class="ml-3 mr-3" *ngIf="item?.file_path">
										<i
											class="fa fa-file-pdf-o"
											style="color: #0e3768; font-size: 25px"
											matTooltip="{{ 'lang_preview' | language }}"
											(click)="
												ds?.preview_pdf_file(
													item?.file_path,
													item?.group_type_name
												)
											"
										></i>
									</div>
								</div>
								<mat-menu
									#menu="matMenu"
									[overlapTrigger]="false"
									xPosition="{{ lang_key == 'en' ? 'before' : 'after' }}"
								>
									<button
										mat-menu-item
										routerLink="/transactions/form/{{
											item?.transaction_key
										}}/vr/{{ item?.transaction_request_id }}"
									>
										<mat-icon style="color: rgba(0, 0, 0, 0.54) !important"
											>assignment</mat-icon
										>
										<span>{{ "lang_view" | language }}</span>
									</button>
								</mat-menu>
								<button mat-icon-button [matMenuTriggerFor]="menu">
									<i class="fa fa-ellipsis-v"></i>
								</button>
							</mat-card-title>
							<mat-card-content>
								<div class="row pt-3">
									<div class="col-12">
										<div class="card-body-table">
											<table style="width: 100%">
												<tr *ngIf="item?.group_type_name">
													<td
														colspan="2"
														style="
															text-align: center;
															text-transform: uppercase;
															color: #0e3768 !important;
															font-weight: 600;
														"
													>
														{{ item?.group_type_name }}
													</td>
												</tr>
												<tr>
													<th>{{ "lang_platform_title" | language }}</th>
													<td>
														<span
															*ngIf="item?.platform_name?.length >= 30"
															matTooltip="{{ item?.platform_name }}"
															>{{
																item?.platform_name | shortenLength: 30
															}}</span
														>
														<span *ngIf="item?.platform_name?.length <= 30">{{
															item?.platform_name
														}}</span>
													</td>
												</tr>
												<tr>
													<th>{{ "lang_group_one" | language }}</th>
													<td>{{ item?.group1_name }}</td>
												</tr>
												<tr>
													<th>{{ "lang_group_two" | language }}</th>
													<td>{{ item?.group2_name }}</td>
												</tr>
												<tr>
													<th>{{ "lang_platform_code_system" | language }}</th>
													<td style="direction: ltr">
														<span style="direction: ltr">{{
															item?.platform_code_system
														}}</span>
													</td>
												</tr>
												<tr>
													<th>{{ "lang_unit" | language }}</th>
													<td>{{ item?.unit_id }}</td>
												</tr>
												<tr>
													<th>{{ "lang_by_phases" | language }}</th>
													<td>{{ item?.phase_short_code }}</td>
												</tr>
												<tr>
													<th>{{ "lang_level" | language }}</th>
													<td>
														{{ item?.level_name }}
													</td>
												</tr>
												<tr>
													<th>{{ "lang_barcode" | language }}</th>
													<td style="direction: ltr">
														<span style="direction: ltr">{{
															item?.barcode
														}}</span>
													</td>
												</tr>
												<tr>
													<th>{{ "lang_request_number" | language }}</th>
													<td>{{ item?.transaction_request_id }}</td>
												</tr>
												<tr>
													<th>{{ "lang_last_step_opened" | language }}</th>
													<td>{{ item?.transaction_request_last_step }}</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</mat-card-content>
						</mat-card>
					</div>
				</ng-container>
			</ng-container>
			<div
				class="col-12"
				style="
					min-height: 200px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
				"
				*ngIf="TransactionsData.length == 0 && !isloading"
			>
				<p>{{ "lang_no_data" | language }}</p>
			</div>
			<div
				class="col-12"
				style="
					min-height: 400px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
				"
				*ngIf="isloading"
			>
				<mat-spinner></mat-spinner>
			</div>
		</div>
		<div class="row pt-2">
			<div
				class="col-12 col-sm-4 d-flex align-items-center"
				*ngIf="TransactionsData.length != 0"
			>
				<p style="font-size: 16px; margin: 0">
					{{ "lang_total" | language }}: {{ collection_size }}
				</p>
			</div>
			<div
				class="col-12 col-sm-6 mt-2 mt-sm-0 d-flex align-items-center justify-content-center"
				*ngIf="TransactionsData.length != 0"
			>
				<ngb-pagination
					[collectionSize]="collection_size"
					[(page)]="page_no"
					[maxSize]="paginationMaxSize"
					[pageSize]="page_size"
					[boundaryLinks]="true"
					[rotate]="true"
					(pageChange)="onPageChange()"
				></ngb-pagination>
			</div>
			<div
				class="col-7 col-sm-2 mt-2 mt-sm-0 d-flex align-items-center justify-content-end"
				*ngIf="TransactionsData.length != 0"
			>
				<mat-select
					[(value)]="page_size"
					(selectionChange)="load_by_page_size($event)"
				>
					<mat-option [value]="10"
						>10 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option [value]="20"
						>20 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option [value]="50"
						>50 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option [value]="100"
						>100 {{ "lang_per_page" | language }}</mat-option
					>
				</mat-select>
			</div>
		</div>
	</div>
	<div
		class="col-12 col-md-4"
		*ngIf="showFilter && !showSort"
		[attr.style]="isMob ? 'order:1' : 'order:2'"
	>
		<mat-card class="card">
			<mat-card-title class="card-title">
				<h4>{{ "lang_advanced_filter" | language }}</h4>
			</mat-card-title>
			<mat-card-content>
				<div class="row">
					<div class="col-12">
						<label for="filter">{{ "lang_filter" | language }}</label>
						<ng-select
							[items]="filterList"
							class="customSelect"
							id="filter"
							name="filter"
							#filterSelect
							placeholder="Select Filter"
							bindLabel="filter_name"
							bindValue="filter_id"
							(change)="getFilterDetails($event)"
							[(ngModel)]="selectedFilter"
							[notFoundText]="'lang_no_data' | language"
						>
							<ng-template ng-option-tmp let-item="item" let-index="index">
								<b>{{ item.filter_name }}</b>
								<span (click)="removeFilter($event, item)"
									><i class="fa fa-trash red"></i
								></span>
							</ng-template>
						</ng-select>
					</div>
					<div class="col-12">
						<label for="filter_name">{{ "lang_filter_name" | language }}</label>
						<input
							type="text"
							class="form-control"
							id="filter_name"
							name="filter_name"
							[(ngModel)]="filter_name"
						/>
					</div>
				</div>
				<form [formGroup]="filterForm">
					<div class="row">
						<div class="col-12 mt-2">
							<mat-form-field appearance="outline" style="width: 100%">
								<mat-label>{{ "lang_date" | language }}</mat-label>
								<mat-date-range-input [rangePicker]="picker">
									<input
										matStartDate
										formControlName="transaction_start_date"
										placeholder="YYYY/MM/DD"
										readonly
										id="transaction_start_date"
										name="transaction_start_date"
									/>
									<input
										matEndDate
										formControlName="transaction_end_date"
										placeholder="YYYY/MM/DD"
										readonly
										id="transaction_end_date"
										name="transaction_end_date"
									/>
								</mat-date-range-input>
								<mat-datepicker-toggle
									matSuffix
									[for]="picker"
								></mat-datepicker-toggle>
								<mat-date-range-picker #picker></mat-date-range-picker>
							</mat-form-field>
						</div>
						<div class="col-12 mt-1">
							<label for="form_request_id">{{
								"lang_transaction_number" | language
							}}</label>
							<input
								type="text"
								class="form-control"
								id="form_request_id"
								name="form_request_id"
								(keypress)="ds?.isNumber($event)"
								formControlName="form_request_id"
							/>
						</div>
						<div class="col-12 mt-1">
							<div
								class="box"
								style="
									border: 2px solid rgba(165, 161, 165, 0.467);
									margin: 5px 0;
									padding: 10px;
									border-radius: 5px;
								"
							>
								<div class="row">
									<div class="col-12 mt-1">
										<div class="d-flex justify-content-between mb-2">
											<span>{{ "lang_template_id" | language }}</span>
											<i
												class="fa fa-plus mnabricon"
												(click)="openTemplateFilter()"
											></i>
										</div>
										<mat-chip-list>
											<mat-chip
												color="primary"
												*ngFor="let item of template; let i = index"
												(removed)="removeTemplate(i)"
											>
												{{ item?.label }}
												<button matChipRemove>
													<mat-icon>cancel</mat-icon>
												</button>
											</mat-chip>
											<div
												*ngIf="template?.length == 0"
												style="font-size: 12px; color: #333333d6; margin: 0 5px"
											>
												{{ "lang_no_data" | language }}
											</div>
										</mat-chip-list>
									</div>
									<div class="col-12 mt-1">
										<label for="group1_id">{{
											"lang_group_one" | language
										}}</label>
										<mat-chip-list>
											<mat-chip
												color="primary"
												*ngFor="let item of group1; let i = index"
												(removed)="removeGroup1(i)"
											>
												{{ item?.label }}
												<button matChipRemove>
													<mat-icon>cancel</mat-icon>
												</button>
											</mat-chip>
											<div
												*ngIf="group1?.length == 0"
												style="font-size: 12px; color: #333333d6; margin: 0 5px"
											>
												{{ "lang_no_data" | language }}
											</div>
										</mat-chip-list>
									</div>
									<div class="col-12 mt-1">
										<label for="group_type_id">{{
											"lang_group_type" | language
										}}</label>
										<mat-chip-list>
											<mat-chip
												color="primary"
												*ngFor="let item of group_type; let i = index"
												(removed)="removeGroupType(i)"
											>
												{{ item?.label }}
												<button matChipRemove>
													<mat-icon>cancel</mat-icon>
												</button>
											</mat-chip>
											<div
												*ngIf="group_type?.length == 0"
												style="font-size: 12px; color: #333333d6; margin: 0 5px"
											>
												{{ "lang_no_data" | language }}
											</div>
										</mat-chip-list>
									</div>
									<div class="col-12 mt-1">
										<label for="group2_id">{{
											"lang_group_two" | language
										}}</label>
										<mat-chip-list>
											<mat-chip
												color="primary"
												*ngFor="let item of group2; let i = index"
												(removed)="removeGroup2(i)"
											>
												{{ item?.label }}
												<button matChipRemove style="border: none">
													<mat-icon style="color: #000 !important"
														>cancel</mat-icon
													>
												</button>
											</mat-chip>
											<div
												*ngIf="group2?.length == 0"
												style="font-size: 12px; color: #333333d6; margin: 0 5px"
											>
												{{ "lang_no_data" | language }}
											</div>
										</mat-chip-list>
									</div>
								</div>
							</div>
						</div>

						<div class="col-12 mt-1">
							<label for="platform_code_system">{{
								"lang_platform_code_system" | language
							}}</label>
							<input
								type="text"
								class="form-control"
								id="platform_code_system"
								name="platform_code_system"
								formControlName="platform_code_system"
							/>
						</div>
						<div class="col-12 mt-1">
							<div
								class="box"
								style="
									border: 2px solid rgba(165, 161, 165, 0.467);
									margin: 5px 0;
									padding: 10px;
									border-radius: 5px;
								"
							>
								<div class="row">
									<div class="col-12 mt-1">
										<div class="d-flex justify-content-between mb-2">
											<label for="zone">{{ "lang_zone" | language }}</label>
											<i
												class="fa fa-plus mnabricon"
												(click)="openZoneFilter()"
											></i>
										</div>
										<mat-chip-list>
											<mat-chip
												color="primary"
												*ngFor="let item of zone; let i = index"
												(removed)="removeZone(i)"
											>
												{{ item?.label }}
												<button matChipRemove>
													<mat-icon>cancel</mat-icon>
												</button>
											</mat-chip>
											<div
												*ngIf="zone?.length == 0"
												style="font-size: 12px; color: #333333d6; margin: 0 5px"
											>
												{{ "lang_no_data" | language }}
											</div>
										</mat-chip-list>
									</div>
									<div class="col-12 mt-1">
										<label for="block">{{ "lang_blocks" | language }}</label>
										<mat-chip-list>
											<mat-chip
												color="primary"
												*ngFor="let item of block; let i = index"
												(removed)="removeBlock(i)"
											>
												{{ item?.label }}
												<button matChipRemove>
													<mat-icon>cancel</mat-icon>
												</button>
											</mat-chip>
											<div
												*ngIf="block?.length == 0"
												style="font-size: 12px; color: #333333d6; margin: 0 5px"
											>
												{{ "lang_no_data" | language }}
											</div>
										</mat-chip-list>
									</div>
									<div class="col-12 mt-1">
										<label for="cluster">{{
											"lang_clusters" | language
										}}</label>
										<mat-chip-list>
											<mat-chip
												color="primary"
												*ngFor="let item of cluster; let i = index"
												(removed)="removeCluster(i)"
											>
												{{ item?.label }}
												<button matChipRemove>
													<mat-icon>cancel</mat-icon>
												</button>
											</mat-chip>
											<div
												*ngIf="cluster?.length == 0"
												style="font-size: 12px; color: #333333d6; margin: 0 5px"
											>
												{{ "lang_no_data" | language }}
											</div>
										</mat-chip-list>
									</div>
								</div>
							</div>
						</div>

						<div class="col-12 mt-1">
							<div
								class="box"
								style="
									border: 2px solid rgba(165, 161, 165, 0.467);
									margin: 5px 0;
									padding: 10px;
									border-radius: 5px;
								"
							>
								<label for="phase_short_code">{{
									"lang_by_phases" | language
								}}</label>
								<input
									type="text"
									class="form-control mb-1"
									id="phase_short_code"
									name="phase_short_code"
									formControlName="phase_short_code"
								/>
								<label for="unit_id">{{
									"lang_general_numbers" | language
								}}</label>
								<input
									type="text"
									class="form-control mb-1"
									id="unit_id"
									name="unit_id"
									(keypress)="ds?.isNumber($event)"
									formControlName="unit_id"
								/>
								<label for="level_key">{{ "lang_level_key" | language }}</label>
								<ng-select
									[items]="levels_arr"
									[multiple]="true"
									bindLabel="label"
									bindValue="value"
									appendTo="body"
									clearAllText="Clear"
									formControlName="level_key"
									placeholder="{{ 'lang_level_key' | language }}"
									[notFoundText]="'lang_no_data' | language"
								></ng-select>
							</div>
						</div>
						<div class="col-12 mt-1">
							<label for="result_code">{{ "lang_result" | language }}</label>
							<ng-select
								[items]="result_code_arr"
								[multiple]="true"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
								clearAllText="Clear"
								formControlName="result_code"
								placeholder="{{ 'lang_result' | language }}"
								[notFoundText]="'lang_no_data' | language"
							>
							</ng-select>
						</div>
						<div class="col-12 mt-1">
							<label for="version">{{ "lang_status" | language }}</label>
							<ng-select
								[items]="status_arr"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
								clearAllText="Clear"
								formControlName="version"
								placeholder="{{ 'lang_status' | language }}"
								[notFoundText]="'lang_no_data' | language"
							>
							</ng-select>
						</div>
						<div class="col-12 mt-1">
							<label for="barcode">{{ "lang_barcode" | language }}</label>
							<input
								type="text"
								class="form-control"
								id="barcode"
								name="barcode"
								formControlName="barcode"
							/>
						</div>

						<div class="col-12 mt-2 d-flex justify-content-between">
							<button
								type="button"
								class="albutton"
								style="width: 40% !important"
								(click)="resetFilterForm()"
							>
								{{ "lang_reset" | language }}
							</button>

							<div
								class="btn-group"
								role="group"
								aria-label="Basic example"
								style="width: 50% !important"
							>
								<button
									type="submit"
									class="btn albutton filter-btn"
									style="color: #fff"
									(click)="sendFilterForm()"
								>
									{{ "lang_submit" | language }}
								</button>
								<button
									type="button"
									class="btn albutton filter-btn"
									style="color: #fff"
									(click)="saveFilter()"
								>
									{{ "lang_save" | language }}
								</button>
							</div>
						</div>
					</div>
				</form>
			</mat-card-content>
		</mat-card>
	</div>
	<div
		class="col-12 col-md-4"
		*ngIf="!showFilter && showSort"
		[attr.style]="isMob ? 'order:1' : 'order:2'"
	>
		<mat-card class="card">
			<mat-card-title class="card-title">
				<h4>{{ "lang_sorting_order" | language }}</h4>
			</mat-card-title>
			<mat-card-content>
				<div class="row">
					<div class="col-12">
						<label for="filter">{{ "lang_filter" | language }}</label>
						<ng-select
							[items]="filterList"
							class="customSelect"
							id="filter"
							name="filter"
							#filterSelectAtsort
							placeholder="Select Filter"
							bindLabel="filter_name"
							bindValue="filter_id"
							(change)="getFilterDetails($event)"
							[(ngModel)]="selectedFilter"
							[notFoundText]="'lang_no_data' | language"
						>
							<ng-template ng-option-tmp let-item="item" let-index="index">
								<b>{{ item.filter_name }}</b>
								<span (click)="removeFilter($event, item)"
									><i class="fa fa-trash red"></i
								></span>
							</ng-template>
						</ng-select>
					</div>
					<div class="col-12">
						<label for="filter_name">{{ "lang_filter_name" | language }}</label>
						<input
							type="text"
							class="form-control"
							id="filter_name"
							name="filter_name"
							[(ngModel)]="filter_name"
						/>
					</div>
				</div>
				<form [formGroup]="sortForm">
					<div class="row">
						<div class="col-12">
							<label for="form_request_id">{{
								"lang_transaction_number" | language
							}}</label>
							<ng-select
								[items]="form_request_id_arr"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
								clearAllText="Clear"
								formControlName="form_request_id"
								placeholder="{{ 'lang_transaction_number' | language }}"
								[notFoundText]="'lang_no_data' | language"
							></ng-select>
						</div>
						<div class="col-12 mt-1">
							<label for="template_id">{{
								"lang_template_id" | language
							}}</label>
							<ng-select
								[items]="template_id_arr"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
								clearAllText="Clear"
								formControlName="template_id"
								placeholder="{{ 'lang_template_id' | language }}"
								[notFoundText]="'lang_no_data' | language"
							></ng-select>
						</div>

						<div class="col-12 mt-1">
							<label for="platform_code_system">{{
								"lang_platform_code_system" | language
							}}</label>
							<ng-select
								[items]="platform_code_system_arr"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
								clearAllText="Clear"
								formControlName="platform_code_system"
								placeholder="{{ 'lang_platform_code_system' | language }}"
								[notFoundText]="'lang_no_data' | language"
							></ng-select>
						</div>
						<div class="col-12 mt-1">
							<label for="zone">{{ "lang_zone" | language }}</label>
							<ng-select
								[items]="zone_arr"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
								clearAllText="Clear"
								formControlName="zone"
								placeholder="{{ 'lang_zone' | language }}"
								[notFoundText]="'lang_no_data' | language"
							></ng-select>
						</div>
						<div class="col-12 mt-1">
							<label for="block">{{ "lang_blocks" | language }}</label>
							<ng-select
								[items]="block_arr"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
								clearAllText="Clear"
								formControlName="block"
								placeholder="{{ 'lang_blocks' | language }}"
								[notFoundText]="'lang_no_data' | language"
							></ng-select>
						</div>
						<div class="col-12 mt-1">
							<label for="cluster">{{ "lang_clusters" | language }}</label>
							<ng-select
								[items]="cluster_arr"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
								clearAllText="Clear"
								formControlName="cluster"
								placeholder="{{ 'lang_clusters' | language }}"
								[notFoundText]="'lang_no_data' | language"
							>
							</ng-select>
						</div>

						<div class="col-12 mt-1">
							<label for="barcode">{{ "lang_barcode" | language }}</label>
							<ng-select
								[items]="barcode_arr"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
								clearAllText="Clear"
								formControlName="barcode"
								placeholder="{{ 'lang_barcode' | language }}"
								[notFoundText]="'lang_no_data' | language"
							>
							</ng-select>
						</div>
						<div class="col-12 mt-2 d-flex justify-content-between">
							<button
								type="button"
								class="albutton"
								style="width: 40% !important"
								(click)="resetSortForm()"
							>
								{{ "lang_reset" | language }}
							</button>
							<div
								class="btn-group"
								role="group"
								aria-label="Basic example"
								style="width: 50% !important"
							>
								<button
									type="submit"
									class="btn albutton filter-btn"
									(click)="sendSortForm()"
								>
									{{ "lang_submit" | language }}
								</button>
								<button
									type="button"
									class="btn albutton filter-btn"
									(click)="saveSortForm()"
								>
									{{ "lang_save" | language }}
								</button>
							</div>
						</div>
					</div>
				</form>
			</mat-card-content>
		</mat-card>
	</div>
</div>
