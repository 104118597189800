import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { environment } from "@env/environment";
import moment from "moment";

@Component({
  selector: "app-risks-list",
  templateUrl: "./risks-list.component.html",
  styleUrls: ["./risks-list.component.scss"],
})
export class RisksListComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key") || "en";
  @Input() projects_work_area_id;
  @Input() projects_profile_id;
  @Input() projects_supervision_id;
  risk_type_list = [];
  risk_continuity_list = [];
  risk_status_list = [];
  issue_type_list = [];
  issue_level_list = [];
  issue_status_list = [];
  risk_type = "";
  risk_continuity = "";
  risk_status = "";
  risk_list = [];
  risk_pageno = 1;
  risk_pagesize = "10";
  total_records = 0;
  isEdit = false;
  form: FormGroup;
  form2: FormGroup;
  modalRef: BsModalRef;
  constructor(
    private lang: LanguagePipe,
    private ds: DataService,
    private spinner: PreloaderService,
    private sweetAlert: SweetAlertService,
    private fb: FormBuilder,
    public modalService: BsModalService
  ) {
    this.risk_type_list = [
      {
        label: this.lang.transform("lang_specification"),
        value: "specification",
      },
      {
        label: this.lang.transform("lang_quality"),
        value: "quality",
      },
      {
        label: this.lang.transform("lang_schedule"),
        value: "schedule",
      },
    ];
    this.risk_continuity_list = [
      {
        label: this.lang.transform("lang_ongoing"),
        value: "ongoing",
      },
      {
        label: this.lang.transform("lang_onetime"),
        value: "onetime",
      },
    ];
    this.risk_status_list = [
      {
        label: this.lang.transform("lang_close"),
        value: "close",
      },
      {
        label: this.lang.transform("lang_open"),
        value: "open",
      },
      {
        label: this.lang.transform("lang_cause_issue"),
        value: "cause_issue",
      },
    ];
    this.issue_type_list = [
      {
        label: this.lang.transform("lang_specification"),
        value: "specification",
      },
      {
        label: this.lang.transform("lang_quality"),
        value: "quality",
      },
      {
        label: this.lang.transform("lang_schedule"),
        value: "schedule",
      },
    ];
    this.issue_level_list = [
      {
        label: this.lang.transform("lang_severe"),
        value: "severe",
      },
      {
        label: this.lang.transform("lang_medium"),
        value: "medium",
      },
    ];
    this.issue_status_list = [
      {
        label: this.lang.transform("lang_close"),
        value: "close",
      },
      {
        label: this.lang.transform("lang_open"),
        value: "open",
      },
    ];
  }

  ngOnInit(): void {
    moment.locale("en");
    this.getRisks();
    this.buildForm();
    this.buildForm2();
  }

  buildForm() {
    this.form = this.fb.group({
      risk_id: [""],
      risk_description_ar: ["", Validators.required],
      risk_description_en: ["", Validators.required],
      risk_type: ["", Validators.required],
      risk_continuity: ["", Validators.required],
      risk_impact: ["", Validators.required],
      risk_probability: ["", Validators.required],
      risk_rank: ["", Validators.required],
      risk_impact_description: ["", Validators.required],
      risk_mitigation_plan: ["", Validators.required],
      risk_owner: ["", Validators.required],
      risk_mitigation_by: ["", Validators.required],
      risk_status: ["", Validators.required],
    });
  }
  buildForm2() {
    this.form2 = this.fb.group({
      issue_id: [""],
      issue_description_ar: ["", Validators.required],
      issue_description_en: ["", Validators.required],
      issue_type: ["", Validators.required],
      issue_level: ["", Validators.required],
      issue_owner: ["", Validators.required],
      issue_action_by: ["", Validators.required],
      issue_recommended_action: ["", Validators.required],
      issue_start_date: ["", Validators.required],
      issue_expected_closure_date: ["", Validators.required],
      issue_end_date: ["", Validators.required],
      issue_status: ["", Validators.required],
      project_risk_id: ["", Validators.required],
    });
  }

  getRisks() {
    let formData = new FormData();
    formData.append("projects_work_area_id", this.projects_work_area_id || "");
    formData.append("risk_type", this.risk_type || "");
    formData.append("risk_continuity", this.risk_continuity || "");
    formData.append("risk_status", this.risk_status || "");
    this.spinner.show();
    this.ds
      .post(
        `pr/risk_management/get_work_area_risk_management/${this.risk_pageno}/${this.risk_pagesize}`,
        formData
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.risk_list = res?.results;
            this.total_records = res?.pages?.total_records;
          } else {
            this.risk_list = [];
            this.sweetAlert.errorToast(res?.error, 3000);
            this.total_records = 0;
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
          this.total_records = 0;
          this.risk_list = [];
        }
      );
  }
  onAddUpdateRisk(template, data?) {
    this.form.reset();
    if (data) {
      this.isEdit = true;
      this.form.patchValue(data);
    }
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  onaddisseu(template, data?) {
    this.form2.reset();
    if (data) {
      this.form2.get("issue_type").setValue(data.risk_type);
      this.form2.get("issue_id").setValue(data.risk_id);
      this.form2.get("issue_description_ar").setValue(data.risk_description_ar);
      this.form2.get("issue_description_en").setValue(data.risk_description_en);
      this.form2.get("issue_owner").setValue(data.risk_owner);
      this.form2.get("issue_owner").setValue(data.risk_owner);
    }
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  submit() {
    let formData = new FormData();
    formData.append("projects_work_area_id", this.projects_work_area_id || "");
    formData.append(
      "risk_description_ar",
      this.form.get("risk_description_ar")?.value || ""
    );
    formData.append(
      "risk_description_en",
      this.form.get("risk_description_en")?.value || ""
    );
    formData.append("risk_type", this.form.get("risk_type")?.value || "");
    formData.append(
      "risk_continuity",
      this.form.get("risk_continuity")?.value || ""
    );
    formData.append("risk_impact", this.form.get("risk_impact")?.value || "");
    formData.append(
      "risk_probability",
      this.form.get("risk_probability")?.value || ""
    );
    formData.append("risk_rank", this.form.get("risk_rank")?.value || "");
    formData.append(
      "risk_impact_description",
      this.form.get("risk_impact_description")?.value || ""
    );
    formData.append(
      "risk_mitigation_plan",
      this.form.get("risk_mitigation_plan")?.value || ""
    );
    formData.append("risk_owner", this.form.get("risk_owner")?.value || "");
    formData.append(
      "risk_mitigation_by",
      this.form.get("risk_mitigation_by")?.value || ""
    );
    formData.append("risk_status", this.form.get("risk_status")?.value || "");
    if (this.isEdit) {
      formData.append("risk_id", this.form.get("risk_id")?.value || "");
    }
    this.spinner.show();
    this.ds.post("pr/risk_management/add_edit_risk", formData).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.sweetAlert.successToast("lang_success", 3000);
          this.getRisks();
          this.modalRef.hide();
        } else {
          this.sweetAlert.errorToast(res?.error, 3000);
        }
      },
      (err) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          3000
        );
      }
    );
  }
  add_issue() {
    let formData = new FormData();
    formData.append("projects_work_area_id", this.projects_work_area_id || "");
    formData.append(
      "issue_description_ar",
      this.form2.get("issue_description_ar")?.value || ""
    );
    formData.append(
      "issue_description_en",
      this.form2.get("issue_description_en")?.value || ""
    );
    formData.append("issue_type", this.form2.get("issue_type")?.value || "");
    formData.append("issue_level", this.form2.get("issue_level")?.value || "");
    formData.append("issue_owner", this.form2.get("issue_owner")?.value || "");
    formData.append(
      "issue_action_by",
      this.form2.get("issue_action_by")?.value || ""
    );
    formData.append(
      "issue_recommended_action",
      this.form2.get("issue_recommended_action")?.value || ""
    );
    formData.append(
      "issue_start_date",
      this.form2.get("issue_start_date")?.value
        ? moment(this.form2.get("issue_start_date")?.value).format("YYYY/MM/DD")
        : ""
    );
    formData.append(
      "issue_expected_closure_date",
      this.form2.get("issue_expected_closure_date")?.value
        ? moment(this.form2.get("issue_expected_closure_date")?.value).format(
            "YYYY/MM/DD"
          )
        : ""
    );
    formData.append(
      "issue_end_date",
      this.form2.get("issue_end_date")?.value
        ? moment(this.form2.get("issue_end_date")?.value).format("YYYY/MM/DD")
        : ""
    );
    formData.append(
      "issue_status",
      this.form2.get("issue_status")?.value || ""
    );
    formData.append(
      "project_risk_id",
      this.form2.get("project_risk_id")?.value || ""
    );
    this.spinner.show();
    this.ds.post("pr/risk_management/add_edit_issue", formData).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.sweetAlert.successToast("lang_success", 3000);
          this.modalRef.hide();
        } else {
          this.sweetAlert.errorToast(res?.error, 3000);
        }
      },
      (err) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          3000
        );
      }
    );
  }

  deleteRisk(item) {
    let formData = new FormData();
    formData.append("risk_id", item?.risk_id || "");
    this.spinner.show();
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formData),
        "pr/risk_management/delete_risk"
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.sweetAlert.successToast(
              this.lang.transform("lang_success"),
              3000
            );
            this.getRisks();
            this.modalRef.hide();
          } else {
            this.sweetAlert.errorToast(res?.error, 3000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
  }

  onChangeRiskStatus(item, status, templet?) {
    let formData = new FormData();
    formData.append("risk_id", item?.risk_id || "");
    formData.append("projects_work_area_id", item?.projects_work_area_id || "");
    if (item?.risk_status != status) {
      formData.append("risk_status", status);
      this.spinner.show();
      this.ds.post("pr/risk_management/update_risk_status", formData).subscribe(
        (res) => {
          this.spinner.hide();
          if (res?.status) {
            this.sweetAlert.successToast(
              this.lang.transform("lang_success"),
              3000
            );
            this.getRisks();
            if (templet) {
              this.onaddisseu(templet, item);
            }
          } else {
            this.sweetAlert.errorToast(res?.error, 3000);
          }
        },
        (err) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            3000
          );
        }
      );
    }
  }
}
