<div mat-dialog-title class="d-flex justify-content-between">
	<h4 *ngIf="!task_data">
		{{ "lang_add" | language }} {{ "lang_task" | language }}
	</h4>
	<h4 *ngIf="task_data">
		{{ "lang_edit" | language }} {{ "lang_task" | language }}
	</h4>
	<div>
		<div class="task-dates">
			<h5 class="task-dates-title">{{ "lang_date" | language }}</h5>
			<div class="task-dates-content task-btn">
				{{ "lang_from" | language }} {{ startDate }} {{ "lang_to" | language }}
				{{ endDate }}
			</div>
		</div>
	</div>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="task_form">
		<div class="p-2">
			<div class="row mb-3">
				<div class="col-12 col-sm-6 col-md-4 mb-2">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_subject" | language }}</mat-label>
						<input
							matInput
							placeholder="{{ 'lang_subject' | language }}"
							formControlName="title"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6 col-md-4 mb-2">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_description" | language }}</mat-label>
						<input
							matInput
							placeholder="{{ 'lang_description' | language }}"
							formControlName="task_description"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6 col-md-4 mb-4">
					<ng-select
						[items]="status_done_list"
						[multiple]="false"
						appendTo="body"
						bindLabel="name"
						bindValue="id"
						clearAllText="Clear"
						[searchable]="false"
						placeholder="{{ 'lang_status_done' | language }}"
						formControlName="task_status_done"
						class="related_task"
					>
					</ng-select>
				</div>
				<div
					class="col-12 col-sm-6 col-md-4 mb-4 d-flex align-items-center"
					*ngIf="!showGroups"
				>
					<button
						mat-button
						class="albutton mnabrbutton mat-accent"
						(click)="showGroups = true"
						style="height: 93%; margin-top: 3px"
					>
						<div class="d-flex align-items-center justify-content-between">
							<span>{{ "lang_groups" | language }}</span>
							<i class="fa fa-users"></i>
						</div>
					</button>
				</div>
				<div class="col-12 col-sm-6 col-md-4 mb-4" *ngIf="showGroups">
					<ng-select
						[items]="group_type_list"
						[multiple]="false"
						appendTo="body"
						bindLabel="label"
						bindValue="value"
						[searchable]="false"
						placeholder="{{ 'lang_group_type' | language }}"
						formControlName="group_type_id"
						(change)="getGroups()"
					>
					</ng-select>
				</div>
				<div class="col-12 col-sm-6 col-md-4 mb-4 d-flex" *ngIf="showGroups">
					<ng-select
						[items]="group_list"
						[multiple]="false"
						appendTo="body"
						bindLabel="group_name"
						bindValue="group_id"
						clearAllText="Clear"
						[searchable]="false"
						placeholder="{{ 'lang_group' | language }}"
						formControlName="group_id"
						(change)="onChangeGroup()"
						style="width: 100%"
					>
					</ng-select>
					<i
						class="fa fa-times red mr-1 ml-1"
						style="height: fit-content"
						(click)="showGroups = false"
						matTooltip="{{ 'lang_close' | language }}"
					></i>
				</div>
				<div class="col-12 col-sm-6 col-md-4 mb-2">
					<ng-select
						[items]="user_list"
						[multiple]="true"
						appendTo="body"
						bindLabel="label"
						clearAllText="Clear"
						[searchFn]="ds.customSearchFn"
						placeholder="{{ 'lang_users' | language }}"
						[clearable]="true"
						[searchable]="true"
						formControlName="assigned_user"
						(search)="search_users($event)"
						id="assigned_user"
						name="assigned_user"
					>
						<ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
							<div class="ng-value" *ngFor="let item of items">
								<span class="ng-value-label">
									<img
										[src]="item?.profile_image"
										width="20px"
										height="20px"
										*ngIf="item?.profile_image"
									/>
									<img
										src="assets/images/avatar.png"
										width="20px"
										height="20px"
										*ngIf="!item?.profile_image"
									/>
									<span style="font-size: 11px; margin: 0 1px">{{
										item.label
									}}</span>
								</span>
								<span
									class="ng-value-icon right"
									(click)="clear(item)"
									aria-hidden="true"
									>×</span
								>
							</div>
						</ng-template>
						<ng-template
							ng-option-tmp
							let-item="item"
							let-index="index"
							let-search="searchTerm"
						>
							<div
								style="
									display: flex;
									justify-content: space-between;
									align-items: center;
								"
							>
								<img
									src="{{ item?.profile_image }}"
									alt="user avatar"
									width="20px"
									*ngIf="item?.profile_image"
								/>
								<img
									src="assets/images/avatar.png"
									width="20px"
									height="20px"
									*ngIf="!item?.profile_image"
								/>
								<span>{{ item?.label }}</span>
							</div>
						</ng-template>
					</ng-select>
				</div>
				<div class="col-12 col-sm-6 col-md-4 mb-4">
					<ng-select
						[items]="type_work_list"
						[multiple]="false"
						appendTo="body"
						bindLabel="name"
						bindValue="id"
						clearAllText="Clear"
						[searchable]="false"
						placeholder="{{ 'lang_type_work' | language }}"
						formControlName="type_work"
						class="related_task"
					>
					</ng-select>
				</div>
				<div
					class="col-12 col-sm-6 col-md-4 mb-4"
					*ngIf="task_form.get('type_work')?.value == '1'"
				>
					<ng-select
						[items]="shift_list"
						[multiple]="false"
						appendTo="body"
						bindLabel="shift_title_english"
						bindValue="shift_id"
						clearAllText="Clear"
						[searchable]="false"
						placeholder="{{ 'lang_shift' | language }}"
						formControlName="shift_id"
						class="related_task"
						(change)="onChangeStartDate()"
					>
					</ng-select>
				</div>
				<div class="col-12 col-sm-6 col-md-4">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_task_days" | language }}</mat-label>
						<input
							matInput
							placeholder="{{ 'lang_task_days' | language }}"
							formControlName="task_time"
							autocomplete="off"
							(keypress)="isNumber($event)"
							(keyup)="taskTimeValidation(); calcEndDate($event, 'dayschange')"
						/>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6 col-md-4">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_start_date" | language }}</mat-label>
						<input matInput required readonly formControlName="start_date" />
						<mat-datepicker-toggle
							matSuffix
							(click)="picker.open()"
						></mat-datepicker-toggle>
						<mbsc-datepicker
							[controls]="['calendar', 'time']"
							select="date"
							[touchUi]="false"
							dateFormat="YYYY/MM/DD HH:mm:ss"
							[(ngModel)]="startDateMobiscroll"
							[buttons]="autoButtons"
							[ngModelOptions]="{ standalone: true }"
							(onClose)="custmizeDate('start_date')"
							style="visibility: hidden; height: 0"
							#picker
						>
						</mbsc-datepicker>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6 col-md-4">
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_end_date" | language }}</mat-label>
						<input matInput required readonly formControlName="end_date" />
						<mat-datepicker-toggle matSuffix></mat-datepicker-toggle>
					</mat-form-field>
				</div>
				<div
					class="col-12 col-sm-6 col-md-4"
					*ngIf="task_form.get('type_work')?.value == '1'"
				>
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>{{ "lang_end_date" | language }}</mat-label>
						<input matInput required readonly formControlName="end_date" />
						<mat-datepicker-toggle matSuffix></mat-datepicker-toggle>
					</mat-form-field>
				</div>
				<div class="col-12 col-sm-6 col-md-4 mb-4">
					<ng-select
						[items]="priority_list"
						[multiple]="false"
						appendTo="body"
						bindLabel="name"
						bindValue="id"
						clearAllText="Clear"
						[searchable]="false"
						placeholder="{{ 'lang_priority' | language }}"
						formControlName="important_id"
					>
					</ng-select>
				</div>
				<div class="col-12 col-sm-6 col-md-4 mb-4">
					<ng-select
						[items]="tasks_list"
						[multiple]="true"
						appendTo="body"
						bindLabel="title"
						bindValue="task_id"
						clearAllText="Clear"
						placeholder="{{ 'lang_related_task' | language }}"
						formControlName="related_id"
						class="related_task"
					>
					</ng-select>
				</div>
				<div class="col-12 TableParts">
					<p class="mb-10">
						{{ "lang_attachments" | language }}
						<span class="red" *ngIf="other_attachments?.length">*</span>
					</p>
					<div class="table-responsive customResponsive quotationitemtable">
						<table
							border="1"
							class="table CustomTable"
							style="width: 99% !important"
							formArrayName="attachments"
						>
							<thead>
								<tr>
									<td width="2%" style="padding: 5px; word-break: break-all">
										#
									</td>
									<td width="2%" style="padding: 5px; word-break: break-all">
										<mat-icon
											class="mnabricon"
											matTooltip="{{ 'lang_add' | language }}"
											(click)="addfiles()"
											>add_box</mat-icon
										>
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_title" | language }}
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_file" | language }}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let file of filesFormGroup.controls;
										let i = index;
										let odd = odd;
										let even = even
									"
									[formGroupName]="i"
								>
									<td
										class="verticalalignmid"
										style="padding: 5px; word-break: break-all"
									>
										{{ i + 1 }}
									</td>
									<td class="verticalalignmid">
										<mat-icon
											*ngIf="
												!other_attachments.controls[i].get('delete_icon').value
											"
											(click)="removefiles(i)"
											class="mnabricon red"
											matTooltip="{{ 'lang_delete' | language }}"
											>delete_forever</mat-icon
										>
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<input
											type="text"
											formControlName="attach_title"
											placeholder="{{ 'lang_description' | language }}"
											autocomplete="off"
											class="form-control almnabrformcontrol shadow-none"
										/>
									</td>
									<td
										class="verticalalignmid"
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
										*ngIf="!other_attachments.controls[i].get('view').value"
									>
										<input
											type="text"
											formControlName="file"
											placeholder="{{ 'lang_file' | language }}"
											autocomplete="off"
											class="form-control almnabrformcontrol shadow-none"
											[class.border-danger]="
												other_attachments.controls[i].get('file').errors
													?.urlWrong
											"
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-12 TableParts" *ngIf="userCounts?.length">
					<p style="margin: 10px 0">
						{{ "lang_user_tasks" | language }}
						<span class="red" *ngIf="other_attachments?.length">*</span>
					</p>
					<div
						class="table-responsive customResponsive tablefields quotationitemtable"
					>
						<table border="1" class="table CustomTable" width="100%">
							<thead>
								<tr>
									<td width="4%" style="padding: 5px; word-break: break-all">
										#
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_username" | language }}
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_tasks" | language }}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let item of userCounts;
										let i = index;
										let odd = odd;
										let even = even
									"
								>
									<td
										class="verticalalignmid"
										style="padding: 5px; word-break: break-all"
									>
										{{ i + 1 }}
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										{{ item?.user_profile }}
									</td>
									<td
										class="verticalalignmid"
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<span
											style="
												font-weight: 600;
												color: rgb(38, 133, 228);
												cursor: pointer;
											"
											(click)="onSelectUser(item?.user_id)"
											>{{ item?.counts }}</span
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-0 col-sm-9"></div>
	<div class="col-12 col-sm-3">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="addTask()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
