<div mat-dialog-title>
	<div class="contacts-header">
		<span mat-dialog-close>
			<svg fill="#fff" viewBox="0 0 24 24" width="24" height="24">
				<path
					d="m19.1 17.2-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"
				></path>
			</svg>
		</span>
		<h1>Chat New Contact</h1>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="mbrl15">
		<div class="row">
			<div class="col-12 mb-2">
				<label for="name">{{ "lang_name" | language }}</label>
				<input
					type="text"
					class="form-control number-inpt"
					placeholder="{{ 'lang_name' | language }}"
					[(ngModel)]="name"
					id="name"
					name="name"
				/>
			</div>
			<div class="col-12">
				<label for="number">{{ "lang_mobile_number" | language }}</label>
				<input
					type="text"
					class="form-control number-inpt"
					placeholder="Ex: +XXXXXXXXXXXX"
					[(ngModel)]="number"
					(keypress)="isNumber($event)"
					id="number"
					name="number"
				/>
			</div>
			<div class="col-12 action-btns">
				<button mat-dialog-close>Cancel</button>
				<button (click)="createContact()">Message</button>
			</div>
		</div>
	</div>
</mat-dialog-content>
