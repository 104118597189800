import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { environment } from "@env/environment";
import { AdminLayoutComponent } from "../theme/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "../theme/auth-layout/auth-layout.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./sessions/login/login.component";
import { AuthGuard } from "@core";
import { QrcodepdfComponent } from "./transactions/tcore/qrcodepdf/qrcodepdf.component";
import { SigninComponent } from "./sessions/signin/signin.component";
import { ForgetComponent } from "./sessions/forget/forget.component";
import { RegistrationComponent } from "./sessions/registration/registration.component";
import { ResetComponent } from "./sessions/reset/reset.component";
import { SeditComponent } from "./sessions/sedit/sedit.component";
import { SignupComponent } from "./sessions/signup/signup.component";
import { StatusComponent } from "./sessions/status/status.component";
import { SviewComponent } from "./sessions/sview/sview.component";
import { ExternalPersonLoginComponent } from "./sessions/external-person-login/external-person-login.component";
import { EpTicketListComponent } from "./tasks/ep-ticket-list/ep-ticket-list.component";
import { EpTicketViewComponent } from "./tasks/ep-ticket-list/ep-ticket-view/ep-ticket-view.component";
import { EpAuthGuard } from "@core/authentication/ep-auth.guard";
import { EpTaskViewComponent } from "./tasks/ep-ticket-list/ep-task-view/ep-task-view.component";

const routes: Routes = [
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      {
        path: "dashboard",
        component: DashboardComponent,
        data: { title: "Home", titleI18n: "lang_dashboard" },
      },
      {
        path: "default",
        loadChildren: () =>
          import("./default/default.module").then((m) => m.DefaultModule),
        data: { title: "Default", titleI18n: "default" },
      },
      {
        path: "transactions",
        loadChildren: () =>
          import("./transactions/transactions.module").then(
            (m) => m.TransactionsModule
          ),
        data: { title: "Transactions", titleI18n: "lang_transations" },
      },
      {
        path: "Communication",
        loadChildren: () =>
          import("./communication/communication.module").then(
            (m) => m.CommunicationModule
          ),
        data: { title: "Communications", titleI18n: "lang_communications" },
      },
      {
        path: "human_resources",
        loadChildren: () =>
          import("./humanresources/humanresources.module").then(
            (m) => m.HumanresourcesModule
          ),
        data: { title: "Human Resources", titleI18n: "lang_human_resources" },
      },
      {
        path: "tasks",
        loadChildren: () =>
          import("./tasks/tasks.module").then((m) => m.TasksModule),
        data: { title: "Tasks", titleI18n: "lang_tasks" },
      },
      {
        path: "customers",
        loadChildren: () =>
          import("./customers/customers.module").then((m) => m.CustomersModule),
        data: { title: "Customers", titleI18n: "lang_customers" },
      },
      {
        path: "contractors",
        loadChildren: () =>
          import("./contractors/contractors.module").then(
            (m) => m.ContractorsModule
          ),
        data: { title: "Contractors", titleI18n: "lang_contractors" },
      },
      {
        path: "suppliers",
        loadChildren: () =>
          import("./suppliers/suppliers.module").then((m) => m.SuppliersModule),
        data: { title: "Suppliers", titleI18n: "lang_suppliers" },
      },
      {
        path: "accounts",
        loadChildren: () =>
          import("./accounts/accounts.module").then((m) => m.AccountsModule),
        data: { title: "Accounts", titleI18n: "lang_accounts" },
      },
      {
        path: "projects",
        loadChildren: () =>
          import("./projects/projects.module").then((m) => m.ProjectsModule),
        data: { title: "Projects", titleI18n: "lang_projects" },
      },
      {
        path: "documents",
        loadChildren: () =>
          import("./documents/documents.module").then((m) => m.DocumentsModule),
        data: { title: "Documents", titleI18n: "lang_documents" },
      },
      {
        path: "whatsapp",
        loadChildren: () =>
          import("./whatsapp/whatsapp.module").then((m) => m.WhatsappModule),
        data: { title: "Whatsapp", titleI18n: "lang_whatsapp" },
      },
      {
        path: "truenas",
        loadChildren: () =>
          import("./truenas/truenas.module").then((m) => m.TruenasModule),
        data: { title: "Treunas", titleI18n: "lang_truenas" },
      },
    ],
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
        data: { title: "Login", titleI18n: "lang_login" },
      },
    ],
  },
  {
    path: "external-person/login",
    component: ExternalPersonLoginComponent,
    data: {
      title: "External Person Login",
      titleI18n: "lang_external_person_login",
    },
  },
  {
    path: "external-person/tickets",
    component: EpTicketListComponent,
    data: {
      title: "External Person Tickets",
    },
    canActivate: [EpAuthGuard],
  },
  {
    path: "external-person/tickets/:id",
    component: EpTicketViewComponent,
    data: {
      title: "External Person Ticket View",
    },
    canActivate: [EpAuthGuard],
  },
  {
    path: "external-person/tickets/:ticketId/task/:taskId",
    component: EpTaskViewComponent,
    data: {
      title: "External Person Task View",
    },
    canActivate: [EpAuthGuard],
  },
  {
    path: "q",
    component: QrcodepdfComponent,
    data: { title: "View File" },
  },
  {
    path: "q/:barcode/:code",
    component: QrcodepdfComponent,
    data: { title: "View File" },
  },
  {
    path: "signin/:empno/:id/:token",
    component: SigninComponent,
    data: { title: "Employee Registration" },
  },
  {
    path: "registration/:token",
    component: RegistrationComponent,
    data: { title: "Complete Registration" },
  },
  {
    path: "status",
    component: StatusComponent,
    data: { title: "Status" },
  },
  {
    path: "freset/:token",
    component: ResetComponent,
    data: { title: "Reset Password" },
  },
  {
    path: "forget",
    component: ForgetComponent,
    data: { title: "Forget Password" },
  },
  {
    path: "signup",
    component: SignupComponent,
    data: { title: "Signup" },
  },
  {
    path: "viewdata/:id/:code",
    component: SviewComponent,
    data: { title: "View Apllicant Data" },
  },
  {
    path: "editdata/:id/:code",
    component: SeditComponent,
    data: { title: "Edit Apllicant Data" },
  },
  { path: "**", redirectTo: "dashboard" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {}
