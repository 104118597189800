import { Component, OnInit } from "@angular/core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-ep-ticket-list",
	templateUrl: "./ep-ticket-list.component.html",
	styleUrls: ["./ep-ticket-list.component.scss"],
})
export class EpTicketListComponent implements OnInit {
	ticket_list: any;
	ticketLoading = false;
	tickets_page_no = 1;
	tickets_page_size = "10";
	constructor(
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private ls: LocalStorage
	) {}

	ngOnInit(): void {
		this.getTicketList();
	}

	getTicketList() {
		this.ticketLoading = true;
		let formData = new FormData();
		formData.append(
			"token_extarnal",
			localStorage.getItem("eploggedin") == "1"
				? this.ls.getItem("eptoken")
				: ""
		);
		this.ds.post("tasks/get_ticket_for_extarnal_person", formData).subscribe(
			(res) => {
				this.ticketLoading = false;
				console.log(res);
				if (res?.status) {
					this.ticket_list = res;
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
					this.ticket_list = null;
				}
			},
			(err) => {
				this.ticketLoading = false;
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
				this.ticket_list = null;
			}
		);
	}

	load_by_page_no(page) {
		this.tickets_page_no = page;
		this.getTicketList();
	}
	load_by_page_size(size) {
		this.tickets_page_size = size;
		this.getTicketList();
	}
}
