<section
	class="card matborderno activeiconstepper customdesignstepper">
	<mat-accordion class="stylepanels">
		<mat-expansion-panel class="mb-2" [expanded]="true">
			<mat-expansion-panel-header
				>{{ "lang_form_details" | language }}
			</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
                    <div class="row">
                        <div class="col-12">
                            <app-cp-step1></app-cp-step1>
                        </div>
                    </div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="card-footer">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
			<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
				<button
					type="button"
					(click)="showAction.emit({ mode: 'VIEW', data: null })"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_supervision_operations" | language }}
				</button>
			</div>
			<div class="col-md-7 col-sm-2 col-xs-12 mb-10"></div>
		</div>
	</div>
</section>
