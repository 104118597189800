import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-supervision-owner",
	templateUrl: "./supervision-owner.component.html",
	styleUrls: ["./supervision-owner.component.scss"],
})
export class SupervisionOwnerComponent implements OnInit {
	@Input() supervision_data: any;
	@Input() able2add: any;
	@Input() able2edit: any;
	@Input() project_data: any;
	@Input() makeActionGroups: any;
	@Input() makeActionInitial: any;
	@Input() makeActionOperations: any;
	selected_tab = "bank_payments";
	constructor() {}

	ngOnInit(): void {}

	selectedListTabValue(e) {
		if (e?.tab?.textLabel == "bank_payments") {
			this.selected_tab = "bank_payments";
		}
	}
}
