import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { MaterialExtensionsModule } from "@ng-matero/extensions";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgProgressModule } from "ngx-progressbar";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { ToastrModule } from "ngx-toastr";
import { TranslateModule } from "@ngx-translate/core";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { PageHeaderComponent } from "./components/page-header/page-header.component";
import { ErrorCodeComponent } from "./components/error-code/error-code.component";
import { DisableControlDirective } from "./directives/disable-control.directive";
import { SafeUrlPipe } from "./pipes/safe-url.pipe";
import { ToObservablePipe } from "./pipes/to-observable.pipe";
import { LanguagePipe } from "./pipes/language.pipe";
import { AlertComponent } from "./components/alert/alert/alert.component";
import { AlertDialogComponent } from "./components/alert-dialog/alert-dialog.component";
import { ConfirmationDialog } from "./components/confirmation-dialog/confirmation-dialog.component";
import { FileviewComponent } from "./components/fileview/fileview.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PageTitleComponent } from "./components/page-title/page-title.component";
import { UnitLevelShowComponent } from "./components/unit-level-show/unit-level-show.component";
import { ShortenLengthPipe } from "./pipes/shorten-length.pipe";
import { TransactionAttachmentsComponent } from "./components/transaction-attachments/transaction-attachments.component";
import { VersionsShowComponent } from "./components/versions-show/versions-show.component";
import { MatSelectModule } from "@angular/material/select";
import { PersonDetailsComponent } from "./components/person-details/person-details.component";
import { TransactionsNotesComponent } from "./components/transactions-notes/transactions-notes.component";
import { TransactionsHistoryComponent } from "./components/transactions-history/transactions-history.component";
import { PreviewPdfComponent } from "./components/preview-pdf/preview-pdf.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { MatSliderModule } from "@angular/material/slider";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { VirtualScrollerModule } from "ngx-virtual-scroller";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { Formhrv1AddComponent } from "app/routes/transactions/FORM_HRV1/formhrv1-add/formhrv1-add.component";
import { NoDataComponent } from "./components/no-data/no-data.component";
import { AddStepUserComponent } from "./components/add-step-user/add-step-user.component";
import { CommentSkeletonComponent } from "./components/comment-skeleton/comment-skeleton.component";
import { DynamicFilesControlComponent } from "./components/dynamic-files-control/dynamic-files-control.component";
import { CustomARENDateInputComponent } from "./components/custom-arendate-input/custom-arendate-input.component";
import { WhatsPhoneSendcodeComponent } from "./components/whats-phone-sendcode/whats-phone-sendcode.component";
import { CompletedStepComponent } from "app/routes/humanresources/payrole/components/steps/completed-step/completed-step.component";
import { CalenderComponent } from "app/routes/humanresources/employees/calender/calender.component";
import { CalendarModule } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "app/custom-material-config";
import { ShowPlatformAccessCodesComponent } from "./components/show-platform-access-codes/show-platform-access-codes.component";
import { TaskDatesBarComponent } from "./components/task-dates-bar/task-dates-bar.component";
import { MaterialModule } from "app/material.module";
import { UserTransactionsComponent } from "./components/user-transactions/user-transactions.component";
import { CreateProjectTransactionComponent } from "./components/create-project-transaction/create-project-transaction.component";
import { DateFormnowPipe } from "./pipes/date-formnow.pipe";
import { TableDataComponent } from "./components/table-data/table-data.component";
import { TableModule } from "primeng/table";
import { MultiSelectModule } from "primeng/multiselect";
import { TransactionNotesModalComponent } from './components/transaction-notes-modal/transaction-notes-modal.component';

const MODULES = [
	NgbModule,
	MaterialModule,
	MaterialExtensionsModule,
	FlexLayoutModule,
	NgProgressModule,

	NgSelectModule,
	FormlyModule,
	FormlyMaterialModule,
	ToastrModule,
	TranslateModule,
	PdfViewerModule,
	MatSliderModule,
	VirtualScrollerModule,
	NgxSkeletonLoaderModule,
	TableModule,
	MultiSelectModule,
];
const COMPONENTS = [
	CalenderComponent,
	CompletedStepComponent,
	NoDataComponent,
	Formhrv1AddComponent,
	BreadcrumbComponent,
	DynamicFilesControlComponent,
	PageHeaderComponent,
	TransactionAttachmentsComponent,
	UnitLevelShowComponent,
	ErrorCodeComponent,
	AlertComponent,
	AlertComponent,
	ConfirmationDialog,
	AlertDialogComponent,
	FileviewComponent,
	PageTitleComponent,
	PersonDetailsComponent,
	VersionsShowComponent,
	TransactionsNotesComponent,
	TransactionsHistoryComponent,
	CommentSkeletonComponent,
	TaskDatesBarComponent,
	UserTransactionsComponent,
	TableDataComponent,
];
const COMPONENTS_DYNAMIC = [];
const DIRECTIVES = [DisableControlDirective];
const PIPES = [
	SafeUrlPipe,
	ToObservablePipe,
	LanguagePipe,
	ShortenLengthPipe,
	DateFormnowPipe,
];

@NgModule({
	declarations: [
		...COMPONENTS,
		...COMPONENTS_DYNAMIC,
		...DIRECTIVES,
		...PIPES,
		PreviewPdfComponent,
		NotificationsComponent,
		AddStepUserComponent,
		CustomARENDateInputComponent,
		WhatsPhoneSendcodeComponent,
		ShowPlatformAccessCodesComponent,
		CreateProjectTransactionComponent,
  TransactionNotesModalComponent,
	],
	imports: [
		CommonModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		CarouselModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		...MODULES,
	],
	exports: [
		CommonModule,
		CalendarModule,
		FormsModule,
		CustomARENDateInputComponent,
		RouterModule,
		ReactiveFormsModule,
		MatSelectModule,
		CarouselModule,
		...MODULES,
		...COMPONENTS,
		...DIRECTIVES,
		...PIPES,
	],
	providers: [
		{ provide: DateAdapter, useClass: AppDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
	],
	entryComponents: COMPONENTS_DYNAMIC,
})
export class SharedModule {}
