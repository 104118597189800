<div class="mbrl15 martb0">
	<div class="row">
		<div class="col-12">
			<div class="input-group input-group-sm mb-10">
				<input
					type="text"
					class="form-control almnabrformcontrol"
					(keyup)="getGroupsList()"
					[(ngModel)]="searchKey"
					placeholder="{{ 'lang_search' | language }}"
				/>
				<span class="input-group-btn">
					<button
						class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
						id="btnSearch"
						(click)="onAddEditGroup()"
						type="button"
						matTooltip="{{ 'lang_add' | language }}"
					></button>
				</span>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>Group Name</td>
							<td>Group Email</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody *ngIf="groupsList.length != 0" class="AppendList">
						<tr
							*ngFor="
								let row of groupsList;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ row.group_name }}</td>
							<td>{{ row.group_email }}</td>
							<td>
								<i
									class="fa fa-cog mnabricon"
									[matMenuTriggerFor]="menu"
									matTooltip="{{ 'lang_settings' | language }}"
								></i>
								<mat-menu #menu="matMenu">
									<button
										mat-menu-item
										class="d-flex justify-content-center align-items-center"
										(click)="onViewGroup(row)"
									>
										<span>{{ "lang_view" | language }}</span>
										<i class="fa fa-eye ml-1 mr-1 fa-icon mnabricon"></i>
									</button>
									<button
										mat-menu-item
										class="d-flex justify-content-center align-items-center"
										(click)="onAddEditGroup(row)"
									>
										<span>{{ "lang_edit" | language }}</span>
										<i
											class="fa fa-pencil-square-o ml-1 mr-1 fa-icon mnabricon"
										></i>
									</button>
									<button
										mat-menu-item
										class="d-flex justify-content-center align-items-center"
										(click)="deleteGroup(row?.group_id)"
									>
										<span>{{ "lang_delete" | language }}</span>
										<i class="fa fa-trash ml-1 mr-1 fa-icon red"></i>
									</button>
									<button
										mat-menu-item
										class="d-flex justify-content-center align-items-center"
										(click)="onAddUserGroup(row)"
									>
										<span
											>{{ "lang_add" | language }}
											{{ "lang_users" | language }}</span
										>
										<i class="fa fa-user-plus ml-1 mr-1 fa-icon mnabricon"></i>
									</button>
								</mat-menu>
							</td>
						</tr>
					</tbody>
					<tbody *ngIf="groupsList.length == 0" class="AppendList">
						<tr class="odd">
							<td colspan="10" align="center">{{ nodata }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
<div *ngIf="groupsList?.length != 0" class="card-footer padb0">
	<div class="row">
		<div class="col-12 col-sm-2">
			{{ "lang_total" | language }} : {{ collectionSize }}
		</div>
		<div class="col-12 col-sm-8 d-flex justify-content-center">
			<ngb-pagination
				[collectionSize]="collectionSize"
				[maxSize]="3"
				[rotate]="true"
				[ellipses]="false"
				[boundaryLinks]="true"
				[(page)]="pageno"
				[pageSize]="pagesize"
				(pageChange)="getGroupsList()"
			></ngb-pagination>
		</div>
		<div class="col-12 col-sm-2 d-flex justify-content-end">
			<mat-select
				class="customselect"
				[(ngModel)]="pagesize"
				(change)="getGroupsList()"
			>
				<mat-option value="10">10 {{ "lang_per_page" | language }}</mat-option>
				<mat-option value="20">20 {{ "lang_per_page" | language }}</mat-option>
				<mat-option value="50">50 {{ "lang_per_page" | language }}</mat-option>
				<mat-option value="100"
					>100 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option value="250"
					>250 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option value="500"
					>500 {{ "lang_per_page" | language }}</mat-option
				>
			</mat-select>
		</div>
	</div>
</div>
