<mat-accordion class="stylepanel groups_data">
  <mat-expansion-panel class="mpanel" [expanded]="true">
    <mat-expansion-panel-header class="mpanel-header holidays-header">
      {{ "lang_holidays" | language }}
    </mat-expansion-panel-header>
    <div class="material-panel-body tlist">
      <div class="mbrl15 fromtransactions">
        <div class="material-panel-body tlist">
          <div class="mbrl15 fromtransactions">
            <div
              class="row col-md-12 attendence mb-4"
              style="justify-content: end"
            >
              <div class="col-md-3">
                <button
                  type="button"
                  class="albutton mnabrbutton m-2"
                  (click)="openModal(createHolliday)"
                  *ngIf="!update_holiday"
                >
                  {{ "lang_add" | language }}
                </button>
                <button
                  type="button"
                  class="albutton mnabrbutton m-2"
                  (click)="openModal(createHolliday)"
                  *ngIf="update_holiday"
                >
                  {{ "lang_edit" | language }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive customResponsive">
                  <table border="1" class="table CustomTable">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>{{ "lang_holiday_id" | language }}</td>
                        <td>
                          {{ "lang_name" | language }}
                        </td>
                        <td>{{ "lang_start_date" | language }}</td>
                        <td>{{ "lang_end_date" | language }}</td>
                      </tr>
                    </thead>
                    <tbody
                      *ngIf="listData?.records?.length != '0'"
                      class="AppendList"
                    >
                      <tr
                        *ngFor="
                          let list of listData?.records;
                          let i = index;
                          let odd = odd;
                          let even = even
                        "
                        [ngClass]="{ odd: odd, even: even }"
                        class="{{ list.row_color }}{{ list.row_color_flash }}
											makeback removerow{{ list.mention_id }}
											"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>{{ list.shift_holiday_id }}</td>
                        <td *ngIf="lang_key == 'en'">{{ list.name_en }}</td>
                        <td *ngIf="lang_key == 'ar'">{{ list.name_ar }}</td>
                        <td>{{ list.start_holiday_date }}</td>
                        <td>{{ list.end_holiday_date }}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="loading">
                      <tr>
                        <td colspan="5" class="nodata" align="center">
                          <ngx-skeleton-loader
                            count="5"
                            [theme]="{ 'border-radius': '3px', height: '50px' }"
                          ></ngx-skeleton-loader>
                        </td>
                      </tr>
                    </tbody>
                    <tbody
                      *ngIf="listData?.length == '0' && !loading"
                      class="AppendList"
                    >
                      <tr class="odd">
                        <td colspan="5" class="nodata" align="center">
                          {{ nodata }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<ng-template #createHolliday>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_add_holidays" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()" class="form">
      <div class="mbrl15">
        <div>
          <div class="row">
            <div class="col-sm-12 col-12">
              <div
                class="table-responsive dropdownyes customResponsive tablefields"
              >
                <table
                  border="1"
                  class="table CustomTable"
                  width="100%"
                  formArrayName="shift_holidays"
                >
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>
                        <mat-icon
                          class="mnabricon"
                          matTooltip="{{ 'lang_add' | language }}"
                          (click)="add_shift_holidays()"
                          >add_box</mat-icon
                        >
                      </td>
                      <td
                        width="32%"
                        style="padding: 5px; word-break: break-all"
                      >
                        {{ "lang_holiday_date" | language }}
                        <span
                          class="red"
                          *ngIf="shift_holidays_Array?.length > 0"
                          >*</span
                        >
                      </td>
                      <td
                        width="32%"
                        style="padding: 5px; word-break: break-all"
                      >
                        {{ "lang_name_ar" | language }}
                        <span
                          class="red"
                          *ngIf="shift_holidays_Array?.length > 0"
                          >*</span
                        >
                      </td>
                      <td
                        width="32%"
                        style="padding: 5px; word-break: break-all"
                      >
                        {{ "lang_name_en" | language }}
                        <span
                          class="red"
                          *ngIf="shift_holidays_Array?.length > 0"
                          >*</span
                        >
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let item of shift_holidays_Group().controls;
                        let i = index;
                        let odd = odd;
                        let even = even
                      "
                      [formGroupName]="i"
                    >
                      <td class="verticalalignmid">
                        {{ i + 1 }}
                      </td>
                      <td class="verticalalignmid">
                        <i
                          (click)="remove_shift_holidays(i)"
                          class="fa fa-minus-square fa-icon red mnabricon"
                          matTooltip="{{ 'lang_delete' | language }}"
                        ></i>
                      </td>
                      <td
                        style="
                          padding: 5px;
                          word-break: break-all;
                          position: relative;
                        "
                      >
                        <mat-form-field
                          appearance="outline"
                          class="holidays-date"
                        >
                          <mat-date-range-input [rangePicker]="picker">
                            <input
                              matStartDate
                              formControlName="start_holiday_date"
                              readonly
                              placeholder="{{ 'lang_start_date' | language }}"
                            />
                            <input
                              matEndDate
                              formControlName="end_holiday_date"
                              readonly
                              placeholder="{{ 'lang_end_date' | language }}"
                            />
                          </mat-date-range-input>
                          <mat-datepicker-toggle
                            matIconSuffix
                            [for]="picker"
                          ></mat-datepicker-toggle>
                          <mat-date-range-picker #picker>
                          </mat-date-range-picker>
                        </mat-form-field>
                        <mat-error
                          *ngIf="
                            shift_holidays_Array.controls[i].get(
                              'start_holiday_date'
                            ).touched &&
                            shift_holidays_Array.controls[i].get(
                              'start_holiday_date'
                            ).errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}</mat-error
                        >
                      </td>
                      <td
                        style="
                          padding: 5px;
                          word-break: break-all;
                          position: relative;
                        "
                      >
                        <input
                          type="text"
                          formControlName="name_ar"
                          placeholder="{{ 'lang_name_ar' | language }}"
                          autocomplete="off"
                          class="form-control almnabrformcontrol"
                        />
                        <mat-error
                          *ngIf="
                            shift_holidays_Array.controls[i].get('name_ar')
                              .touched &&
                            shift_holidays_Array.controls[i].get('name_ar')
                              .errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}</mat-error
                        >
                      </td>
                      <td
                        style="
                          padding: 5px;
                          word-break: break-all;
                          position: relative;
                        "
                      >
                        <input
                          type="text"
                          formControlName="name_en"
                          placeholder="{{ 'lang_name_en' | language }}"
                          autocomplete="off"
                          class="form-control almnabrformcontrol"
                        />
                        <mat-error
                          *ngIf="
                            shift_holidays_Array.controls[i].get('name_en')
                              .touched &&
                            shift_holidays_Array.controls[i].get('name_en')
                              .errors?.required
                          "
                        >
                          {{ "lang_field_required" | language }}</mat-error
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer padb0">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
          <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
