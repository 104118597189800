/* import { DatePipe } from '@angular/common'; */
import { Component, Input, OnInit } from "@angular/core";
/* import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { PreviewPdfComponent } from '@shared/components/preview-pdf/preview-pdf.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { platformRequirements } from 'app/routes/transactions/FORM_PS_TR1/formdata';
import { DwsrService } from 'app/routes/transactions/FORM_PS_TR1/services/dwsr.service';
import { ByPhasesComponent } from 'app/routes/transactions/FORM_PS_TR1/steps/t-step1/by-phases/by-phases.component';
import { GeneralNumbersComponent } from 'app/routes/transactions/FORM_PS_TR1/steps/t-step1/general-numbers/general-numbers.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'; */

@Component({
  selector: "app-OverTime-asPdf-view",
  templateUrl: "./OverTime-asPdf-view.component.html",
  styleUrls: ["./OverTime-asPdf-view.component.scss"],
})
export class OverTimeAsPdfViewComponent implements OnInit {
  @Input() records;
  @Input() financial_details;
  @Input() notes;
  @Input() status;
  @Input() lang_key;
  @Input() alldata;

  constructor() {}

  ngOnInit(): void {}
}
