import {
	Component,
	Input,
	forwardRef,
	ChangeDetectionStrategy,
	ViewChild,
	ElementRef,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import {
	FormGroup,
	FormControlName,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	AbstractControl,
} from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
} from "@angular/material/core";
import { Output, EventEmitter } from "@angular/core";
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from "moment";
import { DataService } from "@core/bootstrap/data.service";
import { DatePipe } from "@angular/common";

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
	parse: {
		dateInput: "DD/MM/YYYY",
	},
	display: {
		dateInput: "DD/MM/YYYY",
		monthYearLabel: "MMM YYYY",
		dateA11yLabel: "DD/MM/YYYY",
		monthYearA11yLabel: "DD/MM/YYYY",
	},
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CustomARENDateInputComponent),
	multi: true,
};

@Component({
	selector: "custom-arendate-input",
	templateUrl: "./custom-arendate-input.component.html",
	styleUrls: ["./custom-arendate-input.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CustomARENDateInputComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => CustomARENDateInputComponent),
			multi: true,
		},
	],
})
export class CustomARENDateInputComponent
	implements OnInit, ControlValueAccessor
{
	@Output() dateChange = new EventEmitter<any>();
	@Input() parentFormControlName: FormControlName;
	@Input() ARDateFormControlName: FormControlName;
	@Input() parentForm: FormGroup;
	constructor(public ds: DataService, public datepipe: DatePipe) {}
	@Input() label: string;
	@ViewChild("datePicked") datePicked: ElementRef;
	@Input() private format = "DD/MM/YYYY";
	@Input() _dateValue = "";
	@Input() public readOnly = false;
	isArabic = false;
	arDate;
	enDate;
	addNewItem(value: any) {
		this.dateChange.emit(value);
	}
	clickOnIt() {
		this.datePicked.nativeElement.click();
	}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?._dateValue && changes?._dateValue?.currentValue) {
			this.addEventChange("change", changes?._dateValue?.currentValue);
		}
	}

	lastDateValue = "";

	addEventChange(type: string, event: any) {
		let obj = {};
		if (this.isArabic) {
			this.ds.arabicToGiorgen("AR", event).subscribe(
				(data) => {
					if (data.status) {
						let r = data.data.gregorian.date.split("-");
						let reqdate = r[2] + "/" + r[1] + "/" + r[0];
						obj = {
							enField: reqdate,
							arField: event,
						};
						this.lastDateValue = event;
						this.addNewItem(obj);
					} else {
						return "";
					}
				},
				(error) => {
					return "";
				}
			);
		} else {
			this.ds
				.arabicToGiorgen("EN", this.datepipe.transform(event, "dd/MM/yyyy"))
				.subscribe(
					(data) => {
						if (data.status) {
							let r = data.data.hijri.date.split("-");
							let reqdate = r[0] + "/" + r[1] + "/" + r[2];
							obj = {
								arField: reqdate,
								enField: event,
							};
							this.lastDateValue = event;
							this.addNewItem(obj);
						} else {
							return "";
						}
					},
					(error) => {
						return "";
					}
				);
		}

		/* this.dateValue = moment(event.value, this.format); */
	}

	toggleDates() {
		if (!this.lastDateValue) {
			this.isArabic = !this.isArabic;
			return 0;
		}
		if (this.isArabic) {
			this.ds.arabicToGiorgen("AR", this.lastDateValue).subscribe(
				(data) => {
					if (data.status) {
						let r = data.data.gregorian.date.split("-");
						let reqdate = r[2] + "/" + r[1] + "/" + r[0];
						this.isArabic = !this.isArabic;
						_moment.locale("en");
						this._dateValue = new Date(reqdate).toISOString();
						this.lastDateValue = new Date(reqdate).toISOString();
					} else {
						return "";
					}
				},
				(error) => {
					return "";
				}
			);
		} else {
			this.isArabic = !this.isArabic;
			this.ds
				.arabicToGiorgen(
					"EN",
					this.datepipe.transform(
						this.lastDateValue || this._dateValue,
						"dd/MM/yyyy"
					)
				)
				.subscribe(
					(data) => {
						if (data.status) {
							let r = data.data.hijri.date.split("-");
							let reqdate = r[0] + "/" + r[1] + "/" + r[2];

							this.arDate = reqdate;
							this.lastDateValue = reqdate;
						} else {
							return "";
						}
					},
					(error) => {
						return "";
					}
				);
		}
	}

	writeValue(value: any) {}

	propagateChange = (_: any) => {};

	registerOnChange(fn) {
		this.propagateChange = fn;
	}

	registerOnTouched() {}
}
