import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";

@Component({
	selector: "app-w-templates-modal",
	templateUrl: "./w-templates-modal.component.html",
	styleUrls: ["./w-templates-modal.component.scss"],
})
export class WTemplatesModalComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	templateList = [];
	templatesLimit: string = "10";
	waToken: string = "";
	waPhonenumberID: string = "";
	constructor(
		private dialogRef: MatDialogRef<WTemplatesModalComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data,
		private lang: LanguagePipe,
		public ds: DataService,
		private spinner: PreloaderService
	) {
		this.waToken = data?.waToken;
		this.waPhonenumberID = data?.waPhonenumberID;
	}

	ngOnInit(): void {
		this.getAllTemplates();
	}

	getAllTemplates() {
		let formData = new FormData();
		formData.append("limit", this.templatesLimit || "");
		this.spinner.show();
		this.ds.post("WhatsappApi/getTemplates", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.templateList = res?.records;
				} else {
					this.templateList = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.templateList = [];
			}
		);
	}

	onTemplateSelected(template) {
		this.dialogRef.close(template);
	}
}
