import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

declare var $: any;
@Component({
  selector: "app-supervision-customers",
  templateUrl: "./supervision-customers.component.html",
  styleUrls: ["./supervision-customers.component.scss"],
})
export class SupervisionCustomersComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() supervision_data;
  @Input() able2add;
  @Input() able2edit;
  @Input() project_data;
  @Input() makeAction;
  searchKey = "";
  able2delete = JSON.parse(this.ls.getItem("is_admin"));
  settings = environment.singledropdown;
  multiple = environment.multipledropdown;
  users: any = [];
  positionItems: any = [];
  positions: any = [];
  tpageno = 1;
  tpagesize = "10";
  tListData: any = [];
  tnodata: string = this.lang.transform("lang_loading");
  error_message: string = "";
  error_status: boolean = false;
  form: FormGroup;
  team_posistions: FormArray;
  submitted = false;
  viewdata: any = [];
  directors: any = [];
  managers: any = [];
  action: string = "ADD";
  public config = environment.modelconfig;
  constructor(
    public ds: DataService,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.get_positions();
    this.load_supervision_customers();
    this.buildform();
  }
  public selectallposition() {
    this.positionItems = this.positions.map((item) => item.value);
    this.load_supervision_customers();
  }
  public get_positions() {
    this.positions = [];
    this.ds.getActionByUrl([], "366484fd45").subscribe((res) => {
      if (res.status) {
        this.positions = res.records;
      }
    });
  }
  public buildform() {
    this.form = new FormGroup({
      customers: this.fb.array([]),
    });
    this.team_posistions = this.form.get("customers") as FormArray;
  }
  public items_group() {
    return this.form.get("customers") as FormArray;
  }
  get itemsgroup() {
    return this.form.get("customers") as FormArray;
  }
  public add_new_items() {
    this.team_posistions.push(this.item_records());
  }
  public removeItems(index) {
    this.users = [];
    if (this.team_posistions.length > 1) {
      this.team_posistions.removeAt(index);
    }
  }
  public item_records(): FormGroup {
    return this.fb.group({
      project_settings_id: new FormControl(null, [Validators.required]),
      project_user_group_user_id: new FormControl(null, [Validators.required]),
    });
  }
  public search_users(key) {
    this.users = [];
    let param = new FormData();
    if (key.term && key.term != undefined) {
      param.append("user_type_id", "3");
      param.append("search", key.term);
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "sZMVmoFEATU9YlN/projects_add"
        )
        .subscribe((res) => {
          if (res.status) {
            this.users = res.records;
          }
        });
    }
  }
  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }
  public emptyform() {
    this.alert.clear();
    this.form.reset();
    this.itemsgroup.controls = [];
    this.add_new_items();
  }
  public emptyFormEdit() {
    this.action = "EDIT";
    this.alert.clear();
    this.itemsgroup.controls = [];
    this.spinner.show();
    let formdata = new FormData();
    formdata.append(
      "projects_supervision_id",
      this.supervision_data.projects_supervision_id
    );
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formdata),
        "pr/get_wcustomers/" +
          this.supervision_data.projects_profile_id +
          "/" +
          this.supervision_data.projects_work_area_id +
          "/1/100"
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.users = res.records;
            let uniquesettings = this.getDistinct(res.records);
            uniquesettings.forEach((v, k) => {
              this.add_new_items();
              this.team_posistions.controls[k]
                .get("project_settings_id")
                .setValue(v.value);
              this.team_posistions.controls[k]
                .get("project_user_group_user_id")
                .setValue(this.team_users(res.records, v.value));
            });
          } else {
            this.add_new_items();
          }
        },
        (error) => {
          this.spinner.hide();
          this.add_new_items();
        }
      );
  }
  private team_users(records, id) {
    let ss = [];
    records.forEach((v, k) => {
      if (v.project_settings_id === id) {
        ss.push(v.value);
      }
    });
    return ss;
  }
  public getDistinct(data) {
    let resArr = [];
    let uniqueid = [];
    data.forEach(function (item) {
      if (
        uniqueid.indexOf(item.project_settings_id) === -1 &&
        item.project_settings_id != "0"
      ) {
        uniqueid.push(item.project_settings_id);
        resArr.push({
          value: item.project_settings_id,
          label: item.position,
          key: item.project_user_group_mention_key,
        });
      }
    });
    return resArr;
  }
  //Submit Form
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.set("projects_profile_id", this.supervision_data.projects_profile_id);
    param.set(
      "projects_work_area_id",
      this.supervision_data.projects_work_area_id
    );
    param.set(
      "projects_supervision_id",
      this.supervision_data.projects_supervision_id
    );
    this.team_posistions.value.forEach((v, k) => {
      if (v.project_settings_id) {
        param.set(
          "project_settings_id[" + k + "]",
          v.project_settings_id || ""
        );
      }
      if (v.project_user_group_user_id) {
        param.set(
          "project_user_group_user_id[" + k + "]",
          v.project_user_group_user_id.toString()
        );
      }
    });
    let url =
      this.action == "ADD" ? "pr/create_customers" : "pr/update_customers";
    this.ds.postActionByUrl(param, url).subscribe(
      (res) => {
        this.spinner.hide();
        this.error_status = res.status;
        if (res.status) {
          this.alert.success(res.msg);
          this.form.reset();
          this.load_supervision_customers();
          setTimeout(() => {
            this.modalRef.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.alert.error(
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  // List customer Users
  public search_supervision_customers() {
    this.tpageno = 1;
    this.tpagesize = "10";
    this.load_supervision_customers();
  }
  public load_supervision_customers_page(page) {
    this.spinner.show();
    this.tpageno = page;
    this.tpagesize = this.tpagesize;
    this.load_supervision_customers();
  }
  public load_supervision_customers_pagesize(size) {
    this.spinner.show();
    this.tpageno = 1;
    this.tpagesize = size;
    this.load_supervision_customers();
  }
  public load_supervision_customers() {
    let formdata = new FormData();
    formdata.append(
      "projects_supervision_id",
      this.supervision_data.projects_supervision_id
    );
    formdata.append("search_key", this.searchKey);
    formdata.append("positions", this.positionItems.toString());
    this.ds
      .getActionByUrl(
        this.ds.formData2string(formdata),
        "pr/get_wcustomers/" +
          this.project_data.projects_profile_id +
          "/" +
          this.supervision_data.projects_work_area_id +
          "/" +
          this.tpageno +
          "/" +
          this.tpagesize
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.tListData = res;
          } else {
            this.tListData = [];
            this.tnodata = res.error;
          }
        },
        (error) => {
          this.spinner.hide();
          this.tListData = [];
          this.tnodata =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
  }
  //Delete Customer Users
  public checkAllItemsTeam(event) {
    if (event.target.checked) {
      $(".supervision_customers .eachItemTeam").prop("checked", true);
      $(".supervision_customers .makeback").addClass("bground");
      $(".supervision_customers .deleteIconDiv").show();
    } else {
      $(".supervision_customers .eachItemTeam").prop("checked", false);
      $(".supervision_customers .makeback").removeClass("bground");
      $(".supervision_customers .deleteIconDiv").hide();
    }
  }
  public checkEachItemTeam(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".supervision_customers .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".supervision_customers .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".supervision_customers .eachItemTeam:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".supervision_customers .eachItemTeam").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".supervision_customers .deleteIconDiv").show();
    } else {
      $(".supervision_customers .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".supervision_customers #allItemsTeam").prop("checked", true);
    } else {
      $(".supervision_customers #allItemsTeam").prop("checked", false);
    }
  }
  public deleteBulkDataTeam() {
    const checkedtotal = [];
    $(".supervision_customers .eachItemTeam:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true,
        width: "400px",
        data: {
          title: this.lang.transform("lang_confirmation"),
          message: this.lang.transform("lang_are_you_sure"),
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          this.ds
            .deleteActionByUrl(
              checkedtotal,
              "pr/delete_wcustomers/" +
                this.supervision_data.projects_profile_id +
                "/" +
                this.supervision_data.projects_work_area_id
            )
            .subscribe(
              (data) => {
                this.spinner.hide();
                if (data.status) {
                  this.load_supervision_customers();
                  $(".supervision_customers #allItemsTeam").prop(
                    "checked",
                    false
                  );
                  $(".supervision_customers .deleteIconDiv").hide();
                  this.ds.dialogf("", data.msg);
                } else {
                  this.ds.dialogf("", data.error);
                }
              },
              (error) => {
                this.ds.dialogf(
                  "",
                  error && error.error && error.error.error
                    ? error.error.error
                    : this.lang.transform("lang_internal_server_error")
                );
                this.spinner.hide();
              }
            );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
