import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-receipt-lists',
  templateUrl: './receipt-lists.component.html',
  styleUrls: ['./receipt-lists.component.scss']
})
export class ReceiptListsComponent implements OnInit ,OnDestroy {
  apiUrl            = environment.SERVER_ORIGIN;
  branches: any     = [];
  finances: any     = [];
  recData:any       = [];
  branch_id: any    = (localStorage.getItem('selectedBranch') != null) ? localStorage.getItem('selectedBranch') : '0';
  recviewdata: any  = [];
  lodingdatas:any   = this.lang.transform('lang_loading');
  recpage_no:any    = localStorage.getItem('AC_RE_P') || 1;
  recpage_size:any  = localStorage.getItem('AC_RE_S') || '10';
  finance_id: any   = localStorage.getItem('AC_RE_S_F') || null;
  searchKey:any     = localStorage.getItem('AC_RE_S_K') || "";
  accAction:string     = "LIST";
  constructor(public ds:DataService,public datePipe: DatePipe,public router : Router, public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { 
    this.spinner.show();
    this.load_branches();
  }
  ngOnInit(): void {
    if (this.branch_id && this.branch_id != '0') {
      this.load_financial_years();
    }
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public selectBrach(branch) {
    this.finances = [];
    this.finance_id = "";
    if (branch && branch !='0' ) {
      localStorage.setItem('selectedBranch', branch);
      this.branch_id = branch;
      this.load_financial_years();
    } else {
      this.spinner.hide();
    }
  }
  public load_financial_years() {
    this.finances = [];
    this.finance_id = null;
    this.subscriptions.add(
        this.ds.getActionByUrl([], 'financial/' + this.branch_id).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
              }
            });
          }
          this.load_receipts();
        }, error => {
          this.spinner.hide();
        })      
      )

  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
        this.ds.getActionByUrl([], 'ab/accounts_view').subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        }, error => {
          this.spinner.hide();
        })      
      )

  }
  public loadpagerec(page) {
    this.spinner.show();
    this.recpage_no = page;
    this.recpage_size = this.recpage_size;
    this.load_receipts();
  }
  public loadrecpagesize(size) {
    this.spinner.show();
    this.recpage_no = 1;
    this.recpage_size = size;
    this.load_receipts();
  }
  public search_receipts() {
    this.recpage_no = 1;
    this.recpage_size = '10';
    this.load_receipts();
  }
  public load_receipts() {    
		//Accounts Receipts
		localStorage.setItem('AC_RE_S_K', this.searchKey);
		localStorage.setItem('AC_RE_S_F', this.finance_id);
		localStorage.setItem('AC_RE_P',   this.recpage_no);
		localStorage.setItem('AC_RE_S',   this.recpage_size);
    let formdata = new FormData();
    this.branch_id = (this.branch_id) ? this.branch_id : 0;
    formdata.append('search_key', this.searchKey);
    formdata.append('finance_id',(this.finance_id) ? this.finance_id : '0');
    this.subscriptions.add(
        this.ds.postActionByUrl(formdata, 'listreceipts/' + this.branch_id + '/' + this.recpage_no + '/' + this.recpage_size).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.recData = res;
          } else {
            this.recData = [];
            this.lodingdatas = res.error;
          }
        }, error => {
          this.spinner.hide();
          this.recData = [];
          this.lodingdatas = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
        })      
      )

  }
  public remove_receipt(data) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.spinner.show()
            this.subscriptions.add(
      this.ds.deleteActionByUrl([], 'deletereceipt/' + this.branch_id + '/' + data.transaction_id + '/' + data.payment_receipt_id).subscribe(
                    (data) => {
                      this.spinner.hide();
                      if (data.status) {
                        this.load_receipts();
                        this.ds.dialogf('',data.msg);
                      } else {
                        this.ds.dialogf('',data.error);
                      }
                    },
                    (error) => {
                      this.spinner.hide();
                      this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                    }
                  )      
              )
            ;
          }
        })      
      )
  ;    
  }
}
