import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-add-external-person",
	templateUrl: "./add-external-person.component.html",
	styleUrls: ["./add-external-person.component.scss"],
})
export class AddExternalPersonComponent implements OnInit {
	ticketList = [];
	form: FormGroup;
	edit_mode = false;
	fromTicket = false;
	ticket_id;
	constructor(
		private fb: FormBuilder,
		public ds: DataService,
		public spinner: PreloaderService,
		public sweetAlert: SweetAlertService,
		public lang: LanguagePipe,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<AddExternalPersonComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.fromTicket = data?.fromTicket;
		this.ticket_id = data?.ticket_id;
		if (data?.epData) {
			this.edit_mode = true;
		}
	}

	ngOnInit(): void {
		this.buildForm();
		this.getTicketList({ term: "" });
		if (this.edit_mode) {
			this.setEditData(this.data?.epData);
		}
	}

	buildForm() {
		this.form = this.fb.group({
			person_name: ["", Validators.required],
			mobile_no: ["", Validators.required],
			email: ["", [Validators.required, Validators.email]],
			ticket_id: [this.ticket_id || null, Validators.required],
		});
	}

	setEditData(data) {
		this.form.patchValue({ ...data, ticket_id: [data?.ticket_id] });
	}

	getTicketList(event) {
		this.spinner.show();
		let formData = new FormData();
		formData.append("ticket_titel", event?.term || "");
		formData.append("ticket_no", "");
		this.ds.post("tasks/get_data_ticket_system", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.ticketList = res?.data;
				} else {
					this.ticketList = [];
				}
			},
			(err) => {
				this.spinner.hide();
				this.ticketList = [];
			}
		);
	}

	onSubmit() {
		let formData = new FormData();
		formData.append("person_name", this.form.get("person_name").value || "");
		formData.append("mobile_no", this.form.get("mobile_no").value || "");
		formData.append("email", this.form.get("email").value || "");
		formData.append("ticket_id", this.form.get("ticket_id").value || "");
		let url = "tasks/add_extarnal_person";
		if (this.edit_mode) {
			url = "tasks/update_extarnal_person";
			formData.append("person_id", this.data?.epData?.person_id || "");
		}
		this.spinner.show();
		this.ds.post(url, formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
					this.dialogRef.close(true);
				} else {
					this.sweetAlert.errorToast(res?.error, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
