<div class="w-100 m-auto text-center">
  <img src="../../../../../../../assets/img/Completed-amico.png" style="max-width: 500px;" width="100%"  alt="">
  <div class="w-100">
      <button
      style="width: fit-content !important;"
      mat-button
      class="albutton mnabrbutton mat-accent"
       [routerLink]="['/tasks/alltasks']"
    >
      {{ "lang_all_ticket" | language }}
    </button>
  </div>
</div>