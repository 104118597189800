import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { TeamsCreateUpdateComponent } from "./teams-create-update/teams-create-update.component";
declare var $: any;

@Component({
	selector: "app-design-teams",
	templateUrl: "./design-teams.component.html",
	styleUrls: ["./design-teams.component.scss"],
})
export class DesignTeamsComponent implements OnInit {
	@Input() design_data;
	@Input() able2add;
	@Input() able2edit;
	@Input() project_data;
	@Input() project_profile_id;
	tListData: any = [];
	positionItems = [];
	positions = [];
	searchKey = "";
	tnodata: string = this.lang.transform("lang_loading");
	tpageno = 1;
	tpagesize = 10;
	constructor(
		private ds: DataService,
		private spinner: PreloaderService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.get_positions();
		this.load_design_teams();
	}
	get_positions() {
		this.positions = [];
		this.ds.getActionByUrl([], "366484fd45").subscribe((res) => {
			if (res.status) {
				this.positions = res.records;
			}
		});
	}

	load_design_teams() {
		this.spinner.show();
		let formdata = new FormData();
		formdata.append("search_key", this.searchKey);
		formdata.append("positions", this.positionItems.toString());
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formdata),
				"project_design/get_project_user_action/" +
					this.project_profile_id +
					"/" +
					this.design_data?.project_design_id +
					"/" +
					this.tpageno +
					"/" +
					this.tpagesize
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.tListData = res;
					} else {
						this.tListData = [];
						this.tnodata = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.tListData = [];
					this.tnodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}

	checkAllItemsTeam(event) {
		if (event.target.checked) {
			$(".design_teams .eachItemTeam").prop("checked", true);
			$(".design_teams .makeback").addClass("bground");
			$(".design_teams .deleteIconDiv").show();
		} else {
			$(".design_teams .eachItemTeam").prop("checked", false);
			$(".design_teams .makeback").removeClass("bground");
			$(".design_teams .deleteIconDiv").hide();
		}
	}
	checkEachItemTeam(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".design_teams .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".design_teams .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".design_teams .eachItemTeam:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".design_teams .eachItemTeam").each(function () {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".design_teams .deleteIconDiv").show();
		} else {
			$(".design_teams .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".design_teams #allItemsTeam").prop("checked", true);
		} else {
			$(".design_teams #allItemsTeam").prop("checked", false);
		}
	}
	deleteBulkDataTeam() {
		const checkedtotal = [];
		$(".design_teams .eachItemTeam:checked").each(function () {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			this.sweetAlert
				.confirmMessage(
					this.lang.transform("lang_are_you_sure"),
					this.lang.transform("lang_yes")
				)
				.then((confirmRes) => {
					if (confirmRes.isConfirmed) {
						this.spinner.show();
						this.ds
							.deleteActionByUrl(
								checkedtotal,
								"project_design/delete_project_users/" +
									this.project_profile_id +
									"/" +
									this.design_data.project_design_id
							)
							.subscribe(
								(data) => {
									this.spinner.hide();
									if (data.status) {
										this.load_design_teams();
										$(".design_teams #allItemsTeam").prop("checked", false);
										$(".design_teams .deleteIconDiv").hide();
										this.sweetAlert.successToast(data.msg, 2000);
									} else {
										this.sweetAlert.errorToast(data.error, 2000);
									}
								},
								(error) => {
									this.sweetAlert.successToast(
										error && error.error && error.error.error
											? error.error.error
											: this.lang.transform("lang_internal_server_error"),
										2000
									);
									this.spinner.hide();
								}
							);
					}
				});
		}
	}

	onAddUpdateTeams(data?) {
		const dialogRef = this.dialog.open(TeamsCreateUpdateComponent, {
			width: "700px",
			data: {
				project_data: this.project_data,
				design_data: this.design_data,
				teams_data: data ? data : null,
			},
		});
	}
}
