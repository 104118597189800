import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
declare var $: any;

@Component({
	selector: "app-form-pq1-add",
	templateUrl: "./form-pq1-add.component.html",
	styleUrls: ["./form-pq1-add.component.scss"],
})
export class FormPq1AddComponent implements OnInit {
	project_id = this.route.snapshot.paramMap.get("pid");
	branch_id = this.route.snapshot.paramMap.get("bid");
	service_id = this.route.snapshot.paramMap.get("sid");
	@Input() form_key;
	@Input() form_request_id;
	@Input() allData;
	transaction_request: any;
	persons: any = [];
	notes: any = [];
	form_url = "";
	form: FormGroup;
	lang_key = localStorage.getItem("lang_key");
	//stepper
	@ViewChild("stepper") myStepper: MatStepper;
	totalStepsCount: number = 6;
	selectedIndex: number = 0;
	stepProgressValue = 100 / this.totalStepsCount;
	progressValue = this.stepProgressValue;
	stepsTitles = [
		`${this.lang.transform("lang_configurations")}`,
		`${this.lang.transform("lang_contractor_team_approval")}`,
		`${this.lang.transform("lang_final_result")}`,
	];
	stepTitle = this.stepsTitles[this.selectedIndex];
	@ViewChild("stepTitleEl") stepTitleEl: any;
	varSettimeout: any;
	isLinear: boolean = false;
	currentStep = 0;
	@Output() showAction = new EventEmitter();
	drawing_file: any;
	constructor(
		public ds: DataService,
		public datepipe: DatePipe,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		public changeDetectorRef: ChangeDetectorRef,
		private route: ActivatedRoute
	) {}
	ngOnInit(): void {
		this.form_url = "FORM_PQ1";
		this.currentStep = 0;
	}
	ngAfterViewInit(): void {
		this.varSettimeout = setTimeout(() => {
			this.styleStepperTitle();
		}, 1100);
	}

	selectionChange(e: any) {
		this.selectedIndex = e.selectedIndex;
		this.progressValue = this.stepProgressValue * (this.selectedIndex + 1);
		this.styleStepperTitle();
		this.stepTitle = this.stepsTitles[this.selectedIndex];
	}

	styleStepperTitle() {
		if (this.selectedIndex + 1 == this.totalStepsCount) {
			$("#stepTitle").css(
				`${this.lang_key == "en" ? "left" : "right"}`,
				`${this.progressValue - 12}%`
			);
		} else {
			$("#stepTitle").css(
				`${this.lang_key == "en" ? "left" : "right"}`,
				`${this.progressValue - 6}%`
			);
		}
	}
	moveStepper(action: string, mode: string) {
		if (mode == "create") {
			return false;
		} else {
			if (
				action == "next" &&
				this.myStepper.selectedIndex + 1 < this.myStepper.steps.length
			) {
				this.myStepper.selectedIndex++;
			} else if (action == "prev" && this.myStepper.selectedIndex > 0) {
				this.myStepper.selectedIndex--;
			} else {
				return false;
			}
		}
	}

	current_stepper(step) {
		this.currentStep = parseInt(step);
	}

	ngOnDestroy(): void {
		clearTimeout(this.varSettimeout);
	}
}
