<div class="card">
	<div class="width100">
		<i
			*ngIf="files && files.edu"
			matTooltip="{{ 'lang_recent_attachment' | language }} : {{ files.edu_d }}"
			class="fa fa-paperclip s2icon mnabricon"
			(click)="ds.preview_pdf_file(files.edu)"
		></i>
		<div class="card-header">
			{{ "lang_human_resources_education_details" | language }}
		</div>
		<div class="card-body pad0" [formGroup]="signupForm">
			<div class="mbrl15" formGroupName="educationDetails">
				<div class="row">
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="primary_education_level"
								>{{ "lang_human_resources_graduation" | language }} :
								<span class="red">*</span></label
							>
							<select
								formControlName="primary_education_level"
								id="primary_education_level"
								type="text"
								matTooltip="{{ 'lang_human_resources_graduation' | language }}"
								class="form-control almnabrformcontrol primary_education_level"
							>
								<option value="">{{ "lang_choose_options" | language }}</option>
								<option value="SL">
									{{ "lang_human_resources_belowsslc" | language }}
								</option>
								<option value="SS">
									{{ "lang_human_resources_sslc" | language }}
								</option>
								<option value="HS">
									{{ "lang_human_resources_hsc" | language }}
								</option>
								<option value="DP">
									{{ "lang_human_resources_diploma" | language }}
								</option>
								<option value="UG">
									{{ "lang_human_resources_bachelor" | language }}
								</option>
								<option value="PG">
									{{ "lang_human_resources_pg" | language }}
								</option>
								<option value="DC">
									{{ "lang_human_resources_doc" | language }}
								</option>
							</select>
							<mat-error
								*ngIf="
									signupForm.get('educationDetails')['controls'][
										'primary_education_level'
									].touched &&
									signupForm.get('educationDetails')['controls'][
										'primary_education_level'
									].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="primary_graduation_year"
								>{{ "lang_human_resources_graduation_year" | language }} :
								<span class="red">*</span></label
							>
							<input
								formControlName="primary_graduation_year"
								minlength="4"
								maxlength="4"
								id="primary_graduation_year"
								type="text"
								placeholder="{{
									'lang_human_resources_graduation_year' | language
								}}"
								class="form-control almnabrformcontrol primary_graduation_year"
								(keypress)="ds.numberOnly($event)"
							/>
							<mat-error
								*ngIf="
									signupForm.get('educationDetails')['controls'][
										'primary_graduation_year'
									].touched &&
									signupForm.get('educationDetails')['controls'][
										'primary_graduation_year'
									].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
							<mat-error
								*ngIf="
									signupForm.get('educationDetails')['controls'][
										'primary_graduation_year'
									].errors?.minlength
								"
							>
								{{ "lang_minimum" | language }} 4
							</mat-error>
							<mat-error
								*ngIf="
									signupForm.get('educationDetails')['controls'][
										'primary_graduation_year'
									].errors?.maxlength
								"
							>
								{{ "lang_maximum" | language }} 4
							</mat-error>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="edu_file_name"
								>{{ "lang_human_resources_filename" | language }} :
								<span class="red" *ngIf="action !== 'EDIT'">*</span></label
							>
							<input
								formControlName="edu_file_name"
								id="edu_file_name"
								type="text"
								placeholder="{{ 'lang_human_resources_filename' | language }}"
								class="form-control almnabrformcontrol edu_file_name"
							/>
							<mat-error
								*ngIf="
									signupForm.get('educationDetails')['controls'][
										'edu_file_name'
									].touched &&
									signupForm.get('educationDetails')['controls'][
										'edu_file_name'
									].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<div *ngIf="!isFile && action !== 'EDIT'">
								<span class="red">*</span>
							</div>
							<div *ngIf="isFile">
								<i
									aria-hidden="true"
									(click)="removeFile()"
									class="fa faicon fa-close red"
								></i>
							</div>
							<div class="IDDIV">
								<input
									name="education_file"
									type="file"
									onclick="this.value = null"
									(change)="uploadEDUFile($event)"
									class="nghide albutton width100"
									accept=".pdf"
								/>
								<label id="uploadIDButton" class="albutton"
									>{{ idfilename }}
								</label>
								<mat-error
									*ngIf="
										signupForm.get('educationDetails')['controls'][
											'education_file'
										].touched &&
										signupForm.get('educationDetails')['controls'][
											'education_file'
										].errors?.required
									"
								>
									{{ "lang_field_required" | language }}
								</mat-error>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer padb0">
				<div class="row">
					<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
						<button type="button" class="albutton" matStepperPrevious>
							{{ "lang_previous" | language }}
						</button>
					</div>
					<div class="col-md-8 col-sm-6 col-xs-12 mb-10"></div>
					<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
						<button
							type="button"
							class="albutton"
							matStepperNext
							(click)="rstep6Submitted()"
						>
							{{ "lang_next" | language }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
