<div class="col-md-3 mt-2 mb-2">
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label
      >{{ "lang_attendance_report" | language }}
      {{ "lang_month" | language }}</mat-label
    >
    <input matInput [matDatepicker]="dp" readonly [formControl]="date2" />

    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
      #dp
      startView="multi-year"
      (monthSelected)="setMonthAndYear($event, dp)"
      panelClass="example-month-picker"
    >
    </mat-datepicker>
  </mat-form-field>
</div>
<div class="table-responsive dropdownyes customResponsive">
  <table border="1" class="table CustomTable">
    <thead>
      <tr>
        <td>#</td>
        <td>{{ "lang_human_resources_number" | language }}</td>
        <td>{{ "lang_human_resources_employee_name" | language }}</td>
        <td>{{ "lang_branch_name" | language }}</td>
        <td>{{ "lang_shift_title" | language }}</td>
        <td>{{ "lang_emloyee_hour" | language }}</td>
        <td>{{ "lang_total_hours" | language }}</td>
        <td>{{ "lang_ratio" | language }}</td>
        <td>{{ "lang_action" | language }}</td>
      </tr>
    </thead>
    <tbody class="AppendList" *ngIf="calendarReport.length != 0">
      <tr
        *ngFor="
          let data of calendarReport;
          let i = index;
          let odd = odd;
          let even = even
        "
        [ngClass]="{ odd: odd, even: even }"
      >
        <td>{{ i + 1 }}</td>
        <td>
          {{ data.employee_number }}
        </td>
        <td>
          {{ data.employee_name }}
        </td>
        <td>
          {{ data.branch_name_en }}
        </td>
        <td>
          {{ data.shift_title_english }}
        </td>
        <td>
          {{ data.employee_hours }}
        </td>
        <td>
          {{ data.total_employee_hours }}
        </td>
        <td>
          {{ data.ratio }}
        </td>
        <td>
          <i
            matTooltip="{{ 'lang_view' | language }}"
            class="fa fa-eye fa-icon mnabricon s2icon"
            (click)="
              loadContract(
                data.days,
                data.employee_number,
                data.employee_name,
                viewContracts
              )
            "
          ></i>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="calendarReport.length == 0" class="AppendList">
      <tr class="odd">
        <td colspan="9" align="center">{{ lodingdatas }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="calendarReport.length" class="card-footer padb0">
  <div class="row responsive_pagination">
    <div
      class="col-lg-3 col-sm-3 col-6 mb-10"
      style="height: fit-content; margin: auto 0"
    >
      {{ "lang_total" | language }} :
      {{ total_records }}
    </div>
    <div
      class="col-lg-6 col-sm-6 mb-10"
      style="display: flex; justify-content: center"
    >
      <ngb-pagination
        class="m-auto"
        [collectionSize]="total_records"
        [rotate]="true"
        [ellipses]="false"
        [maxSize]="3"
        [boundaryLinks]="true"
        [(page)]="page_no"
        [pageSize]="page_size"
        (pageChange)="getAttendanceReport()"
      ></ngb-pagination>
    </div>
    <div
      class="col-lg-3 col-sm-3"
      style="display: flex; justify-content: end; margin: auto"
    >
      <mat-select
        style="max-width: 150px"
        [(ngModel)]="page_size"
        (selectionChange)="getAttendanceReport()"
      >
        <mat-option class="text-center" value="10"
          >10 {{ "lang_per_page" | language }}
        </mat-option>
        <mat-option class="text-center" value="20"
          >20 {{ "lang_per_page" | language }}
        </mat-option>
        <mat-option class="text-center" value="50"
          >50 {{ "lang_per_page" | language }}
        </mat-option>
        <mat-option class="text-center" value="100"
          >100 {{ "lang_per_page" | language }}</mat-option
        >
        <mat-option class="text-center" value="500"
          >500 {{ "lang_per_page" | language }}</mat-option
        >
      </mat-select>
    </div>
  </div>
</div>
<ng-template #viewContracts>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ "lang_calender_report" | language }}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        &times;
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="row p-3 pb-5">
          <div class="table-responsive dropdownyes customResponsive">
            <table border="1" class="table CustomTable personal">
              <thead>
                <tr>
                  <td>{{ "lang_human_resources_employee_name" | language }}</td>
                  <td>{{ "lang_human_resources_number" | language }}</td>
                </tr>
              </thead>
              <tbody class="AppendList">
                <tr>
                  <td>
                    {{ ename }}
                  </td>
                  <td>
                    {{ enumber }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row pb-5">
          <div class="table-responsive dropdownyes customResponsive">
            <table border="1" class="table CustomTable">
              <thead>
                <tr>
                  <td>#</td>
                  <td>{{ "lang_date" | language }}</td>
                  <td>{{ "lang_status" | language }}</td>
                  <td>{{ "lang_hours" | language }}</td>
                </tr>
              </thead>
              <tbody class="AppendList">
                <tr
                  *ngFor="
                    let data of elveiw;
                    let i = index;
                    let odd = odd;
                    let even = even
                  "
                  [ngClass]="{ odd: odd, even: even }"
                >
                  <td>{{ i + 1 }}</td>
                  <td>
                    {{ data.date }}
                  </td>
                  <td>
                    {{ data.state | titlecase }}
                  </td>
                  <td>
                    {{ data.total_hours_per_day | titlecase }}
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="!elveiw.length" class="AppendList">
                <tr class="odd">
                  <td colspan="4" align="center">
                    {{ "lang_no_data" | language }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>
