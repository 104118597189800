<div class="tickets-transactions">
  <mat-tab-group
    class="tickets-transactions-tabs"
    [(selectedIndex)]="tt_tabindex"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <h3
          class="tickets-transactions-tab-title"
          *ngIf="!transactions?.length"
        >
          {{ "lang_my_transactions" | language }}
        </h3>
        <h3 class="tickets-transactions-tab-title" *ngIf="transactions?.length">
          {{ "lang_my_transactions" | language }} ({{
            transactionCollectionSize
          }})
        </h3>
      </ng-template>
      <div class="p-2">
        <div class="row">
          <div class="col-12" style="min-height: 130px; margin-bottom: 15px">
            <mat-tab-group class="projects-transactions-tabs">
              <mat-tab>
                <ng-template mat-tab-label>
                  <h3
                    class="tickets-transactions-tab-title"
                    *ngIf="!transactions?.length"
                  >
                    {{ "lang_my_transactions" | language }}
                  </h3>
                  <h3
                    class="tickets-transactions-tab-title"
                    *ngIf="transactions?.length"
                  >
                    {{ "lang_my_transactions" | language }} ({{
                      transactionCollectionSize
                    }})
                  </h3>
                </ng-template>
                <div class="main-status">
                  <div class="ms-header">
                    <h3 class="ms-header-title" *ngIf="!transactions?.length">
                      {{ "lang_my_transactions" | language }}
                    </h3>
                    <h3 class="ms-header-title" *ngIf="transactions?.length">
                      {{ "lang_my_transactions" | language }} ({{
                        transactionCollectionSize
                      }})
                    </h3>
                    <div>
                      <mat-slide-toggle
                        *ngIf="admin"
                        class="ml-3 mr-3"
                        (change)="onFilterAdminTransactions($event)"
                        [color]="'primary'"
                        [(ngModel)]="filterAdminToggle"
                        >{{ "lang_admin" | language }}</mat-slide-toggle
                      >
                      <mat-slide-toggle
                        *ngIf="admin"
                        class="ml-3 mr-3"
                        (change)="onFilterstatusTransactions($event)"
                        [color]="'primary'"
                        [(ngModel)]="filterstatusToggle"
                        >{{ "lang_all_complete" | language }}</mat-slide-toggle
                      >
                    </div>
                  </div>
                  <div class="ms-content">
                    <div class="row mb-2">
                      <div
                        [ngClass]="{
                          'col-md-3 col-xs-12 mb-1': admin,
                          'col-md-6 col-xs-12 mb-1': !admin
                        }"
                      >
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control almnabrformcontrol search_key"
                            id="search_key"
                            [(ngModel)]="transactionSearchKey"
                            (keyup)="get_form_lists()"
                            placeholder="{{ 'lang_search' | language }}"
                          />
                        </div>
                      </div>
                      <div class="col-md-3 col-xs-12 mb-1" *ngIf="admin">
                        <button
                          mat-button
                          class="albutton"
                          routerLink="/transactions/nBU2waRF8N"
                        >
                          {{ "lang_approve_transactions" | language }}
                        </button>
                      </div>
                      <div class="col-md-3 col-xs-12 mb-1" *ngIf="admin">
                        <button
                          mat-button
                          class="albutton"
                          routerLink="/transactions/KmRJAQGkZJ"
                        >
                          {{ "lang_cron_transactions" | language }}
                        </button>
                      </div>
                      <div
                        [ngClass]="{
                          'col-md-3 col-xs-12 mb-1': admin,
                          'col-md-6 col-xs-12 mb-1': !admin
                        }"
                      >
                        <button
                          mat-button
                          class="albutton"
                          (click)="showTransactionFilters()"
                        >
                          {{ "lang_advanced_filter" | language }}
                        </button>
                      </div>
                    </div>
                    <div class="msc-list">
                      <div
                        class="row"
                        *ngIf="!transactionLoading && transactions?.length"
                      >
                        <div
                          class="col-12 col-sm-6"
                          *ngFor="let item of transactions"
                        >
                          <div
                            class="mscl-item"
                            routerLink="/transactions/{{ item.url }}"
                          >
                            <div class="mscli-labels mscli-labels-show">
                              <div
                                class="mscli-labels-label"
                                [attr.style]="
                                  item?.transaction_request_status == 'new'
                                    ? 'background-color:#61bd4f;'
                                    : 'background-color:#0079BF;'
                                "
                              >
                                <span style="color: #fff">{{
                                  item?.transaction_request_status
                                }}</span>
                              </div>
                              <div
                                class="mscli-labels-label ml-1 mr-1"
                                *ngIf="item?.barcode && item?.barcode != '---'"
                              >
                                <i class="pi pi-qrcode"></i>
                                <span style="margin: 0 5px">{{
                                  item?.barcode
                                }}</span>
                              </div>
                              <div
                                class="mscli-labels-label"
                                *ngIf="item?.transactions_name"
                              >
                                <i class="pi pi-arrows-h"></i>
                                <span style="margin: 0 5px">{{
                                  item?.transactions_name
                                }}</span>
                              </div>
                            </div>
                            <div
                              class="mscli-header"
                              [ngClass]="{
                                no_labels:
                                  !item?.transaction_request_description
                              }"
                            >
                              <h4 class="mscli-header-title">
                                {{ item?.transaction_request_id }} -
                                {{ item?.transaction_request_description }}
                              </h4>
                              <span
                                class="mscli-header-icon"
                                *ngIf="
                                  admin &&
                                  item?.transaction_request_last_step !=
                                    'completed'
                                "
                                (click)="deleteTransaction($event, item)"
                                matTooltip="{{ 'lang_delete' | language }}"
                              >
                                <i class="fa fa-trash mnabricon"></i>
                              </span>
                              <span
                                class="mscli-header-icon"
                                *ngIf="
                                  admin &&
                                  item?.transaction_request_last_step ==
                                    'completed' &&
                                  item?.transaction_key == 'FORM_HRV1'
                                "
                                (click)="cancelTransaction($event, item)"
                                matTooltip="{{ 'lang_cancel' | language }}"
                              >
                                <i class="fa fa-trash mnabricon"></i>
                              </span>
                            </div>
                            <div class="mscli-content">
                              <div class="task-badge" *ngIf="item?.module_name">
                                <i
                                  class="pi pi-check-square"
                                  style="font-size: 14px"
                                ></i>
                                <span>{{ item?.module_name }}</span>
                              </div>
                              <div
                                class="task-badge"
                                *ngIf="item?.transactions_date_last_update"
                              >
                                <i class="pi pi-clock"></i>
                                <span>{{
                                  item?.transactions_date_last_update
                                }}</span>
                              </div>
                              <div
                                class="task-badge"
                                *ngIf="
                                  item?.transaction_request_user_name_writer
                                "
                              >
                                <i class="pi pi-user"></i>
                                <span>{{
                                  item?.transaction_request_user_name_writer
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="transactionLoading">
                        <div class="col-12 col-sm-6">
                          <ngx-skeleton-loader
                            count="5"
                            [theme]="{ 'border-radius': '3px', height: '70px' }"
                          ></ngx-skeleton-loader>
                        </div>
                        <div class="col-12 col-sm-6">
                          <ngx-skeleton-loader
                            count="5"
                            [theme]="{ 'border-radius': '3px', height: '70px' }"
                          ></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                    <no-data
                      style="margin: auto"
                      [Opened]="!transactions?.length && !transactionLoading"
                    ></no-data>
                  </div>
                  <div class="ms-footer" *ngIf="transactions?.length > 0">
                    <div class="ms-footer-pagination">
                      <ngb-pagination
                        [collectionSize]="transactionCollectionSize"
                        [rotate]="true"
                        [ellipses]="false"
                        [maxSize]="3"
                        [boundaryLinks]="true"
                        [(page)]="+transactionPageno"
                        [pageSize]="+transactionPagesize"
                        (pageChange)="loadPageFrm($event)"
                      ></ngb-pagination>
                      <mat-select
                        style="max-width: 150px"
                        [(ngModel)]="transactionPagesize"
                        (selectionChange)="loadFrmPagesize($event.value)"
                      >
                        <mat-option class="text-center" value="10"
                          >10 {{ "lang_per_page" | language }}
                        </mat-option>
                        <mat-option class="text-center" value="20"
                          >20 {{ "lang_per_page" | language }}
                        </mat-option>
                        <mat-option class="text-center" value="50"
                          >50 {{ "lang_per_page" | language }}
                        </mat-option>
                        <mat-option class="text-center" value="100"
                          >100 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option class="text-center" value="500"
                          >500 {{ "lang_per_page" | language }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <h3 class="tickets-transactions-tab-title">
                    {{ "lang_workarea_transactions" | language }}
                  </h3>
                </ng-template>
                <div class="main-status">
                  <div class="ms-header">
                    <h3 class="ms-header-title">
                      {{ "lang_workarea_transactions" | language }}
                    </h3>
                  </div>
                  <div class="ms-content">
                    <app-user-transactions></app-user-transactions>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <h3
          class="tickets-transactions-tab-title"
          *ngIf="!ticket_list?.data?.length"
        >
          {{ "lang_ticket" | language }} {{ "lang_list" | language }}
        </h3>
        <h3
          class="tickets-transactions-tab-title"
          *ngIf="ticket_list?.data?.length"
        >
          {{ "lang_ticket" | language }} {{ "lang_list" | language }} ({{
            ticket_list?.page?.total_records
          }})
        </h3>
      </ng-template>
      <div class="p-2">
        <div class="row">
          <div class="col-12" style="min-height: 130px; margin-bottom: 15px">
            <div class="main-status">
              <div class="ms-header">
                <h3 class="ms-header-title" *ngIf="!ticket_list?.data?.length">
                  {{ "lang_ticket" | language }} {{ "lang_list" | language }}
                </h3>
                <h3 class="ms-header-title" *ngIf="ticket_list?.data?.length">
                  {{ "lang_ticket" | language }}
                  {{ "lang_list" | language }} ({{
                    ticket_list?.page?.total_records
                  }})
                </h3>
                <mat-slide-toggle
                  *ngIf="admin"
                  class="ml-3 mr-3"
                  (change)="onFilterAdminTickets($event)"
                  [color]="'primary'"
                  >{{ "lang_admin" | language }}</mat-slide-toggle
                >
              </div>
              <div class="ms-content">
                <div class="row mb-2">
                  <div class="col-md-8 col-xs-0"></div>
                  <div class="col-md-4 col-xs-12 mb-1">
                    <button
                      mat-button
                      class="albutton"
                      (click)="showTicketsFilters()"
                    >
                      {{ "lang_advanced_filter" | language }}
                    </button>
                  </div>
                </div>
                <div class="msc-list">
                  <div
                    class="row"
                    *ngIf="!ticketLoading && ticket_list?.data?.length"
                  >
                    <div
                      class="col-12 col-sm-6"
                      *ngFor="let item of ticket_list?.data; let i = index"
                    >
                      <div
                        class="mscl-item"
                        routerLink="/tasks/view/{{ item?.ticket_id }}"
                      >
                        <div class="taskNumTag">
                          <span>{{ item?.ticket_no }}</span>
                        </div>
                        <div
                          class="mscli-labels mscli-labels-show"
                          style="padding: 0 50px"
                        >
                          <div
                            class="mscli-labels-label"
                            [attr.style]="
                              item?.ticket_status_name == 'new'
                                ? 'background-color:#61bd4f'
                                : 'background-color:#0079BF'
                            "
                          >
                            <span style="color: #fff">{{
                              item?.ticket_status_name
                            }}</span>
                          </div>
                        </div>
                        <div
                          class="mscli-header"
                          [ngClass]="{
                            no_labels: !item?.ticket_titel
                          }"
                        >
                          <h4 class="mscli-header-title">
                            {{ item?.ticket_titel }}
                          </h4>
                          <div class="d-flex">
                            <span
                              class="mscli-header-icon mr-1 ml-1"
                              *ngIf="item?.can_edit"
                              (click)="$event?.stopPropagation()"
                              routerLink="/tasks/edit/{{ item?.ticket_id }}"
                              matTooltip="{{ 'lang_edit' | language }}"
                            >
                              <i class="fa fa-pencil mnabricon"></i>
                            </span>
                            <span
                              class="mscli-header-icon"
                              *ngIf="item?.can_delete"
                              (click)="deleteTicket($event, item?.ticket_id)"
                              matTooltip="{{ 'lang_delete' | language }}"
                            >
                              <i class="fa fa-trash mnabricon"></i>
                            </span>
                          </div>
                        </div>
                        <div class="mscli-content">
                          <div class="task-badge" *ngIf="item?.barcode">
                            <i class="pi pi-qrcode"></i>
                            <span>{{ item?.barcode }}</span>
                          </div>
                          <div class="task-badge" *ngIf="item?.ref_model">
                            <i
                              class="pi pi-check-square"
                              style="font-size: 14px"
                            ></i>
                            <span>{{ item?.ref_model }}</span>
                          </div>
                          <div class="task-badge" *ngIf="item?.insert_date">
                            <i class="pi pi-clock"></i>
                            <span>{{ item?.insert_date }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="ticketLoading">
                    <div class="col-12 col-sm-6">
                      <ngx-skeleton-loader
                        count="5"
                        [theme]="{ 'border-radius': '3px', height: '70px' }"
                      ></ngx-skeleton-loader>
                    </div>
                    <div class="col-12 col-sm-6">
                      <ngx-skeleton-loader
                        count="5"
                        [theme]="{ 'border-radius': '3px', height: '70px' }"
                      ></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
                <no-data
                  style="margin: auto"
                  [Opened]="!ticket_list == null && !ticketLoading"
                ></no-data>
              </div>
              <div class="ms-footer" *ngIf="ticket_list?.data?.length > 0">
                <div class="ms-footer-pagination">
                  <ngb-pagination
                    [collectionSize]="ticket_list?.page?.total_records"
                    [rotate]="true"
                    [ellipses]="false"
                    [maxSize]="3"
                    [boundaryLinks]="true"
                    [(page)]="+tickets_page_no"
                    [pageSize]="+tickets_page_size"
                    (pageChange)="load_by_page_no($event)"
                  ></ngb-pagination>
                  <mat-select
                    style="max-width: 150px"
                    [(ngModel)]="tickets_page_size"
                    (selectionChange)="load_by_page_size($event.value)"
                  >
                    <mat-option class="text-center" value="10"
                      >10 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" value="20"
                      >20 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" value="50"
                      >50 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" value="100"
                      >100 {{ "lang_per_page" | language }}</mat-option
                    >
                    <mat-option class="text-center" value="500"
                      >500 {{ "lang_per_page" | language }}</mat-option
                    >
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="row mt-5">
    <div class="col-12">
      <div class="row pb-3">
        <div
          class="col-12 col-sm-3 col-md-2 mb-2 mb-sm-0"
          style="align-self: center"
        >
          <mat-select
            (selectionChange)="changeView()"
            [(ngModel)]="view"
            class="view-select"
          >
            <mat-option value="day">{{ "lang_day" | language }}</mat-option>
            <mat-option value="week">{{ "lang_week" | language }}</mat-option>
            <mat-option value="month">{{ "lang_month" | language }}</mat-option>
            <mat-option value="year" *ngIf="currentView == 'calendar'">{{
              "lang_year" | language
            }}</mat-option>
          </mat-select>
        </div>
        <div
          class="col-12 col-sm-3 col-md-2 mb-2 mb-sm-0"
          style="align-self: center"
        >
          <mat-select
            (selectionChange)="changeType()"
            [(ngModel)]="resource_type"
            class="resource-type-select"
          >
            <mat-option value="status">{{
              "lang_status" | language
            }}</mat-option>
            <mat-option value="members">{{
              "lang_users" | language
            }}</mat-option>
            <mat-option value="gantt" *ngIf="isTimeline">{{
              "lang_gantt" | language
            }}</mat-option>
          </mat-select>
        </div>
        <div
          class="col-12 col-sm-3 col-md-2 mb-2 mb-sm-0"
          style="align-self: center"
        >
          <mat-select [(ngModel)]="myTheme" class="theme-select">
            <mat-option value="ios">{{
              "lang_theme_ios" | language
            }}</mat-option>
            <mat-option value="windows">{{
              "lang_theme_windows" | language
            }}</mat-option>
            <mat-option value="material">{{
              "lang_theme_material" | language
            }}</mat-option>
          </mat-select>
        </div>
        <div class="col-12 col-sm-3">
          <ng-select
            [items]="ticketUsers"
            [multiple]="true"
            appendTo="body"
            bindLabel="emp_name"
            bindValue="emp_id"
            clearAllText="Clear"
            placeholder="{{ 'lang_users' | language }}"
            [(ngModel)]="filter_user"
            (change)="changeUsers()"
            class="calendar_users"
            *ngIf="resource_type == 'members'"
          >
          </ng-select>
        </div>

        <div class="col-12 col-sm-3 text-end">
          <button
            (click)="showFilters()"
            class="btn btn-info albutton filter-btn m-auto"
          >
            <i class="fa fa-filter" style="font-size: 17px; margin: 0 5px"></i>
            {{ "lang_advanced_filter" | language }}
          </button>
        </div>
      </div>
      <div class="md-event-listing mb-3">
        <mbsc-eventcalendar
          [data]="myEvents"
          [connections]="connections"
          class="rtl-calender"
          [options]="calendarOptions"
          [themeVariant]="'light'"
          [theme]="myTheme"
          [view]="calCalendarView"
          [calendarSystem]="lang_key == 'ar' ? hijriCalendar : null"
          [locale]="localeAll[lang_key]"
          [rtl]="lang_key == 'ar' ? true : false"
          [resources]="myResources"
          [headerTemplate]="myHeaderTemplate"
          [resourceHeaderTemplate]="resourceHeaderTemp"
          [dragToMove]="true"
          [dragToResize]="true"
          [(selectedDate)]="currentDate"
          [(selectedEvents)]="mySelectedEvent"
        >
          <ng-template #resourceHeaderTemp>
            <span *ngIf="resource_type == 'status'">{{
              "lang_status" | language
            }}</span>
            <span *ngIf="resource_type == 'members'">{{
              "lang_users" | language
            }}</span>
            <span *ngIf="resource_type == 'gantt'">{{
              "lang_gantt" | language
            }}</span>
          </ng-template>
          <ng-template #myHeaderTemplate>
            <mbsc-calendar-nav class="md-event-listing-nav"></mbsc-calendar-nav>
            <div class="md-event-listing-picker">
              <div class="timeline-calendar-toggle">
                <mat-slide-toggle
                  [color]="'primary'"
                  [(ngModel)]="isTimeline"
                  (change)="changeCalendarTimeline($event)"
                >
                  {{ "lang_timeline" | language }}
                </mat-slide-toggle>
                <mat-slide-toggle
                  *ngIf="admin"
                  class="ml-3 mr-3"
                  (change)="onFilterAdmin($event)"
                  [color]="'primary'"
                  >{{ "lang_admin" | language }}</mat-slide-toggle
                >
              </div>
            </div>
            <div class="md-seach-header-bar mbsc-flex-1-0">
              <mbsc-input
                #searchInput
                (input)="onSearch($event)"
                startIcon="material-search"
                inputStyle="box"
                placeholder="Search events"
              ></mbsc-input>
            </div>
            <mbsc-calendar-prev
              class="md-event-listing-prev"
            ></mbsc-calendar-prev>
            <mbsc-calendar-today
              class="md-event-listing-today"
            ></mbsc-calendar-today>
            <mbsc-calendar-next
              class="md-event-listing-next"
            ></mbsc-calendar-next>
          </ng-template>
          <mbsc-popup
            class="md-tooltip"
            #popup
            [anchor]="anchor"
            [options]="popupOptions"
          >
            <div
              (mouseleave)="mouseLeave()"
              [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
            >
              <div
                class="md-tooltip-header"
                [ngStyle]="{
                  background: '#ddd',
                  color: currentEvent?.type == '2' ? 'black' : '#fff'
                }"
              >
                <span class="md-tooltip-name-age"
                  >{{ currentEvent?.task_no }} - {{ currentEvent?.title }}</span
                >
              </div>
              <div class="md-tooltip-info">
                <div class="md-tooltip-title">
                  {{ "lang_ticket_title" | language }}:
                  <span class="md-tooltip-status md-tooltip-text">{{
                    currentEvent?.ticket_title
                  }}</span>
                </div>
                <hr />
                <div class="md-tooltip-title">
                  {{ "lang_ticket" | language }}
                  {{ "lang_start_date" | language }}:
                  <span class="md-tooltip-reason md-tooltip-text">{{
                    currentEvent?.start_date_ticket
                  }}</span>
                </div>
                <div class="md-tooltip-title">
                  {{ "lang_ticket" | language }}
                  {{ "lang_end_date" | language }}:
                  <span class="md-tooltip-reason md-tooltip-text">{{
                    currentEvent?.end_date_ticket
                  }}</span>
                </div>
                <hr />
                <div class="md-tooltip-title">
                  {{ "lang_task" | language }}
                  {{ "lang_start_date" | language }}:
                  <span class="md-tooltip-reason md-tooltip-text">{{
                    currentEvent?.start_date_task
                  }}</span>
                </div>
                <div class="md-tooltip-title">
                  {{ "lang_task" | language }}
                  {{ "lang_end_date" | language }}:
                  <span class="md-tooltip-reason md-tooltip-text">{{
                    currentEvent?.end_date_task
                  }}</span>
                </div>
                <hr />
                <div class="md-tooltip-title">
                  {{ "lang_task" | language }} {{ "lang_progress" | language }}:
                  <span class="md-tooltip-reason md-tooltip-text">{{
                    currentEvent?.task_progress
                  }}</span>
                </div>
              </div>
            </div>
          </mbsc-popup>
          <mbsc-popup
            [options]="popupOptions"
            class="md-search-popup"
            [anchor]="searchInput"
            [focusElm]="searchInput"
            #popupSearch
            (onInit)="initPopup()"
          >
            <mbsc-eventcalendar
              [data]="listEvents"
              [view]="listView"
              [showControls]="false"
              class="mbsc-popover-list"
              (onEventClick)="eventClick($event)"
            ></mbsc-eventcalendar>
          </mbsc-popup>
        </mbsc-eventcalendar>
      </div>
    </div>
  </div>
</div>
