<fieldset
	class="cardback viewquotation fieldset mb-10 mt-3"
	[ngClass]="{ ' contentrtl': lang_key == 'ar' }"
>
	<legend>{{ "lang_contract" | language }}</legend>
	<div class="row mb-10">
		<div class="contentbodypanel width100 mt-0">
			<div class="col-12 contentpanel">
				<div class="itemsdata">
					<label> {{ "lang_info" | language }} : </label>
					<div class="TableParts">
						<div
							class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
						>
							<table class="table CustomTables text-center">
								<tbody class="AppendList quotationtable">
									<tr>
										<td class="bold">
											{{ "lang_type" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.contract_type }}
										</td>

										<td class="bold">
											{{ "lang_subject" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.subject }}
										</td>

										<td class="bold">
											{{ "lang_from_org" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.transaction_from_name }}
										</td>
									</tr>
									<tr [ngSwitch]="formCt1Data?.main_data?.work_type">
										<td class="bold">
											{{ "lang_work_domain" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.tr_work_domain }}
										</td>

										<td class="bold">
											{{ "lang_work_location" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.tr_work_location }}
										</td>

										<td class="bold">
											{{ "lang_work_type" | language }}
										</td>
										<td *ngSwitchCase="'1'">
											{{ "lang_fulltime" | language }}
										</td>
										<td *ngSwitchCase="'2'">
											{{ "lang_parttime" | language }}
										</td>
										<td *ngSwitchCase="'3'">
											{{ "lang_contract" | language }}
										</td>
										<td *ngSwitchCase="'4'">
											{{ "lang_others" | language }}
										</td>
										<td *ngSwitchDefault>---</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<br />
				</div>

				<div class="itemsdata">
					<label> {{ "lang_work" | language }} : </label>
					<div class="TableParts">
						<div
							class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
						>
							<table class="table CustomTables text-center">
								<tbody class="AppendList quotationtable">
									<tr>
										<td class="bold">
											{{
												"lang_human_resources_joining_date_english" | language
											}}
										</td>
										<td>
											{{ formCt1Data?.main_data?.joining_date_english }}
										</td>
										<td class="bold">
											{{
												"lang_human_resources_joining_date_arabic" | language
											}}
										</td>
										<td>
											{{ formCt1Data?.main_data?.joining_date_arabic }}
										</td>
										<td class="bold">
											{{ "lang_human_resources_propation_period" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.probation_period }}
										</td>
									</tr>
									<tr>
										<td class="bold">
											{{
												"lang_human_resources_contract_start_date" | language
											}}
										</td>
										<td>
											{{ formCt1Data?.main_data?.contract_start_date_english }}
											- {{ formCt1Data?.main_data?.contract_start_date_arabic }}
										</td>
										<td class="bold">
											{{ "lang_human_resources_contract_end_date" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.contract_end_date_english }} -
											{{ formCt1Data?.main_data?.contract_end_date_arabic }}
										</td>
										<td class="bold">
											{{ "lang_human_resources_contract_period" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.contract_period }}
										</td>
									</tr>

									<tr>
										<td class="bold" colspan="2">
											{{
												"lang_human_resources_notification_period" | language
											}}
										</td>
										<td>
											{{ formCt1Data?.main_data?.notification_period }}
										</td>
										<td class="bold" colspan="2">
											{{ "lang_auto_renew" | language }}
										</td>
										<td>
											{{
												formCt1Data?.main_data?.auto_renew == "0"
													? ("lang_no" | language)
													: ("lang_yes" | language)
											}}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<br />
				</div>

				<div class="itemsdata">
					<label> {{ "lang_salary" | language }} : </label>
					<div class="TableParts">
						<div
							class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
						>
							<table class="table CustomTables text-center">
								<tbody class="AppendList quotationtable">
									<tr>
										<td class="bold">
											{{ "lang_hours_work_type" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.working_hours_type }}
										</td>
										<td class="bold">
											{{ "lang_human_resources_working_hours" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.working_hours }}
										</td>
									</tr>
									<tr>
										<td class="bold">
											{{
												"lang_human_resources_working_days_per_week" | language
											}}
										</td>
										<td>
											{{ formCt1Data?.main_data?.working_days_per_week }}
										</td>

										<td class="bold">
											{{ "lang_human_resources_vacation_paid_days" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.vacation_paid_days }}
										</td>
									</tr>
									<tr>
										<td class="bold">
											{{ "lang_ticket_amount" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.ticket_amount }}
										</td>

										<td class="bold">
											{{ "lang_basic_salary" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.basic_salary }}
										</td>
									</tr>
									<tr>
										<td class="bold">
											{{ "lang_home_allowance" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.home_allowance }}
										</td>
										<td class="bold">
											{{ "lang_net_amount" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.net_amount }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<br />
				</div>

				<div class="itemsdata" *ngIf="formCt1Data?.home_allowance?.length > 0">
					<label> {{ "lang_other_allowances" | language }} : </label>
					<div class="row mb-2 mt-2">
						<div class="col-md-12 pad0">
							<div class="table-responsive customResponsive">
								<table border="1" class="table CustomTable">
									<thead>
										<tr>
											<td>#</td>
											<td>{{ "lang_desc_english" | language }}</td>
											<td>{{ "lang_desc_arabic" | language }}</td>
											<td>{{ "lang_invoice_item_amount" | language }}</td>
										</tr>
									</thead>
									<tbody class="AppendList">
										<tr
											*ngFor="
												let item of formCt1Data?.home_allowance;
												let i = index;
												let odd = odd;
												let even = even
											"
										>
											<td>{{ i + 1 }}</td>
											<td>{{ item?.other_allowance_description_en }}</td>
											<td>{{ item?.other_allowance_description_ar }}</td>
											<td>{{ item?.other_allowance_amount }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div class="itemsdata">
					<label>
						{{ "lang_human_resources_bank_details" | language }} :
					</label>
					<div class="TableParts">
						<div
							class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
						>
							<table class="table CustomTables text-center">
								<tbody class="AppendList quotationtable">
									<tr>
										<td class="bold w-50">
											{{ "lang_human_resources_account_number" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.account_number }}
										</td>
									</tr>
									<tr>
										<td class="bold w-50">
											{{ "lang_bank" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.bankname }} ({{
												formCt1Data?.main_data?.bank_short_code
											}})
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<br />
				</div>

				<div
					class="itemsdata"
					*ngIf="formCt1Data?.additional_terms?.length > 0"
				>
					<label> {{ "lang_other_allowances" | language }} : </label>
					<div class="row mb-2 mt-2">
						<div class="col-md-12 pad0">
							<div class="table-responsive customResponsive">
								<table border="1" class="table CustomTable">
									<thead>
										<tr>
											<td>#</td>
											<td>{{ "lang_desc_english" | language }}</td>
											<td>{{ "lang_desc_arabic" | language }}</td>
											<td>{{ "lang_type" | language }}</td>
										</tr>
									</thead>
									<tbody class="AppendList">
										<tr
											*ngFor="
												let item of formCt1Data?.additional_terms;
												let i = index;
												let odd = odd;
												let even = even
											"
										>
											<td>{{ i + 1 }}</td>
											<td>{{ item?.terms_content_english }}</td>
											<td>{{ item?.terms_content_arabic }}</td>
											<td>{{ item?.terms_type }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div class="itemsdata">
					<label> {{ "lang_penalty_clause" | language }} : </label>
					<div class="TableParts">
						<div
							class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
						>
							<table class="table CustomTables text-center">
								<tbody class="AppendList quotationtable">
									<tr>
										<td class="bold w-50">
											{{ "lang_penalty_clause_first_party" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.penalty_clause_first_party }}
										</td>
									</tr>
									<tr>
										<td class="bold w-50">
											{{ "lang_penalty_clause_second_party" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.penalty_clause_second_party }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<br />
				</div>

				<div class="itemsdata">
					<label> {{ "lang_signature_stamp" | language }} : </label>
					<div class="TableParts">
						<div
							class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
						>
							<table class="table CustomTables text-center">
								<tbody class="AppendList quotationtable">
									<tr>
										<td class="bold w-50">
											{{ "lang_first_party" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.first_party_user_name }}
										</td>
									</tr>
									<tr>
										<td class="bold w-50">
											{{ "lang_second_party" | language }}
										</td>
										<td>
											{{ formCt1Data?.main_data?.second_party_user_name }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<br />
				</div>
			</div>
		</div>
	</div>
</fieldset>
