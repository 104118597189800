<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno row">
  <div class="col-12 col-sm-4 mb-2">
    <button
      class="albutton mnabrbutton"
      mat-raised-button
      [routerLink]="['/human_resources/employees']"
    >
      <i class="fa fa-users"></i>
      {{ "lang_human_resources_all_emp" | language }}
    </button>
  </div>

  <div class="col-12 col-sm-4 mb-2">
    <button
      class="albutton mnabrbutton"
      mat-raised-button
      (click)="toggleFilter()"
    >
      <i class="fa fa-filter"></i>
      {{ "lang_advanced_filter" | language }}
    </button>
  </div>

  <div class="col-12 mb-4 reports">
    <div class="card">
      <div class="card-header">{{ "lang_employees_reports" | language }}</div>
      <div class="card-body p-0">
        <div class="mbrl15 martb0">
          <mat-tab-group>
            <mat-tab>
              <ng-template mat-tab-label>{{
                "lang_employees_no_contract" | language
              }}</ng-template>
              <div class="p-2">
                <div class="row">
                  <div class="col-12 pt-3">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <th style="width: 5%">#</th>
                            <th style="width: 30%">
                              {{ "lang_employee_name" | language }}
                            </th>
                            <th style="width: 30%">
                              {{ "lang_user_id" | language }}
                            </th>
                            <th style="width: 30%">
                              {{ "lang_employee_number" | language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of ENC_report_data;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                          >
                            <td style="width: 5%">{{ i + 1 }}</td>
                            <td style="width: 30%">
                              {{ item?.employee_name }}
                            </td>
                            <td style="width: 30%">
                              {{ item?.user_id }}
                            </td>
                            <td style="width: 30%">
                              {{ item?.employee_number }}
                            </td>
                          </tr>
                          <tr *ngIf="!ENC_report_data?.length">
                            <td colspan="4">{{ "lang_no_data" | language }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      *ngIf="ENC_report_data?.length > 0"
                      class="mt-2 special-pagination"
                    >
                      <p>
                        {{ "lang_total" | language }}:
                        {{ ENC_total_records }}
                      </p>
                      <ngb-pagination
                        [collectionSize]="ENC_total_records"
                        [rotate]="true"
                        [maxSize]="3"
                        [boundaryLinks]="true"
                        [(page)]="ENC_page_no"
                        [pageSize]="ENC_page_size"
                        (pageChange)="getENCemployeesReport()"
                      ></ngb-pagination>
                      <mat-select
                        class="customselect"
                        [(ngModel)]="ENC_page_size"
                        (selectionChange)="getENCemployeesReport()"
                        style="width: 200px"
                      >
                        <mat-option [value]="5"
                          >5 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="10"
                          >10 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="20"
                          >20 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="30"
                          >30 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="40"
                          >40 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="50"
                          >50 {{ "lang_per_page" | language }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>{{
                "lang_employees_have_contract" | language
              }}</ng-template>
              <div class="p-2">
                <div class="row">
                  <div class="col-12 pt-3">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <th style="width: 5%">#</th>
                            <th style="width: 30%">
                              {{ "lang_employee_name" | language }}
                            </th>
                            <th style="width: 30%">
                              {{ "lang_user_id" | language }}
                            </th>
                            <th style="width: 30%">
                              {{ "lang_employee_number" | language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of EHC_report_data;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                          >
                            <td style="width: 5%">{{ i + 1 }}</td>
                            <td style="width: 30%">
                              {{ item?.employee_name }}
                            </td>
                            <td style="width: 30%">
                              {{ item?.user_id }}
                            </td>
                            <td style="width: 30%">
                              {{ item?.employee_number }}
                            </td>
                          </tr>
                          <tr *ngIf="!EHC_report_data?.length">
                            <td colspan="4">{{ "lang_no_data" | language }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      *ngIf="EHC_report_data?.length > 0"
                      class="mt-2 special-pagination"
                    >
                      <p>
                        {{ "lang_total" | language }}:
                        {{ EHC_total_records }}
                      </p>
                      <ngb-pagination
                        [collectionSize]="EHC_total_records"
                        [rotate]="true"
                        [maxSize]="3"
                        [boundaryLinks]="true"
                        [(page)]="EHC_page_no"
                        [pageSize]="EHC_page_size"
                        (pageChange)="getEHCemployeesReport()"
                      ></ngb-pagination>
                      <mat-select
                        class="customselect"
                        [(ngModel)]="EHC_page_size"
                        (selectionChange)="getEHCemployeesReport()"
                        style="width: 200px"
                      >
                        <mat-option [value]="5"
                          >5 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="10"
                          >10 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="20"
                          >20 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="30"
                          >30 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="40"
                          >40 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="50"
                          >50 {{ "lang_per_page" | language }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>{{
                "lang_employees_no_joining_after_vacation" | language
              }}</ng-template>
              <div class="p-2">
                <div class="row">
                  <div class="col-12 pt-3">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <th style="width: 5%">#</th>
                            <th style="width: 30%">
                              {{ "lang_employee_name" | language }}
                            </th>
                            <th style="width: 30%">
                              {{ "lang_user_id" | language }}
                            </th>
                            <th style="width: 30%">
                              {{ "lang_employee_number" | language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of ENJ_report_data;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                          >
                            <td style="width: 5%">{{ i + 1 }}</td>
                            <td style="width: 30%">
                              {{ item?.employee_name }}
                            </td>
                            <td style="width: 30%">
                              {{ item?.user_id }}
                            </td>
                            <td style="width: 30%">
                              {{ item?.employee_number }}
                            </td>
                          </tr>
                          <tr *ngIf="!ENJ_report_data?.length">
                            <td colspan="4">{{ "lang_no_data" | language }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      *ngIf="ENJ_report_data?.length > 0"
                      class="mt-2 special-pagination"
                    >
                      <p>
                        {{ "lang_total" | language }}:
                        {{ ENJ_total_records }}
                      </p>
                      <ngb-pagination
                        [collectionSize]="ENJ_total_records"
                        [rotate]="true"
                        [maxSize]="3"
                        [boundaryLinks]="true"
                        [(page)]="ENJ_page_no"
                        [pageSize]="ENJ_page_size"
                        (pageChange)="getENJemployeesReport()"
                      ></ngb-pagination>
                      <mat-select
                        class="customselect"
                        [(ngModel)]="ENJ_page_size"
                        (selectionChange)="getENJemployeesReport()"
                        style="width: 200px"
                      >
                        <mat-option [value]="5"
                          >5 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="10"
                          >10 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="20"
                          >20 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="30"
                          >30 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="40"
                          >40 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="50"
                          >50 {{ "lang_per_page" | language }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>{{
                "lang_employees_on_vacation" | language
              }}</ng-template>
              <div class="p-2">
                <div class="row pt-md-2">
                  <div class="col-md-4 pl-md-3 pt-3">
                    <form class="form" [formGroup]="form">
                      <div class="form-group">
                        <label
                          >{{ "lang_start_date" | language }} :<span class="red"
                            >*</span
                          ></label
                        >
                        <div class="input-group input-group-sm">
                          <input
                            [matDatepicker]="start_date"
                            formControlName="start_date"
                            class="form-control almnabrformcontrol"
                            placeholder="YYYY/MM/DD"
                            (dateChange)="getEOVemployeesReport()"
                            readonly
                          />
                          <span class="input-group-btn">
                            <mat-datepicker-toggle
                              class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                              matSuffix
                              [for]="start_date"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #start_date></mat-datepicker>
                          </span>
                          <span class="input-group-btn">
                            <button
                              (click)="
                                form.get('start_date').setValue('');
                                getEOVemployeesReport()
                              "
                              matTooltip="{{ 'lang_empty' | language }}"
                              type="button"
                              class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
                            ></button>
                          </span>
                        </div>
                        <mat-error
                          *ngIf="
                            form.controls['start_date'].touched &&
                            form.controls['start_date'].errors?.required
                          "
                          >{{ "lang_field_required" | language }}</mat-error
                        >
                      </div>
                    </form>
                  </div>
                  <div class="col-md-4 pl-md-4 pt-3">
                    <form class="form" [formGroup]="form">
                      <div class="form-group">
                        <label
                          >{{ "lang_end_date" | language }} :<span class="red"
                            >*</span
                          ></label
                        >
                        <div class="input-group input-group-sm">
                          <input
                            [matDatepicker]="end_date"
                            formControlName="end_date"
                            class="form-control almnabrformcontrol"
                            placeholder="YYYY/MM/DD"
                            (dateChange)="getEOVemployeesReport()"
                            readonly
                          />
                          <span class="input-group-btn">
                            <mat-datepicker-toggle
                              class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                              matSuffix
                              [for]="end_date"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #end_date></mat-datepicker>
                          </span>
                          <span class="input-group-btn">
                            <button
                              (click)="
                                form.get('end_date').setValue('');
                                getEOVemployeesReport()
                              "
                              matTooltip="{{ 'lang_empty' | language }}"
                              type="button"
                              class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
                            ></button>
                          </span>
                        </div>
                        <mat-error
                          *ngIf="
                            form.controls['end_date'].touched &&
                            form.controls['end_date'].errors?.required
                          "
                          >{{ "lang_field_required" | language }}</mat-error
                        >
                      </div>
                    </form>
                  </div>
                  <div class="col-12 pt-4">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <th style="width: 5%">#</th>
                            <th style="width: 30%">
                              {{ "lang_employee_name" | language }}
                            </th>
                            <th style="width: 20%">
                              {{
                                "lang_vacation_start_date_english" | language
                              }}
                            </th>
                            <th style="width: 20%">
                              {{ "lang_vacation_end_date_english" | language }}
                            </th>
                            <th style="width: 20%">
                              {{ "lang_vacation_id" | language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of EOV_report_data;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                          >
                            <td style="width: 5%">
                              {{ item?.employee_number }}
                            </td>
                            <td style="width: 30%">
                              {{ item?.employee_name }}
                            </td>
                            <td style="width: 20%">
                              {{ item?.vacation_start_date_english }}
                            </td>
                            <td style="width: 20%">
                              {{ item?.vacation_end_date_english }}
                            </td>
                            <td style="width: 20%">
                              {{ item?.vacation_id }}
                            </td>
                          </tr>
                          <tr *ngIf="!EOV_report_data?.length">
                            <td colspan="5">{{ "lang_no_data" | language }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      *ngIf="EOV_report_data?.length > 0"
                      class="mt-2 special-pagination"
                    >
                      <p>
                        {{ "lang_total" | language }}:
                        {{ EOV_total_records }}
                      </p>
                      <ngb-pagination
                        [collectionSize]="EOV_total_records"
                        [rotate]="true"
                        [maxSize]="3"
                        [boundaryLinks]="true"
                        [(page)]="EOV_page_no"
                        [pageSize]="EOV_page_size"
                        (pageChange)="getEOVemployeesReport()"
                      ></ngb-pagination>
                      <mat-select
                        class="customselect"
                        [(ngModel)]="EOV_page_size"
                        (selectionChange)="getEOVemployeesReport()"
                        style="width: 200px"
                      >
                        <mat-option [value]="5"
                          >5 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="10"
                          >10 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="20"
                          >20 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="30"
                          >30 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="40"
                          >40 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="50"
                          >50 {{ "lang_per_page" | language }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 mb-4 reports">
    <div class="card">
      <div class="card-header">{{ "lang_reports" | language }}</div>
      <div class="card-body p-0">
        <div class="mbrl15 martb0">
          <mat-tab-group>
            <mat-tab>
              <ng-template mat-tab-label>{{
                "lang_report_expiry" | language
              }}</ng-template>
              <div class="p-2">
                <div class="row">
                  <div class="col-12 col-sm-3 pt-2 pb-2">
                    <ng-select
                      [items]="RE_report_type_list"
                      [multiple]="false"
                      bindLabel="label"
                      bindValue="value"
                      [clearable]="false"
                      appendTo="body"
                      [(ngModel)]="RE_report_type"
                      placeholder="{{ 'lang_report_type' | language }}"
                      (change)="getREReport()"
                    >
                    </ng-select>
                  </div>
                  <div class="div col-12 col-sm-3 pt-2 pb-2">
                    <div style="width: 100px; position: relative">
                      <input
                        type="text"
                        class="form-control mb-1"
                        id="RE_filter_type"
                        name="RE_filter_type"
                        placeholder="Limit"
                        (keypress)="ds?.isNumber($event)"
                        [(ngModel)]="RE_filter_type"
                      />
                      <i
                        class="fa fa-search mnabricon"
                        (click)="getREReport()"
                        [attr.style]="
                          lang_key == 'ar'
                            ? 'position: absolute; left: 7px;right:unset; top: 9px; font-size: 18px'
                            : 'position: absolute; right: 7px;left:unset; top: 9px; font-size: 18px'
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <th style="width: 5%">#</th>
                            <th>
                              {{ "lang_human_resources_number" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_human_resources_nameen" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_human_resources_namear" | language }}
                            </th>
                            <th
                              *ngIf="
                                RE_report_type ==
                                'membership_expiry_date_english'
                              "
                              style="width: 19%"
                            >
                              {{
                                "lang_membership_expiry_date_english" | language
                              }}
                            </th>
                            <th
                              style="width: 19%"
                              *ngIf="
                                RE_report_type == 'passport_expiry_date_english'
                              "
                            >
                              {{
                                "lang_human_resources_passport_expiry_date_english"
                                  | language
                              }}
                            </th>
                            <th
                              style="width: 19%"
                              *ngIf="
                                RE_report_type == 'iqama_expiry_date_english'
                              "
                            >
                              {{ "lang_iqama_expiry_date_english" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_days" | language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of RE_report_data;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                          >
                            <td style="width: 5%">{{ i + 1 }}</td>
                            <td style="width: 19%">
                              {{ item?.employee_number }}
                            </td>
                            <td style="width: 19%">{{ item?.name_en }}</td>
                            <td style="width: 19%">{{ item?.name_ar }}</td>
                            <td
                              style="width: 19%"
                              *ngIf="
                                RE_report_type ==
                                'membership_expiry_date_english'
                              "
                            >
                              {{ item?.membership_expiry_date_english }}
                            </td>
                            <td
                              style="width: 19%"
                              *ngIf="
                                RE_report_type == 'passport_expiry_date_english'
                              "
                            >
                              {{ item?.passport_expiry_date_english }}
                            </td>
                            <td
                              style="width: 19%"
                              *ngIf="
                                RE_report_type == 'iqama_expiry_date_english'
                              "
                            >
                              {{ item?.iqama_expiry_date_english }}
                            </td>
                            <td style="width: 19%">
                              {{ item?.days }}
                            </td>
                          </tr>
                          <tr *ngIf="!RE_report_data?.length">
                            <td colspan="6">{{ "lang_no_data" | language }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      *ngIf="RE_report_data?.length > 0"
                      class="mt-2 special-pagination"
                    >
                      <p>
                        {{ "lang_total" | language }}:
                        {{ RE_total_records }}
                      </p>
                      <ngb-pagination
                        [collectionSize]="RE_total_records"
                        [rotate]="true"
                        [maxSize]="3"
                        [boundaryLinks]="true"
                        [(page)]="RE_page_no"
                        [pageSize]="RE_page_size"
                        (pageChange)="getREReport()"
                      ></ngb-pagination>
                      <mat-select
                        class="customselect"
                        [(ngModel)]="RE_page_size"
                        (selectionChange)="getREReport()"
                        style="width: 200px"
                      >
                        <mat-option [value]="5"
                          >5 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="10"
                          >10 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="20"
                          >20 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="30"
                          >30 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="40"
                          >40 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="50"
                          >50 {{ "lang_per_page" | language }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>{{
                "lang_report_expiry_contract" | language
              }}</ng-template>
              <div class="p-2">
                <div class="row">
                  <div class="div col-12 col-sm-3 pt-2 pb-2">
                    <div style="width: 100px; position: relative">
                      <input
                        type="text"
                        class="form-control mb-1"
                        id="REC_filter_type"
                        name="REC_filter_type"
                        placeholder="Limit"
                        (keypress)="ds?.isNumber($event)"
                        [(ngModel)]="REC_filter_type"
                      />
                      <i
                        class="fa fa-search mnabricon"
                        (click)="getRECReport()"
                        [attr.style]="
                          lang_key == 'ar'
                            ? 'position: absolute; left: 7px;right:unset; top: 9px; font-size: 18px'
                            : 'position: absolute; right: 7px;left:unset; top: 9px; font-size: 18px'
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <th style="width: 5%">#</th>
                            <th style="width: 19%">
                              {{ "lang_human_resources_number" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_human_resources_nameen" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_human_resources_namear" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_contract_actual_end_date" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_end_date" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_days" | language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of REC_report_data;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                          >
                            <td style="width: 5%">{{ i + 1 }}</td>
                            <td style="width: 19%">
                              {{ item?.employee_number }}
                            </td>
                            <td style="width: 19%">{{ item?.name_en }}</td>
                            <td style="width: 19%">{{ item?.name_ar }}</td>
                            <td style="width: 19%">
                              {{ item?.contract_actual_end_date }}
                            </td>
                            <td style="width: 19%">
                              {{ item?.contract_end_date_english }}
                            </td>
                            <td style="width: 19%">
                              {{ item?.days }}
                            </td>
                          </tr>
                          <tr *ngIf="!REC_report_data?.length">
                            <td colspan="7">{{ "lang_no_data" | language }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      *ngIf="REC_report_data?.length > 0"
                      class="mt-2 special-pagination"
                    >
                      <p>
                        {{ "lang_total" | language }}:
                        {{ REC_total_records }}
                      </p>
                      <ngb-pagination
                        [collectionSize]="REC_total_records"
                        [rotate]="true"
                        [maxSize]="3"
                        [boundaryLinks]="true"
                        [(page)]="REC_page_no"
                        [pageSize]="REC_page_size"
                        (pageChange)="getRECReport()"
                      ></ngb-pagination>
                      <mat-select
                        class="customselect"
                        [(ngModel)]="REC_page_size"
                        (selectionChange)="getRECReport()"
                        style="width: 200px"
                      >
                        <mat-option [value]="5"
                          >5 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="10"
                          >10 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="20"
                          >20 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="30"
                          >30 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="40"
                          >40 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="50"
                          >50 {{ "lang_per_page" | language }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>{{
                "lang_report_expiry_vacation" | language
              }}</ng-template>
              <div class="p-2">
                <div class="row">
                  <div class="div col-12 col-sm-3 pt-2 pb-2">
                    <div style="width: 100px; position: relative">
                      <input
                        type="text"
                        class="form-control mb-1"
                        id="REV_filter_type"
                        name="REV_filter_type"
                        placeholder="Limit"
                        (keypress)="ds?.isNumber($event)"
                        [(ngModel)]="REV_filter_type"
                      />
                      <i
                        class="fa fa-search mnabricon"
                        (click)="getREVReport()"
                        [attr.style]="
                          lang_key == 'ar'
                            ? 'position: absolute; left: 7px;right:unset; top: 9px; font-size: 18px'
                            : 'position: absolute; right: 7px;left:unset; top: 9px; font-size: 18px'
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <th style="width: 5%">#</th>
                            <th style="width: 19%">
                              {{ "lang_human_resources_number" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_human_resources_nameen" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_human_resources_namear" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_vacation_end_date_english" | language }}
                            </th>
                            <th style="width: 19%">
                              {{ "lang_days" | language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of REV_report_data;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                          >
                            <td style="width: 5%">{{ i + 1 }}</td>
                            <td style="width: 19%">
                              {{ item?.employee_number }}
                            </td>
                            <td style="width: 19%">{{ item?.name_en }}</td>
                            <td style="width: 19%">{{ item?.name_ar }}</td>
                            <td style="width: 19%">
                              {{ item?.vacation_end_date_english }}
                            </td>
                            <td style="width: 19%">
                              {{ item?.days }}
                            </td>
                          </tr>
                          <tr *ngIf="!REV_report_data?.length">
                            <td colspan="6">{{ "lang_no_data" | language }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      *ngIf="REV_report_data?.length > 0"
                      class="mt-2 special-pagination"
                    >
                      <p>
                        {{ "lang_total" | language }}:
                        {{ REV_total_records }}
                      </p>
                      <ngb-pagination
                        [collectionSize]="REV_total_records"
                        [rotate]="true"
                        [maxSize]="3"
                        [boundaryLinks]="true"
                        [(page)]="REV_page_no"
                        [pageSize]="REV_page_size"
                        (pageChange)="getREVReport()"
                      ></ngb-pagination>
                      <mat-select
                        class="customselect"
                        [(ngModel)]="REV_page_size"
                        (selectionChange)="getREVReport()"
                        style="width: 200px"
                      >
                        <mat-option [value]="5"
                          >5 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="10"
                          >10 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="20"
                          >20 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="30"
                          >30 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="40"
                          >40 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="50"
                          >50 {{ "lang_per_page" | language }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>{{
                "lang_extend_period_report" | language
              }}</ng-template>
              <div class="p-2">
                <div class="row">
                  <div class="div col-12 col-sm-3 pt-2 pb-2">
                    <div style="width: 100px; position: relative">
                      <input
                        type="text"
                        class="form-control mb-1"
                        id="EPP_filter_type"
                        name="EPP_filter_type"
                        placeholder="Limit"
                        (keypress)="ds?.isNumber($event)"
                        [(ngModel)]="EPP_filter_type"
                      />
                      <i
                        class="fa fa-search mnabricon"
                        (click)="getEPPReport()"
                        [attr.style]="
                          lang_key == 'ar'
                            ? 'position: absolute; left: 7px;right:unset; top: 9px; font-size: 18px'
                            : 'position: absolute; right: 7px;left:unset; top: 9px; font-size: 18px'
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table border="1" class="table CustomTable">
                        <thead>
                          <tr>
                            <th width="1%">#</th>
                            <th>
                              {{ "lang_human_resources_number" | language }}
                            </th>
                            <th>
                              {{ "lang_human_resources_nameen" | language }}
                            </th>
                            <th>
                              {{ "lang_human_resources_namear" | language }}
                            </th>
                            <th>
                              {{
                                "lang_extra_probation_end_date_english"
                                  | language
                              }}
                            </th>
                            <th>
                              {{
                                "lang_probation_expiry_date_english" | language
                              }}
                            </th>
                            <th>
                              {{ "lang_days" | language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of EPP_report_data;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                          >
                            <td>{{ i + 1 }}</td>
                            <td style="width: 19%">
                              {{ item?.employee_number }}
                            </td>
                            <td style="width: 19%">{{ item?.name_en }}</td>
                            <td style="width: 19%">{{ item?.name_ar }}</td>
                            <td style="width: 19%">
                              {{ item?.extra_probation_end_date_english }}
                            </td>
                            <td style="width: 19%">
                              {{ item?.probation_expiry_date_english }}
                            </td>
                            <td style="width: 19%">
                              {{ item?.days }}
                            </td>
                          </tr>
                          <tr
                            style="width: 19%"
                            *ngIf="!EPP_report_data?.length"
                          >
                            <td colspan="7">{{ "lang_no_data" | language }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      *ngIf="EPP_report_data?.length > 0"
                      class="mt-2 special-pagination"
                    >
                      <p>
                        {{ "lang_total" | language }}:
                        {{ EPP_total_records }}
                      </p>
                      <ngb-pagination
                        [collectionSize]="EPP_total_records"
                        [rotate]="true"
                        [maxSize]="3"
                        [boundaryLinks]="true"
                        [(page)]="EPP_page_no"
                        [pageSize]="EPP_page_size"
                        (pageChange)="getEPPReport()"
                      ></ngb-pagination>
                      <mat-select
                        class="customselect"
                        [(ngModel)]="EPP_page_size"
                        (selectionChange)="getEPPReport()"
                        style="width: 200px"
                      >
                        <mat-option [value]="5"
                          >5 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="10"
                          >10 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="20"
                          >20 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="30"
                          >30 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="40"
                          >40 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option [value]="50"
                          >50 {{ "lang_per_page" | language }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>

  <div
    [ngClass]="{
      'col-12 col-sm-8': showFilter && !isMob,
      'col-12': !showFilter || !showSort
    }"
  >
    <mat-accordion class="all_employees">
      <div class="card appattachments">
        <div class="card-header">{{ "lang_human_resources" | language }}</div>
        <div class="card-body p-0">
          <div class="mbrl15 martb0">
            <div class="row justify-content-center">
              <div class="col-12 col-sm-12 mb-5 row justify-content-start">
                <ng-container *ngIf="npContractReports">
                  <div class="row text-center col-md-12 mb-3">
                    <h5 class="m-auto">
                      {{ "lang_no_data" | language }}
                    </h5>
                  </div>
                </ng-container>
                <div class="col-md-6 flex-column mb-2 mt-3">
                  <h5
                    class="card-title title_card"
                    style="font-size: 17px; font-weight: 600"
                  >
                    <i class="fa fa-users customIcon"></i>
                    {{ "lang_invoice_total_amount" | language }}
                    {{ "lang_human_resources_all_emp" | language }}
                  </h5>
                  <h5
                    *ngIf="!count_data_loading"
                    style="font-size: 20px; font-weight: 500"
                  >
                    {{ general_counts?.workers_count }}
                  </h5>

                  <ngx-skeleton-loader
                    class="mx-2"
                    *ngIf="count_data_loading"
                    count="1"
                    appearance="circle"
                    [theme]="{
                      width: '100%',
                      height: '30px',
                      'border-radius': '10px'
                    }"
                  ></ngx-skeleton-loader>
                </div>

                <div class="col-md-6 flex-column mb-2 mt-3">
                  <h5
                    class="card-title title_card"
                    style="font-size: 17px; font-weight: 600"
                  >
                    <i class="fa fa-plus customIcon"></i>
                    {{ "lang_age" | language }} {{ "lang_average" | language }}
                  </h5>
                  <h5
                    *ngIf="!count_data_loading"
                    style="font-size: 20px; font-weight: 500"
                  >
                    {{ general_counts?.age_average }}
                  </h5>

                  <ngx-skeleton-loader
                    class="mx-2"
                    *ngIf="count_data_loading"
                    count="1"
                    appearance="circle"
                    [theme]="{
                      width: '100%',
                      height: '30px',
                      'border-radius': '10px'
                    }"
                  ></ngx-skeleton-loader>
                </div>
                <div class="col-md-6 mb-2">
                  <div class="row">
                    <div class="col-md-6">
                      <p>
                        <img
                          class="customIcon"
                          width="20px"
                          [src]="getIcon('SA')"
                          alt="SA"
                        />
                        {{ "lang_saudi" }} |
                        <span class="text-success">
                          {{ general_counts?.saudies_count }}
                        </span>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <p>
                        <i
                          class="fa fa-globe customIcon"
                          aria-hidden="true"
                        ></i>
                        {{ "lang_nonsaudi" }} |
                        <span class="text-secondary">
                          {{ general_counts?.non_saudies_count }}
                        </span>
                      </p>
                    </div>
                    <div class="col-md-12">
                      <p>
                        <ngb-progressbar
                          type="success"
                          max="{{ general_counts?.workers_count }}"
                          *ngIf="!count_data_loading"
                          value="{{ general_counts?.saudies_count }}"
                        ></ngb-progressbar>
                      </p>
                      <ngx-skeleton-loader
                        style="width: 100%"
                        *ngIf="count_data_loading"
                        count="1"
                        appearance="circle"
                        [theme]="{
                          width: '100%',
                          height: '30px',
                          'border-radius': '10px'
                        }"
                      ></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-2">
                  <div class="row">
                    <div class="col-md-6">
                      <p>
                        <i class="fa fa-male customIcon" aria-hidden="true"></i>
                        {{ "lang_invoice_total_amount" | language }}
                        {{ "lang_men" }} |
                        <span class="text-info">
                          {{ general_counts?.males_count }}
                        </span>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <p>
                        <i
                          class="fa fa-female customIcon"
                          aria-hidden="true"
                        ></i>
                        {{ "lang_invoice_total_amount" | language }}
                        {{ "lang_womans" }} |
                        <span class="text-secondary">
                          {{ general_counts?.females_count }}
                        </span>
                      </p>
                    </div>
                    <div class="col-md-12">
                      <p>
                        <ngb-progressbar
                          type="info"
                          max="{{ general_counts?.workers_count }}"
                          *ngIf="!count_data_loading"
                          value="{{ general_counts?.males_count }}"
                        ></ngb-progressbar>
                      </p>
                      <ngx-skeleton-loader
                        style="width: 100%"
                        *ngIf="count_data_loading"
                        count="1"
                        appearance="circle"
                        [theme]="{
                          width: '100%',
                          height: '30px',
                          'border-radius': '10px'
                        }"
                      ></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-6"
                [ngClass]="{ 'col-md-12': showFilter && !isMob }"
              >
                <h5
                  class="card-title title_card"
                  style="font-size: 17px; font-weight: 600"
                >
                  {{ "lang_age" | language }}
                </h5>

                <div
                  echarts
                  [options]="agesOptions"
                  (chartClick)="onChartClick($event)"
                  class="forms-chart"
                ></div>
              </div>
              <div
                class="col-md-6"
                [ngClass]="{ 'col-md-12': showFilter && !isMob }"
              >
                <h5
                  class="card-title title_card"
                  style="font-size: 17px; font-weight: 600"
                >
                  {{ "lang_vacations" | language }}
                  {{ "lang_notes" | language }} {{ "lang_joinings" | language }}
                </h5>
                <div echarts [options]="othersOtions" class="forms-chart"></div>
              </div>

              <ng-container *ngIf="position_names.length > 0">
                <div class="col-md-12">
                  <h5
                    class="card-title title_card"
                    style="font-size: 17px; font-weight: 600"
                  >
                    {{ "lang_position" | language }}
                  </h5>
                </div>

                <div class="col-0 col-sm-9"></div>
                <div class="col-12 col-sm-3 mb-1">
                  <div
                    style="
                      display: flex;
                      justify-content: end;
                      margin-bottom: 10px;
                    "
                  >
                    <div class="table-arrows">
                      <i
                        class="fa"
                        [ngClass]="{
                          'fa-arrow-right': lang_key == 'ar',
                          'fa-arrow-left': lang_key != 'ar'
                        }"
                        (click)="
                          lang_key == 'ar' ? scrollRight2() : scrollLeft2()
                        "
                      ></i>
                      <i
                        class="fa"
                        [ngClass]="{
                          'fa-arrow-left': lang_key == 'ar',
                          'fa-arrow-right': lang_key != 'ar'
                        }"
                        (click)="
                          lang_key == 'ar' ? scrollLeft2() : scrollRight2()
                        "
                      ></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 mb-3">
                  <div class="table-container" id="table-container">
                    <table class="table main-table table-fixed">
                      <thead>
                        <tr>
                          <th
                            style="
                              color: #035a99;
                              width: 27px;
                              border-left: 1px solid rgba(148, 148, 148, 0.747);
                              width: min-content;
                            "
                            class="sticky"
                          >
                            {{ "lang_position" | language }}
                          </th>
                          <th
                            colspan="2"
                            style="color: #035a99; width: min-content"
                          >
                            {{ "lang_saudi" | language }}
                          </th>
                          <th
                            colspan="2"
                            style="color: #035a99; width: min-content"
                          >
                            {{ "lang_non_saudi" | language }}
                          </th>
                          <th
                            colspan="2"
                            style="color: #035a99; width: min-content"
                          >
                            {{ "lang_experience" | language }}
                          </th>
                          <th
                            *ngFor="let item of jopsOptionsHead"
                            colspan="2"
                            style="color: #035a99; width: min-content"
                          >
                            {{ item }}
                          </th>
                        </tr>
                      </thead>
                      <tbody *ngIf="position_names.length != 0">
                        <tr *ngFor="let list of position_names; let i = index">
                          <td class="sticky" style="padding: 5px">
                            {{ list }}
                          </td>

                          <td
                            class=""
                            style="
                              border-right: 1px solid rgba(148, 148, 148, 0.747);
                              padding: 5px;
                              width: min-content;
                            "
                            colspan="2"
                          >
                            {{ saudi_arr[i] }}
                          </td>
                          <td
                            class=""
                            style="
                              border-right: 1px solid rgba(148, 148, 148, 0.747);
                              padding: 5px;
                              width: min-content;
                            "
                            colspan="2"
                          >
                            {{ non_saudi_arr[i] }}
                          </td>
                          <td
                            class=""
                            style="
                              border-right: 1px solid rgba(148, 148, 148, 0.747);
                              padding: 5px;
                              width: min-content;
                            "
                            colspan="2"
                          >
                            {{ experiance_arr[i] | number: "1.0-0" }}
                          </td>
                          <td
                            class=""
                            *ngFor="let el of jopsOptionsHead; let ii = index"
                            style="
                              border-right: 1px solid rgba(148, 148, 148, 0.747);
                              padding: 5px;
                              width: min-content;
                            "
                            colspan="2"
                          >
                            {{ orignalStack2[el][i] }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="jopsOptionsHeadStack3.length > 0">
                <div class="col-0 col-sm-9"></div>
                <div class="col-12 col-sm-3 mb-1">
                  <div
                    style="
                      display: flex;
                      justify-content: end;
                      margin-bottom: 10px;
                    "
                  >
                    <div class="table-arrows">
                      <i
                        class="fa"
                        [ngClass]="{
                          'fa-arrow-right': lang_key == 'ar',
                          'fa-arrow-left': lang_key != 'ar'
                        }"
                        (click)="
                          lang_key == 'ar' ? scrollRight() : scrollLeft()
                        "
                      ></i>
                      <i
                        class="fa"
                        [ngClass]="{
                          'fa-arrow-left': lang_key == 'ar',
                          'fa-arrow-right': lang_key != 'ar'
                        }"
                        (click)="
                          lang_key == 'ar' ? scrollLeft() : scrollRight()
                        "
                      ></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="table-container" id="table-container-3">
                    <table class="table main-table table-fixed">
                      <thead>
                        <tr>
                          <th
                            style="
                              color: #035a99;
                              width: 27px;
                              border-left: 1px solid rgba(148, 148, 148, 0.747);
                            "
                            class="sticky"
                          >
                            {{ "lang_position" | language }}
                          </th>
                          <th
                            *ngFor="let item of jopsOptionsHeadStack3"
                            colspan="2"
                            style="color: #035a99"
                          >
                            {{ item }}
                          </th>
                        </tr>
                      </thead>
                      <tbody *ngIf="position_names.length != 0">
                        <tr *ngFor="let list of position_names; let i = index">
                          <td class="sticky" style="padding: 5px">
                            {{ list }}
                          </td>

                          <td
                            class=""
                            *ngFor="
                              let el of jopsOptionsHeadStack3;
                              let ii = index
                            "
                            style="
                              border-right: 1px solid rgba(148, 148, 148, 0.747);
                              padding: 5px;
                            "
                            colspan="2"
                          >
                            {{ orignalStack3[el][i] }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </mat-accordion>
  </div>
  <div class="col-4" *ngIf="showFilter && !isMob && !showSort">
    <app-filter-employee-card
      [projects]="projects"
      [metaData]="metaData"
      [branches]="branches"
      (filterParams)="filterParams($event)"
    ></app-filter-employee-card>
  </div>
</section>
