import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { AddUpdateTitlesComponent } from "./add-update-titles/add-update-titles.component";
import { CpExcelBulkComponent } from "./cp-excel-bulk/cp-excel-bulk.component";
declare var $: any;

@Component({
	selector: "app-contractor-payment-setting",
	templateUrl: "./contractor-payment-setting.component.html",
	styleUrls: ["./contractor-payment-setting.component.scss"],
})
export class ContractorPaymentSettingComponent implements OnInit {
	@Input() supervision_data;
	@Input() project_data;
	@Input() makeActionConfig;
	searchKey = "";
	mainTitles = [];
	mainTitlesCollectionSize = 0;
	mainTitlesPageno = 1;
	mainTitlesPagesize = "10";
	nodata = this.lang.transform("lang_no_data");
	dialogRef: any;
	constructor(
		public ds: DataService,
		public lang: LanguagePipe,
		public ls: LocalStorage,
		public spinner: PreloaderService,
		public sweetalert: SweetAlertService,
		public dialog: MatDialog,
		public fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.load_main_titles();
	}
	public search_main_titles() {
		this.mainTitlesPageno = 1;
		this.mainTitlesPagesize = "10";
		this.load_main_titles();
	}
	public load_main_titles_page(page) {
		this.spinner.show();
		this.mainTitlesPageno = page;
		this.mainTitlesPagesize = this.mainTitlesPagesize;
		this.load_main_titles();
	}
	public load_main_titles_pagesize(size) {
		this.spinner.show();
		this.mainTitlesPageno = 1;
		this.mainTitlesPagesize = size;
		this.load_main_titles();
	}
	public load_main_titles() {
		let formdata = new FormData();
		formdata.append("search_key", this.searchKey || "");
		formdata.append(
			"projects_profile_id",
			this.project_data?.projects_profile_id || ""
		);
		formdata.append(
			"projects_work_area_id",
			this.supervision_data?.projects_work_area_id || ""
		);
		formdata.append("title_id", "");
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formdata),
				"p/cp/list_main_titles/" +
					this.mainTitlesPageno +
					"/" +
					this.mainTitlesPagesize
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.mainTitles = res?.records.map((el) => {
							return {
								...el,
								expanded: false,
							};
						});
						this.mainTitlesCollectionSize = res?.page?.total_records;
					} else {
						this.mainTitles = [];
						this.mainTitlesCollectionSize = 0;
						this.nodata = res.error;
					}
				},
				(error) => {
					this.spinner.hide();
					this.mainTitles = [];
					this.mainTitlesCollectionSize = 0;
					this.nodata =
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error");
				}
			);
	}

	toggleMainTitle(index) {
		for (let i = 0; i < this.mainTitles.length; i++) {
			if (i != index) {
				this.mainTitles[i].expanded = false;
			}
		}
		this.mainTitles[index].expanded = !this.mainTitles[index].expanded;
	}

	onAddUpdateMainTitle(data?) {
		this.dialogRef = this.dialog.open(AddUpdateTitlesComponent, {
			width: "500px",
			data: {
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
				projects_profile_id: this.project_data?.projects_profile_id,
				edit_data: data,
				type: "main",
			},
		});
		this.dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.load_main_titles();
			}
		});
	}
	onDeleteMainTitle(id) {
		this.spinner.show();
		this.ds
			.deleteActionByUrl(
				[id],
				`p/cp/delete_main_titles/${this.project_data?.projects_profile_id}/${this.supervision_data?.projects_work_area_id}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						this.sweetalert.successToast(
							this.lang.transform("lang_data_deleted_successfully"),
							2000
						);
						this.load_main_titles();
					} else {
						this.sweetalert.errorToast(res.error, 2000);
					}
				},
				(error) => {
					this.spinner.hide();
					this.sweetalert.errorToast(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error"),
						2000
					);
				}
			);
	}

	opencpExcel() {
		this.dialogRef = this.dialog.open(CpExcelBulkComponent, {
			width: "700px",
			data: {
				projects_work_area_id: this.supervision_data?.projects_work_area_id,
				projects_profile_id: this.project_data?.projects_profile_id,
				projects_supervision_id: this.supervision_data?.projects_supervision_id,
			},
		});
	}
}
