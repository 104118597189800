<breadcrumb></breadcrumb>
<div class="themesection">
  <div class="container mb-10">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-xs-12 mar0auto"></div>
      <div class="col-md-3 col-sm-6 col-xs-12 mar0auto">
        <div *ngIf="branch_id == '0'" class="red pad0 text-center">
          {{ "lang_choose_branch" | language }}
        </div>
        <select
          name="branch_id"
          class="form-control almnabrformcontrol"
          matTooltip="{{ 'lang_branch' | language }}"
          [(ngModel)]="branch_id"
          (change)="selectBrach($event.target.value)"
        >
          <option value="0">{{ "lang_choose_options" | language }}</option>
          <option *ngFor="let branch of branches" value="{{ branch.id }}">
            {{ branch.title }}
          </option>
        </select>
      </div>
      <div
        class="col-md-3 col-sm-6 col-xs-12 mar0auto"
        *ngIf="branch_id && branch_id != '0'"
      >
        <ng-select
          [items]="finances"
          [multiple]="false"
          bindLabel="label"
          bindValue="value"
          clearAllText="Clear"
          appendTo="body"
          [(ngModel)]="finance_id"
          placeholder="{{ 'lang_financial_years' | language }}"
          (change)="spinner.show(); load_transaction_viewers()"
        >
        </ng-select>
      </div>
      <div class="col-md-3 col-sm-6 col-xs-12 mar0auto"></div>
    </div>
  </div>
  <section
    class="panel-expansion matborderno"
    *ngIf="branch_id && branch_id != '0'"
  >
    <mat-accordion class="accounts_masters">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_transaction_viewer" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <div class="mbrl15">
            <div class="row">
              <div
                class="col-xs-12 mb-10"
                [ngClass]="{
                  'col-md-9 col-sm-9': length.length > 0,
                  'col-md-12 col-sm-12': length.length == 0
                }"
              >
                <div class="input-group input-group-sm mb-10">
                  <input
                    type="text"
                    placeholder="{{ 'lang_search' | language }}"
                    (keyup)="loadPageTrans(1)"
                    class="h34 form-control almnabrformcontrol search_key"
                    id="search_key"
                  />
                  <span class="input-group-btn" *ngIf="length.length != 0">
                    <button
                      type="button"
                      class="btnSearch pad0 h34 btn-md btn-gray"
                    >
                      <select
                        class="customselect"
                        (change)="
                          loadPageTrans($event.target.value); spinner.show()
                        "
                      >
                        <option
                          *ngFor="let ks of ds.getrange(paging.total_pages)"
                          value="{{ ks }}"
                        >
                          {{ ks }}
                        </option>
                      </select>
                    </button>
                  </span>
                  <span class="input-group-btn" *ngIf="length.length != 0">
                    <button
                      type="button"
                      class="btnSearch h34 btn-md btn-gray fa fa-external-link mnabricon"
                      (click)="
                        ds.openTransaction(
                          data.transaction_listing,
                          data.branch_id,
                          data.request_id,
                          data.finance_id
                        )
                      "
                      matTooltip="{{ 'lang_view' | language }}"
                    ></button>
                  </span>
                </div>
              </div>
              <div
                class="col-md-3 col-sm-3 col-xs-12 mb-10"
                *ngIf="length.length != 0"
              >
                <mat-paginator
                  [length]="length?.length"
                  [pageIndex]="page_no - 1"
                  [pageSize]="1"
                  [showFirstLastButtons]="true"
                  (page)="loadtransaction($event)"
                  aria-label="Select page"
                  [nextPageLabel]="nextLabel"
                >
                </mat-paginator>
                <!-- <jw-pagination [items]="length" [maxPages]='1' [pageSize]='1' (changePage)="loadtransaction($event)"></jw-pagination> -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_transaction_number" | language }} :
                    <b> {{ data.transaction_id }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label>
                    <span *ngIf="data.transaction_listing == 'RECEIPT'"
                      >{{ "lang_receipt_no" | language }} :
                    </span>
                    <span *ngIf="data.transaction_listing == 'PAYMENT'"
                      >{{ "lang_payment_no" | language }} :
                    </span>
                    <span *ngIf="data.transaction_listing == 'JOURNAL'"
                      >{{ "lang_journal_no" | language }} :
                    </span>
                    <span *ngIf="data.transaction_listing == 'SINVOICE'"
                      >{{ "lang_selling_invoice_no" | language }} :
                    </span>
                    <span *ngIf="data.transaction_listing == 'PINVOICE'"
                      >{{ "lang_purchase_invoice_no" | language }} :
                    </span>
                    <b> {{ data.request_id }}</b>
                  </label>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_transaction_type" | language }} :
                    <b *ngIf="data.transaction_listing == 'RECEIPT'">{{
                      "lang_receipts" | language
                    }}</b>
                    <b *ngIf="data.transaction_listing == 'PAYMENT'">{{
                      "lang_payments" | language
                    }}</b>
                    <b *ngIf="data.transaction_listing == 'JOURNAL'">{{
                      "lang_journal_voucher" | language
                    }}</b>
                    <b *ngIf="data.transaction_listing == 'SINVOICE'">{{
                      "lang_selling_invoices" | language
                    }}</b>
                    <b *ngIf="data.transaction_listing == 'PINVOICE'">{{
                      "lang_purchase_invoice" | language
                    }}</b>
                  </label>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_transaction_date" | language }} :
                    <b> {{ data.transaction_date }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_debit_total" | language }} :
                    <b> {{ data.total_debit }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_credit_total" | language }} :
                    <b> {{ data.total_credit }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_total" | language }} :
                    <b> {{ data.no_of_records }}</b></label
                  >
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                <div class="form-group">
                  <label
                    >{{ "lang_writer" | language }} :
                    <b> {{ data.writer_name }}</b></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive dropdownyes customResponsive">
                  <table border="1" class="table CustomTable">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>{{ "lang_account" | language }}</td>
                        <td>{{ "lang_accounts_cost_center" | language }}</td>
                        <td>{{ "lang_debit" | language }}</td>
                        <td>{{ "lang_credit" | language }}</td>
                        <td>{{ "lang_description" | language }}</td>
                        <td>{{ "lang_reference_no" | language }}</td>
                        <td>{{ "lang_notes" | language }}</td>
                      </tr>
                    </thead>
                    <tbody *ngIf="transaction.length > 0" class="AppendList">
                      <tr
                        *ngFor="
                          let trans of transaction;
                          let i = index;
                          let odd = odd;
                          let even = even
                        "
                        [ngClass]="{ odd: odd, even: even }"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>
                          {{ trans.account_name }}
                          <a
                            href="javascript:void(0)"
                            class="fa fa-exchange mnabricon s2icon"
                            routerLink="/accounts/statement_of_accounts/{{
                              trans.account_masters_id
                            }}/"
                            matTooltip="{{
                              'lang_view_transactions' | language
                            }}"
                          ></a>
                        </td>
                        <td *ngIf="trans.cost_name">
                          {{ trans.cost_name }}
                          <a
                            href="javascript:void(0)"
                            class="fa fa-exchange mnabricon s2icon"
                            routerLink="/accounts/statement_of_costs/{{
                              trans.cost_center_id
                            }}/"
                            matTooltip="{{
                              'lang_view_transactions' | language
                            }}"
                          ></a>
                        </td>
                        <td *ngIf="!trans.cost_name">----</td>
                        <td>{{ trans.debit_amount }}</td>
                        <td>{{ trans.credit_amount }}</td>
                        <td *ngIf="trans.transaction_history_description">
                          {{ trans.transaction_history_description }}
                        </td>
                        <td *ngIf="!trans.transaction_history_description">
                          ----
                        </td>
                        <td *ngIf="trans.transaction_history_ref_number">
                          {{ trans.transaction_history_ref_number }}
                        </td>
                        <td *ngIf="!trans.transaction_history_ref_number">
                          ----
                        </td>
                        <td *ngIf="trans.transaction_history_notes">
                          {{ trans.transaction_history_notes }}
                        </td>
                        <td *ngIf="!trans.transaction_history_notes">----</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="transaction.length == '0'" class="AppendList">
                      <tr class="odd">
                        <td colspan="8" align="center">{{ lodingdatas }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
