<mat-card
	class="mat-card-custom"
	style="padding: 16px !important; border-radius: 10px"
>
	<div class="form-wrapper">
		<div class="d-flex justify-content-between">
			<h4 style="margin-bottom: 10px; font-size: 1.3rem">View a vacation</h4>
			<i class="fa fa-close" mat-dialog-close></i>
		</div>
		<mat-divider></mat-divider>

		<div>
			<div class="row pt-3">
				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>Vaction Type</p>
					<p class="bold">something</p>
				</div>
				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>before vacation working date</p>
					<p class="bold">11/5/2021 - 1444/4/3</p>
				</div>
				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>After Vacation Working Date</p>
					<p class="bold">11/5/2021 - 1444/4/3</p>
				</div>

				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>other vaction</p>
					<p class="bold">something</p>
				</div>
				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>Vaction Start Date</p>
					<p class="bold">11/5/2021 - 1444/4/3</p>
				</div>
				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>Vaction End Date</p>
					<p class="bold">11/5/2021 - 1444/4/3</p>
				</div>
				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>contract Id</p>
					<p class="bold">1232</p>
				</div>
				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>Vaction Total Days</p>
					<p class="bold">12</p>
				</div>

				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>vacation paid days from contract</p>
					<p class="bold">12</p>
				</div>
				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>vacation total paid days</p>
					<p class="bold">12</p>
				</div>
				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>vacation total unpaid days</p>
					<p class="bold">12</p>
				</div>

				<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
					<p>vacation total paid amount</p>
					<p class="bold">12</p>
				</div>
			</div>
		</div>
	</div>
</mat-card>
