import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';

@Component({
  selector: 'app-price-filters',
  templateUrl: './price-filters.component.html',
  styleUrls: ['./price-filters.component.scss']
})
export class PriceFiltersComponent implements OnInit {

  from_date                   = new FormControl(null);
  to_date                     = new FormControl(null);
  phase_zone_no               = null;
  platform_group1_code_system = null;
  projects_profile_id;
  projects_supervision_id;
  zones                       = [];
  divisions                   = [];
  constructor(public dialogRef: MatDialogRef<PriceFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private spinner: PreloaderService, private ds: DataService) { 
      this.projects_profile_id = data?.projects_profile_id;
      this.projects_supervision_id = data?.projects_supervision_id;
    }

  ngOnInit(): void {
    this.get_zones();
    this.get_divisions();
  }

  get_zones() {
    this.spinner.show();
    let phaseId = '0',
    param = new FormData();
    param.append('phase_parent_id', phaseId);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'joYF29rbEi/'+this.projects_profile_id+'/'+this.projects_supervision_id).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.zones = res?.records;
        }
    });
  }

  get_divisions() {
    this.spinner.show();
    this.ds.get('pr/get_system_division').subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.divisions = res?.result;
        }
    });
  }


  onSubmit() {
    this.spinner.show();
    this.dialogRef.close({
      from_date: this.convertDateToString(this.from_date.value), 
      to_date: this.convertDateToString(this.to_date.value), 
      phase_zone_no: this.phase_zone_no ? this.phase_zone_no?.toString() : '', 
      platform_group1_code_system: this.platform_group1_code_system ? this.platform_group1_code_system?.toString() : '', 
    });
    this.spinner.hide();
  }

  convertDateToString(date) {
    if(date) {
      var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("/");
    } else {
      return null;
    }
  }
}
