<div mat-dialog-title class="d-flex justify-content-between">
	<h4>{{ "lang_users" | language }}</h4>
	<i class="fa fa-times mnabricon" mat-dialog-close></i>
</div>
<mat-dialog-content>
	<div class="col-12">
		<div
			class="table-responsive customResponsive tablefields quotationitemtable"
		>
			<table border="1" class="table CustomTable" width="100%">
				<thead>
					<tr>
						<td width="10%" style="padding: 5px; word-break: break-all">#</td>
						<td style="padding: 5px; word-break: break-all">
							{{ "lang_user_id" | language }}
						</td>
						<td style="padding: 5px; word-break: break-all">
							{{ "lang_human_resources_fname_english" | language }}
						</td>
						<td style="padding: 5px; word-break: break-all">
							{{ "lang_human_resources_lname_english" | language }}
						</td>
					</tr>
				</thead>
				<tbody *ngIf="users?.length">
					<tr
						*ngFor="
							let user of users;
							let i = index;
							let odd = odd;
							let even = even
						"
					>
						<td
							class="verticalalignmid"
							style="padding: 5px; word-break: break-all"
						>
							{{ i + 1 }}
						</td>
						<td
							style="
								padding: 5px;
								word-break: break-all;
								position: relative;
								text-align: center;
							"
						>
							{{ user?.emp_id }}
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="user?.firstname_english && lang_key == 'en'"
						>
							{{ user?.firstname_english }}
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="!user?.firstname_english && lang_key == 'en'"
						>
							---
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="user?.lastname_english && lang_key == 'en'"
						>
							{{ user?.lastname_english }}
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="!user?.lastname_english && lang_key == 'en'"
						>
							---
						</td>

						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="user?.firstname_arabic && lang_key == 'ar'"
						>
							{{ user?.firstname_arabic }}
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="!user?.firstname_arabic && lang_key == 'ar'"
						>
							---
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="user?.lastname_arabic && lang_key == 'ar'"
						>
							{{ user?.lastname_arabic }}
						</td>
						<td
							class="verticalalignmid text-center"
							style="padding: 5px; word-break: break-all"
							*ngIf="!user?.lastname_arabic && lang_key == 'ar'"
						>
							---
						</td>
					</tr>
				</tbody>
				<tbody *ngIf="!users?.length">
					<tr>
						<td colspan="3" class="text-center">
							{{ "lang_no_data" | language }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</mat-dialog-content>
