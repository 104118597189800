import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MatDialogRef,
	MatDialog,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-add-edit-group",
	templateUrl: "./add-edit-group.component.html",
	styleUrls: ["./add-edit-group.component.scss"],
})
export class AddEditGroupComponent implements OnInit {
	lang_key = localStorage.getItem("lang_key") || "en";
	groupForm: FormGroup;
	group_data;
	edit_mode = false;
	constructor(
		private fb: FormBuilder,
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe,
		private spinner: PreloaderService,
		private dialogRef: MatDialogRef<AddEditGroupComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.group_data = data?.group_data;
		this.edit_mode = this.group_data ? true : false;
	}

	ngOnInit(): void {
		this.buildForm();
		if (this.edit_mode) {
			this.setEditData();
		}
	}

	buildForm() {
		this.groupForm = this.fb.group({
			group_name: [null, Validators.required],
			group_email: [null, [Validators.required, Validators.email]],
		});
	}
	setEditData() {
		this.groupForm.get("group_name").setValue(this.group_data.group_name);
		this.groupForm.get("group_email").setValue(this.group_data.group_email);
	}

	addEditGroup() {
		this.spinner.show();
		let param = new FormData();
		let url = "project_design/add_group";
		if (this.edit_mode) {
			param.append("group_id", this.group_data.group_id);
			url = "project_design/update_group";
		}
		param.append("group_name", this.groupForm.value.group_name);
		param.append("group_email", this.groupForm.value.group_email);
		this.ds.post(url, param).subscribe((res) => {
			this.spinner.hide();
			if (res.status) {
				this.sweetAlert.successToast(res?.message, 2000);
				this.dialogRef.close(true);
			} else {
				this.sweetAlert.errorToast(res.error, 2000);
			}
		});
	}
}
