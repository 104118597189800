import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '@core/bootstrap/data.service';
@Pipe({
	name: 'language'
})
export class LanguagePipe implements PipeTransform {
	constructor(private ds: DataService) { }
	transform(key: string): string {
		if (this.ds.phrases === undefined || this.ds.phrases[key] === undefined) {
			return key;
		}
		return this.ds.phrases[key];
	}
}
