import { Component, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { special_approval_notes } from "app/routes/transactions/FORM_PS_TR1/formdata";
import { SendCodeComponent } from "app/routes/transactions/FORM_PS_TR1/send-code/send-code.component";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-step-vacation-three",
  templateUrl: "./step-three.component.html",
  styleUrls: ["./step-three.component.scss"],
})
export class StepThreeComponent implements OnInit {
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;

  lang_key =
    localStorage.getItem("lang_key") == null
      ? "en"
      : localStorage.getItem("lang_key");
  submitForm: boolean = false;
  sendCodeForm: any;
  sendCodeStatus: boolean = false;
  rejectedValidators = [];
  stepName = "DIRECT_MANAGER";
  form: FormGroup;
  users: any = [];
  employee_type = [
    { type: `${this.lang.transform("lang_inside")}`, value: "1" },
    { type: `${this.lang.transform("lang_outside")}`, value: "0" },
    { type: `${this.lang.transform("lang_not_required")}`, value: "2" },
  ];
  myData: any = [];

  constructor(
    public ds: DataService,
    public router: Router,
    public route: ActivatedRoute,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.myData = special_approval_notes;
    this.form = new FormGroup({
      transaction_request_id: new FormControl(this.form_request_id, [
        Validators.required,
      ]),
      approved: new FormControl("", [Validators.required]),
      notes: new FormControl("", []),
      transactions_persons_action_code: new FormControl("", [
        Validators.required,
      ]),
      substitute_employee: new FormControl(null),
      employee_type: new FormControl(null),
      substitute_employee_name: new FormControl(""),
      substitute_employee_number_iqama: new FormControl(""),
    });
  }
  setValidators() {
    if (this.form.get("employee_type").value == "0") {
      this.form
        .get("substitute_employee")
        ?.setValidators(Validators.nullValidator);
      this.form
        .get("substitute_employee_name")
        ?.setValidators(Validators.required);
      this.form
        .get("substitute_employee_number_iqama")
        ?.setValidators(Validators.required);
    } else if (this.form.get("employee_type").value == "1") {
      this.form.get("substitute_employee")?.setValidators(Validators.required);
      this.form
        .get("substitute_employee_name")
        ?.setValidators(Validators.nullValidator);
      this.form
        .get("substitute_employee_number_iqama")
        ?.setValidators(Validators.nullValidator);
    } else {
      this.form
        .get("substitute_employee")
        ?.setValidators(Validators.nullValidator);
      this.form
        .get("substitute_employee_name")
        ?.setValidators(Validators.nullValidator);
      this.form
        .get("substitute_employee_number_iqama")
        ?.setValidators(Validators.nullValidator);
    }
  }

  get getNotes() {
    return this.form.get("notes");
  }

  checkStatus() {
    if (this.form.get("approved").value == "0") {
      this.rejectedValidators = [Validators.required];
      this.getNotes?.setValidators([Validators.required]);

      this.getNotes?.updateValueAndValidity();
    } else {
      this.rejectedValidators = [];
      this.getNotes?.setValidators([]);

      this.getNotes?.updateValueAndValidity();
    }
  }
  public completeStep3() {
    this.submitForm = true;
    if (this.form.valid) {
      this.spinner.show();
      let formData = new FormData();
      formData.append(
        "transaction_request_id",
        this.form.value.transaction_request_id
      );
      formData.append("approving_status", this.form.value.approved);
      if (this.form?.value?.approved) {
        formData.append("note", this.form.value.notes);
      }

      formData.append(
        "transactions_persons_action_code",
        this.form.value.transactions_persons_action_code
      );
      if (this.form.get("employee_type").value == "0") {
        formData.append(
          "substitute_employee_name",
          this.form.value.substitute_employee_name
        );
        formData.append(
          "substitute_employee_number_iqama",
          this.form.value.substitute_employee_number_iqama
        );
      } else {
        formData.append(
          "substitute_employee",
          this.form.value.substitute_employee
        );
      }

      this.ds.post(`/form/${this.form_key}/sr`, formData).subscribe(
        (data) => {
          if (data.status) {
            this.spinner.hide();
            this.alert.success(data.msg);
            this.submitForm = false;
            setTimeout(() => {
              location.reload();
            }, 2000);
          } else {
            this.spinner.hide();
            this.alert.error(data?.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(error);
        }
      );
    } else {
      this.alert.error(this.lang.transform("lang_all_fields_required"));
    }
  }

  openModal() {
    const dialogRef = this.dialog.open(SendCodeComponent, {
      disableClose: true,
      width: "400px",
      data: {
        transaction_persons_type: "signature",
        allData: this.allData,
        stepName: this.stepName,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.sendCodeForm = data?.formValue;
      this.sendCodeStatus = data?.sendstatus;
    });
  }
  public search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append("search", key.term || "");
    param.append("lang_key", this.lang_key);
    param.append("user_type_id", "1");
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
      .subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      });
  }
}
