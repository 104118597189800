<section class="card matborderno activeiconstepper customdesignstepper">
	<mat-accordion class="stylepanels">
		<mat-expansion-panel class="mb-2" [expanded]="expandedStep == 0">
			<mat-expansion-panel-header (click)="openAccord(0)"
				>{{ "lang_form_details" | language }}
			</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_transaction_number" | language
							}}</label>
							<div>
								{{ transaction_request?.transaction_request_id }}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_transaction_from" | language
							}}</label>
							<div>
								{{ transaction_request?.transaction_from_name }}
							</div>
						</div>

						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_transaction_to" | language }}</label>
							<div>
								{{ transaction_request?.transaction_to_name }}
							</div>
						</div>

						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_transaction_key" | language
							}}</label>
							<div style="direction: ltr !important">
								{{ transaction_request?.transaction_key }}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_created_by" | language }}</label>
							<div>
								{{ transaction_request?.created_name }}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_created_date" | language }}</label>
							<div>
								{{ transaction_request?.created_date }}
							</div>
						</div>
						<div
							class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center"
							*ngIf="!transaction_request?.view_link"
						>
							<label class="bold">{{ "lang_preview" | language }}</label>
							<div>
								<i
									class="fa fa-file-pdf-o"
									aria-hidden="true"
									(click)="preview_form_data(false)"
								></i>
							</div>
						</div>
						<div
							class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center"
							*ngIf="transaction_request?.view_link"
						>
							<label class="bold">{{ "lang_view" | language }}</label>
							<div>
								<i
									class="fa fa-eye"
									aria-hidden="true"
									(click)="preview_form_data(true)"
								></i>
							</div>
						</div>
					</div>
					<div
						class="row"
						[ngStyle]="
							lang_key == 'ar'
								? {
										'padding-left': '10px',
										'padding-right': '10px'
								  }
								: null
						"
					>
						<div class="col-xs-12 col-12 pad0">
							<div class="stepper-progress">
								<div>
									<p>
										{{ "lang_selected_step" | language }}:
										<span class="bold">{{
											stepsTitles[selectedIndex]?.label
										}}</span>
									</p>
									<p>
										{{ "lang_last_step_opened" | language }}:
										<span class="green bold"
											>{{ lastStepOpenedShow }}
											<span
												*ngIf="rejected"
												class="red"
												style="cursor: pointer"
												(click)="openAccord(1)"
											>
												({{ rejected }})
											</span>
											<i
												style="padding: 0 10px"
												class="fa fa-info-circle orangestatus-color mr-2 ml-2"
												style="white-space: pre-line !important"
												matTooltip="{{ usersTooltip | language }}"
											></i>
											<i
												style="padding: 0 10px"
												class="fa fa-edit mnabricon"
												style="white-space: pre-line !important"
												*ngIf="Admin_Persons"
												(click)="addStepUser()"
											>
											</i>
										</span>
									</p>
									<p class="step-counter">
										{{ "lang_step" | language }} <b>{{ selectedIndex + 1 }}</b>
										{{ "lang_of" | language }}
										<b>{{ totalStepsCount }}</b>
									</p>
								</div>
								<mat-progress-bar mode="determinate" [value]="progressValue">
								</mat-progress-bar>
							</div>
							<div class="row pb-3">
								<div class="col-3 col-sm-2">
									<button
										(click)="moveStepper('prev')"
										class="carousel-btn-pre albutton mnabrbutton mat-button"
									>
										<i
											class="fa"
											[ngClass]="{
												'fa-chevron-left': lang_key == 'en',
												'fa-chevron-right': lang_key == 'ar'
											}"
											style="color: #fff"
										></i>
									</button>
								</div>
								<div class="col-6 col-sm-8"></div>
								<div class="col-3 col-sm-2">
									<button
										(click)="moveStepper('next')"
										class="carousel-btn-next albutton mnabrbutton mat-button"
									>
										<i
											class="fa"
											[ngClass]="{
												'fa-chevron-right': lang_key == 'en',
												'fa-chevron-left': lang_key == 'ar'
											}"
											style="color: #fff"
										></i>
									</button>
								</div>
							</div>

							<mat-horizontal-stepper
								#stepper
								class="pad0 steps13-stepper"
								[linear]="isLinear"
								[selectedIndex]="selectedIndex"
								(selectionChange)="selectionChange($event)"
							>
								<mat-step>
									<app-pq1-step1
										*ngIf="
											allData?.steps['CONFIGURATION - GENERAL'] &&
											selectedIndex == 0
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										[editMode]="true"
										[allData]="allData"
										(refreshComponent)="refreshComponent($event)"
									></app-pq1-step1>
									<div *ngIf="!allData?.steps['CONFIGURATION - GENERAL']">
										<div class="col-12 mt-2">
											<div class="step-history">
												<div
													class="step-header"
													[ngStyle]="{
														left: lang_key == 'en' ? '20px' : 'unset',
														right: lang_key == 'ar' ? '20px' : 'unset'
													}"
												>
													{{ "lang_configurations" | language }}
												</div>
												<div class="row">
													<div class="col-12 col-sm-4 mb-1">
														<p style="font-weight: 600; margin: 0">
															{{ "lang_languages" | language }} :
															<span style="font-weight: 500">{{
																configurationData?.language
															}}</span>
														</p>
													</div>
													<div class="col-12 col-sm-4 mb-1">
														<p style="font-weight: 600; margin: 0">
															{{ "lang_subject" | language }} :
															<span style="font-weight: 500">{{
																configurationData?.subject
															}}</span>
														</p>
													</div>
													<div class="col-12 col-sm-4 mb-1">
														<p style="font-weight: 600; margin: 0">
															{{ "lang_care" | language }} :
															<span style="font-weight: 500">{{
																configurationData?.care
															}}</span>
														</p>
													</div>
													<div class="col-12 col-sm-6 mb-1">
														<p style="font-weight: 600; margin: 0">
															{{ "lang_transaction_to" | language }} :
															<span style="font-weight: 500">{{
																configurationData?.transaction_to
															}}</span>
														</p>
													</div>
													<div class="col-12 col-sm-6 mb-1">
														<p style="font-weight: 600; margin: 0">
															{{ "lang_vat" | language }} :
															<span style="font-weight: 500">{{
																configurationData?.vat_name
															}}</span>
														</p>
													</div>
													<div class="col-12 col-sm-6 mb-1">
														<p style="font-weight: 600; margin: 0">
															{{ "lang_quotation_type" | language }} :
															<span style="font-weight: 500">{{
																configurationData?.quotation_types_name
															}}</span>
														</p>
													</div>
													<div class="col-12 col-sm-6 mb-1">
														<p style="font-weight: 600; margin: 0">
															{{ "lang_attachmentstitle" | language }} :
															<span style="font-weight: 500">{{
																configurationData?.attachmentTitle
															}}</span>
														</p>
													</div>
													<div class="col-12 mb-1">
														<p style="font-weight: 600; margin: 0">
															{{ "lang_description" | language }} :
														</p>
														<div [innerHtml]="configurationData?.content"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</mat-step>
								<mat-step>
									<app-pq1-step2
										[form_url]="form_url"
										[form_key]="form_key"
										[allData]="allData"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
										*ngIf="
											allData?.steps['CONFIGURATION - TEAMS'] &&
											selectedIndex == 1
										"
									></app-pq1-step2>
									<div *ngIf="!allData?.steps['CONFIGURATION - TEAMS']">
										<div class="col-12 mt-2">
											<div class="step-history">
												<div
													class="step-header"
													[ngStyle]="{
														left: lang_key == 'en' ? '20px' : 'unset',
														right: lang_key == 'ar' ? '20px' : 'unset'
													}"
												>
													{{ "lang_teams" | language }}
												</div>
												<div class="row">
													<div class="col-12">
														<div class="itemsdata">
															<div class="TableParts">
																<div
																	class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
																>
																	<table
																		border="1"
																		class="table CustomTable text-center"
																	>
																		<thead>
																			<tr>
																				<td>#</td>
																				<td>
																					<span
																						matTooltip="{{
																							'lang_position' | language
																						}}"
																						>{{
																							"lang_position" | language
																						}}</span
																					>
																				</td>
																				<td>
																					<span
																						matTooltip="{{
																							'lang_presence' | language
																						}}"
																						>{{
																							"lang_presence" | language
																						}}</span
																					>
																				</td>
																				<td>
																					<span
																						matTooltip="{{
																							'lang_projects_nationality'
																								| language
																						}}"
																						>{{
																							"lang_projects_nationality"
																								| language
																						}}</span
																					>
																				</td>
																				<td>
																					<span
																						matTooltip="{{
																							'lang_team_number' | language
																						}}"
																						>{{
																							"lang_team_number" | language
																						}}</span
																					>
																				</td>
																				<td>
																					<span
																						matTooltip="{{
																							'lang_experience' | language
																						}}"
																						>{{
																							"lang_experience" | language
																						}}</span
																					>
																				</td>
																				<td>
																					<span
																						matTooltip="{{
																							'lang_proit_ratio' | language
																						}}"
																						>{{
																							"lang_proit_ratio" | language
																						}}</span
																					>
																				</td>
																				<td>
																					<span
																						matTooltip="{{
																							'lang_total_salary_per_person_month'
																								| language
																						}}"
																						>{{
																							"lang_total_salary_per_person_month"
																								| language
																						}}</span
																					>
																				</td>
																				<td>
																					<span
																						matTooltip="{{
																							'lang_duration_number_months'
																								| language
																						}}"
																						>{{
																							"lang_duration_number_months"
																								| language
																						}}</span
																					>
																				</td>
																				<td>
																					<span
																						matTooltip="{{
																							'lang_total_no_of_visits'
																								| language
																						}}"
																						>{{
																							"lang_total_no_of_visits"
																								| language
																						}}</span
																					>
																				</td>
																				<td>
																					<span
																						matTooltip="{{
																							'lang_toal_bid' | language
																						}}"
																						>{{
																							"lang_toal_bid" | language
																						}}</span
																					>
																				</td>
																			</tr>
																		</thead>
																		<tbody class="AppendList quotationtable">
																			<tr
																				*ngFor="
																					let item of teamsData;
																					let itemIndex = index;
																					let odd = odd;
																					let even = even
																				"
																				style="background: #ffffff"
																			>
																				<td class="verticalalignmid">
																					{{ itemIndex + 1 }}
																				</td>
																				<td width="10%">
																					{{ item?.position_name }}
																				</td>
																				<td width="10%">
																					{{ item?.presence_name }}
																				</td>
																				<td width="10%">
																					{{ item?.nationality_name }}
																				</td>
																				<td>
																					{{ item?.team_number }}
																				</td>
																				<td>
																					{{ item?.experience }}
																				</td>
																				<td>
																					{{ item?.profit_ratio }}
																				</td>
																				<td>
																					{{
																						item?.total_salary_per_person_month
																					}}
																				</td>
																				<td>
																					{{ item?.duration_of_months }}
																				</td>
																				<td>
																					{{ item?.visits_per_month }}
																				</td>
																				<td>
																					{{ item?.total_bid_of_project }}
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
															<div class="TableParts">
																<div
																	class="table-responsive customResponsive mb-10 tablefields"
																>
																	<table
																		border="1"
																		class="table CustomTable text-center"
																	>
																		<thead>
																			<tr>
																				<td>#</td>
																				<td>
																					<span
																						matTooltip="{{
																							'lang_position_description'
																								| language
																						}}"
																						>{{
																							"lang_position_description"
																								| language
																						}}</span
																					>
																				</td>
																			</tr>
																		</thead>
																		<tbody class="AppendList">
																			<tr
																				*ngFor="
																					let item of teamsData;
																					let itemIndex = index;
																					let odd = odd;
																					let even = even
																				"
																				style="background: #ffffff"
																			>
																				<td width="1%" class="verticalalignmid">
																					{{ itemIndex + 1 }}
																				</td>
																				<td>
																					{{ item?.positions_task }}
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</mat-step>
								<mat-step>
									<app-pq1-step3
										*ngIf="
											allData?.steps['CONFIGURATION -CONDITIONS'] &&
											selectedIndex == 2
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[allData]="allData"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[editMode]="true"
										(refreshComponent)="refreshComponent($event)"
									></app-pq1-step3>
									<div
										class="col-12 mt-3"
										*ngIf="!allData?.steps['CONFIGURATION -CONDITIONS']"
									>
										<div class="step-history">
											<div
												class="step-header"
												[ngStyle]="{
													left: lang_key == 'en' ? '20px' : 'unset',
													right: lang_key == 'ar' ? '20px' : 'unset'
												}"
											>
												{{ "lang_terms_conditions" | language }}
											</div>
											<div class="row">
												<div class="col-12">
													<div class="TableParts">
														<div
															class="table-responsive customResponsive mb-10 tablefields quotationitemtable"
														>
															<table
																border="1"
																class="table CustomTable text-center"
															>
																<thead>
																	<tr>
																		<td width="5%">#</td>
																		<td>
																			<span
																				matTooltip="{{
																					'lang_title_english' | language
																				}}"
																				>{{
																					"lang_title_english" | language
																				}}</span
																			>
																		</td>
																		<td>
																			<span
																				matTooltip="{{
																					'lang_title_arabic' | language
																				}}"
																				>{{
																					"lang_title_arabic" | language
																				}}</span
																			>
																		</td>
																		<td>
																			<span
																				matTooltip="{{
																					'lang_type' | language
																				}}"
																				>{{ "lang_type" | language }}</span
																			>
																		</td>
																	</tr>
																</thead>
																<tbody class="AppendList quotationtable">
																	<tr
																		*ngFor="
																			let item of termsData;
																			let itemIndex = index;
																			let odd = odd;
																			let even = even
																		"
																		style="background: #ffffff"
																	>
																		<td class="verticalalignmid">
																			{{ itemIndex + 1 }}
																		</td>
																		<td width="10%">
																			{{ item?.rf_name_en }}
																		</td>
																		<td width="10%">
																			{{ item?.rf_name_ar }}
																		</td>
																		<td width="10%">
																			{{
																				"lang_" + item?.condition_type
																					| language
																			}}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</mat-step>
								<mat-step>
									<app-pq1-step4
										*ngIf="
											allData?.steps['FINICLA_MANAGERS_STEP'] &&
											selectedIndex == 3
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[allData]="allData"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
									></app-pq1-step4>
									<div
										class="not-allow"
										*ngIf="!allData?.steps['FINICLA_MANAGERS_STEP']"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
								<mat-step>
									<app-pq1-step5
										*ngIf="
											allData?.steps['EXECUTIVE_MANAGEMENT'] &&
											selectedIndex == 4
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[allData]="allData"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										(refreshComponent)="refreshComponent($event)"
									></app-pq1-step5>
									<div
										class="not-allow"
										*ngIf="!allData?.steps['EXECUTIVE_MANAGEMENT']"
									>
										<div class="permission-img">
											<img
												src="./assets/images/no-permission.gif"
												alt=""
												width="100%"
												height="100%"
											/>
										</div>
										<p style="text-align: center">
											{{ "lang_step_permission" | language }}
										</p>
									</div>
								</mat-step>
								<mat-step>
									<app-pq1-step6
										*ngIf="
											(lastStepOpened == 'last' ||
												lastStepOpened == 'completed') &&
											selectedIndex == 5
										"
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[allData]="allData"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										[lastStepOpened]="lastStepOpened"
									></app-pq1-step6>
								</mat-step>
							</mat-horizontal-stepper>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel class="mb-2" [expanded]="expandedStep == 1">
			<mat-expansion-panel-header (click)="openAccord(1)">
				{{ "lang_notes" | language }}
			</mat-expansion-panel-header>
			<div class="row">
				<div class="col-12">
					<div class="TableParts">
						<app-transactions-notes [notes]="notes"></app-transactions-notes>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
		<mat-expansion-panel class="mb-2" [expanded]="expandedStep == 2">
			<mat-expansion-panel-header (click)="openAccord(2)">{{
				"lang_person_details" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body communication">
				<div class="mbrl15">
					<div class="col-md-12 pad0">
						<app-person-details [persons]="persons"></app-person-details>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
		<mat-expansion-panel class="mb-2" [expanded]="expandedStep == 3">
			<mat-expansion-panel-header (click)="openAccord(3)">{{
				"lang_attachments" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body communication">
				<div class="mbrl15">
					<div class="col-md-12 pad0">
						<app-transaction-attachments
							[All_Attachments]="All_Attachments"
							[isInsideSupervision]="true"
						></app-transaction-attachments>
					</div>
				</div>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel class="mb-2" [expanded]="expandedStep == 4">
			<mat-expansion-panel-header
				class="mpanel-header"
				(click)="openAccord(4)"
				>{{ "lang_history" | language }}</mat-expansion-panel-header
			>
			<div class="material-panel-body communication">
				<div class="mbrl15">
					<div class="col-md-12 pad0">
						<app-transactions-history
							[history]="history"
						></app-transactions-history>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="card-footer">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
		</div>
	</div>
</section>
