import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { PayroleAddEmployeesComponent } from "../../payrole-add-employees/payrole-add-employees.component";

@Component({
  selector: "app-step-two",
  templateUrl: "./step-two.component.html",
  styleUrls: ["./step-two.component.scss"],
})
export class StepTwoComponent implements OnInit {
  constructor(
    private _formBuilder: FormBuilder,
    public ds: DataService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public lang: LanguagePipe,
    private _sweetAlertService: SweetAlertService,
    private spinner: PreloaderService
  ) {}
  @Input() stepper: MatStepper;
  @Input() t_id: any = "";
  @Input() projects: any = [];
  @Input() branches: any = [];
  @Input() requestInfo: any = "";
  @Input() usersList: any[] = [];
  @Output() resfreshUsers = new EventEmitter<any>();
  @Output() UserListSetter = new EventEmitter<any>();
  @Output() sendComponentUpdate = new EventEmitter<any>();
  showFilters = false;
  filterForm = this._formBuilder.group({
    employee_id: [""],
    employee_name: [""],
    project_id: [""],
    branch_id: [""],
  });
  ngOnInit(): void {}

  sendDataToParent(value: any) {
    this.UserListSetter.emit(value);
  }
  moveToDetails() {
    if (this.usersList.length > 0) {
      this.spinner.show();
      let formData = new FormData();

      for (let index = 0; index < this.usersList.length; index++) {
        const element = this.usersList[index];
        formData.append(
          `employees_numbers[${index + 1}]`,
          element.employee_number
        );
      }

      this.ds
        .post(`form/FORM_SAL/store_selected_employees/${this.t_id}`, formData)
        .subscribe(
          (data) => {
            if (data.status) {
              this.sendComponentUpdate.emit();
              this._sweetAlertService.successToast(
                this.lang.transform("lang_data_sent_successfully"),
                2000
              );
              this.spinner.hide();
              this.resfreshUsers.emit();
              this.update_configuration_step();
            } else {
              this.spinner.hide();
              this._sweetAlertService.errorMessage(data.msg);
            }
          },
          (error) => {
            this._sweetAlertService.errorMessage(error?.error?.error);
            this.spinner.hide();
            /*    this.showMsg(error?.error?.error, 'ok', 'error-snackbar'); */
          }
        );
    } else {
      this._sweetAlertService.warningToast(
        this.lang.transform("lang_select_anyone"),
        2000
      );
    }
  }
  update_configuration_step() {
    this.spinner.show();
    let formData = new FormData();

    formData.append(`Configration_Step`, "selected_employees_datils");

    this.ds
      .post(`form/FORM_SAL/update_configuration_step/${this.t_id}`, formData)
      .subscribe(
        (data) => {
          if (data.status) {
            this.goForward(this.stepper);

            this.spinner.hide();
          } else {
            this.spinner.hide();
            this._sweetAlertService.errorMessage(data.error);
            /*   this.showMsg(data.error, 'ok', 'error-snackbar'); */
          }
        },
        (error) => {
          this._sweetAlertService.errorMessage(error?.error?.error);
          this.spinner.hide();
          /*    this.showMsg(error?.error?.error, 'ok', 'error-snackbar'); */
        }
      );
  }
  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  orignalUsersList = [];
  sortArray() {
    this.usersList = this.orignalUsersList;
    let condition = true;
    console.log(this.filterForm.value);
    let filters: any = {
      employee_number: (employee_number) =>
        employee_number.includes(this.filterForm.value?.employee_id),
      employee_name: (employee_name) =>
        employee_name
          .toLowerCase()
          .includes(this.filterForm.value.employee_name.toLowerCase()),
      branch_id: (branch_id) =>
        branch_id.includes(this.filterForm.value.branch_id),
      /* project_id: (project_id) =>  project_id.includes(this.filterForm.value.project_id)   */
    };
    let filterKeys = Object.keys(filters);
    if (!this.filterForm.value.branch_id) {
      filterKeys.splice(2, 1);
    }
    if (!this.filterForm.value.employee_name) {
      filterKeys.splice(1, 1);
    }
    if (!this.filterForm.value.employee_id) {
      filterKeys.splice(0, 1);
    }

    if (this.filterForm.value.project_id) {
      this.usersList = this.usersList.filter((ul) => {
        return ul.project_id != null;
      });

      filters = {
        employee_number: (employee_number) =>
          employee_number.includes(this.filterForm.value.employee_id),
        employee_name: (employee_name) =>
          employee_name
            .toLowerCase()
            .includes(this.filterForm.value.employee_name.toLowerCase()),
        branch_id: (branch_id) =>
          branch_id.includes(this.filterForm.value.branch_id),
        project_id: (project_id) =>
          project_id.includes(this.filterForm.value.project_id),
      };
    }
    this.usersList = this.usersList.filter((ul) => {
      return filterKeys.every((key) => {
        // ignores non-function predicates
        if (typeof filters[key] !== "function") return true;
        return filters[key](ul[key]);
      });
    });
  }

  openChartData(info) {
    const dialogRef = this.dialog.open(PayroleAddEmployeesComponent, {
      width: "100%",
      data: {
        info: info,
        projects: this.projects,
        branches: this.branches,
        usersList: this.usersList,
        t_id: this.t_id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.usersList = [];
        for (let index = 0; index < result.new.length; index++) {
          const element = result.new[index];
          let inTheArr = false;
          for (let i = 0; i < this.usersList.length; i++) {
            const e = this.usersList[i];
            if (e.employee_number == element.employee_number) {
              inTheArr = true;
            }
          }
          if (!inTheArr) {
            this.usersList.push({ ...element, collapsed: false, isNew: true });
          }
        }
        this.orignalUsersList = this.usersList;
        this.sendDataToParent(this.orignalUsersList);
      }
    });
  }

  clearAll() {
    this.usersList = [];
    this.orignalUsersList = this.usersList;
    this.sendDataToParent(this.orignalUsersList);
  }
  deleteSingle(id: any, index) {
    this.usersList.splice(index, 1);

    this.orignalUsersList = this.usersList;
    this.sendDataToParent(this.orignalUsersList);
  }
}
