<div mat-dialog-title>
  <div class="d-flex justify-content-between">
    <h4>{{ "lang_timeline_details" }}</h4>
    <span mat-dialog-close><i class="fa fa-times"></i></span>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div
    class="table-responsive dropdownyes customResponsive tablefields"
    *ngIf="dataList"
  >
    <table border="1" class="table CustomTable">
      <thead>
        <tr>
          <th>#</th>
          <th>{{ "lang_activity_code_system" | language }}</th>
          <th>{{ "lang_unit_id" | language }}</th>
          <th>{{ "lang_group_one" | language }}</th>
          <th>{{ "lang_group_two" | language }}</th>
          <th>{{ "lang_result" | language }}</th>
          <th>{{ "lang_activity_subject" | language }}</th>
          <th>{{ "lang_start_date" | language }}</th>
          <th>{{ "lang_end_date" | language }}</th>
          <th>{{ "lang_ratio" | language }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let item of dataList?.records;
            let i = index;
            let odd = odd;
            let even = even
          "
          [ngClass]="{
            accepteTd: item?.result_code === 'A' || item?.result_code === 'B',
            rejectTd:
              item?.result_code === 'C' ||
              item?.result_code === 'D' ||
              item?.result_code === 'E'
          }"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ item?.activity_code_system_action || "---" }}</td>
          <td>{{ item?.unit_id || "---" }}</td>
          <td>
            {{
              lang_key == "en"
                ? item?.activity_group1_title_english
                : item?.activity_group1_title_arabic || "---"
            }}
          </td>
          <td>
            {{
              lang_key == "en"
                ? item?.activity_group2_title_english
                : item?.activity_group2_title_arabic || "---"
            }}
          </td>
          <td>{{ item?.result_code || "---" }}</td>
          <td>
            {{
              lang_key == "en"
                ? item?.activity_subject_english
                : item?.activity_subject_arabic || "---"
            }}
          </td>
          <td>{{ item?.activity_plan_start_date || "---" }}</td>
          <td>{{ item?.activity_plan_end_date || "---" }}</td>
          <td>
            {{ item?.work_level_ratio | number: "1.0-2" || "---" }}
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="col-12 mt-2"
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      "
      *ngIf="dataList?.records?.length > 0"
    >
      <p>
        {{ "lang_total" | language }}:
        {{ dataList?.pages?.total_records }}
      </p>
      <ngb-pagination
        [collectionSize]="dataList?.pages?.total_records"
        [rotate]="true"
        [maxSize]="3"
        [boundaryLinks]="true"
        [(page)]="page_no"
        [pageSize]="page_size"
        (pageChange)="getList()"
      ></ngb-pagination>
      <mat-select
        class="customselect"
        [(ngModel)]="page_size"
        (selectionChange)="page_no = 1; getList()"
        style="width: 120px"
      >
        <mat-option [value]="5">5 {{ "lang_per_page" | language }}</mat-option>
        <mat-option [value]="10"
          >10 {{ "lang_per_page" | language }}</mat-option
        >
        <mat-option [value]="25"
          >25 {{ "lang_per_page" | language }}</mat-option
        >
        <mat-option [value]="50"
          >50 {{ "lang_per_page" | language }}</mat-option
        >
      </mat-select>
    </div>
  </div>
  <div class="mt-3 mb-3 text-center" *ngIf="!dataList">
    <p style="font-size: 18px">{{ dataList_msg }}</p>
  </div>
</mat-dialog-content>
