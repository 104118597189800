import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import moment from "moment";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
	selector: "app-wafi-extra-data",
	templateUrl: "./wafi-extra-data.component.html",
	styleUrls: ["./wafi-extra-data.component.scss"],
})
export class WafiExtraDataComponent implements OnInit {
	@Input() projects_work_area_id;
	extraData;
	modalRef;
	extraDataForm: FormGroup;
	constructor(
		public modalService: BsModalService,
		private fb: FormBuilder,
		private spinner: PreloaderService,
		private ds: DataService,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		moment.locale("en");
		this.buildForm();
		this.getExtraData();
	}

	getExtraData() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		this.spinner.show();
		this.ds
			.getActionByUrl(
				this.ds.formData2string(formData),
				"pr/report/get_work_area_report_extra_data"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.extraData = res?.record[0];
						this.setFormData();
					} else {
						this.extraData = null;
						this.sweetAlert.errorToast(res?.error, 3000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
	}

	buildForm() {
		this.extraDataForm = this.fb.group({
			city: [this.extraData?.city ?? ""],
			region: [this.extraData?.region ?? ""],
			land_ownership: [this.extraData?.land_ownership ?? ""],
			land_area: [this.extraData?.land_area ?? ""],
			no_of_buildings: [this.extraData?.no_of_buildings ?? ""],
			type_of_units: [this.extraData?.type_of_units ?? ""],
			extend_project_days: [this.extraData?.extend_project_days ?? ""],
			revised_constractual_completion_date: [
				this.extraData?.revised_constractual_completion_date
					? new Date(this.extraData?.revised_constractual_completion_date)
					: null,
			],
			forecast_completion_date: [
				this.extraData?.forecast_completion_date
					? new Date(this.extraData?.forecast_completion_date)
					: null,
			],
		});
	}

	setFormData() {
		this.extraDataForm.patchValue({
			...this.extraData,
			revised_constractual_completion_date: this.extraData
				?.revised_constractual_completion_date
				? new Date(this.extraData?.revised_constractual_completion_date)
				: null,
			forecast_completion_date: this.extraData?.forecast_completion_date
				? new Date(this.extraData?.forecast_completion_date)
				: null,
		});
	}

	onStoreExtraData(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, environment.modelconfig);
	}

	onSubmit() {
		let formData = new FormData();
		formData.append("projects_work_area_id", this.projects_work_area_id || "");
		formData.append("city", this.extraDataForm.get("city").value || "");
		formData.append("region", this.extraDataForm.get("region").value || "");
		formData.append(
			"land_ownership",
			this.extraDataForm.get("land_ownership").value || ""
		);
		formData.append(
			"land_area",
			this.extraDataForm.get("land_area").value || ""
		);
		formData.append(
			"no_of_buildings",
			this.extraDataForm.get("no_of_buildings").value || ""
		);
		formData.append(
			"type_of_units",
			this.extraDataForm.get("type_of_units").value || ""
		);
		formData.append(
			"extend_project_days",
			this.extraDataForm.get("extend_project_days").value || ""
		);
		formData.append(
			"revised_constractual_completion_date",
			this.extraDataForm.get("revised_constractual_completion_date").value
				? moment(
						this.extraDataForm.get("revised_constractual_completion_date").value
				  ).format("YYYY/MM/DD")
				: ""
		);
		formData.append(
			"forecast_completion_date",
			this.extraDataForm.get("forecast_completion_date").value
				? moment(
						this.extraDataForm.get("forecast_completion_date").value
				  ).format("YYYY/MM/DD")
				: ""
		);
		this.spinner.show();
		this.ds.post("pr/report/add_edit_report_extra_data", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						3000
					);
					this.modalRef.hide();
					this.getExtraData();
				} else {
					this.sweetAlert.errorToast(res?.msg, 3000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					3000
				);
			}
		);
	}
}
