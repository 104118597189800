<h3 mat-dialog-title>{{ "lang_create_violation" | language }}</h3>

<mat-dialog-content class="mat-typography">
		<form [formGroup]="form">
			<div class="row">
				<div class="col-12 mt-1" *ngIf="type == 'confirm'">
					<!-- <label for="lang_nationality_type">{{ "lang_nationality_type" | language }}</label>
					<ng-select
					    [items]="metaData?.nationality_types"
						[multiple]="false"
						[bindLabel]="lang_key == 'en'? 'name_english' : 'name_arabic'"
						bindValue="id"
						appendTo="body"
						clearAllText="Clear"
						formControlName="nationality_type"
						placeholder="{{ 'lang_nationality_type' | language }}"
					>
					</ng-select> -->



					<label class="mb-10">
                        {{ "lang_user" | language }}
                        <span class="red"> * </span> </label>
    
                        <ng-select
                        [items]="users"
                        [multiple]="false"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                         
                        appendTo="body"
                        formControlName="direct_manager"
                        placeholder="{{ 'lang_user' | language }}"
                        (search)="search_users($event)"
                        
                        [searchFn]="ds.customSearchFn"
                         
                    >
                    </ng-select>
                    <mat-error *ngIf="
                    form.controls['direct_manager'].touched &&
                    form.controls['direct_manager'].errors
                        ?.required
                      ">
                    {{ "lang_field_required" | language }}
                    </mat-error>
                 
				</div>
	
				<div class="col-12 mt-1" *ngIf="type != 'confirm'">
					<!-- <label for="lang_nationality_type">{{ "lang_nationality_type" | language }}</label>
					<ng-select
					    [items]="metaData?.nationality_types"
						[multiple]="false"
						[bindLabel]="lang_key == 'en'? 'name_english' : 'name_arabic'"
						bindValue="id"
						appendTo="body"
						clearAllText="Clear"
						formControlName="nationality_type"
						placeholder="{{ 'lang_nationality_type' | language }}"
					>
					</ng-select> -->



					<label class="mb-10">
                        {{ "lang_note" | language }}
                         </label>
    
						 <textarea name=""
						 formControlName="cancel_note"
						 placeholder="{{ 'lang_note' | language }}"
						 class="form-control" id="" cols="30" rows="10"></textarea>
                        <!-- <ng-select
                        [items]="users"
                        [multiple]="false"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                         
                        appendTo="body"
                        formControlName="direct_manager"
                        placeholder="{{ 'lang_user' | language }}"
                        (search)="search_users($event)"
                        
                        [searchFn]="ds.customSearchFn"
                        >
                        </ng-select> -->
                    <mat-error *ngIf="
                    form.controls['cancel_note'].touched &&
                    form.controls['cancel_note'].errors
                        ?.required
                      ">
                    {{ "lang_field_required" | language }}
                    </mat-error>
                 
				</div>

			 
			</div>
		</form>
	</mat-dialog-content>
 
	<mat-dialog-actions>
		<div class="d-flex justify-content-between" style="width: 100%">
			
			<div style="width: 100px">
				<button type="button" class="albutton" (click)="resetFilterForm()">
					{{ "lang_cancel" | language }}
				</button>
			</div>
	
			<div style="width: 100px">
				<button
					mat-button
					class="albutton mnabrbutton mat-accent"
					(click)="sendFilterForm()"
				>
					{{ "lang_submit" | language }}
				</button>
			</div>
		</div>
	</mat-dialog-actions>
	