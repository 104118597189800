import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import * as Countries from '../../../../../shared/utils/countries_en'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-confirm-violation-card',
  templateUrl: './confirm-violation.component.html',
  styleUrls: ['./confirm-violation.component.scss']
})
export class ConfirmViolationComponent implements OnInit {
 
  @Input() projects :any =[];
  @Input() branches :any =[];
  @Input() metaData :any =[];
  form:FormGroup = this.fb.group({
   
    direct_manager:null,
   
  });
  lang_key = localStorage.getItem('lang_key') ? localStorage.getItem('lang_key') : 'en';
  users:any =[]
  public search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append('search', key.term);
    param.append('lang_key', this.lang_key);
    param.append('user_type_id', '1');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.users = res.list;
      }
    })
  }
  type = 'confirm'
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmViolationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: PreloaderService,public ds: DataService, private lang:LanguagePipe) { 
      this.type = this.data.type;
    }

  ngOnInit(): void {
  
    this.buildForm();
    
  }

  buildForm() {
    if(this.type == 'confirm'){
      this.form = this.fb.group({
     
        direct_manager:['',[Validators.required]]
      });
    }else{
      this.form = this.fb.group({
     
        cancel_note:['',[]]
      });
    }
   
  }

 

  sendFilterForm() {
   
    this.dialogRef.close({...this.form?.value,sendRequest:true});
  }

  resetFilterForm() {
     
    /*    this.filterForm.get('version').setValue('final_completed_versions'); */
    this.form.reset();
  }

  public isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if(charCode == 44) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
}
