import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CreateUpdateUserComponent } from './create-update-user/create-update-user.component';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
declare var $:any;
interface list {
  id?: number;
  user_id: string,
  user_username: string,
  user_email: string,
  user_phone: string,
  user_status: string,
  user_type_name_en: string,
  user_type_name_ar: string,
}
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit ,OnDestroy {
  modalRefA: BsModalRef;
  tabledatas: list[];
  collectionSize                    = 0;
  page                              = 1;
  pageSize                          = 10;
  platform_group_type_code_system   = null;
  projects_services_ids             = null;
  nodata: string                    = this.lang.transform('lang_loading');
  admin                             = JSON.parse(this.ls.getItem('is_admin'));
  lang_key                          = localStorage.getItem('lang_key');
  wholeData:any                     = [];
  tabledata:any                     = [];
  types:any                         = [];
  searchKey:any                     = "";
  userStatus:any                    = "";
  userType:any                      = null;
  is_admin:any                      = this.ls.getItem('is_admin');
  constructor(public ds:DataService, private sweetAlert: SweetAlertService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.get_group_types();
    this.get_user_list();
  }
  private subscriptions = new Subscription();
	ngOnDestroy(): void {
	  this.subscriptions.unsubscribe();
	}
  public get_group_types() {
    this.types = [];
    this.subscriptions.add(
        this.ds.postActionByUrl([], 'users/users_types/1').subscribe( res=> {
          if(res.status) {
            this.types = res.users_list;
          }
        })			
      )

  }  
  public get_user_list() {
    let param = new FormData();
    param.append('user_status',    this.userStatus || '');
    param.append('user_type_id',   this.userType   || '');
    param.append('search_key',     this.searchKey  || '');
    param.append('lang_key',       this.lang_key   || '');
    param.append('is_admin',       '');
    this.subscriptions.add(
        this.ds.getActionByUrl(this.ds.formData2string(param), `user/get_users_lists/${this.page}/${this.pageSize}`).subscribe(res=>{
          this.spinner.hide();
          if(res.status) {
            this.wholeData      = res;
            this.tabledata      = res.records;
            this.collectionSize = res.page?.total_records;
          } else {
            this.wholeData  = [];
            this.nodata     = res.error;
          }
        }, error => {
          this.spinner.hide();
          this.wholeData  = [];
          this.nodata     = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
        }) 			
      )

  }

  public checkallItemsUsl(event) {
    if (event.target.checked) {
      $(".appuserslists .eachItemUsl").prop("checked", true);
      $(".appuserslists .makeback").addClass("bground");
      $(".appuserslists .deleteIconDiv").show();
    } else {
      $(".appuserslists .eachItemUsl").prop("checked", false);
      $(".appuserslists .makeback").removeClass("bground");
      $(".appuserslists .deleteIconDiv").hide();
    }
  }
  public checkeachItemUsl(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appuserslists .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appuserslists .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appuserslists .eachItemUsl:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appuserslists .eachItemUsl").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appuserslists .deleteIconDiv").show();
    } else {
      $(".appuserslists .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appuserslists #allItemsUsl").prop("checked", true);
    } else {
      $(".appuserslists #allItemsUsl").prop("checked", false);
    }
  }
  public deleteBulkDataUsl() {
    const checkedtotal = [];
    $(".appuserslists .eachItemUsl:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      this.sweetAlert.confirmMessage(this.lang.transform('lang_are_you_sure'), this.lang.transform('lang_yes')).then(res => {
        if(res?.isConfirmed) {
          this.spinner.show()
          this.subscriptions.add(
                this.ds.deleteActionByUrl(checkedtotal, 'user/delete_users').subscribe(
                  (data) => {
                    this.spinner.hide()
                    if (data.status) {
                      this.get_user_list();
                      $(".appuserslists #allItemsUsl").prop("checked", false);
                      $(".appuserslists .deleteIconDiv").hide();
                      this.sweetAlert.successToast(data.msg, 2000);
                    } else {
                      this.sweetAlert.errorToast(data.error, 2000);
                    }
                  },
                  (error) => {
                    this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
                    this.spinner.hide()
                  }
                )			
            )

        }
      })
    } else {
      this.ds.dialogf();
    }
  }

  onAddUpdateUser(data?:any) {
    const dialogRef = this.dialog.open(CreateUpdateUserComponent,{
      width:'700px',
      data: {
        userData: data
      }
    });
    this.subscriptions.add(
        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.get_user_list();
          }
        })			
      )

  }
}
