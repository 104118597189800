import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-quotations-lists',
  templateUrl: './quotations-lists.component.html',
  styleUrls: ['./quotations-lists.component.scss']
})
export class QuotationsListsComponent implements OnInit {
  modalRefA: BsModalRef;
  modalRefR: BsModalRef;
  modalRefV: BsModalRef;
  @Input() project_data;
  @Input() service_data;
  @Input() filelevels;
  @Input() able2add;
  @Input() able2edit;
  @Input() able2delete;
  @Output() actionQuotEvent = new EventEmitter<any>();
  listData:any = [];
  pageno: number = 1;
  pagesize: any = '10';
  nodata: string = this.lang.transform('lang_loading');
  searchKey = "";
  approveform:FormGroup;
  rejectform:FormGroup;
  submitted = false;
  filename = this.lang.transform('lang_select_files');
  viewdata:any = [];
  constructor(public ds: DataService,public router:Router,public route :ActivatedRoute,public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
  ngOnInit(): void {
    this.approveform = new FormGroup({
      quotation_approval_receipt : new FormControl('', [Validators.required]),
      projects_quotation_id : new FormControl('', [Validators.required]),
    });
    this.rejectform = new FormGroup({
      quotation_rejected_reason : new FormControl('', [Validators.required]),
      projects_quotation_id : new FormControl('', [Validators.required]),
    });
    this.load_supervision_quotations();
  }
  public search_supervision_quotations() {
    this.pageno = 1;
    this.pagesize = '10';
    this.load_supervision_quotations();
  }
  public load_supervision_quotations_page(page) {
    this.spinner.show();
    this.pageno = page;
    this.pagesize = this.pagesize;
    this.load_supervision_quotations();
  }
  public load_supervision_quotations_pagesize(size) {
    this.spinner.show();
    this.pageno = 1;
    this.pagesize = size;
    this.load_supervision_quotations();
  }
  public load_supervision_quotations() {
    let formdata = new FormData();
    formdata.append('search_key', this.searchKey);    
    this.ds.getActionByUrl(this.ds.formData2string(formdata), 'squotest/' + this.project_data.projects_profile_id+'/'+ this.pageno + '/' + this.pagesize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.listData = res;
      } else {
        this.listData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.listData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public openArroveModal(template: TemplateRef<any>, data) {
    this.submitted = false;
    this.approveform.reset();
    this.alert.clear();
    this.filename = this.lang.transform('lang_select_files');
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
    this.approveform.get('projects_quotation_id').setValue(data.projects_quotation_id);
  }  
  public openRejectModal(template: TemplateRef<any>, data) {
    this.submitted = false;
    this.alert.clear();
    this.rejectform.reset();
    this.modalRefR = this.modalService.show(template, environment.modelconfig);
    this.rejectform.get('projects_quotation_id').setValue(data.projects_quotation_id);
  }
  public openViewModal(template: TemplateRef<any>, data) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
    this.viewdata = data;
  }
  public uploadFrontFile(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			const fileid = event.target.files[0];
			this.filename = fileid.name;
			this.approveform.get("quotation_approval_receipt").setValue(fileid, {
				emitEvent: true
			});
		} else {
			this.approveform.get("quotation_approval_receipt").setValue(null);
			this.filename = this.lang.transform('lang_select_files');
		}
	}
  public approveQuotation() {
    this.submitted = true;
    if(this.approveform.valid) {
      this.submitted = false;
      this.spinner.show();
      let param = this.ds.json2formdata(this.approveform.value);
      param.append('projects_profile_id', this.project_data.projects_profile_id);
      this.ds.postActionByUrl(param, '4uDWsYXRrU').subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.actionQuotEvent.emit({quot : true});
          this.alert.success(res.msg);
          this.approveform.reset();
          this.search_supervision_quotations();
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    }
  }
  public rejectQuotation() {
    this.submitted = true;
    if(this.rejectform.valid) {
      this.submitted = false;
      this.spinner.show();
      let param = this.ds.json2formdata(this.rejectform.value);
      param.append('projects_profile_id', this.project_data.projects_profile_id);
      this.ds.postActionByUrl(param, 'GnkoLefoWN').subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.alert.success(res.msg);
          this.rejectform.reset();
          this.search_supervision_quotations();
          setTimeout(() => {
            this.modalRefR.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    }
  }
}
