import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";

@Component({
  selector: "app-general-ledger",
  templateUrl: "./general-ledger.component.html",
  styleUrls: ["./general-ledger.component.scss"],
})
export class GeneralLedgerComponent implements OnInit, OnDestroy {
  form: FormGroup;
  branches: any = [];
  finances: any = [];
  lodingdatas = this.lang.transform("lang_loading");
  fromaccounts: any = [];
  toaccounts: any = [];
  finance_id: any = null;
  datastatus = false;
  data: any = [];
  settings = environment.singledropdown;
  branch_id: any =
    localStorage.getItem("selectedBranch") != null
      ? localStorage.getItem("selectedBranch")
      : "0";
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    public sweetAlert: SweetAlertService
  ) {}
  ngOnInit(): void {
    this.buildform();
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != "0") {
      this.load_financial_years();
      this.getParentAccounts({ term: "" });
    }
  }
  public selectBrach(branch) {
    this.data = [];
    this.datastatus = false;
    if (branch && branch != "0") {
      localStorage.setItem("selectedBranch", branch);
      this.branch_id = branch;
      this.load_financial_years();
      this.getParentAccounts({ term: "" });
    } else {
      this.spinner.hide();
    }
  }
  public selectFinanceYear(financeid) {
    if (financeid && financeid != "0") {
      this.finance_id = financeid;
    }
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public load_financial_years() {
    this.finances = [];
    this.form.get("finance_id").setValue(null);
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.form.get("finance_id").setValue(v.finance_id);
                this.form.get("period_from").setValue(v.finance_start_date);
                this.form.get("period_to").setValue(v.finance_end_date);
              }
            });
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      finance_id: new FormControl(null, [Validators.required]),
      request_type: new FormControl("0", [Validators.required]),
      // account_code_from: new FormControl(null),
      // account_code_to: new FormControl(null),
      period_from: new FormControl(null, [Validators.required]),
      period_to: new FormControl(null, [Validators.required]),
    });
  }
  public getParentAccounts(event) {
    let param = new FormData();
    param.append("branch_id", this.branch_id);
    param.append("finance_id", this.form.get("finance_id").value || "");
    param.append("search_text", event?.term || "");
    param.append("account_parent", "0");
    this.fromaccounts = [];
    if (this.branch_id) {
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "sam/accounts_view").subscribe(
          (data) => {
            if (data.status) {
              this.fromaccounts = data.records;
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
      );
    }
  }
  public fetch_statements() {
    this.spinner.show();
    let param = new FormData();
    param.set("branch_id", this.form.get("branch_id").value);
    param.set("finance_id", this.form.get("finance_id").value || 0);
    param.set("request_type", this.form.get("request_type").value);
    // if (this.form.get("request_type").value == "0") {
    //   param.set(
    //     "account_code_from",
    //     this.form.get("account_code_from").value
    //       ? this.form.get("account_code_from").value
    //       : ""
    //   );
    //   param.set(
    //     "account_code_to",
    //     this.form.get("account_code_to").value
    //       ? this.form.get("account_code_to").value
    //       : ""
    //   );
    // }
    param.set(
      "period_from",
      this.form.get("period_from").value != null
        ? this.form.get("period_from").value
        : ""
    );
    param.set(
      "period_to",
      this.form.get("period_to").value != null
        ? this.form.get("period_to").value
        : ""
    );
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "general_ledger").subscribe(
        (res) => {
          this.datastatus = res.status;
          this.spinner.hide();
          if (res.status && res.records.length > 0) {
            this.data = res.records;
          } else {
            this.lodingdatas = this.lang.transform("lang_no_data");
            this.data = [];
            this.ds.dialogf("", res.error);
          }
        },
        (error) => {
          this.lodingdatas =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
          this.datastatus = false;
          this.data = [];
          this.spinner.hide();
          this.ds.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public absolute(amount) {
    let famount: any = Math.abs(amount);
    return amount == "0.00" ? "0.00" : this.ds.addCommas(parseFloat(famount));
  }

  export_exl() {
    let param = new FormData();
    param.set("branch_id", this.form.get("branch_id").value);
    param.set("finance_id", this.form.get("finance_id").value);
    param.set("request_type", this.form.get("request_type")?.value);
    // param.set("account_code_from", this.form.get("account_code_from")?.value);
    // param.set("account_code_to", this.form.get("account_code_to")?.value);
    param.set("period_from", this.form.get("period_from")?.value);
    param.set("period_to", this.form.get("period_to")?.value);
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "accounts/general_ledger_excal").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.downloadFile(res, "gender_excal");
          } else {
            this.sweetAlert.errorToast(res?.error, 2000);
          }
        },
        (error) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error"),
            2000
          );
        }
      )
    );
  }
  downloadFile(data, title?, type?) {
    if (data.status) {
      let url = data.base64;
      let tagA = document.createElement("a");
      document.body.appendChild(tagA);
      tagA.style.display = "none";
      tagA.href = url;
      if (type == "blank") {
        tagA.setAttribute("target", "_blank");
      }
      tagA.download = title || "Almnabr";
      tagA.click();
      document.body.removeChild(tagA);
    }
  }
}
