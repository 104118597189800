<div class="card">
	<div class="width100">
		<i
			*ngIf="files && files.con"
			matTooltip="{{ 'lang_recent_attachment' | language }} : {{ files.con_d }}"
			class="fa fa-paperclip s2icon mnabricon"
			(click)="ds.preview_pdf_file(files.con)"
		></i>
		<div class="card-header">
			{{ "lang_human_resources_contact_details" | language }}
		</div>
		<div class="card-body pad0" [formGroup]="signupForm">
			<div class="mbrl15" formGroupName="contactDetails">
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<div class="form-group">
							<label for="primary_mobile"
								>{{ "lang_human_resources_primary_mobile" | language }} :
								<span class="red"> * (EX : 966555555555)</span></label
							>
							<input
								formControlName="primary_mobile"
								minlength="12"
								maxlength="12"
								id="primary_mobile"
								type="text"
								placeholder="{{
									'lang_human_resources_primary_mobile' | language
								}}"
								class="form-control almnabrformcontrol primary_mobile"
								(keypress)="ds.numberOnly($event)"
							/>
							<mat-error
								*ngIf="
									signupForm.get('contactDetails')['controls']['primary_mobile']
										.touched &&
									signupForm.get('contactDetails')['controls']['primary_mobile']
										.errors?.required
								"
								>{{ "lang_field_required" | language }}</mat-error
							>
							<mat-error
								*ngIf="
									signupForm.get('contactDetails')['controls']['primary_mobile']
										.errors?.minlength
								"
								>{{ "lang_minimum" | language }} 12</mat-error
							>
							<mat-error
								*ngIf="
									signupForm.get('contactDetails')['controls']['primary_mobile']
										.errors?.maxlength
								"
								>{{ "lang_maximum" | language }} 12</mat-error
							>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<div class="form-group">
							<label for="primary_email"
								>{{ "lang_human_resources_primary_email" | language }} :
								<span class="red">*</span></label
							>
							<input
								formControlName="primary_email"
								id="primary_email"
								type="text"
								placeholder="{{
									'lang_human_resources_primary_email' | language
								}}"
								class="form-control almnabrformcontrol primary_email"
							/>
							<mat-error
								*ngIf="
									signupForm.get('contactDetails')['controls']['primary_email']
										.touched &&
									signupForm.get('contactDetails')['controls']['primary_email']
										.errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
							<mat-error
								*ngIf="
									signupForm.get('contactDetails')['controls']['primary_email']
										.touched &&
									signupForm.get('contactDetails')['controls']['primary_email']
										.errors?.email
								"
							>
								{{ "lang_field_valid_email" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<div class="form-group">
							<label for="primary_address"
								>{{ "lang_human_resources_primary_address" | language }} :
								<span class="red">*</span></label
							>
							<textarea
								formControlName="primary_address"
								id="primary_address"
								type="text"
								class="form-control almnabrformcontrol primary_address"
								placeholder="{{
									'lang_human_resources_primary_address' | language
								}}"
							></textarea>
							<mat-error
								*ngIf="
									signupForm.get('contactDetails')['controls'][
										'primary_address'
									].touched &&
									signupForm.get('contactDetails')['controls'][
										'primary_address'
									].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<label for="contact_file_name"
								>{{ "lang_human_resources_filename" | language }} :
							</label>
							<input
								formControlName="contact_file_name"
								id="contact_file_name"
								type="text"
								placeholder="{{ 'lang_human_resources_filename' | language }}"
								class="form-control almnabrformcontrol contact_file_name"
							/>
						</div>
					</div>
					<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
						<div class="form-group">
							<div *ngIf="!isFile" class="opacity0" for="address_file">
								<i aria-hidden="true" class="fa faicon fa-close red">*</i>
							</div>
							<div *ngIf="isFile" for="address_file">
								<i
									aria-hidden="true"
									(click)="removeFile()"
									class="fa faicon fa-close red"
								></i>
							</div>
							<div class="IDDIV">
								<input
									name="address_file"
									type="file"
									onclick="this.value = null"
									(change)="uploadIDFile($event)"
									class="nghide width100 albutton"
									accept=".pdf"
								/>
								<label id="uploadIDButton" class="albutton"
									>{{ idfilename }}
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer padb0">
				<div class="row">
					<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
						<button type="button" class="albutton" matStepperPrevious>
							{{ "lang_previous" | language }}
						</button>
					</div>
					<div class="col-md-8 col-sm-6 col-xs-12 mb-10"></div>
					<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
						<button
							type="button"
							class="albutton"
							matStepperNext
							(click)="rstep4Submitted()"
						>
							{{ "lang_next" | language }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
