import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
	selector: "app-template-list",
	templateUrl: "./template-list.component.html",
	styleUrls: ["./template-list.component.scss"],
})
export class TemplateListComponent implements OnInit {
	module_key = this.route.snapshot.paramMap.get("module_id");
	lang_key = localStorage.getItem("lang_key") || "en";
	user_id = this.ls.getItem("user_id");
	module_name = "";
	templates = [];
	constructor(
		private ds: DataService,
		private route: ActivatedRoute,
		private spinner: PreloaderService,
		private ls: LocalStorage,
		private sweetAlert: SweetAlertService,
		private lang: LanguagePipe
	) {}

	ngOnInit(): void {
		this.getTemplates();
	}

	getTemplates() {
		this.templates = [];
		this.spinner.show();
		this.ds.post("tasks/get_temp_tickets", {}).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.templates = res?.data;
					switch (this.module_key) {
						case "projects": {
							this.module_name = this.lang.transform("lang_projects");
							this.templates = this.templates.filter(
								(el) => el.ref_model == this.module_key
							);
							break;
						}
						case "human_resources": {
							this.module_name = this.lang.transform("lang_human_resources");
							this.templates = this.templates.filter(
								(el) => el.ref_model == this.module_key
							);
							break;
						}
						case "contractors": {
							this.module_name = this.lang.transform("lang_contractors");
							this.templates = this.templates.filter(
								(el) => el.ref_model == this.module_key
							);
							break;
						}
						case "customers": {
							this.module_name = this.lang.transform("lang_customers");
							this.templates = this.templates.filter(
								(el) => el.ref_model == this.module_key
							);
							break;
						}
						case "suppliers": {
							this.module_name = this.lang.transform("lang_suppliers");
							this.templates = this.templates.filter(
								(el) => el.ref_model == this.module_key
							);
							break;
						}
						case "tasks": {
							this.module_name = this.lang.transform("lang_tasks");
							this.templates = this.templates.filter(
								(el) => el.ref_model == this.module_key
							);
							break;
						}
					}
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);
	}

	//toggle template from private to public and vice versa
	toggleTemplatePrivate(event, id, status) {
		event.stopPropagation();
		this.spinner.show();
		let formData = new FormData();
		formData.append("temp_id", id || "");
		if (status == "1") {
			formData.append("status", "2");
		} else if (status == "2") {
			formData.append("status", "1");
		}
		this.ds.post("tasks/update_private_public", formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res?.status) {
					this.sweetAlert.successToast(res?.data?.msg, 2000);
					this.getTemplates();
				} else {
					this.sweetAlert.errorToast(res?.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
}
