<div class="mbrl15">
	<div class="row">
		<div class="col-12 col-sm-3 mb-2">
			<button
				class="albutton mnabrbutton"
				mat-raised-button
				(click)="acceptedUnacceptedUnits()"
			>
				<i class="fa fa-building-o"></i>
				{{ "lang_available_units" | language }}
			</button>
		</div>
	</div>
	<div class="row">
		<div class="col-12 mb-10">
			<div class="input-group input-group-sm">
				<input
					type="text"
					class="form-control almnabrformcontrol"
					[(ngModel)]="searchKey"
					(keyup)="get_initial_delivery_list()"
					placeholder="{{ 'lang_search' | language }}"
				/>
				<span class="input-group-btn" *ngIf="makeActionInitial">
					<button
						id="btnSearch"
						type="button"
						matTooltip="{{ 'lang_add' | language }}"
						class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
						(click)="createUpdateInitialDelivery()"
					></button>
				</span>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_units" | language }}</td>
							<td>{{ "lang_writer" | language }}</td>
							<td>{{ "lang_ondate" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody *ngIf="initial_delivery_list.length" class="AppendList">
						<tr
							*ngFor="
								let list of initial_delivery_list;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ list.unit_general_numbers }}</td>
							<td>{{ list.writer }}</td>
							<td>{{ list.created_datetime }}</td>
							<td>
								<i
									class="fa fa-trash red"
									(click)="deleteInitialDelivery(list?.initial_delivery_id)"
									matTooltip="{{ 'lang_delete' | language }}"
									*ngIf="makeActionInitial"
								></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
<div
	*ngIf="initial_delivery_list.length"
	class="padb0 card-footer padb0 ng-star-inserted"
>
	<div class="row responsive_pagination">
		<div
			class="col-lg-3 col-sm-3 mb-10"
			style="height: fit-content; margin: auto 0"
		>
			{{ "lang_total" | language }} : {{ collection_size }}
		</div>
		<div
			class="col-lg-5 col-sm-5 mb-10"
			style="display: flex; justify-content: center"
		>
			<ngb-pagination
				class="m-auto"
				[collectionSize]="collection_size"
				[rotate]="true"
				[ellipses]="false"
				[maxSize]="3"
				[boundaryLinks]="true"
				[(page)]="page_no"
				[pageSize]="page_size"
				(pageChange)="get_initial_delivery_list()"
			></ngb-pagination>
		</div>
		<div
			class="col-lg-3 col-sm-3"
			style="display: flex; justify-content: end; margin: auto"
		>
			<mat-select
				[(ngModel)]="page_size"
				(selectionChange)="get_initial_delivery_list()"
			>
				<mat-option class="text-center" [value]="10"
					>10 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" [value]="20"
					>20 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" [value]="50"
					>50 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" [value]="100"
					>100 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option class="text-center" [value]="500"
					>500 {{ "lang_per_page" | language }}</mat-option
				>
			</mat-select>
		</div>
	</div>
</div>
<no-data
	style="margin: auto"
	[Opened]="initial_delivery_list.length < 1"
></no-data>
