import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
declare var $: any;
@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
  apiurl = environment.SERVER_ORIGIN;
  public contctform: FormGroup;
  @Input() able2edit;
  @Input() able2delete;
  @Input() able2add;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  @Input() types;
  @Input() requestfor;
  @Input() user_data;
  @Input() attachments;
  @Output() resfreshview_details2 = new EventEmitter<any>();
  wholedata: any = [];
  cusers: any = [];
  cltotal: number = 0;
  cltotalpage: number = 0;
  cwholddata: any = [];
  cerrormessagef: any = this.lang.transform("lang_loading");
  pagedata: any = [];
  contactdata: any = [];
  cpage = 1;
  csize = "10";
  modalRefA: BsModalRef;
  modalRefV: BsModalRef;
  searchKey = "";
  expandstatus = false;
  constructor(
    public router: Router,
    public ds: DataService,
    public route: ActivatedRoute,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.expandstatus = this.requestfor == "profile" ? true : false;
    this.contactform();
    this.get_employee_contacts();
  }
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  get f() {
    return this.contctform;
  }
  public emptycontactform() {
    this.alert.clear();
    this.contctform.reset();
  }
  public contactform() {
    this.contctform = new FormGroup({
      contact_id: new FormControl(""),
      contact_person_name: new FormControl("", [Validators.required]),
      contact_mobile_number: new FormControl(""),
      contact_email_address: new FormControl("", [Validators.email]),
      contact_address_text: new FormControl("", []),
    });
  }
  public contacts_operations() {
    this.spinner.show();
    const formData = this.ds.json2formdata(this.contctform.value);
    formData.append("branch_id", this.branch_id);
    formData.append("id", this.id);
    formData.append("employee_id_number", this.user_data.employee_id_number);
    let url = this.contctform.get("contact_id").value
      ? "human_resources/update_contacts"
      : "human_resources/add_contacts";
    this.ds.postActionByUrl(formData, url).subscribe(
      (data) => {
        this.spinner.hide();
        if (data.status) {
          this.contctform.reset();
          this.get_employee_contacts();
          this.alert.success(data.msg);
          setTimeout(() => {
            this.modalRefA.hide();
          }, 2000);
        } else {
          this.alert.error(data.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.alert.error(
          error.error + " " + (error && error.error && error.error.error)
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  public loadbypagenumber(size) {
    this.csize = size;
    this.get_employee_contacts();
  }
  public loadbypagelength(page) {
    this.cpage = page;
    this.get_employee_contacts();
  }
  public load_employee_contacts() {
    this.csize = "10";
    this.cpage = 1;
    this.get_employee_contacts();
  }
  public get_employee_contacts() {
    const param = new FormData();
    param.append("searchKey", this.searchKey || "");
    param.append("branch_id", this.branch_id);
    param.append("id", this.id);
    param.append("employee_id_number", this.user_data.employee_id_number);
    let url =
      this.requestfor === "profile"
        ? "my_contacts"
        : "human_resources/get_employee_contacts";
    this.ds
      .postActionByUrl(param, url + "/" + this.cpage + "/" + this.csize)
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.wholedata = data;
          } else {
            this.wholedata = [];
            this.cerrormessagef = data.error;
          }
        },
        (error) => {
          this.spinner.hide();
          this.wholedata = [];
          this.cerrormessagef =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
  }
  public loadContact(data, type, template) {
    this.emptycontactform();
    this.contactdata = data;
    if (type == "edit") {
      this.contctform.patchValue({
        contact_id: data.contact_id,
        contact_person_name: data.contact_person_name,
        contact_mobile_number: data.contact_mobile_number,
        contact_email_address: data.contact_email_address,
        contact_address_text: data.contact_address_text,
      });
      //this.openModalA(template);
    } else {
      //this.openModalV(template);
    }
  }
  public checkAllCon(event) {
    if (event.target.checked) {
      $(".appcontacts .eachItemCon").prop("checked", true);
      $(".appcontacts .makeback").addClass("bground");
      $(".appcontacts .deleteIconDiv").show();
    } else {
      $(".appcontacts .eachItemCon").prop("checked", false);
      $(".appcontacts .makeback").removeClass("bground");
      $(".appcontacts .deleteIconDiv").hide();
    }
  }
  public checkEachItemCon(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appcontacts .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appcontacts .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appcontacts .eachItemCon:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appcontacts .eachItemCon").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appcontacts .deleteIconDiv").show();
    } else {
      $(".appcontacts .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appcontacts #allItemsCon").prop("checked", true);
    } else {
      $(".appcontacts #allItemsCon").prop("checked", false);
    }
  }
  public deleteBulkDataCon() {
    const checkedtotal = [];
    $(".appcontacts .eachItemCon:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true,
        width: "400px",
        data: {
          title: this.lang.transform("lang_confirmation"),
          message: this.lang.transform("lang_are_you_sure"),
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          this.ds
            .deleteActionByUrl(
              checkedtotal,
              "01f5086b879a62a05da4094dac203558/CONTACT/" +
                this.id +
                "/" +
                this.branch_id
            )
            .subscribe(
              (data) => {
                this.spinner.hide();
                if (data.status) {
                  this.load_employee_contacts();
                  $(".appcontacts #allItemsCon").prop("checked", false);
                  $(".appcontacts .deleteIconDiv").hide();
                  this.ds.dialogf("", data.msg);
                } else {
                  this.ds.dialogf("", data.error);
                }
              },
              (error) => {
                this.ds.dialogf(
                  "",
                  error && error.error && error.error.error
                    ? error.error.error
                    : this.lang.transform("lang_internal_server_error")
                );
                this.spinner.hide();
              }
            );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
  resfreshview_details() {
    this.resfreshview_details2.emit();
  }
}
