import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-supervision-wafi-report",
	templateUrl: "./supervision-wafi-report.component.html",
	styleUrls: ["./supervision-wafi-report.component.scss"],
})
export class SupervisionWafiReportComponent implements OnInit {
	@Input() supervision_data: any;
	@Input() able2add: any;
	@Input() able2edit: any;
	@Input() project_data: any;
	@Input() makeActionGroups: any;
	@Input() makeActionInitial: any;
	@Input() makeActionOperations: any;
	selected_tab = "wafi_settings";
	constructor() {}

	ngOnInit(): void {}

	selectedListTabValue(e) {
		if (e?.tab?.textLabel == "wafi_settings") {
			this.selected_tab = "wafi_settings";
		}
	}
}
