import { DatePipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	Validators,
	FormArray,
	FormControl,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import * as moment from "moment";

declare var $: any;

@Component({
	selector: "app-createLOAN",
	templateUrl: "./createLOAN.component.html",
	styleUrls: ["./createLOAN.component.scss"],
})
export class CreateLOANComponent implements OnInit {
	@Output() loanEvent = new EventEmitter();
	@Input() employee_data;
	@Input() form_request_id;
	@Input() form_key;
	users: any = [];
	direct_anager: any = [];
	CreateSubmitted: boolean = false;
	min = new Date();
	form: FormGroup;
	submitted: boolean = false;
	lang_key = localStorage.getItem("lang_key") || "en";

	filename = this.lang.transform("lang_select_files");
	isFile: boolean = false;
	reasons: any[] = [
		{
			label: "سلفة لغرض سداد رسوم المرافقين",
			value: "سلفة لغرض سداد رسوم المرافقين",
		},
		{
			label: "سلفة لغرض الزواج",
			value: "سلفة لغرض الزواج",
		},
		{
			label: "سلفة لغرض ترقية فئة التأمين",
			value: "سلفة لغرض ترقية فئة التأمين",
		},
		{
			label: "سلفة لغرض رسوم مدارس",
			value: "سلفة لغرض رسوم مدارس",
		},
		{
			label: "سلفة لغرض خاص",
			value: "سلفة لغرض خاص",
		},
		{
			label: "سلفة لأسباب اخرى",
			value: "سلفة لأسباب اخرى",
		},
	];
	attachments: FormArray;
	attachstatus: boolean = false;
	showAttachment = true;
	emp_data: any = "";
	requestFor = "";
	emp_number;
	constructor(
		public ds: DataService,
		public datepipe: DatePipe,
		private snackBar: MatSnackBar,
		public lang: LanguagePipe,
		public spinner: PreloaderService,
		public alert: AlertService,
		public fb: FormBuilder,
		public router: Router,
		private route: ActivatedRoute,
		public changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params) => {
			this.requestFor = params.from;
			if (params.emp_id) {
				this.emp_number = params.emp_id;
			}
		});
		let data = new FormData();
		data.append("employee_number", this.employee_data?.employee_number);
		let url = "human_resources/get_emp_details";
		if (this.requestFor == "profile") {
			url = "form/FORM_HRLN1/get_emp_details";
		}
		this.spinner.show();
		this.ds.post(url, this.requestFor == "profile" ? {} : data).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.emp_data = res.records;
				} else {
					this.spinner.hide();
					this.showMsg(
						res.error || this.lang.transform("lang_employee_have_no_contract"),
						"ok",
						"error-snackbar"
					);
					this.loanEvent.emit();
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);

		this.ds.get(`form/FORM_HRLN1/get_loan_reasons`, data).subscribe(
			(res) => {
				this.spinner.hide();

				if (res.status) {
					this.reasons = res.records;
				} else {
					this.alert.error(res.error);
				}
			},
			(err) => {
				this.spinner.hide();
			}
		);

		this.form = this.fb.group({
			financial_type: [this.lang.transform("lang_loan")],
			employee_number: ["123"],
			loan_date_english: ["", Validators.required],
			loan_date_arabic: ["", Validators.required],
			loan_amount: ["", Validators.required],
			needAttach: ["0", Validators.required],
			reasons: [null, Validators.required],
			attachments: this.fb.array([]),
			direct_manager: [
				{ value: null, disabled: this.direct_anager.length > 0 ? true : false },
				[Validators.required],
			],
			createInstallments: this.fb.group({
				amount: ["", Validators.compose([Validators.required])],
				due_month: ["", Validators.compose([])],
				due_year: ["", Validators.compose([])],
				error_message: [""],
				month: [null, Validators.compose([Validators.required])],
				month_salary: ["", Validators.compose([])],
			}),
			installments: this.fb.array([]),
		});
		this.attachments = this.form.get("attachments") as FormArray;
		this.spinner.hide();
	}

	public enable_field(field) {
		if (field == "attachment") {
			this.attachments.clear();
			this.form.get("needAttach").setValue("1");
			this.addfiles();
			this.attachstatus = true;
		}
	}

	public files(): FormGroup {
		return this.fb.group({
			attach_title: new FormControl(
				"",
				this.form.get("needAttach").value == "1" ? [Validators.required] : null
			),
			file: new FormControl(
				"",
				this.form.get("needAttach").value == "1" ? [Validators.required] : null
			),
		});
	}
	public addfiles() {
		if (this.attachments.length < 5) {
			this.attachments.push(this.files());
		}
	}
	public removefiles(index) {
		if (this.attachments.length > 1) {
			this.attachments.removeAt(index);
		}
	}

	public getfilesgroup(index): FormGroup {
		const formGroup = this.attachments.controls[index] as FormGroup;
		return formGroup;
	}

	public UploadFile(event, index) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			const fileid = event.target.files[0];
			$("#uploadText" + index).text(fileid.name);
			this.attachments.controls[index]
				.get("file")
				.setValue(fileid, { emitEvent: true });
		} else {
			this.attachments.controls[index].get("file").setValue(null);
			$("#uploadText" + index).text(this.lang.transform("lang_select_files"));
		}
	}
	public uploadFile(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			this.isFile = true;
			const fileid = event.target.files[0];
			this.filename = fileid.name;
			this.form.get("upload").setValue(fileid, { emitEvent: true });
		} else {
			this.isFile = false;
			this.form.get("upload").setValue(null);
			this.filename = this.lang.transform("lang_select_files");
		}
	}
	public removeFile() {
		this.isFile = false;
		this.form.get("upload").setValue(null);
		this.filename = this.lang.transform("lang_select_files");
	}
	public disable_field(field) {
		if (field == "attachment") {
			this.form.get("needAttach").setValue("0");
			this.isFile = false;
			this.attachstatus = false;
		}
		this.form.updateValueAndValidity();
	}
	get filesFormGroup() {
		return this.form.get("attachments") as FormArray;
	}
	get getAttachments() {
		return this.form.get("attachments");
	}
	getLoanDates(event) {
		this.spinner.show();
		let formData = new FormData();
		formData.append(
			"employee_number",
			this.employee_data?.employee_number || this.emp_number
		);
		formData.append("loan_date", new Date(event.target.value).toISOString());

		this.ds
			.post(
				`form/${this.form_key || "FORM_HRLN1"}/get_contract_months`,
				formData
			)
			.subscribe(
				(data) => {
					if (data.status) {
						this.alert.success(data.msg);
						this.monthes = data["records"].map((mon) => {
							return {
								label: mon,
								value: mon,
							};
						});
						this.monthesAfterSearch = data["records"].map((mon) => {
							return {
								label: mon,
								value: mon,
							};
						});
						this.spinner.hide();
					} else {
						this.spinner.hide();
						this.alert.error(data?.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(error);
				}
			);
	}

	getThisMonthSalary(event) {
		this.spinner.show();
		let formData = new FormData();
		formData.append(
			"employee_number",
			this.employee_data?.employee_number || this.emp_number
		);
		moment.locale("en");
		formData.append(
			"finance_year",
			event?.value ? moment(event?.value, "MM-YYYY").format("yyyy") : ""
		);
		formData.append(
			"finance_month",
			event?.value ? moment(event?.value, "MM-YYYY").format("mm") : ""
		);
		this.ds
			.post(
				`form/${this.form_key || "FORM_HRLN1"}/check_salary_for_month`,
				formData
			)
			.subscribe(
				(data) => {
					this.alert.success(data.msg);
					this.form.controls.createInstallments.patchValue({
						...this.form?.controls?.createInstallments.value,
						month_salary: data.salary,
					});
					this.spinner.hide();
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(error);
				}
			);
	}

	monthes = [];

	monthesAfterSearch = [];

	checkFor50Percent() {
		const val = this.form.get("createInstallments") as FormGroup;

		if (
			val.value.amount &&
			val.value.month_salary &&
			val.value.month_salary - +val.value.amount <
				+this.emp_data.basic_salary / 2
		) {
			val.patchValue({
				...val.value,
				error_message: "lang_50_percent_error_message",
			});
		} else {
			val.patchValue({
				...val.value,
				error_message: "",
			});
		}
	}

	getControl(index: number) {
		return this.form.controls.installments["controls"][index] as FormGroup;
	}

	getItems(index: any) {
		let data = this.form.get("installments") as FormArray;

		return this.monthesAfterSearch.push({
			label: data.value[index].month,
			value: data.value[index].month,
		});
	}

	addInstallments() {
		this.CreateSubmitted = true;
		if (this.form?.controls?.createInstallments.status == "VALID") {
			if (!this.form?.value.loan_amount) {
				this.showMsg(
					"Please enter the loan you want first",
					"ok",
					"error-snackbar"
				);
				return 0;
			}

			const creds = this.form.get("installments") as FormArray;

			const val = this.form.get("createInstallments") as FormGroup;

			let totalValuesOfLoanPerMonthes = 0;
			for (let index = 0; index < creds.length; index++) {
				const element = creds["controls"][index].value;
				totalValuesOfLoanPerMonthes += Number(element.amount);
			}

			if (
				this.form?.value.loan_amount &&
				totalValuesOfLoanPerMonthes > this.form?.value.loan_amount
			) {
				this.showMsg(
					"Total amount values at all monthes must be equal to your loan value",
					"ok",
					"error-snackbar"
				);
				return 0;
			}

			if (
				val.value.amount &&
				val.value.month_salary &&
				+val.value.amount > +val.value.month_salary
			) {
				this.showMsg(
					"Your amout must not be bigger than your salary!",
					"ok",
					"error-snackbar"
				);
				return 0;
			}

			creds.push(
				this.fb.group({
					amount: [val.value.amount, Validators.compose([Validators.required])],
					due_month: [val.value.due_month, Validators.compose([])],
					due_year: [val.value.due_year, Validators.compose([])],
					errorMessage: [""],
					month: [val.value.month, Validators.compose([Validators.required])],
					month_salary: [val.value.month_salary, Validators.compose([])],
					trackingId: this.generateUniqueId(),
				})
			);

			this.monthesAfterSearch = this.monthesAfterSearch.filter((data) => {
				return data.value != val.value.month;
			});
			val.patchValue({
				month: this.monthesAfterSearch[0]
					? this.monthesAfterSearch[0].value
					: "",
			});
			val.reset();
			this.CreateSubmitted = false;
		} else {
			this.showMsg("Please fill all fields first", "ok", "error-snackbar");
		}
	}

	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 4000,
			panelClass: [className],
		});
	}
	trackByFnCustom(index: number, item: any) {
		return item.trackingId;
	}
	generateUniqueId() {
		return (
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15)
		);
	}

	mon;

	deleteCreds(index, cred) {
		const creds = this.form.get("installments") as FormArray;

		this.monthesAfterSearch = [
			...this.monthesAfterSearch,
			{
				label: cred.month,
				value: cred.month,
			},
		];
		creds.removeAt(index);
		this.changeDetectorRef.detectChanges();
	}

	public isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 101 || charCode == 69) {
			return false;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	submitForm() {
		this.form.controls.createInstallments.disable();
		if (this.form?.status == "VALID") {
			const creds = this.form.get("installments") as FormArray;

			const val = this.form.get("createInstallments") as FormGroup;

			let totalValuesOfLoanPerMonthes = 0;
			for (let index = 0; index < creds.length; index++) {
				const element = creds["controls"][index].value;
				totalValuesOfLoanPerMonthes += Number(element.amount);
			}

			if (
				this.form?.value.loan_amount &&
				totalValuesOfLoanPerMonthes != this.form?.value.loan_amount
			) {
				this.showMsg(
					"Total amount values at all monthes must be equal to your loan value",
					"ok",
					"error-snackbar"
				);
				this.form.controls.createInstallments.enable();
				return 0;
			}

			this.spinner.show();
			let formData = new FormData();
			formData.append(
				"employee_number",
				this.employee_data?.employee_number || this.emp_number
			);
			formData.append(
				"loan_date_english",
				this.form.value.loan_date_english
					? moment(this.form.value.loan_date_english).format("YYYY/MM/DD")
					: ""
			);
			formData.append("loan_date_arabic", this.form.value.loan_date_arabic);
			formData.append("loan_amount", this.form.value.loan_amount);
			formData.append("direct_manager", this.form.value.direct_manager || "");
			formData.append("loan_reason", this.form.value.reasons); // this.form.value.reasons
			this.attachments.controls.forEach((item, index) => {
				formData.append(
					`attachments[${index + 1}][file]`,
					item.get("file").value
				);
				formData.append(
					`attachments[${index + 1}][attach_title]`,
					item.get("attach_title").value
				);
			});
			this.form.value.installments.forEach((installment, index) => {
				formData.append(
					`installments[${index + 1}][amount]`,
					installment.amount
				);
				formData.append(
					`installments[${index + 1}][due_year]`,
					installment.month
						? moment(installment.month, "MM-YYYY").format("YYYY")
						: ""
				);
				formData.append(
					`installments[${index + 1}][due_month]`,
					installment.month
						? moment(installment.month, "MM-YYYY").format("MM")
						: ""
				);
			});

			this.ds
				.post(`form/${this.form_key || "FORM_HRLN1"}/cr/0`, formData)
				.subscribe(
					(data) => {
						if (data.status) {
							this.alert.success(data.msg);
							this.form.reset();

							if (this.lang_key == "ar") {
								this.showMsg("تم انشاء السلفة بنجاح", "ok", "success-snackbar");
							} else {
								this.showMsg(
									"Loan Created Successfully",
									"ok",
									"success-snackbar"
								);
							}

							setTimeout(() => {
								this.router.navigate(["transactions/allforms/"]);
							}, 1000);

							this.spinner.hide();
						} else {
							this.spinner.hide();
							this.alert.error(data?.error);
						}
					},
					(error) => {
						this.spinner.hide();
						this.form.controls.createOverTimes.enable();
						this.alert.error(error);
					}
				);
		} else {
			this.form.controls.createInstallments.enable();
			this.showMsg("Please fill all fields first", "ok", "error-snackbar");
		}
	}

	public search_users(key) {
		if (key) {
			this.users = [];
			let param = new FormData();
			param.append("search", key.term);
			param.append("lang_key", this.lang_key);
			param.append("user_type_id", "1");
			this.ds
				.getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
				.subscribe((res) => {
					this.spinner.hide();
					if (res.status) {
						this.users = res.list;
					}
				});
		}
	}
}
