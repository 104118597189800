import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
@Component({
  selector: "app-invoice-claim-list",
  templateUrl: "./invoice-claim-list.component.html",
  styleUrls: ["./invoice-claim-list.component.scss"],
})
export class InvoiceClaimListComponent implements OnInit, OnDestroy {
  branches: any = [];
  InvData: any = [];
  branch_id: any =
    localStorage.getItem("selectedBranch") != null
      ? localStorage.getItem("selectedBranch")
      : "0";
  lodingdatas = this.lang.transform("lang_loading");
  finances: any = [];
  Invpage_no: any = localStorage.getItem("AC_SI_P") || 1;
  Invpage_size: any = localStorage.getItem("AC_SI_S") || "10";
  finance_id: any = localStorage.getItem("AC_SI_S_F") || null;
  searchKey: any = localStorage.getItem("AC_SI_S_K") || "";
  constructor(
    public ds: DataService,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    public router: Router,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != "0") {
      this.load_financial_years();
    }
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public load_financial_years() {
    this.finances = [];
    this.finance_id = null;
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
              }
            });
          }
          this.load_invoices_claim();
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public selectBrach(branch) {
    if (branch && branch != "0") {
      localStorage.setItem("selectedBranch", branch);
      this.branch_id = branch;
      this.load_financial_years();
    } else {
      this.spinner.hide();
    }
  }
  public loadPageInv(page) {
    this.spinner.show();
    this.Invpage_no = page;
    this.Invpage_size = this.Invpage_size;
    this.load_invoices_claim();
  }
  public loadInvPagesize(size) {
    this.spinner.show();
    this.Invpage_no = 1;
    this.Invpage_size = size;
    this.load_invoices_claim();
  }
  public search_invoices() {
    this.Invpage_no = 1;
    this.Invpage_size = "10";
    this.load_invoices_claim();
  }
  public load_invoices_claim() {
    let formdata = new FormData();
    this.branch_id = this.branch_id ? this.branch_id : 0;
    formdata.append("key_search", this.searchKey);
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          formdata,
          "invoices_claim/" +
            this.branch_id +
            "/" +
            this.Invpage_no +
            "/" +
            this.Invpage_size +
            "/" +
            this.finance_id
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.InvData = res;
            } else {
              this.InvData = [];
              this.lodingdatas = res.error;
            }
          },
          (error) => {
            this.spinner.hide();
            this.InvData = [];
            this.lodingdatas =
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error");
          }
        )
    );
  }
  public remove_invoice_claim(branch_id, transaction_id, invoice_id) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true,
      width: "400px",
      data: {
        title: this.lang.transform("lang_confirmation"),
        message: this.lang.transform("lang_are_you_sure"),
      },
    });
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          this.subscriptions.add(
            this.ds
              .get(
                "dsinvoiceclaim/" +
                  branch_id +
                  "/" +
                  transaction_id +
                  "/" +
                  invoice_id
              )
              .subscribe(
                (data) => {
                  this.spinner.hide();
                  if (data.status) {
                    this.sweetAlert.successToast(data.msg, 2000);
                    this.load_invoices_claim();
                  } else {
                    this.sweetAlert.errorToast(data.error, 2000);
                  }
                },
                (error) => {
                  this.spinner.hide();
                  this.sweetAlert.errorToast(
                    error && error.error && error.error.error
                      ? error.error.error
                      : this.lang.transform("lang_internal_server_error"),
                    2000
                  );
                }
              )
          );
        }
      })
    );
  }
  convert_to_selling(invoice_id) {
    this.spinner.show();
    let param = new FormData();
    param.append("invoice_id", invoice_id);
    this.ds.postActionByUrl(param, "claim_for_convert").subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.load_invoices_claim();
          this.sweetAlert.successToast(res?.msg, 2000);
        } else {
          this.sweetAlert.errorToast(res?.msg, 2000);
        }
      },
      (error) => {
        this.spinner.hide();
        this.ds.dialogf(
          "",
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
}
