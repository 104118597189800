<section
  class="card matborderno activeiconstepper customdesignstepper"
  *ngIf="showStatus"
>
  <mat-accordion class="stylepanels">
    <mat-expansion-panel class="mb-2" [expanded]="expandedStep == 0">
      <mat-expansion-panel-header (click)="openAccord(0)"
        >{{ "lang_form_details" | language }}
      </mat-expansion-panel-header>
      <div class="material-panel-body">
        <div class="mbrl15 martb0">
          <div class="row mb-1">
            <div class="col-xs-12 col-sm-6 col-md-3 title mb-sm-1 mb-lg-0">
              <label class="bold p-2">{{
                "lang_transaction_number" | language
              }}</label>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 p-2">
              {{ transaction_request?.transaction_request_id }}
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 title mb-sm-1 mb-lg-0">
              <label class="bold p-2">{{
                "lang_project_title" | language
              }}</label>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 p-2">
              {{
                allData?.view_request?.work_area_info?.records
                  ?.projects_profile_name
              }}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-xs-12 col-sm-6 col-md-3 title mb-sm-1 mb-lg-0">
              <label class="bold p-2">{{
                "lang_supervision_title" | language
              }}</label>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 p-2">
              {{
                allData?.view_request?.work_area_info?.records
                  ?.projects_services_name
              }}
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 title mb-sm-1 mb-lg-0">
              <label class="bold p-2">{{ "lang_forms_code" | language }}</label>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 p-2">
              {{
                allData?.view_request?.form_wir_data?.records[0]
                  ?.platform_code_system
              }}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-xs-12 col-sm-6 col-md-3 title mb-sm-1 mb-lg-0">
              <label class="bold p-2">{{ "lang_group_one" | language }}</label>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 p-2">
              {{ allData?.view_request?.form_wir_data?.records[0]?.group1name }}
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 title mb-sm-1 mb-lg-0">
              <label class="bold p-2">{{ "lang_group_two" | language }}</label>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 p-2">
              {{ allData?.view_request?.form_wir_data?.records[0]?.group2name }}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-xs-12 col-sm-6 col-md-3 title mb-sm-1 mb-lg-0">
              <label class="bold p-2">{{ "lang_form_name" | language }}</label>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 p-2">
              {{
                allData?.view_request?.form_wir_data?.records[0]?.platformname
              }}
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 title mb-sm-1 mb-lg-0">
              <label class="bold p-2">{{
                "lang_form_specifications" | language
              }}</label>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 p-2">
              {{
                allData?.view_request?.form_wir_data?.records[0]?.specification
              }}
            </div>
          </div>
          <app-forms
            [form_url]="form_url"
            [form_key]="form_key"
            [form_request_id]="form_request_id"
            [project_data]="project_data"
            [supervision_data]="supervision_data"
            [template_data]="template_data"
            [editMode]="true"
            (refreshComponent)="refreshComponent($event)"
          ></app-forms>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="mb-2" [expanded]="expandedStep == 1">
      <mat-expansion-panel-header (click)="openAccord(1)">
        {{ "lang_notes" | language }}
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col-12">
          <div class="TableParts">
            <app-transactions-notes [notes]="notes"></app-transactions-notes>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="mb-2" [expanded]="expandedStep == 2">
      <mat-expansion-panel-header (click)="openAccord(2)">{{
        "lang_person_details" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body communication">
        <div class="mbrl15">
          <div class="col-md-12 pad0">
            <app-person-details [persons]="persons"></app-person-details>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="mb-2" [expanded]="expandedStep == 3">
      <mat-expansion-panel-header (click)="openAccord(3)">{{
        "lang_attachments" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body communication">
        <div class="mbrl15">
          <div class="col-md-12 pad0">
            <app-transaction-attachments
              [All_Attachments]="All_Attachments"
              [isInsideSupervision]="true"
            ></app-transaction-attachments>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="mb-2" [expanded]="expandedStep == 4">
      <mat-expansion-panel-header
        class="mpanel-header"
        (click)="openAccord(4)"
        >{{ "lang_history" | language }}</mat-expansion-panel-header
      >
      <div class="material-panel-body communication">
        <div class="mbrl15">
          <div class="col-md-12 pad0">
            <app-transactions-history
              [history]="history"
            ></app-transactions-history>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <app-versions-show
    [data]="allData"
    *ngIf="show_versions"
    id="versions_section"
  ></app-versions-show>
  <div class="card-footer">
    <div class="row">
      <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
        <button
          type="button"
          routerLink="/projects/BbVUrWitLE"
          class="albutton mnabrbutton mat-accent"
          mat-button
        >
          {{ "lang_all_projects" | language }}
        </button>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
        <button
          role="button"
          routerLink="/projects/Rpq7t/{{ projects_profile_id }}/{{
            projects_workarea_id
          }}"
          class="albutton mnabrbutton mat-accent"
          mat-button
        >
          {{ "lang_supervision_operations" | language }}
        </button>
      </div>
      <div class="col-md-7 col-sm-2 col-xs-12 mb-10"></div>
    </div>
  </div>
</section>
