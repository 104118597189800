import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
@Component({
  selector: "app-ect1-step7",
  templateUrl: "./ect1-step7.component.html",
  styleUrls: ["./ect1-step7.component.scss"],
})
export class Ect1Step7Component implements OnInit {
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() lastStepOpened;
  constructor(
    public ds: DataService,
    public datepipe: DatePipe,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public fb: FormBuilder,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}
}
