import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from "@angular/router";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class EpAuthGuard implements CanActivate {
	constructor(private ls: LocalStorage, private router: Router) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		let token = this.ls.getItem("eptoken");
		if (token) {
			return true;
		} else {
			this.router.navigate(["external-person/login"]);
			return false;
		}
	}
}
