import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from "angular-calendar";
import { addDays, addHours, endOfMonth, startOfDay, subDays } from "date-fns";
import moment, * as Moment from "moment";
import { extendMoment } from "moment-range";
import { Subject, Subscription } from "rxjs";
import { parseISO } from "date-fns";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AddSingleShiftComponent } from "./add-single-shift/add-single-shift.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PreloaderService } from "@core";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { DataService } from "@core/bootstrap/data.service";

import { OnDestroy } from "@angular/core";
export interface CalendarEvent2<MetaType = any> {
  id?: string | number;
  start: Date;
  end?: Date;
  title: string;
  color?: any;
  actions?: any[];
  allDay?: boolean;
  cssClass?: string;
  resizable?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };
  data?: any;
  draggable?: boolean;
  meta?: MetaType;
}

@Component({
  selector: "app-add-shift",
  templateUrl: "./add-shift.component.html",
  styleUrls: ["./add-shift.component.scss"],
})
export class AddShifComponent implements OnInit, OnDestroy {
  oldYear = moment().year();
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {}
  startDate: any;
  endDate: any;
  form: FormGroup | any;
  lang_key = localStorage.getItem("lang_key") || "en";
  shift_id: any = "";
  viewDate: Date = new Date();

  ngOnInit(): void {
    moment.locale("en");

    this.subscriptions.add(
      this.route.params.subscribe((params: Params) => {
        this.shift_id = params["shift_id"];
        /*  this.type = params['type'] */
      })
    );

    this.form = this.fb.group({
      month_shifts: this.fb.array([]),
    });

    for (let index = 0; index < moment.months().length; index++) {
      const element = moment.months()[index];

      let monthDays = Array.from(
        {
          length: moment(
            moment(this.viewDate).locale("en").month(element).format("YYYY-MM")
          ).daysInMonth(),
        },
        (x, i) =>
          moment(this.viewDate)
            .month(element)
            .startOf("month")
            .add(i, "days")
            .format("DD/MM/YYYY")
      );
      const creds = this.form.controls.month_shifts as FormArray;
      console.log(this.viewDate);
      creds.push(
        this.fb.group({
          shift_time_year: moment(this.viewDate).month(element).format("YYYY"),
          shift_time_month: moment(this.viewDate).month(element).format("MM"),
          times: this.fb.array([]),
        })
      );

      for (let i = 0; i < monthDays.length; i++) {
        const e = monthDays[i];

        let times = creds.controls[index]["controls"]?.times as FormArray;
        times?.push(
          this.fb.group({
            title: "",

            data: [
              {
                status: "requested",
                vio: "حضور متأخر",
                count: 3,
              },
              [],
            ],
            index: i,

            start: [parseISO(moment(e, "DD/MM/YYYY").format())],
            end: [parseISO(moment(e, "DD/MM/YYYY").format()), []],
            shift_time_day: [e.split("/")[0], []],
            shift_time_date: [e, []],
            shift_time_day_off: ["", [Validators.required]],
            effect_all_data: ["", [Validators.required]],
            shift_total_hours: ["", []],
            shift_break_hours: ["", []],
            check_in_start: ["", []],
            check_in_end: ["", []],
            break_out_start: ["", []],
            break_out_end: ["", []],
            break_in_start: ["", []],
            break_in_end: ["", []],
            check_out_start: ["", []],
            check_out_end: ["", []],
            shift_start_time: ["", []],
            shift_end_time: ["", []],
            absent_A: ["", []],
            absent_B: ["", []],
            absent_C: ["", []],
            absent_D: ["", []],
            not_ok_A: ["", []],
            not_ok_B: ["", []],
            not_ok_C: ["", []],
            not_ok_D: ["", []],
            record_missing_A: ["", []],
            record_missing_B: ["", []],
            record_missing_C: ["", []],
            record_missing_D: ["", []],
            trackingId: [this.generateUniqueId()],
          })
        );
      }
    }

    this.startDate = moment(this.viewDate)
      .locale("en")
      .clone()
      .startOf("month")
      .format("DD-MM-YYYY")
      .toString();
    this.endDate = moment(this.viewDate)
      .locale("en")
      .clone()
      .endOf("month")
      .format("DD-MM-YYYY")
      .toString();
    this.index = this.form.value.month_shifts.findIndex(
      (i) => i.shift_time_month == moment(this.viewDate).format("MM")
    );
    this.LoadBookingsForCalendar();

    this.loadShifts();
  }
  private subscriptions = new Subscription();

  loadShifts() {
    this.subscriptions.add(
      this.ds
        .get(
          `at/get_times/${this.shift_id}/${moment(this.viewDate).format(
            "YYYY"
          )}/0`
        )
        .subscribe(
          (data) => {
            if (data.status) {
              for (let index = 0; index < data.records.length; index++) {
                const element = data.records[index];
                let day = this.form.controls.month_shifts.controls[
                  +element.shift_time_month - 1
                ].controls?.times?.controls[
                  +element.shift_time_day - 1
                ] as FormControl;
                if (+element.shift_time_day_off) {
                  day.patchValue({
                    ...day.value,
                    /*  "shift_time_id": "1029", */

                    data: [
                      {
                        status: "requested",
                        vio: "حضور متأخر",
                        count: 3,
                      },
                      [],
                    ],
                    trackingId: this.generateUniqueId(),
                    start: parseISO(
                      moment(element.shift_time_date, "YYYY/MM/DD").format()
                    ),
                    end: parseISO(
                      moment(element.shift_time_date, "YYYY/MM/DD").format()
                    ),
                    shift_time_day: element.shift_time_day,
                    shift_time_date: parseISO(
                      moment(element.shift_time_date, "YYYY/MM/DD").format()
                    ),
                    shift_time_day_off: +element.shift_time_day_off,
                  });
                } else {
                  day.patchValue({
                    /*  "shift_time_id": "1029", */
                    ...day.value,
                    data: [
                      {
                        status: "requested",
                        vio: "حضور متأخر",
                        count: 3,
                      },
                      [],
                    ],
                    trackingId: this.generateUniqueId(),
                    start: parseISO(
                      moment(element.shift_time_date, "YYYY/MM/DD").format()
                    ),
                    end: parseISO(
                      moment(element.shift_time_date, "YYYY/MM/DD").format()
                    ),
                    shift_time_day: element.shift_time_day,
                    shift_time_date: parseISO(
                      moment(element.shift_time_date, "YYYY/MM/DD").format()
                    ),
                    shift_time_day_off: +element.shift_time_day_off,
                    shift_total_hours: element.shift_total_hours,
                    shift_break_hours: element.shift_break_hours,
                    check_in_start: moment(
                      element.check_in_start,
                      "HH:mm:ss"
                    ).format("HH:mm"),
                    check_in_end: moment(
                      element.check_in_end,
                      "HH:mm:ss"
                    ).format("HH:mm"),
                    break_out_start: moment(
                      element.break_out_start,
                      "HH:mm:ss"
                    ).format("HH:mm"),
                    break_out_end: moment(
                      element.break_out_end,
                      "HH:mm:ss"
                    ).format("HH:mm"),
                    break_in_start: moment(
                      element.break_in_start,
                      "HH:mm:ss"
                    ).format("HH:mm"),
                    break_in_end: moment(
                      element.break_in_end,
                      "HH:mm:ss"
                    ).format("HH:mm"),
                    check_out_start: moment(
                      element.check_out_start,
                      "HH:mm:ss"
                    ).format("HH:mm"),
                    check_out_end: moment(
                      element.check_out_end,
                      "HH:mm:ss"
                    ).format("HH:mm"),
                    shift_start_time: moment(
                      element.shift_start_time,
                      "HH:mm:ss"
                    ).format("HH:mm"),
                    shift_end_time: moment(
                      element.shift_end_time,
                      "HH:mm:ss"
                    ).format("HH:mm"),
                  });
                }
              }
            } else {
              this.form.reset();
            }
          },
          (error) => {
            this.spinner.hide();
            this.showMsg(error?.error?.error, "ok", "error-snackbar");
          }
        )
    );
  }

  trackByFnCustom(index: number, item: any) {
    return item.trackingId;
  }
  generateUniqueId() {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }

  getMonthByIndex(mon) {
    let index = this.form.value.month_shifts.findIndex(
      (i) => i == moment(mon).format("MM")
    );
  }

  pushDataToAllMonthes(data) {
    const creds = this.form.controls.month_shifts as FormArray;
    creds.push(
      this.fb.group({
        shift_time_year: [],
        shift_time_month: [],
        times: this.fb.array([]),
      })
    );
  }
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;
  view: CalendarView = CalendarView.Month;

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });

    this.handleEvent("Dropped or resized", event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    /*   this.modalData = { event: [event], action: action, selectedDate: '', date: new Date().toString() };
    this.modal.open(this.modalContent, { size: 'lg' }); */
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.openModal({ date, events });

    /* if (isSameMonth(date, this.viewDate)) {
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)  
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen =true ;
        }
        this.viewDate = date;
      } */
  }
  showMsg(msg: string, action: string, className: string) {
    this.snackBar.open(msg, action, {
      duration: 4000,
      panelClass: [className],
    });
  }

  from_to = {
    startDate: Moment(this.viewDate)
      .locale("en")
      .startOf("month")
      .format("DD-MM-YYYY"),
    endDate: Moment(this.viewDate)
      .locale("en")
      .startOf("month")
      .add(30, "days")
      .format("YYYY-MM-DD"),
  };

  openModal(data) {
    console.log(data);

    const dialogRef = this.dialog.open(AddSingleShiftComponent, {
      disableClose: true,
      width: "80%",
      data: data,
      panelClass: "warning-dialog",
    });
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((data) => {
        if (data.formValue) {
          let daysArr = data.days;
          let formVal = data.formValue;
          console.log(formVal);
          console.log(this.form.controls.month_shifts);

          let selectedDay = this.form.controls.month_shifts?.controls[
            this.index
          ]?.controls?.times?.controls[formVal.index] as FormGroup;
          selectedDay?.patchValue({
            shift_time_day_off: formVal.shift_time_day_off,
            effect_all_data: formVal.effect_all_data,
            shift_total_hours: formVal.shift_total_hours,
            shift_break_hours: formVal.shift_break_hours,
            check_in_start: formVal.check_in_start,
            check_in_end: formVal.check_in_end,
            break_out_start: formVal.break_out_start,
            break_out_end: formVal.break_out_end,
            break_in_start: formVal.break_in_start,
            break_in_end: formVal.break_in_end,
            absent_A: formVal.absent_A,
            absent_B: formVal.absent_B,
            absent_C: formVal.absent_C,
            absent_D: formVal.absent_D,
            not_ok_A: formVal.not_ok_A,
            not_ok_B: formVal.not_ok_B,
            not_ok_C: formVal.not_ok_C,
            not_ok_D: formVal.not_ok_D,
            record_missing_A: formVal.record_missing_A,
            record_missing_B: formVal.record_missing_B,
            record_missing_C: formVal.record_missing_C,
            record_missing_D: formVal.record_missing_D,
            check_out_start: formVal.check_out_start,
            check_out_end: formVal.check_out_end,
            shift_start_time: formVal.shift_start_time,
            shift_end_time: formVal.shift_end_time,
          });

          if (daysArr) {
            for (const property in daysArr) {
              const dayAr = daysArr[property];
              for (let index = 0; index < dayAr.length; index++) {
                const element = dayAr[index];
                for (
                  let i = 0;
                  i <
                  this.form.controls.month_shifts.controls[this.index].controls
                    .times?.controls.length;
                  i++
                ) {
                  const formGroup = this.form.controls.month_shifts.controls[
                    this.index
                  ].controls.times?.controls[i] as FormGroup;

                  if (
                    moment(
                      formGroup.value.shift_time_date,
                      "DD/MM/YYYY"
                    ).isSame(element, "day")
                  ) {
                    if (formVal.shift_time_day_off) {
                      formGroup.patchValue({
                        ...formGroup.value,
                        shift_time_day_off: formVal.shift_time_day_off,
                      });
                    } else {
                      formGroup.patchValue({
                        ...formGroup.value,

                        shift_time_day_off: formVal.shift_time_day_off,
                        shift_total_hours: formVal.shift_total_hours,
                        shift_break_hours: formVal.shift_break_hours,
                        check_in_start: formVal.check_in_start,
                        check_in_end: formVal.check_in_end,
                        break_out_start: formVal.break_out_start,
                        break_out_end: formVal.break_out_end,
                        break_in_start: formVal.break_in_start,
                        break_in_end: formVal.break_in_end,
                        absent_A: formVal.absent_A,
                        absent_B: formVal.absent_B,
                        absent_C: formVal.absent_C,
                        absent_D: formVal.absent_D,
                        not_ok_A: formVal.not_ok_A,
                        not_ok_B: formVal.not_ok_B,
                        not_ok_C: formVal.not_ok_C,
                        not_ok_D: formVal.not_ok_D,
                        record_missing_A: formVal.record_missing_A,
                        record_missing_B: formVal.record_missing_B,
                        record_missing_C: formVal.record_missing_C,
                        record_missing_D: formVal.record_missing_D,
                        check_out_start: formVal.check_out_start,
                        check_out_end: formVal.check_out_end,
                        shift_start_time: formVal.shift_start_time,
                        shift_end_time: formVal.shift_end_time,
                      });
                    }
                  }
                }
              }
            }
          }
          if (formVal.apply_to_all_monthes) {
            for (let y = 0; y < this.form.value.month_shifts.length; y++) {
              const bigArray = this.form.value.month_shifts[y];
              for (const property in daysArr) {
                let dayAr = [];
                let day_name = property;
                var day = moment(this.viewDate)
                  .month(bigArray.shift_time_month - 1)
                  .startOf("month")
                  .day(day_name);
                if (day.date() > 7) day.add(7, "d");
                var month = day.month();
                while (month === day.month()) {
                  /* document.body.innerHTML += "<p>"+day.toString()+"</p>"; */

                  dayAr[day_name]
                    ? dayAr[day_name].push(day.toString())
                    : (dayAr[day_name] = [day.toString()]);

                  day.add(7, "d");
                }
                for (const property in dayAr) {
                  const element = dayAr[property];
                  for (let x = 0; x < element.length; x++) {
                    const elementDay = element[x];
                    for (
                      let i = 0;
                      i <
                      this.form.controls.month_shifts.controls[y].controls.times
                        .controls.length;
                      i++
                    ) {
                      const formGroup = this.form.controls.month_shifts
                        .controls[y].controls.times?.controls[i] as FormGroup;
                      if (
                        moment(
                          formGroup.value.shift_time_date,
                          "DD/MM/YYYY"
                        ).isSame(elementDay, "day")
                      ) {
                        if (formVal.shift_time_day_off) {
                          formGroup.patchValue({
                            ...formGroup.value,
                            shift_time_day_off: formVal.shift_time_day_off,
                          });
                        } else {
                          formGroup.patchValue({
                            ...formGroup.value,

                            shift_time_day_off: formVal.shift_time_day_off,
                            shift_total_hours: formVal.shift_total_hours,
                            shift_break_hours: formVal.shift_break_hours,
                            check_in_start: formVal.check_in_start,
                            check_in_end: formVal.check_in_end,
                            break_out_start: formVal.break_out_start,
                            break_out_end: formVal.break_out_end,
                            break_in_start: formVal.break_in_start,
                            break_in_end: formVal.break_in_end,
                            absent_A: formVal.absent_A,
                            absent_B: formVal.absent_B,
                            absent_C: formVal.absent_C,
                            absent_D: formVal.absent_D,
                            not_ok_A: formVal.not_ok_A,
                            not_ok_B: formVal.not_ok_B,
                            not_ok_C: formVal.not_ok_C,
                            not_ok_D: formVal.not_ok_D,
                            record_missing_A: formVal.record_missing_A,
                            record_missing_B: formVal.record_missing_B,
                            record_missing_C: formVal.record_missing_C,
                            record_missing_D: formVal.record_missing_D,
                            check_out_start: formVal.check_out_start,
                            check_out_end: formVal.check_out_end,
                            shift_start_time: formVal.shift_start_time,
                            shift_end_time: formVal.shift_end_time,
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
        }
      })
    );
  }

  ShowTime(time) {
    return moment(time, "HH:mm").format("hh:mm A");
  }

  submitForm() {
    this.spinner.show();
    let times = 0;
    for (let index = 0; index < this.form.value.month_shifts.length; index++) {
      let formData = new FormData();
      console.log(this.form);
      console.log(index);

      this.form
        .get("month_shifts")
        ?.controls[index]?.get("shift_time_year")
        ?.setValue(moment(this.viewDate, "YYYY").format("YYYY"));
      const element = this.form.value.month_shifts[index];
      console.log(element);

      formData.append(`shift_time[${index + 1}][shift_id]`, this.shift_id);
      formData.append(
        `shift_time[${index + 1}][shift_time_year]`,
        moment(element.shift_time_year, "YYYY").format("YYYY")
      );
      formData.append(
        `shift_time[${index + 1}][shift_time_month]`,
        element.shift_time_month
      );
      for (let i = 0; i < element.times?.length; i++) {
        let t = element.times[i];
        t["shift_time_day_off_2"] = t.shift_time_day_off == true ? 1 : 0;
        t["effect_all_data_2"] = t.effect_all_data == true ? 1 : 0;
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][shift_time_day]`,
          t.shift_time_day
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][shift_time_date]`,
          t.shift_time_date
            ? moment(t.shift_time_date, "DD/MM/YYYY").format("YYYY/MM/DD")
            : ""
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][shift_time_day_off]`,
          t.shift_time_day_off_2
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][effect_all_data]`,
          t.effect_all_data_2
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][shift_total_hours]`,
          t.shift_total_hours
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][shift_break_hours]`,
          t.shift_break_hours
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][check_in_start]`,
          t.shift_time_day_off_2 == 0
            ? moment(
                t.check_in_start,
                `${
                  t.check_in_start?.includes("AM") ||
                  t.check_in_start?.includes("PM")
                    ? "hh:mm A"
                    : "HH:mm"
                }`
              ).format("HH:mm")
            : ""
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][check_in_end]`,
          t.shift_time_day_off_2 == 0
            ? moment(
                t.check_in_end,
                `${
                  t.check_in_end?.includes("AM") ||
                  t.check_in_end?.includes("PM")
                    ? "hh:mm A"
                    : "HH:mm"
                }`
              ).format("HH:mm")
            : ""
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][break_out_start]`,
          t.shift_time_day_off_2 == 0
            ? moment(
                t.break_out_start,
                `${
                  t.break_out_start?.includes("AM") ||
                  t.break_out_start?.includes("PM")
                    ? "hh:mm A"
                    : "HH:mm"
                }`
              ).format("HH:mm")
            : ""
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][break_out_end]`,
          t.shift_time_day_off_2 == 0
            ? moment(
                t.break_out_end,
                `${
                  t.break_out_end?.includes("AM") ||
                  t.break_out_end?.includes("PM")
                    ? "hh:mm A"
                    : "HH:mm"
                }`
              ).format("HH:mm")
            : ""
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][break_in_start]`,
          t.shift_time_day_off_2 == 0
            ? moment(
                t.break_in_start,
                `${
                  t.break_in_start?.includes("AM") ||
                  t.break_in_start?.includes("PM")
                    ? "hh:mm A"
                    : "HH:mm"
                }`
              ).format("HH:mm")
            : ""
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][check_out_start]`,
          t.shift_time_day_off_2 == 0
            ? moment(
                t.check_out_start,
                `${
                  t.check_out_start?.includes("AM") ||
                  t.check_out_start?.includes("PM")
                    ? "hh:mm A"
                    : "HH:mm"
                }`
              ).format("HH:mm")
            : ""
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][break_in_end]`,
          t.shift_time_day_off_2 == 0
            ? moment(
                t.break_in_end,
                `${
                  t.break_in_end?.includes("AM") ||
                  t.break_in_end?.includes("PM")
                    ? "hh:mm A"
                    : "HH:mm"
                }`
              ).format("HH:mm")
            : ""
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][absent_A]`,
          t.absent_A
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][absent_B]`,
          t.absent_B
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][absent_C]`,
          t.absent_C
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][absent_D]`,
          t.absent_D
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][not_ok_A]`,
          t.not_ok_A
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][not_ok_B]`,
          t.not_ok_B
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][not_ok_C]`,
          t.not_ok_C
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][not_ok_D]`,
          t.not_ok_D
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][record_missing_A]`,
          t.record_missing_A
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][record_missing_B]`,
          t.record_missing_B
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][record_missing_C]`,
          t.record_missing_C
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][record_missing_D]`,
          t.record_missing_D
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][check_out_end]`,
          t.shift_time_day_off_2 == 0
            ? moment(
                t.check_out_end,
                `${
                  t.check_out_end?.includes("AM") ||
                  t.check_out_end?.includes("PM")
                    ? "hh:mm A"
                    : "HH:mm"
                }`
              ).format("HH:mm")
            : ""
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][shift_start_time]`,
          t.shift_time_day_off_2 == 0
            ? moment(
                t.shift_start_time,
                `${
                  t.shift_start_time?.includes("AM") ||
                  t.shift_start_time?.includes("PM")
                    ? "hh:mm A"
                    : "HH:mm"
                }`
              ).format("HH:mm")
            : ""
        );
        formData.append(
          `shift_time[${index + 1}][times][${i + 1}][shift_end_time]`,
          t.shift_time_day_off_2 == 0
            ? moment(
                t.shift_end_time,
                `${
                  t.shift_end_time?.includes("AM") ||
                  t.shift_end_time?.includes("PM")
                    ? "hh:mm A"
                    : "HH:mm"
                }`
              ).format("HH:mm")
            : ""
        );
      }
      this.subscriptions.add(
        this.ds.post(`at/create_times`, formData).subscribe(
          (data) => {
            if (data.status) {
              times++;
              this.showMsg(
                this.lang.transform("lang_modified_successfully"),
                "ok",
                "success-snackbar"
              );
              if (times == 12) {
                this.spinner.hide();
                this.router.navigate([
                  "/human_resources/attendances/all-shifts",
                ]);
              }
            } else {
              this.spinner.hide();
              return 0;
            }
          },
          (error) => {
            this.spinner.hide();
          }
        )
      );
    }
    // if (this.form.valid) {

    // } else {
    // 	this.showMsg("Please fill all fields first", "ok", "error-snackbar");
    // }
  }

  index = 0;
  closeOpenMonthViewDay(status: string) {
    const moment = extendMoment(Moment);
    var start = "";
    var end = "";
    if (status == "today") {
      this.startDate = moment(this.viewDate)
        .locale("en")
        .clone()
        .startOf("month")
        .format("DD-MM-YYYY")
        .toString();
      this.endDate = moment(this.viewDate)
        .locale("en")
        .clone()
        .endOf("month")
        .format("DD-MM-YYYY")
        .toString();
      this.from_to = {
        startDate: Moment(this.viewDate)
          .locale("en")
          .startOf("month")
          .format("DD-MM-YYYY"),
        endDate: Moment(this.viewDate)
          .locale("en")
          .startOf("month")
          .add(30, "d")
          .format("YYYY-MM-DD"),
      };
      this.index = this.form.value.month_shifts.findIndex(
        (i) => i.shift_time_month == moment(this.viewDate).format("MM")
      );
    }
    if (status == "next") {
      this.activeDayIsOpen = false;
      this.from_to = {
        endDate: Moment(this.from_to.endDate)
          .locale("en")
          .add(30, "day")
          .format("YYYY-MM-DD"),
        startDate: Moment(this.from_to.endDate)
          .locale("en")
          .add(30, "day")
          .format("YYYY-MM-DD"),
      };
      this.index = this.form.value.month_shifts.findIndex(
        (i) => i.shift_time_month == moment(this.viewDate).format("MM")
      );
    }
    if (status == "previous") {
      var date = moment(this.startDate, "DD-MM-YYYY").locale("en").toDate();
      this.activeDayIsOpen = false;
      this.from_to = {
        endDate: Moment(this.from_to.endDate)
          .locale("en")
          .add(-30, "days")
          .format("YYYY-MM-DD"),
        startDate: Moment(this.from_to.startDate)
          .locale("en")
          .add(-30, "days")
          .format("YYYY-MM-DD"),
      };
      this.index = this.form.value.month_shifts.findIndex(
        (i) => i.shift_time_month == moment(this.viewDate).format("MM")
      );
    }

    this.LoadBookingsForCalendar();
  }

  loadShiftsOnChange() {
    console.log(moment(this.viewDate).year(), this.oldYear);

    if (moment(this.viewDate).year() != this.oldYear) {
      this.loadShifts();
    }
    this.oldYear = moment(this.viewDate).year();
  }

  currentUser: any;
  userType = "";
  serverEvents: Event[] | any = [];
  CalendarView = CalendarView;

  actions: any[] = [];

  LoadBookingsForCalendar(from_to?: any) {
    // if(this.status == 'vacation'){
    //   this.type = 'vacation';
    // }

    this.serverEvents = [
      {
        start: startOfDay(new Date()),
        end: addDays(new Date(), 1),
        title: "A 3 day event",
        data: {
          status: "requested",
          vio: "حضور متأخر",
          count: 3,
        },
        actions: this.actions,
        allDay: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      },
      {
        start: startOfDay(new Date()),
        end: addDays(new Date(), 1),
        title: "An event with no end date",
        data: {
          status: "cancelled",
          vio: "غياب",
          count: 3,
        },
        actions: this.actions,
      },

      {
        start: subDays(endOfMonth(new Date()), 3),
        end: addDays(endOfMonth(new Date()), 3),
        title: "A long event that spans 2 months",
        data: {
          status: "requested",
          vio: "حضور متأخر",
          count: 3,
        },
        allDay: true,
      },
      {
        start: addHours(startOfDay(new Date()), 2),
        end: addHours(new Date(), 2),
        title: "A draggable and resizable event",
        data: {
          status: "available",
          vio: "حاضرين في الميعاد",
          count: 3,
        },
        actions: this.actions,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      },
      {
        start: addHours(startOfDay(new Date()), 4),
        end: addHours(new Date(), 4),
        title: "A draggable and resizable event",
        data: {
          status: "vacation",
          vio: "حاضرين في الميعاد",
          count: 3,
        },
        actions: this.actions,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      },
    ];

    this.events = [];
    this.serverEvents.map((x: any) => {
      let calEvent: CalendarEvent2 = {
        start: new Date(new Date(x.start).toUTCString().replace("GMT", "")),
        end: new Date(new Date(x.end).toUTCString().replace("GMT", "")),
        title: "",
        actions: this.actions,
        data: {
          ...x.data,
        },
      };
      this.events.push(calEvent);
    });
    this.refresh.next();

    /*   this.subscriptions.add(this._EventsService.getAllEvent(this.tutorId!,this.status!,this.type!).subscribe(res => {
   

     }));     */
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
