import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";

@Component({
  selector: "app-non-equal-transactions",
  templateUrl: "./non-equal-transactions.component.html",
  styleUrls: ["./non-equal-transactions.component.scss"],
})
export class NonEqualTransactionsComponent implements OnInit {
  apiUrl = environment.SERVER_ORIGIN;
  branches: any = [];
  finances: any = [];
  transData: any = [];
  branch_id: any =
    localStorage.getItem("selectedBranch") != null
      ? localStorage.getItem("selectedBranch")
      : "0";
  recviewdata: any = [];
  lodingdatas: any = this.lang.transform("lang_loading");
  recpage_no: any = 1;
  recpage_size: any = "10";
  finance_id: any;
  transaction_request_type: any;
  transactionType = [
    {
      label: this.lang.transform("lang_selling_invoices"),
      value: "SIV",
    },
    {
      label: this.lang.transform("lang_purchase_invoice"),
      value: "PIV",
    },
    {
      label: this.lang.transform("lang_journal_voucher"),
      value: "JRN",
    },
    {
      label: this.lang.transform("lang_accounts_debit_notes"),
      value: "DTN",
    },
    {
      label: this.lang.transform("lang_credit_notes"),
      value: "CDN",
    },
    {
      label: this.lang.transform("lang_receipts"),
      value: "REC",
    },
    {
      label: this.lang.transform("lang_payments"),
      value: "PAY",
    },
  ];

  constructor(
    public ds: DataService,
    public datePipe: DatePipe,
    public router: Router,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {
    this.spinner.show();
    this.load_branches();
  }
  ngOnInit(): void {
    if (this.branch_id && this.branch_id != "0") {
      this.load_financial_years();
    }
  }

  public selectBrach(branch) {
    this.finances = [];
    this.finance_id = "";
    if (branch && branch != "0") {
      localStorage.setItem("selectedBranch", branch);
      this.branch_id = branch;
      this.load_financial_years();
    } else {
      this.spinner.hide();
    }
  }
  public load_financial_years() {
    this.finances = [];
    this.finance_id = null;
    this.subscriptions.add(
      this.ds.getActionByUrl([], "financial/" + this.branch_id).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.finances = res.records;
            this.finances.forEach((v, k) => {
              if (v.finance_status == "1") {
                this.finance_id = v.finance_id;
              }
            });
          }
          this.load_transactions();
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_view").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.branches = res.records;
          }
        },
        (error) => {
          this.spinner.hide();
        }
      )
    );
  }
  public loadpagerec(page) {
    this.spinner.show();
    this.recpage_no = page;
    this.recpage_size = this.recpage_size;
    this.load_transactions();
  }
  public loadrecpagesize(size) {
    this.spinner.show();
    this.recpage_no = 1;
    this.recpage_size = size;
    this.load_transactions();
  }
  public load_transactions() {
    let formdata = new FormData();

    formdata.append("branch_id", this.branch_id ? this.branch_id : "0");
    formdata.append("finance_id", this.finance_id ? this.finance_id : "0");
    formdata.append(
      "transaction_request_type",
      this.transaction_request_type ? this.transaction_request_type : ""
    );
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(formdata),
          "accounts/non_equal_transactions/" +
            this.recpage_no +
            "/" +
            this.recpage_size
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.transData = res;
            } else {
              this.transData = [];
              this.lodingdatas = res.error;
            }
          },
          (error) => {
            this.spinner.hide();
            this.transData = [];
            this.lodingdatas =
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error");
          }
        )
    );
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
