<section class="board">
	<div class="board-header">
		<div class="bh-use-template">
			<button class="use-template-btn" (click)="useTemplate()">
				{{ "lang_use_template" | language }}
			</button>
		</div>
	</div>

	<div class="board-tasks" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
		<div class="row">
			<!-- start main status new -->
			<div
				class="col-12 col-sm-6 col-md-3"
				style="min-height: 130px; margin-bottom: 15px"
			>
				<div class="main-status">
					<div class="ms-header">
						<h3 class="ms-header-title" *ngIf="!newList?.length">
							{{ "lang_new" | language }}
						</h3>
						<h3 class="ms-header-title" *ngIf="newList?.length">
							{{ "lang_new" | language }} ({{ newList?.length }})
						</h3>
						<button mat-button class="ms-header-action">
							<i class="fa fa-ellipsis-h mnabricon"></i>
						</button>
					</div>
					<div class="ms-content">
						<div class="msc-list">
							<ng-container *ngFor="let item of newList">
								<div
									class="mscl-item"
									(click)="openTask(item)"
									*ngIf="item?.is_can_view"
								>
									<div
										class="mscli-labels"
										id="mscli-labels{{ item?.task_id }}"
										*ngIf="
											item?.important_id != null && item?.important_id != '0'
										"
									>
										<div
											class="mscli-labels-label"
											[ngStyle]="{
												'background-color':
													item?.important_id == '1' || item?.important_id == '2'
														? '#61bd4f'
														: '#eb5a46'
											}"
											(click)="
												showTaskLabels($event, 'mscli-labels' + item?.task_id)
											"
										>
											<span>{{ item?.important_name }}</span>
										</div>
										<div
											class="mscli-labels-label"
											style="background-color: #0079bf"
											(click)="
												showTaskLabels($event, 'mscli-labels' + item?.task_id)
											"
											*ngIf="item?.status_done_name"
										>
											<span>{{ item?.status_done_name }}</span>
										</div>
									</div>
									<div class="mscli-header">
										<h4 class="mscli-header-title" #itemref>
											{{ item?.title }}
										</h4>
									</div>
									<div class="mscli-content">
										<div
											class="task-badge"
											*ngIf="
												item?.total_points != '0' && item?.total_points != null
											"
										>
											<i
												class="pi pi-check-square"
												style="font-size: 14px"
												[ngStyle]="{
													color:
														(item?.total_checked_points ==
															item?.total_points) !=
														'0'
															? '#61bd4f'
															: '#5e6c84'
												}"
											></i>
											<span
												>{{ item?.total_checked_points }}/{{
													item?.total_points
												}}</span
											>
										</div>
										<div class="task-badge" *ngIf="item?.relateds_numbers">
											<i class="pi pi-share-alt"></i>
											<span>{{ item?.relateds_numbers | arrToStr }}</span>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<!-- end main status new -->
			<!-- start main status in progress -->
			<div
				class="col-12 col-sm-6 col-md-3"
				style="min-height: 130px; margin-bottom: 15px"
			>
				<div class="main-status">
					<div class="ms-header">
						<h3 class="ms-header-title" *ngIf="!inprogressList.length">
							{{ "lang_in_progress" | language }}
						</h3>
						<h3 class="ms-header-title" *ngIf="inprogressList.length">
							{{ "lang_in_progress" | language }} ({{ inprogressList.length }})
						</h3>
						<button mat-button class="ms-header-action">
							<i class="fa fa-ellipsis-h mnabricon"></i>
						</button>
					</div>
					<div class="ms-content">
						<div class="msc-list">
							<ng-container *ngFor="let item of inprogressList">
								<div
									class="mscl-item"
									(click)="openTask(item)"
									*ngIf="item?.is_can_view"
								>
									<div
										class="mscli-labels"
										id="mscli-labels{{ item?.task_id }}"
										*ngIf="
											item?.important_id != null && item?.important_id != '0'
										"
									>
										<div
											class="mscli-labels-label"
											[ngStyle]="{
												'background-color':
													item?.important_id == '1' || item?.important_id == '2'
														? '#61bd4f'
														: '#eb5a46'
											}"
											(click)="
												showTaskLabels($event, 'mscli-labels' + item?.task_id)
											"
										>
											<span>{{ item?.important_name }}</span>
										</div>
										<div
											class="mscli-labels-label"
											style="background-color: #0079bf"
											(click)="
												showTaskLabels($event, 'mscli-labels' + item?.task_id)
											"
											*ngIf="item?.status_done_name"
										>
											<span>{{ item?.status_done_name }}</span>
										</div>
									</div>
									<div class="mscli-header">
										<h4 class="mscli-header-title">{{ item?.title }}</h4>
									</div>
									<div class="mscli-content">
										<div
											class="task-badge"
											*ngIf="
												item?.total_points != '0' && item?.total_points != null
											"
										>
											<i
												class="pi pi-check-square"
												style="font-size: 14px"
												[ngStyle]="{
													color:
														(item?.total_checked_points ==
															item?.total_points) !=
														'0'
															? '#61bd4f'
															: '#5e6c84'
												}"
											></i>
											<span
												>{{ item?.total_checked_points }}/{{
													item?.total_points
												}}</span
											>
										</div>
										<div class="task-badge" *ngIf="item?.relateds_numbers">
											<i class="pi pi-share-alt"></i>
											<span>{{ item?.relateds_numbers | arrToStr }}</span>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<!-- end main status in progress -->
			<!-- start main status confirm -->
			<div
				class="col-12 col-sm-6 col-md-3"
				style="min-height: 130px; margin-bottom: 15px"
			>
				<div class="main-status">
					<div class="ms-header">
						<h3 class="ms-header-title" *ngIf="!confirmList.length">
							{{ "lang_confirm" | language }}
						</h3>
						<h3 class="ms-header-title" *ngIf="confirmList.length">
							{{ "lang_confirm" | language }} ({{ confirmList?.length }})
						</h3>
						<button mat-button class="ms-header-action">
							<i class="fa fa-ellipsis-h mnabricon"></i>
						</button>
					</div>
					<div class="ms-content">
						<div class="msc-list">
							<ng-container *ngFor="let item of confirmList">
								<div
									class="mscl-item"
									(click)="openTask(item)"
									*ngIf="item?.is_can_view"
								>
									<div
										class="mscli-labels"
										id="mscli-labels{{ item?.task_id }}"
										*ngIf="
											item?.important_id != null && item?.important_id != '0'
										"
									>
										<div
											class="mscli-labels-label"
											[ngStyle]="{
												'background-color':
													item?.important_id == '1' || item?.important_id == '2'
														? '#61bd4f'
														: '#eb5a46'
											}"
											(click)="
												showTaskLabels($event, 'mscli-labels' + item?.task_id)
											"
										>
											<span>{{ item?.important_name }}</span>
										</div>
										<div
											class="mscli-labels-label"
											style="background-color: #0079bf"
											(click)="
												showTaskLabels($event, 'mscli-labels' + item?.task_id)
											"
											*ngIf="item?.status_done_name"
										>
											<span>{{ item?.status_done_name }}</span>
										</div>
									</div>
									<div class="mscli-header">
										<h4 class="mscli-header-title">{{ item?.title }}</h4>
									</div>
									<div class="mscli-content">
										<div
											class="task-badge"
											*ngIf="
												item?.total_points != '0' && item?.total_points != null
											"
										>
											<i
												class="pi pi-check-square"
												style="font-size: 14px"
												[ngStyle]="{
													color:
														(item?.total_checked_points ==
															item?.total_points) !=
														'0'
															? '#61bd4f'
															: '#5e6c84'
												}"
											></i>
											<span
												>{{ item?.total_checked_points }}/{{
													item?.total_points
												}}</span
											>
										</div>
										<div class="task-badge" *ngIf="item?.relateds_numbers">
											<i class="pi pi-share-alt"></i>
											<span>{{ item?.relateds_numbers | arrToStr }}</span>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<!-- end main status confirm -->
			<!-- start main status done -->
			<div
				class="col-12 col-sm-6 col-md-3"
				style="min-height: 130px; margin-bottom: 15px"
			>
				<div class="main-status">
					<div class="ms-header">
						<h3 class="ms-header-title" *ngIf="!doneList.length">
							{{ "lang_done" | language }}
						</h3>
						<h3 class="ms-header-title" *ngIf="doneList.length">
							{{ "lang_done" | language }} ({{ doneList?.length }})
						</h3>
						<button mat-button class="ms-header-action">
							<i class="fa fa-ellipsis-h mnabricon"></i>
						</button>
					</div>
					<div class="ms-content">
						<div class="msc-list">
							<ng-container *ngFor="let item of doneList">
								<div
									class="mscl-item"
									(click)="openTask(item)"
									*ngIf="item?.is_can_view"
								>
									<div
										class="mscli-labels"
										id="mscli-labels{{ item?.task_id }}"
										*ngIf="
											item?.important_id != null && item?.important_id != '0'
										"
									>
										<div
											class="mscli-labels-label"
											[ngStyle]="{
												'background-color':
													item?.important_id == '1' || item?.important_id == '2'
														? '#61bd4f'
														: '#eb5a46'
											}"
											(click)="
												showTaskLabels($event, 'mscli-labels' + item?.task_id)
											"
										>
											<span>{{ item?.important_name }}</span>
										</div>
										<div
											class="mscli-labels-label"
											style="background-color: #0079bf"
											(click)="
												showTaskLabels($event, 'mscli-labels' + item?.task_id)
											"
											*ngIf="item?.status_done_name"
										>
											<span>{{ item?.status_done_name }}</span>
										</div>
									</div>
									<div class="mscli-header">
										<h4 class="mscli-header-title">{{ item?.title }}</h4>
									</div>
									<div class="mscli-content">
										<div
											class="task-badge"
											*ngIf="
												item?.total_points != '0' && item?.total_points != null
											"
										>
											<i
												class="pi pi-check-square"
												style="font-size: 14px"
												[ngStyle]="{
													color:
														(item?.total_checked_points ==
															item?.total_points) !=
														'0'
															? '#61bd4f'
															: '#5e6c84'
												}"
											></i>
											<span
												>{{ item?.total_checked_points }}/{{
													item?.total_points
												}}</span
											>
										</div>
										<div class="task-badge" *ngIf="item?.relateds_numbers">
											<i class="pi pi-share-alt"></i>
											<span>{{ item?.relateds_numbers | arrToStr }}</span>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<!-- end main status in done -->
		</div>
	</div>
</section>

<!-- user template popover -->
<ng-template #test>
	<div class="user-popover">
		<div class="up-header">
			<div class="up-header-content">
				<div class="up-header-img">
					<img src="assets/images/avatar.png" alt="" />
				</div>
				<div class="up-header-details">
					<h3>{{ user_popover.first_name }} {{ user_popover.last_name }}</h3>
					<p>@{{ user_popover.user_name }}</p>
				</div>
			</div>
		</div>
		<div class="up-body"></div>
	</div>
</ng-template>
