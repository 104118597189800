<form [formGroup]="form" class="form" (ngSubmit)="completeStep4()">
	<div class="card">
		<div class="card-header">
			{{ "lang_recipient_verification" | language }}
			<i
				style="padding: 0 10px"
				class="fa fa-info-circle orangestatus-color"
				matTooltip="{{ 'lang_recipient_verification_tooltip' | language }}"
			></i>
		</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
						<label>{{ "lang_reserve_the_transaction" | language }} ?!</label>
						<section class="example-section">
							<mat-checkbox
								formControlName="teserve_transaction"
								class="example-margin"
							></mat-checkbox>
						</section>
					</div>
				</div>
			</div>
		</div>
		<div class="pl-2 pr-2">
			<div class="row">
				<div class="col-md-12 col-sm-8 col-xs-12 mb-10">
					<app-alert id="default-alert"></app-alert>
				</div>
				<div class="col-sm-8"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<input
						type="submit"
						value="{{ 'lang_submit' | language }}"
						class="albutton mnabrbutton mat-accent"
						mat-button
					/>
				</div>
			</div>
		</div>
	</div>
</form>

<!--steps history-->
<app-ncr-steps-history
	[allData]="allData"
	[step_no]="5"
></app-ncr-steps-history>
