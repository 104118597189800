<mat-tab-group
	(selectedTabChange)="selectedListTabValue($event)"
	class="wafi-settings"
>
	<mat-tab label="manpower">
		<ng-template mat-tab-label>{{ "lang_manpower" | language }}</ng-template>
		<ng-container>
			<div class="p-2">
				<app-wafi-manpower
					[projects_work_area_id]="projects_work_area_id"
					[projects_profile_id]="projects_profile_id"
					[projects_supervision_id]="projects_supervision_id"
				></app-wafi-manpower>
			</div>
		</ng-container>
	</mat-tab>
	<mat-tab label="extra_data">
		<ng-template mat-tab-label>{{ "lang_extra_data" | language }}</ng-template>
		<ng-container>
			<div class="p-2">
				<app-wafi-extra-data
					[projects_work_area_id]="projects_work_area_id"
				></app-wafi-extra-data>
			</div>
		</ng-container>
	</mat-tab>
	<mat-tab label="custom_divisions">
		<ng-template mat-tab-label>{{
			"lang_custom_divisions" | language
		}}</ng-template>
		<ng-container>
			<div class="p-2">
				<app-wafi-custom-divisions
					[projects_work_area_id]="projects_work_area_id"
				></app-wafi-custom-divisions>
			</div>
		</ng-container>
	</mat-tab>
</mat-tab-group>
