<form [formGroup]="form" (ngSubmit)="completeStep3()">
	<div class="card">
		<div class="card-header">
			{{ "lang_finicla_managers" | language }}
		</div>
		<div class="card-body pad0">
			<div class="mbrl15">
				<div class="row mb-10">
					<div class="col-sm-6 col-xs-12 mb-10">
						<label class="mb-10">
							{{ "lang_transaction_approval" | language }} !?.
							<span class="red"> * </span> </label
						><br />
						<mat-radio-group
							aria-label="Select an option"
							formControlName="approving_status"
							(change)="form.get('note').setValue('')"
						>
							<mat-radio-button value="Reject" style="display: block">{{
								"lang_reject" | language
							}}</mat-radio-button>
							<mat-radio-button value="Return" style="display: block">{{
								"lang_return_to_update" | language
							}}</mat-radio-button>
							<mat-radio-button value="Approve" style="display: block">{{
								"lang_approve" | language
							}}</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="col-sm-6 col-xs-12 mb-10">
						<div
							*ngIf="
								form.get('approving_status').value == 'Return' ||
								form.get('approving_status').value == 'Reject'
							"
						>
							<label
								>{{ "lang_rejected_notes" | language }} :
								<span class="red">*</span></label
							>
							<textarea
								class="form-control almnabrformcontrol"
								formControlName="note"
							></textarea>
						</div>
					</div>
					<div class="col-12 mb-4">
						<div class="itemsdata">
							<label> {{ "lang_notes" | language }}:</label>
							<div class="TableParts">
								<div
									class="table-responsive customResponsive mb-10 tablefields"
								>
									<table border="1" class="table CustomTable text-center">
										<thead>
											<tr>
												<td style="width: 10%">#</td>
												<td style="width: 10%">
													<mat-icon
														class="mnabricon"
														matTooltip="{{ 'lang_add' | language }}"
														(click)="addNotes()"
														>add_box</mat-icon
													>
												</td>
												<td>
													<span matTooltip="{{ 'note' | language }}">{{
														"note" | language
													}}</span>
												</td>
											</tr>
										</thead>
										<tbody
											formArrayName="notes"
											class="AppendList quotationtable"
										>
											<tr
												*ngFor="
													let item of getNotesFormGroup.controls;
													let itemIndex = index;
													let odd = odd;
													let even = even
												"
												[formGroupName]="itemIndex"
												style="background: #ffffff"
											>
												<td class="verticalalignmid">
													{{ itemIndex + 1 }}
												</td>
												<td class="verticalalignmid">
													<mat-icon
														class="mnabricon red"
														matTooltip="{{ 'lang_delete' | language }}"
														(click)="removeNotes(itemIndex)"
														>close</mat-icon
													>
												</td>
												<td>
													<input
														type="text"
														formControlName="note"
														placeholder="{{ 'lang_note' | language }}"
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row pt-3">
					<div class="col-sm-12">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
				<div class="row mb-10">
					<div class="col-sm-4 col-xs-12 mb-10"></div>
					<div class="col-sm-4 col-xs-12 mb-10"></div>
					<div class="col-sm-4 col-xs-12 mb-10">
						<div>
							<input
								type="submit"
								value="{{ 'lang_submit' | language }}"
								class="albutton mnabrbutton mat-accent"
								mat-button
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<!--steps history-->
<app-pq1-steps-history
	[allData]="allData"
	[step_no]="4"
></app-pq1-steps-history>
