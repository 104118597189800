<mat-tab-group class="quotation-mat-tab">
	<mat-tab>
		<ng-template mat-tab-label>{{
			"lang_quotation_type" | language
		}}</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12">
					<app-quotation-types></app-quotation-types>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>{{ "lang_position" | language }}</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12">
					<app-quotation-positions></app-quotation-positions>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>{{
			"lang_terms_conditions" | language
		}}</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12">
					<app-quotation-conditions></app-quotation-conditions>
				</div>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>
