<div class="row wafi_header">
  <div class="table-responsive customResponsive">
    <table border="1" class="table wafi_title" width="100%">
      <tbody>
        <tr>
          <td width="130px">
            <img src="assets/images/logoawafi.PNG" alt="" />
          </td>
          <td width="87.5%">
            <div class="title_content">
              {{
                "supervision consultant monthly report for july-2022" | language
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row content_header">
  <div class="col">
    {{ "lang_risk_register" | language }}
  </div>
</div>
<div class="row programme_work">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody>
        <tr>
          <th rowspan="2">{{ "lang_risks_summary" | language }}</th>
          <th class="highlight">{{ "lang_total_no_of_risks" | language }}</th>
          <th class="highlight">{{ "lang_no_of_closed_risks" | language }}</th>
          <th class="highlight">
            {{ "lang_no_of_open_risks" | language }}
          </th>
          <th class="highlight">
            {{ "lang_no_of_risks_transferred_to_issues" | language }}
          </th>
          <th class="highlight">
            {{ "lang_risks_management_plan_status" | language }}
          </th>
        </tr>
        <tr>
          <td class="highlight">
            {{ report_Data?.total_risk_counts || "---" }}
          </td>
          <td class="highlight">
            {{ no_of_closed_risks }}
          </td>
          <td class="highlight">
            {{ no_of_open_risks }}
          </td>
          <td class="highlight">
            {{ no_of_risks_cause_issue }}
          </td>
          <td class="highlight">
            {{
              report_Data?.risks_management_plan_status || "not_exist"
                | language
            }}
          </td>
        </tr>
        <br />
        <tr>
          <th style="background-color: #595959">#</th>
          <th>{{ "lang_risk_discerption" | language }}</th>
          <th>
            {{ "lang_type" | language }}
          </th>
          <th>
            {{ "lang_continuity" | language }}
          </th>
          <th>
            {{ "lang_impact" | language }}
          </th>
          <th>
            {{ "lang_probability" | language }}
          </th>
          <th>
            {{ "lang_risk_rank" | language }}
          </th>
          <th>
            {{ "lang_impact_description" | language }}
          </th>
          <th>
            {{ "lang_mitigation_plan" | language }}
          </th>
          <th>
            {{ "lang_risk_owner" | language }}
          </th>
          <th>
            {{ "lang_mitigation_by" | language }}
          </th>
          <th>
            {{ "lang_status" | language }}
          </th>
        </tr>
        <tr
          *ngFor="
            let risk of report_Data?.work_area_risk_management;
            let i = index
          "
        >
          <td class="highlight2">{{ i + 1 }}</td>
          <td *ngIf="lang_key == 'ar'">
            {{ risk?.risk_description_ar }}
          </td>
          <td *ngIf="lang_key == 'en'">
            {{ risk?.risk_description_en }}
          </td>
          <td>
            {{ "lang_" + risk?.risk_type | language }}
          </td>
          <td>
            {{ "lang_" + risk?.risk_continuity | language }}
          </td>
          <td>
            {{ risk?.risk_impact }}
          </td>
          <td>
            {{ risk?.risk_probability }}
          </td>
          <td class="bg-warning">
            {{ risk?.risk_rank }}
          </td>
          <td>
            {{ risk?.risk_impact_description }}
          </td>
          <td>
            {{ risk?.risk_mitigation_plan }}
          </td>
          <td>{{ risk?.risk_owner }}</td>
          <td>{{ risk?.risk_mitigation_by }}</td>
          <td class="bg-success">
            {{ "lang_" + risk?.risk_status | language }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
