import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DataService } from "@core/bootstrap/data.service";

@Component({
	selector: "app-meeting-view",
	templateUrl: "./meeting-view.component.html",
	styleUrls: ["./meeting-view.component.scss"],
})
export class MeetingViewComponent implements OnInit {
	@Input() meetingId;
	@Output() returnToMeetings = new EventEmitter<any>();
	@Output() goToChecklist = new EventEmitter<any>();
	meeting;
	constructor(private ds: DataService) {}

	ngOnInit(): void {
		this.getMeetingData();
	}

	back_to_meetings() {
		this.returnToMeetings.emit({
			isViewMeetings: true,
		});
	}
	go_to_checklist() {
		this.goToChecklist.emit({
			showChecklist: true,
			meetingId: this.meetingId,
			meeting: this.meeting,
		});
	}

	getMeetingData() {
		let formData = new FormData();
		formData.append("meeting_id", this.meetingId || "");
		this.ds.post("tasks/get_row_meetings", formData).subscribe((res) => {
			if (res?.status) {
				this.meeting = res?.data;
			} else {
				this.meeting = null;
			}
		});
	}
}
