<form [formGroup]="firstFormGroup">
  <div class="mbrl15 mt-5 mb-5">
    <div class="row mb-md-2">
      <div class="col-md-6 pl-md-5 tx-left">
        <div class="row">
          <div class="col-md-8 mb-3">
            <div class="form-group">
              <label>{{ "lang_branch" | language }} :</label>
              <ng-select
                [items]="branches"
                bindLabel="label"
                bindValue="value"
                clearAllText="Clear"
                formControlName="branch"
                placeholder="{{ 'lang_branch' | language }}"
                #branch
              >
              </ng-select>
              <mat-error *ngIf="branch.touched && branch.errors?.required">{{
                "lang_field_required" | language
              }}</mat-error>
            </div>
          </div>
          <div class="col-md-4 mb-md-10 p-2 label"></div>
        </div>
      </div>
      <div class="col-md-6 pr-md-5">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label
            >{{ "lang_pay_role" | language }}
            {{ "lang_month" | language }}</mat-label
          >
          <input
            matInput
            [matDatepicker]="dp"
            readonly
            formControlName="salary_month"
          />

          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker
            #dp
            startView="multi-year"
            (monthSelected)="setMonthAndYear($event, dp)"
            panelClass="example-month-picker"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>

<div class="w-100 text-start">
  <button
    class="btn mb-2 mx-2 btn-cancel"
    routerLink="/human_resources/payrole/list"
  >
    {{ "lang_cancel" | language }}
  </button>
  <button class="btn mb-2 mx-2 btn-apply" (click)="StepOneSubmit()">
    {{ "lang_next" | language }}
  </button>
</div>
