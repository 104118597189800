<mat-tab-group>
	<mat-tab label="setting">
		<ng-template mat-tab-label>{{ "lang_settings" | language }}</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12">
					<app-contractor-payment-setting
						[supervision_data]="supervision_data"
						[project_data]="project_data"
						[makeActionConfig]="makeActionConfig"
					>
					</app-contractor-payment-setting>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>Payment</ng-template>
		<div class="p-2">
			<div class="row">
				<div class="col-12">
					<app-contract-payment-create
						[supervision_data]="supervision_data"
						[project_data]="project_data"
						[makeActionList]="makeActionList"
						*ngIf="template_type != '1'"
					>
					</app-contract-payment-create>

					<!--Division Template-->
					<app-contractor-payment-division-template
						[supervision_data]="supervision_data"
						[project_data]="project_data"
						[makeActionList]="makeActionList"
						*ngIf="template_type == '1'"
					>
					</app-contractor-payment-division-template>
				</div>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>
