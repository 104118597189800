import {
  trigger,
  transition,
  query,
  style,
  stagger,
  animate,
  state,
} from "@angular/animations";
import { HttpParams } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { PreviewPdfComponent } from "@shared/components/preview-pdf/preview-pdf.component";
import { TransactionNotesModalComponent } from "@shared/components/transaction-notes-modal/transaction-notes-modal.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
@Component({
  selector: "app-tlist",
  templateUrl: "./tlist.component.html",
  styleUrls: ["./tlist.component.scss"],
  animations: [
    trigger("fadeIn", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      transition("void <=> *", animate(1000)),
    ]),
  ],
})
export class TlistComponent implements OnInit {
  modules: any = null;
  statusList = [
    {
      label: this.lang.transform("lang_all_pending_need_action"),
      value: "all_pending_need_action",
    },
    {
      label: this.lang.transform("lang_all_pending"),
      value: "all_pending",
    },
    {
      label: this.lang.transform("lang_all_complete"),
      value: "all_complete",
    },
    {
      label: this.lang.transform("lang_all_sent"),
      value: "all_sent",
    },
  ];
  admin: any = JSON.parse(this.ls.getItem("is_admin"));
  selectedItems = [];
  listData: any = [];
  formlist: any = [];
  nodata: string = this.lang.transform("lang_loading");
  searchKey: string = localStorage.getItem("M_T_S_K") || "";
  filteradmin = localStorage.getItem("M_T_A") || "0";
  searchByForm: any =
    localStorage.getItem("M_T_F") && localStorage.getItem("M_T_F") != ""
      ? localStorage.getItem("M_T_F")
      : null;
  searchByModule: any =
    localStorage.getItem("M_T_M") && localStorage.getItem("M_T_M") != ""
      ? localStorage.getItem("M_T_M")?.split(",")
      : null;
  searchByStatus: any =
    localStorage.getItem("M_T_STATUS") &&
    localStorage.getItem("M_T_STATUS") != ""
      ? localStorage.getItem("M_T_STATUS")
      : "all_pending_need_action";
  pageno: any = localStorage.getItem("M_T_P") || 1;
  pagesize: any = localStorage.getItem("M_T_S") || "10";
  loading = true;
  userId = this.ls.getItem("user_id");
  previewOutgoingModal: any;
  @ViewChild('previewOutgoing') previewOutgoing: any;
  previewOutgoingSrc: any;
  approveOutgoinForm!: FormGroup;
  dialogRefVerifyPasswordOutgoing: any;
  @ViewChild('verifyPasswordOutgoing') verifyPasswordOutgoing: any;
  selected_transaction_request_id!: string;
  selected_transaction_request_description!: string;
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    private sweetAlert: SweetAlertService,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.spinner.hide();
    this.module_lists();
    this.form_lists();
    this.get_form_lists();
    this.buildApproveOutgoinForm();
  }
  public module_lists() {
    this.modules = [];
    this.ds.getActionByUrl([], "tc/getmodulesmydoclist").subscribe((res) => {
      if (res.status) {
        this.modules = res.list;
      }
    });
  }
  public form_lists() {
    this.formlist = [];
    this.ds.getActionByUrl([], "tc/gettcmydoclist").subscribe((res) => {
      if (res.status) {
        this.formlist = res.list;
      }
    });
  }
  public search_transactions() {
    this.pageno = 1;
    this.pagesize = 10;
    this.get_form_lists();
  }
  public loadPageFrm(page) {
    this.pageno = page;
    this.pagesize = this.pagesize;
    this.get_form_lists();
  }
  public loadFrmPagesize(size) {
    this.pageno = 1;
    this.pagesize = size;
    this.get_form_lists();
  }
  public get_form_lists() {
    this.listData = [];
    this.loading = true;
    localStorage.setItem("M_T_S_K", this.searchKey || "");
    localStorage.setItem("M_T_A", this.admin ? this.filteradmin : "0");
    localStorage.setItem("M_T_F", this.searchByForm || "");
    localStorage.setItem(
      "M_T_M",
      this.searchByModule &&
        this.searchByModule &&
        this.searchByModule.length > 0
        ? this.searchByModule
        : ""
    );
    localStorage.setItem("M_T_P", this.pageno);
    localStorage.setItem("M_T_S", this.pagesize);
    localStorage.setItem(
      "M_T_STATUS",
      this.searchByStatus || "all_pending_need_action"
    );
    let param = new FormData();
    param.append("searchKey", this.searchKey || "");
    param.append("searchAdmin", this.admin ? this.filteradmin : "0");
    param.append("searchByForm", this.searchByForm || "");
    param.append(
      "searchByModule",
      this.searchByModule &&
        this.searchByModule != null &&
        this.searchByModule.length > 0
        ? this.searchByModule.toString()
        : ""
    );
    param.append("searchByStatus", this.searchByStatus || "");
    this.ds
      .getActionByUrl(
        this.ds.formData2string(param),
        "tc/list/" + this.pageno + "/" + this.pagesize
      )
      .subscribe(
        (res) => {
          this.loading = false;
          if (res.status) {
            this.listData = res;
          } else {
            this.listData = [];
            this.nodata = res.error;
          }
        },
        (error) => {
          this.loading = false;
          this.listData = [];
          this.nodata =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
  }

  deleteTransaction(list) {
    let body = new HttpParams();
    body.append("transaction_request_id", list?.transaction_request_id);
    this.sweetAlert
      .confirmMessage("Are you sure?", "Confirm")
      .then((result) => {
        if (result.isConfirmed) {
          let data = new FormData();
          data.append("transaction_request_id", list?.transaction_request_id);
          this.spinner.show();
          if (
            list?.transaction_key == "FORM_HRV1" ||
            list?.transaction_key == "FORM_HRJ1" ||
            list?.transaction_key == "FORM_OVR1" ||
            list?.transaction_key == "FORM_VOL1" ||
            list?.transaction_key == "FORM_BNS1" ||
            list?.transaction_key == "FORM_DET1" ||
            list?.transaction_key == "FORM_C1" ||
            list?.transaction_key == "FORM_C2" ||
            list?.transaction_key == "FORM_PQ1" ||
            list?.transaction_key == "FORM_ECT1" ||
            list?.transaction_key == "FORM_JF" ||
            list?.transaction_key == "FORM_HRLN1" ||
            list?.transaction_key == "FORM_SAL"
          ) {
            this.ds.post(`form/${list?.transaction_key}/dr`, data).subscribe(
              (res) => {
                this.spinner.hide();
                if (res.status) {
                  this.sweetAlert.successMessage(
                    this.lang.transform("lang_transaction_deleted_successfully")
                  );
                  this.get_form_lists();
                } else {
                  this.sweetAlert.errorMessage(res.error);
                }
              },
              (err) => {
                this.spinner.hide();
              }
            );
          } else {
            this.ds
              .deleteActionById(
                list?.transaction_request_id,
                `form/${list?.transaction_key}/dr`
              )
              .subscribe(
                (res) => {
                  this.spinner.hide();
                  if (res.status) {
                    this.sweetAlert.successMessage(
                      this.lang.transform(
                        "lang_transaction_deleted_successfully"
                      )
                    );
                    this.get_form_lists();
                  } else {
                    this.sweetAlert.errorMessage(res.error);
                  }
                },
                (err) => {
                  this.spinner.hide();
                }
              );
          }
        }
      });
  }

  cancelTransaction(event, list) {
    event?.stopPropagation();
    
    let requestBody = new FormData();
    requestBody.append('transaction_request_id', list?.transaction_request_id);
    this.ds
    .post(
      `form/${list?.transaction_key}/cancel_request`,
      requestBody
    )
    .subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.sweetAlert.successMessage(
            this.lang.transform(
              "lang_transaction_cancelled_successfully"
            )
          );
          this.get_form_lists();
        } else {
          this.sweetAlert.errorMessage(res.error);
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  previewOutgoingIncomingPDF(transactionId: string, transaction_key: string) {
    this.spinner.show();
    this.ds.previewIncomingOutgoing(transactionId, transaction_key).pipe(tap((response: any) => {
      this.spinner.hide();
      if (response?.status) {
        this.previewOutgoingSrc = response?.base64;
        let src = "data:" + response["content-type"] + ";base64," + response.base64;
        this.previewOutgoingModal = this.dialog.open(PreviewPdfComponent, {
          width: '80vw',
          autoFocus: false,
          data: {
            src: src,
            base64: response.base64,
          },
        });
      } else {
        this.sweetAlert.errorToast(response?.error, 3000);
      }

    }), catchError(err => {
      this.spinner.hide();
      return of(err);
    })).subscribe();
  }

  emptyApproveOutgoinForm(transaction_request_id: string, transaction_request_desc: string, transaction_key: string) {
    this.approveOutgoinForm.reset();
    this.approveOutgoinForm.get("transaction_request_id")?.setValue(transaction_request_id);
    this.approveOutgoinForm.get("transaction_key")?.setValue(transaction_key);
    this.selected_transaction_request_id = transaction_request_id;
    this.selected_transaction_request_description = transaction_request_desc;
    this.dialogRefVerifyPasswordOutgoing = this.dialog.open(this.verifyPasswordOutgoing, {
      width: '50vw',
      autoFocus: false,
    });
  }

  buildApproveOutgoinForm() {
    this.approveOutgoinForm = new FormGroup({
      transaction_request_id: new FormControl('', [
        Validators.required,
      ]),
      transaction_key: new FormControl('', [
        Validators.required,
      ]),
      user_pass: new FormControl("", [Validators.required]),
    });
  }

  closeVerifyPasswordOutgoing() {
    this.dialogRefVerifyPasswordOutgoing?.close();
  }

  approveOutgoingForm() {
    this.spinner.show();
    const bodyReq = {
      transaction_request_id: this.approveOutgoinForm.value?.transaction_request_id,
      user_pass: this.approveOutgoinForm.value?.user_pass,
    }
    this.ds
      .postActionByUrl(
        this.ds.json2formdata(bodyReq),
        `form/${this.approveOutgoinForm.value?.transaction_key}/cm`
      )
      .subscribe(
        (data) => {
          this.spinner.hide()
          if (data.status) {
            this.sweetAlert.successToast(data.msg, 3000);
            this.approveOutgoinForm.reset();
            this.closeVerifyPasswordOutgoing();
            this.get_form_lists();
          } else {
            this.sweetAlert.errorMessage(data.error);
          }
        },
        (error) => {
          this.spinner.hide()
          this.sweetAlert.errorMessage(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }

  onShowNotes(item:any) {
    const dialogRef = this.dialog.open(TransactionNotesModalComponent, {
      autoFocus: false,
      width: '80vw',
      height: '80vh',
      data: {
        transaction_request_id: item?.transaction_request_id,
        last_step: item?.transaction_request_last_step,
        type: item?.transaction_key
      }
    })
  }

  stopPropagation(event:any) {
    event.stopPropagation();
  }
}
