<h3 mat-dialog-title class="d-flex align-items-center justify-content-between">
	<span>{{ "lang_advanced_filter" | language }}</span>
	<i class="fa fa-close mnabricon" mat-dialog-close></i>
</h3>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="filtersForm">
		<div class="row">
			<div class="col-12 col-sm-6 mb-2">
				<label for="unit">{{ "lang_unit" | language }}</label>
				<ng-select
					[items]="unitList"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_unit' | language }}"
					formControlName="unit_ids"
					id="unit"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="title">{{ "lang_title" | language }}</label>
				<ng-select
					[items]="titlesList"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_title' | language }}"
					formControlName="title_ids"
					id="title"
					(change)="getDataForFilters('subTitles')"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="sub_title">{{ "lang_sub_title" | language }}</label>
				<ng-select
					[items]="subTitlesList"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_sub_title' | language }}"
					formControlName="sub_title_ids"
					id="sub_title"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="template">{{ "lang_template" | language }}</label>
				<ng-select
					[items]="templatesList"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_template' | language }}"
					formControlName="template_ids"
					id="template"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="platform">{{ "lang_platform" | language }}</label>
				<ng-select
					[items]="platformList"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_platform' | language }}"
					formControlName="platform_code_systems"
					id="platform"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="worklevel">{{ "lang_worklevel" | language }}</label>
				<ng-select
					[items]="worklevelsList"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_worklevel' | language }}"
					formControlName="platform_work_level_keys"
					id="worklevel"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="group1">{{ "lang_group_one" | language }}</label>
				<ng-select
					[items]="group1List"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_group_one' | language }}"
					formControlName="platform_group1_code_systems"
					id="group1"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="type">{{ "lang_type" | language }}</label>
				<ng-select
					[items]="typeList"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_type' | language }}"
					formControlName="platform_group_type_code_systems"
					id="type"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="group2">{{ "lang_group_two" | language }}</label>
				<ng-select
					[items]="group2List"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_group_two' | language }}"
					formControlName="platform_group2_code_systems"
					id="group2"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="zone">{{ "lang_zone" | language }}</label>
				<ng-select
					[items]="zones"
					[multiple]="false"
					bindLabel="label"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_zone' | language }}"
					formControlName="phase_zone_nos"
					id="zone"
					(change)="get_blocks()"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="block">{{ "lang_blocks" | language }}</label>
				<ng-select
					[items]="blocks"
					[multiple]="false"
					bindLabel="label"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_blocks' | language }}"
					formControlName="phase_zone_block_nos"
					id="block"
					(change)="get_clusters()"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<label for="cluster">{{ "lang_clusters" | language }}</label>
				<ng-select
					[items]="clusters"
					[multiple]="false"
					bindLabel="label"
					appendTo="body"
					clearAllText="Clear"
					placeholder="{{ 'lang_clusters' | language }}"
					formControlName="phase_zone_block_cluster_nos"
					id="cluster"
					[notFoundText]="'lang_no_data' | language"
				>
				</ng-select>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<mat-form-field appearance="outline">
					<mat-label>{{ "lang_date" | language }}</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input
							matStartDate
							formControlName="transaction_date_start"
							placeholder="YYYY/MM/DD"
							readonly
						/>
						<input
							matEndDate
							formControlName="transaction_date_end"
							placeholder="YYYY/MM/DD"
							readonly
						/>
					</mat-date-range-input>
					<mat-datepicker-toggle
						matSuffix
						[for]="picker"
					></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
			<div class="col-12 col-sm-6 mb-2">
				<mat-checkbox formControlName="pending_levels" class="d-block"
					>Pending Levels</mat-checkbox
				>
				<mat-checkbox formControlName="paid_levels">Paid Levels</mat-checkbox>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div style="width: 100px">
		<button
			mat-button
			class="albutton mnabrbutton mat-accent"
			(click)="onSubmit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
