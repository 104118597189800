import { NgModule } from '@angular/core';
import { SuppliersRoutingModule } from './suppliers-routing.module';
import { SpsettingsComponent } from './spsettings/spsettings.component';
import { SpcreateComponent } from './spcreate/spcreate.component';
import { SplistComponent } from './splist/splist.component';
import { SpviewComponent } from './spview/spview.component';
import { SpeditComponent } from './spedit/spedit.component';
import { SharedModule } from '@shared';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/custom-material-config';
import { SpUsersComponent } from './sp-users/sp-users.component';
import { SpAttachmentsComponent } from './sp-attachments/sp-attachments.component';
import { SpNotesComponent } from './sp-notes/sp-notes.component';


const COMPONENTS = [SpsettingsComponent, SpcreateComponent, SplistComponent, SpviewComponent, SpeditComponent];
const COMPONENTS_DYNAMIC = [];
@NgModule({
  imports: [
    SharedModule,
    SuppliersRoutingModule,
    ModalModule.forRoot()
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, SpUsersComponent, SpAttachmentsComponent, SpNotesComponent],
  entryComponents: COMPONENTS_DYNAMIC,
  providers : [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ]
})
export class SuppliersModule { }