import { Component, OnInit } from '@angular/core';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-snag-excel-history',
  templateUrl: './snag-excel-history.component.html',
  styleUrls: ['./snag-excel-history.component.scss']
})
export class SnagExcelHistoryComponent implements OnInit {
  snag_list_files = [];
  constructor(private ds:DataService, private sweetAlert: SweetAlertService, private lang:LanguagePipe, private spinner:PreloaderService) { }

  ngOnInit(): void {
    this.getSnagExcelHistory();
  }

  getSnagExcelHistory() {
    this.spinner.show();
    this.ds.get('p/snag/history').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.snag_list_files = res?.records;
      } else {
        this.snag_list_files = [];
      }
    }, error => {
      this.spinner.hide();
      this.snag_list_files   = [];
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    });
  }

  downloadFile(path) {
    this.spinner.show();
    this.ds.get(path).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.ds.downloadFile(res);
      } else {
        this.sweetAlert.errorToast(res?.error, 2000);
      }
    }, error => {
      this.spinner.hide();
      this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 2000);
    });
  }

}
