import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataService } from "@core/bootstrap/data.service";
import { PreloaderService } from "@core";
import { AlertService } from "@shared/components/alert/alert.service";
import { Router } from "@angular/router";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";

@Component({
  selector: "app-create-keys",
  templateUrl: "./create-keys.component.html",
  styleUrls: ["./create-keys.component.scss"],
})
export class CreateKeysComponent implements OnInit {
  _branch_id;
  @Input() set branch_id(data: any) {
    this._branch_id = data;
    this.load_branches();
  }
  form: FormGroup;
  branches: any[];
  lang_key;
  check_update = null;
  branch_name = "";
  invoiceTypes = [
    {
      label: this.lang.transform("lang_standard_tax_invoice"),
      value: "1000",
    },
    {
      label: this.lang.transform("lang_simplified_tax_invoice"),
      value: "0100",
    },
    {
      label: this.lang.transform("lang_standard_and_simplified_tax_invoice"),
      value: "1100",
    },
  ];
  constructor(
    public ds: DataService,
    public spinner: PreloaderService,
    public alert: AlertService,
    public router: Router,
    public lang: LanguagePipe,
    private sweetAlert: SweetAlertService,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.buildform();
    this.get_update();
  }
  buildform() {
    this.form = new FormGroup({
      tax_number: new FormControl("", [Validators.required]),
      branch_name_ar: new FormControl("", [Validators.required]),
      branch_name_en: new FormControl("", [Validators.required]),
      commercial_registration_number: new FormControl(null, [
        Validators.required,
      ]),
      street_name: new FormControl(null, [Validators.required]),
      building_number: new FormControl(null, [Validators.required]),
      plot_identification: new FormControl(null, [Validators.required]),
      city_sub_division: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      postal_number: new FormControl(null, [Validators.required]),
      egs_serial_number: new FormControl(null, [Validators.required]),
      businessCategory: new FormControl(null, [Validators.required]),
      commonName: new FormControl(null, [Validators.required]),
      organizationalUnitName: new FormControl(null, [Validators.required]),
      organizationName: new FormControl(null, [Validators.required]),
      countryName: new FormControl(null, [Validators.required]),
      registeredAddress: new FormControl(null, [Validators.required]),
      is_phase_two: new FormControl("", [Validators.required]),
      is_production: new FormControl("", [Validators.required]),
      otp: new FormControl(null, [Validators.required]),
      emailAddress: new FormControl(null, [Validators.required]),
      invoice_type: new FormControl(null, [Validators.required]),
      id: new FormControl(null, [Validators.required]),
    });
  }
  public create_keys_company() {
    let param = new FormData();
    param.set("tax_number", this.form.get("tax_number").value);
    param.set(
      "commercial_registration_number",
      this.form.get("commercial_registration_number").value || ""
    );
    param.set("branch_name", this.branch_name || "");
    param.set("street_name", this.form.get("street_name").value || "");
    param.set("building_number", this.form.get("building_number").value || "");
    param.set(
      "plot_identification",
      this.form.get("plot_identification").value || ""
    );
    param.set(
      "city_sub_division",
      this.form.get("city_sub_division").value || ""
    );
    param.set("city", this.form.get("city").value || "");

    param.set("postal_number", this.form.get("postal_number").value);
    param.set(
      "egs_serial_number",
      this.form.get("egs_serial_number").value || ""
    );
    param.set(
      "businessCategory",
      this.form.get("businessCategory").value || ""
    );
    param.set("commonName", this.form.get("commonName").value || "");
    param.set(
      "organizationalUnitName",
      this.form.get("organizationalUnitName").value || ""
    );
    param.set(
      "organizationName",
      this.form.get("organizationName").value || ""
    );
    param.set("countryName", this.form.get("countryName").value || "");
    param.set(
      "registeredAddress",
      this.form.get("registeredAddress").value || ""
    );

    param.set("is_phase_two", this.form.get("is_phase_two").value);
    param.set("is_production", this.form.get("is_production").value || "");
    param.set("otp", this.form.get("otp").value || "");
    param.set("emailAddress", this.form.get("emailAddress").value || "");
    param.set("invoice_type", this.form.get("invoice_type").value || "");
    this.spinner.show();
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "accounts/create_tax_save").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.alert.success(res.msg);
          } else {
            this.alert.error(res.error);
            this.form.reset();
          }
        },
        (error) => {
          this.spinner.hide();
          this.form.reset();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public update_keys_company() {
    let param = new FormData();
    param.set("id", this._branch_id);
    param.set("tax_number", this.form.get("tax_number").value);
    param.set(
      "commercial_registration_number",
      this.form.get("commercial_registration_number").value || ""
    );
    param.set("branch_name", this.branch_name || "");
    param.set("street_name", this.form.get("street_name").value || "");
    param.set("building_number", this.form.get("building_number").value || "");
    param.set(
      "plot_identification",
      this.form.get("plot_identification").value || ""
    );
    param.set(
      "city_sub_division",
      this.form.get("city_sub_division").value || ""
    );
    param.set("city", this.form.get("city").value || "");

    param.set("postal_number", this.form.get("postal_number").value);
    param.set(
      "egs_serial_number",
      this.form.get("egs_serial_number").value || ""
    );
    param.set(
      "businessCategory",
      this.form.get("businessCategory").value || ""
    );
    param.set("commonName", this.form.get("commonName").value || "");
    param.set(
      "organizationalUnitName",
      this.form.get("organizationalUnitName").value || ""
    );
    param.set(
      "organizationName",
      this.form.get("organizationName").value || ""
    );
    param.set("countryName", this.form.get("countryName").value || "");
    param.set(
      "registeredAddress",
      this.form.get("registeredAddress").value || ""
    );

    param.set("is_phase_two", this.form.get("is_phase_two").value);
    param.set("is_production", this.form.get("is_production").value || "");
    param.set("otp", this.form.get("otp").value || "");
    param.set("emailAddress", this.form.get("emailAddress").value || "");
    param.set("invoice_type", this.form.get("invoice_type").value || "");
    this.spinner.show();
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "accounts/tax_save").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.alert.success(res.msg);
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public get_update() {
    let param = new FormData();
    param.set("branch_id", this._branch_id);
    this.spinner.show();
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "accounts/get_row_branch").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.form.patchValue(res.data);
            this.form.get("id").setValue(res.data.branch_id);
            this.check_update = true;
          } else {
            this.check_update = null;
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public load_branches() {
    this.spinner.show();
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_add").subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.branches = res.records;
          this.branches.forEach((element) => {
            if (element.id == this._branch_id) {
              this.branch_name = element.title;
            }
          });
          this.spinner.hide();
        }
      })
    );
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
