import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddTicketComponent } from "./add-ticket/add-ticket.component";
import { EditTicketComponent } from "./edit-ticket/edit-ticket.component";
import { SettingsComponent } from "./settings/settings/settings.component";
import { TemplateListComponent } from "./templates/template-list/template-list.component";
import { TemplateMainComponent } from "./templates/template-main/template-main.component";
import { TemplateViewComponent } from "./templates/template-view/template-view.component";
import { TicketsTransactionsComponent } from "./tickets-transactions/tickets-transactions.component";
import { TicketTimelineComponent } from "./view-ticket/ticket-timeline/ticket-timeline.component";
import { TrackingTicketComponent } from "./view-ticket/tracking-ticket/tracking-ticket.component";
import { ViewTaskFullComponent } from "./view-ticket/view-task/view-task-full/view-task-full.component";

const routes: Routes = [
	{
		path: "alltasks",
		component: TicketsTransactionsComponent,
	},
	{
		path: "view/:ticket_id",
		component: TrackingTicketComponent,
	},
	{
		path: "timeline/:ticket_id",
		component: TicketTimelineComponent,
	},
	{
		path: "edit/:ticket_id",
		component: EditTicketComponent,
	},
	{
		path: "create_ticket",
		component: AddTicketComponent,
	},
	{
		path: "view/:ticket_id/task/:task_id",
		component: ViewTaskFullComponent,
	},
	{
		path: "settings",
		component: SettingsComponent,
	},
	{
		path: "templates",
		component: TemplateMainComponent,
	},
	{
		path: "templates/module/:module_id",
		component: TemplateListComponent,
	},
	{
		path: "templates/view/:template_id",
		component: TemplateViewComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class TasksRoutingModule {}
