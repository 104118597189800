import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { BehaviorSubject, forkJoin, Observable, Subscription } from "rxjs";
import { environment } from "@env/environment";
import { LocalStorage } from "./localstorage.service";
import { MatDialog } from "@angular/material/dialog";
import { AlertDialogComponent } from "@shared/components/alert-dialog/alert-dialog.component";
import { PreloaderService } from "./preloader.service";
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import { PreviewPdfComponent } from "@shared/components/preview-pdf/preview-pdf.component";
import * as printJS from "print-js";
declare var $: any;
const frontUrl = environment.frontUrl;
@Injectable({
  providedIn: "root",
})
export class DataService implements OnDestroy {
  languages: any[] = [];
  user_data: any = [];
  phrases: any;
  currentLang: string;
  isRtl: boolean;
  permissions: any = [];
  langChangeSource = new BehaviorSubject<any>(this.currentLanguage());
  err: any = [];
  range: any[];
  taskDates = new BehaviorSubject<any>(null);
  general_work_area_id = new BehaviorSubject<any>(null);
  employee_status_change = new BehaviorSubject<string>("0");
  constructor(
    private http: HttpClient,
    private ls: LocalStorage,
    private dialog: MatDialog,
    public spinner: PreloaderService,
    public sanitizer: DomSanitizer,
    public datepipe: DatePipe
  ) {}
  public select_array_string(form: any, key, field, req = "S") {
    let return_data;
    let data = form.get(key).value;
    if (!Array.isArray(data)) {
      return_data = data;
    } else if (Array.isArray(data) && data.length == 1) {
      if (data[0][field]) {
        return_data = req == "A" ? [data[0][field]] : data[0][field];
      }
    } else if (Array.isArray(data) && data.length > 1) {
      let arraydata = [];
      data.forEach((k, v) => {
        if (k[field]) {
          arraydata.push(k[field]);
        }
      });
      return_data = req == "A" ? arraydata : arraydata.join(",");
    }
    return !return_data || return_data == null ? "" : return_data;
  }
  getdata;
  public f2o(formData) {
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    return JSON.stringify(object);
  }
  public get(url: string, obj: any = {}): Observable<any> {
    if (obj) {
      url = this.formUrlParam(url, obj);
    }
    return this.http.get<any>(environment.SERVER_ORIGIN + url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public get2(url: string, obj: any = {}): Observable<any> {
    if (obj) {
      url = this.formUrlParam(url, obj);
    }
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public forkJoin(urls: string[], getdata?): Observable<any[]> {
    let response: any = [];
    let param = getdata
      ? {
          params: new HttpParams({
            fromString: getdata.toString(),
          }),
        }
      : {};
    for (let i = 0; i < urls.length; i++) {
      response.push(this.http.get(environment.SERVER_ORIGIN + urls[i], param));
    }
    return forkJoin(response);
  }
  public getServerSideScrollList(
    url: string,
    offset: number,
    limit: number
  ): Observable<any> {
    const data: any = {
      page: offset,
      size: limit,
    };
    url = this.formUrlParam(url, data);
    return this.http.get<any>(environment.SERVER_ORIGIN + url).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public post(url: string, reqBody: any): Observable<any> {
    return this.http.post<any>(environment.SERVER_ORIGIN + url, reqBody).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public delete(url: string) {
    return this.http.delete<any>(environment.SERVER_ORIGIN + url).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public put(url: string, reqBody: any): Observable<any> {
    return this.http.put<any>(environment.SERVER_ORIGIN + url, reqBody).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public patch(url: string, reqBody: any): Observable<any> {
    return this.http.patch<any>(environment.SERVER_ORIGIN + url, reqBody).pipe(
      map((data) => {
        return data;
      })
    );
  }
  // for making query params for api urls
  public formUrlParam(url: string, data: any): string {
    let queryString: string = "";
    for (const key in data) {
      if (data.hasOwnProperty(key) && (data[key] || data[key] === 0)) {
        if (!queryString) {
          queryString = `?${key}=${data[key]}`;
        } else {
          queryString += `&${key}=${data[key]}`;
        }
      }
    }
    return url + queryString;
  }
  /*arabicToGiorgen(url: string, date): Observable < any > {
		let arr = date.split('/');
		let orgdate = arr[0] + '-' + arr[1] + '-' + arr[2];
		url = url + '/' + orgdate;
		return this.http.get < any > (environment.SERVER_ORIGIN + url).pipe(map(data => {return data;}));
	}*/
  public arabicToGiorgen(key, date) {
    if (key === "EN") {
      let arr = date.split("/");
      let orgdate = arr[0] + "-" + arr[1] + "-" + arr[2];
      return this.http
        .get<any>(environment.SERVER_ORIGIN + "dateconversion/g2h/" + orgdate)
        .pipe(
          map((data) => {
            return data;
          })
        );
    } else {
      let arr = date.split("/");
      let orgdate = arr[0] + "-" + arr[1] + "-" + arr[2];
      return this.http
        .get<any>(environment.SERVER_ORIGIN + "dateconversion/h2g/" + orgdate)
        .pipe(
          map((data) => {
            return data;
          })
        );
    }
  }
  private currentLanguage(): string {
    return this.currentLang || "en";
  }
  updatedlangSelection(data: string) {
    this.langChangeSource.next(data);
  }
  public is_admin() {
    let is_admin = JSON.parse(this.ls.getItem("is_admin"));
    return is_admin ? true : false;
  }
  public securemobile(mobile) {
    if (mobile) {
      return "******" + mobile.substr(-4, 4);
    }
    return null;
  }
  public secureemail(email) {
    if (email) {
      return (
        email.substr(0, 3) + "*******" + email.substr(email.indexOf(" @ ") + 4)
      );
    }
    return null;
  }
  public getrange(totalpage: number) {
    this.range = [];
    for (let i = 1; i <= totalpage; i++) {
      this.range.push(i);
    }
    return this.range;
  }
  public pagereload() {
    window.location.reload();
  }

  downloadFile(data, title?, type?) {
    if (data.status) {
      let url = "data:" + data["content-type"] + ";base64," + data.base64;
      let tagA = document.createElement("a");
      document.body.appendChild(tagA);
      tagA.style.display = "none";
      tagA.href = url;
      if (type == "blank") {
        tagA.setAttribute("target", "_blank");
      }
      tagA.download = title || "Almnabr";
      tagA.click();
      document.body.removeChild(tagA);
    }
  }

  downloadimage(data, title?, type?) {
    if (data.status) {
      let url = data?.base64;
      let tagA = document.createElement("a");
      document.body.appendChild(tagA);
      tagA.style.display = "none";
      tagA.href = url;
      if (type == "blank") {
        tagA.setAttribute("target", "_blank");
      }
      tagA.download = title || "Almnabr";
      tagA.click();
      document.body.removeChild(tagA);
      console.log(url);
    }
  }

  downloadFileByUrl(url, title) {
    if (url) {
      let tagA = document.createElement("a");
      document.body.appendChild(tagA);
      tagA.style.display = "none";
      tagA.href = url;
      if (this.getBrowserName() == "chrome") {
        tagA.download = title || "Almnabr";
      }
      tagA.click();
      document.body.removeChild(tagA);
    }
  }

  getBrowserName() {
    if (navigator.userAgent.indexOf("Chrome") != -1) {
      return "chrome";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return "firefox";
    }
  }

  public numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  public getname(users: any, id: number) {
    const user = users.find((x) => x.id === id);
    if (user !== undefined && user.id) {
      if (user.name === null) {
        return user.username;
      } else {
        return user.name;
      }
    }
    return "null";
  }
  public arcon(date) {
    if (date && date !== "0") {
      let d = date.split("/");
      return d[2] + "/" + d[1] + "/" + d[0];
    }
    return null;
  }
  public encon(date) {
    if (date && date !== "0") {
      return new Date(date);
    }
    return null;
  }
  public splitwords(x, c) {
    let s = x.split(" ").slice(0, c).join(" ");
    let cnt = x.split(" ");
    if (cnt.length > c) {
      return s + ".....";
    } else {
      return s;
    }
  }
  public formData2string(formData) {
    let httpParams = new HttpParams();
    let object = {};
    if (formData) {
      formData.forEach(function (value, key) {
        object[key] = value;
      });
      for (const key in object) {
        httpParams = httpParams.append(key, object[key]);
      }
      return httpParams.toString();
    } else {
      return "";
    }
  }
  public addCommas(nStr) {
    nStr += "";
    let x = nStr.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }
  public CheckDecimal(value, field) {
    let intVal = parseInt(value);
    if (intVal >= 0 || value.startsWith(".")) {
      field.setValue(parseFloat(value).toFixed(2));
    } else {
      field.setValue("");
    }
  }
  public CheckDecimalNofixed(value, field) {
    let intVal = parseInt(value);
    if (intVal >= 0 || value.startsWith(".")) {
      field.setValue(parseFloat(value));
    } else {
      field.setValue("");
    }
  }
  public birthday(date) {
    let ss = new Date(date);
    let birthdate = new Date(date).getMonth() + 1 + new Date(date).getDate();
    let today = new Date().getMonth() + 1 + new Date().getDate();
    return today === birthdate ? true : false;
  }
  public calculateage(date, str = "You are %s years old") {
    if (date) {
      let today: any = new Date(date);
      let timediff = Math.abs(Date.now() - today);
      let age: any = Math.floor(timediff / (1000 * 3600 * 24) / 365);
      if (age !== 0) {
        return str.replace("%s", age);
      }
    }
    return "";
  }
  //custom request By Raja
  // header with token starts
  // GET Request
  getActionByUrl(getdata, url) {
    return this.http
      .get<any>(environment.SERVER_ORIGIN + url, {
        params: new HttpParams({
          fromString: getdata.toString(),
        }),
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // POST Request
  postActionByUrl(formdata, url) {
    return this.http.post<any>(environment.SERVER_ORIGIN + url, formdata).pipe(
      map((data) => {
        return data;
      })
    );
  }
  // PUT Request
  putActionByUrl(httpParams, url) {
    return this.http.put<any>(environment.SERVER_ORIGIN + url, httpParams).pipe(
      map((data) => {
        return data;
      })
    );
  }
  //DELETE Request
  deleteActionByUrl(ids, url) {
    let httpParams = new HttpParams();
    if (ids.length > 0) {
      ids.forEach((id) => {
        httpParams = httpParams.append("key_ids[]", id);
      });
    }
    const deleteheaders = {
      headers: null,
      body: httpParams.toString(),
    };
    return this.http
      .delete<any>(environment.SERVER_ORIGIN + url, deleteheaders)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  deleteShiftsByUrl(ids, url) {
    let httpParams = new HttpParams();
    if (ids.length > 0) {
      ids.forEach((id) => {
        httpParams = httpParams.append("shift_id[]", id);
      });
    }
    const deleteheaders = {
      headers: null,
      body: httpParams.toString(),
    };
    return this.http
      .delete<any>(environment.SERVER_ORIGIN + url, deleteheaders)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  deleteActionByExtraData(ids, url, extraData) {
    let httpParams = new HttpParams();
    if (ids.length > 0) {
      ids.forEach((id) => {
        httpParams = httpParams.append("key_ids[]", id);
      });
    }
    httpParams = httpParams.append("branch_id", extraData?.branch_id);
    httpParams = httpParams.append("finance_id", extraData?.finance_id);
    const deleteheaders = {
      headers: null,
      body: httpParams,
    };
    return this.http
      .delete<any>(environment.SERVER_ORIGIN + url, deleteheaders)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  //DELETE Request
  deleteActionByUrl2(ids, url) {
    let httpParams = new HttpParams();
    if (ids.length > 0) {
      ids.forEach((id) => {
        httpParams = httpParams.append("key_ids", id);
      });
    }
    const deleteheaders = {
      headers: null,
      body: httpParams.toString(),
    };
    return this.http
      .delete<any>(environment.SERVER_ORIGIN + url, deleteheaders)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteActionByX_www_url_encoded(data, url) {
    var settings = {
      url: environment.SERVER_ORIGIN + url,
      method: "DELETE",
      timeout: 0,
      headers: {
        "X-API-KEY": environment.X_API_KEY,
        authorization:
          localStorage.getItem("loggedin") == "1"
            ? this.ls.getItemString("token")
            : "",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    return $.ajax(settings);
  }

  /* deleteActionByUrlRermissions(body,url) {
		var myHeaders = new HttpHeaders();
		myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

		let options = {
			headers: myHeaders,
			body: body,
		};
		return this.http.delete<any>(environment.SERVER_ORIGIN + url, options).pipe(
			map((data) => {
				return data;
			})
		);
	}
 */

  customDeleteWith_WWW_URL(ids, url) {
    var settings = {
      url: environment.SERVER_ORIGIN + url,
      method: "DELETE",
      timeout: 0,
      headers: {
        authorization:
          localStorage.getItem("loggedin") == "1"
            ? this.ls.getItemString("token")
            : "",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        key_ids: ids.toString(),
      },
    };

    return $.ajax(settings);
  }

  deleteActionById(id, url) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("transaction_request_id", id);
    const deleteheaders = {
      headers: null,
      body: httpParams.toString(),
    };
    return this.http
      .delete<any>(environment.SERVER_ORIGIN + url, deleteheaders)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteByX_WWW(httpParams, url) {
    /* let httpParams = new HttpParams();
		httpParams = httpParams.append("transaction_request_id", id); */
    const deleteheaders = {
      headers: null,
      body: httpParams.toString(),
    };
    return this.http
      .delete<any>(environment.SERVER_ORIGIN + url, deleteheaders)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  //DELETE Request by body
  deleteActionByBody(url, body) {
    var myHeaders = new HttpHeaders();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let options = {
      headers: myHeaders,
      body: body.toString(),
    };
    return this.http.delete<any>(environment.SERVER_ORIGIN + url, options).pipe(
      map((data) => {
        return data;
      })
    );
  }
  PostActionURLEnc(url, body) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let options = {
      headers: myHeaders,
      body: body,
    };
    return this.http.post<any>(environment.SERVER_ORIGIN + url, options).pipe(
      map((data) => {
        return data;
      })
    );
  }

  deleteActionByBody2(param: HttpParams, url: string) {
    var myHeaders = new HttpHeaders();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    const deleteheaders: any = {
      headers: myHeaders,
      body: param.toString(),
    };
    return this.http
      .delete<any>(environment.SERVER_ORIGIN + url, deleteheaders)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }
  // header without token starts
  // GET Request no token
  getActionByNoToken(getdata, url, header = null) {
    return this.http
      .get<any>(environment.SERVER_ORIGIN + url, {
        headers: null,
        params: new HttpParams({
          fromString: getdata.toString(),
        }),
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getWithXapikey(url, header) {
    return this.http
      .get<any>(environment.SERVER_ORIGIN + url, {
        headers: header,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // POST Request no token
  postActionByNoToken(formdata, url, header = null) {
    // environment.SERVER_ORIGIN +
    return this.http.post<any>(environment.SERVER_ORIGIN + url, formdata).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // POST Request no token
  postActionByNoToken2(formdata, url, header = null) {
    // environment.SERVER_ORIGIN +
    return this.http.post<any>(url, formdata).pipe(
      map((data) => {
        return data;
      })
    );
  }
  // PUT Request no token
  putActionByNoToken(httpParams, url, header = null) {
    return this.http.put<any>(environment.SERVER_ORIGIN + url, httpParams).pipe(
      map((data) => {
        return data;
      })
    );
  }

  extendTokenExpiry() {
    return this.http.post(environment.SERVER_ORIGIN + "update_token", {});
  }
  // header with out token ends
  // header with token ends
  // header with out token ends
  // GET Request with Full given URL
  getActionByFullUrl(getdata, url, header = null) {
    return this.http.get<any>(url, {
      headers: header,
      params: new HttpParams({
        fromString: getdata.toString(),
      }),
    });
  }
  // GET Request with Full given URL without Token
  getActionByNoTokenFullUrl(getdata, url, header = null) {
    let params = new HttpParams();
    for (let el in getdata) {
      params.append(`${el}`, getdata[el]);
    }
    return this.http.get<any>(environment.SERVER_ORIGIN + url, {
      headers: header,
      params: getdata,
    });
  }
  // POST Request with Full given URL without Token
  postActionByNoTokenFullUrl(formdata, url, header = null) {
    const myHeaders = header;
    return this.http.post<any>(url, formdata, myHeaders);
  }
  // POST Request with Full given URL
  postActionByFullUrl(formdata, url, header = null) {
    return this.http.post<any>(url, formdata);
  }
  public dialogf(title?, msg?, timeout?) {
    this.dialog.open(AlertDialogComponent, {
      disableClose: true,
      width: "400px",
      data: {
        title: title,
        message: msg,
      },
    });
    if (timeout != "S") {
      setTimeout(() => {
        this.dialog.closeAll();
      }, 5000);
    }
  }
  private _buildFormData(formData, data, parentKey?) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this._buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
    }
  }
  json2formdata(json) {
    let obj = {};
    const formData = new FormData();
    if (typeof json === "string") {
      try {
        obj = JSON.parse(json);
      } catch (err) {}
    } else if (typeof json === "object") {
      obj = json;
    }
    this._buildFormData(formData, obj);
    return formData;
  }
  public PreviewData(url, id = "showPDFPreview") {
    document.querySelector("#mnabrPdf")["src"] = "";
    this.spinner.show();
    this.subscriptions.add(
      this.getActionByUrl([], url).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.fileview(data, id);
          } else {
            this.dialogf("", data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.dialogf("", error.error.error);
        }
      )
    );
  }
  fileview(data, id = "showPDFPreview") {
    if (data.extension != "zip") {
      ($("#" + id) as any).modal({
        backdrop: "static",
        keyboard: false,
      });
      ($("#" + id) as any).modal("show");
    }

    document.querySelector("#mnabrPdf")["src"] =
      "data:" + data["content-type"] + ";base64," + data.base64;
  }
  public previewData(url, id = "#showPDFPreview") {
    $(id + " iframe").attr("src", "");
    this.spinner.show();
    this.subscriptions.add(
      this.getActionByUrl([], url).subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            if (data.extension != "zip") {
              ($(id) as any).modal({
                backdrop: "static",
                keyboard: false,
              });
              ($(id) as any).modal("show");
            }
            $(id + " iframe").attr(
              "src",
              "data:" + data["content-type"] + ";base64," + data.base64
            );
          } else {
            this.dialogf("", data.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.dialogf("", error.error.error);
        }
      )
    );
  }

  public showPreviewWithoutAPi(data, id = "#showPDFPreview") {
    $(id + " iframe").attr("src", "");
    $(id + " iframe").attr("src", "");
    if (data.status) {
      if (data.extension != "zip") {
        ($(id) as any).modal({
          backdrop: "static",
          keyboard: false,
        });
        ($(id) as any).modal("show");
      }
      $(id + " iframe").attr(
        "src",
        "data:" + data["content-type"] + ";base64," + data.base64
      );
    } else {
      this.dialogf("", data.error);
    }
  }

  public preview_pdf_file(url, title?) {
    this.spinner.show();
    this.subscriptions.add(
      this.getActionByUrl([], url).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            if (res.extension == "pdf") {
              let src = "data:" + res["content-type"] + ";base64," + res.base64;
              const dialogRef = this.dialog.open(PreviewPdfComponent, {
                disableClose: false,
                width: "100%",
                data: {
                  src: src,
                  title: title,
                  base64: res.base64,
                },
              });
            } else {
              this.fileview(res);
            }
          }
        },
        (err) => {
          this.spinner.hide();
        }
      )
    );
  }
  public preview_pdf_full_url(url, title?) {
    this.spinner.show();
    this.subscriptions.add(
      this.getActionByFullUrl([], url).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            if (res.extension == "pdf") {
              let src = "data:" + res["content-type"] + ";base64," + res.base64;
              const dialogRef = this.dialog.open(PreviewPdfComponent, {
                disableClose: false,
                width: "100%",
                data: {
                  src: src,
                  base64: res.base64,
                  title: title,
                },
              });
            } else {
              this.fileview(res);
            }
          }
        },
        (err) => {
          this.spinner.hide();
        }
      )
    );
  }

  customSearchFn(term: string, item: any) {
    return item ? true : false;
  }
  public editMe(datas, id, branch_id) {
    let admin = JSON.parse(this.ls.getItem("is_admin"));
    const edit = datas.find((x) => x.branch_id === branch_id);
    if (admin) {
      return true;
    } else if (edit !== undefined) {
      if (edit.values === null || edit.values.includes(id)) {
        return true;
      }
    } else {
      return false;
    }
  }
  public allowMe(module_key, permit_key) {
    /* debugger; */
    let admin = this.ls.getItem("is_admin")
      ? JSON.parse(this.ls.getItem("is_admin"))
      : false;

    if (admin) {
      return true;
    } else if (this.permissions.length > 0) {
      const pallow = this.permissions.find(
        (x) => x.permission_key === permit_key
      );
      if (
        pallow !== undefined &&
        pallow.module_key == module_key &&
        pallow.permission_key
      ) {
        return true;
      }
    }
    return false;
  }
  public setDate(event, form) {
    if (event) {
      form.get("period_from").setValue(event.finance_start_date);
      form.get("period_to").setValue(event.finance_end_date);
    }
  }
  public setENTOARDate(eng, formfield) {
    if (eng && eng.value) {
      this.subscriptions.add(
        this.arabicToGiorgen(
          "EN",
          this.datepipe.transform(eng.value, "dd/MM/yyyy")
        ).subscribe(
          (data) => {
            if (data.status) {
              let r = data.data.hijri.date.split("-");
              let reqdate = r[0] + "/" + r[1] + "/" + r[2];
              formfield.setValue(reqdate);
            } else {
              formfield.setValue(null);
            }
          },
          (error) => {
            formfield.setValue(null);
          }
        )
      );
    }
  }
  public setARTOENDate(arab, formfield) {
    if (arab) {
      this.subscriptions.add(
        this.arabicToGiorgen("AR", arab).subscribe(
          (data) => {
            if (data.status) {
              let r = data.data.gregorian.date.split("-");
              let reqdate = r[2] + "/" + r[1] + "/" + r[0];
              formfield.setValue(new Date(reqdate));
            } else {
              formfield.setValue(null);
            }
          },
          (error) => {
            formfield.setValue(null);
          }
        )
      );
    }
  }

  public setENTOARDateOnlyVal(eng) {
    if (eng) {
      this.subscriptions.add(
        this.arabicToGiorgen(
          "EN",
          this.datepipe.transform(eng, "dd/MM/yyyy")
        ).subscribe(
          (data) => {
            if (data.status) {
              let r = data.data.hijri.date.split("-");
              let reqdate = r[0] + "/" + r[1] + "/" + r[2];
              return reqdate;
            } else {
              return "";
            }
          },
          (error) => {
            return "";
          }
        )
      );
    }
  }

  public setARTOENDateValOnly(arab) {
    if (arab) {
      this.subscriptions.add(
        this.arabicToGiorgen("AR", arab).subscribe(
          (data) => {
            if (data.status) {
              let r = data.data.gregorian.date.split("-");
              let reqdate = r[2] + "/" + r[1] + "/" + r[0];
              return reqdate;
            } else {
              return "";
            }
          },
          (error) => {
            return "";
          }
        )
      );
    }
  }

  public openTransaction(type, branch_id, request_id, finance_id?) {
    let url = "";
    if (type === "RECEIPT" || type === "REC") {
      url =
        frontUrl +
        "accounts/recview/" +
        branch_id +
        "/" +
        request_id +
        "/" +
        finance_id +
        "/view";
    } else if (type === "PAYMENT" || type === "PAY") {
      url =
        frontUrl +
        "accounts/payview/" +
        branch_id +
        "/" +
        request_id +
        "/" +
        finance_id +
        "/view";
    } else if (type === "JOURNAL" || type === "JRN") {
      url =
        frontUrl +
        "accounts/jview/" +
        branch_id +
        "/" +
        request_id +
        "/" +
        finance_id +
        "/view";
    } else if (type === "SINVOICE" || type === "SIV") {
      url =
        frontUrl +
        "accounts/vsinvoices/" +
        branch_id +
        "/" +
        request_id +
        "/" +
        finance_id +
        "/view";
    } else if (type === "PINVOICE" || type === "PIV") {
      url =
        frontUrl +
        "accounts/vpinvoices/" +
        branch_id +
        "/" +
        request_id +
        "/" +
        finance_id +
        "/view";
    } else if (type === "DTN") {
      url =
        frontUrl +
        "accounts/view_invoice_notes/" +
        branch_id +
        "/" +
        request_id +
        "/" +
        finance_id;
    }
    window.open(url, "_blank");
  }
  public export_account_transaction(
    id,
    type,
    request,
    branch_id,
    iddiv = "#showPDFPreview"
  ) {
    this.spinner.show();
    $(iddiv + " iframe").attr("src", "");
    this.subscriptions.add(
      this.getActionByUrl(
        [],
        "accounts/exportdata/" +
          request +
          "/" +
          type +
          "/" +
          id +
          "/" +
          branch_id
      ).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            if (request == "PDF") {
              ($(iddiv) as any).modal({
                backdrop: "static",
                keyboard: false,
              });
              ($(iddiv) as any).modal("show");
              if (res.direct_data) {
                document.querySelector("iframe").src = res.base64;
                document.querySelector("iframe").style.height = "70vh";
              } else {
                $(iddiv + " iframe").attr(
                  "src",
                  "data:" + res["content-type"] + ";base64," + res.base64
                );
                $(iddiv + " iframe").css("height:70vh");
              }
            } else if (request == "EXL") {
              this.downloadFile(res);
            }
            // if (request == "PDF" || request == "EXL") {
            //   if (res.extension != "zip") {
            //     ($(iddiv) as any).modal({
            //       backdrop: "static",
            //       keyboard: false,
            //     });
            //     ($(iddiv) as any).modal("show");
            //   }
            //   if (res.direct_data) {
            //     document.querySelector("iframe").src = res.base64;
            //   } else {
            //     $(iddiv + " iframe").attr(
            //       "src",
            //       "data:" + res["content-type"] + ";base64," + res.base64
            //     );
            //   }
            // } else {
            //   this.dialogf("", res.msg);
            // }
          } else {
            this.dialogf("", res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : "Internal Server Error 501."
          );
        }
      )
    );
  }
  public export_account_reports(data, request, iddiv = "#showPDFPreview") {
    this.spinner.show();
    $(iddiv + " iframe").attr("src", "");
    this.subscriptions.add(
      this.getActionByUrl(
        this.formData2string(data),
        "accounts/exportreports"
      ).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            if (request == "P") {
              ($(iddiv) as any).modal({
                backdrop: "static",
                keyboard: false,
              });
              ($(iddiv) as any).modal("show");
              if (res.direct_data) {
                document.querySelector("iframe").src = res.base64;
                document.querySelector("iframe").style.height = "70vh";
              } else {
                $(iddiv + " iframe").attr(
                  "src",
                  "data:" + res["content-type"] + ";base64," + res.base64
                );
                $(iddiv + " iframe").css("height:70vh");
              }
            } else if (request == "E") {
              this.downloadFile(res);
            }
          } else {
            this.dialogf("", res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : "Internal Server Error 501."
          );
        }
      )
    );
  }
  public print_account_transaction(id, type, request, branch_id) {
    this.spinner.show();
    this.subscriptions.add(
      this.getActionByUrl(
        [],
        "accounts/exportdata/" +
          request +
          "/" +
          type +
          "/" +
          id +
          "/" +
          branch_id
      ).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            if (request == "PDF") {
              if (res.extension != "zip") {
                printJS({ printable: res.base64, type: "pdf", base64: true });
              }
              if (res.direct_data) {
              } else {
              }
            } else {
              this.dialogf("", res.msg);
            }
          } else {
            this.dialogf("", res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.dialogf(
            "",
            error && error.error && error.error.error
              ? error.error.error
              : "Internal Server Error 501."
          );
        }
      )
    );
  }
  public reset_whole_pagination() {
    //Customers
    localStorage.setItem("M_C_S_N", "1"); // Customers Page No
    localStorage.setItem("M_C_S_S", "10"); // Customers Page Size
    localStorage.setItem("M_C_S_B", ""); // Customers Branch
    localStorage.setItem("M_C_S_T", ""); // Customers Type
    localStorage.setItem("M_C_S_S_K", ""); // Customers Search Key
    //Contractors
    localStorage.setItem("M_C_T_N", "1"); // Contractors Page No
    localStorage.setItem("M_C_T_S", "10"); // Contractors Page Size
    localStorage.setItem("M_C_T_B", ""); // Contractors Branch
    localStorage.setItem("M_C_T_T", ""); // Contractors Type
    localStorage.setItem("M_C_T_S_K", ""); // Contractors Search Key
    //Suppliers
    localStorage.setItem("M_S_P_N", "1"); // Suppliers Page No
    localStorage.setItem("M_S_P_S", "10"); // Suppliers Page Size
    localStorage.setItem("M_S_P_B", ""); // Suppliers Branch
    localStorage.setItem("M_S_P_T", ""); // Suppliers Type
    localStorage.setItem("M_S_P_S_K", ""); // Suppliers Search Key
    //Human Resources Employees
    localStorage.setItem("M_H_R_N", "1"); // HR Page No
    localStorage.setItem("M_H_R_S", "10"); // HR Page Size
    localStorage.setItem("M_H_R_B", ""); // HR Branch
    localStorage.setItem("M_H_R_S_K", ""); // HR Search Key
    //Human Resources Applications
    localStorage.setItem("M_H_R_A_N", "1"); // HR application Page No
    localStorage.setItem("M_H_R_A_S", "10"); // HR application Page Size
    localStorage.setItem("M_H_R_A_T", ""); // HR application Type
    localStorage.setItem("M_H_R_A_S_K", ""); // HR application Search Key
    //My Transactions
    localStorage.setItem("M_T_S_K", "");
    localStorage.setItem("M_T_A", "0");
    localStorage.setItem("M_T_F", "");
    localStorage.setItem("M_T_M", "");
    localStorage.setItem("M_T_STATUS", "all_pending_need_action");
    localStorage.setItem("M_T_P", "1");
    localStorage.setItem("M_T_S", "10");
    //Approve Transactions
    localStorage.setItem("A_T_S_K", "");
    localStorage.setItem("A_T_A", "");
    localStorage.setItem("A_T_F", null);
    localStorage.setItem("A_T_M", null);
    localStorage.setItem("A_T_P", "1");
    localStorage.setItem("A_T_S", "10");
    //Projects
    localStorage.setItem("M_P_S_K", "");
    localStorage.setItem("M_P_B", null);
    localStorage.setItem("M_P_S_R", null);
    localStorage.setItem("M_P_P", "1");
    localStorage.setItem("M_P_S", "10");
    //Project Work Area (Supervision)
    localStorage.setItem("M_P_S_S_K", "");
    localStorage.setItem("M_P_S_P", "1");
    localStorage.setItem("M_P_S_S", "10");
    //Accounts Receipts
    localStorage.setItem("AC_RE_S_K", "");
    localStorage.setItem("AC_RE_S_F", null);
    localStorage.setItem("AC_RE_P", "1");
    localStorage.setItem("AC_RE_S", "10");
    //Accounts Payments
    localStorage.setItem("AC_PY_S_K", "");
    localStorage.setItem("AC_PY_S_F", null);
    localStorage.setItem("AC_PY_P", "1");
    localStorage.setItem("AC_PY_S", "10");
    //Accounts Journal Voucher
    localStorage.setItem("AC_JR_S_K", "");
    localStorage.setItem("AC_JR_S_F", null);
    localStorage.setItem("AC_JR_P", "1");
    localStorage.setItem("AC_JR_S", "10");
    //Accounts Selling Invoice
    localStorage.setItem("AC_SI_S_K", "");
    localStorage.setItem("AC_SI_S_F", null);
    localStorage.setItem("AC_SI_P", "1");
    localStorage.setItem("AC_SI_S", "10");
    //Accounts Purchase Invoice
    localStorage.setItem("AC_PI_S_K", "");
    localStorage.setItem("AC_PI_S_F", null);
    localStorage.setItem("AC_PI_P", "1");
    localStorage.setItem("AC_PI_S", "10");
  }
  public print() {
    window.print();
  }
  public uploadSingleFile(event, formfield, id, selectfile?) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      $("#" + id).text(fileid.name);
      formfield.setValue(fileid, { emitEvent: true });
    } else {
      formfield.setValue(null);
      $("#" + id).text(selectfile);
    }
  }
  public isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 44 || charCode == 46) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  public preventSpaces(evt, type) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32 && type == "keypress") {
      return false;
    } else if (type == "paste") {
      setTimeout(() => {
        let val = evt.target.value;
        evt.target.value = val.split(" ").join("");
      }, 100);
    }
    return true;
  }

  checkEmployeeStatus(user_id) {
    let formData = new FormData();
    formData.append("user_id", user_id || "");
    this.post(
      "form/FORM_CT1/check_employee_vocation_status",
      formData
    ).subscribe(
      (res) => {
        if (res?.status) {
          this.ls.setItem("employee_status", "1");
          this.employee_status_change.next("1");
        } else {
          this.ls.setItem("employee_status", "0");
          this.employee_status_change.next("0");
        }
      },
      (err) => {
        this.ls.setItem("employee_status", "0");
        this.employee_status_change.next("0");
      }
    );
  }

  previewIncomingOutgoing(
    transaction_request_id: string,
    transaction_key: string
  ) {
    return this.http.get(
      environment.SERVER_ORIGIN +
        `form/${transaction_key}/pr/${transaction_request_id}`
    );
  }

  getOutgoingFormData(transactionId: string) {
    return this.http.get(
      environment.SERVER_ORIGIN + `form/FORM_C2/vr/${transactionId}`
    );
  }
  getIncomingFormData(transactionId: string) {
    return this.http.get(
      environment.SERVER_ORIGIN + `form/FORM_C1/vr/${transactionId}`
    );
  }

  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
