import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { NgbCalendar, NgbCalendarIslamicUmalqura, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { IslamicI18n } from '@shared/utils/arabicpicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import SignaturePad from 'signature_pad';
declare var $:any;
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n }
  ]
})
export class RegistrationComponent implements OnInit {
  token: string = this.route.snapshot.paramMap.get('token');
  mainhideclass: string = "none";
  employeehideclass: string = "none";
  etitles: any = [];
  banklists: any = [];
  nationality:any = [];
  lang_key = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
  for_show_status = false;
  idfilename = this.lang.transform('lang_select_files');
  fileurl;
  apiurl = environment.SERVER_ORIGIN;
  registrationform: FormGroup;
  errorstatus: boolean = false;
  errormsg: string = "";
  tokendata: any = [];
  employeeform: FormGroup;
  @ViewChild('sPad', { static: true }) signaturePadElement;
  @ViewChild('mPad', { static: true }) markPadElement;
  @ViewChild('padElement', { static: true }) padElement: any;
  width: any = 900;
  height: any = 450;
  signaturePad: any;
  markPad: any;
  constructor(private cdRef: ChangeDetectorRef, public ds: DataService, public route:ActivatedRoute ,public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {
    this.spinner.show();
    this.validtoken();
    this.buildform();
    this.build_employee_form();
  }
  @HostListener('window:resize', ['$event'])
  canvassize(event?) {
    this.width = (this.padElement.nativeElement.offsetWidth > 0) ? this.padElement.nativeElement.offsetWidth : this.width;
    this.height = (this.padElement.nativeElement.offsetHeight > 0) ? this.padElement.nativeElement.offsetHeight : this.height;
    this.cdRef.detectChanges();
  }
  ngAfterViewInit() {
    this.canvassize();
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
      minWidth: 5,
      maxWidth: 8,
      penColor: "rgb(63, 81, 181)",
      backgroundColor: 'rgba(255,255,255, 0)'
    });
    this.markPad = new SignaturePad(this.markPadElement.nativeElement, {
      minWidth: 5,
      maxWidth: 8,
      penColor: "rgb(63, 81, 181)",
      backgroundColor: 'rgba(255,255,255, 0)'
    });
    this.cdRef.detectChanges();
  }
  public validtoken() {
    this.ds.getActionByNoToken([], 'reg/c/' + this.token + '/' + this.lang_key).subscribe(res => {
      this.spinner.hide();
      this.for_show_status = res.status;
      if (res.status) {
        this.mainhideclass = "";
        this.tokendata = res.data;
        this.registrationform.get('personal_id_number').setValue(res.data.personal_id_number || '');
        if (this.tokendata.user_type_id == "1") {
          this.employeeform.get('firstname_english').setValue(res.data.user_registration_firstname_en || '');
          this.employeeform.get('lastname_english').setValue(res.data.user_registration_lastname_en || '');
          this.employeeform.get('firstname_arabic').setValue(res.data.user_registration_firstname_ar || '');
          this.employeeform.get('lastname_arabic').setValue(res.data.user_registration_lastname_ar || '');
          this.employeehideclass = "";
          this.bank_title();
          this.employee_title();
          this.countries();
        } else {
          $("#removableEmployeeDiv").remove();
        }
      } else {
        $("#removableEmployeeDiv").remove();
        this.employeehideclass = "none";
        this.mainhideclass = "none";
        this.tokendata = [];
        this.router.navigate(['/auth/login']);
        this.for_show_status = false;
        this.ds.dialogf('', res.error);
      }
    }, error => {
      $("#removableEmployeeDiv").remove();
      this.employeehideclass = "none";
      this.mainhideclass = "none";
      this.tokendata = [];
      this.spinner.hide();
      this.for_show_status = false;
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      this.router.navigate(['/auth/login']);
    });
  }
  public buildform() {
    this.registrationform = new FormGroup({
      username: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      confirm_password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      terms_conditions: new FormControl(null, [Validators.required]),
      personal_id_number: new FormControl(null),
      token: new FormControl(this.token, [Validators.required]),
    }, { validators: this.passwordConfirming })
  }
  public passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirm_password').value) {
      return { invalid: true };
    }
  }
  public idExists(id) {
    this.ds.getActionByNoToken([], 'signup/id_exists/' + id).subscribe(data => {
      if (data.status) {
        this.registrationform.get('personal_id_number').setValue('');
        this.ds.dialogf('', data.msg);
      }
    }, error => {
      this.registrationform.get('personal_id_number').setValue('');
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public usernameExists(username) {
    this.ds.getActionByNoToken([], 'signup/username_exists/' + username).subscribe(data => {
      if (data.status) {
        this.registrationform.get('username').setValue('');
        this.ds.dialogf('', data.msg);
      }
    }, error => {
      this.registrationform.get('username').setValue('');
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  //build employee data
  public build_employee_form() {
    this.employeeform = new FormGroup({
      firstname_english: new FormControl('', [Validators.required]),
      secondname_english: new FormControl('', [Validators.required]),
      thirdname_english: new FormControl('', [Validators.required]),
      lastname_english: new FormControl('', [Validators.required]),
      firstname_arabic: new FormControl('', [Validators.required]),
      secondname_arabic: new FormControl('', [Validators.required]),
      thirdname_arabic: new FormControl('', [Validators.required]),
      lastname_arabic: new FormControl('', [Validators.required]),
      iqama_expiry_date_english: new FormControl('', [Validators.required]),
      iqama_expiry_date_arabic: new FormControl(null, [Validators.required]),
      birth_date_english: new FormControl('', [Validators.required]),
      birth_date_arabic: new FormControl(null, [Validators.required]),
      copy_number: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      nationality: new FormControl(null, [Validators.required]),
      marital_status: new FormControl('', [Validators.required]),
      religion: new FormControl('', [Validators.required]),
      job_title_iqama: new FormControl('', [Validators.required]),
      employee_title: new FormControl(''),
      primary_address: new FormControl('', [Validators.required]),
      account_number: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(35)]),
      bank_short_code: new FormControl(null, [Validators.required]),
      primary_education_level: new FormControl(''),
      primary_graduation_year: new FormControl('', [Validators.required]),
      passport_number: new FormControl('', [Validators.required]),
      passport_issue_date_english: new FormControl('', [Validators.required]),
      passport_issue_date_arabic: new FormControl('', [Validators.required]),
      passport_expiry_date_english: new FormControl('', [Validators.required]),
      passport_expiry_date_arabic: new FormControl(''),
      passport_issue_place: new FormControl('', [Validators.required]),
      employee_signature: new FormControl('', [Validators.required]),
      employee_mark: new FormControl('', [Validators.required])
    });
  }
  public uploadIDFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.employeeform.get("idFileUpload").setValue(fileid, { emitEvent: true });
    } else {
      this.employeeform.get("idFileUpload").setValue(null);
      this.idfilename = this.lang.transform('lang_select_files');
    }
  }
  public onFileChange(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      var reader = new FileReader();
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        if (event) {
          this.fileurl = (<FileReader>event.target).result;
        }
      };
      this.employeeform.get("profile_image").setValue(file, { emitEvent: true });
    } else {
      this.employeeform.get("profile_image").setValue(null);
      this.fileurl = "";
    }
  }
  public employee_title() {
    this.etitles = [];
    this.ds.getActionByNoToken([], 'signup/get_settings/ETIT/' + this.lang_key).subscribe(res => {
      if (res.status) {
        this.etitles = res.titles;
      }
    })
  }
  public bank_title() {
    this.banklists = [];
    this.ds.getActionByNoToken([], 'signup/get_settings/BANK/' + this.lang_key).subscribe(res => {
      if (res.status) {
        this.banklists = res.titles;
      }
    })
  }
  public countries() {
    this.nationality = [];
    this.ds.getActionByNoToken([], 'countries/'+this.lang_key).subscribe(res => {
      if (res.status) {
        this.nationality = res.records;
      }
    })
  }
  public submit_registration() {
    this.spinner.show();
    let obj: any = {};
    if (this.tokendata && this.tokendata.user_type_id == "1") {
      this.employeeform.get('employee_signature').setValue(null);
      this.employeeform.get('employee_mark').setValue(null);
      if (!this.signaturePad.isEmpty() && !this.markPad.isEmpty()) {
        this.employeeform.get('employee_signature').setValue(this.signaturePad.toDataURL());
        this.employeeform.get('employee_mark').setValue(this.markPad.toDataURL());
      }
      obj = { employee_data: this.employeeform.value };
    }
    Object.assign(obj, this.registrationform.value);
    this.ds.postActionByNoToken(this.ds.json2formdata(obj), 'reg/s').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.alert.success(res.msg);
        this.registrationform.reset();
        setTimeout(() => {
          this.router.navigate(['/auth/login']);
        }, 2000);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}
