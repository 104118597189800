import {
	trigger,
	state,
	style,
	transition,
	animate,
} from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { WhatsPhoneSendcodeComponent } from "@shared/components/whats-phone-sendcode/whats-phone-sendcode.component";
import { LanguagePipe } from "@shared/pipes/language.pipe";
@Component({
	selector: "app-qrcodepdf",
	templateUrl: "./qrcodepdf.component.html",
	styleUrls: ["./qrcodepdf.component.scss"],
	animations: [
		trigger("fade", [
			state("in", style({ opacity: 1 })),
			transition(":enter", [style({ opacity: 0 }), animate(600)]),
			transition(":leave", animate(0, style({ opacity: 0 }))),
		]),
	],
})
export class QrcodepdfComponent implements OnInit {
	public href: string = "";
	form: FormGroup;
	barcode = this.route.snapshot.paramMap.get("barcode");
	code = this.route.snapshot.paramMap.get("code");
	loggedin = JSON.parse(localStorage.getItem("loggedin"));
	lang_key = localStorage.getItem("lang_key") || "en";
	constructor(
		public ds: DataService,
		public router: Router,
		public route: ActivatedRoute,
		public alert: AlertService,
		public fb: FormBuilder,
		public spinner: PreloaderService,
		public lang: LanguagePipe,
		private dialog: MatDialog
	) {
		this.buildform();
		if (this.barcode && this.code) {
			this.submit();
		}
	}
	ngOnInit(): void {}
	buildform() {
		this.form = new FormGroup({
			document_barcode: new FormControl(this.barcode, [Validators.required]),
			document_code: new FormControl(this.code, [Validators.required]),
		});
	}
	submit() {
		// tc/q
		this.ds
			.postActionByNoToken(
				this.ds.json2formdata({
					...this.form.value,
					mobile_num: "",
					sender_type: "",
					mobile_code: "",
				}),
				"tc/q"
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						if (res?.needCode) {
							this.dialog.open(WhatsPhoneSendcodeComponent, {
								width: "500px",
								data: {
									type: "phone",
									document_barcode: this.form.value.document_barcode,
									document_code: this.form.value.document_code,
								},
							});
						} else {
							// this.ds.getActionByFullUrl([], res.url).subscribe(
							// 	(data) => {
							// 		if (data.status) {
							// 			this.ds.fileview(data);
							// 		} else {
							// 			this.ds.dialogf("", data.error);
							// 		}
							// 	},
							// 	(error) => {
							// 		this.ds.dialogf("", this.lang.transform("lang_login_must"));
							// 	}
							// );
							this.ds.preview_pdf_full_url(res?.url);
						}
					} else {
						this.ds.dialogf("", res.error);
						// this.dialog.open(WhatsPhoneSendcodeComponent, {
						// 	width: "500px",
						// 	data: {
						// 		type: "phone",
						// 		document_barcode: this.form.value.document_barcode,
						// 		document_code: this.form.value.document_code,
						// 	},
						// });
					}
				},
				(error) => {
					this.ds.dialogf(
						"",
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
					this.spinner.hide();
				}
			);
	}
}
