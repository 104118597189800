import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";

@Component({
	selector: "app-add-constant",
	templateUrl: "./add-constant.component.html",
	styleUrls: ["./add-constant.component.scss"],
})
export class AddConstantComponent implements OnInit, OnDestroy {
	edit_mode = false;
	constantForm: FormGroup;
	constantData;
	constant_type_list = [];
	subscription: Subscription = new Subscription();
	constructor(
		private fb: FormBuilder,
		public ds: DataService,
		private sweetAlert: SweetAlertService,
		public dialogRef: MatDialogRef<AddConstantComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private lang: LanguagePipe
	) {
		this.buildForm();
		this.getConstantTypes();
		if (data?.constant) {
			this.edit_mode = true;
			this.constantData = data?.constant;
			this.setEditData();
		}
	}

	ngOnInit(): void {}
	getConstantTypes() {
		this.subscription.add(
			this.ds.post("tasks/constants", {}).subscribe((res) => {
				if (res?.status) {
					this.constant_type_list = res?.data;
				} else {
					this.constant_type_list = [];
				}
			})
		);
	}
	buildForm() {
		this.constantForm = this.fb.group({
			constant_name: ["", Validators.required],
			contant_type: [null, Validators.required],
			constant_name_en: ["", Validators.required],
		});
	}
	setEditData() {
		this.constantForm.get("constant_name").setValue(this.constantData?.name);
		this.constantForm
			.get("contant_type")
			.setValue(this.constantData?.contant_type);
		this.constantForm
			.get("constant_name_en")
			.setValue(this.constantData?.name_en);
	}
	onSubmit() {
		let formData = new FormData();
		formData.append(
			"constant_name",
			this.constantForm.get("constant_name").value || ""
		);
		formData.append(
			"contant_type",
			this.constantForm.get("contant_type").value || ""
		);
		formData.append(
			"constant_name_en",
			this.constantForm.get("constant_name_en").value || ""
		);
		let end_point = "tasks/add_constant";
		if (this.edit_mode) {
			end_point = "tasks/update_constant";
			formData.append("constant_id", this.constantData?.constant_id || "");
		}
		if (this.constantForm.valid) {
			this.ds.post(end_point, formData).subscribe(
				(res) => {
					if (res?.status) {
						this.sweetAlert.successToast(
							this.lang.transform("lang_data_sent_successfully"),
							2000
						);
						this.dialogRef.close(true);
					} else {
						this.sweetAlert.errorToast(res?.error, 2000);
					}
				},
				(err) => {
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						2000
					);
					this.dialogRef.close();
				}
			);
		} else {
			this.sweetAlert.errorToast(
				this.lang.transform("lang_fill_required_fields"),
				2000
			);
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
