import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";
@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class CreateComponent implements OnInit, OnDestroy {
  public createHRForm: FormGroup;
  licencesList: any;
  action: string = "ADD";
  constructor(
    public router: Router,
    public ds: DataService,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.buildHrForm();
  }
  buildHrForm() {
    this.createHRForm = new FormGroup({
      idDetails: new FormGroup({
        employee_id_number: new FormControl("", [Validators.required]),
        branch_id: new FormControl("", [Validators.required]),
        name_english: new FormControl("", [Validators.required]),
        name_arabic: new FormControl("", [Validators.required]),
        firstname_english: new FormControl("", [Validators.required]),
        secondname_english: new FormControl("", [Validators.required]),
        thirdname_english: new FormControl(""),
        lastname_english: new FormControl("", [Validators.required]),
        firstname_arabic: new FormControl("", [Validators.required]),
        secondname_arabic: new FormControl("", [Validators.required]),
        thirdname_arabic: new FormControl(""),
        lastname_arabic: new FormControl("", [Validators.required]),
        iqama_expiry_date_english: new FormControl("", [Validators.required]),
        iqama_expiry_date_arabic: new FormControl(null, [Validators.required]),
        copy_number: new FormControl("", [Validators.required]),
        birth_date_english: new FormControl("", [Validators.required]),
        birth_date_arabic: new FormControl(null, [Validators.required]),
        gender: new FormControl("", [Validators.required]),
        nationality: new FormControl(null, [Validators.required]),
        profile_image: new FormControl(""),
        job_title_iqama: new FormControl("", [Validators.required]),
        marital_status: new FormControl("", [Validators.required]),
        religion: new FormControl("", [Validators.required]),
        work_domain: new FormControl("", [Validators.required]),
        work_location: new FormControl("", [Validators.required]),
        work_type: new FormControl("", [Validators.required]),
        employee_title: new FormControl(""),
        id_filename: new FormControl("", [Validators.required]),
        idFileUpload: new FormControl("", [Validators.required]),
        legal_situation_status: new FormControl("0", [Validators.required]),
        group_id: new FormControl(""),
        total_previous_contract_days: new FormControl(""),
      }),
      jobDetails: new FormGroup({
        job_title_id: new FormControl(null, [Validators.required]),
        job_descriptions: new FormControl("", [Validators.required]),
        liclists: this.fb.array([]),
      }),
      contactDetails: new FormGroup({
        primary_mobile: new FormControl("", [Validators.required]),
        primary_email: new FormControl("", [
          Validators.required,
          Validators.email,
        ]),
        primary_address: new FormControl("", [Validators.required]),
        contact_file_name: new FormControl(""),
        address_file: new FormControl(""),
      }),
      bankDetails: new FormGroup({
        account_number: new FormControl("", [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(35),
        ]),
        bank_short_code: new FormControl(null, [Validators.required]),
        bank_file_name: new FormControl(""),
        bank_file: new FormControl(""),
      }),
      educationDetails: new FormGroup({
        primary_graduation_year: new FormControl("", [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
        ]),
        primary_education_level: new FormControl("", [Validators.required]),
        membership_number: new FormControl(""),
        rating_degree: new FormControl("", [Validators.required]),
        membership_expiry_date_english: new FormControl(""),
        membership_expiry_date_arabic: new FormControl(null),
        edu_file_name: new FormControl("", [Validators.required]),
        education_file: new FormControl("", [Validators.required]),
      }),
      passportDetails: new FormGroup({
        passport_number: new FormControl(""),
        passport_issue_date_english: new FormControl(""),
        passport_issue_date_arabic: new FormControl(null),
        passport_expiry_date_english: new FormControl(""),
        passport_expiry_date_arabic: new FormControl(null),
        passport_issue_place: new FormControl(""),
        passport_file_name: new FormControl(""),
        passport_file: new FormControl(""),
      }),
      insuranceDetails: new FormGroup({
        insurance_number: new FormControl(""),
        insurance_date: new FormControl(""),
        insurance_type_class: new FormControl(""),
      }),
    });
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  createNewEmployee() {
    let wholeData: any = {};
    let id = this.createHRForm.get("idDetails").value;
    Object.assign(wholeData, id);
    let job = this.createHRForm.get("jobDetails").value;
    Object.assign(wholeData, job);
    let contact = this.createHRForm.get("contactDetails").value;
    Object.assign(wholeData, contact);
    let bank = this.createHRForm.get("bankDetails").value;
    Object.assign(wholeData, bank);
    let edu = this.createHRForm.get("educationDetails").value;
    Object.assign(wholeData, edu);
    let passport = this.createHRForm.get("passportDetails").value;
    Object.assign(wholeData, passport);
    let ins = this.createHRForm.get("insuranceDetails").value;
    Object.assign(wholeData, ins);
    this.spinner.show();
    this.subscriptions.add(
      this.ds
        .postActionByUrl(
          this.ds.json2formdata(wholeData),
          "human_resources/create_new_employee"
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
              this.alert.success(data.msg);
              this.createHRForm.reset();
              setTimeout(() => {
                this.router.navigate([
                  "human_resources/view/" + data.id + "/view/" + data.bid,
                ]);
              }, 2000);
            } else {
              this.alert.error(data.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }
}
