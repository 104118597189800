import { NgModule } from "@angular/core";
import { TasksRoutingModule } from "./tasks-routing.module";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "app/custom-material-config";
import { SharedModule } from "@shared";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { TicketListComponent } from "./ticket-list/ticket-list.component";
import { AddTicketComponent } from "./add-ticket/add-ticket.component";
import { EditTicketComponent } from "./edit-ticket/edit-ticket.component";
import { TimelineModule } from "primeng/timeline";
import { CardModule } from "primeng/card";
import { AddUpdateTaskComponent } from "./view-ticket/add-update-task/add-update-task.component";
import { ViewUsersComponent } from "./view-ticket/view-users/view-users.component";
import { EditCommentComponent } from "./view-ticket/edit-comment/edit-comment.component";
import { MentionsModule } from "@bboxx/mentions";
import { TicketAttachmentsComponent } from "./view-ticket/ticket-attachments/ticket-attachments.component";
import { TrackingTicketComponent } from "./view-ticket/tracking-ticket/tracking-ticket.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ViewTaskComponent } from "./view-ticket/view-task/view-task.component";
import { TicketDetailsComponent } from "./view-ticket/ticket-details/ticket-details.component";
import { TicketTimelineComponent } from "./view-ticket/ticket-timeline/ticket-timeline.component";
import { MbscModule } from "@mobiscroll/angular";
import { ArrToStrPipe } from "./pipes/arr-to-str.pipe";
import { GroupsComponent } from "./settings/groups/groups.component";
import { SettingsComponent } from "./settings/settings/settings.component";
import { ConstantsComponent } from "./settings/constants/constants.component";
import { AddGroupComponent } from "./settings/groups/add-group/add-group.component";
import { ViewGroupComponent } from "./settings/groups/view-group/view-group.component";
import { AddConstantComponent } from "./settings/constants/add-constant/add-constant.component";
import { TemplateListComponent } from "./templates/template-list/template-list.component";
import { TemplateMainComponent } from "./templates/template-main/template-main.component";
import { TemplateViewComponent } from "./templates/template-view/template-view.component";
import { TemplateTaskComponent } from "./templates/template-task/template-task.component";
import { AddTemplateComponent } from "./templates/add-template/add-template.component";
import { FiltersModelComponent } from "./ticket-list/filters-model/filters-model.component";
import { ChecklistDetailsComponent } from "./view-ticket/view-task/checklist-details/checklist-details.component";
import { ChecklistDetailsViewComponent } from "./view-ticket/view-task/checklist-details-view/checklist-details-view.component";
import { TicketsTransactionsComponent } from "./tickets-transactions/tickets-transactions.component";
import { TicketFiltersComponent } from "./tickets-transactions/ticket-filters/ticket-filters.component";
import { TransactionFiltersComponent } from "./tickets-transactions/transaction-filters/transaction-filters.component";
import { PermissionsComponent } from "./settings/permissions/permissions.component";
import { AddPermissionComponent } from "./settings/permissions/add-permission/add-permission.component";
import { TicketContractsComponent } from "./view-ticket/ticket-contracts/ticket-contracts.component";
import { TicketTaskUsersComponent } from "./view-ticket/ticket-task-users/ticket-task-users.component";
import { UserGroupsComponent } from "./templates/user-groups/user-groups.component";
import { AddUserGroupComponent } from "./templates/user-groups/add-group/add-group.component";
import { ViewUserGroupComponent } from "./templates/user-groups/view-group/view-group.component";
import { UserGroupComponent } from "./user-group/user-group.component";
import { UserActiveTasksComponent } from "./user-active-tasks/user-active-tasks.component";
import { ViewTaskFullComponent } from "./view-ticket/view-task/view-task-full/view-task-full.component";
import { ChecklistDetailsViewFloorComponent } from "./view-ticket/view-task/checklist-details-view-floor/checklist-details-view-floor.component";
import { ChecklistDetailsFloorComponent } from "./view-ticket/view-task/checklist-details-floor/checklist-details-floor.component";
import { ExternalPersonsComponent } from "./settings/external-persons/external-persons.component";
import { AddExternalPersonComponent } from "./settings/external-persons/add-external-person/add-external-person.component";
import { ThemeModule } from "@theme/theme.module";
import { TaskMeetingComponent } from "./view-ticket/task-meeting/task-meeting.component";
import { AddUpdateMeetingComponent } from "./view-ticket/task-meeting/add-update-meeting/add-update-meeting.component";
import { MeetingListComponent } from "./view-ticket/task-meeting/meeting-list/meeting-list.component";
import { MeetingViewComponent } from "./view-ticket/task-meeting/meeting-view/meeting-view.component";
import { MeetingSubPointViewComponent } from "./view-ticket/task-meeting/meeting-sub-point-view/meeting-sub-point-view.component";
import { MeetingCheckAddUpdateComponent } from "./view-ticket/task-meeting/meeting-check-add-update/meeting-check-add-update.component";
import { AddUpdateTicketModalComponent } from "./add-update-ticket-modal/add-update-ticket-modal.component";
import { CKEditorModule } from "ckeditor4-angular";

@NgModule({
	imports: [
		SharedModule,
		TasksRoutingModule,
		InfiniteScrollModule,
		MbscModule,
		AngularMultiSelectModule,
		TimelineModule,
		CardModule,
		MentionsModule,
		PopoverModule,
		CKEditorModule,
		ThemeModule,
	],
	declarations: [
		FiltersModelComponent,
		TicketListComponent,
		AddTicketComponent,
		EditTicketComponent,
		AddUpdateTaskComponent,
		ViewUsersComponent,
		EditCommentComponent,
		TicketAttachmentsComponent,
		TrackingTicketComponent,
		ViewTaskComponent,
		TicketDetailsComponent,
		TicketTimelineComponent,
		ArrToStrPipe,
		GroupsComponent,
		SettingsComponent,
		ConstantsComponent,
		AddGroupComponent,
		ViewGroupComponent,
		AddConstantComponent,
		TemplateListComponent,
		TemplateMainComponent,
		TemplateViewComponent,
		TemplateTaskComponent,
		AddTemplateComponent,
		ChecklistDetailsComponent,
		ChecklistDetailsViewComponent,
		TicketsTransactionsComponent,
		TicketFiltersComponent,
		TransactionFiltersComponent,
		PermissionsComponent,
		AddPermissionComponent,
		TicketContractsComponent,
		TicketTaskUsersComponent,
		UserGroupsComponent,
		AddUserGroupComponent,
		ViewUserGroupComponent,
		UserGroupComponent,
		UserActiveTasksComponent,
		ViewTaskFullComponent,
		ChecklistDetailsViewFloorComponent,
		ChecklistDetailsFloorComponent,
		ExternalPersonsComponent,
		AddExternalPersonComponent,
		TaskMeetingComponent,
		AddUpdateMeetingComponent,
		MeetingListComponent,
		MeetingViewComponent,
		MeetingSubPointViewComponent,
		MeetingCheckAddUpdateComponent,
		AddUpdateTicketModalComponent,
	],
	entryComponents: [],
	providers: [
		{ provide: DateAdapter, useClass: AppDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
	],
	exports: [ChecklistDetailsViewFloorComponent],
})
export class TasksModule {}
