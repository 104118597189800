<div class="row wafi_header">
  <div class="table-responsive customResponsive">
    <table border="1" class="table wafi_title" width="100%">
      <tbody>
        <tr>
          <td width="130px">
            <img src="assets/images/logoawafi.PNG" alt="" />
          </td>
          <td width="87.5%">
            <div class="title_content">
              {{
                "supervision consultant monthly report for july-2022" | language
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row content_header">
  <div class="col">
    {{ "lang_hse_management" | language }}
  </div>
</div>
<div class="row programme_work">
  <div class="table-responsive customResponsive">
    <table border="1" class="table" width="100%">
      <tbody>
        <tr>
          <th rowspan="2">{{ "lang_hse_plan" | language }}</th>
          <th>{{ "lang_hse_plan_status" | language }}</th>
          <th rowspan="2" class="another_title">
            {{ "lang_hse_assurance" | language }}
          </th>
          <td class="highlight2" rowspan="2">
            {{ "lang_preventive_measures" | language }}
          </td>
          <td rowspan="2" colspan="2">
            {{ report_data?.wafi_report?.preventive_measures | language }}
          </td>
        </tr>
        <tr>
          <td>
            {{ report_data?.wafi_report?.hse_plan_status | language }}
          </td>
        </tr>
        <br />
        <tr>
          <th rowspan="3">{{ "lang_hse_control" | language }}</th>
          <th>{{ "lang_period" | language }}</th>
          <th>{{ "lang_major_incidents" | language }}</th>
          <th>
            {{ "lang_lost_time_injuries" | language }}
          </th>
          <th>{{ "lang_near_miss_reports" | language }}</th>
          <th>{{ "lang_hse_observations" | language }}</th>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "december-15" }}
          </td>
          <td>
            {{ report_data?.wafi_report?.major_incidents | language }}
          </td>
          <td>
            {{ report_data?.wafi_report?.lost_time_injuries | language }}
          </td>
          <td>
            {{ report_data?.wafi_report?.near_miss_reports | language }}
          </td>
          <td rowspan="2">
            {{ report_data?.wafi_report?.hse_observations | language }}
          </td>
        </tr>
        <tr>
          <td class="highlight2">
            {{ "cumulative" | language }}
          </td>
          <td>
            {{ report_data?.wafi_report?.major_incidents | language }}
          </td>
          <td>
            {{ report_data?.wafi_report?.lost_time_injuries | language }}
          </td>
          <td>
            {{ report_data?.wafi_report?.near_miss_reports | language }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
