<div class="container" [attr.dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
	<div class="row">
		<div class="col-12">
			<section class="featured-categories">
				<div class="fc-header">
					<h1 class="section-title">{{ "lang_modules" | language }}</h1>
				</div>
				<div class="fc-list">
					<div
						class="fc-list-item"
						*ngFor="let item of template_featured_categories"
						routerLink="/tasks/templates/module/{{ item?.module_key }}"
					>
						<div class="fc-list-item-image">
							<img
								src="{{ item?.img }}"
								alt="Featured Category"
								width="100px"
								height="100px"
							/>
						</div>
						<div class="fc-list-item-content">
							<span>{{ item?.title }}</span>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="col-12 mt-2" *ngIf="tasks_temp?.length">
			<section class="category-list">
				<div class="cl-header">
					<div class="cl-title">
						<img
							src="assets/images/tickets-assets/template-category-title7.svg"
							alt="title icon"
							width="24px"
							height="24px"
						/>
						<h2 class="section-title">{{ "lang_tasks" | language }}</h2>
					</div>
					<div class="cl-action">
						<button class="task-btn" routerLink="/tasks/templates/module/tasks">
							{{ "lang_show_more" | language }}
						</button>
					</div>
				</div>
				<div class="cl-list">
					<div
						class="cl-list-item"
						*ngFor="let item of tasks_temp | slice: 0:3"
						routerLink="/tasks/templates/view/{{ item?.ticket_id }}"
					>
						<div class="cl-list-item-header">
							<h3>{{ item?.temp_id }} - {{ item?.title }}</h3>
							<p>
								By {{ item?.firstname_english }}
								{{ item?.lastname_english }} @{{ item?.user_username }}
							</p>
						</div>
						<div class="cl-list-item-content">
							<p>{{ item?.description }}</p>
						</div>
						<div class="temp-actions">
							<i
								class="fa fa-trash red mr-1 ml-1"
								(click)="deleteTemplate($event, item?.temp_id)"
								*ngIf="user_id == item?.user_id"
								matTooltip="{{ 'lang_delete' | language }}"
							></i>
							<i
								class="pi pi-shield mr-1 ml-1 green"
								(click)="
									toggleTemplatePrivate($event, item?.temp_id, item?.is_view)
								"
								*ngIf="user_id == item?.user_id && item?.is_view == '1'"
								matTooltip="{{ 'lang_make_public' | language }}"
								style="font-weight: 600"
							></i>
							<i
								class="pi pi-shield mr-1 ml-1"
								(click)="
									toggleTemplatePrivate($event, item?.temp_id, item?.is_view)
								"
								*ngIf="user_id == item?.user_id && item?.is_view == '2'"
								matTooltip="{{ 'lang_make_private' | language }}"
								style="font-weight: 600"
							></i>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="col-12 mt-4" *ngIf="projects_temp?.length">
			<section class="category-list">
				<div class="cl-header">
					<div class="cl-title">
						<img
							src="assets/images/tickets-assets/template-category-title1.svg"
							alt="title icon"
							width="24px"
							height="24px"
						/>
						<h2 class="section-title">{{ "lang_projects" | language }}</h2>
					</div>
					<div class="cl-action">
						<button
							class="task-btn"
							routerLink="/tasks/templates/module/projects"
						>
							{{ "lang_more" | language }}
						</button>
					</div>
				</div>
				<div class="cl-list">
					<div
						class="cl-list-item"
						*ngFor="let item of projects_temp | slice: 0:3"
						routerLink="/tasks/templates/view/{{ item?.ticket_id }}"
					>
						<div class="cl-list-item-header">
							<h3>{{ item?.temp_id }} - {{ item?.title }}</h3>
							<p>
								By {{ item?.firstname_english }}
								{{ item?.lastname_english }} @{{ item?.user_username }}
							</p>
						</div>
						<div class="cl-list-item-content">
							<p>{{ item?.description }}</p>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="col-12 mt-2" *ngIf="suppliers_temp?.length">
			<section class="category-list">
				<div class="cl-header">
					<div class="cl-title">
						<img
							src="assets/images/tickets-assets/template-category-title5.svg"
							alt="title icon"
							width="24px"
							height="24px"
						/>
						<h2 class="section-title">{{ "lang_suppliers" | language }}</h2>
					</div>
					<div class="cl-action">
						<button
							class="task-btn"
							routerLink="/tasks/templates/module/suppliers"
						>
							{{ "lang_more" | language }}
						</button>
					</div>
				</div>
				<div class="cl-list">
					<div
						class="cl-list-item"
						*ngFor="let item of suppliers_temp | slice: 0:3"
						routerLink="/tasks/templates/view/{{ item?.ticket_id }}"
					>
						<div class="cl-list-item-header">
							<h3>{{ item?.temp_id }} - {{ item?.title }}</h3>
							<p>
								By {{ item?.firstname_english }}
								{{ item?.lastname_english }} @{{ item?.user_username }}
							</p>
						</div>
						<div class="cl-list-item-content">
							<p>{{ item?.description }}</p>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="col-12 mt-2" *ngIf="customers_temp?.length">
			<section class="category-list">
				<div class="cl-header">
					<div class="cl-title">
						<img
							src="assets/images/tickets-assets/template-category-title4.svg"
							alt="title icon"
							width="24px"
							height="24px"
						/>
						<h2 class="section-title">{{ "lang_customers" | language }}</h2>
					</div>
					<div class="cl-action">
						<button
							class="task-btn"
							routerLink="/tasks/templates/module/customers"
						>
							{{ "lang_more" | language }}
						</button>
					</div>
				</div>
				<div class="cl-list">
					<div
						class="cl-list-item"
						*ngFor="let item of customers_temp | slice: 0:3"
						routerLink="/tasks/templates/view/{{ item?.ticket_id }}"
					>
						<div class="cl-list-item-header">
							<h3>{{ item?.temp_id }} - {{ item?.title }}</h3>
							<p>
								By {{ item?.firstname_english }}
								{{ item?.lastname_english }} @{{ item?.user_username }}
							</p>
						</div>
						<div class="cl-list-item-content">
							<p>{{ item?.description }}</p>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="col-12 mt-2" *ngIf="contractors_temp?.length">
			<section class="category-list">
				<div class="cl-header">
					<div class="cl-title">
						<img
							src="assets/images/tickets-assets/template-category-title3.svg"
							alt="title icon"
							width="24px"
							height="24px"
						/>
						<h2 class="section-title">{{ "lang_contractors" | language }}</h2>
					</div>
					<div class="cl-action">
						<button
							class="task-btn"
							routerLink="/tasks/templates/module/contractors"
						>
							{{ "lang_more" | language }}
						</button>
					</div>
				</div>
				<div class="cl-list">
					<div
						class="cl-list-item"
						*ngFor="let item of contractors_temp | slice: 0:3"
						routerLink="/tasks/templates/view/{{ item?.ticket_id }}"
					>
						<div class="cl-list-item-header">
							<h3>{{ item?.temp_id }} - {{ item?.title }}</h3>
							<p>
								By {{ item?.firstname_english }}
								{{ item?.lastname_english }} @{{ item?.user_username }}
							</p>
						</div>
						<div class="cl-list-item-content">
							<p>{{ item?.description }}</p>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="col-12 mt-2" *ngIf="hr_temp?.length">
			<section class="category-list">
				<div class="cl-header">
					<div class="cl-title">
						<img
							src="assets/images/tickets-assets/template-category-title2.svg"
							alt="title icon"
							width="24px"
							height="24px"
						/>
						<h2 class="section-title">
							{{ "lang_human_resources" | language }}
						</h2>
					</div>
					<div class="cl-action">
						<button
							class="task-btn"
							routerLink="/tasks/templates/module/human_resources"
						>
							{{ "lang_more" | language }}
						</button>
					</div>
				</div>
				<div class="cl-list">
					<div
						class="cl-list-item"
						*ngFor="let item of hr_temp | slice: 0:3"
						routerLink="/tasks/templates/view/{{ item?.ticket_id }}"
					>
						<div class="cl-list-item-header">
							<h3>{{ item?.temp_id }} - {{ item?.title }}</h3>
							<p>
								By {{ item?.firstname_english }}
								{{ item?.lastname_english }} @{{ item?.user_username }}
							</p>
						</div>
						<div class="cl-list-item-content">
							<p>{{ item?.description }}</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<app-user-groups></app-user-groups>
</div>
