<div class="mbrl15 martb0">
    <div class="row">
        <div class="col-xs-12 col-12">
            <div class="red text-center mt-3">{{'lang_phases_conditions_edit' | language }}</div>
            <mat-horizontal-stepper #stepper linear class="pad0">
                <mat-step [stepControl]="phase1form">
                    <ng-template matStepLabel>
                        <div class="step">
                            <div class="st">
                                <i class="icon-5x fa fa-cog fa-icon"></i>
                            </div>
                        </div>
                        <div>{{'lang_config_implement_phases' | language }}</div>
                    </ng-template>
                    <hr>
                    <form id="phase1form" [formGroup]="phase1form" class="phase1form">
                        <div class="phase1details paddingtop20">
                            <div class="row">
                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label for="total_no_of_zones">{{'lang_total_zones' | language }}:<span class="red">*</span></label>
                                        <input type="text" class="form-control almnabrformcontrol" formControlName="total_no_of_zones" >
                                        <mat-error *ngIf="step1submitted && phase1form.controls['total_no_of_zones'].errors?.required">
                                            {{'lang_field_required' | language }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label for="total_no_of_blocks">{{'lang_total_blocks' | language }}:<span class="red">*</span></label>
                                        <input type="text" class="form-control almnabrformcontrol" formControlName="total_no_of_blocks" >
                                        <mat-error *ngIf="step1submitted && phase1form.controls['total_no_of_blocks'].errors?.required">
                                            {{'lang_field_required' | language }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label for="total_no_of_clusters">{{'lang_total_clusters' | language }}:<span class="red">*</span></label>
                                        <input type="text" class="form-control almnabrformcontrol" formControlName="total_no_of_clusters" >
                                        <mat-error *ngIf="step1submitted && phase1form.controls['total_no_of_clusters'].errors?.required">
                                            {{'lang_field_required' | language }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label for="total_no_of_units">{{'lang_total_units' | language }}:<span class="red">*</span></label>
                                        <input type="text" class="form-control almnabrformcontrol" formControlName="total_no_of_units" >
                                        <mat-error *ngIf="step1submitted && phase1form.controls['total_no_of_units'].errors?.required">
                                            {{'lang_field_required' | language }}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-10 mb-10"></div>
                                <div class="col-2 mb-10">
                                    <button mat-button class="albutton" (click)="complete_step_one();" matStepperNext>{{'lang_next' | language }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="phase2form">
                    <ng-template matStepLabel>
                        <div class="step">
                            <div class="st">
                                <i class="icon-5x fa fa-building fa-icon"></i>
                            </div>
                        </div>
                        <div>{{'lang_phase_distribution' | language }}</div>
                    </ng-template>
                    <hr>
                    <form id="phase2form" [formGroup]="phase2form" class="phase2form">
                        <div class="phase1details paddingtop20">
                            <div class="row">
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_zones' | language }}:{{phase1form.get('total_no_of_zones').value}}</label>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_blocks' | language }}:{{phase1form.get('total_no_of_blocks').value}}</label>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_clusters' | language }}:{{phase1form.get('total_no_of_clusters').value}}</label>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_units' | language }}:{{phase1form.get('total_no_of_units').value}}</label>
                                </div>
                            </div>
                            <div class="table-responsive customResponsive mb-10 tablefields">
                                <table border="1" class="table CustomTable text-center">
                                    <thead>
                                        <tr>
                                            <td>{{'lang_zone' | language }} <i class="red">*</i></td>
                                            <td>{{'lang_blocks' | language }} <i class="red">* </i></td>
                                        </tr>
                                    </thead>
                                    <tbody formArrayName="phase2items" class="AppendList">
                                        <tr *ngFor="let item of items_group().controls; let itemIndex = index;let odd=odd; let even=even" [formGroupName]="itemIndex" style="background: #ffffff;">
                                            <td width="10%">
                                                <input type="text" class="form-control almnabrformcontrol" formControlName="specific_zone_no" readonly>
                                                <mat-error *ngIf="step2submitted && itemsgroup.controls[itemIndex].get('specific_zone_no').errors?.required">{{'lang_field_required' | language }}</mat-error>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control almnabrformcontrol" formControlName="specific_zone_blocks_config" placeholder="{{'lang_blocks' | language }}" >
                                                <mat-error *ngIf="step2submitted && itemsgroup.controls[itemIndex].get('specific_zone_blocks_config').errors?.required">{{'lang_field_required' | language }}</mat-error>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-2 mb-10">
                                    <button mat-button class="albutton" matStepperPrevious>{{'lang_previous' | language }}</button>
                                </div>
                                <div class="col-8 mb-10">
                                    <app-alert id="default-alert"></app-alert>
                                </div>
                                <div class="col-2 mb-10">
                                    <button mat-button class="albutton" (click)="complete_step_two(stepper);">{{'lang_next' | language }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="phase3form">
                    <ng-template matStepLabel>
                        <div class="step">
                            <div class="st">
                                <i class="icon-5x fa fa-upload fa-icon"></i>
                            </div>
                        </div>
                        <div>{{'lang_clusters' | language }}</div>
                    </ng-template>
                    <hr>
                    <form id="phase3form" [formGroup]="phase3form" class="phase3form">
                        <div class="phase1details paddingtop20">
                            <div class="row">
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_zones' | language }}:{{phase1form.get('total_no_of_zones').value}}</label>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_blocks' | language }}:{{phase1form.get('total_no_of_blocks').value}}</label>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_clusters' | language }}:{{phase1form.get('total_no_of_clusters').value}}</label>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_units' | language }}:{{phase1form.get('total_no_of_units').value}}</label>
                                </div>
                            </div>
                            <hr>
                            <div id="phase3formdetails paddingtop20">
                                <div class="row">
                                    <div class="col-2 col-xs-12 mb-10">
                                        <div class="IDDIV">
                                            <input name="cluster_attachment" type="file" required onclick="this.value = null" (change)="uploadfile($event, 'C')" class="nghide albutton width100" accept=".xlsx">
                                            <label id="uploadButton" class="albutton">{{filenamephase3}} </label>
                                            <mat-error *ngIf="step3submitted && phase3form.controls['cluster_attachment'].errors?.required">
                                                {{'lang_field_required' | language }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 mb-10">
                                    <button mat-button class="albutton" matStepperPrevious>{{'lang_previous' | language }}</button>
                                </div>
                                <div class="col-8 mb-10">
                                    <app-alert id="default-alert"></app-alert>
                                </div>
                                <div class="col-2 mb-10">
                                    <button mat-button class="albutton" (click)="complete_step_three(stepper);">{{'lang_next' | language }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="phase4form">
                    <ng-template matStepLabel>
                        <div class="step">
                            <div class="st">
                                <i class="icon-5x fa fa-upload fa-icon"></i>
                            </div>
                        </div>
                        <div>{{'lang_units' | language }}</div>
                    </ng-template>
                    <hr>
                    <form id="phase4form" [formGroup]="phase4form" class="phase4form">
                        <div class="phase1details paddingtop20">
                            <div class="row">
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_zones' | language }}:{{phase1form.get('total_no_of_zones').value}}</label>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_blocks' | language }}:{{phase1form.get('total_no_of_blocks').value}}</label>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_clusters' | language }}:{{phase1form.get('total_no_of_clusters').value}}</label>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12">
                                    <label>{{'lang_total_units' | language }}:{{phase1form.get('total_no_of_units').value}}</label>
                                </div>
                            </div>
                            <hr>
                            <div id="phase4formdetails paddingtop20">
                                <div class="row">
                                    <div class="col-2 col-xs-12 mb-10">
                                        <div class="IDDIV">
                                            <input name="unit_attachment" type="file" required onclick="this.value = null" (change)="uploadfile($event, 'U')" class="nghide albutton width100" accept=".xlsx">
                                            <label id="uploadButton" class="albutton">{{filenamephase4}} </label>
                                            <mat-error *ngIf="step4submitted && phase4form.controls['unit_attachment'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 mb-10">
                                    <button mat-button class="albutton" matStepperPrevious>{{'lang_previous' | language }}</button>
                                </div>
                                <div class="col-8 mb-10">
                                    <app-alert id="default-alert"></app-alert>
                                </div>
                                <div class="col-2 mb-10">
                                    <button mat-button class="albutton" (click)="complete_step_four(stepper);">{{'lang_next' | language }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</div>