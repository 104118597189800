import { Component, OnInit } from "@angular/core";
import { PreloaderService } from "@core";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { DataService } from "@core/bootstrap/data.service";
import { MatDialog } from "@angular/material/dialog";
import { AddDeviceComponent } from "../add-device/add-device.component";
import moment from "moment";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-device-attendance-logs",
  templateUrl: "./device-attendance-logs.component.html",
  styleUrls: ["./device-attendance-logs.component.scss"],
})
export class DeviceAttendanceLogsComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key") || "en";
  listData: any = [];
  loading: boolean = true;
  nodata: string = this.lang.transform("lang_loading");
  search_key: any = "";
  page_no: any = 1;
  pagesize: any = "10";
  device_key: any = "";
  person_id: any = "";
  subscriptions = new Subscription();

  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public dialog: MatDialog,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    private sweetAlert: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.module_lists();
  }

  reasonFromToDate: FormGroup = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });

  public module_lists(page = 1) {
    this.listData = [];
    this.page_no = page;
    this.pagesize = this.pagesize;
    this.get_logs_lists();
  }

  public loadPageFrm(page) {
    this.page_no = page;
    this.pagesize = this.pagesize;
    this.get_logs_lists();
  }
  public loadFrmPagesize(size) {
    this.page_no = 1;
    this.pagesize = size;
    this.get_logs_lists();
  }

  public get_logs_lists() {
    this.listData = [];
    this.loading = true;

    let param = new FormData();
    param.append("page_no", this.page_no);
    param.append("page_size", this.pagesize);
    param.append("person_id", this.person_id);
    param.append("device_key", this.device_key);
    param.append(
      "from_date",
      this.reasonFromToDate?.get("start")?.value
        ? moment(this.reasonFromToDate?.get("start")?.value)
            .lang("en")
            .format("YYYY/MM/DD")
        : ""
    );
    param.append(
      "to_date",
      this.reasonFromToDate?.get("end")?.value
        ? moment(this.reasonFromToDate?.get("end")?.value)
            .lang("en")
            .format("YYYY/MM/DD")
        : ""
    );

    this.ds
      .getActionByUrl(
        this.ds.formData2string(param),
        "at/get_device_attendance_logs"
      )
      .subscribe(
        (res) => {
          this.loading = false;
          if (res.status) {
            this.listData = res;
          } else {
            this.listData = [];
            this.nodata = this.lang.transform("lang_no_data");
          }
        },
        (error) => {
          this.loading = false;
          this.listData = [];
          this.nodata =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
  }
  export_exl() {
    this.spinner.show()
    let param = new FormData();
    param.set("person_id", this.person_id || "");
    param.set("device_key", this.device_key || "");
    param.append(
      "from_date",
      this.reasonFromToDate?.get("start")?.value
        ? moment(this.reasonFromToDate?.get("start")?.value)
            .lang("en")
            .format("YYYY/MM/DD")
        : ""
    );
    param.append(
      "to_date",
      this.reasonFromToDate?.get("end")?.value
        ? moment(this.reasonFromToDate?.get("end")?.value)
            .lang("en")
            .format("YYYY/MM/DD")
        : ""
    );
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          `at/export_data`
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.downloadFile(res, "attendance_details");
            } else {
              this.sweetAlert.errorToast(res?.error, 2000);
            }
          },
          (error) => {
            this.spinner.hide();
            this.sweetAlert.errorToast(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error"),
              2000
            );
          }
        )
    );
  }
  downloadFile(data, title?, type?) {
    if (data.status) {
      let url = "data:" + data["content-type"] + ";base64," + data.base64;
      let tagA = document.createElement("a");
      document.body.appendChild(tagA);
      tagA.style.display = "none";
      tagA.href = url;
      if (type == "blank") {
        tagA.setAttribute("target", "_blank");
      }
      tagA.download = title || "Almnabr";
      tagA.click();
      document.body.removeChild(tagA);
    }
  }
  async downloadImage(
    imageSrc,
    nameOfDownload = 'my-image.png',
  ) {
    const response = await fetch(imageSrc);
  
    const blobImage = await response.blob();
  
    const href = URL.createObjectURL(blobImage);
  
    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    anchorElement.download = nameOfDownload;
  
    document.body.appendChild(anchorElement);
    anchorElement.click();
  
    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}


