<div class="mbrl15">
	<div class="row mb-3">
		<div class="col-12 col-sm-3 d-flex align-items-center">
			<button
				class="albutton mnabrbutton"
				mat-button
				(click)="onUpdateUnit(createTemp)"
			>
				<i class="fa fa-plus mnabricon mr-1 ml-1" style="color: #fff"></i>
				<span>{{ "lang_update" | language }}</span>
			</button>
		</div>
		<div class="col-0 col-sm-3 d-flex align-items-center">
			<input
				type="file"
				#uploadUnitsInput
				id="uploadUnitsInput"
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
				hidden
				(change)="uploadUnitsFile($event.target.files)"
			/>
			<i
				class="fa fa-download mnabricon mr-1 ml-1"
				(click)="downloadUnitsFile()"
				matTooltip="{{ 'lang_download' | language }}"
			></i>
			<i
				class="fa fa-upload mnabricon mr-1 ml-1"
				(click)="uploadUnitsInput.click()"
				matTooltip="{{ 'lang_upload' | language }}"
			></i>
			<i
				class="fa fa-history mnabricon mr-1 ml-1"
				(click)="getUnitsFileHistory(historyFilesTemp)"
				matTooltip="{{ 'lang_history' | language }}"
			></i>
		</div>
		<div class="col-12 col-sm-3 mb-2"></div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_zone" | language }}</td>
							<td>{{ "lang_blocks" | language }}</td>
							<td>{{ "lang_clusters" | language }}</td>
							<td>{{ "lang_general_numbers" | language }}</td>
							<td>{{ "lang_unit_ratio" | language }}</td>
							<td>{{ "lang_payment_completion_ratio" | language }}</td>
							<td>{{ "lang_payment_due_ratio" | language }}</td>
							<td>{{ "lang_human_resources_bank_shortcode" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody class="AppendList">
						<tr
							*ngFor="
								let list of unitList;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
						>
							<td>{{ i + 1 }}</td>
							<td>{{ list.phase_zone_no }}</td>
							<td>{{ list.phase_zone_block_no }}</td>
							<td>{{ list.phase_zone_block_cluster_no }}</td>
							<td>{{ list.phase_zone_block_cluster_g_no }}</td>
							<td>{{ list.total_unit_ratio }}</td>
							<td>{{ list.payment_completion_ratio }}</td>
							<td>{{ list.payment_due_ratio }}</td>
							<td>{{ list.bank_short_code }}</td>
							<td>
								<i
									class="fa fa-product-hunt mnabricon"
									(click)="onShowUnitPayments(paymentsTemp, list.unit_id)"
									matTooltip="{{ 'lang_payments' | language }}"
								></i>
							</td>
						</tr>
						<tr *ngIf="!unitList?.length">
							<td colspan="11" class="text-center">
								{{ "lang_no_data" | language }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div
			class="col-12 mb-2 d-flex align-items-center justify-content-between mt-3"
		>
			<span>{{ "lang_total" | language }}: {{ unitsCollectionSize }}</span>
			<ngb-pagination
				[collectionSize]="unitsCollectionSize"
				[rotate]="true"
				[ellipses]="false"
				[maxSize]="3"
				[boundaryLinks]="true"
				[(page)]="+page_no"
				[pageSize]="+page_size"
				(pageChange)="getAllUnits()"
			></ngb-pagination>
			<mat-select
				style="max-width: 150px"
				[(ngModel)]="page_size"
				(selectionChange)="getAllUnits()"
			>
				<mat-option class="text-center" value="10"
					>10 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" value="20"
					>20 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" value="50"
					>50 {{ "lang_per_page" | language }}
				</mat-option>
				<mat-option class="text-center" value="100"
					>100 {{ "lang_per_page" | language }}</mat-option
				>
				<mat-option class="text-center" value="500"
					>500 {{ "lang_per_page" | language }}</mat-option
				>
			</mat-select>
		</div>
	</div>
</div>

<ng-template #historyFilesTemp>
	<div class="modal-content">
		<!-- Modal Header -->
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_history" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<!-- Modal body -->
		<div class="modal-body">
			<div class="row">
				<div class="col-12 mb-2">
					<div class="table-responsive customResponsive quotationitemtable">
						<table border="1" class="table CustomTable" width="100%">
							<thead>
								<tr>
									<td width="2%" style="padding: 5px; word-break: break-all">
										#
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_created_datetime" | language }}
									</td>
									<td width="48%" style="padding: 5px; word-break: break-all">
										{{ "lang_payment_due_ratio" | language }}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="
										let item of historyFilesList;
										let i = index;
										let odd = odd;
										let even = even
									"
								>
									<td
										class="verticalalignmid"
										style="padding: 5px; word-break: break-all"
									>
										{{ i + 1 }}
									</td>
									<td
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										{{ item?.created_datetime }}
									</td>
									<td
										class="verticalalignmid"
										style="
											padding: 5px;
											word-break: break-all;
											position: relative;
										"
									>
										<i
											class="fa fa-download mnabricon"
											matTooltip="{{ 'lang_download' | language }}"
											(click)="
												downloadHistoryUnitsFile(
													item?.file_url,
													item?.created_datetime
												)
											"
										></i>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal footer -->
		<div class="modal-footer">
			<button
				type="button"
				class="btn btn-secondary"
				data-dismiss="modal"
				(click)="modalRef.hide()"
				style="width: 40%"
			>
				{{ "lang_close" | language }}
			</button>
		</div>
	</div>
</ng-template>

<ng-template #paymentsTemp>
	<div class="modal-content">
		<!-- Modal Header -->
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_payments" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<!-- Modal body -->
		<div class="modal-body">
			<div class="row">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table border="1" class="table CustomTable">
							<thead>
								<tr>
									<td>#</td>
									<td>{{ "lang_unit_ratio" | language }}</td>
									<td>{{ "lang_payment_completion_ratio" | language }}</td>
									<td>{{ "lang_payment_due_ratio" | language }}</td>
									<td>
										{{ "lang_human_resources_bank_shortcode" | language }}
									</td>
									<td>{{ "lang_status" | language }}</td>
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody class="AppendList">
								<tr
									*ngFor="
										let list of payment_list;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
								>
									<td>{{ i + 1 }}</td>
									<td>{{ list.total_unit_ratio }}</td>
									<td>{{ list.payment_completion_ratio }}</td>
									<td>{{ list.payment_due_ratio }}</td>
									<td>{{ list.bank_short_code }}</td>
									<td>
										<div
											class="d-flex align-items-center justify-content-center"
										>
											<span
												*ngIf="list?.payment_status == 'not_due'"
												class="statusIcon yellowbgcolor"
												matTooltip="{{ 'lang_not_due' | language }}"
											></span>
											<span
												*ngIf="list?.payment_status == 'due'"
												class="statusIcon greenbg"
												matTooltip="{{ 'lang_due' | language }}"
											></span>
										</div>
									</td>
									<td>
										<div
											class="d-flex align-items-center justify-content-center"
										>
											<span
												(click)="changePaymentStatus(list)"
												*ngIf="
													list?.received_status == 'not_received' &&
													list?.payment_status == 'due'
												"
												class="statusIcon yellowbgcolor"
												matTooltip="{{ 'lang_not_received' | language }}"
											></span>
											<span
												*ngIf="
													list?.received_status == 'received' &&
													list?.payment_status == 'due'
												"
												class="statusIcon greenbg"
												matTooltip="{{ 'lang_received' | language }}"
											></span>
											<span *ngIf="list?.payment_status == 'not_due'">---</span>
										</div>
									</td>
								</tr>
								<tr *ngIf="!unitList?.length">
									<td colspan="4" class="text-center">
										{{ "lang_no_data" | language }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal footer -->
		<div class="modal-footer">
			<button
				type="button"
				class="btn btn-secondary"
				data-dismiss="modal"
				(click)="modalRef.hide()"
				style="width: 40%"
			>
				{{ "lang_close" | language }}
			</button>
		</div>
	</div>
</ng-template>

<ng-template #createTemp>
	<div class="modal-content">
		<!-- Modal Header -->
		<div class="modal-header">
			<h4 class="modal-title">{{ "lang_unit_bank" | language }}</h4>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				(click)="modalRef.hide()"
			>
				&times;
			</button>
		</div>
		<!-- Modal body -->
		<div class="modal-body">
			<form [formGroup]="unitForm">
				<div class="row">
					<div class="col-md-12 mb-2">
						<div class="form-group">
							<label>{{ "lang_general_numbers" | language }}</label>
							<input
								type="text"
								formControlName="unit_id"
								class="form-control"
							/>
						</div>
					</div>
				</div>
				<div class="col-12">
					<div class="form-group">
						<label>{{
							"lang_human_resources_bank_shortcode" | language
						}}</label>
						<ng-select
							[items]="shortCodeList"
							[multiple]="false"
							bindLabel="bank_name_en"
							bindValue="bank_short_code"
							clearAllText="Clear"
							[searchFn]="ds.customSearchFn"
							appendTo="body"
							formControlName="bank_short_code"
							placeholder="{{
								'lang_human_resources_bank_shortcode' | language
							}}"
							[notFoundText]="'lang_no_data' | language"
						>
						</ng-select>
					</div>
				</div>
			</form>
		</div>
		<!-- Modal footer -->
		<div class="modal-footer justify-content-between">
			<button
				type="button"
				class="btn btn-secondary"
				data-dismiss="modal"
				(click)="modalRef.hide()"
				style="width: 40%"
			>
				{{ "lang_close" | language }}
			</button>
			<button
				type="button"
				class="albutton mnabrbutton"
				style="width: 40% !important"
				(click)="onSubmitUnit()"
				mat-button
			>
				{{ "lang_submit" | language }}
			</button>
		</div>
	</div>
</ng-template>
