<div *ngIf="formName == 'DSR'">
  <app-form-ps-tr1-add [form_key]="formKey" [form_request_id]="'0'">
  </app-form-ps-tr1-add>
</div>

<div *ngIf="formName == 'WIR'">
  <app-form-wri-add [form_key]="formKey" [form_request_id]="'0'">
  </app-form-wri-add>
</div>

<div *ngIf="formName == 'SQR'">
  <app-form-sqr-add [form_key]="formKey" [form_request_id]="'0'">
  </app-form-sqr-add>
</div>

<div *ngIf="formName == 'MSR'">
  <app-form-msr-add [form_key]="formKey" [form_request_id]="'0'">
  </app-form-msr-add>
</div>

<div *ngIf="formName == 'MIR'">
  <app-form-mir-add [form_key]="formKey" [form_request_id]="'0'">
  </app-form-mir-add>
</div>

<div *ngIf="formName == 'NCR'">
  <app-form-ncr-add [form_key]="formKey" [form_request_id]="'0'">
  </app-form-ncr-add>
</div>

<div *ngIf="formName == 'SI'">
  <app-form-si-add
    [form_key]="formKey"
    [projects_work_area_id]="projects_work_area_id"
    [template_id]="template_id"
    [form_request_id]="'0'"
  >
  </app-form-si-add>
</div>
<!-- <div *ngIf="formName == 'TSR'">
	<app-form-ncr-add [form_key]="formKey" [form_request_id]="'0'">
	</app-form-ncr-add>
</div> -->
