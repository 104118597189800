import { Component, Inject, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-journal-costcenter",
  templateUrl: "./journal-costcenter.component.html",
  styleUrls: ["./journal-costcenter.component.scss"],
})
export class JournalCostcenterComponent implements OnInit {
  costCenterForm: FormGroup;
  costCenterFormArray: FormArray;
  costData = [];
  branch_id;
  total_cost;
  private subscriptions = new Subscription();
  constructor(
    public ds: DataService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public alert: AlertService,
    public router: Router,
    private dialogRef: MatDialogRef<JournalCostcenterComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private sweetAlert: SweetAlertService
  ) {
    this.branch_id = data?.branch_id;
    this.total_cost = data?.total_cost;
  }

  ngOnInit(): void {
    this.buildForm();
    if (this.data?.cost_centers && this.data?.cost_centers?.length) {
      for (let el of this.data?.cost_centers) {
        this.addCostCentersRecord(el);
      }
    } else {
      this.addCostCentersRecord();
    }
  }

  buildForm() {
    this.costCenterForm = this.fb.group({
      cost_centers: this.fb.array([]),
    });
    this.costCenterFormArray = this.costCenterForm.get(
      "cost_centers"
    ) as FormArray;
  }
  get costCenters() {
    return this.costCenterForm.get("cost_centers") as FormArray;
  }
  public costCentersRecords(value?): FormGroup {
    return this.fb.group({
      cid: new FormControl(value ? value?.cid : null, [Validators.required]),
      amount: new FormControl(value ? value?.amount : "", [
        Validators.required,
      ]),
      clabel: new FormControl(value ? value?.clabel : null),
      costList: new FormControl(value ? [...value?.costList] : []),
    });
  }
  public addCostCentersRecord(value?) {
    this.costCenterFormArray.push(this.costCentersRecords(value));
  }
  public removeCostCentersRecord(index) {
    if (this.costCenterFormArray.length > 1) {
      this.costCenterFormArray.removeAt(index);
    }
  }

  clearCostCenter() {
    for (let k = this.costCenterFormArray.length - 1; k > -1; k--) {
      if (
        this.costCenterFormArray.controls[k]?.get("cid").invalid &&
        this.costCenterFormArray.controls[k]?.get("amount").invalid
      ) {
        this.removeCostCentersRecord(k);
      }
    }
  }

  searchCosts(key, index) {
    // this.costData = [];
    let param = new FormData();
    param.append("branch_id", this.branch_id || "");
    param.append("search_text", key.term);
    if (this.branch_id && key.term && key.term != undefined) {
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "cctransactions").subscribe(
          (res) => {
            if (res.status) {
              // this.costData = res.records;
              this.costCenterFormArray.controls[index]
                ?.get("costList")
                ?.setValue(res.records);
            } else {
              // this.costData = [];
              this.costCenterFormArray.controls[index]
                ?.get("costList")
                ?.setValue([]);
            }
          },
          (error) => {
            // this.costData = [];
            this.costCenterFormArray.controls[index]
              ?.get("costList")
              ?.setValue([]);
          }
        )
      );
    }
  }
  onSubmit() {
    this.clearCostCenter();
    if (this.checkValidation()) {
      this.dialogRef.close({ costCenters: this.costCenterFormArray?.value });
      this.sweetAlert.successToast(this.lang.transform("lang_success"), 3000);
    }
  }

  checkValidation() {
    const costData = this.costCenterFormArray?.value?.map((el) => +el?.amount);
    if (this.costCenterForm.invalid) {
      this.sweetAlert.errorToast(
        this.lang.transform("lang_fill_required_fields"),
        3000
      );
      return false;
    } else if (this.total_cost != this.sumNumbers(costData)) {
      this.sweetAlert.errorToast("Total Cost not equal to sum of costs.", 3000);
      return false;
    } else {
      return true;
    }
  }
  sumNumbers(arr) {
    let sum = 0;
    for (let e of arr) {
      sum += e;
    }
    return sum;
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
